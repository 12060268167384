import { FormComponent, Model, createOptionsForSelect } from "../../utils/models/fields";
import { Note, Dmv, ContactNumber } from "./types";
import States from "us-states";
import { StateAccess } from "utils/models/formGenerator";
import { getStateLabelByState } from "../../utils/functions";
const contactStruct: FormComponent<ContactNumber> = {
  formComponent: "segment",
  name: "Contact Number",
  entities: [
    {
      formComponent: "segment",
      entities: [
        {
          formComponent: "phone-field",
          name: "phone",
          label: "Phone",
          path: ["contactNumber"],
          default: null,
          required: true
        }
      ]
    }
  ]
};

const noteStruct: FormComponent<Note> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "text-field",
      name: "note",
      label: "Note",
      width: "full",
      multiline: true,
      path: ["note"],
      required: true,
      default: null
    }
  ]
};

export const dmvStruct: Model<Dmv> = {
  formComponent: "model",
  schema: "new_dmv",
  name: "dmv",
  entities: [
    {
      formComponent: "segment",
      name: "Dmv Info",
      entities: [
        {
          formComponent: "select-field",
          name: "state",
          label: "State",
          width: "1/2",
          path: ["data", "info", "state"],
          options: createOptionsForSelect({
            possibleValues: (stateAccess: StateAccess) => Object.keys(States),
            getOptionLabel: (x) => getStateLabelByState(x),
            getSelectedOption: (x, y) => x === y
          }),
          default: null,
          required: true
        }
      ]
    },
    {
      formComponent: "list-model",
      name: "Contact Numbers",
      width: "full",
      entity: contactStruct,
      required: false,
      path: ["data", "info", "contactNumbers"]
    },
    {
      formComponent: "list-model",
      name: "Notes",
      entity: noteStruct,
      required: false,
      path: ["data", "notes"]
    }
  ]
};
