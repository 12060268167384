import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import {
  DealResponse,
  DealToEdit,
  DealToEditSend,
  PartialDealToEdit,
  PartialDealToEditSend
} from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DealToEditSend | PartialDealToEditSend,
  DealResponse
>("EditDeal", {
  request: "update_deal"
});

export const disaggregateDeal = (request: DealToEdit) => {
  const { refNumber, ...rest } = request.data.info;

  return {
    availableToRolesIds: request.availableToRolesIds ?? [],
    _id: request._id,
    approved: request?.approved ?? null,
    approvedAt: request.approvedAt ?? undefined,
    updatedAt: request.updatedAt ?? undefined,
    approvedByUserId: request.approvedByUserId ?? undefined,
    dataHash: request.dataHash ?? undefined,
    data: {
      info: { ...rest },
      source: request?.data?.source ?? undefined,
      //oneSpanSignings: request?.data?.oneSpanSignings ?? undefined,
      titleIssueId: request?.data?.titleIssueId ?? null,
      stateTaxId: request.data.stateTax?._id ?? null,
      lenderId: request.data.lender?._id ?? null,
      dealershipId: request.data.dealership?._id ?? null,
      payoffBankId: request.data.payoffBank?._id ?? null,
      vehicleInsuranceCompanyId: request.data.vehicleInsuranceCompany?._id ?? null,
      applicantId: request.data.applicant?._id ?? null,
      coApplicantId: request.data.coApplicant?._id ?? null,
      userId: request.data.user?._id ?? null,
      netsuiteEstimateId: request.data.netsuiteEstimateId ?? null,
      netsuiteSalesOrderId: request.data.netsuiteSalesOrderId ?? null,
      netsuiteInvoiceId: request.data.netsuiteInvoiceId ?? null,
      netsuiteVendorBillIds: request.data.netsuiteVendorBillIds,
      appOneData: request.data.appOneData ?? null,
      appOneApplicationNumber: request.data.appOneApplicationNumber ?? null,
      appOneApplicationURL: request.data.appOneApplicationURL ?? null,
      dealertrackDealNumbers: request.data.dealertrackDealNumbers ?? null,
      creditSmartsId: request.data.creditSmartsId,
      creditSmartsDate: request.data.creditSmartsDate,
      netsuiteStatus: request.data.netsuiteStatus ?? null
    }
  };
};

export const editDeal = (request: DealToEdit & Identifiable) => {
  return socketAction({ requestId: request.requestId, ...disaggregateDeal(request) });
};

export const editPartialDeal = (request: PartialDealToEdit & Identifiable) => {
  return socketAction({
    requestId: request.requestId,
    availableToRolesIds: request.availableToRolesIds ?? [],
    _id: request._id,
    approved: request?.approved ?? null,
    approvedAt: request.approvedAt ?? undefined,
    approvedByUserId: request.approvedByUserId ?? undefined,
    type: "partial",
    data: request.data
  });
};

export const editDealActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
