import { CustomRegexes } from "../../helpers";

export default {
  firstName: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: {
      pattern: "should not contain numbers"
    }
  },
  middleName: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: {
      pattern: "should not contain numbers"
    }
  },
  lastName: {
    type: "string",
    pattern: CustomRegexes.LETTERS,
    errorMessage: {
      pattern: "should not contain numbers"
    },
    nullable: true
  },
  socialSecurityNumber: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.SSN,
    errorMessage: {
      pattern: `
      SSN cannot
      begin with 666 or 900-999,
      be 078-05-1120 or 219-09-9999,
      contain all zeroes in a group(i.e., 000-##-####, ###-00-####, or ###-##-0000)
      or contain all matching values(i.e., 000-00-0000, 111-11-1111, 222-22-2222, etc.)
    `
    }
  },
  lastFourSSNDigits: {
    type: "string",
    nullable: true,
    maxLength: 4,
    pattern: CustomRegexes.NUMBERS,
    errorMessage: {
      pattern: "should not contain letters"
    }
  },
  driverLicenseNumber: { type: "string", nullable: true },
  driverLicenseIssued: {
    type: "string",
    nullable: true,
    format: "date-time",
    errorMessage: { format: "must be a valid date" }
  },
  driverLicenseExpires: {
    type: "string",
    nullable: true,
    format: "date-time",
    errorMessage: { format: "must be a valid date" }
  },
  birthDate: {
    type: "string",
    nullable: true,
    format: "age-restriction",
    errorMessage: {
      format: "must be Date between 17 and 100"
    }
  },
  currentCounty: {
    type: "string",
    pattern: CustomRegexes.LETTERS,
    CountyInState: true,
    errorMessage: {
      pattern: "should not contain numbers"
    },
    nullable: true
  },
  currentState: {
    type: "string",
    pattern: CustomRegexes.LETTERS,
    errorMessage: {
      pattern: "should not contain numbers"
    },
    nullable: true
  },
  currentAddress: {
    type: "string",
    nullable: true
  },
  currentAddressNumber: { type: "string", nullable: true },
  currentCountry: {
    type: "string",
    pattern: CustomRegexes.LETTERS,
    errorMessage: {
      pattern: "should not contain numbers"
    },
    nullable: true
  },
  currentCity: {
    type: "string",
    pattern: CustomRegexes.LETTERS,
    errorMessage: {
      pattern: "should not contain numbers"
    },
    nullable: true
  },
  currentZipCode: {
    type: "string",
    pattern: CustomRegexes.ZIP_CODE,
    nullable: true,
    errorMessage: {
      pattern: "should match either of the number formats: 'XXXXX' or 'XXXXX-XXXX'"
    }
  },
  yearsAtCurrentAddress: {
    type: "integer",
    minimum: 0,
    maximum: 125,
    nullable: true
  },
  monthsAtCurrentAddress: {
    type: "integer",
    minimum: 0,
    maximum: 12,
    nullable: true
  },
  previousCounty: {
    type: "string",
    CountyInState: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: {
      pattern: "should not contain numbers"
    },
    nullable: true
  },
  previousState: {
    type: "string",
    pattern: CustomRegexes.LETTERS,
    errorMessage: {
      pattern: "should not contain numbers"
    },
    nullable: true
  },
  previousAddress: { type: "string", nullable: true },
  previousAddressNumber: { type: "string", nullable: true },
  previousCountry: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: {
      pattern: "should not contain numbers"
    }
  },
  previousCity: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: {
      pattern: "should not contain numbers"
    }
  },
  previousZipCode: {
    type: "string",
    pattern: CustomRegexes.ZIP_CODE,
    nullable: true,
    errorMessage: {
      pattern: "should match either of the number formats: 'XXXXX' or 'XXXXX-XXXX'"
    }
  },
  yearsAtPreviousAddress: {
    type: "integer",
    minimum: 0,
    maximum: 125,
    nullable: true
  },
  monthsAtPreviousAddress: {
    type: "integer",
    minimum: 0,
    maximum: 12,
    nullable: true
  },
  propertyOwnership: { type: "string", nullable: true },
  previousPropertyOwnership: { type: "string", nullable: true },
  previousMortgagePerMonth: { type: "number", nullable: true },
  previousRentPerMonth: { type: "number", nullable: true },
  mortgagePerMonth: { type: "number", nullable: true },
  rentPerMonth: { type: "number", nullable: true },
  maritalStatus: {
    type: ["string", "null"],
    enum: ["Married", "Not married", null],
    nullable: true,
    errorMessage: {
      enum: "must be one of the following: 'Married' or 'Not Married'"
    }
  },
  usCitizen: {
    type: ["string", "null"],
    enum: ["Yes", "No", null],
    nullable: true,
    errorMessage: {
      enum: "must be one of the following: 'Yes' or 'No'"
    }
  },
  employmentStatus: {
    type: ["string", "null"],
    enum: ["Employee", "Business owner", "Retired", null],
    nullable: true,
    errorMessage: {
      enum: "must be one of the following: 'Employee', 'Business owner', 'Retired'"
    }
  },
  typeOfBusiness: { type: "string", nullable: true },
  employer: { type: "string", nullable: true },
  employerName: { type: "string", nullable: true },
  jobOccupation: { type: "string", nullable: true },
  monthsAtCurrentJob: {
    type: "integer",
    minimum: 0,
    maximum: 12,
    nullable: true
  },
  annualIncome: { type: "number", nullable: true },
  monthlyIncome: { type: "number", nullable: true },
  additionalIncome: { type: "number", nullable: true },
  sourceOfAdditionalIncome: { type: "string", nullable: true },
  previousEmployer: { type: "string", nullable: true },
  previousOccupation: { type: "string", nullable: true },
  yearsAtCurrentJob: {
    type: "integer",
    minimum: 0,
    maximum: 125,
    nullable: true
  },
  monthsAtPreviousJob: {
    type: "integer",
    minimum: 0,
    maximum: 12,
    nullable: true
  },
  yearsAtPreviousJob: {
    type: "integer",
    minimum: 0,
    maximum: 125,
    nullable: true
  },
  email: {
    type: "string",
    pattern: CustomRegexes.EMAIL,
    nullable: true,
    errorMessage: {
      pattern: "must match the format <emailname>@<provider>.<org>"
    }
  },
  homePhone: {
    type: "string",
    pattern: CustomRegexes.PHONE,
    nullable: true,
    errorMessage: {
      pattern: "must contain only numbers and cannot start with 0 or 1"
    }
  },
  mobilePhone: {
    type: "string",
    pattern: CustomRegexes.PHONE,
    nullable: true,
    errorMessage: {
      pattern: "must contain only numbers and cannot start with 0 or 1"
    }
  },
  refNumber: { type: "string", nullable: true },
  businessPhone: {
    type: "string",
    pattern: CustomRegexes.PHONE,
    nullable: true,
    errorMessage: {
      pattern: "must contain only numbers and cannot start with 0 or 1"
    }
  },
  drivingLicenseState: { type: "string", nullable: true },
  firstLastName: { type: "string", nullable: true },
  initials: { type: "string", nullable: true },
  fullName: { type: "string", nullable: true },
  suffix: {
    type: ["string", "null"],
    enum: ["SR", "JR", "I", "II", "III", "IV", "V", null],
    nullable: true,
    errorMessage: {
      enum: "must be one of the following: 'SR', 'JR', 'I', 'II', 'III', 'IV', 'V'"
    }
  }
};
