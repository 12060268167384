import { createListSlice } from "../Middlewares/listSliceCreator";
import { AdditionalRequiredDocument } from "./types";

const { ListSlice, getList } = createListSlice<AdditionalRequiredDocument>({
  name: "AdditionalRequiredDocumentList",
  request_topic: "all_additional_required_documents",
  reducers: {}
});

export default ListSlice.reducer;
export const getAdditionalRequiredDocumentList = getList;
export const removeAdditionalRequiredDocumentList = ListSlice.actions.removeList;
export const listAdditionalRequiredDocumentActions = ListSlice.actions;
