import { EntityType, getModel } from "utils/entitySlice";
import { fillDefaultsByPath, generateDefault, getByPath } from "utils/models/formGenerator";
import { Permission, Permissions, Role } from "./types";

export default (newRole: Role, oldRole: Role): Role => {
  return {
    ...newRole,
    data: {
      ...newRole.data,
      info: { ...newRole.data.info, permissions: recalcPermissions(newRole.data.info.permissions) }
    }
  };
};
const recalcPermission = (
  entityType: EntityType,
  permission: Permission<EntityType>,
  defaultRole: Role
): Permission<"role"> => {
  if (permission.read?.hasPermission !== true) {
    return {
      read: {
        filters: [],
        hasPermission: false,
        hasCustomPermission: false,
        dataPermissions: undefined
      },
      update: {
        hasPermission: false,
        hasCustomPermission: false,
        dataPermissions: undefined
      },
      create: {
        hasPermission: false
      },
      delete: {
        hasPermission: false
      },
      menu: {
        hasPermission: false
      }
    };
  } else {
    return {
      ...permission,
      read: {
        ...permission.read,
        hasCustomPermission: permission.read?.hasPermission
          ? permission.read?.hasCustomPermission
          : false,
        dataPermissions: permission.read?.hasCustomPermission
          ? permission.read?.dataPermissions ||
            getByPath(defaultRole, [
              "data",
              "info",
              "permissions",
              entityType,
              "read",
              "dataPermissions"
            ])
          : null
      },
      update: {
        ...permission.update,
        hasCustomPermission: permission.update?.hasPermission
          ? permission.update?.hasCustomPermission
          : false,
        dataPermissions: permission.update?.hasCustomPermission
          ? permission.update?.dataPermissions ||
            getByPath(defaultRole, [
              "data",
              "info",
              "permissions",
              entityType,
              "update",
              "dataPermissions"
            ])
          : null
      }
    };
  }
};
const recalcPermissions = (permissions: Permissions): Permissions => {
  const defaultRole = generateDefault(
    getModel("role") as any,
    {},
    fillDefaultsByPath as any
  ) as Role;
  return Object.entries(permissions).reduce((acc, [key, permission]) => {
    return { ...acc, [key]: recalcPermission(key as EntityType, permission, defaultRole) };
  }, {} as Permissions);
};
