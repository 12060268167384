import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";
import { LenderNote, AddLenderNoteRequest } from "./types";

interface AddLenderNoteResponse {
  message: LenderNote;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddLenderNoteRequest,
  AddLenderNoteResponse
>("AddLenderNoteResponse", {
  request: "new_lender_note"
});

export const addLenderNoteActions = socketMessageSlice.actions;

export const addLenderNote = socketAction;

export default socketMessageSlice.reducer;
