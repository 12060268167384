import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface GenerateLenderLinkRequest {
  _id: string;
}

export interface GenerateLenderLinkResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  GenerateLenderLinkRequest,
  GenerateLenderLinkResponse
>("GenerateLinkLender", {
  request: "lender_link_generator"
});

export const GenerateLenderLinkActions = socketMessageSlice.actions;
export const GenerateLenderLink = socketAction;
export default socketMessageSlice.reducer;
