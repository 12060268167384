import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { DeleteContractRequerst } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<DeleteContractRequerst, any>(
  "DeleteContractRequerst",
  {
    request: "protective_delete_contract"
  }
);

export const deleteContract = socketAction;
export const deleteContractActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
