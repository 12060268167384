import info_properties from "./info_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

const data = {
  type: "object",
  required: ["info"],
  properties: {
    info: {
      type: "object",
      required: ["level", "userEmail", "type", "title", "body", "referenceId"],
      properties: info_properties,
    },
  },
};

export default {
  new_notification: {
    $id: "newNotification",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
  delete_notification: {
    $id: "deleteNotification",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  delete_all_notifications: {
    $id: "deleteAllNotifications",
    type: "object",
    required: ["userEmail"],
    properties: {
      userEmail: { type: "string" },
    },
  },
  update_notification: {
    $id: "updateNotification",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: data,
    },
  },
  get_notification: {
    $id: "getNotification",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_notifications: {
    $id: "allNotifications",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
