import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PhoneIcon from "@material-ui/icons/Phone";
import { FormEditContext } from "components/Content/FormEditContext";
import { hasPermissionMatch } from "components/Roles/model";
import { DataPermissionsNode, Permission } from "components/Roles/types";
import { User } from "components/Users/types";
import React from "react";
import { RequestType } from "validations/types";
import {
  CheckboxField,
  DateField,
  DateWithAge,
  DurationField,
  filePreviewField,
  FormComponent,
  ListModel,
  ReadOnlyListModel,
  Model,
  MultiSelectField,
  MultiTextInputField,
  NumberInputField,
  Path,
  PercentageField,
  RadioField,
  RawField,
  ReadOnlyDateField,
  ReadOnlyField,
  ReadOnlyNumberField,
  ReadOnlyPercentageField,
  Segment,
  SelectField,
  TabListModel,
  TextInputField,
  Timeline,
  VirtualField,
  ZipCodeField
} from "./fields";
import { editRenderSet, showRenderSet } from "./formRenderers";

export type ParentPath<T> = Path<T>;

export const getByPath = <T extends Object>(x: T, path: Path<T>) => {
  return (path as Array<any>).reduce((prev: any, curr: any) => prev?.[curr], x);
};

export const getLastByPath = <T extends Object>(x: T, path: Path<T>) => {
  let acc: any = x;
  path.every((p: any) => {
    const nextValue: any = acc?.[p];
    if (nextValue) {
      acc = nextValue;
      return true;
    } else {
      return false;
    }
  });
  return acc;
};

export const setByPath = <T extends Object>(x: T, path: Path<T>, value: any): any => {
  if (path.length < 1) {
    return value;
  } else {
    const currentPart = path[0];
    if (typeof currentPart === "number") {
      if (((x as unknown) as Array<any>)?.[currentPart]) {
        return ((x as unknown) as Array<any>)?.map((el: any, index: number) =>
          index === currentPart ? setByPath(el, (path as any).slice(1), value) : el
        );
      } else {
        ((x as unknown) as Array<any>)[currentPart] = setByPath({}, (path as any).slice(1), value);
        return x;
      }
    } else {
      return {
        ...(x ?? {}),
        // @ts-ignore
        [path[0]]: setByPath(x[path[0]] ?? {}, (path as any).slice(1), value)
      };
    }
  }
};

export const fillDefaultsByPath = <T extends Object>(
  x: Partial<T> | undefined,
  path: Path<T>,
  value: any
): any => {
  if ((path.length as number) === 0) {
    return value;
  } else {
    const currentPart = path[0];
    if (typeof currentPart === "number") {
      return ((x ?? [{}]) as any).map((el: any, index: number) =>
        index === currentPart ? fillDefaultsByPath(el, (path as any).slice(1), value) : el
      );
    } else {
      return {
        ...x,
        // @ts-ignore
        [currentPart]: fillDefaultsByPath(x?.[currentPart] as any, (path as any).slice(1), value)
      };
    }
  }
};

export const accessStringToNumber = (stateAccess: StateAccess): StateAccess => {
  return {
    get: (path) => stateAccess.get(path as any) ?? "",
    set: (path, value): any =>
      stateAccess.set(path as any, value === "" ? 0 : parseFloat(value) || 0)
  };
};

export const accessNumberToString = (stateAccess: StateAccess): StateAccess => {
  return {
    get: (path) => stateAccess.get(path) ?? "",
    set: (path, value): any => {
      const finalValue = value?.replace(/\D/g, "");
      stateAccess.set(path, finalValue !== "" ? finalValue : undefined);
    }
  };
};

export interface StateAccess {
  get: <T>(path: Path<T>) => any;
  set: <T>(path: Path<T>, value: any) => T;
}

export interface RenderSet {
  type: "edit" | "show";
  schema: RequestType | false;
  listModelRenderer: <T extends any>(
    listModel: ListModel<T>,
    stateAccess: StateAccess,
    path: Path<T>,
    mainStateAccess: StateAccess,
    renderSet: RenderSet,
    permissions?: Permission,
    user?: User,
    formEditContext?: FormEditContext,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  readOnlyListModelRenderer: <T extends any>(
    listModel: ReadOnlyListModel<T>,
    stateAccess: StateAccess,
    path: Path<T>,
    mainStateAccess: StateAccess,
    renderSet: RenderSet,
    permissions?: Permission,
    user?: User,
    formEditContext?: FormEditContext,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  tabListModelRenderer: <T extends any>(
    listModel: TabListModel<T>,
    stateAccess: StateAccess,
    path: Path<T>,
    mainStateAccess: StateAccess,
    renderSet: RenderSet,
    permissions?: Permission,
    user?: User,
    formEditContext?: FormEditContext,
    parentPath?: ParentPath<T>,
    addRestriction?: (stateAccess: StateAccess) => boolean
  ) => JSX.Element;
  segmentRenderer: <T extends any>(
    segment: Segment<T>,
    stateAccess: StateAccess,
    path: Path<T>,
    mainStateAccess: StateAccess,
    renderSet: RenderSet,
    permissions?: Permission,
    user?: User,
    formEditContext?: FormEditContext,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  modelRenderer: <T extends any>(
    model: Model<T>,
    stateAccess: StateAccess,
    mainStateAccess: StateAccess,
    renderSet: RenderSet,
    permissions?: Permission,
    user?: User,
    formEditContext?: FormEditContext,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  textInputRenderer: <T extends any>(
    textInput: TextInputField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    digitsOnlyField?: boolean,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  upperCaseTextInputRenderer: <T extends any>(
    textInput: TextInputField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    digitsOnlyField?: boolean,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  hiddenInputRenderer: <T extends any>(
    textInput: TextInputField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  SSNFieldRenderer: <T extends any>(
    textInput: TextInputField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  numberInputRenderer: <T extends any>(
    numberInput: NumberInputField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  currencyInputRenderer: <T extends any>(
    numberInput: NumberInputField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  percentageInputRenderer: <T extends any>(
    numberInput: PercentageField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  dateInputRenderer: <T extends any>(
    dateField: DateField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  readOnlydateInputRenderer: <T extends any>(
    dateField: ReadOnlyDateField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet
  ) => JSX.Element;
  selectInputRenderer: <T extends any>(
    selectField: SelectField<T>,
    stateAccess: StateAccess,
    path: Path<T>,
    mainStateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  multiSelectInputRenderer: <T extends any>(
    multiSelectField: MultiSelectField<T>,
    stateAccess: StateAccess,
    path: Path<T>,
    mainStateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  multiTextInputFieldRenderer: <T extends any>(
    multiTextInputField: MultiTextInputField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    digitsOnlyField?: boolean,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  readOnlyMultiTextInputFieldRenderer: <T extends any>(
    multiTextInputField: MultiTextInputField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    digitsOnlyField?: boolean,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  multiPhoneInputFieldRenderer: <T extends any>(
    multiPhoneInputField: MultiTextInputField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  radioInputRenderer: <T extends any>(
    radioField: RadioField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    formEditContext?: FormEditContext,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  checkboxInputRenderer: <T extends any>(
    checkboxField: CheckboxField<T>,
    stateAccess: StateAccess,
    mainStateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  phoneInputRenderer: <T extends any>(
    textInput: TextInputField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  dateWithAgeRenderer: <T extends any>(
    dateField: DateWithAge<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  readOnlyRenderer: <T extends any>(
    dateField: ReadOnlyField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  readOnlyNumberRenderer: <T extends any>(
    dateField: ReadOnlyNumberField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  readOnlyPercentageRenderer: <T extends any>(
    dateField: ReadOnlyPercentageField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  zipCodeInputRenderer: <T extends any>(
    props: ZipCodeField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  filePreviewFieldRenderer: <T extends any>(
    filePreviewField: filePreviewField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  virtualFieldRenderer: <T extends any>(
    VirtualField: VirtualField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  yearFieldRenderer: <T extends any>(
    numberInput: NumberInputField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  monthsFieldRenderer: <T extends any>(
    numberInput: NumberInputField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  rawFieldRenderer: <T extends any>(
    rawField: RawField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  durationFieldRenderer: <T extends any>(
    durationField: DurationField<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
  timelineRenderer: <T extends any>(
    props: Timeline<T>,
    stateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: ParentPath<T>
  ) => JSX.Element;
}
const sanitizeByFilters = (filters: any, stateAccess: any, userId: any): any => {
  return filters.every((filter: any) => {
    if (Array.isArray(filter)) return sanitizeByFilters(filter, stateAccess, userId);

    const value =
      filter.criteriaType === "date"
        ? new Date(stateAccess.get(filter.path.split(".")))?.toISOString().slice(0, 10)
        : stateAccess.get(filter.path.split("."))?.toString();
    const criteria =
      filter.criteriaType === "date"
        ? new Date(value)?.toISOString().slice(0, 10)
        : filter.criteriaType === "currentUserId"
        ? userId
        : filter.criteria?.toString();

    switch (filter.operation) {
      case "==":
        return value === criteria;
      case "!=":
        return value !== criteria;
      case ">=":
        return value >= criteria;
      case ">":
        return value > criteria;
      case "<":
        return value < criteria;
      case "<=":
        return value <= criteria;
      default:
        return false;
    }
  });
};
const GenerateFormComponent = <T extends any>({
  formComponent,
  stateAccess,
  path,
  mainStateAccess,
  renderSet,
  permissions,
  formEditContext,
  user
}: {
  formComponent: FormComponent<T>;
  stateAccess: StateAccess;
  path: string[];
  mainStateAccess: StateAccess;
  renderSet: RenderSet;
  permissions?: Permission;
  formEditContext?: FormEditContext;
  user?: User;
}) => {
  const parentPath = path;
  if (formComponent.formComponent !== "model" && formComponent?.path?.length > 0 && permissions) {
    if (!permissions.read.hasPermission) return null;
    if (permissions.read.hasCustomPermission) {
      const permission: DataPermissionsNode | undefined = getLastByPath(
        permissions.read.dataPermissions ?? {},
        formComponent?.path
      );
      if (permission && permission?.hasPermission === false) {
        return null;
      }
      if (
        permission &&
        permission?.hasPermission === true &&
        permission?.filters?.length > 0 &&
        user &&
        !sanitizeByFilters(permission?.filters, stateAccess, user._id)
      ) {
        return null;
      }

      if (
        typeof permission === "object" &&
        !permission.hasOwnProperty("hasPermission") &&
        !hasPermissionMatch(permission, true)
      )
        return null;
    }
  }
  let sanittizedRenderSet = renderSet;
  if (
    formEditContext?.enabled &&
    formComponent.formComponent !== "model" &&
    formComponent?.path?.length > 0 &&
    permissions?.update.hasCustomPermission
  ) {
    sanittizedRenderSet = getLastByPath(
      permissions?.update?.dataPermissions ?? {},
      formComponent?.path
    )
      ? editRenderSet(renderSet.schema)
      : showRenderSet(renderSet.schema);
  }

  switch (formComponent.formComponent) {
    case "segment":
      return sanittizedRenderSet.segmentRenderer(
        formComponent,
        stateAccess,
        parentPath,
        mainStateAccess,
        renderSet,
        permissions,
        user,
        formEditContext
      );
    case "model":
      return sanittizedRenderSet.modelRenderer(
        formComponent,
        stateAccess,
        mainStateAccess,
        renderSet,
        permissions,
        user,
        formEditContext
      );
    case "list-model":
      return sanittizedRenderSet.listModelRenderer(
        formComponent,
        stateAccess,
        parentPath,
        mainStateAccess,
        // @ts-ignore
        { ...sanittizedRenderSet, entityName: undefined },
        permissions,
        formEditContext
      );

    case "read-only-list-model":
      return sanittizedRenderSet.readOnlyListModelRenderer(
        formComponent,
        stateAccess,
        parentPath,
        mainStateAccess,
        // @ts-ignore
        { ...sanittizedRenderSet, entityName: undefined },
        permissions,
        formEditContext
      );
    case "tab-list-model":
      return sanittizedRenderSet.tabListModelRenderer(
        formComponent,
        stateAccess,
        parentPath,
        mainStateAccess,
        // @ts-ignore
        { ...sanittizedRenderSet, entityName: undefined },
        permissions,
        formEditContext
      );
    case "email-field":
      return sanittizedRenderSet.textInputRenderer(
        { ...formComponent, type: "email", icon: <EmailIcon /> },
        stateAccess,
        sanittizedRenderSet,
        undefined,
        parentPath
      );
    case "text-field":
      return sanittizedRenderSet.textInputRenderer(
        { ...formComponent, type: "text" },
        stateAccess,
        sanittizedRenderSet,
        undefined,
        parentPath
      );
    case "password-field":
      return sanittizedRenderSet.textInputRenderer(
        { ...formComponent, type: "password" },
        stateAccess,
        sanittizedRenderSet,
        undefined,
        parentPath
      );
    case "multi-text-field":
      return sanittizedRenderSet.multiTextInputFieldRenderer(
        { ...formComponent, type: "text" },
        stateAccess,
        sanittizedRenderSet,
        undefined,
        parentPath
      );
    case "multi-email-field":
      return sanittizedRenderSet.multiTextInputFieldRenderer(
        { ...formComponent, type: "email" },
        stateAccess,
        sanittizedRenderSet,
        undefined,
        parentPath
      );
    case "read-only-multi-text-field":
      return sanittizedRenderSet.readOnlyMultiTextInputFieldRenderer(
        { ...formComponent, type: "text" },
        stateAccess,
        sanittizedRenderSet,
        undefined,
        parentPath
      );
    case "multi-phone-field":
      return sanittizedRenderSet.multiPhoneInputFieldRenderer(
        { ...formComponent, type: "text" },
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "uppercase-text-field":
      return sanittizedRenderSet.upperCaseTextInputRenderer(
        { ...formComponent, type: "text" },
        stateAccess,
        sanittizedRenderSet,
        undefined,
        parentPath
      );
    case "hidden-field":
      return sanittizedRenderSet.hiddenInputRenderer(
        { ...formComponent, type: "text" },
        stateAccess,
        sanittizedRenderSet
      );
    case "virtual-field":
      return <span key={"virtual" + formComponent.path.join() + formComponent.label} />;
    case "name-field":
      return sanittizedRenderSet.textInputRenderer(
        {
          ...formComponent,
          type: "text"
        },
        stateAccess,
        sanittizedRenderSet,
        undefined,
        parentPath
      );
    case "address-field":
      return sanittizedRenderSet.textInputRenderer(
        {
          ...formComponent,
          width: formComponent.width ?? "full",
          type: "text",
          icon: <HomeIcon />
        },
        stateAccess,
        sanittizedRenderSet,
        undefined,
        parentPath
      );
    case "city-field":
      return sanittizedRenderSet.textInputRenderer(
        { ...formComponent, type: "text", icon: <LocationCityIcon /> },
        stateAccess,
        sanittizedRenderSet,
        undefined,
        parentPath
      );
    case "zip-code-field":
      return sanittizedRenderSet.zipCodeInputRenderer(
        formComponent,
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "phone-field":
      return sanittizedRenderSet.phoneInputRenderer(
        { ...formComponent, type: "phone", icon: <PhoneIcon /> },
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "date-field":
      return sanittizedRenderSet.dateInputRenderer(
        formComponent,
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "read-only-date-field":
      return sanittizedRenderSet.readOnlydateInputRenderer(formComponent, stateAccess, renderSet);

    case "ssn-field":
      return sanittizedRenderSet.SSNFieldRenderer(
        { ...formComponent, type: "text" },
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );

    case "raw-field":
      return sanittizedRenderSet.rawFieldRenderer(
        { ...formComponent },
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "license-number-field":
      return sanittizedRenderSet.textInputRenderer(
        { ...formComponent, type: "text" },
        stateAccess,
        sanittizedRenderSet,
        undefined,
        parentPath
      );
    case "radio-field":
      return sanittizedRenderSet.radioInputRenderer(
        formComponent,
        stateAccess,
        sanittizedRenderSet,
        formEditContext,
        parentPath
      );
    case "select-field":
      return sanittizedRenderSet.selectInputRenderer(
        formComponent,
        stateAccess,
        parentPath,
        mainStateAccess,
        renderSet,
        parentPath
      );
    case "multiselect-field":
      return sanittizedRenderSet.multiSelectInputRenderer(
        formComponent,
        stateAccess,
        parentPath,
        mainStateAccess,
        renderSet,
        parentPath
      );
    case "months-field":
      return sanittizedRenderSet.monthsFieldRenderer(
        { ...formComponent, max: 3 },
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "number-field":
      return sanittizedRenderSet.numberInputRenderer(
        formComponent,
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "percentage-field":
      return sanittizedRenderSet.percentageInputRenderer(
        formComponent,
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "income-field":
      return sanittizedRenderSet.currencyInputRenderer(
        formComponent,
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "currency-field":
      return sanittizedRenderSet.currencyInputRenderer(
        formComponent,
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "one-to-many-field":
      return formComponent.component(
        stateAccess,
        mainStateAccess,
        sanittizedRenderSet,
        parentPath,
        permissions,
        formEditContext
      );
    case "checkbox-field":
      return sanittizedRenderSet.checkboxInputRenderer(
        formComponent,
        stateAccess,
        mainStateAccess,
        renderSet,
        parentPath
      );
    case "date-with-age-field":
      return sanittizedRenderSet.dateWithAgeRenderer(
        formComponent,
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "read-only-field":
      return sanittizedRenderSet.readOnlyRenderer(
        formComponent,
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "read-only-number-field":
      return sanittizedRenderSet.readOnlyNumberRenderer(
        formComponent,
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "read-only-percentage-field":
      return sanittizedRenderSet.readOnlyPercentageRenderer(
        formComponent,
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "digits-only-field":
      return sanittizedRenderSet.textInputRenderer(
        { ...formComponent, type: "text" },
        accessNumberToString(stateAccess),
        renderSet,
        true,
        parentPath
      );
    case "file-preview-field":
      return sanittizedRenderSet.filePreviewFieldRenderer(
        formComponent,
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "year-field":
      return sanittizedRenderSet.yearFieldRenderer(
        formComponent,
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "timeline":
      return sanittizedRenderSet.timelineRenderer(
        formComponent,
        stateAccess,
        sanittizedRenderSet,
        parentPath
      );
    case "duration-field":
      return renderSet.durationFieldRenderer(formComponent, stateAccess, renderSet, parentPath);
    default:
      return <></>;
  }
};

export const generateForm = <T extends any>(
  formComponent: FormComponent<T>,
  stateAccess: StateAccess,
  path: string[],
  mainStateAccess: StateAccess,
  renderSet: RenderSet,
  permissions?: Permission,
  formEditContext?: FormEditContext,
  user?: User
) => {
  const Component = GenerateFormComponent({
    formComponent,
    stateAccess,
    path,
    mainStateAccess,
    renderSet,
    permissions,
    formEditContext,
    user
  });
  return Component;
};

export const generateDefault = <T extends any>(
  component: FormComponent<T>,
  x: Object,
  set: (y: Object, path: Path<T>, value: any) => any,
  permissions?: Permission
): Object => {
  if (
    component.formComponent !== "model" &&
    Array.isArray(component.path) &&
    component.path.length > 0 &&
    permissions &&
    permissions.read.hasCustomPermission &&
    !getByPath(permissions.read.dataPermissions ?? {}, component.path)
  ) {
    return x;
  }
  switch (component.formComponent) {
    case "model":
      return component.entities.reduce((temp, entity) => {
        return generateDefault(entity, temp, set, permissions);
      }, x);
    case "segment":
      return component.hasOwnProperty("default") && component.hasOwnProperty("path")
        ? set(
            x,
            component.path as any,
            typeof component.default === "function" ? component.default() : component.default
          )
        : component.entities.reduce((temp, entity) => {
            return generateDefault(entity, temp, set, permissions);
          }, x);
    case "list-model":
    case "read-only-list-model":
    case "tab-list-model":
      return set(x, component.path, []);
    case "one-to-many-field":
      // if (component.struct !== undefined) {
      //   return generateDefault(
      //     typeof component.struct === "function" ? component.struct() : component.struct,
      //     x,
      //     (final: Object, deepPath: Path<T>, value: any) =>
      //       // @ts-ignore
      //       set(final, ((component.path as Path<T>) || []).concat(deepPath), value)
      //   );
      // } else {
      //   if (component.path === null || component.path?.length === 0) {
      //     return x;
      //   }
      //   return component.hasOwnProperty("default") ? set(x, component.path, component.default) : x;
      // }
      if (component.path === null || component.path?.length === 0) {
        return x;
      }
      return component.hasOwnProperty("default")
        ? set(
            x,
            component.path,
            typeof component.default === "function" ? component.default() : component.default
          )
        : x;
    case "virtual-field":
      return x;
    default:
      if (component.path === null || component.path?.length === 0) {
        return x;
      }
      return component.hasOwnProperty("default")
        ? set(
            x,
            component.path,
            typeof component.default === "function" ? component.default() : component.default
          )
        : x;
  }
};
