import { RootState } from "app/rootReducer";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateAccess } from "utils/models/formGenerator";
import {
  getDealershipStatisticList,
  removeDealershipStatisticList
} from "./listDealershipStatisticSlice";

interface Props {
  stateAccess: StateAccess;
}

export default function StatisticsPreview({ stateAccess }: Props) {
  // const dispatch = useDispatch();
  // const listId = `dealership-statistics-${stateAccess.get(["_id"])}`;
  // const statisticsList = useSelector(
  //   (state: RootState) => state.listDealershipStatisticsSlice[listId]
  // );

  // const dealership = stateAccess.get([]);

  // console.log("dealershipList", statisticsList);
  // useEffect(() => {
  //   if (!statisticsList) dispatch(getDealershipStatisticList(listId, {}));
  //   return () => {
  //     dispatch(removeDealershipStatisticList(listId));
  //     return;
  //   };
  // }, []);

  // return <pre>{JSON.stringify(statisticsList, null, 2)}</pre>;
  return <></>;
}
