import { createSlice } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "app/store";

const loaderSlice = createSlice({
  name: "loader",
  initialState: { waiting: false },
  reducers: {
    showLoader(state) {
      state.waiting = true;
    },
    hideLoader(state) {
      state.waiting = false;
    },
  },
});

export const showLoader = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(loaderSlice.actions.showLoader());
};
export const hideLoader = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(loaderSlice.actions.hideLoader());
};

export default loaderSlice.reducer;
