import { permissionNode, PermissionStruct, relationPermissionNode } from "components/Roles/types";
import { ExternalCreditApplication } from "./types";
export type ExternalCreditApplicationPermissionsStruct = PermissionStruct<
  ExternalCreditApplication
>;

export const externalCreditApplicationPermissionStruct: ExternalCreditApplicationPermissionsStruct = {
  _id: permissionNode("id"),
  createdAt: permissionNode("Created at at date"),
  updatedAt: permissionNode("Updated at at date"),
  deleted: permissionNode("Deleted deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      adfEmailSent: permissionNode("Adf email sent"),
      creditType: permissionNode("Credit type"),
      applicant: {
        firstName: permissionNode("First name"),
        middleName: permissionNode("Middle name"),
        lastName: permissionNode("Last name"),
        idType: permissionNode("Id type"),
        idNumber: permissionNode("Id number"),
        idIssuedBy: permissionNode("Id issued by"),
        idIssuedDate: permissionNode("Driver license issued"),
        drivingLicenseState: permissionNode("Driving license state"),
        idExpDate: permissionNode("Id exp date"),
        dateOfBirth: permissionNode("Date of birth"),
        ssn: permissionNode("Ssn"),
        primaryPhone: permissionNode("Primary phone"),
        secondaryPhone: permissionNode("Secondary phone"),
        email: permissionNode("Email"),
        currentAddress: {
          address: permissionNode("Address"),
          addressNumber: permissionNode("Address number"),
          apt: permissionNode("Apt"),
          city: permissionNode("City"),
          state: permissionNode("State"),
          zip: permissionNode("Zip"),
          time: { years: permissionNode("Years"), months: permissionNode("Months") }
        },
        residentialStatus: permissionNode("Residential status"),
        rentOrMortgageAmount: permissionNode("Rent or mortgage amount"),
        previousAddress: {
          address: permissionNode("Address"),
          addressNumber: permissionNode("Address number"),
          apt: permissionNode("Apt"),
          city: permissionNode("City"),
          state: permissionNode("State"),
          zip: permissionNode("Zip"),
          time: { years: permissionNode("Years"), months: permissionNode("Months") }
        },
        currentEmployer: {
          name: permissionNode("Name"),
          grossMonthlySalary: permissionNode("Gross monthly salary"),
          workPhone: permissionNode("Work phone"),
          occupationOrJobTitle: permissionNode("Occupation or job title"),
          time: { years: permissionNode("Years"), months: permissionNode("Months") }
        },
        prevEmployer: {
          name: permissionNode("Name"),
          occupationOrJobTitle: permissionNode("Occupation or job title"),
          time: { years: permissionNode("Years"), months: permissionNode("Months") }
        },
        grossMonthlyOtherIncome: permissionNode("Gross monthly other income"),
        otherIncomeSource: permissionNode("Other income source"),
        isUSCitizen: permissionNode("Is us citizen"),
        maritalStatus: permissionNode("Marital status"),
        employmentStatus: permissionNode("Employment status"),
        retirementIncome: permissionNode("Retirement income"),
        sourceOfRetirementIncome: permissionNode("Source of retirement income")
      },
      coApplicant: {
        firstName: permissionNode("First name"),
        middleName: permissionNode("Middle name"),
        lastName: permissionNode("Last name"),
        idType: permissionNode("Id type"),
        idNumber: permissionNode("Id number"),
        idIssuedBy: permissionNode("Id issued by"),
        idIssuedDate: permissionNode("Driver license issued"),
        drivingLicenseState: permissionNode("Driving license state"),
        idExpDate: permissionNode("Id exp date"),
        dateOfBirth: permissionNode("Date of birth"),
        ssn: permissionNode("Ssn"),
        primaryPhone: permissionNode("Primary phone"),
        secondaryPhone: permissionNode("Secondary phone"),
        email: permissionNode("Email"),
        currentAddress: {
          address: permissionNode("Address"),
          addressNumber: permissionNode("Address number"),
          apt: permissionNode("Apt"),
          city: permissionNode("City"),
          state: permissionNode("State"),
          zip: permissionNode("Zip"),
          time: { years: permissionNode("Years"), months: permissionNode("Months") }
        },
        residentialStatus: permissionNode("Residential status"),
        rentOrMortgageAmount: permissionNode("Rent or mortgage amount"),
        previousAddress: {
          address: permissionNode("Address"),
          addressNumber: permissionNode("Address number"),
          apt: permissionNode("Apt"),
          city: permissionNode("City"),
          state: permissionNode("State"),
          zip: permissionNode("Zip"),
          time: { years: permissionNode("Years"), months: permissionNode("Months") }
        },
        currentEmployer: {
          name: permissionNode("Name"),
          grossMonthlySalary: permissionNode("Gross monthly salary"),
          workPhone: permissionNode("Work phone"),
          occupationOrJobTitle: permissionNode("Occupation or job title"),
          time: { years: permissionNode("Years"), months: permissionNode("Months") }
        },
        prevEmployer: {
          name: permissionNode("Name"),
          occupationOrJobTitle: permissionNode("Occupation or job title"),
          time: { years: permissionNode("Years"), months: permissionNode("Months") }
        },
        grossMonthlyOtherIncome: permissionNode("Gross monthly other income"),
        otherIncomeSource: permissionNode("Other income source"),
        isUSCitizen: permissionNode("Is us citizen"),
        maritalStatus: permissionNode("Marital status"),
        employmentStatus: permissionNode("Employment status"),
        retirementIncome: permissionNode("Retirement income"),
        sourceOfRetirementIncome: permissionNode("Source of retirement income")
      },
      collateral: {
        vin: permissionNode("Vin"),
        unitStatus: permissionNode("Unit Status"),
        MSRP: permissionNode("MSRP"),
        year: permissionNode("Year"),
        make: permissionNode("Make"),
        model: permissionNode("Model"),
        miles: permissionNode("Miles"),
        isVinDecoded: permissionNode("Is vin decoded")
      },
      tradeIn: {
        vin: permissionNode("Vin"),
        miles: permissionNode("Miles"),
        year: permissionNode("Year"),
        make: permissionNode("Make"),
        model: permissionNode("Model")
      },
      payoff: {
        payoff: permissionNode("Payoff"),
        payoffBank: permissionNode("Payoff Bank")
      },
      price: {
        price: permissionNode("Price"),
        totalCash: permissionNode("Total Cash"),
        tradeAllowance: permissionNode("Trade Allowance")
      },
      fees: {
        titleFees: permissionNode("Title/Transfer Fees"),
        docFee: permissionNode("Doc Fee")
      },
      taxes: {
        stateTax: permissionNode("State Tax")
      }
    },
    applicantId: permissionNode("Applicant id"),
    payoffBankId: permissionNode("Payoff Bank id"),
    dealershipId: permissionNode("Dealership id"),
    coApplicantId: permissionNode("Co applicant id"),
    dealership: relationPermissionNode("Dealership", "dealership"),
    payoffBank: relationPermissionNode("Payoff Bank", "payoff_bank"),
    user: relationPermissionNode("User", "wfd_user"),
    dealType: permissionNode("Deal type")
  }
};
