import { Dealership } from "components/Dealerships/types";
import { DealershipTicketNote } from "components/DealershipTicketNotes/types";
import { Note } from "components/Notes/types";
import { CrudEntity } from "utils/types";

export type DealershipTicket = CrudEntity & {
  data: FormState;
};

export type FormState = {
  dealershipTicketNotes: DealershipTicketNote[];
  notes: Note[];
  userId: string;
  dealership?: Dealership;
  dealershipId: string;
  info: Info;
};
export type Info = {
  description: string;
  url?: string;
  status: DealershipTicketStatus;
  user: {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
  };
  browserInfo: {
    browserName: string;
    browserVersion: string;
  };
  deviceInfo: {
    deviceType: string;
    osName: string;
    osVersion: string;
  };
};
export enum DealershipTicketStatus {
  NEW = "new",
  IN_PROGRESS = "in progress",
  RESOLVED = "resolved",
  CLOSED = "closed"
}

export type EditDealershipTicketRequest = CrudEntity & {
  data: Omit<FormState, "dealershipTicketNotes">;
};
