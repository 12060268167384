import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";
import { LenderNote } from "./types";

interface EditLenderNoteResponse {
  message: LenderNote;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  LenderNote,
  EditLenderNoteResponse
>("EditLenderNote", {
  request: "update_lender_note"
});

export const editLenderNote = socketAction;
export const editLenderNoteActions = socketMessageSlice.actions;
export const editLenderNoteError = socketMessageSlice.actions.error;

export default socketMessageSlice.reducer;
