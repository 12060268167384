import { Avatar, Box, Chip, IconButton, TableSortLabel, Tooltip } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { History, MoreHoriz, Warning } from "@material-ui/icons";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { AppDispatch } from "app/store";
import filtersContext from "components/Content/FiltersContext";
import formEditContext from "components/Content/FormEditContext";
import { dealsDragOrder } from "components/Deals/DealsLog/dealDragOrderSlice";
import { listDealActions } from "components/Deals/listDealSlice";
import { Deal } from "components/Deals/types";
import {
  ColumnFilter,
  DateFilters,
  ExistFilters,
  FilterQuery,
  Filters,
  RelationsFilters
} from "components/Filters/types";
import UploadLendingDecisions from "components/LenderDecisions/UploadLendingDecisions";
import TextLoop from "components/Loader/TextLoop";
import { ListState, Normalized, getListProps } from "components/Middlewares/listSliceCreator";
import { Permission } from "components/Roles/types";
import { User } from "components/Users/types";
import { HintTooltip } from "components/common/HintTooltip";
import { useSnackbar } from "notistack";
import React, { Dispatch, useContext, useEffect, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { EntityType } from "utils/entitySlice";
import { formatNumberAsCurrency, truncate } from "utils/functions";
import { CrudEntity } from "utils/types";
import { uuid } from "uuidv4";
import { RootState } from "../../app/rootReducer";
import formatDate from "../../utils/formatDate";
import ClearFilters from "../Filters/ClearFilters";
import CustomFilter from "../Filters/CustomFilters";
import AddButton from "./AddButton";
import AddButtonByDocument from "./AddButtonByDocument";
import DeleteButton from "./DeleteButton";
import ExportToExcelButton from "./ExportToExcelButton";
import OrderArrows from "./OrderArrows";
import Print from "./Print";
import RecoverButton from "./RecoverButton";
import Search from "./Search";
import ShowButton from "./ShowButton";
import TableSettings, { initializeColumnsSettingsData } from "./TableSettings/TableSettings";

export type CellValue = string | number | Date | undefined | JSX.Element | string[];

interface Changeable<T extends any> {
  target: T;
  shouldUpdate: (x: T) => boolean;
  shouldLoad: (x: T) => boolean;
}

type CustomRowEntity = {
  style: {};
  ref: (element?: HTMLElement | null | undefined) => any;
  "data-rbd-draggable-context-id": string;
  "data-rbd-draggable-id": string;
  onTransitionEnd?: React.TransitionEventHandler<any> | undefined;
};

export type PrevCurrNextDeals<T> = { prev?: Entry<T>; curr: Entry<T>; next?: Entry<T> };

type customBodyRender<T> = (
  x: CellValue,
  row: Entry<T>,
  rows?: PrevCurrNextDeals<T>
) => string | React.ReactElement | React.ReactElement[];

interface OptionsSort<T> {
  filter?: boolean;
  sort: boolean;
  path: string[];
  customBodyRender?: customBodyRender<T>;
}

interface OptionsNoSort<T> {
  filter?: boolean;
  sort?: never;
  path?: never;
  customBodyRender?: customBodyRender<T>;
}

type Options<T> = OptionsSort<T> | OptionsNoSort<T>;

export interface Column<T> {
  label: string;
  name: string;
  excelColumns?: string[];
  disableElipsis?: boolean;
  truncate?: number;
  bold?: (entry: Entry<T>) => boolean;
  getData: (el: Entry<T>, showHiddenValues?: boolean) => CellValue;
  options?: Options<T>;
  total?: <T>(entities: Entry<T>[], column: Column<T>) => string | Node;
  show?: (userPermissions?: Permission<EntityType>, selectedColumns?: any) => boolean;
  filters?: ColumnFilter<T>[];
  onHover?: (entry: Entry<T>) => string;
}

export interface Request {
  requestId: string;
  _id: string;
}

export type ListFunction = (
  _id: string,
  props: getListProps
) => (dispatch: AppDispatch) => Promise<void>;
type SortColumn = [number, "asc" | "desc"] | undefined;

export interface TableSettings {
  pagination: boolean;
  page: number;
  rowsPerPage: number;
  sort: { [x: string]: "asc" | "desc" };
  sortColumn: SortColumn;
  withDeleted: boolean;
  searchTerm: string;
}
export const createChangeable = <T extends any>(
  target: T,
  shouldUpdate: (x: T) => boolean,
  shouldLoad: (x: T) => boolean = (): boolean => false
): Changeable<T> => {
  return { target, shouldUpdate, shouldLoad };
};

const refreshTable = (
  settings: TableSettings,
  dispatch: Dispatch<any>,
  listFunction: ListFunction,
  filters: FilterQuery,
  query?: Record<string, unknown> | undefined,
  slice?: string | undefined,
  projection?: { [key: string]: number },
  aggregateFirst?: boolean
): void => {
  filters = filters.filter((filter) => filter);
  if (query?.["$or"]) {
    const { $or, ...rest } = query;
    query = {
      ...rest,
      ...{ $and: [...(filters ?? []), { $or }] }
    };
  } else {
    query = {
      ...query,
      ...(Array.isArray(filters) && filters.length > 0 ? { $and: filters } : {})
    };
  }

  dispatch(
    listFunction(slice ?? "table", {
      options: {
        projection,
        offset: settings.page * settings.rowsPerPage,
        limit: settings.rowsPerPage,

        ...(Object.keys(settings.sort).length > 0 ? { sort: settings.sort } : {})
      },
      withDeleted: settings.withDeleted,
      aggregateFirst,
      ...(settings.searchTerm !== "" ? { search: { term: settings.searchTerm } } : { query })
    })
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const useStylesTableSortLabel = makeStyles({
  root: {
    '&:hover:not([class*="active"])': {
      "& svg": {
        opacity: "0 !important"
      }
    }
  }
});

export type Entry<T> = CrudEntity & {
  data: T;
} & Normalized;
const renderEntryDataString = (value: CellValue, isReport: boolean): string => {
  if (React.isValidElement(value)) {
    return "";
  } else {
    return (renderEntryData(value, isReport) as unknown) as string;
  }
};
const renderEntryData = (
  value: CellValue,
  isReport: boolean,
  truncateLength?: number
): string | JSX.Element => {
  if (value instanceof Date) {
    return formatDate(value, "short");
  } else if (typeof value === "number" && isReport) {
    return formatNumberAsCurrency(value) ?? "0.00";
  } else if (React.isValidElement(value)) {
    return value;
  } else {
    return value && truncateLength
      ? truncate(value.toString(), truncateLength) ?? ""
      : value
      ? value.toString()
      : "";
  }
};
export type customEntity = {
  customRow: (attrs: CustomRowEntity) => JSX.Element;
};

const isCustomEntity = <T extends unknown>(
  x: Entry<T> | customEntity | undefined
): x is customEntity => typeof x === "object" && x?.hasOwnProperty("customRow");

const dealLogFilter = (deal: Deal) =>
  deal.data.info.type &&
  deal.data.info.status !== "lead" &&
  deal.data.info.status !== "credit check";

const checkEntityAvailabilityRoles = <T extends unknown>(entry: Entry<T>, user?: User) => {
  if (user && entry?.availableToRolesIds && entry?.availableToRolesIds?.length > 0) {
    return entry?.availableToRolesIds?.some((r) => user?.data?.rolesIds?.includes(r));
  }
  return true;
};
const filterEntries = <T extends unknown>(
  entries: (Entry<T> | customEntity)[] | undefined,
  tableSettings: TableSettings,
  renderTimestamp: number,
  user?: User,
  listEntity?: ListState<Entry<T> | customEntity>,
  modificationForNewEntries?: boolean
) =>
  entries
    ?.filter((entry) => {
      if (isCustomEntity(entry)) {
        return true;
      } else {
        return (!entry.deleted || tableSettings.withDeleted) && entry?.createdAt
          ? (renderTimestamp > new Date(entry.createdAt).getTime() || !modificationForNewEntries) &&
              checkEntityAvailabilityRoles(entry, user)
          : true;
      }
    })
    ?.map((entry, index) => ({
      prev: listEntity?.entities?.[index - 1],
      curr: entry,
      next: listEntity?.entities?.[index + 1]
    }));

const clearEntries = <T extends unknown>(entries: {
  prev: customEntity | Entry<T> | undefined;
  curr: Entry<T>;
  next: customEntity | Entry<T> | undefined;
}): {
  prev: Entry<T> | undefined;
  curr: Entry<T>;
  next: Entry<T> | undefined;
} => ({
  prev: isCustomEntity(entries.prev) ? undefined : entries.prev,
  curr: entries.curr,
  next: isCustomEntity(entries.next) ? undefined : entries.next
});

type FilteredEntities<T> = {
  prev: customEntity | Entry<T> | undefined;
  curr: customEntity | Entry<T>;
  next: customEntity | Entry<T> | undefined;
}[];

export default <T extends unknown>({
  setResetStickyCallback,
  showHiddenValues,
  tableName,
  title,
  columns,
  entityName,
  showModel,
  modificationForNewEntries = true,
  showUpload = false,
  showTotalFooter = false,
  isReport = false,
  hideShowButton = false,
  openShowEditInModal = false,
  hideSearch = false,
  hideCalendar = false,
  hideDeleteButton = false,
  hideAddButton = false,
  hideAddButtonByDocument = true,
  hideRecoverButton = false,
  hideExcelExportButton = false,
  hidePagination = false,
  hidePrintButton = false,
  hideFilters = false,
  pagination = true,
  withOrder = false,
  orderSlice = undefined,
  aggregateFirst = false,
  query,
  toolbarStyle,
  toolbarVariant = "regular",
  customCellFontSize,
  customActionsStyle = {},
  slice,
  listEntity,
  listFunction,
  deleteEntityFunction,
  addEntityFunction,
  addEntityFromDocumentFunction,
  recoverEntityFunction,
  editAction = undefined,
  elevation = 3,
  sort = { createdAt: "desc" },
  rowsPerPage,
  hideColumns,
  parentId = "",
  dateFilters = [
    { label: "Created At", path: ["createdAt"] },
    { label: "Updated At", path: ["updatedAt"] }
  ],
  relationFilters = [],
  existFilters = {
    filters: [],
    name: ""
  },
  projection,
  dealLogTable = false,
  externalRenderTime,
  renewMode = false,
  showApprovedFilter = false,
  isDraggable = false,
  hideSettings = false,
  showAdditionalColumns = false,
  tableContainerOverflowX = "inherit",
  showToolbar = true
}: {
  tableName: string;
  title: string | React.ReactElement;
  entityName: EntityType;
  columns: Column<T>[];
  hideColumns?: string[];
  listEntity?: ListState<Entry<T> | customEntity>;
  modificationForNewEntries?: boolean;
  hideShowButton?: boolean;
  hideDeleteButton?: boolean;
  hideAddButton?: boolean;
  hideAddButtonByDocument?: boolean;
  hideRecoverButton?: boolean;
  openShowEditInModal?: boolean;
  hideSearch?: boolean;
  hideCalendar?: boolean;
  hidePagination?: boolean;
  aggregateFirst?: boolean;
  hidePrintButton?: boolean;
  hideFilters?: boolean;
  showModel?: undefined | string;
  showUpload?: boolean | string;
  showTotalFooter?: boolean;
  isReport?: boolean;
  pagination?: boolean;
  withOrder?: boolean;
  orderSlice?: (request: any) => (dispatch: AppDispatch) => Promise<void>;
  query?: Record<string, unknown>;
  toolbarStyle?: Record<string, unknown>;
  toolbarVariant?: "regular" | "dense";
  customCellFontSize?: string;
  customActionsStyle?: React.CSSProperties;
  elevation?: number;
  slice?: string;
  sort?: { [_key: string]: "desc" | "asc" };
  rowsPerPage?: number;
  setResetStickyCallback?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
  parentId?: string;
  listFunction: ListFunction;
  editAction?: (entry: Entry<T>) => void;
  addAction?: () => void;
  deleteEntityFunction?: (request: Request) => (dispatch: AppDispatch) => Promise<void>;
  addEntityFunction?: () => void;
  addEntityFromDocumentFunction?: (state: object) => void;
  recoverEntityFunction?: (request: Request) => (dispatch: AppDispatch) => Promise<void>;
  hideExcelExportButton?: boolean;
  dateFilters?: DateFilters<T>;
  relationFilters?: RelationsFilters<T>;
  existFilters?: ExistFilters<T>;
  projection?: { [key: string]: number };
  dealLogTable?: boolean;
  externalRenderTime?: number;
  renewMode?: boolean;
  showApprovedFilter?: boolean;
  isDraggable?: boolean;
  hideSettings?: boolean;
  showAdditionalColumns?: boolean;
  showHiddenValues?: boolean;
  showToolbar?: boolean;
  tableContainerOverflowX?: "inherit" | "scroll" | "auto" | "clip" | "hidden" | "visible";
}): JSX.Element => {
  const user = useSelector((state: RootState) => state?.authSlice?.user);
  const tableColumnSettings = useSelector((state: RootState) =>
    state.listTableSettingsSlice["table"]?.entities?.find(
      (setting) => setting?.data?.tableName === tableName
    )
  );
  const userPermissions = user?.permissions?.[entityName];
  const { enqueueSnackbar } = useSnackbar();
  const { enabled: editMode } = useContext(formEditContext);
  const [renderTimestamp, setRenderTimestamp] = useState(new Date().getTime());
  const listWithoutCustomEntities = {
    total: listEntity?.total ?? 0,
    entities: listEntity?.entities?.filter((el): el is Entry<T> => !isCustomEntity<T>(el))
  };
  const hiddenEntries = modificationForNewEntries
    ? listWithoutCustomEntities?.entities?.filter((el) => {
        return (
          new Date(el.createdAt).getTime() > renderTimestamp &&
          (dealLogTable ? dealLogFilter(el as Deal) : true)
        );
      })
    : [] ?? [];

  const [tableSettings, setTableSettingsState] = useState<TableSettings>({
    pagination: pagination,
    page: 0,
    rowsPerPage: rowsPerPage ?? 50,
    sort: sort ?? { createdAt: "desc" },
    sortColumn: undefined,
    withDeleted: false,
    searchTerm: ""
  });
  const { filters: allFilters, setFilters: setFiltersState } = React.useContext(filtersContext);
  const filters = allFilters[tableName];

  const setTableSettings = (newState: TableSettings) => {
    setTableSettingsState(newState);
    refreshTableCallback(filtersToQuery(filters), newState);
  };

  const dispatch = useDispatch();

  const setFilters = (
    state: (Filters | undefined) | ((oldState: Filters | undefined) => Filters | undefined)
  ) => {
    const newState = typeof state === "function" ? state(filters) : state;
    setFiltersState((old) => ({ ...old, [tableName]: newState ?? {} }));

    refreshTableCallback(filtersToQuery(newState), tableSettings);
  };

  const filtersToQuery = (filters: Filters | undefined): FilterQuery => {
    return Object.values(filters ?? {}).reduce((acc, filter) => {
      if (Array.isArray(filter)) {
        return [
          ...acc,
          ...filter.reduce((acc: FilterQuery, x) => [...acc, ...(x ? [x.query] : [])], [])
        ];
      }
      return acc;
    }, [] as FilterQuery);
  };
  const columnsFiltered = React.useCallback(() => {
    return columns.filter((c) =>
      c.show
        ? c.show(userPermissions, tableColumnSettings ?? initializeColumnsSettingsData(columns)) &&
          !hideColumns?.includes(c.name)
        : true
    );
  }, [userPermissions, tableColumnSettings, filters, showAdditionalColumns, hideColumns]);
  const [queryState, setQueryState] = useState(query);

  useEffect(() => {
    refreshTableCallback(filtersToQuery(filters), tableSettings);
  }, [filters]);

  useEffect(() => {
    if (JSON.stringify(queryState) !== JSON.stringify(query)) {
      setQueryState(query);
      refreshTableCallback(filtersToQuery(filters), tableSettings, query);
    }
  }, [query, queryState]);
  useEffect(() => {
    if (externalRenderTime && externalRenderTime !== renderTimestamp) {
      setRenderTimestamp(externalRenderTime);
    }
  }, [externalRenderTime, renderTimestamp, setRenderTimestamp]);

  useEffect(() => {
    if (listEntity?.entities === undefined) {
      refreshTable(
        tableSettings,
        dispatch,
        listFunction,
        filtersToQuery(filters),
        queryState,
        slice,
        projection,
        aggregateFirst
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  const tableSortLabelClass = useStylesTableSortLabel();

  const tableRef = useRef(null);

  const handleChangePage = (_: unknown, newPage: number): void => {
    setTableSettings({ ...tableSettings, page: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTableSettings({ ...tableSettings, page: 0, rowsPerPage: parseInt(event.target.value) });
  };
  const [refreshTimeout, setRefreshTimeoutState] = useState<NodeJS.Timeout | undefined>(undefined);
  const refreshTableCallback = (
    filters: FilterQuery,
    tableSettings: TableSettings,
    query: any = undefined
  ): void => {
    if (refreshTimeout) clearTimeout(refreshTimeout);
    setRefreshTimeoutState(
      setTimeout(() => {
        refreshTable(
          tableSettings,
          dispatch,
          listFunction,
          filters,
          query ?? queryState,
          slice,
          projection,
          aggregateFirst
        );
      }, 200)
    );
  };

  const actionsCell = (entry: Entry<T>): JSX.Element => (
    <TableCell
      key="actions"
      size="small"
      style={{ whiteSpace: "nowrap", textAlign: "right", paddingRight: 0 }}
    >
      <Box displayPrint="none" style={customActionsStyle}>
        {editAction !== undefined ? (
          <IconButton
            id={entityName + "-" + entry._id}
            size="small"
            color="primary"
            aria-label="show more"
            style={{ marginRight: "10px" }}
            onClick={(): void => editAction(entry)}
            disabled={!editMode}
          >
            <MoreHoriz />
          </IconButton>
        ) : hideShowButton ? null : (
          <ShowButton
            renewMode={renewMode}
            entityName={entityName}
            _id={entry._id}
            showModel={showModel}
            openShowEditInModal={openShowEditInModal}
          />
        )}

        {hideDeleteButton || editMode === false ? null : (
          <DeleteButton
            entityName={entityName}
            _id={entry._id}
            deleteEntityFunction={deleteEntityFunction}
            callback={() => refreshTableCallback(filtersToQuery(filters), tableSettings)}
          />
        )}
      </Box>
    </TableCell>
  );

  const recoverCell = (_id: string): JSX.Element => (
    <TableCell key="actions" size="small" style={{ whiteSpace: "nowrap", textAlign: "right" }}>
      <Box displayPrint="none">
        {hideShowButton ? null : (
          <ShowButton entityName={entityName} _id={_id} showModel={showModel} />
        )}
        {hideRecoverButton ? null : (
          <RecoverButton _id={_id} recoverEntityFunction={recoverEntityFunction} />
        )}
      </Box>
    </TableCell>
  );
  const approvedCell = (entity?: Entry<T>): JSX.Element => (
    <TableCell key="approved" size="small" style={{ whiteSpace: "nowrap", textAlign: "left" }}>
      <Box displayPrint="none">
        {entity?.approved ? (
          <>{`${entity?.approvedByUser?.data?.info.firstName ?? ""} ${
            entity?.approvedByUser?.data?.info.lastName ?? ""
          }`}</>
        ) : (
          <>Not approved</>
        )}
      </Box>
    </TableCell>
  );

  const sortByColumn = (path: string[] | undefined, newSort = {}): void => {
    if (path !== undefined) {
      const key = path.join(".");

      if (tableSettings.sort[key] === undefined) {
        newSort = { [key]: "asc" };
      } else if (tableSettings.sort[key] === "asc") {
        newSort = { [key]: "desc" };
      } else if (tableSettings.sort[key] === "desc") {
        newSort = sort ?? { createdAt: "desc" };
      }
      setTableSettings({ ...tableSettings, sort: newSort });
    }
  };

  const removeKey = (key: string, { [key]: _, ...rest }): Record<string, unknown> => rest;
  const [requestId] = useState(uuid());

  const orderActionState = useSelector(
    // @ts-ignore
    (state: RootState) => state?.[`${entityName}OrderSlice`]?.[requestId]
  );

  useEffect(() => {
    if (orderActionState?.status === "success") {
      refreshTable(
        tableSettings,
        dispatch,
        listFunction,
        filtersToQuery(filters),
        queryState,
        slice,
        projection,
        aggregateFirst
      );
      enqueueSnackbar("Successfully reordered!", { variant: "success" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderActionState]);

  const onDragEnd = (result: DropResult, entities: any[] | undefined) => {
    if (!result.destination) {
      return;
    }

    if (entities) {
      const rows = [...entities];
      const reorderedRows = reorderDropDragItems(
        rows,
        result.source.index,
        result.destination.index
      )
        ?.map((value, index) => {
          return {
            dealId: value?.curr?._id,
            order:
              rows[index]?.curr?.data?.order &&
              rows[index].curr?.data?.order !== value?.curr?.data?.order
                ? rows[index]?.curr?.data?.order
                : undefined
          };
        })
        .filter((e) => e.order && e.dealId);

      if (reorderedRows) {
        const updateOperations = reorderedRows?.map((reorderedRow) => ({
          _id: reorderedRow.dealId,
          order: reorderedRow.order,
          updateFunction: (deal: any) => {
            return { ...deal, data: { ...deal.data, order: reorderedRow.order } };
          }
        }));
        dispatch(
          listDealActions.updateManyPartial({
            listId: slice,
            updateOperations: updateOperations,
            sortFunction: (prev: any, curr: any) => prev.data.order - curr.data.order
          })
        );
      }

      dispatch(
        dealsDragOrder({
          requestId,
          data: reorderedRows
        })
      );
    }

    return;
  };

  const reorderDropDragItems = (
    list: any[] | undefined,
    startIndex: number,
    endIndex: number
  ): any[] | undefined => {
    if (list) {
      const result = [...list];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    }
  };
  const filteredEntities: FilteredEntities<T> = (filterEntries(
    listEntity?.entities,
    tableSettings,
    renderTimestamp,
    user?.databaseData,
    listEntity,
    modificationForNewEntries
  ) as unknown) as FilteredEntities<T>;
  const handleResetStickyState = () => {
    setFiltersState((old) => ({ ...old, [tableName]: {} }));
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, []);

  return (
    <Paper
      className={classes.root}
      style={{ tableLayout: "fixed", display: "table" }}
      elevation={elevation}
    >
      {showToolbar ? (
        <Toolbar variant={toolbarVariant} style={toolbarStyle}>
          <Typography variant="body1" style={{ flex: "1 1 100%" }} component="div">
            {title}
          </Typography>
          {hiddenEntries && hiddenEntries?.length > 0 && (
            <Chip
              color="primary"
              avatar={<Avatar>{hiddenEntries?.length}</Avatar>}
              label="New entries"
              id="new entries button"
              onClick={(): void => {
                // refreshTableCallback();
                setRenderTimestamp(new Date().getTime());
              }}
            />
          )}
          {!hideSearch && (
            <Search setTableSettings={setTableSettings} tableSettings={tableSettings} />
          )}
          {(relationFilters.length > 0 ||
            dateFilters?.length > 0 ||
            existFilters?.filters?.length > 0) &&
            !hideFilters && (
              <ClearFilters
                setFilters={setFilters}
                filtersCount={
                  Object.values(filters ?? {})?.filter((x) => x.filter((x) => x)?.length > 0)
                    ?.length
                }
              />
            )}
          {listEntity && !hidePrintButton && (
            <Print
              tableRef={tableRef}
              entityName={entityName}
              listEntity={listWithoutCustomEntities}
            />
          )}
          {!hideRecoverButton && (
            <HintTooltip title={tableSettings.withDeleted ? "Hide deleted" : "Show deleted"}>
              <IconButton
                onClick={(): void => {
                  setTableSettings({
                    ...tableSettings,
                    withDeleted: !tableSettings.withDeleted
                  });
                }}
                aria-label={`Recover ${entityName}`}
              >
                <History />
              </IconButton>
            </HintTooltip>
          )}
          {!hideExcelExportButton && (
            <ExportToExcelButton
              entityName={entityName}
              entries={listWithoutCustomEntities?.entities ?? []}
              columns={columnsFiltered()}
            />
          )}
          {showUpload && <UploadLendingDecisions />}
          {hideAddButton ? null : (
            <AddButton entityName={entityName} addEntityFunction={addEntityFunction} />
          )}
          {hideAddButtonByDocument
            ? null
            : addEntityFromDocumentFunction &&
              entityName !== "sequence" && (
                <AddButtonByDocument
                  entityName={entityName}
                  addEntityFromDocumentFunction={addEntityFromDocumentFunction}
                />
              )}
          {!hideSettings && <TableSettings<T> {...{ tableName, columns }} />}
        </Toolbar>
      ) : null}
      <TableContainer
        style={{ overflowX: tableContainerOverflowX, overflowY: "auto", display: "block" }}
        ref={tableRef}
      >
        <Table size="small">
          {React.useMemo(
            () => (
              <TableHead style={{ background: "#f1f1f1" }}>
                <TableRow>
                  {columnsFiltered()?.map((column, index) => {
                    return column.label === "Actions" ? (
                      <TableCell
                        key={column.label + index}
                        style={{
                          whiteSpace: "nowrap",
                          textAlign: "center",
                          border: "1px solid #76767673",
                          paddingRight: 0
                        }}
                      >
                        <Box displayPrint="none">{column.label}</Box>
                      </TableCell>
                    ) : (
                      <TableCell
                        key={column.label + index}
                        size="small"
                        style={{
                          whiteSpace: "nowrap",
                          border: "1px solid #76767673",
                          position: "relative",
                          overflow: "hidden"
                        }}
                      >
                        {column.options?.sort ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                          >
                            <TableSortLabel
                              className={tableSortLabelClass.root}
                              active={
                                tableSettings.sort &&
                                tableSettings.sort[column.options?.path.join(".")] !== undefined
                              }
                              hideSortIcon={true}
                              direction={
                                tableSettings.sort &&
                                tableSettings.sort[column.options?.path.join(".")]
                              }
                              onClick={(): void => {
                                sortByColumn(column.options?.path);
                              }}
                            >
                              {column.label}
                            </TableSortLabel>
                            {column.filters && (
                              <CustomFilter
                                setFilters={(filters: Filters[string]) => {
                                  return setFilters((x) => ({ ...x, [column.name]: filters }));
                                }}
                                columnFilters={column.filters}
                                label={column?.label}
                                filters={filters?.[column.name]}
                              />
                            )}
                          </div>
                        ) : column.filters ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                          >
                            <Box displayPrint="none">{column.label}</Box>
                            {column.filters && (
                              <CustomFilter
                                setFilters={(filters: Filters[string]) =>
                                  setFilters((x) => ({ ...x, [column.name]: filters }))
                                }
                                columnFilters={column.filters}
                                label={column?.label}
                                filters={filters?.[column.name]}
                              />
                            )}
                          </div>
                        ) : (
                          <span>{column.label}</span>
                        )}
                      </TableCell>
                    );
                  })}
                  {withOrder && (
                    <TableCell style={{ width: "30px", border: "1px solid #76767673" }}></TableCell>
                  )}
                </TableRow>
              </TableHead>
            ),
            [columnsFiltered, tableColumnSettings, tableSettings, columns]
          )}
          {listEntity?.status === "waiting" ? (
            <tr>
              <td style={{ textAlign: "center" }} colSpan={columnsFiltered()?.length}>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    position: "relative"
                  }}
                >
                  <TextLoop style={{ fontSize: "28px" }} />
                </div>
              </td>
            </tr>
          ) : filteredEntities?.length === 0 ? (
            <tr>
              <td
                style={{ textAlign: "center", fontSize: "20px" }}
                colSpan={columnsFiltered()?.length}
              >
                No records found!
              </td>
            </tr>
          ) : (
            (filteredEntities?.reduce((acc, cur) => {
              if (isCustomEntity(cur.curr) || isCustomEntity(cur.prev)) {
                return [...acc, [cur]];
              }
              if (acc[acc.length - 1]) {
                acc[acc.length - 1] = [...acc[acc.length - 1], cur];
              } else {
                return [...acc, [cur]];
              }
              return acc;
            }, [] as FilteredEntities<T>[]) as FilteredEntities<T>[])?.map((entites, index) => (
              <DragDropContext
                key={`droppable-${index}`}
                onDragEnd={(result) => onDragEnd(result, entites)}
              >
                <Droppable
                  type={`droppable-${index}`}
                  droppableId={`droppable-${index}`}
                  direction="vertical"
                  isCombineEnabled={false}
                >
                  {(droppableProvided: DroppableProvided) => (
                    <TableBody
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {!entites && (
                        <TableRow key={"loading-row"}>
                          <TableCell
                            colSpan={columnsFiltered.length}
                            style={{
                              textAlign: "center",
                              fontSize: "20px"
                            }}
                            key={"loading-row"}
                          >
                            Loading...
                          </TableCell>
                        </TableRow>
                      )}
                      {entites?.map((entries, index) => {
                        const entry = entries.curr;
                        if (isCustomEntity(entry)) {
                          return (
                            <Draggable
                              key={index}
                              draggableId={index.toString()}
                              index={index}
                              isDragDisabled={true}
                            >
                              {(
                                draggableProvided: DraggableProvided,
                                snapshot: DraggableStateSnapshot
                              ) => {
                                return entry.customRow({
                                  ...draggableProvided.draggableProps,
                                  style: {
                                    ...draggableProvided.draggableProps.style
                                    //transform: "none !important"
                                  },
                                  ref: draggableProvided.innerRef
                                });
                              }}
                            </Draggable>
                          );
                        }

                        const clearedEntries = clearEntries({ ...entries, curr: entry });
                        return (
                          <Draggable key={entry._id} draggableId={entry._id} index={index}>
                            {(
                              draggableProvided: DraggableProvided,
                              snapshot: DraggableStateSnapshot
                            ) => {
                              return (
                                <TableRow
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={entry._id}
                                  style={
                                    entry.deleted
                                      ? { background: "rgba(245, 0, 85, 0.16)" }
                                      : {
                                          ...draggableProvided.draggableProps.style,
                                          ...(index % 2 === 1
                                            ? {
                                                boxShadow:
                                                  "inset 0px 0px 1000000000px 100px rgba(0,0,0,0.05)"
                                              }
                                            : {}),
                                          background: snapshot.isDragging
                                            ? "rgba(245,245,245, 0.75)"
                                            : undefined,
                                          ...(snapshot.isDragging
                                            ? {
                                                display: "table"
                                              }
                                            : {})
                                        }
                                  }
                                >
                                  {columnsFiltered().map((column, index) => {
                                    if (typeof column.options?.customBodyRender === "function") {
                                      return (
                                        <TableCell key={entry._id + index} size="small">
                                          {column.options.customBodyRender(
                                            column.getData(entry, showHiddenValues),
                                            entry,
                                            clearedEntries
                                          )}
                                        </TableCell>
                                      );
                                    }

                                    return column.label === "Actions" ? (
                                      entry.deleted ? (
                                        recoverCell(entry._id)
                                      ) : (
                                        actionsCell(entry)
                                      )
                                    ) : column.label === "Approver" ? (
                                      approvedCell(entry)
                                    ) : (
                                      <TableCell
                                        style={{
                                          fontSize: customCellFontSize,
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          ...(column.disableElipsis
                                            ? {}
                                            : { textOverflow: "ellipsis", maxWidth: 150 })
                                        }}
                                        title={renderEntryDataString(
                                          column.onHover && column.onHover(entry)
                                            ? column.onHover(entry)
                                            : column.getData(entry, showHiddenValues),
                                          isReport
                                        )}
                                        key={entry._id + index}
                                        size="small"
                                      >
                                        {column.bold && column.bold(entry) ? (
                                          <b>
                                            {renderEntryData(
                                              column.getData(entry, showHiddenValues),
                                              isReport,
                                              column?.truncate
                                            )}
                                          </b>
                                        ) : (
                                          renderEntryData(
                                            column.getData(entry, showHiddenValues),
                                            isReport,
                                            column?.truncate
                                          )
                                        )}
                                      </TableCell>
                                    );
                                  })}

                                  {withOrder && orderSlice && (
                                    <TableCell key={entry._id + "order"} size="small">
                                      {(entry?.data as any)?.order ? (
                                        clearedEntries?.next?._id || clearedEntries?.prev?._id ? (
                                          <div
                                            {...draggableProvided.dragHandleProps}
                                            style={{ marginTop: "5px" }}
                                          >
                                            <HintTooltip
                                              title={`Click here to move the ${entityName}.`}
                                            >
                                              <DragIndicatorIcon />
                                            </HintTooltip>
                                          </div>
                                        ) : null
                                      ) : (
                                        <Tooltip title="Ordering is not working for this entry! Contact system administrator to fix the problem!">
                                          <Warning style={{ color: "#eed202" }} />
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            ))
          )}

          {showTotalFooter && listEntity?.entities && (
            <TableFooter>
              <TableRow>
                {columnsFiltered()?.map((column, index) => {
                  return (
                    <TableCell
                      style={{ color: "black", fontWeight: "bold", fontSize: "14px" }}
                      key={index}
                    >
                      {column?.total &&
                        column.total(listWithoutCustomEntities.entities ?? [], column)}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {!hidePagination && filteredEntities?.length !== 0 && (
        <TablePagination
          rowsPerPageOptions={[50, 200, 400, 800, listEntity?.total || 0].filter(
            (el) => el <= (listEntity ? listEntity?.total : 200)
          )}
          component="div"
          count={listEntity?.total || 0}
          rowsPerPage={tableSettings.rowsPerPage}
          page={tableSettings.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};
