import { Box } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Add } from "@material-ui/icons";
import AccessControl from "components/Access/AccessControl";
import { HintTooltip } from "components/common/HintTooltip";
import { TabContext } from "components/Layout/LayoutWrapper";
import React, { useContext } from "react";
import { EntityType } from "utils/entitySlice";

export default ({
  entityName,
  addEntityFunction
}: {
  entityName: EntityType;
  addEntityFunction?: () => void;
}) => {
  const createOrFocusTab = useContext(TabContext);
  return (
    <AccessControl
      key={`add-${entityName}-button`}
      requiredPermissions={{ entity: entityName, action: "create" }}
    >
      <Box component="span">
        <HintTooltip title="Add new entry">
          <IconButton
            aria-label={`add-${entityName}`}
            id={`add-${entityName}`}
            onClick={
              addEntityFunction ??
              (() =>
                createOrFocusTab({
                  label: "Add page",
                  index: "addPage",
                  isForSidebar: false,
                  isForQuickAccess: false,
                  isPersistent: false,
                  props: { type: entityName }
                }))
            }
          >
            <Add />
          </IconButton>
        </HintTooltip>
      </Box>
    </AccessControl>
  );
};
