import { CreditScore } from "../Deals/types";
import React from "react";
import { Paper, IconButton, Zoom } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import VisibilityIcon from "@material-ui/icons/Visibility";
import toNormalCase from "utils/toNormalCase";
import { HintTooltip } from "components/common/HintTooltip";

export const CreditScoreView = ({
  creditScores,
  formatScores,
  handleShowDialog
}: {
  creditScores: CreditScore[];
  formatScores: (el: CreditScore | undefined) => string;
  handleShowDialog: (url: string) => void;
}) => {
  return (
    <>
      {creditScores?.map((creditScore, index) => (
        <Paper
          elevation={3}
          key={index}
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "14px"
          }}
        >
          <div style={{ marginLeft: "5px" }}>{toNormalCase(creditScore.type)}</div>
          <div>{formatScores(creditScore)}</div>
          <div>
            {creditScore.isDuplicate && (
              <HintTooltip
                title={
                  <span dangerouslySetInnerHTML={{ __html: creditScore.duplicateInfo as string }} />
                }
                style={{ marginLeft: "5px" }}
                arrow
                TransitionComponent={Zoom}
              >
                <IconButton id="warning-score-icon" aria-label="warning" disableRipple={true}>
                  <WarningIcon style={{ color: "#eed202" }} />
                </IconButton>
              </HintTooltip>
            )}
            <IconButton
              id="show-score-icon"
              style={{ marginRight: "5px" }}
              color="primary"
              onClick={() => handleShowDialog(creditScore.url)}
              disabled={creditScore.url === undefined || creditScore.url === null}
            >
              <VisibilityIcon />
            </IconButton>
          </div>
        </Paper>
      ))}
    </>
  );
};
