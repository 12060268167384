import { createListSlice } from "../../Middlewares/listSliceCreator";
import { Chargeback } from "../types";

const { ListSlice, getList } = createListSlice<Chargeback>({
  name: "ChargebackBillsList",
  request_topic: "all_chargeback_bills",
  reducers: {}
});

export default ListSlice.reducer;
export const getChargebackBillsList = getList;
export const listChargebackBillsActions = ListSlice.actions;
export const removeChargebackBillsList = ListSlice.actions.removeList;
