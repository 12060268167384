import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { addBusinessReport } from "./addBussinesReportSlice";

import {
  generateForm,
  getByPath,
  setByPath,
  StateAccess,
  generateDefault,
  fillDefaultsByPath
} from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { businessStruct } from "./model";
import modifyBusinessReport from "./modifyBusinessReport";
import { RootState } from "app/rootReducer";
export default function () {
  const [requestId] = uuidv4();
  const dispatch = useDispatch();
  const settings = useSelector((state: RootState) => state.listSettingsSlice["settingsData"])
    ?.entities?.[0];

  const [state, setState] = useState(
    generateDefault(businessStruct(settings), {}, fillDefaultsByPath as any) as any
  );

  const stateAccess: StateAccess = {
    get: (path) => getByPath(state as any, path),
    set: (path, value): any =>
      setState(modifyBusinessReport(setByPath(state as any, path, value), state))
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(addBusinessReport({ requestId, ...(state as any) }));
  };

  return (
    <Grid
      style={{ margin: "10px" }}
      item
      container
      xs={12}
      key={`${state?.data?.info?.request?.type}}`}
    >
      <Grid xs={12} item>
        <form id="report-form" onSubmit={handleSubmit}>
          {generateForm(
            businessStruct(settings),
            stateAccess,
            [],
            stateAccess,
            editRenderSet(false)
          )}
        </form>
      </Grid>

      <Grid
        xs={12}
        style={{
          justifyContent: "flex-end",
          margin: "10px",
          alignItems: "end",
          display: "flex"
        }}
        item
      >
        <Button type="submit" variant="contained" color="primary" form="report-form">
          Request a report
        </Button>
      </Grid>
    </Grid>
  );
}
