import infoProperties from "./info_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

const unrequitedExternalId = { type: "string", nullable: true };

const data = {
  type: "object",
  required: ["info", "dealershipId"],
  properties: {
    dealershipId: { type: "string" },
    info: { type: "object", properties: infoProperties },
    applicantId: unrequitedExternalId,
    coApplicantId: unrequitedExternalId,
  },
};

export default {
  new_external_credit_application: {
    $id: "newExternalCreditApplication",
    type: "object",
    required: ["data"],
    properties: {
      captchaToken: { type: "string", nullable: true },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_external_credit_application: {
    $id: "updateExternalCreditApplication",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      approved: {
        type: "boolean",
        nullable: true,
      },
      approvedAt: {
        type: "string",
        nullable: true,
      },
      approvedByUserId: {
        type: "string",
        nullable: true,
      },
    },
  },
  delete_external_credit_application: {
    $id: "deleteExternalCreditApplication",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  recover_external_credit_application: {
    $id: "recoverExternalCreditApplication",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_external_credit_application: {
    $id: "getExternalCreditApplication",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_external_credit_applications: {
    $id: "allExternalCreditApplications",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
