import { useSnackbar } from "notistack";
import { useState } from "react";
const isSupported = () =>
  "Notification" in window && "serviceWorker" in navigator && "PushManager" in window;

export function useBrowserNotifications() {
  const internalNotificationAPI = isSupported() ? Notification : undefined;
  const [permission, setPermission] = useState(internalNotificationAPI?.permission);
  const { enqueueSnackbar } = useSnackbar();
  const requestPermission = () =>
    internalNotificationAPI?.requestPermission().then((permission) => {
      setPermission(permission);
    });

  const playNotificationSound = () => {
    const audio = new Audio(`${process.env.PUBLIC_URL}/notification.mp3`);
    audio.volume = 1;
    return setInterval(() => {
      audio.play();
    }, 100);
  };
  const showNotification = (title: string, options?: NotificationOptions) => {
    const interval = playNotificationSound();
    enqueueSnackbar(title, {
      variant: "warning",
      persist: true,
      onClose: () => {
        clearInterval(interval);
      }
    });
  };

  const requestBrowserNotificationPermissions = () => {
    if (internalNotificationAPI?.permission === "default") {
      requestPermission();
    }
  };

  return { permission, requestBrowserNotificationPermissions, showNotification };
}
