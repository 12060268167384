import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { ManagerProgram } from "./types";
interface DeleteManagerProgramRequest {
  _id: string;
}

interface DeleteManagerProgramResponse {
  message: ManagerProgram;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteManagerProgramRequest,
  DeleteManagerProgramResponse
>("DeleteManagerProgram", {
  request: "delete_manager_program"
});

export const deleteManagerProgramActions = socketMessageSlice.actions;
export const deleteManagerProgram = socketAction;
export default socketMessageSlice.reducer;
