import React from "react";
import { RootState } from "app/rootReducer";
import { useSelector } from "react-redux";
import Spinner from "./Spinner";

export default function Loader() {
  const { loaderSlice } = useSelector((state: RootState) => state);

  return loaderSlice.waiting ? <Spinner /> : null;
}
