import { LenderTicketType } from "./types";
import { Model } from "../../utils/models/fields";

export const lenderTicketTypeModel: Model<LenderTicketType> = {
  formComponent: "model",
  schema: "new_lender_ticket_type",
  name: "lender_ticket_type",
  entities: [
    {
      formComponent: "segment",
      name: "Lender Ticket Type",
      entities: [
        {
          formComponent: "text-field",
          name: "name",
          label: "Name",
          width: "full",
          path: ["data", "info", "type"],
          required: true,
          default: null
        }
      ]
    }
  ]
};
