import { Checklist } from "components/Deals/Checklist/types";
import { GenerateContractForPrintResponse } from "components/Deals/Documents/generateContractForPrintSlice";
import {
  DocumentsTypesLiterals,
  GenerateContractCoordinates,
  GenerateContractFile,
  ModalDocuments
} from "components/Deals/Documents/types";
import { disaggregateDeal } from "components/Deals/editDealSlice";
import { editDealStruct } from "components/Deals/model";
import { Deal } from "components/Deals/types";
import { VirtualFields, modelToVirtualMap } from "components/DocumentTemplates/DocumentTemplates";
import { Coordinates } from "components/DocumentTemplates/types";
import ZipCode from "components/ZipCodeLookup/ZipCode";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import printJS from "print-js-updated";
import React from "react";
import { generateContractForPrint } from "utils/httpRequestsLibrary/generateContractForPrintRequests";
import { addGenerateContract } from "utils/httpRequestsLibrary/generateContractRequests";
import { notifyWebhooks } from "utils/logging";
import { StateAccess, getByPath } from "utils/models/formGenerator";
import { capitalize, formatNumberAsCurrency } from "../../utils/functions";

export const clearPhone = (phone: string) => {
  const newPhone = phone.replace(/\D/g, "");

  if (newPhone.length === 11 && newPhone[0] === "1") {
    return newPhone.slice(1);
  }

  return newPhone;
};
const transformCoordinatesWithValues = (
  virtualFields: VirtualFields,
  stateAccess: StateAccess,
  deal: Deal,
  coordinates?: Coordinates[]
): GenerateContractCoordinates[] =>
  (coordinates ?? []).reduce((acc, draggable) => {
    const dealFromState = stateAccess.get([]);
    dealFromState.data.lender = deal.data.lender;
    if (draggable.x < 775) {
      const value = displayData(dealFromState, draggable, (deal: Deal, draggable: any) =>
        showData(deal, draggable, virtualFields)
      );
      const valueToBeAdded = value !== null && typeof value === "object" ? " " : value;

      return [
        ...acc,
        {
          ...draggable,
          value: valueToBeAdded
        }
      ];
    }
    return acc;
  }, [] as GenerateContractCoordinates[]);
const documentsToGenerateContractsRequest = (
  selectedDocuments: ModalDocuments,
  deal: Deal,
  virtualFields: VirtualFields,
  stateAccess: StateAccess,
  checklistDealStateAccess?: StateAccess
) => {
  return selectedDocuments.reduce((acc: GenerateContractFile[], document) => {
    switch (document.type) {
      case DocumentsTypesLiterals.document_template:
        if (document?.documentData?.data?.files) {
          const allFiles: GenerateContractFile[] = document.documentData.data.files
            .filter((file) => file.deleted !== true)
            .map((file) => {
              const address = deal.data?.lender?.data?.info?.additionalAddresses?.find(
                (address) => address?.name === document?.address?.name
              );
              const newDeal =
                deal.data.lender && address
                  ? ({
                      ...deal,
                      data: {
                        ...deal.data,
                        lender: {
                          ...deal.data.lender,
                          data: {
                            ...deal.data.lender.data,
                            info: {
                              ...deal.data.lender.data.info,
                              address: address.address,
                              city: address.city,
                              zipCode: address.zipCode,
                              state: address.state,
                              securedPartyNumber: address.securedPartyNumber
                            }
                          }
                        }
                      }
                    } as Deal)
                  : deal;
              const convertedCoordinates = transformCoordinatesWithValues(
                virtualFields,
                checklistDealStateAccess ?? stateAccess,
                newDeal,
                file.data.info.coordinates
              );
              return {
                packType: document.packType,
                type: document.type,
                url: file.data.info.url,
                coordinates: convertedCoordinates || [],
                fileId: file._id,
                documentTemplateId: file.data.documentTemplateId,
                name: document.name,
                required: {
                  default: document.additionalProperties.required,
                  dealership: document.additionalProperties.dealerships,
                  applicant: document.additionalProperties.applicant,
                  optionalSignature:
                    document.documentData?.data?.info?.requestedBy?.optionalSignature
                }
              };
            });
          return [...acc, ...allFiles];
        }
        return acc;

      case DocumentsTypesLiterals.insurance_document:
        return [
          ...acc,
          {
            packType: document.packType,
            type: document.type,
            url: document.fileUrl,
            coordinates: [],
            fileId: document._id,
            name: document.name,
            provider: document.provider,
            required: {
              default: document.additionalProperties.required,
              dealership: document.additionalProperties.dealerships,
              applicant: document.additionalProperties.applicant
            }
          }
        ];
      case DocumentsTypesLiterals.credit_score:
        return [
          ...acc,
          {
            packType: document.packType,
            type: document.type,
            url: document.fileUrl,
            coordinates: [],
            fileId: document.documentData._id,
            name: document.name,
            required: {
              default: document.additionalProperties.required,
              dealership: document.additionalProperties.dealerships,
              applicant: document.additionalProperties.applicant
            }
          }
        ];
      case DocumentsTypesLiterals.other_required_document:
        return [
          ...acc,
          {
            packType: document.packType,
            type: document.type,
            url: document.fileUrl,
            coordinates: [],
            fileId: document._id,
            name: document.name,
            required: {
              default: document.additionalProperties.required,
              dealership: document.additionalProperties.dealerships,
              applicant: document.additionalProperties.applicant
            }
          }
        ];
      case DocumentsTypesLiterals.additional_required_document:
        return acc;
      default:
        alert("Found unknown document in the state");
        return acc;
    }
  }, [] as GenerateContractFile[]);
};
export const displayData = (
  deal: Deal,
  draggable: Coordinates,
  showData: (deal: Deal, draggable: any) => boolean | string | undefined | JSX.Element
) => {
  const value = showData(deal, draggable);
  if (value === true) {
    return "X";
  } else if (typeof value === "function" || typeof value === "object") {
    return value;
  } else {
    return (value || " ").toString();
  }
};

const generateStateAccessFromDeal = (deal: Deal) => {
  return {
    get: (path: string[]) => {
      return getByPath(deal, path as any);
    },
    set: (path: string[], value: any) => {
      console.error("Unable to set value on deal", path, value);
    }
  } as StateAccess;
};

export const showData = (
  deal: Deal,
  draggable: any,
  virtualFields: VirtualFields
): boolean | string | undefined | JSX.Element => {
  const stateAccess = generateStateAccessFromDeal(deal);
  const value = getByPath(deal, draggable.path as any);
  if (draggable?.type === "input-date") {
    if (!value) {
      return "";
    }
    const date = new Date(value);
    switch (draggable.format) {
      case "day":
        return date.toLocaleString("en-US", { day: "numeric" });
      case "month-text":
        return date.toLocaleString("en-US", { month: "long" });
      case "month-number":
        return date?.toLocaleString("en-US", { month: "numeric" });
      case "year":
        return date?.toLocaleString("en-US", { year: "numeric" });
      case "short-year":
        return date?.toLocaleString("en-US", { year: "numeric" }).substring(2);
      case "mm-dd-yyyy":
        return `${date.toLocaleString("en-US", { month: "numeric" })}-${date.toLocaleString(
          "en-US",
          {
            day: "numeric"
          }
        )}-${date.toLocaleString("en-US", { year: "numeric" })}`;
      case "full-date":
      default:
        return date.toLocaleDateString("en-US");
    }
  } else if (draggable?.type === "input-phone") {
    if (value === undefined || value === null) {
      return "";
    }
    const clearedPhone = clearPhone(value);
    if (clearedPhone === "" || clearedPhone.length < 5) {
      return "";
    }
    switch (draggable.format) {
      case "(XXX) XXX-XXXX":
        return `(${clearedPhone.slice(0, 3)}) ${clearedPhone.slice(3, 6)}-${clearedPhone.slice(
          6,
          10
        )}`;
      case "+1 (XXX) XXX-XXXX":
      default:
        return `+1 (${clearedPhone.slice(0, 3)}) ${clearedPhone.slice(3, 6)}-${clearedPhone.slice(
          6,
          10
        )}`;
    }
  } else if (draggable?.type === "virtual") {
    const path = draggable.path.join(".");
    if (path in virtualFields) {
      if (React.isValidElement(virtualFields[draggable.path.join(".")](stateAccess))) {
        throw new Error(`Virtual field ${draggable.path.join(".")} returns JSX value!`);
      }
      return virtualFields[draggable.path.join(".")](stateAccess);
    } else {
      try {
        console.error("Unable to find virtual field:", draggable, deal);
        notifyWebhooks(
          ("Unable to find virtual field: " + JSON.stringify(draggable)).slice(0, 1000)
        );
      } catch (error) {
        console.error(error);
      }
      return " ";
    }
  } else if (draggable.label === "Unit Status") {
    return capitalize(value) || "";
  } else if (draggable.type === "currency") {
    return typeof value === "number" ? formatNumberAsCurrency(value) : "0.00";
  } else {
    return value;
  }
};
export const generateContract = async (
  checklistDealStateAccess: StateAccess,
  stateAccess: StateAccess,
  checklistStateAccess: StateAccess,
  selectedDocuments: ModalDocuments,
  includeBarcodes: boolean
) => {
  const deal: Deal = { ...stateAccess.get([]) };
  const checklist: Checklist = checklistStateAccess.get([]);

  const virtualFields = modelToVirtualMap<"deal">(editDealStruct);
  return await addGenerateContract({
    data: {
      deal: disaggregateDeal(deal),
      checklist,
      info: {
        includeBarcodes,
        generateContractFiles: documentsToGenerateContractsRequest(
          selectedDocuments,
          deal,
          virtualFields,
          stateAccess,
          checklistDealStateAccess
        )
      }
    }
  });
};
export const generateContractForPrinting = async (
  stateAccess: StateAccess,
  selectedDocuments: ModalDocuments,
  type: "external_credit_application" | "recap_sheet"
) => {
  const deal: Deal = stateAccess.get([]);

  const virtualFields = modelToVirtualMap<"deal">(editDealStruct);
  return await generateContractForPrint({
    data: {
      dealId: type === "recap_sheet" ? deal._id : null,
      info: {
        type,
        includeBarcodes: false,
        generateContractFiles: documentsToGenerateContractsRequest(
          selectedDocuments,
          deal,
          virtualFields,
          stateAccess
        )
      }
    }
  });
};
const printContract = (
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  contract?: { result: GenerateContractForPrintResponse; status: string }
) => {
  if (contract) {
    const warning = contract?.result?.data?.generatedPack?.warning;
    if ((warning && confirm(warning + " Do you still want to print the documents?")) || !warning) {
      console.log(contract?.result?.data?.generatedPack?.url);
      printJS({
        printable: contract?.result?.data?.generatedPack?.url,
        type: "pdf",
        showModal: true,
        modalMessage: "Downloading file..."
      });
    }
  } else {
    const err = "Unable to download documents pack!";
    notifyWebhooks(err);
    enqueueSnackbar(err, { variant: "error" });
  }
};
export const generateAndPrintContract = async (
  stateAccess: StateAccess,
  selectedDocuments: ModalDocuments,
  type: "external_credit_application" | "recap_sheet",
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey
) => {
  const contract = await generateContractForPrinting(stateAccess, selectedDocuments, type);
  console.log(contract);
  printContract(enqueueSnackbar, contract);
};
