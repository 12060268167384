import { Sequence } from "./types";
import { permissionNode, PermissionStruct, relationPermissionNode } from "components/Roles/types";

export type SequencePermissionsStruct = PermissionStruct<Sequence>;

export const sequencePermissionStruct: SequencePermissionsStruct = {
  _id: permissionNode("Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  seq: permissionNode("Sequence"),
  createdAt: permissionNode("Created At"),
  updatedAt: permissionNode("Updated At"),
  deleted: permissionNode("Deleted"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids")
};
