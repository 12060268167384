import styles from "./Badge.module.css";
import React from "react";
type BadgeProps = {
  badgeCount?: number;
  style?: React.CSSProperties;
};

function Badge({ badgeCount, style }: BadgeProps) {
  return badgeCount ? (
    <div className={styles.badgeContainer} style={style}>
      <div>{badgeCount}</div>
    </div>
  ) : null;
}

type Position = "top-left" | "top-right" | "bottom-left" | "bottom-right";

type Badge2Props = {
  children: React.ReactNode;
  dotColor: string;
  position: Position;
};

export const Badge2 = ({ children, dotColor, position }: Badge2Props) => {
  return (
    <div className={styles.badge}>
      {children}
      <div
        className={[styles.dot, styles[position]].join(" ")}
        style={{ background: dotColor }}
      ></div>
    </div>
  );
};

export default Badge;
