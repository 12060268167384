import { Grid, useMediaQuery } from "@material-ui/core";
import { Lender } from "components/Lenders/types";
import Spinner from "components/Loader/Spinner";
import { LenderAllocationStatisticRequest } from "hooks/useLenderAllocationStatistics/types";
import useLenderAllocationMonthlyStatistics from "hooks/useLenderAllocationStatistics/useLenderAllocationMonthlyStatistics";
import React, { useEffect } from "react";
import AllocationDealsModal from "../AllocationDealsModal";
import { ToggleState } from "../LenderAllocationDashboard";
import LenderChartByStatus from "./../LenderChartByStatus";
import MonthlyAllocationInformation from "./MonthlyAllocationInformation";
import MonthlyManagerTable from "./MonthlyManagerTable";

interface Props {
  lender: Lender | undefined;
  state: LenderAllocationStatisticRequest;
  periodToggle: ToggleState;
  setViewDealsButton: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
}
export default function LifetimeDashboard({
  lender,
  state,
  periodToggle,
  setViewDealsButton
}: Props) {
  const matches = useMediaQuery("(min-width:800px)");
  const { data, isLoading } = useLenderAllocationMonthlyStatistics({
    ...state,
    allocationType: periodToggle,
    lenderId: lender?._id ?? ""
  });
  useEffect(() => {
    if (data && data.data) {
      setViewDealsButton(
        <AllocationDealsModal
          dealIds={data?.data?.monthlyStatistics?.monthlyDealIds}
          lender={lender}
        />
      );
    } else {
      setViewDealsButton(null);
    }
  }, [data, lender, setViewDealsButton]);
  return isLoading ? (
    <Spinner
      text={`Loading: ${lender?.data?.info?.name}`}
      style={{ position: "relative", zIndex: 5 }}
    />
  ) : data && data.data ? (
    <>
      <Grid item xs={12} style={{ marginBottom: 10 }}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <LenderChartByStatus
              selectedStatuses={state.statuses}
              currencyFormat={true}
              data={data.data.monthlyStatistics?.amountFinancedByStatus}
              chartType="bar"
              title="Amount financed by status"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <LenderChartByStatus
              selectedStatuses={state.statuses}
              unallocated={true}
              data={data.data.monthlyStatistics?.allocatedAmount}
              chartType="pie"
              title="Allocated amount"
              currencyFormat={true}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <LenderChartByStatus
              selectedStatuses={state.statuses}
              data={data.data.monthlyStatistics?.dealCountByStatus}
              chartType="bar"
              title={"Deal count by status"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={8}
            style={{
              maxWidth: matches ? "auto" : "calc(100vw - 60px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: " flex-start"
            }}
          >
            <MonthlyManagerTable
              selectedStatuses={state.statuses}
              data={data.data.monthlyStatistics}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start"
            }}
          >
            <MonthlyAllocationInformation
              toggleState={periodToggle}
              state={state}
              overviewStatistics={data.data?.overview}
              data={
                data.data?.monthlyStatistics?.monthlyDealIds ? [data.data?.monthlyStatistics] : []
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
}
