import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { auth } from "../../firebase/firebase";
import {
  LenderAllocationMonthlyStatisticsResponse,
  LenderAllocationStatisticRequest,
  LenderAllocationStatisticsResponse
} from "./types";

export const getLenderAllocationMonthlyStatistics = (
  request: LenderAllocationStatisticRequest
): (() => Promise<LenderAllocationMonthlyStatisticsResponse>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/lender_allocation_statistics_monthly`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    );

    return response.data.result;
  };
};

const useLenderAllocationMonthlyStatistics = (
  request: LenderAllocationStatisticRequest,
  enabled = true
) => {
  const queryKey = ["lender_allocation_statistics", request];

  const { data, isLoading, isSuccess, error } = useQuery({
    queryKey: queryKey,
    queryFn: getLenderAllocationMonthlyStatistics(request),
    enabled: enabled && request.lenderId && request.year ? true : false
  });

  return {
    data,
    isSuccess,
    isLoading,
    error
  };
};

export default useLenderAllocationMonthlyStatistics;
