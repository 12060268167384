import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import { RootState } from "app/rootReducer";
import clsx from "clsx";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openDrawer } from "../Drawer/drawerSlice";
import GlobalSearch from "../GlobalSearch/GlobalSearch";
import Logo from "./Logo";
import MenuLinks from "./MenuLinks";

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    appBar: {
      backgroundColor:
        process.env.REACT_APP_FIREBASE_PROJECT_ID === "wfd-frontend-dev"
          ? "#702528"
          : process.env.REACT_APP_FIREBASE_PROJECT_ID === "wfd-frontend-stable"
          ? "#25702b"
          : "#254e70",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block"
      }
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  })
);

export default function CustomAppBar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { drawerSlice } = useSelector((state: RootState) => state);

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: drawerSlice.isOpen
      })}
    >
      <Toolbar variant="dense">
        {!drawerSlice.isOpen ? (
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="toggle drawer"
            onClick={() => dispatch(openDrawer())}
          >
            <MenuIcon />
          </IconButton>
        ) : null}

        <Typography className={classes.title} variant="h6" noWrap>
          <Logo />
          {process.env.REACT_APP_FIREBASE_PROJECT_ID === "wfd-frontend-dev"
            ? "DEV"
            : process.env.REACT_APP_FIREBASE_PROJECT_ID === "wfd-frontend-stable"
            ? "TESTING"
            : process.env.REACT_APP_HTTP_URL
            ? ""
            : "OLD VERSION"}
        </Typography>
        <GlobalSearch />
        <div className={classes.grow} />
        <MenuLinks />
      </Toolbar>
    </AppBar>
  );
}
