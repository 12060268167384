import { useQuery } from "@tanstack/react-query";
import { getApplicants } from "Chat/http/applicants";
import { getDealershipUsers } from "Chat/http/dealership_users";
import { getUsers } from "Chat/http/users";
import { HttpQuery } from "Chat/types/http";

const useChatSearch = (query: HttpQuery) => {
  const queryKey = ["members-chat-search", query ?? []];

  const { data: applicants, isLoading: applicantsAreLoading, error: applicantsError } = useQuery({
    queryKey: ["applicants-chat-search", query ?? {}],
    queryFn: getApplicants(query),
    enabled: !!Object.keys(query)?.length
  });

  const { data: users, isLoading: usersAreLoading, error: usersError } = useQuery({
    queryKey: queryKey,
    queryFn: getUsers(query),
    enabled: !!Object.keys(query?.search ?? {})?.length
  });

  const {
    data: dealershipUsers,
    isLoading: dealershipUsersAreLoading,
    error: dealershipUsersError
  } = useQuery({
    queryKey: ["dealeship-users-chat-search", query ?? []],
    queryFn: getDealershipUsers(query),
    enabled: !!Object.keys(query?.search ?? {})?.length
  });

  return {
    data: { users, applicants, dealershipUsers },
    isLoading: { usersAreLoading, applicantsAreLoading, dealershipUsersAreLoading },
    error: { usersError, applicantsError, dealershipUsersError }
  };
};
export default useChatSearch;
