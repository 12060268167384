import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteRoleRequest {
  _id: string;
}

interface DeleteRoleResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteRoleRequest,
  DeleteRoleResponse
>("DeleteUser", {
  request: "delete_role"
});

export const deleteRoleActions = socketMessageSlice.actions;
export const deleteRole = socketAction;
export default socketMessageSlice.reducer;
