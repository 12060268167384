import React, { useEffect, useState } from "react";
import { Paper, IconButton, Zoom } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import VisibilityIcon from "@material-ui/icons/Visibility";
import toNormalCase from "utils/toNormalCase";
import { ErrorResponse } from "./checkCreditScoreSlice";
import formatDate from "utils/formatDate";
import { SuccessResponse, CreditCheckTypes, CreditBureau } from "./types";
import { HintTooltip } from "components/common/HintTooltip";
import DuplicatedCreditScoresDialog from "./DuplicatedCreditScoresDialog";
import { RootState } from "app/rootReducer";
import { getUserList, removeUserList } from "components/Users/listUserSlice";
import { useSelector, useDispatch } from "react-redux";

const isSuccessScore = (
  score: SuccessResponse | ErrorResponse | undefined
): score is SuccessResponse => score?.hasOwnProperty("bureau_xml_data") === true;

export const formatCreditBureausScores = (creditBureau: CreditBureau) => {
  const score = creditBureau?.data["700CreditResponse"]?.Results;

  let result = null;
  if (isSuccessScore(score)) {
    result = score.bureau_xml_data?.[0].TU_Report?.[0]?.subject_segments?.map((segment: any) => {
      const scoringSegment = segment?.scoring_segments?.[0];
      const scoreValue =
        scoringSegment?.scoring?.[0]?.score?.[0] || scoringSegment?.Scoring?.[0]?.Score?.[0];
      return scoreValue;
    });
    switch (creditBureau?.data.type) {
      case CreditCheckTypes.Joint:
        return `${creditBureau?.data.request.NAME ?? ""} - ${result?.[0] ? result[0] : "N/A"} / ${
          creditBureau?.data.request.SPOUSE
        } - ${result?.[1] ? result[1] : "N/A"}`;
      default:
        return `${creditBureau?.data.request.NAME ?? ""} - ${result?.[0] ? result[0] : "N/A"}`;
    }
  }
  return "";
};
export const extractUrlFromResponse = (creditBureau: CreditBureau): string | undefined | null => {
  try {
    return creditBureau?.data["700CreditResponse"]?.Results?.custom_report_url?.[0]?.iframe[0]
      ?.attributes?.src;
  } catch (error) {
    console.error("error from getting the iframe url", error);
    return undefined;
  }
};
export const CreditBureauView = ({
  creditBureaus,
  handleShowDialog
}: {
  creditBureaus: CreditBureau[];
  handleShowDialog: (url: string) => void;
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const listId = "creditScoresUsers";

  const userList = useSelector((state: RootState) => state.listUserSlice[listId]);

  useEffect(() => {
    if (!userList) {
      dispatch(
        getUserList(listId, {
          options: { pagination: false }
        })
      );
    }
    return () => {
      dispatch(removeUserList(listId));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {creditBureaus.map((creditBureau, index) => (
        <HintTooltip
          key={index}
          title={
            <span>
              Created by:{" "}
              {userList?.entities?.find((x) => x._id === creditBureau.creatorId)?.data.info.email}
            </span>
          }
        >
          <Paper
            elevation={3}
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "14px"
            }}
          >
            <div style={{ marginLeft: "5px" }}>{toNormalCase(creditBureau.data?.type)}</div>
            <div>{formatCreditBureausScores(creditBureau)}</div>
            <div>
              {creditBureau.data.source === "local" ? (
                <HintTooltip
                  title={
                    <span>
                      Duplicate report found! Same report was requested on{" "}
                      {formatDate(new Date(creditBureau.createdAt ?? ""), "medium", true)}
                    </span>
                  }
                  style={{ marginLeft: "5px" }}
                  arrow
                  TransitionComponent={Zoom}
                >
                  <IconButton
                    id="warning-score-icon"
                    aria-label="warning"
                    onClick={() => setOpen(true)}
                    disableRipple={true}
                  >
                    <WarningIcon style={{ color: "#eed202" }} />
                  </IconButton>
                </HintTooltip>
              ) : (
                <div style={{ width: "51px", display: "inline-block" }} />
              )}
              <IconButton
                id="show-score-icon"
                style={{ marginRight: "5px" }}
                color="primary"
                onClick={() => {
                  handleShowDialog(extractUrlFromResponse(creditBureau) ?? "");
                }}
                disabled={
                  extractUrlFromResponse(creditBureau) === undefined ||
                  extractUrlFromResponse(creditBureau) === null
                }
              >
                <VisibilityIcon />
              </IconButton>
            </div>
            <DuplicatedCreditScoresDialog
              applicantId={creditBureau.data.applicantId}
              open={open}
              handleClose={() => setOpen(false)}
            />
          </Paper>
        </HintTooltip>
      ))}
    </>
  );
};
