import { Deal } from "components/Deals/types";
import { createListSlice } from "../../Middlewares/listSliceCreator";

const { ListSlice, getList } = createListSlice<any>({
  name: "dealCommissionsByDays",
  request_topic: "deals_commision_by_days",
  reducers: {}
});

export default ListSlice.reducer;
export const getDealCommissionByDays = getList;
export const removeDealList = ListSlice.actions.removeList;
export const listDealActions = ListSlice.actions;
