import React, { useContext, useState } from "react";
import { Button } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { RenderSet, StateAccess, getByPath, setByPath } from "utils/models/formGenerator";
import { Deal } from "./types";
import { VerificationContext } from "components/Layout/Main";
import DocumentsModal from "./DocumentsModal";
import formEditContext from "components/Content/FormEditContext";
import DescriptionIcon from "@material-ui/icons/Description";
import { v4 as uuidv4 } from "uuid";
import { Checklist } from "./Checklist/types";
import { RootState } from "app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { addChecklist } from "./Checklist/addChecklistSlice";
import { editChecklist } from "./Checklist/editChecklistSlice";
interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}

export default function DocumentsModalButton({ stateAccess, renderSet }: Props) {
  const deal: Deal = stateAccess.get<Deal>([] as any);
  const checklistListId = deal._id;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [requestId] = useState(uuidv4());
  const { enabled: editMode } = useContext(formEditContext);
  const [checklistState, setChecklistState] = useState<Checklist | undefined>(undefined);
  const checklist = useSelector((state: RootState) => state.listChecklistSlice[checklistListId])
    ?.entities?.[0];
  const checklistStateAccess: StateAccess = {
    get: (path) => getByPath(checklistState as Checklist, path),
    set: (path, value): any =>
      setChecklistState(setByPath((checklistState as any) ?? {}, path, value))
  };

  const checkDataValidity = useContext(VerificationContext);
  const handleClose = () => {
    if (!checklist) {
      const checklistStateToSend = {
        ...checklistStateAccess.get([]),
        data: {
          ...checklistStateAccess.get(["data"]),
          dealId: deal._id
        }
      };
      dispatch(addChecklist({ ...checklistStateToSend, requestId }));
    } else {
      dispatch(editChecklist({ ...checklistStateAccess.get([]), requestId }));
    }
    setOpen(false);
  };

  const requiredFields = [
    { name: "Lender", value: deal?.data?.lender?._id },
    { name: "Dealerpship state", value: deal?.data?.dealership?.data?.info?.state },
    { name: "Deal type", value: deal?.data?.info?.type },
    { name: "Signed at", value: deal?.data?.info?.dealDates?.contractDate },
    { name: "Payment Type", value: deal?.data?.info?.payment?.type },
    { name: "Number of payments", value: deal?.data?.info?.payment?.numberOfPayments },
    { name: "Contract rate", value: deal?.data?.info?.payment?.interestRate }
  ];

  return (
    <div>
      <Button
        id="print-button"
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          checkDataValidity(requiredFields, () => {
            stateAccess.set(
              ["data", "info", "aftermarketOptions", "insurances"],
              deal.data?.info?.aftermarketOptions?.insurances?.map((ins) => {
                if (!ins.id) {
                  return { ...ins, id: uuidv4() };
                }
                return ins;
              })
            );
            setOpen(true);
          });
        }}
        style={{
          backgroundColor: editMode ? "#254e70" : "#0000001f",
          color: editMode ? "#fff" : "black"
        }}
        startIcon={<DescriptionIcon />}
        disabled={!editMode}
        disableElevation
      >
        Generate documents
      </Button>

      {open && (
        <DocumentsModal
          checklistListId={checklistListId}
          checklistStateAccess={checklistStateAccess}
          renderSet={renderSet}
          stateAccess={stateAccess}
          open={open}
          handleClose={handleClose}
        />
      )}
    </div>
  );
}
