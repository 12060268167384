import {
  multyPermissionNode,
  permissionNode,
  PermissionStruct,
  relationPermissionNode
} from "components/Roles/types";
import { DealershipTicket } from "./types";

export type DealershipTicketPermissionsStruct = PermissionStruct<DealershipTicket>;

export const dealershipTicketPermissionsStruct: DealershipTicketPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    dealership: relationPermissionNode("Dealership", "dealership"),
    dealershipTicketNotes: relationPermissionNode(
      "Dealership Ticket Notes",
      "dealership_ticket_note"
    ),
    notes: relationPermissionNode("Internal Notes", "note"),
    userId: permissionNode("User id"),
    dealershipId: permissionNode("Dealership id"),
    info: {
      description: permissionNode("Description"),
      url: permissionNode("URL"),
      status: permissionNode("status"),
      user: {
        firstName: permissionNode("User first name"),
        lastName: permissionNode("User last name"),
        email: permissionNode("User email")
      },
      browserInfo: {
        browserName: permissionNode("Browser name"),
        browserVersion: permissionNode("Browser version")
      },
      deviceInfo: {
        deviceType: permissionNode("Device type"),
        osName: permissionNode("OS Name"),
        osVersion: permissionNode("OS version")
      }
    }
  }
};
