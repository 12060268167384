import { Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import IconButton from "@material-ui/core/IconButton";
import modalContainerContext from "../../Content/modalContainer";
import AddEntity from "components/Content/addEntity";
import CloseDialogButton from "components/common/CloseDialogButton";
import { Add, Save, Delete } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { getCustomReportList } from "components/CustomReport/listCustomReportSlice";
import { HintTooltip } from "components/common/HintTooltip";

export default ({
  setDeleteButtonVisibility
}: {
  setDeleteButtonVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const modalContainer = useContext(modalContainerContext);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  return (
    <>
      {open
        ? ReactDOM.createPortal(
            <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
              <DialogTitle>
                <CloseDialogButton closeFunction={handleClose} />
              </DialogTitle>
              <DialogContent>
                <AddEntity
                  type="custom_report"
                  openInNewTab={false}
                  callback={() => {
                    setOpen(false);
                    dispatch(getCustomReportList("table"));
                  }}
                  buttonProps={{ text: "Add", startIcon: <Save /> }}
                />
              </DialogContent>
            </Dialog>,
            modalContainer
          )
        : null}
      <Grid container spacing={3} justifyContent="flex-end">
        <Grid item>
          <IconButton
            onClick={() => {
              setDeleteButtonVisibility((d) => !d);
            }}
            style={{ color: "red" }}
            component="span"
            id="delete-reports-button"
          >
            <HintTooltip title="Click here to mark custom reports for deletion.">
              <Delete />
            </HintTooltip>
          </IconButton>
          <IconButton
            id="add-new-custom-report"
            onClick={() => {
              setOpen(true);
            }}
            color="primary"
            component="span"
          >
            <HintTooltip title="Click here to add new custom report.">
              <Add />
            </HintTooltip>
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};
