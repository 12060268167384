import { CountiesGEOJSON } from "./types";

export default {
  type: "FeatureCollection",
  name: "cb_2017_us_county_20m",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "075",
        COUNTYNS: "00277302",
        AFFGEOID: "0500000US06075",
        GEOID: "06075",
        NAME: "San Francisco",
        LSAD: "06",
        ALAND: 121485107,
        AWATER: 479107241
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.511983, 37.771129999086256],
            [-122.465396, 37.800878999086002],
            [-122.398139, 37.805629999085973],
            [-122.385323, 37.79072399908609],
            [-122.376462, 37.738557999086517],
            [-122.356784, 37.729504999086579],
            [-122.361749, 37.715009999086718],
            [-122.389826876588003, 37.708330999086769],
            [-122.500678019083011, 37.708132567104663],
            [-122.505601251345013, 37.735567464327247],
            [-122.511983, 37.771129999086256]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "25",
        COUNTYFP: "025",
        COUNTYNS: "00606939",
        AFFGEOID: "0500000US25025",
        GEOID: "25025",
        NAME: "Suffolk",
        LSAD: "06",
        ALAND: 150855462,
        AWATER: 160479920
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.191155, 42.283058999060863],
            [-71.156887, 42.330240999060713],
            [-71.167625, 42.36007299906062],
            [-71.117193, 42.3642289990606],
            [-71.077095, 42.358696999060626],
            [-71.064059, 42.369000999060582],
            [-71.080947, 42.382377999060544],
            [-70.982545601587901, 42.42022179911244],
            [-70.974897, 42.355842999060641],
            [-70.975892942126109, 42.354339252782545],
            [-70.983093585947202, 42.343467193993753],
            [-70.997838, 42.321204999060726],
            [-70.9930596399482, 42.312892279473168],
            [-71.041694, 42.305297999060784],
            [-71.053284, 42.277501999060867],
            [-71.093737, 42.2671069990609],
            [-71.102691, 42.259883999060932],
            [-71.111737, 42.260468999060926],
            [-71.11326, 42.258925999060928],
            [-71.109544, 42.255411999060939],
            [-71.109347, 42.247989999060962],
            [-71.126377, 42.23916199906099],
            [-71.122856, 42.235224999061003],
            [-71.130771, 42.227925999061029],
            [-71.146642, 42.25575499906094],
            [-71.188167, 42.28041199906086],
            [-71.191155, 42.283058999060863]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "007",
        COUNTYNS: "00835826",
        AFFGEOID: "0500000US31007",
        GEOID: "31007",
        NAME: "Banner",
        LSAD: "06",
        ALAND: 1932676697,
        AWATER: 397069
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.052825235238998, 41.697953852126851],
            [-103.370391, 41.699209999062838],
            [-103.369024, 41.437654999063852],
            [-103.37762, 41.394632999064022],
            [-104.052287134121002, 41.393213999257433],
            [-104.052287, 41.393306999064038],
            [-104.052634571632993, 41.56427577863704],
            [-104.052735, 41.613675999063162],
            [-104.052825235238998, 41.697953852126851]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "181",
        COUNTYNS: "01008591",
        AFFGEOID: "0500000US37181",
        GEOID: "37181",
        NAME: "Vance",
        LSAD: "06",
        ALAND: 653705784,
        AWATER: 42187365
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.497783, 36.514476999097397],
            [-78.457277896255889, 36.541448707496627],
            [-78.323718509559001, 36.542421390655726],
            [-78.277165, 36.306131999099414],
            [-78.306965, 36.266190999099813],
            [-78.37814, 36.235064999100118],
            [-78.42188, 36.164308999100818],
            [-78.496614, 36.175198999100715],
            [-78.511224, 36.454807999097966],
            [-78.497783, 36.514476999097397]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "421",
        COUNTYNS: "01383996",
        AFFGEOID: "0500000US48421",
        GEOID: "48421",
        NAME: "Sherman",
        LSAD: "06",
        ALAND: 2390651189,
        AWATER: 428754
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.162463, 36.500325999097534],
            [-102.032339018963, 36.500065672107631],
            [-101.826565, 36.499653999097532],
            [-101.623915, 36.499527999097545],
            [-101.623466, 36.055404999101896],
            [-102.163015, 36.055248999101899],
            [-102.162463, 36.500325999097534]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "50",
        COUNTYFP: "011",
        COUNTYNS: "01461762",
        AFFGEOID: "0500000US50011",
        GEOID: "50011",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1641633748,
        AWATER: 150930318
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.256321, 44.759443999056778],
            [-73.192787, 44.908699999056772],
            [-73.230777, 44.927436999056773],
            [-73.240323, 44.951590999056762],
            [-73.192310449066895, 45.012861162008868],
            [-73.169923816685696, 45.013161181680267],
            [-73.048386, 45.014789999056774],
            [-72.93644, 45.014266999056758],
            [-72.582371, 45.011542999056772],
            [-72.554271616223502, 45.009467719710273],
            [-72.532638, 44.833345999056775],
            [-72.578161, 44.781577999056779],
            [-72.74736, 44.786169999056781],
            [-72.786134, 44.727722999056788],
            [-72.795505, 44.692199999056797],
            [-72.851068, 44.711069999056797],
            [-72.924112, 44.63305099905682],
            [-73.038028, 44.651156999056809],
            [-73.228854, 44.722865999056793],
            [-73.256321, 44.759443999056778]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "680",
        COUNTYNS: "01498429",
        AFFGEOID: "0500000US51680",
        GEOID: "51680",
        NAME: "Lynchburg",
        LSAD: "25",
        ALAND: 126842531,
        AWATER: 1428787
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.266986, 37.447867999088963],
            [-79.185702, 37.465819999088801],
            [-79.120279, 37.398186999089376],
            [-79.085123, 37.394874999089403],
            [-79.214982, 37.332999999089935],
            [-79.257955, 37.355815999089742],
            [-79.266986, 37.447867999088963]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "099",
        COUNTYNS: "00069169",
        AFFGEOID: "0500000US05099",
        GEOID: "05099",
        NAME: "Nevada",
        LSAD: "06",
        ALAND: 1600192985,
        AWATER: 7254365
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.483097, 33.476074999131491],
            [-93.471207, 33.825223999127061],
            [-93.456709, 33.956836999125422],
            [-93.419093, 33.948793999125535],
            [-93.373406, 33.95706999912543],
            [-93.264485, 33.83228199912697],
            [-93.139279, 33.814819999127195],
            [-93.10429, 33.777010999127668],
            [-93.116361, 33.452908999131786],
            [-93.22129, 33.440443999131951],
            [-93.365585, 33.444458999131896],
            [-93.483974, 33.44722299913186],
            [-93.483097, 33.476074999131491]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "014",
        COUNTYNS: "01945881",
        AFFGEOID: "0500000US08014",
        GEOID: "08014",
        NAME: "Broomfield",
        LSAD: "06",
        ALAND: 85478932,
        AWATER: 1411395
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.16615, 39.91404699907131],
            [-105.109517, 39.956995999071069],
            [-105.071899, 39.950197999071115],
            [-105.052823, 40.000259999070813],
            [-104.961071, 40.044143999070563],
            [-104.961405, 40.000336999070811],
            [-104.988159, 39.968465999071014],
            [-104.99725, 39.968528999070998],
            [-104.997423, 39.981212999070941],
            [-105.015845, 39.981192999070927],
            [-105.052892, 39.914223999071318],
            [-105.165167, 39.891485999071449],
            [-105.16615, 39.91404699907131]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "065",
        COUNTYNS: "00394803",
        AFFGEOID: "0500000US16065",
        GEOID: "16065",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 1215396379,
        AWATER: 10500827
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.976849, 43.927384999057359],
            [-111.83759, 43.898260999057399],
            [-111.558833, 43.883304999057415],
            [-111.501232, 43.929205999057373],
            [-111.398781, 43.922889999057354],
            [-111.399765, 43.621975999057774],
            [-111.565974, 43.622036999057777],
            [-111.626043, 43.626755999057778],
            [-111.717356, 43.655325999057723],
            [-111.934357, 43.766159999057571],
            [-111.983527, 43.75380599905759],
            [-111.976849, 43.927384999057359]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "027",
        COUNTYNS: "00424215",
        AFFGEOID: "0500000US17027",
        GEOID: "17027",
        NAME: "Clinton",
        LSAD: "06",
        ALAND: 1227823898,
        AWATER: 75642557
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.707024, 38.655151999079472],
            [-89.595103, 38.655947999079459],
            [-89.597321, 38.743235999078834],
            [-89.254237, 38.742018999078844],
            [-89.138393, 38.736330999078888],
            [-89.143898, 38.50308799908057],
            [-89.360248, 38.516521999080467],
            [-89.481556, 38.468464999080823],
            [-89.577521, 38.483159999080705],
            [-89.704417, 38.415666999081225],
            [-89.707024, 38.655151999079472]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "025",
        COUNTYNS: "00465202",
        AFFGEOID: "0500000US19025",
        GEOID: "19025",
        NAME: "Calhoun",
        LSAD: "06",
        ALAND: 1475894622,
        AWATER: 5873828
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.914485, 42.560308999060048],
            [-94.443024, 42.558842999060047],
            [-94.443033, 42.47337799906029],
            [-94.397671, 42.473299999060295],
            [-94.397526, 42.209160999061083],
            [-94.628806, 42.209395999061073],
            [-94.858412, 42.209691999061086],
            [-94.856689, 42.473995999060293],
            [-94.914487, 42.474189999060286],
            [-94.914485, 42.560308999060048]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "129",
        COUNTYNS: "00516911",
        AFFGEOID: "0500000US21129",
        GEOID: "21129",
        NAME: "Lee",
        LSAD: "06",
        ALAND: 540937712,
        AWATER: 6215038
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.90557, 37.542855999088154],
            [-83.822121, 37.665577999087112],
            [-83.7221, 37.716419999086696],
            [-83.707974, 37.716462999086694],
            [-83.616649, 37.666924999087108],
            [-83.522308, 37.638515999087339],
            [-83.579186, 37.506248999088456],
            [-83.635706, 37.54001099908816],
            [-83.742984, 37.494252999088552],
            [-83.885262, 37.517687999088359],
            [-83.90557, 37.542855999088154]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "063",
        COUNTYNS: "00558083",
        AFFGEOID: "0500000US22063",
        GEOID: "22063",
        NAME: "Livingston",
        LSAD: "15",
        ALAND: 1678750672,
        AWATER: 112119167
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.985759, 30.584453999173014],
            [-90.910701, 30.649384999171993],
            [-90.567165, 30.650022999171977],
            [-90.5669, 30.526129999173939],
            [-90.546118, 30.430282999175475],
            [-90.47376, 30.345245999176839],
            [-90.485826, 30.276991999177934],
            [-90.554147, 30.195627999179251],
            [-90.592021, 30.208323999179051],
            [-90.632811, 30.221408999178834],
            [-90.694146, 30.175066999179585],
            [-90.830752, 30.31862499917727],
            [-90.891728, 30.345243999176841],
            [-90.93723, 30.366564999176489],
            [-90.991529, 30.461817999174965],
            [-90.985759, 30.584453999173014]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "097",
        COUNTYNS: "00835870",
        AFFGEOID: "0500000US31097",
        GEOID: "31097",
        NAME: "Johnson",
        LSAD: "06",
        ALAND: 973968228,
        AWATER: 1766531
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.463632, 40.523012999068023],
            [-96.067012, 40.523147999068016],
            [-96.066736, 40.262037999069378],
            [-96.463667, 40.262172999069378],
            [-96.463632, 40.523012999068023]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "101",
        COUNTYNS: "00835872",
        AFFGEOID: "0500000US31101",
        GEOID: "31101",
        NAME: "Keith",
        LSAD: "06",
        ALAND: 2749531887,
        AWATER: 124672175
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.055535, 41.221546999064756],
            [-102.055256, 41.395161999064008],
            [-101.98463, 41.394711999064029],
            [-101.40726, 41.395415999064014],
            [-101.269695, 41.394933999064023],
            [-101.270381, 41.047391999065511],
            [-101.249975, 41.003949999065696],
            [-102.051761, 41.003889999065706],
            [-102.055528, 41.047456999065517],
            [-102.055535, 41.221546999064756]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "775",
        COUNTYNS: "01789074",
        AFFGEOID: "0500000US51775",
        GEOID: "51775",
        NAME: "Salem",
        LSAD: "25",
        ALAND: 37599554,
        AWATER: 277514
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.12049, 37.287052999090342],
            [-80.022226, 37.308337999090149],
            [-80.033464, 37.262889999090547],
            [-80.081631, 37.265951999090532],
            [-80.12049, 37.287052999090342]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "493",
        COUNTYNS: "01384032",
        AFFGEOID: "0500000US48493",
        GEOID: "48493",
        NAME: "Wilson",
        LSAD: "06",
        ALAND: 2081662847,
        AWATER: 12111367
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.407336, 29.114434999197393],
            [-98.134171, 29.441750999191779],
            [-98.113175, 29.365666999193071],
            [-97.956081, 29.378435999192853],
            [-97.840383, 29.376790999192881],
            [-97.858782, 29.352889999193287],
            [-97.728443, 29.221747999195539],
            [-97.995302, 29.026103999198927],
            [-98.190991, 28.882332999201438],
            [-98.407336, 29.114434999197393]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "115",
        COUNTYNS: "01383843",
        AFFGEOID: "0500000US48115",
        GEOID: "48115",
        NAME: "Dawson",
        LSAD: "06",
        ALAND: 2331781556,
        AWATER: 4720730
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.20852, 32.958955999138269],
            [-102.076214, 32.959701999138268],
            [-101.691284, 32.961837999138226],
            [-101.68874, 32.525221999144186],
            [-102.202703, 32.523270999144216],
            [-102.20852, 32.958955999138269]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "051",
        COUNTYNS: "01265782",
        AFFGEOID: "0500000US46051",
        GEOID: "46051",
        NAME: "Grant",
        LSAD: "06",
        ALAND: 1764949271,
        AWATER: 15753610
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.226244, 45.297646999056838],
            [-97.007543, 45.296865999056834],
            [-96.992946, 45.326879999056843],
            [-96.47007760304929, 45.326799648449153],
            [-96.457781, 45.307609999056837],
            [-96.457553498485012, 45.268897927775214],
            [-96.455840113633599, 44.977345247336757],
            [-96.882345, 44.976869999056767],
            [-96.883948, 45.150223999056784],
            [-97.226281, 45.151825999056783],
            [-97.226244, 45.297646999056838]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "101",
        COUNTYNS: "01804531",
        AFFGEOID: "0500000US72101",
        GEOID: "72101",
        NAME: "Morovis",
        LSAD: "13",
        ALAND: 100676181,
        AWATER: 139537
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.463211, 18.371472999434975],
            [-66.4438, 18.371026999434985],
            [-66.395585, 18.371884999434965],
            [-66.377058, 18.344245999435692],
            [-66.365272, 18.333228999435985],
            [-66.377712, 18.293975999437023],
            [-66.384028, 18.290796999437106],
            [-66.390957, 18.273391999437568],
            [-66.455703, 18.25772599943798],
            [-66.464883, 18.283239999437306],
            [-66.463211, 18.371472999434975]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "067",
        COUNTYNS: "00066861",
        AFFGEOID: "0500000US05067",
        GEOID: "05067",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 1641853230,
        AWATER: 19623381
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.58188, 35.530446999107347],
            [-91.36957, 35.52636199910738],
            [-91.36878, 35.640011999106186],
            [-91.310358, 35.76617299910486],
            [-91.232392, 35.81266399910438],
            [-91.198556, 35.890023999103583],
            [-91.138801, 35.889998999103582],
            [-91.032499, 35.885069999103635],
            [-91.036967, 35.708417999105471],
            [-91.03923, 35.444716999108259],
            [-91.039783, 35.354255999109235],
            [-91.251504, 35.3575569991092],
            [-91.252927, 35.445844999108239],
            [-91.347257, 35.439105999108321],
            [-91.584687, 35.442310999108287],
            [-91.58188, 35.530446999107347]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "670",
        COUNTYNS: "01498428",
        AFFGEOID: "0500000US51670",
        GEOID: "51670",
        NAME: "Hopewell",
        LSAD: "25",
        ALAND: 26668801,
        AWATER: 1324529
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.334549, 37.312467999090103],
            [-77.322813, 37.308791999090147],
            [-77.315456, 37.308819999090147],
            [-77.30571, 37.312333999090114],
            [-77.297739, 37.312935999090115],
            [-77.29627, 37.309169999090152],
            [-77.290628, 37.313251999090106],
            [-77.287284, 37.316851999090083],
            [-77.284019, 37.313011999090122],
            [-77.273379, 37.316115999090087],
            [-77.253234, 37.295667999090263],
            [-77.260195, 37.282712999090379],
            [-77.278336, 37.280375999090388],
            [-77.31081, 37.261450999090563],
            [-77.328291, 37.271747999090465],
            [-77.334549, 37.312467999090103]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "003",
        COUNTYNS: "01266983",
        AFFGEOID: "0500000US46003",
        GEOID: "46003",
        NAME: "Aurora",
        LSAD: "06",
        ALAND: 1834813747,
        AWATER: 11201379
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.807771, 43.935222999057359],
            [-98.331508, 43.937707999057352],
            [-98.331545, 43.868518999057443],
            [-98.325242, 43.85105799905746],
            [-98.320595, 43.498650999057972],
            [-98.705782, 43.499318999057976],
            [-98.794588, 43.499186999057976],
            [-98.796965, 43.848574999057469],
            [-98.807771, 43.935222999057359]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "031",
        COUNTYNS: "00450345",
        AFFGEOID: "0500000US18031",
        GEOID: "18031",
        NAME: "Decatur",
        LSAD: "06",
        ALAND: 964946087,
        AWATER: 1955470
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.684515, 39.350048999074744],
            [-85.63063, 39.350204999074741],
            [-85.629327, 39.452749999074094],
            [-85.297575, 39.453274999074083],
            [-85.29654, 39.268290999075283],
            [-85.440054, 39.195616999075739],
            [-85.56632, 39.132760999076154],
            [-85.686783, 39.130858999076167],
            [-85.684515, 39.350048999074744]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "169",
        COUNTYNS: "00450399",
        AFFGEOID: "0500000US18169",
        GEOID: "18169",
        NAME: "Wabash",
        LSAD: "06",
        ALAND: 1068205084,
        AWATER: 22117118
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.945561, 41.042875999065537],
            [-85.795814, 41.043587999065529],
            [-85.684181, 41.046715999065512],
            [-85.643841, 41.002304999065721],
            [-85.638587, 40.65312899906737],
            [-85.864791, 40.651692999067379],
            [-85.939525, 40.650990999067375],
            [-85.946436, 40.999194999065729],
            [-85.945561, 41.042875999065537]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "161",
        COUNTYNS: "00465269",
        AFFGEOID: "0500000US19161",
        GEOID: "19161",
        NAME: "Sac",
        LSAD: "06",
        ALAND: 1489300182,
        AWATER: 8569441
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.38801, 42.56174199906004],
            [-94.914485, 42.560308999060048],
            [-94.914487, 42.474189999060286],
            [-94.856689, 42.473995999060293],
            [-94.858412, 42.209691999061086],
            [-95.090851, 42.210404999061076],
            [-95.323497, 42.210931999061074],
            [-95.322357, 42.474736999060291],
            [-95.387445, 42.474795999060284],
            [-95.38801, 42.56174199906004]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "107",
        COUNTYNS: "01008568",
        AFFGEOID: "0500000US37107",
        GEOID: "37107",
        NAME: "Lenoir",
        LSAD: "06",
        ALAND: 1033630023,
        AWATER: 5900300
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.818655, 35.356349999109213],
            [-77.806248, 35.368753999109074],
            [-77.697516, 35.377096999108979],
            [-77.676719, 35.347343999109313],
            [-77.475515, 35.426654999108457],
            [-77.426448, 35.349758999109277],
            [-77.391026, 35.33953299910938],
            [-77.431253, 35.329829999109506],
            [-77.473692, 35.228984999110601],
            [-77.527182, 35.24300099911045],
            [-77.507463, 35.172813999111206],
            [-77.601282, 35.07136799911234],
            [-77.731026, 35.008137999113046],
            [-77.749572, 35.10860999911192],
            [-77.769177, 35.145575999111522],
            [-77.834249, 35.177845999111163],
            [-77.818655, 35.356349999109213]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "055",
        COUNTYNS: "00758482",
        AFFGEOID: "0500000US29055",
        GEOID: "29055",
        NAME: "Crawford",
        LSAD: "06",
        ALAND: 1923108653,
        AWATER: 3203556
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.535332, 38.210973999082761],
            [-91.367482, 38.209740999082776],
            [-91.349553, 38.204077999082813],
            [-91.095765, 38.204082999082814],
            [-91.100017, 37.740011999086498],
            [-91.146521, 37.740810999086506],
            [-91.153345, 37.697339999086857],
            [-91.310655, 37.700047999086834],
            [-91.308311, 37.787185999086127],
            [-91.528797, 37.788988999086108],
            [-91.525245, 38.135627999083354],
            [-91.534384, 38.152592999083211],
            [-91.535332, 38.210973999082761]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "105",
        COUNTYNS: "00835874",
        AFFGEOID: "0500000US31105",
        GEOID: "31105",
        NAME: "Kimball",
        LSAD: "06",
        ALAND: 2465269097,
        AWATER: 1448446
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.053142, 41.114456999065219],
            [-104.052453, 41.278201999064514],
            [-104.052287134121002, 41.393213999257433],
            [-103.37762, 41.394632999064022],
            [-103.376021, 41.047770999065513],
            [-103.382491651890007, 41.001926145686717],
            [-103.573774006195009, 41.001721798149816],
            [-103.574522, 41.001720999065718],
            [-104.053249, 41.001405999065717],
            [-104.053142, 41.114456999065219]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "045",
        COUNTYNS: "00198138",
        AFFGEOID: "0500000US08045",
        GEOID: "08045",
        NAME: "Garfield",
        LSAD: "06",
        ALAND: 7634095739,
        AWATER: 21408198
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.051066846272988, 39.497738808440012],
            [-109.050871925262015, 39.660471776232214],
            [-108.895728, 39.650336999072884],
            [-108.554627, 39.650163999072873],
            [-108.554722, 39.693639999072616],
            [-107.938546, 39.694463999072603],
            [-107.938502, 39.824995999071838],
            [-107.43177, 39.828376999071807],
            [-107.431665, 39.917724999071282],
            [-107.318751, 39.914967999071308],
            [-107.316993, 40.090764999070309],
            [-107.037363, 40.091537999070304],
            [-107.03205, 40.002938999070807],
            [-107.033968, 39.918912999071281],
            [-107.113672, 39.919111999071276],
            [-107.113446, 39.366065999074635],
            [-107.430949, 39.366177999074644],
            [-108.106015, 39.366150999074641],
            [-109.051223831016998, 39.366677548660938],
            [-109.051066846272988, 39.497738808440012]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "067",
        COUNTYNS: "00424235",
        AFFGEOID: "0500000US17067",
        GEOID: "17067",
        NAME: "Hancock",
        LSAD: "06",
        ALAND: 2055740566,
        AWATER: 53535827
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.496957490339483, 40.248703875442253],
            [-91.492891, 40.26992299906933],
            [-91.469656, 40.322408999069054],
            [-91.419422, 40.378263999068764],
            [-91.372921, 40.399107999068654],
            [-91.379907, 40.452109999068377],
            [-91.367876, 40.51047899906807],
            [-91.394475, 40.534542999067966],
            [-91.374252, 40.582589999067714],
            [-91.339719, 40.613487999067551],
            [-91.247851, 40.638389999067442],
            [-91.18698, 40.637296999067445],
            [-91.18546060870429, 40.638111811690941],
            [-90.904216, 40.639200999067434],
            [-90.909756, 40.284393999069259],
            [-90.911969, 40.193087999069753],
            [-91.506167908622274, 40.200643511864811],
            [-91.496957490339483, 40.248703875442253]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "173",
        COUNTYNS: "00659531",
        AFFGEOID: "0500000US27173",
        GEOID: "27173",
        NAME: "Yellow Medicine",
        LSAD: "06",
        ALAND: 1966062971,
        AWATER: 10687958
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.454830515553795, 44.805550203102577],
            [-95.849009, 44.805346999056781],
            [-95.847452, 44.891798999056768],
            [-95.737309, 44.891478999056766],
            [-95.736696, 44.93603999905676],
            [-95.498078, 44.789460999056786],
            [-95.482843, 44.752504999056789],
            [-95.415854, 44.734504999056782],
            [-95.360177, 44.698539999056798],
            [-95.3604, 44.541761999056867],
            [-95.594817, 44.54230499905686],
            [-95.604001, 44.629944999056818],
            [-96.09295, 44.630485999056816],
            [-96.453806699667098, 44.631335831004826],
            [-96.454830515553795, 44.805550203102577]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "145",
        COUNTYNS: "00835894",
        AFFGEOID: "0500000US31145",
        GEOID: "31145",
        NAME: "Red Willow",
        LSAD: "06",
        ALAND: 1856984023,
        AWATER: 2643458
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.758435, 40.349506999068907],
            [-100.197807, 40.350026999068916],
            [-100.193599056836007, 40.001573004790714],
            [-100.477018, 40.001751999070812],
            [-100.738824713191008, 40.002262955638507],
            [-100.75883, 40.002301999070816],
            [-100.758435, 40.349506999068907]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "089",
        COUNTYNS: "01687424",
        AFFGEOID: "0500000US13089",
        GEOID: "13089",
        NAME: "DeKalb",
        LSAD: "06",
        ALAND: 693144670,
        AWATER: 9191912
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.348091, 33.845292999126812],
            [-84.348298, 33.857485999126666],
            [-84.348315, 33.89090299912624],
            [-84.346955, 33.968142999125284],
            [-84.277093, 33.957613999125428],
            [-84.237113, 33.904446999126066],
            [-84.17313, 33.858114999126656],
            [-84.075914, 33.77750699912766],
            [-84.023713, 33.752807999127981],
            [-84.115791, 33.61466599912972],
            [-84.184143, 33.64615699912931],
            [-84.245453, 33.630729999129514],
            [-84.266882, 33.647169999129304],
            [-84.281273, 33.647410999129299],
            [-84.350224, 33.647907999129295],
            [-84.348091, 33.845292999126812]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "077",
        COUNTYNS: "01248015",
        AFFGEOID: "0500000US45077",
        GEOID: "45077",
        NAME: "Pickens",
        LSAD: "06",
        ALAND: 1285538264,
        AWATER: 40609723
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.943604, 34.98815399911328],
            [-82.897499, 35.056020999112519],
            [-82.783283, 35.085599999112183],
            [-82.762059552113584, 35.081874435116319],
            [-82.749351, 35.047828999112596],
            [-82.571378, 35.074558999112298],
            [-82.594141, 35.008204999113055],
            [-82.537866, 35.015695999112971],
            [-82.486746, 34.819474999115187],
            [-82.788664, 34.67201699911687],
            [-82.840253, 34.623211999117437],
            [-82.855425, 34.724805999116271],
            [-82.900144, 34.742439999116058],
            [-82.915785, 34.971835999113452],
            [-82.943604, 34.98815399911328]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "131",
        COUNTYNS: "00485029",
        AFFGEOID: "0500000US20131",
        GEOID: "20131",
        NAME: "Nemaha",
        LSAD: "06",
        ALAND: 1858124407,
        AWATER: 5208983
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.239207878411094, 40.000691041825213],
            [-96.239172, 40.000690999070812],
            [-96.154365, 40.000494999070824],
            [-96.02409, 40.000718999070813],
            [-96.010678817934505, 40.000704664842829],
            [-95.788110994891696, 40.00046677843612],
            [-95.788941, 39.653000999072859],
            [-95.789101, 39.56591599907339],
            [-96.035952, 39.566099999073387],
            [-96.238969, 39.56621999907339],
            [-96.2395, 39.914344999071318],
            [-96.239207878411094, 40.000691041825213]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "111",
        COUNTYNS: "00069174",
        AFFGEOID: "0500000US05111",
        GEOID: "05111",
        NAME: "Poinsett",
        LSAD: "06",
        ALAND: 1964147526,
        AWATER: 13521720
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.036967, 35.708417999105471],
            [-90.286736, 35.699913999105554],
            [-90.286899, 35.438572999108324],
            [-90.502429, 35.441725999108286],
            [-90.610753, 35.448768999108211],
            [-91.03923, 35.444716999108259],
            [-91.036967, 35.708417999105471]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "175",
        COUNTYNS: "00424288",
        AFFGEOID: "0500000US17175",
        GEOID: "17175",
        NAME: "Stark",
        LSAD: "06",
        ALAND: 746121490,
        AWATER: 708705
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.984559, 41.149365999065061],
            [-89.868384, 41.148954999065069],
            [-89.867895, 41.216229999064765],
            [-89.857798, 41.234482999064703],
            [-89.638864, 41.233861999064693],
            [-89.638429, 41.148590999065064],
            [-89.638528, 41.141320999065094],
            [-89.638727, 40.973706999065833],
            [-89.985479, 40.97449499906584],
            [-89.984559, 41.149365999065061]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "147",
        COUNTYNS: "01008578",
        AFFGEOID: "0500000US37147",
        GEOID: "37147",
        NAME: "Pitt",
        LSAD: "06",
        ALAND: 1689619168,
        AWATER: 8248766
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.665131, 35.674934999105808],
            [-77.531262, 35.753527999104989],
            [-77.390154, 35.833061999104174],
            [-77.350369, 35.819227999104314],
            [-77.2552, 35.78635199910466],
            [-77.174, 35.732830999105204],
            [-77.195772, 35.699778999105561],
            [-77.100803, 35.551454999107115],
            [-77.176178, 35.518811999107477],
            [-77.189115, 35.418361999108548],
            [-77.247896, 35.354414999109224],
            [-77.29434, 35.379136999108972],
            [-77.391026, 35.33953299910938],
            [-77.426448, 35.349758999109277],
            [-77.475515, 35.426654999108457],
            [-77.503716, 35.51812399910748],
            [-77.61037, 35.567551999106954],
            [-77.700692, 35.652294999106054],
            [-77.665131, 35.674934999105808]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "147",
        COUNTYNS: "00465262",
        AFFGEOID: "0500000US19147",
        GEOID: "19147",
        NAME: "Palo Alto",
        LSAD: "06",
        ALAND: 1460391661,
        AWATER: 14437623
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.913723, 43.255053999058418],
            [-94.443137, 43.255013999058413],
            [-94.442954, 42.908072999059165],
            [-94.91389, 42.909699999059157],
            [-94.913723, 43.255053999058418]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "081",
        COUNTYNS: "00465229",
        AFFGEOID: "0500000US19081",
        GEOID: "19081",
        NAME: "Hancock",
        LSAD: "06",
        ALAND: 1478895055,
        AWATER: 5265568
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.970415, 43.255357999058418],
            [-93.497635, 43.255467999058425],
            [-93.498617, 42.908511999059151],
            [-93.971238, 42.907761999059161],
            [-93.970415, 43.255357999058418]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "229",
        COUNTYNS: "00758567",
        AFFGEOID: "0500000US29229",
        GEOID: "29229",
        NAME: "Wright",
        LSAD: "06",
        ALAND: 1765780148,
        AWATER: 3533325
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.686671, 37.481544999088662],
            [-92.252261, 37.472943999088741],
            [-92.251359, 37.059652999092357],
            [-92.685867, 37.067050999092281],
            [-92.686671, 37.481544999088662]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "073",
        COUNTYNS: "00835858",
        AFFGEOID: "0500000US31073",
        GEOID: "31073",
        NAME: "Gosper",
        LSAD: "06",
        ALAND: 1186616240,
        AWATER: 11831826
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.095095, 40.438655999068459],
            [-99.981193, 40.43829099906845],
            [-99.981743, 40.700002999067138],
            [-99.699269, 40.699968999067138],
            [-99.643462, 40.684910999067199],
            [-99.642209, 40.351199999068903],
            [-100.094798, 40.351244999068903],
            [-100.095095, 40.438655999068459]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "043",
        COUNTYNS: "01034206",
        AFFGEOID: "0500000US38043",
        GEOID: "38043",
        NAME: "Kidder",
        LSAD: "06",
        ALAND: 3499507647,
        AWATER: 211725374
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.113261, 47.327261999059999],
            [-100.033292, 47.327191999059984],
            [-99.480726, 47.327075999060007],
            [-99.481085, 46.980693999059127],
            [-99.446423, 46.980888999059133],
            [-99.44972, 46.63162999905839],
            [-99.915508, 46.632924999058389],
            [-100.081198, 46.633395999058365],
            [-100.075229, 46.981281999059128],
            [-100.113261, 47.327261999059999]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "015",
        COUNTYNS: "01101795",
        AFFGEOID: "0500000US40015",
        GEOID: "40015",
        NAME: "Caddo",
        LSAD: "06",
        ALAND: 3310745123,
        AWATER: 30820525
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.623149, 35.551596999107105],
            [-98.313251, 35.551522999107114],
            [-98.306326, 35.377238999108982],
            [-98.096014, 35.377752999108978],
            [-98.092645, 34.854918999114773],
            [-98.619816, 34.854952999114786],
            [-98.621054, 35.097351999112057],
            [-98.61824, 35.117173999111827],
            [-98.623336, 35.464224999108048],
            [-98.623149, 35.551596999107105]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "075",
        COUNTYNS: "01214034",
        AFFGEOID: "0500000US42075",
        GEOID: "42075",
        NAME: "Lebanon",
        LSAD: "06",
        ALAND: 937138344,
        AWATER: 1727921
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.67804, 40.474716999068264],
            [-76.535414, 40.555156999067862],
            [-76.440181, 40.495951999068147],
            [-76.341021, 40.434357999068467],
            [-76.307108, 40.413437999068577],
            [-76.205528, 40.360660999068855],
            [-76.151215, 40.315970999069094],
            [-76.307578, 40.255065999069416],
            [-76.466307, 40.228399999069559],
            [-76.566339, 40.196643999069735],
            [-76.578128, 40.217853999069611],
            [-76.67804, 40.474716999068264]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "069",
        COUNTYNS: "01383820",
        AFFGEOID: "0500000US48069",
        GEOID: "48069",
        NAME: "Castro",
        LSAD: "06",
        ALAND: 2316573415,
        AWATER: 12584396
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.525184, 34.74693099911601],
            [-102.168839, 34.747416999116005],
            [-101.998493, 34.748189999116001],
            [-101.99802, 34.313038999121098],
            [-102.090415, 34.31313199912109],
            [-102.525631, 34.31303399912111],
            [-102.525184, 34.74693099911601]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "279",
        COUNTYNS: "01383926",
        AFFGEOID: "0500000US48279",
        GEOID: "48279",
        NAME: "Lamb",
        LSAD: "06",
        ALAND: 2631897577,
        AWATER: 3973764
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.61515, 34.312890999121109],
            [-102.525631, 34.31303399912111],
            [-102.090415, 34.31313199912109],
            [-102.085733, 33.824674999127076],
            [-102.615447, 33.825120999127058],
            [-102.61515, 34.312890999121109]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "385",
        COUNTYNS: "01383978",
        AFFGEOID: "0500000US48385",
        GEOID: "48385",
        NAME: "Real",
        LSAD: "06",
        ALAND: 1810812906,
        AWATER: 2346258
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.03088, 29.848272999184953],
            [-100.002302, 29.896827999184147],
            [-99.967626, 30.08235599918109],
            [-99.757621, 30.074131999181226],
            [-99.689879, 30.073947999181236],
            [-99.691215, 29.908581999183959],
            [-99.602776, 29.907678999183968],
            [-99.60313, 29.627180999188646],
            [-100.014188, 29.623494999188708],
            [-100.03088, 29.848272999184953]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "141",
        COUNTYNS: "01581130",
        AFFGEOID: "0500000US55141",
        GEOID: "55141",
        NAME: "Wood",
        LSAD: "06",
        ALAND: 2053928984,
        AWATER: 42054151
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.316055, 44.424501999056922],
            [-90.316253, 44.685153999056816],
            [-89.963861, 44.684827999056793],
            [-89.844931, 44.68494499905681],
            [-89.84441, 44.511541999056874],
            [-89.726622, 44.511190999056872],
            [-89.724746, 44.247677999057046],
            [-89.902783, 44.249472999057048],
            [-90.080691, 44.249010999057035],
            [-90.31268, 44.248749999057054],
            [-90.317818, 44.265949999057028],
            [-90.316055, 44.424501999056922]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "109",
        COUNTYNS: "01804535",
        AFFGEOID: "0500000US72109",
        GEOID: "72109",
        NAME: "Patillas",
        LSAD: "13",
        ALAND: 120695652,
        AWATER: 74074554
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.089399, 18.056255999443326],
            [-66.053146, 18.107292999441974],
            [-66.051788, 18.109877999441903],
            [-66.010245, 18.075564999442815],
            [-65.988523, 18.058940999443259],
            [-65.9819, 18.0348399994439],
            [-65.914936083488399, 17.982765902364989],
            [-65.98455, 17.969410999445646],
            [-66.017954834139402, 17.974902263054997],
            [-66.057263, 18.040433999443746],
            [-66.082263, 18.032957999443951],
            [-66.089399, 18.056255999443326]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "099",
        COUNTYNS: "01581110",
        AFFGEOID: "0500000US55099",
        GEOID: "55099",
        NAME: "Price",
        LSAD: "06",
        ALAND: 3247805080,
        AWATER: 62281745
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.678749, 45.638263999057031],
            [-90.676968, 45.981553999057368],
            [-90.302063, 45.981348999057374],
            [-90.043958, 45.981947999057375],
            [-90.042857, 45.897262999057276],
            [-90.043414, 45.555081999056974],
            [-90.042273, 45.381865999056863],
            [-90.678773, 45.377906999056862],
            [-90.678749, 45.638263999057031]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "087",
        COUNTYNS: "01804524",
        AFFGEOID: "0500000US72087",
        GEOID: "72087",
        NAME: "Loíza",
        LSAD: "13",
        ALAND: 50086873,
        AWATER: 119822959
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-65.99079, 18.460418999432633],
            [-65.904988, 18.450925999432883],
            [-65.831476, 18.426848999433517],
            [-65.827737477559012, 18.425562488942646],
            [-65.867447, 18.378197999434796],
            [-65.869949, 18.408914999433986],
            [-65.919278, 18.402992999434147],
            [-65.9304, 18.428477999433468],
            [-65.992803754656293, 18.460171553896433],
            [-65.99079, 18.460418999432633]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "057",
        COUNTYNS: "00929112",
        AFFGEOID: "0500000US35057",
        GEOID: "35057",
        NAME: "Torrance",
        LSAD: "06",
        ALAND: 8664074710,
        AWATER: 2303485
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.466707, 34.717010999116361],
            [-106.466639, 34.754863999115919],
            [-106.464775, 34.783202999115595],
            [-106.464546, 34.810516999115286],
            [-106.463868, 34.870193999114605],
            [-106.411781, 34.870251999114615],
            [-106.149737, 34.870141999114608],
            [-106.149828, 34.953461999113671],
            [-106.243762, 34.953354999113664],
            [-106.243874, 35.040002999112694],
            [-106.194713, 35.040255999112702],
            [-105.714419, 35.041604999112671],
            [-105.290791, 35.042029999112671],
            [-105.290396, 34.606765999117634],
            [-105.312765, 34.606950999117622],
            [-105.313072, 34.347204999120692],
            [-105.313218, 34.260030999121732],
            [-105.925968, 34.259989999121736],
            [-106.415972, 34.259821999121748],
            [-106.416924, 34.439686999119587],
            [-106.416881, 34.521714999118629],
            [-106.46979, 34.521216999118636],
            [-106.466707, 34.717010999116361]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "107",
        COUNTYNS: "01622996",
        AFFGEOID: "0500000US26107",
        GEOID: "26107",
        NAME: "Mecosta",
        LSAD: "06",
        ALAND: 1437745751,
        AWATER: 41762966
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.562433, 43.815482999057508],
            [-85.088811, 43.813675999057509],
            [-85.084996, 43.466189999058031],
            [-85.562348, 43.468129999058014],
            [-85.562433, 43.815482999057508]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "003",
        COUNTYNS: "00758456",
        AFFGEOID: "0500000US29003",
        GEOID: "29003",
        NAME: "Andrew",
        LSAD: "06",
        ALAND: 1120659795,
        AWATER: 9686701
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.04308, 40.130095999070093],
            [-94.605942, 40.126126999070117],
            [-94.605377, 40.039216999070604],
            [-94.602819, 39.81960799907187],
            [-94.8778175298512, 39.820414775107352],
            [-94.878677, 39.826521999071822],
            [-94.928466, 39.876343999071523],
            [-94.95154, 39.900532999071388],
            [-94.993374239714385, 39.898565259063709],
            [-95.010542, 40.046760999070557],
            [-95.060797, 40.070261999070425],
            [-95.04308, 40.130095999070093]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "097",
        COUNTYNS: "01209186",
        AFFGEOID: "0500000US42097",
        GEOID: "42097",
        NAME: "Northumberland",
        LSAD: "06",
        ALAND: 1186219234,
        AWATER: 50572294
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.939749, 40.63837799906743],
            [-76.864014, 40.706692999067101],
            [-76.849645, 40.809009999066618],
            [-76.798747, 40.87805199906628],
            [-76.800242, 40.881993999066268],
            [-76.874719, 40.948143999065969],
            [-76.858335, 41.073077999065404],
            [-76.896114, 41.1390699990651],
            [-76.883833, 41.157413999065021],
            [-76.732672, 41.172039999064957],
            [-76.734891, 41.092132999065321],
            [-76.79261, 40.946646999065969],
            [-76.644446, 40.964529999065888],
            [-76.557013, 40.938616999066006],
            [-76.528034, 40.882514999066267],
            [-76.501312, 40.824816999066535],
            [-76.409667, 40.833623999066496],
            [-76.380152, 40.775510999066768],
            [-76.687266, 40.665600999067301],
            [-76.701624, 40.658081999067342],
            [-76.919272, 40.603543999067611],
            [-76.949414, 40.628162999067492],
            [-76.939749, 40.63837799906743]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "141",
        COUNTYNS: "01784894",
        AFFGEOID: "0500000US17141",
        GEOID: "17141",
        NAME: "Ogle",
        LSAD: "06",
        ALAND: 1964706939,
        AWATER: 11425029
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.688486, 42.19911199906111],
            [-89.396192, 42.201915999061107],
            [-89.173, 42.204240999061099],
            [-89.172799, 42.150296999061261],
            [-89.091831, 42.150680999061272],
            [-88.939732, 42.152319999061255],
            [-88.941279, 41.891751999062144],
            [-89.414627, 41.886174999062163],
            [-89.426727, 41.908417999062081],
            [-89.62933, 41.901616999062099],
            [-89.685366, 41.930339999061999],
            [-89.688486, 42.19911199906111]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "091",
        COUNTYNS: "00306915",
        AFFGEOID: "0500000US12091",
        GEOID: "12091",
        NAME: "Okaloosa",
        LSAD: "06",
        ALAND: 2409367483,
        AWATER: 393201898
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.785691976005893, 30.996982992683357],
            [-86.688240964246091, 30.99620188916807],
            [-86.563494, 30.995201999166589],
            [-86.388644662000402, 30.994528214441797],
            [-86.397379606502398, 30.377495279595415],
            [-86.412076, 30.380345999176267],
            [-86.632953, 30.396298999176015],
            [-86.800351351965887, 30.384508086553794],
            [-86.785691976005893, 30.996982992683357]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "075",
        COUNTYNS: "00424239",
        AFFGEOID: "0500000US17075",
        GEOID: "17075",
        NAME: "Iroquois",
        LSAD: "06",
        ALAND: 2893780721,
        AWATER: 4225839
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.131938, 40.997838999065735],
            [-87.526463305991186, 41.010354817030169],
            [-87.526014, 40.895581999066195],
            [-87.526136490423681, 40.736885080489856],
            [-87.526292, 40.535408999067961],
            [-87.526875773253778, 40.491224408758569],
            [-87.935309, 40.485922999068208],
            [-88.117905, 40.488085999068183],
            [-88.118191, 40.516981999068058],
            [-88.131938, 40.997838999065735]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "059",
        COUNTYNS: "00465218",
        AFFGEOID: "0500000US19059",
        GEOID: "19059",
        NAME: "Dickinson",
        LSAD: "06",
        ALAND: 985539078,
        AWATER: 60716111
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.387787594021873, 43.500478727965373],
            [-95.214938, 43.500884999057966],
            [-94.914613484713598, 43.500595872044059],
            [-94.913723, 43.255053999058418],
            [-95.388078, 43.255220999058423],
            [-95.387787594021873, 43.500478727965373]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "129",
        COUNTYNS: "00485135",
        AFFGEOID: "0500000US20129",
        GEOID: "20129",
        NAME: "Morton",
        LSAD: "06",
        ALAND: 1889993244,
        AWATER: 507796
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.04192, 37.035082999092559],
            [-102.041983, 37.106550999091915],
            [-102.041963, 37.258163999090606],
            [-102.041938522873991, 37.389190054483251],
            [-101.556606, 37.388484999089457],
            [-101.555258848690983, 36.995290938370523],
            [-101.90244, 36.993701999092934],
            [-102.028204191044992, 36.993145146201243],
            [-102.04224, 36.993082999092934],
            [-102.04192, 37.035082999092559]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "153",
        COUNTYNS: "00695797",
        AFFGEOID: "0500000US28153",
        GEOID: "28153",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 2099745578,
        AWATER: 7255476
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.943355, 31.824564999154145],
            [-88.910459, 31.826648999154116],
            [-88.809174, 31.832896999154023],
            [-88.625036, 31.856688999153675],
            [-88.468662484051293, 31.893855710768733],
            [-88.468669, 31.790721999154645],
            [-88.463625304684896, 31.697942370506198],
            [-88.459478, 31.621651999157123],
            [-88.449446069017085, 31.435836860437494],
            [-88.841707, 31.433702999159916],
            [-88.943468, 31.433457999159927],
            [-88.943355, 31.824564999154145]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "073",
        COUNTYNS: "00974135",
        AFFGEOID: "0500000US36073",
        GEOID: "36073",
        NAME: "Orleans",
        LSAD: "06",
        ALAND: 1013366849,
        AWATER: 1103741896
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.465551187596091, 43.370895421224603],
            [-78.329374, 43.373148999058195],
            [-78.145195, 43.375509999058188],
            [-77.995589794938397, 43.365310254735817],
            [-77.995698, 43.284962999058358],
            [-77.99729, 43.132980999058667],
            [-78.465505, 43.128618999058673],
            [-78.465551187596091, 43.370895421224603]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "003",
        COUNTYNS: "00974100",
        AFFGEOID: "0500000US36003",
        GEOID: "36003",
        NAME: "Allegany",
        LSAD: "06",
        ALAND: 2665875177,
        AWATER: 13153776
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.308839, 42.521216999060165],
            [-78.038261, 42.521521999060141],
            [-77.840694, 42.517770999060161],
            [-77.840231, 42.474575999060292],
            [-77.722964, 42.471215999060306],
            [-77.731029, 42.403939999060484],
            [-77.749930925127288, 41.99875543939077],
            [-77.83203, 41.99852399906176],
            [-78.031177, 41.999414999061777],
            [-78.206603037858201, 41.999088304819388],
            [-78.271204, 41.998967999061769],
            [-78.308128960446595, 41.999071133803163],
            [-78.308839, 42.521216999060165]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "009",
        COUNTYNS: "01480095",
        AFFGEOID: "0500000US51009",
        GEOID: "51009",
        NAME: "Amherst",
        LSAD: "06",
        ALAND: 1227554626,
        AWATER: 12883346
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.345235, 37.660624999087155],
            [-79.231557, 37.813174999085909],
            [-79.172131, 37.803096999085994],
            [-79.137964, 37.814140999085907],
            [-79.071417, 37.778302999086193],
            [-79.067355, 37.72025499908667],
            [-78.986017, 37.695545999086875],
            [-78.869245, 37.54209199908815],
            [-78.949942, 37.503141999088477],
            [-79.021687, 37.429242999089112],
            [-79.067923, 37.385845999089483],
            [-79.085123, 37.394874999089403],
            [-79.120279, 37.398186999089376],
            [-79.185702, 37.465819999088801],
            [-79.34167, 37.518014999088358],
            [-79.436947, 37.617056999087517],
            [-79.345235, 37.660624999087155]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "267",
        COUNTYNS: "00347445",
        AFFGEOID: "0500000US13267",
        GEOID: "13267",
        NAME: "Tattnall",
        LSAD: "06",
        ALAND: 1241979177,
        AWATER: 22837730
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.232788, 32.318837999147064],
            [-82.025339, 32.278882999147626],
            [-81.980041, 32.081501999150433],
            [-81.888938, 32.050397999150881],
            [-81.761735, 32.047899999150921],
            [-81.824405, 32.014881999151392],
            [-81.981389, 31.79457799915458],
            [-81.969052, 31.789323999154664],
            [-81.982941, 31.784903999154711],
            [-82.048582, 31.827074999154114],
            [-82.111818, 31.900486999153042],
            [-82.225042, 31.913073999152857],
            [-82.181926, 32.165944999149232],
            [-82.232788, 32.318837999147064]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "053",
        COUNTYNS: "00395662",
        AFFGEOID: "0500000US16053",
        GEOID: "16053",
        NAME: "Jerome",
        LSAD: "06",
        ALAND: 1547386409,
        AWATER: 13152108
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.617401, 42.850887999059296],
            [-114.594375, 42.850900999059292],
            [-114.365366, 42.850789999059295],
            [-114.345659, 42.809054999059406],
            [-114.049849, 42.808443999059406],
            [-114.049961, 42.765005999059518],
            [-113.931687, 42.76502599905951],
            [-113.931799, 42.535242999060117],
            [-114.000388, 42.526372999060143],
            [-114.045454, 42.528698999060133],
            [-114.13035, 42.494905999060222],
            [-114.244703, 42.559243999060037],
            [-114.465709, 42.599672999059941],
            [-114.616487, 42.649297999059812],
            [-114.617401, 42.850887999059296]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "039",
        COUNTYNS: "00974118",
        AFFGEOID: "0500000US36039",
        GEOID: "36039",
        NAME: "Greene",
        LSAD: "06",
        ALAND: 1676140490,
        AWATER: 28201061
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.53731, 42.201423999061106],
            [-74.443506, 42.355016999060638],
            [-74.244692, 42.377158999060569],
            [-74.254303, 42.408206999060468],
            [-73.783721, 42.46423099906032],
            [-73.786832, 42.273006999060875],
            [-73.910675, 42.127292999061332],
            [-74.00245, 42.176991999061187],
            [-74.074797, 42.096588999061453],
            [-74.307571, 42.114345999061385],
            [-74.451713, 42.1692249990612],
            [-74.53731, 42.201423999061106]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "077",
        COUNTYNS: "01622981",
        AFFGEOID: "0500000US26077",
        GEOID: "26077",
        NAME: "Kalamazoo",
        LSAD: "06",
        ALAND: 1456083085,
        AWATER: 46882315
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.764697, 42.420714999060451],
            [-85.543191, 42.421432999060443],
            [-85.298879, 42.419848999060449],
            [-85.293626, 42.071552999061531],
            [-85.762943, 42.069326999061538],
            [-85.764697, 42.420714999060451]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "123",
        COUNTYNS: "01623004",
        AFFGEOID: "0500000US26123",
        GEOID: "26123",
        NAME: "Newaygo",
        LSAD: "06",
        ALAND: 2112606166,
        AWATER: 119183856
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.039491, 43.467446999058026],
            [-86.037884, 43.815610999057505],
            [-85.562433, 43.815482999057508],
            [-85.562348, 43.468129999058014],
            [-85.562538, 43.294270999058348],
            [-85.790448, 43.293002999058352],
            [-86.038953, 43.293518999058342],
            [-86.039491, 43.467446999058026]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "073",
        COUNTYNS: "00485003",
        AFFGEOID: "0500000US20073",
        GEOID: "20073",
        NAME: "Greenwood",
        LSAD: "06",
        ALAND: 2961121922,
        AWATER: 24166737
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.522782, 38.086369999083729],
            [-96.358099, 38.085816999083733],
            [-96.357277, 38.172659999083059],
            [-95.958862, 38.17093899908307],
            [-95.959046, 38.040194999084093],
            [-95.960876, 37.734303999086556],
            [-95.961002, 37.603760999087633],
            [-96.5253, 37.60701499908761],
            [-96.522782, 38.086369999083729]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "035",
        COUNTYNS: "00835839",
        AFFGEOID: "0500000US31035",
        GEOID: "31035",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 1482222903,
        AWATER: 3234878
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.278103, 40.698290999067147],
            [-97.825723, 40.698554999067142],
            [-97.82478, 40.698536999067137],
            [-97.824114, 40.350540999068905],
            [-98.273571, 40.350358999068902],
            [-98.27809, 40.350346999068911],
            [-98.278103, 40.698290999067147]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "011",
        COUNTYNS: "00835828",
        AFFGEOID: "0500000US31011",
        GEOID: "31011",
        NAME: "Boone",
        LSAD: "06",
        ALAND: 1778166034,
        AWATER: 1718583
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.295602, 41.914953999062057],
            [-97.833288, 41.916285999062062],
            [-97.830493, 41.742237999062688],
            [-97.829846, 41.526173999063502],
            [-98.041328, 41.510533999063561],
            [-98.099523, 41.480776999063664],
            [-98.291344, 41.48072699906367],
            [-98.290992, 41.719786999062769],
            [-98.29576, 41.741177999062678],
            [-98.295602, 41.914953999062057]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "081",
        COUNTYNS: "01008558",
        AFFGEOID: "0500000US37081",
        GEOID: "37081",
        NAME: "Guilford",
        LSAD: "06",
        ALAND: 1672545660,
        AWATER: 30723331
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.043238, 36.010757999102353],
            [-80.038554, 36.146462999100997],
            [-80.035119, 36.257183999099894],
            [-79.53241, 36.241462999100044],
            [-79.541728, 35.899850999103485],
            [-79.713233, 35.906789999103424],
            [-80.046869, 35.920692999103274],
            [-80.043238, 36.010757999102353]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "061",
        COUNTYNS: "01266979",
        AFFGEOID: "0500000US46061",
        GEOID: "46061",
        NAME: "Hanson",
        LSAD: "06",
        ALAND: 1125387966,
        AWATER: 2248724
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.967279, 43.850685999057447],
            [-97.85111, 43.849856999057451],
            [-97.608517, 43.849058999057462],
            [-97.607012, 43.499825999057975],
            [-97.965012, 43.499039999057992],
            [-97.967279, 43.850685999057447]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "50",
        COUNTYFP: "015",
        COUNTYNS: "01461764",
        AFFGEOID: "0500000US50015",
        GEOID: "50015",
        NAME: "Lamoille",
        LSAD: "06",
        ALAND: 1188473310,
        AWATER: 12658077
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.924112, 44.63305099905682],
            [-72.851068, 44.711069999056797],
            [-72.795505, 44.692199999056797],
            [-72.786134, 44.727722999056788],
            [-72.74736, 44.786169999056781],
            [-72.578161, 44.781577999056779],
            [-72.417315, 44.722892999056796],
            [-72.482302, 44.633927999056816],
            [-72.374786, 44.58412499905684],
            [-72.434315, 44.506097999056877],
            [-72.492914, 44.423029999056915],
            [-72.596807, 44.47929199905689],
            [-72.654398, 44.398223999056938],
            [-72.765856, 44.442594999056915],
            [-72.804995, 44.451675999056903],
            [-72.838638, 44.459228999056911],
            [-72.809349, 44.547920999056856],
            [-72.855735, 44.577223999056848],
            [-72.924112, 44.63305099905682]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "095",
        COUNTYNS: "01804528",
        AFFGEOID: "0500000US72095",
        GEOID: "72095",
        NAME: "Maunabo",
        LSAD: "13",
        ALAND: 54816722,
        AWATER: 44549509
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-65.988523, 18.058940999443259],
            [-65.864692, 18.025736999444145],
            [-65.850918555785199, 18.011966436323309],
            [-65.884937, 17.988520999445136],
            [-65.914936083488399, 17.982765902364989],
            [-65.9819, 18.0348399994439],
            [-65.988523, 18.058940999443259]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "031",
        COUNTYNS: "01804495",
        AFFGEOID: "0500000US72031",
        GEOID: "72031",
        NAME: "Carolina",
        LSAD: "13",
        ALAND: 117502258,
        AWATER: 38734577
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-65.992803754656293, 18.460171553896433],
            [-65.9304, 18.428477999433468],
            [-65.919278, 18.402992999434147],
            [-65.901782, 18.316157999436435],
            [-65.918535, 18.270129999437653],
            [-65.942429, 18.293468999437035],
            [-65.938908, 18.309169999436616],
            [-65.999556, 18.380064999434747],
            [-65.991415, 18.398573999434259],
            [-66.03432184747949, 18.455069906231671],
            [-65.992803754656293, 18.460171553896433]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "069",
        COUNTYNS: "01581094",
        AFFGEOID: "0500000US55069",
        GEOID: "55069",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 2276114436,
        AWATER: 72463206
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.042273, 45.381865999056863],
            [-90.043414, 45.555081999056974],
            [-89.428258, 45.555352999056979],
            [-89.424824, 45.467165999056917],
            [-89.425971, 45.119097999056784],
            [-90.043685, 45.12021399905678],
            [-90.042273, 45.381865999056863]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "091",
        COUNTYNS: "01639760",
        AFFGEOID: "0500000US47091",
        GEOID: "47091",
        NAME: "Johnson",
        LSAD: "06",
        ALAND: 772960730,
        AWATER: 11007740
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.94984, 36.472384999097798],
            [-81.983843, 36.50831399909746],
            [-81.871443, 36.602014999096568],
            [-81.826734148052608, 36.614719103257841],
            [-81.6469, 36.61191799909647],
            [-81.677535, 36.588116999096698],
            [-81.699962, 36.536828999097189],
            [-81.695311, 36.467911999097844],
            [-81.734312, 36.413341999098378],
            [-81.725372779685287, 36.389738496534697],
            [-81.705966, 36.338495999099102],
            [-81.768977, 36.341041999099069],
            [-81.833202, 36.347338999099016],
            [-81.908137, 36.302012999099453],
            [-81.918444472904795, 36.287357916481703],
            [-81.934369244492586, 36.264716203479637],
            [-82.058545, 36.367214999098813],
            [-81.94984, 36.472384999097798]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "145",
        COUNTYNS: "00465261",
        AFFGEOID: "0500000US19145",
        GEOID: "19145",
        NAME: "Page",
        LSAD: "06",
        ALAND: 1385497544,
        AWATER: 1359120
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.384964, 40.901549999066184],
            [-94.928459, 40.900652999066182],
            [-94.928529, 40.727398999066999],
            [-94.914897867361603, 40.574921168179038],
            [-95.068921, 40.576879999067735],
            [-95.202266000830292, 40.578375628063739],
            [-95.335588, 40.57987099906773],
            [-95.373925053398679, 40.580332377009221],
            [-95.370618, 40.728240999067005],
            [-95.384964, 40.901549999066184]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "119",
        COUNTYNS: "00198175",
        AFFGEOID: "0500000US08119",
        GEOID: "08119",
        NAME: "Teller",
        LSAD: "06",
        ALAND: 1442734233,
        AWATER: 4944578
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.32922, 39.129688999076173],
            [-105.033544, 39.129818999076178],
            [-105.028903, 38.868906999077957],
            [-105.072184, 38.799381999078435],
            [-104.938994, 38.796883999078453],
            [-104.942426, 38.64988199907949],
            [-105.240362, 38.647594999079516],
            [-105.237792, 38.696900999079162],
            [-105.329134, 38.697204999079162],
            [-105.330444, 39.129684999076176],
            [-105.32922, 39.129688999076173]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "139",
        COUNTYNS: "00758524",
        AFFGEOID: "0500000US29139",
        GEOID: "29139",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 1385765331,
        AWATER: 14100636
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.647171, 38.70339599907912],
            [-91.633998, 39.059056999076653],
            [-91.629598, 39.147852999076058],
            [-91.409033, 39.142541999076101],
            [-91.260232, 39.139844999076118],
            [-91.264287, 38.992761999077103],
            [-91.264927, 38.843832999078131],
            [-91.415832, 38.845299999078122],
            [-91.418637, 38.709777999079073],
            [-91.558185, 38.676634999079312],
            [-91.640372, 38.703791999079122],
            [-91.647171, 38.70339599907912]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "127",
        COUNTYNS: "00559594",
        AFFGEOID: "0500000US22127",
        GEOID: "22127",
        NAME: "Winn",
        LSAD: "15",
        ALAND: 2460711982,
        AWATER: 17448075
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.9668, 31.781298999154778],
            [-92.872174, 31.945855999152386],
            [-92.93908, 32.147941999149488],
            [-92.814737, 32.1469069991495],
            [-92.312218, 32.146122999149505],
            [-92.312623, 31.927381999152644],
            [-92.340494, 31.813366999154308],
            [-92.362608, 31.796632999154557],
            [-92.619224, 31.797148999154544],
            [-92.619976, 31.70957199915582],
            [-92.973529, 31.708921999155841],
            [-92.9668, 31.781298999154778]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "11",
        COUNTYFP: "001",
        COUNTYNS: "01702382",
        AFFGEOID: "0500000US11001",
        GEOID: "11001",
        NAME: "District of Columbia",
        LSAD: "00",
        ALAND: 158351639,
        AWATER: 18675956
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.119759, 38.934342999077501],
            [-77.041018, 38.995547999077083],
            [-77.002546244247, 38.965531699523183],
            [-76.909393, 38.892851999077784],
            [-76.979497, 38.837811999078163],
            [-77.039006, 38.791644999078486],
            [-77.039066356764693, 38.841271888596758],
            [-77.039099, 38.868111999077946],
            [-77.0902, 38.904210999077705],
            [-77.119759, 38.934342999077501]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "041",
        COUNTYNS: "00395585",
        AFFGEOID: "0500000US16041",
        GEOID: "16041",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1717209197,
        AWATER: 12155421
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.126194, 42.285228999060848],
            [-111.983848, 42.285549999060848],
            [-111.896728, 42.256275999060932],
            [-111.875636, 42.416332999060465],
            [-111.733742, 42.430634999060416],
            [-111.599227, 42.41539499906046],
            [-111.552151, 42.351385999060646],
            [-111.626104, 42.207541999061092],
            [-111.599116, 42.099005999061433],
            [-111.521577, 42.074436999061518],
            [-111.507813035175985, 41.999685667413971],
            [-111.750778, 41.999329999061771],
            [-112.109443055819995, 41.997598428468372],
            [-112.109271, 42.169910999061209],
            [-112.070959, 42.238262999060993],
            [-112.126194, 42.285228999060848]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "007",
        COUNTYNS: "00465193",
        AFFGEOID: "0500000US19007",
        GEOID: "19007",
        NAME: "Appanoose",
        LSAD: "06",
        ALAND: 1287982562,
        AWATER: 49084378
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.097595, 40.898450999066192],
            [-92.639091, 40.898885999066188],
            [-92.637903293599493, 40.590956546165572],
            [-92.686693, 40.589808999067664],
            [-92.714597263045007, 40.589582832903986],
            [-92.941595, 40.587742999067686],
            [-93.097291203749805, 40.583823476392809],
            [-93.097595, 40.898450999066192]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "157",
        COUNTYNS: "00485042",
        AFFGEOID: "0500000US20157",
        GEOID: "20157",
        NAME: "Republic",
        LSAD: "06",
        ALAND: 1857980543,
        AWATER: 7969708
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.931824926317191, 40.002236344090406],
            [-97.821500812275204, 40.002186881159702],
            [-97.777155, 40.002166999070816],
            [-97.415833, 40.002000999070816],
            [-97.369199035314992, 40.001939304831311],
            [-97.36867, 39.65404299907285],
            [-97.931482, 39.653766999072857],
            [-97.931824926317191, 40.002236344090406]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "101",
        COUNTYNS: "01622993",
        AFFGEOID: "0500000US26101",
        GEOID: "26101",
        NAME: "Manistee",
        LSAD: "06",
        ALAND: 1404616462,
        AWATER: 1912440724
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.351638, 44.229428999057063],
            [-86.26871, 44.345323999056966],
            [-86.251926, 44.40098399905694],
            [-86.248914, 44.483003999056884],
            [-86.237020865016504, 44.51829858520157],
            [-85.817802, 44.512968999056874],
            [-85.821186, 44.164196999057118],
            [-86.043507, 44.167087999057117],
            [-86.387837360265195, 44.178693999057117],
            [-86.351638, 44.229428999057063]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "047",
        COUNTYNS: "00659469",
        AFFGEOID: "0500000US27047",
        GEOID: "27047",
        NAME: "Freeborn",
        LSAD: "06",
        ALAND: 1831351973,
        AWATER: 39709692
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.64829, 43.848138999057454],
            [-93.406642, 43.848119999057459],
            [-93.049524, 43.848442999057461],
            [-93.049192007816487, 43.499557479082576],
            [-93.228861, 43.499566999057976],
            [-93.497351325220393, 43.499530723569677],
            [-93.576728, 43.499519999057981],
            [-93.648533031173599, 43.499535488733073],
            [-93.64829, 43.848138999057454]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "093",
        COUNTYNS: "01074059",
        AFFGEOID: "0500000US39093",
        GEOID: "39093",
        NAME: "Lorain",
        LSAD: "06",
        ALAND: 1272272276,
        AWATER: 1119442372
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.348019206891195, 41.427263307798391],
            [-82.268479, 41.430841999063887],
            [-82.181598, 41.471633999063727],
            [-82.07115045287361, 41.496911985848314],
            [-81.994565, 41.514439999063555],
            [-81.968480702528993, 41.503861006965387],
            [-81.960324970003896, 41.500553291661397],
            [-81.971262, 41.351267999064198],
            [-81.87696, 41.350684999064207],
            [-81.878053, 41.275043999064522],
            [-81.972485, 41.274828999064525],
            [-81.973895, 41.199830999064837],
            [-82.072465, 41.199849999064838],
            [-82.074266, 41.136455999065106],
            [-82.169875, 41.137096999065122],
            [-82.171492, 41.063536999065441],
            [-82.336496, 41.065760999065425],
            [-82.344086, 41.202151999064839],
            [-82.344003, 41.211265999064793],
            [-82.342314, 41.283553999064488],
            [-82.345048, 41.353478999064194],
            [-82.345202, 41.357429999064173],
            [-82.348019206891195, 41.427263307798391]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "139",
        COUNTYNS: "01639782",
        AFFGEOID: "0500000US47139",
        GEOID: "47139",
        NAME: "Polk",
        LSAD: "06",
        ALAND: 1125617646,
        AWATER: 19911983
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.775837671297296, 34.987937205262568],
            [-84.699737, 35.172273999111226],
            [-84.703607, 35.241042999110462],
            [-84.667398, 35.26804099911017],
            [-84.495529, 35.285471999109987],
            [-84.356923, 35.233675999110545],
            [-84.325722, 35.219376999110708],
            [-84.286598378740905, 35.205758217606743],
            [-84.321869, 34.988407999113271],
            [-84.509052, 34.988032999113273],
            [-84.621483, 34.988328999113271],
            [-84.727434, 34.988019999113277],
            [-84.775837671297296, 34.987937205262568]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "359",
        COUNTYNS: "01383965",
        AFFGEOID: "0500000US48359",
        GEOID: "48359",
        NAME: "Oldham",
        LSAD: "06",
        ALAND: 3886337121,
        AWATER: 2339716
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.041554, 35.622486999106371],
            [-103.041553998934006, 35.62248763106507],
            [-102.162809, 35.62751499910631],
            [-102.162752, 35.620034999106387],
            [-102.167471, 35.183232999111098],
            [-103.042617950564988, 35.183157258119515],
            [-103.041554, 35.622486999106371]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "191",
        COUNTYNS: "00516942",
        AFFGEOID: "0500000US21191",
        GEOID: "21191",
        NAME: "Pendleton",
        LSAD: "06",
        ALAND: 717836419,
        AWATER: 12328246
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.532288, 38.791936999078487],
            [-84.418107, 38.80659599907839],
            [-84.387389, 38.812322999078354],
            [-84.232305045403493, 38.874707895109616],
            [-84.233265, 38.842670999078138],
            [-84.226162633963497, 38.829777140320232],
            [-84.19702, 38.594007999079899],
            [-84.205346, 38.582038999079991],
            [-84.400321, 38.560602999080153],
            [-84.448556, 38.529421999080384],
            [-84.476299, 38.542722999080283],
            [-84.532288, 38.791936999078487]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "137",
        COUNTYNS: "00424270",
        AFFGEOID: "0500000US17137",
        GEOID: "17137",
        NAME: "Morgan",
        LSAD: "06",
        ALAND: 1473194924,
        AWATER: 9064992
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.571754, 39.839325999071747],
            [-90.583534, 39.876749999071535],
            [-89.994405, 39.872859999071544],
            [-89.993755, 39.785328999072057],
            [-89.926037, 39.522103999073664],
            [-90.15375, 39.520314999073676],
            [-90.301801, 39.520191999073681],
            [-90.300666, 39.636540999072956],
            [-90.372969, 39.665780999072787],
            [-90.371253, 39.75338799907226],
            [-90.483385, 39.75343399907225],
            [-90.483063, 39.789239999072045],
            [-90.599105, 39.790059999072035],
            [-90.571754, 39.839325999071747]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "169",
        COUNTYNS: "00465273",
        AFFGEOID: "0500000US19169",
        GEOID: "19169",
        NAME: "Story",
        LSAD: "06",
        ALAND: 1483235295,
        AWATER: 2509634
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.69839, 42.209338999061089],
            [-93.463043, 42.209301999061083],
            [-93.231722, 42.208885999061074],
            [-93.231858, 41.862710999062237],
            [-93.347933, 41.86310399906224],
            [-93.698032, 41.863369999062236],
            [-93.69839, 42.209338999061089]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "065",
        COUNTYNS: "01622975",
        AFFGEOID: "0500000US26065",
        GEOID: "26065",
        NAME: "Ingham",
        LSAD: "06",
        ALAND: 1440322252,
        AWATER: 11920176
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.602761, 42.769879999059505],
            [-84.38471, 42.769881999059493],
            [-84.363659, 42.775777999059478],
            [-84.158189, 42.776638999059479],
            [-84.140713, 42.424610999060434],
            [-84.600403, 42.421983999060437],
            [-84.602761, 42.769879999059505]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "127",
        COUNTYNS: "00695784",
        AFFGEOID: "0500000US28127",
        GEOID: "28127",
        NAME: "Simpson",
        LSAD: "06",
        ALAND: 1525930832,
        AWATER: 3378529
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.230331, 32.049727999150882],
            [-89.730066, 32.04707199915093],
            [-89.662086, 32.04723899915092],
            [-89.653025, 31.780652999154785],
            [-89.755103, 31.774653999154868],
            [-89.974586, 31.761654999155066],
            [-90.122565, 31.752679999155188],
            [-90.107491, 31.777684999154836],
            [-90.230331, 32.049727999150882]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "035",
        COUNTYNS: "00695742",
        AFFGEOID: "0500000US28035",
        GEOID: "28035",
        NAME: "Forrest",
        LSAD: "06",
        ALAND: 1207042799,
        AWATER: 10872920
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.451616, 31.434028999159921],
            [-89.399178, 31.434028999159921],
            [-89.146092, 31.434051999159919],
            [-89.146176, 31.000446999166513],
            [-89.137967, 30.90987699916791],
            [-89.340806, 30.90991199916791],
            [-89.34072, 30.994162999166615],
            [-89.34895, 31.010369999166361],
            [-89.347863, 31.346699999161238],
            [-89.451073, 31.34638299916125],
            [-89.451616, 31.434028999159921]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "33",
        COUNTYFP: "001",
        COUNTYNS: "00873174",
        AFFGEOID: "0500000US33001",
        GEOID: "33001",
        NAME: "Belknap",
        LSAD: "06",
        ALAND: 1040130767,
        AWATER: 177190123
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.651153, 43.621118999057778],
            [-71.648346, 43.684510999057693],
            [-71.581403, 43.691948999057672],
            [-71.521466, 43.69799199905767],
            [-71.531658, 43.760978999057592],
            [-71.43989, 43.695557999057669],
            [-71.344245, 43.629219999057774],
            [-71.34362, 43.628793999057777],
            [-71.208941, 43.550516999057891],
            [-71.162675, 43.538958999057911],
            [-71.159364, 43.355122999058231],
            [-71.236011, 43.284993999058351],
            [-71.471454, 43.411297999058114],
            [-71.55003, 43.452700999058059],
            [-71.612426, 43.437892999058079],
            [-71.727573, 43.563410999057858],
            [-71.651153, 43.621118999057778]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "055",
        COUNTYNS: "01074040",
        AFFGEOID: "0500000US39055",
        GEOID: "39055",
        NAME: "Geauga",
        LSAD: "06",
        ALAND: 1036429792,
        AWATER: 21045380
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.391826, 41.416316999063937],
            [-81.391518, 41.445221999063833],
            [-81.391005, 41.569715999063327],
            [-81.29588, 41.569949999063326],
            [-81.294669, 41.640888999063066],
            [-81.101422, 41.641456999063045],
            [-81.102258, 41.714306999062785],
            [-81.003631, 41.715136999062779],
            [-81.003125, 41.501684999063585],
            [-81.003319, 41.347859999064219],
            [-81.391694, 41.34827199906421],
            [-81.391826, 41.416316999063937]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "059",
        COUNTYNS: "01248009",
        AFFGEOID: "0500000US45059",
        GEOID: "45059",
        NAME: "Laurens",
        LSAD: "06",
        ALAND: 1848783527,
        AWATER: 25949287
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.313996, 34.484001999119073],
            [-82.236125, 34.535829999118469],
            [-82.238054, 34.586955999117869],
            [-82.146792, 34.785553999115564],
            [-82.071621, 34.704241999116512],
            [-81.863275, 34.580230999117937],
            [-81.854002, 34.594548999117784],
            [-81.677321, 34.557971999118202],
            [-81.643552, 34.533651999118483],
            [-81.703198, 34.452630999119442],
            [-81.737103, 34.430543999119699],
            [-81.782627, 34.365088999120481],
            [-81.895331, 34.300496999121258],
            [-81.945019, 34.203147999122422],
            [-82.08367, 34.299239999121276],
            [-82.168857, 34.330150999120896],
            [-82.246337, 34.409675999119955],
            [-82.310314, 34.466655999119276],
            [-82.313996, 34.484001999119073]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "097",
        COUNTYNS: "01265777",
        AFFGEOID: "0500000US46097",
        GEOID: "46097",
        NAME: "Miner",
        LSAD: "06",
        ALAND: 1476889390,
        AWATER: 4382648
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.849492, 44.195234999057085],
            [-97.370115, 44.194970999057084],
            [-97.369463, 43.848525999057465],
            [-97.608517, 43.849058999057462],
            [-97.85111, 43.849856999057451],
            [-97.849492, 44.195234999057085]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "127",
        COUNTYNS: "01383849",
        AFFGEOID: "0500000US48127",
        GEOID: "48127",
        NAME: "Dimmit",
        LSAD: "06",
        ALAND: 3441795365,
        AWATER: 14576538
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.114336, 28.648121999205571],
            [-99.409021, 28.640565999205702],
            [-99.395736, 28.640783999205706],
            [-99.394177, 28.204626999213545],
            [-100.113719, 28.197818999213666],
            [-100.114336, 28.648121999205571]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "305",
        COUNTYNS: "01383938",
        AFFGEOID: "0500000US48305",
        GEOID: "48305",
        NAME: "Lynn",
        LSAD: "06",
        ALAND: 2309924845,
        AWATER: 4169719
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.075929, 33.389585999132599],
            [-101.556884, 33.394759999132532],
            [-101.557434, 32.961024999138253],
            [-101.691284, 32.961837999138226],
            [-102.076214, 32.959701999138268],
            [-102.075929, 33.389585999132599]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "171",
        COUNTYNS: "01383871",
        AFFGEOID: "0500000US48171",
        GEOID: "48171",
        NAME: "Gillespie",
        LSAD: "06",
        ALAND: 2740716834,
        AWATER: 9015052
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.303996, 30.499831999174354],
            [-98.964232, 30.498481999174381],
            [-98.59167, 30.499874999174352],
            [-98.587897, 30.138953999180181],
            [-98.920147, 30.138289999180181],
            [-99.300988, 30.134297999180255],
            [-99.301718, 30.286652999177782],
            [-99.303996, 30.499831999174354]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "097",
        COUNTYNS: "01480136",
        AFFGEOID: "0500000US51097",
        GEOID: "51097",
        NAME: "King and Queen",
        LSAD: "06",
        ALAND: 816244563,
        AWATER: 28884077
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.181418, 37.891653999085271],
            [-77.167284, 37.9639039990847],
            [-77.070115, 37.964769999084695],
            [-77.040713, 37.868936999085449],
            [-76.943221, 37.782954999086158],
            [-76.804423, 37.794891999086062],
            [-76.751072, 37.728604999086606],
            [-76.693584, 37.627875999087436],
            [-76.654076, 37.60317399908763],
            [-76.646794, 37.481153999088669],
            [-76.712305, 37.43130899908909],
            [-76.741984, 37.467075999088784],
            [-76.792184, 37.517593999088355],
            [-76.777729, 37.554445999088053],
            [-76.915586, 37.69332499908689],
            [-77.033273, 37.720808999086664],
            [-77.103108, 37.786033999086129],
            [-77.169004, 37.897044999085232],
            [-77.181418, 37.891653999085271]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "003",
        COUNTYNS: "00424203",
        AFFGEOID: "0500000US17003",
        GEOID: "17003",
        NAME: "Alexander",
        LSAD: "06",
        ALAND: 609996941,
        AWATER: 44237191
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.49516, 37.32479499909001],
            [-89.473679434541197, 37.334853901284511],
            [-89.248439, 37.335108999089918],
            [-89.261779, 37.10574199909194],
            [-89.166620212019694, 37.072110342892138],
            [-89.128899, 37.017907999092714],
            [-89.132915, 36.982056999093039],
            [-89.195039, 36.989767999092983],
            [-89.257608, 37.015495999092749],
            [-89.307436691172981, 37.028759448720535],
            [-89.359456, 37.042605999092501],
            [-89.384175, 37.103266999091964],
            [-89.456105, 37.188119999091199],
            [-89.470525, 37.253356999090634],
            [-89.482889284644003, 37.260950717504663],
            [-89.517032, 37.281919999090384],
            [-89.49516, 37.32479499909001]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "173",
        COUNTYNS: "01648583",
        AFFGEOID: "0500000US47173",
        GEOID: "47173",
        NAME: "Union",
        LSAD: "06",
        ALAND: 579055053,
        AWATER: 61028041
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.004317, 36.270377999099772],
            [-83.981016, 36.284977999099617],
            [-83.954908, 36.387622999098618],
            [-83.921217, 36.387878999098611],
            [-83.905638, 36.419978999098312],
            [-83.805515, 36.435878999098151],
            [-83.73301, 36.344979999099039],
            [-83.667408, 36.344380999099037],
            [-83.714704, 36.173782999100716],
            [-83.732704, 36.164582999100801],
            [-83.905169, 36.160905999100855],
            [-83.941711, 36.1863779991006],
            [-83.998745, 36.25236999909994],
            [-84.004317, 36.270377999099772]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "005",
        COUNTYNS: "00451683",
        AFFGEOID: "0500000US18005",
        GEOID: "18005",
        NAME: "Bartholomew",
        LSAD: "06",
        ALAND: 1053869826,
        AWATER: 6814131
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.085601, 39.344188999074788],
            [-85.952005, 39.347372999074771],
            [-85.684515, 39.350048999074744],
            [-85.686783, 39.130858999076167],
            [-85.799834, 39.128896999076183],
            [-85.798804, 39.068535999076595],
            [-85.860573, 39.040909999076774],
            [-86.080272, 39.050269999076704],
            [-86.07903, 39.079640999076517],
            [-86.085601, 39.344188999074788]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "031",
        COUNTYNS: "01101803",
        AFFGEOID: "0500000US40031",
        GEOID: "40031",
        NAME: "Comanche",
        LSAD: "06",
        ALAND: 2769447440,
        AWATER: 37331078
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.826008, 34.594412999117786],
            [-98.826041, 34.855121999114772],
            [-98.619816, 34.854952999114786],
            [-98.092645, 34.854918999114773],
            [-98.08901, 34.834805999114998],
            [-98.089098, 34.681118999116762],
            [-98.141799, 34.681157999116763],
            [-98.142148, 34.506993999118798],
            [-98.24412, 34.50712099911879],
            [-98.295928, 34.449042999119477],
            [-98.504988, 34.42045199911982],
            [-98.661956, 34.405947999119995],
            [-98.661982, 34.507459999118801],
            [-98.82607, 34.507369999118808],
            [-98.826008, 34.594412999117786]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "195",
        COUNTYNS: "00758550",
        AFFGEOID: "0500000US29195",
        GEOID: "29195",
        NAME: "Saline",
        LSAD: "06",
        ALAND: 1956716997,
        AWATER: 29587339
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.477233, 39.292795999075118],
            [-93.399133, 39.226436999075538],
            [-93.333337, 39.250011999075376],
            [-93.332252, 39.309605999075004],
            [-93.230079, 39.327876999074896],
            [-93.200708, 39.401786999074425],
            [-93.104485, 39.383655999074527],
            [-93.072915, 39.33551999907484],
            [-92.959801, 39.312525999074978],
            [-92.911669, 39.223945999075561],
            [-92.849224, 39.226217999075537],
            [-92.94385, 39.121737999076238],
            [-92.934569, 39.064546999076612],
            [-93.048471, 38.972417999077244],
            [-93.050453, 38.928243999077544],
            [-93.496377, 38.942836999077436],
            [-93.477233, 39.292795999075118]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "023",
        COUNTYNS: "00161537",
        AFFGEOID: "0500000US01023",
        GEOID: "01023",
        NAME: "Choctaw",
        LSAD: "06",
        ALAND: 2365869837,
        AWATER: 19144469
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.468662484051293, 31.893855710768733],
            [-88.46866, 31.933172999152564],
            [-88.453387763109276, 32.053049204570549],
            [-88.431145363462804, 32.227636233837252],
            [-88.428278, 32.250142999148032],
            [-88.421312221394302, 32.308679122158509],
            [-87.930661, 32.310573999147174],
            [-88.019145, 32.285239999147542],
            [-88.007077, 32.185312999148948],
            [-88.113584, 32.006239999151511],
            [-88.07349, 31.990181999151741],
            [-88.121414, 31.950255999152308],
            [-88.180384, 31.814545999154287],
            [-88.088288, 31.699302999155979],
            [-88.463625304684896, 31.697942370506198],
            [-88.468669, 31.790721999154645],
            [-88.468662484051293, 31.893855710768733]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "061",
        COUNTYNS: "00344805",
        AFFGEOID: "0500000US13061",
        GEOID: "13061",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 506035793,
        AWATER: 56605066
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.129159315583195, 31.780278267231392],
            [-85.073941, 31.771440999154912],
            [-84.95864, 31.777853999154832],
            [-84.949784, 31.679913999156273],
            [-84.930998, 31.672308999156371],
            [-84.942353, 31.618668999157169],
            [-84.817843, 31.619770999157161],
            [-84.80796, 31.514382999158713],
            [-84.819807, 31.50119199915892],
            [-85.029227, 31.486070999159136],
            [-85.051681429506203, 31.519540328300337],
            [-85.041881, 31.544683999158273],
            [-85.05796, 31.570839999157865],
            [-85.058169, 31.620226999157151],
            [-85.12553, 31.694964999156046],
            [-85.11893, 31.732663999155488],
            [-85.125440577342502, 31.762968702573346],
            [-85.129159315583195, 31.780278267231392]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "091",
        COUNTYNS: "00450507",
        AFFGEOID: "0500000US18091",
        GEOID: "18091",
        NAME: "LaPorte",
        LSAD: "06",
        ALAND: 1549582915,
        AWATER: 38751745
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.932849028494388, 41.71649693696498],
            [-86.90913, 41.726937999062713],
            [-86.824828, 41.760239999062613],
            [-86.641322920520793, 41.759674937205403],
            [-86.640044, 41.759670999062607],
            [-86.524219742442895, 41.759572155036111],
            [-86.526682, 41.650439999063018],
            [-86.486433, 41.570544999063316],
            [-86.524711, 41.43288599906387],
            [-86.642542, 41.432638999063862],
            [-86.739995, 41.321707999064337],
            [-86.814652, 41.273500999064531],
            [-86.870265, 41.264552999064563],
            [-86.930079, 41.236797999064684],
            [-86.932849028494388, 41.71649693696498]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "171",
        COUNTYNS: "00450400",
        AFFGEOID: "0500000US18171",
        GEOID: "18171",
        NAME: "Warren",
        LSAD: "06",
        ALAND: 944520568,
        AWATER: 4466121
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.530054, 40.250670999069435],
            [-87.527065263399194, 40.476882291529755],
            [-87.093676, 40.475744999068247],
            [-87.092148, 40.36661199906883],
            [-87.244898, 40.309251999069133],
            [-87.438755, 40.165693999069894],
            [-87.406667, 40.127507999070112],
            [-87.49041, 40.127405999070113],
            [-87.531021612428006, 40.148035395815697],
            [-87.530054, 40.250670999069435]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "029",
        COUNTYNS: "01622957",
        AFFGEOID: "0500000US26029",
        GEOID: "26029",
        NAME: "Charlevoix",
        LSAD: "06",
        ALAND: 1078286047,
        AWATER: 2522933853
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-85.371593, 45.270833999056819],
              [-85.294848, 45.316407999056828],
              [-85.196704, 45.360640999056855],
              [-85.096056872271006, 45.363088039491657],
              [-85.001803, 45.272327999056813],
              [-84.964787, 45.291600999056833],
              [-84.732687, 45.289381999056829],
              [-84.734303, 45.201935999056793],
              [-84.734799, 45.114902999056788],
              [-84.857452, 45.116706999056781],
              [-85.223681, 45.117860999056781],
              [-85.222707, 45.205559999056803],
              [-85.37782429593301, 45.207644401543696],
              [-85.371593, 45.270833999056819]
            ]
          ],
          [
            [
              [-85.701809, 45.736128999057122],
              [-85.651866, 45.743138999057123],
              [-85.524448, 45.829793999057216],
              [-85.360952, 45.817553999057189],
              [-85.377132, 45.769012999057153],
              [-85.509276, 45.596474999057008],
              [-85.561634, 45.57221299905698],
              [-85.622741, 45.586027999056995],
              [-85.696872, 45.697249999057078],
              [-85.701809, 45.736128999057122]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "181",
        COUNTYNS: "00835912",
        AFFGEOID: "0500000US31181",
        GEOID: "31181",
        NAME: "Webster",
        LSAD: "06",
        ALAND: 1489017971,
        AWATER: 337241
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.726827, 40.35039799906891],
            [-98.723948, 40.350390999068907],
            [-98.27809, 40.350346999068911],
            [-98.273571, 40.350358999068902],
            [-98.274017059752907, 40.002337449800706],
            [-98.504454983636791, 40.002379875806817],
            [-98.613755, 40.002399999070818],
            [-98.726372963939014, 40.002336272237713],
            [-98.726827, 40.35039799906891]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "087",
        COUNTYNS: "01074056",
        AFFGEOID: "0500000US39087",
        GEOID: "39087",
        NAME: "Lawrence",
        LSAD: "06",
        ALAND: 1174224653,
        AWATER: 10110033
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.811542384650409, 38.572369588641962],
            [-82.740383, 38.597166999079889],
            [-82.763895, 38.679973999079294],
            [-82.706823, 38.677494999079308],
            [-82.662886, 38.747976999078809],
            [-82.650039, 38.849072999078089],
            [-82.575583, 38.844477999078123],
            [-82.582683, 38.779076999078576],
            [-82.483579, 38.772277999078625],
            [-82.472478, 38.682278999079273],
            [-82.354468, 38.676069999079324],
            [-82.361674, 38.585181999079971],
            [-82.282133344028097, 38.579860795483505],
            [-82.293271, 38.560282999080151],
            [-82.304223, 38.496307999080621],
            [-82.323999, 38.449267999080966],
            [-82.381773, 38.434782999081065],
            [-82.447076, 38.426981999081121],
            [-82.508966949731288, 38.414643911732924],
            [-82.560664, 38.404337999081299],
            [-82.593673, 38.421808999081172],
            [-82.618474, 38.477088999080763],
            [-82.664116907515691, 38.507715589337529],
            [-82.675724, 38.515503999080472],
            [-82.724846, 38.557599999080168],
            [-82.800112, 38.563182999080119],
            [-82.811542384650409, 38.572369588641962]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "099",
        COUNTYNS: "01480137",
        AFFGEOID: "0500000US51099",
        GEOID: "51099",
        NAME: "King George",
        LSAD: "06",
        ALAND: 465080763,
        AWATER: 21140635
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.330094, 38.334009999081822],
            [-77.284346499792193, 38.351636815595995],
            [-77.265295, 38.333164999081838],
            [-77.162692, 38.345993999081735],
            [-77.093713, 38.352800999081687],
            [-77.048137, 38.360144999081633],
            [-77.020947, 38.329272999081859],
            [-77.026304, 38.302684999082061],
            [-76.996791259204898, 38.279147833062154],
            [-77.044799, 38.260406999082392],
            [-77.061695, 38.161630999083158],
            [-77.073796, 38.141330999083308],
            [-77.115997, 38.149930999083224],
            [-77.2143, 38.197028999082875],
            [-77.229757, 38.245207999082503],
            [-77.326692, 38.2451359990825],
            [-77.330094, 38.334009999081822]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "037",
        COUNTYNS: "00066848",
        AFFGEOID: "0500000US05037",
        GEOID: "05037",
        NAME: "Cross",
        LSAD: "06",
        ALAND: 1596421812,
        AWATER: 15344834
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.039783, 35.354255999109235],
            [-91.03923, 35.444716999108259],
            [-90.610753, 35.448768999108211],
            [-90.502429, 35.441725999108286],
            [-90.506475, 35.178714999111151],
            [-90.502993, 35.144213999111535],
            [-91.04267, 35.148188999111483],
            [-91.039783, 35.354255999109235]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "095",
        COUNTYNS: "00295750",
        AFFGEOID: "0500000US12095",
        GEOID: "12095",
        NAME: "Orange",
        LSAD: "06",
        ALAND: 2339138134,
        AWATER: 259141175
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.658597, 28.766869999203468],
            [-81.646513, 28.78587199920312],
            [-81.414446, 28.784954999203148],
            [-81.459728, 28.640195999205712],
            [-81.407633, 28.64010399920571],
            [-81.32789, 28.610276999206242],
            [-81.129006, 28.612534999206204],
            [-80.98725, 28.612996999206196],
            [-80.952011, 28.604791999206341],
            [-80.88089, 28.503632999208151],
            [-80.862908, 28.34748699921095],
            [-81.094674, 28.348104999210943],
            [-81.309571, 28.348050999210948],
            [-81.657268, 28.347097999210959],
            [-81.657281, 28.546293999207386],
            [-81.658597, 28.766869999203468]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "143",
        COUNTYNS: "01784920",
        AFFGEOID: "0500000US17143",
        GEOID: "17143",
        NAME: "Peoria",
        LSAD: "06",
        ALAND: 1602484321,
        AWATER: 30706228
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.986068, 40.712256999067073],
            [-89.985479, 40.97449499906584],
            [-89.638727, 40.973706999065833],
            [-89.447693, 40.973245999065846],
            [-89.47233, 40.921205999066096],
            [-89.562394, 40.793636999066685],
            [-89.554994, 40.747636999066906],
            [-89.553374, 40.706502999067105],
            [-89.592157, 40.685014999067214],
            [-89.675404, 40.553937999067855],
            [-89.770412, 40.553148999067865],
            [-89.872463, 40.513126999068064],
            [-89.87351, 40.62448199906752],
            [-89.989018, 40.625834999067486],
            [-89.986068, 40.712256999067073]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "105",
        COUNTYNS: "00451680",
        AFFGEOID: "0500000US18105",
        GEOID: "18105",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 1021814153,
        AWATER: 43504254
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.68561, 39.336042999074834],
            [-86.630818, 39.346947999074764],
            [-86.611706, 39.340250999074797],
            [-86.381395, 39.339751999074814],
            [-86.370066, 39.048374999076728],
            [-86.318133, 39.048809999076724],
            [-86.317474, 38.990869999077113],
            [-86.682277, 38.992403999077105],
            [-86.683002, 39.165745999075938],
            [-86.68561, 39.336042999074834]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "061",
        COUNTYNS: "00484999",
        AFFGEOID: "0500000US20061",
        GEOID: "20061",
        NAME: "Geary",
        LSAD: "06",
        ALAND: 996280339,
        AWATER: 51594747
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.963177, 39.13229999907616],
            [-96.961219, 39.151119999076045],
            [-96.961693, 39.220075999075583],
            [-96.849879, 39.21901199907559],
            [-96.851409, 39.088175999076462],
            [-96.501166, 39.043665999076765],
            [-96.501556, 38.869703999077942],
            [-96.890245, 38.870066999077949],
            [-96.92671, 38.870349999077938],
            [-96.926209, 38.97894099907721],
            [-96.96347, 38.964900999077287],
            [-96.963177, 39.13229999907616]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "141",
        COUNTYNS: "00695791",
        AFFGEOID: "0500000US28141",
        GEOID: "28141",
        NAME: "Tishomingo",
        LSAD: "06",
        ALAND: 1098939231,
        AWATER: 52359814
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.363530268539179, 34.995746750910293],
            [-88.258111, 34.995462999113194],
            [-88.200064, 34.995633999113196],
            [-88.154617, 34.922391999114012],
            [-88.097888, 34.892201999114356],
            [-88.134263, 34.622659999117452],
            [-88.139559441247386, 34.581696745001821],
            [-88.154902184008691, 34.463034299559325],
            [-88.329193, 34.463570999119327],
            [-88.330699, 34.740720999116085],
            [-88.365472, 34.755603999115912],
            [-88.363530268539179, 34.995746750910293]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "059",
        COUNTYNS: "00835851",
        AFFGEOID: "0500000US31059",
        GEOID: "31059",
        NAME: "Fillmore",
        LSAD: "06",
        ALAND: 1490202783,
        AWATER: 2989990
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.82478, 40.698536999067137],
            [-97.368401, 40.69862499906715],
            [-97.368688, 40.350391999068904],
            [-97.820816, 40.350544999068902],
            [-97.824114, 40.350540999068905],
            [-97.82478, 40.698536999067137]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "025",
        COUNTYNS: "01213664",
        AFFGEOID: "0500000US42025",
        GEOID: "42025",
        NAME: "Carbon",
        LSAD: "06",
        ALAND: 987977576,
        AWATER: 15349904
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.997348, 40.912984999066126],
            [-75.73193, 41.008219999065687],
            [-75.770196, 41.0502189990655],
            [-75.693388, 41.131058999065147],
            [-75.649637, 41.122343999065173],
            [-75.49599, 40.987180999065778],
            [-75.556438, 40.928692999066051],
            [-75.487445, 40.837714999066478],
            [-75.474193, 40.814745999066588],
            [-75.577488, 40.799413999066658],
            [-75.608985, 40.787386999066712],
            [-75.694749, 40.770074999066793],
            [-75.757807, 40.735413999066964],
            [-75.886037, 40.816266999066563],
            [-75.926429, 40.85172599906641],
            [-75.997348, 40.912984999066126]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "461",
        COUNTYNS: "01384016",
        AFFGEOID: "0500000US48461",
        GEOID: "48461",
        NAME: "Upton",
        LSAD: "06",
        ALAND: 3215010601,
        AWATER: 464309
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.31805, 31.651326999156684],
            [-102.287345, 31.651275999156685],
            [-101.775802, 31.651318999156683],
            [-101.776191, 31.079783999165286],
            [-102.301212, 31.086211999165194],
            [-102.31805, 31.651326999156684]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "145",
        COUNTYNS: "01383858",
        AFFGEOID: "0500000US48145",
        GEOID: "48145",
        NAME: "Falls",
        LSAD: "06",
        ALAND: 1982544974,
        AWATER: 21693803
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.278113, 31.279798999162235],
            [-96.801123, 31.522268999158602],
            [-96.596862, 31.220899999163137],
            [-96.829121, 31.105870999164889],
            [-96.849079, 31.100470999164973],
            [-97.070188, 30.986219999166728],
            [-97.278113, 31.279798999162235]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "307",
        COUNTYNS: "01383945",
        AFFGEOID: "0500000US48307",
        GEOID: "48307",
        NAME: "McCulloch",
        LSAD: "06",
        ALAND: 2759894991,
        AWATER: 20284774
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.603223, 31.087295999165182],
            [-99.60185, 31.491949999159061],
            [-99.511477, 31.42440099916006],
            [-99.402284, 31.446197999159736],
            [-99.314875, 31.409874999160284],
            [-99.202768, 31.467233999159422],
            [-99.098794, 31.448469999159698],
            [-99.090622, 31.460926999159515],
            [-99.092317, 30.941001999167419],
            [-99.484755, 30.940604999167427],
            [-99.603627, 30.94072599916743],
            [-99.603223, 31.087295999165182]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "025",
        COUNTYNS: "01581072",
        AFFGEOID: "0500000US55025",
        GEOID: "55025",
        NAME: "Dane",
        LSAD: "06",
        ALAND: 3098774789,
        AWATER: 106341440
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.838135, 43.206056999058518],
            [-89.714988, 43.276972999058387],
            [-89.720463, 43.293083999058354],
            [-89.363085, 43.281304999058364],
            [-89.009139, 43.28482999905836],
            [-89.008832, 43.197723999058539],
            [-89.013582, 42.847629999059308],
            [-89.369128, 42.845035999059313],
            [-89.378262, 42.856375999059281],
            [-89.838167, 42.857396999059283],
            [-89.838135, 43.206056999058518]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "049",
        COUNTYNS: "01581084",
        AFFGEOID: "0500000US55049",
        GEOID: "55049",
        NAME: "Iowa",
        LSAD: "06",
        ALAND: 1975379853,
        AWATER: 13870296
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.429825, 43.200939999058527],
            [-90.350513, 43.209785999058511],
            [-90.31113, 43.206676999058516],
            [-90.260077, 43.197688999058528],
            [-90.193814, 43.164463999058597],
            [-90.05866, 43.145290999058638],
            [-90.000123, 43.194623999058528],
            [-89.838135, 43.206056999058518],
            [-89.838167, 42.857396999059283],
            [-89.83841, 42.813798999059387],
            [-90.426902, 42.812861999059386],
            [-90.429825, 43.200939999058527]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "053",
        COUNTYNS: "01804506",
        AFFGEOID: "0500000US72053",
        GEOID: "72053",
        NAME: "Fajardo",
        LSAD: "13",
        ALAND: 77448647,
        AWATER: 195352764
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-65.753591, 18.296754999436949],
            [-65.732065, 18.288168999437175],
            [-65.6613516940984, 18.389038930797913],
            [-65.624022, 18.387169999434555],
            [-65.586232, 18.393382999434394],
            [-65.565949, 18.358332999435323],
            [-65.564931, 18.325041999436198],
            [-65.624871, 18.31066999943658],
            [-65.613949666954994, 18.293815341641221],
            [-65.737739, 18.273880999437559],
            [-65.753591, 18.296754999436949]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "043",
        COUNTYNS: "01639740",
        AFFGEOID: "0500000US47043",
        GEOID: "47043",
        NAME: "Dickson",
        LSAD: "06",
        ALAND: 1268824251,
        AWATER: 3689043
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.566798, 36.177856999100662],
            [-87.523627, 36.248889999099973],
            [-87.513533, 36.334712999099139],
            [-87.303129, 36.312441999099356],
            [-87.286501, 36.321932999099268],
            [-87.153043, 36.30534999909942],
            [-87.193301, 36.248968999099972],
            [-87.149876, 36.176877999100689],
            [-87.182573, 36.049725999101966],
            [-87.190826, 35.975883999102713],
            [-87.204242, 35.959185999102864],
            [-87.53453, 35.993073999102535],
            [-87.552874, 36.016236999102297],
            [-87.566798, 36.177856999100662]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "071",
        COUNTYNS: "01804516",
        AFFGEOID: "0500000US72071",
        GEOID: "72071",
        NAME: "Isabela",
        LSAD: "13",
        ALAND: 143255845,
        AWATER: 94636933
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.097303425856495, 18.511667915785477],
            [-67.042276, 18.511593999431284],
            [-66.956315951859992, 18.493900428302055],
            [-66.947333, 18.401595999434178],
            [-66.92202, 18.393203999434402],
            [-66.938329, 18.376431999434843],
            [-67.027173, 18.393375999434394],
            [-67.056507, 18.460953999432611],
            [-67.05846, 18.479981999432116],
            [-67.097303425856495, 18.511667915785477]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "037",
        COUNTYNS: "01622961",
        AFFGEOID: "0500000US26037",
        GEOID: "26037",
        NAME: "Clinton",
        LSAD: "06",
        ALAND: 1467009545,
        AWATER: 21106020
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.836889, 43.118850999058701],
            [-84.36776, 43.117941999058694],
            [-84.363659, 42.775777999059478],
            [-84.38471, 42.769881999059493],
            [-84.602761, 42.769879999059505],
            [-84.837085, 42.770478999059499],
            [-84.836889, 43.118850999058701]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "047",
        COUNTYNS: "00465212",
        AFFGEOID: "0500000US19047",
        GEOID: "19047",
        NAME: "Crawford",
        LSAD: "06",
        ALAND: 1849742884,
        AWATER: 2194272
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.670822, 42.211407999061088],
            [-95.323497, 42.210931999061074],
            [-95.090851, 42.210404999061076],
            [-95.092861, 41.863373999062247],
            [-95.557277, 41.863448999062236],
            [-95.672771, 41.863146999062238],
            [-95.670822, 42.211407999061088]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "011",
        COUNTYNS: "00484975",
        AFFGEOID: "0500000US20011",
        GEOID: "20011",
        NAME: "Bourbon",
        LSAD: "06",
        ALAND: 1645861891,
        AWATER: 9377598
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.088082, 37.732475999086567],
            [-95.079692, 37.819799999085852],
            [-95.077876, 38.03770599908411],
            [-94.614100248705398, 38.037056582465425],
            [-94.614465, 37.987798999084504],
            [-94.617885, 37.682213999086983],
            [-94.617872450898091, 37.673110699419162],
            [-95.088012, 37.674518999087034],
            [-95.088082, 37.732475999086567]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "051",
        COUNTYNS: "00484995",
        AFFGEOID: "0500000US20051",
        GEOID: "20051",
        NAME: "Ellis",
        LSAD: "06",
        ALAND: 2330762291,
        AWATER: 1197085
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.591776, 39.132356999076173],
            [-99.047687, 39.133013999076162],
            [-99.0375, 39.133120999076148],
            [-99.042626, 38.696806999079165],
            [-99.585087, 38.696536999079164],
            [-99.598323, 38.696513999079173],
            [-99.591776, 39.132356999076173]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "049",
        COUNTYNS: "00295746",
        AFFGEOID: "0500000US12049",
        GEOID: "12049",
        NAME: "Hardee",
        LSAD: "06",
        ALAND: 1651867901,
        AWATER: 1439673
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.054349, 27.646381999223852],
            [-81.563318, 27.646646999223844],
            [-81.563696, 27.340679999229621],
            [-82.055753, 27.338263999229671],
            [-82.054349, 27.646381999223852]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "059",
        COUNTYNS: "01101817",
        AFFGEOID: "0500000US40059",
        GEOID: "40059",
        NAME: "Harper",
        LSAD: "06",
        ALAND: 2691041192,
        AWATER: 5259447
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.002571514321986, 37.001619153004874],
            [-99.995201, 37.001630999092875],
            [-99.657658, 37.000196999092879],
            [-99.541114912360896, 36.999909643658384],
            [-99.456202434675276, 36.999700278500796],
            [-99.374218, 36.879596999093984],
            [-99.295186, 36.819565999094529],
            [-99.295257, 36.595122999096631],
            [-99.605565, 36.592752999096646],
            [-100.00316, 36.593249999096649],
            [-100.002571514321986, 37.001619153004874]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "44",
        COUNTYFP: "001",
        COUNTYNS: "01219777",
        AFFGEOID: "0500000US44001",
        GEOID: "44001",
        NAME: "Bristol",
        LSAD: "06",
        ALAND: 62513687,
        AWATER: 53346252
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.365207, 41.735648999062704],
            [-71.317402007931207, 41.777256108031253],
            [-71.261392, 41.752300999062641],
            [-71.20860053289509, 41.69030924266557],
            [-71.256728, 41.638136999063065],
            [-71.33097, 41.686961999062881],
            [-71.365207, 41.735648999062704]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "081",
        COUNTYNS: "00198156",
        AFFGEOID: "0500000US08081",
        GEOID: "08081",
        NAME: "Moffat",
        LSAD: "06",
        ALAND: 12284943876,
        AWATER: 19803412
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.048249, 40.653600999067365],
            [-109.048259750048004, 40.662601816295322],
            [-109.048455, 40.826080999066527],
            [-109.050076, 41.000658999065735],
            [-108.250649, 41.000113999065725],
            [-107.918418289848987, 41.00122706970982],
            [-107.367443, 41.003072999065708],
            [-107.317795759790002, 41.002842480297907],
            [-107.314153, 40.600280999067621],
            [-107.428813, 40.542206999067922],
            [-107.439386, 40.223378999069588],
            [-107.468897, 40.220233999069599],
            [-108.113179, 40.22166999906959],
            [-109.050732112077, 40.22265525732719],
            [-109.048249, 40.653600999067365]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "103",
        COUNTYNS: "01622994",
        AFFGEOID: "0500000US26103",
        GEOID: "26103",
        NAME: "Marquette",
        LSAD: "06",
        ALAND: 4685386476,
        AWATER: 4185372277
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.116571, 46.41995099905801],
            [-88.115843, 46.678380999058476],
            [-87.991546, 46.679112999058489],
            [-87.991389, 46.765158999058656],
            [-88.044709, 46.765352999058649],
            [-88.044521682281484, 46.917449944335175],
            [-87.900339, 46.909685999058965],
            [-87.77693, 46.876725999058884],
            [-87.687164, 46.841741999058812],
            [-87.595307, 46.782949999058694],
            [-87.573203, 46.720470999058556],
            [-87.503025, 46.647496999058419],
            [-87.381649, 46.580058999058281],
            [-87.366767, 46.507302999058155],
            [-87.175065, 46.497547999058121],
            [-87.116359589470704, 46.506151401178641],
            [-87.116138, 46.159049999057601],
            [-87.241506, 46.159102999057595],
            [-87.242885, 45.985116999057368],
            [-87.367849, 45.985320999057372],
            [-87.617091, 45.986013999057384],
            [-87.615597, 46.246652999057716],
            [-88.117407, 46.246617999057719],
            [-88.116571, 46.41995099905801]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "003",
        COUNTYNS: "01074015",
        AFFGEOID: "0500000US39003",
        GEOID: "39003",
        NAME: "Allen",
        LSAD: "06",
        ALAND: 1042470095,
        AWATER: 11266164
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.397374, 40.815940999066576],
            [-84.340531, 40.859098999066376],
            [-84.109586, 40.860993999066366],
            [-84.109516, 40.904729999066163],
            [-83.880423, 40.920428999066097],
            [-83.880063, 40.819918999066566],
            [-83.880194, 40.644689999067403],
            [-84.107787, 40.643068999067417],
            [-84.222799, 40.685956999067194],
            [-84.396778, 40.684925999067218],
            [-84.397374, 40.815940999066576]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "019",
        COUNTYNS: "00516856",
        AFFGEOID: "0500000US21019",
        GEOID: "21019",
        NAME: "Boyd",
        LSAD: "06",
        ALAND: 414050310,
        AWATER: 5584870
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.810637, 38.393023999081386],
            [-82.769047, 38.433714999081083],
            [-82.664116907515691, 38.507715589337529],
            [-82.618474, 38.477088999080763],
            [-82.593673, 38.421808999081172],
            [-82.595964264499997, 38.380889760796968],
            [-82.597979, 38.344908999081746],
            [-82.571877, 38.315780999081973],
            [-82.581796, 38.248591999082471],
            [-82.584693814125586, 38.240512992452238],
            [-82.614304, 38.266077999082341],
            [-82.794206, 38.243771999082519],
            [-82.771737, 38.362587999081612],
            [-82.817454, 38.373934999081527],
            [-82.810637, 38.393023999081386]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "093",
        COUNTYNS: "01702378",
        AFFGEOID: "0500000US51093",
        GEOID: "51093",
        NAME: "Isle of Wight",
        LSAD: "06",
        ALAND: 817464624,
        AWATER: 122256020
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.924629, 36.707728999095579],
            [-76.817737, 36.882056999093955],
            [-76.849684, 36.996210999092916],
            [-76.698255, 37.059139999092352],
            [-76.641581, 37.153826999091514],
            [-76.645821, 37.12515499909177],
            [-76.445301, 36.941890999093417],
            [-76.53352, 36.915562999093652],
            [-76.691033, 36.796238999094754],
            [-76.897592, 36.644049999096183],
            [-76.909672, 36.648174999096128],
            [-76.917742, 36.662367999095991],
            [-76.912015, 36.67271699909589],
            [-76.920984, 36.686006999095767],
            [-76.912221, 36.698407999095657],
            [-76.925691, 36.698328999095658],
            [-76.924629, 36.707728999095579]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "037",
        COUNTYNS: "00516865",
        AFFGEOID: "0500000US21037",
        GEOID: "21037",
        NAME: "Campbell",
        LSAD: "06",
        ALAND: 391966324,
        AWATER: 20930398
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.49918784070239, 39.10216439994187],
            [-84.493743, 39.102459999076366],
            [-84.480943, 39.116759999076272],
            [-84.462042, 39.121759999076232],
            [-84.445242, 39.114460999076279],
            [-84.432941, 39.083960999076481],
            [-84.40094, 39.046361999076737],
            [-84.326539, 39.027462999076874],
            [-84.321207226856799, 39.020586351766312],
            [-84.297255, 38.989693999077119],
            [-84.288164, 38.955788999077356],
            [-84.232132, 38.880482999077877],
            [-84.232305045403493, 38.874707895109616],
            [-84.387389, 38.812322999078354],
            [-84.418107, 38.80659599907839],
            [-84.450774, 38.819525999078301],
            [-84.4508, 38.993608999077097],
            [-84.506518737436906, 39.101766407264371],
            [-84.49918784070239, 39.10216439994187]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "107",
        COUNTYNS: "00306910",
        AFFGEOID: "0500000US12107",
        GEOID: "12107",
        NAME: "Putnam",
        LSAD: "06",
        ALAND: 1885167090,
        AWATER: 256548320
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.050955, 29.702568999187378],
            [-82.055625, 29.718231999187122],
            [-82.049244, 29.71866999918711],
            [-81.939427, 29.747496999186637],
            [-81.90926, 29.793559999185856],
            [-81.81243, 29.836489999185144],
            [-81.581207, 29.840175999185082],
            [-81.52523, 29.759496999186442],
            [-81.524804, 29.721585999187063],
            [-81.52366, 29.622431999188723],
            [-81.520596, 29.500248999190791],
            [-81.476893, 29.396551999192553],
            [-81.433992, 29.398551999192509],
            [-81.445886, 29.380141999192826],
            [-81.680903, 29.324429999193779],
            [-81.741422, 29.371048999192983],
            [-81.776205, 29.487447999191001],
            [-81.843009, 29.521003999190437],
            [-82.004385, 29.501714999190757],
            [-82.055899, 29.471231999191279],
            [-82.050955, 29.702568999187378]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "133",
        COUNTYNS: "00348672",
        AFFGEOID: "0500000US13133",
        GEOID: "13133",
        NAME: "Greene",
        LSAD: "06",
        ALAND: 1003451710,
        AWATER: 48630003
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.406189, 33.698306999128668],
            [-83.29145, 33.734314999128195],
            [-83.28034, 33.761773999127854],
            [-83.118729, 33.69881799912865],
            [-82.995602, 33.693582999128715],
            [-82.951163, 33.661271999129113],
            [-83.014038, 33.580382999130144],
            [-83.012853, 33.469177999131574],
            [-83.164207, 33.355029999133052],
            [-83.224564, 33.380422999132719],
            [-83.279931, 33.48343799913139],
            [-83.269213, 33.533225999130757],
            [-83.34687, 33.600497999129892],
            [-83.406189, 33.698306999128668]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "019",
        COUNTYNS: "00929111",
        AFFGEOID: "0500000US35019",
        GEOID: "35019",
        NAME: "Guadalupe",
        LSAD: "06",
        ALAND: 7847071878,
        AWATER: 2888161
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.312765, 34.606950999117622],
            [-105.290396, 34.606765999117634],
            [-105.290791, 35.042029999112671],
            [-105.291169, 35.21648899911073],
            [-104.125136, 35.21569599911075],
            [-104.125121, 35.142057999111557],
            [-104.129143, 34.779336999115642],
            [-104.339817, 34.77906199911564],
            [-104.339725, 34.692233999116652],
            [-104.444935, 34.691654999116643],
            [-104.444762, 34.604951999117652],
            [-104.892023, 34.604425999117652],
            [-104.891745, 34.347042999120696],
            [-105.313072, 34.347204999120692],
            [-105.312765, 34.606950999117622]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "181",
        COUNTYNS: "00450338",
        AFFGEOID: "0500000US18181",
        GEOID: "18181",
        NAME: "White",
        LSAD: "06",
        ALAND: 1308269840,
        AWATER: 9203227
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.099793, 40.837606999066487],
            [-86.986438, 40.837920999066469],
            [-86.987438, 40.912176999066133],
            [-86.930759, 40.912417999066115],
            [-86.581532, 40.910924999066133],
            [-86.581739, 40.735764999066959],
            [-86.750011, 40.736026999066958],
            [-86.771293, 40.562081999067814],
            [-87.095357, 40.562894999067815],
            [-87.097069, 40.71440099906706],
            [-87.09839, 40.73654099906696],
            [-87.099793, 40.837606999066487]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "123",
        COUNTYNS: "00465250",
        AFFGEOID: "0500000US19123",
        GEOID: "19123",
        NAME: "Mahaska",
        LSAD: "06",
        ALAND: 1478527855,
        AWATER: 6564804
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.871421, 41.508521999063575],
            [-92.756508, 41.508793999063563],
            [-92.411995, 41.509547999063571],
            [-92.410233, 41.161941999065007],
            [-92.640361, 41.161297999065006],
            [-92.869771, 41.161065999065016],
            [-92.871421, 41.508521999063575]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "035",
        COUNTYNS: "00465207",
        AFFGEOID: "0500000US19035",
        GEOID: "19035",
        NAME: "Cherokee",
        LSAD: "06",
        ALAND: 1494180443,
        AWATER: 423160
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.859377, 42.909096999059166],
            [-95.388182, 42.909896999059157],
            [-95.38801, 42.56174199906004],
            [-95.741611, 42.561284999060042],
            [-95.859949, 42.560619999060044],
            [-95.859377, 42.909096999059166]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "157",
        COUNTYNS: "00516922",
        AFFGEOID: "0500000US21157",
        GEOID: "21157",
        NAME: "Marshall",
        LSAD: "06",
        ALAND: 780276604,
        AWATER: 100761088
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.484103, 36.942095999093411],
            [-88.485254, 37.001108999092878],
            [-88.482957, 37.022565999092677],
            [-88.339292, 37.061215999092333],
            [-88.240371, 36.981910999093039],
            [-88.189389, 36.935548999093477],
            [-88.157347, 36.867182999094091],
            [-88.129944, 36.807545999094636],
            [-88.110831, 36.747149999095193],
            [-88.485697, 36.75032799909517],
            [-88.484103, 36.942095999093411]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "085",
        COUNTYNS: "00450367",
        AFFGEOID: "0500000US18085",
        GEOID: "18085",
        NAME: "Kosciusko",
        LSAD: "06",
        ALAND: 1376361579,
        AWATER: 59509695
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.077551, 41.172867999064955],
            [-86.054116, 41.176367999064944],
            [-86.05923, 41.435811999063866],
            [-85.653507, 41.436855999063852],
            [-85.653227, 41.351194999064198],
            [-85.651928, 41.294775999064434],
            [-85.686574, 41.178375999064947],
            [-85.684181, 41.046715999065512],
            [-85.795814, 41.043587999065529],
            [-85.945561, 41.042875999065537],
            [-86.075939, 41.084877999065341],
            [-86.077551, 41.172867999064955]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "059",
        COUNTYNS: "01074042",
        AFFGEOID: "0500000US39059",
        GEOID: "39059",
        NAME: "Guernsey",
        LSAD: "06",
        ALAND: 1352631185,
        AWATER: 15666075
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.716276, 40.152165999069979],
            [-81.670899, 40.150975999069985],
            [-81.622683, 40.221307999069602],
            [-81.338057, 40.214252999069636],
            [-81.339565, 40.171998999069864],
            [-81.225924, 40.170074999069875],
            [-81.234053, 39.951269999071108],
            [-81.386006, 39.950693999071099],
            [-81.464108, 39.894549999071423],
            [-81.57859, 39.897682999071414],
            [-81.580313, 39.839103999071753],
            [-81.694146, 39.842635999071732],
            [-81.691104, 39.930755999071216],
            [-81.728611, 39.931727999071221],
            [-81.716276, 40.152165999069979]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "049",
        COUNTYNS: "01639743",
        AFFGEOID: "0500000US47049",
        GEOID: "47049",
        NAME: "Fentress",
        LSAD: "06",
        ALAND: 1291397316,
        AWATER: 829331
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.120445, 36.407163999098429],
            [-85.043924, 36.558790999096978],
            [-84.943144, 36.581269999096754],
            [-84.731839, 36.52467399909731],
            [-84.659082, 36.39522699909854],
            [-84.700785, 36.370823999098782],
            [-84.797861, 36.29689399909951],
            [-84.875842, 36.285091999099627],
            [-84.907753, 36.156292999100899],
            [-85.029461, 36.170432999100747],
            [-85.100213, 36.138560999101067],
            [-85.119583, 36.144718999101016],
            [-85.082497, 36.196170999100495],
            [-85.122245, 36.304598999099433],
            [-85.120445, 36.407163999098429]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "007",
        COUNTYNS: "01639725",
        AFFGEOID: "0500000US47007",
        GEOID: "47007",
        NAME: "Bledsoe",
        LSAD: "06",
        ALAND: 1052635794,
        AWATER: 838360
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.423804, 35.567421999106948],
            [-85.265387, 35.721236999105329],
            [-85.254062, 35.765610999104865],
            [-84.928536, 35.768548999104837],
            [-84.916062, 35.761938999104906],
            [-85.107762, 35.571358999106906],
            [-85.146393, 35.491298999107762],
            [-85.135191, 35.458648999108114],
            [-85.168302, 35.455613999108145],
            [-85.225877, 35.354275999109234],
            [-85.403298, 35.492279999107744],
            [-85.436147, 35.541364999107216],
            [-85.423804, 35.567421999106948]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "247",
        COUNTYNS: "01383909",
        AFFGEOID: "0500000US48247",
        GEOID: "48247",
        NAME: "Jim Hogg",
        LSAD: "06",
        ALAND: 2942674729,
        AWATER: 92565
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.954669, 27.269396999230981],
            [-98.798087, 27.268011999231003],
            [-98.798323, 27.354028999229367],
            [-98.589537, 27.358694999229275],
            [-98.522293, 27.34328399922957],
            [-98.523284, 27.265149999231056],
            [-98.492943, 27.235011999231634],
            [-98.466542, 27.054710999235091],
            [-98.417883, 27.055284999235084],
            [-98.422616, 26.783534999240366],
            [-98.95423, 26.785693999240319],
            [-98.954669, 27.269396999230981]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "027",
        COUNTYNS: "01581073",
        AFFGEOID: "0500000US55027",
        GEOID: "55027",
        NAME: "Dodge",
        LSAD: "06",
        ALAND: 2267979574,
        AWATER: 81401509
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.009139, 43.28482999905836],
            [-89.006117, 43.458758999058041],
            [-89.006848, 43.633044999057759],
            [-88.886052, 43.63353999905776],
            [-88.401041, 43.630932999057762],
            [-88.400426, 43.543531999057898],
            [-88.418439, 43.370174999058207],
            [-88.417987, 43.194696999058536],
            [-88.535843, 43.196047999058528],
            [-89.008832, 43.197723999058539],
            [-89.009139, 43.28482999905836]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "131",
        COUNTYNS: "01804546",
        AFFGEOID: "0500000US72131",
        GEOID: "72131",
        NAME: "San Sebastián",
        LSAD: "13",
        ALAND: 182394904,
        AWATER: 2060666
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.050233, 18.348540999435581],
            [-67.027173, 18.393375999434394],
            [-66.938329, 18.376431999434843],
            [-66.92202, 18.393203999434402],
            [-66.8989, 18.366107999435116],
            [-66.893339, 18.367088999435087],
            [-66.909969, 18.302810999436787],
            [-66.907236, 18.253089999438107],
            [-66.932119, 18.253386999438103],
            [-67.03819, 18.280469999437379],
            [-67.037935, 18.289704999437134],
            [-67.052583, 18.306654999436688],
            [-67.050233, 18.348540999435581]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "011",
        COUNTYNS: "01581065",
        AFFGEOID: "0500000US55011",
        GEOID: "55011",
        NAME: "Buffalo",
        LSAD: "06",
        ALAND: 1739529649,
        AWATER: 98310686
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.022788, 44.590518999056847],
            [-91.650248, 44.596648999056839],
            [-91.529101, 44.596196999056829],
            [-91.529741, 44.246776999057047],
            [-91.612994, 44.172605999057104],
            [-91.534229, 44.096045999057189],
            [-91.5592155630397, 44.024209315638458],
            [-91.573283, 44.026900999057261],
            [-91.647873, 44.064108999057204],
            [-91.719097, 44.128852999057152],
            [-91.817302, 44.164234999057115],
            [-91.854499975297685, 44.197226518609298],
            [-91.892698, 44.231104999057067],
            [-91.916191, 44.318093999056984],
            [-91.9636, 44.362111999056964],
            [-92.084528580492787, 44.404614313857941],
            [-92.022788, 44.590518999056847]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "353",
        COUNTYNS: "01383962",
        AFFGEOID: "0500000US48353",
        GEOID: "48353",
        NAME: "Nolan",
        LSAD: "06",
        ALAND: 2362061742,
        AWATER: 5052158
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.660626, 32.525311999144179],
            [-100.146543, 32.522792999144215],
            [-100.151911, 32.082637999150407],
            [-100.235137, 32.082371999150418],
            [-100.271675, 32.081431999150446],
            [-100.665353, 32.085406999150365],
            [-100.660626, 32.525311999144179]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "053",
        COUNTYNS: "00161552",
        AFFGEOID: "0500000US01053",
        GEOID: "01053",
        NAME: "Escambia",
        LSAD: "06",
        ALAND: 2447822304,
        AWATER: 20797758
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.61589, 31.24445799916278],
            [-87.559287, 31.227290999163046],
            [-87.427455, 31.26038599916253],
            [-86.763961, 31.261292999162521],
            [-86.772519, 31.202242999163417],
            [-86.700282, 31.192216999163577],
            [-86.700251, 31.008900999166379],
            [-86.688240964246091, 30.99620188916807],
            [-86.785691976005893, 30.996982992683357],
            [-86.831979, 30.997353999166553],
            [-86.927851, 30.997677999166552],
            [-87.162644, 30.99902599916653],
            [-87.16308069276559, 30.999024183044135],
            [-87.312206, 30.998403999166541],
            [-87.425791, 30.998057999166544],
            [-87.519533, 30.99755199916655],
            [-87.598828946262103, 30.997422176071858],
            [-87.61553, 31.007723999166394],
            [-87.61589, 31.24445799916278]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "015",
        COUNTYNS: "01581067",
        AFFGEOID: "0500000US55015",
        GEOID: "55015",
        NAME: "Calumet",
        LSAD: "06",
        ALAND: 824226133,
        AWATER: 204199001
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.40407, 44.244101999057051],
            [-88.192611, 44.241946999057042],
            [-88.04324, 44.24101799905705],
            [-88.041794, 43.891691999057407],
            [-88.162274, 43.891510999057409],
            [-88.161654, 43.937682999057351],
            [-88.404187, 43.93819999905736],
            [-88.40407, 44.244101999057051]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "105",
        COUNTYNS: "00659498",
        AFFGEOID: "0500000US27105",
        GEOID: "27105",
        NAME: "Nobles",
        LSAD: "06",
        ALAND: 1852077339,
        AWATER: 19323570
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.05232, 43.849069999057456],
            [-95.462427, 43.847910999057454],
            [-95.452502, 43.847952999057455],
            [-95.454432238660601, 43.500322084307975],
            [-95.486803, 43.500245999057981],
            [-95.834421, 43.499965999057977],
            [-95.860946201572304, 43.499992883963586],
            [-96.05316283450999, 43.500187707215467],
            [-96.05232, 43.849069999057456]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "153",
        COUNTYNS: "00659521",
        AFFGEOID: "0500000US27153",
        GEOID: "27153",
        NAME: "Todd",
        LSAD: "06",
        ALAND: 2447451028,
        AWATER: 89444026
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.15557, 46.36887999905791],
            [-94.731442, 46.368604999057915],
            [-94.717126, 46.351367999057899],
            [-94.653462, 46.348677999057877],
            [-94.64399, 45.773949999057159],
            [-95.139731, 45.773415999057143],
            [-95.14588, 46.106760999057535],
            [-95.145791, 46.282367999057783],
            [-95.15557, 46.36887999905791]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "039",
        COUNTYNS: "00758474",
        AFFGEOID: "0500000US29039",
        GEOID: "29039",
        NAME: "Cedar",
        LSAD: "06",
        ALAND: 1228905197,
        AWATER: 62603495
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.073524, 37.639721999087328],
            [-94.065675, 37.900986999085205],
            [-93.808648, 37.892774999085262],
            [-93.811402, 37.83457099908572],
            [-93.628404, 37.829434999085777],
            [-93.609489, 37.741754999086496],
            [-93.616033, 37.572688999087887],
            [-94.075385, 37.581569999087819],
            [-94.073524, 37.639721999087328]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "093",
        COUNTYNS: "01008563",
        AFFGEOID: "0500000US37093",
        GEOID: "37093",
        NAME: "Hoke",
        LSAD: "06",
        ALAND: 1010380313,
        AWATER: 3981006
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.458751, 35.043638999112659],
            [-79.335477, 35.16158799911134],
            [-79.246383, 35.212963999110777],
            [-79.097602, 35.174193999111203],
            [-79.112921, 35.125650999111748],
            [-79.036917, 34.956952999113632],
            [-79.038747, 34.95271499911366],
            [-79.191391, 34.833716999115012],
            [-79.347823, 34.838616999114961],
            [-79.353124, 34.944241999113771],
            [-79.458751, 35.043638999112659]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "009",
        COUNTYNS: "00424206",
        AFFGEOID: "0500000US17009",
        GEOID: "17009",
        NAME: "Brown",
        LSAD: "06",
        ALAND: 791518279,
        AWATER: 4139308
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.913616, 40.104451999070236],
            [-90.695884, 40.103793999070248],
            [-90.607134, 39.981657999070919],
            [-90.513747, 39.987890999070885],
            [-90.512616, 39.965284999071024],
            [-90.583534, 39.876749999071535],
            [-90.571754, 39.839325999071747],
            [-90.890082, 39.840999999071748],
            [-90.916609, 39.845074999071727],
            [-90.913616, 40.104451999070236]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "187",
        COUNTYNS: "01785134",
        AFFGEOID: "0500000US17187",
        GEOID: "17187",
        NAME: "Warren",
        LSAD: "06",
        ALAND: 1404823489,
        AWATER: 1674135
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.785194, 41.068748999065413],
            [-90.439423, 41.063994999065436],
            [-90.444343, 40.71466699906707],
            [-90.445506, 40.627842999067489],
            [-90.789652, 40.63574999906745],
            [-90.785194, 41.068748999065413]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "071",
        COUNTYNS: "00424237",
        AFFGEOID: "0500000US17071",
        GEOID: "17071",
        NAME: "Henderson",
        LSAD: "06",
        ALAND: 981273713,
        AWATER: 42340542
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.12082, 40.672776999067267],
            [-91.118222938397508, 40.699534649886246],
            [-91.115735, 40.725167999067011],
            [-91.091703, 40.779707999066758],
            [-91.092993, 40.821078999066557],
            [-91.044653, 40.868355999066331],
            [-90.985462, 40.912140999066118],
            [-90.952233, 40.95404699906593],
            [-90.945324, 41.019278999065641],
            [-90.951894142681283, 41.069872734931799],
            [-90.785194, 41.068748999065413],
            [-90.789652, 40.63574999906745],
            [-90.904216, 40.639200999067434],
            [-91.18546060870429, 40.638111811690941],
            [-91.12082, 40.672776999067267]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "139",
        COUNTYNS: "01623012",
        AFFGEOID: "0500000US26139",
        GEOID: "26139",
        NAME: "Ottawa",
        LSAD: "06",
        ALAND: 1459627896,
        AWATER: 2765705477
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.273928278671391, 43.118367967766495],
            [-85.907435, 43.118910999058691],
            [-85.908388, 43.205919999058509],
            [-85.790662, 43.205166999058513],
            [-85.788503, 43.030876999058883],
            [-85.782106, 42.856295999059292],
            [-85.782498, 42.768199999059505],
            [-86.126702, 42.768401999059499],
            [-86.137046, 42.768341999059508],
            [-86.208538502909676, 42.767543870975189],
            [-86.214138, 42.883554999059221],
            [-86.226305, 42.988283999058979],
            [-86.254646, 43.083408999058761],
            [-86.273928278671391, 43.118367967766495]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "129",
        COUNTYNS: "00758519",
        AFFGEOID: "0500000US29129",
        GEOID: "29129",
        NAME: "Mercer",
        LSAD: "06",
        ALAND: 1175427728,
        AWATER: 3302140
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.774344204030285, 40.577530452458852],
            [-93.597352, 40.579495999067731],
            [-93.556896674271684, 40.579659484128229],
            [-93.374386258763806, 40.580397031676931],
            [-93.37484, 40.472211999068271],
            [-93.366935, 40.382998999068739],
            [-93.367214, 40.266313999069361],
            [-93.763324, 40.263987999069364],
            [-93.764823, 40.472514999068274],
            [-93.774344204030285, 40.577530452458852]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "147",
        COUNTYNS: "01383859",
        AFFGEOID: "0500000US48147",
        GEOID: "48147",
        NAME: "Fannin",
        LSAD: "06",
        ALAND: 2307250942,
        AWATER: 20847063
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.384602, 33.397825999132486],
            [-96.379660825952101, 33.715530907694436],
            [-96.363135, 33.694214999128711],
            [-96.307389, 33.735004999128194],
            [-96.277269, 33.769734999127756],
            [-96.229023, 33.748020999128038],
            [-96.173025, 33.800559999127373],
            [-96.15163, 33.831945999126972],
            [-96.063924, 33.841522999126859],
            [-95.935325, 33.875098999126443],
            [-95.887491, 33.863855999126578],
            [-95.844878534070688, 33.860421903845619],
            [-95.855013, 33.827991999127029],
            [-95.857738, 33.461721999131669],
            [-95.858723, 33.409529999132332],
            [-96.295413, 33.35193599913309],
            [-96.384495, 33.341118999133229],
            [-96.384602, 33.397825999132486]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "139",
        COUNTYNS: "01500440",
        AFFGEOID: "0500000US51139",
        GEOID: "51139",
        NAME: "Page",
        LSAD: "06",
        ALAND: 802893902,
        AWATER: 8425697
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.641769, 38.604718999079829],
            [-78.548828, 38.738654999078868],
            [-78.50926, 38.725625999078964],
            [-78.394704, 38.822789999078267],
            [-78.290403, 38.780372999078565],
            [-78.284805, 38.759314999078718],
            [-78.318775, 38.737865999078871],
            [-78.338176, 38.627311999079652],
            [-78.442767, 38.527774999080393],
            [-78.452801, 38.475526999080778],
            [-78.48574, 38.421578999081177],
            [-78.548833, 38.426697999081142],
            [-78.691312, 38.510007999080521],
            [-78.641769, 38.604718999079829]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "105",
        COUNTYNS: "01581113",
        AFFGEOID: "0500000US55105",
        GEOID: "55105",
        NAME: "Rock",
        LSAD: "06",
        ALAND: 1859979851,
        AWATER: 20695904
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.369128, 42.845035999059313],
            [-89.013582, 42.847629999059308],
            [-88.918672, 42.846120999059316],
            [-88.777076, 42.842693999059328],
            [-88.777137, 42.834487999059334],
            [-88.776495760735187, 42.494136599527636],
            [-88.940384860378003, 42.495439826531424],
            [-88.992560226817304, 42.495854718959428],
            [-89.042898, 42.496254999060213],
            [-89.365798607663493, 42.500025966011712],
            [-89.369128, 42.845035999059313]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "101",
        COUNTYNS: "00069900",
        AFFGEOID: "0500000US05101",
        GEOID: "05101",
        NAME: "Newton",
        LSAD: "06",
        ALAND: 2126136365,
        AWATER: 5930177
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.523639, 35.881591999103676],
            [-93.450715, 35.967492999102795],
            [-93.445619, 36.080748999101644],
            [-93.479528, 36.125071999101195],
            [-93.30082, 36.121549999101248],
            [-93.265157, 36.120314999101261],
            [-92.944666, 36.115122999101295],
            [-92.93851, 36.071725999101737],
            [-92.940932, 35.990068999102569],
            [-92.951315, 35.724662999105298],
            [-93.164452, 35.728830999105249],
            [-93.519575, 35.73442199910518],
            [-93.519204, 35.763520999104884],
            [-93.523639, 35.881591999103676]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "087",
        COUNTYNS: "00198159",
        AFFGEOID: "0500000US08087",
        GEOID: "08087",
        NAME: "Morgan",
        LSAD: "06",
        ALAND: 3316441507,
        AWATER: 34663099
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.150494, 40.350244999068913],
            [-104.147714, 40.524388999068009],
            [-103.581844, 40.523382999068012],
            [-103.4672, 40.523252999068013],
            [-103.465377, 40.436345999068458],
            [-103.47199, 40.001501999070811],
            [-103.705701, 40.001365999070806],
            [-104.150332, 40.000855999070822],
            [-104.150494, 40.350244999068913]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "243",
        COUNTYNS: "00352242",
        AFFGEOID: "0500000US13243",
        GEOID: "13243",
        NAME: "Randolph",
        LSAD: "06",
        ALAND: 1109126226,
        AWATER: 6830041
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.95864, 31.777853999154832],
            [-84.910338, 31.776840999154839],
            [-84.907006, 31.924464999152683],
            [-84.810118, 31.931839999152579],
            [-84.69173, 31.920442999152758],
            [-84.675788, 31.928679999152635],
            [-84.65582, 31.920307999152758],
            [-84.599776, 31.920170999152763],
            [-84.603086, 31.772000999154912],
            [-84.535434, 31.67649399915631],
            [-84.546854, 31.621198999157127],
            [-84.59715, 31.637612999156882],
            [-84.817843, 31.619770999157161],
            [-84.942353, 31.618668999157169],
            [-84.930998, 31.672308999156371],
            [-84.949784, 31.679913999156273],
            [-84.95864, 31.777853999154832]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "195",
        COUNTYNS: "01008596",
        AFFGEOID: "0500000US37195",
        GEOID: "37195",
        NAME: "Wilson",
        LSAD: "06",
        ALAND: 952010950,
        AWATER: 14334531
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.175342, 35.73952899910514],
            [-77.915657, 35.83644999910414],
            [-77.844737, 35.837617999104125],
            [-77.828444, 35.867207999103812],
            [-77.751339, 35.82779399910423],
            [-77.718436, 35.728822999105248],
            [-77.665131, 35.674934999105808],
            [-77.700692, 35.652294999106054],
            [-77.801777, 35.586204999106755],
            [-77.822511, 35.585382999106763],
            [-77.873751, 35.586240999106749],
            [-78.048572, 35.595876999106643],
            [-78.064784, 35.585262999106753],
            [-78.115641, 35.600432999106594],
            [-78.161255, 35.712870999105427],
            [-78.192117, 35.730544999105241],
            [-78.175342, 35.73952899910514]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "021",
        COUNTYNS: "00465200",
        AFFGEOID: "0500000US19021",
        GEOID: "19021",
        NAME: "Buena Vista",
        LSAD: "06",
        ALAND: 1488989049,
        AWATER: 13791660
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.388182, 42.909896999059157],
            [-94.91389, 42.909699999059157],
            [-94.914485, 42.560308999060048],
            [-95.38801, 42.56174199906004],
            [-95.388182, 42.909896999059157]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "119",
        COUNTYNS: "00516906",
        AFFGEOID: "0500000US21119",
        GEOID: "21119",
        NAME: "Knott",
        LSAD: "06",
        ALAND: 910427757,
        AWATER: 3453309
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.124348, 37.404972999089317],
            [-83.089007, 37.522647999088313],
            [-82.948544, 37.503166999088478],
            [-82.92546, 37.486524999088616],
            [-82.831473, 37.466989999088781],
            [-82.835476, 37.414444999089248],
            [-82.762749, 37.425132999089158],
            [-82.710805, 37.285322999090354],
            [-82.732693, 37.271501999090475],
            [-82.819373, 37.268670999090496],
            [-82.979183, 37.172419999091346],
            [-83.000142, 37.195709999091129],
            [-83.066377, 37.244394999090716],
            [-83.126805, 37.235972999090791],
            [-83.124348, 37.404972999089317]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "093",
        COUNTYNS: "01629464",
        AFFGEOID: "0500000US22093",
        GEOID: "22093",
        NAME: "St. James",
        LSAD: "15",
        ALAND: 625582188,
        AWATER: 42168610
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.963693, 30.066449999181359],
            [-90.915565, 30.113284999180596],
            [-90.641998, 30.166429999179726],
            [-90.68885, 30.035952999181855],
            [-90.656312, 29.889245999184276],
            [-90.77729, 29.922028999183734],
            [-90.885589, 29.905352999184007],
            [-90.925849, 29.934954999183514],
            [-90.959869, 29.97792399918281],
            [-90.963693, 30.066449999181359]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "179",
        COUNTYNS: "00450337",
        AFFGEOID: "0500000US18179",
        GEOID: "18179",
        NAME: "Wells",
        LSAD: "06",
        ALAND: 953341274,
        AWATER: 5596377
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.448825, 40.653606999067371],
            [-85.334667, 40.654412999067368],
            [-85.33603, 40.917081999066106],
            [-85.073861, 40.917822999066104],
            [-85.068479, 40.568239999067785],
            [-85.201146, 40.567241999067797],
            [-85.390539, 40.566897999067798],
            [-85.447014, 40.566928999067791],
            [-85.448825, 40.653606999067371]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "165",
        COUNTYNS: "00485358",
        AFFGEOID: "0500000US20165",
        GEOID: "20165",
        NAME: "Rush",
        LSAD: "06",
        ALAND: 1858997545,
        AWATER: 544720
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.585087, 38.696536999079164],
            [-99.042626, 38.696806999079165],
            [-99.032971, 38.696758999079165],
            [-99.032408, 38.348333999081731],
            [-99.584794, 38.349385999081711],
            [-99.585087, 38.696536999079164]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "013",
        COUNTYNS: "00882276",
        AFFGEOID: "0500000US34013",
        GEOID: "34013",
        NAME: "Essex",
        LSAD: "06",
        ALAND: 326559360,
        AWATER: 8636456
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.367145, 40.761111999066834],
            [-74.326114, 40.908604999066149],
            [-74.272063, 40.897426999066205],
            [-74.197872, 40.85705299906639],
            [-74.130016, 40.819937999066561],
            [-74.139575, 40.805408999066628],
            [-74.147529, 40.786490999066714],
            [-74.16598, 40.748067999066905],
            [-74.117605, 40.739637999066943],
            [-74.117057, 40.710380999067084],
            [-74.136703, 40.674443999067257],
            [-74.209248, 40.690708999067184],
            [-74.285187, 40.72215199906703],
            [-74.297212, 40.715188999067053],
            [-74.371738, 40.739639999066945],
            [-74.367145, 40.761111999066834]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "036",
        COUNTYNS: "01480109",
        AFFGEOID: "0500000US51036",
        GEOID: "51036",
        NAME: "Charles City",
        LSAD: "06",
        ALAND: 473494108,
        AWATER: 55492133
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.249665, 37.381999999089523],
            [-77.224636, 37.379827999089528],
            [-77.177324, 37.490599999088595],
            [-77.021316, 37.416009999089226],
            [-76.904116, 37.403820999089334],
            [-76.904471, 37.377664999089561],
            [-76.874437, 37.365426999089657],
            [-76.883156, 37.223056999090893],
            [-76.921569, 37.225339999090885],
            [-76.972439, 37.248500999090673],
            [-76.996003, 37.300978999090226],
            [-77.069269, 37.269741999090492],
            [-77.124694, 37.302922999090192],
            [-77.270675, 37.320755999090039],
            [-77.249665, 37.381999999089523]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "063",
        COUNTYNS: "01244251",
        AFFGEOID: "0500000US45063",
        GEOID: "45063",
        NAME: "Lexington",
        LSAD: "06",
        ALAND: 1810553251,
        AWATER: 151957530
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.471972, 34.076551999123971],
            [-81.394124, 34.071487999124024],
            [-81.437172, 34.1299629991233],
            [-81.338961, 34.197586999122478],
            [-81.275845, 34.097380999123708],
            [-81.139509, 34.068291999124064],
            [-81.05211, 33.997615999124932],
            [-81.01233, 33.880079999126394],
            [-81.058521, 33.747052999128044],
            [-81.014003, 33.781240999127618],
            [-80.924968, 33.75680299912792],
            [-81.042854, 33.707130999128552],
            [-81.086633, 33.695408999128695],
            [-81.187271, 33.652936999129231],
            [-81.417939, 33.734442999128191],
            [-81.571485, 33.877229999126406],
            [-81.471972, 34.076551999123971]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "145",
        COUNTYNS: "01639785",
        AFFGEOID: "0500000US47145",
        GEOID: "47145",
        NAME: "Roane",
        LSAD: "06",
        ALAND: 934284690,
        AWATER: 88700776
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.781901, 35.824999999104264],
            [-84.729634, 35.858381999103912],
            [-84.680633, 35.908453999103408],
            [-84.603763, 35.954008999102939],
            [-84.446054, 35.985983999102601],
            [-84.341812, 36.048976999101974],
            [-84.32006, 35.991606999102551],
            [-84.271587, 35.91016499910338],
            [-84.263383, 35.896939999103516],
            [-84.32997, 35.892903999103552],
            [-84.347624, 35.832366999104174],
            [-84.584472, 35.644423999106138],
            [-84.619865, 35.644650999106133],
            [-84.619031, 35.707879999105472],
            [-84.723853, 35.752895999105014],
            [-84.754472, 35.754389999104994],
            [-84.781901, 35.824999999104264]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "011",
        COUNTYNS: "01247983",
        AFFGEOID: "0500000US45011",
        GEOID: "45011",
        NAME: "Barnwell",
        LSAD: "06",
        ALAND: 1420294943,
        AWATER: 23008120
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.762505982795787, 33.197265887432408],
            [-81.742519, 33.208845999134951],
            [-81.372931, 33.490416999131305],
            [-81.301445, 33.481217999131417],
            [-81.222673, 33.439999999131942],
            [-81.227804, 33.160696999135588],
            [-81.193099, 33.11866799913615],
            [-81.364857, 33.108060999136285],
            [-81.541881, 33.158278999135625],
            [-81.615955710789095, 33.089338538500542],
            [-81.658433, 33.103151999136351],
            [-81.755135, 33.15154999913571],
            [-81.762505982795787, 33.197265887432408]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "047",
        COUNTYNS: "01581083",
        AFFGEOID: "0500000US55047",
        GEOID: "55047",
        NAME: "Green Lake",
        LSAD: "06",
        ALAND: 904711548,
        AWATER: 79805895
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.245375, 43.75981299905758],
            [-89.167886, 43.765642999057576],
            [-89.198299, 43.812676999057508],
            [-89.168612, 43.982833999057299],
            [-88.886193, 43.983232999057314],
            [-88.885697, 43.895183999057402],
            [-88.886052, 43.63353999905776],
            [-89.006848, 43.633044999057759],
            [-89.12717, 43.631723999057755],
            [-89.245437, 43.643082999057739],
            [-89.245375, 43.75981299905758]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "151",
        COUNTYNS: "01804556",
        AFFGEOID: "0500000US72151",
        GEOID: "72151",
        NAME: "Yabucoa",
        LSAD: "13",
        ALAND: 143005178,
        AWATER: 72592521
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.010245, 18.075564999442815],
            [-65.933166, 18.093753999442331],
            [-65.926227, 18.119962999441636],
            [-65.877615, 18.118166999441684],
            [-65.848117, 18.117487999441703],
            [-65.802906686375493, 18.071185999442932],
            [-65.809174, 18.056817999443314],
            [-65.833142, 18.024217999444183],
            [-65.850918555785199, 18.011966436323309],
            [-65.864692, 18.025736999444145],
            [-65.988523, 18.058940999443259],
            [-66.010245, 18.075564999442815]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "013",
        COUNTYNS: "01448021",
        AFFGEOID: "0500000US49013",
        GEOID: "49013",
        NAME: "Duchesne",
        LSAD: "06",
        ALAND: 8379502703,
        AWATER: 38797815
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.89198, 40.727122999066999],
            [-110.822478, 40.710460999067081],
            [-110.750733, 40.747705999066909],
            [-110.656482, 40.740274999066941],
            [-110.625737, 40.76947099906679],
            [-110.561377, 40.758364999066856],
            [-110.378962, 40.787179999066709],
            [-110.292692, 40.833342999066502],
            [-110.137819, 40.810912999066602],
            [-110.000711, 40.813677999066584],
            [-109.976402, 40.809685999066602],
            [-109.976814, 39.806229999071945],
            [-110.85778, 39.813284999071904],
            [-110.857647, 39.899706999071405],
            [-110.891655, 39.899653999071404],
            [-110.901974, 40.678161999067243],
            [-110.89198, 40.727122999066999]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "023",
        COUNTYNS: "01247988",
        AFFGEOID: "0500000US45023",
        GEOID: "45023",
        NAME: "Chester",
        LSAD: "06",
        ALAND: 1503893316,
        AWATER: 14261786
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.456304, 34.792520999115489],
            [-81.478454, 34.821508999115167],
            [-80.898215, 34.820745999115161],
            [-80.876121, 34.788046999115544],
            [-80.860274, 34.695846999116604],
            [-80.896406, 34.631780999117339],
            [-80.877491, 34.543145999118373],
            [-81.422706, 34.572028999118039],
            [-81.415894, 34.633588999117329],
            [-81.482351, 34.744397999116039],
            [-81.456304, 34.792520999115489]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "009",
        COUNTYNS: "00695729",
        AFFGEOID: "0500000US28009",
        GEOID: "28009",
        NAME: "Benton",
        LSAD: "06",
        ALAND: 1053131400,
        AWATER: 5279183
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.352679391848397, 34.993996140859096],
            [-89.198287901494993, 34.994450529228303],
            [-89.02654, 34.994955999113202],
            [-89.017127011552404, 34.994967611949498],
            [-89.017551, 34.858722999114732],
            [-89.088065, 34.815306999115229],
            [-89.088488, 34.598246999117734],
            [-89.22228, 34.597628999117745],
            [-89.245645, 34.583233999117908],
            [-89.298334, 34.582925999117911],
            [-89.299227, 34.844414999114896],
            [-89.352705, 34.844431999114903],
            [-89.352679391848397, 34.993996140859096]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "065",
        COUNTYNS: "00516879",
        AFFGEOID: "0500000US21065",
        GEOID: "21065",
        NAME: "Estill",
        LSAD: "06",
        ALAND: 655468668,
        AWATER: 6557602
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.126948, 37.642420999087307],
            [-84.089212, 37.668148999087094],
            [-84.073908, 37.801116999086013],
            [-84.120226, 37.817380999085877],
            [-84.080337, 37.85306599908558],
            [-84.075107, 37.823271999085826],
            [-84.001967, 37.83759199908571],
            [-83.987054, 37.848306999085615],
            [-83.906251, 37.759362999086335],
            [-83.777592, 37.756775999086365],
            [-83.7221, 37.716419999086696],
            [-83.822121, 37.665577999087112],
            [-83.90557, 37.542855999088154],
            [-83.965244, 37.581420999087825],
            [-84.090364, 37.566226999087952],
            [-84.126948, 37.642420999087307]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "129",
        COUNTYNS: "00356672",
        AFFGEOID: "0500000US13129",
        GEOID: "13129",
        NAME: "Gordon",
        LSAD: "06",
        ALAND: 921597971,
        AWATER: 5729801
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.069159, 34.587183999117869],
            [-85.050446, 34.622481999117461],
            [-84.926119, 34.616163999117525],
            [-84.913456, 34.634127999117311],
            [-84.863793, 34.608596999117609],
            [-84.715692, 34.62287599911744],
            [-84.654523, 34.583186999117906],
            [-84.654366, 34.54894599911831],
            [-84.644432, 34.455788999119406],
            [-84.653232, 34.41258999911993],
            [-84.989916, 34.384557999120247],
            [-85.005775, 34.392445999120156],
            [-85.104811, 34.40460799912001],
            [-85.069159, 34.587183999117869]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "127",
        COUNTYNS: "00835885",
        AFFGEOID: "0500000US31127",
        GEOID: "31127",
        NAME: "Nemaha",
        LSAD: "06",
        ALAND: 1055100608,
        AWATER: 5769943
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.067012, 40.523147999068016],
            [-95.712280371801072, 40.523754425275413],
            [-95.694726, 40.493601999068169],
            [-95.684363, 40.463365999068323],
            [-95.649418, 40.396148999068664],
            [-95.641027, 40.366398999068828],
            [-95.653729, 40.322581999069044],
            [-95.598657, 40.309808999069119],
            [-95.553292, 40.291157999069227],
            [-95.548182001180081, 40.264414671912057],
            [-95.547870316270291, 40.262783459889178],
            [-96.010176, 40.261481999069375],
            [-96.066736, 40.262037999069378],
            [-96.067012, 40.523147999068016]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "009",
        COUNTYNS: "00069897",
        AFFGEOID: "0500000US05009",
        GEOID: "05009",
        NAME: "Boone",
        LSAD: "06",
        ALAND: 1528076369,
        AWATER: 31092507
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.30252, 36.482491999097711],
            [-93.293447355633987, 36.498259374331852],
            [-93.125969, 36.497850999097558],
            [-92.854049114984889, 36.498023380239133],
            [-92.884445, 36.490712999097632],
            [-92.890695, 36.114131999101325],
            [-92.944666, 36.115122999101295],
            [-93.265157, 36.120314999101261],
            [-93.30082, 36.121549999101248],
            [-93.30252, 36.482491999097711]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "009",
        COUNTYNS: "00295749",
        AFFGEOID: "0500000US12009",
        GEOID: "12009",
        NAME: "Brevard",
        LSAD: "06",
        ALAND: 2629333877,
        AWATER: 1403340249
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.967895, 28.790196999203062],
            [-80.727505670073796, 28.791192870525744],
            [-80.647288, 28.677874999205049],
            [-80.583884, 28.597704999206467],
            [-80.525094, 28.459453999208936],
            [-80.587813, 28.410855999209815],
            [-80.606874, 28.336483999211147],
            [-80.604214, 28.257732999212578],
            [-80.589975, 28.177989999214024],
            [-80.547675, 28.048794999216394],
            [-80.44767694921758, 27.860513512580869],
            [-80.509075, 27.822057999220579],
            [-80.868881, 27.822521999220559],
            [-80.862908, 28.34748699921095],
            [-80.88089, 28.503632999208151],
            [-80.952011, 28.604791999206341],
            [-80.98725, 28.612996999206196],
            [-80.967895, 28.790196999203062]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "015",
        COUNTYNS: "00295742",
        AFFGEOID: "0500000US12015",
        GEOID: "12015",
        NAME: "Charlotte",
        LSAD: "06",
        ALAND: 1764104254,
        AWATER: 458735963
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.369201392169089, 26.946081024870693],
            [-82.255521, 26.945263999237209],
            [-82.255237, 27.032974999235513],
            [-82.057469, 27.032118999235532],
            [-81.983242, 27.035249999235468],
            [-81.562202, 27.033835999235503],
            [-81.565931, 26.769551999240633],
            [-81.931098, 26.770048999240633],
            [-82.205562, 26.77052799924062],
            [-82.280541277628799, 26.789308585683344],
            [-82.314277, 26.8583839992389],
            [-82.369201392169089, 26.946081024870693]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "119",
        COUNTYNS: "00485024",
        AFFGEOID: "0500000US20119",
        GEOID: "20119",
        NAME: "Meade",
        LSAD: "06",
        ALAND: 2533222390,
        AWATER: 3258258
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.652642, 37.47484799908873],
            [-100.216229, 37.475189999088705],
            [-100.107248, 37.474835999088718],
            [-100.091529, 37.386891999089471],
            [-100.089481743902994, 37.001479468556369],
            [-100.552683, 37.000734999092884],
            [-100.633324986158001, 37.000173608145779],
            [-100.63392, 37.381173999089533],
            [-100.652728, 37.387626999089456],
            [-100.652642, 37.47484799908873]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "125",
        COUNTYNS: "00485027",
        AFFGEOID: "0500000US20125",
        GEOID: "20125",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 1666728645,
        AWATER: 20601461
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.964535, 37.363578999089682],
            [-95.961605, 37.386635999089471],
            [-95.52556, 37.3839789990895],
            [-95.52113, 37.383989999089501],
            [-95.5224145523987, 36.999319785875791],
            [-95.573598, 36.999309999092894],
            [-95.786761870778193, 36.999270916684893],
            [-95.928122, 36.999244999092888],
            [-95.964271232102192, 36.999223143375289],
            [-95.964399, 37.299231999090239],
            [-95.964535, 37.363578999089682]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "149",
        COUNTYNS: "01625034",
        AFFGEOID: "0500000US26149",
        GEOID: "26149",
        NAME: "St. Joseph",
        LSAD: "06",
        ALAND: 1296530601,
        AWATER: 52921099
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.760186, 41.798813999062467],
            [-85.762943, 42.069326999061538],
            [-85.293626, 42.071552999061531],
            [-85.2921788113335, 41.759755273764412],
            [-85.659750237990906, 41.759236685109613],
            [-85.791334961962789, 41.759051038620107],
            [-85.760186, 41.798813999062467]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "027",
        COUNTYNS: "01155131",
        AFFGEOID: "0500000US41027",
        GEOID: "41027",
        NAME: "Hood River",
        LSAD: "06",
        ALAND: 1352203450,
        AWATER: 29040461
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.923749361985017, 45.654349508026442],
            [-121.900858, 45.662008999057058],
            [-121.867167, 45.693276999057076],
            [-121.811304, 45.706760999057089],
            [-121.735104, 45.694038999057078],
            [-121.668362, 45.705081999057086],
            [-121.533106, 45.7265409990571],
            [-121.524006104220021, 45.723836224256296],
            [-121.440695827861006, 45.69907379382947],
            [-121.482168, 45.51960399905694],
            [-121.481888, 45.258127999056818],
            [-121.696479, 45.25791999905681],
            [-121.696925, 45.373563999056856],
            [-121.819587, 45.46167499905691],
            [-121.90661, 45.521229999056942],
            [-121.923749361985017, 45.654349508026442]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "073",
        COUNTYNS: "01678557",
        AFFGEOID: "0500000US54073",
        GEOID: "54073",
        NAME: "Pleasants",
        LSAD: "06",
        ALAND: 337015703,
        AWATER: 11459910
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.370389808826289, 39.348700671478156],
            [-81.347567, 39.345769999074776],
            [-81.249088, 39.389991999074489],
            [-81.185946, 39.430730999074243],
            [-81.128533, 39.449374999074116],
            [-81.121273856107791, 39.457697412963363],
            [-81.029932, 39.467744999074],
            [-81.007836, 39.350922999074747],
            [-81.221335, 39.307974999075014],
            [-81.239477, 39.268327999075261],
            [-81.363638, 39.320501999074928],
            [-81.370389808826289, 39.348700671478156]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "067",
        COUNTYNS: "00295732",
        AFFGEOID: "0500000US12067",
        GEOID: "12067",
        NAME: "Lafayette",
        LSAD: "06",
        ALAND: 1407329163,
        AWATER: 11745118
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.36726, 30.260439999178203],
            [-83.247253, 30.260669999178191],
            [-83.225659, 30.113320999180591],
            [-83.160557, 30.109822999180647],
            [-82.963112, 29.993049999182563],
            [-82.879802, 29.886846999184314],
            [-82.893371, 29.826838999185309],
            [-82.920608, 29.824142999185355],
            [-83.318859, 29.822622999185388],
            [-83.35286, 29.822221999185388],
            [-83.37089, 29.887933999184291],
            [-83.36726, 30.260439999178203]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "081",
        COUNTYNS: "00424242",
        AFFGEOID: "0500000US17081",
        GEOID: "17081",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 1479314410,
        AWATER: 32679991
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.147416, 38.212895999082754],
            [-89.144388, 38.473877999080784],
            [-88.698961, 38.474913999080783],
            [-88.702391, 38.25666099908242],
            [-88.704606, 38.125194999083433],
            [-89.129637, 38.124746999083428],
            [-89.149457, 38.130829999083389],
            [-89.147416, 38.212895999082754]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "137",
        COUNTYNS: "00450385",
        AFFGEOID: "0500000US18137",
        GEOID: "18137",
        NAME: "Ripley",
        LSAD: "06",
        ALAND: 1156236802,
        AWATER: 4237459
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.440054, 39.195616999075739],
            [-85.29654, 39.268290999075283],
            [-85.217883, 39.308473999075012],
            [-85.065574, 39.307231999075022],
            [-85.132508, 38.948054999077407],
            [-85.135832, 38.929577999077537],
            [-85.203166, 38.913802999077639],
            [-85.444897, 38.912997999077653],
            [-85.440054, 39.195616999075739]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "205",
        COUNTYNS: "00485063",
        AFFGEOID: "0500000US20205",
        GEOID: "20205",
        NAME: "Wilson",
        LSAD: "06",
        ALAND: 1477379082,
        AWATER: 12055440
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.961002, 37.603760999087633],
            [-95.960876, 37.734303999086556],
            [-95.525499, 37.73275899908657],
            [-95.52556, 37.3839789990895],
            [-95.961605, 37.386635999089471],
            [-95.961002, 37.603760999087633]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "171",
        COUNTYNS: "00485048",
        AFFGEOID: "0500000US20171",
        GEOID: "20171",
        NAME: "Scott",
        LSAD: "06",
        ALAND: 1858536844,
        AWATER: 306079
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.128379, 38.700602999079145],
            [-100.818698, 38.699860999079142],
            [-100.688006, 38.70002099907915],
            [-100.684699, 38.264136999082361],
            [-101.103268, 38.264556999082352],
            [-101.125438, 38.264508999082352],
            [-101.128379, 38.700602999079145]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "215",
        COUNTYNS: "00516954",
        AFFGEOID: "0500000US21215",
        GEOID: "21215",
        NAME: "Spencer",
        LSAD: "06",
        ALAND: 483503272,
        AWATER: 12896776
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.516882, 38.011266999084313],
            [-85.436129, 38.104760999083581],
            [-85.42883, 38.118382999083487],
            [-85.424054, 38.147437999083259],
            [-85.232406, 38.08943099908371],
            [-85.196117, 38.074821999083824],
            [-85.101646, 38.03726999908411],
            [-85.169359, 37.997575999084432],
            [-85.167834, 37.971808999084644],
            [-85.399559, 37.939928999084891],
            [-85.489202, 37.990653999084479],
            [-85.516882, 38.011266999084313]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "135",
        COUNTYNS: "01623010",
        AFFGEOID: "0500000US26135",
        GEOID: "26135",
        NAME: "Oscoda",
        LSAD: "06",
        ALAND: 1465088065,
        AWATER: 15136485
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.371737, 44.855038999056767],
            [-83.888482, 44.856634999056766],
            [-83.888484, 44.856457999056758],
            [-83.886634, 44.508975999056879],
            [-84.370643, 44.507222999056864],
            [-84.371737, 44.855038999056767]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "007",
        COUNTYNS: "00758458",
        AFFGEOID: "0500000US29007",
        GEOID: "29007",
        NAME: "Audrain",
        LSAD: "06",
        ALAND: 1792880224,
        AWATER: 11880946
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.310028, 39.347794999074758],
            [-91.874651, 39.337793999074826],
            [-91.717968, 39.33912199907482],
            [-91.718467, 39.324504999074904],
            [-91.438235, 39.318715999074939],
            [-91.409033, 39.142541999076101],
            [-91.629598, 39.147852999076058],
            [-91.633998, 39.059056999076653],
            [-92.110387, 39.064203999076625],
            [-92.104374, 39.239808999075457],
            [-92.314471, 39.246453999075413],
            [-92.310028, 39.347794999074758]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "003",
        COUNTYNS: "01155126",
        AFFGEOID: "0500000US41003",
        GEOID: "41003",
        NAME: "Benton",
        LSAD: "06",
        ALAND: 1748712527,
        AWATER: 7740249
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.816525, 44.315302999056996],
            [-123.715538, 44.360457999056976],
            [-123.720071, 44.433373999056911],
            [-123.597677, 44.433108999056913],
            [-123.602609, 44.721153999056789],
            [-123.301882, 44.719834999056793],
            [-123.149025, 44.720224999056789],
            [-123.073574, 44.650494999056818],
            [-123.17088, 44.634244999056818],
            [-123.260151, 44.555147999056857],
            [-123.214683, 44.50768099905688],
            [-123.245678, 44.332098999056981],
            [-123.180388, 44.283724999057029],
            [-123.593323, 44.276693999057031],
            [-123.775598, 44.283546999057023],
            [-123.816525, 44.315302999056996]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "459",
        COUNTYNS: "01384015",
        AFFGEOID: "0500000US48459",
        GEOID: "48459",
        NAME: "Upshur",
        LSAD: "06",
        ALAND: 1509791538,
        AWATER: 24997458
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.15274, 32.660948999142306],
            [-95.15211, 32.902640999139031],
            [-94.719942, 32.904501999139008],
            [-94.705878, 32.879176999139347],
            [-94.672351, 32.834755999139944],
            [-94.70214, 32.793087999140496],
            [-94.70179, 32.652208999142431],
            [-94.746136, 32.667539999142221],
            [-94.820223, 32.590647999143272],
            [-94.986585, 32.537323999144014],
            [-94.986935, 32.537245999144012],
            [-95.009992, 32.5164779991443],
            [-95.15341, 32.57011499914357],
            [-95.15274, 32.660948999142306]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "413",
        COUNTYNS: "01383992",
        AFFGEOID: "0500000US48413",
        GEOID: "48413",
        NAME: "Schleicher",
        LSAD: "06",
        ALAND: 3394546071,
        AWATER: 87401
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.962176, 31.082489999165247],
            [-100.688764, 31.086575999165184],
            [-100.115216, 31.087993999165167],
            [-100.116234, 30.710365999171028],
            [-100.960587, 30.7060709991711],
            [-100.962176, 31.082489999165247]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "099",
        COUNTYNS: "01639764",
        AFFGEOID: "0500000US47099",
        GEOID: "47099",
        NAME: "Lawrence",
        LSAD: "06",
        ALAND: 1598356130,
        AWATER: 2228678
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.57275, 35.049050999112595],
            [-87.588833, 35.058430999112488],
            [-87.575352, 35.398453999108753],
            [-87.44882, 35.420621999108519],
            [-87.448485, 35.457843999108114],
            [-87.294534, 35.444675999108256],
            [-87.227932, 35.429331999108406],
            [-87.206578, 35.433206999108378],
            [-87.232054, 35.292703999109911],
            [-87.2153, 35.286624999109968],
            [-87.224053641875699, 34.999230741085249],
            [-87.60609786765329, 35.003519525317401],
            [-87.57275, 35.049050999112595]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "181",
        COUNTYNS: "01480174",
        AFFGEOID: "0500000US51181",
        GEOID: "51181",
        NAME: "Surry",
        LSAD: "06",
        ALAND: 722475755,
        AWATER: 81216907
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.012301, 37.232882999090805],
            [-76.972439, 37.248500999090673],
            [-76.921569, 37.225339999090885],
            [-76.883156, 37.223056999090893],
            [-76.79774, 37.214086999090981],
            [-76.760314, 37.176798999091297],
            [-76.660445, 37.204145999091068],
            [-76.641581, 37.153826999091514],
            [-76.698255, 37.059139999092352],
            [-76.849684, 36.996210999092916],
            [-76.953546, 36.944449999093386],
            [-76.965139, 37.054307999092394],
            [-77.155415, 37.112033999091885],
            [-77.012301, 37.232882999090805]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "151",
        COUNTYNS: "01383861",
        AFFGEOID: "0500000US48151",
        GEOID: "48151",
        NAME: "Fisher",
        LSAD: "06",
        ALAND: 2328246701,
        AWATER: 7283897
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.65587, 32.963468999138215],
            [-100.519208, 32.962925999138214],
            [-100.144224, 32.95997799913826],
            [-100.146543, 32.522792999144215],
            [-100.660626, 32.525311999144179],
            [-100.65587, 32.963468999138215]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "003",
        COUNTYNS: "01247979",
        AFFGEOID: "0500000US45003",
        GEOID: "45003",
        NAME: "Aiken",
        LSAD: "06",
        ALAND: 2774109851,
        AWATER: 24619610
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.01655587044489, 33.52905538555671],
            [-81.651759, 33.814509999127189],
            [-81.571485, 33.877229999126406],
            [-81.417939, 33.734442999128191],
            [-81.187271, 33.652936999129231],
            [-81.372931, 33.490416999131305],
            [-81.742519, 33.208845999134951],
            [-81.762505982795787, 33.197265887432408],
            [-81.763535, 33.203647999135029],
            [-81.846536, 33.24174599913453],
            [-81.846500532357894, 33.247252084564359],
            [-81.846136, 33.303842999133721],
            [-81.932737, 33.343540999133197],
            [-81.920121, 33.410752999132328],
            [-81.926336, 33.462936999131649],
            [-81.990938, 33.494234999131258],
            [-82.01655587044489, 33.52905538555671]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "067",
        COUNTYNS: "01804514",
        AFFGEOID: "0500000US72067",
        GEOID: "72067",
        NAME: "Hormigueros",
        LSAD: "13",
        ALAND: 29384893,
        AWATER: 481
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.159733, 18.153226999440754],
            [-67.098382, 18.158549999440609],
            [-67.086359, 18.149828999440842],
            [-67.080751, 18.10698799944198],
            [-67.100492, 18.106394999441996],
            [-67.126157, 18.115142999441765],
            [-67.159733, 18.153226999440754]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "257",
        COUNTYNS: "01383914",
        AFFGEOID: "0500000US48257",
        GEOID: "48257",
        NAME: "Kaufman",
        LSAD: "06",
        ALAND: 2021962201,
        AWATER: 69763126
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.523008, 32.559623999143703],
            [-96.519261, 32.79602799914047],
            [-96.51897, 32.813615999140232],
            [-96.297376, 32.814184999140224],
            [-96.297322, 32.841722999139861],
            [-96.076801, 32.838488999139891],
            [-96.075899, 32.357504999146521],
            [-96.196231, 32.354761999146561],
            [-96.452138, 32.358630999146492],
            [-96.434514, 32.388729999146079],
            [-96.529987, 32.545281999143903],
            [-96.523008, 32.559623999143703]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "051",
        COUNTYNS: "00450354",
        AFFGEOID: "0500000US18051",
        GEOID: "18051",
        NAME: "Gibson",
        LSAD: "06",
        ALAND: 1262606254,
        AWATER: 30223915
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.968968, 38.237388999082562],
            [-87.908542, 38.268580999082324],
            [-87.831972, 38.307240999082033],
            [-87.779996, 38.370841999081549],
            [-87.7511059451205, 38.418849207536006],
            [-87.599317, 38.450720999080957],
            [-87.494671, 38.49569199908062],
            [-87.462892, 38.533518999080343],
            [-87.462486, 38.46560999908084],
            [-87.407583, 38.375500999081524],
            [-87.316219, 38.381473999081464],
            [-87.316893, 38.246076999082497],
            [-87.31703, 38.201752999082835],
            [-87.467284, 38.165402999083121],
            [-87.688374, 38.168418999083094],
            [-87.799677, 38.201943999082836],
            [-87.799169, 38.228579999082633],
            [-87.970200840803898, 38.230271057468421],
            [-87.968968, 38.237388999082562]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "035",
        COUNTYNS: "00659463",
        AFFGEOID: "0500000US27035",
        GEOID: "27035",
        NAME: "Crow Wing",
        LSAD: "06",
        ALAND: 2585785629,
        AWATER: 409661410
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.388922, 46.238165999057713],
            [-94.341679, 46.277704999057761],
            [-94.330936, 46.631207999058375],
            [-94.34291, 46.805528999058737],
            [-93.776024, 46.802890999058725],
            [-93.777905, 46.589670999058299],
            [-93.810407, 46.250947999057736],
            [-93.796281, 46.243198999057711],
            [-93.810802, 46.234294999057695],
            [-93.810362, 46.1565849990576],
            [-94.374592, 46.157092999057596],
            [-94.388922, 46.238165999057713]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "083",
        COUNTYNS: "00485006",
        AFFGEOID: "0500000US20083",
        GEOID: "20083",
        NAME: "Hodgeman",
        LSAD: "06",
        ALAND: 2227369151,
        AWATER: 826344
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.227137, 38.262236999082376],
            [-99.584207, 38.262178999082373],
            [-99.570469, 38.252575999082438],
            [-99.569998, 38.189331999082924],
            [-99.569533, 38.087371999083707],
            [-99.569879, 37.913015999085097],
            [-100.22709, 37.914017999085097],
            [-100.22661, 38.001014999084397],
            [-100.227137, 38.262236999082376]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "135",
        COUNTYNS: "01074080",
        AFFGEOID: "0500000US39135",
        GEOID: "39135",
        NAME: "Preble",
        LSAD: "06",
        ALAND: 1098738405,
        AWATER: 6065437
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.814129, 39.726555999072417],
            [-84.814128137932784, 39.726616510691713],
            [-84.811417106380489, 39.916913628651109],
            [-84.485367, 39.918490999071288],
            [-84.479213, 39.591023999073236],
            [-84.478927, 39.568789999073381],
            [-84.815705943255495, 39.567721663100777],
            [-84.814129, 39.726555999072417]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "057",
        COUNTYNS: "01685718",
        AFFGEOID: "0500000US13057",
        GEOID: "13057",
        NAME: "Cherokee",
        LSAD: "06",
        ALAND: 1090598900,
        AWATER: 34469349
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.644293, 34.355167999120596],
            [-84.653232, 34.41258999911993],
            [-84.58283, 34.412190999119922],
            [-84.58263, 34.381491999120286],
            [-84.424525, 34.387069999120229],
            [-84.400774, 34.37326599912037],
            [-84.257586, 34.380991999120297],
            [-84.258075, 34.335155999120836],
            [-84.258689, 34.23709399912201],
            [-84.258743, 34.185908999122631],
            [-84.352302, 34.176716999122746],
            [-84.418927, 34.073297999124001],
            [-84.515431, 34.078697999123939],
            [-84.571683, 34.076298999123964],
            [-84.624215, 34.081778999123891],
            [-84.659241, 34.078239999123952],
            [-84.644293, 34.355167999120596]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "211",
        COUNTYNS: "00346008",
        AFFGEOID: "0500000US13211",
        GEOID: "13211",
        NAME: "Morgan",
        LSAD: "06",
        ALAND: 899632962,
        AWATER: 18830382
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.677717, 33.571833999130263],
            [-83.680896, 33.596918999129947],
            [-83.526386, 33.79611899912743],
            [-83.505928, 33.817759999127155],
            [-83.479523, 33.802264999127352],
            [-83.406189, 33.698306999128668],
            [-83.34687, 33.600497999129892],
            [-83.269213, 33.533225999130757],
            [-83.279931, 33.48343799913139],
            [-83.533736, 33.434471999132022],
            [-83.682196, 33.526219999130838],
            [-83.677717, 33.571833999130263]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "001",
        COUNTYNS: "00835823",
        AFFGEOID: "0500000US31001",
        GEOID: "31001",
        NAME: "Adams",
        LSAD: "06",
        ALAND: 1458869099,
        AWATER: 2481410
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.724387, 40.689771999067183],
            [-98.72143, 40.698902999067137],
            [-98.282851, 40.698283999067144],
            [-98.278103, 40.698290999067147],
            [-98.27809, 40.350346999068911],
            [-98.723948, 40.350390999068907],
            [-98.724387, 40.689771999067183]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "205",
        COUNTYNS: "00758555",
        AFFGEOID: "0500000US29205",
        GEOID: "29205",
        NAME: "Shelby",
        LSAD: "06",
        ALAND: 1297230230,
        AWATER: 3764605
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.287882, 39.952489999071098],
            [-91.952726, 39.949425999071103],
            [-91.840353, 39.948234999071119],
            [-91.846743, 39.658512999072819],
            [-92.184986, 39.662931999072796],
            [-92.186691, 39.604081999073152],
            [-92.299316, 39.605343999073142],
            [-92.287882, 39.952489999071098]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "109",
        COUNTYNS: "00069173",
        AFFGEOID: "0500000US05109",
        GEOID: "05109",
        NAME: "Pike",
        LSAD: "06",
        ALAND: 1555599952,
        AWATER: 34989238
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.935371, 34.350096999120652],
            [-93.478646, 34.340850999120775],
            [-93.502208, 34.214105999122289],
            [-93.46169, 34.205750999122387],
            [-93.434165, 34.049738999124287],
            [-93.373406, 33.95706999912543],
            [-93.419093, 33.948793999125535],
            [-93.456709, 33.956836999125422],
            [-93.528263, 33.943022999125596],
            [-93.648131, 33.982966999125104],
            [-93.823076, 34.008892999124797],
            [-93.820722, 34.183801999122657],
            [-93.92583, 34.186495999122627],
            [-93.935371, 34.350096999120652]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "221",
        COUNTYNS: "01383896",
        AFFGEOID: "0500000US48221",
        GEOID: "48221",
        NAME: "Hood",
        LSAD: "06",
        ALAND: 1089567387,
        AWATER: 41838592
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.066836, 32.558821999143731],
            [-97.617066, 32.555483999143767],
            [-97.615286, 32.318617999147065],
            [-97.78276, 32.316492999147094],
            [-97.945625, 32.233467999148274],
            [-97.950265, 32.244262999148113],
            [-98.068545, 32.511625999144371],
            [-98.066836, 32.558821999143731]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "087",
        COUNTYNS: "00424245",
        AFFGEOID: "0500000US17087",
        GEOID: "17087",
        NAME: "Johnson",
        LSAD: "06",
        ALAND: 890741064,
        AWATER: 12811451
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.044787, 37.329845999089962],
            [-89.041401, 37.596575999087698],
            [-88.708546, 37.599276999087671],
            [-88.71065, 37.337089999089905],
            [-88.908291, 37.335726999089914],
            [-88.929129, 37.3026469990902],
            [-89.044648, 37.294342999090276],
            [-89.044787, 37.329845999089962]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "219",
        COUNTYNS: "00758562",
        AFFGEOID: "0500000US29219",
        GEOID: "29219",
        NAME: "Warren",
        LSAD: "06",
        ALAND: 1110070358,
        AWATER: 23748217
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.415832, 38.845299999078122],
            [-91.264927, 38.843832999078131],
            [-91.264287, 38.992761999077103],
            [-91.18839, 38.992140999077108],
            [-91.189348, 38.932150999077514],
            [-91.110187, 38.931900999077527],
            [-91.111251, 38.872708999077936],
            [-90.958536, 38.870864999077931],
            [-90.964461, 38.547544999080245],
            [-91.077324, 38.6093429990798],
            [-91.226547, 38.621566999079711],
            [-91.319022, 38.70836799907908],
            [-91.369192, 38.699323999079155],
            [-91.418637, 38.709777999079073],
            [-91.415832, 38.845299999078122]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "011",
        COUNTYNS: "00424207",
        AFFGEOID: "0500000US17011",
        GEOID: "17011",
        NAME: "Bureau",
        LSAD: "06",
        ALAND: 2250785990,
        AWATER: 11578416
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.862351, 41.584004999063275],
            [-89.631494, 41.584948999063279],
            [-89.166561, 41.585288999063266],
            [-89.163705, 41.310186999064371],
            [-89.334901, 41.300876999064414],
            [-89.356671, 41.233234999064699],
            [-89.466534, 41.233872999064694],
            [-89.466421, 41.148557999065062],
            [-89.638429, 41.148590999065064],
            [-89.638864, 41.233861999064693],
            [-89.857798, 41.234482999064703],
            [-89.862351, 41.584004999063275]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "075",
        COUNTYNS: "00835859",
        AFFGEOID: "0500000US31075",
        GEOID: "31075",
        NAME: "Grant",
        LSAD: "06",
        ALAND: 2010410702,
        AWATER: 18130867
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.06665, 42.009194999061741],
            [-102.008562, 42.008900999061751],
            [-102.006204, 42.096337999061447],
            [-101.426557, 42.092193999061465],
            [-101.42592, 41.743058999062661],
            [-101.985523, 41.742289999062685],
            [-102.067918, 41.742098999062677],
            [-102.06665, 42.009194999061741]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "187",
        COUNTYNS: "00516940",
        AFFGEOID: "0500000US21187",
        GEOID: "21187",
        NAME: "Owen",
        LSAD: "06",
        ALAND: 909364763,
        AWATER: 8129649
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.074583, 38.596837999079881],
            [-84.942418, 38.6504689990795],
            [-84.934536, 38.662129999079411],
            [-84.811845, 38.70230699907912],
            [-84.785788, 38.720458999078986],
            [-84.774322, 38.618593999079728],
            [-84.580539, 38.473038999080785],
            [-84.705988, 38.370101999081555],
            [-84.740594, 38.352414999081688],
            [-84.793412, 38.33866899908179],
            [-84.870482, 38.356750999081648],
            [-84.91223, 38.459843999080888],
            [-84.963265, 38.437357999081058],
            [-84.977166, 38.512988999080491],
            [-85.074583, 38.596837999079881]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "003",
        COUNTYNS: "01101789",
        AFFGEOID: "0500000US40003",
        GEOID: "40003",
        NAME: "Alfalfa",
        LSAD: "06",
        ALAND: 2244107192,
        AWATER: 38546675
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.544661973348894, 36.998524239493499],
            [-98.354073, 36.997960999092903],
            [-98.347148779744685, 36.9979692077425],
            [-98.111985183745787, 36.998247993728206],
            [-98.109393, 36.611542999096464],
            [-98.104286, 36.593577999096638],
            [-98.104427, 36.463104999097887],
            [-98.533617, 36.463229999097877],
            [-98.544661973348894, 36.998524239493499]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "005",
        COUNTYNS: "00424204",
        AFFGEOID: "0500000US17005",
        GEOID: "17005",
        NAME: "Bond",
        LSAD: "06",
        ALAND: 984918649,
        AWATER: 6426353
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.639265, 38.999128999077065],
            [-89.586088, 39.028245999076859],
            [-89.250447, 39.028144999076858],
            [-89.2574, 38.99915099907706],
            [-89.256708, 38.917172999077621],
            [-89.254237, 38.742018999078844],
            [-89.597321, 38.743235999078834],
            [-89.599593, 38.874529999077922],
            [-89.637831, 38.918352999077612],
            [-89.637049, 38.918363999077606],
            [-89.639265, 38.999128999077065]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "025",
        COUNTYNS: "00399408",
        AFFGEOID: "0500000US16025",
        GEOID: "16025",
        NAME: "Camas",
        LSAD: "06",
        ALAND: 2782253889,
        AWATER: 11548052
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-115.083071, 43.604398999057821],
            [-115.033834, 43.618635999057787],
            [-115.04234, 43.745581999057599],
            [-114.989258, 43.85856299905744],
            [-114.847781, 43.8163809990575],
            [-114.714648, 43.81466899905751],
            [-114.666202, 43.737961999057603],
            [-114.682355, 43.590348999057817],
            [-114.586743, 43.558825999057873],
            [-114.595698, 43.531234999057915],
            [-114.508233, 43.481511999057993],
            [-114.546546, 43.435444999058085],
            [-114.534488, 43.33338299905828],
            [-114.394606, 43.326466999058276],
            [-114.374962, 43.199639999058519],
            [-114.594605, 43.198344999058527],
            [-115.086155, 43.198063999058533],
            [-115.083071, 43.604398999057821]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "169",
        COUNTYNS: "01648581",
        AFFGEOID: "0500000US47169",
        GEOID: "47169",
        NAME: "Trousdale",
        LSAD: "06",
        ALAND: 296143587,
        AWATER: 5960710
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.268788, 36.452896999098002],
            [-86.228434, 36.48747199909765],
            [-86.168261, 36.444292999098074],
            [-85.978893, 36.426072999098238],
            [-85.977143, 36.40507599909845],
            [-86.0809, 36.355366999098941],
            [-86.136803, 36.295858999099515],
            [-86.20656, 36.342658999099058],
            [-86.264436, 36.299717999099478],
            [-86.283256, 36.347499999099021],
            [-86.268788, 36.452896999098002]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "177",
        COUNTYNS: "01785076",
        AFFGEOID: "0500000US17177",
        GEOID: "17177",
        NAME: "Stephenson",
        LSAD: "06",
        ALAND: 1462175613,
        AWATER: 1385929
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.926484, 42.505786999060206],
            [-89.83759488632559, 42.504910351851699],
            [-89.493216, 42.501513999060215],
            [-89.401416811040576, 42.50044193013391],
            [-89.397809, 42.30717099906078],
            [-89.396192, 42.201915999061107],
            [-89.688486, 42.19911199906111],
            [-89.898103, 42.196273999061113],
            [-89.91965, 42.196823999061124],
            [-89.927006876764011, 42.50579048483479],
            [-89.926484, 42.505786999060206]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "057",
        COUNTYNS: "01034233",
        AFFGEOID: "0500000US38057",
        GEOID: "38057",
        NAME: "Mercer",
        LSAD: "06",
        ALAND: 2701236315,
        AWATER: 180102277
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.20535, 47.574505999060712],
            [-102.036648, 47.572704999060704],
            [-101.813848, 47.496501999060477],
            [-101.707537, 47.538277999060604],
            [-101.641696, 47.528378999060578],
            [-101.428037, 47.561985999060674],
            [-101.370866, 47.518695999060554],
            [-101.440139, 47.477298999060416],
            [-101.362536, 47.367997999060115],
            [-101.345735, 47.291896999059901],
            [-101.257031, 47.264894999059841],
            [-101.257944, 47.241346999059772],
            [-101.764419, 47.241422999059779],
            [-101.764392, 46.981305999059124],
            [-102.096675, 46.981158999059133],
            [-102.144771, 47.010306999059203],
            [-102.144453, 47.328078999060004],
            [-102.205601, 47.32807099906001],
            [-102.20535, 47.574505999060712]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "067",
        COUNTYNS: "00974132",
        AFFGEOID: "0500000US36067",
        GEOID: "36067",
        NAME: "Onondaga",
        LSAD: "06",
        ALAND: 2016012328,
        AWATER: 70528316
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.479224, 43.227518999058475],
            [-76.258951, 43.237467999058453],
            [-76.198748, 43.2125679990585],
            [-76.21083, 43.268472999058396],
            [-76.07839, 43.199415999058523],
            [-75.993394, 43.183375999058569],
            [-75.975588, 43.091277999058754],
            [-75.917189, 43.085778999058768],
            [-75.896079, 42.790963999059443],
            [-76.274673, 42.771256999059496],
            [-76.356974, 42.849449999059303],
            [-76.450738, 42.845759999059311],
            [-76.491941, 43.004963999058937],
            [-76.479224, 43.227518999058475]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "193",
        COUNTYNS: "00465285",
        AFFGEOID: "0500000US19193",
        GEOID: "19193",
        NAME: "Woodbury",
        LSAD: "06",
        ALAND: 2260790978,
        AWATER: 12793719
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.49297, 42.517281999060174],
            [-96.476952, 42.556078999060055],
            [-96.480022478980004, 42.561324775620747],
            [-95.859949, 42.560619999060044],
            [-95.741611, 42.561284999060042],
            [-95.741821, 42.474864999060294],
            [-95.669265, 42.474644999060295],
            [-95.670822, 42.211407999061088],
            [-96.33721609470588, 42.214849505956671],
            [-96.336323, 42.218921999061045],
            [-96.336003, 42.264805999060904],
            [-96.351957192496002, 42.280894703604154],
            [-96.407998, 42.337407999060687],
            [-96.411808, 42.41089399906047],
            [-96.381307, 42.461693999060323],
            [-96.445508, 42.490629999060239],
            [-96.477454, 42.509588999060185],
            [-96.49297, 42.517281999060174]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "065",
        COUNTYNS: "01034205",
        AFFGEOID: "0500000US38065",
        GEOID: "38065",
        NAME: "Oliver",
        LSAD: "06",
        ALAND: 1871296135,
        AWATER: 22476236
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.764419, 47.241422999059779],
            [-101.257944, 47.241346999059772],
            [-101.257031, 47.264894999059841],
            [-101.034623, 47.286392999059899],
            [-100.977629, 47.24999199905978],
            [-100.965156, 47.156630999059544],
            [-100.880487, 47.019412999059213],
            [-100.935983, 46.982839999059131],
            [-101.764392, 46.981305999059124],
            [-101.764419, 47.241422999059779]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "075",
        COUNTYNS: "00516884",
        AFFGEOID: "0500000US21075",
        GEOID: "21075",
        NAME: "Fulton",
        LSAD: "06",
        ALAND: 533358346,
        AWATER: 64084949
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.544434, 36.574509999096819],
            [-89.479346, 36.566252999096896],
            [-89.407906, 36.562344999096943],
            [-89.378694, 36.622291999096376],
            [-89.327319777009009, 36.62394628786685],
            [-89.324658, 36.62403199909636],
            [-89.278935, 36.577698999096789],
            [-89.227319, 36.569374999096873],
            [-89.199136, 36.625648999096342],
            [-89.175649772346873, 36.651319261649597],
            [-89.147098, 36.64911699909613],
            [-89.026629, 36.592123999096664],
            [-88.940873, 36.591513999096655],
            [-88.90348, 36.576863999096794],
            [-88.834589022874297, 36.501980341356813],
            [-88.964471, 36.502190999097515],
            [-89.211409, 36.505629999097472],
            [-89.345194319538791, 36.501343207734429],
            [-89.417293, 36.499032999097544],
            [-89.539232, 36.497933999097562],
            [-89.571481, 36.53808699909716],
            [-89.544434, 36.574509999096819]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "159",
        COUNTYNS: "00465268",
        AFFGEOID: "0500000US19159",
        GEOID: "19159",
        NAME: "Ringgold",
        LSAD: "06",
        ALAND: 1386935176,
        AWATER: 8720332
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.470779, 40.899501999066196],
            [-94.014803, 40.897030999066203],
            [-94.015492, 40.574073706077357],
            [-94.091085, 40.572896999067765],
            [-94.232240710208288, 40.572014611194369],
            [-94.310724, 40.571523999067772],
            [-94.471207727123186, 40.570959457424777],
            [-94.470779, 40.899501999066196]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "083",
        COUNTYNS: "00516888",
        AFFGEOID: "0500000US21083",
        GEOID: "21083",
        NAME: "Graves",
        LSAD: "06",
        ALAND: 1429025286,
        AWATER: 13047565
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.819387, 36.749968999095174],
            [-88.813229, 36.773114999094958],
            [-88.812897, 36.946863999093367],
            [-88.484103, 36.942095999093411],
            [-88.485697, 36.75032799909517],
            [-88.489076738713194, 36.501283718173227],
            [-88.51192, 36.50145699909752],
            [-88.516358059979396, 36.501464197260823],
            [-88.816764157348089, 36.501951430910722],
            [-88.819387, 36.749968999095174]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "083",
        COUNTYNS: "00399334",
        AFFGEOID: "0500000US16083",
        GEOID: "16083",
        NAME: "Twin Falls",
        LSAD: "06",
        ALAND: 4977213745,
        AWATER: 18063174
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-115.037697, 42.768413999059504],
            [-115.037774, 42.91186399905915],
            [-114.960322, 42.914924999059146],
            [-114.901636, 42.859107999059276],
            [-114.933622, 42.766930999059504],
            [-114.857807, 42.755300999059521],
            [-114.812167, 42.664414999059765],
            [-114.616487, 42.649297999059812],
            [-114.465709, 42.599672999059941],
            [-114.244703, 42.559243999060037],
            [-114.13035, 42.494905999060222],
            [-114.045454, 42.528698999060133],
            [-114.000388, 42.526372999060143],
            [-114.067073, 42.514452999060175],
            [-114.069162, 42.417123999060465],
            [-114.285448, 42.417332999060456],
            [-114.281799467219997, 41.994213884819679],
            [-114.281855, 41.994213999061792],
            [-114.598267, 41.994510999061781],
            [-114.89921, 41.999908999061759],
            [-115.038109752433996, 41.998634114738259],
            [-115.037697, 42.768413999059504]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "175",
        COUNTYNS: "00465276",
        AFFGEOID: "0500000US19175",
        GEOID: "19175",
        NAME: "Union",
        LSAD: "06",
        ALAND: 1097235765,
        AWATER: 5575853
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.470603, 41.157565999065028],
            [-94.241637, 41.157133999065024],
            [-94.014189, 41.15676199906504],
            [-94.014803, 40.897030999066203],
            [-94.470779, 40.899501999066196],
            [-94.470603, 41.157565999065028]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "065",
        COUNTYNS: "01639749",
        AFFGEOID: "0500000US47065",
        GEOID: "47065",
        NAME: "Hamilton",
        LSAD: "06",
        ALAND: 1404756919,
        AWATER: 86766886
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.474338843134305, 34.983673325904419],
            [-85.365031, 35.07255399911233],
            [-85.387089, 35.147168999111493],
            [-85.246005, 35.319076999109619],
            [-85.225877, 35.354275999109234],
            [-85.168302, 35.455613999108145],
            [-85.135191, 35.458648999108114],
            [-85.102158, 35.428441999108429],
            [-85.016343, 35.409287999108635],
            [-85.031314, 35.379476999108959],
            [-84.946339, 35.287716999109954],
            [-85.026643, 35.13617099911162],
            [-85.008478, 35.09268999911211],
            [-84.961146, 35.015174999112972],
            [-84.976973806592298, 34.987219836468178],
            [-84.979854025231504, 34.987205613088875],
            [-85.045183, 34.986882999113284],
            [-85.265055021715796, 34.985077643855007],
            [-85.277556, 34.984974999113312],
            [-85.363919028931093, 34.983376562253824],
            [-85.384967, 34.982986999113336],
            [-85.474338843134305, 34.983673325904419]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "087",
        COUNTYNS: "01581104",
        AFFGEOID: "0500000US55087",
        GEOID: "55087",
        NAME: "Outagamie",
        LSAD: "06",
        ALAND: 1651321050,
        AWATER: 18205949
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.736802, 44.590604999056843],
            [-88.606083, 44.59051499905685],
            [-88.245204, 44.584745999056835],
            [-88.190465, 44.586798999056839],
            [-88.192611, 44.241946999057042],
            [-88.40407, 44.244101999057051],
            [-88.524791, 44.244027999057046],
            [-88.73977, 44.24330299905705],
            [-88.736802, 44.590604999056843]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "023",
        COUNTYNS: "01711058",
        AFFGEOID: "0500000US24023",
        GEOID: "24023",
        NAME: "Garrett",
        LSAD: "06",
        ALAND: 1681276216,
        AWATER: 22324651
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.484372, 39.344299999074792],
            [-79.482366, 39.531688999073602],
            [-79.476662, 39.721077999072449],
            [-79.392458405022396, 39.721439357712548],
            [-79.045576, 39.722927999072432],
            [-78.928416129888902, 39.722998586513341],
            [-79.066892, 39.480622999073915],
            [-79.067447408072397, 39.472808698202172],
            [-79.091329, 39.472406999073968],
            [-79.166497, 39.400887999074413],
            [-79.262392117176091, 39.326243827542299],
            [-79.283723, 39.30963999907501],
            [-79.35375, 39.278038999075214],
            [-79.424413, 39.22817099907553],
            [-79.486873, 39.205960999075678],
            [-79.484372, 39.344299999074792]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "159",
        COUNTYNS: "00424281",
        AFFGEOID: "0500000US17159",
        GEOID: "17159",
        NAME: "Richland",
        LSAD: "06",
        ALAND: 932444608,
        AWATER: 4863725
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.257783, 38.731138999078915],
            [-88.258608, 38.847520999078114],
            [-87.945923, 38.850107999078084],
            [-87.908113, 38.850106999078093],
            [-87.912286, 38.570103999080075],
            [-87.953898, 38.570093999080072],
            [-88.147814, 38.569085999080087],
            [-88.148044, 38.597987999079891],
            [-88.253851, 38.59950199907987],
            [-88.296989, 38.634189999079616],
            [-88.257783, 38.731138999078915]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "005",
        COUNTYNS: "01622945",
        AFFGEOID: "0500000US26005",
        GEOID: "26005",
        NAME: "Allegan",
        LSAD: "06",
        ALAND: 2137372873,
        AWATER: 2610825889
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.240642, 42.539999999060107],
            [-86.228694801002291, 42.62951185376108],
            [-86.226638, 42.644921999059825],
            [-86.208309, 42.762788999059524],
            [-86.208538502909676, 42.767543870975189],
            [-86.137046, 42.768341999059508],
            [-86.126702, 42.768401999059499],
            [-85.782498, 42.768199999059505],
            [-85.545564, 42.768138999059502],
            [-85.543191, 42.421432999060443],
            [-85.764697, 42.420714999060451],
            [-86.276994044531506, 42.419310314986745],
            [-86.240642, 42.539999999060107]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "129",
        COUNTYNS: "01026329",
        AFFGEOID: "0500000US37129",
        GEOID: "37129",
        NAME: "New Hanover",
        LSAD: "06",
        ALAND: 497789754,
        AWATER: 353951640
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.029922, 34.331767999120878],
            [-77.823102, 34.389286999120195],
            [-77.713513170458796, 34.29024718580817],
            [-77.764022, 34.24564099912191],
            [-77.829209, 34.162617999122908],
            [-77.885455075521094, 34.038240983977445],
            [-77.956605, 34.234490999122052],
            [-78.029922, 34.331767999120878]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "155",
        COUNTYNS: "00485041",
        AFFGEOID: "0500000US20155",
        GEOID: "20155",
        NAME: "Reno",
        LSAD: "06",
        ALAND: 3251326003,
        AWATER: 43423736
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.472794, 38.172756999083056],
            [-98.216032, 38.173497999083047],
            [-98.215924, 38.15874999908317],
            [-97.922136, 38.173712999083051],
            [-97.701841, 38.173813999083059],
            [-97.701969, 37.911324999085124],
            [-97.698692, 37.735055999086548],
            [-97.807823, 37.733854999086553],
            [-98.464804, 37.732674999086569],
            [-98.46482, 37.810205999085937],
            [-98.472257, 37.824496999085817],
            [-98.472794, 38.172756999083056]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "041",
        COUNTYNS: "00465625",
        AFFGEOID: "0500000US19041",
        GEOID: "19041",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 1469139466,
        AWATER: 13866649
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.388078, 43.255220999058423],
            [-94.913723, 43.255053999058418],
            [-94.91389, 42.909699999059157],
            [-95.388182, 42.909896999059157],
            [-95.388078, 43.255220999058423]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "159",
        COUNTYNS: "01008583",
        AFFGEOID: "0500000US37159",
        GEOID: "37159",
        NAME: "Rowan",
        LSAD: "06",
        ALAND: 1324722118,
        AWATER: 32235596
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.771582, 35.672162999105844],
            [-80.707826, 35.852928999103966],
            [-80.691809, 35.863507999103859],
            [-80.47182, 35.768355999104834],
            [-80.458884, 35.743030999105095],
            [-80.423226, 35.725863999105279],
            [-80.334509, 35.720470999105331],
            [-80.330602, 35.678798999105766],
            [-80.209509, 35.581950999106795],
            [-80.182559, 35.504150999107623],
            [-80.295421, 35.502919999107633],
            [-80.737379, 35.505803999107599],
            [-80.743617, 35.559548999107029],
            [-80.771582, 35.672162999105844]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "199",
        COUNTYNS: "00485060",
        AFFGEOID: "0500000US20199",
        GEOID: "20199",
        NAME: "Wallace",
        LSAD: "06",
        ALAND: 2366348295,
        AWATER: 140932
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.047200721701003, 39.133146708587354],
            [-101.478195, 39.134760999076143],
            [-101.484383, 38.700165999079132],
            [-101.567094, 38.699668999079151],
            [-102.045712903087008, 38.697565769157656],
            [-102.046571, 39.047037999076736],
            [-102.047200721701003, 39.133146708587354]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "085",
        COUNTYNS: "01383828",
        AFFGEOID: "0500000US48085",
        GEOID: "48085",
        NAME: "Collin",
        LSAD: "06",
        ALAND: 2178638821,
        AWATER: 116470919
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.841849, 33.086455999136568],
            [-96.837795, 33.262834999134242],
            [-96.83411, 33.405497999132393],
            [-96.384602, 33.397825999132486],
            [-96.384495, 33.341118999133229],
            [-96.295413, 33.35193599913309],
            [-96.297227, 32.981751999137977],
            [-96.466726, 32.981999999137962],
            [-96.516866, 32.982307999137944],
            [-96.613377, 32.98402099913794],
            [-96.803375, 32.987002999137907],
            [-96.843979, 32.98755399913788],
            [-96.841849, 33.086455999136568]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "075",
        COUNTYNS: "01383823",
        AFFGEOID: "0500000US48075",
        GEOID: "48075",
        NAME: "Childress",
        LSAD: "06",
        ALAND: 1803680231,
        AWATER: 44835076
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.415895, 34.747524999116003],
            [-100.000382194816012, 34.746360551677618],
            [-100.000381, 34.56050899911817],
            [-99.997629328163001, 34.561136623678266],
            [-99.99772, 34.311828999121104],
            [-100.417783, 34.313523999121095],
            [-100.415895, 34.747524999116003]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "143",
        COUNTYNS: "01383857",
        AFFGEOID: "0500000US48143",
        GEOID: "48143",
        NAME: "Erath",
        LSAD: "06",
        ALAND: 2805191451,
        AWATER: 17394954
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.474685, 32.299083999147342],
            [-98.475177, 32.513038999144356],
            [-98.068545, 32.511625999144371],
            [-97.950265, 32.244262999148113],
            [-97.945625, 32.233467999148274],
            [-97.864864, 32.087327999150354],
            [-98.005462, 32.01789699915134],
            [-98.208386, 31.917510999152793],
            [-98.250318, 31.979002999151906],
            [-98.271184, 31.969948999152027],
            [-98.327499, 31.941260999152448],
            [-98.551158, 32.261435999147864],
            [-98.474685, 32.299083999147342]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "031",
        COUNTYNS: "00484985",
        AFFGEOID: "0500000US20031",
        GEOID: "20031",
        NAME: "Coffey",
        LSAD: "06",
        ALAND: 1623624044,
        AWATER: 70470775
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.958862, 38.17093899908307],
            [-95.95861, 38.246301999082498],
            [-95.949994, 38.259705999082392],
            [-95.950282, 38.434104999081079],
            [-95.508267, 38.433932999081087],
            [-95.508328, 38.390277999081405],
            [-95.51897, 38.038229999084109],
            [-95.959046, 38.040194999084093],
            [-95.958862, 38.17093899908307]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "073",
        COUNTYNS: "00465225",
        AFFGEOID: "0500000US19073",
        GEOID: "19073",
        NAME: "Greene",
        LSAD: "06",
        ALAND: 1475260906,
        AWATER: 4255701
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.628806, 42.209395999061073],
            [-94.397526, 42.209160999061083],
            [-94.164704, 42.209919999061071],
            [-94.164138, 41.863243999062227],
            [-94.28034, 41.862998999062235],
            [-94.628724, 41.862762999062241],
            [-94.628806, 42.209395999061073]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "207",
        COUNTYNS: "00485064",
        AFFGEOID: "0500000US20207",
        GEOID: "20207",
        NAME: "Woodson",
        LSAD: "06",
        ALAND: 1289347405,
        AWATER: 19251486
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.959046, 38.040194999084093],
            [-95.51897, 38.038229999084109],
            [-95.519308, 37.820258999085851],
            [-95.525499, 37.73275899908657],
            [-95.960876, 37.734303999086556],
            [-95.959046, 38.040194999084093]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "189",
        COUNTYNS: "00465283",
        AFFGEOID: "0500000US19189",
        GEOID: "19189",
        NAME: "Winnebago",
        LSAD: "06",
        ALAND: 1037261944,
        AWATER: 3182052
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.97076, 43.499604999057965],
            [-93.648533031173599, 43.499535488733073],
            [-93.576728, 43.499519999057981],
            [-93.497351325220393, 43.499530723569677],
            [-93.497635, 43.255467999058425],
            [-93.970415, 43.255357999058418],
            [-93.970761558528196, 43.499605002265362],
            [-93.97076, 43.499604999057965]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "057",
        COUNTYNS: "01622971",
        AFFGEOID: "0500000US26057",
        GEOID: "26057",
        NAME: "Gratiot",
        LSAD: "06",
        ALAND: 1472084943,
        AWATER: 8383160
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.845962, 43.466157999058041],
            [-84.60754, 43.466005999058027],
            [-84.369876, 43.466043999058023],
            [-84.367891, 43.128451999058676],
            [-84.36776, 43.117941999058694],
            [-84.836889, 43.118850999058701],
            [-84.845962, 43.466157999058041]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "111",
        COUNTYNS: "00485020",
        AFFGEOID: "0500000US20111",
        GEOID: "20111",
        NAME: "Lyon",
        LSAD: "06",
        ALAND: 2194957493,
        AWATER: 20562422
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.35378, 38.521656999080435],
            [-96.352613, 38.739020999078868],
            [-95.945924, 38.739111999078865],
            [-95.949654, 38.695384999079181],
            [-95.950282, 38.434104999081079],
            [-95.949994, 38.259705999082392],
            [-95.95861, 38.246301999082498],
            [-95.958862, 38.17093899908307],
            [-96.357277, 38.172659999083059],
            [-96.35378, 38.521656999080435]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "195",
        COUNTYNS: "00465286",
        AFFGEOID: "0500000US19195",
        GEOID: "19195",
        NAME: "Worth",
        LSAD: "06",
        ALAND: 1036314926,
        AWATER: 4709127
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.497351325220393, 43.499530723569677],
            [-93.228861, 43.499566999057976],
            [-93.049192007816487, 43.499557479082576],
            [-93.024347900541883, 43.499556162687981],
            [-93.024143, 43.255537999058419],
            [-93.497635, 43.255467999058425],
            [-93.497351325220393, 43.499530723569677]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "133",
        COUNTYNS: "00485030",
        AFFGEOID: "0500000US20133",
        GEOID: "20133",
        NAME: "Neosho",
        LSAD: "06",
        ALAND: 1480106489,
        AWATER: 16374155
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.525499, 37.73275899908657],
            [-95.124987, 37.733923999086556],
            [-95.088082, 37.732475999086567],
            [-95.088012, 37.674518999087034],
            [-95.088189, 37.383836999089496],
            [-95.52113, 37.383989999089501],
            [-95.52556, 37.3839789990895],
            [-95.525499, 37.73275899908657]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "123",
        COUNTYNS: "01383844",
        AFFGEOID: "0500000US48123",
        GEOID: "48123",
        NAME: "DeWitt",
        LSAD: "06",
        ALAND: 2354237945,
        AWATER: 3919919
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.613132, 29.109764999197477],
            [-97.335207, 29.315048999193937],
            [-97.240108, 29.384487999192757],
            [-96.976378, 29.104045999197581],
            [-97.305916, 28.864036999201765],
            [-97.41734, 28.925227999200686],
            [-97.574639, 28.813299999202655],
            [-97.75511, 29.007112999199254],
            [-97.613132, 29.109764999197477]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "143",
        COUNTYNS: "00516918",
        AFFGEOID: "0500000US21143",
        GEOID: "21143",
        NAME: "Lyon",
        LSAD: "06",
        ALAND: 553844796,
        AWATER: 110561084
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.209221, 36.999975999092875],
            [-88.231145, 37.081574999092155],
            [-88.193519, 37.147255999091577],
            [-88.184188, 37.12618899909176],
            [-88.095781, 37.179683999091282],
            [-88.042142, 37.174755999091325],
            [-87.876996, 36.960115999093247],
            [-88.002697, 36.910167999093701],
            [-88.157347, 36.867182999094091],
            [-88.189389, 36.935548999093477],
            [-88.240371, 36.981910999093039],
            [-88.209221, 36.999975999092875]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "037",
        COUNTYNS: "01008544",
        AFFGEOID: "0500000US37037",
        GEOID: "37037",
        NAME: "Chatham",
        LSAD: "06",
        ALAND: 1765011149,
        AWATER: 71264075
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.542428, 35.843302999104075],
            [-79.237295, 35.844246999104058],
            [-79.249538, 35.876809999103727],
            [-79.082036, 35.860775999103893],
            [-79.016305, 35.863209999103859],
            [-78.906097, 35.86791799910381],
            [-78.909425, 35.842936999104069],
            [-78.936271, 35.771897999104809],
            [-78.995059, 35.610134999106492],
            [-78.914734, 35.583671999106784],
            [-78.96964, 35.521668999107426],
            [-79.117096, 35.628163999106306],
            [-79.244663, 35.569115999106934],
            [-79.350073, 35.518001999107469],
            [-79.555804, 35.51503899910751],
            [-79.542428, 35.843302999104075]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "165",
        COUNTYNS: "00516929",
        AFFGEOID: "0500000US21165",
        GEOID: "21165",
        NAME: "Menifee",
        LSAD: "06",
        ALAND: 527283187,
        AWATER: 6084693
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.775168, 37.988662999084497],
            [-83.75943, 37.998730999084422],
            [-83.659293, 38.028260999084189],
            [-83.580859, 38.011567999084313],
            [-83.574989, 38.058882999083949],
            [-83.498846, 38.050619999084006],
            [-83.463408, 38.016974999084276],
            [-83.433942, 38.033973999084147],
            [-83.444343, 37.942467999084876],
            [-83.504389, 37.870241999085451],
            [-83.49508, 37.861905999085508],
            [-83.55141, 37.821614999085845],
            [-83.63106, 37.825371999085824],
            [-83.66296, 37.834734999085732],
            [-83.768783, 37.918370999085056],
            [-83.775168, 37.988662999084497]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "085",
        COUNTYNS: "01622985",
        AFFGEOID: "0500000US26085",
        GEOID: "26085",
        NAME: "Lake",
        LSAD: "06",
        ALAND: 1470022082,
        AWATER: 17522231
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.043507, 44.167087999057117],
            [-85.821186, 44.164196999057118],
            [-85.564554, 44.164860999057119],
            [-85.562433, 43.815482999057508],
            [-86.037884, 43.815610999057505],
            [-86.040106, 43.989670999057296],
            [-86.043507, 44.167087999057117]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "045",
        COUNTYNS: "00066852",
        AFFGEOID: "0500000US05045",
        GEOID: "05045",
        NAME: "Faulkner",
        LSAD: "06",
        ALAND: 1678176463,
        AWATER: 41765990
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.554685, 35.113276999111875],
            [-92.521445, 35.114696999111864],
            [-92.528074, 35.230558999110585],
            [-92.481469, 35.368391999109086],
            [-92.252759, 35.363717999109134],
            [-92.111463, 35.36272499910914],
            [-92.119719, 35.066492999112398],
            [-92.121336, 35.012209999112997],
            [-92.227541, 35.014483999112976],
            [-92.229893, 34.927557999113951],
            [-92.419276, 34.931674999113902],
            [-92.436108, 34.842217999114915],
            [-92.483512, 34.950936999113686],
            [-92.544341, 34.953318999113669],
            [-92.597762, 34.995826999113191],
            [-92.554685, 35.113276999111875]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "027",
        COUNTYNS: "01008541",
        AFFGEOID: "0500000US37027",
        GEOID: "37027",
        NAME: "Caldwell",
        LSAD: "06",
        ALAND: 1222192511,
        AWATER: 7038401
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.810516, 36.111510999101341],
            [-81.675149, 36.122529999101225],
            [-81.545488, 36.117474999101269],
            [-81.397817, 36.066060999101794],
            [-81.328324, 35.9965829991025],
            [-81.343993, 35.824162999104267],
            [-81.334272, 35.796280999104553],
            [-81.353514, 35.776267999104753],
            [-81.363796, 35.76780199910484],
            [-81.555994, 35.777537999104744],
            [-81.709749, 35.875002999103728],
            [-81.807162, 35.961954999102844],
            [-81.735673, 36.067583999101778],
            [-81.810516, 36.111510999101341]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "051",
        COUNTYNS: "00066855",
        AFFGEOID: "0500000US05051",
        GEOID: "05051",
        NAME: "Garland",
        LSAD: "06",
        ALAND: 1755290919,
        AWATER: 147383373
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.402189, 34.685192999116722],
            [-93.394289, 34.743392999116061],
            [-93.394456, 34.772354999115727],
            [-93.287785, 34.773192999115714],
            [-93.075178, 34.771891999115724],
            [-92.96925, 34.772265999115731],
            [-92.979902, 34.637221999117287],
            [-92.875456, 34.635123999117305],
            [-92.808028, 34.59040599911782],
            [-92.793197, 34.503605999118832],
            [-92.915079, 34.50611399911881],
            [-92.948071, 34.38925499912019],
            [-93.407227, 34.3968529991201],
            [-93.402189, 34.685192999116722]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "053",
        COUNTYNS: "00066856",
        AFFGEOID: "0500000US05053",
        GEOID: "05053",
        NAME: "Grant",
        LSAD: "06",
        ALAND: 1636384394,
        AWATER: 2896901
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.668711, 34.4142369991199],
            [-92.589418, 34.45564099911941],
            [-92.40468, 34.451915999119443],
            [-92.403557, 34.495794999118928],
            [-92.245818, 34.493505999118959],
            [-92.208041, 34.492764999118975],
            [-92.212906, 34.230399999122092],
            [-92.233376, 34.062311999124134],
            [-92.336765, 34.059632999124162],
            [-92.405883, 34.11553499912349],
            [-92.493672, 34.144233999123138],
            [-92.678383, 34.14962199912307],
            [-92.668711, 34.4142369991199]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "083",
        COUNTYNS: "00974140",
        AFFGEOID: "0500000US36083",
        GEOID: "36083",
        NAME: "Rensselaer",
        LSAD: "06",
        ALAND: 1689815931,
        AWATER: 33569457
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.773161, 42.509376999060187],
            [-73.761265, 42.610378999059904],
            [-73.676762, 42.783276999059467],
            [-73.672355, 42.795790999059435],
            [-73.683074, 42.903775999059171],
            [-73.660433, 42.93253399905911],
            [-73.635463, 42.941289999059087],
            [-73.430623, 42.958649999059041],
            [-73.273832807021009, 42.943631746585581],
            [-73.278673, 42.833409999059349],
            [-73.290944, 42.801919999059422],
            [-73.264957, 42.745939999059559],
            [-73.307004076180576, 42.632653450471359],
            [-73.352524954944201, 42.510007509010784],
            [-73.783721, 42.46423099906032],
            [-73.773161, 42.509376999060187]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "121",
        COUNTYNS: "01213690",
        AFFGEOID: "0500000US42121",
        GEOID: "42121",
        NAME: "Venango",
        LSAD: "06",
        ALAND: 1746388722,
        AWATER: 22148538
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.999779, 41.490048999063653],
            [-79.828876, 41.622056999063126],
            [-79.688082, 41.617362999063147],
            [-79.644117, 41.617205999063138],
            [-79.612873, 41.623857999063127],
            [-79.51207, 41.624558999063112],
            [-79.512276, 41.465239999063748],
            [-79.478163, 41.440133999063839],
            [-79.477822, 41.38647899906406],
            [-79.47814, 41.335707999064262],
            [-79.604161, 41.321477999064335],
            [-79.694984, 41.172864999064956],
            [-79.999779, 41.171860999064968],
            [-79.999779, 41.490048999063653]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "193",
        COUNTYNS: "01383882",
        AFFGEOID: "0500000US48193",
        GEOID: "48193",
        NAME: "Hamilton",
        LSAD: "06",
        ALAND: 2165008743,
        AWATER: 1238628
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.463736, 31.683988999156202],
            [-98.156568, 31.841712999153891],
            [-98.208386, 31.917510999152793],
            [-98.005462, 32.01789699915134],
            [-97.766431, 31.672460999156375],
            [-98.180006, 31.463716999159473],
            [-98.27107, 31.416397999160179],
            [-98.463736, 31.683988999156202]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "067",
        COUNTYNS: "01034212",
        AFFGEOID: "0500000US38067",
        GEOID: "38067",
        NAME: "Pembina",
        LSAD: "06",
        ALAND: 2897408189,
        AWATER: 7010908
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.950205011515592, 49.000515118004571],
            [-97.77575, 49.00057399906617],
            [-97.229039, 49.000686999066161],
            [-97.227854, 48.945863999065907],
            [-97.187362, 48.867597999065566],
            [-97.152588, 48.772601999065131],
            [-97.121253, 48.713592999064879],
            [-97.100009, 48.667925999064678],
            [-97.142915, 48.583732999064331],
            [-97.147723568300904, 48.543891682714758],
            [-97.148103466133591, 48.540744045250854],
            [-97.927154, 48.543123999064164],
            [-97.949577, 48.717692999064894],
            [-97.950205011515592, 49.000515118004571]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "520",
        COUNTYNS: "01498416",
        AFFGEOID: "0500000US51520",
        GEOID: "51520",
        NAME: "Bristol",
        LSAD: "25",
        ALAND: 33420181,
        AWATER: 322409
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.179632, 36.626797999096333],
            [-82.114373, 36.638417999096227],
            [-82.131621, 36.677315999095853],
            [-82.102713, 36.646861999096146],
            [-82.145573270514504, 36.594560329930751],
            [-82.173982, 36.594606999096627],
            [-82.243385005200196, 36.594876186762036],
            [-82.179632, 36.626797999096333]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "053",
        COUNTYNS: "00835848",
        AFFGEOID: "0500000US31053",
        GEOID: "31053",
        NAME: "Dodge",
        LSAD: "06",
        ALAND: 1370405061,
        AWATER: 35956200
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.905922, 41.742762999062677],
            [-96.555172, 41.742017999062682],
            [-96.444217, 41.741774999062685],
            [-96.444785, 41.683632999062901],
            [-96.434792, 41.494927999063627],
            [-96.329012, 41.393135999064036],
            [-96.47072, 41.393238999064039],
            [-96.568677, 41.432985999063867],
            [-96.905862, 41.45338899906379],
            [-96.90591, 41.456435999063778],
            [-96.905922, 41.742762999062677]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "027",
        COUNTYNS: "00835835",
        AFFGEOID: "0500000US31027",
        GEOID: "31027",
        NAME: "Cedar",
        LSAD: "06",
        ALAND: 1917219988,
        AWATER: 14513825
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.484916876342012, 42.850003213927209],
            [-97.452177, 42.846047999059309],
            [-97.417066, 42.865917999059256],
            [-97.341181, 42.855881999059299],
            [-97.302075, 42.865659999059261],
            [-97.237868, 42.853138999059297],
            [-97.213957, 42.820142999059371],
            [-97.165070392373806, 42.791616640527145],
            [-97.131331, 42.771928999059497],
            [-97.02485, 42.762429999059513],
            [-97.015631112747201, 42.756525461547717],
            [-97.017789, 42.351346999060638],
            [-97.367724, 42.351860999060648],
            [-97.36759, 42.438774999060385],
            [-97.485298, 42.438577999060392],
            [-97.484916876342012, 42.850003213927209]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "177",
        COUNTYNS: "00758543",
        AFFGEOID: "0500000US29177",
        GEOID: "29177",
        NAME: "Ray",
        LSAD: "06",
        ALAND: 1473200436,
        AWATER: 13287170
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.210642, 39.454681999074097],
            [-94.208423, 39.52719999907363],
            [-93.759183, 39.524557999073636],
            [-93.758463, 39.207020999075674],
            [-93.840147, 39.215466999075609],
            [-93.988892, 39.152785999076031],
            [-93.980088, 39.205873999075692],
            [-94.024206, 39.154536999076015],
            [-94.104823, 39.143506999076088],
            [-94.173519, 39.213828999075623],
            [-94.212515, 39.20708599907568],
            [-94.210642, 39.454681999074097]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "085",
        COUNTYNS: "01008560",
        AFFGEOID: "0500000US37085",
        GEOID: "37085",
        NAME: "Harnett",
        LSAD: "06",
        ALAND: 1541116844,
        AWATER: 16361414
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.183556, 35.307160999109747],
            [-78.96964, 35.521668999107426],
            [-78.914734, 35.583671999106784],
            [-78.708345, 35.519295999107456],
            [-78.529851, 35.334934999109443],
            [-78.538349, 35.316409999109638],
            [-78.583451, 35.296778999109868],
            [-78.617127, 35.245577999110417],
            [-78.794564, 35.266046999110188],
            [-78.880408, 35.230439999110573],
            [-79.095808, 35.192067999110996],
            [-79.223021, 35.268132999110172],
            [-79.183556, 35.307160999109747]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "105",
        COUNTYNS: "01008567",
        AFFGEOID: "0500000US37105",
        GEOID: "37105",
        NAME: "Lee",
        LSAD: "06",
        ALAND: 660580685,
        AWATER: 10752017
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.350073, 35.518001999107469],
            [-79.244663, 35.569115999106934],
            [-79.117096, 35.628163999106306],
            [-78.96964, 35.521668999107426],
            [-79.183556, 35.307160999109747],
            [-79.273565, 35.35336899910925],
            [-79.359765, 35.46967499910798],
            [-79.350073, 35.518001999107469]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "055",
        COUNTYNS: "01581087",
        AFFGEOID: "0500000US55055",
        GEOID: "55055",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 1441198727,
        AWATER: 68095317
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.008832, 43.197723999058539],
            [-88.535843, 43.196047999058528],
            [-88.541579, 43.019386999058895],
            [-88.541535, 42.842995999059326],
            [-88.69812, 42.842633999059316],
            [-88.777076, 42.842693999059328],
            [-88.918672, 42.846120999059316],
            [-89.013582, 42.847629999059308],
            [-89.008832, 43.197723999058539]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "181",
        COUNTYNS: "00516937",
        AFFGEOID: "0500000US21181",
        GEOID: "21181",
        NAME: "Nicholas",
        LSAD: "06",
        ALAND: 505465376,
        AWATER: 4302413
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.1021, 38.45937899908089],
            [-83.999227, 38.422285999081168],
            [-83.980068, 38.43944799908104],
            [-83.916131, 38.36015199908163],
            [-83.839597, 38.33836799908179],
            [-83.848355, 38.296948999082105],
            [-83.977924, 38.19211799908291],
            [-83.969281, 38.209329999082783],
            [-84.095324, 38.259013999082391],
            [-84.19405, 38.371749999081558],
            [-84.1021, 38.45937899908089]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "043",
        COUNTYNS: "00659467",
        AFFGEOID: "0500000US27043",
        GEOID: "27043",
        NAME: "Faribault",
        LSAD: "06",
        ALAND: 1845301801,
        AWATER: 24422161
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.247123, 43.847945999057472],
            [-93.767761, 43.847955999057461],
            [-93.64829, 43.848138999057454],
            [-93.648533031173599, 43.499535488733073],
            [-93.97076, 43.499604999057965],
            [-93.970761558528196, 43.499605002265362],
            [-94.247967441555204, 43.500175475765559],
            [-94.247123, 43.847945999057472]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "153",
        COUNTYNS: "01008581",
        AFFGEOID: "0500000US37153",
        GEOID: "37153",
        NAME: "Richmond",
        LSAD: "06",
        ALAND: 1226882608,
        AWATER: 15537990
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.075365, 35.143080999111532],
            [-79.851268, 35.183854999111105],
            [-79.614304, 35.163678999111319],
            [-79.575931, 35.069903999112356],
            [-79.458751, 35.043638999112659],
            [-79.573423, 34.992209999113236],
            [-79.561856, 34.910349999114146],
            [-79.614045, 34.815308999115224],
            [-79.692946383709298, 34.804962306038547],
            [-79.924684253136405, 34.807829018866819],
            [-79.849536, 34.900005999114271],
            [-79.877197, 35.035309999112748],
            [-79.923972, 35.115429999111853],
            [-79.992941, 35.08558999911218],
            [-80.075365, 35.143080999111532]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "219",
        COUNTYNS: "00516956",
        AFFGEOID: "0500000US21219",
        GEOID: "21219",
        NAME: "Todd",
        LSAD: "06",
        ALAND: 969941707,
        AWATER: 6629026
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.259371, 37.072400999092238],
            [-87.250256, 37.040556999092523],
            [-87.053164, 37.06101899909234],
            [-87.060826344392893, 36.644770883947764],
            [-87.115003556688407, 36.644142043747372],
            [-87.335979050080795, 36.641577159589289],
            [-87.259371, 37.072400999092238]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "051",
        COUNTYNS: "00424227",
        AFFGEOID: "0500000US17051",
        GEOID: "17051",
        NAME: "Fayette",
        LSAD: "06",
        ALAND: 1855687602,
        AWATER: 22942188
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.2574, 38.99915099907706],
            [-89.250447, 39.028144999076858],
            [-89.250513, 39.217511999075597],
            [-89.140076, 39.217906999075595],
            [-89.117534, 39.217233999075603],
            [-88.805325, 39.216262999075603],
            [-88.806789, 38.911654999077655],
            [-88.693531, 38.914616999077644],
            [-88.695165, 38.826298999078261],
            [-89.138138, 38.824243999078277],
            [-89.138393, 38.736330999078888],
            [-89.254237, 38.742018999078844],
            [-89.256708, 38.917172999077621],
            [-89.2574, 38.99915099907706]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "053",
        COUNTYNS: "00424228",
        AFFGEOID: "0500000US17053",
        GEOID: "17053",
        NAME: "Ford",
        LSAD: "06",
        ALAND: 1257719718,
        AWATER: 1640286
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.459475, 40.617344999067548],
            [-88.234949, 40.618165999067536],
            [-88.2473, 40.994559999065757],
            [-88.131938, 40.997838999065735],
            [-88.118191, 40.516981999068058],
            [-88.117905, 40.488085999068183],
            [-87.935309, 40.485922999068208],
            [-87.932858, 40.399400999068654],
            [-88.346987, 40.398650999068664],
            [-88.459957, 40.398849999068645],
            [-88.459475, 40.617344999067548]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "233",
        COUNTYNS: "00516963",
        AFFGEOID: "0500000US21233",
        GEOID: "21233",
        NAME: "Webster",
        LSAD: "06",
        ALAND: 859730022,
        AWATER: 9613810
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.932952, 37.480051999088687],
            [-87.734545, 37.638375999087351],
            [-87.495331, 37.647546999087268],
            [-87.482702, 37.600913999087652],
            [-87.375145, 37.569987999087907],
            [-87.497112, 37.487801999088596],
            [-87.593575, 37.461962999088833],
            [-87.813413, 37.350644999089781],
            [-87.801046, 37.379444999089543],
            [-87.847694, 37.420700999089185],
            [-87.929999, 37.40916699908928],
            [-87.932952, 37.480051999088687]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "129",
        COUNTYNS: "01383850",
        AFFGEOID: "0500000US48129",
        GEOID: "48129",
        NAME: "Donley",
        LSAD: "06",
        ALAND: 2400623972,
        AWATER: 15954005
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.086281, 35.182139999111108],
            [-100.538978, 35.18314399911111],
            [-100.540703, 34.747722999116007],
            [-100.944939, 34.748280999115991],
            [-101.090749, 34.748245999116001],
            [-101.086281, 35.182139999111108]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "057",
        COUNTYNS: "01008548",
        AFFGEOID: "0500000US37057",
        GEOID: "37057",
        NAME: "Davidson",
        LSAD: "06",
        ALAND: 1432711780,
        AWATER: 37452228
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.487586, 35.832909999104174],
            [-80.398823, 35.841346999104083],
            [-80.370009, 35.885746999103617],
            [-80.394307, 35.97276199910273],
            [-80.213842, 35.996698999102499],
            [-80.21373, 36.026807999102189],
            [-80.043238, 36.010757999102353],
            [-80.046869, 35.920692999103274],
            [-80.066842, 35.505660999107597],
            [-80.182559, 35.504150999107623],
            [-80.209509, 35.581950999106795],
            [-80.330602, 35.678798999105766],
            [-80.334509, 35.720470999105331],
            [-80.423226, 35.725863999105279],
            [-80.458884, 35.743030999105095],
            [-80.487586, 35.832909999104174]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "013",
        COUNTYNS: "01265785",
        AFFGEOID: "0500000US46013",
        GEOID: "46013",
        NAME: "Brown",
        LSAD: "06",
        ALAND: 4436728163,
        AWATER: 46414610
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.7243744138881, 45.93867473231883],
            [-98.625379, 45.938227999057311],
            [-98.414518, 45.936503999057315],
            [-98.070515, 45.936179999057323],
            [-98.008101798277778, 45.936012628106624],
            [-97.978777809623381, 45.935933991156027],
            [-97.979592, 45.588482999056986],
            [-97.981457, 45.240414999056824],
            [-98.717759, 45.24373199905682],
            [-98.722481, 45.24376399905681],
            [-98.722247, 45.562647999056963],
            [-98.725002, 45.591251999056993],
            [-98.7243744138881, 45.93867473231883]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "001",
        COUNTYNS: "01245666",
        AFFGEOID: "0500000US45001",
        GEOID: "45001",
        NAME: "Abbeville",
        LSAD: "06",
        ALAND: 1270337025,
        AWATER: 53126680
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.735107988941593, 34.212614811365206],
            [-82.313996, 34.484001999119073],
            [-82.310314, 34.466655999119276],
            [-82.246337, 34.409675999119955],
            [-82.334234, 34.342629999120746],
            [-82.247747, 34.219618999122233],
            [-82.267184, 34.108359999123579],
            [-82.326947, 34.064119999124109],
            [-82.530567, 34.071924999124022],
            [-82.595025792607288, 34.013517808334335],
            [-82.642797, 34.081311999123891],
            [-82.715373, 34.148164999123097],
            [-82.735107988941593, 34.212614811365206]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "125",
        COUNTYNS: "01265770",
        AFFGEOID: "0500000US46125",
        GEOID: "46125",
        NAME: "Turner",
        LSAD: "06",
        ALAND: 1598188427,
        AWATER: 1636386
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.40145, 43.499782999057977],
            [-97.129478, 43.499682999057967],
            [-96.924841, 43.500006999057966],
            [-96.924142, 43.083732999058775],
            [-97.160543, 43.083144999058774],
            [-97.160544, 43.169977999058581],
            [-97.399191, 43.169418999058585],
            [-97.40145, 43.499782999057977]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "005",
        COUNTYNS: "01266986",
        AFFGEOID: "0500000US46005",
        GEOID: "46005",
        NAME: "Beadle",
        LSAD: "06",
        ALAND: 3260045596,
        AWATER: 15805388
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.705762, 44.634158999056815],
            [-97.977791, 44.631601999056826],
            [-97.856226, 44.631177999056824],
            [-97.853028, 44.544397999056862],
            [-97.85366, 44.195232999057083],
            [-98.332042, 44.196619999057077],
            [-98.700453, 44.196726999057084],
            [-98.705762, 44.634158999056815]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "033",
        COUNTYNS: "00516863",
        AFFGEOID: "0500000US21033",
        GEOID: "21033",
        NAME: "Caldwell",
        LSAD: "06",
        ALAND: 893006262,
        AWATER: 8700648
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.089493, 37.222065999090916],
            [-87.801046, 37.379444999089543],
            [-87.813413, 37.350644999089781],
            [-87.850706, 37.321642999090038],
            [-87.805029, 37.241082999090743],
            [-87.680617, 37.149238999091558],
            [-87.710912, 37.02160699909269],
            [-87.733368, 37.002102999092862],
            [-87.858088, 36.950713999093324],
            [-87.876996, 36.960115999093247],
            [-88.042142, 37.174755999091325],
            [-88.095781, 37.179683999091282],
            [-88.089493, 37.222065999090916]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "103",
        COUNTYNS: "01266995",
        AFFGEOID: "0500000US46103",
        GEOID: "46103",
        NAME: "Pennington",
        LSAD: "06",
        ALAND: 7191808178,
        AWATER: 19906989
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.055488, 43.853476999057463],
            [-104.055416190705003, 44.14108120584055],
            [-103.452453, 44.140771999057144],
            [-102.388045, 44.140182999057146],
            [-102.393522, 44.202965999057085],
            [-102.341635, 44.348771999056964],
            [-102.300002, 44.37498599905696],
            [-102.282876, 44.448625999056908],
            [-102.189438, 44.427193999056925],
            [-102.112535, 44.435885999056914],
            [-102.001068, 44.510925999056859],
            [-102.007336, 43.994529999057285],
            [-102.018887, 43.708617999057665],
            [-102.033477, 43.689341999057675],
            [-102.139158, 43.700947999057654],
            [-102.175231, 43.687755999057686],
            [-102.809398, 43.687559999057683],
            [-102.695526, 43.798149999057522],
            [-102.687191, 43.855598999057463],
            [-104.055487998450985, 43.853475997513449],
            [-104.055488, 43.853476999057463]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "039",
        COUNTYNS: "01265781",
        AFFGEOID: "0500000US46039",
        GEOID: "46039",
        NAME: "Deuel",
        LSAD: "06",
        ALAND: 1612756368,
        AWATER: 36065238
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.88457, 44.804435999056778],
            [-96.882345, 44.976869999056767],
            [-96.455840113633599, 44.977345247336757],
            [-96.454830515553795, 44.805550203102577],
            [-96.453806699667098, 44.631335831004826],
            [-96.453291313598683, 44.543636800874857],
            [-96.885504, 44.543772999056863],
            [-96.88457, 44.804435999056778]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "015",
        COUNTYNS: "01247985",
        AFFGEOID: "0500000US45015",
        GEOID: "45015",
        NAME: "Berkeley",
        LSAD: "06",
        ALAND: 2845745102,
        AWATER: 338007534
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.361851, 33.257442999134319],
            [-80.253836, 33.299259999133767],
            [-80.222267, 33.443715999131918],
            [-80.150003, 33.448462999131834],
            [-80.101697, 33.496890999131217],
            [-79.971062, 33.500742999131162],
            [-79.677014, 33.304943999133698],
            [-79.458734, 33.235218999134609],
            [-79.446699, 33.213457999134889],
            [-79.518844, 33.148296999135752],
            [-79.642577, 33.124122999136077],
            [-79.828611, 32.918964999138801],
            [-79.897279, 32.858765999139621],
            [-79.914307, 32.818891999140149],
            [-79.919202, 32.818990999140155],
            [-79.950448, 32.910173999138919],
            [-79.985703, 32.904208999139016],
            [-80.023318, 32.921433999138777],
            [-80.055462, 32.999511999137724],
            [-80.149246, 33.021600999137434],
            [-80.244542, 33.093868999136468],
            [-80.279567, 33.119212999136145],
            [-80.330057, 33.148503999135741],
            [-80.297577, 33.181837999135311],
            [-80.361851, 33.257442999134319]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "019",
        COUNTYNS: "01383795",
        AFFGEOID: "0500000US48019",
        GEOID: "48019",
        NAME: "Bandera",
        LSAD: "06",
        ALAND: 2048666851,
        AWATER: 17288354
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.602776, 29.907678999183968],
            [-99.176988, 29.89506299918418],
            [-98.917725, 29.781397999186062],
            [-98.778782, 29.720166999187089],
            [-98.806552, 29.690708999187574],
            [-98.927161, 29.562249999189731],
            [-98.971383, 29.551841999189918],
            [-98.983787, 29.623449999188708],
            [-99.411817, 29.627513999188647],
            [-99.60313, 29.627180999188646],
            [-99.602776, 29.907678999183968]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "023",
        COUNTYNS: "01213663",
        AFFGEOID: "0500000US42023",
        GEOID: "42023",
        NAME: "Cameron",
        LSAD: "06",
        ALAND: 1026229553,
        AWATER: 5664041
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.419118, 41.602187999063204],
            [-78.41181, 41.616955999063158],
            [-78.203422, 41.618156999063132],
            [-78.050442, 41.475461999063711],
            [-77.989194, 41.4748219990637],
            [-77.988786, 41.367452999064142],
            [-78.093381, 41.216930999064765],
            [-78.236817, 41.230474999064718],
            [-78.253959, 41.404720999063983],
            [-78.420028, 41.405045999063979],
            [-78.419118, 41.602187999063204]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "063",
        COUNTYNS: "01648576",
        AFFGEOID: "0500000US47063",
        GEOID: "47063",
        NAME: "Hamblen",
        LSAD: "06",
        ALAND: 417438540,
        AWATER: 37890290
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.449498, 36.217988999100278],
            [-83.328295, 36.296689999099513],
            [-83.255292, 36.28909199909959],
            [-83.16259, 36.34529199909904],
            [-83.082312, 36.242295999100037],
            [-83.14219, 36.187389999100589],
            [-83.166085, 36.180893999100654],
            [-83.187386, 36.174994999100704],
            [-83.175098, 36.127356999101181],
            [-83.245787, 36.124193999101216],
            [-83.234585, 36.085093999101602],
            [-83.274501, 36.130793999101158],
            [-83.426, 36.154549999100908],
            [-83.467097, 36.174687999100712],
            [-83.449498, 36.217988999100278]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "027",
        COUNTYNS: "01247990",
        AFFGEOID: "0500000US45027",
        GEOID: "45027",
        NAME: "Clarendon",
        LSAD: "06",
        ALAND: 1571964062,
        AWATER: 229790157
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.534085, 33.643910999129332],
            [-80.449293, 33.737527999128162],
            [-80.262672, 33.795998999127427],
            [-80.195371, 33.838981999126894],
            [-79.974601, 33.94652699912556],
            [-79.945948, 33.885996999126299],
            [-79.87562, 33.885244999126321],
            [-79.915305, 33.818647999127144],
            [-79.995638, 33.767905999127784],
            [-79.974382, 33.721589999128355],
            [-80.101697, 33.496890999131217],
            [-80.150003, 33.448462999131834],
            [-80.222267, 33.443715999131918],
            [-80.393145, 33.445338999131877],
            [-80.496273, 33.558480999130438],
            [-80.540514, 33.629031999129531],
            [-80.534085, 33.643910999129332]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "087",
        COUNTYNS: "01265773",
        AFFGEOID: "0500000US46087",
        GEOID: "46087",
        NAME: "McCook",
        LSAD: "06",
        ALAND: 1487182081,
        AWATER: 6898508
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.608517, 43.849058999057462],
            [-97.369463, 43.848525999057465],
            [-97.129089, 43.847972999057468],
            [-97.129478, 43.499682999057967],
            [-97.40145, 43.499782999057977],
            [-97.607012, 43.499825999057975],
            [-97.608517, 43.849058999057462]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "073",
        COUNTYNS: "01265778",
        AFFGEOID: "0500000US46073",
        GEOID: "46073",
        NAME: "Jerauld",
        LSAD: "06",
        ALAND: 1362808091,
        AWATER: 16668604
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.925953, 44.196574999057077],
            [-98.700453, 44.196726999057084],
            [-98.332042, 44.196619999057077],
            [-98.331508, 43.937707999057352],
            [-98.807771, 43.935222999057359],
            [-98.926997, 43.935142999057355],
            [-98.925953, 44.196574999057077]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "099",
        COUNTYNS: "01265772",
        AFFGEOID: "0500000US46099",
        GEOID: "46099",
        NAME: "Minnehaha",
        LSAD: "06",
        ALAND: 2089691696,
        AWATER: 18198505
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.129089, 43.847972999057468],
            [-96.888664, 43.848384999057458],
            [-96.452909574366288, 43.849506892075858],
            [-96.453315, 43.552298999057882],
            [-96.45326, 43.500389999057973],
            [-96.598928, 43.500456999057974],
            [-96.924841, 43.500006999057966],
            [-97.129478, 43.499682999057967],
            [-97.129089, 43.847972999057468]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "063",
        COUNTYNS: "00424233",
        AFFGEOID: "0500000US17063",
        GEOID: "17063",
        NAME: "Grundy",
        LSAD: "06",
        ALAND: 1082840997,
        AWATER: 32125709
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.59596, 41.457033999063775],
            [-88.252231, 41.463065999063758],
            [-88.252219, 41.462752999063753],
            [-88.244155, 41.201545999064834],
            [-88.241433, 41.128949999065156],
            [-88.251995, 41.114228999065226],
            [-88.58624, 41.108292999065242],
            [-88.59596, 41.457033999063775]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "005",
        COUNTYNS: "01448017",
        AFFGEOID: "0500000US49005",
        GEOID: "49005",
        NAME: "Cache",
        LSAD: "06",
        ALAND: 3016670856,
        AWATER: 21097698
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.109532, 41.997597999061774],
            [-112.109443055819995, 41.997598428468372],
            [-111.750778, 41.999329999061771],
            [-111.507813035175985, 41.999685667413971],
            [-111.50780619568701, 41.999685677426058],
            [-111.471321, 41.928156999062018],
            [-111.507864, 41.852588999062277],
            [-111.460722, 41.816984999062406],
            [-111.401576, 41.654080999063012],
            [-111.510839, 41.423099999063922],
            [-111.666576, 41.428829999063879],
            [-111.76059, 41.36881799906412],
            [-111.885443, 41.426373999063898],
            [-111.873314, 41.48574299906366],
            [-111.981042, 41.534116999063471],
            [-112.051737, 41.699551999062834],
            [-112.026371, 41.776411999062553],
            [-112.052863, 41.866230999062232],
            [-112.159177451510004, 41.99868192879957],
            [-112.109532, 41.997597999061774]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "079",
        COUNTYNS: "00659485",
        AFFGEOID: "0500000US27079",
        GEOID: "27079",
        NAME: "Le Sueur",
        LSAD: "06",
        ALAND: 1162055133,
        AWATER: 65130232
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.025252, 44.25687499905704],
            [-93.932685, 44.344884999056966],
            [-93.92955, 44.456715999056897],
            [-93.885373, 44.518208999056874],
            [-93.910405, 44.543162999056861],
            [-93.524327, 44.543617999056849],
            [-93.525231, 44.196133999057089],
            [-93.767971, 44.195835999057088],
            [-93.768031, 44.239383999057054],
            [-94.011868, 44.239519999057059],
            [-94.025252, 44.25687499905704]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "003",
        COUNTYNS: "01675170",
        AFFGEOID: "0500000US51003",
        GEOID: "51003",
        NAME: "Albemarle",
        LSAD: "06",
        ALAND: 1866002205,
        AWATER: 14235728
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.780879, 38.081028999083777],
            [-78.749396, 38.206647999082797],
            [-78.655273, 38.260426999082384],
            [-78.663145, 38.277932999082253],
            [-78.368743, 38.184073999082976],
            [-78.341674, 38.176897999083025],
            [-78.209384, 38.131127999083375],
            [-78.253989, 38.06281299908391],
            [-78.306763, 38.006473999084363],
            [-78.491332, 37.796970999086042],
            [-78.497714, 37.763099999086322],
            [-78.643918, 37.733083999086553],
            [-78.668862, 37.724751999086628],
            [-78.682986, 37.782059999086151],
            [-78.838874, 38.047369999084033],
            [-78.780879, 38.081028999083777]
          ],
          [
            [-78.519333, 38.010677999084322],
            [-78.46368, 38.020450999084254],
            [-78.448796, 38.058446999083948],
            [-78.46613, 38.045699999084064],
            [-78.495034, 38.065336999083904],
            [-78.519333, 38.010677999084322]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "135",
        COUNTYNS: "01639781",
        AFFGEOID: "0500000US47135",
        GEOID: "47135",
        NAME: "Perry",
        LSAD: "06",
        ALAND: 1074185963,
        AWATER: 21034745
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.042332, 35.444616999108263],
            [-87.97463, 35.521024999107453],
            [-88.035837, 35.621838999106373],
            [-88.034788, 35.717260999105363],
            [-87.970738, 35.815704999104355],
            [-87.96274, 35.840920999104092],
            [-87.852682, 35.80379899910448],
            [-87.71676, 35.83874999910411],
            [-87.74375, 35.663951999105933],
            [-87.658189, 35.609348999106508],
            [-87.651755, 35.564863999106976],
            [-87.717559, 35.483346999107845],
            [-87.988894, 35.453665999108168],
            [-88.007709, 35.423380999108488],
            [-88.042332, 35.444616999108263]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "147",
        COUNTYNS: "01639786",
        AFFGEOID: "0500000US47147",
        GEOID: "47147",
        NAME: "Robertson",
        LSAD: "06",
        ALAND: 1233605511,
        AWATER: 451691
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.150371, 36.566823999096904],
            [-87.115003556688407, 36.644142043747372],
            [-87.060826344392893, 36.644770883947764],
            [-86.813037, 36.647646999096125],
            [-86.763290964108492, 36.648720673597914],
            [-86.606394, 36.652106999096098],
            [-86.562067651865306, 36.640746673003498],
            [-86.693292, 36.407230999098417],
            [-86.754795, 36.405495999098449],
            [-86.825053, 36.349816999098991],
            [-86.913233, 36.38262099909867],
            [-86.98806, 36.369153999098806],
            [-87.063143, 36.421414999098296],
            [-87.120443, 36.455459999097961],
            [-87.150371, 36.566823999096904]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "009",
        COUNTYNS: "00758459",
        AFFGEOID: "0500000US29009",
        GEOID: "29009",
        NAME: "Barry",
        LSAD: "06",
        ALAND: 2015661187,
        AWATER: 32940075
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.068898, 36.747817999095183],
            [-94.06274, 36.931774999093506],
            [-93.611003, 36.922830999093584],
            [-93.612929, 36.820561999094522],
            [-93.5738, 36.820031999094518],
            [-93.584281547355687, 36.498901677725847],
            [-93.700171, 36.499134999097542],
            [-93.866758211671069, 36.498866163782438],
            [-93.95919, 36.49871699909756],
            [-94.077088266893469, 36.498975956319455],
            [-94.068898, 36.747817999095183]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "037",
        COUNTYNS: "01492442",
        AFFGEOID: "0500000US51037",
        GEOID: "51037",
        NAME: "Charlotte",
        LSAD: "06",
        ALAND: 1230994495,
        AWATER: 5824992
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.904587, 37.022287999092676],
            [-78.824209, 37.205360999091049],
            [-78.777293, 37.229106999090838],
            [-78.681573, 37.248758999090676],
            [-78.692432, 37.203672999091069],
            [-78.547128, 37.150430999091533],
            [-78.443644, 37.07937099909217],
            [-78.493028, 36.89121999909387],
            [-78.648541, 36.697908999095652],
            [-78.671463, 36.857950999094172],
            [-78.749727, 36.920064999093618],
            [-78.743891, 37.005438999092831],
            [-78.794862, 36.955987999093288],
            [-78.892702, 36.976693999093087],
            [-78.904587, 37.022287999092676]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "179",
        COUNTYNS: "01639797",
        AFFGEOID: "0500000US47179",
        GEOID: "47179",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 845605969,
        AWATER: 8635314
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.680677, 36.431799999098196],
            [-82.601474, 36.423199999098273],
            [-82.421572, 36.447253999098038],
            [-82.299859, 36.396504999098539],
            [-82.299968, 36.37113999909878],
            [-82.34046, 36.252806999099938],
            [-82.420661, 36.163206999100829],
            [-82.527605, 36.148950999100961],
            [-82.595065, 36.096204999101488],
            [-82.647769, 36.14640399910099],
            [-82.631794, 36.418768999098305],
            [-82.702566, 36.410411999098393],
            [-82.680677, 36.431799999098196]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "107",
        COUNTYNS: "01581114",
        AFFGEOID: "0500000US55107",
        GEOID: "55107",
        NAME: "Rusk",
        LSAD: "06",
        ALAND: 2366092049,
        AWATER: 44813688
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.540294, 45.637604999057032],
            [-90.678749, 45.638263999057031],
            [-90.678773, 45.377906999056862],
            [-90.925343, 45.379349999056863],
            [-90.925222, 45.292060999056829],
            [-91.541322, 45.292219999056833],
            [-91.540294, 45.637604999057032]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "013",
        COUNTYNS: "01480097",
        AFFGEOID: "0500000US51013",
        GEOID: "51013",
        NAME: "Arlington",
        LSAD: "06",
        ALAND: 67362395,
        AWATER: 244142
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.172276, 38.893244999077794],
            [-77.124875, 38.930407999077531],
            [-77.119759, 38.934342999077501],
            [-77.0902, 38.904210999077705],
            [-77.039099, 38.868111999077946],
            [-77.039066356764693, 38.841271888596758],
            [-77.087805, 38.827356999078248],
            [-77.110799, 38.843445999078142],
            [-77.149701, 38.875669999077907],
            [-77.172276, 38.893244999077794]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "079",
        COUNTYNS: "01249299",
        AFFGEOID: "0500000US45079",
        GEOID: "45079",
        NAME: "Richland",
        LSAD: "06",
        ALAND: 1960994034,
        AWATER: 37854754
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.316911, 34.239457999121989],
            [-81.178293, 34.174551999122762],
            [-81.181196, 34.220284999122214],
            [-81.095863, 34.21387199912229],
            [-80.825921, 34.268759999121635],
            [-80.869524, 34.182270999122686],
            [-80.719318, 34.068971999124045],
            [-80.692965, 34.0812799991239],
            [-80.616186, 34.099604999123677],
            [-80.635712, 34.084885999123863],
            [-80.601036, 34.035929999124455],
            [-80.633545, 33.852679999126721],
            [-80.620107, 33.743236999128094],
            [-80.78486, 33.761007999127862],
            [-80.91731, 33.813734999127206],
            [-81.01233, 33.880079999126394],
            [-81.05211, 33.997615999124932],
            [-81.139509, 34.068291999124064],
            [-81.275845, 34.097380999123708],
            [-81.338961, 34.197586999122478],
            [-81.316911, 34.239457999121989]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "065",
        COUNTYNS: "01494061",
        AFFGEOID: "0500000US51065",
        GEOID: "51065",
        NAME: "Fluvanna",
        LSAD: "06",
        ALAND: 743630411,
        AWATER: 10746124
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.491332, 37.796970999086042],
            [-78.306763, 38.006473999084363],
            [-78.205325, 37.957439999084748],
            [-78.062481, 37.904684999085163],
            [-78.15302, 37.7656329990863],
            [-78.159326, 37.748527999086441],
            [-78.18099, 37.744958999086471],
            [-78.239748, 37.690494999086923],
            [-78.464149, 37.76019999908636],
            [-78.491332, 37.796970999086042]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "043",
        COUNTYNS: "01690562",
        AFFGEOID: "0500000US51043",
        GEOID: "51043",
        NAME: "Clarke",
        LSAD: "06",
        ALAND: 455661684,
        AWATER: 5693068
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.101984, 39.106290999076336],
            [-78.033183460934197, 39.26462369755],
            [-78.032841, 39.264402999075308],
            [-77.82830183326729, 39.132422453908674],
            [-77.862825, 39.080978999076514],
            [-77.962203, 39.013732999076964],
            [-78.004313, 38.979437999077184],
            [-78.058571, 39.019205999076924],
            [-78.151614, 39.036628999076804],
            [-78.101984, 39.106290999076336]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "067",
        COUNTYNS: "01155137",
        AFFGEOID: "0500000US41067",
        GEOID: "41067",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1875846816,
        AWATER: 6127010
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.484726, 45.708763999057084],
            [-123.361028, 45.708695999057092],
            [-123.361622, 45.779578999057165],
            [-123.030873, 45.779158999057145],
            [-122.929146, 45.721481999057104],
            [-122.929128, 45.635312999057035],
            [-122.867592, 45.591877999057004],
            [-122.743859, 45.516664999056943],
            [-122.743713, 45.438376999056899],
            [-122.743721, 45.433293999056893],
            [-122.743741, 45.332066999056835],
            [-122.867891, 45.317344999056829],
            [-122.970229, 45.346453999056848],
            [-123.032161, 45.40496199905688],
            [-123.135427, 45.433458999056896],
            [-123.46488, 45.433331999056897],
            [-123.461181, 45.491559999056932],
            [-123.299438, 45.607246999057011],
            [-123.423273, 45.636175999057038],
            [-123.484726, 45.708763999057084]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "103",
        COUNTYNS: "00659497",
        AFFGEOID: "0500000US27103",
        GEOID: "27103",
        NAME: "Nicollet",
        LSAD: "06",
        ALAND: 1161921138,
        AWATER: 47467472
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.78063, 44.4566499990569],
            [-94.6242, 44.456029999056909],
            [-93.92955, 44.456715999056897],
            [-93.932685, 44.344884999056966],
            [-94.025252, 44.25687499905704],
            [-94.011868, 44.239519999057059],
            [-94.046671, 44.154373999057128],
            [-94.371731, 44.264447999057026],
            [-94.491333, 44.359675999056968],
            [-94.666062, 44.394047999056944],
            [-94.78063, 44.4566499990569]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "107",
        COUNTYNS: "00659499",
        AFFGEOID: "0500000US27107",
        GEOID: "27107",
        NAME: "Norman",
        LSAD: "06",
        ALAND: 2260512389,
        AWATER: 10208057
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.855959349053194, 47.499173228589505],
            [-96.067621, 47.499135999060485],
            [-96.067069, 47.151812999059537],
            [-96.194671, 47.151148999059536],
            [-96.826569127228296, 47.150538999059535],
            [-96.836009594541892, 47.237981874546868],
            [-96.84022, 47.276980999059866],
            [-96.85748, 47.440456999060316],
            [-96.855959349053194, 47.499173228589505]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "117",
        COUNTYNS: "00659504",
        AFFGEOID: "0500000US27117",
        GEOID: "27117",
        NAME: "Pipestone",
        LSAD: "06",
        ALAND: 1204488112,
        AWATER: 2348855
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.452435854669488, 44.196779539152189],
            [-96.452435824595497, 44.196801585722092],
            [-96.07858, 44.196619999057077],
            [-96.063778, 44.196424999057101],
            [-96.06462, 43.849038999057463],
            [-96.452909571693198, 43.849508851694658],
            [-96.452435854669488, 44.196779539152189]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "055",
        COUNTYNS: "01383813",
        AFFGEOID: "0500000US48055",
        GEOID: "48055",
        NAME: "Caldwell",
        LSAD: "06",
        ALAND: 1412139126,
        AWATER: 4972802
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.899238, 29.8575829991848],
            [-97.712548, 30.020612999182102],
            [-97.710215, 30.02449899918205],
            [-97.665761, 30.063861999181398],
            [-97.64937, 30.067943999181338],
            [-97.315823, 29.786540999185977],
            [-97.317893, 29.784657999186013],
            [-97.597743, 29.630717999188594],
            [-97.633176, 29.649862999188265],
            [-97.768459, 29.719102999187104],
            [-97.780917, 29.759257999186431],
            [-97.799396, 29.752259999186553],
            [-97.875259, 29.858207999184792],
            [-97.899238, 29.8575829991848]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "103",
        COUNTYNS: "01480139",
        AFFGEOID: "0500000US51103",
        GEOID: "51103",
        NAME: "Lancaster",
        LSAD: "06",
        ALAND: 345240091,
        AWATER: 254112966
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.668182, 37.778385999086197],
            [-76.564735, 37.83728799908571],
            [-76.507828, 37.838813999085694],
            [-76.419511, 37.823436999085821],
            [-76.325299149690608, 37.682573884523677],
            [-76.32912, 37.670975999087069],
            [-76.279447, 37.618224999087509],
            [-76.2888778367019, 37.587359933191777],
            [-76.528637, 37.630899999087418],
            [-76.587234, 37.737072999086529],
            [-76.668182, 37.778385999086197]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "031",
        COUNTYNS: "01155132",
        AFFGEOID: "0500000US41031",
        GEOID: "41031",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 4615975255,
        AWATER: 26213922
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.84688, 44.491089999056889],
            [-121.793895, 44.594055999056835],
            [-121.800015, 44.683424999056804],
            [-121.759748, 44.761934999056784],
            [-121.816308, 44.80877399905679],
            [-121.752994, 44.829918999056773],
            [-121.725068, 44.825518999056769],
            [-121.08948, 44.823271999056765],
            [-120.371441, 44.821768999056772],
            [-120.404676, 44.794718999056784],
            [-120.386561, 44.564008999056838],
            [-120.827555, 44.562792999056846],
            [-120.826899, 44.476498999056894],
            [-120.988824, 44.476483999056889],
            [-120.988375, 44.390264999056946],
            [-121.107534, 44.390599999056938],
            [-121.843138, 44.392629999056943],
            [-121.84688, 44.491089999056889]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "043",
        COUNTYNS: "00758476",
        AFFGEOID: "0500000US29043",
        GEOID: "29043",
        NAME: "Christian",
        LSAD: "06",
        ALAND: 1457243595,
        AWATER: 3012641
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.608899, 37.098152999092001],
            [-93.065274, 37.088693999092072],
            [-92.911057, 37.08557199909211],
            [-92.903273, 37.070650999092251],
            [-92.909336, 36.809177999094629],
            [-93.304359, 36.816865999094553],
            [-93.337451, 36.992493999092957],
            [-93.610126, 36.99580999909292],
            [-93.608899, 37.098152999092001]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "057",
        COUNTYNS: "00758483",
        AFFGEOID: "0500000US29057",
        GEOID: "29057",
        NAME: "Dade",
        LSAD: "06",
        ALAND: 1269123621,
        AWATER: 42069988
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.082083, 37.349290999089796],
            [-94.075385, 37.581569999087819],
            [-93.616033, 37.572688999087887],
            [-93.621153, 37.427422999089124],
            [-93.625844, 37.282010999090382],
            [-94.052313, 37.290077999090329],
            [-94.083833, 37.290850999090296],
            [-94.082083, 37.349290999089796]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "053",
        COUNTYNS: "01213669",
        AFFGEOID: "0500000US42053",
        GEOID: "42053",
        NAME: "Forest",
        LSAD: "06",
        ALAND: 1106623935,
        AWATER: 8356610
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.51207, 41.624558999063112],
            [-78.956042, 41.623401999063127],
            [-78.958957, 41.43706099906386],
            [-79.06352, 41.453206999063788],
            [-79.095096, 41.340657999064248],
            [-79.106444, 41.32624699906431],
            [-79.208878, 41.331860999064283],
            [-79.207196, 41.430921999063884],
            [-79.400269, 41.436144999063856],
            [-79.400439, 41.386578999064049],
            [-79.477822, 41.38647899906406],
            [-79.478163, 41.440133999063839],
            [-79.512276, 41.465239999063748],
            [-79.51207, 41.624558999063112]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "017",
        COUNTYNS: "00424210",
        AFFGEOID: "0500000US17017",
        GEOID: "17017",
        NAME: "Cass",
        LSAD: "06",
        ALAND: 973345745,
        AWATER: 20581547
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.512616, 39.965284999071024],
            [-90.513747, 39.987890999070885],
            [-90.435852, 40.020561999070701],
            [-90.355198, 40.124244999070122],
            [-90.286724, 40.055320999070503],
            [-90.134517, 40.062022999070471],
            [-89.994724, 40.10837299907022],
            [-89.994506, 39.901924999071383],
            [-89.994405, 39.872859999071544],
            [-90.583534, 39.876749999071535],
            [-90.512616, 39.965284999071024]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "055",
        COUNTYNS: "00485326",
        AFFGEOID: "0500000US20055",
        GEOID: "20055",
        NAME: "Finney",
        LSAD: "06",
        ALAND: 3372066557,
        AWATER: 1794398
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.103268, 38.264556999082352],
            [-100.684699, 38.264136999082361],
            [-100.244393, 38.26228999908237],
            [-100.227137, 38.262236999082376],
            [-100.22661, 38.001014999084397],
            [-100.664166, 38.002529999084395],
            [-100.652251, 37.73627499908654],
            [-101.089667, 37.736336999086532],
            [-101.103778, 37.829034999085778],
            [-101.103268, 38.264556999082352]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "093",
        COUNTYNS: "00485011",
        AFFGEOID: "0500000US20093",
        GEOID: "20093",
        NAME: "Kearny",
        LSAD: "06",
        ALAND: 2254696682,
        AWATER: 1133601
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.542312, 38.263206999082371],
            [-101.125438, 38.264508999082352],
            [-101.103268, 38.264556999082352],
            [-101.103778, 37.829034999085778],
            [-101.089667, 37.736336999086532],
            [-101.527063, 37.736313999086533],
            [-101.542757, 37.827593999085792],
            [-101.542312, 38.263206999082371]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "051",
        COUNTYNS: "00399751",
        AFFGEOID: "0500000US16051",
        GEOID: "16051",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 2832617277,
        AWATER: 31168379
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.695242, 43.971940999057324],
            [-112.694744, 44.058463999057231],
            [-112.155935, 44.057779999057232],
            [-112.15637, 43.971246999057314],
            [-111.977016, 43.970908999057315],
            [-111.976849, 43.927384999057359],
            [-111.983527, 43.75380599905759],
            [-111.934357, 43.766159999057571],
            [-111.717356, 43.655325999057723],
            [-111.626043, 43.626755999057778],
            [-112.519861, 43.626622999057766],
            [-112.639238, 43.626573999057769],
            [-112.697122, 43.623139999057763],
            [-112.695242, 43.971940999057324]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "321",
        COUNTYNS: "00343365",
        AFFGEOID: "0500000US13321",
        GEOID: "13321",
        NAME: "Worth",
        LSAD: "06",
        ALAND: 1478123917,
        AWATER: 10485470
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.008445, 31.801644999154483],
            [-83.939437, 31.847928999153801],
            [-83.845323, 31.831189999154056],
            [-83.802723, 31.803578999154446],
            [-83.796846, 31.622928999157118],
            [-83.718774, 31.62202799915713],
            [-83.649384, 31.567976999157921],
            [-83.650566, 31.490337999159074],
            [-83.665777, 31.485134999159154],
            [-83.651764, 31.433917999159924],
            [-83.65409, 31.330660999161466],
            [-83.714134, 31.318364999161666],
            [-83.759086, 31.332224999161451],
            [-83.99943, 31.334965999161412],
            [-83.997796, 31.44375299915977],
            [-83.996246, 31.625168999157069],
            [-83.993374, 31.650033999156712],
            [-84.018404, 31.650273999156699],
            [-84.008445, 31.801644999154483]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "091",
        COUNTYNS: "00835867",
        AFFGEOID: "0500000US31091",
        GEOID: "31091",
        NAME: "Hooker",
        LSAD: "06",
        ALAND: 1867796192,
        AWATER: 882994
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.426557, 42.092193999061465],
            [-100.846129, 42.088165999061474],
            [-100.842459, 41.740403999062686],
            [-101.406466, 41.743199999062689],
            [-101.42592, 41.743058999062661],
            [-101.426557, 42.092193999061465]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "111",
        COUNTYNS: "01383841",
        AFFGEOID: "0500000US48111",
        GEOID: "48111",
        NAME: "Dallam",
        LSAD: "06",
        ALAND: 3893093470,
        AWATER: 5182189
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.041924, 36.500438999097526],
            [-103.002434, 36.500396999097525],
            [-102.162463, 36.500325999097534],
            [-102.163015, 36.055248999101899],
            [-103.040824, 36.055230999101902],
            [-103.041924, 36.500438999097526]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "069",
        COUNTYNS: "01213675",
        AFFGEOID: "0500000US42069",
        GEOID: "42069",
        NAME: "Lackawanna",
        LSAD: "06",
        ALAND: 1188217624,
        AWATER: 15896171
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.834695, 41.427245999063892],
            [-75.772134, 41.50586899906358],
            [-75.719887, 41.64226299906305],
            [-75.462411, 41.641586999063058],
            [-75.440658, 41.258124999064599],
            [-75.505607, 41.232538999064694],
            [-75.558862, 41.206856999064804],
            [-75.600736, 41.161496999065001],
            [-75.643494, 41.176258999064942],
            [-75.652922, 41.25831399906459],
            [-75.687244, 41.339215999064251],
            [-75.760518, 41.360941999064167],
            [-75.795286, 41.378126999064094],
            [-75.834695, 41.427245999063892]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "083",
        COUNTYNS: "01210235",
        AFFGEOID: "0500000US42083",
        GEOID: "42083",
        NAME: "McKean",
        LSAD: "06",
        ALAND: 2536105992,
        AWATER: 13010718
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.915775, 41.840895999062326],
            [-78.918857363615203, 41.999103197751168],
            [-78.59665, 41.999876999061776],
            [-78.308128960446595, 41.999071133803163],
            [-78.271204, 41.998967999061769],
            [-78.206603037858201, 41.999088304819388],
            [-78.203422, 41.618156999063132],
            [-78.41181, 41.616955999063158],
            [-78.419118, 41.602187999063204],
            [-78.483409, 41.629897999063097],
            [-78.956056, 41.623862999063128],
            [-78.915775, 41.840895999062326]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "125",
        COUNTYNS: "00758516",
        AFFGEOID: "0500000US29125",
        GEOID: "29125",
        NAME: "Maries",
        LSAD: "06",
        ALAND: 1364869337,
        AWATER: 7636521
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.195675, 38.292236999082149],
            [-91.644729, 38.288509999082166],
            [-91.644847, 38.164736999083118],
            [-91.630554, 38.153518999083204],
            [-91.632431, 38.050950999084009],
            [-91.895347, 38.051198999084015],
            [-92.021165, 38.010638999084321],
            [-92.18537, 38.016337999084278],
            [-92.181372, 38.161237999083141],
            [-92.195675, 38.292236999082149]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "107",
        COUNTYNS: "01639773",
        AFFGEOID: "0500000US47107",
        GEOID: "47107",
        NAME: "McMinn",
        LSAD: "06",
        ALAND: 1114009037,
        AWATER: 5340571
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.805544, 35.448570999108213],
            [-84.619865, 35.644650999106133],
            [-84.584472, 35.644423999106138],
            [-84.52585, 35.624185999106359],
            [-84.409226, 35.398377999108753],
            [-84.401025, 35.33037399910949],
            [-84.495529, 35.285471999109987],
            [-84.667398, 35.26804099911017],
            [-84.703607, 35.241042999110462],
            [-84.697838, 35.253172999110326],
            [-84.744441, 35.264222999110217],
            [-84.72314, 35.28547099910999],
            [-84.860164, 35.350073999109277],
            [-84.805544, 35.448570999108213]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "093",
        COUNTYNS: "01213681",
        AFFGEOID: "0500000US42093",
        GEOID: "42093",
        NAME: "Montour",
        LSAD: "06",
        ALAND: 337326240,
        AWATER: 5407948
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.734891, 41.092132999065321],
            [-76.732672, 41.172039999064957],
            [-76.678776, 41.154171999065035],
            [-76.640767, 41.15571799906504],
            [-76.618971, 41.063758999065442],
            [-76.514789, 40.958513999065914],
            [-76.528034, 40.882514999066267],
            [-76.557013, 40.938616999066006],
            [-76.644446, 40.964529999065888],
            [-76.79261, 40.946646999065969],
            [-76.734891, 41.092132999065321]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "007",
        COUNTYNS: "01074017",
        AFFGEOID: "0500000US39007",
        GEOID: "39007",
        NAME: "Ashtabula",
        LSAD: "06",
        ALAND: 1818360011,
        AWATER: 1724498213
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.003631, 41.715136999062779],
            [-81.002269406517499, 41.849172465499883],
            [-80.900342, 41.86891199906222],
            [-80.800794, 41.909634999062071],
            [-80.581882, 41.957609999061901],
            [-80.519425, 41.97752299906184],
            [-80.519396726690502, 41.849562989621596],
            [-80.519357, 41.669766999062958],
            [-80.519176750353395, 41.499924050269605],
            [-81.003125, 41.501684999063585],
            [-81.003631, 41.715136999062779]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "087",
        COUNTYNS: "00835865",
        AFFGEOID: "0500000US31087",
        GEOID: "31087",
        NAME: "Hitchcock",
        LSAD: "06",
        ALAND: 1838742723,
        AWATER: 22103380
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.323353, 40.35055299906891],
            [-100.777376, 40.349465999068912],
            [-100.758435, 40.349506999068907],
            [-100.75883, 40.002301999070816],
            [-101.060317, 40.002306999070811],
            [-101.293991, 40.002558999070821],
            [-101.325514027519006, 40.002565347301299],
            [-101.323353, 40.35055299906891]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "011",
        COUNTYNS: "00758460",
        AFFGEOID: "0500000US29011",
        GEOID: "29011",
        NAME: "Barton",
        LSAD: "06",
        ALAND: 1533062380,
        AWATER: 12440434
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.617845524354209, 37.653577795721631],
            [-94.073524, 37.639721999087328],
            [-94.075385, 37.581569999087819],
            [-94.082083, 37.349290999089796],
            [-94.617667604700799, 37.364170326128367],
            [-94.617511, 37.410908999089273],
            [-94.617845524354209, 37.653577795721631]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "017",
        COUNTYNS: "01034226",
        AFFGEOID: "0500000US38017",
        GEOID: "38017",
        NAME: "Cass",
        LSAD: "06",
        ALAND: 4571107601,
        AWATER: 7732062
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.706034, 47.239977999059775],
            [-97.451512, 47.239060999059767],
            [-96.836009594541892, 47.237981874546868],
            [-96.826569127228296, 47.150538999059535],
            [-96.819078, 47.081151999059358],
            [-96.833504, 47.010109999059196],
            [-96.763973, 46.912506999058976],
            [-96.788803, 46.777574999058672],
            [-96.786845, 46.692804999058502],
            [-96.790523, 46.636879999058401],
            [-96.789786513049791, 46.635746671775095],
            [-96.821202, 46.630185999058376],
            [-97.279948, 46.629347999058375],
            [-97.682006, 46.629927999058374],
            [-97.681508, 46.978480999059116],
            [-97.706034, 47.239977999059775]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "019",
        COUNTYNS: "00882228",
        AFFGEOID: "0500000US34019",
        GEOID: "34019",
        NAME: "Hunterdon",
        LSAD: "06",
        ALAND: 1108047582,
        AWATER: 24800279
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.188199878125189, 40.592613365716268],
            [-75.146009, 40.628585999067482],
            [-74.983388, 40.705371999067104],
            [-74.968047, 40.711323999067076],
            [-74.889819, 40.787728999066708],
            [-74.82388, 40.742357999066932],
            [-74.725666, 40.719531999067051],
            [-74.71315, 40.58390299906771],
            [-74.797841, 40.51555699906806],
            [-74.748147, 40.424155999068518],
            [-74.856262, 40.346695999068935],
            [-74.928110569091373, 40.339828446758261],
            [-74.946006, 40.357305999068878],
            [-74.969597, 40.399769999068646],
            [-75.024775, 40.403454999068629],
            [-75.056102, 40.416065999068572],
            [-75.070568, 40.455164999068373],
            [-75.062227, 40.481390999068225],
            [-75.078503, 40.548295999067889],
            [-75.136748, 40.575730999067744],
            [-75.186737, 40.569405999067783],
            [-75.188199878125189, 40.592613365716268]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "171",
        COUNTYNS: "01008587",
        AFFGEOID: "0500000US37171",
        GEOID: "37171",
        NAME: "Surry",
        LSAD: "06",
        ALAND: 1382436644,
        AWATER: 10286511
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.973643, 36.45347399909798],
            [-80.901661118920998, 36.561751186264942],
            [-80.840213077246005, 36.561928450734243],
            [-80.704831, 36.562318999096945],
            [-80.612188788124598, 36.558216275477385],
            [-80.440101541963699, 36.55059527426036],
            [-80.451696, 36.261501999099856],
            [-80.452322, 36.241418999100048],
            [-80.547885, 36.285418999099612],
            [-80.624245, 36.286821999099615],
            [-80.873205, 36.236304999100106],
            [-80.868746, 36.326446999099218],
            [-80.967153, 36.402019999098485],
            [-80.973643, 36.45347399909798]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "167",
        COUNTYNS: "01497573",
        AFFGEOID: "0500000US51167",
        GEOID: "51167",
        NAME: "Russell",
        LSAD: "06",
        ALAND: 1226378115,
        AWATER: 7588460
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.406633, 36.876355999094002],
            [-82.298596, 36.905760999093737],
            [-82.32783, 36.970981999093141],
            [-82.284965, 36.998428999092901],
            [-82.149375, 37.0416409990925],
            [-82.045338, 37.046668999092468],
            [-82.007716, 37.119870999091809],
            [-81.900892, 37.142552999091606],
            [-81.891041, 37.113127999091866],
            [-81.780545, 36.95862899909325],
            [-81.837096, 36.929038999093521],
            [-81.94595, 36.864606999094121],
            [-82.078703, 36.854775999094208],
            [-82.103853, 36.798166999094725],
            [-82.332078, 36.708968999095553],
            [-82.356356, 36.779851999094902],
            [-82.406633, 36.876355999094002]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "021",
        COUNTYNS: "00933055",
        AFFGEOID: "0500000US35021",
        GEOID: "35021",
        NAME: "Harding",
        LSAD: "06",
        ALAND: 5504963090,
        AWATER: 1162620
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.436056, 36.218533999100273],
            [-104.008823, 36.21811499910028],
            [-104.008864, 36.174526999100706],
            [-103.793991, 36.173971999100715],
            [-103.794525, 36.085737999101603],
            [-103.364977, 36.08604999910159],
            [-103.374864, 35.998656999102487],
            [-103.375117, 35.739515999105144],
            [-103.37973, 35.396795999108775],
            [-103.637053, 35.389661999108853],
            [-103.723609, 35.422784999108501],
            [-103.976901, 35.801746999104495],
            [-104.021702, 35.781493999104704],
            [-104.37012, 35.779301999104732],
            [-104.389736, 35.857603999103915],
            [-104.327284, 35.96019699910287],
            [-104.414546, 36.123760999101222],
            [-104.436056, 36.218533999100273]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "151",
        COUNTYNS: "00659520",
        AFFGEOID: "0500000US27151",
        GEOID: "27151",
        NAME: "Swift",
        LSAD: "06",
        ALAND: 1921870987,
        AWATER: 26208987
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.117036, 45.411992999056885],
            [-95.747402, 45.41231299905688],
            [-95.255206, 45.41252499905687],
            [-95.255196, 45.238711999056804],
            [-95.24649, 45.152383999056788],
            [-96.036698, 45.15220599905679],
            [-96.103614, 45.176674999056793],
            [-96.118054, 45.239783999056812],
            [-96.117036, 45.411992999056885]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "067",
        COUNTYNS: "01581093",
        AFFGEOID: "0500000US55067",
        GEOID: "55067",
        NAME: "Langlade",
        LSAD: "06",
        ALAND: 2254971901,
        AWATER: 44403904
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.424824, 45.467165999056917],
            [-89.364049, 45.46988599905692],
            [-89.04649, 45.464438999056924],
            [-88.923062, 45.465136999056917],
            [-88.926025, 45.378636999056852],
            [-88.677996, 45.378682999056863],
            [-88.680066, 45.204993999056796],
            [-88.641159, 45.117345999056774],
            [-88.982176, 45.117732999056777],
            [-88.98168, 45.028916999056769],
            [-89.223814, 45.029245999056762],
            [-89.224214, 45.118566999056775],
            [-89.425971, 45.119097999056784],
            [-89.424824, 45.467165999056917]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "157",
        COUNTYNS: "00835900",
        AFFGEOID: "0500000US31157",
        GEOID: "31157",
        NAME: "Scotts Bluff",
        LSAD: "06",
        ALAND: 1915079274,
        AWATER: 15397684
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.053026, 41.885463999062161],
            [-104.052762137092003, 42.001718264928357],
            [-103.401639, 42.003539999061758],
            [-103.363337, 42.002929999061756],
            [-103.36262, 41.743547999062677],
            [-103.370391, 41.699209999062838],
            [-104.052825235238998, 41.697953852126851],
            [-104.053026, 41.885463999062161]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "041",
        COUNTYNS: "01702369",
        AFFGEOID: "0500000US35041",
        GEOID: "35041",
        NAME: "Roosevelt",
        LSAD: "06",
        ALAND: 6335224660,
        AWATER: 18697914
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.948778, 34.605057999117662],
            [-103.738166, 34.604669999117654],
            [-103.740066, 34.30305599912122],
            [-103.45982, 34.303260999121221],
            [-103.043835830164994, 34.30264799912122],
            [-103.043563920791001, 34.112834388878916],
            [-103.043516, 34.079381999123918],
            [-103.047346, 33.824674999127076],
            [-103.047346018167005, 33.824674122278772],
            [-103.05261, 33.570598999130276],
            [-103.052610516564002, 33.570574775572481],
            [-103.510129, 33.570116999130271],
            [-103.509759, 33.657261999129176],
            [-103.718333, 33.656209999129189],
            [-103.716885, 33.819557999127142],
            [-103.841933, 33.819199999127136],
            [-103.841691, 34.081908999123883],
            [-103.946019, 34.082462999123884],
            [-103.948778, 34.605057999117662]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "097",
        COUNTYNS: "00659494",
        AFFGEOID: "0500000US27097",
        GEOID: "27097",
        NAME: "Morrison",
        LSAD: "06",
        ALAND: 2913973124,
        AWATER: 73283119
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.653462, 46.348677999057877],
            [-94.534724, 46.302796999057811],
            [-94.426529, 46.332335999057854],
            [-94.341679, 46.277704999057761],
            [-94.388922, 46.238165999057713],
            [-94.374592, 46.157092999057596],
            [-93.810362, 46.1565849990576],
            [-93.810321, 45.983079999057374],
            [-93.763948, 45.822142999057199],
            [-94.353112, 45.821966999057196],
            [-94.271776, 45.775207999057159],
            [-94.5133, 45.774379999057167],
            [-94.64399, 45.773949999057159],
            [-94.653462, 46.348677999057877]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "630",
        COUNTYNS: "01498425",
        AFFGEOID: "0500000US51630",
        GEOID: "51630",
        NAME: "Fredericksburg",
        LSAD: "25",
        ALAND: 27067954,
        AWATER: 178255
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.506782, 38.325924999081884],
            [-77.447126, 38.284613999082211],
            [-77.492015, 38.273718999082291],
            [-77.530283, 38.309174999082018],
            [-77.506782, 38.325924999081884]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "003",
        COUNTYNS: "01034225",
        AFFGEOID: "0500000US38003",
        GEOID: "38003",
        NAME: "Barnes",
        LSAD: "06",
        ALAND: 3863107435,
        AWATER: 56591279
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.467476, 47.240452999059762],
            [-97.961208, 47.24051199905977],
            [-97.706034, 47.239977999059775],
            [-97.681508, 46.978480999059116],
            [-97.682006, 46.629927999058374],
            [-98.033862, 46.630726999058382],
            [-98.439056, 46.631119999058377],
            [-98.439466, 46.979657999059128],
            [-98.467476, 47.240452999059762]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "660",
        COUNTYNS: "01498563",
        AFFGEOID: "0500000US51660",
        GEOID: "51660",
        NAME: "Harrisonburg",
        LSAD: "25",
        ALAND: 44890270,
        AWATER: 149190
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.915443, 38.437454999081062],
            [-78.871361, 38.489517999080661],
            [-78.825432, 38.432135999081098],
            [-78.827031, 38.420551999081191],
            [-78.914587, 38.390066999081398],
            [-78.915443, 38.437454999081062]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "013",
        COUNTYNS: "00484976",
        AFFGEOID: "0500000US20013",
        GEOID: "20013",
        NAME: "Brown",
        LSAD: "06",
        ALAND: 1478550074,
        AWATER: 3196147
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.788110994891696, 40.00046677843612],
            [-95.784575, 40.00046299907082],
            [-95.339895970850293, 40.000028856176222],
            [-95.33974, 39.652979999072862],
            [-95.564126, 39.652871999072858],
            [-95.788941, 39.653000999072859],
            [-95.788110994891696, 40.00046677843612]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "045",
        COUNTYNS: "01605086",
        AFFGEOID: "0500000US56045",
        GEOID: "56045",
        NAME: "Weston",
        LSAD: "06",
        ALAND: 6210804109,
        AWATER: 5225498
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.079281, 44.176181999057107],
            [-104.055406377827012, 44.180382873152794],
            [-104.055416190705003, 44.14108120584055],
            [-104.055488, 43.853476999057463],
            [-104.055487998450985, 43.853475997513449],
            [-104.055032, 43.558602999057868],
            [-104.054794284348006, 43.503327529463974],
            [-104.899941, 43.499639999057969],
            [-105.079797, 43.498444999057966],
            [-105.079281, 44.176181999057107]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "129",
        COUNTYNS: "01804545",
        AFFGEOID: "0500000US72129",
        GEOID: "72129",
        NAME: "San Lorenzo",
        LSAD: "13",
        ALAND: 137547974,
        AWATER: 256425
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.052555, 18.113068999441822],
            [-66.035651, 18.119203999441655],
            [-65.99765, 18.206991999439325],
            [-65.951019, 18.229291999438733],
            [-65.92957, 18.197452999439573],
            [-65.928341, 18.142844999441021],
            [-65.926227, 18.119962999441636],
            [-65.933166, 18.093753999442331],
            [-66.010245, 18.075564999442815],
            [-66.051788, 18.109877999441903],
            [-66.052555, 18.113068999441822]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "127",
        COUNTYNS: "01804544",
        AFFGEOID: "0500000US72127",
        GEOID: "72127",
        NAME: "San Juan",
        LSAD: "13",
        ALAND: 124029911,
        AWATER: 75277891
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.129260345438709, 18.47217458928942],
            [-66.03944, 18.454440999432784],
            [-66.03432184747949, 18.455069906231671],
            [-65.991415, 18.398573999434259],
            [-65.999556, 18.380064999434747],
            [-66.035161, 18.369872999435014],
            [-66.041535, 18.312343999436539],
            [-66.066103, 18.303320999436774],
            [-66.080475, 18.297294999436929],
            [-66.099586, 18.342221999435743],
            [-66.108281, 18.438901999433199],
            [-66.129403, 18.465631999432492],
            [-66.129260345438709, 18.47217458928942]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "115",
        COUNTYNS: "00295741",
        AFFGEOID: "0500000US12115",
        GEOID: "12115",
        NAME: "Sarasota",
        LSAD: "06",
        ALAND: 1439588493,
        AWATER: 438890089
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.648170616859488, 27.389719999228692],
            [-82.252264, 27.386242999228749],
            [-82.253589, 27.210509999232109],
            [-82.056497, 27.207768999232155],
            [-82.057469, 27.032118999235532],
            [-82.255237, 27.032974999235513],
            [-82.255521, 26.945263999237209],
            [-82.369201392169089, 26.946081024870693],
            [-82.452673, 27.079358999234621],
            [-82.516585422788197, 27.207826302473961],
            [-82.539719, 27.254325999231277],
            [-82.610581, 27.348816999229467],
            [-82.648170616859488, 27.389719999228692]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "135",
        COUNTYNS: "01784866",
        AFFGEOID: "0500000US17135",
        GEOID: "17135",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 1822539611,
        AWATER: 15512963
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.701645, 39.523368999073654],
            [-89.533655, 39.52459199907365],
            [-89.530836, 39.348863999074752],
            [-89.139807, 39.348887999074755],
            [-89.140076, 39.217906999075595],
            [-89.250513, 39.217511999075597],
            [-89.250447, 39.028144999076858],
            [-89.586088, 39.028245999076859],
            [-89.639265, 38.999128999077065],
            [-89.698555, 38.998978999077067],
            [-89.701645, 39.523368999073654]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "017",
        COUNTYNS: "00659454",
        AFFGEOID: "0500000US27017",
        GEOID: "27017",
        NAME: "Carlton",
        LSAD: "06",
        ALAND: 2230821123,
        AWATER: 36133708
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.061529, 46.766549999058654],
            [-92.301676, 46.76413399905865],
            [-92.292192, 46.663241999058442],
            [-92.292371, 46.495584999058131],
            [-92.292759802428293, 46.417219501937993],
            [-93.054847, 46.419268999057998],
            [-93.061529, 46.766549999058654]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "057",
        COUNTYNS: "00659474",
        AFFGEOID: "0500000US27057",
        GEOID: "27057",
        NAME: "Hubbard",
        LSAD: "06",
        ALAND: 2398071535,
        AWATER: 190599026
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.183216, 47.412772999060252],
            [-94.799934, 47.409847999060219],
            [-94.670214, 47.410150999060235],
            [-94.659942, 46.803715999058738],
            [-94.787126, 46.803942999058734],
            [-95.16301, 46.80471999905874],
            [-95.169172, 47.152514999059541],
            [-95.169633, 47.325398999059992],
            [-95.183216, 47.412772999060252]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "101",
        COUNTYNS: "01034231",
        AFFGEOID: "0500000US38101",
        GEOID: "38101",
        NAME: "Ward",
        LSAD: "06",
        ALAND: 5214059942,
        AWATER: 110820843
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.2336, 48.72028999906491],
            [-102.152545, 48.720293999064907],
            [-102.152164, 48.80719999906529],
            [-102.021655, 48.807107999065295],
            [-102.021428, 48.720282999064899],
            [-101.842141, 48.720142999064905],
            [-101.841969, 48.459544999063823],
            [-101.059802, 48.458447999063821],
            [-101.014138, 48.371431999063468],
            [-101.015024, 48.022738999062163],
            [-100.971325, 47.84870299906158],
            [-101.871862, 47.847588999061571],
            [-101.871537, 48.023175999062182],
            [-101.922123, 48.023172999062183],
            [-101.922237, 48.372329999063474],
            [-101.973399, 48.372094999063471],
            [-101.973322, 48.547132999064182],
            [-102.233892, 48.546338999064162],
            [-102.2336, 48.72028999906491]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "007",
        COUNTYNS: "00659449",
        AFFGEOID: "0500000US27007",
        GEOID: "27007",
        NAME: "Beltrami",
        LSAD: "06",
        ALAND: 6487120495,
        AWATER: 1426321738
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.602315, 48.538886999064133],
            [-95.34254, 48.540210999064143],
            [-95.211966, 48.539960999064142],
            [-95.210953, 48.36597099906345],
            [-94.428702, 48.367332999063457],
            [-94.417747, 47.93310999906187],
            [-94.418543, 47.845815999061571],
            [-94.416279, 47.444827999060337],
            [-94.416387, 47.411829999060231],
            [-94.670214, 47.410150999060235],
            [-94.799934, 47.409847999060219],
            [-95.183216, 47.412772999060252],
            [-95.193705, 47.872543999061655],
            [-95.277834, 47.925345999061832],
            [-95.229133, 48.019957999062164],
            [-95.582887, 48.020556999062158],
            [-95.592287, 48.037358999062221],
            [-95.593788, 48.172915999062717],
            [-95.602315, 48.538886999064133]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "001",
        COUNTYNS: "00663198",
        AFFGEOID: "0500000US27001",
        GEOID: "27001",
        NAME: "Aitkin",
        LSAD: "06",
        ALAND: 4718271444,
        AWATER: 449535492
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.810407, 46.250947999057736],
            [-93.777905, 46.589670999058299],
            [-93.776024, 46.802890999058725],
            [-93.775257, 47.030417999059239],
            [-93.055943, 47.026414999059234],
            [-93.061529, 46.766549999058654],
            [-93.054847, 46.419268999057998],
            [-93.053871, 46.158119999057597],
            [-93.284475, 46.159442999057603],
            [-93.431831, 46.153990999057591],
            [-93.430648, 46.246411999057727],
            [-93.796281, 46.243198999057711],
            [-93.810407, 46.250947999057736]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "031",
        COUNTYNS: "01622958",
        AFFGEOID: "0500000US26031",
        GEOID: "26031",
        NAME: "Cheboygan",
        LSAD: "06",
        ALAND: 1852552031,
        AWATER: 440619039
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.732687, 45.289381999056829],
            [-84.732242, 45.780496852346161],
            [-84.718904, 45.777598999057155],
            [-84.553311, 45.698565999057088],
            [-84.46168, 45.652403999057043],
            [-84.413642, 45.669426999057059],
            [-84.329537, 45.664379999057061],
            [-84.2108933455563, 45.626231016905429],
            [-84.25202, 45.62599799905702],
            [-84.246634, 45.198726999056809],
            [-84.366614, 45.198736999056798],
            [-84.734303, 45.201935999056793],
            [-84.732687, 45.289381999056829]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "035",
        COUNTYNS: "01622960",
        AFFGEOID: "0500000US26035",
        GEOID: "26035",
        NAME: "Clare",
        LSAD: "06",
        ALAND: 1461741364,
        AWATER: 28501274
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.087403, 44.164241999057118],
            [-84.851705, 44.161374999057131],
            [-84.608104, 44.160481999057119],
            [-84.606035, 43.815217999057516],
            [-85.088811, 43.813675999057509],
            [-85.087403, 44.164241999057118]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "001",
        COUNTYNS: "00450401",
        AFFGEOID: "0500000US18001",
        GEOID: "18001",
        NAME: "Adams",
        LSAD: "06",
        ALAND: 878079474,
        AWATER: 2429955
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.073861, 40.917822999066104],
            [-84.802670206153394, 40.922568712987093],
            [-84.802119, 40.728162999066996],
            [-84.802119032263789, 40.728145935939608],
            [-84.802413878579401, 40.572212879714478],
            [-85.068479, 40.568239999067785],
            [-85.073861, 40.917822999066104]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "021",
        COUNTYNS: "00161536",
        AFFGEOID: "0500000US01021",
        GEOID: "01021",
        NAME: "Chilton",
        LSAD: "06",
        ALAND: 1794478905,
        AWATER: 20590301
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.019157, 32.837033999139912],
            [-86.876118, 32.836263999139916],
            [-86.881182, 33.049900999137058],
            [-86.881638, 33.07186099913676],
            [-86.610003, 33.07000299913679],
            [-86.517344, 33.020565999137446],
            [-86.515959, 32.929360999138673],
            [-86.451524, 32.863691999139562],
            [-86.457015, 32.813898999140228],
            [-86.374974, 32.753579999141046],
            [-86.413335, 32.750590999141089],
            [-86.413116, 32.707385999141678],
            [-86.714219, 32.705693999141701],
            [-86.71339, 32.661731999142312],
            [-86.917595, 32.664168999142269],
            [-87.01766, 32.663268999142275],
            [-87.017762, 32.729531999141379],
            [-87.019157, 32.837033999139912]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "127",
        COUNTYNS: "00069179",
        AFFGEOID: "0500000US05127",
        GEOID: "05127",
        NAME: "Scott",
        LSAD: "06",
        ALAND: 2311063525,
        AWATER: 14887193
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.449058, 34.890555999114376],
            [-94.447509535784292, 34.93397621127108],
            [-94.230524, 34.9685269991135],
            [-94.228244, 35.057594999112496],
            [-94.140571, 35.099677999112025],
            [-94.141765, 35.055684999112522],
            [-94.074347, 35.026584999112842],
            [-93.704885, 35.019714999112914],
            [-93.710297, 34.745295999116024],
            [-93.817003, 34.711300999116432],
            [-93.878163, 34.665269999116944],
            [-93.930509, 34.665607999116958],
            [-93.937304, 34.680554999116779],
            [-94.317659, 34.697407999116578],
            [-94.454395034137889, 34.728958672295626],
            [-94.449058, 34.890555999114376]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "029",
        COUNTYNS: "00465204",
        AFFGEOID: "0500000US19029",
        GEOID: "19029",
        NAME: "Cass",
        LSAD: "06",
        ALAND: 1461450798,
        AWATER: 1758815
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.154722, 41.505210999063578],
            [-95.04077, 41.504689999063579],
            [-94.700629, 41.504147999063584],
            [-94.700589, 41.158084999065025],
            [-94.927587, 41.158507999065023],
            [-95.155851, 41.159235999065025],
            [-95.154722, 41.505210999063578]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "840",
        COUNTYNS: "01789077",
        AFFGEOID: "0500000US51840",
        GEOID: "51840",
        NAME: "Winchester",
        LSAD: "25",
        ALAND: 23809486,
        AWATER: 54462
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.192659, 39.167926999075931],
            [-78.187794, 39.204869999075683],
            [-78.136725, 39.184370999075831],
            [-78.183183, 39.13102699907617],
            [-78.197023, 39.143074999076092],
            [-78.192659, 39.167926999075931]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "003",
        COUNTYNS: "00484971",
        AFFGEOID: "0500000US20003",
        GEOID: "20003",
        NAME: "Anderson",
        LSAD: "06",
        ALAND: 1501277564,
        AWATER: 10586104
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.508328, 38.390277999081405],
            [-95.065831, 38.389944999081408],
            [-95.077876, 38.03770599908411],
            [-95.51897, 38.038229999084109],
            [-95.508328, 38.390277999081405]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "099",
        COUNTYNS: "01074062",
        AFFGEOID: "0500000US39099",
        GEOID: "39099",
        NAME: "Mahoning",
        LSAD: "06",
        ALAND: 1065795297,
        AWATER: 35434368
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.086312, 40.988030999065771],
            [-81.001695, 40.987782999065786],
            [-81.00236, 41.062092999065442],
            [-81.00229, 41.134188999065131],
            [-80.519196690325003, 41.133386501614041],
            [-80.519222086080902, 41.125093565860972],
            [-80.519643772547283, 40.987392653071595],
            [-80.519891, 40.906660999066148],
            [-80.519876846740402, 40.902448674485875],
            [-80.519869707686183, 40.900323933315086],
            [-80.821923, 40.900934999066187],
            [-80.895813, 40.930186999066045],
            [-81.086679, 40.901608999066177],
            [-81.086312, 40.988030999065771]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "039",
        COUNTYNS: "00465209",
        AFFGEOID: "0500000US19039",
        GEOID: "19039",
        NAME: "Clarke",
        LSAD: "06",
        ALAND: 1116716130,
        AWATER: 1524746
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.014189, 41.15676199906504],
            [-93.898813, 41.156609999065033],
            [-93.789196, 41.162034999064993],
            [-93.557556, 41.161270999065017],
            [-93.55654, 40.898294999066188],
            [-94.014803, 40.897030999066203],
            [-94.014189, 41.15676199906504]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "111",
        COUNTYNS: "00450376",
        AFFGEOID: "0500000US18111",
        GEOID: "18111",
        NAME: "Newton",
        LSAD: "06",
        ALAND: 1040539918,
        AWATER: 4349506
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.526648158082395, 41.16608994357658],
            [-87.393796, 41.162805999065],
            [-87.275882, 41.218594999064777],
            [-87.267298, 40.736581999066956],
            [-87.526136490423681, 40.736885080489856],
            [-87.526014, 40.895581999066195],
            [-87.526463305991186, 41.010354817030169],
            [-87.52652, 41.024836999065613],
            [-87.526648158082395, 41.16608994357658]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "055",
        COUNTYNS: "00465216",
        AFFGEOID: "0500000US19055",
        GEOID: "19055",
        NAME: "Delaware",
        LSAD: "06",
        ALAND: 1496278852,
        AWATER: 3185282
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.607059, 42.64397299905982],
            [-91.132766, 42.645843999059807],
            [-91.130079, 42.295760999060811],
            [-91.363263, 42.29644499906081],
            [-91.596981, 42.296407999060818],
            [-91.607059, 42.64397299905982]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "149",
        COUNTYNS: "00069908",
        AFFGEOID: "0500000US05149",
        GEOID: "05149",
        NAME: "Yell",
        LSAD: "06",
        ALAND: 2409046726,
        AWATER: 48482972
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.704885, 35.019714999112914],
            [-93.706692, 35.134942999111644],
            [-93.529838, 35.145440999111514],
            [-93.457022, 35.215781999110753],
            [-93.279959, 35.212551999110779],
            [-93.278293, 35.317401999109642],
            [-93.156078, 35.235306999110534],
            [-93.065176, 35.118183999111821],
            [-93.000171, 35.168445999111249],
            [-92.896109, 35.170677999111234],
            [-93.040709, 35.108950999111919],
            [-93.040525, 35.076922999112284],
            [-93.305684, 34.875591999114548],
            [-93.287785, 34.773192999115714],
            [-93.394456, 34.772354999115727],
            [-93.394289, 34.743392999116061],
            [-93.710297, 34.745295999116024],
            [-93.704885, 35.019714999112914]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "109",
        COUNTYNS: "01074067",
        AFFGEOID: "0500000US39109",
        GEOID: "39109",
        NAME: "Miami",
        LSAD: "06",
        ALAND: 1053028461,
        AWATER: 7967436
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.432575, 40.197036999069731],
            [-84.218658, 40.200003999069722],
            [-84.022919, 40.183944999069794],
            [-84.036069, 40.040181999070597],
            [-84.051039, 39.879806999071512],
            [-84.157223, 39.885629999071483],
            [-84.157671, 39.922969999071256],
            [-84.425902, 39.919621999071282],
            [-84.432575, 40.197036999069731]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "283",
        COUNTYNS: "01383924",
        AFFGEOID: "0500000US48283",
        GEOID: "48283",
        NAME: "La Salle",
        LSAD: "06",
        ALAND: 3850545746,
        AWATER: 19503743
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.395736, 28.640783999205706],
            [-98.800841, 28.647486999205583],
            [-98.800848, 28.647305999205592],
            [-98.800082, 28.202332999213588],
            [-98.803325, 28.057479999216234],
            [-99.388946, 28.030418999216735],
            [-99.393972, 28.197494999213671],
            [-99.394177, 28.204626999213545],
            [-99.395736, 28.640783999205706]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "069",
        COUNTYNS: "00465223",
        AFFGEOID: "0500000US19069",
        GEOID: "19069",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1507301123,
        AWATER: 1428932
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.498617, 42.908511999059151],
            [-93.025144, 42.907548999059159],
            [-93.027, 42.55680999906005],
            [-93.499485, 42.557699999060063],
            [-93.498617, 42.908511999059151]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "113",
        COUNTYNS: "01784833",
        AFFGEOID: "0500000US17113",
        GEOID: "17113",
        NAME: "McLean",
        LSAD: "06",
        ALAND: 3064581023,
        AWATER: 7842778
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.26939, 40.59432899906767],
            [-89.133752, 40.596733999067638],
            [-89.044368, 40.627427999067493],
            [-88.9847, 40.664953999067308],
            [-88.986896, 40.752296999066878],
            [-88.929331, 40.753336999066875],
            [-88.584272, 40.757607999066842],
            [-88.574502, 40.616549999067544],
            [-88.459475, 40.617344999067548],
            [-88.459957, 40.398849999068645],
            [-88.460418, 40.281934999069271],
            [-88.491052, 40.281218999069274],
            [-88.574885, 40.28150099906928],
            [-88.919544, 40.282864999069268],
            [-89.148764, 40.282037999069274],
            [-89.148772, 40.282519999069265],
            [-89.26265, 40.280918999069279],
            [-89.26374, 40.325343999069041],
            [-89.26939, 40.59432899906767]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "311",
        COUNTYNS: "01383947",
        AFFGEOID: "0500000US48311",
        GEOID: "48311",
        NAME: "McMullen",
        LSAD: "06",
        ALAND: 2952065876,
        AWATER: 43985815
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.800082, 28.202332999213588],
            [-98.800848, 28.647305999205592],
            [-98.335047, 28.648274999205565],
            [-98.335031, 28.612657999206203],
            [-98.334323, 28.057799999216233],
            [-98.803325, 28.057479999216234],
            [-98.800082, 28.202332999213588]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "119",
        COUNTYNS: "01074072",
        AFFGEOID: "0500000US39119",
        GEOID: "39119",
        NAME: "Muskingum",
        LSAD: "06",
        ALAND: 1721328762,
        AWATER: 20656658
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.198728, 39.950195999071106],
            [-82.194786, 40.020693999070708],
            [-82.187105, 40.166879999069891],
            [-81.716276, 40.152165999069979],
            [-81.728611, 39.931727999071221],
            [-81.691104, 39.930755999071216],
            [-81.694146, 39.842635999071732],
            [-81.697442, 39.755571999072245],
            [-82.076639, 39.770959999072147],
            [-82.072934, 39.816226999071887],
            [-82.170047, 39.820749999071857],
            [-82.162385, 39.909370999071356],
            [-82.233974, 39.913259999071322],
            [-82.198728, 39.950195999071106]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "121",
        COUNTYNS: "01074073",
        AFFGEOID: "0500000US39121",
        GEOID: "39121",
        NAME: "Noble",
        LSAD: "06",
        ALAND: 1030846457,
        AWATER: 16984489
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.694146, 39.842635999071732],
            [-81.580313, 39.839103999071753],
            [-81.57859, 39.897682999071414],
            [-81.464108, 39.894549999071423],
            [-81.386006, 39.950693999071099],
            [-81.234053, 39.951269999071108],
            [-81.237316, 39.867827999071579],
            [-81.313591, 39.869470999071588],
            [-81.319125, 39.707359999072523],
            [-81.283075, 39.605795999073145],
            [-81.394934, 39.601546999073165],
            [-81.47316, 39.645975999072895],
            [-81.473471, 39.58331799907328],
            [-81.588185, 39.586972999073268],
            [-81.586132, 39.663992999072789],
            [-81.6432, 39.665680999072784],
            [-81.639886, 39.753419999072257],
            [-81.697442, 39.755571999072245],
            [-81.694146, 39.842635999071732]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "193",
        COUNTYNS: "00485057",
        AFFGEOID: "0500000US20193",
        GEOID: "20193",
        NAME: "Thomas",
        LSAD: "06",
        ALAND: 2783425154,
        AWATER: 288241
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.389068, 39.568368999073378],
            [-100.740873, 39.56804299907337],
            [-100.720213, 39.568033999073371],
            [-100.721296, 39.133380999076167],
            [-100.811858, 39.133359999076156],
            [-101.391717, 39.135116999076146],
            [-101.389068, 39.568368999073378]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "101",
        COUNTYNS: "00465239",
        AFFGEOID: "0500000US19101",
        GEOID: "19101",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 1127999784,
        AWATER: 3524824
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.179974, 41.162661999065001],
            [-91.945571, 41.163577999065005],
            [-91.716042, 41.162807999065002],
            [-91.71818, 40.90108699906618],
            [-92.179072, 40.899719999066178],
            [-92.179974, 41.162661999065001]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "105",
        COUNTYNS: "00198168",
        AFFGEOID: "0500000US08105",
        GEOID: "08105",
        NAME: "Rio Grande",
        LSAD: "06",
        ALAND: 2361956102,
        AWATER: 984295
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.711624, 37.664272999087139],
            [-106.693845, 37.835243999085726],
            [-106.587139, 37.834425999085738],
            [-106.586374, 37.747808999086438],
            [-106.038413, 37.748352999086443],
            [-106.039331, 37.400851999089355],
            [-106.678373, 37.40359599908934],
            [-106.710775, 37.404227999089315],
            [-106.711624, 37.664272999087139]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "125",
        COUNTYNS: "00465251",
        AFFGEOID: "0500000US19125",
        GEOID: "19125",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 1436124182,
        AWATER: 41487001
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.328614, 41.507823999063568],
            [-92.871421, 41.508521999063575],
            [-92.869771, 41.161065999065016],
            [-93.099217, 41.160866999065014],
            [-93.327886, 41.160658999065006],
            [-93.328407, 41.490920999063633],
            [-93.328614, 41.507823999063568]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "381",
        COUNTYNS: "01383976",
        AFFGEOID: "0500000US48381",
        GEOID: "48381",
        NAME: "Randall",
        LSAD: "06",
        ALAND: 2361843607,
        AWATER: 27235708
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.167471, 35.183232999111098],
            [-101.622941, 35.183116999111107],
            [-101.629257, 34.747648999116009],
            [-101.998493, 34.748189999116001],
            [-102.168839, 34.747416999116005],
            [-102.167471, 35.183232999111098]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "149",
        COUNTYNS: "00465263",
        AFFGEOID: "0500000US19149",
        GEOID: "19149",
        NAME: "Plymouth",
        LSAD: "06",
        ALAND: 2234715192,
        AWATER: 2176851
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.621875, 42.779254999059475],
            [-96.577937, 42.827644999059359],
            [-96.537851, 42.878474999059236],
            [-96.540472329193278, 42.908595697015166],
            [-95.859377, 42.909096999059166],
            [-95.859949, 42.560619999060044],
            [-96.480022478980004, 42.561324775620747],
            [-96.526766, 42.641183999059827],
            [-96.591602, 42.688080999059693],
            [-96.624704, 42.725496999059608],
            [-96.621875, 42.779254999059475]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "081",
        COUNTYNS: "00351607",
        AFFGEOID: "0500000US13081",
        GEOID: "13081",
        NAME: "Crisp",
        LSAD: "06",
        ALAND: 705921217,
        AWATER: 21739092
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.961278, 32.030594999151162],
            [-83.609663, 32.027937999151213],
            [-83.612256, 31.854088999153717],
            [-83.612642, 31.804127999154442],
            [-83.802723, 31.803578999154446],
            [-83.845323, 31.831189999154056],
            [-83.939437, 31.847928999153801],
            [-83.922494, 31.909652999152904],
            [-83.950304, 32.015377999151383],
            [-83.961278, 32.030594999151162]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "129",
        COUNTYNS: "00465253",
        AFFGEOID: "0500000US19129",
        GEOID: "19129",
        NAME: "Mills",
        LSAD: "06",
        ALAND: 1132950323,
        AWATER: 8306492
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.868688, 41.12469799906517],
            [-95.861898068209385, 41.160302347277721],
            [-95.384349, 41.160010999065015],
            [-95.384964, 40.901549999066184],
            [-95.795156, 40.901624999066179],
            [-95.818727278569881, 40.897948065472697],
            [-95.837774, 40.924711999066076],
            [-95.828329, 40.972377999065849],
            [-95.865878, 41.017402999065652],
            [-95.8647847640853, 41.052845503678988],
            [-95.863839, 41.083506999065349],
            [-95.868688, 41.12469799906517]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "399",
        COUNTYNS: "01383985",
        AFFGEOID: "0500000US48399",
        GEOID: "48399",
        NAME: "Runnels",
        LSAD: "06",
        ALAND: 2721934030,
        AWATER: 15930084
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.235137, 32.082371999150418],
            [-100.151911, 32.082637999150407],
            [-99.713971, 32.082088999150415],
            [-99.721698, 31.576758999157793],
            [-100.111234, 31.580266999157736],
            [-100.233783, 31.582195999157712],
            [-100.235761, 31.692969999156077],
            [-100.235137, 32.082371999150418]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "227",
        COUNTYNS: "00516960",
        AFFGEOID: "0500000US21227",
        GEOID: "21227",
        NAME: "Warren",
        LSAD: "06",
        ALAND: 1402737947,
        AWATER: 15447169
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.622895, 37.181088999091266],
            [-86.497187, 37.190386999091182],
            [-86.399165, 37.169895999091374],
            [-86.28175, 37.080596999092151],
            [-86.218607, 37.104028999091945],
            [-86.113512, 37.061071999092334],
            [-86.16674, 36.934014999093478],
            [-86.201417, 36.935314999093464],
            [-86.405769, 36.776186999094925],
            [-86.400518, 36.807225999094648],
            [-86.583289, 36.830281999094431],
            [-86.611586, 36.883056999093952],
            [-86.674462, 36.999765999092887],
            [-86.622895, 37.181088999091266]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "103",
        COUNTYNS: "00350219",
        AFFGEOID: "0500000US13103",
        GEOID: "13103",
        NAME: "Effingham",
        LSAD: "06",
        ALAND: 1237281310,
        AWATER: 13464072
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.548006, 32.489285999144684],
            [-81.528169, 32.514887999144335],
            [-81.386901902694191, 32.598964853042958],
            [-81.328753, 32.561227999143682],
            [-81.284238381765604, 32.547110945428884],
            [-81.274927, 32.544157999143913],
            [-81.194829, 32.465085999145018],
            [-81.194931, 32.411488999145767],
            [-81.1734737944861, 32.384902780032832],
            [-81.133032, 32.334793999146839],
            [-81.128034, 32.276296999147661],
            [-81.153531, 32.237686999148217],
            [-81.147595170455375, 32.227169445745453],
            [-81.195449, 32.237590999148225],
            [-81.391698, 32.095885999150227],
            [-81.406776, 32.101930999150134],
            [-81.43583, 32.241288999148161],
            [-81.446334, 32.284716999147548],
            [-81.534904, 32.39357999914602],
            [-81.548006, 32.489285999144684]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "185",
        COUNTYNS: "00465281",
        AFFGEOID: "0500000US19185",
        GEOID: "19185",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 1360940440,
        AWATER: 4347639
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.55654, 40.898294999066188],
            [-93.097595, 40.898450999066192],
            [-93.097291203749805, 40.583823476392809],
            [-93.135802, 40.582853999067716],
            [-93.345442, 40.580513999067726],
            [-93.374386258763806, 40.580397031676931],
            [-93.556896674271684, 40.579659484128229],
            [-93.55654, 40.898294999066188]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "445",
        COUNTYNS: "01384008",
        AFFGEOID: "0500000US48445",
        GEOID: "48445",
        NAME: "Terry",
        LSAD: "06",
        ALAND: 2302083289,
        AWATER: 5443861
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.594836, 33.388488999132626],
            [-102.075929, 33.389585999132599],
            [-102.076214, 32.959701999138268],
            [-102.20852, 32.958955999138269],
            [-102.595023, 32.958830999138272],
            [-102.594836, 33.388488999132626]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "155",
        COUNTYNS: "01074089",
        AFFGEOID: "0500000US39155",
        GEOID: "39155",
        NAME: "Trumbull",
        LSAD: "06",
        ALAND: 1600938408,
        AWATER: 47766218
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.003319, 41.347859999064219],
            [-81.003125, 41.501684999063585],
            [-80.519176750353395, 41.499924050269605],
            [-80.519165169895601, 41.489012189218251],
            [-80.51900013136428, 41.333502143257874],
            [-80.518893, 41.232555999064701],
            [-80.519196690325003, 41.133386501614041],
            [-81.00229, 41.134188999065131],
            [-81.00288, 41.271841999064534],
            [-81.003191, 41.347858999064222],
            [-81.003319, 41.347859999064219]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "035",
        COUNTYNS: "00424219",
        AFFGEOID: "0500000US17035",
        GEOID: "17035",
        NAME: "Cumberland",
        LSAD: "06",
        ALAND: 896203087,
        AWATER: 2531272
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.470906, 39.215028999075621],
            [-88.47083, 39.374514999074592],
            [-88.012121, 39.378967999074561],
            [-88.007766, 39.173924999075879],
            [-88.360654, 39.171117999075896],
            [-88.470865, 39.171462999075899],
            [-88.470906, 39.215028999075621]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "153",
        COUNTYNS: "01671693",
        AFFGEOID: "0500000US13153",
        GEOID: "13153",
        NAME: "Houston",
        LSAD: "06",
        ALAND: 972671487,
        AWATER: 11319233
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.85654, 32.382398999146162],
            [-83.836994, 32.388792999146084],
            [-83.846676, 32.468513999144967],
            [-83.723139, 32.521121999144235],
            [-83.70109, 32.691597999141891],
            [-83.701152, 32.692168999141892],
            [-83.617714, 32.663447999142278],
            [-83.597656, 32.664337999142269],
            [-83.561058, 32.658274999142343],
            [-83.497923, 32.452197999145191],
            [-83.498039, 32.401714999145902],
            [-83.523458, 32.402287999145898],
            [-83.615579, 32.288555999147484],
            [-83.68513, 32.289891999147478],
            [-83.718434, 32.290161999147465],
            [-83.848379, 32.290969999147457],
            [-83.85654, 32.382398999146162]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "463",
        COUNTYNS: "01384017",
        AFFGEOID: "0500000US48463",
        GEOID: "48463",
        NAME: "Uvalde",
        LSAD: "06",
        ALAND: 4019521039,
        AWATER: 17320583
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.112098, 29.623262999188714],
            [-100.014188, 29.623494999188708],
            [-99.60313, 29.627180999188646],
            [-99.411817, 29.627513999188647],
            [-99.41388, 29.091334999197795],
            [-100.111406, 29.086317999197881],
            [-100.112098, 29.623262999188714]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "025",
        COUNTYNS: "01074025",
        AFFGEOID: "0500000US39025",
        GEOID: "39025",
        NAME: "Clermont",
        LSAD: "06",
        ALAND: 1170958177,
        AWATER: 19831170
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.299328, 39.174446999075883],
            [-84.288288, 39.186486999075804],
            [-84.320028, 39.223432999075563],
            [-84.259431, 39.270795999075247],
            [-84.006782, 39.255067999075358],
            [-83.99312, 39.254234999075365],
            [-84.0526461732196, 38.771614666614433],
            [-84.135088, 38.789484999078525],
            [-84.212904, 38.805706999078389],
            [-84.226162633963497, 38.829777140320232],
            [-84.233265, 38.842670999078138],
            [-84.232305045403493, 38.874707895109616],
            [-84.232132, 38.880482999077877],
            [-84.288164, 38.955788999077356],
            [-84.297255, 38.989693999077119],
            [-84.321207226856799, 39.020586351766312],
            [-84.299328, 39.174446999075883]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "483",
        COUNTYNS: "01384027",
        AFFGEOID: "0500000US48483",
        GEOID: "48483",
        NAME: "Wheeler",
        LSAD: "06",
        ALAND: 2368603585,
        AWATER: 2481258
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.540158, 35.619295999106399],
            [-100.000392, 35.619114999106415],
            [-100.000388844143004, 35.422363987417903],
            [-100.000385, 35.182701999111117],
            [-100.538978, 35.18314399911111],
            [-100.540158, 35.619295999106399]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "231",
        COUNTYNS: "00346482",
        AFFGEOID: "0500000US13231",
        GEOID: "13231",
        NAME: "Pike",
        LSAD: "06",
        ALAND: 559659548,
        AWATER: 8596853
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.536991, 33.036729999137243],
            [-84.496783, 33.183865999135286],
            [-84.451336, 33.208888999134956],
            [-84.248185, 33.189018999135222],
            [-84.248659, 33.039329999137209],
            [-84.269169, 33.031437999137303],
            [-84.27014, 32.991010999137842],
            [-84.490015, 32.993486999137822],
            [-84.52702, 32.970547999138127],
            [-84.536991, 33.036729999137243]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "027",
        COUNTYNS: "01074026",
        AFFGEOID: "0500000US39027",
        GEOID: "39027",
        NAME: "Clinton",
        LSAD: "06",
        ALAND: 1058487159,
        AWATER: 9351754
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.006782, 39.255067999075358],
            [-83.977005, 39.569168999073362],
            [-83.670196, 39.550253999073476],
            [-83.585523, 39.545108999073513],
            [-83.578194, 39.52403299907364],
            [-83.590878, 39.378735999074564],
            [-83.784793, 39.262887999075303],
            [-83.813873, 39.223043999075571],
            [-83.865679, 39.247332999075411],
            [-83.99312, 39.254234999075365],
            [-84.006782, 39.255067999075358]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "117",
        COUNTYNS: "01673358",
        AFFGEOID: "0500000US13117",
        GEOID: "13117",
        NAME: "Forsyth",
        LSAD: "06",
        ALAND: 580598432,
        AWATER: 59439960
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.258743, 34.185908999122631],
            [-84.258689, 34.23709399912201],
            [-84.258075, 34.335155999120836],
            [-83.957077, 34.334010999120849],
            [-83.925575, 34.285269999121439],
            [-83.989059, 34.195731999122508],
            [-84.057632, 34.183276999122668],
            [-84.062841, 34.167872999122835],
            [-84.117927, 34.065563999124095],
            [-84.097693, 34.05070799912427],
            [-84.258934, 34.109538999123558],
            [-84.258743, 34.185908999122631]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "113",
        COUNTYNS: "01687740",
        AFFGEOID: "0500000US13113",
        GEOID: "13113",
        NAME: "Fayette",
        LSAD: "06",
        ALAND: 503420105,
        AWATER: 12825183
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.60954, 33.502510999131147],
            [-84.556857, 33.528404999130814],
            [-84.458665, 33.550932999130531],
            [-84.438151, 33.548173999130562],
            [-84.397479, 33.485737999131352],
            [-84.381814, 33.463466999131647],
            [-84.388118, 33.352464999133076],
            [-84.432907, 33.256499999134334],
            [-84.497527, 33.257421999134316],
            [-84.62713, 33.43982099913196],
            [-84.60954, 33.502510999131147]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "177",
        COUNTYNS: "00343390",
        AFFGEOID: "0500000US13177",
        GEOID: "13177",
        NAME: "Lee",
        LSAD: "06",
        ALAND: 921722888,
        AWATER: 15318763
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.338143, 31.916189999152817],
            [-83.922494, 31.909652999152904],
            [-83.939437, 31.847928999153801],
            [-84.008445, 31.801644999154483],
            [-84.018404, 31.650273999156699],
            [-84.043213, 31.623572999157094],
            [-84.297801, 31.621950999157118],
            [-84.286456, 31.791181999154638],
            [-84.259611, 31.827864999154095],
            [-84.338245, 31.873590999153429],
            [-84.338143, 31.916189999152817]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "303",
        COUNTYNS: "01383937",
        AFFGEOID: "0500000US48303",
        GEOID: "48303",
        NAME: "Lubbock",
        LSAD: "06",
        ALAND: 2319602826,
        AWATER: 13205756
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.085733, 33.824674999127076],
            [-101.56358, 33.830450999126995],
            [-101.556884, 33.394759999132532],
            [-102.075929, 33.389585999132599],
            [-102.085733, 33.824674999127076]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "285",
        COUNTYNS: "01383928",
        AFFGEOID: "0500000US48285",
        GEOID: "48285",
        NAME: "Lavaca",
        LSAD: "06",
        ALAND: 2511535250,
        AWATER: 1882216
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.204087, 29.580612999189427],
            [-97.142643, 29.628100999188632],
            [-96.874222, 29.632705999188552],
            [-96.793132, 29.577377999189487],
            [-96.560555, 29.335438999193588],
            [-96.658466, 29.263875999194823],
            [-96.938587, 29.063243999198271],
            [-96.976378, 29.104045999197581],
            [-97.240108, 29.384487999192757],
            [-97.204087, 29.580612999189427]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "321",
        COUNTYNS: "01383943",
        AFFGEOID: "0500000US48321",
        GEOID: "48321",
        NAME: "Matagorda",
        LSAD: "06",
        ALAND: 2830586532,
        AWATER: 1345801461
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.378533159286789, 28.38986502969809],
            [-96.322731, 28.642943999205663],
            [-96.323522, 28.67559699920508],
            [-96.309178, 28.963290999200009],
            [-95.965196, 29.146864999196829],
            [-95.874028, 29.229701999195402],
            [-95.84631, 29.107798999197517],
            [-95.770446, 29.066209999198225],
            [-95.769393, 28.971108999199888],
            [-95.67955, 28.965733999199976],
            [-95.567708, 28.829757999202357],
            [-95.507037396916999, 28.824735511394543],
            [-95.588801, 28.78316899920317],
            [-95.684089, 28.734040999204051],
            [-95.812504, 28.664941999205272],
            [-96.000682, 28.588237999206633],
            [-96.194412, 28.502223999208173],
            [-96.328817, 28.423658999209582],
            [-96.378533159286789, 28.38986502969809]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "025",
        COUNTYNS: "00063760",
        AFFGEOID: "0500000US05025",
        GEOID: "05025",
        NAME: "Cleveland",
        LSAD: "06",
        ALAND: 1548252545,
        AWATER: 2500346
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.471667, 34.0570539991242],
            [-92.336765, 34.059632999124162],
            [-92.233376, 34.062311999124134],
            [-91.953799, 34.064140999124106],
            [-91.976439, 33.977296999125187],
            [-91.975974, 33.791786999127488],
            [-91.975844, 33.704413999128569],
            [-92.330839, 33.707805999128531],
            [-92.341845, 33.742895999128095],
            [-92.334249, 33.795064999127447],
            [-92.337389, 33.828129999127022],
            [-92.45613, 33.972621999125238],
            [-92.471667, 34.0570539991242]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "093",
        COUNTYNS: "00659492",
        AFFGEOID: "0500000US27093",
        GEOID: "27093",
        NAME: "Meeker",
        LSAD: "06",
        ALAND: 1575108557,
        AWATER: 94852453
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.76308, 45.326099999056858],
            [-94.640096, 45.325600999056839],
            [-94.383744, 45.326726999056838],
            [-94.383449, 45.282796999056828],
            [-94.260821, 45.283940999056824],
            [-94.256681, 45.239271999056804],
            [-94.256074, 44.979464999056766],
            [-94.501885, 44.979694999056768],
            [-94.502395, 44.892294999056752],
            [-94.758189, 44.892096999056761],
            [-94.76308, 45.326099999056858]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "071",
        COUNTYNS: "01074048",
        AFFGEOID: "0500000US39071",
        GEOID: "39071",
        NAME: "Highland",
        LSAD: "06",
        ALAND: 1432479993,
        AWATER: 12194983
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.865679, 39.247332999075411],
            [-83.813873, 39.223043999075571],
            [-83.784793, 39.262887999075303],
            [-83.590878, 39.378735999074564],
            [-83.372714, 39.377415999074572],
            [-83.386057, 39.319689999074939],
            [-83.393794, 39.268047999075286],
            [-83.353531, 39.19758499907573],
            [-83.385637, 39.055196999076678],
            [-83.611592, 39.018889999076933],
            [-83.673018, 39.02042999907691],
            [-83.872214, 39.021303999076913],
            [-83.865679, 39.247332999075411]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "091",
        COUNTYNS: "00198161",
        AFFGEOID: "0500000US08091",
        GEOID: "08091",
        NAME: "Ouray",
        LSAD: "06",
        ALAND: 1402719352,
        AWATER: 1599543
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-108.13238, 38.33157299908185],
            [-107.63504, 38.301895999082078],
            [-107.580243, 38.230823999082617],
            [-107.56862, 38.147266999083257],
            [-107.51088, 38.060876999083924],
            [-107.568875, 37.965014999084694],
            [-107.641588, 37.965008999084695],
            [-107.695665, 37.892409999085267],
            [-107.738283, 37.90543199908516],
            [-107.795469, 37.988519999084502],
            [-107.889716, 38.011614999084323],
            [-107.891469, 38.115956999083501],
            [-107.965789, 38.152327999083205],
            [-107.937588, 38.218991999082711],
            [-108.086604, 38.255201999082416],
            [-108.13238, 38.33157299908185]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "397",
        COUNTYNS: "01383984",
        AFFGEOID: "0500000US48397",
        GEOID: "48397",
        NAME: "Rockwall",
        LSAD: "06",
        ALAND: 329205133,
        AWATER: 56076769
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.516866, 32.982307999137944],
            [-96.466726, 32.981999999137962],
            [-96.297227, 32.981751999137977],
            [-96.297322, 32.841722999139861],
            [-96.297376, 32.814184999140224],
            [-96.51897, 32.813615999140232],
            [-96.516866, 32.982307999137944]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "005",
        COUNTYNS: "01074016",
        AFFGEOID: "0500000US39005",
        GEOID: "39005",
        NAME: "Ashland",
        LSAD: "06",
        ALAND: 1095444134,
        AWATER: 9962880
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.436916, 41.065378999065437],
            [-82.336496, 41.065760999065425],
            [-82.171492, 41.063536999065441],
            [-82.173359, 40.992045999065759],
            [-82.129334, 40.991806999065759],
            [-82.126199, 40.668228999067281],
            [-82.220665, 40.667579999067286],
            [-82.22011, 40.568205999067793],
            [-82.336962, 40.555000999067843],
            [-82.338483, 40.638501999067437],
            [-82.339211, 40.726682999067009],
            [-82.420752, 40.823329999066544],
            [-82.432852, 40.992943999065758],
            [-82.436916, 41.065378999065437]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "027",
        COUNTYNS: "00399406",
        AFFGEOID: "0500000US16027",
        GEOID: "16027",
        NAME: "Canyon",
        LSAD: "06",
        ALAND: 1520439790,
        AWATER: 43128953
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.023577, 43.8238109990575],
            [-116.985545038489988, 43.881184851871211],
            [-116.95452, 43.836486999057485],
            [-116.853154, 43.792709999057536],
            [-116.712672, 43.80716099905753],
            [-116.512502, 43.80716099905753],
            [-116.513123, 43.634182999057764],
            [-116.473597, 43.459217999058048],
            [-116.511897, 43.290519999058361],
            [-116.579167, 43.306177999058306],
            [-116.622569, 43.370008999058193],
            [-116.781336, 43.478632999058014],
            [-116.844978, 43.594399999057828],
            [-116.911538, 43.603075999057808],
            [-117.025663810446019, 43.680293635011687],
            [-117.023577, 43.8238109990575]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "429",
        COUNTYNS: "01384000",
        AFFGEOID: "0500000US48429",
        GEOID: "48429",
        NAME: "Stephens",
        LSAD: "06",
        ALAND: 2322492638,
        AWATER: 64152295
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.095956, 32.707580999141669],
            [-99.096016, 32.957036999138296],
            [-98.950875, 32.956917999138305],
            [-98.575616, 32.954348999138332],
            [-98.576023, 32.733833999141318],
            [-98.576256, 32.515162999144337],
            [-99.09605, 32.514767999144318],
            [-99.095956, 32.707580999141669]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "005",
        COUNTYNS: "00835825",
        AFFGEOID: "0500000US31005",
        GEOID: "31005",
        NAME: "Arthur",
        LSAD: "06",
        ALAND: 1852759732,
        AWATER: 7644719
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.985523, 41.742289999062685],
            [-101.42592, 41.743058999062661],
            [-101.406466, 41.743199999062689],
            [-101.40726, 41.395415999064014],
            [-101.98463, 41.394711999064029],
            [-101.985523, 41.742289999062685]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "147",
        COUNTYNS: "00695794",
        AFFGEOID: "0500000US28147",
        GEOID: "28147",
        NAME: "Walthall",
        LSAD: "06",
        ALAND: 1046169273,
        AWATER: 1236552
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.260391, 31.350273999161178],
            [-90.24389, 31.350273999161178],
            [-90.108651, 31.350868999161165],
            [-90.040466, 31.336013999161395],
            [-90.031896, 31.175726999163825],
            [-89.938178, 31.132948999164476],
            [-89.920944, 31.074676999165366],
            [-89.835873, 31.074925999165369],
            [-89.835907824328189, 31.002101422284088],
            [-89.897516, 31.001912999166482],
            [-90.259554940491299, 31.000661780395607],
            [-90.260391, 31.350273999161178]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "159",
        COUNTYNS: "00758534",
        AFFGEOID: "0500000US29159",
        GEOID: "29159",
        NAME: "Pettis",
        LSAD: "06",
        ALAND: 1766949637,
        AWATER: 10503983
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.51103, 38.556209999080181],
            [-93.49311, 38.899015999077747],
            [-93.497278, 38.928429999077544],
            [-93.496377, 38.942836999077436],
            [-93.050453, 38.928243999077544],
            [-93.059974, 38.693076999079182],
            [-93.067291, 38.529994999080373],
            [-93.290454, 38.535387999080321],
            [-93.291851, 38.50632099908055],
            [-93.512743, 38.512475999080507],
            [-93.51103, 38.556209999080181]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "121",
        COUNTYNS: "00659506",
        AFFGEOID: "0500000US27121",
        GEOID: "27121",
        NAME: "Pope",
        LSAD: "06",
        ALAND: 1734134611,
        AWATER: 123231111
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.758508, 45.759931999057137],
            [-95.13967, 45.758890999057137],
            [-95.13173, 45.41240199905689],
            [-95.255206, 45.41252499905687],
            [-95.747402, 45.41231299905688],
            [-95.758508, 45.759931999057137]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "363",
        COUNTYNS: "01383967",
        AFFGEOID: "0500000US48363",
        GEOID: "48363",
        NAME: "Palo Pinto",
        LSAD: "06",
        ALAND: 2466313772,
        AWATER: 86141064
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.576023, 32.733833999141318],
            [-98.575616, 32.954348999138332],
            [-98.427211, 32.952299999138361],
            [-98.426553, 33.007912999137609],
            [-98.056094, 33.003331999137686],
            [-98.066836, 32.558821999143731],
            [-98.068545, 32.511625999144371],
            [-98.475177, 32.513038999144356],
            [-98.576256, 32.515162999144337],
            [-98.576023, 32.733833999141318]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "307",
        COUNTYNS: "00352287",
        AFFGEOID: "0500000US13307",
        GEOID: "13307",
        NAME: "Webster",
        LSAD: "06",
        ALAND: 541613279,
        AWATER: 2965865
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.63112, 32.184053999148958],
            [-84.649319, 32.232952999148281],
            [-84.527117, 32.134555999149676],
            [-84.431214, 32.134057999149675],
            [-84.433014, 32.041959999150997],
            [-84.443807, 31.967455999152069],
            [-84.45331, 31.919223999152774],
            [-84.599776, 31.920170999152763],
            [-84.65582, 31.920307999152758],
            [-84.63112, 32.184053999148958]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "115",
        COUNTYNS: "01101845",
        AFFGEOID: "0500000US40115",
        GEOID: "40115",
        NAME: "Ottawa",
        LSAD: "06",
        ALAND: 1219467497,
        AWATER: 35708902
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.007620004472088, 36.999522558218693],
            [-94.995293, 36.999528999092888],
            [-94.71277, 36.998793999092896],
            [-94.617964, 36.9989049990929],
            [-94.618307, 36.766559999095023],
            [-94.617991778417192, 36.667921271440157],
            [-94.999403, 36.670630999095927],
            [-94.999153, 36.943237999093405],
            [-95.034123, 36.994224999092935],
            [-95.007620004472088, 36.999522558218693]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "189",
        COUNTYNS: "00348794",
        AFFGEOID: "0500000US13189",
        GEOID: "13189",
        NAME: "McDuffie",
        LSAD: "06",
        ALAND: 666813913,
        AWATER: 23119020
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.649705, 33.608767999129796],
            [-82.507223, 33.659993999129142],
            [-82.479518, 33.639534999129388],
            [-82.425328, 33.650628999129253],
            [-82.437081, 33.550537999130533],
            [-82.294179, 33.354634999133054],
            [-82.353498, 33.312317999133604],
            [-82.383829, 33.312105999133614],
            [-82.548074, 33.352797999133074],
            [-82.649705, 33.608767999129796]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "175",
        COUNTYNS: "00346568",
        AFFGEOID: "0500000US13175",
        GEOID: "13175",
        NAME: "Laurens",
        LSAD: "06",
        ALAND: 2090842776,
        AWATER: 28985734
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.226536, 32.584200999143363],
            [-82.957066, 32.708317999141663],
            [-82.862763, 32.715759999141554],
            [-82.794538, 32.660229999142317],
            [-82.668557, 32.612163999142979],
            [-82.647733, 32.512506999144364],
            [-82.748732, 32.329149999146914],
            [-82.721964, 32.309282999147207],
            [-82.755158, 32.269689999147758],
            [-82.773916, 32.265361999147821],
            [-82.884803, 32.196071999148799],
            [-82.990967, 32.14727399914949],
            [-83.138991, 32.423068999145606],
            [-83.226536, 32.584200999143363]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "083",
        COUNTYNS: "01581102",
        AFFGEOID: "0500000US55083",
        GEOID: "55083",
        NAME: "Oconto",
        LSAD: "06",
        ALAND: 2583464874,
        AWATER: 392038134
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.677996, 45.378682999056863],
            [-88.428102, 45.377009999056867],
            [-88.305811, 45.374609999056865],
            [-88.309063, 45.201579999056797],
            [-88.186355, 45.197973999056799],
            [-88.189247, 45.111843999056774],
            [-88.118879, 45.110196999056775],
            [-88.121152, 45.023580999056769],
            [-87.942995, 45.020417999056775],
            [-87.760071, 44.990529999056768],
            [-87.762638490971099, 44.962752170817055],
            [-87.812989, 44.954012999056758],
            [-87.843433, 44.924354999056767],
            [-87.838359, 44.873986999056768],
            [-87.854681, 44.857770999056775],
            [-87.904484, 44.818722999056767],
            [-87.941453, 44.756079999056787],
            [-87.983494, 44.720195999056799],
            [-87.997573406442498, 44.67766393810161],
            [-88.220384, 44.672379999056808],
            [-88.242687, 44.679630999056805],
            [-88.248213, 44.852738999056768],
            [-88.489149, 44.855444999056765],
            [-88.483732, 45.116886999056781],
            [-88.641159, 45.117345999056774],
            [-88.680066, 45.204993999056796],
            [-88.677996, 45.378682999056863]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "125",
        COUNTYNS: "01581122",
        AFFGEOID: "0500000US55125",
        GEOID: "55125",
        NAME: "Vilas",
        LSAD: "06",
        ALAND: 2221447085,
        AWATER: 414426793
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.043958, 45.981947999057375],
            [-89.928837, 45.98196699905737],
            [-89.929125625664796, 46.299915727465901],
            [-89.638416, 46.243803999057725],
            [-89.09163, 46.138504999057581],
            [-88.991217028396008, 46.096536353493512],
            [-88.932768451009593, 46.072107162833483],
            [-88.933222, 45.982275999057364],
            [-89.047795, 45.982344999057368],
            [-89.047601, 45.895354999057268],
            [-89.17785, 45.899311999057275],
            [-89.175245, 45.856578999057234],
            [-89.300473, 45.90146299905728],
            [-90.042857, 45.897262999057276],
            [-90.043958, 45.981947999057375]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "129",
        COUNTYNS: "01581124",
        AFFGEOID: "0500000US55129",
        GEOID: "55129",
        NAME: "Washburn",
        LSAD: "06",
        ALAND: 2064513622,
        AWATER: 145566159
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.049636, 46.157596999057589],
            [-91.551282, 46.157044999057597],
            [-91.540294, 45.637604999057032],
            [-92.031417, 45.639927999057036],
            [-92.033404, 45.983869999057376],
            [-92.049636, 46.157596999057589]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "031",
        COUNTYNS: "00293656",
        AFFGEOID: "0500000US12031",
        GEOID: "12031",
        NAME: "Duval",
        LSAD: "06",
        ALAND: 1975764931,
        AWATER: 403035529
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.049236, 30.273431999177994],
            [-82.037542, 30.283456999177822],
            [-81.831227, 30.467436999174872],
            [-81.746923, 30.530818999173867],
            [-81.721507, 30.571851999173209],
            [-81.606158, 30.585565999172989],
            [-81.610212, 30.554379999173484],
            [-81.543018, 30.52388899917397],
            [-81.499575, 30.563792999173341],
            [-81.428954737643295, 30.506183073339461],
            [-81.42601, 30.496738999174408],
            [-81.410809, 30.482038999174641],
            [-81.396407, 30.340039999176923],
            [-81.3743761280233, 30.252930999178322],
            [-81.436929, 30.252334999178331],
            [-81.433783, 30.10553599918072],
            [-81.680215, 30.121239999180467],
            [-81.679626, 30.190494999179336],
            [-81.711348, 30.191192999179322],
            [-81.738924, 30.189320999179351],
            [-82.049411, 30.186932999179394],
            [-82.049236, 30.273431999177994]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "129",
        COUNTYNS: "01074077",
        AFFGEOID: "0500000US39129",
        GEOID: "39129",
        NAME: "Pickaway",
        LSAD: "06",
        ALAND: 1297966523,
        AWATER: 13998877
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.252435, 39.695437999072603],
            [-83.243702, 39.81250299907191],
            [-82.824254, 39.794995999072],
            [-82.842953, 39.561479999073427],
            [-82.731518, 39.554444999073461],
            [-82.740729, 39.468349999074],
            [-82.998204, 39.48006099907392],
            [-82.98456, 39.506753999073759],
            [-83.266737, 39.516248999073696],
            [-83.252435, 39.695437999072603]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "131",
        COUNTYNS: "01074078",
        AFFGEOID: "0500000US39131",
        GEOID: "39131",
        NAME: "Pike",
        LSAD: "06",
        ALAND: 1140324458,
        AWATER: 9567612
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.353531, 39.19758499907573],
            [-82.785891, 39.168768999075915],
            [-82.80709, 38.948072999077411],
            [-83.04362, 38.956938999077344],
            [-83.212107, 38.960147999077329],
            [-83.270817, 39.015790999076948],
            [-83.314589, 39.052194999076697],
            [-83.385637, 39.055196999076678],
            [-83.353531, 39.19758499907573]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "095",
        COUNTYNS: "01267177",
        AFFGEOID: "0500000US46095",
        GEOID: "46095",
        NAME: "Mellette",
        LSAD: "06",
        ALAND: 3385957220,
        AWATER: 9219640
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.217768, 43.803846999057519],
            [-101.064326, 43.841089999057466],
            [-100.930768, 43.831158999057479],
            [-100.841307, 43.760177999057575],
            [-100.795732, 43.754054999057587],
            [-100.789635, 43.725437999057611],
            [-100.735003, 43.707620999057653],
            [-100.587903, 43.769284999057554],
            [-100.33897, 43.716941999057632],
            [-100.280028, 43.696794999057666],
            [-100.230845, 43.713855999057643],
            [-100.230991, 43.477980999058012],
            [-100.214221, 43.390320999058162],
            [-100.252092, 43.390401999058163],
            [-101.228234, 43.389184999058173],
            [-101.217768, 43.803846999057519]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "119",
        COUNTYNS: "01266982",
        AFFGEOID: "0500000US46119",
        GEOID: "46119",
        NAME: "Sully",
        LSAD: "06",
        ALAND: 2607664601,
        AWATER: 164384696
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.676387, 44.823161999056772],
            [-100.609036, 44.769427999056788],
            [-100.529636, 44.766300999056774],
            [-100.418682, 44.843365999056772],
            [-100.404753, 44.89769499905676],
            [-99.67305, 44.897491999056761],
            [-99.676398, 44.548110999056853],
            [-100.526498, 44.547421999056866],
            [-100.620897, 44.566697999056849],
            [-100.613805, 44.707134999056798],
            [-100.717644, 44.770929999056769],
            [-100.676387, 44.823161999056772]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "049",
        COUNTYNS: "01074037",
        AFFGEOID: "0500000US39049",
        GEOID: "39049",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1378853105,
        AWATER: 29125601
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.255051, 40.048613999070554],
            [-83.210587, 40.065276999070448],
            [-83.206273, 40.107732999070215],
            [-83.169981, 40.143086999070029],
            [-83.005204, 40.136335999070056],
            [-82.971307, 40.134932999070067],
            [-82.761827, 40.125854999070114],
            [-82.767483, 40.051027999070541],
            [-82.773825, 39.976124999070947],
            [-82.782495, 39.939661999071156],
            [-82.810797, 39.941161999071156],
            [-82.799213, 39.859683999071628],
            [-82.802476, 39.822952999071845],
            [-82.824254, 39.794995999072],
            [-83.243702, 39.81250299907191],
            [-83.255051, 40.048613999070554]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "199",
        COUNTYNS: "00758552",
        AFFGEOID: "0500000US29199",
        GEOID: "29199",
        NAME: "Scotland",
        LSAD: "06",
        ALAND: 1130718763,
        AWATER: 7047471
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.350804186726393, 40.597257275443738],
            [-92.17978, 40.600528999067627],
            [-91.943117459685695, 40.606060585421602],
            [-91.949747, 40.300831999069167],
            [-92.347801, 40.302755999069156],
            [-92.349975, 40.34650499906892],
            [-92.350804186726393, 40.597257275443738]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "103",
        COUNTYNS: "01026338",
        AFFGEOID: "0500000US37103",
        GEOID: "37103",
        NAME: "Jones",
        LSAD: "06",
        ALAND: 1220837016,
        AWATER: 6031827
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.731026, 35.008137999113046],
            [-77.601282, 35.07136799911234],
            [-77.507463, 35.172813999111206],
            [-77.527182, 35.24300099911045],
            [-77.473692, 35.228984999110601],
            [-77.187679, 35.081085999112233],
            [-77.109656, 35.068608999112364],
            [-77.108851, 34.830779999115045],
            [-77.078263, 34.811321999115279],
            [-77.161676, 34.795074999115464],
            [-77.165986, 34.785031999115581],
            [-77.267647, 34.925789999113974],
            [-77.366768, 34.908649999114175],
            [-77.502771, 34.969634999113481],
            [-77.674805, 34.973899999113435],
            [-77.731026, 35.008137999113046]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "029",
        COUNTYNS: "01383800",
        AFFGEOID: "0500000US48029",
        GEOID: "48029",
        NAME: "Bexar",
        LSAD: "06",
        ALAND: 3211308851,
        AWATER: 41911545
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.806552, 29.690708999187574],
            [-98.778782, 29.720166999187089],
            [-98.738381, 29.716542999187141],
            [-98.646124, 29.745180999186665],
            [-98.550489, 29.760712999186413],
            [-98.445448, 29.735635999186822],
            [-98.443852, 29.719649999187098],
            [-98.352589, 29.734364999186848],
            [-98.378068, 29.662612999188056],
            [-98.328651, 29.608232999188971],
            [-98.31095, 29.594559999189201],
            [-98.310928, 29.594472999189193],
            [-98.27371, 29.551212999189918],
            [-98.233899, 29.554892999189864],
            [-98.164638, 29.478090999191167],
            [-98.122555, 29.477969999191163],
            [-98.134171, 29.441750999191779],
            [-98.407336, 29.114434999197393],
            [-98.441548, 29.126126999197194],
            [-98.804763, 29.250692999195042],
            [-98.806552, 29.690708999187574]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "281",
        COUNTYNS: "01383927",
        AFFGEOID: "0500000US48281",
        GEOID: "48281",
        NAME: "Lampasas",
        LSAD: "06",
        ALAND: 1846250633,
        AWATER: 2833490
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.562739, 31.230581999162975],
            [-98.281557, 31.401450999160403],
            [-98.27107, 31.416397999160179],
            [-98.180006, 31.463716999159473],
            [-97.9071, 31.069373999165453],
            [-97.911684, 31.034918999165981],
            [-98.439687, 31.029536999166069],
            [-98.548051, 31.123473999164627],
            [-98.510341, 31.155665999164125],
            [-98.562739, 31.230581999162975]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "121",
        COUNTYNS: "00295729",
        AFFGEOID: "0500000US12121",
        GEOID: "12121",
        NAME: "Suwannee",
        LSAD: "06",
        ALAND: 1783341106,
        AWATER: 9594316
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.247253, 30.260669999178191],
            [-83.192343, 30.374845999176351],
            [-83.170969, 30.385224999176202],
            [-83.082017, 30.442864999175271],
            [-83.002058, 30.429370999175493],
            [-82.794594, 30.337023999176964],
            [-82.793975, 29.986201999182672],
            [-82.761519, 29.965516999183006],
            [-82.800477, 29.932125999183576],
            [-82.863266, 29.911049999183913],
            [-82.879802, 29.886846999184314],
            [-82.963112, 29.993049999182563],
            [-83.160557, 30.109822999180647],
            [-83.225659, 30.113320999180591],
            [-83.247253, 30.260669999178191]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "031",
        COUNTYNS: "01531936",
        AFFGEOID: "0500000US53031",
        GEOID: "53031",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 4671790710,
        AWATER: 994835543
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.613108656052006, 47.880572440810298],
            [-123.505917, 47.866896999061645],
            [-122.949167, 47.866821999061635],
            [-122.94918, 48.065149999062321],
            [-122.896971, 48.065154999062322],
            [-122.910686755724981, 48.109799742995285],
            [-122.833173, 48.134405999062579],
            [-122.760448, 48.143239999062608],
            [-122.698465, 48.103101999062467],
            [-122.70184, 48.016105999062162],
            [-122.701294, 47.972978999061993],
            [-122.651063, 47.920984999061808],
            [-122.634102865279985, 47.923035298800635],
            [-122.595535, 47.882297999061706],
            [-122.721971, 47.787664999061384],
            [-122.783237, 47.672753999061015],
            [-122.950621, 47.60630799906081],
            [-123.505917, 47.6046149990608],
            [-123.505917, 47.517715999060549],
            [-124.04674, 47.518524999060546],
            [-124.353615670886981, 47.533537082112694],
            [-124.355955, 47.545697999060621],
            [-124.412106, 47.691198999061065],
            [-124.471687, 47.766906999061305],
            [-124.539927, 47.836966999061552],
            [-124.613108656052006, 47.880572440810298]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "019",
        COUNTYNS: "00326638",
        AFFGEOID: "0500000US13019",
        GEOID: "13019",
        NAME: "Berrien",
        LSAD: "06",
        ALAND: 1170501546,
        AWATER: 15409110
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.370888, 31.401141999160409],
            [-83.369514, 31.465054999159456],
            [-83.338728, 31.475990999159283],
            [-83.145587, 31.472275999159347],
            [-83.140483, 31.420394999160131],
            [-83.033468, 31.275040999162307],
            [-83.046885, 31.183679999163701],
            [-83.036369, 31.151037999164199],
            [-83.165072, 31.147197999164263],
            [-83.197971, 31.025404999166131],
            [-83.295052, 31.027277999166099],
            [-83.279781, 31.063350999165532],
            [-83.320471, 31.097961999165015],
            [-83.335949, 31.244578999162773],
            [-83.434492, 31.350360999161179],
            [-83.370888, 31.401141999160409]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "041",
        COUNTYNS: "01605084",
        AFFGEOID: "0500000US56041",
        GEOID: "56041",
        NAME: "Uinta",
        LSAD: "06",
        ALAND: 5391911952,
        AWATER: 16345637
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.046636983126007, 41.251626955316027],
            [-111.0466, 41.360691999064166],
            [-111.045789, 41.565570999063354],
            [-111.045818464842, 41.579844947289082],
            [-110.084778, 41.5776149990633],
            [-110.048, 41.578014999063299],
            [-110.048482995685006, 40.997297291916333],
            [-110.121639, 40.997100999065736],
            [-110.539819, 40.996345999065746],
            [-111.046723, 40.997958999065744],
            [-111.046636983126007, 41.251626955316027]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "065",
        COUNTYNS: "00351285",
        AFFGEOID: "0500000US13065",
        GEOID: "13065",
        NAME: "Clinch",
        LSAD: "06",
        ALAND: 2072566890,
        AWATER: 61565560
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.971247, 31.183987999163698],
            [-82.671669, 31.183738999163698],
            [-82.592071, 31.018486999166232],
            [-82.490558, 30.963165999167082],
            [-82.495476, 30.819552999169314],
            [-82.435852, 30.820067999169307],
            [-82.418984112178492, 30.580916668904568],
            [-82.459581, 30.584263999173015],
            [-82.459792435413675, 30.584276527479215],
            [-82.584005186419489, 30.591636578260498],
            [-82.581202, 30.696236999171248],
            [-82.766159, 30.732403999170685],
            [-82.846353, 30.834987999169073],
            [-82.971336, 30.869391999168535],
            [-82.971247, 31.183987999163698]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "093",
        COUNTYNS: "00161573",
        AFFGEOID: "0500000US01093",
        GEOID: "01093",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 1922656984,
        AWATER: 3183684
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.203583495219377, 34.086527730591932],
            [-88.173261734594902, 34.321039533455298],
            [-87.634725, 34.306996999121175],
            [-87.636118, 34.002202999124883],
            [-87.635932, 33.915250999125931],
            [-87.739347, 33.914730999125958],
            [-87.951785, 33.919929999125884],
            [-87.951929, 34.022201999124626],
            [-87.98693, 34.052101999124254],
            [-88.207229, 34.058332999124183],
            [-88.203583495219377, 34.086527730591932]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "093",
        COUNTYNS: "01689423",
        AFFGEOID: "0500000US54093",
        GEOID: "54093",
        NAME: "Tucker",
        LSAD: "06",
        ALAND: 1085094885,
        AWATER: 5528932
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.838093, 39.131001999076169],
            [-79.809729, 39.23052599907551],
            [-79.687283, 39.271397999075241],
            [-79.487175, 39.19490599907575],
            [-79.306461, 39.209202999075643],
            [-79.355468, 39.086507999076467],
            [-79.298164, 39.073209999076553],
            [-79.357666, 38.964508999077296],
            [-79.508765, 38.975172999077223],
            [-79.785685, 39.037403999076794],
            [-79.825092, 39.11580299907628],
            [-79.838093, 39.131001999076169]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "580",
        COUNTYNS: "01789069",
        AFFGEOID: "0500000US51580",
        GEOID: "51580",
        NAME: "Covington",
        LSAD: "25",
        ALAND: 14163308,
        AWATER: 522024
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.00136, 37.789825999086105],
            [-79.987402, 37.813584999085911],
            [-79.967071, 37.803404999085984],
            [-79.978214, 37.764974999086299],
            [-79.966509, 37.75710899908637],
            [-80.003265, 37.756566999086367],
            [-80.00136, 37.789825999086105]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "035",
        COUNTYNS: "01639736",
        AFFGEOID: "0500000US47035",
        GEOID: "47035",
        NAME: "Cumberland",
        LSAD: "06",
        ALAND: 1763849850,
        AWATER: 9823728
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.269359, 35.793387999104588],
            [-85.213115, 35.910378999103379],
            [-85.242698, 35.913039999103354],
            [-85.264206, 35.979153999102685],
            [-85.246362, 36.100697999101449],
            [-85.15275, 36.099543999101471],
            [-85.100213, 36.138560999101067],
            [-85.029461, 36.170432999100747],
            [-84.907753, 36.156292999100899],
            [-84.80546, 36.09050099910155],
            [-84.720727, 35.994913999102522],
            [-84.680633, 35.908453999103408],
            [-84.729634, 35.858381999103912],
            [-84.781901, 35.824999999104264],
            [-84.80163, 35.823065999104273],
            [-84.916062, 35.761938999104906],
            [-84.928536, 35.768548999104837],
            [-85.254062, 35.765610999104865],
            [-85.269359, 35.793387999104588]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "171",
        COUNTYNS: "00424286",
        AFFGEOID: "0500000US17171",
        GEOID: "17171",
        NAME: "Scott",
        LSAD: "06",
        ALAND: 649861236,
        AWATER: 4812706
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.599105, 39.790059999072035],
            [-90.483063, 39.789239999072045],
            [-90.483385, 39.75343399907225],
            [-90.371253, 39.75338799907226],
            [-90.372969, 39.665780999072787],
            [-90.300666, 39.636540999072956],
            [-90.301801, 39.520191999073681],
            [-90.581055, 39.521727999073661],
            [-90.64599, 39.703367999072562],
            [-90.599105, 39.790059999072035]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "011",
        COUNTYNS: "01622948",
        AFFGEOID: "0500000US26011",
        GEOID: "26011",
        NAME: "Arenac",
        LSAD: "06",
        ALAND: 940623611,
        AWATER: 822317353
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.166107, 44.161787999057125],
            [-83.883977, 44.161785999057123],
            [-83.564645792496705, 44.163524817460114],
            [-83.567744, 44.155898999057129],
            [-83.58409, 44.056747999057229],
            [-83.679654, 44.036364999057248],
            [-83.693214, 43.988769999057297],
            [-83.787863, 43.985278999057307],
            [-83.869406, 43.960718999057327],
            [-83.901329352181008, 43.908427049901583],
            [-84.044843, 43.911145999057389],
            [-84.045409, 43.996973999057275],
            [-84.166377, 43.996900999057289],
            [-84.166107, 44.161787999057125]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "119",
        COUNTYNS: "01623002",
        AFFGEOID: "0500000US26119",
        GEOID: "26119",
        NAME: "Montmorency",
        LSAD: "06",
        ALAND: 1415822785,
        AWATER: 41410946
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.366614, 45.198736999056798],
            [-84.246634, 45.198726999056809],
            [-84.022072, 45.1984239990568],
            [-83.881223, 45.202070999056801],
            [-83.888482, 44.856634999056766],
            [-84.371737, 44.855038999056767],
            [-84.366614, 45.198736999056798]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "113",
        COUNTYNS: "01383842",
        AFFGEOID: "0500000US48113",
        GEOID: "48113",
        NAME: "Dallas",
        LSAD: "06",
        ALAND: 2258737472,
        AWATER: 94576756
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.036417, 32.69318799914187],
            [-97.036207, 32.711112999141633],
            [-97.035167, 32.759367999140977],
            [-97.03479, 32.78604099914061],
            [-97.034085, 32.816659999140185],
            [-97.032287, 32.989323999137866],
            [-96.990214, 32.989286999137867],
            [-96.980069, 32.989202999137859],
            [-96.958665, 32.989012999137877],
            [-96.843979, 32.98755399913788],
            [-96.803375, 32.987002999137907],
            [-96.613377, 32.98402099913794],
            [-96.516866, 32.982307999137944],
            [-96.51897, 32.813615999140232],
            [-96.519261, 32.79602799914047],
            [-96.523008, 32.559623999143703],
            [-96.529987, 32.545281999143903],
            [-97.038385, 32.54866199914386],
            [-97.036417, 32.69318799914187]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "047",
        COUNTYNS: "01639742",
        AFFGEOID: "0500000US47047",
        GEOID: "47047",
        NAME: "Fayette",
        LSAD: "06",
        ALAND: 1825375565,
        AWATER: 3774866
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.632776, 35.375823999109002],
            [-89.616176, 35.388661999108855],
            [-89.474171, 35.403038999108702],
            [-89.375071, 35.399935999108742],
            [-89.347491, 35.400337999108736],
            [-89.183944, 35.397125999108773],
            [-89.198287901494993, 34.994450529228303],
            [-89.352679391848397, 34.993996140859096],
            [-89.434954, 34.993753999113203],
            [-89.644283834092107, 34.994067209675713],
            [-89.632776, 35.375823999109002]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "163",
        COUNTYNS: "00485045",
        AFFGEOID: "0500000US20163",
        GEOID: "20163",
        NAME: "Rooks",
        LSAD: "06",
        ALAND: 2306483733,
        AWATER: 11962259
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.602176, 39.56732799907337],
            [-99.06622, 39.568124999073369],
            [-99.044398, 39.568034999073369],
            [-99.047687, 39.133013999076162],
            [-99.591776, 39.132356999076173],
            [-99.605187, 39.132480999076165],
            [-99.602176, 39.56732799907337]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "153",
        COUNTYNS: "01804557",
        AFFGEOID: "0500000US72153",
        GEOID: "72153",
        NAME: "Yauco",
        LSAD: "13",
        ALAND: 175371914,
        AWATER: 1625260
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.926517, 18.151266999440804],
            [-66.85678, 18.138589999441145],
            [-66.83676, 18.170552999440293],
            [-66.827327, 18.171241999440269],
            [-66.797557, 18.133444999441274],
            [-66.836682, 17.965970999445737],
            [-66.85791394008578, 17.951049271127133],
            [-66.858320862140388, 17.95107281229733],
            [-66.863219, 17.998028999444884],
            [-66.884653, 18.024815999444172],
            [-66.901033, 18.039853999443764],
            [-66.926517, 18.151266999440804]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "115",
        COUNTYNS: "01623000",
        AFFGEOID: "0500000US26115",
        GEOID: "26115",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 1423275272,
        AWATER: 337746237
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.773922, 42.082429999061482],
            [-83.539396, 42.085597999061477],
            [-83.284155, 42.089951999061462],
            [-83.194952311689505, 42.033107925872663],
            [-83.216897, 41.988560999061797],
            [-83.269521, 41.939041999061978],
            [-83.326024, 41.92496099906203],
            [-83.341557, 41.879955999062183],
            [-83.39622, 41.852964999062287],
            [-83.441668, 41.808645999062435],
            [-83.424076, 41.740737999062688],
            [-83.453832, 41.732646999062723],
            [-83.585541842108796, 41.728772027575538],
            [-83.763038, 41.723549999062747],
            [-83.763149851365199, 41.723546800848332],
            [-83.773922, 42.082429999061482]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "009",
        COUNTYNS: "01804484",
        AFFGEOID: "0500000US72009",
        GEOID: "72009",
        NAME: "Aibonito",
        LSAD: "13",
        ALAND: 81094471,
        AWATER: 71895
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.314848, 18.152087999440781],
            [-66.248293, 18.165614999440422],
            [-66.242735, 18.180247999440034],
            [-66.216899, 18.143219999441019],
            [-66.22449, 18.095338999442291],
            [-66.257527, 18.075883999442805],
            [-66.320913, 18.126004999441474],
            [-66.314848, 18.152087999440781]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "019",
        COUNTYNS: "01035304",
        AFFGEOID: "0500000US38019",
        GEOID: "38019",
        NAME: "Cavalier",
        LSAD: "06",
        ALAND: 3855846888,
        AWATER: 54931562
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.999803372711014, 48.999986294718553],
            [-98.869037, 49.000204999066163],
            [-97.950205011515592, 49.000515118004571],
            [-97.949577, 48.717692999064894],
            [-97.927154, 48.543123999064164],
            [-98.318098, 48.543772999064153],
            [-98.970392, 48.544231999064166],
            [-98.970368, 48.718088999064904],
            [-98.99989, 48.718128999064909],
            [-98.999803372711014, 48.999986294718553]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "069",
        COUNTYNS: "00450361",
        AFFGEOID: "0500000US18069",
        GEOID: "18069",
        NAME: "Huntington",
        LSAD: "06",
        ALAND: 991057049,
        AWATER: 13133342
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.643841, 41.002304999065721],
            [-85.335643, 41.005249999065697],
            [-85.33603, 40.917081999066106],
            [-85.334667, 40.654412999067368],
            [-85.448825, 40.653606999067371],
            [-85.638587, 40.65312899906737],
            [-85.643841, 41.002304999065721]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "053",
        COUNTYNS: "00484996",
        AFFGEOID: "0500000US20053",
        GEOID: "20053",
        NAME: "Ellsworth",
        LSAD: "06",
        ALAND: 1854037822,
        AWATER: 19262994
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.484861, 38.87079099907794],
            [-97.928592, 38.871098999077944],
            [-97.924795, 38.609879999079794],
            [-97.924269, 38.522754999080419],
            [-98.480377, 38.521840999080418],
            [-98.479913, 38.681527999079272],
            [-98.486108, 38.696877999079156],
            [-98.484861, 38.87079099907794]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "045",
        COUNTYNS: "00484992",
        AFFGEOID: "0500000US20045",
        GEOID: "20045",
        NAME: "Douglas",
        LSAD: "06",
        ALAND: 1180628927,
        AWATER: 48674249
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.500724, 38.869814999077946],
            [-95.500254, 39.056655999076668],
            [-95.419439, 39.067204999076608],
            [-95.285425, 39.032161999076834],
            [-95.187103, 39.044108999076755],
            [-95.186189, 38.964541999077291],
            [-95.056258, 38.982119999077177],
            [-95.056412, 38.738586999078869],
            [-95.500738, 38.738807999078865],
            [-95.500724, 38.869814999077946]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "101",
        COUNTYNS: "01265775",
        AFFGEOID: "0500000US46101",
        GEOID: "46101",
        NAME: "Moody",
        LSAD: "06",
        ALAND: 1345200317,
        AWATER: 3679400
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.889216, 44.195704999057092],
            [-96.452435854669488, 44.196779539152189],
            [-96.452909571693198, 43.849508851694658],
            [-96.452909574366288, 43.849506892075858],
            [-96.888664, 43.848384999057458],
            [-96.889216, 44.195704999057092]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "109",
        COUNTYNS: "01550061",
        AFFGEOID: "0500000US54109",
        GEOID: "54109",
        NAME: "Wyoming",
        LSAD: "06",
        ALAND: 1293580739,
        AWATER: 6123514
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.855939, 37.548909999088103],
            [-81.795537, 37.631508999087409],
            [-81.802937, 37.660507999087159],
            [-81.701734, 37.70140899908683],
            [-81.762137, 37.756307999086374],
            [-81.607532, 37.788708999086111],
            [-81.57653, 37.763309999086317],
            [-81.514228, 37.79121099908609],
            [-81.458027, 37.784210999086149],
            [-81.315123, 37.58841399908777],
            [-81.222121, 37.510415999088416],
            [-81.307387, 37.459485999088855],
            [-81.291455, 37.414390999089228],
            [-81.311201, 37.424508999089163],
            [-81.465845, 37.48470299908864],
            [-81.654431, 37.523311999088314],
            [-81.817937, 37.506609999088447],
            [-81.855939, 37.548909999088103]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "007",
        COUNTYNS: "00161529",
        AFFGEOID: "0500000US01007",
        GEOID: "01007",
        NAME: "Bibb",
        LSAD: "06",
        ALAND: 1612165763,
        AWATER: 9603798
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.421936, 33.003378999137681],
            [-87.318539, 33.006178999137639],
            [-87.281945, 33.133059999135959],
            [-87.199317, 33.130656999135986],
            [-87.199153, 33.196553999135126],
            [-87.065738, 33.246906999134467],
            [-87.026846, 33.246458999134468],
            [-87.025596, 33.165794999135521],
            [-86.881182, 33.049900999137058],
            [-86.876118, 32.836263999139916],
            [-87.019157, 32.837033999139912],
            [-87.319184, 32.831521999139987],
            [-87.319473, 32.875123999139397],
            [-87.4212, 32.874507999139404],
            [-87.421936, 33.003378999137681]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "221",
        COUNTYNS: "00516957",
        AFFGEOID: "0500000US21221",
        GEOID: "21221",
        NAME: "Trigg",
        LSAD: "06",
        ALAND: 1143307587,
        AWATER: 103330127
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.157347, 36.867182999094091],
            [-88.002697, 36.910167999093701],
            [-87.876996, 36.960115999093247],
            [-87.858088, 36.950713999093324],
            [-87.733368, 37.002102999092862],
            [-87.659724, 36.966396999093192],
            [-87.694185907864281, 36.636838243039932],
            [-87.853204, 36.633246999096272],
            [-87.849567, 36.66370099909598],
            [-88.011792, 36.677024999095863],
            [-88.070532, 36.678117999095846],
            [-88.080126, 36.706738999095585],
            [-88.110831, 36.747149999095193],
            [-88.129944, 36.807545999094636],
            [-88.157347, 36.867182999094091]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "083",
        COUNTYNS: "01034203",
        AFFGEOID: "0500000US38083",
        GEOID: "38083",
        NAME: "Sheridan",
        LSAD: "06",
        ALAND: 2518459184,
        AWATER: 86143152
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.672408, 47.674037999061021],
            [-100.585106, 47.673911999061019],
            [-100.585161, 47.847479999061576],
            [-100.197996, 47.847599999061572],
            [-100.069206, 47.847300999061574],
            [-100.032849, 47.673620999061022],
            [-100.033292, 47.327191999059984],
            [-100.113261, 47.327261999059999],
            [-100.673285, 47.327425999060004],
            [-100.672408, 47.674037999061021]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "001",
        COUNTYNS: "00465190",
        AFFGEOID: "0500000US19001",
        GEOID: "19001",
        NAME: "Adair",
        LSAD: "06",
        ALAND: 1474404199,
        AWATER: 2597996
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.700629, 41.504147999063584],
            [-94.241593, 41.503678999063574],
            [-94.241637, 41.157133999065024],
            [-94.470603, 41.157565999065028],
            [-94.700589, 41.158084999065025],
            [-94.700629, 41.504147999063584]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "105",
        COUNTYNS: "00695775",
        AFFGEOID: "0500000US28105",
        GEOID: "28105",
        NAME: "Oktibbeha",
        LSAD: "06",
        ALAND: 1186750731,
        AWATER: 9649656
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.088438, 33.532517999130761],
            [-89.088446, 33.533201999130753],
            [-89.088263, 33.562038999130387],
            [-89.018613, 33.562113999130382],
            [-88.770596, 33.565211999130327],
            [-88.671526, 33.56513499913035],
            [-88.67125, 33.506167999131101],
            [-88.669076, 33.286921999133938],
            [-88.813053, 33.286423999133945],
            [-88.965963, 33.286107999133947],
            [-89.088255, 33.286002999133949],
            [-89.088652, 33.445820999131868],
            [-89.088438, 33.532517999130761]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "051",
        COUNTYNS: "01383811",
        AFFGEOID: "0500000US48051",
        GEOID: "48051",
        NAME: "Burleson",
        LSAD: "06",
        ALAND: 1706886781,
        AWATER: 46048550
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.963629, 30.557168999173445],
            [-96.621281, 30.730818999170712],
            [-96.566983, 30.695669999171262],
            [-96.601831, 30.641925999172106],
            [-96.510494, 30.627827999172332],
            [-96.358817, 30.541752999173692],
            [-96.340875, 30.474542999174766],
            [-96.272049, 30.416144999175703],
            [-96.299173, 30.37915199917629],
            [-96.357407, 30.373317999176386],
            [-96.640883, 30.296785999177615],
            [-96.743672, 30.319756999177237],
            [-96.87711, 30.447944999175192],
            [-96.918902, 30.539211999173737],
            [-96.963629, 30.557168999173445]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "054",
        COUNTYNS: "01804507",
        AFFGEOID: "0500000US72054",
        GEOID: "72054",
        NAME: "Florida",
        LSAD: "13",
        ALAND: 39391498,
        AWATER: 34012
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.588181, 18.3894079994345],
            [-66.543079, 18.405421999434079],
            [-66.533623, 18.351332999435506],
            [-66.590785, 18.33805999943586],
            [-66.588181, 18.3894079994345]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "001",
        COUNTYNS: "01101788",
        AFFGEOID: "0500000US40001",
        GEOID: "40001",
        NAME: "Adair",
        LSAD: "06",
        ALAND: 1485304967,
        AWATER: 9254749
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.797279, 36.161389999100841],
            [-94.562267928552785, 36.161972613727237],
            [-94.551906282606296, 36.102225604770034],
            [-94.532071, 35.987851999102581],
            [-94.494549, 35.768302999104847],
            [-94.493040072427007, 35.759166473432231],
            [-94.473119390646701, 35.638547151635606],
            [-94.807297, 35.638603999106202],
            [-94.797279, 36.161389999100841]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "109",
        COUNTYNS: "00695777",
        AFFGEOID: "0500000US28109",
        GEOID: "28109",
        NAME: "Pearl River",
        LSAD: "06",
        ALAND: 2100183723,
        AWATER: 20778648
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.836331, 30.727196999170758],
            [-89.791745, 30.820386999169301],
            [-89.750073, 30.912929999167865],
            [-89.728175690160597, 31.002313887150581],
            [-89.654038, 31.002501999166476],
            [-89.501411, 31.013906999166306],
            [-89.425337, 30.982451999166795],
            [-89.34895, 31.010369999166361],
            [-89.34072, 30.994162999166615],
            [-89.340806, 30.90991199916791],
            [-89.341446, 30.647719999172011],
            [-89.544232, 30.648367999172002],
            [-89.54451, 30.516861999174093],
            [-89.647072, 30.502737999174311],
            [-89.699932082840789, 30.454036879961084],
            [-89.712493, 30.477509999174721],
            [-89.791664, 30.551523999173536],
            [-89.821868, 30.644023999172074],
            [-89.826180359115199, 30.668823269356892],
            [-89.836331, 30.727196999170758]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "550",
        COUNTYNS: "01498558",
        AFFGEOID: "0500000US51550",
        GEOID: "51550",
        NAME: "Chesapeake",
        LSAD: "25",
        ALAND: 876737781,
        AWATER: 32210024
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.466768, 36.746248999095222],
            [-76.456168, 36.816947999094559],
            [-76.420368, 36.866347999094096],
            [-76.387421, 36.852198999094227],
            [-76.400265, 36.80643699909465],
            [-76.363839, 36.787803999094834],
            [-76.292144, 36.798662999094724],
            [-76.289962, 36.822047999094508],
            [-76.22606, 36.839946999094337],
            [-76.223591, 36.820792999094522],
            [-76.2037, 36.76974199909499],
            [-76.067996, 36.692578999095701],
            [-76.121955, 36.665749999095965],
            [-76.122349947010079, 36.550552331651154],
            [-76.313215, 36.550550999097055],
            [-76.313302968260288, 36.550551088904051],
            [-76.49148257547229, 36.550732992933447],
            [-76.466768, 36.746248999095222]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "183",
        COUNTYNS: "00465280",
        AFFGEOID: "0500000US19183",
        GEOID: "19183",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1473243904,
        AWATER: 5353677
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.946043, 41.510748999063551],
            [-91.829231, 41.511456999063562],
            [-91.519828, 41.51100999906356],
            [-91.483995, 41.423847999063902],
            [-91.485717, 41.162404999065011],
            [-91.716042, 41.162807999065002],
            [-91.945571, 41.163577999065005],
            [-91.946043, 41.510748999063551]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "065",
        COUNTYNS: "00695756",
        AFFGEOID: "0500000US28065",
        GEOID: "28065",
        NAME: "Jefferson Davis",
        LSAD: "06",
        ALAND: 1057871313,
        AWATER: 1791496
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.974586, 31.761654999155066],
            [-89.755103, 31.774653999154868],
            [-89.755614, 31.609750999157299],
            [-89.586919, 31.513443999158731],
            [-89.586819, 31.433687999159918],
            [-89.654212, 31.433804999159925],
            [-89.824236, 31.434092999159919],
            [-89.823332, 31.390020999160576],
            [-89.95894, 31.390488999160574],
            [-89.976052, 31.522476999158599],
            [-89.974586, 31.761654999155066]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "087",
        COUNTYNS: "00465232",
        AFFGEOID: "0500000US19087",
        GEOID: "19087",
        NAME: "Henry",
        LSAD: "06",
        ALAND: 1124792081,
        AWATER: 5768130
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.71818, 40.90108699906618],
            [-91.716042, 41.162807999065002],
            [-91.485717, 41.162404999065011],
            [-91.370298, 41.161246999065007],
            [-91.37071, 41.074111999065394],
            [-91.372095, 40.812630999066592],
            [-91.409453, 40.812904999066596],
            [-91.718787, 40.813543999066589],
            [-91.71818, 40.90108699906618]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "091",
        COUNTYNS: "01629457",
        AFFGEOID: "0500000US22091",
        GEOID: "22091",
        NAME: "St. Helena",
        LSAD: "15",
        ALAND: 1057772583,
        AWATER: 2796210
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.850499, 30.700266999171184],
            [-90.849041, 30.719310999170887],
            [-90.840953, 30.846918999168896],
            [-90.857983, 30.873143999168484],
            [-90.855009, 30.873992999168468],
            [-90.845366, 30.888573999168248],
            [-90.863041, 30.941980999167409],
            [-90.825829141291905, 30.999525354507121],
            [-90.758775, 30.999582999166527],
            [-90.567195400658491, 30.999945023566418],
            [-90.567171, 30.824805999169239],
            [-90.567165, 30.650022999171977],
            [-90.910701, 30.649384999171993],
            [-90.850499, 30.700266999171184]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "097",
        COUNTYNS: "00450371",
        AFFGEOID: "0500000US18097",
        GEOID: "18097",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 1026465038,
        AWATER: 17326366
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.327291, 39.822491999071843],
            [-86.325373, 39.866175999071601],
            [-86.326293, 39.924028999071247],
            [-86.239739, 39.926054999071241],
            [-85.937587, 39.927136999071237],
            [-85.938067, 39.869811999071565],
            [-85.953683, 39.856116999071638],
            [-85.951721, 39.697135999072586],
            [-85.95208, 39.638344999072949],
            [-86.249885, 39.633644999072978],
            [-86.326341, 39.632176999072982],
            [-86.327291, 39.822491999071843]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "107",
        COUNTYNS: "00450374",
        AFFGEOID: "0500000US18107",
        GEOID: "18107",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 1306954200,
        AWATER: 2144021
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.092563, 40.214805999069632],
            [-86.695637, 40.214366999069632],
            [-86.695804, 40.17865699906983],
            [-86.695071, 39.922769999071257],
            [-86.695127, 39.8648449990716],
            [-87.009477, 39.866704999071587],
            [-87.091542, 39.86726499907158],
            [-87.091006, 39.953008999071102],
            [-87.092563, 40.214805999069632]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "091",
        COUNTYNS: "01074058",
        AFFGEOID: "0500000US39091",
        GEOID: "39091",
        NAME: "Logan",
        LSAD: "06",
        ALAND: 1187337620,
        AWATER: 21597586
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.0037, 40.312926999069099],
            [-84.002372, 40.483114999068221],
            [-83.993699, 40.535200999067953],
            [-83.879932, 40.538707999067938],
            [-83.859178, 40.530651999067977],
            [-83.520227, 40.504076999068104],
            [-83.551338, 40.229369999069547],
            [-83.784196, 40.260045999069384],
            [-84.014763, 40.273458999069319],
            [-84.0037, 40.312926999069099]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "770",
        COUNTYNS: "01498439",
        AFFGEOID: "0500000US51770",
        GEOID: "51770",
        NAME: "Roanoke",
        LSAD: "25",
        ALAND: 110141450,
        AWATER: 865676
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.022226, 37.308337999090149],
            [-79.974023, 37.335418999089917],
            [-79.87855, 37.291889999090287],
            [-79.946817, 37.213036999090981],
            [-80.033464, 37.262889999090547],
            [-80.022226, 37.308337999090149]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "115",
        COUNTYNS: "00465246",
        AFFGEOID: "0500000US19115",
        GEOID: "19115",
        NAME: "Louisa",
        LSAD: "06",
        ALAND: 1040583090,
        AWATER: 40784329
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.483995, 41.423847999063902],
            [-91.460384, 41.424179999063909],
            [-91.368521, 41.423177999063903],
            [-91.369332, 41.336062999064261],
            [-91.074415498019604, 41.333631568104977],
            [-91.114186, 41.250028999064625],
            [-91.081445, 41.214428999064779],
            [-91.041536, 41.166137999064979],
            [-90.997906, 41.162563999065007],
            [-90.957246, 41.11108499906522],
            [-90.952265416482192, 41.072731748735407],
            [-91.37071, 41.074111999065394],
            [-91.370298, 41.161246999065007],
            [-91.485717, 41.162404999065011],
            [-91.483995, 41.423847999063902]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "077",
        COUNTYNS: "01581098",
        AFFGEOID: "0500000US55077",
        GEOID: "55077",
        NAME: "Marquette",
        LSAD: "06",
        ALAND: 1180275605,
        AWATER: 22682651
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.59795, 43.982099999057304],
            [-89.168612, 43.982833999057299],
            [-89.198299, 43.812676999057508],
            [-89.167886, 43.765642999057576],
            [-89.245375, 43.75981299905758],
            [-89.245437, 43.643082999057739],
            [-89.599547, 43.642635999057745],
            [-89.59795, 43.982099999057304]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "065",
        COUNTYNS: "00481811",
        AFFGEOID: "0500000US20065",
        GEOID: "20065",
        NAME: "Graham",
        LSAD: "06",
        ALAND: 2327173855,
        AWATER: 599356
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.161667, 39.567276999073371],
            [-99.627953, 39.567436999073379],
            [-99.602176, 39.56732799907337],
            [-99.605187, 39.132480999076165],
            [-100.14794, 39.132178999076167],
            [-100.1642, 39.132070999076163],
            [-100.161667, 39.567276999073371]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "061",
        COUNTYNS: "00395622",
        AFFGEOID: "0500000US16061",
        GEOID: "16061",
        NAME: "Lewis",
        LSAD: "06",
        ALAND: 1240137223,
        AWATER: 2345525
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.713496, 46.325526999057857],
            [-116.368801, 46.343216999057866],
            [-116.369122, 46.466922999058085],
            [-116.308621, 46.412997999057978],
            [-116.184198, 46.403377999057966],
            [-116.170272, 46.371587999057915],
            [-116.127103, 46.298574999057806],
            [-116.012498, 46.22570799905769],
            [-116.13357, 46.215230999057674],
            [-116.188993, 46.155356999057581],
            [-116.428497, 46.202528999057662],
            [-116.48557, 46.12116999905755],
            [-116.53962, 45.998396999057384],
            [-116.648516, 46.03059699905743],
            [-116.701975, 45.996216999057388],
            [-116.713496, 46.325526999057857]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "101",
        COUNTYNS: "00485015",
        AFFGEOID: "0500000US20101",
        GEOID: "20101",
        NAME: "Lane",
        LSAD: "06",
        ALAND: 1858093492,
        AWATER: 551220
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.688006, 38.70002099907915],
            [-100.2472, 38.698164999079154],
            [-100.244393, 38.26228999908237],
            [-100.684699, 38.264136999082361],
            [-100.688006, 38.70002099907915]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "123",
        COUNTYNS: "00485026",
        AFFGEOID: "0500000US20123",
        GEOID: "20123",
        NAME: "Mitchell",
        LSAD: "06",
        ALAND: 1817632928,
        AWATER: 44979980
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.487384, 39.567491999073376],
            [-97.931844, 39.566920999073375],
            [-97.928623, 39.551459999073479],
            [-97.929097, 39.306396999075034],
            [-97.931614, 39.277093999075213],
            [-97.929746, 39.219272999075599],
            [-98.490149, 39.219779999075591],
            [-98.487384, 39.567491999073376]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "021",
        COUNTYNS: "00558477",
        AFFGEOID: "0500000US22021",
        GEOID: "22021",
        NAME: "Caldwell",
        LSAD: "15",
        ALAND: 1371203831,
        AWATER: 29277303
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.312218, 32.146122999149505],
            [-92.311851, 32.277440999147643],
            [-92.106115, 32.258551999147919],
            [-92.034397, 32.277067999147654],
            [-91.993367, 32.276794999147654],
            [-91.980603, 32.166633999149219],
            [-91.894729, 32.151549999149438],
            [-91.868202, 32.09155499915029],
            [-91.933229, 32.066881999150645],
            [-91.889687, 31.97147399915201],
            [-91.904381, 31.927560999152654],
            [-92.005434, 31.927295999152655],
            [-92.312623, 31.927381999152644],
            [-92.312218, 32.146122999149505]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "027",
        COUNTYNS: "00558505",
        AFFGEOID: "0500000US22027",
        GEOID: "22027",
        NAME: "Claiborne",
        LSAD: "15",
        ALAND: 1955125391,
        AWATER: 32473222
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.238606962399388, 33.018015879094087],
            [-93.197402, 33.017950999137476],
            [-92.988707610715011, 33.017250939289688],
            [-92.971137, 33.017191999137495],
            [-92.724743173179291, 33.014341766812343],
            [-92.725369, 32.817978999140173],
            [-92.725439, 32.759539999140976],
            [-92.828535, 32.758813999140983],
            [-92.880805, 32.585276999143346],
            [-93.178767, 32.584915999143355],
            [-93.173589, 32.633067999142689],
            [-93.186571, 32.716754999141557],
            [-93.237381, 32.716249999141553],
            [-93.238606962399388, 33.018015879094087]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "043",
        COUNTYNS: "01581081",
        AFFGEOID: "0500000US55043",
        GEOID: "55043",
        NAME: "Grant",
        LSAD: "06",
        ALAND: 2970364997,
        AWATER: 94558771
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.159084305947488, 42.987477790381782],
            [-91.062211, 42.992551999058961],
            [-90.940087, 43.061881999058812],
            [-90.845746, 43.077624999058777],
            [-90.666786, 43.171776999058579],
            [-90.545408, 43.211454999058503],
            [-90.429825, 43.200939999058527],
            [-90.426902, 42.812861999059386],
            [-90.426376960736093, 42.5071777342839],
            [-90.437011, 42.50714699906019],
            [-90.642843, 42.508480999060197],
            [-90.672727, 42.576598999059996],
            [-90.700856, 42.626444999059878],
            [-90.743677, 42.645559999059827],
            [-90.852497, 42.664821999059768],
            [-90.896962027158409, 42.674318055494055],
            [-90.941567, 42.683843999059718],
            [-91.017239, 42.719565999059633],
            [-91.070716, 42.775501999059479],
            [-91.09882, 42.86442099905927],
            [-91.138, 42.903771999059174],
            [-91.155519, 42.975773999059008],
            [-91.159084305947488, 42.987477790381782]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "131",
        COUNTYNS: "01480151",
        AFFGEOID: "0500000US51131",
        GEOID: "51131",
        NAME: "Northampton",
        LSAD: "06",
        ALAND: 548282864,
        AWATER: 1511417329
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.023475, 37.289066999090316],
            [-75.987122, 37.368547999089635],
            [-75.976491, 37.444877999088988],
            [-75.945574318930682, 37.5490406572398],
            [-75.90041, 37.557264999088027],
            [-75.835214, 37.554244999088056],
            [-75.788012, 37.528815999088266],
            [-75.804797, 37.514725999088384],
            [-75.793521, 37.488836999088598],
            [-75.812793, 37.473894999088721],
            [-75.776564, 37.454588999088898],
            [-75.763912, 37.463307999088826],
            [-75.713275, 37.449875999088924],
            [-75.665417910875092, 37.467293514581293],
            [-75.658379, 37.451814999088924],
            [-75.720739, 37.373128999089587],
            [-75.735829, 37.335425999089921],
            [-75.778817, 37.297175999090243],
            [-75.795164, 37.247094999090692],
            [-75.817387, 37.193436999091155],
            [-75.897298, 37.118036999091828],
            [-75.942399, 37.089606999092076],
            [-75.979608, 37.10044799909199],
            [-75.998647, 37.188738999091193],
            [-76.023475, 37.289066999090316]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "029",
        COUNTYNS: "01026335",
        AFFGEOID: "0500000US37029",
        GEOID: "37029",
        NAME: "Camden",
        LSAD: "06",
        ALAND: 622448679,
        AWATER: 181112904
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.49148257547229, 36.550732992933447],
            [-76.313302968260288, 36.550551088904051],
            [-76.158532, 36.425332999098259],
            [-76.114069, 36.357497999098918],
            [-76.050755, 36.359818999098898],
            [-75.948777, 36.285850999099615],
            [-75.887984, 36.163310999100823],
            [-75.906295, 36.085876999101593],
            [-76.022398, 36.086653999101586],
            [-76.022607, 36.09672499910149],
            [-76.02939, 36.158818999100873],
            [-76.12458, 36.263962999099832],
            [-76.216211, 36.298301999099493],
            [-76.231329, 36.372079999098766],
            [-76.437286, 36.467845999097847],
            [-76.491336, 36.510676999097441],
            [-76.541965865718296, 36.550784531457154],
            [-76.49148257547229, 36.550732992933447]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "017",
        COUNTYNS: "00758463",
        AFFGEOID: "0500000US29017",
        GEOID: "29017",
        NAME: "Bollinger",
        LSAD: "06",
        ALAND: 1600363402,
        AWATER: 8531304
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.217657, 37.314966999090096],
            [-90.145699, 37.312090999090124],
            [-90.146763, 37.597433999087698],
            [-89.861123, 37.599285999087677],
            [-89.866627, 37.126226999091763],
            [-89.959576, 37.127352999091748],
            [-89.99572, 37.040109999092522],
            [-90.111247, 37.041204999092514],
            [-90.110354, 37.085870999092108],
            [-90.22323, 37.0865189990921],
            [-90.217657, 37.314966999090096]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "720",
        COUNTYNS: "01498434",
        AFFGEOID: "0500000US51720",
        GEOID: "51720",
        NAME: "Norton",
        LSAD: "25",
        ALAND: 19366208,
        AWATER: 89336
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.663625, 36.9209589990936],
            [-82.634101, 36.952247999093316],
            [-82.595022, 36.95575199909328],
            [-82.591147, 36.935312999093476],
            [-82.621962, 36.927067999093545],
            [-82.640127, 36.902331999093768],
            [-82.663625, 36.9209589990936]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "830",
        COUNTYNS: "01789076",
        AFFGEOID: "0500000US51830",
        GEOID: "51830",
        NAME: "Williamsburg",
        LSAD: "25",
        ALAND: 23155312,
        AWATER: 416904
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.740465, 37.305736999090179],
            [-76.727859, 37.305926999090161],
            [-76.68167, 37.292607999090301],
            [-76.680151, 37.265996999090525],
            [-76.664516, 37.25058599909066],
            [-76.741143, 37.273379999090452],
            [-76.740465, 37.305736999090179]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "091",
        COUNTYNS: "00758500",
        AFFGEOID: "0500000US29091",
        GEOID: "29091",
        NAME: "Howell",
        LSAD: "06",
        ALAND: 2401562307,
        AWATER: 2913502
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.112346, 36.794230999094758],
            [-92.095841, 36.798438999094721],
            [-92.088708, 37.056247999092378],
            [-91.655607, 37.048924999092435],
            [-91.658111, 36.888723999093898],
            [-91.674237, 36.787831999094827],
            [-91.672342456443701, 36.49925663289644],
            [-91.985802, 36.49843099909755],
            [-92.120429104388108, 36.498193123097252],
            [-92.112346, 36.794230999094758]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "093",
        COUNTYNS: "00758501",
        AFFGEOID: "0500000US29093",
        GEOID: "29093",
        NAME: "Iron",
        LSAD: "06",
        ALAND: 1425177705,
        AWATER: 4662498
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.153345, 37.697339999086857],
            [-91.146521, 37.740810999086506],
            [-91.100017, 37.740011999086498],
            [-90.645135, 37.734812999086543],
            [-90.647874, 37.642808999087308],
            [-90.535016, 37.643640999087296],
            [-90.547406, 37.598409999087671],
            [-90.549729, 37.317593999090079],
            [-90.550603, 37.272607999090461],
            [-90.735453, 37.271039999090476],
            [-90.772949, 37.373658999089585],
            [-90.768695, 37.603010999087651],
            [-90.987055, 37.603851999087631],
            [-91.155073, 37.588091999087766],
            [-91.153345, 37.697339999086857]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "067",
        COUNTYNS: "00558097",
        AFFGEOID: "0500000US22067",
        GEOID: "22067",
        NAME: "Morehouse",
        LSAD: "15",
        ALAND: 2058870205,
        AWATER: 28878805
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.069103758561283, 33.008482262837212],
            [-91.875128, 33.007727999137622],
            [-91.489176, 33.006181999137638],
            [-91.460391771831908, 33.005997054839149],
            [-91.435931080331798, 33.005839890109748],
            [-91.437551, 32.980931999137972],
            [-91.566363, 32.855560999139676],
            [-91.637587, 32.667340999142219],
            [-91.742286, 32.558905999143718],
            [-91.80432, 32.571315999143543],
            [-91.839532, 32.518481999144271],
            [-91.913057, 32.505901999144449],
            [-91.943419, 32.587118999143328],
            [-91.911168, 32.66113899914231],
            [-92.065179, 32.723002999141457],
            [-92.052728, 32.794733999140483],
            [-92.085617, 32.965192999138189],
            [-92.069103758561283, 33.008482262837212]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "800",
        COUNTYNS: "01498560",
        AFFGEOID: "0500000US51800",
        GEOID: "51800",
        NAME: "Suffolk",
        LSAD: "25",
        ALAND: 1033802700,
        AWATER: 77061968
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.930936, 36.559568999096967],
            [-76.897592, 36.644049999096183],
            [-76.691033, 36.796238999094754],
            [-76.53352, 36.915562999093652],
            [-76.445301, 36.941890999093417],
            [-76.399567, 36.930946999093514],
            [-76.420368, 36.866347999094096],
            [-76.456168, 36.816947999094559],
            [-76.466768, 36.746248999095222],
            [-76.49148257547229, 36.550732992933447],
            [-76.541965865718296, 36.550784531457154],
            [-76.738329, 36.550984999097047],
            [-76.915731802349697, 36.546089768435984],
            [-76.930936, 36.559568999096967]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "223",
        COUNTYNS: "00758564",
        AFFGEOID: "0500000US29223",
        GEOID: "29223",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 1966257800,
        AWATER: 38558983
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.779025, 37.137919999091658],
            [-90.737146, 37.166412999091399],
            [-90.735453, 37.271039999090476],
            [-90.550603, 37.272607999090461],
            [-90.549729, 37.317593999090079],
            [-90.217657, 37.314966999090096],
            [-90.22323, 37.0865189990921],
            [-90.110354, 37.085870999092108],
            [-90.111247, 37.041204999092514],
            [-90.186327, 36.99334499909294],
            [-90.258563, 36.922042999093584],
            [-90.678562, 36.926489999093548],
            [-90.729722, 37.049533999092432],
            [-90.779553, 37.050323999092434],
            [-90.779025, 37.137919999091658]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "383",
        COUNTYNS: "01383977",
        AFFGEOID: "0500000US48383",
        GEOID: "48383",
        NAME: "Reagan",
        LSAD: "06",
        ALAND: 3044049124,
        AWATER: 1792716
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.775802, 31.651318999156683],
            [-101.267122, 31.650853999156695],
            [-101.26763, 31.556461999158088],
            [-101.267947, 31.5286879991585],
            [-101.274799, 31.0793789991653],
            [-101.776191, 31.079783999165286],
            [-101.775802, 31.651318999156683]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "411",
        COUNTYNS: "01383991",
        AFFGEOID: "0500000US48411",
        GEOID: "48411",
        NAME: "San Saba",
        LSAD: "06",
        ALAND: 2940447178,
        AWATER: 8069104
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.092317, 30.941001999167419],
            [-99.090622, 31.460926999159515],
            [-99.041741, 31.490301999159076],
            [-98.991608, 31.484070999159165],
            [-98.933188, 31.436927999159874],
            [-98.8725, 31.444298999159766],
            [-98.854292, 31.413424999160224],
            [-98.7244, 31.427173999160026],
            [-98.696634, 31.407286999160331],
            [-98.713131, 31.348829999161197],
            [-98.65456, 31.37002199916088],
            [-98.643921, 31.314069999161731],
            [-98.587268, 31.325462999161552],
            [-98.626926, 31.281181999162222],
            [-98.562739, 31.230581999162975],
            [-98.510341, 31.155665999164125],
            [-98.548051, 31.123473999164627],
            [-98.439687, 31.029536999166069],
            [-98.418746, 30.926259999167659],
            [-98.445782, 30.92143899916773],
            [-98.964612, 30.921367999167735],
            [-99.091886, 30.921965999167714],
            [-99.092317, 30.941001999167419]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "043",
        COUNTYNS: "00198137",
        AFFGEOID: "0500000US08043",
        GEOID: "08043",
        NAME: "Fremont",
        LSAD: "06",
        ALAND: 3970664546,
        AWATER: 2235374
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.010751, 38.446565999080981],
            [-105.908717, 38.505630999080559],
            [-105.878122, 38.602119999079854],
            [-105.96975, 38.693550999079186],
            [-105.329134, 38.697204999079162],
            [-105.237792, 38.696900999079162],
            [-105.240362, 38.647594999079516],
            [-104.942426, 38.64988199907949],
            [-104.941533, 38.519568999080441],
            [-104.940366, 38.258274999082396],
            [-105.049215, 38.257972999082398],
            [-105.796897, 38.265046999082351],
            [-105.999788, 38.423621999081156],
            [-106.010751, 38.446565999080981]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "475",
        COUNTYNS: "01384023",
        AFFGEOID: "0500000US48475",
        GEOID: "48475",
        NAME: "Ward",
        LSAD: "06",
        ALAND: 2164201087,
        AWATER: 624206
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.610887, 31.651801999156671],
            [-103.327538, 31.651419999156687],
            [-102.798939, 31.65178299915668],
            [-102.767246, 31.651713999156687],
            [-102.767365, 31.293802999162036],
            [-102.826735, 31.266880999162428],
            [-102.933578, 31.350080999161175],
            [-103.01104, 31.37130599916086],
            [-103.181225, 31.370244999160874],
            [-103.261677, 31.427569999160017],
            [-103.329941, 31.411997999160249],
            [-103.44241, 31.433751999159924],
            [-103.509379, 31.625933999157052],
            [-103.610887, 31.651801999156671]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "253",
        COUNTYNS: "01383912",
        AFFGEOID: "0500000US48253",
        GEOID: "48253",
        NAME: "Jones",
        LSAD: "06",
        ALAND: 2404946720,
        AWATER: 22227413
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.144224, 32.95997799913826],
            [-99.988827, 32.960120999138255],
            [-99.612001, 32.956961999138294],
            [-99.612026, 32.514653999144329],
            [-99.629579, 32.514656999144329],
            [-99.656569, 32.520639999144244],
            [-100.146543, 32.522792999144215],
            [-100.144224, 32.95997799913826]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "319",
        COUNTYNS: "01383942",
        AFFGEOID: "0500000US48319",
        GEOID: "48319",
        NAME: "Mason",
        LSAD: "06",
        ALAND: 2405596036,
        AWATER: 8833381
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.484755, 30.940604999167427],
            [-99.092317, 30.941001999167419],
            [-99.091886, 30.921965999167714],
            [-98.964612, 30.921367999167735],
            [-98.964232, 30.498481999174381],
            [-99.303996, 30.499831999174354],
            [-99.484493, 30.499640999174357],
            [-99.483869, 30.710770999171018],
            [-99.484755, 30.940604999167427]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "335",
        COUNTYNS: "01383953",
        AFFGEOID: "0500000US48335",
        GEOID: "48335",
        NAME: "Mitchell",
        LSAD: "06",
        ALAND: 2359710933,
        AWATER: 12489774
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.174571, 32.524111999144196],
            [-101.174562, 32.527702999144147],
            [-100.660626, 32.525311999144179],
            [-100.665353, 32.085406999150365],
            [-100.821594, 32.086609999150362],
            [-101.183997, 32.087207999150351],
            [-101.174571, 32.524111999144196]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "035",
        COUNTYNS: "00198133",
        AFFGEOID: "0500000US08035",
        GEOID: "08035",
        NAME: "Douglas",
        LSAD: "06",
        ALAND: 2176069189,
        AWATER: 6950848
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.217833, 39.260114999075327],
            [-105.171278, 39.406853999074386],
            [-105.073045, 39.545264999073517],
            [-105.05306, 39.563108999073407],
            [-105.04874, 39.566087999073396],
            [-104.904415, 39.565981999073394],
            [-104.660626, 39.565908999073386],
            [-104.662896, 39.129526999076184],
            [-105.033544, 39.129818999076178],
            [-105.32922, 39.129688999076173],
            [-105.217833, 39.260114999075327]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "433",
        COUNTYNS: "01384002",
        AFFGEOID: "0500000US48433",
        GEOID: "48433",
        NAME: "Stonewall",
        LSAD: "06",
        ALAND: 2373238704,
        AWATER: 10177387
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.517449, 33.397865999132492],
            [-99.99098, 33.3974039991325],
            [-99.988827, 32.960120999138255],
            [-100.144224, 32.95997799913826],
            [-100.519208, 32.962925999138214],
            [-100.517449, 33.397865999132492]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "051",
        COUNTYNS: "01101813",
        AFFGEOID: "0500000US40051",
        GEOID: "40051",
        NAME: "Grady",
        LSAD: "06",
        ALAND: 2850272919,
        AWATER: 11403868
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.096014, 35.377752999108978],
            [-97.955124, 35.346047999109324],
            [-97.862805, 35.361829999109155],
            [-97.833675, 35.335361999109438],
            [-97.771765, 35.345734999109325],
            [-97.671595, 35.335933999109429],
            [-97.670629, 34.872959999114578],
            [-97.668099, 34.855163999114765],
            [-97.668425, 34.681343999116763],
            [-98.089098, 34.681118999116762],
            [-98.08901, 34.834805999114998],
            [-98.092645, 34.854918999114773],
            [-98.096014, 35.377752999108978]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "017",
        COUNTYNS: "01101796",
        AFFGEOID: "0500000US40017",
        GEOID: "40017",
        NAME: "Canadian",
        LSAD: "06",
        ALAND: 2322265669,
        AWATER: 23229300
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.31337, 35.725203999105275],
            [-98.207104, 35.725139999105288],
            [-97.674026, 35.725969999105281],
            [-97.671383, 35.406351999108679],
            [-97.671369, 35.377150999108977],
            [-97.671529, 35.337507999109427],
            [-97.671595, 35.335933999109429],
            [-97.771765, 35.345734999109325],
            [-97.833675, 35.335361999109438],
            [-97.862805, 35.361829999109155],
            [-97.955124, 35.346047999109324],
            [-98.096014, 35.377752999108978],
            [-98.306326, 35.377238999108982],
            [-98.313251, 35.551522999107114],
            [-98.31337, 35.725203999105275]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "027",
        COUNTYNS: "01101801",
        AFFGEOID: "0500000US40027",
        GEOID: "40027",
        NAME: "Cleveland",
        LSAD: "06",
        ALAND: 1395529148,
        AWATER: 49908184
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.671369, 35.377150999108977],
            [-97.423566, 35.377315999108987],
            [-97.317832, 35.377285999108977],
            [-97.142114, 35.376859999108994],
            [-97.142349, 34.928175999113954],
            [-97.333833, 34.958947999113605],
            [-97.354192, 35.07665599911229],
            [-97.407082, 35.138143999111598],
            [-97.54146, 35.214164999110771],
            [-97.556987, 35.288439999109947],
            [-97.671529, 35.337507999109427],
            [-97.671369, 35.377150999108977]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "041",
        COUNTYNS: "00974119",
        AFFGEOID: "0500000US36041",
        GEOID: "36041",
        NAME: "Hamilton",
        LSAD: "06",
        ALAND: 4447987884,
        AWATER: 234205889
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.775617, 43.486676999057998],
            [-74.854171, 44.070088999057212],
            [-74.535156, 44.099249999057172],
            [-74.28187, 44.120551999057156],
            [-74.255998, 43.969796999057316],
            [-74.336826, 43.925222999057375],
            [-74.213734, 43.810874999057503],
            [-74.149076, 43.829612999057488],
            [-74.047062, 43.796342999057529],
            [-74.057005, 43.744512999057598],
            [-74.214625, 43.728702999057624],
            [-74.1601, 43.371531999058199],
            [-74.140147, 43.253978999058418],
            [-74.220902, 43.221402999058476],
            [-74.326378, 43.241634999058441],
            [-74.534657, 43.228114999058462],
            [-74.712615, 43.286142999058349],
            [-74.867712, 43.339825999058249],
            [-74.775617, 43.486676999057998]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "023",
        COUNTYNS: "00974110",
        AFFGEOID: "0500000US36023",
        GEOID: "36023",
        NAME: "Cortland",
        LSAD: "06",
        ALAND: 1291815779,
        AWATER: 7112822
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.274673, 42.771256999059496],
            [-75.896079, 42.790963999059443],
            [-75.889832, 42.723843999059618],
            [-75.86402, 42.415701999060452],
            [-76.021374, 42.409007999060485],
            [-76.130181, 42.410336999060476],
            [-76.253359, 42.40756799906049],
            [-76.265584, 42.623587999059879],
            [-76.274673, 42.771256999059496]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "071",
        COUNTYNS: "00835857",
        AFFGEOID: "0500000US31071",
        GEOID: "31071",
        NAME: "Garfield",
        LSAD: "06",
        ALAND: 1474558345,
        AWATER: 5321554
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.222733, 42.087915999061465],
            [-98.761155, 42.08851799906148],
            [-98.759133, 41.740381999062684],
            [-99.212016, 41.740612999062684],
            [-99.22183, 41.740791999062679],
            [-99.222733, 42.087915999061465]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "057",
        COUNTYNS: "00974127",
        AFFGEOID: "0500000US36057",
        GEOID: "36057",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 1044067732,
        AWATER: 18598434
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.743805, 43.030740999058878],
            [-74.759895, 43.047422999058831],
            [-74.542367, 42.985529999058976],
            [-74.488844, 42.985117999058978],
            [-74.097467, 42.982933999059],
            [-74.096897, 42.979377999059004],
            [-74.09298, 42.955867999059045],
            [-74.083883, 42.897353999059199],
            [-74.263314, 42.796533999059427],
            [-74.289446, 42.78380499905947],
            [-74.411384, 42.776489999059478],
            [-74.454911, 42.77297899905949],
            [-74.648298, 42.829557999059347],
            [-74.763303, 42.863236999059261],
            [-74.743805, 43.030740999058878]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "053",
        COUNTYNS: "00974125",
        AFFGEOID: "0500000US36053",
        GEOID: "36053",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 1696091558,
        AWATER: 16670528
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.993394, 43.183375999058569],
            [-75.932778, 43.176582999058574],
            [-75.884275, 43.155561999058612],
            [-75.723929, 43.16259599905861],
            [-75.552774, 43.037553999058872],
            [-75.542803, 42.931773999059104],
            [-75.444173, 42.933088999059095],
            [-75.437167, 42.863318999059267],
            [-75.247963, 42.871603999059246],
            [-75.251416, 42.784564999059462],
            [-75.295877, 42.744105999059549],
            [-75.428668, 42.745420999059554],
            [-75.889832, 42.723843999059618],
            [-75.896079, 42.790963999059443],
            [-75.917189, 43.085778999058768],
            [-75.975588, 43.091277999058754],
            [-75.993394, 43.183375999058569]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "313",
        COUNTYNS: "01383939",
        AFFGEOID: "0500000US48313",
        GEOID: "48313",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 1207103467,
        AWATER: 16493254
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.24102, 30.973736999166924],
            [-95.975218, 31.091966999165102],
            [-95.76441, 31.094210999165067],
            [-95.656487, 31.079338999165287],
            [-95.681158, 30.986957999166727],
            [-95.617872, 30.930417999167588],
            [-95.616233, 30.910195999167907],
            [-95.716508, 30.911404999167889],
            [-95.863059, 30.864124999168617],
            [-96.16837, 30.822996999169266],
            [-96.207477, 30.947080999167337],
            [-96.24102, 30.973736999166924]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "017",
        COUNTYNS: "00974107",
        AFFGEOID: "0500000US36017",
        GEOID: "36017",
        NAME: "Chenango",
        LSAD: "06",
        ALAND: 2314378336,
        AWATER: 13062477
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.889832, 42.723843999059618],
            [-75.428668, 42.745420999059554],
            [-75.295877, 42.744105999059549],
            [-75.330143, 42.568081999060034],
            [-75.403626, 42.509062999060184],
            [-75.374905, 42.41078399906047],
            [-75.415319, 42.314150999060764],
            [-75.418421, 42.195031999061136],
            [-75.483638, 42.195182999061132],
            [-75.63711, 42.195627999061124],
            [-75.638299, 42.24868599906096],
            [-75.836406, 42.244154999060981],
            [-75.86402, 42.415701999060452],
            [-75.889832, 42.723843999059618]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "477",
        COUNTYNS: "01384024",
        AFFGEOID: "0500000US48477",
        GEOID: "48477",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1564249679,
        AWATER: 46077120
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.734469, 30.241228999178507],
            [-96.640883, 30.296785999177615],
            [-96.357407, 30.373317999176386],
            [-96.299173, 30.37915199917629],
            [-96.272455, 30.358467999176622],
            [-96.178661, 30.394717999176034],
            [-96.15463, 30.330287999177077],
            [-96.094404, 30.309370999177411],
            [-96.093165, 30.225186999178771],
            [-96.15901, 30.206612999179068],
            [-96.191782, 30.138419999180186],
            [-96.146052, 30.070223999181302],
            [-96.159442, 30.053831999181565],
            [-96.292849, 30.09614999918087],
            [-96.62198, 30.044282999181728],
            [-96.647223, 30.145173999180074],
            [-96.794552, 30.160544999179823],
            [-96.734469, 30.241228999178507]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "291",
        COUNTYNS: "01383931",
        AFFGEOID: "0500000US48291",
        GEOID: "48291",
        NAME: "Liberty",
        LSAD: "06",
        ALAND: 3000343678,
        AWATER: 46378363
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.165897, 30.344975999176842],
            [-94.85252, 30.482906999174624],
            [-94.849414, 30.493552999174458],
            [-94.777341, 30.489001999174519],
            [-94.732732, 30.490065999174515],
            [-94.596338, 30.112100999180608],
            [-94.44491, 30.113014999180596],
            [-94.442235, 29.889537999184267],
            [-94.98146, 29.884275999184357],
            [-94.999834, 29.991321999182592],
            [-95.026772, 29.996738999182504],
            [-95.035692, 30.005063999182358],
            [-95.096708, 30.167213999179708],
            [-95.165897, 30.344975999176842]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "103",
        COUNTYNS: "00161578",
        AFFGEOID: "0500000US01103",
        GEOID: "01103",
        NAME: "Morgan",
        LSAD: "06",
        ALAND: 1500602270,
        AWATER: 50931876
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.105073, 34.686036999116723],
            [-86.838554, 34.556631999118224],
            [-86.790056, 34.550789999118294],
            [-86.689353, 34.586424999117867],
            [-86.646393, 34.548920999118316],
            [-86.578855, 34.58135499911792],
            [-86.550166, 34.545962999118345],
            [-86.58132, 34.371093999120411],
            [-86.581936, 34.304693999121191],
            [-87.110111, 34.31379899912109],
            [-87.105073, 34.686036999116723]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "009",
        COUNTYNS: "01558262",
        AFFGEOID: "0500000US54009",
        GEOID: "54009",
        NAME: "Brooke",
        LSAD: "06",
        ALAND: 231034166,
        AWATER: 8714144
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.684173210707996, 40.187017972420485],
            [-80.644598, 40.251269999069429],
            [-80.606596, 40.303868999069159],
            [-80.631596, 40.385467999068716],
            [-80.627362629936897, 40.395170932085882],
            [-80.573784, 40.399754999068648],
            [-80.519029691194092, 40.399641055409354],
            [-80.519039, 40.342100999068954],
            [-80.519084370456596, 40.159671994188024],
            [-80.664006, 40.178946999069822],
            [-80.684173210707996, 40.187017972420485]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "053",
        COUNTYNS: "01074039",
        AFFGEOID: "0500000US39053",
        GEOID: "39053",
        NAME: "Gallia",
        LSAD: "06",
        ALAND: 1208307867,
        AWATER: 12085303
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.575583, 38.844477999078123],
            [-82.455179, 38.844576999078122],
            [-82.435309, 39.035078999076802],
            [-82.322874, 39.027673999076868],
            [-82.095465, 39.00277799907704],
            [-82.098866650554299, 38.96087928357062],
            [-82.134766, 38.905578999077704],
            [-82.16157, 38.824631999078257],
            [-82.20929, 38.80267199907842],
            [-82.201537, 38.760371999078721],
            [-82.185567, 38.659582999079433],
            [-82.175167, 38.608483999079795],
            [-82.218967, 38.591682999079922],
            [-82.27427, 38.59368299907991],
            [-82.282133344028097, 38.579860795483505],
            [-82.361674, 38.585181999079971],
            [-82.354468, 38.676069999079324],
            [-82.472478, 38.682278999079273],
            [-82.483579, 38.772277999078625],
            [-82.582683, 38.779076999078576],
            [-82.575583, 38.844477999078123]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "133",
        COUNTYNS: "00161592",
        AFFGEOID: "0500000US01133",
        GEOID: "01133",
        NAME: "Winston",
        LSAD: "06",
        ALAND: 1587608917,
        AWATER: 48922729
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.634725, 34.306996999121175],
            [-87.529722, 34.304597999121199],
            [-87.109911, 34.29929899912127],
            [-87.111992, 33.992384999124994],
            [-87.151036, 33.993224999124969],
            [-87.170054, 34.005203999124831],
            [-87.216735, 33.993906999124981],
            [-87.636118, 34.002202999124883],
            [-87.634725, 34.306996999121175]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "131",
        COUNTYNS: "00161591",
        AFFGEOID: "0500000US01131",
        GEOID: "01131",
        NAME: "Wilcox",
        LSAD: "06",
        ALAND: 2301075110,
        AWATER: 49057525
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.667769, 31.991354999151721],
            [-87.622756, 32.005481999151527],
            [-87.624005, 32.132344999149709],
            [-87.521946, 32.132815999149706],
            [-87.514066, 32.265992999147812],
            [-87.472206, 32.264918999147824],
            [-87.324783, 32.199068999148764],
            [-87.17786, 32.047513999150929],
            [-86.906956, 32.047969999150915],
            [-86.856576, 32.04835199915091],
            [-86.857583, 31.962166999152149],
            [-86.908939, 31.96167299915216],
            [-86.906899, 31.830627999154061],
            [-87.50093, 31.829250999154073],
            [-87.620112, 31.827122999154106],
            [-87.666836, 31.875746999153403],
            [-87.667769, 31.991354999151721]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "107",
        COUNTYNS: "00161580",
        AFFGEOID: "0500000US01107",
        GEOID: "01107",
        NAME: "Pickens",
        LSAD: "06",
        ALAND: 2282893859,
        AWATER: 22521211
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.317135, 33.184122999135283],
            [-88.304442813510377, 33.288319999133918],
            [-88.274516470783695, 33.534001476915932],
            [-87.946519, 33.524064999130871],
            [-87.840683, 33.524838999130857],
            [-87.837521, 33.153636999135685],
            [-87.968348, 33.078234999136683],
            [-88.149395, 33.037297999137223],
            [-88.171852, 32.995859999137778],
            [-88.340084992139296, 32.991264337012836],
            [-88.317135, 33.184122999135283]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "059",
        COUNTYNS: "01550036",
        AFFGEOID: "0500000US54059",
        GEOID: "54059",
        NAME: "Mingo",
        LSAD: "06",
        ALAND: 1095926624,
        AWATER: 2177833
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.403743, 37.862893999085493],
            [-82.306391, 37.943639999084851],
            [-82.190658, 37.974777999084615],
            [-82.112411, 37.854038999085581],
            [-82.082648, 37.701564999086827],
            [-81.993444, 37.640606999087325],
            [-81.930238, 37.682010999086977],
            [-81.802937, 37.660507999087159],
            [-81.795537, 37.631508999087409],
            [-81.855939, 37.548909999088103],
            [-81.890889, 37.526831999088273],
            [-81.932279, 37.511960999088402],
            [-81.968297, 37.537797999088184],
            [-82.064418, 37.544515999088134],
            [-82.141555, 37.595165999087705],
            [-82.226111, 37.653091999087231],
            [-82.296118, 37.686173999086947],
            [-82.3206749571778, 37.745965842545857],
            [-82.327356, 37.762232999086322],
            [-82.369973, 37.801748999086008],
            [-82.398464816258894, 37.843054149548557],
            [-82.403743, 37.862893999085493]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "065",
        COUNTYNS: "01557950",
        AFFGEOID: "0500000US54065",
        GEOID: "54065",
        NAME: "Morgan",
        LSAD: "06",
        ALAND: 593295476,
        AWATER: 1401570
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.460951, 39.525986999073638],
            [-78.438179, 39.563523999073404],
            [-78.382959, 39.622245999073044],
            [-78.332788021499098, 39.628527595571803],
            [-78.313033, 39.631000999072988],
            [-78.225075, 39.658877999072828],
            [-78.08226, 39.671165999072748],
            [-78.027629100173186, 39.620655961417164],
            [-78.138937, 39.593795999073222],
            [-78.229129534218501, 39.390663886278581],
            [-78.347087, 39.466011999074013],
            [-78.457749, 39.510899999073729],
            [-78.468269595002894, 39.526224269545338],
            [-78.460951, 39.525986999073638]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "043",
        COUNTYNS: "00066851",
        AFFGEOID: "0500000US05043",
        GEOID: "05043",
        NAME: "Drew",
        LSAD: "06",
        ALAND: 2145434146,
        AWATER: 18914033
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.000134, 33.473926999131514],
            [-91.97608, 33.529686999130796],
            [-91.975844, 33.704413999128569],
            [-91.975974, 33.791786999127488],
            [-91.663738, 33.792245999127466],
            [-91.557648, 33.783383999127587],
            [-91.450325, 33.780414999127622],
            [-91.453438, 33.563702999130356],
            [-91.45737, 33.38897399913261],
            [-91.990167, 33.398213999132487],
            [-92.000134, 33.473926999131514]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "119",
        COUNTYNS: "00695780",
        AFFGEOID: "0500000US28119",
        GEOID: "28119",
        NAME: "Quitman",
        LSAD: "06",
        ALAND: 1048975544,
        AWATER: 3676001
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.450572, 34.262401999121714],
            [-90.399014, 34.261523999121721],
            [-90.399498, 34.423806999119776],
            [-90.301851, 34.424545999119772],
            [-90.304317, 34.509620999118766],
            [-90.198536, 34.511087999118757],
            [-90.194086, 34.301406999121234],
            [-90.134441, 34.265194999121675],
            [-90.135456, 34.160073999122943],
            [-90.135695, 34.072899999124004],
            [-90.450981, 34.072931999124009],
            [-90.450572, 34.262401999121714]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "063",
        COUNTYNS: "00758486",
        AFFGEOID: "0500000US29063",
        GEOID: "29063",
        NAME: "DeKalb",
        LSAD: "06",
        ALAND: 1091311254,
        AWATER: 11561078
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.605377, 40.039216999070604],
            [-94.2188, 40.034884999070627],
            [-94.203595, 40.025047999070686],
            [-94.205844, 39.788953999072042],
            [-94.205961, 39.7458409990723],
            [-94.602257, 39.747194999072285],
            [-94.602819, 39.81960799907187],
            [-94.605377, 40.039216999070604]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "171",
        COUNTYNS: "00758540",
        AFFGEOID: "0500000US29171",
        GEOID: "29171",
        NAME: "Putnam",
        LSAD: "06",
        ALAND: 1339843851,
        AWATER: 5943334
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.374386258763806, 40.580397031676931],
            [-93.345442, 40.580513999067726],
            [-93.135802, 40.582853999067716],
            [-93.097291203749805, 40.583823476392809],
            [-92.941595, 40.587742999067686],
            [-92.714597263045007, 40.589582832903986],
            [-92.683162, 40.560662999067823],
            [-92.70932, 40.445892999068406],
            [-92.684167, 40.34346599906894],
            [-92.855629, 40.342735999068935],
            [-92.855313, 40.385893999068728],
            [-93.366935, 40.382998999068739],
            [-93.37484, 40.472211999068271],
            [-93.374386258763806, 40.580397031676931]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "091",
        COUNTYNS: "01677577",
        AFFGEOID: "0500000US30091",
        GEOID: "30091",
        NAME: "Sheridan",
        LSAD: "06",
        ALAND: 4340307007,
        AWATER: 75071798
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.057641716691009, 48.999228886458653],
            [-104.875527, 48.998990999066159],
            [-104.543636, 48.999540999066156],
            [-104.048736, 48.99987699906616],
            [-104.0489, 48.847386999065456],
            [-104.048087568832003, 48.634012372463147],
            [-104.047555, 48.494139999063968],
            [-104.046782161706005, 48.389300730209044],
            [-104.62776, 48.389361999063546],
            [-104.627726, 48.476348999063894],
            [-104.757643, 48.476555999063891],
            [-104.757619, 48.563126999064245],
            [-104.973354, 48.563189999064242],
            [-104.973283, 48.650059999064609],
            [-105.038548, 48.650098999064603],
            [-105.038171, 48.910511999065747],
            [-105.057641716691009, 48.999228886458653]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "091",
        COUNTYNS: "01689856",
        AFFGEOID: "0500000US54091",
        GEOID: "54091",
        NAME: "Taylor",
        LSAD: "06",
        ALAND: 447475054,
        AWATER: 7532835
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.197286, 39.393185999074475],
            [-80.087334, 39.386214999074511],
            [-79.938878, 39.452464999074095],
            [-79.894688, 39.435587999074201],
            [-79.895536, 39.299583999075068],
            [-80.023411, 39.247891999075406],
            [-80.166387, 39.242282999075442],
            [-80.199504, 39.270689999075259],
            [-80.197286, 39.393185999074475]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "005",
        COUNTYNS: "00295738",
        AFFGEOID: "0500000US12005",
        GEOID: "12005",
        NAME: "Bay",
        LSAD: "06",
        ALAND: 1964404472,
        AWATER: 711449254
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.992736, 30.389319999176127],
            [-85.8535, 30.440622999175304],
            [-85.487968, 30.436654999175371],
            [-85.486358, 30.567573999173266],
            [-85.434782, 30.567559999173287],
            [-85.383948, 30.566855999173296],
            [-85.389679, 30.200972999179164],
            [-85.389240033231999, 29.924114861787395],
            [-85.425956, 29.949887999183275],
            [-85.487764, 29.96122699918309],
            [-85.571907, 30.026439999182013],
            [-85.601178, 30.056341999181523],
            [-85.69681, 30.096889999180856],
            [-85.811219, 30.178319999179536],
            [-85.996102593975991, 30.26890138038717],
            [-85.992736, 30.389319999176127]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "007",
        COUNTYNS: "01804483",
        AFFGEOID: "0500000US72007",
        GEOID: "72007",
        NAME: "Aguas Buenas",
        LSAD: "13",
        ALAND: 77919490,
        AWATER: 28383
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.190676, 18.258839999437949],
            [-66.143861, 18.279589999437405],
            [-66.086465, 18.285093999437258],
            [-66.080475, 18.297294999436929],
            [-66.066103, 18.303320999436774],
            [-66.090193, 18.227599999438784],
            [-66.117774, 18.209948999439241],
            [-66.141065, 18.197905999439566],
            [-66.169126, 18.22428999943887],
            [-66.190676, 18.258839999437949]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "061",
        COUNTYNS: "01804511",
        AFFGEOID: "0500000US72061",
        GEOID: "72061",
        NAME: "Guaynabo",
        LSAD: "13",
        ALAND: 71444631,
        AWATER: 468304
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.121725, 18.414459999433838],
            [-66.13158, 18.424655999433572],
            [-66.108281, 18.438901999433199],
            [-66.099586, 18.342221999435743],
            [-66.080475, 18.297294999436929],
            [-66.086465, 18.285093999437258],
            [-66.143861, 18.279589999437405],
            [-66.121725, 18.414459999433838]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "005",
        COUNTYNS: "01550009",
        AFFGEOID: "0500000US54005",
        GEOID: "54005",
        NAME: "Boone",
        LSAD: "06",
        ALAND: 1298967373,
        AWATER: 4305976
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.932507, 38.025355999084205],
            [-81.953263, 38.118877999083473],
            [-81.878779, 38.137201999083338],
            [-81.83347, 38.209569999082767],
            [-81.64387, 38.22075899908269],
            [-81.515836, 38.112359999083523],
            [-81.456632, 37.987306999084517],
            [-81.571534, 37.92770699908499],
            [-81.514228, 37.79121099908609],
            [-81.57653, 37.763309999086317],
            [-81.607532, 37.788708999086111],
            [-81.722136, 37.809506999085933],
            [-81.785641, 37.936403999084916],
            [-81.980248, 37.986499999084522],
            [-81.932507, 38.025355999084205]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "041",
        COUNTYNS: "01804500",
        AFFGEOID: "0500000US72041",
        GEOID: "72041",
        NAME: "Cidra",
        LSAD: "13",
        ALAND: 93294534,
        AWATER: 1123798
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.24046, 18.184793999439915],
            [-66.211851, 18.173086999440223],
            [-66.169126, 18.22428999943887],
            [-66.141065, 18.197905999439566],
            [-66.117774, 18.209948999439241],
            [-66.095099, 18.169076999440335],
            [-66.110176, 18.142036999441046],
            [-66.216899, 18.143219999441019],
            [-66.242735, 18.180247999440034],
            [-66.24046, 18.184793999439915]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "091",
        COUNTYNS: "00695769",
        AFFGEOID: "0500000US28091",
        GEOID: "28091",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 1404769593,
        AWATER: 16149942
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.040485, 31.394672999160509],
            [-89.95894, 31.390488999160574],
            [-89.823332, 31.390020999160576],
            [-89.824236, 31.434092999159919],
            [-89.654212, 31.433804999159925],
            [-89.654038, 31.002501999166476],
            [-89.728175690160597, 31.002313887150581],
            [-89.728147, 31.002430999166481],
            [-89.835907824328189, 31.002101422284088],
            [-89.835873, 31.074925999165369],
            [-89.920944, 31.074676999165366],
            [-89.938178, 31.132948999164476],
            [-90.031896, 31.175726999163825],
            [-90.040466, 31.336013999161395],
            [-90.040485, 31.394672999160509]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "063",
        COUNTYNS: "00835853",
        AFFGEOID: "0500000US31063",
        GEOID: "31063",
        NAME: "Frontier",
        LSAD: "06",
        ALAND: 2524180462,
        AWATER: 14212842
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.777833, 40.700134999067139],
            [-100.223311, 40.700253999067137],
            [-99.981743, 40.700002999067138],
            [-99.981193, 40.43829099906845],
            [-100.095095, 40.438655999068459],
            [-100.094798, 40.351244999068903],
            [-100.197807, 40.350026999068916],
            [-100.758435, 40.349506999068907],
            [-100.777376, 40.349465999068912],
            [-100.777833, 40.700134999067139]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "083",
        COUNTYNS: "00161567",
        AFFGEOID: "0500000US01083",
        GEOID: "01083",
        NAME: "Limestone",
        LSAD: "06",
        ALAND: 1450278151,
        AWATER: 122329451
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.278302, 34.773562999115704],
            [-87.214914, 34.816010999115221],
            [-87.210758579982297, 34.999049180112848],
            [-86.836286315949508, 34.992803003330231],
            [-86.783648, 34.991924999113238],
            [-86.783628017169008, 34.99192492110523],
            [-86.790056, 34.550789999118294],
            [-86.838554, 34.556631999118224],
            [-87.105073, 34.686036999116723],
            [-87.222773, 34.763225999115818],
            [-87.260676, 34.758625999115885],
            [-87.278302, 34.773562999115704]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "013",
        COUNTYNS: "00306919",
        AFFGEOID: "0500000US12013",
        GEOID: "12013",
        NAME: "Calhoun",
        LSAD: "06",
        ALAND: 1469389207,
        AWATER: 18074798
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.383948, 30.566855999173296],
            [-85.171891, 30.564335999173331],
            [-85.167687, 30.607978999172644],
            [-84.932519, 30.606392999172662],
            [-84.990017, 30.52479499917396],
            [-84.983203, 30.441836999175283],
            [-85.04277, 30.352465999176719],
            [-85.03429, 30.312477999177361],
            [-85.11566, 30.199975999179181],
            [-85.389679, 30.200972999179164],
            [-85.383948, 30.566855999173296]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "117",
        COUNTYNS: "00303665",
        AFFGEOID: "0500000US12117",
        GEOID: "12117",
        NAME: "Seminole",
        LSAD: "06",
        ALAND: 801709341,
        AWATER: 92719341
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.414446, 28.784954999203148],
            [-81.416846, 28.824568999202459],
            [-81.36694, 28.879226999201489],
            [-81.320473, 28.834529999202271],
            [-81.226003, 28.832629999202304],
            [-81.181433, 28.783276999203178],
            [-81.067413, 28.812988999202648],
            [-81.06111, 28.750000999203763],
            [-80.98725, 28.612996999206196],
            [-81.129006, 28.612534999206204],
            [-81.32789, 28.610276999206242],
            [-81.407633, 28.64010399920571],
            [-81.459728, 28.640195999205712],
            [-81.414446, 28.784954999203148]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "205",
        COUNTYNS: "00343645",
        AFFGEOID: "0500000US13205",
        GEOID: "13205",
        NAME: "Mitchell",
        LSAD: "06",
        ALAND: 1326342585,
        AWATER: 4454719
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.471001, 31.167980999163934],
            [-84.40435, 31.199142999163463],
            [-84.340552, 31.305340999161853],
            [-84.20066, 31.355524999161101],
            [-84.141124, 31.440178999159823],
            [-84.11864, 31.442478999159789],
            [-83.997796, 31.44375299915977],
            [-83.99943, 31.334965999161412],
            [-84.002968, 31.113172999164778],
            [-84.013851, 31.102435999164939],
            [-84.003627, 31.07728999916533],
            [-84.116644, 31.077970999165313],
            [-84.376612, 31.078882999165291],
            [-84.508078, 31.07839999916531],
            [-84.471001, 31.167980999163934]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "023",
        COUNTYNS: "00695736",
        AFFGEOID: "0500000US28023",
        GEOID: "28023",
        NAME: "Clarke",
        LSAD: "06",
        ALAND: 1791177222,
        AWATER: 5069761
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.913847, 32.224185999148403],
            [-88.431145363462804, 32.227636233837252],
            [-88.453387763109276, 32.053049204570549],
            [-88.46866, 31.933172999152564],
            [-88.468662484051293, 31.893855710768733],
            [-88.625036, 31.856688999153675],
            [-88.809174, 31.832896999154023],
            [-88.910459, 31.826648999154116],
            [-88.913847, 32.224185999148403]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "045",
        COUNTYNS: "01266972",
        AFFGEOID: "0500000US46045",
        GEOID: "46045",
        NAME: "Edmunds",
        LSAD: "06",
        ALAND: 2916221362,
        AWATER: 65024748
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.707705, 45.593390999057],
            [-98.725002, 45.591251999056993],
            [-98.722247, 45.562647999056963],
            [-98.722481, 45.24376399905681],
            [-99.573445, 45.245363999056799],
            [-99.708987, 45.245865999056811],
            [-99.707705, 45.593390999057]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "085",
        COUNTYNS: "00695766",
        AFFGEOID: "0500000US28085",
        GEOID: "28085",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 1518055421,
        AWATER: 5309826
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.736824, 31.698490999155982],
            [-90.414198, 31.70045599915596],
            [-90.379409, 31.685193999156184],
            [-90.245191, 31.717523999155706],
            [-90.24389, 31.350273999161178],
            [-90.260391, 31.350273999161178],
            [-90.548199, 31.349573999161187],
            [-90.633302, 31.349305999161192],
            [-90.633231, 31.61140899915727],
            [-90.73733, 31.611123999157282],
            [-90.736824, 31.698490999155982]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "131",
        COUNTYNS: "00351261",
        AFFGEOID: "0500000US13131",
        GEOID: "13131",
        NAME: "Grady",
        LSAD: "06",
        ALAND: 1177152222,
        AWATER: 14925882
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.376612, 31.078882999165291],
            [-84.116644, 31.077970999165313],
            [-84.119058, 30.98095599916681],
            [-84.075958, 30.912537999167871],
            [-84.083753082299992, 30.675943397139566],
            [-84.124993, 30.678036999171539],
            [-84.285514509039487, 30.684809171119426],
            [-84.380754183552497, 30.688827196217272],
            [-84.376612, 31.078882999165291]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "147",
        COUNTYNS: "01804554",
        AFFGEOID: "0500000US72147",
        GEOID: "72147",
        NAME: "Vieques",
        LSAD: "13",
        ALAND: 131527813,
        AWATER: 552206400
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-65.57686, 18.103223999442083],
            [-65.50592, 18.152604999440769],
            [-65.398166, 18.161722999440524],
            [-65.287962, 18.148096999440888],
            [-65.291235, 18.103468999442079],
            [-65.374419, 18.108040999441954],
            [-65.45138, 18.086095999442534],
            [-65.542087, 18.081176999442665],
            [-65.57686, 18.103223999442083]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "099",
        COUNTYNS: "01804530",
        AFFGEOID: "0500000US72099",
        GEOID: "72099",
        NAME: "Moca",
        LSAD: "13",
        ALAND: 130383573,
        AWATER: 56474
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.13221, 18.3893909994345],
            [-67.125171, 18.408925999433986],
            [-67.056507, 18.460953999432611],
            [-67.027173, 18.393375999434394],
            [-67.050233, 18.348540999435581],
            [-67.052583, 18.306654999436688],
            [-67.116447, 18.323400999436249],
            [-67.130167, 18.317926999436388],
            [-67.126651, 18.371446999434976],
            [-67.13221, 18.3893909994345]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "135",
        COUNTYNS: "00446853",
        AFFGEOID: "0500000US18135",
        GEOID: "18135",
        NAME: "Randolph",
        LSAD: "06",
        ALAND: 1171656639,
        AWATER: 2420286
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.218758, 40.306705999069138],
            [-84.804917302578403, 40.310095914103215],
            [-84.808706, 40.107215999070227],
            [-84.810161238474493, 40.005067542919001],
            [-85.201473, 40.004520999070792],
            [-85.213543, 40.015602999070737],
            [-85.214386, 40.076888999070391],
            [-85.218758, 40.306705999069138]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "111",
        COUNTYNS: "00659501",
        AFFGEOID: "0500000US27111",
        GEOID: "27111",
        NAME: "Otter Tail",
        LSAD: "06",
        ALAND: 5107436184,
        AWATER: 654101087
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.280977, 46.630764999058385],
            [-96.17227, 46.630708999058385],
            [-96.174587, 46.717853999058555],
            [-95.247999, 46.716254999058549],
            [-95.16371, 46.717639999058555],
            [-95.15554, 46.629939999058379],
            [-95.15557, 46.36887999905791],
            [-95.145791, 46.282367999057783],
            [-95.14588, 46.106760999057535],
            [-95.76975, 46.107449999057529],
            [-96.265366, 46.108609999057535],
            [-96.28, 46.28221999905778],
            [-96.280977, 46.630764999058385]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "073",
        COUNTYNS: "01480126",
        AFFGEOID: "0500000US51073",
        GEOID: "51073",
        NAME: "Gloucester",
        LSAD: "06",
        ALAND: 564117815,
        AWATER: 181754413
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.712305, 37.43130899908909],
            [-76.646794, 37.481153999088669],
            [-76.654076, 37.60317399908763],
            [-76.55593, 37.555053999088045],
            [-76.437561, 37.514359999088384],
            [-76.447944, 37.420063999089187],
            [-76.402945875352898, 37.392600000880634],
            [-76.437525, 37.379749999089533],
            [-76.38777, 37.307669999090159],
            [-76.36229, 37.270225999090485],
            [-76.376936809717506, 37.24949176145396],
            [-76.501939, 37.241755999090728],
            [-76.658651, 37.380138999089525],
            [-76.712305, 37.43130899908909]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "10",
        COUNTYFP: "001",
        COUNTYNS: "00217271",
        AFFGEOID: "0500000US10001",
        GEOID: "10001",
        NAME: "Kent",
        LSAD: "06",
        ALAND: 1518568163,
        AWATER: 549099845
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.76044141645049, 39.296789620175183],
            [-75.714901, 39.299365999075079],
            [-75.651159, 39.291593999075111],
            [-75.619631, 39.310057999074992],
            [-75.584341, 39.308717999075],
            [-75.505643167352886, 39.370394559154207],
            [-75.469324, 39.330819999074862],
            [-75.408376, 39.264697999075295],
            [-75.39479, 39.188353999075787],
            [-75.407473, 39.133705999076163],
            [-75.396277, 39.057883999076665],
            [-75.34089, 39.019959999076917],
            [-75.306652109509699, 38.947660162405811],
            [-75.380649, 38.961872999077308],
            [-75.410463, 38.91641799907763],
            [-75.484125, 38.904447999077703],
            [-75.555013, 38.835648999078181],
            [-75.723102693332692, 38.829826555605926],
            [-75.748154814254093, 39.14313173003579],
            [-75.756435215568501, 39.246687536200724],
            [-75.76044141645049, 39.296789620175183]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "127",
        COUNTYNS: "01480150",
        AFFGEOID: "0500000US51127",
        GEOID: "51127",
        NAME: "New Kent",
        LSAD: "06",
        ALAND: 543990076,
        AWATER: 35597678
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.240988, 37.538087999088177],
            [-77.140457, 37.592168999087733],
            [-77.123675, 37.62688099908744],
            [-76.981158, 37.567112999087939],
            [-76.858427, 37.57954099908784],
            [-76.878069, 37.531376999088238],
            [-76.792184, 37.517593999088355],
            [-76.741984, 37.467075999088784],
            [-76.891798, 37.432631999089082],
            [-76.904471, 37.377664999089561],
            [-76.904116, 37.403820999089334],
            [-77.021316, 37.416009999089226],
            [-77.177324, 37.490599999088595],
            [-77.221306, 37.533251999088222],
            [-77.240988, 37.538087999088177]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "195",
        COUNTYNS: "00485058",
        AFFGEOID: "0500000US20195",
        GEOID: "20195",
        NAME: "Trego",
        LSAD: "06",
        ALAND: 2303731642,
        AWATER: 26281951
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.14794, 39.132178999076167],
            [-99.605187, 39.132480999076165],
            [-99.591776, 39.132356999076173],
            [-99.598323, 38.696513999079173],
            [-100.153823, 38.697340999079167],
            [-100.14794, 39.132178999076167]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "137",
        COUNTYNS: "00069902",
        AFFGEOID: "0500000US05137",
        GEOID: "05137",
        NAME: "Stone",
        LSAD: "06",
        ALAND: 1570579424,
        AWATER: 7841928
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.416719, 35.78858999910463],
            [-92.414324, 35.975864999102711],
            [-92.30722, 35.974021999102725],
            [-92.304951, 36.06107999910185],
            [-92.197649, 36.059286999101865],
            [-92.19543, 36.132797999101122],
            [-92.139104, 36.112220999101325],
            [-92.063576, 36.024371999102222],
            [-92.108622, 35.931327999103161],
            [-91.95302, 35.931643999103152],
            [-91.849448, 35.867410999103811],
            [-91.870983, 35.810561999104401],
            [-91.837179, 35.704931999105504],
            [-92.24052, 35.712189999105426],
            [-92.418408, 35.71604599910539],
            [-92.416719, 35.78858999910463]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "043",
        COUNTYNS: "01008546",
        AFFGEOID: "0500000US37043",
        GEOID: "37043",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 556804508,
        AWATER: 15017754
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.005336733438284, 34.987649583125574],
            [-83.855493, 35.137955999111597],
            [-83.738129, 35.155949999111392],
            [-83.659824, 35.160303999111342],
            [-83.482873204290698, 34.990873960400044],
            [-83.549180753504686, 34.988803195027273],
            [-83.619985, 34.986591999113294],
            [-83.93642734425201, 34.987484382492987],
            [-83.936646, 34.987484999113285],
            [-84.005336733438284, 34.987649583125574]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "133",
        COUNTYNS: "01026341",
        AFFGEOID: "0500000US37133",
        GEOID: "37133",
        NAME: "Onslow",
        LSAD: "06",
        ALAND: 1973376983,
        AWATER: 371063373
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.653352, 34.940534999113808],
            [-77.674805, 34.973899999113435],
            [-77.502771, 34.969634999113481],
            [-77.366768, 34.908649999114175],
            [-77.267647, 34.925789999113974],
            [-77.165986, 34.785031999115581],
            [-77.096073, 34.671239999116885],
            [-77.112964393076794, 34.638092831508771],
            [-77.136843, 34.63292599911734],
            [-77.240991, 34.587506999117849],
            [-77.322524, 34.535573999118469],
            [-77.462922, 34.471353999119223],
            [-77.515216544584788, 34.437384968546226],
            [-77.576165, 34.476606999119163],
            [-77.680127, 34.720594999116322],
            [-77.653352, 34.940534999113808]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "143",
        COUNTYNS: "00350637",
        AFFGEOID: "0500000US13143",
        GEOID: "13143",
        NAME: "Haralson",
        LSAD: "06",
        ALAND: 730804590,
        AWATER: 2616530
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.38667057377279, 33.901701493129416],
            [-85.05031, 33.904487999126083],
            [-85.036861, 33.89258699912623],
            [-85.037926, 33.811941999127228],
            [-85.050889, 33.71463199912845],
            [-85.338116299905906, 33.653114390730615],
            [-85.360532, 33.767956999127776],
            [-85.38667057377279, 33.901701493129416]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "005",
        COUNTYNS: "00695727",
        AFFGEOID: "0500000US28005",
        GEOID: "28005",
        NAME: "Amite",
        LSAD: "06",
        ALAND: 1890950867,
        AWATER: 3877823
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.065741, 31.338897999161347],
            [-91.018012, 31.347501999161228],
            [-90.633302, 31.349305999161192],
            [-90.548199, 31.349573999161187],
            [-90.547574425233094, 30.99998210095811],
            [-90.567195400658491, 30.999945023566418],
            [-90.758775, 30.999582999166527],
            [-90.825829141291905, 30.999525354507121],
            [-91.060127467996594, 30.999323934475722],
            [-91.059469, 31.281920999162196],
            [-91.095398, 31.320974999161617],
            [-91.065741, 31.338897999161347]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "041",
        COUNTYNS: "00484990",
        AFFGEOID: "0500000US20041",
        GEOID: "20041",
        NAME: "Dickinson",
        LSAD: "06",
        ALAND: 2193894222,
        AWATER: 12632461
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.381205, 38.928899999077551],
            [-97.371614, 38.957851999077342],
            [-97.371154, 39.131960999076178],
            [-96.963177, 39.13229999907616],
            [-96.96347, 38.964900999077287],
            [-96.926209, 38.97894099907721],
            [-96.92671, 38.870349999077938],
            [-96.890245, 38.870066999077949],
            [-96.927234, 38.812159999078339],
            [-96.930286, 38.609361999079795],
            [-97.371911, 38.609352999079803],
            [-97.381205, 38.928899999077551]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "151",
        COUNTYNS: "00835897",
        AFFGEOID: "0500000US31151",
        GEOID: "31151",
        NAME: "Saline",
        LSAD: "06",
        ALAND: 1486698710,
        AWATER: 5934626
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.368401, 40.69862499906715],
            [-96.913493, 40.697947999067139],
            [-96.912637, 40.523624999068012],
            [-96.913513, 40.370370999068804],
            [-96.916055, 40.349726999068913],
            [-97.368688, 40.350391999068904],
            [-97.368401, 40.69862499906715]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "037",
        COUNTYNS: "01639737",
        AFFGEOID: "0500000US47037",
        GEOID: "47037",
        NAME: "Davidson",
        LSAD: "06",
        ALAND: 1304960561,
        AWATER: 56649279
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.041362, 36.041311999102042],
            [-86.963358, 36.267003999099799],
            [-86.906254, 36.366217999098822],
            [-86.913233, 36.38262099909867],
            [-86.825053, 36.349816999098991],
            [-86.754795, 36.405495999098449],
            [-86.639095, 36.255104999099927],
            [-86.594286, 36.245104999100008],
            [-86.556537, 36.135266999101098],
            [-86.515589, 36.100609999101444],
            [-86.546884, 36.087816999101584],
            [-86.618892, 35.968223999102783],
            [-86.790875, 36.036714999102102],
            [-86.9292, 36.052721999101927],
            [-87.040407, 35.989225999102572],
            [-87.054114, 36.04553499910201],
            [-87.041362, 36.041311999102042]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "017",
        COUNTYNS: "00161534",
        AFFGEOID: "0500000US01017",
        GEOID: "01017",
        NAME: "Chambers",
        LSAD: "06",
        ALAND: 1545009122,
        AWATER: 17048126
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.593177, 33.1073399991363],
            [-85.232441405187288, 33.108077093069895],
            [-85.232441, 33.108074999136285],
            [-85.18611740178919, 32.870138269111074],
            [-85.1844, 32.861316999139596],
            [-85.160963, 32.826671999140054],
            [-85.124533222750586, 32.751629605892482],
            [-85.285043, 32.730730999141365],
            [-85.574954, 32.736346999141276],
            [-85.593151, 32.728529999141386],
            [-85.593177, 33.1073399991363]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "023",
        COUNTYNS: "01135851",
        AFFGEOID: "0500000US41023",
        GEOID: "41023",
        NAME: "Grant",
        LSAD: "06",
        ALAND: 11726927790,
        AWATER: 1893807
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.672228, 44.995116999056769],
            [-119.643745, 44.99459599905677],
            [-119.162966, 44.996240999056766],
            [-118.518689, 44.995876999056755],
            [-118.4361, 44.962419999056763],
            [-118.364214, 44.990762999056763],
            [-118.244662, 44.958395999056769],
            [-118.228244, 44.865177999056769],
            [-118.295659, 44.863042999056759],
            [-118.285439, 44.750974999056787],
            [-118.380067, 44.744226999056792],
            [-118.51944, 44.705885999056804],
            [-118.504327, 44.665271999056806],
            [-118.375037, 44.646101999056818],
            [-118.305778, 44.588809999056835],
            [-118.408786, 44.452647999056914],
            [-118.497499, 44.255247999057033],
            [-118.232214, 44.256128999057047],
            [-118.228843, 44.212549999057082],
            [-118.227645, 44.039861999057244],
            [-118.587125, 44.04026599905724],
            [-118.81689, 44.047827999057226],
            [-118.816931, 43.960732999057321],
            [-119.657183, 43.958919999057329],
            [-119.655517, 44.307044999056998],
            [-119.652431, 44.823298999056775],
            [-119.672228, 44.995116999056769]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "093",
        COUNTYNS: "00516893",
        AFFGEOID: "0500000US21093",
        GEOID: "21093",
        NAME: "Hardin",
        LSAD: "06",
        ALAND: 1614331601,
        AWATER: 17701432
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.151285, 37.798877999086024],
            [-86.007432, 37.818438999085863],
            [-85.975948, 37.875792999085405],
            [-85.997351659754301, 37.991225601383597],
            [-85.976028, 38.003559999084388],
            [-85.951729645915492, 38.014940126174899],
            [-85.938321, 37.998755999084423],
            [-85.854871, 37.945384999084851],
            [-85.838198, 37.879283999085381],
            [-85.737777, 37.811789999085924],
            [-85.736295, 37.781025999086175],
            [-85.680488, 37.732283999086576],
            [-85.735949, 37.654597999087208],
            [-85.875807, 37.543996999088137],
            [-85.892804, 37.440307999089022],
            [-86.047598, 37.449932999088936],
            [-86.114261, 37.567181999087943],
            [-86.27527, 37.593518999087728],
            [-86.151285, 37.798877999086024]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "181",
        COUNTYNS: "01688000",
        AFFGEOID: "0500000US13181",
        GEOID: "13181",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 544880787,
        AWATER: 121516946
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.636698, 33.974091999125221],
            [-82.562996798580897, 33.956554909652333],
            [-82.556835, 33.945352999125568],
            [-82.51295, 33.936968999125668],
            [-82.43115, 33.86705099912654],
            [-82.32448, 33.820032999127129],
            [-82.239098, 33.73087199912824],
            [-82.215938364747501, 33.687754974447486],
            [-82.308246, 33.700435999128622],
            [-82.425328, 33.650628999129253],
            [-82.479518, 33.639534999129388],
            [-82.593527, 33.827321999127044],
            [-82.572297, 33.866342999126559],
            [-82.605687, 33.859941999126626],
            [-82.645451, 33.984194999125087],
            [-82.636698, 33.974091999125221]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "151",
        COUNTYNS: "01101863",
        AFFGEOID: "0500000US40151",
        GEOID: "40151",
        NAME: "Woods",
        LSAD: "06",
        ALAND: 3331900449,
        AWATER: 9091699
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.407015, 36.99957899909289],
            [-99.129449, 36.999421999092888],
            [-99.000301053884186, 36.999358097237291],
            [-98.791936, 36.999254999092898],
            [-98.544661973348894, 36.998524239493499],
            [-98.533617, 36.463229999097877],
            [-98.53355, 36.397591999098523],
            [-98.625361, 36.39259499909857],
            [-98.80782, 36.460743999097907],
            [-98.843646, 36.506247999097468],
            [-98.960334, 36.506913999097478],
            [-98.959973, 36.5989909990966],
            [-99.139855, 36.783039999094875],
            [-99.250383, 36.785014999094855],
            [-99.295186, 36.819565999094529],
            [-99.374218, 36.879596999093984],
            [-99.456202434675276, 36.999700278500796],
            [-99.407015, 36.99957899909289]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "235",
        COUNTYNS: "00347593",
        AFFGEOID: "0500000US13235",
        GEOID: "13235",
        NAME: "Pulaski",
        LSAD: "06",
        ALAND: 645503546,
        AWATER: 5131020
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.615579, 32.288555999147484],
            [-83.523458, 32.402287999145898],
            [-83.498039, 32.401714999145902],
            [-83.346528, 32.272488999147718],
            [-83.373931, 32.249740999148031],
            [-83.291106, 32.178824999149043],
            [-83.337313, 32.105884999150085],
            [-83.361051, 32.124008999149822],
            [-83.608547, 32.118404999149895],
            [-83.606214, 32.275643999147668],
            [-83.615579, 32.288555999147484]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "113",
        COUNTYNS: "00516903",
        AFFGEOID: "0500000US21113",
        GEOID: "21113",
        NAME: "Jessamine",
        LSAD: "06",
        ALAND: 445871152,
        AWATER: 6166287
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.689021, 37.851090999085599],
            [-84.709918, 37.861951999085505],
            [-84.66034, 38.00383599908438],
            [-84.594057, 37.986876999084515],
            [-84.570703, 37.977586999084586],
            [-84.479629, 37.940218999084891],
            [-84.43572, 37.847201999085627],
            [-84.476283, 37.853130999085586],
            [-84.462031, 37.803430999085982],
            [-84.525643, 37.769496999086272],
            [-84.571158, 37.728549999086603],
            [-84.656608, 37.809907999085937],
            [-84.656903, 37.783920999086149],
            [-84.716925, 37.81538899908589],
            [-84.689021, 37.851090999085599]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "600",
        COUNTYNS: "01789070",
        AFFGEOID: "0500000US51600",
        GEOID: "51600",
        NAME: "Fairfax",
        LSAD: "25",
        ALAND: 16156802,
        AWATER: 90056
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.328788, 38.86484799907798],
            [-77.270228, 38.86563899907798],
            [-77.27052, 38.840901999078149],
            [-77.303067, 38.832821999078206],
            [-77.325298, 38.846286999078117],
            [-77.328788, 38.86484799907798]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "235",
        COUNTYNS: "01383903",
        AFFGEOID: "0500000US48235",
        GEOID: "48235",
        NAME: "Irion",
        LSAD: "06",
        ALAND: 2723485996,
        AWATER: 176317
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.267947, 31.5286879991585],
            [-100.693065, 31.523953999158575],
            [-100.688764, 31.086575999165184],
            [-100.962176, 31.082489999165247],
            [-101.274799, 31.0793789991653],
            [-101.267947, 31.5286879991585]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "095",
        COUNTYNS: "01622990",
        AFFGEOID: "0500000US26095",
        GEOID: "26095",
        NAME: "Luce",
        LSAD: "06",
        ALAND: 2328596732,
        AWATER: 2624713509
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.864932, 46.504757999058143],
            [-85.864607141329188, 46.6865683145719],
            [-85.841057, 46.688895999058495],
            [-85.482096, 46.680431999058477],
            [-85.25686, 46.75337999905863],
            [-85.237873177359575, 46.75570278031514],
            [-85.237839, 46.245429999057727],
            [-85.865025, 46.244630999057719],
            [-85.864932, 46.504757999058143]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "003",
        COUNTYNS: "00465191",
        AFFGEOID: "0500000US19003",
        GEOID: "19003",
        NAME: "Adams",
        LSAD: "06",
        ALAND: 1096700779,
        AWATER: 5353407
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.927587, 41.158507999065023],
            [-94.700589, 41.158084999065025],
            [-94.470603, 41.157565999065028],
            [-94.470779, 40.899501999066196],
            [-94.928459, 40.900652999066182],
            [-94.927587, 41.158507999065023]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "011",
        COUNTYNS: "00042807",
        AFFGEOID: "0500000US04011",
        GEOID: "04011",
        NAME: "Greenlee",
        LSAD: "06",
        ALAND: 4770701078,
        AWATER: 13750461
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.49544, 33.652696999129233],
            [-109.480135, 33.691409999128744],
            [-109.364621, 33.738755999128145],
            [-109.347968, 33.777455999127653],
            [-109.046607473921995, 33.778223799478162],
            [-109.047298, 33.409782999132332],
            [-109.047237, 33.208964999134956],
            [-109.047236980537008, 33.20889503068576],
            [-109.047117062309994, 32.77779400008842],
            [-109.047117, 32.777569999140724],
            [-109.047612, 32.426376999145546],
            [-109.047612003219982, 32.42637538765976],
            [-109.113987, 32.426176999145568],
            [-109.22607, 32.529095999144126],
            [-109.206249, 32.635802999142662],
            [-109.245005, 32.741984999141195],
            [-109.333425, 32.843265999139838],
            [-109.49574, 33.078408999136677],
            [-109.49544, 33.652696999129233]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "157",
        COUNTYNS: "00695799",
        AFFGEOID: "0500000US28157",
        GEOID: "28157",
        NAME: "Wilkinson",
        LSAD: "06",
        ALAND: 1756287150,
        AWATER: 25019144
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.628257298208794, 31.005097001061635],
            [-91.560365, 31.049507999165748],
            [-91.594693, 31.091443999165108],
            [-91.621671, 31.136869999164421],
            [-91.590993893064493, 31.19199856486237],
            [-91.488954, 31.22591999916305],
            [-91.468435, 31.319569999161633],
            [-91.306294, 31.360861999161006],
            [-91.15815, 31.346694999161222],
            [-91.145181, 31.347534999161212],
            [-91.095398, 31.320974999161617],
            [-91.059469, 31.281920999162196],
            [-91.060127467996594, 30.999323934475722],
            [-91.176140123966888, 30.999224201485131],
            [-91.224068, 30.999182999166521],
            [-91.636942, 30.999415999166526],
            [-91.628257298208794, 31.005097001061635]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "055",
        COUNTYNS: "00161553",
        AFFGEOID: "0500000US01055",
        GEOID: "01055",
        NAME: "Etowah",
        LSAD: "06",
        ALAND: 1386383039,
        AWATER: 34559412
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.332394, 34.040498999124402],
            [-86.303516, 34.099072999123678],
            [-86.206107, 34.17264999912279],
            [-86.1089, 34.186310999122625],
            [-86.106086, 34.200755999122464],
            [-85.843617, 34.20000599912246],
            [-85.841365, 34.110694999123545],
            [-85.797071, 34.100178999123671],
            [-85.738975, 33.968459999125294],
            [-85.740968, 33.935300999125694],
            [-85.846174, 33.957259999125434],
            [-85.96917, 33.914597999125952],
            [-86.065272, 33.842197999126853],
            [-86.142717, 33.89939099912614],
            [-86.199164, 33.988917999125036],
            [-86.290127, 33.983718999125109],
            [-86.325622, 33.940146999125631],
            [-86.370152, 33.93976999912563],
            [-86.332394, 34.040498999124402]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "207",
        COUNTYNS: "00343073",
        AFFGEOID: "0500000US13207",
        GEOID: "13207",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 1025872423,
        AWATER: 5643844
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.12334, 32.93218399913863],
            [-84.054175, 32.931496999138652],
            [-84.041498, 33.202628999135044],
            [-83.842615, 33.196772999135121],
            [-83.822261, 33.180237999135336],
            [-83.816048, 33.131815999135974],
            [-83.72537, 33.032665999137286],
            [-83.710685, 32.952791999138348],
            [-83.734153, 32.951008999138374],
            [-83.863831, 32.864682999139539],
            [-83.891922, 32.848350999139754],
            [-83.957579, 32.848756999139752],
            [-83.9807, 32.848956999139752],
            [-84.124275, 32.849561999139745],
            [-84.12334, 32.93218399913863]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "151",
        COUNTYNS: "00485039",
        AFFGEOID: "0500000US20151",
        GEOID: "20151",
        NAME: "Pratt",
        LSAD: "06",
        ALAND: 1903740958,
        AWATER: 1771939
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.013318, 37.825335999085809],
            [-98.472257, 37.824496999085817],
            [-98.46482, 37.810205999085937],
            [-98.464804, 37.732674999086569],
            [-98.464663, 37.471012999088757],
            [-99.012598, 37.470420999088766],
            [-99.01355, 37.732969999086563],
            [-99.013318, 37.825335999085809]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "185",
        COUNTYNS: "00835914",
        AFFGEOID: "0500000US31185",
        GEOID: "31185",
        NAME: "York",
        LSAD: "06",
        ALAND: 1482793604,
        AWATER: 8595507
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.826287, 41.046580999065512],
            [-97.368118, 41.046946999065511],
            [-97.368401, 40.69862499906715],
            [-97.82478, 40.698536999067137],
            [-97.825723, 40.698554999067142],
            [-97.826287, 41.046580999065512]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "047",
        COUNTYNS: "01101811",
        AFFGEOID: "0500000US40047",
        GEOID: "40047",
        NAME: "Garfield",
        LSAD: "06",
        ALAND: 2741479095,
        AWATER: 4075930
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.104286, 36.593577999096638],
            [-97.462459, 36.593626999096635],
            [-97.461603, 36.593636999096645],
            [-97.460792, 36.164461999100808],
            [-97.675617, 36.164662999100813],
            [-98.103904, 36.164876999100812],
            [-98.104427, 36.463104999097887],
            [-98.104286, 36.593577999096638]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "051",
        COUNTYNS: "01717539",
        AFFGEOID: "0500000US54051",
        GEOID: "54051",
        NAME: "Marshall",
        LSAD: "06",
        ALAND: 791063503,
        AWATER: 17411500
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.869933, 39.763554999072191],
            [-80.824969, 39.801091999071971],
            [-80.824276, 39.847158999071709],
            [-80.823438104785893, 39.85003208646809],
            [-80.803394, 39.918761999071286],
            [-80.764479, 39.950249999071104],
            [-80.740126, 39.970792999070994],
            [-80.738218439983598, 40.033543225149032],
            [-80.51912, 40.016409999070724],
            [-80.519160794314502, 39.96220005159293],
            [-80.519342, 39.721402999072446],
            [-80.835521693007493, 39.719251801272549],
            [-80.869933, 39.763554999072191]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "177",
        COUNTYNS: "00450336",
        AFFGEOID: "0500000US18177",
        GEOID: "18177",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 1040508996,
        AWATER: 6732250
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.200945, 39.873798999071553],
            [-85.201473, 40.004520999070792],
            [-84.810161238474493, 40.005067542919001],
            [-84.811417106380489, 39.916913628651109],
            [-84.814128137932784, 39.726616510691713],
            [-85.022528, 39.729178999072388],
            [-85.034575, 39.714763999072481],
            [-85.185089, 39.715514999072468],
            [-85.221118, 39.788448999072052],
            [-85.200945, 39.873798999071553]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "123",
        COUNTYNS: "00835883",
        AFFGEOID: "0500000US31123",
        GEOID: "31123",
        NAME: "Morrill",
        LSAD: "06",
        ALAND: 3687723999,
        AWATER: 15598670
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.370391, 41.699209999062838],
            [-103.36262, 41.743547999062677],
            [-103.363337, 42.002929999061756],
            [-102.827941, 42.000863999061764],
            [-102.697835, 42.004842999061765],
            [-102.677582, 42.005281999061751],
            [-102.636476, 41.743138999062666],
            [-102.636695, 41.439052999063847],
            [-103.369024, 41.437654999063852],
            [-103.370391, 41.699209999062838]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "003",
        COUNTYNS: "00711755",
        AFFGEOID: "0500000US28003",
        GEOID: "28003",
        NAME: "Alcorn",
        LSAD: "06",
        ALAND: 1036100568,
        AWATER: 3417891
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.823050501632011, 34.995207044808197],
            [-88.786612, 34.995251999113194],
            [-88.469877, 34.99603299911319],
            [-88.380492511522078, 34.995792407335287],
            [-88.363530268539179, 34.995746750910293],
            [-88.365472, 34.755603999115912],
            [-88.718508, 34.756780999115897],
            [-88.753065, 34.756959999115892],
            [-88.752956, 34.858774999114736],
            [-88.823473, 34.858628999114735],
            [-88.823050501632011, 34.995207044808197]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "171",
        COUNTYNS: "01074097",
        AFFGEOID: "0500000US39171",
        GEOID: "39171",
        NAME: "Williams",
        LSAD: "06",
        ALAND: 1090296608,
        AWATER: 5507083
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.806082, 41.696088999062837],
            [-84.438067, 41.704902999062831],
            [-84.399548785474195, 41.705921356468401],
            [-84.381312, 41.616729999063153],
            [-84.341902, 41.485518999063665],
            [-84.341664, 41.427567999063903],
            [-84.804253257611506, 41.426049710613796],
            [-84.804958156239593, 41.530137812451677],
            [-84.806082, 41.696088999062837]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "195",
        COUNTYNS: "01383883",
        AFFGEOID: "0500000US48195",
        GEOID: "48195",
        NAME: "Hansford",
        LSAD: "06",
        ALAND: 2382296649,
        AWATER: 1548341
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.623915, 36.499527999097545],
            [-101.085156, 36.499243999097544],
            [-101.085716, 36.057571999101881],
            [-101.085735, 36.055275999101902],
            [-101.623466, 36.055404999101896],
            [-101.623915, 36.499527999097545]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "049",
        COUNTYNS: "01622967",
        AFFGEOID: "0500000US26049",
        GEOID: "26049",
        NAME: "Genesee",
        LSAD: "06",
        ALAND: 1649726963,
        AWATER: 32695696
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.932071, 43.220376999058487],
            [-83.695621, 43.221421999058485],
            [-83.460733, 43.223130999058469],
            [-83.453364, 42.880431999059233],
            [-83.689384, 42.871262999059248],
            [-83.686493, 42.783262999059467],
            [-83.922516, 42.780820999059472],
            [-83.929079, 43.132781999058658],
            [-83.932071, 43.220376999058487]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "079",
        COUNTYNS: "01622982",
        AFFGEOID: "0500000US26079",
        GEOID: "26079",
        NAME: "Kalkaska",
        LSAD: "06",
        ALAND: 1449687582,
        AWATER: 28061738
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.332836, 44.812371999056765],
            [-85.327595, 44.850759999056777],
            [-85.291716, 44.859607999056777],
            [-84.847309, 44.858036999056772],
            [-84.850929, 44.511068999056867],
            [-85.334, 44.512302999056871],
            [-85.332836, 44.812371999056765]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "067",
        COUNTYNS: "01648577",
        AFFGEOID: "0500000US47067",
        GEOID: "47067",
        NAME: "Hancock",
        LSAD: "06",
        ALAND: 575816105,
        AWATER: 2988718
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.472093573809602, 36.599475731155884],
            [-83.2763, 36.598186999096598],
            [-82.984458495572383, 36.595289968146631],
            [-82.830433, 36.593760999096645],
            [-82.950519, 36.543004999097128],
            [-83.010789, 36.563690999096927],
            [-83.120966, 36.513882999097405],
            [-83.280595, 36.394688999098541],
            [-83.304197, 36.38818899909861],
            [-83.3868, 36.413185999098367],
            [-83.369401, 36.459185999097926],
            [-83.398303, 36.534884999097201],
            [-83.472093573809602, 36.599475731155884]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "069",
        COUNTYNS: "01034230",
        AFFGEOID: "0500000US38069",
        GEOID: "38069",
        NAME: "Pierce",
        LSAD: "06",
        ALAND: 2637787590,
        AWATER: 165445738
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.27666, 48.54481299906417],
            [-100.145858, 48.54521099906416],
            [-99.493189, 48.544833999064174],
            [-99.492919, 48.370945999063473],
            [-99.846613, 48.371297999063479],
            [-99.812133, 48.021450999062168],
            [-99.812167, 47.847256999061564],
            [-100.069206, 47.847300999061574],
            [-100.197996, 47.847599999061572],
            [-100.197558, 48.021856999062166],
            [-100.237332, 48.371245999063461],
            [-100.276401, 48.371333999063467],
            [-100.27666, 48.54481299906417]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "125",
        COUNTYNS: "01209190",
        AFFGEOID: "0500000US42125",
        GEOID: "42125",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 2219590653,
        AWATER: 10147672
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.51912, 40.016409999070724],
            [-80.519084370456596, 40.159671994188024],
            [-80.519039, 40.342100999068954],
            [-80.519029691194092, 40.399641055409354],
            [-80.519017117347389, 40.477363145305347],
            [-80.360873, 40.477538999068244],
            [-80.183472, 40.332781999069006],
            [-79.914139, 40.252517999069433],
            [-79.959532, 40.216133999069626],
            [-79.898846, 40.194859999069749],
            [-79.870585, 40.197414999069728],
            [-79.870668, 40.162126999069919],
            [-79.887337, 40.164982999069906],
            [-79.893306, 40.164982999069906],
            [-79.900346, 40.162502999069922],
            [-79.904778, 40.157254999069949],
            [-79.90417, 40.151966999069977],
            [-79.877385, 40.126791999070115],
            [-79.838999, 40.090600999070318],
            [-79.877048, 40.036825999070608],
            [-79.998014, 39.983321999070924],
            [-80.048292, 39.978526999070944],
            [-80.038927, 39.996466999070847],
            [-80.077242, 39.997013999070845],
            [-80.301396, 40.020558999070701],
            [-80.519160794314502, 39.96220005159293],
            [-80.51912, 40.016409999070724]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "141",
        COUNTYNS: "00069904",
        AFFGEOID: "0500000US05141",
        GEOID: "05141",
        NAME: "Van Buren",
        LSAD: "06",
        ALAND: 1838200422,
        AWATER: 37692771
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.813417, 35.548999999107139],
            [-92.809763, 35.722400999105318],
            [-92.631207, 35.718364999105368],
            [-92.629837, 35.790822999104606],
            [-92.416719, 35.78858999910463],
            [-92.418408, 35.71604599910539],
            [-92.24052, 35.712189999105426],
            [-92.252759, 35.363717999109134],
            [-92.481469, 35.368391999109086],
            [-92.479268, 35.455356999108147],
            [-92.850624, 35.462949999108062],
            [-92.813417, 35.548999999107139]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "045",
        COUNTYNS: "00450351",
        AFFGEOID: "0500000US18045",
        GEOID: "18045",
        NAME: "Fountain",
        LSAD: "06",
        ALAND: 1024769248,
        AWATER: 5734092
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.440179, 39.961175999071031],
            [-87.406667, 40.127507999070112],
            [-87.438755, 40.165693999069894],
            [-87.244898, 40.309251999069133],
            [-87.092148, 40.36661199906883],
            [-87.092563, 40.214805999069632],
            [-87.091006, 39.953008999071102],
            [-87.420221, 39.952451999071101],
            [-87.440179, 39.961175999071031]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "019",
        COUNTYNS: "00695734",
        AFFGEOID: "0500000US28019",
        GEOID: "28019",
        NAME: "Choctaw",
        LSAD: "06",
        ALAND: 1083088569,
        AWATER: 4331852
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.45405, 33.433199999132029],
            [-89.381092, 33.460306999131689],
            [-89.261481, 33.518627999130949],
            [-89.088446, 33.533201999130753],
            [-89.088438, 33.532517999130761],
            [-89.088652, 33.445820999131868],
            [-89.088255, 33.286002999133949],
            [-89.1089, 33.1980589991351],
            [-89.213422, 33.197542999135095],
            [-89.213921, 33.109401999136267],
            [-89.317855, 33.108870999136279],
            [-89.316408, 33.285555999133955],
            [-89.453808, 33.285941999133946],
            [-89.45405, 33.433199999132029]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "690",
        COUNTYNS: "01789072",
        AFFGEOID: "0500000US51690",
        GEOID: "51690",
        NAME: "Martinsville",
        LSAD: "25",
        ALAND: 28376605,
        AWATER: 133615
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.904077, 36.684126999095788],
            [-79.88126, 36.711536999095536],
            [-79.865814, 36.712581999095534],
            [-79.837359, 36.704136999095596],
            [-79.843737, 36.672442999095907],
            [-79.836739, 36.668544999095943],
            [-79.834624, 36.663158999095991],
            [-79.826619, 36.659378999096027],
            [-79.831433, 36.646320999096154],
            [-79.859761, 36.650367999096105],
            [-79.85926, 36.661736999096],
            [-79.894181, 36.672035999095904],
            [-79.904077, 36.684126999095788]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "191",
        COUNTYNS: "00356886",
        AFFGEOID: "0500000US13191",
        GEOID: "13191",
        NAME: "McIntosh",
        LSAD: "06",
        ALAND: 1099290158,
        AWATER: 387088216
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.663206, 31.538666999158355],
            [-81.566009, 31.576466999157788],
            [-81.491798, 31.699574999155974],
            [-81.43576, 31.642054999156827],
            [-81.314548, 31.659381999156555],
            [-81.22222, 31.630419999156992],
            [-81.174832636590594, 31.539596448653135],
            [-81.177254, 31.517073999158679],
            [-81.213493, 31.462817999159487],
            [-81.258616, 31.404424999160366],
            [-81.279338, 31.351126999161167],
            [-81.260958, 31.303909999161874],
            [-81.264377948088395, 31.294599063907519],
            [-81.481696, 31.336087999161393],
            [-81.62429, 31.45298199915964],
            [-81.612054, 31.46940499915938],
            [-81.663206, 31.538666999158355]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "255",
        COUNTYNS: "01383913",
        AFFGEOID: "0500000US48255",
        GEOID: "48255",
        NAME: "Karnes",
        LSAD: "06",
        ALAND: 1936008340,
        AWATER: 15671660
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.190991, 28.882332999201438],
            [-97.995302, 29.026103999198927],
            [-97.728443, 29.221747999195539],
            [-97.613132, 29.109764999197477],
            [-97.75511, 29.007112999199254],
            [-97.574639, 28.813299999202655],
            [-97.77853, 28.668026999205217],
            [-97.915106, 28.719647999204302],
            [-98.005252, 28.69023899920483],
            [-98.098315, 28.786948999203119],
            [-98.190991, 28.882332999201438]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "097",
        COUNTYNS: "01074061",
        AFFGEOID: "0500000US39097",
        GEOID: "39097",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 1206646148,
        AWATER: 1919019
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.647169, 39.773028999072146],
            [-83.588041, 39.768777999072164],
            [-83.516155, 40.010187999070773],
            [-83.503714, 40.111467999070193],
            [-83.206273, 40.107732999070215],
            [-83.210587, 40.065276999070448],
            [-83.255051, 40.048613999070554],
            [-83.243702, 39.81250299907191],
            [-83.252435, 39.695437999072603],
            [-83.65333, 39.716875999072478],
            [-83.647169, 39.773028999072146]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "013",
        COUNTYNS: "00758461",
        AFFGEOID: "0500000US29013",
        GEOID: "29013",
        NAME: "Bates",
        LSAD: "06",
        ALAND: 2167013743,
        AWATER: 38050433
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.612614, 38.237765999082555],
            [-94.612772628992886, 38.388718481813214],
            [-94.612866, 38.477570999080754],
            [-94.612865595922102, 38.477602179315852],
            [-94.31217, 38.471495999080794],
            [-94.212023, 38.44675399908099],
            [-94.065713, 38.447086999080973],
            [-94.075182, 38.215439999082733],
            [-94.049895, 38.213984999082733],
            [-94.058285, 38.036626999084113],
            [-94.135238, 38.058117999083947],
            [-94.237787, 38.026016999084206],
            [-94.613929961055703, 38.060052958980442],
            [-94.612614, 38.237765999082555]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "301",
        COUNTYNS: "01383936",
        AFFGEOID: "0500000US48301",
        GEOID: "48301",
        NAME: "Loving",
        LSAD: "06",
        ALAND: 1732059386,
        AWATER: 20151852
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.980213477381014, 32.000032850514195],
            [-103.722881979148013, 32.000165567827985],
            [-103.326501, 32.000369999151594],
            [-103.327538, 31.651419999156687],
            [-103.610887, 31.651801999156671],
            [-103.625726, 31.705031999155892],
            [-103.746809, 31.739508999155394],
            [-103.809458, 31.794316999154578],
            [-103.829385, 31.887991999153222],
            [-103.885584, 31.870372999153471],
            [-103.980213477381014, 32.000032850514195]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "055",
        COUNTYNS: "00451676",
        AFFGEOID: "0500000US18055",
        GEOID: "18055",
        NAME: "Greene",
        LSAD: "06",
        ALAND: 1404983930,
        AWATER: 8919620
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.240737, 39.171772999075905],
            [-87.054578, 39.16808599907592],
            [-86.683002, 39.165745999075938],
            [-86.682277, 38.992403999077105],
            [-86.682853, 38.904696999077707],
            [-86.904252, 38.904203999077716],
            [-87.03542, 38.902459999077713],
            [-87.098771, 38.903973999077699],
            [-87.129882, 38.907314999077684],
            [-87.241027, 38.90787399907768],
            [-87.240737, 39.171772999075905]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "141",
        COUNTYNS: "00485034",
        AFFGEOID: "0500000US20141",
        GEOID: "20141",
        NAME: "Osborne",
        LSAD: "06",
        ALAND: 2311575393,
        AWATER: 4806274
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.044398, 39.568034999073369],
            [-98.505266, 39.56760299907338],
            [-98.487384, 39.567491999073376],
            [-98.490149, 39.219779999075591],
            [-98.489997, 39.132696999076167],
            [-99.0375, 39.133120999076148],
            [-99.047687, 39.133013999076162],
            [-99.044398, 39.568034999073369]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "075",
        COUNTYNS: "00758492",
        AFFGEOID: "0500000US29075",
        GEOID: "29075",
        NAME: "Gentry",
        LSAD: "06",
        ALAND: 1272776756,
        AWATER: 762384
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.605942, 40.126126999070117],
            [-94.598487, 40.386713999068718],
            [-94.214278, 40.384253999068733],
            [-94.216696, 40.136625999070056],
            [-94.2188, 40.034884999070627],
            [-94.605377, 40.039216999070604],
            [-94.605942, 40.126126999070117]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "051",
        COUNTYNS: "00758480",
        AFFGEOID: "0500000US29051",
        GEOID: "29051",
        NAME: "Cole",
        LSAD: "06",
        ALAND: 1014013382,
        AWATER: 19259438
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.495185, 38.427828999081122],
            [-92.394866, 38.739129999078862],
            [-92.357813, 38.679516999079283],
            [-92.220661, 38.64316699907954],
            [-92.161663, 38.578056999080012],
            [-92.008983, 38.570899999080069],
            [-92.043489, 38.466478999080842],
            [-92.167357, 38.469686999080814],
            [-92.114977, 38.401936999081308],
            [-92.194476, 38.33553599908182],
            [-92.408407, 38.341343999081772],
            [-92.403484, 38.42533099908114],
            [-92.495185, 38.427828999081122]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "131",
        COUNTYNS: "01383851",
        AFFGEOID: "0500000US48131",
        GEOID: "48131",
        NAME: "Duval",
        LSAD: "06",
        ALAND: 4645085813,
        AWATER: 5478267
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.803325, 28.057479999216234],
            [-98.334323, 28.057799999216233],
            [-98.235497, 28.057965999216222],
            [-98.232464, 27.262486999231108],
            [-98.523284, 27.265149999231056],
            [-98.522293, 27.34328399922957],
            [-98.589537, 27.358694999229275],
            [-98.798323, 27.354028999229367],
            [-98.803325, 28.057479999216234]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "019",
        COUNTYNS: "01265793",
        AFFGEOID: "0500000US46019",
        GEOID: "46019",
        NAME: "Butte",
        LSAD: "06",
        ALAND: 5827518214,
        AWATER: 42730886
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.057698, 44.99743099905676],
            [-104.039138, 44.998519999056761],
            [-104.039977, 45.124987999056771],
            [-104.040135756493015, 45.2128907610984],
            [-102.957281, 45.212850999056812],
            [-102.957371, 45.039568999056769],
            [-102.964125, 44.604286999056832],
            [-103.567653, 44.604097999056826],
            [-103.820639, 44.604776999056824],
            [-104.055695199260995, 44.570990376199049],
            [-104.05581, 44.691342999056793],
            [-104.057698, 44.99743099905676]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "099",
        COUNTYNS: "00659495",
        AFFGEOID: "0500000US27099",
        GEOID: "27099",
        NAME: "Mower",
        LSAD: "06",
        ALAND: 1842332253,
        AWATER: 617233
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.049524, 43.848442999057461],
            [-93.045966, 43.848466999057464],
            [-92.689391, 43.848569999057453],
            [-92.67884, 43.833695999057483],
            [-92.448965, 43.83410499905748],
            [-92.448947951437773, 43.50041498762068],
            [-92.553128090518783, 43.500300035370273],
            [-92.553161, 43.500299999057987],
            [-92.870277, 43.499547999057974],
            [-93.024347900541883, 43.499556162687981],
            [-93.049192007816487, 43.499557479082576],
            [-93.049524, 43.848442999057461]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "245",
        COUNTYNS: "00358024",
        AFFGEOID: "0500000US13245",
        GEOID: "13245",
        NAME: "Richmond",
        LSAD: "06",
        ALAND: 840026450,
        AWATER: 11024164
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.353498, 33.312317999133604],
            [-82.294179, 33.354634999133054],
            [-82.28283, 33.362694999132941],
            [-82.028238, 33.544933999130606],
            [-82.01655587044489, 33.52905538555671],
            [-81.990938, 33.494234999131258],
            [-81.926336, 33.462936999131649],
            [-81.920121, 33.410752999132328],
            [-81.932737, 33.343540999133197],
            [-81.846136, 33.303842999133721],
            [-81.846500532357894, 33.247252084564359],
            [-82.097135, 33.230611999134673],
            [-82.17424, 33.296770999133805],
            [-82.26758, 33.267396999134192],
            [-82.332774, 33.308680999133649],
            [-82.353498, 33.312317999133604]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "033",
        COUNTYNS: "00424218",
        AFFGEOID: "0500000US17033",
        GEOID: "17033",
        NAME: "Crawford",
        LSAD: "06",
        ALAND: 1149004930,
        AWATER: 5659945
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.950385, 39.174881999075879],
            [-87.656794, 39.172176999075901],
            [-87.638293413744094, 39.157492546278498],
            [-87.625379, 39.101805999076369],
            [-87.572588, 39.057285999076662],
            [-87.579117, 39.001606999077048],
            [-87.529496, 38.971924999077245],
            [-87.527645, 38.90768799907768],
            [-87.528717663697904, 38.905943785366901],
            [-87.54737, 38.875613999077906],
            [-87.535257050808497, 38.852491464045471],
            [-87.908113, 38.850106999078093],
            [-87.945923, 38.850107999078084],
            [-87.950385, 39.174881999075879]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "33",
        COUNTYFP: "017",
        COUNTYNS: "00873182",
        AFFGEOID: "0500000US33017",
        GEOID: "33017",
        NAME: "Strafford",
        LSAD: "06",
        ALAND: 951728786,
        AWATER: 39171752
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.236011, 43.284993999058351],
            [-71.159364, 43.355122999058231],
            [-71.162675, 43.538958999057911],
            [-71.037931, 43.529825999057927],
            [-71.024244, 43.482912999057994],
            [-70.963792679806403, 43.540220954424413],
            [-70.954755, 43.509801999057963],
            [-70.960788896047006, 43.474088732679604],
            [-70.968359, 43.429282999058088],
            [-70.984335, 43.376127999058198],
            [-70.923949, 43.324767999058281],
            [-70.872585, 43.270151999058385],
            [-70.813119, 43.217251999058483],
            [-70.8247767120681, 43.179763104240777],
            [-70.824801, 43.179684999058573],
            [-70.8281, 43.129085999058667],
            [-70.819549285713009, 43.123230972489878],
            [-70.875436, 43.080421999058771],
            [-71.072936, 43.081680999058769],
            [-71.026686, 43.137946999058649],
            [-71.127948, 43.20007199905853],
            [-71.247336, 43.275231999058377],
            [-71.236011, 43.284993999058351]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "105",
        COUNTYNS: "01101840",
        AFFGEOID: "0500000US40105",
        GEOID: "40105",
        NAME: "Nowata",
        LSAD: "06",
        ALAND: 1465358455,
        AWATER: 38869450
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.80982, 36.941929999093418],
            [-95.786761870778193, 36.999270916684893],
            [-95.573598, 36.999309999092894],
            [-95.5224145523987, 36.999319785875791],
            [-95.407620005877092, 36.999341735731988],
            [-95.431731, 36.942775999093399],
            [-95.43157, 36.597539999096597],
            [-95.809617, 36.597475999096616],
            [-95.80982, 36.941929999093418]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "031",
        COUNTYNS: "00161541",
        AFFGEOID: "0500000US01031",
        GEOID: "01031",
        NAME: "Coffee",
        LSAD: "06",
        ALAND: 1758565927,
        AWATER: 3907189
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.193951, 31.440071999159827],
            [-86.194784, 31.529948999158481],
            [-86.14395, 31.537674999158376],
            [-86.145895, 31.617740999157174],
            [-85.789142, 31.61796399915718],
            [-85.791402, 31.196348999163508],
            [-86.125405, 31.182056999163734],
            [-86.193476, 31.192212999163566],
            [-86.196309, 31.422389999160092],
            [-86.193951, 31.440071999159827]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "221",
        COUNTYNS: "00351262",
        AFFGEOID: "0500000US13221",
        GEOID: "13221",
        NAME: "Oglethorpe",
        LSAD: "06",
        ALAND: 1137034765,
        AWATER: 7838679
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.306619, 33.811443999127235],
            [-83.275933, 33.847976999126786],
            [-83.24086, 33.90442999912608],
            [-83.278645, 33.951744999125488],
            [-83.258413, 33.999097999124899],
            [-83.123878, 34.047557999124308],
            [-83.048889, 34.015803999124692],
            [-82.976294, 34.04321899912437],
            [-82.942268, 34.01495699912472],
            [-82.779506, 33.971123999125254],
            [-82.874587, 33.921766999125865],
            [-82.986325, 33.785404999127564],
            [-82.949046, 33.733332999128216],
            [-82.995602, 33.693582999128715],
            [-83.118729, 33.69881799912865],
            [-83.28034, 33.761773999127854],
            [-83.306619, 33.811443999127235]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "133",
        COUNTYNS: "00450384",
        AFFGEOID: "0500000US18133",
        GEOID: "18133",
        NAME: "Putnam",
        LSAD: "06",
        ALAND: 1244585902,
        AWATER: 5585892
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.013062, 39.604786999073156],
            [-87.009477, 39.866704999071587],
            [-86.695127, 39.8648449990716],
            [-86.687406, 39.630436999072998],
            [-86.643014, 39.618028999073076],
            [-86.65665, 39.601394999073179],
            [-86.64832, 39.565440999073388],
            [-86.68571, 39.470062999073988],
            [-86.939981, 39.473344999073959],
            [-87.014533, 39.473571999073961],
            [-87.013062, 39.604786999073156]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "013",
        COUNTYNS: "00659452",
        AFFGEOID: "0500000US27013",
        GEOID: "27013",
        NAME: "Blue Earth",
        LSAD: "06",
        ALAND: 1936830865,
        AWATER: 46082217
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.371731, 44.264447999057026],
            [-94.046671, 44.154373999057128],
            [-94.011868, 44.239519999057059],
            [-93.768031, 44.239383999057054],
            [-93.767971, 44.195835999057088],
            [-93.767761, 43.847955999057461],
            [-94.247123, 43.847945999057472],
            [-94.368974, 43.848046999057459],
            [-94.36885, 44.108697999057171],
            [-94.371731, 44.264447999057026]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "029",
        COUNTYNS: "00858642",
        AFFGEOID: "0500000US32029",
        GEOID: "32029",
        NAME: "Storey",
        LSAD: "06",
        ALAND: 680962190,
        AWATER: 1871089
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.658501, 39.33154399907486],
            [-119.695643, 39.520022999073674],
            [-119.621697, 39.511601999073726],
            [-119.467141, 39.584501999073268],
            [-119.283637, 39.62156099907304],
            [-119.478806, 39.337415999074828],
            [-119.64735, 39.266567999075278],
            [-119.712645, 39.250870999075374],
            [-119.658501, 39.33154399907486]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "133",
        COUNTYNS: "01639780",
        AFFGEOID: "0500000US47133",
        GEOID: "47133",
        NAME: "Overton",
        LSAD: "06",
        ALAND: 1122721529,
        AWATER: 3492728
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.495422, 36.403073999098467],
            [-85.446372, 36.497969999097549],
            [-85.284878, 36.531292999097232],
            [-85.261159, 36.534996999097203],
            [-85.120445, 36.407163999098429],
            [-85.122245, 36.304598999099433],
            [-85.082497, 36.196170999100495],
            [-85.119583, 36.144718999101016],
            [-85.204156, 36.152488999100932],
            [-85.43585, 36.247147999099987],
            [-85.495124, 36.302277999099452],
            [-85.497587, 36.37505099909874],
            [-85.495422, 36.403073999098467]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "678",
        COUNTYNS: "01789071",
        AFFGEOID: "0500000US51678",
        GEOID: "51678",
        NAME: "Lexington",
        LSAD: "25",
        ALAND: 6473002,
        AWATER: 67785
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.461582, 37.786355999086133],
            [-79.437337, 37.794783999086057],
            [-79.414948, 37.788626999086112],
            [-79.42215, 37.784787999086134],
            [-79.455667, 37.765218999086301],
            [-79.461582, 37.786355999086133]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "017",
        COUNTYNS: "01804488",
        AFFGEOID: "0500000US72017",
        GEOID: "72017",
        NAME: "Barceloneta",
        LSAD: "13",
        ALAND: 48421896,
        AWATER: 31649450
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.58006, 18.468102999432421],
            [-66.586254775676792, 18.487952291398102],
            [-66.534256445318604, 18.479485370441626],
            [-66.521165, 18.431157999433402],
            [-66.543079, 18.405421999434079],
            [-66.588181, 18.3894079994345],
            [-66.58006, 18.468102999432421]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "075",
        COUNTYNS: "00161563",
        AFFGEOID: "0500000US01075",
        GEOID: "01075",
        NAME: "Lamar",
        LSAD: "06",
        ALAND: 1566544636,
        AWATER: 1600679
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.254445, 33.698778999128649],
            [-88.248387473909489, 33.74490759400976],
            [-88.207229, 34.058332999124183],
            [-87.98693, 34.052101999124254],
            [-87.951929, 34.022201999124626],
            [-87.951785, 33.919929999125884],
            [-87.946519, 33.524064999130871],
            [-88.274516470783695, 33.534001476915932],
            [-88.254445, 33.698778999128649]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "019",
        COUNTYNS: "01622952",
        AFFGEOID: "0500000US26019",
        GEOID: "26019",
        NAME: "Benzie",
        LSAD: "06",
        ALAND: 828049220,
        AWATER: 1398210900
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.248474, 44.699045999056807],
            [-86.160268, 44.728188999056798],
            [-86.089186, 44.741495999056788],
            [-86.078498188030295, 44.778330746717671],
            [-85.815119, 44.774533999056779],
            [-85.817802, 44.512968999056874],
            [-86.237020865016504, 44.51829858520157],
            [-86.220697, 44.566741999056845],
            [-86.25395, 44.648079999056804],
            [-86.248474, 44.699045999056807]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "145",
        COUNTYNS: "01804553",
        AFFGEOID: "0500000US72145",
        GEOID: "72145",
        NAME: "Vega Baja",
        LSAD: "13",
        ALAND: 118777657,
        AWATER: 57795017
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.448989, 18.387213999434557],
            [-66.438950007398887, 18.481492908694772],
            [-66.420921, 18.488638999431888],
            [-66.349503322691305, 18.479214309741433],
            [-66.347733, 18.429536999433441],
            [-66.377058, 18.344245999435692],
            [-66.395585, 18.371884999434965],
            [-66.4438, 18.371026999434985],
            [-66.448989, 18.387213999434557]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "045",
        COUNTYNS: "01383808",
        AFFGEOID: "0500000US48045",
        GEOID: "48045",
        NAME: "Briscoe",
        LSAD: "06",
        ALAND: 2330991085,
        AWATER: 4068657
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.471562, 34.747461999116013],
            [-101.090749, 34.748245999116001],
            [-100.944939, 34.748280999115991],
            [-100.946132, 34.312758999121108],
            [-101.041484, 34.312443999121108],
            [-101.47158, 34.312289999121113],
            [-101.471562, 34.747461999116013]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "017",
        COUNTYNS: "00465198",
        AFFGEOID: "0500000US19017",
        GEOID: "19017",
        NAME: "Bremer",
        LSAD: "06",
        ALAND: 1127885581,
        AWATER: 10166470
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.554211, 42.907111999059161],
            [-92.081568, 42.907012999059162],
            [-92.081661, 42.642055999059821],
            [-92.554492, 42.642313999059823],
            [-92.554211, 42.907111999059161]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "025",
        COUNTYNS: "01008540",
        AFFGEOID: "0500000US37025",
        GEOID: "37025",
        NAME: "Cabarrus",
        LSAD: "06",
        ALAND: 935603275,
        AWATER: 7006691
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.784167, 35.506016999107601],
            [-80.737379, 35.505803999107599],
            [-80.295421, 35.502919999107633],
            [-80.49751, 35.201838999110898],
            [-80.504985, 35.184997999111083],
            [-80.550349, 35.20841199911083],
            [-80.599733, 35.234104999110556],
            [-80.66648, 35.26793399911017],
            [-80.693061, 35.346378999109319],
            [-80.766414, 35.401477999108728],
            [-80.784167, 35.506016999107601]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "113",
        COUNTYNS: "01213687",
        AFFGEOID: "0500000US42113",
        GEOID: "42113",
        NAME: "Sullivan",
        LSAD: "06",
        ALAND: 1165338399,
        AWATER: 6617028
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.813731, 41.590033999063259],
            [-76.220139, 41.541284999063436],
            [-76.228131, 41.511136999063567],
            [-76.2831, 41.376516999064094],
            [-76.310133, 41.310198999064362],
            [-76.407934, 41.308417999064382],
            [-76.447597, 41.275628999064516],
            [-76.592532, 41.304331999064395],
            [-76.749497, 41.40557199906398],
            [-76.813731, 41.590033999063259]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "095",
        COUNTYNS: "01678706",
        AFFGEOID: "0500000US54095",
        GEOID: "54095",
        NAME: "Tyler",
        LSAD: "06",
        ALAND: 663797704,
        AWATER: 11403003
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.07595, 39.50965999907374],
            [-81.037365041987087, 39.538055711546164],
            [-80.943782, 39.606925999073141],
            [-80.846393, 39.601090999073179],
            [-80.73407, 39.491552999073853],
            [-80.618629, 39.450374999074114],
            [-80.713324, 39.430249999074242],
            [-80.888744, 39.294297999075098],
            [-80.904438, 39.378120999074561],
            [-81.007836, 39.350922999074747],
            [-81.029932, 39.467744999074],
            [-81.121273856107791, 39.457697412963363],
            [-81.07595, 39.50965999907374]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "023",
        COUNTYNS: "00347451",
        AFFGEOID: "0500000US13023",
        GEOID: "13023",
        NAME: "Bleckley",
        LSAD: "06",
        ALAND: 559100209,
        AWATER: 8447343
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.497923, 32.452197999145191],
            [-83.412055, 32.502090999144507],
            [-83.226536, 32.584200999143363],
            [-83.138991, 32.423068999145606],
            [-83.173252, 32.452532999145184],
            [-83.30475, 32.341957999146736],
            [-83.346528, 32.272488999147718],
            [-83.498039, 32.401714999145902],
            [-83.497923, 32.452197999145191]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "145",
        COUNTYNS: "01784940",
        AFFGEOID: "0500000US17145",
        GEOID: "17145",
        NAME: "Perry",
        LSAD: "06",
        ALAND: 1144186125,
        AWATER: 13416186
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.592797, 38.219270999082703],
            [-89.147416, 38.212895999082754],
            [-89.149457, 38.130829999083389],
            [-89.129637, 38.124746999083428],
            [-89.115414, 38.085953999083742],
            [-89.177597, 37.950310999084813],
            [-89.595084, 37.955309999084761],
            [-89.592797, 38.219270999082703]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "049",
        COUNTYNS: "00424226",
        AFFGEOID: "0500000US17049",
        GEOID: "17049",
        NAME: "Effingham",
        LSAD: "06",
        ALAND: 1240027659,
        AWATER: 3183217
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.805325, 39.216262999075603],
            [-88.470906, 39.215028999075621],
            [-88.470865, 39.171462999075899],
            [-88.360654, 39.171117999075896],
            [-88.361745, 38.91084699907767],
            [-88.693531, 38.914616999077644],
            [-88.806789, 38.911654999077655],
            [-88.805325, 39.216262999075603]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "059",
        COUNTYNS: "00161555",
        AFFGEOID: "0500000US01059",
        GEOID: "01059",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1641839976,
        AWATER: 32645401
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.154902184008691, 34.463034299559325],
            [-88.139559441247386, 34.581696745001821],
            [-87.529667, 34.567080999118105],
            [-87.529722, 34.304597999121199],
            [-87.634725, 34.306996999121175],
            [-88.173261734594902, 34.321039533455298],
            [-88.154902184008691, 34.463034299559325]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "105",
        COUNTYNS: "00485017",
        AFFGEOID: "0500000US20105",
        GEOID: "20105",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 1863226687,
        AWATER: 2748359
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.490149, 39.219779999075591],
            [-97.929746, 39.219272999075599],
            [-97.92856, 38.958394999077335],
            [-97.928592, 38.871098999077944],
            [-98.484861, 38.87079099907794],
            [-98.483813, 39.114228999076296],
            [-98.489997, 39.132696999076167],
            [-98.490149, 39.219779999075591]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "151",
        COUNTYNS: "00450390",
        AFFGEOID: "0500000US18151",
        GEOID: "18151",
        NAME: "Steuben",
        LSAD: "06",
        ALAND: 799725227,
        AWATER: 35458838
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.196774200302002, 41.759870840865624],
            [-84.825128283876793, 41.76019900541452],
            [-84.805883, 41.760215999062609],
            [-84.806082, 41.696088999062837],
            [-84.804958156239593, 41.530137812451677],
            [-85.194084, 41.526436999063492],
            [-85.196774200302002, 41.759870840865624]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "059",
        COUNTYNS: "01008549",
        AFFGEOID: "0500000US37059",
        GEOID: "37059",
        NAME: "Davie",
        LSAD: "06",
        ALAND: 682967799,
        AWATER: 7399100
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.693197, 36.051259999101937],
            [-80.496283, 36.046544999101997],
            [-80.463592, 36.062540999101834],
            [-80.394307, 35.97276199910273],
            [-80.370009, 35.885746999103617],
            [-80.398823, 35.841346999104083],
            [-80.487586, 35.832909999104174],
            [-80.458884, 35.743030999105095],
            [-80.47182, 35.768355999104834],
            [-80.691809, 35.863507999103859],
            [-80.707826, 35.852928999103966],
            [-80.693197, 36.051259999101937]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "033",
        COUNTYNS: "00465206",
        AFFGEOID: "0500000US19033",
        GEOID: "19033",
        NAME: "Cerro Gordo",
        LSAD: "06",
        ALAND: 1471881731,
        AWATER: 17668265
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.497635, 43.255467999058425],
            [-93.024143, 43.255537999058419],
            [-93.024092, 43.212811999058495],
            [-93.025144, 42.907548999059159],
            [-93.498617, 42.908511999059151],
            [-93.497635, 43.255467999058425]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "111",
        COUNTYNS: "01266977",
        AFFGEOID: "0500000US46111",
        GEOID: "46111",
        NAME: "Sanborn",
        LSAD: "06",
        ALAND: 1474586446,
        AWATER: 2938339
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.332042, 44.196619999057077],
            [-97.85366, 44.195232999057083],
            [-97.849492, 44.195234999057085],
            [-97.85111, 43.849856999057451],
            [-97.967279, 43.850685999057447],
            [-98.325242, 43.85105799905746],
            [-98.331545, 43.868518999057443],
            [-98.331508, 43.937707999057352],
            [-98.332042, 44.196619999057077]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "173",
        COUNTYNS: "01074098",
        AFFGEOID: "0500000US39173",
        GEOID: "39173",
        NAME: "Wood",
        LSAD: "06",
        ALAND: 1598508447,
        AWATER: 8545541
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.883234, 41.414502999063942],
            [-83.85426, 41.414450999063952],
            [-83.747217, 41.466215999063735],
            [-83.680084, 41.546858999063424],
            [-83.588034, 41.600949999063197],
            [-83.565729, 41.617462999063143],
            [-83.415919, 41.618937999063149],
            [-83.414626, 41.500227999063597],
            [-83.419843, 41.25400299906461],
            [-83.421054, 41.166779999064978],
            [-83.881149, 41.167823999064979],
            [-83.883234, 41.414502999063942]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "197",
        COUNTYNS: "00344107",
        AFFGEOID: "0500000US13197",
        GEOID: "13197",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 947978209,
        AWATER: 3791374
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.657325, 32.494939999144599],
            [-84.637323, 32.534854999144052],
            [-84.586101, 32.522649999144214],
            [-84.444353, 32.562082999143676],
            [-84.453154, 32.534335999144048],
            [-84.392316, 32.414045999145728],
            [-84.389927, 32.297236999147366],
            [-84.429451, 32.232150999148288],
            [-84.430218, 32.166256999149233],
            [-84.431214, 32.134057999149675],
            [-84.527117, 32.134555999149676],
            [-84.649319, 32.232952999148281],
            [-84.65892, 32.232851999148281],
            [-84.657325, 32.494939999144599]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "015",
        COUNTYNS: "01550014",
        AFFGEOID: "0500000US54015",
        GEOID: "54015",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 885617251,
        AWATER: 4862445
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.283872, 38.472028999080806],
            [-81.194113, 38.527633999080393],
            [-81.130775, 38.565947999080102],
            [-81.08371, 38.611981999079774],
            [-81.031677, 38.667838999079379],
            [-80.913289, 38.563826999080121],
            [-80.881232, 38.507044999080534],
            [-80.817424, 38.478552999080755],
            [-80.911361, 38.41478499908122],
            [-81.231434, 38.263715999082351],
            [-81.283872, 38.472028999080806]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "185",
        COUNTYNS: "01497748",
        AFFGEOID: "0500000US51185",
        GEOID: "51185",
        NAME: "Tazewell",
        LSAD: "06",
        ALAND: 1343661925,
        AWATER: 2921369
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.900892, 37.142552999091606],
            [-81.761894, 37.201926999091093],
            [-81.739055030485488, 37.239497102963554],
            [-81.678603, 37.202466999091072],
            [-81.560625, 37.206662999091037],
            [-81.53307, 37.223413999090894],
            [-81.483559, 37.250603999090643],
            [-81.427946, 37.271014999090475],
            [-81.362156, 37.337686999089897],
            [-81.225104, 37.234873999090794],
            [-81.32814, 37.138948999091632],
            [-81.270078, 37.093011999092056],
            [-81.462279, 37.035401999092564],
            [-81.43673, 37.010134999092791],
            [-81.474539, 37.018475999092722],
            [-81.682786, 36.932933999093478],
            [-81.780545, 36.95862899909325],
            [-81.891041, 37.113127999091866],
            [-81.900892, 37.142552999091606]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "135",
        COUNTYNS: "00465256",
        AFFGEOID: "0500000US19135",
        GEOID: "19135",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 1123317229,
        AWATER: 1522790
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.099217, 41.160866999065014],
            [-92.869771, 41.161065999065016],
            [-92.640361, 41.161297999065006],
            [-92.639091, 40.898885999066188],
            [-93.097595, 40.898450999066192],
            [-93.099217, 41.160866999065014]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "191",
        COUNTYNS: "00465284",
        AFFGEOID: "0500000US19191",
        GEOID: "19191",
        NAME: "Winneshiek",
        LSAD: "06",
        ALAND: 1786713778,
        AWATER: 698598
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.080976, 43.212946999058502],
            [-92.079801993128271, 43.500704884234572],
            [-91.824848, 43.500683999057969],
            [-91.730216997837701, 43.500685700004077],
            [-91.610835000554374, 43.500687845837277],
            [-91.605307, 43.081652999058775],
            [-92.081146, 43.082793999058765],
            [-92.080976, 43.212946999058502]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "103",
        COUNTYNS: "01034204",
        AFFGEOID: "0500000US38103",
        GEOID: "38103",
        NAME: "Wells",
        LSAD: "06",
        ALAND: 3291081473,
        AWATER: 50967324
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.069206, 47.847300999061574],
            [-99.812167, 47.847256999061564],
            [-99.297204, 47.846810999061567],
            [-99.297318, 47.673003999061009],
            [-99.265846, 47.587617999060761],
            [-99.26628, 47.327193999059993],
            [-99.480726, 47.327075999060007],
            [-100.033292, 47.327191999059984],
            [-100.032849, 47.673620999061022],
            [-100.069206, 47.847300999061574]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "165",
        COUNTYNS: "01008585",
        AFFGEOID: "0500000US37165",
        GEOID: "37165",
        NAME: "Scotland",
        LSAD: "06",
        ALAND: 826556054,
        AWATER: 3841985
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.692946383709298, 34.804962306038547],
            [-79.614045, 34.815308999115224],
            [-79.561856, 34.910349999114146],
            [-79.573423, 34.992209999113236],
            [-79.458751, 35.043638999112659],
            [-79.353124, 34.944241999113771],
            [-79.347823, 34.838616999114961],
            [-79.330825, 34.774077999115704],
            [-79.461791031001297, 34.630030677708859],
            [-79.675299, 34.804743999115352],
            [-79.692946383709298, 34.804962306038547]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "047",
        COUNTYNS: "01383809",
        AFFGEOID: "0500000US48047",
        GEOID: "48047",
        NAME: "Brooks",
        LSAD: "06",
        ALAND: 2443277016,
        AWATER: 741627
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.523284, 27.265149999231056],
            [-98.232464, 27.262486999231108],
            [-98.058078, 27.260980999231144],
            [-97.986076, 27.260493999231148],
            [-97.985887, 27.209307999232124],
            [-97.985494, 26.780916999240411],
            [-98.32067, 26.783080999240369],
            [-98.422616, 26.783534999240366],
            [-98.417883, 27.055284999235084],
            [-98.466542, 27.054710999235091],
            [-98.492943, 27.235011999231634],
            [-98.523284, 27.265149999231056]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "139",
        COUNTYNS: "01383856",
        AFFGEOID: "0500000US48139",
        GEOID: "48139",
        NAME: "Ellis",
        LSAD: "06",
        ALAND: 2423342394,
        AWATER: 41672841
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.086834, 32.549427999143852],
            [-97.038385, 32.54866199914386],
            [-96.529987, 32.545281999143903],
            [-96.434514, 32.388729999146079],
            [-96.452138, 32.358630999146492],
            [-96.451474, 32.345573999146687],
            [-96.383082, 32.328850999146923],
            [-96.896209, 32.073976999150545],
            [-96.940656, 32.052086999150845],
            [-97.086191, 32.265450999147809],
            [-97.086834, 32.549427999143852]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "111",
        COUNTYNS: "01025836",
        AFFGEOID: "0500000US37111",
        GEOID: "37111",
        NAME: "McDowell",
        LSAD: "06",
        ALAND: 1139478112,
        AWATER: 13977177
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.275399, 35.704437999105501],
            [-82.257842, 35.698851999105557],
            [-82.193402, 35.735313999105188],
            [-82.133484, 35.82330899910427],
            [-81.980168, 35.885045999103632],
            [-81.981954, 35.911360999103366],
            [-81.943188, 35.960049999102871],
            [-81.906652, 35.883375999103649],
            [-81.986885, 35.807065999104452],
            [-81.869388, 35.71962399910533],
            [-81.824122, 35.574982999106865],
            [-81.842468, 35.542262999107216],
            [-81.967619, 35.526006999107388],
            [-82.169049, 35.527810999107366],
            [-82.291026, 35.59132199910669],
            [-82.275399, 35.704437999105501]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "189",
        COUNTYNS: "00485056",
        AFFGEOID: "0500000US20189",
        GEOID: "20189",
        NAME: "Stevens",
        LSAD: "06",
        ALAND: 1883604193,
        AWATER: 464936
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.556606, 37.388484999089457],
            [-101.52581, 37.388391999089457],
            [-101.089653, 37.387720999089467],
            [-101.067904, 37.387778999089463],
            [-101.066450215659998, 36.99773617423331],
            [-101.211486, 36.997123999092913],
            [-101.485326, 36.995610999092925],
            [-101.555258848690983, 36.995290938370523],
            [-101.556606, 37.388484999089457]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "151",
        COUNTYNS: "00758530",
        AFFGEOID: "0500000US29151",
        GEOID: "29151",
        NAME: "Osage",
        LSAD: "06",
        ALAND: 1571017654,
        AWATER: 17751408
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.194476, 38.33553599908182],
            [-92.114977, 38.401936999081308],
            [-92.167357, 38.469686999080814],
            [-92.043489, 38.466478999080842],
            [-92.008983, 38.570899999080069],
            [-91.854867, 38.670335999079349],
            [-91.647171, 38.70339599907912],
            [-91.640372, 38.703791999079122],
            [-91.644729, 38.288509999082166],
            [-92.195675, 38.292236999082149],
            [-92.194476, 38.33553599908182]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "219",
        COUNTYNS: "01383895",
        AFFGEOID: "0500000US48219",
        GEOID: "48219",
        NAME: "Hockley",
        LSAD: "06",
        ALAND: 2352723906,
        AWATER: 446119
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.615447, 33.825120999127058],
            [-102.085733, 33.824674999127076],
            [-102.075929, 33.389585999132599],
            [-102.594836, 33.388488999132626],
            [-102.615447, 33.825120999127058]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "121",
        COUNTYNS: "01581120",
        AFFGEOID: "0500000US55121",
        GEOID: "55121",
        NAME: "Trempealeau",
        LSAD: "06",
        ALAND: 1898471750,
        AWATER: 23372072
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.612994, 44.172605999057104],
            [-91.529741, 44.246776999057047],
            [-91.529101, 44.596196999056829],
            [-91.165619, 44.596986999056831],
            [-91.165548, 44.247444999057052],
            [-91.151932, 44.079664999057208],
            [-91.307478, 44.064875999057207],
            [-91.322143, 43.984461999057309],
            [-91.423568699817295, 43.984296879379905],
            [-91.440536, 44.001500999057278],
            [-91.5592155630397, 44.024209315638458],
            [-91.534229, 44.096045999057189],
            [-91.612994, 44.172605999057104]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "153",
        COUNTYNS: "00758531",
        AFFGEOID: "0500000US29153",
        GEOID: "29153",
        NAME: "Ozark",
        LSAD: "06",
        ALAND: 1929467329,
        AWATER: 26575007
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.764869, 36.806096999094649],
            [-92.112346, 36.794230999094758],
            [-92.120429104388108, 36.498193123097252],
            [-92.150306249501185, 36.498140332421762],
            [-92.350277, 36.497786999097556],
            [-92.529136581043687, 36.498165682082856],
            [-92.564238, 36.498239999097557],
            [-92.772333893372092, 36.498083153176964],
            [-92.764869, 36.806096999094649]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "067",
        COUNTYNS: "00835855",
        AFFGEOID: "0500000US31067",
        GEOID: "31067",
        NAME: "Gage",
        LSAD: "06",
        ALAND: 2205275212,
        AWATER: 22010737
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.916055, 40.349726999068913],
            [-96.913513, 40.370370999068804],
            [-96.912637, 40.523624999068012],
            [-96.463632, 40.523012999068023],
            [-96.463667, 40.262172999069378],
            [-96.463712076774783, 40.000958571625318],
            [-96.469945, 40.000965999070822],
            [-96.805768, 40.001368454166219],
            [-96.873812, 40.001449999070815],
            [-96.916407005465771, 40.001454090114706],
            [-96.916055, 40.349726999068913]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "141",
        COUNTYNS: "01074083",
        AFFGEOID: "0500000US39141",
        GEOID: "39141",
        NAME: "Ross",
        LSAD: "06",
        ALAND: 1784986611,
        AWATER: 9938846
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.386057, 39.319689999074939],
            [-83.372714, 39.377415999074572],
            [-83.266737, 39.516248999073696],
            [-82.98456, 39.506753999073759],
            [-82.998204, 39.48006099907392],
            [-82.740729, 39.468349999074],
            [-82.748591, 39.368164999074622],
            [-82.760111, 39.237643999075466],
            [-82.762892, 39.207966999075666],
            [-82.785891, 39.168768999075915],
            [-83.353531, 39.19758499907573],
            [-83.393794, 39.268047999075286],
            [-83.386057, 39.319689999074939]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "141",
        COUNTYNS: "00465259",
        AFFGEOID: "0500000US19141",
        GEOID: "19141",
        NAME: "O'Brien",
        LSAD: "06",
        ALAND: 1484154165,
        AWATER: 447448
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.861914, 43.257565999058414],
            [-95.388078, 43.255220999058423],
            [-95.388182, 42.909896999059157],
            [-95.859377, 42.909096999059166],
            [-95.861914, 43.257565999058414]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "007",
        COUNTYNS: "01265812",
        AFFGEOID: "0500000US46007",
        GEOID: "46007",
        NAME: "Bennett",
        LSAD: "06",
        ALAND: 3068207883,
        AWATER: 15044355
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.110819, 43.389901999058168],
            [-101.228234, 43.389184999058173],
            [-101.228013397794015, 42.997868738706458],
            [-102.082486319192, 42.99914054757825],
            [-102.108892, 43.129257999058666],
            [-102.110819, 43.389901999058168]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "139",
        COUNTYNS: "01804550",
        AFFGEOID: "0500000US72139",
        GEOID: "72139",
        NAME: "Trujillo Alto",
        LSAD: "13",
        ALAND: 53786135,
        AWATER: 1592601
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.035161, 18.369872999435014],
            [-65.999556, 18.380064999434747],
            [-65.938908, 18.309169999436616],
            [-65.942429, 18.293468999437035],
            [-65.968053, 18.3099909994366],
            [-66.021747, 18.305033999436731],
            [-66.041535, 18.312343999436539],
            [-66.035161, 18.369872999435014]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "087",
        COUNTYNS: "01550050",
        AFFGEOID: "0500000US54087",
        GEOID: "54087",
        NAME: "Roane",
        LSAD: "06",
        ALAND: 1252422375,
        AWATER: 404682
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.52731, 38.901665999077728],
            [-81.502628, 38.91792199907762],
            [-81.448148, 38.941644999077454],
            [-81.343911, 38.895632999077769],
            [-81.278412, 38.914866999077638],
            [-81.220791, 38.843603999078141],
            [-81.158582, 38.644036999079539],
            [-81.08371, 38.611981999079774],
            [-81.130775, 38.565947999080102],
            [-81.194113, 38.527633999080393],
            [-81.471654, 38.546335999080242],
            [-81.522166, 38.61274599907977],
            [-81.546631, 38.672386999079336],
            [-81.52731, 38.901665999077728]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "011",
        COUNTYNS: "01101793",
        AFFGEOID: "0500000US40011",
        GEOID: "40011",
        NAME: "Blaine",
        LSAD: "06",
        ALAND: 2404605819,
        AWATER: 26851760
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.636899, 36.164893999100805],
            [-98.210544, 36.164889999100808],
            [-98.207104, 35.725139999105288],
            [-98.31337, 35.725203999105275],
            [-98.313251, 35.551522999107114],
            [-98.623149, 35.551596999107105],
            [-98.632166, 35.56370999910699],
            [-98.631985, 35.812401999104381],
            [-98.636899, 36.164893999100805]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "025",
        COUNTYNS: "00484982",
        AFFGEOID: "0500000US20025",
        GEOID: "20025",
        NAME: "Clark",
        LSAD: "06",
        ALAND: 2524300311,
        AWATER: 6603384
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.107248, 37.474835999088718],
            [-99.556119, 37.467721999088781],
            [-99.555495, 37.391587999089438],
            [-99.543189, 37.381123999089517],
            [-99.541114912360896, 36.999909643658384],
            [-99.657658, 37.000196999092879],
            [-99.995201, 37.001630999092875],
            [-100.002571514321986, 37.001619153004874],
            [-100.089481743902994, 37.001479468556369],
            [-100.091529, 37.386891999089471],
            [-100.107248, 37.474835999088718]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "121",
        COUNTYNS: "00161586",
        AFFGEOID: "0500000US01121",
        GEOID: "01121",
        NAME: "Talladega",
        LSAD: "06",
        ALAND: 1908251622,
        AWATER: 60931665
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.503127, 33.179143999135349],
            [-86.458026, 33.241433999134529],
            [-86.35734, 33.296916999133806],
            [-86.341113, 33.354598999133067],
            [-86.378665, 33.390982999132582],
            [-86.281999, 33.509894999131042],
            [-86.235328, 33.49453199913124],
            [-86.16948, 33.619235999129657],
            [-86.193733, 33.697850999128661],
            [-86.145562, 33.679097999128899],
            [-86.050669, 33.674589999128962],
            [-85.994935, 33.586474999130076],
            [-85.794559, 33.585564999130085],
            [-85.796054, 33.556219999130462],
            [-85.805241, 33.535188999130725],
            [-85.85189, 33.498741999131191],
            [-85.904909, 33.498654999131205],
            [-85.923762, 33.396205999132512],
            [-85.976525, 33.381869999132704],
            [-85.980293, 33.294189999133842],
            [-86.118198, 33.296319999133807],
            [-86.120567, 33.194510999135147],
            [-86.17283, 33.195680999135128],
            [-86.17437, 33.104393999136335],
            [-86.491029, 33.102943999136357],
            [-86.503127, 33.179143999135349]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "115",
        COUNTYNS: "00516904",
        AFFGEOID: "0500000US21115",
        GEOID: "21115",
        NAME: "Johnson",
        LSAD: "06",
        ALAND: 678468756,
        AWATER: 5639297
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.961423, 37.893826999085256],
            [-82.989014, 37.963967999084701],
            [-82.952043, 38.000315999084407],
            [-82.831615, 37.967883999084663],
            [-82.612393, 37.879271999085368],
            [-82.670812, 37.852090999085597],
            [-82.64075, 37.720181999086677],
            [-82.75223, 37.756226999086365],
            [-82.940763, 37.716337999086697],
            [-82.950582, 37.794733999086063],
            [-83.004649, 37.859246999085542],
            [-82.961423, 37.893826999085256]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "121",
        COUNTYNS: "01266989",
        AFFGEOID: "0500000US46121",
        GEOID: "46121",
        NAME: "Todd",
        LSAD: "06",
        ALAND: 3596445663,
        AWATER: 5854544
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.228234, 43.389184999058173],
            [-100.252092, 43.390401999058163],
            [-100.214221, 43.390320999058162],
            [-100.21385, 43.128387999058667],
            [-100.198412611565999, 42.997976883690441],
            [-100.198413799774997, 42.997976883028365],
            [-101.000429, 42.997529999058962],
            [-101.228013397794015, 42.997868738706458],
            [-101.228234, 43.389184999058173]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "007",
        COUNTYNS: "01383789",
        AFFGEOID: "0500000US48007",
        GEOID: "48007",
        NAME: "Aransas",
        LSAD: "06",
        ALAND: 652804634,
        AWATER: 714591509
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.144916, 28.102621999215408],
            [-97.023586, 28.187656999213846],
            [-97.123799, 28.272458999212311],
            [-96.790512, 28.319237999211463],
            [-96.830928, 28.107893999215307],
            [-96.852070622730082, 28.059820860820793],
            [-96.886459, 28.030733999216725],
            [-97.003325, 27.908306999218979],
            [-97.0436840836865, 27.83653235269211],
            [-97.095713, 27.957834999218065],
            [-97.136312, 27.904389999219056],
            [-97.260818, 28.075758999215903],
            [-97.144916, 28.102621999215408]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "085",
        COUNTYNS: "00351312",
        AFFGEOID: "0500000US13085",
        GEOID: "13085",
        NAME: "Dawson",
        LSAD: "06",
        ALAND: 546042216,
        AWATER: 9272655
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.334938, 34.580161999117948],
            [-84.255112, 34.568332999118077],
            [-84.196754, 34.617923999117487],
            [-84.188557, 34.602691999117681],
            [-84.19104, 34.539180999118429],
            [-84.102037, 34.4645439991193],
            [-83.980649, 34.418388999119848],
            [-83.981433, 34.357828999120564],
            [-83.957077, 34.334010999120849],
            [-84.258075, 34.335155999120836],
            [-84.257586, 34.380991999120297],
            [-84.256867, 34.467326999119265],
            [-84.319679, 34.467856999119263],
            [-84.345553, 34.562734999118142],
            [-84.334938, 34.580161999117948]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "157",
        COUNTYNS: "00465267",
        AFFGEOID: "0500000US19157",
        GEOID: "19157",
        NAME: "Poweshiek",
        LSAD: "06",
        ALAND: 1514929499,
        AWATER: 2977605
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.765999, 41.862372999062245],
            [-92.298164, 41.862778999062243],
            [-92.297494, 41.509789999063564],
            [-92.411995, 41.509547999063571],
            [-92.756508, 41.508793999063563],
            [-92.766759, 41.601354999063204],
            [-92.765999, 41.862372999062245]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "159",
        COUNTYNS: "01383865",
        AFFGEOID: "0500000US48159",
        GEOID: "48159",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 736570306,
        AWATER: 26874743
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.308593, 33.377189999132753],
            [-95.30664, 33.37802699913275],
            [-95.153438, 33.380763999132718],
            [-95.125451, 33.389453999132606],
            [-95.12613, 33.034580999137262],
            [-95.152206, 33.013449999137542],
            [-95.172947, 32.961828999138234],
            [-95.231193, 32.962195999138231],
            [-95.308957, 32.962571999138227],
            [-95.308593, 33.377189999132753]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "047",
        COUNTYNS: "00450352",
        AFFGEOID: "0500000US18047",
        GEOID: "18047",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 995670602,
        AWATER: 17143494
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.29811, 39.525475999073635],
            [-85.036087, 39.526212999073636],
            [-85.011882, 39.524297999073646],
            [-84.816160194910793, 39.521968108066559],
            [-84.817453, 39.391752999074477],
            [-84.8188770978104, 39.305166168127329],
            [-84.818877469275094, 39.305143582634436],
            [-85.065574, 39.307231999075022],
            [-85.217883, 39.308473999075012],
            [-85.29654, 39.268290999075283],
            [-85.297575, 39.453274999074083],
            [-85.29811, 39.525475999073635]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "027",
        COUNTYNS: "00516860",
        AFFGEOID: "0500000US21027",
        GEOID: "21027",
        NAME: "Breckinridge",
        LSAD: "06",
        ALAND: 1469049378,
        AWATER: 47701854
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.646703057859099, 37.864908974983294],
            [-86.615215, 37.852856999085596],
            [-86.599848, 37.906753999085154],
            [-86.509368, 37.902886999085183],
            [-86.525174, 37.968227999084654],
            [-86.521825, 38.038326999084099],
            [-86.488052467128298, 38.043665307983765],
            [-86.242777, 37.877001999085394],
            [-86.151285, 37.798877999086024],
            [-86.27527, 37.593518999087728],
            [-86.409744, 37.566513999087952],
            [-86.511631, 37.622923999087476],
            [-86.59281, 37.564359999087962],
            [-86.640821, 37.619185999087506],
            [-86.638935, 37.661876999087141],
            [-86.62295, 37.656564999087202],
            [-86.677873, 37.792847999086071],
            [-86.646703057859099, 37.864908974983294]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "159",
        COUNTYNS: "00346144",
        AFFGEOID: "0500000US13159",
        GEOID: "13159",
        NAME: "Jasper",
        LSAD: "06",
        ALAND: 953536358,
        AWATER: 13732175
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.824922, 33.437262999131981],
            [-83.674644, 33.487224999131342],
            [-83.682196, 33.526219999130838],
            [-83.533736, 33.434471999132022],
            [-83.545876, 33.171943999135451],
            [-83.816048, 33.131815999135974],
            [-83.822261, 33.180237999135336],
            [-83.807265, 33.192985999135161],
            [-83.863058, 33.368277999132864],
            [-83.824922, 33.437262999131981]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "165",
        COUNTYNS: "01383868",
        AFFGEOID: "0500000US48165",
        GEOID: "48165",
        NAME: "Gaines",
        LSAD: "06",
        ALAND: 3891105276,
        AWATER: 1262946
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.064889, 32.849358999139753],
            [-103.063468888475001, 32.95910638096877],
            [-102.595023, 32.958830999138272],
            [-102.20852, 32.958955999138269],
            [-102.202703, 32.523270999144216],
            [-102.211037, 32.523243999144206],
            [-103.064696, 32.522192999144224],
            [-103.064761, 32.587982999143314],
            [-103.064889, 32.849358999139753]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "211",
        COUNTYNS: "01383891",
        AFFGEOID: "0500000US48211",
        GEOID: "48211",
        NAME: "Hemphill",
        LSAD: "06",
        ALAND: 2347270020,
        AWATER: 15205585
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.540221, 36.056490999101896],
            [-100.000399, 36.055676999101891],
            [-100.000396198329014, 35.880947999100471],
            [-100.000392, 35.619114999106415],
            [-100.540158, 35.619295999106399],
            [-100.540221, 36.056490999101896]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "037",
        COUNTYNS: "00465208",
        AFFGEOID: "0500000US19037",
        GEOID: "19037",
        NAME: "Chickasaw",
        LSAD: "06",
        ALAND: 1306338760,
        AWATER: 3309323
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.554381, 43.212812999058499],
            [-92.080976, 43.212946999058502],
            [-92.081146, 43.082793999058765],
            [-92.081568, 42.907012999059162],
            [-92.554211, 42.907111999059161],
            [-92.554381, 43.212812999058499]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "083",
        COUNTYNS: "01248592",
        AFFGEOID: "0500000US45083",
        GEOID: "45083",
        NAME: "Spartanburg",
        LSAD: "06",
        ALAND: 2092673515,
        AWATER: 29211230
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.2162487192291, 35.193258547764188],
            [-82.048391229647876, 35.189637542486523],
            [-82.039651, 35.189448999111029],
            [-81.969358401257594, 35.186927087511556],
            [-81.87411220431629, 35.183509907137399],
            [-81.805985, 35.055551999112524],
            [-81.754911, 34.9326719991139],
            [-81.711694, 34.912576999114137],
            [-81.78339, 34.837124999114977],
            [-81.854002, 34.594548999117784],
            [-81.863275, 34.580230999117937],
            [-82.071621, 34.704241999116512],
            [-82.146792, 34.785553999115564],
            [-82.165093, 34.796586999115434],
            [-82.226538, 34.850530999114817],
            [-82.2162487192291, 35.193258547764188]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "095",
        COUNTYNS: "00465236",
        AFFGEOID: "0500000US19095",
        GEOID: "19095",
        NAME: "Iowa",
        LSAD: "06",
        ALAND: 1518916008,
        AWATER: 2686847
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.298164, 41.862778999062243],
            [-91.831379, 41.861850999062241],
            [-91.829231, 41.511456999063562],
            [-91.946043, 41.510748999063551],
            [-92.297494, 41.509789999063564],
            [-92.298164, 41.862778999062243]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "133",
        COUNTYNS: "01101854",
        AFFGEOID: "0500000US40133",
        GEOID: "40133",
        NAME: "Seminole",
        LSAD: "06",
        ALAND: 1639040857,
        AWATER: 19778906
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.776707, 35.411371999108617],
            [-96.623699, 35.400721999108733],
            [-96.536718, 35.390771999108843],
            [-96.44129, 35.467711999108005],
            [-96.441368, 35.290121999109921],
            [-96.441401, 35.115769999111855],
            [-96.490434, 35.115856999111841],
            [-96.490786, 34.910584999114135],
            [-96.528587, 34.869585999114612],
            [-96.577089, 34.916583999114074],
            [-96.710934, 34.911714999114139],
            [-96.727591, 34.857181999114758],
            [-96.775681, 34.899957999114264],
            [-96.776707, 35.411371999108617]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "155",
        COUNTYNS: "00659522",
        AFFGEOID: "0500000US27155",
        GEOID: "27155",
        NAME: "Traverse",
        LSAD: "06",
        ALAND: 1486320975,
        AWATER: 31025234
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.851621, 45.619411999057021],
            [-96.82616, 45.65416399905704],
            [-96.745086, 45.701575999057077],
            [-96.672665, 45.732335999057113],
            [-96.630512, 45.781156999057153],
            [-96.587093, 45.816444999057182],
            [-96.571871, 45.87184599905725],
            [-96.563672, 45.935244999057318],
            [-96.574264, 46.016544999057423],
            [-96.572697494194003, 46.021891670415108],
            [-96.26614, 46.021609999057411],
            [-96.253675, 45.934647999057312],
            [-96.254022, 45.759819999057143],
            [-96.253026, 45.585525999056983],
            [-96.835419161978095, 45.586128691937795],
            [-96.843957, 45.594002999056997],
            [-96.851621, 45.619411999057021]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "059",
        COUNTYNS: "00450357",
        AFFGEOID: "0500000US18059",
        GEOID: "18059",
        NAME: "Hancock",
        LSAD: "06",
        ALAND: 792563315,
        AWATER: 2628056
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.953683, 39.856116999071638],
            [-85.938067, 39.869811999071565],
            [-85.937587, 39.927136999071237],
            [-85.862489, 39.943617999071144],
            [-85.576192, 39.945755999071125],
            [-85.596678, 39.872925999071548],
            [-85.596916, 39.786518999072058],
            [-85.633491, 39.786176999072055],
            [-85.633228, 39.698461999072585],
            [-85.951721, 39.697135999072586],
            [-85.953683, 39.856116999071638]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "087",
        COUNTYNS: "00277308",
        AFFGEOID: "0500000US06087",
        GEOID: "06087",
        NAME: "Santa Cruz",
        LSAD: "06",
        ALAND: 1152967426,
        AWATER: 419570389
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.317682, 37.186944999091217],
            [-122.152774, 37.215443999090958],
            [-122.152278, 37.286054999090354],
            [-121.99109, 37.144269999091584],
            [-121.84629, 37.097019999092012],
            [-121.809076, 37.069300999092256],
            [-121.738697, 36.989990999092974],
            [-121.581354, 36.899151999093803],
            [-121.644001, 36.893995999093846],
            [-121.699956, 36.91968299909361],
            [-121.812732426762011, 36.850049442839762],
            [-121.862266, 36.931551999093507],
            [-121.906468, 36.968949999093169],
            [-121.95167, 36.971449999093139],
            [-122.027174, 36.951149999093325],
            [-122.067316729059982, 36.953595688824713],
            [-122.105976, 36.955950999093282],
            [-122.20618, 37.013948999092754],
            [-122.260481, 37.07254799909223],
            [-122.284882, 37.101746999091972],
            [-122.294310191913013, 37.105141385810029],
            [-122.317682, 37.186944999091217]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "011",
        COUNTYNS: "00063757",
        AFFGEOID: "0500000US05011",
        GEOID: "05011",
        NAME: "Bradley",
        LSAD: "06",
        ALAND: 1681514463,
        AWATER: 9532926
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.378616, 33.314732999133568],
            [-92.311079, 33.49978199913118],
            [-92.330839, 33.707805999128531],
            [-91.975844, 33.704413999128569],
            [-91.97608, 33.529686999130796],
            [-92.000134, 33.473926999131514],
            [-91.990167, 33.398213999132487],
            [-91.977284, 33.30202599913374],
            [-92.128464, 33.208774999134953],
            [-92.137527, 33.162955999135569],
            [-92.222362, 33.209257999134948],
            [-92.22406, 33.251781999134387],
            [-92.356064, 33.281158999134014],
            [-92.350432, 33.298940999133784],
            [-92.378616, 33.314732999133568]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "171",
        COUNTYNS: "00465274",
        AFFGEOID: "0500000US19171",
        GEOID: "19171",
        NAME: "Tama",
        LSAD: "06",
        ALAND: 1867411148,
        AWATER: 2769421
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.76809, 42.296679999060814],
            [-92.53351, 42.297000999060813],
            [-92.298792, 42.2975269990608],
            [-92.298164, 41.862778999062243],
            [-92.765999, 41.862372999062245],
            [-92.767463, 42.210139999061077],
            [-92.76809, 42.296679999060814]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "031",
        COUNTYNS: "01674917",
        AFFGEOID: "0500000US51031",
        GEOID: "51031",
        NAME: "Campbell",
        LSAD: "06",
        ALAND: 1303240046,
        AWATER: 8955341
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.442859, 37.05586699909238],
            [-79.330477, 37.238417999090771],
            [-79.257955, 37.355815999089742],
            [-79.214982, 37.332999999089935],
            [-79.085123, 37.394874999089403],
            [-79.067923, 37.385845999089483],
            [-79.021687, 37.429242999089112],
            [-78.921665, 37.245911999090687],
            [-78.824209, 37.205360999091049],
            [-78.904587, 37.022287999092676],
            [-78.971675, 37.050059999092419],
            [-79.092264, 37.061497999092339],
            [-79.194525, 37.055815999092381],
            [-79.217104, 37.118873999091811],
            [-79.341422, 37.13716599909165],
            [-79.442859, 37.05586699909238]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "001",
        COUNTYNS: "01581060",
        AFFGEOID: "0500000US55001",
        GEOID: "55001",
        NAME: "Adams",
        LSAD: "06",
        ALAND: 1672140731,
        AWATER: 108432130
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.025953, 44.091747999057191],
            [-89.923387, 44.152415999057133],
            [-89.902783, 44.249472999057048],
            [-89.724746, 44.247677999057046],
            [-89.59798, 44.245724999057053],
            [-89.59795, 43.982099999057304],
            [-89.599547, 43.642635999057745],
            [-89.784901, 43.641050999057761],
            [-89.78702, 43.667148999057716],
            [-89.865132, 43.783413999057558],
            [-89.980676, 43.905622999057385],
            [-89.954449, 43.921985999057377],
            [-90.025953, 44.091747999057191]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "179",
        COUNTYNS: "01008590",
        AFFGEOID: "0500000US37179",
        GEOID: "37179",
        NAME: "Union",
        LSAD: "06",
        ALAND: 1636332950,
        AWATER: 20337550
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.840566909021376, 35.001471528507416],
            [-80.77248, 35.033156999112769],
            [-80.550349, 35.20841199911083],
            [-80.504985, 35.184997999111083],
            [-80.356982, 35.164939999111311],
            [-80.276829, 35.195721999110958],
            [-80.320832571129785, 34.81362082065926],
            [-80.561657, 34.817480999115212],
            [-80.561708549382402, 34.817482399451706],
            [-80.796997, 34.823873999115129],
            [-80.782042, 34.935781999113857],
            [-80.840566909021376, 35.001471528507416]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "165",
        COUNTYNS: "00450397",
        AFFGEOID: "0500000US18165",
        GEOID: "18165",
        NAME: "Vermillion",
        LSAD: "06",
        ALAND: 665311748,
        AWATER: 7904759
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.532454493884302, 39.882999117853792],
            [-87.532308, 40.011586999070758],
            [-87.531021612428006, 40.148035395815697],
            [-87.49041, 40.127405999070113],
            [-87.406667, 40.127507999070112],
            [-87.440179, 39.961175999071031],
            [-87.420221, 39.952451999071101],
            [-87.353863, 39.862076999071618],
            [-87.395625, 39.652949999072867],
            [-87.382418, 39.607936999073132],
            [-87.532385282791012, 39.607304943294629],
            [-87.532703, 39.664867999072783],
            [-87.532454493884302, 39.882999117853792]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "011",
        COUNTYNS: "01035619",
        AFFGEOID: "0500000US38011",
        GEOID: "38011",
        NAME: "Bowman",
        LSAD: "06",
        ALAND: 3009306320,
        AWATER: 13587978
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.045465958927991, 46.280188047268467],
            [-102.995454, 46.280712999057776],
            [-102.995668019138009, 45.945116442828827],
            [-103.218396, 45.945207999057324],
            [-103.434851, 45.945290999057335],
            [-103.660779, 45.945240999057326],
            [-104.045443, 45.945309999057329],
            [-104.045465958927991, 46.280188047268467]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "031",
        COUNTYNS: "00277280",
        AFFGEOID: "0500000US06031",
        GEOID: "06031",
        NAME: "Kings",
        LSAD: "06",
        ALAND: 3598593366,
        AWATER: 5453080
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.315068, 35.907185999103405],
            [-119.959058, 36.181746999100646],
            [-119.959227, 36.400974999098487],
            [-119.754213, 36.402022999098484],
            [-119.6664, 36.418879999098323],
            [-119.573194, 36.488834999097641],
            [-119.528286, 36.487544999097665],
            [-119.528792, 36.401464999098494],
            [-119.474892, 36.4009529990985],
            [-119.474607, 36.269024999099784],
            [-119.529358, 36.269849999099769],
            [-119.536161, 36.050781999101943],
            [-119.538116, 35.789566999104622],
            [-120.194146, 35.789203999104622],
            [-120.213979, 35.789275999104625],
            [-120.27576, 35.905880999103431],
            [-120.315068, 35.907185999103405]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "123",
        COUNTYNS: "00516908",
        AFFGEOID: "0500000US21123",
        GEOID: "21123",
        NAME: "Larue",
        LSAD: "06",
        ALAND: 677386387,
        AWATER: 5418290
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.875807, 37.543996999088137],
            [-85.735949, 37.654597999087208],
            [-85.680488, 37.732283999086576],
            [-85.592088, 37.693478999086885],
            [-85.62394, 37.540488999088176],
            [-85.52129, 37.554342999088036],
            [-85.503001, 37.549089999088096],
            [-85.46625, 37.4655949990888],
            [-85.498434, 37.482789999088645],
            [-85.583497, 37.469916999088767],
            [-85.634193, 37.47178499908874],
            [-85.657332, 37.42199599908917],
            [-85.892804, 37.440307999089022],
            [-85.875807, 37.543996999088137]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "125",
        COUNTYNS: "01101850",
        AFFGEOID: "0500000US40125",
        GEOID: "40125",
        NAME: "Pottawatomie",
        LSAD: "06",
        ALAND: 2040060549,
        AWATER: 14842439
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.142114, 35.376859999108994],
            [-97.14149, 35.463976999108056],
            [-96.624865, 35.462705999108067],
            [-96.623699, 35.400721999108733],
            [-96.776707, 35.411371999108617],
            [-96.775681, 34.899957999114264],
            [-96.813452, 34.94464899911376],
            [-96.8854, 34.926250999113968],
            [-96.930586, 34.964363999113537],
            [-97.01593, 34.90734799911418],
            [-97.142349, 34.928175999113954],
            [-97.142114, 35.376859999108994]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "079",
        COUNTYNS: "01804520",
        AFFGEOID: "0500000US72079",
        GEOID: "72079",
        NAME: "Lajas",
        LSAD: "13",
        ALAND: 155287823,
        AWATER: 106643202
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.109044, 18.056084999443335],
            [-67.068517, 18.066140999443064],
            [-66.983337, 18.052517999443427],
            [-66.958748, 18.032476999443965],
            [-66.961935, 17.991591999445053],
            [-66.97870064243881, 17.95729094152686],
            [-66.982206, 17.961191999445866],
            [-67.014744, 17.968467999445668],
            [-67.054455, 17.97316499944554],
            [-67.089827, 17.951417999446122],
            [-67.107809434276305, 17.951623192348521],
            [-67.115765, 18.031461999443987],
            [-67.109044, 18.056084999443335]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "069",
        COUNTYNS: "01804515",
        AFFGEOID: "0500000US72069",
        GEOID: "72069",
        NAME: "Humacao",
        LSAD: "13",
        ALAND: 115929990,
        AWATER: 68988203
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-65.854877, 18.176775999440132],
            [-65.826063, 18.200407999439498],
            [-65.792477, 18.20675899943933],
            [-65.733357095755693, 18.165770471656113],
            [-65.758728, 18.156600999440659],
            [-65.777584, 18.129238999441391],
            [-65.802906686375493, 18.071185999442932],
            [-65.848117, 18.117487999441703],
            [-65.877615, 18.118166999441684],
            [-65.854877, 18.176775999440132]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "129",
        COUNTYNS: "00659510",
        AFFGEOID: "0500000US27129",
        GEOID: "27129",
        NAME: "Renville",
        LSAD: "06",
        ALAND: 2545744678,
        AWATER: 10851893
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.482889, 44.890711999056762],
            [-95.24852, 44.891309999056766],
            [-94.758189, 44.892096999056761],
            [-94.502395, 44.892294999056752],
            [-94.498028, 44.872515999056766],
            [-94.498074, 44.717170999056798],
            [-94.629489, 44.716662999056794],
            [-94.6242, 44.456029999056909],
            [-94.78063, 44.4566499990569],
            [-94.81488, 44.484449999056892],
            [-94.865824, 44.498217999056898],
            [-94.880335, 44.516793999056873],
            [-95.132652, 44.596442999056841],
            [-95.244218, 44.667059999056811],
            [-95.360177, 44.698539999056798],
            [-95.415854, 44.734504999056782],
            [-95.482843, 44.752504999056789],
            [-95.482889, 44.890711999056762]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "169",
        COUNTYNS: "00485047",
        AFFGEOID: "0500000US20169",
        GEOID: "20169",
        NAME: "Saline",
        LSAD: "06",
        ALAND: 1865381414,
        AWATER: 2809967
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.92856, 38.958394999077335],
            [-97.371614, 38.957851999077342],
            [-97.381205, 38.928899999077551],
            [-97.371911, 38.609352999079803],
            [-97.924795, 38.609879999079794],
            [-97.928592, 38.871098999077944],
            [-97.92856, 38.958394999077335]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "077",
        COUNTYNS: "01101826",
        AFFGEOID: "0500000US40077",
        GEOID: "40077",
        NAME: "Latimer",
        LSAD: "06",
        ALAND: 1870180320,
        AWATER: 18213565
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.507658, 35.029196999112813],
            [-95.349233, 35.058513999112478],
            [-94.927863, 35.058278999112495],
            [-94.927668, 34.942211999113795],
            [-94.99812, 34.942156999113784],
            [-94.99781, 34.855077999114769],
            [-95.059512, 34.855018999114776],
            [-95.059616, 34.680735999116763],
            [-95.514514, 34.681141999116782],
            [-95.507658, 35.029196999112813]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "109",
        COUNTYNS: "00465243",
        AFFGEOID: "0500000US19109",
        GEOID: "19109",
        NAME: "Kossuth",
        LSAD: "06",
        ALAND: 2519361813,
        AWATER: 4125592
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.443137, 43.255013999058413],
            [-94.442849989718198, 43.500478506712675],
            [-94.390597, 43.500468999057965],
            [-94.247967441555204, 43.500175475765559],
            [-93.970761558528196, 43.499605002265362],
            [-93.970415, 43.255357999058418],
            [-93.971238, 42.907761999059161],
            [-94.442954, 42.908072999059165],
            [-94.443137, 43.255013999058413]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "001",
        COUNTYNS: "01213656",
        AFFGEOID: "0500000US42001",
        GEOID: "42001",
        NAME: "Adams",
        LSAD: "06",
        ALAND: 1343396471,
        AWATER: 7887889
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.471085, 39.944076999071143],
            [-77.403606, 39.994477999070853],
            [-77.18563, 40.029500999070663],
            [-77.137425, 40.06994499907043],
            [-77.018648, 40.013946999070747],
            [-76.966202, 39.935191999071201],
            [-76.954932, 39.856849999071642],
            [-77.000312, 39.831218999071794],
            [-76.999318026333199, 39.720067671425056],
            [-77.217023755237392, 39.720217247457853],
            [-77.23995, 39.720232999072458],
            [-77.45943341952389, 39.720229170717438],
            [-77.471085, 39.944076999071143]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "065",
        COUNTYNS: "00450359",
        AFFGEOID: "0500000US18065",
        GEOID: "18065",
        NAME: "Henry",
        LSAD: "06",
        ALAND: 1014954735,
        AWATER: 7655289
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.596678, 39.872925999071548],
            [-85.576192, 39.945755999071125],
            [-85.576197, 40.077142999070389],
            [-85.214386, 40.076888999070391],
            [-85.213543, 40.015602999070737],
            [-85.201473, 40.004520999070792],
            [-85.200945, 39.873798999071553],
            [-85.221118, 39.788448999072052],
            [-85.301584, 39.787578999072053],
            [-85.596916, 39.786518999072058],
            [-85.596678, 39.872925999071548]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "067",
        COUNTYNS: "01265771",
        AFFGEOID: "0500000US46067",
        GEOID: "46067",
        NAME: "Hutchinson",
        LSAD: "06",
        ALAND: 2105695489,
        AWATER: 3644220
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.114758, 43.498296999057978],
            [-97.965012, 43.499039999057992],
            [-97.607012, 43.499825999057975],
            [-97.40145, 43.499782999057977],
            [-97.399191, 43.169418999058585],
            [-97.637496, 43.168769999058583],
            [-98.077108, 43.168329999058585],
            [-98.109492, 43.19683799905853],
            [-98.114758, 43.498296999057978]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "119",
        COUNTYNS: "01581119",
        AFFGEOID: "0500000US55119",
        GEOID: "55119",
        NAME: "Taylor",
        LSAD: "06",
        ALAND: 2525318829,
        AWATER: 24686414
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.925343, 45.379349999056863],
            [-90.678773, 45.377906999056862],
            [-90.042273, 45.381865999056863],
            [-90.043685, 45.12021399905678],
            [-90.197765, 45.120603999056769],
            [-90.197514, 45.033639999056767],
            [-90.315038, 45.033828999056759],
            [-90.433612, 45.030976999056769],
            [-90.923362, 45.031142999056762],
            [-90.925222, 45.292060999056829],
            [-90.925343, 45.379349999056863]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "095",
        COUNTYNS: "00351259",
        AFFGEOID: "0500000US13095",
        GEOID: "13095",
        NAME: "Dougherty",
        LSAD: "06",
        ALAND: 851140307,
        AWATER: 15396055
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.447032, 31.605080999157376],
            [-84.450398, 31.621867999157125],
            [-84.297801, 31.621950999157118],
            [-84.043213, 31.623572999157094],
            [-84.018404, 31.650273999156699],
            [-83.993374, 31.650033999156712],
            [-83.996246, 31.625168999157069],
            [-83.997796, 31.44375299915977],
            [-84.11864, 31.442478999159789],
            [-84.141124, 31.440178999159823],
            [-84.211593, 31.435855999159891],
            [-84.429876, 31.436660999159873],
            [-84.412521, 31.45692999915957],
            [-84.454923, 31.548330999158207],
            [-84.447032, 31.605080999157376]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "039",
        COUNTYNS: "00516866",
        AFFGEOID: "0500000US21039",
        GEOID: "21039",
        NAME: "Carlisle",
        LSAD: "06",
        ALAND: 490632724,
        AWATER: 24722530
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.155891, 36.789125999094814],
            [-89.147674, 36.847147999094275],
            [-89.120472, 36.891895999093855],
            [-89.103135448326597, 36.944760899415684],
            [-89.080253, 36.915485999093654],
            [-88.815952, 36.954099999093302],
            [-88.812897, 36.946863999093367],
            [-88.813229, 36.773114999094958],
            [-89.067265, 36.772762999094972],
            [-89.104158, 36.797284999094735],
            [-89.155984909070384, 36.786292662466252],
            [-89.155891, 36.789125999094814]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "157",
        COUNTYNS: "00349570",
        AFFGEOID: "0500000US13157",
        GEOID: "13157",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 879710263,
        AWATER: 8907131
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.817682, 34.127492999123326],
            [-83.620115, 34.295275999121323],
            [-83.480361, 34.262037999121709],
            [-83.402428, 34.197498999122487],
            [-83.357051, 34.116198999123476],
            [-83.386763, 34.050240999124284],
            [-83.360028, 34.040571999124403],
            [-83.384863, 34.037804999124432],
            [-83.454025, 34.00480899912484],
            [-83.503054, 33.999576999124898],
            [-83.537385, 33.965911999125311],
            [-83.563277, 34.031863999124504],
            [-83.767532, 34.06644799912408],
            [-83.817682, 34.127492999123326]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "131",
        COUNTYNS: "00446852",
        AFFGEOID: "0500000US18131",
        GEOID: "18131",
        NAME: "Pulaski",
        LSAD: "06",
        ALAND: 1123146087,
        AWATER: 2291536
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.93017, 41.172317999064958],
            [-86.467046, 41.17144199906496],
            [-86.468509, 40.909888999066126],
            [-86.581532, 40.910924999066133],
            [-86.930759, 40.912417999066115],
            [-86.93017, 41.172317999064958]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "102",
        COUNTYNS: "01266992",
        AFFGEOID: "0500000US46102",
        GEOID: "46102",
        NAME: "Oglala Lakota",
        LSAD: "06",
        ALAND: 5422404947,
        AWATER: 7126155
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.001017, 43.605815999057796],
            [-102.9025, 43.674887999057695],
            [-102.809398, 43.687559999057683],
            [-102.175231, 43.687755999057686],
            [-102.139158, 43.700947999057654],
            [-102.139051, 43.476675999058003],
            [-102.110819, 43.389901999058168],
            [-102.108892, 43.129257999058666],
            [-102.082486319192, 42.99914054757825],
            [-102.082548043072009, 42.999140639448953],
            [-102.40864, 42.999625999058956],
            [-102.792110939839006, 43.000036234829345],
            [-103.000609526033003, 43.000259285833359],
            [-103.000913, 43.476847999058016],
            [-103.001017, 43.605815999057796]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "683",
        COUNTYNS: "01498430",
        AFFGEOID: "0500000US51683",
        GEOID: "51683",
        NAME: "Manassas",
        LSAD: "25",
        ALAND: 25508044,
        AWATER: 147721
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.47576, 38.781170999078576],
            [-77.458695, 38.772318999078621],
            [-77.471177, 38.727664999078947],
            [-77.523569, 38.717641999079021],
            [-77.47576, 38.781170999078576]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "167",
        COUNTYNS: "00485046",
        AFFGEOID: "0500000US20167",
        GEOID: "20167",
        NAME: "Russell",
        LSAD: "06",
        ALAND: 2295402887,
        AWATER: 34126777
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.0375, 39.133120999076148],
            [-98.489997, 39.132696999076167],
            [-98.483813, 39.114228999076296],
            [-98.484861, 38.87079099907794],
            [-98.486108, 38.696877999079156],
            [-99.032971, 38.696758999079165],
            [-99.042626, 38.696806999079165],
            [-99.0375, 39.133120999076148]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "205",
        COUNTYNS: "01383888",
        AFFGEOID: "0500000US48205",
        GEOID: "48205",
        NAME: "Hartley",
        LSAD: "06",
        ALAND: 3786435518,
        AWATER: 3023182
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.041356721818005, 35.739433505453839],
            [-103.040824, 36.055230999101902],
            [-102.163015, 36.055248999101899],
            [-102.162809, 35.62751499910631],
            [-103.041553998934006, 35.62248763106507],
            [-103.041356721818005, 35.739433505453839]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "033",
        COUNTYNS: "00198132",
        AFFGEOID: "0500000US08033",
        GEOID: "08033",
        NAME: "Dolores",
        LSAD: "06",
        ALAND: 2763958520,
        AWATER: 2716141
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.042604100621006, 37.88108481657796],
            [-108.81671, 37.895537999085242],
            [-108.256498, 37.894606999085248],
            [-108.210164, 37.821124999085846],
            [-107.989208, 37.856639999085559],
            [-107.860845, 37.775516999086214],
            [-107.872966, 37.72241599908665],
            [-107.968875, 37.696484999086856],
            [-107.970086, 37.639586999087335],
            [-108.250635, 37.632846999087391],
            [-108.917081, 37.631901999087404],
            [-109.043780720502994, 37.484819230506147],
            [-109.042604100621006, 37.88108481657796]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "105",
        COUNTYNS: "01804533",
        AFFGEOID: "0500000US72105",
        GEOID: "72105",
        NAME: "Naranjito",
        LSAD: "13",
        ALAND: 70969180,
        AWATER: 898339
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.296086, 18.257544999437986],
            [-66.278076, 18.329974999436068],
            [-66.217179, 18.334193999435961],
            [-66.204734, 18.31603499943644],
            [-66.206135, 18.276659999437481],
            [-66.228094, 18.254026999438079],
            [-66.266466, 18.245269999438314],
            [-66.313299, 18.2493239994382],
            [-66.296086, 18.257544999437986]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "011",
        COUNTYNS: "00595737",
        AFFGEOID: "0500000US24011",
        GEOID: "24011",
        NAME: "Caroline",
        LSAD: "06",
        ALAND: 827350251,
        AWATER: 16777064
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.014427, 38.726462999078947],
            [-75.896774, 38.815108999078333],
            [-75.949335, 38.918332999077613],
            [-75.940933, 38.976294999077219],
            [-75.834948, 39.108436999076311],
            [-75.748154814254093, 39.14313173003579],
            [-75.723102693332692, 38.829826555605926],
            [-75.707555026429006, 38.635385354139501],
            [-75.839103, 38.704123999079108],
            [-75.945248, 38.674108999079337],
            [-76.014427, 38.726462999078947]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "011",
        COUNTYNS: "00465195",
        AFFGEOID: "0500000US19011",
        GEOID: "19011",
        NAME: "Benton",
        LSAD: "06",
        ALAND: 1855117319,
        AWATER: 5760770
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.298792, 42.2975269990608],
            [-92.064766, 42.297258999060816],
            [-91.831089, 42.299062999060801],
            [-91.831379, 41.861850999062241],
            [-92.298164, 41.862778999062243],
            [-92.298792, 42.2975269990608]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "189",
        COUNTYNS: "01785150",
        AFFGEOID: "0500000US17189",
        GEOID: "17189",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1457058107,
        AWATER: 3454759
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.704417, 38.415666999081225],
            [-89.577521, 38.483159999080705],
            [-89.481556, 38.468464999080823],
            [-89.360248, 38.516521999080467],
            [-89.143898, 38.50308799908057],
            [-89.144388, 38.473877999080784],
            [-89.147416, 38.212895999082754],
            [-89.592797, 38.219270999082703],
            [-89.703256, 38.219403999082708],
            [-89.704417, 38.415666999081225]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "151",
        COUNTYNS: "01008580",
        AFFGEOID: "0500000US37151",
        GEOID: "37151",
        NAME: "Randolph",
        LSAD: "06",
        ALAND: 2026174233,
        AWATER: 19822770
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.046869, 35.920692999103274],
            [-79.713233, 35.906789999103424],
            [-79.541728, 35.899850999103485],
            [-79.542428, 35.843302999104075],
            [-79.555804, 35.51503899910751],
            [-79.767957, 35.51148399910754],
            [-80.066842, 35.505660999107597],
            [-80.046869, 35.920692999103274]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "087",
        COUNTYNS: "01383829",
        AFFGEOID: "0500000US48087",
        GEOID: "48087",
        NAME: "Collingsworth",
        LSAD: "06",
        ALAND: 2378749932,
        AWATER: 2279193
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.538978, 35.18314399911111],
            [-100.000385, 35.182701999111117],
            [-100.000384020773012, 35.030384999112698],
            [-100.000382194816012, 34.746360551677618],
            [-100.415895, 34.747524999116003],
            [-100.540703, 34.747722999116007],
            [-100.538978, 35.18314399911111]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "035",
        COUNTYNS: "00484987",
        AFFGEOID: "0500000US20035",
        GEOID: "20035",
        NAME: "Cowley",
        LSAD: "06",
        ALAND: 2915648172,
        AWATER: 17322934
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.153331, 37.475543999088714],
            [-96.52569, 37.476404999088707],
            [-96.524873, 37.302729999090204],
            [-96.525582044282899, 36.998677967817699],
            [-96.749838, 36.99898799909289],
            [-97.100652, 36.9989979990929],
            [-97.147720966922691, 36.998972334714388],
            [-97.153331, 37.475543999088714]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "039",
        COUNTYNS: "01034219",
        AFFGEOID: "0500000US38039",
        GEOID: "38039",
        NAME: "Griggs",
        LSAD: "06",
        ALAND: 1835826857,
        AWATER: 19759408
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.500181, 47.672412999061017],
            [-97.98481, 47.672299999061011],
            [-97.986585, 47.326099999060006],
            [-97.961208, 47.24051199905977],
            [-98.467476, 47.240452999059762],
            [-98.467265, 47.325357999059996],
            [-98.499246, 47.326537999060001],
            [-98.500085, 47.587227999060737],
            [-98.500181, 47.672412999061017]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "057",
        COUNTYNS: "00066858",
        AFFGEOID: "0500000US05057",
        GEOID: "05057",
        NAME: "Hempstead",
        LSAD: "06",
        ALAND: 1884268212,
        AWATER: 35372110
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.957315, 33.750939999127993],
            [-93.831452, 33.747708999128037],
            [-93.823076, 34.008892999124797],
            [-93.648131, 33.982966999125104],
            [-93.528263, 33.943022999125596],
            [-93.456709, 33.956836999125422],
            [-93.471207, 33.825223999127061],
            [-93.483097, 33.476074999131491],
            [-93.722867, 33.481962999131405],
            [-93.772686, 33.498512999131194],
            [-93.825884, 33.609852999129771],
            [-93.965637, 33.669735999129017],
            [-93.957315, 33.750939999127993]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "530",
        COUNTYNS: "01498417",
        AFFGEOID: "0500000US51530",
        GEOID: "51530",
        NAME: "Buena Vista",
        LSAD: "25",
        ALAND: 16673676,
        AWATER: 219579
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.381509, 37.742148999086481],
            [-79.33113, 37.75990399908634],
            [-79.326012, 37.743268999086474],
            [-79.371705, 37.695746999086872],
            [-79.366105, 37.726314999086625],
            [-79.381509, 37.742148999086481]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "133",
        COUNTYNS: "01623009",
        AFFGEOID: "0500000US26133",
        GEOID: "26133",
        NAME: "Osceola",
        LSAD: "06",
        ALAND: 1466836685,
        AWATER: 17262767
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.564554, 44.164860999057119],
            [-85.334777, 44.165117999057124],
            [-85.087403, 44.164241999057118],
            [-85.088811, 43.813675999057509],
            [-85.562433, 43.815482999057508],
            [-85.564554, 44.164860999057119]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "153",
        COUNTYNS: "01383862",
        AFFGEOID: "0500000US48153",
        GEOID: "48153",
        NAME: "Floyd",
        LSAD: "06",
        ALAND: 2569637276,
        AWATER: 983737
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.564856, 34.312492999121098],
            [-101.47158, 34.312289999121113],
            [-101.041484, 34.312443999121108],
            [-101.041437, 34.130795999123301],
            [-101.041158, 33.833624999126961],
            [-101.56358, 33.830450999126995],
            [-101.564563, 34.074227999123991],
            [-101.564856, 34.312492999121098]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "079",
        COUNTYNS: "01265776",
        AFFGEOID: "0500000US46079",
        GEOID: "46079",
        NAME: "Lake",
        LSAD: "06",
        ALAND: 1457893335,
        AWATER: 31769730
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.370115, 44.194970999057084],
            [-97.129671, 44.195932999057085],
            [-96.889216, 44.195704999057092],
            [-96.888664, 43.848384999057458],
            [-97.129089, 43.847972999057468],
            [-97.369463, 43.848525999057465],
            [-97.370115, 44.194970999057084]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "027",
        COUNTYNS: "01265764",
        AFFGEOID: "0500000US46027",
        GEOID: "46027",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 1067154414,
        AWATER: 13955950
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.16036, 42.811794999059394],
            [-97.160543, 43.083144999058774],
            [-96.924142, 43.083732999058775],
            [-96.805682, 43.08366999905877],
            [-96.807370600098196, 42.70067898443267],
            [-96.906797, 42.733799999059599],
            [-96.965679, 42.724531999059607],
            [-97.015631112747201, 42.756525461547717],
            [-97.02485, 42.762429999059513],
            [-97.131331, 42.771928999059497],
            [-97.165070392373806, 42.791616640527145],
            [-97.16036, 42.811794999059394]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "037",
        COUNTYNS: "01213665",
        AFFGEOID: "0500000US42037",
        GEOID: "42037",
        NAME: "Columbia",
        LSAD: "06",
        ALAND: 1251240776,
        AWATER: 18266225
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.640767, 41.15571799906504],
            [-76.592607, 41.15776499906503],
            [-76.447597, 41.275628999064516],
            [-76.407934, 41.308417999064382],
            [-76.310133, 41.310198999064362],
            [-76.319236, 41.208091999064813],
            [-76.277639, 41.131803999065141],
            [-76.228975, 41.138465999065119],
            [-76.207827, 40.949739999065962],
            [-76.284611, 40.883587999066251],
            [-76.30717, 40.801808999066644],
            [-76.380152, 40.775510999066768],
            [-76.409667, 40.833623999066496],
            [-76.501312, 40.824816999066535],
            [-76.528034, 40.882514999066267],
            [-76.514789, 40.958513999065914],
            [-76.618971, 41.063758999065442],
            [-76.640767, 41.15571799906504]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "540",
        COUNTYNS: "01789068",
        AFFGEOID: "0500000US51540",
        GEOID: "51540",
        NAME: "Charlottesville",
        LSAD: "25",
        ALAND: 26533566,
        AWATER: 47160
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.495034, 38.065336999083904],
            [-78.46613, 38.045699999084064],
            [-78.448796, 38.058446999083948],
            [-78.46368, 38.020450999084254],
            [-78.519333, 38.010677999084322],
            [-78.495034, 38.065336999083904]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "033",
        COUNTYNS: "01480107",
        AFFGEOID: "0500000US51033",
        GEOID: "51033",
        NAME: "Caroline",
        LSAD: "06",
        ALAND: 1366471980,
        AWATER: 24948487
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.517616, 38.105419999083573],
            [-77.370297, 38.246575999082495],
            [-77.326692, 38.2451359990825],
            [-77.229757, 38.245207999082503],
            [-77.2143, 38.197028999082875],
            [-77.115997, 38.149930999083224],
            [-77.1701, 38.079542999083792],
            [-77.070115, 37.964769999084695],
            [-77.167284, 37.9639039990847],
            [-77.181418, 37.891653999085271],
            [-77.241044, 37.909907999085135],
            [-77.345215, 37.789174999086107],
            [-77.397317, 37.777288999086203],
            [-77.48417, 37.883368999085334],
            [-77.642518, 37.990687999084479],
            [-77.517616, 38.105419999083573]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "133",
        COUNTYNS: "00659512",
        AFFGEOID: "0500000US27133",
        GEOID: "27133",
        NAME: "Rock",
        LSAD: "06",
        ALAND: 1249560774,
        AWATER: 698231
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.453315, 43.552298999057882],
            [-96.452909574366288, 43.849506892075858],
            [-96.452909571693198, 43.849508851694658],
            [-96.06462, 43.849038999057463],
            [-96.05232, 43.849069999057456],
            [-96.05316283450999, 43.500187707215467],
            [-96.198484, 43.500334999057976],
            [-96.45326, 43.500389999057973],
            [-96.453315, 43.552298999057882]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "053",
        COUNTYNS: "00758481",
        AFFGEOID: "0500000US29053",
        GEOID: "29053",
        NAME: "Cooper",
        LSAD: "06",
        ALAND: 1462738176,
        AWATER: 11434005
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.050453, 38.928243999077544],
            [-93.048471, 38.972417999077244],
            [-92.934569, 39.064546999076612],
            [-92.852628, 38.982182999077168],
            [-92.558086, 38.97069199907726],
            [-92.498377, 38.922083999077593],
            [-92.617147, 38.680012999079281],
            [-92.839837, 38.683973999079264],
            [-93.059974, 38.693076999079182],
            [-93.050453, 38.928243999077544]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "147",
        COUNTYNS: "00485037",
        AFFGEOID: "0500000US20147",
        GEOID: "20147",
        NAME: "Phillips",
        LSAD: "06",
        ALAND: 2294395642,
        AWATER: 22493382
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.625326726399592, 40.00177782671782],
            [-99.501792, 40.00202599907081],
            [-99.179133150861077, 40.002108951765713],
            [-99.085597, 40.002132999070824],
            [-99.067018349898092, 40.002143512123006],
            [-99.06622, 39.568124999073369],
            [-99.602176, 39.56732799907337],
            [-99.627953, 39.567436999073379],
            [-99.625326726399592, 40.00177782671782]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "011",
        COUNTYNS: "00858640",
        AFFGEOID: "0500000US32011",
        GEOID: "32011",
        NAME: "Eureka",
        LSAD: "06",
        ALAND: 10814958250,
        AWATER: 11082875
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.592855, 39.374927999074593],
            [-116.599986, 40.07230699907042],
            [-116.594214, 40.320250999069074],
            [-116.596815, 40.946459999065965],
            [-116.586996, 40.964775999065886],
            [-116.586786, 41.000397999065719],
            [-116.158155, 40.999911999065723],
            [-116.157834, 40.666388999067294],
            [-116.000897, 40.127375999070111],
            [-115.834969, 40.1278419990701],
            [-115.835301, 40.017370999070721],
            [-115.794864, 39.853103999071671],
            [-115.833101, 39.729411999072397],
            [-115.813476, 39.593520999073206],
            [-115.875886, 39.465717999074009],
            [-115.907071, 39.161847999075967],
            [-116.600946, 39.161462999075972],
            [-116.592855, 39.374927999074593]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "031",
        COUNTYNS: "01034223",
        AFFGEOID: "0500000US38031",
        GEOID: "38031",
        NAME: "Foster",
        LSAD: "06",
        ALAND: 1645810432,
        AWATER: 29450853
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.265846, 47.587617999060761],
            [-98.500085, 47.587227999060737],
            [-98.499246, 47.326537999060001],
            [-99.26628, 47.327193999059993],
            [-99.265846, 47.587617999060761]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "145",
        COUNTYNS: "00758527",
        AFFGEOID: "0500000US29145",
        GEOID: "29145",
        NAME: "Newton",
        LSAD: "06",
        ALAND: 1618104260,
        AWATER: 4717267
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.617964, 36.9989049990929],
            [-94.618102891102978, 37.056796389965278],
            [-94.059211, 37.048126999092453],
            [-94.06274, 36.931774999093506],
            [-94.068898, 36.747817999095183],
            [-94.618307, 36.766559999095023],
            [-94.617964, 36.9989049990929]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "640",
        COUNTYNS: "01498426",
        AFFGEOID: "0500000US51640",
        GEOID: "51640",
        NAME: "Galax",
        LSAD: "25",
        ALAND: 21333585,
        AWATER: 103777
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.949599, 36.662991999095986],
            [-80.944109, 36.67630299909586],
            [-80.932271, 36.671592999095907],
            [-80.920497, 36.678138999095843],
            [-80.914951, 36.694797999095684],
            [-80.90549, 36.686852999095763],
            [-80.891142, 36.695962999095684],
            [-80.877274, 36.692214999095718],
            [-80.888758, 36.675436999095858],
            [-80.914173, 36.6502459990961],
            [-80.916043, 36.629342999096309],
            [-80.931024, 36.625444999096345],
            [-80.921711, 36.643532999096173],
            [-80.947754, 36.648360999096141],
            [-80.949599, 36.662991999095986]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "083",
        COUNTYNS: "01008559",
        AFFGEOID: "0500000US37083",
        GEOID: "37083",
        NAME: "Halifax",
        LSAD: "06",
        ALAND: 1874459064,
        AWATER: 16933522
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.911963, 36.382445999098678],
            [-77.899524, 36.504258999097495],
            [-77.644367, 36.480227999097728],
            [-77.555275, 36.417963999098333],
            [-77.585698, 36.335509999099116],
            [-77.541108, 36.303263999099435],
            [-77.445886, 36.324887999099232],
            [-77.386595, 36.213547999100321],
            [-77.310018, 36.16885899910077],
            [-77.291519, 36.168341999100775],
            [-77.230275, 36.104998999101419],
            [-77.327569, 36.071360999101742],
            [-77.340038, 36.063997999101815],
            [-77.402614, 36.004965999102403],
            [-77.517646, 36.036774999102093],
            [-77.5407, 36.0960849991015],
            [-77.697956, 36.153149999100926],
            [-77.720742, 36.133883999101123],
            [-77.8864, 36.143993999101014],
            [-78.006551, 36.20263299910043],
            [-77.911963, 36.382445999098678]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "159",
        COUNTYNS: "00485043",
        AFFGEOID: "0500000US20159",
        GEOID: "20159",
        NAME: "Rice",
        LSAD: "06",
        ALAND: 1880935846,
        AWATER: 4482698
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.480377, 38.521840999080418],
            [-97.924269, 38.522754999080419],
            [-97.922136, 38.173712999083051],
            [-98.215924, 38.15874999908317],
            [-98.216032, 38.173497999083047],
            [-98.472794, 38.172756999083056],
            [-98.472455, 38.238646999082555],
            [-98.479841, 38.260789999082384],
            [-98.480377, 38.521840999080418]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "087",
        COUNTYNS: "00450368",
        AFFGEOID: "0500000US18087",
        GEOID: "18087",
        NAME: "LaGrange",
        LSAD: "06",
        ALAND: 983237213,
        AWATER: 18317909
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.659750237990906, 41.759236685109613],
            [-85.2921788113335, 41.759755273764412],
            [-85.232835, 41.759838999062623],
            [-85.196774200302002, 41.759870840865624],
            [-85.194084, 41.526436999063492],
            [-85.654747, 41.523346999063499],
            [-85.659750237990906, 41.759236685109613]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "085",
        COUNTYNS: "00308550",
        AFFGEOID: "0500000US12085",
        GEOID: "12085",
        NAME: "Martin",
        LSAD: "06",
        ALAND: 1408247995,
        AWATER: 542262212
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.677427, 27.121618999233807],
            [-80.677862, 27.205987999232189],
            [-80.28551, 27.206024999232195],
            [-80.284771, 27.263722999231085],
            [-80.198021352658699, 27.263008802903602],
            [-80.153375, 27.169307999232888],
            [-80.138605, 27.111516999234002],
            [-80.116772, 27.072396999234762],
            [-80.083077629755294, 26.970533999236721],
            [-80.142007, 26.956639999236994],
            [-80.580237, 26.957933999236968],
            [-80.885639, 26.95891899923695],
            [-80.677427, 27.121618999233807]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "129",
        COUNTYNS: "00424266",
        AFFGEOID: "0500000US17129",
        GEOID: "17129",
        NAME: "Menard",
        LSAD: "06",
        ALAND: 814389209,
        AWATER: 2641080
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.994724, 40.10837299907022],
            [-89.969618, 40.142357999070036],
            [-89.749196, 40.126851999070112],
            [-89.638262, 40.154869999069959],
            [-89.601604, 40.122431999070137],
            [-89.579127, 40.09232399907031],
            [-89.578289, 39.976126999070956],
            [-89.698259, 39.975308999070968],
            [-89.701864, 39.916786999071306],
            [-89.769236, 39.902415999071373],
            [-89.994506, 39.901924999071383],
            [-89.994724, 40.10837299907022]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "143",
        COUNTYNS: "01074084",
        AFFGEOID: "0500000US39143",
        GEOID: "39143",
        NAME: "Sandusky",
        LSAD: "06",
        ALAND: 1057906601,
        AWATER: 23958602
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.414626, 41.500227999063597],
            [-83.338196, 41.457507999063765],
            [-83.067459, 41.457168999063775],
            [-82.952224, 41.458238999063774],
            [-82.847804, 41.430515999063878],
            [-82.844565, 41.36239399906416],
            [-82.841475, 41.290022999064469],
            [-82.840087, 41.255336999064596],
            [-83.419843, 41.25400299906461],
            [-83.414626, 41.500227999063597]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "191",
        COUNTYNS: "00424296",
        AFFGEOID: "0500000US17191",
        GEOID: "17191",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 1848771790,
        AWATER: 4328965
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.698961, 38.474913999080783],
            [-88.698457, 38.606303999079813],
            [-88.564109, 38.607092999079818],
            [-88.253851, 38.59950199907987],
            [-88.148044, 38.597987999079891],
            [-88.147814, 38.569085999080087],
            [-88.1509, 38.256096999082416],
            [-88.370491, 38.255412999082424],
            [-88.702391, 38.25666099908242],
            [-88.698961, 38.474913999080783]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "011",
        COUNTYNS: "01383791",
        AFFGEOID: "0500000US48011",
        GEOID: "48011",
        NAME: "Armstrong",
        LSAD: "06",
        ALAND: 2354581675,
        AWATER: 12219587
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.622941, 35.183116999111107],
            [-101.086281, 35.182139999111108],
            [-101.090749, 34.748245999116001],
            [-101.471562, 34.747461999116013],
            [-101.629257, 34.747648999116009],
            [-101.622941, 35.183116999111107]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "131",
        COUNTYNS: "00659511",
        AFFGEOID: "0500000US27131",
        GEOID: "27131",
        NAME: "Rice",
        LSAD: "06",
        ALAND: 1284224027,
        AWATER: 52057387
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.524327, 44.543617999056849],
            [-93.357704, 44.544005999056843],
            [-93.281521, 44.54395699905686],
            [-93.281686, 44.471997999056889],
            [-93.039485, 44.47187099905689],
            [-93.041159, 44.196725999057094],
            [-93.04591, 44.196715999057083],
            [-93.162285, 44.196567999057095],
            [-93.406565, 44.196376999057094],
            [-93.525231, 44.196133999057089],
            [-93.524327, 44.543617999056849]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "009",
        COUNTYNS: "00933053",
        AFFGEOID: "0500000US35009",
        GEOID: "35009",
        NAME: "Curry",
        LSAD: "06",
        ALAND: 3640098102,
        AWATER: 8190594
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.738166, 34.604669999117654],
            [-103.706088, 34.605009999117655],
            [-103.705788, 34.692445999116636],
            [-103.495653, 34.691392999116651],
            [-103.495646, 34.778574999115641],
            [-103.390274, 34.778121999115655],
            [-103.389798, 34.865428999114656],
            [-103.284317, 34.864989999114663],
            [-103.284149, 34.954171999113662],
            [-103.042738818608015, 34.954142039475059],
            [-103.042769, 34.747360999116005],
            [-103.043072, 34.619781999117478],
            [-103.043946, 34.379554999120309],
            [-103.043850301172, 34.312749872628999],
            [-103.043835830164994, 34.30264799912122],
            [-103.45982, 34.303260999121221],
            [-103.740066, 34.30305599912122],
            [-103.738166, 34.604669999117654]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "115",
        COUNTYNS: "00758512",
        AFFGEOID: "0500000US29115",
        GEOID: "29115",
        NAME: "Linn",
        LSAD: "06",
        ALAND: 1594366100,
        AWATER: 14991073
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.361957, 39.967605999071004],
            [-93.361951, 40.033097999070627],
            [-92.856191, 40.03725299907061],
            [-92.847477, 40.037300999070609],
            [-92.85792, 39.699984999072576],
            [-93.082306, 39.706713999072534],
            [-93.266968, 39.703561999072548],
            [-93.364808, 39.703802999072558],
            [-93.361957, 39.967605999071004]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "125",
        COUNTYNS: "00659508",
        AFFGEOID: "0500000US27125",
        GEOID: "27125",
        NAME: "Red Lake",
        LSAD: "06",
        ALAND: 1119929328,
        AWATER: 227815
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.48255, 47.963594999061968],
            [-95.70984, 47.964329999061967],
            [-95.709619, 47.935804999061865],
            [-95.709148, 47.848593999061585],
            [-95.836827, 47.848218999061579],
            [-95.837177, 47.760893999061295],
            [-96.351727, 47.760299999061296],
            [-96.352708, 47.847327999061577],
            [-96.482092, 47.84742799906158],
            [-96.48255, 47.963594999061968]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "041",
        COUNTYNS: "01448034",
        AFFGEOID: "0500000US49041",
        GEOID: "49041",
        NAME: "Sevier",
        LSAD: "06",
        ALAND: 4948000005,
        AWATER: 20260738
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.515394, 38.572844999080061],
            [-112.447517, 38.681269999079284],
            [-112.309953, 38.676289999079316],
            [-112.218356, 38.727360999078947],
            [-112.224461, 38.86480299907798],
            [-112.133862, 38.959301999077333],
            [-112.065162, 38.959301999077333],
            [-112.014083, 39.045517999076743],
            [-111.853297, 39.032983999076826],
            [-111.29936, 39.032263999076832],
            [-111.305665, 38.510168999080506],
            [-111.752325, 38.510124999080517],
            [-111.766393, 38.502242999080586],
            [-112.125753, 38.512139999080503],
            [-112.518495, 38.510412999080522],
            [-112.515394, 38.572844999080061]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "047",
        COUNTYNS: "00353230",
        AFFGEOID: "0500000US13047",
        GEOID: "13047",
        NAME: "Catoosa",
        LSAD: "06",
        ALAND: 419999268,
        AWATER: 771158
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.265055021715796, 34.985077643855007],
            [-85.045183, 34.986882999113284],
            [-84.979854025231504, 34.987205613088875],
            [-85.061648, 34.819477999115186],
            [-85.144789, 34.767638999115775],
            [-85.201856, 34.77507999911569],
            [-85.20218, 34.854450999114789],
            [-85.264762, 34.854276999114781],
            [-85.265055021715796, 34.985077643855007]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "045",
        COUNTYNS: "01581082",
        AFFGEOID: "0500000US55045",
        GEOID: "55045",
        NAME: "Green",
        LSAD: "06",
        ALAND: 1512664667,
        AWATER: 1315749
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.838167, 42.857396999059283],
            [-89.378262, 42.856375999059281],
            [-89.369128, 42.845035999059313],
            [-89.365798607663493, 42.500025966011712],
            [-89.401416811040576, 42.50044193013391],
            [-89.493216, 42.501513999060215],
            [-89.83759488632559, 42.504910351851699],
            [-89.83841, 42.813798999059387],
            [-89.838167, 42.857396999059283]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "009",
        COUNTYNS: "00858641",
        AFFGEOID: "0500000US32009",
        GEOID: "32009",
        NAME: "Esmeralda",
        LSAD: "06",
        ALAND: 9277050041,
        AWATER: 18552011
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-118.351484, 37.893704999085251],
            [-117.691021, 38.473796999080776],
            [-117.165612, 38.0016919990844],
            [-117.166000564031009, 36.971207537683448],
            [-117.244917, 37.030243999092605],
            [-117.68061, 37.353398999089755],
            [-117.833504044143993, 37.464938765333301],
            [-118.022181, 37.602582999087645],
            [-118.427995325156999, 37.896224279995039],
            [-118.351484, 37.893704999085251]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "113",
        COUNTYNS: "01804537",
        AFFGEOID: "0500000US72113",
        GEOID: "72113",
        NAME: "Ponce",
        LSAD: "13",
        ALAND: 297688774,
        AWATER: 203748398
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.678087, 18.100699999442149],
            [-66.69407, 18.130533999441358],
            [-66.670494, 18.152830999440763],
            [-66.649467, 18.158856999440601],
            [-66.591005, 18.172478999440241],
            [-66.552766, 18.152056999440784],
            [-66.530417, 18.002643999444757],
            [-66.540537316236197, 17.975475887272982],
            [-66.583233, 17.961228999445861],
            [-66.645651, 17.980259999445352],
            [-66.664391, 17.968258999445677],
            [-66.697034446958497, 17.981973768042511],
            [-66.678087, 18.100699999442149]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "085",
        COUNTYNS: "00485007",
        AFFGEOID: "0500000US20085",
        GEOID: "20085",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 1699600373,
        AWATER: 4666514
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.035952, 39.566099999073387],
            [-95.789101, 39.56591599907339],
            [-95.788941, 39.653000999072859],
            [-95.564126, 39.652871999072858],
            [-95.570351, 39.419049999074296],
            [-95.589041, 39.404585999074385],
            [-95.589472, 39.216080999075608],
            [-96.035573, 39.216519999075601],
            [-96.035952, 39.566099999073387]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "197",
        COUNTYNS: "01008597",
        AFFGEOID: "0500000US37197",
        GEOID: "37197",
        NAME: "Yadkin",
        LSAD: "06",
        ALAND: 867492667,
        AWATER: 7236018
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.873205, 36.236304999100106],
            [-80.624245, 36.286821999099615],
            [-80.547885, 36.285418999099612],
            [-80.452322, 36.241418999100048],
            [-80.432241, 36.217994999100277],
            [-80.439104, 36.139948999101065],
            [-80.505532, 36.109154999101371],
            [-80.496283, 36.046544999101997],
            [-80.693197, 36.051259999101937],
            [-80.881591, 36.055717999101908],
            [-80.873205, 36.236304999100106]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "161",
        COUNTYNS: "00485044",
        AFFGEOID: "0500000US20161",
        GEOID: "20161",
        NAME: "Riley",
        LSAD: "06",
        ALAND: 1579167161,
        AWATER: 31952420
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.958719, 39.566400999073394],
            [-96.806544, 39.566422999073389],
            [-96.580362, 39.566331999073384],
            [-96.706722, 39.461532999074045],
            [-96.726667, 39.403600999074385],
            [-96.638605, 39.29282699907511],
            [-96.562342, 39.24134099907544],
            [-96.557892, 39.181973999075829],
            [-96.389046, 39.172877999075894],
            [-96.390796, 39.043256999076753],
            [-96.501166, 39.043665999076765],
            [-96.851409, 39.088175999076462],
            [-96.849879, 39.21901199907559],
            [-96.961693, 39.220075999075583],
            [-96.958719, 39.566400999073394]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "019",
        COUNTYNS: "01581069",
        AFFGEOID: "0500000US55019",
        GEOID: "55019",
        NAME: "Clark",
        LSAD: "06",
        ALAND: 3133093098,
        AWATER: 23242138
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.923362, 45.031142999056762],
            [-90.433612, 45.030976999056769],
            [-90.315038, 45.033828999056759],
            [-90.316253, 44.685153999056816],
            [-90.316055, 44.424501999056922],
            [-90.558746, 44.422209999056925],
            [-90.801918, 44.422441999056915],
            [-90.801525, 44.509680999056869],
            [-90.922889, 44.509839999056872],
            [-90.92235, 44.596292999056828],
            [-90.922247, 44.857308999056769],
            [-90.922669, 44.944427999056771],
            [-90.923362, 45.031142999056762]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "051",
        COUNTYNS: "00835847",
        AFFGEOID: "0500000US31051",
        GEOID: "31051",
        NAME: "Dixon",
        LSAD: "06",
        ALAND: 1233156423,
        AWATER: 17075472
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.017789, 42.351346999060638],
            [-97.015631112747201, 42.756525461547717],
            [-96.965679, 42.724531999059607],
            [-96.906797, 42.733799999059599],
            [-96.807370600098196, 42.70067898443267],
            [-96.801652, 42.698773999059675],
            [-96.778182, 42.662992999059774],
            [-96.697639, 42.659142999059782],
            [-96.7093, 42.603752999059935],
            [-96.658754, 42.566425999060023],
            [-96.627945456901699, 42.527096138201941],
            [-96.722231, 42.524651999060147],
            [-96.726618, 42.280416999060861],
            [-96.726661, 42.278004999060862],
            [-96.73778, 42.264103999060907],
            [-96.822497, 42.264291999060909],
            [-96.823077, 42.264291999060909],
            [-97.01773, 42.264452999060907],
            [-97.017789, 42.351346999060638]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "013",
        COUNTYNS: "00198122",
        AFFGEOID: "0500000US08013",
        GEOID: "08013",
        NAME: "Boulder",
        LSAD: "06",
        ALAND: 1881281634,
        AWATER: 36521204
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.693694, 39.942404999071158],
            [-105.638797, 40.038786999070609],
            [-105.653321, 40.260456999069383],
            [-105.15906, 40.261218999069378],
            [-105.055089, 40.261792999069378],
            [-105.055393, 40.138262999070051],
            [-105.05541, 40.017709999070725],
            [-105.052823, 40.000259999070813],
            [-105.071899, 39.950197999071115],
            [-105.109517, 39.956995999071069],
            [-105.16615, 39.91404699907131],
            [-105.339547, 39.913785999071315],
            [-105.397849, 39.912885999071321],
            [-105.398041, 39.934853999071201],
            [-105.675798, 39.932444999071208],
            [-105.693694, 39.942404999071158]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "495",
        COUNTYNS: "01384033",
        AFFGEOID: "0500000US48495",
        GEOID: "48495",
        NAME: "Winkler",
        LSAD: "06",
        ALAND: 2178481652,
        AWATER: 414369
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.326501, 32.000369999151594],
            [-103.064423, 32.000517999151597],
            [-103.064422401106, 32.08705099915035],
            [-102.844395, 32.087061999150343],
            [-102.799086, 32.085794999150366],
            [-102.798939, 31.65178299915668],
            [-103.327538, 31.651419999156687],
            [-103.326501, 32.000369999151594]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "263",
        COUNTYNS: "01383917",
        AFFGEOID: "0500000US48263",
        GEOID: "48263",
        NAME: "Kent",
        LSAD: "06",
        ALAND: 2337482011,
        AWATER: 1065195
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.038788, 33.397213999132497],
            [-100.517449, 33.397865999132492],
            [-100.519208, 32.962925999138214],
            [-100.65587, 32.963468999138215],
            [-100.990181, 32.965022999138185],
            [-101.03866, 32.970224999138125],
            [-101.038788, 33.397213999132497]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "115",
        COUNTYNS: "01213688",
        AFFGEOID: "0500000US42115",
        GEOID: "42115",
        NAME: "Susquehanna",
        LSAD: "06",
        ALAND: 2132850304,
        AWATER: 22385627
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.145518994782094, 41.998866462369769],
            [-76.10584, 41.998857999061777],
            [-75.870677, 41.998827999061774],
            [-75.553112539095096, 41.999295227535271],
            [-75.483149270958904, 41.999398163584466],
            [-75.462411, 41.641586999063058],
            [-75.719887, 41.64226299906305],
            [-76.090387, 41.644591999063046],
            [-76.115172, 41.651824999063003],
            [-76.145518994782094, 41.998866462369769]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "107",
        COUNTYNS: "00974151",
        AFFGEOID: "0500000US36107",
        GEOID: "36107",
        NAME: "Tioga",
        LSAD: "06",
        ALAND: 1343192581,
        AWATER: 11059913
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.563589, 42.152462999061264],
            [-76.538349, 42.281754999060851],
            [-76.416199, 42.262975999060906],
            [-76.415305, 42.318367999060754],
            [-76.250149, 42.296675999060817],
            [-76.253359, 42.40756799906049],
            [-76.130181, 42.410336999060476],
            [-76.080681, 42.258083999060929],
            [-76.108948, 42.074750999061514],
            [-76.104829, 42.031567999061657],
            [-76.10584, 41.998857999061777],
            [-76.145518994782094, 41.998866462369769],
            [-76.462155, 41.998933999061769],
            [-76.473030885808001, 41.999072380068782],
            [-76.557624143569385, 42.000148715403476],
            [-76.563589, 42.152462999061264]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "159",
        COUNTYNS: "00695800",
        AFFGEOID: "0500000US28159",
        GEOID: "28159",
        NAME: "Winston",
        LSAD: "06",
        ALAND: 1572709946,
        AWATER: 7405132
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.317855, 33.108870999136279],
            [-89.213921, 33.109401999136267],
            [-89.213422, 33.197542999135095],
            [-89.1089, 33.1980589991351],
            [-89.088255, 33.286002999133949],
            [-88.965963, 33.286107999133947],
            [-88.813053, 33.286423999133945],
            [-88.812782, 32.925972999138708],
            [-88.914413, 32.926599999138709],
            [-88.930505, 32.91923999913881],
            [-89.017723, 32.93132599913865],
            [-89.319163, 32.931653999138639],
            [-89.317855, 33.108870999136279]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "211",
        COUNTYNS: "00758558",
        AFFGEOID: "0500000US29211",
        GEOID: "29211",
        NAME: "Sullivan",
        LSAD: "06",
        ALAND: 1678264466,
        AWATER: 9123184
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.366935, 40.382998999068739],
            [-92.855313, 40.385893999068728],
            [-92.855629, 40.342735999068935],
            [-92.856191, 40.03725299907061],
            [-93.361951, 40.033097999070627],
            [-93.36661, 40.051560999070531],
            [-93.367214, 40.266313999069361],
            [-93.366935, 40.382998999068739]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "025",
        COUNTYNS: "01804492",
        AFFGEOID: "0500000US72025",
        GEOID: "72025",
        NAME: "Caguas",
        LSAD: "13",
        ALAND: 151782488,
        AWATER: 1201689
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.117774, 18.209948999439241],
            [-66.090193, 18.227599999438784],
            [-66.066103, 18.303320999436774],
            [-66.041535, 18.312343999436539],
            [-66.021747, 18.305033999436731],
            [-66.029894, 18.290869999437103],
            [-65.99765, 18.206991999439325],
            [-66.035651, 18.119203999441655],
            [-66.052555, 18.113068999441822],
            [-66.065968, 18.14841099944088],
            [-66.095099, 18.169076999440335],
            [-66.117774, 18.209948999439241]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "031",
        COUNTYNS: "00695740",
        AFFGEOID: "0500000US28031",
        GEOID: "28031",
        NAME: "Covington",
        LSAD: "06",
        ALAND: 1071727825,
        AWATER: 2937796
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.755103, 31.774653999154868],
            [-89.653025, 31.780652999154785],
            [-89.401227, 31.796862999154545],
            [-89.399178, 31.434028999159921],
            [-89.451616, 31.434028999159921],
            [-89.586819, 31.433687999159918],
            [-89.586919, 31.513443999158731],
            [-89.755614, 31.609750999157299],
            [-89.755103, 31.774653999154868]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "287",
        COUNTYNS: "00343238",
        AFFGEOID: "0500000US13287",
        GEOID: "13287",
        NAME: "Turner",
        LSAD: "06",
        ALAND: 739164624,
        AWATER: 11881239
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.802723, 31.803578999154446],
            [-83.612642, 31.804127999154442],
            [-83.612256, 31.854088999153717],
            [-83.54164, 31.853101999153729],
            [-83.480176, 31.847311999153803],
            [-83.45887, 31.793611999154596],
            [-83.453634, 31.75786099915512],
            [-83.452972, 31.708199999155848],
            [-83.500702, 31.593989999157539],
            [-83.648972, 31.596465999157498],
            [-83.649384, 31.567976999157921],
            [-83.718774, 31.62202799915713],
            [-83.796846, 31.622928999157118],
            [-83.802723, 31.803578999154446]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "133",
        COUNTYNS: "00835888",
        AFFGEOID: "0500000US31133",
        GEOID: "31133",
        NAME: "Pawnee",
        LSAD: "06",
        ALAND: 1116478185,
        AWATER: 4852361
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.463667, 40.262172999069378],
            [-96.066736, 40.262037999069378],
            [-96.010176, 40.261481999069375],
            [-96.010678817934505, 40.000704664842829],
            [-96.02409, 40.000718999070813],
            [-96.154365, 40.000494999070824],
            [-96.239172, 40.000690999070812],
            [-96.239207878411094, 40.000691041825213],
            [-96.463712076774783, 40.000958571625318],
            [-96.463667, 40.262172999069378]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "121",
        COUNTYNS: "00974158",
        AFFGEOID: "0500000US36121",
        GEOID: "36121",
        NAME: "Wyoming",
        LSAD: "06",
        ALAND: 1535218841,
        AWATER: 9158424
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.464381, 42.867460999059261],
            [-78.074171, 42.870187999059247],
            [-77.954964, 42.862753999059272],
            [-77.956334, 42.667321999059759],
            [-78.060617, 42.53281399906011],
            [-78.038261, 42.521521999060141],
            [-78.308839, 42.521216999060165],
            [-78.460716, 42.519193999060157],
            [-78.46394, 42.536331999060117],
            [-78.463233, 42.780460999059478],
            [-78.486249, 42.857329999059282],
            [-78.464381, 42.867460999059261]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "155",
        COUNTYNS: "00465266",
        AFFGEOID: "0500000US19155",
        GEOID: "19155",
        NAME: "Pottawattamie",
        LSAD: "06",
        ALAND: 2461903582,
        AWATER: 22261204
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.994020393011894, 41.506890640005068],
            [-95.497997, 41.506073999063567],
            [-95.154722, 41.505210999063578],
            [-95.155851, 41.159235999065025],
            [-95.384349, 41.160010999065015],
            [-95.861898068209385, 41.160302347277721],
            [-95.856788, 41.187097999064903],
            [-95.90969, 41.18439799906492],
            [-95.909908491254797, 41.191282849892779],
            [-95.911391, 41.237997999064675],
            [-95.890152, 41.278307999064509],
            [-95.92569, 41.322196999064325],
            [-95.92879, 41.370095999064134],
            [-95.927336242320095, 41.389987936523646],
            [-95.922529, 41.455765999063772],
            [-95.982962, 41.46977799906373],
            [-95.994020393011894, 41.506890640005068]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "105",
        COUNTYNS: "00559500",
        AFFGEOID: "0500000US22105",
        GEOID: "22105",
        NAME: "Tangipahoa",
        LSAD: "15",
        ALAND: 2049488093,
        AWATER: 136678798
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.567195400658491, 30.999945023566418],
            [-90.547574425233094, 30.99998210095811],
            [-90.347241080662101, 31.000360667147309],
            [-90.34671, 30.905426999167986],
            [-90.316871, 30.890931999168203],
            [-90.255648, 30.711904999170997],
            [-90.243172, 30.596014999172823],
            [-90.244305, 30.503591999174304],
            [-90.243649, 30.361580999176571],
            [-90.160636, 30.234336999178634],
            [-90.320464, 30.298984999177577],
            [-90.485826, 30.276991999177934],
            [-90.47376, 30.345245999176839],
            [-90.546118, 30.430282999175475],
            [-90.5669, 30.526129999173939],
            [-90.567165, 30.650022999171977],
            [-90.567171, 30.824805999169239],
            [-90.567195400658491, 30.999945023566418]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "027",
        COUNTYNS: "00581299",
        AFFGEOID: "0500000US23027",
        GEOID: "23027",
        NAME: "Waldo",
        LSAD: "06",
        ALAND: 1890577548,
        AWATER: 318053597
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.445498, 44.46383899905689],
            [-69.395987, 44.457077999056906],
            [-69.372419, 44.544584999056859],
            [-69.41684, 44.55107999905686],
            [-69.393123, 44.64037499905681],
            [-69.461251, 44.647397999056821],
            [-69.471712, 44.692898999056801],
            [-69.372065, 44.754631999056784],
            [-69.327407, 44.714441999056788],
            [-69.266785, 44.721927999056788],
            [-69.2052, 44.729585999056795],
            [-69.183732, 44.644022999056823],
            [-69.014167, 44.671793999056817],
            [-68.837529, 44.702124999056792],
            [-68.815793, 44.683753999056812],
            [-68.815824, 44.667252999056807],
            [-68.845722, 44.614839999056819],
            [-68.798337, 44.56826799905685],
            [-68.804615, 44.458410999056916],
            [-68.896384, 44.209934999057083],
            [-68.934976431761783, 44.202907472994369],
            [-68.95189, 44.218718999057074],
            [-69.021074545004595, 44.230435313179967],
            [-69.121622, 44.25564399905705],
            [-69.268882, 44.364652999056958],
            [-69.346616, 44.308336999056998],
            [-69.394361, 44.342933999056974],
            [-69.434992, 44.33186599905698],
            [-69.506382, 44.342869999056973],
            [-69.445498, 44.46383899905689]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "005",
        COUNTYNS: "01695314",
        AFFGEOID: "0500000US24005",
        GEOID: "24005",
        NAME: "Baltimore",
        LSAD: "06",
        ALAND: 1549830329,
        AWATER: 215909963
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.8931, 39.457401999074065],
            [-76.804304, 39.675881999072729],
            [-76.787097199757596, 39.721048145183744],
            [-76.715771, 39.721392999072442],
            [-76.569475117211596, 39.721458558177751],
            [-76.56671, 39.662688999072799],
            [-76.532174, 39.543976999073529],
            [-76.420896, 39.478413999073936],
            [-76.386697, 39.459704999074056],
            [-76.373493, 39.421417999074286],
            [-76.306579, 39.310106999075003],
            [-76.325418341956905, 39.272905085157937],
            [-76.349994, 39.248821999075396],
            [-76.395509, 39.231701999075511],
            [-76.425281, 39.205707999075685],
            [-76.463483, 39.205907999075677],
            [-76.498384, 39.204807999075683],
            [-76.504008590918104, 39.19928624820173],
            [-76.529486, 39.218407999075595],
            [-76.529517, 39.297055999075084],
            [-76.529793, 39.372057999074599],
            [-76.626193, 39.372016999074603],
            [-76.693693, 39.372104999074601],
            [-76.711191, 39.362797999074665],
            [-76.711186, 39.354382999074716],
            [-76.711054, 39.277888999075202],
            [-76.684649, 39.266457999075286],
            [-76.65986, 39.255508999075346],
            [-76.644212, 39.248923999075394],
            [-76.697085, 39.214091999075627],
            [-76.717258, 39.225478999075541],
            [-76.79379, 39.265862999075289],
            [-76.793626, 39.313654999074977],
            [-76.882274, 39.350184999074749],
            [-76.873378, 39.357149999074693],
            [-76.8873, 39.440501999074165],
            [-76.8931, 39.457401999074065]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "007",
        COUNTYNS: "01622946",
        AFFGEOID: "0500000US26007",
        GEOID: "26007",
        NAME: "Alpena",
        LSAD: "06",
        ALAND: 1481123848,
        AWATER: 2908714548
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.888482, 44.856634999056766],
            [-83.881223, 45.202070999056801],
            [-83.385211079256493, 45.207103992204807],
            [-83.315924, 45.139991999056789],
            [-83.265896, 45.026843999056759],
            [-83.340257, 45.041544999056761],
            [-83.399255, 45.070363999056774],
            [-83.442052, 45.051055999056764],
            [-83.435822, 45.000011999056774],
            [-83.438856, 44.940842999056763],
            [-83.352815, 44.886163999056777],
            [-83.320503, 44.880570999056765],
            [-83.316266108576599, 44.858591449617656],
            [-83.888484, 44.856457999056758],
            [-83.888482, 44.856634999056766]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "017",
        COUNTYNS: "01622951",
        AFFGEOID: "0500000US26017",
        GEOID: "26017",
        NAME: "Bay",
        LSAD: "06",
        ALAND: 1145557694,
        AWATER: 487990588
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.167318, 43.825901999057493],
            [-84.166377, 43.996900999057289],
            [-84.045409, 43.996973999057275],
            [-84.044843, 43.911145999057389],
            [-83.901329352181008, 43.908427049901583],
            [-83.910613, 43.893219999057408],
            [-83.929375, 43.777090999057556],
            [-83.94774, 43.735164999057609],
            [-83.909479, 43.672621999057696],
            [-83.817894, 43.673788999057706],
            [-83.731005, 43.623368999057767],
            [-83.699417091881102, 43.601637149314314],
            [-83.699484, 43.565822999057865],
            [-83.698816, 43.478956999057999],
            [-83.817678, 43.479051999057994],
            [-83.817228, 43.522344999057943],
            [-83.916091, 43.522628999057936],
            [-84.050987, 43.52406399905793],
            [-84.05, 43.567323999057869],
            [-84.168127, 43.568898999057858],
            [-84.167318, 43.825901999057493]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "119",
        COUNTYNS: "00659505",
        AFFGEOID: "0500000US27119",
        GEOID: "27119",
        NAME: "Polk",
        LSAD: "06",
        ALAND: 5105067510,
        AWATER: 69169913
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.1458449594749, 48.173223482053224],
            [-96.500843, 48.17410799906272],
            [-96.500901, 48.021655999062183],
            [-96.48255, 47.963594999061968],
            [-96.482092, 47.84742799906158],
            [-96.352708, 47.847327999061577],
            [-96.351727, 47.760299999061296],
            [-95.837177, 47.760893999061295],
            [-95.836827, 47.848218999061579],
            [-95.709148, 47.848593999061585],
            [-95.709619, 47.935804999061865],
            [-95.582155, 47.933965999061861],
            [-95.578839, 47.673676999061016],
            [-95.553392, 47.673609999061014],
            [-95.554248, 47.499892999060499],
            [-96.067621, 47.499135999060485],
            [-96.855959349053194, 47.499173228589505],
            [-96.854073, 47.572009999060704],
            [-96.882376, 47.649024999060927],
            [-96.893493301339888, 47.672126958504712],
            [-96.928506, 47.74488399906123],
            [-96.996364, 47.844397999061556],
            [-97.037354, 47.933278999061862],
            [-97.068987, 48.026266999062173],
            [-97.105616, 48.091361999062421],
            [-97.146745, 48.168555999062697],
            [-97.1458449594749, 48.173223482053224]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "227",
        COUNTYNS: "00758566",
        AFFGEOID: "0500000US29227",
        GEOID: "29227",
        NAME: "Worth",
        LSAD: "06",
        ALAND: 690500346,
        AWATER: 558541
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.632025917694804, 40.571759586467778],
            [-94.533878, 40.570738999067764],
            [-94.471207727123186, 40.570959457424777],
            [-94.310724, 40.571523999067772],
            [-94.232240710208288, 40.572014611194369],
            [-94.231565, 40.470356999068279],
            [-94.214278, 40.384253999068733],
            [-94.598487, 40.386713999068718],
            [-94.608429, 40.463657999068317],
            [-94.631889, 40.472512999068272],
            [-94.632025917694804, 40.571759586467778]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "033",
        COUNTYNS: "00835838",
        AFFGEOID: "0500000US31033",
        GEOID: "31033",
        NAME: "Cheyenne",
        LSAD: "06",
        ALAND: 3098367767,
        AWATER: 292549
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.376021, 41.047770999065513],
            [-103.37762, 41.394632999064022],
            [-103.369024, 41.437654999063852],
            [-102.636695, 41.439052999063847],
            [-102.609386, 41.39511499906402],
            [-102.610413, 41.221613999064743],
            [-102.610213, 41.048206999065513],
            [-102.621033024452004, 41.002223201681325],
            [-102.653464137269012, 41.002225323209309],
            [-103.076536, 41.002252999065711],
            [-103.382491651890007, 41.001926145686717],
            [-103.376021, 41.047770999065513]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "033",
        COUNTYNS: "00858615",
        AFFGEOID: "0500000US32033",
        GEOID: "32033",
        NAME: "White Pine",
        LSAD: "06",
        ALAND: 22985104192,
        AWATER: 54678877
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-115.875886, 39.465717999074009],
            [-115.813476, 39.593520999073206],
            [-115.833101, 39.729411999072397],
            [-115.794864, 39.853103999071671],
            [-115.835301, 40.017370999070721],
            [-115.834969, 40.1278419990701],
            [-115.263729, 40.122542999070134],
            [-114.251633, 40.116689999070168],
            [-114.046372817372998, 40.116935832082369],
            [-114.046386, 40.097895999070275],
            [-114.047268153076999, 39.906098112827564],
            [-114.047783, 39.794159999072015],
            [-114.047181412925994, 39.542743601303229],
            [-114.047079, 39.499942999073788],
            [-114.049104, 39.005508999077023],
            [-114.048054, 38.878692999077884],
            [-114.049436705930987, 38.6773624559589],
            [-115.000846, 38.677321999079304],
            [-115.907071, 39.161847999075967],
            [-115.875886, 39.465717999074009]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "079",
        COUNTYNS: "00974138",
        AFFGEOID: "0500000US36079",
        GEOID: "36079",
        NAME: "Putnam",
        LSAD: "06",
        ALAND: 596504204,
        AWATER: 41282800
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.981384, 41.324692999064318],
            [-73.947294, 41.39476499906403],
            [-73.981486, 41.438904999063844],
            [-73.933775, 41.488278999063652],
            [-73.673285, 41.514700999063542],
            [-73.579783, 41.526460999063509],
            [-73.529677714301286, 41.527161020191798],
            [-73.536969, 41.441093999063845],
            [-73.543147346265485, 41.376770667361505],
            [-73.543183207996293, 41.376397307580909],
            [-73.543306580691691, 41.375112863091807],
            [-73.544151186400597, 41.366319595308539],
            [-73.780007, 41.342491999064229],
            [-73.982584, 41.321692999064325],
            [-73.981384, 41.324692999064318]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "101",
        COUNTYNS: "00974148",
        AFFGEOID: "0500000US36101",
        GEOID: "36101",
        NAME: "Steuben",
        LSAD: "06",
        ALAND: 3601504767,
        AWATER: 35055663
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.731029, 42.403939999060484],
            [-77.722964, 42.471215999060306],
            [-77.720617, 42.535065999060109],
            [-77.661315, 42.535066999060106],
            [-77.659917, 42.580408999059991],
            [-77.490889, 42.577287999060005],
            [-77.455632, 42.576894999060009],
            [-77.366505, 42.576367999060011],
            [-77.143795, 42.576868999059997],
            [-77.107203, 42.483770999060255],
            [-77.099657, 42.272355999060885],
            [-76.965028, 42.278494999060868],
            [-76.965728759426696, 42.000783394108652],
            [-77.007635, 42.000847999061769],
            [-77.610020114476001, 41.999149853141375],
            [-77.749930925127288, 41.99875543939077],
            [-77.731029, 42.403939999060484]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "105",
        COUNTYNS: "01074065",
        AFFGEOID: "0500000US39105",
        GEOID: "39105",
        NAME: "Meigs",
        LSAD: "06",
        ALAND: 1113958478,
        AWATER: 7519824
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.306669, 39.205493999075678],
            [-82.268583, 39.203759999075686],
            [-81.960882, 39.188743999075783],
            [-81.752297036740103, 39.181034746481728],
            [-81.742953, 39.106577999076336],
            [-81.750266843426303, 39.104031192176151],
            [-81.807855, 39.083977999076495],
            [-81.793304, 39.040352999076781],
            [-81.775734, 38.98073699907718],
            [-81.827354, 38.945897999077424],
            [-81.89847, 38.929602999077538],
            [-81.941829, 38.993294999077101],
            [-82.007062, 39.029577999076857],
            [-82.041563, 39.017877999076923],
            [-82.089065, 38.975977999077216],
            [-82.098866650554299, 38.96087928357062],
            [-82.095465, 39.00277799907704],
            [-82.322874, 39.027673999076868],
            [-82.306669, 39.205493999075678]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "007",
        COUNTYNS: "01247981",
        AFFGEOID: "0500000US45007",
        GEOID: "45007",
        NAME: "Anderson",
        LSAD: "06",
        ALAND: 1853025715,
        AWATER: 108725240
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.976661, 34.494283999118949],
            [-82.840253, 34.623211999117437],
            [-82.788664, 34.67201699911687],
            [-82.486746, 34.819474999115187],
            [-82.45837, 34.779199999115647],
            [-82.453789, 34.625113999117417],
            [-82.417293, 34.55344999911825],
            [-82.313996, 34.484001999119073],
            [-82.735107988941593, 34.212614811365206],
            [-82.744982, 34.244860999121912],
            [-82.774628731012086, 34.288366817376087],
            [-82.780308, 34.296700999121292],
            [-82.82342, 34.358871999120552],
            [-82.841997, 34.399765999120071],
            [-82.873831, 34.471507999119218],
            [-82.925766, 34.481801999119106],
            [-82.991388153182299, 34.472980626354996],
            [-82.976661, 34.494283999118949]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "047",
        COUNTYNS: "00295736",
        AFFGEOID: "0500000US12047",
        GEOID: "12047",
        NAME: "Hamilton",
        LSAD: "06",
        ALAND: 1330689579,
        AWATER: 13659798
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.309347121595806, 30.634238765843833],
            [-83.13661885820359, 30.623885944065499],
            [-83.131431, 30.623574999172391],
            [-82.877311, 30.609015999172634],
            [-82.68952979847279, 30.597889289814596],
            [-82.726583, 30.559485999173408],
            [-82.647198, 30.394796999176041],
            [-82.689922, 30.333108999177028],
            [-82.738443, 30.325782999177147],
            [-82.788503, 30.324012999177178],
            [-82.794594, 30.337023999176964],
            [-83.002058, 30.429370999175493],
            [-83.082017, 30.442864999175271],
            [-83.170969, 30.385224999176202],
            [-83.221868, 30.423409999175576],
            [-83.272612, 30.62768699917233],
            [-83.309347121595806, 30.634238765843833]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "061",
        COUNTYNS: "00465219",
        AFFGEOID: "0500000US19061",
        GEOID: "19061",
        NAME: "Dubuque",
        LSAD: "06",
        ALAND: 1575488090,
        AWATER: 21138790
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.132766, 42.645843999059807],
            [-90.897415, 42.645713999059815],
            [-90.896962027158409, 42.674318055494055],
            [-90.852497, 42.664821999059768],
            [-90.743677, 42.645559999059827],
            [-90.700856, 42.626444999059878],
            [-90.672727, 42.576598999059996],
            [-90.642843, 42.508480999060197],
            [-90.646727, 42.471903999060281],
            [-90.590416, 42.447492999060366],
            [-90.565248, 42.43874199906039],
            [-90.517516, 42.4030189990605],
            [-90.484345672716287, 42.381597777252459],
            [-90.665029, 42.382154999060553],
            [-90.665551, 42.294698999060834],
            [-90.89802, 42.295235999060822],
            [-91.130079, 42.295760999060811],
            [-91.132766, 42.645843999059807]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "041",
        COUNTYNS: "00161546",
        AFFGEOID: "0500000US01041",
        GEOID: "01041",
        NAME: "Crenshaw",
        LSAD: "06",
        ALAND: 1576952826,
        AWATER: 5388561
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.499533, 31.655246999156631],
            [-86.448635, 31.655616999156624],
            [-86.448198, 31.964628999152104],
            [-86.405005, 31.963774999152125],
            [-86.406276, 32.050730999150865],
            [-86.303616, 32.051663999150854],
            [-86.302217, 31.965064999152112],
            [-86.191379, 31.966452999152086],
            [-86.199378, 31.790449999154639],
            [-86.148339, 31.790950999154646],
            [-86.147147, 31.663149999156513],
            [-86.179672, 31.616413999157199],
            [-86.145895, 31.617740999157174],
            [-86.14395, 31.537674999158376],
            [-86.194784, 31.529948999158481],
            [-86.193951, 31.440071999159827],
            [-86.277031, 31.45560199915959],
            [-86.280155, 31.528509999158498],
            [-86.398536, 31.45132499915966],
            [-86.39994, 31.527127999158523],
            [-86.499213, 31.525330999158548],
            [-86.499533, 31.655246999156631]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "379",
        COUNTYNS: "01383975",
        AFFGEOID: "0500000US48379",
        GEOID: "48379",
        NAME: "Rains",
        LSAD: "06",
        ALAND: 594280566,
        AWATER: 76080004
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.9453, 32.979876999138],
            [-95.862521, 32.979570999137998],
            [-95.676795, 32.979589999138],
            [-95.665389, 32.960433999138253],
            [-95.635017, 32.7203799991415],
            [-95.659432, 32.711440999141622],
            [-95.761537, 32.770313999140818],
            [-95.829581, 32.76696699914087],
            [-95.934433, 32.837216999139898],
            [-95.987259, 32.876414999139385],
            [-95.9453, 32.979876999138]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "093",
        COUNTYNS: "01383832",
        AFFGEOID: "0500000US48093",
        GEOID: "48093",
        NAME: "Comanche",
        LSAD: "06",
        ALAND: 2428770456,
        AWATER: 25718009
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.924401, 32.078017999150497],
            [-98.551158, 32.261435999147864],
            [-98.327499, 31.941260999152448],
            [-98.271184, 31.969948999152027],
            [-98.250318, 31.979002999151906],
            [-98.208386, 31.917510999152793],
            [-98.156568, 31.841712999153891],
            [-98.463736, 31.683988999156202],
            [-98.492802, 31.723607999155615],
            [-98.668441, 31.700513999155959],
            [-98.924401, 32.078017999150497]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "193",
        COUNTYNS: "00346957",
        AFFGEOID: "0500000US13193",
        GEOID: "13193",
        NAME: "Macon",
        LSAD: "06",
        ALAND: 1037653519,
        AWATER: 13860062
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.254613, 32.37205299914632],
            [-84.197912, 32.400851999145907],
            [-84.111226, 32.514123999144338],
            [-84.00849, 32.521768999144228],
            [-84.018168, 32.506497999144443],
            [-83.846676, 32.468513999144967],
            [-83.836994, 32.388792999146084],
            [-83.85654, 32.382398999146162],
            [-83.848379, 32.290969999147457],
            [-83.894777, 32.292174999147441],
            [-84.027853, 32.171082999149156],
            [-84.134736, 32.185391999148955],
            [-84.181657, 32.22970299914833],
            [-84.219211, 32.295056999147398],
            [-84.255912, 32.296155999147381],
            [-84.254613, 32.37205299914632]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "437",
        COUNTYNS: "01384004",
        AFFGEOID: "0500000US48437",
        GEOID: "48437",
        NAME: "Swisher",
        LSAD: "06",
        ALAND: 2305502706,
        AWATER: 27277987
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.998493, 34.748189999116001],
            [-101.629257, 34.747648999116009],
            [-101.471562, 34.747461999116013],
            [-101.47158, 34.312289999121113],
            [-101.564856, 34.312492999121098],
            [-101.99802, 34.313038999121098],
            [-101.998493, 34.748189999116001]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "225",
        COUNTYNS: "00343153",
        AFFGEOID: "0500000US13225",
        GEOID: "13225",
        NAME: "Peach",
        LSAD: "06",
        ALAND: 389188297,
        AWATER: 2678495
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.00849, 32.521768999144228],
            [-84.003363, 32.529926999144124],
            [-83.766787, 32.692621999141878],
            [-83.70109, 32.691597999141891],
            [-83.723139, 32.521121999144235],
            [-83.846676, 32.468513999144967],
            [-84.018168, 32.506497999144443],
            [-84.00849, 32.521768999144228]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "510",
        COUNTYNS: "00767557",
        AFFGEOID: "0500000US29510",
        GEOID: "29510",
        NAME: "St. Louis",
        LSAD: "25",
        ALAND: 160462911,
        AWATER: 10663382
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.318212, 38.600016999079863],
            [-90.251745, 38.718886999078997],
            [-90.166594640015589, 38.772450138383732],
            [-90.20991, 38.726049999078953],
            [-90.19521, 38.687549999079238],
            [-90.18152438153129, 38.660372884389915],
            [-90.18111, 38.659549999079438],
            [-90.18451, 38.611550999079775],
            [-90.248913, 38.544751999080262],
            [-90.255294887669393, 38.530877710901365],
            [-90.313582, 38.57876799908],
            [-90.318212, 38.600016999079863]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "007",
        COUNTYNS: "00484973",
        AFFGEOID: "0500000US20007",
        GEOID: "20007",
        NAME: "Barber",
        LSAD: "06",
        ALAND: 2937248475,
        AWATER: 5403867
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.012598, 37.470420999088766],
            [-98.464663, 37.471012999088757],
            [-98.464951, 37.384085999089486],
            [-98.349804, 37.384055999089504],
            [-98.347148779744685, 36.9979692077425],
            [-98.354073, 36.997960999092903],
            [-98.544661973348894, 36.998524239493499],
            [-98.791936, 36.999254999092898],
            [-99.000301053884186, 36.999358097237291],
            [-99.001378, 37.375021999089569],
            [-99.011499, 37.384262999089501],
            [-99.012598, 37.470420999088766]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "197",
        COUNTYNS: "00465287",
        AFFGEOID: "0500000US19197",
        GEOID: "19197",
        NAME: "Wright",
        LSAD: "06",
        ALAND: 1503286935,
        AWATER: 4703964
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.971714, 42.644706999059814],
            [-93.971238, 42.907761999059161],
            [-93.498617, 42.908511999059151],
            [-93.499485, 42.557699999060063],
            [-93.971583, 42.558138999060048],
            [-93.971714, 42.644706999059814]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "145",
        COUNTYNS: "01674225",
        AFFGEOID: "0500000US51145",
        GEOID: "51145",
        NAME: "Powhatan",
        LSAD: "06",
        ALAND: 673915707,
        AWATER: 5449745
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.089466, 37.63941499908735],
            [-78.072174, 37.657384999087192],
            [-77.982236, 37.62427899908748],
            [-77.918866, 37.691703999086911],
            [-77.814902, 37.606529999087613],
            [-77.655399, 37.563985999087961],
            [-77.809003, 37.426233999089135],
            [-77.855148, 37.418362999089211],
            [-77.899761, 37.475421999088724],
            [-77.999575, 37.498051999088524],
            [-78.132048, 37.454685999088888],
            [-78.089466, 37.63941499908735]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "087",
        COUNTYNS: "00695767",
        AFFGEOID: "0500000US28087",
        GEOID: "28087",
        NAME: "Lowndes",
        LSAD: "06",
        ALAND: 1309077220,
        AWATER: 28560499
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.67125, 33.506167999131101],
            [-88.472714, 33.524450999130863],
            [-88.513917, 33.650208999129262],
            [-88.40656, 33.673341999128965],
            [-88.325119, 33.745590999128062],
            [-88.248387473909489, 33.74490759400976],
            [-88.254445, 33.698778999128649],
            [-88.274516470783695, 33.534001476915932],
            [-88.304442813510377, 33.288319999133918],
            [-88.669076, 33.286921999133938],
            [-88.67125, 33.506167999131101]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "045",
        COUNTYNS: "01035621",
        AFFGEOID: "0500000US38045",
        GEOID: "38045",
        NAME: "LaMoure",
        LSAD: "06",
        ALAND: 2967976309,
        AWATER: 12813378
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.036625, 46.630210999058377],
            [-98.439056, 46.631119999058377],
            [-98.033862, 46.630726999058382],
            [-98.034573, 46.282795999057768],
            [-99.003118, 46.282897999057774],
            [-99.037367, 46.283150999057781],
            [-99.036625, 46.630210999058377]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "017",
        COUNTYNS: "00395158",
        AFFGEOID: "0500000US16017",
        GEOID: "16017",
        NAME: "Bonner",
        LSAD: "06",
        ALAND: 4488460265,
        AWATER: 479480478
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.041214453461009, 48.045465102865059],
            [-117.041107, 48.124903999062539],
            [-117.035289, 48.422731999063672],
            [-117.033671, 48.656901999064637],
            [-117.032939182117019, 48.84666792026696],
            [-116.785174, 48.847401999065454],
            [-116.784878, 48.500471999063976],
            [-116.049156520648992, 48.502042480822098],
            [-116.049155, 48.481246999063906],
            [-116.048948, 48.309846999063232],
            [-116.048929247775988, 48.216127946989978],
            [-116.048911, 48.124929999062552],
            [-116.049153, 47.999922999062086],
            [-116.038565829872979, 47.984634255503742],
            [-116.233113, 48.068889999062336],
            [-116.282236, 48.059385999062322],
            [-116.329601, 48.017721999062154],
            [-116.329538, 47.890392999061717],
            [-116.505104, 47.890141999061719],
            [-116.504329, 47.991385999062068],
            [-116.634114, 47.977072999062017],
            [-117.041306446459004, 47.977455919088811],
            [-117.041214453461009, 48.045465102865059]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "163",
        COUNTYNS: "01623022",
        AFFGEOID: "0500000US26163",
        GEOID: "26163",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 1585060122,
        AWATER: 157341390
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.551907, 42.435165999060395],
            [-83.433734, 42.438939999060388],
            [-83.316825, 42.442032999060388],
            [-83.203558, 42.444747999060368],
            [-83.151986, 42.44600699906038],
            [-83.083393, 42.447152999060364],
            [-82.87033226919759, 42.451009583265453],
            [-82.870347, 42.450887999060349],
            [-82.92397, 42.352067999060644],
            [-82.949672098645593, 42.344264220281161],
            [-82.988619, 42.332438999060706],
            [-83.096521, 42.290137999060832],
            [-83.097858496256109, 42.286011364309537],
            [-83.115682841402489, 42.231017145251919],
            [-83.133923, 42.174739999061188],
            [-83.133511, 42.088142999061475],
            [-83.185526, 42.05224299906159],
            [-83.194952311689505, 42.033107925872663],
            [-83.284155, 42.089951999061462],
            [-83.539396, 42.085597999061477],
            [-83.542102, 42.174343999061186],
            [-83.543728, 42.262461999060911],
            [-83.547548, 42.349216999060651],
            [-83.551907, 42.435165999060395]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "017",
        COUNTYNS: "01383794",
        AFFGEOID: "0500000US48017",
        GEOID: "48017",
        NAME: "Bailey",
        LSAD: "06",
        ALAND: 2141847193,
        AWATER: 1775275
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.047346, 33.824674999127076],
            [-103.043516, 34.079381999123918],
            [-103.043563920791001, 34.112834388878916],
            [-103.043835830164994, 34.30264799912122],
            [-103.043850301172, 34.312749872628999],
            [-102.61515, 34.312890999121109],
            [-102.615447, 33.825120999127058],
            [-103.047346018167005, 33.824674122278772],
            [-103.047346, 33.824674999127076]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "117",
        COUNTYNS: "00485023",
        AFFGEOID: "0500000US20117",
        GEOID: "20117",
        NAME: "Marshall",
        LSAD: "06",
        ALAND: 2331432992,
        AWATER: 12743798
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.806201, 39.827537999071822],
            [-96.805768, 40.001368454166219],
            [-96.469945, 40.000965999070822],
            [-96.463712076774783, 40.000958571625318],
            [-96.239207878411094, 40.000691041825213],
            [-96.2395, 39.914344999071318],
            [-96.238969, 39.56621999907339],
            [-96.580362, 39.566331999073384],
            [-96.806544, 39.566422999073389],
            [-96.806201, 39.827537999071822]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "001",
        COUNTYNS: "00558389",
        AFFGEOID: "0500000US22001",
        GEOID: "22001",
        NAME: "Acadia",
        LSAD: "15",
        ALAND: 1696777754,
        AWATER: 6021519
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.631987, 30.481146999174658],
            [-92.493259, 30.480498999174671],
            [-92.244795, 30.480192999174669],
            [-92.176059, 30.400470999175944],
            [-92.175775, 30.349571999176771],
            [-92.158606, 30.349735999176758],
            [-92.158554, 30.342444999176884],
            [-92.142184, 30.298816999177586],
            [-92.2479, 30.211038999179006],
            [-92.284675, 30.147442999180033],
            [-92.423166, 30.049319999181638],
            [-92.534638, 30.107851999180678],
            [-92.625484, 30.092329999180929],
            [-92.591129, 30.182364999179466],
            [-92.626612, 30.230271999178694],
            [-92.630975, 30.374584999176363],
            [-92.594586, 30.420338999175634],
            [-92.631987, 30.481146999174658]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "101",
        COUNTYNS: "01719566",
        AFFGEOID: "0500000US30101",
        GEOID: "30101",
        NAME: "Toole",
        LSAD: "06",
        ALAND: 4959868816,
        AWATER: 78026225
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.193410195914012, 48.998893775699848],
            [-112.143769, 48.998916999066147],
            [-111.854088, 48.998066999066154],
            [-111.500812, 48.996962999066149],
            [-111.269862000829008, 48.997229785877742],
            [-111.275306, 48.21953099906289],
            [-111.409018, 48.219541999062884],
            [-111.665599, 48.219548999062901],
            [-111.665756, 48.349455999063387],
            [-111.795855, 48.349811999063384],
            [-111.79591, 48.393080999063557],
            [-111.990889, 48.394264999063559],
            [-111.990944, 48.438545999063734],
            [-112.184784, 48.477577999063897],
            [-112.193410195914012, 48.998893775699848]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "097",
        COUNTYNS: "00485013",
        AFFGEOID: "0500000US20097",
        GEOID: "20097",
        NAME: "Kiowa",
        LSAD: "06",
        ALAND: 1871627778,
        AWATER: 596764
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.55958, 37.734887999086553],
            [-99.01355, 37.732969999086563],
            [-99.012598, 37.470420999088766],
            [-99.011499, 37.384262999089501],
            [-99.543189, 37.381123999089517],
            [-99.555495, 37.391587999089438],
            [-99.556119, 37.467721999088781],
            [-99.55958, 37.734887999086553]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "111",
        COUNTYNS: "01581115",
        AFFGEOID: "0500000US55111",
        GEOID: "55111",
        NAME: "Sauk",
        LSAD: "06",
        ALAND: 2153665639,
        AWATER: 45713371
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.312404, 43.640987999057749],
            [-89.785809, 43.641048999057745],
            [-89.732238, 43.571825999057857],
            [-89.599357, 43.558040999057859],
            [-89.60073, 43.380665999058181],
            [-89.677613, 43.361196999058215],
            [-89.720463, 43.293083999058354],
            [-89.714988, 43.276972999058387],
            [-89.838135, 43.206056999058518],
            [-90.000123, 43.194623999058528],
            [-90.05866, 43.145290999058638],
            [-90.193814, 43.164463999058597],
            [-90.191938, 43.380082999058182],
            [-90.191964, 43.554995999057873],
            [-90.311069, 43.553990999057874],
            [-90.312404, 43.640987999057749]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "151",
        COUNTYNS: "00465264",
        AFFGEOID: "0500000US19151",
        GEOID: "19151",
        NAME: "Pocahontas",
        LSAD: "06",
        ALAND: 1495047142,
        AWATER: 3666530
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.91389, 42.909699999059157],
            [-94.442954, 42.908072999059165],
            [-94.443083, 42.645163999059825],
            [-94.443024, 42.558842999060047],
            [-94.914485, 42.560308999060048],
            [-94.91389, 42.909699999059157]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "087",
        COUNTYNS: "01101834",
        AFFGEOID: "0500000US40087",
        GEOID: "40087",
        NAME: "McClain",
        LSAD: "06",
        ALAND: 1478114523,
        AWATER: 24734523
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.671529, 35.337507999109427],
            [-97.556987, 35.288439999109947],
            [-97.54146, 35.214164999110771],
            [-97.407082, 35.138143999111598],
            [-97.354192, 35.07665599911229],
            [-97.333833, 34.958947999113605],
            [-97.142349, 34.928175999113954],
            [-97.01593, 34.90734799911418],
            [-96.930586, 34.964363999113537],
            [-96.930806, 34.875080999114552],
            [-96.932471, 34.854454999114786],
            [-97.668099, 34.855163999114765],
            [-97.670629, 34.872959999114578],
            [-97.671595, 35.335933999109429],
            [-97.671529, 35.337507999109427]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "101",
        COUNTYNS: "00424252",
        AFFGEOID: "0500000US17101",
        GEOID: "17101",
        NAME: "Lawrence",
        LSAD: "06",
        ALAND: 963936864,
        AWATER: 5077778
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.908113, 38.850106999078093],
            [-87.535257050808497, 38.852491464045471],
            [-87.521681, 38.826575999078244],
            [-87.498948, 38.757773999078729],
            [-87.545538, 38.677612999079294],
            [-87.62012, 38.639488999079575],
            [-87.637752, 38.588511999079948],
            [-87.648356763074489, 38.566628715137696],
            [-87.912286, 38.570103999080075],
            [-87.908113, 38.850106999078093]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "375",
        COUNTYNS: "01383973",
        AFFGEOID: "0500000US48375",
        GEOID: "48375",
        NAME: "Potter",
        LSAD: "06",
        ALAND: 2352751873,
        AWATER: 35190086
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.162752, 35.620034999106387],
            [-101.6228, 35.620195999106393],
            [-101.622941, 35.183116999111107],
            [-102.167471, 35.183232999111098],
            [-102.162752, 35.620034999106387]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "121",
        COUNTYNS: "01639775",
        AFFGEOID: "0500000US47121",
        GEOID: "47121",
        NAME: "Meigs",
        LSAD: "06",
        ALAND: 505397665,
        AWATER: 55976974
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.016343, 35.409287999108635],
            [-84.968953, 35.466673999108025],
            [-84.912148, 35.442670999108273],
            [-84.859949, 35.497071999107689],
            [-84.873969, 35.550216999107121],
            [-84.794904, 35.552549999107107],
            [-84.781804, 35.673907999105815],
            [-84.72324, 35.689669999105654],
            [-84.723853, 35.752895999105014],
            [-84.619031, 35.707879999105472],
            [-84.619865, 35.644650999106133],
            [-84.805544, 35.448570999108213],
            [-84.860164, 35.350073999109277],
            [-84.879507, 35.353230999109243],
            [-84.946339, 35.287716999109954],
            [-85.031314, 35.379476999108959],
            [-85.016343, 35.409287999108635]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "095",
        COUNTYNS: "00516894",
        AFFGEOID: "0500000US21095",
        GEOID: "21095",
        NAME: "Harlan",
        LSAD: "06",
        ALAND: 1206490776,
        AWATER: 6014027
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.489813, 36.895412999093828],
            [-83.408614, 36.890217999093885],
            [-83.399656, 36.92095499909361],
            [-83.26002, 36.968950999093153],
            [-83.182104, 37.021114999092688],
            [-83.12209, 37.000996999092877],
            [-83.153401, 36.956590999093272],
            [-82.939466, 37.015519999092739],
            [-82.869183, 36.974181999093126],
            [-82.865192, 36.920922999093591],
            [-82.883754212976385, 36.897130135748021],
            [-82.895445, 36.88214499909396],
            [-83.012587, 36.847288999094268],
            [-83.07559, 36.850588999094242],
            [-83.114693, 36.796087999094745],
            [-83.136395, 36.743087999095245],
            [-83.236399, 36.726886999095399],
            [-83.386099, 36.686588999095768],
            [-83.436508, 36.666184999095954],
            [-83.460954647463808, 36.666131035369268],
            [-83.500891, 36.733253999095339],
            [-83.489813, 36.895412999093828]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "047",
        COUNTYNS: "01668802",
        AFFGEOID: "0500000US24047",
        GEOID: "24047",
        NAME: "Worcester",
        LSAD: "06",
        ALAND: 1212832597,
        AWATER: 586845178
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.661827, 38.041852999084085],
            [-75.54087, 38.089479999083707],
            [-75.545898, 38.178739999083014],
            [-75.612764, 38.278625999082237],
            [-75.363582, 38.291305999082155],
            [-75.307011, 38.354759999081665],
            [-75.341287320348187, 38.45243652503644],
            [-75.185455, 38.451011999080954],
            [-75.048939, 38.45126299908096],
            [-75.085518, 38.324269999081899],
            [-75.102947, 38.311524999081996],
            [-75.143229, 38.220474999082697],
            [-75.177394, 38.130013999083388],
            [-75.193796, 38.096012999083641],
            [-75.242266, 38.027208999084195],
            [-75.624341, 37.994210999084459],
            [-75.661827, 38.041852999084085]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "497",
        COUNTYNS: "01384034",
        AFFGEOID: "0500000US48497",
        GEOID: "48497",
        NAME: "Wise",
        LSAD: "06",
        ALAND: 2342363525,
        AWATER: 47038989
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.918189, 33.433872999132021],
            [-97.487065, 33.433680999132029],
            [-97.406768, 33.433109999132036],
            [-97.383095, 33.430449999132072],
            [-97.39267, 33.183508999135299],
            [-97.39848, 32.990838999137843],
            [-97.544181, 32.994176999137807],
            [-97.679565, 32.996372999137776],
            [-97.921642, 33.001283999137705],
            [-97.918189, 33.433872999132021]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "183",
        COUNTYNS: "01690257",
        AFFGEOID: "0500000US51183",
        GEOID: "51183",
        NAME: "Sussex",
        LSAD: "06",
        ALAND: 1269664389,
        AWATER: 6755317
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.619029, 36.877843999093983],
            [-77.398498, 36.99298499909294],
            [-77.187038, 37.098932999092],
            [-77.155415, 37.112033999091885],
            [-76.965139, 37.054307999092394],
            [-76.953546, 36.944449999093386],
            [-77.430127, 36.707657999095574],
            [-77.489999, 36.721008999095439],
            [-77.458678, 36.862962999094137],
            [-77.51036, 36.84508199909429],
            [-77.619029, 36.877843999093983]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "097",
        COUNTYNS: "01034221",
        AFFGEOID: "0500000US38097",
        GEOID: "38097",
        NAME: "Traill",
        LSAD: "06",
        ALAND: 2232438250,
        AWATER: 1462184
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.472745, 47.672050999061014],
            [-96.893493301339888, 47.672126958504712],
            [-96.882376, 47.649024999060927],
            [-96.854073, 47.572009999060704],
            [-96.855959349053194, 47.499173228589505],
            [-96.85748, 47.440456999060316],
            [-96.84022, 47.276980999059866],
            [-96.836009594541892, 47.237981874546868],
            [-97.451512, 47.239060999059767],
            [-97.474337, 47.324775999060009],
            [-97.472745, 47.672050999061014]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "189",
        COUNTYNS: "00516941",
        AFFGEOID: "0500000US21189",
        GEOID: "21189",
        NAME: "Owsley",
        LSAD: "06",
        ALAND: 511282856,
        AWATER: 2285944
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.885262, 37.517687999088359],
            [-83.742984, 37.494252999088552],
            [-83.635706, 37.54001099908816],
            [-83.579186, 37.506248999088456],
            [-83.534281, 37.417408999089211],
            [-83.547628, 37.334417999089929],
            [-83.551777, 37.287217999090338],
            [-83.526907, 37.258528999090586],
            [-83.653303, 37.358074999089716],
            [-83.784975, 37.34871699908981],
            [-83.883953, 37.477334999088697],
            [-83.885262, 37.517687999088359]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "065",
        COUNTYNS: "00424234",
        AFFGEOID: "0500000US17065",
        GEOID: "17065",
        NAME: "Hamilton",
        LSAD: "06",
        ALAND: 1125780248,
        AWATER: 3172680
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.704606, 38.125194999083433],
            [-88.702391, 38.25666099908242],
            [-88.370491, 38.255412999082424],
            [-88.37452, 37.908431999085138],
            [-88.37453, 37.907677999085145],
            [-88.706622, 37.906796999085159],
            [-88.704606, 38.125194999083433]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "025",
        COUNTYNS: "01383798",
        AFFGEOID: "0500000US48025",
        GEOID: "48025",
        NAME: "Bee",
        LSAD: "06",
        ALAND: 2279809504,
        AWATER: 246409
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.005252, 28.69023899920483],
            [-97.915106, 28.719647999204302],
            [-97.77853, 28.668026999205217],
            [-97.696572, 28.541536999207469],
            [-97.558496, 28.514053999207967],
            [-97.551369, 28.450440999209103],
            [-97.375579, 28.388683999210205],
            [-97.541071, 28.164668999214278],
            [-97.568482, 28.129845999214901],
            [-97.817716, 28.176847999214051],
            [-97.808774, 28.183414999213927],
            [-98.089764, 28.662978999205315],
            [-98.005252, 28.69023899920483]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "021",
        COUNTYNS: "01605075",
        AFFGEOID: "0500000US56021",
        GEOID: "56021",
        NAME: "Laramie",
        LSAD: "06",
        ALAND: 6956482527,
        AWATER: 4273816
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.278236, 41.656654999063001],
            [-104.653338, 41.65300699906301],
            [-104.652992, 41.565013999063346],
            [-104.052634571632993, 41.56427577863704],
            [-104.052287, 41.393306999064038],
            [-104.052287134121002, 41.393213999257433],
            [-104.052453, 41.278201999064514],
            [-104.053142, 41.114456999065219],
            [-104.053249, 41.001405999065717],
            [-104.497058, 41.001804999065712],
            [-104.855273, 40.998047999065733],
            [-104.943371, 40.998074102795542],
            [-105.276859994035007, 40.99817291669163],
            [-105.278236, 41.656654999063001]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "019",
        COUNTYNS: "01609191",
        AFFGEOID: "0500000US56019",
        GEOID: "56019",
        NAME: "Johnson",
        LSAD: "06",
        ALAND: 10759208728,
        AWATER: 52947545
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.369621, 44.559836999056856],
            [-106.428897, 44.556776999056851],
            [-106.400677, 44.561928999056846],
            [-106.009676, 44.563980999056845],
            [-106.014477, 44.520979999056863],
            [-106.009201, 43.821578999057493],
            [-106.017366, 43.494968999057988],
            [-106.078068, 43.494470999057981],
            [-107.090251, 43.492389999057984],
            [-107.110734, 43.500285999057972],
            [-107.113655, 43.816091999057498],
            [-107.128374, 44.154124999057132],
            [-107.146808, 44.165665999057111],
            [-107.169228, 44.393779999056939],
            [-107.30058, 44.459080999056901],
            [-107.369621, 44.559836999056856]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "053",
        COUNTYNS: "01383812",
        AFFGEOID: "0500000US48053",
        GEOID: "48053",
        NAME: "Burnet",
        LSAD: "06",
        ALAND: 2575134235,
        AWATER: 69922923
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.445782, 30.92143899916773],
            [-98.418746, 30.926259999167659],
            [-98.439687, 31.029536999166069],
            [-97.911684, 31.034918999165981],
            [-97.840365, 30.929317999167608],
            [-97.828512, 30.906187999167972],
            [-97.96286, 30.785640999169846],
            [-98.049886, 30.62415499917239],
            [-98.12342, 30.486481999174568],
            [-98.125556, 30.426185999175527],
            [-98.351041, 30.486095999174584],
            [-98.351983, 30.558392999173432],
            [-98.411148, 30.574643999173162],
            [-98.438995, 30.67328499917161],
            [-98.371905, 30.740484999170555],
            [-98.432098, 30.786309999169845],
            [-98.376877, 30.834889999169079],
            [-98.457382, 30.87889699916839],
            [-98.445782, 30.92143899916773]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "063",
        COUNTYNS: "00450358",
        AFFGEOID: "0500000US18063",
        GEOID: "18063",
        NAME: "Hendricks",
        LSAD: "06",
        ALAND: 1053849206,
        AWATER: 4880843
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.695071, 39.922769999071257],
            [-86.355262, 39.924040999071252],
            [-86.326293, 39.924028999071247],
            [-86.325373, 39.866175999071601],
            [-86.327291, 39.822491999071843],
            [-86.326341, 39.632176999072982],
            [-86.335904, 39.631984999072991],
            [-86.465605, 39.600768999073175],
            [-86.65665, 39.601394999073179],
            [-86.643014, 39.618028999073076],
            [-86.687406, 39.630436999072998],
            [-86.695127, 39.8648449990716],
            [-86.695071, 39.922769999071257]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "199",
        COUNTYNS: "01008598",
        AFFGEOID: "0500000US37199",
        GEOID: "37199",
        NAME: "Yancey",
        LSAD: "06",
        ALAND: 809602887,
        AWATER: 1531168
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.508007659722495, 35.982013204544138],
            [-82.464558, 36.006507999102396],
            [-82.416937594673186, 36.072970009810227],
            [-82.351672, 36.084333999101609],
            [-82.253966, 36.005320999102402],
            [-82.197834, 36.015331999102308],
            [-82.133484, 35.82330899910427],
            [-82.193402, 35.735313999105188],
            [-82.257842, 35.698851999105557],
            [-82.275399, 35.704437999105501],
            [-82.284733, 35.725525999105287],
            [-82.335054, 35.74939599910504],
            [-82.361956, 35.821244999104294],
            [-82.408602, 35.818177999104329],
            [-82.494781, 35.909483999103387],
            [-82.508007659722495, 35.982013204544138]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "027",
        COUNTYNS: "00063761",
        AFFGEOID: "0500000US05027",
        GEOID: "05027",
        NAME: "Columbia",
        LSAD: "06",
        ALAND: 1983889573,
        AWATER: 1990757
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.48176, 33.259990999134288],
            [-93.442019, 33.258164999134316],
            [-93.365585, 33.444458999131896],
            [-93.22129, 33.440443999131951],
            [-93.116361, 33.452908999131786],
            [-93.119014, 33.38029299913272],
            [-92.978469, 33.377279999132753],
            [-92.988707610715011, 33.017250939289688],
            [-93.197402, 33.017950999137476],
            [-93.238606962399388, 33.018015879094087],
            [-93.377134, 33.018233999137493],
            [-93.490512400120394, 33.018634594803771],
            [-93.48176, 33.259990999134288]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "031",
        COUNTYNS: "00066847",
        AFFGEOID: "0500000US05031",
        GEOID: "05031",
        NAME: "Craighead",
        LSAD: "06",
        ALAND: 1831867347,
        AWATER: 14069141
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.032499, 35.885069999103635],
            [-90.872785, 35.88086999910368],
            [-90.852064, 35.968091999102789],
            [-90.387378, 35.964707999102835],
            [-90.368718, 35.995811999102521],
            [-90.2889479152728, 35.996514003313898],
            [-90.286736, 35.699913999105554],
            [-91.036967, 35.708417999105471],
            [-91.032499, 35.885069999103635]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "163",
        COUNTYNS: "01383867",
        AFFGEOID: "0500000US48163",
        GEOID: "48163",
        NAME: "Frio",
        LSAD: "06",
        ALAND: 2935753507,
        AWATER: 2193620
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.41388, 29.091334999197795],
            [-98.8049, 29.090433999197806],
            [-98.800841, 28.647486999205583],
            [-99.395736, 28.640783999205706],
            [-99.409021, 28.640565999205702],
            [-99.41388, 29.091334999197795]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "109",
        COUNTYNS: "01008569",
        AFFGEOID: "0500000US37109",
        GEOID: "37109",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 766177348,
        AWATER: 23377500
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.535403, 35.568136999106947],
            [-80.960025, 35.547019999107171],
            [-80.939685, 35.529528999107356],
            [-80.948122, 35.491166999107755],
            [-80.94238, 35.455146999108145],
            [-80.95486, 35.400077999108738],
            [-81.4556, 35.41981099910852],
            [-81.515009, 35.55778199910705],
            [-81.537599, 35.564227999106983],
            [-81.535403, 35.568136999106947]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "057",
        COUNTYNS: "00424230",
        AFFGEOID: "0500000US17057",
        GEOID: "17057",
        NAME: "Fulton",
        LSAD: "06",
        ALAND: 2241880793,
        AWATER: 43973314
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.450227, 40.276334999069299],
            [-90.445506, 40.627842999067489],
            [-90.444343, 40.71466699906707],
            [-89.986068, 40.712256999067073],
            [-89.989018, 40.625834999067486],
            [-89.87351, 40.62448199906752],
            [-89.872463, 40.513126999068064],
            [-89.885235, 40.492479999068173],
            [-89.92468, 40.435920999068465],
            [-90.03795, 40.375040999068787],
            [-90.118966, 40.235262999069526],
            [-90.199556, 40.183944999069794],
            [-90.451502, 40.18889199906976],
            [-90.450227, 40.276334999069299]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "047",
        COUNTYNS: "01026339",
        AFFGEOID: "0500000US37047",
        GEOID: "37047",
        NAME: "Columbus",
        LSAD: "06",
        ALAND: 2429714643,
        AWATER: 43719396
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.071243612225302, 34.299303950155874],
            [-78.960011, 34.442683999119566],
            [-78.868961, 34.484777999119068],
            [-78.830353, 34.462577999119333],
            [-78.648373, 34.460868999119342],
            [-78.44532, 34.377399999120335],
            [-78.334569, 34.367535999120463],
            [-78.256085, 34.399468999120067],
            [-78.202532, 34.353305999120629],
            [-78.16235, 34.357006999120571],
            [-78.254622, 34.216304999122265],
            [-78.374295, 34.2048929991224],
            [-78.422734, 34.139630999123199],
            [-78.507338, 34.169245999122836],
            [-78.545218, 34.145746999123119],
            [-78.555043, 34.067070999124077],
            [-78.640688, 34.009853999124772],
            [-78.650871280533295, 33.945055719658463],
            [-78.81171, 34.081005999123903],
            [-79.071169, 34.299239999121276],
            [-79.071243612225302, 34.299303950155874]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "237",
        COUNTYNS: "01383904",
        AFFGEOID: "0500000US48237",
        GEOID: "48237",
        NAME: "Jack",
        LSAD: "06",
        ALAND: 2358611822,
        AWATER: 24503945
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.420666, 33.396194999132518],
            [-98.421419, 33.46679699913161],
            [-97.978919, 33.467128999131596],
            [-97.978967, 33.433747999132038],
            [-97.918189, 33.433872999132021],
            [-97.921642, 33.001283999137705],
            [-98.056094, 33.003331999137686],
            [-98.426553, 33.007912999137609],
            [-98.420666, 33.396194999132518]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "095",
        COUNTYNS: "00198163",
        AFFGEOID: "0500000US08095",
        GEOID: "08095",
        NAME: "Phillips",
        LSAD: "06",
        ALAND: 1781724973,
        AWATER: 301808
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.664689, 40.705188999067104],
            [-102.65131, 40.74940199906689],
            [-102.051292000207013, 40.749585993940599],
            [-102.051294151579, 40.697546831456151],
            [-102.051304798597016, 40.440007992445445],
            [-102.664804, 40.438472999068438],
            [-102.664689, 40.705188999067104]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "113",
        COUNTYNS: "00465245",
        AFFGEOID: "0500000US19113",
        GEOID: "19113",
        NAME: "Linn",
        LSAD: "06",
        ALAND: 1856758894,
        AWATER: 19699801
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.831089, 42.299062999060801],
            [-91.596981, 42.296407999060818],
            [-91.363263, 42.29644499906081],
            [-91.365789, 41.947409999061939],
            [-91.366079, 41.860067999062252],
            [-91.831379, 41.861850999062241],
            [-91.831089, 42.299062999060801]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "219",
        COUNTYNS: "00356774",
        AFFGEOID: "0500000US13219",
        GEOID: "13219",
        NAME: "Oconee",
        LSAD: "06",
        ALAND: 477396249,
        AWATER: 5305211
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.537385, 33.965911999125311],
            [-83.508452, 33.931232999125747],
            [-83.415512, 33.918540999125902],
            [-83.275933, 33.847976999126786],
            [-83.306619, 33.811443999127235],
            [-83.28034, 33.761773999127854],
            [-83.29145, 33.734314999128195],
            [-83.406189, 33.698306999128668],
            [-83.479523, 33.802264999127352],
            [-83.505928, 33.817759999127155],
            [-83.533165, 33.82092299912712],
            [-83.647031, 33.906197999126043],
            [-83.537385, 33.965911999125311]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "273",
        COUNTYNS: "00352238",
        AFFGEOID: "0500000US13273",
        GEOID: "13273",
        NAME: "Terrell",
        LSAD: "06",
        ALAND: 868785319,
        AWATER: 5861795
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.599776, 31.920170999152763],
            [-84.45331, 31.919223999152774],
            [-84.443807, 31.967455999152069],
            [-84.357615, 31.885155999153259],
            [-84.338245, 31.873590999153429],
            [-84.259611, 31.827864999154095],
            [-84.286456, 31.791181999154638],
            [-84.297801, 31.621950999157118],
            [-84.450398, 31.621867999157125],
            [-84.476994, 31.621166999157126],
            [-84.546854, 31.621198999157127],
            [-84.535434, 31.67649399915631],
            [-84.603086, 31.772000999154912],
            [-84.599776, 31.920170999152763]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "149",
        COUNTYNS: "00659519",
        AFFGEOID: "0500000US27149",
        GEOID: "27149",
        NAME: "Stevens",
        LSAD: "06",
        ALAND: 1459747661,
        AWATER: 30319494
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.254022, 45.759819999057143],
            [-95.758508, 45.759931999057137],
            [-95.747402, 45.41231299905688],
            [-96.117036, 45.411992999056885],
            [-96.241328, 45.412666999056881],
            [-96.253026, 45.585525999056983],
            [-96.254022, 45.759819999057143]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "141",
        COUNTYNS: "00659515",
        AFFGEOID: "0500000US27141",
        GEOID: "27141",
        NAME: "Sherburne",
        LSAD: "06",
        ALAND: 1121229505,
        AWATER: 46967228
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.149467, 45.559065999056976],
            [-93.759435, 45.559645999056976],
            [-93.510704, 45.558771999056972],
            [-93.51007, 45.414797999056887],
            [-93.51219, 45.245682999056804],
            [-93.521653, 45.246617999056809],
            [-93.563814, 45.301135999056832],
            [-93.75661, 45.294720999056828],
            [-94.047327, 45.422860999056894],
            [-94.141989, 45.493242999056932],
            [-94.149467, 45.559065999056976]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "057",
        COUNTYNS: "01074041",
        AFFGEOID: "0500000US39057",
        GEOID: "39057",
        NAME: "Greene",
        LSAD: "06",
        ALAND: 1071555583,
        AWATER: 6535068
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.092938, 39.838344999071758],
            [-84.053736, 39.850457999071679],
            [-83.939583, 39.843796999071721],
            [-83.829374, 39.797765999071991],
            [-83.647169, 39.773028999072146],
            [-83.65333, 39.716875999072478],
            [-83.670196, 39.550253999073476],
            [-83.977005, 39.569168999073362],
            [-84.114195, 39.577982999073313],
            [-84.092938, 39.838344999071758]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "027",
        COUNTYNS: "01448027",
        AFFGEOID: "0500000US49027",
        GEOID: "49027",
        NAME: "Millard",
        LSAD: "06",
        ALAND: 17106839735,
        AWATER: 600835682
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.049436705930987, 38.6773624559589],
            [-114.048054, 38.878692999077884],
            [-114.049104, 39.005508999077023],
            [-114.047079, 39.499942999073788],
            [-114.047181412925994, 39.542743601303229],
            [-113.839771, 39.543838999073522],
            [-113.815743, 39.552643999073481],
            [-113.426363, 39.552465999073476],
            [-112.462419, 39.552450999073464],
            [-112.354467, 39.553683999073463],
            [-112.212045, 39.553986999073466],
            [-112.188922, 39.329391999074872],
            [-112.016003, 39.314560999074956],
            [-112.014083, 39.045517999076743],
            [-112.065162, 38.959301999077333],
            [-112.133862, 38.959301999077333],
            [-112.224461, 38.86480299907798],
            [-112.218356, 38.727360999078947],
            [-112.309953, 38.676289999079316],
            [-112.447517, 38.681269999079284],
            [-112.515394, 38.572844999080061],
            [-112.976724, 38.572429999080065],
            [-114.050153620806, 38.572975212083463],
            [-114.049436705930987, 38.6773624559589]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "091",
        COUNTYNS: "01622988",
        AFFGEOID: "0500000US26091",
        GEOID: "26091",
        NAME: "Lenawee",
        LSAD: "06",
        ALAND: 1941336570,
        AWATER: 30687108
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.363297, 42.07345599906153],
            [-84.131963, 42.071576999061527],
            [-83.773922, 42.082429999061482],
            [-83.763149851365199, 41.723546800848332],
            [-83.880390465353287, 41.720194489382358],
            [-84.134417, 41.712930999062785],
            [-84.360419041397293, 41.706955881696302],
            [-84.363297, 42.07345599906153]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "129",
        COUNTYNS: "01623007",
        AFFGEOID: "0500000US26129",
        GEOID: "26129",
        NAME: "Ogemaw",
        LSAD: "06",
        ALAND: 1459425379,
        AWATER: 29682543
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.370643, 44.507222999056864],
            [-83.886634, 44.508975999056879],
            [-83.883977, 44.161785999057123],
            [-84.166107, 44.161787999057125],
            [-84.368039, 44.160537999057119],
            [-84.370643, 44.507222999056864]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "125",
        COUNTYNS: "01804543",
        AFFGEOID: "0500000US72125",
        GEOID: "72125",
        NAME: "San Germán",
        LSAD: "13",
        ALAND: 141145018,
        AWATER: 29360
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.100492, 18.106394999441996],
            [-67.080751, 18.10698799944198],
            [-67.086359, 18.149828999440842],
            [-67.051461, 18.1740529994402],
            [-67.013844, 18.165873999440418],
            [-66.977855, 18.143798999441],
            [-66.97085, 18.12763199944143],
            [-66.983337, 18.052517999443427],
            [-67.068517, 18.066140999443064],
            [-67.109044, 18.056084999443335],
            [-67.100492, 18.106394999441996]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "037",
        COUNTYNS: "01035300",
        AFFGEOID: "0500000US38037",
        GEOID: "38037",
        NAME: "Grant",
        LSAD: "06",
        ALAND: 4297180181,
        AWATER: 17563262
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.096239, 46.717705999058545],
            [-101.718155, 46.71801899905855],
            [-101.718105, 46.630998999058377],
            [-101.299052, 46.630309999058369],
            [-101.29899, 46.37145099905792],
            [-101.048532, 46.371392999057925],
            [-101.025662, 46.284218999057785],
            [-101.198073, 46.183769999057624],
            [-101.189747, 46.14869299905758],
            [-101.301698, 46.133268999057563],
            [-101.299917, 46.104843999057529],
            [-101.554185, 46.006835999057408],
            [-101.622717, 46.037027999057443],
            [-101.664528, 46.011635999057404],
            [-101.749591, 46.041666999057433],
            [-101.83837, 46.033042999057429],
            [-101.849355, 46.073274999057489],
            [-101.998575, 46.053460999057464],
            [-101.997888, 46.205479999057665],
            [-102.046929, 46.283605999057784],
            [-102.051237, 46.630905999058378],
            [-102.096585, 46.631023999058385],
            [-102.096239, 46.717705999058545]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "033",
        COUNTYNS: "00450346",
        AFFGEOID: "0500000US18033",
        GEOID: "18033",
        NAME: "DeKalb",
        LSAD: "06",
        ALAND: 939731978,
        AWATER: 2657419
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.194084, 41.526436999063492],
            [-84.804958156239593, 41.530137812451677],
            [-84.804253257611506, 41.426049710613796],
            [-84.804133, 41.408291999063962],
            [-84.803703572616897, 41.271258329635131],
            [-85.192094, 41.264208999064572],
            [-85.194084, 41.526436999063492]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "029",
        COUNTYNS: "00484984",
        AFFGEOID: "0500000US20029",
        GEOID: "20029",
        NAME: "Cloud",
        LSAD: "06",
        ALAND: 1852726628,
        AWATER: 6859887
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.931482, 39.653766999072857],
            [-97.36867, 39.65404299907285],
            [-97.368574, 39.567017999073386],
            [-97.369839, 39.306010999075021],
            [-97.929097, 39.306396999075034],
            [-97.928623, 39.551459999073479],
            [-97.931844, 39.566920999073375],
            [-97.931482, 39.653766999072857]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "057",
        COUNTYNS: "00450356",
        AFFGEOID: "0500000US18057",
        GEOID: "18057",
        NAME: "Hamilton",
        LSAD: "06",
        ALAND: 1021307261,
        AWATER: 20999468
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.242743, 40.215833999069631],
            [-85.861903, 40.218935999069608],
            [-85.862489, 39.943617999071144],
            [-85.937587, 39.927136999071237],
            [-86.239739, 39.926054999071241],
            [-86.242365, 40.180777999069818],
            [-86.242743, 40.215833999069631]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "037",
        COUNTYNS: "00758473",
        AFFGEOID: "0500000US29037",
        GEOID: "29037",
        NAME: "Cass",
        LSAD: "06",
        ALAND: 1804142327,
        AWATER: 15044253
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.611958018322298, 38.547634433351938],
            [-94.609489672345802, 38.73810170855208],
            [-94.609456, 38.740699999078863],
            [-94.608960343749501, 38.847211314523108],
            [-94.119096, 38.833166999078202],
            [-94.119664, 38.568711999080101],
            [-94.064317, 38.567379999080103],
            [-94.06782, 38.466015999080824],
            [-94.065713, 38.447086999080973],
            [-94.212023, 38.44675399908099],
            [-94.31217, 38.471495999080794],
            [-94.612865595922102, 38.477602179315852],
            [-94.611958018322298, 38.547634433351938]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "037",
        COUNTYNS: "00558542",
        AFFGEOID: "0500000US22037",
        GEOID: "22037",
        NAME: "East Feliciana",
        LSAD: "15",
        ALAND: 1174307241,
        AWATER: 6151756
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.320706, 30.699416999171198],
            [-91.234828, 30.824699999169244],
            [-91.222387, 30.880761999168357],
            [-91.176140123966888, 30.999224201485131],
            [-91.060127467996594, 30.999323934475722],
            [-90.825829141291905, 30.999525354507121],
            [-90.863041, 30.941980999167409],
            [-90.845366, 30.888573999168248],
            [-90.855009, 30.873992999168468],
            [-90.857983, 30.873143999168484],
            [-90.840953, 30.846918999168896],
            [-90.849041, 30.719310999170887],
            [-91.254419, 30.705293999171097],
            [-91.297658, 30.649547999171993],
            [-91.310061, 30.653960999171911],
            [-91.320706, 30.699416999171198]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "041",
        COUNTYNS: "00198135",
        AFFGEOID: "0500000US08041",
        GEOID: "08041",
        NAME: "El Paso",
        LSAD: "06",
        ALAND: 5508502469,
        AWATER: 7099036
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.028903, 38.868906999077957],
            [-105.033544, 39.129818999076178],
            [-104.662896, 39.129526999076184],
            [-104.051568, 39.128137999076195],
            [-104.055528, 38.868867999077949],
            [-104.053921, 38.522392999080431],
            [-104.941533, 38.519568999080441],
            [-104.942426, 38.64988199907949],
            [-104.938994, 38.796883999078453],
            [-105.072184, 38.799381999078435],
            [-105.028903, 38.868906999077957]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "213",
        COUNTYNS: "00758559",
        AFFGEOID: "0500000US29213",
        GEOID: "29213",
        NAME: "Taney",
        LSAD: "06",
        ALAND: 1637993911,
        AWATER: 49745785
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.304359, 36.816865999094553],
            [-92.909336, 36.809177999094629],
            [-92.764869, 36.806096999094649],
            [-92.772333893372092, 36.498083153176964],
            [-92.838876, 36.498032999097553],
            [-92.854049114984889, 36.498023380239133],
            [-93.125969, 36.497850999097558],
            [-93.293447355633987, 36.498259374331852],
            [-93.315327106529097, 36.498312725395458],
            [-93.304359, 36.816865999094553]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "047",
        COUNTYNS: "01497831",
        AFFGEOID: "0500000US51047",
        GEOID: "51047",
        NAME: "Culpeper",
        LSAD: "06",
        ALAND: 982079080,
        AWATER: 9015222
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.175425, 38.521038999080439],
            [-77.935355, 38.695839999079176],
            [-77.909809, 38.697321999079158],
            [-77.877169, 38.584890999079967],
            [-77.8136, 38.530114999080368],
            [-77.735516, 38.413115999081228],
            [-77.63494, 38.410217999081262],
            [-77.618727, 38.367834999081573],
            [-77.645744, 38.378106999081496],
            [-77.702843, 38.360839999081634],
            [-77.770061, 38.392991999081389],
            [-77.782494, 38.364464999081598],
            [-77.908979, 38.381502999081476],
            [-78.039921, 38.311565999081999],
            [-78.094498, 38.311241999082],
            [-78.09441, 38.399229999081335],
            [-78.231803, 38.532538999080352],
            [-78.175425, 38.521038999080439]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "075",
        COUNTYNS: "01008555",
        AFFGEOID: "0500000US37075",
        GEOID: "37075",
        NAME: "Graham",
        LSAD: "06",
        ALAND: 756200800,
        AWATER: 25045983
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.038081, 35.348362999109291],
            [-84.007586, 35.371660999109046],
            [-84.021782, 35.407417999108667],
            [-83.973171, 35.452581999108176],
            [-83.958917834563906, 35.45789943868801],
            [-83.953231380150399, 35.460020888684788],
            [-83.585756, 35.434831999108368],
            [-83.680114, 35.279499999110051],
            [-83.702772, 35.248519999110385],
            [-83.831429, 35.256116999110297],
            [-83.968532, 35.218073999110715],
            [-84.029104413772487, 35.292123775603805],
            [-84.02351, 35.295782999109875],
            [-84.038081, 35.348362999109291]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "197",
        COUNTYNS: "00758551",
        AFFGEOID: "0500000US29197",
        GEOID: "29197",
        NAME: "Schuyler",
        LSAD: "06",
        ALAND: 795916406,
        AWATER: 2216011
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.686693, 40.589808999067664],
            [-92.637903293599493, 40.590956546165572],
            [-92.453745, 40.595287999067651],
            [-92.350804186726393, 40.597257275443738],
            [-92.349975, 40.34650499906892],
            [-92.684167, 40.34346599906894],
            [-92.70932, 40.445892999068406],
            [-92.683162, 40.560662999067823],
            [-92.714597263045007, 40.589582832903986],
            [-92.686693, 40.589808999067664]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "039",
        COUNTYNS: "00695744",
        AFFGEOID: "0500000US28039",
        GEOID: "28039",
        NAME: "George",
        LSAD: "06",
        ALAND: 1239817244,
        AWATER: 12838126
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.885038, 30.910787999167898],
            [-88.834475, 30.910322999167906],
            [-88.834339, 30.997982999166542],
            [-88.426020963787096, 30.998281356596852],
            [-88.41246743135919, 30.735597458385925],
            [-88.834081, 30.734235999170647],
            [-88.884534, 30.735590999170629],
            [-88.885038, 30.910787999167898]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "051",
        COUNTYNS: "00465214",
        AFFGEOID: "0500000US19051",
        GEOID: "19051",
        NAME: "Davis",
        LSAD: "06",
        ALAND: 1300661857,
        AWATER: 6904096
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.639091, 40.898885999066188],
            [-92.179072, 40.899719999066178],
            [-92.17978, 40.600528999067627],
            [-92.350804186726393, 40.597257275443738],
            [-92.453745, 40.595287999067651],
            [-92.637903293599493, 40.590956546165572],
            [-92.639091, 40.898885999066188]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "021",
        COUNTYNS: "00695735",
        AFFGEOID: "0500000US28021",
        GEOID: "28021",
        NAME: "Claiborne",
        LSAD: "06",
        ALAND: 1262400024,
        AWATER: 35711133
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.234899, 31.876862999153389],
            [-91.18111, 31.920058999152754],
            [-91.17741, 31.973256999151975],
            [-91.117409, 31.98705699915179],
            [-91.080808, 32.023455999151253],
            [-91.079108, 32.05025499915088],
            [-91.034707, 32.101052999150149],
            [-91.039472318907002, 32.107968141815356],
            [-90.942925, 32.078336999150473],
            [-90.90774, 32.131887999149711],
            [-90.77482, 32.153992999149402],
            [-90.728622, 32.225118999148386],
            [-90.718297, 32.04835199915091],
            [-90.716785, 31.844582999153847],
            [-90.736282, 31.786133999154703],
            [-90.852657, 31.785421999154707],
            [-90.841886, 31.826254999154123],
            [-91.013676, 31.861851999153608],
            [-91.244015161324697, 31.86973243665739],
            [-91.234899, 31.876862999153389]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "163",
        COUNTYNS: "00695802",
        AFFGEOID: "0500000US28163",
        GEOID: "28163",
        NAME: "Yazoo",
        LSAD: "06",
        ALAND: 2387955940,
        AWATER: 31503956
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.759753, 32.835762999139931],
            [-90.656632, 32.922509999138754],
            [-90.449893, 32.923289999138753],
            [-90.449323, 33.009009999137596],
            [-90.364031, 33.010595999137585],
            [-90.346221, 33.022384999137422],
            [-90.338339, 33.010130999137594],
            [-89.965882, 32.879572999139342],
            [-90.049129, 32.735673999141284],
            [-90.099313, 32.700482999141776],
            [-90.277813, 32.660427999142314],
            [-90.363788, 32.603330999143104],
            [-90.404875, 32.574715999143493],
            [-90.450037, 32.573782999143511],
            [-90.522217, 32.503109999144492],
            [-90.553821, 32.507573999144427],
            [-90.654563, 32.546041999143888],
            [-90.655527, 32.58977099914329],
            [-90.720102, 32.61885799914289],
            [-90.691405, 32.636283999142648],
            [-90.723159, 32.662127999142299],
            [-90.667678, 32.670982999142169],
            [-90.742634, 32.72922499914138],
            [-90.694631, 32.815641999140198],
            [-90.759753, 32.835762999139931]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "021",
        COUNTYNS: "00395159",
        AFFGEOID: "0500000US16021",
        GEOID: "16021",
        NAME: "Boundary",
        LSAD: "06",
        ALAND: 3285990181,
        AWATER: 24160674
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.032351, 48.999187999066159],
            [-116.757234, 48.999942999066164],
            [-116.417503, 49.000098999066161],
            [-116.049193, 49.000911999066162],
            [-116.049156520648992, 48.502042480822098],
            [-116.784878, 48.500471999063976],
            [-116.785174, 48.847401999065454],
            [-117.032939182117019, 48.84666792026696],
            [-117.032351, 48.999187999066159]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "173",
        COUNTYNS: "01383872",
        AFFGEOID: "0500000US48173",
        GEOID: "48173",
        NAME: "Glasscock",
        LSAD: "06",
        ALAND: 2331552973,
        AWATER: 2245301
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.776085, 32.086924999150355],
            [-101.695011, 32.087531999150336],
            [-101.264216, 32.087135999150355],
            [-101.267122, 31.650853999156695],
            [-101.775802, 31.651318999156683],
            [-101.776085, 32.086924999150355]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "137",
        COUNTYNS: "01023355",
        AFFGEOID: "0500000US37137",
        GEOID: "37137",
        NAME: "Pamlico",
        LSAD: "06",
        ALAND: 871556645,
        AWATER: 583050685
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.987266, 35.154949999111416],
            [-76.895919, 35.253888999110323],
            [-76.845505, 35.216058999110743],
            [-76.632561, 35.235323999110527],
            [-76.590497, 35.361856999109143],
            [-76.522131, 35.353622999109234],
            [-76.454738, 35.334375999109447],
            [-76.304136, 35.201452999110906],
            [-76.71158, 34.981767999113352],
            [-76.780586, 34.950433999113699],
            [-76.953285, 35.021247999112902],
            [-76.940819, 35.11910299911181],
            [-76.987266, 35.154949999111416]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "125",
        COUNTYNS: "00516909",
        AFFGEOID: "0500000US21125",
        GEOID: "21125",
        NAME: "Laurel",
        LSAD: "06",
        ALAND: 1123945963,
        AWATER: 25218355
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.358024, 36.95939999909325],
            [-84.303016, 37.038395999092529],
            [-84.289076, 37.151739999091525],
            [-84.254315, 37.239081999090757],
            [-84.137898, 37.319758999090062],
            [-84.124945, 37.33562599908992],
            [-84.005606, 37.253342999090627],
            [-83.943718, 37.250550999090656],
            [-83.969571, 37.168875999091377],
            [-83.885673, 37.118766999091818],
            [-83.871315, 37.054918999092394],
            [-83.959138, 36.977272999093088],
            [-84.091853, 36.955991999093278],
            [-84.183131, 36.939368999093439],
            [-84.25772, 36.972844999093134],
            [-84.297416, 36.94593099909337],
            [-84.366101, 36.943422999093393],
            [-84.358024, 36.95939999909325]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "001",
        COUNTYNS: "01605066",
        AFFGEOID: "0500000US56001",
        GEOID: "56001",
        NAME: "Albany",
        LSAD: "06",
        ALAND: 11070455501,
        AWATER: 89161368
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.322885, 41.395140999064019],
            [-106.068406, 41.39502799906402],
            [-106.074919, 42.086648999061481],
            [-106.073403, 42.433235999060415],
            [-105.556042, 42.43058299906042],
            [-105.575642, 42.377313999060569],
            [-105.613865, 42.304454999060781],
            [-105.526399, 42.288858999060828],
            [-105.375541, 42.290138999060829],
            [-105.332638, 42.431105999060421],
            [-105.28391, 42.431401999060412],
            [-105.271809, 41.742028999062676],
            [-105.278236, 41.656654999063001],
            [-105.276859994035007, 40.99817291669163],
            [-105.277138, 40.998172999065744],
            [-106.190554078708999, 40.997746611640132],
            [-106.217573, 40.997733999065737],
            [-106.321169215913997, 40.998215010133734],
            [-106.322885, 41.395140999064019]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "147",
        COUNTYNS: "00516926",
        AFFGEOID: "0500000US21147",
        GEOID: "21147",
        NAME: "McCreary",
        LSAD: "06",
        ALAND: 1105416732,
        AWATER: 10730756
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.62256, 36.793932999094764],
            [-84.581516, 36.80368299909469],
            [-84.578158, 36.867132999094089],
            [-84.416816, 36.94691999909336],
            [-84.358024, 36.95939999909325],
            [-84.366101, 36.943422999093393],
            [-84.297416, 36.94593099909337],
            [-84.318033, 36.833762999094397],
            [-84.355967, 36.811018999094614],
            [-84.307598, 36.676808999095861],
            [-84.227193961998296, 36.592179511451761],
            [-84.227332, 36.592180999096662],
            [-84.2613212015047, 36.592741696363653],
            [-84.499938, 36.59667799909662],
            [-84.778455864350491, 36.603210511312454],
            [-84.62256, 36.793932999094764]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "027",
        COUNTYNS: "00198129",
        AFFGEOID: "0500000US08027",
        GEOID: "08027",
        NAME: "Custer",
        LSAD: "06",
        ALAND: 1913031958,
        AWATER: 3364151
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.796897, 38.265046999082351],
            [-105.049215, 38.257972999082398],
            [-105.049917, 37.915478999085082],
            [-105.168652, 38.018945999084259],
            [-105.285076, 37.899420999085201],
            [-105.317081, 37.938948999084893],
            [-105.473204, 37.895969999085231],
            [-105.583922, 37.967195999084666],
            [-105.67627, 38.146172999083269],
            [-105.796897, 38.265046999082351]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "075",
        COUNTYNS: "00343878",
        AFFGEOID: "0500000US13075",
        GEOID: "13075",
        NAME: "Cook",
        LSAD: "06",
        ALAND: 588450813,
        AWATER: 15542061
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.574857, 31.078134999165318],
            [-83.509961, 31.205971999163367],
            [-83.512607, 31.327404999161519],
            [-83.460644, 31.326262999161539],
            [-83.434492, 31.350360999161179],
            [-83.335949, 31.244578999162773],
            [-83.320471, 31.097961999165015],
            [-83.279781, 31.063350999165532],
            [-83.295052, 31.027277999166099],
            [-83.475617, 31.031963999166017],
            [-83.551628, 31.054328999165683],
            [-83.574857, 31.078134999165318]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "033",
        COUNTYNS: "00295737",
        AFFGEOID: "0500000US12033",
        GEOID: "12033",
        NAME: "Escambia",
        LSAD: "06",
        ALAND: 1701448275,
        AWATER: 564025766
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.592064, 30.951459999167273],
            [-87.598937, 30.997421999166555],
            [-87.598828946262103, 30.997422176071858],
            [-87.519533, 30.99755199916655],
            [-87.425791, 30.998057999166544],
            [-87.312206, 30.998403999166541],
            [-87.16308069276559, 30.999024183044135],
            [-87.286882, 30.925440999167666],
            [-87.313611, 30.847265999168879],
            [-87.302661, 30.719369999170887],
            [-87.269407, 30.711686999171],
            [-87.258884, 30.611280999172596],
            [-87.209715, 30.555265999173479],
            [-87.124968, 30.500195999174348],
            [-87.1344, 30.420293999175627],
            [-87.228226, 30.384245999176205],
            [-87.2297658113648, 30.319632928978255],
            [-87.267827, 30.31547999917731],
            [-87.319518, 30.317813999177275],
            [-87.419859, 30.297127999177604],
            [-87.518324, 30.280434999177871],
            [-87.452282, 30.344096999176852],
            [-87.431784, 30.4031929991759],
            [-87.366601, 30.436642999175369],
            [-87.414685, 30.457288999175038],
            [-87.444722, 30.507483999174241],
            [-87.43145, 30.550251999173557],
            [-87.401189, 30.604382999172707],
            [-87.400189, 30.657200999171859],
            [-87.442291, 30.692660999171313],
            [-87.523621, 30.738284999170581],
            [-87.542268, 30.767480999170136],
            [-87.634943, 30.865856999168596],
            [-87.592064, 30.951459999167273]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "047",
        COUNTYNS: "00516870",
        AFFGEOID: "0500000US21047",
        GEOID: "21047",
        NAME: "Christian",
        LSAD: "06",
        ALAND: 1858332405,
        AWATER: 16928991
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.710912, 37.02160699909269],
            [-87.680617, 37.149238999091558],
            [-87.520116, 37.10545199909194],
            [-87.333741, 37.157185999091467],
            [-87.334277, 37.131817999091709],
            [-87.259371, 37.072400999092238],
            [-87.335979050080795, 36.641577159589289],
            [-87.347796, 36.641439999096185],
            [-87.64114589700209, 36.638036046706326],
            [-87.64115, 36.638035999096218],
            [-87.694185907864281, 36.636838243039932],
            [-87.659724, 36.966396999093192],
            [-87.733368, 37.002102999092862],
            [-87.710912, 37.02160699909269]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "033",
        COUNTYNS: "01155133",
        AFFGEOID: "0500000US41033",
        GEOID: "41033",
        NAME: "Josephine",
        LSAD: "06",
        ALAND: 4244087614,
        AWATER: 5629244
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.008397, 42.496351999060224],
            [-123.890657, 42.500537999060221],
            [-123.831264, 42.632312999059849],
            [-123.716492, 42.784060999059463],
            [-123.716505, 42.739657999059581],
            [-123.581699, 42.73991999905958],
            [-123.445456, 42.703024999059664],
            [-123.274283, 42.732029999059591],
            [-123.229619, 42.702609999059675],
            [-123.229545, 42.495622999060231],
            [-123.229901, 42.290815999060825],
            [-123.231001260578012, 42.004970061317756],
            [-123.347562, 41.999107999061778],
            [-123.43477, 42.001640999061763],
            [-123.519112836804013, 41.999172517094678],
            [-123.656998, 41.995136999061778],
            [-123.822037598587997, 41.995620816008781],
            [-123.822139, 42.08752199906148],
            [-123.858549, 42.13511599906132],
            [-123.7922, 42.237622999060996],
            [-123.888411, 42.351626999060649],
            [-124.041983, 42.387655999060549],
            [-124.008397, 42.496351999060224]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "105",
        COUNTYNS: "01266990",
        AFFGEOID: "0500000US46105",
        GEOID: "46105",
        NAME: "Perkins",
        LSAD: "06",
        ALAND: 7434503583,
        AWATER: 50539581
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.957281, 45.212850999056812],
            [-102.942069961191009, 45.945094410410519],
            [-102.880252, 45.945068999057327],
            [-102.704871, 45.945071999057319],
            [-102.550947, 45.945014999057321],
            [-102.32823, 45.94480599905733],
            [-102.087555, 45.944597999057322],
            [-102.000679921018005, 45.944538391784825],
            [-101.99979, 45.472413999056918],
            [-101.99999, 45.038273999056777],
            [-102.957371, 45.039568999056769],
            [-102.957281, 45.212850999056812]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "101",
        COUNTYNS: "01209187",
        AFFGEOID: "0500000US42101",
        GEOID: "42101",
        NAME: "Philadelphia",
        LSAD: "06",
        ALAND: 347520038,
        AWATER: 22086063
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.276482, 39.976958999070952],
            [-75.249214, 39.990546999070872],
            [-75.205771, 40.011628999070766],
            [-75.264431, 40.054093999070517],
            [-75.22361, 40.092906999070301],
            [-75.12632, 40.055480999070504],
            [-75.10745, 40.048598999070542],
            [-75.015066, 40.137991999070067],
            [-74.955777, 40.095102999070292],
            [-74.980688, 40.065006999070462],
            [-74.972854756445599, 40.046505364606865],
            [-74.989914, 40.037310999070606],
            [-75.059017, 39.992511999070878],
            [-75.060129970722599, 39.99201100233347],
            [-75.11922, 39.965411999071016],
            [-75.13572, 39.947111999071126],
            [-75.13342, 39.896212999071416],
            [-75.141435521747297, 39.893918371552743],
            [-75.183023, 39.882012999071506],
            [-75.211196061474496, 39.866518630753689],
            [-75.221025, 39.861112999071615],
            [-75.258805818047691, 39.85467389985606],
            [-75.264433, 39.882166999071494],
            [-75.247068, 39.913777999071314],
            [-75.242186, 39.933425999071204],
            [-75.235161, 39.938270999071179],
            [-75.251496, 39.949250999071104],
            [-75.248056, 39.964073999071033],
            [-75.265448, 39.965256999071023],
            [-75.280266, 39.974963999070958],
            [-75.276482, 39.976958999070952]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "203",
        COUNTYNS: "01383887",
        AFFGEOID: "0500000US48203",
        GEOID: "48203",
        NAME: "Harrison",
        LSAD: "06",
        ALAND: 2330820903,
        AWATER: 40969486
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.70214, 32.793087999140496],
            [-94.507323, 32.693737999141867],
            [-94.390783, 32.694376999141845],
            [-94.242809, 32.750729999141079],
            [-94.176401, 32.694693999141855],
            [-94.092738, 32.734268999141314],
            [-94.043051527617592, 32.69302997580747],
            [-94.043083, 32.564260999143649],
            [-94.042788232805307, 32.392283192427229],
            [-94.350303, 32.32661399914695],
            [-94.385839, 32.367199999146393],
            [-94.491818, 32.394136999146006],
            [-94.554971, 32.407602999145823],
            [-94.579219, 32.394915999146001],
            [-94.701986, 32.421781999145622],
            [-94.70179, 32.652208999142431],
            [-94.70214, 32.793087999140496]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "50",
        COUNTYFP: "013",
        COUNTYNS: "01461763",
        AFFGEOID: "0500000US50013",
        GEOID: "50013",
        NAME: "Grand Isle",
        LSAD: "06",
        ALAND: 211881636,
        AWATER: 292317315
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.389966, 44.61961999905683],
            [-73.36556, 44.700296999056789],
            [-73.357671, 44.751017999056792],
            [-73.33443, 44.802187999056784],
            [-73.342012483950001, 44.808075537717777],
            [-73.365678, 44.826450999056782],
            [-73.379822, 44.857036999056767],
            [-73.338979, 44.917680999056749],
            [-73.34474, 44.970467999056758],
            [-73.343124, 45.010839999056756],
            [-73.192310449066895, 45.012861162008868],
            [-73.240323, 44.951590999056762],
            [-73.230777, 44.927436999056773],
            [-73.192787, 44.908699999056772],
            [-73.256321, 44.759443999056778],
            [-73.228854, 44.722865999056793],
            [-73.26559, 44.592610999056838],
            [-73.362682812743799, 44.562455220743551],
            [-73.367275, 44.567544999056857],
            [-73.389966, 44.61961999905683]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "023",
        COUNTYNS: "01533500",
        AFFGEOID: "0500000US53023",
        GEOID: "53023",
        NAME: "Garfield",
        LSAD: "06",
        ALAND: 1841054914,
        AWATER: 19294817
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.85185, 46.624669999058369],
            [-117.740868, 46.694582999058504],
            [-117.597792, 46.672542999058464],
            [-117.464769, 46.699625999058512],
            [-117.382742, 46.61468399905835],
            [-117.254262, 46.545081999058219],
            [-117.22812, 46.462244999058065],
            [-117.228796, 46.411305999057987],
            [-117.419334, 46.383138999057941],
            [-117.419482, 46.121955999057548],
            [-117.479988, 46.121993999057551],
            [-117.47993726365398, 45.997566765899592],
            [-117.603425834504009, 45.998760172500091],
            [-117.611903, 46.338477999057865],
            [-117.674946, 46.337650999057871],
            [-117.737303, 46.471453999058092],
            [-117.863347, 46.470666999058089],
            [-117.85185, 46.624669999058369]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "164",
        COUNTYNS: "01419975",
        AFFGEOID: "0500000US02164",
        GEOID: "02164",
        NAME: "Lake and Peninsula",
        LSAD: "04",
        ALAND: 61894488750,
        AWATER: 25991759519
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-157.288702, 56.56603899913317],
              [-157.172027, 56.598038999133578],
              [-157.091146, 56.581133999133357],
              [-156.975549, 56.540445999132828],
              [-157.047173, 56.519930999132576],
              [-157.168777, 56.530209999132701],
              [-157.326059, 56.525168999132632],
              [-157.288702, 56.56603899913317]
            ]
          ],
          [
            [
              [-159.900149, 56.494238999132236],
              [-159.588036, 56.494244999132242],
              [-159.588041, 56.58096099913336],
              [-159.275928, 56.580966999133359],
              [-159.275932, 56.667681999134494],
              [-159.051089, 56.667686999134489],
              [-159.051094, 56.754400999135633],
              [-158.893607, 56.754403999135633],
              [-158.893547986565011, 56.809308547513552],
              [-158.853294, 56.792619999136136],
              [-158.744534, 56.795111999136168],
              [-158.656355, 56.810011999136364],
              [-158.646812, 56.846991999136861],
              [-158.686184, 56.911554999137721],
              [-158.679293, 56.988624999138743],
              [-158.531328, 57.132155999140679],
              [-158.32018, 57.281557999142713],
              [-158.229883, 57.321533999143263],
              [-158.083785, 57.357180999143758],
              [-157.931624, 57.476207999145416],
              [-157.772496, 57.547054999146404],
              [-157.678891, 57.56388799914663],
              [-157.684282, 57.609973999147272],
              [-157.709179, 57.657458999147948],
              [-157.683349, 57.753694999149324],
              [-157.642226, 57.868776999150967],
              [-157.596601, 58.088669999154135],
              [-157.556556, 58.148444999155004],
              [-157.48013, 58.217353999156018],
              [-157.547209, 58.277534999156899],
              [-157.541041, 58.377301999158384],
              [-157.481487, 58.480770999159922],
              [-157.313572, 58.565042999161186],
              [-157.234300636678029, 58.616666999161957],
              [-156.313889, 58.61666599916196],
              [-156.313889, 58.88583299916607],
              [-157.045496, 58.88583299916607],
              [-157.095372270938981, 58.866670716260778],
              [-157.116866, 58.867532999165782],
              [-157.196292140099018, 58.849359540827599],
              [-157.196306, 59.086121999169158],
              [-157.275221, 59.086120999169168],
              [-157.275233, 59.259481999171875],
              [-157.106808, 59.25948299917188],
              [-157.106814, 59.346161999173248],
              [-156.769965, 59.346165999173259],
              [-156.769971, 59.432842999174632],
              [-156.675483, 59.432843999174608],
              [-156.675489, 59.519520999176009],
              [-156.505345, 59.519522999176004],
              [-156.505349, 59.606198999177394],
              [-156.165066, 59.606201999177394],
              [-156.165068, 59.692876999178786],
              [-156.000145, 59.692878999178802],
              [-156.000086, 60.906189999199164],
              [-155.144206, 60.906198999199169],
              [-154.455687, 60.906206999199149],
              [-153.440884, 60.906235999199176],
              [-153.397284, 60.819580999197676],
              [-153.397276, 60.47294799919171],
              [-153.528441, 60.472938999191726],
              [-153.528392, 60.126280999185909],
              [-153.65698, 60.126276999185912],
              [-153.656977, 59.77960699918021],
              [-153.78307, 59.7795969991802],
              [-153.783068, 59.692925999178797],
              [-153.953198, 59.692917999178803],
              [-153.953181, 59.519568999176009],
              [-154.123327, 59.519551999176002],
              [-154.123321, 59.432886999174627],
              [-154.412028, 59.432881999174633],
              [-154.412013, 59.259523999171897],
              [-154.748861, 59.259517999171891],
              [-154.695105, 59.08615899916915],
              [-154.695036, 58.739430999163815],
              [-154.642558, 58.739430999163815],
              [-154.642545, 58.65274699916251],
              [-154.305691, 58.6527509991625],
              [-154.303447, 58.460384999159615],
              [-154.351306, 58.418082999158976],
              [-154.432332, 58.418997999158996],
              [-154.465741, 58.361355999158143],
              [-154.569723, 58.360807999158141],
              [-154.570107, 58.333054999157717],
              [-154.729769, 58.33283699915772],
              [-154.730369, 58.304668999157307],
              [-155.008132, 58.290904999157092],
              [-155.057741, 58.23620899915629],
              [-155.221463, 58.236407999156313],
              [-155.221313, 58.193192999155663],
              [-155.331945, 58.193282999155663],
              [-155.280169, 58.10859099915443],
              [-155.280743, 58.047873999153545],
              [-155.331678, 58.04821099915354],
              [-155.330245, 57.876342999151063],
              [-155.44602, 57.871458999150988],
              [-155.446251, 57.827093999150357],
              [-155.53883, 57.799805999149974],
              [-155.693773, 57.797986999149934],
              [-155.692481, 57.739053999149107],
              [-155.747821, 57.739829999149123],
              [-155.768022, 57.668260999148103],
              [-155.898455, 57.668099999148112],
              [-155.84623, 57.629143999147558],
              [-155.921548, 57.5543339991465],
              [-155.952772, 57.598212999147115],
              [-156.071148, 57.584423999146914],
              [-156.132032, 57.509691999145865],
              [-156.370235, 57.522367999146056],
              [-156.508759, 57.434830999144829],
              [-156.502226, 57.362605999143824],
              [-156.617395, 57.334941999143446],
              [-156.672142, 57.292979999142879],
              [-156.672751, 57.234908999142078],
              [-156.75078, 57.235794999142101],
              [-156.750815, 57.162877999141102],
              [-156.374287301554006, 57.159250119865156],
              [-156.44301, 57.119532999140496],
              [-156.479111, 57.068394999139812],
              [-156.55052, 56.984609999138691],
              [-156.63784, 56.99390499913882],
              [-156.704216, 56.987078999138717],
              [-156.825982, 56.897666999137527],
              [-156.935629, 56.92008699913783],
              [-157.034624, 56.884486999137351],
              [-157.073453, 56.838344999136737],
              [-157.183636, 56.769078999135829],
              [-157.290511, 56.804712999136292],
              [-157.411488, 56.778350999135945],
              [-157.530765, 56.753774999135622],
              [-157.563802, 56.703425999134957],
              [-157.45216, 56.643219999134168],
              [-157.496523, 56.616896999133822],
              [-157.605231, 56.621314999133887],
              [-157.674587, 56.609506999133743],
              [-157.719048, 56.65308399913431],
              [-157.791844, 56.670691999134533],
              [-157.918541, 56.643136999134164],
              [-157.869897, 56.56683699913318],
              [-157.817826, 56.51420999913249],
              [-157.869124, 56.456611999131759],
              [-157.971711, 56.476736999132015],
              [-158.12744, 56.46080499913181],
              [-158.246144, 56.466123999131874],
              [-158.284699, 56.481088999132076],
              [-158.371953, 56.467333999131888],
              [-158.438414, 56.427470999131387],
              [-158.489546, 56.341864999130287],
              [-158.415095, 56.336227999130209],
              [-158.288369, 56.31608899912996],
              [-158.207387, 56.294353999129676],
              [-158.117797, 56.230741999128874],
              [-158.283191, 56.173211999128149],
              [-158.374324, 56.134521999127664],
              [-158.394922, 56.064720999126806],
              [-158.431471, 55.994451999125921],
              [-158.50984, 55.979616999125739],
              [-158.638211, 55.994742999125926],
              [-158.653214, 55.958614999125487],
              [-158.74856, 55.959364999125491],
              [-158.898116, 55.951040999125382],
              [-159.096187, 55.914749999124943],
              [-159.086217, 55.83486899912397],
              [-159.394595, 55.714943999122511],
              [-159.532754, 55.676423999122051],
              [-159.561438320694009, 55.640913755779735],
              [-159.56136, 55.887196999124612],
              [-159.867971, 55.887192999124608],
              [-159.86797, 55.973917999125668],
              [-159.806406, 55.973918999125672],
              [-159.806389, 56.320804999130011],
              [-159.900133, 56.320802999130009],
              [-159.900149, 56.494238999132236]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "095",
        COUNTYNS: "00069167",
        AFFGEOID: "0500000US05095",
        GEOID: "05095",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 1572480973,
        AWATER: 36694917
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.446983, 34.667530999116927],
            [-91.374765, 34.66596399911694],
            [-91.36856, 34.912734999114114],
            [-91.29479, 34.912451999114126],
            [-91.293111, 34.985090999113304],
            [-91.237493, 35.004290999113088],
            [-91.149842, 35.00246999911311],
            [-91.152457, 34.91184699911414],
            [-91.099961, 34.867119999114635],
            [-91.102542, 34.734863999116151],
            [-91.049474, 34.734183999116155],
            [-91.051918, 34.645720999117188],
            [-91.054774, 34.470879999119227],
            [-91.000459, 34.469397999119252],
            [-91.002693, 34.33635299912082],
            [-91.056276, 34.336604999120823],
            [-91.107762, 34.353403999120616],
            [-91.174745, 34.450442999119474],
            [-91.291483, 34.561760999118157],
            [-91.37782, 34.563479999118137],
            [-91.447795, 34.609184999117602],
            [-91.446983, 34.667530999116927]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "039",
        COUNTYNS: "00066849",
        AFFGEOID: "0500000US05039",
        GEOID: "05039",
        NAME: "Dallas",
        LSAD: "06",
        ALAND: 1728522517,
        AWATER: 1864048
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.961419, 33.974158999125216],
            [-92.893116, 33.983283999125106],
            [-92.886285, 34.155876999122995],
            [-92.678383, 34.14962199912307],
            [-92.493672, 34.144233999123138],
            [-92.405883, 34.11553499912349],
            [-92.336765, 34.059632999124162],
            [-92.471667, 34.0570539991242],
            [-92.45613, 33.972621999125238],
            [-92.337389, 33.828129999127022],
            [-92.334249, 33.795064999127447],
            [-92.583054, 33.802197999127344],
            [-92.893534, 33.809942999127252],
            [-92.89314, 33.902750999126091],
            [-92.961419, 33.974158999125216]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "085",
        COUNTYNS: "00277307",
        AFFGEOID: "0500000US06085",
        GEOID: "06085",
        NAME: "Santa Clara",
        LSAD: "06",
        ALAND: 3344209919,
        AWATER: 33276429
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.190402, 37.431471999089091],
            [-122.144933, 37.458199999088869],
            [-122.081473, 37.477837999088692],
            [-122.059673, 37.464086999088813],
            [-121.925041, 37.454185999088899],
            [-121.855762, 37.484536999088647],
            [-121.472648, 37.482169999088654],
            [-121.409693, 37.382012999089525],
            [-121.459051, 37.282738999090377],
            [-121.404636, 37.155988999091484],
            [-121.282271, 37.183674999091252],
            [-121.226804, 37.134773999091685],
            [-121.245887, 36.983035999093026],
            [-121.215406, 36.961247999093239],
            [-121.418246, 36.960548999093248],
            [-121.451972, 36.988839999092981],
            [-121.501488, 36.971894999093138],
            [-121.50528, 36.964215999093213],
            [-121.512263, 36.957996999093275],
            [-121.513813, 36.945154999093369],
            [-121.523591, 36.937089999093459],
            [-121.534463, 36.930932999093507],
            [-121.540754, 36.924627999093566],
            [-121.540016, 36.920764999093599],
            [-121.548009, 36.917390999093627],
            [-121.581354, 36.899151999093803],
            [-121.738697, 36.989990999092974],
            [-121.809076, 37.069300999092256],
            [-121.84629, 37.097019999092012],
            [-121.99109, 37.144269999091584],
            [-122.152278, 37.286054999090354],
            [-122.192162, 37.31909399909005],
            [-122.190402, 37.431471999089091]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "125",
        COUNTYNS: "00835884",
        AFFGEOID: "0500000US31125",
        GEOID: "31125",
        NAME: "Nance",
        LSAD: "06",
        ALAND: 1143826263,
        AWATER: 17085923
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.291344, 41.48072699906367],
            [-98.099523, 41.480776999063664],
            [-98.041328, 41.510533999063561],
            [-97.829846, 41.526173999063502],
            [-97.703627, 41.526795999063495],
            [-97.703765, 41.394875999064027],
            [-97.705497, 41.309133999064393],
            [-98.053137, 41.293122999064444],
            [-98.101546, 41.264040999064562],
            [-98.275408, 41.264340999064558],
            [-98.286584, 41.393897999064031],
            [-98.291419, 41.393893999064034],
            [-98.291344, 41.48072699906367]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "061",
        COUNTYNS: "00558077",
        AFFGEOID: "0500000US22061",
        GEOID: "22061",
        NAME: "Lincoln",
        LSAD: "15",
        ALAND: 1221806134,
        AWATER: 1897709
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.880805, 32.585276999143346],
            [-92.828535, 32.758813999140983],
            [-92.725439, 32.759539999140976],
            [-92.571295, 32.74857899914111],
            [-92.518356, 32.670719999142165],
            [-92.41505, 32.670055999142185],
            [-92.415071, 32.582844999143383],
            [-92.41535, 32.495485999144599],
            [-92.622335, 32.496405999144585],
            [-92.621678, 32.452998999145187],
            [-92.777197, 32.453454999145173],
            [-92.879268, 32.454117999145161],
            [-92.880805, 32.585276999143346]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "111",
        COUNTYNS: "00835877",
        AFFGEOID: "0500000US31111",
        GEOID: "31111",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 6640952057,
        AWATER: 28570547
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.269695, 41.394933999064023],
            [-100.713243, 41.393510999064034],
            [-100.251098, 41.393297999064025],
            [-100.238115, 41.061674999065445],
            [-100.224235, 41.046596999065521],
            [-100.223311, 40.700253999067137],
            [-100.777833, 40.700134999067139],
            [-101.247963, 40.697801999067146],
            [-101.249975, 41.003949999065696],
            [-101.270381, 41.047391999065511],
            [-101.269695, 41.394933999064023]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "073",
        COUNTYNS: "01035301",
        AFFGEOID: "0500000US38073",
        GEOID: "38073",
        NAME: "Ransom",
        LSAD: "06",
        ALAND: 2233494166,
        AWATER: 3581125
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.033862, 46.630726999058382],
            [-97.682006, 46.629927999058374],
            [-97.279948, 46.629347999058375],
            [-97.280568, 46.282320999057788],
            [-98.006715, 46.282625999057778],
            [-98.034573, 46.282795999057768],
            [-98.033862, 46.630726999058382]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "069",
        COUNTYNS: "01101822",
        AFFGEOID: "0500000US40069",
        GEOID: "40069",
        NAME: "Johnston",
        LSAD: "06",
        ALAND: 1665212040,
        AWATER: 39633300
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.933457, 34.332660999120868],
            [-96.881005, 34.332976999120859],
            [-96.880814, 34.506052999118808],
            [-96.827288, 34.505988999118813],
            [-96.513865, 34.505388999118829],
            [-96.513786, 34.419568999119832],
            [-96.40733, 34.418390999119843],
            [-96.40764, 34.157316999122976],
            [-96.453839, 34.114405999123484],
            [-96.585355, 34.114458999123492],
            [-96.620784, 34.162040999122922],
            [-96.933671, 34.172552999122786],
            [-96.933457, 34.332660999120868]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "021",
        COUNTYNS: "01213662",
        AFFGEOID: "0500000US42021",
        GEOID: "42021",
        NAME: "Cambria",
        LSAD: "06",
        ALAND: 1782804173,
        AWATER: 13696237
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.040788, 40.302316999069156],
            [-78.974649, 40.395971999068671],
            [-78.834641, 40.661677999067322],
            [-78.801697, 40.724538999067015],
            [-78.35043, 40.724826999067012],
            [-78.485958, 40.540846999067924],
            [-78.53984, 40.50615399906809],
            [-78.556958, 40.409135999068603],
            [-78.564085, 40.370608999068807],
            [-78.620383, 40.327024999069039],
            [-78.651868, 40.281677999069274],
            [-78.658409, 40.242932999069481],
            [-78.884862, 40.244630999069471],
            [-78.927601, 40.285040999069246],
            [-79.055983, 40.285088999069252],
            [-79.040788, 40.302316999069156]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "105",
        COUNTYNS: "01639767",
        AFFGEOID: "0500000US47105",
        GEOID: "47105",
        NAME: "Loudon",
        LSAD: "06",
        ALAND: 593760987,
        AWATER: 46923925
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.584472, 35.644423999106138],
            [-84.347624, 35.832366999104174],
            [-84.32997, 35.892903999103552],
            [-84.263383, 35.896939999103516],
            [-84.179798, 35.820231999104308],
            [-84.166267, 35.80510599910447],
            [-84.172812, 35.712755999105418],
            [-84.134468, 35.647398999106109],
            [-84.188319, 35.610548999106491],
            [-84.290765, 35.654924999106022],
            [-84.504274, 35.665865999105918],
            [-84.52585, 35.624185999106359],
            [-84.584472, 35.644423999106138]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "087",
        COUNTYNS: "01639758",
        AFFGEOID: "0500000US47087",
        GEOID: "47087",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 799368960,
        AWATER: 28196490
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.828026, 36.411835999098386],
            [-85.813885, 36.499472999097542],
            [-85.704075, 36.522394999097315],
            [-85.57596, 36.471616999097812],
            [-85.495422, 36.403073999098467],
            [-85.497587, 36.37505099909874],
            [-85.495124, 36.302277999099452],
            [-85.557527, 36.223176999100232],
            [-85.780148, 36.238010999100084],
            [-85.82766, 36.255271999099918],
            [-85.828026, 36.411835999098386]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "075",
        COUNTYNS: "01101825",
        AFFGEOID: "0500000US40075",
        GEOID: "40075",
        NAME: "Kiowa",
        LSAD: "06",
        ALAND: 2629209766,
        AWATER: 40296743
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.408338, 35.116489999111842],
            [-99.360226, 35.116737999111834],
            [-98.750344, 35.116342999111851],
            [-98.651267, 35.124180999111758],
            [-98.621054, 35.097351999112057],
            [-98.619816, 34.854952999114786],
            [-98.826041, 34.855121999114772],
            [-98.826008, 34.594412999117786],
            [-99.001107, 34.594365999117784],
            [-99.001025, 34.637871999117273],
            [-99.10315, 34.637949999117275],
            [-99.052511, 34.701556999116548],
            [-99.149114, 34.70235199911653],
            [-99.14457, 34.849539999114846],
            [-99.246462, 34.82211399911516],
            [-99.30622, 34.857631999114744],
            [-99.327788, 35.041696999112666],
            [-99.368367, 35.087866999112151],
            [-99.409291, 35.114508999111855],
            [-99.408338, 35.116489999111842]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "093",
        COUNTYNS: "01101831",
        AFFGEOID: "0500000US40093",
        GEOID: "40093",
        NAME: "Major",
        LSAD: "06",
        ALAND: 2473407963,
        AWATER: 7651201
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.960334, 36.506913999097478],
            [-98.843646, 36.506247999097468],
            [-98.80782, 36.460743999097907],
            [-98.625361, 36.39259499909857],
            [-98.53355, 36.397591999098523],
            [-98.533617, 36.463229999097877],
            [-98.104427, 36.463104999097887],
            [-98.103904, 36.164876999100812],
            [-98.210544, 36.164889999100808],
            [-98.636899, 36.164893999100805],
            [-98.955849, 36.161576999100845],
            [-98.960334, 36.506913999097478]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "177",
        COUNTYNS: "01383874",
        AFFGEOID: "0500000US48177",
        GEOID: "48177",
        NAME: "Gonzales",
        LSAD: "06",
        ALAND: 2762704870,
        AWATER: 8204086
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.840383, 29.376790999192881],
            [-97.664987, 29.607719999188973],
            [-97.633176, 29.649862999188265],
            [-97.597743, 29.630717999188594],
            [-97.317893, 29.784657999186013],
            [-97.131188, 29.643761999188371],
            [-97.142643, 29.628100999188632],
            [-97.204087, 29.580612999189427],
            [-97.240108, 29.384487999192757],
            [-97.335207, 29.315048999193937],
            [-97.613132, 29.109764999197477],
            [-97.728443, 29.221747999195539],
            [-97.858782, 29.352889999193287],
            [-97.840383, 29.376790999192881]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "133",
        COUNTYNS: "01383852",
        AFFGEOID: "0500000US48133",
        GEOID: "48133",
        NAME: "Eastland",
        LSAD: "06",
        ALAND: 2399599303,
        AWATER: 14047796
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.114293, 32.514810999144345],
            [-99.09605, 32.514767999144318],
            [-98.576256, 32.515162999144337],
            [-98.475177, 32.513038999144356],
            [-98.474685, 32.299083999147342],
            [-98.551158, 32.261435999147864],
            [-98.924401, 32.078017999150497],
            [-99.118665, 32.079584999150455],
            [-99.114293, 32.514810999144345]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "610",
        COUNTYNS: "01498423",
        AFFGEOID: "0500000US51610",
        GEOID: "51610",
        NAME: "Falls Church",
        LSAD: "25",
        ALAND: 5300264,
        AWATER: 32
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.194874, 38.898875999077752],
            [-77.172276, 38.893244999077794],
            [-77.149701, 38.875669999077907],
            [-77.158398, 38.87317599907793],
            [-77.173243, 38.878603999077889],
            [-77.189754, 38.878135999077891],
            [-77.194263, 38.886093999077843],
            [-77.189854, 38.894394999077782],
            [-77.194874, 38.898875999077752]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "173",
        COUNTYNS: "01500545",
        AFFGEOID: "0500000US51173",
        GEOID: "51173",
        NAME: "Smyth",
        LSAD: "06",
        ALAND: 1168992134,
        AWATER: 3519017
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.780545, 36.95862899909325],
            [-81.682786, 36.932933999093478],
            [-81.474539, 37.018475999092722],
            [-81.43673, 37.010134999092791],
            [-81.379402, 36.95244199909331],
            [-81.326985, 36.899751999093795],
            [-81.261987, 36.763032999095053],
            [-81.543957, 36.701516999095624],
            [-81.605865, 36.638078999096223],
            [-81.625477, 36.646310999096144],
            [-81.699561, 36.789315999094811],
            [-81.837096, 36.929038999093521],
            [-81.780545, 36.95862899909325]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "137",
        COUNTYNS: "01383854",
        AFFGEOID: "0500000US48137",
        GEOID: "48137",
        NAME: "Edwards",
        LSAD: "06",
        ALAND: 5485259829,
        AWATER: 5199800
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.700393, 30.288275999177756],
            [-100.116461, 30.290295999177722],
            [-99.754142, 30.290697999177702],
            [-99.757621, 30.074131999181226],
            [-99.967626, 30.08235599918109],
            [-100.002302, 29.896827999184147],
            [-100.03088, 29.848272999184953],
            [-100.014188, 29.623494999188708],
            [-100.112098, 29.623262999188714],
            [-100.699932, 29.623896999188702],
            [-100.700393, 30.288275999177756]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "021",
        COUNTYNS: "00277275",
        AFFGEOID: "0500000US06021",
        GEOID: "06021",
        NAME: "Glenn",
        LSAD: "06",
        ALAND: 3403149421,
        AWATER: 33704225
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.911794, 39.799484999071979],
            [-122.046471, 39.797647999071991],
            [-122.026498, 39.800136999071981],
            [-121.967868, 39.722396999072444],
            [-121.949868, 39.733000999072381],
            [-121.994343, 39.67145799907275],
            [-121.994021, 39.533925999073588],
            [-121.856532, 39.536903999073566],
            [-121.890013, 39.383863999074521],
            [-122.008857, 39.413566999074348],
            [-122.136161, 39.414498999074333],
            [-122.192334, 39.385256999074521],
            [-122.739062, 39.383265999074531],
            [-122.735639, 39.580667999073292],
            [-122.885352, 39.580107999073306],
            [-122.892676, 39.708897999072519],
            [-122.937654, 39.798155999071994],
            [-122.911794, 39.799484999071979]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "129",
        COUNTYNS: "00069180",
        AFFGEOID: "0500000US05129",
        GEOID: "05129",
        NAME: "Searcy",
        LSAD: "06",
        ALAND: 1725176973,
        AWATER: 6256687
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.940932, 35.990068999102569],
            [-92.93851, 36.071725999101737],
            [-92.944666, 36.115122999101295],
            [-92.890695, 36.114131999101325],
            [-92.836859, 36.113740999101317],
            [-92.836531, 36.070064999101753],
            [-92.411502, 36.062755999101839],
            [-92.414324, 35.975864999102711],
            [-92.416719, 35.78858999910463],
            [-92.629837, 35.790822999104606],
            [-92.631207, 35.718364999105368],
            [-92.809763, 35.722400999105318],
            [-92.951315, 35.724662999105298],
            [-92.940932, 35.990068999102569]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "217",
        COUNTYNS: "01673547",
        AFFGEOID: "0500000US13217",
        GEOID: "13217",
        NAME: "Newton",
        LSAD: "06",
        ALAND: 708857834,
        AWATER: 14261429
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.044493, 33.52577599913085],
            [-84.003278, 33.554874999130476],
            [-83.930863, 33.651822999129244],
            [-83.914823, 33.744202999128071],
            [-83.790705, 33.666655999129055],
            [-83.691551, 33.616988999129688],
            [-83.680896, 33.596918999129947],
            [-83.677717, 33.571833999130263],
            [-83.682196, 33.526219999130838],
            [-83.674644, 33.487224999131342],
            [-83.824922, 33.437262999131981],
            [-83.863058, 33.368277999132864],
            [-83.912516, 33.417751999132236],
            [-83.923913, 33.444193999131897],
            [-83.946526, 33.48176099913141],
            [-84.054396, 33.514906999131],
            [-84.044493, 33.52577599913085]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "229",
        COUNTYNS: "00349086",
        AFFGEOID: "0500000US13229",
        GEOID: "13229",
        NAME: "Pierce",
        LSAD: "06",
        ALAND: 881044511,
        AWATER: 7916649
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.417246, 31.417118999160174],
            [-82.344843, 31.430129999159973],
            [-82.300422, 31.493174999159031],
            [-82.226585, 31.530698999158471],
            [-82.156101, 31.471630999159359],
            [-82.132794, 31.471261999159356],
            [-82.063387, 31.466361999159435],
            [-82.041129, 31.373720999160817],
            [-81.997421, 31.288533999162112],
            [-82.114822, 31.270354999162379],
            [-82.193031, 31.202119999163422],
            [-82.284561, 31.224448999163087],
            [-82.31504, 31.225957999163057],
            [-82.383705, 31.291142999162059],
            [-82.417246, 31.417118999160174]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "013",
        COUNTYNS: "00395696",
        AFFGEOID: "0500000US16013",
        GEOID: "16013",
        NAME: "Blaine",
        LSAD: "06",
        ALAND: 6831709855,
        AWATER: 44656291
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.996687, 43.914406999057384],
            [-114.971351, 43.938307999057358],
            [-114.873372, 43.99294899905729],
            [-114.813873, 43.992940999057282],
            [-114.81379, 43.92777199905737],
            [-114.681168, 43.927704999057354],
            [-114.56235, 43.844367999057468],
            [-114.507778, 43.899089999057395],
            [-114.359293, 43.874176999057418],
            [-114.292684, 43.88714299905741],
            [-114.275725, 43.831137999057482],
            [-114.070019, 43.734695999057614],
            [-114.029718, 43.773360999057566],
            [-113.97447, 43.708185999057648],
            [-113.875329, 43.65050399905774],
            [-113.798441, 43.569216999057858],
            [-113.701153, 43.584552999057834],
            [-113.634325, 43.497473999057981],
            [-113.63523, 43.367545999058208],
            [-113.36191, 43.36715999905821],
            [-113.361958, 43.284677999058367],
            [-113.007318, 43.284777999058356],
            [-113.007712, 43.110932999058704],
            [-113.243478, 43.110945999058714],
            [-113.236943, 42.762211999059517],
            [-113.178877, 42.762354999059518],
            [-113.178633, 42.67545999905974],
            [-113.236968, 42.625253999059872],
            [-113.3044, 42.628748999059866],
            [-113.373117, 42.687637999059703],
            [-113.472177, 42.669351999059742],
            [-113.472155, 42.849200999059299],
            [-113.413138, 42.849168999059295],
            [-113.413867, 43.199858999058527],
            [-113.714642, 43.199782999058527],
            [-114.374962, 43.199639999058519],
            [-114.394606, 43.326466999058276],
            [-114.534488, 43.33338299905828],
            [-114.546546, 43.435444999058085],
            [-114.508233, 43.481511999057993],
            [-114.595698, 43.531234999057915],
            [-114.586743, 43.558825999057873],
            [-114.682355, 43.590348999057817],
            [-114.666202, 43.737961999057603],
            [-114.714648, 43.81466899905751],
            [-114.847781, 43.8163809990575],
            [-114.989258, 43.85856299905744],
            [-114.996687, 43.914406999057384]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "085",
        COUNTYNS: "00399756",
        AFFGEOID: "0500000US16085",
        GEOID: "16085",
        NAME: "Valley",
        LSAD: "06",
        ALAND: 9492623106,
        AWATER: 177058013
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.21291, 44.324372999056997],
            [-116.152295, 44.324518999056984],
            [-116.157022, 44.498927999056882],
            [-116.183571, 44.58365499905684],
            [-116.157627, 44.702026999056791],
            [-116.211211, 44.780871999056778],
            [-116.197011, 44.864215999056761],
            [-116.089118, 45.063616999056777],
            [-116.144386, 45.107650999056773],
            [-116.121662, 45.158548999056784],
            [-115.976659, 45.219222999056804],
            [-115.975787, 45.195281999056796],
            [-114.694001, 45.197181999056795],
            [-114.73227, 45.151154999056779],
            [-114.723294, 44.891176999056768],
            [-114.812407, 44.808345999056776],
            [-114.956116, 44.720981999056782],
            [-115.091922, 44.766725999056781],
            [-115.151937, 44.728591999056789],
            [-115.165735, 44.650284999056815],
            [-115.281217, 44.610922999056825],
            [-115.304994, 44.579856999056844],
            [-115.246934, 44.519378999056869],
            [-115.228222, 44.423520999056926],
            [-115.294614, 44.33944499905698],
            [-115.357484, 44.345958999056982],
            [-115.401408, 44.259622999057044],
            [-115.520287, 44.235355999057056],
            [-116.103072, 44.236408999057055],
            [-116.11249, 44.147838999057129],
            [-116.213871, 44.151386999057138],
            [-116.21291, 44.324372999056997]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "051",
        COUNTYNS: "01702370",
        AFFGEOID: "0500000US35051",
        GEOID: "35051",
        NAME: "Sierra",
        LSAD: "06",
        ALAND: 10828937018,
        AWATER: 147987561
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-108.000602, 33.478013999131463],
            [-107.712754, 33.476831999131477],
            [-106.474295, 33.475785999131489],
            [-106.372797, 33.479874999131432],
            [-106.371895, 33.39182099913257],
            [-106.34571, 33.390594999132588],
            [-106.340515, 33.052776999137009],
            [-106.887891, 32.822780999140114],
            [-106.887733, 32.779272999140694],
            [-107.299475, 32.780165999140685],
            [-107.299631, 32.605369999143072],
            [-107.608485, 32.605448999143078],
            [-107.736949, 32.629493999142746],
            [-107.721387, 32.778076999140723],
            [-107.773088, 32.853774999139695],
            [-107.780548, 32.95277599913836],
            [-107.856672, 33.002538999137684],
            [-107.836192, 33.067762999136825],
            [-107.858292, 33.200878999135064],
            [-108.000598, 33.201252999135058],
            [-108.000602, 33.478013999131463]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "049",
        COUNTYNS: "00484994",
        AFFGEOID: "0500000US20049",
        GEOID: "20049",
        NAME: "Elk",
        LSAD: "06",
        ALAND: 1668636733,
        AWATER: 16061122
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.5253, 37.60701499908761],
            [-95.961002, 37.603760999087633],
            [-95.961605, 37.386635999089471],
            [-95.964535, 37.363578999089682],
            [-95.964399, 37.299231999090239],
            [-96.524873, 37.302729999090204],
            [-96.52569, 37.476404999088707],
            [-96.5253, 37.60701499908761]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "001",
        COUNTYNS: "01702363",
        AFFGEOID: "0500000US35001",
        GEOID: "35001",
        NAME: "Bernalillo",
        LSAD: "06",
        ALAND: 3007310205,
        AWATER: 16190430
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.197244, 35.219458999110714],
            [-106.763062, 35.217993999110718],
            [-106.72899, 35.217977999110715],
            [-106.630581, 35.219739999110701],
            [-106.598725, 35.217851999110728],
            [-106.244216, 35.215656999110749],
            [-106.243874, 35.040002999112694],
            [-106.243762, 34.953354999113664],
            [-106.149828, 34.953461999113671],
            [-106.149737, 34.870141999114608],
            [-106.411781, 34.870251999114615],
            [-106.414475, 34.892224999114354],
            [-106.423996, 34.901867999114245],
            [-106.685801, 34.906357999114192],
            [-106.720666, 34.870052999114598],
            [-107.024996, 34.871017999114592],
            [-107.066562, 34.957189999113609],
            [-107.197244, 35.219458999110714]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "001",
        COUNTYNS: "00516847",
        AFFGEOID: "0500000US21001",
        GEOID: "21001",
        NAME: "Adair",
        LSAD: "06",
        ALAND: 1049678094,
        AWATER: 18430783
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.526881, 37.109449999091893],
            [-85.380258, 37.168313999091374],
            [-85.352771, 37.19243199909117],
            [-85.312291, 37.246404999090693],
            [-85.192542, 37.272138999090458],
            [-85.165174, 37.31039599909014],
            [-85.056898, 37.257040999090606],
            [-85.043316, 37.186027999091223],
            [-85.205831, 36.996861999092921],
            [-85.232026, 36.925068999093568],
            [-85.45183, 36.938137999093435],
            [-85.445337, 36.957225999093275],
            [-85.503629, 37.007679999092815],
            [-85.526881, 37.109449999091893]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "079",
        COUNTYNS: "00559460",
        AFFGEOID: "0500000US22079",
        GEOID: "22079",
        NAME: "Rapides",
        LSAD: "15",
        ALAND: 3413632347,
        AWATER: 113043381
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.947095, 31.346750999161227],
            [-92.718983, 31.517593999158667],
            [-92.692887, 31.399597999160434],
            [-92.631944, 31.390477999160574],
            [-92.196131, 31.477876999159253],
            [-92.072937, 31.345406999161245],
            [-92.086718, 31.335373999161394],
            [-92.147066, 31.301861999161904],
            [-92.22169, 31.331854999161461],
            [-92.234258, 31.131465999164497],
            [-92.208299, 30.962429999167099],
            [-92.280738, 30.96507099916705],
            [-92.383033, 31.001453999166493],
            [-92.48033, 30.952270999167254],
            [-92.508854, 30.896002999168132],
            [-92.597741, 30.896100999168119],
            [-92.622716, 30.899091999168089],
            [-92.823932, 30.889999999168218],
            [-92.829651, 31.252280999162657],
            [-92.879756, 31.317372999161666],
            [-92.981464, 31.346643999161227],
            [-92.947095, 31.346750999161227]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "001",
        COUNTYNS: "00581286",
        AFFGEOID: "0500000US23001",
        GEOID: "23001",
        NAME: "Androscoggin",
        LSAD: "06",
        ALAND: 1211961731,
        AWATER: 75575386
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.485288, 44.060397999057216],
            [-70.391735, 44.153561999057125],
            [-70.325218, 44.208398999057088],
            [-70.265223, 44.36291299905696],
            [-70.274412, 44.440631999056919],
            [-70.238904, 44.458941999056904],
            [-70.201276, 44.477975999056888],
            [-70.12939, 44.487216999056898],
            [-70.100491, 44.383853999056953],
            [-70.132595, 44.371381999056972],
            [-70.100183, 44.334520999056984],
            [-70.07426, 44.290153999057011],
            [-70.060498, 44.191774999057088],
            [-69.993792, 44.180593999057102],
            [-70.026319, 44.13383799905715],
            [-70.005471, 44.124518999057159],
            [-70.033264, 44.001343999057283],
            [-70.051824, 43.990981999057283],
            [-70.034238, 43.975660999057297],
            [-70.080839, 43.926457999057362],
            [-70.11586, 43.906709999057384],
            [-70.229244, 43.974063999057314],
            [-70.315374, 44.037806999057246],
            [-70.363874, 43.986444999057298],
            [-70.480078, 44.03207799905725],
            [-70.485288, 44.060397999057216]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "155",
        COUNTYNS: "00695798",
        AFFGEOID: "0500000US28155",
        GEOID: "28155",
        NAME: "Webster",
        LSAD: "06",
        ALAND: 1090223315,
        AWATER: 5935288
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.507135, 33.721818999128359],
            [-89.216469, 33.721978999128353],
            [-89.191781, 33.73860199912815],
            [-89.034498, 33.739429999128134],
            [-89.034924, 33.654711999129212],
            [-89.018343, 33.625118999129583],
            [-89.018613, 33.562113999130382],
            [-89.088263, 33.562038999130387],
            [-89.088446, 33.533201999130753],
            [-89.261481, 33.518627999130949],
            [-89.381092, 33.460306999131689],
            [-89.506496, 33.459805999131696],
            [-89.506872, 33.677604999128917],
            [-89.507135, 33.721818999128359]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "051",
        COUNTYNS: "00516872",
        AFFGEOID: "0500000US21051",
        GEOID: "21051",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 1215461477,
        AWATER: 4763611
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.943718, 37.250550999090656],
            [-83.901574, 37.253739999090627],
            [-83.784975, 37.34871699908981],
            [-83.653303, 37.358074999089716],
            [-83.526907, 37.258528999090586],
            [-83.507886, 37.235876999090777],
            [-83.551303, 37.167509999091394],
            [-83.553101, 37.079280999092184],
            [-83.509083, 36.938508999093436],
            [-83.589521, 36.955785999093287],
            [-83.640616, 36.959552999093248],
            [-83.774061, 37.036830999092551],
            [-83.871315, 37.054918999092394],
            [-83.885673, 37.118766999091818],
            [-83.969571, 37.168875999091377],
            [-83.943718, 37.250550999090656]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "047",
        COUNTYNS: "01796943",
        AFFGEOID: "0500000US30047",
        GEOID: "30047",
        NAME: "Lake",
        LSAD: "06",
        ALAND: 3860298044,
        AWATER: 423538790
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.604462, 47.789870999061378],
            [-114.47729, 47.789940999061379],
            [-114.49219, 47.961988999061965],
            [-114.038545, 47.96254999906197],
            [-114.078282, 48.023147999062175],
            [-114.029042, 48.05165299906227],
            [-113.884541, 48.051899999062279],
            [-113.842313, 47.978876999062024],
            [-113.747183, 47.952951999061938],
            [-113.668077, 47.89096299906172],
            [-113.6817, 47.805152999061441],
            [-113.640588, 47.719997999061164],
            [-113.603924, 47.721439999061168],
            [-113.634079, 47.600026999060788],
            [-113.948844, 47.599992999060788],
            [-113.930289, 47.59849899906078],
            [-113.908581, 47.514435999060531],
            [-113.95118, 47.475993999060421],
            [-113.87111, 47.391969999060187],
            [-113.870769, 47.288157999059898],
            [-113.821166, 47.268699999059848],
            [-113.821447, 47.181708999059616],
            [-113.933568, 47.181719999059624],
            [-113.933503, 47.138288999059505],
            [-114.18717, 47.13819099905951],
            [-114.203119, 47.181907999059625],
            [-114.203026, 47.355745999060076],
            [-114.288557, 47.355874999060077],
            [-114.35308, 47.389849999060175],
            [-114.301457, 47.497049999060479],
            [-114.383453, 47.483253999060437],
            [-114.320528, 47.594550999060772],
            [-114.58376, 47.601717999060796],
            [-114.584454, 47.746354999061246],
            [-114.604462, 47.789870999061378]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "023",
        COUNTYNS: "00758466",
        AFFGEOID: "0500000US29023",
        GEOID: "29023",
        NAME: "Butler",
        LSAD: "06",
        ALAND: 1799185018,
        AWATER: 11195137
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.678562, 36.926489999093548],
            [-90.258563, 36.922042999093584],
            [-90.204205, 36.772261999094965],
            [-90.139804, 36.696071999095686],
            [-90.148398, 36.630655999096305],
            [-90.209954, 36.542033999097143],
            [-90.220749053915398, 36.495937591292083],
            [-90.494575, 36.498367999097553],
            [-90.576179065567302, 36.498405926895551],
            [-90.592166, 36.679367999095831],
            [-90.627517, 36.8109539990946],
            [-90.662617, 36.811452999094612],
            [-90.661244, 36.882908999093949],
            [-90.678562, 36.926489999093548]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "027",
        COUNTYNS: "00929116",
        AFFGEOID: "0500000US35027",
        GEOID: "35027",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 12512530204,
        AWATER: 554703
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.37366, 33.660503999129126],
            [-106.052718, 33.650296999129253],
            [-106.052662, 33.825605999127056],
            [-105.924685, 33.82562399912706],
            [-105.925968, 34.259989999121736],
            [-105.313218, 34.260030999121732],
            [-105.313072, 34.347204999120692],
            [-104.891745, 34.347042999120696],
            [-104.893377, 34.088406999123812],
            [-104.892433, 33.398198999132497],
            [-104.88513, 33.305826999133693],
            [-104.905424, 33.138903999135877],
            [-105.316968, 33.132302999135966],
            [-105.317376, 33.305976999133691],
            [-105.725957, 33.304833999133706],
            [-105.727171, 33.390632999132592],
            [-106.34571, 33.390594999132588],
            [-106.371895, 33.39182099913257],
            [-106.372797, 33.479874999131432],
            [-106.37366, 33.660503999129126]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "065",
        COUNTYNS: "01008551",
        AFFGEOID: "0500000US37065",
        GEOID: "37065",
        NAME: "Edgecombe",
        LSAD: "06",
        ALAND: 1309062991,
        AWATER: 3717083
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.828444, 35.867207999103812],
            [-77.706966, 36.115502999101295],
            [-77.697956, 36.153149999100926],
            [-77.5407, 36.0960849991015],
            [-77.517646, 36.036774999102093],
            [-77.402614, 36.004965999102403],
            [-77.342418, 35.905261999103431],
            [-77.350369, 35.819227999104314],
            [-77.390154, 35.833061999104174],
            [-77.531262, 35.753527999104989],
            [-77.665131, 35.674934999105808],
            [-77.718436, 35.728822999105248],
            [-77.751339, 35.82779399910423],
            [-77.828444, 35.867207999103812]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "025",
        COUNTYNS: "01035623",
        AFFGEOID: "0500000US38025",
        GEOID: "38025",
        NAME: "Dunn",
        LSAD: "06",
        ALAND: 5201888569,
        AWATER: 190575791
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.099867, 47.674415999061026],
            [-102.642766, 47.673910999061022],
            [-102.642266, 47.823712999061499],
            [-102.561965, 47.780110999061364],
            [-102.385758, 47.75800799906127],
            [-102.335554, 47.801109999061424],
            [-102.262051, 47.782008999061354],
            [-102.265452, 47.624306999060856],
            [-102.20535, 47.574505999060712],
            [-102.205601, 47.32807099906001],
            [-102.144453, 47.328078999060004],
            [-102.144771, 47.010306999059203],
            [-102.525462, 46.981306999059129],
            [-103.032681, 46.980579999059117],
            [-103.032569, 47.328691999060005],
            [-103.099197, 47.328713999060021],
            [-103.099867, 47.674415999061026]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "027",
        COUNTYNS: "01214720",
        AFFGEOID: "0500000US42027",
        GEOID: "42027",
        NAME: "Centre",
        LSAD: "06",
        ALAND: 2874529780,
        AWATER: 8187277
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.376518, 40.754416999066876],
            [-78.288441, 40.849746999066426],
            [-78.24922, 40.8553829990664],
            [-78.168128, 40.960864999065905],
            [-78.07774, 40.958823999065899],
            [-78.056276, 41.004169999065695],
            [-78.112239, 41.114216999065214],
            [-78.038203, 41.153632999065032],
            [-78.003569, 41.144855999065079],
            [-77.973078, 41.204187999064821],
            [-77.898195, 41.252751999064607],
            [-77.892724, 41.178258999064944],
            [-77.79856, 41.181949999064926],
            [-77.741911, 41.107613999065244],
            [-77.621759, 41.09118499906532],
            [-77.508596, 40.9629379990659],
            [-77.339925, 40.987166999065792],
            [-77.14416, 41.044337999065519],
            [-77.279236, 40.909709999066138],
            [-77.36418, 40.846936999066422],
            [-77.652574, 40.744920999066927],
            [-77.681334, 40.729757999067004],
            [-77.824506, 40.743680999066925],
            [-77.94425, 40.691556999067174],
            [-78.116521, 40.739126999066947],
            [-78.153768, 40.74216599906692],
            [-78.359912, 40.732591999066983],
            [-78.376518, 40.754416999066876]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "017",
        COUNTYNS: "01639729",
        AFFGEOID: "0500000US47017",
        GEOID: "47017",
        NAME: "Carroll",
        LSAD: "06",
        ALAND: 1547925975,
        AWATER: 6308712
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.692709, 36.062745999101821],
            [-88.655488, 36.117337999101281],
            [-88.532233, 36.115415999101295],
            [-88.530322, 36.152022999100936],
            [-88.211698, 36.145973999100995],
            [-88.212559, 36.120289999101253],
            [-88.217436, 35.846581999104025],
            [-88.177859, 35.845840999104034],
            [-88.179177, 35.816784999104328],
            [-88.56955, 35.824450999104258],
            [-88.606862, 35.78935399910462],
            [-88.706811, 35.791074999104616],
            [-88.692709, 36.062745999101821]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "119",
        COUNTYNS: "01639772",
        AFFGEOID: "0500000US47119",
        GEOID: "47119",
        NAME: "Maury",
        LSAD: "06",
        ALAND: 1588021148,
        AWATER: 6319196
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.339232, 35.659109999105979],
            [-87.25561, 35.700515999105548],
            [-87.215099, 35.850650999103991],
            [-87.035681, 35.796212999104554],
            [-87.003959, 35.779027999104727],
            [-86.935719, 35.760658999104919],
            [-86.782016, 35.706594999105491],
            [-86.849573, 35.525514999107394],
            [-86.960804, 35.417020999108558],
            [-87.129112, 35.456018999108139],
            [-87.206578, 35.433206999108378],
            [-87.227932, 35.429331999108406],
            [-87.294534, 35.444675999108256],
            [-87.269432, 35.505850999107601],
            [-87.29719, 35.548421999107141],
            [-87.352305, 35.584620999106768],
            [-87.339232, 35.659109999105979]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "035",
        COUNTYNS: "01448031",
        AFFGEOID: "0500000US49035",
        GEOID: "49035",
        NAME: "Salt Lake",
        LSAD: "06",
        ALAND: 1921906751,
        AWATER: 164903351
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.260216, 40.769092999066814],
            [-112.006566, 40.921845999066072],
            [-111.958672, 40.921821999066083],
            [-111.946289, 40.821789999066553],
            [-111.738744, 40.860997999066363],
            [-111.663996, 40.850193999066406],
            [-111.641052, 40.798924999066664],
            [-111.649129, 40.773442999066781],
            [-111.606221, 40.712855999067074],
            [-111.604411, 40.666522999067297],
            [-111.553266, 40.609312999067576],
            [-111.593942, 40.577065999067742],
            [-111.60617, 40.564622999067808],
            [-111.913313, 40.452522999068378],
            [-111.913664, 40.452439999068382],
            [-111.982136, 40.414216999068572],
            [-112.172862, 40.467064999068299],
            [-112.175337, 40.603980999067602],
            [-112.196393, 40.62933999906749],
            [-112.201816, 40.706403999067113],
            [-112.260216, 40.769092999066814]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "029",
        COUNTYNS: "01501502",
        AFFGEOID: "0500000US51029",
        GEOID: "51029",
        NAME: "Buckingham",
        LSAD: "06",
        ALAND: 1501214582,
        AWATER: 9895206
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.82254, 37.630641999087402],
            [-78.739437, 37.631541999087403],
            [-78.677134, 37.69623999908687],
            [-78.641666, 37.683674999086982],
            [-78.643918, 37.733083999086553],
            [-78.497714, 37.763099999086322],
            [-78.491332, 37.796970999086042],
            [-78.464149, 37.76019999908636],
            [-78.239748, 37.690494999086923],
            [-78.249021, 37.63554599908737],
            [-78.465089, 37.339676999089875],
            [-78.511491, 37.329723999089964],
            [-78.594436, 37.39797399908938],
            [-78.63065, 37.384436999089488],
            [-78.635714, 37.416192999089233],
            [-78.825238, 37.552541999088071],
            [-78.82254, 37.630641999087402]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "061",
        COUNTYNS: "01529222",
        AFFGEOID: "0500000US53061",
        GEOID: "53061",
        NAME: "Snohomish",
        LSAD: "06",
        ALAND: 5404083801,
        AWATER: 282887163
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.458596, 48.297702999063191],
            [-122.201274, 48.29763399906318],
            [-121.577501, 48.298966999063197],
            [-121.001658, 48.296008999063183],
            [-120.907425, 48.163183999062689],
            [-121.015942, 48.074794999062348],
            [-121.15326, 48.039692999062233],
            [-121.118183, 47.997102999062079],
            [-121.180776, 47.898768999061737],
            [-121.070685, 47.826615999061517],
            [-121.119179, 47.779932999061359],
            [-121.991775, 47.775348999061343],
            [-122.243612, 47.77695899906135],
            [-122.271033, 47.77709499906134],
            [-122.327392, 47.777645999061356],
            [-122.438093, 47.777813999061358],
            [-122.429625, 47.828097999061505],
            [-122.398047, 47.861616999061617],
            [-122.333348, 47.93321699906187],
            [-122.350289, 48.080311999062381],
            [-122.417909, 48.17062199906271],
            [-122.392977, 48.237429999062954],
            [-122.458596, 48.297702999063191]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "015",
        COUNTYNS: "01383793",
        AFFGEOID: "0500000US48015",
        GEOID: "48015",
        NAME: "Austin",
        LSAD: "06",
        ALAND: 1674405635,
        AWATER: 25606133
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.62198, 30.044282999181728],
            [-96.292849, 30.09614999918087],
            [-96.159442, 30.053831999181565],
            [-96.146052, 30.070223999181302],
            [-96.084541, 30.005136999182366],
            [-96.13165, 29.937503999183473],
            [-96.121405, 29.836651999185136],
            [-96.049234, 29.803186999185698],
            [-96.032711, 29.727943999186955],
            [-96.02485, 29.602876999189057],
            [-96.088912, 29.601657999189065],
            [-96.10965, 29.622523999188729],
            [-96.175422, 29.633805999188532],
            [-96.259226, 29.668911999187944],
            [-96.343247, 29.829696999185263],
            [-96.413283, 29.82498499918534],
            [-96.535705, 29.907325999183978],
            [-96.569844, 29.961515999183089],
            [-96.62198, 30.044282999181728]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "033",
        COUNTYNS: "01804496",
        AFFGEOID: "0500000US72033",
        GEOID: "72033",
        NAME: "Cataño",
        LSAD: "13",
        ALAND: 12629288,
        AWATER: 5619250
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.169516, 18.430997999433405],
            [-66.129403, 18.465631999432492],
            [-66.108281, 18.438901999433199],
            [-66.13158, 18.424655999433572],
            [-66.169516, 18.430997999433405]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "059",
        COUNTYNS: "01804510",
        AFFGEOID: "0500000US72059",
        GEOID: "72059",
        NAME: "Guayanilla",
        LSAD: "13",
        ALAND: 109480387,
        AWATER: 56911386
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.85791394008578, 17.951049271127133],
            [-66.836682, 17.965970999445737],
            [-66.797557, 18.133444999441274],
            [-66.769049, 18.134982999441235],
            [-66.765131, 18.038979999443793],
            [-66.732995, 18.017493999444365],
            [-66.775358902087888, 17.984431433270945],
            [-66.784953, 17.97832599944541],
            [-66.838584, 17.949930999446163],
            [-66.85791394008578, 17.951049271127133]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "031",
        COUNTYNS: "01718557",
        AFFGEOID: "0500000US54031",
        GEOID: "54031",
        NAME: "Hardy",
        LSAD: "06",
        ALAND: 1508194693,
        AWATER: 5617193
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.134296, 38.81333999907833],
            [-79.046853, 38.92720999907754],
            [-79.089655, 39.038207999076789],
            [-78.979898, 39.237623999075467],
            [-78.623555, 39.118508999076262],
            [-78.561282, 39.131443999076168],
            [-78.508132, 39.088629999076453],
            [-78.53226618655701, 39.052764523121091],
            [-78.561711, 39.009006999076995],
            [-78.620453, 38.982600999077171],
            [-78.681617, 38.925839999077567],
            [-78.772793, 38.893741999077783],
            [-78.821167, 38.830981999078212],
            [-78.869276, 38.762990999078703],
            [-78.999014, 38.840073999078157],
            [-79.023053, 38.798612999078436],
            [-79.057253, 38.761412999078708],
            [-79.134296, 38.81333999907833]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "083",
        COUNTYNS: "01550048",
        AFFGEOID: "0500000US54083",
        GEOID: "54083",
        NAME: "Randolph",
        LSAD: "06",
        ALAND: 2692810852,
        AWATER: 667359
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.236831, 38.743764999078827],
            [-80.126105, 38.745835999078821],
            [-80.085062, 38.947235999077414],
            [-79.895993, 38.973403999077235],
            [-79.825092, 39.11580299907628],
            [-79.785685, 39.037403999076794],
            [-79.508765, 38.975172999077223],
            [-79.357666, 38.964508999077296],
            [-79.349867, 38.957508999077348],
            [-79.519474, 38.892009999077793],
            [-79.510073, 38.780711999078576],
            [-79.626774, 38.664213999079408],
            [-79.73918, 38.679612999079289],
            [-79.776483, 38.739810999078856],
            [-79.86325, 38.550819999080218],
            [-80.029208, 38.459183999080899],
            [-80.11692, 38.473952999080787],
            [-80.245518, 38.388456999081413],
            [-80.183931, 38.525385999080406],
            [-80.280059, 38.694866999079181],
            [-80.236831, 38.743764999078827]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "103",
        COUNTYNS: "01383837",
        AFFGEOID: "0500000US48103",
        GEOID: "48103",
        NAME: "Crane",
        LSAD: "06",
        ALAND: 2033319713,
        AWATER: 1695980
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.767246, 31.651713999156687],
            [-102.31805, 31.651326999156684],
            [-102.301212, 31.086211999165194],
            [-102.388804, 31.087155999165187],
            [-102.429545, 31.193556999163558],
            [-102.523063, 31.262697999162505],
            [-102.600677, 31.26709699916243],
            [-102.676104, 31.330083999161477],
            [-102.767365, 31.293802999162036],
            [-102.767246, 31.651713999156687]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "075",
        COUNTYNS: "01674271",
        AFFGEOID: "0500000US51075",
        GEOID: "51075",
        NAME: "Goochland",
        LSAD: "06",
        ALAND: 726830013,
        AWATER: 21963164
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.15302, 37.7656329990863],
            [-78.062481, 37.904684999085163],
            [-77.948222, 37.847220999085621],
            [-77.888908, 37.760807999086339],
            [-77.797306, 37.729357999086602],
            [-77.711709, 37.706284999086783],
            [-77.63067, 37.705672999086779],
            [-77.61643, 37.577554999087845],
            [-77.655399, 37.563985999087961],
            [-77.814902, 37.606529999087613],
            [-77.918866, 37.691703999086911],
            [-77.982236, 37.62427899908748],
            [-78.072174, 37.657384999087192],
            [-78.099509, 37.715349999086705],
            [-78.159326, 37.748527999086441],
            [-78.15302, 37.7656329990863]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "181",
        COUNTYNS: "01639798",
        AFFGEOID: "0500000US47181",
        GEOID: "47181",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 1901326637,
        AWATER: 4023436
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.024741, 35.392306999108818],
            [-87.97366, 35.404957999108689],
            [-88.007709, 35.423380999108488],
            [-87.988894, 35.453665999108168],
            [-87.717559, 35.483346999107845],
            [-87.645896, 35.47274499910796],
            [-87.575352, 35.398453999108753],
            [-87.588833, 35.058430999112488],
            [-87.57275, 35.049050999112595],
            [-87.60609786765329, 35.003519525317401],
            [-87.625025, 35.0037319991131],
            [-87.851886, 35.005655999113081],
            [-87.984916043698902, 35.005910123438888],
            [-87.981585, 35.295677999109863],
            [-88.024741, 35.392306999108818]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "059",
        COUNTYNS: "01383815",
        AFFGEOID: "0500000US48059",
        GEOID: "48059",
        NAME: "Callahan",
        LSAD: "06",
        ALAND: 2329363248,
        AWATER: 4898783
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.629579, 32.514656999144329],
            [-99.612026, 32.514653999144329],
            [-99.114293, 32.514810999144345],
            [-99.118665, 32.079584999150455],
            [-99.195871, 32.079234999150458],
            [-99.631413, 32.08127399915044],
            [-99.629579, 32.514656999144329]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "087",
        COUNTYNS: "01622986",
        AFFGEOID: "0500000US26087",
        GEOID: "26087",
        NAME: "Lapeer",
        LSAD: "06",
        ALAND: 1668448345,
        AWATER: 48310232
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.460708, 43.232790999058466],
            [-83.351709, 43.235192999058448],
            [-83.354418, 43.32225699905829],
            [-83.120396, 43.327048999058277],
            [-83.119027, 43.283679999058364],
            [-83.00043, 43.284912999058363],
            [-82.996257, 43.154098999058604],
            [-82.983647, 42.893740999059197],
            [-83.102891, 42.888646999059205],
            [-83.453364, 42.880431999059233],
            [-83.460733, 43.223130999058469],
            [-83.460708, 43.232790999058466]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "049",
        COUNTYNS: "00450353",
        AFFGEOID: "0500000US18049",
        GEOID: "18049",
        NAME: "Fulton",
        LSAD: "06",
        ALAND: 954146361,
        AWATER: 7426329
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.467046, 41.17144199906496],
            [-86.077551, 41.172867999064955],
            [-86.075939, 41.084877999065341],
            [-85.945561, 41.042875999065537],
            [-85.946436, 40.999194999065729],
            [-86.168459, 40.996096999065749],
            [-86.169012, 40.909835999066132],
            [-86.468509, 40.909888999066126],
            [-86.467046, 41.17144199906496]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "037",
        COUNTYNS: "00659464",
        AFFGEOID: "0500000US27037",
        GEOID: "27037",
        NAME: "Dakota",
        LSAD: "06",
        ALAND: 1456715603,
        AWATER: 63149548
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.329615, 44.791085999056769],
            [-93.251873, 44.811475999056775],
            [-93.19794, 44.861999999056763],
            [-93.178539, 44.888337999056773],
            [-93.09607, 44.923286999056764],
            [-93.020044, 44.890749999056766],
            [-93.010032, 44.845988999056765],
            [-93.015961, 44.775563999056772],
            [-92.792360799670504, 44.75898381275389],
            [-92.731623851303198, 44.7149227843164],
            [-92.732043, 44.629492999056815],
            [-92.792584, 44.629721999056827],
            [-92.792715, 44.543239999056858],
            [-93.039344, 44.515349999056873],
            [-93.039485, 44.47187099905689],
            [-93.281686, 44.471997999056889],
            [-93.281521, 44.54395699905686],
            [-93.278169, 44.630750999056822],
            [-93.318731, 44.630577999056818],
            [-93.318157, 44.717535999056793],
            [-93.329615, 44.791085999056769]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "113",
        COUNTYNS: "00659502",
        AFFGEOID: "0500000US27113",
        GEOID: "27113",
        NAME: "Pennington",
        LSAD: "06",
        ALAND: 1596928760,
        AWATER: 4332057
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.500843, 48.17410799906272],
            [-95.593788, 48.172915999062717],
            [-95.592287, 48.037358999062221],
            [-95.582887, 48.020556999062158],
            [-95.582155, 47.933965999061861],
            [-95.709619, 47.935804999061865],
            [-95.70984, 47.964329999061967],
            [-96.48255, 47.963594999061968],
            [-96.500901, 48.021655999062183],
            [-96.500843, 48.17410799906272]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "091",
        COUNTYNS: "00465234",
        AFFGEOID: "0500000US19091",
        GEOID: "19091",
        NAME: "Humboldt",
        LSAD: "06",
        ALAND: 1124996886,
        AWATER: 3376514
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.442954, 42.908072999059165],
            [-93.971238, 42.907761999059161],
            [-93.971714, 42.644706999059814],
            [-94.443083, 42.645163999059825],
            [-94.442954, 42.908072999059165]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "021",
        COUNTYNS: "00659456",
        AFFGEOID: "0500000US27021",
        GEOID: "27021",
        NAME: "Cass",
        LSAD: "06",
        ALAND: 5235576788,
        AWATER: 1016652167
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.787126, 46.803942999058734],
            [-94.659942, 46.803715999058738],
            [-94.670214, 47.410150999060235],
            [-94.416387, 47.411829999060231],
            [-94.416279, 47.444827999060337],
            [-94.327531, 47.41584399906025],
            [-94.095115, 47.477971999060436],
            [-94.027858, 47.408632999060224],
            [-94.042287, 47.373111999060121],
            [-93.911505, 47.297867999059932],
            [-93.888213, 47.318855999059984],
            [-93.77401, 47.305059999059935],
            [-93.80882, 47.232621999059752],
            [-93.77393, 47.211334999059694],
            [-93.775257, 47.030417999059239],
            [-93.776024, 46.802890999058725],
            [-94.34291, 46.805528999058737],
            [-94.330936, 46.631207999058375],
            [-94.341679, 46.277704999057761],
            [-94.426529, 46.332335999057854],
            [-94.534724, 46.302796999057811],
            [-94.653462, 46.348677999057877],
            [-94.717126, 46.351367999057899],
            [-94.731442, 46.368604999057915],
            [-94.779121, 46.394214999057951],
            [-94.787126, 46.803942999058734]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "039",
        COUNTYNS: "01135855",
        AFFGEOID: "0500000US41039",
        GEOID: "41039",
        NAME: "Lane",
        LSAD: "06",
        ALAND: 11799215321,
        AWATER: 437134106
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.150267, 43.910849999057383],
            [-124.122406, 44.104441999057173],
            [-124.111054, 44.235070999057058],
            [-124.114370074258005, 44.276193999057028],
            [-123.961647, 44.277375999057021],
            [-123.941075, 44.282950999057022],
            [-123.775598, 44.283546999057023],
            [-123.593323, 44.276693999057031],
            [-123.180388, 44.283724999057029],
            [-123.165428, 44.20007099905709],
            [-122.905755, 44.200735999057081],
            [-122.903999, 44.259128999057047],
            [-122.865465, 44.287316999057005],
            [-122.762735, 44.290536999057018],
            [-122.646812, 44.266617999057033],
            [-122.577019, 44.227880999057071],
            [-122.384561, 44.21706299905707],
            [-122.32715, 44.252840999057042],
            [-122.029136, 44.25384399905704],
            [-121.819429, 44.262393999057039],
            [-121.769005, 44.102034999057196],
            [-121.857063, 43.965247999057318],
            [-121.869974, 43.911663999057382],
            [-121.928213, 43.909180999057391],
            [-121.975113, 43.857569999057446],
            [-121.962098, 43.763536999057585],
            [-121.986267, 43.661707999057718],
            [-121.964918, 43.62704499905778],
            [-122.002362, 43.615497999057787],
            [-122.131009, 43.55728399905788],
            [-122.132034, 43.440220999058084],
            [-122.741716, 43.437394999058071],
            [-122.741845, 43.544653999057921],
            [-123.107686, 43.540009999057901],
            [-123.137211, 43.605905999057796],
            [-123.137319, 43.779669999057553],
            [-123.348246, 43.780169999057556],
            [-123.3482, 43.80921199905751],
            [-123.470371, 43.810055999057504],
            [-123.579782, 43.868289999057431],
            [-123.619012, 43.921116999057382],
            [-123.7038, 43.944318999057352],
            [-123.827004, 43.944836999057351],
            [-123.946291, 43.862681999057443],
            [-124.160207315876008, 43.863720500579049],
            [-124.150267, 43.910849999057383]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "095",
        COUNTYNS: "00695771",
        AFFGEOID: "0500000US28095",
        GEOID: "28095",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 1981580823,
        AWATER: 18138022
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.719979, 33.811329999127238],
            [-88.718964, 34.074520999123983],
            [-88.712454, 34.089240999123795],
            [-88.543352, 34.08904399912381],
            [-88.203583495219377, 34.086527730591932],
            [-88.207229, 34.058332999124183],
            [-88.248387473909489, 33.74490759400976],
            [-88.325119, 33.745590999128062],
            [-88.40656, 33.673341999128965],
            [-88.513917, 33.650208999129262],
            [-88.513933, 33.650437999129267],
            [-88.513955, 33.650610999129249],
            [-88.513987, 33.650856999129253],
            [-88.514315, 33.651804999129247],
            [-88.514482, 33.653029999129231],
            [-88.514421, 33.653631999129239],
            [-88.513938, 33.65448499912921],
            [-88.513698, 33.655700999129195],
            [-88.513608, 33.656651999129181],
            [-88.513574, 33.657012999129179],
            [-88.513515, 33.657957999129174],
            [-88.513339, 33.659310999129147],
            [-88.513104, 33.660696999129129],
            [-88.512655, 33.661234999129121],
            [-88.512048, 33.66152799912912],
            [-88.511344, 33.661690999129121],
            [-88.510085, 33.661815999129125],
            [-88.509407, 33.661959999129117],
            [-88.508841, 33.662195999129125],
            [-88.507481, 33.663165999129106],
            [-88.506533, 33.664046999129091],
            [-88.505731, 33.664640999129084],
            [-88.503687, 33.665480999129073],
            [-88.503169, 33.66545699912907],
            [-88.502378, 33.665684999129056],
            [-88.502126, 33.665846999129066],
            [-88.501928, 33.666187999129065],
            [-88.502007, 33.667264999129053],
            [-88.502207, 33.668138999129042],
            [-88.502394, 33.668879999129032],
            [-88.502407, 33.668946999129027],
            [-88.502386, 33.66919599912903],
            [-88.502292, 33.670322999128999],
            [-88.502121, 33.671300999129009],
            [-88.501837, 33.672478999128984],
            [-88.501594, 33.673352999128973],
            [-88.501439, 33.673633999128974],
            [-88.501191, 33.674058999128967],
            [-88.501129, 33.674112999128965],
            [-88.500465, 33.674697999128952],
            [-88.500043, 33.674992999128953],
            [-88.499987, 33.675029999128952],
            [-88.499931, 33.675066999128951],
            [-88.499803, 33.675142999128944],
            [-88.499725, 33.675190999128951],
            [-88.499372, 33.675219999128934],
            [-88.49922, 33.675233999128942],
            [-88.498893, 33.67528999912895],
            [-88.498369, 33.675312999128941],
            [-88.497793, 33.675272999128943],
            [-88.497142, 33.675086999128951],
            [-88.496904, 33.674957999128949],
            [-88.496583, 33.674776999128945],
            [-88.496161, 33.674524999128948],
            [-88.495782, 33.674106999128959],
            [-88.495262, 33.673695999128967],
            [-88.495228, 33.673668999128971],
            [-88.494976, 33.673418999128955],
            [-88.494698, 33.673105999128971],
            [-88.49447, 33.672813999128977],
            [-88.494217, 33.67233299912899],
            [-88.493881, 33.671853999128977],
            [-88.493721, 33.67169699912899],
            [-88.493554, 33.671541999128983],
            [-88.493437, 33.67145199912899],
            [-88.493181, 33.671247999129001],
            [-88.492906, 33.671247999129001],
            [-88.492484, 33.671542999128995],
            [-88.492211, 33.671794999128984],
            [-88.49179, 33.672194999128976],
            [-88.49156, 33.672521999128989],
            [-88.49142, 33.672719999128979],
            [-88.491087, 33.673213999128976],
            [-88.490978, 33.673725999128955],
            [-88.501915, 33.679947999128885],
            [-88.719626, 33.680252999128882],
            [-88.719979, 33.811329999127238]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "079",
        COUNTYNS: "01008557",
        AFFGEOID: "0500000US37079",
        GEOID: "37079",
        NAME: "Greene",
        LSAD: "06",
        ALAND: 690851081,
        AWATER: 1217389
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.822511, 35.585382999106763],
            [-77.801777, 35.586204999106755],
            [-77.700692, 35.652294999106054],
            [-77.61037, 35.567551999106954],
            [-77.503716, 35.51812399910748],
            [-77.475515, 35.426654999108457],
            [-77.676719, 35.347343999109313],
            [-77.697516, 35.377096999108979],
            [-77.806248, 35.368753999109074],
            [-77.826744, 35.424089999108475],
            [-77.822511, 35.585382999106763]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "235",
        COUNTYNS: "00516964",
        AFFGEOID: "0500000US21235",
        GEOID: "21235",
        NAME: "Whitley",
        LSAD: "06",
        ALAND: 1133976430,
        AWATER: 18875138
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.318033, 36.833762999094397],
            [-84.297416, 36.94593099909337],
            [-84.25772, 36.972844999093134],
            [-84.183131, 36.939368999093439],
            [-84.091853, 36.955991999093278],
            [-84.065833, 36.854146999094212],
            [-83.983674, 36.800838999094708],
            [-83.958534, 36.727244999095376],
            [-83.877503, 36.687258999095768],
            [-83.956319, 36.645432999096151],
            [-83.930760783943896, 36.587694257209094],
            [-83.987610950682793, 36.589592285169182],
            [-83.987842, 36.589599999096684],
            [-84.227193961998296, 36.592179511451761],
            [-84.307598, 36.676808999095861],
            [-84.355967, 36.811018999094614],
            [-84.318033, 36.833762999094397]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "223",
        COUNTYNS: "00349912",
        AFFGEOID: "0500000US13223",
        GEOID: "13223",
        NAME: "Paulding",
        LSAD: "06",
        ALAND: 808831878,
        AWATER: 5309332
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.049833, 33.952634999125479],
            [-84.978683, 33.951392999125488],
            [-84.922742, 34.082496999123883],
            [-84.910041, 34.075296999123971],
            [-84.737836, 34.079398999123917],
            [-84.739636, 33.947601999125546],
            [-84.72423, 33.90359599912609],
            [-84.724139, 33.806169999127299],
            [-84.725477, 33.788578999127523],
            [-84.901688, 33.780702999127612],
            [-84.971203, 33.79961699912738],
            [-85.037926, 33.811941999127228],
            [-85.036861, 33.89258699912623],
            [-85.05031, 33.904487999126083],
            [-85.049833, 33.952634999125479]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "045",
        COUNTYNS: "00835844",
        AFFGEOID: "0500000US31045",
        GEOID: "31045",
        NAME: "Dawes",
        LSAD: "06",
        ALAND: 3616823834,
        AWATER: 11921995
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.505099261631997, 43.000758812090147],
            [-103.476133, 43.000767999058951],
            [-103.000609526033003, 43.000259285833359],
            [-102.792110939839006, 43.000036234829345],
            [-102.773369, 42.78526499905945],
            [-102.773366, 42.439921999060381],
            [-103.444532, 42.437353999060392],
            [-103.480082, 42.437266999060398],
            [-103.480403, 42.784965999059466],
            [-103.505099261631997, 43.000758812090147]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "009",
        COUNTYNS: "01676636",
        AFFGEOID: "0500000US24009",
        GEOID: "24009",
        NAME: "Calvert",
        LSAD: "06",
        ALAND: 552178309,
        AWATER: 341560885
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.686358, 38.748474999078802],
            [-76.642749, 38.769280999078653],
            [-76.615902, 38.720803999078989],
            [-76.52709254739419, 38.71275030031736],
            [-76.528923, 38.663888999079397],
            [-76.511278, 38.615744999079745],
            [-76.517506, 38.539148999080304],
            [-76.492699, 38.482848999080723],
            [-76.450937, 38.442421999081027],
            [-76.393378, 38.389476999081417],
            [-76.387002, 38.361266999081636],
            [-76.400194624698003, 38.319871943043331],
            [-76.453453, 38.305056999082041],
            [-76.501475, 38.372496999081534],
            [-76.608373, 38.424444999081153],
            [-76.674118, 38.499621999080588],
            [-76.675457, 38.535875999080332],
            [-76.70196, 38.710863999079066],
            [-76.686358, 38.748474999078802]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "023",
        COUNTYNS: "00040472",
        AFFGEOID: "0500000US04023",
        GEOID: "04023",
        NAME: "Santa Cruz",
        LSAD: "06",
        ALAND: 3203613249,
        AWATER: 3088046
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.366613, 31.521290999158609],
            [-111.163725, 31.521825999158608],
            [-111.164183, 31.72598299915558],
            [-111.05804, 31.726229999155581],
            [-110.451736, 31.731266999155505],
            [-110.460564, 31.614036999157236],
            [-110.460172610445014, 31.333141378153034],
            [-111.074825, 31.33223899916144],
            [-111.366969371164004, 31.424824286351654],
            [-111.366613, 31.521290999158609]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "003",
        COUNTYNS: "00063756",
        AFFGEOID: "0500000US05003",
        GEOID: "05003",
        NAME: "Ashley",
        LSAD: "06",
        ALAND: 2396650149,
        AWATER: 39309860
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.137527, 33.162955999135569],
            [-92.128464, 33.208774999134953],
            [-91.977284, 33.30202599913374],
            [-91.990167, 33.398213999132487],
            [-91.45737, 33.38897399913261],
            [-91.460391771831908, 33.005997054839149],
            [-91.489176, 33.006181999137638],
            [-91.875128, 33.007727999137622],
            [-92.069103758561283, 33.008482262837212],
            [-92.069146592481289, 33.008482429394505],
            [-92.143596, 33.09824199913642],
            [-92.137527, 33.162955999135569]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "089",
        COUNTYNS: "00069898",
        AFFGEOID: "0500000US05089",
        GEOID: "05089",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 1546109507,
        AWATER: 112285682
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.884445, 36.490712999097632],
            [-92.854049114984889, 36.498023380239133],
            [-92.838876, 36.498032999097553],
            [-92.772333893372092, 36.498083153176964],
            [-92.564238, 36.498239999097557],
            [-92.529136581043687, 36.498165682082856],
            [-92.533175, 36.386562999098629],
            [-92.594962, 36.354691999098939],
            [-92.575141, 36.309332999099389],
            [-92.469262, 36.250903999099961],
            [-92.480306, 36.199369999100455],
            [-92.411852, 36.162122999100831],
            [-92.411502, 36.062755999101839],
            [-92.836531, 36.070064999101753],
            [-92.836859, 36.113740999101317],
            [-92.890695, 36.114131999101325],
            [-92.884445, 36.490712999097632]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "09",
        COUNTYFP: "013",
        COUNTYNS: "00212668",
        AFFGEOID: "0500000US09013",
        GEOID: "09013",
        NAME: "Tolland",
        LSAD: "06",
        ALAND: 1062807475,
        AWATER: 17549693
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.51333, 41.945400999061953],
            [-72.492729, 41.947496999061933],
            [-72.509179336298303, 42.034080495651757],
            [-72.397476255195897, 42.032816190197458],
            [-72.317148, 42.031906999061654],
            [-72.198845978131999, 42.030103892343668],
            [-72.135731461946691, 42.029141929003266],
            [-72.102159434526797, 42.028630239116474],
            [-72.099229, 41.958947999061913],
            [-72.151654, 41.959301999061921],
            [-72.21698, 41.958160999061903],
            [-72.164498, 41.83491299906234],
            [-72.156719, 41.753718999062635],
            [-72.239559, 41.714190999062779],
            [-72.334226, 41.64359799906304],
            [-72.328095, 41.605470999063186],
            [-72.410367, 41.6025809990632],
            [-72.409164, 41.6303499990631],
            [-72.463899, 41.745571999062655],
            [-72.49681, 41.860615999062254],
            [-72.51733, 41.86989699906222],
            [-72.51333, 41.945400999061953]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "083",
        COUNTYNS: "00198157",
        AFFGEOID: "0500000US08083",
        GEOID: "08083",
        NAME: "Montezuma",
        LSAD: "06",
        ALAND: 5255937110,
        AWATER: 27261088
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.043780720502994, 37.484819230506147],
            [-108.917081, 37.631901999087404],
            [-108.250635, 37.632846999087391],
            [-107.970086, 37.639586999087335],
            [-108.022895, 37.590586999087755],
            [-108.038409, 37.451930999088908],
            [-108.105857, 37.382067999089514],
            [-108.197776, 37.354938999089761],
            [-108.219688, 37.287414999090338],
            [-108.290423, 37.223583999090891],
            [-108.290459, 37.145974999091578],
            [-108.379302950094001, 36.999564685404394],
            [-108.620309, 36.999286999092888],
            [-109.045223, 36.999083999092889],
            [-109.043780720502994, 37.484819230506147]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "213",
        COUNTYNS: "01688005",
        AFFGEOID: "0500000US13213",
        GEOID: "13213",
        NAME: "Murray",
        LSAD: "06",
        ALAND: 892235146,
        AWATER: 5719645
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.94434, 34.676867999116823],
            [-84.864945, 34.707355999116466],
            [-84.878499, 34.779609999115628],
            [-84.818715, 34.876949999114537],
            [-84.810477466886397, 34.987877954342466],
            [-84.775837671297296, 34.987937205262568],
            [-84.727434, 34.988019999113277],
            [-84.621483, 34.988328999113271],
            [-84.622731, 34.875076999114555],
            [-84.618637, 34.855397999114778],
            [-84.627348, 34.784727999115589],
            [-84.657098, 34.728904999116217],
            [-84.654523, 34.583186999117906],
            [-84.715692, 34.62287599911744],
            [-84.863793, 34.608596999117609],
            [-84.913456, 34.634127999117311],
            [-84.94434, 34.676867999116823]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "131",
        COUNTYNS: "01784750",
        AFFGEOID: "0500000US17131",
        GEOID: "17131",
        NAME: "Mercer",
        LSAD: "06",
        ALAND: 1453500381,
        AWATER: 19409929
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.114186, 41.250028999064625],
            [-91.074415498019604, 41.333631568104977],
            [-91.074087623674089, 41.334320800984784],
            [-90.433771, 41.326982999064306],
            [-90.437657, 41.151251999065046],
            [-90.439423, 41.063994999065436],
            [-90.785194, 41.068748999065413],
            [-90.951894142681283, 41.069872734931799],
            [-90.952265416482192, 41.072731748735407],
            [-90.957246, 41.11108499906522],
            [-90.997906, 41.162563999065007],
            [-91.041536, 41.166137999064979],
            [-91.081445, 41.214428999064779],
            [-91.114186, 41.250028999064625]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "085",
        COUNTYNS: "00424244",
        AFFGEOID: "0500000US17085",
        GEOID: "17085",
        NAME: "Jo Daviess",
        LSAD: "06",
        ALAND: 1556339187,
        AWATER: 45776828
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.642843, 42.508480999060197],
            [-90.437011, 42.50714699906019],
            [-90.426376960736093, 42.5071777342839],
            [-90.22319, 42.507764999060193],
            [-89.927006876764011, 42.50579048483479],
            [-89.91965, 42.196823999061124],
            [-90.315696514271508, 42.193946431312526],
            [-90.338169, 42.203320999061091],
            [-90.400653, 42.239292999060972],
            [-90.430884, 42.278229999060869],
            [-90.417125, 42.319942999060736],
            [-90.44632, 42.357040999060636],
            [-90.484345672716287, 42.381597777252459],
            [-90.517516, 42.4030189990605],
            [-90.565248, 42.43874199906039],
            [-90.590416, 42.447492999060366],
            [-90.646727, 42.471903999060281],
            [-90.642843, 42.508480999060197]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "017",
        COUNTYNS: "00558458",
        AFFGEOID: "0500000US22017",
        GEOID: "22017",
        NAME: "Caddo",
        LSAD: "15",
        ALAND: 2275369596,
        AWATER: 150398853
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.043051527617592, 32.69302997580747],
            [-94.043026, 32.797475999140453],
            [-94.043002621563303, 32.881088978063318],
            [-94.042964, 33.019218999137472],
            [-93.814553013433795, 33.019389044008257],
            [-93.842597, 32.946763999138433],
            [-93.785181, 32.857352999139636],
            [-93.824253, 32.79245099914052],
            [-93.783233, 32.784359999140619],
            [-93.819169, 32.736001999141294],
            [-93.782111, 32.712211999141608],
            [-93.739474, 32.590772999143276],
            [-93.767156, 32.538041999144006],
            [-93.680334, 32.462886999145049],
            [-93.685569, 32.395497999145995],
            [-93.659041, 32.406057999145837],
            [-93.61513, 32.348331999146652],
            [-93.555323, 32.331422999146895],
            [-93.539354, 32.289919999147465],
            [-93.471249, 32.237185999148217],
            [-93.61469, 32.237525999148204],
            [-93.666472, 32.317443999147095],
            [-93.764513, 32.345559999146687],
            [-93.951085, 32.195544999148801],
            [-94.042695923740098, 32.1960049739737],
            [-94.042739, 32.363558999146434],
            [-94.042788232805307, 32.392283192427229],
            [-94.043083, 32.564260999143649],
            [-94.043051527617592, 32.69302997580747]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "121",
        COUNTYNS: "01623003",
        AFFGEOID: "0500000US26121",
        GEOID: "26121",
        NAME: "Muskegon",
        LSAD: "06",
        ALAND: 1298494539,
        AWATER: 2482949902
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.463516886681091, 43.472329698564714],
            [-86.039491, 43.467446999058026],
            [-86.038953, 43.293518999058342],
            [-85.790448, 43.293002999058352],
            [-85.790662, 43.205166999058513],
            [-85.908388, 43.205919999058509],
            [-85.907435, 43.118910999058691],
            [-86.273928278671391, 43.118367967766495],
            [-86.316259, 43.195113999058535],
            [-86.407832, 43.338435999058262],
            [-86.448743, 43.432012999058095],
            [-86.463516886681091, 43.472329698564714]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "131",
        COUNTYNS: "01623008",
        AFFGEOID: "0500000US26131",
        GEOID: "26131",
        NAME: "Ontonagon",
        LSAD: "06",
        ALAND: 3395416191,
        AWATER: 6292279421
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.884329580411489, 46.765471148335656],
            [-89.831956, 46.804052999058726],
            [-89.720277, 46.830412999058794],
            [-89.642255, 46.82533999905877],
            [-89.569808, 46.831858999058788],
            [-89.49908, 46.841620999058811],
            [-89.415154, 46.843982999058802],
            [-89.227914, 46.912953999058971],
            [-89.142595, 46.984858999059135],
            [-89.02893, 47.001139999059177],
            [-88.959409, 47.008495999059186],
            [-88.933368451341707, 47.033599096915253],
            [-88.93248, 46.764786999058657],
            [-88.863906, 46.765012999058648],
            [-88.864473, 46.67931099905848],
            [-88.989529, 46.680118999058486],
            [-88.991236, 46.419703999058001],
            [-88.991012, 46.332307999057853],
            [-89.365507, 46.333077999057856],
            [-89.365094, 46.505948999058155],
            [-89.740568, 46.505641999058142],
            [-89.740001, 46.592493999058298],
            [-89.86442, 46.592642999058306],
            [-89.863793, 46.765404999058653],
            [-89.884329580411489, 46.765471148335656]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "081",
        COUNTYNS: "01719582",
        AFFGEOID: "0500000US30081",
        GEOID: "30081",
        NAME: "Ravalli",
        LSAD: "06",
        ALAND: 6192612337,
        AWATER: 24251907
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.517143, 45.835992999057211],
            [-114.422963, 45.855380999057225],
            [-114.388243, 45.882339999057258],
            [-114.413168, 45.911478999057294],
            [-114.402261, 45.961488999057337],
            [-114.441185, 45.988452999057372],
            [-114.480241, 46.030324999057427],
            [-114.460049, 46.097103999057509],
            [-114.5213, 46.12528699905755],
            [-114.514706, 46.167725999057616],
            [-114.445928, 46.173932999057627],
            [-114.449819, 46.237118999057721],
            [-114.441326, 46.273799999057765],
            [-114.431708, 46.310743999057827],
            [-114.422458, 46.387096999057945],
            [-114.384756, 46.411783999057988],
            [-114.403019, 46.498674999058139],
            [-114.351655, 46.508118999058148],
            [-114.331338, 46.577780999058277],
            [-114.320665, 46.64696299905841],
            [-114.335724935404997, 46.655272966412028],
            [-114.317808, 46.660550999058437],
            [-113.827743, 46.66089599905844],
            [-113.811703, 46.589069999058303],
            [-113.874651, 46.508328999058143],
            [-113.849218, 46.440977999058049],
            [-113.782032, 46.359517999057907],
            [-113.785795, 46.2880769990578],
            [-113.750527, 46.27214599905777],
            [-113.821174, 46.212465999057663],
            [-113.753246, 46.11279999905755],
            [-113.803677, 46.037674999057451],
            [-113.687673, 46.035405999057438],
            [-113.648358, 45.965037999057358],
            [-113.517409, 45.94008899905733],
            [-113.516144, 45.938813999057324],
            [-113.528581, 45.881159999057246],
            [-113.697639, 45.843544999057215],
            [-113.829875, 45.750745999057131],
            [-113.870452, 45.781573999057166],
            [-113.948321482569995, 45.682577357498573],
            [-113.971565, 45.700635999057084],
            [-114.015633, 45.696126999057071],
            [-114.014973, 45.65400799905705],
            [-114.083149, 45.603995999057005],
            [-114.18647, 45.545538999056959],
            [-114.251836, 45.537811999056949],
            [-114.279217, 45.480615999056916],
            [-114.36852, 45.492715999056927],
            [-114.456764, 45.543982999056972],
            [-114.506341, 45.559215999056974],
            [-114.523773044931005, 45.585326505663581],
            [-114.538132, 45.606833999057002],
            [-114.53577, 45.650612999057046],
            [-114.499637, 45.669034999057061],
            [-114.504869, 45.722175999057107],
            [-114.562509, 45.779926999057167],
            [-114.517143, 45.835992999057211]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "039",
        COUNTYNS: "01702368",
        AFFGEOID: "0500000US35039",
        GEOID: "35039",
        NAME: "Rio Arriba",
        LSAD: "06",
        ALAND: 15179716552,
        AWATER: 91476917
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.618181, 36.804266999094665],
            [-107.597983, 36.8343019990944],
            [-107.451445, 36.904035999093757],
            [-107.457159, 36.954963999093287],
            [-107.420921905745004, 37.000004999031482],
            [-107.420913, 37.000004999092887],
            [-106.877292, 37.000138999092883],
            [-106.869796, 36.992425999092958],
            [-106.476233588828009, 36.993774099639843],
            [-106.343139, 36.994229999092937],
            [-106.006316410798007, 36.995386627910221],
            [-105.971889, 36.88624699909392],
            [-105.981088, 36.713444999095522],
            [-105.938754, 36.462401999097892],
            [-105.9598, 36.354788999098936],
            [-106.038312, 36.325660999099227],
            [-106.058364, 36.296977999099511],
            [-105.856947, 36.230433999100157],
            [-105.735883, 36.100132999101461],
            [-105.53038, 36.013013999102341],
            [-105.568045, 35.973795999102734],
            [-105.718611, 35.979462999102672],
            [-105.716808, 36.00231999910244],
            [-106.071153, 36.00157299910245],
            [-106.054346, 35.930782999103165],
            [-106.245649, 35.930741999103162],
            [-106.248161, 35.966326999102812],
            [-106.247164, 36.001433999102446],
            [-106.885317, 35.998968999102473],
            [-106.885053, 36.216569999100301],
            [-107.624283, 36.219808999100273],
            [-107.618181, 36.804266999094665]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "055",
        COUNTYNS: "00974126",
        AFFGEOID: "0500000US36055",
        GEOID: "36055",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 1702151431,
        AWATER: 1837595092
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.995698, 43.284962999058358],
            [-77.995589794938397, 43.365310254735817],
            [-77.994838, 43.365258999058199],
            [-77.816533, 43.343559999058243],
            [-77.808263409607193, 43.343207635956553],
            [-77.760231, 43.341160999058268],
            [-77.660359, 43.28299799905836],
            [-77.551022, 43.235762999058451],
            [-77.50092, 43.250362999058424],
            [-77.376051995953375, 43.274033762545379],
            [-77.371478, 43.034695999058883],
            [-77.485418, 43.034563999058868],
            [-77.482517, 42.943163999059081],
            [-77.580377, 42.943962999059075],
            [-77.759052, 42.940461999059082],
            [-77.730957, 42.98837199905897],
            [-77.909832, 42.987761999058975],
            [-77.904121, 43.037055999058879],
            [-77.905934, 43.133560999058659],
            [-77.99729, 43.132980999058667],
            [-77.995698, 43.284962999058358]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "009",
        COUNTYNS: "01034227",
        AFFGEOID: "0500000US38009",
        GEOID: "38009",
        NAME: "Bottineau",
        LSAD: "06",
        ALAND: 4321196493,
        AWATER: 74864998
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.496737, 48.999144833071455],
            [-101.125434, 48.999077999066152],
            [-100.431676, 48.999397999066161],
            [-100.182706982328, 48.99923022370745],
            [-100.183034, 48.718908999064908],
            [-100.145494, 48.718874999064901],
            [-100.145858, 48.54521099906416],
            [-100.27666, 48.54481299906417],
            [-100.406388, 48.544656999064166],
            [-100.40678, 48.631877999064528],
            [-101.059624, 48.632423999064528],
            [-101.05966, 48.545336999064169],
            [-101.451275, 48.546078999064171],
            [-101.496684, 48.720077999064912],
            [-101.496737, 48.999144833071455]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "035",
        COUNTYNS: "01101805",
        AFFGEOID: "0500000US40035",
        GEOID: "40035",
        NAME: "Craig",
        LSAD: "06",
        ALAND: 1971897017,
        AWATER: 3697828
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.431731, 36.942775999093399],
            [-95.407620005877092, 36.999341735731988],
            [-95.322565, 36.999357999092901],
            [-95.073503966630298, 36.999488133771983],
            [-95.007620004472088, 36.999522558218693],
            [-95.034123, 36.994224999092935],
            [-94.999153, 36.943237999093405],
            [-94.999403, 36.670630999095927],
            [-95.00569, 36.597659999096599],
            [-95.00573, 36.509889999097446],
            [-95.32817, 36.510239999097436],
            [-95.32837, 36.597629999096604],
            [-95.43157, 36.597539999096597],
            [-95.431731, 36.942775999093399]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "009",
        COUNTYNS: "01135847",
        AFFGEOID: "0500000US41009",
        GEOID: "41009",
        NAME: "Columbia",
        LSAD: "06",
        ALAND: 1704178258,
        AWATER: 80260317
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.363744507266006, 46.146243193406086],
            [-123.280166, 46.144842999057573],
            [-123.212493591208982, 46.171096457457914],
            [-123.166414, 46.188972999057633],
            [-123.115904, 46.18526799905765],
            [-123.004233, 46.133822999057557],
            [-122.962681, 46.104816999057526],
            [-122.904119, 46.0837339990575],
            [-122.856158, 46.014468999057407],
            [-122.813998, 45.960983999057341],
            [-122.81151, 45.912724999057282],
            [-122.785026, 45.867698999057247],
            [-122.788086362594996, 45.851007453413317],
            [-122.795605, 45.809999999057197],
            [-122.761451, 45.759162999057139],
            [-122.76651077132999, 45.728662573502305],
            [-122.784735, 45.720945999057115],
            [-122.929146, 45.721481999057104],
            [-123.030873, 45.779158999057145],
            [-123.361622, 45.779578999057165],
            [-123.363744507266006, 46.146243193406086]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "071",
        COUNTYNS: "01383821",
        AFFGEOID: "0500000US48071",
        GEOID: "48071",
        NAME: "Chambers",
        LSAD: "06",
        ALAND: 1546618338,
        AWATER: 709745135
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.015039, 29.631949999188571],
            [-94.913813, 29.766299999186323],
            [-94.908663, 29.826069999185322],
            [-94.98146, 29.884275999184357],
            [-94.442235, 29.889537999184267],
            [-94.357976, 29.887463999184298],
            [-94.354122544913693, 29.562102819364537],
            [-94.370816, 29.555645987484851],
            [-94.422868, 29.566255999189668],
            [-94.545908, 29.572502999189567],
            [-94.762569, 29.52416199919038],
            [-94.791611, 29.550415999189941],
            [-94.909845, 29.496440999190849],
            [-95.018205, 29.554596999189869],
            [-95.015039, 29.631949999188571]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "009",
        COUNTYNS: "01448019",
        AFFGEOID: "0500000US49009",
        GEOID: "49009",
        NAME: "Daggett",
        LSAD: "06",
        ALAND: 1805185918,
        AWATER: 61230035
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.000716736441007, 40.99742545877394],
            [-109.715409, 40.998190999065734],
            [-109.250735, 41.001008999065725],
            [-109.050076, 41.000658999065735],
            [-109.048455, 40.826080999066527],
            [-109.048259750048004, 40.662601816295322],
            [-109.161063, 40.683816999067211],
            [-109.163425, 40.785954999066725],
            [-109.20325, 40.858400999066383],
            [-109.394491, 40.858165999066372],
            [-109.394465, 40.791031999066696],
            [-109.484332, 40.740431999066935],
            [-109.525495, 40.826369999066529],
            [-109.870207, 40.763828999066831],
            [-109.976402, 40.809685999066602],
            [-110.000711, 40.813677999066584],
            [-110.000716736441007, 40.99742545877394]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "045",
        COUNTYNS: "00161548",
        AFFGEOID: "0500000US01045",
        GEOID: "01045",
        NAME: "Dale",
        LSAD: "06",
        ALAND: 1453311031,
        AWATER: 4016457
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.789142, 31.61796399915718],
            [-85.748251, 31.618047999157174],
            [-85.416437, 31.619465999157164],
            [-85.417434, 31.314972999161707],
            [-85.485854, 31.246095999162762],
            [-85.666121, 31.267315999162438],
            [-85.710333, 31.195172999163521],
            [-85.791402, 31.196348999163508],
            [-85.789142, 31.61796399915718]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "125",
        COUNTYNS: "00161588",
        AFFGEOID: "0500000US01125",
        GEOID: "01125",
        NAME: "Tuscaloosa",
        LSAD: "06",
        ALAND: 3421041792,
        AWATER: 78682797
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.840683, 33.524838999130857],
            [-87.666661, 33.521666999130908],
            [-87.631718, 33.609832999129779],
            [-87.423701, 33.602095999129872],
            [-87.37104, 33.587064999130057],
            [-87.318532, 33.58739299913006],
            [-87.318237, 33.514165999131002],
            [-87.266923, 33.512928999131013],
            [-87.341698, 33.470500999131559],
            [-87.194841, 33.343068999133209],
            [-87.179754, 33.328009999133407],
            [-87.179638, 33.3211119991335],
            [-87.162175, 33.321011999133489],
            [-87.162007, 33.306586999133678],
            [-87.127142, 33.306012999133692],
            [-87.126756, 33.290843999133891],
            [-87.087497, 33.290674999133891],
            [-87.087546, 33.279665999134025],
            [-87.074602, 33.279681999134034],
            [-87.074634, 33.276017999134076],
            [-87.065754, 33.275936999134082],
            [-87.065738, 33.246906999134467],
            [-87.199153, 33.196553999135126],
            [-87.199317, 33.130656999135986],
            [-87.281945, 33.133059999135959],
            [-87.318539, 33.006178999137639],
            [-87.421936, 33.003378999137681],
            [-87.715709, 33.006823999137637],
            [-87.832142, 33.017596999137488],
            [-87.838047, 33.136863999135905],
            [-87.837521, 33.153636999135685],
            [-87.840683, 33.524838999130857]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "011",
        COUNTYNS: "00161531",
        AFFGEOID: "0500000US01011",
        GEOID: "01011",
        NAME: "Bullock",
        LSAD: "06",
        ALAND: 1613059168,
        AWATER: 6054988
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.999157, 32.250542999148031],
            [-85.986557, 32.272341999147727],
            [-85.919293, 32.274381999147678],
            [-85.898689, 32.274986999147679],
            [-85.898539, 32.305288999147258],
            [-85.856218, 32.231974999148285],
            [-85.433543, 32.234647999148251],
            [-85.427441, 32.146550999149504],
            [-85.410241, 32.1466509991495],
            [-85.428476, 32.014950999151388],
            [-85.587344, 31.997354999151643],
            [-85.657668, 31.880274999153329],
            [-85.791047, 31.880356999153342],
            [-85.790048, 31.967253999152067],
            [-85.88435, 31.96725299915207],
            [-85.893652, 32.047350999150922],
            [-85.946298, 32.061889999150715],
            [-85.996853, 32.051048999150879],
            [-85.997859, 32.141604999149564],
            [-85.999157, 32.250542999148031]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "068",
        COUNTYNS: "01419988",
        AFFGEOID: "0500000US02068",
        GEOID: "02068",
        NAME: "Denali",
        LSAD: "04",
        ALAND: 32729613863,
        AWATER: 66068878
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-152.428084, 63.183170999241213],
            [-152.428098, 63.304258999243572],
            [-152.619512, 63.304253999243592],
            [-152.619517, 63.34756799924444],
            [-152.810931, 63.347562999244445],
            [-152.810945, 63.477500999247006],
            [-152.87341, 63.650744999250456],
            [-152.228439, 63.650759999250454],
            [-152.229256, 63.824000999253933],
            [-152.055132, 63.824005999253927],
            [-152.055758, 63.997241999257426],
            [-151.830535, 63.997247999257425],
            [-151.793976, 64.069429999258887],
            [-151.564903, 64.0694359992589],
            [-151.516333, 64.020630999257904],
            [-151.281995, 63.997264999257425],
            [-150.729385, 64.358172999264795],
            [-149.132653, 64.358213999264805],
            [-148.046696, 64.343801999264514],
            [-147.779479, 64.259028999262767],
            [-146.999909, 64.25719599926272],
            [-146.999966, 63.47964499924705],
            [-148.025627, 63.477608999247011],
            [-148.025618, 63.333232999244174],
            [-149.526148, 63.333216999244172],
            [-150.720916, 63.063183999238881],
            [-151.894015, 62.798057999233755],
            [-151.893784, 62.725863999232374],
            [-152.997647, 62.727400999232422],
            [-152.428084, 63.183170999241213]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "289",
        COUNTYNS: "00345456",
        AFFGEOID: "0500000US13289",
        GEOID: "13289",
        NAME: "Twiggs",
        LSAD: "06",
        ALAND: 928304208,
        AWATER: 10914123
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.602186, 32.741447999141208],
            [-83.513511, 32.844867999139808],
            [-83.40699, 32.898219999139094],
            [-83.226536, 32.584200999143363],
            [-83.412055, 32.502090999144507],
            [-83.497923, 32.452197999145191],
            [-83.561058, 32.658274999142343],
            [-83.597656, 32.664337999142269],
            [-83.602186, 32.741447999141208]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "037",
        COUNTYNS: "00198134",
        AFFGEOID: "0500000US08037",
        GEOID: "08037",
        NAME: "Eagle",
        LSAD: "06",
        ALAND: 4362804330,
        AWATER: 18920534
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.113672, 39.919111999071276],
            [-107.033968, 39.918912999071281],
            [-106.656536, 39.919960999071286],
            [-106.626555, 39.918670999071281],
            [-106.626569, 39.924784999071257],
            [-106.434508, 39.924913999071258],
            [-106.385478, 39.767163999072174],
            [-106.176464, 39.635721999072963],
            [-106.251638, 39.465038999074025],
            [-106.206732, 39.379635999074551],
            [-106.283812, 39.349468999074752],
            [-106.426487, 39.361870999074675],
            [-106.509214, 39.362297999074656],
            [-107.113446, 39.366065999074635],
            [-107.113672, 39.919111999071276]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "097",
        COUNTYNS: "00198164",
        AFFGEOID: "0500000US08097",
        GEOID: "08097",
        NAME: "Pitkin",
        LSAD: "06",
        ALAND: 2514104933,
        AWATER: 6472574
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.430949, 39.366177999074644],
            [-107.113446, 39.366065999074635],
            [-106.509214, 39.362297999074656],
            [-106.426487, 39.361870999074675],
            [-106.50238, 39.298566999075078],
            [-106.561265, 39.155676999076007],
            [-106.577966, 39.057907999076669],
            [-106.599214, 38.997993999077067],
            [-106.694969, 39.055666999076685],
            [-106.749357, 39.042527999076761],
            [-106.801874, 38.978302999077208],
            [-106.861687, 39.009438999076991],
            [-106.906422, 38.992090999077092],
            [-107.001622, 39.042272999076772],
            [-107.06632, 39.118804999076247],
            [-107.28249, 39.118717999076267],
            [-107.375608, 39.197930999075723],
            [-107.394486, 39.256298999075348],
            [-107.465187, 39.31451499907498],
            [-107.430949, 39.366177999074644]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "091",
        COUNTYNS: "00348116",
        AFFGEOID: "0500000US13091",
        GEOID: "13091",
        NAME: "Dodge",
        LSAD: "06",
        ALAND: 1284379383,
        AWATER: 18687765
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.346528, 32.272488999147718],
            [-83.30475, 32.341957999146736],
            [-83.173252, 32.452532999145184],
            [-83.138991, 32.423068999145606],
            [-82.990967, 32.14727399914949],
            [-82.884803, 32.196071999148799],
            [-82.874864, 32.180023999149022],
            [-82.927865, 32.135273999149653],
            [-82.934566, 32.153273999149405],
            [-82.942066, 32.124073999149829],
            [-83.205739, 31.900324999153042],
            [-83.292061, 31.984505999151828],
            [-83.295206, 32.079179999150476],
            [-83.337313, 32.105884999150085],
            [-83.291106, 32.178824999149043],
            [-83.373931, 32.249740999148031],
            [-83.346528, 32.272488999147718]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "187",
        COUNTYNS: "00357196",
        AFFGEOID: "0500000US13187",
        GEOID: "13187",
        NAME: "Lumpkin",
        LSAD: "06",
        ALAND: 732786481,
        AWATER: 3280165
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.188557, 34.602691999117681],
            [-84.158035, 34.648242999117137],
            [-84.03651, 34.641933999117235],
            [-83.939007, 34.740858999116078],
            [-83.856506, 34.722190999116293],
            [-83.876133, 34.620843999117469],
            [-83.843405, 34.505493999118819],
            [-83.931886, 34.469344999119244],
            [-83.980649, 34.418388999119848],
            [-84.102037, 34.4645439991193],
            [-84.19104, 34.539180999118429],
            [-84.188557, 34.602691999117681]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "035",
        COUNTYNS: "00352295",
        AFFGEOID: "0500000US13035",
        GEOID: "13035",
        NAME: "Butts",
        LSAD: "06",
        ALAND: 475759863,
        AWATER: 11264566
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.088991, 33.285645999133962],
            [-84.102582, 33.29819099913378],
            [-84.061322, 33.30966799913363],
            [-84.042533, 33.341112999133223],
            [-83.964374, 33.375153999132792],
            [-83.923913, 33.444193999131897],
            [-83.912516, 33.417751999132236],
            [-83.863058, 33.368277999132864],
            [-83.807265, 33.192985999135161],
            [-83.822261, 33.180237999135336],
            [-83.842615, 33.196772999135121],
            [-84.041498, 33.202628999135044],
            [-84.123767, 33.202823999135035],
            [-84.088991, 33.285645999133962]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "15",
        COUNTYFP: "005",
        COUNTYNS: "01702380",
        AFFGEOID: "0500000US15005",
        GEOID: "15005",
        NAME: "Kalawao",
        LSAD: "06",
        ALAND: 31057603,
        AWATER: 105764468
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-157.014267869299005, 21.200694265621468],
            [-156.984032, 21.212197999362889],
            [-156.962847, 21.212130999362891],
            [-156.921108, 21.169067999363939],
            [-156.917859561199009, 21.169021345645042],
            [-156.900241, 21.131624999364849],
            [-156.918072, 21.129478999364906],
            [-156.942135, 21.160172999364153],
            [-156.973643, 21.175239999363789],
            [-157.014612, 21.182348999363615],
            [-157.014267869299005, 21.200694265621468]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "179",
        COUNTYNS: "00465278",
        AFFGEOID: "0500000US19179",
        GEOID: "19179",
        NAME: "Wapello",
        LSAD: "06",
        ALAND: 1118460312,
        AWATER: 10829143
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.640361, 41.161297999065006],
            [-92.410233, 41.161941999065007],
            [-92.179974, 41.162661999065001],
            [-92.179072, 40.899719999066178],
            [-92.639091, 40.898885999066188],
            [-92.640361, 41.161297999065006]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "031",
        COUNTYNS: "00465205",
        AFFGEOID: "0500000US19031",
        GEOID: "19031",
        NAME: "Cedar",
        LSAD: "06",
        ALAND: 1500730782,
        AWATER: 6385832
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.366079, 41.860067999062252],
            [-91.365789, 41.947409999061939],
            [-90.898484, 41.946244999061932],
            [-90.898373, 41.771391999062558],
            [-90.899853, 41.59713299906322],
            [-91.366448, 41.598372999063223],
            [-91.366079, 41.860067999062252]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "161",
        COUNTYNS: "00695801",
        AFFGEOID: "0500000US28161",
        GEOID: "28161",
        NAME: "Yalobusha",
        LSAD: "06",
        ALAND: 1209858146,
        AWATER: 72197127
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.931485, 34.164302999122889],
            [-89.721431, 34.163512999122908],
            [-89.72105, 34.192544999122546],
            [-89.50944, 34.190959999122562],
            [-89.509748, 34.162081999122925],
            [-89.507353, 33.867468999126537],
            [-89.76905, 33.867333999126529],
            [-89.769296, 33.897136999126168],
            [-89.926099, 33.897956999126158],
            [-89.925497, 34.140552999123173],
            [-89.931485, 34.164302999122889]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "135",
        COUNTYNS: "00758522",
        AFFGEOID: "0500000US29135",
        GEOID: "29135",
        NAME: "Moniteau",
        LSAD: "06",
        ALAND: 1074911084,
        AWATER: 9960533
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.839837, 38.683973999079264],
            [-92.617147, 38.680012999079281],
            [-92.498377, 38.922083999077593],
            [-92.381304, 38.808731999078375],
            [-92.394866, 38.739129999078862],
            [-92.495185, 38.427828999081122],
            [-92.625189, 38.430777999081101],
            [-92.841871, 38.59679199907989],
            [-92.839837, 38.683973999079264]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "165",
        COUNTYNS: "01623023",
        AFFGEOID: "0500000US26165",
        GEOID: "26165",
        NAME: "Wexford",
        LSAD: "06",
        ALAND: 1463234116,
        AWATER: 27096654
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.817802, 44.512968999056874],
            [-85.334, 44.512302999056871],
            [-85.334777, 44.165117999057124],
            [-85.564554, 44.164860999057119],
            [-85.821186, 44.164196999057118],
            [-85.817802, 44.512968999056874]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "021",
        COUNTYNS: "00974109",
        AFFGEOID: "0500000US36021",
        GEOID: "36021",
        NAME: "Columbia",
        LSAD: "06",
        ALAND: 1643778340,
        AWATER: 35063763
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.910675, 42.127292999061332],
            [-73.786832, 42.273006999060875],
            [-73.783721, 42.46423099906032],
            [-73.352524954944201, 42.510007509010784],
            [-73.352527, 42.510001999060187],
            [-73.383505657283607, 42.425646049244229],
            [-73.410644407427284, 42.351746298708541],
            [-73.508142, 42.086256999061476],
            [-73.489679942032097, 42.053797708648283],
            [-73.527072, 41.97797999906183],
            [-73.71093, 42.005487999061756],
            [-73.929626, 42.078777999061501],
            [-73.910675, 42.127292999061332]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "011",
        COUNTYNS: "00974104",
        AFFGEOID: "0500000US36011",
        GEOID: "36011",
        NAME: "Cayuga",
        LSAD: "06",
        ALAND: 1791190504,
        AWATER: 445708175
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.73674, 42.970285999059023],
            [-76.713806, 43.024034999058898],
            [-76.702324, 43.080158999058774],
            [-76.722002565712799, 43.337580195039649],
            [-76.684856, 43.35269099905824],
            [-76.630774, 43.413355999058119],
            [-76.617213732577582, 43.420175553523713],
            [-76.605012, 43.253569999058421],
            [-76.479224, 43.227518999058475],
            [-76.491941, 43.004963999058937],
            [-76.450738, 42.845759999059311],
            [-76.356974, 42.849449999059303],
            [-76.274673, 42.771256999059496],
            [-76.265584, 42.623587999059879],
            [-76.4584, 42.618614999059886],
            [-76.666543, 42.623456999059869],
            [-76.731636, 42.720739999059624],
            [-76.73674, 42.970285999059023]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "125",
        COUNTYNS: "00198178",
        AFFGEOID: "0500000US08125",
        GEOID: "08125",
        NAME: "Yuma",
        LSAD: "06",
        ALAND: 6123763472,
        AWATER: 11134666
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.803767, 40.002553999070791],
            [-102.793582, 40.351280999068905],
            [-102.77996, 40.438445999068456],
            [-102.664804, 40.438472999068438],
            [-102.051304798597016, 40.440007992445445],
            [-102.051308551844016, 40.349221355941118],
            [-102.051309, 40.338380999068981],
            [-102.051744, 40.003077999070797],
            [-102.051254, 39.818991999071876],
            [-102.049992135644004, 39.574056096494353],
            [-102.802932, 39.567840999073383],
            [-102.803767, 40.002553999070791]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "063",
        COUNTYNS: "01008550",
        AFFGEOID: "0500000US37063",
        GEOID: "37063",
        NAME: "Durham",
        LSAD: "06",
        ALAND: 741761173,
        AWATER: 29701774
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.0095, 35.902400999103456],
            [-78.950597, 36.239319999100069],
            [-78.802335, 36.235793999100103],
            [-78.807484, 36.087294999101587],
            [-78.751273, 36.070833999101737],
            [-78.699316, 36.011447999102359],
            [-78.75821, 35.921557999103257],
            [-78.806756, 35.922924999103238],
            [-78.829965, 35.86697799910381],
            [-78.906097, 35.86791799910381],
            [-79.016305, 35.863209999103859],
            [-79.0095, 35.902400999103456]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "067",
        COUNTYNS: "00485099",
        AFFGEOID: "0500000US20067",
        GEOID: "20067",
        NAME: "Grant",
        LSAD: "06",
        ALAND: 1488724884,
        AWATER: 892323
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.527063, 37.736313999086533],
            [-101.089667, 37.736336999086532],
            [-101.089653, 37.387720999089467],
            [-101.52581, 37.388391999089457],
            [-101.527063, 37.736313999086533]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "201",
        COUNTYNS: "00758553",
        AFFGEOID: "0500000US29201",
        GEOID: "29201",
        NAME: "Scott",
        LSAD: "06",
        ALAND: 1087765645,
        AWATER: 15199712
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.765211, 37.126065999091765],
            [-89.620256, 37.233007999090809],
            [-89.482889284644003, 37.260950717504663],
            [-89.470525, 37.253356999090634],
            [-89.456105, 37.188119999091199],
            [-89.384175, 37.103266999091964],
            [-89.359456, 37.042605999092501],
            [-89.307436691172981, 37.028759448720535],
            [-89.375064, 36.964946999093208],
            [-89.465393, 36.935728999093463],
            [-89.501683, 36.906261999093736],
            [-89.519809, 36.869616999094077],
            [-89.567101, 36.870881999094067],
            [-89.691603, 36.859516999094154],
            [-89.68953, 36.925999999093555],
            [-89.786867, 37.087101999092106],
            [-89.765211, 37.126065999091765]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "137",
        COUNTYNS: "00758523",
        AFFGEOID: "0500000US29137",
        GEOID: "29137",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 1677414413,
        AWATER: 58300656
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.300871, 39.605347999073146],
            [-92.299316, 39.605343999073142],
            [-92.186691, 39.604081999073152],
            [-92.184986, 39.662931999072796],
            [-91.846743, 39.658512999072819],
            [-91.715247, 39.657643999072832],
            [-91.711934, 39.599094999073188],
            [-91.717968, 39.33912199907482],
            [-91.874651, 39.337793999074826],
            [-92.310028, 39.347794999074758],
            [-92.300871, 39.605347999073146]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "203",
        COUNTYNS: "00758554",
        AFFGEOID: "0500000US29203",
        GEOID: "29203",
        NAME: "Shannon",
        LSAD: "06",
        ALAND: 2599883711,
        AWATER: 464011
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.655607, 37.048924999092435],
            [-91.646626, 37.422730999089168],
            [-91.211863, 37.41527699908923],
            [-91.175405, 37.414804999089242],
            [-91.159323, 37.25493599909062],
            [-91.126028, 37.199479999091103],
            [-91.034346, 37.169342999091377],
            [-91.017766, 37.095676999092028],
            [-91.21715, 37.086196999092117],
            [-91.22283, 36.883856999093936],
            [-91.658111, 36.888723999093898],
            [-91.655607, 37.048924999092435]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "085",
        COUNTYNS: "01074055",
        AFFGEOID: "0500000US39085",
        GEOID: "39085",
        NAME: "Lake",
        LSAD: "06",
        ALAND: 593799763,
        AWATER: 1942309063
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.488682733409007, 41.634463926883981],
            [-81.466038, 41.649147999063018],
            [-81.388632, 41.707143999062808],
            [-81.286925, 41.760242999062605],
            [-81.184368, 41.786670999062522],
            [-81.05192, 41.839556999062324],
            [-81.002269406517499, 41.849172465499883],
            [-81.003631, 41.715136999062779],
            [-81.102258, 41.714306999062785],
            [-81.101422, 41.641456999063045],
            [-81.294669, 41.640888999063066],
            [-81.29588, 41.569949999063326],
            [-81.391005, 41.569715999063327],
            [-81.487842, 41.570050999063319],
            [-81.488682733409007, 41.634463926883981]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "017",
        COUNTYNS: "00835830",
        AFFGEOID: "0500000US31017",
        GEOID: "31017",
        NAME: "Brown",
        LSAD: "06",
        ALAND: 3163296235,
        AWATER: 9814399
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.198478, 42.8461019990593],
            [-100.11334, 42.799682999059421],
            [-99.677684, 42.730902999059595],
            [-99.663752, 42.434608999060394],
            [-99.662379, 42.086008999061477],
            [-99.686959, 42.086074999061481],
            [-100.167605, 42.085847999061485],
            [-100.183167, 42.433429999060408],
            [-100.18327, 42.781004999059469],
            [-100.198478, 42.8461019990593]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "079",
        COUNTYNS: "00835861",
        AFFGEOID: "0500000US31079",
        GEOID: "31079",
        NAME: "Hall",
        LSAD: "06",
        ALAND: 1414817966,
        AWATER: 15229160
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.721975, 41.046673999065511],
            [-98.287168, 41.046362999065522],
            [-98.282395, 41.026596999065603],
            [-98.282578, 40.868676999066331],
            [-98.282851, 40.698283999067144],
            [-98.72143, 40.698902999067137],
            [-98.721975, 41.046673999065511]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "021",
        COUNTYNS: "01035622",
        AFFGEOID: "0500000US38021",
        GEOID: "38021",
        NAME: "Dickey",
        LSAD: "06",
        ALAND: 2930494902,
        AWATER: 27610562
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.0057543501525, 45.93994430818573],
            [-99.003118, 46.282897999057774],
            [-98.034573, 46.282795999057768],
            [-98.006715, 46.282625999057778],
            [-98.008101798277778, 45.936012628106624],
            [-98.070515, 45.936179999057323],
            [-98.414518, 45.936503999057315],
            [-98.625379, 45.938227999057311],
            [-98.7243744138881, 45.93867473231883],
            [-99.005642, 45.93994399905732],
            [-99.0057543501525, 45.93994430818573]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "039",
        COUNTYNS: "00161545",
        AFFGEOID: "0500000US01039",
        GEOID: "01039",
        NAME: "Covington",
        LSAD: "06",
        ALAND: 2668871125,
        AWATER: 34489285
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.702446, 31.345396999161249],
            [-86.662082, 31.402797999160377],
            [-86.701554, 31.523945999158574],
            [-86.499213, 31.525330999158548],
            [-86.39994, 31.527127999158523],
            [-86.398536, 31.45132499915966],
            [-86.280155, 31.528509999158498],
            [-86.277031, 31.45560199915959],
            [-86.193951, 31.440071999159827],
            [-86.196309, 31.422389999160092],
            [-86.193476, 31.192212999163566],
            [-86.190559, 31.013629999166305],
            [-86.187248048981303, 30.994066722628812],
            [-86.364974, 30.994436999166599],
            [-86.388644662000402, 30.994528214441797],
            [-86.563494, 30.995201999166589],
            [-86.688240964246091, 30.99620188916807],
            [-86.700251, 31.008900999166379],
            [-86.700282, 31.192216999163577],
            [-86.702446, 31.345396999161249]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "019",
        COUNTYNS: "00161535",
        AFFGEOID: "0500000US01019",
        GEOID: "01019",
        NAME: "Cherokee",
        LSAD: "06",
        ALAND: 1434228753,
        AWATER: 119702920
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.843801, 34.244594999121922],
            [-85.774165, 34.259093999121752],
            [-85.627512, 34.39591999912011],
            [-85.559282, 34.502254999118861],
            [-85.513044229273291, 34.523946470442304],
            [-85.502471, 34.47452599911918],
            [-85.475147281104299, 34.343684656996935],
            [-85.463140824775778, 34.286190987626924],
            [-85.429499, 34.12509499912337],
            [-85.421073175228798, 34.080812651731115],
            [-85.398871244842013, 33.964129285904846],
            [-85.495289, 33.956909999125429],
            [-85.530094, 33.941422999125621],
            [-85.542214, 33.956731999125424],
            [-85.738975, 33.968459999125294],
            [-85.797071, 34.100178999123671],
            [-85.841365, 34.110694999123545],
            [-85.843617, 34.20000599912246],
            [-85.843801, 34.244594999121922]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "081",
        COUNTYNS: "01581101",
        AFFGEOID: "0500000US55081",
        GEOID: "55081",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 2333327981,
        AWATER: 18589237
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.977279, 44.129607999057143],
            [-90.904579, 44.158297999057112],
            [-90.553421, 44.160214999057139],
            [-90.312522, 44.15519799905713],
            [-90.312694, 43.981383999057307],
            [-90.312693, 43.981334999057303],
            [-90.312194, 43.731478999057629],
            [-90.910653, 43.725333999057625],
            [-90.912918, 44.071521999057218],
            [-90.973107, 44.070881999057207],
            [-90.977279, 44.129607999057143]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "091",
        COUNTYNS: "01804526",
        AFFGEOID: "0500000US72091",
        GEOID: "72091",
        NAME: "Manatí",
        LSAD: "13",
        ALAND: 116913007,
        AWATER: 66163823
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.543079, 18.405421999434079],
            [-66.521165, 18.431157999433402],
            [-66.534256445318604, 18.479485370441626],
            [-66.470292, 18.469069999432403],
            [-66.438950007398887, 18.481492908694772],
            [-66.448989, 18.387213999434557],
            [-66.4438, 18.371026999434985],
            [-66.463211, 18.371472999434975],
            [-66.533623, 18.351332999435506],
            [-66.543079, 18.405421999434079]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "075",
        COUNTYNS: "01804518",
        AFFGEOID: "0500000US72075",
        GEOID: "72075",
        NAME: "Juana Díaz",
        LSAD: "13",
        ALAND: 156117100,
        AWATER: 121328720
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.54722, 18.153129999440754],
            [-66.521899, 18.151953999440785],
            [-66.518244, 18.085029999442568],
            [-66.430314, 18.082052999442642],
            [-66.426989, 18.042626999443691],
            [-66.421054, 18.019308999444313],
            [-66.453296753479293, 17.98013311292716],
            [-66.510143, 17.985617999445211],
            [-66.540537316236197, 17.975475887272982],
            [-66.530417, 18.002643999444757],
            [-66.552766, 18.152056999440784],
            [-66.54722, 18.153129999440754]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "097",
        COUNTYNS: "01696800",
        AFFGEOID: "0500000US54097",
        GEOID: "54097",
        NAME: "Upshur",
        LSAD: "06",
        ALAND: 918506896,
        AWATER: 302703
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.409541, 38.76832699907866],
            [-80.386989, 38.874807999077916],
            [-80.317486, 38.958646999077338],
            [-80.300209, 39.103856999076356],
            [-80.227173, 39.112791999076293],
            [-80.128198, 39.037794999076787],
            [-80.054619, 39.03552899907681],
            [-80.085062, 38.947235999077414],
            [-80.126105, 38.745835999078821],
            [-80.236831, 38.743764999078827],
            [-80.280059, 38.694866999079181],
            [-80.318246, 38.684295999079254],
            [-80.393063, 38.727570999078942],
            [-80.409541, 38.76832699907866]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "093",
        COUNTYNS: "01581107",
        AFFGEOID: "0500000US55093",
        GEOID: "55093",
        NAME: "Pierce",
        LSAD: "06",
        ALAND: 1486126051,
        AWATER: 46825858
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.805287, 44.768360999056789],
            [-92.768574, 44.854367999056763],
            [-92.767117334357195, 44.861515184932664],
            [-92.767023265751888, 44.861976736154467],
            [-92.136348, 44.857847999056759],
            [-92.135202, 44.68437399905681],
            [-92.1353, 44.539579999056862],
            [-92.316933133405797, 44.53927601125956],
            [-92.361518, 44.558934999056845],
            [-92.399281, 44.558291999056841],
            [-92.54928, 44.577703999056844],
            [-92.618025, 44.612869999056826],
            [-92.696491, 44.689435999056798],
            [-92.731623851303198, 44.7149227843164],
            [-92.792360799670504, 44.75898381275389],
            [-92.805287, 44.768360999056789]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "620",
        COUNTYNS: "01498424",
        AFFGEOID: "0500000US51620",
        GEOID: "51620",
        NAME: "Franklin",
        LSAD: "25",
        ALAND: 21295844,
        AWATER: 369820
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.96839, 36.700579999095638],
            [-76.952438, 36.708679999095558],
            [-76.924629, 36.707728999095579],
            [-76.925691, 36.698328999095658],
            [-76.912221, 36.698407999095657],
            [-76.920984, 36.686006999095767],
            [-76.912015, 36.67271699909589],
            [-76.917742, 36.662367999095991],
            [-76.909672, 36.648174999096128],
            [-76.934907, 36.659128999096033],
            [-76.962339, 36.66751499909595],
            [-76.947438, 36.675879999095869],
            [-76.9645, 36.678869999095845],
            [-76.96839, 36.700579999095638]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "147",
        COUNTYNS: "00835895",
        AFFGEOID: "0500000US31147",
        GEOID: "31147",
        NAME: "Richardson",
        LSAD: "06",
        ALAND: 1429261293,
        AWATER: 7077617
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.010176, 40.261481999069375],
            [-95.547870316270291, 40.262783459889178],
            [-95.54716, 40.259065999069399],
            [-95.472548, 40.236077999069515],
            [-95.48102, 40.18852399906978],
            [-95.432165, 40.141024999070048],
            [-95.394216, 40.10826299907022],
            [-95.414734, 40.06981999907044],
            [-95.382957, 40.027111999070669],
            [-95.348777, 40.02929699907066],
            [-95.30829, 39.999997999070814],
            [-95.339895970850293, 40.000028856176222],
            [-95.784575, 40.00046299907082],
            [-95.788110994891696, 40.00046677843612],
            [-96.010678817934505, 40.000704664842829],
            [-96.010176, 40.261481999069375]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "431",
        COUNTYNS: "01384001",
        AFFGEOID: "0500000US48431",
        GEOID: "48431",
        NAME: "Sterling",
        LSAD: "06",
        ALAND: 2391722776,
        AWATER: 167451
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.267122, 31.650853999156695],
            [-101.264216, 32.087135999150355],
            [-101.183997, 32.087207999150351],
            [-100.821594, 32.086609999150362],
            [-100.82537, 31.696154999156025],
            [-100.861099, 31.705417999155884],
            [-100.865256, 31.562293999158005],
            [-101.26763, 31.556461999158088],
            [-101.267122, 31.650853999156695]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "055",
        COUNTYNS: "01448041",
        AFFGEOID: "0500000US49055",
        GEOID: "49055",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 6374158879,
        AWATER: 15154124
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.848761, 38.42493299908115],
            [-111.766393, 38.502242999080586],
            [-111.752325, 38.510124999080517],
            [-111.305665, 38.510168999080506],
            [-111.279345, 38.499977999080592],
            [-110.025402, 38.499980999080591],
            [-110.044544, 38.452623999080934],
            [-110.031973, 38.384956999081453],
            [-109.931973, 38.284252999082206],
            [-109.887266, 38.190213999082928],
            [-109.927995, 38.151883999083225],
            [-110.687406, 38.151384999083227],
            [-111.635194, 38.150086999083236],
            [-111.843215, 38.151279999083229],
            [-111.848761, 38.42493299908115]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "001",
        COUNTYNS: "01639722",
        AFFGEOID: "0500000US47001",
        GEOID: "47001",
        NAME: "Anderson",
        LSAD: "06",
        ALAND: 873292849,
        AWATER: 19724754
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.441034, 36.163477999100834],
            [-84.403633, 36.174777999100705],
            [-84.372731, 36.216777999100294],
            [-84.241825, 36.177577999100684],
            [-84.227527, 36.24487899910001],
            [-84.069918, 36.230377999100163],
            [-84.032519, 36.301778999099447],
            [-84.004317, 36.270377999099772],
            [-83.998745, 36.25236999909994],
            [-83.941711, 36.1863779991006],
            [-84.094011, 36.068910999101767],
            [-84.272869, 35.940505999103074],
            [-84.271587, 35.91016499910338],
            [-84.32006, 35.991606999102551],
            [-84.341812, 36.048976999101974],
            [-84.449734, 36.14167699910103],
            [-84.441034, 36.163477999100834]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "075",
        COUNTYNS: "01581097",
        AFFGEOID: "0500000US55075",
        GEOID: "55075",
        NAME: "Marinette",
        LSAD: "06",
        ALAND: 3624558795,
        AWATER: 389917044
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.425302, 45.722425999057094],
            [-88.059246, 45.713062999057087],
            [-88.057010465949006, 45.784977029200867],
            [-88.048514, 45.782548999057155],
            [-87.995876, 45.795434999057171],
            [-87.96697, 45.764020999057138],
            [-87.879812, 45.754842999057139],
            [-87.833049468035298, 45.722752574404005],
            [-87.805076, 45.703555999057087],
            [-87.781007, 45.673933999057056],
            [-87.824676, 45.653210999057045],
            [-87.777671, 45.609203999057016],
            [-87.787292, 45.574905999056988],
            [-87.804203, 45.524675999056953],
            [-87.805773, 45.473138999056914],
            [-87.847429, 45.4441769990569],
            [-87.85683, 45.393105999056871],
            [-87.863489, 45.353019999056862],
            [-87.800464, 45.353607999056855],
            [-87.750928, 45.35503699905685],
            [-87.706767, 45.383826999056858],
            [-87.657349, 45.368751999056862],
            [-87.667423, 45.31635999905685],
            [-87.71148, 45.245223999056812],
            [-87.741805, 45.197050999056792],
            [-87.695055, 45.150521999056785],
            [-87.648191, 45.106367999056772],
            [-87.590208, 45.095263999056769],
            [-87.625748, 45.045156999056772],
            [-87.630298, 44.976864999056751],
            [-87.696492, 44.97423299905676],
            [-87.762638490971099, 44.962752170817055],
            [-87.760071, 44.990529999056768],
            [-87.942995, 45.020417999056775],
            [-88.121152, 45.023580999056769],
            [-88.118879, 45.110196999056775],
            [-88.189247, 45.111843999056774],
            [-88.186355, 45.197973999056799],
            [-88.309063, 45.201579999056797],
            [-88.305811, 45.374609999056865],
            [-88.428102, 45.377009999056867],
            [-88.425302, 45.722425999057094]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "025",
        COUNTYNS: "00424214",
        AFFGEOID: "0500000US17025",
        GEOID: "17025",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 1212958827,
        AWATER: 3278251
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.695165, 38.826298999078261],
            [-88.693531, 38.914616999077644],
            [-88.361745, 38.91084699907767],
            [-88.36176, 38.851948999078076],
            [-88.258608, 38.847520999078114],
            [-88.257783, 38.731138999078915],
            [-88.296989, 38.634189999079616],
            [-88.253851, 38.59950199907987],
            [-88.564109, 38.607092999079818],
            [-88.698457, 38.606303999079813],
            [-88.695165, 38.826298999078261]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "003",
        COUNTYNS: "00450402",
        AFFGEOID: "0500000US18003",
        GEOID: "18003",
        NAME: "Allen",
        LSAD: "06",
        ALAND: 1702454342,
        AWATER: 6999605
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.338552, 41.179119999064937],
            [-85.307781, 41.264157999064558],
            [-85.192094, 41.264208999064572],
            [-84.803703572616897, 41.271258329635131],
            [-84.803644982255591, 41.252561682612821],
            [-84.803234, 41.12141399906519],
            [-84.802859621951384, 40.989374051074371],
            [-84.802670206153394, 40.922568712987093],
            [-85.073861, 40.917822999066104],
            [-85.33603, 40.917081999066106],
            [-85.335643, 41.005249999065697],
            [-85.338552, 41.179119999064937]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "009",
        COUNTYNS: "01622947",
        AFFGEOID: "0500000US26009",
        GEOID: "26009",
        NAME: "Antrim",
        LSAD: "06",
        ALAND: 1232000354,
        AWATER: 326539096
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.431415475008308, 45.016649451774462],
            [-85.380659, 45.046318999056766],
            [-85.366749, 45.101590999056768],
            [-85.380464, 45.180875999056795],
            [-85.37782429593301, 45.207644401543696],
            [-85.222707, 45.205559999056803],
            [-85.223681, 45.117860999056781],
            [-84.857452, 45.116706999056781],
            [-84.847309, 44.858036999056772],
            [-85.291716, 44.859607999056777],
            [-85.327595, 44.850759999056777],
            [-85.332836, 44.812371999056765],
            [-85.381983, 44.859567999056765],
            [-85.473841, 44.85944899905676],
            [-85.431415475008308, 45.016649451774462]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "043",
        COUNTYNS: "00465210",
        AFFGEOID: "0500000US19043",
        GEOID: "19043",
        NAME: "Clayton",
        LSAD: "06",
        ALAND: 2016295994,
        AWATER: 36695627
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.605307, 43.081652999058775],
            [-91.174934913911173, 43.080260370903986],
            [-91.174692, 43.038712999058866],
            [-91.159084305947488, 42.987477790381782],
            [-91.155519, 42.975773999059008],
            [-91.138, 42.903771999059174],
            [-91.09882, 42.86442099905927],
            [-91.070716, 42.775501999059479],
            [-91.017239, 42.719565999059633],
            [-90.941567, 42.683843999059718],
            [-90.896962027158409, 42.674318055494055],
            [-90.897415, 42.645713999059815],
            [-91.132766, 42.645843999059807],
            [-91.607059, 42.64397299905982],
            [-91.605307, 43.081652999058775]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "075",
        COUNTYNS: "00485327",
        AFFGEOID: "0500000US20075",
        GEOID: "20075",
        NAME: "Hamilton",
        LSAD: "06",
        ALAND: 2580958344,
        AWATER: 2893322
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.044634270290999, 38.262411566831879],
            [-101.567482, 38.263144999082371],
            [-101.542312, 38.263206999082371],
            [-101.542757, 37.827593999085792],
            [-101.527063, 37.736313999086533],
            [-102.041965658628001, 37.738540572724723],
            [-102.044255, 38.113010999083528],
            [-102.044634270290999, 38.262411566831879]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "081",
        COUNTYNS: "00295744",
        AFFGEOID: "0500000US12081",
        GEOID: "12081",
        NAME: "Manatee",
        LSAD: "06",
        ALAND: 1924699210,
        AWATER: 387514585
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.719852, 27.528932999226058],
            [-82.65072, 27.523114999226173],
            [-82.584629, 27.596020999224795],
            [-82.552887207063591, 27.645447595589371],
            [-82.054349, 27.646381999223852],
            [-82.055753, 27.338263999229671],
            [-82.056497, 27.207768999232155],
            [-82.253589, 27.210509999232109],
            [-82.252264, 27.386242999228749],
            [-82.648170616859488, 27.389719999228692],
            [-82.691821, 27.437217999227787],
            [-82.743017, 27.531085999226018],
            [-82.719852, 27.528932999226058]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "041",
        COUNTYNS: "00695745",
        AFFGEOID: "0500000US28041",
        GEOID: "28041",
        NAME: "Greene",
        LSAD: "06",
        ALAND: 1846034017,
        AWATER: 15380474
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.841707, 31.433702999159916],
            [-88.449446069017085, 31.435836860437494],
            [-88.44866, 31.421276999160121],
            [-88.432007, 31.114297999164755],
            [-88.426020963787096, 30.998281356596852],
            [-88.834339, 30.997982999166542],
            [-88.840896, 31.00655299916642],
            [-88.841707, 31.433702999159916]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "019",
        COUNTYNS: "01074022",
        AFFGEOID: "0500000US39019",
        GEOID: "39019",
        NAME: "Carroll",
        LSAD: "06",
        ALAND: 1022030958,
        AWATER: 11192655
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.317739, 40.651578999067368],
            [-81.24125, 40.650533999067392],
            [-81.237227, 40.723541999067024],
            [-81.198599, 40.729171999066992],
            [-81.087289, 40.727815999066998],
            [-80.917172, 40.726933999067001],
            [-80.918125, 40.643463999067421],
            [-80.861994, 40.599403999067626],
            [-80.863567, 40.555410999067846],
            [-80.92092, 40.556314999067844],
            [-80.924566, 40.467974999068289],
            [-80.941911, 40.424699999068523],
            [-81.212309, 40.433456999068476],
            [-81.269951, 40.433485999068466],
            [-81.268434, 40.476713999068259],
            [-81.264744, 40.565041999067802],
            [-81.321659, 40.56664799906779],
            [-81.317739, 40.651578999067368]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "147",
        COUNTYNS: "00758528",
        AFFGEOID: "0500000US29147",
        GEOID: "29147",
        NAME: "Nodaway",
        LSAD: "06",
        ALAND: 2271326597,
        AWATER: 2361588
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.202266000830292, 40.578375628063739],
            [-95.068921, 40.576879999067735],
            [-94.914897867361603, 40.574921168179038],
            [-94.819978, 40.573713999067756],
            [-94.632025917694804, 40.571759586467778],
            [-94.631889, 40.472512999068272],
            [-94.608429, 40.463657999068317],
            [-94.598487, 40.386713999068718],
            [-94.605942, 40.126126999070117],
            [-95.04308, 40.130095999070093],
            [-95.064972, 40.246588999069459],
            [-95.180614, 40.26170599906937],
            [-95.177708, 40.478902999068232],
            [-95.202266000830292, 40.578375628063739]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "023",
        COUNTYNS: "00558488",
        AFFGEOID: "0500000US22023",
        GEOID: "22023",
        NAME: "Cameron",
        LSAD: "15",
        ALAND: 3327836871,
        AWATER: 1688122644
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.929208, 29.802951999185712],
            [-93.872446, 29.851649999184897],
            [-93.852309870922298, 29.872091003576461],
            [-93.830374, 29.894358999184181],
            [-93.807815, 29.954548999183192],
            [-93.741078, 30.021570999182099],
            [-93.706335762470189, 30.052180240712495],
            [-93.128475, 30.052586999181592],
            [-92.996371, 30.038478999181812],
            [-92.774499, 30.039209999181796],
            [-92.738604, 30.037322999181836],
            [-92.61561, 30.038167999181812],
            [-92.617234437449881, 29.589059015362796],
            [-92.684486, 29.605000999189024],
            [-92.879992, 29.680284999187755],
            [-92.993128, 29.723845999187024],
            [-93.088182, 29.749124999186606],
            [-93.17693, 29.770486999186247],
            [-93.295573, 29.775070999186177],
            [-93.411087, 29.767356999186298],
            [-93.538462, 29.763298999186365],
            [-93.741948, 29.736342999186814],
            [-93.79925, 29.715259999187172],
            [-93.837971, 29.690618999187581],
            [-93.863204, 29.724058999187019],
            [-93.890821, 29.761672999186395],
            [-93.929208, 29.802951999185712]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "055",
        COUNTYNS: "01716270",
        AFFGEOID: "0500000US30055",
        GEOID: "30055",
        NAME: "McCone",
        LSAD: "06",
        ALAND: 6843265262,
        AWATER: 103091384
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.400605, 48.061920999062302],
            [-106.229302, 48.026415999062195],
            [-106.006176, 48.033688999062207],
            [-105.843891, 48.010288999062126],
            [-105.647941, 48.077303999062373],
            [-105.503195, 48.09779199906243],
            [-105.194783, 48.066616999062333],
            [-105.195122, 47.86808099906164],
            [-105.234771, 47.790501999061377],
            [-105.363262, 47.79041899906138],
            [-105.363507, 47.529557999060557],
            [-105.406309, 47.181582999059607],
            [-105.833044, 47.181091999059625],
            [-105.832637, 47.093108999059389],
            [-105.959897, 47.092967999059397],
            [-105.959819, 47.180999999059622],
            [-106.086777, 47.180937999059609],
            [-106.17042, 47.180825999059614],
            [-106.170675, 47.354494999060087],
            [-106.298158, 47.354297999060073],
            [-106.298073, 47.52904099906057],
            [-106.260932, 47.868430999061644],
            [-106.358085, 47.868411999061657],
            [-106.358288, 47.957215999061937],
            [-106.41862, 47.957456999061939],
            [-106.400605, 48.061920999062302]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "101",
        COUNTYNS: "01629492",
        AFFGEOID: "0500000US22101",
        GEOID: "22101",
        NAME: "St. Mary",
        LSAD: "15",
        ALAND: 1438845622,
        AWATER: 1460445312
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.935286, 29.671938999187883],
            [-91.711149, 29.846064999184989],
            [-91.624709, 29.945274999183347],
            [-91.471951, 29.95598899918317],
            [-91.373642, 29.834400999185185],
            [-91.386491, 29.783680999186032],
            [-91.215195, 29.751210999186572],
            [-91.10001, 29.699401999187437],
            [-91.105395, 29.64328099918837],
            [-91.082602, 29.626367999188648],
            [-91.178048, 29.609702999188936],
            [-91.27124, 29.535185999190197],
            [-91.258332, 29.444318999191736],
            [-91.213419, 29.417497999192189],
            [-91.235795, 29.373936999192935],
            [-91.266322670719205, 29.361363732539154],
            [-91.334051, 29.391524999192633],
            [-91.363967, 29.42066399919214],
            [-91.347514, 29.444437999191734],
            [-91.394307, 29.497114999190835],
            [-91.460963, 29.469960999191301],
            [-91.48559, 29.499115999190803],
            [-91.531021, 29.531542999190265],
            [-91.537445, 29.565887999189677],
            [-91.541974, 29.594352999189194],
            [-91.600179, 29.631155999188586],
            [-91.643832, 29.630624999188587],
            [-91.623829, 29.699239999187437],
            [-91.667128, 29.745821999186663],
            [-91.737253, 29.749369999186602],
            [-91.808142, 29.725096999187009],
            [-91.85307, 29.702935999187378],
            [-91.862556996522599, 29.667395462947873],
            [-91.935286, 29.671938999187883]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "179",
        COUNTYNS: "00835911",
        AFFGEOID: "0500000US31179",
        GEOID: "31179",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 1147143905,
        AWATER: 1087702
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.367724, 42.351860999060648],
            [-97.017789, 42.351346999060638],
            [-97.01773, 42.264452999060907],
            [-96.823077, 42.264291999060909],
            [-96.822497, 42.264291999060909],
            [-96.82367, 42.090410999061461],
            [-97.019359, 42.090576999061462],
            [-97.368404, 42.090921999061464],
            [-97.367724, 42.351860999060648]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "019",
        COUNTYNS: "00395407",
        AFFGEOID: "0500000US16019",
        GEOID: "16019",
        NAME: "Bonneville",
        LSAD: "06",
        ALAND: 4832874977,
        AWATER: 88853019
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.519861, 43.626622999057766],
            [-111.626043, 43.626755999057778],
            [-111.565974, 43.622036999057777],
            [-111.399765, 43.621975999057774],
            [-111.243839, 43.621852999057786],
            [-111.207561, 43.543856999057894],
            [-111.045360560970011, 43.501051450170173],
            [-111.044617, 43.315719999058302],
            [-111.044143, 43.072363999058801],
            [-111.044052705117991, 43.019407031957918],
            [-111.589489, 43.020051999058914],
            [-111.588049, 43.281840999058367],
            [-111.822494, 43.282285999058374],
            [-111.822475, 43.366034999058215],
            [-112.043821, 43.366707999058207],
            [-112.043672, 43.410142999058131],
            [-112.103298, 43.425056999058093],
            [-112.520024, 43.425169999058113],
            [-112.519861, 43.626622999057766]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "093",
        COUNTYNS: "00069899",
        AFFGEOID: "0500000US05093",
        GEOID: "05093",
        NAME: "Mississippi",
        LSAD: "06",
        ALAND: 2332480068,
        AWATER: 49826375
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.2889479152728, 35.996514003313898],
            [-90.103842, 35.998142999102484],
            [-89.9593752951737, 35.999014109259377],
            [-89.901183, 35.999364999102475],
            [-89.733095, 36.000607999102456],
            [-89.686924, 35.947715999102982],
            [-89.652279, 35.921461999103265],
            [-89.6489049703452, 35.903583481416049],
            [-89.64727, 35.89491999910355],
            [-89.722634, 35.873717999103754],
            [-89.729517, 35.847631999104017],
            [-89.723426, 35.80938199910441],
            [-89.797053, 35.782647999104682],
            [-89.863874, 35.747591999105055],
            [-89.915491, 35.754916999104985],
            [-89.956589, 35.695485999105586],
            [-89.898916, 35.650903999106063],
            [-89.876548, 35.626652999106327],
            [-89.9325, 35.607864999106518],
            [-89.944754, 35.560307999107017],
            [-89.958498, 35.541702999107223],
            [-90.037615, 35.550328999107123],
            [-90.045805, 35.4965329991077],
            [-90.022064, 35.457374999108119],
            [-90.045306, 35.415434999108562],
            [-90.070549, 35.423290999108488],
            [-90.112504, 35.410152999108632],
            [-90.140257, 35.436635999108354],
            [-90.286899, 35.438572999108324],
            [-90.286736, 35.699913999105554],
            [-90.2889479152728, 35.996514003313898]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "075",
        COUNTYNS: "00198153",
        AFFGEOID: "0500000US08075",
        GEOID: "08075",
        NAME: "Logan",
        LSAD: "06",
        ALAND: 4761948002,
        AWATER: 16363553
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.573774006195009, 41.001721798149816],
            [-103.382491651890007, 41.001926145686717],
            [-103.076536, 41.002252999065711],
            [-102.653464137269012, 41.002225323209309],
            [-102.65131, 40.74940199906689],
            [-102.664689, 40.705188999067104],
            [-102.664804, 40.438472999068438],
            [-102.77996, 40.438445999068456],
            [-103.465377, 40.436345999068458],
            [-103.4672, 40.523252999068013],
            [-103.581844, 40.523382999068012],
            [-103.573774006195009, 41.001721798149816]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "055",
        COUNTYNS: "00352213",
        AFFGEOID: "0500000US13055",
        GEOID: "13055",
        NAME: "Chattooga",
        LSAD: "06",
        ALAND: 811512406,
        AWATER: 570806
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.526894818161011, 34.588685691851346],
            [-85.360191, 34.581798999117929],
            [-85.107748, 34.58748299911786],
            [-85.180548, 34.43599599911964],
            [-85.334881, 34.36974799912042],
            [-85.387351, 34.286096999121426],
            [-85.463140824775778, 34.286190987626924],
            [-85.475147281104299, 34.343684656996935],
            [-85.502471, 34.47452599911918],
            [-85.513044229273291, 34.523946470442304],
            [-85.526894818161011, 34.588685691851346]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "127",
        COUNTYNS: "01784730",
        AFFGEOID: "0500000US17127",
        GEOID: "17127",
        NAME: "Massac",
        LSAD: "06",
        ALAND: 614391211,
        AWATER: 11903724
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.929129, 37.3026469990902],
            [-88.908291, 37.335726999089914],
            [-88.71065, 37.337089999089905],
            [-88.490336, 37.159357999091455],
            [-88.490399446324702, 37.067957622040169],
            [-88.531576, 37.067191999092273],
            [-88.561043929472589, 37.083999980050535],
            [-88.61144, 37.112744999091859],
            [-88.693983, 37.141154999091619],
            [-88.753068, 37.154700999091496],
            [-88.835051, 37.196485999091131],
            [-88.928004829494299, 37.226389765343974],
            [-88.929129, 37.3026469990902]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "115",
        COUNTYNS: "00450378",
        AFFGEOID: "0500000US18115",
        GEOID: "18115",
        NAME: "Ohio",
        LSAD: "06",
        ALAND: 223126822,
        AWATER: 3352208
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.135832, 38.929577999077537],
            [-85.132508, 38.948054999077407],
            [-85.097459, 38.935506999077496],
            [-84.998111, 38.996539999077079],
            [-84.877570231124793, 39.03126284317085],
            [-84.849445, 39.000922999077055],
            [-84.832617, 38.961459999077306],
            [-84.877762, 38.92035699907759],
            [-84.864428518991687, 38.913843640127041],
            [-85.140312, 38.903193999077722],
            [-85.135832, 38.929577999077537]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "171",
        COUNTYNS: "00516932",
        AFFGEOID: "0500000US21171",
        GEOID: "21171",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 853073337,
        AWATER: 6949352
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.97691, 36.722721999095434],
            [-85.772286, 36.836339999094378],
            [-85.739255, 36.841475999094335],
            [-85.613014, 36.832716999094409],
            [-85.596214, 36.818038999094547],
            [-85.517429, 36.731325999095354],
            [-85.471021, 36.733215999095329],
            [-85.496932, 36.690717999095725],
            [-85.436404272885895, 36.618003844123422],
            [-85.488353, 36.614993999096441],
            [-85.731862, 36.620428999096397],
            [-85.788556543613893, 36.621711858131071],
            [-85.873857, 36.623641999096364],
            [-85.975713174144005, 36.628637717778012],
            [-85.950661, 36.651022999096099],
            [-85.97691, 36.722721999095434]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "209",
        COUNTYNS: "00485065",
        AFFGEOID: "0500000US20209",
        GEOID: "20209",
        NAME: "Wyandotte",
        LSAD: "06",
        ALAND: 392650861,
        AWATER: 11890119
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.900191, 39.202910999075698],
            [-94.791994673743304, 39.201260249315901],
            [-94.741938, 39.170202999075912],
            [-94.680336, 39.184302999075825],
            [-94.623934, 39.156602999076],
            [-94.601937981921097, 39.155503232539814],
            [-94.591933, 39.155002999076011],
            [-94.607354, 39.113443999076296],
            [-94.607870353373997, 39.044085095555246],
            [-94.874812, 39.058039999076655],
            [-94.908765, 38.991400999077115],
            [-94.900191, 39.202910999075698]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "009",
        COUNTYNS: "00581290",
        AFFGEOID: "0500000US23009",
        GEOID: "23009",
        NAME: "Hancock",
        LSAD: "06",
        ALAND: 4110284976,
        AWATER: 1963056852
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.896384, 44.209934999057083],
            [-68.804615, 44.458410999056916],
            [-68.798337, 44.56826799905685],
            [-68.845722, 44.614839999056819],
            [-68.815824, 44.667252999056807],
            [-68.815793, 44.683753999056812],
            [-68.743953, 44.66057899905681],
            [-68.69093, 44.721667999056798],
            [-68.647134, 44.703635999056793],
            [-68.616673, 44.740991999056789],
            [-68.445219, 44.763013999056781],
            [-68.454889, 44.801549999056775],
            [-68.477952, 44.889090999056762],
            [-68.501095, 44.980717999056758],
            [-68.375892, 44.997372999056772],
            [-68.396903, 45.083295999056773],
            [-68.270497, 45.100120999056777],
            [-68.303714, 45.22153099905681],
            [-68.054985, 45.252576999056814],
            [-67.981851, 44.95588499905675],
            [-68.105017, 44.93908799905676],
            [-68.017219, 44.589086999056832],
            [-67.962624, 44.423413999056926],
            [-67.943843957055208, 44.407015789899944],
            [-67.978876, 44.387033999056946],
            [-68.01399, 44.39025499905695],
            [-68.049334, 44.330729999056977],
            [-68.103757, 44.364361999056968],
            [-68.125624, 44.387126999056946],
            [-68.189155, 44.373832999056944],
            [-68.173608, 44.328396999056991],
            [-68.191924, 44.306674999057002],
            [-68.22949, 44.266917999057043],
            [-68.17433, 44.225907999057071],
            [-68.306519, 44.234828999057058],
            [-68.314789, 44.197156999057086],
            [-68.331032, 44.107579999057165],
            [-68.438518, 44.116179999057167],
            [-68.502942, 44.099721999057195],
            [-68.531414202351797, 44.089852379313697],
            [-68.548472, 44.12969899905714],
            [-68.720917, 44.115259999057173],
            [-68.828471, 44.209006999057081],
            [-68.896384, 44.209934999057083]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "041",
        COUNTYNS: "01622963",
        AFFGEOID: "0500000US26041",
        GEOID: "26041",
        NAME: "Delta",
        LSAD: "06",
        ALAND: 3033137837,
        AWATER: 2124389418
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.367849, 45.985320999057372],
            [-87.242885, 45.985116999057368],
            [-87.241506, 46.159102999057595],
            [-87.116138, 46.159049999057601],
            [-86.615601, 46.158690999057598],
            [-86.490142, 46.1581779990576],
            [-86.49025, 45.896336999057283],
            [-86.459880517749397, 45.750226549653128],
            [-86.54143, 45.708109999057086],
            [-86.616972, 45.620580999057019],
            [-86.636895, 45.542052999056956],
            [-86.712328, 45.610938999057012],
            [-86.705184, 45.690900999057085],
            [-86.647319, 45.732617999057112],
            [-86.773279, 45.811384999057196],
            [-86.838746, 45.72230699905711],
            [-86.964275, 45.672760999057068],
            [-87.070442, 45.718778999057093],
            [-87.172241, 45.661787999057061],
            [-87.253449628780189, 45.550115589946067],
            [-87.327502, 45.551249999056964],
            [-87.325391, 45.898664999057281],
            [-87.367849, 45.985320999057372]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "013",
        COUNTYNS: "01622949",
        AFFGEOID: "0500000US26013",
        GEOID: "26013",
        NAME: "Baraga",
        LSAD: "06",
        ALAND: 2326929577,
        AWATER: 441604239
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.678983, 46.851821999058835],
            [-88.551943, 46.851794999058825],
            [-88.552185, 46.938532999059021],
            [-88.439363185943805, 46.941982424059432],
            [-88.455404, 46.923320999059001],
            [-88.477935, 46.850559999058838],
            [-88.372681, 46.872276999058883],
            [-88.244437, 46.929611999058999],
            [-88.143688, 46.966664999059105],
            [-88.065192, 46.918562999058985],
            [-88.044521682281484, 46.917449944335175],
            [-88.044709, 46.765352999058649],
            [-87.991389, 46.765158999058656],
            [-87.991546, 46.679112999058489],
            [-88.115843, 46.678380999058476],
            [-88.116571, 46.41995099905801],
            [-88.679392, 46.420138999058004],
            [-88.678983, 46.851821999058835]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "151",
        COUNTYNS: "00695796",
        AFFGEOID: "0500000US28151",
        GEOID: "28151",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1877069905,
        AWATER: 94495553
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.215671, 33.529422999130809],
            [-90.763509, 33.529388999130802],
            [-90.763773, 33.270358999134146],
            [-90.716277, 33.270031999134154],
            [-90.680319, 33.20197099913505],
            [-90.679607, 33.102482999136356],
            [-90.701845, 33.096293999136435],
            [-90.914465, 33.097416999136435],
            [-90.914337, 33.007696999137622],
            [-90.965433, 33.010544999137579],
            [-91.159606098012986, 33.011242320444374],
            [-91.120379, 33.054529999136996],
            [-91.180836, 33.098363999136403],
            [-91.153015, 33.135092999135921],
            [-91.104317, 33.13159799913597],
            [-91.084366, 33.180855999135325],
            [-91.068708, 33.232935999134632],
            [-91.086137, 33.273651999134117],
            [-91.125539, 33.280254999134023],
            [-91.142219, 33.34898899913312],
            [-91.113764, 33.393123999132555],
            [-91.147663, 33.427171999132113],
            [-91.171799, 33.46234199913166],
            [-91.189375, 33.493004999131266],
            [-91.215671, 33.529422999130809]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "001",
        COUNTYNS: "01719627",
        AFFGEOID: "0500000US30001",
        GEOID: "30001",
        NAME: "Beaverhead",
        LSAD: "06",
        ALAND: 14355492113,
        AWATER: 78345297
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.948321482569995, 45.682577357498573],
            [-113.870452, 45.781573999057166],
            [-113.829875, 45.750745999057131],
            [-113.697639, 45.843544999057215],
            [-113.528581, 45.881159999057246],
            [-113.516144, 45.938813999057324],
            [-113.377852, 45.759975999057133],
            [-113.179791, 45.885008999057256],
            [-113.085517, 45.860647999057235],
            [-112.988358, 45.808475999057187],
            [-112.759339, 45.748208999057127],
            [-112.687054, 45.624853999057024],
            [-112.691417, 45.483517999056929],
            [-112.628609, 45.4405859990569],
            [-112.505568, 45.4430089990569],
            [-112.458062, 45.349909999056848],
            [-112.456854, 45.088508999056764],
            [-112.333899, 45.088422999056768],
            [-112.334248, 45.000518999056752],
            [-112.191187, 45.000511999056762],
            [-112.167108, 44.826737999056775],
            [-112.065678, 44.825984999056772],
            [-112.065797, 44.783474999056779],
            [-111.781157, 44.781026999056763],
            [-111.781099, 44.708844999056787],
            [-111.474963, 44.708772999056812],
            [-111.456947232493988, 44.695640736896301],
            [-111.468833, 44.679334999056799],
            [-111.519126, 44.582915999056837],
            [-111.562814, 44.555208999056845],
            [-111.617107034305008, 44.557127247475456],
            [-111.704218, 44.560204999056836],
            [-111.807914, 44.511715999056861],
            [-111.870504, 44.564032999056856],
            [-112.034133, 44.537715999056857],
            [-112.125101, 44.528526999056858],
            [-112.221698, 44.54351899905685],
            [-112.286187, 44.568471999056847],
            [-112.358917, 44.528846999056867],
            [-112.387389, 44.448057999056914],
            [-112.473207, 44.480026999056889],
            [-112.601863, 44.49101499905688],
            [-112.707815, 44.503022999056874],
            [-112.735084, 44.499158999056874],
            [-112.828191, 44.442471999056899],
            [-112.821896, 44.407435999056929],
            [-112.826826881265006, 44.405202430824438],
            [-112.881769, 44.380314999056942],
            [-112.951146, 44.416698999056933],
            [-113.006846, 44.4717149990569],
            [-113.006828, 44.518438999056876],
            [-113.061071, 44.577328999056839],
            [-113.049349, 44.629379999056816],
            [-113.101154, 44.708577999056793],
            [-113.131387, 44.764737999056777],
            [-113.247166, 44.822949999056775],
            [-113.301508, 44.798984999056778],
            [-113.377153, 44.834857999056766],
            [-113.422376, 44.842594999056764],
            [-113.474573, 44.910845999056761],
            [-113.448958, 44.953543999056755],
            [-113.437726, 45.006966999056758],
            [-113.45197, 45.059246999056768],
            [-113.510819, 45.099901999056762],
            [-113.57467, 45.128410999056783],
            [-113.650064, 45.234709999056811],
            [-113.735601, 45.325264999056834],
            [-113.73239, 45.385057999056876],
            [-113.763368, 45.427731999056881],
            [-113.759986, 45.480734999056921],
            [-113.802849, 45.523158999056953],
            [-113.806729, 45.602145999057008],
            [-113.861404, 45.623659999057026],
            [-113.898883, 45.644166999057042],
            [-113.948321482569995, 45.682577357498573]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "33",
        COUNTYFP: "019",
        COUNTYNS: "00873183",
        AFFGEOID: "0500000US33019",
        GEOID: "33019",
        NAME: "Sullivan",
        LSAD: "06",
        ALAND: 1392157812,
        AWATER: 37865613
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.440563, 43.215253999058476],
            [-72.433611197959493, 43.232903811320263],
            [-72.421583, 43.263441999058401],
            [-72.402532, 43.320379999058297],
            [-72.406901858031688, 43.337448837793062],
            [-72.413377, 43.362740999058218],
            [-72.396923025035903, 43.428922115056693],
            [-72.39689079629089, 43.429051745393203],
            [-72.380894, 43.493393999057979],
            [-72.37944, 43.574068999057857],
            [-72.333596501356297, 43.605591340646903],
            [-72.106412, 43.553484999057879],
            [-72.090893, 43.528856999057922],
            [-72.069516, 43.553171999057881],
            [-71.93531, 43.528879999057914],
            [-72.011363, 43.43687099905808],
            [-72.053752, 43.441096999058082],
            [-72.090146, 43.322552999058281],
            [-72.009074, 43.187881999058547],
            [-72.035544, 43.180282999058562],
            [-72.038149, 43.128806999058661],
            [-72.178497, 43.131865999058668],
            [-72.156661, 43.181967999058564],
            [-72.262483, 43.169951999058583],
            [-72.431632, 43.135634999058659],
            [-72.450395939215895, 43.161213515965009],
            [-72.440563, 43.215253999058476]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "013",
        COUNTYNS: "00974105",
        AFFGEOID: "0500000US36013",
        GEOID: "36013",
        NAME: "Chautauqua",
        LSAD: "06",
        ALAND: 2745967850,
        AWATER: 1139487496
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.761951, 42.269859999060898],
            [-79.627484, 42.324685999060719],
            [-79.453533, 42.411156999060466],
            [-79.381943, 42.466490999060319],
            [-79.283364, 42.511227999060189],
            [-79.193232, 42.545880999060088],
            [-79.138569, 42.564461999060036],
            [-79.1359444040216, 42.569178709033629],
            [-79.107778, 42.569964999060026],
            [-79.060777, 42.537852999060114],
            [-79.061264403159896, 41.998838108972762],
            [-79.472472, 41.998254999061771],
            [-79.610835774986697, 41.998519903170163],
            [-79.761313, 41.998807999061782],
            [-79.762122, 42.131245999061328],
            [-79.761951, 42.269859999060898]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "129",
        COUNTYNS: "01101852",
        AFFGEOID: "0500000US40129",
        GEOID: "40129",
        NAME: "Roger Mills",
        LSAD: "06",
        ALAND: 2955528251,
        AWATER: 13695174
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.000396198329014, 35.880947999100471],
            [-99.925147, 35.913663999103342],
            [-99.888309, 35.987846999102587],
            [-99.809444, 36.000043999102459],
            [-99.801389, 35.910644999103376],
            [-99.717101, 35.859768999103892],
            [-99.619813, 35.842088999104071],
            [-99.558486, 35.865987999103837],
            [-99.495812, 35.978731999102685],
            [-99.381022, 36.012924999102331],
            [-99.381082, 35.898829999103491],
            [-99.375906, 35.875045999103733],
            [-99.375817, 35.812187999104388],
            [-99.377052, 35.551740999107125],
            [-99.364141, 35.50879299910757],
            [-99.575598, 35.508860999107569],
            [-99.57582, 35.421654999108512],
            [-100.000388844143004, 35.422363987417903],
            [-100.000392, 35.619114999106415],
            [-100.000396198329014, 35.880947999100471]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "157",
        COUNTYNS: "01639790",
        AFFGEOID: "0500000US47157",
        GEOID: "47157",
        NAME: "Shelby",
        LSAD: "06",
        ALAND: 1977720315,
        AWATER: 55485268
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.300697, 35.028792999112817],
            [-90.265296, 35.040292999112687],
            [-90.197146, 35.050730999112567],
            [-90.181387, 35.091400999112118],
            [-90.160058, 35.128829999111694],
            [-90.09061, 35.118286999111817],
            [-90.099777, 35.164473999111301],
            [-90.093285, 35.203281999110892],
            [-90.097947, 35.249982999110372],
            [-90.166594, 35.274587999110111],
            [-90.121864, 35.304534999109791],
            [-90.087903, 35.363269999109136],
            [-90.112504, 35.410152999108632],
            [-90.070549, 35.423290999108488],
            [-90.045306, 35.415434999108562],
            [-90.039777, 35.396530999108769],
            [-89.865515, 35.390769999108841],
            [-89.70248, 35.408583999108643],
            [-89.632776, 35.375823999109002],
            [-89.644283834092107, 34.994067209675713],
            [-89.724315837968589, 34.994186957866702],
            [-89.759612642234373, 34.994239770844707],
            [-89.795187, 34.994292999113206],
            [-90.309297, 34.995693999113186],
            [-90.300697, 35.028792999112817]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "077",
        COUNTYNS: "01383824",
        AFFGEOID: "0500000US48077",
        GEOID: "48077",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 2819788075,
        AWATER: 72590626
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.423533, 34.081949475129797],
            [-98.414426, 34.085073999123857],
            [-98.398441, 34.128455999123325],
            [-98.364023, 34.157108999122975],
            [-98.31875, 34.146420999123102],
            [-98.225282, 34.127244999123334],
            [-98.16912, 34.114170999123495],
            [-98.138486025746403, 34.141206018834268],
            [-98.123377, 34.15453999912301],
            [-98.099328, 34.104294999123617],
            [-98.099096, 34.0486389991243],
            [-98.082839, 34.002411999124867],
            [-98.005667, 33.995963999124946],
            [-97.947572, 33.99105299912501],
            [-97.953695, 33.924372999125822],
            [-97.951905950855178, 33.891225813983546],
            [-97.977869, 33.88873799912627],
            [-97.978919, 33.467128999131596],
            [-98.421419, 33.46679699913161],
            [-98.423577, 33.836046999126928],
            [-98.423533, 34.081949475129797]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "003",
        COUNTYNS: "01533502",
        AFFGEOID: "0500000US53003",
        GEOID: "53003",
        NAME: "Asotin",
        LSAD: "06",
        ALAND: 1647443944,
        AWATER: 11275706
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.479988, 46.121993999057551],
            [-117.419482, 46.121955999057548],
            [-117.419334, 46.383138999057941],
            [-117.228796, 46.411305999057987],
            [-117.22812, 46.462244999058065],
            [-117.208628, 46.423706999058005],
            [-117.036645748421009, 46.426100481713412],
            [-117.035545, 46.410011999057978],
            [-117.062748, 46.353623999057895],
            [-116.99726, 46.30315099905782],
            [-116.964379, 46.253281999057727],
            [-116.962966, 46.199679999057658],
            [-116.923958, 46.17091999905761],
            [-116.935473, 46.142447999057566],
            [-116.981962, 46.084914999057496],
            [-116.942656, 46.060999999057472],
            [-116.915989, 45.995412999057393],
            [-117.353928, 45.996348999057382],
            [-117.47993726365398, 45.997566765899592],
            [-117.479988, 46.121993999057551]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "089",
        COUNTYNS: "01581105",
        AFFGEOID: "0500000US55089",
        GEOID: "55089",
        NAME: "Ozaukee",
        LSAD: "06",
        ALAND: 603471584,
        AWATER: 2287458200
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.039985, 43.367754999058199],
            [-88.040528, 43.542360999057912],
            [-87.791020140028493, 43.543015443985901],
            [-87.793239, 43.49278299905798],
            [-87.840955, 43.420676999058109],
            [-87.889207, 43.307651999058308],
            [-87.896286, 43.197107999058538],
            [-87.896579311188788, 43.192134631585049],
            [-87.924662, 43.192144999058542],
            [-87.959456, 43.192404999058546],
            [-88.063353, 43.192116999058541],
            [-88.039985, 43.367754999058199]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "121",
        COUNTYNS: "01674630",
        AFFGEOID: "0500000US51121",
        GEOID: "51121",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 1001920374,
        AWATER: 6291775
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.606962, 37.244870999090708],
            [-80.500823, 37.275642999090429],
            [-80.430943, 37.316377999090086],
            [-80.32484, 37.368832999089626],
            [-80.262184, 37.341525999089868],
            [-80.189592, 37.233448999090804],
            [-80.178125, 37.113399999091875],
            [-80.347858, 37.021592999092682],
            [-80.520379, 37.011233999092774],
            [-80.543933, 36.984638999093029],
            [-80.543145, 37.048464999092445],
            [-80.580469, 37.08966199909208],
            [-80.556479, 37.099989999091996],
            [-80.551262, 37.11861999909182],
            [-80.53303, 37.118087999091834],
            [-80.50854, 37.135707999091672],
            [-80.511287, 37.147781999091571],
            [-80.529762, 37.133841999091679],
            [-80.515768, 37.201910999091076],
            [-80.585999, 37.182763999091257],
            [-80.606962, 37.244870999090708]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "061",
        COUNTYNS: "00066872",
        AFFGEOID: "0500000US05061",
        GEOID: "05061",
        NAME: "Howard",
        LSAD: "06",
        ALAND: 1524380220,
        AWATER: 17475726
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.254668, 34.355480999120601],
            [-93.935371, 34.350096999120652],
            [-93.92583, 34.186495999122627],
            [-93.820722, 34.183801999122657],
            [-93.823076, 34.008892999124797],
            [-93.831452, 33.747708999128037],
            [-93.957315, 33.750939999127993],
            [-93.958828, 33.750993999127992],
            [-93.964656, 33.796153999127434],
            [-94.069934, 33.926779999125806],
            [-94.057534, 33.968218999125284],
            [-94.09543, 34.078545999123932],
            [-94.089758, 34.186834999122617],
            [-94.243739, 34.192500999122558],
            [-94.254668, 34.355480999120601]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "107",
        COUNTYNS: "00277318",
        AFFGEOID: "0500000US06107",
        GEOID: "06107",
        NAME: "Tulare",
        LSAD: "06",
        ALAND: 12494838453,
        AWATER: 37259234
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.546131, 36.508962999097456],
            [-119.466322, 36.575237999096828],
            [-119.305102, 36.573724999096832],
            [-119.304625, 36.660605999096013],
            [-119.174791, 36.658877999096028],
            [-119.088295, 36.657513999096032],
            [-118.984779, 36.657146999096042],
            [-118.982441, 36.741645999095248],
            [-118.360586, 36.744772999095225],
            [-118.366328, 36.691634999095719],
            [-118.274624, 36.597333999096612],
            [-118.290523, 36.558534999096977],
            [-118.239021, 36.523634999097311],
            [-118.21482, 36.435035999098154],
            [-118.100317, 36.346136999099024],
            [-118.127147, 36.279655999099674],
            [-118.033611, 36.008943999102385],
            [-118.003577, 35.98371799910263],
            [-117.980761, 35.867514999103818],
            [-118.005489, 35.862698999103863],
            [-118.008043, 35.789160999104638],
            [-118.067719, 35.791536999104601],
            [-118.803729, 35.790346999104614],
            [-119.053437, 35.79074799910461],
            [-119.538116, 35.789566999104622],
            [-119.536161, 36.050781999101943],
            [-119.529358, 36.269849999099769],
            [-119.474607, 36.269024999099784],
            [-119.474892, 36.4009529990985],
            [-119.528792, 36.401464999098494],
            [-119.528286, 36.487544999097665],
            [-119.573194, 36.488834999097641],
            [-119.546131, 36.508962999097456]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "025",
        COUNTYNS: "00198128",
        AFFGEOID: "0500000US08025",
        GEOID: "08025",
        NAME: "Crowley",
        LSAD: "06",
        ALAND: 2039427875,
        AWATER: 33422982
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.053921, 38.522392999080431],
            [-103.61299, 38.52254799908043],
            [-103.504666, 38.516408999080468],
            [-103.506985, 38.342255999081772],
            [-103.501764, 38.265015999082351],
            [-103.509364, 38.172513999083066],
            [-103.619283, 38.17145499908306],
            [-103.619172, 38.113354999083519],
            [-103.835616, 38.113339999083522],
            [-104.058242, 38.146491999083253],
            [-104.053921, 38.522392999080431]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "033",
        COUNTYNS: "00659462",
        AFFGEOID: "0500000US27033",
        GEOID: "27033",
        NAME: "Cottonwood",
        LSAD: "06",
        ALAND: 1657445964,
        AWATER: 22318968
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.462547, 44.195941999057077],
            [-95.121234, 44.195273999057093],
            [-95.108777, 44.195043999057098],
            [-95.100892, 44.180295999057108],
            [-95.100221, 44.10803399905717],
            [-94.859803, 44.108015999057166],
            [-94.859388, 43.848092999057464],
            [-95.100586, 43.847936999057467],
            [-95.452502, 43.847952999057455],
            [-95.462427, 43.847910999057454],
            [-95.462547, 44.195941999057077]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "015",
        COUNTYNS: "00659453",
        AFFGEOID: "0500000US27015",
        GEOID: "27015",
        NAME: "Brown",
        LSAD: "06",
        ALAND: 1582659431,
        AWATER: 18584892
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.10748, 44.281767999057017],
            [-94.866259, 44.282371999057013],
            [-94.865824, 44.498217999056898],
            [-94.81488, 44.484449999056892],
            [-94.78063, 44.4566499990569],
            [-94.666062, 44.394047999056944],
            [-94.491333, 44.359675999056968],
            [-94.371731, 44.264447999057026],
            [-94.36885, 44.108697999057171],
            [-94.859803, 44.108015999057166],
            [-95.100221, 44.10803399905717],
            [-95.100892, 44.180295999057108],
            [-95.108777, 44.195043999057098],
            [-95.10748, 44.281767999057017]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "041",
        COUNTYNS: "00450349",
        AFFGEOID: "0500000US18041",
        GEOID: "18041",
        NAME: "Fayette",
        LSAD: "06",
        ALAND: 556901556,
        AWATER: 366408
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.301584, 39.787578999072053],
            [-85.221118, 39.788448999072052],
            [-85.185089, 39.715514999072468],
            [-85.034575, 39.714763999072481],
            [-85.036087, 39.526212999073636],
            [-85.29811, 39.525475999073635],
            [-85.301584, 39.787578999072053]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "047",
        COUNTYNS: "00929114",
        AFFGEOID: "0500000US35047",
        GEOID: "35047",
        NAME: "San Miguel",
        LSAD: "06",
        ALAND: 12230067556,
        AWATER: 51380263
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.724461, 35.871193999103774],
            [-105.34783, 35.87069499910379],
            [-105.110049, 35.807903999104433],
            [-105.025454, 35.754196999104991],
            [-105.018811, 35.769438999104821],
            [-104.750645, 35.783126999104688],
            [-104.37012, 35.779301999104732],
            [-104.021702, 35.781493999104704],
            [-103.976901, 35.801746999104495],
            [-103.723609, 35.422784999108501],
            [-103.637053, 35.389661999108853],
            [-103.63713, 35.240818999110466],
            [-103.85753, 35.242404999110455],
            [-104.125121, 35.142057999111557],
            [-104.125136, 35.21569599911075],
            [-105.291169, 35.21648899911073],
            [-105.290791, 35.042029999112671],
            [-105.714419, 35.041604999112671],
            [-105.724461, 35.871193999103774]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "131",
        COUNTYNS: "00516912",
        AFFGEOID: "0500000US21131",
        GEOID: "21131",
        NAME: "Leslie",
        LSAD: "06",
        ALAND: 1038165209,
        AWATER: 9230600
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.551303, 37.167509999091394],
            [-83.507886, 37.235876999090777],
            [-83.39873, 37.320563999090048],
            [-83.32462, 37.225560999090874],
            [-83.201887, 37.174129999091328],
            [-83.167233, 37.102371999091964],
            [-83.182104, 37.021114999092688],
            [-83.26002, 36.968950999093153],
            [-83.399656, 36.92095499909361],
            [-83.408614, 36.890217999093885],
            [-83.489813, 36.895412999093828],
            [-83.509083, 36.938508999093436],
            [-83.553101, 37.079280999092184],
            [-83.551303, 37.167509999091394]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "203",
        COUNTYNS: "00516948",
        AFFGEOID: "0500000US21203",
        GEOID: "21203",
        NAME: "Rockcastle",
        LSAD: "06",
        ALAND: 819848737,
        AWATER: 4016362
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.446719, 37.485892999088641],
            [-84.37444, 37.47266299908874],
            [-84.347805, 37.539063999088178],
            [-84.268112, 37.51530999908838],
            [-84.199489, 37.522857999088323],
            [-84.12913, 37.357834999089725],
            [-84.137898, 37.319758999090062],
            [-84.254315, 37.239081999090757],
            [-84.289076, 37.151739999091525],
            [-84.303191, 37.166117999091391],
            [-84.502932, 37.328800999089978],
            [-84.446719, 37.485892999088641]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "007",
        COUNTYNS: "00558414",
        AFFGEOID: "0500000US22007",
        GEOID: "22007",
        NAME: "Assumption",
        LSAD: "15",
        ALAND: 877133400,
        AWATER: 66996288
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.259388, 30.000199999182449],
            [-91.224356, 30.025335999182037],
            [-91.105951, 30.062477999181429],
            [-91.089103, 30.06251399918143],
            [-91.074581, 30.081630999181112],
            [-90.963693, 30.066449999181359],
            [-90.959869, 29.97792399918281],
            [-90.925849, 29.934954999183514],
            [-90.885589, 29.905352999184007],
            [-90.885195, 29.877504999184467],
            [-90.925046, 29.830376999185255],
            [-90.947496, 29.792305999185885],
            [-91.006743, 29.714770999187177],
            [-91.082602, 29.626367999188648],
            [-91.105395, 29.64328099918837],
            [-91.10001, 29.699401999187437],
            [-91.092682, 29.800999999185745],
            [-91.182817, 29.846171999184985],
            [-91.255229, 29.971456999182919],
            [-91.259388, 30.000199999182449]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "099",
        COUNTYNS: "00974147",
        AFFGEOID: "0500000US36099",
        GEOID: "36099",
        NAME: "Seneca",
        LSAD: "06",
        ALAND: 838403626,
        AWATER: 172828921
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.981334, 42.861212999059269],
            [-76.963926, 43.013156999058928],
            [-76.713806, 43.024034999058898],
            [-76.73674, 42.970285999059023],
            [-76.731636, 42.720739999059624],
            [-76.666543, 42.623456999059869],
            [-76.585989, 42.54990999906007],
            [-76.696655, 42.546789999060088],
            [-76.895596, 42.541536999060099],
            [-76.895349, 42.65625499905979],
            [-76.947641, 42.759178999059522],
            [-76.971392, 42.76422299905952],
            [-76.981334, 42.861212999059269]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "143",
        COUNTYNS: "00659516",
        AFFGEOID: "0500000US27143",
        GEOID: "27143",
        NAME: "Sibley",
        LSAD: "06",
        ALAND: 1524941287,
        AWATER: 30470667
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.629489, 44.716662999056794],
            [-94.498074, 44.717170999056798],
            [-94.497835, 44.629920999056814],
            [-94.254719, 44.630310999056817],
            [-94.254076, 44.717854999056804],
            [-94.010494, 44.717463999056797],
            [-93.888636, 44.717043999056791],
            [-93.888831, 44.673408999056804],
            [-93.768176, 44.673519999056801],
            [-93.768039, 44.640302999056814],
            [-93.902479, 44.587646999056844],
            [-93.910405, 44.543162999056861],
            [-93.885373, 44.518208999056874],
            [-93.92955, 44.456715999056897],
            [-94.6242, 44.456029999056909],
            [-94.629489, 44.716662999056794]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "131",
        COUNTYNS: "00695786",
        AFFGEOID: "0500000US28131",
        GEOID: "28131",
        NAME: "Stone",
        LSAD: "06",
        ALAND: 1153800122,
        AWATER: 6712353
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.340806, 30.90991199916791],
            [-89.137967, 30.90987699916791],
            [-88.885038, 30.910787999167898],
            [-88.884534, 30.735590999170629],
            [-88.884533, 30.677291999171544],
            [-89.240073, 30.67715999917155],
            [-89.341446, 30.647719999172011],
            [-89.340806, 30.90991199916791]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "129",
        COUNTYNS: "00695785",
        AFFGEOID: "0500000US28129",
        GEOID: "28129",
        NAME: "Smith",
        LSAD: "06",
        ALAND: 1647926085,
        AWATER: 2599432
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.730424, 32.222038999148424],
            [-89.701621, 32.223756999148407],
            [-89.322692, 32.223471999148408],
            [-89.31656, 31.802089999154475],
            [-89.401227, 31.796862999154545],
            [-89.653025, 31.780652999154785],
            [-89.662086, 32.04723899915092],
            [-89.730066, 32.04707199915093],
            [-89.730424, 32.222038999148424]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "095",
        COUNTYNS: "00758502",
        AFFGEOID: "0500000US29095",
        GEOID: "29095",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 1565601892,
        AWATER: 30717815
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.608334, 38.981805999077181],
            [-94.607870353373997, 39.044085095555246],
            [-94.607354, 39.113443999076296],
            [-94.591933, 39.155002999076011],
            [-94.586353, 39.195730999075735],
            [-94.576552, 39.181182999075844],
            [-94.586944, 39.123330999076224],
            [-94.549573, 39.130894999076176],
            [-94.539232, 39.142702999076086],
            [-94.523463, 39.148770999076056],
            [-94.495411, 39.150393999076044],
            [-94.484616, 39.145503999076077],
            [-94.46159, 39.120371999076234],
            [-94.425764, 39.141806999076103],
            [-94.402328, 39.164765999075954],
            [-94.399928, 39.174802999075879],
            [-94.408628, 39.193602999075758],
            [-94.36598, 39.16322699907596],
            [-94.335213, 39.183336999075827],
            [-94.290785, 39.236914999075474],
            [-94.212515, 39.20708599907568],
            [-94.173519, 39.213828999075623],
            [-94.104823, 39.143506999076088],
            [-94.110005, 39.01498699907696],
            [-94.114175, 38.917303999077618],
            [-94.119096, 38.833166999078202],
            [-94.608960343749501, 38.847211314523108],
            [-94.608334, 38.981805999077181]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "061",
        COUNTYNS: "00933052",
        AFFGEOID: "0500000US35061",
        GEOID: "35061",
        NAME: "Valencia",
        LSAD: "06",
        ALAND: 2762826562,
        AWATER: 2787804
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.202882, 34.957571999113618],
            [-107.066562, 34.957189999113609],
            [-107.024996, 34.871017999114592],
            [-106.720666, 34.870052999114598],
            [-106.685801, 34.906357999114192],
            [-106.423996, 34.901867999114245],
            [-106.414475, 34.892224999114354],
            [-106.411781, 34.870251999114615],
            [-106.463868, 34.870193999114605],
            [-106.464546, 34.810516999115286],
            [-106.464775, 34.783202999115595],
            [-106.466639, 34.754863999115919],
            [-106.466707, 34.717010999116361],
            [-106.46979, 34.521216999118636],
            [-106.416881, 34.521714999118629],
            [-106.416924, 34.439686999119587],
            [-106.768281, 34.53126199911852],
            [-106.882658, 34.579530999117949],
            [-107.201743, 34.578756999117957],
            [-107.202882, 34.957571999113618]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "065",
        COUNTYNS: "00974131",
        AFFGEOID: "0500000US36065",
        GEOID: "36065",
        NAME: "Oneida",
        LSAD: "06",
        ALAND: 3139921985,
        AWATER: 117340432
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.886756, 43.325020999058282],
            [-75.814627, 43.483577999058006],
            [-75.756213, 43.470387999058033],
            [-75.5335, 43.419755999058111],
            [-75.11016, 43.615228999057784],
            [-75.076581, 43.330704999058263],
            [-75.161886, 43.257348999058415],
            [-75.069165, 43.227332999058468],
            [-75.219106, 43.052468999058831],
            [-75.212158, 42.879972999059227],
            [-75.247963, 42.871603999059246],
            [-75.437167, 42.863318999059267],
            [-75.444173, 42.933088999059095],
            [-75.542803, 42.931773999059104],
            [-75.552774, 43.037553999058872],
            [-75.723929, 43.16259599905861],
            [-75.884275, 43.155561999058612],
            [-75.886756, 43.325020999058282]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "135",
        COUNTYNS: "01008576",
        AFFGEOID: "0500000US37135",
        GEOID: "37135",
        NAME: "Orange",
        LSAD: "06",
        ALAND: 1029600383,
        AWATER: 8876665
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.257952, 36.243444999100028],
            [-79.153997, 36.241874999100048],
            [-78.950597, 36.239319999100069],
            [-79.0095, 35.902400999103456],
            [-79.016305, 35.863209999103859],
            [-79.082036, 35.860775999103893],
            [-79.249538, 35.876809999103727],
            [-79.268243, 35.909181999103389],
            [-79.257952, 36.243444999100028]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "065",
        COUNTYNS: "00758487",
        AFFGEOID: "0500000US29065",
        GEOID: "29065",
        NAME: "Dent",
        LSAD: "06",
        ALAND: 1949640578,
        AWATER: 4519381
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.806219, 37.791285999086092],
            [-91.528797, 37.788988999086108],
            [-91.308311, 37.787185999086127],
            [-91.310655, 37.700047999086834],
            [-91.153345, 37.697339999086857],
            [-91.155073, 37.588091999087766],
            [-91.312458, 37.592823999087727],
            [-91.314236, 37.505131999088455],
            [-91.210984, 37.501910999088494],
            [-91.211863, 37.41527699908923],
            [-91.646626, 37.422730999089168],
            [-91.75504, 37.424109999089154],
            [-91.754795, 37.598767999087677],
            [-91.809105, 37.598862999087686],
            [-91.806219, 37.791285999086092]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "121",
        COUNTYNS: "01008571",
        AFFGEOID: "0500000US37121",
        GEOID: "37121",
        NAME: "Mitchell",
        LSAD: "06",
        ALAND: 573038008,
        AWATER: 1629508
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.409458, 36.083408999101621],
            [-82.346857, 36.115208999101313],
            [-82.297655, 36.133509999101122],
            [-82.26569, 36.127613999101186],
            [-82.220253010286598, 36.153820038944019],
            [-82.211251, 36.159011999100862],
            [-82.140847, 36.13621599910109],
            [-82.127146, 36.104416999101417],
            [-82.081148917132396, 36.105692113433996],
            [-82.034104, 35.942056999103052],
            [-81.981954, 35.911360999103366],
            [-81.980168, 35.885045999103632],
            [-82.133484, 35.82330899910427],
            [-82.197834, 36.015331999102308],
            [-82.253966, 36.005320999102402],
            [-82.351672, 36.084333999101609],
            [-82.416937594673186, 36.072970009810227],
            [-82.409458, 36.083408999101621]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "057",
        COUNTYNS: "01383814",
        AFFGEOID: "0500000US48057",
        GEOID: "48057",
        NAME: "Calhoun",
        LSAD: "06",
        ALAND: 1312707024,
        AWATER: 1361884769
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.930367, 28.586727999206666],
            [-96.853757, 28.635198999205802],
            [-96.722814, 28.66975899920519],
            [-96.665707, 28.705704999204546],
            [-96.659864, 28.70167099920462],
            [-96.643442, 28.712104999204431],
            [-96.428394, 28.703522999204587],
            [-96.39954, 28.730230999204114],
            [-96.404445, 28.674408999205109],
            [-96.323522, 28.67559699920508],
            [-96.322731, 28.642943999205663],
            [-96.378533159286789, 28.38986502969809],
            [-96.390376, 28.381814999210331],
            [-96.442849, 28.317665999211496],
            [-96.63201, 28.222820999213216],
            [-96.719627, 28.164593999214276],
            [-96.792158, 28.110496999215261],
            [-96.852070622730082, 28.059820860820793],
            [-96.830928, 28.107893999215307],
            [-96.790512, 28.319237999211463],
            [-96.764132, 28.426521999209527],
            [-96.813494, 28.430260999209466],
            [-96.890951, 28.507177999208086],
            [-96.930367, 28.586727999206666]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "003",
        COUNTYNS: "01383787",
        AFFGEOID: "0500000US48003",
        GEOID: "48003",
        NAME: "Andrews",
        LSAD: "06",
        ALAND: 3886850342,
        AWATER: 957039
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.064696, 32.522192999144224],
            [-102.211037, 32.523243999144206],
            [-102.211249, 32.086799999150365],
            [-102.287048, 32.086990999150345],
            [-102.799086, 32.085794999150366],
            [-102.844395, 32.087061999150343],
            [-103.064422401106, 32.08705099915035],
            [-103.064422, 32.145005999149525],
            [-103.064696, 32.522192999144224]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "50",
        COUNTYFP: "021",
        COUNTYNS: "01461767",
        AFFGEOID: "0500000US50021",
        GEOID: "50021",
        NAME: "Rutland",
        LSAD: "06",
        ALAND: 2408212536,
        AWATER: 38758591
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.414546, 43.658208999057727],
            [-73.393723, 43.699199999057669],
            [-73.361107151684294, 43.753233456341491],
            [-73.206322, 43.766774999057567],
            [-73.218956, 43.837290999057473],
            [-73.029938, 43.846715999057466],
            [-73.016831, 43.804952999057519],
            [-72.958853, 43.826541999057483],
            [-72.882038, 43.855332999057438],
            [-72.873366, 43.839632999057471],
            [-72.796653, 43.829386999057483],
            [-72.782812, 43.80433799905753],
            [-72.818605, 43.7463049990576],
            [-72.820043, 43.716833999057641],
            [-72.700945, 43.682830999057693],
            [-72.758005, 43.587516999057833],
            [-72.772829, 43.591564999057823],
            [-72.79107, 43.494999999057988],
            [-72.771543, 43.477861999058007],
            [-72.723995, 43.461693999058049],
            [-72.759799, 43.354143999058223],
            [-72.853439, 43.371075999058192],
            [-72.867902, 43.300741999058332],
            [-72.976152, 43.291640999058352],
            [-73.255355838057596, 43.314712397658312],
            [-73.252832, 43.363492999058209],
            [-73.242042, 43.534924999057921],
            [-73.292113, 43.584508999057832],
            [-73.327702, 43.625912999057768],
            [-73.395767, 43.568086999057861],
            [-73.424977, 43.598774999057817],
            [-73.414546, 43.658208999057727]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "750",
        COUNTYNS: "01498438",
        AFFGEOID: "0500000US51750",
        GEOID: "51750",
        NAME: "Radford",
        LSAD: "25",
        ALAND: 25061300,
        AWATER: 858713
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.595167, 37.12512499909176],
            [-80.592875, 37.127865999091739],
            [-80.573087, 37.139875999091643],
            [-80.553642, 37.146729999091576],
            [-80.548837, 37.146556999091572],
            [-80.529762, 37.133841999091679],
            [-80.511287, 37.147781999091571],
            [-80.50854, 37.135707999091672],
            [-80.53303, 37.118087999091834],
            [-80.551262, 37.11861999909182],
            [-80.556479, 37.099989999091996],
            [-80.580469, 37.08966199909208],
            [-80.590406, 37.101999999091973],
            [-80.596409, 37.118888999091809],
            [-80.595167, 37.12512499909176]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "057",
        COUNTYNS: "01581088",
        AFFGEOID: "0500000US55057",
        GEOID: "55057",
        NAME: "Juneau",
        LSAD: "06",
        ALAND: 1986622513,
        AWATER: 98737513
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.312694, 43.981383999057307],
            [-90.312522, 44.15519799905713],
            [-90.31268, 44.248749999057054],
            [-90.080691, 44.249010999057035],
            [-89.902783, 44.249472999057048],
            [-89.923387, 44.152415999057133],
            [-90.025953, 44.091747999057191],
            [-89.954449, 43.921985999057377],
            [-89.980676, 43.905622999057385],
            [-89.865132, 43.783413999057558],
            [-89.78702, 43.667148999057716],
            [-89.784901, 43.641050999057761],
            [-89.785809, 43.641048999057745],
            [-90.312404, 43.640987999057749],
            [-90.312194, 43.731478999057629],
            [-90.312693, 43.981334999057303],
            [-90.312694, 43.981383999057307]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "089",
        COUNTYNS: "01008562",
        AFFGEOID: "0500000US37089",
        GEOID: "37089",
        NAME: "Henderson",
        LSAD: "06",
        ALAND: 966142717,
        AWATER: 5556898
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.673343, 35.459452999108088],
            [-82.532713, 35.416614999108553],
            [-82.495208, 35.445185999108247],
            [-82.332822, 35.500219999107664],
            [-82.265795, 35.467817999108007],
            [-82.280563, 35.44306899910827],
            [-82.261305, 35.393197999108814],
            [-82.359152, 35.244488999110438],
            [-82.353175272919486, 35.198714128711636],
            [-82.411301, 35.202482999110892],
            [-82.455609, 35.177424999111167],
            [-82.53256, 35.155616999111409],
            [-82.5777151484413, 35.146475346959001],
            [-82.619248, 35.303707999109783],
            [-82.745139, 35.422966999108496],
            [-82.673343, 35.459452999108088]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "287",
        COUNTYNS: "01383929",
        AFFGEOID: "0500000US48287",
        GEOID: "48287",
        NAME: "Lee",
        LSAD: "06",
        ALAND: 1629205103,
        AWATER: 13106451
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.334463, 30.40284299917591],
            [-97.155219, 30.457343999175048],
            [-96.963629, 30.557168999173445],
            [-96.918902, 30.539211999173737],
            [-96.87711, 30.447944999175192],
            [-96.743672, 30.319756999177237],
            [-96.640883, 30.296785999177615],
            [-96.734469, 30.241228999178507],
            [-96.794552, 30.160544999179823],
            [-96.845857, 30.116026999180548],
            [-97.018379, 30.032405999181915],
            [-97.024461, 30.051434999181605],
            [-97.081834, 30.259356999178223],
            [-97.158811, 30.31069499917739],
            [-97.199578, 30.337832999176968],
            [-97.251707, 30.362847999176555],
            [-97.334463, 30.40284299917591]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "149",
        COUNTYNS: "01008579",
        AFFGEOID: "0500000US37149",
        GEOID: "37149",
        NAME: "Polk",
        LSAD: "06",
        ALAND: 615606300,
        AWATER: 1964035
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.359152, 35.244488999110438],
            [-82.261305, 35.393197999108814],
            [-82.151916, 35.407718999108667],
            [-81.968788, 35.257763999110281],
            [-81.969358401257594, 35.186927087511556],
            [-82.039651, 35.189448999111029],
            [-82.048391229647876, 35.189637542486523],
            [-82.2162487192291, 35.193258547764188],
            [-82.295354, 35.194964999110972],
            [-82.353175272919486, 35.198714128711636],
            [-82.359152, 35.244488999110438]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "029",
        COUNTYNS: "01605079",
        AFFGEOID: "0500000US56029",
        GEOID: "56029",
        NAME: "Park",
        LSAD: "06",
        ALAND: 17974153917,
        AWATER: 65512584
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.056888, 44.86665799905677],
            [-111.044318545430002, 45.000880002364461],
            [-111.044275, 45.001344999056769],
            [-110.785008, 45.002951999056769],
            [-110.705272, 44.992323999056758],
            [-110.324441, 44.999155999056761],
            [-109.99505, 45.00317399905677],
            [-109.798483772435006, 45.002920307341775],
            [-109.574321, 45.002630999056763],
            [-109.103445, 45.005903999056756],
            [-109.062262, 44.999622999056768],
            [-108.621493494730004, 44.999676370078269],
            [-108.592296, 44.871443999056766],
            [-108.57342, 44.520810999056863],
            [-108.573791, 44.171418999057117],
            [-108.550562, 44.168454999057118],
            [-108.550758, 44.079402999057216],
            [-108.792277, 44.079615999057189],
            [-108.791779, 43.991052999057288],
            [-108.915985, 43.990853999057293],
            [-108.912783, 43.904753999057398],
            [-109.092878, 43.904473999057402],
            [-109.093603, 43.817618999057508],
            [-109.312949, 43.813292999057502],
            [-109.442361, 43.930433999057357],
            [-109.542332, 43.961351999057314],
            [-109.641808, 43.922784999057363],
            [-109.665893, 43.845725999057464],
            [-109.7899, 43.803251999057515],
            [-109.843455, 43.819863999057503],
            [-109.816575, 43.880533999057427],
            [-109.858754, 43.957987999057337],
            [-109.990073, 43.949065999057339],
            [-110.053179, 44.008021999057277],
            [-110.053357, 44.132627999057142],
            [-110.119193, 44.132583999057147],
            [-110.111284, 44.199374999057085],
            [-110.1511, 44.292463999057006],
            [-110.19019, 44.30049699905701],
            [-110.228408, 44.373843999056959],
            [-110.296648, 44.431340999056928],
            [-110.276506, 44.515664999056874],
            [-110.29485, 44.546922999056861],
            [-110.375334, 44.583015999056848],
            [-110.667899, 44.582791999056852],
            [-110.667867, 44.666290999056812],
            [-111.055332727123997, 44.666262308974311],
            [-111.055511, 44.725342999056792],
            [-111.056888, 44.86665799905677]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "017",
        COUNTYNS: "00042808",
        AFFGEOID: "0500000US04017",
        GEOID: "04017",
        NAME: "Navajo",
        LSAD: "06",
        ALAND: 25770094249,
        AWATER: 24078111
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.750686478927989, 37.003196933818053],
            [-110.47019, 36.997996999092905],
            [-110.000681818589001, 36.997967999390504],
            [-110.000674, 35.662656999105934],
            [-109.826936, 35.662483999105937],
            [-109.843764, 35.517874999107477],
            [-109.844624, 34.822561999115152],
            [-109.8505, 34.647441999117163],
            [-109.865231, 34.55968899911818],
            [-109.848246, 34.473434999119192],
            [-109.859393, 34.125990999123353],
            [-109.859274, 33.777682999127656],
            [-109.891766, 33.77777699912766],
            [-109.891759, 33.567828999130306],
            [-109.903421, 33.575115999130226],
            [-110.000649, 33.576929999130193],
            [-110.00066, 34.0000459991249],
            [-110.750672, 34.000047999124895],
            [-110.750678, 34.263313999121699],
            [-110.7509, 35.030950999112797],
            [-110.750686478927989, 37.003196933818053]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "049",
        COUNTYNS: "00161550",
        AFFGEOID: "0500000US01049",
        GEOID: "01049",
        NAME: "DeKalb",
        LSAD: "06",
        ALAND: 2012676476,
        AWATER: 4121538
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.118894, 34.403844999120011],
            [-86.057712, 34.475993999119176],
            [-86.015594, 34.481638999119106],
            [-85.785471, 34.624583999117426],
            [-85.582812333785284, 34.860435473592318],
            [-85.561424, 34.750078999115971],
            [-85.534405257815393, 34.623790337553238],
            [-85.526894818161011, 34.588685691851346],
            [-85.513044229273291, 34.523946470442304],
            [-85.559282, 34.502254999118861],
            [-85.627512, 34.39591999912011],
            [-85.774165, 34.259093999121752],
            [-85.843801, 34.244594999121922],
            [-85.843617, 34.20000599912246],
            [-86.106086, 34.200755999122464],
            [-86.118894, 34.403844999120011]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "760",
        COUNTYNS: "01789073",
        AFFGEOID: "0500000US51760",
        GEOID: "51760",
        NAME: "Richmond",
        LSAD: "25",
        ALAND: 155173777,
        AWATER: 6878090
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.595462, 37.555749999088043],
            [-77.532388, 37.592483999087733],
            [-77.531747, 37.592053999087724],
            [-77.531483, 37.591784999087743],
            [-77.523094, 37.586074999087785],
            [-77.522897, 37.586059999087787],
            [-77.518078, 37.582716999087801],
            [-77.517793, 37.582586999087802],
            [-77.517621, 37.582434999087823],
            [-77.516879, 37.581987999087815],
            [-77.516464, 37.581665999087818],
            [-77.5162, 37.582161999087809],
            [-77.51575, 37.582027999087821],
            [-77.515586, 37.581943999087819],
            [-77.515087, 37.581801999087808],
            [-77.514959, 37.582001999087822],
            [-77.514632, 37.581842999087812],
            [-77.514164, 37.581567999087824],
            [-77.513248, 37.58252899908782],
            [-77.500615, 37.575752999087861],
            [-77.500422, 37.575680999087872],
            [-77.499937, 37.575396999087864],
            [-77.499671, 37.575744999087867],
            [-77.4982, 37.577274999087869],
            [-77.497153, 37.578414999087848],
            [-77.497013, 37.578549999087848],
            [-77.493528, 37.582285999087809],
            [-77.49087, 37.580120999087832],
            [-77.490646, 37.580079999087829],
            [-77.482368, 37.573137999087891],
            [-77.472563, 37.578619999087856],
            [-77.472489, 37.578685999087845],
            [-77.472583, 37.578834999087839],
            [-77.47385, 37.580630999087823],
            [-77.474085, 37.581814999087818],
            [-77.475709, 37.583952999087806],
            [-77.475774, 37.584139999087796],
            [-77.476338, 37.584883999087801],
            [-77.476748, 37.585511999087792],
            [-77.478035, 37.587291999087775],
            [-77.478174, 37.587333999087768],
            [-77.478332, 37.587557999087771],
            [-77.478903, 37.587650999087764],
            [-77.479206, 37.587650999087771],
            [-77.479358, 37.588683999087756],
            [-77.479415, 37.590812999087746],
            [-77.479188, 37.592810999087725],
            [-77.479132, 37.593098999087729],
            [-77.477208, 37.597897999087692],
            [-77.477317, 37.598079999087687],
            [-77.477646, 37.598223999087672],
            [-77.477775, 37.598387999087684],
            [-77.477982, 37.598536999087671],
            [-77.478254, 37.598548999087683],
            [-77.478146, 37.598891999087684],
            [-77.478033, 37.598806999087685],
            [-77.47782, 37.598733999087678],
            [-77.476797, 37.598541999087672],
            [-77.475865, 37.598528999087677],
            [-77.475467, 37.598548999087683],
            [-77.470903, 37.598395999087685],
            [-77.468824, 37.598604999087684],
            [-77.468096, 37.598761999087685],
            [-77.466995, 37.598927999087678],
            [-77.466668, 37.599097999087675],
            [-77.466057, 37.599132999087686],
            [-77.465838, 37.596757999087693],
            [-77.454475, 37.597495999087684],
            [-77.455239, 37.601777999087645],
            [-77.447581, 37.602809999087647],
            [-77.447509, 37.602216999087638],
            [-77.443559, 37.60148599908765],
            [-77.443133, 37.601258999087648],
            [-77.437616, 37.598684999087681],
            [-77.43762, 37.598565999087683],
            [-77.385513, 37.535171999088206],
            [-77.420875, 37.447100999088953],
            [-77.596849, 37.534651999088219],
            [-77.595462, 37.555749999088043]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "003",
        COUNTYNS: "00306920",
        AFFGEOID: "0500000US12003",
        GEOID: "12003",
        NAME: "Baker",
        LSAD: "06",
        ALAND: 1515738965,
        AWATER: 9686120
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.459581, 30.584263999173015],
            [-82.418984112178492, 30.580916668904568],
            [-82.218607, 30.564394999173334],
            [-82.229427, 30.520813999174031],
            [-82.200965, 30.474426999174764],
            [-82.210318, 30.424576999175557],
            [-82.180043, 30.368609999176456],
            [-82.143306, 30.363377999176546],
            [-82.094709, 30.360765999176579],
            [-82.050982981680889, 30.36836777512136],
            [-82.049236, 30.273431999177994],
            [-82.049411, 30.186932999179394],
            [-82.049425, 30.1431359991801],
            [-82.142578, 30.143116999180108],
            [-82.458364, 30.136448999180224],
            [-82.458352, 30.164594999179762],
            [-82.459792435413675, 30.584276527479215],
            [-82.459581, 30.584263999173015]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "151",
        COUNTYNS: "01639788",
        AFFGEOID: "0500000US47151",
        GEOID: "47151",
        NAME: "Scott",
        LSAD: "06",
        ALAND: 1378656638,
        AWATER: 2414254
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.785341, 36.603371999096552],
            [-84.778455864350491, 36.603210511312454],
            [-84.499938, 36.59667799909662],
            [-84.2613212015047, 36.592741696363653],
            [-84.251132, 36.534179999097219],
            [-84.266877, 36.511697999097422],
            [-84.330434, 36.509479999097444],
            [-84.349939, 36.466291999097862],
            [-84.329927, 36.285085999099621],
            [-84.372731, 36.216777999100294],
            [-84.403633, 36.174777999100705],
            [-84.441034, 36.163477999100834],
            [-84.573741, 36.22997699910016],
            [-84.700785, 36.370823999098782],
            [-84.659082, 36.39522699909854],
            [-84.731839, 36.52467399909731],
            [-84.785399978550302, 36.603375419032155],
            [-84.785341, 36.603371999096552]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "157",
        COUNTYNS: "01784967",
        AFFGEOID: "0500000US17157",
        GEOID: "17157",
        NAME: "Randolph",
        LSAD: "06",
        ALAND: 1490498589,
        AWATER: 56254913
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.205728625882699, 38.088233182192305],
            [-90.035999, 38.135629999083342],
            [-90.036385, 38.22312499908265],
            [-89.899061, 38.220754999082693],
            [-89.703256, 38.219403999082708],
            [-89.592797, 38.219270999082703],
            [-89.595084, 37.955309999084761],
            [-89.664475, 37.829028999085779],
            [-89.687221380853089, 37.796406717525358],
            [-89.696559, 37.814336999085903],
            [-89.782035, 37.855091999085566],
            [-89.851048, 37.90397999908518],
            [-89.923185, 37.870671999085452],
            [-89.933095790915004, 37.880099057337766],
            [-89.974221, 37.919216999085052],
            [-89.95491, 37.966646999084681],
            [-90.008353, 37.970178999084659],
            [-90.080959, 38.015427999084295],
            [-90.126006, 38.050569999084011],
            [-90.205728625882699, 38.088233182192305]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "037",
        COUNTYNS: "00277283",
        AFFGEOID: "0500000US06037",
        GEOID: "06037",
        NAME: "Los Angeles",
        LSAD: "06",
        ALAND: 10510588451,
        AWATER: 1794793532
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-118.593969, 33.467197999131599],
              [-118.484785, 33.487482999131331],
              [-118.370323, 33.409284999132339],
              [-118.286261, 33.351462999133098],
              [-118.325244, 33.299074999133786],
              [-118.374768, 33.3200649991335],
              [-118.465368, 33.32605599913343],
              [-118.482609, 33.369913999132855],
              [-118.563442, 33.434380999132017],
              [-118.593969, 33.467197999131599]
            ]
          ],
          [
            [
              [-118.594033, 33.035950999137249],
              [-118.540069, 32.980932999137984],
              [-118.446771, 32.895423999139126],
              [-118.353504, 32.821961999140122],
              [-118.425634, 32.800594999140408],
              [-118.487908, 32.844589999139814],
              [-118.581513, 32.931671999138644],
              [-118.641578, 33.017128999137491],
              [-118.594033, 33.035950999137249]
            ]
          ],
          [
            [
              [-118.940801, 34.07496699912398],
              [-118.856473, 34.126764999123353],
              [-118.788889, 34.168213999122848],
              [-118.723374, 34.167860999122844],
              [-118.693834, 34.168556999122842],
              [-118.667944, 34.199165999122471],
              [-118.667708, 34.236691999122016],
              [-118.633461, 34.269521999121622],
              [-118.652285, 34.323391999120972],
              [-118.738618, 34.498968999118901],
              [-118.881364, 34.790628999115505],
              [-118.894474, 34.817971999115201],
              [-118.326281, 34.819725999115178],
              [-117.774368, 34.823300999115133],
              [-117.667292, 34.822525999115143],
              [-117.655235, 34.397221999120099],
              [-117.646374, 34.289169999121384],
              [-117.677405, 34.166102999122877],
              [-117.693545, 34.1216269991234],
              [-117.70429, 34.095054999123732],
              [-117.711067, 34.07953599912392],
              [-117.744066, 34.019807999124659],
              [-117.767752, 34.019428999124649],
              [-117.802539, 33.97555099912519],
              [-117.783287, 33.946410999125554],
              [-117.919725, 33.947667999125549],
              [-117.966691, 33.946057999125564],
              [-117.976593, 33.902809999126099],
              [-118.028714, 33.866241999126544],
              [-118.058655, 33.846126999126795],
              [-118.063268, 33.824219999127074],
              [-118.093099, 33.786151999127554],
              [-118.115076722882009, 33.743803322023091],
              [-118.132698, 33.753216999127964],
              [-118.1837, 33.736117999128183],
              [-118.231926185872993, 33.715295466955936],
              [-118.258687, 33.703740999128584],
              [-118.317205, 33.712817999128468],
              [-118.333295892977006, 33.721184834386072],
              [-118.354705, 33.732316999128237],
              [-118.396606, 33.735916999128179],
              [-118.428407, 33.774714999127696],
              [-118.394307, 33.804314999127314],
              [-118.412708, 33.883912999126331],
              [-118.460611, 33.969110999125284],
              [-118.519514, 34.027508999124564],
              [-118.603572, 34.039047999124421],
              [-118.679366, 34.03325499912448],
              [-118.744952, 34.032102999124504],
              [-118.805114, 34.00123899912488],
              [-118.854653, 34.034214999124472],
              [-118.944479511458994, 34.04673895247533],
              [-118.940801, 34.07496699912398]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "097",
        COUNTYNS: "01657246",
        AFFGEOID: "0500000US06097",
        GEOID: "06097",
        NAME: "Sonoma",
        LSAD: "06",
        ALAND: 4081450561,
        AWATER: 497495823
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.540922011634009, 38.76765621511796],
            [-123.368236, 38.777065999078602],
            [-123.136253, 38.809135999078372],
            [-123.080997, 38.85243799907807],
            [-122.821592, 38.850139999078088],
            [-122.77248, 38.82152999907828],
            [-122.627396, 38.667505999079367],
            [-122.646421, 38.598592999079884],
            [-122.602659, 38.557495999080174],
            [-122.543893, 38.519965999080455],
            [-122.349564, 38.193971999082891],
            [-122.406786, 38.155631999083191],
            [-122.397581230623999, 38.142003596336593],
            [-122.488499639259032, 38.10909447339175],
            [-122.505707, 38.115525999083509],
            [-122.565093, 38.182216999082989],
            [-122.7399, 38.207017999082801],
            [-122.911319, 38.321208999081925],
            [-123.003146477056006, 38.295705237796625],
            [-123.004122, 38.297011999082109],
            [-123.053504, 38.299384999082093],
            [-123.068437, 38.335209999081819],
            [-123.085572, 38.3905249990814],
            [-123.166428, 38.474946999080778],
            [-123.249797, 38.511044999080504],
            [-123.331899, 38.565541999080118],
            [-123.349612, 38.596804999079879],
            [-123.441774, 38.699743999079146],
            [-123.514784, 38.741965999078843],
            [-123.540922011634009, 38.76765621511796]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "065",
        COUNTYNS: "00277297",
        AFFGEOID: "0500000US06065",
        GEOID: "06065",
        NAME: "Riverside",
        LSAD: "06",
        ALAND: 18664542308,
        AWATER: 250588224
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.673749, 33.87083099912649],
            [-117.655375, 33.924342999125834],
            [-117.610947, 33.92511399912582],
            [-117.558269, 34.018905999124676],
            [-117.375278, 34.033874999124492],
            [-117.371588, 34.019398999124654],
            [-117.225373, 34.018714999124668],
            [-117.121293, 34.00391599912485],
            [-117.104737, 34.004014999124841],
            [-116.929357, 34.004913999124838],
            [-116.929558, 34.034112999124481],
            [-116.300631, 34.032543999124499],
            [-115.316212, 34.034109999124482],
            [-115.316065, 34.077842999123945],
            [-114.430091138520012, 34.078931405403431],
            [-114.435504, 34.042614999124368],
            [-114.454807, 34.010967999124759],
            [-114.509568, 33.957263999125416],
            [-114.534987, 33.928498999125772],
            [-114.508708, 33.90063999912612],
            [-114.505638, 33.864275999126569],
            [-114.520465, 33.827777999127022],
            [-114.504863, 33.760464999127876],
            [-114.496565, 33.719154999128392],
            [-114.504993, 33.693021999128725],
            [-114.525201, 33.661582999129116],
            [-114.529186, 33.606649999129814],
            [-114.524599, 33.552230999130508],
            [-114.597283, 33.490652999131306],
            [-114.629146518188989, 33.433544999131826],
            [-114.830833, 33.430093999132076],
            [-115.467506, 33.42699199913212],
            [-116.085165, 33.425931999132132],
            [-116.197591, 33.428892999132096],
            [-116.821826, 33.426872999132122],
            [-117.030861, 33.426930999132111],
            [-117.089024, 33.430285999132074],
            [-117.141574, 33.43260999913204],
            [-117.241271, 33.431992999132056],
            [-117.241668, 33.44892799913184],
            [-117.250495, 33.451628999131792],
            [-117.259308, 33.454512999131765],
            [-117.364272, 33.505024999131116],
            [-117.509722, 33.505018999131124],
            [-117.51021, 33.533998999130745],
            [-117.457937, 33.602188999129872],
            [-117.412987, 33.659044999129158],
            [-117.533999, 33.710354999128505],
            [-117.675053, 33.86872499912652],
            [-117.673749, 33.87083099912649]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "099",
        COUNTYNS: "00295761",
        AFFGEOID: "0500000US12099",
        GEOID: "12099",
        NAME: "Palm Beach",
        LSAD: "06",
        ALAND: 5092730552,
        AWATER: 1079713751
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.885639, 26.95891899923695],
            [-80.580237, 26.957933999236968],
            [-80.142007, 26.956639999236994],
            [-80.083077629755294, 26.970533999236721],
            [-80.046263, 26.859237999238886],
            [-80.038962, 26.813960751979671],
            [-80.03377091796601, 26.781768188660301],
            [-80.03212, 26.771529999240592],
            [-80.035763, 26.676042999242465],
            [-80.035567757402205, 26.644952079859682],
            [-80.035363, 26.612345999243715],
            [-80.038863, 26.569346999244562],
            [-80.050363, 26.509548999245752],
            [-80.061607857331992, 26.426402475897699],
            [-80.075874439443183, 26.320912769129695],
            [-80.099747, 26.327667999249364],
            [-80.170149, 26.327780999249367],
            [-80.204083, 26.327821999249363],
            [-80.24977, 26.34152599924909],
            [-80.297471, 26.33435599924923],
            [-80.881233, 26.33380599924924],
            [-80.885639, 26.95891899923695]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "083",
        COUNTYNS: "00450366",
        AFFGEOID: "0500000US18083",
        GEOID: "18083",
        NAME: "Knox",
        LSAD: "06",
        ALAND: 1336511182,
        AWATER: 20758112
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.74104, 38.435575999081067],
            [-87.714047, 38.479879999080744],
            [-87.654166, 38.511910999080499],
            [-87.660732, 38.541091999080287],
            [-87.648356763074489, 38.566628715137696],
            [-87.637752, 38.588511999079948],
            [-87.62012, 38.639488999079575],
            [-87.545538, 38.677612999079294],
            [-87.498948, 38.757773999078729],
            [-87.521681, 38.826575999078244],
            [-87.535257050808497, 38.852491464045471],
            [-87.54737, 38.875613999077906],
            [-87.528717663697904, 38.905943785366901],
            [-87.260257, 38.90162299907773],
            [-87.241027, 38.90787399907768],
            [-87.129882, 38.907314999077684],
            [-87.098771, 38.903973999077699],
            [-87.213267, 38.807976999078377],
            [-87.241463, 38.817475999078312],
            [-87.238551, 38.743234999078837],
            [-87.278553, 38.691342999079197],
            [-87.240629, 38.632127999079643],
            [-87.242097, 38.544929999080267],
            [-87.288884, 38.511559999080504],
            [-87.351833, 38.542246999080277],
            [-87.462892, 38.533518999080343],
            [-87.494671, 38.49569199908062],
            [-87.599317, 38.450720999080957],
            [-87.7511059451205, 38.418849207536006],
            [-87.74104, 38.435575999081067]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "123",
        COUNTYNS: "00451682",
        AFFGEOID: "0500000US18123",
        GEOID: "18123",
        NAME: "Perry",
        LSAD: "06",
        ALAND: 988669306,
        AWATER: 11816977
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.81365994128528, 37.996034494226045],
            [-86.798293, 38.015527999084284],
            [-86.772367, 37.998164999084437],
            [-86.791497, 38.205129999082814],
            [-86.792152, 38.249099999082475],
            [-86.679511, 38.263085999082378],
            [-86.570136, 38.266327999082336],
            [-86.571685, 38.207538999082793],
            [-86.460425, 38.206824999082791],
            [-86.4606, 38.201476999082836],
            [-86.461022, 38.12108699908346],
            [-86.433574364088088, 38.087144386663212],
            [-86.434046, 38.086762999083724],
            [-86.471903, 38.046217999084043],
            [-86.488052467128298, 38.043665307983765],
            [-86.521825, 38.038326999084099],
            [-86.525174, 37.968227999084654],
            [-86.509368, 37.902886999085183],
            [-86.599848, 37.906753999085154],
            [-86.615215, 37.852856999085596],
            [-86.646703057859099, 37.864908974983294],
            [-86.658374, 37.869375999085456],
            [-86.722247, 37.892647999085263],
            [-86.779993, 37.956521999084757],
            [-86.810913, 37.997149999084435],
            [-86.81365994128528, 37.996034494226045]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "183",
        COUNTYNS: "00484969",
        AFFGEOID: "0500000US20183",
        GEOID: "20183",
        NAME: "Smith",
        LSAD: "06",
        ALAND: 2319241783,
        AWATER: 3884267
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.067018349898092, 40.002143512123006],
            [-98.726372963939014, 40.002336272237713],
            [-98.613755, 40.002399999070818],
            [-98.504454983636791, 40.002379875806817],
            [-98.505266, 39.56760299907338],
            [-99.044398, 39.568034999073369],
            [-99.06622, 39.568124999073369],
            [-99.067018349898092, 40.002143512123006]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "041",
        COUNTYNS: "00516867",
        AFFGEOID: "0500000US21041",
        GEOID: "21041",
        NAME: "Carroll",
        LSAD: "06",
        ALAND: 332993661,
        AWATER: 22650593
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.275454, 38.741171999078851],
            [-85.238665, 38.722493999078978],
            [-85.201760622548591, 38.697440790925462],
            [-85.187278, 38.687608999079224],
            [-85.146861, 38.695426999079167],
            [-85.071928, 38.741566999078849],
            [-85.021052141674204, 38.758527323792627],
            [-84.962671, 38.679585999079286],
            [-84.934536, 38.662129999079411],
            [-84.942418, 38.6504689990795],
            [-85.074583, 38.596837999079881],
            [-85.16827, 38.585447999079967],
            [-85.211375, 38.580215999080011],
            [-85.332640733751404, 38.734816753426585],
            [-85.275454, 38.741171999078851]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "007",
        COUNTYNS: "00450403",
        AFFGEOID: "0500000US18007",
        GEOID: "18007",
        NAME: "Benton",
        LSAD: "06",
        ALAND: 1052616452,
        AWATER: 236033
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.526875773253778, 40.491224408758569],
            [-87.526292, 40.535408999067961],
            [-87.526136490423681, 40.736885080489856],
            [-87.267298, 40.736581999066956],
            [-87.09839, 40.73654099906696],
            [-87.097069, 40.71440099906706],
            [-87.095357, 40.562894999067815],
            [-87.093676, 40.475744999068247],
            [-87.527065263399194, 40.476882291529755],
            [-87.526875773253778, 40.491224408758569]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "151",
        COUNTYNS: "01623016",
        AFFGEOID: "0500000US26151",
        GEOID: "26151",
        NAME: "Sanilac",
        LSAD: "06",
        ALAND: 2493045473,
        AWATER: 1624882510
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.120396, 43.327048999058277],
            [-83.104136, 43.33820599905826],
            [-83.117844, 43.675492999057695],
            [-82.606479082838078, 43.690449269767171],
            [-82.593785, 43.581466999057831],
            [-82.53993, 43.422377999058099],
            [-82.523086, 43.225360999058474],
            [-82.506042404955096, 43.168827370370082],
            [-82.996257, 43.154098999058604],
            [-83.00043, 43.284912999058363],
            [-83.119027, 43.283679999058364],
            [-83.120396, 43.327048999058277]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "003",
        COUNTYNS: "01622944",
        AFFGEOID: "0500000US26003",
        GEOID: "26003",
        NAME: "Alger",
        LSAD: "06",
        ALAND: 2369911333,
        AWATER: 10703533792
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.116359589470704, 46.506151401178641],
            [-86.976958, 46.52658099905819],
            [-86.903742, 46.466137999058084],
            [-86.810967, 46.449662999058042],
            [-86.750157, 46.479108999058106],
            [-86.695645, 46.555025999058238],
            [-86.62738, 46.533709999058196],
            [-86.557731, 46.487433999058119],
            [-86.45993, 46.551927999058236],
            [-86.188024, 46.654007999058422],
            [-86.138295, 46.672934999058462],
            [-85.995044, 46.673675999058467],
            [-85.864607141329188, 46.6865683145719],
            [-85.864932, 46.504757999058143],
            [-86.364953, 46.505851999058137],
            [-86.364989, 46.331926999057863],
            [-86.615397, 46.333262999057858],
            [-86.615601, 46.158690999057598],
            [-87.116138, 46.159049999057601],
            [-87.116359589470704, 46.506151401178641]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "045",
        COUNTYNS: "00711756",
        AFFGEOID: "0500000US28045",
        GEOID: "28045",
        NAME: "Hancock",
        LSAD: "06",
        ALAND: 1227560683,
        AWATER: 204096133
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.699932082840789, 30.454036879961084],
            [-89.647072, 30.502737999174311],
            [-89.54451, 30.516861999174093],
            [-89.544232, 30.648367999172002],
            [-89.341446, 30.647719999172011],
            [-89.340847, 30.357994999176622],
            [-89.307023760844501, 30.303994779421597],
            [-89.344746, 30.293195999177676],
            [-89.424624, 30.245390999178444],
            [-89.447465, 30.205097999179088],
            [-89.475824, 30.191560999179316],
            [-89.524504, 30.180752999179493],
            [-89.607655, 30.217095999178898],
            [-89.634208, 30.30825599917743],
            [-89.678514, 30.414011999175735],
            [-89.699932082840789, 30.454036879961084]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "031",
        COUNTYNS: "00558521",
        AFFGEOID: "0500000US22031",
        GEOID: "22031",
        NAME: "De Soto",
        LSAD: "15",
        ALAND: 2269878444,
        AWATER: 47049426
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.042695923740098, 32.1960049739737],
            [-93.951085, 32.195544999148801],
            [-93.764513, 32.345559999146687],
            [-93.666472, 32.317443999147095],
            [-93.61469, 32.237525999148204],
            [-93.535963, 32.193125999148833],
            [-93.539553, 32.10216699915015],
            [-93.441506, 31.989179999151755],
            [-93.355452, 31.932221999152585],
            [-93.419781, 31.934057999152554],
            [-93.441171, 31.84523299915384],
            [-93.878251895386597, 31.84427655639945],
            [-93.909557, 31.893143999153146],
            [-93.977461, 31.926418999152666],
            [-94.029428406596011, 31.979686963319899],
            [-94.041833, 31.992401999151713],
            [-94.042681, 32.137955999149618],
            [-94.042695923740098, 32.1960049739737]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "023",
        COUNTYNS: "00581297",
        AFFGEOID: "0500000US23023",
        GEOID: "23023",
        NAME: "Sagadahoc",
        LSAD: "06",
        ALAND: 657803472,
        AWATER: 300569614
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.051824, 43.990981999057283],
            [-70.033264, 44.001343999057283],
            [-70.005471, 44.124518999057159],
            [-69.897783, 44.107661999057164],
            [-69.854983, 44.167861999057116],
            [-69.763566, 44.153216999057129],
            [-69.758208, 44.139333999057136],
            [-69.791476, 44.040903999057235],
            [-69.813517, 44.02682099905725],
            [-69.700152, 44.006584999057281],
            [-69.743263, 43.893925999057409],
            [-69.692076, 43.857174999057449],
            [-69.695815456259893, 43.796054966348834],
            [-69.717074, 43.792402999057536],
            [-69.754091, 43.743865999057597],
            [-69.807359, 43.728080999057617],
            [-69.833471, 43.701280999057651],
            [-69.855081, 43.704745999057664],
            [-69.862155, 43.758961999057583],
            [-69.887406124157692, 43.766593378509377],
            [-69.893232, 43.878517999057429],
            [-69.857032, 43.944741999057342],
            [-69.976226, 43.915275999057378],
            [-70.034238, 43.975660999057297],
            [-70.051824, 43.990981999057283]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "061",
        COUNTYNS: "01720030",
        AFFGEOID: "0500000US30061",
        GEOID: "30061",
        NAME: "Mineral",
        LSAD: "06",
        ALAND: 3158799736,
        AWATER: 9897206
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-115.69293, 47.457236999060363],
            [-115.634684, 47.481759999060444],
            [-115.52591, 47.495409999060477],
            [-115.249057, 47.480415999060433],
            [-114.982265, 47.396803999060189],
            [-114.968978, 47.312624999059956],
            [-114.797204, 47.268915999059843],
            [-114.775833, 47.225572999059736],
            [-114.712274, 47.225589999059729],
            [-114.712283, 47.182186999059617],
            [-114.586735, 47.123756999059466],
            [-114.566094, 47.067379999059334],
            [-114.483694, 47.067507999059337],
            [-114.419532, 47.023245999059228],
            [-114.525358, 47.009249999059193],
            [-114.566129, 46.96340499905908],
            [-114.549127, 46.747365999058609],
            [-114.676827162695005, 46.731838738871687],
            [-114.699008, 46.740222999058609],
            [-114.76718, 46.7388279990586],
            [-114.79004, 46.778728999058671],
            [-114.880588, 46.811790999058751],
            [-114.943281, 46.867970999058869],
            [-114.927432, 46.914184999058968],
            [-114.961422906068023, 46.932893875029826],
            [-115.031651, 46.971547999059112],
            [-115.071254, 47.022082999059222],
            [-115.120917, 47.061236999059325],
            [-115.189451, 47.131031999059495],
            [-115.255786, 47.174724999059592],
            [-115.29211, 47.209860999059686],
            [-115.326903, 47.255911999059805],
            [-115.371825, 47.265212999059834],
            [-115.470959, 47.284872999059893],
            [-115.531971, 47.314120999059973],
            [-115.578619, 47.367006999060109],
            [-115.71034, 47.417783999060248],
            [-115.69293, 47.457236999060363]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "019",
        COUNTYNS: "01637566",
        AFFGEOID: "0500000US30019",
        GEOID: "30019",
        NAME: "Daniels",
        LSAD: "06",
        ALAND: 3693365374,
        AWATER: 1021697
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.112063323684012, 48.999279437466456],
            [-106.050543, 48.999206999066153],
            [-105.775808, 48.999636999066162],
            [-105.355888, 48.999356999066151],
            [-105.265192, 48.999499999066167],
            [-105.057641716691009, 48.999228886458653],
            [-105.038171, 48.910511999065747],
            [-105.038548, 48.650098999064603],
            [-104.973283, 48.650059999064609],
            [-104.973354, 48.563189999064242],
            [-105.804436, 48.563357999064245],
            [-106.02131, 48.563423999064248],
            [-106.021251, 48.823974999065356],
            [-106.151908, 48.823926999065371],
            [-106.112063323684012, 48.999279437466456]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "039",
        COUNTYNS: "00882235",
        AFFGEOID: "0500000US34039",
        GEOID: "34039",
        NAME: "Union",
        LSAD: "06",
        ALAND: 266170662,
        AWATER: 7046286
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.463291, 40.599186999067634],
            [-74.424401, 40.619544999067529],
            [-74.40352, 40.654655999067344],
            [-74.403377, 40.666621999067289],
            [-74.461035, 40.673503999067265],
            [-74.443762, 40.688037999067198],
            [-74.380314, 40.727560999067009],
            [-74.371738, 40.739639999066945],
            [-74.297212, 40.715188999067053],
            [-74.285187, 40.72215199906703],
            [-74.209248, 40.690708999067184],
            [-74.136703, 40.674443999067257],
            [-74.142516, 40.667514999067294],
            [-74.160146783153181, 40.646076515512199],
            [-74.170611, 40.645288999067404],
            [-74.200122901991094, 40.631868901013164],
            [-74.202247, 40.630902999067473],
            [-74.203688, 40.592690999067663],
            [-74.29099, 40.591924999067665],
            [-74.302921, 40.608682999067589],
            [-74.358717, 40.604060999067606],
            [-74.441719, 40.59708499906764],
            [-74.463291, 40.599186999067634]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "017",
        COUNTYNS: "00882278",
        AFFGEOID: "0500000US34017",
        GEOID: "34017",
        NAME: "Hudson",
        LSAD: "06",
        ALAND: 119764738,
        AWATER: 41834804
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.147529, 40.786490999066714],
            [-74.090787, 40.761983999066835],
            [-74.087979, 40.764439999066823],
            [-74.090098, 40.776882999066764],
            [-74.089143, 40.77959599906675],
            [-74.07985, 40.789725999066711],
            [-74.076753, 40.795620999066678],
            [-74.069032, 40.798348999066661],
            [-74.063837, 40.804151999066633],
            [-74.058549, 40.806276999066633],
            [-74.044923, 40.807554999066618],
            [-74.009938, 40.823340999066538],
            [-74.005341, 40.826944999066527],
            [-73.997476, 40.822323999066548],
            [-73.996108, 40.824413999066529],
            [-73.990798, 40.821440999066553],
            [-73.993689, 40.814039999066587],
            [-73.984592494680186, 40.797543327618968],
            [-74.013784, 40.756600999066855],
            [-74.023491533732198, 40.737453164554246],
            [-74.025635529540793, 40.733224193728979],
            [-74.030927085683885, 40.722786749560619],
            [-74.041156244303494, 40.702610024775922],
            [-74.046966591832998, 40.691149279335761],
            [-74.047313, 40.690465999067186],
            [-74.067720330318991, 40.670380593311869],
            [-74.070943537715294, 40.667208231842892],
            [-74.086806, 40.651595999067368],
            [-74.160146783153181, 40.646076515512199],
            [-74.142516, 40.667514999067294],
            [-74.136703, 40.674443999067257],
            [-74.117057, 40.710380999067084],
            [-74.117605, 40.739637999066943],
            [-74.16598, 40.748067999066905],
            [-74.147529, 40.786490999066714]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "015",
        COUNTYNS: "00882277",
        AFFGEOID: "0500000US34015",
        GEOID: "34015",
        NAME: "Gloucester",
        LSAD: "06",
        ALAND: 833862843,
        AWATER: 38373885
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.410492477637092, 39.804672233394044],
            [-75.390315623251894, 39.816829216902377],
            [-75.351648214068902, 39.84012715258514],
            [-75.341765, 39.846081999071707],
            [-75.293376, 39.84878199907169],
            [-75.258805818047691, 39.85467389985606],
            [-75.221025, 39.861112999071615],
            [-75.211196061474496, 39.866518630753689],
            [-75.183023, 39.882012999071506],
            [-75.141435521747297, 39.893918371552743],
            [-75.08707, 39.779575999072101],
            [-74.943677, 39.698360999072584],
            [-74.87726, 39.608351999073129],
            [-74.911882, 39.578461999073305],
            [-74.985271, 39.514966999073707],
            [-75.061854, 39.568745999073364],
            [-75.199793, 39.666995999072775],
            [-75.376589, 39.726420999072417],
            [-75.378228, 39.745869999072305],
            [-75.429061, 39.78381999907208],
            [-75.410492477637092, 39.804672233394044]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "011",
        COUNTYNS: "01008536",
        AFFGEOID: "0500000US37011",
        GEOID: "37011",
        NAME: "Avery",
        LSAD: "06",
        ALAND: 640601890,
        AWATER: 445285
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.080143, 36.105719999101396],
            [-82.02874, 36.12432199910122],
            [-81.960101, 36.228130999100181],
            [-81.934369244492586, 36.264716203479637],
            [-81.918444472904795, 36.287357916481703],
            [-81.907698, 36.214162999100324],
            [-81.82923, 36.168790999100771],
            [-81.810516, 36.111510999101341],
            [-81.735673, 36.067583999101778],
            [-81.807162, 35.961954999102844],
            [-81.899871, 35.999908999102459],
            [-81.943188, 35.960049999102871],
            [-81.981954, 35.911360999103366],
            [-82.034104, 35.942056999103052],
            [-82.081148917132396, 36.105692113433996],
            [-82.080143, 36.105719999101396]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "145",
        COUNTYNS: "01074085",
        AFFGEOID: "0500000US39145",
        GEOID: "39145",
        NAME: "Scioto",
        LSAD: "06",
        ALAND: 1580451539,
        AWATER: 15363856
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.270817, 39.015790999076948],
            [-83.212107, 38.960147999077329],
            [-83.04362, 38.956938999077344],
            [-82.80709, 38.948072999077411],
            [-82.753391, 38.945372999077428],
            [-82.76069, 38.854874999078049],
            [-82.650039, 38.849072999078089],
            [-82.662886, 38.747976999078809],
            [-82.706823, 38.677494999079308],
            [-82.763895, 38.679973999079294],
            [-82.740383, 38.597166999079889],
            [-82.811542384650409, 38.572369588641962],
            [-82.851314, 38.604333999079827],
            [-82.869592, 38.678176999079291],
            [-82.871192, 38.718376999079013],
            [-82.882289993609291, 38.741619222348447],
            [-82.889193, 38.756075999078746],
            [-82.943147, 38.743279999078837],
            [-83.011816, 38.730056999078926],
            [-83.030334931231096, 38.716867675735124],
            [-83.042338, 38.708318999079083],
            [-83.112372, 38.671684999079339],
            [-83.128973, 38.640230999079577],
            [-83.172647, 38.62025199907972],
            [-83.239515, 38.628587999079656],
            [-83.264298901958696, 38.613112495967265],
            [-83.268292, 38.634011999079618],
            [-83.270817, 39.015790999076948]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "029",
        COUNTYNS: "01074027",
        AFFGEOID: "0500000US39029",
        GEOID: "39029",
        NAME: "Columbiana",
        LSAD: "06",
        ALAND: 1377680388,
        AWATER: 7255101
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.086686, 40.898857999066195],
            [-81.086679, 40.901608999066177],
            [-80.895813, 40.930186999066045],
            [-80.821923, 40.900934999066187],
            [-80.519869707686183, 40.900323933315086],
            [-80.519705112599198, 40.851336777751804],
            [-80.518991, 40.638800999067449],
            [-80.583633, 40.615519999067558],
            [-80.627171, 40.619935999067529],
            [-80.667957, 40.58249599906771],
            [-80.747112, 40.596990999067643],
            [-80.861994, 40.599403999067626],
            [-80.918125, 40.643463999067421],
            [-80.917172, 40.726933999067001],
            [-81.087289, 40.727815999066998],
            [-81.086686, 40.898857999066195]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "227",
        COUNTYNS: "01673011",
        AFFGEOID: "0500000US13227",
        GEOID: "13227",
        NAME: "Pickens",
        LSAD: "06",
        ALAND: 601027940,
        AWATER: 1693768
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.654366, 34.54894599911831],
            [-84.50261, 34.563680999118134],
            [-84.467989, 34.563487999118131],
            [-84.437226, 34.549349999118313],
            [-84.371352, 34.548494999118304],
            [-84.345553, 34.562734999118142],
            [-84.319679, 34.467856999119263],
            [-84.256867, 34.467326999119265],
            [-84.257586, 34.380991999120297],
            [-84.400774, 34.37326599912037],
            [-84.424525, 34.387069999120229],
            [-84.58263, 34.381491999120286],
            [-84.58283, 34.412190999119922],
            [-84.653232, 34.41258999911993],
            [-84.644432, 34.455788999119406],
            [-84.654366, 34.54894599911831]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "103",
        COUNTYNS: "00695774",
        AFFGEOID: "0500000US28103",
        GEOID: "28103",
        NAME: "Noxubee",
        LSAD: "06",
        ALAND: 1800451886,
        AWATER: 12487951
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.813053, 33.286423999133945],
            [-88.669076, 33.286921999133938],
            [-88.304442813510377, 33.288319999133918],
            [-88.317135, 33.184122999135283],
            [-88.340084992139296, 32.991264337012836],
            [-88.347490247834898, 32.92903478601918],
            [-88.812782, 32.925972999138708],
            [-88.813053, 33.286423999133945]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "181",
        COUNTYNS: "00758545",
        AFFGEOID: "0500000US29181",
        GEOID: "29181",
        NAME: "Ripley",
        LSAD: "06",
        ALAND: 1630498740,
        AWATER: 5443027
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.115926, 36.823671999094486],
            [-90.662617, 36.811452999094612],
            [-90.627517, 36.8109539990946],
            [-90.592166, 36.679367999095831],
            [-90.576179065567302, 36.498405926895551],
            [-90.765672, 36.498493999097555],
            [-90.784244155131205, 36.498462199226651],
            [-91.017974, 36.498061999097551],
            [-91.126538874564702, 36.497797700116962],
            [-91.115926, 36.823671999094486]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "009",
        COUNTYNS: "01720111",
        AFFGEOID: "0500000US30009",
        GEOID: "30009",
        NAME: "Carbon",
        LSAD: "06",
        ALAND: 5303530750,
        AWATER: 35410727
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.798673, 45.167338999056803],
            [-109.687792, 45.167338999056796],
            [-109.687857, 45.261439999056812],
            [-109.608504, 45.26144499905682],
            [-109.608831, 45.304831999056837],
            [-109.520884, 45.391851999056875],
            [-109.356417, 45.464325999056911],
            [-109.191761, 45.464503999056909],
            [-109.191551, 45.523094999056951],
            [-109.128225, 45.52302499905695],
            [-109.127343, 45.587670999056996],
            [-109.068498, 45.58324099905699],
            [-108.97281, 45.616553999057025],
            [-108.843016, 45.611041999057008],
            [-108.782, 45.638638999057029],
            [-108.801761, 45.55196499905697],
            [-108.699283, 45.522628999056941],
            [-108.645325, 45.46407299905691],
            [-108.682223, 45.433131999056904],
            [-108.69962, 45.348945999056852],
            [-108.699616, 45.217708999056804],
            [-108.098228, 45.220422999056801],
            [-108.127979, 45.217910999056805],
            [-108.258603, 45.008179999056757],
            [-108.248525185308011, 45.000629826474267],
            [-108.500679, 44.99969099905676],
            [-108.621493494730004, 44.999676370078269],
            [-109.062262, 44.999622999056768],
            [-109.103445, 45.005903999056756],
            [-109.574321, 45.002630999056763],
            [-109.798483772435006, 45.002920307341775],
            [-109.798673, 45.167338999056803]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "173",
        COUNTYNS: "00835908",
        AFFGEOID: "0500000US31173",
        GEOID: "31173",
        NAME: "Thurston",
        LSAD: "06",
        ALAND: 1019377255,
        AWATER: 6458661
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.822497, 42.264291999060909],
            [-96.73778, 42.264103999060907],
            [-96.726661, 42.278004999060862],
            [-96.380298, 42.276350999060867],
            [-96.351957192496002, 42.280894703604154],
            [-96.336003, 42.264805999060904],
            [-96.336323, 42.218921999061045],
            [-96.33721609470588, 42.214849505956671],
            [-96.347752, 42.166805999061225],
            [-96.2689, 42.113589999061389],
            [-96.272877, 42.047237999061608],
            [-96.309645, 42.015186999061719],
            [-96.554866, 42.015874999061722],
            [-96.555511, 42.089956999061464],
            [-96.82367, 42.090410999061461],
            [-96.822497, 42.264291999060909]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "006",
        COUNTYNS: "00933051",
        AFFGEOID: "0500000US35006",
        GEOID: "35006",
        NAME: "Cibola",
        LSAD: "06",
        ALAND: 11758240020,
        AWATER: 5819738
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.045851, 34.959717999113586],
            [-109.045851235182994, 34.9598185149503],
            [-108.469402, 34.959080999113596],
            [-108.468683, 35.299242999109829],
            [-107.734224, 35.304986999109772],
            [-107.734241, 35.348144999109294],
            [-107.628313, 35.348313999109294],
            [-107.628788, 35.304332999109775],
            [-107.309386, 35.30568699910976],
            [-107.23889, 35.305506999109753],
            [-107.197244, 35.219458999110714],
            [-107.066562, 34.957189999113609],
            [-107.202882, 34.957571999113618],
            [-107.201743, 34.578756999117957],
            [-107.691565, 34.579424999117961],
            [-107.724794, 34.578131999117971],
            [-109.04613893536299, 34.579291011935851],
            [-109.045851, 34.959717999113586]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "009",
        COUNTYNS: "01101792",
        AFFGEOID: "0500000US40009",
        GEOID: "40009",
        NAME: "Beckham",
        LSAD: "06",
        ALAND: 2335665266,
        AWATER: 5527154
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.000388844143004, 35.422363987417903],
            [-99.57582, 35.421654999108512],
            [-99.575598, 35.508860999107569],
            [-99.364141, 35.50879299910757],
            [-99.364217, 35.465327999108034],
            [-99.360226, 35.116737999111834],
            [-99.408338, 35.116489999111842],
            [-99.409291, 35.114508999111855],
            [-99.782727, 35.116779999111834],
            [-99.782949, 35.0301109991128],
            [-99.888571, 35.030261999112795],
            [-100.000384020773012, 35.030384999112698],
            [-100.000385, 35.182701999111117],
            [-100.000388844143004, 35.422363987417903]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "123",
        COUNTYNS: "01213691",
        AFFGEOID: "0500000US42123",
        GEOID: "42123",
        NAME: "Warren",
        LSAD: "06",
        ALAND: 2289914225,
        AWATER: 37352304
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.612072, 41.850055999062285],
            [-79.610835774986697, 41.998519903170163],
            [-79.472472, 41.998254999061771],
            [-79.061264403159896, 41.998838108972762],
            [-78.983065, 41.998948999061774],
            [-78.918857363615203, 41.999103197751168],
            [-78.915775, 41.840895999062326],
            [-78.956056, 41.623862999063128],
            [-78.956042, 41.623401999063127],
            [-79.51207, 41.624558999063112],
            [-79.612873, 41.623857999063127],
            [-79.612072, 41.850055999062285]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "175",
        COUNTYNS: "01074099",
        AFFGEOID: "0500000US39175",
        GEOID: "39175",
        NAME: "Wyandot",
        LSAD: "06",
        ALAND: 1053773838,
        AWATER: 1783531
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.514728, 40.905114999066164],
            [-83.45785, 40.991670999065768],
            [-83.420319, 40.991888999065765],
            [-83.112737, 40.99344699906576],
            [-83.11136, 40.702914999067119],
            [-83.304687, 40.702005999067119],
            [-83.310556, 40.687108999067199],
            [-83.419836, 40.686822999067203],
            [-83.495298, 40.701533999067109],
            [-83.496526, 40.809885999066609],
            [-83.515883, 40.818133999066568],
            [-83.514728, 40.905114999066164]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "101",
        COUNTYNS: "00161577",
        AFFGEOID: "0500000US01101",
        GEOID: "01101",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 2033764493,
        AWATER: 37997545
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.496774, 32.344436999146701],
            [-86.459552, 32.405440999145846],
            [-86.411172, 32.409936999145785],
            [-86.262006, 32.501689999144503],
            [-86.192284, 32.436129999145429],
            [-86.046402, 32.406145999145842],
            [-86.023012, 32.419977999145651],
            [-85.991283, 32.33609799914683],
            [-85.919293, 32.274381999147678],
            [-85.986557, 32.272341999147727],
            [-85.999157, 32.250542999148031],
            [-85.997859, 32.141604999149564],
            [-85.996853, 32.051048999150879],
            [-85.995563, 31.96755399915207],
            [-86.191379, 31.966452999152086],
            [-86.302217, 31.965064999152112],
            [-86.303616, 32.051663999150854],
            [-86.406276, 32.050730999150865],
            [-86.408272, 32.20897599914862],
            [-86.408771, 32.24430899914811],
            [-86.422236, 32.253716999147983],
            [-86.448746, 32.263594999147848],
            [-86.458512, 32.274091999147686],
            [-86.48063, 32.271457999147728],
            [-86.484678, 32.28447299914756],
            [-86.474479, 32.33154699914688],
            [-86.496774, 32.344436999146701]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "50",
        COUNTYFP: "001",
        COUNTYNS: "01461757",
        AFFGEOID: "0500000US50001",
        GEOID: "50001",
        NAME: "Addison",
        LSAD: "06",
        ALAND: 1984780357,
        AWATER: 107303299
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.416319, 44.099421999057185],
            [-73.3998685693646, 44.152485646748843],
            [-73.395399, 44.166902999057115],
            [-73.349889, 44.230355999057061],
            [-73.316618, 44.257768999057028],
            [-73.317455974665094, 44.263522188933827],
            [-72.973318, 44.293419999057008],
            [-72.96525, 44.230325999057065],
            [-72.952167, 44.161270999057123],
            [-72.933281, 44.153996999057121],
            [-72.909019, 44.072586999057208],
            [-72.879324, 44.068834999057216],
            [-72.742303, 44.02965499905725],
            [-72.791336, 43.961854999057316],
            [-72.865283, 43.861655999057447],
            [-72.876556, 43.902764999057396],
            [-72.980158, 43.882586999057423],
            [-72.958853, 43.826541999057483],
            [-73.016831, 43.804952999057519],
            [-73.029938, 43.846715999057466],
            [-73.218956, 43.837290999057473],
            [-73.206322, 43.766774999057567],
            [-73.361107151684294, 43.753233456341491],
            [-73.350707, 43.770462999057564],
            [-73.382526149539899, 43.808158987607008],
            [-73.390302, 43.817370999057509],
            [-73.374051, 43.875562999057429],
            [-73.407742, 43.929886999057359],
            [-73.411248, 43.975595999057312],
            [-73.405977, 44.011484999057274],
            [-73.43688, 44.042577999057244],
            [-73.416319, 44.099421999057185]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "003",
        COUNTYNS: "01605067",
        AFFGEOID: "0500000US56003",
        GEOID: "56003",
        NAME: "Big Horn",
        LSAD: "06",
        ALAND: 8123500143,
        AWATER: 56402796
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-108.621493494730004, 44.999676370078269],
            [-108.500679, 44.99969099905676],
            [-108.248525185308011, 45.000629826474267],
            [-107.997353, 45.001564999056761],
            [-107.911522788162003, 45.001544003668165],
            [-107.832319, 44.790160999056781],
            [-107.743467, 44.715422999056798],
            [-107.615891, 44.701296999056801],
            [-107.369621, 44.559836999056856],
            [-107.30058, 44.459080999056901],
            [-107.169228, 44.393779999056939],
            [-107.146808, 44.165665999057111],
            [-107.541198, 44.166508999057115],
            [-108.550562, 44.168454999057118],
            [-108.573791, 44.171418999057117],
            [-108.57342, 44.520810999056863],
            [-108.592296, 44.871443999056766],
            [-108.621493494730004, 44.999676370078269]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "143",
        COUNTYNS: "01101859",
        AFFGEOID: "0500000US40143",
        GEOID: "40143",
        NAME: "Tulsa",
        LSAD: "06",
        ALAND: 1477021879,
        AWATER: 43361035
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.297888, 36.162278999100835],
            [-96.268287, 36.161979999100836],
            [-96.001055, 36.161293999100842],
            [-96.001171, 36.423685999098275],
            [-95.81268, 36.423559999098273],
            [-95.79439, 36.417369999098327],
            [-95.81221, 36.351009999098977],
            [-95.812064, 36.24954399909997],
            [-95.8154, 36.16262999910083],
            [-95.76165, 36.162749999100825],
            [-95.761687, 35.900810999103477],
            [-95.819964, 35.855899999103926],
            [-96.033118, 35.856823999103923],
            [-96.02957, 35.901105999103471],
            [-96.029544, 35.988386999102573],
            [-96.029583, 36.075365999101706],
            [-96.297786, 36.075778999101701],
            [-96.297888, 36.162278999100835]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "013",
        COUNTYNS: "01419964",
        AFFGEOID: "0500000US02013",
        GEOID: "02013",
        NAME: "Aleutians East",
        LSAD: "04",
        ALAND: 18091325125,
        AWATER: 20800198540
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-160.252749, 54.913250999113174],
              [-160.192058, 55.038156999114584],
              [-160.187261, 55.1183759991155],
              [-160.137102, 55.171564999116114],
              [-160.025257, 55.20391399911648],
              [-159.870591, 55.284888999117427],
              [-159.843859, 55.249366999117008],
              [-159.816419, 55.178050999116181],
              [-159.670305, 55.182336999116231],
              [-159.521096, 55.253392999117054],
              [-159.488766, 55.188811999116311],
              [-159.33847, 55.046682999114672],
              [-159.203228, 54.9148419991132],
              [-159.272354, 54.864203999112632],
              [-159.309681, 54.865812999112642],
              [-159.447982, 54.941373999113495],
              [-159.504434, 55.027315999114464],
              [-159.635226, 55.037293999114574],
              [-159.752779, 55.066138999114905],
              [-159.813625, 55.027466999114466],
              [-160.027035, 55.020909999114394],
              [-160.099295, 54.962852999113728],
              [-160.226967, 54.864074999112631],
              [-160.252749, 54.913250999113174]
            ]
          ],
          [
            [
              [-160.856621, 55.318487999117806],
              [-160.808929, 55.37012199911841],
              [-160.687442, 55.402197999118791],
              [-160.517513, 55.37937799911851],
              [-160.333421, 55.436927999119192],
              [-160.260565, 55.463673999119507],
              [-160.137032, 55.45070899911935],
              [-160.154038, 55.377517999118496],
              [-160.30655, 55.303274999117633],
              [-160.341217, 55.251798999117035],
              [-160.468262, 55.288924999117469],
              [-160.527617, 55.256373999117095],
              [-160.486511, 55.181950999116225],
              [-160.525226, 55.129870999115624],
              [-160.655577, 55.160260999115984],
              [-160.734942, 55.151310999115871],
              [-160.821381, 55.117850999115497],
              [-160.841917, 55.204439999116488],
              [-160.856621, 55.318487999117806]
            ]
          ],
          [
            [
              [-161.426005, 55.216562999116633],
              [-161.356726, 55.221261999116678],
              [-161.329875, 55.219417999116665],
              [-161.344152, 55.158503999115972],
              [-161.451286, 55.178026999116184],
              [-161.426005, 55.216562999116633]
            ]
          ],
          [
            [
              [-161.697129, 55.249147999117],
              [-161.523434, 55.271658999117271],
              [-161.560207, 55.207044999116519],
              [-161.691553, 55.198478999116418],
              [-161.697129, 55.249147999117]
            ]
          ],
          [
            [
              [-162.844362, 54.510427999108742],
              [-162.585315, 54.447994999108083],
              [-162.34484, 54.40133599910758],
              [-162.388754, 54.367622999107219],
              [-162.46695, 54.342691999106954],
              [-162.608608, 54.369146999107237],
              [-162.760247, 54.37219299910727],
              [-162.861736, 54.424770999107835],
              [-162.844362, 54.510427999108742]
            ]
          ],
          [
            [
              [-164.948789, 54.579876999109494],
              [-164.864333, 54.620187999109945],
              [-164.741815, 54.645440999110221],
              [-164.674836, 54.702595999110841],
              [-164.576896, 54.824563999112186],
              [-164.57626, 54.895341999112979],
              [-164.43528, 54.933125999113393],
              [-164.343534, 54.894138999112961],
              [-164.204897, 54.93123999911338],
              [-164.119196, 54.969415999113806],
              [-163.994179, 54.983314999113972],
              [-163.894695, 55.039114999114595],
              [-163.774093, 55.05577999911479],
              [-163.527109, 55.04087099911461],
              [-163.429548, 54.954758999113636],
              [-163.343768, 54.974438999113872],
              [-163.280771, 55.032958999114513],
              [-163.314652, 55.126311999115593],
              [-163.132007, 55.179628999116204],
              [-163.032256, 55.172146999116109],
              [-162.86152, 55.198338999116423],
              [-162.900027, 55.252465999117035],
              [-162.813255, 55.299457999117585],
              [-162.64165, 55.392575999118684],
              [-162.565411, 55.466848999119541],
              [-162.365467, 55.604585999121191],
              [-162.219551, 55.710866999122466],
              [-162.120886, 55.749088999122911],
              [-162.05063, 55.790896999123433],
              [-161.898956, 55.833463999123957],
              [-161.807833, 55.891953999124667],
              [-161.712283, 55.904231999124818],
              [-161.450442, 55.954484999125434],
              [-161.15687, 56.012215999126141],
              [-160.964744, 56.023753999126292],
              [-160.807119, 56.023979999126297],
              [-160.811041, 55.947229999125341],
              [-160.793215, 55.885959999124594],
              [-160.564014, 55.863718999124309],
              [-160.508433, 55.869378999124379],
              [-160.457194, 55.91723299912497],
              [-160.533685, 55.959949999125499],
              [-160.589569, 55.983047999125787],
              [-160.488708, 56.077213999126954],
              [-160.405869, 56.207937999128582],
              [-160.385922, 56.279705999129497],
              [-160.222878, 56.34686799913036],
              [-160.146252, 56.400175999131029],
              [-159.985615, 56.449742999131665],
              [-159.828049, 56.543934999132873],
              [-159.534961, 56.626528999133953],
              [-159.219956, 56.739529999135428],
              [-158.972735, 56.842137999136796],
              [-158.893547986565011, 56.809308547513552],
              [-158.893607, 56.754403999135633],
              [-159.051094, 56.754400999135633],
              [-159.051089, 56.667686999134489],
              [-159.275932, 56.667681999134494],
              [-159.275928, 56.580966999133359],
              [-159.588041, 56.58096099913336],
              [-159.588036, 56.494244999132242],
              [-159.900149, 56.494238999132236],
              [-159.900133, 56.320802999130009],
              [-159.806389, 56.320804999130011],
              [-159.806406, 55.973918999125672],
              [-159.86797, 55.973917999125668],
              [-159.867971, 55.887192999124608],
              [-159.56136, 55.887196999124612],
              [-159.561438320694009, 55.640913755779735],
              [-159.572125, 55.627683999121466],
              [-159.696713, 55.573305999120812],
              [-159.733899, 55.569984999120777],
              [-159.760365, 55.615202999121301],
              [-159.679201, 55.655894999121813],
              [-159.673191, 55.750960999122952],
              [-159.627482, 55.803247999123577],
              [-159.679792, 55.83876499912401],
              [-159.770298, 55.852356999124183],
              [-159.847359, 55.802529999123564],
              [-159.937089, 55.803305999123587],
              [-160.026282, 55.792294999123442],
              [-160.058443, 55.721733999122591],
              [-160.130445, 55.681418999122108],
              [-160.279827, 55.641383999121629],
              [-160.392587, 55.602770999121162],
              [-160.464301, 55.53324299912034],
              [-160.462745, 55.506653999120019],
              [-160.521335, 55.47441999911964],
              [-160.654117, 55.512595999120087],
              [-160.666917, 55.459775999119465],
              [-160.781401, 55.451779999119374],
              [-160.836725, 55.473134999119615],
              [-160.976551, 55.472735999119614],
              [-161.080549, 55.408497999118858],
              [-161.253977, 55.355895999118239],
              [-161.486114, 55.359321999118286],
              [-161.514211, 55.385253999118589],
              [-161.478303, 55.44059999911925],
              [-161.469271, 55.496829999119896],
              [-161.376102, 55.569793999120769],
              [-161.392613, 55.62822099912146],
              [-161.482064, 55.633978999121531],
              [-161.587047, 55.620059999121366],
              [-161.658262, 55.560446999120657],
              [-161.700069, 55.514389999120112],
              [-161.686495, 55.408040999118853],
              [-161.777414, 55.329376999117933],
              [-161.863339, 55.266988999117203],
              [-161.817232, 55.176528999116158],
              [-161.718614, 55.154165999115911],
              [-161.576643, 55.103830999115331],
              [-161.550357, 55.065733999114897],
              [-161.690346, 55.078499999115046],
              [-161.792297, 55.052277999114743],
              [-161.906434, 55.1003199991153],
              [-161.956595, 55.112173999115427],
              [-162.053281, 55.074211999114993],
              [-162.11874, 55.102910999115323],
              [-162.190348, 55.066980999114911],
              [-162.219326, 55.028974999114482],
              [-162.235675, 54.962600999113732],
              [-162.236806, 54.881629999112818],
              [-162.282944, 54.841215999112372],
              [-162.349315, 54.836048999112315],
              [-162.428237, 54.895433999112967],
              [-162.435473, 54.929248999113355],
              [-162.41351, 55.036559999114566],
              [-162.471364, 55.051931999114743],
              [-162.569291, 55.004598999114215],
              [-162.587967, 54.972009999113837],
              [-162.708453, 54.958479999113685],
              [-162.834245, 54.926850999113327],
              [-162.913684, 54.950272999113594],
              [-162.962205, 54.993537999114089],
              [-163.065602, 54.926171999113322],
              [-163.14958, 54.885905999112879],
              [-163.254588, 54.838906999112346],
              [-163.352997, 54.810173999112031],
              [-163.281379, 54.776729999111652],
              [-163.184295, 54.774911999111637],
              [-163.068952, 54.712604999110944],
              [-163.037788, 54.646994999110234],
              [-163.223179, 54.676894999110552],
              [-163.392198, 54.65849599911035],
              [-163.572383, 54.623210999109972],
              [-163.80359, 54.636497999110112],
              [-164.03827, 54.624687999109987],
              [-164.257585, 54.572721999109412],
              [-164.337538, 54.524258999108902],
              [-164.352704, 54.46502299910825],
              [-164.456554, 54.419855999107774],
              [-164.640457, 54.391165999107479],
              [-164.743977, 54.394215999107516],
              [-164.861475, 54.431352999107901],
              [-164.904077, 54.499194999108632],
              [-164.944636, 54.532902999108991],
              [-164.948789, 54.579876999109494]
            ]
          ],
          [
            [
              [-165.602225, 54.045266999103852],
              [-165.468221, 54.079640999104207],
              [-165.28077, 54.115624999104583],
              [-165.140978, 54.13107899910473],
              [-165.00791, 54.134933999104774],
              [-164.883134, 54.196186999105407],
              [-164.824183, 54.225526999105725],
              [-164.7637, 54.223152999105707],
              [-164.816166, 54.158753999105031],
              [-164.956139, 54.060987999104022],
              [-165.088283, 54.07249099910414],
              [-165.287665, 54.038348999103789],
              [-165.555768, 54.023550999103627],
              [-165.602225, 54.045266999103852]
            ]
          ],
          [
            [
              [-166.06155, 54.185091999105303],
              [-165.959751, 54.220981999105689],
              [-165.868192, 54.214883999105616],
              [-165.62555, 54.298963999106498],
              [-165.478452, 54.295332999106463],
              [-165.383719, 54.196730999105426],
              [-165.549217, 54.11219599910455],
              [-165.784434, 54.069434999104111],
              [-165.875129, 54.036419999103771],
              [-165.901649, 54.062869999104038],
              [-166.046438, 54.044185999103846],
              [-166.112242, 54.122527999104655],
              [-166.06155, 54.185091999105303]
            ]
          ],
          [
            [
              [-166.225644, 53.986229104188254],
              [-166.172365, 53.998123999103377],
              [-166.075283, 53.969570999103084],
              [-166.160924652198986, 53.935638051296628],
              [-166.225641, 53.960205999102982],
              [-166.225644, 53.986229104188254]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "290",
        COUNTYNS: "01419987",
        AFFGEOID: "0500000US02290",
        GEOID: "02290",
        NAME: "Yukon-Koyukuk",
        LSAD: "05",
        ALAND: 377030936019,
        AWATER: 5953180597
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-161.048176, 62.55240999922907],
            [-160.996201, 62.552423999229077],
            [-160.996215, 62.898935999235697],
            [-160.94344, 62.999268999237643],
            [-160.85799, 63.011952999237884],
            [-160.858004, 63.271837999242962],
            [-160.667361, 63.271844999242951],
            [-160.690263, 63.358484999244645],
            [-160.690294, 63.53168899924809],
            [-160.497375, 63.531712999248086],
            [-160.497381, 63.618342999249805],
            [-160.111522, 63.618350999249799],
            [-160.111498, 63.704971999251534],
            [-159.932739, 63.704972999251538],
            [-159.932731, 63.791588999253278],
            [-159.737451, 63.791590999253273],
            [-159.737475, 64.051458999258529],
            [-159.947288, 64.051458999258529],
            [-159.962255, 64.397926999265636],
            [-159.96229, 64.744376999272831],
            [-159.774897, 64.744381999272832],
            [-159.774921, 64.917564999276465],
            [-159.572173, 64.917596999276469],
            [-159.582736, 65.264025999283845],
            [-159.377365, 65.264032999283842],
            [-159.385578, 65.52383499928942],
            [-159.593506, 65.52383499928942],
            [-159.604813, 65.956829999298876],
            [-158.972175, 65.95684899929887],
            [-158.972185, 66.130026999302686],
            [-157.906295, 66.130048999302687],
            [-157.90631, 66.476423999310413],
            [-157.051284, 66.47643799931042],
            [-157.051266, 66.303253999306548],
            [-156.623757, 66.303258999306536],
            [-156.62377, 66.47644199931041],
            [-156.196224, 66.476581999310426],
            [-156.196257, 66.389856999308478],
            [-155.982504, 66.389859999308484],
            [-155.982499, 66.303267999306556],
            [-155.554993, 66.303273999306555],
            [-155.510581, 66.47645899931041],
            [-155.510585, 66.563050999312367],
            [-154.860431, 66.563063999312362],
            [-154.860446, 66.736241999316277],
            [-154.210302, 66.736254999316273],
            [-154.210317, 66.822841999318229],
            [-154.146221, 66.82284299931824],
            [-154.146314, 67.169177999326166],
            [-154.303097, 67.169176999326154],
            [-154.30311, 67.255758999328165],
            [-154.748904, 67.255752999328152],
            [-154.690477, 67.515495999334178],
            [-154.690489, 67.60207499933621],
            [-154.916649, 67.602069999336194],
            [-154.916661, 67.68864799933823],
            [-155.142825, 67.688642999338228],
            [-155.142837, 67.775220999340263],
            [-155.368999, 67.775215999340261],
            [-155.318723, 67.861792999342285],
            [-155.318744, 68.000031999345552],
            [-153.960576, 67.999999999345576],
            [-152.741341, 68.000008999345553],
            [-151.900276, 68.000006999345558],
            [-150.890028, 68.000003999345566],
            [-149.32543, 67.999998999345564],
            [-147.753117, 67.999987999345578],
            [-146.809716, 67.999999999345576],
            [-146.0, 67.999999999345576],
            [-146.0, 68.499999999357499],
            [-144.778977, 68.499999999357499],
            [-143.718715, 68.5000049993575],
            [-142.232922, 68.500006999357495],
            [-141.002609596218008, 68.498364003948566],
            [-141.002465, 65.840074999296306],
            [-141.002464926138003, 65.8394210000952],
            [-141.093233, 65.827387999296036],
            [-141.336224, 65.711613999293505],
            [-141.387842, 65.614394999291392],
            [-141.78905, 65.501545999288936],
            [-141.855761, 65.44578599928775],
            [-142.065431, 65.465340999288159],
            [-142.307081, 65.440632999287629],
            [-142.441972, 65.385041999286443],
            [-142.601998, 65.390930999286553],
            [-142.742849, 65.30140999928463],
            [-142.7319, 65.233638999283173],
            [-142.864344, 65.140386999281219],
            [-143.081816, 65.120936999280786],
            [-143.242092, 65.052517999279331],
            [-143.373311, 65.037106999279004],
            [-143.364478, 64.996995999278141],
            [-143.525172, 64.960079999277369],
            [-143.503643, 64.858250999275214],
            [-143.434679, 64.817790999274379],
            [-143.529991, 64.800610999273999],
            [-143.506329, 64.754067999273047],
            [-143.647213, 64.669505999271266],
            [-143.752529, 64.647632999270812],
            [-144.069029, 64.683561999271561],
            [-144.095375, 64.741712999272764],
            [-144.029789, 64.767592999273305],
            [-144.127807, 64.799107999273971],
            [-143.986949, 64.967242999277502],
            [-144.098484, 65.020384999278647],
            [-143.88408, 65.091067999280142],
            [-143.973639, 65.119303999280746],
            [-144.331377, 65.107706999280481],
            [-144.443678, 65.058435999279439],
            [-144.684887, 65.106142999280465],
            [-144.983514, 65.136987999281132],
            [-145.225944, 65.069215999279692],
            [-145.372503, 65.069495999279695],
            [-145.650312, 65.034302999278935],
            [-145.778697, 65.080002999279898],
            [-145.633882, 65.136801999281118],
            [-145.689426, 65.189077999282233],
            [-145.949873, 65.20548299928258],
            [-146.079908, 65.247980999283484],
            [-146.138999, 65.315192999284932],
            [-146.007899, 65.359433999285883],
            [-145.98704, 65.408442999286933],
            [-146.119349, 65.403986999286843],
            [-146.198886, 65.453522999287927],
            [-146.39704, 65.418420999287136],
            [-146.508383, 65.433108999287469],
            [-146.548654, 65.345002999285555],
            [-146.903142, 65.283374999284248],
            [-147.279115, 65.279761999284176],
            [-147.557771, 65.209894999282682],
            [-148.661199, 65.20987499928269],
            [-148.663265, 64.590789999269617],
            [-148.550742, 64.617207999270164],
            [-148.424918, 64.574853999269294],
            [-148.403117, 64.531296999268392],
            [-148.270857, 64.491768999267578],
            [-148.176111, 64.400923999265686],
            [-148.046696, 64.343801999264514],
            [-149.132653, 64.358213999264805],
            [-150.729385, 64.358172999264795],
            [-151.281995, 63.997264999257425],
            [-151.516333, 64.020630999257904],
            [-151.564903, 64.0694359992589],
            [-151.793976, 64.069429999258887],
            [-151.830535, 63.997247999257425],
            [-152.055758, 63.997241999257426],
            [-152.055132, 63.824005999253927],
            [-152.229256, 63.824000999253933],
            [-152.228439, 63.650759999250454],
            [-152.87341, 63.650744999250456],
            [-152.810945, 63.477500999247006],
            [-152.810931, 63.347562999244445],
            [-152.619517, 63.34756799924444],
            [-152.619512, 63.304253999243592],
            [-152.428098, 63.304258999243572],
            [-152.428084, 63.183170999241213],
            [-152.997647, 62.727400999232422],
            [-153.001506, 62.727440999232414],
            [-153.001997, 62.68149199923154],
            [-152.999839, 62.292620999224198],
            [-153.07012, 62.29264399922419],
            [-153.070123, 62.206014999222575],
            [-153.395009, 62.20598999922256],
            [-153.395009, 62.119346999220966],
            [-153.761871, 62.11933499922096],
            [-153.761875, 62.032688999219353],
            [-154.537299, 62.03267499921936],
            [-155.497645, 62.03265899921935],
            [-156.276897, 62.032645999219362],
            [-157.063852, 62.032633999219357],
            [-157.063864, 62.119270999220959],
            [-158.531361, 62.119263999220948],
            [-158.531351, 62.032619999219364],
            [-159.265035, 62.032597999219348],
            [-159.265037, 61.945956999217756],
            [-160.534671, 61.945939999217764],
            [-160.915953, 61.945942999217749],
            [-160.915964, 62.205865999222567],
            [-161.048165, 62.205868999222567],
            [-161.048176, 62.55240999922907]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "021",
        COUNTYNS: "00025447",
        AFFGEOID: "0500000US04021",
        GEOID: "04021",
        NAME: "Pinal",
        LSAD: "06",
        ALAND: 13897471114,
        AWATER: 22332471
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.203509, 32.683595999142007],
            [-112.202163, 33.24686199913446],
            [-112.203288, 33.310532999133628],
            [-112.083488, 33.204559999135014],
            [-111.893085, 33.204810999135006],
            [-111.869945, 33.20358299913503],
            [-111.686035, 33.204636999135005],
            [-111.582709, 33.205722999134991],
            [-111.580634, 33.465799999131619],
            [-111.039976, 33.466018999131606],
            [-110.974706, 33.342094999133217],
            [-110.955497, 33.279138999134041],
            [-110.781076, 32.983737999137936],
            [-110.725583, 33.069895999136804],
            [-110.632529, 33.14277099913582],
            [-110.593459, 33.13392599913594],
            [-110.449021, 33.194525999135145],
            [-110.449095, 32.536546999144022],
            [-110.451218, 32.513994999144344],
            [-110.842071, 32.513864999144346],
            [-110.85872, 32.511222999144387],
            [-111.157493, 32.511254999144377],
            [-111.351531, 32.501354999144517],
            [-111.792534, 32.506778999144444],
            [-112.203719, 32.506759999144442],
            [-112.203509, 32.683595999142007]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "180",
        COUNTYNS: "01419977",
        AFFGEOID: "0500000US02180",
        GEOID: "02180",
        NAME: "Nome",
        LSAD: "05",
        ALAND: 59496552709,
        AWATER: 13774839669
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-168.12893, 65.655743999292284],
              [-167.979889, 65.727971999293857],
              [-167.650051, 65.795702999295344],
              [-167.282753, 65.897385999297569],
              [-166.768944, 66.06858199930133],
              [-166.038082, 66.269511999305792],
              [-165.407204, 66.420440999309164],
              [-164.816093, 66.525024999311512],
              [-164.400724, 66.581109999312758],
              [-164.395943591522013, 66.581197638227877],
              [-164.395887, 66.476323999310409],
              [-164.318936, 66.476318999310422],
              [-164.318913, 66.129943999302682],
              [-164.244191, 66.12994599930272],
              [-164.244127, 65.783552999295068],
              [-163.963516, 65.783560999295062],
              [-163.963498, 65.610362999291297],
              [-163.755407, 65.610366999291315],
              [-163.755391, 65.437164999287546],
              [-162.331036, 65.43715799928755],
              [-160.976372, 65.437194999287556],
              [-159.801692, 65.437230999287564],
              [-159.8017, 65.523825999289414],
              [-159.593506, 65.52383499928942],
              [-159.385578, 65.52383499928942],
              [-159.377365, 65.264032999283842],
              [-159.582736, 65.264025999283845],
              [-159.572173, 64.917596999276469],
              [-159.774921, 64.917564999276465],
              [-159.774897, 64.744381999272832],
              [-159.96229, 64.744376999272831],
              [-159.962255, 64.397926999265636],
              [-159.947288, 64.051458999258529],
              [-159.737475, 64.051458999258529],
              [-159.737451, 63.791590999253273],
              [-159.932731, 63.791588999253278],
              [-159.932739, 63.704972999251538],
              [-160.111498, 63.704971999251534],
              [-160.111522, 63.618350999249799],
              [-160.497381, 63.618342999249805],
              [-160.497375, 63.531712999248086],
              [-160.690294, 63.53168899924809],
              [-160.690263, 63.358484999244645],
              [-160.667361, 63.271844999242951],
              [-160.858004, 63.271837999242962],
              [-160.85799, 63.011952999237884],
              [-162.001769, 63.011918999237899],
              [-162.001798, 63.098535999239559],
              [-162.192443, 63.098555999239558],
              [-162.192503, 63.185183999241261],
              [-162.383076, 63.185194999241254],
              [-162.383082, 63.271815999242961],
              [-162.602860986156998, 63.277182502712066],
              [-162.526588, 63.31655099924383],
              [-162.42153, 63.409013999245651],
              [-162.352274, 63.454068999246545],
              [-162.562007, 63.537104999248186],
              [-162.707559, 63.577606999249006],
              [-162.587527, 63.625114999249952],
              [-162.401203, 63.634366999250126],
              [-162.252411, 63.541752999248281],
              [-162.073156, 63.513767999247712],
              [-161.982168, 63.446312999246395],
              [-161.676526, 63.465002999246764],
              [-161.421085, 63.460149999246667],
              [-161.191163, 63.490071999247249],
              [-161.073573, 63.561699999248681],
              [-160.783304, 63.752892999252509],
              [-160.76562, 63.828713999254035],
              [-160.900464, 63.998339999257453],
              [-160.941096, 64.066318999258826],
              [-160.962007, 64.220574999261984],
              [-161.177712, 64.343540999264505],
              [-161.263519, 64.398165999265629],
              [-161.504903, 64.423073999266165],
              [-161.469046, 64.506574999267869],
              [-161.389879, 64.547832999268735],
              [-161.198029, 64.49662599926765],
              [-160.992894, 64.541294999268587],
              [-160.793356, 64.619316999270225],
              [-160.783398, 64.717159999272255],
              [-160.935974, 64.822369999274471],
              [-161.079718, 64.869548999275466],
              [-161.133062, 64.898218999276068],
              [-161.213756, 64.88332399927576],
              [-161.327848, 64.829835999274621],
              [-161.376985, 64.773035999273432],
              [-161.518211, 64.753249999273024],
              [-161.64552, 64.776451999273505],
              [-161.772978, 64.749257999272928],
              [-161.878363, 64.709475999272087],
              [-162.060291, 64.692871999271745],
              [-162.188146, 64.672394999271333],
              [-162.234477, 64.619335999270234],
              [-162.539996, 64.530930999268378],
              [-162.603236, 64.479903999267307],
              [-162.632242, 64.385733999265383],
              [-162.768424, 64.333515999264307],
              [-162.83654, 64.436701999266418],
              [-162.857562, 64.49977999926773],
              [-162.940776, 64.54241699926861],
              [-163.033231, 64.519313999268135],
              [-163.027158, 64.47794499926728],
              [-163.091486, 64.437735999266451],
              [-163.133172, 64.3818439992653],
              [-163.249092, 64.456222999266842],
              [-163.4129, 64.524985999268253],
              [-163.686337, 64.568797999269165],
              [-163.829739, 64.574964999269284],
              [-163.974352, 64.551369999268786],
              [-164.147059, 64.564551999269071],
              [-164.307273, 64.561487999269019],
              [-164.548298, 64.516737999268102],
              [-164.807747, 64.449431999266693],
              [-165.001961, 64.433916999266359],
              [-165.291644, 64.480730999267337],
              [-165.819595, 64.540170999268582],
              [-166.236939, 64.583557999269487],
              [-166.413926, 64.651228999270884],
              [-166.482682, 64.755100999273054],
              [-166.478978, 64.79703599927393],
              [-166.407315, 64.852280999275095],
              [-166.432246, 64.883159999275748],
              [-166.586066, 64.955711999277284],
              [-166.697808, 64.99120099927805],
              [-166.73725, 65.027525999278808],
              [-166.911922, 65.125964999280882],
              [-166.886677, 65.13876299928117],
              [-166.634449, 65.125872999280887],
              [-166.479913, 65.167248999281767],
              [-166.451711, 65.236177999283242],
              [-166.347189, 65.276340999284116],
              [-166.439404, 65.319057999285022],
              [-166.596964, 65.336245999285396],
              [-166.750702, 65.333171999285312],
              [-166.899681, 65.360641999285917],
              [-167.067707, 65.385116999286424],
              [-167.348739, 65.397890999286716],
              [-167.474024, 65.412743999287031],
              [-167.684378, 65.489078999288694],
              [-167.851234, 65.538180999289736],
              [-168.04762, 65.56914899929042],
              [-168.12893, 65.655743999292284]
            ]
          ],
          [
            [
              [-171.83683, 63.564882999248745],
              [-171.791881, 63.620624999249863],
              [-171.802824, 63.716390999251765],
              [-171.743398, 63.782970999253095],
              [-171.613182, 63.785064999253144],
              [-171.58305, 63.715556999251746],
              [-171.552856, 63.666250999250764],
              [-171.309333, 63.621084999249859],
              [-170.950817, 63.570126999248849],
              [-170.859032, 63.587502999249196],
              [-170.606282, 63.6727319992509],
              [-170.488192, 63.696722999251371],
              [-170.344855, 63.694224999251318],
              [-170.26748, 63.675815999250965],
              [-170.176413, 63.62548899924996],
              [-170.095833, 63.612700999249697],
              [-170.048963, 63.537957999248199],
              [-170.007943, 63.475427999246968],
              [-169.857078, 63.441974999246305],
              [-169.656474, 63.429928999246066],
              [-169.566562, 63.388724999245248],
              [-169.462733, 63.360457999244701],
              [-169.087914, 63.340936999244306],
              [-168.937385, 63.33378899924417],
              [-168.685145, 63.29642699924343],
              [-168.751537, 63.217961999241915],
              [-168.841654, 63.153843999240642],
              [-168.93915, 63.137652999240323],
              [-169.07503, 63.177688999241099],
              [-169.230523, 63.172947999241018],
              [-169.436748, 63.113578999239856],
              [-169.534984, 63.074354999239098],
              [-169.576965, 63.027024999238165],
              [-169.568016, 62.976878999237215],
              [-169.638309, 62.937526999236447],
              [-169.757249, 62.960086999236871],
              [-169.788466, 63.04301499923848],
              [-169.88123, 63.105847999239721],
              [-170.049622, 63.163376999240832],
              [-170.186485, 63.18161799924119],
              [-170.263032, 63.179146999241141],
              [-170.30363, 63.238691999242313],
              [-170.430656, 63.314283999243784],
              [-170.663536, 63.376108999245005],
              [-170.896167, 63.417744999245826],
              [-171.067663, 63.424578999245966],
              [-171.226326, 63.39510799924539],
              [-171.285411, 63.366463999244822],
              [-171.433319, 63.307577999243655],
              [-171.528084, 63.324932999243984],
              [-171.667115, 63.35616599924461],
              [-171.760112, 63.38163299924512],
              [-171.849984, 63.485038999247159],
              [-171.83683, 63.564882999248745]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "079",
        COUNTYNS: "00198155",
        AFFGEOID: "0500000US08079",
        GEOID: "08079",
        NAME: "Mineral",
        LSAD: "06",
        ALAND: 2268209080,
        AWATER: 5222161
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.138191, 37.938847999084892],
            [-107.000602, 37.956040999084756],
            [-106.879167, 37.961109999084712],
            [-106.84727, 37.919169999085057],
            [-106.693523, 37.87351899908542],
            [-106.693845, 37.835243999085726],
            [-106.711624, 37.664272999087139],
            [-106.710775, 37.404227999089315],
            [-107.128737, 37.392408999089426],
            [-107.12868, 37.422941999089161],
            [-107.145138, 37.675496999087038],
            [-107.138191, 37.938847999084892]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "097",
        COUNTYNS: "01686467",
        AFFGEOID: "0500000US13097",
        GEOID: "13097",
        NAME: "Douglas",
        LSAD: "06",
        ALAND: 518261551,
        AWATER: 2517004
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.901688, 33.780702999127612],
            [-84.725477, 33.788578999127523],
            [-84.724139, 33.806169999127299],
            [-84.622692, 33.804841999127312],
            [-84.578132, 33.74350699912808],
            [-84.630117, 33.693115999128715],
            [-84.796536, 33.595591999129951],
            [-84.808934, 33.574084999130235],
            [-84.905788, 33.573377999130244],
            [-84.901688, 33.780702999127612]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "011",
        COUNTYNS: "00400593",
        AFFGEOID: "0500000US16011",
        GEOID: "16011",
        NAME: "Bingham",
        LSAD: "06",
        ALAND: 5422887230,
        AWATER: 68487087
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.007712, 43.110932999058704],
            [-113.007318, 43.284777999058356],
            [-112.947016, 43.284820999058361],
            [-112.936835, 43.449385999058066],
            [-112.816704, 43.449163999058051],
            [-112.816278, 43.535948999057908],
            [-112.698891, 43.540051999057908],
            [-112.697122, 43.623139999057763],
            [-112.639238, 43.626573999057769],
            [-112.519861, 43.626622999057766],
            [-112.520024, 43.425169999058113],
            [-112.103298, 43.425056999058093],
            [-112.043672, 43.410142999058131],
            [-112.043821, 43.366707999058207],
            [-111.822475, 43.366034999058215],
            [-111.822494, 43.282285999058374],
            [-111.588049, 43.281840999058367],
            [-111.589489, 43.020051999058914],
            [-112.063023, 43.022363999058896],
            [-112.678364, 43.021729999058905],
            [-112.750562, 42.951527999059053],
            [-112.816578, 42.863230999059269],
            [-113.00776, 42.863050999059276],
            [-113.007712, 43.110932999058704]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "089",
        COUNTYNS: "00424246",
        AFFGEOID: "0500000US17089",
        GEOID: "17089",
        NAME: "Kane",
        LSAD: "06",
        ALAND: 1347216944,
        AWATER: 10775892
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.60156, 42.017924999061712],
            [-88.58833, 42.066461999061545],
            [-88.588657, 42.153589999061261],
            [-88.393762, 42.15389999906126],
            [-88.354183, 42.154223999061266],
            [-88.257689, 42.154072999061249],
            [-88.238369, 42.154252999061256],
            [-88.238291, 42.092347999061474],
            [-88.263378, 42.066625999061543],
            [-88.263255, 42.015491999061716],
            [-88.262876, 41.986226999061813],
            [-88.26241, 41.899233999062112],
            [-88.262492, 41.882885999062182],
            [-88.26279, 41.812300999062423],
            [-88.26279, 41.81213199906243],
            [-88.261952, 41.72465199906275],
            [-88.37532, 41.722609999062733],
            [-88.4045, 41.722269999062753],
            [-88.601933, 41.719562999062767],
            [-88.60156, 42.017924999061712]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "125",
        COUNTYNS: "01008573",
        AFFGEOID: "0500000US37125",
        GEOID: "37125",
        NAME: "Moore",
        LSAD: "06",
        ALAND: 1806961891,
        AWATER: 20736340
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.767957, 35.51148399910754],
            [-79.555804, 35.51503899910751],
            [-79.350073, 35.518001999107469],
            [-79.359765, 35.46967499910798],
            [-79.273565, 35.35336899910925],
            [-79.183556, 35.307160999109747],
            [-79.223021, 35.268132999110172],
            [-79.095808, 35.192067999110996],
            [-79.097602, 35.174193999111203],
            [-79.246383, 35.212963999110777],
            [-79.335477, 35.16158799911134],
            [-79.458751, 35.043638999112659],
            [-79.575931, 35.069903999112356],
            [-79.614304, 35.163678999111319],
            [-79.70268, 35.255906999110302],
            [-79.767957, 35.51148399910754]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "181",
        COUNTYNS: "00465279",
        AFFGEOID: "0500000US19181",
        GEOID: "19181",
        NAME: "Warren",
        LSAD: "06",
        ALAND: 1475875019,
        AWATER: 9095218
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.790612, 41.511915999063561],
            [-93.417688, 41.511541999063553],
            [-93.328407, 41.490920999063633],
            [-93.327886, 41.160658999065006],
            [-93.557556, 41.161270999065017],
            [-93.789196, 41.162034999064993],
            [-93.790612, 41.511915999063561]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "081",
        COUNTYNS: "00516887",
        AFFGEOID: "0500000US21081",
        GEOID: "21081",
        NAME: "Grant",
        LSAD: "06",
        ALAND: 668161261,
        AWATER: 7236760
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.780766, 38.765207999078683],
            [-84.660107, 38.777292999078597],
            [-84.615664, 38.802276999078408],
            [-84.532288, 38.791936999078487],
            [-84.476299, 38.542722999080283],
            [-84.485295, 38.508551999080538],
            [-84.557375, 38.492916999080641],
            [-84.580539, 38.473038999080785],
            [-84.774322, 38.618593999079728],
            [-84.785788, 38.720458999078986],
            [-84.780766, 38.765207999078683]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "049",
        COUNTYNS: "00465213",
        AFFGEOID: "0500000US19049",
        GEOID: "19049",
        NAME: "Dallas",
        LSAD: "06",
        ALAND: 1523691141,
        AWATER: 8561943
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.28034, 41.862998999062235],
            [-94.164138, 41.863243999062227],
            [-93.815721, 41.863418999062247],
            [-93.814282, 41.600455999063215],
            [-93.790612, 41.511915999063561],
            [-93.896807, 41.503097999063598],
            [-94.241593, 41.503678999063574],
            [-94.241356, 41.600970999063215],
            [-94.280057, 41.601311999063206],
            [-94.28034, 41.862998999062235]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "133",
        COUNTYNS: "00695787",
        AFFGEOID: "0500000US28133",
        GEOID: "28133",
        NAME: "Sunflower",
        LSAD: "06",
        ALAND: 1807187009,
        AWATER: 23747315
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.763509, 33.529388999130802],
            [-90.763469, 33.616163999129697],
            [-90.6586, 33.616271999129687],
            [-90.657002, 33.987585999125038],
            [-90.451012, 33.985688999125081],
            [-90.451643, 33.811389999127236],
            [-90.453233, 33.32948399913338],
            [-90.555549, 33.329180999133385],
            [-90.555211, 33.270532999134147],
            [-90.716277, 33.270031999134154],
            [-90.763773, 33.270358999134146],
            [-90.763509, 33.529388999130802]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "125",
        COUNTYNS: "00695783",
        AFFGEOID: "0500000US28125",
        GEOID: "28125",
        NAME: "Sharkey",
        LSAD: "06",
        ALAND: 1118149660,
        AWATER: 8806465
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.963753, 32.838132999139901],
            [-90.914337, 33.007696999137622],
            [-90.914465, 33.097416999136435],
            [-90.701845, 33.096293999136435],
            [-90.658049, 33.096513999136434],
            [-90.656632, 32.922509999138754],
            [-90.759753, 32.835762999139931],
            [-90.694631, 32.815641999140198],
            [-90.742634, 32.72922499914138],
            [-90.667678, 32.670982999142169],
            [-90.723159, 32.662127999142299],
            [-90.964864, 32.662797999142278],
            [-90.963753, 32.838132999139901]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "167",
        COUNTYNS: "00516930",
        AFFGEOID: "0500000US21167",
        GEOID: "21167",
        NAME: "Mercer",
        LSAD: "06",
        ALAND: 644463522,
        AWATER: 11600781
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.030528, 37.891537999085273],
            [-84.933085, 37.954185999084771],
            [-84.796327, 37.97008799908464],
            [-84.826454, 37.916457999085083],
            [-84.765204, 37.847834999085627],
            [-84.709918, 37.861951999085505],
            [-84.689021, 37.851090999085599],
            [-84.716925, 37.81538899908589],
            [-84.689343, 37.727273999086613],
            [-84.744888, 37.713074999086722],
            [-84.793574, 37.717248999086685],
            [-85.025021, 37.678853999087011],
            [-85.00052, 37.854722999085567],
            [-85.031456, 37.881596999085353],
            [-85.030528, 37.891537999085273]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "119",
        COUNTYNS: "00758518",
        AFFGEOID: "0500000US29119",
        GEOID: "29119",
        NAME: "McDonald",
        LSAD: "06",
        ALAND: 1397247177,
        AWATER: 520251
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.618307, 36.766559999095023],
            [-94.068898, 36.747817999095183],
            [-94.077088266893469, 36.498975956319455],
            [-94.361203, 36.499599999097541],
            [-94.617919, 36.499413999097541],
            [-94.617815, 36.612603999096464],
            [-94.617991778417192, 36.667921271440157],
            [-94.618307, 36.766559999095023]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "015",
        COUNTYNS: "01719619",
        AFFGEOID: "0500000US30015",
        GEOID: "30015",
        NAME: "Chouteau",
        LSAD: "06",
        ALAND: 10288824961,
        AWATER: 62565296
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.409097, 48.132217999062568],
            [-110.827168, 48.133204999062571],
            [-110.827413, 48.219565999062894],
            [-110.755873, 48.219600999062884],
            [-110.625543, 48.21973099906289],
            [-110.625409, 48.306478999063216],
            [-109.845932, 48.30597099906322],
            [-109.845812, 48.219003999062885],
            [-109.726943, 48.2215249990629],
            [-109.726914, 48.13285899906257],
            [-109.534295, 48.134563999062578],
            [-109.538677, 47.738863999061216],
            [-109.691058, 47.728553999061184],
            [-109.722711, 47.702620999061104],
            [-109.836861, 47.712040999061131],
            [-109.850793, 47.539141999060611],
            [-110.030854, 47.479550999060429],
            [-110.159579, 47.415311999060243],
            [-110.213596, 47.416656999060251],
            [-110.639559, 47.416485999060249],
            [-110.639589, 47.445424999060336],
            [-110.769129, 47.44609599906034],
            [-110.768465, 47.525637999060571],
            [-110.86463, 47.524713999060559],
            [-111.049847, 47.598332999060787],
            [-110.976171, 47.698737999061088],
            [-111.40885, 47.698122999061084],
            [-111.408514, 47.987177999062055],
            [-111.409097, 48.132217999062568]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "041",
        COUNTYNS: "00835842",
        AFFGEOID: "0500000US31041",
        GEOID: "31041",
        NAME: "Custer",
        LSAD: "06",
        ALAND: 6670757281,
        AWATER: 932956
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.251224, 41.739816999062683],
            [-99.686834, 41.740325999062684],
            [-99.22183, 41.740791999062679],
            [-99.212016, 41.740612999062684],
            [-99.212394, 41.408677999063961],
            [-99.203261, 41.394139999064045],
            [-99.207564, 41.047002999065512],
            [-99.425986, 41.046470999065512],
            [-100.224235, 41.046596999065521],
            [-100.238115, 41.061674999065445],
            [-100.251098, 41.393297999064025],
            [-100.251224, 41.739816999062683]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "081",
        COUNTYNS: "00835862",
        AFFGEOID: "0500000US31081",
        GEOID: "31081",
        NAME: "Hamilton",
        LSAD: "06",
        ALAND: 1403509100,
        AWATER: 7360265
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.282578, 40.868676999066331],
            [-98.010548, 41.072760999065409],
            [-97.874073, 41.123147999065175],
            [-97.828256, 41.173438999064949],
            [-97.826287, 41.046580999065512],
            [-97.825723, 40.698554999067142],
            [-98.278103, 40.698290999067147],
            [-98.282851, 40.698283999067144],
            [-98.282578, 40.868676999066331]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "041",
        COUNTYNS: "01074033",
        AFFGEOID: "0500000US39041",
        GEOID: "39041",
        NAME: "Delaware",
        LSAD: "06",
        ALAND: 1147867060,
        AWATER: 36610530
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.248595, 40.443988999068409],
            [-83.020798, 40.433794999068468],
            [-82.924781, 40.41500399906856],
            [-82.929588, 40.358122999068861],
            [-82.744931, 40.349602999068907],
            [-82.750747, 40.276995999069293],
            [-82.761827, 40.125854999070114],
            [-82.971307, 40.134932999070067],
            [-83.005204, 40.136335999070056],
            [-83.169981, 40.143086999070029],
            [-83.17233, 40.244256999069464],
            [-83.248428, 40.244465999069469],
            [-83.248595, 40.443988999068409]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "033",
        COUNTYNS: "01035617",
        AFFGEOID: "0500000US38033",
        GEOID: "38033",
        NAME: "Golden Valley",
        LSAD: "06",
        ALAND: 2593894350,
        AWATER: 4147470
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.045542, 46.93388699905902],
            [-104.044788, 47.12742999905948],
            [-104.045308309869014, 47.330127857866003],
            [-103.666723, 47.329353999059997],
            [-103.666986, 46.979788999059124],
            [-103.609545, 46.979901999059116],
            [-103.60921, 46.629796999058378],
            [-103.800825, 46.629562999058379],
            [-103.800881, 46.540746999058207],
            [-104.045125413323007, 46.540929927607216],
            [-104.045385103689014, 46.641501058180211],
            [-104.045572, 46.713880999058539],
            [-104.045542, 46.93388699905902]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "079",
        COUNTYNS: "01209183",
        AFFGEOID: "0500000US42079",
        GEOID: "42079",
        NAME: "Luzerne",
        LSAD: "06",
        ALAND: 2305976986,
        AWATER: 41237262
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.310133, 41.310198999064362],
            [-76.2831, 41.376516999064094],
            [-76.00507, 41.384550999064061],
            [-75.834695, 41.427245999063892],
            [-75.795286, 41.378126999064094],
            [-75.760518, 41.360941999064167],
            [-75.687244, 41.339215999064251],
            [-75.652922, 41.25831399906459],
            [-75.643494, 41.176258999064942],
            [-75.600736, 41.161496999065001],
            [-75.649637, 41.122343999065173],
            [-75.693388, 41.131058999065147],
            [-75.770196, 41.0502189990655],
            [-75.73193, 41.008219999065687],
            [-75.997348, 40.912984999066126],
            [-76.02815, 40.901985999066177],
            [-76.207827, 40.949739999065962],
            [-76.228975, 41.138465999065119],
            [-76.277639, 41.131803999065141],
            [-76.319236, 41.208091999064813],
            [-76.310133, 41.310198999064362]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "129",
        COUNTYNS: "01209191",
        AFFGEOID: "0500000US42129",
        GEOID: "42129",
        NAME: "Westmoreland",
        LSAD: "06",
        ALAND: 2661370996,
        AWATER: 22050453
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.904778, 40.157254999069949],
            [-79.900346, 40.162502999069922],
            [-79.893306, 40.164982999069906],
            [-79.887337, 40.164982999069906],
            [-79.870668, 40.162126999069919],
            [-79.870585, 40.197414999069728],
            [-79.781761, 40.227710999069565],
            [-79.783806, 40.315440999069089],
            [-79.764646, 40.3903459990687],
            [-79.722151, 40.409097999068599],
            [-79.704503, 40.434024999068477],
            [-79.701711, 40.525490999068005],
            [-79.722387, 40.542042999067917],
            [-79.748228, 40.551180999067867],
            [-79.765415, 40.549853999067878],
            [-79.765946, 40.550914999067864],
            [-79.77187, 40.562966999067811],
            [-79.77437, 40.569766999067781],
            [-79.773331, 40.580757999067714],
            [-79.772285, 40.58378799906771],
            [-79.76377, 40.592965999067658],
            [-79.749187, 40.598969999067634],
            [-79.712477, 40.620075999067524],
            [-79.692587, 40.669731999067281],
            [-79.668345, 40.680048999067225],
            [-79.450176, 40.530148999067983],
            [-79.453765, 40.484249999068204],
            [-79.395461, 40.457131999068352],
            [-79.155789, 40.411764999068581],
            [-79.121634, 40.370575999068805],
            [-79.016617, 40.420278999068543],
            [-78.974649, 40.395971999068671],
            [-79.040788, 40.302316999069156],
            [-79.055983, 40.285088999069252],
            [-79.088656, 40.241258999069494],
            [-79.183619, 40.110685999070199],
            [-79.293682, 40.040412999070604],
            [-79.480367, 40.143123999070021],
            [-79.641098, 40.088316999070329],
            [-79.744404, 40.128167999070094],
            [-79.877385, 40.126791999070115],
            [-79.90417, 40.151966999069977],
            [-79.904778, 40.157254999069949]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "017",
        COUNTYNS: "01265767",
        AFFGEOID: "0500000US46017",
        GEOID: "46017",
        NAME: "Buffalo",
        LSAD: "06",
        ALAND: 1220891600,
        AWATER: 42362357
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.576571, 44.192420999057084],
            [-99.30018, 44.194829999057092],
            [-98.925953, 44.196574999057077],
            [-98.926997, 43.935142999057355],
            [-99.355864, 43.93437099905735],
            [-99.345995, 43.9814459990573],
            [-99.562536, 44.101518999057177],
            [-99.576571, 44.192420999057084]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "041",
        COUNTYNS: "01383806",
        AFFGEOID: "0500000US48041",
        GEOID: "48041",
        NAME: "Brazos",
        LSAD: "06",
        ALAND: 1516471988,
        AWATER: 14824446
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.566983, 30.695669999171262],
            [-96.45763, 30.744473999170499],
            [-96.338001, 30.920530999167749],
            [-96.24102, 30.973736999166924],
            [-96.207477, 30.947080999167337],
            [-96.16837, 30.822996999169266],
            [-96.187765, 30.598736999172786],
            [-96.121806, 30.443425999175261],
            [-96.079969, 30.430029999175478],
            [-96.147255, 30.382839999176223],
            [-96.15463, 30.330287999177077],
            [-96.178661, 30.394717999176034],
            [-96.272455, 30.358467999176622],
            [-96.299173, 30.37915199917629],
            [-96.272049, 30.416144999175703],
            [-96.340875, 30.474542999174766],
            [-96.358817, 30.541752999173692],
            [-96.510494, 30.627827999172332],
            [-96.601831, 30.641925999172106],
            [-96.566983, 30.695669999171262]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "135",
        COUNTYNS: "01493928",
        AFFGEOID: "0500000US51135",
        GEOID: "51135",
        NAME: "Nottoway",
        LSAD: "06",
        ALAND: 814265581,
        AWATER: 4297829
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.231234, 37.296219999090255],
            [-77.826108, 37.201985999091079],
            [-77.795926, 37.192591999091171],
            [-77.900338, 37.14388199909159],
            [-77.889813, 36.98881799909298],
            [-77.968114, 36.987291999092996],
            [-78.003639, 37.022755999092681],
            [-78.082023, 37.011120999092782],
            [-78.239354, 37.120088999091813],
            [-78.231234, 37.296219999090255]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "023",
        COUNTYNS: "01674418",
        AFFGEOID: "0500000US51023",
        GEOID: "51023",
        NAME: "Botetourt",
        LSAD: "06",
        ALAND: 1401891585,
        AWATER: 12133615
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.074119, 37.422468999089183],
            [-79.969071, 37.544407999088129],
            [-80.020554, 37.647441999087256],
            [-79.91756, 37.701847999086823],
            [-79.816721, 37.800972999086014],
            [-79.674162, 37.763092999086318],
            [-79.683554, 37.661762999087159],
            [-79.498699, 37.532796999088234],
            [-79.588526, 37.449595999088949],
            [-79.626124, 37.455283999088891],
            [-79.634206, 37.457020999088869],
            [-79.658798, 37.485009999088632],
            [-79.69006, 37.476373999088707],
            [-79.719076, 37.448956999088942],
            [-79.79049, 37.408789999089286],
            [-79.814203, 37.403653999089322],
            [-79.792185, 37.38082199908952],
            [-79.778616, 37.381782999089516],
            [-79.788151, 37.351115999089778],
            [-79.847476, 37.30935199909014],
            [-79.865099, 37.328416999089988],
            [-79.945616, 37.362996999089688],
            [-80.062622, 37.408324999089281],
            [-80.074119, 37.422468999089183]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "047",
        COUNTYNS: "01804503",
        AFFGEOID: "0500000US72047",
        GEOID: "72047",
        NAME: "Corozal",
        LSAD: "13",
        ALAND: 110248539,
        AWATER: 16284
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.365272, 18.333228999435985],
            [-66.338239, 18.36793399943506],
            [-66.318764, 18.36741099943508],
            [-66.278076, 18.329974999436068],
            [-66.296086, 18.257544999437986],
            [-66.313299, 18.2493239994382],
            [-66.350793, 18.241234999438419],
            [-66.377712, 18.293975999437023],
            [-66.365272, 18.333228999435985]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "147",
        COUNTYNS: "00069907",
        AFFGEOID: "0500000US05147",
        GEOID: "05147",
        NAME: "Woodruff",
        LSAD: "06",
        ALAND: 1519723278,
        AWATER: 18703959
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.407782, 35.212654999110775],
            [-91.437898, 35.297104999109855],
            [-91.438202, 35.369377999109069],
            [-91.356598, 35.408063999108656],
            [-91.347257, 35.439105999108321],
            [-91.252927, 35.445844999108239],
            [-91.251504, 35.3575569991092],
            [-91.039783, 35.354255999109235],
            [-91.04267, 35.148188999111483],
            [-91.04543, 35.001039999113118],
            [-91.149842, 35.00246999911311],
            [-91.237493, 35.004290999113088],
            [-91.293111, 34.985090999113304],
            [-91.29479, 34.912451999114126],
            [-91.36856, 34.912734999114114],
            [-91.363763, 35.051886999112561],
            [-91.465114, 35.089453999112138],
            [-91.407782, 35.212654999110775]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "005",
        COUNTYNS: "01581062",
        AFFGEOID: "0500000US55005",
        GEOID: "55005",
        NAME: "Barron",
        LSAD: "06",
        ALAND: 2234461780,
        AWATER: 70014121
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.154888, 45.63974199905703],
            [-92.031417, 45.639927999057036],
            [-91.540294, 45.637604999057032],
            [-91.541322, 45.292219999056833],
            [-91.542234, 45.206537999056799],
            [-91.665647, 45.207990999056804],
            [-92.156465, 45.209553999056801],
            [-92.154888, 45.63974199905703]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "127",
        COUNTYNS: "01623006",
        AFFGEOID: "0500000US26127",
        GEOID: "26127",
        NAME: "Oceana",
        LSAD: "06",
        ALAND: 1355525949,
        AWATER: 2026870042
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.540787, 43.644592999057735],
            [-86.510319, 43.698624999057664],
            [-86.445123, 43.771563999057562],
            [-86.435485038258307, 43.819429197061687],
            [-86.037884, 43.815610999057505],
            [-86.039491, 43.467446999058026],
            [-86.463516886681091, 43.472329698564714],
            [-86.479276, 43.515334999057963],
            [-86.529507, 43.593461999057808],
            [-86.540787, 43.644592999057735]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "073",
        COUNTYNS: "00659482",
        AFFGEOID: "0500000US27073",
        GEOID: "27073",
        NAME: "Lac qui Parle",
        LSAD: "06",
        ALAND: 1981394520,
        AWATER: 33643131
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.457553498485012, 45.268897927775214],
            [-96.311413, 45.251596999056815],
            [-96.103614, 45.176674999056793],
            [-96.036698, 45.15220599905679],
            [-95.824614, 44.986883999056765],
            [-95.804281, 44.949196999056767],
            [-95.736696, 44.93603999905676],
            [-95.737309, 44.891478999056766],
            [-95.847452, 44.891798999056768],
            [-95.849009, 44.805346999056781],
            [-96.454830515553795, 44.805550203102577],
            [-96.455840113633599, 44.977345247336757],
            [-96.457553498485012, 45.268897927775214]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "059",
        COUNTYNS: "01156673",
        AFFGEOID: "0500000US41059",
        GEOID: "41059",
        NAME: "Umatilla",
        LSAD: "06",
        ALAND: 8327941915,
        AWATER: 41647897
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.432138558816007, 45.913209143291191],
            [-119.364396, 45.921604999057301],
            [-119.25715, 45.939925999057323],
            [-119.12612, 45.932858999057309],
            [-119.061462, 45.958526999057341],
            [-118.987227539564998, 45.9998002125811],
            [-118.987129, 45.999854999057391],
            [-118.67787, 46.000934999057392],
            [-118.606789154188988, 46.000863248848589],
            [-118.36779, 46.000621999057394],
            [-117.99697042960301, 46.00019066622999],
            [-117.977657306531995, 46.000168201439415],
            [-117.977613, 45.883430999057268],
            [-117.97298, 45.860594999057227],
            [-117.972948, 45.816895999057195],
            [-118.045003, 45.817072999057189],
            [-118.066383, 45.688394999057081],
            [-118.117194, 45.688606999057072],
            [-118.116461, 45.470731999056916],
            [-118.197955, 45.47039999905693],
            [-118.197876, 45.427203999056893],
            [-118.428539, 45.429075999056884],
            [-118.428329, 45.355189999056854],
            [-118.697766, 45.345646999056854],
            [-118.656102, 45.257728999056816],
            [-118.655992, 45.196927999056797],
            [-118.546632, 45.196897999056787],
            [-118.518687, 45.080689999056773],
            [-118.518689, 44.995876999056755],
            [-119.162966, 44.996240999056766],
            [-119.146424, 45.082939999056777],
            [-119.145144, 45.515908999056954],
            [-119.248214, 45.516075999056945],
            [-119.248547, 45.601934999057008],
            [-119.43464, 45.603055999057005],
            [-119.432138558816007, 45.913209143291191]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "141",
        COUNTYNS: "01497999",
        AFFGEOID: "0500000US51141",
        GEOID: "51141",
        NAME: "Patrick",
        LSAD: "06",
        ALAND: 1250846053,
        AWATER: 7142208
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.602965, 36.652660999096085],
            [-80.525522, 36.644113999096177],
            [-80.46418, 36.708717999095562],
            [-80.458572, 36.738358999095283],
            [-80.386909, 36.797675999094729],
            [-80.235455, 36.872129999094049],
            [-80.066652, 36.841603999094325],
            [-80.041493, 36.794364999094768],
            [-80.094389, 36.779551999094906],
            [-80.053455043350098, 36.542639427183737],
            [-80.295243, 36.543972999097122],
            [-80.431605, 36.550218999097055],
            [-80.440101541963699, 36.55059527426036],
            [-80.612188788124598, 36.558216275477385],
            [-80.602965, 36.652660999096085]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "035",
        COUNTYNS: "01480108",
        AFFGEOID: "0500000US51035",
        GEOID: "51035",
        NAME: "Carroll",
        LSAD: "06",
        ALAND: 1229526812,
        AWATER: 7645484
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.042428, 36.806447999094644],
            [-80.812735, 36.85323199909422],
            [-80.740483, 36.879000999093982],
            [-80.68259, 36.89514399909384],
            [-80.633987, 36.9317799990935],
            [-80.46418, 36.708717999095562],
            [-80.525522, 36.644113999096177],
            [-80.602965, 36.652660999096085],
            [-80.612188788124598, 36.558216275477385],
            [-80.704831, 36.562318999096945],
            [-80.840213077246005, 36.561928450734243],
            [-80.914173, 36.6502459990961],
            [-80.888758, 36.675436999095858],
            [-80.877274, 36.692214999095718],
            [-80.891142, 36.695962999095684],
            [-80.90549, 36.686852999095763],
            [-80.914951, 36.694797999095684],
            [-80.920497, 36.678138999095843],
            [-80.932271, 36.671592999095907],
            [-81.042428, 36.806447999094644]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "037",
        COUNTYNS: "01265790",
        AFFGEOID: "0500000US46037",
        GEOID: "46037",
        NAME: "Day",
        LSAD: "06",
        ALAND: 2662165809,
        AWATER: 163751130
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.981457, 45.240414999056824],
            [-97.979592, 45.588482999056986],
            [-97.373366, 45.587633999056997],
            [-97.36464, 45.558727999056977],
            [-97.227089, 45.558157999056974],
            [-97.226244, 45.297646999056838],
            [-97.226281, 45.151825999056783],
            [-97.249127, 45.151169999056791],
            [-97.494254, 45.151630999056778],
            [-97.981792, 45.153294999056783],
            [-97.981457, 45.240414999056824]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "129",
        COUNTYNS: "01639778",
        AFFGEOID: "0500000US47129",
        GEOID: "47129",
        NAME: "Morgan",
        LSAD: "06",
        ALAND: 1352439646,
        AWATER: 823018
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.875842, 36.285091999099627],
            [-84.797861, 36.29689399909951],
            [-84.700785, 36.370823999098782],
            [-84.573741, 36.22997699910016],
            [-84.441034, 36.163477999100834],
            [-84.449734, 36.14167699910103],
            [-84.341812, 36.048976999101974],
            [-84.446054, 35.985983999102601],
            [-84.603763, 35.954008999102939],
            [-84.680633, 35.908453999103408],
            [-84.720727, 35.994913999102522],
            [-84.80546, 36.09050099910155],
            [-84.907753, 36.156292999100899],
            [-84.875842, 36.285091999099627]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "119",
        COUNTYNS: "01480147",
        AFFGEOID: "0500000US51119",
        GEOID: "51119",
        NAME: "Middlesex",
        LSAD: "06",
        ALAND: 337507714,
        AWATER: 208357022
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.723086, 37.756696999086373],
            [-76.668182, 37.778385999086197],
            [-76.587234, 37.737072999086529],
            [-76.528637, 37.630899999087418],
            [-76.2888778367019, 37.587359933191777],
            [-76.29796, 37.557635999088021],
            [-76.289492797178099, 37.536075287097006],
            [-76.404118, 37.53554199908821],
            [-76.437561, 37.514359999088384],
            [-76.55593, 37.555053999088045],
            [-76.654076, 37.60317399908763],
            [-76.693584, 37.627875999087436],
            [-76.751072, 37.728604999086606],
            [-76.723086, 37.756696999086373]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "075",
        COUNTYNS: "00450362",
        AFFGEOID: "0500000US18075",
        GEOID: "18075",
        NAME: "Jay",
        LSAD: "06",
        ALAND: 994310106,
        AWATER: 458556
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.219901, 40.379033999068753],
            [-85.206886, 40.386017999068713],
            [-85.206508, 40.479372999068232],
            [-85.200628, 40.495826999068157],
            [-85.201146, 40.567241999067797],
            [-85.068479, 40.568239999067785],
            [-84.802413878579401, 40.572212879714478],
            [-84.802547, 40.501809999068122],
            [-84.8029313665713, 40.465386620788102],
            [-84.804119, 40.352843999068888],
            [-84.804120527014902, 40.352762229365993],
            [-84.804917302578403, 40.310095914103215],
            [-85.218758, 40.306705999069138],
            [-85.219901, 40.379033999068753]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "053",
        COUNTYNS: "00465215",
        AFFGEOID: "0500000US19053",
        GEOID: "19053",
        NAME: "Decatur",
        LSAD: "06",
        ALAND: 1377567314,
        AWATER: 4140764
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.014803, 40.897030999066203],
            [-93.55654, 40.898294999066188],
            [-93.556896674271684, 40.579659484128229],
            [-93.597352, 40.579495999067731],
            [-93.774344204030285, 40.577530452458852],
            [-93.84093, 40.57679099906774],
            [-94.015492, 40.574073706077357],
            [-94.014803, 40.897030999066203]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "105",
        COUNTYNS: "00465241",
        AFFGEOID: "0500000US19105",
        GEOID: "19105",
        NAME: "Jones",
        LSAD: "06",
        ALAND: 1490771974,
        AWATER: 3661438
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.363263, 42.29644499906081],
            [-91.130079, 42.295760999060811],
            [-90.89802, 42.295235999060822],
            [-90.898373, 42.033547999061646],
            [-90.898484, 41.946244999061932],
            [-91.365789, 41.947409999061939],
            [-91.363263, 42.29644499906081]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "041",
        COUNTYNS: "00277285",
        AFFGEOID: "0500000US06041",
        GEOID: "06041",
        NAME: "Marin",
        LSAD: "06",
        ALAND: 1347976832,
        AWATER: 797029137
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.011533, 38.003437999084383],
            [-122.960889, 38.112961999083517],
            [-122.953629, 38.175669999083041],
            [-122.987149, 38.237537999082562],
            [-122.986319, 38.273163999082293],
            [-123.003146477056006, 38.295705237796625],
            [-122.911319, 38.321208999081925],
            [-122.7399, 38.207017999082801],
            [-122.565093, 38.182216999082989],
            [-122.505707, 38.115525999083509],
            [-122.488499639259032, 38.10909447339175],
            [-122.491283, 38.108086999083568],
            [-122.499465, 38.032164999084159],
            [-122.452995, 37.996166999084444],
            [-122.488665, 37.966713999084675],
            [-122.486375, 37.921880999085033],
            [-122.448413, 37.893409999085264],
            [-122.41847, 37.852720999085584],
            [-122.483483, 37.826727999085797],
            [-122.537285, 37.830327999085782],
            [-122.60129, 37.875125999085412],
            [-122.678474, 37.906603999085164],
            [-122.70264, 37.89381999908526],
            [-122.754606, 37.935526999084921],
            [-122.797405, 37.976656999084589],
            [-122.856573, 38.016716999084281],
            [-122.939711, 38.031907999084154],
            [-122.97439, 37.992428999084467],
            [-123.011533, 38.003437999084383]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "169",
        COUNTYNS: "00516931",
        AFFGEOID: "0500000US21169",
        GEOID: "21169",
        NAME: "Metcalfe",
        LSAD: "06",
        ALAND: 750178423,
        AWATER: 3999881
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.776962, 37.138327999091644],
            [-85.744221, 37.16981299909137],
            [-85.686482, 37.182426999091248],
            [-85.632313, 37.125055999091764],
            [-85.526881, 37.109449999091893],
            [-85.503629, 37.007679999092815],
            [-85.445337, 36.957225999093275],
            [-85.45183, 36.938137999093435],
            [-85.560719, 36.850856999094248],
            [-85.601624, 36.872195999094039],
            [-85.596214, 36.818038999094547],
            [-85.613014, 36.832716999094409],
            [-85.739255, 36.841475999094335],
            [-85.75609, 37.109834999091895],
            [-85.776962, 37.138327999091644]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "043",
        COUNTYNS: "01101809",
        AFFGEOID: "0500000US40043",
        GEOID: "40043",
        NAME: "Dewey",
        LSAD: "06",
        ALAND: 2588642249,
        AWATER: 22679881
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.382074, 36.164300999100824],
            [-98.955849, 36.161576999100845],
            [-98.636899, 36.164893999100805],
            [-98.631985, 35.812401999104381],
            [-99.375817, 35.812187999104388],
            [-99.375906, 35.875045999103733],
            [-99.381082, 35.898829999103491],
            [-99.381022, 36.012924999102331],
            [-99.382074, 36.164300999100824]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "043",
        COUNTYNS: "00558567",
        AFFGEOID: "0500000US22043",
        GEOID: "22043",
        NAME: "Grant",
        LSAD: "15",
        ALAND: 1665429795,
        AWATER: 56013947
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.973529, 31.708921999155841],
            [-92.619976, 31.70957199915582],
            [-92.619224, 31.797148999154544],
            [-92.362608, 31.796632999154557],
            [-92.35127, 31.624550999157091],
            [-92.275088, 31.5360869991584],
            [-92.277615, 31.496235999158987],
            [-92.196131, 31.477876999159253],
            [-92.631944, 31.390477999160574],
            [-92.692887, 31.399597999160434],
            [-92.718983, 31.517593999158667],
            [-92.806206, 31.598547999157468],
            [-92.908313, 31.625168999157069],
            [-92.964615, 31.680720999156243],
            [-92.973529, 31.708921999155841]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "029",
        COUNTYNS: "01035615",
        AFFGEOID: "0500000US38029",
        GEOID: "38029",
        NAME: "Emmons",
        LSAD: "06",
        ALAND: 3912007898,
        AWATER: 115480697
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.66189, 46.634449999058397],
            [-100.081198, 46.633395999058365],
            [-99.915508, 46.632924999058389],
            [-99.916758, 46.283170999057781],
            [-99.878431, 46.28281099905778],
            [-99.88006209480659, 45.941670914859522],
            [-99.880292, 45.94167199905732],
            [-100.152084, 45.942485999057332],
            [-100.294126, 45.943268999057331],
            [-100.499354215383988, 45.943631997871627],
            [-100.511793, 45.943653999057325],
            [-100.511949026151015, 45.943654069491828],
            [-100.614504, 46.071795999057485],
            [-100.629145, 46.145607999057589],
            [-100.558549, 46.249992999057739],
            [-100.551199, 46.363493999057908],
            [-100.590199, 46.427893999058007],
            [-100.561035, 46.5878469990583],
            [-100.66189, 46.634449999058397]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "075",
        COUNTYNS: "01034229",
        AFFGEOID: "0500000US38075",
        GEOID: "38075",
        NAME: "Renville",
        LSAD: "06",
        ALAND: 2271556737,
        AWATER: 40648334
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.021655, 48.807107999065295],
            [-102.021149424659001, 48.998756604477052],
            [-101.625438, 48.999167999066159],
            [-101.496737, 48.999144833071455],
            [-101.496684, 48.720077999064912],
            [-101.451275, 48.546078999064171],
            [-101.05966, 48.545336999064169],
            [-101.059802, 48.458447999063821],
            [-101.841969, 48.459544999063823],
            [-101.842141, 48.720142999064905],
            [-102.021428, 48.720282999064899],
            [-102.021655, 48.807107999065295]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "015",
        COUNTYNS: "01074020",
        AFFGEOID: "0500000US39015",
        GEOID: "39015",
        NAME: "Brown",
        LSAD: "06",
        ALAND: 1269138602,
        AWATER: 8895019
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.0526461732196, 38.771614666614433],
            [-83.99312, 39.254234999075365],
            [-83.865679, 39.247332999075411],
            [-83.872214, 39.021303999076913],
            [-83.673018, 39.02042999907691],
            [-83.705861635585592, 38.638037864145588],
            [-83.77216, 38.658149999079441],
            [-83.78362, 38.69564099907916],
            [-83.834015, 38.716007999079032],
            [-83.852085, 38.75143299907878],
            [-83.904375086104807, 38.767283874346468],
            [-83.928454, 38.774582999078618],
            [-83.978814, 38.787103999078518],
            [-84.051642, 38.77139699907864],
            [-84.0526461732196, 38.771614666614433]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "133",
        COUNTYNS: "00069182",
        AFFGEOID: "0500000US05133",
        GEOID: "05133",
        NAME: "Sevier",
        LSAD: "06",
        ALAND: 1463680831,
        AWATER: 41831844
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.474895, 34.019654999124654],
            [-94.47015267816208, 34.189864291248689],
            [-94.243739, 34.192500999122558],
            [-94.089758, 34.186834999122617],
            [-94.09543, 34.078545999123932],
            [-94.057534, 33.968218999125284],
            [-94.069934, 33.926779999125806],
            [-93.964656, 33.796153999127434],
            [-93.958828, 33.750993999127992],
            [-94.040849, 33.782045999127604],
            [-94.237445, 33.802073999127352],
            [-94.388548, 33.895175999126195],
            [-94.408672, 33.945280999125579],
            [-94.477266741529803, 33.940910927060429],
            [-94.474895, 34.019654999124654]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "073",
        COUNTYNS: "00277301",
        AFFGEOID: "0500000US06073",
        GEOID: "06073",
        NAME: "San Diego",
        LSAD: "06",
        ALAND: 10898787530,
        AWATER: 822557348
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.596188685737005, 33.386964993452537],
            [-117.57848, 33.453926999131767],
            [-117.508614, 33.469613999131575],
            [-117.509722, 33.505018999131124],
            [-117.364272, 33.505024999131116],
            [-117.259308, 33.454512999131765],
            [-117.250495, 33.451628999131792],
            [-117.241668, 33.44892799913184],
            [-117.241271, 33.431992999132056],
            [-117.141574, 33.43260999913204],
            [-117.089024, 33.430285999132074],
            [-117.030861, 33.426930999132111],
            [-116.821826, 33.426872999132122],
            [-116.197591, 33.428892999132096],
            [-116.085165, 33.425931999132132],
            [-116.08109, 33.074832999136731],
            [-116.103252, 33.074669999136724],
            [-116.105634, 32.721569999141487],
            [-116.106178889050014, 32.618578141778492],
            [-116.540643, 32.583746999143372],
            [-116.62705, 32.576260999143472],
            [-116.857154, 32.557458999143734],
            [-117.124862, 32.534155999144055],
            [-117.132038955738011, 32.585601186525444],
            [-117.136664, 32.618753999142896],
            [-117.168866, 32.671951999142152],
            [-117.196767, 32.688850999141934],
            [-117.246069, 32.669351999142201],
            [-117.255169, 32.700050999141773],
            [-117.25497, 32.786947999140587],
            [-117.280971, 32.822246999140106],
            [-117.28217, 32.83954699913987],
            [-117.27387, 32.851446999139725],
            [-117.262905, 32.849348999139742],
            [-117.25617, 32.859446999139607],
            [-117.256160535148993, 32.859673593241304],
            [-117.25447, 32.90014599913907],
            [-117.272139427178004, 32.975524581686855],
            [-117.28077, 33.012342999137566],
            [-117.315278, 33.09350399913648],
            [-117.362572, 33.168436999135494],
            [-117.445583, 33.268516999134171],
            [-117.547693, 33.365490999132909],
            [-117.59588, 33.38662899913264],
            [-117.596188685737005, 33.386964993452537]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "007",
        COUNTYNS: "00198119",
        AFFGEOID: "0500000US08007",
        GEOID: "08007",
        NAME: "Archuleta",
        LSAD: "06",
        ALAND: 3496731609,
        AWATER: 13720776
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.482131, 37.422672999089166],
            [-107.12868, 37.422941999089161],
            [-107.128737, 37.392408999089426],
            [-106.710775, 37.404227999089315],
            [-106.678373, 37.40359599908934],
            [-106.678354, 37.228565999090861],
            [-106.597384, 37.19497399909114],
            [-106.589178, 37.14018799909163],
            [-106.476233588828009, 36.993774099639843],
            [-106.869796, 36.992425999092958],
            [-106.877292, 37.000138999092883],
            [-107.420913, 37.000004999092887],
            [-107.420921905745004, 37.000004999031482],
            [-107.481736999155004, 37.000004579407182],
            [-107.482131, 37.422672999089166]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "127",
        COUNTYNS: "00306921",
        AFFGEOID: "0500000US12127",
        GEOID: "12127",
        NAME: "Volusia",
        LSAD: "06",
        ALAND: 2852082296,
        AWATER: 857948738
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.680903, 29.324429999193779],
            [-81.445886, 29.380141999192826],
            [-81.433992, 29.398551999192509],
            [-81.41729, 29.26115599919487],
            [-81.150081, 29.265956999194781],
            [-81.155881, 29.410953999192305],
            [-81.102967351745392, 29.426996649644028],
            [-81.046678, 29.307855999194057],
            [-80.966176, 29.147959999196807],
            [-80.907275, 29.064261999198266],
            [-80.787021, 28.875265999201559],
            [-80.727505670073796, 28.791192870525744],
            [-80.967895, 28.790196999203062],
            [-80.98725, 28.612996999206196],
            [-81.06111, 28.750000999203763],
            [-81.067413, 28.812988999202648],
            [-81.181433, 28.783276999203178],
            [-81.226003, 28.832629999202304],
            [-81.320473, 28.834529999202271],
            [-81.36694, 28.879226999201489],
            [-81.354682, 28.984806999199641],
            [-81.502055, 29.097801999197674],
            [-81.531262, 29.17662099919632],
            [-81.61234, 29.202942999195866],
            [-81.641916, 29.276765999194591],
            [-81.680903, 29.324429999193779]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "039",
        COUNTYNS: "01671316",
        AFFGEOID: "0500000US13039",
        GEOID: "13039",
        NAME: "Camden",
        LSAD: "06",
        ALAND: 1587815888,
        AWATER: 437170621
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.936749, 31.060786999165583],
            [-81.840671, 31.097664999165016],
            [-81.766322, 31.169594999163909],
            [-81.631624, 31.108116999164849],
            [-81.562606, 31.130284999164516],
            [-81.572692, 31.097268999165017],
            [-81.412518035579495, 30.990834502675053],
            [-81.408484, 30.977717999166863],
            [-81.405153, 30.908202999167937],
            [-81.44013, 30.821368999169287],
            [-81.460061, 30.769911999170091],
            [-81.444124, 30.709713999171033],
            [-81.507218, 30.722935999170826],
            [-81.528281, 30.723358999170813],
            [-81.56171, 30.715596999170948],
            [-81.633273, 30.72960299917073],
            [-81.668283, 30.744643999170492],
            [-81.732238, 30.749634999170411],
            [-81.763384, 30.773820999170034],
            [-81.808543, 30.790015999169778],
            [-81.868624, 30.792755999169742],
            [-81.902354, 30.820819999169284],
            [-81.905978439406383, 30.821407340671279],
            [-81.906036, 31.039714999165906],
            [-81.936006, 31.047992999165775],
            [-81.936749, 31.060786999165583]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "059",
        COUNTYNS: "00424231",
        AFFGEOID: "0500000US17059",
        GEOID: "17059",
        NAME: "Gallatin",
        LSAD: "06",
        ALAND: 836749960,
        AWATER: 13238356
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.37453, 37.907677999085145],
            [-88.37452, 37.908431999085138],
            [-88.136053, 37.916022999085079],
            [-88.059469492700785, 37.866689803096278],
            [-88.067364, 37.856050999085561],
            [-88.02803, 37.799223999086017],
            [-88.059588, 37.742607999086488],
            [-88.132341, 37.69714199908686],
            [-88.160062, 37.654331999087212],
            [-88.132163438272485, 37.574516999917883],
            [-88.179647, 37.599092999087674],
            [-88.375332, 37.599562999087667],
            [-88.37453, 37.907677999085145]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "233",
        COUNTYNS: "00343585",
        AFFGEOID: "0500000US13233",
        GEOID: "13233",
        NAME: "Polk",
        LSAD: "06",
        ALAND: 803751977,
        AWATER: 4688357
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.421073175228798, 34.080812651731115],
            [-85.25807, 34.079132999123935],
            [-85.257624, 34.100667999123658],
            [-85.046871, 34.096411999123724],
            [-85.047046, 34.082880999123887],
            [-84.922742, 34.082496999123883],
            [-84.978683, 33.951392999125488],
            [-85.049833, 33.952634999125479],
            [-85.05031, 33.904487999126083],
            [-85.38667057377279, 33.901701493129416],
            [-85.398871, 33.964127999125331],
            [-85.398871244842013, 33.964129285904846],
            [-85.421073175228798, 34.080812651731115]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "107",
        COUNTYNS: "00485018",
        AFFGEOID: "0500000US20107",
        GEOID: "20107",
        NAME: "Linn",
        LSAD: "06",
        ALAND: 1538581904,
        AWATER: 31974029
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.065831, 38.389944999081408],
            [-94.612772628992886, 38.388718481813214],
            [-94.612614, 38.237765999082555],
            [-94.613929961055703, 38.060052958980442],
            [-94.614100248705398, 38.037056582465425],
            [-95.077876, 38.03770599908411],
            [-95.065831, 38.389944999081408]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "188",
        COUNTYNS: "01419979",
        AFFGEOID: "0500000US02188",
        GEOID: "02188",
        NAME: "Northwest Arctic",
        LSAD: "04",
        ALAND: 92326483031,
        AWATER: 12878258897
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-165.396520296503013, 68.034730518044569],
            [-164.499437, 68.034747999346379],
            [-164.499463, 68.207889999350499],
            [-163.615271, 68.207906999350485],
            [-162.72219, 68.207925999350493],
            [-162.722202, 68.294495999352549],
            [-162.023273, 68.294510999352582],
            [-162.023261, 68.207939999350486],
            [-161.557328, 68.207948999350506],
            [-161.55732, 68.294520999352571],
            [-161.091364, 68.294531999352571],
            [-161.091351, 68.207960999350504],
            [-160.15944, 68.207981999350508],
            [-160.159452, 68.294552999352547],
            [-159.693487, 68.294563999352576],
            [-159.693477, 68.207991999350497],
            [-158.1791, 68.208023999350488],
            [-157.154924, 68.208053999350497],
            [-157.15491, 68.121482999348444],
            [-156.236831, 68.121497999348449],
            [-156.236817, 68.034925999346399],
            [-155.318748, 68.034941999346401],
            [-155.318744, 68.000031999345552],
            [-155.318723, 67.861792999342285],
            [-155.368999, 67.775215999340261],
            [-155.142837, 67.775220999340263],
            [-155.142825, 67.688642999338228],
            [-154.916661, 67.68864799933823],
            [-154.916649, 67.602069999336194],
            [-154.690489, 67.60207499933621],
            [-154.690477, 67.515495999334178],
            [-154.748904, 67.255752999328152],
            [-154.30311, 67.255758999328165],
            [-154.303097, 67.169176999326154],
            [-154.146314, 67.169177999326166],
            [-154.146221, 66.82284299931824],
            [-154.210317, 66.822841999318229],
            [-154.210302, 66.736254999316273],
            [-154.860446, 66.736241999316277],
            [-154.860431, 66.563063999312362],
            [-155.510585, 66.563050999312367],
            [-155.510581, 66.47645899931041],
            [-155.554993, 66.303273999306555],
            [-155.982499, 66.303267999306556],
            [-155.982504, 66.389859999308484],
            [-156.196257, 66.389856999308478],
            [-156.196224, 66.476581999310426],
            [-156.62377, 66.47644199931041],
            [-156.623757, 66.303258999306536],
            [-157.051266, 66.303253999306548],
            [-157.051284, 66.47643799931042],
            [-157.90631, 66.476423999310413],
            [-157.906295, 66.130048999302687],
            [-158.972185, 66.130026999302686],
            [-158.972175, 65.95684899929887],
            [-159.604813, 65.956829999298876],
            [-159.593506, 65.52383499928942],
            [-159.8017, 65.523825999289414],
            [-159.801692, 65.437230999287564],
            [-160.976372, 65.437194999287556],
            [-162.331036, 65.43715799928755],
            [-163.755391, 65.437164999287546],
            [-163.755407, 65.610366999291315],
            [-163.963498, 65.610362999291297],
            [-163.963516, 65.783560999295062],
            [-164.244127, 65.783552999295068],
            [-164.244191, 66.12994599930272],
            [-164.318913, 66.129943999302682],
            [-164.318936, 66.476318999310422],
            [-164.395887, 66.476323999310409],
            [-164.395943591522013, 66.581197638227877],
            [-163.824166, 66.591679999313001],
            [-163.603956, 66.558088999312261],
            [-163.728308, 66.498551999310919],
            [-163.798687, 66.436874999309524],
            [-163.873106, 66.389014999308458],
            [-163.849163, 66.307638999306647],
            [-163.843108, 66.259868999305567],
            [-163.925152, 66.225077999304816],
            [-163.916551, 66.190493999304039],
            [-163.80358, 66.100058999302036],
            [-163.695394, 66.059551999301135],
            [-163.495845, 66.085387999301702],
            [-163.372072, 66.085028999301699],
            [-163.146726, 66.059486999301129],
            [-162.997473, 66.076844999301514],
            [-162.750705, 66.090159999301804],
            [-162.622284, 66.039525999300693],
            [-162.423726, 66.048983999300901],
            [-162.331284, 66.031402999300525],
            [-162.137424, 66.078546999301565],
            [-161.838018, 66.022581999300314],
            [-161.775537, 66.073731999301444],
            [-161.613943, 66.176692999303739],
            [-161.548429, 66.23991199930515],
            [-161.484539, 66.262425999305634],
            [-161.341189, 66.255099999305486],
            [-161.320778, 66.223590999304776],
            [-161.198971, 66.210948999304506],
            [-160.993965, 66.234443999305],
            [-161.089161, 66.315139999306822],
            [-161.322126, 66.368553999308006],
            [-161.575413, 66.396805999308626],
            [-161.694404, 66.39617399930863],
            [-161.916309, 66.349480999307588],
            [-161.86369, 66.45948699931003],
            [-161.87488, 66.511445999311192],
            [-162.105641, 66.622583999313719],
            [-162.175398, 66.687788999315188],
            [-162.349774, 66.726712999316064],
            [-162.501415, 66.742502999316415],
            [-162.626696, 66.859571999319073],
            [-162.582856, 66.904291999320094],
            [-162.466702, 66.950997999321146],
            [-162.468441, 66.980603999321843],
            [-162.63547, 66.998433999322259],
            [-162.842979, 66.991176999322064],
            [-163.011676, 67.029537999322969],
            [-163.299266, 67.060747999323681],
            [-163.591216, 67.092372999324382],
            [-163.702045, 67.1093749993248],
            [-163.74082, 67.209959999327111],
            [-163.878781, 67.416124999331871],
            [-164.051288, 67.56635099933537],
            [-164.256634, 67.651650999337363],
            [-164.533937, 67.725605999339095],
            [-165.094228, 67.931962999343952],
            [-165.35005, 68.025859999346167],
            [-165.396520296503013, 68.034730518044569]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "009",
        COUNTYNS: "00025444",
        AFFGEOID: "0500000US04009",
        GEOID: "04009",
        NAME: "Graham",
        LSAD: "06",
        ALAND: 11972778533,
        AWATER: 47436491
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.441544, 33.381669999132697],
            [-110.340949, 33.427065999132111],
            [-110.168992, 33.451697999131795],
            [-110.000646, 33.465915999131624],
            [-110.000649, 33.576929999130193],
            [-109.903421, 33.575115999130226],
            [-109.891759, 33.567828999130306],
            [-109.80327, 33.481726999131418],
            [-109.49544, 33.652696999129233],
            [-109.49574, 33.078408999136677],
            [-109.333425, 32.843265999139838],
            [-109.245005, 32.741984999141195],
            [-109.206249, 32.635802999142662],
            [-109.22607, 32.529095999144126],
            [-109.113987, 32.426176999145568],
            [-110.170441, 32.426377999145558],
            [-110.451503, 32.427353999145538],
            [-110.451218, 32.513994999144344],
            [-110.449095, 32.536546999144022],
            [-110.449021, 33.194525999135145],
            [-110.400676, 33.198813999135091],
            [-110.457194, 33.295267999133827],
            [-110.441544, 33.381669999132697]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "029",
        COUNTYNS: "02054176",
        AFFGEOID: "0500000US06029",
        GEOID: "06029",
        NAME: "Kern",
        LSAD: "06",
        ALAND: 21062393794,
        AWATER: 78770534
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.194146, 35.789203999104622],
            [-119.538116, 35.789566999104622],
            [-119.053437, 35.79074799910461],
            [-118.803729, 35.790346999104614],
            [-118.067719, 35.791536999104601],
            [-118.008043, 35.789160999104638],
            [-117.924459, 35.798148999104527],
            [-117.632996, 35.797250999104534],
            [-117.651986, 35.709933999105452],
            [-117.616195, 35.680855999105752],
            [-117.630126, 35.564070999106981],
            [-117.632011, 34.82226999911515],
            [-117.667292, 34.822525999115143],
            [-117.774368, 34.823300999115133],
            [-118.326281, 34.819725999115178],
            [-118.894474, 34.817971999115201],
            [-118.881364, 34.790628999115505],
            [-118.976721, 34.812198999115274],
            [-119.243645, 34.814177999115245],
            [-119.276946, 34.879674999114506],
            [-119.442352, 34.901273999114252],
            [-119.472754, 34.901173999114256],
            [-119.472719, 35.076884999112281],
            [-119.560975, 35.087672999112158],
            [-119.553641, 35.179974999111145],
            [-119.667056, 35.174808999111193],
            [-119.666663, 35.262526999110236],
            [-119.809449, 35.263583999110217],
            [-119.809346, 35.350864999109277],
            [-119.880172, 35.35121099910927],
            [-119.880045, 35.439132999108317],
            [-119.913659, 35.439261999108318],
            [-120.086674, 35.526553999107378],
            [-120.085922, 35.614523999106446],
            [-120.193918, 35.614358999106457],
            [-120.194146, 35.789203999104622]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "055",
        COUNTYNS: "00198143",
        AFFGEOID: "0500000US08055",
        GEOID: "08055",
        NAME: "Huerfano",
        LSAD: "06",
        ALAND: 4120756337,
        AWATER: 5792101
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.501755, 37.597570999087679],
            [-105.457255, 37.751463999086411],
            [-105.428893, 37.851732999085591],
            [-105.473204, 37.895969999085231],
            [-105.317081, 37.938948999084893],
            [-105.285076, 37.899420999085201],
            [-105.168652, 38.018945999084259],
            [-105.049917, 37.915478999085082],
            [-105.013729, 37.881270999085352],
            [-104.646383, 37.900526999085201],
            [-104.351109, 37.81748799908587],
            [-104.45493, 37.740838999086499],
            [-104.548417, 37.609423999087589],
            [-104.548652, 37.574699999087883],
            [-104.649229, 37.495319999088544],
            [-104.696111, 37.485424999088636],
            [-104.695075, 37.439914999089019],
            [-104.748938, 37.407028999089306],
            [-104.995505, 37.375550999089569],
            [-105.115425, 37.296293999090246],
            [-105.154176, 37.293128999090285],
            [-105.187549, 37.619668999087502],
            [-105.295587, 37.654415999087213],
            [-105.485516, 37.57789899908785],
            [-105.501755, 37.597570999087679]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "069",
        COUNTYNS: "00308551",
        AFFGEOID: "0500000US12069",
        GEOID: "12069",
        NAME: "Lake",
        LSAD: "06",
        ALAND: 2436872973,
        AWATER: 559601970
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.954247, 28.551689999207284],
            [-81.95419, 28.960052999200069],
            [-81.761654, 28.96093199920006],
            [-81.658698, 28.960344999200071],
            [-81.640925, 29.168808999196447],
            [-81.641916, 29.276765999194591],
            [-81.61234, 29.202942999195866],
            [-81.531262, 29.17662099919632],
            [-81.502055, 29.097801999197674],
            [-81.354682, 28.984806999199641],
            [-81.36694, 28.879226999201489],
            [-81.416846, 28.824568999202459],
            [-81.414446, 28.784954999203148],
            [-81.646513, 28.78587199920312],
            [-81.658597, 28.766869999203468],
            [-81.657281, 28.546293999207386],
            [-81.657268, 28.347097999210959],
            [-81.857624, 28.361867999210695],
            [-81.957641, 28.345179999210998],
            [-81.954247, 28.551689999207284]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "051",
        COUNTYNS: "00307626",
        AFFGEOID: "0500000US12051",
        GEOID: "12051",
        NAME: "Hendry",
        LSAD: "06",
        ALAND: 2994620710,
        AWATER: 87035817
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.565931, 26.769551999240633],
            [-80.945354, 26.769048999240646],
            [-80.885639, 26.95891899923695],
            [-80.881233, 26.33380599924924],
            [-80.879809, 26.259454999250728],
            [-81.26855, 26.253044999250857],
            [-81.271768, 26.517068999245595],
            [-81.563763, 26.513323999245671],
            [-81.565095, 26.600332999243953],
            [-81.565931, 26.769551999240633]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "043",
        COUNTYNS: "00342852",
        AFFGEOID: "0500000US13043",
        GEOID: "13043",
        NAME: "Candler",
        LSAD: "06",
        ALAND: 629508737,
        AWATER: 15030369
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.254526, 32.351497999146602],
            [-82.148338, 32.520428999144251],
            [-82.079842, 32.560062999143703],
            [-82.030233, 32.538769999144002],
            [-81.918652, 32.415072999145714],
            [-81.969069, 32.268782999147767],
            [-82.025339, 32.278882999147626],
            [-82.232788, 32.318837999147064],
            [-82.254526, 32.351497999146602]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "029",
        COUNTYNS: "00424216",
        AFFGEOID: "0500000US17029",
        GEOID: "17029",
        NAME: "Coles",
        LSAD: "06",
        ALAND: 1316459192,
        AWATER: 4631825
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.472073, 39.651587999072866],
            [-88.063437, 39.652554999072862],
            [-87.9666, 39.685927999072661],
            [-87.960179, 39.48130899907391],
            [-88.014205, 39.48076199907392],
            [-88.012121, 39.378967999074561],
            [-88.47083, 39.374514999074592],
            [-88.470505, 39.447040999074133],
            [-88.472073, 39.651587999072866]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "093",
        COUNTYNS: "00424248",
        AFFGEOID: "0500000US17093",
        GEOID: "17093",
        NAME: "Kendall",
        LSAD: "06",
        ALAND: 829446935,
        AWATER: 5141326
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.601933, 41.719562999062767],
            [-88.4045, 41.722269999062753],
            [-88.37532, 41.722609999062733],
            [-88.261952, 41.72465199906275],
            [-88.261273, 41.724533999062736],
            [-88.26081, 41.69584299906284],
            [-88.257274, 41.593468999063241],
            [-88.25429, 41.520720999063506],
            [-88.252231, 41.463065999063758],
            [-88.59596, 41.457033999063775],
            [-88.60224, 41.631388999063105],
            [-88.601933, 41.719562999062767]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "101",
        COUNTYNS: "01026327",
        AFFGEOID: "0500000US37101",
        GEOID: "37101",
        NAME: "Johnston",
        LSAD: "06",
        ALAND: 2049282622,
        AWATER: 11562555
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.642702, 35.570064999106918],
            [-78.468503, 35.70707199910548],
            [-78.255973, 35.818119999104326],
            [-78.23251, 35.751255999105013],
            [-78.192117, 35.730544999105241],
            [-78.161255, 35.712870999105427],
            [-78.115641, 35.600432999106594],
            [-78.064784, 35.585262999106753],
            [-78.15491, 35.417809999108556],
            [-78.153243, 35.350670999109269],
            [-78.30658, 35.287603999109962],
            [-78.411734, 35.254662999110316],
            [-78.492042, 35.263420999110224],
            [-78.538349, 35.316409999109638],
            [-78.529851, 35.334934999109443],
            [-78.708345, 35.519295999107456],
            [-78.642702, 35.570064999106918]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "127",
        COUNTYNS: "01008574",
        AFFGEOID: "0500000US37127",
        GEOID: "37127",
        NAME: "Nash",
        LSAD: "06",
        ALAND: 1399739716,
        AWATER: 6177826
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.255973, 35.818119999104326],
            [-78.006551, 36.20263299910043],
            [-77.8864, 36.143993999101014],
            [-77.720742, 36.133883999101123],
            [-77.697956, 36.153149999100926],
            [-77.706966, 36.115502999101295],
            [-77.828444, 35.867207999103812],
            [-77.844737, 35.837617999104125],
            [-77.915657, 35.83644999910414],
            [-78.175342, 35.73952899910514],
            [-78.192117, 35.730544999105241],
            [-78.23251, 35.751255999105013],
            [-78.255973, 35.818119999104326]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "045",
        COUNTYNS: "01135858",
        AFFGEOID: "0500000US41045",
        GEOID: "41045",
        NAME: "Malheur",
        LSAD: "06",
        ALAND: 25608869178,
        AWATER: 109173277
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-118.232214, 44.256128999057047],
            [-118.152456, 44.299508999057011],
            [-117.971787, 44.443848999056911],
            [-117.590849, 44.444572999056909],
            [-117.486744, 44.387245999056944],
            [-117.487014, 44.300316999057003],
            [-117.211995506953002, 44.296451094995312],
            [-117.216974, 44.288356999057015],
            [-117.170342, 44.25888999905704],
            [-117.121037, 44.277584999057026],
            [-117.059352, 44.23724399905705],
            [-116.971958, 44.235676999057056],
            [-116.965498, 44.194125999057086],
            [-116.902752, 44.179466999057098],
            [-116.895931, 44.154294999057136],
            [-116.897854677114992, 44.152666394448531],
            [-116.977351, 44.085363999057201],
            [-116.937342, 44.029375999057258],
            [-116.959871, 43.982924999057296],
            [-116.976024, 43.895547999057399],
            [-116.985545038489988, 43.881184851871211],
            [-117.023577, 43.8238109990575],
            [-117.025663810446019, 43.680293635011687],
            [-117.026889, 43.596032999057819],
            [-117.026652, 43.025127999058896],
            [-117.026253, 42.807446999059394],
            [-117.026551, 42.378556999060557],
            [-117.026197, 41.999889999061779],
            [-117.197798, 42.000379999061771],
            [-117.403613, 41.999289999061766],
            [-117.623731, 41.998466999061776],
            [-117.873467, 41.998334999061775],
            [-118.197189, 41.996994999061776],
            [-118.197369215928006, 41.996994080225285],
            [-118.195311, 42.267118999060912],
            [-118.214698, 42.275890999060884],
            [-118.216832, 42.914702999059145],
            [-118.2321, 43.770828999057557],
            [-118.227645, 44.039861999057244],
            [-118.228843, 44.212549999057082],
            [-118.232214, 44.256128999057047]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "277",
        COUNTYNS: "01383925",
        AFFGEOID: "0500000US48277",
        GEOID: "48277",
        NAME: "Lamar",
        LSAD: "06",
        ALAND: 2349588917,
        AWATER: 67136455
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.855013, 33.827991999127029],
            [-95.844878534070688, 33.860421903845619],
            [-95.820596, 33.858464999126646],
            [-95.78963931775229, 33.872437383798776],
            [-95.737508, 33.89596699912618],
            [-95.669978, 33.905843999126063],
            [-95.603657, 33.927194999125796],
            [-95.556915, 33.927019999125804],
            [-95.525322, 33.885486999126314],
            [-95.44737, 33.868849999126518],
            [-95.352338, 33.867788999126532],
            [-95.310449795449088, 33.873843037311957],
            [-95.308637, 33.380661999132712],
            [-95.416898, 33.412193999132313],
            [-95.491897, 33.455336999131752],
            [-95.603675, 33.484720999131369],
            [-95.671634, 33.472635999131526],
            [-95.761076, 33.495386999131235],
            [-95.826979, 33.458967999131701],
            [-95.857738, 33.461721999131669],
            [-95.855013, 33.827991999127029]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "087",
        COUNTYNS: "00161569",
        AFFGEOID: "0500000US01087",
        GEOID: "01087",
        NAME: "Macon",
        LSAD: "06",
        ALAND: 1576801788,
        AWATER: 11399310
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.023012, 32.419977999145651],
            [-85.852625, 32.475746999144867],
            [-85.886148, 32.493052999144616],
            [-85.797156, 32.494235999144607],
            [-85.798585, 32.581088999143411],
            [-85.695854, 32.595932999143201],
            [-85.489348, 32.496936999144566],
            [-85.438575, 32.497089999144578],
            [-85.434045, 32.409839999145788],
            [-85.433543, 32.234647999148251],
            [-85.856218, 32.231974999148285],
            [-85.898539, 32.305288999147258],
            [-85.898689, 32.274986999147679],
            [-85.919293, 32.274381999147678],
            [-85.991283, 32.33609799914683],
            [-86.023012, 32.419977999145651]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "047",
        COUNTYNS: "00161549",
        AFFGEOID: "0500000US01047",
        GEOID: "01047",
        NAME: "Dallas",
        LSAD: "06",
        ALAND: 2534863481,
        AWATER: 39131006
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.47308, 32.307613999147229],
            [-87.421744, 32.308100999147229],
            [-87.423153, 32.482964999144777],
            [-87.110817, 32.489947999144675],
            [-87.017762, 32.729531999141379],
            [-87.01766, 32.663268999142275],
            [-86.917595, 32.664168999142269],
            [-86.906403, 32.536711999144032],
            [-86.814912, 32.34080299914676],
            [-86.850412, 32.328946999146922],
            [-86.864367, 32.274917999147682],
            [-86.810313, 32.224746999148401],
            [-86.908302, 32.225027999148388],
            [-86.906956, 32.047969999150915],
            [-87.17786, 32.047513999150929],
            [-87.324783, 32.199068999148764],
            [-87.472206, 32.264918999147824],
            [-87.47308, 32.307613999147229]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "077",
        COUNTYNS: "00485004",
        AFFGEOID: "0500000US20077",
        GEOID: "20077",
        NAME: "Harper",
        LSAD: "06",
        ALAND: 2075276152,
        AWATER: 3913474
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.349804, 37.384055999089504],
            [-97.807057, 37.386292999089484],
            [-97.804337, 37.366068999089656],
            [-97.8023129703503, 36.998698608486897],
            [-98.045342, 36.998326999092889],
            [-98.111985183745787, 36.998247993728206],
            [-98.347148779744685, 36.9979692077425],
            [-98.349804, 37.384055999089504]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "043",
        COUNTYNS: "00295743",
        AFFGEOID: "0500000US12043",
        GEOID: "12043",
        NAME: "Glades",
        LSAD: "06",
        ALAND: 2089270942,
        AWATER: 466758592
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.562202, 27.033835999235503],
            [-81.267152, 27.032460999235528],
            [-81.267677, 27.121096999233824],
            [-81.168281, 27.121733999233808],
            [-81.169138, 27.209584999232117],
            [-80.943186, 27.210598999232104],
            [-80.878669, 27.169220999232905],
            [-80.885639, 26.95891899923695],
            [-80.945354, 26.769048999240646],
            [-81.565931, 26.769551999240633],
            [-81.562202, 27.033835999235503]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "09",
        COUNTYFP: "015",
        COUNTYNS: "00212801",
        AFFGEOID: "0500000US09015",
        GEOID: "09015",
        NAME: "Windham",
        LSAD: "06",
        ALAND: 1328250338,
        AWATER: 21706060
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.239559, 41.714190999062779],
            [-72.156719, 41.753718999062635],
            [-72.164498, 41.83491299906234],
            [-72.21698, 41.958160999061903],
            [-72.151654, 41.959301999061921],
            [-72.099229, 41.958947999061913],
            [-72.102159434526797, 42.028630239116474],
            [-71.987326, 42.02687999906167],
            [-71.884676835903704, 42.025059350545988],
            [-71.80065, 42.023568999061695],
            [-71.799242, 42.008064999061745],
            [-71.792767, 41.807000999062446],
            [-71.789695431682389, 41.725198243871844],
            [-71.789678, 41.724733999062742],
            [-71.789464590839003, 41.640017189769949],
            [-71.857917, 41.644226999063044],
            [-71.954983, 41.634798999063086],
            [-72.062051, 41.652238999063016],
            [-72.146717, 41.667168999062959],
            [-72.157546, 41.657626999062991],
            [-72.239559, 41.714190999062779]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "309",
        COUNTYNS: "00351278",
        AFFGEOID: "0500000US13309",
        GEOID: "13309",
        NAME: "Wheeler",
        LSAD: "06",
        ALAND: 765314385,
        AWATER: 12377029
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.927865, 32.135273999149653],
            [-82.874864, 32.180023999149022],
            [-82.884803, 32.196071999148799],
            [-82.773916, 32.265361999147821],
            [-82.755158, 32.269689999147758],
            [-82.721964, 32.309282999147207],
            [-82.67377, 32.293594999147423],
            [-82.655486, 32.297560999147365],
            [-82.644309, 32.29419299914742],
            [-82.597671, 32.013978999151405],
            [-82.543655, 31.958913999152191],
            [-82.594757, 31.931584999152584],
            [-82.645659, 31.918882999152764],
            [-82.720733, 32.000735999151594],
            [-82.887657, 32.07989099915045],
            [-82.927865, 32.135273999149653]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "043",
        COUNTYNS: "01720045",
        AFFGEOID: "0500000US30043",
        GEOID: "30043",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 4291254207,
        AWATER: 6605135
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.598092, 46.236546999057708],
            [-112.558599, 46.265820999057752],
            [-112.570046, 46.30497199905782],
            [-112.449521, 46.33409799905786],
            [-112.310573, 46.421170999058013],
            [-112.176235, 46.456341999058068],
            [-112.205757, 46.49974799905813],
            [-112.083441, 46.516662999058177],
            [-112.029231, 46.568804999058258],
            [-111.787949, 46.569464999058269],
            [-111.784565, 46.049812999057451],
            [-111.660422, 46.049191999057463],
            [-111.660057, 45.833499999057203],
            [-111.705467, 45.795737999057181],
            [-111.805259, 45.79830599905717],
            [-111.926965, 45.855693999057237],
            [-112.085509, 45.851038999057231],
            [-112.190269, 45.748502999057123],
            [-112.401441, 45.83185099905721],
            [-112.404349, 45.888048999057254],
            [-112.519997, 46.136393999057574],
            [-112.505377, 46.184316999057643],
            [-112.577106, 46.178980999057636],
            [-112.598092, 46.236546999057708]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "033",
        COUNTYNS: "01702367",
        AFFGEOID: "0500000US35033",
        GEOID: "35033",
        NAME: "Mora",
        LSAD: "06",
        ALAND: 4992675873,
        AWATER: 6093304
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.718611, 35.979462999102672],
            [-105.568045, 35.973795999102734],
            [-105.53038, 36.013013999102341],
            [-105.419992, 36.131703999101134],
            [-105.409677, 36.2368139991001],
            [-105.341264, 36.261862999099847],
            [-104.865244, 36.261418999099853],
            [-104.865402, 36.217868999100283],
            [-104.436056, 36.218533999100273],
            [-104.414546, 36.123760999101222],
            [-104.327284, 35.96019699910287],
            [-104.389736, 35.857603999103915],
            [-104.37012, 35.779301999104732],
            [-104.750645, 35.783126999104688],
            [-105.018811, 35.769438999104821],
            [-105.025454, 35.754196999104991],
            [-105.110049, 35.807903999104433],
            [-105.34783, 35.87069499910379],
            [-105.724461, 35.871193999103774],
            [-105.718611, 35.979462999102672]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "081",
        COUNTYNS: "00450365",
        AFFGEOID: "0500000US18081",
        GEOID: "18081",
        NAME: "Johnson",
        LSAD: "06",
        ALAND: 829909465,
        AWATER: 3525572
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.249885, 39.633644999072978],
            [-85.95208, 39.638344999072949],
            [-85.952005, 39.347372999074771],
            [-86.085601, 39.344188999074788],
            [-86.251684, 39.341671999074805],
            [-86.249885, 39.633644999072978]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "165",
        COUNTYNS: "00465271",
        AFFGEOID: "0500000US19165",
        GEOID: "19165",
        NAME: "Shelby",
        LSAD: "06",
        ALAND: 1530110554,
        AWATER: 1486125
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.557277, 41.863448999062236],
            [-95.092861, 41.863373999062247],
            [-95.09252, 41.602054999063213],
            [-95.040748, 41.602151999063203],
            [-95.04077, 41.504689999063579],
            [-95.154722, 41.505210999063578],
            [-95.497997, 41.506073999063567],
            [-95.497793, 41.602332999063208],
            [-95.558143, 41.602382999063202],
            [-95.557277, 41.863448999062236]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "115",
        COUNTYNS: "00485022",
        AFFGEOID: "0500000US20115",
        GEOID: "20115",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 2445701362,
        AWATER: 24448710
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.371911, 38.609352999079803],
            [-96.930286, 38.609361999079795],
            [-96.929969, 38.522411999080418],
            [-96.819723, 38.522463999080429],
            [-96.837654, 38.348635999081722],
            [-96.840772, 38.085621999083735],
            [-97.152913, 38.087703999083722],
            [-97.153093, 38.174633999083049],
            [-97.37175, 38.173672999083053],
            [-97.371911, 38.609352999079803]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "025",
        COUNTYNS: "00516859",
        AFFGEOID: "0500000US21025",
        GEOID: "21025",
        NAME: "Breathitt",
        LSAD: "06",
        ALAND: 1275346433,
        AWATER: 7390878
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.579186, 37.506248999088456],
            [-83.522308, 37.638515999087339],
            [-83.495488, 37.619594999087496],
            [-83.418474, 37.690728999086907],
            [-83.248692, 37.669615999087085],
            [-83.134949, 37.606811999087611],
            [-83.089796, 37.632166999087396],
            [-83.01217, 37.583473999087808],
            [-82.948544, 37.503166999088478],
            [-83.089007, 37.522647999088313],
            [-83.124348, 37.404972999089317],
            [-83.214735, 37.443537999088981],
            [-83.38165, 37.338265999089892],
            [-83.449454, 37.378852999089538],
            [-83.547628, 37.334417999089929],
            [-83.534281, 37.417408999089211],
            [-83.579186, 37.506248999088456]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "073",
        COUNTYNS: "00558114",
        AFFGEOID: "0500000US22073",
        GEOID: "22073",
        NAME: "Ouachita",
        LSAD: "15",
        ALAND: 1580943094,
        AWATER: 55283099
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.41535, 32.495485999144599],
            [-92.415071, 32.582844999143383],
            [-92.223849, 32.590365999143273],
            [-92.065179, 32.723002999141457],
            [-91.911168, 32.66113899914231],
            [-91.943419, 32.587118999143328],
            [-91.913057, 32.505901999144449],
            [-91.994208, 32.401093999145914],
            [-92.034397, 32.277067999147654],
            [-92.106115, 32.258551999147919],
            [-92.311851, 32.277440999147643],
            [-92.311829, 32.320754999147042],
            [-92.415418, 32.407811999145814],
            [-92.41535, 32.495485999144599]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "019",
        COUNTYNS: "00581295",
        AFFGEOID: "0500000US23019",
        GEOID: "23019",
        NAME: "Penobscot",
        LSAD: "06",
        ALAND: 8798662789,
        AWATER: 414019982
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.355667, 45.073468999056765],
            [-69.232021, 45.090165999056772],
            [-68.9735, 45.12721599905678],
            [-68.856587, 45.142782999056784],
            [-68.880923, 45.224946999056797],
            [-68.776061, 45.240316999056809],
            [-68.857297, 45.527300999056955],
            [-68.964653, 45.512366999056951],
            [-68.95891, 45.662194999057057],
            [-68.827039, 45.684825999057082],
            [-68.819401, 46.395781999057959],
            [-68.706297, 46.395493999057962],
            [-68.436443, 46.382120999057932],
            [-68.436902, 46.042889999057444],
            [-68.427598, 45.944590999057318],
            [-68.433001, 45.581203999056989],
            [-68.410884, 45.573336999056984],
            [-68.260136, 45.604482999057005],
            [-68.048012, 45.639896999057029],
            [-67.93976, 45.267844999056827],
            [-68.054985, 45.252576999056814],
            [-68.303714, 45.22153099905681],
            [-68.270497, 45.100120999056777],
            [-68.396903, 45.083295999056773],
            [-68.375892, 44.997372999056772],
            [-68.501095, 44.980717999056758],
            [-68.477952, 44.889090999056762],
            [-68.454889, 44.801549999056775],
            [-68.445219, 44.763013999056781],
            [-68.616673, 44.740991999056789],
            [-68.647134, 44.703635999056793],
            [-68.69093, 44.721667999056798],
            [-68.743953, 44.66057899905681],
            [-68.815793, 44.683753999056812],
            [-68.837529, 44.702124999056792],
            [-69.014167, 44.671793999056817],
            [-69.183732, 44.644022999056823],
            [-69.2052, 44.729585999056795],
            [-69.266785, 44.721927999056788],
            [-69.282052, 44.789112999056776],
            [-69.281275, 44.808656999056772],
            [-69.305521, 44.895987999056764],
            [-69.355667, 45.073468999056765]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "115",
        COUNTYNS: "00695803",
        AFFGEOID: "0500000US28115",
        GEOID: "28115",
        NAME: "Pontotoc",
        LSAD: "06",
        ALAND: 1289142874,
        AWATER: 8496511
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.246339, 34.379343999120309],
            [-88.92887, 34.379239999120301],
            [-88.824966, 34.364758999120482],
            [-88.824523, 34.074947999123985],
            [-89.139158, 34.074115999123983],
            [-89.244436, 34.073941999123988],
            [-89.245485, 34.161185999122928],
            [-89.246339, 34.379343999120309]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "117",
        COUNTYNS: "00695779",
        AFFGEOID: "0500000US28117",
        GEOID: "28117",
        NAME: "Prentiss",
        LSAD: "06",
        ALAND: 1074778137,
        AWATER: 8353594
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.73544, 34.596482999117754],
            [-88.717853, 34.602614999117684],
            [-88.718508, 34.756780999115897],
            [-88.365472, 34.755603999115912],
            [-88.330699, 34.740720999116085],
            [-88.329193, 34.463570999119327],
            [-88.542079, 34.465275999119285],
            [-88.541749, 34.509191999118784],
            [-88.736465, 34.508909999118778],
            [-88.73544, 34.596482999117754]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "115",
        COUNTYNS: "00835879",
        AFFGEOID: "0500000US31115",
        GEOID: "31115",
        NAME: "Loup",
        LSAD: "06",
        ALAND: 1459513672,
        AWATER: 19565477
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.686959, 42.086074999061481],
            [-99.662379, 42.086008999061477],
            [-99.234629, 42.087994999061479],
            [-99.222733, 42.087915999061465],
            [-99.22183, 41.740791999062679],
            [-99.686834, 41.740325999062684],
            [-99.686959, 42.086074999061481]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "077",
        COUNTYNS: "00974137",
        AFFGEOID: "0500000US36077",
        GEOID: "36077",
        NAME: "Otsego",
        LSAD: "06",
        ALAND: 2594462131,
        AWATER: 36339122
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.374905, 42.41078399906047],
            [-75.403626, 42.509062999060184],
            [-75.330143, 42.568081999060034],
            [-75.295877, 42.744105999059549],
            [-75.251416, 42.784564999059462],
            [-75.247963, 42.871603999059246],
            [-75.212158, 42.879972999059227],
            [-75.210642, 42.856548999059292],
            [-75.100999, 42.908362999059165],
            [-74.906738, 42.82494299905936],
            [-74.878822, 42.898273999059185],
            [-74.763303, 42.863236999059261],
            [-74.648298, 42.829557999059347],
            [-74.667512, 42.750709999059538],
            [-74.630631, 42.626673999059875],
            [-74.71158, 42.517798999060169],
            [-74.844121, 42.512590999060187],
            [-75.197237, 42.358328999060625],
            [-75.415319, 42.314150999060764],
            [-75.374905, 42.41078399906047]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "101",
        COUNTYNS: "01074063",
        AFFGEOID: "0500000US39101",
        GEOID: "39101",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 1045726876,
        AWATER: 908577
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.419836, 40.686822999067203],
            [-83.310556, 40.687108999067199],
            [-83.304687, 40.702005999067119],
            [-83.11136, 40.702914999067119],
            [-82.858302, 40.705018999067114],
            [-82.859753, 40.64639499906739],
            [-82.957715, 40.635652999067446],
            [-82.958401, 40.490663999068182],
            [-83.020798, 40.433794999068468],
            [-83.248595, 40.443988999068409],
            [-83.248298, 40.500996999068114],
            [-83.267767, 40.506905999068096],
            [-83.418378, 40.505231999068101],
            [-83.415977, 40.525974999068005],
            [-83.419836, 40.686822999067203]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "105",
        COUNTYNS: "01035306",
        AFFGEOID: "0500000US38105",
        GEOID: "38105",
        NAME: "Williams",
        LSAD: "06",
        ALAND: 5381129960,
        AWATER: 182522236
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.048087568832003, 48.634012372463147],
            [-102.885775, 48.633297999064531],
            [-102.885763, 48.546389999064175],
            [-102.885737, 48.372577999063473],
            [-102.828061, 48.372919999063477],
            [-102.828477, 48.123663999062529],
            [-103.107083, 48.146493999062614],
            [-103.20189, 48.13520799906258],
            [-103.225965, 48.075122999062366],
            [-103.288366, 48.040122999062241],
            [-103.42837, 48.035923999062213],
            [-103.485072, 48.006724999062122],
            [-103.584774, 48.054123999062284],
            [-103.548572, 48.087723999062419],
            [-103.597614, 48.134391999062565],
            [-103.742216, 48.09340299906242],
            [-103.709454, 48.065461999062322],
            [-103.784394, 48.059490999062305],
            [-103.800817, 47.994268999062079],
            [-103.856306, 48.015071999062137],
            [-103.919789, 47.955949999061936],
            [-104.044093215348013, 47.996098354626774],
            [-104.044093272821016, 47.996107173249186],
            [-104.045692, 48.241414999062968],
            [-104.046782161706005, 48.389300730209044],
            [-104.047555, 48.494139999063968],
            [-104.048087568832003, 48.634012372463147]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "107",
        COUNTYNS: "01213685",
        AFFGEOID: "0500000US42107",
        GEOID: "42107",
        NAME: "Schuylkill",
        LSAD: "06",
        ALAND: 2016645900,
        AWATER: 10834897
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.687266, 40.665600999067301],
            [-76.380152, 40.775510999066768],
            [-76.30717, 40.801808999066644],
            [-76.284611, 40.883587999066251],
            [-76.207827, 40.949739999065962],
            [-76.02815, 40.901985999066177],
            [-75.997348, 40.912984999066126],
            [-75.926429, 40.85172599906641],
            [-75.886037, 40.816266999066563],
            [-75.757807, 40.735413999066964],
            [-75.807789, 40.707491999067102],
            [-75.891473, 40.677269999067249],
            [-75.993212, 40.639711999067423],
            [-76.01708, 40.574454999067761],
            [-76.171624, 40.534946999067955],
            [-76.440181, 40.495951999068147],
            [-76.535414, 40.555156999067862],
            [-76.701624, 40.658081999067342],
            [-76.687266, 40.665600999067301]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "045",
        COUNTYNS: "01209177",
        AFFGEOID: "0500000US42045",
        GEOID: "42045",
        NAME: "Delaware",
        LSAD: "06",
        ALAND: 476088135,
        AWATER: 17572422
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.597596, 39.874160999071542],
            [-75.562539, 39.90163799907139],
            [-75.361066, 40.065542999070452],
            [-75.349457, 40.052904999070527],
            [-75.311432, 40.019809999070709],
            [-75.276482, 39.976958999070952],
            [-75.280266, 39.974963999070958],
            [-75.265448, 39.965256999071023],
            [-75.248056, 39.964073999071033],
            [-75.251496, 39.949250999071104],
            [-75.235161, 39.938270999071179],
            [-75.242186, 39.933425999071204],
            [-75.247068, 39.913777999071314],
            [-75.264433, 39.882166999071494],
            [-75.258805818047691, 39.85467389985606],
            [-75.293376, 39.84878199907169],
            [-75.341765, 39.846081999071707],
            [-75.351648214068902, 39.84012715258514],
            [-75.390315623251894, 39.816829216902377],
            [-75.410492477637092, 39.804672233394044],
            [-75.415062, 39.801918999071958],
            [-75.481207, 39.829190999071805],
            [-75.570433, 39.839184999071747],
            [-75.594316905220083, 39.834594972163075],
            [-75.597596, 39.874160999071542]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "033",
        COUNTYNS: "01648574",
        AFFGEOID: "0500000US47033",
        GEOID: "47033",
        NAME: "Crockett",
        LSAD: "06",
        ALAND: 687766537,
        AWATER: 494100
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.339567, 35.862267999103878],
            [-89.342829, 35.880933999103668],
            [-89.280188, 35.886415999103619],
            [-89.188844, 35.999759999102466],
            [-89.135958, 35.967840999102791],
            [-89.109285, 35.862088999103868],
            [-88.916181, 35.797306999104549],
            [-89.021364, 35.67095299910585],
            [-89.069544, 35.692678999105624],
            [-89.338704, 35.789272999104618],
            [-89.356033, 35.817634999104328],
            [-89.339567, 35.862267999103878]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "175",
        COUNTYNS: "01648584",
        AFFGEOID: "0500000US47175",
        GEOID: "47175",
        NAME: "Van Buren",
        LSAD: "06",
        ALAND: 708142420,
        AWATER: 2893455
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.602876, 35.794995999104557],
            [-85.598389, 35.806303999104443],
            [-85.543209, 35.797324999104546],
            [-85.535043, 35.799351999104516],
            [-85.519775, 35.792076999104601],
            [-85.526581, 35.807282999104437],
            [-85.517326, 35.815814999104347],
            [-85.499398, 35.806514999104436],
            [-85.49499, 35.813034999104381],
            [-85.474213, 35.822655999104278],
            [-85.464154, 35.81516099910435],
            [-85.462996, 35.822208999104291],
            [-85.44936, 35.821558999104298],
            [-85.439527, 35.815865999104346],
            [-85.423179, 35.814690999104357],
            [-85.265064, 35.821972999104283],
            [-85.269359, 35.793387999104588],
            [-85.254062, 35.765610999104865],
            [-85.265387, 35.721236999105329],
            [-85.423804, 35.567421999106948],
            [-85.459856, 35.546849999107174],
            [-85.557535, 35.532976999107312],
            [-85.594632, 35.617897999106411],
            [-85.615164, 35.761063999104913],
            [-85.602876, 35.794995999104557]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "095",
        COUNTYNS: "01383833",
        AFFGEOID: "0500000US48095",
        GEOID: "48095",
        NAME: "Concho",
        LSAD: "06",
        ALAND: 2547913999,
        AWATER: 25740027
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.111234, 31.580266999157736],
            [-99.721698, 31.576758999157793],
            [-99.62492, 31.467441999159423],
            [-99.60185, 31.491949999159061],
            [-99.603223, 31.087295999165182],
            [-100.115216, 31.087993999165167],
            [-100.111234, 31.580266999157736]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "355",
        COUNTYNS: "01383963",
        AFFGEOID: "0500000US48355",
        GEOID: "48355",
        NAME: "Nueces",
        LSAD: "06",
        ALAND: 2171223720,
        AWATER: 847262030
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.934274, 27.885201999219408],
            [-97.798517, 27.995658999217369],
            [-97.806798, 27.934711999218489],
            [-97.691074, 27.91735299921881],
            [-97.67528, 27.882537999219458],
            [-97.638174, 27.865593999219765],
            [-97.609593, 27.89037699921931],
            [-97.589473, 27.856100999219954],
            [-97.542622, 27.8583149992199],
            [-97.485519, 27.862796999219817],
            [-97.337677, 27.883164999219442],
            [-97.359997, 27.850765999220044],
            [-97.250796, 27.876717999219565],
            [-97.188659, 27.823884999220539],
            [-97.165617, 27.838212999220278],
            [-97.186987, 27.825488999220511],
            [-97.136312, 27.904389999219056],
            [-97.095713, 27.957834999218065],
            [-97.0436840836865, 27.83653235269211],
            [-97.044846, 27.834465999220338],
            [-97.090735, 27.785887999221249],
            [-97.140854, 27.71668799922254],
            [-97.212679, 27.596419999224786],
            [-97.222991407531794, 27.576607315704766],
            [-97.325216, 27.560895999225451],
            [-97.840678, 27.558357999225503],
            [-97.942146, 27.635931999224045],
            [-97.934274, 27.885201999219408]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "131",
        COUNTYNS: "01209192",
        AFFGEOID: "0500000US42131",
        GEOID: "42131",
        NAME: "Wyoming",
        LSAD: "06",
        ALAND: 1029016039,
        AWATER: 20034376
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.228131, 41.511136999063567],
            [-76.220139, 41.541284999063436],
            [-76.197998, 41.647822999063024],
            [-76.115172, 41.651824999063003],
            [-76.090387, 41.644591999063046],
            [-75.719887, 41.64226299906305],
            [-75.772134, 41.50586899906358],
            [-75.834695, 41.427245999063892],
            [-76.00507, 41.384550999064061],
            [-76.2831, 41.376516999064094],
            [-76.228131, 41.511136999063567]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "347",
        COUNTYNS: "01383959",
        AFFGEOID: "0500000US48347",
        GEOID: "48347",
        NAME: "Nacogdoches",
        LSAD: "06",
        ALAND: 2451572359,
        AWATER: 89731152
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.937532, 31.84555499915384],
            [-94.452416, 31.844280999153856],
            [-94.432715, 31.822209999154179],
            [-94.399091, 31.653595999156654],
            [-94.311042, 31.589251999157604],
            [-94.300622, 31.526457999158538],
            [-94.33119, 31.362965999160981],
            [-94.339773, 31.240819999162834],
            [-94.326616, 31.224753999163074],
            [-94.396017, 31.3023559991619],
            [-94.449422, 31.321336999161609],
            [-94.495874, 31.405727999160355],
            [-94.544888, 31.431714999159954],
            [-94.728679, 31.457225999159576],
            [-94.865857, 31.526915999158529],
            [-94.924219, 31.579486999157744],
            [-94.978128, 31.793293999154599],
            [-94.937532, 31.84555499915384]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "053",
        COUNTYNS: "01529159",
        AFFGEOID: "0500000US53053",
        GEOID: "53053",
        NAME: "Pierce",
        LSAD: "06",
        ALAND: 4320445281,
        AWATER: 355666719
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.852917, 47.256461999059809],
            [-122.801199, 47.403577999060204],
            [-122.536993, 47.403354999060205],
            [-122.537595, 47.33733899906003],
            [-122.510135, 47.319619999059981],
            [-122.437252, 47.333716999060016],
            [-122.350506, 47.273841999059847],
            [-122.33492, 47.257593999059814],
            [-122.331322, 47.257364999059831],
            [-122.229792, 47.25755899905981],
            [-122.143976, 47.257525999059808],
            [-122.094764, 47.194976999059648],
            [-121.937864, 47.138720999059501],
            [-121.796464, 47.173058999059599],
            [-121.630993, 47.153512999059544],
            [-121.581673, 47.118647999059462],
            [-121.379961, 47.087247999059393],
            [-121.456447, 46.923576999058987],
            [-121.52307, 46.872782999058884],
            [-121.455218, 46.783796999058694],
            [-121.758593, 46.783790999058688],
            [-121.84189, 46.728454999058577],
            [-122.203115, 46.763060999058645],
            [-122.265906, 46.765471999058654],
            [-122.303576, 46.828116999058793],
            [-122.491079, 46.86796499905887],
            [-122.494116, 46.905217999058948],
            [-122.560436, 46.933456999059011],
            [-122.659863, 47.003224999059178],
            [-122.692178, 47.0992059990594],
            [-122.759505, 47.141314999059517],
            [-122.820576, 47.194219999059655],
            [-122.852917, 47.256461999059809]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "027",
        COUNTYNS: "01648573",
        AFFGEOID: "0500000US47027",
        GEOID: "47027",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 612626310,
        AWATER: 59071215
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.796462, 36.613540999096443],
            [-85.788556543613893, 36.621711858131071],
            [-85.731862, 36.620428999096397],
            [-85.488353, 36.614993999096441],
            [-85.436404272885895, 36.618003844123422],
            [-85.295812485727481, 36.626149558463837],
            [-85.290627, 36.626449999096337],
            [-85.276287947499398, 36.626157539899737],
            [-85.267057, 36.573237999096833],
            [-85.284878, 36.531292999097232],
            [-85.446372, 36.497969999097549],
            [-85.495422, 36.403073999098467],
            [-85.57596, 36.471616999097812],
            [-85.704075, 36.522394999097315],
            [-85.813885, 36.499472999097542],
            [-85.796462, 36.613540999096443]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "119",
        COUNTYNS: "00161585",
        AFFGEOID: "0500000US01119",
        GEOID: "01119",
        NAME: "Sumter",
        LSAD: "06",
        ALAND: 2340898915,
        AWATER: 24634883
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.389248564124301, 32.578122395102056],
            [-88.373338, 32.711824999141605],
            [-88.347490247834898, 32.92903478601918],
            [-88.340084992139296, 32.991264337012836],
            [-88.171852, 32.995859999137778],
            [-88.175329, 32.932092999138625],
            [-88.207316, 32.92478199913873],
            [-88.132685, 32.832852999139966],
            [-88.053729, 32.593051999143242],
            [-87.928689, 32.6322839991427],
            [-87.840858, 32.605035999143077],
            [-87.898355, 32.592320999143261],
            [-87.85342, 32.532085999144087],
            [-88.031623, 32.43360999914546],
            [-88.046335, 32.377086999146243],
            [-87.930661, 32.310573999147174],
            [-88.421312221394302, 32.308679122158509],
            [-88.389248564124301, 32.578122395102056]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "027",
        COUNTYNS: "01531823",
        AFFGEOID: "0500000US53027",
        GEOID: "53027",
        NAME: "Grays Harbor",
        LSAD: "06",
        ALAND: 4924343838,
        AWATER: 834767680
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.353615670886981, 47.533537082112694],
            [-124.04674, 47.518524999060546],
            [-123.505917, 47.517715999060549],
            [-123.505021, 47.258534999059819],
            [-123.488943, 47.082645999059373],
            [-123.201891, 47.085058999059385],
            [-123.158364, 46.995870999059157],
            [-123.1605, 46.827160999058783],
            [-123.160588, 46.793382999058714],
            [-123.351391, 46.793508999058716],
            [-123.37095, 46.792128999058704],
            [-124.096796667904997, 46.794084999058711],
            [-124.101232, 46.810655999058746],
            [-124.138225, 46.905533999058953],
            [-124.180111, 46.926356999058996],
            [-124.169113, 46.994507999059145],
            [-124.188544, 47.157858999059549],
            [-124.195893, 47.173999999059596],
            [-124.236349, 47.287286999059887],
            [-124.319379, 47.355558999060079],
            [-124.353615670886981, 47.533537082112694]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "051",
        COUNTYNS: "01581085",
        AFFGEOID: "0500000US55051",
        GEOID: "55051",
        NAME: "Iron",
        LSAD: "06",
        ALAND: 1963699135,
        AWATER: 416924547
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.548578555966074, 46.586236257808793],
            [-90.505909, 46.589613999058315],
            [-90.437596, 46.561491999058255],
            [-90.418136, 46.566093999058253],
            [-90.387228, 46.533662999058194],
            [-90.331887, 46.553277999058238],
            [-90.285707, 46.518845999058158],
            [-90.214866, 46.499946999058132],
            [-90.158241, 46.420484999057997],
            [-90.120489, 46.33685199905787],
            [-89.929125625664796, 46.299915727465901],
            [-89.928837, 45.98196699905737],
            [-90.043958, 45.981947999057375],
            [-90.302063, 45.981348999057374],
            [-90.303072, 46.154848999057599],
            [-90.426274, 46.154049999057591],
            [-90.425978, 46.240449999057709],
            [-90.552227, 46.240850999057727],
            [-90.548578555966074, 46.586236257808793]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "033",
        COUNTYNS: "01581076",
        AFFGEOID: "0500000US55033",
        GEOID: "55033",
        NAME: "Dunn",
        LSAD: "06",
        ALAND: 2201882935,
        AWATER: 35592324
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.156465, 45.209553999056801],
            [-91.665647, 45.207990999056804],
            [-91.666402, 45.120822999056777],
            [-91.651396, 45.030164999056765],
            [-91.650455, 44.855950999056773],
            [-91.650361, 44.683632999056805],
            [-92.135202, 44.68437399905681],
            [-92.136348, 44.857847999056759],
            [-92.136113, 45.121431999056774],
            [-92.156465, 45.209553999056801]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "097",
        COUNTYNS: "01383834",
        AFFGEOID: "0500000US48097",
        GEOID: "48097",
        NAME: "Cooke",
        LSAD: "06",
        ALAND: 2265775270,
        AWATER: 61333916
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.484143438587594, 33.913889108016861],
            [-97.451469, 33.870929999126496],
            [-97.444193, 33.82377299912708],
            [-97.372941, 33.819453999127141],
            [-97.318243, 33.865120999126546],
            [-97.24618, 33.900343999126115],
            [-97.206141, 33.914279999125952],
            [-97.166629, 33.847310999126783],
            [-97.205652, 33.809823999127246],
            [-97.149394, 33.721966999128355],
            [-97.091072, 33.7351149991282],
            [-97.087852, 33.774098999127702],
            [-97.07859, 33.812755999127226],
            [-97.055838, 33.855740999126688],
            [-96.985567, 33.886521999126295],
            [-96.988745, 33.918467999125902],
            [-96.952313, 33.944581999125582],
            [-96.94461688837049, 33.945013249579588],
            [-96.94386, 33.416409999132256],
            [-97.383095, 33.430449999132072],
            [-97.406768, 33.433109999132036],
            [-97.487065, 33.433680999132029],
            [-97.484143438587594, 33.913889108016861]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "057",
        COUNTYNS: "01448042",
        AFFGEOID: "0500000US49057",
        GEOID: "49057",
        NAME: "Weber",
        LSAD: "06",
        ALAND: 1492520445,
        AWATER: 216460044
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.493515, 41.07688799906537],
            [-112.238065, 41.336551999064262],
            [-112.028672, 41.336511999064271],
            [-111.96318, 41.365334999064146],
            [-111.958286, 41.433872999063873],
            [-111.885443, 41.426373999063898],
            [-111.76059, 41.36881799906412],
            [-111.666576, 41.428829999063879],
            [-111.510839, 41.423099999063922],
            [-111.477344, 41.384168999064066],
            [-111.420728, 41.361307999064167],
            [-111.497182, 41.226904999064729],
            [-111.596716, 41.238552999064666],
            [-111.678016, 41.181207999064924],
            [-111.799811, 41.217177999064766],
            [-111.872732, 41.188037999064896],
            [-111.856247, 41.13908299906511],
            [-112.186693, 41.153027999065046],
            [-112.451621, 41.087333999065336],
            [-112.493515, 41.07688799906537]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "810",
        COUNTYNS: "01498559",
        AFFGEOID: "0500000US51810",
        GEOID: "51810",
        NAME: "Virginia Beach",
        LSAD: "25",
        ALAND: 633823900,
        AWATER: 654692027
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.22606, 36.839946999094337],
            [-76.197259, 36.828147999094455],
            [-76.193122, 36.877424999094004],
            [-76.176947585198704, 36.928538679248533],
            [-76.087955, 36.908646999093705],
            [-76.043054, 36.927546999093543],
            [-75.996252, 36.922046999093595],
            [-75.961589, 36.799994999094707],
            [-75.921748, 36.692050999095713],
            [-75.890946, 36.630752999096295],
            [-75.867044, 36.550753999097061],
            [-76.02675, 36.550552999097057],
            [-76.122349947010079, 36.550552331651154],
            [-76.121955, 36.665749999095965],
            [-76.067996, 36.692578999095701],
            [-76.2037, 36.76974199909499],
            [-76.223591, 36.820792999094522],
            [-76.22606, 36.839946999094337]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "045",
        COUNTYNS: "01639741",
        AFFGEOID: "0500000US47045",
        GEOID: "47045",
        NAME: "Dyer",
        LSAD: "06",
        ALAND: 1326943234,
        AWATER: 36772010
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.692437, 36.02050699910226],
            [-89.680029, 36.082493999101636],
            [-89.64302, 36.103619999101426],
            [-89.592102, 36.135636999101109],
            [-89.623804, 36.183127999100627],
            [-89.627641441675394, 36.185460315706614],
            [-89.488283, 36.187543999100576],
            [-89.482971, 36.212658999100334],
            [-89.154464, 36.204677999100419],
            [-89.163406, 36.026043999102193],
            [-89.206297, 36.011813999102344],
            [-89.188844, 35.999759999102466],
            [-89.280188, 35.886415999103619],
            [-89.342829, 35.880933999103668],
            [-89.398186, 35.941368999103062],
            [-89.631345, 35.924132999103236],
            [-89.6489049703452, 35.903583481416049],
            [-89.652279, 35.921461999103265],
            [-89.686924, 35.947715999102982],
            [-89.733095, 36.000607999102456],
            [-89.692437, 36.02050699910226]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "041",
        COUNTYNS: "01639738",
        AFFGEOID: "0500000US47041",
        GEOID: "47041",
        NAME: "DeKalb",
        LSAD: "06",
        ALAND: 788354549,
        AWATER: 63715775
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.059706, 36.086023999101606],
            [-85.910118, 36.116924999101293],
            [-85.864024, 36.093808999101519],
            [-85.807415, 36.131581999101144],
            [-85.767136, 36.070086999101761],
            [-85.644604, 36.015052999102316],
            [-85.662917, 35.971500999102744],
            [-85.632449, 35.847384999104023],
            [-85.682095, 35.831253999104192],
            [-85.868174, 35.846359999104024],
            [-85.885156, 35.839657999104098],
            [-85.88921, 35.857542999103913],
            [-86.014849, 35.961059999102851],
            [-86.063413, 36.07005199910175],
            [-86.059706, 36.086023999101606]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "043",
        COUNTYNS: "00277286",
        AFFGEOID: "0500000US06043",
        GEOID: "06043",
        NAME: "Mariposa",
        LSAD: "06",
        ALAND: 3752472156,
        AWATER: 36225644
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.391931, 37.683558999086969],
            [-120.306312, 37.665418999087123],
            [-120.345437, 37.724786999086632],
            [-120.127226, 37.781565999086169],
            [-120.079508, 37.828807999085775],
            [-119.907013, 37.757925999086353],
            [-119.809409, 37.75502499908638],
            [-119.667203, 37.801223999086005],
            [-119.646203, 37.846822999085624],
            [-119.535699, 37.90412199908517],
            [-119.474295, 37.855622999085561],
            [-119.426792, 37.86672399908548],
            [-119.308995, 37.777985999086198],
            [-119.583585, 37.560334999088006],
            [-119.58422, 37.494695999088549],
            [-119.651172, 37.461350999088843],
            [-119.651191, 37.417831999089209],
            [-119.761809, 37.417113999089217],
            [-120.052055, 37.183107999091241],
            [-120.177636, 37.261525999090559],
            [-120.38767, 37.633363999087393],
            [-120.387613, 37.633703999087388],
            [-120.391931, 37.683558999086969]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "103",
        COUNTYNS: "00198167",
        AFFGEOID: "0500000US08103",
        GEOID: "08103",
        NAME: "Rio Blanco",
        LSAD: "06",
        ALAND: 8342275028,
        AWATER: 4868111
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.050732112077, 40.22265525732719],
            [-108.113179, 40.22166999906959],
            [-107.468897, 40.220233999069599],
            [-107.439386, 40.223378999069588],
            [-107.038097, 40.225356999069568],
            [-107.037363, 40.091537999070304],
            [-107.316993, 40.090764999070309],
            [-107.318751, 39.914967999071308],
            [-107.431665, 39.917724999071282],
            [-107.43177, 39.828376999071807],
            [-107.938502, 39.824995999071838],
            [-107.938546, 39.694463999072603],
            [-108.554722, 39.693639999072616],
            [-108.554627, 39.650163999072873],
            [-108.895728, 39.650336999072884],
            [-109.050871925262015, 39.660471776232214],
            [-109.050615, 39.874969999071538],
            [-109.050973, 40.180848999069816],
            [-109.050732112077, 40.22265525732719]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "131",
        COUNTYNS: "00295727",
        AFFGEOID: "0500000US12131",
        GEOID: "12131",
        NAME: "Walton",
        LSAD: "06",
        ALAND: 2687686788,
        AWATER: 522848570
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.388644662000402, 30.994528214441797],
            [-86.364974, 30.994436999166599],
            [-86.187248048981303, 30.994066722628812],
            [-86.035038104950601, 30.99374960646891],
            [-86.037591, 30.704571999171112],
            [-85.844421, 30.703129999171136],
            [-85.915297, 30.636426999172187],
            [-85.859769, 30.490133999174517],
            [-85.992736, 30.389319999176127],
            [-85.996102593975991, 30.26890138038717],
            [-85.999937, 30.270779999178036],
            [-86.089963, 30.303568999177504],
            [-86.222561, 30.343584999176862],
            [-86.397379606502398, 30.377495279595415],
            [-86.388644662000402, 30.994528214441797]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "005",
        COUNTYNS: "00516849",
        AFFGEOID: "0500000US21005",
        GEOID: "21005",
        NAME: "Anderson",
        LSAD: "06",
        ALAND: 522773659,
        AWATER: 6311541
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.169359, 37.997575999084432],
            [-85.101646, 38.03726999908411],
            [-85.047783, 38.072340999083842],
            [-85.023711, 38.129051999083401],
            [-84.902266, 38.093436999083679],
            [-84.864908, 38.116925999083499],
            [-84.830915, 38.093604999083666],
            [-84.849779, 38.047984999084022],
            [-84.796327, 37.97008799908464],
            [-84.933085, 37.954185999084771],
            [-85.030528, 37.891537999085273],
            [-85.126552, 37.917168999085078],
            [-85.152797, 37.897660999085225],
            [-85.167834, 37.971808999084644],
            [-85.169359, 37.997575999084432]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "217",
        COUNTYNS: "00758561",
        AFFGEOID: "0500000US29217",
        GEOID: "29217",
        NAME: "Vernon",
        LSAD: "06",
        ALAND: 2140348947,
        AWATER: 27015775
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.617885, 37.682213999086983],
            [-94.614465, 37.987798999084504],
            [-94.614100248705398, 38.037056582465425],
            [-94.613929961055703, 38.060052958980442],
            [-94.237787, 38.026016999084206],
            [-94.135238, 38.058117999083947],
            [-94.058285, 38.036626999084113],
            [-94.065675, 37.900986999085205],
            [-94.073524, 37.639721999087328],
            [-94.617845524354209, 37.653577795721631],
            [-94.617872450898091, 37.673110699419162],
            [-94.617885, 37.682213999086983]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "095",
        COUNTYNS: "00835869",
        AFFGEOID: "0500000US31095",
        GEOID: "31095",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 1476736217,
        AWATER: 14232350
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.368688, 40.350391999068904],
            [-96.916055, 40.349726999068913],
            [-96.916407005465771, 40.001454090114706],
            [-97.009165, 40.00146299907081],
            [-97.369199035314992, 40.001939304831311],
            [-97.368688, 40.350391999068904]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "003",
        COUNTYNS: "00835824",
        AFFGEOID: "0500000US31003",
        GEOID: "31003",
        NAME: "Antelope",
        LSAD: "06",
        ALAND: 2220183804,
        AWATER: 3558463
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.300235, 42.436912999060397],
            [-97.834431, 42.437683999060397],
            [-97.834536, 42.089749999061461],
            [-97.833288, 41.916285999062062],
            [-98.295602, 41.914953999062057],
            [-98.295421, 42.070494999061523],
            [-98.300592, 42.088852999061466],
            [-98.300235, 42.436912999060397]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "105",
        COUNTYNS: "00347828",
        AFFGEOID: "0500000US13105",
        GEOID: "13105",
        NAME: "Elbert",
        LSAD: "06",
        ALAND: 909303700,
        AWATER: 59911773
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.078004, 34.223605999122171],
            [-82.980018, 34.210065999122342],
            [-82.774628731012086, 34.288366817376087],
            [-82.744982, 34.244860999121912],
            [-82.735107988941593, 34.212614811365206],
            [-82.715373, 34.148164999123097],
            [-82.642797, 34.081311999123891],
            [-82.595025792607288, 34.013517808334335],
            [-82.591855, 34.009017999124794],
            [-82.562996798580897, 33.956554909652333],
            [-82.636698, 33.974091999125221],
            [-82.645451, 33.984194999125087],
            [-82.72439, 33.984471999125084],
            [-82.779506, 33.971123999125254],
            [-82.942268, 34.01495699912472],
            [-82.976294, 34.04321899912437],
            [-83.103264, 34.170527999122811],
            [-83.078004, 34.223605999122171]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "119",
        COUNTYNS: "00835880",
        AFFGEOID: "0500000US31119",
        GEOID: "31119",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 1483412284,
        AWATER: 6998082
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.834536, 42.089749999061461],
            [-97.368404, 42.090921999061464],
            [-97.36807, 41.743206999062679],
            [-97.830493, 41.742237999062688],
            [-97.833288, 41.916285999062062],
            [-97.834536, 42.089749999061461]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "089",
        COUNTYNS: "00835866",
        AFFGEOID: "0500000US31089",
        GEOID: "31089",
        NAME: "Holt",
        LSAD: "06",
        ALAND: 6248083634,
        AWATER: 13201503
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.257039, 42.804295999059413],
            [-98.982527, 42.8964359990592],
            [-98.892846, 42.845791999059308],
            [-98.656835, 42.809020999059406],
            [-98.58376, 42.779735999059476],
            [-98.305149, 42.761206999059517],
            [-98.30544, 42.451421999060365],
            [-98.300235, 42.436912999060397],
            [-98.300592, 42.088852999061466],
            [-98.761155, 42.08851799906148],
            [-99.222733, 42.087915999061465],
            [-99.234629, 42.087994999061479],
            [-99.245803, 42.782498999059477],
            [-99.257039, 42.804295999059413]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "067",
        COUNTYNS: "00161559",
        AFFGEOID: "0500000US01067",
        GEOID: "01067",
        NAME: "Henry",
        LSAD: "06",
        ALAND: 1454926615,
        AWATER: 16986823
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.416437, 31.619465999157164],
            [-85.416038, 31.706663999155875],
            [-85.216076, 31.702408999155931],
            [-85.125440577342502, 31.762968702573346],
            [-85.11893, 31.732663999155488],
            [-85.12553, 31.694964999156046],
            [-85.058169, 31.620226999157151],
            [-85.05796, 31.570839999157865],
            [-85.041881, 31.544683999158273],
            [-85.051681429506203, 31.519540328300337],
            [-85.071621, 31.468383999159403],
            [-85.066005, 31.431362999159955],
            [-85.092487, 31.362880999160996],
            [-85.087929, 31.321647999161613],
            [-85.088829963530486, 31.308647754658399],
            [-85.179131, 31.307674999161819],
            [-85.417434, 31.314972999161707],
            [-85.416437, 31.619465999157164]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "081",
        COUNTYNS: "00277305",
        AFFGEOID: "0500000US06081",
        GEOID: "06081",
        NAME: "San Mateo",
        LSAD: "06",
        ALAND: 1161757245,
        AWATER: 757313941
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.518088, 37.576137999087862],
            [-122.496786, 37.612135999087556],
            [-122.496784, 37.686432999086954],
            [-122.500678019083011, 37.708132567104663],
            [-122.389826876588003, 37.708330999086769],
            [-122.39319, 37.707530999086764],
            [-122.360219, 37.592500999087733],
            [-122.244372, 37.558139999088027],
            [-122.168449, 37.504142999088472],
            [-122.129199702224, 37.521321997679138],
            [-122.11724, 37.506720999088458],
            [-122.081473, 37.477837999088692],
            [-122.144933, 37.458199999088869],
            [-122.190402, 37.431471999089091],
            [-122.192162, 37.31909399909005],
            [-122.152278, 37.286054999090354],
            [-122.152774, 37.215443999090958],
            [-122.317682, 37.186944999091217],
            [-122.294310191913013, 37.105141385810029],
            [-122.322971, 37.115459999091854],
            [-122.344029, 37.144098999091597],
            [-122.397065, 37.187248999091217],
            [-122.418452, 37.24852099909068],
            [-122.405590143900994, 37.314965036834003],
            [-122.401323, 37.33700899908991],
            [-122.409258, 37.374804999089569],
            [-122.443687, 37.435940999089048],
            [-122.445987, 37.461540999088825],
            [-122.493789, 37.492340999088576],
            [-122.516689, 37.521339999088326],
            [-122.518088, 37.576137999087862]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "045",
        COUNTYNS: "00306917",
        AFFGEOID: "0500000US12045",
        GEOID: "12045",
        NAME: "Gulf",
        LSAD: "06",
        ALAND: 1433785086,
        AWATER: 494266609
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.416548, 29.842627999185044],
            [-85.38473, 29.920948999183757],
            [-85.389240033231999, 29.924114861787395],
            [-85.389679, 30.200972999179164],
            [-85.11566, 30.199975999179181],
            [-85.154015, 30.09204299918094],
            [-85.113691, 30.023172999182073],
            [-85.025439, 29.971398999182917],
            [-85.053109, 29.910608999183925],
            [-85.039355, 29.780169999186086],
            [-85.109401, 29.776792999186142],
            [-85.228425133073102, 29.669559996949946],
            [-85.259719, 29.68129599918775],
            [-85.352615, 29.659786999188096],
            [-85.40283, 29.758781999186443],
            [-85.416548, 29.842627999185044]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "281",
        COUNTYNS: "00351252",
        AFFGEOID: "0500000US13281",
        GEOID: "13281",
        NAME: "Towns",
        LSAD: "06",
        ALAND: 431313630,
        AWATER: 13975835
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.93642734425201, 34.987484382492987],
            [-83.619985, 34.986591999113294],
            [-83.549180753504686, 34.988803195027273],
            [-83.656592, 34.87819199911452],
            [-83.652194, 34.822992999115144],
            [-83.681596, 34.800592999115402],
            [-83.704146, 34.790981999115509],
            [-83.780401, 34.792892999115487],
            [-83.810399, 34.906490999114183],
            [-83.915104, 34.926886999113968],
            [-83.93642734425201, 34.987484382492987]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "009",
        COUNTYNS: "00395190",
        AFFGEOID: "0500000US16009",
        GEOID: "16009",
        NAME: "Benewah",
        LSAD: "06",
        ALAND: 2012070462,
        AWATER: 18975652
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.040490375568012, 47.366028885758105],
            [-116.629059, 47.371437999060127],
            [-116.586549, 47.414694999060238],
            [-116.329406, 47.414010999060238],
            [-116.329418, 47.021914999059227],
            [-116.45812, 47.036595999059266],
            [-116.835269, 47.032451999059248],
            [-116.967735, 47.115231999059446],
            [-117.039833386027993, 47.127268991313585],
            [-117.039836, 47.154733999059552],
            [-117.040159751863015, 47.25927199905982],
            [-117.040490375568012, 47.366028885758105]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "023",
        COUNTYNS: "00424213",
        AFFGEOID: "0500000US17023",
        GEOID: "17023",
        NAME: "Clark",
        LSAD: "06",
        ALAND: 1298676804,
        AWATER: 8796711
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.014205, 39.48076199907392],
            [-87.960179, 39.48130899907391],
            [-87.687622, 39.487362999073873],
            [-87.531666681974301, 39.477110991816829],
            [-87.531624, 39.469377999073984],
            [-87.531646, 39.347887999074764],
            [-87.578331, 39.340342999074814],
            [-87.600397, 39.312903999074983],
            [-87.59474584540601, 39.259383615558342],
            [-87.593486, 39.247451999075402],
            [-87.577029, 39.211122999075656],
            [-87.640435, 39.166726999075948],
            [-87.638293413744094, 39.157492546278498],
            [-87.656794, 39.172176999075901],
            [-87.950385, 39.174881999075879],
            [-88.007766, 39.173924999075879],
            [-88.012121, 39.378967999074561],
            [-88.014205, 39.48076199907392]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "141",
        COUNTYNS: "00452855",
        AFFGEOID: "0500000US18141",
        GEOID: "18141",
        NAME: "St. Joseph",
        LSAD: "06",
        ALAND: 1185912330,
        AWATER: 9070753
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.524219742442895, 41.759572155036111],
            [-86.501773, 41.759552999062599],
            [-86.226094344987388, 41.760015958179004],
            [-86.22607, 41.760015999062617],
            [-86.06256302901059, 41.759653032220605],
            [-86.059451, 41.479153999063691],
            [-86.467108, 41.476458999063702],
            [-86.466603, 41.43296799906387],
            [-86.524711, 41.43288599906387],
            [-86.486433, 41.570544999063316],
            [-86.526682, 41.650439999063018],
            [-86.524219742442895, 41.759572155036111]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "103",
        COUNTYNS: "01629503",
        AFFGEOID: "0500000US22103",
        GEOID: "22103",
        NAME: "St. Tammany",
        LSAD: "15",
        ALAND: 2190165998,
        AWATER: 647368450
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.255648, 30.711904999170997],
            [-89.99459, 30.665306999171737],
            [-89.826180359115199, 30.668823269356892],
            [-89.821868, 30.644023999172074],
            [-89.791664, 30.551523999173536],
            [-89.712493, 30.477509999174721],
            [-89.699932082840789, 30.454036879961084],
            [-89.678514, 30.414011999175735],
            [-89.634208, 30.30825599917743],
            [-89.607655, 30.217095999178898],
            [-89.524504, 30.180752999179493],
            [-89.605088456554597, 30.142814156739401],
            [-89.625054, 30.153492999179928],
            [-89.685287, 30.175345999179576],
            [-89.715686, 30.160840999179815],
            [-89.744078, 30.177498999179544],
            [-89.797414, 30.160756999179814],
            [-89.903234, 30.198659999179203],
            [-90.040277, 30.15511399917991],
            [-90.108119, 30.164939999179744],
            [-90.156256, 30.189612999179342],
            [-90.160636, 30.234336999178634],
            [-90.243649, 30.361580999176571],
            [-90.244305, 30.503591999174304],
            [-90.243172, 30.596014999172823],
            [-90.255648, 30.711904999170997]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "019",
        COUNTYNS: "00596495",
        AFFGEOID: "0500000US24019",
        GEOID: "24019",
        NAME: "Dorchester",
        LSAD: "06",
        ALAND: 1400542024,
        AWATER: 1145384767
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.33636, 38.492234999080651],
            [-76.277461, 38.541850999080296],
            [-76.290043, 38.569157999080083],
            [-76.279589, 38.609519999079787],
            [-76.231187, 38.614009999079762],
            [-76.203065, 38.610740999079781],
            [-76.165435, 38.610199999079789],
            [-76.170161874587293, 38.640842046229672],
            [-76.027717, 38.572432999080064],
            [-75.945248, 38.674108999079337],
            [-75.839103, 38.704123999079108],
            [-75.707555026429006, 38.635385354139501],
            [-75.707551, 38.635334999079603],
            [-75.701777515244999, 38.560766928158749],
            [-75.823706, 38.481021999080731],
            [-75.864213, 38.350781999081697],
            [-75.928628, 38.297271999082113],
            [-75.923766082428301, 38.2462852760845],
            [-75.9445, 38.249144999082475],
            [-76.038935, 38.254931999082423],
            [-76.031988, 38.187419999082948],
            [-76.011916, 38.122213999083456],
            [-76.005904, 38.0771699990838],
            [-76.048692, 38.086727999083735],
            [-76.095548, 38.125122999083423],
            [-76.088639, 38.192648999082891],
            [-76.135513, 38.232184999082605],
            [-76.217613, 38.305682999082045],
            [-76.257667, 38.3248549990819],
            [-76.25, 38.362303999081625],
            [-76.280551, 38.403142999081311],
            [-76.33636, 38.492234999080651]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "25",
        COUNTYFP: "013",
        COUNTYNS: "00606933",
        AFFGEOID: "0500000US25013",
        GEOID: "25013",
        NAME: "Hampden",
        LSAD: "06",
        ALAND: 1598126407,
        AWATER: 44423491
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.071113, 42.148205999061275],
            [-73.007867, 42.238408999060987],
            [-73.001529, 42.26266099906092],
            [-73.000173, 42.312677999060753],
            [-72.953254, 42.343828999060669],
            [-72.885184, 42.332614999060695],
            [-72.912302, 42.239132999060985],
            [-72.793414, 42.236853999060997],
            [-72.686861, 42.183389999061156],
            [-72.613138, 42.286264999060855],
            [-72.609202, 42.285476999060847],
            [-72.598933, 42.268089999060905],
            [-72.623934, 42.233690999061004],
            [-72.607933, 42.216290999061059],
            [-72.589938, 42.21197499906107],
            [-72.44712, 42.227253999061027],
            [-72.395478, 42.185736999061149],
            [-72.333875, 42.220718999061049],
            [-72.291567, 42.232220999061013],
            [-72.221218, 42.245251999060976],
            [-72.263924, 42.183830999061165],
            [-72.135011, 42.161783999061228],
            [-72.135731461946691, 42.029141929003266],
            [-72.198845978131999, 42.030103892343668],
            [-72.317148, 42.031906999061654],
            [-72.397476255195897, 42.032816190197458],
            [-72.509179336298303, 42.034080495651757],
            [-72.528131, 42.034294999061657],
            [-72.607933, 42.030794999061669],
            [-72.735496, 42.036398999061639],
            [-72.766739, 42.002994999061748],
            [-72.774759316078601, 42.002129103242055],
            [-72.810078, 41.998315999061774],
            [-72.847142, 42.03689399906164],
            [-72.999549, 42.038652999061632],
            [-73.008762781630693, 42.038903415308326],
            [-73.053362673748097, 42.040115570971935],
            [-73.071113, 42.148205999061275]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "093",
        COUNTYNS: "00695770",
        AFFGEOID: "0500000US28093",
        GEOID: "28093",
        NAME: "Marshall",
        LSAD: "06",
        ALAND: 1828989824,
        AWATER: 9195190
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.724315837968589, 34.994186957866702],
            [-89.644283834092107, 34.994067209675713],
            [-89.434954, 34.993753999113203],
            [-89.352679391848397, 34.993996140859096],
            [-89.352705, 34.844431999114903],
            [-89.299227, 34.844414999114896],
            [-89.298334, 34.582925999117911],
            [-89.245645, 34.583233999117908],
            [-89.246133, 34.495632999118932],
            [-89.32582, 34.525973999118577],
            [-89.424459, 34.519128999118657],
            [-89.476642, 34.554167999118249],
            [-89.668448, 34.554366999118244],
            [-89.669183, 34.685596999116719],
            [-89.722333, 34.685503999116719],
            [-89.723442, 34.772310999115724],
            [-89.724315837968589, 34.994186957866702]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "071",
        COUNTYNS: "00659481",
        AFFGEOID: "0500000US27071",
        GEOID: "27071",
        NAME: "Koochiching",
        LSAD: "06",
        ALAND: 8039819178,
        AWATER: 129002560
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.430201279234097, 48.698308784593316],
            [-94.388848, 48.711944999064876],
            [-94.281797, 48.705254999064856],
            [-94.251169, 48.683513999064751],
            [-94.250191, 48.656322999064635],
            [-94.091244, 48.64366899906458],
            [-93.927004, 48.631219999064534],
            [-93.844008, 48.629394999064516],
            [-93.80527, 48.570298999064278],
            [-93.815178, 48.526507999064087],
            [-93.674568, 48.516296999064039],
            [-93.562062, 48.528896999064109],
            [-93.467504, 48.545663999064175],
            [-93.464308, 48.591791999064355],
            [-93.371156, 48.605084999064417],
            [-93.347528, 48.626619999064502],
            [-93.207398, 48.64247399906457],
            [-93.14242, 48.624923999064507],
            [-93.088454535286104, 48.626814217561012],
            [-93.096488, 48.067825999062329],
            [-93.081158, 47.891869999061726],
            [-93.775772, 47.898949999061749],
            [-93.776173, 47.846403999061586],
            [-94.418543, 47.845815999061571],
            [-94.417747, 47.93310999906187],
            [-94.428702, 48.367332999063457],
            [-94.430201279234097, 48.698308784593316]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "029",
        COUNTYNS: "01719611",
        AFFGEOID: "0500000US30029",
        GEOID: "30029",
        NAME: "Flathead",
        LSAD: "06",
        ALAND: 13175679131,
        AWATER: 437280390
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-115.020079, 48.225953999062895],
            [-114.848098, 48.226124999062911],
            [-114.849803, 48.573501999064284],
            [-114.888679, 48.573545999064287],
            [-114.88859, 48.658764999064644],
            [-114.639007, 48.658877999064636],
            [-114.692131, 48.684576999064753],
            [-114.612063, 48.751857999065052],
            [-114.732545, 48.809751999065291],
            [-114.691671, 48.842114999065444],
            [-114.718806, 48.938677999065874],
            [-114.6848, 48.97327599906604],
            [-114.727045223277003, 49.000587002954767],
            [-114.678217, 49.000724999066158],
            [-114.375977, 49.001389999066177],
            [-114.180211, 48.999702999066159],
            [-114.068178454280002, 48.999355880729965],
            [-114.021207, 48.961342999065984],
            [-114.058093, 48.888953999065663],
            [-114.004135, 48.833214999065397],
            [-113.790539, 48.845529999065448],
            [-113.702938, 48.71986499906491],
            [-113.750454, 48.614334999064454],
            [-113.59905, 48.575172999064286],
            [-113.543488, 48.586871999064343],
            [-113.467372, 48.547554999064182],
            [-113.47916, 48.453114999063793],
            [-113.355976, 48.423911999063677],
            [-113.333424, 48.38796099906353],
            [-113.387048, 48.341501999063347],
            [-113.349046, 48.310262999063234],
            [-113.22768, 48.235751999062956],
            [-113.230906, 48.170425999062722],
            [-113.014811, 48.131029999062562],
            [-112.878931, 47.975712999062011],
            [-112.984734, 47.953917999061922],
            [-113.086634, 47.918181999061801],
            [-113.080997, 47.749916999061263],
            [-113.165811, 47.719763999061172],
            [-113.144368, 47.595506999060781],
            [-113.325845, 47.595506999060781],
            [-113.466485, 47.600056999060797],
            [-113.634079, 47.600026999060788],
            [-113.603924, 47.721439999061168],
            [-113.640588, 47.719997999061164],
            [-113.6817, 47.805152999061441],
            [-113.668077, 47.89096299906172],
            [-113.747183, 47.952951999061938],
            [-113.842313, 47.978876999062024],
            [-113.884541, 48.051899999062279],
            [-114.029042, 48.05165299906227],
            [-114.078282, 48.023147999062175],
            [-114.038545, 47.96254999906197],
            [-114.49219, 47.961988999061965],
            [-114.47729, 47.789940999061379],
            [-114.604462, 47.789870999061378],
            [-114.604584, 47.875604999061679],
            [-114.989344, 47.872806999061645],
            [-115.010672, 48.017620999062153],
            [-115.020079, 48.225953999062895]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "041",
        COUNTYNS: "00882237",
        AFFGEOID: "0500000US34041",
        GEOID: "34041",
        NAME: "Warren",
        LSAD: "06",
        ALAND: 923327586,
        AWATER: 15931292
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.192612, 40.715873999067064],
            [-75.177477, 40.764224999066826],
            [-75.111358731672297, 40.789982288281088],
            [-75.108505, 40.791093999066703],
            [-75.090962, 40.849186999066418],
            [-75.065438, 40.88568199906625],
            [-75.09772, 40.926678999066063],
            [-75.123253944208486, 40.965305533453588],
            [-75.133086, 40.980178999065821],
            [-75.070532, 41.018619999065642],
            [-75.015271, 41.061214999065449],
            [-74.992385995182204, 41.093028246058708],
            [-74.983042074247791, 41.106017557922051],
            [-74.962665, 41.090526999065325],
            [-74.790408, 40.930434999066037],
            [-74.769425, 40.910933999066124],
            [-74.850513, 40.803955999066631],
            [-74.889819, 40.787728999066708],
            [-74.968047, 40.711323999067076],
            [-74.983388, 40.705371999067104],
            [-75.146009, 40.628585999067482],
            [-75.188199878125189, 40.592613365716268],
            [-75.189236401355899, 40.609056960189676],
            [-75.191059, 40.637970999067441],
            [-75.191841871748295, 40.677242124508645],
            [-75.192612, 40.715873999067064]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "055",
        COUNTYNS: "00933056",
        AFFGEOID: "0500000US35055",
        GEOID: "35055",
        NAME: "Taos",
        LSAD: "06",
        ALAND: 5704119046,
        AWATER: 3383126
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.038312, 36.325660999099227],
            [-105.9598, 36.354788999098936],
            [-105.938754, 36.462401999097892],
            [-105.981088, 36.713444999095522],
            [-105.971889, 36.88624699909392],
            [-106.006316410798007, 36.995386627910221],
            [-105.997472, 36.995416999092917],
            [-105.718469683991003, 36.995845926393926],
            [-105.716471, 36.995848999092921],
            [-105.533922, 36.995874999092919],
            [-105.41931, 36.995855999092925],
            [-105.251296, 36.995604999092933],
            [-105.220505200781005, 36.995563235579425],
            [-105.200117, 36.915627999093651],
            [-105.229372, 36.84770999909427],
            [-105.230632, 36.723783999095417],
            [-105.311842, 36.724470999095409],
            [-105.370638, 36.681031999095822],
            [-105.351385, 36.636904999096238],
            [-105.377745, 36.552095999097041],
            [-105.318718, 36.491692999097609],
            [-105.356826, 36.44950399909802],
            [-105.327301, 36.405429999098438],
            [-105.341264, 36.261862999099847],
            [-105.409677, 36.2368139991001],
            [-105.419992, 36.131703999101134],
            [-105.53038, 36.013013999102341],
            [-105.735883, 36.100132999101461],
            [-105.856947, 36.230433999100157],
            [-106.058364, 36.296977999099511],
            [-106.038312, 36.325660999099227]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "029",
        COUNTYNS: "00974113",
        AFFGEOID: "0500000US36029",
        GEOID: "36029",
        NAME: "Erie",
        LSAD: "06",
        ALAND: 2700590735,
        AWATER: 476948860
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.111361, 42.6133579990599],
            [-79.06376, 42.644757999059813],
            [-79.04886, 42.689157999059709],
            [-78.972378, 42.71599099905962],
            [-78.904843, 42.746120999059549],
            [-78.851355, 42.791757999059442],
            [-78.85688499234989, 42.80529199893671],
            [-78.865656, 42.82675799905936],
            [-78.882557, 42.867257999059262],
            [-78.912458, 42.886556999059216],
            [-78.909159, 42.933256999059097],
            [-78.927957, 42.952921999059058],
            [-78.961761, 42.957755999059053],
            [-79.019964, 42.994755999058974],
            [-79.00545, 43.057230999058831],
            [-79.018246, 43.066015999058806],
            [-79.019577884894304, 43.066296466949211],
            [-78.945262, 43.066955999058806],
            [-78.90586, 43.051555999058834],
            [-78.89326, 43.038755999058864],
            [-78.890159, 43.021955999058896],
            [-78.868756, 43.023261999058889],
            [-78.83935, 43.019452999058906],
            [-78.825366, 43.03365599905888],
            [-78.83126, 43.050245999058845],
            [-78.744015, 43.070375999058797],
            [-78.727647, 43.086219999058763],
            [-78.603989, 43.07202499905879],
            [-78.510384, 43.097957999058728],
            [-78.464449, 43.088702999058754],
            [-78.464381, 42.867460999059261],
            [-78.486249, 42.857329999059282],
            [-78.463233, 42.780460999059478],
            [-78.46394, 42.536331999060117],
            [-78.695937, 42.471939999060297],
            [-78.919766, 42.442385999060384],
            [-78.991702, 42.529248999060123],
            [-79.060777, 42.537852999060114],
            [-79.107778, 42.569964999060026],
            [-79.1359444040216, 42.569178709033629],
            [-79.111361, 42.6133579990599]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "013",
        COUNTYNS: "01065575",
        AFFGEOID: "0500000US39013",
        GEOID: "39013",
        NAME: "Belmont",
        LSAD: "06",
        ALAND: 1378212692,
        AWATER: 23682282
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.234053, 39.951269999071108],
            [-81.225924, 40.170074999069875],
            [-81.195889, 40.172137999069861],
            [-81.089702, 40.166997999069899],
            [-80.882892, 40.159494999069935],
            [-80.766269, 40.155110999069962],
            [-80.702575303622595, 40.157141397079656],
            [-80.705994, 40.151590999069981],
            [-80.736804, 40.080071999070377],
            [-80.738218439983598, 40.033543225149032],
            [-80.740126, 39.970792999070994],
            [-80.764479, 39.950249999071104],
            [-80.803394, 39.918761999071286],
            [-80.823438104785893, 39.85003208646809],
            [-81.237316, 39.867827999071579],
            [-81.234053, 39.951269999071108]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "041",
        COUNTYNS: "01135856",
        AFFGEOID: "0500000US41041",
        GEOID: "41041",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 2539209521,
        AWATER: 554669104
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.1152, 44.286485999057014],
            [-124.084401, 44.415610999056923],
            [-124.083601, 44.501122999056882],
            [-124.065008, 44.632503999056823],
            [-124.063406, 44.703176999056787],
            [-124.074066, 44.79810699905677],
            [-124.063155, 44.835332999056774],
            [-124.023834, 44.949824999056766],
            [-124.010097209885004, 45.04499895849797],
            [-123.724663, 45.044431999056776],
            [-123.725001, 44.739045999056778],
            [-123.704467, 44.721153999056789],
            [-123.602609, 44.721153999056789],
            [-123.597677, 44.433108999056913],
            [-123.720071, 44.433373999056911],
            [-123.715538, 44.360457999056976],
            [-123.816525, 44.315302999056996],
            [-123.775598, 44.283546999057023],
            [-123.941075, 44.282950999057022],
            [-123.961647, 44.277375999057021],
            [-124.114370074258005, 44.276193999057028],
            [-124.1152, 44.286485999057014]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "061",
        COUNTYNS: "01074043",
        AFFGEOID: "0500000US39061",
        GEOID: "39061",
        NAME: "Hamilton",
        LSAD: "06",
        ALAND: 1051353810,
        AWATER: 17350705
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.820159, 39.227224999075531],
            [-84.818877469275094, 39.305143582634436],
            [-84.8188770978104, 39.305166168127329],
            [-84.630047, 39.312055999074985],
            [-84.353209, 39.292286999075124],
            [-84.262581, 39.288384999075141],
            [-84.259431, 39.270795999075247],
            [-84.320028, 39.223432999075563],
            [-84.288288, 39.186486999075804],
            [-84.299328, 39.174446999075883],
            [-84.321207226856799, 39.020586351766312],
            [-84.326539, 39.027462999076874],
            [-84.40094, 39.046361999076737],
            [-84.432941, 39.083960999076481],
            [-84.445242, 39.114460999076279],
            [-84.462042, 39.121759999076232],
            [-84.480943, 39.116759999076272],
            [-84.493743, 39.102459999076366],
            [-84.49918784070239, 39.10216439994187],
            [-84.506518737436906, 39.101766407264371],
            [-84.550844, 39.099359999076384],
            [-84.607928, 39.073237999076561],
            [-84.622027683659809, 39.078327545730922],
            [-84.677247, 39.09825999907639],
            [-84.714048, 39.132658999076156],
            [-84.750749, 39.147357999076057],
            [-84.820157, 39.105479999076337],
            [-84.820159, 39.227224999075531]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "059",
        COUNTYNS: "01214033",
        AFFGEOID: "0500000US42059",
        GEOID: "42059",
        NAME: "Greene",
        LSAD: "06",
        ALAND: 1491700969,
        AWATER: 5253864
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.519160794314502, 39.96220005159293],
            [-80.301396, 40.020558999070701],
            [-80.077242, 39.997013999070845],
            [-80.038927, 39.996466999070847],
            [-80.048292, 39.978526999070944],
            [-79.998014, 39.983321999070924],
            [-79.923325, 39.921546999071268],
            [-79.916017356037798, 39.721055932591248],
            [-80.041702012810504, 39.721287031991238],
            [-80.075947, 39.721349999072444],
            [-80.421388, 39.721188999072446],
            [-80.519342, 39.721402999072446],
            [-80.519160794314502, 39.96220005159293]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "015",
        COUNTYNS: "00516854",
        AFFGEOID: "0500000US21015",
        GEOID: "21015",
        NAME: "Boone",
        LSAD: "06",
        ALAND: 637796403,
        AWATER: 26558591
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.897171, 39.05240699907668],
            [-84.860689, 39.078139999076519],
            [-84.820157, 39.105479999076337],
            [-84.750749, 39.147357999076057],
            [-84.714048, 39.132658999076156],
            [-84.677247, 39.09825999907639],
            [-84.622027683659809, 39.078327545730922],
            [-84.597932, 38.879430999077883],
            [-84.615664, 38.802276999078408],
            [-84.660107, 38.777292999078597],
            [-84.778623, 38.859359999078009],
            [-84.798699822952301, 38.859227389284726],
            [-84.786406, 38.882219999077854],
            [-84.830472, 38.897255999077757],
            [-84.864428518991687, 38.913843640127041],
            [-84.877762, 38.92035699907759],
            [-84.832617, 38.961459999077306],
            [-84.849445, 39.000922999077055],
            [-84.877570231124793, 39.03126284317085],
            [-84.897171, 39.05240699907668]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "029",
        COUNTYNS: "01513272",
        AFFGEOID: "0500000US53029",
        GEOID: "53029",
        NAME: "Island",
        LSAD: "06",
        ALAND: 539709170,
        AWATER: 800155316
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.752563, 48.260060999063043],
            [-122.707077, 48.315285999063256],
            [-122.66698252821601, 48.412466991107941],
            [-122.58717, 48.416852999063657],
            [-122.458596, 48.297702999063191],
            [-122.392977, 48.237429999062954],
            [-122.417909, 48.17062199906271],
            [-122.350289, 48.080311999062381],
            [-122.333348, 47.93321699906187],
            [-122.398047, 47.861616999061617],
            [-122.429625, 47.828097999061505],
            [-122.501809, 47.929712999061863],
            [-122.573745864315001, 47.951004166271026],
            [-122.546824, 47.967214999061973],
            [-122.542924, 47.996403999062082],
            [-122.607342, 48.030991999062195],
            [-122.598301, 48.110615999062496],
            [-122.633167, 48.163280999062685],
            [-122.711508, 48.193572999062795],
            [-122.752563, 48.260060999063043]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "003",
        COUNTYNS: "01550008",
        AFFGEOID: "0500000US54003",
        GEOID: "54003",
        NAME: "Berkeley",
        LSAD: "06",
        ALAND: 831766640,
        AWATER: 1061824
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.229129534218501, 39.390663886278581],
            [-78.138937, 39.593795999073222],
            [-78.027629100173186, 39.620655961417164],
            [-78.006734, 39.601336999073183],
            [-77.925988, 39.607641999073138],
            [-77.829814, 39.587287999073268],
            [-77.823762, 39.525906999073641],
            [-77.810943896262188, 39.500739165640297],
            [-77.825572, 39.49328899907384],
            [-77.84714, 39.446556999074133],
            [-77.930868, 39.381169999074551],
            [-77.966953, 39.367163999074627],
            [-77.988179, 39.320083999074953],
            [-78.035454, 39.278223999075209],
            [-78.033185235366602, 39.264624841080504],
            [-78.18737, 39.36398899907465],
            [-78.229129534218501, 39.390663886278581]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "031",
        COUNTYNS: "01581075",
        AFFGEOID: "0500000US55031",
        GEOID: "55031",
        NAME: "Douglas",
        LSAD: "06",
        ALAND: 3377709531,
        AWATER: 454636088
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.293619, 46.244042999057719],
            [-92.292759802428293, 46.417219501937993],
            [-92.292371, 46.495584999058131],
            [-92.292192, 46.663241999058442],
            [-92.205492, 46.664740999058445],
            [-92.183091, 46.695240999058498],
            [-92.143338, 46.731595999058591],
            [-92.100255, 46.734445999058586],
            [-92.050819, 46.710516999058541],
            [-92.01529, 46.706468999058529],
            [-91.961889, 46.682538999058487],
            [-91.886963, 46.6902109990585],
            [-91.820027, 46.690175999058496],
            [-91.645502, 46.734732999058579],
            [-91.574291, 46.757487999058633],
            [-91.551341535656391, 46.757475292518137],
            [-91.551936, 46.748110999058611],
            [-91.551282, 46.157044999057597],
            [-92.049636, 46.157596999057589],
            [-92.293830608578702, 46.157321305553602],
            [-92.293619, 46.244042999057719]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "043",
        COUNTYNS: "00161547",
        AFFGEOID: "0500000US01043",
        GEOID: "01043",
        NAME: "Cullman",
        LSAD: "06",
        ALAND: 1903070708,
        AWATER: 52424813
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.111992, 33.992384999124994],
            [-87.109911, 34.29929899912127],
            [-87.110111, 34.31379899912109],
            [-86.581936, 34.304693999121191],
            [-86.477525, 34.302757999121226],
            [-86.45302, 34.259316999121744],
            [-86.518927, 34.252320999121835],
            [-86.56385, 34.170492999122814],
            [-86.601147, 34.119039999123437],
            [-86.692474, 34.092404999123758],
            [-86.685365, 34.05913999912417],
            [-86.793914, 33.952058999125491],
            [-86.924387, 33.909221999126011],
            [-86.963358, 33.858220999126658],
            [-87.091836, 33.890092999126267],
            [-87.151036, 33.993224999124969],
            [-87.111992, 33.992384999124994]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "095",
        COUNTYNS: "00161574",
        AFFGEOID: "0500000US01095",
        GEOID: "01095",
        NAME: "Marshall",
        LSAD: "06",
        ALAND: 1465528724,
        AWATER: 148498594
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.58132, 34.371093999120411],
            [-86.550166, 34.545962999118345],
            [-86.533445, 34.502794999118848],
            [-86.423914, 34.479580999119122],
            [-86.330198, 34.512683999118728],
            [-86.326853, 34.599402999117721],
            [-86.148464, 34.599068999117719],
            [-86.097741, 34.512546999118733],
            [-86.087628, 34.466557999119281],
            [-86.057712, 34.475993999119176],
            [-86.118894, 34.403844999120011],
            [-86.106086, 34.200755999122464],
            [-86.1089, 34.186310999122625],
            [-86.206107, 34.17264999912279],
            [-86.303516, 34.099072999123678],
            [-86.45302, 34.259316999121744],
            [-86.477525, 34.302757999121226],
            [-86.581936, 34.304693999121191],
            [-86.58132, 34.371093999120411]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "111",
        COUNTYNS: "00351094",
        AFFGEOID: "0500000US13111",
        GEOID: "13111",
        NAME: "Fannin",
        LSAD: "06",
        ALAND: 1002610853,
        AWATER: 13459979
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.621483, 34.988328999113271],
            [-84.509052, 34.988032999113273],
            [-84.321869, 34.988407999113271],
            [-84.129446962635882, 34.98794695306858],
            [-84.178834, 34.952085999113685],
            [-84.107422, 34.886895999114415],
            [-84.093193, 34.80141099911539],
            [-84.158035, 34.648242999117137],
            [-84.188557, 34.602691999117681],
            [-84.196754, 34.617923999117487],
            [-84.343661, 34.824780999115127],
            [-84.422754, 34.85479499911478],
            [-84.618637, 34.855397999114778],
            [-84.622731, 34.875076999114555],
            [-84.621483, 34.988328999113271]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "087",
        COUNTYNS: "00485008",
        AFFGEOID: "0500000US20087",
        GEOID: "20087",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 1379359782,
        AWATER: 62427887
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.589472, 39.216080999075608],
            [-95.589041, 39.404585999074385],
            [-95.570351, 39.419049999074296],
            [-95.180891, 39.419217999074299],
            [-95.180125, 39.128888999076189],
            [-95.187103, 39.044108999076755],
            [-95.285425, 39.032161999076834],
            [-95.419439, 39.067204999076608],
            [-95.500254, 39.056655999076668],
            [-95.596759, 39.064671999076623],
            [-95.589472, 39.216080999075608]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "037",
        COUNTYNS: "00451675",
        AFFGEOID: "0500000US18037",
        GEOID: "18037",
        NAME: "Dubois",
        LSAD: "06",
        ALAND: 1106626119,
        AWATER: 20883162
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.072369, 38.514699999080484],
            [-86.947663, 38.490452999080659],
            [-86.924186, 38.505357999080559],
            [-86.888634, 38.494984999080636],
            [-86.837462, 38.52646799908041],
            [-86.682359, 38.526377999080395],
            [-86.681424, 38.394766999081376],
            [-86.679511, 38.263085999082378],
            [-86.792152, 38.249099999082475],
            [-86.791497, 38.205129999082814],
            [-87.01749, 38.203579999082827],
            [-87.073067, 38.232595999082605],
            [-87.072369, 38.514699999080484]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "069",
        COUNTYNS: "00974133",
        AFFGEOID: "0500000US36069",
        GEOID: "36069",
        NAME: "Ontario",
        LSAD: "06",
        ALAND: 1668114449,
        AWATER: 47820988
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.61167, 42.763168999059516],
            [-77.580377, 42.943962999059075],
            [-77.482517, 42.943163999059081],
            [-77.485418, 43.034563999058868],
            [-77.371478, 43.034695999058883],
            [-77.246804, 43.034662999058881],
            [-77.215839, 43.03984399905886],
            [-77.133397, 43.012462999058933],
            [-76.963926, 43.013156999058928],
            [-76.981334, 42.861212999059269],
            [-76.971392, 42.76422299905952],
            [-77.313004, 42.761264999059513],
            [-77.337605, 42.732665999059591],
            [-77.366996, 42.655069999059791],
            [-77.366505, 42.576367999060011],
            [-77.455632, 42.576894999060009],
            [-77.490889, 42.577287999060005],
            [-77.486875, 42.670278999059754],
            [-77.598815, 42.671964999059746],
            [-77.61167, 42.763168999059516]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "005",
        COUNTYNS: "00659448",
        AFFGEOID: "0500000US27005",
        GEOID: "27005",
        NAME: "Becker",
        LSAD: "06",
        ALAND: 3406166398,
        AWATER: 336840617
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.194671, 47.151148999059536],
            [-96.067069, 47.151812999059537],
            [-95.551186, 47.151452999059536],
            [-95.169172, 47.152514999059541],
            [-95.16301, 46.80471999905874],
            [-95.16371, 46.717639999058555],
            [-95.247999, 46.716254999058549],
            [-96.174587, 46.717853999058555],
            [-96.174108, 46.977374999059116],
            [-96.194671, 47.151148999059536]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "035",
        COUNTYNS: "00758472",
        AFFGEOID: "0500000US29035",
        GEOID: "29035",
        NAME: "Carter",
        LSAD: "06",
        ALAND: 1314052209,
        AWATER: 4305247
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.22283, 36.883856999093936],
            [-91.21715, 37.086196999092117],
            [-91.017766, 37.095676999092028],
            [-90.964466, 37.053583999092403],
            [-90.779553, 37.050323999092434],
            [-90.729722, 37.049533999092432],
            [-90.678562, 36.926489999093548],
            [-90.661244, 36.882908999093949],
            [-90.662617, 36.811452999094612],
            [-91.115926, 36.823671999094486],
            [-91.224825, 36.825678999094478],
            [-91.22283, 36.883856999093936]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "093",
        COUNTYNS: "00835868",
        AFFGEOID: "0500000US31093",
        GEOID: "31093",
        NAME: "Howard",
        LSAD: "06",
        ALAND: 1474578440,
        AWATER: 16199404
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.74433, 41.394177999064027],
            [-98.291419, 41.393893999064034],
            [-98.286584, 41.393897999064031],
            [-98.287168, 41.046362999065522],
            [-98.721975, 41.046673999065511],
            [-98.748526, 41.046639999065512],
            [-98.74433, 41.394177999064027]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "079",
        COUNTYNS: "01715847",
        AFFGEOID: "0500000US30079",
        GEOID: "30079",
        NAME: "Prairie",
        LSAD: "06",
        ALAND: 4497780202,
        AWATER: 15092932
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.086777, 47.180937999059609],
            [-105.959819, 47.180999999059622],
            [-105.959897, 47.092967999059397],
            [-105.832637, 47.093108999059389],
            [-105.833044, 47.181091999059625],
            [-105.406309, 47.181582999059607],
            [-105.324853, 47.181495999059614],
            [-105.324757, 46.97719599905912],
            [-105.196735, 46.977171999059109],
            [-105.197011, 46.919191999058981],
            [-105.069524, 46.919171999058982],
            [-105.027526, 46.861456999058852],
            [-104.603766, 46.860852999058856],
            [-104.607307, 46.831876999058792],
            [-104.606903, 46.684923999058498],
            [-104.732843, 46.656263999058432],
            [-104.733059, 46.612694999058334],
            [-104.858738, 46.612556999058341],
            [-104.858749, 46.569114999058257],
            [-104.98474, 46.569385999058262],
            [-104.984714, 46.540388999058209],
            [-105.239238, 46.541187999058209],
            [-105.239058, 46.570264999058267],
            [-105.449196, 46.570966999058264],
            [-105.491919, 46.600084999058325],
            [-105.492002, 46.658116999058436],
            [-105.576063, 46.658002999058432],
            [-105.618097, 46.744931999058601],
            [-105.618158, 46.83214799905879],
            [-105.832674, 46.860819999058847],
            [-106.086585, 46.860289999058857],
            [-106.086777, 47.180937999059609]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "065",
        COUNTYNS: "01719593",
        AFFGEOID: "0500000US30065",
        GEOID: "30065",
        NAME: "Musselshell",
        LSAD: "06",
        ALAND: 4840577752,
        AWATER: 7118682
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.00957, 46.75070199905862],
            [-108.631462, 46.749391999058624],
            [-107.827687, 46.755874999058626],
            [-107.792939, 46.679829999058477],
            [-107.837732, 46.596646999058308],
            [-107.795473, 46.596651999058317],
            [-107.781214, 46.496013999058135],
            [-107.782087, 46.395181999057954],
            [-107.928547, 46.395283999057952],
            [-108.011948, 46.336812999057862],
            [-108.029363, 46.264054999057755],
            [-108.320812, 46.263996999057753],
            [-108.404856, 46.235959999057712],
            [-108.404795, 46.13295199905756],
            [-108.779733, 46.132456999057567],
            [-108.779272, 46.277397999057769],
            [-108.862277, 46.364585999057908],
            [-108.860801, 46.451664999058053],
            [-108.904904, 46.576907999058271],
            [-108.988995, 46.577850999058278],
            [-109.00957, 46.75070199905862]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "035",
        COUNTYNS: "01008543",
        AFFGEOID: "0500000US37035",
        GEOID: "37035",
        NAME: "Catawba",
        LSAD: "06",
        ALAND: 1039550614,
        AWATER: 37945362
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.432898, 35.677846999105782],
            [-81.363796, 35.76780199910484],
            [-81.353514, 35.776267999104753],
            [-81.334272, 35.796280999104553],
            [-81.264611, 35.805211999104465],
            [-81.248998, 35.815715999104356],
            [-81.143053, 35.82788699910423],
            [-81.109507, 35.776593999104747],
            [-81.061328, 35.710640999105436],
            [-81.005321, 35.71020899910544],
            [-80.994445, 35.702109999105524],
            [-80.990928, 35.694139999105609],
            [-80.982037, 35.682491999105736],
            [-80.966305, 35.67031299910586],
            [-80.951241, 35.641412999106159],
            [-80.943817, 35.637517999106208],
            [-80.92754, 35.622977999106361],
            [-80.933483, 35.591328999106686],
            [-80.960025, 35.547019999107171],
            [-81.535403, 35.568136999106947],
            [-81.432898, 35.677846999105782]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "079",
        COUNTYNS: "01074052",
        AFFGEOID: "0500000US39079",
        GEOID: "39079",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 1088598925,
        AWATER: 3167846
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.785891, 39.168768999075915],
            [-82.762892, 39.207966999075666],
            [-82.536306, 39.180756999075861],
            [-82.540282, 39.14447099907607],
            [-82.424677, 39.137971999076129],
            [-82.435309, 39.035078999076802],
            [-82.455179, 38.844576999078122],
            [-82.575583, 38.844477999078123],
            [-82.650039, 38.849072999078089],
            [-82.76069, 38.854874999078049],
            [-82.753391, 38.945372999077428],
            [-82.80709, 38.948072999077411],
            [-82.785891, 39.168768999075915]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "015",
        COUNTYNS: "00863368",
        AFFGEOID: "0500000US32015",
        GEOID: "32015",
        NAME: "Lander",
        LSAD: "06",
        ALAND: 14219341058,
        AWATER: 76104322
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.806183, 39.145844999076076],
            [-117.714056, 39.245557999075423],
            [-117.759795, 39.369224999074618],
            [-117.646011, 39.426601999074251],
            [-117.656397, 39.468042999074001],
            [-117.59024, 39.517520999073668],
            [-117.485336, 39.545321999073515],
            [-117.461786, 39.622443999073056],
            [-117.495344, 39.748363999072275],
            [-117.46164, 39.88681699907147],
            [-117.541749, 40.001033999070806],
            [-117.300597, 40.526135999068003],
            [-117.246788, 40.642444999067408],
            [-117.018664, 40.643034999067417],
            [-117.018419, 41.00025399906572],
            [-116.586786, 41.000397999065719],
            [-116.586996, 40.964775999065886],
            [-116.596815, 40.946459999065965],
            [-116.594214, 40.320250999069074],
            [-116.599986, 40.07230699907042],
            [-116.592855, 39.374927999074593],
            [-116.600946, 39.161462999075972],
            [-117.331857, 39.163429999075959],
            [-117.775468, 39.093424999076419],
            [-117.806183, 39.145844999076076]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "069",
        COUNTYNS: "01135865",
        AFFGEOID: "0500000US41069",
        GEOID: "41069",
        NAME: "Wheeler",
        LSAD: "06",
        ALAND: 4444476933,
        AWATER: 1241033
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.49516, 45.068278999056773],
            [-119.790699, 45.067822999056773],
            [-119.791162, 44.994754999056759],
            [-119.672228, 44.995116999056769],
            [-119.652431, 44.823298999056775],
            [-119.655517, 44.307044999056998],
            [-119.899002, 44.306619999056991],
            [-119.899283, 44.389574999056947],
            [-120.020708, 44.389686999056941],
            [-120.021621, 44.438792999056908],
            [-120.386074, 44.436082999056921],
            [-120.386561, 44.564008999056838],
            [-120.404676, 44.794718999056784],
            [-120.371441, 44.821768999056772],
            [-120.455204, 44.865606999056759],
            [-120.49516, 45.068278999056773]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "081",
        COUNTYNS: "01639756",
        AFFGEOID: "0500000US47081",
        GEOID: "47081",
        NAME: "Hickman",
        LSAD: "06",
        ALAND: 1586324705,
        AWATER: 373710
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.71676, 35.83874999910411],
            [-87.706438, 35.905754999103429],
            [-87.53453, 35.993073999102535],
            [-87.204242, 35.959185999102864],
            [-87.201426, 35.940578999103067],
            [-87.215099, 35.850650999103991],
            [-87.25561, 35.700515999105548],
            [-87.339232, 35.659109999105979],
            [-87.459587, 35.615123999106451],
            [-87.586867, 35.653303999106043],
            [-87.658189, 35.609348999106508],
            [-87.74375, 35.663951999105933],
            [-87.71676, 35.83874999910411]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "207",
        COUNTYNS: "01383889",
        AFFGEOID: "0500000US48207",
        GEOID: "48207",
        NAME: "Haskell",
        LSAD: "06",
        ALAND: 2339103815,
        AWATER: 18465208
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.99098, 33.3974039991325],
            [-99.472444, 33.399022999132484],
            [-99.471258, 32.957024999138298],
            [-99.612001, 32.956961999138294],
            [-99.988827, 32.960120999138255],
            [-99.99098, 33.3974039991325]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "031",
        COUNTYNS: "01210234",
        AFFGEOID: "0500000US42031",
        GEOID: "42031",
        NAME: "Clarion",
        LSAD: "06",
        ALAND: 1556152465,
        AWATER: 23335885
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.694984, 41.172864999064956],
            [-79.604161, 41.321477999064335],
            [-79.47814, 41.335707999064262],
            [-79.477822, 41.38647899906406],
            [-79.400439, 41.386578999064049],
            [-79.400269, 41.436144999063856],
            [-79.207196, 41.430921999063884],
            [-79.208878, 41.331860999064283],
            [-79.211173, 41.112574999065224],
            [-79.215214, 41.050514999065499],
            [-79.284998, 41.009294999065695],
            [-79.620787, 40.975166999065834],
            [-79.679288, 41.11624199906521],
            [-79.690711, 41.170690999064973],
            [-79.694984, 41.172864999064956]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "407",
        COUNTYNS: "01383989",
        AFFGEOID: "0500000US48407",
        GEOID: "48407",
        NAME: "San Jacinto",
        LSAD: "06",
        ALAND: 1474332262,
        AWATER: 151949236
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.32748, 30.859546999168689],
            [-95.252385, 30.906718999167968],
            [-95.20018, 30.824565999169238],
            [-95.131205, 30.781702999169909],
            [-95.135384, 30.693815999171292],
            [-95.054602, 30.680172999171511],
            [-94.965729, 30.576585999173144],
            [-94.829984, 30.541111999173701],
            [-94.849414, 30.493552999174458],
            [-94.85252, 30.482906999174624],
            [-95.165897, 30.344975999176842],
            [-95.226139, 30.319539999177252],
            [-95.203813, 30.352094999176721],
            [-95.308395, 30.405749999175864],
            [-95.359156, 30.504368999174286],
            [-95.32748, 30.859546999168689]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "005",
        COUNTYNS: "01034216",
        AFFGEOID: "0500000US38005",
        GEOID: "38005",
        NAME: "Benson",
        LSAD: "06",
        ALAND: 3596568808,
        AWATER: 131708144
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.846613, 48.371297999063479],
            [-99.492919, 48.370945999063473],
            [-99.200306, 48.370657999063461],
            [-99.199911, 48.155308999062655],
            [-99.070443, 48.107856999062477],
            [-99.070351, 48.021954999062167],
            [-99.001038, 48.053293999062284],
            [-98.991253, 47.990259999062054],
            [-98.803893, 48.059900999062322],
            [-98.75061, 47.982455999062033],
            [-98.79456, 47.951613999061934],
            [-98.672086, 48.000399999062097],
            [-98.625384, 47.916398999061812],
            [-98.525555, 47.915055999061799],
            [-98.525516, 47.846275999061561],
            [-98.556378, 47.847458999061573],
            [-99.297204, 47.846810999061567],
            [-99.812167, 47.847256999061564],
            [-99.812133, 48.021450999062168],
            [-99.846613, 48.371297999063479]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "039",
        COUNTYNS: "01101807",
        AFFGEOID: "0500000US40039",
        GEOID: "40039",
        NAME: "Custer",
        LSAD: "06",
        ALAND: 2561032754,
        AWATER: 34321688
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.375817, 35.812187999104388],
            [-98.631985, 35.812401999104381],
            [-98.632166, 35.56370999910699],
            [-98.623149, 35.551596999107105],
            [-98.623336, 35.464224999108048],
            [-99.364217, 35.465327999108034],
            [-99.364141, 35.50879299910757],
            [-99.377052, 35.551740999107125],
            [-99.375817, 35.812187999104388]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "005",
        COUNTYNS: "01155127",
        AFFGEOID: "0500000US41005",
        GEOID: "41005",
        NAME: "Clackamas",
        LSAD: "06",
        ALAND: 4845049025,
        AWATER: 31834351
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.867891, 45.317344999056829],
            [-122.743741, 45.332066999056835],
            [-122.743721, 45.433293999056893],
            [-122.705021, 45.433036999056895],
            [-122.660979, 45.457818999056911],
            [-122.514243, 45.461259999056907],
            [-121.852796, 45.460235999056898],
            [-121.819587, 45.46167499905691],
            [-121.696925, 45.373563999056856],
            [-121.696479, 45.25791999905681],
            [-121.681525, 45.248817999056811],
            [-121.731438, 45.170632999056778],
            [-121.652638, 45.101991999056771],
            [-121.65871, 45.06635499905677],
            [-121.804426, 45.012876999056765],
            [-121.734223, 44.885719999056761],
            [-122.401021, 44.885719999056761],
            [-122.535999, 44.937160999056765],
            [-122.595523, 45.019678999056772],
            [-122.705115, 45.059022999056765],
            [-122.787007, 45.128624999056775],
            [-122.73903, 45.259358999056815],
            [-122.849925, 45.259696999056821],
            [-122.867891, 45.317344999056829]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "061",
        COUNTYNS: "01164165",
        AFFGEOID: "0500000US41061",
        GEOID: "41061",
        NAME: "Union",
        LSAD: "06",
        ALAND: 5275597066,
        AWATER: 4989941
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-118.697766, 45.345646999056854],
            [-118.428329, 45.355189999056854],
            [-118.428539, 45.429075999056884],
            [-118.197876, 45.427203999056893],
            [-118.197955, 45.47039999905693],
            [-118.116461, 45.470731999056916],
            [-118.117194, 45.688606999057072],
            [-118.066383, 45.688394999057081],
            [-118.045003, 45.817072999057189],
            [-117.972948, 45.816895999057195],
            [-117.97298, 45.860594999057227],
            [-117.747327, 45.861071999057245],
            [-117.74765, 45.773624999057148],
            [-117.788395, 45.773514999057156],
            [-117.787623, 45.689566999057078],
            [-117.727225, 45.616989999057012],
            [-117.727235, 45.514779999056941],
            [-117.663391, 45.365748999056869],
            [-117.582344, 45.338095999056847],
            [-117.522249, 45.267730999056816],
            [-117.520941, 45.209767999056801],
            [-117.476684, 45.16653899905679],
            [-117.266006, 45.16653899905679],
            [-117.266573, 45.080569999056763],
            [-117.563486, 45.079445999056766],
            [-117.564033, 44.992865999056761],
            [-117.777504, 44.992160999056757],
            [-117.84343, 45.058476999056765],
            [-117.969083, 44.995830999056757],
            [-118.130351, 45.045023999056774],
            [-118.244662, 44.958395999056769],
            [-118.364214, 44.990762999056763],
            [-118.4361, 44.962419999056763],
            [-118.518689, 44.995876999056755],
            [-118.518687, 45.080689999056773],
            [-118.546632, 45.196897999056787],
            [-118.655992, 45.196927999056797],
            [-118.656102, 45.257728999056816],
            [-118.697766, 45.345646999056854]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "029",
        COUNTYNS: "01265791",
        AFFGEOID: "0500000US46029",
        GEOID: "46029",
        NAME: "Codington",
        LSAD: "06",
        ALAND: 1780837027,
        AWATER: 76608850
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.494254, 45.151630999056778],
            [-97.249127, 45.151169999056791],
            [-97.226281, 45.151825999056783],
            [-96.883948, 45.150223999056784],
            [-96.882345, 44.976869999056767],
            [-96.88457, 44.804435999056778],
            [-97.491346, 44.804034999056775],
            [-97.494254, 45.151630999056778]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "117",
        COUNTYNS: "01266998",
        AFFGEOID: "0500000US46117",
        GEOID: "46117",
        NAME: "Stanley",
        LSAD: "06",
        ALAND: 3741060714,
        AWATER: 188383669
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.155188, 44.343146999056962],
            [-101.154516, 44.689946999056801],
            [-101.136838, 44.747125999056792],
            [-101.022611, 44.724152999056805],
            [-100.910312, 44.783736999056785],
            [-100.717644, 44.770929999056769],
            [-100.613805, 44.707134999056798],
            [-100.620897, 44.566697999056849],
            [-100.526498, 44.547421999056866],
            [-100.580034, 44.461841999056908],
            [-100.386376, 44.440007999056917],
            [-100.36539, 44.35552399905697],
            [-100.08533, 44.308450999056987],
            [-99.892101, 44.241880999057045],
            [-99.938217, 44.195194999057094],
            [-100.367247, 44.194620999057086],
            [-100.367207, 44.168693999057112],
            [-101.046916, 44.168483999057123],
            [-101.166753, 44.168084999057122],
            [-101.155188, 44.343146999056962]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "121",
        COUNTYNS: "01101848",
        AFFGEOID: "0500000US40121",
        GEOID: "40121",
        NAME: "Pittsburg",
        LSAD: "06",
        ALAND: 3381112561,
        AWATER: 187439435
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.092001, 34.767478999115781],
            [-96.088861, 35.049757999112579],
            [-95.98371, 35.151556999111456],
            [-95.907966, 35.143493999111534],
            [-95.870663, 35.185985999111075],
            [-95.75597, 35.177197999111158],
            [-95.615664, 35.249697999110367],
            [-95.602342, 35.220738999110694],
            [-95.45125, 35.296018999109869],
            [-95.454954, 35.160576999111349],
            [-95.349339, 35.160295999111355],
            [-95.349233, 35.058513999112478],
            [-95.507658, 35.029196999112813],
            [-95.514514, 34.681141999116782],
            [-95.514315, 34.594152999117789],
            [-95.671976, 34.593851999117796],
            [-95.881987, 34.593527999117789],
            [-95.882103, 34.680474999116775],
            [-96.092043, 34.680555999116777],
            [-96.092001, 34.767478999115781]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "507",
        COUNTYNS: "01384039",
        AFFGEOID: "0500000US48507",
        GEOID: "48507",
        NAME: "Zavala",
        LSAD: "06",
        ALAND: 3360268880,
        AWATER: 11208707
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.111406, 29.086317999197881],
            [-99.41388, 29.091334999197795],
            [-99.409021, 28.640565999205702],
            [-100.114336, 28.648121999205571],
            [-100.111406, 29.086317999197881]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "339",
        COUNTYNS: "01383955",
        AFFGEOID: "0500000US48339",
        GEOID: "48339",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 2700317493,
        AWATER: 88817300
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.83024, 30.630283999172285],
            [-95.598971, 30.509001999174213],
            [-95.359156, 30.504368999174286],
            [-95.308395, 30.405749999175864],
            [-95.203813, 30.352094999176721],
            [-95.226139, 30.319539999177252],
            [-95.165897, 30.344975999176842],
            [-95.096708, 30.167213999179708],
            [-95.292681, 30.027760999181989],
            [-95.405443, 30.092075999180942],
            [-95.491123, 30.110367999180639],
            [-95.500893, 30.1434029991801],
            [-95.551421, 30.17060599917966],
            [-95.57463, 30.151782999179964],
            [-95.691213, 30.098727999180834],
            [-95.80333, 30.090095999180964],
            [-95.804306, 30.245569999178446],
            [-95.83024, 30.630283999172285]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "045",
        COUNTYNS: "01529221",
        AFFGEOID: "0500000US53045",
        GEOID: "53045",
        NAME: "Mason",
        LSAD: "06",
        ALAND: 2484806998,
        AWATER: 237381766
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.505917, 47.6046149990608],
            [-122.950621, 47.60630799906081],
            [-123.038404, 47.520235999060539],
            [-122.801012, 47.520405999060543],
            [-122.801199, 47.403577999060204],
            [-122.852917, 47.256461999059809],
            [-122.820576, 47.194219999059655],
            [-122.902417, 47.157380999059562],
            [-122.936283, 47.18945699905963],
            [-122.947046, 47.185640999059629],
            [-122.952819, 47.170102999059587],
            [-122.969123, 47.161524999059566],
            [-123.002435, 47.151301999059548],
            [-123.010456, 47.143325999059513],
            [-123.015389, 47.12379199905947],
            [-123.034109, 47.115040999059453],
            [-123.060583, 47.114024999059446],
            [-123.073654, 47.106241999059421],
            [-123.074957, 47.090843999059395],
            [-123.102984, 47.08526899905938],
            [-123.201891, 47.085058999059385],
            [-123.488943, 47.082645999059373],
            [-123.505021, 47.258534999059819],
            [-123.505917, 47.517715999060549],
            [-123.505917, 47.6046149990608]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "161",
        COUNTYNS: "01639792",
        AFFGEOID: "0500000US47161",
        GEOID: "47161",
        NAME: "Stewart",
        LSAD: "06",
        ALAND: 1189687496,
        AWATER: 87209797
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.070532, 36.678117999095846],
            [-88.011792, 36.677024999095863],
            [-87.849567, 36.66370099909598],
            [-87.853204, 36.633246999096272],
            [-87.694185907864281, 36.636838243039932],
            [-87.64115, 36.638035999096218],
            [-87.64114589700209, 36.638036046706326],
            [-87.592358, 36.367662999098819],
            [-87.715494, 36.368239999098812],
            [-87.821816, 36.328723999099196],
            [-87.98074, 36.353306999098955],
            [-87.99092, 36.360132999098887],
            [-88.061428, 36.443991999098074],
            [-88.053350800775888, 36.49999624977913],
            [-88.050466, 36.500052999097541],
            [-88.033802, 36.551732999097041],
            [-88.055738, 36.630474999096293],
            [-88.070532, 36.678117999095846]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "037",
        COUNTYNS: "01609192",
        AFFGEOID: "0500000US56037",
        GEOID: "56037",
        NAME: "Sweetwater",
        LSAD: "06",
        ALAND: 27005738206,
        AWATER: 166246301
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.053708, 42.27074399906089],
            [-109.496675, 42.263317999060916],
            [-109.043864, 42.263683999060916],
            [-108.756031, 42.262874999060919],
            [-107.639128, 42.261355999060932],
            [-107.522722, 42.261755999060917],
            [-107.522321, 42.087956999061475],
            [-107.50972, 41.657451999062992],
            [-107.929736, 41.659595999062986],
            [-107.92988, 41.39852999906401],
            [-107.918418289848987, 41.00122706970982],
            [-108.250649, 41.000113999065725],
            [-109.050076, 41.000658999065735],
            [-109.250735, 41.001008999065725],
            [-109.715409, 40.998190999065734],
            [-110.000716736441007, 40.99742545877394],
            [-110.048482995685006, 40.997297291916333],
            [-110.048, 41.578014999063299],
            [-110.053708, 42.27074399906089]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "031",
        COUNTYNS: "01265811",
        AFFGEOID: "0500000US46031",
        GEOID: "46031",
        NAME: "Corson",
        LSAD: "06",
        ALAND: 6396478456,
        AWATER: 155287099
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.000679921018005, 45.944538391784825],
            [-101.998617078234005, 45.944536976414234],
            [-101.794606, 45.944396999057318],
            [-101.557276, 45.944099999057322],
            [-101.365283, 45.944091999057328],
            [-101.106826, 45.94398399905733],
            [-100.76211, 45.943766999057324],
            [-100.511949026151015, 45.943654069491828],
            [-100.511793, 45.943653999057325],
            [-100.499354215383988, 45.943631997871627],
            [-100.387036, 45.869141999057234],
            [-100.308849, 45.689633999057072],
            [-100.406438, 45.650010999057045],
            [-100.430079, 45.594822999056994],
            [-100.486561, 45.536520999056954],
            [-100.341924, 45.472903999056918],
            [-101.47019, 45.472415999056913],
            [-101.99979, 45.472413999056918],
            [-102.000679921018005, 45.944538391784825]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "175",
        COUNTYNS: "01383873",
        AFFGEOID: "0500000US48175",
        GEOID: "48175",
        NAME: "Goliad",
        LSAD: "06",
        ALAND: 2206698586,
        AWATER: 19050015
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.77853, 28.668026999205217],
            [-97.574639, 28.813299999202655],
            [-97.41734, 28.925227999200686],
            [-97.305916, 28.864036999201765],
            [-97.202759, 28.853848999201936],
            [-97.158933, 28.776155999203315],
            [-97.182359, 28.610601999206242],
            [-97.160751, 28.55347499920725],
            [-97.375579, 28.388683999210205],
            [-97.551369, 28.450440999209103],
            [-97.558496, 28.514053999207967],
            [-97.696572, 28.541536999207469],
            [-97.77853, 28.668026999205217]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "013",
        COUNTYNS: "01696945",
        AFFGEOID: "0500000US54013",
        GEOID: "54013",
        NAME: "Calhoun",
        LSAD: "06",
        ALAND: 723252434,
        AWATER: 3704539
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.27269, 38.936337999077494],
            [-81.162455, 39.03060799907685],
            [-81.136467, 39.045110999076755],
            [-81.033632, 39.009583999076987],
            [-81.002777, 38.846024999078118],
            [-81.051909, 38.779995999078572],
            [-80.98495, 38.717897999079007],
            [-81.031677, 38.667838999079379],
            [-81.08371, 38.611981999079774],
            [-81.158582, 38.644036999079539],
            [-81.220791, 38.843603999078141],
            [-81.278412, 38.914866999077638],
            [-81.27269, 38.936337999077494]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "025",
        COUNTYNS: "01605077",
        AFFGEOID: "0500000US56025",
        GEOID: "56025",
        NAME: "Natrona",
        LSAD: "06",
        ALAND: 13831948351,
        AWATER: 90949279
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.543526, 42.781557999059466],
            [-107.501425, 42.781457999059477],
            [-107.502327, 43.128059999058678],
            [-107.517031, 43.472658999058019],
            [-107.534897, 43.50136199905797],
            [-107.110734, 43.500285999057972],
            [-107.090251, 43.492389999057984],
            [-106.078068, 43.494470999057981],
            [-106.073869, 43.472569999058024],
            [-106.077069, 42.777964999059471],
            [-106.075852, 42.433217999060403],
            [-106.654781, 42.431093999060408],
            [-107.522923, 42.434355999060415],
            [-107.542058, 42.441839999060385],
            [-107.543526, 42.781557999059466]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "013",
        COUNTYNS: "00063758",
        AFFGEOID: "0500000US05013",
        GEOID: "05013",
        NAME: "Calhoun",
        LSAD: "06",
        ALAND: 1627975434,
        AWATER: 9926146
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.718156, 33.551992999130505],
            [-92.735617, 33.624140999129594],
            [-92.647931, 33.663784999129099],
            [-92.58503, 33.736513999128171],
            [-92.583054, 33.802197999127344],
            [-92.334249, 33.795064999127447],
            [-92.341845, 33.742895999128095],
            [-92.330839, 33.707805999128531],
            [-92.311079, 33.49978199913118],
            [-92.378616, 33.314732999133568],
            [-92.350432, 33.298940999133784],
            [-92.431092, 33.269789999134161],
            [-92.567156, 33.3671999991329],
            [-92.653211, 33.433967999132037],
            [-92.705093, 33.43121499913206],
            [-92.716346, 33.481584999131421],
            [-92.776729, 33.537647999130698],
            [-92.718156, 33.551992999130505]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "111",
        COUNTYNS: "01804536",
        AFFGEOID: "0500000US72111",
        GEOID: "72111",
        NAME: "Peñuelas",
        LSAD: "13",
        ALAND: 115558555,
        AWATER: 60210081
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.775358902087888, 17.984431433270945],
            [-66.732995, 18.017493999444365],
            [-66.765131, 18.038979999443793],
            [-66.769049, 18.134982999441235],
            [-66.749618, 18.113663999441805],
            [-66.69407, 18.130533999441358],
            [-66.678087, 18.100699999442149],
            [-66.697034446958497, 17.981973768042511],
            [-66.716957, 17.990343999445088],
            [-66.746248, 17.990348999445086],
            [-66.758467, 17.995180999444962],
            [-66.775358902087888, 17.984431433270945]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "061",
        COUNTYNS: "01480120",
        AFFGEOID: "0500000US51061",
        GEOID: "51061",
        NAME: "Fauquier",
        LSAD: "06",
        ALAND: 1678273823,
        AWATER: 9687703
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.062016, 38.891925999077792],
            [-78.004313, 38.979437999077184],
            [-77.962203, 39.013732999076964],
            [-77.655169, 38.942655999077445],
            [-77.71582, 38.838829999078172],
            [-77.679735, 38.778439999078593],
            [-77.624978, 38.696167999079172],
            [-77.531328, 38.556549999080175],
            [-77.633895, 38.466588999080848],
            [-77.63494, 38.410217999081262],
            [-77.735516, 38.413115999081228],
            [-77.8136, 38.530114999080368],
            [-77.877169, 38.584890999079967],
            [-77.909809, 38.697321999079158],
            [-77.935355, 38.695839999079176],
            [-77.995664, 38.710415999079061],
            [-78.031768, 38.793507999078479],
            [-78.130574, 38.864864999077987],
            [-78.062016, 38.891925999077792]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "127",
        COUNTYNS: "01581123",
        AFFGEOID: "0500000US55127",
        GEOID: "55127",
        NAME: "Walworth",
        LSAD: "06",
        ALAND: 1438141978,
        AWATER: 55217124
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.777076, 42.842693999059328],
            [-88.69812, 42.842633999059316],
            [-88.541535, 42.842995999059326],
            [-88.306384, 42.84209499905932],
            [-88.305891, 42.610816999059907],
            [-88.304692, 42.495608171811732],
            [-88.506912, 42.494882999060238],
            [-88.70737771540179, 42.493587013829831],
            [-88.70738, 42.493586999060234],
            [-88.776495760735187, 42.494136599527636],
            [-88.777137, 42.834487999059334],
            [-88.777076, 42.842693999059328]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "011",
        COUNTYNS: "01531820",
        AFFGEOID: "0500000US53011",
        GEOID: "53011",
        NAME: "Clark",
        LSAD: "06",
        ALAND: 1627728322,
        AWATER: 70264672
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.788086362594996, 45.851007453413317],
            [-122.719767, 45.870902999057257],
            [-122.719712, 45.933255999057309],
            [-122.657632, 45.928133999057323],
            [-122.477104, 45.988088999057368],
            [-122.357765, 45.956914999057332],
            [-122.245856, 46.053852999057455],
            [-122.249197008051993, 45.549998931798164],
            [-122.262625, 45.544320999056957],
            [-122.331502, 45.548240999056965],
            [-122.380302, 45.575940999056982],
            [-122.438674, 45.563584999056964],
            [-122.492259, 45.583280999056988],
            [-122.643907, 45.609738999057008],
            [-122.738109, 45.644137999057044],
            [-122.75644390397099, 45.662421024144649],
            [-122.774511, 45.680436999057065],
            [-122.76651077132999, 45.728662573502305],
            [-122.761451, 45.759162999057139],
            [-122.795605, 45.809999999057197],
            [-122.788086362594996, 45.851007453413317]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "078",
        COUNTYNS: "01581099",
        AFFGEOID: "0500000US55078",
        GEOID: "55078",
        NAME: "Menominee",
        LSAD: "06",
        ALAND: 926201674,
        AWATER: 19038372
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.982176, 45.117732999056777],
            [-88.641159, 45.117345999056774],
            [-88.483732, 45.116886999056781],
            [-88.489149, 44.855444999056765],
            [-88.736194, 44.856375999056759],
            [-88.735548, 44.943177999056758],
            [-88.981319, 44.942744999056757],
            [-88.98168, 45.028916999056769],
            [-88.982176, 45.117732999056777]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "067",
        COUNTYNS: "01383819",
        AFFGEOID: "0500000US48067",
        GEOID: "48067",
        NAME: "Cass",
        LSAD: "06",
        ALAND: 2426706440,
        AWATER: 60517774
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.65226, 33.268860999134169],
            [-94.572463, 33.249825999134423],
            [-94.517868, 33.282095999133993],
            [-94.415739, 33.284016999133968],
            [-94.379409, 33.255650999134346],
            [-94.231333, 33.241072999134531],
            [-94.218517, 33.296935999133801],
            [-94.042945847176, 33.271242083273947],
            [-94.042719, 33.160290999135597],
            [-94.042964, 33.019218999137472],
            [-94.043002621563303, 32.881088978063318],
            [-94.653994, 32.87950599913934],
            [-94.65226, 33.268860999134169]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "071",
        COUNTYNS: "01494551",
        AFFGEOID: "0500000US51071",
        GEOID: "51071",
        NAME: "Giles",
        LSAD: "06",
        ALAND: 925206333,
        AWATER: 11233315
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.980845256892806, 37.300852815280727],
            [-80.919259, 37.306162999090176],
            [-80.835479, 37.33482399908992],
            [-80.883248, 37.383932999089502],
            [-80.865148, 37.419926999089192],
            [-80.858148116819805, 37.421006905810685],
            [-80.857358570189774, 37.42112871308818],
            [-80.836446, 37.424354999089147],
            [-80.770082, 37.372362999089603],
            [-80.664971, 37.414214999089239],
            [-80.544836, 37.474694999088733],
            [-80.469568890504192, 37.4290254650818],
            [-80.444612, 37.328472999089982],
            [-80.430943, 37.316377999090086],
            [-80.500823, 37.275642999090429],
            [-80.606962, 37.244870999090708],
            [-80.80149, 37.179631999091278],
            [-80.854627, 37.147481999091561],
            [-81.014489, 37.275384999090441],
            [-80.980845256892806, 37.300852815280727]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "155",
        COUNTYNS: "00450392",
        AFFGEOID: "0500000US18155",
        GEOID: "18155",
        NAME: "Switzerland",
        LSAD: "06",
        ALAND: 571509742,
        AWATER: 7193732
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.203166, 38.913802999077639],
            [-85.135832, 38.929577999077537],
            [-85.140312, 38.903193999077722],
            [-84.864428518991687, 38.913843640127041],
            [-84.830472, 38.897255999077757],
            [-84.786406, 38.882219999077854],
            [-84.798699822952301, 38.859227389284726],
            [-84.803247, 38.85072299907808],
            [-84.812877, 38.786086999078528],
            [-84.856904, 38.790223999078506],
            [-84.962535, 38.778034999078585],
            [-85.021052141674204, 38.758527323792627],
            [-85.071928, 38.741566999078849],
            [-85.146861, 38.695426999079167],
            [-85.187278, 38.687608999079224],
            [-85.201760622548591, 38.697440790925462],
            [-85.203166, 38.913802999077639]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "021",
        COUNTYNS: "01494613",
        AFFGEOID: "0500000US51021",
        GEOID: "51021",
        NAME: "Bland",
        LSAD: "06",
        ALAND: 926272936,
        AWATER: 2585515
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.462279, 37.035401999092564],
            [-81.270078, 37.093011999092056],
            [-81.32814, 37.138948999091632],
            [-81.225104, 37.234873999090794],
            [-81.112596, 37.278496999090407],
            [-80.996013, 37.29954499909023],
            [-80.980845256892806, 37.300852815280727],
            [-81.014489, 37.275384999090441],
            [-80.854627, 37.147481999091561],
            [-80.930627, 37.115086999091851],
            [-80.912674, 37.073348999092232],
            [-81.114319, 37.022611999092675],
            [-81.224624, 37.047834999092451],
            [-81.379402, 36.95244199909331],
            [-81.43673, 37.010134999092791],
            [-81.462279, 37.035401999092564]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "510",
        COUNTYNS: "01498415",
        AFFGEOID: "0500000US51510",
        GEOID: "51510",
        NAME: "Alexandria",
        LSAD: "25",
        ALAND: 38679307,
        AWATER: 1070269
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.110799, 38.843445999078142],
            [-77.087805, 38.827356999078248],
            [-77.039066356764693, 38.841271888596758],
            [-77.039006, 38.791644999078486],
            [-77.039239885082296, 38.785336324702151],
            [-77.143206, 38.805623999078392],
            [-77.110799, 38.843445999078142]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "009",
        COUNTYNS: "01605070",
        AFFGEOID: "0500000US56009",
        GEOID: "56009",
        NAME: "Converse",
        LSAD: "06",
        ALAND: 11020250155,
        AWATER: 26204242
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.078068, 43.494470999057981],
            [-106.017366, 43.494968999057988],
            [-105.079797, 43.498444999057966],
            [-104.899941, 43.499639999057969],
            [-104.89848, 42.783029999059458],
            [-104.892437, 42.608938999059916],
            [-105.286511, 42.605825999059931],
            [-105.28391, 42.431401999060412],
            [-105.332638, 42.431105999060421],
            [-105.375541, 42.290138999060829],
            [-105.526399, 42.288858999060828],
            [-105.613865, 42.304454999060781],
            [-105.575642, 42.377313999060569],
            [-105.556042, 42.43058299906042],
            [-106.073403, 42.433235999060415],
            [-106.075852, 42.433217999060403],
            [-106.077069, 42.777964999059471],
            [-106.073869, 43.472569999058024],
            [-106.078068, 43.494470999057981]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "011",
        COUNTYNS: "01804485",
        AFFGEOID: "0500000US72011",
        GEOID: "72011",
        NAME: "Añasco",
        LSAD: "13",
        ALAND: 101747426,
        AWATER: 14607646
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.225240248479693, 18.297984662410915],
            [-67.182852, 18.313025999436523],
            [-67.130167, 18.317926999436388],
            [-67.116447, 18.323400999436249],
            [-67.052583, 18.306654999436688],
            [-67.037935, 18.289704999437134],
            [-67.082002, 18.25494599943805],
            [-67.135819, 18.245927999438294],
            [-67.191221277720501, 18.266748883449548],
            [-67.209963, 18.294973999436994],
            [-67.225240248479693, 18.297984662410915]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "031",
        COUNTYNS: "00929107",
        AFFGEOID: "0500000US35031",
        GEOID: "35031",
        NAME: "McKinley",
        LSAD: "06",
        ALAND: 14116799310,
        AWATER: 14078537
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.046796, 35.363605999109126],
            [-109.046296, 35.614250999106446],
            [-109.046024, 35.879799999103696],
            [-109.045871168204982, 36.002701629621434],
            [-108.165051, 36.002838999102437],
            [-107.626511, 36.000287999102461],
            [-107.308667, 35.998555999102479],
            [-107.309386, 35.30568699910976],
            [-107.628788, 35.304332999109775],
            [-107.628313, 35.348313999109294],
            [-107.734241, 35.348144999109294],
            [-107.734224, 35.304986999109772],
            [-108.468683, 35.299242999109829],
            [-108.469402, 34.959080999113596],
            [-109.045851235182994, 34.9598185149503],
            [-109.046355894384007, 35.175507187462379],
            [-109.046796, 35.363605999109126]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "027",
        COUNTYNS: "01622956",
        AFFGEOID: "0500000US26027",
        GEOID: "26027",
        NAME: "Cass",
        LSAD: "06",
        ALAND: 1269334177,
        AWATER: 47180840
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.226094344987388, 41.760015958179004],
            [-86.22294, 42.071483999061527],
            [-85.762943, 42.069326999061538],
            [-85.760186, 41.798813999062467],
            [-85.791334961962789, 41.759051038620107],
            [-85.791363, 41.759050999062616],
            [-86.06256302901059, 41.759653032220605],
            [-86.22607, 41.760015999062617],
            [-86.226094344987388, 41.760015958179004]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "175",
        COUNTYNS: "00451665",
        AFFGEOID: "0500000US18175",
        GEOID: "18175",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1330545619,
        AWATER: 7438838
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.310058, 38.733130999078895],
            [-86.275281, 38.763794999078691],
            [-86.091705, 38.783398999078543],
            [-85.901055, 38.753813999078758],
            [-85.888334, 38.734442999078901],
            [-85.885643, 38.575411999080032],
            [-85.860996, 38.575906999080047],
            [-85.847893, 38.561268999080141],
            [-85.884776, 38.503468999080567],
            [-85.957102, 38.501948999080575],
            [-85.993317, 38.488539999080672],
            [-85.99462, 38.418344999081192],
            [-86.032817, 38.417897999081198],
            [-86.255045, 38.42268499908117],
            [-86.308674, 38.422874999081159],
            [-86.308701, 38.688100999079225],
            [-86.310058, 38.733130999078895]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "007",
        COUNTYNS: "01008534",
        AFFGEOID: "0500000US37007",
        GEOID: "37007",
        NAME: "Anson",
        LSAD: "06",
        ALAND: 1376469217,
        AWATER: 14612134
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.276829, 35.195721999110958],
            [-80.172819, 35.147846999111501],
            [-80.112644, 35.193881999110978],
            [-80.075365, 35.143080999111532],
            [-79.992941, 35.08558999911218],
            [-79.923972, 35.115429999111853],
            [-79.877197, 35.035309999112748],
            [-79.849536, 34.900005999114271],
            [-79.924684253136405, 34.807829018866819],
            [-79.927398038771997, 34.807862589746321],
            [-80.077223, 34.809715999115298],
            [-80.320832571129785, 34.81362082065926],
            [-80.276829, 35.195721999110958]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "011",
        COUNTYNS: "01074019",
        AFFGEOID: "0500000US39011",
        GEOID: "39011",
        NAME: "Auglaize",
        LSAD: "06",
        ALAND: 1039603387,
        AWATER: 1345186
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.45618, 40.684861999067209],
            [-84.396778, 40.684925999067218],
            [-84.222799, 40.685956999067194],
            [-84.107787, 40.643068999067417],
            [-83.880194, 40.644689999067403],
            [-83.879932, 40.538707999067938],
            [-83.993699, 40.535200999067953],
            [-84.002372, 40.483114999068221],
            [-84.339137, 40.481075999068239],
            [-84.3386, 40.379198999068748],
            [-84.434387, 40.354533999068884],
            [-84.455532, 40.363962999068832],
            [-84.455029, 40.451304999068384],
            [-84.455713, 40.567887999067786],
            [-84.45618, 40.684861999067209]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "139",
        COUNTYNS: "00695790",
        AFFGEOID: "0500000US28139",
        GEOID: "28139",
        NAME: "Tippah",
        LSAD: "06",
        ALAND: 1185725576,
        AWATER: 5551259
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.088065, 34.815306999115229],
            [-89.017551, 34.858722999114732],
            [-89.017127011552404, 34.994967611949498],
            [-88.823050501632011, 34.995207044808197],
            [-88.823473, 34.858628999114735],
            [-88.752956, 34.858774999114736],
            [-88.753065, 34.756959999115892],
            [-88.718508, 34.756780999115897],
            [-88.717853, 34.602614999117684],
            [-88.73544, 34.596482999117754],
            [-89.088488, 34.598246999117734],
            [-89.088065, 34.815306999115229]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "001",
        COUNTYNS: "00695726",
        AFFGEOID: "0500000US28001",
        GEOID: "28001",
        NAME: "Adams",
        LSAD: "06",
        ALAND: 1197464269,
        AWATER: 65273640
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.621358, 31.267810999162418],
            [-91.564192, 31.261632999162515],
            [-91.508858, 31.291643999162055],
            [-91.536061, 31.338354999161357],
            [-91.532336, 31.390274999160575],
            [-91.510356, 31.438927999159837],
            [-91.51714, 31.498393999158957],
            [-91.489618, 31.534265999158421],
            [-91.437616, 31.546165999158248],
            [-91.457517, 31.587565999157636],
            [-91.463817, 31.620364999157143],
            [-91.395715, 31.644164999156786],
            [-91.380915, 31.732463999155499],
            [-91.38012452998349, 31.732626952205496],
            [-91.318576, 31.745314999155298],
            [-91.320458733281583, 31.747800613637459],
            [-91.317864, 31.749765999155237],
            [-91.307062, 31.711360999155797],
            [-91.301028, 31.713862999155758],
            [-91.302116, 31.707495999155849],
            [-91.296926, 31.707373999155859],
            [-91.299095, 31.700088999155959],
            [-91.294002, 31.697893999156001],
            [-91.291888, 31.691702999156092],
            [-91.292648, 31.687111999156159],
            [-91.300281, 31.681196999156242],
            [-91.297343, 31.678584999156286],
            [-91.300257, 31.672487999156367],
            [-91.153864, 31.610067999157298],
            [-91.15815, 31.346694999161222],
            [-91.306294, 31.360861999161006],
            [-91.468435, 31.319569999161633],
            [-91.488954, 31.22591999916305],
            [-91.590993893064493, 31.19199856486237],
            [-91.590051, 31.193692999163549],
            [-91.644356, 31.234413999162928],
            [-91.621358, 31.267810999162418]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "101",
        COUNTYNS: "00295739",
        AFFGEOID: "0500000US12101",
        GEOID: "12101",
        NAME: "Pasco",
        LSAD: "06",
        ALAND: 1936400029,
        AWATER: 312907704
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.859624, 28.1741349992141],
            [-82.764103, 28.244344999212824],
            [-82.73146, 28.325074999211353],
            [-82.697433, 28.420165999209644],
            [-82.690795013376089, 28.433341924972108],
            [-82.253439, 28.434823999209385],
            [-82.253293, 28.478490999208599],
            [-82.054694, 28.478396999208602],
            [-82.055819, 28.312793999211586],
            [-82.106206, 28.259239999212557],
            [-82.105853, 28.171649999214146],
            [-82.259741, 28.171461999214149],
            [-82.474056, 28.171934999214148],
            [-82.651165, 28.173265999214117],
            [-82.859384956032898, 28.172175073818643],
            [-82.859624, 28.1741349992141]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "141",
        COUNTYNS: "00516917",
        AFFGEOID: "0500000US21141",
        GEOID: "21141",
        NAME: "Logan",
        LSAD: "06",
        ALAND: 1430014646,
        AWATER: 12688607
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.053164, 37.06101899909234],
            [-86.976399, 37.073587999092226],
            [-86.941391, 37.068980999092268],
            [-86.84535, 37.056512999092362],
            [-86.674462, 36.999765999092887],
            [-86.611586, 36.883056999093952],
            [-86.655888, 36.87625599909402],
            [-86.75134, 36.727119999095386],
            [-86.763290964108492, 36.648720673597914],
            [-86.813037, 36.647646999096125],
            [-87.060826344392893, 36.644770883947764],
            [-87.053164, 37.06101899909234]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "109",
        COUNTYNS: "00758509",
        AFFGEOID: "0500000US29109",
        GEOID: "29109",
        NAME: "Lawrence",
        LSAD: "06",
        ALAND: 1584391270,
        AWATER: 4249327
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.059211, 37.048126999092453],
            [-94.052313, 37.290077999090329],
            [-93.625844, 37.282010999090382],
            [-93.605113, 37.280252999090393],
            [-93.608899, 37.098152999092001],
            [-93.610126, 36.99580999909292],
            [-93.611003, 36.922830999093584],
            [-94.06274, 36.931774999093506],
            [-94.059211, 37.048126999092453]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "041",
        COUNTYNS: "01720048",
        AFFGEOID: "0500000US30041",
        GEOID: "30041",
        NAME: "Hill",
        LSAD: "06",
        ALAND: 7508815856,
        AWATER: 44701996
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.750595, 48.914087999065771],
            [-110.743064, 48.998008111250947],
            [-110.531615, 48.998389999066156],
            [-110.438151, 48.999187999066159],
            [-110.171595, 48.999261999066157],
            [-109.500737, 49.000439999066167],
            [-109.489549778986003, 49.000420802946664],
            [-109.505514, 48.91391099906577],
            [-109.505333, 48.567909999064263],
            [-109.464318, 48.525259999064076],
            [-109.464318, 48.452637999063796],
            [-109.496741, 48.451261999063789],
            [-109.551644, 48.293478999063169],
            [-109.534295, 48.134563999062578],
            [-109.726914, 48.13285899906257],
            [-109.726943, 48.2215249990629],
            [-109.845812, 48.219003999062885],
            [-109.845932, 48.30597099906322],
            [-110.625409, 48.306478999063216],
            [-110.625543, 48.21973099906289],
            [-110.755873, 48.219600999062884],
            [-110.750595, 48.914087999065771]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "255",
        COUNTYNS: "00353055",
        AFFGEOID: "0500000US13255",
        GEOID: "13255",
        NAME: "Spalding",
        LSAD: "06",
        ALAND: 507343333,
        AWATER: 9593612
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.497527, 33.257421999134316],
            [-84.432907, 33.256499999134334],
            [-84.388118, 33.352464999133076],
            [-84.3544, 33.35251399913308],
            [-84.150581, 33.335638999133302],
            [-84.102582, 33.29819099913378],
            [-84.088991, 33.285645999133962],
            [-84.123767, 33.202823999135035],
            [-84.123981, 33.178619999135357],
            [-84.248185, 33.189018999135222],
            [-84.451336, 33.208888999134956],
            [-84.496783, 33.183865999135286],
            [-84.502352, 33.2210549991348],
            [-84.497527, 33.257421999134316]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "009",
        COUNTYNS: "00558424",
        AFFGEOID: "0500000US22009",
        GEOID: "22009",
        NAME: "Avoyelles",
        LSAD: "15",
        ALAND: 2155855604,
        AWATER: 86365500
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.280738, 30.96507099916705],
            [-92.208299, 30.962429999167099],
            [-92.234258, 31.131465999164497],
            [-92.22169, 31.331854999161461],
            [-92.147066, 31.301861999161904],
            [-92.086718, 31.335373999161394],
            [-92.025948, 31.343008999161292],
            [-92.008121, 31.325796999161547],
            [-91.990596, 31.225209999163063],
            [-91.926854, 31.294829999162008],
            [-91.83428, 31.266343999162441],
            [-91.677302, 31.188046999163635],
            [-91.719004, 31.134615999164453],
            [-91.723804, 31.044253999165832],
            [-91.750119, 31.018813999166227],
            [-91.805063, 30.97574599916689],
            [-91.815258, 30.848106999168873],
            [-92.028871, 30.849496999168849],
            [-92.212711, 30.848606999168865],
            [-92.237698, 30.848209999168862],
            [-92.280738, 30.96507099916705]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "055",
        COUNTYNS: "00066857",
        AFFGEOID: "0500000US05055",
        GEOID: "05055",
        NAME: "Greene",
        LSAD: "06",
        ALAND: 1496205815,
        AWATER: 4789586
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.820763, 36.079769999101657],
            [-90.74747, 36.149115999100971],
            [-90.809816, 36.149566999100962],
            [-90.806615, 36.266864999099788],
            [-90.31982, 36.259143999099877],
            [-90.32096, 36.200574999100461],
            [-90.189127982216903, 36.198986607767864],
            [-90.220425, 36.184763999100618],
            [-90.235585, 36.139473999101078],
            [-90.294492, 36.112948999101334],
            [-90.339343, 36.047111999101986],
            [-90.368718, 35.995811999102521],
            [-90.387378, 35.964707999102835],
            [-90.852064, 35.968091999102789],
            [-90.820763, 36.079769999101657]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "111",
        COUNTYNS: "00277320",
        AFFGEOID: "0500000US06111",
        GEOID: "06111",
        NAME: "Ventura",
        LSAD: "06",
        ALAND: 4773302741,
        AWATER: 946030569
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-119.477946706723998, 34.378837557895906],
              [-119.445991, 34.404066999120019],
              [-119.442269, 34.463948999119303],
              [-119.442353, 34.561100999118175],
              [-119.442352, 34.901273999114252],
              [-119.276946, 34.879674999114506],
              [-119.243645, 34.814177999115245],
              [-118.976721, 34.812198999115274],
              [-118.881364, 34.790628999115505],
              [-118.738618, 34.498968999118901],
              [-118.652285, 34.323391999120972],
              [-118.633461, 34.269521999121622],
              [-118.667708, 34.236691999122016],
              [-118.667944, 34.199165999122471],
              [-118.693834, 34.168556999122842],
              [-118.723374, 34.167860999122844],
              [-118.788889, 34.168213999122848],
              [-118.856473, 34.126764999123353],
              [-118.940801, 34.07496699912398],
              [-118.944479511458994, 34.04673895247533],
              [-118.954722, 34.048166999124305],
              [-119.069959, 34.090469999123783],
              [-119.109784, 34.094565999123738],
              [-119.227743, 34.161727999122931],
              [-119.257043, 34.213303999122303],
              [-119.270144, 34.252902999121815],
              [-119.276614251858987, 34.256340424136084],
              [-119.313034, 34.275688999121556],
              [-119.37578, 34.321117999121007],
              [-119.461036, 34.374063999120381],
              [-119.477946706723998, 34.378837557895906]
            ]
          ],
          [
            [
              [-119.470736656944013, 34.053996621228237],
              [-119.442654, 34.054155999124248],
              [-119.364214, 34.050793999124274],
              [-119.363065, 34.000547999124898],
              [-119.391587, 33.994635999124966],
              [-119.487719557382007, 33.996515153514238],
              [-119.470736656944013, 34.053996621228237]
            ]
          ],
          [
            [
              [-119.578942, 33.278627999134045],
              [-119.510489, 33.307268999133662],
              [-119.427171, 33.266022999134201],
              [-119.429559, 33.2281669991347],
              [-119.464725, 33.215431999134879],
              [-119.545872, 33.233405999134639],
              [-119.578942, 33.278627999134045]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "025",
        COUNTYNS: "00277277",
        AFFGEOID: "0500000US06025",
        GEOID: "06025",
        NAME: "Imperial",
        LSAD: "06",
        ALAND: 10817291631,
        AWATER: 790230304
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.105634, 32.721569999141487],
            [-116.103252, 33.074669999136724],
            [-116.08109, 33.074832999136731],
            [-116.085165, 33.425931999132132],
            [-115.467506, 33.42699199913212],
            [-114.830833, 33.430093999132076],
            [-114.629146518188989, 33.433544999131826],
            [-114.635183, 33.422725999132169],
            [-114.673901, 33.418298999132226],
            [-114.725282, 33.4050479991324],
            [-114.707348, 33.376627999132765],
            [-114.707962, 33.323420999133461],
            [-114.723259, 33.288078999133916],
            [-114.674491, 33.25559699913434],
            [-114.678097, 33.230299999134672],
            [-114.679359, 33.159518999135607],
            [-114.706175, 33.105334999136318],
            [-114.670803, 33.037983999137218],
            [-114.628293, 33.031051999137304],
            [-114.575161, 33.036541999137228],
            [-114.517066790281007, 33.024628762705895],
            [-114.511343, 33.023454999137407],
            [-114.481315, 32.972063999138093],
            [-114.47664, 32.923627999138745],
            [-114.463127, 32.901883999139045],
            [-114.468971, 32.845154999139808],
            [-114.531746, 32.782502999140654],
            [-114.570675, 32.747416999141144],
            [-114.617386523961997, 32.741052772317815],
            [-114.667493, 32.734225999141309],
            [-114.705717, 32.741580999141206],
            [-114.719633, 32.718762999141518],
            [-115.000802, 32.699675999141782],
            [-115.465164, 32.667099999142231],
            [-116.04662, 32.623352999142838],
            [-116.106178889050014, 32.618578141778492],
            [-116.105634, 32.721569999141487]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "017",
        COUNTYNS: "00277273",
        AFFGEOID: "0500000US06017",
        GEOID: "06017",
        NAME: "El Dorado",
        LSAD: "06",
        ALAND: 4423290535,
        AWATER: 203328338
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.141009, 38.711979999079063],
            [-121.057993, 38.847845999078096],
            [-121.040511, 38.915537999077635],
            [-121.037502, 38.915688999077638],
            [-120.940281, 38.962338999077311],
            [-120.858903, 38.952255999077387],
            [-120.812295, 39.00013299907706],
            [-120.746395, 39.010351999076981],
            [-120.642458, 38.94440399907743],
            [-120.563747, 38.913704999077652],
            [-120.470985, 38.965506999077277],
            [-120.435299, 39.028162999076862],
            [-120.184098, 39.031013999076848],
            [-120.143663, 39.067284999076598],
            [-120.001975250867005, 39.0674958735449],
            [-120.001014, 38.999573999077057],
            [-119.904315, 38.933323999077508],
            [-119.877287, 38.870192999077943],
            [-119.964948, 38.7759859990786],
            [-120.072392, 38.702766999079124],
            [-120.098322, 38.709028999079081],
            [-120.140084, 38.63837399907959],
            [-120.212787, 38.629370999079647],
            [-120.301721, 38.549108999080232],
            [-120.510596, 38.511466999080497],
            [-120.627604, 38.503142999080573],
            [-120.813554, 38.562192999080146],
            [-121.027507, 38.508291999080527],
            [-121.118617, 38.717117999079015],
            [-121.141009, 38.711979999079063]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "083",
        COUNTYNS: "00347465",
        AFFGEOID: "0500000US13083",
        GEOID: "13083",
        NAME: "Dade",
        LSAD: "06",
        ALAND: 450605326,
        AWATER: 467844
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.605165, 34.984677999113309],
            [-85.474338843134305, 34.983673325904419],
            [-85.384967, 34.982986999113336],
            [-85.363919028931093, 34.983376562253824],
            [-85.43371, 34.875076999114555],
            [-85.450561, 34.831676999115039],
            [-85.450409, 34.759240999115868],
            [-85.49053, 34.699109999116565],
            [-85.490795, 34.669448999116916],
            [-85.534405257815393, 34.623790337553238],
            [-85.561424, 34.750078999115971],
            [-85.582812333785284, 34.860435473592318],
            [-85.595165, 34.92417099911399],
            [-85.605165, 34.984677999113309]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "197",
        COUNTYNS: "01785190",
        AFFGEOID: "0500000US17197",
        GEOID: "17197",
        NAME: "Will",
        LSAD: "06",
        ALAND: 2166099738,
        AWATER: 33374154
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.261273, 41.724533999062736],
            [-88.148194, 41.726116999062732],
            [-88.030352, 41.72889399906272],
            [-88.028985, 41.685517999062881],
            [-88.028557, 41.669914999062954],
            [-87.91193, 41.643932999063047],
            [-87.909377, 41.556818999063381],
            [-87.812262, 41.558290999063381],
            [-87.790408, 41.528040999063485],
            [-87.787302, 41.469838999063725],
            [-87.525409353347499, 41.470280665125422],
            [-87.526768, 41.298176999064431],
            [-87.526767886227489, 41.298051601543634],
            [-88.013919, 41.292446999064438],
            [-88.011812, 41.20560399906482],
            [-88.244155, 41.201545999064834],
            [-88.252219, 41.462752999063753],
            [-88.252231, 41.463065999063758],
            [-88.25429, 41.520720999063506],
            [-88.257274, 41.593468999063241],
            [-88.26081, 41.69584299906284],
            [-88.261273, 41.724533999062736]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "029",
        COUNTYNS: "00450344",
        AFFGEOID: "0500000US18029",
        GEOID: "18029",
        NAME: "Dearborn",
        LSAD: "06",
        ALAND: 790138587,
        AWATER: 6033369
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.132508, 38.948054999077407],
            [-85.065574, 39.307231999075022],
            [-84.818877469275094, 39.305143582634436],
            [-84.820159, 39.227224999075531],
            [-84.820157, 39.105479999076337],
            [-84.860689, 39.078139999076519],
            [-84.897171, 39.05240699907668],
            [-84.877570231124793, 39.03126284317085],
            [-84.998111, 38.996539999077079],
            [-85.097459, 38.935506999077496],
            [-85.132508, 38.948054999077407]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "119",
        COUNTYNS: "00465248",
        AFFGEOID: "0500000US19119",
        GEOID: "19119",
        NAME: "Lyon",
        LSAD: "06",
        ALAND: 1522007138,
        AWATER: 334399
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.598928, 43.500456999057974],
            [-96.45326, 43.500389999057973],
            [-96.198484, 43.500334999057976],
            [-96.05316283450999, 43.500187707215467],
            [-95.860946201572304, 43.499992883963586],
            [-95.861914, 43.257565999058414],
            [-96.531027, 43.259724999058406],
            [-96.559027342709697, 43.257555674676418],
            [-96.578823, 43.291094999058345],
            [-96.530392, 43.300033999058314],
            [-96.524289, 43.347213999058241],
            [-96.521572, 43.385639999058171],
            [-96.594254, 43.434152999058092],
            [-96.584603, 43.469609999058022],
            [-96.598928, 43.500456999057974]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "167",
        COUNTYNS: "00465272",
        AFFGEOID: "0500000US19167",
        GEOID: "19167",
        NAME: "Sioux",
        LSAD: "06",
        ALAND: 1988797652,
        AWATER: 2885657
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.559027342709697, 43.257555674676418],
            [-96.531027, 43.259724999058406],
            [-95.861914, 43.257565999058414],
            [-95.859377, 42.909096999059166],
            [-96.540472329193278, 42.908595697015166],
            [-96.541689, 42.922575999059127],
            [-96.500308, 42.959390999059039],
            [-96.520246, 42.977642999059007],
            [-96.492693, 43.005088999058941],
            [-96.511605, 43.039926999058871],
            [-96.458201, 43.067553999058802],
            [-96.452097516053684, 43.082552924253271],
            [-96.439335, 43.113915999058705],
            [-96.458854, 43.143355999058642],
            [-96.475571, 43.221053999058469],
            [-96.522084, 43.220959999058479],
            [-96.552963, 43.247280999058432],
            [-96.559027342709697, 43.257555674676418]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "25",
        COUNTYFP: "017",
        COUNTYNS: "00606935",
        AFFGEOID: "0500000US25017",
        GEOID: "25017",
        NAME: "Middlesex",
        LSAD: "06",
        ALAND: 2117830644,
        AWATER: 75814940
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.898768771936588, 42.711466719868447],
            [-71.805389602738288, 42.708914942588144],
            [-71.745817, 42.707286999059662],
            [-71.636214, 42.70488799905965],
            [-71.351874, 42.698153999059677],
            [-71.294205, 42.696989999059696],
            [-71.255605, 42.736388999059578],
            [-71.255110121810603, 42.736397052346774],
            [-71.238551, 42.671249999059754],
            [-71.255749, 42.656928999059787],
            [-71.164879, 42.598020999059933],
            [-71.145942, 42.60833499905992],
            [-71.033998, 42.58549299905998],
            [-71.053365, 42.475924999060283],
            [-70.982994, 42.423995999060438],
            [-70.982545601587901, 42.42022179911244],
            [-71.080947, 42.382377999060544],
            [-71.064059, 42.369000999060582],
            [-71.077095, 42.358696999060626],
            [-71.117193, 42.3642289990606],
            [-71.167625, 42.36007299906062],
            [-71.156887, 42.330240999060713],
            [-71.191155, 42.283058999060863],
            [-71.23106, 42.320735999060737],
            [-71.269958, 42.328085999060718],
            [-71.32975, 42.312990999060759],
            [-71.302922, 42.248273999060963],
            [-71.344083, 42.200978999061107],
            [-71.478119, 42.156781999061252],
            [-71.502626, 42.191415999061135],
            [-71.555738, 42.182502999061157],
            [-71.586759, 42.259544999060921],
            [-71.549453, 42.266094999060897],
            [-71.522178, 42.266453999060893],
            [-71.506478, 42.264275999060906],
            [-71.486797, 42.330188999060702],
            [-71.551126, 42.326394999060717],
            [-71.585168, 42.31097699906077],
            [-71.624702, 42.350464999060648],
            [-71.5591, 42.411942999060464],
            [-71.53878, 42.543029999060096],
            [-71.635869, 42.524023999060155],
            [-71.678969, 42.530429999060125],
            [-71.664601, 42.61159799905991],
            [-71.775755, 42.644229999059817],
            [-71.858483, 42.633814999059837],
            [-71.898768771936588, 42.711466719868447]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "091",
        COUNTYNS: "00659490",
        AFFGEOID: "0500000US27091",
        GEOID: "27091",
        NAME: "Martin",
        LSAD: "06",
        ALAND: 1844947511,
        AWATER: 44649915
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.854444, 43.848098999057463],
            [-94.368974, 43.848046999057459],
            [-94.247123, 43.847945999057472],
            [-94.247967441555204, 43.500175475765559],
            [-94.390597, 43.500468999057965],
            [-94.442849989718198, 43.500478506712675],
            [-94.85455506761069, 43.500553418210174],
            [-94.854444, 43.848098999057463]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "33",
        COUNTYFP: "007",
        COUNTYNS: "00873177",
        AFFGEOID: "0500000US33007",
        GEOID: "33007",
        NAME: "Coos",
        LSAD: "06",
        ALAND: 4647904964,
        AWATER: 92563568
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.7631925213134, 44.403568261833335],
            [-71.69092, 44.42123399905693],
            [-71.646551, 44.468868999056895],
            [-71.579974, 44.501777999056877],
            [-71.588076, 44.547849999056851],
            [-71.544922, 44.579277999056828],
            [-71.551722, 44.627597999056832],
            [-71.584574, 44.665350999056812],
            [-71.626909, 44.747223999056779],
            [-71.570402, 44.805275999056782],
            [-71.522393, 44.880810999056756],
            [-71.494403, 44.911836999056767],
            [-71.531605, 44.976022999056759],
            [-71.501088, 45.01337699905676],
            [-71.498399, 45.069628999056768],
            [-71.448678, 45.109000999056775],
            [-71.419058, 45.170487999056796],
            [-71.405636, 45.198138999056795],
            [-71.438546, 45.239003999056813],
            [-71.360664, 45.269834999056819],
            [-71.283684, 45.301976999056841],
            [-71.244499, 45.268138999056823],
            [-71.182587, 45.2410689990568],
            [-71.13943, 45.242957999056813],
            [-71.109349, 45.282221999056823],
            [-71.083924, 45.305450999056831],
            [-71.057861, 45.000048999056759],
            [-71.036705, 44.736497999056787],
            [-71.022992, 44.500057999056871],
            [-71.019461729485499, 44.440363238844412],
            [-71.013575903814598, 44.340837451616466],
            [-71.01127, 44.301845999057008],
            [-71.010271167868297, 44.28488832062903],
            [-71.04228, 44.237513999057057],
            [-71.251957, 44.244265999057049],
            [-71.335882, 44.085751999057202],
            [-71.356482, 44.165835999057123],
            [-71.415898, 44.212168999057077],
            [-71.443338, 44.249171999057054],
            [-71.575819, 44.254595999057045],
            [-71.587004, 44.304758999057],
            [-71.696885, 44.332117999056976],
            [-71.7631925213134, 44.403568261833335]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "023",
        COUNTYNS: "00882230",
        AFFGEOID: "0500000US34023",
        GEOID: "34023",
        NAME: "Middlesex",
        LSAD: "06",
        ALAND: 800857610,
        AWATER: 35472648
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.62024, 40.373748999068788],
            [-74.584987, 40.418175999068545],
            [-74.489491, 40.474451999068272],
            [-74.459863, 40.491045999068177],
            [-74.523917, 40.569669999067777],
            [-74.463291, 40.599186999067634],
            [-74.441719, 40.59708499906764],
            [-74.358717, 40.604060999067606],
            [-74.302921, 40.608682999067589],
            [-74.29099, 40.591924999067665],
            [-74.203688, 40.592690999067663],
            [-74.216839, 40.558617999067835],
            [-74.249211, 40.545063999067914],
            [-74.260611, 40.502435999068119],
            [-74.261889, 40.464705999068315],
            [-74.224652466014689, 40.448662488554895],
            [-74.236332, 40.443443999068428],
            [-74.290883, 40.372977999068773],
            [-74.327377, 40.344598999068936],
            [-74.393384, 40.27963699906929],
            [-74.484962, 40.25332499906942],
            [-74.622462, 40.32479999906905],
            [-74.62024, 40.373748999068788]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "003",
        COUNTYNS: "00882271",
        AFFGEOID: "0500000US34003",
        GEOID: "34003",
        NAME: "Bergen",
        LSAD: "06",
        ALAND: 602859293,
        AWATER: 35406803
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.250458, 41.060815999065454],
            [-74.211617643198593, 41.132981362601434],
            [-74.041054, 41.059087999065454],
            [-73.893981860035879, 40.997198202604643],
            [-73.893979, 40.997196999065736],
            [-73.90728, 40.95149799906595],
            [-73.920471529379299, 40.918606331107092],
            [-73.922030897866492, 40.914718212524917],
            [-73.934892580960678, 40.882648981008366],
            [-73.938081, 40.874698999066304],
            [-73.947484569083201, 40.857773452353776],
            [-73.965834517046304, 40.824745258625136],
            [-73.968082, 40.820699999066548],
            [-73.971208018282397, 40.816315625387475],
            [-73.984592494680186, 40.797543327618968],
            [-73.993689, 40.814039999066587],
            [-73.990798, 40.821440999066553],
            [-73.996108, 40.824413999066529],
            [-73.997476, 40.822323999066548],
            [-74.005341, 40.826944999066527],
            [-74.009938, 40.823340999066538],
            [-74.044923, 40.807554999066618],
            [-74.058549, 40.806276999066633],
            [-74.063837, 40.804151999066633],
            [-74.069032, 40.798348999066661],
            [-74.076753, 40.795620999066678],
            [-74.07985, 40.789725999066711],
            [-74.089143, 40.77959599906675],
            [-74.090098, 40.776882999066764],
            [-74.087979, 40.764439999066823],
            [-74.090787, 40.761983999066835],
            [-74.147529, 40.786490999066714],
            [-74.139575, 40.805408999066628],
            [-74.130016, 40.819937999066561],
            [-74.123773, 40.822756999066549],
            [-74.12099, 40.830538999066519],
            [-74.124035, 40.83871699906647],
            [-74.119238, 40.852042999066406],
            [-74.120199, 40.858714999066365],
            [-74.106786, 40.859298999066382],
            [-74.132337, 40.922429999066082],
            [-74.141048, 40.966347999065874],
            [-74.171141, 40.977065999065836],
            [-74.272226, 41.017934999065645],
            [-74.250458, 41.060815999065454]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "063",
        COUNTYNS: "00974130",
        AFFGEOID: "0500000US36063",
        GEOID: "36063",
        NAME: "Niagara",
        LSAD: "06",
        ALAND: 1352887116,
        AWATER: 1598842827
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.060206, 43.124798999058676],
            [-79.055252440939597, 43.133812265675658],
            [-79.044567, 43.153254999058618],
            [-79.052868, 43.222053999058481],
            [-79.070469, 43.262453999058401],
            [-78.834061, 43.317554999058295],
            [-78.547395, 43.369540999058209],
            [-78.465551187596091, 43.370895421224603],
            [-78.465505, 43.128618999058673],
            [-78.464449, 43.088702999058754],
            [-78.510384, 43.097957999058728],
            [-78.603989, 43.07202499905879],
            [-78.727647, 43.086219999058763],
            [-78.744015, 43.070375999058797],
            [-78.83126, 43.050245999058845],
            [-78.825366, 43.03365599905888],
            [-78.83935, 43.019452999058906],
            [-78.868756, 43.023261999058889],
            [-78.890159, 43.021955999058896],
            [-78.89326, 43.038755999058864],
            [-78.90586, 43.051555999058834],
            [-78.945262, 43.066955999058806],
            [-79.019577884894304, 43.066296466949211],
            [-79.074467, 43.077854999058779],
            [-79.060206, 43.124798999058676]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "051",
        COUNTYNS: "01074038",
        AFFGEOID: "0500000US39051",
        GEOID: "39051",
        NAME: "Fulton",
        LSAD: "06",
        ALAND: 1050092648,
        AWATER: 4621138
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.399548785474195, 41.705921356468401],
            [-84.360419041397293, 41.706955881696302],
            [-84.134417, 41.712930999062785],
            [-83.880390465353287, 41.720194489382358],
            [-83.882943, 41.487542999063649],
            [-84.341902, 41.485518999063665],
            [-84.381312, 41.616729999063153],
            [-84.399548785474195, 41.705921356468401]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "013",
        COUNTYNS: "01101794",
        AFFGEOID: "0500000US40013",
        GEOID: "40013",
        NAME: "Bryan",
        LSAD: "06",
        ALAND: 2342528103,
        AWATER: 102267324
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.624803, 34.009723999124773],
            [-96.548028, 34.063301999124128],
            [-96.585355, 34.114458999123492],
            [-96.453839, 34.114405999123484],
            [-96.40764, 34.157316999122976],
            [-95.991578, 34.156804999122983],
            [-95.93815, 34.125099999123371],
            [-95.918871, 33.925056999125815],
            [-95.78963931775229, 33.872437383798776],
            [-95.820596, 33.858464999126646],
            [-95.844878534070688, 33.860421903845619],
            [-95.887491, 33.863855999126578],
            [-95.935325, 33.875098999126443],
            [-96.063924, 33.841522999126859],
            [-96.15163, 33.831945999126972],
            [-96.173025, 33.800559999127373],
            [-96.229023, 33.748020999128038],
            [-96.277269, 33.769734999127756],
            [-96.307389, 33.735004999128194],
            [-96.363135, 33.694214999128711],
            [-96.379660825952101, 33.715530907694436],
            [-96.403507, 33.746288999128041],
            [-96.436455, 33.78004999912762],
            [-96.502286, 33.773459999127716],
            [-96.523863, 33.818113999127149],
            [-96.572937, 33.819097999127138],
            [-96.592926, 33.830915999126994],
            [-96.590112, 33.880664999126367],
            [-96.594674307377105, 33.883018656524648],
            [-96.57701, 33.96440599912534],
            [-96.624803, 34.009723999124773]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "015",
        COUNTYNS: "01155129",
        AFFGEOID: "0500000US41015",
        GEOID: "41015",
        NAME: "Curry",
        LSAD: "06",
        ALAND: 4217568363,
        AWATER: 934534728
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.552441, 42.840567999059324],
            [-124.480938, 42.951494999059058],
            [-124.479882226931991, 42.954322436736447],
            [-124.258388, 42.954945999059056],
            [-124.219623, 42.933781999059107],
            [-124.141475, 42.850585999059298],
            [-124.159966, 42.739946999059569],
            [-124.132918, 42.666696999059766],
            [-123.996291, 42.685650999059703],
            [-123.925717, 42.774513999059486],
            [-123.811553, 42.788836999059455],
            [-123.783304, 42.799425999059423],
            [-123.716492, 42.784060999059463],
            [-123.831264, 42.632312999059849],
            [-123.890657, 42.500537999060221],
            [-124.008397, 42.496351999060224],
            [-124.041983, 42.387655999060549],
            [-123.888411, 42.351626999060649],
            [-123.7922, 42.237622999060996],
            [-123.858549, 42.13511599906132],
            [-123.822139, 42.08752199906148],
            [-123.822037598587997, 41.995620816008781],
            [-124.001188, 41.996145999061781],
            [-124.211605, 41.998459999061787],
            [-124.270464, 42.045552999061613],
            [-124.314289, 42.067863999061544],
            [-124.351535, 42.129795999061336],
            [-124.361009, 42.180751999061165],
            [-124.383633, 42.227159999061016],
            [-124.410982, 42.250546999060958],
            [-124.410556, 42.307430999060777],
            [-124.425554, 42.351873999060643],
            [-124.435105, 42.440162999060384],
            [-124.399065, 42.539927999060104],
            [-124.400918, 42.597517999059953],
            [-124.413119, 42.657933999059793],
            [-124.45074, 42.675797999059746],
            [-124.448418, 42.689908999059696],
            [-124.510017, 42.734745999059591],
            [-124.552441, 42.840567999059324]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "057",
        COUNTYNS: "01135864",
        AFFGEOID: "0500000US41057",
        GEOID: "41057",
        NAME: "Tillamook",
        LSAD: "06",
        ALAND: 2855122944,
        AWATER: 595798838
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.00977, 45.047265999056769],
            [-123.975425, 45.145475999056785],
            [-123.972919, 45.216783999056801],
            [-123.962887, 45.280217999056823],
            [-123.979715, 45.347723999056861],
            [-123.960557, 45.430777999056893],
            [-123.976544, 45.489732999056933],
            [-123.947556, 45.564877999056968],
            [-123.939005, 45.661922999057047],
            [-123.939448, 45.708794999057091],
            [-123.968563, 45.757018999057131],
            [-123.966278384519001, 45.783084902602454],
            [-123.720001, 45.77307999905716],
            [-123.361622, 45.779578999057165],
            [-123.361028, 45.708695999057092],
            [-123.484726, 45.708763999057084],
            [-123.423273, 45.636175999057038],
            [-123.299438, 45.607246999057011],
            [-123.461181, 45.491559999056932],
            [-123.46488, 45.433331999056897],
            [-123.463518, 45.216311999056785],
            [-123.78454, 45.216293999056802],
            [-123.784218, 45.076654999056771],
            [-123.724368, 45.076225999056781],
            [-123.724663, 45.044431999056776],
            [-124.010097209885004, 45.04499895849797],
            [-124.00977, 45.047265999056769]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "427",
        COUNTYNS: "01383999",
        AFFGEOID: "0500000US48427",
        GEOID: "48427",
        NAME: "Starr",
        LSAD: "06",
        ALAND: 3168019245,
        AWATER: 15346371
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.176816092381102, 26.569660554618164],
            [-99.011112, 26.67502299924249],
            [-98.95423, 26.785693999240319],
            [-98.422616, 26.783534999240366],
            [-98.32067, 26.783080999240369],
            [-98.491943, 26.445361999247019],
            [-98.593300218279197, 26.242936347927664],
            [-98.613465, 26.252027999250881],
            [-98.654221, 26.235959999251204],
            [-98.698856, 26.265618999250606],
            [-98.779912, 26.32654199924939],
            [-98.807348, 26.369420999248533],
            [-98.890965, 26.35756899924877],
            [-98.958325, 26.394055999248042],
            [-99.032316, 26.412081999247679],
            [-99.082002, 26.396509999247996],
            [-99.110855, 26.4262779992474],
            [-99.091635, 26.4769769992464],
            [-99.105031, 26.500334999245936],
            [-99.171404, 26.549847999244946],
            [-99.176816092381102, 26.569660554618164]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "735",
        COUNTYNS: "01498436",
        AFFGEOID: "0500000US51735",
        GEOID: "51735",
        NAME: "Poquoson",
        LSAD: "25",
        ALAND: 39839069,
        AWATER: 163454357
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.412885, 37.155807999091493],
            [-76.347189928967595, 37.189644492746503],
            [-76.3431, 37.186550999091217],
            [-76.311088, 37.138494999091648],
            [-76.293126918301013, 37.11416369997216],
            [-76.38369, 37.111579999091873],
            [-76.395795, 37.107173999091927],
            [-76.412885, 37.155807999091493]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "087",
        COUNTYNS: "01629542",
        AFFGEOID: "0500000US22087",
        GEOID: "22087",
        NAME: "St. Bernard",
        LSAD: "15",
        ALAND: 977746424,
        AWATER: 4604207636
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.012102, 29.945979999183336],
            [-89.845297119572407, 30.016381839752277],
            [-89.852583, 29.952720999183228],
            [-89.795969, 29.934002999183534],
            [-89.744272, 29.917646999183805],
            [-89.701725, 29.874084999184525],
            [-89.647064, 29.863601999184702],
            [-89.598129, 29.881408999184401],
            [-89.574425, 29.983737999182711],
            [-89.494064, 30.040971999181778],
            [-89.444618, 30.060958999181441],
            [-89.342163, 30.059171999181487],
            [-89.303026, 30.091569999180948],
            [-89.233168, 30.13495699918024],
            [-89.183256, 30.149343999180005],
            [-89.185799, 30.063933999181398],
            [-89.215675, 29.993522999182556],
            [-89.231178, 29.925483999183673],
            [-89.236298, 29.877080999184475],
            [-89.293251, 29.803052999185702],
            [-89.271034, 29.756354999186485],
            [-89.399162, 29.770591999186252],
            [-89.403956, 29.681807999187729],
            [-89.465562, 29.651737999188228],
            [-89.500966753133696, 29.633455424247341],
            [-89.5186, 29.64911699918828],
            [-89.628181, 29.680681999187748],
            [-89.721045, 29.757651999186468],
            [-89.86972, 29.795450999185835],
            [-89.910708, 29.867864999184626],
            [-89.921019, 29.915728999183841],
            [-90.012102, 29.945979999183336]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "023",
        COUNTYNS: "01455158",
        AFFGEOID: "0500000US49023",
        GEOID: "49023",
        NAME: "Juab",
        LSAD: "06",
        ALAND: 8784407434,
        AWATER: 36542088
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.047268153076999, 39.906098112827564],
            [-113.248498, 39.904713999071362],
            [-112.341988, 39.904457999071369],
            [-112.332296, 39.952788999071096],
            [-112.269882, 39.938946999071163],
            [-112.1798, 40.011655999070754],
            [-112.053511, 39.893396999071442],
            [-112.089843, 39.781927999072089],
            [-111.969467, 39.807107999071938],
            [-111.909464, 39.894570999071426],
            [-111.82529, 39.947695999071122],
            [-111.811116, 39.93973799907117],
            [-111.73793, 39.864099999071605],
            [-111.766325, 39.810816999071918],
            [-111.641944, 39.81286899907191],
            [-111.584858, 39.784234999072069],
            [-111.584754, 39.740140999072338],
            [-111.717482, 39.694559999072602],
            [-111.713224, 39.46029399907404],
            [-111.747389, 39.416959999074322],
            [-111.747044, 39.382749999074541],
            [-111.922389, 39.381590999074547],
            [-112.016003, 39.314560999074956],
            [-112.188922, 39.329391999074872],
            [-112.212045, 39.553986999073466],
            [-112.354467, 39.553683999073463],
            [-112.462419, 39.552450999073464],
            [-113.426363, 39.552465999073476],
            [-113.815743, 39.552643999073481],
            [-113.839771, 39.543838999073522],
            [-114.047181412925994, 39.542743601303229],
            [-114.047783, 39.794159999072015],
            [-114.047268153076999, 39.906098112827564]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "50",
        COUNTYFP: "027",
        COUNTYNS: "01461770",
        AFFGEOID: "0500000US50027",
        GEOID: "50027",
        NAME: "Windsor",
        LSAD: "06",
        ALAND: 2511193707,
        AWATER: 19363219
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.980158, 43.882586999057423],
            [-72.876556, 43.902764999057396],
            [-72.865283, 43.861655999057447],
            [-72.791336, 43.961854999057316],
            [-72.783208, 43.928966999057366],
            [-72.574443, 43.87334799905743],
            [-72.211502122349899, 43.773019409499256],
            [-72.222069, 43.75983099905757],
            [-72.284805, 43.720359999057635],
            [-72.329522, 43.608392999057799],
            [-72.333596501356297, 43.605591340646903],
            [-72.37944, 43.574068999057857],
            [-72.380894, 43.493393999057979],
            [-72.39689079629089, 43.429051745393203],
            [-72.396923025035903, 43.428922115056693],
            [-72.413377, 43.362740999058218],
            [-72.406901858031688, 43.337448837793062],
            [-72.402532, 43.320379999058297],
            [-72.421583, 43.263441999058401],
            [-72.433611197959493, 43.232903811320263],
            [-72.685637, 43.222494999058476],
            [-72.819368, 43.255497999058413],
            [-72.8199, 43.299209999058334],
            [-72.867902, 43.300741999058332],
            [-72.853439, 43.371075999058192],
            [-72.759799, 43.354143999058223],
            [-72.723995, 43.461693999058049],
            [-72.771543, 43.477861999058007],
            [-72.79107, 43.494999999057988],
            [-72.772829, 43.591564999057823],
            [-72.758005, 43.587516999057833],
            [-72.700945, 43.682830999057693],
            [-72.820043, 43.716833999057641],
            [-72.818605, 43.7463049990576],
            [-72.782812, 43.80433799905753],
            [-72.796653, 43.829386999057483],
            [-72.873366, 43.839632999057471],
            [-72.882038, 43.855332999057438],
            [-72.958853, 43.826541999057483],
            [-72.980158, 43.882586999057423]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "50",
        COUNTYFP: "017",
        COUNTYNS: "01461765",
        AFFGEOID: "0500000US50017",
        GEOID: "50017",
        NAME: "Orange",
        LSAD: "06",
        ALAND: 1779389357,
        AWATER: 12016544
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.791336, 43.961854999057316],
            [-72.742303, 44.02965499905725],
            [-72.683772, 44.012937999057272],
            [-72.62891, 44.088638999057203],
            [-72.601395, 44.134862999057141],
            [-72.589333, 44.160281999057126],
            [-72.443032, 44.128668999057147],
            [-72.419747, 44.220978999057067],
            [-72.368353, 44.204056999057087],
            [-72.30498, 44.183148999057096],
            [-72.17026, 44.192052999057097],
            [-72.053826883614704, 44.159817092860521],
            [-72.036883, 44.103118999057166],
            [-72.075486, 44.03461399905725],
            [-72.079696272847798, 44.030256642441863],
            [-72.079944224002602, 44.030000029237961],
            [-72.116706, 43.991953999057287],
            [-72.105875, 43.949369999057339],
            [-72.16978, 43.873424999057434],
            [-72.183333, 43.808176999057515],
            [-72.211502122349899, 43.773019409499256],
            [-72.574443, 43.87334799905743],
            [-72.783208, 43.928966999057366],
            [-72.791336, 43.961854999057316]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "009",
        COUNTYNS: "00161530",
        AFFGEOID: "0500000US01009",
        GEOID: "01009",
        NAME: "Blount",
        LSAD: "06",
        ALAND: 1670079465,
        AWATER: 15039864
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.963358, 33.858220999126658],
            [-86.924387, 33.909221999126011],
            [-86.793914, 33.952058999125491],
            [-86.685365, 34.05913999912417],
            [-86.692474, 34.092404999123758],
            [-86.601147, 34.119039999123437],
            [-86.56385, 34.170492999122814],
            [-86.518927, 34.252320999121835],
            [-86.45302, 34.259316999121744],
            [-86.303516, 34.099072999123678],
            [-86.332394, 34.040498999124402],
            [-86.370152, 33.93976999912563],
            [-86.325622, 33.940146999125631],
            [-86.345822, 33.882893999126352],
            [-86.405981, 33.835895999126933],
            [-86.577528, 33.801976999127355],
            [-86.577799, 33.765315999127807],
            [-86.64529, 33.773011999127718],
            [-86.759212, 33.840591999126872],
            [-86.883947, 33.84323699912683],
            [-86.953664, 33.815296999127192],
            [-86.963358, 33.858220999126658]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "085",
        COUNTYNS: "00161568",
        AFFGEOID: "0500000US01085",
        GEOID: "01085",
        NAME: "Lowndes",
        LSAD: "06",
        ALAND: 1854201380,
        AWATER: 23721835
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.908302, 32.225027999148388],
            [-86.810313, 32.224746999148401],
            [-86.864367, 32.274917999147682],
            [-86.850412, 32.328946999146922],
            [-86.814912, 32.34080299914676],
            [-86.816107, 32.309969999147192],
            [-86.781354, 32.392493999146026],
            [-86.653419, 32.39724699914597],
            [-86.496774, 32.344436999146701],
            [-86.474479, 32.33154699914688],
            [-86.484678, 32.28447299914756],
            [-86.48063, 32.271457999147728],
            [-86.458512, 32.274091999147686],
            [-86.448746, 32.263594999147848],
            [-86.422236, 32.253716999147983],
            [-86.408771, 32.24430899914811],
            [-86.408272, 32.20897599914862],
            [-86.406276, 32.050730999150865],
            [-86.405005, 31.963774999152125],
            [-86.448198, 31.964628999152104],
            [-86.857583, 31.962166999152149],
            [-86.856576, 32.04835199915091],
            [-86.906956, 32.047969999150915],
            [-86.908302, 32.225027999148388]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "090",
        COUNTYNS: "01419969",
        AFFGEOID: "0500000US02090",
        GEOID: "02090",
        NAME: "Fairbanks North Star",
        LSAD: "04",
        ALAND: 18982307701,
        AWATER: 274103901
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-148.661199, 65.20987499928269],
            [-147.557771, 65.209894999282682],
            [-147.279115, 65.279761999284176],
            [-146.903142, 65.283374999284248],
            [-146.548654, 65.345002999285555],
            [-146.508383, 65.433108999287469],
            [-146.39704, 65.418420999287136],
            [-146.198886, 65.453522999287927],
            [-146.119349, 65.403986999286843],
            [-145.98704, 65.408442999286933],
            [-146.007899, 65.359433999285883],
            [-146.138999, 65.315192999284932],
            [-146.079908, 65.247980999283484],
            [-145.949873, 65.20548299928258],
            [-145.689426, 65.189077999282233],
            [-145.633882, 65.136801999281118],
            [-145.778697, 65.080002999279898],
            [-145.650312, 65.034302999278935],
            [-145.372503, 65.069495999279695],
            [-145.225944, 65.069215999279692],
            [-144.983514, 65.136987999281132],
            [-144.684887, 65.106142999280465],
            [-144.443678, 65.058435999279439],
            [-144.331377, 65.107706999280481],
            [-143.973639, 65.119303999280746],
            [-143.88408, 65.091067999280142],
            [-144.098484, 65.020384999278647],
            [-143.986949, 64.967242999277502],
            [-144.127807, 64.799107999273971],
            [-144.029789, 64.767592999273305],
            [-144.095375, 64.741712999272764],
            [-144.069029, 64.683561999271561],
            [-144.072511, 64.654081999270957],
            [-144.31598, 64.645135999270749],
            [-144.460096, 64.583259999269472],
            [-144.552526, 64.600644999269832],
            [-144.798044, 64.568295999269168],
            [-144.985331, 64.568608999269173],
            [-145.082787, 64.50734899926789],
            [-145.373929, 64.483835999267399],
            [-145.374083, 64.455242999266801],
            [-145.603118, 64.423089999266139],
            [-145.740002, 64.460131999266906],
            [-145.925934, 64.424530999266182],
            [-146.003772, 64.381090999265282],
            [-146.230686, 64.363733999264923],
            [-146.232329, 64.310791999263841],
            [-146.344673, 64.276363999263125],
            [-146.486446, 64.281767999263238],
            [-146.655165, 64.257211999262722],
            [-146.999909, 64.25719599926272],
            [-147.779479, 64.259028999262767],
            [-148.046696, 64.343801999264514],
            [-148.176111, 64.400923999265686],
            [-148.270857, 64.491768999267578],
            [-148.403117, 64.531296999268392],
            [-148.424918, 64.574853999269294],
            [-148.550742, 64.617207999270164],
            [-148.663265, 64.590789999269617],
            [-148.661199, 65.20987499928269]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "070",
        COUNTYNS: "01419968",
        AFFGEOID: "0500000US02070",
        GEOID: "02070",
        NAME: "Dillingham",
        LSAD: "05",
        ALAND: 47477685010,
        AWATER: 5951019310
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-161.031448, 58.999392999167824],
              [-160.864703, 58.9993939991678],
              [-160.812133, 59.086076999169158],
              [-160.81214, 59.259437999171865],
              [-160.643716, 59.259439999171889],
              [-160.643719, 59.346119999173254],
              [-160.475296, 59.346121999173242],
              [-160.475354, 59.432798999174608],
              [-160.41864, 59.432799999174627],
              [-160.418647, 59.606151999177385],
              [-160.248503, 59.606153999177408],
              [-160.24851, 59.7795009991802],
              [-160.017423, 59.7795039991802],
              [-160.01743, 59.952845999183033],
              [-159.845524, 59.952847999183042],
              [-159.845533, 60.1261859991859],
              [-159.781902, 60.126186999185904],
              [-159.781906, 60.212853999187345],
              [-159.608196, 60.212855999187347],
              [-159.608204, 60.386187999190255],
              [-159.434497, 60.386189999190265],
              [-159.434502, 60.472853999191734],
              [-159.366206, 60.472855999191729],
              [-159.366215, 60.646178999194682],
              [-159.190657, 60.646180999194677],
              [-159.190661, 60.732840999196171],
              [-159.015104, 60.732842999196166],
              [-159.01511, 60.81950299919766],
              [-158.941919, 60.81950299919766],
              [-158.941926, 60.906161999199149],
              [-157.877224, 60.906171999199152],
              [-157.877208, 60.819514999197672],
              [-157.69976, 60.819514999197672],
              [-157.699775, 60.906174999199166],
              [-157.522325, 60.906177999199166],
              [-157.522332, 60.99283399920067],
              [-157.167428, 60.992838999200679],
              [-157.16742, 60.90618299919916],
              [-156.000086, 60.906189999199164],
              [-156.000145, 59.692878999178802],
              [-156.165068, 59.692876999178786],
              [-156.165066, 59.606201999177394],
              [-156.505349, 59.606198999177394],
              [-156.505345, 59.519522999176004],
              [-156.675489, 59.519520999176009],
              [-156.675483, 59.432843999174608],
              [-156.769971, 59.432842999174632],
              [-156.769965, 59.346165999173259],
              [-157.106814, 59.346161999173248],
              [-157.106808, 59.25948299917188],
              [-157.275233, 59.259481999171875],
              [-157.275221, 59.086120999169168],
              [-157.196306, 59.086121999169158],
              [-157.196292140099018, 58.849359540827599],
              [-157.388651, 58.805345999164821],
              [-157.572524, 58.750838999164003],
              [-157.777937, 58.70328799916328],
              [-158.140307, 58.61501999916193],
              [-158.232276, 58.61990199916201],
              [-158.332093, 58.665312999162701],
              [-158.376873, 58.74804299916395],
              [-158.423828, 58.769846999164287],
              [-158.564833, 58.802714999164792],
              [-158.520327, 58.857104999165614],
              [-158.619684, 58.911047999166449],
              [-158.767748, 58.864263999165729],
              [-158.790378, 58.804711999164823],
              [-158.780136, 58.753789999164042],
              [-158.861207, 58.695579999163172],
              [-158.827852, 58.626431999162108],
              [-158.704052, 58.482758999159941],
              [-158.795316, 58.40803199915883],
              [-158.880927, 58.390669999158575],
              [-159.063346, 58.423138999159058],
              [-159.228398, 58.603046999161748],
              [-159.409779, 58.773610999164354],
              [-159.532347, 58.833608999165271],
              [-159.643549, 58.845062999165428],
              [-159.601899, 58.88467099916604],
              [-159.61612, 58.931600999166776],
              [-159.712114, 58.929467999166739],
              [-159.748183, 58.875826999165895],
              [-159.792923, 58.823970999165113],
              [-159.908386, 58.779902999164435],
              [-159.979344, 58.83553499916529],
              [-160.150528, 58.866061999165765],
              [-160.232788, 58.901126999166287],
              [-160.322922, 58.953952999167115],
              [-160.256592, 58.994479999167737],
              [-160.31778, 59.07047699916891],
              [-160.516426, 59.011239999168005],
              [-160.730971, 58.92118599916661],
              [-160.823489, 58.829135999165203],
              [-160.872003, 58.878471999165932],
              [-161.001101, 58.849692999165505],
              [-161.03144100529201, 58.839807950370961],
              [-161.031448, 58.999392999167824]
            ]
          ],
          [
            [
              [-161.078486, 58.635576999162247],
              [-161.056595, 58.702201999163265],
              [-160.918586, 58.746934999163948],
              [-160.700627, 58.817367999165008],
              [-160.679309, 58.78022599916445],
              [-160.880515, 58.581324999161446],
              [-160.961416, 58.553722999161018],
              [-161.07563, 58.549915999160952],
              [-161.078486, 58.635576999162247]
            ]
          ],
          [
            [
              [-161.364931, 58.826020999165152],
              [-161.073748442440973, 58.826023803777851],
              [-161.221942, 58.777740999164394],
              [-161.337982, 58.74291199916388],
              [-161.339580659244007, 58.73933862416682],
              [-161.365049, 58.739363999163821],
              [-161.364931, 58.826020999165152]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "059",
        COUNTYNS: "00277294",
        AFFGEOID: "0500000US06059",
        GEOID: "06059",
        NAME: "Orange",
        LSAD: "06",
        ALAND: 2048392529,
        AWATER: 406915886
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-118.093099, 33.786151999127554],
            [-118.063268, 33.824219999127074],
            [-118.058655, 33.846126999126795],
            [-118.028714, 33.866241999126544],
            [-117.976593, 33.902809999126099],
            [-117.966691, 33.946057999125564],
            [-117.919725, 33.947667999125549],
            [-117.783287, 33.946410999125554],
            [-117.673749, 33.87083099912649],
            [-117.675053, 33.86872499912652],
            [-117.533999, 33.710354999128505],
            [-117.412987, 33.659044999129158],
            [-117.457937, 33.602188999129872],
            [-117.51021, 33.533998999130745],
            [-117.509722, 33.505018999131124],
            [-117.508614, 33.469613999131575],
            [-117.57848, 33.453926999131767],
            [-117.596188685737005, 33.386964993452537],
            [-117.645582, 33.440727999131944],
            [-117.715349, 33.460555999131685],
            [-117.726486, 33.483426999131396],
            [-117.73225829761401, 33.487955023109933],
            [-117.814188, 33.552223999130511],
            [-117.840289, 33.573522999130233],
            [-117.927091, 33.605520999129823],
            [-118.000593, 33.654318999129224],
            [-118.088896, 33.729816999128253],
            [-118.115076722882009, 33.743803322023091],
            [-118.093099, 33.786151999127554]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "123",
        COUNTYNS: "00351260",
        AFFGEOID: "0500000US13123",
        GEOID: "13123",
        NAME: "Gilmer",
        LSAD: "06",
        ALAND: 1103942496,
        AWATER: 12227724
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.657098, 34.728904999116217],
            [-84.627348, 34.784727999115589],
            [-84.618637, 34.855397999114778],
            [-84.422754, 34.85479499911478],
            [-84.343661, 34.824780999115127],
            [-84.196754, 34.617923999117487],
            [-84.255112, 34.568332999118077],
            [-84.334938, 34.580161999117948],
            [-84.345553, 34.562734999118142],
            [-84.371352, 34.548494999118304],
            [-84.437226, 34.549349999118313],
            [-84.467989, 34.563487999118131],
            [-84.50261, 34.563680999118134],
            [-84.654366, 34.54894599911831],
            [-84.654523, 34.583186999117906],
            [-84.657098, 34.728904999116217]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "173",
        COUNTYNS: "01785051",
        AFFGEOID: "0500000US17173",
        GEOID: "17173",
        NAME: "Shelby",
        LSAD: "06",
        ALAND: 1964598160,
        AWATER: 24693146
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.139807, 39.348887999074755],
            [-89.025633, 39.345837999074767],
            [-89.02568, 39.654182999072844],
            [-88.810575, 39.653221999072855],
            [-88.809051, 39.580240999073311],
            [-88.717539, 39.579298999073302],
            [-88.716875, 39.521208999073657],
            [-88.641468, 39.520957999073666],
            [-88.584273, 39.447581999074124],
            [-88.470505, 39.447040999074133],
            [-88.47083, 39.374514999074592],
            [-88.470906, 39.215028999075621],
            [-88.805325, 39.216262999075603],
            [-89.117534, 39.217233999075603],
            [-89.140076, 39.217906999075595],
            [-89.139807, 39.348887999074755]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "149",
        COUNTYNS: "00450389",
        AFFGEOID: "0500000US18149",
        GEOID: "18149",
        NAME: "Starke",
        LSAD: "06",
        ALAND: 800678470,
        AWATER: 7930487
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.930079, 41.236797999064684],
            [-86.870265, 41.264552999064563],
            [-86.814652, 41.273500999064531],
            [-86.739995, 41.321707999064337],
            [-86.642542, 41.432638999063862],
            [-86.524711, 41.43288599906387],
            [-86.466603, 41.43296799906387],
            [-86.467046, 41.17144199906496],
            [-86.93017, 41.172317999064958],
            [-86.930079, 41.236797999064684]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "027",
        COUNTYNS: "00450343",
        AFFGEOID: "0500000US18027",
        GEOID: "18027",
        NAME: "Daviess",
        LSAD: "06",
        ALAND: 1112375531,
        AWATER: 19123262
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.278553, 38.691342999079197],
            [-87.238551, 38.743234999078837],
            [-87.241463, 38.817475999078312],
            [-87.213267, 38.807976999078377],
            [-87.098771, 38.903973999077699],
            [-87.03542, 38.902459999077713],
            [-86.904252, 38.904203999077716],
            [-86.922943, 38.817237999078316],
            [-86.924186, 38.505357999080559],
            [-86.947663, 38.490452999080659],
            [-87.072369, 38.514699999080484],
            [-87.10636, 38.537233999080321],
            [-87.242097, 38.544929999080267],
            [-87.240629, 38.632127999079643],
            [-87.278553, 38.691342999079197]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "121",
        COUNTYNS: "00465249",
        AFFGEOID: "0500000US19121",
        GEOID: "19121",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 1452999208,
        AWATER: 2983840
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.241593, 41.503678999063574],
            [-93.896807, 41.503097999063598],
            [-93.790612, 41.511915999063561],
            [-93.789196, 41.162034999064993],
            [-93.898813, 41.156609999065033],
            [-94.014189, 41.15676199906504],
            [-94.241637, 41.157133999065024],
            [-94.241593, 41.503678999063574]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "077",
        COUNTYNS: "00465227",
        AFFGEOID: "0500000US19077",
        GEOID: "19077",
        NAME: "Guthrie",
        LSAD: "06",
        ALAND: 1529698836,
        AWATER: 6355533
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.744876, 41.862393999062242],
            [-94.628724, 41.862762999062241],
            [-94.28034, 41.862998999062235],
            [-94.280057, 41.601311999063206],
            [-94.241356, 41.600970999063215],
            [-94.241593, 41.503678999063574],
            [-94.700629, 41.504147999063584],
            [-94.700745, 41.602183999063215],
            [-94.744204, 41.602184999063205],
            [-94.744876, 41.862393999062242]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "139",
        COUNTYNS: "00485033",
        AFFGEOID: "0500000US20139",
        GEOID: "20139",
        NAME: "Osage",
        LSAD: "06",
        ALAND: 1827283226,
        AWATER: 37107132
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.949654, 38.695384999079181],
            [-95.945924, 38.739111999078865],
            [-95.946587, 38.869972999077952],
            [-95.500724, 38.869814999077946],
            [-95.500738, 38.738807999078865],
            [-95.508042, 38.695267999079178],
            [-95.508267, 38.433932999081087],
            [-95.950282, 38.434104999081079],
            [-95.949654, 38.695384999079181]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "009",
        COUNTYNS: "00516851",
        AFFGEOID: "0500000US21009",
        GEOID: "21009",
        NAME: "Barren",
        LSAD: "06",
        ALAND: 1262733192,
        AWATER: 32260040
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.113512, 37.061071999092334],
            [-86.074267, 37.053284999092398],
            [-86.056443, 37.167207999091382],
            [-85.906336, 37.14837699909156],
            [-85.744221, 37.16981299909137],
            [-85.776962, 37.138327999091644],
            [-85.75609, 37.109834999091895],
            [-85.739255, 36.841475999094335],
            [-85.772286, 36.836339999094378],
            [-85.97691, 36.722721999095434],
            [-86.069957, 36.80578299909466],
            [-86.06531, 36.8991279990938],
            [-86.106421, 36.865063999094097],
            [-86.16674, 36.934014999093478],
            [-86.113512, 37.061071999092334]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "099",
        COUNTYNS: "00516896",
        AFFGEOID: "0500000US21099",
        GEOID: "21099",
        NAME: "Hart",
        LSAD: "06",
        ALAND: 1068313096,
        AWATER: 13912256
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.064554, 37.348612999089816],
            [-86.019089, 37.394536999089411],
            [-86.047598, 37.449932999088936],
            [-85.892804, 37.440307999089022],
            [-85.657332, 37.42199599908917],
            [-85.697325, 37.301837999090203],
            [-85.686482, 37.182426999091248],
            [-85.744221, 37.16981299909137],
            [-85.906336, 37.14837699909156],
            [-86.056443, 37.167207999091382],
            [-86.049133, 37.215284999090969],
            [-86.157589, 37.335648999089912],
            [-86.064554, 37.348612999089816]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "019",
        COUNTYNS: "00424211",
        AFFGEOID: "0500000US17019",
        GEOID: "17019",
        NAME: "Champaign",
        LSAD: "06",
        ALAND: 2579733580,
        AWATER: 6091992
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.463174, 40.14219599907004],
            [-88.460418, 40.281934999069271],
            [-88.459957, 40.398849999068645],
            [-88.346987, 40.398650999068664],
            [-87.932858, 40.399400999068654],
            [-87.942103, 40.225481999069572],
            [-87.937645, 39.879802999071508],
            [-88.349187, 39.879450999071508],
            [-88.462207, 39.879091999071512],
            [-88.463174, 40.14219599907004]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "103",
        COUNTYNS: "00465240",
        AFFGEOID: "0500000US19103",
        GEOID: "19103",
        NAME: "Johnson",
        LSAD: "06",
        ALAND: 1590377745,
        AWATER: 23572945
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.831379, 41.861850999062241],
            [-91.366079, 41.860067999062252],
            [-91.366448, 41.598372999063223],
            [-91.368521, 41.423177999063903],
            [-91.460384, 41.424179999063909],
            [-91.483995, 41.423847999063902],
            [-91.519828, 41.51100999906356],
            [-91.829231, 41.511456999063562],
            [-91.831379, 41.861850999062241]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "075",
        COUNTYNS: "00465226",
        AFFGEOID: "0500000US19075",
        GEOID: "19075",
        NAME: "Grundy",
        LSAD: "06",
        ALAND: 1299797785,
        AWATER: 94406
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.027, 42.55680999906005],
            [-92.5543, 42.555853999060062],
            [-92.534015, 42.469591999060292],
            [-92.53351, 42.297000999060813],
            [-92.76809, 42.296679999060814],
            [-92.767463, 42.210139999061077],
            [-93.001674, 42.209266999061072],
            [-93.002902, 42.470637999060294],
            [-93.027, 42.55680999906005]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "067",
        COUNTYNS: "00758488",
        AFFGEOID: "0500000US29067",
        GEOID: "29067",
        NAME: "Douglas",
        LSAD: "06",
        ALAND: 2107273796,
        AWATER: 2520175
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.903273, 37.070650999092251],
            [-92.82467, 37.068673999092269],
            [-92.685867, 37.067050999092281],
            [-92.251359, 37.059652999092357],
            [-92.088708, 37.056247999092378],
            [-92.095841, 36.798438999094721],
            [-92.112346, 36.794230999094758],
            [-92.764869, 36.806096999094649],
            [-92.909336, 36.809177999094629],
            [-92.903273, 37.070650999092251]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "001",
        COUNTYNS: "00765805",
        AFFGEOID: "0500000US29001",
        GEOID: "29001",
        NAME: "Adair",
        LSAD: "06",
        ALAND: 1469362052,
        AWATER: 5468507
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.855629, 40.342735999068935],
            [-92.684167, 40.34346599906894],
            [-92.349975, 40.34650499906892],
            [-92.347801, 40.302755999069156],
            [-92.345064, 40.037209999070612],
            [-92.847477, 40.037300999070609],
            [-92.856191, 40.03725299907061],
            [-92.855629, 40.342735999068935]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "099",
        COUNTYNS: "00835871",
        AFFGEOID: "0500000US31099",
        GEOID: "31099",
        NAME: "Kearney",
        LSAD: "06",
        ALAND: 1337063632,
        AWATER: 274634
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.179248, 40.660540999067322],
            [-98.906096, 40.65272199906736],
            [-98.724387, 40.689771999067183],
            [-98.723948, 40.350390999068907],
            [-98.726827, 40.35039799906891],
            [-99.179403, 40.350680999068913],
            [-99.179248, 40.660540999067322]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "005",
        COUNTYNS: "00758457",
        AFFGEOID: "0500000US29005",
        GEOID: "29005",
        NAME: "Atchison",
        LSAD: "06",
        ALAND: 1417462632,
        AWATER: 7359184
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.765645, 40.585207999067698],
            [-95.533182, 40.582248999067723],
            [-95.373925053398679, 40.580332377009221],
            [-95.335588, 40.57987099906773],
            [-95.202266000830292, 40.578375628063739],
            [-95.177708, 40.478902999068232],
            [-95.180614, 40.26170599906937],
            [-95.548182001180081, 40.264414671912057],
            [-95.553292, 40.291157999069227],
            [-95.598657, 40.309808999069119],
            [-95.653729, 40.322581999069044],
            [-95.641027, 40.366398999068828],
            [-95.649418, 40.396148999068664],
            [-95.684363, 40.463365999068323],
            [-95.694726, 40.493601999068169],
            [-95.712280371801072, 40.523754425275413],
            [-95.714291, 40.52720799906799],
            [-95.75711, 40.525989999068003],
            [-95.765645, 40.585207999067698]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "023",
        COUNTYNS: "00835833",
        AFFGEOID: "0500000US31023",
        GEOID: "31023",
        NAME: "Butler",
        LSAD: "06",
        ALAND: 1514884892,
        AWATER: 15309107
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.368186, 41.396415999064011],
            [-97.287761, 41.40063799906401],
            [-97.253519, 41.384211999064071],
            [-97.178464, 41.3768159990641],
            [-96.90591, 41.456435999063778],
            [-96.905862, 41.45338899906379],
            [-96.908507, 41.04609099906552],
            [-96.91094, 41.04611599906552],
            [-97.368118, 41.046946999065511],
            [-97.368186, 41.396415999064011]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "009",
        COUNTYNS: "00835827",
        AFFGEOID: "0500000US31009",
        GEOID: "31009",
        NAME: "Blaine",
        LSAD: "06",
        ALAND: 1840674086,
        AWATER: 9443894
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.267622, 42.086146999061476],
            [-100.167605, 42.085847999061485],
            [-99.686959, 42.086074999061481],
            [-99.686834, 41.740325999062684],
            [-100.251224, 41.739816999062683],
            [-100.265474, 41.739855999062691],
            [-100.267622, 42.086146999061476]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "053",
        COUNTYNS: "01702371",
        AFFGEOID: "0500000US35053",
        GEOID: "35053",
        NAME: "Socorro",
        LSAD: "06",
        ALAND: 17214061674,
        AWATER: 4843011
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.724794, 34.578131999117971],
            [-107.691565, 34.579424999117961],
            [-107.201743, 34.578756999117957],
            [-106.882658, 34.579530999117949],
            [-106.768281, 34.53126199911852],
            [-106.416924, 34.439686999119587],
            [-106.415972, 34.259821999121748],
            [-105.925968, 34.259989999121736],
            [-105.924685, 33.82562399912706],
            [-106.052662, 33.825605999127056],
            [-106.052718, 33.650296999129253],
            [-106.37366, 33.660503999129126],
            [-106.372797, 33.479874999131432],
            [-106.474295, 33.475785999131489],
            [-107.712754, 33.476831999131477],
            [-107.716301, 34.25985599912174],
            [-107.725427, 34.274223999121567],
            [-107.724794, 34.578131999117971]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "051",
        COUNTYNS: "01622968",
        AFFGEOID: "0500000US26051",
        GEOID: "26051",
        NAME: "Gladwin",
        LSAD: "06",
        ALAND: 1299662664,
        AWATER: 36635214
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.608104, 44.160481999057119],
            [-84.368039, 44.160537999057119],
            [-84.166107, 44.161787999057125],
            [-84.166377, 43.996900999057289],
            [-84.167318, 43.825901999057493],
            [-84.366676, 43.813559999057496],
            [-84.606035, 43.815217999057516],
            [-84.608104, 44.160481999057119]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "037",
        COUNTYNS: "00974117",
        AFFGEOID: "0500000US36037",
        GEOID: "36037",
        NAME: "Genesee",
        LSAD: "06",
        ALAND: 1276698028,
        AWATER: 6142605
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.465505, 43.128618999058673],
            [-77.99729, 43.132980999058667],
            [-77.905934, 43.133560999058659],
            [-77.904121, 43.037055999058879],
            [-77.909832, 42.987761999058975],
            [-77.95553, 42.905860999059165],
            [-77.954964, 42.862753999059272],
            [-78.074171, 42.870187999059247],
            [-78.464381, 42.867460999059261],
            [-78.464449, 43.088702999058754],
            [-78.465505, 43.128618999058673]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "189",
        COUNTYNS: "01008594",
        AFFGEOID: "0500000US37189",
        GEOID: "37189",
        NAME: "Watauga",
        LSAD: "06",
        ALAND: 809160511,
        AWATER: 2332417
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.908137, 36.302012999099453],
            [-81.833202, 36.347338999099016],
            [-81.768977, 36.341041999099069],
            [-81.705966, 36.338495999099102],
            [-81.725372779685287, 36.389738496534697],
            [-81.638186, 36.349605999098998],
            [-81.566349, 36.27220199909975],
            [-81.477516, 36.240249999100065],
            [-81.455792, 36.200440999100458],
            [-81.545488, 36.117474999101269],
            [-81.675149, 36.122529999101225],
            [-81.810516, 36.111510999101341],
            [-81.82923, 36.168790999100771],
            [-81.907698, 36.214162999100324],
            [-81.918444472904795, 36.287357916481703],
            [-81.908137, 36.302012999099453]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "137",
        COUNTYNS: "00465257",
        AFFGEOID: "0500000US19137",
        GEOID: "19137",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 1098405722,
        AWATER: 2410445
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.384349, 41.160010999065015],
            [-95.155851, 41.159235999065025],
            [-94.927587, 41.158507999065023],
            [-94.928459, 40.900652999066182],
            [-95.384964, 40.901549999066184],
            [-95.384349, 41.160010999065015]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "121",
        COUNTYNS: "00198176",
        AFFGEOID: "0500000US08121",
        GEOID: "08121",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 6521806786,
        AWATER: 15207400
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.706547, 39.739893999072329],
            [-103.705701, 40.001365999070806],
            [-103.47199, 40.001501999070811],
            [-103.465377, 40.436345999068458],
            [-102.77996, 40.438445999068456],
            [-102.793582, 40.351280999068905],
            [-102.803767, 40.002553999070791],
            [-102.802932, 39.567840999073383],
            [-103.154376, 39.565653999073383],
            [-103.707072, 39.566294999073385],
            [-103.706547, 39.739893999072329]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "127",
        COUNTYNS: "00485028",
        AFFGEOID: "0500000US20127",
        GEOID: "20127",
        NAME: "Morris",
        LSAD: "06",
        ALAND: 1800737060,
        AWATER: 19736109
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.930286, 38.609361999079795],
            [-96.927234, 38.812159999078339],
            [-96.890245, 38.870066999077949],
            [-96.501556, 38.869703999077942],
            [-96.501397, 38.82618799907825],
            [-96.390398, 38.825857999078259],
            [-96.389749, 38.738983999078862],
            [-96.352613, 38.739020999078868],
            [-96.35378, 38.521656999080435],
            [-96.819723, 38.522463999080429],
            [-96.929969, 38.522411999080418],
            [-96.930286, 38.609361999079795]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "081",
        COUNTYNS: "00695764",
        AFFGEOID: "0500000US28081",
        GEOID: "28081",
        NAME: "Lee",
        LSAD: "06",
        ALAND: 1165367719,
        AWATER: 8349354
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.824966, 34.364758999120482],
            [-88.789442, 34.494929999118938],
            [-88.736465, 34.508909999118778],
            [-88.541749, 34.509191999118784],
            [-88.542079, 34.465275999119285],
            [-88.543352, 34.08904399912381],
            [-88.712454, 34.089240999123795],
            [-88.718964, 34.074520999123983],
            [-88.824523, 34.074947999123985],
            [-88.824966, 34.364758999120482]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "111",
        COUNTYNS: "00695778",
        AFFGEOID: "0500000US28111",
        GEOID: "28111",
        NAME: "Perry",
        LSAD: "06",
        ALAND: 1676259638,
        AWATER: 7754345
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.146092, 31.434051999159919],
            [-88.943468, 31.433457999159927],
            [-88.841707, 31.433702999159916],
            [-88.840896, 31.00655299916642],
            [-88.834339, 30.997982999166542],
            [-88.834475, 30.910322999167906],
            [-88.885038, 30.910787999167898],
            [-89.137967, 30.90987699916791],
            [-89.146176, 31.000446999166513],
            [-89.146092, 31.434051999159919]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "029",
        COUNTYNS: "00695739",
        AFFGEOID: "0500000US28029",
        GEOID: "28029",
        NAME: "Copiah",
        LSAD: "06",
        ALAND: 2013048568,
        AWATER: 5754986
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.736282, 31.786133999154703],
            [-90.716785, 31.844582999153847],
            [-90.718297, 32.04835199915091],
            [-90.230331, 32.049727999150882],
            [-90.107491, 31.777684999154836],
            [-90.122565, 31.752679999155188],
            [-90.195422, 31.727914999155566],
            [-90.245191, 31.717523999155706],
            [-90.379409, 31.685193999156184],
            [-90.414198, 31.70045599915596],
            [-90.736824, 31.698490999155982],
            [-90.736282, 31.786133999154703]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "193",
        COUNTYNS: "00516943",
        AFFGEOID: "0500000US21193",
        GEOID: "21193",
        NAME: "Perry",
        LSAD: "06",
        ALAND: 879750403,
        AWATER: 7603771
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.547628, 37.334417999089929],
            [-83.449454, 37.378852999089538],
            [-83.38165, 37.338265999089892],
            [-83.214735, 37.443537999088981],
            [-83.124348, 37.404972999089317],
            [-83.126805, 37.235972999090791],
            [-83.066377, 37.244394999090716],
            [-83.000142, 37.195709999091129],
            [-83.058818, 37.124078999091779],
            [-83.05534, 37.017390999092719],
            [-83.12209, 37.000996999092877],
            [-83.182104, 37.021114999092688],
            [-83.167233, 37.102371999091964],
            [-83.201887, 37.174129999091328],
            [-83.32462, 37.225560999090874],
            [-83.39873, 37.320563999090048],
            [-83.507886, 37.235876999090777],
            [-83.526907, 37.258528999090586],
            [-83.551777, 37.287217999090338],
            [-83.547628, 37.334417999089929]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "055",
        COUNTYNS: "00295756",
        AFFGEOID: "0500000US12055",
        GEOID: "12055",
        NAME: "Highlands",
        LSAD: "06",
        ALAND: 2632952088,
        AWATER: 231653040
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.563318, 27.646646999223844],
            [-81.529961, 27.64642199922385],
            [-81.142164, 27.643237999223913],
            [-81.140472, 27.601301999224699],
            [-81.213717, 27.528653999226059],
            [-81.205096, 27.489701999226799],
            [-81.09845, 27.379107999228896],
            [-81.034429, 27.359159999229263],
            [-80.970916, 27.217697999231966],
            [-80.943186, 27.210598999232104],
            [-81.169138, 27.209584999232117],
            [-81.168281, 27.121733999233808],
            [-81.267677, 27.121096999233824],
            [-81.267152, 27.032460999235528],
            [-81.562202, 27.033835999235503],
            [-81.563696, 27.340679999229621],
            [-81.563318, 27.646646999223844]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "027",
        COUNTYNS: "00345518",
        AFFGEOID: "0500000US13027",
        GEOID: "13027",
        NAME: "Brooks",
        LSAD: "06",
        ALAND: 1277105513,
        AWATER: 12191505
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.736158, 31.037678999165934],
            [-83.573959, 31.033874999165995],
            [-83.574857, 31.078134999165318],
            [-83.551628, 31.054328999165683],
            [-83.475617, 31.031963999166017],
            [-83.441549, 30.914346999167837],
            [-83.346405, 30.853542999168784],
            [-83.449293, 30.817956999169343],
            [-83.482461, 30.756930999170297],
            [-83.357716313628103, 30.637137872306393],
            [-83.499951, 30.645662999172046],
            [-83.61170405882639, 30.651560092880562],
            [-83.743729, 30.658526912922351],
            [-83.736158, 31.037678999165934]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "093",
        COUNTYNS: "00351608",
        AFFGEOID: "0500000US13093",
        GEOID: "13093",
        NAME: "Dooly",
        LSAD: "06",
        ALAND: 1015119749,
        AWATER: 13841085
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.027853, 32.171082999149156],
            [-83.894777, 32.292174999147441],
            [-83.848379, 32.290969999147457],
            [-83.718434, 32.290161999147465],
            [-83.68513, 32.289891999147478],
            [-83.615579, 32.288555999147484],
            [-83.606214, 32.275643999147668],
            [-83.608547, 32.118404999149895],
            [-83.609663, 32.027937999151213],
            [-83.961278, 32.030594999151162],
            [-84.033411, 32.15321599914941],
            [-84.027853, 32.171082999149156]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "173",
        COUNTYNS: "00516933",
        AFFGEOID: "0500000US21173",
        GEOID: "21173",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 511177578,
        AWATER: 3859635
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.992845, 38.173363999083051],
            [-83.977924, 38.19211799908291],
            [-83.974659, 38.158436999083172],
            [-83.881353, 38.136516999083341],
            [-83.75943, 37.998730999084422],
            [-83.775168, 37.988662999084497],
            [-83.768783, 37.918370999085056],
            [-83.814919, 37.900226999085206],
            [-83.96631, 37.930767999084964],
            [-84.080527, 38.115117999083502],
            [-83.992845, 38.173363999083051]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "025",
        COUNTYNS: "01688745",
        AFFGEOID: "0500000US30025",
        GEOID: "30025",
        NAME: "Fallon",
        LSAD: "06",
        ALAND: 4197205007,
        AWATER: 5915331
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.905988, 46.48225099905811],
            [-104.732672, 46.482201999058098],
            [-104.733059, 46.612694999058334],
            [-104.732843, 46.656263999058432],
            [-104.606903, 46.684923999058498],
            [-104.417998, 46.684891999058486],
            [-104.354835, 46.641408999058406],
            [-104.045385103689014, 46.641501058180211],
            [-104.045125413323007, 46.540929927607216],
            [-104.045045, 46.509787999058162],
            [-104.045469, 46.324544999057842],
            [-104.045465958927991, 46.280188047268467],
            [-104.045443, 45.945309999057329],
            [-104.044133156106994, 45.881976257407061],
            [-104.134826, 45.881927999057254],
            [-104.134842, 45.968832999057355],
            [-104.390876, 45.970362999057365],
            [-104.390744, 46.054825999057456],
            [-104.516078, 46.054843999057454],
            [-104.516126, 46.13619599905757],
            [-104.885495, 46.136594999057564],
            [-104.905988, 46.48225099905811]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "037",
        COUNTYNS: "01581078",
        AFFGEOID: "0500000US55037",
        GEOID: "55037",
        NAME: "Florence",
        LSAD: "06",
        ALAND: 1264232435,
        AWATER: 24111636
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.679132, 46.013537999057412],
            [-88.65776, 45.989286999057384],
            [-88.613063, 45.990626999057383],
            [-88.59386, 46.015131999057417],
            [-88.526673, 46.020821999057411],
            [-88.409864, 45.979687999057354],
            [-88.380183, 45.991653999057384],
            [-88.30952, 45.959368999057347],
            [-88.246307, 45.962982999057346],
            [-88.178008, 45.947110999057337],
            [-88.116856537376094, 45.922811241319998],
            [-88.115346, 45.922210999057299],
            [-88.073944, 45.875592999057261],
            [-88.135067, 45.821693999057203],
            [-88.105518, 45.798838999057175],
            [-88.057010465949006, 45.784977029200867],
            [-88.059246, 45.713062999057087],
            [-88.425302, 45.722425999057094],
            [-88.675821, 45.722899999057105],
            [-88.683232208499376, 46.014465669377707],
            [-88.679132, 46.013537999057412]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "091",
        COUNTYNS: "01581106",
        AFFGEOID: "0500000US55091",
        GEOID: "55091",
        NAME: "Pepin",
        LSAD: "06",
        ALAND: 600892978,
        AWATER: 43242987
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.316933133405797, 44.53927601125956],
            [-92.1353, 44.539579999056862],
            [-92.135202, 44.68437399905681],
            [-91.650361, 44.683632999056805],
            [-91.650248, 44.596648999056839],
            [-92.022788, 44.590518999056847],
            [-92.084528580492787, 44.404614313857941],
            [-92.111085, 44.413947999056916],
            [-92.232472, 44.44543399905691],
            [-92.245364649005396, 44.454251123408717],
            [-92.291005, 44.48546399905689],
            [-92.314071, 44.538013999056858],
            [-92.316933133405797, 44.53927601125956]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "069",
        COUNTYNS: "01513275",
        AFFGEOID: "0500000US53069",
        GEOID: "53069",
        NAME: "Wahkiakum",
        LSAD: "06",
        ALAND: 680956787,
        AWATER: 61588406
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.726557, 46.384871999057943],
            [-123.358334, 46.384024999057942],
            [-123.259068, 46.383457999057931],
            [-123.21795, 46.38561699905793],
            [-123.212493591208982, 46.171096457457914],
            [-123.280166, 46.144842999057573],
            [-123.363744507266006, 46.146243193406086],
            [-123.371433, 46.146371999057578],
            [-123.430847, 46.181826999057634],
            [-123.427629, 46.229347999057694],
            [-123.479644, 46.269130999057758],
            [-123.547659, 46.259108999057744],
            [-123.669501, 46.26683199905775],
            [-123.700764, 46.305277999057822],
            [-123.72790381759701, 46.291335584768987],
            [-123.726557, 46.384871999057943]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "223",
        COUNTYNS: "01383897",
        AFFGEOID: "0500000US48223",
        GEOID: "48223",
        NAME: "Hopkins",
        LSAD: "06",
        ALAND: 1987018121,
        AWATER: 66250826
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.861778, 33.219329999134814],
            [-95.786682, 33.253996999134358],
            [-95.731056, 33.305221999133707],
            [-95.720231, 33.299410999133777],
            [-95.673722, 33.30211699913373],
            [-95.584549, 33.359343999132996],
            [-95.501827, 33.347864999133158],
            [-95.308593, 33.377189999132753],
            [-95.308957, 32.962571999138227],
            [-95.665389, 32.960433999138253],
            [-95.676795, 32.979589999138],
            [-95.862521, 32.979570999137998],
            [-95.861778, 33.219329999134814]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "165",
        COUNTYNS: "00835904",
        AFFGEOID: "0500000US31165",
        GEOID: "31165",
        NAME: "Sioux",
        LSAD: "06",
        ALAND: 5352538105,
        AWATER: 1872668
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.053127, 43.000584999058944],
            [-103.505099261631997, 43.000758812090147],
            [-103.480403, 42.784965999059466],
            [-103.480082, 42.437266999060398],
            [-103.444532, 42.437353999060392],
            [-103.444108, 42.090707999061458],
            [-103.401667, 42.090561999061457],
            [-103.401639, 42.003539999061758],
            [-104.052762137092003, 42.001718264928357],
            [-104.052729, 42.016317999061727],
            [-104.052793, 42.249961999060957],
            [-104.053107, 42.499963999060206],
            [-104.05266219156799, 42.611766299601406],
            [-104.052586, 42.630916999059856],
            [-104.053127, 43.000584999058944]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "089",
        COUNTYNS: "01557805",
        AFFGEOID: "0500000US54089",
        GEOID: "54089",
        NAME: "Summers",
        LSAD: "06",
        ALAND: 933901529,
        AWATER: 18506801
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.068774, 37.639822999087336],
            [-80.947017, 37.715448999086703],
            [-80.877729, 37.685602999086953],
            [-80.94509, 37.819120999085861],
            [-80.806316, 37.868914999085462],
            [-80.668228, 37.762965999086319],
            [-80.663348, 37.731695999086575],
            [-80.664322, 37.70825199908677],
            [-80.857358570189774, 37.42112871308818],
            [-80.858148116819805, 37.421006905810685],
            [-80.866887, 37.434588999089073],
            [-81.09463, 37.588657999087765],
            [-81.068774, 37.639822999087336]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "075",
        COUNTYNS: "01266981",
        AFFGEOID: "0500000US46075",
        GEOID: "46075",
        NAME: "Jones",
        LSAD: "06",
        ALAND: 2511453379,
        AWATER: 3147180
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.064068, 43.994928999057294],
            [-101.046786, 43.99496999905729],
            [-101.046916, 44.168483999057123],
            [-100.367207, 44.168693999057112],
            [-100.366174, 43.846977999057472],
            [-100.33897, 43.716941999057632],
            [-100.587903, 43.769284999057554],
            [-100.735003, 43.707620999057653],
            [-100.789635, 43.725437999057611],
            [-100.795732, 43.754054999057587],
            [-100.841307, 43.760177999057575],
            [-100.930768, 43.831158999057479],
            [-101.064326, 43.841089999057466],
            [-101.064068, 43.994928999057294]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "485",
        COUNTYNS: "01384028",
        AFFGEOID: "0500000US48485",
        GEOID: "48485",
        NAME: "Wichita",
        LSAD: "06",
        ALAND: 1625943418,
        AWATER: 13820243
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.952324887528192, 34.2046698977259],
            [-98.94022, 34.20368599912242],
            [-98.872229, 34.160445999122935],
            [-98.80681, 34.155900999122998],
            [-98.737232, 34.130991999123289],
            [-98.690072, 34.133154999123271],
            [-98.648073, 34.164440999122888],
            [-98.610351781644695, 34.156209938619689],
            [-98.577136, 34.148961999123081],
            [-98.5282, 34.094960999123735],
            [-98.475066, 34.064268999124103],
            [-98.423533, 34.081949475129797],
            [-98.423577, 33.836046999126928],
            [-98.953088, 33.833999999126966],
            [-98.952324887528192, 34.2046698977259]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "097",
        COUNTYNS: "00974146",
        AFFGEOID: "0500000US36097",
        GEOID: "36097",
        NAME: "Schuyler",
        LSAD: "06",
        ALAND: 850379112,
        AWATER: 36246374
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.107203, 42.483770999060255],
            [-76.989329, 42.484820999060261],
            [-76.987913, 42.463238999060337],
            [-76.889805, 42.463053999060314],
            [-76.895596, 42.541536999060099],
            [-76.696655, 42.546789999060088],
            [-76.691406, 42.284306999060853],
            [-76.619303, 42.28285299906085],
            [-76.642256, 42.233720999061006],
            [-76.732677, 42.248051999060969],
            [-76.733912, 42.293719999060819],
            [-76.965028, 42.278494999060868],
            [-77.099657, 42.272355999060885],
            [-77.107203, 42.483770999060255]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "071",
        COUNTYNS: "00465224",
        AFFGEOID: "0500000US19071",
        GEOID: "19071",
        NAME: "Fremont",
        LSAD: "06",
        ALAND: 1323807065,
        AWATER: 14280096
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.888697, 40.736291999066957],
            [-95.834156, 40.783015999066734],
            [-95.834243816714405, 40.783784386109836],
            [-95.841309, 40.845603999066441],
            [-95.810709, 40.886680999066243],
            [-95.818727278569881, 40.897948065472697],
            [-95.795156, 40.901624999066179],
            [-95.384964, 40.901549999066184],
            [-95.370618, 40.728240999067005],
            [-95.373925053398679, 40.580332377009221],
            [-95.533182, 40.582248999067723],
            [-95.765645, 40.585207999067698],
            [-95.748626, 40.603354999067612],
            [-95.781909, 40.653271999067364],
            [-95.846034, 40.682604999067209],
            [-95.888697, 40.736291999066957]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "187",
        COUNTYNS: "00485055",
        AFFGEOID: "0500000US20187",
        GEOID: "20187",
        NAME: "Stanton",
        LSAD: "06",
        ALAND: 1762104518,
        AWATER: 178555
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.041965658628001, 37.738540572724723],
            [-101.527063, 37.736313999086533],
            [-101.52581, 37.388391999089457],
            [-101.556606, 37.388484999089457],
            [-102.041938522873991, 37.389190054483251],
            [-102.041890869664996, 37.644277684379098],
            [-102.041876, 37.72387499908664],
            [-102.041965658628001, 37.738540572724723]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "033",
        COUNTYNS: "00484986",
        AFFGEOID: "0500000US20033",
        GEOID: "20033",
        NAME: "Comanche",
        LSAD: "06",
        ALAND: 2041681113,
        AWATER: 3604155
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.543189, 37.381123999089517],
            [-99.011499, 37.384262999089501],
            [-99.001378, 37.375021999089569],
            [-99.000301053884186, 36.999358097237291],
            [-99.129449, 36.999421999092888],
            [-99.407015, 36.99957899909289],
            [-99.456202434675276, 36.999700278500796],
            [-99.541114912360896, 36.999909643658384],
            [-99.543189, 37.381123999089517]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "223",
        COUNTYNS: "00516958",
        AFFGEOID: "0500000US21223",
        GEOID: "21223",
        NAME: "Trimble",
        LSAD: "06",
        ALAND: 392766460,
        AWATER: 11833388
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.448862, 38.713367999079047],
            [-85.400481, 38.7359799990789],
            [-85.340953, 38.733892999078904],
            [-85.332640733751404, 38.734816753426585],
            [-85.211375, 38.580215999080011],
            [-85.16827, 38.585447999079967],
            [-85.279627, 38.496267999080622],
            [-85.314006, 38.492591999080645],
            [-85.378743, 38.518821999080451],
            [-85.432972269426088, 38.524123396068312],
            [-85.4156, 38.54634099908025],
            [-85.431416070752192, 38.586285612188874],
            [-85.43617, 38.598291999079876],
            [-85.438742, 38.659318999079439],
            [-85.448862, 38.713367999079047]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "103",
        COUNTYNS: "01213683",
        AFFGEOID: "0500000US42103",
        GEOID: "42103",
        NAME: "Pike",
        LSAD: "06",
        ALAND: 1411441329,
        AWATER: 56692145
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.311143, 41.331438999064282],
            [-75.350648, 41.367693999064137],
            [-75.265712, 41.372381999064103],
            [-75.046198605911684, 41.603764018965904],
            [-75.043879, 41.57509399906332],
            [-74.982463, 41.496466999063621],
            [-74.890358, 41.455323999063786],
            [-74.799546, 41.431289999063878],
            [-74.756271834725197, 41.427627427607298],
            [-74.734893, 41.425817999063902],
            [-74.694914, 41.357422999064177],
            [-74.760325, 41.340324999064251],
            [-74.815703, 41.29615099906443],
            [-74.867405, 41.227769999064733],
            [-74.905256, 41.155667999065024],
            [-74.979873, 41.110422999065229],
            [-74.983042074247791, 41.106017557922051],
            [-74.992385995182204, 41.093028246058708],
            [-75.027957, 41.079867999065364],
            [-75.076811, 41.141121999065099],
            [-75.156507, 41.150340999065051],
            [-75.129721, 41.252100999064609],
            [-75.307348, 41.242229999064655],
            [-75.359184, 41.23920599906468],
            [-75.311143, 41.331438999064282]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "049",
        COUNTYNS: "00758479",
        AFFGEOID: "0500000US29049",
        GEOID: "29049",
        NAME: "Clinton",
        LSAD: "06",
        ALAND: 1085096687,
        AWATER: 11719847
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.602257, 39.747194999072285],
            [-94.205961, 39.7458409990723],
            [-94.208423, 39.52719999907363],
            [-94.210642, 39.454681999074097],
            [-94.600819, 39.456154999074066],
            [-94.601246, 39.530371999073616],
            [-94.602257, 39.747194999072285]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "117",
        COUNTYNS: "00516905",
        AFFGEOID: "0500000US21117",
        GEOID: "21117",
        NAME: "Kenton",
        LSAD: "06",
        ALAND: 414950497,
        AWATER: 10577248
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.607928, 39.073237999076561],
            [-84.550844, 39.099359999076384],
            [-84.506518737436906, 39.101766407264371],
            [-84.4508, 38.993608999077097],
            [-84.450774, 38.819525999078301],
            [-84.418107, 38.80659599907839],
            [-84.532288, 38.791936999078487],
            [-84.615664, 38.802276999078408],
            [-84.597932, 38.879430999077883],
            [-84.622027683659809, 39.078327545730922],
            [-84.607928, 39.073237999076561]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "105",
        COUNTYNS: "01678923",
        AFFGEOID: "0500000US30105",
        GEOID: "30105",
        NAME: "Valley",
        LSAD: "06",
        ALAND: 12758538566,
        AWATER: 351470610
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.404676, 47.870508999061656],
            [-107.404513, 48.218439999062902],
            [-107.36973, 48.218372999062886],
            [-107.369252, 48.305162999063214],
            [-107.260458, 48.304767999063216],
            [-107.260279, 48.394079999063564],
            [-107.301073, 48.393999999063567],
            [-107.301721, 48.480942999063906],
            [-107.25803, 48.51482099906405],
            [-107.192691, 48.509401999064025],
            [-107.206316, 48.568169999064267],
            [-107.206032, 48.912709999065761],
            [-107.179812012537994, 48.999911600813554],
            [-106.617539, 48.999582999066163],
            [-106.233987, 48.999422999066155],
            [-106.112063323684012, 48.999279437466456],
            [-106.151908, 48.823926999065371],
            [-106.021251, 48.823974999065356],
            [-106.02131, 48.563423999064248],
            [-105.804436, 48.563357999064245],
            [-105.804599, 48.219036999062887],
            [-105.843891, 48.010288999062126],
            [-106.006176, 48.033688999062207],
            [-106.229302, 48.026415999062195],
            [-106.400605, 48.061920999062302],
            [-106.41862, 47.957456999061939],
            [-106.501725, 47.958286999061947],
            [-106.510321, 47.878995999061679],
            [-106.603126, 47.784742999061365],
            [-106.711969, 47.772112999061328],
            [-106.734453, 47.734155999061208],
            [-106.861668, 47.73737199906121],
            [-106.892753, 47.67481799906102],
            [-107.011358, 47.663110999060983],
            [-107.249, 47.661565999060976],
            [-107.414886, 47.691733999061057],
            [-107.404676, 47.870508999061656]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "115",
        COUNTYNS: "00559548",
        AFFGEOID: "0500000US22115",
        GEOID: "22115",
        NAME: "Vernon",
        LSAD: "15",
        ALAND: 3439275960,
        AWATER: 35153783
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.530936, 30.924533999167679],
            [-93.549841, 30.96711799916703],
            [-93.539526, 31.008497999166384],
            [-93.531219, 31.051677999165715],
            [-93.540278, 31.128867999164544],
            [-93.535097, 31.185613999163664],
            [-93.552497307502307, 31.184820022610481],
            [-93.535414, 31.276401999162282],
            [-93.442554, 31.276715999162285],
            [-93.390495, 31.364146999160969],
            [-93.236104, 31.364506999160955],
            [-92.991988, 31.360472999161029],
            [-92.981464, 31.346643999161227],
            [-92.879756, 31.317372999161666],
            [-92.829651, 31.252280999162657],
            [-92.823932, 30.889999999168218],
            [-92.828298, 30.875741999168444],
            [-92.978453, 30.878174999168408],
            [-93.421102, 30.883137999168326],
            [-93.478718, 30.859182999168695],
            [-93.554575854345487, 30.877469501029513],
            [-93.530936, 30.924533999167679]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "023",
        COUNTYNS: "01719617",
        AFFGEOID: "0500000US30023",
        GEOID: "30023",
        NAME: "Deer Lodge",
        LSAD: "06",
        ALAND: 1907999319,
        AWATER: 12199471
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.517409, 45.94008899905733],
            [-113.378448, 46.01862999905741],
            [-113.278012, 46.045267999057444],
            [-113.284532, 46.222782999057692],
            [-113.036818, 46.223329999057682],
            [-113.036825, 46.266825999057744],
            [-112.558599, 46.265820999057752],
            [-112.598092, 46.236546999057708],
            [-112.577106, 46.178980999057636],
            [-112.681004, 46.135671999057571],
            [-112.77712, 46.135825999057566],
            [-112.777717, 46.048312999057465],
            [-112.852763, 46.048433999057451],
            [-112.938955, 45.993257999057384],
            [-112.923224, 45.93459499905731],
            [-113.085517, 45.860647999057235],
            [-113.179791, 45.885008999057256],
            [-113.377852, 45.759975999057133],
            [-113.516144, 45.938813999057324],
            [-113.517409, 45.94008899905733]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "129",
        COUNTYNS: "00835886",
        AFFGEOID: "0500000US31129",
        GEOID: "31129",
        NAME: "Nuckolls",
        LSAD: "06",
        ALAND: 1489645186,
        AWATER: 1718484
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.273571, 40.350358999068902],
            [-97.824114, 40.350540999068905],
            [-97.820816, 40.350544999068902],
            [-97.821500812275204, 40.002186881159702],
            [-97.931824926317191, 40.002236344090406],
            [-98.076034, 40.002300999070812],
            [-98.274017059752907, 40.002337449800706],
            [-98.273571, 40.350358999068902]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "073",
        COUNTYNS: "01244832",
        AFFGEOID: "0500000US45073",
        GEOID: "45073",
        NAME: "Oconee",
        LSAD: "06",
        ALAND: 1622181422,
        AWATER: 122207290
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.353238, 34.728647999116227],
            [-83.320062, 34.759615999115873],
            [-83.323866, 34.789711999115532],
            [-83.284812, 34.823042999115145],
            [-83.252582, 34.853482999114796],
            [-83.201183, 34.884652999114437],
            [-83.140621, 34.924914999113987],
            [-83.124378, 34.955239999113637],
            [-83.108606, 35.000658999113142],
            [-83.00847281226919, 35.026918543036736],
            [-82.897499, 35.056020999112519],
            [-82.943604, 34.98815399911328],
            [-82.915785, 34.971835999113452],
            [-82.900144, 34.742439999116058],
            [-82.855425, 34.724805999116271],
            [-82.840253, 34.623211999117437],
            [-82.976661, 34.494283999118949],
            [-82.991388153182299, 34.472980626354996],
            [-82.99509, 34.472482999119194],
            [-83.048289, 34.493253999118977],
            [-83.0505729641024, 34.495053651288039],
            [-83.096858, 34.531523999118512],
            [-83.102873826035704, 34.537430908883842],
            [-83.154577, 34.588197999117853],
            [-83.221402, 34.609946999117604],
            [-83.27796, 34.644852999117191],
            [-83.33869, 34.68200199911675],
            [-83.340039106784573, 34.686327709528811],
            [-83.349608510913285, 34.717010581743672],
            [-83.353238, 34.728647999116227]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "041",
        COUNTYNS: "00066850",
        AFFGEOID: "0500000US05041",
        GEOID: "05041",
        NAME: "Desha",
        LSAD: "06",
        ALAND: 1989461629,
        AWATER: 133085432
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.548668, 33.97679899912518],
            [-91.421715, 34.014471999124723],
            [-91.347628, 33.954940999125455],
            [-91.239438, 33.973759999125221],
            [-91.151365, 33.937509999125666],
            [-91.194278, 34.070937999124027],
            [-91.117905, 34.119104999123444],
            [-90.973313, 34.118686999123454],
            [-90.944795524309498, 34.116657204437672],
            [-90.946323, 34.109373999123548],
            [-90.90113, 34.094666999123746],
            [-90.874541, 34.072040999124013],
            [-90.89242, 34.026859999124568],
            [-90.942662, 34.018049999124671],
            [-90.979945, 34.000105999124898],
            [-91.004981, 33.977010999125184],
            [-91.048367, 33.985077999125082],
            [-91.088696, 33.961333999125372],
            [-91.035961, 33.943757999125587],
            [-91.026382, 33.907979999126042],
            [-91.061247, 33.877504999126415],
            [-91.052819, 33.824180999127073],
            [-91.025173, 33.805952999127307],
            [-91.026782, 33.763641999127834],
            [-91.08551, 33.776409999127665],
            [-91.111494, 33.774567999127683],
            [-91.143287, 33.747140999128035],
            [-91.075389, 33.714402999128453],
            [-91.10098, 33.660550999129129],
            [-91.178311, 33.651108999129256],
            [-91.130902, 33.610918999129758],
            [-91.188942, 33.576224999130204],
            [-91.205644985947899, 33.546978370063471],
            [-91.24576, 33.517147999130962],
            [-91.35081, 33.519475999130925],
            [-91.34954, 33.563645999130365],
            [-91.453438, 33.563702999130356],
            [-91.450325, 33.780414999127622],
            [-91.557648, 33.783383999127587],
            [-91.548668, 33.97679899912518]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "081",
        COUNTYNS: "00161566",
        AFFGEOID: "0500000US01081",
        GEOID: "01081",
        NAME: "Lee",
        LSAD: "06",
        ALAND: 1573550101,
        AWATER: 21491164
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.696755, 32.697428999141813],
            [-85.679854, 32.712529999141609],
            [-85.593151, 32.728529999141386],
            [-85.574954, 32.736346999141276],
            [-85.285043, 32.730730999141365],
            [-85.124533222750586, 32.751629605892482],
            [-85.11425, 32.730446999141364],
            [-85.088533, 32.657957999142354],
            [-85.076072372604798, 32.608067474300135],
            [-85.069848, 32.583145999143383],
            [-85.0071, 32.523867999144201],
            [-85.001130839123689, 32.510154787542191],
            [-85.059294, 32.472908999144913],
            [-85.333843, 32.468638999144964],
            [-85.330143, 32.410841999145774],
            [-85.434045, 32.409839999145788],
            [-85.438575, 32.497089999144578],
            [-85.489348, 32.496936999144566],
            [-85.695854, 32.595932999143201],
            [-85.696755, 32.697428999141813]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "109",
        COUNTYNS: "00308371",
        AFFGEOID: "0500000US12109",
        GEOID: "12109",
        NAME: "St. Johns",
        LSAD: "06",
        ALAND: 1555793861,
        AWATER: 571962027
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.680215, 30.121239999180467],
            [-81.433783, 30.10553599918072],
            [-81.436929, 30.252334999178331],
            [-81.3743761280233, 30.252930999178322],
            [-81.288955, 29.915179999183852],
            [-81.270442, 29.883105999184373],
            [-81.261933460574795, 29.822123485861489],
            [-81.256711, 29.78469299918601],
            [-81.210409596553802, 29.670640804892212],
            [-81.324059, 29.625609999188676],
            [-81.52366, 29.622431999188723],
            [-81.524804, 29.721585999187063],
            [-81.52523, 29.759496999186442],
            [-81.581207, 29.840175999185082],
            [-81.60099, 29.956016999183177],
            [-81.689413, 30.031233999181936],
            [-81.680215, 30.121239999180467]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "053",
        COUNTYNS: "00351012",
        AFFGEOID: "0500000US13053",
        GEOID: "13053",
        NAME: "Chattahoochee",
        LSAD: "06",
        ALAND: 644222675,
        AWATER: 6292704
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.983466, 32.363185999146438],
            [-84.981150294133698, 32.379040075828023],
            [-84.768407, 32.420590999145638],
            [-84.69451, 32.518731999144272],
            [-84.637323, 32.534854999144052],
            [-84.657325, 32.494939999144599],
            [-84.65892, 32.232851999148281],
            [-84.799348, 32.230851999148307],
            [-84.832962, 32.230850999148309],
            [-84.919942147925894, 32.23084820008161],
            [-84.891841, 32.263397999147841],
            [-84.955704, 32.305909999147246],
            [-85.008096, 32.336676999146817],
            [-84.983466, 32.363185999146438]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "099",
        COUNTYNS: "00345041",
        AFFGEOID: "0500000US13099",
        GEOID: "13099",
        NAME: "Early",
        LSAD: "06",
        ALAND: 1327612139,
        AWATER: 9717177
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.108192, 31.258590999162557],
            [-85.089774, 31.295025999162011],
            [-85.088829963530486, 31.308647754658399],
            [-85.087929, 31.321647999161613],
            [-85.092487, 31.362880999160996],
            [-85.066005, 31.431362999159955],
            [-85.071621, 31.468383999159403],
            [-85.051681429506203, 31.519540328300337],
            [-85.029227, 31.486070999159136],
            [-84.819807, 31.50119199915892],
            [-84.789032, 31.43635599915989],
            [-84.637579, 31.433925999159918],
            [-84.627579, 31.33211699916145],
            [-84.641673, 31.258966999162553],
            [-84.917421, 31.255989999162594],
            [-84.922423, 31.072589999165395],
            [-85.021107562362801, 31.075463871471758],
            [-85.035615, 31.108191999164848],
            [-85.107516, 31.186450999163657],
            [-85.108192, 31.258590999162557]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "313",
        COUNTYNS: "00353513",
        AFFGEOID: "0500000US13313",
        GEOID: "13313",
        NAME: "Whitfield",
        LSAD: "06",
        ALAND: 752293512,
        AWATER: 1533754
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.144789, 34.767638999115775],
            [-85.061648, 34.819477999115186],
            [-84.979854025231504, 34.987205613088875],
            [-84.976973806592298, 34.987219836468178],
            [-84.861314, 34.98779099911328],
            [-84.810477466886397, 34.987877954342466],
            [-84.818715, 34.876949999114537],
            [-84.878499, 34.779609999115628],
            [-84.864945, 34.707355999116466],
            [-84.94434, 34.676867999116823],
            [-84.913456, 34.634127999117311],
            [-84.926119, 34.616163999117525],
            [-85.050446, 34.622481999117461],
            [-85.050847, 34.719979999116326],
            [-85.168851, 34.72027899911631],
            [-85.144789, 34.767638999115775]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "193",
        COUNTYNS: "00424297",
        AFFGEOID: "0500000US17193",
        GEOID: "17193",
        NAME: "White",
        LSAD: "06",
        ALAND: 1281447206,
        AWATER: 18366965
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.370491, 38.255412999082424],
            [-88.1509, 38.256096999082416],
            [-88.027749, 38.255660999082423],
            [-87.9906, 38.259714999082391],
            [-87.968968, 38.237388999082562],
            [-87.970200840803898, 38.230271057468421],
            [-87.975819, 38.197833999082867],
            [-87.927468, 38.151945999083217],
            [-87.96221, 38.10005399908362],
            [-87.98877, 38.055590999083982],
            [-88.030884, 38.030712999084173],
            [-88.016311, 37.96157399908472],
            [-88.040861, 37.891766999085277],
            [-88.059469492700785, 37.866689803096278],
            [-88.136053, 37.916022999085079],
            [-88.37452, 37.908431999085138],
            [-88.370491, 38.255412999082424]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "147",
        COUNTYNS: "00450388",
        AFFGEOID: "0500000US18147",
        GEOID: "18147",
        NAME: "Spencer",
        LSAD: "06",
        ALAND: 1027620480,
        AWATER: 12069099
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.268003, 37.924765999085004],
            [-87.072332, 38.118279999083484],
            [-87.017453, 38.11830099908348],
            [-87.01749, 38.203579999082827],
            [-86.791497, 38.205129999082814],
            [-86.772367, 37.998164999084437],
            [-86.798293, 38.015527999084284],
            [-86.81365994128528, 37.996034494226045],
            [-86.875874, 37.970769999084645],
            [-86.927747, 37.934955999084927],
            [-86.977741425110196, 37.925699207457711],
            [-87.010315, 37.91966799908505],
            [-87.043049, 37.875048999085408],
            [-87.057836, 37.827456999085797],
            [-87.105614, 37.767630999086272],
            [-87.137502, 37.807263999085954],
            [-87.180063, 37.84137499908568],
            [-87.25525, 37.867325999085466],
            [-87.270387407739008, 37.8754228256754],
            [-87.268003, 37.924765999085004]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "089",
        COUNTYNS: "00450495",
        AFFGEOID: "0500000US18089",
        GEOID: "18089",
        NAME: "Lake",
        LSAD: "06",
        ALAND: 1292050352,
        AWATER: 330971523
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.526768, 41.298176999064431],
            [-87.525409353347499, 41.470280665125422],
            [-87.52494, 41.529734999063479],
            [-87.524044, 41.708334999062799],
            [-87.470742, 41.672834999062943],
            [-87.415816, 41.688182999062875],
            [-87.365439, 41.629535999063101],
            [-87.261536, 41.620335999063123],
            [-87.222799949118397, 41.628889838023113],
            [-87.218862, 41.24202699906467],
            [-87.275882, 41.218594999064777],
            [-87.393796, 41.162805999065],
            [-87.526648158082395, 41.16608994357658],
            [-87.526767886227489, 41.298051601543634],
            [-87.526768, 41.298176999064431]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "069",
        COUNTYNS: "00399394",
        AFFGEOID: "0500000US16069",
        GEOID: "16069",
        NAME: "Nez Perce",
        LSAD: "06",
        ALAND: 2197056674,
        AWATER: 21339031
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.035545, 46.410011999057978],
            [-117.036645748421009, 46.426100481713412],
            [-117.039771, 46.471778999058088],
            [-117.039777655561991, 46.541708991096321],
            [-116.719085, 46.542873999058223],
            [-116.61174, 46.631244999058381],
            [-116.456098, 46.629096999058369],
            [-116.451662, 46.490735999058124],
            [-116.369311, 46.500491999058141],
            [-116.369122, 46.466922999058085],
            [-116.368801, 46.343216999057866],
            [-116.713496, 46.325526999057857],
            [-116.701975, 45.996216999057388],
            [-116.757042, 45.942102999057326],
            [-116.750235, 45.869286999057245],
            [-116.799204532500994, 45.851045434540126],
            [-116.859795, 45.907263999057278],
            [-116.886843, 45.958616999057341],
            [-116.915989, 45.995412999057393],
            [-116.942656, 46.060999999057472],
            [-116.981962, 46.084914999057496],
            [-116.935473, 46.142447999057566],
            [-116.923958, 46.17091999905761],
            [-116.962966, 46.199679999057658],
            [-116.964379, 46.253281999057727],
            [-116.99726, 46.30315099905782],
            [-117.062748, 46.353623999057895],
            [-117.035545, 46.410011999057978]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "083",
        COUNTYNS: "01622984",
        AFFGEOID: "0500000US26083",
        GEOID: "26083",
        NAME: "Keweenaw",
        LSAD: "06",
        ALAND: 1398883253,
        AWATER: 14053673397
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-88.50078, 47.293502999059925],
              [-88.418673, 47.371187999060126],
              [-88.285195, 47.42239199906026],
              [-88.217822, 47.448737999060342],
              [-88.085252, 47.468960999060407],
              [-87.929269, 47.478736999060423],
              [-87.801184, 47.473300999060413],
              [-87.680067, 47.455684999060352],
              [-87.591498, 47.424112999060277],
              [-87.604704, 47.388624999060163],
              [-87.800294, 47.392147999060185],
              [-87.941613, 47.390072999060187],
              [-87.94336, 47.335898999060021],
              [-88.016478, 47.306274999059951],
              [-88.096851, 47.261350999059822],
              [-88.194218, 47.209241999059685],
              [-88.200450675933695, 47.199716895041654],
              [-88.298436, 47.19978199905966],
              [-88.298992, 47.286096999059886],
              [-88.51294650563608, 47.28610724677489],
              [-88.50078, 47.293502999059925]
            ]
          ],
          [
            [
              [-89.221332, 47.908068999061776],
              [-89.179154, 47.935029999061861],
              [-89.018303, 47.99252499906207],
              [-88.940886, 48.01958999906217],
              [-88.893701, 48.034769999062213],
              [-88.816084, 48.057005999062298],
              [-88.728198, 48.101913999062454],
              [-88.631908, 48.148306999062619],
              [-88.547033, 48.174890999062733],
              [-88.425162, 48.210649999062845],
              [-88.427373, 48.166763999062695],
              [-88.55044, 48.102110999062468],
              [-88.579172, 48.040757999062244],
              [-88.718555, 47.995133999062084],
              [-88.852923, 47.96532199906197],
              [-88.898986, 47.90068499906176],
              [-89.044463, 47.855749999061594],
              [-89.157738, 47.824014999061497],
              [-89.201812, 47.850242999061585],
              [-89.255202, 47.876101999061675],
              [-89.221332, 47.908068999061776]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "027",
        COUNTYNS: "00659459",
        AFFGEOID: "0500000US27027",
        GEOID: "27027",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 2707039036,
        AWATER: 19306174
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.819078, 47.081151999059358],
            [-96.826569127228296, 47.150538999059535],
            [-96.194671, 47.151148999059536],
            [-96.174108, 46.977374999059116],
            [-96.174587, 46.717853999058555],
            [-96.17227, 46.630708999058385],
            [-96.280977, 46.630764999058385],
            [-96.7857861825985, 46.629590848319289],
            [-96.789786513049791, 46.635746671775095],
            [-96.790523, 46.636879999058401],
            [-96.786845, 46.692804999058502],
            [-96.788803, 46.777574999058672],
            [-96.763973, 46.912506999058976],
            [-96.833504, 47.010109999059196],
            [-96.819078, 47.081151999059358]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "035",
        COUNTYNS: "01719607",
        AFFGEOID: "0500000US30035",
        GEOID: "30035",
        NAME: "Glacier",
        LSAD: "06",
        ALAND: 7756795340,
        AWATER: 106093201
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.068178454280002, 48.999355880729965],
            [-113.907487, 48.998857999066153],
            [-113.692982, 48.997631999066144],
            [-113.375925, 48.998561999066155],
            [-113.116356, 48.998461999066151],
            [-112.193410195914012, 48.998893775699848],
            [-112.184784, 48.477577999063897],
            [-112.576982, 48.483347999063916],
            [-112.575864, 48.309683999063225],
            [-113.349046, 48.310262999063234],
            [-113.387048, 48.341501999063347],
            [-113.333424, 48.38796099906353],
            [-113.355976, 48.423911999063677],
            [-113.47916, 48.453114999063793],
            [-113.467372, 48.547554999064182],
            [-113.543488, 48.586871999064343],
            [-113.59905, 48.575172999064286],
            [-113.750454, 48.614334999064454],
            [-113.702938, 48.71986499906491],
            [-113.790539, 48.845529999065448],
            [-114.004135, 48.833214999065397],
            [-114.058093, 48.888953999065663],
            [-114.021207, 48.961342999065984],
            [-114.068178454280002, 48.999355880729965]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "33",
        COUNTYFP: "009",
        COUNTYNS: "00873178",
        AFFGEOID: "0500000US33009",
        GEOID: "33009",
        NAME: "Grafton",
        LSAD: "06",
        ALAND: 4425224129,
        AWATER: 106489725
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.329522, 43.608392999057799],
            [-72.284805, 43.720359999057635],
            [-72.222069, 43.75983099905757],
            [-72.211502122349899, 43.773019409499256],
            [-72.183333, 43.808176999057515],
            [-72.16978, 43.873424999057434],
            [-72.105875, 43.949369999057339],
            [-72.116706, 43.991953999057287],
            [-72.079944224002602, 44.030000029237961],
            [-72.079696272847798, 44.030256642441863],
            [-72.075486, 44.03461399905725],
            [-72.036883, 44.103118999057166],
            [-72.053826883614704, 44.159817092860521],
            [-72.061338, 44.184950999057101],
            [-72.05399, 44.246925999057048],
            [-72.046302, 44.291982999057012],
            [-72.002314, 44.324870999056984],
            [-71.945163, 44.33774399905699],
            [-71.875863, 44.337369999056975],
            [-71.837790626388795, 44.347764541533977],
            [-71.818838, 44.352938999056967],
            [-71.778613, 44.399798999056934],
            [-71.7631925213134, 44.403568261833335],
            [-71.696885, 44.332117999056976],
            [-71.587004, 44.304758999057],
            [-71.575819, 44.254595999057045],
            [-71.443338, 44.249171999057054],
            [-71.415898, 44.212168999057077],
            [-71.38163, 44.097873999057178],
            [-71.361965, 43.91593799905737],
            [-71.564603, 43.893171999057415],
            [-71.560621, 43.875044999057437],
            [-71.543805, 43.796042999057526],
            [-71.531658, 43.760978999057592],
            [-71.521466, 43.69799199905767],
            [-71.581403, 43.691948999057672],
            [-71.648346, 43.684510999057693],
            [-71.651153, 43.621118999057778],
            [-71.727573, 43.563410999057858],
            [-71.81757, 43.544458999057895],
            [-71.864599, 43.598062999057809],
            [-71.892112, 43.574617999057857],
            [-71.93531, 43.528879999057914],
            [-72.069516, 43.553171999057881],
            [-72.090893, 43.528856999057922],
            [-72.106412, 43.553484999057879],
            [-72.333596501356297, 43.605591340646903],
            [-72.329522, 43.608392999057799]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "091",
        COUNTYNS: "01026127",
        AFFGEOID: "0500000US37091",
        GEOID: "37091",
        NAME: "Hertford",
        LSAD: "06",
        ALAND: 914685904,
        AWATER: 18740844
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.208468, 36.246577999100005],
            [-77.122959, 36.471217999097817],
            [-77.066463, 36.496510999097573],
            [-77.164322603718205, 36.546152818944996],
            [-76.917318, 36.546045999097096],
            [-76.916037632856188, 36.546081329387405],
            [-76.946258, 36.413821999098374],
            [-76.779467, 36.362468999098866],
            [-76.696571, 36.296137999099507],
            [-76.706584, 36.243795999100023],
            [-77.180277, 36.242765999100037],
            [-77.208468, 36.246577999100005]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "051",
        COUNTYNS: "01135861",
        AFFGEOID: "0500000US41051",
        GEOID: "41051",
        NAME: "Multnomah",
        LSAD: "06",
        ALAND: 1116597726,
        AWATER: 88213123
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.929146, 45.721481999057104],
            [-122.784735, 45.720945999057115],
            [-122.76651077132999, 45.728662573502305],
            [-122.774511, 45.680436999057065],
            [-122.75644390397099, 45.662421024144649],
            [-122.738109, 45.644137999057044],
            [-122.643907, 45.609738999057008],
            [-122.492259, 45.583280999056988],
            [-122.438674, 45.563584999056964],
            [-122.380302, 45.575940999056982],
            [-122.331502, 45.548240999056965],
            [-122.262625, 45.544320999056957],
            [-122.249197008051993, 45.549998931798164],
            [-122.183695, 45.577695999056978],
            [-122.101675, 45.583515999056992],
            [-122.00369, 45.615929999057009],
            [-121.951838, 45.644950999057038],
            [-121.923749361985017, 45.654349508026442],
            [-121.90661, 45.521229999056942],
            [-121.819587, 45.46167499905691],
            [-121.852796, 45.460235999056898],
            [-122.514243, 45.461259999056907],
            [-122.660979, 45.457818999056911],
            [-122.705021, 45.433036999056895],
            [-122.743721, 45.433293999056893],
            [-122.743713, 45.438376999056899],
            [-122.743859, 45.516664999056943],
            [-122.867592, 45.591877999057004],
            [-122.929128, 45.635312999057035],
            [-122.929146, 45.721481999057104]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "065",
        COUNTYNS: "01248012",
        AFFGEOID: "0500000US45065",
        GEOID: "45065",
        NAME: "McCormick",
        LSAD: "06",
        ALAND: 930141786,
        AWATER: 89983085
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.595025792607288, 34.013517808334335],
            [-82.530567, 34.071924999124022],
            [-82.326947, 34.064119999124109],
            [-82.306243, 33.971583999125244],
            [-82.245055, 34.018780999124665],
            [-82.200286, 33.978596999125166],
            [-82.04513, 33.983395999125101],
            [-82.046974, 33.953209999125484],
            [-82.1557, 33.929289999125771],
            [-82.113728, 33.829879999127009],
            [-82.182987, 33.724112999128337],
            [-82.095527, 33.633868999129476],
            [-82.114653838584786, 33.59790505369994],
            [-82.142456474500889, 33.605399599121924],
            [-82.161908, 33.610642999129759],
            [-82.199747, 33.657610999129169],
            [-82.215938364747501, 33.687754974447486],
            [-82.239098, 33.73087199912824],
            [-82.32448, 33.820032999127129],
            [-82.43115, 33.86705099912654],
            [-82.51295, 33.936968999125668],
            [-82.556835, 33.945352999125568],
            [-82.562996798580897, 33.956554909652333],
            [-82.591855, 34.009017999124794],
            [-82.595025792607288, 34.013517808334335]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "071",
        COUNTYNS: "01034215",
        AFFGEOID: "0500000US38071",
        GEOID: "38071",
        NAME: "Ramsey",
        LSAD: "06",
        ALAND: 3074174337,
        AWATER: 295182394
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.200306, 48.370657999063461],
            [-98.970975, 48.370471999063462],
            [-98.970392, 48.544231999064166],
            [-98.318098, 48.543772999064153],
            [-98.31867, 48.369721999063465],
            [-98.292184, 48.195176999062795],
            [-98.4221, 48.194823999062798],
            [-98.422659, 48.021114999062164],
            [-98.525609, 48.021149999062175],
            [-98.525555, 47.915055999061799],
            [-98.625384, 47.916398999061812],
            [-98.672086, 48.000399999062097],
            [-98.79456, 47.951613999061934],
            [-98.75061, 47.982455999062033],
            [-98.803893, 48.059900999062322],
            [-98.991253, 47.990259999062054],
            [-99.001038, 48.053293999062284],
            [-99.070351, 48.021954999062167],
            [-99.070443, 48.107856999062477],
            [-99.199911, 48.155308999062655],
            [-99.200306, 48.370657999063461]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "061",
        COUNTYNS: "01035307",
        AFFGEOID: "0500000US38061",
        GEOID: "38061",
        NAME: "Mountrail",
        LSAD: "06",
        ALAND: 4727469721,
        AWATER: 301107657
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.885763, 48.546389999064175],
            [-102.233892, 48.546338999064162],
            [-101.973322, 48.547132999064182],
            [-101.973399, 48.372094999063471],
            [-101.922237, 48.372329999063474],
            [-101.922123, 48.023172999062183],
            [-101.871537, 48.023175999062182],
            [-101.871862, 47.847588999061571],
            [-102.385565, 47.847749999061577],
            [-102.385758, 47.75800799906127],
            [-102.561965, 47.780110999061364],
            [-102.642266, 47.823712999061499],
            [-102.644764, 47.906714999061769],
            [-102.551964, 47.997148999062084],
            [-102.663487, 48.076650999062359],
            [-102.828477, 48.123663999062529],
            [-102.828061, 48.372919999063477],
            [-102.885737, 48.372577999063473],
            [-102.885763, 48.546389999064175]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "055",
        COUNTYNS: "01034232",
        AFFGEOID: "0500000US38055",
        GEOID: "38055",
        NAME: "McLean",
        LSAD: "06",
        ALAND: 5466428300,
        AWATER: 563112245
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.385565, 47.847749999061577],
            [-101.871862, 47.847588999061571],
            [-100.971325, 47.84870299906158],
            [-100.585161, 47.847479999061576],
            [-100.585106, 47.673911999061019],
            [-100.672408, 47.674037999061021],
            [-100.673285, 47.327425999060004],
            [-100.749334, 47.327684999060004],
            [-100.74937, 47.15724499905955],
            [-100.965156, 47.156630999059544],
            [-100.977629, 47.24999199905978],
            [-101.034623, 47.286392999059899],
            [-101.257031, 47.264894999059841],
            [-101.345735, 47.291896999059901],
            [-101.362536, 47.367997999060115],
            [-101.440139, 47.477298999060416],
            [-101.370866, 47.518695999060554],
            [-101.428037, 47.561985999060674],
            [-101.641696, 47.528378999060578],
            [-101.707537, 47.538277999060604],
            [-101.813848, 47.496501999060477],
            [-102.036648, 47.572704999060704],
            [-102.20535, 47.574505999060712],
            [-102.265452, 47.624306999060856],
            [-102.262051, 47.782008999061354],
            [-102.335554, 47.801109999061424],
            [-102.385758, 47.75800799906127],
            [-102.385565, 47.847749999061577]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "357",
        COUNTYNS: "01383964",
        AFFGEOID: "0500000US48357",
        GEOID: "48357",
        NAME: "Ochiltree",
        LSAD: "06",
        ALAND: 2376792102,
        AWATER: 1331778
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.085156, 36.499243999097544],
            [-100.954152157693017, 36.499529495723742],
            [-100.884174, 36.49968199909754],
            [-100.592614, 36.499468999097537],
            [-100.546145126097016, 36.499505138401339],
            [-100.546724, 36.056535999101889],
            [-101.085716, 36.057571999101881],
            [-101.085156, 36.499243999097544]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "075",
        COUNTYNS: "01533501",
        AFFGEOID: "0500000US53075",
        GEOID: "53075",
        NAME: "Whitman",
        LSAD: "06",
        ALAND: 5592428648,
        AWATER: 48281266
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-118.249122, 46.734137999058582],
            [-118.210243, 46.738832999058602],
            [-118.015567, 46.784872999058699],
            [-117.959335, 46.914810999058979],
            [-117.96093, 47.260567999059823],
            [-117.823629, 47.260219999059821],
            [-117.040159751863015, 47.25927199905982],
            [-117.039836, 47.154733999059552],
            [-117.039833386027993, 47.127268991313585],
            [-117.039777655561991, 46.541708991096321],
            [-117.039771, 46.471778999058088],
            [-117.036645748421009, 46.426100481713412],
            [-117.208628, 46.423706999058005],
            [-117.22812, 46.462244999058065],
            [-117.254262, 46.545081999058219],
            [-117.382742, 46.61468399905835],
            [-117.464769, 46.699625999058512],
            [-117.597792, 46.672542999058464],
            [-117.740868, 46.694582999058504],
            [-117.85185, 46.624669999058369],
            [-117.881857, 46.592192999058298],
            [-118.165292, 46.555134999058247],
            [-118.21566, 46.588923999058309],
            [-118.249122, 46.734137999058582]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "685",
        COUNTYNS: "01498431",
        AFFGEOID: "0500000US51685",
        GEOID: "51685",
        NAME: "Manassas Park",
        LSAD: "25",
        ALAND: 6538719,
        AWATER: 616
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.472972, 38.788390999078509],
            [-77.444875, 38.770494999078636],
            [-77.440333, 38.783472999078555],
            [-77.420581, 38.762286999078704],
            [-77.427332, 38.753597999078757],
            [-77.458695, 38.772318999078621],
            [-77.47576, 38.781170999078576],
            [-77.472972, 38.788390999078509]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "240",
        COUNTYNS: "01419983",
        AFFGEOID: "0500000US02240",
        GEOID: "02240",
        NAME: "Southeast Fairbanks",
        LSAD: "05",
        ALAND: 64290353013,
        AWATER: 752301282
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-146.999909, 64.25719599926272],
            [-146.655165, 64.257211999262722],
            [-146.486446, 64.281767999263238],
            [-146.344673, 64.276363999263125],
            [-146.232329, 64.310791999263841],
            [-146.230686, 64.363733999264923],
            [-146.003772, 64.381090999265282],
            [-145.925934, 64.424530999266182],
            [-145.740002, 64.460131999266906],
            [-145.603118, 64.423089999266139],
            [-145.374083, 64.455242999266801],
            [-145.373929, 64.483835999267399],
            [-145.082787, 64.50734899926789],
            [-144.985331, 64.568608999269173],
            [-144.798044, 64.568295999269168],
            [-144.552526, 64.600644999269832],
            [-144.460096, 64.583259999269472],
            [-144.31598, 64.645135999270749],
            [-144.072511, 64.654081999270957],
            [-144.069029, 64.683561999271561],
            [-143.752529, 64.647632999270812],
            [-143.647213, 64.669505999271266],
            [-143.506329, 64.754067999273047],
            [-143.529991, 64.800610999273999],
            [-143.434679, 64.817790999274379],
            [-143.503643, 64.858250999275214],
            [-143.525172, 64.960079999277369],
            [-143.364478, 64.996995999278141],
            [-143.373311, 65.037106999279004],
            [-143.242092, 65.052517999279331],
            [-143.081816, 65.120936999280786],
            [-142.864344, 65.140386999281219],
            [-142.7319, 65.233638999283173],
            [-142.742849, 65.30140999928463],
            [-142.601998, 65.390930999286553],
            [-142.441972, 65.385041999286443],
            [-142.307081, 65.440632999287629],
            [-142.065431, 65.465340999288159],
            [-141.855761, 65.44578599928775],
            [-141.78905, 65.501545999288936],
            [-141.387842, 65.614394999291392],
            [-141.336224, 65.711613999293505],
            [-141.093233, 65.827387999296036],
            [-141.002464926138003, 65.8394210000952],
            [-141.002020469675017, 61.904047027059804],
            [-141.831804, 61.904052999216994],
            [-141.831812, 62.117177999220907],
            [-142.014987, 62.117645999220926],
            [-141.977336, 62.163982999221794],
            [-141.977353, 62.510558999228294],
            [-142.125838, 62.510555999228281],
            [-142.126131, 62.597191999229935],
            [-142.313555, 62.597192999229925],
            [-142.313564, 62.683827999231582],
            [-142.505637, 62.68382199923159],
            [-142.719523, 62.713507999232142],
            [-142.742485, 62.657812999231091],
            [-142.848579, 62.600546999229998],
            [-143.104996, 62.614904999230269],
            [-143.151065, 62.630678999230568],
            [-143.009391, 62.767249999233172],
            [-143.20757, 62.829090999234367],
            [-143.094868, 62.879613999235325],
            [-143.001837, 62.995622999237561],
            [-143.098876, 63.007890999237802],
            [-143.132337, 63.116977999239936],
            [-144.580159, 63.116930999239926],
            [-145.154385, 63.131148999240203],
            [-145.154395, 63.217780999241882],
            [-146.111486, 63.217761999241894],
            [-146.140827, 63.184012999241233],
            [-146.32926, 63.190561999241353],
            [-146.494311, 63.16897499924093],
            [-146.489541, 63.48291299924712],
            [-146.999966, 63.47964499924705],
            [-146.999909, 64.25719599926272]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "059",
        COUNTYNS: "00198145",
        AFFGEOID: "0500000US08059",
        GEOID: "08059",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 1979380402,
        AWATER: 25428019
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.398949, 39.566055999073392],
            [-105.397949, 39.747086999072287],
            [-105.397849, 39.912885999071321],
            [-105.339547, 39.913785999071315],
            [-105.16615, 39.91404699907131],
            [-105.165167, 39.891485999071449],
            [-105.052892, 39.914223999071318],
            [-105.053246, 39.791062999072032],
            [-105.053256, 39.667784999072758],
            [-105.109927, 39.626987999073016],
            [-105.096955, 39.617968999073064],
            [-105.053439, 39.621422999073047],
            [-105.04874, 39.566087999073396],
            [-105.05306, 39.563108999073407],
            [-105.073045, 39.545264999073517],
            [-105.171278, 39.406853999074386],
            [-105.217833, 39.260114999075327],
            [-105.32922, 39.129688999076173],
            [-105.330444, 39.129684999076176],
            [-105.397875, 39.129563999076183],
            [-105.398154, 39.401269999074415],
            [-105.398949, 39.566055999073392]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "063",
        COUNTYNS: "01672399",
        AFFGEOID: "0500000US13063",
        GEOID: "13063",
        NAME: "Clayton",
        LSAD: "06",
        ALAND: 366881210,
        AWATER: 7038080
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.458556, 33.594435999129978],
            [-84.458053, 33.629240999129529],
            [-84.457726, 33.648865999129285],
            [-84.350224, 33.647907999129295],
            [-84.281273, 33.647410999129299],
            [-84.243897, 33.590293999130026],
            [-84.283879, 33.549077999130553],
            [-84.29468, 33.43571499913201],
            [-84.353824, 33.397290999132508],
            [-84.3544, 33.35251399913308],
            [-84.388118, 33.352464999133076],
            [-84.381814, 33.463466999131647],
            [-84.397479, 33.485737999131352],
            [-84.438151, 33.548173999130562],
            [-84.458665, 33.550932999130531],
            [-84.458556, 33.594435999129978]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "139",
        COUNTYNS: "00446854",
        AFFGEOID: "0500000US18139",
        GEOID: "18139",
        NAME: "Rush",
        LSAD: "06",
        ALAND: 1057035404,
        AWATER: 872270
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.633491, 39.786176999072055],
            [-85.596916, 39.786518999072058],
            [-85.301584, 39.787578999072053],
            [-85.29811, 39.525475999073635],
            [-85.297575, 39.453274999074083],
            [-85.629327, 39.452749999074094],
            [-85.633228, 39.698461999072585],
            [-85.633491, 39.786176999072055]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "067",
        COUNTYNS: "00450360",
        AFFGEOID: "0500000US18067",
        GEOID: "18067",
        NAME: "Howard",
        LSAD: "06",
        ALAND: 758866967,
        AWATER: 2386193
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.374417, 40.561360999067823],
            [-86.165024, 40.562648999067818],
            [-86.013325, 40.564127999067807],
            [-85.863641, 40.565524999067804],
            [-85.862117, 40.406888999068613],
            [-86.060645, 40.405197999068612],
            [-86.242812, 40.40291399906863],
            [-86.242992, 40.373660999068775],
            [-86.309249, 40.431711999068483],
            [-86.375762, 40.43185099906848],
            [-86.374417, 40.561360999067823]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "061",
        COUNTYNS: "01026132",
        AFFGEOID: "0500000US37061",
        GEOID: "37061",
        NAME: "Duplin",
        LSAD: "06",
        ALAND: 2110160392,
        AWATER: 14193280
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.147129, 34.902505999114226],
            [-78.16342, 35.18971599911103],
            [-78.044451, 35.192534999110997],
            [-77.891892, 35.146424999111503],
            [-77.834249, 35.177845999111163],
            [-77.769177, 35.145575999111522],
            [-77.749572, 35.10860999911192],
            [-77.731026, 35.008137999113046],
            [-77.674805, 34.973899999113435],
            [-77.653352, 34.940534999113808],
            [-77.680127, 34.720594999116322],
            [-77.981195, 34.713982999116404],
            [-78.114007, 34.721794999116298],
            [-78.198642, 34.741636999116075],
            [-78.147129, 34.902505999114226]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "095",
        COUNTYNS: "00485012",
        AFFGEOID: "0500000US20095",
        GEOID: "20095",
        NAME: "Kingman",
        LSAD: "06",
        ALAND: 2236100139,
        AWATER: 8536907
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.464663, 37.471012999088757],
            [-98.464804, 37.732674999086569],
            [-97.807823, 37.733854999086553],
            [-97.8076, 37.474183999088723],
            [-97.807057, 37.386292999089484],
            [-98.349804, 37.384055999089504],
            [-98.464951, 37.384085999089486],
            [-98.464663, 37.471012999088757]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "229",
        COUNTYNS: "00516961",
        AFFGEOID: "0500000US21229",
        GEOID: "21229",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 769158640,
        AWATER: 10943099
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.401784, 37.730657999086581],
            [-85.254333, 37.866422999085472],
            [-85.204253, 37.846452999085642],
            [-85.152797, 37.897660999085225],
            [-85.126552, 37.917168999085078],
            [-85.030528, 37.891537999085273],
            [-85.031456, 37.881596999085353],
            [-85.00052, 37.854722999085567],
            [-85.025021, 37.678853999087011],
            [-85.026526, 37.664426999087119],
            [-85.030172, 37.631269999087408],
            [-85.166084, 37.6311099990874],
            [-85.349334, 37.655662999087205],
            [-85.401784, 37.730657999086581]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "067",
        COUNTYNS: "00516880",
        AFFGEOID: "0500000US21067",
        GEOID: "21067",
        NAME: "Fayette",
        LSAD: "06",
        ALAND: 734615998,
        AWATER: 4922111
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.625079, 38.116396999083491],
            [-84.609533, 38.13354599908336],
            [-84.401847, 38.20788899908279],
            [-84.35523, 38.178473999083025],
            [-84.379613, 38.111455999083532],
            [-84.286461, 38.067027999083876],
            [-84.282629, 38.019102999084268],
            [-84.348874, 37.898542999085208],
            [-84.337393, 37.892006999085268],
            [-84.386165, 37.845489999085643],
            [-84.43572, 37.847201999085627],
            [-84.479629, 37.940218999084891],
            [-84.570703, 37.977586999084586],
            [-84.594057, 37.986876999084515],
            [-84.66034, 38.00383599908438],
            [-84.625079, 38.116396999083491]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "003",
        COUNTYNS: "00558397",
        AFFGEOID: "0500000US22003",
        GEOID: "22003",
        NAME: "Allen",
        LSAD: "15",
        ALAND: 1973083800,
        AWATER: 10634064
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.130294, 30.597885999172806],
            [-92.97917, 30.598339999172794],
            [-92.978453, 30.878174999168408],
            [-92.828298, 30.875741999168444],
            [-92.823932, 30.889999999168218],
            [-92.622716, 30.899091999168089],
            [-92.597741, 30.896100999168119],
            [-92.583508, 30.562689999173354],
            [-92.630347, 30.487872999174549],
            [-92.773976, 30.488283999174545],
            [-92.775737, 30.437616999175358],
            [-93.065543, 30.438568999175338],
            [-93.131155, 30.423611999175577],
            [-93.130294, 30.597885999172806]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "039",
        COUNTYNS: "01719605",
        AFFGEOID: "0500000US30039",
        GEOID: "30039",
        NAME: "Granite",
        LSAD: "06",
        ALAND: 4473328965,
        AWATER: 14546368
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.874651, 46.508328999058143],
            [-113.811703, 46.589069999058303],
            [-113.827743, 46.66089599905844],
            [-113.667996, 46.658371999058438],
            [-113.668166, 46.716228999058551],
            [-113.479856, 46.744713999058597],
            [-113.479832, 46.802771999058734],
            [-113.302903, 46.832158999058784],
            [-113.115614, 46.713386999058542],
            [-113.039134, 46.735549999058584],
            [-113.036825, 46.266825999057744],
            [-113.036818, 46.223329999057682],
            [-113.284532, 46.222782999057692],
            [-113.278012, 46.045267999057444],
            [-113.378448, 46.01862999905741],
            [-113.517409, 45.94008899905733],
            [-113.648358, 45.965037999057358],
            [-113.687673, 46.035405999057438],
            [-113.803677, 46.037674999057451],
            [-113.753246, 46.11279999905755],
            [-113.821174, 46.212465999057663],
            [-113.750527, 46.27214599905777],
            [-113.785795, 46.2880769990578],
            [-113.782032, 46.359517999057907],
            [-113.849218, 46.440977999058049],
            [-113.874651, 46.508328999058143]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "095",
        COUNTYNS: "00659493",
        AFFGEOID: "0500000US27095",
        GEOID: "27095",
        NAME: "Mille Lacs",
        LSAD: "06",
        ALAND: 1482348668,
        AWATER: 282998978
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.810802, 46.234294999057695],
            [-93.796281, 46.243198999057711],
            [-93.430648, 46.246411999057727],
            [-93.431831, 46.153990999057591],
            [-93.432492, 45.981397999057371],
            [-93.51808, 45.981788999057379],
            [-93.513683, 45.734317999057112],
            [-93.510704, 45.558771999056972],
            [-93.759435, 45.559645999056976],
            [-93.763948, 45.822142999057199],
            [-93.810321, 45.983079999057374],
            [-93.810362, 46.1565849990576],
            [-93.810802, 46.234294999057695]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "145",
        COUNTYNS: "00695793",
        AFFGEOID: "0500000US28145",
        GEOID: "28145",
        NAME: "Union",
        LSAD: "06",
        ALAND: 1076399403,
        AWATER: 3387488
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.246133, 34.495632999118932],
            [-89.245645, 34.583233999117908],
            [-89.22228, 34.597628999117745],
            [-89.088488, 34.598246999117734],
            [-88.73544, 34.596482999117754],
            [-88.736465, 34.508909999118778],
            [-88.789442, 34.494929999118938],
            [-88.824966, 34.364758999120482],
            [-88.92887, 34.379239999120301],
            [-89.246339, 34.379343999120309],
            [-89.246133, 34.495632999118932]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "021",
        COUNTYNS: "00516857",
        AFFGEOID: "0500000US21021",
        GEOID: "21021",
        NAME: "Boyle",
        LSAD: "06",
        ALAND: 467093981,
        AWATER: 6460546
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.030172, 37.631269999087408],
            [-85.026526, 37.664426999087119],
            [-85.025021, 37.678853999087011],
            [-84.793574, 37.717248999086685],
            [-84.744888, 37.713074999086722],
            [-84.652294, 37.65089199908725],
            [-84.658296, 37.634500999087372],
            [-84.65787, 37.618277999087518],
            [-84.847183, 37.548454999088101],
            [-84.8981, 37.532112999088234],
            [-85.039674, 37.545231999088124],
            [-85.030172, 37.631269999087408]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "028",
        COUNTYNS: "01702366",
        AFFGEOID: "0500000US35028",
        GEOID: "35028",
        NAME: "Los Alamos",
        LSAD: "06",
        ALAND: 282821648,
        AWATER: 229869
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.399713, 35.960094999102864],
            [-106.248161, 35.966326999102812],
            [-106.245649, 35.930741999103162],
            [-106.248257, 35.839177999104102],
            [-106.171852, 35.827759999104224],
            [-106.250499, 35.755817999104963],
            [-106.418778, 35.819985999104318],
            [-106.399713, 35.960094999102864]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "117",
        COUNTYNS: "01026328",
        AFFGEOID: "0500000US37117",
        GEOID: "37117",
        NAME: "Martin",
        LSAD: "06",
        ALAND: 1182135018,
        AWATER: 746610
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.402614, 36.004965999102403],
            [-77.340038, 36.063997999101815],
            [-77.327569, 36.071360999101742],
            [-77.205817, 36.005322999102411],
            [-77.195882, 35.936800999103099],
            [-77.027544, 35.929682999103171],
            [-77.04405, 35.861620999103877],
            [-77.00525, 35.858432999103911],
            [-76.93904, 35.901509999103475],
            [-76.894022, 35.861936999103868],
            [-76.891106, 35.812653999104391],
            [-76.834825, 35.88290999910366],
            [-76.760598, 35.864886999103838],
            [-76.777404, 35.857838999103912],
            [-76.847264, 35.71998199910535],
            [-76.985842, 35.65884799910598],
            [-77.174, 35.732830999105204],
            [-77.2552, 35.78635199910466],
            [-77.350369, 35.819227999104314],
            [-77.342418, 35.905261999103431],
            [-77.402614, 36.004965999102403]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "091",
        COUNTYNS: "01213680",
        AFFGEOID: "0500000US42091",
        GEOID: "42091",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 1250824558,
        AWATER: 11016543
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.638828, 40.312771999069099],
            [-75.529694, 40.446994999068409],
            [-75.490104, 40.422191999068531],
            [-75.484057, 40.418450999068554],
            [-75.243171, 40.274394999069315],
            [-75.015066, 40.137991999070067],
            [-75.10745, 40.048598999070542],
            [-75.12632, 40.055480999070504],
            [-75.22361, 40.092906999070301],
            [-75.264431, 40.054093999070517],
            [-75.205771, 40.011628999070766],
            [-75.249214, 39.990546999070872],
            [-75.276482, 39.976958999070952],
            [-75.311432, 40.019809999070709],
            [-75.349457, 40.052904999070527],
            [-75.361066, 40.065542999070452],
            [-75.456501, 40.088057999070323],
            [-75.461729, 40.103034999070246],
            [-75.560154, 40.209445999069658],
            [-75.696782, 40.241862999069482],
            [-75.638828, 40.312771999069099]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "041",
        COUNTYNS: "01266994",
        AFFGEOID: "0500000US46041",
        GEOID: "46041",
        NAME: "Dewey",
        LSAD: "06",
        ALAND: 5963430685,
        AWATER: 369819592
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.486593, 45.038373999056766],
            [-101.486348, 45.385589999056869],
            [-101.47019, 45.472415999056913],
            [-100.341924, 45.472903999056918],
            [-100.274176, 45.381844999056867],
            [-100.307384, 45.29190899905683],
            [-100.260223, 45.246809999056822],
            [-100.325451, 45.099336999056774],
            [-100.27965, 45.019557999056765],
            [-100.391818, 45.026005999056757],
            [-100.404753, 44.89769499905676],
            [-100.418682, 44.843365999056772],
            [-100.529636, 44.766300999056774],
            [-100.609036, 44.769427999056788],
            [-100.676387, 44.823161999056772],
            [-100.717644, 44.770929999056769],
            [-100.910312, 44.783736999056785],
            [-101.022611, 44.724152999056805],
            [-101.136838, 44.747125999056792],
            [-101.136162, 44.994073999056774],
            [-101.500512, 44.99377999905677],
            [-101.486593, 45.038373999056766]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "127",
        COUNTYNS: "01648579",
        AFFGEOID: "0500000US47127",
        GEOID: "47127",
        NAME: "Moore",
        LSAD: "06",
        ALAND: 334684865,
        AWATER: 3093435
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.525306, 35.354717999109234],
            [-86.422338, 35.31884599910962],
            [-86.25759, 35.412475999108608],
            [-86.279905, 35.387451999108869],
            [-86.261489, 35.333578999109463],
            [-86.245289, 35.323736999109563],
            [-86.301597, 35.23524099911053],
            [-86.318168, 35.126985999111717],
            [-86.363363, 35.123029999111765],
            [-86.392192, 35.235380999110525],
            [-86.52728, 35.311535999109701],
            [-86.525306, 35.354717999109234]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "189",
        COUNTYNS: "01639802",
        AFFGEOID: "0500000US47189",
        GEOID: "47189",
        NAME: "Wilson",
        LSAD: "06",
        ALAND: 1479053189,
        AWATER: 32002365
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.594286, 36.245104999100008],
            [-86.552842, 36.319065999099301],
            [-86.521642, 36.278098999099683],
            [-86.451721, 36.335628999099121],
            [-86.298452, 36.313633999099331],
            [-86.283256, 36.347499999099021],
            [-86.264436, 36.299717999099478],
            [-86.20656, 36.342658999099058],
            [-86.136803, 36.295858999099515],
            [-86.115607, 36.26888599909978],
            [-86.059706, 36.086023999101606],
            [-86.063413, 36.07005199910175],
            [-86.014849, 35.961059999102851],
            [-86.090502, 35.974450999102721],
            [-86.153214, 35.954398999102928],
            [-86.251731, 35.961140999102852],
            [-86.515589, 36.100609999101444],
            [-86.556537, 36.135266999101098],
            [-86.594286, 36.245104999100008]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "023",
        COUNTYNS: "01383797",
        AFFGEOID: "0500000US48023",
        GEOID: "48023",
        NAME: "Baylor",
        LSAD: "06",
        ALAND: 2246768188,
        AWATER: 86991036
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.47565, 33.834004999126954],
            [-98.953088, 33.833999999126966],
            [-98.953938, 33.397533999132499],
            [-99.472444, 33.399022999132484],
            [-99.474498, 33.733848999128199],
            [-99.47565, 33.834004999126954]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "401",
        COUNTYNS: "01383986",
        AFFGEOID: "0500000US48401",
        GEOID: "48401",
        NAME: "Rusk",
        LSAD: "06",
        ALAND: 2393244478,
        AWATER: 37217197
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.985411, 32.370819999146342],
            [-94.591703, 32.365571999146411],
            [-94.579219, 32.394915999146001],
            [-94.554971, 32.407602999145823],
            [-94.491818, 32.394136999146006],
            [-94.530714, 32.271841999147732],
            [-94.5805, 32.201362999148728],
            [-94.599978, 31.973192999151994],
            [-94.511431, 31.973983999151979],
            [-94.511137, 31.923878999152699],
            [-94.452416, 31.844280999153856],
            [-94.937532, 31.84555499915384],
            [-94.984768, 31.846143999153828],
            [-94.985272, 32.13798899914962],
            [-94.985411, 32.370819999146342]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "065",
        COUNTYNS: "01383818",
        AFFGEOID: "0500000US48065",
        GEOID: "48065",
        NAME: "Carson",
        LSAD: "06",
        ALAND: 2383359749,
        AWATER: 10064086
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.6228, 35.620195999106393],
            [-101.622831, 35.624056999106351],
            [-101.086068, 35.625266999106344],
            [-101.085935, 35.619101999106405],
            [-101.086281, 35.182139999111108],
            [-101.622941, 35.183116999111107],
            [-101.6228, 35.620195999106393]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "017",
        COUNTYNS: "01448023",
        AFFGEOID: "0500000US49017",
        GEOID: "49017",
        NAME: "Garfield",
        LSAD: "06",
        ALAND: 13403572555,
        AWATER: 85610784
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.689221, 37.805589999085967],
            [-112.57855, 37.804539999085982],
            [-112.588402, 37.890422999085288],
            [-112.468177, 37.890463999085291],
            [-112.47868, 38.147418999083257],
            [-112.444214, 38.150000999083233],
            [-111.951349, 38.145982999083273],
            [-111.843215, 38.151279999083229],
            [-111.635194, 38.150086999083236],
            [-110.687406, 38.151384999083227],
            [-109.927995, 38.151883999083225],
            [-110.009494, 38.109655999083557],
            [-110.077417, 37.998866999084427],
            [-110.13669, 38.005385999084382],
            [-110.202382, 37.946535999084837],
            [-110.214949, 37.89255499908527],
            [-110.404612, 37.879922999085366],
            [-110.428107, 37.7789869990862],
            [-110.486917, 37.703554999086805],
            [-110.501594, 37.649999999087242],
            [-110.56308, 37.63781199908734],
            [-110.646394, 37.541062999088169],
            [-111.250719, 37.539973999088176],
            [-111.84317, 37.535743999088204],
            [-112.38192, 37.542867999088152],
            [-112.68375, 37.543691999088139],
            [-112.689221, 37.805589999085967]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "027",
        COUNTYNS: "01383799",
        AFFGEOID: "0500000US48027",
        GEOID: "48027",
        NAME: "Bell",
        LSAD: "06",
        ALAND: 2721755826,
        AWATER: 95727690
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.9071, 31.069373999165453],
            [-97.846428, 31.100609999164977],
            [-97.815854, 31.11620999916472],
            [-97.418606, 31.320201999161633],
            [-97.343426, 31.244214999162779],
            [-97.278113, 31.279798999162235],
            [-97.070188, 30.986219999166728],
            [-97.259082, 30.889595999168225],
            [-97.315507, 30.75237099917037],
            [-97.62491, 30.870333999168523],
            [-97.828512, 30.906187999167972],
            [-97.840365, 30.929317999167608],
            [-97.911684, 31.034918999165981],
            [-97.9071, 31.069373999165453]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "049",
        COUNTYNS: "01448038",
        AFFGEOID: "0500000US49049",
        GEOID: "49049",
        NAME: "Utah",
        LSAD: "06",
        ALAND: 5189271229,
        AWATER: 364277381
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.212254, 40.459113999068343],
            [-112.172862, 40.467064999068299],
            [-111.982136, 40.414216999068572],
            [-111.913664, 40.452439999068382],
            [-111.913313, 40.452522999068378],
            [-111.60617, 40.564622999067808],
            [-111.593942, 40.577065999067742],
            [-111.575028, 40.486525999068199],
            [-111.617505, 40.438017999068457],
            [-111.555235, 40.362695999068848],
            [-111.480337, 40.362168999068849],
            [-111.426482, 40.286905999069241],
            [-111.31483, 40.287565999069244],
            [-111.278762, 40.258792999069385],
            [-111.225507, 40.1673239990699],
            [-111.249339, 40.05521699907051],
            [-111.205772, 40.047367999070566],
            [-111.083061, 39.943197999071138],
            [-111.082455, 39.900154999071397],
            [-110.891655, 39.899653999071404],
            [-110.857647, 39.899706999071405],
            [-110.85778, 39.813284999071904],
            [-111.247496, 39.813027999071906],
            [-111.641944, 39.81286899907191],
            [-111.766325, 39.810816999071918],
            [-111.73793, 39.864099999071605],
            [-111.811116, 39.93973799907117],
            [-111.82529, 39.947695999071122],
            [-111.909464, 39.894570999071426],
            [-111.969467, 39.807107999071938],
            [-112.089843, 39.781927999072089],
            [-112.053511, 39.893396999071442],
            [-112.1798, 40.011655999070754],
            [-112.146856, 40.177816999069833],
            [-112.179693, 40.232239999069535],
            [-112.175533, 40.335836999068988],
            [-112.212254, 40.459113999068343]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "121",
        COUNTYNS: "01804541",
        AFFGEOID: "0500000US72121",
        GEOID: "72121",
        NAME: "Sabana Grande",
        LSAD: "13",
        ALAND: 94050249,
        AWATER: 25323
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.97085, 18.12763199944143],
            [-66.977855, 18.143798999441],
            [-66.926517, 18.151266999440804],
            [-66.901033, 18.039853999443764],
            [-66.884653, 18.024815999444172],
            [-66.910887, 18.02388299944419],
            [-66.958748, 18.032476999443965],
            [-66.983337, 18.052517999443427],
            [-66.97085, 18.12763199944143]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "083",
        COUNTYNS: "01804522",
        AFFGEOID: "0500000US72083",
        GEOID: "72083",
        NAME: "Las Marías",
        LSAD: "13",
        ALAND: 120071822,
        AWATER: 376224
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.082002, 18.25494599943805],
            [-67.037935, 18.289704999437134],
            [-67.03819, 18.280469999437379],
            [-66.932119, 18.253386999438103],
            [-66.907236, 18.253089999438107],
            [-66.897964, 18.187743999439832],
            [-66.97838, 18.207915999439301],
            [-67.019836, 18.195470999439628],
            [-67.055921, 18.245356999438307],
            [-67.082002, 18.25494599943805]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "047",
        COUNTYNS: "01689162",
        AFFGEOID: "0500000US54047",
        GEOID: "54047",
        NAME: "McDowell",
        LSAD: "06",
        ALAND: 1381646897,
        AWATER: 3733285
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.984891, 37.454314999088894],
            [-81.932279, 37.511960999088402],
            [-81.890889, 37.526831999088273],
            [-81.855939, 37.548909999088103],
            [-81.817937, 37.506609999088447],
            [-81.654431, 37.523311999088314],
            [-81.465845, 37.48470299908864],
            [-81.311201, 37.424508999089163],
            [-81.362156, 37.337686999089897],
            [-81.427946, 37.271014999090475],
            [-81.483559, 37.250603999090643],
            [-81.53307, 37.223413999090894],
            [-81.560625, 37.206662999091037],
            [-81.678603, 37.202466999091072],
            [-81.739055030485488, 37.239497102963554],
            [-81.744003, 37.242527999090726],
            [-81.774747, 37.274846999090443],
            [-81.849949, 37.285226999090355],
            [-81.896001, 37.33196699908995],
            [-81.933601, 37.389216999089456],
            [-81.93695, 37.419919999089196],
            [-81.984891, 37.454314999088894]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "063",
        COUNTYNS: "01529225",
        AFFGEOID: "0500000US53063",
        GEOID: "53063",
        NAME: "Spokane",
        LSAD: "06",
        ALAND: 4568458718,
        AWATER: 43434957
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.820947, 47.825774999061501],
            [-117.697774, 47.856277999061618],
            [-117.535262, 47.794457999061407],
            [-117.535262, 48.047778999062274],
            [-117.436993, 48.047206999062261],
            [-117.041214453461009, 48.045465102865059],
            [-117.041306446459004, 47.977455919088811],
            [-117.041634, 47.735299999061212],
            [-117.040490375568012, 47.366028885758105],
            [-117.040159751863015, 47.25927199905982],
            [-117.823629, 47.260219999059821],
            [-117.820947, 47.825774999061501]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "181",
        COUNTYNS: "00485053",
        AFFGEOID: "0500000US20181",
        GEOID: "20181",
        NAME: "Sherman",
        LSAD: "06",
        ALAND: 2735193304,
        AWATER: 548248
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.049961857827995, 39.568178979746477],
            [-101.413913, 39.56836099907337],
            [-101.389068, 39.568368999073378],
            [-101.391717, 39.135116999076146],
            [-101.478195, 39.134760999076143],
            [-102.047200721701003, 39.133146708587354],
            [-102.04896, 39.373711999074601],
            [-102.049961857827995, 39.568178979746477]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "037",
        COUNTYNS: "01448032",
        AFFGEOID: "0500000US49037",
        GEOID: "49037",
        NAME: "San Juan",
        LSAD: "06",
        ALAND: 20252985182,
        AWATER: 293755887
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.412783987872999, 37.001477686248876],
            [-111.314856, 37.012132999092771],
            [-111.268784, 37.054199999092397],
            [-111.247488, 37.02160399909269],
            [-111.178349, 37.102612999091967],
            [-111.05767, 37.099394999091984],
            [-110.958333, 37.124437999091775],
            [-110.899517, 37.176152999091308],
            [-110.873704, 37.28699199909034],
            [-110.837202, 37.299291999090229],
            [-110.869804, 37.348690999089797],
            [-110.777799, 37.322991999090029],
            [-110.736098, 37.345191999089835],
            [-110.699197, 37.430690999089094],
            [-110.745999, 37.452690999088908],
            [-110.671897, 37.477490999088701],
            [-110.646394, 37.541062999088169],
            [-110.56308, 37.63781199908734],
            [-110.501594, 37.649999999087242],
            [-110.486917, 37.703554999086805],
            [-110.428107, 37.7789869990862],
            [-110.404612, 37.879922999085366],
            [-110.214949, 37.89255499908527],
            [-110.202382, 37.946535999084837],
            [-110.13669, 38.005385999084382],
            [-110.077417, 37.998866999084427],
            [-110.009494, 38.109655999083557],
            [-109.927995, 38.151883999083225],
            [-109.887266, 38.190213999082928],
            [-109.931973, 38.284252999082206],
            [-110.031973, 38.384956999081453],
            [-110.044544, 38.452623999080934],
            [-110.025402, 38.499980999080591],
            [-109.059962, 38.499986999080583],
            [-109.060062, 38.27548899908227],
            [-109.041762, 38.164689999083123],
            [-109.041796614855002, 38.153032304051713],
            [-109.042604100621006, 37.88108481657796],
            [-109.043780720502994, 37.484819230506147],
            [-109.045223, 36.999083999092889],
            [-109.495338, 36.999104999092893],
            [-110.000677, 36.997967999092907],
            [-110.000681818589001, 36.997967999390504],
            [-110.47019, 36.997996999092905],
            [-110.750686478927989, 37.003196933818053],
            [-110.75069, 37.003196999092864],
            [-111.066496, 37.002388999092865],
            [-111.278286, 37.000464999092891],
            [-111.405869, 37.001480999092863],
            [-111.412783987872999, 37.001477686248876]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "011",
        COUNTYNS: "01265779",
        AFFGEOID: "0500000US46011",
        GEOID: "46011",
        NAME: "Brookings",
        LSAD: "06",
        ALAND: 2051804710,
        AWATER: 32967600
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.128024, 44.543125999056862],
            [-96.885504, 44.543772999056863],
            [-96.453291313598683, 44.543636800874857],
            [-96.452213, 44.36014899905696],
            [-96.452435824595497, 44.196801585722092],
            [-96.452435854669488, 44.196779539152189],
            [-96.889216, 44.195704999057092],
            [-97.129671, 44.195932999057085],
            [-97.128024, 44.543125999056862]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "071",
        COUNTYNS: "01265794",
        AFFGEOID: "0500000US46071",
        GEOID: "46071",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 4827418440,
        AWATER: 19106241
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.139158, 43.700947999057654],
            [-102.033477, 43.689341999057675],
            [-102.018887, 43.708617999057665],
            [-102.007336, 43.994529999057285],
            [-101.064068, 43.994928999057294],
            [-101.064326, 43.841089999057466],
            [-101.217768, 43.803846999057519],
            [-101.228234, 43.389184999058173],
            [-102.110819, 43.389901999058168],
            [-102.139051, 43.476675999058003],
            [-102.139158, 43.700947999057654]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "117",
        COUNTYNS: "01639771",
        AFFGEOID: "0500000US47117",
        GEOID: "47117",
        NAME: "Marshall",
        LSAD: "06",
        ALAND: 972442316,
        AWATER: 1801922
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.960804, 35.417020999108558],
            [-86.849573, 35.525514999107394],
            [-86.782016, 35.706594999105491],
            [-86.751651, 35.696446999105596],
            [-86.686193, 35.710050999105448],
            [-86.639436, 35.685899999105708],
            [-86.664164, 35.49177099910775],
            [-86.618572, 35.367400999109094],
            [-86.599478, 35.364949999109115],
            [-86.602613, 35.327012999109535],
            [-86.746899, 35.252877999110339],
            [-86.828301, 35.263676999110224],
            [-86.906451, 35.314074999109678],
            [-86.960804, 35.417020999108558]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "131",
        COUNTYNS: "00465254",
        AFFGEOID: "0500000US19131",
        GEOID: "19131",
        NAME: "Mitchell",
        LSAD: "06",
        ALAND: 1215049040,
        AWATER: 1118415
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.024347900541883, 43.499556162687981],
            [-92.870277, 43.499547999057974],
            [-92.553161, 43.500299999057987],
            [-92.553128090518783, 43.500300035370273],
            [-92.554381, 43.212812999058499],
            [-93.024092, 43.212811999058495],
            [-93.024143, 43.255537999058419],
            [-93.024347900541883, 43.499556162687981]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "121",
        COUNTYNS: "00424259",
        AFFGEOID: "0500000US17121",
        GEOID: "17121",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 1482448747,
        AWATER: 9481542
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.143898, 38.50308799908057],
            [-89.138393, 38.736330999078888],
            [-89.138138, 38.824243999078277],
            [-88.695165, 38.826298999078261],
            [-88.698457, 38.606303999079813],
            [-88.698961, 38.474913999080783],
            [-89.144388, 38.473877999080784],
            [-89.143898, 38.50308799908057]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "085",
        COUNTYNS: "00559489",
        AFFGEOID: "0500000US22085",
        GEOID: "22085",
        NAME: "Sabine",
        LSAD: "15",
        ALAND: 2244636627,
        AWATER: 375317817
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.878251895386597, 31.84427655639945],
            [-93.441171, 31.84523299915384],
            [-93.441743, 31.714484999155751],
            [-93.338972, 31.71425899915576],
            [-93.337456, 31.538109999158365],
            [-93.236662, 31.538338999158356],
            [-93.236104, 31.364506999160955],
            [-93.390495, 31.364146999160969],
            [-93.442554, 31.276715999162285],
            [-93.535414, 31.276401999162282],
            [-93.552497307502307, 31.184820022610481],
            [-93.600603087362884, 31.182624954432516],
            [-93.602443, 31.182540999163713],
            [-93.613942, 31.259374999162549],
            [-93.67544, 31.301039999161912],
            [-93.668146, 31.375102999160809],
            [-93.697603, 31.428408999159998],
            [-93.749476, 31.468689999159402],
            [-93.725925, 31.504091999158877],
            [-93.787687, 31.527343999158525],
            [-93.834923214477783, 31.586210020235644],
            [-93.834924, 31.586210999157647],
            [-93.816838, 31.622508999157102],
            [-93.803419, 31.700685999155958],
            [-93.85339, 31.805466999154426],
            [-93.878251895386597, 31.84427655639945]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "069",
        COUNTYNS: "00558108",
        AFFGEOID: "0500000US22069",
        GEOID: "22069",
        NAME: "Natchitoches",
        LSAD: "15",
        ALAND: 3243341073,
        AWATER: 121033890
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.441171, 31.84523299915384],
            [-93.419781, 31.934057999152554],
            [-93.355452, 31.932221999152585],
            [-93.239501, 31.903519999152991],
            [-93.23847, 31.972780999151993],
            [-93.121188, 31.972609999151999],
            [-93.187162, 32.148411999149481],
            [-92.93908, 32.147941999149488],
            [-92.872174, 31.945855999152386],
            [-92.9668, 31.781298999154778],
            [-92.973529, 31.708921999155841],
            [-92.964615, 31.680720999156243],
            [-92.908313, 31.625168999157069],
            [-92.806206, 31.598547999157468],
            [-92.718983, 31.517593999158667],
            [-92.947095, 31.346750999161227],
            [-92.981464, 31.346643999161227],
            [-92.991988, 31.360472999161029],
            [-93.236104, 31.364506999160955],
            [-93.236662, 31.538338999158356],
            [-93.337456, 31.538109999158365],
            [-93.338972, 31.71425899915576],
            [-93.441743, 31.714484999155751],
            [-93.441171, 31.84523299915384]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "249",
        COUNTYNS: "00344129",
        AFFGEOID: "0500000US13249",
        GEOID: "13249",
        NAME: "Schley",
        LSAD: "06",
        ALAND: 432287212,
        AWATER: 2373636
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.429451, 32.232150999148288],
            [-84.389927, 32.297236999147366],
            [-84.392316, 32.414045999145728],
            [-84.381817, 32.428046999145536],
            [-84.254613, 32.37205299914632],
            [-84.255912, 32.296155999147381],
            [-84.219211, 32.295056999147398],
            [-84.181657, 32.22970299914833],
            [-84.183569, 32.158492999149331],
            [-84.430218, 32.166256999149233],
            [-84.429451, 32.232150999148288]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "015",
        COUNTYNS: "00596115",
        AFFGEOID: "0500000US24015",
        GEOID: "24015",
        NAME: "Cecil",
        LSAD: "06",
        ALAND: 896857451,
        AWATER: 185336236
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.233279347116593, 39.721646254700339],
            [-76.135697426990191, 39.721767549679136],
            [-75.812079984846804, 39.722169808322342],
            [-75.788596, 39.72219899907244],
            [-75.76690460670919, 39.377651592625767],
            [-75.981109, 39.366568999074644],
            [-76.061498821955695, 39.387748113189609],
            [-76.040962, 39.394236999074465],
            [-76.00688, 39.414526999074333],
            [-76.012312, 39.453114999074089],
            [-76.037646288315798, 39.452642381383399],
            [-76.041976, 39.494227999073829],
            [-76.094472, 39.567809999073383],
            [-76.212676, 39.686404999072657],
            [-76.233279347116593, 39.721646254700339]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "201",
        COUNTYNS: "01383886",
        AFFGEOID: "0500000US48201",
        GEOID: "48201",
        NAME: "Harris",
        LSAD: "06",
        ALAND: 4415733152,
        AWATER: 187863191
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.844741, 30.088913999180992],
            [-95.80333, 30.090095999180964],
            [-95.691213, 30.098727999180834],
            [-95.57463, 30.151782999179964],
            [-95.551421, 30.17060599917966],
            [-95.500893, 30.1434029991801],
            [-95.491123, 30.110367999180639],
            [-95.405443, 30.092075999180942],
            [-95.292681, 30.027760999181989],
            [-95.096708, 30.167213999179708],
            [-95.035692, 30.005063999182358],
            [-95.026772, 29.996738999182504],
            [-94.999834, 29.991321999182592],
            [-94.98146, 29.884275999184357],
            [-94.908663, 29.826069999185322],
            [-94.913813, 29.766299999186323],
            [-95.015039, 29.631949999188571],
            [-95.018205, 29.554596999189869],
            [-95.065065, 29.558477999189805],
            [-95.117139, 29.508030999190652],
            [-95.163927, 29.498050999190824],
            [-95.200985, 29.563211999189729],
            [-95.213826, 29.555324999189853],
            [-95.216422, 29.556079999189837],
            [-95.253856, 29.553777999189883],
            [-95.286493, 29.597885999189145],
            [-95.297411, 29.596521999189157],
            [-95.331146, 29.599107999189116],
            [-95.424118, 29.58023299918943],
            [-95.446339, 29.59069799918926],
            [-95.571345, 29.648740999188281],
            [-95.621848, 29.673571999187871],
            [-95.652144, 29.690945999187576],
            [-95.654265, 29.691654999187559],
            [-95.687082, 29.710077999187252],
            [-95.688349, 29.710861999187248],
            [-95.751446, 29.74647199918665],
            [-95.82622, 29.788279999185953],
            [-95.960733, 30.163414999179778],
            [-95.844741, 30.088913999180992]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "105",
        COUNTYNS: "00069171",
        AFFGEOID: "0500000US05105",
        GEOID: "05105",
        NAME: "Perry",
        LSAD: "06",
        ALAND: 1428543051,
        AWATER: 23226441
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.305684, 34.875591999114548],
            [-93.040525, 35.076922999112284],
            [-92.618827, 35.078014999112277],
            [-92.554685, 35.113276999111875],
            [-92.597762, 34.995826999113191],
            [-92.544341, 34.953318999113669],
            [-92.753626, 34.911247999114131],
            [-92.755087, 34.871794999114584],
            [-92.739779, 34.8534589991148],
            [-93.073877, 34.858090999114751],
            [-93.075178, 34.771891999115724],
            [-93.287785, 34.773192999115714],
            [-93.305684, 34.875591999114548]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "730",
        COUNTYNS: "01498435",
        AFFGEOID: "0500000US51730",
        GEOID: "51730",
        NAME: "Petersburg",
        LSAD: "25",
        ALAND: 58813627,
        AWATER: 609780
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.447408, 37.223065999090906],
            [-77.416501, 37.233207999090808],
            [-77.41542, 37.233795999090802],
            [-77.413559, 37.233948999090792],
            [-77.413119, 37.234256999090796],
            [-77.409877, 37.233722999090809],
            [-77.409183, 37.234271999090801],
            [-77.408023, 37.234551999090797],
            [-77.406415, 37.235644999090781],
            [-77.405034, 37.23609999909079],
            [-77.403671, 37.23734599909077],
            [-77.385773, 37.238191999090766],
            [-77.376785, 37.244848999090706],
            [-77.329767, 37.197494999091127],
            [-77.399203, 37.170849999091359],
            [-77.42347, 37.165094999091416],
            [-77.447408, 37.223065999090906]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "217",
        COUNTYNS: "01383894",
        AFFGEOID: "0500000US48217",
        GEOID: "48217",
        NAME: "Hill",
        LSAD: "06",
        ALAND: 2483455057,
        AWATER: 69547484
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.476086, 32.173459999149117],
            [-97.462821, 32.184682999148961],
            [-97.086191, 32.265450999147809],
            [-96.940656, 32.052086999150845],
            [-96.896209, 32.073976999150545],
            [-96.719114, 31.814886999154286],
            [-96.932215, 31.70888699915584],
            [-97.037266, 31.863078999153579],
            [-97.277265, 31.745491999155291],
            [-97.32653, 31.788359999154668],
            [-97.28206, 31.843151999153875],
            [-97.382848, 31.870787999153475],
            [-97.438765, 31.933505999152558],
            [-97.379992, 31.972739999151997],
            [-97.485968, 32.017610999151344],
            [-97.476086, 32.173459999149117]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "083",
        COUNTYNS: "00424243",
        AFFGEOID: "0500000US17083",
        GEOID: "17083",
        NAME: "Jersey",
        LSAD: "06",
        ALAND: 956433111,
        AWATER: 20324168
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.580587, 39.184894999075816],
            [-90.313531, 39.174279999075885],
            [-90.314071, 39.225052999075551],
            [-90.148069, 39.261946999075313],
            [-90.145991, 39.000045999077066],
            [-90.273686, 38.999346999077055],
            [-90.276583722025606, 38.919338466289204],
            [-90.298711, 38.92339499907758],
            [-90.395816, 38.960036999077325],
            [-90.450969940891895, 38.961395002401822],
            [-90.467766, 38.96920799907727],
            [-90.534657, 38.958498999077335],
            [-90.603569, 39.117591999076261],
            [-90.580587, 39.184894999075816]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "053",
        COUNTYNS: "00277291",
        AFFGEOID: "0500000US06053",
        GEOID: "06053",
        NAME: "Monterey",
        LSAD: "06",
        ALAND: 8496680819,
        AWATER: 1270736099
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.970427, 36.582753999096745],
            [-121.923866, 36.634558999096257],
            [-121.860604, 36.611135999096476],
            [-121.814462, 36.682857999095802],
            [-121.796826, 36.777542999094926],
            [-121.791544, 36.815185999094574],
            [-121.812732426762011, 36.850049442839762],
            [-121.699956, 36.91968299909361],
            [-121.644001, 36.893995999093846],
            [-121.597698, 36.837349999094378],
            [-121.45134, 36.72479299909542],
            [-121.468086, 36.685517999095779],
            [-121.350092, 36.648356999096116],
            [-121.318373, 36.610025999096493],
            [-121.311788, 36.50273599909751],
            [-121.244091, 36.506910999097471],
            [-121.193465, 36.44575099909806],
            [-121.040797, 36.323968999099236],
            [-121.02724, 36.259892999099868],
            [-120.920551, 36.311011999099371],
            [-120.86508, 36.292577999099542],
            [-120.761483, 36.20357999910042],
            [-120.718169, 36.196847999100491],
            [-120.717696, 36.260011999099866],
            [-120.75834, 36.30860399909939],
            [-120.678582, 36.2673189990998],
            [-120.62691, 36.203226999100437],
            [-120.667551, 36.138056999101082],
            [-120.433053, 35.968932999102783],
            [-120.315068, 35.907185999103405],
            [-120.27576, 35.905880999103431],
            [-120.213979, 35.789275999104625],
            [-120.681959, 35.790811999104612],
            [-121.347053956004004, 35.795186202919162],
            [-121.406823, 35.844622999104047],
            [-121.462264, 35.885617999103637],
            [-121.4862, 35.970347999102771],
            [-121.531876, 36.014367999102312],
            [-121.574602, 36.025155999102211],
            [-121.622009, 36.099694999101452],
            [-121.680145, 36.16581799910081],
            [-121.779851, 36.227406999100182],
            [-121.826425, 36.241859999100043],
            [-121.888491, 36.302809999099452],
            [-121.903195, 36.393602999098555],
            [-121.9416, 36.485601999097675],
            [-121.970427, 36.582753999096745]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "129",
        COUNTYNS: "00450383",
        AFFGEOID: "0500000US18129",
        GEOID: "18129",
        NAME: "Posey",
        LSAD: "06",
        ALAND: 1060786892,
        AWATER: 25260282
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.059469492700785, 37.866689803096278],
            [-88.040861, 37.891766999085277],
            [-88.016311, 37.96157399908472],
            [-88.030884, 38.030712999084173],
            [-87.98877, 38.055590999083982],
            [-87.96221, 38.10005399908362],
            [-87.927468, 38.151945999083217],
            [-87.975819, 38.197833999082867],
            [-87.970200840803898, 38.230271057468421],
            [-87.799169, 38.228579999082633],
            [-87.799677, 38.201943999082836],
            [-87.688374, 38.168418999083094],
            [-87.70027, 37.907282999085155],
            [-87.713210785290286, 37.883088389451849],
            [-87.723635, 37.892057999085274],
            [-87.808013, 37.875190999085405],
            [-87.87254, 37.920998999085036],
            [-87.921744, 37.907884999085148],
            [-87.925393381709796, 37.899591476222518],
            [-87.938128, 37.870650999085434],
            [-87.903804, 37.817761999085867],
            [-87.935861, 37.789702999086103],
            [-87.970262, 37.781855999086162],
            [-88.02803, 37.799223999086017],
            [-88.067364, 37.856050999085561],
            [-88.059469492700785, 37.866689803096278]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "09",
        COUNTYFP: "003",
        COUNTYNS: "00212338",
        AFFGEOID: "0500000US09003",
        GEOID: "09003",
        NAME: "Hartford",
        LSAD: "06",
        ALAND: 1903983151,
        AWATER: 40128067
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.008762781630693, 42.038903415308326],
            [-72.999549, 42.038652999061632],
            [-72.847142, 42.03689399906164],
            [-72.810078, 41.998315999061774],
            [-72.774759316078601, 42.002129103242055],
            [-72.766739, 42.002994999061748],
            [-72.735496, 42.036398999061639],
            [-72.607933, 42.030794999061669],
            [-72.528131, 42.034294999061657],
            [-72.509179336298303, 42.034080495651757],
            [-72.492729, 41.947496999061933],
            [-72.51333, 41.945400999061953],
            [-72.51733, 41.86989699906222],
            [-72.49681, 41.860615999062254],
            [-72.463899, 41.745571999062655],
            [-72.409164, 41.6303499990631],
            [-72.410367, 41.6025809990632],
            [-72.46673, 41.583898999063265],
            [-72.505431, 41.646998999063037],
            [-72.54423, 41.642695999063051],
            [-72.713897, 41.627704999063113],
            [-72.714538, 41.604309999063197],
            [-72.752181, 41.57889399906329],
            [-72.844147, 41.544696999063426],
            [-72.94635, 41.556796999063373],
            [-72.938501, 41.644310999063038],
            [-72.98325, 41.639597999063049],
            [-72.99855, 41.712497999062791],
            [-73.016936, 41.798182999062483],
            [-72.949024, 41.806430999062428],
            [-72.905945, 41.921695999062024],
            [-72.88706, 41.973120999061869],
            [-73.029537, 41.966605999061869],
            [-73.008762781630693, 42.038903415308326]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "229",
        COUNTYNS: "01383900",
        AFFGEOID: "0500000US48229",
        GEOID: "48229",
        NAME: "Hudspeth",
        LSAD: "06",
        ALAND: 11837605523,
        AWATER: 2189943
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.997971151664004, 32.001974589850576],
            [-105.750527, 32.002205999151577],
            [-105.153994, 32.000496999151594],
            [-104.918356994280003, 32.000472072225094],
            [-104.91289, 31.625119999157079],
            [-104.916487, 30.821033999169291],
            [-104.917163, 30.663625999171764],
            [-104.980753891324994, 30.628812017960616],
            [-105.00124, 30.67258299917162],
            [-105.062334, 30.686302999171406],
            [-105.098282, 30.718913999170891],
            [-105.160153, 30.757058999170294],
            [-105.21866, 30.801566999169598],
            [-105.314863, 30.816960999169357],
            [-105.394242, 30.852978999168794],
            [-105.399609, 30.888940999168231],
            [-105.488027, 30.9432779991674],
            [-105.55743, 30.990228999166664],
            [-105.579542, 31.035395999165971],
            [-105.627349, 31.098544999164996],
            [-105.709491, 31.136374999164417],
            [-105.773257, 31.166896999163953],
            [-105.794386, 31.202239999163417],
            [-105.869353, 31.288633999162101],
            [-105.938452, 31.318734999161659],
            [-105.953943, 31.364748999160962],
            [-105.996429378287004, 31.387840128673201],
            [-105.954328, 31.423467999160074],
            [-105.993255, 31.47839199915925],
            [-105.994756, 31.679687999156268],
            [-105.997971151664004, 32.001974589850576]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "075",
        COUNTYNS: "01622980",
        AFFGEOID: "0500000US26075",
        GEOID: "26075",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 1817385089,
        AWATER: 56453210
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.718493, 42.42151999906045],
            [-84.600403, 42.421983999060437],
            [-84.140713, 42.424610999060434],
            [-84.131136, 42.424566999060438],
            [-84.131963, 42.071576999061527],
            [-84.363297, 42.07345599906153],
            [-84.709556, 42.070365999061522],
            [-84.718493, 42.42151999906045]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "197",
        COUNTYNS: "00485059",
        AFFGEOID: "0500000US20197",
        GEOID: "20197",
        NAME: "Wabaunsee",
        LSAD: "06",
        ALAND: 2057386289,
        AWATER: 13786678
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.501556, 38.869703999077942],
            [-96.501166, 39.043665999076765],
            [-96.390796, 39.043256999076753],
            [-96.389046, 39.172877999075894],
            [-96.331396, 39.158884999075987],
            [-96.233671, 39.212278999075636],
            [-96.083557, 39.193503999075766],
            [-96.03906, 39.126526999076205],
            [-95.993738, 39.133816999076153],
            [-95.946754, 39.098684999076376],
            [-95.946587, 38.869972999077952],
            [-95.945924, 38.739111999078865],
            [-96.352613, 38.739020999078868],
            [-96.389749, 38.738983999078862],
            [-96.390398, 38.825857999078259],
            [-96.501397, 38.82618799907825],
            [-96.501556, 38.869703999077942]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "145",
        COUNTYNS: "00516925",
        AFFGEOID: "0500000US21145",
        GEOID: "21145",
        NAME: "McCracken",
        LSAD: "06",
        ALAND: 644243924,
        AWATER: 50305452
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.931745, 37.227592999090852],
            [-88.928004829494299, 37.226389765343974],
            [-88.835051, 37.196485999091131],
            [-88.753068, 37.154700999091496],
            [-88.693983, 37.141154999091619],
            [-88.61144, 37.112744999091859],
            [-88.561043929472589, 37.083999980050535],
            [-88.531576, 37.067191999092273],
            [-88.490399446324702, 37.067957622040169],
            [-88.483803276979998, 37.068080268987771],
            [-88.482957, 37.022565999092677],
            [-88.485254, 37.001108999092878],
            [-88.484103, 36.942095999093411],
            [-88.812897, 36.946863999093367],
            [-88.815952, 36.954099999093302],
            [-88.933517214747795, 37.227511279005462],
            [-88.931745, 37.227592999090852]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "045",
        COUNTYNS: "00659468",
        AFFGEOID: "0500000US27045",
        GEOID: "27045",
        NAME: "Fillmore",
        LSAD: "06",
        ALAND: 2230746823,
        AWATER: 2055507
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.448965, 43.83410499905748],
            [-92.438491, 43.848240999057452],
            [-92.079292, 43.847079999057463],
            [-91.730424, 43.846934999057467],
            [-91.730216997837701, 43.500685700004077],
            [-91.824848, 43.500683999057969],
            [-92.079801993128271, 43.500704884234572],
            [-92.178863, 43.500712999057974],
            [-92.448947951437773, 43.50041498762068],
            [-92.448965, 43.83410499905748]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "153",
        COUNTYNS: "00516920",
        AFFGEOID: "0500000US21153",
        GEOID: "21153",
        NAME: "Magoffin",
        LSAD: "06",
        ALAND: 798866697,
        AWATER: 1847051
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.269662, 37.766011999086295],
            [-83.113277, 37.864120999085493],
            [-83.129258, 37.894437999085248],
            [-83.004649, 37.859246999085542],
            [-82.950582, 37.794733999086063],
            [-82.940763, 37.716337999086697],
            [-82.879082, 37.535394999088219],
            [-82.92546, 37.486524999088616],
            [-82.948544, 37.503166999088478],
            [-83.01217, 37.583473999087808],
            [-83.089796, 37.632166999087396],
            [-83.134949, 37.606811999087611],
            [-83.248692, 37.669615999087085],
            [-83.262172, 37.712656999086732],
            [-83.269662, 37.766011999086295]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "081",
        COUNTYNS: "01622983",
        AFFGEOID: "0500000US26081",
        GEOID: "26081",
        NAME: "Kent",
        LSAD: "06",
        ALAND: 2195420014,
        AWATER: 62899810
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.790448, 43.293002999058352],
            [-85.562538, 43.294270999058348],
            [-85.312376, 43.294204999058351],
            [-85.31225, 43.206437999058515],
            [-85.312513, 43.118792999058691],
            [-85.309626, 42.769877999059496],
            [-85.545564, 42.768138999059502],
            [-85.782498, 42.768199999059505],
            [-85.782106, 42.856295999059292],
            [-85.788503, 43.030876999058883],
            [-85.790662, 43.205166999058513],
            [-85.790448, 43.293002999058352]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "055",
        COUNTYNS: "00659473",
        AFFGEOID: "0500000US27055",
        GEOID: "27055",
        NAME: "Houston",
        LSAD: "06",
        ALAND: 1429784922,
        AWATER: 43495762
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.730424, 43.846934999057467],
            [-91.287655627367101, 43.847064999057466],
            [-91.243955, 43.773045999057558],
            [-91.257000156005688, 43.725658736280025],
            [-91.273252, 43.666622999057722],
            [-91.252926, 43.600362999057822],
            [-91.232812, 43.564841999057855],
            [-91.217706, 43.500549999057974],
            [-91.491042, 43.500689999057975],
            [-91.610835000554374, 43.500687845837277],
            [-91.730216997837701, 43.500685700004077],
            [-91.730424, 43.846934999057467]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "167",
        COUNTYNS: "01383869",
        AFFGEOID: "0500000US48167",
        GEOID: "48167",
        NAME: "Galveston",
        LSAD: "06",
        ALAND: 981478578,
        AWATER: 1281645343
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.216422, 29.556079999189837],
            [-95.213826, 29.555324999189853],
            [-95.200985, 29.563211999189729],
            [-95.163927, 29.498050999190824],
            [-95.117139, 29.508030999190652],
            [-95.065065, 29.558477999189805],
            [-95.018205, 29.554596999189869],
            [-94.909845, 29.496440999190849],
            [-94.791611, 29.550415999189941],
            [-94.762569, 29.52416199919038],
            [-94.545908, 29.572502999189567],
            [-94.422868, 29.566255999189668],
            [-94.370816, 29.555645987484851],
            [-94.500807, 29.505366999190699],
            [-94.594853, 29.467902999191345],
            [-94.670389, 29.430779999191966],
            [-94.731047, 29.369140999193014],
            [-94.72253, 29.331445999193658],
            [-94.803695, 29.279236999194552],
            [-95.026219, 29.148063999196811],
            [-95.115050505598276, 29.075552021676771],
            [-95.056499, 29.199976999195911],
            [-95.19219, 29.404366999192419],
            [-95.233081, 29.46556599919138],
            [-95.216422, 29.556079999189837]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "153",
        COUNTYNS: "00835898",
        AFFGEOID: "0500000US31153",
        GEOID: "31153",
        NAME: "Sarpy",
        LSAD: "06",
        ALAND: 618872493,
        AWATER: 22209229
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.327399, 41.189998999064876],
            [-96.009134, 41.190699999064876],
            [-95.909908491254797, 41.191282849892779],
            [-95.90969, 41.18439799906492],
            [-95.856788, 41.187097999064903],
            [-95.861898068209385, 41.160302347277721],
            [-95.868688, 41.12469799906517],
            [-95.863839, 41.083506999065349],
            [-95.8647847640853, 41.052845503678988],
            [-95.978778, 41.05336199906548],
            [-96.005191, 41.060623999065463],
            [-96.056256, 41.065355999065432],
            [-96.218939, 40.994307999065747],
            [-96.319191, 41.044997999065529],
            [-96.339805, 41.082501999065357],
            [-96.327399, 41.189998999064876]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "179",
        COUNTYNS: "01480173",
        AFFGEOID: "0500000US51179",
        GEOID: "51179",
        NAME: "Stafford",
        LSAD: "06",
        ALAND: 697237227,
        AWATER: 28088013
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.633895, 38.466588999080848],
            [-77.531328, 38.556549999080175],
            [-77.48483, 38.59218999907992],
            [-77.312604284119288, 38.501927799664671],
            [-77.322622, 38.46713099908083],
            [-77.317288, 38.383575999081444],
            [-77.284346499792193, 38.351636815595995],
            [-77.330094, 38.334009999081822],
            [-77.326692, 38.2451359990825],
            [-77.370297, 38.246575999082495],
            [-77.39085, 38.245588999082493],
            [-77.447126, 38.284613999082211],
            [-77.506782, 38.325924999081884],
            [-77.530283, 38.309174999082018],
            [-77.603865, 38.333577999081825],
            [-77.618727, 38.367834999081573],
            [-77.63494, 38.410217999081262],
            [-77.633895, 38.466588999080848]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "059",
        COUNTYNS: "00929115",
        AFFGEOID: "0500000US35059",
        GEOID: "35059",
        NAME: "Union",
        LSAD: "06",
        ALAND: 9903868964,
        AWATER: 18464199
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.008823, 36.21811499910028],
            [-104.007849409613002, 36.995984093802015],
            [-103.733247, 36.9980159990929],
            [-103.086101075630992, 36.999864360199282],
            [-103.002199, 37.000103999092886],
            [-103.001964, 36.909572999093712],
            [-103.002518, 36.675185999095874],
            [-103.002188, 36.60271599909656],
            [-103.002434, 36.500396999097525],
            [-103.041924, 36.500438999097526],
            [-103.040824, 36.055230999101902],
            [-103.041356721818005, 35.739433505453839],
            [-103.375117, 35.739515999105144],
            [-103.374864, 35.998656999102487],
            [-103.364977, 36.08604999910159],
            [-103.794525, 36.085737999101603],
            [-103.793991, 36.173971999100715],
            [-104.008864, 36.174526999100706],
            [-104.008823, 36.21811499910028]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "089",
        COUNTYNS: "01074057",
        AFFGEOID: "0500000US39089",
        GEOID: "39089",
        NAME: "Licking",
        LSAD: "06",
        ALAND: 1767338086,
        AWATER: 12924074
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.773825, 39.976124999070947],
            [-82.767483, 40.051027999070541],
            [-82.761827, 40.125854999070114],
            [-82.750747, 40.276995999069293],
            [-82.476114, 40.264702999069364],
            [-82.477273, 40.245809999069458],
            [-82.195911, 40.239070999069504],
            [-82.183492, 40.22725999906956],
            [-82.187105, 40.166879999069891],
            [-82.194786, 40.020693999070708],
            [-82.198728, 39.950195999071106],
            [-82.233974, 39.913259999071322],
            [-82.418666, 39.922446999071262],
            [-82.462813, 39.930379999071228],
            [-82.493407, 39.926062999071242],
            [-82.782495, 39.939661999071156],
            [-82.773825, 39.976124999070947]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "191",
        COUNTYNS: "01026128",
        AFFGEOID: "0500000US37191",
        GEOID: "37191",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 1434638257,
        AWATER: 9855192
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.30658, 35.287603999109962],
            [-78.153243, 35.350670999109269],
            [-78.15491, 35.417809999108556],
            [-78.064784, 35.585262999106753],
            [-78.048572, 35.595876999106643],
            [-77.873751, 35.586240999106749],
            [-77.822511, 35.585382999106763],
            [-77.826744, 35.424089999108475],
            [-77.806248, 35.368753999109074],
            [-77.818655, 35.356349999109213],
            [-77.834249, 35.177845999111163],
            [-77.891892, 35.146424999111503],
            [-78.044451, 35.192534999110997],
            [-78.16342, 35.18971599911103],
            [-78.173532, 35.207347999110837],
            [-78.246217, 35.225995999110623],
            [-78.30658, 35.287603999109962]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "09",
        COUNTYFP: "007",
        COUNTYNS: "00212797",
        AFFGEOID: "0500000US09007",
        GEOID: "09007",
        NAME: "Middlesex",
        LSAD: "06",
        ALAND: 956493768,
        AWATER: 180679144
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.752181, 41.57889399906329],
            [-72.714538, 41.604309999063197],
            [-72.713897, 41.627704999063113],
            [-72.54423, 41.642695999063051],
            [-72.505431, 41.646998999063037],
            [-72.46673, 41.583898999063265],
            [-72.430767, 41.524566999063509],
            [-72.322931, 41.518118999063525],
            [-72.305531, 41.436281999063851],
            [-72.43371, 41.423994999063908],
            [-72.376004, 41.376254999064102],
            [-72.340012908701908, 41.277849261302904],
            [-72.348643, 41.277445999064511],
            [-72.386629, 41.261797999064576],
            [-72.40593, 41.278397999064502],
            [-72.472539, 41.270102999064541],
            [-72.53456489716811, 41.253824273455706],
            [-72.613919, 41.329427999064286],
            [-72.653672, 41.438124999063845],
            [-72.678739, 41.433796999063865],
            [-72.746141, 41.423296999063915],
            [-72.744742, 41.497697999063604],
            [-72.748878, 41.544278999063422],
            [-72.752181, 41.57889399906329]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "039",
        COUNTYNS: "00450348",
        AFFGEOID: "0500000US18039",
        GEOID: "18039",
        NAME: "Elkhart",
        LSAD: "06",
        ALAND: 1199614707,
        AWATER: 12423391
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.06256302901059, 41.759653032220605],
            [-85.791363, 41.759050999062616],
            [-85.791334961962789, 41.759051038620107],
            [-85.659750237990906, 41.759236685109613],
            [-85.654747, 41.523346999063499],
            [-85.653507, 41.436855999063852],
            [-86.05923, 41.435811999063866],
            [-86.059451, 41.479153999063691],
            [-86.06256302901059, 41.759653032220605]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "003",
        COUNTYNS: "00516848",
        AFFGEOID: "0500000US21003",
        GEOID: "21003",
        NAME: "Allen",
        LSAD: "06",
        ALAND: 891830211,
        AWATER: 19490893
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.418888, 36.676030999095872],
            [-86.405769, 36.776186999094925],
            [-86.201417, 36.935314999093464],
            [-86.16674, 36.934014999093478],
            [-86.106421, 36.865063999094097],
            [-86.06531, 36.8991279990938],
            [-86.069957, 36.80578299909466],
            [-85.97691, 36.722721999095434],
            [-85.950661, 36.651022999096099],
            [-85.975713174144005, 36.628637717778012],
            [-86.081944, 36.633847999096275],
            [-86.205565269855981, 36.639246869425016],
            [-86.411496198966404, 36.64824042194433],
            [-86.418888, 36.676030999095872]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "001",
        COUNTYNS: "00025441",
        AFFGEOID: "0500000US04001",
        GEOID: "04001",
        NAME: "Apache",
        LSAD: "06",
        ALAND: 29001298929,
        AWATER: 54174161
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.000677, 36.997967999092907],
            [-109.495338, 36.999104999092893],
            [-109.045223, 36.999083999092889],
            [-109.045433, 36.87458899909403],
            [-109.045729, 36.117027999101282],
            [-109.045871168204982, 36.002701629621434],
            [-109.046024, 35.879799999103696],
            [-109.046296, 35.614250999106446],
            [-109.046796, 35.363605999109126],
            [-109.046355894384007, 35.175507187462379],
            [-109.045851235182994, 34.9598185149503],
            [-109.045851, 34.959717999113586],
            [-109.04613893536299, 34.579291011935851],
            [-109.046182, 34.522392999118622],
            [-109.046426, 33.875051999126448],
            [-109.046607473921995, 33.778223799478162],
            [-109.347968, 33.777455999127653],
            [-109.364621, 33.738755999128145],
            [-109.480135, 33.691409999128744],
            [-109.49544, 33.652696999129233],
            [-109.80327, 33.481726999131418],
            [-109.891759, 33.567828999130306],
            [-109.891766, 33.77777699912766],
            [-109.859274, 33.777682999127656],
            [-109.859393, 34.125990999123353],
            [-109.848246, 34.473434999119192],
            [-109.865231, 34.55968899911818],
            [-109.8505, 34.647441999117163],
            [-109.844624, 34.822561999115152],
            [-109.843764, 35.517874999107477],
            [-109.826936, 35.662483999105937],
            [-110.000674, 35.662656999105934],
            [-110.000681818589001, 36.997967999390504],
            [-110.000677, 36.997967999092907]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "111",
        COUNTYNS: "01784815",
        AFFGEOID: "0500000US17111",
        GEOID: "17111",
        NAME: "McHenry",
        LSAD: "06",
        ALAND: 1562336883,
        AWATER: 19832927
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.70737771540179, 42.493587013829831],
            [-88.506912, 42.494882999060238],
            [-88.304692, 42.495608171811732],
            [-88.2169, 42.495922999060227],
            [-88.199529001448497, 42.495756014750533],
            [-88.198601, 42.415566999060459],
            [-88.198647, 42.328860999060723],
            [-88.198314, 42.241611999060972],
            [-88.198683, 42.241670999060979],
            [-88.199584, 42.15425999906126],
            [-88.238369, 42.154252999061256],
            [-88.257689, 42.154072999061249],
            [-88.354183, 42.154223999061266],
            [-88.393762, 42.15389999906126],
            [-88.588657, 42.153589999061261],
            [-88.705633, 42.153560999061256],
            [-88.70737771540179, 42.493587013829831]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "027",
        COUNTYNS: "00023901",
        AFFGEOID: "0500000US04027",
        GEOID: "04027",
        NAME: "Yuma",
        LSAD: "06",
        ALAND: 14281265651,
        AWATER: 13171316
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.799683, 32.593620999143248],
            [-114.809393, 32.617118999142903],
            [-114.76495, 32.649390999142469],
            [-114.719633, 32.718762999141518],
            [-114.705717, 32.741580999141206],
            [-114.667493, 32.734225999141309],
            [-114.617386523961997, 32.741052772317815],
            [-114.570675, 32.747416999141144],
            [-114.531746, 32.782502999140654],
            [-114.468971, 32.845154999139808],
            [-114.463127, 32.901883999139045],
            [-114.47664, 32.923627999138745],
            [-114.481315, 32.972063999138093],
            [-114.511343, 33.023454999137407],
            [-114.517066790281007, 33.024628762705895],
            [-114.268748, 33.029814999137322],
            [-114.267815, 33.463573999131654],
            [-113.958042, 33.464654999131639],
            [-113.958046, 33.377577999132754],
            [-113.33392, 33.377424999132749],
            [-113.333709, 32.767850999140862],
            [-113.333897, 32.504937999144467],
            [-113.333767973015014, 32.040249079241121],
            [-113.750756, 32.16900499914918],
            [-114.813613, 32.49427699914461],
            [-114.811536, 32.522833999144211],
            [-114.795635, 32.550955999143824],
            [-114.814185, 32.56478799914364],
            [-114.799683, 32.593620999143248]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "053",
        COUNTYNS: "01101814",
        AFFGEOID: "0500000US40053",
        GEOID: "40053",
        NAME: "Grant",
        LSAD: "06",
        ALAND: 2592244840,
        AWATER: 7021951
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.111985183745787, 36.998247993728206],
            [-98.045342, 36.998326999092889],
            [-97.8023129703503, 36.998698608486897],
            [-97.768704, 36.998749999092901],
            [-97.462346029864094, 36.998824237889799],
            [-97.462459, 36.593626999096635],
            [-98.104286, 36.593577999096638],
            [-98.109393, 36.611542999096464],
            [-98.111985183745787, 36.998247993728206]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "139",
        COUNTYNS: "01101857",
        AFFGEOID: "0500000US40139",
        GEOID: "40139",
        NAME: "Texas",
        LSAD: "06",
        ALAND: 5286926133,
        AWATER: 19215617
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.028204191044992, 36.993145146201243],
            [-101.90244, 36.993701999092934],
            [-101.555258848690983, 36.995290938370523],
            [-101.485326, 36.995610999092925],
            [-101.211486, 36.997123999092913],
            [-101.066450215659998, 36.99773617423331],
            [-100.945466793078012, 36.99824682782171],
            [-100.954152157693017, 36.499529495723742],
            [-101.085156, 36.499243999097544],
            [-101.623915, 36.499527999097545],
            [-101.826565, 36.499653999097532],
            [-102.032339018963, 36.500065672107631],
            [-102.028204191044992, 36.993145146201243]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "021",
        COUNTYNS: "01266974",
        AFFGEOID: "0500000US46021",
        GEOID: "46021",
        NAME: "Campbell",
        LSAD: "06",
        ALAND: 1900214168,
        AWATER: 96243697
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.499354215383988, 45.943631997871627],
            [-100.294126, 45.943268999057331],
            [-100.152084, 45.942485999057332],
            [-99.880292, 45.94167199905732],
            [-99.88006209480659, 45.941670914859522],
            [-99.718073, 45.940906999057319],
            [-99.718071042882201, 45.940907002553715],
            [-99.717466, 45.593427999056992],
            [-100.430079, 45.594822999056994],
            [-100.406438, 45.650010999057045],
            [-100.308849, 45.689633999057072],
            [-100.387036, 45.869141999057234],
            [-100.499354215383988, 45.943631997871627]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "015",
        COUNTYNS: "01026334",
        AFFGEOID: "0500000US37015",
        GEOID: "37015",
        NAME: "Bertie",
        LSAD: "06",
        ALAND: 1810858584,
        AWATER: 109088135
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.230275, 36.104998999101419],
            [-77.291519, 36.168341999100775],
            [-77.29825, 36.207707999100386],
            [-77.208468, 36.246577999100005],
            [-77.180277, 36.242765999100037],
            [-76.706584, 36.243795999100023],
            [-76.736933, 36.150582999100955],
            [-76.682535, 36.030946999102156],
            [-76.606214, 35.978050999102692],
            [-76.683751, 35.953100999102944],
            [-76.760598, 35.864886999103838],
            [-76.834825, 35.88290999910366],
            [-76.891106, 35.812653999104391],
            [-76.894022, 35.861936999103868],
            [-76.93904, 35.901509999103475],
            [-77.00525, 35.858432999103911],
            [-77.04405, 35.861620999103877],
            [-77.027544, 35.929682999103171],
            [-77.195882, 35.936800999103099],
            [-77.205817, 36.005322999102411],
            [-77.327569, 36.071360999101742],
            [-77.230275, 36.104998999101419]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "185",
        COUNTYNS: "01639800",
        AFFGEOID: "0500000US47185",
        GEOID: "47185",
        NAME: "White",
        LSAD: "06",
        ALAND: 975592111,
        AWATER: 7113368
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.682095, 35.831253999104192],
            [-85.632449, 35.847384999104023],
            [-85.662917, 35.971500999102744],
            [-85.644604, 36.015052999102316],
            [-85.507677, 36.082011999101631],
            [-85.309363, 36.03159199910214],
            [-85.264206, 35.979153999102685],
            [-85.242698, 35.913039999103354],
            [-85.213115, 35.910378999103379],
            [-85.269359, 35.793387999104588],
            [-85.265064, 35.821972999104283],
            [-85.423179, 35.814690999104357],
            [-85.439527, 35.815865999104346],
            [-85.44936, 35.821558999104298],
            [-85.462996, 35.822208999104291],
            [-85.464154, 35.81516099910435],
            [-85.474213, 35.822655999104278],
            [-85.49499, 35.813034999104381],
            [-85.499398, 35.806514999104436],
            [-85.517326, 35.815814999104347],
            [-85.526581, 35.807282999104437],
            [-85.519775, 35.792076999104601],
            [-85.535043, 35.799351999104516],
            [-85.543209, 35.797324999104546],
            [-85.598389, 35.806303999104443],
            [-85.602876, 35.794995999104557],
            [-85.663609, 35.819588999104312],
            [-85.682095, 35.831253999104192]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "015",
        COUNTYNS: "01035614",
        AFFGEOID: "0500000US38015",
        GEOID: "38015",
        NAME: "Burleigh",
        LSAD: "06",
        ALAND: 4228239131,
        AWATER: 92618423
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.965156, 47.156630999059544],
            [-100.74937, 47.15724499905955],
            [-100.749334, 47.327684999060004],
            [-100.673285, 47.327425999060004],
            [-100.113261, 47.327261999059999],
            [-100.075229, 46.981281999059128],
            [-100.081198, 46.633395999058365],
            [-100.66189, 46.634449999058397],
            [-100.787809, 46.690629999058501],
            [-100.779477, 46.7365709990586],
            [-100.907902, 46.904366999058958],
            [-100.935983, 46.982839999059131],
            [-100.880487, 47.019412999059213],
            [-100.965156, 47.156630999059544]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "063",
        COUNTYNS: "01265792",
        AFFGEOID: "0500000US46063",
        GEOID: "46063",
        NAME: "Harding",
        LSAD: "06",
        ALAND: 6919334579,
        AWATER: 16956320
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.045443, 45.945309999057329],
            [-103.660779, 45.945240999057326],
            [-103.434851, 45.945290999057335],
            [-103.218396, 45.945207999057324],
            [-102.995668019138009, 45.945116442828827],
            [-102.942069961191009, 45.945094410410519],
            [-102.957281, 45.212850999056812],
            [-104.040135756493015, 45.2128907610984],
            [-104.040358, 45.335945999056854],
            [-104.041764, 45.490788999056917],
            [-104.041937, 45.557914999056969],
            [-104.042597, 45.749997999057129],
            [-104.043776, 45.864706999057233],
            [-104.044133156106994, 45.881976257407061],
            [-104.045443, 45.945309999057329]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "117",
        COUNTYNS: "00465247",
        AFFGEOID: "0500000US19117",
        GEOID: "19117",
        NAME: "Lucas",
        LSAD: "06",
        ALAND: 1115257644,
        AWATER: 9852833
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.557556, 41.161270999065017],
            [-93.327886, 41.160658999065006],
            [-93.099217, 41.160866999065014],
            [-93.097595, 40.898450999066192],
            [-93.55654, 40.898294999066188],
            [-93.557556, 41.161270999065017]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "025",
        COUNTYNS: "00451674",
        AFFGEOID: "0500000US18025",
        GEOID: "18025",
        NAME: "Crawford",
        LSAD: "06",
        ALAND: 791534789,
        AWATER: 8041751
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.681424, 38.394766999081376],
            [-86.614784, 38.395460999081365],
            [-86.308868, 38.394068999081391],
            [-86.308674, 38.422874999081159],
            [-86.255045, 38.42268499908117],
            [-86.253137, 38.291899999082155],
            [-86.321274, 38.147417999083267],
            [-86.356407267971008, 38.135277835849145],
            [-86.387216, 38.124631999083441],
            [-86.433574364088088, 38.087144386663212],
            [-86.461022, 38.12108699908346],
            [-86.4606, 38.201476999082836],
            [-86.460425, 38.206824999082791],
            [-86.571685, 38.207538999082793],
            [-86.570136, 38.266327999082336],
            [-86.679511, 38.263085999082378],
            [-86.681424, 38.394766999081376]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "003",
        COUNTYNS: "01639723",
        AFFGEOID: "0500000US47003",
        GEOID: "47003",
        NAME: "Bedford",
        LSAD: "06",
        ALAND: 1226719784,
        AWATER: 2980756
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.639436, 35.685899999105708],
            [-86.543116, 35.697616999105577],
            [-86.402726, 35.618570999106403],
            [-86.34194, 35.664258999105932],
            [-86.245165, 35.63191299910627],
            [-86.234575, 35.452759999108174],
            [-86.25759, 35.412475999108608],
            [-86.422338, 35.31884599910962],
            [-86.525306, 35.354717999109234],
            [-86.599478, 35.364949999109115],
            [-86.618572, 35.367400999109094],
            [-86.664164, 35.49177099910775],
            [-86.639436, 35.685899999105708]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "035",
        COUNTYNS: "00929104",
        AFFGEOID: "0500000US35035",
        GEOID: "35035",
        NAME: "Otero",
        LSAD: "06",
        ALAND: 17126453574,
        AWATER: 36946480
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.376585, 32.958049999138296],
            [-106.340709, 32.958381999138275],
            [-106.340515, 33.052776999137009],
            [-106.34571, 33.390594999132588],
            [-105.727171, 33.390632999132592],
            [-105.725957, 33.304833999133706],
            [-105.317376, 33.305976999133691],
            [-105.316968, 33.132302999135966],
            [-105.316047, 32.962099999138225],
            [-105.352513, 32.962006999138232],
            [-105.35401, 32.518775999144275],
            [-104.851524, 32.520540999144245],
            [-104.84774, 32.0004646019866],
            [-104.918356994280003, 32.000472072225094],
            [-105.153994, 32.000496999151594],
            [-105.750527, 32.002205999151577],
            [-105.997971151664004, 32.001974589850576],
            [-106.200699, 32.001784999151575],
            [-106.377173188246999, 32.001240100189079],
            [-106.376585, 32.958049999138296]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "107",
        COUNTYNS: "00424255",
        AFFGEOID: "0500000US17107",
        GEOID: "17107",
        NAME: "Logan",
        LSAD: "06",
        ALAND: 1600758859,
        AWATER: 2252736
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.602979, 40.32012899906907],
            [-89.26374, 40.325343999069041],
            [-89.26265, 40.280918999069279],
            [-89.148772, 40.282519999069265],
            [-89.148764, 40.282037999069274],
            [-89.144764, 40.048852999070562],
            [-89.143457, 39.917919999071302],
            [-89.217846, 39.916989999071298],
            [-89.40506, 39.918303999071291],
            [-89.483826, 39.976732999070954],
            [-89.578289, 39.976126999070956],
            [-89.579127, 40.09232399907031],
            [-89.601604, 40.122431999070137],
            [-89.602979, 40.32012899906907]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "145",
        COUNTYNS: "01101860",
        AFFGEOID: "0500000US40145",
        GEOID: "40145",
        NAME: "Wagoner",
        LSAD: "06",
        ALAND: 1454425176,
        AWATER: 75403442
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.819964, 35.855899999103926],
            [-95.761687, 35.900810999103477],
            [-95.76165, 36.162749999100825],
            [-95.726408, 36.162495999100834],
            [-95.61852, 36.162511999100829],
            [-95.571525, 36.075281999101712],
            [-95.43997, 36.075199999101713],
            [-95.207946, 36.074707999101712],
            [-95.278701, 35.96410199910283],
            [-95.231459, 35.851194999103996],
            [-95.265679, 35.813265999104374],
            [-95.360363, 35.822410999104285],
            [-95.589736, 35.765159999104881],
            [-95.65042, 35.856755999103918],
            [-95.766166, 35.856402999103935],
            [-95.819964, 35.855899999103926]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "085",
        COUNTYNS: "01034208",
        AFFGEOID: "0500000US38085",
        GEOID: "38085",
        NAME: "Sioux",
        LSAD: "06",
        ALAND: 2833690974,
        AWATER: 88449900
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.998575, 46.053460999057464],
            [-101.849355, 46.073274999057489],
            [-101.83837, 46.033042999057429],
            [-101.749591, 46.041666999057433],
            [-101.664528, 46.011635999057404],
            [-101.622717, 46.037027999057443],
            [-101.554185, 46.006835999057408],
            [-101.299917, 46.104843999057529],
            [-101.301698, 46.133268999057563],
            [-101.189747, 46.14869299905758],
            [-101.198073, 46.183769999057624],
            [-101.025662, 46.284218999057785],
            [-100.884552, 46.409095999057989],
            [-100.721261, 46.39008399905795],
            [-100.590199, 46.427893999058007],
            [-100.551199, 46.363493999057908],
            [-100.558549, 46.249992999057739],
            [-100.629145, 46.145607999057589],
            [-100.614504, 46.071795999057485],
            [-100.511949026151015, 45.943654069491828],
            [-100.76211, 45.943766999057324],
            [-101.106826, 45.94398399905733],
            [-101.365283, 45.944091999057328],
            [-101.557276, 45.944099999057322],
            [-101.794606, 45.944396999057318],
            [-101.998617078234005, 45.944536976414234],
            [-101.998575, 46.053460999057464]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "005",
        COUNTYNS: "00882272",
        AFFGEOID: "0500000US34005",
        GEOID: "34005",
        NAME: "Burlington",
        LSAD: "06",
        ALAND: 2069239325,
        AWATER: 55032009
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.059017, 39.992511999070878],
            [-74.989914, 40.037310999070606],
            [-74.972854756445599, 40.046505364606865],
            [-74.932211, 40.068410999070437],
            [-74.863809, 40.082209999070358],
            [-74.825907, 40.123909999070122],
            [-74.769488, 40.1291449990701],
            [-74.723379297151496, 40.152895545301675],
            [-74.71847, 40.150028999069981],
            [-74.706269, 40.161792999069917],
            [-74.704177, 40.168478999069883],
            [-74.710782, 40.168044999069885],
            [-74.710099, 40.181282999069822],
            [-74.587835, 40.138253999070045],
            [-74.553105, 40.079129999070382],
            [-74.434223, 39.855156999071646],
            [-74.389708, 39.77328299907213],
            [-74.39812, 39.576574999073323],
            [-74.417393, 39.557254999073443],
            [-74.475664, 39.551459999073479],
            [-74.654318, 39.626798999073017],
            [-74.723856, 39.722062999072442],
            [-74.736216, 39.72977499907239],
            [-74.819419, 39.788000999072054],
            [-74.902368, 39.791040999072031],
            [-74.931071, 39.885974999071472],
            [-74.984045, 39.927353999071251],
            [-74.98558, 39.930429999071222],
            [-74.975051, 39.938510999071177],
            [-75.004149, 39.941464999071158],
            [-75.015081, 39.946812999071128],
            [-75.013092, 39.956627999071067],
            [-75.0176, 39.959374999071059],
            [-75.019253, 39.967433999071005],
            [-75.060129970722599, 39.99201100233347],
            [-75.059017, 39.992511999070878]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "059",
        COUNTYNS: "00516876",
        AFFGEOID: "0500000US21059",
        GEOID: "21059",
        NAME: "Daviess",
        LSAD: "06",
        ALAND: 1187163564,
        AWATER: 47158145
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.394093, 37.723994999086642],
            [-87.271608, 37.780119999086175],
            [-87.323402, 37.819238999085862],
            [-87.304057452441981, 37.893432548207556],
            [-87.270387407739008, 37.8754228256754],
            [-87.25525, 37.867325999085466],
            [-87.180063, 37.84137499908568],
            [-87.137502, 37.807263999085954],
            [-87.105614, 37.767630999086272],
            [-87.057836, 37.827456999085797],
            [-87.043049, 37.875048999085408],
            [-87.010315, 37.91966799908505],
            [-86.977741425110196, 37.925699207457711],
            [-86.987159, 37.896699999085243],
            [-86.893859, 37.882112999085351],
            [-86.823429, 37.737753999086522],
            [-86.817783, 37.673678999087059],
            [-86.94876, 37.630547999087412],
            [-87.03826, 37.560802999087983],
            [-87.138821, 37.575627999087878],
            [-87.408547, 37.683562999086966],
            [-87.394093, 37.723994999086642]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "013",
        COUNTYNS: "00558445",
        AFFGEOID: "0500000US22013",
        GEOID: "22013",
        NAME: "Bienville",
        LSAD: "15",
        ALAND: 2101207370,
        AWATER: 27945994
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.402923, 32.368726999146368],
            [-93.42338, 32.397739999145962],
            [-93.372781, 32.410152999145794],
            [-93.236656, 32.410070999145788],
            [-93.147058, 32.45533999914516],
            [-93.178767, 32.584915999143355],
            [-92.880805, 32.585276999143346],
            [-92.879268, 32.454117999145161],
            [-92.777197, 32.453454999145173],
            [-92.773173, 32.235983999148239],
            [-92.814737, 32.1469069991495],
            [-92.93908, 32.147941999149488],
            [-93.187162, 32.148411999149481],
            [-93.213143, 32.235756999148244],
            [-93.42819, 32.235896999148238],
            [-93.402923, 32.368726999146368]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "205",
        COUNTYNS: "00516949",
        AFFGEOID: "0500000US21205",
        GEOID: "21205",
        NAME: "Rowan",
        LSAD: "06",
        ALAND: 724723335,
        AWATER: 16699596
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.564335, 38.216605999082731],
            [-83.50716, 38.269446999082319],
            [-83.452848, 38.381798999081475],
            [-83.412003, 38.396445999081365],
            [-83.341347, 38.319520999081924],
            [-83.30114, 38.209702999082765],
            [-83.244306, 38.192379999082902],
            [-83.247194, 38.185360999082953],
            [-83.259942, 38.190082999082925],
            [-83.264057, 38.187214999082947],
            [-83.248937, 38.180249999083003],
            [-83.243324, 38.176097999083026],
            [-83.23793, 38.177721999083019],
            [-83.226762, 38.173791999083058],
            [-83.215454, 38.177071999083026],
            [-83.205257, 38.17551299908304],
            [-83.198994, 38.16864299908309],
            [-83.263289, 38.115385999083507],
            [-83.430638, 38.046958999084048],
            [-83.433942, 38.033973999084147],
            [-83.463408, 38.016974999084276],
            [-83.498846, 38.050619999084006],
            [-83.463285, 38.07971099908378],
            [-83.53442, 38.094692999083669],
            [-83.635195, 38.187524999082946],
            [-83.564335, 38.216605999082731]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "073",
        COUNTYNS: "01383822",
        AFFGEOID: "0500000US48073",
        GEOID: "48073",
        NAME: "Cherokee",
        LSAD: "06",
        ALAND: 2727141725,
        AWATER: 23873001
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.459908, 32.135558999149659],
            [-94.985272, 32.13798899914962],
            [-94.984768, 31.846143999153828],
            [-94.937532, 31.84555499915384],
            [-94.978128, 31.793293999154599],
            [-94.924219, 31.579486999157744],
            [-94.865857, 31.526915999158529],
            [-95.003345, 31.425709999160045],
            [-95.101382, 31.469886999159385],
            [-95.099966, 31.518868999158652],
            [-95.168509, 31.583670999157686],
            [-95.273203, 31.592885999157552],
            [-95.258859, 31.6099589991573],
            [-95.275849, 31.657228999156597],
            [-95.396531, 31.765265999155005],
            [-95.412908, 31.835156999153991],
            [-95.446747, 31.843115999153881],
            [-95.423832, 31.932008999152583],
            [-95.446449, 31.998516999151626],
            [-95.428512, 32.084474999150387],
            [-95.459908, 32.135558999149659]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "015",
        COUNTYNS: "00558453",
        AFFGEOID: "0500000US22015",
        GEOID: "22015",
        NAME: "Bossier",
        LSAD: "15",
        ALAND: 2176185432,
        AWATER: 70496339
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.814553013433795, 33.019389044008257],
            [-93.804907774630379, 33.019396224588363],
            [-93.723273, 33.019456999137461],
            [-93.520993553771589, 33.018742292725477],
            [-93.530454, 32.975608999138046],
            [-93.469572, 32.917716999138825],
            [-93.447916, 32.866683999139518],
            [-93.473797, 32.783171999140642],
            [-93.444743, 32.761165999140943],
            [-93.441212, 32.410430999145774],
            [-93.372781, 32.410152999145794],
            [-93.42338, 32.397739999145962],
            [-93.402923, 32.368726999146368],
            [-93.42819, 32.235896999148238],
            [-93.471249, 32.237185999148217],
            [-93.539354, 32.289919999147465],
            [-93.555323, 32.331422999146895],
            [-93.61513, 32.348331999146652],
            [-93.659041, 32.406057999145837],
            [-93.685569, 32.395497999145995],
            [-93.680334, 32.462886999145049],
            [-93.767156, 32.538041999144006],
            [-93.739474, 32.590772999143276],
            [-93.782111, 32.712211999141608],
            [-93.819169, 32.736001999141294],
            [-93.783233, 32.784359999140619],
            [-93.824253, 32.79245099914052],
            [-93.785181, 32.857352999139636],
            [-93.842597, 32.946763999138433],
            [-93.814553013433795, 33.019389044008257]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "013",
        COUNTYNS: "01675903",
        AFFGEOID: "0500000US06013",
        GEOID: "06013",
        NAME: "Contra Costa",
        LSAD: "06",
        ALAND: 1857310903,
        AWATER: 225193562
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.425258, 37.955671999084764],
            [-122.367582, 37.978167999084583],
            [-122.368891, 38.007947999084344],
            [-122.342803852188013, 38.00925387686604],
            [-122.321706, 38.010309999084335],
            [-122.262861, 38.051472999084012],
            [-122.269320054916989, 38.060374496615125],
            [-122.14312, 38.031760999084163],
            [-122.095733, 38.048354999084033],
            [-122.050386, 38.060197999083933],
            [-121.878235, 38.049317999084018],
            [-121.862462, 38.066029999083895],
            [-121.80127, 38.021621999084239],
            [-121.737824, 38.0266299990842],
            [-121.673065, 38.093517999083666],
            [-121.580022, 38.09441399908367],
            [-121.579425, 37.872299999085428],
            [-121.534108, 37.84933899908561],
            [-121.556997, 37.816487999085879],
            [-121.577005, 37.811526999085928],
            [-121.873542, 37.739316999086526],
            [-121.96077, 37.718628999086683],
            [-121.97247, 37.728527999086602],
            [-121.989971, 37.733627999086558],
            [-122.011771, 37.747427999086447],
            [-121.997771, 37.763226999086321],
            [-122.004809, 37.770570999086253],
            [-122.045473, 37.798125999086032],
            [-122.14026, 37.804561999085976],
            [-122.27108, 37.90582399908515],
            [-122.32871428740701, 37.893832345406054],
            [-122.33453, 37.908790999085141],
            [-122.378709, 37.905190999085164],
            [-122.425258, 37.955671999084764]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "045",
        COUNTYNS: "00936844",
        AFFGEOID: "0500000US35045",
        GEOID: "35045",
        NAME: "San Juan",
        LSAD: "06",
        ALAND: 14289427705,
        AWATER: 55030762
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.045729, 36.117027999101282],
            [-109.045433, 36.87458899909403],
            [-109.045223, 36.999083999092889],
            [-108.620309, 36.999286999092888],
            [-108.379302950094001, 36.999564685404394],
            [-108.000623, 37.000000999092883],
            [-107.481736999155004, 37.000004579407182],
            [-107.420921905745004, 37.000004999031482],
            [-107.457159, 36.954963999093287],
            [-107.451445, 36.904035999093757],
            [-107.597983, 36.8343019990944],
            [-107.618181, 36.804266999094665],
            [-107.624283, 36.219808999100273],
            [-107.626511, 36.000287999102461],
            [-108.165051, 36.002838999102437],
            [-109.045871168204982, 36.002701629621434],
            [-109.045729, 36.117027999101282]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "167",
        COUNTYNS: "01785010",
        AFFGEOID: "0500000US17167",
        GEOID: "17167",
        NAME: "Sangamon",
        LSAD: "06",
        ALAND: 2248891495,
        AWATER: 22577723
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.994506, 39.901924999071383],
            [-89.769236, 39.902415999071373],
            [-89.701864, 39.916786999071306],
            [-89.698259, 39.975308999070968],
            [-89.578289, 39.976126999070956],
            [-89.483826, 39.976732999070954],
            [-89.40506, 39.918303999071291],
            [-89.217846, 39.916989999071298],
            [-89.217523, 39.813223999071894],
            [-89.246235, 39.825947999071822],
            [-89.303122, 39.775812999072123],
            [-89.425535, 39.747675999072285],
            [-89.425051, 39.683695999072675],
            [-89.478923, 39.683963999072667],
            [-89.478473, 39.640841999072933],
            [-89.535028, 39.641137999072924],
            [-89.534998, 39.612225999073104],
            [-89.533655, 39.52459199907365],
            [-89.701645, 39.523368999073654],
            [-89.926037, 39.522103999073664],
            [-89.993755, 39.785328999072057],
            [-89.994405, 39.872859999071544],
            [-89.994506, 39.901924999071383]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "045",
        COUNTYNS: "00395442",
        AFFGEOID: "0500000US16045",
        GEOID: "16045",
        NAME: "Gem",
        LSAD: "06",
        ALAND: 1449792319,
        AWATER: 12495804
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.712501, 43.981325999057304],
            [-116.572224, 43.980484999057296],
            [-116.532117, 44.066023999057222],
            [-116.452551, 44.06541399905722],
            [-116.452068, 44.151464999057119],
            [-116.358492, 44.151157999057126],
            [-116.347394, 44.322102999056987],
            [-116.299876, 44.444575999056909],
            [-116.213314, 44.510812999056867],
            [-116.157022, 44.498927999056882],
            [-116.152295, 44.324518999056984],
            [-116.21291, 44.324372999056997],
            [-116.213871, 44.151386999057138],
            [-116.273069, 44.151226999057123],
            [-116.282019, 43.807364999057505],
            [-116.512502, 43.80716099905753],
            [-116.712672, 43.80716099905753],
            [-116.712501, 43.981325999057304]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "075",
        COUNTYNS: "00394896",
        AFFGEOID: "0500000US16075",
        GEOID: "16075",
        NAME: "Payette",
        LSAD: "06",
        ALAND: 1053857311,
        AWATER: 8870733
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.976024, 43.895547999057399],
            [-116.959871, 43.982924999057296],
            [-116.937342, 44.029375999057258],
            [-116.977351, 44.085363999057201],
            [-116.897854677114992, 44.152666394448531],
            [-116.452068, 44.151464999057119],
            [-116.452551, 44.06541399905722],
            [-116.532117, 44.066023999057222],
            [-116.572224, 43.980484999057296],
            [-116.712501, 43.981325999057304],
            [-116.712672, 43.80716099905753],
            [-116.853154, 43.792709999057536],
            [-116.95452, 43.836486999057485],
            [-116.985545038489988, 43.881184851871211],
            [-116.976024, 43.895547999057399]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "081",
        COUNTYNS: "00559472",
        AFFGEOID: "0500000US22081",
        GEOID: "22081",
        NAME: "Red River",
        LSAD: "15",
        ALAND: 1007739206,
        AWATER: 34327512
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.61469, 32.237525999148204],
            [-93.471249, 32.237185999148217],
            [-93.42819, 32.235896999148238],
            [-93.213143, 32.235756999148244],
            [-93.187162, 32.148411999149481],
            [-93.121188, 31.972609999151999],
            [-93.23847, 31.972780999151993],
            [-93.239501, 31.903519999152991],
            [-93.355452, 31.932221999152585],
            [-93.441506, 31.989179999151755],
            [-93.539553, 32.10216699915015],
            [-93.535963, 32.193125999148833],
            [-93.61469, 32.237525999148204]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "079",
        COUNTYNS: "01702379",
        AFFGEOID: "0500000US16079",
        GEOID: "16079",
        NAME: "Shoshone",
        LSAD: "06",
        ALAND: 6830709342,
        AWATER: 14151673
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.329601, 48.017721999062154],
            [-116.282236, 48.059385999062322],
            [-116.233113, 48.068889999062336],
            [-116.038565829872979, 47.984634255503742],
            [-116.030751, 47.973348999062004],
            [-115.959946, 47.89814199906175],
            [-115.900934, 47.843063999061563],
            [-115.845474, 47.814966999061461],
            [-115.835365, 47.760956999061293],
            [-115.72377, 47.696670999061084],
            [-115.73627, 47.654761999060959],
            [-115.694284, 47.623459999060856],
            [-115.721207, 47.576322999060721],
            [-115.717024, 47.532692999060586],
            [-115.634684, 47.481759999060444],
            [-115.69293, 47.457236999060363],
            [-115.71034, 47.417783999060248],
            [-115.578619, 47.367006999060109],
            [-115.531971, 47.314120999059973],
            [-115.470959, 47.284872999059893],
            [-115.371825, 47.265212999059834],
            [-115.326903, 47.255911999059805],
            [-115.29211, 47.209860999059686],
            [-115.255786, 47.174724999059592],
            [-115.189451, 47.131031999059495],
            [-115.120917, 47.061236999059325],
            [-115.071254, 47.022082999059222],
            [-115.031651, 46.971547999059112],
            [-114.961422906068023, 46.932893875029826],
            [-115.636166, 46.933362999059021],
            [-116.329436, 46.934668999059021],
            [-116.329418, 47.021914999059227],
            [-116.329406, 47.414010999060238],
            [-116.329538, 47.890392999061717],
            [-116.329601, 48.017721999062154]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "117",
        COUNTYNS: "00424257",
        AFFGEOID: "0500000US17117",
        GEOID: "17117",
        NAME: "Macoupin",
        LSAD: "06",
        ALAND: 2234952037,
        AWATER: 12253242
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.15375, 39.520314999073676],
            [-89.926037, 39.522103999073664],
            [-89.701645, 39.523368999073654],
            [-89.698555, 38.998978999077067],
            [-90.145991, 39.000045999077066],
            [-90.148069, 39.261946999075313],
            [-90.15375, 39.520314999073676]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "119",
        COUNTYNS: "00424258",
        AFFGEOID: "0500000US17119",
        GEOID: "17119",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 1853819910,
        AWATER: 64225067
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.273686, 38.999346999077055],
            [-90.145991, 39.000045999077066],
            [-89.698555, 38.998978999077067],
            [-89.639265, 38.999128999077065],
            [-89.637049, 38.918363999077606],
            [-89.637831, 38.918352999077612],
            [-89.599593, 38.874529999077922],
            [-89.597321, 38.743235999078834],
            [-89.595103, 38.655947999079459],
            [-89.707024, 38.655151999079472],
            [-90.037105, 38.658448999079432],
            [-90.18152438153129, 38.660372884389915],
            [-90.19521, 38.687549999079238],
            [-90.20991, 38.726049999078953],
            [-90.166594640015589, 38.772450138383732],
            [-90.166409, 38.772648999078633],
            [-90.117707, 38.805747999078399],
            [-90.113327, 38.849305999078091],
            [-90.207282301185586, 38.898732321917656],
            [-90.230336, 38.910859999077665],
            [-90.276583722025606, 38.919338466289204],
            [-90.273686, 38.999346999077055]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "017",
        COUNTYNS: "01676992",
        AFFGEOID: "0500000US24017",
        GEOID: "24017",
        NAME: "Charles",
        LSAD: "06",
        ALAND: 1185656912,
        AWATER: 479539652
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.246584, 38.538340999080312],
            [-77.183767, 38.600698999079853],
            [-77.129084, 38.614363999079757],
            [-77.1302, 38.635016999079603],
            [-77.132501, 38.673815999079324],
            [-77.08578476515639, 38.705281280911507],
            [-77.047656, 38.616302999079736],
            [-77.011028, 38.651974999079485],
            [-76.862955, 38.658648999079446],
            [-76.747335, 38.616676999079743],
            [-76.740747, 38.559140999080164],
            [-76.675457, 38.535875999080332],
            [-76.674118, 38.499621999080588],
            [-76.76829, 38.5129489990805],
            [-76.87146, 38.389936999081407],
            [-76.827036129281879, 38.258300099696498],
            [-76.864292, 38.268944999082322],
            [-76.922177, 38.311338999081997],
            [-76.975492, 38.34732699908173],
            [-77.001638, 38.421951999081166],
            [-77.016371, 38.44557199908099],
            [-77.075489, 38.424709999081159],
            [-77.123325, 38.410645999081254],
            [-77.211188, 38.380661999081482],
            [-77.259962, 38.435820999081052],
            [-77.246584, 38.538340999080312]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "035",
        COUNTYNS: "00395697",
        AFFGEOID: "0500000US16035",
        GEOID: "16035",
        NAME: "Clearwater",
        LSAD: "06",
        ALAND: 6364399894,
        AWATER: 79605439
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.456098, 46.629096999058369],
            [-116.32954, 46.627956999058377],
            [-116.329436, 46.934668999059021],
            [-115.636166, 46.933362999059021],
            [-114.961422906068023, 46.932893875029826],
            [-114.927432, 46.914184999058968],
            [-114.943281, 46.867970999058869],
            [-114.880588, 46.811790999058751],
            [-114.79004, 46.778728999058671],
            [-114.76718, 46.7388279990586],
            [-114.699008, 46.740222999058609],
            [-114.676827162695005, 46.731838738871687],
            [-114.626695, 46.712888999058549],
            [-114.621483, 46.658142999058427],
            [-114.591116003227015, 46.652550479413229],
            [-115.630708, 46.473037999058093],
            [-115.6342, 46.437580999058021],
            [-115.733477, 46.362881999057905],
            [-115.778831, 46.267350999057747],
            [-115.814741, 46.265754999057748],
            [-115.999438, 46.306519999057819],
            [-116.076088, 46.361987999057909],
            [-116.170272, 46.371587999057915],
            [-116.184198, 46.403377999057966],
            [-116.308621, 46.412997999057978],
            [-116.369122, 46.466922999058085],
            [-116.369311, 46.500491999058141],
            [-116.451662, 46.490735999058124],
            [-116.456098, 46.629096999058369]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "143",
        COUNTYNS: "01639784",
        AFFGEOID: "0500000US47143",
        GEOID: "47143",
        NAME: "Rhea",
        LSAD: "06",
        ALAND: 816822292,
        AWATER: 54464086
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.146393, 35.491298999107762],
            [-85.107762, 35.571358999106906],
            [-84.916062, 35.761938999104906],
            [-84.80163, 35.823065999104273],
            [-84.781901, 35.824999999104264],
            [-84.754472, 35.754389999104994],
            [-84.723853, 35.752895999105014],
            [-84.72324, 35.689669999105654],
            [-84.781804, 35.673907999105815],
            [-84.794904, 35.552549999107107],
            [-84.873969, 35.550216999107121],
            [-84.859949, 35.497071999107689],
            [-84.912148, 35.442670999108273],
            [-84.968953, 35.466673999108025],
            [-85.016343, 35.409287999108635],
            [-85.102158, 35.428441999108429],
            [-85.135191, 35.458648999108114],
            [-85.146393, 35.491298999107762]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "069",
        COUNTYNS: "01639750",
        AFFGEOID: "0500000US47069",
        GEOID: "47069",
        NAME: "Hardeman",
        LSAD: "06",
        ALAND: 1729448612,
        AWATER: 6829952
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.183944, 35.397125999108773],
            [-89.182551, 35.432815999108385],
            [-89.078876, 35.431427999108401],
            [-88.841607, 35.427825999108435],
            [-88.79274, 35.328970999109508],
            [-88.781768, 35.247586999110396],
            [-88.786612, 34.995251999113194],
            [-88.823050501632011, 34.995207044808197],
            [-89.017127011552404, 34.994967611949498],
            [-89.02654, 34.994955999113202],
            [-89.198287901494993, 34.994450529228303],
            [-89.183944, 35.397125999108773]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "087",
        COUNTYNS: "00399757",
        AFFGEOID: "0500000US16087",
        GEOID: "16087",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 3762870366,
        AWATER: 53228012
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.243027, 44.390973999056946],
            [-117.215072, 44.427161999056921],
            [-117.225932, 44.479388999056894],
            [-117.167187, 44.523430999056863],
            [-117.14293, 44.557235999056857],
            [-117.094968, 44.652010999056813],
            [-117.062273, 44.727142999056781],
            [-117.013802, 44.756840999056784],
            [-116.9318, 44.787180999056773],
            [-116.889295186967018, 44.840529777210072],
            [-116.625034, 44.838951999056768],
            [-116.624153, 44.663268999056811],
            [-116.529167, 44.615153999056837],
            [-116.528898, 44.49154499905687],
            [-116.407648, 44.444435999056907],
            [-116.299876, 44.444575999056909],
            [-116.347394, 44.322102999056987],
            [-116.358492, 44.151157999057126],
            [-116.452068, 44.151464999057119],
            [-116.897854677114992, 44.152666394448531],
            [-116.895931, 44.154294999057136],
            [-116.902752, 44.179466999057098],
            [-116.965498, 44.194125999057086],
            [-116.971958, 44.235676999057056],
            [-117.059352, 44.23724399905705],
            [-117.121037, 44.277584999057026],
            [-117.170342, 44.25888999905704],
            [-117.216974, 44.288356999057015],
            [-117.211995506953002, 44.296451094995312],
            [-117.192203, 44.328629999056986],
            [-117.216911, 44.360162999056968],
            [-117.243027, 44.390973999056946]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "147",
        COUNTYNS: "00424275",
        AFFGEOID: "0500000US17147",
        GEOID: "17147",
        NAME: "Piatt",
        LSAD: "06",
        ALAND: 1137445090,
        AWATER: 756346
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.745164, 40.055190999070511],
            [-88.745344, 40.098812999070255],
            [-88.574885, 40.28150099906928],
            [-88.491052, 40.281218999069274],
            [-88.460418, 40.281934999069271],
            [-88.463174, 40.14219599907004],
            [-88.462207, 39.879091999071512],
            [-88.462284, 39.802308999071968],
            [-88.473182, 39.791836999072032],
            [-88.745671, 39.792145999072034],
            [-88.745164, 40.055190999070511]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "023",
        COUNTYNS: "01266976",
        AFFGEOID: "0500000US46023",
        GEOID: "46023",
        NAME: "Charles Mix",
        LSAD: "06",
        ALAND: 2842481188,
        AWATER: 136616766
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.297998, 43.499668999057974],
            [-99.297882, 43.499855999057978],
            [-98.794588, 43.499186999057976],
            [-98.705782, 43.499318999057976],
            [-98.706855, 43.37007499905819],
            [-98.109492, 43.19683799905853],
            [-98.077108, 43.168329999058585],
            [-98.109534, 43.087476999058758],
            [-98.117047, 42.869385999059254],
            [-98.152586654838188, 42.841153380123011],
            [-98.231922, 42.861139999059269],
            [-98.280007, 42.874995999059237],
            [-98.308186816451297, 42.886489206484804],
            [-98.386445, 42.91840699905913],
            [-98.434503, 42.929226999059111],
            [-98.478919, 42.96353899905904],
            [-98.49855, 42.998559999058955],
            [-98.524871, 43.039346999058864],
            [-98.69046, 43.076555999058783],
            [-98.773406, 43.145918999058637],
            [-98.868762, 43.1620089990586],
            [-98.896783, 43.246908999058427],
            [-98.981253, 43.2589449990584],
            [-99.069285, 43.3206879990583],
            [-99.152889, 43.426907999058109],
            [-99.310073, 43.467934999058023],
            [-99.297998, 43.499668999057974]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "099",
        COUNTYNS: "00277314",
        AFFGEOID: "0500000US06099",
        GEOID: "06099",
        NAME: "Stanislaus",
        LSAD: "06",
        ALAND: 3874634688,
        AWATER: 46384540
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.471925, 37.481782999088672],
            [-121.241219, 37.66400899908713],
            [-121.110049, 37.742127999086492],
            [-120.995696, 37.760177999086338],
            [-120.920993, 37.737946999086525],
            [-120.926449, 38.077420999083799],
            [-120.653274, 37.831857999085756],
            [-120.387613, 37.633703999087388],
            [-120.38767, 37.633363999087393],
            [-120.989811, 37.395870999089396],
            [-120.963812, 37.346143999089826],
            [-121.226804, 37.134773999091685],
            [-121.282271, 37.183674999091252],
            [-121.404636, 37.155988999091484],
            [-121.459051, 37.282738999090377],
            [-121.409693, 37.382012999089525],
            [-121.472648, 37.482169999088654],
            [-121.471925, 37.481782999088672]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "095",
        COUNTYNS: "01639762",
        AFFGEOID: "0500000US47095",
        GEOID: "47095",
        NAME: "Lake",
        LSAD: "06",
        ALAND: 429381243,
        AWATER: 72799687
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.678046, 36.248283999099975],
            [-89.602374, 36.238105999100078],
            [-89.554289, 36.277750999099688],
            [-89.611819, 36.309087999099383],
            [-89.600544, 36.342984999099052],
            [-89.545031333969092, 36.344271397762036],
            [-89.522695, 36.344788999099038],
            [-89.51038, 36.378355999098709],
            [-89.542337, 36.420102999098312],
            [-89.521021, 36.461933999097909],
            [-89.539232, 36.497933999097562],
            [-89.417293, 36.499032999097544],
            [-89.345194319538791, 36.501343207734429],
            [-89.36012, 36.409276999098417],
            [-89.444587, 36.235537999100117],
            [-89.482971, 36.212658999100334],
            [-89.488283, 36.187543999100576],
            [-89.627641441675394, 36.185460315706614],
            [-89.69263, 36.224958999100217],
            [-89.678046, 36.248283999099975]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "025",
        COUNTYNS: "01698178",
        AFFGEOID: "0500000US24025",
        GEOID: "24025",
        NAME: "Harford",
        LSAD: "06",
        ALAND: 1132095114,
        AWATER: 231904976
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.569475117211596, 39.721458558177751],
            [-76.41898, 39.721525999072441],
            [-76.239683741114604, 39.72164198899705],
            [-76.233485, 39.72164599907245],
            [-76.233279347116593, 39.721646254700339],
            [-76.212676, 39.686404999072657],
            [-76.094472, 39.567809999073383],
            [-76.041976, 39.494227999073829],
            [-76.037646288315798, 39.452642381383399],
            [-76.060931, 39.452207999074098],
            [-76.146373, 39.405309999074397],
            [-76.224161, 39.352779999074727],
            [-76.296609, 39.301136999075055],
            [-76.325418341956905, 39.272905085157937],
            [-76.306579, 39.310106999075003],
            [-76.373493, 39.421417999074286],
            [-76.386697, 39.459704999074056],
            [-76.420896, 39.478413999073936],
            [-76.532174, 39.543976999073529],
            [-76.56671, 39.662688999072799],
            [-76.569475117211596, 39.721458558177751]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "097",
        COUNTYNS: "01639763",
        AFFGEOID: "0500000US47097",
        GEOID: "47097",
        NAME: "Lauderdale",
        LSAD: "06",
        ALAND: 1222389178,
        AWATER: 92231159
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.915491, 35.754916999104985],
            [-89.863874, 35.747591999105055],
            [-89.797053, 35.782647999104682],
            [-89.723426, 35.80938199910441],
            [-89.729517, 35.847631999104017],
            [-89.722634, 35.873717999103754],
            [-89.64727, 35.89491999910355],
            [-89.6489049703452, 35.903583481416049],
            [-89.631345, 35.924132999103236],
            [-89.398186, 35.941368999103062],
            [-89.342829, 35.880933999103668],
            [-89.339567, 35.862267999103878],
            [-89.356033, 35.817634999104328],
            [-89.401805, 35.822656999104282],
            [-89.502118, 35.580620999106806],
            [-89.623458, 35.652315999106051],
            [-89.7952, 35.635289999106227],
            [-89.909797, 35.537913999107268],
            [-89.944754, 35.560307999107017],
            [-89.9325, 35.607864999106518],
            [-89.876548, 35.626652999106327],
            [-89.898916, 35.650903999106063],
            [-89.956589, 35.695485999105586],
            [-89.915491, 35.754916999104985]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "055",
        COUNTYNS: "01266988",
        AFFGEOID: "0500000US46055",
        GEOID: "46055",
        NAME: "Haakon",
        LSAD: "06",
        ALAND: 4689280303,
        AWATER: 42449686
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.001068, 44.510925999056859],
            [-102.000432, 44.511662999056888],
            [-101.889038, 44.538457999056853],
            [-101.811198, 44.52237299905687],
            [-101.715038, 44.579482999056843],
            [-101.531035, 44.567874999056848],
            [-101.456072, 44.636202999056813],
            [-101.229495, 44.692286999056797],
            [-101.136838, 44.747125999056792],
            [-101.154516, 44.689946999056801],
            [-101.155188, 44.343146999056962],
            [-101.166753, 44.168084999057122],
            [-101.046916, 44.168483999057123],
            [-101.046786, 43.99496999905729],
            [-101.064068, 43.994928999057294],
            [-102.007336, 43.994529999057285],
            [-102.001068, 44.510925999056859]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "023",
        COUNTYNS: "01639732",
        AFFGEOID: "0500000US47023",
        GEOID: "47023",
        NAME: "Chester",
        LSAD: "06",
        ALAND: 740053456,
        AWATER: 575590
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.841607, 35.427825999108435],
            [-88.675867, 35.489162999107783],
            [-88.613611, 35.588088999106724],
            [-88.56557, 35.58708799910675],
            [-88.505354, 35.51202999910754],
            [-88.364005, 35.492800999107743],
            [-88.360829, 35.418971999108528],
            [-88.362572, 35.380993999108945],
            [-88.571763, 35.382083999108936],
            [-88.640934, 35.361016999109154],
            [-88.719761, 35.24754999911039],
            [-88.781768, 35.247586999110396],
            [-88.79274, 35.328970999109508],
            [-88.841607, 35.427825999108435]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "079",
        COUNTYNS: "01383825",
        AFFGEOID: "0500000US48079",
        GEOID: "48079",
        NAME: "Cochran",
        LSAD: "06",
        ALAND: 2007453829,
        AWATER: 229068
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.052610516564002, 33.570574775572481],
            [-103.05261, 33.570598999130276],
            [-103.047346018167005, 33.824674122278772],
            [-102.615447, 33.825120999127058],
            [-102.594836, 33.388488999132626],
            [-103.056495064337994, 33.388414213753215],
            [-103.052610516564002, 33.570574775572481]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "037",
        COUNTYNS: "01697853",
        AFFGEOID: "0500000US24037",
        GEOID: "24037",
        NAME: "St. Mary's",
        LSAD: "06",
        ALAND: 928885370,
        AWATER: 1050517131
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.87146, 38.389936999081407],
            [-76.76829, 38.5129489990805],
            [-76.674118, 38.499621999080588],
            [-76.608373, 38.424444999081153],
            [-76.501475, 38.372496999081534],
            [-76.453453, 38.305056999082041],
            [-76.400194624698003, 38.319871943043331],
            [-76.402894, 38.311401999082001],
            [-76.374481, 38.296347999082116],
            [-76.392668, 38.239662999082547],
            [-76.353516, 38.178134999083028],
            [-76.320136, 38.138338999083324],
            [-76.330794, 38.099330999083627],
            [-76.322093, 38.036502999084121],
            [-76.37179, 38.079564999083786],
            [-76.430425, 38.11938299908347],
            [-76.481036, 38.1158729990835],
            [-76.54038, 38.152990999083215],
            [-76.590637, 38.214211999082742],
            [-76.673462, 38.234400999082581],
            [-76.740055, 38.235226999082585],
            [-76.805949, 38.252274999082452],
            [-76.827036129281879, 38.258300099696498],
            [-76.87146, 38.389936999081407]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "091",
        COUNTYNS: "00516892",
        AFFGEOID: "0500000US21091",
        GEOID: "21091",
        NAME: "Hancock",
        LSAD: "06",
        ALAND: 486020462,
        AWATER: 29093100
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.977741425110196, 37.925699207457711],
            [-86.927747, 37.934955999084927],
            [-86.875874, 37.970769999084645],
            [-86.81365994128528, 37.996034494226045],
            [-86.810913, 37.997149999084435],
            [-86.779993, 37.956521999084757],
            [-86.722247, 37.892647999085263],
            [-86.658374, 37.869375999085456],
            [-86.646703057859099, 37.864908974983294],
            [-86.677873, 37.792847999086071],
            [-86.62295, 37.656564999087202],
            [-86.638935, 37.661876999087141],
            [-86.823429, 37.737753999086522],
            [-86.893859, 37.882112999085351],
            [-86.987159, 37.896699999085243],
            [-86.977741425110196, 37.925699207457711]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "081",
        COUNTYNS: "01383826",
        AFFGEOID: "0500000US48081",
        GEOID: "48081",
        NAME: "Coke",
        LSAD: "06",
        ALAND: 2360696264,
        AWATER: 42788774
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.821594, 32.086609999150362],
            [-100.665353, 32.085406999150365],
            [-100.271675, 32.081431999150446],
            [-100.235137, 32.082371999150418],
            [-100.235761, 31.692969999156077],
            [-100.82537, 31.696154999156025],
            [-100.821594, 32.086609999150362]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "039",
        COUNTYNS: "00596907",
        AFFGEOID: "0500000US24039",
        GEOID: "24039",
        NAME: "Somerset",
        LSAD: "06",
        ALAND: 828090979,
        AWATER: 752714478
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-75.942375, 38.187065999082954],
              [-75.864104, 38.200857999082842],
              [-75.875447311464697, 38.219709301043707],
              [-75.768671, 38.285182999082195],
              [-75.662679, 38.298844999082107],
              [-75.612764, 38.278625999082237],
              [-75.545898, 38.178739999083014],
              [-75.54087, 38.089479999083707],
              [-75.661827, 38.041852999084085],
              [-75.624341, 37.994210999084459],
              [-75.669711, 37.950795999084804],
              [-75.722662, 37.971309999084632],
              [-75.783815, 37.97259399908463],
              [-75.860727, 37.918309999085061],
              [-75.892686, 37.916847999085086],
              [-75.898956, 37.974513999084621],
              [-75.857507, 38.038777999084111],
              [-75.858881, 38.060134999083935],
              [-75.86381, 38.100967999083615],
              [-75.937089, 38.124208999083443],
              [-75.942375, 38.187065999082954]
            ]
          ],
          [
            [
              [-76.046213, 38.025532999084206],
              [-76.007337, 38.03670599908412],
              [-75.980089, 38.004890999084381],
              [-75.984648, 37.938120999084902],
              [-76.04653, 37.953585999084787],
              [-76.046213, 38.025532999084206]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "019",
        COUNTYNS: "00558468",
        AFFGEOID: "0500000US22019",
        GEOID: "22019",
        NAME: "Calcasieu",
        LSAD: "15",
        ALAND: 2756538198,
        AWATER: 77608623
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.745333, 30.397021999176012],
            [-93.738540238226008, 30.402263671656716],
            [-93.486116, 30.402499999175909],
            [-93.486384, 30.490522999174505],
            [-93.384673, 30.490108999174517],
            [-93.38515, 30.402629999175922],
            [-93.131109, 30.403056999175902],
            [-93.030955, 30.379389999176283],
            [-93.030685, 30.260594999178199],
            [-92.9963, 30.156954999179881],
            [-92.893324, 30.156313999179893],
            [-92.887222, 30.083454999181072],
            [-92.996371, 30.038478999181812],
            [-93.128475, 30.052586999181592],
            [-93.706335762470189, 30.052180240712495],
            [-93.70394, 30.054290999181557],
            [-93.702436, 30.112720999180599],
            [-93.703764, 30.173935999179605],
            [-93.713359, 30.225260999178779],
            [-93.711062171300895, 30.243970714818769],
            [-93.70719, 30.275512999177959],
            [-93.760328, 30.329923999177083],
            [-93.745333, 30.397021999176012]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "041",
        COUNTYNS: "00592947",
        AFFGEOID: "0500000US24041",
        GEOID: "24041",
        NAME: "Talbot",
        LSAD: "06",
        ALAND: 695519250,
        AWATER: 539405840
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.379739, 38.788313999078518],
            [-76.310081, 38.796845999078457],
            [-76.271575, 38.85177099907807],
            [-76.219328, 38.812370999078347],
            [-76.19109, 38.829659999078224],
            [-76.196868759948003, 38.855741962498755],
            [-76.103512, 38.88230799907786],
            [-76.096956, 38.944258999077434],
            [-75.949335, 38.918332999077613],
            [-75.896774, 38.815108999078333],
            [-76.014427, 38.726462999078947],
            [-75.945248, 38.674108999079337],
            [-76.027717, 38.572432999080064],
            [-76.170161874587293, 38.640842046229672],
            [-76.175159, 38.673235999079338],
            [-76.200334, 38.670773999079351],
            [-76.238725, 38.712844999079053],
            [-76.275015, 38.71271399907905],
            [-76.322418, 38.679303999079288],
            [-76.347998, 38.686233999079235],
            [-76.340543, 38.730337999078927],
            [-76.39035, 38.757003999078734],
            [-76.379739, 38.788313999078518]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "043",
        COUNTYNS: "01714220",
        AFFGEOID: "0500000US24043",
        GEOID: "24043",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1185609331,
        AWATER: 24803926
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.342593, 39.722657999072432],
            [-78.098970696166688, 39.722466193500537],
            [-78.075861, 39.722447999072443],
            [-77.768644, 39.721537999072432],
            [-77.469274, 39.720228999072454],
            [-77.469145003680396, 39.720229001322451],
            [-77.570182, 39.61999799907305],
            [-77.622604, 39.507597999073752],
            [-77.656177, 39.368396999074641],
            [-77.677695762078685, 39.317940816162952],
            [-77.719519, 39.321313999074931],
            [-77.74593, 39.353220999074722],
            [-77.740012, 39.401693999074425],
            [-77.798201, 39.475718999073948],
            [-77.810943896262188, 39.500739165640297],
            [-77.823762, 39.525906999073641],
            [-77.829814, 39.587287999073268],
            [-77.925988, 39.607641999073138],
            [-78.006734, 39.601336999073183],
            [-78.027629100173186, 39.620655961417164],
            [-78.08226, 39.671165999072748],
            [-78.225075, 39.658877999072828],
            [-78.313033, 39.631000999072988],
            [-78.332788021499098, 39.628527595571803],
            [-78.313325, 39.692473999072618],
            [-78.342833518574395, 39.722658291998926],
            [-78.342593, 39.722657999072432]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "057",
        COUNTYNS: "01213671",
        AFFGEOID: "0500000US42057",
        GEOID: "42057",
        NAME: "Fulton",
        LSAD: "06",
        ALAND: 1133252798,
        AWATER: 1305486
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.380477064564005, 39.722704137912444],
            [-78.299003, 39.825499999071837],
            [-78.181533, 40.058924999070491],
            [-78.206894, 40.086264999070337],
            [-78.134863, 40.165130999069902],
            [-77.92556, 40.103976999070227],
            [-77.864489, 40.061567999070476],
            [-77.920952, 40.000449999070817],
            [-78.0027, 39.826598999071827],
            [-78.098970696166688, 39.722466193500537],
            [-78.342593, 39.722657999072432],
            [-78.342833518574395, 39.722658291998926],
            [-78.380477064564005, 39.722704137912444]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "045",
        COUNTYNS: "00465211",
        AFFGEOID: "0500000US19045",
        GEOID: "19045",
        NAME: "Clinton",
        LSAD: "06",
        ALAND: 1799830900,
        AWATER: 39514681
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.898373, 42.033547999061646],
            [-90.159682451122677, 42.033087256647562],
            [-90.140613, 41.995998999061776],
            [-90.156902, 41.938180999061984],
            [-90.158153033709496, 41.929843226438109],
            [-90.165065, 41.883776999062164],
            [-90.181401, 41.844646999062306],
            [-90.180643, 41.811978999062418],
            [-90.242368247397195, 41.78276872504712],
            [-90.248631, 41.779804999062534],
            [-90.310708, 41.742213999062663],
            [-90.311856850416177, 41.728532891216346],
            [-90.471723, 41.773602999062561],
            [-90.685383, 41.757204999062616],
            [-90.898373, 41.771391999062558],
            [-90.898484, 41.946244999061932],
            [-90.898373, 42.033547999061646]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "510",
        COUNTYNS: "01702381",
        AFFGEOID: "0500000US24510",
        GEOID: "24510",
        NAME: "Baltimore",
        LSAD: "25",
        ALAND: 209643563,
        AWATER: 28767622
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.711191, 39.362797999074665],
            [-76.693693, 39.372104999074601],
            [-76.626193, 39.372016999074603],
            [-76.529793, 39.372057999074599],
            [-76.529517, 39.297055999075084],
            [-76.529486, 39.218407999075595],
            [-76.580287, 39.206606999075682],
            [-76.644212, 39.248923999075394],
            [-76.65986, 39.255508999075346],
            [-76.684649, 39.266457999075286],
            [-76.711054, 39.277888999075202],
            [-76.711186, 39.354382999074716],
            [-76.711191, 39.362797999074665]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "001",
        COUNTYNS: "00484970",
        AFFGEOID: "0500000US20001",
        GEOID: "20001",
        NAME: "Allen",
        LSAD: "06",
        ALAND: 1295762491,
        AWATER: 13023677
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.519308, 37.820258999085851],
            [-95.51897, 38.038229999084109],
            [-95.077876, 38.03770599908411],
            [-95.079692, 37.819799999085852],
            [-95.088082, 37.732475999086567],
            [-95.124987, 37.733923999086556],
            [-95.525499, 37.73275899908657],
            [-95.519308, 37.820258999085851]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "037",
        COUNTYNS: "00484988",
        AFFGEOID: "0500000US20037",
        GEOID: "20037",
        NAME: "Crawford",
        LSAD: "06",
        ALAND: 1527463453,
        AWATER: 13609414
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.088012, 37.674518999087034],
            [-94.617872450898091, 37.673110699419162],
            [-94.617845524354209, 37.653577795721631],
            [-94.617511, 37.410908999089273],
            [-94.617667604700799, 37.364170326128367],
            [-94.617753891606583, 37.338417999089884],
            [-95.075166, 37.339936999089879],
            [-95.088189, 37.383836999089496],
            [-95.088012, 37.674518999087034]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "089",
        COUNTYNS: "01383830",
        AFFGEOID: "0500000US48089",
        GEOID: "48089",
        NAME: "Colorado",
        LSAD: "06",
        ALAND: 2487123844,
        AWATER: 34710043
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.874222, 29.632705999188552],
            [-96.569844, 29.961515999183089],
            [-96.535705, 29.907325999183978],
            [-96.413283, 29.82498499918534],
            [-96.343247, 29.829696999185263],
            [-96.259226, 29.668911999187944],
            [-96.175422, 29.633805999188532],
            [-96.307672, 29.51452299919054],
            [-96.349217, 29.406051999192382],
            [-96.640315, 29.247803999195096],
            [-96.658466, 29.263875999194823],
            [-96.560555, 29.335438999193588],
            [-96.793132, 29.577377999189487],
            [-96.874222, 29.632705999188552]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "091",
        COUNTYNS: "01383831",
        AFFGEOID: "0500000US48091",
        GEOID: "48091",
        NAME: "Comal",
        LSAD: "06",
        ALAND: 1449127320,
        AWATER: 39800429
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.646124, 29.745180999186665],
            [-98.414018, 29.937556999183478],
            [-98.2976, 30.037993999181822],
            [-98.12127, 29.912843999183888],
            [-98.030523, 29.848538999184949],
            [-98.01518, 29.801484999185735],
            [-97.999271, 29.752443999186543],
            [-98.089941, 29.683478999187699],
            [-98.19763, 29.638127999188459],
            [-98.310928, 29.594472999189193],
            [-98.31095, 29.594559999189201],
            [-98.328651, 29.608232999188971],
            [-98.378068, 29.662612999188056],
            [-98.352589, 29.734364999186848],
            [-98.443852, 29.719649999187098],
            [-98.445448, 29.735635999186822],
            [-98.550489, 29.760712999186413],
            [-98.646124, 29.745180999186665]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "099",
        COUNTYNS: "01383835",
        AFFGEOID: "0500000US48099",
        GEOID: "48099",
        NAME: "Coryell",
        LSAD: "06",
        ALAND: 2725199226,
        AWATER: 11781488
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.180006, 31.463716999159473],
            [-97.766431, 31.672460999156375],
            [-97.689834, 31.711018999155812],
            [-97.60523, 31.587761999157628],
            [-97.418606, 31.320201999161633],
            [-97.815854, 31.11620999916472],
            [-97.846428, 31.100609999164977],
            [-97.9071, 31.069373999165453],
            [-98.180006, 31.463716999159473]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "105",
        COUNTYNS: "01383838",
        AFFGEOID: "0500000US48105",
        GEOID: "48105",
        NAME: "Crockett",
        LSAD: "06",
        ALAND: 7270935065,
        AWATER: 53329
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.301212, 31.086211999165194],
            [-101.776191, 31.079783999165286],
            [-101.274799, 31.0793789991653],
            [-100.962176, 31.082489999165247],
            [-100.960587, 30.7060709991711],
            [-100.960643, 30.28777599917775],
            [-101.7584, 30.288043999177763],
            [-101.65853, 30.357860999176633],
            [-101.740429, 30.420896999175625],
            [-101.646245, 30.560762999173388],
            [-101.652446, 30.62047099917244],
            [-101.76842, 30.65307699917193],
            [-101.80742, 30.699503999171188],
            [-101.883436, 30.925646999167672],
            [-101.98364, 30.98716999916671],
            [-102.191731, 31.003787999166462],
            [-102.202032, 31.031025999166033],
            [-102.292681, 31.036676999165962],
            [-102.388804, 31.087155999165187],
            [-102.301212, 31.086211999165194]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "105",
        COUNTYNS: "00516899",
        AFFGEOID: "0500000US21105",
        GEOID: "21105",
        NAME: "Hickman",
        LSAD: "06",
        ALAND: 627487971,
        AWATER: 27882017
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.202511, 36.716617999095476],
            [-89.15699, 36.755967999095112],
            [-89.155984909070384, 36.786292662466252],
            [-89.104158, 36.797284999094735],
            [-89.067265, 36.772762999094972],
            [-88.813229, 36.773114999094958],
            [-88.819387, 36.749968999095174],
            [-88.816764157348089, 36.501951430910722],
            [-88.827178036340484, 36.501968321354418],
            [-88.834589022874297, 36.501980341356813],
            [-88.90348, 36.576863999096794],
            [-88.940873, 36.591513999096655],
            [-89.026629, 36.592123999096664],
            [-89.147098, 36.64911699909613],
            [-89.175649772346873, 36.651319261649597],
            [-89.165488, 36.662425999095994],
            [-89.202511, 36.716617999095476]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "007",
        COUNTYNS: "00581289",
        AFFGEOID: "0500000US23007",
        GEOID: "23007",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 4395190350,
        AWATER: 121369621
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.82979, 45.286940999056824],
            [-70.808613, 45.311605999056837],
            [-70.819471, 45.341434999056851],
            [-70.806244, 45.376557999056871],
            [-70.825612, 45.400304999056878],
            [-70.781471, 45.431158999056898],
            [-70.755567, 45.428360999056885],
            [-70.729972, 45.399358999056865],
            [-70.677995, 45.394361999056876],
            [-70.634661, 45.383607999056871],
            [-70.635498, 45.427816999056894],
            [-70.674903, 45.452398999056911],
            [-70.723396, 45.510393999056944],
            [-70.688214, 45.563980999056973],
            [-70.649578, 45.598146999057001],
            [-70.591275, 45.630550999057036],
            [-70.55282390337851, 45.667806056942162],
            [-70.518696, 45.512126999056939],
            [-70.418519, 45.14400499905679],
            [-70.308491, 45.163182999056779],
            [-70.293826, 45.109902999056764],
            [-70.159375, 45.128395999056771],
            [-70.110365, 44.947310999056761],
            [-70.149085, 44.896101999056761],
            [-70.12993, 44.850764999056764],
            [-70.03327, 44.864539999056774],
            [-69.996191, 44.677496999056807],
            [-69.960149, 44.681225999056799],
            [-69.930451, 44.610851999056827],
            [-69.946761, 44.582345999056834],
            [-69.953495, 44.574687999056849],
            [-70.040127, 44.588452999056841],
            [-70.041163, 44.571252999056846],
            [-70.072226, 44.529323999056871],
            [-70.035546, 44.508082999056882],
            [-70.04235, 44.485234999056885],
            [-70.12939, 44.487216999056898],
            [-70.201276, 44.477975999056888],
            [-70.238904, 44.458941999056904],
            [-70.250895, 44.503401999056891],
            [-70.307659, 44.537875999056858],
            [-70.280748, 44.56961899905685],
            [-70.549985, 44.610726999056816],
            [-70.510598, 44.627215999056823],
            [-70.61503, 44.795633999056768],
            [-70.769228, 44.732227999056789],
            [-70.773902, 44.83977699905676],
            [-70.823634, 45.013789999056762],
            [-70.779842, 45.018378999056772],
            [-70.834019537440099, 45.271794401453626],
            [-70.82979, 45.286940999056824]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "067",
        COUNTYNS: "00465222",
        AFFGEOID: "0500000US19067",
        GEOID: "19067",
        NAME: "Floyd",
        LSAD: "06",
        ALAND: 1296631080,
        AWATER: 1942548
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.024092, 43.212811999058495],
            [-92.554381, 43.212812999058499],
            [-92.554211, 42.907111999059161],
            [-93.025144, 42.907548999059159],
            [-93.024092, 43.212811999058495]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "017",
        COUNTYNS: "01026336",
        AFFGEOID: "0500000US37017",
        GEOID: "37017",
        NAME: "Bladen",
        LSAD: "06",
        ALAND: 2265870801,
        AWATER: 33027800
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.901998, 34.835267999115004],
            [-78.799597, 34.850856999114832],
            [-78.516123, 34.845918999114879],
            [-78.494705, 34.85618199911476],
            [-78.390748, 34.749462999115984],
            [-78.391978, 34.741264999116076],
            [-78.374363, 34.700721999116553],
            [-78.254441, 34.55359499911826],
            [-78.255468, 34.508613999118779],
            [-78.176501, 34.465355999119289],
            [-78.256085, 34.399468999120067],
            [-78.334569, 34.367535999120463],
            [-78.44532, 34.377399999120335],
            [-78.648373, 34.460868999119342],
            [-78.830353, 34.462577999119333],
            [-78.868961, 34.484777999119068],
            [-78.805721, 34.68920199911669],
            [-78.901998, 34.835267999115004]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "149",
        COUNTYNS: "01639787",
        AFFGEOID: "0500000US47149",
        GEOID: "47149",
        NAME: "Rutherford",
        LSAD: "06",
        ALAND: 1604162229,
        AWATER: 12132094
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.686193, 35.710050999105448],
            [-86.613328, 35.790330999104626],
            [-86.618892, 35.968223999102783],
            [-86.546884, 36.087816999101584],
            [-86.515589, 36.100609999101444],
            [-86.251731, 35.961140999102852],
            [-86.153214, 35.954398999102928],
            [-86.141766, 35.91437399910334],
            [-86.207147, 35.70378999910551],
            [-86.245406, 35.688898999105668],
            [-86.245165, 35.63191299910627],
            [-86.34194, 35.664258999105932],
            [-86.402726, 35.618570999106403],
            [-86.543116, 35.697616999105577],
            [-86.639436, 35.685899999105708],
            [-86.686193, 35.710050999105448]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "153",
        COUNTYNS: "01652643",
        AFFGEOID: "0500000US47153",
        GEOID: "47153",
        NAME: "Sequatchie",
        LSAD: "06",
        ALAND: 688550864,
        AWATER: 498314
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.557535, 35.532976999107312],
            [-85.459856, 35.546849999107174],
            [-85.423804, 35.567421999106948],
            [-85.436147, 35.541364999107216],
            [-85.403298, 35.492279999107744],
            [-85.225877, 35.354275999109234],
            [-85.246005, 35.319076999109619],
            [-85.387089, 35.147168999111493],
            [-85.403187, 35.223418999110656],
            [-85.558551, 35.319291999109609],
            [-85.485622, 35.406306999108672],
            [-85.607326, 35.53165099910732],
            [-85.557535, 35.532976999107312]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "041",
        COUNTYNS: "00424222",
        AFFGEOID: "0500000US17041",
        GEOID: "17041",
        NAME: "Douglas",
        LSAD: "06",
        ALAND: 1079156592,
        AWATER: 1448702
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.473182, 39.791836999072032],
            [-88.462284, 39.802308999071968],
            [-88.462207, 39.879091999071512],
            [-88.349187, 39.879450999071508],
            [-87.937645, 39.879802999071508],
            [-87.968777, 39.79234999907203],
            [-87.9666, 39.685927999072661],
            [-88.063437, 39.652554999072862],
            [-88.472073, 39.651587999072866],
            [-88.473182, 39.791836999072032]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "085",
        COUNTYNS: "00465231",
        AFFGEOID: "0500000US19085",
        GEOID: "19085",
        NAME: "Harrison",
        LSAD: "06",
        ALAND: 1804808356,
        AWATER: 12417698
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.126821253465394, 41.866095246763841],
            [-95.672771, 41.863146999062238],
            [-95.557277, 41.863448999062236],
            [-95.558143, 41.602382999063202],
            [-95.497793, 41.602332999063208],
            [-95.497997, 41.506073999063567],
            [-95.994020393011894, 41.506890640005068],
            [-96.005079, 41.544003999063435],
            [-96.080493, 41.528198999063491],
            [-96.09182, 41.561085999063359],
            [-96.118105, 41.613494999063164],
            [-96.111483, 41.668547999062952],
            [-96.107937871746472, 41.676508988825034],
            [-96.0876, 41.72217999906276],
            [-96.064537, 41.793001999062483],
            [-96.107911, 41.840338999062318],
            [-96.126821253465394, 41.866095246763841]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "121",
        COUNTYNS: "00516907",
        AFFGEOID: "0500000US21121",
        GEOID: "21121",
        NAME: "Knox",
        LSAD: "06",
        ALAND: 1000508765,
        AWATER: 3753811
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.091853, 36.955991999093278],
            [-83.959138, 36.977272999093088],
            [-83.871315, 37.054918999092394],
            [-83.774061, 37.036830999092551],
            [-83.640616, 36.959552999093248],
            [-83.589521, 36.955785999093287],
            [-83.566142, 36.931524999093504],
            [-83.798248, 36.785196999094843],
            [-83.877503, 36.687258999095768],
            [-83.958534, 36.727244999095376],
            [-83.983674, 36.800838999094708],
            [-84.065833, 36.854146999094212],
            [-84.091853, 36.955991999093278]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "059",
        COUNTYNS: "01622972",
        AFFGEOID: "0500000US26059",
        GEOID: "26059",
        NAME: "Hillsdale",
        LSAD: "06",
        ALAND: 1549207599,
        AWATER: 22938677
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.826491, 42.07246799906153],
            [-84.709556, 42.070365999061522],
            [-84.363297, 42.07345599906153],
            [-84.360419041397293, 41.706955881696302],
            [-84.399548785474195, 41.705921356468401],
            [-84.438067, 41.704902999062831],
            [-84.806082, 41.696088999062837],
            [-84.805883, 41.760215999062609],
            [-84.825128283876793, 41.76019900541452],
            [-84.826491, 42.07246799906153]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "127",
        COUNTYNS: "00516910",
        AFFGEOID: "0500000US21127",
        GEOID: "21127",
        NAME: "Lawrence",
        LSAD: "06",
        ALAND: 1076385464,
        AWATER: 11584860
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.022115, 38.007046999084359],
            [-82.888644, 38.112805999083527],
            [-82.924805, 38.175113999083038],
            [-82.8232, 38.208263999082781],
            [-82.794206, 38.243771999082519],
            [-82.614304, 38.266077999082341],
            [-82.584693814125586, 38.240512992452238],
            [-82.598864, 38.201006999082843],
            [-82.626182, 38.134834999083346],
            [-82.549407, 38.063062999083911],
            [-82.464987, 37.976858999084591],
            [-82.479420123222994, 37.938562523783006],
            [-82.580864, 37.954073999084777],
            [-82.612393, 37.879271999085368],
            [-82.831615, 37.967883999084663],
            [-82.952043, 38.000315999084407],
            [-82.989014, 37.963967999084701],
            [-83.022115, 38.007046999084359]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "167",
        COUNTYNS: "01639795",
        AFFGEOID: "0500000US47167",
        GEOID: "47167",
        NAME: "Tipton",
        LSAD: "06",
        ALAND: 1187267191,
        AWATER: 38609225
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.037615, 35.550328999107123],
            [-89.958498, 35.541702999107223],
            [-89.944754, 35.560307999107017],
            [-89.909797, 35.537913999107268],
            [-89.7952, 35.635289999106227],
            [-89.623458, 35.652315999106051],
            [-89.502118, 35.580620999106806],
            [-89.469862, 35.546565999107173],
            [-89.474171, 35.403038999108702],
            [-89.616176, 35.388661999108855],
            [-89.632776, 35.375823999109002],
            [-89.70248, 35.408583999108643],
            [-89.865515, 35.390769999108841],
            [-90.039777, 35.396530999108769],
            [-90.045306, 35.415434999108562],
            [-90.022064, 35.457374999108119],
            [-90.045805, 35.4965329991077],
            [-90.037615, 35.550328999107123]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "119",
        COUNTYNS: "01383846",
        AFFGEOID: "0500000US48119",
        GEOID: "48119",
        NAME: "Delta",
        LSAD: "06",
        ALAND: 665174292,
        AWATER: 54666016
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.858723, 33.409529999132332],
            [-95.857738, 33.461721999131669],
            [-95.826979, 33.458967999131701],
            [-95.761076, 33.495386999131235],
            [-95.671634, 33.472635999131526],
            [-95.603675, 33.484720999131369],
            [-95.491897, 33.455336999131752],
            [-95.416898, 33.412193999132313],
            [-95.308637, 33.380661999132712],
            [-95.30664, 33.37802699913275],
            [-95.308593, 33.377189999132753],
            [-95.501827, 33.347864999133158],
            [-95.584549, 33.359343999132996],
            [-95.673722, 33.30211699913373],
            [-95.720231, 33.299410999133777],
            [-95.731056, 33.305221999133707],
            [-95.786682, 33.253996999134358],
            [-95.861778, 33.219329999134814],
            [-95.858723, 33.409529999132332]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "133",
        COUNTYNS: "00516913",
        AFFGEOID: "0500000US21133",
        GEOID: "21133",
        NAME: "Letcher",
        LSAD: "06",
        ALAND: 875192042,
        AWATER: 2898572
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.12209, 37.000996999092877],
            [-83.05534, 37.017390999092719],
            [-83.058818, 37.124078999091779],
            [-83.000142, 37.195709999091129],
            [-82.979183, 37.172419999091346],
            [-82.819373, 37.268670999090496],
            [-82.732693, 37.271501999090475],
            [-82.684264, 37.228795999090856],
            [-82.628921, 37.247803999090678],
            [-82.56527570279701, 37.195901105974229],
            [-82.726294, 37.111851999091897],
            [-82.722254, 37.057947999092363],
            [-82.750715, 37.024106999092659],
            [-82.815748, 37.007195999092822],
            [-82.869183, 36.974181999093126],
            [-82.939466, 37.015519999092739],
            [-83.153401, 36.956590999093272],
            [-83.12209, 37.000996999092877]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "135",
        COUNTYNS: "00516914",
        AFFGEOID: "0500000US21135",
        GEOID: "21135",
        NAME: "Lewis",
        LSAD: "06",
        ALAND: 1250540181,
        AWATER: 32492720
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.642994, 38.643272999079556],
            [-83.626922, 38.679386999079291],
            [-83.533339, 38.702104999079133],
            [-83.440404, 38.669360999079366],
            [-83.376302, 38.661472999079415],
            [-83.320531, 38.622712999079695],
            [-83.286514, 38.599240999079861],
            [-83.264298901958696, 38.613112495967265],
            [-83.239515, 38.628587999079656],
            [-83.172647, 38.62025199907972],
            [-83.128973, 38.640230999079577],
            [-83.112372, 38.671684999079339],
            [-83.042338, 38.708318999079083],
            [-83.030334931231096, 38.716867675735124],
            [-83.024075, 38.683305999079266],
            [-83.071005, 38.595199999079888],
            [-83.166419, 38.503964999080566],
            [-83.237181, 38.426245999081132],
            [-83.230598, 38.339507999081782],
            [-83.341347, 38.319520999081924],
            [-83.412003, 38.396445999081365],
            [-83.452848, 38.381798999081475],
            [-83.581613, 38.431257999081097],
            [-83.641852, 38.525380999080404],
            [-83.624596, 38.61142499907978],
            [-83.646911404126683, 38.641851933625368],
            [-83.642994, 38.643272999079556]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "029",
        COUNTYNS: "00558515",
        AFFGEOID: "0500000US22029",
        GEOID: "22029",
        NAME: "Concordia",
        LSAD: "15",
        ALAND: 1805172274,
        AWATER: 130329995
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.845019, 31.375915999160789],
            [-91.776779, 31.385214999160649],
            [-91.855889, 31.423830999160071],
            [-91.857083, 31.494526999159014],
            [-91.807098, 31.438793999159856],
            [-91.738331, 31.431571999159949],
            [-91.738032, 31.467409999159418],
            [-91.798754, 31.473353999159329],
            [-91.779725, 31.530972999158465],
            [-91.826926, 31.591670999157571],
            [-91.775836, 31.648625999156735],
            [-91.737189, 31.614087999157242],
            [-91.705948, 31.642862999156815],
            [-91.694469, 31.735285999155455],
            [-91.635822, 31.729118999155542],
            [-91.601622, 31.761863999155061],
            [-91.544791, 31.751730999155203],
            [-91.39829, 31.753211999155187],
            [-91.38012452998349, 31.732626952205496],
            [-91.380915, 31.732463999155499],
            [-91.395715, 31.644164999156786],
            [-91.463817, 31.620364999157143],
            [-91.457517, 31.587565999157636],
            [-91.437616, 31.546165999158248],
            [-91.489618, 31.534265999158421],
            [-91.51714, 31.498393999158957],
            [-91.510356, 31.438927999159837],
            [-91.532336, 31.390274999160575],
            [-91.536061, 31.338354999161357],
            [-91.508858, 31.291643999162055],
            [-91.564192, 31.261632999162515],
            [-91.621358, 31.267810999162418],
            [-91.644356, 31.234413999162928],
            [-91.590051, 31.193692999163549],
            [-91.590993893064493, 31.19199856486237],
            [-91.621671, 31.136869999164421],
            [-91.594693, 31.091443999165108],
            [-91.560365, 31.049507999165748],
            [-91.628257298208794, 31.005097001061635],
            [-91.723804, 31.044253999165832],
            [-91.719004, 31.134615999164453],
            [-91.677302, 31.188046999163635],
            [-91.83428, 31.266343999162441],
            [-91.862168, 31.302313999161907],
            [-91.845019, 31.375915999160789]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "317",
        COUNTYNS: "00351265",
        AFFGEOID: "0500000US13317",
        GEOID: "13317",
        NAME: "Wilkes",
        LSAD: "06",
        ALAND: 1215973894,
        AWATER: 11924769
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.986325, 33.785404999127564],
            [-82.874587, 33.921766999125865],
            [-82.779506, 33.971123999125254],
            [-82.72439, 33.984471999125084],
            [-82.645451, 33.984194999125087],
            [-82.605687, 33.859941999126626],
            [-82.572297, 33.866342999126559],
            [-82.593527, 33.827321999127044],
            [-82.479518, 33.639534999129388],
            [-82.507223, 33.659993999129142],
            [-82.649705, 33.608767999129796],
            [-82.679975, 33.599791999129899],
            [-82.812958, 33.655835999129195],
            [-82.879449, 33.620409999129642],
            [-82.949046, 33.733332999128216],
            [-82.986325, 33.785404999127564]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "007",
        COUNTYNS: "00069896",
        AFFGEOID: "0500000US05007",
        GEOID: "05007",
        NAME: "Benton",
        LSAD: "06",
        ALAND: 2195019818,
        AWATER: 94307300
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.617919, 36.499413999097541],
            [-94.361203, 36.499599999097541],
            [-94.077088266893469, 36.498975956319455],
            [-93.95919, 36.49871699909756],
            [-93.866758211671069, 36.498866163782438],
            [-93.890508, 36.37789399909871],
            [-93.815605, 36.306562999099398],
            [-93.869066, 36.307449999099404],
            [-93.887775, 36.235045999100109],
            [-93.973298, 36.203851999100422],
            [-94.333565, 36.214726999100314],
            [-94.33643, 36.142247999101038],
            [-94.391125, 36.099395999101461],
            [-94.551906282606296, 36.102225604770034],
            [-94.562267928552785, 36.161972613727237],
            [-94.5862, 36.29996899909947],
            [-94.617919, 36.499413999097541]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "057",
        COUNTYNS: "00465217",
        AFFGEOID: "0500000US19057",
        GEOID: "19057",
        NAME: "Des Moines",
        LSAD: "06",
        ALAND: 1077784093,
        AWATER: 35342793
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.372095, 40.812630999066592],
            [-91.37071, 41.074111999065394],
            [-90.952265416482192, 41.072731748735407],
            [-90.951894142681283, 41.069872734931799],
            [-90.945324, 41.019278999065641],
            [-90.952233, 40.95404699906593],
            [-90.985462, 40.912140999066118],
            [-91.044653, 40.868355999066331],
            [-91.092993, 40.821078999066557],
            [-91.091703, 40.779707999066758],
            [-91.115735, 40.725167999067011],
            [-91.118222938397508, 40.699534649886246],
            [-91.208185, 40.713579999067065],
            [-91.409453, 40.812904999066596],
            [-91.372095, 40.812630999066592]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "045",
        COUNTYNS: "01668606",
        AFFGEOID: "0500000US24045",
        GEOID: "24045",
        NAME: "Wicomico",
        LSAD: "06",
        ALAND: 969736578,
        AWATER: 66797773
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.928628, 38.297271999082113],
            [-75.864213, 38.350781999081697],
            [-75.823706, 38.481021999080731],
            [-75.701777515244999, 38.560766928158749],
            [-75.700382, 38.542742999080289],
            [-75.693721, 38.460127999080882],
            [-75.479283, 38.45369799908093],
            [-75.341287320348187, 38.45243652503644],
            [-75.307011, 38.354759999081665],
            [-75.363582, 38.291305999082155],
            [-75.612764, 38.278625999082237],
            [-75.662679, 38.298844999082107],
            [-75.768671, 38.285182999082195],
            [-75.875447311464697, 38.219709301043707],
            [-75.888513, 38.241422999082531],
            [-75.923766082428301, 38.2462852760845],
            [-75.928628, 38.297271999082113]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "017",
        COUNTYNS: "00863992",
        AFFGEOID: "0500000US32017",
        GEOID: "32017",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 27540342244,
        AWATER: 9802713
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-115.893857, 38.05053099908401],
            [-115.000846, 38.050756999084001],
            [-115.000846, 38.677321999079304],
            [-114.049436705930987, 38.6773624559589],
            [-114.050153620806, 38.572975212083463],
            [-114.050154, 38.57291999908005],
            [-114.05012, 38.404535999081297],
            [-114.050138, 38.249959999082471],
            [-114.049903375658005, 38.148763025753837],
            [-114.049903, 38.14860099908325],
            [-114.049658, 37.881367999085349],
            [-114.051728, 37.745996999086451],
            [-114.052471996614003, 37.604776641807035],
            [-114.052472, 37.604775999087614],
            [-114.052701, 37.492013999088577],
            [-114.051974, 37.284510999090358],
            [-114.051749, 37.088433999092096],
            [-114.0506, 37.000395999092873],
            [-114.050160776087992, 36.843141538783719],
            [-114.762507, 36.853472999094222],
            [-115.762975, 36.841910999094324],
            [-115.896925, 36.842084999094318],
            [-115.893857, 38.05053099908401]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "033",
        COUNTYNS: "01265788",
        AFFGEOID: "0500000US46033",
        GEOID: "46033",
        NAME: "Custer",
        LSAD: "06",
        ALAND: 4032473668,
        AWATER: 5481079
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.055487998450985, 43.853475997513449],
            [-102.687191, 43.855598999057463],
            [-102.695526, 43.798149999057522],
            [-102.809398, 43.687559999057683],
            [-102.9025, 43.674887999057695],
            [-103.001017, 43.605815999057796],
            [-103.000913, 43.476847999058016],
            [-103.935373, 43.478823999058008],
            [-104.054684568427007, 43.477815540769029],
            [-104.054794284348006, 43.503327529463974],
            [-104.055032, 43.558602999057868],
            [-104.055487998450985, 43.853475997513449]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "710",
        COUNTYNS: "01498557",
        AFFGEOID: "0500000US51710",
        GEOID: "51710",
        NAME: "Norfolk",
        LSAD: "25",
        ALAND: 137985835,
        AWATER: 111697132
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.341825, 36.924771999093565],
            [-76.296792290295201, 36.99379096554464],
            [-76.280972050816587, 36.977743726761076],
            [-76.267962, 36.964546999093201],
            [-76.189959, 36.931446999093509],
            [-76.176947585198704, 36.928538679248533],
            [-76.193122, 36.877424999094004],
            [-76.197259, 36.828147999094455],
            [-76.22606, 36.839946999094337],
            [-76.289962, 36.822047999094508],
            [-76.336784, 36.875026999094018],
            [-76.333088, 36.896362999093832],
            [-76.341825, 36.924771999093565]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "130",
        COUNTYNS: "01419973",
        AFFGEOID: "0500000US02130",
        GEOID: "02130",
        NAME: "Ketchikan Gateway",
        LSAD: "04",
        ALAND: 12579507052,
        AWATER: 5220218255
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-132.199681399056999, 55.633833999121542],
            [-132.091178, 55.66070299912186],
            [-132.058825, 55.710929999122463],
            [-131.962744, 55.700756999122341],
            [-131.978551, 55.753365999122977],
            [-131.935635, 55.798430999123518],
            [-132.011037, 55.854787999124206],
            [-131.871943, 55.948877999125365],
            [-131.905601, 55.979060999125736],
            [-131.829365, 56.056464999126689],
            [-131.664222, 56.067501999126833],
            [-131.653403, 56.103850999127289],
            [-131.527013, 56.053160999126639],
            [-131.498849, 56.019096999126226],
            [-131.364317, 56.01794299912622],
            [-131.369226, 56.069909999126871],
            [-131.439957, 56.131082999127621],
            [-131.253358, 56.206849999128579],
            [-131.240485, 56.172613999128146],
            [-131.110314, 56.194068999128426],
            [-130.99843, 56.274564999129431],
            [-131.013177, 56.314210999129948],
            [-131.09623, 56.335165999130211],
            [-131.086794696281999, 56.407094772354725],
            [-131.085704, 56.406539999131112],
            [-130.810707, 56.371062999130658],
            [-130.740619, 56.34295299913029],
            [-130.622482, 56.267938999129342],
            [-130.466874, 56.239788999128983],
            [-130.425575, 56.140675999127751],
            [-130.343716, 56.12716199912758],
            [-130.246577284174009, 56.097195987601907],
            [-130.289296, 56.047320999126583],
            [-130.22006, 55.99913699912598],
            [-130.288619, 55.99812499912597],
            [-130.388606, 55.943178999125294],
            [-130.288673, 55.887005999124597],
            [-130.237973, 55.816644999123739],
            [-130.138222339589021, 55.763027564929402],
            [-130.150061, 55.727098999122646],
            [-130.111677, 55.682050999122112],
            [-130.120132, 55.563918999120695],
            [-130.085413, 55.491516999119831],
            [-130.039928, 55.429421999119107],
            [-130.023558, 55.33825899911804],
            [-129.982348, 55.302078999117612],
            [-130.001735, 55.264556999117183],
            [-130.104749, 55.188974999116311],
            [-130.169294, 55.105423999115359],
            [-130.221512, 55.025989999114451],
            [-130.339504, 54.921375999113259],
            [-130.529228, 54.810899999112046],
            [-130.636745, 54.778455999111671],
            [-130.62807, 54.739340999111242],
            [-130.686192, 54.716909999110996],
            [-130.737423, 54.753544999111398],
            [-130.792122, 54.784783999111745],
            [-130.844145, 54.765800999111541],
            [-130.866866, 54.76906799911157],
            [-130.932454, 54.806937999111987],
            [-130.947338, 54.88673299911288],
            [-130.9604, 54.93368499911341],
            [-130.97503, 54.974852999113864],
            [-131.012061, 54.996237999114115],
            [-130.997057, 55.044255999114654],
            [-131.013215, 55.090068999115182],
            [-131.052298, 55.118159999115498],
            [-131.087497, 55.163035999116012],
            [-131.093806, 55.191334999116336],
            [-131.160492, 55.197480999116408],
            [-131.190628, 55.108012999115381],
            [-131.190033, 55.043172999114638],
            [-131.246018, 54.989554999114034],
            [-131.245988, 54.940490999113479],
            [-131.195197, 54.91976699911325],
            [-131.253671, 54.866778999112661],
            [-131.327624, 54.859121999112574],
            [-131.433473, 54.896534999113008],
            [-131.491504, 54.930391999113375],
            [-131.594567, 54.931129999113374],
            [-131.621948, 54.946530999113563],
            [-131.61188974327203, 54.982270633997061],
            [-131.51066, 55.016365999114335],
            [-131.336983, 54.995533999114116],
            [-131.239554, 55.169682999116091],
            [-131.569223, 55.294690999117535],
            [-131.648375, 55.227950999116764],
            [-131.684131985649998, 55.119093613191119],
            [-131.748334, 55.128587999115616],
            [-131.828395, 55.198481999116417],
            [-131.862162, 55.289283999117465],
            [-131.854297, 55.42107399911901],
            [-131.844157, 55.45674199911943],
            [-131.971792, 55.498278999119918],
            [-132.114654, 55.550622999120534],
            [-132.183207, 55.588127999120978],
            [-132.199681399056999, 55.633833999121542]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "143",
        COUNTYNS: "00450386",
        AFFGEOID: "0500000US18143",
        GEOID: "18143",
        NAME: "Scott",
        LSAD: "06",
        ALAND: 493126036,
        AWATER: 6088999
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.888334, 38.734442999078901],
            [-85.869559, 38.726170999078953],
            [-85.804554, 38.779905999078579],
            [-85.795357, 38.807507999078382],
            [-85.733191, 38.831811999078212],
            [-85.683839, 38.815405999078322],
            [-85.683338, 38.736730999078887],
            [-85.57093, 38.678705999079298],
            [-85.56998, 38.606154999079813],
            [-85.719718, 38.605539999079824],
            [-85.793584, 38.604813999079816],
            [-85.847893, 38.561268999080141],
            [-85.860996, 38.575906999080047],
            [-85.885643, 38.575411999080032],
            [-85.888334, 38.734442999078901]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "049",
        COUNTYNS: "00558592",
        AFFGEOID: "0500000US22049",
        GEOID: "22049",
        NAME: "Jackson",
        LSAD: "15",
        ALAND: 1474195902,
        AWATER: 28714912
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.773173, 32.235983999148239],
            [-92.777197, 32.453454999145173],
            [-92.621678, 32.452998999145187],
            [-92.622335, 32.496405999144585],
            [-92.41535, 32.495485999144599],
            [-92.415418, 32.407811999145814],
            [-92.311829, 32.320754999147042],
            [-92.311851, 32.277440999147643],
            [-92.312218, 32.146122999149505],
            [-92.814737, 32.1469069991495],
            [-92.773173, 32.235983999148239]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "019",
        COUNTYNS: "00835831",
        AFFGEOID: "0500000US31019",
        GEOID: "31019",
        NAME: "Buffalo",
        LSAD: "06",
        ALAND: 2507344546,
        AWATER: 18927907
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.425986, 41.046470999065512],
            [-99.207564, 41.047002999065512],
            [-98.748526, 41.046639999065512],
            [-98.721975, 41.046673999065511],
            [-98.72143, 40.698902999067137],
            [-98.724387, 40.689771999067183],
            [-98.906096, 40.65272199906736],
            [-99.179248, 40.660540999067322],
            [-99.380544, 40.664859999067311],
            [-99.417316, 40.670824999067264],
            [-99.426353, 40.699771999067131],
            [-99.425986, 41.046470999065512]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "44",
        COUNTYFP: "007",
        COUNTYNS: "01219781",
        AFFGEOID: "0500000US44007",
        GEOID: "44007",
        NAME: "Providence",
        LSAD: "06",
        ALAND: 1060791694,
        AWATER: 67639362
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.799242, 42.008064999061745],
            [-71.606203441134099, 42.013117909300526],
            [-71.591096527631009, 42.013513342618907],
            [-71.559439, 42.01434199906172],
            [-71.498224013124698, 42.015874110080205],
            [-71.458080994556099, 42.0168788241904],
            [-71.381401, 42.018797999061704],
            [-71.381431346893095, 41.985084118191317],
            [-71.381461, 41.952140999061925],
            [-71.3817, 41.893198999062129],
            [-71.339298, 41.893398999062136],
            [-71.339597, 41.831999999062354],
            [-71.329396, 41.782599999062541],
            [-71.317402007931207, 41.777256108031253],
            [-71.365207, 41.735648999062704],
            [-71.378273, 41.755296999062637],
            [-71.399378, 41.768263999062583],
            [-71.456412, 41.732388999062714],
            [-71.789695431682389, 41.725198243871844],
            [-71.792767, 41.807000999062446],
            [-71.799242, 42.008064999061745]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "029",
        COUNTYNS: "01560574",
        AFFGEOID: "0500000US54029",
        GEOID: "54029",
        NAME: "Hancock",
        LSAD: "06",
        ALAND: 213957146,
        AWATER: 13879326
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.627171, 40.619935999067529],
            [-80.583633, 40.615519999067558],
            [-80.518991, 40.638800999067449],
            [-80.519017117347389, 40.477363145305347],
            [-80.519029691194092, 40.399641055409354],
            [-80.573784, 40.399754999068648],
            [-80.627362629936897, 40.395170932085882],
            [-80.604895, 40.446666999068405],
            [-80.622195, 40.520496999068037],
            [-80.667957, 40.58249599906771],
            [-80.627171, 40.619935999067529]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "059",
        COUNTYNS: "01529220",
        AFFGEOID: "0500000US53059",
        GEOID: "53059",
        NAME: "Skamania",
        LSAD: "06",
        ALAND: 4294875666,
        AWATER: 73344946
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.245856, 46.053852999057455],
            [-122.240966, 46.385360999057937],
            [-121.522324, 46.388223999057949],
            [-121.522321, 46.04400599905744],
            [-121.612232, 46.044092999057447],
            [-121.609246, 45.78251899905716],
            [-121.524006104220021, 45.723836224256296],
            [-121.533106, 45.7265409990571],
            [-121.668362, 45.705081999057086],
            [-121.735104, 45.694038999057078],
            [-121.811304, 45.706760999057089],
            [-121.867167, 45.693276999057076],
            [-121.900858, 45.662008999057058],
            [-121.923749361985017, 45.654349508026442],
            [-121.951838, 45.644950999057038],
            [-122.00369, 45.615929999057009],
            [-122.101675, 45.583515999056992],
            [-122.183695, 45.577695999056978],
            [-122.249197008051993, 45.549998931798164],
            [-122.245856, 46.053852999057455]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "013",
        COUNTYNS: "01155128",
        AFFGEOID: "0500000US41013",
        GEOID: "41013",
        NAME: "Crook",
        LSAD: "06",
        ALAND: 7715298889,
        AWATER: 21091929
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.107534, 44.390599999056938],
            [-120.988375, 44.390264999056946],
            [-120.988824, 44.476483999056889],
            [-120.826899, 44.476498999056894],
            [-120.827555, 44.562792999056846],
            [-120.386561, 44.564008999056838],
            [-120.386074, 44.436082999056921],
            [-120.021621, 44.438792999056908],
            [-120.020708, 44.389686999056941],
            [-119.899283, 44.389574999056947],
            [-119.899002, 44.306619999056991],
            [-119.655517, 44.307044999056998],
            [-119.657183, 43.958919999057329],
            [-119.775402, 43.959085999057322],
            [-119.777528, 43.697956999057659],
            [-119.897434, 43.698199999057671],
            [-120.257777, 43.698761999057673],
            [-120.258386, 43.785296999057543],
            [-120.378107, 43.785428999057544],
            [-120.37874, 43.872361999057432],
            [-120.747848, 43.871330999057434],
            [-120.748249, 43.95730999905733],
            [-120.986587, 43.960851999057319],
            [-120.987271, 44.134025999057137],
            [-121.102773, 44.138124999057148],
            [-121.107534, 44.390599999056938]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "031",
        COUNTYNS: "00659461",
        AFFGEOID: "0500000US27031",
        GEOID: "27031",
        NAME: "Cook",
        LSAD: "06",
        ALAND: 3761621396,
        AWATER: 4887941604
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.032539461265102, 48.190578820961477],
            [-90.906829, 48.237338999062963],
            [-90.843624, 48.243575999062976],
            [-90.804207, 48.177832999062737],
            [-90.775962, 48.122228999062528],
            [-90.703702, 48.096008999062434],
            [-90.566113, 48.122619999062536],
            [-90.471019, 48.106075999062469],
            [-90.31723, 48.10379299906247],
            [-90.136191, 48.112135999062495],
            [-90.029626, 48.087587999062407],
            [-89.973433, 48.020349999062169],
            [-89.868153, 47.989897999062059],
            [-89.749314, 48.023324999062169],
            [-89.625087, 48.011516999062138],
            [-89.489226, 48.014527999062153],
            [-89.555015, 47.974848999062004],
            [-89.660616, 47.951215999061922],
            [-89.737539, 47.918182999061813],
            [-89.793539, 47.891357999061725],
            [-89.923649, 47.862061999061623],
            [-89.974296, 47.830513999061516],
            [-90.072025, 47.811104999061456],
            [-90.187636, 47.778129999061349],
            [-90.323446, 47.753770999061267],
            [-90.42139, 47.735149999061221],
            [-90.537105, 47.703054999061102],
            [-90.647837, 47.656175999060956],
            [-90.735927, 47.624342999060858],
            [-90.86827, 47.556899999060654],
            [-91.023124, 47.46496399906038],
            [-91.023124717702089, 47.464963515121191],
            [-91.020737, 47.887994999061718],
            [-91.032539461265102, 48.190578820961477]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "041",
        COUNTYNS: "00758475",
        AFFGEOID: "0500000US29041",
        GEOID: "29041",
        NAME: "Chariton",
        LSAD: "06",
        ALAND: 1945540984,
        AWATER: 40353306
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.278098, 39.615631999073081],
            [-93.261386, 39.623252999073031],
            [-93.266968, 39.703561999072548],
            [-93.082306, 39.706713999072534],
            [-92.85792, 39.699984999072576],
            [-92.689331, 39.69809399907259],
            [-92.692149, 39.610264999073117],
            [-92.697228, 39.597698999073195],
            [-92.707367, 39.321613999074934],
            [-92.790369, 39.343585999074776],
            [-92.849224, 39.226217999075537],
            [-92.911669, 39.223945999075561],
            [-92.959801, 39.312525999074978],
            [-93.072915, 39.33551999907484],
            [-93.104485, 39.383655999074527],
            [-93.11882, 39.417101999074319],
            [-93.275848, 39.458301999074052],
            [-93.278098, 39.615631999073081]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "111",
        COUNTYNS: "01492239",
        AFFGEOID: "0500000US51111",
        GEOID: "51111",
        NAME: "Lunenburg",
        LSAD: "06",
        ALAND: 1118051114,
        AWATER: 1795027
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.443644, 37.07937099909217],
            [-78.275167, 37.103525999091957],
            [-78.239354, 37.120088999091813],
            [-78.082023, 37.011120999092782],
            [-78.003639, 37.022755999092681],
            [-78.027411, 36.777857999094913],
            [-78.421187, 36.851651999094237],
            [-78.493028, 36.89121999909387],
            [-78.443644, 37.07937099909217]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "047",
        COUNTYNS: "01213668",
        AFFGEOID: "0500000US42047",
        GEOID: "42047",
        NAME: "Elk",
        LSAD: "06",
        ALAND: 2142856032,
        AWATER: 12818644
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.06352, 41.453206999063788],
            [-78.958957, 41.43706099906386],
            [-78.956042, 41.623401999063127],
            [-78.956056, 41.623862999063128],
            [-78.483409, 41.629897999063097],
            [-78.419118, 41.602187999063204],
            [-78.420028, 41.405045999063979],
            [-78.253959, 41.404720999063983],
            [-78.236817, 41.230474999064718],
            [-78.646666, 41.253777999064617],
            [-78.710248, 41.201858999064832],
            [-78.73528, 41.262914999064584],
            [-78.971904, 41.376564999064108],
            [-79.095096, 41.340657999064248],
            [-79.06352, 41.453206999063788]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "131",
        COUNTYNS: "00758520",
        AFFGEOID: "0500000US29131",
        GEOID: "29131",
        NAME: "Miller",
        LSAD: "06",
        ALAND: 1534813236,
        AWATER: 19159074
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.689834, 38.348471999081724],
            [-92.633756, 38.348081999081728],
            [-92.625189, 38.430777999081101],
            [-92.495185, 38.427828999081122],
            [-92.403484, 38.42533099908114],
            [-92.408407, 38.341343999081772],
            [-92.194476, 38.33553599908182],
            [-92.195675, 38.292236999082149],
            [-92.181372, 38.161237999083141],
            [-92.18537, 38.016337999084278],
            [-92.406275, 38.021178999084249],
            [-92.515979, 38.022335999084227],
            [-92.570281, 38.066634999083881],
            [-92.604684, 38.170732999083079],
            [-92.695886, 38.222030999082683],
            [-92.689834, 38.348471999081724]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "075",
        COUNTYNS: "01719584",
        AFFGEOID: "0500000US30075",
        GEOID: "30075",
        NAME: "Powder River",
        LSAD: "06",
        ALAND: 8542288902,
        AWATER: 1565958
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.279549, 45.351796999056859],
            [-106.234502, 45.788577999057168],
            [-106.191734, 45.788599999057169],
            [-104.98194, 45.786981999057154],
            [-104.987318, 45.352039999056849],
            [-105.038205, 45.352034999056855],
            [-105.038252188273987, 45.000291672048562],
            [-105.076600952253997, 45.000296612464162],
            [-105.848065, 45.000395999056757],
            [-106.024880029032005, 44.997584122612857],
            [-106.263586, 44.993787999056757],
            [-106.263715092464992, 44.993788432058267],
            [-106.263684, 45.173352999056796],
            [-106.279709, 45.179727999056787],
            [-106.279549, 45.351796999056859]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "057",
        COUNTYNS: "00161554",
        AFFGEOID: "0500000US01057",
        GEOID: "01057",
        NAME: "Fayette",
        LSAD: "06",
        ALAND: 1625693280,
        AWATER: 4330881
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.951785, 33.919929999125884],
            [-87.739347, 33.914730999125958],
            [-87.635932, 33.915250999125931],
            [-87.63604, 33.871998999126475],
            [-87.531602, 33.86761799912653],
            [-87.528338, 33.69204899912873],
            [-87.423843, 33.689111999128777],
            [-87.423701, 33.602095999129872],
            [-87.631718, 33.609832999129779],
            [-87.666661, 33.521666999130908],
            [-87.840683, 33.524838999130857],
            [-87.946519, 33.524064999130871],
            [-87.951785, 33.919929999125884]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "029",
        COUNTYNS: "01135853",
        AFFGEOID: "0500000US41029",
        GEOID: "41029",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 7208499985,
        AWATER: 46860048
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.229901, 42.290815999060825],
            [-123.229545, 42.495622999060231],
            [-123.229619, 42.702609999059675],
            [-123.152233, 42.755834999059537],
            [-123.009765, 42.752143999059548],
            [-122.951199, 42.774772999059479],
            [-122.794776, 42.778220999059464],
            [-122.677181, 42.881079999059224],
            [-122.560299, 42.924689999059126],
            [-122.460224, 42.939872999059091],
            [-122.401025, 42.996631999058955],
            [-122.28273, 42.996498999058957],
            [-122.284689, 42.474499999060285],
            [-122.28776, 42.44028699906039],
            [-122.289749096156996, 42.007764729558332],
            [-122.501135, 42.008459999061735],
            [-122.80008, 42.004070999061746],
            [-123.045254, 42.003048999061754],
            [-123.145959, 42.00924699906173],
            [-123.231001260578012, 42.004970061317756],
            [-123.229901, 42.290815999060825]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "049",
        COUNTYNS: "00357747",
        AFFGEOID: "0500000US13049",
        GEOID: "13049",
        NAME: "Charlton",
        LSAD: "06",
        ALAND: 2003702672,
        AWATER: 22676200
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.415603, 31.013589999166307],
            [-82.131698, 31.010713999166349],
            [-82.081207, 31.010629999166358],
            [-82.06087, 31.07580899916535],
            [-81.936006, 31.047992999165775],
            [-81.906036, 31.039714999165906],
            [-81.905978439406383, 30.821407340671279],
            [-81.943187, 30.827436999169194],
            [-81.994992, 30.78607399916984],
            [-82.032667, 30.750673999170402],
            [-82.041834, 30.692373999171316],
            [-82.049529, 30.655543999171893],
            [-82.015728, 30.601697999172746],
            [-82.018381, 30.531175999173854],
            [-82.028232, 30.447384999175195],
            [-82.042011, 30.403252999175901],
            [-82.040766, 30.370143999176435],
            [-82.050982981680889, 30.36836777512136],
            [-82.094709, 30.360765999176579],
            [-82.143306, 30.363377999176546],
            [-82.180043, 30.368609999176456],
            [-82.210318, 30.424576999175557],
            [-82.200965, 30.474426999174764],
            [-82.229427, 30.520813999174031],
            [-82.218607, 30.564394999173334],
            [-82.149872, 30.784335999169869],
            [-82.420442, 30.795228999169698],
            [-82.415603, 31.013589999166307]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "023",
        COUNTYNS: "00401703",
        AFFGEOID: "0500000US16023",
        GEOID: "16023",
        NAME: "Butte",
        LSAD: "06",
        ALAND: 5792509562,
        AWATER: 5260331
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.759998, 43.622680999057785],
            [-113.528063, 43.719090999057634],
            [-113.41444, 43.816925999057503],
            [-113.36399, 43.794648999057536],
            [-113.453985, 43.873907999057423],
            [-113.457816, 44.059527999057224],
            [-113.416525, 44.233363999057062],
            [-113.320609, 44.233163999057048],
            [-112.995091, 44.234125999057049],
            [-112.994323, 43.972361999057327],
            [-112.695242, 43.971940999057324],
            [-112.697122, 43.623139999057763],
            [-112.698891, 43.540051999057908],
            [-112.816278, 43.535948999057908],
            [-112.816704, 43.449163999058051],
            [-112.936835, 43.449385999058066],
            [-112.947016, 43.284820999058361],
            [-113.007318, 43.284777999058356],
            [-113.361958, 43.284677999058367],
            [-113.36191, 43.36715999905821],
            [-113.63523, 43.367545999058208],
            [-113.634325, 43.497473999057981],
            [-113.701153, 43.584552999057834],
            [-113.798441, 43.569216999057858],
            [-113.759998, 43.622680999057785]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "107",
        COUNTYNS: "01101841",
        AFFGEOID: "0500000US40107",
        GEOID: "40107",
        NAME: "Okfuskee",
        LSAD: "06",
        ALAND: 1602086606,
        AWATER: 26680140
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.624865, 35.462705999108067],
            [-96.620648, 35.639004999106191],
            [-96.192563, 35.63908699910619],
            [-96.192844, 35.551901999107102],
            [-96.087795, 35.552118999107108],
            [-96.08753, 35.376876999108987],
            [-95.981307, 35.37682799910899],
            [-95.981376, 35.28989899910993],
            [-96.441368, 35.290121999109921],
            [-96.44129, 35.467711999108005],
            [-96.536718, 35.390771999108843],
            [-96.623699, 35.400721999108733],
            [-96.624865, 35.462705999108067]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "121",
        COUNTYNS: "00695781",
        AFFGEOID: "0500000US28121",
        GEOID: "28121",
        NAME: "Rankin",
        LSAD: "06",
        ALAND: 2008322209,
        AWATER: 79429951
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.254799, 32.151187999149435],
            [-90.194855, 32.187854999148911],
            [-90.179701, 32.276161999147668],
            [-90.093681, 32.390243999146058],
            [-90.065697, 32.399528999145929],
            [-90.075349, 32.419085999145658],
            [-89.863104, 32.575771999143491],
            [-89.839985, 32.593294999143239],
            [-89.784123, 32.586890999143343],
            [-89.730063, 32.352732999146596],
            [-89.730424, 32.222038999148424],
            [-89.730066, 32.04707199915093],
            [-90.230331, 32.049727999150882],
            [-90.254799, 32.151187999149435]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "073",
        COUNTYNS: "00066864",
        AFFGEOID: "0500000US05073",
        GEOID: "05073",
        NAME: "Lafayette",
        LSAD: "06",
        ALAND: 1368278178,
        AWATER: 43469442
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.850076, 33.145342999135799],
            [-93.750177, 33.183948999135282],
            [-93.702531, 33.245833999134469],
            [-93.699199, 33.312835999133604],
            [-93.665518, 33.327020999133417],
            [-93.733317, 33.42087999913219],
            [-93.722867, 33.481962999131405],
            [-93.483097, 33.476074999131491],
            [-93.483974, 33.44722299913186],
            [-93.365585, 33.444458999131896],
            [-93.442019, 33.258164999134316],
            [-93.48176, 33.259990999134288],
            [-93.490512400120394, 33.018634594803771],
            [-93.520993553771589, 33.018742292725477],
            [-93.723273, 33.019456999137461],
            [-93.804907774630379, 33.019396224588363],
            [-93.860409, 33.092601999136491],
            [-93.850076, 33.145342999135799]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "111",
        COUNTYNS: "01101843",
        AFFGEOID: "0500000US40111",
        GEOID: "40111",
        NAME: "Okmulgee",
        LSAD: "06",
        ALAND: 1806123424,
        AWATER: 12915763
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.192563, 35.63908699910619],
            [-96.192382, 35.857047999103926],
            [-96.141561, 35.857261999103933],
            [-96.033118, 35.856823999103923],
            [-95.819964, 35.855899999103926],
            [-95.766166, 35.856402999103935],
            [-95.766265, 35.72574999910529],
            [-95.713081, 35.725806999105281],
            [-95.712957, 35.551801999107113],
            [-95.822361, 35.551692999107125],
            [-95.823396, 35.463968999108054],
            [-95.875572, 35.463624999108056],
            [-95.875626, 35.376689999108997],
            [-95.981307, 35.37682799910899],
            [-96.08753, 35.376876999108987],
            [-96.087795, 35.552118999107108],
            [-96.192844, 35.551901999107102],
            [-96.192563, 35.63908699910619]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "033",
        COUNTYNS: "01716058",
        AFFGEOID: "0500000US30033",
        GEOID: "30033",
        NAME: "Garfield",
        LSAD: "06",
        ALAND: 12112233301,
        AWATER: 446864348
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.963887, 47.354011999060077],
            [-107.909603, 47.401579999060203],
            [-107.90912, 47.450691999060354],
            [-107.864922, 47.513739999060533],
            [-107.761921, 47.548049999060623],
            [-107.694687, 47.629761999060875],
            [-107.610852, 47.647368999060937],
            [-107.440064, 47.62546199906086],
            [-107.414886, 47.691733999061057],
            [-107.249, 47.661565999060976],
            [-107.011358, 47.663110999060983],
            [-106.892753, 47.67481799906102],
            [-106.861668, 47.73737199906121],
            [-106.734453, 47.734155999061208],
            [-106.711969, 47.772112999061328],
            [-106.603126, 47.784742999061365],
            [-106.510321, 47.878995999061679],
            [-106.501725, 47.958286999061947],
            [-106.41862, 47.957456999061939],
            [-106.358288, 47.957215999061937],
            [-106.358085, 47.868411999061657],
            [-106.260932, 47.868430999061644],
            [-106.298073, 47.52904099906057],
            [-106.298158, 47.354297999060073],
            [-106.170675, 47.354494999060087],
            [-106.17042, 47.180825999059614],
            [-106.086777, 47.180937999059609],
            [-106.086585, 46.860289999058857],
            [-106.086463, 46.845880999058835],
            [-106.720157, 46.859602999058858],
            [-107.46777, 46.851159999058837],
            [-107.892233, 46.85121499905884],
            [-107.939276, 46.876240999058886],
            [-107.883113, 46.998340999059167],
            [-107.92164, 47.02400499905923],
            [-107.968626, 47.249822999059795],
            [-107.963887, 47.354011999060077]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "083",
        COUNTYNS: "00695765",
        AFFGEOID: "0500000US28083",
        GEOID: "28083",
        NAME: "Leflore",
        LSAD: "06",
        ALAND: 1536793536,
        AWATER: 33720363
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.451643, 33.811389999127236],
            [-90.182584, 33.809321999127263],
            [-90.185599, 33.765513999127798],
            [-90.294502, 33.750980999127989],
            [-90.299057, 33.697588999128669],
            [-90.239603, 33.737245999128163],
            [-90.136787, 33.721647999128358],
            [-90.127775, 33.676955999128921],
            [-90.102971, 33.660729999129131],
            [-90.101401, 33.458939999131708],
            [-90.174756, 33.417002999132251],
            [-90.174614, 33.333377999133319],
            [-90.321513, 33.37512099913279],
            [-90.279627, 33.339508999133251],
            [-90.331589, 33.302824999133733],
            [-90.413236, 33.23531999913461],
            [-90.453233, 33.32948399913338],
            [-90.451643, 33.811389999127236]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "109",
        COUNTYNS: "00659500",
        AFFGEOID: "0500000US27109",
        GEOID: "27109",
        NAME: "Olmsted",
        LSAD: "06",
        ALAND: 1692581425,
        AWATER: 3505507
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.689391, 43.848569999057453],
            [-92.678313, 43.861772999057443],
            [-92.678717, 44.195515999057079],
            [-92.550871, 44.194915999057102],
            [-92.318986, 44.194056999057089],
            [-92.318971, 44.107733999057167],
            [-92.079491, 44.106987999057175],
            [-92.079292, 43.847079999057463],
            [-92.438491, 43.848240999057452],
            [-92.448965, 43.83410499905748],
            [-92.67884, 43.833695999057483],
            [-92.689391, 43.848569999057453]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "425",
        COUNTYNS: "01383998",
        AFFGEOID: "0500000US48425",
        GEOID: "48425",
        NAME: "Somervell",
        LSAD: "06",
        ALAND: 482938558,
        AWATER: 14221312
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.945625, 32.233467999148274],
            [-97.78276, 32.316492999147094],
            [-97.615286, 32.318617999147065],
            [-97.615055, 32.203580999148691],
            [-97.642178, 32.201295999148719],
            [-97.864864, 32.087327999150354],
            [-97.945625, 32.233467999148274]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "049",
        COUNTYNS: "00793926",
        AFFGEOID: "0500000US30049",
        GEOID: "30049",
        NAME: "Lewis and Clark",
        LSAD: "06",
        ALAND: 8957160027,
        AWATER: 101500820
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.165811, 47.719763999061172],
            [-113.080997, 47.749916999061263],
            [-113.086634, 47.918181999061801],
            [-112.984734, 47.953917999061922],
            [-112.913256, 47.826215999061503],
            [-112.91422, 47.694957999061089],
            [-112.857082, 47.629826999060882],
            [-112.780545, 47.598621999060782],
            [-112.549721, 47.651976999060949],
            [-112.429183, 47.603385999060798],
            [-112.405146, 47.55231699906065],
            [-112.317471, 47.542069999060608],
            [-112.278, 47.505622999060499],
            [-112.047781, 47.516033999060532],
            [-112.044311, 47.192825999059636],
            [-111.977739, 47.184102999059625],
            [-111.942139, 47.143581999059514],
            [-111.789278, 47.128921999059479],
            [-111.790236, 46.913763999058972],
            [-111.658132, 46.913422999058973],
            [-111.639502, 46.832133999058783],
            [-111.498095, 46.762501999058642],
            [-111.602294, 46.778260999058674],
            [-111.639261, 46.738179999058595],
            [-111.631906, 46.569971999058268],
            [-111.787949, 46.569464999058269],
            [-112.029231, 46.568804999058258],
            [-112.083441, 46.516662999058177],
            [-112.205757, 46.49974799905813],
            [-112.176235, 46.456341999058068],
            [-112.310573, 46.421170999058013],
            [-112.316754, 46.574417999058269],
            [-112.298531, 46.625972999058369],
            [-112.320871, 46.655926999058423],
            [-112.410638, 46.656010999058438],
            [-112.410846, 46.699504999058512],
            [-112.535597, 46.69948199905852],
            [-112.535748, 46.834233999058803],
            [-112.795642, 46.831664999058795],
            [-112.795808, 47.1770039990596],
            [-113.059053, 47.179569999059623],
            [-113.059199, 47.488598999060464],
            [-113.123098, 47.515729999060532],
            [-113.144368, 47.595506999060781],
            [-113.165811, 47.719763999061172]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "301",
        COUNTYNS: "00347777",
        AFFGEOID: "0500000US13301",
        GEOID: "13301",
        NAME: "Warren",
        LSAD: "06",
        ALAND: 736316130,
        AWATER: 6201954
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.866605, 33.466598999131605],
            [-82.815315, 33.521136999130903],
            [-82.751275, 33.510142999131048],
            [-82.679975, 33.599791999129899],
            [-82.649705, 33.608767999129796],
            [-82.548074, 33.352797999133074],
            [-82.383829, 33.312105999133614],
            [-82.431957, 33.274829999134099],
            [-82.559753, 33.327269999133406],
            [-82.755846, 33.253439999134386],
            [-82.824105, 33.427626999132109],
            [-82.851954, 33.443542999131914],
            [-82.866605, 33.466598999131605]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "155",
        COUNTYNS: "00835899",
        AFFGEOID: "0500000US31155",
        GEOID: "31155",
        NAME: "Saunders",
        LSAD: "06",
        ALAND: 1943097562,
        AWATER: 24827641
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.905862, 41.45338899906379],
            [-96.568677, 41.432985999063867],
            [-96.47072, 41.393238999064039],
            [-96.421439, 41.364164999064151],
            [-96.327399, 41.189998999064876],
            [-96.339805, 41.082501999065357],
            [-96.319191, 41.044997999065529],
            [-96.349471, 41.015210999065651],
            [-96.463861, 41.016068999065652],
            [-96.463869, 41.045082999065535],
            [-96.908507, 41.04609099906552],
            [-96.905862, 41.45338899906379]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "145",
        COUNTYNS: "00659517",
        AFFGEOID: "0500000US27145",
        GEOID: "27145",
        NAME: "Stearns",
        LSAD: "06",
        ALAND: 3477880457,
        AWATER: 122121594
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.139731, 45.773415999057143],
            [-94.64399, 45.773949999057159],
            [-94.5133, 45.774379999057167],
            [-94.271776, 45.775207999057159],
            [-94.182651, 45.679579999057076],
            [-94.203666, 45.619555999057013],
            [-94.149467, 45.559065999056976],
            [-94.141989, 45.493242999056932],
            [-94.047327, 45.422860999056894],
            [-94.09272, 45.342466999056846],
            [-94.260821, 45.283940999056824],
            [-94.383449, 45.282796999056828],
            [-94.383744, 45.326726999056838],
            [-94.640096, 45.325600999056839],
            [-94.76308, 45.326099999056858],
            [-94.762934, 45.412860999056889],
            [-95.13173, 45.41240199905689],
            [-95.13967, 45.758890999057137],
            [-95.139731, 45.773415999057143]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "041",
        COUNTYNS: "00558557",
        AFFGEOID: "0500000US22041",
        GEOID: "22041",
        NAME: "Franklin",
        LSAD: "15",
        ALAND: 1617667694,
        AWATER: 28107882
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.933229, 32.066881999150645],
            [-91.868202, 32.09155499915029],
            [-91.894729, 32.151549999149438],
            [-91.775019, 32.24333299914813],
            [-91.764379, 32.330017999146897],
            [-91.634883, 32.405252999145866],
            [-91.476739, 32.405807999145843],
            [-91.472391, 32.316528999147081],
            [-91.557199, 32.249653999148038],
            [-91.492718, 32.203348999148695],
            [-91.489761, 32.092421999150275],
            [-91.528204, 31.959342999152192],
            [-91.511374, 31.930024999152604],
            [-91.575721, 31.882559999153301],
            [-91.596922, 31.969757999152044],
            [-91.6475, 31.970427999152026],
            [-91.729158, 31.882509999153307],
            [-91.804869, 31.89319299915315],
            [-91.889687, 31.97147399915201],
            [-91.933229, 32.066881999150645]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "045",
        COUNTYNS: "00974121",
        AFFGEOID: "0500000US36045",
        GEOID: "36045",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 3285895343,
        AWATER: 1524639909
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.412138, 43.925675999057354],
            [-76.279314, 43.972461999057323],
            [-76.307674, 44.025276999057255],
            [-76.37556, 44.03153599905724],
            [-76.361836, 44.072720999057204],
            [-76.370706, 44.100498999057173],
            [-76.355679, 44.13325799905715],
            [-76.334584, 44.164944999057113],
            [-76.286547, 44.20377299905708],
            [-76.206777, 44.214542999057066],
            [-76.164265, 44.239602999057055],
            [-76.161833, 44.280776999057032],
            [-76.097351, 44.299546999057007],
            [-76.000998, 44.347533999056971],
            [-75.94954, 44.349128999056973],
            [-75.861271192834707, 44.405192012560043],
            [-75.446124, 44.217654999057075],
            [-75.545886, 44.102977999057181],
            [-75.484528, 44.074171999057214],
            [-75.542898, 43.967794999057304],
            [-75.60367, 43.97136299905732],
            [-75.758157, 43.87878499905743],
            [-75.84056, 43.883975999057419],
            [-75.850534, 43.791885999057534],
            [-75.786759, 43.788319999057542],
            [-75.774553, 43.68888399905768],
            [-76.025087, 43.707017999057655],
            [-76.022003, 43.668142999057714],
            [-76.201482547385694, 43.680285959281377],
            [-76.213205, 43.753512999057584],
            [-76.229268, 43.804134999057517],
            [-76.296758, 43.857078999057457],
            [-76.361037, 43.872584999057437],
            [-76.441848, 43.88286399905742],
            [-76.412138, 43.925675999057354]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "115",
        COUNTYNS: "00069901",
        AFFGEOID: "0500000US05115",
        GEOID: "05115",
        NAME: "Pope",
        LSAD: "06",
        ALAND: 2104418088,
        AWATER: 47279033
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.29591, 35.469914999107992],
            [-93.223633, 35.468592999108012],
            [-93.22117, 35.59893799910661],
            [-93.1678, 35.597694999106622],
            [-93.164452, 35.728830999105249],
            [-92.951315, 35.724662999105298],
            [-92.809763, 35.722400999105318],
            [-92.813417, 35.548999999107139],
            [-92.850624, 35.462949999108062],
            [-92.855, 35.172776999111214],
            [-92.896109, 35.170677999111234],
            [-93.000171, 35.168445999111249],
            [-93.065176, 35.118183999111821],
            [-93.156078, 35.235306999110534],
            [-93.278293, 35.317401999109642],
            [-93.299014, 35.327424999109532],
            [-93.29591, 35.469914999107992]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "035",
        COUNTYNS: "00558534",
        AFFGEOID: "0500000US22035",
        GEOID: "22035",
        NAME: "East Carroll",
        LSAD: "15",
        ALAND: 1089619193,
        AWATER: 56273972
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.445299, 32.581955999143396],
            [-91.396964, 32.609467999143021],
            [-91.398255, 32.692979999141876],
            [-91.344845, 32.75665899914101],
            [-91.30938, 32.935236999138596],
            [-91.2645640502205, 33.004738823408061],
            [-91.166073, 33.004105999137671],
            [-91.134414, 32.980532999137985],
            [-91.072075, 32.937831999138552],
            [-91.070602, 32.88865899913921],
            [-91.13789, 32.848974999139749],
            [-91.161669, 32.812464999140246],
            [-91.157614, 32.776032999140739],
            [-91.113652, 32.739969999141238],
            [-91.056999, 32.725579999141438],
            [-91.098762, 32.685290999141984],
            [-91.079506, 32.600679999143139],
            [-91.055293104949897, 32.578983500220545],
            [-91.133713, 32.537911999144001],
            [-91.457643, 32.53780699914401],
            [-91.445299, 32.581955999143396]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "215",
        COUNTYNS: "01383893",
        AFFGEOID: "0500000US48215",
        GEOID: "48215",
        NAME: "Hidalgo",
        LSAD: "06",
        ALAND: 4068610437,
        AWATER: 31102594
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.593300218279197, 26.242936347927664],
            [-98.491943, 26.445361999247019],
            [-98.32067, 26.783080999240369],
            [-97.985494, 26.780916999240411],
            [-97.985303, 26.615808999243644],
            [-97.957405, 26.611768999243733],
            [-98.004189, 26.448783999246956],
            [-97.86192, 26.433578999247249],
            [-97.861875, 26.348155999248956],
            [-97.861875, 26.120294999253524],
            [-97.862281563106279, 26.057746827694984],
            [-97.871187, 26.058082999254779],
            [-97.944345, 26.059620999254747],
            [-98.010971, 26.06386299925466],
            [-98.039239, 26.041274999255112],
            [-98.091038, 26.059168999254755],
            [-98.149463, 26.055812999254826],
            [-98.197046, 26.05615299925481],
            [-98.204953955592373, 26.058742335255161],
            [-98.248806, 26.073100999254468],
            [-98.302979, 26.110049999253725],
            [-98.308200117045899, 26.113032550282369],
            [-98.386694, 26.157871999252766],
            [-98.442536, 26.199150999251945],
            [-98.503492, 26.214797999251626],
            [-98.576188, 26.235220999251215],
            [-98.593300218279197, 26.242936347927664]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "005",
        COUNTYNS: "00465192",
        AFFGEOID: "0500000US19005",
        GEOID: "19005",
        NAME: "Allamakee",
        LSAD: "06",
        ALAND: 1655214176,
        AWATER: 50995532
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.610835000554374, 43.500687845837277],
            [-91.491042, 43.500689999057975],
            [-91.217706, 43.500549999057974],
            [-91.232276, 43.450951999058056],
            [-91.210663357772702, 43.41944178196141],
            [-91.199408, 43.403031999058143],
            [-91.207367, 43.3736589990582],
            [-91.154806, 43.334825999058253],
            [-91.107237, 43.313644999058305],
            [-91.05791, 43.253967999058425],
            [-91.087456, 43.221890999058488],
            [-91.134173, 43.174404999058574],
            [-91.175253, 43.134664999058657],
            [-91.174934913911173, 43.080260370903986],
            [-91.605307, 43.081652999058775],
            [-91.610835000554374, 43.500687845837277]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "015",
        COUNTYNS: "00758462",
        AFFGEOID: "0500000US29015",
        GEOID: "29015",
        NAME: "Benton",
        LSAD: "06",
        ALAND: 1823463551,
        AWATER: 125579913
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.512743, 38.512475999080507],
            [-93.291851, 38.50632099908055],
            [-93.290454, 38.535387999080321],
            [-93.067291, 38.529994999080373],
            [-93.077692, 38.263091999082377],
            [-93.059073, 38.185684999082966],
            [-93.065199, 38.062478999083915],
            [-93.503946, 38.073122999083836],
            [-93.49728, 38.199086999082859],
            [-93.523484, 38.207215999082798],
            [-93.512743, 38.512475999080507]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "045",
        COUNTYNS: "00558576",
        AFFGEOID: "0500000US22045",
        GEOID: "22045",
        NAME: "Iberia",
        LSAD: "15",
        ALAND: 1486941064,
        AWATER: 1182115493
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.042894303827396, 29.577480288161485],
            [-91.985194, 29.629189999188611],
            [-91.969648, 29.816887999185472],
            [-91.990396, 29.970325999182936],
            [-91.964458, 30.036917999181849],
            [-91.950998, 30.073212999181248],
            [-91.819216, 30.044012999181724],
            [-91.729927, 30.122483999180449],
            [-91.658886, 30.108225999180675],
            [-91.600354, 30.033168999181903],
            [-91.368835, 30.058823999181485],
            [-91.231842, 30.040384999181786],
            [-91.224356, 30.025335999182037],
            [-91.259388, 30.000199999182449],
            [-91.255229, 29.971456999182919],
            [-91.471951, 29.95598899918317],
            [-91.624709, 29.945274999183347],
            [-91.711149, 29.846064999184989],
            [-91.935286, 29.671938999187883],
            [-91.862556996522599, 29.667395462947873],
            [-91.873266, 29.627276999188645],
            [-91.80373, 29.595951999189165],
            [-91.711081, 29.569327999189621],
            [-91.768263, 29.490361999190952],
            [-91.821579, 29.473924999191233],
            [-91.915321, 29.518512999190477],
            [-92.030186, 29.572668999189567],
            [-92.042894303827396, 29.577480288161485]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "041",
        COUNTYNS: "01025822",
        AFFGEOID: "0500000US37041",
        GEOID: "37041",
        NAME: "Chowan",
        LSAD: "06",
        ALAND: 447179927,
        AWATER: 157966162
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.706584, 36.243795999100023],
            [-76.696571, 36.296137999099507],
            [-76.669975, 36.315213999099328],
            [-76.559646, 36.351055999098968],
            [-76.590107, 36.238861999100067],
            [-76.572077, 36.10946299910136],
            [-76.402957, 36.07286699910172],
            [-76.396794, 36.026442999102201],
            [-76.511899, 35.98072599910266],
            [-76.606214, 35.978050999102692],
            [-76.682535, 36.030946999102156],
            [-76.736933, 36.150582999100955],
            [-76.706584, 36.243795999100023]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "261",
        COUNTYNS: "00343504",
        AFFGEOID: "0500000US13261",
        GEOID: "13261",
        NAME: "Sumter",
        LSAD: "06",
        ALAND: 1250167780,
        AWATER: 25799665
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.433014, 32.041959999150997],
            [-84.431214, 32.134057999149675],
            [-84.430218, 32.166256999149233],
            [-84.183569, 32.158492999149331],
            [-84.181657, 32.22970299914833],
            [-84.134736, 32.185391999148955],
            [-84.027853, 32.171082999149156],
            [-84.033411, 32.15321599914941],
            [-83.961278, 32.030594999151162],
            [-83.950304, 32.015377999151383],
            [-83.922494, 31.909652999152904],
            [-84.338143, 31.916189999152817],
            [-84.338245, 31.873590999153429],
            [-84.357615, 31.885155999153259],
            [-84.443807, 31.967455999152069],
            [-84.433014, 32.041959999150997]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "075",
        COUNTYNS: "00974136",
        AFFGEOID: "0500000US36075",
        GEOID: "36075",
        NAME: "Oswego",
        LSAD: "06",
        ALAND: 2464746080,
        AWATER: 933480216
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.617213732577582, 43.420175553523713],
            [-76.515882, 43.471135999058021],
            [-76.417581, 43.521284999057926],
            [-76.370942837669006, 43.525627060588242],
            [-76.368849, 43.525821999057932],
            [-76.319701, 43.512274999057958],
            [-76.235834, 43.529255999057924],
            [-76.203473, 43.574977999057843],
            [-76.196596, 43.649760999057726],
            [-76.201482547385694, 43.680285959281377],
            [-76.022003, 43.668142999057714],
            [-76.025087, 43.707017999057655],
            [-75.774553, 43.68888399905768],
            [-75.756213, 43.470387999058033],
            [-75.814627, 43.483577999058006],
            [-75.886756, 43.325020999058282],
            [-75.884275, 43.155561999058612],
            [-75.932778, 43.176582999058574],
            [-75.993394, 43.183375999058569],
            [-76.07839, 43.199415999058523],
            [-76.21083, 43.268472999058396],
            [-76.198748, 43.2125679990585],
            [-76.258951, 43.237467999058453],
            [-76.479224, 43.227518999058475],
            [-76.605012, 43.253569999058421],
            [-76.617213732577582, 43.420175553523713]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "265",
        COUNTYNS: "01383918",
        AFFGEOID: "0500000US48265",
        GEOID: "48265",
        NAME: "Kerr",
        LSAD: "06",
        ALAND: 2857602924,
        AWATER: 10246419
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.754142, 30.290697999177702],
            [-99.301718, 30.286652999177782],
            [-99.300988, 30.134297999180255],
            [-98.920147, 30.138289999180181],
            [-98.917725, 29.781397999186062],
            [-99.176988, 29.89506299918418],
            [-99.602776, 29.907678999183968],
            [-99.691215, 29.908581999183959],
            [-99.689879, 30.073947999181236],
            [-99.757621, 30.074131999181226],
            [-99.754142, 30.290697999177702]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "143",
        COUNTYNS: "01025841",
        AFFGEOID: "0500000US37143",
        GEOID: "37143",
        NAME: "Perquimans",
        LSAD: "06",
        ALAND: 640099676,
        AWATER: 211829497
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.590107, 36.238861999100067],
            [-76.559646, 36.351055999098968],
            [-76.453711, 36.378091999098707],
            [-76.37488, 36.258653999099884],
            [-76.290671, 36.223496999100242],
            [-76.177396, 36.109190999101372],
            [-76.157953, 36.059516999101874],
            [-76.186705, 36.04772499910198],
            [-76.358393, 36.020018999102255],
            [-76.396794, 36.026442999102201],
            [-76.402957, 36.07286699910172],
            [-76.572077, 36.10946299910136],
            [-76.590107, 36.238861999100067]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "001",
        COUNTYNS: "00858638",
        AFFGEOID: "0500000US32001",
        GEOID: "32001",
        NAME: "Churchill",
        LSAD: "06",
        ALAND: 12770128151,
        AWATER: 242405502
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.225176, 39.99938499907082],
            [-118.500979, 39.998675999070826],
            [-117.541749, 40.001033999070806],
            [-117.46164, 39.88681699907147],
            [-117.495344, 39.748363999072275],
            [-117.461786, 39.622443999073056],
            [-117.485336, 39.545321999073515],
            [-117.59024, 39.517520999073668],
            [-117.656397, 39.468042999074001],
            [-117.646011, 39.426601999074251],
            [-117.759795, 39.369224999074618],
            [-117.714056, 39.245557999075423],
            [-117.806183, 39.145844999076076],
            [-117.775468, 39.093424999076419],
            [-117.865116, 39.073653999076555],
            [-118.753835, 39.074629999076549],
            [-118.754014, 39.112022999076302],
            [-118.890479, 39.227752999075541],
            [-118.988528, 39.285794999075158],
            [-119.118193, 39.388515999074492],
            [-119.117635, 39.518741999073676],
            [-119.077988, 39.72244099907244],
            [-119.059922, 39.740384999072333],
            [-119.190162, 39.632166999072979],
            [-119.189342, 39.650869999072874],
            [-119.170641, 39.680452999072699],
            [-119.208543, 39.809966999071925],
            [-119.225176, 39.99938499907082]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "233",
        COUNTYNS: "01383902",
        AFFGEOID: "0500000US48233",
        GEOID: "48233",
        NAME: "Hutchinson",
        LSAD: "06",
        ALAND: 2298408148,
        AWATER: 19507501
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.623466, 36.055404999101896],
            [-101.085735, 36.055275999101902],
            [-101.086068, 35.625266999106344],
            [-101.622831, 35.624056999106351],
            [-101.623466, 36.055404999101896]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "055",
        COUNTYNS: "01026133",
        AFFGEOID: "0500000US37055",
        GEOID: "37055",
        NAME: "Dare",
        LSAD: "06",
        ALAND: 992522471,
        AWATER: 3000549341
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.008348, 35.895801999103526],
            [-75.973152, 35.976642999102701],
            [-75.840046, 36.028833999102169],
            [-75.76628, 36.056955999101895],
            [-75.770651462559698, 36.232080306150941],
            [-75.71831, 36.113673999101316],
            [-75.658537, 36.020429999102262],
            [-75.569794, 35.863300999103856],
            [-75.51901, 35.769086999104829],
            [-75.496086, 35.728514999105265],
            [-75.458659, 35.596596999106637],
            [-75.486771, 35.391651999108824],
            [-75.533627, 35.225824999110635],
            [-75.635493, 35.220259999110688],
            [-75.749561423962191, 35.185616361303282],
            [-75.814021, 35.50018199910766],
            [-75.901342, 35.668951999105872],
            [-76.027479, 35.668848999105897],
            [-76.008348, 35.895801999103526]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "053",
        COUNTYNS: "01026125",
        AFFGEOID: "0500000US37053",
        GEOID: "37053",
        NAME: "Currituck",
        LSAD: "06",
        ALAND: 678415822,
        AWATER: 685024028
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.313215, 36.550550999097055],
            [-76.122349947010079, 36.550552331651154],
            [-76.02675, 36.550552999097057],
            [-75.867044, 36.550753999097061],
            [-75.838437, 36.434896999098171],
            [-75.79641, 36.290350999099573],
            [-75.770651462559698, 36.232080306150941],
            [-75.76628, 36.056955999101895],
            [-75.840046, 36.028833999102169],
            [-75.874806, 36.041788999102039],
            [-75.906295, 36.085876999101593],
            [-75.887984, 36.163310999100823],
            [-75.948777, 36.285850999099615],
            [-76.050755, 36.359818999098898],
            [-76.114069, 36.357497999098918],
            [-76.158532, 36.425332999098259],
            [-76.313302968260288, 36.550551088904051],
            [-76.313215, 36.550550999097055]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "061",
        COUNTYNS: "00451677",
        AFFGEOID: "0500000US18061",
        GEOID: "18061",
        NAME: "Harrison",
        LSAD: "06",
        ALAND: 1254889443,
        AWATER: 5193189
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.321274, 38.147417999083267],
            [-86.253137, 38.291899999082155],
            [-86.255045, 38.42268499908117],
            [-86.032817, 38.417897999081198],
            [-86.032559, 38.33065999908186],
            [-85.955224, 38.260500999082389],
            [-85.957576, 38.186071999082962],
            [-85.895911787735585, 38.179926919953409],
            [-85.905164, 38.11106999908354],
            [-85.922395, 38.028678999084178],
            [-85.951729645915492, 38.014940126174899],
            [-85.976028, 38.003559999084388],
            [-85.997351659754301, 37.991225601383597],
            [-86.033386, 37.970381999084644],
            [-86.095766, 38.00892999908433],
            [-86.206439, 38.021875999084237],
            [-86.278656, 38.098508999083641],
            [-86.321274, 38.147417999083267]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "063",
        COUNTYNS: "00485000",
        AFFGEOID: "0500000US20063",
        GEOID: "20063",
        NAME: "Gove",
        LSAD: "06",
        ALAND: 2775601943,
        AWATER: 245369
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.811858, 39.133359999076156],
            [-100.721296, 39.133380999076167],
            [-100.1642, 39.132070999076163],
            [-100.14794, 39.132178999076167],
            [-100.153823, 38.697340999079167],
            [-100.2472, 38.698164999079154],
            [-100.688006, 38.70002099907915],
            [-100.818698, 38.699860999079142],
            [-100.811858, 39.133359999076156]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "121",
        COUNTYNS: "00758514",
        AFFGEOID: "0500000US29121",
        GEOID: "29121",
        NAME: "Macon",
        LSAD: "06",
        ALAND: 2075118128,
        AWATER: 28912824
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.847477, 40.037300999070609],
            [-92.345064, 40.037209999070612],
            [-92.344708, 39.953296999071092],
            [-92.287882, 39.952489999071098],
            [-92.299316, 39.605343999073142],
            [-92.300871, 39.605347999073146],
            [-92.692149, 39.610264999073117],
            [-92.689331, 39.69809399907259],
            [-92.85792, 39.699984999072576],
            [-92.847477, 40.037300999070609]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "003",
        COUNTYNS: "00581287",
        AFFGEOID: "0500000US23003",
        GEOID: "23003",
        NAME: "Aroostook",
        LSAD: "06",
        ALAND: 17278038056,
        AWATER: 405253238
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.997086, 46.695229999058512],
            [-69.818552, 46.875029999058889],
            [-69.566383, 47.125031999059466],
            [-69.439198, 47.25003299905979],
            [-69.219996, 47.457158999060354],
            [-69.156074, 47.451034999060354],
            [-69.108215, 47.4358309990603],
            [-69.039301, 47.422169999060266],
            [-69.053885, 47.377877999060139],
            [-69.0402, 47.245099999059782],
            [-68.966433, 47.2127119990597],
            [-68.900985, 47.178518999059598],
            [-68.803537, 47.216032999059706],
            [-68.675913, 47.242625999059776],
            [-68.604819, 47.249417999059794],
            [-68.588725, 47.281720999059871],
            [-68.507432, 47.296635999059916],
            [-68.460064, 47.286064999059889],
            [-68.375615, 47.292267999059902],
            [-68.384281, 47.326942999059995],
            [-68.361559, 47.355604999060077],
            [-68.26971, 47.353732999060071],
            [-68.204263, 47.339729999060033],
            [-68.153509, 47.314037999059956],
            [-68.082896, 47.271920999059851],
            [-67.998171, 47.217841999059708],
            [-67.952269, 47.196141999059655],
            [-67.889155, 47.118771999059469],
            [-67.789761, 47.065743999059322],
            [-67.789799, 46.79486799905871],
            [-67.788406, 46.601794999058313],
            [-67.782114, 46.279380999057786],
            [-67.780438, 46.038451999057443],
            [-67.779984, 45.938162999057319],
            [-67.750422, 45.917897999057303],
            [-67.803678, 45.86937899905724],
            [-67.763955, 45.829982999057201],
            [-67.803626, 45.781623999057153],
            [-67.781892, 45.731188999057117],
            [-67.802894295077081, 45.67892788452027],
            [-67.902264, 45.680114999057082],
            [-68.048012, 45.639896999057029],
            [-68.260136, 45.604482999057005],
            [-68.410884, 45.573336999056984],
            [-68.433001, 45.581203999056989],
            [-68.427598, 45.944590999057318],
            [-68.436902, 46.042889999057444],
            [-68.436443, 46.382120999057932],
            [-68.706297, 46.395493999057962],
            [-68.819401, 46.395781999057959],
            [-68.821414, 46.572509999058276],
            [-69.721109, 46.574052999058274],
            [-70.023019787056185, 46.573486471575571],
            [-69.997086, 46.695229999058512]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "137",
        COUNTYNS: "00351383",
        AFFGEOID: "0500000US13137",
        GEOID: "13137",
        NAME: "Habersham",
        LSAD: "06",
        ALAND: 716858330,
        AWATER: 5814561
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.652194, 34.822992999115144],
            [-83.586897, 34.819990999115177],
            [-83.534904, 34.740012999116082],
            [-83.431465, 34.747271999116002],
            [-83.349608510913285, 34.717010581743672],
            [-83.340039106784573, 34.686327709528811],
            [-83.365044, 34.675307999116846],
            [-83.45977, 34.481141999119103],
            [-83.537436, 34.491733999118985],
            [-83.615251, 34.431747999119686],
            [-83.666415, 34.50360199911885],
            [-83.61554, 34.552863999118259],
            [-83.662819, 34.659800999117017],
            [-83.628295, 34.76339399911582],
            [-83.681596, 34.800592999115402],
            [-83.652194, 34.822992999115144]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "25",
        COUNTYFP: "001",
        COUNTYNS: "00606927",
        AFFGEOID: "0500000US25001",
        GEOID: "25001",
        NAME: "Barnstable",
        LSAD: "06",
        ALAND: 1020989901,
        AWATER: 2360346063
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.698197, 41.559001999063376],
            [-70.695392, 41.60254599906321],
            [-70.715979844668794, 41.614013267827367],
            [-70.690964, 41.660456999062987],
            [-70.620785, 41.747529999062657],
            [-70.632555, 41.762903999062608],
            [-70.565363, 41.786668999062513],
            [-70.536407254124796, 41.811634144808821],
            [-70.494048, 41.773882999062558],
            [-70.441718, 41.752897999062633],
            [-70.323819, 41.736057999062702],
            [-70.272289, 41.721345999062748],
            [-70.216073, 41.742980999062681],
            [-70.121978, 41.75884099906262],
            [-70.024734, 41.787363999062507],
            [-70.003842, 41.808519999062433],
            [-70.006111, 41.852395999062274],
            [-70.06901, 41.884923999062167],
            [-70.074006, 41.938649999061958],
            [-70.076573999136698, 41.957942457311916],
            [-70.083775, 42.012040999061718],
            [-70.15076, 42.026568999061681],
            [-70.190834, 42.020027999061696],
            [-70.245385, 42.06373299906155],
            [-70.189305, 42.082336999061489],
            [-70.138942, 42.092906999061462],
            [-70.049382, 42.064688999061552],
            [-69.994136, 41.999257999061768],
            [-69.980231361587698, 41.945986459295256],
            [-69.974781, 41.925104999062029],
            [-69.935952, 41.809421999062423],
            [-69.928261, 41.691699999062863],
            [-69.931129, 41.622658999063127],
            [-69.964982, 41.551109999063407],
            [-70.011229, 41.543930999063434],
            [-70.011961, 41.619796999063141],
            [-70.007011, 41.671578999062945],
            [-70.055523, 41.664842999062969],
            [-70.158621, 41.650437999063037],
            [-70.269687, 41.617774999063151],
            [-70.321588, 41.630507999063099],
            [-70.400581, 41.606381999063181],
            [-70.445289, 41.591814999063246],
            [-70.476256, 41.558501999063367],
            [-70.559689, 41.548329999063412],
            [-70.654104, 41.519024999063532],
            [-70.669058366600495, 41.512929661738156],
            [-70.697498, 41.527157999063505],
            [-70.726088944807003, 41.543236930981038],
            [-70.698197, 41.559001999063376]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "025",
        COUNTYNS: "00974111",
        AFFGEOID: "0500000US36025",
        GEOID: "36025",
        NAME: "Delaware",
        LSAD: "06",
        ALAND: 3735952964,
        AWATER: 64717114
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.415319, 42.314150999060764],
            [-75.197237, 42.358328999060625],
            [-74.844121, 42.512590999060187],
            [-74.71158, 42.517798999060169],
            [-74.618895, 42.424388999060426],
            [-74.443506, 42.355016999060638],
            [-74.53731, 42.201423999061106],
            [-74.451713, 42.1692249990612],
            [-74.530985, 42.146474999061276],
            [-74.780693, 42.016374999061711],
            [-75.146664899285881, 41.850125929208588],
            [-75.190203, 41.86245399906224],
            [-75.263005, 41.885108999062155],
            [-75.291762, 41.947091999061946],
            [-75.341125, 41.992771999061794],
            [-75.359859929150502, 41.993685887966393],
            [-75.402239, 42.010698999061724],
            [-75.418421, 42.195031999061136],
            [-75.415319, 42.314150999060764]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "183",
        COUNTYNS: "01639799",
        AFFGEOID: "0500000US47183",
        GEOID: "47183",
        NAME: "Weakley",
        LSAD: "06",
        ALAND: 1503107889,
        AWATER: 3707115
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.949163, 36.410098999098388],
            [-88.81642, 36.410731999098388],
            [-88.827178036340484, 36.501968321354418],
            [-88.816764157348089, 36.501951430910722],
            [-88.516358059979396, 36.501464197260823],
            [-88.530322, 36.152022999100936],
            [-88.532233, 36.115415999101295],
            [-88.655488, 36.117337999101281],
            [-88.692709, 36.062745999101821],
            [-88.863298, 36.137638999101085],
            [-88.959839, 36.222833999100239],
            [-88.949163, 36.410098999098388]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "053",
        COUNTYNS: "01720038",
        AFFGEOID: "0500000US30053",
        GEOID: "30053",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 9356348235,
        AWATER: 161596376
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.049193, 49.000911999066162],
            [-115.501016, 49.000693999066165],
            [-115.207912, 48.99922799906615],
            [-114.727045223277003, 49.000587002954767],
            [-114.6848, 48.97327599906604],
            [-114.718806, 48.938677999065874],
            [-114.691671, 48.842114999065444],
            [-114.732545, 48.809751999065291],
            [-114.612063, 48.751857999065052],
            [-114.692131, 48.684576999064753],
            [-114.639007, 48.658877999064636],
            [-114.88859, 48.658764999064644],
            [-114.888679, 48.573545999064287],
            [-114.849803, 48.573501999064284],
            [-114.848098, 48.226124999062911],
            [-115.020079, 48.225953999062895],
            [-115.010672, 48.017620999062153],
            [-115.154527, 48.017691999062158],
            [-115.185196, 47.919003999061822],
            [-115.278953, 47.890273999061712],
            [-115.32828, 47.917472999061815],
            [-115.524695, 47.908593999061772],
            [-115.558139, 47.942290999061889],
            [-115.544841, 47.99938399906209],
            [-115.584705, 48.021974999062174],
            [-115.645768, 48.134141999062564],
            [-115.696111, 48.177473999062727],
            [-115.672359, 48.20639399906284],
            [-115.737279, 48.26329199906305],
            [-115.800172, 48.222817999062904],
            [-115.936745, 48.184165999062763],
            [-115.957251, 48.149041999062625],
            [-116.048929247775988, 48.216127946989978],
            [-116.048948, 48.309846999063232],
            [-116.049155, 48.481246999063906],
            [-116.049156520648992, 48.502042480822098],
            [-116.049193, 49.000911999066162]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "157",
        COUNTYNS: "01383864",
        AFFGEOID: "0500000US48157",
        GEOID: "48157",
        NAME: "Fort Bend",
        LSAD: "06",
        ALAND: 2231423107,
        AWATER: 61595965
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.088912, 29.601657999189065],
            [-96.02485, 29.602876999189057],
            [-96.032711, 29.727943999186955],
            [-95.97094, 29.72855399918695],
            [-95.82622, 29.788279999185953],
            [-95.751446, 29.74647199918665],
            [-95.688349, 29.710861999187248],
            [-95.687082, 29.710077999187252],
            [-95.654265, 29.691654999187559],
            [-95.652144, 29.690945999187576],
            [-95.621848, 29.673571999187871],
            [-95.571345, 29.648740999188281],
            [-95.446339, 29.59069799918926],
            [-95.424118, 29.58023299918943],
            [-95.443224, 29.510213999190615],
            [-95.462503, 29.439239999191823],
            [-95.549994, 29.438438999191831],
            [-95.579598, 29.340128999193507],
            [-95.625863, 29.293348999194315],
            [-95.752866, 29.324502999193783],
            [-95.847656, 29.262589999194834],
            [-95.94681, 29.333432999193622],
            [-95.97343, 29.411444999192295],
            [-96.059627, 29.473328999191249],
            [-96.044596, 29.562237999189733],
            [-96.088912, 29.601657999189065]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "069",
        COUNTYNS: "00516881",
        AFFGEOID: "0500000US21069",
        GEOID: "21069",
        NAME: "Fleming",
        LSAD: "06",
        ALAND: 902719721,
        AWATER: 7185948
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.930348, 38.492277999080656],
            [-83.85928, 38.456347999080911],
            [-83.641852, 38.525380999080404],
            [-83.581613, 38.431257999081097],
            [-83.452848, 38.381798999081475],
            [-83.50716, 38.269446999082319],
            [-83.564335, 38.216605999082731],
            [-83.635195, 38.187524999082946],
            [-83.64615, 38.171000999083077],
            [-83.725393, 38.292583999082147],
            [-83.848355, 38.296948999082105],
            [-83.839597, 38.33836799908179],
            [-83.916131, 38.36015199908163],
            [-83.980068, 38.43944799908104],
            [-83.930348, 38.492277999080656]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "099",
        COUNTYNS: "01720018",
        AFFGEOID: "0500000US30099",
        GEOID: "30099",
        NAME: "Teton",
        LSAD: "06",
        ALAND: 5883425292,
        AWATER: 52657849
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.014811, 48.131029999062562],
            [-112.177755, 48.130716999062564],
            [-112.177751, 48.087161999062396],
            [-112.047999, 48.086611999062399],
            [-112.048342, 48.042908999062234],
            [-111.984125, 48.042766999062252],
            [-111.984263, 47.98459199906204],
            [-111.408514, 47.987177999062055],
            [-111.40885, 47.698122999061084],
            [-111.666482, 47.697760999061103],
            [-111.66626, 47.611252999060831],
            [-111.922936, 47.611230999060815],
            [-111.927109, 47.500145999060493],
            [-112.047781, 47.516033999060532],
            [-112.278, 47.505622999060499],
            [-112.317471, 47.542069999060608],
            [-112.405146, 47.55231699906065],
            [-112.429183, 47.603385999060798],
            [-112.549721, 47.651976999060949],
            [-112.780545, 47.598621999060782],
            [-112.857082, 47.629826999060882],
            [-112.91422, 47.694957999061089],
            [-112.913256, 47.826215999061503],
            [-112.984734, 47.953917999061922],
            [-112.878931, 47.975712999062011],
            [-113.014811, 48.131029999062562]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "50",
        COUNTYFP: "025",
        COUNTYNS: "01461769",
        AFFGEOID: "0500000US50025",
        GEOID: "50025",
        NAME: "Windham",
        LSAD: "06",
        ALAND: 2034441181,
        AWATER: 32925693
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.002789, 43.0230069990589],
            [-72.996941, 43.119096999058691],
            [-72.868737, 43.113169999058705],
            [-72.819368, 43.255497999058413],
            [-72.685637, 43.222494999058476],
            [-72.433611197959493, 43.232903811320263],
            [-72.440563, 43.215253999058476],
            [-72.450395939215895, 43.161213515965009],
            [-72.451802, 43.153485999058624],
            [-72.435191, 43.086621999058764],
            [-72.462248, 43.044213999058854],
            [-72.451954709068204, 43.020494909689212],
            [-72.444977, 43.004415999058942],
            [-72.492597, 42.967647999059018],
            [-72.532186, 42.954944999059059],
            [-72.531469, 42.897949999059186],
            [-72.556112, 42.866251999059259],
            [-72.553902050848592, 42.858031609228995],
            [-72.5396, 42.804831999059409],
            [-72.477615, 42.761244999059514],
            [-72.458519, 42.726852999059609],
            [-72.516711182782203, 42.72846766888879],
            [-72.809113, 42.736580999059584],
            [-72.864291854845391, 42.73771388421877],
            [-72.930262612517993, 42.739068339517175],
            [-72.921376, 42.92679199905912],
            [-73.008063, 42.930086999059114],
            [-73.002789, 43.0230069990589]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "25",
        COUNTYFP: "005",
        COUNTYNS: "00606929",
        AFFGEOID: "0500000US25005",
        GEOID: "25005",
        NAME: "Bristol",
        LSAD: "06",
        ALAND: 1432461196,
        AWATER: 357549440
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.381461, 41.952140999061925],
            [-71.381431346893095, 41.985084118191317],
            [-71.353265, 41.989539999061805],
            [-71.288265, 42.014461999061709],
            [-71.138619, 42.072812999061519],
            [-71.080483, 42.095538999061439],
            [-71.054718, 41.985056999061811],
            [-70.999773, 41.929670999062004],
            [-71.000002, 41.928817999061998],
            [-70.999669, 41.92843899906201],
            [-70.997782, 41.927953999062012],
            [-70.996804, 41.927403999062008],
            [-70.995908, 41.926351999062028],
            [-70.995631, 41.925587999062031],
            [-70.995744, 41.924424999062026],
            [-70.997232, 41.923233999062035],
            [-70.996907, 41.920907999062038],
            [-70.997436, 41.918884999062044],
            [-70.997395, 41.917401999062051],
            [-70.996347, 41.915626999062056],
            [-70.994834, 41.91492299906205],
            [-70.994341, 41.915235999062062],
            [-70.993955, 41.916348999062045],
            [-70.992507, 41.916175999062048],
            [-70.991978, 41.915601999062055],
            [-70.990984, 41.910058999062073],
            [-70.991455, 41.908339999062086],
            [-70.992307, 41.90705899906208],
            [-70.993152, 41.906444999062089],
            [-70.987256, 41.905807999062091],
            [-70.973717, 41.860878999062251],
            [-71.03657, 41.816524999062402],
            [-71.014591, 41.799567999062468],
            [-71.026288, 41.77888799906254],
            [-70.921782, 41.791243999062502],
            [-70.907184, 41.763542999062594],
            [-70.88497, 41.756114999062625],
            [-70.865003, 41.700163999062831],
            [-70.839175, 41.614760999063165],
            [-70.803962613207901, 41.601515540063502],
            [-70.82191, 41.582840999063272],
            [-70.853121, 41.587320999063259],
            [-70.85762366603339, 41.586512091526771],
            [-70.9090917764468, 41.577265804528793],
            [-70.910165, 41.577072999063304],
            [-70.941785, 41.540120999063447],
            [-70.981708, 41.51006999906356],
            [-71.02032123222439, 41.502159474980608],
            [-71.035514, 41.499046999063602],
            [-71.085663, 41.509291999063556],
            [-71.12057, 41.497447999063603],
            [-71.137492, 41.602560999063208],
            [-71.132888, 41.660101999062988],
            [-71.19564, 41.675089999062934],
            [-71.201327187524896, 41.681768327265601],
            [-71.20860053289509, 41.69030924266557],
            [-71.261392, 41.752300999062641],
            [-71.317402007931207, 41.777256108031253],
            [-71.329396, 41.782599999062541],
            [-71.339597, 41.831999999062354],
            [-71.339298, 41.893398999062136],
            [-71.3817, 41.893198999062129],
            [-71.381461, 41.952140999061925]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "049",
        COUNTYNS: "00695748",
        AFFGEOID: "0500000US28049",
        GEOID: "28049",
        NAME: "Hinds",
        LSAD: "06",
        ALAND: 2252651371,
        AWATER: 19495790
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.728622, 32.225118999148386],
            [-90.705175, 32.346787999146656],
            [-90.636162, 32.374497999146286],
            [-90.553821, 32.507573999144427],
            [-90.522217, 32.503109999144492],
            [-90.450037, 32.573782999143511],
            [-90.449864, 32.488393999144698],
            [-90.243641, 32.487532999144705],
            [-90.243764, 32.400097999145927],
            [-90.123488, 32.399450999145927],
            [-90.065697, 32.399528999145929],
            [-90.093681, 32.390243999146058],
            [-90.179701, 32.276161999147668],
            [-90.194855, 32.187854999148911],
            [-90.254799, 32.151187999149435],
            [-90.230331, 32.049727999150882],
            [-90.718297, 32.04835199915091],
            [-90.728622, 32.225118999148386]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "071",
        COUNTYNS: "00758490",
        AFFGEOID: "0500000US29071",
        GEOID: "29071",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 2389734942,
        AWATER: 20729139
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.369192, 38.699323999079155],
            [-91.319022, 38.70836799907908],
            [-91.226547, 38.621566999079711],
            [-91.077324, 38.6093429990798],
            [-90.964461, 38.547544999080245],
            [-90.916554, 38.534724999080332],
            [-90.773622, 38.594616999079904],
            [-90.742412, 38.63456299907962],
            [-90.732328, 38.639301999079578],
            [-90.736311, 38.46664299908084],
            [-90.737599, 38.415896999081227],
            [-90.7494, 38.358189999081645],
            [-90.780185, 38.204111999082812],
            [-90.970187, 38.206686999082798],
            [-91.095765, 38.204082999082814],
            [-91.349553, 38.204077999082813],
            [-91.367482, 38.209740999082776],
            [-91.369192, 38.699323999079155]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "077",
        COUNTYNS: "00424240",
        AFFGEOID: "0500000US17077",
        GEOID: "17077",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 1512739207,
        AWATER: 47416095
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.687221380853089, 37.796406717525358],
            [-89.664475, 37.829028999085779],
            [-89.595084, 37.955309999084761],
            [-89.177597, 37.950310999084813],
            [-89.150755, 37.945184999084852],
            [-89.151176, 37.861998999085515],
            [-89.153668, 37.600468999087667],
            [-89.453355, 37.600194999087662],
            [-89.497745926039187, 37.569985913016104],
            [-89.494051, 37.580115999087838],
            [-89.506563, 37.625049999087466],
            [-89.521948, 37.696474999086867],
            [-89.591289, 37.723598999086647],
            [-89.667993, 37.759483999086342],
            [-89.687221380853089, 37.796406717525358]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "003",
        COUNTYNS: "01710958",
        AFFGEOID: "0500000US24003",
        GEOID: "24003",
        NAME: "Anne Arundel",
        LSAD: "06",
        ALAND: 1074289193,
        AWATER: 448097535
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.840362, 39.103141999076364],
            [-76.826665, 39.109947999076311],
            [-76.813312, 39.122463999076224],
            [-76.786966, 39.128781999076189],
            [-76.781511, 39.145497999076085],
            [-76.765016, 39.164836999075945],
            [-76.746544, 39.179591999075853],
            [-76.722606, 39.182856999075831],
            [-76.713346, 39.193738999075762],
            [-76.712164, 39.201932999075709],
            [-76.697085, 39.214091999075627],
            [-76.644212, 39.248923999075394],
            [-76.580287, 39.206606999075682],
            [-76.529486, 39.218407999075595],
            [-76.504008590918104, 39.19928624820173],
            [-76.525785, 39.177907999075856],
            [-76.428681, 39.131708999076167],
            [-76.42186, 39.081441999076503],
            [-76.420394, 39.042069999076773],
            [-76.39408, 39.011310999076976],
            [-76.448981, 38.982810999077181],
            [-76.471281, 38.95651199907735],
            [-76.45028, 38.941112999077461],
            [-76.46938, 38.907612999077685],
            [-76.473976163655493, 38.902693377707621],
            [-76.49068, 38.884813999077842],
            [-76.516944, 38.851156999078079],
            [-76.489878, 38.838714999078164],
            [-76.526979, 38.787015999078541],
            [-76.558743, 38.756351999078746],
            [-76.526655, 38.724429999078971],
            [-76.52709254739419, 38.71275030031736],
            [-76.615902, 38.720803999078989],
            [-76.642749, 38.769280999078653],
            [-76.686358, 38.748474999078802],
            [-76.710639, 38.815693999078327],
            [-76.670111, 38.906918999077682],
            [-76.751258, 39.034713999076828],
            [-76.790741, 39.045721999076747],
            [-76.831424, 39.069033999076574],
            [-76.840362, 39.103141999076364]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "001",
        COUNTYNS: "00308548",
        AFFGEOID: "0500000US12001",
        GEOID: "12001",
        NAME: "Alachua",
        LSAD: "06",
        ALAND: 2266400894,
        AWATER: 242801104
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.658554, 29.830143999185253],
            [-82.640627, 29.828848999185279],
            [-82.529705, 29.940880999183424],
            [-82.510819, 29.945233999183344],
            [-82.418728, 29.923092999183723],
            [-82.272563, 29.843010999185037],
            [-82.133126, 29.83594899918516],
            [-82.055625, 29.718231999187122],
            [-82.050955, 29.702568999187378],
            [-82.055899, 29.471231999191279],
            [-82.199287, 29.422034999192107],
            [-82.211449, 29.484066999191054],
            [-82.40662, 29.485047999191043],
            [-82.556766, 29.480054999191125],
            [-82.557565, 29.537605999190156],
            [-82.656301, 29.564810999189692],
            [-82.658554, 29.830143999185253]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "023",
        COUNTYNS: "01681908",
        AFFGEOID: "0500000US06023",
        GEOID: "06023",
        NAME: "Humboldt",
        LSAD: "06",
        ALAND: 9241251740,
        AWATER: 1254039383
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.387023, 40.504953999068107],
            [-124.301355, 40.65964299906733],
            [-124.176715, 40.843617999066446],
            [-124.118147, 40.98926299906578],
            [-124.125448, 41.048503999065517],
            [-124.154513, 41.087158999065338],
            [-124.163988, 41.13867499906511],
            [-124.122677, 41.189725999064891],
            [-124.092284, 41.287694999064477],
            [-124.063076, 41.439578999063855],
            [-124.067473659422006, 41.464736187554635],
            [-123.770551, 41.464192999063748],
            [-123.770239, 41.380775999064085],
            [-123.661363, 41.382089999064085],
            [-123.487831, 41.3769339990641],
            [-123.408291, 41.179943999064932],
            [-123.464006, 41.076347999065376],
            [-123.406082, 41.013791999065667],
            [-123.481357, 40.91515699906612],
            [-123.560163, 40.95025699906595],
            [-123.623662, 40.92982299906604],
            [-123.55985, 40.829583999066514],
            [-123.544059, 40.73938599906694],
            [-123.544458, 40.001922999070821],
            [-124.025205174530029, 40.001299340739827],
            [-124.035904, 40.013318999070755],
            [-124.068908, 40.021306999070696],
            [-124.087086, 40.078441999070378],
            [-124.139952, 40.11634999907016],
            [-124.187874, 40.130541999070097],
            [-124.258405, 40.184276999069795],
            [-124.34307, 40.243978999069476],
            [-124.363414, 40.260973999069378],
            [-124.353124, 40.331424999069007],
            [-124.365357, 40.374854999068781],
            [-124.409591, 40.438075999068452],
            [-124.387023, 40.504953999068107]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "089",
        COUNTYNS: "00758499",
        AFFGEOID: "0500000US29089",
        GEOID: "29089",
        NAME: "Howard",
        LSAD: "06",
        ALAND: 1201192546,
        AWATER: 19893064
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.94385, 39.121737999076238],
            [-92.849224, 39.226217999075537],
            [-92.790369, 39.343585999074776],
            [-92.707367, 39.321613999074934],
            [-92.430229, 39.248794999075407],
            [-92.565052, 38.998740999077064],
            [-92.558086, 38.97069199907726],
            [-92.852628, 38.982182999077168],
            [-92.934569, 39.064546999076612],
            [-92.94385, 39.121737999076238]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "303",
        COUNTYNS: "00348428",
        AFFGEOID: "0500000US13303",
        GEOID: "13303",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1757156969,
        AWATER: 15316016
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.049081, 32.985604999137912],
            [-83.052197, 33.080681999136644],
            [-83.00874, 33.080848999136641],
            [-82.888866, 33.138602999135884],
            [-82.855046, 33.196426999135134],
            [-82.748311, 33.238347999134568],
            [-82.739483, 33.17436799913542],
            [-82.661917, 33.126330999136044],
            [-82.598018, 33.032012999137301],
            [-82.550225, 33.019350999137465],
            [-82.510851, 32.917753999138817],
            [-82.521052, 32.822355999140115],
            [-82.768365, 32.769107999140829],
            [-82.802456, 32.809755999140293],
            [-82.946966, 32.75935799914096],
            [-82.955664, 32.756960999141],
            [-82.974265, 32.839235999139881],
            [-83.027036, 32.85613299913966],
            [-83.073648, 32.946561999138439],
            [-83.049081, 32.985604999137912]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "345",
        COUNTYNS: "01383958",
        AFFGEOID: "0500000US48345",
        GEOID: "48345",
        NAME: "Motley",
        LSAD: "06",
        ALAND: 2562963901,
        AWATER: 644140
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.041484, 34.312443999121108],
            [-100.946132, 34.312758999121108],
            [-100.51734, 34.314101999121092],
            [-100.518691, 33.835649999126929],
            [-101.041158, 33.833624999126961],
            [-101.041437, 34.130795999123301],
            [-101.041484, 34.312443999121108]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "305",
        COUNTYNS: "00350608",
        AFFGEOID: "0500000US13305",
        GEOID: "13305",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 1662305031,
        AWATER: 18071707
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.133013, 31.773403999154894],
            [-82.08015, 31.827393999154104],
            [-82.048582, 31.827074999154114],
            [-81.982941, 31.784903999154711],
            [-81.969052, 31.789323999154664],
            [-81.850624, 31.667670999156449],
            [-81.701774, 31.584921999157672],
            [-81.681892, 31.541809999158307],
            [-81.663206, 31.538666999158355],
            [-81.612054, 31.46940499915938],
            [-81.62429, 31.45298199915964],
            [-81.691911, 31.40052399916042],
            [-81.731694, 31.330047999161486],
            [-81.801052, 31.363736999160963],
            [-81.923238, 31.345874999161243],
            [-82.041129, 31.373720999160817],
            [-82.063387, 31.466361999159435],
            [-82.132794, 31.471261999159356],
            [-82.133013, 31.773403999154894]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "311",
        COUNTYNS: "00326781",
        AFFGEOID: "0500000US13311",
        GEOID: "13311",
        NAME: "White",
        LSAD: "06",
        ALAND: 623386723,
        AWATER: 3928195
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.856506, 34.722190999116293],
            [-83.825465, 34.778971999115647],
            [-83.780401, 34.792892999115487],
            [-83.704146, 34.790981999115509],
            [-83.681596, 34.800592999115402],
            [-83.628295, 34.76339399911582],
            [-83.662819, 34.659800999117017],
            [-83.61554, 34.552863999118259],
            [-83.666415, 34.50360199911885],
            [-83.788604, 34.514095999118716],
            [-83.843405, 34.505493999118819],
            [-83.876133, 34.620843999117469],
            [-83.856506, 34.722190999116293]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "125",
        COUNTYNS: "00345642",
        AFFGEOID: "0500000US13125",
        GEOID: "13125",
        NAME: "Glascock",
        LSAD: "06",
        ALAND: 372284127,
        AWATER: 1847843
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.755846, 33.253439999134386],
            [-82.559753, 33.327269999133406],
            [-82.431957, 33.274829999134099],
            [-82.584942, 33.171452999135454],
            [-82.578816, 33.119839999136126],
            [-82.661917, 33.126330999136044],
            [-82.739483, 33.17436799913542],
            [-82.748311, 33.238347999134568],
            [-82.755846, 33.253439999134386]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "209",
        COUNTYNS: "00352276",
        AFFGEOID: "0500000US13209",
        GEOID: "13209",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 620371304,
        AWATER: 13383238
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.649298, 32.311131999147179],
            [-82.40913, 32.353737999146574],
            [-82.438987, 32.293179999147426],
            [-82.483131, 31.968960999152067],
            [-82.480224, 31.954960999152249],
            [-82.543655, 31.958913999152191],
            [-82.597671, 32.013978999151405],
            [-82.644309, 32.29419299914742],
            [-82.655486, 32.297560999147365],
            [-82.649298, 32.311131999147179]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "135",
        COUNTYNS: "01688166",
        AFFGEOID: "0500000US13135",
        GEOID: "13135",
        NAME: "Gwinnett",
        LSAD: "06",
        ALAND: 1114851817,
        AWATER: 16349375
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.262276, 33.987404999125047],
            [-84.182023, 33.997596999124923],
            [-84.097693, 34.05070799912427],
            [-84.117927, 34.065563999124095],
            [-84.062841, 34.167872999122835],
            [-83.890097, 34.106178999123586],
            [-83.817682, 34.127492999123326],
            [-83.869115, 34.004315999124842],
            [-83.799104, 33.929843999125758],
            [-83.855066, 33.885309999126314],
            [-83.908465, 33.842844999126847],
            [-83.982033, 33.786053999127553],
            [-84.023713, 33.752807999127981],
            [-84.075914, 33.77750699912766],
            [-84.17313, 33.858114999126656],
            [-84.237113, 33.904446999126066],
            [-84.277093, 33.957613999125428],
            [-84.262276, 33.987404999125047]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "055",
        COUNTYNS: "01135863",
        AFFGEOID: "0500000US41055",
        GEOID: "41055",
        NAME: "Sherman",
        LSAD: "06",
        ALAND: 2133151552,
        AWATER: 19617470
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.01569, 45.307147999056838],
            [-120.956471, 45.310344999056838],
            [-120.868569, 45.401145999056865],
            [-120.826385, 45.514977999056953],
            [-120.908459, 45.55824099905697],
            [-120.913935013084, 45.64806586548054],
            [-120.895575, 45.642944999057036],
            [-120.855674, 45.671544999057048],
            [-120.68937, 45.715846999057099],
            [-120.65251676019399, 45.736169729732005],
            [-120.634968, 45.745846999057122],
            [-120.591166, 45.746546999057124],
            [-120.505863, 45.700047999057084],
            [-120.488546820704002, 45.699912226535581],
            [-120.35798, 45.533712999056959],
            [-120.496059, 45.4651489990569],
            [-120.525059, 45.405248999056873],
            [-120.525558, 45.305748999056831],
            [-120.554457, 45.252748999056813],
            [-120.45225, 45.145449999056787],
            [-120.503733, 45.083476999056764],
            [-120.729792, 45.083730999056769],
            [-120.723569, 45.130910999056773],
            [-120.889357, 45.202304999056793],
            [-121.02408, 45.218725999056794],
            [-121.01569, 45.307147999056838]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "163",
        COUNTYNS: "00345714",
        AFFGEOID: "0500000US13163",
        GEOID: "13163",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 1363593760,
        AWATER: 8237192
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.661917, 33.126330999136044],
            [-82.578816, 33.119839999136126],
            [-82.584942, 33.171452999135454],
            [-82.431957, 33.274829999134099],
            [-82.383829, 33.312105999133614],
            [-82.353498, 33.312317999133604],
            [-82.332774, 33.308680999133649],
            [-82.26758, 33.267396999134192],
            [-82.232554, 33.231210999134674],
            [-82.29118, 33.062826999136888],
            [-82.272353, 32.937795999138558],
            [-82.316512, 32.835771999139929],
            [-82.419129, 32.810926999140264],
            [-82.434149, 32.762257999140928],
            [-82.458249, 32.768222999140853],
            [-82.521052, 32.822355999140115],
            [-82.510851, 32.917753999138817],
            [-82.550225, 33.019350999137465],
            [-82.598018, 33.032012999137301],
            [-82.661917, 33.126330999136044]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "110",
        COUNTYNS: "01419971",
        AFFGEOID: "0500000US02110",
        GEOID: "02110",
        NAME: "Juneau",
        LSAD: "03",
        ALAND: 6829216436,
        AWATER: 1423792753
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-134.772728, 58.166495999155266],
              [-134.724477838090991, 58.211465284801037],
              [-134.699956, 58.161493999155191],
              [-134.608911, 58.171636999155346],
              [-134.46176, 58.159288999155166],
              [-134.329872, 58.134989999154818],
              [-134.256223, 58.144792999154959],
              [-134.254386050803987, 58.144355273441739],
              [-134.538413, 58.098171999154275],
              [-134.577773, 58.05411599915363],
              [-134.697766, 58.032035999153308],
              [-134.784681, 58.096598999154253],
              [-134.772728, 58.166495999155266]
            ]
          ],
          [
            [
              [-135.136971, 58.829797999165201],
              [-135.02326, 58.732627999163725],
              [-134.983701, 58.875643999165895],
              [-135.058835, 58.966784999167309],
              [-134.327992277917957, 58.962972176980848],
              [-134.328964, 58.919592999166582],
              [-134.250526, 58.858045999165633],
              [-133.840392, 58.727990999163644],
              [-133.699835, 58.607289999161821],
              [-133.379908, 58.427908999159143],
              [-133.461475, 58.385525999158503],
              [-133.343725, 58.270914999156801],
              [-133.176979642157988, 58.150537691543938],
              [-133.696701, 57.792723999149864],
              [-133.703025360282965, 57.792036260745157],
              [-133.703097, 57.792151999149851],
              [-133.848776, 57.935439999151903],
              [-134.049603, 58.062026999153758],
              [-134.078155, 58.152045999155057],
              [-134.146685, 58.199083999155739],
              [-134.234572, 58.197233999155721],
              [-134.375579, 58.208704999155891],
              [-134.464635, 58.227387999156164],
              [-134.631203, 58.247445999156454],
              [-134.750586, 58.391532999158599],
              [-134.936897, 58.457473999159582],
              [-135.061769077114008, 58.451353242782879],
              [-135.216683, 58.967773999167314],
              [-135.136971, 58.829797999165201]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "299",
        COUNTYNS: "01383935",
        AFFGEOID: "0500000US48299",
        GEOID: "48299",
        NAME: "Llano",
        LSAD: "06",
        ALAND: 2419183499,
        AWATER: 82373072
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.964612, 30.921367999167735],
            [-98.445782, 30.92143899916773],
            [-98.457382, 30.87889699916839],
            [-98.376877, 30.834889999169079],
            [-98.432098, 30.786309999169845],
            [-98.371905, 30.740484999170555],
            [-98.438995, 30.67328499917161],
            [-98.411148, 30.574643999173162],
            [-98.351983, 30.558392999173432],
            [-98.351041, 30.486095999174584],
            [-98.411462, 30.502094999174322],
            [-98.59167, 30.499874999174352],
            [-98.964232, 30.498481999174381],
            [-98.964612, 30.921367999167735]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "700",
        COUNTYNS: "01498555",
        AFFGEOID: "0500000US51700",
        GEOID: "51700",
        NAME: "Newport News",
        LSAD: "25",
        ALAND: 178939602,
        AWATER: 131011289
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.641581, 37.153826999091514],
            [-76.581218, 37.182510999091257],
            [-76.591577, 37.213832999090982],
            [-76.565214, 37.220494999090924],
            [-76.4355, 37.094865999092036],
            [-76.443117, 37.019808999092703],
            [-76.384419, 36.95105899909332],
            [-76.399567, 36.930946999093514],
            [-76.445301, 36.941890999093417],
            [-76.645821, 37.12515499909177],
            [-76.641581, 37.153826999091514]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "09",
        COUNTYFP: "001",
        COUNTYNS: "00212794",
        AFFGEOID: "0500000US09001",
        GEOID: "09001",
        NAME: "Fairfield",
        LSAD: "06",
        ALAND: 1618664433,
        AWATER: 549280551
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.695935802169586, 41.115255455969304],
            [-73.482709, 41.212759999064779],
            [-73.550961, 41.29542199906443],
            [-73.544151186400597, 41.366319595308539],
            [-73.543306580691691, 41.375112863091807],
            [-73.543183207996293, 41.376397307580909],
            [-73.543147346265485, 41.376770667361505],
            [-73.536969, 41.441093999063845],
            [-73.529677714301286, 41.527161020191798],
            [-73.520017, 41.641196999063062],
            [-73.517918790638191, 41.666720529772967],
            [-73.506923, 41.665801999062957],
            [-73.441466, 41.491293999063629],
            [-73.386964, 41.514793999063535],
            [-73.310473, 41.468814999063724],
            [-73.29776, 41.44827599906381],
            [-73.207688, 41.420543999063916],
            [-73.183269, 41.391710999064045],
            [-73.147555, 41.367695999064132],
            [-73.115754, 41.332195999064282],
            [-73.067851, 41.300995999064412],
            [-73.086852, 41.276595999064519],
            [-73.086752, 41.254595999064612],
            [-73.122353, 41.179796999064934],
            [-73.101173821644508, 41.163726270229304],
            [-73.108352, 41.153717999065037],
            [-73.130253, 41.146796999065074],
            [-73.172835365127398, 41.153442278177451],
            [-73.202656, 41.158095999065033],
            [-73.262358, 41.117495999065198],
            [-73.2698175797685, 41.116676889108504],
            [-73.297214389349008, 41.113668542766426],
            [-73.33066, 41.109995999065227],
            [-73.372296, 41.104019999065258],
            [-73.354231, 41.085638999065345],
            [-73.387227, 41.058246999065467],
            [-73.422165, 41.047561999065515],
            [-73.468239, 41.051346999065494],
            [-73.516903, 41.038737999065539],
            [-73.561968, 41.016796999065647],
            [-73.595699, 41.015994999065647],
            [-73.657336, 40.985170999065794],
            [-73.657374009261389, 40.985524882924992],
            [-73.659362, 41.00403399906569],
            [-73.659533, 41.017856999065636],
            [-73.727775, 41.100695999065273],
            [-73.695935802169586, 41.115255455969304]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "089",
        COUNTYNS: "00977309",
        AFFGEOID: "0500000US36089",
        GEOID: "36089",
        NAME: "St. Lawrence",
        LSAD: "06",
        ALAND: 6939259769,
        AWATER: 367915773
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.834126, 44.422432999056923],
            [-75.807778, 44.471643999056894],
            [-75.76623, 44.515850999056873],
            [-75.567413, 44.658708999056806],
            [-75.423943, 44.756328999056784],
            [-75.333744, 44.806377999056764],
            [-75.255517, 44.857650999056766],
            [-75.142958, 44.900236999056759],
            [-75.066245, 44.93017399905677],
            [-75.005155, 44.958401999056761],
            [-74.992756, 44.977448999056769],
            [-74.907956, 44.983358999056769],
            [-74.834669, 45.014682999056753],
            [-74.74464, 44.990576999056771],
            [-74.725813431619002, 44.991792625582669],
            [-74.644739319825689, 44.997027559473459],
            [-74.525683, 44.170635999057119],
            [-74.535156, 44.099249999057172],
            [-74.854171, 44.070088999057212],
            [-75.062779, 44.050399999057234],
            [-75.170159, 44.096958999057179],
            [-75.446124, 44.217654999057075],
            [-75.861271192834707, 44.405192012560043],
            [-75.834126, 44.422432999056923]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "005",
        COUNTYNS: "00069895",
        AFFGEOID: "0500000US05005",
        GEOID: "05005",
        NAME: "Baxter",
        LSAD: "06",
        ALAND: 1435438032,
        AWATER: 84171993
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.594962, 36.354691999098939],
            [-92.533175, 36.386562999098629],
            [-92.529136581043687, 36.498165682082856],
            [-92.350277, 36.497786999097556],
            [-92.150306249501185, 36.498140332421762],
            [-92.150677, 36.435853999098157],
            [-92.15699, 36.261247999099851],
            [-92.159885, 36.173781999100719],
            [-92.2139, 36.175099999100695],
            [-92.215047, 36.13823199910108],
            [-92.19543, 36.132797999101122],
            [-92.197649, 36.059286999101865],
            [-92.304951, 36.06107999910185],
            [-92.30722, 35.974021999102725],
            [-92.414324, 35.975864999102711],
            [-92.411502, 36.062755999101839],
            [-92.411852, 36.162122999100831],
            [-92.480306, 36.199369999100455],
            [-92.469262, 36.250903999099961],
            [-92.575141, 36.309332999099389],
            [-92.594962, 36.354691999098939]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "025",
        COUNTYNS: "01702365",
        AFFGEOID: "0500000US35025",
        GEOID: "35025",
        NAME: "Lea",
        LSAD: "06",
        ALAND: 11373741670,
        AWATER: 8469171
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.814507, 32.96511399913819],
            [-103.766417, 32.965263999138188],
            [-103.766361, 33.395296999132526],
            [-103.721399, 33.395408999132528],
            [-103.720809, 33.569536999130285],
            [-103.510129, 33.570116999130271],
            [-103.052610516564002, 33.570574775572481],
            [-103.056495064337994, 33.388414213753215],
            [-103.060103, 33.219224999134823],
            [-103.063468888475001, 32.95910638096877],
            [-103.064889, 32.849358999139753],
            [-103.064761, 32.587982999143314],
            [-103.064696, 32.522192999144224],
            [-103.064422, 32.145005999149525],
            [-103.064422401106, 32.08705099915035],
            [-103.064423, 32.000517999151597],
            [-103.326501, 32.000369999151594],
            [-103.722881979148013, 32.000165567827985],
            [-103.722938, 32.522343999144219],
            [-103.814216, 32.522367999144215],
            [-103.814507, 32.96511399913819]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "141",
        COUNTYNS: "01623013",
        AFFGEOID: "0500000US26141",
        GEOID: "26141",
        NAME: "Presque Isle",
        LSAD: "06",
        ALAND: 1706070003,
        AWATER: 4957327100
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.25202, 45.62599799905702],
            [-84.2108933455563, 45.626231016905429],
            [-84.196043, 45.621455999057012],
            [-84.126532, 45.556615999056973],
            [-84.095905, 45.49729799905694],
            [-83.99835, 45.491157999056931],
            [-83.909472, 45.48578399905692],
            [-83.841543, 45.435286999056885],
            [-83.697316, 45.396238999056877],
            [-83.599273, 45.352560999056855],
            [-83.488826, 45.355871999056859],
            [-83.385104, 45.274194999056817],
            [-83.405914, 45.22715699905681],
            [-83.385211079256493, 45.207103992204807],
            [-83.881223, 45.202070999056801],
            [-84.022072, 45.1984239990568],
            [-84.246634, 45.198726999056809],
            [-84.25202, 45.62599799905702]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "065",
        COUNTYNS: "00558088",
        AFFGEOID: "0500000US22065",
        GEOID: "22065",
        NAME: "Madison",
        LSAD: "15",
        ALAND: 1617282478,
        AWATER: 68539547
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.557199, 32.249653999148038],
            [-91.472391, 32.316528999147081],
            [-91.476739, 32.405807999145843],
            [-91.485222, 32.502923999144492],
            [-91.457643, 32.53780699914401],
            [-91.133713, 32.537911999144001],
            [-91.055293104949897, 32.578983500220545],
            [-91.049312, 32.573623999143507],
            [-91.048760427763696, 32.572797039564932],
            [-91.011275, 32.5165959991443],
            [-91.060516, 32.512360999144356],
            [-91.052907, 32.43844199914539],
            [-90.965986, 32.424805999145583],
            [-90.986672, 32.351759999146594],
            [-90.92117, 32.342072999146744],
            [-90.947834, 32.283485999147551],
            [-90.991227, 32.214661999148532],
            [-91.108509, 32.208149999148624],
            [-91.133968, 32.249338999148044],
            [-91.320742, 32.202454999148721],
            [-91.492718, 32.203348999148695],
            [-91.557199, 32.249653999148038]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "001",
        COUNTYNS: "00198116",
        AFFGEOID: "0500000US08001",
        GEOID: "08001",
        NAME: "Adams",
        LSAD: "06",
        ALAND: 3023220812,
        AWATER: 41815517
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.052892, 39.914223999071318],
            [-105.015845, 39.981192999070927],
            [-104.997423, 39.981212999070941],
            [-104.99725, 39.968528999070998],
            [-104.988159, 39.968465999071014],
            [-104.961405, 40.000336999070811],
            [-104.489757, 40.000612999070825],
            [-104.150332, 40.000855999070822],
            [-103.705701, 40.001365999070806],
            [-103.706547, 39.739893999072329],
            [-104.526958, 39.738668999072345],
            [-104.634448, 39.739593999072341],
            [-104.659953, 39.740476999072321],
            [-104.884646, 39.740155999072336],
            [-104.734601, 39.769180999072162],
            [-104.76247, 39.822979999071855],
            [-104.619938, 39.822420999071845],
            [-104.600435, 39.899458999071406],
            [-104.64037, 39.906240999071358],
            [-104.73155, 39.898610999071401],
            [-104.79091, 39.798249999071984],
            [-105.053246, 39.791062999072032],
            [-105.052892, 39.914223999071318]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "027",
        COUNTYNS: "00882231",
        AFFGEOID: "0500000US34027",
        GEOID: "34027",
        NAME: "Morris",
        LSAD: "06",
        ALAND: 1192765238,
        AWATER: 54376835
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.850513, 40.803955999066631],
            [-74.769425, 40.910933999066124],
            [-74.711562, 40.899176999066192],
            [-74.636163, 40.942638999065984],
            [-74.641044, 40.964425999065888],
            [-74.535808, 41.058485999065461],
            [-74.503212, 41.085872999065344],
            [-74.497777, 41.034260999065573],
            [-74.447017, 41.054428999065486],
            [-74.43504, 41.020020999065629],
            [-74.362826, 41.013126999065662],
            [-74.298851, 41.002912999065735],
            [-74.272063, 40.897426999066205],
            [-74.326114, 40.908604999066149],
            [-74.367145, 40.761111999066834],
            [-74.371738, 40.739639999066945],
            [-74.380314, 40.727560999067009],
            [-74.443762, 40.688037999067198],
            [-74.461035, 40.673503999067265],
            [-74.523078, 40.649124999067382],
            [-74.513717, 40.697749999067149],
            [-74.555991, 40.758157999066853],
            [-74.657258, 40.735214999066969],
            [-74.725666, 40.719531999067051],
            [-74.82388, 40.742357999066932],
            [-74.889819, 40.787728999066708],
            [-74.850513, 40.803955999066631]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "033",
        COUNTYNS: "01383802",
        AFFGEOID: "0500000US48033",
        GEOID: "48033",
        NAME: "Borden",
        LSAD: "06",
        ALAND: 2324366073,
        AWATER: 22297610
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.691284, 32.961837999138226],
            [-101.557434, 32.961024999138253],
            [-101.173378, 32.963596999138211],
            [-101.174562, 32.527702999144147],
            [-101.174571, 32.524111999144196],
            [-101.68874, 32.525221999144186],
            [-101.691284, 32.961837999138226]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "157",
        COUNTYNS: "00758533",
        AFFGEOID: "0500000US29157",
        GEOID: "29157",
        NAME: "Perry",
        LSAD: "06",
        ALAND: 1228575201,
        AWATER: 25393801
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.146778, 37.641617999087323],
            [-90.110537, 37.671339999087074],
            [-89.999923, 37.821522999085843],
            [-89.933095790915004, 37.880099057337766],
            [-89.923185, 37.870671999085452],
            [-89.851048, 37.90397999908518],
            [-89.782035, 37.855091999085566],
            [-89.696559, 37.814336999085903],
            [-89.687221380853089, 37.796406717525358],
            [-89.667993, 37.759483999086342],
            [-89.591289, 37.723598999086647],
            [-89.521948, 37.696474999086867],
            [-89.506563, 37.625049999087466],
            [-89.494051, 37.580115999087838],
            [-89.497745926039187, 37.569985913016104],
            [-89.501791049499801, 37.558895718411208],
            [-89.602828, 37.603224999087637],
            [-89.672802, 37.583760999087794],
            [-89.861123, 37.599285999087677],
            [-90.146763, 37.597433999087698],
            [-90.146778, 37.641617999087323]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "175",
        COUNTYNS: "01480171",
        AFFGEOID: "0500000US51175",
        GEOID: "51175",
        NAME: "Southampton",
        LSAD: "06",
        ALAND: 1551752840,
        AWATER: 8247158
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.430127, 36.707657999095574],
            [-76.953546, 36.944449999093386],
            [-76.849684, 36.996210999092916],
            [-76.817737, 36.882056999093955],
            [-76.924629, 36.707728999095579],
            [-76.952438, 36.708679999095558],
            [-76.96839, 36.700579999095638],
            [-76.9645, 36.678869999095845],
            [-76.947438, 36.675879999095869],
            [-76.962339, 36.66751499909595],
            [-76.934907, 36.659128999096033],
            [-76.909672, 36.648174999096128],
            [-76.897592, 36.644049999096183],
            [-76.930936, 36.559568999096967],
            [-76.915731802349697, 36.546089768435984],
            [-76.916037632856188, 36.546081329387405],
            [-76.917318, 36.546045999097096],
            [-77.164322603718205, 36.546152818944996],
            [-77.190175, 36.546163999097097],
            [-77.298770188811787, 36.546039009951599],
            [-77.355111, 36.554121999097021],
            [-77.414985, 36.654068999096076],
            [-77.501917, 36.670505999095909],
            [-77.430127, 36.707657999095574]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "158",
        COUNTYNS: "01419985",
        AFFGEOID: "0500000US02158",
        GEOID: "02158",
        NAME: "Kusilvak",
        LSAD: "05",
        ALAND: 44218384953,
        AWATER: 6697190751
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-166.211787, 61.608372999211618],
            [-166.143757, 61.72435199921371],
            [-166.050997, 61.766688999214473],
            [-166.094312, 61.813858999215334],
            [-165.940864, 61.849079999215988],
            [-165.803979, 61.825684999215554],
            [-165.639516, 61.847005999215938],
            [-165.650103, 61.874152999216442],
            [-165.743528, 61.96253299921807],
            [-165.754295, 62.055954999219786],
            [-165.672037, 62.139889999221346],
            [-165.458499, 62.282846999223999],
            [-165.26927, 62.427351999226715],
            [-165.096155, 62.522451999228501],
            [-165.052202, 62.598216999229948],
            [-164.962432, 62.658245999231085],
            [-164.837703, 62.685266999231608],
            [-164.864367, 62.752041999232887],
            [-164.87564, 62.806253999233924],
            [-164.813007, 62.903918999235792],
            [-164.685213, 63.022190999238084],
            [-164.607425, 63.112898999239853],
            [-164.442368, 63.202671999241609],
            [-164.209475, 63.251471999242554],
            [-164.066991, 63.262275999242775],
            [-163.885059, 63.222307999241998],
            [-163.73265, 63.213256999241807],
            [-163.616272, 63.141212999240409],
            [-163.529938, 63.135399999240299],
            [-163.316203, 63.037762999238389],
            [-163.053996, 63.058333999238791],
            [-162.919727, 63.120152999239998],
            [-162.844559, 63.154190999240654],
            [-162.821122, 63.205595999241666],
            [-162.72408, 63.214614999241832],
            [-162.602860986156998, 63.277182502712066],
            [-162.383082, 63.271815999242961],
            [-162.383076, 63.185194999241254],
            [-162.192503, 63.185183999241261],
            [-162.192443, 63.098555999239558],
            [-162.001798, 63.098535999239559],
            [-162.001769, 63.011918999237899],
            [-160.85799, 63.011952999237884],
            [-160.94344, 62.999268999237643],
            [-160.996215, 62.898935999235697],
            [-160.996201, 62.552423999229077],
            [-161.048176, 62.55240999922907],
            [-161.048165, 62.205868999222567],
            [-160.915964, 62.205865999222567],
            [-160.915953, 61.945942999217749],
            [-160.534671, 61.945939999217764],
            [-160.469477, 61.869347999216345],
            [-160.18666, 61.831692999215662],
            [-160.20263, 61.810321999215276],
            [-160.35014, 61.819070999215434],
            [-160.597733, 61.760155999214341],
            [-160.775244, 61.738526999213974],
            [-160.953961, 61.685668999213007],
            [-161.126812, 61.599539999211444],
            [-161.449611, 61.568837999210899],
            [-161.697058, 61.530086999210191],
            [-161.885286, 61.482809999209344],
            [-162.013438, 61.477056999209246],
            [-162.328294, 61.518375999209979],
            [-162.476189, 61.503958999209729],
            [-162.505761, 61.55642599921066],
            [-162.75398, 61.542665999210428],
            [-162.847199, 61.498735999209622],
            [-163.061999, 61.479930999209301],
            [-163.171354, 61.449070999208736],
            [-163.431258, 61.484106999209374],
            [-163.531412, 61.401453999207881],
            [-163.524124, 61.355097999207054],
            [-163.65539, 61.335826999206702],
            [-163.785076, 61.36709699920727],
            [-163.99276, 61.371762999207363],
            [-163.981767, 61.243285999205085],
            [-163.920874, 61.210691999204499],
            [-164.017645, 61.192885999204179],
            [-164.121482, 61.126999999203022],
            [-164.201874, 61.151612999203465],
            [-164.211781, 61.082261999202252],
            [-164.146306, 61.074681999202106],
            [-164.154702, 61.022624999201192],
            [-164.234986, 60.988240999200585],
            [-164.529911, 60.940795999199779],
            [-164.616585, 60.979048999200423],
            [-164.797619, 60.982486999200489],
            [-165.108494992779015, 60.926575397822425],
            [-165.194945, 60.973899999200341],
            [-165.133937, 61.011249999200992],
            [-165.057842, 61.059745999201837],
            [-165.139403, 61.092945999202428],
            [-165.203757, 61.150340999203436],
            [-165.325552, 61.169305999203779],
            [-165.385437, 61.079573999202196],
            [-165.459236, 61.08342399920226],
            [-165.55514, 61.092673999202418],
            [-165.640289, 61.138065999203228],
            [-165.63288, 61.227964999204808],
            [-165.623317, 61.278430999205696],
            [-165.787442, 61.310062999206266],
            [-165.831365, 61.306718999206204],
            [-165.921194, 61.403079999207925],
            [-165.877104, 61.431148999208418],
            [-165.791085, 61.449851999208761],
            [-165.746352, 61.489303999209461],
            [-165.865668, 61.535045999210283],
            [-165.912496, 61.556199999210676],
            [-165.999535, 61.539719999210369],
            [-166.075398, 61.492979999209531],
            [-166.149577, 61.513287999209901],
            [-166.211787, 61.608372999211618]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "001",
        COUNTYNS: "00349113",
        AFFGEOID: "0500000US13001",
        GEOID: "13001",
        NAME: "Appling",
        LSAD: "06",
        ALAND: 1313488286,
        AWATER: 13263005
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.550714, 31.736333999155441],
            [-82.520251, 31.838387999153937],
            [-82.431362, 31.837992999153954],
            [-82.431531, 31.966181999152088],
            [-82.407245, 31.949487999152332],
            [-82.225042, 31.913073999152857],
            [-82.111818, 31.900486999153042],
            [-82.048582, 31.827074999154114],
            [-82.08015, 31.827393999154104],
            [-82.133013, 31.773403999154894],
            [-82.132794, 31.471261999159356],
            [-82.156101, 31.471630999159359],
            [-82.226585, 31.530698999158471],
            [-82.495384, 31.709630999155831],
            [-82.52142, 31.710795999155806],
            [-82.550714, 31.736333999155441]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "179",
        COUNTYNS: "01785094",
        AFFGEOID: "0500000US17179",
        GEOID: "17179",
        NAME: "Tazewell",
        LSAD: "06",
        ALAND: 1674232803,
        AWATER: 29822303
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.885235, 40.492479999068173],
            [-89.872463, 40.513126999068064],
            [-89.770412, 40.553148999067865],
            [-89.675404, 40.553937999067855],
            [-89.592157, 40.685014999067214],
            [-89.553374, 40.706502999067105],
            [-89.554994, 40.747636999066906],
            [-89.330167, 40.748256999066896],
            [-89.327343, 40.615565999067549],
            [-89.26939, 40.59432899906767],
            [-89.26374, 40.325343999069041],
            [-89.602979, 40.32012899906907],
            [-89.714927, 40.319217999069075],
            [-89.717104, 40.435654999068454],
            [-89.92468, 40.435920999068465],
            [-89.885235, 40.492479999068173]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "149",
        COUNTYNS: "00485038",
        AFFGEOID: "0500000US20149",
        GEOID: "20149",
        NAME: "Pottawatomie",
        LSAD: "06",
        ALAND: 2177617398,
        AWATER: 54039076
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.706722, 39.461532999074045],
            [-96.580362, 39.566331999073384],
            [-96.238969, 39.56621999907339],
            [-96.035952, 39.566099999073387],
            [-96.035573, 39.216519999075601],
            [-96.035665, 39.144082999076069],
            [-96.03906, 39.126526999076205],
            [-96.083557, 39.193503999075766],
            [-96.233671, 39.212278999075636],
            [-96.331396, 39.158884999075987],
            [-96.389046, 39.172877999075894],
            [-96.557892, 39.181973999075829],
            [-96.562342, 39.24134099907544],
            [-96.638605, 39.29282699907511],
            [-96.726667, 39.403600999074385],
            [-96.706722, 39.461532999074045]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "069",
        COUNTYNS: "01074047",
        AFFGEOID: "0500000US39069",
        GEOID: "39069",
        NAME: "Henry",
        LSAD: "06",
        ALAND: 1077461474,
        AWATER: 9687645
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.341902, 41.485518999063665],
            [-83.882943, 41.487542999063649],
            [-83.883234, 41.414502999063942],
            [-83.881149, 41.167823999064979],
            [-84.228222, 41.165861999064994],
            [-84.228454, 41.427809999063889],
            [-84.341664, 41.427567999063903],
            [-84.341902, 41.485518999063665]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "455",
        COUNTYNS: "01384013",
        AFFGEOID: "0500000US48455",
        GEOID: "48455",
        NAME: "Trinity",
        LSAD: "06",
        ALAND: 1796914248,
        AWATER: 52267270
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.434781, 31.058097999165632],
            [-94.95811, 31.386929999160628],
            [-94.860501, 31.234954999162913],
            [-94.842947, 31.146577999164265],
            [-94.939127, 31.042047999165863],
            [-95.20018, 30.824565999169238],
            [-95.252385, 30.906718999167968],
            [-95.32748, 30.859546999168689],
            [-95.396697, 30.857705999168726],
            [-95.434335, 30.937404999167484],
            [-95.434781, 31.058097999165632]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "053",
        COUNTYNS: "01690739",
        AFFGEOID: "0500000US51053",
        GEOID: "51053",
        NAME: "Dinwiddie",
        LSAD: "06",
        ALAND: 1305041272,
        AWATER: 9004891
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.900338, 37.14388199909159],
            [-77.795926, 37.192591999091171],
            [-77.747693, 37.192728999091173],
            [-77.65061, 37.265111999090522],
            [-77.521624, 37.21699399909096],
            [-77.447408, 37.223065999090906],
            [-77.42347, 37.165094999091416],
            [-77.399203, 37.170849999091359],
            [-77.394645, 37.035733999092564],
            [-77.398498, 36.99298499909294],
            [-77.619029, 36.877843999093983],
            [-77.658036, 36.894513999093853],
            [-77.839834, 36.995911999092925],
            [-77.889813, 36.98881799909298],
            [-77.900338, 37.14388199909159]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "077",
        COUNTYNS: "01008556",
        AFFGEOID: "0500000US37077",
        GEOID: "37077",
        NAME: "Granville",
        LSAD: "06",
        ALAND: 1377849157,
        AWATER: 14503651
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.802335, 36.235793999100103],
            [-78.796273955140492, 36.541759492678743],
            [-78.734122801117195, 36.541608733937935],
            [-78.509965, 36.541064999097145],
            [-78.457277896255889, 36.541448707496627],
            [-78.497783, 36.514476999097397],
            [-78.511224, 36.454807999097966],
            [-78.496614, 36.175198999100715],
            [-78.544812, 36.080845999101648],
            [-78.546414, 36.021825999102241],
            [-78.683224, 36.074483999101709],
            [-78.751273, 36.070833999101737],
            [-78.807484, 36.087294999101587],
            [-78.802335, 36.235793999100103]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "067",
        COUNTYNS: "01494434",
        AFFGEOID: "0500000US51067",
        GEOID: "51067",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1788431417,
        AWATER: 54655591
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.134619, 36.964654999093199],
            [-80.128674, 37.122900999091783],
            [-80.082765, 37.160907999091442],
            [-79.961627, 37.137541999091653],
            [-79.847217, 37.225405999090874],
            [-79.718275, 37.189719999091196],
            [-79.670485, 37.149620999091546],
            [-79.594069, 37.042205999092502],
            [-79.640652, 36.856138999094192],
            [-79.669153, 36.827837999094456],
            [-79.741417, 36.806805999094649],
            [-79.999437, 36.830617999094414],
            [-80.041493, 36.794364999094768],
            [-80.066652, 36.841603999094325],
            [-80.235455, 36.872129999094049],
            [-80.134619, 36.964654999093199]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "003",
        COUNTYNS: "01804481",
        AFFGEOID: "0500000US72003",
        GEOID: "72003",
        NAME: "Aguada",
        LSAD: "13",
        ALAND: 79923633,
        AWATER: 38025989
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.239128357404596, 18.373827544626916],
            [-67.226744, 18.378246999434793],
            [-67.160169877874694, 18.415599746577108],
            [-67.13221, 18.3893909994345],
            [-67.126651, 18.371446999434976],
            [-67.130167, 18.317926999436388],
            [-67.182852, 18.313025999436523],
            [-67.227336, 18.348765999435571],
            [-67.239128357404596, 18.373827544626916]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "191",
        COUNTYNS: "00481812",
        AFFGEOID: "0500000US20191",
        GEOID: "20191",
        NAME: "Sumner",
        LSAD: "06",
        ALAND: 3060416043,
        AWATER: 8602566
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.8076, 37.474183999088723],
            [-97.153331, 37.475543999088714],
            [-97.147720966922691, 36.998972334714388],
            [-97.384925, 36.998842999092894],
            [-97.462346029864094, 36.998824237889799],
            [-97.768704, 36.998749999092901],
            [-97.8023129703503, 36.998698608486897],
            [-97.804337, 37.366068999089656],
            [-97.807057, 37.386292999089484],
            [-97.8076, 37.474183999088723]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "277",
        COUNTYNS: "00344090",
        AFFGEOID: "0500000US13277",
        GEOID: "13277",
        NAME: "Tift",
        LSAD: "06",
        ALAND: 675662341,
        AWATER: 20498270
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.665777, 31.485134999159154],
            [-83.650566, 31.490337999159074],
            [-83.649384, 31.567976999157921],
            [-83.648972, 31.596465999157498],
            [-83.500702, 31.593989999157539],
            [-83.500951, 31.570116999157889],
            [-83.42084, 31.553564999158134],
            [-83.338728, 31.475990999159283],
            [-83.369514, 31.465054999159456],
            [-83.370888, 31.401141999160409],
            [-83.434492, 31.350360999161179],
            [-83.460644, 31.326262999161539],
            [-83.512607, 31.327404999161519],
            [-83.65409, 31.330660999161466],
            [-83.651764, 31.433917999159924],
            [-83.665777, 31.485134999159154]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "061",
        COUNTYNS: "01213672",
        AFFGEOID: "0500000US42061",
        GEOID: "42061",
        NAME: "Huntingdon",
        LSAD: "06",
        ALAND: 2265337403,
        AWATER: 37851955
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.245798, 40.318631999069076],
            [-78.191046, 40.391869999068696],
            [-78.130081, 40.481989999068226],
            [-78.197083, 40.55844399906784],
            [-78.166371, 40.617947999067546],
            [-78.22197, 40.674538999067252],
            [-78.116521, 40.739126999066947],
            [-77.94425, 40.691556999067174],
            [-77.824506, 40.743680999066925],
            [-77.681334, 40.729757999067004],
            [-77.68708, 40.676749999067248],
            [-77.841827, 40.550034999067869],
            [-77.816513, 40.500073999068128],
            [-77.913844, 40.398678999068657],
            [-77.76172, 40.368732999068811],
            [-77.75212, 40.378544999068751],
            [-77.70303, 40.263575999069374],
            [-77.768325, 40.204128999069681],
            [-77.864489, 40.061567999070476],
            [-77.92556, 40.103976999070227],
            [-78.134863, 40.165130999069902],
            [-78.236302, 40.224558999069579],
            [-78.257305, 40.297882999069188],
            [-78.245798, 40.318631999069076]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "037",
        COUNTYNS: "01135854",
        AFFGEOID: "0500000US41037",
        GEOID: "41037",
        NAME: "Lake",
        LSAD: "06",
        ALAND: 21078875989,
        AWATER: 568514572
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.332413, 43.356662999058223],
            [-121.332969, 43.616628999057788],
            [-120.377752, 43.615945999057793],
            [-120.35095, 43.611599999057802],
            [-119.896581, 43.611163999057787],
            [-119.896969, 43.179016999058568],
            [-119.932611, 43.178962999058562],
            [-119.931595, 42.917004999059138],
            [-119.943779, 42.74637099905955],
            [-119.365337, 42.748957999059549],
            [-119.360123119603003, 41.99409225404299],
            [-119.72573, 41.996295999061779],
            [-119.999168, 41.994539999061779],
            [-120.181563, 41.994587999061793],
            [-120.501069, 41.993784999061788],
            [-120.692219, 41.993676999061783],
            [-120.879925710235995, 41.993483259046691],
            [-120.883327, 42.744213999059568],
            [-121.349679, 42.746643999059557],
            [-121.332413, 43.356662999058223]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "077",
        COUNTYNS: "00758493",
        AFFGEOID: "0500000US29077",
        GEOID: "29077",
        NAME: "Greene",
        LSAD: "06",
        ALAND: 1749069420,
        AWATER: 6631777
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.621153, 37.427422999089124],
            [-93.182489, 37.417203999089217],
            [-93.07338, 37.414988999089225],
            [-93.076779, 37.270039999090486],
            [-93.062142, 37.235116999090792],
            [-93.065274, 37.088693999092072],
            [-93.608899, 37.098152999092001],
            [-93.605113, 37.280252999090393],
            [-93.625844, 37.282010999090382],
            [-93.621153, 37.427422999089124]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "047",
        COUNTYNS: "01135859",
        AFFGEOID: "0500000US41047",
        GEOID: "41047",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 3057624577,
        AWATER: 26554204
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.110356, 44.930696999056757],
            [-123.044903, 44.944027999056765],
            [-123.068667, 45.07485999905677],
            [-122.996693, 45.11706299905677],
            [-123.04117, 45.22078799905681],
            [-122.968666, 45.284728999056831],
            [-122.849925, 45.259696999056821],
            [-122.73903, 45.259358999056815],
            [-122.787007, 45.128624999056775],
            [-122.705115, 45.059022999056765],
            [-122.595523, 45.019678999056772],
            [-122.535999, 44.937160999056765],
            [-122.401021, 44.885719999056761],
            [-121.734223, 44.885719999056761],
            [-121.752994, 44.829918999056773],
            [-121.816308, 44.80877399905679],
            [-121.759748, 44.761934999056784],
            [-121.800015, 44.683424999056804],
            [-122.033006, 44.685690999056803],
            [-122.138702, 44.725178999056794],
            [-122.230559, 44.697866999056792],
            [-122.276675, 44.746134999056792],
            [-122.480122, 44.755818999056778],
            [-122.620463, 44.78921299905678],
            [-122.690864, 44.775416999056787],
            [-122.802457, 44.790863999056768],
            [-123.006847, 44.686897999056804],
            [-123.042009, 44.737718999056789],
            [-123.143843, 44.748913999056782],
            [-123.093335, 44.814043999056764],
            [-123.180908, 44.857552999056765],
            [-123.110356, 44.930696999056757]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "023",
        COUNTYNS: "01804491",
        AFFGEOID: "0500000US72023",
        GEOID: "72023",
        NAME: "Cabo Rojo",
        LSAD: "13",
        ALAND: 182264459,
        AWATER: 277228652
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.209887, 18.035438999443883],
            [-67.199314, 18.091134999442396],
            [-67.180822, 18.168054999440358],
            [-67.180754718857003, 18.168198471222556],
            [-67.159733, 18.153226999440754],
            [-67.126157, 18.115142999441765],
            [-67.100492, 18.106394999441996],
            [-67.109044, 18.056084999443335],
            [-67.115765, 18.031461999443987],
            [-67.107809434276305, 17.951623192348521],
            [-67.133733, 17.951918999446111],
            [-67.183457, 17.931134999446662],
            [-67.211973, 17.992992999445015],
            [-67.209887, 18.035438999443883]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "067",
        COUNTYNS: "00394807",
        AFFGEOID: "0500000US16067",
        GEOID: "16067",
        NAME: "Minidoka",
        LSAD: "06",
        ALAND: 1960659281,
        AWATER: 13928460
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.931687, 42.76502599905951],
            [-113.763862, 42.764507999059511],
            [-113.714044, 42.849733999059296],
            [-113.714642, 43.199782999058527],
            [-113.413867, 43.199858999058527],
            [-113.413138, 42.849168999059295],
            [-113.472155, 42.849200999059299],
            [-113.472177, 42.669351999059742],
            [-113.557193, 42.656798999059795],
            [-113.654149, 42.536154999060116],
            [-113.699044, 42.51918999906016],
            [-113.778137, 42.556423999060065],
            [-113.931799, 42.535242999060117],
            [-113.931687, 42.76502599905951]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "067",
        COUNTYNS: "01209180",
        AFFGEOID: "0500000US42067",
        GEOID: "42067",
        NAME: "Juniata",
        LSAD: "06",
        ALAND: 1013592887,
        AWATER: 5606074
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.75212, 40.378544999068751],
            [-77.497506, 40.581739999067707],
            [-77.490021, 40.614791999067556],
            [-77.287941, 40.693594999067166],
            [-77.109597, 40.691577999067171],
            [-76.939749, 40.63837799906743],
            [-76.949414, 40.628162999067492],
            [-77.182789, 40.582210999067719],
            [-77.540141, 40.399220999068646],
            [-77.671761, 40.289824999069225],
            [-77.70303, 40.263575999069374],
            [-77.75212, 40.378544999068751]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "097",
        COUNTYNS: "01784796",
        AFFGEOID: "0500000US17097",
        GEOID: "17097",
        NAME: "Lake",
        LSAD: "06",
        ALAND: 1149926934,
        AWATER: 2394188156
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.198683, 42.241670999060979],
            [-88.198314, 42.241611999060972],
            [-88.198647, 42.328860999060723],
            [-88.198601, 42.415566999060459],
            [-88.199529001448497, 42.495756014750533],
            [-87.897998770543495, 42.49285745844044],
            [-87.800477, 42.491919999060244],
            [-87.80337, 42.420620999060446],
            [-87.820858, 42.36158399906062],
            [-87.834769, 42.30152199906081],
            [-87.833377771567015, 42.297773704215309],
            [-87.800646109044294, 42.209586948530088],
            [-87.800066, 42.208023999061091],
            [-87.759326565238595, 42.152361978041156],
            [-87.946149, 42.153172999061255],
            [-88.004466, 42.153644999061257],
            [-88.02375, 42.153734999061264],
            [-88.199584, 42.15425999906126],
            [-88.198683, 42.241670999060979]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "161",
        COUNTYNS: "00450395",
        AFFGEOID: "0500000US18161",
        GEOID: "18161",
        NAME: "Union",
        LSAD: "06",
        ALAND: 417568563,
        AWATER: 10238515
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.034575, 39.714763999072481],
            [-85.022528, 39.729178999072388],
            [-84.814128137932784, 39.726616510691713],
            [-84.814129, 39.726555999072417],
            [-84.815705943255495, 39.567721663100777],
            [-84.816160194910793, 39.521968108066559],
            [-85.011882, 39.524297999073646],
            [-85.036087, 39.526212999073636],
            [-85.034575, 39.714763999072481]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "071",
        COUNTYNS: "01209181",
        AFFGEOID: "0500000US42071",
        GEOID: "42071",
        NAME: "Lancaster",
        LSAD: "06",
        ALAND: 2444600820,
        AWATER: 103429249
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.697664, 40.156341999069951],
            [-76.678137, 40.148733999069989],
            [-76.566339, 40.196643999069735],
            [-76.466307, 40.228399999069559],
            [-76.307578, 40.255065999069416],
            [-76.151215, 40.315970999069094],
            [-76.091764, 40.27769199906929],
            [-76.043993, 40.247195999069454],
            [-75.873375, 40.137106999070056],
            [-75.94244, 40.106514999070214],
            [-75.93587, 40.037465999070605],
            [-75.994629, 39.952428999071088],
            [-75.987846, 39.872114999071556],
            [-76.017384, 39.833487999071778],
            [-76.049293, 39.802002999071959],
            [-76.102479, 39.724032999072428],
            [-76.135697426990191, 39.721767549679136],
            [-76.233279347116593, 39.721646254700339],
            [-76.233485, 39.72164599907245],
            [-76.239683741114604, 39.72164198899705],
            [-76.47358, 39.947351999071117],
            [-76.534927, 40.051358999070537],
            [-76.663825, 40.063226999070459],
            [-76.72162, 40.120069999070147],
            [-76.697664, 40.156341999069951]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "221",
        COUNTYNS: "00758563",
        AFFGEOID: "0500000US29221",
        GEOID: "29221",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1968056216,
        AWATER: 6778977
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.095765, 38.204082999082814],
            [-90.970187, 38.206686999082798],
            [-90.780185, 38.204111999082812],
            [-90.684547, 38.086310999083722],
            [-90.63998, 38.0765479990838],
            [-90.645135, 37.734812999086543],
            [-91.100017, 37.740011999086498],
            [-91.095765, 38.204082999082814]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "103",
        COUNTYNS: "00758506",
        AFFGEOID: "0500000US29103",
        GEOID: "29103",
        NAME: "Knox",
        LSAD: "06",
        ALAND: 1305376708,
        AWATER: 7307012
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.347801, 40.302755999069156],
            [-91.949747, 40.300831999069167],
            [-91.950812, 40.257209999069403],
            [-91.947241, 40.213798999069631],
            [-91.952726, 39.949425999071103],
            [-92.287882, 39.952489999071098],
            [-92.344708, 39.953296999071092],
            [-92.345064, 40.037209999070612],
            [-92.347801, 40.302755999069156]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "117",
        COUNTYNS: "00758513",
        AFFGEOID: "0500000US29117",
        GEOID: "29117",
        NAME: "Livingston",
        LSAD: "06",
        ALAND: 1378706292,
        AWATER: 16156752
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.761973, 39.959551999071053],
            [-93.361957, 39.967605999071004],
            [-93.364808, 39.703802999072558],
            [-93.266968, 39.703561999072548],
            [-93.261386, 39.623252999073031],
            [-93.278098, 39.615631999073081],
            [-93.758357, 39.611406999073111],
            [-93.758992, 39.784673999072062],
            [-93.761973, 39.959551999071053]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "087",
        COUNTYNS: "01213679",
        AFFGEOID: "0500000US42087",
        GEOID: "42087",
        NAME: "Mifflin",
        LSAD: "06",
        ALAND: 1064602238,
        AWATER: 9507398
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.816513, 40.500073999068128],
            [-77.841827, 40.550034999067869],
            [-77.68708, 40.676749999067248],
            [-77.681334, 40.729757999067004],
            [-77.652574, 40.744920999066927],
            [-77.36418, 40.846936999066422],
            [-77.356628, 40.807333999066614],
            [-77.354097, 40.701666999067129],
            [-77.287941, 40.693594999067166],
            [-77.490021, 40.614791999067556],
            [-77.497506, 40.581739999067707],
            [-77.75212, 40.378544999068751],
            [-77.76172, 40.368732999068811],
            [-77.913844, 40.398678999068657],
            [-77.816513, 40.500073999068128]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "161",
        COUNTYNS: "00659525",
        AFFGEOID: "0500000US27161",
        GEOID: "27161",
        NAME: "Waseca",
        LSAD: "06",
        ALAND: 1096505111,
        AWATER: 24893716
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.767971, 44.195835999057088],
            [-93.525231, 44.196133999057089],
            [-93.406565, 44.196376999057094],
            [-93.406642, 43.848119999057459],
            [-93.64829, 43.848138999057454],
            [-93.767761, 43.847955999057461],
            [-93.767971, 44.195835999057088]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "016",
        COUNTYNS: "01419965",
        AFFGEOID: "0500000US02016",
        GEOID: "02016",
        NAME: "Aleutians West",
        LSAD: "05",
        ALAND: 11376643262,
        AWATER: 25184877501
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [179.481318, 51.975300999084951],
              [179.582857, 52.016840999085289],
              [179.636846, 52.02571199908536],
              [179.773922, 51.970692999084918],
              [179.743012, 51.911748999084452],
              [179.649484, 51.87366999908415],
              [179.543516, 51.890925999084288],
              [179.484634, 51.921267999084527],
              [179.481318, 51.975300999084951]
            ]
          ],
          [
            [
              [178.600493, 51.655255999082456],
              [178.6606, 51.683064999082667],
              [178.92533, 51.623903999082223],
              [179.195247, 51.477870999081127],
              [179.295785, 51.419231999080679],
              [179.41824, 51.416194999080659],
              [179.480418, 51.363859999080283],
              [179.253271, 51.337238999080085],
              [179.031533, 51.449883999080917],
              [178.930219, 51.530088999081507],
              [178.869249, 51.556985999081711],
              [178.7724, 51.554118999081695],
              [178.604866, 51.616014999082154],
              [178.600493, 51.655255999082456]
            ]
          ],
          [
            [
              [178.463385, 51.987848999085053],
              [178.552612, 51.973967999084941],
              [178.591597, 51.952651999084772],
              [178.539395, 51.903245999084383],
              [178.502493, 51.899643999084354],
              [178.432461, 51.965532999084878],
              [178.463385, 51.987848999085053]
            ]
          ],
          [
            [
              [178.204442, 51.830889999083809],
              [178.329699, 51.83679199908385],
              [178.378009, 51.792625999083519],
              [178.374074, 51.747855999083171],
              [178.270955, 51.7651859990833],
              [178.204442, 51.830889999083809]
            ]
          ],
          [
            [
              [178.093673, 52.055140999085587],
              [178.154769, 52.061420999085641],
              [178.201313, 52.031501999085393],
              [178.2009, 51.99115599908508],
              [178.120941, 51.977011999084965],
              [178.079214, 52.018959999085304],
              [178.093673, 52.055140999085587]
            ]
          ],
          [
            [
              [177.213086, 51.920357999084516],
              [177.310827, 51.933272999084622],
              [177.367363, 51.968374999084894],
              [177.460539, 51.999750999085144],
              [177.521006, 52.063061999085654],
              [177.581271, 52.14492699908633],
              [177.648654, 52.130899999086218],
              [177.675952, 52.09216699908589],
              [177.609087, 52.028517999085381],
              [177.572068, 52.001811999085163],
              [177.611553, 51.950828999084763],
              [177.601005, 51.922253999084539],
              [177.49928, 51.922032999084536],
              [177.409536, 51.930820999084595],
              [177.371263, 51.901944999084371],
              [177.334229, 51.866768999084101],
              [177.311768, 51.825970999083772],
              [177.262195, 51.861890999084061],
              [177.178789, 51.879218999084195],
              [177.213086, 51.920357999084516]
            ]
          ],
          [
            [
              [173.863992, 52.792482999091845],
              [174.067293, 52.757636999091545],
              [174.140115, 52.750736999091487],
              [174.158146, 52.706058999091084],
              [173.975116, 52.707458999091102],
              [173.819039, 52.759804999091564],
              [173.863992, 52.792482999091845]
            ]
          ],
          [
            [
              [173.439026, 52.470527999089043],
              [173.555739, 52.479471999089114],
              [173.638061, 52.524208999089502],
              [173.772799, 52.509904999089386],
              [173.702252, 52.434803999088743],
              [173.748301, 52.392345999088384],
              [173.725696, 52.356578999088079],
              [173.651293, 52.356369999088081],
              [173.543778, 52.392665999088386],
              [173.48638, 52.368612999088178],
              [173.319948, 52.412057999088546],
              [173.439026, 52.470527999089043]
            ]
          ],
          [
            [
              [172.458911, 52.9545479990933],
              [172.643266, 53.004978999093758],
              [172.792872, 53.008567999093806],
              [173.121988, 52.990351999093619],
              [173.251326, 52.944361999093218],
              [173.425362, 52.868344999092535],
              [173.423819, 52.828798999092172],
              [173.284417, 52.827932999092162],
              [173.204948, 52.848910999092361],
              [173.166899, 52.79522899909189],
              [173.096237, 52.786781999091794],
              [172.9826, 52.791079999091842],
              [172.903628, 52.761666999091574],
              [172.809387, 52.789289999091828],
              [172.763366, 52.823655999092132],
              [172.754236, 52.877489999092596],
              [172.669943, 52.913010999092933],
              [172.585075, 52.921326999093019],
              [172.472857, 52.890233999092729],
              [172.458911, 52.9545479990933]
            ]
          ],
          [
            [
              [-167.790928, 53.335519999096839],
              [-167.694484, 53.388033999097345],
              [-167.591219, 53.393345999097392],
              [-167.457366, 53.442792999097861],
              [-167.369791, 53.450645999097944],
              [-167.278827, 53.478564999098211],
              [-167.188033, 53.524072999098657],
              [-167.135695, 53.551226999098915],
              [-167.16164, 53.605908999099441],
              [-167.107836, 53.63305599909971],
              [-167.071823, 53.665559999100047],
              [-167.041245, 53.707928999100453],
              [-167.005778, 53.755445999100921],
              [-167.098135, 53.799986999101364],
              [-167.141966, 53.826931999101639],
              [-167.140992, 53.866773999102044],
              [-167.031252, 53.945203999102837],
              [-166.879488, 53.98871599910327],
              [-166.742587, 54.015500999103558],
              [-166.644627, 54.014494999103533],
              [-166.587393, 53.959830999102977],
              [-166.508388, 53.923948999102613],
              [-166.437083, 53.955643999102939],
              [-166.357117, 54.002342999103419],
              [-166.264519, 53.977549999103175],
              [-166.225644, 53.986229104188254],
              [-166.225641, 53.960205999102982],
              [-166.160924652198986, 53.935638051296628],
              [-166.210685, 53.915921999102537],
              [-166.250935, 53.876850999102139],
              [-166.320004, 53.869526999102064],
              [-166.404896, 53.809344999101462],
              [-166.336768, 53.787089999101255],
              [-166.198751, 53.836099999101734],
              [-166.113037, 53.853715999101908],
              [-166.09753, 53.826932999101643],
              [-166.138657, 53.731081999100688],
              [-166.244056, 53.710707999100478],
              [-166.320262, 53.674275999100125],
              [-166.444909, 53.640645999099796],
              [-166.508982, 53.583799999099234],
              [-166.581011, 53.530448999098716],
              [-166.656234, 53.487118999098293],
              [-166.749158, 53.440943999097854],
              [-166.878087, 53.429883999097733],
              [-166.994329, 53.429200999097738],
              [-167.075386, 53.424978999097704],
              [-167.166348, 53.412792999097583],
              [-167.291831, 53.364101999097116],
              [-167.308126, 53.33432999909683],
              [-167.417713, 53.3298559990968],
              [-167.488215, 53.269120999096216],
              [-167.539247, 53.277863999096304],
              [-167.622173, 53.250361999096043],
              [-167.747754, 53.273563999096261],
              [-167.851511, 53.308667999096592],
              [-167.790928, 53.335519999096839]
            ]
          ],
          [
            [
              [-169.28652, 52.78474699909178],
              [-169.044466, 52.893926999092756],
              [-168.95946, 52.936738999093151],
              [-168.861061, 53.016383999093868],
              [-168.785236, 53.045037999094141],
              [-168.804901, 53.12001499909482],
              [-168.763331, 53.182811999095414],
              [-168.581891, 53.286520999096382],
              [-168.445083, 53.265329999096181],
              [-168.420521, 53.322742999096718],
              [-168.395355, 53.397775999097441],
              [-168.342127, 53.475991999098191],
              [-168.238321, 53.521901999098624],
              [-168.027006, 53.56275499909902],
              [-167.914669, 53.522715999098637],
              [-167.789164, 53.519328999098605],
              [-167.808117, 53.473860999098171],
              [-167.856837, 53.428608999097726],
              [-167.842328, 53.386488999097324],
              [-167.959096, 53.341787999096908],
              [-168.092011, 53.288269999096393],
              [-168.296229, 53.227234999095828],
              [-168.343075, 53.170552999095293],
              [-168.412522, 53.110682999094742],
              [-168.457103, 53.055838999094235],
              [-168.613964, 53.008775999093807],
              [-168.688468, 52.966399999093412],
              [-168.755531, 52.907506999092874],
              [-168.851017, 52.908039999092885],
              [-169.005038, 52.829991999092186],
              [-169.170371, 52.776662999091712],
              [-169.261765, 52.754896999091521],
              [-169.28652, 52.78474699909178]
            ]
          ],
          [
            [
              [-169.81831, 56.633611999134047],
              [-169.613691, 56.622760999133902],
              [-169.474322, 56.625182999133941],
              [-169.453786, 56.58378599913339],
              [-169.582624, 56.536938999132786],
              [-169.685825, 56.539716999132828],
              [-169.81831, 56.633611999134047]
            ]
          ],
          [
            [
              [-170.170683, 52.784917999091782],
              [-170.092221, 52.919386999092993],
              [-170.026342, 52.944911999093215],
              [-169.857567, 52.908532999092891],
              [-169.76274, 52.978049999093521],
              [-169.820198, 53.066789999094333],
              [-169.747457, 53.09319999909458],
              [-169.680033, 53.03507499909405],
              [-169.662385, 52.951751999093283],
              [-169.666512, 52.864348999092492],
              [-169.703873, 52.77711699909171],
              [-169.818548, 52.791576999091845],
              [-169.951498, 52.78861499909182],
              [-170.077734, 52.720415999091223],
              [-170.207887, 52.708898999091105],
              [-170.170683, 52.784917999091782]
            ]
          ],
          [
            [
              [-170.420047, 57.212916999141783],
              [-170.303091, 57.238028999142124],
              [-170.143996, 57.24280399914219],
              [-170.133884, 57.181328999141357],
              [-170.286318, 57.128168999140627],
              [-170.421867, 57.16120199914107],
              [-170.420047, 57.212916999141783]
            ]
          ],
          [
            [
              [-170.817943, 52.636274999090482],
              [-170.671545, 52.698081999091016],
              [-170.532144, 52.679970999090848],
              [-170.58496, 52.587185999090039],
              [-170.685914, 52.581227999089997],
              [-170.788495, 52.540239999089636],
              [-170.841936, 52.558170999089789],
              [-170.817943, 52.636274999090482]
            ]
          ],
          [
            [
              [-171.312658, 52.49350199908924],
              [-171.256768, 52.528579999089544],
              [-171.196013, 52.5001059990893],
              [-171.226729, 52.434268999088744],
              [-171.30417, 52.449951999088867],
              [-171.312658, 52.49350199908924]
            ]
          ],
          [
            [
              [-172.612274, 52.306827999087666],
              [-172.545116, 52.357862999088084],
              [-172.448182, 52.391438999088379],
              [-172.326444, 52.366471999088155],
              [-172.301445, 52.329950999087863],
              [-172.414419, 52.276739999087411],
              [-172.528095, 52.254335999087218],
              [-172.639992, 52.244764999087153],
              [-172.612274, 52.306827999087666]
            ]
          ],
          [
            [
              [-175.301556, 52.055601999085603],
              [-175.031213, 52.092108999085895],
              [-174.887242, 52.128601999086193],
              [-174.715205, 52.127374999086186],
              [-174.55467, 52.160404999086452],
              [-174.462962, 52.213030999086875],
              [-174.455979, 52.313689999087728],
              [-174.329818, 52.373547999088224],
              [-174.185347, 52.417787999088588],
              [-174.068248, 52.390330999088363],
              [-173.985203, 52.317599999087761],
              [-174.046994, 52.236261999087077],
              [-174.022638, 52.133712999086235],
              [-173.899966, 52.139948999086279],
              [-173.654404, 52.146191999086334],
              [-173.529923, 52.159363999086445],
              [-173.375229, 52.108227999086026],
              [-173.174403, 52.12627799908617],
              [-172.947811, 52.107370999086022],
              [-172.980222, 52.064048999085671],
              [-173.169557, 52.043849999085502],
              [-173.39397, 52.028673999085377],
              [-173.513047, 52.025309999085351],
              [-173.695316, 52.0553189990856],
              [-173.820692, 52.043311999085503],
              [-173.901075, 52.049434999085548],
              [-174.099836, 52.072077999085735],
              [-174.278279, 52.08948899908588],
              [-174.382661, 52.081657999085806],
              [-174.408693, 52.012810999085261],
              [-174.556278, 52.036732999085437],
              [-174.736592, 52.007307999085207],
              [-174.892306, 52.019686999085316],
              [-175.014807, 52.00699999908521],
              [-175.155673, 52.011511999085243],
              [-175.323322, 52.007487999085207],
              [-175.301556, 52.055601999085603]
            ]
          ],
          [
            [
              [-176.950128, 51.686718999082693],
              [-176.917088, 51.797015999083555],
              [-176.781889, 51.832372999083816],
              [-176.762478, 51.867877999084108],
              [-176.810433, 51.927088999084567],
              [-176.774023, 51.965894999084867],
              [-176.698771, 51.96445399908486],
              [-176.579975, 52.003237999085179],
              [-176.549119, 51.955560999084796],
              [-176.554661, 51.909833999084441],
              [-176.576381, 51.842274999083912],
              [-176.431673, 51.861168999084043],
              [-176.311573, 51.872462999084135],
              [-176.173871, 51.882448999084218],
              [-176.168643, 51.94802499908473],
              [-176.183023, 51.998903999085144],
              [-176.211188, 52.064705999085682],
              [-176.14951, 52.117569999086108],
              [-176.055983, 52.10946899908604],
              [-176.007589, 52.066227999085683],
              [-175.887514, 51.995141999085106],
              [-175.807848, 51.989664999085072],
              [-175.664274, 51.993861999085105],
              [-175.45047, 52.01274199908525],
              [-175.424859, 51.972331999084929],
              [-175.639739, 51.933644999084621],
              [-175.789118, 51.919322999084507],
              [-175.963041, 51.846252999083937],
              [-175.998464, 51.801541999083582],
              [-176.158185, 51.768900999083328],
              [-176.289921, 51.741677999083123],
              [-176.46705, 51.726667999082999],
              [-176.543948, 51.698718999082786],
              [-176.656005, 51.658305999082472],
              [-176.715424, 51.62042199908219],
              [-176.809, 51.616234999082145],
              [-176.938917, 51.590981999081961],
              [-176.987383, 51.606871999082088],
              [-176.950128, 51.686718999082693]
            ]
          ],
          [
            [
              [-177.670119, 51.743380999083136],
              [-177.492124, 51.770305999083334],
              [-177.313149, 51.778222999083404],
              [-177.228177, 51.803782999083602],
              [-177.199539, 51.910238999084442],
              [-177.181271, 51.943166999084696],
              [-177.099266, 51.936118999084648],
              [-177.04509, 51.898604999084348],
              [-177.098661, 51.829646999083806],
              [-177.105188, 51.719332999082951],
              [-177.18994, 51.697216999082769],
              [-177.275121, 51.680509999082652],
              [-177.34801, 51.696512999082771],
              [-177.483959, 51.682277999082658],
              [-177.651386, 51.653603999082442],
              [-177.707802, 51.703267999082826],
              [-177.670119, 51.743380999083136]
            ]
          ],
          [
            [
              [-178.19709, 51.905463999084397],
              [-178.090632, 51.919398999084514],
              [-177.952094, 51.915347999084474],
              [-177.887423, 51.850889999083975],
              [-177.757429, 51.847041999083942],
              [-177.615311, 51.855079999084005],
              [-177.649278, 51.801850999083591],
              [-177.755022, 51.772833999083353],
              [-177.827524, 51.712085999082902],
              [-177.86796, 51.679373999082642],
              [-177.909185, 51.596670999082015],
              [-178.04566, 51.630061999082258],
              [-178.117864, 51.677830999082623],
              [-178.054798, 51.704767999082833],
              [-177.98163, 51.715616999082911],
              [-177.995272, 51.781534999083433],
              [-178.086074, 51.808046999083629],
              [-178.224129, 51.864880999084079],
              [-178.19709, 51.905463999084397]
            ]
          ],
          [
            [
              [-178.889347, 51.570351999081808],
              [-178.678149, 51.626009999082235],
              [-178.551475, 51.610174999082112],
              [-178.584789, 51.563859999081757],
              [-178.734585, 51.542325999081598],
              [-178.825956, 51.547084999081633],
              [-178.889347, 51.570351999081808]
            ]
          ],
          [
            [
              [-178.876843, 51.837916999083859],
              [-178.779657, 51.851546999083979],
              [-178.733355, 51.78394699908344],
              [-178.792409, 51.746070999083152],
              [-178.895958, 51.779218999083412],
              [-178.876843, 51.837916999083859]
            ]
          ],
          [
            [
              [-179.174265, 51.279056999079657],
              [-178.995591, 51.414483999080645],
              [-178.926874, 51.38363999908043],
              [-178.908883, 51.340581999080115],
              [-179.07232, 51.250962999079469],
              [-179.126856, 51.219861999079235],
              [-179.174265, 51.279056999079657]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "050",
        COUNTYNS: "01419966",
        AFFGEOID: "0500000US02050",
        GEOID: "02050",
        NAME: "Bethel",
        LSAD: "05",
        ALAND: 105228855314,
        AWATER: 12768607553
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-165.367676, 60.581157999193572],
              [-165.206433, 60.610226999194047],
              [-165.073091, 60.684216999195328],
              [-164.991665, 60.698839999195577],
              [-165.010452, 60.744788999196381],
              [-165.040843, 60.772659999196854],
              [-165.030183, 60.838049999197985],
              [-165.08509, 60.913762999199292],
              [-165.108494992779015, 60.926575397822425],
              [-164.797619, 60.982486999200489],
              [-164.616585, 60.979048999200423],
              [-164.529911, 60.940795999199779],
              [-164.234986, 60.988240999200585],
              [-164.154702, 61.022624999201192],
              [-164.146306, 61.074681999202106],
              [-164.211781, 61.082261999202252],
              [-164.201874, 61.151612999203465],
              [-164.121482, 61.126999999203022],
              [-164.017645, 61.192885999204179],
              [-163.920874, 61.210691999204499],
              [-163.981767, 61.243285999205085],
              [-163.99276, 61.371762999207363],
              [-163.785076, 61.36709699920727],
              [-163.65539, 61.335826999206702],
              [-163.524124, 61.355097999207054],
              [-163.531412, 61.401453999207881],
              [-163.431258, 61.484106999209374],
              [-163.171354, 61.449070999208736],
              [-163.061999, 61.479930999209301],
              [-162.847199, 61.498735999209622],
              [-162.75398, 61.542665999210428],
              [-162.505761, 61.55642599921066],
              [-162.476189, 61.503958999209729],
              [-162.328294, 61.518375999209979],
              [-162.013438, 61.477056999209246],
              [-161.885286, 61.482809999209344],
              [-161.697058, 61.530086999210191],
              [-161.449611, 61.568837999210899],
              [-161.126812, 61.599539999211444],
              [-160.953961, 61.685668999213007],
              [-160.775244, 61.738526999213974],
              [-160.597733, 61.760155999214341],
              [-160.35014, 61.819070999215434],
              [-160.20263, 61.810321999215276],
              [-160.18666, 61.831692999215662],
              [-160.469477, 61.869347999216345],
              [-160.534671, 61.945939999217764],
              [-159.265037, 61.945956999217756],
              [-159.265035, 62.032597999219348],
              [-158.531351, 62.032619999219364],
              [-158.531361, 62.119263999220948],
              [-157.063864, 62.119270999220959],
              [-157.063852, 62.032633999219357],
              [-156.276897, 62.032645999219362],
              [-155.497645, 62.03265899921935],
              [-154.537299, 62.03267499921936],
              [-153.761875, 62.032688999219353],
              [-153.761871, 62.11933499922096],
              [-153.395009, 62.119346999220966],
              [-153.395009, 62.20598999922256],
              [-153.070123, 62.206014999222575],
              [-153.07012, 62.29264399922419],
              [-152.999839, 62.292620999224198],
              [-152.999882, 61.426171999208329],
              [-153.485575, 61.426152999208334],
              [-153.485584, 61.166192999203723],
              [-153.440884, 60.906235999199176],
              [-154.455687, 60.906206999199149],
              [-155.144206, 60.906198999199169],
              [-156.000086, 60.906189999199164],
              [-157.16742, 60.90618299919916],
              [-157.167428, 60.992838999200679],
              [-157.522332, 60.99283399920067],
              [-157.522325, 60.906177999199166],
              [-157.699775, 60.906174999199166],
              [-157.69976, 60.819514999197672],
              [-157.877208, 60.819514999197672],
              [-157.877224, 60.906171999199152],
              [-158.941926, 60.906161999199149],
              [-158.941919, 60.81950299919766],
              [-159.01511, 60.81950299919766],
              [-159.015104, 60.732842999196166],
              [-159.190661, 60.732840999196171],
              [-159.190657, 60.646180999194677],
              [-159.366215, 60.646178999194682],
              [-159.366206, 60.472855999191729],
              [-159.434502, 60.472853999191734],
              [-159.434497, 60.386189999190265],
              [-159.608204, 60.386187999190255],
              [-159.608196, 60.212855999187347],
              [-159.781906, 60.212853999187345],
              [-159.781902, 60.126186999185904],
              [-159.845533, 60.1261859991859],
              [-159.845524, 59.952847999183042],
              [-160.01743, 59.952845999183033],
              [-160.017423, 59.7795039991802],
              [-160.24851, 59.7795009991802],
              [-160.248503, 59.606153999177408],
              [-160.418647, 59.606151999177385],
              [-160.41864, 59.432799999174627],
              [-160.475354, 59.432798999174608],
              [-160.475296, 59.346121999173242],
              [-160.643719, 59.346119999173254],
              [-160.643716, 59.259439999171889],
              [-160.81214, 59.259437999171865],
              [-160.812133, 59.086076999169158],
              [-160.864703, 58.9993939991678],
              [-161.031448, 58.999392999167824],
              [-161.03144100529201, 58.839807950370961],
              [-161.073748442440973, 58.826023803777851],
              [-161.364931, 58.826020999165152],
              [-161.365049, 58.739363999163821],
              [-161.339580659244007, 58.73933862416682],
              [-161.372314, 58.666171999162707],
              [-161.550537, 58.611159999161885],
              [-161.751999, 58.551841999160999],
              [-161.802156, 58.612317999161895],
              [-162.027363, 58.607049999161816],
              [-162.171722, 58.648440999162439],
              [-161.994644, 58.688827999163053],
              [-161.824107, 58.734548999163749],
              [-161.764791, 58.846234999165461],
              [-161.804034, 58.991716999167686],
              [-161.910267, 59.093502999169274],
              [-161.996859, 59.174125999170521],
              [-162.048584, 59.25417699917179],
              [-161.992903, 59.33838499917313],
              [-161.848988, 59.432493999174611],
              [-161.790375, 59.468196999175191],
              [-161.70253, 59.49090599917556],
              [-161.772979, 59.566242999176758],
              [-161.873944, 59.649486999178102],
              [-161.88552, 59.698389999178893],
              [-162.046547, 59.849687999181356],
              [-162.108772, 59.920106999182494],
              [-162.143049, 59.967505999183281],
              [-162.228371, 60.05631299918474],
              [-162.37224, 60.167008999186592],
              [-162.45128, 60.174366999186702],
              [-162.49418, 60.130270999185967],
              [-162.495608, 60.078948999185123],
              [-162.487649, 60.02808199918428],
              [-162.515276, 59.976182999183415],
              [-162.622569, 59.971808999183359],
              [-162.737447, 59.972253999183366],
              [-162.808513, 59.93393299918273],
              [-162.929135, 59.908053999182307],
              [-163.172633, 59.845057999181265],
              [-163.458092, 59.809957999180703],
              [-163.772229, 59.795623999180464],
              [-163.930798, 59.803852999180599],
              [-164.133393, 59.84561199918128],
              [-164.208475, 59.934460999182726],
              [-164.178705, 59.961809999183195],
              [-164.13181, 59.991176999183686],
              [-164.272808, 60.046193999184574],
              [-164.411016, 60.097674999185429],
              [-164.517647, 60.199492999187129],
              [-164.619501, 60.234937999187729],
              [-164.698889, 60.296297999188738],
              [-164.777233, 60.293832999188695],
              [-164.984527, 60.349928999189657],
              [-165.129403, 60.433706999191052],
              [-165.069693, 60.460892999191522],
              [-165.015155, 60.471413999191704],
              [-164.956788, 60.527836999192658],
              [-164.986952, 60.542405999192908],
              [-165.093939, 60.531861999192735],
              [-165.190449, 60.498000999192151],
              [-165.274867, 60.499020999192176],
              [-165.362975, 60.506865999192307],
              [-165.420349, 60.550691999193042],
              [-165.367676, 60.581157999193572]
            ]
          ],
          [
            [
              [-167.430619, 60.197005999187077],
              [-167.317602, 60.231540999187679],
              [-167.112841, 60.231481999187658],
              [-166.93866, 60.214703999187371],
              [-166.842599, 60.210469999187296],
              [-166.813127, 60.249767999187966],
              [-166.835825, 60.268750999188271],
              [-166.713539, 60.327340999189254],
              [-166.616566, 60.319220999189135],
              [-166.548589, 60.361855999189856],
              [-166.490358, 60.389465999190314],
              [-166.371874, 60.355155999189726],
              [-166.241032, 60.388819999190304],
              [-166.149763, 60.436699999191106],
              [-166.103296, 60.367320999189936],
              [-166.03734, 60.319455999189131],
              [-165.883086, 60.343367999189539],
              [-165.67987, 60.292434999188693],
              [-165.722533, 60.236467999187745],
              [-165.683554, 60.198334999187111],
              [-165.723484, 60.163823999186533],
              [-165.666679, 60.124303999185877],
              [-165.709223, 60.06600299918491],
              [-165.633879, 60.019596999184152],
              [-165.532633, 59.953424999183042],
              [-165.582302, 59.908241999182309],
              [-165.706609, 59.883563999181909],
              [-165.769832, 59.900494999182172],
              [-165.856822, 59.869727999181677],
              [-165.982317, 59.871636999181717],
              [-166.084995, 59.839648999181172],
              [-166.084282, 59.776058999180151],
              [-166.190406, 59.750205999179734],
              [-166.272168, 59.811454999180725],
              [-166.36213, 59.838603999181153],
              [-166.512819, 59.846424999181302],
              [-166.665528, 59.878167999181819],
              [-166.795194, 59.913184999182391],
              [-166.939572, 59.965482999183244],
              [-167.06578, 59.987959999183623],
              [-167.225739, 60.04087999918449],
              [-167.333289, 60.066625999184922],
              [-167.343509, 60.126286999185915],
              [-167.430619, 60.197005999187077]
            ]
          ],
          [
            [
              [-173.074642, 60.704656999195684],
              [-172.912636, 60.604128999193954],
              [-172.847344, 60.516741999192476],
              [-172.545912, 60.412224999190698],
              [-172.380946, 60.382763999190203],
              [-172.238862, 60.336641999189432],
              [-172.254263, 60.297374999188754],
              [-172.416534, 60.314346999189048],
              [-172.630272, 60.334921999189397],
              [-172.895998, 60.450586999191344],
              [-173.0638, 60.502588999192234],
              [-173.115569, 60.658970999194892],
              [-173.074642, 60.704656999195684]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "100",
        COUNTYNS: "01419970",
        AFFGEOID: "0500000US02100",
        GEOID: "02100",
        NAME: "Haines",
        LSAD: "04",
        ALAND: 6233111973,
        AWATER: 1054089659
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-136.466815, 59.284251999172277],
            [-136.474326, 59.464193999175123],
            [-136.358141, 59.449798999174895],
            [-136.234229, 59.524730999176086],
            [-136.23734, 59.558733999176631],
            [-136.350622, 59.599325999177289],
            [-136.190352, 59.639853999177937],
            [-135.945905, 59.663801999178318],
            [-135.717316842186989, 59.730076945753801],
            [-135.37502, 59.350032999173308],
            [-135.029120466615012, 59.345596860786841],
            [-135.029245, 59.34536399917323],
            [-134.961972, 59.280375999172215],
            [-134.702383, 59.247835999171691],
            [-134.66407, 59.181171999170651],
            [-134.566689, 59.128277999169818],
            [-134.481241, 59.128070999169815],
            [-134.379771, 59.034960999168362],
            [-134.401042, 58.976220999167445],
            [-134.327982, 58.963430999167265],
            [-134.327992277917957, 58.962972176980848],
            [-135.058835, 58.966784999167309],
            [-134.983701, 58.875643999165895],
            [-135.02326, 58.732627999163725],
            [-135.136971, 58.829797999165201],
            [-135.216683, 58.967773999167314],
            [-135.061769077114008, 58.451353242782879],
            [-135.104413, 58.449262999159444],
            [-135.068437, 58.37415699915833],
            [-135.049062, 58.309294999157366],
            [-135.10121, 58.29260699915713],
            [-135.087872, 58.200072999155765],
            [-135.227736, 58.236899999156307],
            [-135.306507, 58.242915999156381],
            [-135.408059, 58.342998999157878],
            [-135.449966172855, 58.339068181814689],
            [-135.446307, 58.4056179991588],
            [-135.50702, 58.4996039991602],
            [-135.316732, 58.504392999160288],
            [-135.405223, 58.620592999162021],
            [-135.529943, 58.656783999162563],
            [-135.551001, 58.698792999163217],
            [-135.747005, 58.725605999163619],
            [-135.683487, 58.853597999165565],
            [-135.524117, 58.887695999166098],
            [-135.605493, 58.903988999166337],
            [-135.566129, 58.942449999166925],
            [-135.723091, 59.069846999168902],
            [-135.699146, 59.107409999169505],
            [-135.89905, 59.179059999170619],
            [-136.107895, 59.215692999171196],
            [-136.362339, 59.232960999171461],
            [-136.48965218999399, 59.260491613790997],
            [-136.466815, 59.284251999172277]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "123",
        COUNTYNS: "00083331",
        AFFGEOID: "0500000US05123",
        GEOID: "05123",
        NAME: "St. Francis",
        LSAD: "06",
        ALAND: 1644132565,
        AWATER: 20028723
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.149842, 35.00246999911311],
            [-91.04543, 35.001039999113118],
            [-91.04267, 35.148188999111483],
            [-90.502993, 35.144213999111535],
            [-90.400438, 35.148230999111483],
            [-90.40854, 34.90412099911422],
            [-91.099201, 34.911795999114133],
            [-91.099961, 34.867119999114635],
            [-91.152457, 34.91184699911414],
            [-91.149842, 35.00246999911311]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "079",
        COUNTYNS: "01581100",
        AFFGEOID: "0500000US55079",
        GEOID: "55079",
        NAME: "Milwaukee",
        LSAD: "06",
        ALAND: 625439443,
        AWATER: 2455436199
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.06929, 42.952373999059056],
            [-88.069247, 42.959178999059048],
            [-88.066025, 43.104220999058725],
            [-88.065589, 43.120590999058685],
            [-88.065621, 43.125415999058681],
            [-88.063353, 43.192116999058541],
            [-87.959456, 43.192404999058546],
            [-87.924662, 43.192144999058542],
            [-87.896579311188788, 43.192134631585049],
            [-87.900485, 43.125909999058685],
            [-87.870184, 43.064411999058805],
            [-87.895784, 43.015813999058913],
            [-87.842681, 42.944115999059079],
            [-87.834879, 42.85671699905928],
            [-87.827477524463106, 42.848923007877907],
            [-87.821162938034007, 42.842273545794725],
            [-88.069924, 42.843322999059325],
            [-88.06929, 42.952373999059056]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "020",
        COUNTYNS: "01416061",
        AFFGEOID: "0500000US02020",
        GEOID: "02020",
        NAME: "Anchorage",
        LSAD: "12",
        ALAND: 4419537010,
        AWATER: 615526050
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-150.228774, 61.162580999203655],
            [-150.221240669872003, 61.193170799141789],
            [-149.987495, 61.224063999204738],
            [-149.897622, 61.267386999205513],
            [-149.748369, 61.397370999207823],
            [-149.35944, 61.484024999209367],
            [-149.240449, 61.484024999209367],
            [-149.180062, 61.426249999208338],
            [-148.462471, 61.426266999208323],
            [-148.472065, 60.848519999198174],
            [-148.738363, 60.848545999198159],
            [-148.745618, 60.733006999196164],
            [-149.037741, 60.732986999196164],
            [-149.03408, 60.848533999198168],
            [-149.181942, 60.906316999199156],
            [-149.359399, 60.906329999199166],
            [-149.748463763198998, 61.00123767626922],
            [-149.717167, 61.011302999200986],
            [-149.831922, 61.076196999202139],
            [-150.005041, 61.13855599920322],
            [-150.065646, 61.151078999203442],
            [-150.265894, 61.127364999203031],
            [-150.228774, 61.162580999203655]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "085",
        COUNTYNS: "01581103",
        AFFGEOID: "0500000US55085",
        GEOID: "55085",
        NAME: "Oneida",
        LSAD: "06",
        ALAND: 2885082781,
        AWATER: 317622270
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.042857, 45.897262999057276],
            [-89.300473, 45.90146299905728],
            [-89.175245, 45.856578999057234],
            [-89.17785, 45.899311999057275],
            [-89.047601, 45.895354999057268],
            [-89.04649, 45.464438999056924],
            [-89.364049, 45.46988599905692],
            [-89.424824, 45.467165999056917],
            [-89.428258, 45.555352999056979],
            [-90.043414, 45.555081999056974],
            [-90.042857, 45.897262999057276]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "119",
        COUNTYNS: "00974157",
        AFFGEOID: "0500000US36119",
        GEOID: "36119",
        NAME: "Westchester",
        LSAD: "06",
        ALAND: 1115031328,
        AWATER: 179949077
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.982584, 41.321692999064325],
            [-73.780007, 41.342491999064229],
            [-73.544151186400597, 41.366319595308539],
            [-73.550961, 41.29542199906443],
            [-73.482709, 41.212759999064779],
            [-73.695935802169586, 41.115255455969304],
            [-73.727775, 41.100695999065273],
            [-73.659533, 41.017856999065636],
            [-73.659362, 41.00403399906569],
            [-73.657374009261389, 40.985524882924992],
            [-73.657336, 40.985170999065794],
            [-73.697974, 40.939597999066002],
            [-73.756776, 40.912598999066127],
            [-73.766276, 40.881098999066268],
            [-73.741197244948594, 40.875854591903703],
            [-73.74806, 40.871720999066312],
            [-73.824047, 40.889865999066224],
            [-73.851123, 40.910008999066129],
            [-73.920471529379299, 40.918606331107092],
            [-73.90728, 40.95149799906595],
            [-73.893979, 40.997196999065736],
            [-73.893981860035879, 40.997198202604643],
            [-73.893579, 41.005296999065706],
            [-73.88748, 41.13829499906511],
            [-73.970784, 41.247892999064639],
            [-73.982584, 41.321692999064325]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "071",
        COUNTYNS: "00558113",
        AFFGEOID: "0500000US22071",
        GEOID: "22071",
        NAME: "Orleans",
        LSAD: "15",
        ALAND: 438813602,
        AWATER: 467285512
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.140074, 29.947903999183303],
            [-90.124775, 29.975790999182848],
            [-90.108119, 30.164939999179744],
            [-90.040277, 30.15511399917991],
            [-89.903234, 30.198659999179203],
            [-89.797414, 30.160756999179814],
            [-89.744078, 30.177498999179544],
            [-89.715686, 30.160840999179815],
            [-89.685287, 30.175345999179576],
            [-89.625054, 30.153492999179928],
            [-89.605088456554597, 30.142814156739401],
            [-89.656986, 30.118380999180516],
            [-89.683712, 30.076017999181197],
            [-89.782534, 30.045371999181704],
            [-89.845065, 30.01840999918215],
            [-89.845297119572407, 30.016381839752277],
            [-90.012102, 29.945979999183336],
            [-89.921019, 29.915728999183841],
            [-89.910708, 29.867864999184626],
            [-89.958854, 29.90259499918405],
            [-90.008056, 29.895527999184171],
            [-90.058547, 29.945333999183347],
            [-90.132474, 29.913004999183876],
            [-90.140074, 29.947903999183303]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "019",
        COUNTYNS: "00198125",
        AFFGEOID: "0500000US08019",
        GEOID: "08019",
        NAME: "Clear Creek",
        LSAD: "06",
        ALAND: 1023560263,
        AWATER: 3274894
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.887395, 39.796995999071996],
            [-105.736426, 39.804800999071958],
            [-105.690348, 39.851995999071676],
            [-105.496883, 39.757795999072229],
            [-105.397949, 39.747086999072287],
            [-105.398949, 39.566055999073392],
            [-105.587986, 39.568196999073379],
            [-105.776642, 39.564919999073403],
            [-105.829662, 39.564864999073393],
            [-105.77601, 39.609266999073135],
            [-105.924618, 39.698971999072576],
            [-105.887395, 39.796995999071996]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "065",
        COUNTYNS: "01101820",
        AFFGEOID: "0500000US40065",
        GEOID: "40065",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 2078855934,
        AWATER: 4114419
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.842063884146398, 34.506932768000198],
            [-99.666512, 34.50712099911879],
            [-99.666849, 34.724554999116265],
            [-99.423201, 34.725019999116263],
            [-99.405821, 34.813037999115259],
            [-99.246462, 34.82211399911516],
            [-99.14457, 34.849539999114846],
            [-99.149114, 34.70235199911653],
            [-99.052511, 34.701556999116548],
            [-99.10315, 34.637949999117275],
            [-99.082881, 34.590853999117826],
            [-99.16332, 34.588035999117849],
            [-99.237922, 34.426656999119764],
            [-99.192747, 34.354860999120596],
            [-99.221607900845498, 34.325373573493557],
            [-99.27534, 34.386598999120224],
            [-99.350407, 34.437082999119625],
            [-99.394956, 34.442098999119573],
            [-99.420432, 34.380463999120295],
            [-99.470969, 34.396470999120112],
            [-99.475022137038394, 34.396870988910607],
            [-99.58448, 34.407672999119974],
            [-99.600026, 34.374687999120354],
            [-99.696462, 34.381035999120293],
            [-99.764882, 34.435265999119636],
            [-99.818186, 34.487839999119025],
            [-99.842063884146398, 34.506932768000198]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "067",
        COUNTYNS: "01101821",
        AFFGEOID: "0500000US40067",
        GEOID: "40067",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 1965365168,
        AWATER: 39497494
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.139226, 34.289774999121377],
            [-97.562575, 34.289181999121382],
            [-97.560764, 34.071088999124022],
            [-97.536818, 33.983876999125101],
            [-97.561243150221088, 33.899061505859144],
            [-97.596155, 33.922105999125868],
            [-97.609091, 33.96809299912529],
            [-97.671772, 33.991369999124998],
            [-97.759834, 33.925209999125819],
            [-97.803473, 33.88018999912638],
            [-97.865765, 33.849392999126756],
            [-97.951215, 33.878423999126397],
            [-97.951905950855178, 33.891225813983546],
            [-97.953695, 33.924372999125822],
            [-97.947572, 33.99105299912501],
            [-98.005667, 33.995963999124946],
            [-98.082839, 34.002411999124867],
            [-98.099096, 34.0486389991243],
            [-98.099328, 34.104294999123617],
            [-98.123377, 34.15453999912301],
            [-98.138486025746403, 34.141206018834268],
            [-98.139226, 34.289774999121377]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "005",
        COUNTYNS: "00198118",
        AFFGEOID: "0500000US08005",
        GEOID: "08005",
        NAME: "Arapahoe",
        LSAD: "06",
        ALAND: 2066634438,
        AWATER: 19499590
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.053439, 39.621422999073047],
            [-105.034822, 39.627808999073018],
            [-105.034752, 39.633589999072974],
            [-105.032038, 39.657086999072817],
            [-105.0158, 39.660507999072806],
            [-105.015696, 39.678683999072703],
            [-104.999906, 39.667883999072778],
            [-104.973486, 39.667683999072764],
            [-104.955903, 39.652997999072859],
            [-104.931703, 39.653043999072857],
            [-104.913257, 39.638690999072949],
            [-104.913407, 39.624082999073039],
            [-104.847733, 39.65678799907284],
            [-104.884646, 39.740155999072336],
            [-104.659953, 39.740476999072321],
            [-104.634448, 39.739593999072341],
            [-104.526958, 39.738668999072345],
            [-103.706547, 39.739893999072329],
            [-103.707072, 39.566294999073385],
            [-103.714355, 39.566330999073394],
            [-104.642125, 39.565889999073391],
            [-104.660626, 39.565908999073386],
            [-104.904415, 39.565981999073394],
            [-105.04874, 39.566087999073396],
            [-105.053439, 39.621422999073047]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "389",
        COUNTYNS: "01383980",
        AFFGEOID: "0500000US48389",
        GEOID: "48389",
        NAME: "Reeves",
        LSAD: "06",
        ALAND: 6825534308,
        AWATER: 17396681
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.024521, 32.000009999151601],
            [-103.980213477381014, 32.000032850514195],
            [-103.885584, 31.870372999153471],
            [-103.829385, 31.887991999153222],
            [-103.809458, 31.794316999154578],
            [-103.746809, 31.739508999155394],
            [-103.625726, 31.705031999155892],
            [-103.610887, 31.651801999156671],
            [-103.509379, 31.625933999157052],
            [-103.44241, 31.433751999159924],
            [-103.329941, 31.411997999160249],
            [-103.261677, 31.427569999160017],
            [-103.181225, 31.370244999160874],
            [-103.01104, 31.37130599916086],
            [-103.585084, 30.766469999170152],
            [-104.102351, 31.105202999164899],
            [-104.024521, 32.000009999151601]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "141",
        COUNTYNS: "01101858",
        AFFGEOID: "0500000US40141",
        GEOID: "40141",
        NAME: "Tillman",
        LSAD: "06",
        ALAND: 2256226760,
        AWATER: 20894360
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.237922, 34.426656999119764],
            [-99.16332, 34.588035999117849],
            [-99.082881, 34.590853999117826],
            [-99.10315, 34.637949999117275],
            [-99.001025, 34.637871999117273],
            [-99.001107, 34.594365999117784],
            [-98.826008, 34.594412999117786],
            [-98.82607, 34.507369999118808],
            [-98.661982, 34.507459999118801],
            [-98.661956, 34.405947999119995],
            [-98.662004, 34.333430999120857],
            [-98.60966, 34.333719999120852],
            [-98.610351781644695, 34.156209938619689],
            [-98.648073, 34.164440999122888],
            [-98.690072, 34.133154999123271],
            [-98.737232, 34.130991999123289],
            [-98.80681, 34.155900999122998],
            [-98.872229, 34.160445999122935],
            [-98.94022, 34.20368599912242],
            [-98.952324887528192, 34.2046698977259],
            [-99.002916, 34.208781999122358],
            [-99.0588, 34.201255999122445],
            [-99.131553, 34.209351999122347],
            [-99.189511, 34.214311999122287],
            [-99.2116, 34.313969999121085],
            [-99.221607900845498, 34.325373573493557],
            [-99.192747, 34.354860999120596],
            [-99.237922, 34.426656999119764]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "075",
        COUNTYNS: "00558125",
        AFFGEOID: "0500000US22075",
        GEOID: "22075",
        NAME: "Plaquemines",
        LSAD: "15",
        ALAND: 2021056000,
        AWATER: 4628015820
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.06861, 29.828829999185274],
            [-90.008056, 29.895527999184171],
            [-89.958854, 29.90259499918405],
            [-89.910708, 29.867864999184626],
            [-89.86972, 29.795450999185835],
            [-89.721045, 29.757651999186468],
            [-89.628181, 29.680681999187748],
            [-89.5186, 29.64911699918828],
            [-89.500966753133696, 29.633455424247341],
            [-89.504738, 29.631507999188575],
            [-89.535202, 29.648566999188287],
            [-89.602109, 29.61029499918893],
            [-89.564615, 29.54378599919005],
            [-89.569607, 29.494043999190886],
            [-89.53215, 29.434566999191908],
            [-89.482318, 29.406221999192393],
            [-89.380001, 29.39178499919263],
            [-89.312085, 29.388037999192697],
            [-89.257852, 29.336871999193562],
            [-89.200389, 29.344417999193439],
            [-89.134337, 29.279339999194551],
            [-89.116653, 29.219531999195574],
            [-89.025974, 29.215152999195652],
            [-89.01428, 29.166912999196487],
            [-89.066617, 29.090713999197803],
            [-89.11653, 29.074096999198087],
            [-89.148792, 29.029669999198862],
            [-89.142866, 28.991622999199521],
            [-89.218673, 29.022514999198986],
            [-89.259354, 29.058357999198375],
            [-89.322011, 29.010250999199201],
            [-89.400966, 28.93381199920054],
            [-89.40353, 29.016963999199085],
            [-89.361098, 29.071847999198127],
            [-89.390515, 29.123575999197232],
            [-89.432932, 29.149022999196792],
            [-89.482844, 29.215052999195645],
            [-89.564551, 29.242526999195178],
            [-89.606651, 29.25202299919501],
            [-89.639663, 29.290530999194363],
            [-89.726162, 29.304025999194128],
            [-89.842641, 29.31882299919388],
            [-89.883463, 29.307102999194075],
            [-89.902706324024294, 29.293036605690322],
            [-89.97747, 29.449370999191654],
            [-89.984533, 29.617395999188815],
            [-90.075683, 29.77184999918622],
            [-90.06861, 29.828829999185274]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "077",
        COUNTYNS: "00559449",
        AFFGEOID: "0500000US22077",
        GEOID: "22077",
        NAME: "Pointe Coupee",
        LSAD: "15",
        ALAND: 1443519879,
        AWATER: 86418272
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.805063, 30.97574599916689],
            [-91.750119, 31.018813999166227],
            [-91.687266, 31.018141999166229],
            [-91.636942, 30.999415999166526],
            [-91.644917, 30.928970999167614],
            [-91.661372, 30.857098999168731],
            [-91.556407, 30.848642999168863],
            [-91.614608, 30.917599999167798],
            [-91.549308, 30.916227999167813],
            [-91.515586, 30.875180999168453],
            [-91.539473, 30.791223999169762],
            [-91.594595, 30.7501839991704],
            [-91.553019, 30.730797999170711],
            [-91.450382, 30.736167999170622],
            [-91.38062, 30.758803999170269],
            [-91.33087, 30.658676999171838],
            [-91.309475, 30.650044999171982],
            [-91.41476, 30.511515999174176],
            [-91.485089, 30.497137999174395],
            [-91.58337, 30.497004999174411],
            [-91.700933, 30.497432999174393],
            [-91.756193, 30.497737999174394],
            [-91.731432, 30.512118999174159],
            [-91.75698, 30.562550999173364],
            [-91.733673, 30.701729999171167],
            [-91.815258, 30.848106999168873],
            [-91.805063, 30.97574599916689]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "047",
        COUNTYNS: "00198139",
        AFFGEOID: "0500000US08047",
        GEOID: "08047",
        NAME: "Gilpin",
        LSAD: "06",
        ALAND: 388228996,
        AWATER: 942056
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.699719, 39.891498999071437],
            [-105.675798, 39.932444999071208],
            [-105.398041, 39.934853999071201],
            [-105.397849, 39.912885999071321],
            [-105.397949, 39.747086999072287],
            [-105.496883, 39.757795999072229],
            [-105.690348, 39.851995999071676],
            [-105.699719, 39.891498999071437]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "187",
        COUNTYNS: "00758548",
        AFFGEOID: "0500000US29187",
        GEOID: "29187",
        NAME: "St. Francois",
        LSAD: "06",
        ALAND: 1170437420,
        AWATER: 7160607
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.645135, 37.734812999086543],
            [-90.63998, 38.0765479990838],
            [-90.628192, 38.007961999084344],
            [-90.416022, 38.042314999084077],
            [-90.32287, 37.98173099908454],
            [-90.459133, 37.878923999085373],
            [-90.200208, 37.669627999087076],
            [-90.110537, 37.671339999087074],
            [-90.146778, 37.641617999087323],
            [-90.535016, 37.643640999087296],
            [-90.647874, 37.642808999087308],
            [-90.645135, 37.734812999086543]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "003",
        COUNTYNS: "00161527",
        AFFGEOID: "0500000US01003",
        GEOID: "01003",
        NAME: "Baldwin",
        LSAD: "06",
        ALAND: 4117605847,
        AWATER: 1133109409
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.026319, 30.753357999170348],
            [-87.944546, 30.827045999169201],
            [-87.981261, 30.886894999168263],
            [-87.92429, 30.93540499916752],
            [-87.965187, 30.967783999167015],
            [-87.941152, 31.048160999165781],
            [-87.976222, 31.089534999165146],
            [-87.940121, 31.147236999164264],
            [-87.972869, 31.162693999164013],
            [-87.946588, 31.19292999916356],
            [-87.913637, 31.187355999163643],
            [-87.785775, 31.282943999162185],
            [-87.803517, 31.319006999161651],
            [-87.765152, 31.297345999161969],
            [-87.71765, 31.303123999161883],
            [-87.61589, 31.24445799916278],
            [-87.61553, 31.007723999166394],
            [-87.598828946262103, 30.997422176071858],
            [-87.598937, 30.997421999166555],
            [-87.592064, 30.951459999167273],
            [-87.634943, 30.865856999168596],
            [-87.542268, 30.767480999170136],
            [-87.523621, 30.738284999170581],
            [-87.442291, 30.692660999171313],
            [-87.400189, 30.657200999171859],
            [-87.401189, 30.604382999172707],
            [-87.43145, 30.550251999173557],
            [-87.444722, 30.507483999174241],
            [-87.414685, 30.457288999175038],
            [-87.366601, 30.436642999175369],
            [-87.431784, 30.4031929991759],
            [-87.452282, 30.344096999176852],
            [-87.518324, 30.280434999177871],
            [-87.656888, 30.249708999178381],
            [-87.818867, 30.228313999178724],
            [-87.893201, 30.239236999178544],
            [-87.806466, 30.279797999177891],
            [-87.796717, 30.324197999177176],
            [-87.865017, 30.383449999176225],
            [-87.914136, 30.446143999175227],
            [-87.933355, 30.487356999174562],
            [-87.901711, 30.550878999173548],
            [-87.914956, 30.585892999172998],
            [-87.93107, 30.652693999171927],
            [-88.008396, 30.684955999171429],
            [-88.026319, 30.753357999170348]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "09",
        COUNTYFP: "009",
        COUNTYNS: "00212798",
        AFFGEOID: "0500000US09009",
        GEOID: "09009",
        NAME: "New Haven",
        LSAD: "06",
        ALAND: 1565653728,
        AWATER: 667092277
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.318889, 41.505188999063584],
            [-73.252265, 41.512133999063543],
            [-73.204197, 41.508526999063577],
            [-73.15573, 41.515278999063547],
            [-73.164676, 41.557087999063384],
            [-73.058024, 41.606739999063194],
            [-72.98325, 41.639597999063049],
            [-72.938501, 41.644310999063038],
            [-72.94635, 41.556796999063373],
            [-72.844147, 41.544696999063426],
            [-72.752181, 41.57889399906329],
            [-72.748878, 41.544278999063422],
            [-72.744742, 41.497697999063604],
            [-72.746141, 41.423296999063915],
            [-72.678739, 41.433796999063865],
            [-72.653672, 41.438124999063845],
            [-72.613919, 41.329427999064286],
            [-72.53456489716811, 41.253824273455706],
            [-72.547235, 41.250498999064618],
            [-72.598036, 41.268697999064557],
            [-72.653838, 41.265896999064559],
            [-72.654354020151203, 41.265626307364762],
            [-72.690439, 41.246696999064646],
            [-72.760341, 41.241234999064666],
            [-72.786142, 41.264795999064567],
            [-72.881445, 41.242596999064652],
            [-72.903933760062898, 41.249194133527133],
            [-72.935646, 41.25849699906459],
            [-72.970505415499204, 41.241274308060973],
            [-72.984840812754697, 41.234191741878703],
            [-72.986247, 41.233496999064698],
            [-73.020449, 41.206396999064808],
            [-73.07945, 41.194014999064876],
            [-73.101173821644508, 41.163726270229304],
            [-73.122353, 41.179796999064934],
            [-73.086752, 41.254595999064612],
            [-73.086852, 41.276595999064519],
            [-73.067851, 41.300995999064412],
            [-73.115754, 41.332195999064282],
            [-73.147555, 41.367695999064132],
            [-73.183269, 41.391710999064045],
            [-73.207688, 41.420543999063916],
            [-73.29776, 41.44827599906381],
            [-73.310473, 41.468814999063724],
            [-73.318889, 41.505188999063584]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "011",
        COUNTYNS: "00558436",
        AFFGEOID: "0500000US22011",
        GEOID: "22011",
        NAME: "Beauregard",
        LSAD: "15",
        ALAND: 2997502172,
        AWATER: 21999001
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.702665, 30.429946999175474],
            [-93.710117, 30.506399999174263],
            [-93.729195, 30.544841999173645],
            [-93.684329, 30.592585999172879],
            [-93.685121, 30.625200999172371],
            [-93.629904, 30.679939999171506],
            [-93.617688, 30.738478999170585],
            [-93.569303, 30.802968999169572],
            [-93.558617, 30.869423999168539],
            [-93.554575854345487, 30.877469501029513],
            [-93.478718, 30.859182999168695],
            [-93.421102, 30.883137999168326],
            [-92.978453, 30.878174999168408],
            [-92.97917, 30.598339999172794],
            [-93.130294, 30.597885999172806],
            [-93.131155, 30.423611999175577],
            [-93.131109, 30.403056999175902],
            [-93.38515, 30.402629999175922],
            [-93.384673, 30.490108999174517],
            [-93.486384, 30.490522999174505],
            [-93.486116, 30.402499999175909],
            [-93.738540238226008, 30.402263671656716],
            [-93.702665, 30.429946999175474]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "510",
        COUNTYNS: "00863219",
        AFFGEOID: "0500000US32510",
        GEOID: "32510",
        NAME: "Carson City",
        LSAD: "00",
        ALAND: 374669607,
        AWATER: 32587484
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.003364028833005, 39.165626763144743],
            [-119.880787, 39.165508999075946],
            [-119.843933, 39.200775999075717],
            [-119.770871, 39.215284999075621],
            [-119.712645, 39.250870999075374],
            [-119.648977, 39.195286999075755],
            [-119.561748, 39.194709999075755],
            [-119.55102, 39.08579099907648],
            [-119.761213, 39.114008999076276],
            [-120.002614842913005, 39.112689373172095],
            [-120.003364028833005, 39.165626763144743]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "105",
        COUNTYNS: "00974150",
        AFFGEOID: "0500000US36105",
        GEOID: "36105",
        NAME: "Sullivan",
        LSAD: "06",
        ALAND: 2507463254,
        AWATER: 74035009
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.146664899285881, 41.850125929208588],
            [-74.780693, 42.016374999061711],
            [-74.453685, 41.875594999062201],
            [-74.575086, 41.745257999062659],
            [-74.395071, 41.644875999063032],
            [-74.367055, 41.590976999063244],
            [-74.475619, 41.503952999063586],
            [-74.761435, 41.490244999063641],
            [-74.756271834725197, 41.427627427607298],
            [-74.799546, 41.431289999063878],
            [-74.890358, 41.455323999063786],
            [-74.982463, 41.496466999063621],
            [-75.043879, 41.57509399906332],
            [-75.046198605911684, 41.603764018965904],
            [-75.049281, 41.64186199906306],
            [-75.053431, 41.752537999062639],
            [-75.074412, 41.802190999062461],
            [-75.113369, 41.840697999062321],
            [-75.146664899285881, 41.850125929208588]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "50",
        COUNTYFP: "007",
        COUNTYNS: "01461760",
        AFFGEOID: "0500000US50007",
        GEOID: "50007",
        NAME: "Chittenden",
        LSAD: "06",
        ALAND: 1389740233,
        AWATER: 214237435
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.362682812743799, 44.562455220743551],
            [-73.26559, 44.592610999056838],
            [-73.228854, 44.722865999056793],
            [-73.038028, 44.651156999056809],
            [-72.924112, 44.63305099905682],
            [-72.855735, 44.577223999056848],
            [-72.809349, 44.547920999056856],
            [-72.838638, 44.459228999056911],
            [-72.804995, 44.451675999056903],
            [-72.839132, 44.403354999056937],
            [-72.826112, 44.359189999056959],
            [-72.855592, 44.365550999056957],
            [-72.911398, 44.281768999057014],
            [-72.89598, 44.276283999057028],
            [-72.92207, 44.222231999057072],
            [-72.952167, 44.161270999057123],
            [-72.96525, 44.230325999057065],
            [-72.973318, 44.293419999057008],
            [-73.317455974665094, 44.263522188933827],
            [-73.324229, 44.310022999056997],
            [-73.334637, 44.356876999056958],
            [-73.320954, 44.382668999056946],
            [-73.293613, 44.440558999056911],
            [-73.312871, 44.507245999056877],
            [-73.3479832324265, 44.546162852653865],
            [-73.362682812743799, 44.562455220743551]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "011",
        COUNTYNS: "00198121",
        AFFGEOID: "0500000US08011",
        GEOID: "08011",
        NAME: "Bent",
        LSAD: "06",
        ALAND: 3918303427,
        AWATER: 73093229
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.399938, 38.265434999082352],
            [-102.74233, 38.266970999082332],
            [-102.740464, 37.824063999085816],
            [-102.747615, 37.643641999087301],
            [-103.075938, 37.643419999087307],
            [-103.404339, 37.64357599908729],
            [-103.399938, 38.265434999082352]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "153",
        COUNTYNS: "00465265",
        AFFGEOID: "0500000US19153",
        GEOID: "19153",
        NAME: "Polk",
        LSAD: "06",
        ALAND: 1482734960,
        AWATER: 49332276
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.815721, 41.863418999062247],
            [-93.698032, 41.863369999062236],
            [-93.347933, 41.86310399906224],
            [-93.348681, 41.600998999063208],
            [-93.328614, 41.507823999063568],
            [-93.328407, 41.490920999063633],
            [-93.417688, 41.511541999063553],
            [-93.790612, 41.511915999063561],
            [-93.814282, 41.600455999063215],
            [-93.815721, 41.863418999062247]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "239",
        COUNTYNS: "00516966",
        AFFGEOID: "0500000US21239",
        GEOID: "21239",
        NAME: "Woodford",
        LSAD: "06",
        ALAND: 489012357,
        AWATER: 8307144
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.864804, 38.141372999083309],
            [-84.725168, 38.195425999082886],
            [-84.647821, 38.17660599908303],
            [-84.625079, 38.116396999083491],
            [-84.66034, 38.00383599908438],
            [-84.709918, 37.861951999085505],
            [-84.765204, 37.847834999085627],
            [-84.826454, 37.916457999085083],
            [-84.796327, 37.97008799908464],
            [-84.849779, 38.047984999084022],
            [-84.830915, 38.093604999083666],
            [-84.864908, 38.116925999083499],
            [-84.864804, 38.141372999083309]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "107",
        COUNTYNS: "01719560",
        AFFGEOID: "0500000US30107",
        GEOID: "30107",
        NAME: "Wheatland",
        LSAD: "06",
        ALAND: 3684208406,
        AWATER: 13010925
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.273337, 46.710508999058533],
            [-109.969221, 46.752908999058612],
            [-109.819866, 46.69344599905849],
            [-109.745745, 46.694096999058509],
            [-109.389163, 46.693624999058507],
            [-109.403544, 46.490656999058125],
            [-109.402906, 46.219705999057688],
            [-109.653871, 46.219403999057704],
            [-110.281971, 46.220604999057691],
            [-110.273337, 46.710508999058533]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "019",
        COUNTYNS: "01213661",
        AFFGEOID: "0500000US42019",
        GEOID: "42019",
        NAME: "Butler",
        LSAD: "06",
        ALAND: 2042451307,
        AWATER: 15948099
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.16529, 41.000469999065722],
            [-80.096861, 41.0698039990654],
            [-79.999779, 41.171860999064968],
            [-79.694984, 41.172864999064956],
            [-79.690711, 41.170690999064973],
            [-79.69293, 40.669743999067279],
            [-80.148451, 40.674289999067263],
            [-80.158534, 40.855156999066395],
            [-80.162547, 40.937750999065997],
            [-80.16529, 41.000469999065722]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "075",
        COUNTYNS: "01639753",
        AFFGEOID: "0500000US47075",
        GEOID: "47075",
        NAME: "Haywood",
        LSAD: "06",
        ALAND: 1380741549,
        AWATER: 2472509
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.502118, 35.580620999106806],
            [-89.401805, 35.822656999104282],
            [-89.356033, 35.817634999104328],
            [-89.338704, 35.789272999104618],
            [-89.069544, 35.692678999105624],
            [-89.078876, 35.431427999108401],
            [-89.182551, 35.432815999108385],
            [-89.183944, 35.397125999108773],
            [-89.347491, 35.400337999108736],
            [-89.375071, 35.399935999108742],
            [-89.474171, 35.403038999108702],
            [-89.469862, 35.546565999107173],
            [-89.502118, 35.580620999106806]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "049",
        COUNTYNS: "01513274",
        AFFGEOID: "0500000US53049",
        GEOID: "53049",
        NAME: "Pacific",
        LSAD: "06",
        ALAND: 2415872174,
        AWATER: 760845734
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.096796667904997, 46.794084999058711],
            [-123.37095, 46.792128999058704],
            [-123.358334, 46.384024999057942],
            [-123.726557, 46.384871999057943],
            [-123.72790381759701, 46.291335584768987],
            [-123.75956, 46.275072999057777],
            [-123.806139, 46.283587999057779],
            [-123.875525, 46.239786999057721],
            [-123.909306, 46.245490999057722],
            [-123.954353, 46.277000999057769],
            [-123.969427, 46.291397999057793],
            [-124.080671, 46.267238999057767],
            [-124.064624, 46.326898999057846],
            [-124.057024, 46.493337999058134],
            [-124.069583, 46.630650999058389],
            [-123.960642, 46.636363999058396],
            [-123.923269, 46.672707999058467],
            [-123.975157, 46.713970999058539],
            [-124.080983, 46.735002999058601],
            [-124.096796667904997, 46.794084999058711]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "147",
        COUNTYNS: "01492359",
        AFFGEOID: "0500000US51147",
        GEOID: "51147",
        NAME: "Prince Edward",
        LSAD: "06",
        ALAND: 906395961,
        AWATER: 10040117
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.681573, 37.248758999090676],
            [-78.594394, 37.345541999089832],
            [-78.594436, 37.39797399908938],
            [-78.511491, 37.329723999089964],
            [-78.465089, 37.339676999089875],
            [-78.35275, 37.301605999090214],
            [-78.235039, 37.368101999089646],
            [-78.231234, 37.296219999090255],
            [-78.239354, 37.120088999091813],
            [-78.275167, 37.103525999091957],
            [-78.443644, 37.07937099909217],
            [-78.547128, 37.150430999091533],
            [-78.692432, 37.203672999091069],
            [-78.681573, 37.248758999090676]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "053",
        COUNTYNS: "00558039",
        AFFGEOID: "0500000US22053",
        GEOID: "22053",
        NAME: "Jefferson Davis",
        LSAD: "15",
        ALAND: 1687025362,
        AWATER: 18718206
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.131155, 30.423611999175577],
            [-93.065543, 30.438568999175338],
            [-92.775737, 30.437616999175358],
            [-92.773976, 30.488283999174545],
            [-92.630347, 30.487872999174549],
            [-92.631987, 30.481146999174658],
            [-92.594586, 30.420338999175634],
            [-92.630975, 30.374584999176363],
            [-92.626612, 30.230271999178694],
            [-92.591129, 30.182364999179466],
            [-92.625484, 30.092329999180929],
            [-92.637841, 30.07217399918127],
            [-92.738604, 30.037322999181836],
            [-92.774499, 30.039209999181796],
            [-92.996371, 30.038478999181812],
            [-92.887222, 30.083454999181072],
            [-92.893324, 30.156313999179893],
            [-92.9963, 30.156954999179881],
            [-93.030685, 30.260594999178199],
            [-93.030955, 30.379389999176283],
            [-93.131109, 30.403056999175902],
            [-93.131155, 30.423611999175577]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "149",
        COUNTYNS: "01480160",
        AFFGEOID: "0500000US51149",
        GEOID: "51149",
        NAME: "Prince George",
        LSAD: "06",
        ALAND: 686941559,
        AWATER: 43184612
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.399203, 37.170849999091359],
            [-77.329767, 37.197494999091127],
            [-77.376785, 37.244848999090706],
            [-77.377381, 37.255540999090606],
            [-77.360455, 37.277332999090426],
            [-77.353016, 37.308174999090163],
            [-77.334549, 37.312467999090103],
            [-77.328291, 37.271747999090465],
            [-77.31081, 37.261450999090563],
            [-77.278336, 37.280375999090388],
            [-77.260195, 37.282712999090379],
            [-77.253234, 37.295667999090263],
            [-77.273379, 37.316115999090087],
            [-77.284019, 37.313011999090122],
            [-77.281955, 37.319011999090058],
            [-77.270675, 37.320755999090039],
            [-77.124694, 37.302922999090192],
            [-77.069269, 37.269741999090492],
            [-76.996003, 37.300978999090226],
            [-76.972439, 37.248500999090673],
            [-77.012301, 37.232882999090805],
            [-77.155415, 37.112033999091885],
            [-77.187038, 37.098932999092],
            [-77.398498, 36.99298499909294],
            [-77.394645, 37.035733999092564],
            [-77.399203, 37.170849999091359]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "137",
        COUNTYNS: "01623011",
        AFFGEOID: "0500000US26137",
        GEOID: "26137",
        NAME: "Otsego",
        LSAD: "06",
        ALAND: 1333913235,
        AWATER: 28997739
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.857452, 45.116706999056781],
            [-84.734799, 45.114902999056788],
            [-84.734303, 45.201935999056793],
            [-84.366614, 45.198736999056798],
            [-84.371737, 44.855038999056767],
            [-84.847309, 44.858036999056772],
            [-84.857452, 45.116706999056781]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "111",
        COUNTYNS: "00161582",
        AFFGEOID: "0500000US01111",
        GEOID: "01111",
        NAME: "Randolph",
        LSAD: "06",
        ALAND: 1503615934,
        AWATER: 9285420
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.641129, 33.320500999133507],
            [-85.643482, 33.495884999131242],
            [-85.347744, 33.501377999131151],
            [-85.304943833224897, 33.482756335976902],
            [-85.294346864345286, 33.4279931454967],
            [-85.236595371252776, 33.129544027386707],
            [-85.232441405187288, 33.108077093069895],
            [-85.593177, 33.1073399991363],
            [-85.653654, 33.106633999136314],
            [-85.641129, 33.320500999133507]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "153",
        COUNTYNS: "01480161",
        AFFGEOID: "0500000US51153",
        GEOID: "51153",
        NAME: "Prince William",
        LSAD: "06",
        ALAND: 869614721,
        AWATER: 30829977
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.71582, 38.838829999078172],
            [-77.655169, 38.942655999077445],
            [-77.55224, 38.886587999077825],
            [-77.549001, 38.846552999078106],
            [-77.535991, 38.847367999078116],
            [-77.508812, 38.841069999078144],
            [-77.490113, 38.810032999078359],
            [-77.468234, 38.796298999078459],
            [-77.443084, 38.803652999078416],
            [-77.380526, 38.722536999078983],
            [-77.356276, 38.725499999078963],
            [-77.299868, 38.705161999079117],
            [-77.282567, 38.696566999079174],
            [-77.245756, 38.672272999079347],
            [-77.224145855301487, 38.6351782739805],
            [-77.246704, 38.63521699907961],
            [-77.295274, 38.56212499908014],
            [-77.29923201266611, 38.548376737591141],
            [-77.312604284119288, 38.501927799664671],
            [-77.48483, 38.59218999907992],
            [-77.531328, 38.556549999080175],
            [-77.624978, 38.696167999079172],
            [-77.679735, 38.778439999078593],
            [-77.71582, 38.838829999078172]
          ],
          [
            [-77.523569, 38.717641999079021],
            [-77.471177, 38.727664999078947],
            [-77.458695, 38.772318999078621],
            [-77.427332, 38.753597999078757],
            [-77.420581, 38.762286999078704],
            [-77.440333, 38.783472999078555],
            [-77.444875, 38.770494999078636],
            [-77.472972, 38.788390999078509],
            [-77.47576, 38.781170999078576],
            [-77.523569, 38.717641999079021]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "001",
        COUNTYNS: "00063755",
        AFFGEOID: "0500000US05001",
        GEOID: "05001",
        NAME: "Arkansas",
        LSAD: "06",
        ALAND: 2560988637,
        AWATER: 116409463
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.709709, 34.307763999121171],
            [-91.705185, 34.482669999119089],
            [-91.678037, 34.481919999119093],
            [-91.59552, 34.480509999119114],
            [-91.594429, 34.568049999118095],
            [-91.37782, 34.563479999118137],
            [-91.291483, 34.561760999118157],
            [-91.174745, 34.450442999119474],
            [-91.107762, 34.353403999120616],
            [-91.056276, 34.336604999120823],
            [-91.078628, 34.143728999123148],
            [-91.117905, 34.119104999123444],
            [-91.194278, 34.070937999124027],
            [-91.151365, 33.937509999125666],
            [-91.239438, 33.973759999125221],
            [-91.347628, 33.954940999125455],
            [-91.421715, 34.014471999124723],
            [-91.436548, 34.030185999124527],
            [-91.445614, 34.080146999123912],
            [-91.451931, 34.133864999123254],
            [-91.514374, 34.142292999123157],
            [-91.537991, 34.223806999122168],
            [-91.711795, 34.233918999122054],
            [-91.709709, 34.307763999121171]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "033",
        COUNTYNS: "01714670",
        AFFGEOID: "0500000US24033",
        GEOID: "24033",
        NAME: "Prince George's",
        LSAD: "06",
        ALAND: 1250087042,
        AWATER: 41892810
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.079499, 38.709514999079083],
            [-77.053199, 38.709914999079075],
            [-77.040998, 38.73791399907887],
            [-77.040672570778099, 38.746691929561706],
            [-77.039239885082296, 38.785336324702151],
            [-77.039006, 38.791644999078486],
            [-76.979497, 38.837811999078163],
            [-76.909393, 38.892851999077784],
            [-77.002546244247, 38.965531699523183],
            [-76.974565, 39.014713999076953],
            [-76.907292, 39.117907999076259],
            [-76.888505, 39.130966999076179],
            [-76.840362, 39.103141999076364],
            [-76.831424, 39.069033999076574],
            [-76.790741, 39.045721999076747],
            [-76.751258, 39.034713999076828],
            [-76.670111, 38.906918999077682],
            [-76.710639, 38.815693999078327],
            [-76.686358, 38.748474999078802],
            [-76.70196, 38.710863999079066],
            [-76.675457, 38.535875999080332],
            [-76.740747, 38.559140999080164],
            [-76.747335, 38.616676999079743],
            [-76.862955, 38.658648999079446],
            [-77.011028, 38.651974999079485],
            [-77.047656, 38.616302999079736],
            [-77.08578476515639, 38.705281280911507],
            [-77.079499, 38.709514999079083]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "111",
        COUNTYNS: "00559528",
        AFFGEOID: "0500000US22111",
        GEOID: "22111",
        NAME: "Union",
        LSAD: "15",
        ALAND: 2271359845,
        AWATER: 73624039
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.725369, 32.817978999140173],
            [-92.724743173179291, 33.014341766812343],
            [-92.723553, 33.014327999137542],
            [-92.501383, 33.012159999137573],
            [-92.222825, 33.00907999913759],
            [-92.069146592481289, 33.008482429394505],
            [-92.069103758561283, 33.008482262837212],
            [-92.085617, 32.965192999138189],
            [-92.052728, 32.794733999140483],
            [-92.065179, 32.723002999141457],
            [-92.223849, 32.590365999143273],
            [-92.415071, 32.582844999143383],
            [-92.41505, 32.670055999142185],
            [-92.518356, 32.670719999142165],
            [-92.571295, 32.74857899914111],
            [-92.725439, 32.759539999140976],
            [-92.725369, 32.817978999140173]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "085",
        COUNTYNS: "00835864",
        AFFGEOID: "0500000US31085",
        GEOID: "31085",
        NAME: "Hayes",
        LSAD: "06",
        ALAND: 1846821514,
        AWATER: 570786
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.345365, 40.69809299906715],
            [-101.247963, 40.697801999067146],
            [-100.777833, 40.700134999067139],
            [-100.777376, 40.349465999068912],
            [-101.323353, 40.35055299906891],
            [-101.342788, 40.350443999068908],
            [-101.345365, 40.69809299906715]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "039",
        COUNTYNS: "01383805",
        AFFGEOID: "0500000US48039",
        GEOID: "48039",
        NAME: "Brazoria",
        LSAD: "06",
        ALAND: 3528358447,
        AWATER: 637931958
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.847656, 29.262589999194834],
            [-95.752866, 29.324502999193783],
            [-95.625863, 29.293348999194315],
            [-95.579598, 29.340128999193507],
            [-95.549994, 29.438438999191831],
            [-95.462503, 29.439239999191823],
            [-95.443224, 29.510213999190615],
            [-95.424118, 29.58023299918943],
            [-95.331146, 29.599107999189116],
            [-95.297411, 29.596521999189157],
            [-95.286493, 29.597885999189145],
            [-95.253856, 29.553777999189883],
            [-95.216422, 29.556079999189837],
            [-95.233081, 29.46556599919138],
            [-95.19219, 29.404366999192419],
            [-95.056499, 29.199976999195911],
            [-95.115050505598276, 29.075552021676771],
            [-95.125134, 29.067320999198202],
            [-95.191391, 29.023089999198984],
            [-95.297147, 28.934072999200534],
            [-95.38239, 28.866347999201714],
            [-95.439594, 28.859021999201843],
            [-95.507037396916999, 28.824735511394543],
            [-95.567708, 28.829757999202357],
            [-95.67955, 28.965733999199976],
            [-95.769393, 28.971108999199888],
            [-95.770446, 29.066209999198225],
            [-95.84631, 29.107798999197517],
            [-95.874028, 29.229701999195402],
            [-95.847656, 29.262589999194834]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "021",
        COUNTYNS: "01074023",
        AFFGEOID: "0500000US39021",
        GEOID: "39021",
        NAME: "Champaign",
        LSAD: "06",
        ALAND: 1109624422,
        AWATER: 3638887
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.022919, 40.183944999069794],
            [-84.014763, 40.273458999069319],
            [-83.784196, 40.260045999069384],
            [-83.551338, 40.229369999069547],
            [-83.494498, 40.225466999069575],
            [-83.503714, 40.111467999070193],
            [-83.516155, 40.010187999070773],
            [-84.036069, 40.040181999070597],
            [-84.022919, 40.183944999069794]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "122",
        COUNTYNS: "01419972",
        AFFGEOID: "0500000US02122",
        GEOID: "02122",
        NAME: "Kenai Peninsula",
        LSAD: "04",
        ALAND: 41499900917,
        AWATER: 22525622024
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-151.96313, 59.344957999173232],
              [-151.886513, 59.421032999174436],
              [-151.742915, 59.468285999175187],
              [-151.516339, 59.523519999176081],
              [-151.327803, 59.573046999176867],
              [-151.205459, 59.630283999177784],
              [-151.296895, 59.69686099917886],
              [-151.448669, 59.648170999178078],
              [-151.643061, 59.646965999178057],
              [-151.746815, 59.686233999178697],
              [-151.869468, 59.769158999180029],
              [-151.813619, 59.84429699918126],
              [-151.757693, 59.917636999182449],
              [-151.71801, 60.00947299918397],
              [-151.606881, 60.099557999185457],
              [-151.421702, 60.21293099918735],
              [-151.381959, 60.296950999188759],
              [-151.366874, 60.37265499919004],
              [-151.30609, 60.387256999190285],
              [-151.28181, 60.496033999192122],
              [-151.303125, 60.56132599919323],
              [-151.350154, 60.634659999194476],
              [-151.410273, 60.711022999195791],
              [-151.370515, 60.733571999196172],
              [-151.261319, 60.769800999196804],
              [-151.062558, 60.787428999197111],
              [-150.895508, 60.853165999198239],
              [-150.705812, 60.937791999199717],
              [-150.501923, 61.007956999200935],
              [-150.401859, 61.036226999201439],
              [-150.341709, 61.024200999201234],
              [-150.194128, 60.901339999199081],
              [-150.109276, 60.890356999198886],
              [-149.985374, 60.879032999198699],
              [-149.900135, 60.940042999199747],
              [-149.853693, 60.967394999200216],
              [-149.748463763198998, 61.00123767626922],
              [-149.359399, 60.906329999199166],
              [-149.181942, 60.906316999199156],
              [-149.03408, 60.848533999198168],
              [-149.037741, 60.732986999196164],
              [-148.745618, 60.733006999196164],
              [-148.657119, 60.732975999196171],
              [-148.66447, 60.415182999190741],
              [-148.563715, 60.415171999190754],
              [-148.563026, 60.126313999185918],
              [-148.58618, 59.938584999182815],
              [-148.649529585787008, 59.923553179282472],
              [-148.689496, 59.944700999182899],
              [-148.801325, 59.952793999183029],
              [-148.859556, 59.924397999182567],
              [-148.936406, 59.953428999183046],
              [-149.036467, 59.942136999182864],
              [-149.123262, 59.968558999183308],
              [-149.221067, 59.938747999182809],
              [-149.270623, 59.872066999181712],
              [-149.376593, 59.835878999181112],
              [-149.472227, 59.90369299918224],
              [-149.572714, 59.852316999181383],
              [-149.595531, 59.797719999180508],
              [-149.506758, 59.770926999180077],
              [-149.527793, 59.706845999179016],
              [-149.626311, 59.734409999179462],
              [-149.731966, 59.706782999179012],
              [-149.74622, 59.637584999177911],
              [-149.842672, 59.701299999178936],
              [-149.919444, 59.691835999178785],
              [-150.002337, 59.630562999177791],
              [-150.133747, 59.556785999176611],
              [-150.280838, 59.466832999175168],
              [-150.297108, 59.424746999174488],
              [-150.358992, 59.399683999174101],
              [-150.385341, 59.341962999173184],
              [-150.430144, 59.34335699917321],
              [-150.477717, 59.42211099917445],
              [-150.4989, 59.456297999175],
              [-150.581182, 59.44523299917482],
              [-150.609488, 59.386313999173879],
              [-150.680872, 59.305411999172613],
              [-150.721799, 59.29208699917239],
              [-150.822768, 59.330762999173004],
              [-150.912817, 59.305213999172601],
              [-150.887821, 59.267919999172008],
              [-150.942212, 59.233135999171466],
              [-151.001196, 59.224148999171319],
              [-151.126247, 59.209922999171098],
              [-151.287771, 59.219416999171251],
              [-151.341601, 59.222230999171281],
              [-151.470623, 59.242620999171614],
              [-151.43339, 59.13551699916993],
              [-151.662368, 59.089734999169224],
              [-151.858124, 59.144233999170076],
              [-151.915684, 59.22752199917138],
              [-151.984101, 59.278695999172186],
              [-151.96313, 59.344957999173232]
            ]
          ],
          [
            [
              [-152.064099, 60.417136999190788],
              [-151.952456, 60.510608999192357],
              [-151.839194, 60.485861999191947],
              [-151.891542, 60.440176999191166],
              [-151.956263, 60.367840999189944],
              [-152.079995, 60.341190999189493],
              [-152.064099, 60.417136999190788]
            ]
          ],
          [
            [
              [-153.597411, 59.386826999173884],
              [-153.489005, 59.41522999917435],
              [-153.412493, 59.415104999174353],
              [-153.347772, 59.377984999173748],
              [-153.386898, 59.330749999173001],
              [-153.515286, 59.320877999172851],
              [-153.546695, 59.331347999173012],
              [-153.597411, 59.386826999173884]
            ]
          ],
          [
            [
              [-154.748861, 59.259517999171891],
              [-154.412013, 59.259523999171897],
              [-154.412028, 59.432881999174633],
              [-154.123321, 59.432886999174627],
              [-154.123327, 59.519551999176002],
              [-153.953181, 59.519568999176009],
              [-153.953198, 59.692917999178803],
              [-153.783068, 59.692925999178797],
              [-153.78307, 59.7795969991802],
              [-153.656977, 59.77960699918021],
              [-153.65698, 60.126276999185912],
              [-153.528392, 60.126280999185909],
              [-153.528441, 60.472938999191726],
              [-153.397276, 60.47294799919171],
              [-153.397284, 60.819580999197676],
              [-153.440884, 60.906235999199176],
              [-153.485584, 61.166192999203723],
              [-153.485575, 61.426152999208334],
              [-152.999882, 61.426171999208329],
              [-151.857505, 61.426217999208333],
              [-151.332784, 61.42623899920833],
              [-151.332802, 61.252935999205256],
              [-150.974014, 61.252939999205246],
              [-150.974011916343017, 61.194467297903699],
              [-151.047736, 61.160889999203619],
              [-151.121692, 61.083573999202265],
              [-151.166606, 61.046403999201615],
              [-151.252384, 61.039967999201494],
              [-151.349004, 61.010003999200975],
              [-151.4803, 61.010901999200989],
              [-151.600126, 60.9655889992002],
              [-151.720815, 60.904256999199127],
              [-151.800264, 60.853671999198255],
              [-151.77731, 60.810460999197502],
              [-151.703802, 60.732375999196151],
              [-151.716379, 60.710414999195791],
              [-151.89792, 60.721749999195978],
              [-152.039381, 60.660516999194925],
              [-152.13616, 60.578474999193524],
              [-152.261497, 60.53823699919284],
              [-152.331365, 60.473524999191731],
              [-152.30195, 60.414327999190732],
              [-152.234199, 60.393887999190397],
              [-152.376743, 60.345612999189584],
              [-152.411281, 60.287863999188602],
              [-152.539843, 60.241643999187815],
              [-152.574938, 60.206450999187247],
              [-152.57873, 60.169869999186631],
              [-152.550177, 60.113714999185696],
              [-152.575153, 60.048259999184616],
              [-152.679402, 59.968053999183283],
              [-152.700822, 59.92030899918251],
              [-152.860867, 59.875032999181748],
              [-152.967267, 59.881493999181849],
              [-153.009084, 59.830642999181038],
              [-153.016353, 59.751126999179746],
              [-153.051559, 59.691561999178774],
              [-153.155019, 59.654343999178174],
              [-153.240018, 59.632425999177833],
              [-153.308837, 59.625705999177711],
              [-153.409422, 59.636327999177887],
              [-153.542466, 59.630235999177785],
              [-153.553163, 59.597045999177247],
              [-153.577828, 59.555990999176601],
              [-153.684925, 59.552864999176542],
              [-153.76148, 59.543410999176402],
              [-153.699025, 59.463602999175116],
              [-153.747201, 59.429656999174576],
              [-153.862199, 59.424123999174491],
              [-153.925307, 59.405253999174178],
              [-153.998506, 59.384722999173853],
              [-154.030807, 59.327039999172939],
              [-154.122681, 59.287621999172316],
              [-154.141192, 59.216597999171213],
              [-154.172944, 59.172495999170501],
              [-154.180691, 59.123234999169739],
              [-154.063489, 59.072139999168961],
              [-153.932824, 59.062676999168808],
              [-153.793972, 59.071415999168934],
              [-153.695664, 59.073993999168962],
              [-153.596489, 59.000191999167839],
              [-153.479939, 58.995285999167756],
              [-153.393101, 58.951096999167071],
              [-153.322843, 58.90784899916639],
              [-153.267407, 58.867217999165774],
              [-153.294436234905987, 58.855035999165594],
              [-153.639403, 58.85502899916559],
              [-153.639027, 58.739449999163817],
              [-153.817076, 58.739445999163827],
              [-153.817076, 58.652759999162505],
              [-154.305691, 58.6527509991625],
              [-154.642545, 58.65274699916251],
              [-154.642558, 58.739430999163815],
              [-154.695036, 58.739430999163815],
              [-154.695105, 59.08615899916915],
              [-154.748861, 59.259517999171891]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "150",
        COUNTYNS: "01419974",
        AFFGEOID: "0500000US02150",
        GEOID: "02150",
        NAME: "Kodiak Island",
        LSAD: "04",
        ALAND: 17134142683,
        AWATER: 14166400752
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-154.779663, 57.366334999143874],
              [-154.69331, 57.446084999144993],
              [-154.618704, 57.514971999145956],
              [-154.52206, 57.577785999146826],
              [-154.411385, 57.598451999147116],
              [-154.292471, 57.644222999147757],
              [-154.196959, 57.664638999148053],
              [-153.994572, 57.656904999147933],
              [-153.930279, 57.696790999148504],
              [-153.93522, 57.813046999150146],
              [-153.781408, 57.876416999151061],
              [-153.721176, 57.890614999151275],
              [-153.648798, 57.88010299915112],
              [-153.512024, 57.909155999151537],
              [-153.533204, 57.941116999151987],
              [-153.484603, 57.976499999152516],
              [-153.386422, 57.936525999151932],
              [-153.299009, 57.985625999152646],
              [-153.365574, 58.039051999153408],
              [-153.419783, 58.059637999153715],
              [-153.362281, 58.106785999154404],
              [-153.316127, 58.140389999154884],
              [-153.223709, 58.162119999155202],
              [-153.202801, 58.208079999155878],
              [-153.101841, 58.25793799915661],
              [-153.044316, 58.306335999157319],
              [-152.925586, 58.33968599915783],
              [-152.883107, 58.400442999158727],
              [-152.787776, 58.411312999158874],
              [-152.733845, 58.460661999159633],
              [-152.640313, 58.46986799915976],
              [-152.66622, 58.544086999160882],
              [-152.61613, 58.601851999161731],
              [-152.560171, 58.61967999916201],
              [-152.453817, 58.618514999161981],
              [-152.354709, 58.638279999162286],
              [-152.337212, 58.58909499916156],
              [-152.38761, 58.522869999160548],
              [-152.467197, 58.476608999159879],
              [-152.512483, 58.427348999159129],
              [-152.49848, 58.3723509991583],
              [-152.432235, 58.355220999158057],
              [-152.387343, 58.35949899915812],
              [-152.34486, 58.391629999158589],
              [-152.35609, 58.42346999915906],
              [-152.301713, 58.428696999159143],
              [-152.227835, 58.376423999158376],
              [-152.129257, 58.396413999158668],
              [-152.08925, 58.367643999158226],
              [-151.981781, 58.347970999157944],
              [-151.817111, 58.263443999156685],
              [-151.795696, 58.21109599915593],
              [-151.862321, 58.168264999155298],
              [-152.03412, 58.183736999155521],
              [-152.112205, 58.148558999155014],
              [-152.265111, 58.135731999154828],
              [-152.374399, 58.120013999154594],
              [-152.482674, 58.12981299915473],
              [-152.529036, 58.093778999154203],
              [-152.656801, 58.061048999153734],
              [-152.766673, 58.02988699915327],
              [-152.722524, 57.987363999152677],
              [-152.751978, 57.933465999151885],
              [-152.804807, 57.899174999151391],
              [-152.790211, 57.858057999150795],
              [-152.753437, 57.834451999150474],
              [-152.681204, 57.875674999151052],
              [-152.635378, 57.918609999151677],
              [-152.526283, 57.913265999151591],
              [-152.432608, 57.976028999152497],
              [-152.422573, 57.948661999152108],
              [-152.324103, 57.916603999151647],
              [-152.351152, 57.834767999150472],
              [-152.212247, 57.791432999149848],
              [-152.298762, 57.745924999149217],
              [-152.440182, 57.726639999148915],
              [-152.386805, 57.667922999148097],
              [-152.313974, 57.636419999147655],
              [-152.161617, 57.623286999147467],
              [-152.159677, 57.59361399914706],
              [-152.259641, 57.527155999146117],
              [-152.323683, 57.467860999145294],
              [-152.253603, 57.384018999144118],
              [-152.323687, 57.342659999143557],
              [-152.474883, 57.434203999144835],
              [-152.570527, 57.44890899914504],
              [-152.601148, 57.382164999144116],
              [-152.630441, 57.322667999143299],
              [-152.695698, 57.281317999142722],
              [-152.818187, 57.265367999142491],
              [-152.943463, 57.256955999142377],
              [-152.949333, 57.187345999141435],
              [-152.880321, 57.164797999141122],
              [-152.90054, 57.132075999140682],
              [-153.06433, 57.10378999914029],
              [-153.200217, 57.042038999139457],
              [-153.266822, 56.999642999138892],
              [-153.342018, 56.982824999138671],
              [-153.404263, 57.080510999139989],
              [-153.48652, 57.085914999140059],
              [-153.580831, 57.04904799913956],
              [-153.543429, 56.995244999138841],
              [-153.541492, 56.887874999137395],
              [-153.603624, 56.887335999137392],
              [-153.699409, 56.855381999136974],
              [-153.776468, 56.83031499913664],
              [-153.83964, 56.822098999136529],
              [-153.90215, 56.771207999135846],
              [-153.97178, 56.744860999135511],
              [-154.017042, 56.689310999134776],
              [-154.153147, 56.681696999134672],
              [-154.129017, 56.742167999135475],
              [-154.305713, 56.846870999136854],
              [-154.312888, 56.918672999137812],
              [-154.40749, 56.968333999138473],
              [-154.528538, 57.00189199913892],
              [-154.523432, 57.129105999140641],
              [-154.594977, 57.257160999142393],
              [-154.691855, 57.284109999142757],
              [-154.79384, 57.288861999142824],
              [-154.779663, 57.366334999143874]
            ]
          ],
          [
            [
              [-154.840415, 56.420319999131287],
              [-154.70614, 56.521272999132584],
              [-154.514078, 56.604058999133663],
              [-154.210336, 56.609683999133736],
              [-154.095833, 56.617785999133837],
              [-154.025041, 56.572516999133249],
              [-153.878764, 56.565924999133166],
              [-153.887678, 56.533636999132753],
              [-154.02093, 56.482024999132086],
              [-154.266332, 56.496365999132259],
              [-154.529507, 56.502654999132353],
              [-154.624282, 56.475180999132],
              [-154.742887, 56.401677999131046],
              [-154.840415, 56.420319999131287]
            ]
          ],
          [
            [
              [-155.750002, 55.821848999123802],
              [-155.688098, 55.864885999124333],
              [-155.605373, 55.928825999125124],
              [-155.530591, 55.912212999124904],
              [-155.554245, 55.846459999124114],
              [-155.566307, 55.789487999123409],
              [-155.591002, 55.761724999123082],
              [-155.718593, 55.772355999123199],
              [-155.750002, 55.821848999123802]
            ]
          ],
          [
            [
              [-156.733628, 56.077592999126949],
              [-156.683003, 56.098809999127219],
              [-156.614757, 56.065177999126803],
              [-156.618202, 56.01780199912622],
              [-156.681814, 55.99433699912592],
              [-156.735292, 56.022639999126277],
              [-156.733628, 56.077592999126949]
            ]
          ],
          [
            [
              [-156.75078, 57.235794999142101],
              [-156.672751, 57.234908999142078],
              [-156.672142, 57.292979999142879],
              [-156.617395, 57.334941999143446],
              [-156.502226, 57.362605999143824],
              [-156.508759, 57.434830999144829],
              [-156.370235, 57.522367999146056],
              [-156.132032, 57.509691999145865],
              [-156.071148, 57.584423999146914],
              [-155.952772, 57.598212999147115],
              [-155.921548, 57.5543339991465],
              [-155.84623, 57.629143999147558],
              [-155.898455, 57.668099999148112],
              [-155.768022, 57.668260999148103],
              [-155.747821, 57.739829999149123],
              [-155.692481, 57.739053999149107],
              [-155.693773, 57.797986999149934],
              [-155.53883, 57.799805999149974],
              [-155.446251, 57.827093999150357],
              [-155.44602, 57.871458999150988],
              [-155.330245, 57.876342999151063],
              [-155.331678, 58.04821099915354],
              [-155.280743, 58.047873999153545],
              [-155.280169, 58.10859099915443],
              [-155.331945, 58.193282999155663],
              [-155.221313, 58.193192999155663],
              [-155.221463, 58.236407999156313],
              [-155.057741, 58.23620899915629],
              [-155.008132, 58.290904999157092],
              [-154.730369, 58.304668999157307],
              [-154.729769, 58.33283699915772],
              [-154.570107, 58.333054999157717],
              [-154.569723, 58.360807999158141],
              [-154.465741, 58.361355999158143],
              [-154.432332, 58.418997999158996],
              [-154.351306, 58.418082999158976],
              [-154.303447, 58.460384999159615],
              [-154.305691, 58.6527509991625],
              [-153.817076, 58.652759999162505],
              [-153.817076, 58.739445999163827],
              [-153.639027, 58.739449999163817],
              [-153.639403, 58.85502899916559],
              [-153.294436234905987, 58.855035999165594],
              [-153.369389, 58.821254999165085],
              [-153.402472, 58.742606999163868],
              [-153.445002, 58.709309999163366],
              [-153.55265, 58.687175999163024],
              [-153.591635, 58.640083999162307],
              [-153.731019, 58.608223999161837],
              [-153.851432, 58.611871999161899],
              [-153.909994, 58.561212999161114],
              [-153.930473, 58.497481999160172],
              [-154.001918, 58.492345999160108],
              [-154.07066, 58.440017999159309],
              [-153.985416, 58.390876999158579],
              [-154.074145, 58.352660999158012],
              [-154.103412, 58.280160999156934],
              [-154.145277, 58.210930999155927],
              [-154.222465, 58.132565999154764],
              [-154.340449, 58.09092099915415],
              [-154.477979, 58.052378999153611],
              [-154.581547, 58.019284999153129],
              [-154.765287, 58.003709999152896],
              [-154.876559, 58.02772199915325],
              [-155.026275, 57.999301999152841],
              [-155.118648, 57.953924999152186],
              [-155.061806, 57.904329999151457],
              [-155.097095, 57.8653559991509],
              [-155.272917, 57.823980999150315],
              [-155.285339, 57.758725999149384],
              [-155.354011, 57.715260999148768],
              [-155.506533, 57.760969999149417],
              [-155.609353, 57.777698999149635],
              [-155.615203, 57.688073999148394],
              [-155.629912, 57.656375999147926],
              [-155.724167, 57.633444999147606],
              [-155.732779, 57.549731999146445],
              [-155.915261, 57.535330999146233],
              [-156.046804, 57.525723999146102],
              [-156.012841, 57.451393999145068],
              [-156.091668, 57.439828999144915],
              [-156.220105, 57.445294999144984],
              [-156.362039, 57.400473999144346],
              [-156.336427, 57.336080999143469],
              [-156.342943, 57.24805599914226],
              [-156.334404, 57.182299999141364],
              [-156.374287301554006, 57.159250119865156],
              [-156.750815, 57.162877999141102],
              [-156.75078, 57.235794999142101]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "061",
        COUNTYNS: "00198146",
        AFFGEOID: "0500000US08061",
        GEOID: "08061",
        NAME: "Kiowa",
        LSAD: "06",
        ALAND: 4578603605,
        AWATER: 47032919
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.504666, 38.516408999080468],
            [-103.172878, 38.5253149990804],
            [-103.172943, 38.612449999079757],
            [-103.062143, 38.615020999079761],
            [-102.045510574570002, 38.615164589257247],
            [-102.044944, 38.384418999081447],
            [-102.044650359146985, 38.268749221585416],
            [-102.74233, 38.266970999082332],
            [-103.399938, 38.265434999082352],
            [-103.501764, 38.265015999082351],
            [-103.506985, 38.342255999081772],
            [-103.504666, 38.516408999080468]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "065",
        COUNTYNS: "00198149",
        AFFGEOID: "0500000US08065",
        GEOID: "08065",
        NAME: "Lake",
        LSAD: "06",
        ALAND: 976215870,
        AWATER: 18111565
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.561265, 39.155676999076007],
            [-106.50238, 39.298566999075078],
            [-106.426487, 39.361870999074675],
            [-106.283812, 39.349468999074752],
            [-106.206732, 39.379635999074551],
            [-106.135529, 39.379545999074558],
            [-106.183972, 39.309353999074993],
            [-106.173847, 39.137075999076124],
            [-106.210173, 39.103917999076351],
            [-106.191006, 39.056072999076683],
            [-106.577966, 39.057907999076669],
            [-106.561265, 39.155676999076007]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "071",
        COUNTYNS: "00198151",
        AFFGEOID: "0500000US08071",
        GEOID: "08071",
        NAME: "Las Animas",
        LSAD: "06",
        ALAND: 12361852569,
        AWATER: 6929970
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.154176, 37.293128999090285],
            [-105.115425, 37.296293999090246],
            [-104.995505, 37.375550999089569],
            [-104.748938, 37.407028999089306],
            [-104.695075, 37.439914999089019],
            [-104.696111, 37.485424999088636],
            [-104.649229, 37.495319999088544],
            [-104.548652, 37.574699999087883],
            [-104.548417, 37.609423999087589],
            [-104.45493, 37.740838999086499],
            [-104.351109, 37.81748799908587],
            [-104.061132, 37.734703999086548],
            [-104.058196, 37.644048999087303],
            [-103.404339, 37.64357599908729],
            [-103.075938, 37.643419999087307],
            [-103.086101075630992, 36.999864360199282],
            [-103.733247, 36.9980159990929],
            [-104.007849409613002, 36.995984093802015],
            [-104.338833, 36.993534999092937],
            [-104.732031, 36.993446999092953],
            [-105.1208, 36.995427999092925],
            [-105.155042748048004, 36.995474444703532],
            [-105.154176, 37.293128999090285]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "097",
        COUNTYNS: "01622991",
        AFFGEOID: "0500000US26097",
        GEOID: "26097",
        NAME: "Mackinac",
        LSAD: "06",
        ALAND: 2646602337,
        AWATER: 2793796394
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-84.61622, 45.894469999057272],
              [-84.517895, 45.828542999057198],
              [-84.419696, 45.79982299905717],
              [-84.35602, 45.771894999057139],
              [-84.394038, 45.727622999057111],
              [-84.484128, 45.730709999057105],
              [-84.587572, 45.806699999057187],
              [-84.650783, 45.859209999057235],
              [-84.61622, 45.894469999057272]
            ]
          ],
          [
            [
              [-85.865025, 46.244630999057719],
              [-85.237839, 46.245429999057727],
              [-85.113329, 46.245721999057729],
              [-85.112513, 46.158262999057584],
              [-84.363503, 46.157983999057585],
              [-84.36309, 46.07157399905747],
              [-84.238884, 46.071456999057482],
              [-84.239332, 45.984913999057376],
              [-84.114606562518802, 45.967908373852758],
              [-84.254952, 45.956067999057332],
              [-84.376429, 45.931961999057314],
              [-84.480436, 45.977763999057373],
              [-84.567493, 45.947701999057337],
              [-84.632855, 45.951006999057334],
              [-84.734002, 45.907025999057289],
              [-84.706383, 45.848657999057224],
              [-84.792763, 45.858690999057231],
              [-84.917484, 45.930669999057322],
              [-85.003597, 46.0061299990574],
              [-85.152027, 46.050724999057458],
              [-85.266385, 46.065778999057478],
              [-85.381394, 46.082043999057497],
              [-85.540858, 46.079580999057498],
              [-85.648581, 45.983694999057363],
              [-85.697203, 45.960157999057344],
              [-85.810442, 45.980086999057377],
              [-85.865839921942694, 45.947571072242638],
              [-85.865025, 46.244630999057719]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "011",
        COUNTYNS: "01135848",
        AFFGEOID: "0500000US41011",
        GEOID: "41011",
        NAME: "Coos",
        LSAD: "06",
        ALAND: 4133690002,
        AWATER: 544752776
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.436198, 43.071311999058793],
            [-124.41139, 43.159852999058614],
            [-124.395607, 43.223907999058476],
            [-124.38246, 43.270166999058382],
            [-124.400404, 43.302120999058332],
            [-124.353332, 43.342666999058245],
            [-124.286896, 43.436295999058075],
            [-124.233534, 43.557129999057892],
            [-124.21905767690599, 43.610931758246792],
            [-123.875698, 43.608406999057799],
            [-123.875628, 43.515946999057945],
            [-123.816657, 43.51644899905795],
            [-123.817614, 43.431305999058097],
            [-123.761528, 43.422293999058105],
            [-123.764356, 43.258053999058411],
            [-123.704752, 43.257740999058413],
            [-123.702144, 43.087082999058765],
            [-123.761333, 43.083159999058772],
            [-123.761594, 42.996317999058959],
            [-123.821118, 42.996290999058957],
            [-123.811553, 42.788836999059455],
            [-123.925717, 42.774513999059486],
            [-123.996291, 42.685650999059703],
            [-124.132918, 42.666696999059766],
            [-124.159966, 42.739946999059569],
            [-124.141475, 42.850585999059298],
            [-124.219623, 42.933781999059107],
            [-124.258388, 42.954945999059056],
            [-124.479882226931991, 42.954322436736447],
            [-124.436198, 43.071311999058793]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "109",
        COUNTYNS: "00198170",
        AFFGEOID: "0500000US08109",
        GEOID: "08109",
        NAME: "Saguache",
        LSAD: "06",
        ALAND: 8206547707,
        AWATER: 4454510
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.001119, 38.147071999083266],
            [-107.000601, 38.425618999081145],
            [-106.24694, 38.422767999081174],
            [-106.076535, 38.423651999081152],
            [-106.042771, 38.458266999080898],
            [-106.010751, 38.446565999080981],
            [-105.999788, 38.423621999081156],
            [-105.796897, 38.265046999082351],
            [-105.67627, 38.146172999083269],
            [-105.583922, 37.967195999084666],
            [-105.473204, 37.895969999085231],
            [-105.428893, 37.851732999085591],
            [-105.457255, 37.751463999086411],
            [-106.038413, 37.748352999086443],
            [-106.586374, 37.747808999086438],
            [-106.587139, 37.834425999085738],
            [-106.693845, 37.835243999085726],
            [-106.693523, 37.87351899908542],
            [-106.84727, 37.919169999085057],
            [-106.879167, 37.961109999084712],
            [-107.000602, 37.956040999084756],
            [-107.001119, 38.147071999083266]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "023",
        COUNTYNS: "01581071",
        AFFGEOID: "0500000US55023",
        GEOID: "55023",
        NAME: "Crawford",
        LSAD: "06",
        ALAND: 1478001876,
        AWATER: 73945449
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.210663357772702, 43.41944178196141],
            [-91.18346, 43.423016999058106],
            [-90.668561, 43.4229939990581],
            [-90.666786, 43.171776999058579],
            [-90.845746, 43.077624999058777],
            [-90.940087, 43.061881999058812],
            [-91.062211, 42.992551999058961],
            [-91.159084305947488, 42.987477790381782],
            [-91.174692, 43.038712999058866],
            [-91.174934913911173, 43.080260370903986],
            [-91.175253, 43.134664999058657],
            [-91.134173, 43.174404999058574],
            [-91.087456, 43.221890999058488],
            [-91.05791, 43.253967999058425],
            [-91.107237, 43.313644999058305],
            [-91.154806, 43.334825999058253],
            [-91.207367, 43.3736589990582],
            [-91.199408, 43.403031999058143],
            [-91.210663357772702, 43.41944178196141]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "061",
        COUNTYNS: "01383816",
        AFFGEOID: "0500000US48061",
        GEOID: "48061",
        NAME: "Cameron",
        LSAD: "06",
        ALAND: 2307897628,
        AWATER: 998028553
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.861875, 26.120294999253524],
            [-97.861875, 26.348155999248956],
            [-97.527582, 26.299303999249929],
            [-97.506936, 26.312701999249661],
            [-97.446987, 26.324946999249416],
            [-97.390733, 26.411331999247693],
            [-97.227380226521788, 26.411496004550202],
            [-97.196935, 26.305864999249795],
            [-97.158798, 26.082659999254282],
            [-97.151922, 26.017652999255596],
            [-97.145567, 25.971131999256535],
            [-97.156608, 25.949021999256988],
            [-97.206945, 25.960898999256738],
            [-97.277163, 25.935437999257257],
            [-97.338346, 25.923124999257514],
            [-97.365976, 25.902446999257933],
            [-97.360082, 25.868873999258618],
            [-97.372864, 25.840116999259205],
            [-97.422636, 25.840377999259193],
            [-97.454727, 25.879336999258399],
            [-97.496861, 25.880057999258391],
            [-97.542957, 25.920034999257574],
            [-97.582565, 25.93785699925721],
            [-97.644011, 26.006613999255816],
            [-97.697069, 26.023454999255474],
            [-97.758838, 26.032130999255301],
            [-97.795291, 26.055217999254836],
            [-97.862281563106279, 26.057746827694984],
            [-97.861875, 26.120294999253524]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "417",
        COUNTYNS: "01383994",
        AFFGEOID: "0500000US48417",
        GEOID: "48417",
        NAME: "Shackelford",
        LSAD: "06",
        ALAND: 2367989256,
        AWATER: 3268209
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.612001, 32.956961999138294],
            [-99.471258, 32.957024999138298],
            [-99.096016, 32.957036999138296],
            [-99.095956, 32.707580999141669],
            [-99.09605, 32.514767999144318],
            [-99.114293, 32.514810999144345],
            [-99.612026, 32.514653999144329],
            [-99.612001, 32.956961999138294]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "089",
        COUNTYNS: "01629449",
        AFFGEOID: "0500000US22089",
        GEOID: "22089",
        NAME: "St. Charles",
        LSAD: "15",
        ALAND: 719296011,
        AWATER: 308712052
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.538132, 30.016883999182173],
            [-90.467788, 30.031708999181927],
            [-90.326637, 30.150187999179995],
            [-90.259889, 30.163409999179777],
            [-90.275555, 30.088876999180993],
            [-90.279495, 30.006796999182335],
            [-90.279533, 29.967970999182977],
            [-90.240173, 29.862116999184728],
            [-90.173202, 29.827523999185303],
            [-90.169288, 29.807379999185638],
            [-90.228157, 29.692027999187552],
            [-90.352104, 29.695511999187499],
            [-90.371978, 29.759238999186433],
            [-90.468043, 29.803473999185709],
            [-90.479904, 29.847376999184974],
            [-90.529601, 29.884996999184345],
            [-90.532203, 29.967937999182979],
            [-90.538132, 30.016883999182173]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "083",
        COUNTYNS: "01101829",
        AFFGEOID: "0500000US40083",
        GEOID: "40083",
        NAME: "Logan",
        LSAD: "06",
        ALAND: 1926521247,
        AWATER: 12926653
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.675617, 36.164662999100813],
            [-97.460792, 36.164461999100808],
            [-97.371722, 36.164284999100808],
            [-97.354132, 36.159071999100867],
            [-97.35387, 35.984933999102608],
            [-97.32362, 36.010876999102351],
            [-97.203592, 35.944794999103024],
            [-97.140583, 35.941990999103055],
            [-97.141072, 35.724440999105298],
            [-97.674026, 35.725969999105281],
            [-97.675617, 36.164662999100813]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "241",
        COUNTYNS: "00351489",
        AFFGEOID: "0500000US13241",
        GEOID: "13241",
        NAME: "Rabun",
        LSAD: "06",
        ALAND: 958635596,
        AWATER: 17839179
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.656592, 34.87819199911452],
            [-83.549180753504686, 34.988803195027273],
            [-83.482873204290698, 34.990873960400044],
            [-83.322768, 34.995873999113179],
            [-83.10861299650729, 35.000658842790834],
            [-83.108606, 35.000658999113142],
            [-83.124378, 34.955239999113637],
            [-83.140621, 34.924914999113987],
            [-83.201183, 34.884652999114437],
            [-83.252582, 34.853482999114796],
            [-83.284812, 34.823042999115145],
            [-83.323866, 34.789711999115532],
            [-83.320062, 34.759615999115873],
            [-83.353238, 34.728647999116227],
            [-83.349608510913285, 34.717010581743672],
            [-83.431465, 34.747271999116002],
            [-83.534904, 34.740012999116082],
            [-83.586897, 34.819990999115177],
            [-83.652194, 34.822992999115144],
            [-83.656592, 34.87819199911452]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "143",
        COUNTYNS: "00069905",
        AFFGEOID: "0500000US05143",
        GEOID: "05143",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 2439678769,
        AWATER: 27051804
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.551906282606296, 36.102225604770034],
            [-94.391125, 36.099395999101461],
            [-94.33643, 36.142247999101038],
            [-94.333565, 36.214726999100314],
            [-93.973298, 36.203851999100422],
            [-93.887775, 36.235045999100109],
            [-93.944177, 36.091102999101537],
            [-93.962892, 35.760929999104924],
            [-94.124258, 35.764635999104883],
            [-94.212507, 35.752919999104996],
            [-94.493040072427007, 35.759166473432231],
            [-94.494549, 35.768302999104847],
            [-94.532071, 35.987851999102581],
            [-94.551906282606296, 36.102225604770034]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "261",
        COUNTYNS: "01383916",
        AFFGEOID: "0500000US48261",
        GEOID: "48261",
        NAME: "Kenedy",
        LSAD: "06",
        ALAND: 3777375503,
        AWATER: 1262209528
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.985887, 27.209307999232124],
            [-97.84134, 27.243954999231466],
            [-97.785102, 27.283713999230709],
            [-97.508863, 27.266635999231028],
            [-97.346850876955685, 27.277958831440309],
            [-97.358469, 27.234795999231647],
            [-97.378697, 27.060043999235003],
            [-97.366872, 26.885580999238382],
            [-97.32275, 26.701745999241961],
            [-97.287541116685787, 26.600339880717257],
            [-97.861688, 26.597994999244001],
            [-97.957405, 26.611768999243733],
            [-97.985303, 26.615808999243644],
            [-97.985494, 26.780916999240411],
            [-97.985887, 27.209307999232124]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "169",
        COUNTYNS: "01008586",
        AFFGEOID: "0500000US37169",
        GEOID: "37169",
        NAME: "Stokes",
        LSAD: "06",
        ALAND: 1162938788,
        AWATER: 17584342
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.440101541963699, 36.55059527426036],
            [-80.431605, 36.550218999097055],
            [-80.295243, 36.543972999097122],
            [-80.053455043350098, 36.542639427183737],
            [-80.0273389151412, 36.54249538471133],
            [-80.035119, 36.257183999099894],
            [-80.451696, 36.261501999099856],
            [-80.440101541963699, 36.55059527426036]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "405",
        COUNTYNS: "01383988",
        AFFGEOID: "0500000US48405",
        GEOID: "48405",
        NAME: "San Augustine",
        LSAD: "06",
        ALAND: 1374395181,
        AWATER: 159644687
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.399091, 31.653595999156654],
            [-94.090717, 31.620608999157135],
            [-93.984461, 31.569418999157904],
            [-93.983239, 31.466113999159433],
            [-94.044154, 31.409978999160273],
            [-94.039034, 31.134270999164457],
            [-94.043234, 31.118551999164694],
            [-94.129632, 31.099279999164992],
            [-94.218997, 31.173264999163855],
            [-94.326616, 31.224753999163074],
            [-94.339773, 31.240819999162834],
            [-94.33119, 31.362965999160981],
            [-94.300622, 31.526457999158538],
            [-94.311042, 31.589251999157604],
            [-94.399091, 31.653595999156654]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "005",
        COUNTYNS: "01383788",
        AFFGEOID: "0500000US48005",
        GEOID: "48005",
        NAME: "Angelina",
        LSAD: "06",
        ALAND: 2066241599,
        AWATER: 173338077
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.003345, 31.425709999160045],
            [-94.865857, 31.526915999158529],
            [-94.728679, 31.457225999159576],
            [-94.544888, 31.431714999159954],
            [-94.495874, 31.405727999160355],
            [-94.449422, 31.321336999161609],
            [-94.396017, 31.3023559991619],
            [-94.326616, 31.224753999163074],
            [-94.218997, 31.173264999163855],
            [-94.129632, 31.099279999164992],
            [-94.457816, 31.033324999165995],
            [-94.477026, 31.027740999166085],
            [-94.561943, 31.058951999165618],
            [-94.573675, 31.068431999165455],
            [-94.738594, 31.103689999164928],
            [-94.842947, 31.146577999164265],
            [-94.860501, 31.234954999162913],
            [-94.95811, 31.386929999160628],
            [-94.976718, 31.409144999160283],
            [-95.003345, 31.425709999160045]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "117",
        COUNTYNS: "00069176",
        AFFGEOID: "0500000US05117",
        GEOID: "05117",
        NAME: "Prairie",
        LSAD: "06",
        ALAND: 1678019592,
        AWATER: 71807768
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.802505, 35.030424999112803],
            [-91.695463, 35.062343999112443],
            [-91.608065, 35.02074099911291],
            [-91.584005, 35.09158399911211],
            [-91.465114, 35.089453999112138],
            [-91.363763, 35.051886999112561],
            [-91.36856, 34.912734999114114],
            [-91.374765, 34.66596399911694],
            [-91.446983, 34.667530999116927],
            [-91.447795, 34.609184999117602],
            [-91.37782, 34.563479999118137],
            [-91.594429, 34.568049999118095],
            [-91.59552, 34.480509999119114],
            [-91.678037, 34.481919999119093],
            [-91.632811, 34.55309999911826],
            [-91.638531, 34.626092999117411],
            [-91.700828, 34.672028999116868],
            [-91.693705, 34.919604999114043],
            [-91.803703, 34.921523999114029],
            [-91.802505, 35.030424999112803]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "127",
        COUNTYNS: "00161589",
        AFFGEOID: "0500000US01127",
        GEOID: "01127",
        NAME: "Walker",
        LSAD: "06",
        ALAND: 2048686077,
        AWATER: 36754700
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.636118, 34.002202999124883],
            [-87.216735, 33.993906999124981],
            [-87.170054, 34.005203999124831],
            [-87.151036, 33.993224999124969],
            [-87.091836, 33.890092999126267],
            [-86.963358, 33.858220999126658],
            [-86.953664, 33.815296999127192],
            [-87.005965, 33.787236999127543],
            [-87.057757, 33.656362999129179],
            [-87.17943, 33.613793999129733],
            [-87.185794, 33.555881999130463],
            [-87.271586, 33.529419999130809],
            [-87.266923, 33.512928999131013],
            [-87.318237, 33.514165999131002],
            [-87.318532, 33.58739299913006],
            [-87.37104, 33.587064999130057],
            [-87.423701, 33.602095999129872],
            [-87.423843, 33.689111999128777],
            [-87.528338, 33.69204899912873],
            [-87.531602, 33.86761799912653],
            [-87.63604, 33.871998999126475],
            [-87.635932, 33.915250999125931],
            [-87.636118, 34.002202999124883]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "037",
        COUNTYNS: "01531926",
        AFFGEOID: "0500000US53037",
        GEOID: "53037",
        NAME: "Kittitas",
        LSAD: "06",
        ALAND: 5949976954,
        AWATER: 92436005
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.464234, 47.354415999060073],
            [-121.410878, 47.424538999060275],
            [-121.17225, 47.590053999060757],
            [-121.113569, 47.597287999060789],
            [-121.07497, 47.550890999060648],
            [-120.996578, 47.518544999060538],
            [-120.917936, 47.429366999060292],
            [-120.802533, 47.42262599906028],
            [-120.664368, 47.343304999060045],
            [-120.5305, 47.334327999060022],
            [-120.389197, 47.260934999059813],
            [-120.09446, 47.262158999059821],
            [-120.075969, 47.225897999059733],
            [-120.007074, 47.220132999059715],
            [-120.002065, 47.126152999059478],
            [-120.042866, 47.073452999059356],
            [-119.966129, 46.943756999059033],
            [-119.927436, 46.798444999058724],
            [-119.973036, 46.737125999058591],
            [-120.51, 46.737945999058603],
            [-120.508605, 46.824861999058783],
            [-120.633981, 46.825775999058784],
            [-120.634562, 46.912129999058969],
            [-121.02662, 46.91130799905897],
            [-121.090054, 46.991006999059145],
            [-121.28101, 47.088749999059388],
            [-121.379682, 47.087494999059388],
            [-121.408081, 47.118099999059453],
            [-121.297567, 47.148617999059539],
            [-121.365138, 47.224562999059714],
            [-121.341203, 47.281260999059874],
            [-121.427492, 47.289074999059906],
            [-121.464234, 47.354415999060073]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "061",
        COUNTYNS: "01581090",
        AFFGEOID: "0500000US55061",
        GEOID: "55061",
        NAME: "Kewaunee",
        LSAD: "06",
        ALAND: 886863420,
        AWATER: 1921424713
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.761307678885103, 44.653697748791721],
            [-87.748409, 44.667121999056803],
            [-87.737570860866285, 44.677011815212701],
            [-87.375491550219394, 44.67551210886181],
            [-87.401629, 44.631190999056813],
            [-87.446963, 44.586273999056843],
            [-87.498662, 44.460685999056892],
            [-87.543326785476097, 44.327512855186882],
            [-87.766026, 44.327183999056984],
            [-87.761307678885103, 44.653697748791721]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "165",
        COUNTYNS: "00659527",
        AFFGEOID: "0500000US27165",
        GEOID: "27165",
        NAME: "Watonwan",
        LSAD: "06",
        ALAND: 1126526382,
        AWATER: 12370135
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.859803, 44.108015999057166],
            [-94.36885, 44.108697999057171],
            [-94.368974, 43.848046999057459],
            [-94.854444, 43.848098999057463],
            [-94.859388, 43.848092999057464],
            [-94.859803, 44.108015999057166]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "051",
        COUNTYNS: "00198141",
        AFFGEOID: "0500000US08051",
        GEOID: "08051",
        NAME: "Gunnison",
        LSAD: "06",
        ALAND: 8389338065,
        AWATER: 53067068
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.63504, 38.301895999082078],
            [-107.50002, 38.301925999082073],
            [-107.500655, 38.66855299907936],
            [-107.500606, 39.217915999075608],
            [-107.428073, 39.257595999075335],
            [-107.394486, 39.256298999075348],
            [-107.375608, 39.197930999075723],
            [-107.28249, 39.118717999076267],
            [-107.06632, 39.118804999076247],
            [-107.001622, 39.042272999076772],
            [-106.906422, 38.992090999077092],
            [-106.861687, 39.009438999076991],
            [-106.801874, 38.978302999077208],
            [-106.749357, 39.042527999076761],
            [-106.694969, 39.055666999076685],
            [-106.599214, 38.997993999077067],
            [-106.554211, 38.998710999077069],
            [-106.465032, 38.910353999077664],
            [-106.326099, 38.911003999077657],
            [-106.406987, 38.82933399907823],
            [-106.417431, 38.725674999078954],
            [-106.452126, 38.698776999079151],
            [-106.379899, 38.638482999079592],
            [-106.354185, 38.533383999080336],
            [-106.24694, 38.422767999081174],
            [-107.000601, 38.425618999081145],
            [-107.001119, 38.147071999083266],
            [-107.56862, 38.147266999083257],
            [-107.580243, 38.230823999082617],
            [-107.63504, 38.301895999082078]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "027",
        COUNTYNS: "00294452",
        AFFGEOID: "0500000US12027",
        GEOID: "12027",
        NAME: "DeSoto",
        LSAD: "06",
        ALAND: 1649978043,
        AWATER: 6247257
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.056497, 27.207768999232155],
            [-82.055753, 27.338263999229671],
            [-81.563696, 27.340679999229621],
            [-81.562202, 27.033835999235503],
            [-81.983242, 27.035249999235468],
            [-82.057469, 27.032118999235532],
            [-82.056497, 27.207768999232155]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "209",
        COUNTYNS: "00516951",
        AFFGEOID: "0500000US21209",
        GEOID: "21209",
        NAME: "Scott",
        LSAD: "06",
        ALAND: 729781719,
        AWATER: 9487693
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.740594, 38.352414999081688],
            [-84.705988, 38.370101999081555],
            [-84.580539, 38.473038999080785],
            [-84.557375, 38.492916999080641],
            [-84.467748, 38.393057999081378],
            [-84.433007, 38.299057999082095],
            [-84.442661, 38.283235999082208],
            [-84.419918, 38.214244999082737],
            [-84.401847, 38.20788899908279],
            [-84.609533, 38.13354599908336],
            [-84.625079, 38.116396999083491],
            [-84.647821, 38.17660599908303],
            [-84.725168, 38.195425999082886],
            [-84.730989, 38.216337999082725],
            [-84.740594, 38.352414999081688]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "131",
        COUNTYNS: "00069181",
        AFFGEOID: "0500000US05131",
        GEOID: "05131",
        NAME: "Sebastian",
        LSAD: "06",
        ALAND: 1377601663,
        AWATER: 39693809
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.442925861690014, 35.062506208685143],
            [-94.435316, 35.27589299911007],
            [-94.431515, 35.369590999109064],
            [-94.433913443832395, 35.386361769007593],
            [-94.43488928759669, 35.393185214696302],
            [-94.405656, 35.449270999108201],
            [-94.342481, 35.371832999109046],
            [-94.24234, 35.355236999109223],
            [-94.233765, 35.354071999109237],
            [-94.135113, 35.399245999108743],
            [-94.142437, 35.450239999108206],
            [-94.074895, 35.445402999108268],
            [-94.07983, 35.28689999910997],
            [-94.064873, 35.199847999110915],
            [-94.028547, 35.213825999110774],
            [-94.028653, 35.212130999110784],
            [-94.031236, 35.14085699911157],
            [-94.139307, 35.143360999111536],
            [-94.140571, 35.099677999112025],
            [-94.228244, 35.057594999112496],
            [-94.230524, 34.9685269991135],
            [-94.447509535784292, 34.93397621127108],
            [-94.442925861690014, 35.062506208685143]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "153",
        COUNTYNS: "00450391",
        AFFGEOID: "0500000US18153",
        GEOID: "18153",
        NAME: "Sullivan",
        LSAD: "06",
        ALAND: 1158058307,
        AWATER: 18096342
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.640435, 39.166726999075948],
            [-87.577029, 39.211122999075656],
            [-87.593486, 39.247451999075402],
            [-87.59474584540601, 39.259383615558342],
            [-87.240379, 39.259063999075323],
            [-87.240737, 39.171772999075905],
            [-87.241027, 38.90787399907768],
            [-87.260257, 38.90162299907773],
            [-87.528717663697904, 38.905943785366901],
            [-87.527645, 38.90768799907768],
            [-87.529496, 38.971924999077245],
            [-87.579117, 39.001606999077048],
            [-87.572588, 39.057285999076662],
            [-87.625379, 39.101805999076369],
            [-87.638293413744094, 39.157492546278498],
            [-87.640435, 39.166726999075948]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "069",
        COUNTYNS: "00758489",
        AFFGEOID: "0500000US29069",
        GEOID: "29069",
        NAME: "Dunklin",
        LSAD: "06",
        ALAND: 1401432378,
        AWATER: 15767988
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.339343, 36.047111999101986],
            [-90.294492, 36.112948999101334],
            [-90.235585, 36.139473999101078],
            [-90.220425, 36.184763999100618],
            [-90.189127982216903, 36.198986607767864],
            [-90.155928, 36.214073999100307],
            [-90.114922, 36.265594999099818],
            [-90.06398, 36.303037999099452],
            [-90.063526, 36.356910999098908],
            [-90.066136, 36.386271999098632],
            [-90.131038, 36.415068999098352],
            [-90.141399, 36.459873999097915],
            [-90.153871, 36.495343999097585],
            [-90.220749053915398, 36.495937591292083],
            [-90.209954, 36.542033999097143],
            [-90.148398, 36.630655999096305],
            [-89.958786, 36.629330999096311],
            [-89.96799, 36.403274999098471],
            [-89.96131, 36.388802999098608],
            [-89.9593752951737, 35.999014109259377],
            [-90.103842, 35.998142999102484],
            [-90.2889479152728, 35.996514003313898],
            [-90.368718, 35.995811999102521],
            [-90.339343, 36.047111999101986]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "019",
        COUNTYNS: "00974108",
        AFFGEOID: "0500000US36019",
        GEOID: "36019",
        NAME: "Clinton",
        LSAD: "06",
        ALAND: 2687771451,
        AWATER: 206611024
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.02743141336849, 44.997365358296456],
            [-73.874597, 45.001222999056765],
            [-73.639718, 45.00346399905677],
            [-73.343124, 45.010839999056756],
            [-73.34474, 44.970467999056758],
            [-73.338979, 44.917680999056749],
            [-73.379822, 44.857036999056767],
            [-73.365678, 44.826450999056782],
            [-73.342012483950001, 44.808075537717777],
            [-73.33443, 44.802187999056784],
            [-73.357671, 44.751017999056792],
            [-73.36556, 44.700296999056789],
            [-73.389966, 44.61961999905683],
            [-73.367275, 44.567544999056857],
            [-73.362682812743799, 44.562455220743551],
            [-73.3479832324265, 44.546162852653865],
            [-73.463838, 44.53768099905686],
            [-73.496604, 44.486080999056881],
            [-73.669281, 44.441354999056912],
            [-73.909687, 44.42969899905691],
            [-73.966148, 44.709117999056794],
            [-73.986382, 44.7077729990568],
            [-74.02743141336849, 44.997365358296456]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "163",
        COUNTYNS: "01480166",
        AFFGEOID: "0500000US51163",
        GEOID: "51163",
        NAME: "Rockbridge",
        LSAD: "06",
        ALAND: 1544997746,
        AWATER: 8878371
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.674162, 37.763092999086318],
            [-79.606341, 37.862110999085516],
            [-79.647589, 37.874537999085419],
            [-79.533622, 38.003628999084384],
            [-79.482668, 38.086053999083731],
            [-79.183978, 37.914193999085093],
            [-79.15739, 37.891002999085266],
            [-79.144388, 37.856095999085561],
            [-79.172131, 37.803096999085994],
            [-79.231557, 37.813174999085909],
            [-79.345235, 37.660624999087155],
            [-79.436947, 37.617056999087517],
            [-79.434285, 37.571984999087896],
            [-79.498699, 37.532796999088234],
            [-79.683554, 37.661762999087159],
            [-79.674162, 37.763092999086318]
          ],
          [
            [-79.455667, 37.765218999086301],
            [-79.42215, 37.784787999086134],
            [-79.414948, 37.788626999086112],
            [-79.437337, 37.794783999086057],
            [-79.461582, 37.786355999086133],
            [-79.455667, 37.765218999086301]
          ],
          [
            [-79.366105, 37.726314999086625],
            [-79.371705, 37.695746999086872],
            [-79.326012, 37.743268999086474],
            [-79.33113, 37.75990399908634],
            [-79.381509, 37.742148999086481],
            [-79.366105, 37.726314999086625]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "043",
        COUNTYNS: "01213667",
        AFFGEOID: "0500000US42043",
        GEOID: "42043",
        NAME: "Dauphin",
        LSAD: "06",
        ALAND: 1359893289,
        AWATER: 85780786
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.01627, 40.425172999068522],
            [-76.952838, 40.462443999068334],
            [-76.992488, 40.567533999067784],
            [-76.949414, 40.628162999067492],
            [-76.919272, 40.603543999067611],
            [-76.701624, 40.658081999067342],
            [-76.535414, 40.555156999067862],
            [-76.67804, 40.474716999068264],
            [-76.578128, 40.217853999069611],
            [-76.566339, 40.196643999069735],
            [-76.678137, 40.148733999069989],
            [-76.697664, 40.156341999069951],
            [-76.72162, 40.120069999070147],
            [-76.756379, 40.177051999069825],
            [-76.824696, 40.202318999069696],
            [-76.859023, 40.226327999069561],
            [-76.884916, 40.247264999069458],
            [-76.924152, 40.289165999069233],
            [-76.91497, 40.328483999069022],
            [-76.916811, 40.331675999069006],
            [-77.020799, 40.366229999068828],
            [-77.01627, 40.425172999068522]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "021",
        COUNTYNS: "00063759",
        AFFGEOID: "0500000US05021",
        GEOID: "05021",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 1656214466,
        AWATER: 5097734
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.749667, 36.280535999099662],
            [-90.748637, 36.412763999098367],
            [-90.784244155131205, 36.498462199226651],
            [-90.765672, 36.498493999097555],
            [-90.576179065567302, 36.498405926895551],
            [-90.494575, 36.498367999097553],
            [-90.220749053915398, 36.495937591292083],
            [-90.153871, 36.495343999097585],
            [-90.141399, 36.459873999097915],
            [-90.131038, 36.415068999098352],
            [-90.066136, 36.386271999098632],
            [-90.063526, 36.356910999098908],
            [-90.06398, 36.303037999099452],
            [-90.114922, 36.265594999099818],
            [-90.155928, 36.214073999100307],
            [-90.189127982216903, 36.198986607767864],
            [-90.32096, 36.200574999100461],
            [-90.31982, 36.259143999099877],
            [-90.806615, 36.266864999099788],
            [-90.749667, 36.280535999099662]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "135",
        COUNTYNS: "00835889",
        AFFGEOID: "0500000US31135",
        GEOID: "31135",
        NAME: "Perkins",
        LSAD: "06",
        ALAND: 2287828022,
        AWATER: 2840176
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.051761, 41.003889999065706],
            [-101.249975, 41.003949999065696],
            [-101.247963, 40.697801999067146],
            [-101.345365, 40.69809299906715],
            [-102.051294151579, 40.697546831456151],
            [-102.051292000207013, 40.749585993940599],
            [-102.051292, 40.749590999066889],
            [-102.051614, 41.00237699906571],
            [-102.051717516377991, 41.00237696668961],
            [-102.051761, 41.003889999065706]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "021",
        COUNTYNS: "00882229",
        AFFGEOID: "0500000US34021",
        GEOID: "34021",
        NAME: "Mercer",
        LSAD: "06",
        ALAND: 581086519,
        AWATER: 11647674
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.928110569091373, 40.339828446758261],
            [-74.856262, 40.346695999068935],
            [-74.748147, 40.424155999068518],
            [-74.722061, 40.375204999068785],
            [-74.622682, 40.384335999068725],
            [-74.62024, 40.373748999068788],
            [-74.622462, 40.32479999906905],
            [-74.484962, 40.25332499906942],
            [-74.543268, 40.216691999069624],
            [-74.614576, 40.182382999069809],
            [-74.587835, 40.138253999070045],
            [-74.710099, 40.181282999069822],
            [-74.710782, 40.168044999069885],
            [-74.704177, 40.168478999069883],
            [-74.706269, 40.161792999069917],
            [-74.71847, 40.150028999069981],
            [-74.723379297151496, 40.152895545301675],
            [-74.721604, 40.153809999069964],
            [-74.748497657257801, 40.184908613173796],
            [-74.760605, 40.198908999069729],
            [-74.823907, 40.241507999069484],
            [-74.856508, 40.277406999069292],
            [-74.90331, 40.315606999069097],
            [-74.928110569091373, 40.339828446758261]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "139",
        COUNTYNS: "00835891",
        AFFGEOID: "0500000US31139",
        GEOID: "31139",
        NAME: "Pierce",
        LSAD: "06",
        ALAND: 1484707285,
        AWATER: 3481943
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.834431, 42.437683999060397],
            [-97.485298, 42.438577999060392],
            [-97.36759, 42.438774999060385],
            [-97.367724, 42.351860999060648],
            [-97.368404, 42.090921999061464],
            [-97.834536, 42.089749999061461],
            [-97.834431, 42.437683999060397]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "023",
        COUNTYNS: "00066983",
        AFFGEOID: "0500000US05023",
        GEOID: "05023",
        NAME: "Cleburne",
        LSAD: "06",
        ALAND: 1434168142,
        AWATER: 98893718
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.24052, 35.712189999105426],
            [-91.837179, 35.704931999105504],
            [-91.789823, 35.711733999105434],
            [-91.793958, 35.534113999107298],
            [-91.795855, 35.455573999108147],
            [-91.850144, 35.436441999108354],
            [-91.851958, 35.358286999109183],
            [-92.111463, 35.36272499910914],
            [-92.252759, 35.363717999109134],
            [-92.24052, 35.712189999105426]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "051",
        COUNTYNS: "01034211",
        AFFGEOID: "0500000US38051",
        GEOID: "38051",
        NAME: "McIntosh",
        LSAD: "06",
        ALAND: 2524546488,
        AWATER: 51817666
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.878431, 46.28281099905778],
            [-99.037367, 46.283150999057781],
            [-99.003118, 46.282897999057774],
            [-99.0057543501525, 45.93994430818573],
            [-99.092868, 45.940183999057325],
            [-99.34496, 45.940298999057326],
            [-99.490254, 45.94036199905733],
            [-99.61116, 45.94109799905732],
            [-99.718071042882201, 45.940907002553715],
            [-99.718073, 45.940906999057319],
            [-99.88006209480659, 45.941670914859522],
            [-99.878431, 46.28281099905778]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "045",
        COUNTYNS: "01074035",
        AFFGEOID: "0500000US39045",
        GEOID: "39045",
        NAME: "Fairfield",
        LSAD: "06",
        ALAND: 1306421163,
        AWATER: 10746965
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.824254, 39.794995999072],
            [-82.802476, 39.822952999071845],
            [-82.799213, 39.859683999071628],
            [-82.810797, 39.941161999071156],
            [-82.782495, 39.939661999071156],
            [-82.493407, 39.926062999071242],
            [-82.462813, 39.930379999071228],
            [-82.472402, 39.837222999071756],
            [-82.396594, 39.833056999071772],
            [-82.374531, 39.654958999072846],
            [-82.490329, 39.661703999072806],
            [-82.496123, 39.60284899907316],
            [-82.617546, 39.608604999073123],
            [-82.620088, 39.563986999073393],
            [-82.731518, 39.554444999073461],
            [-82.842953, 39.561479999073427],
            [-82.824254, 39.794995999072]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "065",
        COUNTYNS: "01074045",
        AFFGEOID: "0500000US39065",
        GEOID: "39065",
        NAME: "Hardin",
        LSAD: "06",
        ALAND: 1218342693,
        AWATER: 629922
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.880063, 40.819918999066566],
            [-83.515883, 40.818133999066568],
            [-83.496526, 40.809885999066609],
            [-83.495298, 40.701533999067109],
            [-83.419836, 40.686822999067203],
            [-83.415977, 40.525974999068005],
            [-83.418378, 40.505231999068101],
            [-83.520227, 40.504076999068104],
            [-83.859178, 40.530651999067977],
            [-83.879932, 40.538707999067938],
            [-83.880194, 40.644689999067403],
            [-83.880063, 40.819918999066566]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "141",
        COUNTYNS: "00758525",
        AFFGEOID: "0500000US29141",
        GEOID: "29141",
        NAME: "Morgan",
        LSAD: "06",
        ALAND: 1547854690,
        AWATER: 42426936
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.067291, 38.529994999080373],
            [-93.059974, 38.693076999079182],
            [-92.839837, 38.683973999079264],
            [-92.841871, 38.59679199907989],
            [-92.625189, 38.430777999081101],
            [-92.633756, 38.348081999081728],
            [-92.689834, 38.348471999081724],
            [-92.695886, 38.222030999082683],
            [-92.764539, 38.189618999082931],
            [-92.80696, 38.191156999082921],
            [-93.01499, 38.189774999082928],
            [-93.077692, 38.263091999082377],
            [-93.067291, 38.529994999080373]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "029",
        COUNTYNS: "00066846",
        AFFGEOID: "0500000US05029",
        GEOID: "05029",
        NAME: "Conway",
        LSAD: "06",
        ALAND: 1430330693,
        AWATER: 37331621
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.040709, 35.108950999111919],
            [-92.896109, 35.170677999111234],
            [-92.855, 35.172776999111214],
            [-92.850624, 35.462949999108062],
            [-92.479268, 35.455356999108147],
            [-92.481469, 35.368391999109086],
            [-92.528074, 35.230558999110585],
            [-92.521445, 35.114696999111864],
            [-92.554685, 35.113276999111875],
            [-92.618827, 35.078014999112277],
            [-93.040525, 35.076922999112284],
            [-93.040709, 35.108950999111919]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "261",
        COUNTYNS: "01419984",
        AFFGEOID: "0500000US02261",
        GEOID: "02261",
        NAME: "Valdez-Cordova",
        LSAD: "05",
        ALAND: 88635832004,
        AWATER: 15763249807
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-148.738363, 60.848545999198159],
            [-148.472065, 60.848519999198174],
            [-148.462471, 61.426266999208323],
            [-147.206597, 61.426264999208328],
            [-147.206605, 61.470662999209125],
            [-146.942559, 61.470674999209137],
            [-146.960952, 61.817271999215407],
            [-146.960978, 62.163851999221798],
            [-146.979807, 62.250496999223394],
            [-146.423984, 62.250510999223387],
            [-146.436884, 62.510452999228292],
            [-146.436899, 62.856990999234888],
            [-146.494296, 62.957863999236835],
            [-146.494311, 63.16897499924093],
            [-146.32926, 63.190561999241353],
            [-146.140827, 63.184012999241233],
            [-146.111486, 63.217761999241894],
            [-145.154395, 63.217780999241882],
            [-145.154385, 63.131148999240203],
            [-144.580159, 63.116930999239926],
            [-143.132337, 63.116977999239936],
            [-143.098876, 63.007890999237802],
            [-143.001837, 62.995622999237561],
            [-143.094868, 62.879613999235325],
            [-143.20757, 62.829090999234367],
            [-143.009391, 62.767249999233172],
            [-143.151065, 62.630678999230568],
            [-143.104996, 62.614904999230269],
            [-142.848579, 62.600546999229998],
            [-142.742485, 62.657812999231091],
            [-142.719523, 62.713507999232142],
            [-142.505637, 62.68382199923159],
            [-142.313564, 62.683827999231582],
            [-142.313555, 62.597192999229925],
            [-142.126131, 62.597191999229935],
            [-142.125838, 62.510555999228281],
            [-141.977353, 62.510558999228294],
            [-141.977336, 62.163982999221794],
            [-142.014987, 62.117645999220926],
            [-141.831812, 62.117177999220907],
            [-141.831804, 61.904052999216994],
            [-141.002020469675017, 61.904047027059804],
            [-141.001849665644016, 60.39168801108665],
            [-141.213444, 60.391675999190348],
            [-141.213451, 60.43526799919109],
            [-141.790601, 60.435237999191088],
            [-141.790619, 60.521898999192558],
            [-143.193519, 60.52182799919256],
            [-143.893325, 60.024165999184213],
            [-143.893326, 59.9867216050772],
            [-143.897029, 59.985937999183591],
            [-144.005879, 60.01298099918403],
            [-144.052539, 60.041758999184502],
            [-144.1103, 60.098938999185435],
            [-144.186745, 60.116966999185756],
            [-144.348913, 60.091183999185333],
            [-144.429249, 60.148019999186268],
            [-144.555093, 60.178484999186779],
            [-144.654899, 60.204881999187215],
            [-144.929327, 60.228252999187603],
            [-144.957848, 60.288151999188614],
            [-145.089139, 60.32001399918915],
            [-145.136728, 60.296218999188739],
            [-145.254749, 60.311447999188999],
            [-145.380064, 60.352828999189697],
            [-145.51081, 60.318295999189118],
            [-145.639204, 60.301970999188839],
            [-145.831202, 60.350292999189655],
            [-145.988546, 60.387426999190282],
            [-146.088134, 60.364986999189895],
            [-146.197229, 60.348293999189629],
            [-146.232681, 60.338850999189461],
            [-146.393256, 60.327475999189268],
            [-146.490804, 60.29493899918873],
            [-146.607692, 60.241181999187823],
            [-146.650852, 60.242981999187855],
            [-146.694034, 60.279607999188464],
            [-146.916487, 60.290972999188661],
            [-146.993353, 60.240079999187806],
            [-147.145205, 60.171320999186655],
            [-147.257795, 60.107882999185598],
            [-147.376397, 60.016191999184088],
            [-147.339794, 59.962101999183183],
            [-147.452217, 59.954010999183062],
            [-147.470281, 59.906731999182284],
            [-147.391846, 59.877759999181819],
            [-147.508309, 59.841956999181214],
            [-147.646045, 59.817827999180821],
            [-147.765122, 59.795953999180462],
            [-147.876475, 59.763892999179944],
            [-147.92924, 59.783874999180277],
            [-147.913316, 59.837180999181136],
            [-147.855084, 59.871914999181712],
            [-147.956775, 59.959399999183148],
            [-148.101239, 59.952793999183044],
            [-148.25406, 59.932356999182694],
            [-148.220554, 59.976349999183412],
            [-148.313962, 60.033858999184375],
            [-148.401666, 59.977835999183448],
            [-148.478881, 59.935805999182769],
            [-148.634777, 59.915746999182417],
            [-148.649529585787008, 59.923553179282472],
            [-148.58618, 59.938584999182815],
            [-148.563026, 60.126313999185918],
            [-148.563715, 60.415171999190754],
            [-148.66447, 60.415182999190741],
            [-148.657119, 60.732975999196171],
            [-148.745618, 60.733006999196164],
            [-148.738363, 60.848545999198159]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "017",
        COUNTYNS: "01605074",
        AFFGEOID: "0500000US56017",
        GEOID: "56017",
        NAME: "Hot Springs",
        LSAD: "06",
        ALAND: 5190589691,
        AWATER: 5964358
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.312949, 43.813292999057502],
            [-109.093603, 43.817618999057508],
            [-109.092878, 43.904473999057402],
            [-108.912783, 43.904753999057398],
            [-108.915985, 43.990853999057293],
            [-108.791779, 43.991052999057288],
            [-108.792277, 44.079615999057189],
            [-108.550758, 44.079402999057216],
            [-108.490254, 44.079759999057217],
            [-108.489431, 43.991865999057289],
            [-108.427064, 43.992053999057291],
            [-108.429563, 43.903354999057392],
            [-108.30936, 43.903254999057395],
            [-108.309694, 43.846618999057462],
            [-108.185255, 43.84825499905746],
            [-108.174938, 43.818967999057499],
            [-107.956448, 43.818665999057494],
            [-107.956577, 43.733445999057615],
            [-107.715876, 43.733342999057605],
            [-107.716218, 43.64576599905773],
            [-107.595157, 43.646426999057745],
            [-107.595105, 43.501396999057974],
            [-107.595137, 43.472278999058027],
            [-108.156285, 43.470627999058024],
            [-108.337861, 43.459764999058038],
            [-108.456973, 43.474278999058008],
            [-108.456962, 43.503155999057974],
            [-108.576167, 43.503255999057977],
            [-108.576167, 43.532555999057926],
            [-108.695072, 43.532254999057933],
            [-108.694672, 43.575754999057857],
            [-108.814276, 43.575554999057857],
            [-108.814276, 43.605254999057799],
            [-108.934141, 43.619162999057785],
            [-109.171638, 43.619763999057774],
            [-109.171448, 43.69919799905766],
            [-109.312949, 43.813292999057502]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "061",
        COUNTYNS: "00974129",
        AFFGEOID: "0500000US36061",
        GEOID: "36061",
        NAME: "New York",
        LSAD: "06",
        ALAND: 58681329,
        AWATER: 28550878
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.025635529540793, 40.733224193728979],
            [-74.023491533732198, 40.737453164554246],
            [-74.013784, 40.756600999066855],
            [-73.984592494680186, 40.797543327618968],
            [-73.971208018282397, 40.816315625387475],
            [-73.968082, 40.820699999066548],
            [-73.965834517046304, 40.824745258625136],
            [-73.912456, 40.796095999066672],
            [-73.920999, 40.781748999066735],
            [-73.962478, 40.736801999066955],
            [-73.962645, 40.722746999067027],
            [-73.965572, 40.718949999067043],
            [-74.030927085683885, 40.722786749560619],
            [-74.025635529540793, 40.733224193728979]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "089",
        COUNTYNS: "00659489",
        AFFGEOID: "0500000US27089",
        GEOID: "27089",
        NAME: "Marshall",
        LSAD: "06",
        ALAND: 4597384434,
        AWATER: 98019666
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.148103466133591, 48.540744045250854],
            [-97.147723568300904, 48.543891682714758],
            [-96.387835, 48.544307999064181],
            [-95.602315, 48.538886999064133],
            [-95.593788, 48.172915999062717],
            [-96.500843, 48.17410799906272],
            [-97.1458449594749, 48.173223482053224],
            [-97.141910387198294, 48.19362761793731],
            [-97.129533, 48.257814999063044],
            [-97.137904, 48.344584999063358],
            [-97.139173, 48.430527999063699],
            [-97.149122, 48.532304999064124],
            [-97.148103466133591, 48.540744045250854]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "035",
        COUNTYNS: "01266980",
        AFFGEOID: "0500000US46035",
        GEOID: "46035",
        NAME: "Davison",
        LSAD: "06",
        ALAND: 1127942470,
        AWATER: 3675453
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.325242, 43.85105799905746],
            [-97.967279, 43.850685999057447],
            [-97.965012, 43.499039999057992],
            [-98.114758, 43.498296999057978],
            [-98.320595, 43.498650999057972],
            [-98.325242, 43.85105799905746]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "025",
        COUNTYNS: "01639733",
        AFFGEOID: "0500000US47025",
        GEOID: "47025",
        NAME: "Claiborne",
        LSAD: "06",
        ALAND: 1125581216,
        AWATER: 18090601
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.987610950682793, 36.589592285169182],
            [-83.930760783943896, 36.587694257209094],
            [-83.894421, 36.586480999096715],
            [-83.690714, 36.582580999096756],
            [-83.675413, 36.600813999096573],
            [-83.472093573809602, 36.599475731155884],
            [-83.398303, 36.534884999097201],
            [-83.369401, 36.459185999097926],
            [-83.3868, 36.413185999098367],
            [-83.532504, 36.353384999098949],
            [-83.658007, 36.331981999099163],
            [-83.667408, 36.344380999099037],
            [-83.73301, 36.344979999099039],
            [-83.805515, 36.435878999098151],
            [-83.905638, 36.419978999098312],
            [-83.922618, 36.427778999098237],
            [-83.943139, 36.473563999097784],
            [-83.95696, 36.501554999097522],
            [-83.984327, 36.560101999096965],
            [-83.987610950682793, 36.589592285169182]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "029",
        COUNTYNS: "01247991",
        AFFGEOID: "0500000US45029",
        GEOID: "45029",
        NAME: "Colleton",
        LSAD: "06",
        ALAND: 2736299925,
        AWATER: 198896760
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.082291, 33.026629999137363],
            [-80.937157, 33.102583999136364],
            [-80.896074, 33.061409999136899],
            [-80.895426, 33.125347999136046],
            [-80.797912, 33.176943999135382],
            [-80.790296, 33.180839999135323],
            [-80.706085, 33.154545999135664],
            [-80.6181, 33.064753999136862],
            [-80.474788, 33.064393999136868],
            [-80.390533, 33.043908999137138],
            [-80.418813, 32.967263999138154],
            [-80.40134, 32.858465999139618],
            [-80.45363, 32.73989999914123],
            [-80.389401, 32.652819999142416],
            [-80.429065, 32.623601999142821],
            [-80.428633, 32.613542999142972],
            [-80.426983, 32.608753999143026],
            [-80.424087, 32.605293999143072],
            [-80.426009, 32.603735999143098],
            [-80.418971, 32.603299999143104],
            [-80.4151, 32.604948999143076],
            [-80.407357, 32.610147999143003],
            [-80.395903, 32.616972999142916],
            [-80.393114, 32.617819999142895],
            [-80.389215, 32.616236999142927],
            [-80.38854, 32.612753999142967],
            [-80.394528, 32.60569899914308],
            [-80.398401, 32.599696999143148],
            [-80.399621, 32.596100999143196],
            [-80.400003, 32.587806999143318],
            [-80.398138, 32.582398999143386],
            [-80.393325, 32.575270999143484],
            [-80.394654, 32.569520999143577],
            [-80.401039, 32.570233999143561],
            [-80.405802, 32.568309999143587],
            [-80.409699, 32.564392999143642],
            [-80.418467, 32.559926999143705],
            [-80.420442, 32.558047999143724],
            [-80.422201, 32.553488999143795],
            [-80.421662, 32.54714299914388],
            [-80.418903, 32.543063999143946],
            [-80.409735, 32.536989999144012],
            [-80.411927, 32.534079999144062],
            [-80.407846, 32.523447999144217],
            [-80.406851, 32.507752999144422],
            [-80.408529, 32.502780999144498],
            [-80.51408, 32.513896999144343],
            [-80.551504, 32.565768999143621],
            [-80.659751, 32.612682999142976],
            [-80.67115, 32.671641999142174],
            [-80.826309, 32.704320999141721],
            [-80.900059, 32.832631999139977],
            [-80.986703, 32.900673999139045],
            [-81.082291, 33.026629999137363]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "041",
        COUNTYNS: "00303633",
        AFFGEOID: "0500000US12041",
        GEOID: "12041",
        NAME: "Gilchrist",
        LSAD: "06",
        ALAND: 905705520,
        AWATER: 14362154
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.949707, 29.75440899918652],
            [-82.920608, 29.824142999185355],
            [-82.893371, 29.826838999185309],
            [-82.879802, 29.886846999184314],
            [-82.863266, 29.911049999183913],
            [-82.800477, 29.932125999183576],
            [-82.770379, 29.928108999183632],
            [-82.768445, 29.89700699918415],
            [-82.658554, 29.830143999185253],
            [-82.656301, 29.564810999189692],
            [-82.756458, 29.562466999189738],
            [-82.77291, 29.584878999189357],
            [-82.856518, 29.584242999189367],
            [-82.937329, 29.591195999189249],
            [-82.954879, 29.58560499918935],
            [-82.949707, 29.75440899918652]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "115",
        COUNTYNS: "01074070",
        AFFGEOID: "0500000US39115",
        GEOID: "39115",
        NAME: "Morgan",
        LSAD: "06",
        ALAND: 1078539439,
        AWATER: 13873466
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.076639, 39.770959999072147],
            [-81.697442, 39.755571999072245],
            [-81.639886, 39.753419999072257],
            [-81.6432, 39.665680999072784],
            [-81.586132, 39.663992999072789],
            [-81.588185, 39.586972999073268],
            [-81.712706, 39.585106999073275],
            [-81.708527, 39.480774999073923],
            [-81.823158, 39.494071999073832],
            [-81.844863, 39.450215999074103],
            [-82.055779, 39.460052999074044],
            [-82.047528, 39.550824999073484],
            [-82.024219, 39.724685999072442],
            [-82.080224, 39.727672999072411],
            [-82.076639, 39.770959999072147]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "007",
        COUNTYNS: "00695728",
        AFFGEOID: "0500000US28007",
        GEOID: "28007",
        NAME: "Attala",
        LSAD: "06",
        ALAND: 1903529288,
        AWATER: 4425860
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.896363, 32.932601999138626],
            [-89.749441, 33.214477999134878],
            [-89.701514, 33.28535899913394],
            [-89.64528, 33.285514999133952],
            [-89.453808, 33.285941999133946],
            [-89.316408, 33.285555999133955],
            [-89.317855, 33.108870999136279],
            [-89.319163, 32.931653999138639],
            [-89.730154, 32.929923999138666],
            [-89.730016, 32.885071999139271],
            [-89.965882, 32.879572999139342],
            [-89.896363, 32.932601999138626]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "44",
        COUNTYFP: "009",
        COUNTYNS: "01219782",
        AFFGEOID: "0500000US44009",
        GEOID: "44009",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 852825659,
        AWATER: 604771644
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-71.631472, 41.166677999064987],
              [-71.593342, 41.237427999064685],
              [-71.54541, 41.242729999064665],
              [-71.534084, 41.181861999064921],
              [-71.51921, 41.149623999065057],
              [-71.5937, 41.146338999065073],
              [-71.631472, 41.166677999064987]
            ]
          ],
          [
            [
              [-71.860513, 41.320247999064335],
              [-71.835951, 41.353934999064194],
              [-71.839649, 41.412118999063964],
              [-71.797683, 41.416708999063935],
              [-71.791719061747202, 41.545772003398433],
              [-71.789358672742296, 41.596852159405927],
              [-71.484427, 41.60259099906321],
              [-71.380542, 41.650325999063028],
              [-71.408433, 41.472487999063709],
              [-71.417212791483692, 41.456031311838181],
              [-71.428652, 41.454157999063789],
              [-71.455371, 41.407961999063971],
              [-71.483295, 41.371721999064121],
              [-71.555381, 41.373315999064097],
              [-71.624505, 41.360869999064171],
              [-71.701631, 41.336967999064264],
              [-71.729296576200397, 41.333284001778075],
              [-71.785957, 41.325738999064313],
              [-71.862772, 41.309790999064369],
              [-71.860513, 41.320247999064335]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "183",
        COUNTYNS: "01383877",
        AFFGEOID: "0500000US48183",
        GEOID: "48183",
        NAME: "Gregg",
        LSAD: "06",
        ALAND: 708030600,
        AWATER: 6227994
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.986585, 32.537323999144014],
            [-94.820223, 32.590647999143272],
            [-94.746136, 32.667539999142221],
            [-94.70179, 32.652208999142431],
            [-94.701986, 32.421781999145622],
            [-94.579219, 32.394915999146001],
            [-94.591703, 32.365571999146411],
            [-94.985411, 32.370819999146342],
            [-94.986935, 32.537245999144012],
            [-94.986585, 32.537323999144014]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "033",
        COUNTYNS: "00069161",
        AFFGEOID: "0500000US05033",
        GEOID: "05033",
        NAME: "Crawford",
        LSAD: "06",
        ALAND: 1531188224,
        AWATER: 31076498
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.493040072427007, 35.759166473432231],
            [-94.212507, 35.752919999104996],
            [-94.124258, 35.764635999104883],
            [-93.962892, 35.760929999104924],
            [-93.910836, 35.759751999104928],
            [-93.984204, 35.660782999105955],
            [-94.073694, 35.66240299910595],
            [-94.076209, 35.575488999106859],
            [-94.023014, 35.574203999106878],
            [-94.074895, 35.445402999108268],
            [-94.142437, 35.450239999108206],
            [-94.135113, 35.399245999108743],
            [-94.233765, 35.354071999109237],
            [-94.24234, 35.355236999109223],
            [-94.342481, 35.371832999109046],
            [-94.405656, 35.449270999108201],
            [-94.43488928759669, 35.393185214696302],
            [-94.449696, 35.496718999107699],
            [-94.473119390646701, 35.638547151635606],
            [-94.493040072427007, 35.759166473432231]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "127",
        COUNTYNS: "01213692",
        AFFGEOID: "0500000US42127",
        GEOID: "42127",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 1879249061,
        AWATER: 64832669
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.440658, 41.258124999064599],
            [-75.462411, 41.641586999063058],
            [-75.483149270958904, 41.999398163584466],
            [-75.477144, 41.999406999061762],
            [-75.359859929150502, 41.993685887966393],
            [-75.341125, 41.992771999061794],
            [-75.291762, 41.947091999061946],
            [-75.263005, 41.885108999062155],
            [-75.190203, 41.86245399906224],
            [-75.146664899285881, 41.850125929208588],
            [-75.113369, 41.840697999062321],
            [-75.074412, 41.802190999062461],
            [-75.053431, 41.752537999062639],
            [-75.049281, 41.64186199906306],
            [-75.046198605911684, 41.603764018965904],
            [-75.265712, 41.372381999064103],
            [-75.350648, 41.367693999064137],
            [-75.311143, 41.331438999064282],
            [-75.359184, 41.23920599906468],
            [-75.505607, 41.232538999064694],
            [-75.440658, 41.258124999064599]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "047",
        COUNTYNS: "00066853",
        AFFGEOID: "0500000US05047",
        GEOID: "05047",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1576930289,
        AWATER: 27936032
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.074895, 35.445402999108268],
            [-94.023014, 35.574203999106878],
            [-94.076209, 35.575488999106859],
            [-94.073694, 35.66240299910595],
            [-93.984204, 35.660782999105955],
            [-93.910836, 35.759751999104928],
            [-93.901163, 35.774092999104788],
            [-93.695981, 35.76864899910484],
            [-93.710076, 35.375241999109015],
            [-93.806849, 35.430010999108426],
            [-93.810087, 35.31309499910968],
            [-93.917127, 35.319045999109619],
            [-93.920167, 35.229426999110601],
            [-94.028547, 35.213825999110774],
            [-94.064873, 35.199847999110915],
            [-94.07983, 35.28689999910997],
            [-94.074895, 35.445402999108268]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "049",
        COUNTYNS: "01101812",
        AFFGEOID: "0500000US40049",
        GEOID: "40049",
        NAME: "Garvin",
        LSAD: "06",
        ALAND: 2077332600,
        AWATER: 30111858
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.668099, 34.855163999114765],
            [-96.932471, 34.854454999114786],
            [-96.932328, 34.636810999117287],
            [-97.200073, 34.622761999117451],
            [-97.143958, 34.506531999118806],
            [-97.35211, 34.506787999118806],
            [-97.562323, 34.507035999118798],
            [-97.562757, 34.68114699911677],
            [-97.668425, 34.681343999116763],
            [-97.668099, 34.855163999114765]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "071",
        COUNTYNS: "00066863",
        AFFGEOID: "0500000US05071",
        GEOID: "05071",
        NAME: "Johnson",
        LSAD: "06",
        ALAND: 1709103300,
        AWATER: 59195663
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.695981, 35.76864899910484],
            [-93.519204, 35.763520999104884],
            [-93.519575, 35.73442199910518],
            [-93.164452, 35.728830999105249],
            [-93.1678, 35.597694999106622],
            [-93.22117, 35.59893799910661],
            [-93.223633, 35.468592999108012],
            [-93.29591, 35.469914999107992],
            [-93.299014, 35.327424999109532],
            [-93.417895, 35.326778999109543],
            [-93.435463, 35.427870999108436],
            [-93.59459, 35.383687999108915],
            [-93.710076, 35.375241999109015],
            [-93.695981, 35.76864899910484]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "095",
        COUNTYNS: "00974145",
        AFFGEOID: "0500000US36095",
        GEOID: "36095",
        NAME: "Schoharie",
        LSAD: "06",
        ALAND: 1610503572,
        AWATER: 11676949
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.630631, 42.626673999059875],
            [-74.667512, 42.750709999059538],
            [-74.648298, 42.829557999059347],
            [-74.454911, 42.77297899905949],
            [-74.411384, 42.776489999059478],
            [-74.289446, 42.78380499905947],
            [-74.263314, 42.796533999059427],
            [-74.272295, 42.714269999059645],
            [-74.180274, 42.729978999059604],
            [-74.169725, 42.667425999059773],
            [-74.241572, 42.550801999060077],
            [-74.263185, 42.423212999060439],
            [-74.254303, 42.408206999060468],
            [-74.244692, 42.377158999060569],
            [-74.443506, 42.355016999060638],
            [-74.618895, 42.424388999060426],
            [-74.71158, 42.517798999060169],
            [-74.630631, 42.626673999059875]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "087",
        COUNTYNS: "00069165",
        AFFGEOID: "0500000US05087",
        GEOID: "05087",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 2160705134,
        AWATER: 7320899
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.944177, 36.091102999101537],
            [-93.887775, 36.235045999100109],
            [-93.869066, 36.307449999099404],
            [-93.815605, 36.306562999099398],
            [-93.657689, 36.30308199909944],
            [-93.619974, 36.201207999100454],
            [-93.551135, 36.126270999101195],
            [-93.479528, 36.125071999101195],
            [-93.445619, 36.080748999101644],
            [-93.450715, 35.967492999102795],
            [-93.523639, 35.881591999103676],
            [-93.519204, 35.763520999104884],
            [-93.695981, 35.76864899910484],
            [-93.901163, 35.774092999104788],
            [-93.910836, 35.759751999104928],
            [-93.962892, 35.760929999104924],
            [-93.944177, 36.091102999101537]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "467",
        COUNTYNS: "01384019",
        AFFGEOID: "0500000US48467",
        GEOID: "48467",
        NAME: "Van Zandt",
        LSAD: "06",
        ALAND: 2182212910,
        AWATER: 44164932
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.076801, 32.838488999139891],
            [-95.934433, 32.837216999139898],
            [-95.829581, 32.76696699914087],
            [-95.761537, 32.770313999140818],
            [-95.659432, 32.711440999141622],
            [-95.635017, 32.7203799991415],
            [-95.594541, 32.687025999141952],
            [-95.5943, 32.479857999144812],
            [-95.449165, 32.355232999146544],
            [-96.075899, 32.357504999146521],
            [-96.076801, 32.838488999139891]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "091",
        COUNTYNS: "00974143",
        AFFGEOID: "0500000US36091",
        GEOID: "36091",
        NAME: "Saratoga",
        LSAD: "06",
        ALAND: 2097880370,
        AWATER: 87571838
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.1601, 43.371531999058199],
            [-73.884139, 43.398040999058153],
            [-73.825828, 43.305347999058313],
            [-73.835811, 43.25375599905842],
            [-73.767498, 43.222122999058477],
            [-73.73997, 43.264708999058392],
            [-73.59496, 43.306117999058316],
            [-73.577876, 43.056887999058823],
            [-73.635463, 42.941289999059087],
            [-73.660433, 42.93253399905911],
            [-73.683074, 42.903775999059171],
            [-73.672355, 42.795790999059435],
            [-73.676762, 42.783276999059467],
            [-73.726663, 42.822576999059365],
            [-73.809369, 42.778868999059476],
            [-73.895938, 42.851075999059297],
            [-73.904386, 42.911162999059137],
            [-73.955458, 42.897305999059199],
            [-74.09298, 42.955867999059045],
            [-74.096897, 42.979377999059004],
            [-74.097467, 42.982933999059],
            [-74.140147, 43.253978999058418],
            [-74.1601, 43.371531999058199]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "109",
        COUNTYNS: "01101842",
        AFFGEOID: "0500000US40109",
        GEOID: "40109",
        NAME: "Oklahoma",
        LSAD: "06",
        ALAND: 1835846202,
        AWATER: 24730011
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.674026, 35.725969999105281],
            [-97.141072, 35.724440999105298],
            [-97.14149, 35.463976999108056],
            [-97.142114, 35.376859999108994],
            [-97.317832, 35.377285999108977],
            [-97.423566, 35.377315999108987],
            [-97.671369, 35.377150999108977],
            [-97.671383, 35.406351999108679],
            [-97.674026, 35.725969999105281]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "095",
        COUNTYNS: "01629472",
        AFFGEOID: "0500000US22095",
        GEOID: "22095",
        NAME: "St. John the Baptist",
        LSAD: "15",
        ALAND: 555050557,
        AWATER: 506474565
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.68885, 30.035952999181855],
            [-90.641998, 30.166429999179726],
            [-90.632811, 30.221408999178834],
            [-90.592021, 30.208323999179051],
            [-90.554147, 30.195627999179251],
            [-90.485826, 30.276991999177934],
            [-90.320464, 30.298984999177577],
            [-90.160636, 30.234336999178634],
            [-90.156256, 30.189612999179342],
            [-90.220354, 30.17559499917958],
            [-90.259889, 30.163409999179777],
            [-90.326637, 30.150187999179995],
            [-90.467788, 30.031708999181927],
            [-90.538132, 30.016883999182173],
            [-90.532203, 29.967937999182979],
            [-90.529601, 29.884996999184345],
            [-90.629134, 29.895229999184178],
            [-90.656312, 29.889245999184276],
            [-90.68885, 30.035952999181855]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "113",
        COUNTYNS: "01101844",
        AFFGEOID: "0500000US40113",
        GEOID: "40113",
        NAME: "Osage",
        LSAD: "06",
        ALAND: 5818542165,
        AWATER: 148734982
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.064107, 36.684206999095785],
            [-96.931943, 36.686096999095781],
            [-96.889475, 36.751229999095159],
            [-96.752389, 36.782090999094876],
            [-96.749838, 36.99898799909289],
            [-96.525582044282899, 36.998677967817699],
            [-96.500288, 36.998642999092894],
            [-96.217571, 36.999069999092903],
            [-96.000810206199603, 36.999201052020794],
            [-96.001171, 36.423685999098275],
            [-96.001055, 36.161293999100842],
            [-96.268287, 36.161979999100836],
            [-96.256624, 36.225675999100211],
            [-96.326732, 36.226550999100198],
            [-96.433812, 36.285888999099612],
            [-96.457684, 36.332663999099154],
            [-96.515229, 36.298331999099489],
            [-96.573481, 36.315708999099314],
            [-96.518709, 36.377575999098717],
            [-96.575697, 36.374346999098755],
            [-96.635727, 36.425907999098257],
            [-96.710769, 36.437655999098133],
            [-96.706195, 36.477563999097754],
            [-96.753554, 36.562600999096944],
            [-96.821031, 36.538770999097167],
            [-96.875321, 36.470914999097822],
            [-96.940235, 36.455524999097982],
            [-97.009785, 36.506934999097474],
            [-96.888017, 36.574885999096821],
            [-96.912756, 36.598948999096592],
            [-97.057414, 36.593646999096642],
            [-97.064107, 36.684206999095785]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "071",
        COUNTYNS: "00974134",
        AFFGEOID: "0500000US36071",
        GEOID: "36071",
        NAME: "Orange",
        LSAD: "06",
        ALAND: 2103900470,
        AWATER: 68092052
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.761435, 41.490244999063641],
            [-74.475619, 41.503952999063586],
            [-74.367055, 41.590976999063244],
            [-74.264292, 41.632628999063094],
            [-74.053685, 41.580609999063292],
            [-73.953307, 41.589976999063261],
            [-74.000108, 41.45654899906377],
            [-73.981486, 41.438904999063844],
            [-73.947294, 41.39476499906403],
            [-73.981384, 41.324692999064318],
            [-74.234362831230385, 41.142835270599683],
            [-74.234473, 41.142882999065087],
            [-74.301994, 41.172593999064965],
            [-74.367038261447391, 41.204211471508629],
            [-74.457584, 41.248224999064632],
            [-74.694914, 41.357422999064177],
            [-74.734893, 41.425817999063902],
            [-74.756271834725197, 41.427627427607298],
            [-74.761435, 41.490244999063641]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "017",
        COUNTYNS: "01155130",
        AFFGEOID: "0500000US41017",
        GEOID: "41017",
        NAME: "Deschutes",
        LSAD: "06",
        ALAND: 7815563218,
        AWATER: 94755758
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.964918, 43.62704499905778],
            [-121.986267, 43.661707999057718],
            [-121.962098, 43.763536999057585],
            [-121.975113, 43.857569999057446],
            [-121.928213, 43.909180999057391],
            [-121.869974, 43.911663999057382],
            [-121.857063, 43.965247999057318],
            [-121.769005, 44.102034999057196],
            [-121.819429, 44.262393999057039],
            [-121.841346, 44.285651999057016],
            [-121.843138, 44.392629999056943],
            [-121.107534, 44.390599999056938],
            [-121.102773, 44.138124999057148],
            [-120.987271, 44.134025999057137],
            [-120.986587, 43.960851999057319],
            [-120.748249, 43.95730999905733],
            [-120.747848, 43.871330999057434],
            [-120.37874, 43.872361999057432],
            [-120.378107, 43.785428999057544],
            [-120.258386, 43.785296999057543],
            [-120.257777, 43.698761999057673],
            [-119.897434, 43.698199999057671],
            [-119.896581, 43.611163999057787],
            [-120.35095, 43.611599999057802],
            [-120.377752, 43.615945999057793],
            [-121.332969, 43.616628999057788],
            [-122.002362, 43.615497999057787],
            [-121.964918, 43.62704499905778]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "185",
        COUNTYNS: "00516939",
        AFFGEOID: "0500000US21185",
        GEOID: "21185",
        NAME: "Oldham",
        LSAD: "06",
        ALAND: 484931825,
        AWATER: 23831645
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.621625, 38.417088999081201],
            [-85.587758, 38.450494999080945],
            [-85.498866, 38.468241999080831],
            [-85.474354, 38.50407399908056],
            [-85.433136, 38.52391399908042],
            [-85.432972269426088, 38.524123396068312],
            [-85.378743, 38.518821999080451],
            [-85.314006, 38.492591999080645],
            [-85.34608, 38.459539999080896],
            [-85.283082, 38.35805399908164],
            [-85.324453, 38.310111999081997],
            [-85.468206, 38.285308999082197],
            [-85.476253, 38.289694999082172],
            [-85.585272, 38.339430999081792],
            [-85.634444144977294, 38.378399446656296],
            [-85.621625, 38.417088999081201]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "003",
        COUNTYNS: "00025442",
        AFFGEOID: "0500000US04003",
        GEOID: "04003",
        NAME: "Cochise",
        LSAD: "06",
        ALAND: 15966166113,
        AWATER: 137024922
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.460564, 31.614036999157236],
            [-110.451736, 31.731266999155505],
            [-110.451503, 32.427353999145538],
            [-110.170441, 32.426377999145558],
            [-109.113987, 32.426176999145568],
            [-109.047612003219982, 32.42637538765976],
            [-109.048296, 32.084092999150393],
            [-109.049195, 31.796550999154551],
            [-109.050044, 31.332501999161444],
            [-109.829689, 31.334066999161426],
            [-110.460172610445014, 31.333141378153034],
            [-110.460564, 31.614036999157236]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "019",
        COUNTYNS: "00450340",
        AFFGEOID: "0500000US18019",
        GEOID: "18019",
        NAME: "Clark",
        LSAD: "06",
        ALAND: 965646802,
        AWATER: 9223876
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.993317, 38.488539999080672],
            [-85.957102, 38.501948999080575],
            [-85.884776, 38.503468999080567],
            [-85.847893, 38.561268999080141],
            [-85.793584, 38.604813999079816],
            [-85.719718, 38.605539999079824],
            [-85.56998, 38.606154999079813],
            [-85.496666, 38.60673599907981],
            [-85.431416070752192, 38.586285612188874],
            [-85.4156, 38.54634099908025],
            [-85.432972269426088, 38.524123396068312],
            [-85.433136, 38.52391399908042],
            [-85.474354, 38.50407399908056],
            [-85.498866, 38.468241999080831],
            [-85.587758, 38.450494999080945],
            [-85.621625, 38.417088999081201],
            [-85.634444144977294, 38.378399446656296],
            [-85.646201, 38.342915999081761],
            [-85.683561, 38.295468999082118],
            [-85.750962, 38.267869999082336],
            [-85.794510906770086, 38.277954734877554],
            [-85.760844, 38.350217999081707],
            [-85.807704, 38.404442999081304],
            [-85.99462, 38.418344999081192],
            [-85.993317, 38.488539999080672]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "051",
        COUNTYNS: "01720036",
        AFFGEOID: "0500000US30051",
        GEOID: "30051",
        NAME: "Liberty",
        LSAD: "06",
        ALAND: 3703639185,
        AWATER: 44411469
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.409018, 48.219541999062884],
            [-111.275306, 48.21953099906289],
            [-111.269862000829008, 48.997229785877742],
            [-111.003916, 48.997536999066142],
            [-110.743064, 48.998008111250947],
            [-110.750595, 48.914087999065771],
            [-110.755873, 48.219600999062884],
            [-110.827413, 48.219565999062894],
            [-110.827168, 48.133204999062571],
            [-111.409097, 48.132217999062568],
            [-111.409018, 48.219541999062884]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "185",
        COUNTYNS: "01008593",
        AFFGEOID: "0500000US37185",
        GEOID: "37185",
        NAME: "Warren",
        LSAD: "06",
        ALAND: 1111325893,
        AWATER: 39407120
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.323718509559001, 36.542421390655726],
            [-78.132911, 36.543810999097118],
            [-78.046208304360277, 36.544197671783721],
            [-77.899774136046673, 36.544850732139508],
            [-77.899524, 36.504258999097495],
            [-77.911963, 36.382445999098678],
            [-78.006551, 36.20263299910043],
            [-78.108277, 36.209875999100362],
            [-78.133399, 36.246382999100007],
            [-78.306965, 36.266190999099813],
            [-78.277165, 36.306131999099414],
            [-78.323718509559001, 36.542421390655726]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "191",
        COUNTYNS: "01498359",
        AFFGEOID: "0500000US51191",
        GEOID: "51191",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1453385750,
        AWATER: 12998215
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.332078, 36.708968999095553],
            [-82.103853, 36.798166999094725],
            [-82.078703, 36.854775999094208],
            [-81.94595, 36.864606999094121],
            [-81.837096, 36.929038999093521],
            [-81.699561, 36.789315999094811],
            [-81.625477, 36.646310999096144],
            [-81.605865, 36.638078999096223],
            [-81.6469, 36.61191799909647],
            [-81.826734148052608, 36.614719103257841],
            [-81.922644, 36.616212999096419],
            [-81.934144, 36.594212999096634],
            [-82.145573270514504, 36.594560329930751],
            [-82.102713, 36.646861999096146],
            [-82.131621, 36.677315999095853],
            [-82.114373, 36.638417999096227],
            [-82.179632, 36.626797999096333],
            [-82.243385005200196, 36.594876186762036],
            [-82.294136420129092, 36.595073032055133],
            [-82.307831, 36.6196819990964],
            [-82.332078, 36.708968999095553]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "060",
        COUNTYNS: "01419967",
        AFFGEOID: "0500000US02060",
        GEOID: "02060",
        NAME: "Bristol Bay",
        LSAD: "04",
        ALAND: 1248342013,
        AWATER: 949750202
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-157.135927, 58.680730999162918],
            [-157.06223, 58.740186999163839],
            [-156.993548, 58.836797999165306],
            [-157.016088, 58.863489999165722],
            [-157.095372270938981, 58.866670716260778],
            [-157.045496, 58.88583299916607],
            [-156.313889, 58.88583299916607],
            [-156.313889, 58.61666599916196],
            [-157.234300636678029, 58.616666999161957],
            [-157.135927, 58.680730999162918]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "083",
        COUNTYNS: "00306922",
        AFFGEOID: "0500000US12083",
        GEOID: "12083",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 4114226894,
        AWATER: 192048864
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.53486, 29.214679999195653],
            [-82.403237, 29.215622999195645],
            [-82.405478, 29.361096999193148],
            [-82.40662, 29.485047999191043],
            [-82.211449, 29.484066999191054],
            [-82.199287, 29.422034999192107],
            [-82.055899, 29.471231999191279],
            [-82.004385, 29.501714999190757],
            [-81.843009, 29.521003999190437],
            [-81.776205, 29.487447999191001],
            [-81.741422, 29.371048999192983],
            [-81.680903, 29.324429999193779],
            [-81.641916, 29.276765999194591],
            [-81.640925, 29.168808999196447],
            [-81.658698, 28.960344999200071],
            [-81.761654, 28.96093199920006],
            [-81.95419, 28.960052999200069],
            [-82.311697, 28.960390999200069],
            [-82.311885, 28.962032999200041],
            [-82.311686, 28.963736999200012],
            [-82.312067, 28.964899999199993],
            [-82.312254, 28.966644999199964],
            [-82.31277, 28.967195999199948],
            [-82.313149, 28.969084999199925],
            [-82.313397, 28.969475999199911],
            [-82.315153, 28.970212999199898],
            [-82.315619, 28.970875999199894],
            [-82.315726, 28.971804999199865],
            [-82.315447, 28.972753999199853],
            [-82.315836, 28.973270999199848],
            [-82.317697, 28.973423999199849],
            [-82.318922, 28.974344999199825],
            [-82.319271, 28.974404999199827],
            [-82.319423, 28.973425999199844],
            [-82.320274, 28.973145999199854],
            [-82.32106, 28.973492999199841],
            [-82.321284, 28.974026999199836],
            [-82.320735, 28.974796999199814],
            [-82.321281, 28.97534999919981],
            [-82.322202, 28.975083999199814],
            [-82.323163, 28.975334999199809],
            [-82.323238, 28.976085999199796],
            [-82.32493, 28.976705999199783],
            [-82.325925, 28.977391999199774],
            [-82.327325, 28.978808999199742],
            [-82.32862, 28.978698999199754],
            [-82.329674, 28.978949999199745],
            [-82.330095, 28.980025999199732],
            [-82.331349, 28.980423999199722],
            [-82.33193, 28.981252999199704],
            [-82.332096, 28.981969999199702],
            [-82.333224, 28.982367999199685],
            [-82.333944, 28.981522999199704],
            [-82.334675, 28.982654999199688],
            [-82.335335, 28.98312399919968],
            [-82.337157, 28.983518999199664],
            [-82.337856, 28.984760999199644],
            [-82.33867, 28.985488999199635],
            [-82.339901, 28.986039999199619],
            [-82.341364, 28.987775999199595],
            [-82.341887, 28.987844999199588],
            [-82.342901, 28.98724399919961],
            [-82.345809, 28.987103999199601],
            [-82.346702, 28.987754999199595],
            [-82.348339, 28.987889999199592],
            [-82.349293, 28.988392999199579],
            [-82.350285, 28.989491999199565],
            [-82.352394, 28.991241999199527],
            [-82.35527, 28.991344999199526],
            [-82.356336, 28.991702999199525],
            [-82.357609, 28.993143999199496],
            [-82.358711, 28.993340999199493],
            [-82.360954, 28.993379999199494],
            [-82.361575, 28.99364899919949],
            [-82.362119, 28.99432699919948],
            [-82.362074, 28.995670999199458],
            [-82.36318, 28.996464999199429],
            [-82.363247, 28.99696299919944],
            [-82.362025, 28.997444999199423],
            [-82.361805, 28.997808999199417],
            [-82.361831, 28.998314999199408],
            [-82.362763, 28.998928999199393],
            [-82.477639, 29.052483999198468],
            [-82.535591, 29.044854999198598],
            [-82.53486, 29.214679999195653]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "035",
        COUNTYNS: "00882234",
        AFFGEOID: "0500000US34035",
        GEOID: "34035",
        NAME: "Somerset",
        LSAD: "06",
        ALAND: 781816196,
        AWATER: 8009303
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.797841, 40.51555699906806],
            [-74.71315, 40.58390299906771],
            [-74.725666, 40.719531999067051],
            [-74.657258, 40.735214999066969],
            [-74.555991, 40.758157999066853],
            [-74.513717, 40.697749999067149],
            [-74.523078, 40.649124999067382],
            [-74.461035, 40.673503999067265],
            [-74.403377, 40.666621999067289],
            [-74.40352, 40.654655999067344],
            [-74.424401, 40.619544999067529],
            [-74.463291, 40.599186999067634],
            [-74.523917, 40.569669999067777],
            [-74.459863, 40.491045999068177],
            [-74.489491, 40.474451999068272],
            [-74.584987, 40.418175999068545],
            [-74.62024, 40.373748999068788],
            [-74.622682, 40.384335999068725],
            [-74.722061, 40.375204999068785],
            [-74.748147, 40.424155999068518],
            [-74.797841, 40.51555699906806]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "047",
        COUNTYNS: "00484993",
        AFFGEOID: "0500000US20047",
        GEOID: "20047",
        NAME: "Edwards",
        LSAD: "06",
        ALAND: 1610699247,
        AWATER: 206413
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.569879, 37.913015999085097],
            [-99.569533, 38.087371999083707],
            [-99.350359, 38.087593999083722],
            [-99.350289, 38.000744999084404],
            [-99.240685, 37.999967999084404],
            [-99.022136, 38.00023599908441],
            [-99.022547, 37.839898999085683],
            [-99.013318, 37.825335999085809],
            [-99.01355, 37.732969999086563],
            [-99.55958, 37.734887999086553],
            [-99.570576, 37.825633999085809],
            [-99.569879, 37.913015999085097]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "137",
        COUNTYNS: "00516915",
        AFFGEOID: "0500000US21137",
        GEOID: "21137",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 861936841,
        AWATER: 9315386
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.847183, 37.548454999088101],
            [-84.65787, 37.618277999087518],
            [-84.658296, 37.634500999087372],
            [-84.648559, 37.609896999087582],
            [-84.446719, 37.485892999088641],
            [-84.502932, 37.328800999089978],
            [-84.556461, 37.355728999089749],
            [-84.720712, 37.238327999090764],
            [-84.706402, 37.299539999090236],
            [-84.722826, 37.3600429990897],
            [-84.850816, 37.427142999089135],
            [-84.847183, 37.548454999088101]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "141",
        COUNTYNS: "00835892",
        AFFGEOID: "0500000US31141",
        GEOID: "31141",
        NAME: "Platte",
        LSAD: "06",
        ALAND: 1745910545,
        AWATER: 27167485
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.830493, 41.742237999062688],
            [-97.36807, 41.743206999062679],
            [-97.252425, 41.743084999062667],
            [-97.253519, 41.384211999064071],
            [-97.287761, 41.40063799906401],
            [-97.368186, 41.396415999064011],
            [-97.522412, 41.361933999064156],
            [-97.598253, 41.333118999064283],
            [-97.598461, 41.395067999064011],
            [-97.703765, 41.394875999064027],
            [-97.703627, 41.526795999063495],
            [-97.829846, 41.526173999063502],
            [-97.830493, 41.742237999062688]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "095",
        COUNTYNS: "01034213",
        AFFGEOID: "0500000US38095",
        GEOID: "38095",
        NAME: "Towner",
        LSAD: "06",
        ALAND: 2653593200,
        AWATER: 43823568
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.525700059071696, 48.999271290221657],
            [-99.376068, 48.999356999066151],
            [-98.999803372711014, 48.999986294718553],
            [-98.99989, 48.718128999064909],
            [-98.970368, 48.718088999064904],
            [-98.970392, 48.544231999064166],
            [-98.970975, 48.370471999063462],
            [-99.200306, 48.370657999063461],
            [-99.492919, 48.370945999063473],
            [-99.493189, 48.544833999064174],
            [-99.493002, 48.71835899906489],
            [-99.525895, 48.718383999064905],
            [-99.525700059071696, 48.999271290221657]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "143",
        COUNTYNS: "00758526",
        AFFGEOID: "0500000US29143",
        GEOID: "29143",
        NAME: "New Madrid",
        LSAD: "06",
        ALAND: 1747828876,
        AWATER: 56291575
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.96799, 36.403274999098471],
            [-89.958786, 36.629330999096311],
            [-89.700952, 36.62745299909632],
            [-89.691603, 36.859516999094154],
            [-89.567101, 36.870881999094067],
            [-89.519809, 36.869616999094077],
            [-89.519701, 36.84789599909427],
            [-89.373741, 36.70294799909562],
            [-89.327319777009009, 36.62394628786685],
            [-89.378694, 36.622291999096376],
            [-89.407906, 36.562344999096943],
            [-89.479346, 36.566252999096896],
            [-89.544434, 36.574509999096819],
            [-89.571481, 36.53808699909716],
            [-89.539232, 36.497933999097562],
            [-89.521021, 36.461933999097909],
            [-89.542337, 36.420102999098312],
            [-89.51038, 36.378355999098709],
            [-89.522695, 36.344788999099038],
            [-89.545031333969092, 36.344271397762036],
            [-89.611308, 36.411272999098394],
            [-89.728855, 36.427503999098235],
            [-89.752147, 36.386395999098625],
            [-89.96131, 36.388802999098608],
            [-89.96799, 36.403274999098471]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "021",
        COUNTYNS: "01008538",
        AFFGEOID: "0500000US37021",
        GEOID: "37021",
        NAME: "Buncombe",
        LSAD: "06",
        ALAND: 1700042284,
        AWATER: 8942802
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.883909, 35.678234999105783],
            [-82.805207, 35.686066999105705],
            [-82.513161, 35.803379999104479],
            [-82.499483, 35.801533999104485],
            [-82.494885, 35.799463999104518],
            [-82.488178, 35.801458999104497],
            [-82.476099, 35.798318999104531],
            [-82.465159, 35.801795999104492],
            [-82.463828, 35.809615999104409],
            [-82.454299, 35.807347999104429],
            [-82.446959, 35.810425999104417],
            [-82.435335, 35.80858999910442],
            [-82.427365, 35.813765999104376],
            [-82.41788, 35.813358999104381],
            [-82.408602, 35.818177999104329],
            [-82.361956, 35.821244999104294],
            [-82.335054, 35.74939599910504],
            [-82.284733, 35.725525999105287],
            [-82.275399, 35.704437999105501],
            [-82.291026, 35.59132199910669],
            [-82.169049, 35.527810999107366],
            [-82.233203, 35.519401999107458],
            [-82.265795, 35.467817999108007],
            [-82.332822, 35.500219999107664],
            [-82.495208, 35.445185999108247],
            [-82.532713, 35.416614999108553],
            [-82.673343, 35.459452999108088],
            [-82.745139, 35.422966999108496],
            [-82.798174, 35.469804999107986],
            [-82.77055, 35.575931999106857],
            [-82.84609, 35.614308999106456],
            [-82.883909, 35.678234999105783]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "085",
        COUNTYNS: "00659491",
        AFFGEOID: "0500000US27085",
        GEOID: "27085",
        NAME: "McLeod",
        LSAD: "06",
        ALAND: 1272940264,
        AWATER: 36370228
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.501885, 44.979694999056768],
            [-94.256074, 44.979464999056766],
            [-94.012236, 44.978711999056756],
            [-94.010494, 44.717463999056797],
            [-94.254076, 44.717854999056804],
            [-94.254719, 44.630310999056817],
            [-94.497835, 44.629920999056814],
            [-94.498074, 44.717170999056798],
            [-94.498028, 44.872515999056766],
            [-94.502395, 44.892294999056752],
            [-94.501885, 44.979694999056768]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "005",
        COUNTYNS: "01702364",
        AFFGEOID: "0500000US35005",
        GEOID: "35005",
        NAME: "Chaves",
        LSAD: "06",
        ALAND: 15713841748,
        AWATER: 20545303
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.352513, 32.962006999138232],
            [-105.316047, 32.962099999138225],
            [-105.316968, 33.132302999135966],
            [-104.905424, 33.138903999135877],
            [-104.88513, 33.305826999133693],
            [-104.892433, 33.398198999132497],
            [-104.893377, 34.088406999123812],
            [-104.786429, 34.087663999123819],
            [-104.786104, 34.000136999124891],
            [-104.156269, 33.995412999124952],
            [-104.1556, 34.083204999123872],
            [-103.946019, 34.082462999123884],
            [-103.841691, 34.081908999123883],
            [-103.841933, 33.819199999127136],
            [-103.716885, 33.819557999127142],
            [-103.718333, 33.656209999129189],
            [-103.509759, 33.657261999129176],
            [-103.510129, 33.570116999130271],
            [-103.720809, 33.569536999130285],
            [-103.721399, 33.395408999132528],
            [-103.766361, 33.395296999132526],
            [-103.766417, 32.965263999138188],
            [-103.814507, 32.96511399913819],
            [-104.841576, 32.963212999138221],
            [-104.851524, 32.520540999144245],
            [-105.35401, 32.518775999144275],
            [-105.352513, 32.962006999138232]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "075",
        COUNTYNS: "01074050",
        AFFGEOID: "0500000US39075",
        GEOID: "39075",
        NAME: "Holmes",
        LSAD: "06",
        ALAND: 1094356029,
        AWATER: 3745075
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.220665, 40.667579999067286],
            [-82.126199, 40.668228999067281],
            [-81.650045, 40.668116999067287],
            [-81.649199, 40.635105999067456],
            [-81.669654, 40.443865999068429],
            [-81.709132, 40.444774999068414],
            [-82.184607, 40.456279999068357],
            [-82.179069, 40.573111999067748],
            [-82.22011, 40.568205999067793],
            [-82.220665, 40.667579999067286]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "167",
        COUNTYNS: "00835905",
        AFFGEOID: "0500000US31167",
        GEOID: "31167",
        NAME: "Stanton",
        LSAD: "06",
        ALAND: 1107496313,
        AWATER: 8793984
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.368404, 42.090921999061464],
            [-97.019359, 42.090576999061462],
            [-97.019911, 41.742979999062676],
            [-97.252425, 41.743084999062667],
            [-97.36807, 41.743206999062679],
            [-97.368404, 42.090921999061464]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "023",
        COUNTYNS: "01008539",
        AFFGEOID: "0500000US37023",
        GEOID: "37023",
        NAME: "Burke",
        LSAD: "06",
        ALAND: 1311170317,
        AWATER: 20699390
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.906652, 35.883375999103649],
            [-81.943188, 35.960049999102871],
            [-81.899871, 35.999908999102459],
            [-81.807162, 35.961954999102844],
            [-81.709749, 35.875002999103728],
            [-81.555994, 35.777537999104744],
            [-81.363796, 35.76780199910484],
            [-81.432898, 35.677846999105782],
            [-81.535403, 35.568136999106947],
            [-81.537599, 35.564227999106983],
            [-81.56725, 35.558200999107051],
            [-81.691989, 35.580040999106821],
            [-81.753407, 35.613587999106457],
            [-81.824122, 35.574982999106865],
            [-81.869388, 35.71962399910533],
            [-81.986885, 35.807065999104452],
            [-81.906652, 35.883375999103649]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "109",
        COUNTYNS: "01213686",
        AFFGEOID: "0500000US42109",
        GEOID: "42109",
        NAME: "Snyder",
        LSAD: "06",
        ALAND: 851544496,
        AWATER: 7157948
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.356628, 40.807333999066614],
            [-76.941788, 40.888059999066222],
            [-76.800242, 40.881993999066268],
            [-76.798747, 40.87805199906628],
            [-76.849645, 40.809009999066618],
            [-76.864014, 40.706692999067101],
            [-76.939749, 40.63837799906743],
            [-77.109597, 40.691577999067171],
            [-77.287941, 40.693594999067166],
            [-77.354097, 40.701666999067129],
            [-77.356628, 40.807333999066614]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "33",
        COUNTYFP: "013",
        COUNTYNS: "00873180",
        AFFGEOID: "0500000US33013",
        GEOID: "33013",
        NAME: "Merrimack",
        LSAD: "06",
        ALAND: 2415829018,
        AWATER: 57547411
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.053752, 43.441096999058082],
            [-72.011363, 43.43687099905808],
            [-71.93531, 43.528879999057914],
            [-71.892112, 43.574617999057857],
            [-71.864599, 43.598062999057809],
            [-71.81757, 43.544458999057895],
            [-71.727573, 43.563410999057858],
            [-71.612426, 43.437892999058079],
            [-71.55003, 43.452700999058059],
            [-71.471454, 43.411297999058114],
            [-71.236011, 43.284993999058351],
            [-71.247336, 43.275231999058377],
            [-71.305471, 43.178457999058566],
            [-71.317709, 43.162398999058595],
            [-71.356117, 43.102042999058732],
            [-71.402962, 43.04105399905886],
            [-71.392388, 43.009308999058923],
            [-71.512767, 43.049614999058825],
            [-71.517467, 43.068908999058799],
            [-71.641291, 43.054167999058834],
            [-71.667771, 43.152533999058619],
            [-71.73256, 43.139785999058653],
            [-71.810277, 43.12427099905868],
            [-71.8561, 43.115134999058697],
            [-71.913215, 43.207098999058523],
            [-72.009074, 43.187881999058547],
            [-72.090146, 43.322552999058281],
            [-72.053752, 43.441096999058082]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "149",
        COUNTYNS: "00758529",
        AFFGEOID: "0500000US29149",
        GEOID: "29149",
        NAME: "Oregon",
        LSAD: "06",
        ALAND: 2045563369,
        AWATER: 4528610
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.674237, 36.787831999094827],
            [-91.658111, 36.888723999093898],
            [-91.22283, 36.883856999093936],
            [-91.224825, 36.825678999094478],
            [-91.115926, 36.823671999094486],
            [-91.126538874564702, 36.497797700116962],
            [-91.404915, 36.497119999097563],
            [-91.407137443517485, 36.497140711046171],
            [-91.450004918137395, 36.49754021228226],
            [-91.64259, 36.499334999097542],
            [-91.672342456443701, 36.49925663289644],
            [-91.674237, 36.787831999094827]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "025",
        COUNTYNS: "00882910",
        AFFGEOID: "0500000US34025",
        GEOID: "34025",
        NAME: "Monmouth",
        LSAD: "06",
        ALAND: 1213128209,
        AWATER: 510444544
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.614576, 40.182382999069809],
            [-74.543268, 40.216691999069624],
            [-74.484962, 40.25332499906942],
            [-74.393384, 40.27963699906929],
            [-74.327377, 40.344598999068936],
            [-74.290883, 40.372977999068773],
            [-74.236332, 40.443443999068428],
            [-74.224652466014689, 40.448662488554895],
            [-74.206188, 40.440706999068432],
            [-74.157094, 40.447570999068397],
            [-74.108295, 40.443787999068412],
            [-74.047884, 40.418907999068537],
            [-74.019334, 40.471243999068285],
            [-73.997943, 40.476667999068255],
            [-73.976982, 40.408507999068604],
            [-73.971381, 40.348009999068914],
            [-73.981681, 40.279410999069292],
            [-74.001020794434595, 40.216966591047523],
            [-74.030181, 40.122813999070132],
            [-74.034962382263487, 40.102584305018446],
            [-74.107794, 40.136925999070051],
            [-74.154304, 40.099617999070269],
            [-74.260572, 40.166483999069897],
            [-74.40671, 40.172401999069862],
            [-74.553105, 40.079129999070382],
            [-74.587835, 40.138253999070045],
            [-74.614576, 40.182382999069809]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "007",
        COUNTYNS: "01214112",
        AFFGEOID: "0500000US42007",
        GEOID: "42007",
        NAME: "Beaver",
        LSAD: "06",
        ALAND: 1125901161,
        AWATER: 24165972
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.519705112599198, 40.851336777751804],
            [-80.158534, 40.855156999066395],
            [-80.148451, 40.674289999067263],
            [-80.14485, 40.613473999067566],
            [-80.207978, 40.588037999067687],
            [-80.219279, 40.58190399906772],
            [-80.360873, 40.477538999068244],
            [-80.519017117347389, 40.477363145305347],
            [-80.518991, 40.638800999067449],
            [-80.519705112599198, 40.851336777751804]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "047",
        COUNTYNS: "00758478",
        AFFGEOID: "0500000US29047",
        GEOID: "29047",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 1029939957,
        AWATER: 28510134
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.600819, 39.456154999074066],
            [-94.210642, 39.454681999074097],
            [-94.212515, 39.20708599907568],
            [-94.290785, 39.236914999075474],
            [-94.335213, 39.183336999075827],
            [-94.36598, 39.16322699907596],
            [-94.408628, 39.193602999075758],
            [-94.399928, 39.174802999075879],
            [-94.402328, 39.164765999075954],
            [-94.425764, 39.141806999076103],
            [-94.46159, 39.120371999076234],
            [-94.484616, 39.145503999076077],
            [-94.495411, 39.150393999076044],
            [-94.523463, 39.148770999076056],
            [-94.539232, 39.142702999076086],
            [-94.549573, 39.130894999076176],
            [-94.586944, 39.123330999076224],
            [-94.576552, 39.181182999075844],
            [-94.586353, 39.195730999075735],
            [-94.591933, 39.155002999076011],
            [-94.601937981921097, 39.155503232539814],
            [-94.600819, 39.456154999074066]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "039",
        COUNTYNS: "01213666",
        AFFGEOID: "0500000US42039",
        GEOID: "42039",
        NAME: "Crawford",
        LSAD: "06",
        ALAND: 2621846809,
        AWATER: 65303124
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.519396726690502, 41.849562989621596],
            [-80.206545, 41.849044999062279],
            [-79.612072, 41.850055999062285],
            [-79.612873, 41.623857999063127],
            [-79.644117, 41.617205999063138],
            [-79.688082, 41.617362999063147],
            [-79.828876, 41.622056999063126],
            [-79.999779, 41.490048999063653],
            [-80.125316, 41.480394999063684],
            [-80.519165169895601, 41.489012189218251],
            [-80.519176750353395, 41.499924050269605],
            [-80.519357, 41.669766999062958],
            [-80.519396726690502, 41.849562989621596]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "079",
        COUNTYNS: "00758494",
        AFFGEOID: "0500000US29079",
        GEOID: "29079",
        NAME: "Grundy",
        LSAD: "06",
        ALAND: 1127383741,
        AWATER: 7118002
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.763324, 40.263987999069364],
            [-93.367214, 40.266313999069361],
            [-93.36661, 40.051560999070531],
            [-93.361951, 40.033097999070627],
            [-93.361957, 39.967605999071004],
            [-93.761973, 39.959551999071053],
            [-93.764021, 40.132908999070075],
            [-93.763324, 40.263987999069364]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "137",
        COUNTYNS: "01266993",
        AFFGEOID: "0500000US46137",
        GEOID: "46137",
        NAME: "Ziebach",
        LSAD: "06",
        ALAND: 5079575456,
        AWATER: 24212303
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.99999, 45.038273999056777],
            [-101.99979, 45.472413999056918],
            [-101.47019, 45.472415999056913],
            [-101.486348, 45.385589999056869],
            [-101.486593, 45.038373999056766],
            [-101.500512, 44.99377999905677],
            [-101.136162, 44.994073999056774],
            [-101.136838, 44.747125999056792],
            [-101.229495, 44.692286999056797],
            [-101.456072, 44.636202999056813],
            [-101.531035, 44.567874999056848],
            [-101.715038, 44.579482999056843],
            [-101.811198, 44.52237299905687],
            [-101.889038, 44.538457999056853],
            [-102.000432, 44.511662999056888],
            [-101.99999, 45.038273999056777]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "165",
        COUNTYNS: "00758537",
        AFFGEOID: "0500000US29165",
        GEOID: "29165",
        NAME: "Platte",
        LSAD: "06",
        ALAND: 1088302651,
        AWATER: 17119867
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.091419402824002, 39.5332578842836],
            [-94.627599, 39.531992999073601],
            [-94.601246, 39.530371999073616],
            [-94.600819, 39.456154999074066],
            [-94.601937981921097, 39.155503232539814],
            [-94.623934, 39.156602999076],
            [-94.680336, 39.184302999075825],
            [-94.741938, 39.170202999075912],
            [-94.791994673743304, 39.201260249315901],
            [-94.799663, 39.206017999075677],
            [-94.825663, 39.241728999075448],
            [-94.857072, 39.273824999075238],
            [-94.908065, 39.323662999074912],
            [-94.888972, 39.392431999074475],
            [-94.946662, 39.399716999074428],
            [-94.965747418633498, 39.421681743730694],
            [-94.982144, 39.440551999074167],
            [-95.049845, 39.49441499907384],
            [-95.091419402824002, 39.5332578842836]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "337",
        COUNTYNS: "01383954",
        AFFGEOID: "0500000US48337",
        GEOID: "48337",
        NAME: "Montague",
        LSAD: "06",
        ALAND: 2411034614,
        AWATER: 19087361
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.978919, 33.467128999131596],
            [-97.977869, 33.88873799912627],
            [-97.951905950855178, 33.891225813983546],
            [-97.951215, 33.878423999126397],
            [-97.865765, 33.849392999126756],
            [-97.803473, 33.88018999912638],
            [-97.759834, 33.925209999125819],
            [-97.671772, 33.991369999124998],
            [-97.609091, 33.96809299912529],
            [-97.596155, 33.922105999125868],
            [-97.561243150221088, 33.899061505859144],
            [-97.55827, 33.897098999126165],
            [-97.486505, 33.916993999125921],
            [-97.484143438587594, 33.913889108016861],
            [-97.487065, 33.433680999132029],
            [-97.918189, 33.433872999132021],
            [-97.978967, 33.433747999132038],
            [-97.978919, 33.467128999131596]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "003",
        COUNTYNS: "01455966",
        AFFGEOID: "0500000US49003",
        GEOID: "49003",
        NAME: "Box Elder",
        LSAD: "06",
        ALAND: 14880984762,
        AWATER: 2547651528
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.042145, 40.999925999065731],
            [-114.041447, 41.207751999064811],
            [-114.040231, 41.491689999063631],
            [-114.039901, 41.753780999062634],
            [-114.041723, 41.993719999061781],
            [-113.817964, 41.988579999061812],
            [-113.496548, 41.993304999061792],
            [-113.249159, 41.996202999061794],
            [-113.000821, 41.99822299906176],
            [-113.000040139624005, 41.998227611600868],
            [-112.648019, 42.000306999061763],
            [-112.264936, 42.000990999061763],
            [-112.159177451510004, 41.99868192879957],
            [-112.052863, 41.866230999062232],
            [-112.026371, 41.776411999062553],
            [-112.051737, 41.699551999062834],
            [-111.981042, 41.534116999063471],
            [-111.873314, 41.48574299906366],
            [-111.885443, 41.426373999063898],
            [-111.958286, 41.433872999063873],
            [-111.96318, 41.365334999064146],
            [-112.028672, 41.336511999064271],
            [-112.238065, 41.336551999064262],
            [-112.493515, 41.07688799906537],
            [-112.799359, 40.999938999065719],
            [-114.042145146235001, 40.999896576329022],
            [-114.042145, 40.999925999065731]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "135",
        COUNTYNS: "01383853",
        AFFGEOID: "0500000US48135",
        GEOID: "48135",
        NAME: "Ector",
        LSAD: "06",
        ALAND: 2325100483,
        AWATER: 10596946
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.799086, 32.085794999150366],
            [-102.287048, 32.086990999150345],
            [-102.287345, 31.651275999156685],
            [-102.31805, 31.651326999156684],
            [-102.767246, 31.651713999156687],
            [-102.798939, 31.65178299915668],
            [-102.799086, 32.085794999150366]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "015",
        COUNTYNS: "01448022",
        AFFGEOID: "0500000US49015",
        GEOID: "49015",
        NAME: "Emery",
        LSAD: "06",
        ALAND: 11557242845,
        AWATER: 24843848
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.29936, 39.032263999076832],
            [-111.30092, 39.422599999074286],
            [-111.247748, 39.467198999074007],
            [-111.247391, 39.704349999072541],
            [-111.159113, 39.595946999073199],
            [-111.132248, 39.613875999073095],
            [-111.085349, 39.521521999073663],
            [-111.081333, 39.467454999074008],
            [-110.024118, 39.469268999073982],
            [-110.010617, 39.416321999074334],
            [-110.07727, 39.275868999075222],
            [-110.05457, 39.228282999075525],
            [-110.098763, 39.189508999075791],
            [-110.099411, 39.13627599907614],
            [-110.156222, 39.032653999076828],
            [-110.096336, 38.986256999077149],
            [-110.041176, 38.99351599907709],
            [-110.003984, 38.949910999077396],
            [-110.069114, 38.978982999077203],
            [-110.150353, 38.979792999077183],
            [-110.140451, 38.92432199907757],
            [-110.179252, 38.907247999077683],
            [-110.083861, 38.75195199907877],
            [-110.11294, 38.731650999078923],
            [-110.070489, 38.656275999079455],
            [-110.036344, 38.652310999079475],
            [-110.057897, 38.583739999079981],
            [-109.994283, 38.527117999080403],
            [-110.025402, 38.499980999080591],
            [-111.279345, 38.499977999080592],
            [-111.305665, 38.510168999080506],
            [-111.29936, 39.032263999076832]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "187",
        COUNTYNS: "01383879",
        AFFGEOID: "0500000US48187",
        GEOID: "48187",
        NAME: "Guadalupe",
        LSAD: "06",
        ALAND: 1842135388,
        AWATER: 9163416
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.310928, 29.594472999189193],
            [-98.19763, 29.638127999188459],
            [-98.089941, 29.683478999187699],
            [-97.999271, 29.752443999186543],
            [-97.943564, 29.806913999185639],
            [-97.875259, 29.858207999184792],
            [-97.799396, 29.752259999186553],
            [-97.780917, 29.759257999186431],
            [-97.768459, 29.719102999187104],
            [-97.633176, 29.649862999188265],
            [-97.664987, 29.607719999188973],
            [-97.840383, 29.376790999192881],
            [-97.956081, 29.378435999192853],
            [-98.113175, 29.365666999193071],
            [-98.134171, 29.441750999191779],
            [-98.122555, 29.477969999191163],
            [-98.164638, 29.478090999191167],
            [-98.233899, 29.554892999189864],
            [-98.27371, 29.551212999189918],
            [-98.310928, 29.594472999189193]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "209",
        COUNTYNS: "01383890",
        AFFGEOID: "0500000US48209",
        GEOID: "48209",
        NAME: "Hays",
        LSAD: "06",
        ALAND: 1755978743,
        AWATER: 5037781
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.172977, 30.356311999176661],
            [-97.857222, 30.131239999180309],
            [-97.857161, 30.131195999180306],
            [-97.813018, 30.098612999180837],
            [-97.781369, 30.075669999181205],
            [-97.710215, 30.02449899918205],
            [-97.712548, 30.020612999182102],
            [-97.899238, 29.8575829991848],
            [-97.875259, 29.858207999184792],
            [-97.943564, 29.806913999185639],
            [-97.999271, 29.752443999186543],
            [-98.01518, 29.801484999185735],
            [-98.030523, 29.848538999184949],
            [-98.12127, 29.912843999183888],
            [-98.2976, 30.037993999181822],
            [-98.172977, 30.356311999176661]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "15",
        COUNTYFP: "003",
        COUNTYNS: "00365281",
        AFFGEOID: "0500000US15003",
        GEOID: "15003",
        NAME: "Honolulu",
        LSAD: "06",
        ALAND: 1555581074,
        AWATER: 4054908589
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-158.232192, 21.58380599935391],
            [-158.12561, 21.586738999353837],
            [-158.079895, 21.628100999352849],
            [-158.050692, 21.671214999351818],
            [-157.9923, 21.707999999350935],
            [-157.968628, 21.712703999350818],
            [-157.924591, 21.651182999352297],
            [-157.87735, 21.575276999354113],
            [-157.836945, 21.529944999355205],
            [-157.84549, 21.466746999356726],
            [-157.8139, 21.440299999357368],
            [-157.764572, 21.461334999356854],
            [-157.722506, 21.459224999356913],
            [-157.724324, 21.403310999358258],
            [-157.7106, 21.358499999359342],
            [-157.6518, 21.313899999360427],
            [-157.655107859039987, 21.309280287811834],
            [-157.673069, 21.284195999361135],
            [-157.7001, 21.263999999361634],
            [-157.7572, 21.277999999361302],
            [-157.779944, 21.265251999361606],
            [-157.8096, 21.257699999361783],
            [-157.851048, 21.284529999361133],
            [-157.89, 21.3064999993606],
            [-157.950736, 21.312508999360457],
            [-157.981525, 21.315897999360374],
            [-158.0245, 21.309299999360533],
            [-158.0883, 21.298799999360789],
            [-158.1033, 21.297899999360808],
            [-158.129371, 21.34481799935967],
            [-158.130931366459976, 21.348955847356276],
            [-158.1403, 21.373799999358976],
            [-158.1792, 21.404299999358237],
            [-158.182648, 21.430072999357613],
            [-158.233, 21.48759999935622],
            [-158.231171, 21.523856999355356],
            [-158.277679, 21.578788999354025],
            [-158.232192, 21.58380599935391]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "293",
        COUNTYNS: "01383932",
        AFFGEOID: "0500000US48293",
        GEOID: "48293",
        NAME: "Limestone",
        LSAD: "06",
        ALAND: 2344685542,
        AWATER: 72197848
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.932215, 31.70888699915584],
            [-96.719114, 31.814886999154286],
            [-96.496713, 31.796190999154557],
            [-96.236629, 31.413391999160226],
            [-96.322853, 31.374066999160817],
            [-96.319165, 31.35712899916107],
            [-96.596862, 31.220899999163137],
            [-96.801123, 31.522268999158602],
            [-96.932215, 31.70888699915584]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "117",
        COUNTYNS: "01101846",
        AFFGEOID: "0500000US40117",
        GEOID: "40117",
        NAME: "Pawnee",
        LSAD: "06",
        ALAND: 1471527632,
        AWATER: 68888617
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.032118, 36.506956999097476],
            [-97.009785, 36.506934999097474],
            [-96.940235, 36.455524999097982],
            [-96.875321, 36.470914999097822],
            [-96.821031, 36.538770999097167],
            [-96.753554, 36.562600999096944],
            [-96.706195, 36.477563999097754],
            [-96.710769, 36.437655999098133],
            [-96.635727, 36.425907999098257],
            [-96.575697, 36.374346999098755],
            [-96.518709, 36.377575999098717],
            [-96.573481, 36.315708999099314],
            [-96.515229, 36.298331999099489],
            [-96.457684, 36.332663999099154],
            [-96.433812, 36.285888999099612],
            [-96.326732, 36.226550999100198],
            [-96.256624, 36.225675999100211],
            [-96.268287, 36.161979999100836],
            [-96.297888, 36.162278999100835],
            [-96.598914, 36.163568999100818],
            [-96.621441, 36.159928999100863],
            [-96.819005, 36.158885999100875],
            [-96.818968, 36.246477999099994],
            [-96.925069, 36.246473999099997],
            [-96.924936, 36.333343999099142],
            [-97.032106, 36.333353999099153],
            [-97.032118, 36.506956999097476]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "107",
        COUNTYNS: "00559509",
        AFFGEOID: "0500000US22107",
        GEOID: "22107",
        NAME: "Tensas",
        LSAD: "15",
        ALAND: 1561204507,
        AWATER: 99471076
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.575721, 31.882559999153301],
            [-91.511374, 31.930024999152604],
            [-91.528204, 31.959342999152192],
            [-91.489761, 32.092421999150275],
            [-91.492718, 32.203348999148695],
            [-91.320742, 32.202454999148721],
            [-91.133968, 32.249338999148044],
            [-91.108509, 32.208149999148624],
            [-91.039472318907002, 32.107968141815356],
            [-91.034707, 32.101052999150149],
            [-91.079108, 32.05025499915088],
            [-91.080808, 32.023455999151253],
            [-91.117409, 31.98705699915179],
            [-91.17741, 31.973256999151975],
            [-91.18111, 31.920058999152754],
            [-91.234899, 31.876862999153389],
            [-91.244015161324697, 31.86973243665739],
            [-91.290135, 31.833657999154021],
            [-91.345714, 31.842860999153881],
            [-91.359514, 31.799361999154513],
            [-91.320458733281583, 31.747800613637459],
            [-91.318576, 31.745314999155298],
            [-91.38012452998349, 31.732626952205496],
            [-91.39829, 31.753211999155187],
            [-91.544791, 31.751730999155203],
            [-91.577653, 31.795856999154562],
            [-91.575721, 31.882559999153301]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "103",
        COUNTYNS: "01639766",
        AFFGEOID: "0500000US47103",
        GEOID: "47103",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 1477179215,
        AWATER: 971039
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.828301, 35.263676999110224],
            [-86.746899, 35.252877999110339],
            [-86.602613, 35.327012999109535],
            [-86.599478, 35.364949999109115],
            [-86.525306, 35.354717999109234],
            [-86.52728, 35.311535999109701],
            [-86.392192, 35.235380999110525],
            [-86.363363, 35.123029999111765],
            [-86.318168, 35.126985999111717],
            [-86.3187607654651, 34.991078579556046],
            [-86.467798, 34.990691999113245],
            [-86.783628017169008, 34.99192492110523],
            [-86.783648, 34.991924999113238],
            [-86.836286315949508, 34.992803003330231],
            [-86.828301, 35.263676999110224]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "007",
        COUNTYNS: "00974102",
        AFFGEOID: "0500000US36007",
        GEOID: "36007",
        NAME: "Broome",
        LSAD: "06",
        ALAND: 1827987181,
        AWATER: 25185424
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.130181, 42.410336999060476],
            [-76.021374, 42.409007999060485],
            [-75.86402, 42.415701999060452],
            [-75.836406, 42.244154999060981],
            [-75.638299, 42.24868599906096],
            [-75.63711, 42.195627999061124],
            [-75.483638, 42.195182999061132],
            [-75.418421, 42.195031999061136],
            [-75.402239, 42.010698999061724],
            [-75.359859929150502, 41.993685887966393],
            [-75.477144, 41.999406999061762],
            [-75.483149270958904, 41.999398163584466],
            [-75.553112539095096, 41.999295227535271],
            [-75.870677, 41.998827999061774],
            [-76.10584, 41.998857999061777],
            [-76.104829, 42.031567999061657],
            [-76.108948, 42.074750999061514],
            [-76.080681, 42.258083999060929],
            [-76.130181, 42.410336999060476]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "113",
        COUNTYNS: "01494590",
        AFFGEOID: "0500000US51113",
        GEOID: "51113",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 830426508,
        AWATER: 3228803
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.442767, 38.527774999080393],
            [-78.338176, 38.627311999079652],
            [-78.231803, 38.532538999080352],
            [-78.09441, 38.399229999081335],
            [-78.094498, 38.311241999082],
            [-78.232284, 38.230606999082617],
            [-78.288837, 38.272655999082289],
            [-78.348971, 38.277412999082259],
            [-78.429626, 38.366703999081587],
            [-78.452801, 38.475526999080778],
            [-78.442767, 38.527774999080393]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "005",
        COUNTYNS: "01101790",
        AFFGEOID: "0500000US40005",
        GEOID: "40005",
        NAME: "Atoka",
        LSAD: "06",
        ALAND: 2526582870,
        AWATER: 37283827
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.40733, 34.418390999119843],
            [-96.145096, 34.418676999119839],
            [-96.145342, 34.506101999118812],
            [-96.091873, 34.506154999118813],
            [-96.092043, 34.680555999116777],
            [-95.882103, 34.680474999116775],
            [-95.881987, 34.593527999117789],
            [-95.671976, 34.593851999117796],
            [-95.6717, 34.506798999118807],
            [-95.779425, 34.506555999118795],
            [-95.779376, 34.156698999122973],
            [-95.991578, 34.156804999122983],
            [-96.40764, 34.157316999122976],
            [-96.40733, 34.418390999119843]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "115",
        COUNTYNS: "00974155",
        AFFGEOID: "0500000US36115",
        GEOID: "36115",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 2152713677,
        AWATER: 38126891
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.636651, 43.497241999057977],
            [-73.49291, 43.657138999057729],
            [-73.43812, 43.803686999057518],
            [-73.382526149539899, 43.808158987607008],
            [-73.350707, 43.770462999057564],
            [-73.361107151684294, 43.753233456341491],
            [-73.393723, 43.699199999057669],
            [-73.414546, 43.658208999057727],
            [-73.424977, 43.598774999057817],
            [-73.395767, 43.568086999057861],
            [-73.327702, 43.625912999057768],
            [-73.292113, 43.584508999057832],
            [-73.242042, 43.534924999057921],
            [-73.252832, 43.363492999058209],
            [-73.255355838057596, 43.314712397658312],
            [-73.26978, 43.035922999058862],
            [-73.270008731163585, 43.030714291746385],
            [-73.273832807021009, 42.943631746585581],
            [-73.430623, 42.958649999059041],
            [-73.635463, 42.941289999059087],
            [-73.577876, 43.056887999058823],
            [-73.59496, 43.306117999058316],
            [-73.636651, 43.497241999057977]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "137",
        COUNTYNS: "01494761",
        AFFGEOID: "0500000US51137",
        GEOID: "51137",
        NAME: "Orange",
        LSAD: "06",
        ALAND: 883480537,
        AWATER: 6502589
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.368743, 38.184073999082976],
            [-78.288837, 38.272655999082289],
            [-78.232284, 38.230606999082617],
            [-78.094498, 38.311241999082],
            [-78.039921, 38.311565999081999],
            [-77.908979, 38.381502999081476],
            [-77.782494, 38.364464999081598],
            [-77.770061, 38.392991999081389],
            [-77.702843, 38.360839999081634],
            [-77.715081, 38.335763999081806],
            [-77.953639, 38.11850099908348],
            [-78.101748, 38.15256699908322],
            [-78.209384, 38.131127999083375],
            [-78.341674, 38.176897999083025],
            [-78.368743, 38.184073999082976]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "073",
        COUNTYNS: "00161562",
        AFFGEOID: "0500000US01073",
        GEOID: "01073",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 2877921069,
        AWATER: 32756125
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.266923, 33.512928999131013],
            [-87.271586, 33.529419999130809],
            [-87.185794, 33.555881999130463],
            [-87.17943, 33.613793999129733],
            [-87.057757, 33.656362999129179],
            [-87.005965, 33.787236999127543],
            [-86.953664, 33.815296999127192],
            [-86.883947, 33.84323699912683],
            [-86.759212, 33.840591999126872],
            [-86.64529, 33.773011999127718],
            [-86.577799, 33.765315999127807],
            [-86.542758, 33.765172999127806],
            [-86.525073, 33.721235999128368],
            [-86.516783, 33.545895999130586],
            [-86.517199, 33.524135999130863],
            [-86.674418, 33.466520999131603],
            [-86.830597, 33.332014999133342],
            [-86.882518, 33.332686999133337],
            [-86.97836, 33.256221999134333],
            [-87.026846, 33.246458999134468],
            [-87.065738, 33.246906999134467],
            [-87.065754, 33.275936999134082],
            [-87.074634, 33.276017999134076],
            [-87.074602, 33.279681999134034],
            [-87.087546, 33.279665999134025],
            [-87.087497, 33.290674999133891],
            [-87.126756, 33.290843999133891],
            [-87.127142, 33.306012999133692],
            [-87.162007, 33.306586999133678],
            [-87.162175, 33.321011999133489],
            [-87.179638, 33.3211119991335],
            [-87.179754, 33.328009999133407],
            [-87.194841, 33.343068999133209],
            [-87.341698, 33.470500999131559],
            [-87.266923, 33.512928999131013]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "157",
        COUNTYNS: "01673976",
        AFFGEOID: "0500000US51157",
        GEOID: "51157",
        NAME: "Rappahannock",
        LSAD: "06",
        ALAND: 689901003,
        AWATER: 2142395
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.318775, 38.737865999078871],
            [-78.284805, 38.759314999078718],
            [-78.200764, 38.776664999078605],
            [-78.130574, 38.864864999077987],
            [-78.031768, 38.793507999078479],
            [-77.995664, 38.710415999079061],
            [-77.935355, 38.695839999079176],
            [-78.175425, 38.521038999080439],
            [-78.231803, 38.532538999080352],
            [-78.338176, 38.627311999079652],
            [-78.318775, 38.737865999078871]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "023",
        COUNTYNS: "01101799",
        AFFGEOID: "0500000US40023",
        GEOID: "40023",
        NAME: "Choctaw",
        LSAD: "06",
        ALAND: 1995215215,
        AWATER: 75666999
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.991578, 34.156804999122983],
            [-95.779376, 34.156698999122973],
            [-95.156521, 34.156964999122984],
            [-95.155907617107502, 33.938482387695466],
            [-95.226393, 33.96195399912537],
            [-95.253623, 33.929709999125755],
            [-95.283445, 33.877745999126411],
            [-95.310449795449088, 33.873843037311957],
            [-95.352338, 33.867788999126532],
            [-95.44737, 33.868849999126518],
            [-95.525322, 33.885486999126314],
            [-95.556915, 33.927019999125804],
            [-95.603657, 33.927194999125796],
            [-95.669978, 33.905843999126063],
            [-95.737508, 33.89596699912618],
            [-95.78963931775229, 33.872437383798776],
            [-95.918871, 33.925056999125815],
            [-95.93815, 34.125099999123371],
            [-95.991578, 34.156804999122983]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "063",
        COUNTYNS: "01101819",
        AFFGEOID: "0500000US40063",
        GEOID: "40063",
        NAME: "Hughes",
        LSAD: "06",
        ALAND: 2084026137,
        AWATER: 26504863
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.490434, 35.115856999111841],
            [-96.441401, 35.115769999111855],
            [-96.441368, 35.290121999109921],
            [-95.981376, 35.28989899910993],
            [-95.98371, 35.151556999111456],
            [-96.088861, 35.049757999112579],
            [-96.092001, 34.767478999115781],
            [-96.406483, 34.767589999115778],
            [-96.408639, 34.930672999113916],
            [-96.490786, 34.910584999114135],
            [-96.490434, 35.115856999111841]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "091",
        COUNTYNS: "01101836",
        AFFGEOID: "0500000US40091",
        GEOID: "40091",
        NAME: "McIntosh",
        LSAD: "06",
        ALAND: 1601897950,
        AWATER: 242913899
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.981376, 35.28989899910993],
            [-95.981307, 35.37682799910899],
            [-95.875626, 35.376689999108997],
            [-95.875572, 35.463624999108056],
            [-95.823396, 35.463968999108054],
            [-95.822361, 35.551692999107125],
            [-95.712957, 35.551801999107113],
            [-95.34503, 35.552240999107113],
            [-95.34465, 35.293096999109899],
            [-95.390252, 35.32749699910952],
            [-95.45125, 35.296018999109869],
            [-95.602342, 35.220738999110694],
            [-95.615664, 35.249697999110367],
            [-95.75597, 35.177197999111158],
            [-95.870663, 35.185985999111075],
            [-95.907966, 35.143493999111534],
            [-95.98371, 35.151556999111456],
            [-95.981376, 35.28989899910993]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "185",
        COUNTYNS: "01383878",
        AFFGEOID: "0500000US48185",
        GEOID: "48185",
        NAME: "Grimes",
        LSAD: "06",
        ALAND: 2039539549,
        AWATER: 36603391
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.16837, 30.822996999169266],
            [-95.863059, 30.864124999168617],
            [-95.83024, 30.630283999172285],
            [-95.804306, 30.245569999178446],
            [-96.069319, 30.229963999178704],
            [-96.093165, 30.225186999178771],
            [-96.094404, 30.309370999177411],
            [-96.15463, 30.330287999177077],
            [-96.147255, 30.382839999176223],
            [-96.079969, 30.430029999175478],
            [-96.121806, 30.443425999175261],
            [-96.187765, 30.598736999172786],
            [-96.16837, 30.822996999169266]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "179",
        COUNTYNS: "01383875",
        AFFGEOID: "0500000US48179",
        GEOID: "48179",
        NAME: "Gray",
        LSAD: "06",
        ALAND: 2398261666,
        AWATER: 8700791
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.085935, 35.619101999106405],
            [-100.540158, 35.619295999106399],
            [-100.538978, 35.18314399911111],
            [-101.086281, 35.182139999111108],
            [-101.085935, 35.619101999106405]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "153",
        COUNTYNS: "01623017",
        AFFGEOID: "0500000US26153",
        GEOID: "26153",
        NAME: "Schoolcraft",
        LSAD: "06",
        ALAND: 3033697431,
        AWATER: 1845664144
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.615397, 46.333262999057858],
            [-86.364989, 46.331926999057863],
            [-86.364953, 46.505851999058137],
            [-85.864932, 46.504757999058143],
            [-85.865025, 46.244630999057719],
            [-85.865839921942694, 45.947571072242638],
            [-85.913769, 45.919438999057299],
            [-86.072067, 45.965312999057346],
            [-86.278007, 45.942056999057328],
            [-86.349134, 45.834159999057199],
            [-86.439661, 45.760668999057138],
            [-86.459880517749397, 45.750226549653128],
            [-86.49025, 45.896336999057283],
            [-86.490142, 46.1581779990576],
            [-86.615601, 46.158690999057598],
            [-86.615397, 46.333262999057858]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "071",
        COUNTYNS: "01008554",
        AFFGEOID: "0500000US37071",
        GEOID: "37071",
        NAME: "Gaston",
        LSAD: "06",
        ALAND: 921400770,
        AWATER: 20560593
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.4556, 35.41981099910852],
            [-80.95486, 35.400077999108738],
            [-80.922998, 35.360240999109166],
            [-80.972597, 35.350337999109279],
            [-81.01505, 35.268654999110169],
            [-81.005806, 35.157022999111391],
            [-81.042302450138592, 35.146768648579709],
            [-81.04287, 35.149247999111473],
            [-81.328090989291809, 35.162285914668729],
            [-81.321729, 35.26044899911026],
            [-81.356682, 35.330603999109485],
            [-81.4556, 35.41981099910852]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "057",
        COUNTYNS: "00835850",
        AFFGEOID: "0500000US31057",
        GEOID: "31057",
        NAME: "Dundy",
        LSAD: "06",
        ALAND: 2381956152,
        AWATER: 3046330
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.051309, 40.338380999068981],
            [-102.051308551844016, 40.349221355941118],
            [-101.342788, 40.350443999068908],
            [-101.323353, 40.35055299906891],
            [-101.325514027519006, 40.002565347301299],
            [-101.411028987801998, 40.002582568638402],
            [-101.542273, 40.002608999070809],
            [-101.832161, 40.002932999070808],
            [-102.051744, 40.003077999070797],
            [-102.051309, 40.338380999068981]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "207",
        COUNTYNS: "00758556",
        AFFGEOID: "0500000US29207",
        GEOID: "29207",
        NAME: "Stoddard",
        LSAD: "06",
        ALAND: 2132125025,
        AWATER: 15031190
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.258563, 36.922042999093584],
            [-90.186327, 36.99334499909294],
            [-90.111247, 37.041204999092514],
            [-89.99572, 37.040109999092522],
            [-89.959576, 37.127352999091748],
            [-89.866627, 37.126226999091763],
            [-89.765211, 37.126065999091765],
            [-89.786867, 37.087101999092106],
            [-89.68953, 36.925999999093555],
            [-89.691603, 36.859516999094154],
            [-89.700952, 36.62745299909632],
            [-89.958786, 36.629330999096311],
            [-90.148398, 36.630655999096305],
            [-90.139804, 36.696071999095686],
            [-90.204205, 36.772261999094965],
            [-90.258563, 36.922042999093584]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "011",
        COUNTYNS: "00882275",
        AFFGEOID: "0500000US34011",
        GEOID: "34011",
        NAME: "Cumberland",
        LSAD: "06",
        ALAND: 1251898435,
        AWATER: 503712955
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.417107077488097, 39.388910685788197],
            [-75.368213, 39.456478999074072],
            [-75.238434, 39.559038999073429],
            [-75.080309, 39.460647999074041],
            [-75.061854, 39.568745999073364],
            [-74.985271, 39.514966999073707],
            [-74.856015, 39.423957999074268],
            [-74.861294, 39.323814999074912],
            [-74.926347, 39.209011999075663],
            [-74.915155805562705, 39.176697502594664],
            [-74.998002, 39.191252999075772],
            [-75.048487, 39.215221999075609],
            [-75.090794, 39.210801999075649],
            [-75.136667, 39.181882999075846],
            [-75.166667, 39.222576999075578],
            [-75.21251, 39.262754999075312],
            [-75.244357, 39.285699999075163],
            [-75.285333, 39.2922119990751],
            [-75.326754, 39.332472999074859],
            [-75.355558, 39.347822999074765],
            [-75.399304, 39.379489999074558],
            [-75.417107077488097, 39.388910685788197]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "059",
        COUNTYNS: "00066859",
        AFFGEOID: "0500000US05059",
        GEOID: "05059",
        NAME: "Hot Spring",
        LSAD: "06",
        ALAND: 1593376791,
        AWATER: 18038347
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.407227, 34.3968529991201],
            [-92.948071, 34.38925499912019],
            [-92.915079, 34.50611399911881],
            [-92.793197, 34.503605999118832],
            [-92.685284, 34.473104999119201],
            [-92.668711, 34.4142369991199],
            [-92.678383, 34.14962199912307],
            [-92.886285, 34.155876999122995],
            [-93.038142, 34.155058999123],
            [-93.035039, 34.236663999122008],
            [-93.199428, 34.248063999121875],
            [-93.35684, 34.280305999121488],
            [-93.408124, 34.339526999120778],
            [-93.407227, 34.3968529991201]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "085",
        COUNTYNS: "00974141",
        AFFGEOID: "0500000US36085",
        GEOID: "36085",
        NAME: "Richmond",
        LSAD: "06",
        ALAND: 148972687,
        AWATER: 115952653
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.249211, 40.545063999067914],
            [-74.216839, 40.558617999067835],
            [-74.203688, 40.592690999067663],
            [-74.202247, 40.630902999067473],
            [-74.200122901991094, 40.631868901013164],
            [-74.170611, 40.645288999067404],
            [-74.160146783153181, 40.646076515512199],
            [-74.086806, 40.651595999067368],
            [-74.070943537715294, 40.667208231842892],
            [-74.055739, 40.651759999067373],
            [-74.05663, 40.627286999067493],
            [-74.041393, 40.603105999067616],
            [-74.037970523915803, 40.589570865407175],
            [-74.057316, 40.597545999067641],
            [-74.112585, 40.547602999067884],
            [-74.199923, 40.511728999068069],
            [-74.260611, 40.502435999068119],
            [-74.249211, 40.545063999067914]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "193",
        COUNTYNS: "01480179",
        AFFGEOID: "0500000US51193",
        GEOID: "51193",
        NAME: "Westmoreland",
        LSAD: "06",
        ALAND: 594054337,
        AWATER: 61106324
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.044799, 38.260406999082392],
            [-76.996791259204898, 38.279147833062154],
            [-76.990255, 38.273934999082293],
            [-76.957796, 38.243182999082514],
            [-76.962311, 38.214074999082754],
            [-76.910832, 38.19707299908287],
            [-76.838795, 38.163475999083126],
            [-76.749685, 38.16211399908314],
            [-76.684892, 38.156496999083188],
            [-76.613939, 38.148586999083243],
            [-76.600937, 38.11008399908355],
            [-76.535919, 38.069531999083857],
            [-76.510693961317699, 38.039488926898294],
            [-76.617781, 38.000404999084409],
            [-76.63423, 37.966629999084674],
            [-76.764716, 38.0019789990844],
            [-76.877062, 38.124357999083429],
            [-76.936959, 38.077073999083801],
            [-77.031794, 38.091332999083683],
            [-77.061695, 38.161630999083158],
            [-77.044799, 38.260406999082392]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "111",
        COUNTYNS: "00974153",
        AFFGEOID: "0500000US36111",
        GEOID: "36111",
        NAME: "Ulster",
        LSAD: "06",
        ALAND: 2911757797,
        AWATER: 94596954
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.530985, 42.146474999061276],
            [-74.451713, 42.1692249990612],
            [-74.307571, 42.114345999061385],
            [-74.074797, 42.096588999061453],
            [-74.00245, 42.176991999061187],
            [-73.910675, 42.127292999061332],
            [-73.929626, 42.078777999061501],
            [-73.964413, 41.913147999062055],
            [-73.941081, 41.735992999062702],
            [-73.953307, 41.589976999063261],
            [-74.053685, 41.580609999063292],
            [-74.264292, 41.632628999063094],
            [-74.367055, 41.590976999063244],
            [-74.395071, 41.644875999063032],
            [-74.575086, 41.745257999062659],
            [-74.453685, 41.875594999062201],
            [-74.780693, 42.016374999061711],
            [-74.530985, 42.146474999061276]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "063",
        COUNTYNS: "00069163",
        AFFGEOID: "0500000US05063",
        GEOID: "05063",
        NAME: "Independence",
        LSAD: "06",
        ALAND: 1978631574,
        AWATER: 19593589
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.849448, 35.867410999103811],
            [-91.753345, 35.942882999103063],
            [-91.710552, 35.941758999103058],
            [-91.463198, 35.936809999103126],
            [-91.464678, 35.892496999103564],
            [-91.35723, 35.890620999103568],
            [-91.198556, 35.890023999103583],
            [-91.232392, 35.81266399910438],
            [-91.310358, 35.76617299910486],
            [-91.36878, 35.640011999106186],
            [-91.36957, 35.52636199910738],
            [-91.58188, 35.530446999107347],
            [-91.793958, 35.534113999107298],
            [-91.789823, 35.711733999105434],
            [-91.837179, 35.704931999105504],
            [-91.870983, 35.810561999104401],
            [-91.849448, 35.867410999103811]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "029",
        COUNTYNS: "00758469",
        AFFGEOID: "0500000US29029",
        GEOID: "29029",
        NAME: "Camden",
        LSAD: "06",
        ALAND: 1699090933,
        AWATER: 136351829
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.077692, 38.263091999082377],
            [-93.01499, 38.189774999082928],
            [-92.80696, 38.191156999082921],
            [-92.764539, 38.189618999082931],
            [-92.695886, 38.222030999082683],
            [-92.604684, 38.170732999083079],
            [-92.570281, 38.066634999083881],
            [-92.515979, 38.022335999084227],
            [-92.406275, 38.021178999084249],
            [-92.406872, 37.977801999084583],
            [-92.409231, 37.859179999085526],
            [-92.549074, 37.800538999086008],
            [-92.620181, 37.892520999085271],
            [-92.855384, 37.895735999085247],
            [-93.072447, 37.902626999085179],
            [-93.065199, 38.062478999083915],
            [-93.059073, 38.185684999082966],
            [-93.077692, 38.263091999082377]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "175",
        COUNTYNS: "00758542",
        AFFGEOID: "0500000US29175",
        GEOID: "29175",
        NAME: "Randolph",
        LSAD: "06",
        ALAND: 1250217889,
        AWATER: 13254193
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.697228, 39.597698999073195],
            [-92.692149, 39.610264999073117],
            [-92.300871, 39.605347999073146],
            [-92.310028, 39.347794999074758],
            [-92.314471, 39.246453999075413],
            [-92.430229, 39.248794999075407],
            [-92.707367, 39.321613999074934],
            [-92.697228, 39.597698999073195]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "199",
        COUNTYNS: "01383885",
        AFFGEOID: "0500000US48199",
        GEOID: "48199",
        NAME: "Hardin",
        LSAD: "06",
        ALAND: 2306607883,
        AWATER: 18042331
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.732732, 30.490065999174515],
            [-94.537926, 30.491058999174498],
            [-94.545717, 30.526976999173929],
            [-94.071689, 30.526032999173943],
            [-94.119394, 30.426297999175542],
            [-94.083386, 30.34218099917689],
            [-94.117604, 30.2416649991785],
            [-94.091217, 30.204647999179102],
            [-94.115917, 30.160346999179829],
            [-94.17644, 30.187939999179367],
            [-94.275451, 30.170264999179668],
            [-94.292629, 30.117670999180522],
            [-94.44491, 30.113014999180596],
            [-94.596338, 30.112100999180608],
            [-94.732732, 30.490065999174515]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "091",
        COUNTYNS: "01034220",
        AFFGEOID: "0500000US38091",
        GEOID: "38091",
        NAME: "Steele",
        LSAD: "06",
        ALAND: 1844613572,
        AWATER: 8424876
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.98481, 47.672299999061011],
            [-97.882405, 47.672178999061003],
            [-97.472745, 47.672050999061014],
            [-97.474337, 47.324775999060009],
            [-97.451512, 47.239060999059767],
            [-97.706034, 47.239977999059775],
            [-97.961208, 47.24051199905977],
            [-97.986585, 47.326099999060006],
            [-97.98481, 47.672299999061011]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "001",
        COUNTYNS: "00882270",
        AFFGEOID: "0500000US34001",
        GEOID: "34001",
        NAME: "Atlantic",
        LSAD: "06",
        ALAND: 1438982592,
        AWATER: 301062779
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.911882, 39.578461999073305],
            [-74.87726, 39.608351999073129],
            [-74.800087, 39.674863999072727],
            [-74.736216, 39.72977499907239],
            [-74.723856, 39.722062999072442],
            [-74.654318, 39.626798999073017],
            [-74.475664, 39.551459999073479],
            [-74.417393, 39.557254999073443],
            [-74.380796, 39.498536999073814],
            [-74.2910153361548, 39.508369796571536],
            [-74.291585, 39.507704999073752],
            [-74.304343, 39.471444999073967],
            [-74.36699, 39.402016999074419],
            [-74.412692, 39.360815999074681],
            [-74.521797, 39.313815999074983],
            [-74.540787855582494, 39.300025490259472],
            [-74.653206, 39.288557999075138],
            [-74.861294, 39.323814999074912],
            [-74.856015, 39.423957999074268],
            [-74.985271, 39.514966999073707],
            [-74.911882, 39.578461999073305]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "115",
        COUNTYNS: "01025834",
        AFFGEOID: "0500000US37115",
        GEOID: "37115",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 1164497728,
        AWATER: 4840762
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.966649742898596, 35.795445978678572],
            [-82.937437, 35.827319999104226],
            [-82.899718, 35.874601999103746],
            [-82.910608, 35.926929999103201],
            [-82.893751418431393, 35.933857268812034],
            [-82.860724, 35.947429999103001],
            [-82.81613, 35.923985999103238],
            [-82.787465, 35.952162999102946],
            [-82.779397, 35.992510999102535],
            [-82.725065, 36.018203999102269],
            [-82.628365, 36.062104999101834],
            [-82.605703610793597, 36.037198862663992],
            [-82.595525, 36.026011999102202],
            [-82.610885, 35.974441999102716],
            [-82.557874, 35.953900999102935],
            [-82.508007659722495, 35.982013204544138],
            [-82.494781, 35.909483999103387],
            [-82.408602, 35.818177999104329],
            [-82.41788, 35.813358999104381],
            [-82.427365, 35.813765999104376],
            [-82.435335, 35.80858999910442],
            [-82.446959, 35.810425999104417],
            [-82.454299, 35.807347999104429],
            [-82.463828, 35.809615999104409],
            [-82.465159, 35.801795999104492],
            [-82.476099, 35.798318999104531],
            [-82.488178, 35.801458999104497],
            [-82.494885, 35.799463999104518],
            [-82.499483, 35.801533999104485],
            [-82.513161, 35.803379999104479],
            [-82.805207, 35.686066999105705],
            [-82.883909, 35.678234999105783],
            [-82.957176, 35.74684299910507],
            [-82.966649742898596, 35.795445978678572]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "009",
        COUNTYNS: "01209171",
        AFFGEOID: "0500000US42009",
        GEOID: "42009",
        NAME: "Bedford",
        LSAD: "06",
        ALAND: 2621836594,
        AWATER: 11935889
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.757241, 39.823483999071833],
            [-78.763202, 40.056744999070496],
            [-78.739847, 40.069570999070429],
            [-78.658409, 40.242932999069481],
            [-78.651868, 40.281677999069274],
            [-78.620383, 40.327024999069039],
            [-78.447895, 40.24439499906947],
            [-78.434288, 40.302300999069161],
            [-78.288764, 40.242106999069492],
            [-78.257305, 40.297882999069188],
            [-78.236302, 40.224558999069579],
            [-78.134863, 40.165130999069902],
            [-78.206894, 40.086264999070337],
            [-78.181533, 40.058924999070491],
            [-78.299003, 39.825499999071837],
            [-78.380477064564005, 39.722704137912444],
            [-78.723578, 39.723121999072433],
            [-78.808299529621593, 39.723070955349328],
            [-78.757241, 39.823483999071833]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "009",
        COUNTYNS: "00974103",
        AFFGEOID: "0500000US36009",
        GEOID: "36009",
        NAME: "Cattaraugus",
        LSAD: "06",
        ALAND: 3388633499,
        AWATER: 36608250
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.060777, 42.537852999060114],
            [-78.991702, 42.529248999060123],
            [-78.919766, 42.442385999060384],
            [-78.695937, 42.471939999060297],
            [-78.46394, 42.536331999060117],
            [-78.460716, 42.519193999060157],
            [-78.308839, 42.521216999060165],
            [-78.308128960446595, 41.999071133803163],
            [-78.59665, 41.999876999061776],
            [-78.918857363615203, 41.999103197751168],
            [-78.983065, 41.998948999061774],
            [-79.061264403159896, 41.998838108972762],
            [-79.060777, 42.537852999060114]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "135",
        COUNTYNS: "01804548",
        AFFGEOID: "0500000US72135",
        GEOID: "72135",
        NAME: "Toa Alta",
        LSAD: "13",
        ALAND: 69972584,
        AWATER: 1405464
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.303663, 18.38407699943464],
            [-66.252554, 18.394186999434375],
            [-66.19698, 18.389288999434505],
            [-66.197293, 18.360198999435273],
            [-66.204734, 18.31603499943644],
            [-66.217179, 18.334193999435961],
            [-66.278076, 18.329974999436068],
            [-66.318764, 18.36741099943508],
            [-66.303663, 18.38407699943464]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "067",
        COUNTYNS: "01720027",
        AFFGEOID: "0500000US30067",
        GEOID: "30067",
        NAME: "Park",
        LSAD: "06",
        ALAND: 7258393601,
        AWATER: 27856467
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.044318545430002, 45.000880002364461],
            [-111.038169, 45.351390999056846],
            [-110.917899, 45.35127299905686],
            [-110.919222, 45.524860999056948],
            [-110.858252, 45.524878999056945],
            [-110.857382, 45.590649999057007],
            [-110.794774, 45.590681999056997],
            [-110.790767, 46.141845999057573],
            [-110.783833, 46.19269899905764],
            [-110.409271, 46.192064999057649],
            [-110.281916, 46.18439699905764],
            [-110.292752, 46.133848999057562],
            [-110.292662, 45.785056999057154],
            [-110.220908, 45.78497299905716],
            [-110.219412, 45.348798999056847],
            [-110.228052, 45.172128999056795],
            [-110.064518, 45.172128999056795],
            [-109.812233, 45.172128999056795],
            [-109.798673, 45.167338999056803],
            [-109.798483772435006, 45.002920307341775],
            [-109.99505, 45.00317399905677],
            [-110.324441, 44.999155999056761],
            [-110.705272, 44.992323999056758],
            [-110.785008, 45.002951999056769],
            [-111.044275, 45.001344999056769],
            [-111.044318545430002, 45.000880002364461]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "149",
        COUNTYNS: "01804555",
        AFFGEOID: "0500000US72149",
        GEOID: "72149",
        NAME: "Villalba",
        LSAD: "13",
        ALAND: 92298569,
        AWATER: 3622637
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.521899, 18.151953999440785],
            [-66.470545, 18.177828999440095],
            [-66.44456, 18.176673999440126],
            [-66.419368, 18.145303999440959],
            [-66.430314, 18.082052999442642],
            [-66.518244, 18.085029999442568],
            [-66.521899, 18.151953999440785]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "133",
        COUNTYNS: "01804547",
        AFFGEOID: "0500000US72133",
        GEOID: "72133",
        NAME: "Santa Isabel",
        LSAD: "13",
        ALAND: 88144282,
        AWATER: 111140897
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.453296753479293, 17.98013311292716],
            [-66.421054, 18.019308999444313],
            [-66.426989, 18.042626999443691],
            [-66.356779, 18.036941999443844],
            [-66.331244, 18.015890999444409],
            [-66.338139425689093, 17.976351457200753],
            [-66.33839, 17.976457999445458],
            [-66.385059, 17.939003999446452],
            [-66.445481, 17.979378999445373],
            [-66.453296753479293, 17.98013311292716]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "143",
        COUNTYNS: "01804552",
        AFFGEOID: "0500000US72143",
        GEOID: "72143",
        NAME: "Vega Alta",
        LSAD: "13",
        ALAND: 71816290,
        AWATER: 25307600
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.377058, 18.344245999435692],
            [-66.347733, 18.429536999433441],
            [-66.349503322691305, 18.479214309741433],
            [-66.315477, 18.47472399943225],
            [-66.315024273690199, 18.474741190772747],
            [-66.299926, 18.423912999433593],
            [-66.303663, 18.38407699943464],
            [-66.318764, 18.36741099943508],
            [-66.338239, 18.36793399943506],
            [-66.365272, 18.333228999435985],
            [-66.377058, 18.344245999435692]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "185",
        COUNTYNS: "00326720",
        AFFGEOID: "0500000US13185",
        GEOID: "13185",
        NAME: "Lowndes",
        LSAD: "06",
        ALAND: 1285022595,
        AWATER: 37361466
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.449293, 30.817956999169343],
            [-83.346405, 30.853542999168784],
            [-83.441549, 30.914346999167837],
            [-83.475617, 31.031963999166017],
            [-83.295052, 31.027277999166099],
            [-83.197971, 31.025404999166131],
            [-83.180487, 31.016725999166251],
            [-83.179465, 30.95012799916729],
            [-83.042924, 30.947295999167334],
            [-83.019419, 30.84945299916885],
            [-83.088082, 30.848657999168857],
            [-83.103223, 30.829581999169161],
            [-83.133009, 30.789648999169785],
            [-83.13661885820359, 30.623885944065499],
            [-83.309347121595806, 30.634238765843833],
            [-83.357716313628103, 30.637137872306393],
            [-83.482461, 30.756930999170297],
            [-83.449293, 30.817956999169343]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "107",
        COUNTYNS: "00347508",
        AFFGEOID: "0500000US13107",
        GEOID: "13107",
        NAME: "Emanuel",
        LSAD: "06",
        ALAND: 1762766490,
        AWATER: 24802077
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.564358, 32.53244899914408],
            [-82.594439, 32.57915799914344],
            [-82.465065, 32.646832999142511],
            [-82.499431, 32.686339999141978],
            [-82.434149, 32.762257999140928],
            [-82.419129, 32.810926999140264],
            [-82.316512, 32.835771999139929],
            [-82.213745, 32.803862999140364],
            [-82.144977, 32.812740999140239],
            [-82.082871, 32.663323999142278],
            [-82.001236, 32.606909999143056],
            [-82.030233, 32.538769999144002],
            [-82.079842, 32.560062999143703],
            [-82.148338, 32.520428999144251],
            [-82.254526, 32.351497999146602],
            [-82.232788, 32.318837999147064],
            [-82.354603, 32.292474999147423],
            [-82.409118, 32.353829999146576],
            [-82.38194, 32.440595999145359],
            [-82.488941, 32.491829999144642],
            [-82.647733, 32.512506999144364],
            [-82.564358, 32.53244899914408]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "103",
        COUNTYNS: "00974149",
        AFFGEOID: "0500000US36103",
        GEOID: "36103",
        NAME: "Suffolk",
        LSAD: "06",
        ALAND: 2360846288,
        AWATER: 3785546967
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-72.018926, 41.274113999064525],
              [-71.926802, 41.290121999064453],
              [-71.917281, 41.251332999064616],
              [-72.034754, 41.234817999064688],
              [-72.018926, 41.274113999064525]
            ]
          ],
          [
            [
              [-73.485365, 40.946396999065968],
              [-73.436664, 40.934896999066027],
              [-73.392862, 40.955296999065929],
              [-73.33136, 40.92959699906605],
              [-73.235827406678496, 40.906689766598454],
              [-73.229285, 40.905120999066156],
              [-73.148994, 40.928897999066052],
              [-73.144673, 40.955841999065925],
              [-73.110368, 40.971937999065851],
              [-73.040445, 40.964497999065891],
              [-72.859831, 40.966087999065884],
              [-72.708069, 40.977850999065836],
              [-72.585327, 40.997586999065732],
              [-72.504305, 41.04332899906553],
              [-72.445242, 41.086115999065335],
              [-72.389809, 41.108303999065235],
              [-72.354123, 41.139951999065097],
              [-72.291109, 41.15587399906503],
              [-72.189163, 41.193548999064873],
              [-72.182033, 41.178344999064933],
              [-72.254704, 41.110851999065233],
              [-72.283093, 41.067873999065426],
              [-72.217476, 41.040610999065542],
              [-72.162898, 41.053186999065488],
              [-72.126704, 41.115138999065216],
              [-72.084207, 41.101523999065272],
              [-72.095711, 41.054019999065481],
              [-72.051928, 41.020505999065627],
              [-71.959595, 41.071236999065412],
              [-71.919385, 41.080516999065367],
              [-71.856214, 41.070597999065406],
              [-71.936977, 41.006136999065696],
              [-72.097369, 40.958879999065907],
              [-72.298727, 40.90315099906617],
              [-72.39585, 40.866659999066343],
              [-72.757176, 40.764370999066827],
              [-72.923214, 40.713281999067071],
              [-73.012545, 40.679650999067221],
              [-73.146080869210792, 40.646407967168692],
              [-73.20844, 40.630883999067464],
              [-73.306396, 40.620755999067526],
              [-73.351465, 40.630499999067474],
              [-73.423976617557202, 40.613244876627064],
              [-73.423253, 40.670919999067273],
              [-73.436006, 40.73945799906695],
              [-73.4385, 40.751310999066881],
              [-73.462259, 40.86670999906633],
              [-73.497351038626277, 40.923182272360464],
              [-73.485365, 40.946396999065968]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "109",
        COUNTYNS: "01696629",
        AFFGEOID: "0500000US30109",
        GEOID: "30109",
        NAME: "Wibaux",
        LSAD: "06",
        ALAND: 2301488703,
        AWATER: 1756846
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.607307, 46.831876999058792],
            [-104.603766, 46.860852999058856],
            [-104.412124, 46.904002999058946],
            [-104.306062, 47.042045999059269],
            [-104.316166, 47.180539999059611],
            [-104.420347, 47.354434999060068],
            [-104.131863, 47.353900999060073],
            [-104.044976716405003, 47.3970692003426],
            [-104.045313, 47.331954999060024],
            [-104.045308309869014, 47.330127857866003],
            [-104.044788, 47.12742999905948],
            [-104.045542, 46.93388699905902],
            [-104.045572, 46.713880999058539],
            [-104.045385103689014, 46.641501058180211],
            [-104.354835, 46.641408999058406],
            [-104.417998, 46.684891999058486],
            [-104.606903, 46.684923999058498],
            [-104.607307, 46.831876999058792]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "045",
        COUNTYNS: "01448036",
        AFFGEOID: "0500000US49045",
        GEOID: "49045",
        NAME: "Tooele",
        LSAD: "06",
        ALAND: 17979586953,
        AWATER: 891043654
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.046386, 40.097895999070275],
            [-114.046372817372998, 40.116935832082369],
            [-114.046178, 40.398312999068665],
            [-114.045577, 40.495800999068159],
            [-114.043505, 40.726291999067008],
            [-114.042145146235001, 40.999896576329022],
            [-112.799359, 40.999938999065719],
            [-112.493515, 41.07688799906537],
            [-112.260216, 40.769092999066814],
            [-112.201816, 40.706403999067113],
            [-112.196393, 40.62933999906749],
            [-112.175337, 40.603980999067602],
            [-112.172862, 40.467064999068299],
            [-112.212254, 40.459113999068343],
            [-112.175533, 40.335836999068988],
            [-112.179693, 40.232239999069535],
            [-112.146856, 40.177816999069833],
            [-112.1798, 40.011655999070754],
            [-112.269882, 39.938946999071163],
            [-112.332296, 39.952788999071096],
            [-112.341988, 39.904457999071369],
            [-113.248498, 39.904713999071362],
            [-114.047268153076999, 39.906098112827564],
            [-114.046386, 40.097895999070275]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "323",
        COUNTYNS: "01383944",
        AFFGEOID: "0500000US48323",
        GEOID: "48323",
        NAME: "Maverick",
        LSAD: "06",
        ALAND: 3313819221,
        AWATER: 31869807
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.671215189194001, 29.083516024542426],
            [-100.111406, 29.086317999197881],
            [-100.114336, 28.648121999205571],
            [-100.113719, 28.197818999213666],
            [-100.197510055500999, 28.197000730353579],
            [-100.267604, 28.250268999212718],
            [-100.287554, 28.301092999211797],
            [-100.320393, 28.362116999210695],
            [-100.337059, 28.42715099920952],
            [-100.368288, 28.477195999208625],
            [-100.38886, 28.515747999207935],
            [-100.39727, 28.575636999206868],
            [-100.448648, 28.616773999206124],
            [-100.500354, 28.661959999205319],
            [-100.506701, 28.716744999204359],
            [-100.533017, 28.76327999920353],
            [-100.53583, 28.805887999202781],
            [-100.576846, 28.836167999202246],
            [-100.627206, 28.903733999201066],
            [-100.646993, 28.957078999200135],
            [-100.660208, 29.031496999198829],
            [-100.671215189194001, 29.083516024542426]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "101",
        COUNTYNS: "01480138",
        AFFGEOID: "0500000US51101",
        GEOID: "51101",
        NAME: "King William",
        LSAD: "06",
        ALAND: 709481149,
        AWATER: 31126025
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.345215, 37.789174999086107],
            [-77.241044, 37.909907999085135],
            [-77.181418, 37.891653999085271],
            [-77.169004, 37.897044999085232],
            [-77.103108, 37.786033999086129],
            [-77.033273, 37.720808999086664],
            [-76.915586, 37.69332499908689],
            [-76.777729, 37.554445999088053],
            [-76.792184, 37.517593999088355],
            [-76.878069, 37.531376999088238],
            [-76.858427, 37.57954099908784],
            [-76.981158, 37.567112999087939],
            [-77.123675, 37.62688099908744],
            [-77.108191, 37.660210999087155],
            [-77.186674, 37.657707999087194],
            [-77.292404, 37.714066999086718],
            [-77.345215, 37.789174999086107]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "009",
        COUNTYNS: "01531341",
        AFFGEOID: "0500000US53009",
        GEOID: "53009",
        NAME: "Clallam",
        LSAD: "06",
        ALAND: 4503153141,
        AWATER: 2413877536
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.725839, 48.386011999063527],
            [-124.653243, 48.390690999063551],
            [-124.546259, 48.353593999063406],
            [-124.380874, 48.284698999063131],
            [-124.250882, 48.264772999063055],
            [-124.101773, 48.216882999062882],
            [-124.050734, 48.177746999062734],
            [-123.880068, 48.160620999062672],
            [-123.728736, 48.162799999062685],
            [-123.672445, 48.162714999062686],
            [-123.551131, 48.151381999062629],
            [-123.441972, 48.12425899906254],
            [-123.314578, 48.113724999062491],
            [-123.239129, 48.118216999062518],
            [-123.144783, 48.175942999062727],
            [-123.06621, 48.120468999062524],
            [-123.004128, 48.090515999062418],
            [-122.946119, 48.098551999062444],
            [-122.910686755724981, 48.109799742995285],
            [-122.896971, 48.065154999062322],
            [-122.94918, 48.065149999062321],
            [-122.949167, 47.866821999061635],
            [-123.505917, 47.866896999061645],
            [-124.613108656052006, 47.880572440810298],
            [-124.625512, 47.887962999061713],
            [-124.672427, 47.964413999061968],
            [-124.685393, 48.049237999062257],
            [-124.687101, 48.098656999062442],
            [-124.721725, 48.153184999062638],
            [-124.690389, 48.21974499906289],
            [-124.669265, 48.296352999063181],
            [-124.725839, 48.386011999063527]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "019",
        COUNTYNS: "01531821",
        AFFGEOID: "0500000US53019",
        GEOID: "53019",
        NAME: "Ferry",
        LSAD: "06",
        ALAND: 5706360692,
        AWATER: 140294376
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-118.869633, 48.653825999064615],
            [-118.837006, 48.653868999064628],
            [-118.836613035580001, 49.000307758363761],
            [-118.197375052458995, 49.000406750490761],
            [-118.221669, 48.93709799906587],
            [-118.195508, 48.840724999065436],
            [-118.131002, 48.777652999065154],
            [-118.10496, 48.653502999064614],
            [-118.149709, 48.52056799906407],
            [-118.206507, 48.470295999063872],
            [-118.175602, 48.319491999063267],
            [-118.141477, 48.26015499906304],
            [-118.1945, 48.212888999062862],
            [-118.191298, 48.159887999062661],
            [-118.248606, 48.134254999062577],
            [-118.233198, 48.065284999062328],
            [-118.275476, 48.040566999062236],
            [-118.400807, 48.028681999062186],
            [-118.34874, 47.971781999062003],
            [-118.340938, 47.894315999061732],
            [-118.369064, 47.83268499906152],
            [-118.54409, 47.883972999061697],
            [-118.570396, 47.932317999061866],
            [-118.688394, 47.942717999061898],
            [-118.711682, 47.90334699906176],
            [-118.85196, 47.957905999061957],
            [-118.845785, 47.963477999061972],
            [-118.843888, 48.4817389990639],
            [-118.86952, 48.481625999063915],
            [-118.869633, 48.653825999064615]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "051",
        COUNTYNS: "01529157",
        AFFGEOID: "0500000US53051",
        GEOID: "53051",
        NAME: "Pend Oreille",
        LSAD: "06",
        ALAND: 3626633160,
        AWATER: 65385333
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.629421, 48.742633999064999],
            [-117.563811, 48.742960999065005],
            [-117.563858, 48.836670999065419],
            [-117.498496, 48.836899999065416],
            [-117.498957, 48.923158999065805],
            [-117.430377, 48.923167999065804],
            [-117.429968, 49.000306868891762],
            [-117.268247, 48.999817999066167],
            [-117.032351, 48.999187999066159],
            [-117.032939182117019, 48.84666792026696],
            [-117.033671, 48.656901999064637],
            [-117.035289, 48.422731999063672],
            [-117.041107, 48.124903999062539],
            [-117.041214453461009, 48.045465102865059],
            [-117.436993, 48.047206999062261],
            [-117.437629, 48.134687999062571],
            [-117.502371, 48.134707999062577],
            [-117.499975, 48.65618699906463],
            [-117.629241, 48.655851999064623],
            [-117.629421, 48.742633999064999]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "055",
        COUNTYNS: "01531931",
        AFFGEOID: "0500000US53055",
        GEOID: "53055",
        NAME: "San Juan",
        LSAD: "06",
        ALAND: 450428674,
        AWATER: 1157428261
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.237148, 48.683465999064758],
            [-123.070427, 48.699970999064824],
            [-123.019699, 48.721311999064909],
            [-122.979519, 48.781701999065177],
            [-122.937925, 48.790314999065224],
            [-122.818436, 48.744628999065014],
            [-122.743049, 48.661990999064663],
            [-122.79901, 48.604682999064416],
            [-122.771206, 48.562425999064246],
            [-122.779124, 48.508910999064007],
            [-122.817912, 48.483887999063931],
            [-122.803521, 48.428747999063695],
            [-122.874135, 48.418195999063663],
            [-122.928004, 48.439965999063745],
            [-123.039156, 48.460002999063832],
            [-123.141478, 48.505290999064002],
            [-123.20268, 48.590213999064353],
            [-123.237148, 48.683465999064758]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "065",
        COUNTYNS: "01531930",
        AFFGEOID: "0500000US53065",
        GEOID: "53065",
        NAME: "Stevens",
        LSAD: "06",
        ALAND: 6416413114,
        AWATER: 163008888
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-118.400807, 48.028681999062186],
            [-118.275476, 48.040566999062236],
            [-118.233198, 48.065284999062328],
            [-118.248606, 48.134254999062577],
            [-118.191298, 48.159887999062661],
            [-118.1945, 48.212888999062862],
            [-118.141477, 48.26015499906304],
            [-118.175602, 48.319491999063267],
            [-118.206507, 48.470295999063872],
            [-118.149709, 48.52056799906407],
            [-118.10496, 48.653502999064614],
            [-118.131002, 48.777652999065154],
            [-118.195508, 48.840724999065436],
            [-118.221669, 48.93709799906587],
            [-118.197375052458995, 49.000406750490761],
            [-118.002046, 49.00043699906616],
            [-117.607323, 49.000842999066165],
            [-117.429968, 49.000306868891762],
            [-117.430377, 48.923167999065804],
            [-117.498957, 48.923158999065805],
            [-117.498496, 48.836899999065416],
            [-117.563858, 48.836670999065419],
            [-117.563811, 48.742960999065005],
            [-117.629421, 48.742633999064999],
            [-117.629241, 48.655851999064623],
            [-117.499975, 48.65618699906463],
            [-117.502371, 48.134707999062577],
            [-117.437629, 48.134687999062571],
            [-117.436993, 48.047206999062261],
            [-117.535262, 48.047778999062274],
            [-117.535262, 47.794457999061407],
            [-117.697774, 47.856277999061618],
            [-117.820947, 47.825774999061501],
            [-117.881473, 47.84748999906158],
            [-118.078182, 47.800061999061413],
            [-118.212323, 47.945492999061905],
            [-118.246103, 47.943603999061907],
            [-118.340938, 47.894315999061732],
            [-118.34874, 47.971781999062003],
            [-118.400807, 48.028681999062186]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "073",
        COUNTYNS: "01529224",
        AFFGEOID: "0500000US53073",
        GEOID: "53073",
        NAME: "Whatcom",
        LSAD: "06",
        ALAND: 5459395487,
        AWATER: 1028184768
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.821631, 48.94136899906588],
            [-122.75802, 49.00235699906618],
            [-122.251063, 49.002493999066168],
            [-122.098357, 49.002145999066173],
            [-121.751252, 48.997398999066149],
            [-121.395543, 48.999850999066162],
            [-121.12624, 49.001411999066164],
            [-120.851527133017996, 49.000591151901361],
            [-120.735281, 48.864373999065542],
            [-120.735427, 48.786486999065204],
            [-120.655857, 48.724341999064926],
            [-120.751904, 48.657001999064633],
            [-120.908357, 48.657002999064638],
            [-120.908358, 48.640900999064577],
            [-121.564067, 48.642331999064581],
            [-122.215836, 48.645689999064572],
            [-122.501489, 48.645319999064583],
            [-122.565915, 48.629912999064523],
            [-122.607778, 48.626073999064502],
            [-122.671345425666985, 48.645298997584788],
            [-122.710177, 48.722236999064918],
            [-122.720045, 48.789194999065209],
            [-122.732506, 48.838096999065421],
            [-122.794018, 48.88312999906563],
            [-122.821631, 48.94136899906588]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "009",
        COUNTYNS: "01581064",
        AFFGEOID: "0500000US55009",
        GEOID: "55009",
        NAME: "Brown",
        LSAD: "06",
        ALAND: 1372929582,
        AWATER: 221812520
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.25226, 44.667426999056815],
            [-88.242687, 44.679630999056805],
            [-88.220384, 44.672379999056808],
            [-87.997573406442498, 44.67766393810161],
            [-88.002085, 44.664034999056817],
            [-87.998716, 44.609287999056832],
            [-88.041202, 44.572580999056846],
            [-88.005518, 44.53921599905685],
            [-87.943801, 44.529692999056856],
            [-87.898888, 44.574134999056845],
            [-87.866884, 44.608433999056828],
            [-87.77516, 44.639280999056815],
            [-87.761307678885103, 44.653697748791721],
            [-87.766026, 44.327183999056984],
            [-87.887526, 44.327588999056992],
            [-87.888087, 44.240460999057049],
            [-88.04324, 44.24101799905705],
            [-88.192611, 44.241946999057042],
            [-88.190465, 44.586798999056839],
            [-88.245204, 44.584745999056835],
            [-88.25226, 44.667426999056815]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "029",
        COUNTYNS: "01448028",
        AFFGEOID: "0500000US49029",
        GEOID: "49029",
        NAME: "Morgan",
        LSAD: "06",
        ALAND: 1577739361,
        AWATER: 4437369
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.872732, 41.188037999064896],
            [-111.799811, 41.217177999064766],
            [-111.678016, 41.181207999064924],
            [-111.596716, 41.238552999064666],
            [-111.497182, 41.226904999064729],
            [-111.420728, 41.361307999064167],
            [-111.331944, 41.357471999064174],
            [-111.261803, 41.296680999064428],
            [-111.281298, 41.224969999064733],
            [-111.221776, 41.215118999064778],
            [-111.264974, 41.144043999065083],
            [-111.366604, 41.130017999065146],
            [-111.380452, 41.087247999065333],
            [-111.50751, 41.063233999065439],
            [-111.592981, 40.993014999065764],
            [-111.524895, 40.953841999065929],
            [-111.479555, 40.85828799906637],
            [-111.527985, 40.785361999066723],
            [-111.641052, 40.798924999066664],
            [-111.663996, 40.850193999066406],
            [-111.738744, 40.860997999066363],
            [-111.8154, 40.957717999065906],
            [-111.775041, 40.959315999065915],
            [-111.801627, 40.992576999065761],
            [-111.838249, 41.022979999065626],
            [-111.856247, 41.13908299906511],
            [-111.872732, 41.188037999064896]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "225",
        COUNTYNS: "01383898",
        AFFGEOID: "0500000US48225",
        GEOID: "48225",
        NAME: "Houston",
        LSAD: "06",
        ALAND: 3188288249,
        AWATER: 14426022
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.775578, 31.136094999164424],
            [-95.728703, 31.164063999164],
            [-95.725226, 31.271083999162368],
            [-95.657247, 31.33744799916137],
            [-95.711092, 31.454132999159615],
            [-95.746837, 31.458169999159555],
            [-95.739279, 31.504055999158869],
            [-95.651764, 31.541790999158309],
            [-95.273203, 31.592885999157552],
            [-95.168509, 31.583670999157686],
            [-95.099966, 31.518868999158652],
            [-95.101382, 31.469886999159385],
            [-95.003345, 31.425709999160045],
            [-94.976718, 31.409144999160283],
            [-94.95811, 31.386929999160628],
            [-95.434781, 31.058097999165632],
            [-95.617872, 30.930417999167588],
            [-95.681158, 30.986957999166727],
            [-95.656487, 31.079338999165287],
            [-95.76441, 31.094210999165067],
            [-95.775578, 31.136094999164424]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "395",
        COUNTYNS: "01383983",
        AFFGEOID: "0500000US48395",
        GEOID: "48395",
        NAME: "Robertson",
        LSAD: "06",
        ALAND: 2214719320,
        AWATER: 26358042
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.829121, 31.105870999164889],
            [-96.596862, 31.220899999163137],
            [-96.319165, 31.35712899916107],
            [-96.331083, 31.252711999162649],
            [-96.269199, 31.077854999165318],
            [-96.260594, 31.077070999165336],
            [-96.243258, 31.06113699916558],
            [-96.24102, 30.973736999166924],
            [-96.338001, 30.920530999167749],
            [-96.45763, 30.744473999170499],
            [-96.566983, 30.695669999171262],
            [-96.621281, 30.730818999170712],
            [-96.611097, 30.757353999170284],
            [-96.694853, 30.864516999168622],
            [-96.68497, 30.906190999167965],
            [-96.75633, 30.92720599916764],
            [-96.793139, 31.0657179991655],
            [-96.829121, 31.105870999164889]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "061",
        COUNTYNS: "01101818",
        AFFGEOID: "0500000US40061",
        GEOID: "40061",
        NAME: "Haskell",
        LSAD: "06",
        ALAND: 1493182633,
        AWATER: 126266425
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.45125, 35.296018999109869],
            [-95.390252, 35.32749699910952],
            [-95.34465, 35.293096999109899],
            [-95.240718, 35.262309999110236],
            [-95.170462, 35.310105999109716],
            [-95.049933, 35.458893999108106],
            [-95.00651, 35.414599999108582],
            [-94.901832, 35.397939999108765],
            [-94.914454, 35.347524999109304],
            [-94.828972, 35.361545999109154],
            [-94.813629, 35.323343999109568],
            [-94.814284, 35.202330999110906],
            [-94.927936, 35.202610999110888],
            [-94.927863, 35.058278999112495],
            [-95.349233, 35.058513999112478],
            [-95.349339, 35.160295999111355],
            [-95.454954, 35.160576999111349],
            [-95.45125, 35.296018999109869]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "079",
        COUNTYNS: "01480129",
        AFFGEOID: "0500000US51079",
        GEOID: "51079",
        NAME: "Greene",
        LSAD: "06",
        ALAND: 403788487,
        AWATER: 1756849
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.553143, 38.33482399908182],
            [-78.48574, 38.421578999081177],
            [-78.452801, 38.475526999080778],
            [-78.429626, 38.366703999081587],
            [-78.348971, 38.277412999082259],
            [-78.288837, 38.272655999082289],
            [-78.368743, 38.184073999082976],
            [-78.663145, 38.277932999082253],
            [-78.553143, 38.33482399908182]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "079",
        COUNTYNS: "01101827",
        AFFGEOID: "0500000US40079",
        GEOID: "40079",
        NAME: "Le Flore",
        LSAD: "06",
        ALAND: 4116222830,
        AWATER: 50024113
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.059512, 34.855018999114776],
            [-94.99781, 34.855077999114769],
            [-94.99812, 34.942156999113784],
            [-94.927668, 34.942211999113795],
            [-94.927863, 35.058278999112495],
            [-94.927936, 35.202610999110888],
            [-94.814284, 35.202330999110906],
            [-94.813629, 35.323343999109568],
            [-94.750224, 35.356968999109206],
            [-94.668796, 35.295028999109874],
            [-94.594801, 35.327973999109517],
            [-94.49237, 35.3017759991098],
            [-94.433913443832395, 35.386361769007593],
            [-94.431515, 35.369590999109064],
            [-94.435316, 35.27589299911007],
            [-94.442925861690014, 35.062506208685143],
            [-94.447509535784292, 34.93397621127108],
            [-94.449058, 34.890555999114376],
            [-94.454395034137889, 34.728958672295626],
            [-94.4575, 34.634944999117309],
            [-94.461168686155901, 34.507456650691296],
            [-94.937605, 34.506830999118804],
            [-94.937752, 34.680876999116762],
            [-95.059616, 34.680735999116763],
            [-95.059512, 34.855018999114776]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "053",
        COUNTYNS: "01560254",
        AFFGEOID: "0500000US54053",
        GEOID: "54053",
        NAME: "Mason",
        LSAD: "06",
        ALAND: 1115633278,
        AWATER: 36174540
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.175167, 38.608483999079795],
            [-82.185567, 38.659582999079433],
            [-82.201537, 38.760371999078721],
            [-82.20929, 38.80267199907842],
            [-82.16157, 38.824631999078257],
            [-82.134766, 38.905578999077704],
            [-82.098866650554299, 38.96087928357062],
            [-82.089065, 38.975977999077216],
            [-82.041563, 39.017877999076923],
            [-82.007062, 39.029577999076857],
            [-81.941829, 38.993294999077101],
            [-81.89847, 38.929602999077538],
            [-81.910606, 38.875969999077903],
            [-81.791265, 38.733103999078907],
            [-81.772843, 38.680842999079275],
            [-81.955791, 38.657354999079445],
            [-82.064562, 38.607984999079797],
            [-82.055127, 38.474546999080779],
            [-82.218967, 38.591682999079922],
            [-82.175167, 38.608483999079795]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "069",
        COUNTYNS: "01717163",
        AFFGEOID: "0500000US54069",
        GEOID: "54069",
        NAME: "Ohio",
        LSAD: "06",
        ALAND: 274098959,
        AWATER: 8274296
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.736804, 40.080071999070377],
            [-80.705994, 40.151590999069981],
            [-80.702575303622595, 40.157141397079656],
            [-80.684173210707996, 40.187017972420485],
            [-80.664006, 40.178946999069822],
            [-80.519084370456596, 40.159671994188024],
            [-80.51912, 40.016409999070724],
            [-80.738218439983598, 40.033543225149032],
            [-80.736804, 40.080071999070377]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "039",
        COUNTYNS: "01008545",
        AFFGEOID: "0500000US37039",
        GEOID: "37039",
        NAME: "Cherokee",
        LSAD: "06",
        ALAND: 1179401584,
        AWATER: 29257527
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.286598378740905, 35.205758217606743],
            [-84.28322, 35.226576999110627],
            [-84.223718, 35.269077999110159],
            [-84.178516, 35.240678999110479],
            [-84.097508, 35.247381999110402],
            [-84.029104413772487, 35.292123775603805],
            [-83.968532, 35.218073999110715],
            [-83.831429, 35.256116999110297],
            [-83.702772, 35.248519999110385],
            [-83.690212, 35.237941999110504],
            [-83.738129, 35.155949999111392],
            [-83.855493, 35.137955999111597],
            [-84.005336733438284, 34.987649583125574],
            [-84.129446962635882, 34.98794695306858],
            [-84.321869, 34.988407999113271],
            [-84.286598378740905, 35.205758217606743]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "025",
        COUNTYNS: "00161538",
        AFFGEOID: "0500000US01025",
        GEOID: "01025",
        NAME: "Clarke",
        LSAD: "06",
        ALAND: 3207494103,
        AWATER: 36657891
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.121414, 31.950255999152308],
            [-88.07349, 31.990181999151741],
            [-87.667769, 31.991354999151721],
            [-87.666836, 31.875746999153403],
            [-87.620112, 31.827122999154106],
            [-87.50093, 31.829250999154073],
            [-87.516131, 31.697823999155997],
            [-87.566841, 31.697114999156003],
            [-87.565413, 31.553572999158135],
            [-87.619844, 31.519048999158638],
            [-87.573733, 31.435087999159901],
            [-87.665572, 31.423165999160076],
            [-87.784796, 31.324671999161559],
            [-87.765152, 31.297345999161969],
            [-87.803517, 31.319006999161651],
            [-87.785775, 31.282943999162185],
            [-87.913637, 31.187355999163643],
            [-87.946588, 31.19292999916356],
            [-87.959619, 31.316205999161689],
            [-87.908068, 31.323040999161584],
            [-87.88729, 31.392196999160547],
            [-87.935106, 31.442397999159788],
            [-87.906143, 31.491751999159053],
            [-87.92444, 31.503020999158888],
            [-87.942514, 31.526718999158533],
            [-87.970377, 31.53054599915848],
            [-88.031277, 31.560493999158034],
            [-88.027693, 31.584250999157678],
            [-88.051449, 31.583503999157688],
            [-88.072234, 31.594499999157534],
            [-88.089388, 31.65874699915657],
            [-88.078711, 31.669617999156412],
            [-88.088288, 31.699302999155979],
            [-88.180384, 31.814545999154287],
            [-88.121414, 31.950255999152308]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "091",
        COUNTYNS: "00161572",
        AFFGEOID: "0500000US01091",
        GEOID: "01091",
        NAME: "Marengo",
        LSAD: "06",
        ALAND: 2530140238,
        AWATER: 14966620
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.113584, 32.006239999151511],
            [-88.007077, 32.185312999148948],
            [-88.019145, 32.285239999147542],
            [-87.930661, 32.310573999147174],
            [-88.046335, 32.377086999146243],
            [-88.031623, 32.43360999914546],
            [-87.85342, 32.532085999144087],
            [-87.824977, 32.544788999143918],
            [-87.812559, 32.524559999144195],
            [-87.728521, 32.524531999144187],
            [-87.728744, 32.480917999144793],
            [-87.524485, 32.482027999144783],
            [-87.52429, 32.307292999147229],
            [-87.47308, 32.307613999147229],
            [-87.472206, 32.264918999147824],
            [-87.514066, 32.265992999147812],
            [-87.521946, 32.132815999149706],
            [-87.624005, 32.132344999149709],
            [-87.622756, 32.005481999151527],
            [-87.667769, 31.991354999151721],
            [-88.07349, 31.990181999151741],
            [-88.113584, 32.006239999151511]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "011",
        COUNTYNS: "01550012",
        AFFGEOID: "0500000US54011",
        GEOID: "54011",
        NAME: "Cabell",
        LSAD: "06",
        ALAND: 727837364,
        AWATER: 18122707
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.447076, 38.426981999081121],
            [-82.381773, 38.434782999081065],
            [-82.323999, 38.449267999080966],
            [-82.304223, 38.496307999080621],
            [-82.293271, 38.560282999080151],
            [-82.282133344028097, 38.579860795483505],
            [-82.27427, 38.59368299907991],
            [-82.218967, 38.591682999079922],
            [-82.055127, 38.474546999080779],
            [-82.047128, 38.37443199908153],
            [-82.220797, 38.310410999082009],
            [-82.264849, 38.229198999082627],
            [-82.28624, 38.321184999081929],
            [-82.343911, 38.305208999082048],
            [-82.508966949731288, 38.414643911732924],
            [-82.447076, 38.426981999081121]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "065",
        COUNTYNS: "00161558",
        AFFGEOID: "0500000US01065",
        GEOID: "01065",
        NAME: "Hale",
        LSAD: "06",
        ALAND: 1667907107,
        AWATER: 32423356
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.813178, 32.81017599914027],
            [-87.831749, 32.840504999139867],
            [-87.804218, 32.897522999139099],
            [-87.729941, 32.949257999138418],
            [-87.715709, 33.006823999137637],
            [-87.421936, 33.003378999137681],
            [-87.4212, 32.874507999139404],
            [-87.472174, 32.830619999139998],
            [-87.473915, 32.65586699914239],
            [-87.525198, 32.655712999142381],
            [-87.524485, 32.482027999144783],
            [-87.728744, 32.480917999144793],
            [-87.728521, 32.524531999144187],
            [-87.812559, 32.524559999144195],
            [-87.787701, 32.582288999143401],
            [-87.736928, 32.589688999143291],
            [-87.813401, 32.633874999142684],
            [-87.808284, 32.752371999141069],
            [-87.870464, 32.762441999140925],
            [-87.813178, 32.81017599914027]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "099",
        COUNTYNS: "01550056",
        AFFGEOID: "0500000US54099",
        GEOID: "54099",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 1310504124,
        AWATER: 15860720
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.598864, 38.201006999082843],
            [-82.584693814125586, 38.240512992452238],
            [-82.581796, 38.248591999082471],
            [-82.571877, 38.315780999081973],
            [-82.597979, 38.344908999081746],
            [-82.595964264499997, 38.380889760796968],
            [-82.593673, 38.421808999081172],
            [-82.560664, 38.404337999081299],
            [-82.508966949731288, 38.414643911732924],
            [-82.343911, 38.305208999082048],
            [-82.28624, 38.321184999081929],
            [-82.264849, 38.229198999082627],
            [-82.283316, 38.141935999083302],
            [-82.192314, 38.046313999084042],
            [-82.306391, 37.943639999084851],
            [-82.403743, 37.862893999085493],
            [-82.398464816258894, 37.843054149548557],
            [-82.41869, 37.87237499908543],
            [-82.487556, 37.916974999085085],
            [-82.479420123222994, 37.938562523783006],
            [-82.464987, 37.976858999084591],
            [-82.549407, 38.063062999083911],
            [-82.626182, 38.134834999083346],
            [-82.598864, 38.201006999082843]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "133",
        COUNTYNS: "01209193",
        AFFGEOID: "0500000US42133",
        GEOID: "42133",
        NAME: "York",
        LSAD: "06",
        ALAND: 2341882815,
        AWATER: 16941833
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.029433, 40.147546999070002],
            [-77.012601, 40.148420999069998],
            [-76.905875, 40.165722999069907],
            [-76.921265, 40.209206999069664],
            [-76.859023, 40.226327999069561],
            [-76.824696, 40.202318999069696],
            [-76.756379, 40.177051999069825],
            [-76.72162, 40.120069999070147],
            [-76.663825, 40.063226999070459],
            [-76.534927, 40.051358999070537],
            [-76.47358, 39.947351999071117],
            [-76.239683741114604, 39.72164198899705],
            [-76.41898, 39.721525999072441],
            [-76.569475117211596, 39.721458558177751],
            [-76.715771, 39.721392999072442],
            [-76.787097199757596, 39.721048145183744],
            [-76.991062, 39.720061999072449],
            [-76.999318026333199, 39.720067671425056],
            [-77.000312, 39.831218999071794],
            [-76.954932, 39.856849999071642],
            [-76.966202, 39.935191999071201],
            [-77.018648, 40.013946999070747],
            [-77.137425, 40.06994499907043],
            [-77.029433, 40.147546999070002]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "215",
        COUNTYNS: "00758560",
        AFFGEOID: "0500000US29215",
        GEOID: "29215",
        NAME: "Texas",
        LSAD: "06",
        ALAND: 3049104547,
        AWATER: 5071080
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.252261, 37.472943999088741],
            [-92.249463, 37.604542999087634],
            [-92.183261, 37.605242999087622],
            [-92.029258, 37.602541999087641],
            [-91.809105, 37.598862999087686],
            [-91.754795, 37.598767999087677],
            [-91.75504, 37.424109999089154],
            [-91.646626, 37.422730999089168],
            [-91.655607, 37.048924999092435],
            [-92.088708, 37.056247999092378],
            [-92.251359, 37.059652999092357],
            [-92.252261, 37.472943999088741]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "075",
        COUNTYNS: "00066865",
        AFFGEOID: "0500000US05075",
        GEOID: "05075",
        NAME: "Lawrence",
        LSAD: "06",
        ALAND: 1521880659,
        AWATER: 12286159
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.349834, 36.230966999100154],
            [-91.25892, 36.257879999099892],
            [-91.16308, 36.205916999100396],
            [-91.073465, 36.114893999101305],
            [-91.045223, 36.151367999100941],
            [-90.809816, 36.149566999100962],
            [-90.74747, 36.149115999100971],
            [-90.820763, 36.079769999101657],
            [-90.852064, 35.968091999102789],
            [-90.872785, 35.88086999910368],
            [-91.032499, 35.885069999103635],
            [-91.138801, 35.889998999103582],
            [-91.198556, 35.890023999103583],
            [-91.35723, 35.890620999103568],
            [-91.349834, 36.230966999100154]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "570",
        COUNTYNS: "01498420",
        AFFGEOID: "0500000US51570",
        GEOID: "51570",
        NAME: "Colonial Heights",
        LSAD: "25",
        ALAND: 19475234,
        AWATER: 766909
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.431705, 37.276341999090427],
            [-77.378294, 37.284505999090364],
            [-77.368203, 37.298548999090237],
            [-77.362322, 37.282161999090377],
            [-77.379193, 37.267579999090501],
            [-77.382521, 37.242836999090727],
            [-77.403671, 37.23734599909077],
            [-77.405034, 37.23609999909079],
            [-77.406415, 37.235644999090781],
            [-77.408023, 37.234551999090797],
            [-77.409183, 37.234271999090801],
            [-77.409877, 37.233722999090809],
            [-77.413119, 37.234256999090796],
            [-77.413559, 37.233948999090792],
            [-77.41542, 37.233795999090802],
            [-77.416501, 37.233207999090808],
            [-77.414406, 37.260948999090552],
            [-77.431705, 37.276341999090427]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "081",
        COUNTYNS: "00066874",
        AFFGEOID: "0500000US05081",
        GEOID: "05081",
        NAME: "Little River",
        LSAD: "06",
        ALAND: 1378586566,
        AWATER: 84150136
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.481842, 33.789007999127506],
            [-94.477266741529803, 33.940910927060429],
            [-94.408672, 33.945280999125579],
            [-94.388548, 33.895175999126195],
            [-94.237445, 33.802073999127352],
            [-94.040849, 33.782045999127604],
            [-93.958828, 33.750993999127992],
            [-93.957315, 33.750939999127993],
            [-93.965637, 33.669735999129017],
            [-93.825884, 33.609852999129771],
            [-93.967409, 33.581051999130146],
            [-93.986265, 33.533672999130751],
            [-94.043833747801088, 33.55171373471611],
            [-94.07267, 33.572233999130262],
            [-94.143024, 33.577724999130183],
            [-94.183395, 33.592211999130001],
            [-94.213605, 33.570621999130275],
            [-94.238868, 33.5767219991302],
            [-94.303742, 33.564485999130348],
            [-94.338422, 33.567081999130316],
            [-94.354165, 33.556451999130452],
            [-94.388052, 33.565510999130332],
            [-94.419057, 33.577216999130187],
            [-94.485875, 33.637866999129415],
            [-94.481842, 33.789007999127506]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "590",
        COUNTYNS: "01498421",
        AFFGEOID: "0500000US51590",
        GEOID: "51590",
        NAME: "Danville",
        LSAD: "25",
        ALAND: 110751018,
        AWATER: 2434869
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.500848, 36.618261999096411],
            [-79.422191, 36.600517999096581],
            [-79.398094, 36.644036999096166],
            [-79.365013, 36.607796999096507],
            [-79.302564, 36.577430999096791],
            [-79.343115919369495, 36.541143099065643],
            [-79.470056673924304, 36.540836148883749],
            [-79.456471, 36.576970999096815],
            [-79.518995, 36.603358999096557],
            [-79.500848, 36.618261999096411]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "027",
        COUNTYNS: "01804637",
        AFFGEOID: "0500000US06027",
        GEOID: "06027",
        NAME: "Inyo",
        LSAD: "06",
        ALAND: 26368508430,
        AWATER: 119088140
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-118.775014, 37.463051999088819],
            [-117.833504044143993, 37.464938765333301],
            [-117.68061, 37.353398999089755],
            [-117.244917, 37.030243999092605],
            [-117.166000564031009, 36.971207537683448],
            [-117.000895, 36.847693999094275],
            [-116.488233, 36.45909699909793],
            [-116.375875, 36.372561999098771],
            [-116.093601, 36.155804999100901],
            [-115.892975, 35.999966999102462],
            [-115.846113922762001, 35.96355267221373],
            [-115.648032019624992, 35.809629211707311],
            [-115.735891, 35.793619999104585],
            [-116.277263, 35.793393999104573],
            [-117.251015, 35.795003999104573],
            [-117.632996, 35.797250999104534],
            [-117.924459, 35.798148999104527],
            [-118.008043, 35.789160999104638],
            [-118.005489, 35.862698999103863],
            [-117.980761, 35.867514999103818],
            [-118.003577, 35.98371799910263],
            [-118.033611, 36.008943999102385],
            [-118.127147, 36.279655999099674],
            [-118.100317, 36.346136999099024],
            [-118.21482, 36.435035999098154],
            [-118.239021, 36.523634999097311],
            [-118.290523, 36.558534999096977],
            [-118.274624, 36.597333999096612],
            [-118.366328, 36.691634999095719],
            [-118.360586, 36.744772999095225],
            [-118.389758, 36.834465999094391],
            [-118.360831, 36.887733999093903],
            [-118.437137, 37.059817999092346],
            [-118.592661, 37.138146999091653],
            [-118.654593, 37.141825999091616],
            [-118.716016, 37.328207999089983],
            [-118.786736, 37.343379999089841],
            [-118.775014, 37.463051999088819]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "097",
        COUNTYNS: "01008565",
        AFFGEOID: "0500000US37097",
        GEOID: "37097",
        NAME: "Iredell",
        LSAD: "06",
        ALAND: 1487115275,
        AWATER: 61893483
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.109507, 35.776593999104747],
            [-81.041339, 35.857854999103914],
            [-81.005541, 35.958286999102889],
            [-81.028833, 36.045651999102006],
            [-80.975675, 36.058809999101875],
            [-80.881591, 36.055717999101908],
            [-80.693197, 36.051259999101937],
            [-80.707826, 35.852928999103966],
            [-80.771582, 35.672162999105844],
            [-80.743617, 35.559548999107029],
            [-80.737379, 35.505803999107599],
            [-80.784167, 35.506016999107601],
            [-80.907512, 35.514838999107511],
            [-80.948122, 35.491166999107755],
            [-80.939685, 35.529528999107356],
            [-80.960025, 35.547019999107171],
            [-80.933483, 35.591328999106686],
            [-80.92754, 35.622977999106361],
            [-80.943817, 35.637517999106208],
            [-80.951241, 35.641412999106159],
            [-80.966305, 35.67031299910586],
            [-80.982037, 35.682491999105736],
            [-80.990928, 35.694139999105609],
            [-80.994445, 35.702109999105524],
            [-81.005321, 35.71020899910544],
            [-81.061328, 35.710640999105436],
            [-81.109507, 35.776593999104747]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "183",
        COUNTYNS: "01008592",
        AFFGEOID: "0500000US37183",
        GEOID: "37183",
        NAME: "Wake",
        LSAD: "06",
        ALAND: 2162148714,
        AWATER: 58100780
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.936271, 35.771897999104809],
            [-78.909425, 35.842936999104069],
            [-78.906097, 35.86791799910381],
            [-78.829965, 35.86697799910381],
            [-78.806756, 35.922924999103238],
            [-78.75821, 35.921557999103257],
            [-78.699316, 36.011447999102359],
            [-78.751273, 36.070833999101737],
            [-78.683224, 36.074483999101709],
            [-78.546414, 36.021825999102241],
            [-78.307409, 35.896737999103522],
            [-78.255973, 35.818119999104326],
            [-78.468503, 35.70707199910548],
            [-78.642702, 35.570064999106918],
            [-78.708345, 35.519295999107456],
            [-78.914734, 35.583671999106784],
            [-78.995059, 35.610134999106492],
            [-78.936271, 35.771897999104809]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "105",
        COUNTYNS: "01622995",
        AFFGEOID: "0500000US26105",
        GEOID: "26105",
        NAME: "Mason",
        LSAD: "06",
        ALAND: 1281901694,
        AWATER: 1935678152
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.514702, 44.058118999057207],
            [-86.458059171253993, 44.099292234654676],
            [-86.429871, 44.119781999057167],
            [-86.391399146908697, 44.173701997982221],
            [-86.387837360265195, 44.178693999057117],
            [-86.043507, 44.167087999057117],
            [-86.040106, 43.989670999057296],
            [-86.037884, 43.815610999057505],
            [-86.435485038258307, 43.819429197061687],
            [-86.431198, 43.840719999057463],
            [-86.447915, 43.918088999057368],
            [-86.463136, 43.970975999057323],
            [-86.463203115248476, 43.971064558777016],
            [-86.501738, 44.021911999057266],
            [-86.514702, 44.058118999057207]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "193",
        COUNTYNS: "01008595",
        AFFGEOID: "0500000US37193",
        GEOID: "37193",
        NAME: "Wilkes",
        LSAD: "06",
        ALAND: 1954249751,
        AWATER: 6862434
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.455792, 36.200440999100458],
            [-81.477516, 36.240249999100065],
            [-81.366725, 36.284446999099629],
            [-81.359255, 36.366432999098834],
            [-81.253649, 36.366600999098814],
            [-81.162956, 36.432514999098174],
            [-81.095112, 36.440772999098108],
            [-81.026688, 36.373194999098757],
            [-80.967153, 36.402019999098485],
            [-80.868746, 36.326446999099218],
            [-80.873205, 36.236304999100106],
            [-80.881591, 36.055717999101908],
            [-80.975675, 36.058809999101875],
            [-81.028833, 36.045651999102006],
            [-81.263596, 36.025817999102202],
            [-81.328324, 35.9965829991025],
            [-81.397817, 36.066060999101794],
            [-81.545488, 36.117474999101269],
            [-81.455792, 36.200440999100458]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "123",
        COUNTYNS: "01008572",
        AFFGEOID: "0500000US37123",
        GEOID: "37123",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 1273046508,
        AWATER: 25919921
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.182559, 35.504150999107623],
            [-80.066842, 35.505660999107597],
            [-79.767957, 35.51148399910754],
            [-79.70268, 35.255906999110302],
            [-79.614304, 35.163678999111319],
            [-79.851268, 35.183854999111105],
            [-80.075365, 35.143080999111532],
            [-80.098185, 35.255172999110314],
            [-80.051225, 35.371536999109047],
            [-80.108495, 35.4594509991081],
            [-80.182559, 35.504150999107623]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "093",
        COUNTYNS: "00277311",
        AFFGEOID: "0500000US06093",
        GEOID: "06093",
        NAME: "Siskiyou",
        LSAD: "06",
        ALAND: 16261933292,
        AWATER: 179149824
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.660205, 41.714054999062789],
            [-123.703768, 41.829116999062357],
            [-123.642812, 41.889352999062154],
            [-123.565442, 41.903140999062096],
            [-123.519112836804013, 41.999172517094678],
            [-123.43477, 42.001640999061763],
            [-123.347562, 41.999107999061778],
            [-123.231001260578012, 42.004970061317756],
            [-123.145959, 42.00924699906173],
            [-123.045254, 42.003048999061754],
            [-122.80008, 42.004070999061746],
            [-122.501135, 42.008459999061735],
            [-122.289749096156996, 42.007764729558332],
            [-122.289527, 42.007763999061737],
            [-122.101922, 42.005765999061751],
            [-121.846712, 42.003069999061758],
            [-121.675348, 42.000350999061766],
            [-121.447539998048001, 41.99719003233438],
            [-121.457213, 41.949944999061948],
            [-121.448981, 41.776391999062554],
            [-121.446495, 41.183483999064904],
            [-122.498376, 41.182674999064915],
            [-122.522636, 41.213524999064788],
            [-122.50389, 41.342984999064235],
            [-122.585241, 41.359007999064168],
            [-122.653686, 41.289236999064471],
            [-122.811342, 41.20245099906483],
            [-122.885921, 41.205141999064807],
            [-122.973382, 41.112073999065224],
            [-122.897411, 41.028474999065608],
            [-122.917529, 40.993989999065761],
            [-123.036808, 41.004057999065708],
            [-123.055183, 41.046705999065516],
            [-123.141338, 41.079177999065372],
            [-123.23948, 41.075762999065383],
            [-123.273402, 41.12289099906517],
            [-123.408291, 41.179943999064932],
            [-123.487831, 41.3769339990641],
            [-123.661363, 41.382089999064085],
            [-123.61179, 41.462126999063756],
            [-123.648046, 41.535023999063469],
            [-123.719174, 41.595612999063228],
            [-123.660205, 41.714054999062789]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "220",
        COUNTYNS: "01419981",
        AFFGEOID: "0500000US02220",
        GEOID: "02220",
        NAME: "Sitka",
        LSAD: "03",
        ALAND: 7433222810,
        AWATER: 5037940435
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-136.371986492161, 57.832232009855325],
            [-136.024328, 57.838794999150529],
            [-135.901964, 58.001342999152861],
            [-135.715149, 57.929412999151815],
            [-135.528534, 57.880469999151131],
            [-135.238129, 57.761710999149422],
            [-135.061737, 57.760932999149411],
            [-134.94942955753001, 57.781259288599301],
            [-134.939924, 57.763611999149447],
            [-134.824891, 57.500066999145737],
            [-134.825579, 57.372142999143968],
            [-134.854948, 57.26476599914249],
            [-134.738223, 56.97574099913858],
            [-134.695735, 56.90079099913757],
            [-134.663434, 56.804686999136301],
            [-134.62967, 56.70959599913504],
            [-134.615955, 56.637288999134086],
            [-134.626943, 56.553867999133004],
            [-134.669778, 56.524128999132614],
            [-134.64177, 56.445478999131609],
            [-134.634828, 56.345296999130326],
            [-134.634668, 56.265831999129318],
            [-134.653827, 56.198384999128464],
            [-134.674028, 56.166924999128071],
            [-134.763535, 56.21036299912862],
            [-134.810171, 56.244986999129061],
            [-134.839411, 56.309402999129865],
            [-134.915911, 56.360554999130521],
            [-134.977082, 56.437293999131505],
            [-135.058238, 56.529452999132694],
            [-135.123389, 56.60282299913365],
            [-135.175826, 56.677875999134621],
            [-135.21583, 56.66533999913446],
            [-135.305077, 56.726381999135263],
            [-135.362241, 56.758741999135687],
            [-135.467177, 56.771409999135855],
            [-135.550718, 56.841227999136777],
            [-135.506869, 56.865977999137101],
            [-135.476817, 56.891231999137439],
            [-135.442339, 56.942354999138132],
            [-135.353447, 57.020904999139184],
            [-135.457907, 57.070164999139848],
            [-135.571504, 57.105696999140321],
            [-135.604555, 57.045833999139511],
            [-135.63688, 57.009873999139039],
            [-135.825598, 56.989031999138746],
            [-135.856021, 56.995635999138841],
            [-135.844612, 57.083567999140023],
            [-135.755007, 57.123971999140572],
            [-135.753581, 57.167167999141157],
            [-135.832253, 57.170646999141198],
            [-135.870519, 57.221638999141902],
            [-135.837719, 57.282067999142726],
            [-135.85816, 57.321357999143267],
            [-135.892131, 57.408047999144465],
            [-135.943766, 57.458779999145165],
            [-136.047547, 57.513761999145927],
            [-136.088071, 57.555290999146514],
            [-136.163059, 57.558860999146574],
            [-136.238166, 57.625990999147511],
            [-136.250818, 57.684830999148339],
            [-136.304684, 57.771050999149573],
            [-136.371986492161, 57.832232009855325]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "15",
        COUNTYFP: "007",
        COUNTYNS: "00365282",
        AFFGEOID: "0500000US15007",
        GEOID: "15007",
        NAME: "Kauai",
        LSAD: "06",
        ALAND: 1605492340,
        AWATER: 1674244223
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-159.783746, 22.064896999342448],
              [-159.745247, 22.09750799934168],
              [-159.730544, 22.139952999340675],
              [-159.705531, 22.159320999340217],
              [-159.61165, 22.201387999339225],
              [-159.581058, 22.223487999338705],
              [-159.543924, 22.221694999338752],
              [-159.510756, 22.203547999339175],
              [-159.487939, 22.22951199933857],
              [-159.431707, 22.220014999338794],
              [-159.402466, 22.232602999338489],
              [-159.361507, 22.21409199933893],
              [-159.312293, 22.183081999339652],
              [-159.293013, 22.122959999341081],
              [-159.31828, 22.061416999342534],
              [-159.334489, 22.041697999343],
              [-159.332564, 21.999351999343993],
              [-159.33768, 21.95117299934514],
              [-159.385271, 21.912438999346062],
              [-159.444868, 21.868626999347097],
              [-159.526918, 21.883885999346742],
              [-159.574521, 21.892805999346528],
              [-159.603279, 21.892247999346541],
              [-159.649766, 21.933847999345549],
              [-159.707795, 21.961228999344907],
              [-159.754795, 21.977771999344512],
              [-159.786702, 22.018800999343537],
              [-159.783746, 22.064896999342448]
            ]
          ],
          [
            [
              [-160.24961, 21.815144999348373],
              [-160.228965, 21.889116999346616],
              [-160.193959, 21.922385999345828],
              [-160.13705, 21.948631999345203],
              [-160.122262, 21.962880999344861],
              [-160.112746, 21.995244999344091],
              [-160.072123, 22.003333999343905],
              [-160.058543, 21.996379999344072],
              [-160.051128, 21.981059999344435],
              [-160.070292, 21.963950999344835],
              [-160.085787, 21.927294999345701],
              [-160.079065, 21.896079999346444],
              [-160.124283, 21.876788999346907],
              [-160.156092, 21.867929999347119],
              [-160.174796, 21.846922999347623],
              [-160.189782, 21.822449999348198],
              [-160.205851, 21.779517999349221],
              [-160.230373, 21.789674999348978],
              [-160.24961, 21.815144999348373]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "033",
        COUNTYNS: "01209175",
        AFFGEOID: "0500000US42033",
        GEOID: "42033",
        NAME: "Clearfield",
        LSAD: "06",
        ALAND: 2964714577,
        AWATER: 23932649
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.805167, 40.905979999066155],
            [-78.804532, 41.132185999065136],
            [-78.766377, 41.202758999064834],
            [-78.710248, 41.201858999064832],
            [-78.646666, 41.253777999064617],
            [-78.236817, 41.230474999064718],
            [-78.093381, 41.216930999064765],
            [-78.061007, 41.17453899906495],
            [-78.038203, 41.153632999065032],
            [-78.112239, 41.114216999065214],
            [-78.056276, 41.004169999065695],
            [-78.07774, 40.958823999065899],
            [-78.168128, 40.960864999065905],
            [-78.24922, 40.8553829990664],
            [-78.288441, 40.849746999066426],
            [-78.376518, 40.754416999066876],
            [-78.359912, 40.732591999066983],
            [-78.35043, 40.724826999067012],
            [-78.801697, 40.724538999067015],
            [-78.806375, 40.743159999066926],
            [-78.805167, 40.905979999066155]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "141",
        COUNTYNS: "01639783",
        AFFGEOID: "0500000US47141",
        GEOID: "47141",
        NAME: "Putnam",
        LSAD: "06",
        ALAND: 1038860590,
        AWATER: 3777709
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.809168, 36.206597999100396],
            [-85.780148, 36.238010999100084],
            [-85.557527, 36.223176999100232],
            [-85.495124, 36.302277999099452],
            [-85.43585, 36.247147999099987],
            [-85.204156, 36.152488999100932],
            [-85.119583, 36.144718999101016],
            [-85.100213, 36.138560999101067],
            [-85.15275, 36.099543999101471],
            [-85.246362, 36.100697999101449],
            [-85.264206, 35.979153999102685],
            [-85.309363, 36.03159199910214],
            [-85.507677, 36.082011999101631],
            [-85.644604, 36.015052999102316],
            [-85.767136, 36.070086999101761],
            [-85.807415, 36.131581999101144],
            [-85.809168, 36.206597999100396]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "019",
        COUNTYNS: "00295726",
        AFFGEOID: "0500000US12019",
        GEOID: "12019",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 1566439483,
        AWATER: 100339826
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.049411, 30.186932999179394],
            [-81.738924, 30.189320999179351],
            [-81.711348, 30.191192999179322],
            [-81.679626, 30.190494999179336],
            [-81.680215, 30.121239999180467],
            [-81.689413, 30.031233999181936],
            [-81.60099, 29.956016999183177],
            [-81.581207, 29.840175999185082],
            [-81.81243, 29.836489999185144],
            [-81.90926, 29.793559999185856],
            [-81.939427, 29.747496999186637],
            [-82.049244, 29.71866999918711],
            [-82.046112, 29.747128999186639],
            [-82.049425, 30.1431359991801],
            [-82.049411, 30.186932999179394]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "029",
        COUNTYNS: "00295725",
        AFFGEOID: "0500000US12029",
        GEOID: "12029",
        NAME: "Dixie",
        LSAD: "06",
        ALAND: 1826212966,
        AWATER: 410630395
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.414126513158493, 29.666065223670692],
            [-83.366398, 29.665700999187994],
            [-83.318859, 29.822622999185388],
            [-82.920608, 29.824142999185355],
            [-82.949707, 29.75440899918652],
            [-82.954879, 29.58560499918935],
            [-82.937329, 29.591195999189249],
            [-82.984371, 29.471003999191286],
            [-83.060528, 29.349456999193343],
            [-83.165921811586401, 29.289091841840985],
            [-83.169576, 29.29035499919436],
            [-83.175518, 29.344689999193431],
            [-83.202446, 29.394421999192591],
            [-83.240509, 29.433177999191919],
            [-83.294747, 29.437922999191844],
            [-83.307828, 29.468860999191318],
            [-83.401552, 29.523290999190394],
            [-83.405068, 29.595569999189181],
            [-83.414126513158493, 29.666065223670692]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "155",
        COUNTYNS: "00352302",
        AFFGEOID: "0500000US13155",
        GEOID: "13155",
        NAME: "Irwin",
        LSAD: "06",
        ALAND: 917793238,
        AWATER: 21758147
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.500702, 31.593989999157539],
            [-83.452972, 31.708199999155848],
            [-83.453634, 31.75786099915512],
            [-83.325296, 31.756583999155144],
            [-83.325617, 31.680602999156253],
            [-83.266363, 31.654202999156642],
            [-83.176376, 31.67838199915629],
            [-82.99836, 31.673163999156362],
            [-83.058807, 31.611211999157288],
            [-83.118578, 31.474162999159308],
            [-83.145587, 31.472275999159347],
            [-83.338728, 31.475990999159283],
            [-83.42084, 31.553564999158134],
            [-83.500951, 31.570116999157889],
            [-83.500702, 31.593989999157539]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "015",
        COUNTYNS: "00695732",
        AFFGEOID: "0500000US28015",
        GEOID: "28015",
        NAME: "Carroll",
        LSAD: "06",
        ALAND: 1627140833,
        AWATER: 16426880
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.174756, 33.417002999132251],
            [-90.101401, 33.458939999131708],
            [-90.102971, 33.660729999129131],
            [-90.127775, 33.676955999128921],
            [-89.78689, 33.676726999128931],
            [-89.785227, 33.416274999132256],
            [-89.646314, 33.402543999132433],
            [-89.64528, 33.285514999133952],
            [-89.701514, 33.28535899913394],
            [-89.749441, 33.214477999134878],
            [-90.174614, 33.333377999133319],
            [-90.174756, 33.417002999132251]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "051",
        COUNTYNS: "01639744",
        AFFGEOID: "0500000US47051",
        GEOID: "47051",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1436108593,
        AWATER: 55079682
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.318168, 35.126985999111717],
            [-86.301597, 35.23524099911053],
            [-86.245289, 35.323736999109563],
            [-86.261489, 35.333578999109463],
            [-86.158018, 35.36260699910914],
            [-86.02704, 35.34383699910935],
            [-85.913593, 35.289188999109939],
            [-85.873025, 35.223361999110665],
            [-85.863317, 35.208623999110827],
            [-85.863946111806897, 34.987030857861392],
            [-86.311274, 34.991097999113236],
            [-86.3187607654651, 34.991078579556046],
            [-86.318168, 35.126985999111717]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "081",
        COUNTYNS: "01035303",
        AFFGEOID: "0500000US38081",
        GEOID: "38081",
        NAME: "Sargent",
        LSAD: "06",
        ALAND: 2223531669,
        AWATER: 21728402
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.006715, 46.282625999057778],
            [-97.280568, 46.282320999057788],
            [-97.25934, 46.272785999057767],
            [-97.259632, 45.997184999057389],
            [-97.228291, 45.935656594703417],
            [-97.542598, 45.935257999057328],
            [-97.77704, 45.935392999057321],
            [-97.978777809623381, 45.935933991156027],
            [-98.008101798277778, 45.936012628106624],
            [-98.006715, 46.282625999057778]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "115",
        COUNTYNS: "01639770",
        AFFGEOID: "0500000US47115",
        GEOID: "47115",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 1290244637,
        AWATER: 36484742
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.873025, 35.223361999110665],
            [-85.740113, 35.215294999110746],
            [-85.709418, 35.289250999109939],
            [-85.558551, 35.319291999109609],
            [-85.403187, 35.223418999110656],
            [-85.387089, 35.147168999111493],
            [-85.365031, 35.07255399911233],
            [-85.474338843134305, 34.983673325904419],
            [-85.605165, 34.984677999113309],
            [-85.863946111806897, 34.987030857861392],
            [-85.863317, 35.208623999110827],
            [-85.873025, 35.223361999110665]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "005",
        COUNTYNS: "00484972",
        AFFGEOID: "0500000US20005",
        GEOID: "20005",
        NAME: "Atchison",
        LSAD: "06",
        ALAND: 1116736559,
        AWATER: 6851102
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.564126, 39.652871999072858],
            [-95.33974, 39.652979999072862],
            [-95.115189, 39.652548999072863],
            [-95.044050752570399, 39.613668295893994],
            [-95.047165, 39.595116999073213],
            [-95.076688, 39.576763999073329],
            [-95.113557, 39.553940999073461],
            [-95.091419402824002, 39.5332578842836],
            [-95.049845, 39.49441499907384],
            [-94.982144, 39.440551999074167],
            [-94.965747418633498, 39.421681743730694],
            [-94.997852, 39.418857999074312],
            [-95.180891, 39.419217999074299],
            [-95.570351, 39.419049999074296],
            [-95.564126, 39.652871999072858]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "017",
        COUNTYNS: "01531818",
        AFFGEOID: "0500000US53017",
        GEOID: "53017",
        NAME: "Douglas",
        LSAD: "06",
        ALAND: 4711869329,
        AWATER: 76507816
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.316594, 47.471272999060403],
            [-120.199048, 47.682275999061041],
            [-120.209395, 47.748355999061253],
            [-119.992679, 47.783149999061365],
            [-119.870595, 47.960454999061952],
            [-119.89535, 48.050043999062268],
            [-119.774593, 48.104212999062462],
            [-119.669707, 48.075231999062353],
            [-119.693712, 48.032055999062209],
            [-119.652468, 48.000220999062094],
            [-119.517571, 48.062614999062312],
            [-119.436257, 48.076687999062358],
            [-119.343523, 48.049431999062264],
            [-119.298585, 48.115484999062517],
            [-119.25685, 48.102026999062453],
            [-119.150983, 48.146066999062612],
            [-119.05697, 48.136973999062583],
            [-119.00319, 48.043262999062257],
            [-118.950782, 48.018883999062162],
            [-118.982234, 47.96202499906196],
            [-119.103873, 47.96176299906196],
            [-119.125904, 47.917300999061808],
            [-119.212225, 47.88770799906171],
            [-119.21241, 47.80069499906142],
            [-119.298649, 47.728327999061186],
            [-119.320304, 47.626796999060865],
            [-119.405196, 47.612635999060828],
            [-119.532889, 47.527755999060567],
            [-119.533123, 47.441206999060313],
            [-119.87576, 47.437491999060313],
            [-119.918915, 47.364474999060107],
            [-120.004704, 47.319993999059982],
            [-120.007074, 47.220132999059715],
            [-120.075969, 47.225897999059733],
            [-120.09446, 47.262158999059821],
            [-120.086308, 47.338941999060033],
            [-120.260288, 47.383891999060161],
            [-120.28653, 47.393110999060177],
            [-120.293175, 47.40076699906021],
            [-120.296404, 47.413140999060239],
            [-120.308482, 47.432347999060291],
            [-120.311883, 47.445247999060335],
            [-120.317984, 47.456947999060368],
            [-120.316594, 47.471272999060403]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "071",
        COUNTYNS: "01581095",
        AFFGEOID: "0500000US55071",
        GEOID: "55071",
        NAME: "Manitowoc",
        LSAD: "06",
        ALAND: 1526268667,
        AWATER: 2343369333
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.04324, 44.24101799905705],
            [-87.888087, 44.240460999057049],
            [-87.887526, 44.327588999056992],
            [-87.766026, 44.327183999056984],
            [-87.543326785476097, 44.327512855186882],
            [-87.545382, 44.321384999056995],
            [-87.521755, 44.259956999057039],
            [-87.507419, 44.210802999057073],
            [-87.53994, 44.159689999057115],
            [-87.600882, 44.13169499905716],
            [-87.655183, 44.081893999057208],
            [-87.69892, 43.965935999057315],
            [-87.728579542308893, 43.892208886514197],
            [-88.041794, 43.891691999057407],
            [-88.04324, 44.24101799905705]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "101",
        COUNTYNS: "00348092",
        AFFGEOID: "0500000US13101",
        GEOID: "13101",
        NAME: "Echols",
        LSAD: "06",
        ALAND: 1074571647,
        AWATER: 15087403
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.133009, 30.789648999169785],
            [-83.103223, 30.829581999169161],
            [-83.088082, 30.848657999168857],
            [-83.019419, 30.84945299916885],
            [-82.971336, 30.869391999168535],
            [-82.846353, 30.834987999169073],
            [-82.766159, 30.732403999170685],
            [-82.581202, 30.696236999171248],
            [-82.584005186419489, 30.591636578260498],
            [-82.68952979847279, 30.597889289814596],
            [-82.877311, 30.609015999172634],
            [-83.131431, 30.623574999172391],
            [-83.13661885820359, 30.623885944065499],
            [-83.133009, 30.789648999169785]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "50",
        COUNTYFP: "005",
        COUNTYNS: "01461759",
        AFFGEOID: "0500000US50005",
        GEOID: "50005",
        NAME: "Caledonia",
        LSAD: "06",
        ALAND: 1680951693,
        AWATER: 22386386
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.374786, 44.58412499905684],
            [-72.262965, 44.54233599905686],
            [-72.114503, 44.74962399905678],
            [-72.012504, 44.698820999056792],
            [-71.939936, 44.769031999056772],
            [-71.837294, 44.716636999056789],
            [-71.910282, 44.647037999056806],
            [-71.842732, 44.611164999056825],
            [-71.908657, 44.547079999056862],
            [-71.856637, 44.496177999056876],
            [-71.933288, 44.441173999056907],
            [-71.837790626388795, 44.347764541533977],
            [-71.875863, 44.337369999056975],
            [-71.945163, 44.33774399905699],
            [-72.002314, 44.324870999056984],
            [-72.046302, 44.291982999057012],
            [-72.05399, 44.246925999057048],
            [-72.061338, 44.184950999057101],
            [-72.053826883614704, 44.159817092860521],
            [-72.17026, 44.192052999057097],
            [-72.30498, 44.183148999057096],
            [-72.368353, 44.204056999057087],
            [-72.317621, 44.298383999057009],
            [-72.26985, 44.340226999056981],
            [-72.217104, 44.41108699905692],
            [-72.220442, 44.421196999056932],
            [-72.223689, 44.42457299905692],
            [-72.321277, 44.462571999056912],
            [-72.434315, 44.506097999056877],
            [-72.374786, 44.58412499905684]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "50",
        COUNTYFP: "019",
        COUNTYNS: "01461766",
        AFFGEOID: "0500000US50019",
        GEOID: "50019",
        NAME: "Orleans",
        LSAD: "06",
        ALAND: 1796376281,
        AWATER: 71442197
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.532638, 44.833345999056775],
            [-72.554271616223502, 45.009467719710273],
            [-72.532503, 45.007859999056762],
            [-72.348583, 45.005624999056757],
            [-72.023292, 45.006791999056766],
            [-71.915009, 45.007790999056759],
            [-71.897711511858802, 45.008072272893457],
            [-71.92851, 44.907297999056759],
            [-71.874433, 44.876381999056768],
            [-71.974318, 44.786518999056774],
            [-71.939936, 44.769031999056772],
            [-72.012504, 44.698820999056792],
            [-72.114503, 44.74962399905678],
            [-72.262965, 44.54233599905686],
            [-72.374786, 44.58412499905684],
            [-72.482302, 44.633927999056816],
            [-72.417315, 44.722892999056796],
            [-72.578161, 44.781577999056779],
            [-72.532638, 44.833345999056775]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "069",
        COUNTYNS: "00695758",
        AFFGEOID: "0500000US28069",
        GEOID: "28069",
        NAME: "Kemper",
        LSAD: "06",
        ALAND: 1984403113,
        AWATER: 2182350
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.914413, 32.926599999138709],
            [-88.812782, 32.925972999138708],
            [-88.347490247834898, 32.92903478601918],
            [-88.373338, 32.711824999141605],
            [-88.389248564124301, 32.578122395102056],
            [-88.914516, 32.576954999143467],
            [-88.914413, 32.926599999138709]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "037",
        COUNTYNS: "01550025",
        AFFGEOID: "0500000US54037",
        GEOID: "54037",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 543006589,
        AWATER: 5216623
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.035454, 39.278223999075209],
            [-77.988179, 39.320083999074953],
            [-77.966953, 39.367163999074627],
            [-77.930868, 39.381169999074551],
            [-77.84714, 39.446556999074133],
            [-77.825572, 39.49328899907384],
            [-77.810943896262188, 39.500739165640297],
            [-77.798201, 39.475718999073948],
            [-77.740012, 39.401693999074425],
            [-77.74593, 39.353220999074722],
            [-77.719519, 39.321313999074931],
            [-77.778068, 39.229304999075516],
            [-77.809125, 39.168566999075921],
            [-77.828157, 39.132328999076158],
            [-77.82830183326729, 39.132422453908674],
            [-78.032841, 39.264402999075308],
            [-78.033183460934197, 39.26462369755],
            [-78.033185235366602, 39.264624841080504],
            [-78.035454, 39.278223999075209]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "107",
        COUNTYNS: "00835875",
        AFFGEOID: "0500000US31107",
        GEOID: "31107",
        NAME: "Knox",
        LSAD: "06",
        ALAND: 2870799577,
        AWATER: 81066532
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.308186816451297, 42.886489206484804],
            [-98.280007, 42.874995999059237],
            [-98.231922, 42.861139999059269],
            [-98.152586654838188, 42.841153380123011],
            [-98.14806, 42.840012999059333],
            [-98.1047, 42.80847499905942],
            [-98.035034, 42.764204999059501],
            [-97.950147, 42.769618999059496],
            [-97.905001, 42.798871999059422],
            [-97.876887, 42.852662999059298],
            [-97.857957, 42.865092999059272],
            [-97.801344, 42.858002999059281],
            [-97.70103, 42.843796999059315],
            [-97.635442090441501, 42.851809074244791],
            [-97.59926, 42.85622899905929],
            [-97.515948, 42.853751999059291],
            [-97.484916876342012, 42.850003213927209],
            [-97.485298, 42.438577999060392],
            [-97.834431, 42.437683999060397],
            [-98.300235, 42.436912999060397],
            [-98.30544, 42.451421999060365],
            [-98.305149, 42.761206999059517],
            [-98.310239, 42.784617999059456],
            [-98.308186816451297, 42.886489206484804]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "027",
        COUNTYNS: "00858646",
        AFFGEOID: "0500000US32027",
        GEOID: "32027",
        NAME: "Pershing",
        LSAD: "06",
        ALAND: 15634691937,
        AWATER: 79934815
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.313036, 40.523103999068013],
            [-119.309598, 40.960377999065905],
            [-118.786705, 40.960849999065907],
            [-118.786738, 40.855928999066386],
            [-118.43819, 40.855695999066391],
            [-117.64422, 40.85782399906639],
            [-117.644399, 40.684342999067205],
            [-117.301168, 40.682347999067218],
            [-117.300597, 40.526135999068003],
            [-117.541749, 40.001033999070806],
            [-118.500979, 39.998675999070826],
            [-119.225176, 39.99938499907082],
            [-119.339296, 39.998608999070825],
            [-119.313036, 40.523103999068013]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "820",
        COUNTYNS: "01498443",
        AFFGEOID: "0500000US51820",
        GEOID: "51820",
        NAME: "Waynesboro",
        LSAD: "25",
        ALAND: 38724176,
        AWATER: 411928
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.928697, 38.091483999083692],
            [-78.864449, 38.095737999083653],
            [-78.870563, 38.040459999084092],
            [-78.921941, 38.031402999084165],
            [-78.946801, 38.051984999083999],
            [-78.928697, 38.091483999083692]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "069",
        COUNTYNS: "00347456",
        AFFGEOID: "0500000US13069",
        GEOID: "13069",
        NAME: "Coffee",
        LSAD: "06",
        ALAND: 1490399436,
        AWATER: 70456631
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.145587, 31.472275999159347],
            [-83.118578, 31.474162999159308],
            [-83.058807, 31.611211999157288],
            [-82.99836, 31.673163999156362],
            [-82.995698, 31.780982999154787],
            [-82.868118, 31.782346999154761],
            [-82.836366, 31.81615399915426],
            [-82.836633, 31.67139799915639],
            [-82.627342, 31.672671999156378],
            [-82.628273, 31.558328999158064],
            [-82.597509, 31.469292999159389],
            [-82.628181, 31.469350999159388],
            [-82.628975, 31.363929999160984],
            [-82.817544, 31.364485999160973],
            [-82.844093, 31.403327999160375],
            [-83.140483, 31.420394999160131],
            [-83.145587, 31.472275999159347]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "029",
        COUNTYNS: "01581074",
        AFFGEOID: "0500000US55029",
        GEOID: "55029",
        NAME: "Door",
        LSAD: "06",
        ALAND: 1248277387,
        AWATER: 4890604624
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-86.934276, 45.421148999056889],
              [-86.835749, 45.450187999056915],
              [-86.805868, 45.412902999056882],
              [-86.867743, 45.353064999056848],
              [-86.899891, 45.295184999056836],
              [-86.956198, 45.35200599905685],
              [-86.934276, 45.421148999056889]
            ]
          ],
          [
            [
              [-87.71978, 44.693245999056792],
              [-87.720889, 44.724547999056803],
              [-87.6463, 44.798738999056773],
              [-87.581306, 44.851790999056774],
              [-87.530999, 44.85743699905678],
              [-87.515142, 44.86959599905677],
              [-87.446477, 44.886108999056766],
              [-87.393405, 44.934392999056769],
              [-87.336457, 45.013529999056765],
              [-87.264877, 45.081360999056777],
              [-87.238224, 45.167258999056791],
              [-87.175068, 45.173049999056786],
              [-87.121609, 45.209782999056799],
              [-87.108743, 45.257002999056823],
              [-87.057627, 45.292837999056829],
              [-87.017036, 45.299253999056837],
              [-86.97778, 45.290683999056824],
              [-86.978759, 45.227332999056806],
              [-87.04417, 45.186945999056803],
              [-87.045748, 45.134986999056778],
              [-87.063157, 45.079315999056767],
              [-87.139384, 45.012564999056764],
              [-87.188375, 44.948076999056759],
              [-87.206285, 44.885927999056769],
              [-87.27603, 44.833179999056775],
              [-87.318982, 44.771334999056791],
              [-87.375491550219394, 44.67551210886181],
              [-87.737570860866285, 44.677011815212701],
              [-87.71978, 44.693245999056792]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "019",
        COUNTYNS: "00858645",
        AFFGEOID: "0500000US32019",
        GEOID: "32019",
        NAME: "Lyon",
        LSAD: "06",
        ALAND: 5183173279,
        AWATER: 59602530
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.712645, 39.250870999075374],
            [-119.64735, 39.266567999075278],
            [-119.478806, 39.337415999074828],
            [-119.283637, 39.62156099907304],
            [-119.25876, 39.64921299907288],
            [-119.189342, 39.650869999072874],
            [-119.190162, 39.632166999072979],
            [-119.059922, 39.740384999072333],
            [-119.077988, 39.72244099907244],
            [-119.117635, 39.518741999073676],
            [-119.118193, 39.388515999074492],
            [-118.988528, 39.285794999075158],
            [-118.890479, 39.227752999075541],
            [-118.754014, 39.112022999076302],
            [-118.753835, 39.074629999076549],
            [-118.902684, 39.074073999076553],
            [-118.93035, 39.072767999076561],
            [-119.014837, 38.943322999077445],
            [-119.014528, 38.851299999078073],
            [-118.902124, 38.852157999078067],
            [-118.906861, 38.414676999081216],
            [-119.157226100872009, 38.41439197084253],
            [-119.279262, 38.49991399908059],
            [-119.328704521075991, 38.534351926877846],
            [-119.349884, 38.72919299907894],
            [-119.439534, 38.88200099907786],
            [-119.401648, 38.983322999077167],
            [-119.310416, 39.019377999076923],
            [-119.311714, 39.083614999076488],
            [-119.55102, 39.08579099907648],
            [-119.561748, 39.194709999075755],
            [-119.648977, 39.195286999075755],
            [-119.712645, 39.250870999075374]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "125",
        COUNTYNS: "00559584",
        AFFGEOID: "0500000US22125",
        GEOID: "22125",
        NAME: "West Feliciana",
        LSAD: "15",
        ALAND: 1044342243,
        AWATER: 58999603
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-91.644917, 30.928970999167614],
              [-91.636942, 30.999415999166526],
              [-91.224068, 30.999182999166521],
              [-91.176140123966888, 30.999224201485131],
              [-91.222387, 30.880761999168357],
              [-91.234828, 30.824699999169244],
              [-91.320706, 30.699416999171198],
              [-91.310061, 30.653960999171911],
              [-91.33087, 30.658676999171838],
              [-91.38062, 30.758803999170269],
              [-91.450382, 30.736167999170622],
              [-91.553019, 30.730797999170711],
              [-91.594595, 30.7501839991704],
              [-91.539473, 30.791223999169762],
              [-91.515586, 30.875180999168453],
              [-91.549308, 30.916227999167813],
              [-91.614608, 30.917599999167798],
              [-91.556407, 30.848642999168863],
              [-91.661372, 30.857098999168731],
              [-91.644917, 30.928970999167614]
            ]
          ],
          [
            [
              [-91.723804, 31.044253999165832],
              [-91.628257298208794, 31.005097001061635],
              [-91.636942, 30.999415999166526],
              [-91.687266, 31.018141999166229],
              [-91.750119, 31.018813999166227],
              [-91.723804, 31.044253999165832]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "057",
        COUNTYNS: "01717890",
        AFFGEOID: "0500000US54057",
        GEOID: "54057",
        NAME: "Mineral",
        LSAD: "06",
        ALAND: 849173054,
        AWATER: 3502975
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.166497, 39.400887999074413],
            [-79.091329, 39.472406999073968],
            [-79.067447408072397, 39.472808698202172],
            [-79.035623, 39.473343999073961],
            [-78.956751, 39.440263999074176],
            [-78.942618, 39.479613999073926],
            [-78.851016, 39.554043999073464],
            [-78.77114, 39.638386999072949],
            [-78.73905, 39.609696999073122],
            [-78.707098, 39.555856999073455],
            [-78.655036171546698, 39.544382240157219],
            [-78.767447, 39.447237999074126],
            [-78.850723, 39.331092999074869],
            [-78.979898, 39.237623999075467],
            [-79.134456, 39.312500999074977],
            [-79.262392117176091, 39.326243827542299],
            [-79.166497, 39.400887999074413]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "069",
        COUNTYNS: "01480124",
        AFFGEOID: "0500000US51069",
        GEOID: "51069",
        NAME: "Frederick",
        LSAD: "06",
        ALAND: 1069672126,
        AWATER: 6364651
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.508132, 39.088629999076453],
            [-78.413943, 39.158414999075987],
            [-78.428697, 39.187216999075794],
            [-78.40498, 39.238005999075469],
            [-78.401813, 39.276753999075218],
            [-78.34048, 39.35349199907472],
            [-78.337133, 39.409168999074367],
            [-78.347087, 39.466011999074013],
            [-78.229129534218501, 39.390663886278581],
            [-78.18737, 39.36398899907465],
            [-78.033185235366602, 39.264624841080504],
            [-78.033183460934197, 39.26462369755],
            [-78.101984, 39.106290999076336],
            [-78.151614, 39.036628999076804],
            [-78.163871, 39.017927999076932],
            [-78.31408, 39.007993999077001],
            [-78.336003, 39.101770999076379],
            [-78.391624, 39.101896999076367],
            [-78.454136, 39.027796999076863],
            [-78.53226618655701, 39.052764523121091],
            [-78.508132, 39.088629999076453]
          ],
          [
            [-78.197023, 39.143074999076092],
            [-78.183183, 39.13102699907617],
            [-78.136725, 39.184370999075831],
            [-78.187794, 39.204869999075683],
            [-78.192659, 39.167926999075931],
            [-78.197023, 39.143074999076092]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "061",
        COUNTYNS: "01550037",
        AFFGEOID: "0500000US54061",
        GEOID: "54061",
        NAME: "Monongalia",
        LSAD: "06",
        ALAND: 932572493,
        AWATER: 15051916
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.421388, 39.721188999072446],
            [-80.075947, 39.721349999072444],
            [-80.041702012810504, 39.721287031991238],
            [-79.916017356037798, 39.721055932591248],
            [-79.763774, 39.720775999072458],
            [-79.789277, 39.623780999073041],
            [-79.89974, 39.481944999073917],
            [-79.894688, 39.435587999074201],
            [-79.938878, 39.452464999074095],
            [-80.153532, 39.610425999073108],
            [-80.347466, 39.611664999073106],
            [-80.39581, 39.637346999072946],
            [-80.407408, 39.717751999072469],
            [-80.421388, 39.721188999072446]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "165",
        COUNTYNS: "01488970",
        AFFGEOID: "0500000US51165",
        GEOID: "51165",
        NAME: "Rockingham",
        LSAD: "06",
        ALAND: 2200698748,
        AWATER: 10892582
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.201459, 38.52782099908039],
            [-79.154357, 38.60651799907982],
            [-79.092955, 38.65951699907945],
            [-79.088055, 38.690114999079213],
            [-79.057253, 38.761412999078708],
            [-79.023053, 38.798612999078436],
            [-78.999014, 38.840073999078157],
            [-78.869276, 38.762990999078703],
            [-78.641769, 38.604718999079829],
            [-78.691312, 38.510007999080521],
            [-78.548833, 38.426697999081142],
            [-78.48574, 38.421578999081177],
            [-78.553143, 38.33482399908182],
            [-78.663145, 38.277932999082253],
            [-78.655273, 38.260426999082384],
            [-78.749396, 38.206647999082797],
            [-78.888258, 38.303690999082072],
            [-79.228423002707785, 38.479741556568044],
            [-79.201459, 38.52782099908039]
          ],
          [
            [-78.914587, 38.390066999081398],
            [-78.827031, 38.420551999081191],
            [-78.825432, 38.432135999081098],
            [-78.871361, 38.489517999080661],
            [-78.915443, 38.437454999081062],
            [-78.914587, 38.390066999081398]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "171",
        COUNTYNS: "01673918",
        AFFGEOID: "0500000US51171",
        GEOID: "51171",
        NAME: "Shenandoah",
        LSAD: "06",
        ALAND: 1315692012,
        AWATER: 9944281
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.821167, 38.830981999078212],
            [-78.772793, 38.893741999077783],
            [-78.681617, 38.925839999077567],
            [-78.620453, 38.982600999077171],
            [-78.561711, 39.009006999076995],
            [-78.53226618655701, 39.052764523121091],
            [-78.454136, 39.027796999076863],
            [-78.391624, 39.101896999076367],
            [-78.336003, 39.101770999076379],
            [-78.31408, 39.007993999077001],
            [-78.300414, 38.943591999077441],
            [-78.394704, 38.822789999078267],
            [-78.50926, 38.725625999078964],
            [-78.548828, 38.738654999078868],
            [-78.641769, 38.604718999079829],
            [-78.869276, 38.762990999078703],
            [-78.821167, 38.830981999078212]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "053",
        COUNTYNS: "00695750",
        AFFGEOID: "0500000US28053",
        GEOID: "28053",
        NAME: "Humphreys",
        LSAD: "06",
        ALAND: 1083947281,
        AWATER: 32942668
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.716277, 33.270031999134154],
            [-90.555211, 33.270532999134147],
            [-90.555549, 33.329180999133385],
            [-90.453233, 33.32948399913338],
            [-90.413236, 33.23531999913461],
            [-90.331589, 33.302824999133733],
            [-90.330312, 33.137817999135891],
            [-90.43152, 33.095708999136448],
            [-90.364031, 33.010595999137585],
            [-90.449323, 33.009009999137596],
            [-90.449893, 32.923289999138753],
            [-90.656632, 32.922509999138754],
            [-90.658049, 33.096513999136434],
            [-90.701845, 33.096293999136435],
            [-90.679607, 33.102482999136356],
            [-90.680319, 33.20197099913505],
            [-90.716277, 33.270031999134154]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "097",
        COUNTYNS: "00711757",
        AFFGEOID: "0500000US28097",
        GEOID: "28097",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 1054074717,
        AWATER: 2352280
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.78689, 33.676726999128931],
            [-89.506872, 33.677604999128917],
            [-89.506496, 33.459805999131696],
            [-89.381092, 33.460306999131689],
            [-89.45405, 33.433199999132029],
            [-89.453808, 33.285941999133946],
            [-89.64528, 33.285514999133952],
            [-89.646314, 33.402543999132433],
            [-89.785227, 33.416274999132256],
            [-89.78689, 33.676726999128931]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "143",
        COUNTYNS: "00695792",
        AFFGEOID: "0500000US28143",
        GEOID: "28143",
        NAME: "Tunica",
        LSAD: "06",
        ALAND: 1177602383,
        AWATER: 67538891
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.573288412618297, 34.633670505585322],
            [-90.550158, 34.663444999116969],
            [-90.546053, 34.702075999116538],
            [-90.505494, 34.764567999115805],
            [-90.473527, 34.788834999115537],
            [-90.463795, 34.834922999115001],
            [-90.407982753794286, 34.835265978395711],
            [-90.401633, 34.835304999114996],
            [-90.313476, 34.871697999114588],
            [-90.311424250345183, 34.872851143214575],
            [-90.202126, 34.860373999114728],
            [-90.200199, 34.724417999116277],
            [-90.231202, 34.702341999116527],
            [-90.239971, 34.62623699911741],
            [-90.198631, 34.554424999118254],
            [-90.198536, 34.511087999118757],
            [-90.304317, 34.509620999118766],
            [-90.301851, 34.424545999119772],
            [-90.399498, 34.423806999119776],
            [-90.569347, 34.524866999118601],
            [-90.549244, 34.56810099911808],
            [-90.587224, 34.615731999117529],
            [-90.573288412618297, 34.633670505585322]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "025",
        COUNTYNS: "00758467",
        AFFGEOID: "0500000US29025",
        GEOID: "29025",
        NAME: "Caldwell",
        LSAD: "06",
        ALAND: 1104350543,
        AWATER: 8402198
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.205961, 39.7458409990723],
            [-94.205844, 39.788953999072042],
            [-93.758992, 39.784673999072062],
            [-93.758357, 39.611406999073111],
            [-93.759183, 39.524557999073636],
            [-94.208423, 39.52719999907363],
            [-94.205961, 39.7458409990723]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "045",
        COUNTYNS: "00758477",
        AFFGEOID: "0500000US29045",
        GEOID: "29045",
        NAME: "Clark",
        LSAD: "06",
        ALAND: 1307092462,
        AWATER: 18528245
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.949747, 40.300831999069167],
            [-91.943117459685695, 40.606060585421602],
            [-91.939292, 40.606149999067597],
            [-91.729115, 40.613639999067551],
            [-91.716654987681892, 40.603740138363612],
            [-91.685381, 40.578891999067729],
            [-91.670993, 40.550936999067872],
            [-91.618999, 40.539083999067927],
            [-91.608347, 40.500039999068129],
            [-91.563844, 40.460987999068337],
            [-91.519134, 40.432821999068473],
            [-91.498093, 40.401925999068638],
            [-91.419422, 40.378263999068764],
            [-91.469656, 40.322408999069054],
            [-91.492891, 40.26992299906933],
            [-91.496957490339483, 40.248703875442253],
            [-91.950812, 40.257209999069403],
            [-91.949747, 40.300831999069167]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "081",
        COUNTYNS: "00758495",
        AFFGEOID: "0500000US29081",
        GEOID: "29081",
        NAME: "Harrison",
        LSAD: "06",
        ALAND: 1871276777,
        AWATER: 10103762
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.232240710208288, 40.572014611194369],
            [-94.091085, 40.572896999067765],
            [-94.015492, 40.574073706077357],
            [-93.84093, 40.57679099906774],
            [-93.774344204030285, 40.577530452458852],
            [-93.764823, 40.472514999068274],
            [-93.763324, 40.263987999069364],
            [-93.764021, 40.132908999070075],
            [-94.216696, 40.136625999070056],
            [-94.214278, 40.384253999068733],
            [-94.231565, 40.470356999068279],
            [-94.232240710208288, 40.572014611194369]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "085",
        COUNTYNS: "01689049",
        AFFGEOID: "0500000US54085",
        GEOID: "54085",
        NAME: "Ritchie",
        LSAD: "06",
        ALAND: 1170648844,
        AWATER: 4424610
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.259324, 39.267130999075285],
            [-81.239477, 39.268327999075261],
            [-81.221335, 39.307974999075014],
            [-81.007836, 39.350922999074747],
            [-80.904438, 39.378120999074561],
            [-80.888744, 39.294297999075098],
            [-80.91384, 39.272946999075238],
            [-80.81297, 39.109400999076314],
            [-81.007231, 39.008784999076987],
            [-81.033632, 39.009583999076987],
            [-81.136467, 39.045110999076755],
            [-81.162455, 39.03060799907685],
            [-81.250252, 39.035073999076808],
            [-81.298017, 39.185571999075805],
            [-81.259324, 39.267130999075285]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "063",
        COUNTYNS: "01494354",
        AFFGEOID: "0500000US51063",
        GEOID: "51063",
        NAME: "Floyd",
        LSAD: "06",
        ALAND: 986572574,
        AWATER: 2190750
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.56826, 36.960671999093243],
            [-80.543933, 36.984638999093029],
            [-80.520379, 37.011233999092774],
            [-80.347858, 37.021592999092682],
            [-80.178125, 37.113399999091875],
            [-80.128674, 37.122900999091783],
            [-80.134619, 36.964654999093199],
            [-80.235455, 36.872129999094049],
            [-80.386909, 36.797675999094729],
            [-80.458572, 36.738358999095283],
            [-80.46418, 36.708717999095562],
            [-80.633987, 36.9317799990935],
            [-80.56826, 36.960671999093243]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "091",
        COUNTYNS: "01265759",
        AFFGEOID: "0500000US46091",
        GEOID: "46091",
        NAME: "Marshall",
        LSAD: "06",
        ALAND: 2170630077,
        AWATER: 124680563
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.978777809623381, 45.935933991156027],
            [-97.77704, 45.935392999057321],
            [-97.542598, 45.935257999057328],
            [-97.228291, 45.935656594703417],
            [-97.227089, 45.558157999056974],
            [-97.36464, 45.558727999056977],
            [-97.373366, 45.587633999056997],
            [-97.979592, 45.588482999056986],
            [-97.978777809623381, 45.935933991156027]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "039",
        COUNTYNS: "00659465",
        AFFGEOID: "0500000US27039",
        GEOID: "27039",
        NAME: "Dodge",
        LSAD: "06",
        ALAND: 1137724830,
        AWATER: 800245
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.04591, 44.196715999057083],
            [-93.041159, 44.196725999057094],
            [-92.678717, 44.195515999057079],
            [-92.678313, 43.861772999057443],
            [-92.689391, 43.848569999057453],
            [-93.045966, 43.848466999057464],
            [-93.04591, 44.196715999057083]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "183",
        COUNTYNS: "00516938",
        AFFGEOID: "0500000US21183",
        GEOID: "21183",
        NAME: "Ohio",
        LSAD: "06",
        ALAND: 1521032968,
        AWATER: 23189052
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.099803, 37.416041999089217],
            [-87.135629, 37.485152999088633],
            [-87.03826, 37.560802999087983],
            [-86.94876, 37.630547999087412],
            [-86.817783, 37.673678999087059],
            [-86.823429, 37.737753999086522],
            [-86.638935, 37.661876999087141],
            [-86.640821, 37.619185999087506],
            [-86.59281, 37.564359999087962],
            [-86.66489, 37.556648999088026],
            [-86.612095, 37.395122999089395],
            [-86.759533, 37.304098999090186],
            [-86.815852, 37.328858999089974],
            [-86.899268, 37.212312999090997],
            [-86.98267, 37.269631999090507],
            [-86.985957, 37.316565999090081],
            [-87.07133, 37.370297999089622],
            [-87.136983, 37.335523999089915],
            [-87.099803, 37.416041999089217]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "125",
        COUNTYNS: "00067102",
        AFFGEOID: "0500000US05125",
        GEOID: "05125",
        NAME: "Saline",
        LSAD: "06",
        ALAND: 1873978476,
        AWATER: 17979687
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.073877, 34.858090999114751],
            [-92.739779, 34.8534589991148],
            [-92.651248, 34.851681999114824],
            [-92.654324, 34.764540999115823],
            [-92.549856, 34.76169899911585],
            [-92.551963, 34.689431999116671],
            [-92.443265, 34.672040999116881],
            [-92.445148, 34.626939999117404],
            [-92.346627, 34.625602999117419],
            [-92.348749, 34.582289999117918],
            [-92.244209, 34.580889999117943],
            [-92.245818, 34.493505999118959],
            [-92.403557, 34.495794999118928],
            [-92.40468, 34.451915999119443],
            [-92.589418, 34.45564099911941],
            [-92.668711, 34.4142369991199],
            [-92.685284, 34.473104999119201],
            [-92.793197, 34.503605999118832],
            [-92.808028, 34.59040599911782],
            [-92.875456, 34.635123999117305],
            [-92.979902, 34.637221999117287],
            [-92.96925, 34.772265999115731],
            [-93.075178, 34.771891999115724],
            [-93.073877, 34.858090999114751]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "197",
        COUNTYNS: "00516945",
        AFFGEOID: "0500000US21197",
        GEOID: "21197",
        NAME: "Powell",
        LSAD: "06",
        ALAND: 463556264,
        AWATER: 2956914
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.02737, 37.869453999085451],
            [-83.96631, 37.930767999084964],
            [-83.814919, 37.900226999085206],
            [-83.768783, 37.918370999085056],
            [-83.66296, 37.834734999085732],
            [-83.63106, 37.825371999085824],
            [-83.707974, 37.716462999086694],
            [-83.7221, 37.716419999086696],
            [-83.777592, 37.756775999086365],
            [-83.906251, 37.759362999086335],
            [-83.987054, 37.848306999085615],
            [-84.001967, 37.83759199908571],
            [-84.02737, 37.869453999085451]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "213",
        COUNTYNS: "00516953",
        AFFGEOID: "0500000US21213",
        GEOID: "21213",
        NAME: "Simpson",
        LSAD: "06",
        ALAND: 606584079,
        AWATER: 5857862
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.75134, 36.727119999095386],
            [-86.655888, 36.87625599909402],
            [-86.611586, 36.883056999093952],
            [-86.583289, 36.830281999094431],
            [-86.400518, 36.807225999094648],
            [-86.405769, 36.776186999094925],
            [-86.418888, 36.676030999095872],
            [-86.411496198966404, 36.64824042194433],
            [-86.507771, 36.65244499909609],
            [-86.551292, 36.637984999096226],
            [-86.562067651865306, 36.640746673003498],
            [-86.606394, 36.652106999096098],
            [-86.763290964108492, 36.648720673597914],
            [-86.75134, 36.727119999095386]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "165",
        COUNTYNS: "01639794",
        AFFGEOID: "0500000US47165",
        GEOID: "47165",
        NAME: "Sumner",
        LSAD: "06",
        ALAND: 1371067660,
        AWATER: 35857190
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.693292, 36.407230999098417],
            [-86.562067651865306, 36.640746673003498],
            [-86.551292, 36.637984999096226],
            [-86.507771, 36.65244499909609],
            [-86.411496198966404, 36.64824042194433],
            [-86.205565269855981, 36.639246869425016],
            [-86.218902, 36.50005599909754],
            [-86.228434, 36.48747199909765],
            [-86.268788, 36.452896999098002],
            [-86.283256, 36.347499999099021],
            [-86.298452, 36.313633999099331],
            [-86.451721, 36.335628999099121],
            [-86.521642, 36.278098999099683],
            [-86.552842, 36.319065999099301],
            [-86.594286, 36.245104999100008],
            [-86.639095, 36.255104999099927],
            [-86.754795, 36.405495999098449],
            [-86.693292, 36.407230999098417]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "225",
        COUNTYNS: "00516959",
        AFFGEOID: "0500000US21225",
        GEOID: "21225",
        NAME: "Union",
        LSAD: "06",
        ALAND: 887974689,
        AWATER: 53167480
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.132341, 37.69714199908686],
            [-88.059588, 37.742607999086488],
            [-88.02803, 37.799223999086017],
            [-87.970262, 37.781855999086162],
            [-87.935861, 37.789702999086103],
            [-87.903804, 37.817761999085867],
            [-87.938128, 37.870650999085434],
            [-87.925393381709796, 37.899591476222518],
            [-87.815093, 37.762422999086326],
            [-87.761995, 37.762364999086337],
            [-87.734545, 37.638375999087351],
            [-87.932952, 37.480051999088687],
            [-88.015572, 37.547209999088111],
            [-88.066250320097097, 37.50413832643666],
            [-88.072242, 37.528825999088262],
            [-88.131622, 37.572967999087886],
            [-88.132163438272485, 37.574516999917883],
            [-88.160062, 37.654331999087212],
            [-88.132341, 37.69714199908686]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "039",
        COUNTYNS: "00426598",
        AFFGEOID: "0500000US17039",
        GEOID: "17039",
        NAME: "De Witt",
        LSAD: "06",
        ALAND: 1029643576,
        AWATER: 19519851
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.148764, 40.282037999069274],
            [-88.919544, 40.282864999069268],
            [-88.574885, 40.28150099906928],
            [-88.745344, 40.098812999070255],
            [-88.745164, 40.055190999070511],
            [-89.144764, 40.048852999070562],
            [-89.148764, 40.282037999069274]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "045",
        COUNTYNS: "00424224",
        AFFGEOID: "0500000US17045",
        GEOID: "17045",
        NAME: "Edgar",
        LSAD: "06",
        ALAND: 1614542009,
        AWATER: 1600421
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.968777, 39.79234999907203],
            [-87.937645, 39.879802999071508],
            [-87.615182, 39.8816299990715],
            [-87.557513, 39.868718999071575],
            [-87.532454493884302, 39.882999117853792],
            [-87.532703, 39.664867999072783],
            [-87.532385282791012, 39.607304943294629],
            [-87.531666681974301, 39.477110991816829],
            [-87.687622, 39.487362999073873],
            [-87.960179, 39.48130899907391],
            [-87.9666, 39.685927999072661],
            [-87.968777, 39.79234999907203]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "055",
        COUNTYNS: "00424229",
        AFFGEOID: "0500000US17055",
        GEOID: "17055",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1059021102,
        AWATER: 58413469
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.177597, 37.950310999084813],
            [-89.115414, 38.085953999083742],
            [-89.129637, 38.124746999083428],
            [-88.704606, 38.125194999083433],
            [-88.706622, 37.906796999085159],
            [-88.70676, 37.863337999085502],
            [-89.151176, 37.861998999085515],
            [-89.150755, 37.945184999084852],
            [-89.177597, 37.950310999084813]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "061",
        COUNTYNS: "00424232",
        AFFGEOID: "0500000US17061",
        GEOID: "17061",
        NAME: "Greene",
        LSAD: "06",
        ALAND: 1406413317,
        AWATER: 8425393
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.61454, 39.142289999076091],
            [-90.59123, 39.198623999075714],
            [-90.613694, 39.395774999074462],
            [-90.610502, 39.457446999074065],
            [-90.581055, 39.521727999073661],
            [-90.301801, 39.520191999073681],
            [-90.15375, 39.520314999073676],
            [-90.148069, 39.261946999075313],
            [-90.314071, 39.225052999075551],
            [-90.313531, 39.174279999075885],
            [-90.580587, 39.184894999075816],
            [-90.603569, 39.117591999076261],
            [-90.61454, 39.142289999076091]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "009",
        COUNTYNS: "01675885",
        AFFGEOID: "0500000US06009",
        GEOID: "06009",
        NAME: "Calaveras",
        LSAD: "06",
        ALAND: 2641829199,
        AWATER: 43797662
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.995497, 38.225401999082649],
            [-120.892864, 38.221573999082686],
            [-120.810126, 38.28200999908222],
            [-120.63129, 38.340262999081787],
            [-120.614881, 38.389407999081413],
            [-120.423215, 38.473321999080788],
            [-120.33104, 38.465191999080844],
            [-120.205702, 38.501038999080592],
            [-120.072484, 38.50986899908051],
            [-120.072566, 38.447080999080974],
            [-120.019951, 38.433520999081082],
            [-120.176177, 38.374013999081519],
            [-120.442529, 38.058740999083945],
            [-120.462798, 38.011926999084316],
            [-120.534276, 37.989442999084496],
            [-120.514952, 37.953389999084777],
            [-120.653274, 37.831857999085756],
            [-120.926449, 38.077420999083799],
            [-120.942113, 38.096327999083655],
            [-120.995497, 38.225401999082649]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "009",
        COUNTYNS: "00659450",
        AFFGEOID: "0500000US27009",
        GEOID: "27009",
        NAME: "Benton",
        LSAD: "06",
        ALAND: 1057483363,
        AWATER: 12286992
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.353112, 45.821966999057196],
            [-93.763948, 45.822142999057199],
            [-93.759435, 45.559645999056976],
            [-94.149467, 45.559065999056976],
            [-94.203666, 45.619555999057013],
            [-94.182651, 45.679579999057076],
            [-94.271776, 45.775207999057159],
            [-94.353112, 45.821966999057196]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "007",
        COUNTYNS: "01035616",
        AFFGEOID: "0500000US38007",
        GEOID: "38007",
        NAME: "Billings",
        LSAD: "06",
        ALAND: 2975481852,
        AWATER: 11990484
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.666723, 47.329353999059997],
            [-103.099197, 47.328713999060021],
            [-103.032569, 47.328691999060005],
            [-103.032681, 46.980579999059117],
            [-103.230991, 46.980295999059123],
            [-103.231569, 46.629763999058369],
            [-103.60921, 46.629796999058378],
            [-103.609545, 46.979901999059116],
            [-103.666986, 46.979788999059124],
            [-103.666723, 47.329353999059997]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "035",
        COUNTYNS: "00450347",
        AFFGEOID: "0500000US18035",
        GEOID: "18035",
        NAME: "Delaware",
        LSAD: "06",
        ALAND: 1015542111,
        AWATER: 9857909
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.578589, 40.379523999068745],
            [-85.44433, 40.379139999068762],
            [-85.219901, 40.379033999068753],
            [-85.218758, 40.306705999069138],
            [-85.214386, 40.076888999070391],
            [-85.576197, 40.077142999070389],
            [-85.578589, 40.379523999068745]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "145",
        COUNTYNS: "00485036",
        AFFGEOID: "0500000US20145",
        GEOID: "20145",
        NAME: "Pawnee",
        LSAD: "06",
        ALAND: 1953531655,
        AWATER: 992041
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.584794, 38.349385999081711],
            [-99.032408, 38.348333999081731],
            [-99.032309, 38.261226999082382],
            [-98.912583, 38.261087999082392],
            [-98.913136, 38.0004509990844],
            [-99.022136, 38.00023599908441],
            [-99.240685, 37.999967999084404],
            [-99.350289, 38.000744999084404],
            [-99.350359, 38.087593999083722],
            [-99.569533, 38.087371999083707],
            [-99.569998, 38.189331999082924],
            [-99.570469, 38.252575999082438],
            [-99.584207, 38.262178999082373],
            [-99.584794, 38.349385999081711]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "101",
        COUNTYNS: "01101838",
        AFFGEOID: "0500000US40101",
        GEOID: "40101",
        NAME: "Muskogee",
        LSAD: "06",
        ALAND: 2099053124,
        AWATER: 75966890
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.766166, 35.856402999103935],
            [-95.65042, 35.856755999103918],
            [-95.589736, 35.765159999104881],
            [-95.360363, 35.822410999104285],
            [-95.265679, 35.813265999104374],
            [-95.127163, 35.812754999104378],
            [-95.127464, 35.638893999106195],
            [-95.132437, 35.526195999107394],
            [-95.085635, 35.461395999108078],
            [-95.049933, 35.458893999108106],
            [-95.170462, 35.310105999109716],
            [-95.240718, 35.262309999110236],
            [-95.34465, 35.293096999109899],
            [-95.34503, 35.552240999107113],
            [-95.712957, 35.551801999107113],
            [-95.713081, 35.725806999105281],
            [-95.766265, 35.72574999910529],
            [-95.766166, 35.856402999103935]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "109",
        COUNTYNS: "00559517",
        AFFGEOID: "0500000US22109",
        GEOID: "22109",
        NAME: "Terrebonne",
        LSAD: "15",
        ALAND: 3190255110,
        AWATER: 2202157453
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.334885, 29.298774999194219],
            [-91.276647, 29.329824999193686],
            [-91.265452, 29.360975999193158],
            [-91.266322670719205, 29.361363732539154],
            [-91.235795, 29.373936999192935],
            [-91.213419, 29.417497999192189],
            [-91.258332, 29.444318999191736],
            [-91.27124, 29.535185999190197],
            [-91.178048, 29.609702999188936],
            [-91.082602, 29.626367999188648],
            [-91.006743, 29.714770999187177],
            [-90.880194, 29.716808999187144],
            [-90.807692, 29.775907999186153],
            [-90.707832, 29.665379999188005],
            [-90.687572, 29.645353999188334],
            [-90.619309, 29.598091999189133],
            [-90.384285, 29.365754999193076],
            [-90.409413, 29.239737999195231],
            [-90.4094709017198, 29.058444385126563],
            [-90.442734, 29.0560529991984],
            [-90.488117, 29.058759999198355],
            [-90.652116, 29.057720999198374],
            [-90.748377, 29.040059999198686],
            [-90.81255, 29.042137999198651],
            [-90.867853, 29.056063999198411],
            [-90.877583, 29.104890999197561],
            [-90.941877, 29.162372999196563],
            [-91.000096, 29.169480999196438],
            [-91.094015, 29.187710999196124],
            [-91.158148, 29.218099999195605],
            [-91.278792, 29.247775999195092],
            [-91.334885, 29.298774999194219]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "135",
        COUNTYNS: "01101855",
        AFFGEOID: "0500000US40135",
        GEOID: "40135",
        NAME: "Sequoyah",
        LSAD: "06",
        ALAND: 1743779422,
        AWATER: 105832791
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.127464, 35.638893999106195],
            [-94.807297, 35.638603999106202],
            [-94.473119390646701, 35.638547151635606],
            [-94.449696, 35.496718999107699],
            [-94.43488928759669, 35.393185214696302],
            [-94.433913443832395, 35.386361769007593],
            [-94.49237, 35.3017759991098],
            [-94.594801, 35.327973999109517],
            [-94.668796, 35.295028999109874],
            [-94.750224, 35.356968999109206],
            [-94.813629, 35.323343999109568],
            [-94.828972, 35.361545999109154],
            [-94.914454, 35.347524999109304],
            [-94.901832, 35.397939999108765],
            [-95.00651, 35.414599999108582],
            [-95.049933, 35.458893999108106],
            [-95.085635, 35.461395999108078],
            [-95.132437, 35.526195999107394],
            [-95.127464, 35.638893999106195]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "021",
        COUNTYNS: "01135850",
        AFFGEOID: "0500000US41021",
        GEOID: "41021",
        NAME: "Gilliam",
        LSAD: "06",
        ALAND: 3120213951,
        AWATER: 46914718
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.525558, 45.305748999056831],
            [-120.525059, 45.405248999056873],
            [-120.496059, 45.4651489990569],
            [-120.35798, 45.533712999056959],
            [-120.488546820704002, 45.699912226535581],
            [-120.40396, 45.699248999057083],
            [-120.282156, 45.721249999057108],
            [-120.210754, 45.725950999057098],
            [-120.141352, 45.773151999057141],
            [-120.07015, 45.785151999057163],
            [-119.99950515346201, 45.811684922139086],
            [-120.006555, 45.25770599905681],
            [-119.882346, 45.256915999056815],
            [-119.883914, 45.169151999056787],
            [-119.759759, 45.169210999056787],
            [-119.790699, 45.067822999056773],
            [-120.49516, 45.068278999056773],
            [-120.503733, 45.083476999056764],
            [-120.45225, 45.145449999056787],
            [-120.554457, 45.252748999056813],
            [-120.525558, 45.305748999056831]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "049",
        COUNTYNS: "01035305",
        AFFGEOID: "0500000US38049",
        GEOID: "38049",
        NAME: "McHenry",
        LSAD: "06",
        ALAND: 4853553119,
        AWATER: 97384707
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.05966, 48.545336999064169],
            [-101.059624, 48.632423999064528],
            [-100.40678, 48.631877999064528],
            [-100.406388, 48.544656999064166],
            [-100.27666, 48.54481299906417],
            [-100.276401, 48.371333999063467],
            [-100.237332, 48.371245999063461],
            [-100.197558, 48.021856999062166],
            [-100.197996, 47.847599999061572],
            [-100.585161, 47.847479999061576],
            [-100.971325, 47.84870299906158],
            [-101.015024, 48.022738999062163],
            [-101.014138, 48.371431999063468],
            [-101.059802, 48.458447999063821],
            [-101.05966, 48.545336999064169]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "183",
        COUNTYNS: "00758546",
        AFFGEOID: "0500000US29183",
        GEOID: "29183",
        NAME: "St. Charles",
        LSAD: "06",
        ALAND: 1451493239,
        AWATER: 83353121
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.958536, 38.870864999077931],
            [-90.817827, 38.875965999077906],
            [-90.707557, 38.902651999077726],
            [-90.661582906876788, 38.934703306830691],
            [-90.657254, 38.920269999077597],
            [-90.595354, 38.875049999077916],
            [-90.555693, 38.870784999077948],
            [-90.500117, 38.91040799907767],
            [-90.467784, 38.961808999077299],
            [-90.450969940891895, 38.961395002401822],
            [-90.395816, 38.960036999077325],
            [-90.298711, 38.92339499907758],
            [-90.276583722025606, 38.919338466289204],
            [-90.230336, 38.910859999077665],
            [-90.207282301185586, 38.898732321917656],
            [-90.113327, 38.849305999078091],
            [-90.117707, 38.805747999078399],
            [-90.195678, 38.821260999078284],
            [-90.320213, 38.891147999077802],
            [-90.382268, 38.821941999078291],
            [-90.440617, 38.827649999078247],
            [-90.482299, 38.764347999078687],
            [-90.492149, 38.751792999078781],
            [-90.541612, 38.685266999079246],
            [-90.660509, 38.688550999079233],
            [-90.732328, 38.639301999079578],
            [-90.742412, 38.63456299907962],
            [-90.773622, 38.594616999079904],
            [-90.916554, 38.534724999080332],
            [-90.964461, 38.547544999080245],
            [-90.958536, 38.870864999077931]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "071",
        COUNTYNS: "00485002",
        AFFGEOID: "0500000US20071",
        GEOID: "20071",
        NAME: "Greeley",
        LSAD: "06",
        ALAND: 2016057985,
        AWATER: 0
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.045712903087008, 38.697565769157656],
            [-101.567094, 38.699668999079151],
            [-101.567482, 38.263144999082371],
            [-102.044634270290999, 38.262411566831879],
            [-102.044650359146985, 38.268749221585416],
            [-102.044944, 38.384418999081447],
            [-102.045510574570002, 38.615164589257247],
            [-102.045712903087008, 38.697565769157656]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "10",
        COUNTYFP: "005",
        COUNTYNS: "00217269",
        AFFGEOID: "0500000US10005",
        GEOID: "10005",
        NAME: "Sussex",
        LSAD: "06",
        ALAND: 2424522544,
        AWATER: 674194633
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.723102693332692, 38.829826555605926],
            [-75.555013, 38.835648999078181],
            [-75.484125, 38.904447999077703],
            [-75.410463, 38.91641799907763],
            [-75.380649, 38.961872999077308],
            [-75.306652109509699, 38.947660162405811],
            [-75.302552, 38.939001999077462],
            [-75.304078, 38.913159999077642],
            [-75.232029, 38.844253999078127],
            [-75.159022, 38.790192999078506],
            [-75.113331, 38.782997999078553],
            [-75.089473, 38.797197999078463],
            [-75.071805, 38.696496999079173],
            [-75.053973, 38.536272999080332],
            [-75.048939, 38.45126299908096],
            [-75.185455, 38.451011999080954],
            [-75.341287320348187, 38.45243652503644],
            [-75.479283, 38.45369799908093],
            [-75.693721, 38.460127999080882],
            [-75.700382, 38.542742999080289],
            [-75.701777515244999, 38.560766928158749],
            [-75.707551, 38.635334999079603],
            [-75.707555026429006, 38.635385354139501],
            [-75.723102693332692, 38.829826555605926]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "001",
        COUNTYNS: "00395066",
        AFFGEOID: "0500000US16001",
        GEOID: "16001",
        NAME: "Ada",
        LSAD: "06",
        ALAND: 2724902770,
        AWATER: 21961614
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.512502, 43.80716099905753],
            [-116.282019, 43.807364999057505],
            [-116.263384, 43.793791999057539],
            [-115.992799, 43.595084999057825],
            [-115.975719, 43.591437999057824],
            [-115.974881, 43.365019999058219],
            [-115.978651, 43.113431999058712],
            [-116.266207, 43.112268999058706],
            [-116.385955, 43.193576999058543],
            [-116.425275, 43.293542999058339],
            [-116.511897, 43.290519999058361],
            [-116.473597, 43.459217999058048],
            [-116.513123, 43.634182999057764],
            [-116.512502, 43.80716099905753]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "037",
        COUNTYNS: "00161544",
        AFFGEOID: "0500000US01037",
        GEOID: "01037",
        NAME: "Coosa",
        LSAD: "06",
        ALAND: 1685850816,
        AWATER: 39987274
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.517344, 33.020565999137446],
            [-86.511142, 33.088430999136548],
            [-86.491029, 33.102943999136357],
            [-86.17437, 33.104393999136335],
            [-86.010919, 33.104635999136335],
            [-86.00917, 33.090259999136528],
            [-86.013491, 32.81920299914016],
            [-86.007187, 32.754983999141025],
            [-86.31948, 32.753697999141053],
            [-86.336694, 32.768129999140854],
            [-86.374974, 32.753579999141046],
            [-86.457015, 32.813898999140228],
            [-86.451524, 32.863691999139562],
            [-86.515959, 32.929360999138673],
            [-86.517344, 33.020565999137446]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "061",
        COUNTYNS: "00307624",
        AFFGEOID: "0500000US12061",
        GEOID: "12061",
        NAME: "Indian River",
        LSAD: "06",
        ALAND: 1302197699,
        AWATER: 295585012
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.881172, 27.809169999220817],
            [-80.868881, 27.822521999220559],
            [-80.509075, 27.822057999220579],
            [-80.44767694921758, 27.860513512580869],
            [-80.383695, 27.740044999222103],
            [-80.330956, 27.597540999224769],
            [-80.316686611620383, 27.557340023833127],
            [-80.679821, 27.558469999225501],
            [-80.777166, 27.558732999225498],
            [-80.778047, 27.643194999223912],
            [-80.87315, 27.642287999223928],
            [-80.881172, 27.809169999220817]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "021",
        COUNTYNS: "00858644",
        AFFGEOID: "0500000US32021",
        GEOID: "32021",
        NAME: "Mineral",
        LSAD: "06",
        ALAND: 9719815219,
        AWATER: 155746682
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.157226100872009, 38.41439197084253],
            [-118.906861, 38.414676999081216],
            [-118.902124, 38.852157999078067],
            [-119.014528, 38.851299999078073],
            [-119.014837, 38.943322999077445],
            [-118.93035, 39.072767999076561],
            [-118.902684, 39.074073999076553],
            [-118.753835, 39.074629999076549],
            [-117.865116, 39.073653999076555],
            [-118.196274, 38.999925999077057],
            [-118.196235, 38.919057999077602],
            [-117.691021, 38.473796999080776],
            [-118.351484, 37.893704999085251],
            [-118.427995325156999, 37.896224279995039],
            [-118.500958, 37.949018999084821],
            [-118.949673, 38.268939999082328],
            [-119.157226100872009, 38.41439197084253]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "177",
        COUNTYNS: "00465277",
        AFFGEOID: "0500000US19177",
        GEOID: "19177",
        NAME: "Van Buren",
        LSAD: "06",
        ALAND: 1255646356,
        AWATER: 14839917
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.179072, 40.899719999066178],
            [-91.71818, 40.90108699906618],
            [-91.718787, 40.813543999066589],
            [-91.716654987681892, 40.603740138363612],
            [-91.729115, 40.613639999067551],
            [-91.939292, 40.606149999067597],
            [-91.943117459685695, 40.606060585421602],
            [-92.17978, 40.600528999067627],
            [-92.179072, 40.899719999066178]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "003",
        COUNTYNS: "00863600",
        AFFGEOID: "0500000US32003",
        GEOID: "32003",
        NAME: "Clark",
        LSAD: "06",
        ALAND: 20439281450,
        AWATER: 438376844
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-115.896925, 36.842084999094318],
            [-115.762975, 36.841910999094324],
            [-114.762507, 36.853472999094222],
            [-114.050160776087992, 36.843141538783719],
            [-114.049493, 36.604058999096544],
            [-114.047584, 36.325572999099222],
            [-114.048226, 36.268873999099782],
            [-114.046838, 36.194068999100523],
            [-114.09987, 36.121653999101234],
            [-114.138203, 36.05316099910192],
            [-114.151725, 36.024562999102216],
            [-114.21369, 36.015612999102309],
            [-114.270645, 36.035719999102106],
            [-114.316109, 36.063108999101829],
            [-114.337273, 36.10801999910138],
            [-114.372106, 36.143113999101033],
            [-114.41695, 36.145760999101],
            [-114.448654, 36.126409999101192],
            [-114.487034, 36.12939599910117],
            [-114.511721, 36.150955999100951],
            [-114.572031, 36.151609999100948],
            [-114.627855, 36.141011999101046],
            [-114.666538, 36.11734299910129],
            [-114.736165, 36.104366999101416],
            [-114.743342, 36.070534999101753],
            [-114.743299245886007, 36.065935172861899],
            [-114.742779, 36.009962999102356],
            [-114.731159, 35.943915999103041],
            [-114.700271, 35.901771999103467],
            [-114.669687, 35.865083999103838],
            [-114.70371, 35.814584999104369],
            [-114.697309, 35.733685999105212],
            [-114.689407, 35.651411999106067],
            [-114.653406, 35.610788999106489],
            [-114.663105, 35.5244909991074],
            [-114.6645, 35.449496999108213],
            [-114.627137, 35.409503999108637],
            [-114.587129, 35.262375999110233],
            [-114.572747, 35.138724999111588],
            [-114.59912, 35.121049999111783],
            [-114.619905, 35.121631999111791],
            [-114.625069, 35.068477999112368],
            [-114.633487, 35.001856999113116],
            [-114.804249, 35.139688999111584],
            [-115.043812, 35.332011999109476],
            [-115.160068, 35.424128999108483],
            [-115.303743, 35.538206999107267],
            [-115.404537, 35.617604999106405],
            [-115.647683, 35.8093579991044],
            [-115.648032019624992, 35.809629211707311],
            [-115.846113922762001, 35.96355267221373],
            [-115.845803, 36.122047999101227],
            [-115.895753, 36.172226999100744],
            [-115.896925, 36.842084999094318]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "181",
        COUNTYNS: "01383876",
        AFFGEOID: "0500000US48181",
        GEOID: "48181",
        NAME: "Grayson",
        LSAD: "06",
        ALAND: 2415995947,
        AWATER: 120112446
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.94461688837049, 33.945013249579588],
            [-96.934343303133005, 33.945588928378363],
            [-96.905253, 33.947218999125546],
            [-96.897194, 33.902953999126098],
            [-96.850593, 33.847210999126801],
            [-96.794276, 33.868885999126512],
            [-96.776766, 33.841975999126852],
            [-96.712422, 33.831632999126981],
            [-96.682103, 33.87664499912642],
            [-96.659896, 33.916665999125918],
            [-96.594674307377105, 33.883018656524648],
            [-96.590112, 33.880664999126367],
            [-96.592926, 33.830915999126994],
            [-96.572937, 33.819097999127138],
            [-96.523863, 33.818113999127149],
            [-96.502286, 33.773459999127716],
            [-96.436455, 33.78004999912762],
            [-96.403507, 33.746288999128041],
            [-96.379660825952101, 33.715530907694436],
            [-96.384602, 33.397825999132486],
            [-96.83411, 33.405497999132393],
            [-96.85369, 33.41361299913229],
            [-96.94386, 33.416409999132256],
            [-96.94461688837049, 33.945013249579588]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "189",
        COUNTYNS: "00758549",
        AFFGEOID: "0500000US29189",
        GEOID: "29189",
        NAME: "St. Louis",
        LSAD: "06",
        ALAND: 1314771903,
        AWATER: 39737787
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.732328, 38.639301999079578],
            [-90.660509, 38.688550999079233],
            [-90.541612, 38.685266999079246],
            [-90.492149, 38.751792999078781],
            [-90.482299, 38.764347999078687],
            [-90.440617, 38.827649999078247],
            [-90.382268, 38.821941999078291],
            [-90.320213, 38.891147999077802],
            [-90.195678, 38.821260999078284],
            [-90.117707, 38.805747999078399],
            [-90.166409, 38.772648999078633],
            [-90.166594640015589, 38.772450138383732],
            [-90.251745, 38.718886999078997],
            [-90.318212, 38.600016999079863],
            [-90.313582, 38.57876799908],
            [-90.255294887669393, 38.530877710901365],
            [-90.260976059287003, 38.518526786488252],
            [-90.271314, 38.496051999080613],
            [-90.288815, 38.438452999081051],
            [-90.340244281704301, 38.387094621717722],
            [-90.405262, 38.501152999080574],
            [-90.512522, 38.501453999080589],
            [-90.660703, 38.479194999080747],
            [-90.678775, 38.445876999080987],
            [-90.736311, 38.46664299908084],
            [-90.732328, 38.639301999079578]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "033",
        COUNTYNS: "00558530",
        AFFGEOID: "0500000US22033",
        GEOID: "22033",
        NAME: "East Baton Rouge",
        LSAD: "15",
        ALAND: 1179346038,
        AWATER: 38556769
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.297658, 30.649547999171993],
            [-91.254419, 30.705293999171097],
            [-90.849041, 30.719310999170887],
            [-90.850499, 30.700266999171184],
            [-90.910701, 30.649384999171993],
            [-90.985759, 30.584453999173014],
            [-90.991529, 30.461817999174965],
            [-90.93723, 30.366564999176489],
            [-90.891728, 30.345243999176841],
            [-90.986968, 30.345728999176824],
            [-91.021014, 30.321488999177223],
            [-91.126597, 30.313319999177349],
            [-91.142105, 30.323292999177191],
            [-91.239534, 30.351583999176729],
            [-91.199056, 30.4222789991756],
            [-91.196114, 30.452698999175109],
            [-91.197187, 30.505667999174257],
            [-91.209308, 30.522988999173986],
            [-91.281478, 30.506114999174262],
            [-91.246058, 30.535850999173782],
            [-91.315282, 30.594287999172867],
            [-91.297658, 30.649547999171993]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "005",
        COUNTYNS: "00161528",
        AFFGEOID: "0500000US01005",
        GEOID: "01005",
        NAME: "Barbour",
        LSAD: "06",
        ALAND: 2292144656,
        AWATER: 50538698
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.735732, 31.624492999157081],
            [-85.66623, 31.772876999154896],
            [-85.657668, 31.880274999153329],
            [-85.587344, 31.997354999151643],
            [-85.428476, 32.014950999151388],
            [-85.410241, 32.1466509991495],
            [-85.257834, 32.14793099914948],
            [-85.185067, 32.061707999150705],
            [-85.0514111279128, 32.062256082772706],
            [-85.063590542984684, 31.991856999151729],
            [-85.067829, 31.967357999152071],
            [-85.114031, 31.893359999153141],
            [-85.141831, 31.839260999153929],
            [-85.129159315583195, 31.780278267231392],
            [-85.125440577342502, 31.762968702573346],
            [-85.216076, 31.702408999155931],
            [-85.416038, 31.706663999155875],
            [-85.416437, 31.619465999157164],
            [-85.748251, 31.618047999157174],
            [-85.735732, 31.624492999157081]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "113",
        COUNTYNS: "00069175",
        AFFGEOID: "0500000US05113",
        GEOID: "05113",
        NAME: "Polk",
        LSAD: "06",
        ALAND: 2221382755,
        AWATER: 12372286
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.465425, 34.359547999120551],
            [-94.461168686155901, 34.507456650691296],
            [-94.4575, 34.634944999117309],
            [-94.454395034137889, 34.728958672295626],
            [-94.317659, 34.697407999116578],
            [-93.937304, 34.680554999116779],
            [-93.930509, 34.665607999116958],
            [-93.935371, 34.350096999120652],
            [-94.254668, 34.355480999120601],
            [-94.243739, 34.192500999122558],
            [-94.47015267816208, 34.189864291248689],
            [-94.465425, 34.359547999120551]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "021",
        COUNTYNS: "01672039",
        AFFGEOID: "0500000US13021",
        GEOID: "13021",
        NAME: "Bibb",
        LSAD: "06",
        ALAND: 645699773,
        AWATER: 14503560
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.863831, 32.864682999139539],
            [-83.734153, 32.951008999138374],
            [-83.710685, 32.952791999138348],
            [-83.696017, 32.916869999138825],
            [-83.658601, 32.887835999139234],
            [-83.56507, 32.877163999139377],
            [-83.513511, 32.844867999139808],
            [-83.602186, 32.741447999141208],
            [-83.597656, 32.664337999142269],
            [-83.617714, 32.663447999142278],
            [-83.701152, 32.692168999141892],
            [-83.810141, 32.729941999141374],
            [-83.891922, 32.848350999139754],
            [-83.863831, 32.864682999139539]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "097",
        COUNTYNS: "00161575",
        AFFGEOID: "0500000US01097",
        GEOID: "01097",
        NAME: "Mobile",
        LSAD: "06",
        ALAND: 3184127436,
        AWATER: 1073844850
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.432007, 31.114297999164755],
            [-88.329782, 31.143820999164312],
            [-88.022649, 31.144266999164305],
            [-87.972869, 31.162693999164013],
            [-87.940121, 31.147236999164264],
            [-87.976222, 31.089534999165146],
            [-87.941152, 31.048160999165781],
            [-87.965187, 30.967783999167015],
            [-87.92429, 30.93540499916752],
            [-87.981261, 30.886894999168263],
            [-87.944546, 30.827045999169201],
            [-88.026319, 30.753357999170348],
            [-88.008396, 30.684955999171429],
            [-88.061998, 30.644890999172059],
            [-88.064898, 30.588291999172959],
            [-88.081617, 30.546316999173627],
            [-88.103768, 30.500902999174347],
            [-88.105699, 30.401864999175931],
            [-88.136173, 30.320728999177231],
            [-88.195664, 30.321241999177218],
            [-88.257764, 30.31893299917725],
            [-88.311608, 30.368907999176447],
            [-88.364022, 30.388005999176148],
            [-88.395023, 30.369424999176449],
            [-88.403931, 30.543358999173673],
            [-88.41227, 30.731770999170685],
            [-88.41246743135919, 30.735597458385925],
            [-88.426020963787096, 30.998281356596852],
            [-88.432007, 31.114297999164755]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "103",
        COUNTYNS: "01804532",
        AFFGEOID: "0500000US72103",
        GEOID: "72103",
        NAME: "Naguabo",
        LSAD: "13",
        ALAND: 133799723,
        AWATER: 53351781
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-65.816614, 18.256847999438001],
            [-65.82422, 18.273053999437572],
            [-65.766831, 18.28000399943739],
            [-65.682893, 18.252633999438117],
            [-65.659925744917601, 18.191573206170336],
            [-65.695856, 18.179323999440058],
            [-65.733357095755693, 18.165770471656113],
            [-65.792477, 18.20675899943933],
            [-65.826063, 18.200407999439498],
            [-65.816614, 18.256847999438001]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "001",
        COUNTYNS: "01622943",
        AFFGEOID: "0500000US26001",
        GEOID: "26001",
        NAME: "Alcona",
        LSAD: "06",
        ALAND: 1747349262,
        AWATER: 2890404971
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.888484, 44.856457999056758],
            [-83.316266108576599, 44.858591449617656],
            [-83.296971, 44.758494999056786],
            [-83.276836, 44.689353999056799],
            [-83.314517, 44.608724999056832],
            [-83.316963875440194, 44.511683010816576],
            [-83.886634, 44.508975999056879],
            [-83.888484, 44.856457999056758]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "043",
        COUNTYNS: "01605085",
        AFFGEOID: "0500000US56043",
        GEOID: "56043",
        NAME: "Washakie",
        LSAD: "06",
        ALAND: 5798120186,
        AWATER: 10455585
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-108.550562, 44.168454999057118],
            [-107.541198, 44.166508999057115],
            [-107.146808, 44.165665999057111],
            [-107.128374, 44.154124999057132],
            [-107.113655, 43.816091999057498],
            [-107.110734, 43.500285999057972],
            [-107.534897, 43.50136199905797],
            [-107.595105, 43.501396999057974],
            [-107.595157, 43.646426999057745],
            [-107.716218, 43.64576599905773],
            [-107.715876, 43.733342999057605],
            [-107.956577, 43.733445999057615],
            [-107.956448, 43.818665999057494],
            [-108.174938, 43.818967999057499],
            [-108.185255, 43.84825499905746],
            [-108.309694, 43.846618999057462],
            [-108.30936, 43.903254999057395],
            [-108.429563, 43.903354999057392],
            [-108.427064, 43.992053999057291],
            [-108.489431, 43.991865999057289],
            [-108.490254, 44.079759999057217],
            [-108.550758, 44.079402999057216],
            [-108.550562, 44.168454999057118]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "049",
        COUNTYNS: "01718173",
        AFFGEOID: "0500000US54049",
        GEOID: "54049",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 799642461,
        AWATER: 7186185
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.498163, 39.563326999073404],
            [-80.433285, 39.639129999072949],
            [-80.39581, 39.637346999072946],
            [-80.347466, 39.611664999073106],
            [-80.153532, 39.610425999073108],
            [-79.938878, 39.452464999074095],
            [-80.087334, 39.386214999074511],
            [-80.197286, 39.393185999074475],
            [-80.282776, 39.435796999074206],
            [-80.494085, 39.469598999073987],
            [-80.498163, 39.563326999073404]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "015",
        COUNTYNS: "01480098",
        AFFGEOID: "0500000US51015",
        GEOID: "51015",
        NAME: "Augusta",
        LSAD: "06",
        ALAND: 2504323820,
        AWATER: 9922311
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.438103, 38.216234999082715],
            [-79.308495, 38.382962999081464],
            [-79.311296065514398, 38.418454600536194],
            [-79.297758, 38.416437999081211],
            [-79.23162, 38.474040999080792],
            [-79.228423002707785, 38.479741556568044],
            [-78.888258, 38.303690999082072],
            [-78.749396, 38.206647999082797],
            [-78.780879, 38.081028999083777],
            [-78.838874, 38.047369999084033],
            [-78.902282, 37.951401999084801],
            [-79.005129, 37.881689999085353],
            [-79.062454, 37.91759999908507],
            [-79.15739, 37.891002999085266],
            [-79.183978, 37.914193999085093],
            [-79.482668, 38.086053999083731],
            [-79.436678, 38.162799999083148],
            [-79.512158, 38.180418999083003],
            [-79.438103, 38.216234999082715]
          ],
          [
            [-79.105671, 38.13959099908331],
            [-79.049819, 38.121175999083455],
            [-79.020308, 38.14372499908329],
            [-79.022162, 38.190704999082925],
            [-79.022841, 38.195644999082887],
            [-79.095898, 38.185076999082966],
            [-79.105671, 38.13959099908331]
          ],
          [
            [-78.946801, 38.051984999083999],
            [-78.921941, 38.031402999084165],
            [-78.870563, 38.040459999084092],
            [-78.864449, 38.095737999083653],
            [-78.928697, 38.091483999083692],
            [-78.946801, 38.051984999083999]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "107",
        COUNTYNS: "01560558",
        AFFGEOID: "0500000US54107",
        GEOID: "54107",
        NAME: "Wood",
        LSAD: "06",
        ALAND: 948592039,
        AWATER: 27228519
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.752754, 39.184675999075814],
            [-81.721468139172501, 39.210960808279353],
            [-81.711628, 39.219227999075592],
            [-81.678331, 39.27375499907523],
            [-81.613896, 39.275338999075224],
            [-81.565247, 39.276174999075224],
            [-81.559647, 39.330773999074864],
            [-81.503189, 39.373241999074587],
            [-81.456143, 39.409273999074358],
            [-81.412706, 39.394617999074462],
            [-81.393794, 39.351705999074731],
            [-81.370389808826289, 39.348700671478156],
            [-81.363638, 39.320501999074928],
            [-81.239477, 39.268327999075261],
            [-81.259324, 39.267130999075285],
            [-81.298017, 39.185571999075805],
            [-81.424906, 39.135678999076134],
            [-81.581447, 39.026178999076876],
            [-81.720677, 39.084227999076482],
            [-81.750266843426303, 39.104031192176151],
            [-81.742953, 39.106577999076336],
            [-81.752297036740103, 39.181034746481728],
            [-81.752754, 39.184675999075814]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "105",
        COUNTYNS: "01678877",
        AFFGEOID: "0500000US54105",
        GEOID: "54105",
        NAME: "Wirt",
        LSAD: "06",
        ALAND: 602201080,
        AWATER: 5839706
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.581447, 39.026178999076876],
            [-81.424906, 39.135678999076134],
            [-81.298017, 39.185571999075805],
            [-81.250252, 39.035073999076808],
            [-81.162455, 39.03060799907685],
            [-81.27269, 38.936337999077494],
            [-81.278412, 38.914866999077638],
            [-81.343911, 38.895632999077769],
            [-81.448148, 38.941644999077454],
            [-81.502628, 38.91792199907762],
            [-81.532186, 39.005449999077008],
            [-81.581447, 39.026178999076876]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "011",
        COUNTYNS: "01639727",
        AFFGEOID: "0500000US47011",
        GEOID: "47011",
        NAME: "Bradley",
        LSAD: "06",
        ALAND: 851489133,
        AWATER: 6959140
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.026643, 35.13617099911162],
            [-84.946339, 35.287716999109954],
            [-84.879507, 35.353230999109243],
            [-84.860164, 35.350073999109277],
            [-84.72314, 35.28547099910999],
            [-84.744441, 35.264222999110217],
            [-84.697838, 35.253172999110326],
            [-84.703607, 35.241042999110462],
            [-84.699737, 35.172273999111226],
            [-84.775837671297296, 34.987937205262568],
            [-84.810477466886397, 34.987877954342466],
            [-84.861314, 34.98779099911328],
            [-84.976973806592298, 34.987219836468178],
            [-84.961146, 35.015174999112972],
            [-85.008478, 35.09268999911211],
            [-85.026643, 35.13617099911162]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "123",
        COUNTYNS: "01639776",
        AFFGEOID: "0500000US47123",
        GEOID: "47123",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 1646364006,
        AWATER: 43812886
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.504274, 35.665865999105918],
            [-84.290765, 35.654924999106022],
            [-84.188319, 35.610548999106491],
            [-84.081561, 35.541230999107228],
            [-83.993815, 35.550000999107141],
            [-83.93854, 35.48777299910779],
            [-83.958917834563906, 35.45789943868801],
            [-83.973171, 35.452581999108176],
            [-84.021782, 35.407417999108667],
            [-84.007586, 35.371660999109046],
            [-84.038081, 35.348362999109291],
            [-84.02351, 35.295782999109875],
            [-84.029104413772487, 35.292123775603805],
            [-84.097508, 35.247381999110402],
            [-84.178516, 35.240678999110479],
            [-84.223718, 35.269077999110159],
            [-84.28322, 35.226576999110627],
            [-84.286598378740905, 35.205758217606743],
            [-84.325722, 35.219376999110708],
            [-84.356923, 35.233675999110545],
            [-84.495529, 35.285471999109987],
            [-84.401025, 35.33037399910949],
            [-84.409226, 35.398377999108753],
            [-84.52585, 35.624185999106359],
            [-84.504274, 35.665865999105918]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "051",
        COUNTYNS: "00695749",
        AFFGEOID: "0500000US28051",
        GEOID: "28051",
        NAME: "Holmes",
        LSAD: "06",
        ALAND: 1959776825,
        AWATER: 20568825
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.43152, 33.095708999136448],
            [-90.330312, 33.137817999135891],
            [-90.331589, 33.302824999133733],
            [-90.279627, 33.339508999133251],
            [-90.321513, 33.37512099913279],
            [-90.174614, 33.333377999133319],
            [-89.749441, 33.214477999134878],
            [-89.896363, 32.932601999138626],
            [-89.965882, 32.879572999139342],
            [-90.338339, 33.010130999137594],
            [-90.346221, 33.022384999137422],
            [-90.364031, 33.010595999137585],
            [-90.43152, 33.095708999136448]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "005",
        COUNTYNS: "01673675",
        AFFGEOID: "0500000US51005",
        GEOID: "51005",
        NAME: "Alleghany",
        LSAD: "06",
        ALAND: 1156287826,
        AWATER: 8491488
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.290033810255011, 37.686136458057959],
            [-80.258143, 37.720611999086657],
            [-80.218616, 37.783290999086155],
            [-80.199633, 37.827506999085799],
            [-80.131931, 37.889499999085302],
            [-80.055811594589684, 37.951878219182696],
            [-79.935364, 37.954364999084767],
            [-79.887329, 37.892970999085257],
            [-79.749814, 37.885401999085332],
            [-79.692793, 37.844457999085648],
            [-79.647589, 37.874537999085419],
            [-79.606341, 37.862110999085516],
            [-79.674162, 37.763092999086318],
            [-79.816721, 37.800972999086014],
            [-79.91756, 37.701847999086823],
            [-80.020554, 37.647441999087256],
            [-80.145565, 37.596427999087702],
            [-80.224303244376799, 37.623991370258366],
            [-80.292258, 37.683731999086973],
            [-80.290033810255011, 37.686136458057959]
          ],
          [
            [-80.003265, 37.756566999086367],
            [-79.966509, 37.75710899908637],
            [-79.978214, 37.764974999086299],
            [-79.967071, 37.803404999085984],
            [-79.987402, 37.813584999085911],
            [-80.00136, 37.789825999086105],
            [-80.003265, 37.756566999086367]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "055",
        COUNTYNS: "01639746",
        AFFGEOID: "0500000US47055",
        GEOID: "47055",
        NAME: "Giles",
        LSAD: "06",
        ALAND: 1582304826,
        AWATER: 642572
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.232054, 35.292703999109911],
            [-87.206578, 35.433206999108378],
            [-87.129112, 35.456018999108139],
            [-86.960804, 35.417020999108558],
            [-86.906451, 35.314074999109678],
            [-86.828301, 35.263676999110224],
            [-86.836286315949508, 34.992803003330231],
            [-87.210758579982297, 34.999049180112848],
            [-87.216683, 34.999147999113148],
            [-87.224053641875699, 34.999230741085249],
            [-87.2153, 35.286624999109968],
            [-87.232054, 35.292703999109911]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "005",
        COUNTYNS: "01804482",
        AFFGEOID: "0500000US72005",
        GEOID: "72005",
        NAME: "Aguadilla",
        LSAD: "13",
        ALAND: 94613892,
        AWATER: 101131781
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.169011, 18.466351999432476],
            [-67.125655, 18.511705999431278],
            [-67.097303425856495, 18.511667915785477],
            [-67.05846, 18.479981999432116],
            [-67.056507, 18.460953999432611],
            [-67.125171, 18.408925999433986],
            [-67.13221, 18.3893909994345],
            [-67.160169877874694, 18.415599746577108],
            [-67.159608, 18.415914999433799],
            [-67.169011, 18.466351999432476]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "019",
        COUNTYNS: "01804489",
        AFFGEOID: "0500000US72019",
        GEOID: "72019",
        NAME: "Barranquitas",
        LSAD: "13",
        ALAND: 88714041,
        AWATER: 77812
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.351654, 18.194195999439664],
            [-66.350793, 18.241234999438419],
            [-66.313299, 18.2493239994382],
            [-66.266466, 18.245269999438314],
            [-66.263553, 18.18495899943991],
            [-66.24046, 18.184793999439915],
            [-66.242735, 18.180247999440034],
            [-66.248293, 18.165614999440422],
            [-66.314848, 18.152087999440781],
            [-66.323333, 18.165271999440431],
            [-66.373768, 18.173693999440207],
            [-66.351654, 18.194195999439664]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "107",
        COUNTYNS: "01804534",
        AFFGEOID: "0500000US72107",
        GEOID: "72107",
        NAME: "Orocovis",
        LSAD: "13",
        ALAND: 164788358,
        AWATER: 402899
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.543133, 18.163097999440488],
            [-66.512206, 18.166319999440404],
            [-66.501065, 18.256747999438005],
            [-66.455703, 18.25772599943798],
            [-66.390957, 18.273391999437568],
            [-66.384028, 18.290796999437106],
            [-66.377712, 18.293975999437023],
            [-66.350793, 18.241234999438419],
            [-66.351654, 18.194195999439664],
            [-66.373768, 18.173693999440207],
            [-66.413686, 18.163336999440482],
            [-66.423649, 18.17202499944025],
            [-66.44456, 18.176673999440126],
            [-66.470545, 18.177828999440095],
            [-66.521899, 18.151953999440785],
            [-66.54722, 18.153129999440754],
            [-66.543133, 18.163097999440488]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "017",
        COUNTYNS: "01673638",
        AFFGEOID: "0500000US51017",
        GEOID: "51017",
        NAME: "Bath",
        LSAD: "06",
        ALAND: 1370626013,
        AWATER: 14039993
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.036236, 37.967919999084664],
            [-79.971231, 38.044325999084059],
            [-79.961982272635396, 38.063607098577208],
            [-79.938952, 38.111618999083539],
            [-79.916174, 38.18438599908297],
            [-79.850324, 38.233328999082588],
            [-79.797013536806887, 38.26726812022153],
            [-79.706196, 38.221191999082691],
            [-79.512158, 38.180418999083003],
            [-79.436678, 38.162799999083148],
            [-79.482668, 38.086053999083731],
            [-79.533622, 38.003628999084384],
            [-79.647589, 37.874537999085419],
            [-79.692793, 37.844457999085648],
            [-79.749814, 37.885401999085332],
            [-79.887329, 37.892970999085257],
            [-79.935364, 37.954364999084767],
            [-80.055811594589684, 37.951878219182696],
            [-80.036236, 37.967919999084664]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "039",
        COUNTYNS: "01550026",
        AFFGEOID: "0500000US54039",
        GEOID: "54039",
        NAME: "Kanawha",
        LSAD: "06",
        ALAND: 2335215662,
        AWATER: 24056487
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.900222, 38.398571999081355],
            [-81.723066, 38.467771999080831],
            [-81.750547, 38.591013999079927],
            [-81.696348, 38.626426999079676],
            [-81.632817, 38.554698999080188],
            [-81.522166, 38.61274599907977],
            [-81.471654, 38.546335999080242],
            [-81.194113, 38.527633999080393],
            [-81.283872, 38.472028999080806],
            [-81.231434, 38.263715999082351],
            [-81.231636, 38.263513999082356],
            [-81.329265, 38.182480999082983],
            [-81.380829, 37.969108999084661],
            [-81.438531, 37.968007999084669],
            [-81.456632, 37.987306999084517],
            [-81.515836, 38.112359999083523],
            [-81.64387, 38.22075899908269],
            [-81.83347, 38.209569999082767],
            [-81.812825, 38.311406999082003],
            [-81.915197, 38.325596999081888],
            [-81.900222, 38.398571999081355]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "013",
        COUNTYNS: "00516853",
        AFFGEOID: "0500000US21013",
        GEOID: "21013",
        NAME: "Bell",
        LSAD: "06",
        ALAND: 929806778,
        AWATER: 5480741
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.956319, 36.645432999096151],
            [-83.877503, 36.687258999095768],
            [-83.798248, 36.785196999094843],
            [-83.566142, 36.931524999093504],
            [-83.589521, 36.955785999093287],
            [-83.509083, 36.938508999093436],
            [-83.489813, 36.895412999093828],
            [-83.500891, 36.733253999095339],
            [-83.460954647463808, 36.666131035369268],
            [-83.527112, 36.665984999095954],
            [-83.614513, 36.633982999096268],
            [-83.675413, 36.600813999096573],
            [-83.690714, 36.582580999096756],
            [-83.894421, 36.586480999096715],
            [-83.930760783943896, 36.587694257209094],
            [-83.956319, 36.645432999096151]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "023",
        COUNTYNS: "00516858",
        AFFGEOID: "0500000US21023",
        GEOID: "21023",
        NAME: "Bracken",
        LSAD: "06",
        ALAND: 532533292,
        AWATER: 8636823
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.212904, 38.805706999078389],
            [-84.135088, 38.789484999078525],
            [-84.0526461732196, 38.771614666614433],
            [-84.051642, 38.77139699907864],
            [-83.978814, 38.787103999078518],
            [-83.928454, 38.774582999078618],
            [-83.904375086104807, 38.767283874346468],
            [-83.991605, 38.593700999079914],
            [-84.083746, 38.608593999079794],
            [-84.162031, 38.554124999080202],
            [-84.215625, 38.554975999080192],
            [-84.205346, 38.582038999079991],
            [-84.19702, 38.594007999079899],
            [-84.226162633963497, 38.829777140320232],
            [-84.212904, 38.805706999078389]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "003",
        COUNTYNS: "00659447",
        AFFGEOID: "0500000US27003",
        GEOID: "27003",
        NAME: "Anoka",
        LSAD: "06",
        ALAND: 1095486322,
        AWATER: 59843479
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.51007, 45.414797999056887],
            [-93.019563, 45.411769999056879],
            [-93.01944, 45.296928999056831],
            [-93.020518, 45.12386599905679],
            [-93.227696, 45.124532999056783],
            [-93.227059, 45.049936999056769],
            [-93.226929, 45.035670999056762],
            [-93.280815, 45.071715999056771],
            [-93.296092, 45.121735999056781],
            [-93.311544, 45.143777999056788],
            [-93.373301, 45.178168999056787],
            [-93.425922, 45.214731999056802],
            [-93.51219, 45.245682999056804],
            [-93.51007, 45.414797999056887]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "031",
        COUNTYNS: "00581301",
        AFFGEOID: "0500000US23031",
        GEOID: "23031",
        NAME: "York",
        LSAD: "06",
        ALAND: 2566235154,
        AWATER: 722302593
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.987258046746007, 43.792973591941731],
            [-70.970719, 43.784989999057544],
            [-70.852593, 43.804734999057516],
            [-70.782984, 43.814146999057506],
            [-70.653533, 43.787737999057541],
            [-70.659622, 43.710901999057654],
            [-70.549965, 43.715795999057647],
            [-70.457743, 43.642371999057744],
            [-70.492009, 43.606768999057799],
            [-70.338737477248401, 43.528109278426832],
            [-70.361214, 43.529189999057927],
            [-70.385615, 43.487030999057993],
            [-70.327303, 43.458520999058031],
            [-70.383981, 43.412939999058125],
            [-70.416311, 43.361058999058216],
            [-70.465975, 43.340245999058261],
            [-70.517695, 43.344036999058247],
            [-70.534149065950601, 43.333957239804064],
            [-70.553854, 43.321885999058296],
            [-70.585184, 43.270112999058391],
            [-70.576986634166005, 43.228019172203673],
            [-70.575787, 43.221858999058483],
            [-70.596185, 43.163465999058602],
            [-70.62251, 43.134572999058662],
            [-70.665958, 43.076233999058779],
            [-70.703818, 43.059824999058819],
            [-70.756397, 43.079987999058773],
            [-70.819549285713009, 43.123230972489878],
            [-70.8281, 43.129085999058667],
            [-70.824801, 43.179684999058573],
            [-70.8247767120681, 43.179763104240777],
            [-70.813119, 43.217251999058483],
            [-70.872585, 43.270151999058385],
            [-70.923949, 43.324767999058281],
            [-70.984335, 43.376127999058198],
            [-70.968359, 43.429282999058088],
            [-70.960788896047006, 43.474088732679604],
            [-70.954755, 43.509801999057963],
            [-70.963792679806403, 43.540220954424413],
            [-70.972716, 43.570254999057845],
            [-70.981946, 43.700959999057652],
            [-70.987258046746007, 43.792973591941731]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "031",
        COUNTYNS: "00516862",
        AFFGEOID: "0500000US21031",
        GEOID: "21031",
        NAME: "Butler",
        LSAD: "06",
        ALAND: 1103567143,
        AWATER: 13943044
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.894067, 37.088348999092098],
            [-86.924273, 37.144622999091588],
            [-86.899268, 37.212312999090997],
            [-86.815852, 37.328858999089974],
            [-86.759533, 37.304098999090186],
            [-86.612095, 37.395122999089395],
            [-86.487152, 37.362500999089683],
            [-86.469126, 37.32109899909004],
            [-86.399165, 37.169895999091374],
            [-86.497187, 37.190386999091182],
            [-86.622895, 37.181088999091266],
            [-86.674462, 36.999765999092887],
            [-86.84535, 37.056512999092362],
            [-86.941391, 37.068980999092268],
            [-86.894067, 37.088348999092098]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "25",
        COUNTYFP: "009",
        COUNTYNS: "00606931",
        AFFGEOID: "0500000US25009",
        GEOID: "25009",
        NAME: "Essex",
        LSAD: "06",
        ALAND: 1275355437,
        AWATER: 870391710
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.238551, 42.671249999059754],
            [-71.255110121810603, 42.736397052346774],
            [-71.245384032920697, 42.736555327634186],
            [-71.181803, 42.737589999059573],
            [-71.186104, 42.790688999059448],
            [-71.149703, 42.81548899905939],
            [-71.116375012177485, 42.811902913494492],
            [-71.064201, 42.806288999059412],
            [-71.048716413698585, 42.831064337141548],
            [-71.031201, 42.859088999059267],
            [-70.9665, 42.868988999059255],
            [-70.930799, 42.884588999059211],
            [-70.86475, 42.870257999059248],
            [-70.817296, 42.872289999059255],
            [-70.80522, 42.781797999059464],
            [-70.772267, 42.711063999059654],
            [-70.72982, 42.669601999059765],
            [-70.681594, 42.66234199905977],
            [-70.645101, 42.689422999059694],
            [-70.602506, 42.677701999059735],
            [-70.594014, 42.635029999059853],
            [-70.654727, 42.582233999059994],
            [-70.698574, 42.577392999059995],
            [-70.804091, 42.561594999060041],
            [-70.848492, 42.550194999060068],
            [-70.835991, 42.490495999060244],
            [-70.886493, 42.470196999060299],
            [-70.899233979101794, 42.44991565474686],
            [-70.905993, 42.43915651065258],
            [-70.913192, 42.427696999060423],
            [-70.955211061463288, 42.425469089452129],
            [-70.982994, 42.423995999060438],
            [-71.053365, 42.475924999060283],
            [-71.033998, 42.58549299905998],
            [-71.145942, 42.60833499905992],
            [-71.164879, 42.598020999059933],
            [-71.255749, 42.656928999059787],
            [-71.238551, 42.671249999059754]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "001",
        COUNTYNS: "01135845",
        AFFGEOID: "0500000US41001",
        GEOID: "41001",
        NAME: "Baker",
        LSAD: "06",
        ALAND: 7945996928,
        AWATER: 51832632
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-118.51944, 44.705885999056804],
            [-118.380067, 44.744226999056792],
            [-118.285439, 44.750974999056787],
            [-118.295659, 44.863042999056759],
            [-118.228244, 44.865177999056769],
            [-118.244662, 44.958395999056769],
            [-118.130351, 45.045023999056774],
            [-117.969083, 44.995830999056757],
            [-117.84343, 45.058476999056765],
            [-117.777504, 44.992160999056757],
            [-117.564033, 44.992865999056761],
            [-117.563486, 45.079445999056766],
            [-117.266573, 45.080569999056763],
            [-116.783128263266988, 45.077712504066781],
            [-116.78371, 45.076971999056767],
            [-116.841314, 45.030906999056768],
            [-116.858313, 44.97876099905676],
            [-116.833632, 44.928975999056767],
            [-116.865338, 44.870598999056774],
            [-116.889295186967018, 44.840529777210072],
            [-116.9318, 44.787180999056773],
            [-117.013802, 44.756840999056784],
            [-117.062273, 44.727142999056781],
            [-117.094968, 44.652010999056813],
            [-117.14293, 44.557235999056857],
            [-117.167187, 44.523430999056863],
            [-117.225932, 44.479388999056894],
            [-117.215072, 44.427161999056921],
            [-117.243027, 44.390973999056946],
            [-117.216911, 44.360162999056968],
            [-117.192203, 44.328629999056986],
            [-117.211995506953002, 44.296451094995312],
            [-117.487014, 44.300316999057003],
            [-117.486744, 44.387245999056944],
            [-117.590849, 44.444572999056909],
            [-117.971787, 44.443848999056911],
            [-118.152456, 44.299508999057011],
            [-118.232214, 44.256128999057047],
            [-118.497499, 44.255247999057033],
            [-118.408786, 44.452647999056914],
            [-118.305778, 44.588809999056835],
            [-118.375037, 44.646101999056818],
            [-118.504327, 44.665271999056806],
            [-118.51944, 44.705885999056804]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "149",
        COUNTYNS: "01383860",
        AFFGEOID: "0500000US48149",
        GEOID: "48149",
        NAME: "Fayette",
        LSAD: "06",
        ALAND: 2460152119,
        AWATER: 25739448
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.315823, 29.786540999185977],
            [-97.194235, 29.896775999184147],
            [-97.024461, 30.051434999181605],
            [-97.018379, 30.032405999181915],
            [-96.845857, 30.116026999180548],
            [-96.794552, 30.160544999179823],
            [-96.647223, 30.145173999180074],
            [-96.62198, 30.044282999181728],
            [-96.569844, 29.961515999183089],
            [-96.874222, 29.632705999188552],
            [-97.142643, 29.628100999188632],
            [-97.131188, 29.643761999188371],
            [-97.317893, 29.784657999186013],
            [-97.315823, 29.786540999185977]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "053",
        COUNTYNS: "01639745",
        AFFGEOID: "0500000US47053",
        GEOID: "47053",
        NAME: "Gibson",
        LSAD: "06",
        ALAND: 1561106434,
        AWATER: 2341447
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.206297, 36.011813999102344],
            [-89.163406, 36.026043999102193],
            [-89.154464, 36.204677999100419],
            [-88.961232, 36.201634999100435],
            [-88.959839, 36.222833999100239],
            [-88.863298, 36.137638999101085],
            [-88.692709, 36.062745999101821],
            [-88.706811, 35.791074999104616],
            [-88.916181, 35.797306999104549],
            [-89.109285, 35.862088999103868],
            [-89.135958, 35.967840999102791],
            [-89.188844, 35.999759999102466],
            [-89.206297, 36.011813999102344]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "007",
        COUNTYNS: "00516850",
        AFFGEOID: "0500000US21007",
        GEOID: "21007",
        NAME: "Ballard",
        LSAD: "06",
        ALAND: 638850964,
        AWATER: 70009832
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.168087, 37.074217999092212],
            [-89.099047, 37.140966999091617],
            [-89.058036, 37.1887669990912],
            [-89.000968, 37.224400999090889],
            [-88.933517214747795, 37.227511279005462],
            [-88.815952, 36.954099999093302],
            [-89.080253, 36.915485999093654],
            [-89.103135448326597, 36.944760899415684],
            [-89.098843, 36.957849999093263],
            [-89.132915, 36.982056999093039],
            [-89.128899, 37.017907999092714],
            [-89.166620212019694, 37.072110342892138],
            [-89.168087, 37.074217999092212]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "005",
        COUNTYNS: "01247980",
        AFFGEOID: "0500000US45005",
        GEOID: "45005",
        NAME: "Allendale",
        LSAD: "06",
        ALAND: 1056949849,
        AWATER: 11205143
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.615955710789095, 33.089338538500542],
            [-81.541881, 33.158278999135625],
            [-81.364857, 33.108060999136285],
            [-81.193099, 33.11866799913615],
            [-81.099895, 33.048301999137088],
            [-81.082291, 33.026629999137363],
            [-81.236956, 32.940243999138538],
            [-81.261662, 32.839138999139884],
            [-81.338543, 32.768455999140841],
            [-81.413116795173607, 32.744261327102677],
            [-81.42062, 32.831222999140003],
            [-81.464069, 32.897813999139089],
            [-81.499566, 32.943721999138468],
            [-81.50203, 33.015112999137521],
            [-81.543966224313891, 33.044399953281527],
            [-81.601655, 33.084687999136598],
            [-81.615955710789095, 33.089338538500542]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "005",
        COUNTYNS: "01639724",
        AFFGEOID: "0500000US47005",
        GEOID: "47005",
        NAME: "Benton",
        LSAD: "06",
        ALAND: 1021248371,
        AWATER: 108583504
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.212559, 36.120289999101253],
            [-88.085167, 36.254160999099931],
            [-88.087082, 36.267897999099787],
            [-88.09167, 36.27519799909971],
            [-88.092526, 36.281032999099665],
            [-88.088851, 36.288555999099593],
            [-88.09371, 36.293027999099543],
            [-88.090782, 36.294849999099526],
            [-88.10266, 36.310932999099371],
            [-88.102109, 36.326972999099205],
            [-88.092269, 36.340314999099085],
            [-88.095868, 36.359264999098905],
            [-88.031779, 36.359808999098888],
            [-87.99092, 36.360132999098887],
            [-87.98074, 36.353306999098955],
            [-87.944295, 36.264176999099831],
            [-87.949603, 36.243159999100037],
            [-87.919043, 36.13004899910117],
            [-88.02239, 35.965817999102811],
            [-87.925835, 35.930431999103178],
            [-87.96274, 35.840920999104092],
            [-87.970738, 35.815704999104355],
            [-88.051635, 35.849917999103987],
            [-88.177859, 35.845840999104034],
            [-88.217436, 35.846581999104025],
            [-88.212559, 36.120289999101253]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "161",
        COUNTYNS: "01008584",
        AFFGEOID: "0500000US37161",
        GEOID: "37161",
        NAME: "Rutherford",
        LSAD: "06",
        ALAND: 1464487608,
        AWATER: 4681435
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.265795, 35.467817999108007],
            [-82.233203, 35.519401999107458],
            [-82.169049, 35.527810999107366],
            [-81.967619, 35.526006999107388],
            [-81.842468, 35.542262999107216],
            [-81.824122, 35.574982999106865],
            [-81.753407, 35.613587999106457],
            [-81.691989, 35.580040999106821],
            [-81.702853, 35.357303999109206],
            [-81.768134407773886, 35.179707705495041],
            [-81.87411220431629, 35.183509907137399],
            [-81.969358401257594, 35.186927087511556],
            [-81.968788, 35.257763999110281],
            [-82.151916, 35.407718999108667],
            [-82.261305, 35.393197999108814],
            [-82.280563, 35.44306899910827],
            [-82.265795, 35.467817999108007]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "121",
        COUNTYNS: "00450380",
        AFFGEOID: "0500000US18121",
        GEOID: "18121",
        NAME: "Parke",
        LSAD: "06",
        ALAND: 1151747431,
        AWATER: 13683945
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.420221, 39.952451999071101],
            [-87.091006, 39.953008999071102],
            [-87.091542, 39.86726499907158],
            [-87.009477, 39.866704999071587],
            [-87.013062, 39.604786999073156],
            [-87.199012, 39.607136999073141],
            [-87.382418, 39.607936999073132],
            [-87.395625, 39.652949999072867],
            [-87.353863, 39.862076999071618],
            [-87.420221, 39.952451999071101]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "049",
        COUNTYNS: "00198140",
        AFFGEOID: "0500000US08049",
        GEOID: "08049",
        NAME: "Grand",
        LSAD: "06",
        ALAND: 4782260517,
        AWATER: 59902382
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.652112, 40.445230999068421],
            [-106.612321, 40.379382999068753],
            [-106.489842, 40.411577999068584],
            [-106.44342, 40.346532999068927],
            [-106.351456, 40.355836999068892],
            [-106.230074, 40.323381999069049],
            [-106.018622, 40.373294999068783],
            [-105.959659, 40.348148999068918],
            [-105.905068, 40.39881599906866],
            [-105.891754, 40.476660999068258],
            [-105.854926, 40.486251999068209],
            [-105.807341, 40.47452699906826],
            [-105.81534, 40.421485999068544],
            [-105.653321, 40.260456999069383],
            [-105.638797, 40.038786999070609],
            [-105.693694, 39.942404999071158],
            [-105.675798, 39.932444999071208],
            [-105.699719, 39.891498999071437],
            [-105.690348, 39.851995999071676],
            [-105.736426, 39.804800999071958],
            [-105.887395, 39.796995999071996],
            [-105.924618, 39.698971999072576],
            [-106.012987, 39.686340999072655],
            [-106.083711, 39.805963999071935],
            [-106.252027, 39.914711999071315],
            [-106.434508, 39.924913999071258],
            [-106.626569, 39.924784999071257],
            [-106.637031, 40.002105999070807],
            [-106.632257, 40.341558999068951],
            [-106.652857, 40.346186999068934],
            [-106.652112, 40.445230999068421]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "009",
        COUNTYNS: "00345255",
        AFFGEOID: "0500000US13009",
        GEOID: "13009",
        NAME: "Baldwin",
        LSAD: "06",
        ALAND: 670027550,
        AWATER: 24802965
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.42909, 33.185351999135271],
            [-83.363543, 33.168997999135492],
            [-83.274108, 33.187237999135242],
            [-83.097649, 33.15153599913571],
            [-83.100838, 33.049863999137067],
            [-83.052197, 33.080681999136644],
            [-83.049081, 32.985604999137912],
            [-83.073648, 32.946561999138439],
            [-83.133847, 33.007247999137626],
            [-83.357685, 32.926140999138703],
            [-83.414997, 33.112832999136231],
            [-83.42909, 33.185351999135271]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "007",
        COUNTYNS: "01497770",
        AFFGEOID: "0500000US51007",
        GEOID: "51007",
        NAME: "Amelia",
        LSAD: "06",
        ALAND: 920142764,
        AWATER: 8424800
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.235039, 37.368101999089646],
            [-78.185002, 37.444551999088979],
            [-78.132048, 37.454685999088888],
            [-77.999575, 37.498051999088524],
            [-77.899761, 37.475421999088724],
            [-77.855148, 37.418362999089211],
            [-77.877171, 37.365170999089663],
            [-77.75862, 37.268716999090486],
            [-77.6522, 37.265821999090527],
            [-77.65061, 37.265111999090522],
            [-77.747693, 37.192728999091173],
            [-77.795926, 37.192591999091171],
            [-77.826108, 37.201985999091079],
            [-78.231234, 37.296219999090255],
            [-78.235039, 37.368101999089646]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "271",
        COUNTYNS: "01383921",
        AFFGEOID: "0500000US48271",
        GEOID: "48271",
        NAME: "Kinney",
        LSAD: "06",
        ALAND: 3522525623,
        AWATER: 13124946
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.801867546029001, 29.232831567293342],
            [-100.757822, 29.238324999195253],
            [-100.699141, 29.419746999192153],
            [-100.699932, 29.623896999188702],
            [-100.112098, 29.623262999188714],
            [-100.111406, 29.086317999197881],
            [-100.671215189194001, 29.083516024542426],
            [-100.674656, 29.099776999197648],
            [-100.727462, 29.12912299919714],
            [-100.772649, 29.168491999196455],
            [-100.795681, 29.227729999195436],
            [-100.801867546029001, 29.232831567293342]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "135",
        COUNTYNS: "00069183",
        AFFGEOID: "0500000US05135",
        GEOID: "05135",
        NAME: "Sharp",
        LSAD: "06",
        ALAND: 1565460785,
        AWATER: 5201389
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.682277, 36.00053499910247],
            [-91.674143, 36.208566999100363],
            [-91.691435, 36.253300999099935],
            [-91.566078, 36.249944999099966],
            [-91.565866, 36.337399999099112],
            [-91.454437, 36.335464999099123],
            [-91.450004918137395, 36.49754021228226],
            [-91.407137443517485, 36.497140711046171],
            [-91.275282, 36.374530999098738],
            [-91.25892, 36.257879999099892],
            [-91.349834, 36.230966999100154],
            [-91.35723, 35.890620999103568],
            [-91.464678, 35.892496999103564],
            [-91.463198, 35.936809999103126],
            [-91.710552, 35.941758999103058],
            [-91.682277, 36.00053499910247]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "177",
        COUNTYNS: "01026332",
        AFFGEOID: "0500000US37177",
        GEOID: "37177",
        NAME: "Tyrrell",
        LSAD: "06",
        ALAND: 1012110887,
        AWATER: 534568389
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.405603, 35.786293999104643],
            [-76.353554, 35.861300999103875],
            [-76.380009, 35.914665999103327],
            [-76.358393, 36.020018999102255],
            [-76.186705, 36.04772499910198],
            [-76.157953, 36.059516999101874],
            [-76.058217, 36.08059299910164],
            [-76.022607, 36.09672499910149],
            [-76.022398, 36.086653999101586],
            [-75.906295, 36.085876999101593],
            [-75.874806, 36.041788999102039],
            [-75.840046, 36.028833999102169],
            [-75.973152, 35.976642999102701],
            [-76.008348, 35.895801999103526],
            [-76.027479, 35.668848999105897],
            [-76.144653, 35.702487999105522],
            [-76.213895, 35.598237999106615],
            [-76.285839, 35.604463999106557],
            [-76.269247, 35.690753999105652],
            [-76.405971, 35.697578999105581],
            [-76.405603, 35.786293999104643]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "053",
        COUNTYNS: "00198142",
        AFFGEOID: "0500000US08053",
        GEOID: "08053",
        NAME: "Hinsdale",
        LSAD: "06",
        ALAND: 2893614470,
        AWATER: 15324686
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.568875, 37.965014999084694],
            [-107.51088, 38.060876999083924],
            [-107.56862, 38.147266999083257],
            [-107.001119, 38.147071999083266],
            [-107.000602, 37.956040999084756],
            [-107.138191, 37.938847999084892],
            [-107.145138, 37.675496999087038],
            [-107.12868, 37.422941999089161],
            [-107.482131, 37.422672999089166],
            [-107.482179, 37.639500999087332],
            [-107.458114, 37.78209599908616],
            [-107.516547, 37.8264509990858],
            [-107.568875, 37.965014999084694]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "115",
        COUNTYNS: "00277322",
        AFFGEOID: "0500000US06115",
        GEOID: "06115",
        NAME: "Yuba",
        LSAD: "06",
        ALAND: 1636913850,
        AWATER: 31057914
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.62376, 39.295620999075084],
            [-121.407527, 39.339753999074823],
            [-121.33442, 39.425211999074257],
            [-121.305122, 39.519584999073679],
            [-121.149907, 39.526445999073637],
            [-121.076695, 39.597263999073199],
            [-121.009477, 39.639458999072936],
            [-121.058203, 39.53704299907357],
            [-121.022085, 39.391557999074479],
            [-121.127481, 39.380236999074555],
            [-121.266132, 39.272716999075243],
            [-121.279533, 39.034617999076815],
            [-121.305992, 39.052942999076713],
            [-121.332135, 39.042020999076769],
            [-121.365643, 39.03174299907684],
            [-121.414779, 38.996451999077088],
            [-121.543306, 38.972403999077237],
            [-121.575415, 38.918347999077611],
            [-121.610138, 39.057846999076666],
            [-121.58591, 39.089690999076446],
            [-121.62376, 39.295620999075084]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "013",
        COUNTYNS: "00356976",
        AFFGEOID: "0500000US13013",
        GEOID: "13013",
        NAME: "Barrow",
        LSAD: "06",
        ALAND: 416283948,
        AWATER: 5707989
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.817682, 34.127492999123326],
            [-83.767532, 34.06644799912408],
            [-83.563277, 34.031863999124504],
            [-83.537385, 33.965911999125311],
            [-83.647031, 33.906197999126043],
            [-83.760869, 33.894572999126197],
            [-83.799104, 33.929843999125758],
            [-83.869115, 34.004315999124842],
            [-83.817682, 34.127492999123326]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "103",
        COUNTYNS: "01074064",
        AFFGEOID: "0500000US39103",
        GEOID: "39103",
        NAME: "Medina",
        LSAD: "06",
        ALAND: 1091406463,
        AWATER: 4198457
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.171492, 41.063536999065441],
            [-82.169875, 41.137096999065122],
            [-82.074266, 41.136455999065106],
            [-82.072465, 41.199849999064838],
            [-81.973895, 41.199830999064837],
            [-81.972485, 41.274828999064525],
            [-81.878053, 41.275043999064522],
            [-81.83911, 41.275561999064522],
            [-81.705151, 41.277253999064506],
            [-81.68495, 41.277145999064501],
            [-81.68699, 41.135955999065118],
            [-81.688491, 40.988589999065773],
            [-81.977381, 40.989960999065772],
            [-82.129334, 40.991806999065759],
            [-82.173359, 40.992045999065759],
            [-82.171492, 41.063536999065441]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "099",
        COUNTYNS: "00422247",
        AFFGEOID: "0500000US17099",
        GEOID: "17099",
        NAME: "LaSalle",
        LSAD: "06",
        ALAND: 2939990781,
        AWATER: 33693285
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.1672, 41.628706999063098],
            [-88.938618, 41.628318999063097],
            [-88.818233, 41.631350999063095],
            [-88.712444, 41.630490999063085],
            [-88.60224, 41.631388999063105],
            [-88.59596, 41.457033999063775],
            [-88.58624, 41.108292999065242],
            [-88.930881, 41.105899999065258],
            [-88.93139, 40.927739999066048],
            [-89.047718, 40.925748999066059],
            [-89.047856, 41.104780999065255],
            [-89.162238, 41.104079999065256],
            [-89.163705, 41.310186999064371],
            [-89.166561, 41.585288999063266],
            [-89.1672, 41.628706999063098]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "063",
        COUNTYNS: "00198147",
        AFFGEOID: "0500000US08063",
        GEOID: "08063",
        NAME: "Kit Carson",
        LSAD: "06",
        ALAND: 5596501950,
        AWATER: 2251930
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.155253, 39.1279839990762],
            [-103.154376, 39.565653999073383],
            [-102.802932, 39.567840999073383],
            [-102.049992135644004, 39.574056096494353],
            [-102.049961857827995, 39.568178979746477],
            [-102.04896, 39.373711999074601],
            [-102.047200721701003, 39.133146708587354],
            [-102.046571, 39.047037999076736],
            [-102.715766, 39.038674999076797],
            [-103.163025, 39.0376099990768],
            [-103.155253, 39.1279839990762]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "035",
        COUNTYNS: "01804497",
        AFFGEOID: "0500000US72035",
        GEOID: "72035",
        NAME: "Cayey",
        LSAD: "13",
        ALAND: 134509871,
        AWATER: 68569
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.216899, 18.143219999441019],
            [-66.110176, 18.142036999441046],
            [-66.095099, 18.169076999440335],
            [-66.065968, 18.14841099944088],
            [-66.052555, 18.113068999441822],
            [-66.051788, 18.109877999441903],
            [-66.053146, 18.107292999441974],
            [-66.129031, 18.088636999442468],
            [-66.138987, 18.036916999443843],
            [-66.166145, 18.05006299944349],
            [-66.202038, 18.046112999443594],
            [-66.22449, 18.095338999442291],
            [-66.216899, 18.143219999441019]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "123",
        COUNTYNS: "01804542",
        AFFGEOID: "0500000US72123",
        GEOID: "72123",
        NAME: "Salinas",
        LSAD: "13",
        ALAND: 179660999,
        AWATER: 115910809
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.331244, 18.015890999444409],
            [-66.310623, 18.046966999443576],
            [-66.257527, 18.075883999442805],
            [-66.22449, 18.095338999442291],
            [-66.202038, 18.046112999443594],
            [-66.166145, 18.05006299944349],
            [-66.205592, 18.012979999444486],
            [-66.220530693979995, 17.917810380391824],
            [-66.243235, 17.913768999447125],
            [-66.297679, 17.959147999445918],
            [-66.338139425689093, 17.976351457200753],
            [-66.331244, 18.015890999444409]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "145",
        COUNTYNS: "01623015",
        AFFGEOID: "0500000US26145",
        GEOID: "26145",
        NAME: "Saginaw",
        LSAD: "06",
        ALAND: 2073181841,
        AWATER: 40159474
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.369876, 43.466043999058023],
            [-84.170576, 43.481968999058004],
            [-84.168127, 43.568898999057858],
            [-84.05, 43.567323999057869],
            [-84.050987, 43.52406399905793],
            [-83.916091, 43.522628999057936],
            [-83.817228, 43.522344999057943],
            [-83.817678, 43.479051999057994],
            [-83.698816, 43.478956999057999],
            [-83.698509, 43.392710999058153],
            [-83.695621, 43.221421999058485],
            [-83.932071, 43.220376999058487],
            [-83.929079, 43.132781999058658],
            [-84.367891, 43.128451999058676],
            [-84.369876, 43.466043999058023]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "147",
        COUNTYNS: "01625033",
        AFFGEOID: "0500000US26147",
        GEOID: "26147",
        NAME: "St. Clair",
        LSAD: "06",
        ALAND: 1867864472,
        AWATER: 298677842
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.996257, 43.154098999058604],
            [-82.506042404955096, 43.168827370370082],
            [-82.486042, 43.102485999058722],
            [-82.415937, 43.005554999058951],
            [-82.428603, 42.952000999059059],
            [-82.469912, 42.887458999059213],
            [-82.467483, 42.761909999059519],
            [-82.509935, 42.637293999059835],
            [-82.583996, 42.554040999060064],
            [-82.679059, 42.522209999060145],
            [-82.706371, 42.621106999059883],
            [-82.639701, 42.661232999059777],
            [-82.707885, 42.675496999059746],
            [-82.729277, 42.706058999059657],
            [-82.73901, 42.897540999059188],
            [-82.983647, 42.893740999059197],
            [-82.996257, 43.154098999058604]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "111",
        COUNTYNS: "00465244",
        AFFGEOID: "0500000US19111",
        GEOID: "19111",
        NAME: "Lee",
        LSAD: "06",
        ALAND: 1340422373,
        AWATER: 55292890
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.718787, 40.813543999066589],
            [-91.409453, 40.812904999066596],
            [-91.208185, 40.713579999067065],
            [-91.118222938397508, 40.699534649886246],
            [-91.12082, 40.672776999067267],
            [-91.18546060870429, 40.638111811690941],
            [-91.18698, 40.637296999067445],
            [-91.247851, 40.638389999067442],
            [-91.339719, 40.613487999067551],
            [-91.374252, 40.582589999067714],
            [-91.394475, 40.534542999067966],
            [-91.367876, 40.51047899906807],
            [-91.379907, 40.452109999068377],
            [-91.372921, 40.399107999068654],
            [-91.419422, 40.378263999068764],
            [-91.498093, 40.401925999068638],
            [-91.519134, 40.432821999068473],
            [-91.563844, 40.460987999068337],
            [-91.608347, 40.500039999068129],
            [-91.618999, 40.539083999067927],
            [-91.670993, 40.550936999067872],
            [-91.685381, 40.578891999067729],
            [-91.716654987681892, 40.603740138363612],
            [-91.718787, 40.813543999066589]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "057",
        COUNTYNS: "00558065",
        AFFGEOID: "0500000US22057",
        GEOID: "22057",
        NAME: "Lafourche",
        LSAD: "15",
        ALAND: 2766953348,
        AWATER: 1037594367
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.947496, 29.792305999185885],
            [-90.925046, 29.830376999185255],
            [-90.885195, 29.877504999184467],
            [-90.885589, 29.905352999184007],
            [-90.77729, 29.922028999183734],
            [-90.656312, 29.889245999184276],
            [-90.629134, 29.895229999184178],
            [-90.529601, 29.884996999184345],
            [-90.479904, 29.847376999184974],
            [-90.468043, 29.803473999185709],
            [-90.371978, 29.759238999186433],
            [-90.352104, 29.695511999187499],
            [-90.228157, 29.692027999187552],
            [-90.155807, 29.677529999187797],
            [-90.151725, 29.58787099918931],
            [-90.186172, 29.563340999189712],
            [-90.137504, 29.477162999191172],
            [-90.020622, 29.428460999192001],
            [-90.030189, 29.332070999193643],
            [-89.998954, 29.298338999194225],
            [-90.089835448194194, 29.164475355817636],
            [-90.122753, 29.144285999196871],
            [-90.223587, 29.085074999197904],
            [-90.334935, 29.063802999198273],
            [-90.4094709017198, 29.058444385126563],
            [-90.409413, 29.239737999195231],
            [-90.384285, 29.365754999193076],
            [-90.619309, 29.598091999189133],
            [-90.687572, 29.645353999188334],
            [-90.707832, 29.665379999188005],
            [-90.807692, 29.775907999186153],
            [-90.880194, 29.716808999187144],
            [-91.006743, 29.714770999187177],
            [-90.947496, 29.792305999185885]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "047",
        COUNTYNS: "01622966",
        AFFGEOID: "0500000US26047",
        GEOID: "26047",
        NAME: "Emmet",
        LSAD: "06",
        ALAND: 1210879338,
        AWATER: 1073480654
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.119737, 45.569025999056983],
            [-85.061488, 45.639504999057046],
            [-84.97095, 45.686333999057069],
            [-85.014509, 45.760328999057144],
            [-84.866976, 45.752065999057123],
            [-84.772765, 45.789300999057154],
            [-84.732242, 45.780496852346161],
            [-84.732687, 45.289381999056829],
            [-84.964787, 45.291600999056833],
            [-85.001803, 45.272327999056813],
            [-85.096056872271006, 45.363088039491657],
            [-85.054805, 45.364090999056863],
            [-84.959119, 45.375972999056863],
            [-84.912956, 45.40977599905689],
            [-84.980953, 45.429381999056879],
            [-85.040936, 45.436700999056896],
            [-85.109252, 45.521625999056937],
            [-85.119737, 45.569025999056983]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "039",
        COUNTYNS: "00835841",
        AFFGEOID: "0500000US31039",
        GEOID: "31039",
        NAME: "Cuming",
        LSAD: "06",
        ALAND: 1477641638,
        AWATER: 10701538
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.019359, 42.090576999061462],
            [-96.82367, 42.090410999061461],
            [-96.555511, 42.089956999061464],
            [-96.554866, 42.015874999061722],
            [-96.555172, 41.742017999062682],
            [-96.905922, 41.742762999062677],
            [-97.019911, 41.742979999062676],
            [-97.019359, 42.090576999061462]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "007",
        COUNTYNS: "00424205",
        AFFGEOID: "0500000US17007",
        GEOID: "17007",
        NAME: "Boone",
        LSAD: "06",
        ALAND: 727092582,
        AWATER: 3348032
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.940384860378003, 42.495439826531424],
            [-88.776495760735187, 42.494136599527636],
            [-88.70738, 42.493586999060234],
            [-88.70737771540179, 42.493587013829831],
            [-88.705633, 42.153560999061256],
            [-88.939732, 42.152319999061255],
            [-88.940384860378003, 42.495439826531424]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "005",
        COUNTYNS: "01722470",
        AFFGEOID: "0500000US30005",
        GEOID: "30005",
        NAME: "Blaine",
        LSAD: "06",
        ALAND: 10948196395,
        AWATER: 29079275
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.551644, 48.293478999063169],
            [-109.496741, 48.451261999063789],
            [-109.464318, 48.452637999063796],
            [-109.464318, 48.525259999064076],
            [-109.505333, 48.567909999064263],
            [-109.505514, 48.91391099906577],
            [-109.489549778986003, 49.000420802946664],
            [-109.250722, 49.000010999066163],
            [-109.000708, 48.999233999066163],
            [-108.543194, 48.999376999066151],
            [-108.236393, 48.99955811636756],
            [-108.259372, 48.913591999065773],
            [-108.259384, 48.742224999064995],
            [-108.319119, 48.742208999065006],
            [-108.319011, 48.568430999064262],
            [-108.346316, 48.422304999063662],
            [-108.40987, 48.448577999063765],
            [-108.411239, 48.219549999062892],
            [-108.43398, 47.976552999062022],
            [-108.607579, 47.990996999062062],
            [-108.625587, 47.920410999061815],
            [-108.888316, 47.922721999061828],
            [-108.891115, 47.734455999061204],
            [-108.978383, 47.802657999061431],
            [-109.132768, 47.781121999061355],
            [-109.274171, 47.793238999061401],
            [-109.425315, 47.720152999061163],
            [-109.538677, 47.738863999061216],
            [-109.534295, 48.134563999062578],
            [-109.551644, 48.293478999063169]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "155",
        COUNTYNS: "01623018",
        AFFGEOID: "0500000US26155",
        GEOID: "26155",
        NAME: "Shiawassee",
        LSAD: "06",
        ALAND: 1374689328,
        AWATER: 26342203
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.367891, 43.128451999058676],
            [-83.929079, 43.132781999058658],
            [-83.922516, 42.780820999059472],
            [-84.158189, 42.776638999059479],
            [-84.363659, 42.775777999059478],
            [-84.36776, 43.117941999058694],
            [-84.367891, 43.128451999058676]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "041",
        COUNTYNS: "01034209",
        AFFGEOID: "0500000US38041",
        GEOID: "38041",
        NAME: "Hettinger",
        LSAD: "06",
        ALAND: 2932411624,
        AWATER: 4036912
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.928003, 46.630064999058369],
            [-102.096585, 46.631023999058385],
            [-102.051237, 46.630905999058378],
            [-102.046929, 46.283605999057784],
            [-101.997888, 46.205479999057665],
            [-102.497475, 46.206076999057665],
            [-102.497449, 46.283195999057774],
            [-102.924547, 46.281518999057774],
            [-102.928003, 46.630064999058369]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "053",
        COUNTYNS: "01035299",
        AFFGEOID: "0500000US38053",
        GEOID: "38053",
        NAME: "McKenzie",
        LSAD: "06",
        ALAND: 7149551309,
        AWATER: 260198853
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.045313, 47.331954999060024],
            [-104.044976716405003, 47.3970692003426],
            [-104.043912, 47.603228999060804],
            [-104.042384, 47.803255999061435],
            [-104.043933, 47.971514999061988],
            [-104.044093215348013, 47.996098354626774],
            [-103.919789, 47.955949999061936],
            [-103.856306, 48.015071999062137],
            [-103.800817, 47.994268999062079],
            [-103.784394, 48.059490999062305],
            [-103.709454, 48.065461999062322],
            [-103.742216, 48.09340299906242],
            [-103.597614, 48.134391999062565],
            [-103.548572, 48.087723999062419],
            [-103.584774, 48.054123999062284],
            [-103.485072, 48.006724999062122],
            [-103.42837, 48.035923999062213],
            [-103.288366, 48.040122999062241],
            [-103.225965, 48.075122999062366],
            [-103.20189, 48.13520799906258],
            [-103.107083, 48.146493999062614],
            [-102.828477, 48.123663999062529],
            [-102.663487, 48.076650999062359],
            [-102.551964, 47.997148999062084],
            [-102.644764, 47.906714999061769],
            [-102.642266, 47.823712999061499],
            [-102.642766, 47.673910999061022],
            [-103.099867, 47.674415999061026],
            [-103.099197, 47.328713999060021],
            [-103.666723, 47.329353999059997],
            [-104.045308309869014, 47.330127857866003],
            [-104.045313, 47.331954999060024]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "009",
        COUNTYNS: "00450404",
        AFFGEOID: "0500000US18009",
        GEOID: "18009",
        NAME: "Blackford",
        LSAD: "06",
        ALAND: 427554638,
        AWATER: 1303566
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.447014, 40.566928999067791],
            [-85.390539, 40.566897999067798],
            [-85.201146, 40.567241999067797],
            [-85.200628, 40.495826999068157],
            [-85.206508, 40.479372999068232],
            [-85.206886, 40.386017999068713],
            [-85.219901, 40.379033999068753],
            [-85.44433, 40.379139999068762],
            [-85.447014, 40.566928999067791]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "029",
        COUNTYNS: "01804494",
        AFFGEOID: "0500000US72029",
        GEOID: "72029",
        NAME: "Canóvanas",
        LSAD: "13",
        ALAND: 85127784,
        AWATER: 364681
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-65.919278, 18.402992999434147],
            [-65.869949, 18.408914999433986],
            [-65.867447, 18.378197999434796],
            [-65.867875, 18.293813999437024],
            [-65.836387, 18.275245999437519],
            [-65.852616, 18.254971999438052],
            [-65.918535, 18.270129999437653],
            [-65.901782, 18.316157999436435],
            [-65.919278, 18.402992999434147]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "037",
        COUNTYNS: "01804498",
        AFFGEOID: "0500000US72037",
        GEOID: "72037",
        NAME: "Ceiba",
        LSAD: "13",
        ALAND: 75201651,
        AWATER: 336178483
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-65.758861, 18.290603999437113],
            [-65.753591, 18.296754999436949],
            [-65.737739, 18.273880999437559],
            [-65.613949666954994, 18.293815341641221],
            [-65.588317, 18.254256999438073],
            [-65.599065, 18.212960999439165],
            [-65.635281, 18.199974999439512],
            [-65.659925744917601, 18.191573206170336],
            [-65.682893, 18.252633999438117],
            [-65.766831, 18.28000399943739],
            [-65.758861, 18.290603999437113]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "043",
        COUNTYNS: "01804501",
        AFFGEOID: "0500000US72043",
        GEOID: "72043",
        NAME: "Coamo",
        LSAD: "13",
        ALAND: 202052316,
        AWATER: 38061
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.423649, 18.17202499944025],
            [-66.413686, 18.163336999440482],
            [-66.373768, 18.173693999440207],
            [-66.323333, 18.165271999440431],
            [-66.314848, 18.152087999440781],
            [-66.320913, 18.126004999441474],
            [-66.257527, 18.075883999442805],
            [-66.310623, 18.046966999443576],
            [-66.331244, 18.015890999444409],
            [-66.356779, 18.036941999443844],
            [-66.426989, 18.042626999443691],
            [-66.430314, 18.082052999442642],
            [-66.419368, 18.145303999440959],
            [-66.44456, 18.176673999440126],
            [-66.423649, 18.17202499944025]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "051",
        COUNTYNS: "01804505",
        AFFGEOID: "0500000US72051",
        GEOID: "72051",
        NAME: "Dorado",
        LSAD: "13",
        ALAND: 59870098,
        AWATER: 79238790
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.315024273690199, 18.474741190772747],
            [-66.258015, 18.476905999432191],
            [-66.195882211469396, 18.470653763940565],
            [-66.2581, 18.454975999432772],
            [-66.252554, 18.394186999434375],
            [-66.303663, 18.38407699943464],
            [-66.299926, 18.423912999433593],
            [-66.315024273690199, 18.474741190772747]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "033",
        COUNTYNS: "01531933",
        AFFGEOID: "0500000US53033",
        GEOID: "53033",
        NAME: "King",
        LSAD: "06",
        ALAND: 5478366437,
        AWATER: 497909625
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.536993, 47.403354999060205],
            [-122.445026, 47.542712999060612],
            [-122.438093, 47.777813999061358],
            [-122.327392, 47.777645999061356],
            [-122.271033, 47.77709499906134],
            [-122.243612, 47.77695899906135],
            [-121.991775, 47.775348999061343],
            [-121.119179, 47.779932999061359],
            [-121.066005, 47.713602999061131],
            [-121.121584, 47.685212999061058],
            [-121.113569, 47.597287999060789],
            [-121.17225, 47.590053999060757],
            [-121.410878, 47.424538999060275],
            [-121.464234, 47.354415999060073],
            [-121.427492, 47.289074999059906],
            [-121.341203, 47.281260999059874],
            [-121.365138, 47.224562999059714],
            [-121.297567, 47.148617999059539],
            [-121.408081, 47.118099999059453],
            [-121.379682, 47.087494999059388],
            [-121.379961, 47.087247999059393],
            [-121.581673, 47.118647999059462],
            [-121.630993, 47.153512999059544],
            [-121.796464, 47.173058999059599],
            [-121.937864, 47.138720999059501],
            [-122.094764, 47.194976999059648],
            [-122.143976, 47.257525999059808],
            [-122.229792, 47.25755899905981],
            [-122.331322, 47.257364999059831],
            [-122.33492, 47.257593999059814],
            [-122.350506, 47.273841999059847],
            [-122.437252, 47.333716999060016],
            [-122.510135, 47.319619999059981],
            [-122.537595, 47.33733899906003],
            [-122.536993, 47.403354999060205]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "123",
        COUNTYNS: "00695782",
        AFFGEOID: "0500000US28123",
        GEOID: "28123",
        NAME: "Scott",
        LSAD: "06",
        ALAND: 1577902077,
        AWATER: 3001522
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.770913, 32.606149999143064],
            [-89.730304, 32.634227999142681],
            [-89.730152, 32.577247999143474],
            [-89.322598, 32.576432999143478],
            [-89.322692, 32.223471999148408],
            [-89.701621, 32.223756999148407],
            [-89.730424, 32.222038999148424],
            [-89.730063, 32.352732999146596],
            [-89.784123, 32.586890999143343],
            [-89.770913, 32.606149999143064]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "065",
        COUNTYNS: "00835854",
        AFFGEOID: "0500000US31065",
        GEOID: "31065",
        NAME: "Furnas",
        LSAD: "06",
        ALAND: 1862542307,
        AWATER: 3915843
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.197807, 40.350026999068916],
            [-100.094798, 40.351244999068903],
            [-99.642209, 40.351199999068903],
            [-99.630459, 40.351119999068906],
            [-99.628253849224492, 40.001771946339204],
            [-99.813401, 40.00139999907082],
            [-100.177797545879002, 40.001565812922415],
            [-100.19359, 40.001572999070824],
            [-100.193599056836007, 40.001573004790714],
            [-100.197807, 40.350026999068916]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "095",
        COUNTYNS: "01074060",
        AFFGEOID: "0500000US39095",
        GEOID: "39095",
        NAME: "Lucas",
        LSAD: "06",
        ALAND: 883129493,
        AWATER: 660199684
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.882943, 41.487542999063649],
            [-83.880390465353287, 41.720194489382358],
            [-83.763149851365199, 41.723546800848332],
            [-83.763038, 41.723549999062747],
            [-83.585541842108796, 41.728772027575538],
            [-83.453832, 41.732646999062723],
            [-83.409531, 41.691246999062862],
            [-83.326825, 41.701561999062825],
            [-83.23166, 41.644217999063038],
            [-83.163821417522797, 41.624130312037117],
            [-83.182375, 41.623153999063121],
            [-83.415919, 41.618937999063149],
            [-83.565729, 41.617462999063143],
            [-83.588034, 41.600949999063197],
            [-83.680084, 41.546858999063424],
            [-83.747217, 41.466215999063735],
            [-83.85426, 41.414450999063952],
            [-83.883234, 41.414502999063942],
            [-83.882943, 41.487542999063649]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "015",
        COUNTYNS: "01529156",
        AFFGEOID: "0500000US53015",
        GEOID: "53015",
        NAME: "Cowlitz",
        LSAD: "06",
        ALAND: 2953721040,
        AWATER: 67320045
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.21795, 46.38561699905793],
            [-122.240966, 46.385360999057937],
            [-122.245856, 46.053852999057455],
            [-122.357765, 45.956914999057332],
            [-122.477104, 45.988088999057368],
            [-122.657632, 45.928133999057323],
            [-122.719712, 45.933255999057309],
            [-122.719767, 45.870902999057257],
            [-122.788086362594996, 45.851007453413317],
            [-122.785026, 45.867698999057247],
            [-122.81151, 45.912724999057282],
            [-122.813998, 45.960983999057341],
            [-122.856158, 46.014468999057407],
            [-122.904119, 46.0837339990575],
            [-122.962681, 46.104816999057526],
            [-123.004233, 46.133822999057557],
            [-123.115904, 46.18526799905765],
            [-123.166414, 46.188972999057633],
            [-123.212493591208982, 46.171096457457914],
            [-123.21795, 46.38561699905793]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "115",
        COUNTYNS: "00164997",
        AFFGEOID: "0500000US01115",
        GEOID: "01115",
        NAME: "St. Clair",
        LSAD: "06",
        ALAND: 1636634176,
        AWATER: 56284614
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.577528, 33.801976999127355],
            [-86.405981, 33.835895999126933],
            [-86.345822, 33.882893999126352],
            [-86.325622, 33.940146999125631],
            [-86.290127, 33.983718999125109],
            [-86.199164, 33.988917999125036],
            [-86.142717, 33.89939099912614],
            [-86.065272, 33.842197999126853],
            [-86.043993, 33.763594999127832],
            [-86.145562, 33.679097999128899],
            [-86.193733, 33.697850999128661],
            [-86.16948, 33.619235999129657],
            [-86.235328, 33.49453199913124],
            [-86.281999, 33.509894999131042],
            [-86.378665, 33.390982999132582],
            [-86.378222, 33.502410999131151],
            [-86.481939, 33.502543999131149],
            [-86.481549, 33.546072999130587],
            [-86.516783, 33.545895999130586],
            [-86.525073, 33.721235999128368],
            [-86.542758, 33.765172999127806],
            [-86.577799, 33.765315999127807],
            [-86.577528, 33.801976999127355]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "007",
        COUNTYNS: "01135846",
        AFFGEOID: "0500000US41007",
        GEOID: "41007",
        NAME: "Clatsop",
        LSAD: "06",
        ALAND: 2145196270,
        AWATER: 661305228
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.998052, 46.235326999057719],
            [-123.912405, 46.179449999057624],
            [-123.838801, 46.192210999057643],
            [-123.757589, 46.213000999057677],
            [-123.718149, 46.188988999057649],
            [-123.660868, 46.216295999057678],
            [-123.586205, 46.228653999057698],
            [-123.547659, 46.259108999057744],
            [-123.479644, 46.269130999057758],
            [-123.427629, 46.229347999057694],
            [-123.430847, 46.181826999057634],
            [-123.371433, 46.146371999057578],
            [-123.363744507266006, 46.146243193406086],
            [-123.361622, 45.779578999057165],
            [-123.720001, 45.77307999905716],
            [-123.966278384519001, 45.783084902602454],
            [-123.961544, 45.837100999057206],
            [-123.96763, 45.907806999057279],
            [-123.993703, 45.946430999057334],
            [-123.937471, 45.977305999057364],
            [-123.92933, 46.041977999057444],
            [-123.95919, 46.141674999057564],
            [-124.041128, 46.197671999057647],
            [-123.998052, 46.235326999057719]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "073",
        COUNTYNS: "00306916",
        AFFGEOID: "0500000US12073",
        GEOID: "12073",
        NAME: "Leon",
        LSAD: "06",
        ALAND: 1727201409,
        AWATER: 90424118
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.715144, 30.333076999177031],
            [-84.646408, 30.38828099917615],
            [-84.519796, 30.451498999175129],
            [-84.405402, 30.474011999174763],
            [-84.378384, 30.573466999173178],
            [-84.285514509039487, 30.684809171119426],
            [-84.124993, 30.678036999171539],
            [-84.083753082299992, 30.675943397139566],
            [-84.007454, 30.672069967640823],
            [-83.977424, 30.522233999173999],
            [-84.041293, 30.473539999174783],
            [-84.074691, 30.43430199917541],
            [-84.075539, 30.273616999177992],
            [-84.241222, 30.274243999177983],
            [-84.285987, 30.303300999177512],
            [-84.713351, 30.300169999177562],
            [-84.715144, 30.333076999177031]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "075",
        COUNTYNS: "00295724",
        AFFGEOID: "0500000US12075",
        GEOID: "12075",
        NAME: "Levy",
        LSAD: "06",
        ALAND: 2896206276,
        AWATER: 762912636
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.165921811586401, 29.289091841840985],
            [-83.060528, 29.349456999193343],
            [-82.984371, 29.471003999191286],
            [-82.937329, 29.591195999189249],
            [-82.856518, 29.584242999189367],
            [-82.77291, 29.584878999189357],
            [-82.756458, 29.562466999189738],
            [-82.656301, 29.564810999189692],
            [-82.557565, 29.537605999190156],
            [-82.556766, 29.480054999191125],
            [-82.40662, 29.485047999191043],
            [-82.405478, 29.361096999193148],
            [-82.403237, 29.215622999195645],
            [-82.53486, 29.214679999195653],
            [-82.535591, 29.044854999198598],
            [-82.612653, 29.00927099919922],
            [-82.712039, 29.03083399919884],
            [-82.755573808695701, 29.000930117459763],
            [-82.759378, 29.006618999199262],
            [-82.759704, 29.054191999198441],
            [-82.823659, 29.098901999197658],
            [-82.798876, 29.114503999197392],
            [-82.827073, 29.158424999196633],
            [-82.927107, 29.168906999196455],
            [-82.996144, 29.178073999196297],
            [-83.016248, 29.125370999197205],
            [-83.053207, 29.13083899919711],
            [-83.078986, 29.196943999195973],
            [-83.074734, 29.247974999195097],
            [-83.107477, 29.268888999194729],
            [-83.165921811586401, 29.289091841840985]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "009",
        COUNTYNS: "01639726",
        AFFGEOID: "0500000US47009",
        GEOID: "47009",
        NAME: "Blount",
        LSAD: "06",
        ALAND: 1447309647,
        AWATER: 20304906
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.134468, 35.647398999106109],
            [-84.172812, 35.712755999105418],
            [-84.166267, 35.80510599910447],
            [-84.145659, 35.849585999103994],
            [-84.006696, 35.848923999104009],
            [-83.994677, 35.883748999103659],
            [-83.850155, 35.842018999104084],
            [-83.794275, 35.887124999103612],
            [-83.70525, 35.711579999105432],
            [-83.662814, 35.690617999105648],
            [-83.662911901589297, 35.567799792315249],
            [-83.771736, 35.562117999107002],
            [-83.848502, 35.519258999107457],
            [-83.916801, 35.473611999107945],
            [-83.953231380150399, 35.460020888684788],
            [-83.958917834563906, 35.45789943868801],
            [-83.93854, 35.48777299910779],
            [-83.993815, 35.550000999107141],
            [-84.081561, 35.541230999107228],
            [-84.188319, 35.610548999106491],
            [-84.134468, 35.647398999106109]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "079",
        COUNTYNS: "01639755",
        AFFGEOID: "0500000US47079",
        GEOID: "47079",
        NAME: "Henry",
        LSAD: "06",
        ALAND: 1455159718,
        AWATER: 81742881
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.516358059979396, 36.501464197260823],
            [-88.51192, 36.50145699909752],
            [-88.489076738713194, 36.501283718173227],
            [-88.127378, 36.498539999097545],
            [-88.053350800775888, 36.49999624977913],
            [-88.061428, 36.443991999098074],
            [-87.99092, 36.360132999098887],
            [-88.031779, 36.359808999098888],
            [-88.095868, 36.359264999098905],
            [-88.092269, 36.340314999099085],
            [-88.102109, 36.326972999099205],
            [-88.10266, 36.310932999099371],
            [-88.090782, 36.294849999099526],
            [-88.09371, 36.293027999099543],
            [-88.088851, 36.288555999099593],
            [-88.092526, 36.281032999099665],
            [-88.09167, 36.27519799909971],
            [-88.087082, 36.267897999099787],
            [-88.085167, 36.254160999099931],
            [-88.212559, 36.120289999101253],
            [-88.211698, 36.145973999100995],
            [-88.530322, 36.152022999100936],
            [-88.516358059979396, 36.501464197260823]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "009",
        COUNTYNS: "01247982",
        AFFGEOID: "0500000US45009",
        GEOID: "45009",
        NAME: "Bamberg",
        LSAD: "06",
        ALAND: 1018815541,
        AWATER: 5685855
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.222673, 33.439999999131942],
            [-80.940103, 33.303559999133711],
            [-80.887109, 33.264182999134235],
            [-80.846151, 33.238327999134576],
            [-80.797912, 33.176943999135382],
            [-80.895426, 33.125347999136046],
            [-80.896074, 33.061409999136899],
            [-80.937157, 33.102583999136364],
            [-81.082291, 33.026629999137363],
            [-81.099895, 33.048301999137088],
            [-81.193099, 33.11866799913615],
            [-81.227804, 33.160696999135588],
            [-81.222673, 33.439999999131942]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "021",
        COUNTYNS: "01639731",
        AFFGEOID: "0500000US47021",
        GEOID: "47021",
        NAME: "Cheatham",
        LSAD: "06",
        ALAND: 783276686,
        AWATER: 11964173
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.280597, 36.354165999098953],
            [-87.120443, 36.455459999097961],
            [-87.063143, 36.421414999098296],
            [-86.98806, 36.369153999098806],
            [-86.913233, 36.38262099909867],
            [-86.906254, 36.366217999098822],
            [-86.963358, 36.267003999099799],
            [-87.041362, 36.041311999102042],
            [-87.054114, 36.04553499910201],
            [-87.085365, 36.043804999102015],
            [-87.182573, 36.049725999101966],
            [-87.149876, 36.176877999100689],
            [-87.193301, 36.248968999099972],
            [-87.153043, 36.30534999909942],
            [-87.286501, 36.321932999099268],
            [-87.280597, 36.354165999098953]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "25",
        COUNTYFP: "023",
        COUNTYNS: "00606938",
        AFFGEOID: "0500000US25023",
        GEOID: "25023",
        NAME: "Plymouth",
        LSAD: "06",
        ALAND: 1706344355,
        AWATER: 1125276862
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.080483, 42.095538999061439],
            [-70.948963, 42.148155999061288],
            [-70.924877, 42.157579999061241],
            [-70.914091, 42.288799999060842],
            [-70.948497043534289, 42.28235469284315],
            [-70.91749, 42.305685999060792],
            [-70.881242, 42.300662999060798],
            [-70.851093, 42.268269999060898],
            [-70.835851775394389, 42.264763263774306],
            [-70.85338, 42.239606999060989],
            [-70.8408, 42.213117999061076],
            [-70.827, 42.20079199906111],
            [-70.785081, 42.22527599906104],
            [-70.781574545568489, 42.248636945084868],
            [-70.73056, 42.210939999061075],
            [-70.685315, 42.13302499906132],
            [-70.679237774502496, 42.126349458483837],
            [-70.63848, 42.081578999061492],
            [-70.644337, 42.045894999061602],
            [-70.678798, 42.005509999061751],
            [-70.662476, 41.960591999061897],
            [-70.608166, 41.94070099906196],
            [-70.583572, 41.950006999061934],
            [-70.546386, 41.916750999062053],
            [-70.525567, 41.858729999062255],
            [-70.54103, 41.815753999062409],
            [-70.536407254124796, 41.811634144808821],
            [-70.565363, 41.786668999062513],
            [-70.632555, 41.762903999062608],
            [-70.620785, 41.747529999062657],
            [-70.690964, 41.660456999062987],
            [-70.715979844668794, 41.614013267827367],
            [-70.761770079788405, 41.639518071333455],
            [-70.765463, 41.641574999063053],
            [-70.803962613207901, 41.601515540063502],
            [-70.839175, 41.614760999063165],
            [-70.865003, 41.700163999062831],
            [-70.88497, 41.756114999062625],
            [-70.907184, 41.763542999062594],
            [-70.921782, 41.791243999062502],
            [-71.026288, 41.77888799906254],
            [-71.014591, 41.799567999062468],
            [-71.03657, 41.816524999062402],
            [-70.973717, 41.860878999062251],
            [-70.987256, 41.905807999062091],
            [-70.993152, 41.906444999062089],
            [-70.992307, 41.90705899906208],
            [-70.991455, 41.908339999062086],
            [-70.990984, 41.910058999062073],
            [-70.991978, 41.915601999062055],
            [-70.992507, 41.916175999062048],
            [-70.993955, 41.916348999062045],
            [-70.994341, 41.915235999062062],
            [-70.994834, 41.91492299906205],
            [-70.996347, 41.915626999062056],
            [-70.997395, 41.917401999062051],
            [-70.997436, 41.918884999062044],
            [-70.996907, 41.920907999062038],
            [-70.997232, 41.923233999062035],
            [-70.995744, 41.924424999062026],
            [-70.995631, 41.925587999062031],
            [-70.995908, 41.926351999062028],
            [-70.996804, 41.927403999062008],
            [-70.997782, 41.927953999062012],
            [-70.999669, 41.92843899906201],
            [-71.000002, 41.928817999061998],
            [-70.999773, 41.929670999062004],
            [-71.054718, 41.985056999061811],
            [-71.080483, 42.095538999061439]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "087",
        COUNTYNS: "01008561",
        AFFGEOID: "0500000US37087",
        GEOID: "37087",
        NAME: "Haywood",
        LSAD: "06",
        ALAND: 1433738456,
        AWATER: 2414518
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.256141552303603, 35.715124061763191],
            [-83.255351, 35.71622999910538],
            [-83.198267, 35.725493999105289],
            [-83.161537, 35.763362999104892],
            [-83.097193, 35.776066999104756],
            [-83.04853, 35.787705999104638],
            [-82.978414, 35.782609999104693],
            [-82.966649742898596, 35.795445978678572],
            [-82.957176, 35.74684299910507],
            [-82.883909, 35.678234999105783],
            [-82.84609, 35.614308999106456],
            [-82.77055, 35.575931999106857],
            [-82.798174, 35.469804999107986],
            [-82.745139, 35.422966999108496],
            [-82.83199, 35.318342999109618],
            [-82.920881, 35.292036999109911],
            [-83.164571, 35.509570999107567],
            [-83.186322, 35.514367999107513],
            [-83.155835, 35.55262199910711],
            [-83.181977, 35.671326999105851],
            [-83.264744168290008, 35.70308949527562],
            [-83.256141552303603, 35.715124061763191]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "029",
        COUNTYNS: "01639734",
        AFFGEOID: "0500000US47029",
        GEOID: "47029",
        NAME: "Cocke",
        LSAD: "06",
        ALAND: 1125399301,
        AWATER: 22117950
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.296531, 35.904519999103435],
            [-83.276677, 36.007651999102393],
            [-83.259059, 35.999842999102462],
            [-83.234585, 36.085093999101602],
            [-83.245787, 36.124193999101216],
            [-83.175098, 36.127356999101181],
            [-83.187386, 36.174994999100704],
            [-83.166085, 36.180893999100654],
            [-83.172485, 36.157094999100885],
            [-83.102761, 36.120908999101239],
            [-82.893751418431393, 35.933857268812034],
            [-82.910608, 35.926929999103201],
            [-82.899718, 35.874601999103746],
            [-82.937437, 35.827319999104226],
            [-82.966649742898596, 35.795445978678572],
            [-82.978414, 35.782609999104693],
            [-83.04853, 35.787705999104638],
            [-83.097193, 35.776066999104756],
            [-83.161537, 35.763362999104892],
            [-83.198267, 35.725493999105289],
            [-83.255351, 35.71622999910538],
            [-83.256141552303603, 35.715124061763191],
            [-83.261806, 35.825023999104246],
            [-83.310782, 35.895845999103528],
            [-83.296531, 35.904519999103435]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "017",
        COUNTYNS: "01209173",
        AFFGEOID: "0500000US42017",
        GEOID: "42017",
        NAME: "Bucks",
        LSAD: "06",
        ALAND: 1565408735,
        AWATER: 45201371
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.409733, 40.487983999068192],
            [-75.333514, 40.53705699906795],
            [-75.258151, 40.58200899906771],
            [-75.189236401355899, 40.609056960189676],
            [-75.188199878125189, 40.592613365716268],
            [-75.186737, 40.569405999067783],
            [-75.136748, 40.575730999067744],
            [-75.078503, 40.548295999067889],
            [-75.062227, 40.481390999068225],
            [-75.070568, 40.455164999068373],
            [-75.056102, 40.416065999068572],
            [-75.024775, 40.403454999068629],
            [-74.969597, 40.399769999068646],
            [-74.946006, 40.357305999068878],
            [-74.928110569091373, 40.339828446758261],
            [-74.90331, 40.315606999069097],
            [-74.856508, 40.277406999069292],
            [-74.823907, 40.241507999069484],
            [-74.760605, 40.198908999069729],
            [-74.748497657257801, 40.184908613173796],
            [-74.721604, 40.153809999069964],
            [-74.723379297151496, 40.152895545301675],
            [-74.769488, 40.1291449990701],
            [-74.825907, 40.123909999070122],
            [-74.863809, 40.082209999070358],
            [-74.932211, 40.068410999070437],
            [-74.972854756445599, 40.046505364606865],
            [-74.980688, 40.065006999070462],
            [-74.955777, 40.095102999070292],
            [-75.015066, 40.137991999070067],
            [-75.243171, 40.274394999069315],
            [-75.484057, 40.418450999068554],
            [-75.409733, 40.487983999068192]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "019",
        COUNTYNS: "01252740",
        AFFGEOID: "0500000US45019",
        GEOID: "45019",
        NAME: "Charleston",
        LSAD: "06",
        ALAND: 2376245593,
        AWATER: 1141008039
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.51408, 32.513896999144343],
            [-80.408529, 32.502780999144498],
            [-80.406851, 32.507752999144422],
            [-80.407846, 32.523447999144217],
            [-80.411927, 32.534079999144062],
            [-80.409735, 32.536989999144012],
            [-80.418903, 32.543063999143946],
            [-80.421662, 32.54714299914388],
            [-80.422201, 32.553488999143795],
            [-80.420442, 32.558047999143724],
            [-80.418467, 32.559926999143705],
            [-80.409699, 32.564392999143642],
            [-80.405802, 32.568309999143587],
            [-80.401039, 32.570233999143561],
            [-80.394654, 32.569520999143577],
            [-80.393325, 32.575270999143484],
            [-80.398138, 32.582398999143386],
            [-80.400003, 32.587806999143318],
            [-80.399621, 32.596100999143196],
            [-80.398401, 32.599696999143148],
            [-80.394528, 32.60569899914308],
            [-80.38854, 32.612753999142967],
            [-80.389215, 32.616236999142927],
            [-80.393114, 32.617819999142895],
            [-80.395903, 32.616972999142916],
            [-80.407357, 32.610147999143003],
            [-80.4151, 32.604948999143076],
            [-80.418971, 32.603299999143104],
            [-80.426009, 32.603735999143098],
            [-80.424087, 32.605293999143072],
            [-80.426983, 32.608753999143026],
            [-80.428633, 32.613542999142972],
            [-80.429065, 32.623601999142821],
            [-80.389401, 32.652819999142416],
            [-80.45363, 32.73989999914123],
            [-80.40134, 32.858465999139618],
            [-80.292076, 32.848859999139755],
            [-80.170103, 32.820931999140136],
            [-80.148484, 32.819033999140167],
            [-80.185697, 32.860806999139591],
            [-80.097125, 32.886824999139236],
            [-80.100675, 32.946032999138453],
            [-80.176286, 33.010041999137592],
            [-80.149246, 33.021600999137434],
            [-80.055462, 32.999511999137724],
            [-80.023318, 32.921433999138777],
            [-79.985703, 32.904208999139016],
            [-79.950448, 32.910173999138919],
            [-79.919202, 32.818990999140155],
            [-79.914307, 32.818891999140149],
            [-79.897279, 32.858765999139621],
            [-79.828611, 32.918964999138801],
            [-79.642577, 33.124122999136077],
            [-79.518844, 33.148296999135752],
            [-79.446699, 33.213457999134889],
            [-79.350816, 33.153527999135697],
            [-79.269396, 33.136882999135906],
            [-79.274494584642795, 33.120056266856523],
            [-79.291591, 33.109772999136268],
            [-79.329909, 33.089985999136523],
            [-79.339313, 33.050335999137062],
            [-79.359961, 33.006671999137637],
            [-79.423447, 33.015084999137528],
            [-79.483499, 33.001264999137703],
            [-79.522449, 33.03534999913726],
            [-79.580725, 33.006446999137644],
            [-79.606615, 32.972247999138098],
            [-79.569762, 32.926691999138711],
            [-79.601309, 32.898149999139093],
            [-79.695141, 32.850397999139737],
            [-79.726389, 32.805995999140343],
            [-79.818237, 32.766351999140866],
            [-79.868352, 32.734848999141299],
            [-79.884961, 32.684401999141983],
            [-79.968468, 32.639731999142597],
            [-80.000801, 32.605891999143061],
            [-80.077039, 32.603318999143106],
            [-80.148406, 32.578478999143449],
            [-80.190108, 32.546840999143882],
            [-80.246361, 32.531113999144104],
            [-80.249442426227802, 32.529359328337421],
            [-80.338354, 32.478729999144832],
            [-80.403276592771391, 32.472202406551624],
            [-80.413505, 32.471173999144931],
            [-80.46571, 32.495299999144606],
            [-80.472293124695099, 32.483348913724868],
            [-80.507975, 32.505206999144455],
            [-80.51408, 32.513896999144343]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "039",
        COUNTYNS: "01639739",
        AFFGEOID: "0500000US47039",
        GEOID: "47039",
        NAME: "Decatur",
        LSAD: "06",
        ALAND: 864738981,
        AWATER: 28503793
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.243057, 35.528857999107359],
            [-88.190465, 35.609087999106499],
            [-88.179177, 35.816784999104328],
            [-88.177859, 35.845840999104034],
            [-88.051635, 35.849917999103987],
            [-87.970738, 35.815704999104355],
            [-88.034788, 35.717260999105363],
            [-88.035837, 35.621838999106373],
            [-87.97463, 35.521024999107453],
            [-88.042332, 35.444616999108263],
            [-88.007709, 35.423380999108488],
            [-87.97366, 35.404957999108689],
            [-88.024741, 35.392306999108818],
            [-88.196462, 35.379560999108953],
            [-88.241677, 35.423262999108495],
            [-88.247243, 35.43929599910831],
            [-88.243057, 35.528857999107359]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "071",
        COUNTYNS: "00295758",
        AFFGEOID: "0500000US12071",
        GEOID: "12071",
        NAME: "Lee",
        LSAD: "06",
        ALAND: 2030703925,
        AWATER: 1109326687
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.280541277628799, 26.789308585683344],
            [-82.205562, 26.77052799924062],
            [-81.931098, 26.770048999240633],
            [-81.565931, 26.769551999240633],
            [-81.565095, 26.600332999243953],
            [-81.563763, 26.513323999245671],
            [-81.56218, 26.422624999247471],
            [-81.659506, 26.421076999247504],
            [-81.6579, 26.317562999249567],
            [-81.746167, 26.316895999249581],
            [-81.819019, 26.316244999249591],
            [-81.846485247961695, 26.33037204775211],
            [-81.923611, 26.436657999247192],
            [-81.956611, 26.452357999246885],
            [-82.013913, 26.452057999246886],
            [-82.075015, 26.422058999247486],
            [-82.126671, 26.4362789992472],
            [-82.180717, 26.476256999246402],
            [-82.245395, 26.601093999243929],
            [-82.264351, 26.698495999242027],
            [-82.264682, 26.756835999240888],
            [-82.280541277628799, 26.789308585683344]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "121",
        COUNTYNS: "00485025",
        AFFGEOID: "0500000US20121",
        GEOID: "20121",
        NAME: "Miami",
        LSAD: "06",
        ALAND: 1490955581,
        AWATER: 37798751
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.065772, 38.694902999079169],
            [-95.056412, 38.738586999078869],
            [-94.908606, 38.738280999078874],
            [-94.609489672345802, 38.73810170855208],
            [-94.611958018322298, 38.547634433351938],
            [-94.612865595922102, 38.477602179315852],
            [-94.612866, 38.477570999080754],
            [-94.612772628992886, 38.388718481813214],
            [-95.065831, 38.389944999081408],
            [-95.065772, 38.694902999079169]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "285",
        COUNTYNS: "01673298",
        AFFGEOID: "0500000US13285",
        GEOID: "13285",
        NAME: "Troup",
        LSAD: "06",
        ALAND: 1072272397,
        AWATER: 82763611
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.236595371252776, 33.129544027386707],
            [-85.117402, 33.163653999135555],
            [-85.10798, 33.195097999135143],
            [-85.088036, 33.171700999135439],
            [-84.939015, 33.224692999134746],
            [-84.862135, 33.223857999134765],
            [-84.862359, 33.191172999135183],
            [-84.861768, 32.872494999139434],
            [-84.881702, 32.868760999139504],
            [-85.18611740178919, 32.870138269111074],
            [-85.232441, 33.108074999136285],
            [-85.232441405187288, 33.108077093069895],
            [-85.236595371252776, 33.129544027386707]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "167",
        COUNTYNS: "00450398",
        AFFGEOID: "0500000US18167",
        GEOID: "18167",
        NAME: "Vigo",
        LSAD: "06",
        ALAND: 1044993095,
        AWATER: 18068475
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.600397, 39.312903999074983],
            [-87.578331, 39.340342999074814],
            [-87.531646, 39.347887999074764],
            [-87.531624, 39.469377999073984],
            [-87.531666681974301, 39.477110991816829],
            [-87.532385282791012, 39.607304943294629],
            [-87.382418, 39.607936999073132],
            [-87.199012, 39.607136999073141],
            [-87.238964, 39.520619999073674],
            [-87.240379, 39.259063999075323],
            [-87.59474584540601, 39.259383615558342],
            [-87.600397, 39.312903999074983]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "025",
        COUNTYNS: "00351605",
        AFFGEOID: "0500000US13025",
        GEOID: "13025",
        NAME: "Brantley",
        LSAD: "06",
        ALAND: 1145708458,
        AWATER: 12540408
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.284561, 31.224448999163087],
            [-82.193031, 31.202119999163422],
            [-82.114822, 31.270354999162379],
            [-81.997421, 31.288533999162112],
            [-82.041129, 31.373720999160817],
            [-81.923238, 31.345874999161243],
            [-81.801052, 31.363736999160963],
            [-81.731694, 31.330047999161486],
            [-81.782363, 31.168147999163946],
            [-81.766322, 31.169594999163909],
            [-81.840671, 31.097664999165016],
            [-81.936749, 31.060786999165583],
            [-81.936006, 31.047992999165775],
            [-82.06087, 31.07580899916535],
            [-82.081207, 31.010629999166358],
            [-82.131698, 31.010713999166349],
            [-82.208262, 31.084767999165205],
            [-82.208732, 31.170937999163897],
            [-82.284561, 31.224448999163087]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "001",
        COUNTYNS: "01480091",
        AFFGEOID: "0500000US51001",
        GEOID: "51001",
        NAME: "Accomack",
        LSAD: "06",
        ALAND: 1163743170,
        AWATER: 2229242744
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.941182, 37.56383899908797],
            [-75.898665, 37.635424999087377],
            [-75.859262, 37.70311099908681],
            [-75.812155, 37.749501999086426],
            [-75.818125, 37.79169799908609],
            [-75.73588, 37.816560999085887],
            [-75.702914, 37.849658999085605],
            [-75.757694, 37.903911999085175],
            [-75.669711, 37.950795999084804],
            [-75.624341, 37.994210999084459],
            [-75.242266, 38.027208999084195],
            [-75.338623, 37.89498599908525],
            [-75.380638, 37.851701999085591],
            [-75.439001, 37.869334999085453],
            [-75.48927, 37.832456999085757],
            [-75.551898, 37.748121999086429],
            [-75.591953, 37.663177999087132],
            [-75.614527, 37.609295999087585],
            [-75.607824, 37.560705999087986],
            [-75.672877, 37.483695999088646],
            [-75.665417910875092, 37.467293514581293],
            [-75.713275, 37.449875999088924],
            [-75.763912, 37.463307999088826],
            [-75.776564, 37.454588999088898],
            [-75.812793, 37.473894999088721],
            [-75.793521, 37.488836999088598],
            [-75.804797, 37.514725999088384],
            [-75.788012, 37.528815999088266],
            [-75.835214, 37.554244999088056],
            [-75.90041, 37.557264999088027],
            [-75.945574318930682, 37.5490406572398],
            [-75.941182, 37.56383899908797]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "029",
        COUNTYNS: "00350496",
        AFFGEOID: "0500000US13029",
        GEOID: "13029",
        NAME: "Bryan",
        LSAD: "06",
        ALAND: 1130182689,
        AWATER: 46852821
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.780858, 32.152889999149409],
            [-81.43583, 32.241288999148161],
            [-81.406776, 32.101930999150134],
            [-81.391698, 32.095885999150227],
            [-81.290323, 31.978820999151914],
            [-81.138429, 31.855716999153692],
            [-81.177027, 31.816112999154274],
            [-81.154731, 31.713069999155774],
            [-81.259887, 31.75375399915518],
            [-81.355837, 31.814899999154299],
            [-81.407737, 31.944301999152405],
            [-81.475761, 31.974254999151974],
            [-81.593429, 31.971349999152014],
            [-81.603665, 32.008468999151475],
            [-81.718658, 32.089350999150319],
            [-81.75634, 32.104583999150101],
            [-81.780858, 32.152889999149409]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "289",
        COUNTYNS: "01383930",
        AFFGEOID: "0500000US48289",
        GEOID: "48289",
        NAME: "Leon",
        LSAD: "06",
        ALAND: 2779462014,
        AWATER: 19328383
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.319165, 31.35712899916107],
            [-96.322853, 31.374066999160817],
            [-96.236629, 31.413391999160226],
            [-95.7873, 31.618384999157172],
            [-95.735681, 31.653821999156651],
            [-95.710112, 31.615586999157212],
            [-95.739279, 31.504055999158869],
            [-95.746837, 31.458169999159555],
            [-95.711092, 31.454132999159615],
            [-95.657247, 31.33744799916137],
            [-95.725226, 31.271083999162368],
            [-95.728703, 31.164063999164],
            [-95.775578, 31.136094999164424],
            [-95.76441, 31.094210999165067],
            [-95.975218, 31.091966999165102],
            [-96.24102, 30.973736999166924],
            [-96.243258, 31.06113699916558],
            [-96.260594, 31.077070999165336],
            [-96.269199, 31.077854999165318],
            [-96.331083, 31.252711999162649],
            [-96.319165, 31.35712899916107]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "047",
        COUNTYNS: "00277288",
        AFFGEOID: "0500000US06047",
        GEOID: "06047",
        NAME: "Merced",
        LSAD: "06",
        ALAND: 5012175320,
        AWATER: 112509475
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.226804, 37.134773999091685],
            [-120.963812, 37.346143999089826],
            [-120.989811, 37.395870999089396],
            [-120.38767, 37.633363999087393],
            [-120.177636, 37.261525999090559],
            [-120.052055, 37.183107999091241],
            [-120.106385, 37.167152999091385],
            [-120.227286, 37.163399999091432],
            [-120.476692, 37.096389999092018],
            [-120.541696, 37.044504999092482],
            [-120.590566, 36.952640999093319],
            [-120.65595, 36.95283199909332],
            [-120.918731, 36.740380999095265],
            [-121.141523, 36.836655999094376],
            [-121.234683, 36.926893999093544],
            [-121.215406, 36.961247999093239],
            [-121.245887, 36.983035999093026],
            [-121.226804, 37.134773999091685]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "007",
        COUNTYNS: "00395090",
        AFFGEOID: "0500000US16007",
        GEOID: "16007",
        NAME: "Bear Lake",
        LSAD: "06",
        ALAND: 2527122948,
        AWATER: 191364283
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.626104, 42.207541999061092],
            [-111.552151, 42.351385999060646],
            [-111.599227, 42.41539499906046],
            [-111.61763, 42.583036999059978],
            [-111.368062, 42.590293999059973],
            [-111.279388, 42.552860999060066],
            [-111.241099, 42.602456999059946],
            [-111.234827, 42.478328999060274],
            [-111.171174, 42.473199999060284],
            [-111.045530292713011, 42.513912876835271],
            [-111.04708, 42.349419999060643],
            [-111.046689, 42.001566999061758],
            [-111.374129, 42.000892999061762],
            [-111.471381, 41.999738999061755],
            [-111.50780619568701, 41.999685677426058],
            [-111.507813035175985, 41.999685667413971],
            [-111.521577, 42.074436999061518],
            [-111.599116, 42.099005999061433],
            [-111.626104, 42.207541999061092]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "031",
        COUNTYNS: "00350302",
        AFFGEOID: "0500000US13031",
        GEOID: "13031",
        NAME: "Bulloch",
        LSAD: "06",
        ALAND: 1743101866,
        AWATER: 40429683
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.001236, 32.606909999143056],
            [-81.841005, 32.649092999142468],
            [-81.82785, 32.652796999142431],
            [-81.689643, 32.546289999143895],
            [-81.548006, 32.489285999144684],
            [-81.534904, 32.39357999914602],
            [-81.446334, 32.284716999147548],
            [-81.43583, 32.241288999148161],
            [-81.780858, 32.152889999149409],
            [-81.81628, 32.237469999148217],
            [-81.969069, 32.268782999147767],
            [-81.918652, 32.415072999145714],
            [-82.030233, 32.538769999144002],
            [-82.001236, 32.606909999143056]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "033",
        COUNTYNS: "00347944",
        AFFGEOID: "0500000US13033",
        GEOID: "13033",
        NAME: "Burke",
        LSAD: "06",
        ALAND: 2141993539,
        AWATER: 20601185
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.272353, 32.937795999138558],
            [-82.29118, 33.062826999136888],
            [-82.232554, 33.231210999134674],
            [-82.26758, 33.267396999134192],
            [-82.17424, 33.296770999133805],
            [-82.097135, 33.230611999134673],
            [-81.846500532357894, 33.247252084564359],
            [-81.846536, 33.24174599913453],
            [-81.763535, 33.203647999135029],
            [-81.762505982795787, 33.197265887432408],
            [-81.755135, 33.15154999913571],
            [-81.658433, 33.103151999136351],
            [-81.615955710789095, 33.089338538500542],
            [-81.601655, 33.084687999136598],
            [-81.543966224313891, 33.044399953281527],
            [-81.767553, 32.909410999138942],
            [-81.857985, 32.953881999138346],
            [-82.081349, 32.916761999138842],
            [-82.144977, 32.812740999140239],
            [-82.213745, 32.803862999140364],
            [-82.316512, 32.835771999139929],
            [-82.272353, 32.937795999138558]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "117",
        COUNTYNS: "01074071",
        AFFGEOID: "0500000US39117",
        GEOID: "39117",
        NAME: "Morrow",
        LSAD: "06",
        ALAND: 1051881049,
        AWATER: 2819351
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.958401, 40.490663999068182],
            [-82.957715, 40.635652999067446],
            [-82.859753, 40.64639499906739],
            [-82.858302, 40.705018999067114],
            [-82.838905, 40.712599999067073],
            [-82.727164, 40.711202999067083],
            [-82.689113, 40.711032999067079],
            [-82.627191, 40.70941599906709],
            [-82.644977, 40.666424999067303],
            [-82.623609, 40.549878999067879],
            [-82.646503, 40.345075999068932],
            [-82.744931, 40.349602999068907],
            [-82.929588, 40.358122999068861],
            [-82.924781, 40.41500399906856],
            [-83.020798, 40.433794999068468],
            [-82.958401, 40.490663999068182]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "153",
        COUNTYNS: "00485040",
        AFFGEOID: "0500000US20153",
        GEOID: "20153",
        NAME: "Rawlins",
        LSAD: "06",
        ALAND: 2769775729,
        AWATER: 415527
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.411028987801998, 40.002582568638402],
            [-101.325514027519006, 40.002565347301299],
            [-101.293991, 40.002558999070821],
            [-101.060317, 40.002306999070811],
            [-100.75883, 40.002301999070816],
            [-100.738824713191008, 40.002262955638507],
            [-100.740873, 39.56804299907337],
            [-101.389068, 39.568368999073378],
            [-101.413913, 39.56836099907337],
            [-101.411028987801998, 40.002582568638402]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "139",
        COUNTYNS: "00465258",
        AFFGEOID: "0500000US19139",
        GEOID: "19139",
        NAME: "Muscatine",
        LSAD: "06",
        ALAND: 1132977189,
        AWATER: 30262373
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.368521, 41.423177999063903],
            [-91.366448, 41.598372999063223],
            [-90.899853, 41.59713299906322],
            [-90.783812, 41.596530999063233],
            [-90.786282, 41.452887999063783],
            [-90.867282, 41.448214999063815],
            [-90.924343, 41.422859999063917],
            [-90.966662, 41.430050999063894],
            [-91.027787, 41.42360299906391],
            [-91.065058, 41.369100999064138],
            [-91.071552, 41.339650999064247],
            [-91.074087623674089, 41.334320800984784],
            [-91.074415498019604, 41.333631568104977],
            [-91.369332, 41.336062999064261],
            [-91.368521, 41.423177999063903]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "163",
        COUNTYNS: "00465270",
        AFFGEOID: "0500000US19163",
        GEOID: "19163",
        NAME: "Scott",
        LSAD: "06",
        ALAND: 1186437866,
        AWATER: 26222175
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.898373, 41.771391999062558],
            [-90.685383, 41.757204999062616],
            [-90.471723, 41.773602999062561],
            [-90.311856850416177, 41.728532891216346],
            [-90.314687, 41.694829999062847],
            [-90.336729, 41.664531999062973],
            [-90.339528, 41.59863299906322],
            [-90.364128, 41.5796329990633],
            [-90.41583, 41.562932999063364],
            [-90.461432, 41.523532999063505],
            [-90.513134, 41.519532999063522],
            [-90.571136, 41.516331999063546],
            [-90.618537, 41.485031999063658],
            [-90.701159, 41.454742999063789],
            [-90.786282, 41.452887999063783],
            [-90.783812, 41.596530999063233],
            [-90.899853, 41.59713299906322],
            [-90.898373, 41.771391999062558]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "007",
        COUNTYNS: "00857664",
        AFFGEOID: "0500000US32007",
        GEOID: "32007",
        NAME: "Elko",
        LSAD: "06",
        ALAND: 44468067633,
        AWATER: 85736268
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.018203216741, 41.999839849430856],
            [-116.625947, 41.997378999061773],
            [-116.332763, 41.997282999061774],
            [-115.870181, 41.996765999061779],
            [-115.625914, 41.997414999061775],
            [-115.313877, 41.996102999061776],
            [-115.038109752433996, 41.998634114738259],
            [-114.89921, 41.999908999061759],
            [-114.598267, 41.994510999061781],
            [-114.281855, 41.994213999061792],
            [-114.281799467219997, 41.994213884819679],
            [-114.041723, 41.993719999061781],
            [-114.039901, 41.753780999062634],
            [-114.040231, 41.491689999063631],
            [-114.041447, 41.207751999064811],
            [-114.042145, 40.999925999065731],
            [-114.042145146235001, 40.999896576329022],
            [-114.043505, 40.726291999067008],
            [-114.045577, 40.495800999068159],
            [-114.046178, 40.398312999068665],
            [-114.046372817372998, 40.116935832082369],
            [-114.251633, 40.116689999070168],
            [-115.263729, 40.122542999070134],
            [-115.834969, 40.1278419990701],
            [-116.000897, 40.127375999070111],
            [-116.157834, 40.666388999067294],
            [-116.158155, 40.999911999065723],
            [-116.586786, 41.000397999065719],
            [-117.018419, 41.00025399906572],
            [-117.018203216741, 41.999839849430856]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "081",
        COUNTYNS: "01550047",
        AFFGEOID: "0500000US54081",
        GEOID: "54081",
        NAME: "Raleigh",
        LSAD: "06",
        ALAND: 1567866394,
        AWATER: 10334430
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.571534, 37.92770699908499],
            [-81.456632, 37.987306999084517],
            [-81.438531, 37.968007999084669],
            [-81.380829, 37.969108999084661],
            [-81.34733, 37.976708999084593],
            [-81.295127, 37.889310999085289],
            [-81.073249, 37.875864999085408],
            [-81.057286, 37.835536999085718],
            [-80.94509, 37.819120999085861],
            [-80.877729, 37.685602999086953],
            [-80.947017, 37.715448999086703],
            [-81.068774, 37.639822999087336],
            [-81.09463, 37.588657999087765],
            [-81.198869, 37.575532999087869],
            [-81.222121, 37.510415999088416],
            [-81.315123, 37.58841399908777],
            [-81.458027, 37.784210999086149],
            [-81.514228, 37.79121099908609],
            [-81.571534, 37.92770699908499]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "037",
        COUNTYNS: "00882236",
        AFFGEOID: "0500000US34037",
        GEOID: "34037",
        NAME: "Sussex",
        LSAD: "06",
        ALAND: 1343552956,
        AWATER: 43234734
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.979873, 41.110422999065229],
            [-74.905256, 41.155667999065024],
            [-74.867405, 41.227769999064733],
            [-74.815703, 41.29615099906443],
            [-74.760325, 41.340324999064251],
            [-74.694914, 41.357422999064177],
            [-74.457584, 41.248224999064632],
            [-74.367038261447391, 41.204211471508629],
            [-74.503212, 41.085872999065344],
            [-74.535808, 41.058485999065461],
            [-74.641044, 40.964425999065888],
            [-74.636163, 40.942638999065984],
            [-74.711562, 40.899176999066192],
            [-74.769425, 40.910933999066124],
            [-74.790408, 40.930434999066037],
            [-74.962665, 41.090526999065325],
            [-74.983042074247791, 41.106017557922051],
            [-74.979873, 41.110422999065229]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "049",
        COUNTYNS: "00933322",
        AFFGEOID: "0500000US35049",
        GEOID: "35049",
        NAME: "Santa Fe",
        LSAD: "06",
        ALAND: 4941709205,
        AWATER: 3537550
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.250499, 35.755817999104963],
            [-106.171852, 35.827759999104224],
            [-106.248257, 35.839177999104102],
            [-106.245649, 35.930741999103162],
            [-106.054346, 35.930782999103165],
            [-106.071153, 36.00157299910245],
            [-105.716808, 36.00231999910244],
            [-105.718611, 35.979462999102672],
            [-105.724461, 35.871193999103774],
            [-105.714419, 35.041604999112671],
            [-106.194713, 35.040255999112702],
            [-106.243874, 35.040002999112694],
            [-106.244216, 35.215656999110749],
            [-106.245162, 35.238660999110493],
            [-106.248848, 35.443281999108272],
            [-106.250499, 35.755817999104963]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "057",
        COUNTYNS: "00695752",
        AFFGEOID: "0500000US28057",
        GEOID: "28057",
        NAME: "Itawamba",
        LSAD: "06",
        ALAND: 1379925849,
        AWATER: 19832711
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.542079, 34.465275999119285],
            [-88.329193, 34.463570999119327],
            [-88.154902184008691, 34.463034299559325],
            [-88.173261734594902, 34.321039533455298],
            [-88.203583495219377, 34.086527730591932],
            [-88.543352, 34.08904399912381],
            [-88.542079, 34.465275999119285]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "111",
        COUNTYNS: "00758510",
        AFFGEOID: "0500000US29111",
        GEOID: "29111",
        NAME: "Lewis",
        LSAD: "06",
        ALAND: 1308052634,
        AWATER: 14901556
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.947241, 40.213798999069631],
            [-91.950812, 40.257209999069403],
            [-91.496957490339483, 40.248703875442253],
            [-91.506167908622274, 40.200643511864811],
            [-91.511956, 40.170440999069875],
            [-91.497663, 40.078256999070398],
            [-91.484064, 40.019331999070708],
            [-91.43709, 39.946416999071133],
            [-91.436843268599091, 39.94524346274963],
            [-91.840353, 39.948234999071119],
            [-91.952726, 39.949425999071103],
            [-91.947241, 40.213798999069631]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "021",
        COUNTYNS: "01711211",
        AFFGEOID: "0500000US24021",
        GEOID: "24021",
        NAME: "Frederick",
        LSAD: "06",
        ALAND: 1710553993,
        AWATER: 18042333
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.677695762078685, 39.317940816162952],
            [-77.656177, 39.368396999074641],
            [-77.622604, 39.507597999073752],
            [-77.570182, 39.61999799907305],
            [-77.469145003680396, 39.720229001322451],
            [-77.45943341952389, 39.720229170717438],
            [-77.23995, 39.720232999072458],
            [-77.217023755237392, 39.720217247457853],
            [-77.307416, 39.619100999073069],
            [-77.198024, 39.575429999073329],
            [-77.107711, 39.494555999073839],
            [-77.16808, 39.353956999074725],
            [-77.168801, 39.353501999074723],
            [-77.262529, 39.310279999075],
            [-77.285719, 39.299376999075072],
            [-77.46006562649778, 39.218842942934991],
            [-77.496606, 39.251044999075383],
            [-77.553114, 39.279267999075202],
            [-77.588235, 39.301954999075043],
            [-77.66613, 39.317007999074953],
            [-77.677695762078685, 39.317940816162952]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "135",
        COUNTYNS: "00659513",
        AFFGEOID: "0500000US27135",
        GEOID: "27135",
        NAME: "Roseau",
        LSAD: "06",
        ALAND: 4329471930,
        AWATER: 16913419
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.405408, 48.999983999066167],
            [-95.97539, 48.999983999066167],
            [-95.340962, 48.998739999066153],
            [-95.319888783472194, 48.99875834307656],
            [-95.30104, 48.90421599906572],
            [-95.235606, 48.881449999065616],
            [-95.091464, 48.92061799906579],
            [-95.089863, 48.713061999064884],
            [-95.34273, 48.713027999064884],
            [-95.34254, 48.540210999064143],
            [-95.602315, 48.538886999064133],
            [-96.387835, 48.544307999064181],
            [-96.404924, 48.717069999064897],
            [-96.405408, 48.999983999066167]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "017",
        COUNTYNS: "00915980",
        AFFGEOID: "0500000US35017",
        GEOID: "35017",
        NAME: "Grant",
        LSAD: "06",
        ALAND: 10259427302,
        AWATER: 15346268
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.047236980537008, 33.20889503068576],
            [-108.543178, 33.207954999134969],
            [-108.449988, 33.20099699913505],
            [-108.000598, 33.201252999135058],
            [-107.858292, 33.200878999135064],
            [-107.836192, 33.067762999136825],
            [-107.856672, 33.002538999137684],
            [-107.780548, 32.95277599913836],
            [-107.773088, 32.853774999139695],
            [-107.721387, 32.778076999140723],
            [-107.736949, 32.629493999142746],
            [-107.608485, 32.605448999143078],
            [-107.923997, 32.60437899914308],
            [-107.923766, 32.517415999144283],
            [-108.229343, 32.516836999144296],
            [-108.229951, 32.079869999150453],
            [-108.217143, 31.864138999153571],
            [-108.524538, 31.863613999153575],
            [-108.537676, 32.07983199915045],
            [-108.537011, 32.516617999144302],
            [-108.64677, 32.516520999144298],
            [-108.646523, 32.603729999143091],
            [-108.852919, 32.603373999143102],
            [-108.854581, 32.777275999140727],
            [-109.047117062309994, 32.77779400008842],
            [-109.047236980537008, 33.20889503068576]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "041",
        COUNTYNS: "01101808",
        AFFGEOID: "0500000US40041",
        GEOID: "40041",
        NAME: "Delaware",
        LSAD: "06",
        ALAND: 1911798952,
        AWATER: 140253641
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.00573, 36.509889999097446],
            [-95.00569, 36.597659999096599],
            [-94.999403, 36.670630999095927],
            [-94.617991778417192, 36.667921271440157],
            [-94.617815, 36.612603999096464],
            [-94.617919, 36.499413999097541],
            [-94.5862, 36.29996899909947],
            [-94.562267928552785, 36.161972613727237],
            [-94.797279, 36.161389999100841],
            [-95.011303, 36.161814999100848],
            [-95.00573, 36.509889999097446]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "047",
        COUNTYNS: "01448037",
        AFFGEOID: "0500000US49047",
        GEOID: "49047",
        NAME: "Uintah",
        LSAD: "06",
        ALAND: 11609319530,
        AWATER: 56115772
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.037952, 39.566666999073384],
            [-109.962143, 39.72588499907242],
            [-109.88308, 39.806235999071944],
            [-109.976814, 39.806229999071945],
            [-109.976402, 40.809685999066602],
            [-109.870207, 40.763828999066831],
            [-109.525495, 40.826369999066529],
            [-109.484332, 40.740431999066935],
            [-109.394465, 40.791031999066696],
            [-109.394491, 40.858165999066372],
            [-109.20325, 40.858400999066383],
            [-109.163425, 40.785954999066725],
            [-109.161063, 40.683816999067211],
            [-109.048259750048004, 40.662601816295322],
            [-109.048249, 40.653600999067365],
            [-109.050732112077, 40.22265525732719],
            [-109.050973, 40.180848999069816],
            [-109.050615, 39.874969999071538],
            [-109.050871925262015, 39.660471776232214],
            [-109.051066846272988, 39.497738808440012],
            [-109.106972, 39.461978999074034],
            [-109.953349, 39.461800999074036],
            [-110.023963, 39.469267999073985],
            [-110.037952, 39.566666999073384]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "063",
        COUNTYNS: "00395624",
        AFFGEOID: "0500000US16063",
        GEOID: "16063",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 3111451190,
        AWATER: 11606076
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.594605, 43.198344999058527],
            [-114.374962, 43.199639999058519],
            [-113.714642, 43.199782999058527],
            [-113.714044, 42.849733999059296],
            [-113.763862, 42.764507999059511],
            [-113.931687, 42.76502599905951],
            [-114.049961, 42.765005999059518],
            [-114.049849, 42.808443999059406],
            [-114.345659, 42.809054999059406],
            [-114.365366, 42.850789999059295],
            [-114.594375, 42.850900999059292],
            [-114.594605, 43.198344999058527]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "077",
        COUNTYNS: "00308549",
        AFFGEOID: "0500000US12077",
        GEOID: "12077",
        NAME: "Liberty",
        LSAD: "06",
        ALAND: 2164099093,
        AWATER: 19582444
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.11566, 30.199975999179181],
            [-85.03429, 30.312477999177361],
            [-85.04277, 30.352465999176719],
            [-84.983203, 30.441836999175283],
            [-84.990017, 30.52479499917396],
            [-84.932519, 30.606392999172662],
            [-84.883316, 30.605588999172678],
            [-84.882616, 30.533093999173829],
            [-84.781813, 30.518692999174053],
            [-84.781111, 30.459793999175002],
            [-84.646408, 30.38828099917615],
            [-84.715144, 30.333076999177031],
            [-84.713351, 30.300169999177562],
            [-84.670364, 30.128207999180347],
            [-84.591048, 30.029636999181964],
            [-84.54445, 30.01102999918227],
            [-85.007561, 30.013850999182218],
            [-85.025439, 29.971398999182917],
            [-85.113691, 30.023172999182073],
            [-85.154015, 30.09204299918094],
            [-85.11566, 30.199975999179181]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "103",
        COUNTYNS: "00295745",
        AFFGEOID: "0500000US12103",
        GEOID: "12103",
        NAME: "Pinellas",
        LSAD: "06",
        ALAND: 709243774,
        AWATER: 865801424
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.859384956032898, 28.172175073818643],
            [-82.651165, 28.173265999214117],
            [-82.650602, 28.14492799921463],
            [-82.648817, 27.979177999217669],
            [-82.573419, 27.90899999921897],
            [-82.566380424636094, 27.836338311134512],
            [-82.586519, 27.816702999220674],
            [-82.622723, 27.779867999221356],
            [-82.62502, 27.73270599922224],
            [-82.652521, 27.700306999222839],
            [-82.705017, 27.625309999224246],
            [-82.733076, 27.612971999224488],
            [-82.738467, 27.678503999223249],
            [-82.746223, 27.731305999222268],
            [-82.790224, 27.791602999221148],
            [-82.846526, 27.854300999219983],
            [-82.840882, 27.937161999218453],
            [-82.828354689216383, 28.01887958087384],
            [-82.828163, 28.02012999921692],
            [-82.850881, 28.102450999215414],
            [-82.859384956032898, 28.172175073818643]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "033",
        COUNTYNS: "00277281",
        AFFGEOID: "0500000US06033",
        GEOID: "06033",
        NAME: "Lake",
        LSAD: "06",
        ALAND: 3254348631,
        AWATER: 188852161
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.077812, 39.173790999075898],
            [-122.994839, 39.235923999075482],
            [-123.075114, 39.407682999074382],
            [-123.063181, 39.503538999073768],
            [-122.89031, 39.529013999073612],
            [-122.885352, 39.580107999073306],
            [-122.735639, 39.580667999073292],
            [-122.739062, 39.383265999074531],
            [-122.78509, 39.382974999074527],
            [-122.743482, 39.368129999074633],
            [-122.775043, 39.311777999074991],
            [-122.680927, 39.238725999075463],
            [-122.47729, 39.173949999075894],
            [-122.491278, 39.052990999076691],
            [-122.413416, 39.02021799907692],
            [-122.409118, 38.962792999077308],
            [-122.340172, 38.924245999077577],
            [-122.403941, 38.925288999077566],
            [-122.421904, 38.90376999907771],
            [-122.395056, 38.864244999077982],
            [-122.397989, 38.804000999078411],
            [-122.46389, 38.705202999079106],
            [-122.627396, 38.667505999079367],
            [-122.77248, 38.82152999907828],
            [-122.821592, 38.850139999078088],
            [-122.948865, 38.900219999077741],
            [-122.98664, 38.997282999077065],
            [-123.056212, 39.02109599907692],
            [-123.056115, 39.048814999076733],
            [-123.077812, 39.173790999075898]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "129",
        COUNTYNS: "00306912",
        AFFGEOID: "0500000US12129",
        GEOID: "12129",
        NAME: "Wakulla",
        LSAD: "06",
        ALAND: 1570625279,
        AWATER: 334901690
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.713351, 30.300169999177562],
            [-84.285987, 30.303300999177512],
            [-84.241222, 30.274243999177983],
            [-84.075539, 30.273616999177992],
            [-84.076132071911402, 30.099089882910931],
            [-84.124889, 30.090600999180964],
            [-84.179149, 30.073186999181246],
            [-84.20801, 30.084775999181062],
            [-84.289727, 30.057196999181514],
            [-84.366115, 30.008661999182308],
            [-84.341439, 29.962207999183082],
            [-84.341148833181592, 29.960756335288501],
            [-84.467713, 30.001852999182415],
            [-84.523992, 29.981727999182763],
            [-84.54445, 30.01102999918227],
            [-84.591048, 30.029636999181964],
            [-84.670364, 30.128207999180347],
            [-84.713351, 30.300169999177562]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "007",
        COUNTYNS: "00342832",
        AFFGEOID: "0500000US13007",
        GEOID: "13007",
        NAME: "Baker",
        LSAD: "06",
        ALAND: 885665382,
        AWATER: 18598655
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.627579, 31.33211699916145],
            [-84.637579, 31.433925999159918],
            [-84.513503, 31.439606999159832],
            [-84.447875, 31.454742999159606],
            [-84.429876, 31.436660999159873],
            [-84.211593, 31.435855999159891],
            [-84.141124, 31.440178999159823],
            [-84.20066, 31.355524999161101],
            [-84.340552, 31.305340999161853],
            [-84.40435, 31.199142999163463],
            [-84.471001, 31.167980999163934],
            [-84.508078, 31.07839999916531],
            [-84.542653, 31.079028999165292],
            [-84.537101, 31.255931999162602],
            [-84.641673, 31.258966999162553],
            [-84.627579, 31.33211699916145]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "087",
        COUNTYNS: "00352234",
        AFFGEOID: "0500000US13087",
        GEOID: "13087",
        NAME: "Decatur",
        LSAD: "06",
        ALAND: 1546667779,
        AWATER: 67329727
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.863463844382494, 30.711497013825415],
            [-84.78692, 30.8011889991696],
            [-84.730855, 31.069189999165445],
            [-84.644678, 31.079503999165293],
            [-84.542653, 31.079028999165292],
            [-84.508078, 31.07839999916531],
            [-84.376612, 31.078882999165291],
            [-84.380754183552497, 30.688827196217272],
            [-84.474519, 30.692782999171303],
            [-84.812997, 30.709649999171038],
            [-84.863463844382494, 30.711497013825415]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "275",
        COUNTYNS: "00343835",
        AFFGEOID: "0500000US13275",
        GEOID: "13275",
        NAME: "Thomas",
        LSAD: "06",
        ALAND: 1410520995,
        AWATER: 19717343
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.116644, 31.077970999165313],
            [-84.003627, 31.07728999916533],
            [-84.003876, 31.041581999165878],
            [-83.736158, 31.037678999165934],
            [-83.743729, 30.658526912922351],
            [-83.820973, 30.662602999171778],
            [-84.007454, 30.672069967640823],
            [-84.083753082299992, 30.675943397139566],
            [-84.075958, 30.912537999167871],
            [-84.119058, 30.98095599916681],
            [-84.116644, 31.077970999165313]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "315",
        COUNTYNS: "00345499",
        AFFGEOID: "0500000US13315",
        GEOID: "13315",
        NAME: "Wilcox",
        LSAD: "06",
        ALAND: 978282707,
        AWATER: 11390302
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.609663, 32.027937999151213],
            [-83.608547, 32.118404999149895],
            [-83.361051, 32.124008999149822],
            [-83.337313, 32.105884999150085],
            [-83.295206, 32.079179999150476],
            [-83.292061, 31.984505999151828],
            [-83.205739, 31.900324999153042],
            [-83.204236, 31.884681999153255],
            [-83.177469, 31.847856999153802],
            [-83.190422, 31.849394999153777],
            [-83.480176, 31.847311999153803],
            [-83.54164, 31.853101999153729],
            [-83.612256, 31.854088999153717],
            [-83.609663, 32.027937999151213]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "113",
        COUNTYNS: "00711758",
        AFFGEOID: "0500000US28113",
        GEOID: "28113",
        NAME: "Pike",
        LSAD: "06",
        ALAND: 1059350502,
        AWATER: 4341105
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.548199, 31.349573999161187],
            [-90.260391, 31.350273999161178],
            [-90.259554940491299, 31.000661780395607],
            [-90.346007, 31.000362999166519],
            [-90.347241080662101, 31.000360667147309],
            [-90.547574425233094, 30.99998210095811],
            [-90.548199, 31.349573999161187]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "013",
        COUNTYNS: "00695731",
        AFFGEOID: "0500000US28013",
        GEOID: "28013",
        NAME: "Calhoun",
        LSAD: "06",
        ALAND: 1519218202,
        AWATER: 3590395
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.509748, 34.162081999122925],
            [-89.245485, 34.161185999122928],
            [-89.244436, 34.073941999123988],
            [-89.139158, 34.074115999123983],
            [-89.137876, 33.81214399912723],
            [-89.19004, 33.811709999127238],
            [-89.191781, 33.73860199912815],
            [-89.216469, 33.721978999128353],
            [-89.507135, 33.721818999128359],
            [-89.507353, 33.867468999126537],
            [-89.509748, 34.162081999122925]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "061",
        COUNTYNS: "00695754",
        AFFGEOID: "0500000US28061",
        GEOID: "28061",
        NAME: "Jasper",
        LSAD: "06",
        ALAND: 1751451429,
        AWATER: 3181592
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.322692, 32.223471999148408],
            [-88.913847, 32.224185999148403],
            [-88.910459, 31.826648999154116],
            [-88.943355, 31.824564999154145],
            [-89.31656, 31.802089999154475],
            [-89.322692, 32.223471999148408]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "037",
        COUNTYNS: "00695743",
        AFFGEOID: "0500000US28037",
        GEOID: "28037",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1460658781,
        AWATER: 7250805
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.153864, 31.610067999157298],
            [-90.73733, 31.611123999157282],
            [-90.633231, 31.61140899915727],
            [-90.633302, 31.349305999161192],
            [-91.018012, 31.347501999161228],
            [-91.065741, 31.338897999161347],
            [-91.095398, 31.320974999161617],
            [-91.145181, 31.347534999161212],
            [-91.15815, 31.346694999161222],
            [-91.153864, 31.610067999157298]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "041",
        COUNTYNS: "01531927",
        AFFGEOID: "0500000US53041",
        GEOID: "53041",
        NAME: "Lewis",
        LSAD: "06",
        ALAND: 6223151001,
        AWATER: 86954130
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.37095, 46.792128999058704],
            [-123.351391, 46.793508999058716],
            [-123.160588, 46.793382999058714],
            [-123.160909, 46.764329999058639],
            [-122.203115, 46.763060999058645],
            [-121.84189, 46.728454999058577],
            [-121.758593, 46.783790999058688],
            [-121.455218, 46.783796999058694],
            [-121.353562, 46.711505999058538],
            [-121.451256, 46.533893999058201],
            [-121.393734, 46.390203999057952],
            [-121.522324, 46.388223999057949],
            [-122.240966, 46.385360999057937],
            [-123.21795, 46.38561699905793],
            [-123.259068, 46.383457999057931],
            [-123.358334, 46.384024999057942],
            [-123.37095, 46.792128999058704]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "093",
        COUNTYNS: "01804527",
        AFFGEOID: "0500000US72093",
        GEOID: "72093",
        NAME: "Maricao",
        LSAD: "13",
        ALAND: 94851792,
        AWATER: 12487
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.019836, 18.195470999439628],
            [-66.97838, 18.207915999439301],
            [-66.897964, 18.187743999439832],
            [-66.863991, 18.186747999439859],
            [-66.83676, 18.170552999440293],
            [-66.85678, 18.138589999441145],
            [-66.926517, 18.151266999440804],
            [-66.977855, 18.143798999441],
            [-67.013844, 18.165873999440418],
            [-67.051461, 18.1740529994402],
            [-67.019836, 18.195470999439628]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "115",
        COUNTYNS: "01804538",
        AFFGEOID: "0500000US72115",
        GEOID: "72115",
        NAME: "Quebradillas",
        LSAD: "13",
        ALAND: 58747685,
        AWATER: 30224116
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.956315951859992, 18.493900428302055],
            [-66.924089, 18.487266999431924],
            [-66.901566758551709, 18.488261256866789],
            [-66.8989, 18.366107999435116],
            [-66.92202, 18.393203999434402],
            [-66.947333, 18.401595999434178],
            [-66.956315951859992, 18.493900428302055]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "009",
        COUNTYNS: "01266997",
        AFFGEOID: "0500000US46009",
        GEOID: "46009",
        NAME: "Bon Homme",
        LSAD: "06",
        ALAND: 1459866820,
        AWATER: 46518944
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.152586654838188, 42.841153380123011],
            [-98.117047, 42.869385999059254],
            [-98.109534, 43.087476999058758],
            [-98.077108, 43.168329999058585],
            [-97.637496, 43.168769999058583],
            [-97.635442090441501, 42.851809074244791],
            [-97.70103, 42.843796999059315],
            [-97.801344, 42.858002999059281],
            [-97.857957, 42.865092999059272],
            [-97.876887, 42.852662999059298],
            [-97.905001, 42.798871999059422],
            [-97.950147, 42.769618999059496],
            [-98.035034, 42.764204999059501],
            [-98.1047, 42.80847499905942],
            [-98.14806, 42.840012999059333],
            [-98.152586654838188, 42.841153380123011]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "035",
        COUNTYNS: "01247998",
        AFFGEOID: "0500000US45035",
        GEOID: "45035",
        NAME: "Dorchester",
        LSAD: "06",
        ALAND: 1484540310,
        AWATER: 6724254
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.790296, 33.180839999135323],
            [-80.50279, 33.334495999133324],
            [-80.484578, 33.280033999134034],
            [-80.361851, 33.257442999134319],
            [-80.297577, 33.181837999135311],
            [-80.330057, 33.148503999135741],
            [-80.279567, 33.119212999136145],
            [-80.244542, 33.093868999136468],
            [-80.149246, 33.021600999137434],
            [-80.176286, 33.010041999137592],
            [-80.100675, 32.946032999138453],
            [-80.097125, 32.886824999139236],
            [-80.185697, 32.860806999139591],
            [-80.148484, 32.819033999140167],
            [-80.170103, 32.820931999140136],
            [-80.292076, 32.848859999139755],
            [-80.40134, 32.858465999139618],
            [-80.418813, 32.967263999138154],
            [-80.390533, 33.043908999137138],
            [-80.474788, 33.064393999136868],
            [-80.6181, 33.064753999136862],
            [-80.706085, 33.154545999135664],
            [-80.790296, 33.180839999135323]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "021",
        COUNTYNS: "01247987",
        AFFGEOID: "0500000US45021",
        GEOID: "45021",
        NAME: "Cherokee",
        LSAD: "06",
        ALAND: 1017382887,
        AWATER: 11701702
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.87411220431629, 35.183509907137399],
            [-81.768134407773886, 35.179707705495041],
            [-81.494265, 35.169881999111254],
            [-81.367604910676405, 35.164092160315811],
            [-81.409574, 35.046414999112621],
            [-81.487016, 35.034852999112736],
            [-81.492108, 34.948955999113714],
            [-81.457257, 34.839279999114957],
            [-81.481945, 34.86649499911465],
            [-81.711694, 34.912576999114137],
            [-81.754911, 34.9326719991139],
            [-81.805985, 35.055551999112524],
            [-81.87411220431629, 35.183509907137399]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "025",
        COUNTYNS: "01247989",
        AFFGEOID: "0500000US45025",
        GEOID: "45025",
        NAME: "Chesterfield",
        LSAD: "06",
        ALAND: 2069379207,
        AWATER: 17250030
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.561657, 34.817480999115212],
            [-80.320832571129785, 34.81362082065926],
            [-80.077223, 34.809715999115298],
            [-79.927398038771997, 34.807862589746321],
            [-79.871959, 34.693840999116624],
            [-79.777461, 34.613491999117556],
            [-79.829981, 34.531924999118516],
            [-79.884402, 34.49140399911898],
            [-80.045439, 34.492630999118965],
            [-80.288596, 34.366206999120465],
            [-80.285538, 34.393677999120136],
            [-80.32759, 34.497759999118905],
            [-80.408373, 34.61476499911754],
            [-80.488532, 34.704583999116501],
            [-80.561708549382402, 34.817482399451706],
            [-80.561657, 34.817480999115212]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "047",
        COUNTYNS: "01265787",
        AFFGEOID: "0500000US46047",
        GEOID: "46047",
        NAME: "Fall River",
        LSAD: "06",
        ALAND: 4506227705,
        AWATER: 24080933
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.054684568427007, 43.477815540769029],
            [-103.935373, 43.478823999058008],
            [-103.000913, 43.476847999058016],
            [-103.000609526033003, 43.000259285833359],
            [-103.476133, 43.000767999058951],
            [-103.505099261631997, 43.000758812090147],
            [-104.053127, 43.000584999058944],
            [-104.053876, 43.289800999058343],
            [-104.054684568427007, 43.477815540769029]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "071",
        COUNTYNS: "01639751",
        AFFGEOID: "0500000US47071",
        GEOID: "47071",
        NAME: "Hardin",
        LSAD: "06",
        ALAND: 1495264133,
        AWATER: 49126297
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.374926, 35.304030999109784],
            [-88.362572, 35.380993999108945],
            [-88.360829, 35.418971999108528],
            [-88.258409, 35.416688999108551],
            [-88.241677, 35.423262999108495],
            [-88.196462, 35.379560999108953],
            [-88.024741, 35.392306999108818],
            [-87.981585, 35.295677999109863],
            [-87.984916043698902, 35.005910123438888],
            [-88.000032, 35.005938999113063],
            [-88.202959, 35.008027999113047],
            [-88.200064, 34.995633999113196],
            [-88.258111, 34.995462999113194],
            [-88.363530268539179, 34.995746750910293],
            [-88.380492511522078, 34.995792407335287],
            [-88.374926, 35.304030999109784]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "059",
        COUNTYNS: "01265783",
        AFFGEOID: "0500000US46059",
        GEOID: "46059",
        NAME: "Hand",
        LSAD: "06",
        ALAND: 3720897248,
        AWATER: 9241423
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.311754, 44.897226999056763],
            [-98.716498, 44.896924999056758],
            [-98.705381, 44.880528999056764],
            [-98.705762, 44.634158999056815],
            [-98.700453, 44.196726999057084],
            [-98.925953, 44.196574999057077],
            [-99.30018, 44.194829999057092],
            [-99.300495, 44.547379999056858],
            [-99.311754, 44.897226999056763]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "163",
        COUNTYNS: "01639793",
        AFFGEOID: "0500000US47163",
        GEOID: "47163",
        NAME: "Sullivan",
        LSAD: "06",
        ALAND: 1070732761,
        AWATER: 42201523
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.702279, 36.455498999097962],
            [-82.610175, 36.519299999097356],
            [-82.609181949398987, 36.595089685165135],
            [-82.487238, 36.595821999096628],
            [-82.294136420129092, 36.595073032055133],
            [-82.243385005200196, 36.594876186762036],
            [-82.173982, 36.594606999096627],
            [-82.145573270514504, 36.594560329930751],
            [-81.934144, 36.594212999096634],
            [-81.922644, 36.616212999096419],
            [-81.826734148052608, 36.614719103257841],
            [-81.871443, 36.602014999096568],
            [-81.983843, 36.50831399909746],
            [-82.188653, 36.400407999098491],
            [-82.299859, 36.396504999098539],
            [-82.421572, 36.447253999098038],
            [-82.601474, 36.423199999098273],
            [-82.680677, 36.431799999098196],
            [-82.702279, 36.455498999097962]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "031",
        COUNTYNS: "01247992",
        AFFGEOID: "0500000US45031",
        GEOID: "45031",
        NAME: "Darlington",
        LSAD: "06",
        ALAND: 1453370732,
        AWATER: 14647308
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.288596, 34.366206999120465],
            [-80.045439, 34.492630999118965],
            [-79.884402, 34.49140399911898],
            [-79.829981, 34.531924999118516],
            [-79.752769, 34.51405899911871],
            [-79.750566, 34.436796999119629],
            [-79.657764, 34.30518999912119],
            [-79.724782, 34.296987999121299],
            [-79.749383, 34.245052999121924],
            [-79.809021, 34.252437999121831],
            [-80.072808, 34.086012999123852],
            [-80.158582, 34.161622999122926],
            [-80.112727, 34.197017999122487],
            [-80.177225, 34.366202999120468],
            [-80.243226, 34.324454999120974],
            [-80.288692, 34.364138999120506],
            [-80.288596, 34.366206999120465]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "081",
        COUNTYNS: "01266996",
        AFFGEOID: "0500000US46081",
        GEOID: "46081",
        NAME: "Lawrence",
        LSAD: "06",
        ALAND: 2072127374,
        AWATER: 667509
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.055695199260995, 44.570990376199049],
            [-103.820639, 44.604776999056824],
            [-103.567653, 44.604097999056826],
            [-103.574396, 44.256076999057044],
            [-103.453016, 44.256608999057043],
            [-103.452453, 44.140771999057144],
            [-104.055416190705003, 44.14108120584055],
            [-104.055406377827012, 44.180382873152794],
            [-104.055389, 44.249982999057039],
            [-104.055695199260995, 44.570990376199049]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "085",
        COUNTYNS: "01639757",
        AFFGEOID: "0500000US47085",
        GEOID: "47085",
        NAME: "Humphreys",
        LSAD: "06",
        ALAND: 1374668747,
        AWATER: 67184107
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.02239, 35.965817999102811],
            [-87.919043, 36.13004899910117],
            [-87.949603, 36.243159999100037],
            [-87.798508, 36.24475399910002],
            [-87.58581, 36.197333999100493],
            [-87.566798, 36.177856999100662],
            [-87.552874, 36.016236999102297],
            [-87.53453, 35.993073999102535],
            [-87.706438, 35.905754999103429],
            [-87.71676, 35.83874999910411],
            [-87.852682, 35.80379899910448],
            [-87.96274, 35.840920999104092],
            [-87.925835, 35.930431999103178],
            [-88.02239, 35.965817999102811]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "089",
        COUNTYNS: "00465233",
        AFFGEOID: "0500000US19089",
        GEOID: "19089",
        NAME: "Howard",
        LSAD: "06",
        ALAND: 1225706339,
        AWATER: 927963
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.553128090518783, 43.500300035370273],
            [-92.448947951437773, 43.50041498762068],
            [-92.178863, 43.500712999057974],
            [-92.079801993128271, 43.500704884234572],
            [-92.080976, 43.212946999058502],
            [-92.554381, 43.212812999058499],
            [-92.553128090518783, 43.500300035370273]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "037",
        COUNTYNS: "00344074",
        AFFGEOID: "0500000US13037",
        GEOID: "13037",
        NAME: "Calhoun",
        LSAD: "06",
        ALAND: 726147254,
        AWATER: 8315719
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.80796, 31.514382999158713],
            [-84.817843, 31.619770999157161],
            [-84.59715, 31.637612999156882],
            [-84.546854, 31.621198999157127],
            [-84.476994, 31.621166999157126],
            [-84.450398, 31.621867999157125],
            [-84.447032, 31.605080999157376],
            [-84.454923, 31.548330999158207],
            [-84.412521, 31.45692999915957],
            [-84.429876, 31.436660999159873],
            [-84.447875, 31.454742999159606],
            [-84.513503, 31.439606999159832],
            [-84.637579, 31.433925999159918],
            [-84.789032, 31.43635599915989],
            [-84.819807, 31.50119199915892],
            [-84.80796, 31.514382999158713]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "093",
        COUNTYNS: "00465235",
        AFFGEOID: "0500000US19093",
        GEOID: "19093",
        NAME: "Ida",
        LSAD: "06",
        ALAND: 1117599859,
        AWATER: 1406461
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.741611, 42.561284999060042],
            [-95.38801, 42.56174199906004],
            [-95.387445, 42.474795999060284],
            [-95.322357, 42.474736999060291],
            [-95.323497, 42.210931999061074],
            [-95.670822, 42.211407999061088],
            [-95.669265, 42.474644999060295],
            [-95.741821, 42.474864999060294],
            [-95.741611, 42.561284999060042]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "051",
        COUNTYNS: "01497376",
        AFFGEOID: "0500000US51051",
        GEOID: "51051",
        NAME: "Dickenson",
        LSAD: "06",
        ALAND: 855845425,
        AWATER: 8097296
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.553639544903675, 37.201450373553797],
            [-82.449164, 37.243907999090716],
            [-82.355343, 37.265219999090533],
            [-82.314370452929595, 37.296306250945655],
            [-82.293007, 37.305995999090186],
            [-82.157889, 37.110571999091889],
            [-82.149375, 37.0416409990925],
            [-82.284965, 36.998428999092901],
            [-82.32783, 36.970981999093141],
            [-82.361944, 36.955714999093281],
            [-82.467345, 37.022135999092683],
            [-82.546135, 37.125117999091763],
            [-82.553639544903675, 37.201450373553797]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "133",
        COUNTYNS: "01784865",
        AFFGEOID: "0500000US17133",
        GEOID: "17133",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 997207732,
        AWATER: 33717847
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.372519, 38.323353999081895],
            [-90.349743, 38.377608999081502],
            [-90.342915266737194, 38.384427319109051],
            [-90.340244281704301, 38.387094621717722],
            [-90.288815, 38.438452999081051],
            [-90.271314, 38.496051999080613],
            [-90.260976059287003, 38.518526786488252],
            [-90.036018, 38.308849999082021],
            [-89.903937, 38.297202999082096],
            [-89.899061, 38.220754999082693],
            [-90.036385, 38.22312499908265],
            [-90.035999, 38.135629999083342],
            [-90.205728625882699, 38.088233182192305],
            [-90.218708, 38.094364999083666],
            [-90.252484, 38.127570999083403],
            [-90.2527463239757, 38.127773825312701],
            [-90.322353, 38.181592999082987],
            [-90.351164145060295, 38.219544456177196],
            [-90.363926, 38.236354999082572],
            [-90.372519, 38.323353999081895]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "361",
        COUNTYNS: "01383966",
        AFFGEOID: "0500000US48361",
        GEOID: "48361",
        NAME: "Orange",
        LSAD: "06",
        ALAND: 864489134,
        AWATER: 118488356
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.117604, 30.2416649991785],
            [-93.901452, 30.242671999178494],
            [-93.711062171300895, 30.243970714818769],
            [-93.713359, 30.225260999178779],
            [-93.703764, 30.173935999179605],
            [-93.702436, 30.112720999180599],
            [-93.70394, 30.054290999181557],
            [-93.706335762470189, 30.052180240712495],
            [-93.741078, 30.021570999182099],
            [-93.807815, 29.954548999183192],
            [-93.830374, 29.894358999184181],
            [-93.852309870922298, 29.872091003576461],
            [-93.841416, 29.949300999183276],
            [-93.860169, 29.977396999182819],
            [-94.031976, 30.028012999181989],
            [-94.093539, 30.083683999181076],
            [-94.115917, 30.160346999179829],
            [-94.091217, 30.204647999179102],
            [-94.117604, 30.2416649991785]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "097",
        COUNTYNS: "00295748",
        AFFGEOID: "0500000US12097",
        GEOID: "12097",
        NAME: "Osceola",
        LSAD: "06",
        ALAND: 3438742026,
        AWATER: 461876563
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.657268, 28.347097999210959],
            [-81.309571, 28.348050999210948],
            [-81.094674, 28.348104999210943],
            [-80.862908, 28.34748699921095],
            [-80.868881, 27.822521999220559],
            [-80.881172, 27.809169999220817],
            [-80.87315, 27.642287999223928],
            [-81.142164, 27.643237999223913],
            [-81.145954, 27.71053299922265],
            [-81.208124, 27.82112499922059],
            [-81.301862, 27.862080999219838],
            [-81.308696, 27.921879999218724],
            [-81.386988, 28.003618999217224],
            [-81.459687, 28.05180899921633],
            [-81.378718, 28.011742999217073],
            [-81.346418, 28.084695999215725],
            [-81.455758, 28.084889999215726],
            [-81.456214, 28.143162999214663],
            [-81.524227, 28.142804999214675],
            [-81.557459, 28.259361999212548],
            [-81.657173, 28.264512999212457],
            [-81.657268, 28.347097999210959]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "367",
        COUNTYNS: "01383969",
        AFFGEOID: "0500000US48367",
        GEOID: "48367",
        NAME: "Parker",
        LSAD: "06",
        ALAND: 2340006395,
        AWATER: 17127713
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.056094, 33.003331999137686],
            [-97.921642, 33.001283999137705],
            [-97.679565, 32.996372999137776],
            [-97.544181, 32.994176999137807],
            [-97.550582, 32.555390999143768],
            [-97.617066, 32.555483999143767],
            [-98.066836, 32.558821999143731],
            [-98.056094, 33.003331999137686]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "039",
        COUNTYNS: "00277284",
        AFFGEOID: "0500000US06039",
        GEOID: "06039",
        NAME: "Madera",
        LSAD: "06",
        ALAND: 5534850492,
        AWATER: 42012923
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.476692, 37.096389999092018],
            [-120.227286, 37.163399999091432],
            [-120.106385, 37.167152999091385],
            [-120.052055, 37.183107999091241],
            [-119.761809, 37.417113999089217],
            [-119.651191, 37.417831999089209],
            [-119.651172, 37.461350999088843],
            [-119.58422, 37.494695999088549],
            [-119.583585, 37.560334999088006],
            [-119.308995, 37.777985999086198],
            [-119.268979, 37.739229999086518],
            [-119.124312, 37.733943999086549],
            [-119.022363, 37.585736999087786],
            [-119.286658, 37.374942999089576],
            [-119.335189, 37.310773999090117],
            [-119.330573, 37.205790999091043],
            [-119.36275, 37.167200999091385],
            [-119.43306, 37.161515999091442],
            [-119.470844, 37.110547999091899],
            [-119.506733, 37.150553999091535],
            [-119.567234, 37.115945999091849],
            [-119.562979, 37.064094999092312],
            [-119.604908, 37.071015999092239],
            [-119.621116, 37.02660499909264],
            [-119.705356, 36.999793999092894],
            [-119.713182, 36.991637999092958],
            [-119.814421, 36.850137999094258],
            [-119.932338, 36.843322999094312],
            [-120.028143, 36.814484999094567],
            [-120.057776, 36.822859999094497],
            [-120.229179, 36.769686999094986],
            [-120.370256, 36.784939999094853],
            [-120.456246, 36.862862999094126],
            [-120.450341, 36.911171999093696],
            [-120.541696, 37.044504999092482],
            [-120.476692, 37.096389999092018]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "081",
        COUNTYNS: "01501122",
        AFFGEOID: "0500000US51081",
        GEOID: "51081",
        NAME: "Greensville",
        LSAD: "06",
        ALAND: 764634553,
        AWATER: 4086108
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.767103969926694, 36.545442408432599],
            [-77.655841, 36.713538999095519],
            [-77.658036, 36.894513999093853],
            [-77.619029, 36.877843999093983],
            [-77.51036, 36.84508199909429],
            [-77.458678, 36.862962999094137],
            [-77.489999, 36.721008999095439],
            [-77.430127, 36.707657999095574],
            [-77.501917, 36.670505999095909],
            [-77.414985, 36.654068999096076],
            [-77.355111, 36.554121999097021],
            [-77.298770188811787, 36.546039009951599],
            [-77.749706, 36.545519999097102],
            [-77.767103969926694, 36.545442408432599]
          ],
          [
            [-77.556524, 36.674873999095865],
            [-77.525725, 36.672830999095886],
            [-77.509246, 36.682997999095797],
            [-77.51445, 36.720587999095443],
            [-77.560198, 36.706686999095574],
            [-77.556524, 36.674873999095865]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "161",
        COUNTYNS: "00424282",
        AFFGEOID: "0500000US17161",
        GEOID: "17161",
        NAME: "Rock Island",
        LSAD: "06",
        ALAND: 1107115477,
        AWATER: 61715041
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.071552, 41.339650999064247],
            [-91.065058, 41.369100999064138],
            [-91.027787, 41.42360299906391],
            [-90.966662, 41.430050999063894],
            [-90.924343, 41.422859999063917],
            [-90.867282, 41.448214999063815],
            [-90.786282, 41.452887999063783],
            [-90.701159, 41.454742999063789],
            [-90.618537, 41.485031999063658],
            [-90.571136, 41.516331999063546],
            [-90.513134, 41.519532999063522],
            [-90.461432, 41.523532999063505],
            [-90.41583, 41.562932999063364],
            [-90.364128, 41.5796329990633],
            [-90.339528, 41.59863299906322],
            [-90.336729, 41.664531999062973],
            [-90.314687, 41.694829999062847],
            [-90.311856850416177, 41.728532891216346],
            [-90.310708, 41.742213999062663],
            [-90.248631, 41.779804999062534],
            [-90.242368247397195, 41.78276872504712],
            [-90.229749, 41.675374999062917],
            [-90.161233, 41.643926999063048],
            [-90.185609, 41.584652999063273],
            [-90.196535, 41.540125999063441],
            [-90.332657, 41.514000999063548],
            [-90.43193, 41.456833999063782],
            [-90.433771, 41.326982999064306],
            [-91.074087623674089, 41.334320800984784],
            [-91.071552, 41.339650999064247]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "377",
        COUNTYNS: "01383974",
        AFFGEOID: "0500000US48377",
        GEOID: "48377",
        NAME: "Presidio",
        LSAD: "06",
        ALAND: 9985057446,
        AWATER: 1773188
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.980753891324994, 30.628812017960616],
            [-103.90101, 30.412189999175759],
            [-103.80068, 30.412526999175746],
            [-103.792793, 29.500187999190786],
            [-103.793873010933993, 29.259238392543498],
            [-103.856893, 29.281851999194505],
            [-103.975235, 29.296016999194261],
            [-104.055596, 29.330909999193672],
            [-104.143692, 29.383277999192771],
            [-104.181273, 29.426264999192039],
            [-104.229081, 29.481049999191111],
            [-104.308813, 29.524336999190378],
            [-104.371175, 29.543062999190067],
            [-104.452301, 29.603659999189038],
            [-104.539761, 29.676073999187835],
            [-104.565688, 29.770461999186249],
            [-104.619039, 29.844444999185015],
            [-104.672327, 29.911111999183913],
            [-104.685479, 29.989942999182617],
            [-104.703998, 30.024209999182052],
            [-104.692094, 30.107303999180694],
            [-104.702788, 30.211735999178988],
            [-104.740448, 30.259453999178216],
            [-104.761634, 30.301147999177548],
            [-104.824314, 30.370465999176432],
            [-104.859521, 30.390412999176117],
            [-104.869872, 30.458644999175014],
            [-104.889376, 30.535143999173798],
            [-104.924796, 30.604831999172681],
            [-104.972071, 30.610259999172612],
            [-104.980753891324994, 30.628812017960616]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "083",
        COUNTYNS: "01496705",
        AFFGEOID: "0500000US51083",
        GEOID: "51083",
        NAME: "Halifax",
        LSAD: "06",
        ALAND: 2117910304,
        AWATER: 30527401
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.218455495922285, 36.541444535273243],
            [-79.092264, 37.061497999092339],
            [-78.971675, 37.050059999092419],
            [-78.904587, 37.022287999092676],
            [-78.892702, 36.976693999093087],
            [-78.794862, 36.955987999093288],
            [-78.743891, 37.005438999092831],
            [-78.749727, 36.920064999093618],
            [-78.671463, 36.857950999094172],
            [-78.648541, 36.697908999095652],
            [-78.54923, 36.62662899909634],
            [-78.653551, 36.690849999095732],
            [-78.711702, 36.64107799909619],
            [-78.734122801117195, 36.541608733937935],
            [-78.796273955140492, 36.541759492678743],
            [-78.942009, 36.542112999097135],
            [-79.138335898871404, 36.54163826915304],
            [-79.218455495922285, 36.541444535273243]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "115",
        COUNTYNS: "00424256",
        AFFGEOID: "0500000US17115",
        GEOID: "17115",
        NAME: "Macon",
        LSAD: "06",
        ALAND: 1503973504,
        AWATER: 13409564
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.217846, 39.916989999071298],
            [-89.143457, 39.917919999071302],
            [-89.144764, 40.048852999070562],
            [-88.745164, 40.055190999070511],
            [-88.745671, 39.792145999072034],
            [-88.812709, 39.740485999072334],
            [-88.810575, 39.653221999072855],
            [-89.02568, 39.654182999072844],
            [-89.139125, 39.655130999072846],
            [-89.141817, 39.800909999071962],
            [-89.217523, 39.813223999071894],
            [-89.217846, 39.916989999071298]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "015",
        COUNTYNS: "00484977",
        AFFGEOID: "0500000US20015",
        GEOID: "20015",
        NAME: "Butler",
        LSAD: "06",
        ALAND: 3703330875,
        AWATER: 43157337
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.152476, 37.912732999085108],
            [-97.152913, 38.087703999083722],
            [-96.840772, 38.085621999083735],
            [-96.522782, 38.086369999083729],
            [-96.5253, 37.60701499908761],
            [-96.52569, 37.476404999088707],
            [-97.153331, 37.475543999088714],
            [-97.152476, 37.912732999085108]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "083",
        COUNTYNS: "00559481",
        AFFGEOID: "0500000US22083",
        GEOID: "22083",
        NAME: "Richland",
        LSAD: "15",
        ALAND: 1448537069,
        AWATER: 13551678
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.994208, 32.401093999145914],
            [-91.913057, 32.505901999144449],
            [-91.839532, 32.518481999144271],
            [-91.80432, 32.571315999143543],
            [-91.742286, 32.558905999143718],
            [-91.637587, 32.667340999142219],
            [-91.596027, 32.582191999143397],
            [-91.445299, 32.581955999143396],
            [-91.457643, 32.53780699914401],
            [-91.485222, 32.502923999144492],
            [-91.476739, 32.405807999145843],
            [-91.634883, 32.405252999145866],
            [-91.764379, 32.330017999146897],
            [-91.775019, 32.24333299914813],
            [-91.894729, 32.151549999149438],
            [-91.980603, 32.166633999149219],
            [-91.993367, 32.276794999147654],
            [-92.034397, 32.277067999147654],
            [-91.994208, 32.401093999145914]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "103",
        COUNTYNS: "01719563",
        AFFGEOID: "0500000US30103",
        GEOID: "30103",
        NAME: "Treasure",
        LSAD: "06",
        ALAND: 2532492080,
        AWATER: 17149961
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.738548, 46.481805999058096],
            [-107.174531, 46.482712999058108],
            [-107.153444, 46.39561299905796],
            [-107.028422, 46.395459999057962],
            [-107.007394, 46.305901999057816],
            [-107.007394, 46.134197999057569],
            [-106.939067, 46.136666999057574],
            [-106.939095, 45.870040999057245],
            [-107.052159, 45.870051999057253],
            [-107.051946, 45.913488999057293],
            [-107.17636, 45.913322999057293],
            [-107.176358, 45.956760999057344],
            [-107.425128, 45.956673999057344],
            [-107.424929, 46.043373999057444],
            [-107.511534, 46.043402999057442],
            [-107.466131, 46.177773999057635],
            [-107.612411, 46.351556999057891],
            [-107.738548, 46.481805999058096]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "095",
        COUNTYNS: "01480135",
        AFFGEOID: "0500000US51095",
        GEOID: "51095",
        NAME: "James City",
        LSAD: "06",
        ALAND: 368881354,
        AWATER: 95089229
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.891798, 37.432631999089082],
            [-76.741984, 37.467075999088784],
            [-76.712305, 37.43130899908909],
            [-76.658651, 37.380138999089525],
            [-76.755401, 37.354384999089753],
            [-76.727859, 37.305926999090161],
            [-76.740465, 37.305736999090179],
            [-76.741143, 37.273379999090452],
            [-76.664516, 37.25058599909066],
            [-76.680151, 37.265996999090525],
            [-76.601115, 37.238192999090764],
            [-76.591577, 37.213832999090982],
            [-76.581218, 37.182510999091257],
            [-76.641581, 37.153826999091514],
            [-76.660445, 37.204145999091068],
            [-76.760314, 37.176798999091297],
            [-76.79774, 37.214086999090981],
            [-76.883156, 37.223056999090893],
            [-76.874437, 37.365426999089657],
            [-76.904471, 37.377664999089561],
            [-76.891798, 37.432631999089082]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "449",
        COUNTYNS: "01384010",
        AFFGEOID: "0500000US48449",
        GEOID: "48449",
        NAME: "Titus",
        LSAD: "06",
        ALAND: 1051683607,
        AWATER: 50727822
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.125451, 33.389453999132606],
            [-94.81271, 33.37557699913279],
            [-94.808783, 33.363635999132939],
            [-94.819828, 32.982885999137956],
            [-94.936129, 33.073832999136748],
            [-95.12613, 33.034580999137262],
            [-95.125451, 33.389453999132606]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "065",
        COUNTYNS: "01265774",
        AFFGEOID: "0500000US46065",
        GEOID: "46065",
        NAME: "Hughes",
        LSAD: "06",
        ALAND: 1920594002,
        AWATER: 153254264
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.526498, 44.547421999056866],
            [-99.676398, 44.548110999056853],
            [-99.66371, 44.539098999056861],
            [-99.663508, 44.217516999057061],
            [-99.695752, 44.172374999057098],
            [-99.610311, 44.148886999057133],
            [-99.612249, 44.103693999057178],
            [-99.768681, 44.13574299905715],
            [-99.884485, 44.129581999057145],
            [-99.938217, 44.195194999057094],
            [-99.892101, 44.241880999057045],
            [-100.08533, 44.308450999056987],
            [-100.36539, 44.35552399905697],
            [-100.386376, 44.440007999056917],
            [-100.580034, 44.461841999056908],
            [-100.526498, 44.547421999056866]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "025",
        COUNTYNS: "01557518",
        AFFGEOID: "0500000US54025",
        GEOID: "54025",
        NAME: "Greenbrier",
        LSAD: "06",
        ALAND: 2640889025,
        AWATER: 12578757
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.882187, 38.103456999083598],
            [-80.711961, 38.079128999083785],
            [-80.436514, 38.267333999082332],
            [-80.360048, 38.225844999082653],
            [-80.363295, 38.114330999083514],
            [-80.264653, 38.046615999084047],
            [-80.169169, 38.036110999084123],
            [-79.961982272635396, 38.063607098577208],
            [-79.971231, 38.044325999084059],
            [-80.036236, 37.967919999084664],
            [-80.055811594589684, 37.951878219182696],
            [-80.131931, 37.889499999085302],
            [-80.199633, 37.827506999085799],
            [-80.218616, 37.783290999086155],
            [-80.258143, 37.720611999086657],
            [-80.290033810255011, 37.686136458057959],
            [-80.394562, 37.716917999086704],
            [-80.450651, 37.683857999086975],
            [-80.663348, 37.731695999086575],
            [-80.668228, 37.762965999086319],
            [-80.806316, 37.868914999085462],
            [-80.748757, 37.865096999085495],
            [-80.810866, 37.949140999084818],
            [-80.769866, 37.98526999908453],
            [-80.882187, 38.103456999083598]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "009",
        COUNTYNS: "00198120",
        AFFGEOID: "0500000US08009",
        GEOID: "08009",
        NAME: "Baca",
        LSAD: "06",
        ALAND: 6617400584,
        AWATER: 6142193
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.075938, 37.643419999087307],
            [-102.747615, 37.643641999087301],
            [-102.041890869664996, 37.644277684379098],
            [-102.041938522873991, 37.389190054483251],
            [-102.041963, 37.258163999090606],
            [-102.041983, 37.106550999091915],
            [-102.04192, 37.035082999092559],
            [-102.04224, 36.993082999092934],
            [-102.355288, 36.994505999092944],
            [-102.698142, 36.995148999092919],
            [-102.841989, 36.999597999092892],
            [-103.002199, 37.000103999092886],
            [-103.086101075630992, 36.999864360199282],
            [-103.075938, 37.643419999087307]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "049",
        COUNTYNS: "01026123",
        AFFGEOID: "0500000US37049",
        GEOID: "37049",
        NAME: "Craven",
        LSAD: "06",
        ALAND: 1830335129,
        AWATER: 172457083
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.431253, 35.329829999109506],
            [-77.391026, 35.33953299910938],
            [-77.29434, 35.379136999108972],
            [-77.247896, 35.354414999109224],
            [-77.189115, 35.418361999108548],
            [-76.958887, 35.301012999109808],
            [-76.895919, 35.253888999110323],
            [-76.987266, 35.154949999111416],
            [-76.940819, 35.11910299911181],
            [-76.953285, 35.021247999112902],
            [-76.780586, 34.950433999113699],
            [-76.71158, 34.981767999113352],
            [-76.652571, 34.937594999113848],
            [-76.693018, 34.869179999114621],
            [-76.883201, 34.844892999114883],
            [-76.954438, 34.809065999115305],
            [-77.078263, 34.811321999115279],
            [-77.108851, 34.830779999115045],
            [-77.109656, 35.068608999112364],
            [-77.187679, 35.081085999112233],
            [-77.473692, 35.228984999110601],
            [-77.431253, 35.329829999109506]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "081",
        COUNTYNS: "01074053",
        AFFGEOID: "0500000US39081",
        GEOID: "39081",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 1057248945,
        AWATER: 7120755
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.924566, 40.467974999068289],
            [-80.92092, 40.556314999067844],
            [-80.863567, 40.555410999067846],
            [-80.861994, 40.599403999067626],
            [-80.747112, 40.596990999067643],
            [-80.667957, 40.58249599906771],
            [-80.622195, 40.520496999068037],
            [-80.604895, 40.446666999068405],
            [-80.627362629936897, 40.395170932085882],
            [-80.631596, 40.385467999068716],
            [-80.606596, 40.303868999069159],
            [-80.644598, 40.251269999069429],
            [-80.684173210707996, 40.187017972420485],
            [-80.702575303622595, 40.157141397079656],
            [-80.766269, 40.155110999069962],
            [-80.882892, 40.159494999069935],
            [-80.865504, 40.422928999068525],
            [-80.941911, 40.424699999068523],
            [-80.924566, 40.467974999068289]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "021",
        COUNTYNS: "00198126",
        AFFGEOID: "0500000US08021",
        GEOID: "08021",
        NAME: "Conejos",
        LSAD: "06",
        ALAND: 3334423639,
        AWATER: 9235291
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.678373, 37.40359599908934],
            [-106.039331, 37.400851999089355],
            [-106.038912, 37.356952999089728],
            [-105.743339, 37.356797999089729],
            [-105.720308, 37.285613999090344],
            [-105.770673, 37.041776999092505],
            [-105.718469683991003, 36.995845926393926],
            [-105.997472, 36.995416999092917],
            [-106.006316410798007, 36.995386627910221],
            [-106.343139, 36.994229999092937],
            [-106.476233588828009, 36.993774099639843],
            [-106.589178, 37.14018799909163],
            [-106.597384, 37.19497399909114],
            [-106.678354, 37.228565999090861],
            [-106.678373, 37.40359599908934]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "111",
        COUNTYNS: "00516902",
        AFFGEOID: "0500000US21111",
        GEOID: "21111",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 985870988,
        AWATER: 44122247
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.951729645915492, 38.014940126174899],
            [-85.922395, 38.028678999084178],
            [-85.905164, 38.11106999908354],
            [-85.895911787735585, 38.179926919953409],
            [-85.894764, 38.188468999082943],
            [-85.839664, 38.23976999908254],
            [-85.816164, 38.282968999082215],
            [-85.794510906770086, 38.277954734877554],
            [-85.750962, 38.267869999082336],
            [-85.683561, 38.295468999082118],
            [-85.646201, 38.342915999081761],
            [-85.634444144977294, 38.378399446656296],
            [-85.585272, 38.339430999081792],
            [-85.476253, 38.289694999082172],
            [-85.468206, 38.285308999082197],
            [-85.404899, 38.263729999082351],
            [-85.424054, 38.147437999083259],
            [-85.42883, 38.118382999083487],
            [-85.529477, 38.083863999083754],
            [-85.712578, 38.087310999083719],
            [-85.882805, 38.040544999084091],
            [-85.938321, 37.998755999084423],
            [-85.951729645915492, 38.014940126174899]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "009",
        COUNTYNS: "00882274",
        AFFGEOID: "0500000US34009",
        GEOID: "34009",
        NAME: "Cape May",
        LSAD: "06",
        ALAND: 652640195,
        AWATER: 953946819
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.955363, 39.001261999077045],
            [-74.903664, 39.087436999076466],
            [-74.885914, 39.143626999076091],
            [-74.905181, 39.174944999075883],
            [-74.915155805562705, 39.176697502594664],
            [-74.926347, 39.209011999075663],
            [-74.861294, 39.323814999074912],
            [-74.653206, 39.288557999075138],
            [-74.540787855582494, 39.300025490259472],
            [-74.581008, 39.27081899907526],
            [-74.646595, 39.212001999075646],
            [-74.67143, 39.179801999075849],
            [-74.714341, 39.119803999076247],
            [-74.705876, 39.102936999076356],
            [-74.778777, 39.023072999076888],
            [-74.807917, 38.985947999077162],
            [-74.864458, 38.94040999907746],
            [-74.933571, 38.928518999077546],
            [-74.967274, 38.933412999077511],
            [-74.955363, 39.001261999077045]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "075",
        COUNTYNS: "01550044",
        AFFGEOID: "0500000US54075",
        GEOID: "54075",
        NAME: "Pocahontas",
        LSAD: "06",
        ALAND: 2435322222,
        AWATER: 3978619
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.360048, 38.225844999082653],
            [-80.352171, 38.345336999081745],
            [-80.245518, 38.388456999081413],
            [-80.11692, 38.473952999080787],
            [-80.029208, 38.459183999080899],
            [-79.86325, 38.550819999080218],
            [-79.776483, 38.739810999078856],
            [-79.73918, 38.679612999079289],
            [-79.626774, 38.664213999079408],
            [-79.619174, 38.620814999079712],
            [-79.649075, 38.591514999079912],
            [-79.669128, 38.510882999080515],
            [-79.691088, 38.463743999080855],
            [-79.689675, 38.431438999081095],
            [-79.7346, 38.356727999081656],
            [-79.804093, 38.313921999081984],
            [-79.787542, 38.273297999082281],
            [-79.797013536806887, 38.26726812022153],
            [-79.850324, 38.233328999082588],
            [-79.916174, 38.18438599908297],
            [-79.938952, 38.111618999083539],
            [-79.961982272635396, 38.063607098577208],
            [-80.169169, 38.036110999084123],
            [-80.264653, 38.046615999084047],
            [-80.363295, 38.114330999083514],
            [-80.360048, 38.225844999082653]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "117",
        COUNTYNS: "01804539",
        AFFGEOID: "0500000US72117",
        GEOID: "72117",
        NAME: "Rincón",
        LSAD: "13",
        ALAND: 37005461,
        AWATER: 103924383
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.239128357404596, 18.373827544626916],
            [-67.227336, 18.348765999435571],
            [-67.182852, 18.313025999436523],
            [-67.225240248479693, 18.297984662410915],
            [-67.235137, 18.299934999436864],
            [-67.27135, 18.362328999435213],
            [-67.239128357404596, 18.373827544626916]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "085",
        COUNTYNS: "01804523",
        AFFGEOID: "0500000US72085",
        GEOID: "72085",
        NAME: "Las Piedras",
        LSAD: "13",
        ALAND: 87748363,
        AWATER: 32509
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-65.928341, 18.142844999441021],
            [-65.869006, 18.219455999438996],
            [-65.852616, 18.254971999438052],
            [-65.836387, 18.275245999437519],
            [-65.82422, 18.273053999437572],
            [-65.816614, 18.256847999438001],
            [-65.826063, 18.200407999439498],
            [-65.854877, 18.176775999440132],
            [-65.877615, 18.118166999441684],
            [-65.926227, 18.119962999441636],
            [-65.928341, 18.142844999441021]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "079",
        COUNTYNS: "01550046",
        AFFGEOID: "0500000US54079",
        GEOID: "54079",
        NAME: "Putnam",
        LSAD: "06",
        ALAND: 895302287,
        AWATER: 12226982
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.064562, 38.607984999079797],
            [-81.955791, 38.657354999079445],
            [-81.772843, 38.680842999079275],
            [-81.739877, 38.675320999079311],
            [-81.696348, 38.626426999079676],
            [-81.750547, 38.591013999079927],
            [-81.723066, 38.467771999080831],
            [-81.900222, 38.398571999081355],
            [-81.915197, 38.325596999081888],
            [-81.997406, 38.26660499908234],
            [-82.048589, 38.290137999082162],
            [-82.047128, 38.37443199908153],
            [-82.055127, 38.474546999080779],
            [-82.064562, 38.607984999079797]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "137",
        COUNTYNS: "01581128",
        AFFGEOID: "0500000US55137",
        GEOID: "55137",
        NAME: "Waushara",
        LSAD: "06",
        ALAND: 1621695264,
        AWATER: 29151536
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.59798, 44.245724999057053],
            [-89.224813, 44.24339299905705],
            [-88.886673, 44.24262199905705],
            [-88.886193, 43.983232999057314],
            [-89.168612, 43.982833999057299],
            [-89.59795, 43.982099999057304],
            [-89.59798, 44.245724999057053]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "087",
        COUNTYNS: "00295759",
        AFFGEOID: "0500000US12087",
        GEOID: "12087",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 2545998106,
        AWATER: 7133386423
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.148718, 24.710476999282818],
              [-81.038908, 24.772595999281492],
              [-80.847471, 24.851752999279807],
              [-80.610869, 25.006994999276511],
              [-80.516571, 25.095456999274639],
              [-80.500513, 25.156673999273352],
              [-80.495394, 25.199807999272451],
              [-80.492769, 25.205578999272333],
              [-80.482826, 25.22478599927193],
              [-80.450659, 25.240240999271602],
              [-80.41887, 25.23630999927169],
              [-80.394931, 25.253536999271319],
              [-80.395081, 25.272936999270932],
              [-80.372121, 25.310214999270144],
              [-80.26445, 25.354327999269227],
              [-80.232390558930391, 25.337069458682276],
              [-80.238555, 25.326819999269787],
              [-80.358183, 25.153227999273433],
              [-80.496761, 24.999323999276676],
              [-80.651189, 24.866130999279502],
              [-80.966245, 24.70785199928288],
              [-81.103373, 24.669462999283706],
              [-81.148718, 24.710476999282818]
            ]
          ],
          [
            [
              [-81.441864771799189, 25.803129027690964],
              [-80.873096, 25.805376999259916],
              [-80.873125, 25.760884999260821],
              [-80.87319, 25.363992999269023],
              [-80.858170040030586, 25.177520325799019],
              [-80.87546, 25.174320999272986],
              [-80.915924, 25.141300999273682],
              [-81.009598, 25.125402999274016],
              [-81.079859, 25.118796999274146],
              [-81.142278, 25.182999999272806],
              [-81.170907, 25.245856999271485],
              [-81.148103, 25.332792999269675],
              [-81.146765, 25.407576999268116],
              [-81.208201, 25.504936999266086],
              [-81.240519, 25.599040999264155],
              [-81.289901, 25.673553999262612],
              [-81.355986, 25.703525999261998],
              [-81.38381, 25.776750999260496],
              [-81.441864771799189, 25.803129027690964]
            ]
          ],
          [
            [
              [-81.811693, 24.568744999285862],
              [-81.751266, 24.653516999284044],
              [-81.672338, 24.699513999283059],
              [-81.584599, 24.736695999282258],
              [-81.571151, 24.756353999281846],
              [-81.44351, 24.813363999280629],
              [-81.305054, 24.75518499928187],
              [-81.243232, 24.673997999283603],
              [-81.342192, 24.637773999284384],
              [-81.401889, 24.623543999284692],
              [-81.443915, 24.642676999284269],
              [-81.517404, 24.621238999284742],
              [-81.595334, 24.593106999285339],
              [-81.685243, 24.558675999286084],
              [-81.812536, 24.545468999286371],
              [-81.811693, 24.568744999285862]
            ]
          ],
          [
            [
              [-82.01491, 24.54307099928641],
              [-81.983908, 24.580681999285609],
              [-81.868711, 24.584119999285537],
              [-81.918855, 24.498130999287387],
              [-82.028091, 24.498715999287374],
              [-82.01491, 24.54307099928641]
            ]
          ],
          [
            [
              [-82.188031, 24.574698999285737],
              [-82.144099, 24.622480999284711],
              [-82.086643, 24.590070999285405],
              [-82.100757, 24.533287999286625],
              [-82.179454, 24.529469999286714],
              [-82.188031, 24.574698999285737]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "391",
        COUNTYNS: "01383981",
        AFFGEOID: "0500000US48391",
        GEOID: "48391",
        NAME: "Refugio",
        LSAD: "06",
        ALAND: 1995545559,
        AWATER: 123687613
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.541071, 28.164668999214278],
            [-97.375579, 28.388683999210205],
            [-97.160751, 28.55347499920725],
            [-97.048529, 28.549975999207319],
            [-96.9155, 28.485711999208469],
            [-96.890951, 28.507177999208086],
            [-96.813494, 28.430260999209466],
            [-96.764132, 28.426521999209527],
            [-96.790512, 28.319237999211463],
            [-97.123799, 28.272458999212311],
            [-97.023586, 28.187656999213846],
            [-97.144916, 28.102621999215408],
            [-97.260818, 28.075758999215903],
            [-97.317163, 28.137188999214771],
            [-97.427171, 28.117667999215126],
            [-97.482384, 28.168615999214207],
            [-97.541071, 28.164668999214278]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "151",
        COUNTYNS: "01065576",
        AFFGEOID: "0500000US39151",
        GEOID: "39151",
        NAME: "Stark",
        LSAD: "06",
        ALAND: 1490043482,
        AWATER: 13657023
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.650045, 40.668116999067287],
            [-81.647991, 40.825775999066536],
            [-81.647735, 40.914015999066109],
            [-81.420418, 40.906503999066146],
            [-81.393249, 40.98853099906578],
            [-81.357529, 40.98871699906578],
            [-81.195679, 40.988204999065779],
            [-81.183632, 40.988208999065776],
            [-81.086312, 40.988030999065771],
            [-81.086679, 40.901608999066177],
            [-81.086686, 40.898857999066195],
            [-81.087289, 40.727815999066998],
            [-81.198599, 40.729171999066992],
            [-81.237227, 40.723541999067024],
            [-81.24125, 40.650533999067392],
            [-81.317739, 40.651578999067368],
            [-81.459019, 40.666919999067289],
            [-81.649199, 40.635105999067456],
            [-81.650045, 40.668116999067287]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "259",
        COUNTYNS: "00347360",
        AFFGEOID: "0500000US13259",
        GEOID: "13259",
        NAME: "Stewart",
        LSAD: "06",
        ALAND: 1188153052,
        AWATER: 12598484
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.0514111279128, 32.062256082772706],
            [-85.047063, 32.087388999150349],
            [-85.058749, 32.136017999149658],
            [-84.997765, 32.185444999148949],
            [-84.930127, 32.219050999148472],
            [-84.919942147925894, 32.23084820008161],
            [-84.832962, 32.230850999148309],
            [-84.799348, 32.230851999148307],
            [-84.65892, 32.232851999148281],
            [-84.649319, 32.232952999148281],
            [-84.63112, 32.184053999148958],
            [-84.65582, 31.920307999152758],
            [-84.675788, 31.928679999152635],
            [-84.69173, 31.920442999152758],
            [-84.810118, 31.931839999152579],
            [-84.907006, 31.924464999152683],
            [-84.974067, 31.95020899915232],
            [-84.9537, 31.974780999151982],
            [-85.063590542984684, 31.991856999151729],
            [-85.0514111279128, 32.062256082772706]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "067",
        COUNTYNS: "01074046",
        AFFGEOID: "0500000US39067",
        GEOID: "39067",
        NAME: "Harrison",
        LSAD: "06",
        ALAND: 1042034970,
        AWATER: 21847164
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.338057, 40.214252999069636],
            [-81.334601, 40.304315999069154],
            [-81.275319, 40.303433999069149],
            [-81.269951, 40.433485999068466],
            [-81.212309, 40.433456999068476],
            [-80.941911, 40.424699999068523],
            [-80.865504, 40.422928999068525],
            [-80.882892, 40.159494999069935],
            [-81.089702, 40.166997999069899],
            [-81.195889, 40.172137999069861],
            [-81.225924, 40.170074999069875],
            [-81.339565, 40.171998999069864],
            [-81.338057, 40.214252999069636]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "065",
        COUNTYNS: "01155136",
        AFFGEOID: "0500000US41065",
        GEOID: "41065",
        NAME: "Wasco",
        LSAD: "06",
        ALAND: 6167121090,
        AWATER: 35716361
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.804426, 45.012876999056765],
            [-121.65871, 45.06635499905677],
            [-121.652638, 45.101991999056771],
            [-121.731438, 45.170632999056778],
            [-121.681525, 45.248817999056811],
            [-121.696479, 45.25791999905681],
            [-121.481888, 45.258127999056818],
            [-121.482168, 45.51960399905694],
            [-121.440695827861006, 45.69907379382947],
            [-121.423592, 45.693989999057081],
            [-121.33777, 45.704948999057088],
            [-121.215779, 45.671237999057062],
            [-121.183841, 45.606440999057007],
            [-121.1222, 45.616066999057026],
            [-121.084933, 45.647892999057035],
            [-120.943977, 45.656444999057037],
            [-120.913935013084, 45.64806586548054],
            [-120.908459, 45.55824099905697],
            [-120.826385, 45.514977999056953],
            [-120.868569, 45.401145999056865],
            [-120.956471, 45.310344999056838],
            [-121.01569, 45.307147999056838],
            [-121.02408, 45.218725999056794],
            [-120.889357, 45.202304999056793],
            [-120.723569, 45.130910999056773],
            [-120.729792, 45.083730999056769],
            [-120.503733, 45.083476999056764],
            [-120.49516, 45.068278999056773],
            [-120.455204, 44.865606999056759],
            [-120.371441, 44.821768999056772],
            [-121.08948, 44.823271999056765],
            [-121.725068, 44.825518999056769],
            [-121.752994, 44.829918999056773],
            [-121.734223, 44.885719999056761],
            [-121.804426, 45.012876999056765]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "115",
        COUNTYNS: "00659503",
        AFFGEOID: "0500000US27115",
        GEOID: "27115",
        NAME: "Pine",
        LSAD: "06",
        ALAND: 3655221004,
        AWATER: 60262765
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.142869, 45.981004999057369],
            [-93.055081, 45.981232999057362],
            [-93.053871, 46.158119999057597],
            [-93.054847, 46.419268999057998],
            [-92.292759802428293, 46.417219501937993],
            [-92.293619, 46.244042999057719],
            [-92.293830608578702, 46.157321305553602],
            [-92.294033, 46.074376999057492],
            [-92.338239, 46.052148999057458],
            [-92.35176, 46.015684999057413],
            [-92.392681, 46.019539999057422],
            [-92.44963, 46.0022519990574],
            [-92.472761, 45.972951999057365],
            [-92.545682, 45.970117999057358],
            [-92.580565, 45.946249999057343],
            [-92.656125, 45.924441999057308],
            [-92.721128, 45.883804999057254],
            [-92.759458, 45.835340999057216],
            [-92.776496, 45.790013999057173],
            [-92.826013, 45.736649999057114],
            [-92.840742106625498, 45.729396733526812],
            [-93.141793, 45.730656999057111],
            [-93.142869, 45.981004999057369]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "071",
        COUNTYNS: "00343936",
        AFFGEOID: "0500000US13071",
        GEOID: "13071",
        NAME: "Colquitt",
        LSAD: "06",
        ALAND: 1416682032,
        AWATER: 25006488
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.013851, 31.102435999164939],
            [-84.002968, 31.113172999164778],
            [-83.99943, 31.334965999161412],
            [-83.759086, 31.332224999161451],
            [-83.714134, 31.318364999161666],
            [-83.65409, 31.330660999161466],
            [-83.512607, 31.327404999161519],
            [-83.509961, 31.205971999163367],
            [-83.574857, 31.078134999165318],
            [-83.573959, 31.033874999165995],
            [-83.736158, 31.037678999165934],
            [-84.003876, 31.041581999165878],
            [-84.003627, 31.07728999916533],
            [-84.013851, 31.102435999164939]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "039",
        COUNTYNS: "01248000",
        AFFGEOID: "0500000US45039",
        GEOID: "45039",
        NAME: "Fairfield",
        LSAD: "06",
        ALAND: 1777452228,
        AWATER: 61132194
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.427545, 34.529481999118538],
            [-81.422706, 34.572028999118039],
            [-80.877491, 34.543145999118373],
            [-80.898722, 34.486693999119048],
            [-80.879227, 34.457775999119377],
            [-80.769926, 34.377110999120333],
            [-80.825921, 34.268759999121635],
            [-81.095863, 34.21387199912229],
            [-81.181196, 34.220284999122214],
            [-81.178293, 34.174551999122762],
            [-81.316911, 34.239457999121989],
            [-81.342297, 34.266927999121656],
            [-81.423241, 34.494378999118943],
            [-81.427545, 34.529481999118538]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "069",
        COUNTYNS: "00659480",
        AFFGEOID: "0500000US27069",
        GEOID: "27069",
        NAME: "Kittson",
        LSAD: "06",
        ALAND: 2845894834,
        AWATER: 12411766
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.229039, 49.000686999066161],
            [-96.93096, 48.999983999066167],
            [-96.405408, 48.999983999066167],
            [-96.404924, 48.717069999064897],
            [-96.387835, 48.544307999064181],
            [-97.147723568300904, 48.543891682714758],
            [-97.142915, 48.583732999064331],
            [-97.100009, 48.667925999064678],
            [-97.121253, 48.713592999064879],
            [-97.152588, 48.772601999065131],
            [-97.187362, 48.867597999065566],
            [-97.227854, 48.945863999065907],
            [-97.229039, 49.000686999066161]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "241",
        COUNTYNS: "01383906",
        AFFGEOID: "0500000US48241",
        GEOID: "48241",
        NAME: "Jasper",
        LSAD: "06",
        ALAND: 2431105374,
        AWATER: 80399908
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.457816, 31.033324999165995],
            [-94.129632, 31.099279999164992],
            [-94.043234, 31.118551999164694],
            [-94.039034, 31.134270999164457],
            [-93.911126, 31.158070999164085],
            [-93.865711, 30.947217999167336],
            [-93.901452, 30.242671999178494],
            [-94.117604, 30.2416649991785],
            [-94.083386, 30.34218099917689],
            [-94.119394, 30.426297999175542],
            [-94.071689, 30.526032999173943],
            [-94.094812, 30.575542999173159],
            [-94.05096, 30.638567999172167],
            [-94.089369, 30.686977999171397],
            [-94.125824, 30.684051999171444],
            [-94.150119, 30.790202999169772],
            [-94.179343, 30.794973999169699],
            [-94.215213, 30.900399999168059],
            [-94.200441, 30.929255999167605],
            [-94.244888, 30.968173999167011],
            [-94.238424, 31.013704999166304],
            [-94.353782, 31.039370999165914],
            [-94.45327, 31.006236999166426],
            [-94.457816, 31.033324999165995]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "035",
        COUNTYNS: "01214721",
        AFFGEOID: "0500000US42035",
        GEOID: "42035",
        NAME: "Clinton",
        LSAD: "06",
        ALAND: 2299868230,
        AWATER: 23178794
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.093381, 41.216930999064765],
            [-77.988786, 41.367452999064142],
            [-77.989194, 41.4748219990637],
            [-77.598129, 41.478575999063686],
            [-77.597282, 41.441055999063842],
            [-77.526537, 41.358527999064179],
            [-77.349765, 41.219917999064748],
            [-77.144111, 41.068839999065418],
            [-77.14416, 41.044337999065519],
            [-77.339925, 40.987166999065792],
            [-77.508596, 40.9629379990659],
            [-77.621759, 41.09118499906532],
            [-77.741911, 41.107613999065244],
            [-77.79856, 41.181949999064926],
            [-77.892724, 41.178258999064944],
            [-77.898195, 41.252751999064607],
            [-77.973078, 41.204187999064821],
            [-78.003569, 41.144855999065079],
            [-78.038203, 41.153632999065032],
            [-78.061007, 41.17453899906495],
            [-78.093381, 41.216930999064765]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "093",
        COUNTYNS: "01639761",
        AFFGEOID: "0500000US47093",
        GEOID: "47093",
        NAME: "Knox",
        LSAD: "06",
        ALAND: 1316572195,
        AWATER: 45439419
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.272869, 35.940505999103074],
            [-84.094011, 36.068910999101767],
            [-83.941711, 36.1863779991006],
            [-83.905169, 36.160905999100855],
            [-83.732704, 36.164582999100801],
            [-83.699405, 36.10946899910136],
            [-83.66746, 36.080586999101655],
            [-83.70925, 36.093233999101521],
            [-83.673297, 36.038485999102072],
            [-83.650957, 35.986367999102605],
            [-83.794275, 35.887124999103612],
            [-83.850155, 35.842018999104084],
            [-83.994677, 35.883748999103659],
            [-84.006696, 35.848923999104009],
            [-84.145659, 35.849585999103994],
            [-84.166267, 35.80510599910447],
            [-84.179798, 35.820231999104308],
            [-84.263383, 35.896939999103516],
            [-84.271587, 35.91016499910338],
            [-84.272869, 35.940505999103074]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "087",
        COUNTYNS: "01480133",
        AFFGEOID: "0500000US51087",
        GEOID: "51087",
        NAME: "Henrico",
        LSAD: "06",
        ALAND: 605179949,
        AWATER: 28933034
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.61643, 37.577554999087845],
            [-77.63067, 37.705672999086779],
            [-77.445858, 37.684055999086965],
            [-77.407189, 37.612523999087557],
            [-77.391227, 37.598196999087683],
            [-77.240988, 37.538087999088177],
            [-77.221306, 37.533251999088222],
            [-77.177324, 37.490599999088595],
            [-77.224636, 37.379827999089528],
            [-77.249665, 37.381999999089523],
            [-77.277085, 37.35223099908977],
            [-77.418589, 37.421255999089183],
            [-77.420875, 37.447100999088953],
            [-77.385513, 37.535171999088206],
            [-77.43762, 37.598565999087683],
            [-77.437616, 37.598684999087681],
            [-77.443133, 37.601258999087648],
            [-77.443559, 37.60148599908765],
            [-77.447509, 37.602216999087638],
            [-77.447581, 37.602809999087647],
            [-77.455239, 37.601777999087645],
            [-77.454475, 37.597495999087684],
            [-77.465838, 37.596757999087693],
            [-77.466057, 37.599132999087686],
            [-77.466668, 37.599097999087675],
            [-77.466995, 37.598927999087678],
            [-77.468096, 37.598761999087685],
            [-77.468824, 37.598604999087684],
            [-77.470903, 37.598395999087685],
            [-77.475467, 37.598548999087683],
            [-77.475865, 37.598528999087677],
            [-77.476797, 37.598541999087672],
            [-77.47782, 37.598733999087678],
            [-77.478033, 37.598806999087685],
            [-77.478146, 37.598891999087684],
            [-77.478254, 37.598548999087683],
            [-77.477982, 37.598536999087671],
            [-77.477775, 37.598387999087684],
            [-77.477646, 37.598223999087672],
            [-77.477317, 37.598079999087687],
            [-77.477208, 37.597897999087692],
            [-77.479132, 37.593098999087729],
            [-77.479188, 37.592810999087725],
            [-77.479415, 37.590812999087746],
            [-77.479358, 37.588683999087756],
            [-77.479206, 37.587650999087771],
            [-77.478903, 37.587650999087764],
            [-77.478332, 37.587557999087771],
            [-77.478174, 37.587333999087768],
            [-77.478035, 37.587291999087775],
            [-77.476748, 37.585511999087792],
            [-77.476338, 37.584883999087801],
            [-77.475774, 37.584139999087796],
            [-77.475709, 37.583952999087806],
            [-77.474085, 37.581814999087818],
            [-77.47385, 37.580630999087823],
            [-77.472583, 37.578834999087839],
            [-77.472489, 37.578685999087845],
            [-77.472563, 37.578619999087856],
            [-77.482368, 37.573137999087891],
            [-77.490646, 37.580079999087829],
            [-77.49087, 37.580120999087832],
            [-77.493528, 37.582285999087809],
            [-77.497013, 37.578549999087848],
            [-77.497153, 37.578414999087848],
            [-77.4982, 37.577274999087869],
            [-77.499671, 37.575744999087867],
            [-77.499937, 37.575396999087864],
            [-77.500422, 37.575680999087872],
            [-77.500615, 37.575752999087861],
            [-77.513248, 37.58252899908782],
            [-77.514164, 37.581567999087824],
            [-77.514632, 37.581842999087812],
            [-77.514959, 37.582001999087822],
            [-77.515087, 37.581801999087808],
            [-77.515586, 37.581943999087819],
            [-77.51575, 37.582027999087821],
            [-77.5162, 37.582161999087809],
            [-77.516464, 37.581665999087818],
            [-77.516879, 37.581987999087815],
            [-77.517621, 37.582434999087823],
            [-77.517793, 37.582586999087802],
            [-77.518078, 37.582716999087801],
            [-77.522897, 37.586059999087787],
            [-77.523094, 37.586074999087785],
            [-77.531483, 37.591784999087743],
            [-77.531747, 37.592053999087724],
            [-77.532388, 37.592483999087733],
            [-77.595462, 37.555749999088043],
            [-77.655399, 37.563985999087961],
            [-77.61643, 37.577554999087845]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "035",
        COUNTYNS: "01605082",
        AFFGEOID: "0500000US56035",
        GEOID: "56035",
        NAME: "Sublette",
        LSAD: "06",
        ALAND: 12655929146,
        AWATER: 127353628
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.623434, 42.779204999059473],
            [-110.587662, 42.779187999059474],
            [-110.587993, 43.126146999058683],
            [-110.578656, 43.235223999058455],
            [-110.57838, 43.291639999058347],
            [-110.343959, 43.291164999058346],
            [-110.344218, 43.377917999058184],
            [-110.05731, 43.377767999058186],
            [-110.05157, 43.464782999058023],
            [-109.754717, 43.463199999058034],
            [-109.754462, 43.365904999058216],
            [-109.694942, 43.366151999058211],
            [-109.692716, 43.265029999058392],
            [-109.651451, 43.167035999058591],
            [-109.560451, 43.08353799905877],
            [-109.552579, 43.012537999058914],
            [-109.502407, 43.010510999058937],
            [-109.397757, 42.939197999059097],
            [-109.323552, 42.863867999059273],
            [-109.132315, 42.737484999059589],
            [-109.134466, 42.694288999059701],
            [-109.072851, 42.693513999059697],
            [-109.043801, 42.433643999060401],
            [-109.043864, 42.263683999060916],
            [-109.496675, 42.263317999060916],
            [-110.053708, 42.27074399906089],
            [-110.541634, 42.277490999060866],
            [-110.543329, 42.448535999060354],
            [-110.590131, 42.448434999060368],
            [-110.590074, 42.710086999059641],
            [-110.623477, 42.710180999059652],
            [-110.623434, 42.779204999059473]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "245",
        COUNTYNS: "01383908",
        AFFGEOID: "0500000US48245",
        GEOID: "48245",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 2269793052,
        AWATER: 611960906
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.44491, 30.113014999180596],
            [-94.292629, 30.117670999180522],
            [-94.275451, 30.170264999179668],
            [-94.17644, 30.187939999179367],
            [-94.115917, 30.160346999179829],
            [-94.093539, 30.083683999181076],
            [-94.031976, 30.028012999181989],
            [-93.860169, 29.977396999182819],
            [-93.841416, 29.949300999183276],
            [-93.852309870922298, 29.872091003576461],
            [-93.872446, 29.851649999184897],
            [-93.929208, 29.802951999185712],
            [-93.890821, 29.761672999186395],
            [-93.863204, 29.724058999187019],
            [-93.837971, 29.690618999187581],
            [-93.861291, 29.679006999187767],
            [-93.961867, 29.682212999187719],
            [-94.056506, 29.671162999187906],
            [-94.161549, 29.636587999188489],
            [-94.354122544913693, 29.562102819364537],
            [-94.357976, 29.887463999184298],
            [-94.442235, 29.889537999184267],
            [-94.44491, 30.113014999180596]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "101",
        COUNTYNS: "01639765",
        AFFGEOID: "0500000US47101",
        GEOID: "47101",
        NAME: "Lewis",
        LSAD: "06",
        ALAND: 730608078,
        AWATER: 1031592
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.651755, 35.564863999106976],
            [-87.658189, 35.609348999106508],
            [-87.586867, 35.653303999106043],
            [-87.459587, 35.615123999106451],
            [-87.339232, 35.659109999105979],
            [-87.352305, 35.584620999106768],
            [-87.29719, 35.548421999107141],
            [-87.269432, 35.505850999107601],
            [-87.294534, 35.444675999108256],
            [-87.448485, 35.457843999108114],
            [-87.44882, 35.420621999108519],
            [-87.575352, 35.398453999108753],
            [-87.645896, 35.47274499910796],
            [-87.717559, 35.483346999107845],
            [-87.651755, 35.564863999106976]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "005",
        COUNTYNS: "01513302",
        AFFGEOID: "0500000US53005",
        GEOID: "53005",
        NAME: "Benton",
        LSAD: "06",
        ALAND: 4402976584,
        AWATER: 154626802
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.874042, 46.628282999058371],
            [-119.618479, 46.647582999058415],
            [-119.511388, 46.727686999058562],
            [-119.45319, 46.679242999058481],
            [-119.403042, 46.613042999058344],
            [-119.27032, 46.521310999058166],
            [-119.264392, 46.272594999057759],
            [-119.041498, 46.192666999057643],
            [-118.974664, 46.139522999057576],
            [-118.941323, 46.027527999057426],
            [-118.987227539564998, 45.9998002125811],
            [-119.061462, 45.958526999057341],
            [-119.12612, 45.932858999057309],
            [-119.25715, 45.939925999057323],
            [-119.364396, 45.921604999057301],
            [-119.432138558816007, 45.913209143291191],
            [-119.487829, 45.906306999057286],
            [-119.571584, 45.925455999057313],
            [-119.600549, 45.91958099905731],
            [-119.669877, 45.856866999057232],
            [-119.802655, 45.847529999057222],
            [-119.868153889391024, 45.838226601692924],
            [-119.865829, 46.040857999057444],
            [-119.874042, 46.628282999058371]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "077",
        COUNTYNS: "01531929",
        AFFGEOID: "0500000US53077",
        GEOID: "53077",
        NAME: "Yakima",
        LSAD: "06",
        ALAND: 11122757370,
        AWATER: 41168172
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.456447, 46.923576999058987],
            [-121.379961, 47.087247999059393],
            [-121.379682, 47.087494999059388],
            [-121.28101, 47.088749999059388],
            [-121.090054, 46.991006999059145],
            [-121.02662, 46.91130799905897],
            [-120.634562, 46.912129999058969],
            [-120.633981, 46.825775999058784],
            [-120.508605, 46.824861999058783],
            [-120.51, 46.737945999058603],
            [-119.973036, 46.737125999058591],
            [-119.904046, 46.638100999058388],
            [-119.874042, 46.628282999058371],
            [-119.865829, 46.040857999057444],
            [-120.801295, 46.041013999057448],
            [-121.522321, 46.04400599905744],
            [-121.522324, 46.388223999057949],
            [-121.393734, 46.390203999057952],
            [-121.451256, 46.533893999058201],
            [-121.353562, 46.711505999058538],
            [-121.455218, 46.783796999058694],
            [-121.52307, 46.872782999058884],
            [-121.456447, 46.923576999058987]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "143",
        COUNTYNS: "00465260",
        AFFGEOID: "0500000US19143",
        GEOID: "19143",
        NAME: "Osceola",
        LSAD: "06",
        ALAND: 1032593860,
        AWATER: 1835265
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.860946201572304, 43.499992883963586],
            [-95.834421, 43.499965999057977],
            [-95.486803, 43.500245999057981],
            [-95.454432238660601, 43.500322084307975],
            [-95.387787594021873, 43.500478727965373],
            [-95.388078, 43.255220999058423],
            [-95.861914, 43.257565999058414],
            [-95.860946201572304, 43.499992883963586]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "111",
        COUNTYNS: "00198171",
        AFFGEOID: "0500000US08111",
        GEOID: "08111",
        NAME: "San Juan",
        LSAD: "06",
        ALAND: 1003660601,
        AWATER: 2035929
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.968875, 37.696484999086856],
            [-107.872966, 37.72241599908665],
            [-107.860845, 37.775516999086214],
            [-107.839715, 37.776713999086212],
            [-107.738283, 37.90543199908516],
            [-107.695665, 37.892409999085267],
            [-107.641588, 37.965008999084695],
            [-107.568875, 37.965014999084694],
            [-107.516547, 37.8264509990858],
            [-107.458114, 37.78209599908616],
            [-107.482179, 37.639500999087332],
            [-107.970086, 37.639586999087335],
            [-107.968875, 37.696484999086856]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "115",
        COUNTYNS: "00198173",
        AFFGEOID: "0500000US08115",
        GEOID: "08115",
        NAME: "Sedgwick",
        LSAD: "06",
        ALAND: 1419419128,
        AWATER: 3530746
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.653464137269012, 41.002225323209309],
            [-102.621033024452004, 41.002223201681325],
            [-102.556789, 41.002218999065711],
            [-102.051717516377991, 41.00237696668961],
            [-102.051614, 41.00237699906571],
            [-102.051292, 40.749590999066889],
            [-102.051292000207013, 40.749585993940599],
            [-102.65131, 40.74940199906689],
            [-102.653464137269012, 41.002225323209309]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "049",
        COUNTYNS: "00277289",
        AFFGEOID: "0500000US06049",
        GEOID: "06049",
        NAME: "Modoc",
        LSAD: "06",
        ALAND: 10140960164,
        AWATER: 745420669
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.457213, 41.949944999061948],
            [-121.447539998048001, 41.99719003233438],
            [-121.43961, 41.997079999061782],
            [-121.251099, 41.997569999061774],
            [-121.035195, 41.993322999061796],
            [-120.879925710235995, 41.993483259046691],
            [-120.692219, 41.993676999061783],
            [-120.501069, 41.993784999061788],
            [-120.181563, 41.994587999061793],
            [-119.999168, 41.994539999061779],
            [-119.999276, 41.874890999062195],
            [-119.99828, 41.618764999063131],
            [-119.999866, 41.183973999064918],
            [-120.501404, 41.183939999064918],
            [-121.331786, 41.183885999064913],
            [-121.446495, 41.183483999064904],
            [-121.448981, 41.776391999062554],
            [-121.457213, 41.949944999061948]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "387",
        COUNTYNS: "01383979",
        AFFGEOID: "0500000US48387",
        GEOID: "48387",
        NAME: "Red River",
        LSAD: "06",
        ALAND: 2703626759,
        AWATER: 33305608
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.310449795449088, 33.873843037311957],
            [-95.283445, 33.877745999126411],
            [-95.253623, 33.929709999125755],
            [-95.226393, 33.96195399912537],
            [-95.155907617107502, 33.938482387695466],
            [-95.149462, 33.936335999125681],
            [-95.095002, 33.904815999126072],
            [-95.046568, 33.862564999126597],
            [-94.98165, 33.852283999126733],
            [-94.93956, 33.810502999127245],
            [-94.902276, 33.776288999127672],
            [-94.841634, 33.739430999128132],
            [-94.766146, 33.748030999128034],
            [-94.731932901995904, 33.720830515408679],
            [-94.746096, 33.700374999128641],
            [-94.746925, 33.328937999133387],
            [-94.781693, 33.318904999133522],
            [-94.808783, 33.363635999132939],
            [-94.81271, 33.37557699913279],
            [-95.125451, 33.389453999132606],
            [-95.153438, 33.380763999132718],
            [-95.30664, 33.37802699913275],
            [-95.308637, 33.380661999132712],
            [-95.310449795449088, 33.873843037311957]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "393",
        COUNTYNS: "01383982",
        AFFGEOID: "0500000US48393",
        GEOID: "48393",
        NAME: "Roberts",
        LSAD: "06",
        ALAND: 2393298446,
        AWATER: 346846
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.086068, 35.625266999106344],
            [-101.085735, 36.055275999101902],
            [-101.085716, 36.057571999101881],
            [-100.546724, 36.056535999101889],
            [-100.540221, 36.056490999101896],
            [-100.540158, 35.619295999106399],
            [-101.085935, 35.619101999106405],
            [-101.086068, 35.625266999106344]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "083",
        COUNTYNS: "01383827",
        AFFGEOID: "0500000US48083",
        GEOID: "48083",
        NAME: "Coleman",
        LSAD: "06",
        ALAND: 3268424411,
        AWATER: 50472148
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.713971, 32.082088999150415],
            [-99.631413, 32.08127399915044],
            [-99.195871, 32.079234999150458],
            [-99.202768, 31.467233999159422],
            [-99.314875, 31.409874999160284],
            [-99.402284, 31.446197999159736],
            [-99.511477, 31.42440099916006],
            [-99.60185, 31.491949999159061],
            [-99.62492, 31.467441999159423],
            [-99.721698, 31.576758999157793],
            [-99.713971, 32.082088999150415]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "015",
        COUNTYNS: "00351604",
        AFFGEOID: "0500000US13015",
        GEOID: "13015",
        NAME: "Bartow",
        LSAD: "06",
        ALAND: 1190154332,
        AWATER: 27368202
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.046871, 34.096411999123724],
            [-85.023102, 34.347111999120692],
            [-85.005775, 34.392445999120156],
            [-84.989916, 34.384557999120247],
            [-84.653232, 34.41258999911993],
            [-84.644293, 34.355167999120596],
            [-84.659241, 34.078239999123952],
            [-84.737836, 34.079398999123917],
            [-84.910041, 34.075296999123971],
            [-84.922742, 34.082496999123883],
            [-85.047046, 34.082880999123887],
            [-85.046871, 34.096411999123724]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "085",
        COUNTYNS: "00198158",
        AFFGEOID: "0500000US08085",
        GEOID: "08085",
        NAME: "Montrose",
        LSAD: "06",
        ALAND: 5804023642,
        AWATER: 4923261
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.059962, 38.499986999080583],
            [-109.05996197258898, 38.499989030101396],
            [-108.379136, 38.499986999080583],
            [-108.378698, 38.668096999079381],
            [-107.500655, 38.66855299907936],
            [-107.50002, 38.301925999082073],
            [-107.63504, 38.301895999082078],
            [-108.13238, 38.33157299908185],
            [-108.086604, 38.255201999082416],
            [-107.937588, 38.218991999082711],
            [-107.965789, 38.152327999083205],
            [-108.906749, 38.154958999083213],
            [-109.041796614855002, 38.153032304051713],
            [-109.041762, 38.164689999083123],
            [-109.060062, 38.27548899908227],
            [-109.059962, 38.499986999080583]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "063",
        COUNTYNS: "00277296",
        AFFGEOID: "0500000US06063",
        GEOID: "06063",
        NAME: "Plumas",
        LSAD: "06",
        ALAND: 6612400919,
        AWATER: 156387635
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.497635, 40.445590999068408],
            [-121.361456, 40.446821999068405],
            [-121.327826, 40.445366999068405],
            [-121.061417, 40.446535999068395],
            [-121.061493, 40.256416999069408],
            [-120.928582, 40.191930999069754],
            [-120.764403, 40.316009999069095],
            [-120.652158, 40.307655999069141],
            [-120.510817, 40.248944999069444],
            [-120.445892, 40.176853999069841],
            [-120.341385, 40.11524299907019],
            [-120.209641, 40.08600799907034],
            [-120.201264, 40.01347399907074],
            [-120.108761, 39.939509999071163],
            [-120.110627, 39.765778999072168],
            [-120.147149, 39.707657999072531],
            [-120.654227, 39.706628999072535],
            [-120.68076, 39.676832999072708],
            [-120.792484, 39.709793999072517],
            [-120.873261, 39.776167999072115],
            [-120.949435, 39.672547999072734],
            [-121.009477, 39.639458999072936],
            [-121.076695, 39.597263999073199],
            [-121.136715, 39.628168999073011],
            [-121.210878, 39.725650999072428],
            [-121.350821, 39.82570399907182],
            [-121.429957, 39.900312999071382],
            [-121.41948, 40.015765999070737],
            [-121.366818, 40.086053999070337],
            [-121.436886, 40.151904999069977],
            [-121.368395, 40.212229999069649],
            [-121.342264, 40.309750999069117],
            [-121.470395, 40.350212999068908],
            [-121.497635, 40.445590999068408]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "403",
        COUNTYNS: "01383987",
        AFFGEOID: "0500000US48403",
        GEOID: "48403",
        NAME: "Sabine",
        LSAD: "06",
        ALAND: 1272739766,
        AWATER: 220719716
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.044154, 31.409978999160273],
            [-93.983239, 31.466113999159433],
            [-93.984461, 31.569418999157904],
            [-93.896537, 31.611835999157272],
            [-93.834923214477783, 31.586210020235644],
            [-93.787687, 31.527343999158525],
            [-93.725925, 31.504091999158877],
            [-93.749476, 31.468689999159402],
            [-93.697603, 31.428408999159998],
            [-93.668146, 31.375102999160809],
            [-93.67544, 31.301039999161912],
            [-93.613942, 31.259374999162549],
            [-93.602443, 31.182540999163713],
            [-93.600603087362884, 31.182624954432516],
            [-93.616168, 31.175251999163823],
            [-93.911126, 31.158070999164085],
            [-94.039034, 31.134270999164457],
            [-94.044154, 31.409978999160273]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "105",
        COUNTYNS: "00295747",
        AFFGEOID: "0500000US12105",
        GEOID: "12105",
        NAME: "Polk",
        LSAD: "06",
        ALAND: 4653579490,
        AWATER: 553462009
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.106206, 28.259239999212557],
            [-82.055819, 28.312793999211586],
            [-81.958029, 28.308924999211659],
            [-81.957641, 28.345179999210998],
            [-81.857624, 28.361867999210695],
            [-81.657268, 28.347097999210959],
            [-81.657173, 28.264512999212457],
            [-81.557459, 28.259361999212548],
            [-81.524227, 28.142804999214675],
            [-81.456214, 28.143162999214663],
            [-81.455758, 28.084889999215726],
            [-81.346418, 28.084695999215725],
            [-81.378718, 28.011742999217073],
            [-81.459687, 28.05180899921633],
            [-81.386988, 28.003618999217224],
            [-81.308696, 27.921879999218724],
            [-81.301862, 27.862080999219838],
            [-81.208124, 27.82112499922059],
            [-81.145954, 27.71053299922265],
            [-81.142164, 27.643237999223913],
            [-81.529961, 27.64642199922385],
            [-81.563318, 27.646646999223844],
            [-82.054349, 27.646381999223852],
            [-82.054874, 27.907725999218993],
            [-82.05526, 27.927179999218634],
            [-82.056261, 28.171591999214147],
            [-82.105853, 28.171649999214146],
            [-82.106206, 28.259239999212557]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "443",
        COUNTYNS: "01384007",
        AFFGEOID: "0500000US48443",
        GEOID: "48443",
        NAME: "Terrell",
        LSAD: "06",
        ALAND: 6107235409,
        AWATER: 105866
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.566937, 30.283268999177839],
            [-102.343085, 30.284115999177814],
            [-102.342986, 30.598758999172791],
            [-102.138406, 30.597520999172804],
            [-102.137778, 30.655981999171875],
            [-102.034385, 30.655267999171897],
            [-102.034002, 30.655432999171893],
            [-102.033231, 30.655495999171887],
            [-102.032467, 30.655384999171893],
            [-102.031813, 30.655502999171897],
            [-102.000408, 30.654932999171908],
            [-101.997614, 30.654085999171915],
            [-101.769353, 30.653368999171924],
            [-101.768377, 30.652937999171925],
            [-101.76842, 30.65307699917193],
            [-101.652446, 30.62047099917244],
            [-101.646245, 30.560762999173388],
            [-101.740429, 30.420896999175625],
            [-101.65853, 30.357860999176633],
            [-101.7584, 30.288043999177763],
            [-101.761619209528007, 29.778860095908296],
            [-101.809441, 29.790160999185915],
            [-101.8754, 29.794022999185863],
            [-101.966167, 29.807342999185629],
            [-102.021919, 29.802490999185718],
            [-102.073646, 29.786925999185978],
            [-102.115682, 29.792389999185875],
            [-102.161674, 29.819486999185425],
            [-102.227553, 29.843533999185038],
            [-102.301381, 29.877673999184474],
            [-102.31868395458801, 29.872192945686056],
            [-102.340215, 29.892645999184214],
            [-102.567049, 30.052807999181574],
            [-102.566937, 30.283268999177839]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "109",
        COUNTYNS: "00345923",
        AFFGEOID: "0500000US13109",
        GEOID: "13109",
        NAME: "Evans",
        LSAD: "06",
        ALAND: 473612756,
        AWATER: 10377268
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.025339, 32.278882999147626],
            [-81.969069, 32.268782999147767],
            [-81.81628, 32.237469999148217],
            [-81.780858, 32.152889999149409],
            [-81.75634, 32.104583999150101],
            [-81.718658, 32.089350999150319],
            [-81.761735, 32.047899999150921],
            [-81.888938, 32.050397999150881],
            [-81.980041, 32.081501999150433],
            [-82.025339, 32.278882999147626]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "123",
        COUNTYNS: "01074074",
        AFFGEOID: "0500000US39123",
        GEOID: "39123",
        NAME: "Ottawa",
        LSAD: "06",
        ALAND: 660091271,
        AWATER: 855217016
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-82.863342, 41.693692999062854],
              [-82.82572, 41.722809999062747],
              [-82.782719, 41.694002999062853],
              [-82.788876, 41.643046999063046],
              [-82.842099, 41.628322999063101],
              [-82.863342, 41.693692999062854]
            ]
          ],
          [
            [
              [-83.415919, 41.618937999063149],
              [-83.182375, 41.623153999063121],
              [-83.163821417522797, 41.624130312037117],
              [-83.066593, 41.595339999063235],
              [-83.028072, 41.55565599906339],
              [-82.934369, 41.514352999063554],
              [-82.859531, 41.576370999063293],
              [-82.834101, 41.587586999063255],
              [-82.717878, 41.541929999063441],
              [-82.690567160664898, 41.496705794501807],
              [-82.73044, 41.479342999063682],
              [-82.952224, 41.458238999063774],
              [-83.067459, 41.457168999063775],
              [-83.338196, 41.457507999063765],
              [-83.414626, 41.500227999063597],
              [-83.415919, 41.618937999063149]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "198",
        COUNTYNS: "01419980",
        AFFGEOID: "0500000US02198",
        GEOID: "02198",
        NAME: "Prince of Wales-Hyder",
        LSAD: "05",
        ALAND: 13630288113,
        AWATER: 14625197420
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-130.388606, 55.943178999125294],
              [-130.288619, 55.99812499912597],
              [-130.22006, 55.99913699912598],
              [-130.289296, 56.047320999126583],
              [-130.246577284174009, 56.097195987601907],
              [-130.24554, 56.096875999127185],
              [-130.102761, 56.116695999127444],
              [-130.00426, 55.993378999125909],
              [-130.013198, 55.916381999124951],
              [-130.08451, 55.823996999123828],
              [-130.12372, 55.807039999123639],
              [-130.138222339589021, 55.763027564929402],
              [-130.237973, 55.816644999123739],
              [-130.288673, 55.887005999124597],
              [-130.388606, 55.943178999125294]
            ]
          ],
          [
            [
              [-131.648375, 55.227950999116764],
              [-131.569223, 55.294690999117535],
              [-131.239554, 55.169682999116091],
              [-131.336983, 54.995533999114116],
              [-131.51066, 55.016365999114335],
              [-131.61188974327203, 54.982270633997061],
              [-131.605661, 55.004402999114205],
              [-131.646276, 55.035578999114556],
              [-131.589387, 55.088939999115162],
              [-131.605302, 55.107435999115381],
              [-131.684131985649998, 55.119093613191119],
              [-131.648375, 55.227950999116764]
            ]
          ],
          [
            [
              [-133.935016, 55.920688999125012],
              [-133.816363, 55.964024999125549],
              [-133.7314, 56.02885699912634],
              [-133.659241, 56.083817999127028],
              [-133.643817, 56.127738999127587],
              [-133.6721, 56.222733999128778],
              [-133.656889, 56.281227999129527],
              [-133.656415, 56.326908999130104],
              [-133.582116, 56.352505999130422],
              [-133.41837, 56.332131999130162],
              [-133.197009, 56.333015999130168],
              [-133.158233, 56.314767999129948],
              [-133.07823, 56.246801999129076],
              [-133.039877412399022, 56.239040379224683],
              [-133.016516, 56.150203999127861],
              [-133.007727, 56.134872999127673],
              [-132.893368, 56.067629999126829],
              [-132.85978295116999, 56.052769326150049],
              [-132.837592, 56.024326999126302],
              [-132.618464, 55.911475999124903],
              [-132.470697, 55.782161999123318],
              [-132.462531, 55.673853999122024],
              [-132.382505, 55.665335999121915],
              [-132.301119, 55.550959999120543],
              [-132.142945, 55.457940999119451],
              [-132.258056, 55.416141999118956],
              [-132.126398, 55.288417999117463],
              [-132.037122, 55.275143999117297],
              [-131.977397, 55.180948999116211],
              [-132.027513, 55.104674999115339],
              [-131.984592, 55.027977999114469],
              [-131.983324, 54.897812999113007],
              [-131.957914, 54.791238999111819],
              [-131.999591, 54.731974999111166],
              [-132.029747, 54.701188999110833],
              [-132.165182, 54.694049999110739],
              [-132.228223, 54.725169999111088],
              [-132.307943, 54.718713999111024],
              [-132.366389, 54.751196999111379],
              [-132.403533, 54.784595999111744],
              [-132.509371, 54.781257999111702],
              [-132.639032, 54.753250999111401],
              [-132.674324, 54.67465199911053],
              [-132.753019, 54.673243999110532],
              [-132.866355, 54.700385999110814],
              [-132.87721, 54.753771999111407],
              [-132.918751, 54.783252999111731],
              [-132.990589, 54.820993999112154],
              [-133.099047, 54.919006999113243],
              [-133.164788, 54.976908999113888],
              [-133.197719, 55.033403999114533],
              [-133.239695, 55.092414999115199],
              [-133.215086, 55.136875999115716],
              [-133.232491, 55.198827999116418],
              [-133.281979, 55.217116999116634],
              [-133.341259, 55.205700999116502],
              [-133.404497, 55.214991999116599],
              [-133.471938, 55.247526999116985],
              [-133.468217, 55.281677999117377],
              [-133.586605, 55.308799999117703],
              [-133.596762, 55.218232999116651],
              [-133.658359, 55.232673999116813],
              [-133.690174, 55.304408999117641],
              [-133.633006, 55.361298999118304],
              [-133.630945, 55.416113999118949],
              [-133.697898, 55.454758999119406],
              [-133.789055, 55.457891999119447],
              [-133.75287, 55.544281999120464],
              [-133.728549, 55.593127999121045],
              [-133.716665, 55.660222999121849],
              [-133.643324, 55.729036999122677],
              [-133.701152, 55.785159999123358],
              [-133.700468, 55.837420999123999],
              [-133.861039, 55.848843999124135],
              [-133.92025, 55.860294999124278],
              [-133.935016, 55.920688999125012]
            ]
          ],
          [
            [
              [-134.392983, 56.864283999137093],
              [-134.270459, 56.935579999138028],
              [-134.193751, 56.933597999138009],
              [-134.147103, 56.956969999138316],
              [-134.047753, 56.922999999137865],
              [-134.006635, 56.851586999136913],
              [-133.942936, 56.805550999136308],
              [-133.86904, 56.845937999136844],
              [-133.921451, 56.961510999138376],
              [-134.049218, 57.029202999139279],
              [-134.008856, 57.074577999139912],
              [-133.887957, 57.097743999140214],
              [-133.739433, 57.072183999139874],
              [-133.536258, 57.038699999139425],
              [-133.334272, 57.002441999138931],
              [-133.317871026437018, 57.002674736838635],
              [-133.274012, 56.921814999137851],
              [-133.440042, 56.882354999137327],
              [-133.515689, 56.795982999136179],
              [-133.49736, 56.746656999135539],
              [-133.579378, 56.757840999135674],
              [-133.441307, 56.637625999134102],
              [-133.415378891774992, 56.456444913415048],
              [-133.460634, 56.45411999913172],
              [-133.655468, 56.442278999131574],
              [-133.821628, 56.391601999130927],
              [-133.834555, 56.31980099913001],
              [-133.876624, 56.275883999129448],
              [-133.88114, 56.223196999128781],
              [-133.941986, 56.180094999128229],
              [-133.927725, 56.14594899912781],
              [-133.960525, 56.091358999127117],
              [-134.018043, 56.088175999127088],
              [-134.087446, 56.094938999127173],
              [-134.121868, 56.029894999126363],
              [-134.099805, 55.984139999125794],
              [-134.118062, 55.914641999124932],
              [-134.208251, 55.876708999124482],
              [-134.255096, 55.844613999124078],
              [-134.311763, 55.81228499912369],
              [-134.344652, 55.846311999124111],
              [-134.374965, 55.928491999125107],
              [-134.291804, 55.926218999125076],
              [-134.202178, 56.035174999126419],
              [-134.259749, 56.134439999127672],
              [-134.282212, 56.254788999129168],
              [-134.294679, 56.3358879991302],
              [-134.243126, 56.395777999130985],
              [-134.25192, 56.44454999913161],
              [-134.197967, 56.531027999132725],
              [-134.241938, 56.555530999133033],
              [-134.320134, 56.554483999133012],
              [-134.30112, 56.620316999133877],
              [-134.376274, 56.668607999134494],
              [-134.418534, 56.822332999136535],
              [-134.392983, 56.864283999137093]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "173",
        COUNTYNS: "00348102",
        AFFGEOID: "0500000US13173",
        GEOID: "13173",
        NAME: "Lanier",
        LSAD: "06",
        ALAND: 479903057,
        AWATER: 37546688
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.197971, 31.025404999166131],
            [-83.165072, 31.147197999164263],
            [-83.036369, 31.151037999164199],
            [-83.046885, 31.183679999163701],
            [-82.971247, 31.183987999163698],
            [-82.971336, 30.869391999168535],
            [-83.019419, 30.84945299916885],
            [-83.042924, 30.947295999167334],
            [-83.179465, 30.95012799916729],
            [-83.180487, 31.016725999166251],
            [-83.197971, 31.025404999166131]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "015",
        COUNTYNS: "00395157",
        AFFGEOID: "0500000US16015",
        GEOID: "16015",
        NAME: "Boise",
        LSAD: "06",
        ALAND: 4919826480,
        AWATER: 19104287
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.273069, 44.151226999057123],
            [-116.213871, 44.151386999057138],
            [-116.11249, 44.147838999057129],
            [-116.103072, 44.236408999057055],
            [-115.520287, 44.235355999057056],
            [-115.401408, 44.259622999057044],
            [-115.357484, 44.345958999056982],
            [-115.294614, 44.33944499905698],
            [-115.178964, 44.296520999057016],
            [-115.167684, 44.20283999905709],
            [-115.024181, 44.155477999057119],
            [-115.04356, 44.058819999057214],
            [-114.990777, 43.949722999057329],
            [-115.067482, 43.940744999057351],
            [-115.136209, 43.983858999057297],
            [-115.181931, 44.090532999057181],
            [-115.230528, 44.098765999057179],
            [-115.197067, 44.068551999057206],
            [-115.287226, 43.935519999057348],
            [-115.508722, 43.88485499905741],
            [-115.547538, 43.792666999057538],
            [-115.592796, 43.798288999057533],
            [-115.627317, 43.722864999057634],
            [-115.696456, 43.666504999057715],
            [-115.875927, 43.591264999057827],
            [-115.975719, 43.591437999057824],
            [-115.992799, 43.595084999057825],
            [-116.263384, 43.793791999057539],
            [-116.282019, 43.807364999057505],
            [-116.273069, 44.151226999057123]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "211",
        COUNTYNS: "00516952",
        AFFGEOID: "0500000US21211",
        GEOID: "21211",
        NAME: "Shelby",
        LSAD: "06",
        ALAND: 983235165,
        AWATER: 15627712
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.468206, 38.285308999082197],
            [-85.324453, 38.310111999081997],
            [-85.283082, 38.35805399908164],
            [-84.997667, 38.335585999081815],
            [-84.956959, 38.332270999081842],
            [-84.998898, 38.274978999082279],
            [-85.023711, 38.129051999083401],
            [-85.047783, 38.072340999083842],
            [-85.101646, 38.03726999908411],
            [-85.196117, 38.074821999083824],
            [-85.232406, 38.08943099908371],
            [-85.424054, 38.147437999083259],
            [-85.404899, 38.263729999082351],
            [-85.468206, 38.285308999082197]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "097",
        COUNTYNS: "00758503",
        AFFGEOID: "0500000US29097",
        GEOID: "29097",
        NAME: "Jasper",
        LSAD: "06",
        ALAND: 1653653979,
        AWATER: 7233655
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.618351, 37.160210999091447],
            [-94.617753891606583, 37.338417999089884],
            [-94.617667604700799, 37.364170326128367],
            [-94.082083, 37.349290999089796],
            [-94.083833, 37.290850999090296],
            [-94.052313, 37.290077999090329],
            [-94.059211, 37.048126999092453],
            [-94.618102891102978, 37.056796389965278],
            [-94.618351, 37.160210999091447]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "121",
        COUNTYNS: "00559570",
        AFFGEOID: "0500000US22121",
        GEOID: "22121",
        NAME: "West Baton Rouge",
        LSAD: "15",
        ALAND: 498293891,
        AWATER: 29602238
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.485089, 30.497137999174395],
            [-91.41476, 30.511515999174176],
            [-91.309475, 30.650044999171982],
            [-91.33087, 30.658676999171838],
            [-91.310061, 30.653960999171911],
            [-91.297658, 30.649547999171993],
            [-91.315282, 30.594287999172867],
            [-91.246058, 30.535850999173782],
            [-91.281478, 30.506114999174262],
            [-91.209308, 30.522988999173986],
            [-91.197187, 30.505667999174257],
            [-91.196114, 30.452698999175109],
            [-91.199056, 30.4222789991756],
            [-91.239534, 30.351583999176729],
            [-91.142105, 30.323292999177191],
            [-91.311285, 30.322471999177196],
            [-91.325479, 30.346805999176812],
            [-91.45329, 30.474923999174759],
            [-91.485089, 30.497137999174395]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "063",
        COUNTYNS: "00161557",
        AFFGEOID: "0500000US01063",
        GEOID: "01063",
        NAME: "Greene",
        LSAD: "06",
        ALAND: 1675782063,
        AWATER: 33416143
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.175329, 32.932092999138625],
            [-88.171852, 32.995859999137778],
            [-88.149395, 33.037297999137223],
            [-87.968348, 33.078234999136683],
            [-87.837521, 33.153636999135685],
            [-87.838047, 33.136863999135905],
            [-87.832142, 33.017596999137488],
            [-87.715709, 33.006823999137637],
            [-87.729941, 32.949257999138418],
            [-87.804218, 32.897522999139099],
            [-87.831749, 32.840504999139867],
            [-87.813178, 32.81017599914027],
            [-87.870464, 32.762441999140925],
            [-87.808284, 32.752371999141069],
            [-87.813401, 32.633874999142684],
            [-87.736928, 32.589688999143291],
            [-87.787701, 32.582288999143401],
            [-87.812559, 32.524559999144195],
            [-87.824977, 32.544788999143918],
            [-87.85342, 32.532085999144087],
            [-87.898355, 32.592320999143261],
            [-87.840858, 32.605035999143077],
            [-87.928689, 32.6322839991427],
            [-88.053729, 32.593051999143242],
            [-88.132685, 32.832852999139966],
            [-88.207316, 32.92478199913873],
            [-88.175329, 32.932092999138625]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "089",
        COUNTYNS: "00161570",
        AFFGEOID: "0500000US01089",
        GEOID: "01089",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 2076145098,
        AWATER: 28777508
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.783628017169008, 34.99192492110523],
            [-86.467798, 34.990691999113245],
            [-86.3187607654651, 34.991078579556046],
            [-86.311274, 34.991097999113236],
            [-86.256968, 34.937774999113834],
            [-86.325569, 34.937322999113846],
            [-86.359034, 34.632609999117335],
            [-86.326853, 34.599402999117721],
            [-86.330198, 34.512683999118728],
            [-86.423914, 34.479580999119122],
            [-86.533445, 34.502794999118848],
            [-86.550166, 34.545962999118345],
            [-86.578855, 34.58135499911792],
            [-86.646393, 34.548920999118316],
            [-86.689353, 34.586424999117867],
            [-86.790056, 34.550789999118294],
            [-86.783628017169008, 34.99192492110523]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "103",
        COUNTYNS: "01692767",
        AFFGEOID: "0500000US06103",
        GEOID: "06103",
        NAME: "Tehama",
        LSAD: "06",
        ALAND: 7638228772,
        AWATER: 33767784
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.065426, 40.28697099906924],
            [-122.976548, 40.315161999069097],
            [-122.918055, 40.306971999069134],
            [-122.872274, 40.348968999068909],
            [-122.740358, 40.364999999068829],
            [-122.651341, 40.328287999069026],
            [-122.525677, 40.387612999068715],
            [-122.310412, 40.371063999068795],
            [-122.010316, 40.426557999068507],
            [-121.940989, 40.415330999068559],
            [-121.806539, 40.44494999906842],
            [-121.73429, 40.43568099906846],
            [-121.497635, 40.445590999068408],
            [-121.470395, 40.350212999068908],
            [-121.342264, 40.309750999069117],
            [-121.368395, 40.212229999069649],
            [-121.436886, 40.151904999069977],
            [-121.586407, 40.10052199907026],
            [-121.646032, 39.98252699907092],
            [-121.703241, 39.984229999070905],
            [-121.804041, 39.88434399907149],
            [-122.044865, 39.883770999071487],
            [-122.046471, 39.797647999071991],
            [-122.911794, 39.799484999071979],
            [-122.937654, 39.798155999071994],
            [-122.950077, 39.907717999071352],
            [-122.909787, 39.936384999071187],
            [-122.934013, 39.978130999070949],
            [-122.989623, 40.145495999070015],
            [-122.97755, 40.241284999069492],
            [-123.065426, 40.28697099906924]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "183",
        COUNTYNS: "01785114",
        AFFGEOID: "0500000US17183",
        GEOID: "17183",
        NAME: "Vermilion",
        LSAD: "06",
        ALAND: 2326763522,
        AWATER: 7534491
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.942103, 40.225481999069572],
            [-87.932858, 40.399400999068654],
            [-87.935309, 40.485922999068208],
            [-87.526875773253778, 40.491224408758569],
            [-87.527065263399194, 40.476882291529755],
            [-87.530054, 40.250670999069435],
            [-87.531021612428006, 40.148035395815697],
            [-87.532308, 40.011586999070758],
            [-87.532454493884302, 39.882999117853792],
            [-87.557513, 39.868718999071575],
            [-87.615182, 39.8816299990715],
            [-87.937645, 39.879802999071508],
            [-87.942103, 40.225481999069572]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "031",
        COUNTYNS: "00858608",
        AFFGEOID: "0500000US32031",
        GEOID: "32031",
        NAME: "Washoe",
        LSAD: "06",
        ALAND: 16323099293,
        AWATER: 621146261
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.004795512304, 39.316475011033461],
            [-120.003028919656998, 39.44504577523464],
            [-120.00174, 39.538851999073557],
            [-119.999935448084997, 39.72240692379664],
            [-119.997634, 39.956504999071079],
            [-119.997124, 40.126362999070118],
            [-119.996155, 40.32124999906906],
            [-119.997533, 40.720991999067024],
            [-119.999231, 40.865898999066339],
            [-119.999866, 41.183973999064918],
            [-119.99828, 41.618764999063131],
            [-119.999276, 41.874890999062195],
            [-119.999168, 41.994539999061779],
            [-119.72573, 41.996295999061779],
            [-119.360123119603003, 41.99409225404299],
            [-119.324184120438986, 41.993875626855093],
            [-119.323955, 41.414518999063944],
            [-119.305713, 41.234541999064696],
            [-119.329722, 40.973849999065848],
            [-119.309598, 40.960377999065905],
            [-119.313036, 40.523103999068013],
            [-119.339296, 39.998608999070825],
            [-119.225176, 39.99938499907082],
            [-119.208543, 39.809966999071925],
            [-119.170641, 39.680452999072699],
            [-119.189342, 39.650869999072874],
            [-119.25876, 39.64921299907288],
            [-119.283637, 39.62156099907304],
            [-119.467141, 39.584501999073268],
            [-119.621697, 39.511601999073726],
            [-119.695643, 39.520022999073674],
            [-119.658501, 39.33154399907486],
            [-119.712645, 39.250870999075374],
            [-119.770871, 39.215284999075621],
            [-119.843933, 39.200775999075717],
            [-119.880787, 39.165508999075946],
            [-120.003364028833005, 39.165626763144743],
            [-120.005142, 39.291257999075128],
            [-120.004795512304, 39.316475011033461]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "071",
        COUNTYNS: "00161561",
        AFFGEOID: "0500000US01071",
        GEOID: "01071",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 2791974087,
        AWATER: 126334708
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.359034, 34.632609999117335],
            [-86.325569, 34.937322999113846],
            [-86.256968, 34.937774999113834],
            [-86.311274, 34.991097999113236],
            [-85.863946111806897, 34.987030857861392],
            [-85.605165, 34.984677999113309],
            [-85.595165, 34.92417099911399],
            [-85.582812333785284, 34.860435473592318],
            [-85.785471, 34.624583999117426],
            [-86.015594, 34.481638999119106],
            [-86.057712, 34.475993999119176],
            [-86.087628, 34.466557999119281],
            [-86.097741, 34.512546999118733],
            [-86.148464, 34.599068999117719],
            [-86.326853, 34.599402999117721],
            [-86.359034, 34.632609999117335]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "057",
        COUNTYNS: "01682927",
        AFFGEOID: "0500000US06057",
        GEOID: "06057",
        NAME: "Nevada",
        LSAD: "06",
        ALAND: 2480587331,
        AWATER: 41531993
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.266132, 39.272716999075243],
            [-121.127481, 39.380236999074555],
            [-121.022085, 39.391557999074479],
            [-120.755729, 39.4526629990741],
            [-120.654332, 39.526888999073634],
            [-120.575059, 39.522279999073653],
            [-120.505445, 39.446116999074142],
            [-120.003028919656998, 39.44504577523464],
            [-120.004795512304, 39.316475011033461],
            [-120.036657, 39.316333999074963],
            [-120.322378, 39.316426999074963],
            [-120.5546, 39.315595999074958],
            [-120.727893, 39.288080999075142],
            [-120.908996, 39.170172999075916],
            [-121.067546, 39.005370999077016],
            [-121.137979, 39.037910999076807],
            [-121.279533, 39.034617999076815],
            [-121.266132, 39.272716999075243]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "113",
        COUNTYNS: "00277321",
        AFFGEOID: "0500000US06113",
        GEOID: "06113",
        NAME: "Yolo",
        LSAD: "06",
        ALAND: 2628256658,
        AWATER: 22688556
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.403941, 38.925288999077566],
            [-122.340172, 38.924245999077577],
            [-121.835488, 38.924480999077581],
            [-121.814814, 38.876581999077899],
            [-121.723149, 38.851305999078086],
            [-121.673033, 38.742846999078836],
            [-121.602894, 38.735837999078889],
            [-121.633774, 38.686163999079241],
            [-121.553609, 38.603325999079836],
            [-121.544567, 38.597703999079876],
            [-121.527667, 38.604603999079828],
            [-121.511566, 38.600903999079861],
            [-121.506269, 38.586304999079964],
            [-121.520965, 38.566204999080107],
            [-121.51129, 38.546289999080258],
            [-121.530767, 38.527905999080389],
            [-121.525178, 38.518961999080453],
            [-121.551866, 38.513805999080489],
            [-121.558069, 38.501875999080575],
            [-121.50509, 38.469562999080814],
            [-121.521641, 38.360103999081637],
            [-121.593273, 38.313088999081984],
            [-121.693697, 38.313731999081973],
            [-121.712034, 38.537961999080309],
            [-121.940285, 38.533383999080343],
            [-122.011673, 38.48899099908067],
            [-122.103281, 38.513347999080487],
            [-122.168301, 38.655295999079463],
            [-122.224206, 38.699983999079144],
            [-122.287998, 38.839930999078156],
            [-122.395056, 38.864244999077982],
            [-122.421904, 38.90376999907771],
            [-122.403941, 38.925288999077566]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "033",
        COUNTYNS: "01622959",
        AFFGEOID: "0500000US26033",
        GEOID: "26033",
        NAME: "Chippewa",
        LSAD: "06",
        ALAND: 4036522461,
        AWATER: 2952482167
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.237873177359575, 46.75570278031514],
            [-85.173042, 46.763633999058641],
            [-84.964652, 46.772844999058663],
            [-85.028291, 46.675124999058482],
            [-85.027374, 46.553755999058239],
            [-84.969464, 46.476289999058096],
            [-84.849767, 46.460244999058077],
            [-84.678423, 46.487693999058109],
            [-84.607945, 46.456746999058062],
            [-84.493401, 46.440312999058037],
            [-84.461827, 46.466565999058083],
            [-84.420274, 46.501076999058128],
            [-84.293016, 46.492802999058121],
            [-84.193729, 46.539919999058206],
            [-84.117925, 46.517618999058172],
            [-84.125026, 46.470142999058091],
            [-84.138906, 46.37222099905793],
            [-84.097766, 46.256511999057743],
            [-84.108089, 46.241237999057716],
            [-84.114941, 46.174113999057617],
            [-84.026536, 46.131647999057563],
            [-83.974012, 46.081551999057496],
            [-83.882303, 46.042064999057438],
            [-83.815826, 46.108528999057533],
            [-83.719791, 46.101030999057521],
            [-83.598612, 46.09008499905751],
            [-83.480639, 45.996163999057387],
            [-83.526347, 45.918635999057301],
            [-83.583052, 45.91591899905729],
            [-83.65766, 45.945462999057327],
            [-83.80104, 45.937581999057322],
            [-83.910838, 45.965612999057349],
            [-84.080071, 45.97082199905735],
            [-84.114606562518802, 45.967908373852758],
            [-84.239332, 45.984913999057376],
            [-84.238884, 46.071456999057482],
            [-84.36309, 46.07157399905747],
            [-84.363503, 46.157983999057585],
            [-85.112513, 46.158262999057584],
            [-85.113329, 46.245721999057729],
            [-85.237839, 46.245429999057727],
            [-85.237873177359575, 46.75570278031514]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "471",
        COUNTYNS: "01384021",
        AFFGEOID: "0500000US48471",
        GEOID: "48471",
        NAME: "Walker",
        LSAD: "06",
        ALAND: 2031033548,
        AWATER: 44838548
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.863059, 30.864124999168617],
            [-95.716508, 30.911404999167889],
            [-95.616233, 30.910195999167907],
            [-95.617872, 30.930417999167588],
            [-95.434781, 31.058097999165632],
            [-95.434335, 30.937404999167484],
            [-95.396697, 30.857705999168726],
            [-95.32748, 30.859546999168689],
            [-95.359156, 30.504368999174286],
            [-95.598971, 30.509001999174213],
            [-95.83024, 30.630283999172285],
            [-95.863059, 30.864124999168617]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "089",
        COUNTYNS: "01101835",
        AFFGEOID: "0500000US40089",
        GEOID: "40089",
        NAME: "McCurtain",
        LSAD: "06",
        ALAND: 4793134981,
        AWATER: 133993739
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.156521, 34.156964999122984],
            [-95.151233, 34.243905999121921],
            [-95.046585, 34.243670999121939],
            [-95.046746, 34.507228999118809],
            [-94.937605, 34.506830999118804],
            [-94.461168686155901, 34.507456650691296],
            [-94.465425, 34.359547999120551],
            [-94.47015267816208, 34.189864291248689],
            [-94.474895, 34.019654999124654],
            [-94.477266741529803, 33.940910927060429],
            [-94.481842, 33.789007999127506],
            [-94.485875, 33.637866999129415],
            [-94.528928, 33.621839999129612],
            [-94.572872, 33.669885999129001],
            [-94.630586, 33.673400999128965],
            [-94.714865, 33.707260999128536],
            [-94.731932901995904, 33.720830515408679],
            [-94.766146, 33.748030999128034],
            [-94.841634, 33.739430999128132],
            [-94.902276, 33.776288999127672],
            [-94.93956, 33.810502999127245],
            [-94.98165, 33.852283999126733],
            [-95.046568, 33.862564999126597],
            [-95.095002, 33.904815999126072],
            [-95.149462, 33.936335999125681],
            [-95.155907617107502, 33.938482387695466],
            [-95.156521, 34.156964999122984]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "071",
        COUNTYNS: "01550042",
        AFFGEOID: "0500000US54071",
        GEOID: "54071",
        NAME: "Pendleton",
        LSAD: "06",
        ALAND: 1802751454,
        AWATER: 5437237
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.619174, 38.620814999079712],
            [-79.626774, 38.664213999079408],
            [-79.510073, 38.780711999078576],
            [-79.519474, 38.892009999077793],
            [-79.349867, 38.957508999077348],
            [-79.134296, 38.81333999907833],
            [-79.057253, 38.761412999078708],
            [-79.088055, 38.690114999079213],
            [-79.092955, 38.65951699907945],
            [-79.154357, 38.60651799907982],
            [-79.201459, 38.52782099908039],
            [-79.228423002707785, 38.479741556568044],
            [-79.23162, 38.474040999080792],
            [-79.297758, 38.416437999081211],
            [-79.311296065514398, 38.418454600536194],
            [-79.370302, 38.427243999081135],
            [-79.476638, 38.457227999080914],
            [-79.54257, 38.553216999080206],
            [-79.649075, 38.591514999079912],
            [-79.619174, 38.620814999079712]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "049",
        COUNTYNS: "01804504",
        AFFGEOID: "0500000US72049",
        GEOID: "72049",
        NAME: "Culebra",
        LSAD: "13",
        ALAND: 30115058,
        AWATER: 407158805
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-65.342068, 18.345289999435664],
            [-65.255933, 18.342116999435746],
            [-65.221568, 18.320958999436307],
            [-65.241257, 18.301077999436835],
            [-65.283269, 18.28021399943739],
            [-65.337451, 18.308307999436643],
            [-65.342068, 18.345289999435664]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "017",
        COUNTYNS: "00295752",
        AFFGEOID: "0500000US12017",
        GEOID: "12017",
        NAME: "Citrus",
        LSAD: "06",
        ALAND: 1507111917,
        AWATER: 495578435
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.755573808695701, 29.000930117459763],
            [-82.712039, 29.03083399919884],
            [-82.612653, 29.00927099919922],
            [-82.535591, 29.044854999198598],
            [-82.477639, 29.052483999198468],
            [-82.362763, 28.998928999199393],
            [-82.361831, 28.998314999199408],
            [-82.361805, 28.997808999199417],
            [-82.362025, 28.997444999199423],
            [-82.363247, 28.99696299919944],
            [-82.36318, 28.996464999199429],
            [-82.362074, 28.995670999199458],
            [-82.362119, 28.99432699919948],
            [-82.361575, 28.99364899919949],
            [-82.360954, 28.993379999199494],
            [-82.358711, 28.993340999199493],
            [-82.357609, 28.993143999199496],
            [-82.356336, 28.991702999199525],
            [-82.35527, 28.991344999199526],
            [-82.352394, 28.991241999199527],
            [-82.350285, 28.989491999199565],
            [-82.349293, 28.988392999199579],
            [-82.348339, 28.987889999199592],
            [-82.346702, 28.987754999199595],
            [-82.345809, 28.987103999199601],
            [-82.342901, 28.98724399919961],
            [-82.341887, 28.987844999199588],
            [-82.341364, 28.987775999199595],
            [-82.339901, 28.986039999199619],
            [-82.33867, 28.985488999199635],
            [-82.337856, 28.984760999199644],
            [-82.337157, 28.983518999199664],
            [-82.335335, 28.98312399919968],
            [-82.334675, 28.982654999199688],
            [-82.333944, 28.981522999199704],
            [-82.333224, 28.982367999199685],
            [-82.332096, 28.981969999199702],
            [-82.33193, 28.981252999199704],
            [-82.331349, 28.980423999199722],
            [-82.330095, 28.980025999199732],
            [-82.329674, 28.978949999199745],
            [-82.32862, 28.978698999199754],
            [-82.327325, 28.978808999199742],
            [-82.325925, 28.977391999199774],
            [-82.32493, 28.976705999199783],
            [-82.323238, 28.976085999199796],
            [-82.323163, 28.975334999199809],
            [-82.322202, 28.975083999199814],
            [-82.321281, 28.97534999919981],
            [-82.320735, 28.974796999199814],
            [-82.321284, 28.974026999199836],
            [-82.32106, 28.973492999199841],
            [-82.320274, 28.973145999199854],
            [-82.319423, 28.973425999199844],
            [-82.319271, 28.974404999199827],
            [-82.318922, 28.974344999199825],
            [-82.317697, 28.973423999199849],
            [-82.315836, 28.973270999199848],
            [-82.315447, 28.972753999199853],
            [-82.315726, 28.971804999199865],
            [-82.315619, 28.970875999199894],
            [-82.315153, 28.970212999199898],
            [-82.313397, 28.969475999199911],
            [-82.313149, 28.969084999199925],
            [-82.31277, 28.967195999199948],
            [-82.312254, 28.966644999199964],
            [-82.312067, 28.964899999199993],
            [-82.311686, 28.963736999200012],
            [-82.311885, 28.962032999200041],
            [-82.311697, 28.960390999200069],
            [-82.169141, 28.792953999203007],
            [-82.263052, 28.667633999205229],
            [-82.417684, 28.665896999205252],
            [-82.418353, 28.694858999204737],
            [-82.668711402144794, 28.694303267789952],
            [-82.668722, 28.695657999204737],
            [-82.712373, 28.720920999204278],
            [-82.713121, 28.800282999202878],
            [-82.730245, 28.850154999201994],
            [-82.688864, 28.905608999201036],
            [-82.723861, 28.953505999200186],
            [-82.755573808695701, 29.000930117459763]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "113",
        COUNTYNS: "01639769",
        AFFGEOID: "0500000US47113",
        GEOID: "47113",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 1442901129,
        AWATER: 3930429
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.069544, 35.692678999105624],
            [-89.021364, 35.67095299910585],
            [-88.916181, 35.797306999104549],
            [-88.706811, 35.791074999104616],
            [-88.606862, 35.78935399910462],
            [-88.603575, 35.626083999106328],
            [-88.613611, 35.588088999106724],
            [-88.675867, 35.489162999107783],
            [-88.841607, 35.427825999108435],
            [-89.078876, 35.431427999108401],
            [-89.069544, 35.692678999105624]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "047",
        COUNTYNS: "01248003",
        AFFGEOID: "0500000US45047",
        GEOID: "45047",
        NAME: "Greenwood",
        LSAD: "06",
        ALAND: 1177746759,
        AWATER: 21226294
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.334234, 34.342629999120746],
            [-82.246337, 34.409675999119955],
            [-82.168857, 34.330150999120896],
            [-82.08367, 34.299239999121276],
            [-81.945019, 34.203147999122422],
            [-81.897154, 34.146306999123112],
            [-81.870084, 34.135356999123246],
            [-81.990713, 33.996614999124937],
            [-82.008298, 33.961645999125359],
            [-82.046974, 33.953209999125484],
            [-82.04513, 33.983395999125101],
            [-82.200286, 33.978596999125166],
            [-82.245055, 34.018780999124665],
            [-82.306243, 33.971583999125244],
            [-82.326947, 34.064119999124109],
            [-82.267184, 34.108359999123579],
            [-82.247747, 34.219618999122233],
            [-82.334234, 34.342629999120746]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "007",
        COUNTYNS: "01605069",
        AFFGEOID: "0500000US56007",
        GEOID: "56007",
        NAME: "Carbon",
        LSAD: "06",
        ALAND: 20455215011,
        AWATER: 171386237
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.929736, 41.659595999062986],
            [-107.50972, 41.657451999062992],
            [-107.522321, 42.087956999061475],
            [-107.522722, 42.261755999060917],
            [-107.522923, 42.434355999060415],
            [-106.654781, 42.431093999060408],
            [-106.075852, 42.433217999060403],
            [-106.073403, 42.433235999060415],
            [-106.074919, 42.086648999061481],
            [-106.068406, 41.39502799906402],
            [-106.322885, 41.395140999064019],
            [-106.321169215913997, 40.998215010133734],
            [-106.860377948665004, 41.000718628302813],
            [-107.317795759790002, 41.002842480297907],
            [-107.367443, 41.003072999065708],
            [-107.918418289848987, 41.00122706970982],
            [-107.92988, 41.39852999906401],
            [-107.929736, 41.659595999062986]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "083",
        COUNTYNS: "00659487",
        AFFGEOID: "0500000US27083",
        GEOID: "27083",
        NAME: "Lyon",
        LSAD: "06",
        ALAND: 1850315135,
        AWATER: 18794796
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.09295, 44.630485999056816],
            [-95.604001, 44.629944999056818],
            [-95.594817, 44.54230499905686],
            [-95.59357, 44.195529999057086],
            [-96.063778, 44.196424999057101],
            [-96.07858, 44.196619999057077],
            [-96.08041, 44.543129999056852],
            [-96.09295, 44.630485999056816]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "049",
        COUNTYNS: "01248004",
        AFFGEOID: "0500000US45049",
        GEOID: "45049",
        NAME: "Hampton",
        LSAD: "06",
        ALAND: 1450123401,
        AWATER: 7276755
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.413116795173607, 32.744261327102677],
            [-81.338543, 32.768455999140841],
            [-81.261662, 32.839138999139884],
            [-81.236956, 32.940243999138538],
            [-81.082291, 33.026629999137363],
            [-80.986703, 32.900673999139045],
            [-80.900059, 32.832631999139977],
            [-80.826309, 32.704320999141721],
            [-80.869705, 32.66093499914232],
            [-80.902448, 32.621560999142851],
            [-81.014625, 32.753057999141049],
            [-81.284238381765604, 32.547110945428884],
            [-81.328753, 32.561227999143682],
            [-81.386901902694191, 32.598964853042958],
            [-81.397106, 32.605586999143071],
            [-81.393818, 32.653490999142413],
            [-81.41267, 32.739082999141246],
            [-81.413116795173607, 32.744261327102677]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "125",
        COUNTYNS: "01639777",
        AFFGEOID: "0500000US47125",
        GEOID: "47125",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 1396430651,
        AWATER: 12080001
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.64114589700209, 36.638036046706326],
            [-87.347796, 36.641439999096185],
            [-87.335979050080795, 36.641577159589289],
            [-87.115003556688407, 36.644142043747372],
            [-87.150371, 36.566823999096904],
            [-87.120443, 36.455459999097961],
            [-87.280597, 36.354165999098953],
            [-87.286501, 36.321932999099268],
            [-87.303129, 36.312441999099356],
            [-87.513533, 36.334712999099139],
            [-87.586957, 36.341779999099067],
            [-87.592358, 36.367662999098819],
            [-87.64114589700209, 36.638036046706326]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "025",
        COUNTYNS: "01448026",
        AFFGEOID: "0500000US49025",
        GEOID: "49025",
        NAME: "Kane",
        LSAD: "06",
        ALAND: 10333912540,
        AWATER: 306169207
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.901162, 37.543485999088141],
            [-112.68375, 37.543691999088139],
            [-112.38192, 37.542867999088152],
            [-111.84317, 37.535743999088204],
            [-111.250719, 37.539973999088176],
            [-110.646394, 37.541062999088169],
            [-110.671897, 37.477490999088701],
            [-110.745999, 37.452690999088908],
            [-110.699197, 37.430690999089094],
            [-110.736098, 37.345191999089835],
            [-110.777799, 37.322991999090029],
            [-110.869804, 37.348690999089797],
            [-110.837202, 37.299291999090229],
            [-110.873704, 37.28699199909034],
            [-110.899517, 37.176152999091308],
            [-110.958333, 37.124437999091775],
            [-111.05767, 37.099394999091984],
            [-111.178349, 37.102612999091967],
            [-111.247488, 37.02160399909269],
            [-111.268784, 37.054199999092397],
            [-111.314856, 37.012132999092771],
            [-111.412783987872999, 37.001477686248876],
            [-112.35769, 37.00102499909287],
            [-112.538571, 37.000744127974883],
            [-112.545094, 37.00073399909288],
            [-112.829501944372012, 37.00038640040728],
            [-112.899190897616009, 37.000301227721287],
            [-112.900993, 37.500020999088505],
            [-112.901162, 37.543485999088141]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "251",
        COUNTYNS: "01383911",
        AFFGEOID: "0500000US48251",
        GEOID: "48251",
        NAME: "Johnson",
        LSAD: "06",
        ALAND: 1877223454,
        AWATER: 25002941
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.617066, 32.555483999143767],
            [-97.550582, 32.555390999143768],
            [-97.386367, 32.553558999143796],
            [-97.34899, 32.553144999143797],
            [-97.313984, 32.552626999143811],
            [-97.086834, 32.549427999143852],
            [-97.086191, 32.265450999147809],
            [-97.462821, 32.184682999148961],
            [-97.476086, 32.173459999149117],
            [-97.514682, 32.133604999149682],
            [-97.615055, 32.203580999148691],
            [-97.615286, 32.318617999147065],
            [-97.617066, 32.555483999143767]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "037",
        COUNTYNS: "01723078",
        AFFGEOID: "0500000US30037",
        GEOID: "30037",
        NAME: "Golden Valley",
        LSAD: "06",
        ALAND: 3041740596,
        AWATER: 2621792
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.653871, 46.219403999057704],
            [-109.402906, 46.219705999057688],
            [-109.403544, 46.490656999058125],
            [-109.389163, 46.693624999058507],
            [-109.389208, 46.751350999058616],
            [-109.00957, 46.75070199905862],
            [-108.988995, 46.577850999058278],
            [-108.904904, 46.576907999058271],
            [-108.860801, 46.451664999058053],
            [-108.862277, 46.364585999057908],
            [-108.779272, 46.277397999057769],
            [-108.779733, 46.132456999057567],
            [-108.924589, 46.132369999057566],
            [-109.416227, 46.132370999057564],
            [-109.417158, 46.044758999057443],
            [-109.605153, 46.044993999057453],
            [-109.65384, 46.132464999057575],
            [-109.653871, 46.219403999057704]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "045",
        COUNTYNS: "01719602",
        AFFGEOID: "0500000US30045",
        GEOID: "30045",
        NAME: "Judith Basin",
        LSAD: "06",
        ALAND: 4842466690,
        AWATER: 2066739
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.788302, 47.307847999059952],
            [-110.767125, 47.359127999060092],
            [-110.638911, 47.359031999060086],
            [-110.639559, 47.416485999060249],
            [-110.213596, 47.416656999060251],
            [-110.213285, 47.358770999060091],
            [-110.127952, 47.359019999060095],
            [-110.127699, 47.271909999059858],
            [-110.000289, 47.272132999059849],
            [-110.000024, 47.228715999059737],
            [-109.872475, 47.228882999059742],
            [-109.871985, 47.185322999059629],
            [-109.756568, 47.185693999059623],
            [-109.755423, 46.896022999058935],
            [-109.835424, 46.809160999058747],
            [-109.756666, 46.78038899905868],
            [-109.745745, 46.694096999058509],
            [-109.819866, 46.69344599905849],
            [-109.969221, 46.752908999058612],
            [-110.273337, 46.710508999058533],
            [-110.328511, 46.673177999058467],
            [-110.466812, 46.678794999058475],
            [-110.652877, 46.823625999058777],
            [-110.636381, 46.837160999058796],
            [-110.645212, 47.095869999059403],
            [-110.770698, 47.096545999059401],
            [-110.788302, 47.307847999059952]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "059",
        COUNTYNS: "01719600",
        AFFGEOID: "0500000US30059",
        GEOID: "30059",
        NAME: "Meagher",
        LSAD: "06",
        ALAND: 6194911314,
        AWATER: 7474581
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.658132, 46.913422999058973],
            [-111.539214, 46.913370999058976],
            [-111.539373, 47.000558999059166],
            [-111.285268, 47.001025999059166],
            [-111.158386, 47.011770999059202],
            [-111.158559, 47.088300999059392],
            [-111.080621, 47.087780999059369],
            [-111.104937, 47.028805999059237],
            [-110.913035, 46.98055899905912],
            [-110.856038, 46.92134699905899],
            [-110.752715, 46.890183999058927],
            [-110.751586, 46.844530999058819],
            [-110.652877, 46.823625999058777],
            [-110.466812, 46.678794999058475],
            [-110.328511, 46.673177999058467],
            [-110.273337, 46.710508999058533],
            [-110.281971, 46.220604999057691],
            [-110.281916, 46.18439699905764],
            [-110.409271, 46.192064999057649],
            [-110.783833, 46.19269899905764],
            [-111.061956, 46.192833999057648],
            [-111.075133, 46.250798999057736],
            [-111.126849, 46.273104999057757],
            [-111.057226, 46.403406999057978],
            [-111.125122, 46.439754999058046],
            [-111.246519, 46.438108999058024],
            [-111.257378, 46.498484999058128],
            [-111.344946, 46.545939999058227],
            [-111.330729, 46.633764999058386],
            [-111.411196, 46.639975999058393],
            [-111.455397, 46.742532999058596],
            [-111.498095, 46.762501999058642],
            [-111.639502, 46.832133999058783],
            [-111.658132, 46.913422999058973]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "035",
        COUNTYNS: "01383803",
        AFFGEOID: "0500000US48035",
        GEOID: "48035",
        NAME: "Bosque",
        LSAD: "06",
        ALAND: 2545908057,
        AWATER: 50666112
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.864864, 32.087327999150354],
            [-97.642178, 32.201295999148719],
            [-97.615055, 32.203580999148691],
            [-97.514682, 32.133604999149682],
            [-97.476086, 32.173459999149117],
            [-97.485968, 32.017610999151344],
            [-97.379992, 31.972739999151997],
            [-97.438765, 31.933505999152558],
            [-97.382848, 31.870787999153475],
            [-97.28206, 31.843151999153875],
            [-97.32653, 31.788359999154668],
            [-97.277265, 31.745491999155291],
            [-97.60523, 31.587761999157628],
            [-97.689834, 31.711018999155812],
            [-97.766431, 31.672460999156375],
            [-98.005462, 32.01789699915134],
            [-97.864864, 32.087327999150354]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "057",
        COUNTYNS: "01248008",
        AFFGEOID: "0500000US45057",
        GEOID: "45057",
        NAME: "Lancaster",
        LSAD: "06",
        ALAND: 1422134720,
        AWATER: 15444070
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.903481186712099, 35.072087773158437],
            [-80.840566909021376, 35.001471528507416],
            [-80.782042, 34.935781999113857],
            [-80.796997, 34.823873999115129],
            [-80.561708549382402, 34.817482399451706],
            [-80.488532, 34.704583999116501],
            [-80.408373, 34.61476499911754],
            [-80.550552, 34.560115999118175],
            [-80.527434, 34.50393499911884],
            [-80.739966, 34.479956999119111],
            [-80.743345, 34.539915999118413],
            [-80.879227, 34.457775999119377],
            [-80.898722, 34.486693999119048],
            [-80.877491, 34.543145999118373],
            [-80.896406, 34.631780999117339],
            [-80.860274, 34.695846999116604],
            [-80.876121, 34.788046999115544],
            [-80.898215, 34.820745999115161],
            [-80.866694, 34.84807099911486],
            [-80.868652, 34.951142999113692],
            [-80.906239954082494, 35.075184268877898],
            [-80.903481186712099, 35.072087773158437]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "119",
        COUNTYNS: "00349324",
        AFFGEOID: "0500000US13119",
        GEOID: "13119",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 677294070,
        AWATER: 12811302
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.398396, 34.460882999119349],
            [-83.177838, 34.477016999119144],
            [-83.102873826035704, 34.537430908883842],
            [-83.096858, 34.531523999118512],
            [-83.0505729641024, 34.495053651288039],
            [-83.094238, 34.41590099911987],
            [-83.11336, 34.273529999121571],
            [-83.172745, 34.238521999121993],
            [-83.313591, 34.259143999121754],
            [-83.355269, 34.223729999122178],
            [-83.337849, 34.261032999121724],
            [-83.393915, 34.32480099912096],
            [-83.398396, 34.460882999119349]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "021",
        COUNTYNS: "00295754",
        AFFGEOID: "0500000US12021",
        GEOID: "12021",
        NAME: "Collier",
        LSAD: "06",
        ALAND: 5176983078,
        AWATER: 792880431
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.846485247961695, 26.33037204775211],
            [-81.819019, 26.316244999249591],
            [-81.746167, 26.316895999249581],
            [-81.6579, 26.317562999249567],
            [-81.659506, 26.421076999247504],
            [-81.56218, 26.422624999247471],
            [-81.563763, 26.513323999245671],
            [-81.271768, 26.517068999245595],
            [-81.26855, 26.253044999250857],
            [-80.879809, 26.259454999250728],
            [-80.872932, 25.979433999256369],
            [-80.873096, 25.805376999259916],
            [-81.441864771799189, 25.803129027690964],
            [-81.472239, 25.816929999259678],
            [-81.614735, 25.893976999258111],
            [-81.640236958056803, 25.877537578543542],
            [-81.672633, 25.856653999258867],
            [-81.727086, 25.907206999257838],
            [-81.757463, 26.000373999255942],
            [-81.808833, 26.152245999252877],
            [-81.844555, 26.327711999249367],
            [-81.846485247961695, 26.33037204775211]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "119",
        COUNTYNS: "00295740",
        AFFGEOID: "0500000US12119",
        GEOID: "12119",
        NAME: "Sumter",
        LSAD: "06",
        ALAND: 1418509811,
        AWATER: 83231177
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.311697, 28.960390999200069],
            [-81.95419, 28.960052999200069],
            [-81.954247, 28.551689999207284],
            [-81.957641, 28.345179999210998],
            [-81.958029, 28.308924999211659],
            [-82.055819, 28.312793999211586],
            [-82.054694, 28.478396999208602],
            [-82.054469, 28.521366999207835],
            [-82.208184, 28.572053999206926],
            [-82.274823, 28.656197999205428],
            [-82.263052, 28.667633999205229],
            [-82.169141, 28.792953999203007],
            [-82.311697, 28.960390999200069]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "451",
        COUNTYNS: "01384011",
        AFFGEOID: "0500000US48451",
        GEOID: "48451",
        NAME: "Tom Green",
        LSAD: "06",
        ALAND: 3941965427,
        AWATER: 48077315
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.26763, 31.556461999158088],
            [-100.865256, 31.562293999158005],
            [-100.861099, 31.705417999155884],
            [-100.82537, 31.696154999156025],
            [-100.235761, 31.692969999156077],
            [-100.233783, 31.582195999157712],
            [-100.111234, 31.580266999157736],
            [-100.115216, 31.087993999165167],
            [-100.688764, 31.086575999165184],
            [-100.693065, 31.523953999158575],
            [-101.267947, 31.5286879991585],
            [-101.26763, 31.556461999158088]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "203",
        COUNTYNS: "01785231",
        AFFGEOID: "0500000US17203",
        GEOID: "17203",
        NAME: "Woodford",
        LSAD: "06",
        ALAND: 1366998850,
        AWATER: 38437077
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.562394, 40.793636999066685],
            [-89.47233, 40.921205999066096],
            [-89.160278, 40.926798999066058],
            [-89.047718, 40.925748999066059],
            [-88.93139, 40.927739999066048],
            [-88.929331, 40.753336999066875],
            [-88.986896, 40.752296999066878],
            [-88.9847, 40.664953999067308],
            [-89.044368, 40.627427999067493],
            [-89.133752, 40.596733999067638],
            [-89.26939, 40.59432899906767],
            [-89.327343, 40.615565999067549],
            [-89.330167, 40.748256999066896],
            [-89.554994, 40.747636999066906],
            [-89.562394, 40.793636999066685]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "157",
        COUNTYNS: "01074090",
        AFFGEOID: "0500000US39157",
        GEOID: "39157",
        NAME: "Tuscarawas",
        LSAD: "06",
        ALAND: 1469659271,
        AWATER: 10390938
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.709132, 40.444774999068414],
            [-81.669654, 40.443865999068429],
            [-81.649199, 40.635105999067456],
            [-81.459019, 40.666919999067289],
            [-81.317739, 40.651578999067368],
            [-81.321659, 40.56664799906779],
            [-81.264744, 40.565041999067802],
            [-81.268434, 40.476713999068259],
            [-81.269951, 40.433485999068466],
            [-81.275319, 40.303433999069149],
            [-81.334601, 40.304315999069154],
            [-81.338057, 40.214252999069636],
            [-81.622683, 40.221307999069602],
            [-81.61603, 40.368117999068815],
            [-81.712288, 40.370803999068798],
            [-81.709132, 40.444774999068414]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "015",
        COUNTYNS: "00424209",
        AFFGEOID: "0500000US17015",
        GEOID: "17015",
        NAME: "Carroll",
        LSAD: "06",
        ALAND: 1152707885,
        AWATER: 55841450
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.315696514271508, 42.193946431312526],
            [-89.91965, 42.196823999061124],
            [-89.898103, 42.196273999061113],
            [-89.688486, 42.19911199906111],
            [-89.685366, 41.930339999061999],
            [-90.128204, 41.928976999062002],
            [-90.158153033709496, 41.929843226438109],
            [-90.156902, 41.938180999061984],
            [-90.140613, 41.995998999061776],
            [-90.159682451122677, 42.033087256647562],
            [-90.163446, 42.040406999061638],
            [-90.161159, 42.106371999061416],
            [-90.207421, 42.149108999061276],
            [-90.26908, 42.174499999061197],
            [-90.315696514271508, 42.193946431312526]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "147",
        COUNTYNS: "01687995",
        AFFGEOID: "0500000US13147",
        GEOID: "13147",
        NAME: "Hart",
        LSAD: "06",
        ALAND: 601908709,
        AWATER: 63583341
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.094238, 34.41590099911987],
            [-83.0505729641024, 34.495053651288039],
            [-83.048289, 34.493253999118977],
            [-82.99509, 34.472482999119194],
            [-82.991388153182299, 34.472980626354996],
            [-82.925766, 34.481801999119106],
            [-82.873831, 34.471507999119218],
            [-82.841997, 34.399765999120071],
            [-82.82342, 34.358871999120552],
            [-82.780308, 34.296700999121292],
            [-82.774628731012086, 34.288366817376087],
            [-82.980018, 34.210065999122342],
            [-83.078004, 34.223605999122171],
            [-83.11336, 34.273529999121571],
            [-83.094238, 34.41590099911987]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "165",
        COUNTYNS: "01074094",
        AFFGEOID: "0500000US39165",
        GEOID: "39165",
        NAME: "Warren",
        LSAD: "06",
        ALAND: 1039437339,
        AWATER: 15502922
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.365232, 39.589492999073236],
            [-84.114195, 39.577982999073313],
            [-83.977005, 39.569168999073362],
            [-84.006782, 39.255067999075358],
            [-84.259431, 39.270795999075247],
            [-84.262581, 39.288384999075141],
            [-84.353209, 39.292286999075124],
            [-84.332089, 39.548802999073487],
            [-84.365232, 39.589492999073236]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "011",
        COUNTYNS: "00295753",
        AFFGEOID: "0500000US12011",
        GEOID: "12011",
        NAME: "Broward",
        LSAD: "06",
        ALAND: 3120024303,
        AWATER: 306053086
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.881233, 26.33380599924924],
            [-80.297471, 26.33435599924923],
            [-80.24977, 26.34152599924909],
            [-80.204083, 26.327821999249363],
            [-80.170149, 26.327780999249367],
            [-80.099747, 26.327667999249364],
            [-80.075874439443183, 26.320912769129695],
            [-80.085565, 26.24925899925093],
            [-80.10865850558919, 26.093293885509464],
            [-80.109566, 26.087164999254188],
            [-80.112978251285398, 26.017023960089613],
            [-80.115017964499998, 25.975096331524355],
            [-80.115128923890097, 25.972815489267106],
            [-80.206228, 25.972695999256509],
            [-80.295187, 25.970569999256547],
            [-80.326712, 25.957117999256823],
            [-80.680016, 25.956856999256829],
            [-80.680038, 25.978748999256386],
            [-80.872932, 25.979433999256369],
            [-80.879809, 26.259454999250728],
            [-80.881233, 26.33380599924924]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "169",
        COUNTYNS: "01074096",
        AFFGEOID: "0500000US39169",
        GEOID: "39169",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 1437086621,
        AWATER: 5055679
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.129334, 40.991806999065759],
            [-81.977381, 40.989960999065772],
            [-81.688491, 40.988589999065773],
            [-81.647689, 40.988562999065763],
            [-81.647735, 40.914015999066109],
            [-81.647991, 40.825775999066536],
            [-81.650045, 40.668116999067287],
            [-82.126199, 40.668228999067281],
            [-82.129334, 40.991806999065759]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "179",
        COUNTYNS: "00357095",
        AFFGEOID: "0500000US13179",
        GEOID: "13179",
        NAME: "Liberty",
        LSAD: "06",
        ALAND: 1336136727,
        AWATER: 224402177
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.761735, 32.047899999150921],
            [-81.718658, 32.089350999150319],
            [-81.603665, 32.008468999151475],
            [-81.593429, 31.971349999152014],
            [-81.475761, 31.974254999151974],
            [-81.407737, 31.944301999152405],
            [-81.355837, 31.814899999154299],
            [-81.259887, 31.75375399915518],
            [-81.154731, 31.713069999155774],
            [-81.135298590200492, 31.710564597121905],
            [-81.139394, 31.699916999155977],
            [-81.133493, 31.623347999157101],
            [-81.173079, 31.555907999158098],
            [-81.174832636590594, 31.539596448653135],
            [-81.22222, 31.630419999156992],
            [-81.314548, 31.659381999156555],
            [-81.43576, 31.642054999156827],
            [-81.491798, 31.699574999155974],
            [-81.641761, 31.765450999155007],
            [-81.765357, 31.87453999915342],
            [-81.750257, 31.973206999151984],
            [-81.824405, 32.014881999151392],
            [-81.761735, 32.047899999150921]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "033",
        COUNTYNS: "01074029",
        AFFGEOID: "0500000US39033",
        GEOID: "39033",
        NAME: "Crawford",
        LSAD: "06",
        ALAND: 1040619677,
        AWATER: 2359515
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.112737, 40.99344699906576],
            [-82.855614, 40.993665999065755],
            [-82.829512, 40.99662399906574],
            [-82.724792, 40.995637999065742],
            [-82.727164, 40.711202999067083],
            [-82.838905, 40.712599999067073],
            [-82.858302, 40.705018999067114],
            [-83.11136, 40.702914999067119],
            [-83.112737, 40.99344699906576]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "057",
        COUNTYNS: "00295757",
        AFFGEOID: "0500000US12057",
        GEOID: "12057",
        NAME: "Hillsborough",
        LSAD: "06",
        ALAND: 2642602901,
        AWATER: 635590801
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.651165, 28.173265999214117],
            [-82.474056, 28.171934999214148],
            [-82.259741, 28.171461999214149],
            [-82.105853, 28.171649999214146],
            [-82.056261, 28.171591999214147],
            [-82.05526, 27.927179999218634],
            [-82.054874, 27.907725999218993],
            [-82.054349, 27.646381999223852],
            [-82.552887207063591, 27.645447595589371],
            [-82.514265, 27.70558799922274],
            [-82.477638, 27.723003999222417],
            [-82.43198, 27.768091999221586],
            [-82.448786, 27.810035999220801],
            [-82.4734467487892, 27.817585624747959],
            [-82.489849, 27.822606999220561],
            [-82.553946, 27.848461999220092],
            [-82.566380424636094, 27.836338311134512],
            [-82.573419, 27.90899999921897],
            [-82.648817, 27.979177999217669],
            [-82.650602, 28.14492799921463],
            [-82.651165, 28.173265999214117]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "199",
        COUNTYNS: "00346892",
        AFFGEOID: "0500000US13199",
        GEOID: "13199",
        NAME: "Meriwether",
        LSAD: "06",
        ALAND: 1298162528,
        AWATER: 10832504
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.862359, 33.191172999135183],
            [-84.85236, 33.223589999134774],
            [-84.502352, 33.2210549991348],
            [-84.496783, 33.183865999135286],
            [-84.536991, 33.036729999137243],
            [-84.52702, 32.970547999138127],
            [-84.526902, 32.914097999138875],
            [-84.506888, 32.881787999139313],
            [-84.570669, 32.845178999139804],
            [-84.700538, 32.844639999139808],
            [-84.751338, 32.869199999139482],
            [-84.861768, 32.872494999139434],
            [-84.862359, 33.191172999135183]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "103",
        COUNTYNS: "00450373",
        AFFGEOID: "0500000US18103",
        GEOID: "18103",
        NAME: "Miami",
        LSAD: "06",
        ALAND: 968246952,
        AWATER: 9184624
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.168459, 40.996096999065749],
            [-85.946436, 40.999194999065729],
            [-85.939525, 40.650990999067375],
            [-85.864791, 40.651692999067379],
            [-85.863641, 40.565524999067804],
            [-86.013325, 40.564127999067807],
            [-86.165024, 40.562648999067818],
            [-86.169012, 40.909835999066132],
            [-86.168459, 40.996096999065749]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "051",
        COUNTYNS: "00277290",
        AFFGEOID: "0500000US06051",
        GEOID: "06051",
        NAME: "Mono",
        LSAD: "06",
        ALAND: 7896838493,
        AWATER: 214695673
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.639205, 38.326879999081875],
            [-119.542367, 38.48165699908072],
            [-119.619066, 38.603528999079842],
            [-119.585406144839013, 38.713150899750246],
            [-119.328704521075991, 38.534351926877846],
            [-119.279262, 38.49991399908059],
            [-119.157226100872009, 38.41439197084253],
            [-118.949673, 38.268939999082328],
            [-118.500958, 37.949018999084821],
            [-118.427995325156999, 37.896224279995039],
            [-118.022181, 37.602582999087645],
            [-117.833504044143993, 37.464938765333301],
            [-118.775014, 37.463051999088819],
            [-118.850474, 37.47579599908871],
            [-118.91706, 37.550335999088091],
            [-119.022363, 37.585736999087786],
            [-119.124312, 37.733943999086549],
            [-119.268979, 37.739229999086518],
            [-119.20128, 37.804328999085975],
            [-119.200283, 37.885826999085317],
            [-119.308389, 37.946823999084835],
            [-119.30459, 38.023890999084209],
            [-119.345922, 38.08311299908376],
            [-119.469456, 38.128282999083403],
            [-119.576496, 38.157660999083177],
            [-119.632671, 38.198857999082861],
            [-119.604182, 38.234960999082581],
            [-119.651509, 38.286461999082192],
            [-119.639205, 38.326879999081875]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "109",
        COUNTYNS: "00450375",
        AFFGEOID: "0500000US18109",
        GEOID: "18109",
        NAME: "Morgan",
        LSAD: "06",
        ALAND: 1046298638,
        AWATER: 14115819
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.64832, 39.565440999073388],
            [-86.65665, 39.601394999073179],
            [-86.465605, 39.600768999073175],
            [-86.335904, 39.631984999072991],
            [-86.326341, 39.632176999072982],
            [-86.249885, 39.633644999072978],
            [-86.251684, 39.341671999074805],
            [-86.381395, 39.339751999074814],
            [-86.611706, 39.340250999074797],
            [-86.630818, 39.346947999074764],
            [-86.63191, 39.441085999074168],
            [-86.68571, 39.470062999073988],
            [-86.64832, 39.565440999073388]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "015",
        COUNTYNS: "00465197",
        AFFGEOID: "0500000US19015",
        GEOID: "19015",
        NAME: "Boone",
        LSAD: "06",
        ALAND: 1480381942,
        AWATER: 5358723
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.164704, 42.209919999061071],
            [-93.93158, 42.20990799906108],
            [-93.69839, 42.209338999061089],
            [-93.698032, 41.863369999062236],
            [-93.815721, 41.863418999062247],
            [-94.164138, 41.863243999062227],
            [-94.164704, 42.209919999061071]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "089",
        COUNTYNS: "00695768",
        AFFGEOID: "0500000US28089",
        GEOID: "28089",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 1850209209,
        AWATER: 71667634
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.450037, 32.573782999143511],
            [-90.404875, 32.574715999143493],
            [-90.363788, 32.603330999143104],
            [-90.277813, 32.660427999142314],
            [-90.099313, 32.700482999141776],
            [-90.049129, 32.735673999141284],
            [-89.965882, 32.879572999139342],
            [-89.730016, 32.885071999139271],
            [-89.730304, 32.634227999142681],
            [-89.770913, 32.606149999143064],
            [-89.784123, 32.586890999143343],
            [-89.839985, 32.593294999143239],
            [-89.863104, 32.575771999143491],
            [-90.075349, 32.419085999145658],
            [-90.065697, 32.399528999145929],
            [-90.123488, 32.399450999145927],
            [-90.243764, 32.400097999145927],
            [-90.243641, 32.487532999144705],
            [-90.449864, 32.488393999144698],
            [-90.450037, 32.573782999143511]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "043",
        COUNTYNS: "01448035",
        AFFGEOID: "0500000US49043",
        GEOID: "49043",
        NAME: "Summit",
        LSAD: "06",
        ALAND: 4844778499,
        AWATER: 26615947
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.641052, 40.798924999066664],
            [-111.527985, 40.785361999066723],
            [-111.479555, 40.85828799906637],
            [-111.524895, 40.953841999065929],
            [-111.592981, 40.993014999065764],
            [-111.50751, 41.063233999065439],
            [-111.380452, 41.087247999065333],
            [-111.366604, 41.130017999065146],
            [-111.264974, 41.144043999065083],
            [-111.232824, 41.142880999065085],
            [-111.046636983126007, 41.251626955316027],
            [-111.046723, 40.997958999065744],
            [-110.539819, 40.996345999065746],
            [-110.121639, 40.997100999065736],
            [-110.048482995685006, 40.997297291916333],
            [-110.000716736441007, 40.99742545877394],
            [-110.000711, 40.813677999066584],
            [-110.137819, 40.810912999066602],
            [-110.292692, 40.833342999066502],
            [-110.378962, 40.787179999066709],
            [-110.561377, 40.758364999066856],
            [-110.625737, 40.76947099906679],
            [-110.656482, 40.740274999066941],
            [-110.750733, 40.747705999066909],
            [-110.822478, 40.710460999067081],
            [-110.89198, 40.727122999066999],
            [-110.901974, 40.678161999067243],
            [-110.976086, 40.592953999067667],
            [-111.12087, 40.590456999067683],
            [-111.15182, 40.548450999067889],
            [-111.368623, 40.628943999067481],
            [-111.39424, 40.690605999067181],
            [-111.489794, 40.599331999067637],
            [-111.553266, 40.609312999067576],
            [-111.604411, 40.666522999067297],
            [-111.606221, 40.712855999067074],
            [-111.649129, 40.773442999066781],
            [-111.641052, 40.798924999066664]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "045",
        COUNTYNS: "01245489",
        AFFGEOID: "0500000US45045",
        GEOID: "45045",
        NAME: "Greenville",
        LSAD: "06",
        ALAND: 2033687076,
        AWATER: 25132346
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.762059552113584, 35.081874435116319],
            [-82.746431, 35.079130999112252],
            [-82.68604, 35.124544999111755],
            [-82.5777151484413, 35.146475346959001],
            [-82.53256, 35.155616999111409],
            [-82.455609, 35.177424999111167],
            [-82.411301, 35.202482999110892],
            [-82.353175272919486, 35.198714128711636],
            [-82.295354, 35.194964999110972],
            [-82.2162487192291, 35.193258547764188],
            [-82.226538, 34.850530999114817],
            [-82.165093, 34.796586999115434],
            [-82.146792, 34.785553999115564],
            [-82.238054, 34.586955999117869],
            [-82.236125, 34.535829999118469],
            [-82.313996, 34.484001999119073],
            [-82.417293, 34.55344999911825],
            [-82.453789, 34.625113999117417],
            [-82.45837, 34.779199999115647],
            [-82.486746, 34.819474999115187],
            [-82.537866, 35.015695999112971],
            [-82.594141, 35.008204999113055],
            [-82.571378, 35.074558999112298],
            [-82.749351, 35.047828999112596],
            [-82.762059552113584, 35.081874435116319]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "173",
        COUNTYNS: "00465275",
        AFFGEOID: "0500000US19173",
        GEOID: "19173",
        NAME: "Taylor",
        LSAD: "06",
        ALAND: 1377618873,
        AWATER: 7133364
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.928459, 40.900652999066182],
            [-94.470779, 40.899501999066196],
            [-94.471207727123186, 40.570959457424777],
            [-94.533878, 40.570738999067764],
            [-94.632025917694804, 40.571759586467778],
            [-94.819978, 40.573713999067756],
            [-94.914897867361603, 40.574921168179038],
            [-94.928529, 40.727398999066999],
            [-94.928459, 40.900652999066182]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "085",
        COUNTYNS: "01265789",
        AFFGEOID: "0500000US46085",
        GEOID: "46085",
        NAME: "Lyman",
        LSAD: "06",
        ALAND: 4253139895,
        AWATER: 167659051
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.367247, 44.194620999057086],
            [-99.938217, 44.195194999057094],
            [-99.884485, 44.129581999057145],
            [-99.768681, 44.13574299905715],
            [-99.612249, 44.103693999057178],
            [-99.610311, 44.148886999057133],
            [-99.695752, 44.172374999057098],
            [-99.663508, 44.217516999057061],
            [-99.576571, 44.192420999057084],
            [-99.562536, 44.101518999057177],
            [-99.345995, 43.9814459990573],
            [-99.355864, 43.93437099905735],
            [-99.310826, 43.855215999057457],
            [-99.41015, 43.774618999057566],
            [-99.406961, 43.705276999057652],
            [-99.469097, 43.672802999057694],
            [-99.375378, 43.656868999057728],
            [-99.297882, 43.499855999057978],
            [-99.297998, 43.499668999057974],
            [-99.534481, 43.499800999057975],
            [-99.623578, 43.49985899905797],
            [-99.623587, 43.742413999057597],
            [-99.656634, 43.766068999057559],
            [-99.745836, 43.710294999057645],
            [-99.837825, 43.689070999057684],
            [-99.981465, 43.687118999057688],
            [-100.00693, 43.704732999057654],
            [-100.115169, 43.685433999057693],
            [-100.230845, 43.713855999057643],
            [-100.280028, 43.696794999057666],
            [-100.33897, 43.716941999057632],
            [-100.366174, 43.846977999057472],
            [-100.367207, 44.168693999057112],
            [-100.367247, 44.194620999057086]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "039",
        COUNTYNS: "00484989",
        AFFGEOID: "0500000US20039",
        GEOID: "20039",
        NAME: "Decatur",
        LSAD: "06",
        ALAND: 2314199314,
        AWATER: 1631670
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.738824713191008, 40.002262955638507],
            [-100.477018, 40.001751999070812],
            [-100.193599056836007, 40.001573004790714],
            [-100.19359, 40.001572999070824],
            [-100.177797545879002, 40.001565812922415],
            [-100.180351, 39.566889999073389],
            [-100.720213, 39.568033999073371],
            [-100.740873, 39.56804299907337],
            [-100.738824713191008, 40.002262955638507]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "115",
        COUNTYNS: "01581117",
        AFFGEOID: "0500000US55115",
        GEOID: "55115",
        NAME: "Shawano",
        LSAD: "06",
        ALAND: 2313442856,
        AWATER: 41883375
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.223814, 45.029245999056762],
            [-88.98168, 45.028916999056769],
            [-88.981319, 44.942744999056757],
            [-88.735548, 44.943177999056758],
            [-88.736194, 44.856375999056759],
            [-88.489149, 44.855444999056765],
            [-88.248213, 44.852738999056768],
            [-88.242687, 44.679630999056805],
            [-88.25226, 44.667426999056815],
            [-88.245204, 44.584745999056835],
            [-88.606083, 44.59051499905685],
            [-88.605159, 44.678330999056804],
            [-89.223745, 44.681364999056811],
            [-89.223814, 45.029245999056762]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "083",
        COUNTYNS: "00277306",
        AFFGEOID: "0500000US06083",
        GEOID: "06083",
        NAME: "Santa Barbara",
        LSAD: "06",
        ALAND: 7084000622,
        AWATER: 2729814515
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-119.916216, 34.058350999124187],
              [-119.857304, 34.071297999124027],
              [-119.739472, 34.049298999124289],
              [-119.5667, 34.053451999124235],
              [-119.470736656944013, 34.053996621228237],
              [-119.487719557382007, 33.996515153514238],
              [-119.554472, 33.997819999124914],
              [-119.662825, 33.985888999125081],
              [-119.721206, 33.959582999125395],
              [-119.795938, 33.962928999125346],
              [-119.873358, 33.980374999125132],
              [-119.876916, 34.023526999124613],
              [-119.916216, 34.058350999124187]
            ]
          ],
          [
            [
              [-120.368278, 34.076464999123964],
              [-120.24248, 34.057171999124186],
              [-120.135853, 34.02608699912458],
              [-120.055107, 34.03772899912444],
              [-119.984316, 33.983947999125093],
              [-119.973691, 33.942480999125614],
              [-120.049682, 33.914562999125963],
              [-120.121817, 33.895711999126178],
              [-120.179049, 33.927993999125789],
              [-120.200085, 33.95690399912543],
              [-120.36484, 33.991780999124991],
              [-120.454134, 34.028080999124562],
              [-120.368278, 34.076464999123964]
            ]
          ],
          [
            [
              [-120.650305211840006, 34.975166301382124],
              [-120.496222, 34.993154999113223],
              [-120.435473, 34.986758999113285],
              [-120.301827, 34.905570999114204],
              [-120.334548, 35.006314999113066],
              [-120.188383, 35.030377999112794],
              [-120.167616, 35.075296999112304],
              [-120.082084, 35.114677999111855],
              [-119.980122, 35.057572999112494],
              [-119.928419, 35.059793999112472],
              [-119.745566, 34.973675999113432],
              [-119.672963, 34.973374999113432],
              [-119.535757, 34.897575999114302],
              [-119.472754, 34.901173999114256],
              [-119.442352, 34.901273999114252],
              [-119.442353, 34.561100999118175],
              [-119.442269, 34.463948999119303],
              [-119.445991, 34.404066999120019],
              [-119.477946706723998, 34.378837557895906],
              [-119.536957, 34.395494999120118],
              [-119.616862, 34.42099499911982],
              [-119.684666, 34.408296999119969],
              [-119.709067, 34.395396999120116],
              [-119.785871, 34.415996999119876],
              [-119.835771, 34.415795999119879],
              [-119.873971, 34.408794999119962],
              [-119.971951, 34.444640999119535],
              [-120.050682, 34.461650999119328],
              [-120.141165, 34.473404999119197],
              [-120.295051, 34.470622999119229],
              [-120.451425, 34.44709399911951],
              [-120.511421, 34.522952999118615],
              [-120.550092, 34.542793999118388],
              [-120.581293, 34.556958999118223],
              [-120.622575, 34.554016999118254],
              [-120.645739, 34.581034999117939],
              [-120.60197, 34.692094999116641],
              [-120.614852, 34.730708999116196],
              [-120.62632, 34.738071999116123],
              [-120.610266, 34.858179999114739],
              [-120.670835, 34.904114999114213],
              [-120.650305211840006, 34.975166301382124]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "011",
        COUNTYNS: "00695730",
        AFFGEOID: "0500000US28011",
        GEOID: "28011",
        NAME: "Bolivar",
        LSAD: "06",
        ALAND: 2270541554,
        AWATER: 75515374
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.205644985947899, 33.546978370063471],
            [-91.188942, 33.576224999130204],
            [-91.130902, 33.610918999129758],
            [-91.178311, 33.651108999129256],
            [-91.10098, 33.660550999129129],
            [-91.075389, 33.714402999128453],
            [-91.143287, 33.747140999128035],
            [-91.111494, 33.774567999127683],
            [-91.08551, 33.776409999127665],
            [-91.026782, 33.763641999127834],
            [-91.025173, 33.805952999127307],
            [-91.052819, 33.824180999127073],
            [-91.061247, 33.877504999126415],
            [-91.026382, 33.907979999126042],
            [-91.035961, 33.943757999125587],
            [-91.088696, 33.961333999125372],
            [-91.048367, 33.985077999125082],
            [-91.004981, 33.977010999125184],
            [-90.979945, 34.000105999124898],
            [-90.942662, 34.018049999124671],
            [-90.89242, 34.026859999124568],
            [-90.874541, 34.072040999124013],
            [-90.90113, 34.094666999123746],
            [-90.946323, 34.109373999123548],
            [-90.944795524309498, 34.116657204437672],
            [-90.944080760193799, 34.120065293900822],
            [-90.659457, 34.118866999123441],
            [-90.657002, 33.987585999125038],
            [-90.6586, 33.616271999129687],
            [-90.763469, 33.616163999129697],
            [-90.763509, 33.529388999130802],
            [-91.215671, 33.529422999130809],
            [-91.205644985947899, 33.546978370063471]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "025",
        COUNTYNS: "00581298",
        AFFGEOID: "0500000US23025",
        GEOID: "23025",
        NAME: "Somerset",
        LSAD: "06",
        ALAND: 10164120486,
        AWATER: 437867472
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.552793, 45.667835999057054],
            [-70.446903, 45.704043999057077],
            [-70.383552, 45.734868999057113],
            [-70.415684, 45.786157999057167],
            [-70.39662, 45.808485999057176],
            [-70.329748, 45.853794999057229],
            [-70.259117, 45.890754999057265],
            [-70.252526, 45.933175999057319],
            [-70.26541, 45.962691999057355],
            [-70.31297, 45.961855999057349],
            [-70.303034, 45.9989759990574],
            [-70.317629, 46.019079999057418],
            [-70.306734, 46.061343999057463],
            [-70.266349, 46.100992999057517],
            [-70.239566, 46.142761999057583],
            [-70.290896, 46.185837999057632],
            [-70.255492, 46.246443999057725],
            [-70.232682, 46.284427999057783],
            [-70.205719, 46.29986499905781],
            [-70.207415, 46.331315999057857],
            [-70.161337, 46.360983999057893],
            [-70.118597, 46.384232999057936],
            [-70.080292, 46.410530999057983],
            [-70.053748, 46.429235999058015],
            [-70.023019787056185, 46.573486471575571],
            [-69.721109, 46.574052999058274],
            [-69.73232, 46.394007999057962],
            [-69.728577, 45.976881999057369],
            [-69.683564, 45.983631999057373],
            [-69.646566, 45.863987999057244],
            [-69.705743, 45.853974999057229],
            [-69.732579, 45.755977999057137],
            [-69.784853, 45.785211999057168],
            [-69.832818, 45.738733999057118],
            [-69.701688, 45.62798199905702],
            [-69.779928, 45.542765999056968],
            [-69.620967, 45.010955999056776],
            [-69.515649, 45.025042999056758],
            [-69.499861, 45.053777999056784],
            [-69.355667, 45.073468999056765],
            [-69.305521, 44.895987999056764],
            [-69.281275, 44.808656999056772],
            [-69.282052, 44.789112999056776],
            [-69.266785, 44.721927999056788],
            [-69.327407, 44.714441999056788],
            [-69.372065, 44.754631999056784],
            [-69.471712, 44.692898999056801],
            [-69.470382, 44.718160999056785],
            [-69.632971, 44.698762999056804],
            [-69.578898, 44.625107999056823],
            [-69.605746, 44.577263999056846],
            [-69.665525, 44.586401999056847],
            [-69.744282, 44.602606999056839],
            [-69.792813, 44.577329999056843],
            [-69.822683, 44.607758999056834],
            [-69.930451, 44.610851999056827],
            [-69.960149, 44.681225999056799],
            [-69.996191, 44.677496999056807],
            [-70.03327, 44.864539999056774],
            [-70.12993, 44.850764999056764],
            [-70.149085, 44.896101999056761],
            [-70.110365, 44.947310999056761],
            [-70.159375, 45.128395999056771],
            [-70.293826, 45.109902999056764],
            [-70.308491, 45.163182999056779],
            [-70.418519, 45.14400499905679],
            [-70.518696, 45.512126999056939],
            [-70.55282390337851, 45.667806056942162],
            [-70.552793, 45.667835999057054]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "033",
        COUNTYNS: "00882233",
        AFFGEOID: "0500000US34033",
        GEOID: "34033",
        NAME: "Salem",
        LSAD: "06",
        ALAND: 859474410,
        AWATER: 105430413
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.559446, 39.629811999073006],
            [-75.535144, 39.647211999072887],
            [-75.509742, 39.686112999072655],
            [-75.47764, 39.715012999072485],
            [-75.459439, 39.765812999072182],
            [-75.415062, 39.801918999071958],
            [-75.410492477637092, 39.804672233394044],
            [-75.429061, 39.78381999907208],
            [-75.378228, 39.745869999072305],
            [-75.376589, 39.726420999072417],
            [-75.199793, 39.666995999072775],
            [-75.061854, 39.568745999073364],
            [-75.080309, 39.460647999074041],
            [-75.238434, 39.559038999073429],
            [-75.368213, 39.456478999074072],
            [-75.417107077488097, 39.388910685788197],
            [-75.442393, 39.40229099907441],
            [-75.465212, 39.438929999074176],
            [-75.536431, 39.460558999074031],
            [-75.528088, 39.498113999073816],
            [-75.527676, 39.535277999073578],
            [-75.512732, 39.57799999907332],
            [-75.543965, 39.595999999073214],
            [-75.559446, 39.629811999073006]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "019",
        COUNTYNS: "01135849",
        AFFGEOID: "0500000US41019",
        GEOID: "41019",
        NAME: "Douglas",
        LSAD: "06",
        ALAND: 13042299249,
        AWATER: 252000831
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.193455, 43.706084999057659],
            [-124.160207315876008, 43.863720500579049],
            [-123.946291, 43.862681999057443],
            [-123.827004, 43.944836999057351],
            [-123.7038, 43.944318999057352],
            [-123.619012, 43.921116999057382],
            [-123.579782, 43.868289999057431],
            [-123.470371, 43.810055999057504],
            [-123.3482, 43.80921199905751],
            [-123.348246, 43.780169999057556],
            [-123.137319, 43.779669999057553],
            [-123.137211, 43.605905999057796],
            [-123.107686, 43.540009999057901],
            [-122.741845, 43.544653999057921],
            [-122.741716, 43.437394999058071],
            [-122.132034, 43.440220999058084],
            [-122.010403, 43.34469699905825],
            [-121.974199, 43.261692999058404],
            [-122.03965, 43.214101999058492],
            [-122.093776, 43.076256999058785],
            [-122.282585, 43.067718999058791],
            [-122.28273, 42.996498999058957],
            [-122.401025, 42.996631999058955],
            [-122.460224, 42.939872999059091],
            [-122.560299, 42.924689999059126],
            [-122.677181, 42.881079999059224],
            [-122.794776, 42.778220999059464],
            [-122.951199, 42.774772999059479],
            [-123.009765, 42.752143999059548],
            [-123.152233, 42.755834999059537],
            [-123.229619, 42.702609999059675],
            [-123.274283, 42.732029999059591],
            [-123.445456, 42.703024999059664],
            [-123.581699, 42.73991999905958],
            [-123.716505, 42.739657999059581],
            [-123.716492, 42.784060999059463],
            [-123.783304, 42.799425999059423],
            [-123.811553, 42.788836999059455],
            [-123.821118, 42.996290999058957],
            [-123.761594, 42.996317999058959],
            [-123.761333, 43.083159999058772],
            [-123.702144, 43.087082999058765],
            [-123.704752, 43.257740999058413],
            [-123.764356, 43.258053999058411],
            [-123.761528, 43.422293999058105],
            [-123.817614, 43.431305999058097],
            [-123.816657, 43.51644899905795],
            [-123.875628, 43.515946999057945],
            [-123.875698, 43.608406999057799],
            [-124.21905767690599, 43.610931758246792],
            [-124.193455, 43.706084999057659]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "113",
        COUNTYNS: "00559538",
        AFFGEOID: "0500000US22113",
        GEOID: "22113",
        NAME: "Vermilion",
        LSAD: "15",
        ALAND: 3038598763,
        AWATER: 955343208
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.637841, 30.07217399918127],
            [-92.625484, 30.092329999180929],
            [-92.534638, 30.107851999180678],
            [-92.423166, 30.049319999181638],
            [-92.284675, 30.147442999180033],
            [-92.283673, 30.1441909991801],
            [-92.258912, 30.139297999180165],
            [-92.240158, 30.141040999180138],
            [-92.22705, 30.136895999180211],
            [-92.203124, 30.142622999180112],
            [-92.128423, 30.115988999180555],
            [-92.088421, 30.128298999180352],
            [-92.066153, 30.073925999181238],
            [-91.964458, 30.036917999181849],
            [-91.990396, 29.970325999182936],
            [-91.969648, 29.816887999185472],
            [-91.985194, 29.629189999188611],
            [-92.042894303827396, 29.577480288161485],
            [-92.064513, 29.585664999189348],
            [-92.158624, 29.581615999189417],
            [-92.25186, 29.539353999190133],
            [-92.323465, 29.531496999190253],
            [-92.40986, 29.547476999189993],
            [-92.473585, 29.561080999189759],
            [-92.568038, 29.577396999189485],
            [-92.617234437449881, 29.589059015362796],
            [-92.61561, 30.038167999181812],
            [-92.738604, 30.037322999181836],
            [-92.637841, 30.07217399918127]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "119",
        COUNTYNS: "00069177",
        AFFGEOID: "0500000US05119",
        GEOID: "05119",
        NAME: "Pulaski",
        LSAD: "06",
        ALAND: 1965575733,
        AWATER: 126242806
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.753626, 34.911247999114131],
            [-92.544341, 34.953318999113669],
            [-92.483512, 34.950936999113686],
            [-92.436108, 34.842217999114915],
            [-92.419276, 34.931674999113902],
            [-92.229893, 34.927557999113951],
            [-92.227541, 35.014483999112976],
            [-92.121336, 35.012209999112997],
            [-92.070805, 35.011801999113004],
            [-92.078711, 34.729982999116217],
            [-92.113313, 34.622965999117447],
            [-92.029539, 34.621247999117458],
            [-92.029996, 34.489351999119016],
            [-92.208041, 34.492764999118975],
            [-92.245818, 34.493505999118959],
            [-92.244209, 34.580889999117943],
            [-92.348749, 34.582289999117918],
            [-92.346627, 34.625602999117419],
            [-92.445148, 34.626939999117404],
            [-92.443265, 34.672040999116881],
            [-92.551963, 34.689431999116671],
            [-92.549856, 34.76169899911585],
            [-92.654324, 34.764540999115823],
            [-92.651248, 34.851681999114824],
            [-92.739779, 34.8534589991148],
            [-92.755087, 34.871794999114584],
            [-92.753626, 34.911247999114131]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "031",
        COUNTYNS: "00882232",
        AFFGEOID: "0500000US34031",
        GEOID: "34031",
        NAME: "Passaic",
        LSAD: "06",
        ALAND: 481744927,
        AWATER: 32079811
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.503212, 41.085872999065344],
            [-74.367038261447391, 41.204211471508629],
            [-74.301994, 41.172593999064965],
            [-74.234473, 41.142882999065087],
            [-74.234362831230385, 41.142835270599683],
            [-74.211617643198593, 41.132981362601434],
            [-74.250458, 41.060815999065454],
            [-74.272226, 41.017934999065645],
            [-74.171141, 40.977065999065836],
            [-74.141048, 40.966347999065874],
            [-74.132337, 40.922429999066082],
            [-74.106786, 40.859298999066382],
            [-74.120199, 40.858714999066365],
            [-74.119238, 40.852042999066406],
            [-74.124035, 40.83871699906647],
            [-74.12099, 40.830538999066519],
            [-74.123773, 40.822756999066549],
            [-74.130016, 40.819937999066561],
            [-74.197872, 40.85705299906639],
            [-74.272063, 40.897426999066205],
            [-74.298851, 41.002912999065735],
            [-74.362826, 41.013126999065662],
            [-74.43504, 41.020020999065629],
            [-74.447017, 41.054428999065486],
            [-74.497777, 41.034260999065573],
            [-74.503212, 41.085872999065344]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "077",
        COUNTYNS: "00394951",
        AFFGEOID: "0500000US16077",
        GEOID: "16077",
        NAME: "Power",
        LSAD: "06",
        ALAND: 3635879902,
        AWATER: 99218111
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.243478, 43.110945999058714],
            [-113.007712, 43.110932999058704],
            [-113.00776, 42.863050999059276],
            [-112.816578, 42.863230999059269],
            [-112.750562, 42.951527999059053],
            [-112.747491, 42.912734999059154],
            [-112.558693, 42.961246999059036],
            [-112.515519, 42.909974999059159],
            [-112.49691, 42.733012999059582],
            [-112.37967, 42.646272999059818],
            [-112.419825, 42.503037999060197],
            [-112.498139, 42.502966999060213],
            [-112.497849, 42.415997999060465],
            [-112.653989, 42.41616899906046],
            [-112.654028, 42.328871999060709],
            [-112.999965, 42.327426999060727],
            [-113.002792, 42.588002999059967],
            [-113.175678, 42.589463999059966],
            [-113.236968, 42.625253999059872],
            [-113.178633, 42.67545999905974],
            [-113.178877, 42.762354999059518],
            [-113.236943, 42.762211999059517],
            [-113.243478, 43.110945999058714]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "119",
        COUNTYNS: "01213689",
        AFFGEOID: "0500000US42119",
        GEOID: "42119",
        NAME: "Union",
        LSAD: "06",
        ALAND: 818323459,
        AWATER: 4639329
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.36418, 40.846936999066422],
            [-77.279236, 40.909709999066138],
            [-77.14416, 41.044337999065519],
            [-77.144111, 41.068839999065418],
            [-76.977939, 41.087882999065336],
            [-76.960229, 41.14880099906506],
            [-76.896114, 41.1390699990651],
            [-76.858335, 41.073077999065404],
            [-76.874719, 40.948143999065969],
            [-76.800242, 40.881993999066268],
            [-76.941788, 40.888059999066222],
            [-77.356628, 40.807333999066614],
            [-77.36418, 40.846936999066422]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "349",
        COUNTYNS: "01383960",
        AFFGEOID: "0500000US48349",
        GEOID: "48349",
        NAME: "Navarro",
        LSAD: "06",
        ALAND: 2614935427,
        AWATER: 197626095
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.896209, 32.073976999150545],
            [-96.383082, 32.328850999146923],
            [-96.196965, 32.20694199914864],
            [-96.146523, 32.211714999148576],
            [-96.105364, 32.075336999150522],
            [-96.05478, 32.012525999151414],
            [-96.496713, 31.796190999154557],
            [-96.719114, 31.814886999154286],
            [-96.896209, 32.073976999150545]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "085",
        COUNTYNS: "00069164",
        AFFGEOID: "0500000US05085",
        GEOID: "05085",
        NAME: "Lonoke",
        LSAD: "06",
        ALAND: 1996628025,
        AWATER: 82740471
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.119719, 35.066492999112398],
            [-92.012735, 35.07625099911229],
            [-91.87888, 35.02457699911286],
            [-91.802505, 35.030424999112803],
            [-91.803703, 34.921523999114029],
            [-91.693705, 34.919604999114043],
            [-91.700828, 34.672028999116868],
            [-91.638531, 34.626092999117411],
            [-91.632811, 34.55309999911826],
            [-91.678037, 34.481919999119093],
            [-91.705185, 34.482669999119089],
            [-91.974805, 34.488682999119021],
            [-92.029996, 34.489351999119016],
            [-92.029539, 34.621247999117458],
            [-92.113313, 34.622965999117447],
            [-92.078711, 34.729982999116217],
            [-92.070805, 35.011801999113004],
            [-92.121336, 35.012209999112997],
            [-92.119719, 35.066492999112398]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "091",
        COUNTYNS: "00069166",
        AFFGEOID: "0500000US05091",
        GEOID: "05091",
        NAME: "Miller",
        LSAD: "06",
        ALAND: 1620232433,
        AWATER: 31271179
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.043833747801088, 33.55171373471611],
            [-93.986265, 33.533672999130751],
            [-93.967409, 33.581051999130146],
            [-93.825884, 33.609852999129771],
            [-93.772686, 33.498512999131194],
            [-93.722867, 33.481962999131405],
            [-93.733317, 33.42087999913219],
            [-93.665518, 33.327020999133417],
            [-93.699199, 33.312835999133604],
            [-93.702531, 33.245833999134469],
            [-93.750177, 33.183948999135282],
            [-93.850076, 33.145342999135799],
            [-93.860409, 33.092601999136491],
            [-93.804907774630379, 33.019396224588363],
            [-93.814553013433795, 33.019389044008257],
            [-94.042964, 33.019218999137472],
            [-94.042719, 33.160290999135597],
            [-94.042945847176, 33.271242083273947],
            [-94.043067, 33.330497999133364],
            [-94.042988, 33.435823999131998],
            [-94.043428, 33.551424999130525],
            [-94.043833747801088, 33.55171373471611]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "011",
        COUNTYNS: "01605071",
        AFFGEOID: "0500000US56011",
        GEOID: "56011",
        NAME: "Crook",
        LSAD: "06",
        ALAND: 7393068933,
        AWATER: 28186053
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.085451, 44.786800999056773],
            [-105.076600952253997, 45.000296612464162],
            [-105.038252188273987, 45.000291672048562],
            [-105.025266, 45.000289999056761],
            [-104.057698, 44.99743099905676],
            [-104.05581, 44.691342999056793],
            [-104.055695199260995, 44.570990376199049],
            [-104.055389, 44.249982999057039],
            [-104.055406377827012, 44.180382873152794],
            [-105.079281, 44.176181999057107],
            [-105.085451, 44.786800999056773]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "061",
        COUNTYNS: "01245652",
        AFFGEOID: "0500000US45061",
        GEOID: "45061",
        NAME: "Lee",
        LSAD: "06",
        ALAND: 1062372377,
        AWATER: 2715077
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.479857, 34.168695999122825],
            [-80.437359, 34.284501999121431],
            [-80.353031, 34.30332999912121],
            [-80.288692, 34.364138999120506],
            [-80.243226, 34.324454999120974],
            [-80.177225, 34.366202999120468],
            [-80.112727, 34.197017999122487],
            [-80.158582, 34.161622999122926],
            [-80.072808, 34.086012999123852],
            [-80.02593, 34.073225999123999],
            [-80.000594, 34.04787699912432],
            [-80.180371, 33.952001999125486],
            [-80.228344, 33.995177999124955],
            [-80.321721, 34.080355999123917],
            [-80.481318, 34.112621999123512],
            [-80.479857, 34.168695999122825]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "043",
        COUNTYNS: "01383807",
        AFFGEOID: "0500000US48043",
        GEOID: "48043",
        NAME: "Brewster",
        LSAD: "06",
        ALAND: 16015869999,
        AWATER: 22063764
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.80068, 30.412526999175746],
            [-103.439976, 30.665938999171729],
            [-102.567049, 30.052807999181574],
            [-102.340215, 29.892645999184214],
            [-102.31868395458801, 29.872192945686056],
            [-102.349861, 29.86231699918471],
            [-102.369522, 29.820394999185414],
            [-102.392906, 29.765568999186328],
            [-102.468946, 29.779816999186096],
            [-102.512687, 29.780302999186084],
            [-102.551081, 29.752357999186547],
            [-102.612879, 29.748181999186624],
            [-102.677192, 29.738260999186785],
            [-102.693466, 29.676506999187815],
            [-102.738428, 29.621928999188732],
            [-102.777531, 29.556496999189839],
            [-102.808692, 29.522318999190414],
            [-102.83097, 29.44426699919174],
            [-102.824564, 29.399557999192499],
            [-102.871857, 29.35209299919331],
            [-102.891022, 29.287112999194417],
            [-102.871347, 29.241624999195199],
            [-102.917805, 29.190696999196074],
            [-102.995688, 29.161218999196581],
            [-103.035683, 29.103028999197591],
            [-103.076355, 29.085721999197883],
            [-103.100368, 29.026876999198919],
            [-103.126748, 28.982123999199693],
            [-103.227801, 28.991531999199523],
            [-103.28119, 28.982137999199686],
            [-103.361998, 29.018913999199061],
            [-103.463196, 29.066821999198215],
            [-103.524613, 29.120997999197275],
            [-103.59236, 29.150259999196773],
            [-103.660203, 29.170933999196428],
            [-103.724743, 29.191469999196059],
            [-103.789034, 29.257501999194925],
            [-103.793873010933993, 29.259238392543498],
            [-103.792793, 29.500187999190786],
            [-103.80068, 30.412526999175746]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "067",
        COUNTYNS: "01248010",
        AFFGEOID: "0500000US45067",
        GEOID: "45067",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 1267162021,
        AWATER: 12643194
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.54793, 34.23091299912209],
            [-79.480712, 34.288080999121398],
            [-79.380264, 34.300571999121246],
            [-79.21432, 34.255538999121796],
            [-79.128165, 34.253651999121814],
            [-79.126681, 34.235361999122027],
            [-79.207787, 34.153876999123014],
            [-79.247574, 34.05726899912419],
            [-79.302341, 34.032218999124495],
            [-79.339076, 33.969721999125277],
            [-79.256829, 33.878968999126393],
            [-79.260283, 33.814853999127187],
            [-79.190264, 33.70578699912857],
            [-79.246384, 33.695346999128688],
            [-79.317041, 33.779877999127628],
            [-79.324317, 33.799122999127384],
            [-79.51694, 33.993979999124967],
            [-79.515308, 34.097206999123699],
            [-79.571834, 34.183688999122658],
            [-79.54793, 34.23091299912209]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "187",
        COUNTYNS: "01639801",
        AFFGEOID: "0500000US47187",
        GEOID: "47187",
        NAME: "Williamson",
        LSAD: "06",
        ALAND: 1508923182,
        AWATER: 3012752
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.201426, 35.940578999103067],
            [-87.204242, 35.959185999102864],
            [-87.190826, 35.975883999102713],
            [-87.182573, 36.049725999101966],
            [-87.085365, 36.043804999102015],
            [-87.054114, 36.04553499910201],
            [-87.040407, 35.989225999102572],
            [-86.9292, 36.052721999101927],
            [-86.790875, 36.036714999102102],
            [-86.618892, 35.968223999102783],
            [-86.613328, 35.790330999104626],
            [-86.686193, 35.710050999105448],
            [-86.751651, 35.696446999105596],
            [-86.782016, 35.706594999105491],
            [-86.935719, 35.760658999104919],
            [-87.003959, 35.779027999104727],
            [-87.035681, 35.796212999104554],
            [-87.215099, 35.850650999103991],
            [-87.201426, 35.940578999103067]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "095",
        COUNTYNS: "01581108",
        AFFGEOID: "0500000US55095",
        GEOID: "55095",
        NAME: "Polk",
        LSAD: "06",
        ALAND: 2367400716,
        AWATER: 109704318
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.886697124408997, 45.644147999057047],
            [-92.529111, 45.642170999057029],
            [-92.528198, 45.728679999057107],
            [-92.154443, 45.725615999057105],
            [-92.154888, 45.63974199905703],
            [-92.156465, 45.209553999056801],
            [-92.766091408673688, 45.210021701479498],
            [-92.761868, 45.284937999056815],
            [-92.748267674685891, 45.296059385566622],
            [-92.698967, 45.33637399905686],
            [-92.658486, 45.396057999056872],
            [-92.646768, 45.437928999056908],
            [-92.686793, 45.472270999056917],
            [-92.728023, 45.525651999056954],
            [-92.756906, 45.557498999056968],
            [-92.801503, 45.562853999056962],
            [-92.881136, 45.57340899905698],
            [-92.887929, 45.639005999057034],
            [-92.886697124408997, 45.644147999057047]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "075",
        COUNTYNS: "01248014",
        AFFGEOID: "0500000US45075",
        GEOID: "45075",
        NAME: "Orangeburg",
        LSAD: "06",
        ALAND: 2864691029,
        AWATER: 56516003
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.372931, 33.490416999131305],
            [-81.187271, 33.652936999129231],
            [-81.086633, 33.695408999128695],
            [-81.042854, 33.707130999128552],
            [-80.98421, 33.644796999129333],
            [-80.93979, 33.608797999129791],
            [-80.807631, 33.604080999129849],
            [-80.616575, 33.46600599913161],
            [-80.552272, 33.56541399913035],
            [-80.496273, 33.558480999130438],
            [-80.393145, 33.445338999131877],
            [-80.222267, 33.443715999131918],
            [-80.253836, 33.299259999133767],
            [-80.361851, 33.257442999134319],
            [-80.484578, 33.280033999134034],
            [-80.50279, 33.334495999133324],
            [-80.790296, 33.180839999135323],
            [-80.797912, 33.176943999135382],
            [-80.846151, 33.238327999134576],
            [-80.887109, 33.264182999134235],
            [-80.940103, 33.303559999133711],
            [-81.222673, 33.439999999131942],
            [-81.301445, 33.481217999131417],
            [-81.372931, 33.490416999131305]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "039",
        COUNTYNS: "01605083",
        AFFGEOID: "0500000US56039",
        GEOID: "56039",
        NAME: "Teton",
        LSAD: "06",
        ALAND: 10351785153,
        AWATER: 570864021
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.055332727123997, 44.666262308974311],
            [-110.667867, 44.666290999056812],
            [-110.667899, 44.582791999056852],
            [-110.375334, 44.583015999056848],
            [-110.29485, 44.546922999056861],
            [-110.276506, 44.515664999056874],
            [-110.296648, 44.431340999056928],
            [-110.228408, 44.373843999056959],
            [-110.19019, 44.30049699905701],
            [-110.1511, 44.292463999057006],
            [-110.111284, 44.199374999057085],
            [-110.119193, 44.132583999057147],
            [-110.053357, 44.132627999057142],
            [-110.053179, 44.008021999057277],
            [-110.05157, 43.464782999058023],
            [-110.05731, 43.377767999058186],
            [-110.344218, 43.377917999058184],
            [-110.343959, 43.291164999058346],
            [-110.57838, 43.291639999058347],
            [-110.578656, 43.235223999058455],
            [-110.813993, 43.235177999058457],
            [-110.813996, 43.313939999058299],
            [-111.044617, 43.315719999058302],
            [-111.045360560970011, 43.501051450170173],
            [-111.04611, 43.687847999057681],
            [-111.046515, 43.908375999057384],
            [-111.047219183001999, 43.983431291664999],
            [-111.048452, 44.114830999057162],
            [-111.049148, 44.374924999056958],
            [-111.048974, 44.474071999056889],
            [-111.055208, 44.624926999056818],
            [-111.055332727123997, 44.666262308974311]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "059",
        COUNTYNS: "01480119",
        AFFGEOID: "0500000US51059",
        GEOID: "51059",
        NAME: "Fairfax",
        LSAD: "06",
        ALAND: 1012584627,
        AWATER: 40233137
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.535991, 38.847367999078116],
            [-77.432198, 38.952672999077372],
            [-77.400955, 38.984291999077165],
            [-77.330037705954084, 39.055952112567681],
            [-77.310705, 39.052007999076707],
            [-77.248403, 39.026908999076873],
            [-77.248403704975402, 39.026888353369166],
            [-77.249803, 38.985908999077147],
            [-77.202502, 38.967909999077264],
            [-77.146601, 38.964209999077305],
            [-77.119759, 38.934342999077501],
            [-77.124875, 38.930407999077531],
            [-77.172276, 38.893244999077794],
            [-77.194874, 38.898875999077752],
            [-77.189854, 38.894394999077782],
            [-77.194263, 38.886093999077843],
            [-77.189754, 38.878135999077891],
            [-77.173243, 38.878603999077889],
            [-77.158398, 38.87317599907793],
            [-77.149701, 38.875669999077907],
            [-77.110799, 38.843445999078142],
            [-77.143206, 38.805623999078392],
            [-77.039239885082296, 38.785336324702151],
            [-77.040672570778099, 38.746691929561706],
            [-77.040998, 38.73791399907887],
            [-77.053199, 38.709914999079075],
            [-77.079499, 38.709514999079083],
            [-77.08578476515639, 38.705281280911507],
            [-77.132501, 38.673815999079324],
            [-77.1302, 38.635016999079603],
            [-77.224145855301487, 38.6351782739805],
            [-77.245756, 38.672272999079347],
            [-77.282567, 38.696566999079174],
            [-77.299868, 38.705161999079117],
            [-77.356276, 38.725499999078963],
            [-77.380526, 38.722536999078983],
            [-77.443084, 38.803652999078416],
            [-77.468234, 38.796298999078459],
            [-77.490113, 38.810032999078359],
            [-77.508812, 38.841069999078144],
            [-77.535991, 38.847367999078116]
          ],
          [
            [-77.325298, 38.846286999078117],
            [-77.303067, 38.832821999078206],
            [-77.27052, 38.840901999078149],
            [-77.270228, 38.86563899907798],
            [-77.328788, 38.86484799907798],
            [-77.325298, 38.846286999078117]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "071",
        COUNTYNS: "01248013",
        AFFGEOID: "0500000US45071",
        GEOID: "45071",
        NAME: "Newberry",
        LSAD: "06",
        ALAND: 1631805477,
        AWATER: 44657974
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.945019, 34.203147999122422],
            [-81.895331, 34.300496999121258],
            [-81.782627, 34.365088999120481],
            [-81.737103, 34.430543999119699],
            [-81.703198, 34.452630999119442],
            [-81.643552, 34.533651999118483],
            [-81.541455, 34.445165999119538],
            [-81.423241, 34.494378999118943],
            [-81.342297, 34.266927999121656],
            [-81.316911, 34.239457999121989],
            [-81.338961, 34.197586999122478],
            [-81.437172, 34.1299629991233],
            [-81.394124, 34.071487999124024],
            [-81.471972, 34.076551999123971],
            [-81.502477, 34.073879999123989],
            [-81.568511, 34.099686999123676],
            [-81.731614, 34.187612999122614],
            [-81.860571, 34.179372999122712],
            [-81.870084, 34.135356999123246],
            [-81.897154, 34.146306999123112],
            [-81.945019, 34.203147999122422]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "087",
        COUNTYNS: "01248017",
        AFFGEOID: "0500000US45087",
        GEOID: "45087",
        NAME: "Union",
        LSAD: "06",
        ALAND: 1331696509,
        AWATER: 4805060
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.78339, 34.837124999114977],
            [-81.711694, 34.912576999114137],
            [-81.481945, 34.86649499911465],
            [-81.457257, 34.839279999114957],
            [-81.478454, 34.821508999115167],
            [-81.456304, 34.792520999115489],
            [-81.482351, 34.744397999116039],
            [-81.415894, 34.633588999117329],
            [-81.422706, 34.572028999118039],
            [-81.427545, 34.529481999118538],
            [-81.423241, 34.494378999118943],
            [-81.541455, 34.445165999119538],
            [-81.643552, 34.533651999118483],
            [-81.677321, 34.557971999118202],
            [-81.854002, 34.594548999117784],
            [-81.78339, 34.837124999114977]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "215",
        COUNTYNS: "00357428",
        AFFGEOID: "0500000US13215",
        GEOID: "13215",
        NAME: "Muscogee",
        LSAD: "06",
        ALAND: 560544127,
        AWATER: 11859741
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.076072372604798, 32.608067474300135],
            [-84.912792, 32.607628999143039],
            [-84.907693, 32.607607999143049],
            [-84.907883, 32.583432999143383],
            [-84.694603, 32.583944999143377],
            [-84.667463, 32.563947999143643],
            [-84.69451, 32.518731999144272],
            [-84.768407, 32.420590999145638],
            [-84.981150294133698, 32.379040075828023],
            [-84.971831, 32.442842999145334],
            [-84.999786756310087, 32.507066967928139],
            [-85.001130839123689, 32.510154787542191],
            [-85.0071, 32.523867999144201],
            [-85.069848, 32.583145999143383],
            [-85.076072372604798, 32.608067474300135]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "063",
        COUNTYNS: "01074044",
        AFFGEOID: "0500000US39063",
        GEOID: "39063",
        NAME: "Hancock",
        LSAD: "06",
        ALAND: 1376210232,
        AWATER: 5959837
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.881149, 41.167823999064979],
            [-83.421054, 41.166779999064978],
            [-83.420319, 40.991888999065765],
            [-83.45785, 40.991670999065768],
            [-83.514728, 40.905114999066164],
            [-83.515883, 40.818133999066568],
            [-83.880063, 40.819918999066566],
            [-83.880423, 40.920428999066097],
            [-83.881149, 41.167823999064979]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "073",
        COUNTYNS: "01074049",
        AFFGEOID: "0500000US39073",
        GEOID: "39073",
        NAME: "Hocking",
        LSAD: "06",
        ALAND: 1091232274,
        AWATER: 5959003
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.740729, 39.468349999074],
            [-82.731518, 39.554444999073461],
            [-82.620088, 39.563986999073393],
            [-82.617546, 39.608604999073123],
            [-82.496123, 39.60284899907316],
            [-82.490329, 39.661703999072806],
            [-82.374531, 39.654958999072846],
            [-82.37989, 39.596737999073198],
            [-82.263566, 39.562136999073417],
            [-82.159161, 39.556573999073443],
            [-82.167379, 39.466306999074014],
            [-82.28041, 39.472905999073959],
            [-82.289663, 39.384194999074531],
            [-82.514479, 39.395766999074453],
            [-82.51758, 39.369467999074615],
            [-82.748591, 39.368164999074622],
            [-82.740729, 39.468349999074]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "086",
        COUNTYNS: "00295755",
        AFFGEOID: "0500000US12086",
        GEOID: "12086",
        NAME: "Miami-Dade",
        LSAD: "06",
        ALAND: 4917514967,
        AWATER: 1379206478
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.873125, 25.760884999260821],
            [-80.873096, 25.805376999259916],
            [-80.872932, 25.979433999256369],
            [-80.680038, 25.978748999256386],
            [-80.680016, 25.956856999256829],
            [-80.326712, 25.957117999256823],
            [-80.295187, 25.970569999256547],
            [-80.206228, 25.972695999256509],
            [-80.115128923890097, 25.972815489267106],
            [-80.117904, 25.915771999257665],
            [-80.114514244107795, 25.874199633950607],
            [-80.114385224276106, 25.872617319546436],
            [-80.109953, 25.818259999259645],
            [-80.123806, 25.762766999260787],
            [-80.154972, 25.665489999262778],
            [-80.176916, 25.685061999262381],
            [-80.229107, 25.732508999261405],
            [-80.265879, 25.65837299926293],
            [-80.301464, 25.613298999263854],
            [-80.313918, 25.539163999265384],
            [-80.3159759814327, 25.532620826531616],
            [-80.337049, 25.465620999266903],
            [-80.31036, 25.389706999268483],
            [-80.234849, 25.421959999267813],
            [-80.176024, 25.521153999265756],
            [-80.163156, 25.452183999267188],
            [-80.232390558930391, 25.337069458682276],
            [-80.26445, 25.354327999269227],
            [-80.372121, 25.310214999270144],
            [-80.395081, 25.272936999270932],
            [-80.394931, 25.253536999271319],
            [-80.41887, 25.23630999927169],
            [-80.450659, 25.240240999271602],
            [-80.482826, 25.22478599927193],
            [-80.492769, 25.205578999272333],
            [-80.495394, 25.199807999272451],
            [-80.542391, 25.206378999272321],
            [-80.650532, 25.189096999272678],
            [-80.710607, 25.15252999927344],
            [-80.747746, 25.147440999273556],
            [-80.812133, 25.186038999272743],
            [-80.858170040030586, 25.177520325799019],
            [-80.87319, 25.363992999269023],
            [-80.873125, 25.760884999260821]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "501",
        COUNTYNS: "01384036",
        AFFGEOID: "0500000US48501",
        GEOID: "48501",
        NAME: "Yoakum",
        LSAD: "06",
        ALAND: 2071252452,
        AWATER: 34329
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.060103, 33.219224999134823],
            [-103.056495064337994, 33.388414213753215],
            [-102.594836, 33.388488999132626],
            [-102.595023, 32.958830999138272],
            [-103.063468888475001, 32.95910638096877],
            [-103.060103, 33.219224999134823]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "251",
        COUNTYNS: "00350204",
        AFFGEOID: "0500000US13251",
        GEOID: "13251",
        NAME: "Screven",
        LSAD: "06",
        ALAND: 1670807253,
        AWATER: 27885165
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.865987, 32.662989999142283],
            [-81.767553, 32.909410999138942],
            [-81.543966224313891, 33.044399953281527],
            [-81.50203, 33.015112999137521],
            [-81.499566, 32.943721999138468],
            [-81.464069, 32.897813999139089],
            [-81.42062, 32.831222999140003],
            [-81.413116795173607, 32.744261327102677],
            [-81.41267, 32.739082999141246],
            [-81.393818, 32.653490999142413],
            [-81.397106, 32.605586999143071],
            [-81.386901902694191, 32.598964853042958],
            [-81.528169, 32.514887999144335],
            [-81.548006, 32.489285999144684],
            [-81.689643, 32.546289999143895],
            [-81.82785, 32.652796999142431],
            [-81.841005, 32.649092999142468],
            [-81.865987, 32.662989999142283]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "505",
        COUNTYNS: "01384038",
        AFFGEOID: "0500000US48505",
        GEOID: "48505",
        NAME: "Zapata",
        LSAD: "06",
        ALAND: 2585876220,
        AWATER: 154370982
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.460825799134284, 27.262240196273918],
            [-99.370621, 27.319173999230024],
            [-99.33361, 27.273222999230907],
            [-98.954669, 27.269396999230981],
            [-98.95423, 26.785693999240319],
            [-99.011112, 26.67502299924249],
            [-99.176816092381102, 26.569660554618164],
            [-99.200522, 26.656442999242849],
            [-99.208907, 26.724760999241514],
            [-99.242444, 26.788261999240266],
            [-99.268613, 26.843212999239192],
            [-99.3289, 26.879760999238485],
            [-99.361144, 26.92892099923753],
            [-99.387367, 26.982398999236491],
            [-99.44697, 27.026025999235646],
            [-99.442123, 27.106838999234093],
            [-99.429984, 27.159148999233089],
            [-99.445238, 27.223340999231862],
            [-99.460825799134284, 27.262240196273918]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "239",
        COUNTYNS: "01383905",
        AFFGEOID: "0500000US48239",
        GEOID: "48239",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 2148246715,
        AWATER: 71039573
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.938587, 29.063243999198271],
            [-96.658466, 29.263875999194823],
            [-96.640315, 29.247803999195096],
            [-96.309178, 28.963290999200009],
            [-96.323522, 28.67559699920508],
            [-96.404445, 28.674408999205109],
            [-96.39954, 28.730230999204114],
            [-96.428394, 28.703522999204587],
            [-96.643442, 28.712104999204431],
            [-96.711514, 28.851410999201978],
            [-96.792133, 28.918802999200796],
            [-96.832103, 29.021298999199008],
            [-96.938587, 29.063243999198271]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "073",
        COUNTYNS: "00399760",
        AFFGEOID: "0500000US16073",
        GEOID: "16073",
        NAME: "Owyhee",
        LSAD: "06",
        ALAND: 19860561623,
        AWATER: 74125616
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.026889, 43.596032999057819],
            [-117.025663810446019, 43.680293635011687],
            [-116.911538, 43.603075999057808],
            [-116.844978, 43.594399999057828],
            [-116.781336, 43.478632999058014],
            [-116.622569, 43.370008999058193],
            [-116.579167, 43.306177999058306],
            [-116.511897, 43.290519999058361],
            [-116.425275, 43.293542999058339],
            [-116.385955, 43.193576999058543],
            [-116.266207, 43.112268999058706],
            [-116.191672, 43.082472999058773],
            [-116.20174, 43.048929999058849],
            [-116.145817, 43.013415999058928],
            [-115.964966, 42.943672999059075],
            [-115.935655, 43.001732999058945],
            [-115.791812, 42.972347999059011],
            [-115.771505, 42.938663999059081],
            [-115.60443, 42.937696999059099],
            [-115.441522, 42.931126999059117],
            [-115.454224, 42.767935999059503],
            [-115.037697, 42.768413999059504],
            [-115.038109752433996, 41.998634114738259],
            [-115.313877, 41.996102999061776],
            [-115.625914, 41.997414999061775],
            [-115.870181, 41.996765999061779],
            [-116.332763, 41.997282999061774],
            [-116.625947, 41.997378999061773],
            [-117.018203216741, 41.999839849430856],
            [-117.026197, 41.999889999061779],
            [-117.026551, 42.378556999060557],
            [-117.026253, 42.807446999059394],
            [-117.026652, 43.025127999058896],
            [-117.026889, 43.596032999057819]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "071",
        COUNTYNS: "00516882",
        AFFGEOID: "0500000US21071",
        GEOID: "21071",
        NAME: "Floyd",
        LSAD: "06",
        ALAND: 1018773537,
        AWATER: 6098292
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.940763, 37.716337999086697],
            [-82.75223, 37.756226999086365],
            [-82.64075, 37.720181999086677],
            [-82.576737, 37.694576999086877],
            [-82.563561, 37.684843999086958],
            [-82.614356, 37.67069599908708],
            [-82.623112, 37.503540999088479],
            [-82.584711, 37.466573999088801],
            [-82.672375, 37.306840999090163],
            [-82.710805, 37.285322999090354],
            [-82.762749, 37.425132999089158],
            [-82.835476, 37.414444999089248],
            [-82.831473, 37.466989999088781],
            [-82.92546, 37.486524999088616],
            [-82.879082, 37.535394999088219],
            [-82.940763, 37.716337999086697]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "133",
        COUNTYNS: "00465255",
        AFFGEOID: "0500000US19133",
        GEOID: "19133",
        NAME: "Monona",
        LSAD: "06",
        ALAND: 1797637874,
        AWATER: 12592242
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.33721609470588, 42.214849505956671],
            [-95.670822, 42.211407999061088],
            [-95.672771, 41.863146999062238],
            [-96.126821253465394, 41.866095246763841],
            [-96.159098, 41.910056999062064],
            [-96.132537, 41.974624999061852],
            [-96.223611, 42.022651999061686],
            [-96.272877, 42.047237999061608],
            [-96.2689, 42.113589999061389],
            [-96.347752, 42.166805999061225],
            [-96.33721609470588, 42.214849505956671]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "043",
        COUNTYNS: "00484991",
        AFFGEOID: "0500000US20043",
        GEOID: "20043",
        NAME: "Doniphan",
        LSAD: "06",
        ALAND: 1019094906,
        AWATER: 12434954
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.339895970850293, 40.000028856176222],
            [-95.30829, 39.999997999070814],
            [-95.231114, 39.943783999071137],
            [-95.142445, 39.895419999071422],
            [-95.081534, 39.861717999071615],
            [-95.018743, 39.897371999071417],
            [-94.993374239714385, 39.898565259063709],
            [-94.95154, 39.900532999071388],
            [-94.928466, 39.876343999071523],
            [-94.878677, 39.826521999071822],
            [-94.8778175298512, 39.820414775107352],
            [-94.871144, 39.772993999072142],
            [-94.860371, 39.749529999072273],
            [-94.899316, 39.724041999072426],
            [-94.971078, 39.723145999072436],
            [-94.971317, 39.686409999072644],
            [-95.037464, 39.652904999072852],
            [-95.044050752570399, 39.613668295893994],
            [-95.115189, 39.652548999072863],
            [-95.33974, 39.652979999072862],
            [-95.339895970850293, 40.000028856176222]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "057",
        COUNTYNS: "01101816",
        AFFGEOID: "0500000US40057",
        GEOID: "40057",
        NAME: "Harmon",
        LSAD: "06",
        ALAND: 1391326397,
        AWATER: 3800411
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.000384020773012, 35.030384999112698],
            [-99.888571, 35.030261999112795],
            [-99.888425, 34.943945999113765],
            [-99.731092, 34.944057999113767],
            [-99.720066, 34.768449999115767],
            [-99.666849, 34.724554999116265],
            [-99.666512, 34.50712099911879],
            [-99.842063884146398, 34.506932768000198],
            [-99.929334, 34.576713999117985],
            [-99.997629328163001, 34.561136623678266],
            [-100.000381, 34.56050899911817],
            [-100.000382194816012, 34.746360551677618],
            [-100.000384020773012, 35.030384999112698]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "049",
        COUNTYNS: "00395699",
        AFFGEOID: "0500000US16049",
        GEOID: "16049",
        NAME: "Idaho",
        LSAD: "06",
        ALAND: 21956596295,
        AWATER: 67553776
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.799204532500994, 45.851045434540126],
            [-116.750235, 45.869286999057245],
            [-116.757042, 45.942102999057326],
            [-116.701975, 45.996216999057388],
            [-116.648516, 46.03059699905743],
            [-116.53962, 45.998396999057384],
            [-116.48557, 46.12116999905755],
            [-116.428497, 46.202528999057662],
            [-116.188993, 46.155356999057581],
            [-116.13357, 46.215230999057674],
            [-116.012498, 46.22570799905769],
            [-116.127103, 46.298574999057806],
            [-116.170272, 46.371587999057915],
            [-116.076088, 46.361987999057909],
            [-115.999438, 46.306519999057819],
            [-115.814741, 46.265754999057748],
            [-115.778831, 46.267350999057747],
            [-115.733477, 46.362881999057905],
            [-115.6342, 46.437580999058021],
            [-115.630708, 46.473037999058093],
            [-114.591116003227015, 46.652550479413229],
            [-114.547321, 46.644484999058406],
            [-114.453239, 46.649265999058414],
            [-114.360709, 46.669058999058457],
            [-114.335724935404997, 46.655272966412028],
            [-114.320665, 46.64696299905841],
            [-114.331338, 46.577780999058277],
            [-114.351655, 46.508118999058148],
            [-114.403019, 46.498674999058139],
            [-114.384756, 46.411783999057988],
            [-114.422458, 46.387096999057945],
            [-114.431708, 46.310743999057827],
            [-114.441326, 46.273799999057765],
            [-114.449819, 46.237118999057721],
            [-114.445928, 46.173932999057627],
            [-114.514706, 46.167725999057616],
            [-114.5213, 46.12528699905755],
            [-114.460049, 46.097103999057509],
            [-114.480241, 46.030324999057427],
            [-114.441185, 45.988452999057372],
            [-114.402261, 45.961488999057337],
            [-114.413168, 45.911478999057294],
            [-114.388243, 45.882339999057258],
            [-114.422963, 45.855380999057225],
            [-114.517143, 45.835992999057211],
            [-114.562509, 45.779926999057167],
            [-114.504869, 45.722175999057107],
            [-114.499637, 45.669034999057061],
            [-114.53577, 45.650612999057046],
            [-114.538132, 45.606833999057002],
            [-114.523773044931005, 45.585326505663581],
            [-114.663725, 45.471004999056909],
            [-114.793778, 45.508308999056936],
            [-114.793207, 45.436333999056892],
            [-114.60394, 45.295825999056838],
            [-114.694001, 45.197181999056795],
            [-115.975787, 45.195281999056796],
            [-115.976659, 45.219222999056804],
            [-116.121662, 45.158548999056784],
            [-116.144386, 45.107650999056773],
            [-116.285969, 45.128731999056782],
            [-116.342876, 45.267871999056808],
            [-116.690916254857996, 45.26898413702672],
            [-116.674648, 45.314341999056836],
            [-116.588195, 45.442919999056905],
            [-116.502756, 45.566607999056977],
            [-116.463504, 45.61578499905702],
            [-116.528272, 45.681472999057071],
            [-116.535698, 45.734230999057104],
            [-116.593004, 45.778540999057164],
            [-116.665344, 45.781997999057154],
            [-116.736268, 45.826178999057191],
            [-116.78752, 45.840203999057216],
            [-116.799204532500994, 45.851045434540126]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "089",
        COUNTYNS: "00485009",
        AFFGEOID: "0500000US20089",
        GEOID: "20089",
        NAME: "Jewell",
        LSAD: "06",
        ALAND: 2356317389,
        AWATER: 11863330
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.504454983636791, 40.002379875806817],
            [-98.274017059752907, 40.002337449800706],
            [-98.076034, 40.002300999070812],
            [-97.931824926317191, 40.002236344090406],
            [-97.931482, 39.653766999072857],
            [-97.931844, 39.566920999073375],
            [-98.487384, 39.567491999073376],
            [-98.505266, 39.56760299907338],
            [-98.504454983636791, 40.002379875806817]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "091",
        COUNTYNS: "01248019",
        AFFGEOID: "0500000US45091",
        GEOID: "45091",
        NAME: "York",
        LSAD: "06",
        ALAND: 1763165632,
        AWATER: 39248969
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.487016, 35.034852999112736],
            [-81.409574, 35.046414999112621],
            [-81.367604910676405, 35.164092160315811],
            [-81.328090989291809, 35.162285914668729],
            [-81.04287, 35.149247999111473],
            [-81.042302450138592, 35.146768648579709],
            [-81.036759, 35.122551999111771],
            [-81.058029, 35.073189999112323],
            [-81.041489, 35.044702999112637],
            [-80.93495, 35.107408999111939],
            [-80.906239954082494, 35.075184268877898],
            [-80.868652, 34.951142999113692],
            [-80.866694, 34.84807099911486],
            [-80.898215, 34.820745999115161],
            [-81.478454, 34.821508999115167],
            [-81.457257, 34.839279999114957],
            [-81.492108, 34.948955999113714],
            [-81.487016, 35.034852999112736]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "077",
        COUNTYNS: "00659483",
        AFFGEOID: "0500000US27077",
        GEOID: "27077",
        NAME: "Lake of the Woods",
        LSAD: "06",
        ALAND: 3361462398,
        AWATER: 1247519338
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.34273, 48.713027999064884],
            [-95.089863, 48.713061999064884],
            [-95.091464, 48.92061799906579],
            [-95.235606, 48.881449999065616],
            [-95.30104, 48.90421599906572],
            [-95.319888783472194, 48.99875834307656],
            [-95.153711, 48.998902999066161],
            [-95.153309, 49.184879999067036],
            [-95.15333, 49.309286999067659],
            [-95.153314, 49.384357999068023],
            [-95.058404, 49.353169999067873],
            [-94.988908, 49.368896999067942],
            [-94.952111, 49.368678999067953],
            [-94.878454, 49.333192999067755],
            [-94.816222, 49.320986999067713],
            [-94.797244, 49.214283999067185],
            [-94.774228, 49.124993999066753],
            [-94.750221, 49.099762999066627],
            [-94.718932, 48.999990999066156],
            [-94.683069, 48.883928999065631],
            [-94.685681, 48.840118999065432],
            [-94.694312, 48.789351999065211],
            [-94.61901, 48.737373999064978],
            [-94.508862, 48.700361999064825],
            [-94.446604, 48.692899999064792],
            [-94.430201279234097, 48.698308784593316],
            [-94.428702, 48.367332999063457],
            [-95.210953, 48.36597099906345],
            [-95.211966, 48.539960999064142],
            [-95.34254, 48.540210999064143],
            [-95.34273, 48.713027999064884]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "079",
        COUNTYNS: "01034228",
        AFFGEOID: "0500000US38079",
        GEOID: "38079",
        NAME: "Rolette",
        LSAD: "06",
        ALAND: 2338953786,
        AWATER: 93938154
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.182706982328, 48.99923022370745],
            [-99.91378, 48.999048999066162],
            [-99.525700059071696, 48.999271290221657],
            [-99.525895, 48.718383999064905],
            [-99.493002, 48.71835899906489],
            [-99.493189, 48.544833999064174],
            [-100.145858, 48.54521099906416],
            [-100.145494, 48.718874999064901],
            [-100.183034, 48.718908999064908],
            [-100.182706982328, 48.99923022370745]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "011",
        COUNTYNS: "01688746",
        AFFGEOID: "0500000US30011",
        GEOID: "30011",
        NAME: "Carter",
        LSAD: "06",
        ALAND: 8651792773,
        AWATER: 19503321
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.038205, 45.352034999056855],
            [-104.987318, 45.352039999056849],
            [-104.98194, 45.786981999057154],
            [-104.939205, 46.136574999057565],
            [-104.885495, 46.136594999057564],
            [-104.516126, 46.13619599905757],
            [-104.516078, 46.054843999057454],
            [-104.390744, 46.054825999057456],
            [-104.390876, 45.970362999057365],
            [-104.134842, 45.968832999057355],
            [-104.134826, 45.881927999057254],
            [-104.044133156106994, 45.881976257407061],
            [-104.043776, 45.864706999057233],
            [-104.042597, 45.749997999057129],
            [-104.041937, 45.557914999056969],
            [-104.041764, 45.490788999056917],
            [-104.040358, 45.335945999056854],
            [-104.040135756493015, 45.2128907610984],
            [-104.039977, 45.124987999056771],
            [-104.039138, 44.998519999056761],
            [-104.057698, 44.99743099905676],
            [-105.025266, 45.000289999056761],
            [-105.038252188273987, 45.000291672048562],
            [-105.038205, 45.352034999056855]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "137",
        COUNTYNS: "00485032",
        AFFGEOID: "0500000US20137",
        GEOID: "20137",
        NAME: "Norton",
        LSAD: "06",
        ALAND: 2274122723,
        AWATER: 8421464
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.177797545879002, 40.001565812922415],
            [-99.813401, 40.00139999907082],
            [-99.628253849224492, 40.001771946339204],
            [-99.625326726399592, 40.00177782671782],
            [-99.627953, 39.567436999073379],
            [-100.161667, 39.567276999073371],
            [-100.180351, 39.566889999073389],
            [-100.177797545879002, 40.001565812922415]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "127",
        COUNTYNS: "00659509",
        AFFGEOID: "0500000US27127",
        GEOID: "27127",
        NAME: "Redwood",
        LSAD: "06",
        ALAND: 2275500535,
        AWATER: 8220933
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.594817, 44.54230499905686],
            [-95.3604, 44.541761999056867],
            [-95.360177, 44.698539999056798],
            [-95.244218, 44.667059999056811],
            [-95.132652, 44.596442999056841],
            [-94.880335, 44.516793999056873],
            [-94.865824, 44.498217999056898],
            [-94.866259, 44.282371999057013],
            [-95.10748, 44.281767999057017],
            [-95.108777, 44.195043999057098],
            [-95.121234, 44.195273999057093],
            [-95.462547, 44.195941999057077],
            [-95.59357, 44.195529999057086],
            [-95.594817, 44.54230499905686]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "007",
        COUNTYNS: "01581063",
        AFFGEOID: "0500000US55007",
        GEOID: "55007",
        NAME: "Bayfield",
        LSAD: "06",
        ALAND: 3827691502,
        AWATER: 1460289843
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.551341535656391, 46.757475292518137],
            [-91.511077, 46.757452999058643],
            [-91.411799, 46.789639999058707],
            [-91.360804, 46.798135999058722],
            [-91.314815, 46.826824999058779],
            [-91.256705, 46.836886999058805],
            [-91.211647, 46.866823999058866],
            [-91.167601, 46.844759999058823],
            [-91.130475, 46.870007999058878],
            [-91.087357, 46.879470999058896],
            [-91.034518, 46.90305299905895],
            [-90.984617, 46.925601999058998],
            [-90.989375, 46.982273999059132],
            [-90.924128, 47.001887999059171],
            [-90.85284, 46.962557999059094],
            [-90.80628, 46.938737999059043],
            [-90.745305, 46.894253999058925],
            [-90.798936, 46.823142999058774],
            [-90.861046, 46.765630999058651],
            [-90.880841, 46.739956999058606],
            [-90.852704, 46.699581999058516],
            [-90.915152, 46.658409999058442],
            [-90.932626, 46.617295999058356],
            [-90.95565, 46.592502999058304],
            [-90.925229395342583, 46.587488071503905],
            [-90.924583, 46.15461599905759],
            [-91.551282, 46.157044999057597],
            [-91.551936, 46.748110999058611],
            [-91.551341535656391, 46.757475292518137]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "003",
        COUNTYNS: "01581061",
        AFFGEOID: "0500000US55003",
        GEOID: "55003",
        NAME: "Ashland",
        LSAD: "06",
        ALAND: 2706535135,
        AWATER: 3230651989
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-90.776921, 47.024323999059234],
              [-90.74018, 47.036104999059262],
              [-90.650422, 47.054677999059294],
              [-90.560936, 47.03701299905925],
              [-90.544875, 47.017382999059208],
              [-90.511623, 46.961406999059079],
              [-90.524056, 46.935663999059017],
              [-90.549104, 46.915460999058979],
              [-90.637124, 46.906723999058947],
              [-90.679446, 46.956031999059071],
              [-90.712032, 46.985259999059132],
              [-90.767985, 47.002326999059179],
              [-90.776921, 47.024323999059234]
            ]
          ],
          [
            [
              [-90.925229395342583, 46.587488071503905],
              [-90.898308, 46.583049999058289],
              [-90.829031, 46.61606599905835],
              [-90.794775, 46.624940999058367],
              [-90.755287, 46.646288999058413],
              [-90.73726, 46.692266999058511],
              [-90.663273, 46.645325999058407],
              [-90.56556, 46.584891999058293],
              [-90.548578555966074, 46.586236257808793],
              [-90.552227, 46.240850999057727],
              [-90.425978, 46.240449999057709],
              [-90.426274, 46.154049999057591],
              [-90.303072, 46.154848999057599],
              [-90.302063, 45.981348999057374],
              [-90.676968, 45.981553999057368],
              [-90.925174, 45.981054999057378],
              [-90.924583, 46.15461599905759],
              [-90.925229395342583, 46.587488071503905]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "013",
        COUNTYNS: "01581066",
        AFFGEOID: "0500000US55013",
        GEOID: "55013",
        NAME: "Burnett",
        LSAD: "06",
        ALAND: 2127825031,
        AWATER: 151944424
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.869689, 45.715141999057082],
            [-92.840742106625498, 45.729396733526812],
            [-92.826013, 45.736649999057114],
            [-92.776496, 45.790013999057173],
            [-92.759458, 45.835340999057216],
            [-92.721128, 45.883804999057254],
            [-92.656125, 45.924441999057308],
            [-92.580565, 45.946249999057343],
            [-92.545682, 45.970117999057358],
            [-92.472761, 45.972951999057365],
            [-92.44963, 46.0022519990574],
            [-92.392681, 46.019539999057422],
            [-92.35176, 46.015684999057413],
            [-92.338239, 46.052148999057458],
            [-92.294033, 46.074376999057492],
            [-92.293830608578702, 46.157321305553602],
            [-92.049636, 46.157596999057589],
            [-92.033404, 45.983869999057376],
            [-92.031417, 45.639927999057036],
            [-92.154888, 45.63974199905703],
            [-92.154443, 45.725615999057105],
            [-92.528198, 45.728679999057107],
            [-92.529111, 45.642170999057029],
            [-92.886697124408997, 45.644147999057047],
            [-92.869689, 45.715141999057082]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "049",
        COUNTYNS: "00066854",
        AFFGEOID: "0500000US05049",
        GEOID: "05049",
        NAME: "Fulton",
        LSAD: "06",
        ALAND: 1601111649,
        AWATER: 5526927
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.150677, 36.435853999098157],
            [-92.150306249501185, 36.498140332421762],
            [-92.120429104388108, 36.498193123097252],
            [-91.985802, 36.49843099909755],
            [-91.672342456443701, 36.49925663289644],
            [-91.64259, 36.499334999097542],
            [-91.450004918137395, 36.49754021228226],
            [-91.454437, 36.335464999099123],
            [-91.565866, 36.337399999099112],
            [-91.566078, 36.249944999099966],
            [-91.691435, 36.253300999099935],
            [-92.15699, 36.261247999099851],
            [-92.150677, 36.435853999098157]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "089",
        COUNTYNS: "00295730",
        AFFGEOID: "0500000US12089",
        GEOID: "12089",
        NAME: "Nassau",
        LSAD: "06",
        ALAND: 1679966574,
        AWATER: 200159166
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.050982981680889, 30.36836777512136],
            [-82.040766, 30.370143999176435],
            [-82.042011, 30.403252999175901],
            [-82.028232, 30.447384999175195],
            [-82.018381, 30.531175999173854],
            [-82.015728, 30.601697999172746],
            [-82.049529, 30.655543999171893],
            [-82.041834, 30.692373999171316],
            [-82.032667, 30.750673999170402],
            [-81.994992, 30.78607399916984],
            [-81.943187, 30.827436999169194],
            [-81.905978439406383, 30.821407340671279],
            [-81.902354, 30.820819999169284],
            [-81.868624, 30.792755999169742],
            [-81.808543, 30.790015999169778],
            [-81.763384, 30.773820999170034],
            [-81.732238, 30.749634999170411],
            [-81.668283, 30.744643999170492],
            [-81.633273, 30.72960299917073],
            [-81.56171, 30.715596999170948],
            [-81.528281, 30.723358999170813],
            [-81.507218, 30.722935999170826],
            [-81.444124, 30.709713999171033],
            [-81.42742, 30.698019999171223],
            [-81.443099, 30.600937999172753],
            [-81.434064, 30.522568999173998],
            [-81.428954737643295, 30.506183073339461],
            [-81.499575, 30.563792999173341],
            [-81.543018, 30.52388899917397],
            [-81.610212, 30.554379999173484],
            [-81.606158, 30.585565999172989],
            [-81.721507, 30.571851999173209],
            [-81.746923, 30.530818999173867],
            [-81.831227, 30.467436999174872],
            [-82.037542, 30.283456999177822],
            [-82.049236, 30.273431999177994],
            [-82.050982981680889, 30.36836777512136]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "099",
        COUNTYNS: "00465238",
        AFFGEOID: "0500000US19099",
        GEOID: "19099",
        NAME: "Jasper",
        LSAD: "06",
        ALAND: 1891773617,
        AWATER: 6565650
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.347933, 41.86310399906224],
            [-93.231858, 41.862710999062237],
            [-92.765999, 41.862372999062245],
            [-92.766759, 41.601354999063204],
            [-92.756508, 41.508793999063563],
            [-92.871421, 41.508521999063575],
            [-93.328614, 41.507823999063568],
            [-93.348681, 41.600998999063208],
            [-93.347933, 41.86310399906224]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "175",
        COUNTYNS: "01008589",
        AFFGEOID: "0500000US37175",
        GEOID: "37175",
        NAME: "Transylvania",
        LSAD: "06",
        ALAND: 980105287,
        AWATER: 5145714
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.056889, 35.053826999112538],
            [-82.983216, 35.131919999111666],
            [-82.920881, 35.292036999109911],
            [-82.83199, 35.318342999109618],
            [-82.745139, 35.422966999108496],
            [-82.619248, 35.303707999109783],
            [-82.5777151484413, 35.146475346959001],
            [-82.68604, 35.124544999111755],
            [-82.746431, 35.079130999112252],
            [-82.762059552113584, 35.081874435116319],
            [-82.783283, 35.085599999112183],
            [-82.897499, 35.056020999112519],
            [-83.00847281226919, 35.026918543036736],
            [-83.056889, 35.053826999112538]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "253",
        COUNTYNS: "00351263",
        AFFGEOID: "0500000US13253",
        GEOID: "13253",
        NAME: "Seminole",
        LSAD: "06",
        ALAND: 609245346,
        AWATER: 55231610
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.021107562362801, 31.075463871471758],
            [-84.922423, 31.072589999165395],
            [-84.730855, 31.069189999165445],
            [-84.78692, 30.8011889991696],
            [-84.863463844382494, 30.711497013825415],
            [-84.864693, 30.711541999171015],
            [-84.91815, 30.772081999170055],
            [-84.934283, 30.834032999169089],
            [-84.935698, 30.878702999168397],
            [-84.983757, 30.936983999167488],
            [-85.006062, 30.977038999166869],
            [-85.002499, 31.000681999166513],
            [-85.011392, 31.053545999165692],
            [-85.021107562362801, 31.075463871471758]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "029",
        COUNTYNS: "00593907",
        AFFGEOID: "0500000US24029",
        GEOID: "24029",
        NAME: "Kent",
        LSAD: "06",
        ALAND: 717515665,
        AWATER: 353303064
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.278527, 39.145763999076074],
            [-76.243166, 39.21336199907563],
            [-76.211253, 39.269811999075266],
            [-76.177704, 39.298700999075081],
            [-76.159673, 39.335908999074839],
            [-76.110527, 39.372256999074601],
            [-76.061498821955695, 39.387748113189609],
            [-75.981109, 39.366568999074644],
            [-75.76690460670919, 39.377651592625767],
            [-75.766895, 39.377498999074575],
            [-75.76044141645049, 39.296789620175183],
            [-75.756435215568501, 39.246687536200724],
            [-75.866315, 39.260858999075325],
            [-76.010292, 39.239516999075462],
            [-76.04763, 39.162665999075955],
            [-76.156944, 39.094981999076424],
            [-76.210991, 38.992579999077101],
            [-76.265036640408695, 39.028550954425661],
            [-76.231765, 39.018517999076927],
            [-76.233457, 39.091384999076432],
            [-76.246481, 39.119587999076259],
            [-76.278527, 39.145763999076074]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "031",
        COUNTYNS: "01639735",
        AFFGEOID: "0500000US47031",
        GEOID: "47031",
        NAME: "Coffee",
        LSAD: "06",
        ALAND: 1111017222,
        AWATER: 14472637
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.279905, 35.387451999108869],
            [-86.25759, 35.412475999108608],
            [-86.234575, 35.452759999108174],
            [-86.245165, 35.63191299910627],
            [-86.245406, 35.688898999105668],
            [-86.207147, 35.70378999910551],
            [-86.08889, 35.646822999106099],
            [-85.985062, 35.660107999105975],
            [-85.948192, 35.573421999106884],
            [-85.876962, 35.524094999107405],
            [-85.869712, 35.380361999108956],
            [-85.913593, 35.289188999109939],
            [-86.02704, 35.34383699910935],
            [-86.158018, 35.36260699910914],
            [-86.261489, 35.333578999109463],
            [-86.279905, 35.387451999108869]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "037",
        COUNTYNS: "01247999",
        AFFGEOID: "0500000US45037",
        GEOID: "45037",
        NAME: "Edgefield",
        LSAD: "06",
        ALAND: 1296025466,
        AWATER: 16321055
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.113728, 33.829879999127009],
            [-82.1557, 33.929289999125771],
            [-82.046974, 33.953209999125484],
            [-82.008298, 33.961645999125359],
            [-81.894595, 33.975414999125206],
            [-81.836803, 33.866500999126544],
            [-81.651759, 33.814509999127189],
            [-82.01655587044489, 33.52905538555671],
            [-82.028238, 33.544933999130606],
            [-82.10624, 33.595636999129951],
            [-82.114653838584786, 33.59790505369994],
            [-82.095527, 33.633868999129476],
            [-82.182987, 33.724112999128337],
            [-82.113728, 33.829879999127009]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "035",
        COUNTYNS: "00069162",
        AFFGEOID: "0500000US05035",
        GEOID: "05035",
        NAME: "Crittenden",
        LSAD: "06",
        ALAND: 1580755099,
        AWATER: 67432622
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.506475, 35.178714999111151],
            [-90.502429, 35.441725999108286],
            [-90.286899, 35.438572999108324],
            [-90.140257, 35.436635999108354],
            [-90.112504, 35.410152999108632],
            [-90.087903, 35.363269999109136],
            [-90.121864, 35.304534999109791],
            [-90.166594, 35.274587999110111],
            [-90.097947, 35.249982999110372],
            [-90.093285, 35.203281999110892],
            [-90.099777, 35.164473999111301],
            [-90.09061, 35.118286999111817],
            [-90.160058, 35.128829999111694],
            [-90.181387, 35.091400999112118],
            [-90.197146, 35.050730999112567],
            [-90.265296, 35.040292999112687],
            [-90.300697, 35.028792999112817],
            [-90.309297, 34.995693999113186],
            [-90.244476, 34.937595999113846],
            [-90.250095, 34.907319999114186],
            [-90.311424250345183, 34.872851143214575],
            [-90.313476, 34.871697999114588],
            [-90.401633, 34.835304999114996],
            [-90.407982753794286, 34.835265978395711],
            [-90.40854, 34.90412099911422],
            [-90.400438, 35.148230999111483],
            [-90.502993, 35.144213999111535],
            [-90.506475, 35.178714999111151]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "109",
        COUNTYNS: "01494707",
        AFFGEOID: "0500000US51109",
        GEOID: "51109",
        NAME: "Louisa",
        LSAD: "06",
        ALAND: 1284973900,
        AWATER: 37790617
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.253989, 38.06281299908391],
            [-78.209384, 38.131127999083375],
            [-78.101748, 38.15256699908322],
            [-77.953639, 38.11850099908348],
            [-77.838217, 38.106238999083565],
            [-77.687469, 38.007805999084347],
            [-77.797306, 37.729357999086602],
            [-77.888908, 37.760807999086339],
            [-77.948222, 37.847220999085621],
            [-78.062481, 37.904684999085163],
            [-78.205325, 37.957439999084748],
            [-78.306763, 38.006473999084363],
            [-78.253989, 38.06281299908391]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "071",
        COUNTYNS: "00399759",
        AFFGEOID: "0500000US16071",
        GEOID: "16071",
        NAME: "Oneida",
        LSAD: "06",
        ALAND: 3105269253,
        AWATER: 3995238
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.999965, 42.327426999060727],
            [-112.654028, 42.328871999060709],
            [-112.653989, 42.41616899906046],
            [-112.497849, 42.415997999060465],
            [-112.498139, 42.502966999060213],
            [-112.419825, 42.503037999060197],
            [-112.298113, 42.454498999060341],
            [-112.327102, 42.323835999060741],
            [-112.190656, 42.350838999060642],
            [-112.126194, 42.285228999060848],
            [-112.070959, 42.238262999060993],
            [-112.109271, 42.169910999061209],
            [-112.109443055819995, 41.997598428468372],
            [-112.109532, 41.997597999061774],
            [-112.159177451510004, 41.99868192879957],
            [-112.264936, 42.000990999061763],
            [-112.648019, 42.000306999061763],
            [-113.000040139624005, 41.998227611600868],
            [-112.999965, 42.327426999060727]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "273",
        COUNTYNS: "01383922",
        AFFGEOID: "0500000US48273",
        GEOID: "48273",
        NAME: "Kleberg",
        LSAD: "06",
        ALAND: 2282572442,
        AWATER: 541041664
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.059464, 27.635861999224051],
            [-97.942146, 27.635931999224045],
            [-97.840678, 27.558357999225503],
            [-97.325216, 27.560895999225451],
            [-97.222991407531794, 27.576607315704766],
            [-97.257325, 27.51064399922641],
            [-97.296057, 27.427174999227983],
            [-97.336122, 27.317817999230058],
            [-97.346850876955685, 27.277958831440309],
            [-97.508863, 27.266635999231028],
            [-97.785102, 27.283713999230709],
            [-97.84134, 27.243954999231466],
            [-97.985887, 27.209307999232124],
            [-97.986076, 27.260493999231148],
            [-98.058078, 27.260980999231144],
            [-98.059464, 27.635861999224051]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "089",
        COUNTYNS: "01622987",
        AFFGEOID: "0500000US26089",
        GEOID: "26089",
        NAME: "Leelanau",
        LSAD: "06",
        ALAND: 899206779,
        AWATER: 5659140425
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-86.065966, 44.82152199905677],
              [-86.058862, 44.911011999056775],
              [-85.980219, 44.906135999056765],
              [-85.9316, 44.968787999056758],
              [-85.854304, 44.938146999056762],
              [-85.780439, 44.977931999056764],
              [-85.746444, 45.051228999056768],
              [-85.681096, 45.092692999056773],
              [-85.633124, 45.170898999056789],
              [-85.551072, 45.210741999056793],
              [-85.531461, 45.177246999056798],
              [-85.56613, 45.043632999056776],
              [-85.555142702708793, 45.027034543193665],
              [-85.618033, 44.774556999056784],
              [-85.815119, 44.774533999056779],
              [-86.078498188030295, 44.778330746717671],
              [-86.065966, 44.82152199905677]
            ]
          ],
          [
            [
              [-86.138095, 45.043037999056764],
              [-86.04443, 45.159581999056783],
              [-85.989412, 45.15106899905679],
              [-85.954021, 45.119280999056791],
              [-85.976883, 45.062659999056777],
              [-86.081487, 44.990095999056756],
              [-86.154824, 45.002393999056764],
              [-86.138095, 45.043037999056764]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "025",
        COUNTYNS: "01135852",
        AFFGEOID: "0500000US41025",
        GEOID: "41025",
        NAME: "Harney",
        LSAD: "06",
        ALAND: 26248071434,
        AWATER: 241568256
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.931595, 42.917004999059138],
            [-119.932611, 43.178962999058562],
            [-119.896969, 43.179016999058568],
            [-119.896581, 43.611163999057787],
            [-119.897434, 43.698199999057671],
            [-119.777528, 43.697956999057659],
            [-119.775402, 43.959085999057322],
            [-119.657183, 43.958919999057329],
            [-118.816931, 43.960732999057321],
            [-118.81689, 44.047827999057226],
            [-118.587125, 44.04026599905724],
            [-118.227645, 44.039861999057244],
            [-118.2321, 43.770828999057557],
            [-118.216832, 42.914702999059145],
            [-118.214698, 42.275890999060884],
            [-118.195311, 42.267118999060912],
            [-118.197369215928006, 41.996994080225285],
            [-118.501002, 41.995445999061779],
            [-118.775869, 41.992691999061798],
            [-119.001022, 41.993792999061789],
            [-119.20828, 41.993176999061788],
            [-119.324184120438986, 41.993875626855093],
            [-119.360123119603003, 41.99409225404299],
            [-119.365337, 42.748957999059549],
            [-119.943779, 42.74637099905955],
            [-119.931595, 42.917004999059138]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "085",
        COUNTYNS: "01244717",
        AFFGEOID: "0500000US45085",
        GEOID: "45085",
        NAME: "Sumter",
        LSAD: "06",
        ALAND: 1722537126,
        AWATER: 44054520
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.616186, 34.099604999123677],
            [-80.618542, 34.109175999123565],
            [-80.58011, 34.099948999123669],
            [-80.479857, 34.168695999122825],
            [-80.481318, 34.112621999123512],
            [-80.321721, 34.080355999123917],
            [-80.228344, 33.995177999124955],
            [-80.180371, 33.952001999125486],
            [-80.000594, 34.04787699912432],
            [-79.903658, 34.002244999124862],
            [-79.893517, 33.988016999125037],
            [-79.974601, 33.94652699912556],
            [-80.195371, 33.838981999126894],
            [-80.262672, 33.795998999127427],
            [-80.449293, 33.737527999128162],
            [-80.534085, 33.643910999129332],
            [-80.627531, 33.731211999128242],
            [-80.620107, 33.743236999128094],
            [-80.633545, 33.852679999126721],
            [-80.601036, 34.035929999124455],
            [-80.635712, 34.084885999123863],
            [-80.616186, 34.099604999123677]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "033",
        COUNTYNS: "01448030",
        AFFGEOID: "0500000US49033",
        GEOID: "49033",
        NAME: "Rich",
        LSAD: "06",
        ALAND: 2664700942,
        AWATER: 149106532
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.510839, 41.423099999063922],
            [-111.401576, 41.654080999063012],
            [-111.460722, 41.816984999062406],
            [-111.507864, 41.852588999062277],
            [-111.471321, 41.928156999062018],
            [-111.50780619568701, 41.999685677426058],
            [-111.471381, 41.999738999061755],
            [-111.374129, 42.000892999061762],
            [-111.046689, 42.001566999061758],
            [-111.045818464842, 41.579844947289082],
            [-111.045789, 41.565570999063354],
            [-111.0466, 41.360691999064166],
            [-111.046636983126007, 41.251626955316027],
            [-111.232824, 41.142880999065085],
            [-111.264974, 41.144043999065083],
            [-111.221776, 41.215118999064778],
            [-111.281298, 41.224969999064733],
            [-111.261803, 41.296680999064428],
            [-111.331944, 41.357471999064174],
            [-111.420728, 41.361307999064167],
            [-111.477344, 41.384168999064066],
            [-111.510839, 41.423099999063922]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "44",
        COUNTYFP: "005",
        COUNTYNS: "01219779",
        AFFGEOID: "0500000US44005",
        GEOID: "44005",
        NAME: "Newport",
        LSAD: "06",
        ALAND: 265310311,
        AWATER: 546985247
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.408433, 41.472487999063709],
            [-71.380542, 41.650325999063028],
            [-71.33097, 41.686961999062881],
            [-71.256728, 41.638136999063065],
            [-71.20860053289509, 41.69030924266557],
            [-71.201327187524896, 41.681768327265601],
            [-71.19564, 41.675089999062934],
            [-71.132888, 41.660101999062988],
            [-71.137492, 41.602560999063208],
            [-71.12057, 41.497447999063603],
            [-71.140224, 41.485854999063662],
            [-71.19302, 41.457930999063763],
            [-71.212672759107392, 41.46660170496682],
            [-71.239804423818896, 41.478572069234204],
            [-71.245992, 41.481301999063675],
            [-71.285639, 41.487804999063663],
            [-71.312694, 41.451401999063805],
            [-71.351096, 41.450801999063799],
            [-71.389284, 41.460604999063762],
            [-71.417212791483692, 41.456031311838181],
            [-71.408433, 41.472487999063709]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "013",
        COUNTYNS: "01513273",
        AFFGEOID: "0500000US53013",
        GEOID: "53013",
        NAME: "Columbia",
        LSAD: "06",
        ALAND: 2249624732,
        AWATER: 12561762
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-118.228635, 46.593361999058303],
            [-118.21566, 46.588923999058309],
            [-118.165292, 46.555134999058247],
            [-117.881857, 46.592192999058298],
            [-117.85185, 46.624669999058369],
            [-117.863347, 46.470666999058089],
            [-117.737303, 46.471453999058092],
            [-117.674946, 46.337650999057871],
            [-117.611903, 46.338477999057865],
            [-117.603425834504009, 45.998760172500091],
            [-117.717852, 45.999865999057398],
            [-117.977657306531995, 46.000168201439415],
            [-117.99697042960301, 46.00019066622999],
            [-117.991481, 46.207701999057669],
            [-118.116624, 46.208049999057671],
            [-118.116117, 46.295454999057796],
            [-118.241872, 46.295063999057803],
            [-118.228635, 46.593361999058303]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "133",
        COUNTYNS: "01581126",
        AFFGEOID: "0500000US55133",
        GEOID: "55133",
        NAME: "Waukesha",
        LSAD: "06",
        ALAND: 1423675192,
        AWATER: 79851458
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.541579, 43.019386999058895],
            [-88.535843, 43.196047999058528],
            [-88.417987, 43.194696999058536],
            [-88.063353, 43.192116999058541],
            [-88.065621, 43.125415999058681],
            [-88.065589, 43.120590999058685],
            [-88.066025, 43.104220999058725],
            [-88.069247, 42.959178999059048],
            [-88.06929, 42.952373999059056],
            [-88.069924, 42.843322999059325],
            [-88.306384, 42.84209499905932],
            [-88.541535, 42.842995999059326],
            [-88.541579, 43.019386999058895]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "069",
        COUNTYNS: "01719590",
        AFFGEOID: "0500000US30069",
        GEOID: "30069",
        NAME: "Petroleum",
        LSAD: "06",
        ALAND: 4288047856,
        AWATER: 48927318
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-108.719189, 47.270341999059852],
            [-108.591111, 47.270134999059842],
            [-108.590835, 47.30651599905994],
            [-108.315481, 47.315151999059971],
            [-108.313048, 47.583649999060732],
            [-108.065418, 47.598293999060786],
            [-107.928591, 47.567611999060688],
            [-107.90912, 47.450691999060354],
            [-107.909603, 47.401579999060203],
            [-107.963887, 47.354011999060077],
            [-107.968626, 47.249822999059795],
            [-107.92164, 47.02400499905923],
            [-107.883113, 46.998340999059167],
            [-107.939276, 46.876240999058886],
            [-107.892233, 46.85121499905884],
            [-107.861812, 46.835605999058807],
            [-107.827687, 46.755874999058626],
            [-108.631462, 46.749391999058624],
            [-108.611116, 46.836775999058801],
            [-108.608954, 47.098125999059413],
            [-108.736838, 47.098217999059393],
            [-108.719189, 47.270341999059852]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "097",
        COUNTYNS: "01719569",
        AFFGEOID: "0500000US30097",
        GEOID: "30097",
        NAME: "Sweet Grass",
        LSAD: "06",
        ALAND: 4805607292,
        AWATER: 17665081
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.292752, 46.133848999057562],
            [-110.281916, 46.18439699905764],
            [-110.281971, 46.220604999057691],
            [-109.653871, 46.219403999057704],
            [-109.65384, 46.132464999057575],
            [-109.605153, 46.044993999057453],
            [-109.417158, 46.044758999057443],
            [-109.423026, 45.960010999057339],
            [-109.506604, 45.959111999057349],
            [-109.505963, 45.870217999057246],
            [-109.548822, 45.870748999057248],
            [-109.563907, 45.783470999057158],
            [-109.56207, 45.60911899905701],
            [-109.685385, 45.609021999057006],
            [-109.685385, 45.565609999056988],
            [-109.808793, 45.565924999056989],
            [-109.808841, 45.522469999056952],
            [-109.932025, 45.522455999056952],
            [-109.931984, 45.348754999056844],
            [-110.064581, 45.348754999056858],
            [-110.064518, 45.172128999056795],
            [-110.228052, 45.172128999056795],
            [-110.219412, 45.348798999056847],
            [-110.220908, 45.78497299905716],
            [-110.292662, 45.785056999057154],
            [-110.292752, 46.133848999057562]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "093",
        COUNTYNS: "01719575",
        AFFGEOID: "0500000US30093",
        GEOID: "30093",
        NAME: "Silver Bow",
        LSAD: "06",
        ALAND: 1859568288,
        AWATER: 1518177
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.085517, 45.860647999057235],
            [-112.923224, 45.93459499905731],
            [-112.938955, 45.993257999057384],
            [-112.852763, 46.048433999057451],
            [-112.777717, 46.048312999057465],
            [-112.77712, 46.135825999057566],
            [-112.681004, 46.135671999057571],
            [-112.577106, 46.178980999057636],
            [-112.505377, 46.184316999057643],
            [-112.519997, 46.136393999057574],
            [-112.404349, 45.888048999057254],
            [-112.401441, 45.83185099905721],
            [-112.190269, 45.748502999057123],
            [-112.461816, 45.742583999057125],
            [-112.498432, 45.759764999057133],
            [-112.549534, 45.684316999057074],
            [-112.687054, 45.624853999057024],
            [-112.759339, 45.748208999057127],
            [-112.988358, 45.808475999057187],
            [-113.085517, 45.860647999057235]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "041",
        COUNTYNS: "01209176",
        AFFGEOID: "0500000US42041",
        GEOID: "42041",
        NAME: "Cumberland",
        LSAD: "06",
        ALAND: 1412834149,
        AWATER: 12521844
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.605448, 40.185361999069805],
            [-77.360082, 40.314074999069099],
            [-77.420845, 40.255367999069421],
            [-77.280548, 40.275922999069302],
            [-77.239163, 40.284101999069257],
            [-77.083942, 40.301219999069161],
            [-76.974219, 40.310329999069118],
            [-76.91497, 40.328483999069022],
            [-76.924152, 40.289165999069233],
            [-76.884916, 40.247264999069458],
            [-76.859023, 40.226327999069561],
            [-76.921265, 40.209206999069664],
            [-76.905875, 40.165722999069907],
            [-77.012601, 40.148420999069998],
            [-77.029433, 40.147546999070002],
            [-77.137425, 40.06994499907043],
            [-77.18563, 40.029500999070663],
            [-77.403606, 39.994477999070853],
            [-77.471085, 39.944076999071143],
            [-77.454109, 39.972431999070977],
            [-77.532481, 40.048748999070554],
            [-77.614665, 40.198548999069722],
            [-77.605448, 40.185361999069805]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "033",
        COUNTYNS: "00758471",
        AFFGEOID: "0500000US29033",
        GEOID: "29033",
        NAME: "Carroll",
        LSAD: "06",
        ALAND: 1799061775,
        AWATER: 17533485
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.759183, 39.524557999073636],
            [-93.758357, 39.611406999073111],
            [-93.278098, 39.615631999073081],
            [-93.275848, 39.458301999074052],
            [-93.11882, 39.417101999074319],
            [-93.104485, 39.383655999074527],
            [-93.200708, 39.401786999074425],
            [-93.230079, 39.327876999074896],
            [-93.332252, 39.309605999075004],
            [-93.333337, 39.250011999075376],
            [-93.399133, 39.226436999075538],
            [-93.477233, 39.292795999075118],
            [-93.49196, 39.22346099907557],
            [-93.650526, 39.248213999075404],
            [-93.758463, 39.207020999075674],
            [-93.759183, 39.524557999073636]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "025",
        COUNTYNS: "01531924",
        AFFGEOID: "0500000US53025",
        GEOID: "53025",
        NAME: "Grant",
        LSAD: "06",
        ALAND: 6939832890,
        AWATER: 289982469
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.002065, 47.126152999059478],
            [-120.007074, 47.220132999059715],
            [-120.004704, 47.319993999059982],
            [-119.918915, 47.364474999060107],
            [-119.87576, 47.437491999060313],
            [-119.533123, 47.441206999060313],
            [-119.532889, 47.527755999060567],
            [-119.405196, 47.612635999060828],
            [-119.320304, 47.626796999060865],
            [-119.298649, 47.728327999061186],
            [-119.21241, 47.80069499906142],
            [-119.212225, 47.88770799906171],
            [-119.125904, 47.917300999061808],
            [-119.103873, 47.96176299906196],
            [-118.982234, 47.96202499906196],
            [-118.973504, 47.943413999061903],
            [-118.979777, 47.26170899905982],
            [-118.982652, 46.911343999058978],
            [-119.368662, 46.912561999058973],
            [-119.369429, 46.737694999058597],
            [-119.369165, 46.678178999058474],
            [-119.45319, 46.679242999058481],
            [-119.511388, 46.727686999058562],
            [-119.618479, 46.647582999058415],
            [-119.874042, 46.628282999058371],
            [-119.904046, 46.638100999058388],
            [-119.973036, 46.737125999058591],
            [-119.927436, 46.798444999058724],
            [-119.966129, 46.943756999059033],
            [-120.042866, 47.073452999059356],
            [-120.002065, 47.126152999059478]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "057",
        COUNTYNS: "01720033",
        AFFGEOID: "0500000US30057",
        GEOID: "30057",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 9293288997,
        AWATER: 39841801
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.687054, 45.624853999057024],
            [-112.549534, 45.684316999057074],
            [-112.498432, 45.759764999057133],
            [-112.461816, 45.742583999057125],
            [-112.190269, 45.748502999057123],
            [-112.085509, 45.851038999057231],
            [-111.926965, 45.855693999057237],
            [-111.805259, 45.79830599905717],
            [-111.80538, 45.786747999057162],
            [-111.763324, 45.786827999057159],
            [-111.763273, 45.772553999057152],
            [-111.721847, 45.772368999057157],
            [-111.721683, 45.743277999057113],
            [-111.659738, 45.74334399905711],
            [-111.536241, 45.642256999057039],
            [-111.35138, 45.641937999057042],
            [-111.35003, 45.350547999056857],
            [-111.377138236734993, 44.751196413020388],
            [-111.385005, 44.755127999056782],
            [-111.438793, 44.720545999056789],
            [-111.456947232493988, 44.695640736896301],
            [-111.474963, 44.708772999056812],
            [-111.781099, 44.708844999056787],
            [-111.781157, 44.781026999056763],
            [-112.065797, 44.783474999056779],
            [-112.065678, 44.825984999056772],
            [-112.167108, 44.826737999056775],
            [-112.191187, 45.000511999056762],
            [-112.334248, 45.000518999056752],
            [-112.333899, 45.088422999056768],
            [-112.456854, 45.088508999056764],
            [-112.458062, 45.349909999056848],
            [-112.505568, 45.4430089990569],
            [-112.628609, 45.4405859990569],
            [-112.691417, 45.483517999056929],
            [-112.687054, 45.624853999057024]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "279",
        COUNTYNS: "00345220",
        AFFGEOID: "0500000US13279",
        GEOID: "13279",
        NAME: "Toombs",
        LSAD: "06",
        ALAND: 942770579,
        AWATER: 18094405
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.438987, 32.293179999147426],
            [-82.40913, 32.353737999146574],
            [-82.409118, 32.353829999146576],
            [-82.354603, 32.292474999147423],
            [-82.232788, 32.318837999147064],
            [-82.181926, 32.165944999149232],
            [-82.225042, 31.913073999152857],
            [-82.407245, 31.949487999152332],
            [-82.431531, 31.966181999152088],
            [-82.483131, 31.968960999152067],
            [-82.438987, 32.293179999147426]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "085",
        COUNTYNS: "01101830",
        AFFGEOID: "0500000US40085",
        GEOID: "40085",
        NAME: "Love",
        LSAD: "06",
        ALAND: 1331240598,
        AWATER: 47493019
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.561243150221088, 33.899061505859144],
            [-97.536818, 33.983876999125101],
            [-97.560764, 34.071088999124022],
            [-96.969627, 34.071019999124019],
            [-96.934791, 34.012941999124727],
            [-96.934343303133005, 33.945588928378363],
            [-96.94461688837049, 33.945013249579588],
            [-96.952313, 33.944581999125582],
            [-96.988745, 33.918467999125902],
            [-96.985567, 33.886521999126295],
            [-97.055838, 33.855740999126688],
            [-97.07859, 33.812755999127226],
            [-97.087852, 33.774098999127702],
            [-97.091072, 33.7351149991282],
            [-97.149394, 33.721966999128355],
            [-97.205652, 33.809823999127246],
            [-97.166629, 33.847310999126783],
            [-97.206141, 33.914279999125952],
            [-97.24618, 33.900343999126115],
            [-97.318243, 33.865120999126546],
            [-97.372941, 33.819453999127141],
            [-97.444193, 33.82377299912708],
            [-97.451469, 33.870929999126496],
            [-97.484143438587594, 33.913889108016861],
            [-97.486505, 33.916993999125921],
            [-97.55827, 33.897098999126165],
            [-97.561243150221088, 33.899061505859144]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "035",
        COUNTYNS: "00596089",
        AFFGEOID: "0500000US24035",
        GEOID: "24035",
        NAME: "Queen Anne's",
        LSAD: "06",
        ALAND: 962649223,
        AWATER: 360044740
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.361727, 38.939174999077473],
            [-76.322296, 39.006374999077003],
            [-76.301847, 39.039650999076784],
            [-76.265036640408695, 39.028550954425661],
            [-76.210991, 38.992579999077101],
            [-76.156944, 39.094981999076424],
            [-76.04763, 39.162665999075955],
            [-76.010292, 39.239516999075462],
            [-75.866315, 39.260858999075325],
            [-75.756435215568501, 39.246687536200724],
            [-75.748154814254093, 39.14313173003579],
            [-75.834948, 39.108436999076311],
            [-75.940933, 38.976294999077219],
            [-75.949335, 38.918332999077613],
            [-76.096956, 38.944258999077434],
            [-76.103512, 38.88230799907786],
            [-76.196868759948003, 38.855741962498755],
            [-76.205063, 38.89272599907779],
            [-76.203638, 38.928381999077551],
            [-76.250868, 38.928249999077551],
            [-76.317947, 38.911311999077668],
            [-76.334019, 38.860237999078009],
            [-76.376202, 38.850460999078074],
            [-76.361727, 38.939174999077473]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "085",
        COUNTYNS: "00516889",
        AFFGEOID: "0500000US21085",
        GEOID: "21085",
        NAME: "Grayson",
        LSAD: "06",
        ALAND: 1291710298,
        AWATER: 31853979
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.66489, 37.556648999088026],
            [-86.59281, 37.564359999087962],
            [-86.511631, 37.622923999087476],
            [-86.409744, 37.566513999087952],
            [-86.27527, 37.593518999087728],
            [-86.114261, 37.567181999087943],
            [-86.047598, 37.449932999088936],
            [-86.019089, 37.394536999089411],
            [-86.064554, 37.348612999089816],
            [-86.157589, 37.335648999089912],
            [-86.187534, 37.340795999089863],
            [-86.469126, 37.32109899909004],
            [-86.487152, 37.362500999089683],
            [-86.612095, 37.395122999089395],
            [-86.66489, 37.556648999088026]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "011",
        COUNTYNS: "00581291",
        AFFGEOID: "0500000US23011",
        GEOID: "23011",
        NAME: "Kennebec",
        LSAD: "06",
        ALAND: 2246806176,
        AWATER: 216724439
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.100491, 44.383853999056953],
            [-70.12939, 44.487216999056898],
            [-70.04235, 44.485234999056885],
            [-70.035546, 44.508082999056882],
            [-70.072226, 44.529323999056871],
            [-70.041163, 44.571252999056846],
            [-70.040127, 44.588452999056841],
            [-69.953495, 44.574687999056849],
            [-69.946761, 44.582345999056834],
            [-69.930451, 44.610851999056827],
            [-69.822683, 44.607758999056834],
            [-69.792813, 44.577329999056843],
            [-69.744282, 44.602606999056839],
            [-69.665525, 44.586401999056847],
            [-69.605746, 44.577263999056846],
            [-69.578898, 44.625107999056823],
            [-69.632971, 44.698762999056804],
            [-69.470382, 44.718160999056785],
            [-69.471712, 44.692898999056801],
            [-69.461251, 44.647397999056821],
            [-69.393123, 44.64037499905681],
            [-69.41684, 44.55107999905686],
            [-69.372419, 44.544584999056859],
            [-69.395987, 44.457077999056906],
            [-69.445498, 44.46383899905689],
            [-69.506382, 44.342869999056973],
            [-69.528223, 44.275446999057031],
            [-69.547398, 44.260987999057036],
            [-69.650171, 44.27962499905702],
            [-69.665441, 44.227575999057066],
            [-69.656942, 44.125460999057154],
            [-69.666298, 44.124002999057154],
            [-69.758208, 44.139333999057136],
            [-69.763566, 44.153216999057129],
            [-69.854983, 44.167861999057116],
            [-69.897783, 44.107661999057164],
            [-70.005471, 44.124518999057159],
            [-70.026319, 44.13383799905715],
            [-69.993792, 44.180593999057102],
            [-70.060498, 44.191774999057088],
            [-70.07426, 44.290153999057011],
            [-70.100183, 44.334520999056984],
            [-70.132595, 44.371381999056972],
            [-70.100491, 44.383853999056953]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "091",
        COUNTYNS: "00424247",
        AFFGEOID: "0500000US17091",
        GEOID: "17091",
        NAME: "Kankakee",
        LSAD: "06",
        ALAND: 1752331448,
        AWATER: 12418602
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.251995, 41.114228999065226],
            [-88.241433, 41.128949999065156],
            [-88.244155, 41.201545999064834],
            [-88.011812, 41.20560399906482],
            [-88.013919, 41.292446999064438],
            [-87.526767886227489, 41.298051601543634],
            [-87.526648158082395, 41.16608994357658],
            [-87.52652, 41.024836999065613],
            [-87.526463305991186, 41.010354817030169],
            [-88.131938, 40.997838999065735],
            [-88.2473, 40.994559999065757],
            [-88.251995, 41.114228999065226]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "163",
        COUNTYNS: "00450396",
        AFFGEOID: "0500000US18163",
        GEOID: "18163",
        NAME: "Vanderburgh",
        LSAD: "06",
        ALAND: 605228197,
        AWATER: 7654554
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.70027, 37.907282999085155],
            [-87.688374, 38.168418999083094],
            [-87.467284, 38.165402999083121],
            [-87.473011, 38.035134999084129],
            [-87.448635857609503, 37.933877867299834],
            [-87.486347, 37.920217999085054],
            [-87.551277, 37.925417999084999],
            [-87.608479, 37.898793999085221],
            [-87.625851, 37.851918999085591],
            [-87.681633, 37.855916999085558],
            [-87.713210785290286, 37.883088389451849],
            [-87.70027, 37.907282999085155]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "101",
        COUNTYNS: "00516897",
        AFFGEOID: "0500000US21101",
        GEOID: "21101",
        NAME: "Henderson",
        LSAD: "06",
        ALAND: 1130483404,
        AWATER: 76387046
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.921744, 37.907884999085148],
            [-87.87254, 37.920998999085036],
            [-87.808013, 37.875190999085405],
            [-87.723635, 37.892057999085274],
            [-87.713210785290286, 37.883088389451849],
            [-87.681633, 37.855916999085558],
            [-87.625851, 37.851918999085591],
            [-87.608479, 37.898793999085221],
            [-87.551277, 37.925417999084999],
            [-87.486347, 37.920217999085054],
            [-87.448635857609503, 37.933877867299834],
            [-87.418585, 37.944762999084858],
            [-87.331765, 37.908252999085136],
            [-87.304057452441981, 37.893432548207556],
            [-87.323402, 37.819238999085862],
            [-87.271608, 37.780119999086175],
            [-87.394093, 37.723994999086642],
            [-87.408547, 37.683562999086966],
            [-87.472811, 37.664868999087119],
            [-87.495331, 37.647546999087268],
            [-87.734545, 37.638375999087351],
            [-87.761995, 37.762364999086337],
            [-87.815093, 37.762422999086326],
            [-87.925393381709796, 37.899591476222518],
            [-87.921744, 37.907884999085148]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "003",
        COUNTYNS: "00395067",
        AFFGEOID: "0500000US16003",
        GEOID: "16003",
        NAME: "Adams",
        LSAD: "06",
        ALAND: 3529648932,
        AWATER: 17359848
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.865338, 44.870598999056774],
            [-116.833632, 44.928975999056767],
            [-116.858313, 44.97876099905676],
            [-116.841314, 45.030906999056768],
            [-116.78371, 45.076971999056767],
            [-116.783128263266988, 45.077712504066781],
            [-116.754643, 45.11397199905678],
            [-116.696047, 45.2546789990568],
            [-116.690916254857996, 45.26898413702672],
            [-116.342876, 45.267871999056808],
            [-116.285969, 45.128731999056782],
            [-116.144386, 45.107650999056773],
            [-116.089118, 45.063616999056777],
            [-116.197011, 44.864215999056761],
            [-116.211211, 44.780871999056778],
            [-116.157627, 44.702026999056791],
            [-116.183571, 44.58365499905684],
            [-116.157022, 44.498927999056882],
            [-116.213314, 44.510812999056867],
            [-116.299876, 44.444575999056909],
            [-116.407648, 44.444435999056907],
            [-116.528898, 44.49154499905687],
            [-116.529167, 44.615153999056837],
            [-116.624153, 44.663268999056811],
            [-116.625034, 44.838951999056768],
            [-116.889295186967018, 44.840529777210072],
            [-116.865338, 44.870598999056774]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "075",
        COUNTYNS: "00659484",
        AFFGEOID: "0500000US27075",
        GEOID: "27075",
        NAME: "Lake",
        LSAD: "06",
        ALAND: 5463008925,
        AWATER: 2282651295
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.797283579908694, 48.205775914516437],
            [-91.781182, 48.200431999062815],
            [-91.714931, 48.199129999062812],
            [-91.692366, 48.119329999062522],
            [-91.559272, 48.108267999062484],
            [-91.542512, 48.053267999062278],
            [-91.465499, 48.066769999062338],
            [-91.336578, 48.069626999062351],
            [-91.250112, 48.084086999062393],
            [-91.156107, 48.140474999062583],
            [-91.03555, 48.189458999062786],
            [-91.032539461265102, 48.190578820961477],
            [-91.020737, 47.887994999061718],
            [-91.023124717702089, 47.464963515121191],
            [-91.146958, 47.381463999060159],
            [-91.262512, 47.27928999905987],
            [-91.387021, 47.187292999059629],
            [-91.456965, 47.139155999059504],
            [-91.573817, 47.08991699905939],
            [-91.644564, 47.026490999059241],
            [-91.737098, 46.982852999059133],
            [-91.7939959120814, 46.942780646200944],
            [-91.787928, 47.546816999060624],
            [-91.799114, 47.732177999061207],
            [-91.797283579908694, 48.205775914516437]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "121",
        COUNTYNS: "00069178",
        AFFGEOID: "0500000US05121",
        GEOID: "05121",
        NAME: "Randolph",
        LSAD: "06",
        ALAND: 1689288878,
        AWATER: 9852495
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.404915, 36.497119999097563],
            [-91.126538874564702, 36.497797700116962],
            [-91.017974, 36.498061999097551],
            [-90.784244155131205, 36.498462199226651],
            [-90.748637, 36.412763999098367],
            [-90.749667, 36.280535999099662],
            [-90.806615, 36.266864999099788],
            [-90.809816, 36.149566999100962],
            [-91.045223, 36.151367999100941],
            [-91.073465, 36.114893999101305],
            [-91.16308, 36.205916999100396],
            [-91.25892, 36.257879999099892],
            [-91.275282, 36.374530999098738],
            [-91.407137443517485, 36.497140711046171],
            [-91.404915, 36.497119999097563]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "107",
        COUNTYNS: "00198169",
        AFFGEOID: "0500000US08107",
        GEOID: "08107",
        NAME: "Routt",
        LSAD: "06",
        ALAND: 6117602774,
        AWATER: 15831744
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.428813, 40.542206999067922],
            [-107.314153, 40.600280999067621],
            [-107.317795759790002, 41.002842480297907],
            [-106.860377948665004, 41.000718628302813],
            [-106.852349, 40.925182999066067],
            [-106.82503, 40.932118999066034],
            [-106.739341, 40.870842999066312],
            [-106.691669, 40.888191999066237],
            [-106.654541, 40.849116999066418],
            [-106.636922, 40.789488999066698],
            [-106.706149, 40.616596999067546],
            [-106.653113, 40.494731999068165],
            [-106.652112, 40.445230999068421],
            [-106.652857, 40.346186999068934],
            [-106.632257, 40.341558999068951],
            [-106.637031, 40.002105999070807],
            [-106.626569, 39.924784999071257],
            [-106.626555, 39.918670999071281],
            [-106.656536, 39.919960999071286],
            [-107.033968, 39.918912999071281],
            [-107.03205, 40.002938999070807],
            [-107.037363, 40.091537999070304],
            [-107.038097, 40.225356999069568],
            [-107.439386, 40.223378999069588],
            [-107.428813, 40.542206999067922]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "067",
        COUNTYNS: "01550040",
        AFFGEOID: "0500000US54067",
        GEOID: "54067",
        NAME: "Nicholas",
        LSAD: "06",
        ALAND: 1675296015,
        AWATER: 19608977
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.231434, 38.263715999082351],
            [-80.911361, 38.41478499908122],
            [-80.817424, 38.478552999080755],
            [-80.881232, 38.507044999080534],
            [-80.785177, 38.557648999080172],
            [-80.650786, 38.526589999080407],
            [-80.62552, 38.354391999081678],
            [-80.436514, 38.267333999082332],
            [-80.711961, 38.079128999083785],
            [-80.882187, 38.103456999083598],
            [-80.96942, 38.215684999082725],
            [-81.070465, 38.210102999082764],
            [-81.231636, 38.263513999082356],
            [-81.231434, 38.263715999082351]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "101",
        COUNTYNS: "01550057",
        AFFGEOID: "0500000US54101",
        GEOID: "54101",
        NAME: "Webster",
        LSAD: "06",
        ALAND: 1433477403,
        AWATER: 7148637
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.650786, 38.526589999080407],
            [-80.508655, 38.645552999079527],
            [-80.457332, 38.739168999078856],
            [-80.393063, 38.727570999078942],
            [-80.318246, 38.684295999079254],
            [-80.280059, 38.694866999079181],
            [-80.183931, 38.525385999080406],
            [-80.245518, 38.388456999081413],
            [-80.352171, 38.345336999081745],
            [-80.360048, 38.225844999082653],
            [-80.436514, 38.267333999082332],
            [-80.62552, 38.354391999081678],
            [-80.650786, 38.526589999080407]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "019",
        COUNTYNS: "01101797",
        AFFGEOID: "0500000US40019",
        GEOID: "40019",
        NAME: "Carter",
        LSAD: "06",
        ALAND: 2129428214,
        AWATER: 30012909
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.562323, 34.507035999118798],
            [-97.35211, 34.506787999118806],
            [-97.352158, 34.376226999120348],
            [-97.036488, 34.375414999120359],
            [-97.028704, 34.332637999120863],
            [-96.933457, 34.332660999120868],
            [-96.933671, 34.172552999122786],
            [-96.968415, 34.172616999122788],
            [-96.969627, 34.071019999124019],
            [-97.560764, 34.071088999124022],
            [-97.562575, 34.289181999121382],
            [-97.562323, 34.507035999118798]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "107",
        COUNTYNS: "00516900",
        AFFGEOID: "0500000US21107",
        GEOID: "21107",
        NAME: "Hopkins",
        LSAD: "06",
        ALAND: 1403746106,
        AWATER: 32182700
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.813413, 37.350644999089781],
            [-87.593575, 37.461962999088833],
            [-87.497112, 37.487801999088596],
            [-87.375145, 37.569987999087907],
            [-87.328543, 37.398461999089378],
            [-87.295965, 37.391818999089431],
            [-87.38703, 37.273605999090464],
            [-87.333741, 37.157185999091467],
            [-87.520116, 37.10545199909194],
            [-87.680617, 37.149238999091558],
            [-87.805029, 37.241082999090743],
            [-87.850706, 37.321642999090038],
            [-87.813413, 37.350644999089781]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "109",
        COUNTYNS: "01784729",
        AFFGEOID: "0500000US17109",
        GEOID: "17109",
        NAME: "McDonough",
        LSAD: "06",
        ALAND: 1526558090,
        AWATER: 2012555
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.904216, 40.639200999067434],
            [-90.789652, 40.63574999906745],
            [-90.445506, 40.627842999067489],
            [-90.450227, 40.276334999069299],
            [-90.909756, 40.284393999069259],
            [-90.904216, 40.639200999067434]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "137",
        COUNTYNS: "00695789",
        AFFGEOID: "0500000US28137",
        GEOID: "28137",
        NAME: "Tate",
        LSAD: "06",
        ALAND: 1048332509,
        AWATER: 16090912
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.231202, 34.702341999116527],
            [-90.200199, 34.724417999116277],
            [-90.171483, 34.759009999115875],
            [-90.030051, 34.707949999116451],
            [-89.912202, 34.772505999115715],
            [-89.723442, 34.772310999115724],
            [-89.722333, 34.685503999116719],
            [-89.669183, 34.685596999116719],
            [-89.668448, 34.554366999118244],
            [-89.721341, 34.554273999118251],
            [-90.198631, 34.554424999118254],
            [-90.239971, 34.62623699911741],
            [-90.231202, 34.702341999116527]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "069",
        COUNTYNS: "00424236",
        AFFGEOID: "0500000US17069",
        GEOID: "17069",
        NAME: "Hardin",
        LSAD: "06",
        ALAND: 459696734,
        AWATER: 10588858
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.412112, 37.599911999087666],
            [-88.375332, 37.599562999087667],
            [-88.179647, 37.599092999087674],
            [-88.132163438272485, 37.574516999917883],
            [-88.131622, 37.572967999087886],
            [-88.072242, 37.528825999088262],
            [-88.066250320097097, 37.50413832643666],
            [-88.062294, 37.487836999088607],
            [-88.157061, 37.466936999088794],
            [-88.281667, 37.452595999088906],
            [-88.358436, 37.404859999089318],
            [-88.415902178775681, 37.421220636808073],
            [-88.412112, 37.599911999087666]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "005",
        COUNTYNS: "00558403",
        AFFGEOID: "0500000US22005",
        GEOID: "22005",
        NAME: "Ascension",
        LSAD: "15",
        ALAND: 751030090,
        AWATER: 33194065
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.106477, 30.108098999180676],
            [-91.090097, 30.141109999180138],
            [-91.073432, 30.16073499917983],
            [-91.059273, 30.213112999178968],
            [-91.024256, 30.305615999177473],
            [-91.021014, 30.321488999177223],
            [-90.986968, 30.345728999176824],
            [-90.891728, 30.345243999176841],
            [-90.830752, 30.31862499917727],
            [-90.694146, 30.175066999179585],
            [-90.632811, 30.221408999178834],
            [-90.641998, 30.166429999179726],
            [-90.915565, 30.113284999180596],
            [-90.963693, 30.066449999181359],
            [-91.074581, 30.081630999181112],
            [-91.089103, 30.06251399918143],
            [-91.105951, 30.062477999181429],
            [-91.106477, 30.108098999180676]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "079",
        COUNTYNS: "00450364",
        AFFGEOID: "0500000US18079",
        GEOID: "18079",
        NAME: "Jennings",
        LSAD: "06",
        ALAND: 975364386,
        AWATER: 4546211
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.799834, 39.128896999076183],
            [-85.686783, 39.130858999076167],
            [-85.56632, 39.132760999076154],
            [-85.440054, 39.195616999075739],
            [-85.444897, 38.912997999077653],
            [-85.5382, 38.91221699907765],
            [-85.537431, 38.882779999077854],
            [-85.628855, 38.817582999078311],
            [-85.683839, 38.815405999078322],
            [-85.733191, 38.831811999078212],
            [-85.795357, 38.807507999078382],
            [-85.798804, 39.068535999076595],
            [-85.799834, 39.128896999076183]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "155",
        COUNTYNS: "01501465",
        AFFGEOID: "0500000US51155",
        GEOID: "51155",
        NAME: "Pulaski",
        LSAD: "06",
        ALAND: 828377752,
        AWATER: 24877168
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.930627, 37.115086999091851],
            [-80.854627, 37.147481999091561],
            [-80.80149, 37.179631999091278],
            [-80.606962, 37.244870999090708],
            [-80.585999, 37.182763999091257],
            [-80.515768, 37.201910999091076],
            [-80.529762, 37.133841999091679],
            [-80.548837, 37.146556999091572],
            [-80.553642, 37.146729999091576],
            [-80.573087, 37.139875999091643],
            [-80.592875, 37.127865999091739],
            [-80.595167, 37.12512499909176],
            [-80.596409, 37.118888999091809],
            [-80.590406, 37.101999999091973],
            [-80.580469, 37.08966199909208],
            [-80.543145, 37.048464999092445],
            [-80.543933, 36.984638999093029],
            [-80.56826, 36.960671999093243],
            [-80.633987, 36.9317799990935],
            [-80.68259, 36.89514399909384],
            [-80.740483, 36.879000999093982],
            [-80.893249, 37.027398999092625],
            [-80.912674, 37.073348999092232],
            [-80.930627, 37.115086999091851]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "063",
        COUNTYNS: "01557702",
        AFFGEOID: "0500000US54063",
        GEOID: "54063",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 1224426774,
        AWATER: 2297605
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.857358570189774, 37.42112871308818],
            [-80.664322, 37.70825199908677],
            [-80.663348, 37.731695999086575],
            [-80.450651, 37.683857999086975],
            [-80.394562, 37.716917999086704],
            [-80.290033810255011, 37.686136458057959],
            [-80.292258, 37.683731999086973],
            [-80.224303244376799, 37.623991370258366],
            [-80.223386, 37.623184999087478],
            [-80.28244, 37.585480999087792],
            [-80.291644, 37.536504999088201],
            [-80.39988, 37.462313999088835],
            [-80.46482, 37.426143999089135],
            [-80.469568890504192, 37.4290254650818],
            [-80.544836, 37.474694999088733],
            [-80.664971, 37.414214999089239],
            [-80.770082, 37.372362999089603],
            [-80.836446, 37.424354999089147],
            [-80.857358570189774, 37.42112871308818]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "145",
        COUNTYNS: "01008577",
        AFFGEOID: "0500000US37145",
        GEOID: "37145",
        NAME: "Person",
        LSAD: "06",
        ALAND: 1016141549,
        AWATER: 31218720
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.138335898871404, 36.54163826915304],
            [-78.942009, 36.542112999097135],
            [-78.796273955140492, 36.541759492678743],
            [-78.802335, 36.235793999100103],
            [-78.950597, 36.239319999100069],
            [-79.153997, 36.241874999100048],
            [-79.138335898871404, 36.54163826915304]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "107",
        COUNTYNS: "00465242",
        AFFGEOID: "0500000US19107",
        GEOID: "19107",
        NAME: "Keokuk",
        LSAD: "06",
        ALAND: 1500067253,
        AWATER: 1929323
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.411995, 41.509547999063571],
            [-92.297494, 41.509789999063564],
            [-91.946043, 41.510748999063551],
            [-91.945571, 41.163577999065005],
            [-92.179974, 41.162661999065001],
            [-92.410233, 41.161941999065007],
            [-92.411995, 41.509547999063571]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "005",
        COUNTYNS: "00974101",
        AFFGEOID: "0500000US36005",
        GEOID: "36005",
        NAME: "Bronx",
        LSAD: "06",
        ALAND: 108910661,
        AWATER: 39682657
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.947484569083201, 40.857773452353776],
            [-73.938081, 40.874698999066304],
            [-73.934892580960678, 40.882648981008366],
            [-73.922030897866492, 40.914718212524917],
            [-73.920471529379299, 40.918606331107092],
            [-73.851123, 40.910008999066129],
            [-73.824047, 40.889865999066224],
            [-73.74806, 40.871720999066312],
            [-73.778958, 40.8117129990666],
            [-73.827583, 40.803935999066631],
            [-73.846654, 40.801023999066651],
            [-73.912456, 40.796095999066672],
            [-73.965834517046304, 40.824745258625136],
            [-73.947484569083201, 40.857773452353776]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "031",
        COUNTYNS: "00974114",
        AFFGEOID: "0500000US36031",
        GEOID: "36031",
        NAME: "Essex",
        LSAD: "06",
        ALAND: 4646734755,
        AWATER: 316368965
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.255998, 43.969796999057316],
            [-74.28187, 44.120551999057156],
            [-74.09349, 44.137614999057135],
            [-74.141424, 44.407267999056934],
            [-73.909687, 44.42969899905691],
            [-73.669281, 44.441354999056912],
            [-73.496604, 44.486080999056881],
            [-73.463838, 44.53768099905686],
            [-73.3479832324265, 44.546162852653865],
            [-73.312871, 44.507245999056877],
            [-73.293613, 44.440558999056911],
            [-73.320954, 44.382668999056946],
            [-73.334637, 44.356876999056958],
            [-73.324229, 44.310022999056997],
            [-73.317455974665094, 44.263522188933827],
            [-73.316618, 44.257768999057028],
            [-73.349889, 44.230355999057061],
            [-73.395399, 44.166902999057115],
            [-73.3998685693646, 44.152485646748843],
            [-73.416319, 44.099421999057185],
            [-73.43688, 44.042577999057244],
            [-73.405977, 44.011484999057274],
            [-73.411248, 43.975595999057312],
            [-73.407742, 43.929886999057359],
            [-73.374051, 43.875562999057429],
            [-73.390302, 43.817370999057509],
            [-73.382526149539899, 43.808158987607008],
            [-73.43812, 43.803686999057518],
            [-74.057005, 43.744512999057598],
            [-74.047062, 43.796342999057529],
            [-74.149076, 43.829612999057488],
            [-74.213734, 43.810874999057503],
            [-74.336826, 43.925222999057375],
            [-74.255998, 43.969796999057316]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "147",
        COUNTYNS: "01101861",
        AFFGEOID: "0500000US40147",
        GEOID: "40147",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1076019752,
        AWATER: 22829398
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.000810206199603, 36.999201052020794],
            [-95.964271232102192, 36.999223143375289],
            [-95.928122, 36.999244999092888],
            [-95.786761870778193, 36.999270916684893],
            [-95.80982, 36.941929999093418],
            [-95.809617, 36.597475999096616],
            [-95.812512, 36.580507999096774],
            [-95.81268, 36.423559999098273],
            [-96.001171, 36.423685999098275],
            [-96.000810206199603, 36.999201052020794]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "007",
        COUNTYNS: "01101791",
        AFFGEOID: "0500000US40007",
        GEOID: "40007",
        NAME: "Beaver",
        LSAD: "06",
        ALAND: 4700042738,
        AWATER: 7347161
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.945466793078012, 36.99824682782171],
            [-100.855634, 36.998625999092894],
            [-100.633324986158001, 37.000173608145779],
            [-100.552683, 37.000734999092884],
            [-100.089481743902994, 37.001479468556369],
            [-100.002571514321986, 37.001619153004874],
            [-100.00316, 36.593249999096649],
            [-100.003761993368016, 36.499701847835134],
            [-100.311018, 36.499687999097539],
            [-100.546145126097016, 36.499505138401339],
            [-100.592614, 36.499468999097537],
            [-100.884174, 36.49968199909754],
            [-100.954152157693017, 36.499529495723742],
            [-100.945466793078012, 36.99824682782171]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "369",
        COUNTYNS: "01383970",
        AFFGEOID: "0500000US48369",
        GEOID: "48369",
        NAME: "Parmer",
        LSAD: "06",
        ALAND: 2281312151,
        AWATER: 11355762
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.043946, 34.379554999120309],
            [-103.043072, 34.619781999117478],
            [-103.042769, 34.747360999116005],
            [-102.525184, 34.74693099911601],
            [-102.525631, 34.31303399912111],
            [-102.61515, 34.312890999121109],
            [-103.043850301172, 34.312749872628999],
            [-103.043946, 34.379554999120309]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "097",
        COUNTYNS: "00069168",
        AFFGEOID: "0500000US05097",
        GEOID: "05097",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 2019824136,
        AWATER: 52913372
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.930509, 34.665607999116958],
            [-93.878163, 34.665269999116944],
            [-93.817003, 34.711300999116432],
            [-93.710297, 34.745295999116024],
            [-93.394289, 34.743392999116061],
            [-93.402189, 34.685192999116722],
            [-93.407227, 34.3968529991201],
            [-93.408124, 34.339526999120778],
            [-93.478646, 34.340850999120775],
            [-93.935371, 34.350096999120652],
            [-93.930509, 34.665607999116958]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "263",
        COUNTYNS: "00326757",
        AFFGEOID: "0500000US13263",
        GEOID: "13263",
        NAME: "Talbot",
        LSAD: "06",
        ALAND: 1013662791,
        AWATER: 8766849
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.700538, 32.844639999139808],
            [-84.570669, 32.845178999139804],
            [-84.506888, 32.881787999139313],
            [-84.380398, 32.779175999140705],
            [-84.286246, 32.747625999141135],
            [-84.33794, 32.720646999141501],
            [-84.408866, 32.561922999143675],
            [-84.444353, 32.562082999143676],
            [-84.586101, 32.522649999144214],
            [-84.637323, 32.534854999144052],
            [-84.69451, 32.518731999144272],
            [-84.667463, 32.563947999143643],
            [-84.694603, 32.583944999143377],
            [-84.693841, 32.685771999141984],
            [-84.682876, 32.730349999141367],
            [-84.700538, 32.844639999139808]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "043",
        COUNTYNS: "00929113",
        AFFGEOID: "0500000US35043",
        GEOID: "35043",
        NAME: "Sandoval",
        LSAD: "06",
        ALAND: 9609139504,
        AWATER: 10854361
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.624283, 36.219808999100273],
            [-106.885053, 36.216569999100301],
            [-106.885317, 35.998968999102473],
            [-106.247164, 36.001433999102446],
            [-106.248161, 35.966326999102812],
            [-106.399713, 35.960094999102864],
            [-106.418778, 35.819985999104318],
            [-106.250499, 35.755817999104963],
            [-106.248848, 35.443281999108272],
            [-106.245162, 35.238660999110493],
            [-106.244216, 35.215656999110749],
            [-106.598725, 35.217851999110728],
            [-106.630581, 35.219739999110701],
            [-106.72899, 35.217977999110715],
            [-106.763062, 35.217993999110718],
            [-107.197244, 35.219458999110714],
            [-107.23889, 35.305506999109753],
            [-107.309386, 35.30568699910976],
            [-107.308667, 35.998555999102479],
            [-107.626511, 36.000287999102461],
            [-107.624283, 36.219808999100273]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "125",
        COUNTYNS: "00450381",
        AFFGEOID: "0500000US18125",
        GEOID: "18125",
        NAME: "Pike",
        LSAD: "06",
        ALAND: 865676037,
        AWATER: 17753971
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.462892, 38.533518999080343],
            [-87.351833, 38.542246999080277],
            [-87.288884, 38.511559999080504],
            [-87.242097, 38.544929999080267],
            [-87.10636, 38.537233999080321],
            [-87.072369, 38.514699999080484],
            [-87.073067, 38.232595999082605],
            [-87.298388, 38.23167099908261],
            [-87.316893, 38.246076999082497],
            [-87.316219, 38.381473999081464],
            [-87.407583, 38.375500999081524],
            [-87.462486, 38.46560999908084],
            [-87.462892, 38.533518999080343]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "069",
        COUNTYNS: "00485001",
        AFFGEOID: "0500000US20069",
        GEOID: "20069",
        NAME: "Gray",
        LSAD: "06",
        ALAND: 2250356164,
        AWATER: 1113338
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.664166, 38.002529999084395],
            [-100.22661, 38.001014999084397],
            [-100.22709, 37.914017999085097],
            [-100.214637, 37.826807999085794],
            [-100.216229, 37.475189999088705],
            [-100.652642, 37.47484799908873],
            [-100.652251, 37.73627499908654],
            [-100.664166, 38.002529999084395]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "123",
        COUNTYNS: "00659507",
        AFFGEOID: "0500000US27123",
        GEOID: "27123",
        NAME: "Ramsey",
        LSAD: "06",
        ALAND: 394086546,
        AWATER: 46423262
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.227696, 45.124532999056783],
            [-93.020518, 45.12386599905679],
            [-92.984619, 45.123621999056773],
            [-92.984192, 44.890813999056761],
            [-93.020044, 44.890749999056766],
            [-93.09607, 44.923286999056764],
            [-93.178539, 44.888337999056773],
            [-93.200334, 44.908933999056757],
            [-93.208092, 45.035743999056763],
            [-93.226929, 45.035670999056762],
            [-93.227059, 45.049936999056769],
            [-93.227696, 45.124532999056783]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "021",
        COUNTYNS: "01715632",
        AFFGEOID: "0500000US30021",
        GEOID: "30021",
        NAME: "Dawson",
        LSAD: "06",
        ALAND: 6143238452,
        AWATER: 29905887
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.363507, 47.529557999060557],
            [-105.363262, 47.79041899906138],
            [-105.234771, 47.790501999061377],
            [-105.235016, 47.703580999061117],
            [-104.97809, 47.703292999061098],
            [-104.978553, 47.616271999060835],
            [-104.593664, 47.616293999060836],
            [-104.594046, 47.529334999060566],
            [-104.513049, 47.529441999060573],
            [-104.513292, 47.354718999060076],
            [-104.420347, 47.354434999060068],
            [-104.316166, 47.180539999059611],
            [-104.306062, 47.042045999059269],
            [-104.412124, 46.904002999058946],
            [-104.603766, 46.860852999058856],
            [-105.027526, 46.861456999058852],
            [-105.069524, 46.919171999058982],
            [-105.197011, 46.919191999058981],
            [-105.196735, 46.977171999059109],
            [-105.324757, 46.97719599905912],
            [-105.324853, 47.181495999059614],
            [-105.406309, 47.181582999059607],
            [-105.363507, 47.529557999060557]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "017",
        COUNTYNS: "01720109",
        AFFGEOID: "0500000US30017",
        GEOID: "30017",
        NAME: "Custer",
        LSAD: "06",
        ALAND: 9798723679,
        AWATER: 25612456
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.191629, 46.137182999057579],
            [-106.153433, 46.137259999057569],
            [-106.155143, 46.4836339990581],
            [-106.120366, 46.483647999058107],
            [-106.086463, 46.845880999058835],
            [-106.086585, 46.860289999058857],
            [-105.832674, 46.860819999058847],
            [-105.618158, 46.83214799905879],
            [-105.618097, 46.744931999058601],
            [-105.576063, 46.658002999058432],
            [-105.492002, 46.658116999058436],
            [-105.491919, 46.600084999058325],
            [-105.449196, 46.570966999058264],
            [-105.239058, 46.570264999058267],
            [-105.239238, 46.541187999058209],
            [-104.984714, 46.540388999058209],
            [-104.98474, 46.569385999058262],
            [-104.858749, 46.569114999058257],
            [-104.858738, 46.612556999058341],
            [-104.733059, 46.612694999058334],
            [-104.732672, 46.482201999058098],
            [-104.905988, 46.48225099905811],
            [-104.885495, 46.136594999057564],
            [-104.939205, 46.136574999057565],
            [-104.98194, 45.786981999057154],
            [-106.191734, 45.788599999057169],
            [-106.191629, 46.137182999057579]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "50",
        COUNTYFP: "023",
        COUNTYNS: "01461768",
        AFFGEOID: "0500000US50023",
        GEOID: "50023",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1779421703,
        AWATER: 21184426
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.952167, 44.161270999057123],
            [-72.92207, 44.222231999057072],
            [-72.89598, 44.276283999057028],
            [-72.911398, 44.281768999057014],
            [-72.855592, 44.365550999056957],
            [-72.826112, 44.359189999056959],
            [-72.839132, 44.403354999056937],
            [-72.804995, 44.451675999056903],
            [-72.765856, 44.442594999056915],
            [-72.654398, 44.398223999056938],
            [-72.596807, 44.47929199905689],
            [-72.492914, 44.423029999056915],
            [-72.434315, 44.506097999056877],
            [-72.321277, 44.462571999056912],
            [-72.223689, 44.42457299905692],
            [-72.220442, 44.421196999056932],
            [-72.217104, 44.41108699905692],
            [-72.26985, 44.340226999056981],
            [-72.317621, 44.298383999057009],
            [-72.368353, 44.204056999057087],
            [-72.419747, 44.220978999057067],
            [-72.443032, 44.128668999057147],
            [-72.589333, 44.160281999057126],
            [-72.601395, 44.134862999057141],
            [-72.62891, 44.088638999057203],
            [-72.683772, 44.012937999057272],
            [-72.742303, 44.02965499905725],
            [-72.879324, 44.068834999057216],
            [-72.909019, 44.072586999057208],
            [-72.933281, 44.153996999057121],
            [-72.952167, 44.161270999057123]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "275",
        COUNTYNS: "01383923",
        AFFGEOID: "0500000US48275",
        GEOID: "48275",
        NAME: "Knox",
        LSAD: "06",
        ALAND: 2203109038,
        AWATER: 12577261
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.996434, 33.835966999126917],
            [-99.835143, 33.835799999126941],
            [-99.700705, 33.799962999127374],
            [-99.554906, 33.798555999127395],
            [-99.474498, 33.733848999128199],
            [-99.472444, 33.399022999132484],
            [-99.99098, 33.3974039991325],
            [-99.996434, 33.835966999126917]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "159",
        COUNTYNS: "01639791",
        AFFGEOID: "0500000US47159",
        GEOID: "47159",
        NAME: "Smith",
        LSAD: "06",
        ALAND: 814020549,
        AWATER: 28690300
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.136803, 36.295858999099515],
            [-86.0809, 36.355366999098941],
            [-85.977143, 36.40507599909845],
            [-85.978893, 36.426072999098238],
            [-85.895732, 36.420445999098305],
            [-85.828026, 36.411835999098386],
            [-85.82766, 36.255271999099918],
            [-85.780148, 36.238010999100084],
            [-85.809168, 36.206597999100396],
            [-85.807415, 36.131581999101144],
            [-85.864024, 36.093808999101519],
            [-85.910118, 36.116924999101293],
            [-86.059706, 36.086023999101606],
            [-86.115607, 36.26888599909978],
            [-86.136803, 36.295858999099515]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "203",
        COUNTYNS: "00485062",
        AFFGEOID: "0500000US20203",
        GEOID: "20203",
        NAME: "Wichita",
        LSAD: "06",
        ALAND: 1861078210,
        AWATER: 61987
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.567094, 38.699668999079151],
            [-101.484383, 38.700165999079132],
            [-101.128379, 38.700602999079145],
            [-101.125438, 38.264508999082352],
            [-101.542312, 38.263206999082371],
            [-101.567482, 38.263144999082371],
            [-101.567094, 38.699668999079151]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "051",
        COUNTYNS: "01026124",
        AFFGEOID: "0500000US37051",
        GEOID: "37051",
        NAME: "Cumberland",
        LSAD: "06",
        ALAND: 1690029281,
        AWATER: 15379703
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.097602, 35.174193999111203],
            [-79.095808, 35.192067999110996],
            [-78.880408, 35.230439999110573],
            [-78.794564, 35.266046999110188],
            [-78.617127, 35.245577999110417],
            [-78.639478, 35.115773999111859],
            [-78.672292, 35.085280999112193],
            [-78.647033, 34.992253999113217],
            [-78.494705, 34.85618199911476],
            [-78.516123, 34.845918999114879],
            [-78.799597, 34.850856999114832],
            [-78.901998, 34.835267999115004],
            [-78.940844, 34.903367999114231],
            [-79.038747, 34.95271499911366],
            [-79.036917, 34.956952999113632],
            [-79.112921, 35.125650999111748],
            [-79.097602, 35.174193999111203]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "083",
        COUNTYNS: "01074054",
        AFFGEOID: "0500000US39083",
        GEOID: "39083",
        NAME: "Knox",
        LSAD: "06",
        ALAND: 1361054324,
        AWATER: 10718576
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.744931, 40.349602999068907],
            [-82.646503, 40.345075999068932],
            [-82.623609, 40.549878999067879],
            [-82.374898, 40.550870999067882],
            [-82.336962, 40.555000999067843],
            [-82.22011, 40.568205999067793],
            [-82.179069, 40.573111999067748],
            [-82.184607, 40.456279999068357],
            [-82.195911, 40.239070999069504],
            [-82.477273, 40.245809999069458],
            [-82.476114, 40.264702999069364],
            [-82.750747, 40.276995999069293],
            [-82.744931, 40.349602999068907]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "33",
        COUNTYFP: "015",
        COUNTYNS: "00873181",
        AFFGEOID: "0500000US33015",
        GEOID: "33015",
        NAME: "Rockingham",
        LSAD: "06",
        ALAND: 1800358797,
        AWATER: 259322531
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.451154, 42.930899999059108],
            [-71.377186, 42.94714499905907],
            [-71.392388, 43.009308999058923],
            [-71.402962, 43.04105399905886],
            [-71.356117, 43.102042999058732],
            [-71.317709, 43.162398999058595],
            [-71.305471, 43.178457999058566],
            [-71.247336, 43.275231999058377],
            [-71.127948, 43.20007199905853],
            [-71.026686, 43.137946999058649],
            [-71.072936, 43.081680999058769],
            [-70.875436, 43.080421999058771],
            [-70.819549285713009, 43.123230972489878],
            [-70.756397, 43.079987999058773],
            [-70.703818, 43.059824999058819],
            [-70.735477, 43.012200999058926],
            [-70.765222, 42.975348999059008],
            [-70.798636, 42.924287999059118],
            [-70.81143765483408, 42.88861487751231],
            [-70.817296, 42.872289999059255],
            [-70.86475, 42.870257999059248],
            [-70.930799, 42.884588999059211],
            [-70.9665, 42.868988999059255],
            [-71.031201, 42.859088999059267],
            [-71.048716413698585, 42.831064337141548],
            [-71.064201, 42.806288999059412],
            [-71.116375012177485, 42.811902913494492],
            [-71.149703, 42.81548899905939],
            [-71.186104, 42.790688999059448],
            [-71.181803, 42.737589999059573],
            [-71.245384032920697, 42.736555327634186],
            [-71.364418, 42.785236999059464],
            [-71.360679, 42.798961999059422],
            [-71.427067, 42.824839999059364],
            [-71.442301, 42.890398999059201],
            [-71.451154, 42.930899999059108]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "209",
        COUNTYNS: "00758557",
        AFFGEOID: "0500000US29209",
        GEOID: "29209",
        NAME: "Stone",
        LSAD: "06",
        ALAND: 1201843614,
        AWATER: 121059169
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.611003, 36.922830999093584],
            [-93.610126, 36.99580999909292],
            [-93.337451, 36.992493999092957],
            [-93.304359, 36.816865999094553],
            [-93.315327106529097, 36.498312725395458],
            [-93.426989, 36.498584999097545],
            [-93.584281547355687, 36.498901677725847],
            [-93.5738, 36.820031999094518],
            [-93.612929, 36.820561999094522],
            [-93.611003, 36.922830999093584]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "197",
        COUNTYNS: "01501379",
        AFFGEOID: "0500000US51197",
        GEOID: "51197",
        NAME: "Wythe",
        LSAD: "06",
        ALAND: 1196367968,
        AWATER: 7310342
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.379402, 36.95244199909331],
            [-81.224624, 37.047834999092451],
            [-81.114319, 37.022611999092675],
            [-80.912674, 37.073348999092232],
            [-80.893249, 37.027398999092625],
            [-80.740483, 36.879000999093982],
            [-80.812735, 36.85323199909422],
            [-81.042428, 36.806447999094644],
            [-81.08851, 36.767421999095014],
            [-81.261987, 36.763032999095053],
            [-81.326985, 36.899751999093795],
            [-81.379402, 36.95244199909331]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "003",
        COUNTYNS: "01213657",
        AFFGEOID: "0500000US42003",
        GEOID: "42003",
        NAME: "Allegheny",
        LSAD: "06",
        ALAND: 1890889701,
        AWATER: 37411492
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.219279, 40.58190399906772],
            [-80.207978, 40.588037999067687],
            [-80.14485, 40.613473999067566],
            [-80.148451, 40.674289999067263],
            [-79.69293, 40.669743999067279],
            [-79.692587, 40.669731999067281],
            [-79.712477, 40.620075999067524],
            [-79.749187, 40.598969999067634],
            [-79.76377, 40.592965999067658],
            [-79.772285, 40.58378799906771],
            [-79.773331, 40.580757999067714],
            [-79.77437, 40.569766999067781],
            [-79.77187, 40.562966999067811],
            [-79.765946, 40.550914999067864],
            [-79.765415, 40.549853999067878],
            [-79.748228, 40.551180999067867],
            [-79.722387, 40.542042999067917],
            [-79.701711, 40.525490999068005],
            [-79.704503, 40.434024999068477],
            [-79.722151, 40.409097999068599],
            [-79.764646, 40.3903459990687],
            [-79.783806, 40.315440999069089],
            [-79.781761, 40.227710999069565],
            [-79.870585, 40.197414999069728],
            [-79.898846, 40.194859999069749],
            [-79.959532, 40.216133999069626],
            [-79.914139, 40.252517999069433],
            [-80.183472, 40.332781999069006],
            [-80.360873, 40.477538999068244],
            [-80.219279, 40.58190399906772]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "439",
        COUNTYNS: "01384005",
        AFFGEOID: "0500000US48439",
        GEOID: "48439",
        NAME: "Tarrant",
        LSAD: "06",
        ALAND: 2236924384,
        AWATER: 100013729
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.544181, 32.994176999137807],
            [-97.39848, 32.990838999137843],
            [-97.356048, 32.990196999137858],
            [-97.180907, 32.989812999137861],
            [-97.032287, 32.989323999137866],
            [-97.034085, 32.816659999140185],
            [-97.03479, 32.78604099914061],
            [-97.035167, 32.759367999140977],
            [-97.036207, 32.711112999141633],
            [-97.036417, 32.69318799914187],
            [-97.038385, 32.54866199914386],
            [-97.086834, 32.549427999143852],
            [-97.313984, 32.552626999143811],
            [-97.34899, 32.553144999143797],
            [-97.386367, 32.553558999143796],
            [-97.550582, 32.555390999143768],
            [-97.544181, 32.994176999137807]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "051",
        COUNTYNS: "00659471",
        AFFGEOID: "0500000US27051",
        GEOID: "27051",
        NAME: "Grant",
        LSAD: "06",
        ALAND: 1418786606,
        AWATER: 71704359
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.265366, 46.108609999057535],
            [-95.76975, 46.107449999057529],
            [-95.758508, 45.759931999057137],
            [-96.254022, 45.759819999057143],
            [-96.253675, 45.934647999057312],
            [-96.26614, 46.021609999057411],
            [-96.265366, 46.108609999057535]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "011",
        COUNTYNS: "00516852",
        AFFGEOID: "0500000US21011",
        GEOID: "21011",
        NAME: "Bath",
        LSAD: "06",
        ALAND: 722081570,
        AWATER: 13486212
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.977924, 38.19211799908291],
            [-83.848355, 38.296948999082105],
            [-83.725393, 38.292583999082147],
            [-83.64615, 38.171000999083077],
            [-83.635195, 38.187524999082946],
            [-83.53442, 38.094692999083669],
            [-83.463285, 38.07971099908378],
            [-83.498846, 38.050619999084006],
            [-83.574989, 38.058882999083949],
            [-83.580859, 38.011567999084313],
            [-83.659293, 38.028260999084189],
            [-83.75943, 37.998730999084422],
            [-83.881353, 38.136516999083341],
            [-83.974659, 38.158436999083172],
            [-83.977924, 38.19211799908291]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "043",
        COUNTYNS: "00516868",
        AFFGEOID: "0500000US21043",
        GEOID: "21043",
        NAME: "Carter",
        LSAD: "06",
        ALAND: 1060602204,
        AWATER: 6788531
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.341347, 38.319520999081924],
            [-83.230598, 38.339507999081782],
            [-83.237181, 38.426245999081132],
            [-83.166419, 38.503964999080566],
            [-83.051751, 38.465403999080849],
            [-82.970648, 38.393665999081378],
            [-82.817454, 38.373934999081527],
            [-82.771737, 38.362587999081612],
            [-82.794206, 38.243771999082519],
            [-82.8232, 38.208263999082781],
            [-82.924805, 38.175113999083038],
            [-83.053081, 38.189544999082926],
            [-83.177781, 38.266962999082345],
            [-83.244306, 38.192379999082902],
            [-83.30114, 38.209702999082765],
            [-83.341347, 38.319520999081924]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "035",
        COUNTYNS: "00516864",
        AFFGEOID: "0500000US21035",
        GEOID: "21035",
        NAME: "Calloway",
        LSAD: "06",
        ALAND: 997206000,
        AWATER: 66492945
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.485697, 36.75032799909517],
            [-88.110831, 36.747149999095193],
            [-88.080126, 36.706738999095585],
            [-88.070532, 36.678117999095846],
            [-88.055738, 36.630474999096293],
            [-88.033802, 36.551732999097041],
            [-88.050466, 36.500052999097541],
            [-88.053350800775888, 36.49999624977913],
            [-88.127378, 36.498539999097545],
            [-88.489076738713194, 36.501283718173227],
            [-88.485697, 36.75032799909517]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "013",
        COUNTYNS: "01247984",
        AFFGEOID: "0500000US45013",
        GEOID: "45013",
        NAME: "Beaufort",
        LSAD: "06",
        ALAND: 1492881338,
        AWATER: 899019054
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.006432, 32.306195999147242],
            [-80.934935, 32.300368999147324],
            [-80.841458, 32.385063999146134],
            [-80.81869, 32.463934999145032],
            [-80.836548, 32.608844999143024],
            [-80.869705, 32.66093499914232],
            [-80.826309, 32.704320999141721],
            [-80.67115, 32.671641999142174],
            [-80.659751, 32.612682999142976],
            [-80.551504, 32.565768999143621],
            [-80.51408, 32.513896999144343],
            [-80.507975, 32.505206999144455],
            [-80.472293124695099, 32.483348913724868],
            [-80.484617, 32.460975999145063],
            [-80.457502, 32.410263999145783],
            [-80.434303, 32.375192999146272],
            [-80.455192, 32.326457999146946],
            [-80.539429, 32.287023999147507],
            [-80.596394, 32.273548999147692],
            [-80.644794, 32.291496999147448],
            [-80.714601, 32.325655999146967],
            [-80.766038, 32.292607999147442],
            [-80.726967, 32.265705999147812],
            [-80.669166, 32.216782999148506],
            [-80.721463, 32.160426999149308],
            [-80.812503, 32.109745999150022],
            [-80.858735, 32.099580999150184],
            [-80.867427681891584, 32.078489999150484],
            [-81.016112, 32.24429399914812],
            [-81.006432, 32.306195999147242]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "053",
        COUNTYNS: "01581086",
        AFFGEOID: "0500000US55053",
        GEOID: "55053",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 2558240018,
        AWATER: 32985363
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.165619, 44.596986999056831],
            [-91.043815, 44.596639999056833],
            [-90.92235, 44.596292999056828],
            [-90.922889, 44.509839999056872],
            [-90.801525, 44.509680999056869],
            [-90.801918, 44.422441999056915],
            [-90.558746, 44.422209999056925],
            [-90.316055, 44.424501999056922],
            [-90.317818, 44.265949999057028],
            [-90.31268, 44.248749999057054],
            [-90.312522, 44.15519799905713],
            [-90.553421, 44.160214999057139],
            [-90.904579, 44.158297999057112],
            [-90.977279, 44.129607999057143],
            [-90.973107, 44.070881999057207],
            [-91.136542, 44.071019999057206],
            [-91.151932, 44.079664999057208],
            [-91.165548, 44.247444999057052],
            [-91.165619, 44.596986999056831]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "013",
        COUNTYNS: "01605072",
        AFFGEOID: "0500000US56013",
        GEOID: "56013",
        NAME: "Fremont",
        LSAD: "06",
        ALAND: 23784954724,
        AWATER: 213202754
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.053179, 44.008021999057277],
            [-109.990073, 43.949065999057339],
            [-109.858754, 43.957987999057337],
            [-109.816575, 43.880533999057427],
            [-109.843455, 43.819863999057503],
            [-109.7899, 43.803251999057515],
            [-109.665893, 43.845725999057464],
            [-109.641808, 43.922784999057363],
            [-109.542332, 43.961351999057314],
            [-109.442361, 43.930433999057357],
            [-109.312949, 43.813292999057502],
            [-109.171448, 43.69919799905766],
            [-109.171638, 43.619763999057774],
            [-108.934141, 43.619162999057785],
            [-108.814276, 43.605254999057799],
            [-108.814276, 43.575554999057857],
            [-108.694672, 43.575754999057857],
            [-108.695072, 43.532254999057933],
            [-108.576167, 43.532555999057926],
            [-108.576167, 43.503255999057977],
            [-108.456962, 43.503155999057974],
            [-108.456973, 43.474278999058008],
            [-108.337861, 43.459764999058038],
            [-108.156285, 43.470627999058024],
            [-107.595137, 43.472278999058027],
            [-107.595105, 43.501396999057974],
            [-107.534897, 43.50136199905797],
            [-107.517031, 43.472658999058019],
            [-107.502327, 43.128059999058678],
            [-107.501425, 42.781457999059477],
            [-107.543526, 42.781557999059466],
            [-107.542058, 42.441839999060385],
            [-107.522923, 42.434355999060415],
            [-107.522722, 42.261755999060917],
            [-107.639128, 42.261355999060932],
            [-108.756031, 42.262874999060919],
            [-109.043864, 42.263683999060916],
            [-109.043801, 42.433643999060401],
            [-109.072851, 42.693513999059697],
            [-109.134466, 42.694288999059701],
            [-109.132315, 42.737484999059589],
            [-109.323552, 42.863867999059273],
            [-109.397757, 42.939197999059097],
            [-109.502407, 43.010510999058937],
            [-109.552579, 43.012537999058914],
            [-109.560451, 43.08353799905877],
            [-109.651451, 43.167035999058591],
            [-109.692716, 43.265029999058392],
            [-109.694942, 43.366151999058211],
            [-109.754462, 43.365904999058216],
            [-109.754717, 43.463199999058034],
            [-110.05157, 43.464782999058023],
            [-110.053179, 44.008021999057277]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "137",
        COUNTYNS: "00662850",
        AFFGEOID: "0500000US27137",
        GEOID: "27137",
        NAME: "St. Louis",
        LSAD: "06",
        ALAND: 16181092598,
        AWATER: 1584976832
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.088454535286104, 48.626814217561012],
            [-92.954876, 48.631492999064534],
            [-92.894687, 48.594914999064386],
            [-92.728046, 48.539289999064145],
            [-92.634931, 48.542872999064166],
            [-92.631117, 48.508251999064022],
            [-92.657101, 48.466914999063853],
            [-92.575636, 48.440826999063738],
            [-92.51491, 48.448312999063774],
            [-92.456325, 48.414203999063638],
            [-92.469948, 48.35183599906339],
            [-92.416285, 48.295462999063176],
            [-92.369174, 48.220267999062884],
            [-92.314665, 48.240526999062972],
            [-92.295668, 48.278117999063106],
            [-92.295412, 48.323956999063284],
            [-92.26228, 48.354932999063401],
            [-92.162161, 48.363278999063439],
            [-92.055228, 48.359212999063416],
            [-92.000133, 48.321354999063274],
            [-91.980772, 48.247800999063003],
            [-91.89347, 48.237698999062957],
            [-91.797283579908694, 48.205775914516437],
            [-91.799114, 47.732177999061207],
            [-91.787928, 47.546816999060624],
            [-91.7939959120814, 46.942780646200944],
            [-91.806851, 46.933726999059019],
            [-91.906483, 46.891235999058921],
            [-92.013405, 46.833726999058797],
            [-92.062088, 46.804037999058728],
            [-92.01529, 46.706468999058529],
            [-92.050819, 46.710516999058541],
            [-92.100255, 46.734445999058586],
            [-92.143338, 46.731595999058591],
            [-92.183091, 46.695240999058498],
            [-92.205492, 46.664740999058445],
            [-92.292192, 46.663241999058442],
            [-92.301676, 46.76413399905865],
            [-93.061529, 46.766549999058654],
            [-93.055943, 47.026414999059234],
            [-93.0695, 47.393914999060179],
            [-93.061253, 47.720186999061156],
            [-93.081158, 47.891869999061726],
            [-93.096488, 48.067825999062329],
            [-93.088454535286104, 48.626814217561012]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "083",
        COUNTYNS: "01715338",
        AFFGEOID: "0500000US30083",
        GEOID: "30083",
        NAME: "Richland",
        LSAD: "06",
        ALAND: 5398268971,
        AWATER: 49652120
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.234771, 47.790501999061377],
            [-105.195122, 47.86808099906164],
            [-105.194783, 48.066616999062333],
            [-105.177722, 48.103099999062465],
            [-105.057163, 48.077670999062363],
            [-104.947319, 48.094201999062427],
            [-104.891321, 48.150689999062635],
            [-104.822581, 48.118944999062514],
            [-104.681633, 48.103322999062456],
            [-104.544517, 48.126552999062547],
            [-104.245936, 48.031004999062205],
            [-104.14247, 48.055004999062291],
            [-104.098921, 48.000018999062085],
            [-104.044093272821016, 47.996107173249186],
            [-104.044093215348013, 47.996098354626774],
            [-104.043933, 47.971514999061988],
            [-104.042384, 47.803255999061435],
            [-104.043912, 47.603228999060804],
            [-104.044976716405003, 47.3970692003426],
            [-104.131863, 47.353900999060073],
            [-104.420347, 47.354434999060068],
            [-104.513292, 47.354718999060076],
            [-104.513049, 47.529441999060573],
            [-104.594046, 47.529334999060566],
            [-104.593664, 47.616293999060836],
            [-104.978553, 47.616271999060835],
            [-104.97809, 47.703292999061098],
            [-105.235016, 47.703580999061117],
            [-105.234771, 47.790501999061377]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "003",
        COUNTYNS: "00345784",
        AFFGEOID: "0500000US13003",
        GEOID: "13003",
        NAME: "Atkinson",
        LSAD: "06",
        ALAND: 878787518,
        AWATER: 13550957
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.140483, 31.420394999160131],
            [-82.844093, 31.403327999160375],
            [-82.817544, 31.364485999160973],
            [-82.628975, 31.363929999160984],
            [-82.629622, 31.274725999162321],
            [-82.699206, 31.278103999162269],
            [-82.671669, 31.183738999163698],
            [-82.971247, 31.183987999163698],
            [-83.046885, 31.183679999163701],
            [-83.033468, 31.275040999162307],
            [-83.140483, 31.420394999160131]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "50",
        COUNTYFP: "003",
        COUNTYNS: "01461758",
        AFFGEOID: "0500000US50003",
        GEOID: "50003",
        NAME: "Bennington",
        LSAD: "06",
        ALAND: 1748244447,
        AWATER: 6928011
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.278673, 42.833409999059349],
            [-73.273832807021009, 42.943631746585581],
            [-73.270008731163585, 43.030714291746385],
            [-73.26978, 43.035922999058862],
            [-73.255355838057596, 43.314712397658312],
            [-72.976152, 43.291640999058352],
            [-72.867902, 43.300741999058332],
            [-72.8199, 43.299209999058334],
            [-72.819368, 43.255497999058413],
            [-72.868737, 43.113169999058705],
            [-72.996941, 43.119096999058691],
            [-73.002789, 43.0230069990589],
            [-73.008063, 42.930086999059114],
            [-72.921376, 42.92679199905912],
            [-72.930262612517993, 42.739068339517175],
            [-73.018648613350393, 42.740883005459374],
            [-73.023017893290088, 42.740972711786476],
            [-73.142495120514397, 42.743425716256461],
            [-73.264957, 42.745939999059559],
            [-73.290944, 42.801919999059422],
            [-73.278673, 42.833409999059349]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "013",
        COUNTYNS: "00863310",
        AFFGEOID: "0500000US32013",
        GEOID: "32013",
        NAME: "Humboldt",
        LSAD: "06",
        ALAND: 24969533279,
        AWATER: 44196979
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.329722, 40.973849999065848],
            [-119.305713, 41.234541999064696],
            [-119.323955, 41.414518999063944],
            [-119.324184120438986, 41.993875626855093],
            [-119.20828, 41.993176999061788],
            [-119.001022, 41.993792999061789],
            [-118.775869, 41.992691999061798],
            [-118.501002, 41.995445999061779],
            [-118.197369215928006, 41.996994080225285],
            [-118.197189, 41.996994999061776],
            [-117.873467, 41.998334999061775],
            [-117.623731, 41.998466999061776],
            [-117.403613, 41.999289999061766],
            [-117.197798, 42.000379999061771],
            [-117.026197, 41.999889999061779],
            [-117.018203216741, 41.999839849430856],
            [-117.018419, 41.00025399906572],
            [-117.018664, 40.643034999067417],
            [-117.246788, 40.642444999067408],
            [-117.300597, 40.526135999068003],
            [-117.301168, 40.682347999067218],
            [-117.644399, 40.684342999067205],
            [-117.64422, 40.85782399906639],
            [-118.43819, 40.855695999066391],
            [-118.786738, 40.855928999066386],
            [-118.786705, 40.960849999065907],
            [-119.309598, 40.960377999065905],
            [-119.329722, 40.973849999065848]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "029",
        COUNTYNS: "00161540",
        AFFGEOID: "0500000US01029",
        GEOID: "01029",
        NAME: "Cleburne",
        LSAD: "06",
        ALAND: 1450663938,
        AWATER: 2354898
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.85189, 33.498741999131191],
            [-85.805241, 33.535188999130725],
            [-85.796054, 33.556219999130462],
            [-85.65581, 33.627165999129559],
            [-85.638049, 33.773338999127716],
            [-85.530094, 33.941422999125621],
            [-85.495289, 33.956909999125429],
            [-85.398871244842013, 33.964129285904846],
            [-85.398871, 33.964127999125331],
            [-85.38667057377279, 33.901701493129416],
            [-85.360532, 33.767956999127776],
            [-85.338116299905906, 33.653114390730615],
            [-85.314048, 33.529804999130796],
            [-85.304943833224897, 33.482756335976902],
            [-85.347744, 33.501377999131151],
            [-85.643482, 33.495884999131242],
            [-85.765427, 33.498592999131198],
            [-85.782735, 33.469348999131576],
            [-85.887782, 33.469426999131571],
            [-85.85189, 33.498741999131191]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "239",
        COUNTYNS: "00350547",
        AFFGEOID: "0500000US13239",
        GEOID: "13239",
        NAME: "Quitman",
        LSAD: "06",
        ALAND: 391703077,
        AWATER: 24158294
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.141831, 31.839260999153929],
            [-85.114031, 31.893359999153141],
            [-85.067829, 31.967357999152071],
            [-85.063590542984684, 31.991856999151729],
            [-84.9537, 31.974780999151982],
            [-84.974067, 31.95020899915232],
            [-84.907006, 31.924464999152683],
            [-84.910338, 31.776840999154839],
            [-84.95864, 31.777853999154832],
            [-85.073941, 31.771440999154912],
            [-85.129159315583195, 31.780278267231392],
            [-85.141831, 31.839260999153929]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "195",
        COUNTYNS: "02516404",
        AFFGEOID: "0500000US02195",
        GEOID: "02195",
        NAME: "Petersburg",
        LSAD: "04",
        ALAND: 7513010884,
        AWATER: 2385501229
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-133.49736, 56.746656999135539],
              [-133.515689, 56.795982999136179],
              [-133.440042, 56.882354999137327],
              [-133.274012, 56.921814999137851],
              [-133.317871026437018, 57.002674736838635],
              [-133.104611, 57.005700999138966],
              [-132.98137, 56.927379999137926],
              [-132.903211, 56.803609999136278],
              [-132.796602, 56.776931999135918],
              [-132.743207, 56.713719999135087],
              [-132.619258, 56.660777999134396],
              [-132.611326, 56.599912999133622],
              [-132.671154, 56.54307099913288],
              [-132.806838, 56.505491999132381],
              [-132.933042, 56.52219999913261],
              [-133.041726, 56.518355999132552],
              [-133.183493, 56.454240999131727],
              [-133.300571, 56.46234299913182],
              [-133.415378891774992, 56.456444913415048],
              [-133.441307, 56.637625999134102],
              [-133.579378, 56.757840999135674],
              [-133.49736, 56.746656999135539]
            ]
          ],
          [
            [
              [-133.696701, 57.792723999149864],
              [-133.549358, 57.775224999149614],
              [-133.493048, 57.824725999150331],
              [-133.353451, 57.772269999149586],
              [-133.279877, 57.79476799914989],
              [-133.188197, 57.741007999149126],
              [-132.97733, 57.721497999148852],
              [-132.949844, 57.745424999149193],
              [-132.871898, 57.712795999148724],
              [-132.840996, 57.663105999148023],
              [-132.88785, 57.613738999147337],
              [-132.827503, 57.580659999146881],
              [-132.655278650447002, 57.601744609630657],
              [-132.559178, 57.503926999145797],
              [-132.367984, 57.348684999143636],
              [-132.252187, 57.215654999141819],
              [-132.371312, 57.095228999140176],
              [-132.051044, 57.051154999139577],
              [-132.118917923363, 56.891229389646341],
              [-132.160186, 56.903304999137589],
              [-132.215746, 56.89160599913744],
              [-132.348468, 56.771286999135853],
              [-132.439599, 56.770408999135839],
              [-132.545822263320019, 56.713621235014692],
              [-132.556758, 56.757241999135665],
              [-132.792089, 56.856151999136976],
              [-132.892388, 56.993015999138798],
              [-132.93752, 57.048320999139541],
              [-133.161448, 57.086263999140066],
              [-133.247414, 57.136801999140744],
              [-133.322359, 57.112726999140421],
              [-133.466932, 57.159355999141049],
              [-133.544817, 57.242569999142191],
              [-133.489738, 57.305191999143048],
              [-133.472039, 57.368650999143924],
              [-133.514964, 57.473344999145368],
              [-133.519598, 57.530704999146167],
              [-133.62076, 57.578918999146843],
              [-133.676449, 57.625191999147496],
              [-133.65453, 57.713688999148737],
              [-133.703025360282965, 57.792036260745157],
              [-133.696701, 57.792723999149864]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "137",
        COUNTYNS: "01648580",
        AFFGEOID: "0500000US47137",
        GEOID: "47137",
        NAME: "Pickett",
        LSAD: "06",
        ALAND: 422113135,
        AWATER: 29822859
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.267057, 36.573237999096833],
            [-85.276287947499398, 36.626157539899737],
            [-85.096128, 36.622482999096356],
            [-84.974868435837408, 36.61458335841364],
            [-84.943948, 36.612568999096467],
            [-84.785399978550302, 36.603375419032155],
            [-84.731839, 36.52467399909731],
            [-84.943144, 36.581269999096754],
            [-85.043924, 36.558790999096978],
            [-85.120445, 36.407163999098429],
            [-85.261159, 36.534996999097203],
            [-85.284878, 36.531292999097232],
            [-85.267057, 36.573237999096833]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "161",
        COUNTYNS: "01687999",
        AFFGEOID: "0500000US13161",
        GEOID: "13161",
        NAME: "Jeff Davis",
        LSAD: "06",
        ALAND: 856690612,
        AWATER: 11986249
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.836366, 31.81615399915426],
            [-82.825855, 31.81338999915431],
            [-82.645659, 31.918882999152764],
            [-82.594757, 31.931584999152584],
            [-82.543655, 31.958913999152191],
            [-82.480224, 31.954960999152249],
            [-82.483131, 31.968960999152067],
            [-82.431531, 31.966181999152088],
            [-82.431362, 31.837992999153954],
            [-82.520251, 31.838387999153937],
            [-82.550714, 31.736333999155441],
            [-82.52142, 31.710795999155806],
            [-82.521393, 31.672511999156367],
            [-82.627342, 31.672671999156378],
            [-82.836633, 31.67139799915639],
            [-82.836366, 31.81615399915426]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "049",
        COUNTYNS: "01494109",
        AFFGEOID: "0500000US51049",
        GEOID: "51049",
        NAME: "Cumberland",
        LSAD: "06",
        ALAND: 770511792,
        AWATER: 5931926
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.465089, 37.339676999089875],
            [-78.249021, 37.63554599908737],
            [-78.239748, 37.690494999086923],
            [-78.18099, 37.744958999086471],
            [-78.159326, 37.748527999086441],
            [-78.099509, 37.715349999086705],
            [-78.072174, 37.657384999087192],
            [-78.089466, 37.63941499908735],
            [-78.132048, 37.454685999088888],
            [-78.185002, 37.444551999088979],
            [-78.235039, 37.368101999089646],
            [-78.35275, 37.301605999090214],
            [-78.465089, 37.339676999089875]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "109",
        COUNTYNS: "01639774",
        AFFGEOID: "0500000US47109",
        GEOID: "47109",
        NAME: "McNairy",
        LSAD: "06",
        ALAND: 1457753417,
        AWATER: 1994987
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.781768, 35.247586999110396],
            [-88.719761, 35.24754999911039],
            [-88.640934, 35.361016999109154],
            [-88.571763, 35.382083999108936],
            [-88.362572, 35.380993999108945],
            [-88.374926, 35.304030999109784],
            [-88.380492511522078, 34.995792407335287],
            [-88.469877, 34.99603299911319],
            [-88.786612, 34.995251999113194],
            [-88.781768, 35.247586999110396]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "021",
        COUNTYNS: "00835832",
        AFFGEOID: "0500000US31021",
        GEOID: "31021",
        NAME: "Burt",
        LSAD: "06",
        ALAND: 1273201346,
        AWATER: 14186271
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.554866, 42.015874999061722],
            [-96.309645, 42.015186999061719],
            [-96.272877, 42.047237999061608],
            [-96.223611, 42.022651999061686],
            [-96.132537, 41.974624999061852],
            [-96.159098, 41.910056999062064],
            [-96.126821253465394, 41.866095246763841],
            [-96.107911, 41.840338999062318],
            [-96.064537, 41.793001999062483],
            [-96.0876, 41.72217999906276],
            [-96.107937871746472, 41.676508988825034],
            [-96.134276, 41.682729999062907],
            [-96.444785, 41.683632999062901],
            [-96.444217, 41.741774999062685],
            [-96.555172, 41.742017999062682],
            [-96.554866, 42.015874999061722]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "139",
        COUNTYNS: "01026330",
        AFFGEOID: "0500000US37139",
        GEOID: "37139",
        NAME: "Pasquotank",
        LSAD: "06",
        ALAND: 587616451,
        AWATER: 161745166
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.491336, 36.510676999097441],
            [-76.437286, 36.467845999097847],
            [-76.231329, 36.372079999098766],
            [-76.216211, 36.298301999099493],
            [-76.12458, 36.263962999099832],
            [-76.02939, 36.158818999100873],
            [-76.022607, 36.09672499910149],
            [-76.058217, 36.08059299910164],
            [-76.157953, 36.059516999101874],
            [-76.177396, 36.109190999101372],
            [-76.290671, 36.223496999100242],
            [-76.37488, 36.258653999099884],
            [-76.453711, 36.378091999098707],
            [-76.491405, 36.468647999097847],
            [-76.491336, 36.510676999097441]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "021",
        COUNTYNS: "01383796",
        AFFGEOID: "0500000US48021",
        GEOID: "48021",
        NAME: "Bastrop",
        LSAD: "06",
        ALAND: 2300355868,
        AWATER: 19105699
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.49135, 30.211929999178992],
            [-97.491254, 30.212109999178992],
            [-97.400089, 30.367905999176468],
            [-97.369539, 30.419562999175643],
            [-97.334463, 30.40284299917591],
            [-97.251707, 30.362847999176555],
            [-97.199578, 30.337832999176968],
            [-97.158811, 30.31069499917739],
            [-97.081834, 30.259356999178223],
            [-97.024461, 30.051434999181605],
            [-97.194235, 29.896775999184147],
            [-97.315823, 29.786540999185977],
            [-97.64937, 30.067943999181338],
            [-97.49135, 30.211929999178992]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "103",
        COUNTYNS: "00516898",
        AFFGEOID: "0500000US21103",
        GEOID: "21103",
        NAME: "Henry",
        LSAD: "06",
        ALAND: 741450757,
        AWATER: 12552369
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.314006, 38.492591999080645],
            [-85.279627, 38.496267999080622],
            [-85.16827, 38.585447999079967],
            [-85.074583, 38.596837999079881],
            [-84.977166, 38.512988999080491],
            [-84.963265, 38.437357999081058],
            [-84.91223, 38.459843999080888],
            [-84.870482, 38.356750999081648],
            [-84.906256, 38.374835999081519],
            [-84.997667, 38.335585999081815],
            [-85.283082, 38.35805399908164],
            [-85.34608, 38.459539999080896],
            [-85.314006, 38.492591999080645]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "005",
        COUNTYNS: "00344784",
        AFFGEOID: "0500000US13005",
        GEOID: "13005",
        NAME: "Bacon",
        LSAD: "06",
        ALAND: 735650672,
        AWATER: 4625861
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.627342, 31.672671999156378],
            [-82.521393, 31.672511999156367],
            [-82.52142, 31.710795999155806],
            [-82.495384, 31.709630999155831],
            [-82.226585, 31.530698999158471],
            [-82.300422, 31.493174999159031],
            [-82.344843, 31.430129999159973],
            [-82.417246, 31.417118999160174],
            [-82.48146, 31.417533999160167],
            [-82.494718, 31.468689999159402],
            [-82.597509, 31.469292999159389],
            [-82.628273, 31.558328999158064],
            [-82.627342, 31.672671999156378]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "149",
        COUNTYNS: "00516927",
        AFFGEOID: "0500000US21149",
        GEOID: "21149",
        NAME: "McLean",
        LSAD: "06",
        ALAND: 653906323,
        AWATER: 9734358
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.495331, 37.647546999087268],
            [-87.472811, 37.664868999087119],
            [-87.408547, 37.683562999086966],
            [-87.138821, 37.575627999087878],
            [-87.03826, 37.560802999087983],
            [-87.135629, 37.485152999088633],
            [-87.099803, 37.416041999089217],
            [-87.210958, 37.380929999089531],
            [-87.295965, 37.391818999089431],
            [-87.328543, 37.398461999089378],
            [-87.375145, 37.569987999087907],
            [-87.482702, 37.600913999087652],
            [-87.495331, 37.647546999087268]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "269",
        COUNTYNS: "01383920",
        AFFGEOID: "0500000US48269",
        GEOID: "48269",
        NAME: "King",
        LSAD: "06",
        ALAND: 2359148815,
        AWATER: 6398045
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.518691, 33.835649999126929],
            [-100.04848, 33.835973999126935],
            [-99.996434, 33.835966999126917],
            [-99.99098, 33.3974039991325],
            [-100.517449, 33.397865999132492],
            [-100.518691, 33.835649999126929]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "055",
        COUNTYNS: "01622970",
        AFFGEOID: "0500000US26055",
        GEOID: "26055",
        NAME: "Grand Traverse",
        LSAD: "06",
        ALAND: 1202639650,
        AWATER: 354756663
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.815119, 44.774533999056779],
            [-85.618033, 44.774556999056784],
            [-85.555142702708793, 45.027034543193665],
            [-85.520034, 44.973995999056768],
            [-85.475204, 44.99105299905677],
            [-85.431415475008308, 45.016649451774462],
            [-85.473841, 44.85944899905676],
            [-85.381983, 44.859567999056765],
            [-85.332836, 44.812371999056765],
            [-85.334, 44.512302999056871],
            [-85.817802, 44.512968999056874],
            [-85.815119, 44.774533999056779]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "175",
        COUNTYNS: "00516934",
        AFFGEOID: "0500000US21175",
        GEOID: "21175",
        NAME: "Morgan",
        LSAD: "06",
        ALAND: 987114420,
        AWATER: 6889722
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.504389, 37.870241999085451],
            [-83.444343, 37.942467999084876],
            [-83.433942, 38.033973999084147],
            [-83.430638, 38.046958999084048],
            [-83.263289, 38.115385999083507],
            [-83.267544, 38.058949999083943],
            [-83.194445, 38.010648999084331],
            [-83.022115, 38.007046999084359],
            [-82.989014, 37.963967999084701],
            [-82.961423, 37.893826999085256],
            [-83.004649, 37.859246999085542],
            [-83.129258, 37.894437999085248],
            [-83.113277, 37.864120999085493],
            [-83.269662, 37.766011999086295],
            [-83.262172, 37.712656999086732],
            [-83.386437, 37.813674999085912],
            [-83.484258, 37.843909999085668],
            [-83.49508, 37.861905999085508],
            [-83.504389, 37.870241999085451]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "051",
        COUNTYNS: "01448039",
        AFFGEOID: "0500000US49051",
        GEOID: "49051",
        NAME: "Wasatch",
        LSAD: "06",
        ALAND: 3048467979,
        AWATER: 79114523
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.575028, 40.486525999068199],
            [-111.593942, 40.577065999067742],
            [-111.553266, 40.609312999067576],
            [-111.489794, 40.599331999067637],
            [-111.39424, 40.690605999067181],
            [-111.368623, 40.628943999067481],
            [-111.15182, 40.548450999067889],
            [-111.12087, 40.590456999067683],
            [-110.976086, 40.592953999067667],
            [-110.901974, 40.678161999067243],
            [-110.891655, 39.899653999071404],
            [-111.082455, 39.900154999071397],
            [-111.083061, 39.943197999071138],
            [-111.205772, 40.047367999070566],
            [-111.249339, 40.05521699907051],
            [-111.225507, 40.1673239990699],
            [-111.278762, 40.258792999069385],
            [-111.31483, 40.287565999069244],
            [-111.426482, 40.286905999069241],
            [-111.480337, 40.362168999068849],
            [-111.555235, 40.362695999068848],
            [-111.617505, 40.438017999068457],
            [-111.575028, 40.486525999068199]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "077",
        COUNTYNS: "01209182",
        AFFGEOID: "0500000US42077",
        GEOID: "42077",
        NAME: "Lehigh",
        LSAD: "06",
        ALAND: 894010291,
        AWATER: 7898528
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.807789, 40.707491999067102],
            [-75.757807, 40.735413999066964],
            [-75.694749, 40.770074999066793],
            [-75.608985, 40.787386999066712],
            [-75.530236, 40.736398999066964],
            [-75.481469, 40.655743999067347],
            [-75.430404, 40.674151999067263],
            [-75.404412, 40.58115399906773],
            [-75.333514, 40.53705699906795],
            [-75.409733, 40.487983999068192],
            [-75.484057, 40.418450999068554],
            [-75.490104, 40.422191999068531],
            [-75.529694, 40.446994999068409],
            [-75.571926, 40.473347999068267],
            [-75.891473, 40.677269999067249],
            [-75.807789, 40.707491999067102]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "031",
        COUNTYNS: "01383801",
        AFFGEOID: "0500000US48031",
        GEOID: "48031",
        NAME: "Blanco",
        LSAD: "06",
        ALAND: 1836948497,
        AWATER: 10818164
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.59167, 30.499874999174352],
            [-98.411462, 30.502094999174322],
            [-98.351041, 30.486095999174584],
            [-98.125556, 30.426185999175527],
            [-98.172977, 30.356311999176661],
            [-98.2976, 30.037993999181822],
            [-98.414018, 29.937556999183478],
            [-98.587897, 30.138953999180181],
            [-98.59167, 30.499874999174352]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "103",
        COUNTYNS: "01581112",
        AFFGEOID: "0500000US55103",
        GEOID: "55103",
        NAME: "Richland",
        LSAD: "06",
        ALAND: 1518120269,
        AWATER: 8153437
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.671652, 43.552855999057883],
            [-90.311069, 43.553990999057874],
            [-90.191964, 43.554995999057873],
            [-90.191938, 43.380082999058182],
            [-90.193814, 43.164463999058597],
            [-90.260077, 43.197688999058528],
            [-90.31113, 43.206676999058516],
            [-90.350513, 43.209785999058511],
            [-90.429825, 43.200939999058527],
            [-90.545408, 43.211454999058503],
            [-90.666786, 43.171776999058579],
            [-90.668561, 43.4229939990581],
            [-90.671652, 43.552855999057883]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "103",
        COUNTYNS: "01717836",
        AFFGEOID: "0500000US54103",
        GEOID: "54103",
        NAME: "Wetzel",
        LSAD: "06",
        ALAND: 927380674,
        AWATER: 8458366
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.865575, 39.662750999072799],
            [-80.829764, 39.711838999072498],
            [-80.835521693007493, 39.719251801272549],
            [-80.519342, 39.721402999072446],
            [-80.421388, 39.721188999072446],
            [-80.407408, 39.717751999072469],
            [-80.39581, 39.637346999072946],
            [-80.433285, 39.639129999072949],
            [-80.498163, 39.563326999073404],
            [-80.494085, 39.469598999073987],
            [-80.544521, 39.429119999074238],
            [-80.583091, 39.451414999074096],
            [-80.618629, 39.450374999074114],
            [-80.73407, 39.491552999073853],
            [-80.846393, 39.601090999073179],
            [-80.943782, 39.606925999073141],
            [-80.865575, 39.662750999072799]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "065",
        COUNTYNS: "01581092",
        AFFGEOID: "0500000US55065",
        GEOID: "55065",
        NAME: "Lafayette",
        LSAD: "06",
        ALAND: 1640985307,
        AWATER: 2539100
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.426902, 42.812861999059386],
            [-89.83841, 42.813798999059387],
            [-89.83759488632559, 42.504910351851699],
            [-89.926484, 42.505786999060206],
            [-89.927006876764011, 42.50579048483479],
            [-90.22319, 42.507764999060193],
            [-90.426376960736093, 42.5071777342839],
            [-90.426902, 42.812861999059386]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "067",
        COUNTYNS: "01529226",
        AFFGEOID: "0500000US53067",
        GEOID: "53067",
        NAME: "Thurston",
        LSAD: "06",
        ALAND: 1871173425,
        AWATER: 133711607
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.201891, 47.085058999059385],
            [-123.102984, 47.08526899905938],
            [-123.074957, 47.090843999059395],
            [-123.073654, 47.106241999059421],
            [-123.060583, 47.114024999059446],
            [-123.034109, 47.115040999059453],
            [-123.015389, 47.12379199905947],
            [-123.010456, 47.143325999059513],
            [-123.002435, 47.151301999059548],
            [-122.969123, 47.161524999059566],
            [-122.952819, 47.170102999059587],
            [-122.947046, 47.185640999059629],
            [-122.936283, 47.18945699905963],
            [-122.902417, 47.157380999059562],
            [-122.820576, 47.194219999059655],
            [-122.759505, 47.141314999059517],
            [-122.692178, 47.0992059990594],
            [-122.659863, 47.003224999059178],
            [-122.560436, 46.933456999059011],
            [-122.494116, 46.905217999058948],
            [-122.491079, 46.86796499905887],
            [-122.303576, 46.828116999058793],
            [-122.265906, 46.765471999058654],
            [-122.203115, 46.763060999058645],
            [-123.160909, 46.764329999058639],
            [-123.160588, 46.793382999058714],
            [-123.1605, 46.827160999058783],
            [-123.158364, 46.995870999059157],
            [-123.201891, 47.085058999059385]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "343",
        COUNTYNS: "01383957",
        AFFGEOID: "0500000US48343",
        GEOID: "48343",
        NAME: "Morris",
        LSAD: "06",
        ALAND: 652648672,
        AWATER: 17382822
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.808783, 33.363635999132939],
            [-94.781693, 33.318904999133522],
            [-94.746925, 33.328937999133387],
            [-94.692262, 33.312404999133612],
            [-94.65226, 33.268860999134169],
            [-94.653994, 32.87950599913934],
            [-94.705878, 32.879176999139347],
            [-94.719942, 32.904501999139008],
            [-94.768855, 32.925833999138717],
            [-94.819828, 32.982885999137956],
            [-94.808783, 33.363635999132939]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "001",
        COUNTYNS: "01531601",
        AFFGEOID: "0500000US53001",
        GEOID: "53001",
        NAME: "Adams",
        LSAD: "06",
        ALAND: 4985663373,
        AWATER: 12499935
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.368662, 46.912561999058973],
            [-118.982652, 46.911343999058978],
            [-118.979777, 47.26170899905982],
            [-117.96093, 47.260567999059823],
            [-117.959335, 46.914810999058979],
            [-118.015567, 46.784872999058699],
            [-118.210243, 46.738832999058602],
            [-118.77232, 46.735389999058583],
            [-119.369429, 46.737694999058597],
            [-119.368662, 46.912561999058973]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "171",
        COUNTYNS: "01648582",
        AFFGEOID: "0500000US47171",
        GEOID: "47171",
        NAME: "Unicoi",
        LSAD: "06",
        ALAND: 481902939,
        AWATER: 825725
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.595525, 36.026011999102202],
            [-82.605703610793597, 36.037198862663992],
            [-82.595065, 36.096204999101488],
            [-82.527605, 36.148950999100961],
            [-82.420661, 36.163206999100829],
            [-82.34046, 36.252806999099938],
            [-82.284691, 36.251685999099955],
            [-82.220253010286598, 36.153820038944019],
            [-82.26569, 36.127613999101186],
            [-82.297655, 36.133509999101122],
            [-82.346857, 36.115208999101313],
            [-82.409458, 36.083408999101621],
            [-82.416937594673186, 36.072970009810227],
            [-82.464558, 36.006507999102396],
            [-82.508007659722495, 35.982013204544138],
            [-82.557874, 35.953900999102935],
            [-82.610885, 35.974441999102716],
            [-82.595525, 36.026011999102202]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "059",
        COUNTYNS: "01639747",
        AFFGEOID: "0500000US47059",
        GEOID: "47059",
        NAME: "Greene",
        LSAD: "06",
        ALAND: 1611409749,
        AWATER: 5099752
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.166085, 36.180893999100654],
            [-83.14219, 36.187389999100589],
            [-83.082312, 36.242295999100037],
            [-82.937183, 36.33479699909914],
            [-82.702566, 36.410411999098393],
            [-82.631794, 36.418768999098305],
            [-82.647769, 36.14640399910099],
            [-82.595065, 36.096204999101488],
            [-82.605703610793597, 36.037198862663992],
            [-82.628365, 36.062104999101834],
            [-82.725065, 36.018203999102269],
            [-82.779397, 35.992510999102535],
            [-82.787465, 35.952162999102946],
            [-82.81613, 35.923985999103238],
            [-82.860724, 35.947429999103001],
            [-82.893751418431393, 35.933857268812034],
            [-83.102761, 36.120908999101239],
            [-83.172485, 36.157094999100885],
            [-83.166085, 36.180893999100654]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "035",
        COUNTYNS: "00974116",
        AFFGEOID: "0500000US36035",
        GEOID: "36035",
        NAME: "Fulton",
        LSAD: "06",
        ALAND: 1283234678,
        AWATER: 96916405
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.763614, 43.057961999058818],
            [-74.695985, 43.174176999058581],
            [-74.712615, 43.286142999058349],
            [-74.534657, 43.228114999058462],
            [-74.326378, 43.241634999058441],
            [-74.220902, 43.221402999058476],
            [-74.140147, 43.253978999058418],
            [-74.097467, 42.982933999059],
            [-74.488844, 42.985117999058978],
            [-74.542367, 42.985529999058976],
            [-74.759895, 43.047422999058831],
            [-74.763614, 43.057961999058818]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "021",
        COUNTYNS: "00484980",
        AFFGEOID: "0500000US20021",
        GEOID: "20021",
        NAME: "Cherokee",
        LSAD: "06",
        ALAND: 1521791027,
        AWATER: 8951473
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.075166, 37.339936999089879],
            [-94.617753891606583, 37.338417999089884],
            [-94.618351, 37.160210999091447],
            [-94.618102891102978, 37.056796389965278],
            [-94.617964, 36.9989049990929],
            [-94.71277, 36.998793999092896],
            [-94.995293, 36.999528999092888],
            [-95.007620004472088, 36.999522558218693],
            [-95.073503966630298, 36.999488133771983],
            [-95.075166, 37.339936999089879]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "123",
        COUNTYNS: "00295728",
        AFFGEOID: "0500000US12123",
        GEOID: "12123",
        NAME: "Taylor",
        LSAD: "06",
        ALAND: 2702180626,
        AWATER: 489194665
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.992310340477999, 30.089268736909684],
            [-83.915827, 30.245151999178454],
            [-83.856987, 30.273317999178001],
            [-83.820235, 30.303539999177509],
            [-83.468768, 30.3042119991775],
            [-83.469267, 30.260350999178215],
            [-83.36726, 30.260439999178203],
            [-83.37089, 29.887933999184291],
            [-83.35286, 29.822221999185388],
            [-83.318859, 29.822622999185388],
            [-83.366398, 29.665700999187994],
            [-83.414126513158493, 29.666065223670692],
            [-83.414701, 29.670535999187923],
            [-83.483567, 29.698541999187452],
            [-83.537645, 29.72305999918704],
            [-83.583045, 29.787306999185972],
            [-83.625026, 29.856891999184811],
            [-83.679219, 29.918512999183786],
            [-83.788729, 29.976981999182826],
            [-83.93151, 30.039067999181814],
            [-83.992310340477999, 30.089268736909684]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "039",
        COUNTYNS: "00306918",
        AFFGEOID: "0500000US12039",
        GEOID: "12039",
        NAME: "Gadsden",
        LSAD: "06",
        ALAND: 1337193230,
        AWATER: 31687293
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.932519, 30.606392999172662],
            [-84.860943, 30.693245999171296],
            [-84.864693, 30.711541999171015],
            [-84.863463844382494, 30.711497013825415],
            [-84.812997, 30.709649999171038],
            [-84.474519, 30.692782999171303],
            [-84.380754183552497, 30.688827196217272],
            [-84.285514509039487, 30.684809171119426],
            [-84.378384, 30.573466999173178],
            [-84.405402, 30.474011999174763],
            [-84.519796, 30.451498999175129],
            [-84.646408, 30.38828099917615],
            [-84.781111, 30.459793999175002],
            [-84.781813, 30.518692999174053],
            [-84.882616, 30.533093999173829],
            [-84.883316, 30.605588999172678],
            [-84.932519, 30.606392999172662]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "113",
        COUNTYNS: "00758511",
        AFFGEOID: "0500000US29113",
        GEOID: "29113",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 1622786223,
        AWATER: 36005143
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.260232, 39.139844999076118],
            [-91.18546, 39.139049999076114],
            [-91.182942, 39.22723299907554],
            [-90.723283623412698, 39.224102969133149],
            [-90.707902, 39.150859999076047],
            [-90.681086, 39.100589999076369],
            [-90.713629, 39.053976999076689],
            [-90.676397, 38.984095999077162],
            [-90.661582906876788, 38.934703306830691],
            [-90.707557, 38.902651999077726],
            [-90.817827, 38.875965999077906],
            [-90.958536, 38.870864999077931],
            [-91.111251, 38.872708999077936],
            [-91.110187, 38.931900999077527],
            [-91.189348, 38.932150999077514],
            [-91.18839, 38.992140999077108],
            [-91.264287, 38.992761999077103],
            [-91.260232, 39.139844999076118]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "083",
        COUNTYNS: "01648578",
        AFFGEOID: "0500000US47083",
        GEOID: "47083",
        NAME: "Houston",
        LSAD: "06",
        ALAND: 518749021,
        AWATER: 17312576
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.98074, 36.353306999098955],
            [-87.821816, 36.328723999099196],
            [-87.715494, 36.368239999098812],
            [-87.592358, 36.367662999098819],
            [-87.586957, 36.341779999099067],
            [-87.513533, 36.334712999099139],
            [-87.523627, 36.248889999099973],
            [-87.566798, 36.177856999100662],
            [-87.58581, 36.197333999100493],
            [-87.798508, 36.24475399910002],
            [-87.949603, 36.243159999100037],
            [-87.944295, 36.264176999099831],
            [-87.98074, 36.353306999098955]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "043",
        COUNTYNS: "01248002",
        AFFGEOID: "0500000US45043",
        GEOID: "45043",
        NAME: "Georgetown",
        LSAD: "06",
        ALAND: 2107183535,
        AWATER: 572535186
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.677385, 33.336412999133294],
            [-79.53808, 33.496895999131219],
            [-79.435733, 33.572701999130246],
            [-79.409332, 33.688889999128776],
            [-79.317041, 33.779877999127628],
            [-79.246384, 33.695346999128688],
            [-79.190264, 33.70578699912857],
            [-79.137683, 33.664129999129095],
            [-79.100653, 33.57180699913026],
            [-78.995631694383505, 33.572074597319563],
            [-79.028516, 33.533364999130747],
            [-79.084588, 33.483668999131389],
            [-79.135441, 33.403866999132418],
            [-79.162332, 33.327245999133403],
            [-79.180563, 33.237954999134573],
            [-79.172394, 33.206576999134988],
            [-79.215453, 33.15556899913566],
            [-79.274494584642795, 33.120056266856523],
            [-79.269396, 33.136882999135906],
            [-79.350816, 33.153527999135697],
            [-79.446699, 33.213457999134889],
            [-79.458734, 33.235218999134609],
            [-79.677014, 33.304943999133698],
            [-79.677385, 33.336412999133294]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "039",
        COUNTYNS: "01529219",
        AFFGEOID: "0500000US53039",
        GEOID: "53039",
        NAME: "Klickitat",
        LSAD: "06",
        ALAND: 4847516021,
        AWATER: 84930620
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.612232, 46.044092999057447],
            [-121.522321, 46.04400599905744],
            [-120.801295, 46.041013999057448],
            [-119.865829, 46.040857999057444],
            [-119.868153889391024, 45.838226601692924],
            [-119.965744, 45.824364999057202],
            [-119.99950515346201, 45.811684922139086],
            [-120.07015, 45.785151999057163],
            [-120.141352, 45.773151999057141],
            [-120.210754, 45.725950999057098],
            [-120.282156, 45.721249999057108],
            [-120.40396, 45.699248999057083],
            [-120.488546820704002, 45.699912226535581],
            [-120.505863, 45.700047999057084],
            [-120.591166, 45.746546999057124],
            [-120.634968, 45.745846999057122],
            [-120.65251676019399, 45.736169729732005],
            [-120.68937, 45.715846999057099],
            [-120.855674, 45.671544999057048],
            [-120.895575, 45.642944999057036],
            [-120.913935013084, 45.64806586548054],
            [-120.943977, 45.656444999057037],
            [-121.084933, 45.647892999057035],
            [-121.1222, 45.616066999057026],
            [-121.183841, 45.606440999057007],
            [-121.215779, 45.671237999057062],
            [-121.33777, 45.704948999057088],
            [-121.423592, 45.693989999057081],
            [-121.440695827861006, 45.69907379382947],
            [-121.524006104220021, 45.723836224256296],
            [-121.609246, 45.78251899905716],
            [-121.612232, 46.044092999057447]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "051",
        COUNTYNS: "01248005",
        AFFGEOID: "0500000US45051",
        GEOID: "45051",
        NAME: "Horry",
        LSAD: "06",
        ALAND: 2937082399,
        AWATER: 313208767
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.302341, 34.032218999124495],
            [-79.247574, 34.05726899912419],
            [-79.207787, 34.153876999123014],
            [-79.126681, 34.235361999122027],
            [-79.128165, 34.253651999121814],
            [-79.086067, 34.294955999121314],
            [-79.071254201048191, 34.299313025963158],
            [-79.071243612225302, 34.299303950155874],
            [-79.071169, 34.299239999121276],
            [-78.81171, 34.081005999123903],
            [-78.650871280533295, 33.945055719658463],
            [-78.615932, 33.91552299912594],
            [-78.541087, 33.851111999126736],
            [-78.67226, 33.817586999127151],
            [-78.772737, 33.76851099912777],
            [-78.862931, 33.705653999128565],
            [-78.938076, 33.6398259991294],
            [-78.995631694383505, 33.572074597319563],
            [-79.100653, 33.57180699913026],
            [-79.137683, 33.664129999129095],
            [-79.190264, 33.70578699912857],
            [-79.260283, 33.814853999127187],
            [-79.256829, 33.878968999126393],
            [-79.339076, 33.969721999125277],
            [-79.302341, 34.032218999124495]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "015",
        COUNTYNS: "01605073",
        AFFGEOID: "0500000US56015",
        GEOID: "56015",
        NAME: "Goshen",
        LSAD: "06",
        ALAND: 5764311447,
        AWATER: 16969239
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.655639, 42.609467999059923],
            [-104.05266219156799, 42.611766299601406],
            [-104.053107, 42.499963999060206],
            [-104.052793, 42.249961999060957],
            [-104.052729, 42.016317999061727],
            [-104.052762137092003, 42.001718264928357],
            [-104.053026, 41.885463999062161],
            [-104.052825235238998, 41.697953852126851],
            [-104.052735, 41.613675999063162],
            [-104.052634571632993, 41.56427577863704],
            [-104.652992, 41.565013999063346],
            [-104.653338, 41.65300699906301],
            [-104.655639, 42.609467999059923]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "023",
        COUNTYNS: "01605076",
        AFFGEOID: "0500000US56023",
        GEOID: "56023",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 10555073751,
        AWATER: 49947392
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.04708, 42.349419999060643],
            [-111.045530292713011, 42.513912876835271],
            [-111.043564, 42.722623999059621],
            [-111.043959, 42.964449999059021],
            [-111.044052705117991, 43.019407031957918],
            [-111.044143, 43.072363999058801],
            [-111.044617, 43.315719999058302],
            [-110.813996, 43.313939999058299],
            [-110.813993, 43.235177999058457],
            [-110.578656, 43.235223999058455],
            [-110.587993, 43.126146999058683],
            [-110.587662, 42.779187999059474],
            [-110.623434, 42.779204999059473],
            [-110.623477, 42.710180999059652],
            [-110.590074, 42.710086999059641],
            [-110.590131, 42.448434999060368],
            [-110.543329, 42.448535999060354],
            [-110.541634, 42.277490999060866],
            [-110.053708, 42.27074399906089],
            [-110.048, 41.578014999063299],
            [-110.084778, 41.5776149990633],
            [-111.045818464842, 41.579844947289082],
            [-111.046689, 42.001566999061758],
            [-111.04708, 42.349419999060643]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "089",
        COUNTYNS: "01248018",
        AFFGEOID: "0500000US45089",
        GEOID: "45089",
        NAME: "Williamsburg",
        LSAD: "06",
        ALAND: 2419464748,
        AWATER: 7469568
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.974382, 33.721589999128355],
            [-79.995638, 33.767905999127784],
            [-79.915305, 33.818647999127144],
            [-79.87562, 33.885244999126321],
            [-79.687452, 33.804913999127315],
            [-79.635808, 33.818037999127156],
            [-79.496466, 33.775371999127685],
            [-79.324317, 33.799122999127384],
            [-79.317041, 33.779877999127628],
            [-79.409332, 33.688889999128776],
            [-79.435733, 33.572701999130246],
            [-79.53808, 33.496895999131219],
            [-79.677385, 33.336412999133294],
            [-79.677014, 33.304943999133698],
            [-79.971062, 33.500742999131162],
            [-80.101697, 33.496890999131217],
            [-79.974382, 33.721589999128355]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "031",
        COUNTYNS: "00758470",
        AFFGEOID: "0500000US29031",
        GEOID: "29031",
        NAME: "Cape Girardeau",
        LSAD: "06",
        ALAND: 1498398283,
        AWATER: 20174864
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.861123, 37.599285999087677],
            [-89.672802, 37.583760999087794],
            [-89.602828, 37.603224999087637],
            [-89.501791049499801, 37.558895718411208],
            [-89.5124, 37.529809999088258],
            [-89.471201, 37.466472999088793],
            [-89.42594, 37.407470999089291],
            [-89.428185, 37.356157999089746],
            [-89.473679434541197, 37.334853901284511],
            [-89.49516, 37.32479499909001],
            [-89.517032, 37.281919999090384],
            [-89.482889284644003, 37.260950717504663],
            [-89.620256, 37.233007999090809],
            [-89.765211, 37.126065999091765],
            [-89.866627, 37.126226999091763],
            [-89.861123, 37.599285999087677]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "059",
        COUNTYNS: "00758484",
        AFFGEOID: "0500000US29059",
        GEOID: "29059",
        NAME: "Dallas",
        LSAD: "06",
        ALAND: 1400714729,
        AWATER: 5252024
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.182648, 37.904231999085184],
            [-93.072447, 37.902626999085179],
            [-92.855384, 37.895735999085247],
            [-92.846281, 37.721038999086666],
            [-92.853481, 37.483969999088643],
            [-93.071584, 37.487443999088605],
            [-93.07338, 37.414988999089225],
            [-93.182489, 37.417203999089217],
            [-93.173183, 37.729659999086593],
            [-93.187915, 37.802736999085987],
            [-93.182648, 37.904231999085184]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "169",
        COUNTYNS: "00835906",
        AFFGEOID: "0500000US31169",
        GEOID: "31169",
        NAME: "Thayer",
        LSAD: "06",
        ALAND: 1486153357,
        AWATER: 3025340
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.820816, 40.350544999068902],
            [-97.368688, 40.350391999068904],
            [-97.369199035314992, 40.001939304831311],
            [-97.415833, 40.002000999070816],
            [-97.777155, 40.002166999070816],
            [-97.821500812275204, 40.002186881159702],
            [-97.820816, 40.350544999068902]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "031",
        COUNTYNS: "00835837",
        AFFGEOID: "0500000US31031",
        GEOID: "31031",
        NAME: "Cherry",
        LSAD: "06",
        ALAND: 15436697272,
        AWATER: 127623807
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.082548043072009, 42.999140639448953],
            [-102.082486319192, 42.99914054757825],
            [-101.228013397794015, 42.997868738706458],
            [-101.000429, 42.997529999058962],
            [-100.198413799774997, 42.997976883028365],
            [-100.198478, 42.8461019990593],
            [-100.18327, 42.781004999059469],
            [-100.183167, 42.433429999060408],
            [-100.167605, 42.085847999061485],
            [-100.267622, 42.086146999061476],
            [-100.846129, 42.088165999061474],
            [-101.426557, 42.092193999061465],
            [-102.006204, 42.096337999061447],
            [-102.04028, 42.096743999061452],
            [-102.066718, 42.44941299906035],
            [-102.084961, 42.785508999059452],
            [-102.082548043072009, 42.999140639448953]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "065",
        COUNTYNS: "01213674",
        AFFGEOID: "0500000US42065",
        GEOID: "42065",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 1689784203,
        AWATER: 11321806
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.210722, 41.032420999065579],
            [-79.215214, 41.050514999065499],
            [-79.211173, 41.112574999065224],
            [-79.208878, 41.331860999064283],
            [-79.106444, 41.32624699906431],
            [-79.095096, 41.340657999064248],
            [-78.971904, 41.376564999064108],
            [-78.73528, 41.262914999064584],
            [-78.710248, 41.201858999064832],
            [-78.766377, 41.202758999064834],
            [-78.804532, 41.132185999065136],
            [-78.805167, 40.905979999066155],
            [-79.21531, 40.911345999066128],
            [-79.210722, 41.032420999065579]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "099",
        COUNTYNS: "00758504",
        AFFGEOID: "0500000US29099",
        GEOID: "29099",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 1700691208,
        AWATER: 19797687
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.7494, 38.358189999081645],
            [-90.737599, 38.415896999081227],
            [-90.736311, 38.46664299908084],
            [-90.678775, 38.445876999080987],
            [-90.660703, 38.479194999080747],
            [-90.512522, 38.501453999080589],
            [-90.405262, 38.501152999080574],
            [-90.340244281704301, 38.387094621717722],
            [-90.342915266737194, 38.384427319109051],
            [-90.349743, 38.377608999081502],
            [-90.372519, 38.323353999081895],
            [-90.363926, 38.236354999082572],
            [-90.351164145060295, 38.219544456177196],
            [-90.322353, 38.181592999082987],
            [-90.2527463239757, 38.127773825312701],
            [-90.253076, 38.115537999083514],
            [-90.416022, 38.042314999084077],
            [-90.628192, 38.007961999084344],
            [-90.63998, 38.0765479990838],
            [-90.684547, 38.086310999083722],
            [-90.780185, 38.204111999082812],
            [-90.7494, 38.358189999081645]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "003",
        COUNTYNS: "01008532",
        AFFGEOID: "0500000US37003",
        GEOID: "37003",
        NAME: "Alexander",
        LSAD: "06",
        ALAND: 673358173,
        AWATER: 9445986
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.343993, 35.824162999104267],
            [-81.328324, 35.9965829991025],
            [-81.263596, 36.025817999102202],
            [-81.028833, 36.045651999102006],
            [-81.005541, 35.958286999102889],
            [-81.041339, 35.857854999103914],
            [-81.109507, 35.776593999104747],
            [-81.143053, 35.82788699910423],
            [-81.248998, 35.815715999104356],
            [-81.264611, 35.805211999104465],
            [-81.334272, 35.796280999104553],
            [-81.343993, 35.824162999104267]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "019",
        COUNTYNS: "00758464",
        AFFGEOID: "0500000US29019",
        GEOID: "29019",
        NAME: "Boone",
        LSAD: "06",
        ALAND: 1775563346,
        AWATER: 14239688
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.565052, 38.998740999077064],
            [-92.430229, 39.248794999075407],
            [-92.314471, 39.246453999075413],
            [-92.104374, 39.239808999075457],
            [-92.110387, 39.064203999076625],
            [-92.1625, 38.865584999077967],
            [-92.135245, 38.813893999078338],
            [-92.216388, 38.714494999079037],
            [-92.220661, 38.64316699907954],
            [-92.357813, 38.679516999079283],
            [-92.394866, 38.739129999078862],
            [-92.381304, 38.808731999078375],
            [-92.498377, 38.922083999077593],
            [-92.558086, 38.97069199907726],
            [-92.565052, 38.998740999077064]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "419",
        COUNTYNS: "01383995",
        AFFGEOID: "0500000US48419",
        GEOID: "48419",
        NAME: "Shelby",
        LSAD: "06",
        ALAND: 2060566167,
        AWATER: 101081674
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.511431, 31.973983999151979],
            [-94.029428406596011, 31.979686963319899],
            [-93.977461, 31.926418999152666],
            [-93.909557, 31.893143999153146],
            [-93.878251895386597, 31.84427655639945],
            [-93.85339, 31.805466999154426],
            [-93.803419, 31.700685999155958],
            [-93.816838, 31.622508999157102],
            [-93.834924, 31.586210999157647],
            [-93.834923214477783, 31.586210020235644],
            [-93.896537, 31.611835999157272],
            [-93.984461, 31.569418999157904],
            [-94.090717, 31.620608999157135],
            [-94.399091, 31.653595999156654],
            [-94.432715, 31.822209999154179],
            [-94.452416, 31.844280999153856],
            [-94.511137, 31.923878999152699],
            [-94.511431, 31.973983999151979]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "089",
        COUNTYNS: "01682610",
        AFFGEOID: "0500000US06089",
        GEOID: "06089",
        NAME: "Shasta",
        LSAD: "06",
        ALAND: 9778230094,
        AWATER: 186479466
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.068789, 40.332232999068999],
            [-122.998682, 40.418141999068546],
            [-122.903738, 40.44507099906842],
            [-122.845954, 40.505180999068102],
            [-122.695298, 40.572513999067766],
            [-122.752197, 40.688984999067181],
            [-122.669839, 40.773566999066787],
            [-122.666058, 40.825874999066528],
            [-122.600458, 40.899748999066183],
            [-122.607363, 40.957747999065909],
            [-122.527753, 41.014394999065665],
            [-122.513354, 41.088226999065327],
            [-122.457144, 41.096610999065291],
            [-122.445453, 41.157929999065026],
            [-122.498376, 41.182674999064915],
            [-121.446495, 41.183483999064904],
            [-121.331786, 41.183885999064913],
            [-121.319976, 40.905885999066157],
            [-121.327826, 40.445366999068405],
            [-121.361456, 40.446821999068405],
            [-121.497635, 40.445590999068408],
            [-121.73429, 40.43568099906846],
            [-121.806539, 40.44494999906842],
            [-121.940989, 40.415330999068559],
            [-122.010316, 40.426557999068507],
            [-122.310412, 40.371063999068795],
            [-122.525677, 40.387612999068715],
            [-122.651341, 40.328287999069026],
            [-122.740358, 40.364999999068829],
            [-122.872274, 40.348968999068909],
            [-122.918055, 40.306971999069134],
            [-122.976548, 40.315161999069097],
            [-123.065426, 40.28697099906924],
            [-123.068789, 40.332232999068999]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "111",
        COUNTYNS: "01719558",
        AFFGEOID: "0500000US30111",
        GEOID: "30111",
        NAME: "Yellowstone",
        LSAD: "06",
        ALAND: 6820738870,
        AWATER: 39856668
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-108.924589, 46.132369999057566],
            [-108.779733, 46.132456999057567],
            [-108.404795, 46.13295199905756],
            [-108.404856, 46.235959999057712],
            [-108.320812, 46.263996999057753],
            [-108.029363, 46.264054999057755],
            [-108.011948, 46.336812999057862],
            [-107.928547, 46.395283999057952],
            [-107.782087, 46.395181999057954],
            [-107.781214, 46.496013999058135],
            [-107.738548, 46.481805999058096],
            [-107.612411, 46.351556999057891],
            [-107.466131, 46.177773999057635],
            [-107.511534, 46.043402999057442],
            [-107.674454, 46.043609999057459],
            [-107.674468, 45.985758999057374],
            [-107.798753, 45.985679999057368],
            [-107.798821, 45.956722999057334],
            [-107.881873, 45.928148999057314],
            [-107.881846, 45.899232999057276],
            [-108.047352, 45.899478999057287],
            [-108.069572, 45.783506999057167],
            [-108.069752, 45.518534999056946],
            [-108.193191, 45.489525999056923],
            [-108.316245, 45.489502999056938],
            [-108.316374, 45.460508999056906],
            [-108.645325, 45.46407299905691],
            [-108.699283, 45.522628999056941],
            [-108.801761, 45.55196499905697],
            [-108.782, 45.638638999057029],
            [-108.843016, 45.611041999057008],
            [-108.904726, 45.682820999057064],
            [-108.901655, 45.959415999057349],
            [-108.924589, 46.132369999057566]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "073",
        COUNTYNS: "01026126",
        AFFGEOID: "0500000US37073",
        GEOID: "37073",
        NAME: "Gates",
        LSAD: "06",
        ALAND: 882181715,
        AWATER: 13292007
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.916037632856188, 36.546081329387405],
            [-76.915731802349697, 36.546089768435984],
            [-76.738329, 36.550984999097047],
            [-76.541965865718296, 36.550784531457154],
            [-76.491336, 36.510676999097441],
            [-76.491405, 36.468647999097847],
            [-76.453711, 36.378091999098707],
            [-76.559646, 36.351055999098968],
            [-76.669975, 36.315213999099328],
            [-76.696571, 36.296137999099507],
            [-76.779467, 36.362468999098866],
            [-76.946258, 36.413821999098374],
            [-76.916037632856188, 36.546081329387405]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "087",
        COUNTYNS: "00974142",
        AFFGEOID: "0500000US36087",
        GEOID: "36087",
        NAME: "Rockland",
        LSAD: "06",
        ALAND: 449199631,
        AWATER: 66861670
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.234362831230385, 41.142835270599683],
            [-73.981384, 41.324692999064318],
            [-73.982584, 41.321692999064325],
            [-73.970784, 41.247892999064639],
            [-73.88748, 41.13829499906511],
            [-73.893579, 41.005296999065706],
            [-73.893981860035879, 40.997198202604643],
            [-74.041054, 41.059087999065454],
            [-74.211617643198593, 41.132981362601434],
            [-74.234362831230385, 41.142835270599683]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "740",
        COUNTYNS: "01498556",
        AFFGEOID: "0500000US51740",
        GEOID: "51740",
        NAME: "Portsmouth",
        LSAD: "25",
        ALAND: 86247836,
        AWATER: 34658655
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.399567, 36.930946999093514],
            [-76.384419, 36.95105899909332],
            [-76.341825, 36.924771999093565],
            [-76.333088, 36.896362999093832],
            [-76.336784, 36.875026999094018],
            [-76.289962, 36.822047999094508],
            [-76.292144, 36.798662999094724],
            [-76.363839, 36.787803999094834],
            [-76.400265, 36.80643699909465],
            [-76.387421, 36.852198999094227],
            [-76.420368, 36.866347999094096],
            [-76.399567, 36.930946999093514]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "123",
        COUNTYNS: "00758515",
        AFFGEOID: "0500000US29123",
        GEOID: "29123",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 1280463342,
        AWATER: 8312953
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.547406, 37.598409999087671],
            [-90.535016, 37.643640999087296],
            [-90.146778, 37.641617999087323],
            [-90.146763, 37.597433999087698],
            [-90.145699, 37.312090999090124],
            [-90.217657, 37.314966999090096],
            [-90.549729, 37.317593999090079],
            [-90.547406, 37.598409999087671]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "489",
        COUNTYNS: "01384030",
        AFFGEOID: "0500000US48489",
        GEOID: "48489",
        NAME: "Willacy",
        LSAD: "06",
        ALAND: 1529611927,
        AWATER: 501715022
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.957405, 26.611768999243733],
            [-97.861688, 26.597994999244001],
            [-97.287541116685787, 26.600339880717257],
            [-97.253801, 26.503163999245878],
            [-97.227380226521788, 26.411496004550202],
            [-97.390733, 26.411331999247693],
            [-97.446987, 26.324946999249416],
            [-97.506936, 26.312701999249661],
            [-97.527582, 26.299303999249929],
            [-97.861875, 26.348155999248956],
            [-97.86192, 26.433578999247249],
            [-98.004189, 26.448783999246956],
            [-97.957405, 26.611768999243733]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "089",
        COUNTYNS: "01719578",
        AFFGEOID: "0500000US30089",
        GEOID: "30089",
        NAME: "Sanders",
        LSAD: "06",
        ALAND: 7149403319,
        AWATER: 76395973
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.048911, 48.124929999062552],
            [-116.048929247775988, 48.216127946989978],
            [-115.957251, 48.149041999062625],
            [-115.936745, 48.184165999062763],
            [-115.800172, 48.222817999062904],
            [-115.737279, 48.26329199906305],
            [-115.672359, 48.20639399906284],
            [-115.696111, 48.177473999062727],
            [-115.645768, 48.134141999062564],
            [-115.584705, 48.021974999062174],
            [-115.544841, 47.99938399906209],
            [-115.558139, 47.942290999061889],
            [-115.524695, 47.908593999061772],
            [-115.32828, 47.917472999061815],
            [-115.278953, 47.890273999061712],
            [-115.185196, 47.919003999061822],
            [-115.154527, 48.017691999062158],
            [-115.010672, 48.017620999062153],
            [-114.989344, 47.872806999061645],
            [-114.604584, 47.875604999061679],
            [-114.604462, 47.789870999061378],
            [-114.584454, 47.746354999061246],
            [-114.58376, 47.601717999060796],
            [-114.320528, 47.594550999060772],
            [-114.383453, 47.483253999060437],
            [-114.301457, 47.497049999060479],
            [-114.35308, 47.389849999060175],
            [-114.288557, 47.355874999060077],
            [-114.203026, 47.355745999060076],
            [-114.203119, 47.181907999059625],
            [-114.18717, 47.13819099905951],
            [-114.264148, 47.121069999059465],
            [-114.346111, 47.176234999059602],
            [-114.563737, 47.254610999059807],
            [-114.797204, 47.268915999059843],
            [-114.968978, 47.312624999059956],
            [-114.982265, 47.396803999060189],
            [-115.249057, 47.480415999060433],
            [-115.52591, 47.495409999060477],
            [-115.634684, 47.481759999060444],
            [-115.717024, 47.532692999060586],
            [-115.721207, 47.576322999060721],
            [-115.694284, 47.623459999060856],
            [-115.73627, 47.654761999060959],
            [-115.72377, 47.696670999061084],
            [-115.835365, 47.760956999061293],
            [-115.845474, 47.814966999061461],
            [-115.900934, 47.843063999061563],
            [-115.959946, 47.89814199906175],
            [-116.030751, 47.973348999062004],
            [-116.038565829872979, 47.984634255503742],
            [-116.049153, 47.999922999062086],
            [-116.048911, 48.124929999062552]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "043",
        COUNTYNS: "00974120",
        AFFGEOID: "0500000US36043",
        GEOID: "36043",
        NAME: "Herkimer",
        LSAD: "06",
        ALAND: 3655805548,
        AWATER: 120422420
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.219106, 43.052468999058831],
            [-75.069165, 43.227332999058468],
            [-75.161886, 43.257348999058415],
            [-75.076581, 43.330704999058263],
            [-75.11016, 43.615228999057784],
            [-75.170159, 44.096958999057179],
            [-75.062779, 44.050399999057234],
            [-74.854171, 44.070088999057212],
            [-74.775617, 43.486676999057998],
            [-74.867712, 43.339825999058249],
            [-74.712615, 43.286142999058349],
            [-74.695985, 43.174176999058581],
            [-74.763614, 43.057961999058818],
            [-74.759895, 43.047422999058831],
            [-74.743805, 43.030740999058878],
            [-74.763303, 42.863236999059261],
            [-74.878822, 42.898273999059185],
            [-74.906738, 42.82494299905936],
            [-75.100999, 42.908362999059165],
            [-75.210642, 42.856548999059292],
            [-75.212158, 42.879972999059227],
            [-75.219106, 43.052468999058831]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "113",
        COUNTYNS: "00974154",
        AFFGEOID: "0500000US36113",
        GEOID: "36113",
        NAME: "Warren",
        LSAD: "06",
        ALAND: 2246154434,
        AWATER: 166682934
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.214625, 43.728702999057624],
            [-74.057005, 43.744512999057598],
            [-73.43812, 43.803686999057518],
            [-73.49291, 43.657138999057729],
            [-73.636651, 43.497241999057977],
            [-73.59496, 43.306117999058316],
            [-73.73997, 43.264708999058392],
            [-73.767498, 43.222122999058477],
            [-73.835811, 43.25375599905842],
            [-73.825828, 43.305347999058313],
            [-73.884139, 43.398040999058153],
            [-74.1601, 43.371531999058199],
            [-74.214625, 43.728702999057624]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "019",
        COUNTYNS: "00659455",
        AFFGEOID: "0500000US27019",
        GEOID: "27019",
        NAME: "Carver",
        LSAD: "06",
        ALAND: 917348283,
        AWATER: 56768380
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.012236, 44.978711999056756],
            [-93.767367, 44.977963999056769],
            [-93.767041, 44.890861999056767],
            [-93.520706, 44.891501999056757],
            [-93.520431, 44.804262999056782],
            [-93.578663, 44.794372999056776],
            [-93.594234, 44.776214999056783],
            [-93.618141, 44.707095999056804],
            [-93.768039, 44.640302999056814],
            [-93.768176, 44.673519999056801],
            [-93.888831, 44.673408999056804],
            [-93.888636, 44.717043999056791],
            [-94.010494, 44.717463999056797],
            [-94.012236, 44.978711999056756]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "057",
        COUNTYNS: "00484997",
        AFFGEOID: "0500000US20057",
        GEOID: "20057",
        NAME: "Ford",
        LSAD: "06",
        ALAND: 2844508248,
        AWATER: 2097356
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.22709, 37.914017999085097],
            [-99.569879, 37.913015999085097],
            [-99.570576, 37.825633999085809],
            [-99.55958, 37.734887999086553],
            [-99.556119, 37.467721999088781],
            [-100.107248, 37.474835999088718],
            [-100.216229, 37.475189999088705],
            [-100.214637, 37.826807999085794],
            [-100.22709, 37.914017999085097]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "007",
        COUNTYNS: "01448018",
        AFFGEOID: "0500000US49007",
        GEOID: "49007",
        NAME: "Carbon",
        LSAD: "06",
        ALAND: 3831067724,
        AWATER: 15844443
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.247496, 39.813027999071906],
            [-110.85778, 39.813284999071904],
            [-109.976814, 39.806229999071945],
            [-109.88308, 39.806235999071944],
            [-109.962143, 39.72588499907242],
            [-110.037952, 39.566666999073384],
            [-110.023963, 39.469267999073985],
            [-110.024118, 39.469268999073982],
            [-111.081333, 39.467454999074008],
            [-111.085349, 39.521521999073663],
            [-111.132248, 39.613875999073095],
            [-111.159113, 39.595946999073199],
            [-111.247391, 39.704349999072541],
            [-111.247496, 39.813027999071906]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "131",
        COUNTYNS: "01008575",
        AFFGEOID: "0500000US37131",
        GEOID: "37131",
        NAME: "Northampton",
        LSAD: "06",
        ALAND: 1389889811,
        AWATER: 36034131
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.899774136046673, 36.544850732139508],
            [-77.767103969926694, 36.545442408432599],
            [-77.749706, 36.545519999097102],
            [-77.298770188811787, 36.546039009951599],
            [-77.190175, 36.546163999097097],
            [-77.164322603718205, 36.546152818944996],
            [-77.066463, 36.496510999097573],
            [-77.122959, 36.471217999097817],
            [-77.208468, 36.246577999100005],
            [-77.29825, 36.207707999100386],
            [-77.291519, 36.168341999100775],
            [-77.310018, 36.16885899910077],
            [-77.386595, 36.213547999100321],
            [-77.445886, 36.324887999099232],
            [-77.541108, 36.303263999099435],
            [-77.585698, 36.335509999099116],
            [-77.555275, 36.417963999098333],
            [-77.644367, 36.480227999097728],
            [-77.899524, 36.504258999097495],
            [-77.899774136046673, 36.544850732139508]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "099",
        COUNTYNS: "00450372",
        AFFGEOID: "0500000US18099",
        GEOID: "18099",
        NAME: "Marshall",
        LSAD: "06",
        ALAND: 1148996723,
        AWATER: 15831838
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.467108, 41.476458999063702],
            [-86.059451, 41.479153999063691],
            [-86.05923, 41.435811999063866],
            [-86.054116, 41.176367999064944],
            [-86.077551, 41.172867999064955],
            [-86.467046, 41.17144199906496],
            [-86.466603, 41.43296799906387],
            [-86.467108, 41.476458999063702]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "053",
        COUNTYNS: "01135862",
        AFFGEOID: "0500000US41053",
        GEOID: "41053",
        NAME: "Polk",
        LSAD: "06",
        ALAND: 1918983329,
        AWATER: 8153115
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.724663, 45.044431999056776],
            [-123.724368, 45.076225999056781],
            [-123.068667, 45.07485999905677],
            [-123.044903, 44.944027999056765],
            [-123.110356, 44.930696999056757],
            [-123.180908, 44.857552999056765],
            [-123.093335, 44.814043999056764],
            [-123.143843, 44.748913999056782],
            [-123.149025, 44.720224999056789],
            [-123.301882, 44.719834999056793],
            [-123.602609, 44.721153999056789],
            [-123.704467, 44.721153999056789],
            [-123.725001, 44.739045999056778],
            [-123.724663, 45.044431999056776]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "325",
        COUNTYNS: "01383948",
        AFFGEOID: "0500000US48325",
        GEOID: "48325",
        NAME: "Medina",
        LSAD: "06",
        ALAND: 3432663415,
        AWATER: 23762579
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.411817, 29.627513999188647],
            [-98.983787, 29.623449999188708],
            [-98.971383, 29.551841999189918],
            [-98.927161, 29.562249999189731],
            [-98.806552, 29.690708999187574],
            [-98.804763, 29.250692999195042],
            [-98.8049, 29.090433999197806],
            [-99.41388, 29.091334999197795],
            [-99.411817, 29.627513999188647]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "195",
        COUNTYNS: "01785167",
        AFFGEOID: "0500000US17195",
        GEOID: "17195",
        NAME: "Whiteside",
        LSAD: "06",
        ALAND: 1772197821,
        AWATER: 31797486
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.242368247397195, 41.78276872504712],
            [-90.180643, 41.811978999062418],
            [-90.181401, 41.844646999062306],
            [-90.165065, 41.883776999062164],
            [-90.158153033709496, 41.929843226438109],
            [-90.128204, 41.928976999062002],
            [-89.685366, 41.930339999061999],
            [-89.62933, 41.901616999062099],
            [-89.631494, 41.584948999063279],
            [-89.862351, 41.584004999063275],
            [-90.185609, 41.584652999063273],
            [-90.161233, 41.643926999063048],
            [-90.229749, 41.675374999062917],
            [-90.242368247397195, 41.78276872504712]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "019",
        COUNTYNS: "00025446",
        AFFGEOID: "0500000US04019",
        GEOID: "04019",
        NAME: "Pima",
        LSAD: "06",
        ALAND: 23794788865,
        AWATER: 5259325
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.333897, 32.504937999144467],
            [-112.568482, 32.505218999144461],
            [-112.203719, 32.506759999144442],
            [-111.792534, 32.506778999144444],
            [-111.351531, 32.501354999144517],
            [-111.157493, 32.511254999144377],
            [-110.85872, 32.511222999144387],
            [-110.842071, 32.513864999144346],
            [-110.451218, 32.513994999144344],
            [-110.451503, 32.427353999145538],
            [-110.451736, 31.731266999155505],
            [-111.05804, 31.726229999155581],
            [-111.164183, 31.72598299915558],
            [-111.163725, 31.521825999158608],
            [-111.366613, 31.521290999158609],
            [-111.366969371164004, 31.424824286351654],
            [-112.365043, 31.741129999155365],
            [-113.333767973015014, 32.040249079241121],
            [-113.333897, 32.504937999144467]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "061",
        COUNTYNS: "01639748",
        AFFGEOID: "0500000US47061",
        GEOID: "47061",
        NAME: "Grundy",
        LSAD: "06",
        ALAND: 933548197,
        AWATER: 1753031
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.913593, 35.289188999109939],
            [-85.869712, 35.380361999108956],
            [-85.876962, 35.524094999107405],
            [-85.820334, 35.504572999107616],
            [-85.607326, 35.53165099910732],
            [-85.485622, 35.406306999108672],
            [-85.558551, 35.319291999109609],
            [-85.709418, 35.289250999109939],
            [-85.740113, 35.215294999110746],
            [-85.873025, 35.223361999110665],
            [-85.913593, 35.289188999109939]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "185",
        COUNTYNS: "00424293",
        AFFGEOID: "0500000US17185",
        GEOID: "17185",
        NAME: "Wabash",
        LSAD: "06",
        ALAND: 578224627,
        AWATER: 11012015
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.9906, 38.259714999082391],
            [-87.951122, 38.290610999082155],
            [-87.97607, 38.399927999081328],
            [-87.953898, 38.570093999080072],
            [-87.912286, 38.570103999080075],
            [-87.648356763074489, 38.566628715137696],
            [-87.660732, 38.541091999080287],
            [-87.654166, 38.511910999080499],
            [-87.714047, 38.479879999080744],
            [-87.74104, 38.435575999081067],
            [-87.7511059451205, 38.418849207536006],
            [-87.779996, 38.370841999081549],
            [-87.831972, 38.307240999082033],
            [-87.908542, 38.268580999082324],
            [-87.968968, 38.237388999082562],
            [-87.9906, 38.259714999082391]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "021",
        COUNTYNS: "01622953",
        AFFGEOID: "0500000US26021",
        GEOID: "26021",
        NAME: "Berrien",
        LSAD: "06",
        ALAND: 1470473558,
        AWATER: 2625519926
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.693267, 41.835402999062339],
            [-86.597899, 41.918290999062052],
            [-86.501322, 42.084539999061477],
            [-86.466262, 42.134405999061308],
            [-86.3663791270383, 42.243107726241867],
            [-86.223563, 42.24337899906098],
            [-86.22294, 42.071483999061527],
            [-86.226094344987388, 41.760015958179004],
            [-86.501773, 41.759552999062599],
            [-86.524219742442895, 41.759572155036111],
            [-86.640044, 41.759670999062607],
            [-86.641322920520793, 41.759674937205403],
            [-86.824828, 41.760239999062613],
            [-86.693267, 41.835402999062339]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "171",
        COUNTYNS: "00659530",
        AFFGEOID: "0500000US27171",
        GEOID: "27171",
        NAME: "Wright",
        LSAD: "06",
        ALAND: 1712598664,
        AWATER: 137298052
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.260821, 45.283940999056824],
            [-94.09272, 45.342466999056846],
            [-94.047327, 45.422860999056894],
            [-93.75661, 45.294720999056828],
            [-93.563814, 45.301135999056832],
            [-93.521653, 45.246617999056809],
            [-93.65881, 45.194115999056798],
            [-93.638653, 45.174160999056788],
            [-93.638622, 45.16561999905678],
            [-93.645168, 45.161560999056782],
            [-93.647747, 45.158608999056788],
            [-93.661545, 45.153273999056786],
            [-93.667203, 45.154442999056783],
            [-93.676267, 45.151043999056789],
            [-93.761874, 45.081243999056781],
            [-93.767367, 44.977963999056769],
            [-94.012236, 44.978711999056756],
            [-94.256074, 44.979464999056766],
            [-94.256681, 45.239271999056804],
            [-94.260821, 45.283940999056824]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "063",
        COUNTYNS: "01581091",
        AFFGEOID: "0500000US55063",
        GEOID: "55063",
        NAME: "La Crosse",
        LSAD: "06",
        ALAND: 1170076495,
        AWATER: 73110306
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.423568699817295, 43.984296879379905],
            [-91.322143, 43.984461999057309],
            [-91.307478, 44.064875999057207],
            [-91.151932, 44.079664999057208],
            [-91.136542, 44.071019999057206],
            [-90.973107, 44.070881999057207],
            [-90.912918, 44.071521999057218],
            [-90.910653, 43.725333999057625],
            [-91.257000156005688, 43.725658736280025],
            [-91.243955, 43.773045999057558],
            [-91.287655627367101, 43.847064999057466],
            [-91.291002, 43.852732999057466],
            [-91.357426, 43.917230999057381],
            [-91.423568699817295, 43.984296879379905]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "015",
        COUNTYNS: "00025445",
        AFFGEOID: "0500000US04015",
        GEOID: "04015",
        NAME: "Mohave",
        LSAD: "06",
        ALAND: 34475503964,
        AWATER: 387344307
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.743342, 36.070534999101753],
            [-114.736165, 36.104366999101416],
            [-114.666538, 36.11734299910129],
            [-114.627855, 36.141011999101046],
            [-114.572031, 36.151609999100948],
            [-114.511721, 36.150955999100951],
            [-114.487034, 36.12939599910117],
            [-114.448654, 36.126409999101192],
            [-114.41695, 36.145760999101],
            [-114.372106, 36.143113999101033],
            [-114.337273, 36.10801999910138],
            [-114.316109, 36.063108999101829],
            [-114.270645, 36.035719999102106],
            [-114.21369, 36.015612999102309],
            [-114.151725, 36.024562999102216],
            [-114.138203, 36.05316099910192],
            [-114.09987, 36.121653999101234],
            [-114.046838, 36.194068999100523],
            [-114.048226, 36.268873999099782],
            [-114.047584, 36.325572999099222],
            [-114.049493, 36.604058999096544],
            [-114.050160776087992, 36.843141538783719],
            [-114.0506, 37.000395999092873],
            [-113.965907, 37.000024999092886],
            [-112.966471, 37.00021899909288],
            [-112.899190897616009, 37.000301227721287],
            [-112.829501944372012, 37.00038640040728],
            [-112.545094, 37.00073399909288],
            [-112.538571, 37.000744127974883],
            [-112.529299, 36.952436999093322],
            [-112.600381, 36.831927999094418],
            [-112.638679, 36.674477999095878],
            [-112.620946, 36.595941999096624],
            [-112.660059, 36.543559999097127],
            [-112.623017, 36.474735999097774],
            [-112.62949, 36.391610999098567],
            [-112.681571, 36.341661999099067],
            [-112.723991, 36.34599199909902],
            [-112.792436, 36.28503599909962],
            [-112.865033, 36.282380999099651],
            [-112.902949, 36.253760999099924],
            [-113.009038, 36.239039999100065],
            [-113.13708, 36.166423999100793],
            [-113.196751, 36.152948999100936],
            [-113.224919, 36.09114399910154],
            [-113.319971, 36.096768999101492],
            [-113.354176, 36.04036799910206],
            [-113.317122, 35.961384999102862],
            [-113.33485, 35.91385899910334],
            [-113.310523, 35.864349999103851],
            [-113.334024, 35.803030999104486],
            [-113.334161, 35.528036999107364],
            [-113.334105, 35.508415999107577],
            [-113.333508, 34.31787699912104],
            [-113.370507, 34.291967999121347],
            [-113.539524, 34.306376999121177],
            [-113.606093, 34.229333999122119],
            [-113.679109, 34.210028999122336],
            [-113.851708, 34.251264999121837],
            [-114.031132, 34.259818999121748],
            [-114.140817151280004, 34.30312751300292],
            [-114.14093, 34.305918999121182],
            [-114.172845, 34.344978999120713],
            [-114.264317, 34.401328999120054],
            [-114.335372, 34.450037999119473],
            [-114.378852, 34.450375999119466],
            [-114.378223, 34.51652099911869],
            [-114.422382, 34.580710999117947],
            [-114.465246, 34.691201999116657],
            [-114.490971, 34.724847999116271],
            [-114.576452, 34.81529999911524],
            [-114.634382, 34.872889999114562],
            [-114.629769, 34.943039999113779],
            [-114.633487, 35.001856999113116],
            [-114.625069, 35.068477999112368],
            [-114.619905, 35.121631999111791],
            [-114.59912, 35.121049999111783],
            [-114.572747, 35.138724999111588],
            [-114.587129, 35.262375999110233],
            [-114.627137, 35.409503999108637],
            [-114.6645, 35.449496999108213],
            [-114.663105, 35.5244909991074],
            [-114.653406, 35.610788999106489],
            [-114.689407, 35.651411999106067],
            [-114.697309, 35.733685999105212],
            [-114.70371, 35.814584999104369],
            [-114.669687, 35.865083999103838],
            [-114.700271, 35.901771999103467],
            [-114.731159, 35.943915999103041],
            [-114.742779, 36.009962999102356],
            [-114.743299245886007, 36.065935172861899],
            [-114.743342, 36.070534999101753]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "155",
        COUNTYNS: "00758532",
        AFFGEOID: "0500000US29155",
        GEOID: "29155",
        NAME: "Pemiscot",
        LSAD: "06",
        ALAND: 1275622233,
        AWATER: 54164612
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.96131, 36.388802999098608],
            [-89.752147, 36.386395999098625],
            [-89.728855, 36.427503999098235],
            [-89.611308, 36.411272999098394],
            [-89.545031333969092, 36.344271397762036],
            [-89.600544, 36.342984999099052],
            [-89.611819, 36.309087999099383],
            [-89.554289, 36.277750999099688],
            [-89.602374, 36.238105999100078],
            [-89.678046, 36.248283999099975],
            [-89.69263, 36.224958999100217],
            [-89.627641441675394, 36.185460315706614],
            [-89.623804, 36.183127999100627],
            [-89.592102, 36.135636999101109],
            [-89.64302, 36.103619999101426],
            [-89.680029, 36.082493999101636],
            [-89.692437, 36.02050699910226],
            [-89.733095, 36.000607999102456],
            [-89.901183, 35.999364999102475],
            [-89.9593752951737, 35.999014109259377],
            [-89.96131, 36.388802999098608]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "169",
        COUNTYNS: "01785037",
        AFFGEOID: "0500000US17169",
        GEOID: "17169",
        NAME: "Schuyler",
        LSAD: "06",
        ALAND: 1132531697,
        AWATER: 10511619
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.911969, 40.193087999069753],
            [-90.909756, 40.284393999069259],
            [-90.450227, 40.276334999069299],
            [-90.451502, 40.18889199906976],
            [-90.199556, 40.183944999069794],
            [-90.206144, 40.155788999069955],
            [-90.355198, 40.124244999070122],
            [-90.435852, 40.020561999070701],
            [-90.513747, 39.987890999070885],
            [-90.607134, 39.981657999070919],
            [-90.695884, 40.103793999070248],
            [-90.913616, 40.104451999070236],
            [-90.911969, 40.193087999069753]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "123",
        COUNTYNS: "01101849",
        AFFGEOID: "0500000US40123",
        GEOID: "40123",
        NAME: "Pontotoc",
        LSAD: "06",
        ALAND: 1865885740,
        AWATER: 12421415
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.932471, 34.854454999114786],
            [-96.930806, 34.875080999114552],
            [-96.930586, 34.964363999113537],
            [-96.8854, 34.926250999113968],
            [-96.813452, 34.94464899911376],
            [-96.775681, 34.899957999114264],
            [-96.727591, 34.857181999114758],
            [-96.710934, 34.911714999114139],
            [-96.577089, 34.916583999114074],
            [-96.528587, 34.869585999114612],
            [-96.490786, 34.910584999114135],
            [-96.408639, 34.930672999113916],
            [-96.406483, 34.767589999115778],
            [-96.406357, 34.680022999116794],
            [-96.512171, 34.68006499911678],
            [-96.513865, 34.505388999118829],
            [-96.827288, 34.505988999118813],
            [-96.82729, 34.593219999117785],
            [-96.932423, 34.593260999117803],
            [-96.932328, 34.636810999117287],
            [-96.932471, 34.854454999114786]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "109",
        COUNTYNS: "01581833",
        AFFGEOID: "0500000US55109",
        GEOID: "55109",
        NAME: "St. Croix",
        LSAD: "06",
        ALAND: 1871226040,
        AWATER: 34193433
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.802911, 45.065402999056772],
            [-92.740509, 45.113395999056777],
            [-92.743823479365574, 45.123646261379783],
            [-92.766932, 45.195110999056787],
            [-92.766091408673688, 45.210021701479498],
            [-92.156465, 45.209553999056801],
            [-92.136113, 45.121431999056774],
            [-92.136348, 44.857847999056759],
            [-92.767023265751888, 44.861976736154467],
            [-92.750802, 44.941566999056761],
            [-92.761904, 45.022466999056761],
            [-92.802911, 45.065402999056772]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "051",
        COUNTYNS: "00161551",
        AFFGEOID: "0500000US01051",
        GEOID: "01051",
        NAME: "Elmore",
        LSAD: "06",
        ALAND: 1601762124,
        AWATER: 99965171
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.413335, 32.750590999141089],
            [-86.374974, 32.753579999141046],
            [-86.336694, 32.768129999140854],
            [-86.31948, 32.753697999141053],
            [-86.007187, 32.754983999141025],
            [-85.87986, 32.754527999141025],
            [-85.886148, 32.493052999144616],
            [-85.852625, 32.475746999144867],
            [-86.023012, 32.419977999145651],
            [-86.046402, 32.406145999145842],
            [-86.192284, 32.436129999145429],
            [-86.262006, 32.501689999144503],
            [-86.411172, 32.409936999145785],
            [-86.413116, 32.707385999141678],
            [-86.413335, 32.750590999141089]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "059",
        COUNTYNS: "00558049",
        AFFGEOID: "0500000US22059",
        GEOID: "22059",
        NAME: "LaSalle",
        LSAD: "15",
        ALAND: 1617924843,
        AWATER: 97664776
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.362608, 31.796632999154557],
            [-92.340494, 31.813366999154308],
            [-92.312623, 31.927381999152644],
            [-92.005434, 31.927295999152655],
            [-92.008121, 31.325796999161547],
            [-92.025948, 31.343008999161292],
            [-92.086718, 31.335373999161394],
            [-92.072937, 31.345406999161245],
            [-92.196131, 31.477876999159253],
            [-92.277615, 31.496235999158987],
            [-92.275088, 31.5360869991584],
            [-92.35127, 31.624550999157091],
            [-92.362608, 31.796632999154557]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "069",
        COUNTYNS: "01266984",
        AFFGEOID: "0500000US46069",
        GEOID: "46069",
        NAME: "Hyde",
        LSAD: "06",
        ALAND: 2228778417,
        AWATER: 14541547
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.67305, 44.897491999056761],
            [-99.572699, 44.897262999056771],
            [-99.311754, 44.897226999056763],
            [-99.300495, 44.547379999056858],
            [-99.30018, 44.194829999057092],
            [-99.576571, 44.192420999057084],
            [-99.663508, 44.217516999057061],
            [-99.66371, 44.539098999056861],
            [-99.676398, 44.548110999056853],
            [-99.67305, 44.897491999056761]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "123",
        COUNTYNS: "00161587",
        AFFGEOID: "0500000US01123",
        GEOID: "01123",
        NAME: "Tallapoosa",
        LSAD: "06",
        ALAND: 1855771079,
        AWATER: 128817905
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.00917, 33.090259999136528],
            [-85.974715, 33.105285999136335],
            [-85.653654, 33.106633999136314],
            [-85.593177, 33.1073399991363],
            [-85.593151, 32.728529999141386],
            [-85.679854, 32.712529999141609],
            [-85.696755, 32.697428999141813],
            [-85.695854, 32.595932999143201],
            [-85.798585, 32.581088999143411],
            [-85.797156, 32.494235999144607],
            [-85.886148, 32.493052999144616],
            [-85.87986, 32.754527999141025],
            [-86.007187, 32.754983999141025],
            [-86.013491, 32.81920299914016],
            [-86.00917, 33.090259999136528]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "069",
        COUNTYNS: "00277299",
        AFFGEOID: "0500000US06069",
        GEOID: "06069",
        NAME: "San Benito",
        LSAD: "06",
        ALAND: 3596588882,
        AWATER: 4721397
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.644001, 36.893995999093846],
            [-121.581354, 36.899151999093803],
            [-121.548009, 36.917390999093627],
            [-121.540016, 36.920764999093599],
            [-121.540754, 36.924627999093566],
            [-121.534463, 36.930932999093507],
            [-121.523591, 36.937089999093459],
            [-121.513813, 36.945154999093369],
            [-121.512263, 36.957996999093275],
            [-121.50528, 36.964215999093213],
            [-121.501488, 36.971894999093138],
            [-121.451972, 36.988839999092981],
            [-121.418246, 36.960548999093248],
            [-121.215406, 36.961247999093239],
            [-121.234683, 36.926893999093544],
            [-121.141523, 36.836655999094376],
            [-120.918731, 36.740380999095265],
            [-120.597155, 36.488234999097642],
            [-120.596562, 36.328487999099195],
            [-120.678582, 36.2673189990998],
            [-120.75834, 36.30860399909939],
            [-120.717696, 36.260011999099866],
            [-120.718169, 36.196847999100491],
            [-120.761483, 36.20357999910042],
            [-120.86508, 36.292577999099542],
            [-120.920551, 36.311011999099371],
            [-121.02724, 36.259892999099868],
            [-121.040797, 36.323968999099236],
            [-121.193465, 36.44575099909806],
            [-121.244091, 36.506910999097471],
            [-121.311788, 36.50273599909751],
            [-121.318373, 36.610025999096493],
            [-121.350092, 36.648356999096116],
            [-121.468086, 36.685517999095779],
            [-121.45134, 36.72479299909542],
            [-121.597698, 36.837349999094378],
            [-121.644001, 36.893995999093846]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "075",
        COUNTYNS: "00695761",
        AFFGEOID: "0500000US28075",
        GEOID: "28075",
        NAME: "Lauderdale",
        LSAD: "06",
        ALAND: 1822451131,
        AWATER: 29787695
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.914516, 32.576954999143467],
            [-88.389248564124301, 32.578122395102056],
            [-88.421312221394302, 32.308679122158509],
            [-88.428278, 32.250142999148032],
            [-88.431145363462804, 32.227636233837252],
            [-88.913847, 32.224185999148403],
            [-88.914516, 32.576954999143467]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "037",
        COUNTYNS: "00835840",
        AFFGEOID: "0500000US31037",
        GEOID: "31037",
        NAME: "Colfax",
        LSAD: "06",
        ALAND: 1066152076,
        AWATER: 12619739
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.252425, 41.743084999062667],
            [-97.019911, 41.742979999062676],
            [-96.905922, 41.742762999062677],
            [-96.90591, 41.456435999063778],
            [-97.178464, 41.3768159990641],
            [-97.253519, 41.384211999064071],
            [-97.252425, 41.743084999062667]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "213",
        COUNTYNS: "01383892",
        AFFGEOID: "0500000US48213",
        GEOID: "48213",
        NAME: "Henderson",
        LSAD: "06",
        ALAND: 2263037771,
        AWATER: 193234810
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.452138, 32.358630999146492],
            [-96.196231, 32.354761999146561],
            [-96.075899, 32.357504999146521],
            [-95.449165, 32.355232999146544],
            [-95.488733, 32.232485999148281],
            [-95.478585, 32.147223999149503],
            [-95.459908, 32.135558999149659],
            [-95.428512, 32.084474999150387],
            [-96.052786, 32.005894999151515],
            [-96.05478, 32.012525999151414],
            [-96.105364, 32.075336999150522],
            [-96.146523, 32.211714999148576],
            [-96.196965, 32.20694199914864],
            [-96.383082, 32.328850999146923],
            [-96.451474, 32.345573999146687],
            [-96.452138, 32.358630999146492]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "055",
        COUNTYNS: "00558059",
        AFFGEOID: "0500000US22055",
        GEOID: "22055",
        NAME: "Lafayette",
        LSAD: "15",
        ALAND: 696041521,
        AWATER: 1208724
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.284675, 30.147442999180033],
            [-92.2479, 30.211038999179006],
            [-92.142184, 30.298816999177586],
            [-92.089446, 30.33016599917708],
            [-92.04943, 30.381306999176264],
            [-91.988718, 30.368597999176462],
            [-91.942818, 30.23659899917859],
            [-91.979932, 30.182584999179465],
            [-91.905544, 30.154615999179921],
            [-91.950998, 30.073212999181248],
            [-91.964458, 30.036917999181849],
            [-92.066153, 30.073925999181238],
            [-92.088421, 30.128298999180352],
            [-92.128423, 30.115988999180555],
            [-92.203124, 30.142622999180112],
            [-92.22705, 30.136895999180211],
            [-92.240158, 30.141040999180138],
            [-92.258912, 30.139297999180165],
            [-92.283673, 30.1441909991801],
            [-92.284675, 30.147442999180033]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "023",
        COUNTYNS: "01074024",
        AFFGEOID: "0500000US39023",
        GEOID: "39023",
        NAME: "Clark",
        LSAD: "06",
        ALAND: 1029475869,
        AWATER: 13070242
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.051039, 39.879806999071512],
            [-84.036069, 40.040181999070597],
            [-83.516155, 40.010187999070773],
            [-83.588041, 39.768777999072164],
            [-83.647169, 39.773028999072146],
            [-83.829374, 39.797765999071991],
            [-83.939583, 39.843796999071721],
            [-84.053736, 39.850457999071679],
            [-84.051039, 39.879806999071512]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "047",
        COUNTYNS: "00974122",
        AFFGEOID: "0500000US36047",
        GEOID: "36047",
        NAME: "Kings",
        LSAD: "06",
        ALAND: 179672197,
        AWATER: 71097153
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.067720330318991, 40.670380593311869],
            [-74.047313, 40.690465999067186],
            [-74.046966591832998, 40.691149279335761],
            [-74.041156244303494, 40.702610024775922],
            [-74.030927085683885, 40.722786749560619],
            [-73.965572, 40.718949999067043],
            [-73.962645, 40.722746999067027],
            [-73.962478, 40.736801999066955],
            [-73.923127, 40.717023999067052],
            [-73.922711, 40.716477999067038],
            [-73.896466, 40.682335999067227],
            [-73.879616, 40.691105999067183],
            [-73.868717, 40.694621999067166],
            [-73.863787, 40.681113999067229],
            [-73.848338, 40.643520999067405],
            [-73.825494001131077, 40.576149578061646],
            [-73.940591, 40.542895999067923],
            [-73.973791770244588, 40.560854701585733],
            [-73.991346, 40.570349999067773],
            [-74.036557654454086, 40.588988412809293],
            [-74.037970523915803, 40.589570865407175],
            [-74.041393, 40.603105999067616],
            [-74.05663, 40.627286999067493],
            [-74.055739, 40.651759999067373],
            [-74.070943537715294, 40.667208231842892],
            [-74.067720330318991, 40.670380593311869]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "037",
        COUNTYNS: "00929110",
        AFFGEOID: "0500000US35037",
        GEOID: "35037",
        NAME: "Quay",
        LSAD: "06",
        ALAND: 7443334318,
        AWATER: 18728804
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.129143, 34.779336999115642],
            [-104.125121, 35.142057999111557],
            [-103.85753, 35.242404999110455],
            [-103.63713, 35.240818999110466],
            [-103.637053, 35.389661999108853],
            [-103.37973, 35.396795999108775],
            [-103.375117, 35.739515999105144],
            [-103.041356721818005, 35.739433505453839],
            [-103.041553998934006, 35.62248763106507],
            [-103.041554, 35.622486999106371],
            [-103.042617950564988, 35.183157258119515],
            [-103.042711, 35.144734999111527],
            [-103.042738818608015, 34.954142039475059],
            [-103.284149, 34.954171999113662],
            [-103.284317, 34.864989999114663],
            [-103.389798, 34.865428999114656],
            [-103.390274, 34.778121999115655],
            [-103.495646, 34.778574999115641],
            [-103.495653, 34.691392999116651],
            [-103.705788, 34.692445999116636],
            [-103.706088, 34.605009999117655],
            [-103.738166, 34.604669999117654],
            [-103.948778, 34.605057999117662],
            [-104.12888, 34.605200999117656],
            [-104.129143, 34.779336999115642]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "059",
        COUNTYNS: "00974128",
        AFFGEOID: "0500000US36059",
        GEOID: "36059",
        NAME: "Nassau",
        LSAD: "06",
        ALAND: 737191047,
        AWATER: 437282733
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.778958, 40.8117129990666],
            [-73.74806, 40.871720999066312],
            [-73.741197244948594, 40.875854591903703],
            [-73.713674, 40.870098999066322],
            [-73.654372, 40.878198999066278],
            [-73.617571, 40.897897999066188],
            [-73.499941, 40.918165999066105],
            [-73.497351038626277, 40.923182272360464],
            [-73.462259, 40.86670999906633],
            [-73.4385, 40.751310999066881],
            [-73.436006, 40.73945799906695],
            [-73.423253, 40.670919999067273],
            [-73.423976617557202, 40.613244876627064],
            [-73.484868394347075, 40.598754852827724],
            [-73.507325, 40.593410999067657],
            [-73.640902, 40.582822999067709],
            [-73.750621721656302, 40.589319766195388],
            [-73.741661, 40.64219799906742],
            [-73.724874, 40.653446999067363],
            [-73.727814, 40.66322399906732],
            [-73.72807, 40.67160799906727],
            [-73.72563, 40.679587999067238],
            [-73.727047, 40.709496999067092],
            [-73.729176, 40.719166999067042],
            [-73.724722, 40.724313999067014],
            [-73.701633, 40.752492999066881],
            [-73.750295, 40.782477999066735],
            [-73.778958, 40.8117129990666]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "015",
        COUNTYNS: "01804487",
        AFFGEOID: "0500000US72015",
        GEOID: "72015",
        NAME: "Arroyo",
        LSAD: "13",
        ALAND: 38869489,
        AWATER: 53459916
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.082263, 18.032957999443951],
            [-66.057263, 18.040433999443746],
            [-66.017954834139402, 17.974902263054997],
            [-66.024, 17.975895999445473],
            [-66.041704, 17.934940999446564],
            [-66.068103401526088, 17.945604829090875],
            [-66.093554, 18.021142999444258],
            [-66.082263, 18.032957999443951]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "271",
        COUNTYNS: "00356958",
        AFFGEOID: "0500000US13271",
        GEOID: "13271",
        NAME: "Telfair",
        LSAD: "06",
        ALAND: 1132612023,
        AWATER: 17365488
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.205739, 31.900324999153042],
            [-82.942066, 32.124073999149829],
            [-82.934566, 32.153273999149405],
            [-82.927865, 32.135273999149653],
            [-82.887657, 32.07989099915045],
            [-82.720733, 32.000735999151594],
            [-82.645659, 31.918882999152764],
            [-82.825855, 31.81338999915431],
            [-82.836366, 31.81615399915426],
            [-82.868118, 31.782346999154761],
            [-82.995698, 31.780982999154787],
            [-83.138659, 31.814445999154298],
            [-83.177469, 31.847856999153802],
            [-83.204236, 31.884681999153255],
            [-83.205739, 31.900324999153042]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "041",
        COUNTYNS: "01248001",
        AFFGEOID: "0500000US45041",
        GEOID: "45041",
        NAME: "Florence",
        LSAD: "06",
        ALAND: 2071893246,
        AWATER: 9745365
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.072808, 34.086012999123852],
            [-79.809021, 34.252437999121831],
            [-79.749383, 34.245052999121924],
            [-79.724782, 34.296987999121299],
            [-79.657764, 34.30518999912119],
            [-79.633413, 34.297993999121275],
            [-79.601003, 34.29053199912137],
            [-79.54793, 34.23091299912209],
            [-79.571834, 34.183688999122658],
            [-79.515308, 34.097206999123699],
            [-79.51694, 33.993979999124967],
            [-79.324317, 33.799122999127384],
            [-79.496466, 33.775371999127685],
            [-79.635808, 33.818037999127156],
            [-79.687452, 33.804913999127315],
            [-79.87562, 33.885244999126321],
            [-79.945948, 33.885996999126299],
            [-79.974601, 33.94652699912556],
            [-79.893517, 33.988016999125037],
            [-79.903658, 34.002244999124862],
            [-80.000594, 34.04787699912432],
            [-80.02593, 34.073225999123999],
            [-80.072808, 34.086012999123852]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "089",
        COUNTYNS: "01035618",
        AFFGEOID: "0500000US38089",
        GEOID: "38089",
        NAME: "Stark",
        LSAD: "06",
        ALAND: 3457000613,
        AWATER: 14109003
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.230991, 46.980295999059123],
            [-103.032681, 46.980579999059117],
            [-102.525462, 46.981306999059129],
            [-102.144771, 47.010306999059203],
            [-102.096675, 46.981158999059133],
            [-102.096239, 46.717705999058545],
            [-102.096585, 46.631023999058385],
            [-102.928003, 46.630064999058369],
            [-103.231569, 46.629763999058369],
            [-103.230991, 46.980295999059123]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "009",
        COUNTYNS: "00484974",
        AFFGEOID: "0500000US20009",
        GEOID: "20009",
        NAME: "Barton",
        LSAD: "06",
        ALAND: 2318718654,
        AWATER: 13913626
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.032971, 38.696758999079165],
            [-98.486108, 38.696877999079156],
            [-98.479913, 38.681527999079272],
            [-98.480377, 38.521840999080418],
            [-98.479841, 38.260789999082384],
            [-98.912583, 38.261087999082392],
            [-99.032309, 38.261226999082382],
            [-99.032408, 38.348333999081731],
            [-99.032971, 38.696758999079165]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "035",
        COUNTYNS: "01155134",
        AFFGEOID: "0500000US41035",
        GEOID: "41035",
        NAME: "Klamath",
        LSAD: "06",
        ALAND: 15391613763,
        AWATER: 503712593
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.289749096156996, 42.007764729558332],
            [-122.28776, 42.44028699906039],
            [-122.284689, 42.474499999060285],
            [-122.28273, 42.996498999058957],
            [-122.282585, 43.067718999058791],
            [-122.093776, 43.076256999058785],
            [-122.03965, 43.214101999058492],
            [-121.974199, 43.261692999058404],
            [-122.010403, 43.34469699905825],
            [-122.132034, 43.440220999058084],
            [-122.131009, 43.55728399905788],
            [-122.002362, 43.615497999057787],
            [-121.332969, 43.616628999057788],
            [-121.332413, 43.356662999058223],
            [-121.349679, 42.746643999059557],
            [-120.883327, 42.744213999059568],
            [-120.879925710235995, 41.993483259046691],
            [-121.035195, 41.993322999061796],
            [-121.251099, 41.997569999061774],
            [-121.43961, 41.997079999061782],
            [-121.447539998048001, 41.99719003233438],
            [-121.675348, 42.000350999061766],
            [-121.846712, 42.003069999061758],
            [-122.101922, 42.005765999061751],
            [-122.289527, 42.007763999061737],
            [-122.289749096156996, 42.007764729558332]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "111",
        COUNTYNS: "00308372",
        AFFGEOID: "0500000US12111",
        GEOID: "12111",
        NAME: "St. Lucie",
        LSAD: "06",
        ALAND: 1480655532,
        AWATER: 300844721
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.679821, 27.558469999225501],
            [-80.316686611620383, 27.557340023833127],
            [-80.253665, 27.379789999228883],
            [-80.198021352658699, 27.263008802903602],
            [-80.284771, 27.263722999231085],
            [-80.28551, 27.206024999232195],
            [-80.677862, 27.205987999232189],
            [-80.679821, 27.558469999225501]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "141",
        COUNTYNS: "00348209",
        AFFGEOID: "0500000US13141",
        GEOID: "13141",
        NAME: "Hancock",
        LSAD: "06",
        ALAND: 1220058027,
        AWATER: 17479750
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.253461, 33.259289999134289],
            [-83.145856, 33.31004499913363],
            [-83.164207, 33.355029999133052],
            [-83.012853, 33.469177999131574],
            [-82.983657, 33.447676999131843],
            [-82.851954, 33.443542999131914],
            [-82.824105, 33.427626999132109],
            [-82.755846, 33.253439999134386],
            [-82.748311, 33.238347999134568],
            [-82.855046, 33.196426999135134],
            [-82.888866, 33.138602999135884],
            [-83.00874, 33.080848999136641],
            [-83.052197, 33.080681999136644],
            [-83.100838, 33.049863999137067],
            [-83.097649, 33.15153599913571],
            [-83.274108, 33.187237999135242],
            [-83.253461, 33.259289999134289]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "031",
        COUNTYNS: "00395698",
        AFFGEOID: "0500000US16031",
        GEOID: "16031",
        NAME: "Cassia",
        LSAD: "06",
        ALAND: 6644895767,
        AWATER: 39160685
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.285448, 42.417332999060456],
            [-114.069162, 42.417123999060465],
            [-114.067073, 42.514452999060175],
            [-114.000388, 42.526372999060143],
            [-113.931799, 42.535242999060117],
            [-113.778137, 42.556423999060065],
            [-113.699044, 42.51918999906016],
            [-113.654149, 42.536154999060116],
            [-113.557193, 42.656798999059795],
            [-113.472177, 42.669351999059742],
            [-113.373117, 42.687637999059703],
            [-113.3044, 42.628748999059866],
            [-113.236968, 42.625253999059872],
            [-113.175678, 42.589463999059966],
            [-113.002792, 42.588002999059967],
            [-112.999965, 42.327426999060727],
            [-113.000040139624005, 41.998227611600868],
            [-113.000821, 41.99822299906176],
            [-113.249159, 41.996202999061794],
            [-113.496548, 41.993304999061792],
            [-113.817964, 41.988579999061812],
            [-114.041723, 41.993719999061781],
            [-114.281799467219997, 41.994213884819679],
            [-114.285448, 42.417332999060456]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "029",
        COUNTYNS: "01101802",
        AFFGEOID: "0500000US40029",
        GEOID: "40029",
        NAME: "Coal",
        LSAD: "06",
        ALAND: 1338200617,
        AWATER: 12079759
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.513865, 34.505388999118829],
            [-96.512171, 34.68006499911678],
            [-96.406357, 34.680022999116794],
            [-96.406483, 34.767589999115778],
            [-96.092001, 34.767478999115781],
            [-96.092043, 34.680555999116777],
            [-96.091873, 34.506154999118813],
            [-96.145342, 34.506101999118812],
            [-96.145096, 34.418676999119839],
            [-96.40733, 34.418390999119843],
            [-96.513786, 34.419568999119832],
            [-96.513865, 34.505388999118829]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "067",
        COUNTYNS: "00277298",
        AFFGEOID: "0500000US06067",
        GEOID: "06067",
        NAME: "Sacramento",
        LSAD: "06",
        ALAND: 2499039655,
        AWATER: 76217822
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.860477, 38.070606999083857],
            [-121.843228, 38.076654999083793],
            [-121.827916, 38.066222999083884],
            [-121.796395, 38.060846999083928],
            [-121.743164, 38.087228999083727],
            [-121.71213, 38.085519999083736],
            [-121.685645, 38.159643999083158],
            [-121.615413, 38.195695999082886],
            [-121.593273, 38.313088999081984],
            [-121.521641, 38.360103999081637],
            [-121.50509, 38.469562999080814],
            [-121.558069, 38.501875999080575],
            [-121.551866, 38.513805999080489],
            [-121.525178, 38.518961999080453],
            [-121.530767, 38.527905999080389],
            [-121.51129, 38.546289999080258],
            [-121.520965, 38.566204999080107],
            [-121.506269, 38.586304999079964],
            [-121.511566, 38.600903999079861],
            [-121.527667, 38.604603999079828],
            [-121.544567, 38.597703999079876],
            [-121.553609, 38.603325999079836],
            [-121.633774, 38.686163999079241],
            [-121.602894, 38.735837999078889],
            [-121.559868, 38.736301999078883],
            [-121.484396, 38.7345979990789],
            [-121.381658, 38.72779599907895],
            [-121.30883, 38.722789999078977],
            [-121.141009, 38.711979999079063],
            [-121.118617, 38.717117999079015],
            [-121.027507, 38.508291999080527],
            [-121.027084, 38.300251999082093],
            [-121.222512, 38.244405999082502],
            [-121.282267, 38.24983599908245],
            [-121.398193, 38.227426999082631],
            [-121.448142, 38.255007999082423],
            [-121.472138, 38.25965899908239],
            [-121.580022, 38.09441399908367],
            [-121.673065, 38.093517999083666],
            [-121.737824, 38.0266299990842],
            [-121.80127, 38.021621999084239],
            [-121.862462, 38.066029999083895],
            [-121.860477, 38.070606999083857]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "001",
        COUNTYNS: "01713506",
        AFFGEOID: "0500000US24001",
        GEOID: "24001",
        NAME: "Allegany",
        LSAD: "06",
        ALAND: 1093331712,
        AWATER: 14931771
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.066892, 39.480622999073915],
            [-78.928416129888902, 39.722998586513341],
            [-78.808299529621593, 39.723070955349328],
            [-78.723578, 39.723121999072433],
            [-78.380477064564005, 39.722704137912444],
            [-78.342833518574395, 39.722658291998926],
            [-78.313325, 39.692473999072618],
            [-78.332788021499098, 39.628527595571803],
            [-78.382959, 39.622245999073044],
            [-78.438179, 39.563523999073404],
            [-78.460951, 39.525986999073638],
            [-78.468269595002894, 39.526224269545338],
            [-78.590654, 39.530191999073608],
            [-78.655036171546698, 39.544382240157219],
            [-78.707098, 39.555856999073455],
            [-78.73905, 39.609696999073122],
            [-78.77114, 39.638386999072949],
            [-78.851016, 39.554043999073464],
            [-78.942618, 39.479613999073926],
            [-78.956751, 39.440263999074176],
            [-79.035623, 39.473343999073961],
            [-79.067447408072397, 39.472808698202172],
            [-79.066892, 39.480622999073915]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "043",
        COUNTYNS: "00695746",
        AFFGEOID: "0500000US28043",
        GEOID: "28043",
        NAME: "Grenada",
        LSAD: "06",
        ALAND: 1093252402,
        AWATER: 70698246
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.136491, 33.809318999127264],
            [-89.926294, 33.823853999127074],
            [-89.926099, 33.897956999126158],
            [-89.769296, 33.897136999126168],
            [-89.76905, 33.867333999126529],
            [-89.507353, 33.867468999126537],
            [-89.507135, 33.721818999128359],
            [-89.506872, 33.677604999128917],
            [-89.78689, 33.676726999128931],
            [-90.127775, 33.676955999128921],
            [-90.136787, 33.721647999128358],
            [-90.136491, 33.809318999127264]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "033",
        COUNTYNS: "01718426",
        AFFGEOID: "0500000US54033",
        GEOID: "54033",
        NAME: "Harrison",
        LSAD: "06",
        ALAND: 1077454921,
        AWATER: 1320319
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.531808, 39.360120999074688],
            [-80.544521, 39.429119999074238],
            [-80.494085, 39.469598999073987],
            [-80.282776, 39.435796999074206],
            [-80.197286, 39.393185999074475],
            [-80.199504, 39.270689999075259],
            [-80.166387, 39.242282999075442],
            [-80.224687, 39.170976999075911],
            [-80.227173, 39.112791999076293],
            [-80.300209, 39.103856999076356],
            [-80.577525, 39.16764299907593],
            [-80.596126, 39.167068999075937],
            [-80.528756, 39.195705999075749],
            [-80.603684, 39.291040999075122],
            [-80.531808, 39.360120999074688]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "099",
        COUNTYNS: "00695772",
        AFFGEOID: "0500000US28099",
        GEOID: "28099",
        NAME: "Neshoba",
        LSAD: "06",
        ALAND: 1476641123,
        AWATER: 3830971
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.319163, 32.931653999138639],
            [-89.017723, 32.93132599913865],
            [-88.930505, 32.91923999913881],
            [-88.914413, 32.926599999138709],
            [-88.914516, 32.576954999143467],
            [-89.317588, 32.576457999143479],
            [-89.319163, 32.931653999138639]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "003",
        COUNTYNS: "00198117",
        AFFGEOID: "0500000US08003",
        GEOID: "08003",
        NAME: "Alamosa",
        LSAD: "06",
        ALAND: 1871465873,
        AWATER: 1847609
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.039331, 37.400851999089355],
            [-106.038413, 37.748352999086443],
            [-105.457255, 37.751463999086411],
            [-105.501755, 37.597570999087679],
            [-105.485516, 37.57789899908785],
            [-105.500567, 37.560140999087992],
            [-105.743339, 37.356797999089729],
            [-106.038912, 37.356952999089728],
            [-106.039331, 37.400851999089355]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "055",
        COUNTYNS: "01557645",
        AFFGEOID: "0500000US54055",
        GEOID: "54055",
        NAME: "Mercer",
        LSAD: "06",
        ALAND: 1085113561,
        AWATER: 4434929
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.311201, 37.424508999089163],
            [-81.291455, 37.414390999089228],
            [-81.307387, 37.459485999088855],
            [-81.222121, 37.510415999088416],
            [-81.198869, 37.575532999087869],
            [-81.09463, 37.588657999087765],
            [-80.866887, 37.434588999089073],
            [-80.858148116819805, 37.421006905810685],
            [-80.865148, 37.419926999089192],
            [-80.883248, 37.383932999089502],
            [-80.835479, 37.33482399908992],
            [-80.919259, 37.306162999090176],
            [-80.980845256892806, 37.300852815280727],
            [-80.996013, 37.29954499909023],
            [-81.112596, 37.278496999090407],
            [-81.225104, 37.234873999090794],
            [-81.362156, 37.337686999089897],
            [-81.311201, 37.424508999089163]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "15",
        COUNTYFP: "001",
        COUNTYNS: "00365280",
        AFFGEOID: "0500000US15001",
        GEOID: "15001",
        NAME: "Hawaii",
        LSAD: "06",
        ALAND: 10433637860,
        AWATER: 2739470030
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-156.049651, 19.78045199939848],
            [-156.006267, 19.817579999397537],
            [-155.976651, 19.850529999396699],
            [-155.949251, 19.857033999396535],
            [-155.915662, 19.887125999395774],
            [-155.892533, 19.932161999394637],
            [-155.856588, 19.968884999393708],
            [-155.831948, 19.982774999393349],
            [-155.825473, 20.025943999392265],
            [-155.850385, 20.062505999391345],
            [-155.890646, 20.123575999389807],
            [-155.90278, 20.177072999388464],
            [-155.890663, 20.255239999386504],
            [-155.853293, 20.271547999386097],
            [-155.798884, 20.254114999386534],
            [-155.737004, 20.22277299938732],
            [-155.704331, 20.191694999388101],
            [-155.637459, 20.15305099938907],
            [-155.598033, 20.124538999389792],
            [-155.558933, 20.131569999389608],
            [-155.502561, 20.114154999390049],
            [-155.387578, 20.067118999391226],
            [-155.270316, 20.014524999392552],
            [-155.166625, 19.937889999394486],
            [-155.124618, 19.89728799939552],
            [-155.086341, 19.855398999396574],
            [-155.091216, 19.776367999398584],
            [-155.087118, 19.728012999399819],
            [-155.045382, 19.739823999399508],
            [-155.006423, 19.739285999399524],
            [-154.981102, 19.690686999400768],
            [-154.974342, 19.633200999402227],
            [-154.947106, 19.604855999402954],
            [-154.852618, 19.549171999404372],
            [-154.814417, 19.530089999404861],
            [-154.816009, 19.500647999405615],
            [-154.876618, 19.433222999407338],
            [-154.944185, 19.381851999408656],
            [-155.020537, 19.331316999409957],
            [-155.113272, 19.290612999411007],
            [-155.159635, 19.268374999411581],
            [-155.205892, 19.26090699941177],
            [-155.264619, 19.274212999411425],
            [-155.31337, 19.250697999412033],
            [-155.360631, 19.208929999413112],
            [-155.390701, 19.201170999413314],
            [-155.453516, 19.151951999414578],
            [-155.505281, 19.137907999414946],
            [-155.555326, 19.069376999416722],
            [-155.590697, 19.007672999418315],
            [-155.613966, 18.970398999419288],
            [-155.638054, 18.941722999420033],
            [-155.672005, 18.917465999420664],
            [-155.726043, 18.969436999419315],
            [-155.806109, 19.013966999418159],
            [-155.88155, 19.036643999417571],
            [-155.914216, 19.09914699941595],
            [-155.912069, 19.179113999413879],
            [-155.902565, 19.258426999411839],
            [-155.890842, 19.298904999410787],
            [-155.888701, 19.348030999409524],
            [-155.909087, 19.4154549994078],
            [-155.924732, 19.453909999406811],
            [-155.95149, 19.486648999405968],
            [-155.96935, 19.555962999404201],
            [-155.978206, 19.608158999402871],
            [-155.997728, 19.642815999401982],
            [-156.028982, 19.650097999401797],
            [-156.033326, 19.669229999401306],
            [-156.064364, 19.730765999399743],
            [-156.049651, 19.78045199939848]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "081",
        COUNTYNS: "01804521",
        AFFGEOID: "0500000US72081",
        GEOID: "72081",
        NAME: "Lares",
        LSAD: "13",
        ALAND: 159155302,
        AWATER: 485277
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.909969, 18.302810999436787],
            [-66.893339, 18.367088999435087],
            [-66.876876, 18.356773999435362],
            [-66.824223, 18.342997999435724],
            [-66.826128, 18.323381999436243],
            [-66.827209, 18.244095999438343],
            [-66.817271, 18.230335999438704],
            [-66.832736, 18.227989999438766],
            [-66.827327, 18.171241999440269],
            [-66.83676, 18.170552999440293],
            [-66.863991, 18.186747999439859],
            [-66.897964, 18.187743999439832],
            [-66.907236, 18.253089999438107],
            [-66.909969, 18.302810999436787]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "017",
        COUNTYNS: "00695733",
        AFFGEOID: "0500000US28017",
        GEOID: "28017",
        NAME: "Chickasaw",
        LSAD: "06",
        ALAND: 1299609263,
        AWATER: 6380767
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.19004, 33.811709999127238],
            [-89.137876, 33.81214399912723],
            [-89.139158, 34.074115999123983],
            [-88.824523, 34.074947999123985],
            [-88.718964, 34.074520999123983],
            [-88.719979, 33.811329999127238],
            [-88.931176, 33.812373999127225],
            [-88.931604, 33.75431199912795],
            [-89.034498, 33.739429999128134],
            [-89.191781, 33.73860199912815],
            [-89.19004, 33.811709999127238]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "043",
        COUNTYNS: "01550028",
        AFFGEOID: "0500000US54043",
        GEOID: "54043",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 1131925075,
        AWATER: 4023478
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.192314, 38.046313999084042],
            [-82.283316, 38.141935999083302],
            [-82.264849, 38.229198999082627],
            [-82.220797, 38.310410999082009],
            [-82.047128, 38.37443199908153],
            [-82.048589, 38.290137999082162],
            [-81.997406, 38.26660499908234],
            [-81.915197, 38.325596999081888],
            [-81.812825, 38.311406999082003],
            [-81.83347, 38.209569999082767],
            [-81.878779, 38.137201999083338],
            [-81.953263, 38.118877999083473],
            [-81.932507, 38.025355999084205],
            [-81.988776, 38.02488399908421],
            [-82.190658, 37.974777999084615],
            [-82.306391, 37.943639999084851],
            [-82.192314, 38.046313999084042]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "159",
        COUNTYNS: "00659524",
        AFFGEOID: "0500000US27159",
        GEOID: "27159",
        NAME: "Wadena",
        LSAD: "06",
        ALAND: 1388921383,
        AWATER: 18038938
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.16301, 46.80471999905874],
            [-94.787126, 46.803942999058734],
            [-94.779121, 46.394214999057951],
            [-94.731442, 46.368604999057915],
            [-95.15557, 46.36887999905791],
            [-95.15554, 46.629939999058379],
            [-95.16371, 46.717639999058555],
            [-95.16301, 46.80471999905874]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "057",
        COUNTYNS: "00198144",
        AFFGEOID: "0500000US08057",
        GEOID: "08057",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 4179530271,
        AWATER: 18750017
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.860377948665004, 41.000718628302813],
            [-106.321169215913997, 40.998215010133734],
            [-106.217573, 40.997733999065737],
            [-106.190554078708999, 40.997746611640132],
            [-106.185307, 40.933972999066029],
            [-106.047971, 40.80464299906663],
            [-105.910892, 40.566419999067804],
            [-105.907857, 40.515492999068059],
            [-105.854926, 40.486251999068209],
            [-105.891754, 40.476660999068258],
            [-105.905068, 40.39881599906866],
            [-105.959659, 40.348148999068918],
            [-106.018622, 40.373294999068783],
            [-106.230074, 40.323381999069049],
            [-106.351456, 40.355836999068892],
            [-106.44342, 40.346532999068927],
            [-106.489842, 40.411577999068584],
            [-106.612321, 40.379382999068753],
            [-106.652112, 40.445230999068421],
            [-106.653113, 40.494731999068165],
            [-106.706149, 40.616596999067546],
            [-106.636922, 40.789488999066698],
            [-106.654541, 40.849116999066418],
            [-106.691669, 40.888191999066237],
            [-106.739341, 40.870842999066312],
            [-106.82503, 40.932118999066034],
            [-106.852349, 40.925182999066067],
            [-106.860377948665004, 41.000718628302813]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "055",
        COUNTYNS: "01213670",
        AFFGEOID: "0500000US42055",
        GEOID: "42055",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 2000052118,
        AWATER: 1544300
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.098970696166688, 39.722466193500537],
            [-78.0027, 39.826598999071827],
            [-77.920952, 40.000449999070817],
            [-77.864489, 40.061567999070476],
            [-77.768325, 40.204128999069681],
            [-77.70303, 40.263575999069374],
            [-77.671761, 40.289824999069225],
            [-77.602717, 40.22819999906956],
            [-77.614665, 40.198548999069722],
            [-77.532481, 40.048748999070554],
            [-77.454109, 39.972431999070977],
            [-77.471085, 39.944076999071143],
            [-77.45943341952389, 39.720229170717438],
            [-77.469145003680396, 39.720229001322451],
            [-77.469274, 39.720228999072454],
            [-77.768644, 39.721537999072432],
            [-78.075861, 39.722447999072443],
            [-78.098970696166688, 39.722466193500537]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "071",
        COUNTYNS: "00277300",
        AFFGEOID: "0500000US06071",
        GEOID: "06071",
        NAME: "San Bernardino",
        LSAD: "06",
        ALAND: 51948123813,
        AWATER: 123845007
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.802539, 33.97555099912519],
            [-117.767752, 34.019428999124649],
            [-117.744066, 34.019807999124659],
            [-117.711067, 34.07953599912392],
            [-117.70429, 34.095054999123732],
            [-117.693545, 34.1216269991234],
            [-117.677405, 34.166102999122877],
            [-117.646374, 34.289169999121384],
            [-117.655235, 34.397221999120099],
            [-117.667292, 34.822525999115143],
            [-117.632011, 34.82226999911515],
            [-117.630126, 35.564070999106981],
            [-117.616195, 35.680855999105752],
            [-117.651986, 35.709933999105452],
            [-117.632996, 35.797250999104534],
            [-117.251015, 35.795003999104573],
            [-116.277263, 35.793393999104573],
            [-115.735891, 35.793619999104585],
            [-115.648032019624992, 35.809629211707311],
            [-115.647683, 35.8093579991044],
            [-115.404537, 35.617604999106405],
            [-115.303743, 35.538206999107267],
            [-115.160068, 35.424128999108483],
            [-115.043812, 35.332011999109476],
            [-114.804249, 35.139688999111584],
            [-114.633487, 35.001856999113116],
            [-114.629769, 34.943039999113779],
            [-114.634382, 34.872889999114562],
            [-114.576452, 34.81529999911524],
            [-114.490971, 34.724847999116271],
            [-114.465246, 34.691201999116657],
            [-114.422382, 34.580710999117947],
            [-114.378223, 34.51652099911869],
            [-114.378852, 34.450375999119466],
            [-114.335372, 34.450037999119473],
            [-114.264317, 34.401328999120054],
            [-114.172845, 34.344978999120713],
            [-114.14093, 34.305918999121182],
            [-114.140817151280004, 34.30312751300292],
            [-114.139055, 34.25953799912174],
            [-114.17805, 34.239968999121977],
            [-114.229715, 34.186927999122616],
            [-114.292806, 34.166724999122856],
            [-114.348052, 34.134457999123242],
            [-114.405941, 34.111539999123529],
            [-114.428026, 34.09278699912376],
            [-114.430091138520012, 34.078931405403431],
            [-115.316065, 34.077842999123945],
            [-115.316212, 34.034109999124482],
            [-116.300631, 34.032543999124499],
            [-116.929558, 34.034112999124481],
            [-116.929357, 34.004913999124838],
            [-117.104737, 34.004014999124841],
            [-117.121293, 34.00391599912485],
            [-117.225373, 34.018714999124668],
            [-117.371588, 34.019398999124654],
            [-117.375278, 34.033874999124492],
            [-117.558269, 34.018905999124676],
            [-117.610947, 33.92511399912582],
            [-117.655375, 33.924342999125834],
            [-117.673749, 33.87083099912649],
            [-117.783287, 33.946410999125554],
            [-117.802539, 33.97555099912519]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "031",
        COUNTYNS: "00198131",
        AFFGEOID: "0500000US08031",
        GEOID: "08031",
        NAME: "Denver",
        LSAD: "06",
        ALAND: 397057845,
        AWATER: 4236701
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.109927, 39.626987999073016],
            [-105.053256, 39.667784999072758],
            [-105.053246, 39.791062999072032],
            [-104.79091, 39.798249999071984],
            [-104.73155, 39.898610999071401],
            [-104.64037, 39.906240999071358],
            [-104.600435, 39.899458999071406],
            [-104.619938, 39.822420999071845],
            [-104.76247, 39.822979999071855],
            [-104.734601, 39.769180999072162],
            [-104.884646, 39.740155999072336],
            [-104.847733, 39.65678799907284],
            [-104.913407, 39.624082999073039],
            [-104.913257, 39.638690999072949],
            [-104.931703, 39.653043999072857],
            [-104.955903, 39.652997999072859],
            [-104.973486, 39.667683999072764],
            [-104.999906, 39.667883999072778],
            [-105.015696, 39.678683999072703],
            [-105.0158, 39.660507999072806],
            [-105.032038, 39.657086999072817],
            [-105.034752, 39.633589999072974],
            [-105.034822, 39.627808999073018],
            [-105.053439, 39.621422999073047],
            [-105.096955, 39.617968999073064],
            [-105.109927, 39.626987999073016]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "057",
        COUNTYNS: "01804509",
        AFFGEOID: "0500000US72057",
        GEOID: "72057",
        NAME: "Guayama",
        LSAD: "13",
        ALAND: 168328028,
        AWATER: 108327763
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.205592, 18.012979999444486],
            [-66.166145, 18.05006299944349],
            [-66.138987, 18.036916999443843],
            [-66.129031, 18.088636999442468],
            [-66.053146, 18.107292999441974],
            [-66.089399, 18.056255999443326],
            [-66.082263, 18.032957999443951],
            [-66.093554, 18.021142999444258],
            [-66.068103401526088, 17.945604829090875],
            [-66.098628, 17.957934999445946],
            [-66.155387, 17.929405999446708],
            [-66.220530693979995, 17.917810380391824],
            [-66.205592, 18.012979999444486]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "033",
        COUNTYNS: "01101804",
        AFFGEOID: "0500000US40033",
        GEOID: "40033",
        NAME: "Cotton",
        LSAD: "06",
        ALAND: 1638566730,
        AWATER: 24095321
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.661956, 34.405947999119995],
            [-98.504988, 34.42045199911982],
            [-98.295928, 34.449042999119477],
            [-98.24412, 34.50712099911879],
            [-98.142148, 34.506993999118798],
            [-98.139293, 34.489278999119009],
            [-98.139226, 34.289774999121377],
            [-98.138486025746403, 34.141206018834268],
            [-98.16912, 34.114170999123495],
            [-98.225282, 34.127244999123334],
            [-98.31875, 34.146420999123102],
            [-98.364023, 34.157108999122975],
            [-98.398441, 34.128455999123325],
            [-98.414426, 34.085073999123857],
            [-98.423533, 34.081949475129797],
            [-98.475066, 34.064268999124103],
            [-98.5282, 34.094960999123735],
            [-98.577136, 34.148961999123081],
            [-98.610351781644695, 34.156209938619689],
            [-98.60966, 34.333719999120852],
            [-98.662004, 34.333430999120857],
            [-98.661956, 34.405947999119995]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "029",
        COUNTYNS: "00581300",
        AFFGEOID: "0500000US23029",
        GEOID: "23029",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 6637326498,
        AWATER: 1800003936
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.105017, 44.93908799905676],
            [-67.981851, 44.95588499905675],
            [-68.054985, 45.252576999056814],
            [-67.93976, 45.267844999056827],
            [-68.048012, 45.639896999057029],
            [-67.902264, 45.680114999057082],
            [-67.802894295077081, 45.67892788452027],
            [-67.803313, 45.677885999057061],
            [-67.710464, 45.679371999057068],
            [-67.675417, 45.630958999057022],
            [-67.631762, 45.621408999057024],
            [-67.534919, 45.595427999057009],
            [-67.455406, 45.604664999057007],
            [-67.423646, 45.572152999056989],
            [-67.417417, 45.50198499905693],
            [-67.476855, 45.497239999056937],
            [-67.484328, 45.451954999056902],
            [-67.427243, 45.373689999056865],
            [-67.460554, 45.300378999056832],
            [-67.480256, 45.268184999056821],
            [-67.453473, 45.24112699905681],
            [-67.390579, 45.15411399905679],
            [-67.339869, 45.125593999056775],
            [-67.298209, 45.146671999056771],
            [-67.271076, 45.191080999056794],
            [-67.203933, 45.171406999056785],
            [-67.161247, 45.16287899905678],
            [-67.112414, 45.112322999056772],
            [-67.090786, 45.068720999056772],
            [-67.082074, 45.029607999056758],
            [-67.033474, 44.939922999056762],
            [-66.983558, 44.903276999056772],
            [-66.99296, 44.849180999056777],
            [-66.949895, 44.817418999056777],
            [-67.02615, 44.768198999056771],
            [-67.073439, 44.741956999056789],
            [-67.116745, 44.706105999056795],
            [-67.169857, 44.662104999056815],
            [-67.234275, 44.637200999056816],
            [-67.293403, 44.599264999056828],
            [-67.368269, 44.624671999056822],
            [-67.398987, 44.602630999056821],
            [-67.448513, 44.600321999056824],
            [-67.491751, 44.556122999056846],
            [-67.521168, 44.509909999056866],
            [-67.503208, 44.476917999056901],
            [-67.579726, 44.429130999056909],
            [-67.634806, 44.48705399905689],
            [-67.653123, 44.525822999056864],
            [-67.70668, 44.501974999056884],
            [-67.793589, 44.494778999056891],
            [-67.837938, 44.464669999056902],
            [-67.855108, 44.419433999056928],
            [-67.899571, 44.39407799905694],
            [-67.936531, 44.41118699905693],
            [-67.943843957055208, 44.407015789899944],
            [-67.962624, 44.423413999056926],
            [-68.017219, 44.589086999056832],
            [-68.105017, 44.93908799905676]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "117",
        COUNTYNS: "00198174",
        AFFGEOID: "0500000US08117",
        GEOID: "08117",
        NAME: "Summit",
        LSAD: "06",
        ALAND: 1575590697,
        AWATER: 28283469
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.434508, 39.924913999071258],
            [-106.252027, 39.914711999071315],
            [-106.083711, 39.805963999071935],
            [-106.012987, 39.686340999072655],
            [-105.924618, 39.698971999072576],
            [-105.77601, 39.609266999073135],
            [-105.829662, 39.564864999073393],
            [-105.817631, 39.538309999073562],
            [-105.966786, 39.438034999074176],
            [-106.021966, 39.361712999074676],
            [-106.135529, 39.379545999074558],
            [-106.206732, 39.379635999074551],
            [-106.251638, 39.465038999074025],
            [-106.176464, 39.635721999072963],
            [-106.385478, 39.767163999072174],
            [-106.434508, 39.924913999071258]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "029",
        COUNTYNS: "00933057",
        AFFGEOID: "0500000US35029",
        GEOID: "35029",
        NAME: "Luna",
        LSAD: "06",
        ALAND: 7679944778,
        AWATER: 433660
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-108.229343, 32.516836999144296],
            [-107.923766, 32.517415999144283],
            [-107.923997, 32.60437899914308],
            [-107.608485, 32.605448999143078],
            [-107.299631, 32.605369999143072],
            [-107.296792990414005, 31.783625336262741],
            [-107.422246, 31.783598999154748],
            [-108.208394, 31.783598999154748],
            [-108.217134, 31.820474999154207],
            [-108.217143, 31.864138999153571],
            [-108.229951, 32.079869999150453],
            [-108.229343, 32.516836999144296]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "071",
        COUNTYNS: "01101823",
        AFFGEOID: "0500000US40071",
        GEOID: "40071",
        NAME: "Kay",
        LSAD: "06",
        ALAND: 2382077972,
        AWATER: 65369682
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.462346029864094, 36.998824237889799],
            [-97.384925, 36.998842999092894],
            [-97.147720966922691, 36.998972334714388],
            [-97.100652, 36.9989979990929],
            [-96.749838, 36.99898799909289],
            [-96.752389, 36.782090999094876],
            [-96.889475, 36.751229999095159],
            [-96.931943, 36.686096999095781],
            [-97.064107, 36.684206999095785],
            [-97.057414, 36.593646999096642],
            [-97.461603, 36.593636999096645],
            [-97.462459, 36.593626999096635],
            [-97.462346029864094, 36.998824237889799]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "027",
        COUNTYNS: "01718644",
        AFFGEOID: "0500000US54027",
        GEOID: "54027",
        NAME: "Hampshire",
        LSAD: "06",
        ALAND: 1658614732,
        AWATER: 10922070
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.850723, 39.331092999074869],
            [-78.767447, 39.447237999074126],
            [-78.655036171546698, 39.544382240157219],
            [-78.590654, 39.530191999073608],
            [-78.468269595002894, 39.526224269545338],
            [-78.457749, 39.510899999073729],
            [-78.347087, 39.466011999074013],
            [-78.337133, 39.409168999074367],
            [-78.34048, 39.35349199907472],
            [-78.401813, 39.276753999075218],
            [-78.40498, 39.238005999075469],
            [-78.428697, 39.187216999075794],
            [-78.413943, 39.158414999075987],
            [-78.508132, 39.088629999076453],
            [-78.561282, 39.131443999076168],
            [-78.623555, 39.118508999076262],
            [-78.979898, 39.237623999075467],
            [-78.850723, 39.331092999074869]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "055",
        COUNTYNS: "00695751",
        AFFGEOID: "0500000US28055",
        GEOID: "28055",
        NAME: "Issaquena",
        LSAD: "06",
        ALAND: 1069782900,
        AWATER: 73507441
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.159606098012986, 33.011242320444374],
            [-90.965433, 33.010544999137579],
            [-90.914337, 33.007696999137622],
            [-90.963753, 32.838132999139901],
            [-90.964864, 32.662797999142278],
            [-90.723159, 32.662127999142299],
            [-90.691405, 32.636283999142648],
            [-90.720102, 32.61885799914289],
            [-90.728344, 32.566264999143613],
            [-90.860566, 32.57517299914349],
            [-90.861364, 32.442114999145332],
            [-90.963381, 32.41118499914576],
            [-90.964043, 32.575604999143486],
            [-91.048760427763696, 32.572797039564932],
            [-91.049312, 32.573623999143507],
            [-91.055293104949897, 32.578983500220545],
            [-91.079506, 32.600679999143139],
            [-91.098762, 32.685290999141984],
            [-91.056999, 32.725579999141438],
            [-91.113652, 32.739969999141238],
            [-91.157614, 32.776032999140739],
            [-91.161669, 32.812464999140246],
            [-91.13789, 32.848974999139749],
            [-91.070602, 32.88865899913921],
            [-91.072075, 32.937831999138552],
            [-91.134414, 32.980532999137985],
            [-91.166073, 33.004105999137671],
            [-91.159606098012986, 33.011242320444374]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "019",
        COUNTYNS: "01674818",
        AFFGEOID: "0500000US51019",
        GEOID: "51019",
        NAME: "Bedford",
        LSAD: "06",
        ALAND: 1968605215,
        AWATER: 42120242
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.847476, 37.30935199909014],
            [-79.788151, 37.351115999089778],
            [-79.778616, 37.381782999089516],
            [-79.792185, 37.38082199908952],
            [-79.814203, 37.403653999089322],
            [-79.79049, 37.408789999089286],
            [-79.719076, 37.448956999088942],
            [-79.69006, 37.476373999088707],
            [-79.658798, 37.485009999088632],
            [-79.634206, 37.457020999088869],
            [-79.626124, 37.455283999088891],
            [-79.588526, 37.449595999088949],
            [-79.498699, 37.532796999088234],
            [-79.434285, 37.571984999087896],
            [-79.436947, 37.617056999087517],
            [-79.34167, 37.518014999088358],
            [-79.185702, 37.465819999088801],
            [-79.266986, 37.447867999088963],
            [-79.257955, 37.355815999089742],
            [-79.330477, 37.238417999090771],
            [-79.442859, 37.05586699909238],
            [-79.479921, 37.006034999092833],
            [-79.594069, 37.042205999092502],
            [-79.670485, 37.149620999091546],
            [-79.718275, 37.189719999091196],
            [-79.847217, 37.225405999090874],
            [-79.858405, 37.267154999090508],
            [-79.847476, 37.30935199909014]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "207",
        COUNTYNS: "00516950",
        AFFGEOID: "0500000US21207",
        GEOID: "21207",
        NAME: "Russell",
        LSAD: "06",
        ALAND: 656969509,
        AWATER: 75674970
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.232026, 36.925068999093568],
            [-85.205831, 36.996861999092921],
            [-85.043316, 37.186027999091223],
            [-84.954406, 37.102270999091971],
            [-84.901112, 37.116296999091837],
            [-84.905778, 37.04718699909246],
            [-84.835712, 36.997611999092911],
            [-84.945527, 36.959746999093248],
            [-84.960878, 36.916337999093642],
            [-85.064307, 36.858742999094176],
            [-85.118411, 36.827333999094463],
            [-85.235046, 36.88567299909392],
            [-85.215405, 36.854466999094214],
            [-85.27495, 36.897900999093807],
            [-85.232026, 36.925068999093568]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "053",
        COUNTYNS: "00516873",
        AFFGEOID: "0500000US21053",
        GEOID: "21053",
        NAME: "Clinton",
        LSAD: "06",
        ALAND: 510864294,
        AWATER: 21164151
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.295812485727481, 36.626149558463837],
            [-85.246357, 36.744293999095227],
            [-85.215405, 36.854466999094214],
            [-85.235046, 36.88567299909392],
            [-85.118411, 36.827333999094463],
            [-85.064307, 36.858742999094176],
            [-85.004099, 36.756236999095123],
            [-84.999464, 36.624554999096354],
            [-84.974868435837408, 36.61458335841364],
            [-85.096128, 36.622482999096356],
            [-85.276287947499398, 36.626157539899737],
            [-85.290627, 36.626449999096337],
            [-85.295812485727481, 36.626149558463837]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "083",
        COUNTYNS: "00465230",
        AFFGEOID: "0500000US19083",
        GEOID: "19083",
        NAME: "Hardin",
        LSAD: "06",
        ALAND: 1474507918,
        AWATER: 1773877
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.499485, 42.557699999060063],
            [-93.027, 42.55680999906005],
            [-93.002902, 42.470637999060294],
            [-93.001674, 42.209266999061072],
            [-93.231722, 42.208885999061074],
            [-93.463043, 42.209301999061083],
            [-93.462635, 42.470801999060299],
            [-93.499485, 42.557699999060063]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "035",
        COUNTYNS: "00161543",
        AFFGEOID: "0500000US01035",
        GEOID: "01035",
        NAME: "Conecuh",
        LSAD: "06",
        ALAND: 2201948618,
        AWATER: 6643480
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.166581, 31.519560999158639],
            [-87.135051, 31.642416999156822],
            [-87.0511, 31.718339999155702],
            [-86.905899, 31.753034999155187],
            [-86.906769, 31.632670999156961],
            [-86.839279, 31.525364999158562],
            [-86.701554, 31.523945999158574],
            [-86.662082, 31.402797999160377],
            [-86.702446, 31.345396999161249],
            [-86.700282, 31.192216999163577],
            [-86.772519, 31.202242999163417],
            [-86.763961, 31.261292999162521],
            [-87.427455, 31.26038599916253],
            [-87.166581, 31.519560999158639]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "005",
        COUNTYNS: "00025443",
        AFFGEOID: "0500000US04005",
        GEOID: "04005",
        NAME: "Coconino",
        LSAD: "06",
        ALAND: 48222181220,
        AWATER: 110085803
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.319971, 36.096768999101492],
            [-113.224919, 36.09114399910154],
            [-113.196751, 36.152948999100936],
            [-113.13708, 36.166423999100793],
            [-113.009038, 36.239039999100065],
            [-112.902949, 36.253760999099924],
            [-112.865033, 36.282380999099651],
            [-112.792436, 36.28503599909962],
            [-112.723991, 36.34599199909902],
            [-112.681571, 36.341661999099067],
            [-112.62949, 36.391610999098567],
            [-112.623017, 36.474735999097774],
            [-112.660059, 36.543559999097127],
            [-112.620946, 36.595941999096624],
            [-112.638679, 36.674477999095878],
            [-112.600381, 36.831927999094418],
            [-112.529299, 36.952436999093322],
            [-112.538571, 37.000744127974883],
            [-112.35769, 37.00102499909287],
            [-111.412783987872999, 37.001477686248876],
            [-111.405869, 37.001480999092863],
            [-111.278286, 37.000464999092891],
            [-111.066496, 37.002388999092865],
            [-110.75069, 37.003196999092864],
            [-110.750686478927989, 37.003196933818053],
            [-110.7509, 35.030950999112797],
            [-110.750678, 34.263313999121699],
            [-110.783111, 34.299860999121258],
            [-110.848059, 34.267204999121645],
            [-110.958863, 34.314468999121083],
            [-111.096661, 34.396430999120113],
            [-111.147094, 34.375184999120364],
            [-111.250302, 34.453273999119432],
            [-111.324303, 34.42259399911979],
            [-111.397229, 34.42606199911976],
            [-111.426854, 34.385992999120234],
            [-111.435634, 34.446102999119525],
            [-111.512419, 34.415659999119882],
            [-111.472046, 34.462264999119327],
            [-111.501724, 34.497936999118913],
            [-111.554681, 34.471732999119205],
            [-111.552106, 34.802519999115376],
            [-111.778291, 34.803550999115359],
            [-111.777815, 34.981004999113352],
            [-112.00807, 34.975897999113414],
            [-112.334536, 34.973023999113444],
            [-112.335716, 35.14827899911149],
            [-112.4423, 35.149407999111475],
            [-112.440751, 35.260467999110247],
            [-112.541805, 35.236337999110503],
            [-112.694846, 35.311585999109695],
            [-112.754838, 35.306300999109752],
            [-112.95341, 35.356109999109215],
            [-112.975855, 35.392903999108817],
            [-113.148055, 35.478594999107891],
            [-113.174458, 35.5064449991076],
            [-113.334161, 35.528036999107364],
            [-113.334024, 35.803030999104486],
            [-113.310523, 35.864349999103851],
            [-113.33485, 35.91385899910334],
            [-113.317122, 35.961384999102862],
            [-113.354176, 36.04036799910206],
            [-113.319971, 36.096768999101492]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "029",
        COUNTYNS: "00882279",
        AFFGEOID: "0500000US34029",
        GEOID: "34029",
        NAME: "Ocean",
        LSAD: "06",
        ALAND: 1628663096,
        AWATER: 740751569
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.553105, 40.079129999070382],
            [-74.40671, 40.172401999069862],
            [-74.260572, 40.166483999069897],
            [-74.154304, 40.099617999070269],
            [-74.107794, 40.136925999070051],
            [-74.034962382263487, 40.102584305018446],
            [-74.064135, 39.979156999070938],
            [-74.077247, 39.910990999071331],
            [-74.090945, 39.799977999071977],
            [-74.099618914325191, 39.763784360567996],
            [-74.101443, 39.756172999072234],
            [-74.190974, 39.625117999073034],
            [-74.2910153361548, 39.508369796571536],
            [-74.380796, 39.498536999073814],
            [-74.417393, 39.557254999073443],
            [-74.39812, 39.576574999073323],
            [-74.389708, 39.77328299907213],
            [-74.434223, 39.855156999071646],
            [-74.553105, 40.079129999070382]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "217",
        COUNTYNS: "00516955",
        AFFGEOID: "0500000US21217",
        GEOID: "21217",
        NAME: "Taylor",
        LSAD: "06",
        ALAND: 689791858,
        AWATER: 27162461
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.583497, 37.469916999088767],
            [-85.498434, 37.482789999088645],
            [-85.46625, 37.4655949990888],
            [-85.340446, 37.469511999088766],
            [-85.246759, 37.420204999089187],
            [-85.128685, 37.453190999088903],
            [-85.073772, 37.413668999089253],
            [-85.131162, 37.384264999089503],
            [-85.165174, 37.31039599909014],
            [-85.192542, 37.272138999090458],
            [-85.312291, 37.246404999090693],
            [-85.352771, 37.19243199909117],
            [-85.478081, 37.363672999089665],
            [-85.585163, 37.432851999089088],
            [-85.583497, 37.469916999088767]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "161",
        COUNTYNS: "01383866",
        AFFGEOID: "0500000US48161",
        GEOID: "48161",
        NAME: "Freestone",
        LSAD: "06",
        ALAND: 2273311877,
        AWATER: 36936952
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.496713, 31.796190999154557],
            [-96.05478, 32.012525999151414],
            [-96.052786, 32.005894999151515],
            [-96.062172, 31.956339999152217],
            [-96.022491, 31.957548999152216],
            [-95.980568, 31.784560999154731],
            [-95.875937, 31.755502999155155],
            [-95.861262, 31.687450999156145],
            [-95.794081, 31.660309999156553],
            [-95.7873, 31.618384999157172],
            [-96.236629, 31.413391999160226],
            [-96.496713, 31.796190999154557]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "173",
        COUNTYNS: "00450335",
        AFFGEOID: "0500000US18173",
        GEOID: "18173",
        NAME: "Warrick",
        LSAD: "06",
        ALAND: 996664713,
        AWATER: 16155577
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.467284, 38.165402999083121],
            [-87.31703, 38.201752999082835],
            [-87.316893, 38.246076999082497],
            [-87.298388, 38.23167099908261],
            [-87.073067, 38.232595999082605],
            [-87.01749, 38.203579999082827],
            [-87.017453, 38.11830099908348],
            [-87.072332, 38.118279999083484],
            [-87.268003, 37.924765999085004],
            [-87.270387407739008, 37.8754228256754],
            [-87.304057452441981, 37.893432548207556],
            [-87.331765, 37.908252999085136],
            [-87.418585, 37.944762999084858],
            [-87.448635857609503, 37.933877867299834],
            [-87.473011, 38.035134999084129],
            [-87.467284, 38.165402999083121]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "055",
        COUNTYNS: "00395661",
        AFFGEOID: "0500000US16055",
        GEOID: "16055",
        NAME: "Kootenai",
        LSAD: "06",
        ALAND: 3205258021,
        AWATER: 185023431
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.041306446459004, 47.977455919088811],
            [-116.634114, 47.977072999062017],
            [-116.504329, 47.991385999062068],
            [-116.505104, 47.890141999061719],
            [-116.329538, 47.890392999061717],
            [-116.329406, 47.414010999060238],
            [-116.586549, 47.414694999060238],
            [-116.629059, 47.371437999060127],
            [-117.040490375568012, 47.366028885758105],
            [-117.041634, 47.735299999061212],
            [-117.041306446459004, 47.977455919088811]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "105",
        COUNTYNS: "00424254",
        AFFGEOID: "0500000US17105",
        GEOID: "17105",
        NAME: "Livingston",
        LSAD: "06",
        ALAND: 2704538434,
        AWATER: 4401361
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.930881, 41.105899999065258],
            [-88.58624, 41.108292999065242],
            [-88.251995, 41.114228999065226],
            [-88.2473, 40.994559999065757],
            [-88.234949, 40.618165999067536],
            [-88.459475, 40.617344999067548],
            [-88.574502, 40.616549999067544],
            [-88.584272, 40.757607999066842],
            [-88.929331, 40.753336999066875],
            [-88.93139, 40.927739999066048],
            [-88.930881, 41.105899999065258]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "069",
        COUNTYNS: "00198150",
        AFFGEOID: "0500000US08069",
        GEOID: "08069",
        NAME: "Larimer",
        LSAD: "06",
        ALAND: 6723022936,
        AWATER: 99012625
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.190554078708999, 40.997746611640132],
            [-105.277138, 40.998172999065744],
            [-105.276859994035007, 40.99817291669163],
            [-104.943371, 40.998074102795542],
            [-104.945551, 40.349098999068907],
            [-105.05672, 40.34928099906891],
            [-105.055089, 40.261792999069378],
            [-105.15906, 40.261218999069378],
            [-105.653321, 40.260456999069383],
            [-105.81534, 40.421485999068544],
            [-105.807341, 40.47452699906826],
            [-105.854926, 40.486251999068209],
            [-105.907857, 40.515492999068059],
            [-105.910892, 40.566419999067804],
            [-106.047971, 40.80464299906663],
            [-106.185307, 40.933972999066029],
            [-106.190554078708999, 40.997746611640132]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "149",
        COUNTYNS: "01784941",
        AFFGEOID: "0500000US17149",
        GEOID: "17149",
        NAME: "Pike",
        LSAD: "06",
        ALAND: 2153317672,
        AWATER: 45283711
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.364616603323299, 39.758718225394517],
            [-90.916071, 39.757167999072223],
            [-90.916609, 39.845074999071727],
            [-90.890082, 39.840999999071748],
            [-90.571754, 39.839325999071747],
            [-90.599105, 39.790059999072035],
            [-90.64599, 39.703367999072562],
            [-90.581055, 39.521727999073661],
            [-90.610502, 39.457446999074065],
            [-90.613694, 39.395774999074462],
            [-90.935349946368603, 39.399519527981532],
            [-90.937419, 39.400802999074422],
            [-91.03827, 39.448435999074121],
            [-91.064305, 39.494642999073832],
            [-91.100307, 39.538694999073556],
            [-91.148275, 39.545797999073507],
            [-91.174232, 39.59197499907323],
            [-91.182875500819691, 39.598233114868592],
            [-91.27614, 39.665758999072779],
            [-91.305760334965186, 39.686215469150554],
            [-91.367753, 39.729028999072391],
            [-91.364616603323299, 39.758718225394517]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "051",
        COUNTYNS: "01694477",
        AFFGEOID: "0500000US13051",
        GEOID: "13051",
        NAME: "Chatham",
        LSAD: "06",
        ALAND: 1112416679,
        AWATER: 525134462
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.391698, 32.095885999150227],
            [-81.195449, 32.237590999148225],
            [-81.147595170455375, 32.227169445745453],
            [-81.119361, 32.177141999149065],
            [-81.113334, 32.113204999149985],
            [-81.038265, 32.084468999150381],
            [-81.006745, 32.101151999150154],
            [-80.943226, 32.05782399915077],
            [-80.885517, 32.034599999151112],
            [-80.84313, 32.024225999151255],
            [-80.848441, 31.988278999151767],
            [-80.911207, 31.943768999152407],
            [-80.941359, 31.912983999152857],
            [-81.000317, 31.856743999153682],
            [-81.036873, 31.812720999154315],
            [-81.077057, 31.761255999155072],
            [-81.130634, 31.722691999155639],
            [-81.135298590200492, 31.710564597121905],
            [-81.154731, 31.713069999155774],
            [-81.177027, 31.816112999154274],
            [-81.138429, 31.855716999153692],
            [-81.290323, 31.978820999151914],
            [-81.391698, 32.095885999150227]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "147",
        COUNTYNS: "01074086",
        AFFGEOID: "0500000US39147",
        GEOID: "39147",
        NAME: "Seneca",
        LSAD: "06",
        ALAND: 1427143576,
        AWATER: 4584953
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.421054, 41.166779999064978],
            [-83.419843, 41.25400299906461],
            [-82.840087, 41.255336999064596],
            [-82.838538, 41.216998999064771],
            [-82.829512, 40.99662399906574],
            [-82.855614, 40.993665999065755],
            [-83.112737, 40.99344699906576],
            [-83.420319, 40.991888999065765],
            [-83.421054, 41.166779999064978]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "105",
        COUNTYNS: "00277317",
        AFFGEOID: "0500000US06105",
        GEOID: "06105",
        NAME: "Trinity",
        LSAD: "06",
        ALAND: 8234265086,
        AWATER: 73407949
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.560163, 40.95025699906595],
            [-123.481357, 40.91515699906612],
            [-123.406082, 41.013791999065667],
            [-123.464006, 41.076347999065376],
            [-123.408291, 41.179943999064932],
            [-123.273402, 41.12289099906517],
            [-123.23948, 41.075762999065383],
            [-123.141338, 41.079177999065372],
            [-123.055183, 41.046705999065516],
            [-123.036808, 41.004057999065708],
            [-122.917529, 40.993989999065761],
            [-122.897411, 41.028474999065608],
            [-122.973382, 41.112073999065224],
            [-122.885921, 41.205141999064807],
            [-122.811342, 41.20245099906483],
            [-122.653686, 41.289236999064471],
            [-122.585241, 41.359007999064168],
            [-122.50389, 41.342984999064235],
            [-122.522636, 41.213524999064788],
            [-122.498376, 41.182674999064915],
            [-122.445453, 41.157929999065026],
            [-122.457144, 41.096610999065291],
            [-122.513354, 41.088226999065327],
            [-122.527753, 41.014394999065665],
            [-122.607363, 40.957747999065909],
            [-122.600458, 40.899748999066183],
            [-122.666058, 40.825874999066528],
            [-122.669839, 40.773566999066787],
            [-122.752197, 40.688984999067181],
            [-122.695298, 40.572513999067766],
            [-122.845954, 40.505180999068102],
            [-122.903738, 40.44507099906842],
            [-122.998682, 40.418141999068546],
            [-123.068789, 40.332232999068999],
            [-123.065426, 40.28697099906924],
            [-122.97755, 40.241284999069492],
            [-122.989623, 40.145495999070015],
            [-122.934013, 39.978130999070949],
            [-123.544563, 39.977014999070953],
            [-123.544458, 40.001922999070821],
            [-123.544059, 40.73938599906694],
            [-123.55985, 40.829583999066514],
            [-123.623662, 40.92982299906604],
            [-123.560163, 40.95025699906595]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "163",
        COUNTYNS: "01074093",
        AFFGEOID: "0500000US39163",
        GEOID: "39163",
        NAME: "Vinton",
        LSAD: "06",
        ALAND: 1068008706,
        AWATER: 6781201
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.760111, 39.237643999075466],
            [-82.748591, 39.368164999074622],
            [-82.51758, 39.369467999074615],
            [-82.514479, 39.395766999074453],
            [-82.289663, 39.384194999074531],
            [-82.260243, 39.292914999075116],
            [-82.268583, 39.203759999075686],
            [-82.306669, 39.205493999075678],
            [-82.322874, 39.027673999076868],
            [-82.435309, 39.035078999076802],
            [-82.424677, 39.137971999076129],
            [-82.540282, 39.14447099907607],
            [-82.536306, 39.180756999075861],
            [-82.762892, 39.207966999075666],
            [-82.760111, 39.237643999075466]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "183",
        COUNTYNS: "00326719",
        AFFGEOID: "0500000US13183",
        GEOID: "13183",
        NAME: "Long",
        LSAD: "06",
        ALAND: 1037005778,
        AWATER: 8862741
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.981389, 31.79457799915458],
            [-81.824405, 32.014881999151392],
            [-81.750257, 31.973206999151984],
            [-81.765357, 31.87453999915342],
            [-81.641761, 31.765450999155007],
            [-81.491798, 31.699574999155974],
            [-81.566009, 31.576466999157788],
            [-81.663206, 31.538666999158355],
            [-81.681892, 31.541809999158307],
            [-81.701774, 31.584921999157672],
            [-81.850624, 31.667670999156449],
            [-81.969052, 31.789323999154664],
            [-81.981389, 31.79457799915458]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "237",
        COUNTYNS: "00347246",
        AFFGEOID: "0500000US13237",
        GEOID: "13237",
        NAME: "Putnam",
        LSAD: "06",
        ALAND: 892652833,
        AWATER: 41520809
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.533736, 33.434471999132022],
            [-83.279931, 33.48343799913139],
            [-83.224564, 33.380422999132719],
            [-83.164207, 33.355029999133052],
            [-83.145856, 33.31004499913363],
            [-83.253461, 33.259289999134289],
            [-83.274108, 33.187237999135242],
            [-83.363543, 33.168997999135492],
            [-83.42909, 33.185351999135271],
            [-83.444375, 33.184941999135276],
            [-83.545876, 33.171943999135451],
            [-83.533736, 33.434471999132022]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "249",
        COUNTYNS: "01383910",
        AFFGEOID: "0500000US48249",
        GEOID: "48249",
        NAME: "Jim Wells",
        LSAD: "06",
        ALAND: 2240778091,
        AWATER: 8739522
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.235497, 28.057965999216222],
            [-97.883148, 28.056899999216245],
            [-97.838918, 28.034612999216652],
            [-97.801388, 28.038359999216585],
            [-97.798517, 27.995658999217369],
            [-97.934274, 27.885201999219408],
            [-97.942146, 27.635931999224045],
            [-98.059464, 27.635861999224051],
            [-98.058078, 27.260980999231144],
            [-98.232464, 27.262486999231108],
            [-98.235497, 28.057965999216222]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "055",
        COUNTYNS: "01101815",
        AFFGEOID: "0500000US40055",
        GEOID: "40055",
        NAME: "Greer",
        LSAD: "06",
        ALAND: 1655842972,
        AWATER: 11157422
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.888571, 35.030261999112795],
            [-99.782949, 35.0301109991128],
            [-99.782727, 35.116779999111834],
            [-99.409291, 35.114508999111855],
            [-99.368367, 35.087866999112151],
            [-99.327788, 35.041696999112666],
            [-99.30622, 34.857631999114744],
            [-99.246462, 34.82211399911516],
            [-99.405821, 34.813037999115259],
            [-99.423201, 34.725019999116263],
            [-99.666849, 34.724554999116265],
            [-99.720066, 34.768449999115767],
            [-99.731092, 34.944057999113767],
            [-99.888425, 34.943945999113765],
            [-99.888571, 35.030261999112795]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "297",
        COUNTYNS: "01383934",
        AFFGEOID: "0500000US48297",
        GEOID: "48297",
        NAME: "Live Oak",
        LSAD: "06",
        ALAND: 2692796908,
        AWATER: 101483817
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.335031, 28.612657999206203],
            [-98.098315, 28.786948999203119],
            [-98.005252, 28.69023899920483],
            [-98.089764, 28.662978999205315],
            [-97.808774, 28.183414999213927],
            [-97.817716, 28.176847999214051],
            [-97.904081, 28.114227999215199],
            [-97.883148, 28.056899999216245],
            [-98.235497, 28.057965999216222],
            [-98.334323, 28.057799999216233],
            [-98.335031, 28.612657999206203]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "021",
        COUNTYNS: "00581296",
        AFFGEOID: "0500000US23021",
        GEOID: "23021",
        NAME: "Piscataquis",
        LSAD: "06",
        ALAND: 10259016062,
        AWATER: 1080049539
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.784853, 45.785211999057168],
            [-69.732579, 45.755977999057137],
            [-69.705743, 45.853974999057229],
            [-69.646566, 45.863987999057244],
            [-69.683564, 45.983631999057373],
            [-69.728577, 45.976881999057369],
            [-69.73232, 46.394007999057962],
            [-69.721109, 46.574052999058274],
            [-68.821414, 46.572509999058276],
            [-68.819401, 46.395781999057959],
            [-68.827039, 45.684825999057082],
            [-68.95891, 45.662194999057057],
            [-68.964653, 45.512366999056951],
            [-68.857297, 45.527300999056955],
            [-68.776061, 45.240316999056809],
            [-68.880923, 45.224946999056797],
            [-68.856587, 45.142782999056784],
            [-68.9735, 45.12721599905678],
            [-69.232021, 45.090165999056772],
            [-69.355667, 45.073468999056765],
            [-69.499861, 45.053777999056784],
            [-69.515649, 45.025042999056758],
            [-69.620967, 45.010955999056776],
            [-69.779928, 45.542765999056968],
            [-69.701688, 45.62798199905702],
            [-69.832818, 45.738733999057118],
            [-69.784853, 45.785211999057168]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "409",
        COUNTYNS: "01383990",
        AFFGEOID: "0500000US48409",
        GEOID: "48409",
        NAME: "San Patricio",
        LSAD: "06",
        ALAND: 1795990945,
        AWATER: 37577388
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.904081, 28.114227999215199],
            [-97.817716, 28.176847999214051],
            [-97.568482, 28.129845999214901],
            [-97.541071, 28.164668999214278],
            [-97.482384, 28.168615999214207],
            [-97.427171, 28.117667999215126],
            [-97.317163, 28.137188999214771],
            [-97.260818, 28.075758999215903],
            [-97.136312, 27.904389999219056],
            [-97.186987, 27.825488999220511],
            [-97.165617, 27.838212999220278],
            [-97.188659, 27.823884999220539],
            [-97.250796, 27.876717999219565],
            [-97.359997, 27.850765999220044],
            [-97.337677, 27.883164999219442],
            [-97.485519, 27.862796999219817],
            [-97.542622, 27.8583149992199],
            [-97.589473, 27.856100999219954],
            [-97.609593, 27.89037699921931],
            [-97.638174, 27.865593999219765],
            [-97.67528, 27.882537999219458],
            [-97.691074, 27.91735299921881],
            [-97.806798, 27.934711999218489],
            [-97.798517, 27.995658999217369],
            [-97.801388, 28.038359999216585],
            [-97.838918, 28.034612999216652],
            [-97.883148, 28.056899999216245],
            [-97.904081, 28.114227999215199]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "007",
        COUNTYNS: "01531932",
        AFFGEOID: "0500000US53007",
        GEOID: "53007",
        NAME: "Chelan",
        LSAD: "06",
        ALAND: 7565788660,
        AWATER: 189679746
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.118183, 47.997102999062079],
            [-121.15326, 48.039692999062233],
            [-121.015942, 48.074794999062348],
            [-120.907425, 48.163183999062689],
            [-121.001658, 48.296008999063183],
            [-121.071589, 48.317801999063256],
            [-121.049714, 48.485398999063932],
            [-120.823947, 48.54502999906417],
            [-120.787146, 48.506343999064015],
            [-120.702069, 48.53158899906412],
            [-120.652283, 48.536938999064127],
            [-120.633344, 48.46811799906385],
            [-120.649933, 48.398138999063576],
            [-120.560206, 48.361047999063423],
            [-120.583166, 48.319127999063262],
            [-120.508212, 48.310343999063235],
            [-120.469124, 48.268979999063077],
            [-120.346284, 48.199985999062811],
            [-120.361288, 48.157568999062669],
            [-120.21306, 48.079043999062364],
            [-120.143075, 48.064881999062322],
            [-120.066023, 47.966441999061978],
            [-119.870595, 47.960454999061952],
            [-119.992679, 47.783149999061365],
            [-120.209395, 47.748355999061253],
            [-120.199048, 47.682275999061041],
            [-120.316594, 47.471272999060403],
            [-120.317984, 47.456947999060368],
            [-120.311883, 47.445247999060335],
            [-120.308482, 47.432347999060291],
            [-120.296404, 47.413140999060239],
            [-120.293175, 47.40076699906021],
            [-120.28653, 47.393110999060177],
            [-120.260288, 47.383891999060161],
            [-120.086308, 47.338941999060033],
            [-120.09446, 47.262158999059821],
            [-120.389197, 47.260934999059813],
            [-120.5305, 47.334327999060022],
            [-120.664368, 47.343304999060045],
            [-120.802533, 47.42262599906028],
            [-120.917936, 47.429366999060292],
            [-120.996578, 47.518544999060538],
            [-121.07497, 47.550890999060648],
            [-121.113569, 47.597287999060789],
            [-121.121584, 47.685212999061058],
            [-121.066005, 47.713602999061131],
            [-121.119179, 47.779932999061359],
            [-121.070685, 47.826615999061517],
            [-121.180776, 47.898768999061737],
            [-121.118183, 47.997102999062079]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "063",
        COUNTYNS: "00295734",
        AFFGEOID: "0500000US12063",
        GEOID: "12063",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 2378066857,
        AWATER: 94243008
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.52921, 30.974438999166917],
            [-85.498001585089497, 30.997865048527952],
            [-85.488298288939092, 30.997964622453846],
            [-85.333319, 30.999554999166516],
            [-85.145959, 31.000692999166507],
            [-85.031285, 31.000646999166509],
            [-85.002499, 31.000681999166513],
            [-85.006062, 30.977038999166869],
            [-84.983757, 30.936983999167488],
            [-84.935698, 30.878702999168397],
            [-84.934283, 30.834032999169089],
            [-84.91815, 30.772081999170055],
            [-84.864693, 30.711541999171015],
            [-84.860943, 30.693245999171296],
            [-84.932519, 30.606392999172662],
            [-85.167687, 30.607978999172644],
            [-85.171891, 30.564335999173331],
            [-85.383948, 30.566855999173296],
            [-85.434782, 30.567559999173287],
            [-85.432274, 30.785456999169856],
            [-85.482276, 30.829508999169157],
            [-85.599934, 30.830551999169142],
            [-85.52921, 30.974438999166917]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "127",
        COUNTYNS: "01101851",
        AFFGEOID: "0500000US40127",
        GEOID: "40127",
        NAME: "Pushmataha",
        LSAD: "06",
        ALAND: 3615205224,
        AWATER: 69981947
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.779425, 34.506555999118795],
            [-95.6717, 34.506798999118807],
            [-95.671976, 34.593851999117796],
            [-95.514315, 34.594152999117789],
            [-95.514514, 34.681141999116782],
            [-95.059616, 34.680735999116763],
            [-94.937752, 34.680876999116762],
            [-94.937605, 34.506830999118804],
            [-95.046746, 34.507228999118809],
            [-95.046585, 34.243670999121939],
            [-95.151233, 34.243905999121921],
            [-95.156521, 34.156964999122984],
            [-95.779376, 34.156698999122973],
            [-95.779425, 34.506555999118795]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "295",
        COUNTYNS: "01383933",
        AFFGEOID: "0500000US48295",
        GEOID: "48295",
        NAME: "Lipscomb",
        LSAD: "06",
        ALAND: 2414327232,
        AWATER: 298741
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.546145126097016, 36.499505138401339],
            [-100.311018, 36.499687999097539],
            [-100.003761993368016, 36.499701847835134],
            [-100.000406, 36.499701999097539],
            [-100.000399, 36.055676999101891],
            [-100.540221, 36.056490999101896],
            [-100.546724, 36.056535999101889],
            [-100.546145126097016, 36.499505138401339]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "487",
        COUNTYNS: "01384029",
        AFFGEOID: "0500000US48487",
        GEOID: "48487",
        NAME: "Wilbarger",
        LSAD: "06",
        ALAND: 2514536080,
        AWATER: 18196245
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.475294, 34.085617999123848],
            [-99.475022137038394, 34.396870988910607],
            [-99.470969, 34.396470999120112],
            [-99.420432, 34.380463999120295],
            [-99.394956, 34.442098999119573],
            [-99.350407, 34.437082999119625],
            [-99.27534, 34.386598999120224],
            [-99.221607900845498, 34.325373573493557],
            [-99.2116, 34.313969999121085],
            [-99.189511, 34.214311999122287],
            [-99.131553, 34.209351999122347],
            [-99.0588, 34.201255999122445],
            [-99.002916, 34.208781999122358],
            [-98.952324887528192, 34.2046698977259],
            [-98.953088, 33.833999999126966],
            [-99.47565, 33.834004999126954],
            [-99.475294, 34.085617999123848]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "017",
        COUNTYNS: "00351606",
        AFFGEOID: "0500000US13017",
        GEOID: "13017",
        NAME: "Ben Hill",
        LSAD: "06",
        ALAND: 647813382,
        AWATER: 9803106
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.480176, 31.847311999153803],
            [-83.190422, 31.849394999153777],
            [-83.177469, 31.847856999153802],
            [-83.138659, 31.814445999154298],
            [-82.995698, 31.780982999154787],
            [-82.99836, 31.673163999156362],
            [-83.176376, 31.67838199915629],
            [-83.266363, 31.654202999156642],
            [-83.325617, 31.680602999156253],
            [-83.325296, 31.756583999155144],
            [-83.453634, 31.75786099915512],
            [-83.45887, 31.793611999154596],
            [-83.480176, 31.847311999153803]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "027",
        COUNTYNS: "01497431",
        AFFGEOID: "0500000US51027",
        GEOID: "51027",
        NAME: "Buchanan",
        LSAD: "06",
        ALAND: 1302387725,
        AWATER: 2803981
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.309415, 37.300065999090222],
            [-82.201745, 37.375107999089572],
            [-81.968297, 37.537797999088184],
            [-81.932279, 37.511960999088402],
            [-81.984891, 37.454314999088894],
            [-81.93695, 37.419919999089196],
            [-81.933601, 37.389216999089456],
            [-81.896001, 37.33196699908995],
            [-81.849949, 37.285226999090355],
            [-81.774747, 37.274846999090443],
            [-81.744003, 37.242527999090726],
            [-81.739055030485488, 37.239497102963554],
            [-81.761894, 37.201926999091093],
            [-81.900892, 37.142552999091606],
            [-82.007716, 37.119870999091809],
            [-82.045338, 37.046668999092468],
            [-82.149375, 37.0416409990925],
            [-82.157889, 37.110571999091889],
            [-82.293007, 37.305995999090186],
            [-82.314370452929595, 37.296306250945655],
            [-82.309415, 37.300065999090222]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "039",
        COUNTYNS: "01581079",
        AFFGEOID: "0500000US55039",
        GEOID: "55039",
        NAME: "Fond du Lac",
        LSAD: "06",
        ALAND: 1863635236,
        AWATER: 119885776
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.885697, 43.895183999057402],
            [-88.403195, 43.892976999057396],
            [-88.404187, 43.93819999905736],
            [-88.161654, 43.937682999057351],
            [-88.162274, 43.891510999057409],
            [-88.160873, 43.542942999057907],
            [-88.400426, 43.543531999057898],
            [-88.401041, 43.630932999057762],
            [-88.886052, 43.63353999905776],
            [-88.885697, 43.895183999057402]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "079",
        COUNTYNS: "00516886",
        AFFGEOID: "0500000US21079",
        GEOID: "21079",
        NAME: "Garrard",
        LSAD: "06",
        ALAND: 595899383,
        AWATER: 10017670
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.689343, 37.727273999086613],
            [-84.716925, 37.81538899908589],
            [-84.656903, 37.783920999086149],
            [-84.656608, 37.809907999085937],
            [-84.571158, 37.728549999086603],
            [-84.525643, 37.769496999086272],
            [-84.450201, 37.697897999086855],
            [-84.347805, 37.539063999088178],
            [-84.37444, 37.47266299908874],
            [-84.446719, 37.485892999088641],
            [-84.648559, 37.609896999087582],
            [-84.658296, 37.634500999087372],
            [-84.652294, 37.65089199908725],
            [-84.744888, 37.713074999086722],
            [-84.689343, 37.727273999086613]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "117",
        COUNTYNS: "00559557",
        AFFGEOID: "0500000US22117",
        GEOID: "22117",
        NAME: "Washington",
        LSAD: "15",
        ALAND: 1734071719,
        AWATER: 16460917
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.347241080662101, 31.000360667147309],
            [-90.346007, 31.000362999166519],
            [-90.259554940491299, 31.000661780395607],
            [-89.897516, 31.001912999166482],
            [-89.835907824328189, 31.002101422284088],
            [-89.728147, 31.002430999166481],
            [-89.728175690160597, 31.002313887150581],
            [-89.750073, 30.912929999167865],
            [-89.791745, 30.820386999169301],
            [-89.836331, 30.727196999170758],
            [-89.826180359115199, 30.668823269356892],
            [-89.99459, 30.665306999171737],
            [-90.255648, 30.711904999170997],
            [-90.316871, 30.890931999168203],
            [-90.34671, 30.905426999167986],
            [-90.347241080662101, 31.000360667147309]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "051",
        COUNTYNS: "00558027",
        AFFGEOID: "0500000US22051",
        GEOID: "22051",
        NAME: "Jefferson",
        LSAD: "15",
        ALAND: 765682137,
        AWATER: 897184724
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.279495, 30.006796999182335],
            [-90.275555, 30.088876999180993],
            [-90.259889, 30.163409999179777],
            [-90.220354, 30.17559499917958],
            [-90.156256, 30.189612999179342],
            [-90.108119, 30.164939999179744],
            [-90.124775, 29.975790999182848],
            [-90.140074, 29.947903999183303],
            [-90.132474, 29.913004999183876],
            [-90.058547, 29.945333999183347],
            [-90.008056, 29.895527999184171],
            [-90.06861, 29.828829999185274],
            [-90.075683, 29.77184999918622],
            [-89.984533, 29.617395999188815],
            [-89.97747, 29.449370999191654],
            [-89.902706324024294, 29.293036605690322],
            [-89.95646, 29.253743999194988],
            [-90.058512, 29.183686999196194],
            [-90.089835448194194, 29.164475355817636],
            [-89.998954, 29.298338999194225],
            [-90.030189, 29.332070999193643],
            [-90.020622, 29.428460999192001],
            [-90.137504, 29.477162999191172],
            [-90.186172, 29.563340999189712],
            [-90.151725, 29.58787099918931],
            [-90.155807, 29.677529999187797],
            [-90.228157, 29.692027999187552],
            [-90.169288, 29.807379999185638],
            [-90.173202, 29.827523999185303],
            [-90.240173, 29.862116999184728],
            [-90.279533, 29.967970999182977],
            [-90.279495, 30.006796999182335]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "177",
        COUNTYNS: "01480172",
        AFFGEOID: "0500000US51177",
        GEOID: "51177",
        NAME: "Spotsylvania",
        LSAD: "06",
        ALAND: 1039771765,
        AWATER: 33231063
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.953639, 38.11850099908348],
            [-77.715081, 38.335763999081806],
            [-77.702843, 38.360839999081634],
            [-77.645744, 38.378106999081496],
            [-77.618727, 38.367834999081573],
            [-77.603865, 38.333577999081825],
            [-77.530283, 38.309174999082018],
            [-77.492015, 38.273718999082291],
            [-77.447126, 38.284613999082211],
            [-77.39085, 38.245588999082493],
            [-77.370297, 38.246575999082495],
            [-77.517616, 38.105419999083573],
            [-77.642518, 37.990687999084479],
            [-77.687469, 38.007805999084347],
            [-77.838217, 38.106238999083565],
            [-77.953639, 38.11850099908348]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "143",
        COUNTYNS: "01623014",
        AFFGEOID: "0500000US26143",
        GEOID: "26143",
        NAME: "Roscommon",
        LSAD: "06",
        ALAND: 1345827533,
        AWATER: 156421850
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.850929, 44.511068999056867],
            [-84.370643, 44.507222999056864],
            [-84.368039, 44.160537999057119],
            [-84.608104, 44.160481999057119],
            [-84.851705, 44.161374999057131],
            [-84.850929, 44.511068999056867]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "093",
        COUNTYNS: "00974144",
        AFFGEOID: "0500000US36093",
        GEOID: "36093",
        NAME: "Schenectady",
        LSAD: "06",
        ALAND: 529856261,
        AWATER: 12600384
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.263314, 42.796533999059427],
            [-74.083883, 42.897353999059199],
            [-74.09298, 42.955867999059045],
            [-73.955458, 42.897305999059199],
            [-73.904386, 42.911162999059137],
            [-73.895938, 42.851075999059297],
            [-73.809369, 42.778868999059476],
            [-73.832618, 42.772262999059492],
            [-74.180274, 42.729978999059604],
            [-74.272295, 42.714269999059645],
            [-74.263314, 42.796533999059427]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "09",
        COUNTYFP: "005",
        COUNTYNS: "00212796",
        AFFGEOID: "0500000US09005",
        GEOID: "09005",
        NAME: "Litchfield",
        LSAD: "06",
        ALAND: 2384058125,
        AWATER: 62393362
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.517918790638191, 41.666720529772967],
            [-73.505008, 41.823772999062378],
            [-73.487314, 42.049637999061595],
            [-73.231056, 42.04494499906162],
            [-73.127229170878394, 42.042123147416426],
            [-73.053362673748097, 42.040115570971935],
            [-73.008762781630693, 42.038903415308326],
            [-73.029537, 41.966605999061869],
            [-72.88706, 41.973120999061869],
            [-72.905945, 41.921695999062024],
            [-72.949024, 41.806430999062428],
            [-73.016936, 41.798182999062483],
            [-72.99855, 41.712497999062791],
            [-72.98325, 41.639597999063049],
            [-73.058024, 41.606739999063194],
            [-73.164676, 41.557087999063384],
            [-73.15573, 41.515278999063547],
            [-73.204197, 41.508526999063577],
            [-73.252265, 41.512133999063543],
            [-73.318889, 41.505188999063584],
            [-73.310473, 41.468814999063724],
            [-73.386964, 41.514793999063535],
            [-73.441466, 41.491293999063629],
            [-73.506923, 41.665801999062957],
            [-73.517918790638191, 41.666720529772967]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "013",
        COUNTYNS: "01804486",
        AFFGEOID: "0500000US72013",
        GEOID: "72013",
        NAME: "Arecibo",
        LSAD: "13",
        ALAND: 326179591,
        AWATER: 117205831
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.769132, 18.469092999432402],
            [-66.765570777836302, 18.482796007272345],
            [-66.733986, 18.473456999432287],
            [-66.624618, 18.494198999431735],
            [-66.586254775676792, 18.487952291398102],
            [-66.58006, 18.468102999432421],
            [-66.588181, 18.3894079994345],
            [-66.590785, 18.33805999943586],
            [-66.606989, 18.328185999436119],
            [-66.717302, 18.315680999436449],
            [-66.735947, 18.349674999435546],
            [-66.770072, 18.325012999436201],
            [-66.769132, 18.469092999432402]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "037",
        COUNTYNS: "00306911",
        AFFGEOID: "0500000US12037",
        GEOID: "12037",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1411918808,
        AWATER: 1275529258
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.228425133073102, 29.669559996949946],
            [-85.109401, 29.776792999186142],
            [-85.039355, 29.780169999186086],
            [-85.053109, 29.910608999183925],
            [-85.025439, 29.971398999182917],
            [-85.007561, 30.013850999182218],
            [-84.54445, 30.01102999918227],
            [-84.523992, 29.981727999182763],
            [-84.467713, 30.001852999182415],
            [-84.341148833181592, 29.960756335288501],
            [-84.333746, 29.923720999183701],
            [-84.349066, 29.896811999184155],
            [-84.423834, 29.902995999184046],
            [-84.470323, 29.924523999183688],
            [-84.535873, 29.91009199918393],
            [-84.57744, 29.887827999184299],
            [-84.564976, 29.810179999185582],
            [-84.604003, 29.786020999185983],
            [-84.692619, 29.763038999186367],
            [-84.776954, 29.692190999187556],
            [-84.876731, 29.655757999188172],
            [-85.045074, 29.586990999189322],
            [-85.15731, 29.642889999188384],
            [-85.228425133073102, 29.669559996949946]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "033",
        COUNTYNS: "00695741",
        AFFGEOID: "0500000US28033",
        GEOID: "28033",
        NAME: "DeSoto",
        LSAD: "06",
        ALAND: 1233556699,
        AWATER: 53624068
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.250095, 34.907319999114186],
            [-90.244476, 34.937595999113846],
            [-90.309297, 34.995693999113186],
            [-89.795187, 34.994292999113206],
            [-89.759612642234373, 34.994239770844707],
            [-89.724315837968589, 34.994186957866702],
            [-89.723442, 34.772310999115724],
            [-89.912202, 34.772505999115715],
            [-90.030051, 34.707949999116451],
            [-90.171483, 34.759009999115875],
            [-90.200199, 34.724417999116277],
            [-90.202126, 34.860373999114728],
            [-90.311424250345183, 34.872851143214575],
            [-90.250095, 34.907319999114186]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "105",
        COUNTYNS: "02371430",
        AFFGEOID: "0500000US02105",
        GEOID: "02105",
        NAME: "Hoonah-Angoon",
        LSAD: "05",
        ALAND: 16981434901,
        AWATER: 7808657621
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-133.696701, 57.792723999149864],
              [-133.176979642157988, 58.150537691543938],
              [-133.176444, 58.150150999155024],
              [-133.076421, 57.999761999152845],
              [-132.869318, 57.842940999150578],
              [-132.756813, 57.705092999148626],
              [-132.655278650447002, 57.601744609630657],
              [-132.827503, 57.580659999146881],
              [-132.88785, 57.613738999147337],
              [-132.840996, 57.663105999148023],
              [-132.871898, 57.712795999148724],
              [-132.949844, 57.745424999149193],
              [-132.97733, 57.721497999148852],
              [-133.188197, 57.741007999149126],
              [-133.279877, 57.79476799914989],
              [-133.353451, 57.772269999149586],
              [-133.493048, 57.824725999150331],
              [-133.549358, 57.775224999149614],
              [-133.696701, 57.792723999149864]
            ]
          ],
          [
            [
              [-134.960502, 58.403757999158771],
              [-134.865084, 58.357275999158084],
              [-134.788441, 58.289089999157063],
              [-134.735829, 58.234596999156274],
              [-134.724477838090991, 58.211465284801037],
              [-134.772728, 58.166495999155266],
              [-134.784681, 58.096598999154253],
              [-134.697766, 58.032035999153308],
              [-134.577773, 58.05411599915363],
              [-134.538413, 58.098171999154275],
              [-134.254386050803987, 58.144355273441739],
              [-134.174352, 58.125283999154675],
              [-134.183983, 58.077294999153978],
              [-134.138231, 58.047102999153523],
              [-134.087572, 57.996474999152795],
              [-133.999948, 57.914809999151615],
              [-133.904874, 57.807405999150077],
              [-133.896846, 57.685523999148344],
              [-133.808285, 57.609603999147282],
              [-133.8176, 57.568352999146704],
              [-133.871581, 57.484157999145523],
              [-133.866931, 57.367868999143909],
              [-133.786398, 57.311527999143131],
              [-133.840895, 57.271073999142573],
              [-133.875673, 57.267612999142521],
              [-133.983501, 57.302837999143016],
              [-134.100118, 57.266284999142513],
              [-134.193629, 57.184878999141389],
              [-134.302721, 57.136561999140739],
              [-134.378359, 57.115015999140446],
              [-134.386052, 57.087391999140067],
              [-134.443786, 57.062291999139738],
              [-134.497718, 57.031193999139312],
              [-134.565687, 57.02373699913921],
              [-134.634565, 57.109862999140375],
              [-134.640169, 57.239851999142154],
              [-134.55554, 57.407427999144453],
              [-134.607557, 57.513041999145933],
              [-134.695428, 57.685334999148346],
              [-134.709024, 57.780497999149688],
              [-134.752398, 57.938955999151979],
              [-134.783772, 58.082291999154037],
              [-134.864299, 58.180488999155465],
              [-134.958171, 58.32205699915756],
              [-134.960502, 58.403757999158771]
            ]
          ],
          [
            [
              [-136.563223, 58.035051999153353],
              [-136.538708, 58.093481999154186],
              [-136.446286, 58.113339999154491],
              [-136.365544, 58.148853999155008],
              [-136.387113, 58.252413999156531],
              [-136.290349, 58.251760999156524],
              [-136.176442, 58.265110999156711],
              [-136.033678, 58.276727999156897],
              [-135.877468, 58.259851999156631],
              [-135.78338, 58.286708999157028],
              [-135.712398, 58.231891999156225],
              [-135.497911, 58.16888199915531],
              [-135.275797, 58.097023999154267],
              [-135.108896, 58.088269999154129],
              [-134.950844, 58.036992999153384],
              [-134.926395, 57.921918999151707],
              [-135.004952, 57.884337999151185],
              [-134.94942955753001, 57.781259288599301],
              [-135.061737, 57.760932999149411],
              [-135.238129, 57.761710999149422],
              [-135.528534, 57.880469999151131],
              [-135.715149, 57.929412999151815],
              [-135.901964, 58.001342999152861],
              [-136.024328, 57.838794999150529],
              [-136.371986492161, 57.832232009855325],
              [-136.372377, 57.832586999150436],
              [-136.458829, 57.853900999150746],
              [-136.484259, 57.896459999151347],
              [-136.573288, 57.926843999151792],
              [-136.563223, 58.035051999153353]
            ]
          ],
          [
            [
              [-137.936159895178008, 58.810650222615408],
              [-137.52567710531298, 58.908974493306523],
              [-137.526424, 58.906833999166388],
              [-137.447383, 58.909512999166431],
              [-137.264752, 59.002351999167857],
              [-136.863896, 59.138471999169987],
              [-136.826633, 59.158388999170292],
              [-136.581521, 59.164908999170386],
              [-136.48965218999399, 59.260491613790997],
              [-136.362339, 59.232960999171461],
              [-136.107895, 59.215692999171196],
              [-135.89905, 59.179059999170619],
              [-135.699146, 59.107409999169505],
              [-135.723091, 59.069846999168902],
              [-135.566129, 58.942449999166925],
              [-135.605493, 58.903988999166337],
              [-135.524117, 58.887695999166098],
              [-135.683487, 58.853597999165565],
              [-135.747005, 58.725605999163619],
              [-135.551001, 58.698792999163217],
              [-135.529943, 58.656783999162563],
              [-135.405223, 58.620592999162021],
              [-135.316732, 58.504392999160288],
              [-135.50702, 58.4996039991602],
              [-135.446307, 58.4056179991588],
              [-135.449966172855, 58.339068181814689],
              [-135.544213, 58.330227999157678],
              [-135.649861, 58.324516999157595],
              [-135.728054, 58.397066999158667],
              [-135.917917, 58.381236999158439],
              [-136.041818, 58.38016099915842],
              [-136.11193, 58.342529999157861],
              [-136.265906, 58.314498999157458],
              [-136.437152, 58.30241699915728],
              [-136.544776, 58.316664999157489],
              [-136.576799, 58.2779509991569],
              [-136.567956, 58.245152999156424],
              [-136.591924, 58.217885999156024],
              [-136.70125, 58.219415999156055],
              [-136.717093, 58.273507999156848],
              [-136.857605, 58.316359999157477],
              [-136.911713, 58.37025199915827],
              [-136.986384, 58.404042999158783],
              [-137.078109, 58.39747399915867],
              [-137.239366, 58.453158999159506],
              [-137.355328, 58.492373999160087],
              [-137.568216, 58.587988999161524],
              [-137.653709, 58.608323999161826],
              [-137.681633, 58.656451999162549],
              [-137.836448, 58.741562999163854],
              [-137.941828, 58.794321999164666],
              [-137.936159895178008, 58.810650222615408]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "045",
        COUNTYNS: "00326650",
        AFFGEOID: "0500000US13045",
        GEOID: "13045",
        NAME: "Carroll",
        LSAD: "06",
        ALAND: 1292475855,
        AWATER: 12301426
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.338116299905906, 33.653114390730615],
            [-85.050889, 33.71463199912845],
            [-85.037926, 33.811941999127228],
            [-84.971203, 33.79961699912738],
            [-84.901688, 33.780702999127612],
            [-84.905788, 33.573377999130244],
            [-84.808934, 33.574084999130235],
            [-84.815869, 33.520248999130914],
            [-84.850713, 33.511456999131035],
            [-84.935378, 33.43742099913198],
            [-85.015358, 33.425505999132127],
            [-85.294346864345286, 33.4279931454967],
            [-85.304943833224897, 33.482756335976902],
            [-85.314048, 33.529804999130796],
            [-85.338116299905906, 33.653114390730615]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "093",
        COUNTYNS: "00451703",
        AFFGEOID: "0500000US18093",
        GEOID: "18093",
        NAME: "Lawrence",
        LSAD: "06",
        ALAND: 1163367128,
        AWATER: 7122898
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.682853, 38.904696999077707],
            [-86.682277, 38.992403999077105],
            [-86.317474, 38.990869999077113],
            [-86.280389, 38.991027999077126],
            [-86.275281, 38.763794999078691],
            [-86.310058, 38.733130999078895],
            [-86.308701, 38.688100999079225],
            [-86.683102, 38.686560999079241],
            [-86.682853, 38.904696999077707]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "141",
        COUNTYNS: "01804551",
        AFFGEOID: "0500000US72141",
        GEOID: "72141",
        NAME: "Utuado",
        LSAD: "13",
        ALAND: 294045388,
        AWATER: 3982230
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.826128, 18.323381999436243],
            [-66.777288, 18.32093799943631],
            [-66.770072, 18.325012999436201],
            [-66.735947, 18.349674999435546],
            [-66.717302, 18.315680999436449],
            [-66.606989, 18.328185999436119],
            [-66.56662, 18.298548999436896],
            [-66.597475, 18.284103999437281],
            [-66.649467, 18.158856999440601],
            [-66.670494, 18.152830999440763],
            [-66.69373, 18.216890999439066],
            [-66.781701, 18.250247999438177],
            [-66.817271, 18.230335999438704],
            [-66.827209, 18.244095999438343],
            [-66.826128, 18.323381999436243]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "077",
        COUNTYNS: "00450363",
        AFFGEOID: "0500000US18077",
        GEOID: "18077",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 934033957,
        AWATER: 5843999
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.683839, 38.815405999078322],
            [-85.628855, 38.817582999078311],
            [-85.537431, 38.882779999077854],
            [-85.5382, 38.91221699907765],
            [-85.444897, 38.912997999077653],
            [-85.203166, 38.913802999077639],
            [-85.201760622548591, 38.697440790925462],
            [-85.238665, 38.722493999078978],
            [-85.275454, 38.741171999078851],
            [-85.332640733751404, 38.734816753426585],
            [-85.340953, 38.733892999078904],
            [-85.400481, 38.7359799990789],
            [-85.448862, 38.713367999079047],
            [-85.438742, 38.659318999079439],
            [-85.43617, 38.598291999079876],
            [-85.431416070752192, 38.586285612188874],
            [-85.496666, 38.60673599907981],
            [-85.56998, 38.606154999079813],
            [-85.57093, 38.678705999079298],
            [-85.683338, 38.736730999078887],
            [-85.683839, 38.815405999078322]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "185",
        COUNTYNS: "01419978",
        AFFGEOID: "0500000US02185",
        GEOID: "02185",
        NAME: "North Slope",
        LSAD: "04",
        ALAND: 230056014591,
        AWATER: 15547168253
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-166.838969, 68.337185999353594],
            [-166.591802, 68.405550999355242],
            [-166.328459, 68.442260999356108],
            [-166.28312, 68.521246999358013],
            [-166.229761, 68.613770999360256],
            [-166.193421, 68.726648999362979],
            [-166.224187, 68.87317499936654],
            [-165.923734, 68.868431999366422],
            [-165.522358, 68.855838999366128],
            [-164.967542, 68.883029999366784],
            [-164.526887, 68.917908999367626],
            [-164.253157, 68.930937999367941],
            [-163.926999, 69.000801999369656],
            [-163.574034, 69.124076999372676],
            [-163.244656, 69.306080999377158],
            [-163.151351, 69.430261999380235],
            [-163.151261, 69.612629999384765],
            [-163.071903, 69.737604999387898],
            [-162.989084, 69.825249999390081],
            [-162.78808, 69.929096999392698],
            [-162.503572, 70.100392999397016],
            [-162.302314, 70.204226999399637],
            [-161.879266, 70.329268999402814],
            [-161.581911, 70.302876999402145],
            [-161.288197, 70.296771999401969],
            [-160.81279, 70.376695999404021],
            [-160.214828, 70.559086999408663],
            [-159.869172, 70.706396999412448],
            [-159.648383, 70.794367999414732],
            [-159.17181, 70.875102999416811],
            [-159.114972, 70.817401999415324],
            [-158.85342, 70.792351999414677],
            [-158.573913, 70.794949999414754],
            [-158.366302, 70.819711999415389],
            [-158.032397, 70.832262999415704],
            [-157.768452, 70.875841999416821],
            [-157.421001, 70.976804999419457],
            [-157.176077, 71.095548999422519],
            [-156.906165, 71.239615999426292],
            [-156.809653, 71.286885999427511],
            [-156.56865, 71.352560999429244],
            [-156.531124, 71.296337999427749],
            [-156.309908, 71.259880999426798],
            [-156.074411, 71.242488999426357],
            [-156.044615, 71.184700999424834],
            [-155.895105, 71.193898999425102],
            [-155.587702, 71.172559999424521],
            [-155.520737, 71.102475999422708],
            [-155.533347, 71.06768299942182],
            [-155.705487, 71.02015299942056],
            [-155.762068, 70.985643999419679],
            [-155.95205, 70.964830999419149],
            [-155.979264, 70.918523999417928],
            [-155.924955, 70.852719999416237],
            [-155.731842, 70.831159999415675],
            [-155.543031, 70.847174999416097],
            [-155.485915, 70.885904999417093],
            [-155.513293, 70.94057899941852],
            [-155.36416, 70.994194999419889],
            [-155.262602, 71.079148999422102],
            [-155.060764, 71.145421999423831],
            [-154.942864, 71.12626399942333],
            [-154.581129, 71.00732099942023],
            [-154.608314, 70.942404999418557],
            [-154.572458, 70.825939999415567],
            [-154.430229, 70.831257999415683],
            [-154.290317, 70.821494999415435],
            [-154.127487, 70.778132999414311],
            [-153.89048, 70.885718999417094],
            [-153.666363, 70.883447999417029],
            [-153.426265, 70.89013099941721],
            [-153.23848, 70.922466999418049],
            [-153.049207, 70.91310199941779],
            [-152.904243, 70.883876999417041],
            [-152.696868, 70.882085999417015],
            [-152.423534, 70.858707999416396],
            [-152.223053, 70.824593999415498],
            [-152.19246, 70.795293999414767],
            [-152.348417, 70.744381999413449],
            [-152.352196, 70.697801999412249],
            [-152.473348, 70.683668999411879],
            [-152.433781, 70.616925999410157],
            [-152.29669, 70.602286999409785],
            [-152.078663, 70.584503999409336],
            [-151.975785, 70.5632149994088],
            [-151.697258, 70.547740999408404],
            [-151.734287, 70.503491999407245],
            [-151.739862, 70.436206999405528],
            [-151.504417, 70.431102999405397],
            [-151.297598, 70.400747999404629],
            [-151.175187, 70.375557999403995],
            [-151.020441, 70.43384099940549],
            [-150.903765, 70.460909999406184],
            [-150.786327, 70.46327099940622],
            [-150.557415, 70.481642999406702],
            [-150.414358, 70.459693999406156],
            [-150.301516, 70.418391999405088],
            [-150.074461, 70.439332999405622],
            [-149.866698, 70.510768999407446],
            [-149.740188, 70.498150999407116],
            [-149.461755, 70.518270999407633],
            [-149.179148, 70.485699999406819],
            [-148.928979, 70.426834999405301],
            [-148.667017, 70.430083999405383],
            [-148.477044, 70.359067999403564],
            [-148.46615, 70.313608999402419],
            [-148.351437, 70.304452999402187],
            [-148.203477, 70.348187999403294],
            [-147.9615, 70.314200999402431],
            [-147.863719, 70.293316999401895],
            [-147.765104, 70.219805999400037],
            [-147.431532, 70.188825999399256],
            [-147.233327, 70.207552999399709],
            [-147.161601, 70.155611999398403],
            [-146.991109, 70.147609999398213],
            [-146.885771, 70.185916999399183],
            [-146.508133, 70.186043999399175],
            [-146.129579, 70.158947999398492],
            [-146.006411, 70.140401999398023],
            [-145.858297, 70.16599599939866],
            [-145.623306, 70.084374999396601],
            [-145.43483, 70.036993999395406],
            [-145.175073, 69.991706999394253],
            [-144.902304, 69.96450999939357],
            [-144.792614, 69.979795999393957],
            [-144.672305, 69.966875999393636],
            [-144.455421, 70.035243999395348],
            [-144.274904, 70.048710999395695],
            [-143.914244, 70.115695999397403],
            [-143.5173, 70.138417999397973],
            [-143.425199, 70.124927999397627],
            [-143.281878, 70.151051999398291],
            [-142.99979, 70.088304999396698],
            [-142.746807, 70.042530999395552],
            [-142.452927, 69.958124999393419],
            [-142.404366, 69.916510999392372],
            [-142.239873, 69.896597999391872],
            [-142.015641, 69.837975999390409],
            [-141.713369, 69.7894969993892],
            [-141.43084, 69.695143999386829],
            [-141.210456, 69.684189999386547],
            [-141.002672, 69.645608999385573],
            [-141.002609596218008, 68.498364003948566],
            [-142.232922, 68.500006999357495],
            [-143.718715, 68.5000049993575],
            [-144.778977, 68.499999999357499],
            [-146.0, 68.499999999357499],
            [-146.0, 67.999999999345576],
            [-146.809716, 67.999999999345576],
            [-147.753117, 67.999987999345578],
            [-149.32543, 67.999998999345564],
            [-150.890028, 68.000003999345566],
            [-151.900276, 68.000006999345558],
            [-152.741341, 68.000008999345553],
            [-153.960576, 67.999999999345576],
            [-155.318744, 68.000031999345552],
            [-155.318748, 68.034941999346401],
            [-156.236817, 68.034925999346399],
            [-156.236831, 68.121497999348449],
            [-157.15491, 68.121482999348444],
            [-157.154924, 68.208053999350497],
            [-158.1791, 68.208023999350488],
            [-159.693477, 68.207991999350497],
            [-159.693487, 68.294563999352576],
            [-160.159452, 68.294552999352547],
            [-160.15944, 68.207981999350508],
            [-161.091351, 68.207960999350504],
            [-161.091364, 68.294531999352571],
            [-161.55732, 68.294520999352571],
            [-161.557328, 68.207948999350506],
            [-162.023261, 68.207939999350486],
            [-162.023273, 68.294510999352582],
            [-162.722202, 68.294495999352549],
            [-162.72219, 68.207925999350493],
            [-163.615271, 68.207906999350485],
            [-164.499463, 68.207889999350499],
            [-164.499437, 68.034747999346379],
            [-165.396520296503013, 68.034730518044569],
            [-165.688137, 68.09039599934772],
            [-165.872088, 68.110046999348171],
            [-165.97497, 68.140909999348906],
            [-166.089453, 68.221298999350822],
            [-166.313138, 68.289163999352439],
            [-166.60089, 68.333636999353502],
            [-166.838969, 68.337185999353594]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "097",
        COUNTYNS: "01101833",
        AFFGEOID: "0500000US40097",
        GEOID: "40097",
        NAME: "Mayes",
        LSAD: "06",
        ALAND: 1697432145,
        AWATER: 72855332
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.43561, 36.510159999097432],
            [-95.32817, 36.510239999097436],
            [-95.00573, 36.509889999097446],
            [-95.011303, 36.161814999100848],
            [-95.1185, 36.161629999100839],
            [-95.118377, 36.074543999101699],
            [-95.207946, 36.074707999101712],
            [-95.43997, 36.075199999101713],
            [-95.43561, 36.510159999097432]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "019",
        COUNTYNS: "00484979",
        AFFGEOID: "0500000US20019",
        GEOID: "20019",
        NAME: "Chautauqua",
        LSAD: "06",
        ALAND: 1654694139,
        AWATER: 15243783
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.524873, 37.302729999090204],
            [-95.964399, 37.299231999090239],
            [-95.964271232102192, 36.999223143375289],
            [-96.000810206199603, 36.999201052020794],
            [-96.217571, 36.999069999092903],
            [-96.500288, 36.998642999092894],
            [-96.525582044282899, 36.998677967817699],
            [-96.524873, 37.302729999090204]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "103",
        COUNTYNS: "00835873",
        AFFGEOID: "0500000US31103",
        GEOID: "31103",
        NAME: "Keya Paha",
        LSAD: "06",
        ALAND: 2002257567,
        AWATER: 2192458
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.198413799774997, 42.997976883028365],
            [-100.198412611565999, 42.997976883690441],
            [-99.850037, 42.998170999058964],
            [-99.534055014809013, 42.998197487377062],
            [-99.254455012316583, 42.998220925845651],
            [-99.257039, 42.804295999059413],
            [-99.415222, 42.77209799905949],
            [-99.537491, 42.717625999059628],
            [-99.677684, 42.730902999059595],
            [-100.11334, 42.799682999059421],
            [-100.198478, 42.8461019990593],
            [-100.198413799774997, 42.997976883028365]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "119",
        COUNTYNS: "00559567",
        AFFGEOID: "0500000US22119",
        GEOID: "22119",
        NAME: "Webster",
        LSAD: "15",
        ALAND: 1536282650,
        AWATER: 56145063
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.520993553771589, 33.018742292725477],
            [-93.490512400120394, 33.018634594803771],
            [-93.377134, 33.018233999137493],
            [-93.238606962399388, 33.018015879094087],
            [-93.237381, 32.716249999141553],
            [-93.186571, 32.716754999141557],
            [-93.173589, 32.633067999142689],
            [-93.178767, 32.584915999143355],
            [-93.147058, 32.45533999914516],
            [-93.236656, 32.410070999145788],
            [-93.372781, 32.410152999145794],
            [-93.441212, 32.410430999145774],
            [-93.444743, 32.761165999140943],
            [-93.473797, 32.783171999140642],
            [-93.447916, 32.866683999139518],
            [-93.469572, 32.917716999138825],
            [-93.530454, 32.975608999138046],
            [-93.520993553771589, 33.018742292725477]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "113",
        COUNTYNS: "00485021",
        AFFGEOID: "0500000US20113",
        GEOID: "20113",
        NAME: "McPherson",
        LSAD: "06",
        ALAND: 2326519566,
        AWATER: 6031186
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.924795, 38.609879999079794],
            [-97.371911, 38.609352999079803],
            [-97.37175, 38.173672999083053],
            [-97.701841, 38.173813999083059],
            [-97.922136, 38.173712999083051],
            [-97.924269, 38.522754999080419],
            [-97.924795, 38.609879999079794]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "047",
        COUNTYNS: "01074036",
        AFFGEOID: "0500000US39047",
        GEOID: "39047",
        NAME: "Fayette",
        LSAD: "06",
        ALAND: 1052469899,
        AWATER: 1694037
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.65333, 39.716875999072478],
            [-83.252435, 39.695437999072603],
            [-83.266737, 39.516248999073696],
            [-83.372714, 39.377415999074572],
            [-83.590878, 39.378735999074564],
            [-83.578194, 39.52403299907364],
            [-83.585523, 39.545108999073513],
            [-83.670196, 39.550253999073476],
            [-83.65333, 39.716875999072478]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "257",
        COUNTYNS: "00350028",
        AFFGEOID: "0500000US13257",
        GEOID: "13257",
        NAME: "Stephens",
        LSAD: "06",
        ALAND: 463974775,
        AWATER: 13095267
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.45977, 34.481141999119103],
            [-83.365044, 34.675307999116846],
            [-83.340039106784573, 34.686327709528811],
            [-83.33869, 34.68200199911675],
            [-83.27796, 34.644852999117191],
            [-83.221402, 34.609946999117604],
            [-83.154577, 34.588197999117853],
            [-83.102873826035704, 34.537430908883842],
            [-83.177838, 34.477016999119144],
            [-83.398396, 34.460882999119349],
            [-83.45977, 34.481141999119103]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "141",
        COUNTYNS: "01026129",
        AFFGEOID: "0500000US37141",
        GEOID: "37141",
        NAME: "Pender",
        LSAD: "06",
        ALAND: 2256645184,
        AWATER: 162833567
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.256085, 34.399468999120067],
            [-78.176501, 34.465355999119289],
            [-78.255468, 34.508613999118779],
            [-78.254441, 34.55359499911826],
            [-78.113995, 34.706875999116484],
            [-78.114007, 34.721794999116298],
            [-77.981195, 34.713982999116404],
            [-77.680127, 34.720594999116322],
            [-77.576165, 34.476606999119163],
            [-77.515216544584788, 34.437384968546226],
            [-77.635034, 34.359554999120547],
            [-77.713513170458796, 34.29024718580817],
            [-77.823102, 34.389286999120195],
            [-78.029922, 34.331767999120878],
            [-78.137302, 34.371710999120403],
            [-78.16235, 34.357006999120571],
            [-78.202532, 34.353305999120629],
            [-78.256085, 34.399468999120067]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "031",
        COUNTYNS: "01026337",
        AFFGEOID: "0500000US37031",
        GEOID: "37031",
        NAME: "Carteret",
        LSAD: "06",
        ALAND: 1314749435,
        AWATER: 2131001182
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.161676, 34.795074999115464],
            [-77.078263, 34.811321999115279],
            [-76.954438, 34.809065999115305],
            [-76.883201, 34.844892999114883],
            [-76.693018, 34.869179999114621],
            [-76.652571, 34.937594999113848],
            [-76.71158, 34.981767999113352],
            [-76.304136, 35.201452999110906],
            [-76.065774, 35.124936999111746],
            [-76.013145, 35.061854999112448],
            [-76.137269, 34.987857999113274],
            [-76.233088, 34.905476999114207],
            [-76.31021, 34.852308999114811],
            [-76.386804, 34.784578999115574],
            [-76.450454, 34.714449999116383],
            [-76.535946, 34.588576999117848],
            [-76.553806, 34.628251999117388],
            [-76.618719, 34.672549999116868],
            [-76.726969, 34.696689999116586],
            [-76.906257, 34.682819999116759],
            [-77.112964393076794, 34.638092831508771],
            [-77.096073, 34.671239999116885],
            [-77.165986, 34.785031999115581],
            [-77.161676, 34.795074999115464]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "33",
        COUNTYFP: "005",
        COUNTYNS: "00873176",
        AFFGEOID: "0500000US33005",
        GEOID: "33005",
        NAME: "Cheshire",
        LSAD: "06",
        ALAND: 1830338973,
        AWATER: 57617298
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.556112, 42.866251999059259],
            [-72.531469, 42.897949999059186],
            [-72.532186, 42.954944999059059],
            [-72.492597, 42.967647999059018],
            [-72.444977, 43.004415999058942],
            [-72.451954709068204, 43.020494909689212],
            [-72.462248, 43.044213999058854],
            [-72.435191, 43.086621999058764],
            [-72.451802, 43.153485999058624],
            [-72.450395939215895, 43.161213515965009],
            [-72.431632, 43.135634999058659],
            [-72.262483, 43.169951999058583],
            [-72.156661, 43.181967999058564],
            [-72.178497, 43.131865999058668],
            [-72.038149, 43.128806999058661],
            [-72.03838, 43.10752999905872],
            [-72.057949, 43.018921999058911],
            [-72.062222, 42.944795999059075],
            [-72.001215, 42.919442999059136],
            [-71.974572, 42.789432999059443],
            [-71.929030059053503, 42.712293671592249],
            [-72.081365050187998, 42.716456537600834],
            [-72.124526, 42.717635999059624],
            [-72.203613479806791, 42.71981852773952],
            [-72.283034092510903, 42.722010249683628],
            [-72.412030064752301, 42.725570072422904],
            [-72.451195051822694, 42.726650884553209],
            [-72.458519, 42.726852999059609],
            [-72.477615, 42.761244999059514],
            [-72.5396, 42.804831999059409],
            [-72.553902050848592, 42.858031609228995],
            [-72.556112, 42.866251999059259]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "297",
        COUNTYNS: "00351264",
        AFFGEOID: "0500000US13297",
        GEOID: "13297",
        NAME: "Walton",
        LSAD: "06",
        ALAND: 845768028,
        AWATER: 8927725
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.982033, 33.786053999127553],
            [-83.908465, 33.842844999126847],
            [-83.855066, 33.885309999126314],
            [-83.799104, 33.929843999125758],
            [-83.760869, 33.894572999126197],
            [-83.647031, 33.906197999126043],
            [-83.533165, 33.82092299912712],
            [-83.505928, 33.817759999127155],
            [-83.526386, 33.79611899912743],
            [-83.680896, 33.596918999129947],
            [-83.691551, 33.616988999129688],
            [-83.790705, 33.666655999129055],
            [-83.914823, 33.744202999128071],
            [-83.982033, 33.786053999127553]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "169",
        COUNTYNS: "00345367",
        AFFGEOID: "0500000US13169",
        GEOID: "13169",
        NAME: "Jones",
        LSAD: "06",
        ALAND: 1020296386,
        AWATER: 3827965
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.816048, 33.131815999135974],
            [-83.545876, 33.171943999135451],
            [-83.444375, 33.184941999135276],
            [-83.42909, 33.185351999135271],
            [-83.414997, 33.112832999136231],
            [-83.357685, 32.926140999138703],
            [-83.40699, 32.898219999139094],
            [-83.513511, 32.844867999139808],
            [-83.56507, 32.877163999139377],
            [-83.658601, 32.887835999139234],
            [-83.696017, 32.916869999138825],
            [-83.710685, 32.952791999138348],
            [-83.72537, 33.032665999137286],
            [-83.816048, 33.131815999135974]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "107",
        COUNTYNS: "00069172",
        AFFGEOID: "0500000US05107",
        GEOID: "05107",
        NAME: "Phillips",
        LSAD: "06",
        ALAND: 1801429477,
        AWATER: 82378714
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.078628, 34.143728999123148],
            [-91.056276, 34.336604999120823],
            [-91.002693, 34.33635299912082],
            [-91.000459, 34.469397999119252],
            [-91.054774, 34.470879999119227],
            [-91.051918, 34.645720999117188],
            [-90.589517, 34.641393999117234],
            [-90.573288412618297, 34.633670505585322],
            [-90.587224, 34.615731999117529],
            [-90.549244, 34.56810099911808],
            [-90.569347, 34.524866999118601],
            [-90.583717, 34.458828999119369],
            [-90.575336, 34.415151999119878],
            [-90.641398, 34.38386899912026],
            [-90.660404, 34.335759999120832],
            [-90.729131, 34.364205999120486],
            [-90.765174, 34.342817999120761],
            [-90.752681, 34.289265999121376],
            [-90.812829, 34.279437999121505],
            [-90.839981, 34.236113999122033],
            [-90.89456, 34.224379999122156],
            [-90.882701, 34.184363999122652],
            [-90.894385, 34.160952999122934],
            [-90.938064, 34.14875399912308],
            [-90.944080760193799, 34.120065293900822],
            [-90.944795524309498, 34.116657204437672],
            [-90.973313, 34.118686999123454],
            [-91.117905, 34.119104999123444],
            [-91.078628, 34.143728999123148]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "170",
        COUNTYNS: "01419976",
        AFFGEOID: "0500000US02170",
        GEOID: "02170",
        NAME: "Matanuska-Susitna",
        LSAD: "04",
        ALAND: 63760578688,
        AWATER: 1704714297
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-153.001506, 62.727440999232414],
            [-152.997647, 62.727400999232422],
            [-151.893784, 62.725863999232374],
            [-151.894015, 62.798057999233755],
            [-150.720916, 63.063183999238881],
            [-149.526148, 63.333216999244172],
            [-148.025618, 63.333232999244174],
            [-148.025627, 63.477608999247011],
            [-146.999966, 63.47964499924705],
            [-146.489541, 63.48291299924712],
            [-146.494311, 63.16897499924093],
            [-146.494296, 62.957863999236835],
            [-146.436899, 62.856990999234888],
            [-146.436884, 62.510452999228292],
            [-146.423984, 62.250510999223387],
            [-146.979807, 62.250496999223394],
            [-146.960978, 62.163851999221798],
            [-146.960952, 61.817271999215407],
            [-146.942559, 61.470674999209137],
            [-147.206605, 61.470662999209125],
            [-147.206597, 61.426264999208328],
            [-148.462471, 61.426266999208323],
            [-149.180062, 61.426249999208338],
            [-149.240449, 61.484024999209367],
            [-149.35944, 61.484024999209367],
            [-149.748369, 61.397370999207823],
            [-149.897622, 61.267386999205513],
            [-149.987495, 61.224063999204738],
            [-150.221240669872003, 61.193170799141789],
            [-150.204894, 61.259547999205367],
            [-150.425, 61.245551999205126],
            [-150.535997, 61.269723999205539],
            [-150.679902, 61.265887999205475],
            [-150.827295, 61.228389999204822],
            [-150.939251, 61.210298999204497],
            [-150.974011916343017, 61.194467297903699],
            [-150.974014, 61.252939999205246],
            [-151.332802, 61.252935999205256],
            [-151.332784, 61.42623899920833],
            [-151.857505, 61.426217999208333],
            [-152.999882, 61.426171999208329],
            [-152.999839, 62.292620999224198],
            [-153.001997, 62.68149199923154],
            [-153.001506, 62.727440999232414]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "049",
        COUNTYNS: "01209178",
        AFFGEOID: "0500000US42049",
        GEOID: "42049",
        NAME: "Erie",
        LSAD: "06",
        ALAND: 2069615830,
        AWATER: 1966184465
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.519425, 41.97752299906184],
            [-80.329976, 42.036167999061647],
            [-80.154084, 42.114756999061385],
            [-80.136213, 42.149936999061282],
            [-80.088512, 42.173183999061202],
            [-80.021164925654503, 42.163240308077228],
            [-80.020323, 42.163115999061219],
            [-79.923924, 42.207545999061097],
            [-79.844661, 42.235485999061005],
            [-79.761951, 42.269859999060898],
            [-79.762122, 42.131245999061328],
            [-79.761313, 41.998807999061782],
            [-79.610835774986697, 41.998519903170163],
            [-79.612072, 41.850055999062285],
            [-80.206545, 41.849044999062279],
            [-80.519396726690502, 41.849562989621596],
            [-80.519425, 41.97752299906184]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "141",
        COUNTYNS: "01383855",
        AFFGEOID: "0500000US48141",
        GEOID: "48141",
        NAME: "El Paso",
        LSAD: "06",
        ALAND: 2624144579,
        AWATER: 5443740
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.623445, 31.914033999152835],
            [-106.630114, 31.971257999152019],
            [-106.618486, 32.000494999151599],
            [-106.377173188246999, 32.001240100189079],
            [-106.200699, 32.001784999151575],
            [-105.997971151664004, 32.001974589850576],
            [-105.994756, 31.679687999156268],
            [-105.993255, 31.47839199915925],
            [-105.954328, 31.423467999160074],
            [-105.996429378287004, 31.387840128673201],
            [-106.004926, 31.392457999160541],
            [-106.080258, 31.398701999160448],
            [-106.175675, 31.456278999159579],
            [-106.236804, 31.513375999158733],
            [-106.280811, 31.562061999158004],
            [-106.303536, 31.620412999157143],
            [-106.352610998498008, 31.686946415659769],
            [-106.370139, 31.71070999915581],
            [-106.41794, 31.752008999155201],
            [-106.467642, 31.75960799915509],
            [-106.484642, 31.747808999155261],
            [-106.528242, 31.783147999154753],
            [-106.581344, 31.813905999154301],
            [-106.63588, 31.871513999153454],
            [-106.623445, 31.914033999152835]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "019",
        COUNTYNS: "01448024",
        AFFGEOID: "0500000US49019",
        GEOID: "49019",
        NAME: "Grand",
        LSAD: "06",
        ALAND: 9512361722,
        AWATER: 31552277
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.140451, 38.92432199907757],
            [-110.150353, 38.979792999077183],
            [-110.069114, 38.978982999077203],
            [-110.003984, 38.949910999077396],
            [-110.041176, 38.99351599907709],
            [-110.096336, 38.986256999077149],
            [-110.156222, 39.032653999076828],
            [-110.099411, 39.13627599907614],
            [-110.098763, 39.189508999075791],
            [-110.05457, 39.228282999075525],
            [-110.07727, 39.275868999075222],
            [-110.010617, 39.416321999074334],
            [-110.024118, 39.469268999073982],
            [-110.023963, 39.469267999073985],
            [-109.953349, 39.461800999074036],
            [-109.106972, 39.461978999074034],
            [-109.051066846272988, 39.497738808440012],
            [-109.051223831016998, 39.366677548660938],
            [-109.051512, 39.126094999076201],
            [-109.05996197258898, 38.499989030101396],
            [-109.059962, 38.499986999080583],
            [-110.025402, 38.499980999080591],
            [-109.994283, 38.527117999080403],
            [-110.057897, 38.583739999079981],
            [-110.036344, 38.652310999079475],
            [-110.070489, 38.656275999079455],
            [-110.11294, 38.731650999078923],
            [-110.083861, 38.75195199907877],
            [-110.179252, 38.907247999077683],
            [-110.140451, 38.92432199907757]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "043",
        COUNTYNS: "01514052",
        AFFGEOID: "0500000US53043",
        GEOID: "53043",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 5984258952,
        AWATER: 75428245
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-118.973504, 47.943413999061903],
            [-118.945143, 47.942280999061893],
            [-118.85196, 47.957905999061957],
            [-118.711682, 47.90334699906176],
            [-118.688394, 47.942717999061898],
            [-118.570396, 47.932317999061866],
            [-118.54409, 47.883972999061697],
            [-118.369064, 47.83268499906152],
            [-118.340938, 47.894315999061732],
            [-118.246103, 47.943603999061907],
            [-118.212323, 47.945492999061905],
            [-118.078182, 47.800061999061413],
            [-117.881473, 47.84748999906158],
            [-117.820947, 47.825774999061501],
            [-117.823629, 47.260219999059821],
            [-117.96093, 47.260567999059823],
            [-118.979777, 47.26170899905982],
            [-118.973504, 47.943413999061903]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "067",
        COUNTYNS: "00695757",
        AFFGEOID: "0500000US28067",
        GEOID: "28067",
        NAME: "Jones",
        LSAD: "06",
        ALAND: 1799558905,
        AWATER: 12676802
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.401227, 31.796862999154545],
            [-89.31656, 31.802089999154475],
            [-88.943355, 31.824564999154145],
            [-88.943468, 31.433457999159927],
            [-89.146092, 31.434051999159919],
            [-89.399178, 31.434028999159921],
            [-89.401227, 31.796862999154545]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "073",
        COUNTYNS: "01719587",
        AFFGEOID: "0500000US30073",
        GEOID: "30073",
        NAME: "Pondera",
        LSAD: "06",
        ALAND: 4207608379,
        AWATER: 43980663
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.349046, 48.310262999063234],
            [-112.575864, 48.309683999063225],
            [-112.576982, 48.483347999063916],
            [-112.184784, 48.477577999063897],
            [-111.990944, 48.438545999063734],
            [-111.990889, 48.394264999063559],
            [-111.79591, 48.393080999063557],
            [-111.795855, 48.349811999063384],
            [-111.665756, 48.349455999063387],
            [-111.665599, 48.219548999062901],
            [-111.409018, 48.219541999062884],
            [-111.409097, 48.132217999062568],
            [-111.408514, 47.987177999062055],
            [-111.984263, 47.98459199906204],
            [-111.984125, 48.042766999062252],
            [-112.048342, 48.042908999062234],
            [-112.047999, 48.086611999062399],
            [-112.177751, 48.087161999062396],
            [-112.177755, 48.130716999062564],
            [-113.014811, 48.131029999062562],
            [-113.230906, 48.170425999062722],
            [-113.22768, 48.235751999062956],
            [-113.349046, 48.310262999063234]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "023",
        COUNTYNS: "00863599",
        AFFGEOID: "0500000US32023",
        GEOID: "32023",
        NAME: "Nye",
        LSAD: "06",
        ALAND: 47090938928,
        AWATER: 43207981
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-118.196274, 38.999925999077057],
            [-117.865116, 39.073653999076555],
            [-117.775468, 39.093424999076419],
            [-117.331857, 39.163429999075959],
            [-116.600946, 39.161462999075972],
            [-115.907071, 39.161847999075967],
            [-115.000846, 38.677321999079304],
            [-115.000846, 38.050756999084001],
            [-115.893857, 38.05053099908401],
            [-115.896925, 36.842084999094318],
            [-115.895753, 36.172226999100744],
            [-115.845803, 36.122047999101227],
            [-115.846113922762001, 35.96355267221373],
            [-115.892975, 35.999966999102462],
            [-116.093601, 36.155804999100901],
            [-116.375875, 36.372561999098771],
            [-116.488233, 36.45909699909793],
            [-117.000895, 36.847693999094275],
            [-117.166000564031009, 36.971207537683448],
            [-117.165612, 38.0016919990844],
            [-117.691021, 38.473796999080776],
            [-118.196235, 38.919057999077602],
            [-118.196274, 38.999925999077057]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "037",
        COUNTYNS: "00399758",
        AFFGEOID: "0500000US16037",
        GEOID: "16037",
        NAME: "Custer",
        LSAD: "06",
        ALAND: 12748200986,
        AWATER: 42566923
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-115.281217, 44.610922999056825],
            [-115.165735, 44.650284999056815],
            [-115.151937, 44.728591999056789],
            [-115.091922, 44.766725999056781],
            [-114.956116, 44.720981999056782],
            [-114.812407, 44.808345999056776],
            [-114.820083, 44.747376999056783],
            [-114.710871, 44.650821999056816],
            [-114.662687, 44.662108999056819],
            [-114.566975, 44.575731999056849],
            [-114.45184, 44.611740999056828],
            [-114.372843, 44.709716999056788],
            [-114.404154, 44.739546999056785],
            [-114.221534, 44.879076999056757],
            [-114.191722, 44.837932999056775],
            [-114.205244, 44.744021999056784],
            [-114.084861, 44.755946999056782],
            [-114.003915, 44.634700999056825],
            [-113.816398, 44.494054999056878],
            [-113.501882, 44.422062999056934],
            [-113.451832, 44.454935999056893],
            [-113.339445, 44.316076999056996],
            [-113.320609, 44.233163999057048],
            [-113.416525, 44.233363999057062],
            [-113.457816, 44.059527999057224],
            [-113.453985, 43.873907999057423],
            [-113.36399, 43.794648999057536],
            [-113.41444, 43.816925999057503],
            [-113.528063, 43.719090999057634],
            [-113.759998, 43.622680999057785],
            [-113.798441, 43.569216999057858],
            [-113.875329, 43.65050399905774],
            [-113.97447, 43.708185999057648],
            [-114.029718, 43.773360999057566],
            [-114.070019, 43.734695999057614],
            [-114.275725, 43.831137999057482],
            [-114.292684, 43.88714299905741],
            [-114.359293, 43.874176999057418],
            [-114.507778, 43.899089999057395],
            [-114.56235, 43.844367999057468],
            [-114.681168, 43.927704999057354],
            [-114.81379, 43.92777199905737],
            [-114.813873, 43.992940999057282],
            [-114.873372, 43.99294899905729],
            [-114.971351, 43.938307999057358],
            [-114.990777, 43.949722999057329],
            [-115.04356, 44.058819999057214],
            [-115.024181, 44.155477999057119],
            [-115.167684, 44.20283999905709],
            [-115.178964, 44.296520999057016],
            [-115.294614, 44.33944499905698],
            [-115.228222, 44.423520999056926],
            [-115.246934, 44.519378999056869],
            [-115.304994, 44.579856999056844],
            [-115.281217, 44.610922999056825]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "043",
        COUNTYNS: "01622964",
        AFFGEOID: "0500000US26043",
        GEOID: "26043",
        NAME: "Dickinson",
        LSAD: "06",
        ALAND: 1970831145,
        AWATER: 40519954
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.135067, 45.821693999057203],
            [-88.073944, 45.875592999057261],
            [-88.115346, 45.922210999057299],
            [-88.116856537376094, 45.922811241319998],
            [-88.117407, 46.246617999057719],
            [-87.615597, 46.246652999057716],
            [-87.617091, 45.986013999057384],
            [-87.617396, 45.898845999057286],
            [-87.696946, 45.898841999057261],
            [-87.697159, 45.722959999057103],
            [-87.833049468035298, 45.722752574404005],
            [-87.879812, 45.754842999057139],
            [-87.96697, 45.764020999057138],
            [-87.995876, 45.795434999057171],
            [-88.048514, 45.782548999057155],
            [-88.057010465949006, 45.784977029200867],
            [-88.105518, 45.798838999057175],
            [-88.135067, 45.821693999057203]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "133",
        COUNTYNS: "01074079",
        AFFGEOID: "0500000US39133",
        GEOID: "39133",
        NAME: "Portage",
        LSAD: "06",
        ALAND: 1262374971,
        AWATER: 43121843
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.393282, 41.02543499906561],
            [-81.392534, 41.100434999065271],
            [-81.392114, 41.135721999065133],
            [-81.391831, 41.276653999064521],
            [-81.391694, 41.34827199906421],
            [-81.003319, 41.347859999064219],
            [-81.003191, 41.347858999064222],
            [-81.00288, 41.271841999064534],
            [-81.00229, 41.134188999065131],
            [-81.00236, 41.062092999065442],
            [-81.001695, 40.987782999065786],
            [-81.086312, 40.988030999065771],
            [-81.183632, 40.988208999065776],
            [-81.195679, 40.988204999065779],
            [-81.357529, 40.98871699906578],
            [-81.393249, 40.98853099906578],
            [-81.393282, 41.02543499906561]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "073",
        COUNTYNS: "00695760",
        AFFGEOID: "0500000US28073",
        GEOID: "28073",
        NAME: "Lamar",
        LSAD: "06",
        ALAND: 1287499611,
        AWATER: 8599890
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.654212, 31.433804999159925],
            [-89.586819, 31.433687999159918],
            [-89.451616, 31.434028999159921],
            [-89.451073, 31.34638299916125],
            [-89.347863, 31.346699999161238],
            [-89.34895, 31.010369999166361],
            [-89.425337, 30.982451999166795],
            [-89.501411, 31.013906999166306],
            [-89.654038, 31.002501999166476],
            [-89.654212, 31.433804999159925]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "027",
        COUNTYNS: "01804493",
        AFFGEOID: "0500000US72027",
        GEOID: "72027",
        NAME: "Camuy",
        LSAD: "13",
        ALAND: 120058280,
        AWATER: 40430074
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.901566758551709, 18.488261256866789],
            [-66.836591129039007, 18.491129643784625],
            [-66.810675, 18.386626999434576],
            [-66.824223, 18.342997999435724],
            [-66.876876, 18.356773999435362],
            [-66.893339, 18.367088999435087],
            [-66.8989, 18.366107999435116],
            [-66.901566758551709, 18.488261256866789]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "139",
        COUNTYNS: "00516916",
        AFFGEOID: "0500000US21139",
        GEOID: "21139",
        NAME: "Livingston",
        LSAD: "06",
        ALAND: 810999158,
        AWATER: 75642166
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.486947, 37.339595999089894],
            [-88.465861, 37.40054699908935],
            [-88.418594, 37.421986999089171],
            [-88.415902178775681, 37.421220636808073],
            [-88.358436, 37.404859999089318],
            [-88.318134, 37.398006999089382],
            [-88.21898, 37.273144999090455],
            [-88.193519, 37.147255999091577],
            [-88.231145, 37.081574999092155],
            [-88.209221, 36.999975999092875],
            [-88.240371, 36.981910999093039],
            [-88.339292, 37.061215999092333],
            [-88.482957, 37.022565999092677],
            [-88.483803276979998, 37.068080268987771],
            [-88.476127, 37.068222999092264],
            [-88.444605, 37.098600999092],
            [-88.424776, 37.14990099909155],
            [-88.471753, 37.22015499909093],
            [-88.514661, 37.290947999090314],
            [-88.486947, 37.339595999089894]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "043",
        COUNTYNS: "01135857",
        AFFGEOID: "0500000US41043",
        GEOID: "41043",
        NAME: "Linn",
        LSAD: "06",
        ALAND: 5923493230,
        AWATER: 50497756
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.260151, 44.555147999056857],
            [-123.17088, 44.634244999056818],
            [-123.073574, 44.650494999056818],
            [-123.149025, 44.720224999056789],
            [-123.143843, 44.748913999056782],
            [-123.042009, 44.737718999056789],
            [-123.006847, 44.686897999056804],
            [-122.802457, 44.790863999056768],
            [-122.690864, 44.775416999056787],
            [-122.620463, 44.78921299905678],
            [-122.480122, 44.755818999056778],
            [-122.276675, 44.746134999056792],
            [-122.230559, 44.697866999056792],
            [-122.138702, 44.725178999056794],
            [-122.033006, 44.685690999056803],
            [-121.800015, 44.683424999056804],
            [-121.793895, 44.594055999056835],
            [-121.84688, 44.491089999056889],
            [-121.843138, 44.392629999056943],
            [-121.841346, 44.285651999057016],
            [-121.819429, 44.262393999057039],
            [-122.029136, 44.25384399905704],
            [-122.32715, 44.252840999057042],
            [-122.384561, 44.21706299905707],
            [-122.577019, 44.227880999057071],
            [-122.646812, 44.266617999057033],
            [-122.762735, 44.290536999057018],
            [-122.865465, 44.287316999057005],
            [-122.903999, 44.259128999057047],
            [-122.905755, 44.200735999057081],
            [-123.165428, 44.20007099905709],
            [-123.180388, 44.283724999057029],
            [-123.245678, 44.332098999056981],
            [-123.214683, 44.50768099905688],
            [-123.260151, 44.555147999056857]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "117",
        COUNTYNS: "01623001",
        AFFGEOID: "0500000US26117",
        GEOID: "26117",
        NAME: "Montcalm",
        LSAD: "06",
        ALAND: 1826655021,
        AWATER: 40083131
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.562348, 43.468129999058014],
            [-85.084996, 43.466189999058031],
            [-84.845962, 43.466157999058041],
            [-84.836889, 43.118850999058701],
            [-85.1936, 43.119533999058689],
            [-85.312513, 43.118792999058691],
            [-85.31225, 43.206437999058515],
            [-85.312376, 43.294204999058351],
            [-85.562538, 43.294270999058348],
            [-85.562348, 43.468129999058014]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "033",
        COUNTYNS: "01247993",
        AFFGEOID: "0500000US45033",
        GEOID: "45033",
        NAME: "Dillon",
        LSAD: "06",
        ALAND: 1049113581,
        AWATER: 4545513
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.633413, 34.297993999121275],
            [-79.450277289043385, 34.620608996126172],
            [-79.358317, 34.545357999118352],
            [-79.071254201048191, 34.299313025963158],
            [-79.086067, 34.294955999121314],
            [-79.128165, 34.253651999121814],
            [-79.21432, 34.255538999121796],
            [-79.380264, 34.300571999121246],
            [-79.480712, 34.288080999121398],
            [-79.54793, 34.23091299912209],
            [-79.601003, 34.29053199912137],
            [-79.633413, 34.297993999121275]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "175",
        COUNTYNS: "00485050",
        AFFGEOID: "0500000US20175",
        GEOID: "20175",
        NAME: "Seward",
        LSAD: "06",
        ALAND: 1655865965,
        AWATER: 2777350
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.067904, 37.387778999089463],
            [-100.652728, 37.387626999089456],
            [-100.63392, 37.381173999089533],
            [-100.633324986158001, 37.000173608145779],
            [-100.855634, 36.998625999092894],
            [-100.945466793078012, 36.99824682782171],
            [-101.066450215659998, 36.99773617423331],
            [-101.067904, 37.387778999089463]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "069",
        COUNTYNS: "01248011",
        AFFGEOID: "0500000US45069",
        GEOID: "45069",
        NAME: "Marlboro",
        LSAD: "06",
        ALAND: 1242867984,
        AWATER: 14488283
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.924684253136405, 34.807829018866819],
            [-79.692946383709298, 34.804962306038547],
            [-79.675299, 34.804743999115352],
            [-79.461791031001297, 34.630030677708859],
            [-79.450277289043385, 34.620608996126172],
            [-79.633413, 34.297993999121275],
            [-79.657764, 34.30518999912119],
            [-79.750566, 34.436796999119629],
            [-79.752769, 34.51405899911871],
            [-79.829981, 34.531924999118516],
            [-79.777461, 34.613491999117556],
            [-79.871959, 34.693840999116624],
            [-79.927398038771997, 34.807862589746321],
            [-79.924684253136405, 34.807829018866819]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "045",
        COUNTYNS: "00516869",
        AFFGEOID: "0500000US21045",
        GEOID: "21045",
        NAME: "Casey",
        LSAD: "06",
        ALAND: 1150571810,
        AWATER: 3892620
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.131162, 37.384264999089503],
            [-85.073772, 37.413668999089253],
            [-85.045023, 37.412245999089258],
            [-85.039674, 37.545231999088124],
            [-84.8981, 37.532112999088234],
            [-84.847183, 37.548454999088101],
            [-84.850816, 37.427142999089135],
            [-84.722826, 37.3600429990897],
            [-84.706402, 37.299539999090236],
            [-84.720712, 37.238327999090764],
            [-84.901112, 37.116296999091837],
            [-84.954406, 37.102270999091971],
            [-85.043316, 37.186027999091223],
            [-85.056898, 37.257040999090606],
            [-85.165174, 37.31039599909014],
            [-85.131162, 37.384264999089503]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "113",
        COUNTYNS: "01581116",
        AFFGEOID: "0500000US55113",
        GEOID: "55113",
        NAME: "Sawyer",
        LSAD: "06",
        ALAND: 3256422227,
        AWATER: 240678258
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.551282, 46.157044999057597],
            [-90.924583, 46.15461599905759],
            [-90.925174, 45.981054999057378],
            [-90.676968, 45.981553999057368],
            [-90.678749, 45.638263999057031],
            [-91.540294, 45.637604999057032],
            [-91.551282, 46.157044999057597]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "063",
        COUNTYNS: "01034217",
        AFFGEOID: "0500000US38063",
        GEOID: "38063",
        NAME: "Nelson",
        LSAD: "06",
        ALAND: 2542787677,
        AWATER: 70033883
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.525609, 48.021149999062175],
            [-98.422659, 48.021114999062164],
            [-98.4221, 48.194823999062798],
            [-98.292184, 48.195176999062795],
            [-97.903434, 48.194896999062806],
            [-97.882179, 48.020802999062177],
            [-97.882405, 47.672178999061003],
            [-97.98481, 47.672299999061011],
            [-98.500181, 47.672412999061017],
            [-98.525178, 47.672496999061003],
            [-98.525516, 47.846275999061561],
            [-98.525555, 47.915055999061799],
            [-98.525609, 48.021149999062175]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "023",
        COUNTYNS: "00306909",
        AFFGEOID: "0500000US12023",
        GEOID: "12023",
        NAME: "Columbia",
        LSAD: "06",
        ALAND: 2065628007,
        AWATER: 9773963
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.800477, 29.932125999183576],
            [-82.761519, 29.965516999183006],
            [-82.793975, 29.986201999182672],
            [-82.794594, 30.337023999176964],
            [-82.788503, 30.324012999177178],
            [-82.738443, 30.325782999177147],
            [-82.689922, 30.333108999177028],
            [-82.647198, 30.394796999176041],
            [-82.726583, 30.559485999173408],
            [-82.68952979847279, 30.597889289814596],
            [-82.584005186419489, 30.591636578260498],
            [-82.459792435413675, 30.584276527479215],
            [-82.458352, 30.164594999179762],
            [-82.458364, 30.136448999180224],
            [-82.575991, 29.990856999182604],
            [-82.529705, 29.940880999183424],
            [-82.640627, 29.828848999185279],
            [-82.658554, 29.830143999185253],
            [-82.768445, 29.89700699918415],
            [-82.770379, 29.928108999183632],
            [-82.800477, 29.932125999183576]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "061",
        COUNTYNS: "01622973",
        AFFGEOID: "0500000US26061",
        GEOID: "26061",
        NAME: "Houghton",
        LSAD: "06",
        ALAND: 2613557367,
        AWATER: 1275355802
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.989529, 46.680118999058486],
            [-88.864473, 46.67931099905848],
            [-88.863906, 46.765012999058648],
            [-88.93248, 46.764786999058657],
            [-88.933368451341707, 47.033599096915253],
            [-88.924492, 47.042155999059261],
            [-88.88914, 47.100574999059411],
            [-88.814834, 47.141398999059518],
            [-88.69966, 47.204830999059681],
            [-88.584912, 47.242360999059777],
            [-88.51294650563608, 47.28610724677489],
            [-88.298992, 47.286096999059886],
            [-88.298436, 47.19978199905966],
            [-88.200450675933695, 47.199716895041654],
            [-88.239895, 47.139435999059508],
            [-88.340052, 47.080493999059357],
            [-88.385606, 47.004521999059172],
            [-88.439363185943805, 46.941982424059432],
            [-88.552185, 46.938532999059021],
            [-88.551943, 46.851794999058825],
            [-88.678983, 46.851821999058835],
            [-88.679392, 46.420138999058004],
            [-88.991236, 46.419703999058001],
            [-88.989529, 46.680118999058486]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "309",
        COUNTYNS: "01383946",
        AFFGEOID: "0500000US48309",
        GEOID: "48309",
        NAME: "McLennan",
        LSAD: "06",
        ALAND: 2686101576,
        AWATER: 59883892
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.60523, 31.587761999157628],
            [-97.277265, 31.745491999155291],
            [-97.037266, 31.863078999153579],
            [-96.932215, 31.70888699915584],
            [-96.801123, 31.522268999158602],
            [-97.278113, 31.279798999162235],
            [-97.343426, 31.244214999162779],
            [-97.418606, 31.320201999161633],
            [-97.60523, 31.587761999157628]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "041",
        COUNTYNS: "01581080",
        AFFGEOID: "0500000US55041",
        GEOID: "55041",
        NAME: "Forest",
        LSAD: "06",
        ALAND: 2626878499,
        AWATER: 83594186
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.047795, 45.982344999057368],
            [-88.933222, 45.982275999057364],
            [-88.932768451009593, 46.072107162833483],
            [-88.811948, 46.021608999057413],
            [-88.739994, 46.027307999057427],
            [-88.683232208499376, 46.014465669377707],
            [-88.675821, 45.722899999057105],
            [-88.425302, 45.722425999057094],
            [-88.428102, 45.377009999056867],
            [-88.677996, 45.378682999056863],
            [-88.926025, 45.378636999056852],
            [-88.923062, 45.465136999056917],
            [-89.04649, 45.464438999056924],
            [-89.047601, 45.895354999057268],
            [-89.047795, 45.982344999057368]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "113",
        COUNTYNS: "00161583",
        AFFGEOID: "0500000US01113",
        GEOID: "01113",
        NAME: "Russell",
        LSAD: "06",
        ALAND: 1660557977,
        AWATER: 15661281
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.434045, 32.409839999145788],
            [-85.330143, 32.410841999145774],
            [-85.333843, 32.468638999144964],
            [-85.059294, 32.472908999144913],
            [-85.001130839123689, 32.510154787542191],
            [-84.999786756310087, 32.507066967928139],
            [-84.971831, 32.442842999145334],
            [-84.981150294133698, 32.379040075828023],
            [-84.983466, 32.363185999146438],
            [-85.008096, 32.336676999146817],
            [-84.955704, 32.305909999147246],
            [-84.891841, 32.263397999147841],
            [-84.919942147925894, 32.23084820008161],
            [-84.930127, 32.219050999148472],
            [-84.997765, 32.185444999148949],
            [-85.058749, 32.136017999149658],
            [-85.047063, 32.087388999150349],
            [-85.0514111279128, 32.062256082772706],
            [-85.185067, 32.061707999150705],
            [-85.257834, 32.14793099914948],
            [-85.410241, 32.1466509991495],
            [-85.427441, 32.146550999149504],
            [-85.433543, 32.234647999148251],
            [-85.434045, 32.409839999145788]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "169",
        COUNTYNS: "00659529",
        AFFGEOID: "0500000US27169",
        GEOID: "27169",
        NAME: "Winona",
        LSAD: "06",
        ALAND: 1621558336,
        AWATER: 40131783
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.079491, 44.106987999057175],
            [-92.079236, 44.192965999057094],
            [-91.854499975297685, 44.197226518609298],
            [-91.817302, 44.164234999057115],
            [-91.719097, 44.128852999057152],
            [-91.647873, 44.064108999057204],
            [-91.573283, 44.026900999057261],
            [-91.5592155630397, 44.024209315638458],
            [-91.440536, 44.001500999057278],
            [-91.423568699817295, 43.984296879379905],
            [-91.357426, 43.917230999057381],
            [-91.291002, 43.852732999057466],
            [-91.287655627367101, 43.847064999057466],
            [-91.730424, 43.846934999057467],
            [-92.079292, 43.847079999057463],
            [-92.079491, 44.106987999057175]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "047",
        COUNTYNS: "01531928",
        AFFGEOID: "0500000US53047",
        GEOID: "53047",
        NAME: "Okanogan",
        LSAD: "06",
        ALAND: 13639309300,
        AWATER: 120452753
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.851527133017996, 49.000591151901361],
            [-120.716604, 49.000187999066156],
            [-120.376216, 49.000704999066166],
            [-120.001199, 48.999417999066154],
            [-119.4577, 49.000260999066157],
            [-119.132102, 49.000261999066161],
            [-118.836613035580001, 49.000307758363761],
            [-118.837006, 48.653868999064628],
            [-118.869633, 48.653825999064615],
            [-118.86952, 48.481625999063915],
            [-118.843888, 48.4817389990639],
            [-118.845785, 47.963477999061972],
            [-118.85196, 47.957905999061957],
            [-118.945143, 47.942280999061893],
            [-118.973504, 47.943413999061903],
            [-118.982234, 47.96202499906196],
            [-118.950782, 48.018883999062162],
            [-119.00319, 48.043262999062257],
            [-119.05697, 48.136973999062583],
            [-119.150983, 48.146066999062612],
            [-119.25685, 48.102026999062453],
            [-119.298585, 48.115484999062517],
            [-119.343523, 48.049431999062264],
            [-119.436257, 48.076687999062358],
            [-119.517571, 48.062614999062312],
            [-119.652468, 48.000220999062094],
            [-119.693712, 48.032055999062209],
            [-119.669707, 48.075231999062353],
            [-119.774593, 48.104212999062462],
            [-119.89535, 48.050043999062268],
            [-119.870595, 47.960454999061952],
            [-120.066023, 47.966441999061978],
            [-120.143075, 48.064881999062322],
            [-120.21306, 48.079043999062364],
            [-120.361288, 48.157568999062669],
            [-120.346284, 48.199985999062811],
            [-120.469124, 48.268979999063077],
            [-120.508212, 48.310343999063235],
            [-120.583166, 48.319127999063262],
            [-120.560206, 48.361047999063423],
            [-120.649933, 48.398138999063576],
            [-120.633344, 48.46811799906385],
            [-120.652283, 48.536938999064127],
            [-120.702069, 48.53158899906412],
            [-120.688515, 48.5757049990643],
            [-120.788307, 48.625114999064508],
            [-120.751904, 48.657001999064633],
            [-120.655857, 48.724341999064926],
            [-120.735427, 48.786486999065204],
            [-120.735281, 48.864373999065542],
            [-120.851527133017996, 49.000591151901361]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "259",
        COUNTYNS: "01383915",
        AFFGEOID: "0500000US48259",
        GEOID: "48259",
        NAME: "Kendall",
        LSAD: "06",
        ALAND: 1715747530,
        AWATER: 1496797
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.920147, 30.138289999180181],
            [-98.587897, 30.138953999180181],
            [-98.414018, 29.937556999183478],
            [-98.646124, 29.745180999186665],
            [-98.738381, 29.716542999187141],
            [-98.778782, 29.720166999187089],
            [-98.917725, 29.781397999186062],
            [-98.920147, 30.138289999180181]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "145",
        COUNTYNS: "00450387",
        AFFGEOID: "0500000US18145",
        GEOID: "18145",
        NAME: "Shelby",
        LSAD: "06",
        ALAND: 1064884352,
        AWATER: 4149819
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.95208, 39.638344999072949],
            [-85.951721, 39.697135999072586],
            [-85.633228, 39.698461999072585],
            [-85.629327, 39.452749999074094],
            [-85.63063, 39.350204999074741],
            [-85.684515, 39.350048999074744],
            [-85.952005, 39.347372999074771],
            [-85.95208, 39.638344999072949]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "113",
        COUNTYNS: "01074069",
        AFFGEOID: "0500000US39113",
        GEOID: "39113",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 1195936288,
        AWATER: 6693457
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.485367, 39.918490999071288],
            [-84.425902, 39.919621999071282],
            [-84.157671, 39.922969999071256],
            [-84.157223, 39.885629999071483],
            [-84.051039, 39.879806999071512],
            [-84.053736, 39.850457999071679],
            [-84.092938, 39.838344999071758],
            [-84.114195, 39.577982999073313],
            [-84.365232, 39.589492999073236],
            [-84.479213, 39.591023999073236],
            [-84.485367, 39.918490999071288]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "09",
        COUNTYFP: "011",
        COUNTYNS: "00212799",
        AFFGEOID: "0500000US09011",
        GEOID: "09011",
        NAME: "New London",
        LSAD: "06",
        ALAND: 1722708285,
        AWATER: 276666169
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.46673, 41.583898999063265],
            [-72.410367, 41.6025809990632],
            [-72.328095, 41.605470999063186],
            [-72.334226, 41.64359799906304],
            [-72.239559, 41.714190999062779],
            [-72.157546, 41.657626999062991],
            [-72.146717, 41.667168999062959],
            [-72.062051, 41.652238999063016],
            [-71.954983, 41.634798999063086],
            [-71.857917, 41.644226999063044],
            [-71.789464590839003, 41.640017189769949],
            [-71.789356, 41.596909999063222],
            [-71.789358672742296, 41.596852159405927],
            [-71.791719061747202, 41.545772003398433],
            [-71.797683, 41.416708999063935],
            [-71.839649, 41.412118999063964],
            [-71.835951, 41.353934999064194],
            [-71.860513, 41.320247999064335],
            [-71.886302, 41.336409999064266],
            [-71.956747, 41.329870999064291],
            [-72.021898, 41.31683799906434],
            [-72.094443, 41.314163999064348],
            [-72.134221, 41.299397999064418],
            [-72.201422, 41.315696999064357],
            [-72.235531, 41.300412999064413],
            [-72.293044, 41.280043999064496],
            [-72.340012908701908, 41.277849261302904],
            [-72.376004, 41.376254999064102],
            [-72.43371, 41.423994999063908],
            [-72.305531, 41.436281999063851],
            [-72.322931, 41.518118999063525],
            [-72.430767, 41.524566999063509],
            [-72.46673, 41.583898999063265]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "003",
        COUNTYNS: "01675840",
        AFFGEOID: "0500000US06003",
        GEOID: "06003",
        NAME: "Alpine",
        LSAD: "06",
        ALAND: 1912292631,
        AWATER: 12557304
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.072484, 38.50986899908051],
            [-120.072392, 38.702766999079124],
            [-119.964948, 38.7759859990786],
            [-119.877287, 38.870192999077943],
            [-119.904315, 38.933323999077508],
            [-119.587679, 38.714733999079037],
            [-119.585406144839013, 38.713150899750246],
            [-119.619066, 38.603528999079842],
            [-119.542367, 38.48165699908072],
            [-119.639205, 38.326879999081875],
            [-119.705385, 38.416101999081214],
            [-119.884749, 38.356184999081648],
            [-120.019951, 38.433520999081082],
            [-120.072566, 38.447080999080974],
            [-120.072484, 38.50986899908051]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "023",
        COUNTYNS: "00465201",
        AFFGEOID: "0500000US19023",
        GEOID: "19023",
        NAME: "Butler",
        LSAD: "06",
        ALAND: 1502550906,
        AWATER: 4194911
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.025144, 42.907548999059159],
            [-92.554211, 42.907111999059161],
            [-92.554492, 42.642313999059823],
            [-92.5543, 42.555853999060062],
            [-93.027, 42.55680999906005],
            [-93.025144, 42.907548999059159]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "037",
        COUNTYNS: "00422190",
        AFFGEOID: "0500000US17037",
        GEOID: "17037",
        NAME: "DeKalb",
        LSAD: "06",
        ALAND: 1635216352,
        AWATER: 8545243
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.939732, 42.152319999061255],
            [-88.705633, 42.153560999061256],
            [-88.588657, 42.153589999061261],
            [-88.58833, 42.066461999061545],
            [-88.60156, 42.017924999061712],
            [-88.601933, 41.719562999062767],
            [-88.60224, 41.631388999063105],
            [-88.712444, 41.630490999063085],
            [-88.818233, 41.631350999063095],
            [-88.938618, 41.628318999063097],
            [-88.941279, 41.891751999062144],
            [-88.939732, 42.152319999061255]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "065",
        COUNTYNS: "00066860",
        AFFGEOID: "0500000US05065",
        GEOID: "05065",
        NAME: "Izard",
        LSAD: "06",
        ALAND: 1503683620,
        AWATER: 8913818
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.2139, 36.175099999100695],
            [-92.159885, 36.173781999100719],
            [-92.15699, 36.261247999099851],
            [-91.691435, 36.253300999099935],
            [-91.674143, 36.208566999100363],
            [-91.682277, 36.00053499910247],
            [-91.710552, 35.941758999103058],
            [-91.753345, 35.942882999103063],
            [-91.849448, 35.867410999103811],
            [-91.95302, 35.931643999103152],
            [-92.108622, 35.931327999103161],
            [-92.063576, 36.024371999102222],
            [-92.139104, 36.112220999101325],
            [-92.19543, 36.132797999101122],
            [-92.215047, 36.13823199910108],
            [-92.2139, 36.175099999100695]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "067",
        COUNTYNS: "00659479",
        AFFGEOID: "0500000US27067",
        GEOID: "27067",
        NAME: "Kandiyohi",
        LSAD: "06",
        ALAND: 2064978211,
        AWATER: 168506888
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.255206, 45.41252499905687],
            [-95.13173, 45.41240199905689],
            [-94.762934, 45.412860999056889],
            [-94.76308, 45.326099999056858],
            [-94.758189, 44.892096999056761],
            [-95.24852, 44.891309999056766],
            [-95.24649, 45.152383999056788],
            [-95.255196, 45.238711999056804],
            [-95.255206, 45.41252499905687]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "073",
        COUNTYNS: "01101824",
        AFFGEOID: "0500000US40073",
        GEOID: "40073",
        NAME: "Kingfisher",
        LSAD: "06",
        ALAND: 2326224060,
        AWATER: 20348781
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.210544, 36.164889999100808],
            [-98.103904, 36.164876999100812],
            [-97.675617, 36.164662999100813],
            [-97.674026, 35.725969999105281],
            [-98.207104, 35.725139999105288],
            [-98.210544, 36.164889999100808]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "053",
        COUNTYNS: "01248006",
        AFFGEOID: "0500000US45053",
        GEOID: "45053",
        NAME: "Jasper",
        LSAD: "06",
        ALAND: 1696775859,
        AWATER: 114287450
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.284238381765604, 32.547110945428884],
            [-81.014625, 32.753057999141049],
            [-80.902448, 32.621560999142851],
            [-80.869705, 32.66093499914232],
            [-80.836548, 32.608844999143024],
            [-80.81869, 32.463934999145032],
            [-80.841458, 32.385063999146134],
            [-80.934935, 32.300368999147324],
            [-81.006432, 32.306195999147242],
            [-81.016112, 32.24429399914812],
            [-80.867427681891584, 32.078489999150484],
            [-80.885517, 32.034599999151112],
            [-80.943226, 32.05782399915077],
            [-81.006745, 32.101151999150154],
            [-81.038265, 32.084468999150381],
            [-81.113334, 32.113204999149985],
            [-81.119361, 32.177141999149065],
            [-81.147595170455375, 32.227169445745453],
            [-81.153531, 32.237686999148217],
            [-81.128034, 32.276296999147661],
            [-81.133032, 32.334793999146839],
            [-81.1734737944861, 32.384902780032832],
            [-81.194931, 32.411488999145767],
            [-81.194829, 32.465085999145018],
            [-81.274927, 32.544157999143913],
            [-81.284238381765604, 32.547110945428884]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "073",
        COUNTYNS: "01639752",
        AFFGEOID: "0500000US47073",
        GEOID: "47073",
        NAME: "Hawkins",
        LSAD: "06",
        ALAND: 1261465560,
        AWATER: 32543978
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.280595, 36.394688999098541],
            [-83.120966, 36.513882999097405],
            [-83.010789, 36.563690999096927],
            [-82.950519, 36.543004999097128],
            [-82.830433, 36.593760999096645],
            [-82.609181949398987, 36.595089685165135],
            [-82.610175, 36.519299999097356],
            [-82.702279, 36.455498999097962],
            [-82.680677, 36.431799999098196],
            [-82.702566, 36.410411999098393],
            [-82.937183, 36.33479699909914],
            [-83.082312, 36.242295999100037],
            [-83.16259, 36.34529199909904],
            [-83.255292, 36.28909199909959],
            [-83.288896, 36.378788999098703],
            [-83.280595, 36.394688999098541]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "031",
        COUNTYNS: "01720053",
        AFFGEOID: "0500000US30031",
        GEOID: "30031",
        NAME: "Gallatin",
        LSAD: "06",
        ALAND: 6746899240,
        AWATER: 74547800
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.805259, 45.79830599905717],
            [-111.705467, 45.795737999057181],
            [-111.660057, 45.833499999057203],
            [-111.587696, 45.906681999057284],
            [-111.419618, 45.996321999057386],
            [-111.423831, 46.061950999057466],
            [-111.349372, 46.122606999057538],
            [-111.346652, 46.187784999057641],
            [-111.061956, 46.192833999057648],
            [-110.783833, 46.19269899905764],
            [-110.790767, 46.141845999057573],
            [-110.794774, 45.590681999056997],
            [-110.857382, 45.590649999057007],
            [-110.858252, 45.524878999056945],
            [-110.919222, 45.524860999056948],
            [-110.917899, 45.35127299905686],
            [-111.038169, 45.351390999056846],
            [-111.044318545430002, 45.000880002364461],
            [-111.056888, 44.86665799905677],
            [-111.055511, 44.725342999056792],
            [-111.055332727123997, 44.666262308974311],
            [-111.055208, 44.624926999056818],
            [-111.048974, 44.474071999056889],
            [-111.122654, 44.493658999056883],
            [-111.143557, 44.535731999056857],
            [-111.201459, 44.575695999056848],
            [-111.224161, 44.623401999056817],
            [-111.26875, 44.66827899905681],
            [-111.323669, 44.724473999056791],
            [-111.377138236734993, 44.751196413020388],
            [-111.35003, 45.350547999056857],
            [-111.35138, 45.641937999057042],
            [-111.536241, 45.642256999057039],
            [-111.659738, 45.74334399905711],
            [-111.721683, 45.743277999057113],
            [-111.721847, 45.772368999057157],
            [-111.763273, 45.772553999057152],
            [-111.763324, 45.786827999057159],
            [-111.80538, 45.786747999057162],
            [-111.805259, 45.79830599905717]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "25",
        COUNTYFP: "021",
        COUNTYNS: "00606937",
        AFFGEOID: "0500000US25021",
        GEOID: "25021",
        NAME: "Norfolk",
        LSAD: "06",
        ALAND: 1025865057,
        AWATER: 124987270
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.85338, 42.239606999060989],
              [-70.835851775394389, 42.264763263774306],
              [-70.788724, 42.253919999060948],
              [-70.781574545568489, 42.248636945084868],
              [-70.785081, 42.22527599906104],
              [-70.827, 42.20079199906111],
              [-70.8408, 42.213117999061076],
              [-70.85338, 42.239606999060989]
            ]
          ],
          [
            [
              [-71.499689, 42.103489999061424],
              [-71.478522, 42.131377999061336],
              [-71.478119, 42.156781999061252],
              [-71.344083, 42.200978999061107],
              [-71.302922, 42.248273999060963],
              [-71.32975, 42.312990999060759],
              [-71.269958, 42.328085999060718],
              [-71.23106, 42.320735999060737],
              [-71.191155, 42.283058999060863],
              [-71.188167, 42.28041199906086],
              [-71.146642, 42.25575499906094],
              [-71.130771, 42.227925999061029],
              [-71.122856, 42.235224999061003],
              [-71.126377, 42.23916199906099],
              [-71.109347, 42.247989999060962],
              [-71.109544, 42.255411999060939],
              [-71.11326, 42.258925999060928],
              [-71.111737, 42.260468999060926],
              [-71.102691, 42.259883999060932],
              [-71.093737, 42.2671069990609],
              [-71.053284, 42.277501999060867],
              [-71.041694, 42.305297999060784],
              [-70.9930596399482, 42.312892279473168],
              [-70.967351, 42.268167999060907],
              [-70.948497043534289, 42.28235469284315],
              [-70.914091, 42.288799999060842],
              [-70.924877, 42.157579999061241],
              [-70.948963, 42.148155999061288],
              [-71.080483, 42.095538999061439],
              [-71.138619, 42.072812999061519],
              [-71.288265, 42.014461999061709],
              [-71.353265, 41.989539999061805],
              [-71.381431346893095, 41.985084118191317],
              [-71.381401, 42.018797999061704],
              [-71.458080994556099, 42.0168788241904],
              [-71.498224013124698, 42.015874110080205],
              [-71.498287, 42.064877999061558],
              [-71.499689, 42.103489999061424]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "151",
        COUNTYNS: "00516919",
        AFFGEOID: "0500000US21151",
        GEOID: "21151",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 1132603918,
        AWATER: 15425953
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.462031, 37.803430999085982],
            [-84.476283, 37.853130999085586],
            [-84.43572, 37.847201999085627],
            [-84.386165, 37.845489999085643],
            [-84.337393, 37.892006999085268],
            [-84.272406, 37.917545999085071],
            [-84.261183, 37.875985999085394],
            [-84.080337, 37.85306599908558],
            [-84.120226, 37.817380999085877],
            [-84.073908, 37.801116999086013],
            [-84.089212, 37.668148999087094],
            [-84.126948, 37.642420999087307],
            [-84.090364, 37.566226999087952],
            [-84.171836, 37.557548999088027],
            [-84.199489, 37.522857999088323],
            [-84.268112, 37.51530999908838],
            [-84.347805, 37.539063999088178],
            [-84.450201, 37.697897999086855],
            [-84.525643, 37.769496999086272],
            [-84.462031, 37.803430999085982]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "081",
        COUNTYNS: "01213677",
        AFFGEOID: "0500000US42081",
        GEOID: "42081",
        NAME: "Lycoming",
        LSAD: "06",
        ALAND: 3182098644,
        AWATER: 39419361
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.599278, 41.542270999063433],
            [-76.960481, 41.551516999063395],
            [-76.874714, 41.596918999063227],
            [-76.813731, 41.590033999063259],
            [-76.749497, 41.40557199906398],
            [-76.592532, 41.304331999064395],
            [-76.447597, 41.275628999064516],
            [-76.592607, 41.15776499906503],
            [-76.640767, 41.15571799906504],
            [-76.678776, 41.154171999065035],
            [-76.732672, 41.172039999064957],
            [-76.883833, 41.157413999065021],
            [-76.896114, 41.1390699990651],
            [-76.960229, 41.14880099906506],
            [-76.977939, 41.087882999065336],
            [-77.144111, 41.068839999065418],
            [-77.349765, 41.219917999064748],
            [-77.526537, 41.358527999064179],
            [-77.597282, 41.441055999063842],
            [-77.598129, 41.478575999063686],
            [-77.599278, 41.542270999063433]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "127",
        COUNTYNS: "00465252",
        AFFGEOID: "0500000US19127",
        GEOID: "19127",
        NAME: "Marshall",
        LSAD: "06",
        ALAND: 1482776070,
        AWATER: 1803080
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.231722, 42.208885999061074],
            [-93.001674, 42.209266999061072],
            [-92.767463, 42.210139999061077],
            [-92.765999, 41.862372999062245],
            [-93.231858, 41.862710999062237],
            [-93.231722, 42.208885999061074]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "131",
        COUNTYNS: "01101853",
        AFFGEOID: "0500000US40131",
        GEOID: "40131",
        NAME: "Rogers",
        LSAD: "06",
        ALAND: 1749945924,
        AWATER: 92823523
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.812064, 36.24954399909997],
            [-95.81221, 36.351009999098977],
            [-95.79439, 36.417369999098327],
            [-95.81268, 36.423559999098273],
            [-95.812512, 36.580507999096774],
            [-95.809617, 36.597475999096616],
            [-95.43157, 36.597539999096597],
            [-95.32837, 36.597629999096604],
            [-95.32817, 36.510239999097436],
            [-95.43561, 36.510159999097432],
            [-95.43997, 36.075199999101713],
            [-95.571525, 36.075281999101712],
            [-95.61852, 36.162511999100829],
            [-95.726408, 36.162495999100834],
            [-95.76165, 36.162749999100825],
            [-95.8154, 36.16262999910083],
            [-95.812064, 36.24954399909997]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "079",
        COUNTYNS: "00342918",
        AFFGEOID: "0500000US13079",
        GEOID: "13079",
        NAME: "Crawford",
        LSAD: "06",
        ALAND: 841521994,
        AWATER: 3988980
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.124332, 32.800934999140402],
            [-84.124275, 32.849561999139745],
            [-83.9807, 32.848956999139752],
            [-83.957579, 32.848756999139752],
            [-83.891922, 32.848350999139754],
            [-83.810141, 32.729941999141374],
            [-83.701152, 32.692168999141892],
            [-83.70109, 32.691597999141891],
            [-83.766787, 32.692621999141878],
            [-84.003363, 32.529926999144124],
            [-84.062735, 32.578431999143447],
            [-84.105924, 32.685048999141969],
            [-84.202628, 32.690017999141922],
            [-84.124332, 32.800934999140402]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "111",
        COUNTYNS: "01622998",
        AFFGEOID: "0500000US26111",
        GEOID: "26111",
        NAME: "Midland",
        LSAD: "06",
        ALAND: 1337100078,
        AWATER: 30591264
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.606035, 43.815217999057516],
            [-84.366676, 43.813559999057496],
            [-84.167318, 43.825901999057493],
            [-84.168127, 43.568898999057858],
            [-84.170576, 43.481968999058004],
            [-84.369876, 43.466043999058023],
            [-84.60754, 43.466005999058027],
            [-84.606035, 43.815217999057516]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "097",
        COUNTYNS: "01804529",
        AFFGEOID: "0500000US72097",
        GEOID: "72097",
        NAME: "Mayagüez",
        LSAD: "13",
        ALAND: 201174437,
        AWATER: 508705758
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-67.191221277720501, 18.266748883449548],
              [-67.135819, 18.245927999438294],
              [-67.082002, 18.25494599943805],
              [-67.055921, 18.245356999438307],
              [-67.019836, 18.195470999439628],
              [-67.051461, 18.1740529994402],
              [-67.086359, 18.149828999440842],
              [-67.098382, 18.158549999440609],
              [-67.159733, 18.153226999440754],
              [-67.180754718857003, 18.168198471222556],
              [-67.158001, 18.21671899943907],
              [-67.191221277720501, 18.266748883449548]
            ]
          ],
          [
            [
              [-67.941222, 18.126926999441451],
              [-67.896293, 18.13680199944119],
              [-67.846219, 18.127575999441433],
              [-67.820915, 18.084710999442571],
              [-67.850633, 18.04626799944359],
              [-67.885547, 18.036471999443862],
              [-67.955811, 18.074231999442855],
              [-67.941222, 18.126926999441451]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "017",
        COUNTYNS: "01581068",
        AFFGEOID: "0500000US55017",
        GEOID: "55017",
        NAME: "Chippewa",
        LSAD: "06",
        ALAND: 2611629940,
        AWATER: 85231859
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.665647, 45.207990999056804],
            [-91.542234, 45.206537999056799],
            [-91.541322, 45.292219999056833],
            [-90.925222, 45.292060999056829],
            [-90.923362, 45.031142999056762],
            [-90.922669, 44.944427999056771],
            [-90.922247, 44.857308999056769],
            [-91.650455, 44.855950999056773],
            [-91.651396, 45.030164999056765],
            [-91.666402, 45.120822999056777],
            [-91.665647, 45.207990999056804]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "34",
        COUNTYFP: "007",
        COUNTYNS: "00882273",
        AFFGEOID: "0500000US34007",
        GEOID: "34007",
        NAME: "Camden",
        LSAD: "06",
        ALAND: 573281541,
        AWATER: 15731653
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.13342, 39.896212999071416],
            [-75.13572, 39.947111999071126],
            [-75.11922, 39.965411999071016],
            [-75.060129970722599, 39.99201100233347],
            [-75.019253, 39.967433999071005],
            [-75.0176, 39.959374999071059],
            [-75.013092, 39.956627999071067],
            [-75.015081, 39.946812999071128],
            [-75.004149, 39.941464999071158],
            [-74.975051, 39.938510999071177],
            [-74.98558, 39.930429999071222],
            [-74.984045, 39.927353999071251],
            [-74.931071, 39.885974999071472],
            [-74.902368, 39.791040999072031],
            [-74.819419, 39.788000999072054],
            [-74.736216, 39.72977499907239],
            [-74.800087, 39.674863999072727],
            [-74.87726, 39.608351999073129],
            [-74.943677, 39.698360999072584],
            [-75.08707, 39.779575999072101],
            [-75.141435521747297, 39.893918371552743],
            [-75.13342, 39.896212999071416]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "115",
        COUNTYNS: "00353665",
        AFFGEOID: "0500000US13115",
        GEOID: "13115",
        NAME: "Floyd",
        LSAD: "06",
        ALAND: 1320436485,
        AWATER: 22382107
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.463140824775778, 34.286190987626924],
            [-85.387351, 34.286096999121426],
            [-85.334881, 34.36974799912042],
            [-85.180548, 34.43599599911964],
            [-85.107748, 34.58748299911786],
            [-85.069159, 34.587183999117869],
            [-85.104811, 34.40460799912001],
            [-85.005775, 34.392445999120156],
            [-85.023102, 34.347111999120692],
            [-85.046871, 34.096411999123724],
            [-85.257624, 34.100667999123658],
            [-85.25807, 34.079132999123935],
            [-85.421073175228798, 34.080812651731115],
            [-85.429499, 34.12509499912337],
            [-85.463140824775778, 34.286190987626924]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "093",
        COUNTYNS: "00198162",
        AFFGEOID: "0500000US08093",
        GEOID: "08093",
        NAME: "Park",
        LSAD: "06",
        ALAND: 5682181421,
        AWATER: 43520913
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.210173, 39.103917999076351],
            [-106.173847, 39.137075999076124],
            [-106.183972, 39.309353999074993],
            [-106.135529, 39.379545999074558],
            [-106.021966, 39.361712999074676],
            [-105.966786, 39.438034999074176],
            [-105.817631, 39.538309999073562],
            [-105.829662, 39.564864999073393],
            [-105.776642, 39.564919999073403],
            [-105.587986, 39.568196999073379],
            [-105.398949, 39.566055999073392],
            [-105.398154, 39.401269999074415],
            [-105.397875, 39.129563999076183],
            [-105.330444, 39.129684999076176],
            [-105.329134, 38.697204999079162],
            [-105.96975, 38.693550999079186],
            [-105.906587, 38.806961999078396],
            [-105.925123, 38.872177999077927],
            [-106.028138, 38.942848999077441],
            [-106.110995, 38.940098999077463],
            [-106.191006, 39.056072999076683],
            [-106.210173, 39.103917999076351]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "123",
        COUNTYNS: "00559578",
        AFFGEOID: "0500000US22123",
        GEOID: "22123",
        NAME: "West Carroll",
        LSAD: "15",
        ALAND: 931478638,
        AWATER: 2386193
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.637587, 32.667340999142219],
            [-91.566363, 32.855560999139676],
            [-91.437551, 32.980931999137972],
            [-91.435931080331798, 33.005839890109748],
            [-91.2645640502205, 33.004738823408061],
            [-91.30938, 32.935236999138596],
            [-91.344845, 32.75665899914101],
            [-91.398255, 32.692979999141876],
            [-91.396964, 32.609467999143021],
            [-91.445299, 32.581955999143396],
            [-91.596027, 32.582191999143397],
            [-91.637587, 32.667340999142219]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "109",
        COUNTYNS: "00277319",
        AFFGEOID: "0500000US06109",
        GEOID: "06109",
        NAME: "Tuolumne",
        LSAD: "06",
        ALAND: 5752079368,
        AWATER: 138722711
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.514952, 37.953389999084777],
            [-120.534276, 37.989442999084496],
            [-120.462798, 38.011926999084316],
            [-120.442529, 38.058740999083945],
            [-120.176177, 38.374013999081519],
            [-120.019951, 38.433520999081082],
            [-119.884749, 38.356184999081648],
            [-119.705385, 38.416101999081214],
            [-119.639205, 38.326879999081875],
            [-119.651509, 38.286461999082192],
            [-119.604182, 38.234960999082581],
            [-119.632671, 38.198857999082861],
            [-119.576496, 38.157660999083177],
            [-119.469456, 38.128282999083403],
            [-119.345922, 38.08311299908376],
            [-119.30459, 38.023890999084209],
            [-119.308389, 37.946823999084835],
            [-119.200283, 37.885826999085317],
            [-119.20128, 37.804328999085975],
            [-119.268979, 37.739229999086518],
            [-119.308995, 37.777985999086198],
            [-119.426792, 37.86672399908548],
            [-119.474295, 37.855622999085561],
            [-119.535699, 37.90412199908517],
            [-119.646203, 37.846822999085624],
            [-119.667203, 37.801223999086005],
            [-119.809409, 37.75502499908638],
            [-119.907013, 37.757925999086353],
            [-120.079508, 37.828807999085775],
            [-120.127226, 37.781565999086169],
            [-120.345437, 37.724786999086632],
            [-120.306312, 37.665418999087123],
            [-120.391931, 37.683558999086969],
            [-120.387613, 37.633703999087388],
            [-120.653274, 37.831857999085756],
            [-120.514952, 37.953389999084777]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "33",
        COUNTYFP: "003",
        COUNTYNS: "00873175",
        AFFGEOID: "0500000US33003",
        GEOID: "33003",
        NAME: "Carroll",
        LSAD: "06",
        ALAND: 2413888299,
        AWATER: 159247403
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.564603, 43.893171999057415],
            [-71.361965, 43.91593799905737],
            [-71.38163, 44.097873999057178],
            [-71.415898, 44.212168999057077],
            [-71.356482, 44.165835999057123],
            [-71.335882, 44.085751999057202],
            [-71.251957, 44.244265999057049],
            [-71.04228, 44.237513999057057],
            [-71.010271167868297, 44.28488832062903],
            [-71.008736, 44.258824999057033],
            [-71.001367, 44.092930999057188],
            [-70.989929, 43.839238999057478],
            [-70.987258046746007, 43.792973591941731],
            [-70.981946, 43.700959999057652],
            [-70.972716, 43.570254999057845],
            [-70.963792679806403, 43.540220954424413],
            [-71.024244, 43.482912999057994],
            [-71.037931, 43.529825999057927],
            [-71.162675, 43.538958999057911],
            [-71.208941, 43.550516999057891],
            [-71.34362, 43.628793999057777],
            [-71.344245, 43.629219999057774],
            [-71.43989, 43.695557999057669],
            [-71.531658, 43.760978999057592],
            [-71.543805, 43.796042999057526],
            [-71.560621, 43.875044999057437],
            [-71.564603, 43.893171999057415]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "045",
        COUNTYNS: "00277287",
        AFFGEOID: "0500000US06045",
        GEOID: "06045",
        NAME: "Mendocino",
        LSAD: "06",
        ALAND: 9081426673,
        AWATER: 962946204
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.025205174530029, 40.001299340739827],
            [-123.544458, 40.001922999070821],
            [-123.544563, 39.977014999070953],
            [-122.934013, 39.978130999070949],
            [-122.909787, 39.936384999071187],
            [-122.950077, 39.907717999071352],
            [-122.937654, 39.798155999071994],
            [-122.892676, 39.708897999072519],
            [-122.885352, 39.580107999073306],
            [-122.89031, 39.529013999073612],
            [-123.063181, 39.503538999073768],
            [-123.075114, 39.407682999074382],
            [-122.994839, 39.235923999075482],
            [-123.077812, 39.173790999075898],
            [-123.056115, 39.048814999076733],
            [-123.056212, 39.02109599907692],
            [-122.98664, 38.997282999077065],
            [-122.948865, 38.900219999077741],
            [-122.821592, 38.850139999078088],
            [-123.080997, 38.85243799907807],
            [-123.136253, 38.809135999078372],
            [-123.368236, 38.777065999078602],
            [-123.540922011634009, 38.76765621511796],
            [-123.571987, 38.798188999078448],
            [-123.638637, 38.843864999078136],
            [-123.659846, 38.872528999077936],
            [-123.71054, 38.91322999907765],
            [-123.732892, 38.954993999077359],
            [-123.69074, 39.021292999076913],
            [-123.721505, 39.125326999076208],
            [-123.765891, 39.19365699907577],
            [-123.798991, 39.27135499907525],
            [-123.825331, 39.360813999074672],
            [-123.81469, 39.446537999074131],
            [-123.766475, 39.552802999073464],
            [-123.782322, 39.621485999073052],
            [-123.792659, 39.684121999072666],
            [-123.829545, 39.723070999072419],
            [-123.851714, 39.832040999071793],
            [-123.907664, 39.863027999071605],
            [-123.954952, 39.922372999071264],
            [-124.025205174530029, 40.001299340739827]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "025",
        COUNTYNS: "00558495",
        AFFGEOID: "0500000US22025",
        GEOID: "22025",
        NAME: "Catahoula",
        LSAD: "15",
        ALAND: 1833782911,
        AWATER: 81308667
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.005434, 31.927295999152655],
            [-91.904381, 31.927560999152654],
            [-91.889687, 31.97147399915201],
            [-91.804869, 31.89319299915315],
            [-91.729158, 31.882509999153307],
            [-91.6475, 31.970427999152026],
            [-91.596922, 31.969757999152044],
            [-91.575721, 31.882559999153301],
            [-91.577653, 31.795856999154562],
            [-91.544791, 31.751730999155203],
            [-91.601622, 31.761863999155061],
            [-91.635822, 31.729118999155542],
            [-91.694469, 31.735285999155455],
            [-91.705948, 31.642862999156815],
            [-91.737189, 31.614087999157242],
            [-91.775836, 31.648625999156735],
            [-91.826926, 31.591670999157571],
            [-91.779725, 31.530972999158465],
            [-91.798754, 31.473353999159329],
            [-91.738032, 31.467409999159418],
            [-91.738331, 31.431571999159949],
            [-91.807098, 31.438793999159856],
            [-91.857083, 31.494526999159014],
            [-91.855889, 31.423830999160071],
            [-91.776779, 31.385214999160649],
            [-91.845019, 31.375915999160789],
            [-91.862168, 31.302313999161907],
            [-91.83428, 31.266343999162441],
            [-91.926854, 31.294829999162008],
            [-91.990596, 31.225209999163063],
            [-92.008121, 31.325796999161547],
            [-92.005434, 31.927295999152655]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "159",
        COUNTYNS: "00450394",
        AFFGEOID: "0500000US18159",
        GEOID: "18159",
        NAME: "Tipton",
        LSAD: "06",
        ALAND: 674797600,
        AWATER: 68028
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.242812, 40.40291399906863],
            [-86.060645, 40.405197999068612],
            [-85.862117, 40.406888999068613],
            [-85.862296, 40.378366999068753],
            [-85.861903, 40.218935999069608],
            [-86.242743, 40.215833999069631],
            [-86.242992, 40.373660999068775],
            [-86.242812, 40.40291399906863]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "119",
        COUNTYNS: "01804540",
        AFFGEOID: "0500000US72119",
        GEOID: "72119",
        NAME: "Río Grande",
        LSAD: "13",
        ALAND: 157008580,
        AWATER: 74876344
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-65.867447, 18.378197999434796],
            [-65.827737477559012, 18.425562488942646],
            [-65.771695, 18.406276999434052],
            [-65.741796465289397, 18.398181744571964],
            [-65.770431, 18.366142999435112],
            [-65.758861, 18.290603999437113],
            [-65.766831, 18.28000399943739],
            [-65.82422, 18.273053999437572],
            [-65.836387, 18.275245999437519],
            [-65.867875, 18.293813999437024],
            [-65.867447, 18.378197999434796]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "025",
        COUNTYNS: "01266985",
        AFFGEOID: "0500000US46025",
        GEOID: "46025",
        NAME: "Clark",
        LSAD: "06",
        ALAND: 2480181919,
        AWATER: 25627353
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.981792, 45.153294999056783],
            [-97.494254, 45.151630999056778],
            [-97.491346, 44.804034999056775],
            [-97.492266, 44.54388399905686],
            [-97.853028, 44.544397999056862],
            [-97.856226, 44.631177999056824],
            [-97.977791, 44.631601999056826],
            [-97.981792, 45.153294999056783]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "015",
        COUNTYNS: "00835829",
        AFFGEOID: "0500000US31015",
        GEOID: "31015",
        NAME: "Boyd",
        LSAD: "06",
        ALAND: 1398425584,
        AWATER: 11890320
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.254455012316583, 42.998220925845651],
            [-98.847992, 42.998254999058972],
            [-98.49855, 42.998559999058955],
            [-98.478919, 42.96353899905904],
            [-98.434503, 42.929226999059111],
            [-98.386445, 42.91840699905913],
            [-98.308186816451297, 42.886489206484804],
            [-98.310239, 42.784617999059456],
            [-98.305149, 42.761206999059517],
            [-98.58376, 42.779735999059476],
            [-98.656835, 42.809020999059406],
            [-98.892846, 42.845791999059308],
            [-98.982527, 42.8964359990592],
            [-99.257039, 42.804295999059413],
            [-99.254455012316583, 42.998220925845651]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "029",
        COUNTYNS: "00516861",
        AFFGEOID: "0500000US21029",
        GEOID: "21029",
        NAME: "Bullitt",
        LSAD: "06",
        ALAND: 769200000,
        AWATER: 8481647
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.882805, 38.040544999084091],
            [-85.712578, 38.087310999083719],
            [-85.529477, 38.083863999083754],
            [-85.42883, 38.118382999083487],
            [-85.436129, 38.104760999083581],
            [-85.516882, 38.011266999084313],
            [-85.489202, 37.990653999084479],
            [-85.576259, 37.885128999085325],
            [-85.680471, 37.815119999085901],
            [-85.737777, 37.811789999085924],
            [-85.838198, 37.879283999085381],
            [-85.854871, 37.945384999084851],
            [-85.938321, 37.998755999084423],
            [-85.882805, 38.040544999084091]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "790",
        COUNTYNS: "01789075",
        AFFGEOID: "0500000US51790",
        GEOID: "51790",
        NAME: "Staunton",
        LSAD: "25",
        ALAND: 51593824,
        AWATER: 147694
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.095898, 38.185076999082966],
            [-79.022841, 38.195644999082887],
            [-79.022162, 38.190704999082925],
            [-79.020308, 38.14372499908329],
            [-79.049819, 38.121175999083455],
            [-79.105671, 38.13959099908331],
            [-79.095898, 38.185076999082966]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "049",
        COUNTYNS: "00835846",
        AFFGEOID: "0500000US31049",
        GEOID: "31049",
        NAME: "Deuel",
        LSAD: "06",
        ALAND: 1139208969,
        AWATER: 2230956
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.610213, 41.048206999065513],
            [-102.610413, 41.221613999064743],
            [-102.055535, 41.221546999064756],
            [-102.055528, 41.047456999065517],
            [-102.051761, 41.003889999065706],
            [-102.051717516377991, 41.00237696668961],
            [-102.556789, 41.002218999065711],
            [-102.621033024452004, 41.002223201681325],
            [-102.610213, 41.048206999065513]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "015",
        COUNTYNS: "00974106",
        AFFGEOID: "0500000US36015",
        GEOID: "36015",
        NAME: "Chemung",
        LSAD: "06",
        ALAND: 1055038643,
        AWATER: 8874334
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.965028, 42.278494999060868],
            [-76.733912, 42.293719999060819],
            [-76.732677, 42.248051999060969],
            [-76.642256, 42.233720999061006],
            [-76.619303, 42.28285299906085],
            [-76.538349, 42.281754999060851],
            [-76.563589, 42.152462999061264],
            [-76.557624143569385, 42.000148715403476],
            [-76.558118, 42.00015499906177],
            [-76.926846362954294, 42.000723450879079],
            [-76.965728759426696, 42.000783394108652],
            [-76.965028, 42.278494999060868]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "109",
        COUNTYNS: "00835876",
        AFFGEOID: "0500000US31109",
        GEOID: "31109",
        NAME: "Lancaster",
        LSAD: "06",
        ALAND: 2169252486,
        AWATER: 22867561
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.913493, 40.697947999067139],
            [-96.911049, 40.714101999067069],
            [-96.91094, 41.04611599906552],
            [-96.908507, 41.04609099906552],
            [-96.463869, 41.045082999065535],
            [-96.463861, 41.016068999065652],
            [-96.463764, 40.783959999066738],
            [-96.463632, 40.523012999068023],
            [-96.912637, 40.523624999068012],
            [-96.913493, 40.697947999067139]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "123",
        COUNTYNS: "00424260",
        AFFGEOID: "0500000US17123",
        GEOID: "17123",
        NAME: "Marshall",
        LSAD: "06",
        ALAND: 1001787870,
        AWATER: 30368315
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.638528, 41.141320999065094],
            [-89.638429, 41.148590999065064],
            [-89.466421, 41.148557999065062],
            [-89.32927, 41.147930999065068],
            [-89.358597, 41.103664999065266],
            [-89.162238, 41.104079999065256],
            [-89.047856, 41.104780999065255],
            [-89.047718, 40.925748999066059],
            [-89.160278, 40.926798999066058],
            [-89.47233, 40.921205999066096],
            [-89.447693, 40.973245999065846],
            [-89.638727, 40.973706999065833],
            [-89.638528, 41.141320999065094]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "299",
        COUNTYNS: "00357722",
        AFFGEOID: "0500000US13299",
        GEOID: "13299",
        NAME: "Ware",
        LSAD: "06",
        ALAND: 2312599086,
        AWATER: 39076860
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.699206, 31.278103999162269],
            [-82.629622, 31.274725999162321],
            [-82.628975, 31.363929999160984],
            [-82.628181, 31.469350999159388],
            [-82.597509, 31.469292999159389],
            [-82.494718, 31.468689999159402],
            [-82.48146, 31.417533999160167],
            [-82.417246, 31.417118999160174],
            [-82.383705, 31.291142999162059],
            [-82.31504, 31.225957999163057],
            [-82.284561, 31.224448999163087],
            [-82.208732, 31.170937999163897],
            [-82.208262, 31.084767999165205],
            [-82.131698, 31.010713999166349],
            [-82.415603, 31.013589999166307],
            [-82.420442, 30.795228999169698],
            [-82.149872, 30.784335999169869],
            [-82.218607, 30.564394999173334],
            [-82.418984112178492, 30.580916668904568],
            [-82.435852, 30.820067999169307],
            [-82.495476, 30.819552999169314],
            [-82.490558, 30.963165999167082],
            [-82.592071, 31.018486999166232],
            [-82.671669, 31.183738999163698],
            [-82.699206, 31.278103999162269]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "139",
        COUNTYNS: "01074082",
        AFFGEOID: "0500000US39139",
        GEOID: "39139",
        NAME: "Richland",
        LSAD: "06",
        ALAND: 1282720846,
        AWATER: 12470881
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.724792, 40.995637999065742],
            [-82.432852, 40.992943999065758],
            [-82.420752, 40.823329999066544],
            [-82.339211, 40.726682999067009],
            [-82.338483, 40.638501999067437],
            [-82.336962, 40.555000999067843],
            [-82.374898, 40.550870999067882],
            [-82.623609, 40.549878999067879],
            [-82.644977, 40.666424999067303],
            [-82.627191, 40.70941599906709],
            [-82.689113, 40.711032999067079],
            [-82.727164, 40.711202999067083],
            [-82.724792, 40.995637999065742]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "081",
        COUNTYNS: "00485328",
        AFFGEOID: "0500000US20081",
        GEOID: "20081",
        NAME: "Haskell",
        LSAD: "06",
        ALAND: 1495773052,
        AWATER: 910409
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.089667, 37.736336999086532],
            [-100.652251, 37.73627499908654],
            [-100.652642, 37.47484799908873],
            [-100.652728, 37.387626999089456],
            [-101.067904, 37.387778999089463],
            [-101.089653, 37.387720999089467],
            [-101.089667, 37.736336999086532]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "319",
        COUNTYNS: "00346824",
        AFFGEOID: "0500000US13319",
        GEOID: "13319",
        NAME: "Wilkinson",
        LSAD: "06",
        ALAND: 1158541310,
        AWATER: 12003359
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.357685, 32.926140999138703],
            [-83.133847, 33.007247999137626],
            [-83.073648, 32.946561999138439],
            [-83.027036, 32.85613299913966],
            [-82.974265, 32.839235999139881],
            [-82.955664, 32.756960999141],
            [-82.946966, 32.75935799914096],
            [-82.957066, 32.708317999141663],
            [-83.226536, 32.584200999143363],
            [-83.40699, 32.898219999139094],
            [-83.357685, 32.926140999138703]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "199",
        COUNTYNS: "01785215",
        AFFGEOID: "0500000US17199",
        GEOID: "17199",
        NAME: "Williamson",
        LSAD: "06",
        ALAND: 1088248004,
        AWATER: 62617689
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.151176, 37.861998999085515],
            [-88.70676, 37.863337999085502],
            [-88.708546, 37.599276999087671],
            [-89.041401, 37.596575999087698],
            [-89.153668, 37.600468999087667],
            [-89.151176, 37.861998999085515]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "155",
        COUNTYNS: "01383863",
        AFFGEOID: "0500000US48155",
        GEOID: "48155",
        NAME: "Foard",
        LSAD: "06",
        ALAND: 1824378633,
        AWATER: 8514740
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.047028, 34.229779999122101],
            [-100.037319, 34.238777999121986],
            [-99.997621, 34.224354999122163],
            [-99.772043, 34.093285999123751],
            [-99.65301, 34.098970999123686],
            [-99.5485, 34.05577399912422],
            [-99.475294, 34.085617999123848],
            [-99.47565, 33.834004999126954],
            [-99.474498, 33.733848999128199],
            [-99.554906, 33.798555999127395],
            [-99.700705, 33.799962999127374],
            [-99.835143, 33.835799999126941],
            [-99.996434, 33.835966999126917],
            [-100.04848, 33.835973999126935],
            [-100.047028, 34.229779999122101]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "275",
        COUNTYNS: "02516402",
        AFFGEOID: "0500000US02275",
        GEOID: "02275",
        NAME: "Wrangell",
        LSAD: "03",
        ALAND: 6619614514,
        AWATER: 2384746838
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-133.039877412399022, 56.239040379224683],
              [-132.966922, 56.224275999128785],
              [-132.887585, 56.172937999128145],
              [-132.833864, 56.10390399912729],
              [-132.896433, 56.099743999127234],
              [-132.85978295116999, 56.052769326150049],
              [-132.893368, 56.067629999126829],
              [-133.007727, 56.134872999127673],
              [-133.016516, 56.150203999127861],
              [-133.039877412399022, 56.239040379224683]
            ]
          ],
          [
            [
              [-133.0696, 56.346322999130336],
              [-133.060361, 56.358377999130482],
              [-132.977163, 56.439672999131538],
              [-132.896342, 56.457977999131764],
              [-132.791872, 56.449168999131658],
              [-132.627544, 56.462869999131833],
              [-132.528637, 56.52926899913269],
              [-132.45079, 56.564097999133139],
              [-132.529037, 56.637969999134107],
              [-132.542885, 56.701904999134946],
              [-132.545822263320019, 56.713621235014692],
              [-132.439599, 56.770408999135839],
              [-132.348468, 56.771286999135853],
              [-132.215746, 56.89160599913744],
              [-132.160186, 56.903304999137589],
              [-132.118917923363, 56.891229389646341],
              [-132.125934, 56.874697999137226],
              [-131.871725, 56.804964999136295],
              [-131.90176, 56.753157999135617],
              [-131.835133, 56.601848999133622],
              [-131.581221, 56.613274999133779],
              [-131.461806, 56.547903999132934],
              [-131.167925, 56.448360999131644],
              [-131.086794696281999, 56.407094772354725],
              [-131.09623, 56.335165999130211],
              [-131.013177, 56.314210999129948],
              [-130.99843, 56.274564999129431],
              [-131.110314, 56.194068999128426],
              [-131.240485, 56.172613999128146],
              [-131.253358, 56.206849999128579],
              [-131.439957, 56.131082999127621],
              [-131.369226, 56.069909999126871],
              [-131.364317, 56.01794299912622],
              [-131.498849, 56.019096999126226],
              [-131.527013, 56.053160999126639],
              [-131.653403, 56.103850999127289],
              [-131.664222, 56.067501999126833],
              [-131.829365, 56.056464999126689],
              [-131.905601, 55.979060999125736],
              [-131.871943, 55.948877999125365],
              [-132.011037, 55.854787999124206],
              [-131.935635, 55.798430999123518],
              [-131.978551, 55.753365999122977],
              [-131.962744, 55.700756999122341],
              [-132.058825, 55.710929999122463],
              [-132.091178, 55.66070299912186],
              [-132.199681399056999, 55.633833999121542],
              [-132.224167, 55.701765999122358],
              [-132.265071, 55.762173999123085],
              [-132.130413, 55.811418999123681],
              [-132.067412, 55.87507799912445],
              [-132.170198, 55.919230999125006],
              [-132.279962, 55.924838999125072],
              [-132.323242, 55.851877999124177],
              [-132.397304, 55.878866999124511],
              [-132.449834, 55.956185999125445],
              [-132.492795, 56.06643599912681],
              [-132.594235, 56.021857999126254],
              [-132.708697, 56.112123999127384],
              [-132.718342, 56.217703999128709],
              [-132.843716, 56.238932999128977],
              [-132.877582, 56.240321999129002],
              [-132.926759, 56.266187999129329],
              [-132.961082, 56.29616699912971],
              [-133.029712, 56.315699999129947],
              [-133.070056, 56.330950999130152],
              [-133.0696, 56.346322999130336]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "109",
        COUNTYNS: "00161581",
        AFFGEOID: "0500000US01109",
        GEOID: "01109",
        NAME: "Pike",
        LSAD: "06",
        ALAND: 1740647521,
        AWATER: 2336975
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.191379, 31.966452999152086],
            [-85.995563, 31.96755399915207],
            [-85.996853, 32.051048999150879],
            [-85.946298, 32.061889999150715],
            [-85.893652, 32.047350999150922],
            [-85.88435, 31.96725299915207],
            [-85.790048, 31.967253999152067],
            [-85.791047, 31.880356999153342],
            [-85.657668, 31.880274999153329],
            [-85.66623, 31.772876999154896],
            [-85.735732, 31.624492999157081],
            [-85.748251, 31.618047999157174],
            [-85.789142, 31.61796399915718],
            [-86.145895, 31.617740999157174],
            [-86.179672, 31.616413999157199],
            [-86.147147, 31.663149999156513],
            [-86.148339, 31.790950999154646],
            [-86.199378, 31.790449999154639],
            [-86.191379, 31.966452999152086]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "111",
        COUNTYNS: "01074068",
        AFFGEOID: "0500000US39111",
        GEOID: "39111",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 1180317770,
        AWATER: 4495382
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.313591, 39.869470999071588],
            [-81.237316, 39.867827999071579],
            [-80.823438104785893, 39.85003208646809],
            [-80.824276, 39.847158999071709],
            [-80.824969, 39.801091999071971],
            [-80.869933, 39.763554999072191],
            [-80.835521693007493, 39.719251801272549],
            [-80.829764, 39.711838999072498],
            [-80.865575, 39.662750999072799],
            [-80.943782, 39.606925999073141],
            [-81.037365041987087, 39.538055711546164],
            [-81.036791, 39.572117999073349],
            [-81.283075, 39.605795999073145],
            [-81.319125, 39.707359999072523],
            [-81.313591, 39.869470999071588]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "055",
        COUNTYNS: "00835849",
        AFFGEOID: "0500000US31055",
        GEOID: "31055",
        NAME: "Douglas",
        LSAD: "06",
        ALAND: 850764861,
        AWATER: 28139501
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.47072, 41.393238999064039],
            [-96.329012, 41.393135999064036],
            [-95.945045, 41.393094999064033],
            [-95.927336242320095, 41.389987936523646],
            [-95.92879, 41.370095999064134],
            [-95.92569, 41.322196999064325],
            [-95.890152, 41.278307999064509],
            [-95.911391, 41.237997999064675],
            [-95.909908491254797, 41.191282849892779],
            [-96.009134, 41.190699999064876],
            [-96.327399, 41.189998999064876],
            [-96.421439, 41.364164999064151],
            [-96.47072, 41.393238999064039]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "087",
        COUNTYNS: "01716795",
        AFFGEOID: "0500000US30087",
        GEOID: "30087",
        NAME: "Rosebud",
        LSAD: "06",
        ALAND: 12971010170,
        AWATER: 44234236
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.892233, 46.85121499905884],
            [-107.46777, 46.851159999058837],
            [-106.720157, 46.859602999058858],
            [-106.086463, 46.845880999058835],
            [-106.120366, 46.483647999058107],
            [-106.155143, 46.4836339990581],
            [-106.153433, 46.137259999057569],
            [-106.191629, 46.137182999057579],
            [-106.191734, 45.788599999057169],
            [-106.234502, 45.788577999057168],
            [-106.279549, 45.351796999056859],
            [-106.279709, 45.179727999056787],
            [-106.768014, 45.179727999056787],
            [-106.768676, 45.352825999056847],
            [-106.727573, 45.676468999057057],
            [-106.912959, 45.683914999057066],
            [-106.9128, 45.788220999057152],
            [-106.877217, 45.831713999057214],
            [-106.939077, 45.831607999057191],
            [-106.939095, 45.870040999057245],
            [-106.939067, 46.136666999057574],
            [-107.007394, 46.134197999057569],
            [-107.007394, 46.305901999057816],
            [-107.028422, 46.395459999057962],
            [-107.153444, 46.39561299905796],
            [-107.174531, 46.482712999058108],
            [-107.738548, 46.481805999058096],
            [-107.781214, 46.496013999058135],
            [-107.795473, 46.596651999058317],
            [-107.837732, 46.596646999058308],
            [-107.792939, 46.679829999058477],
            [-107.827687, 46.755874999058626],
            [-107.861812, 46.835605999058807],
            [-107.892233, 46.85121499905884]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "017",
        COUNTYNS: "00581294",
        AFFGEOID: "0500000US23017",
        GEOID: "23017",
        NAME: "Oxford",
        LSAD: "06",
        ALAND: 5379066664,
        AWATER: 256039480
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.083924, 45.305450999056831],
            [-71.03821, 45.311921999056835],
            [-71.012757, 45.344759999056841],
            [-70.949365, 45.331535999056847],
            [-70.912111, 45.296196999056825],
            [-70.892822, 45.239171999056801],
            [-70.84443, 45.234512999056818],
            [-70.834019537440099, 45.271794401453626],
            [-70.779842, 45.018378999056772],
            [-70.823634, 45.013789999056762],
            [-70.773902, 44.83977699905676],
            [-70.769228, 44.732227999056789],
            [-70.61503, 44.795633999056768],
            [-70.510598, 44.627215999056823],
            [-70.549985, 44.610726999056816],
            [-70.280748, 44.56961899905685],
            [-70.307659, 44.537875999056858],
            [-70.250895, 44.503401999056891],
            [-70.238904, 44.458941999056904],
            [-70.274412, 44.440631999056919],
            [-70.265223, 44.36291299905696],
            [-70.325218, 44.208398999057088],
            [-70.391735, 44.153561999057125],
            [-70.485288, 44.060397999057216],
            [-70.480078, 44.03207799905725],
            [-70.544094, 44.005646999057277],
            [-70.595631, 44.032056999057254],
            [-70.587331, 44.122515999057157],
            [-70.624329, 44.171035999057111],
            [-70.821803, 44.077088999057203],
            [-70.715418, 43.961814999057324],
            [-70.801612, 43.856082999057456],
            [-70.782984, 43.814146999057506],
            [-70.852593, 43.804734999057516],
            [-70.970719, 43.784989999057544],
            [-70.987258046746007, 43.792973591941731],
            [-70.989929, 43.839238999057478],
            [-71.001367, 44.092930999057188],
            [-71.008736, 44.258824999057033],
            [-71.010271167868297, 44.28488832062903],
            [-71.01127, 44.301845999057008],
            [-71.013575903814598, 44.340837451616466],
            [-71.019461729485499, 44.440363238844412],
            [-71.022992, 44.500057999056871],
            [-71.036705, 44.736497999056787],
            [-71.057861, 45.000048999056759],
            [-71.083924, 45.305450999056831]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "061",
        COUNTYNS: "00659476",
        AFFGEOID: "0500000US27061",
        GEOID: "27061",
        NAME: "Itasca",
        LSAD: "06",
        ALAND: 6907775594,
        AWATER: 675388758
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.418543, 47.845815999061571],
            [-93.776173, 47.846403999061586],
            [-93.775772, 47.898949999061749],
            [-93.081158, 47.891869999061726],
            [-93.061253, 47.720186999061156],
            [-93.0695, 47.393914999060179],
            [-93.055943, 47.026414999059234],
            [-93.775257, 47.030417999059239],
            [-93.77393, 47.211334999059694],
            [-93.80882, 47.232621999059752],
            [-93.77401, 47.305059999059935],
            [-93.888213, 47.318855999059984],
            [-93.911505, 47.297867999059932],
            [-94.042287, 47.373111999060121],
            [-94.027858, 47.408632999060224],
            [-94.095115, 47.477971999060436],
            [-94.327531, 47.41584399906025],
            [-94.416279, 47.444827999060337],
            [-94.418543, 47.845815999061571]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "017",
        COUNTYNS: "00450339",
        AFFGEOID: "0500000US18017",
        GEOID: "18017",
        NAME: "Cass",
        LSAD: "06",
        ALAND: 1067475985,
        AWATER: 6974423
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.581532, 40.910924999066133],
            [-86.468509, 40.909888999066126],
            [-86.169012, 40.909835999066132],
            [-86.165024, 40.562648999067818],
            [-86.374417, 40.561360999067823],
            [-86.373521, 40.692459999067168],
            [-86.525164, 40.692418999067179],
            [-86.581739, 40.735764999066959],
            [-86.581532, 40.910924999066133]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "017",
        COUNTYNS: "01689476",
        AFFGEOID: "0500000US54017",
        GEOID: "54017",
        NAME: "Doddridge",
        LSAD: "06",
        ALAND: 828070519,
        AWATER: 1958441
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.888744, 39.294297999075098],
            [-80.713324, 39.430249999074242],
            [-80.618629, 39.450374999074114],
            [-80.583091, 39.451414999074096],
            [-80.544521, 39.429119999074238],
            [-80.531808, 39.360120999074688],
            [-80.603684, 39.291040999075122],
            [-80.528756, 39.195705999075749],
            [-80.596126, 39.167068999075937],
            [-80.700642, 39.09049699907645],
            [-80.728326, 39.095678999076405],
            [-80.81297, 39.109400999076314],
            [-80.91384, 39.272946999075238],
            [-80.888744, 39.294297999075098]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "083",
        COUNTYNS: "01265769",
        AFFGEOID: "0500000US46083",
        GEOID: "46083",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 1495182117,
        AWATER: 1179561
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.924841, 43.500006999057966],
            [-96.598928, 43.500456999057974],
            [-96.584603, 43.469609999058022],
            [-96.594254, 43.434152999058092],
            [-96.521572, 43.385639999058171],
            [-96.524289, 43.347213999058241],
            [-96.530392, 43.300033999058314],
            [-96.578823, 43.291094999058345],
            [-96.559027342709697, 43.257555674676418],
            [-96.552963, 43.247280999058432],
            [-96.522084, 43.220959999058479],
            [-96.475571, 43.221053999058469],
            [-96.458854, 43.143355999058642],
            [-96.439335, 43.113915999058705],
            [-96.452097516053684, 43.082552924253271],
            [-96.805682, 43.08366999905877],
            [-96.924142, 43.083732999058775],
            [-96.924841, 43.500006999057966]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "027",
        COUNTYNS: "00161539",
        AFFGEOID: "0500000US01027",
        GEOID: "01027",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 1564253513,
        AWATER: 5283523
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.17283, 33.195680999135128],
            [-86.120567, 33.194510999135147],
            [-86.118198, 33.296319999133807],
            [-85.980293, 33.294189999133842],
            [-85.976525, 33.381869999132704],
            [-85.923762, 33.396205999132512],
            [-85.904909, 33.498654999131205],
            [-85.85189, 33.498741999131191],
            [-85.887782, 33.469426999131571],
            [-85.782735, 33.469348999131576],
            [-85.765427, 33.498592999131198],
            [-85.643482, 33.495884999131242],
            [-85.641129, 33.320500999133507],
            [-85.653654, 33.106633999136314],
            [-85.974715, 33.105285999136335],
            [-86.00917, 33.090259999136528],
            [-86.010919, 33.104635999136335],
            [-86.17437, 33.104393999136335],
            [-86.17283, 33.195680999135128]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "077",
        COUNTYNS: "01074051",
        AFFGEOID: "0500000US39077",
        GEOID: "39077",
        NAME: "Huron",
        LSAD: "06",
        ALAND: 1272965337,
        AWATER: 8615925
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.841475, 41.290022999064469],
            [-82.342314, 41.283553999064488],
            [-82.344003, 41.211265999064793],
            [-82.344086, 41.202151999064839],
            [-82.336496, 41.065760999065425],
            [-82.436916, 41.065378999065437],
            [-82.432852, 40.992943999065758],
            [-82.724792, 40.995637999065742],
            [-82.829512, 40.99662399906574],
            [-82.838538, 41.216998999064771],
            [-82.840087, 41.255336999064596],
            [-82.841475, 41.290022999064469]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "105",
        COUNTYNS: "01496538",
        AFFGEOID: "0500000US51105",
        GEOID: "51105",
        NAME: "Lee",
        LSAD: "06",
        ALAND: 1127622408,
        AWATER: 4896872
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.614513, 36.633982999096268],
            [-83.527112, 36.665984999095954],
            [-83.460954647463808, 36.666131035369268],
            [-83.436508, 36.666184999095954],
            [-83.386099, 36.686588999095768],
            [-83.236399, 36.726886999095399],
            [-83.136395, 36.743087999095245],
            [-83.114693, 36.796087999094745],
            [-83.07559, 36.850588999094242],
            [-83.012587, 36.847288999094268],
            [-82.895445, 36.88214499909396],
            [-82.883754212976385, 36.897130135748021],
            [-82.847362, 36.847662999094275],
            [-82.771595, 36.800030999094709],
            [-82.823358, 36.733602999095332],
            [-82.947587, 36.675869999095859],
            [-82.984458495572383, 36.595289968146631],
            [-83.2763, 36.598186999096598],
            [-83.472093573809602, 36.599475731155884],
            [-83.675413, 36.600813999096573],
            [-83.614513, 36.633982999096268]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "011",
        COUNTYNS: "01675902",
        AFFGEOID: "0500000US06011",
        GEOID: "06011",
        NAME: "Colusa",
        LSAD: "06",
        ALAND: 2980332860,
        AWATER: 14608869
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.78509, 39.382974999074527],
            [-122.739062, 39.383265999074531],
            [-122.192334, 39.385256999074521],
            [-122.136161, 39.414498999074333],
            [-122.008857, 39.413566999074348],
            [-121.890013, 39.383863999074521],
            [-121.899109, 39.357285999074691],
            [-121.908269, 39.303877999075041],
            [-121.945514, 39.179842999075859],
            [-121.889101, 39.07237299907657],
            [-121.795366, 38.995685999077082],
            [-121.835488, 38.924480999077581],
            [-122.340172, 38.924245999077577],
            [-122.409118, 38.962792999077308],
            [-122.413416, 39.02021799907692],
            [-122.491278, 39.052990999076691],
            [-122.47729, 39.173949999075894],
            [-122.680927, 39.238725999075463],
            [-122.775043, 39.311777999074991],
            [-122.743482, 39.368129999074633],
            [-122.78509, 39.382974999074527]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "053",
        COUNTYNS: "00450355",
        AFFGEOID: "0500000US18053",
        GEOID: "18053",
        NAME: "Grant",
        LSAD: "06",
        ALAND: 1072452772,
        AWATER: 2133506
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.864791, 40.651692999067379],
            [-85.638587, 40.65312899906737],
            [-85.448825, 40.653606999067371],
            [-85.447014, 40.566928999067791],
            [-85.44433, 40.379139999068762],
            [-85.578589, 40.379523999068745],
            [-85.862296, 40.378366999068753],
            [-85.862117, 40.406888999068613],
            [-85.863641, 40.565524999067804],
            [-85.864791, 40.651692999067379]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "079",
        COUNTYNS: "00485005",
        AFFGEOID: "0500000US20079",
        GEOID: "20079",
        NAME: "Harvey",
        LSAD: "06",
        ALAND: 1397961182,
        AWATER: 2503283
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.701841, 38.173813999083059],
            [-97.37175, 38.173672999083053],
            [-97.153093, 38.174633999083049],
            [-97.152913, 38.087703999083722],
            [-97.152476, 37.912732999085108],
            [-97.701969, 37.911324999085124],
            [-97.701841, 38.173813999083059]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "001",
        COUNTYNS: "01008531",
        AFFGEOID: "0500000US37001",
        GEOID: "37001",
        NAME: "Alamance",
        LSAD: "06",
        ALAND: 1096654286,
        AWATER: 28025249
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.541728, 35.899850999103485],
            [-79.53241, 36.241462999100044],
            [-79.531865, 36.249672999099971],
            [-79.257952, 36.243444999100028],
            [-79.268243, 35.909181999103389],
            [-79.249538, 35.876809999103727],
            [-79.237295, 35.844246999104058],
            [-79.542428, 35.843302999104075],
            [-79.541728, 35.899850999103485]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "011",
        COUNTYNS: "01209172",
        AFFGEOID: "0500000US42011",
        GEOID: "42011",
        NAME: "Berks",
        LSAD: "06",
        ALAND: 2218035729,
        AWATER: 24256617
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.440181, 40.495951999068147],
            [-76.171624, 40.534946999067955],
            [-76.01708, 40.574454999067761],
            [-75.993212, 40.639711999067423],
            [-75.891473, 40.677269999067249],
            [-75.571926, 40.473347999068267],
            [-75.529694, 40.446994999068409],
            [-75.638828, 40.312771999069099],
            [-75.696782, 40.241862999069482],
            [-75.743178, 40.216720999069622],
            [-75.873375, 40.137106999070056],
            [-76.043993, 40.247195999069454],
            [-76.091764, 40.27769199906929],
            [-76.151215, 40.315970999069094],
            [-76.205528, 40.360660999068855],
            [-76.307108, 40.413437999068577],
            [-76.341021, 40.434357999068467],
            [-76.440181, 40.495951999068147]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "109",
        COUNTYNS: "01265786",
        AFFGEOID: "0500000US46109",
        GEOID: "46109",
        NAME: "Roberts",
        LSAD: "06",
        ALAND: 2851702831,
        AWATER: 89947545
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.228291, 45.935656594703417],
            [-97.082093, 45.935841999057324],
            [-96.563672, 45.935244999057318],
            [-96.571871, 45.87184599905725],
            [-96.587093, 45.816444999057182],
            [-96.630512, 45.781156999057153],
            [-96.672665, 45.732335999057113],
            [-96.745086, 45.701575999057077],
            [-96.82616, 45.65416399905704],
            [-96.851621, 45.619411999057021],
            [-96.843957, 45.594002999056997],
            [-96.835419161978095, 45.586128691937795],
            [-96.781036, 45.535971999056962],
            [-96.742509, 45.47872299905692],
            [-96.710786, 45.436929999056908],
            [-96.675447, 45.410215999056881],
            [-96.617726, 45.408091999056879],
            [-96.562142, 45.386089999056857],
            [-96.521787, 45.375644999056867],
            [-96.482556, 45.346272999056858],
            [-96.47007760304929, 45.326799648449153],
            [-96.992946, 45.326879999056843],
            [-97.007543, 45.296865999056834],
            [-97.226244, 45.297646999056838],
            [-97.227089, 45.558157999056974],
            [-97.228291, 45.935656594703417]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "027",
        COUNTYNS: "01605078",
        AFFGEOID: "0500000US56027",
        GEOID: "56027",
        NAME: "Niobrara",
        LSAD: "06",
        ALAND: 6801380679,
        AWATER: 4969450
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.899941, 43.499639999057969],
            [-104.054794284348006, 43.503327529463974],
            [-104.054684568427007, 43.477815540769029],
            [-104.053876, 43.289800999058343],
            [-104.053127, 43.000584999058944],
            [-104.052586, 42.630916999059856],
            [-104.05266219156799, 42.611766299601406],
            [-104.655639, 42.609467999059923],
            [-104.892437, 42.608938999059916],
            [-104.89848, 42.783029999059458],
            [-104.899941, 43.499639999057969]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "025",
        COUNTYNS: "00659458",
        AFFGEOID: "0500000US27025",
        GEOID: "27025",
        NAME: "Chisago",
        LSAD: "06",
        ALAND: 1074617226,
        AWATER: 71151170
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.141793, 45.730656999057111],
            [-92.840742106625498, 45.729396733526812],
            [-92.869689, 45.715141999057082],
            [-92.886697124408997, 45.644147999057047],
            [-92.887929, 45.639005999057034],
            [-92.881136, 45.57340899905698],
            [-92.801503, 45.562853999056962],
            [-92.756906, 45.557498999056968],
            [-92.728023, 45.525651999056954],
            [-92.686793, 45.472270999056917],
            [-92.646768, 45.437928999056908],
            [-92.658486, 45.396057999056872],
            [-92.698967, 45.33637399905686],
            [-92.748267674685891, 45.296059385566622],
            [-93.01944, 45.296928999056831],
            [-93.019563, 45.411769999056879],
            [-93.022274, 45.557153999056979],
            [-93.141974, 45.558108999056969],
            [-93.141793, 45.730656999057111]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "161",
        COUNTYNS: "00835902",
        AFFGEOID: "0500000US31161",
        GEOID: "31161",
        NAME: "Sheridan",
        LSAD: "06",
        ALAND: 6321833793,
        AWATER: 74941797
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.792110939839006, 43.000036234829345],
            [-102.40864, 42.999625999058956],
            [-102.082548043072009, 42.999140639448953],
            [-102.084961, 42.785508999059452],
            [-102.066718, 42.44941299906035],
            [-102.04028, 42.096743999061452],
            [-102.006204, 42.096337999061447],
            [-102.008562, 42.008900999061751],
            [-102.06665, 42.009194999061741],
            [-102.677582, 42.005281999061751],
            [-102.697835, 42.004842999061765],
            [-102.742269, 42.092387999061465],
            [-102.749611, 42.430453999060418],
            [-102.773366, 42.439921999060381],
            [-102.773369, 42.78526499905945],
            [-102.792110939839006, 43.000036234829345]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "015",
        COUNTYNS: "01682074",
        AFFGEOID: "0500000US06015",
        GEOID: "06015",
        NAME: "Del Norte",
        LSAD: "06",
        ALAND: 2606117992,
        AWATER: 578742645
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.219592, 41.846431999062297],
            [-124.203402, 41.940963999061978],
            [-124.211605, 41.998459999061787],
            [-124.001188, 41.996145999061781],
            [-123.822037598587997, 41.995620816008781],
            [-123.656998, 41.995136999061778],
            [-123.519112836804013, 41.999172517094678],
            [-123.565442, 41.903140999062096],
            [-123.642812, 41.889352999062154],
            [-123.703768, 41.829116999062357],
            [-123.660205, 41.714054999062789],
            [-123.719174, 41.595612999063228],
            [-123.648046, 41.535023999063469],
            [-123.61179, 41.462126999063756],
            [-123.661363, 41.382089999064085],
            [-123.770239, 41.380775999064085],
            [-123.770551, 41.464192999063748],
            [-124.067473659422006, 41.464736187554635],
            [-124.081987, 41.547760999063421],
            [-124.116037, 41.628848999063095],
            [-124.143479, 41.709283999062798],
            [-124.154246, 41.728800999062727],
            [-124.19104, 41.736078999062691],
            [-124.245027, 41.792299999062493],
            [-124.219592, 41.846431999062297]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "095",
        COUNTYNS: "00277312",
        AFFGEOID: "0500000US06095",
        GEOID: "06095",
        NAME: "Solano",
        LSAD: "06",
        ALAND: 2128347201,
        AWATER: 218679972
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.406786, 38.155631999083191],
            [-122.213464, 38.154893999083193],
            [-122.195971, 38.162990999083135],
            [-122.205982, 38.31571299908196],
            [-122.061379, 38.32741099908187],
            [-122.126389, 38.428917999081115],
            [-122.103281, 38.513347999080487],
            [-122.011673, 38.48899099908067],
            [-121.940285, 38.533383999080343],
            [-121.712034, 38.537961999080309],
            [-121.693697, 38.313731999081973],
            [-121.593273, 38.313088999081984],
            [-121.615413, 38.195695999082886],
            [-121.685645, 38.159643999083158],
            [-121.71213, 38.085519999083736],
            [-121.743164, 38.087228999083727],
            [-121.796395, 38.060846999083928],
            [-121.827916, 38.066222999083884],
            [-121.843228, 38.076654999083793],
            [-121.860477, 38.070606999083857],
            [-121.862462, 38.066029999083895],
            [-121.878235, 38.049317999084018],
            [-122.050386, 38.060197999083933],
            [-122.095733, 38.048354999084033],
            [-122.14312, 38.031760999084163],
            [-122.269320054916989, 38.060374496615125],
            [-122.301804, 38.105141999083585],
            [-122.393588, 38.14344899908329],
            [-122.397581230623999, 38.142003596336593],
            [-122.406786, 38.155631999083191]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "195",
        COUNTYNS: "01496656",
        AFFGEOID: "0500000US51195",
        GEOID: "51195",
        NAME: "Wise",
        LSAD: "06",
        ALAND: 1044884216,
        AWATER: 5284456
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.865192, 36.920922999093591],
            [-82.869183, 36.974181999093126],
            [-82.815748, 37.007195999092822],
            [-82.750715, 37.024106999092659],
            [-82.722254, 37.057947999092363],
            [-82.726294, 37.111851999091897],
            [-82.56527570279701, 37.195901105974229],
            [-82.558178, 37.199605999091105],
            [-82.553639544903675, 37.201450373553797],
            [-82.546135, 37.125117999091763],
            [-82.467345, 37.022135999092683],
            [-82.361944, 36.955714999093281],
            [-82.32783, 36.970981999093141],
            [-82.298596, 36.905760999093737],
            [-82.406633, 36.876355999094002],
            [-82.411098, 36.885043999093931],
            [-82.61774, 36.880772999093963],
            [-82.771595, 36.800030999094709],
            [-82.847362, 36.847662999094275],
            [-82.883754212976385, 36.897130135748021],
            [-82.865192, 36.920922999093591]
          ],
          [
            [-82.640127, 36.902331999093768],
            [-82.621962, 36.927067999093545],
            [-82.591147, 36.935312999093476],
            [-82.595022, 36.95575199909328],
            [-82.634101, 36.952247999093316],
            [-82.663625, 36.9209589990936],
            [-82.640127, 36.902331999093768]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "015",
        COUNTYNS: "00198123",
        AFFGEOID: "0500000US08015",
        GEOID: "08015",
        NAME: "Chaffee",
        LSAD: "06",
        ALAND: 2624715691,
        AWATER: 3999975
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.577966, 39.057907999076669],
            [-106.191006, 39.056072999076683],
            [-106.110995, 38.940098999077463],
            [-106.028138, 38.942848999077441],
            [-105.925123, 38.872177999077927],
            [-105.906587, 38.806961999078396],
            [-105.96975, 38.693550999079186],
            [-105.878122, 38.602119999079854],
            [-105.908717, 38.505630999080559],
            [-106.010751, 38.446565999080981],
            [-106.042771, 38.458266999080898],
            [-106.076535, 38.423651999081152],
            [-106.24694, 38.422767999081174],
            [-106.354185, 38.533383999080336],
            [-106.379899, 38.638482999079592],
            [-106.452126, 38.698776999079151],
            [-106.417431, 38.725674999078954],
            [-106.406987, 38.82933399907823],
            [-106.326099, 38.911003999077657],
            [-106.465032, 38.910353999077664],
            [-106.554211, 38.998710999077069],
            [-106.599214, 38.997993999077067],
            [-106.577966, 39.057907999076669]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "149",
        COUNTYNS: "00695795",
        AFFGEOID: "0500000US28149",
        GEOID: "28149",
        NAME: "Warren",
        LSAD: "06",
        ALAND: 1524245417,
        AWATER: 77800995
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.108509, 32.208149999148624],
            [-90.991227, 32.214661999148532],
            [-90.947834, 32.283485999147551],
            [-90.92117, 32.342072999146744],
            [-90.986672, 32.351759999146594],
            [-90.965986, 32.424805999145583],
            [-91.052907, 32.43844199914539],
            [-91.060516, 32.512360999144356],
            [-91.011275, 32.5165959991443],
            [-91.048760427763696, 32.572797039564932],
            [-90.964043, 32.575604999143486],
            [-90.963381, 32.41118499914576],
            [-90.861364, 32.442114999145332],
            [-90.860566, 32.57517299914349],
            [-90.728344, 32.566264999143613],
            [-90.720102, 32.61885799914289],
            [-90.655527, 32.58977099914329],
            [-90.654563, 32.546041999143888],
            [-90.553821, 32.507573999144427],
            [-90.636162, 32.374497999146286],
            [-90.705175, 32.346787999146656],
            [-90.728622, 32.225118999148386],
            [-90.77482, 32.153992999149402],
            [-90.90774, 32.131887999149711],
            [-90.942925, 32.078336999150473],
            [-91.039472318907002, 32.107968141815356],
            [-91.108509, 32.208149999148624]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "327",
        COUNTYNS: "01383949",
        AFFGEOID: "0500000US48327",
        GEOID: "48327",
        NAME: "Menard",
        LSAD: "06",
        ALAND: 2336237920,
        AWATER: 613559
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.115216, 31.087993999165167],
            [-99.603223, 31.087295999165182],
            [-99.603627, 30.94072599916743],
            [-99.484755, 30.940604999167427],
            [-99.483869, 30.710770999171018],
            [-100.116234, 30.710365999171028],
            [-100.115216, 31.087993999165167]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "179",
        COUNTYNS: "00758544",
        AFFGEOID: "0500000US29179",
        GEOID: "29179",
        NAME: "Reynolds",
        LSAD: "06",
        ALAND: 2093947210,
        AWATER: 15372862
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.312458, 37.592823999087727],
            [-91.155073, 37.588091999087766],
            [-90.987055, 37.603851999087631],
            [-90.768695, 37.603010999087651],
            [-90.772949, 37.373658999089585],
            [-90.735453, 37.271039999090476],
            [-90.737146, 37.166412999091399],
            [-90.779025, 37.137919999091658],
            [-90.779553, 37.050323999092434],
            [-90.964466, 37.053583999092403],
            [-91.017766, 37.095676999092028],
            [-91.034346, 37.169342999091377],
            [-91.126028, 37.199479999091103],
            [-91.159323, 37.25493599909062],
            [-91.175405, 37.414804999089242],
            [-91.211863, 37.41527699908923],
            [-91.210984, 37.501910999088494],
            [-91.314236, 37.505131999088455],
            [-91.312458, 37.592823999087727]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "063",
        COUNTYNS: "01719596",
        AFFGEOID: "0500000US30063",
        GEOID: "30063",
        NAME: "Missoula",
        LSAD: "06",
        ALAND: 6715966294,
        AWATER: 64482586
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.797204, 47.268915999059843],
            [-114.563737, 47.254610999059807],
            [-114.346111, 47.176234999059602],
            [-114.264148, 47.121069999059465],
            [-114.18717, 47.13819099905951],
            [-113.933503, 47.138288999059505],
            [-113.933568, 47.181719999059624],
            [-113.821447, 47.181708999059616],
            [-113.821166, 47.268699999059848],
            [-113.870769, 47.288157999059898],
            [-113.87111, 47.391969999060187],
            [-113.95118, 47.475993999060421],
            [-113.908581, 47.514435999060531],
            [-113.930289, 47.59849899906078],
            [-113.948844, 47.599992999060788],
            [-113.634079, 47.600026999060788],
            [-113.466485, 47.600056999060797],
            [-113.46645, 47.179185999059612],
            [-113.302943, 47.179642999059617],
            [-113.302903, 46.832158999058784],
            [-113.479832, 46.802771999058734],
            [-113.479856, 46.744713999058597],
            [-113.668166, 46.716228999058551],
            [-113.667996, 46.658371999058438],
            [-113.827743, 46.66089599905844],
            [-114.317808, 46.660550999058437],
            [-114.335724935404997, 46.655272966412028],
            [-114.360709, 46.669058999058457],
            [-114.453239, 46.649265999058414],
            [-114.547321, 46.644484999058406],
            [-114.591116003227015, 46.652550479413229],
            [-114.621483, 46.658142999058427],
            [-114.626695, 46.712888999058549],
            [-114.676827162695005, 46.731838738871687],
            [-114.549127, 46.747365999058609],
            [-114.566129, 46.96340499905908],
            [-114.525358, 47.009249999059193],
            [-114.419532, 47.023245999059228],
            [-114.483694, 47.067507999059337],
            [-114.566094, 47.067379999059334],
            [-114.586735, 47.123756999059466],
            [-114.712283, 47.182186999059617],
            [-114.712274, 47.225589999059729],
            [-114.775833, 47.225572999059736],
            [-114.797204, 47.268915999059843]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "015",
        COUNTYNS: "00066975",
        AFFGEOID: "0500000US05015",
        GEOID: "05015",
        NAME: "Carroll",
        LSAD: "06",
        ALAND: 1631597803,
        AWATER: 22434173
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.866758211671069, 36.498866163782438],
            [-93.700171, 36.499134999097542],
            [-93.584281547355687, 36.498901677725847],
            [-93.426989, 36.498584999097545],
            [-93.315327106529097, 36.498312725395458],
            [-93.293447355633987, 36.498259374331852],
            [-93.30252, 36.482491999097711],
            [-93.30082, 36.121549999101248],
            [-93.479528, 36.125071999101195],
            [-93.551135, 36.126270999101195],
            [-93.619974, 36.201207999100454],
            [-93.657689, 36.30308199909944],
            [-93.815605, 36.306562999099398],
            [-93.890508, 36.37789399909871],
            [-93.866758211671069, 36.498866163782438]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "089",
        COUNTYNS: "01209184",
        AFFGEOID: "0500000US42089",
        GEOID: "42089",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 1575385119,
        AWATER: 22867226
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.600736, 41.161496999065001],
            [-75.558862, 41.206856999064804],
            [-75.505607, 41.232538999064694],
            [-75.359184, 41.23920599906468],
            [-75.307348, 41.242229999064655],
            [-75.129721, 41.252100999064609],
            [-75.156507, 41.150340999065051],
            [-75.076811, 41.141121999065099],
            [-75.027957, 41.079867999065364],
            [-74.992385995182204, 41.093028246058708],
            [-75.015271, 41.061214999065449],
            [-75.070532, 41.018619999065642],
            [-75.133086, 40.980178999065821],
            [-75.123253944208486, 40.965305533453588],
            [-75.217802, 40.928101999066058],
            [-75.29494, 40.862199999066362],
            [-75.383266, 40.831036999066505],
            [-75.474193, 40.814745999066588],
            [-75.487445, 40.837714999066478],
            [-75.556438, 40.928692999066051],
            [-75.49599, 40.987180999065778],
            [-75.649637, 41.122343999065173],
            [-75.600736, 41.161496999065001]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "097",
        COUNTYNS: "01629479",
        AFFGEOID: "0500000US22097",
        GEOID: "22097",
        NAME: "St. Landry",
        LSAD: "15",
        ALAND: 2392904508,
        AWATER: 39047412
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.458914, 30.52792999917391],
            [-92.262719, 30.538737999173733],
            [-92.211243, 30.568124999173275],
            [-92.172408, 30.673766999171615],
            [-92.172415, 30.767934999170119],
            [-92.212711, 30.848606999168865],
            [-92.028871, 30.849496999168849],
            [-91.815258, 30.848106999168873],
            [-91.733673, 30.701729999171167],
            [-91.75698, 30.562550999173364],
            [-91.731432, 30.512118999174159],
            [-91.756193, 30.497737999174394],
            [-91.678896, 30.394730999176037],
            [-91.978184, 30.407402999175829],
            [-91.988718, 30.368597999176462],
            [-92.04943, 30.381306999176264],
            [-92.089446, 30.33016599917708],
            [-92.142184, 30.298816999177586],
            [-92.158554, 30.342444999176884],
            [-92.158606, 30.349735999176758],
            [-92.175775, 30.349571999176771],
            [-92.176059, 30.400470999175944],
            [-92.244795, 30.480192999174669],
            [-92.493259, 30.480498999174671],
            [-92.458914, 30.52792999917391]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "063",
        COUNTYNS: "00695755",
        AFFGEOID: "0500000US28063",
        GEOID: "28063",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 1346620561,
        AWATER: 18988092
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.345714, 31.842860999153881],
            [-91.290135, 31.833657999154021],
            [-91.244015161324697, 31.86973243665739],
            [-91.013676, 31.861851999153608],
            [-90.841886, 31.826254999154123],
            [-90.852657, 31.785421999154707],
            [-90.736282, 31.786133999154703],
            [-90.736824, 31.698490999155982],
            [-90.73733, 31.611123999157282],
            [-91.153864, 31.610067999157298],
            [-91.300257, 31.672487999156367],
            [-91.297343, 31.678584999156286],
            [-91.300281, 31.681196999156242],
            [-91.292648, 31.687111999156159],
            [-91.291888, 31.691702999156092],
            [-91.294002, 31.697893999156001],
            [-91.299095, 31.700088999155959],
            [-91.296926, 31.707373999155859],
            [-91.302116, 31.707495999155849],
            [-91.301028, 31.713862999155758],
            [-91.307062, 31.711360999155797],
            [-91.317864, 31.749765999155237],
            [-91.320458733281583, 31.747800613637459],
            [-91.359514, 31.799361999154513],
            [-91.345714, 31.842860999153881]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "473",
        COUNTYNS: "01384022",
        AFFGEOID: "0500000US48473",
        GEOID: "48473",
        NAME: "Waller",
        LSAD: "06",
        ALAND: 1329535289,
        AWATER: 11622560
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.15901, 30.206612999179068],
            [-96.093165, 30.225186999178771],
            [-96.069319, 30.229963999178704],
            [-95.804306, 30.245569999178446],
            [-95.80333, 30.090095999180964],
            [-95.844741, 30.088913999180992],
            [-95.960733, 30.163414999179778],
            [-95.82622, 29.788279999185953],
            [-95.97094, 29.72855399918695],
            [-96.032711, 29.727943999186955],
            [-96.049234, 29.803186999185698],
            [-96.121405, 29.836651999185136],
            [-96.13165, 29.937503999183473],
            [-96.084541, 30.005136999182366],
            [-96.146052, 30.070223999181302],
            [-96.191782, 30.138419999180186],
            [-96.15901, 30.206612999179068]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "069",
        COUNTYNS: "01008553",
        AFFGEOID: "0500000US37069",
        GEOID: "37069",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1273631713,
        AWATER: 7304032
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.544812, 36.080845999101648],
            [-78.496614, 36.175198999100715],
            [-78.42188, 36.164308999100818],
            [-78.37814, 36.235064999100118],
            [-78.306965, 36.266190999099813],
            [-78.133399, 36.246382999100007],
            [-78.108277, 36.209875999100362],
            [-78.006551, 36.20263299910043],
            [-78.255973, 35.818119999104326],
            [-78.307409, 35.896737999103522],
            [-78.546414, 36.021825999102241],
            [-78.544812, 36.080845999101648]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "023",
        COUNTYNS: "01034234",
        AFFGEOID: "0500000US38023",
        GEOID: "38023",
        NAME: "Divide",
        LSAD: "06",
        ALAND: 3265315042,
        AWATER: 86629398
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.048736, 48.99987699906616],
            [-103.375467, 48.99895099906616],
            [-102.938777019428017, 48.999350249351259],
            [-102.939932, 48.720468999064906],
            [-102.885686, 48.720487999064908],
            [-102.885775, 48.633297999064531],
            [-104.048087568832003, 48.634012372463147],
            [-104.0489, 48.847386999065456],
            [-104.048736, 48.99987699906616]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "053",
        COUNTYNS: "01448040",
        AFFGEOID: "0500000US49053",
        GEOID: "49053",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 6285757433,
        AWATER: 9061293
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.052472, 37.604775999087614],
            [-114.052471996614003, 37.604776641807035],
            [-113.587797, 37.604610999087626],
            [-113.474485, 37.618288999087511],
            [-113.474029, 37.529084999088255],
            [-113.25334, 37.528982999088264],
            [-113.25349, 37.482166999088655],
            [-113.037048, 37.47447399908873],
            [-113.037088, 37.499747999088513],
            [-112.900993, 37.500020999088505],
            [-112.899190897616009, 37.000301227721287],
            [-112.966471, 37.00021899909288],
            [-113.965907, 37.000024999092886],
            [-114.0506, 37.000395999092873],
            [-114.051749, 37.088433999092096],
            [-114.051974, 37.284510999090358],
            [-114.052701, 37.492013999088577],
            [-114.052472, 37.604775999087614]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "023",
        COUNTYNS: "01622954",
        AFFGEOID: "0500000US26023",
        GEOID: "26023",
        NAME: "Branch",
        LSAD: "06",
        ALAND: 1311600891,
        AWATER: 34424716
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.293626, 42.071552999061531],
            [-84.826491, 42.07246799906153],
            [-84.825128283876793, 41.76019900541452],
            [-85.196774200302002, 41.759870840865624],
            [-85.232835, 41.759838999062623],
            [-85.2921788113335, 41.759755273764412],
            [-85.293626, 42.071552999061531]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "063",
        COUNTYNS: "00465220",
        AFFGEOID: "0500000US19063",
        GEOID: "19063",
        NAME: "Emmet",
        LSAD: "06",
        ALAND: 1025328490,
        AWATER: 17196403
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.914613484713598, 43.500595872044059],
            [-94.874235, 43.500556999057984],
            [-94.85455506761069, 43.500553418210174],
            [-94.442849989718198, 43.500478506712675],
            [-94.443137, 43.255013999058413],
            [-94.913723, 43.255053999058418],
            [-94.914613484713598, 43.500595872044059]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "011",
        COUNTYNS: "01448020",
        AFFGEOID: "0500000US49011",
        GEOID: "49011",
        NAME: "Davis",
        LSAD: "06",
        ALAND: 774500764,
        AWATER: 870693260
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.451621, 41.087333999065336],
            [-112.186693, 41.153027999065046],
            [-111.856247, 41.13908299906511],
            [-111.838249, 41.022979999065626],
            [-111.801627, 40.992576999065761],
            [-111.775041, 40.959315999065915],
            [-111.8154, 40.957717999065906],
            [-111.738744, 40.860997999066363],
            [-111.946289, 40.821789999066553],
            [-111.958672, 40.921821999066083],
            [-112.006566, 40.921845999066072],
            [-112.260216, 40.769092999066814],
            [-112.493515, 41.07688799906537],
            [-112.451621, 41.087333999065336]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "035",
        COUNTYNS: "01034218",
        AFFGEOID: "0500000US38035",
        GEOID: "38035",
        NAME: "Grand Forks",
        LSAD: "06",
        ALAND: 3720138079,
        AWATER: 8156569
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.903434, 48.194896999062806],
            [-97.141910387198294, 48.19362761793731],
            [-97.1458449594749, 48.173223482053224],
            [-97.146745, 48.168555999062697],
            [-97.105616, 48.091361999062421],
            [-97.068987, 48.026266999062173],
            [-97.037354, 47.933278999061862],
            [-96.996364, 47.844397999061556],
            [-96.928506, 47.74488399906123],
            [-96.893493301339888, 47.672126958504712],
            [-97.472745, 47.672050999061014],
            [-97.882405, 47.672178999061003],
            [-97.882179, 48.020802999062177],
            [-97.903434, 48.194896999062806]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "201",
        COUNTYNS: "00516947",
        AFFGEOID: "0500000US21201",
        GEOID: "21201",
        NAME: "Robertson",
        LSAD: "06",
        ALAND: 258799092,
        AWATER: 484952
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.162031, 38.554124999080202],
            [-84.083746, 38.608593999079794],
            [-83.991605, 38.593700999079914],
            [-83.970778, 38.587207999079958],
            [-83.930348, 38.492277999080656],
            [-83.980068, 38.43944799908104],
            [-83.999227, 38.422285999081168],
            [-84.1021, 38.45937899908089],
            [-84.149769, 38.479091999080751],
            [-84.183903, 38.498018999080607],
            [-84.162031, 38.554124999080202]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "009",
        COUNTYNS: "01383790",
        AFFGEOID: "0500000US48009",
        GEOID: "48009",
        NAME: "Archer",
        LSAD: "06",
        ALAND: 2339055662,
        AWATER: 57765519
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.953088, 33.833999999126966],
            [-98.423577, 33.836046999126928],
            [-98.421419, 33.46679699913161],
            [-98.420666, 33.396194999132518],
            [-98.953938, 33.397533999132499],
            [-98.953088, 33.833999999126966]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "001",
        COUNTYNS: "01696996",
        AFFGEOID: "0500000US54001",
        GEOID: "54001",
        NAME: "Barbour",
        LSAD: "06",
        ALAND: 883338745,
        AWATER: 4639232
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.224687, 39.170976999075911],
            [-80.166387, 39.242282999075442],
            [-80.023411, 39.247891999075406],
            [-79.895536, 39.299583999075068],
            [-79.871969, 39.29451799907509],
            [-79.809729, 39.23052599907551],
            [-79.838093, 39.131001999076169],
            [-79.825092, 39.11580299907628],
            [-79.895993, 38.973403999077235],
            [-80.085062, 38.947235999077414],
            [-80.054619, 39.03552899907681],
            [-80.128198, 39.037794999076787],
            [-80.227173, 39.112791999076293],
            [-80.224687, 39.170976999075911]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "081",
        COUNTYNS: "00659486",
        AFFGEOID: "0500000US27081",
        GEOID: "27081",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 1390177661,
        AWATER: 30255242
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.453806699667098, 44.631335831004826],
            [-96.09295, 44.630485999056816],
            [-96.08041, 44.543129999056852],
            [-96.07858, 44.196619999057077],
            [-96.452435824595497, 44.196801585722092],
            [-96.452213, 44.36014899905696],
            [-96.453291313598683, 44.543636800874857],
            [-96.453806699667098, 44.631335831004826]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "081",
        COUNTYNS: "01248016",
        AFFGEOID: "0500000US45081",
        GEOID: "45081",
        NAME: "Saluda",
        LSAD: "06",
        ALAND: 1172692435,
        AWATER: 23417452
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.990713, 33.996614999124937],
            [-81.870084, 34.135356999123246],
            [-81.860571, 34.179372999122712],
            [-81.731614, 34.187612999122614],
            [-81.568511, 34.099686999123676],
            [-81.502477, 34.073879999123989],
            [-81.471972, 34.076551999123971],
            [-81.571485, 33.877229999126406],
            [-81.651759, 33.814509999127189],
            [-81.836803, 33.866500999126544],
            [-81.894595, 33.975414999125206],
            [-82.008298, 33.961645999125359],
            [-81.990713, 33.996614999124937]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "071",
        COUNTYNS: "01531819",
        AFFGEOID: "0500000US53071",
        GEOID: "53071",
        NAME: "Walla Walla",
        LSAD: "06",
        ALAND: 3289366719,
        AWATER: 74935920
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.023417, 46.216655999057679],
            [-118.860733, 46.252454999057726],
            [-118.766898, 46.297915999057807],
            [-118.746199, 46.359511999057901],
            [-118.633826, 46.414021999057987],
            [-118.615592, 46.504542999058138],
            [-118.471646, 46.597261999058318],
            [-118.228635, 46.593361999058303],
            [-118.241872, 46.295063999057803],
            [-118.116117, 46.295454999057796],
            [-118.116624, 46.208049999057671],
            [-117.991481, 46.207701999057669],
            [-117.99697042960301, 46.00019066622999],
            [-118.36779, 46.000621999057394],
            [-118.606789154188988, 46.000863248848589],
            [-118.67787, 46.000934999057392],
            [-118.987129, 45.999854999057391],
            [-118.987227539564998, 45.9998002125811],
            [-118.941323, 46.027527999057426],
            [-118.974664, 46.139522999057576],
            [-119.041498, 46.192666999057643],
            [-119.023417, 46.216655999057679]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "119",
        COUNTYNS: "00450379",
        AFFGEOID: "0500000US18119",
        GEOID: "18119",
        NAME: "Owen",
        LSAD: "06",
        ALAND: 997910783,
        AWATER: 6566402
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.053646, 39.342620999074796],
            [-86.942473, 39.342042999074792],
            [-86.939981, 39.473344999073959],
            [-86.68571, 39.470062999073988],
            [-86.63191, 39.441085999074168],
            [-86.630818, 39.346947999074764],
            [-86.68561, 39.336042999074834],
            [-86.683002, 39.165745999075938],
            [-87.054578, 39.16808599907592],
            [-87.053646, 39.342620999074796]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "009",
        COUNTYNS: "01008535",
        AFFGEOID: "0500000US37009",
        GEOID: "37009",
        NAME: "Ashe",
        LSAD: "06",
        ALAND: 1100901784,
        AWATER: 8093124
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.734312, 36.413341999098378],
            [-81.695311, 36.467911999097844],
            [-81.699962, 36.536828999097189],
            [-81.677535, 36.588116999096698],
            [-81.499831, 36.579819999096777],
            [-81.353221224602194, 36.576238230253203],
            [-81.255438, 36.398236999098522],
            [-81.253649, 36.366600999098814],
            [-81.359255, 36.366432999098834],
            [-81.366725, 36.284446999099629],
            [-81.477516, 36.240249999100065],
            [-81.566349, 36.27220199909975],
            [-81.638186, 36.349605999098998],
            [-81.725372779685287, 36.389738496534697],
            [-81.734312, 36.413341999098378]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "25",
        COUNTYFP: "003",
        COUNTYNS: "00606928",
        AFFGEOID: "0500000US25003",
        GEOID: "25003",
        NAME: "Berkshire",
        LSAD: "06",
        ALAND: 2400678868,
        AWATER: 50642922
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.508142, 42.086256999061476],
            [-73.410644407427284, 42.351746298708541],
            [-73.383505657283607, 42.425646049244229],
            [-73.352527, 42.510001999060187],
            [-73.352524954944201, 42.510007509010784],
            [-73.307004076180576, 42.632653450471359],
            [-73.264957, 42.745939999059559],
            [-73.142495120514397, 42.743425716256461],
            [-73.023017893290088, 42.740972711786476],
            [-72.948758, 42.704091999059663],
            [-72.951039, 42.641005999059843],
            [-72.975409, 42.556036999060055],
            [-72.979284, 42.497856999060218],
            [-73.009085, 42.387733999060544],
            [-73.065772, 42.389110999060541],
            [-73.062886, 42.328950999060709],
            [-73.000173, 42.312677999060753],
            [-73.001529, 42.26266099906092],
            [-73.007867, 42.238408999060987],
            [-73.071113, 42.148205999061275],
            [-73.053362673748097, 42.040115570971935],
            [-73.127229170878394, 42.042123147416426],
            [-73.231056, 42.04494499906162],
            [-73.487314, 42.049637999061595],
            [-73.489679942032097, 42.053797708648283],
            [-73.508142, 42.086256999061476]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "113",
        COUNTYNS: "00198172",
        AFFGEOID: "0500000US08113",
        GEOID: "08113",
        NAME: "San Miguel",
        LSAD: "06",
        ALAND: 3332606125,
        AWATER: 5204095
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.041796614855002, 38.153032304051713],
            [-108.906749, 38.154958999083213],
            [-107.965789, 38.152327999083205],
            [-107.891469, 38.115956999083501],
            [-107.889716, 38.011614999084323],
            [-107.795469, 37.988519999084502],
            [-107.738283, 37.90543199908516],
            [-107.839715, 37.776713999086212],
            [-107.860845, 37.775516999086214],
            [-107.989208, 37.856639999085559],
            [-108.210164, 37.821124999085846],
            [-108.256498, 37.894606999085248],
            [-108.81671, 37.895537999085242],
            [-109.042604100621006, 37.88108481657796],
            [-109.041796614855002, 38.153032304051713]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "031",
        COUNTYNS: "01605080",
        AFFGEOID: "0500000US56031",
        GEOID: "56031",
        NAME: "Platte",
        LSAD: "06",
        ALAND: 5398019715,
        AWATER: 69035953
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.286511, 42.605825999059931],
            [-104.892437, 42.608938999059916],
            [-104.655639, 42.609467999059923],
            [-104.653338, 41.65300699906301],
            [-105.278236, 41.656654999063001],
            [-105.271809, 41.742028999062676],
            [-105.28391, 42.431401999060412],
            [-105.286511, 42.605825999059931]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "167",
        COUNTYNS: "00758538",
        AFFGEOID: "0500000US29167",
        GEOID: "29167",
        NAME: "Polk",
        LSAD: "06",
        ALAND: 1646005964,
        AWATER: 17838300
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.628404, 37.829434999085777],
            [-93.573202, 37.828034999085794],
            [-93.561399, 37.812981999085913],
            [-93.187915, 37.802736999085987],
            [-93.173183, 37.729659999086593],
            [-93.182489, 37.417203999089217],
            [-93.621153, 37.427422999089124],
            [-93.616033, 37.572688999087887],
            [-93.609489, 37.741754999086496],
            [-93.628404, 37.829434999085777]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "005",
        COUNTYNS: "00395091",
        AFFGEOID: "0500000US16005",
        GEOID: "16005",
        NAME: "Bannock",
        LSAD: "06",
        ALAND: 2881317883,
        AWATER: 92094662
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.750562, 42.951527999059053],
            [-112.678364, 43.021729999058905],
            [-112.063023, 43.022363999058896],
            [-112.082053, 42.950424999059059],
            [-112.149835, 42.8955579990592],
            [-112.120335, 42.831007999059352],
            [-112.141034, 42.763092999059523],
            [-112.10588, 42.699202999059679],
            [-111.969449, 42.71803899905963],
            [-111.916927, 42.617263999059901],
            [-111.875636, 42.416332999060465],
            [-111.896728, 42.256275999060932],
            [-111.983848, 42.285549999060848],
            [-112.126194, 42.285228999060848],
            [-112.190656, 42.350838999060642],
            [-112.327102, 42.323835999060741],
            [-112.298113, 42.454498999060341],
            [-112.419825, 42.503037999060197],
            [-112.37967, 42.646272999059818],
            [-112.49691, 42.733012999059582],
            [-112.515519, 42.909974999059159],
            [-112.558693, 42.961246999059036],
            [-112.747491, 42.912734999059154],
            [-112.750562, 42.951527999059053]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "021",
        COUNTYNS: "01531822",
        AFFGEOID: "0500000US53021",
        GEOID: "53021",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 3215718022,
        AWATER: 60509234
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.45319, 46.679242999058481],
            [-119.369165, 46.678178999058474],
            [-119.369429, 46.737694999058597],
            [-118.77232, 46.735389999058583],
            [-118.210243, 46.738832999058602],
            [-118.249122, 46.734137999058582],
            [-118.21566, 46.588923999058309],
            [-118.228635, 46.593361999058303],
            [-118.471646, 46.597261999058318],
            [-118.615592, 46.504542999058138],
            [-118.633826, 46.414021999057987],
            [-118.746199, 46.359511999057901],
            [-118.766898, 46.297915999057807],
            [-118.860733, 46.252454999057726],
            [-119.023417, 46.216655999057679],
            [-119.041498, 46.192666999057643],
            [-119.264392, 46.272594999057759],
            [-119.27032, 46.521310999058166],
            [-119.403042, 46.613042999058344],
            [-119.45319, 46.679242999058481]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "25",
        COUNTYFP: "015",
        COUNTYNS: "00606934",
        AFFGEOID: "0500000US25015",
        GEOID: "25015",
        NAME: "Hampshire",
        LSAD: "06",
        ALAND: 1365526500,
        AWATER: 46525649
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.065772, 42.389110999060541],
            [-73.009085, 42.387733999060544],
            [-72.979284, 42.497856999060218],
            [-72.975409, 42.556036999060055],
            [-72.876849, 42.541196999060098],
            [-72.871136, 42.484040999060255],
            [-72.757758, 42.445882999060366],
            [-72.700877, 42.452919999060342],
            [-72.669138, 42.409710999060472],
            [-72.489891, 42.433815999060407],
            [-72.483696, 42.407480999060468],
            [-72.375022, 42.420818999060451],
            [-72.356086, 42.303279999060784],
            [-72.314599, 42.343525999060667],
            [-72.288734, 42.352023999060648],
            [-72.210795, 42.311379999060762],
            [-72.213973, 42.294256999060806],
            [-72.211079, 42.251261999060951],
            [-72.221218, 42.245251999060976],
            [-72.291567, 42.232220999061013],
            [-72.333875, 42.220718999061049],
            [-72.395478, 42.185736999061149],
            [-72.44712, 42.227253999061027],
            [-72.589938, 42.21197499906107],
            [-72.607933, 42.216290999061059],
            [-72.623934, 42.233690999061004],
            [-72.598933, 42.268089999060905],
            [-72.609202, 42.285476999060847],
            [-72.613138, 42.286264999060855],
            [-72.686861, 42.183389999061156],
            [-72.793414, 42.236853999060997],
            [-72.912302, 42.239132999060985],
            [-72.885184, 42.332614999060695],
            [-72.953254, 42.343828999060669],
            [-73.000173, 42.312677999060753],
            [-73.062886, 42.328950999060709],
            [-73.065772, 42.389110999060541]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "201",
        COUNTYNS: "01785216",
        AFFGEOID: "0500000US17201",
        GEOID: "17201",
        NAME: "Winnebago",
        LSAD: "06",
        ALAND: 1329696034,
        AWATER: 15107670
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.401416811040576, 42.50044193013391],
            [-89.365798607663493, 42.500025966011712],
            [-89.042898, 42.496254999060213],
            [-88.992560226817304, 42.495854718959428],
            [-88.940384860378003, 42.495439826531424],
            [-88.939732, 42.152319999061255],
            [-89.091831, 42.150680999061272],
            [-89.172799, 42.150296999061261],
            [-89.173, 42.204240999061099],
            [-89.396192, 42.201915999061107],
            [-89.397809, 42.30717099906078],
            [-89.401416811040576, 42.50044193013391]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "103",
        COUNTYNS: "01101839",
        AFFGEOID: "0500000US40103",
        GEOID: "40103",
        NAME: "Noble",
        LSAD: "06",
        ALAND: 1895593648,
        AWATER: 27552185
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.461603, 36.593636999096645],
            [-97.057414, 36.593646999096642],
            [-96.912756, 36.598948999096592],
            [-96.888017, 36.574885999096821],
            [-97.009785, 36.506934999097474],
            [-97.032118, 36.506956999097476],
            [-97.032106, 36.333353999099153],
            [-96.924936, 36.333343999099142],
            [-96.925069, 36.246473999099997],
            [-97.140968, 36.246434999099996],
            [-97.140668, 36.159230999100878],
            [-97.354132, 36.159071999100867],
            [-97.371722, 36.164284999100808],
            [-97.460792, 36.164461999100808],
            [-97.461603, 36.593636999096645]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "047",
        COUNTYNS: "00558582",
        AFFGEOID: "0500000US22047",
        GEOID: "22047",
        NAME: "Iberville",
        LSAD: "15",
        ALAND: 1602243609,
        AWATER: 88655983
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.700933, 30.497432999174393],
            [-91.58337, 30.497004999174411],
            [-91.485089, 30.497137999174395],
            [-91.45329, 30.474923999174759],
            [-91.325479, 30.346805999176812],
            [-91.311285, 30.322471999177196],
            [-91.142105, 30.323292999177191],
            [-91.126597, 30.313319999177349],
            [-91.021014, 30.321488999177223],
            [-91.024256, 30.305615999177473],
            [-91.059273, 30.213112999178968],
            [-91.073432, 30.16073499917983],
            [-91.090097, 30.141109999180138],
            [-91.106477, 30.108098999180676],
            [-91.105951, 30.062477999181429],
            [-91.224356, 30.025335999182037],
            [-91.231842, 30.040384999181786],
            [-91.368835, 30.058823999181485],
            [-91.463833, 30.102492999180772],
            [-91.475303, 30.229490999178715],
            [-91.56276, 30.241948999178504],
            [-91.621055, 30.3099379991774],
            [-91.640099, 30.442727999175283],
            [-91.700933, 30.497432999174393]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "049",
        COUNTYNS: "00659470",
        AFFGEOID: "0500000US27049",
        GEOID: "27049",
        NAME: "Goodhue",
        LSAD: "06",
        ALAND: 1960231247,
        AWATER: 61007223
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.039485, 44.47187099905689],
            [-93.039344, 44.515349999056873],
            [-92.792715, 44.543239999056858],
            [-92.792584, 44.629721999056827],
            [-92.732043, 44.629492999056815],
            [-92.731623851303198, 44.7149227843164],
            [-92.696491, 44.689435999056798],
            [-92.618025, 44.612869999056826],
            [-92.54928, 44.577703999056844],
            [-92.399281, 44.558291999056841],
            [-92.361518, 44.558934999056845],
            [-92.316933133405797, 44.53927601125956],
            [-92.314071, 44.538013999056858],
            [-92.291005, 44.48546399905689],
            [-92.245364649005396, 44.454251123408717],
            [-92.430277, 44.455320999056909],
            [-92.430629, 44.368290999056967],
            [-92.550733, 44.368520999056969],
            [-92.550871, 44.194915999057102],
            [-92.678717, 44.195515999057079],
            [-93.041159, 44.196725999057094],
            [-93.039485, 44.47187099905689]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "015",
        COUNTYNS: "01622950",
        AFFGEOID: "0500000US26015",
        GEOID: "26015",
        NAME: "Barry",
        LSAD: "06",
        ALAND: 1432511156,
        AWATER: 62021519
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.545564, 42.768138999059502],
            [-85.309626, 42.769877999059496],
            [-85.074245, 42.770783999059503],
            [-85.071609, 42.421427999060441],
            [-85.298879, 42.419848999060449],
            [-85.543191, 42.421432999060443],
            [-85.545564, 42.768138999059502]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "001",
        COUNTYNS: "00424202",
        AFFGEOID: "0500000US17001",
        GEOID: "17001",
        NAME: "Adams",
        LSAD: "06",
        ALAND: 2214972287,
        AWATER: 41784365
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.506167908622274, 40.200643511864811],
            [-90.911969, 40.193087999069753],
            [-90.913616, 40.104451999070236],
            [-90.916609, 39.845074999071727],
            [-90.916071, 39.757167999072223],
            [-91.364616603323299, 39.758718225394517],
            [-91.361571, 39.787547999072054],
            [-91.397853, 39.821121999071856],
            [-91.436051, 39.845509999071723],
            [-91.428956, 39.907728999071345],
            [-91.436843268599091, 39.94524346274963],
            [-91.43709, 39.946416999071133],
            [-91.484064, 40.019331999070708],
            [-91.497663, 40.078256999070398],
            [-91.511956, 40.170440999069875],
            [-91.506167908622274, 40.200643511864811]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "109",
        COUNTYNS: "01622997",
        AFFGEOID: "0500000US26109",
        GEOID: "26109",
        NAME: "Menominee",
        LSAD: "06",
        ALAND: 2704072756,
        AWATER: 761779246
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.85683, 45.393105999056871],
            [-87.847429, 45.4441769990569],
            [-87.805773, 45.473138999056914],
            [-87.804203, 45.524675999056953],
            [-87.787292, 45.574905999056988],
            [-87.777671, 45.609203999057016],
            [-87.824676, 45.653210999057045],
            [-87.781007, 45.673933999057056],
            [-87.805076, 45.703555999057087],
            [-87.833049468035298, 45.722752574404005],
            [-87.697159, 45.722959999057103],
            [-87.696946, 45.898841999057261],
            [-87.617396, 45.898845999057286],
            [-87.617091, 45.986013999057384],
            [-87.367849, 45.985320999057372],
            [-87.325391, 45.898664999057281],
            [-87.327502, 45.551249999056964],
            [-87.253449628780189, 45.550115589946067],
            [-87.288726, 45.501605999056935],
            [-87.350852, 45.407742999056886],
            [-87.465201, 45.273350999056824],
            [-87.548964, 45.191590999056793],
            [-87.590208, 45.095263999056769],
            [-87.648191, 45.106367999056772],
            [-87.695055, 45.150521999056785],
            [-87.741805, 45.197050999056792],
            [-87.71148, 45.245223999056812],
            [-87.667423, 45.31635999905685],
            [-87.657349, 45.368751999056862],
            [-87.706767, 45.383826999056858],
            [-87.750928, 45.35503699905685],
            [-87.800464, 45.353607999056855],
            [-87.863489, 45.353019999056862],
            [-87.85683, 45.393105999056871]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "091",
        COUNTYNS: "01673774",
        AFFGEOID: "0500000US51091",
        GEOID: "51091",
        NAME: "Highland",
        LSAD: "06",
        ALAND: 1075253055,
        AWATER: 1805613
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.804093, 38.313921999081984],
            [-79.7346, 38.356727999081656],
            [-79.689675, 38.431438999081095],
            [-79.691088, 38.463743999080855],
            [-79.669128, 38.510882999080515],
            [-79.649075, 38.591514999079912],
            [-79.54257, 38.553216999080206],
            [-79.476638, 38.457227999080914],
            [-79.370302, 38.427243999081135],
            [-79.311296065514398, 38.418454600536194],
            [-79.308495, 38.382962999081464],
            [-79.438103, 38.216234999082715],
            [-79.512158, 38.180418999083003],
            [-79.706196, 38.221191999082691],
            [-79.797013536806887, 38.26726812022153],
            [-79.787542, 38.273297999082281],
            [-79.804093, 38.313921999081984]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "089",
        COUNTYNS: "00198160",
        AFFGEOID: "0500000US08089",
        GEOID: "08089",
        NAME: "Otero",
        LSAD: "06",
        ALAND: 3268891492,
        AWATER: 19625371
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.058242, 38.146491999083253],
            [-103.835616, 38.113339999083522],
            [-103.619172, 38.113354999083519],
            [-103.619283, 38.17145499908306],
            [-103.509364, 38.172513999083066],
            [-103.501764, 38.265015999082351],
            [-103.399938, 38.265434999082352],
            [-103.404339, 37.64357599908729],
            [-104.058196, 37.644048999087303],
            [-104.061132, 37.734703999086548],
            [-104.058242, 38.146491999083253]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "087",
        COUNTYNS: "00516890",
        AFFGEOID: "0500000US21087",
        GEOID: "21087",
        NAME: "Green",
        LSAD: "06",
        ALAND: 740839712,
        AWATER: 7123457
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.697325, 37.301837999090203],
            [-85.657332, 37.42199599908917],
            [-85.634193, 37.47178499908874],
            [-85.583497, 37.469916999088767],
            [-85.585163, 37.432851999089088],
            [-85.478081, 37.363672999089665],
            [-85.352771, 37.19243199909117],
            [-85.380258, 37.168313999091374],
            [-85.526881, 37.109449999091893],
            [-85.632313, 37.125055999091764],
            [-85.686482, 37.182426999091248],
            [-85.697325, 37.301837999090203]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "173",
        COUNTYNS: "00758541",
        AFFGEOID: "0500000US29173",
        GEOID: "29173",
        NAME: "Ralls",
        LSAD: "06",
        ALAND: 1216727530,
        AWATER: 36396585
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.717968, 39.33912199907482],
            [-91.711934, 39.599094999073188],
            [-91.715247, 39.657643999072832],
            [-91.715497, 39.686491999072658],
            [-91.305760334965186, 39.686215469150554],
            [-91.27614, 39.665758999072779],
            [-91.182875500819691, 39.598233114868592],
            [-91.460442, 39.450721999074105],
            [-91.438235, 39.318715999074939],
            [-91.718467, 39.324504999074904],
            [-91.717968, 39.33912199907482]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "117",
        COUNTYNS: "00835881",
        AFFGEOID: "0500000US31117",
        GEOID: "31117",
        NAME: "McPherson",
        LSAD: "06",
        ALAND: 2224733585,
        AWATER: 2680669
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.406466, 41.743199999062689],
            [-100.842459, 41.740403999062686],
            [-100.711711, 41.739760999062682],
            [-100.713243, 41.393510999064034],
            [-101.269695, 41.394933999064023],
            [-101.40726, 41.395415999064014],
            [-101.406466, 41.743199999062689]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "057",
        COUNTYNS: "01648575",
        AFFGEOID: "0500000US47057",
        GEOID: "47057",
        NAME: "Grainger",
        LSAD: "06",
        ALAND: 726715762,
        AWATER: 56600343
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.714704, 36.173782999100716],
            [-83.667408, 36.344380999099037],
            [-83.658007, 36.331981999099163],
            [-83.532504, 36.353384999098949],
            [-83.3868, 36.413185999098367],
            [-83.304197, 36.38818899909861],
            [-83.280595, 36.394688999098541],
            [-83.288896, 36.378788999098703],
            [-83.255292, 36.28909199909959],
            [-83.328295, 36.296689999099513],
            [-83.449498, 36.217988999100278],
            [-83.467097, 36.174687999100712],
            [-83.541099, 36.181986999100637],
            [-83.674237, 36.125007999101207],
            [-83.66746, 36.080586999101655],
            [-83.699405, 36.10946899910136],
            [-83.732704, 36.164582999100801],
            [-83.714704, 36.173782999100716]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "077",
        COUNTYNS: "01558642",
        AFFGEOID: "0500000US54077",
        GEOID: "54077",
        NAME: "Preston",
        LSAD: "06",
        ALAND: 1680406633,
        AWATER: 6681831
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.89974, 39.481944999073917],
            [-79.789277, 39.623780999073041],
            [-79.763774, 39.720775999072458],
            [-79.476662, 39.721077999072449],
            [-79.482366, 39.531688999073602],
            [-79.484372, 39.344299999074792],
            [-79.486873, 39.205960999075678],
            [-79.487175, 39.19490599907575],
            [-79.687283, 39.271397999075241],
            [-79.809729, 39.23052599907551],
            [-79.871969, 39.29451799907509],
            [-79.895536, 39.299583999075068],
            [-79.894688, 39.435587999074201],
            [-79.89974, 39.481944999073917]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "169",
        COUNTYNS: "01498172",
        AFFGEOID: "0500000US51169",
        GEOID: "51169",
        NAME: "Scott",
        LSAD: "06",
        ALAND: 1387786118,
        AWATER: 8105331
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.947587, 36.675869999095859],
            [-82.823358, 36.733602999095332],
            [-82.771595, 36.800030999094709],
            [-82.61774, 36.880772999093963],
            [-82.411098, 36.885043999093931],
            [-82.406633, 36.876355999094002],
            [-82.356356, 36.779851999094902],
            [-82.332078, 36.708968999095553],
            [-82.307831, 36.6196819990964],
            [-82.294136420129092, 36.595073032055133],
            [-82.487238, 36.595821999096628],
            [-82.609181949398987, 36.595089685165135],
            [-82.830433, 36.593760999096645],
            [-82.984458495572383, 36.595289968146631],
            [-82.947587, 36.675869999095859]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "177",
        COUNTYNS: "00485051",
        AFFGEOID: "0500000US20177",
        GEOID: "20177",
        NAME: "Shawnee",
        LSAD: "06",
        ALAND: 1408930616,
        AWATER: 30396059
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.035665, 39.144082999076069],
            [-96.035573, 39.216519999075601],
            [-95.589472, 39.216080999075608],
            [-95.596759, 39.064671999076623],
            [-95.500254, 39.056655999076668],
            [-95.500724, 38.869814999077946],
            [-95.946587, 38.869972999077952],
            [-95.946754, 39.098684999076376],
            [-95.993738, 39.133816999076153],
            [-96.03906, 39.126526999076205],
            [-96.035665, 39.144082999076069]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "175",
        COUNTYNS: "00835909",
        AFFGEOID: "0500000US31175",
        GEOID: "31175",
        NAME: "Valley",
        LSAD: "06",
        ALAND: 1471402787,
        AWATER: 6076915
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.212016, 41.740612999062684],
            [-98.759133, 41.740381999062684],
            [-98.752225, 41.740371999062681],
            [-98.751558, 41.39408399906403],
            [-99.203261, 41.394139999064045],
            [-99.212394, 41.408677999063961],
            [-99.212016, 41.740612999062684]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "017",
        COUNTYNS: "00198124",
        AFFGEOID: "0500000US08017",
        GEOID: "08017",
        NAME: "Cheyenne",
        LSAD: "06",
        ALAND: 4605714032,
        AWATER: 8166134
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.164159, 38.690132999079218],
            [-103.163025, 39.0376099990768],
            [-102.715766, 39.038674999076797],
            [-102.046571, 39.047037999076736],
            [-102.045712903087008, 38.697565769157656],
            [-102.045510574570002, 38.615164589257247],
            [-103.062143, 38.615020999079761],
            [-103.172943, 38.612449999079757],
            [-103.164159, 38.690132999079218]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "077",
        COUNTYNS: "00516885",
        AFFGEOID: "0500000US21077",
        GEOID: "21077",
        NAME: "Gallatin",
        LSAD: "06",
        ALAND: 254699793,
        AWATER: 16518583
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.021052141674204, 38.758527323792627],
            [-84.962535, 38.778034999078585],
            [-84.856904, 38.790223999078506],
            [-84.812877, 38.786086999078528],
            [-84.803247, 38.85072299907808],
            [-84.798699822952301, 38.859227389284726],
            [-84.778623, 38.859359999078009],
            [-84.660107, 38.777292999078597],
            [-84.780766, 38.765207999078683],
            [-84.785788, 38.720458999078986],
            [-84.811845, 38.70230699907912],
            [-84.934536, 38.662129999079411],
            [-84.962671, 38.679585999079286],
            [-85.021052141674204, 38.758527323792627]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "087",
        COUNTYNS: "00758498",
        AFFGEOID: "0500000US29087",
        GEOID: "29087",
        NAME: "Holt",
        LSAD: "06",
        ALAND: 1198369598,
        AWATER: 19839056
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.548182001180081, 40.264414671912057],
            [-95.180614, 40.26170599906937],
            [-95.064972, 40.246588999069459],
            [-95.04308, 40.130095999070093],
            [-95.060797, 40.070261999070425],
            [-95.010542, 40.046760999070557],
            [-94.993374239714385, 39.898565259063709],
            [-95.018743, 39.897371999071417],
            [-95.081534, 39.861717999071615],
            [-95.142445, 39.895419999071422],
            [-95.231114, 39.943783999071137],
            [-95.30829, 39.999997999070814],
            [-95.348777, 40.02929699907066],
            [-95.382957, 40.027111999070669],
            [-95.414734, 40.06981999907044],
            [-95.394216, 40.10826299907022],
            [-95.432165, 40.141024999070048],
            [-95.48102, 40.18852399906978],
            [-95.472548, 40.236077999069515],
            [-95.54716, 40.259065999069399],
            [-95.547870316270291, 40.262783459889178],
            [-95.548182001180081, 40.264414671912057]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "009",
        COUNTYNS: "01074018",
        AFFGEOID: "0500000US39009",
        GEOID: "39009",
        NAME: "Athens",
        LSAD: "06",
        ALAND: 1304383737,
        AWATER: 12463875
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.28041, 39.472905999073959],
            [-82.167379, 39.466306999074014],
            [-82.159161, 39.556573999073443],
            [-82.047528, 39.550824999073484],
            [-82.055779, 39.460052999074044],
            [-81.844863, 39.450215999074103],
            [-81.853668, 39.318164999074945],
            [-81.818361, 39.272580999075238],
            [-81.721808, 39.269596999075254],
            [-81.721468139172501, 39.210960808279353],
            [-81.752754, 39.184675999075814],
            [-81.752297036740103, 39.181034746481728],
            [-81.960882, 39.188743999075783],
            [-82.268583, 39.203759999075686],
            [-82.260243, 39.292914999075116],
            [-82.289663, 39.384194999074531],
            [-82.28041, 39.472905999073959]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "099",
        COUNTYNS: "01008566",
        AFFGEOID: "0500000US37099",
        GEOID: "37099",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 1272156138,
        AWATER: 9781460
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.351726, 35.468091999108019],
            [-83.236073, 35.524563999107407],
            [-83.186322, 35.514367999107513],
            [-83.164571, 35.509570999107567],
            [-82.920881, 35.292036999109911],
            [-82.983216, 35.131919999111666],
            [-83.056889, 35.053826999112538],
            [-83.00847281226919, 35.026918543036736],
            [-83.108606, 35.000658999113142],
            [-83.10861299650729, 35.000658842790834],
            [-83.143257, 35.077926999112272],
            [-83.223796, 35.157385999111384],
            [-83.224672, 35.238725999110493],
            [-83.276955, 35.228044999110601],
            [-83.339609, 35.330992999109476],
            [-83.351726, 35.468091999108019]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "045",
        COUNTYNS: "01550029",
        AFFGEOID: "0500000US54045",
        GEOID: "54045",
        NAME: "Logan",
        LSAD: "06",
        ALAND: 1175209778,
        AWATER: 4830034
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.190658, 37.974777999084615],
            [-81.988776, 38.02488399908421],
            [-81.932507, 38.025355999084205],
            [-81.980248, 37.986499999084522],
            [-81.785641, 37.936403999084916],
            [-81.722136, 37.809506999085933],
            [-81.607532, 37.788708999086111],
            [-81.762137, 37.756307999086374],
            [-81.701734, 37.70140899908683],
            [-81.802937, 37.660507999087159],
            [-81.930238, 37.682010999086977],
            [-81.993444, 37.640606999087325],
            [-82.082648, 37.701564999086827],
            [-82.112411, 37.854038999085581],
            [-82.190658, 37.974777999084615]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "129",
        COUNTYNS: "00161590",
        AFFGEOID: "0500000US01129",
        GEOID: "01129",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 2797720370,
        AWATER: 21652733
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.463625304684896, 31.697942370506198],
            [-88.088288, 31.699302999155979],
            [-88.078711, 31.669617999156412],
            [-88.089388, 31.65874699915657],
            [-88.072234, 31.594499999157534],
            [-88.051449, 31.583503999157688],
            [-88.027693, 31.584250999157678],
            [-88.031277, 31.560493999158034],
            [-87.970377, 31.53054599915848],
            [-87.942514, 31.526718999158533],
            [-87.92444, 31.503020999158888],
            [-87.906143, 31.491751999159053],
            [-87.935106, 31.442397999159788],
            [-87.88729, 31.392196999160547],
            [-87.908068, 31.323040999161584],
            [-87.959619, 31.316205999161689],
            [-87.946588, 31.19292999916356],
            [-87.972869, 31.162693999164013],
            [-88.022649, 31.144266999164305],
            [-88.329782, 31.143820999164312],
            [-88.432007, 31.114297999164755],
            [-88.44866, 31.421276999160121],
            [-88.449446069017085, 31.435836860437494],
            [-88.459478, 31.621651999157123],
            [-88.463625304684896, 31.697942370506198]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "041",
        COUNTYNS: "01550027",
        AFFGEOID: "0500000US54041",
        GEOID: "54041",
        NAME: "Lewis",
        LSAD: "06",
        ALAND: 1002071716,
        AWATER: 7108412
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.728326, 39.095678999076405],
            [-80.700642, 39.09049699907645],
            [-80.596126, 39.167068999075937],
            [-80.577525, 39.16764299907593],
            [-80.300209, 39.103856999076356],
            [-80.317486, 38.958646999077338],
            [-80.386989, 38.874807999077916],
            [-80.409541, 38.76832699907866],
            [-80.393063, 38.727570999078942],
            [-80.457332, 38.739168999078856],
            [-80.474111, 38.8334039990782],
            [-80.58349, 38.858870999078029],
            [-80.605799, 38.904166999077724],
            [-80.716815, 39.008429999076988],
            [-80.728326, 39.095678999076405]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "079",
        COUNTYNS: "00295731",
        AFFGEOID: "0500000US12079",
        GEOID: "12079",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 1802951600,
        AWATER: 50683945
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.806667, 30.371688999176413],
            [-83.72172, 30.4343539991754],
            [-83.736954, 30.507568999174236],
            [-83.604186, 30.581915999173056],
            [-83.61170405882639, 30.651560092880562],
            [-83.499951, 30.645662999172046],
            [-83.357716313628103, 30.637137872306393],
            [-83.309347121595806, 30.634238765843833],
            [-83.272612, 30.62768699917233],
            [-83.221868, 30.423409999175576],
            [-83.170969, 30.385224999176202],
            [-83.192343, 30.374845999176351],
            [-83.247253, 30.260669999178191],
            [-83.36726, 30.260439999178203],
            [-83.469267, 30.260350999178215],
            [-83.468768, 30.3042119991775],
            [-83.820235, 30.303539999177509],
            [-83.806667, 30.371688999176413]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "035",
        COUNTYNS: "01559963",
        AFFGEOID: "0500000US54035",
        GEOID: "54035",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 1202677932,
        AWATER: 18836099
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.89847, 38.929602999077538],
            [-81.827354, 38.945897999077424],
            [-81.775734, 38.98073699907718],
            [-81.793304, 39.040352999076781],
            [-81.807855, 39.083977999076495],
            [-81.750266843426303, 39.104031192176151],
            [-81.720677, 39.084227999076482],
            [-81.581447, 39.026178999076876],
            [-81.532186, 39.005449999077008],
            [-81.502628, 38.91792199907762],
            [-81.52731, 38.901665999077728],
            [-81.546631, 38.672386999079336],
            [-81.522166, 38.61274599907977],
            [-81.632817, 38.554698999080188],
            [-81.696348, 38.626426999079676],
            [-81.739877, 38.675320999079311],
            [-81.772843, 38.680842999079275],
            [-81.791265, 38.733103999078907],
            [-81.910606, 38.875969999077903],
            [-81.89847, 38.929602999077538]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "065",
        COUNTYNS: "01804513",
        AFFGEOID: "0500000US72065",
        GEOID: "72065",
        NAME: "Hatillo",
        LSAD: "13",
        ALAND: 108213684,
        AWATER: 43975697
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.836591129039007, 18.491129643784625],
            [-66.79932, 18.492774999431781],
            [-66.765570777836302, 18.482796007272345],
            [-66.769132, 18.469092999432402],
            [-66.770072, 18.325012999436201],
            [-66.777288, 18.32093799943631],
            [-66.826128, 18.323381999436243],
            [-66.824223, 18.342997999435724],
            [-66.810675, 18.386626999434576],
            [-66.836591129039007, 18.491129643784625]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "079",
        COUNTYNS: "00161565",
        AFFGEOID: "0500000US01079",
        GEOID: "01079",
        NAME: "Lawrence",
        LSAD: "06",
        ALAND: 1788859207,
        AWATER: 68687366
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.529667, 34.567080999118105],
            [-87.44577, 34.650967999117128],
            [-87.42651, 34.800021999115408],
            [-87.344224, 34.796492999115443],
            [-87.297905, 34.75081199911596],
            [-87.260676, 34.758625999115885],
            [-87.222773, 34.763225999115818],
            [-87.105073, 34.686036999116723],
            [-87.110111, 34.31379899912109],
            [-87.109911, 34.29929899912127],
            [-87.529722, 34.304597999121199],
            [-87.529667, 34.567080999118105]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "059",
        COUNTYNS: "00295735",
        AFFGEOID: "0500000US12059",
        GEOID: "12059",
        NAME: "Holmes",
        LSAD: "06",
        ALAND: 1239988859,
        AWATER: 26637585
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.035038104950601, 30.99374960646891],
            [-85.893632, 30.993454999166616],
            [-85.749715, 30.995281999166586],
            [-85.579497576338284, 30.997028747577467],
            [-85.498001585089497, 30.997865048527952],
            [-85.52921, 30.974438999166917],
            [-85.599934, 30.830551999169142],
            [-85.619011, 30.743867999170501],
            [-85.736051, 30.746369999170465],
            [-85.790185, 30.790350999169778],
            [-85.821616, 30.790774999169763],
            [-85.844421, 30.703129999171136],
            [-86.037591, 30.704571999171112],
            [-86.035038104950601, 30.99374960646891]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "113",
        COUNTYNS: "00306914",
        AFFGEOID: "0500000US12113",
        GEOID: "12113",
        NAME: "Santa Rosa",
        LSAD: "06",
        ALAND: 2621316069,
        AWATER: 418753385
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.313611, 30.847265999168879],
            [-87.286882, 30.925440999167666],
            [-87.16308069276559, 30.999024183044135],
            [-87.162644, 30.99902599916653],
            [-86.927851, 30.997677999166552],
            [-86.831979, 30.997353999166553],
            [-86.785691976005893, 30.996982992683357],
            [-86.800351351965887, 30.384508086553794],
            [-86.850625, 30.380966999176266],
            [-86.919204749800798, 30.368991471298948],
            [-87.155392, 30.32774799917711],
            [-87.2297658113648, 30.319632928978255],
            [-87.228226, 30.384245999176205],
            [-87.1344, 30.420293999175627],
            [-87.124968, 30.500195999174348],
            [-87.209715, 30.555265999173479],
            [-87.258884, 30.611280999172596],
            [-87.269407, 30.711686999171],
            [-87.302661, 30.719369999170887],
            [-87.313611, 30.847265999168879]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "025",
        COUNTYNS: "00695737",
        AFFGEOID: "0500000US28025",
        GEOID: "28025",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 1062066012,
        AWATER: 15393112
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.034498, 33.739429999128134],
            [-88.931604, 33.75431199912795],
            [-88.931176, 33.812373999127225],
            [-88.719979, 33.811329999127238],
            [-88.719626, 33.680252999128882],
            [-88.501915, 33.679947999128885],
            [-88.490978, 33.673725999128955],
            [-88.491087, 33.673213999128976],
            [-88.49142, 33.672719999128979],
            [-88.49156, 33.672521999128989],
            [-88.49179, 33.672194999128976],
            [-88.492211, 33.671794999128984],
            [-88.492484, 33.671542999128995],
            [-88.492906, 33.671247999129001],
            [-88.493181, 33.671247999129001],
            [-88.493437, 33.67145199912899],
            [-88.493554, 33.671541999128983],
            [-88.493721, 33.67169699912899],
            [-88.493881, 33.671853999128977],
            [-88.494217, 33.67233299912899],
            [-88.49447, 33.672813999128977],
            [-88.494698, 33.673105999128971],
            [-88.494976, 33.673418999128955],
            [-88.495228, 33.673668999128971],
            [-88.495262, 33.673695999128967],
            [-88.495782, 33.674106999128959],
            [-88.496161, 33.674524999128948],
            [-88.496583, 33.674776999128945],
            [-88.496904, 33.674957999128949],
            [-88.497142, 33.675086999128951],
            [-88.497793, 33.675272999128943],
            [-88.498369, 33.675312999128941],
            [-88.498893, 33.67528999912895],
            [-88.49922, 33.675233999128942],
            [-88.499372, 33.675219999128934],
            [-88.499725, 33.675190999128951],
            [-88.499803, 33.675142999128944],
            [-88.499931, 33.675066999128951],
            [-88.499987, 33.675029999128952],
            [-88.500043, 33.674992999128953],
            [-88.500465, 33.674697999128952],
            [-88.501129, 33.674112999128965],
            [-88.501191, 33.674058999128967],
            [-88.501439, 33.673633999128974],
            [-88.501594, 33.673352999128973],
            [-88.501837, 33.672478999128984],
            [-88.502121, 33.671300999129009],
            [-88.502292, 33.670322999128999],
            [-88.502386, 33.66919599912903],
            [-88.502407, 33.668946999129027],
            [-88.502394, 33.668879999129032],
            [-88.502207, 33.668138999129042],
            [-88.502007, 33.667264999129053],
            [-88.501928, 33.666187999129065],
            [-88.502126, 33.665846999129066],
            [-88.502378, 33.665684999129056],
            [-88.503169, 33.66545699912907],
            [-88.503687, 33.665480999129073],
            [-88.505731, 33.664640999129084],
            [-88.506533, 33.664046999129091],
            [-88.507481, 33.663165999129106],
            [-88.508841, 33.662195999129125],
            [-88.509407, 33.661959999129117],
            [-88.510085, 33.661815999129125],
            [-88.511344, 33.661690999129121],
            [-88.512048, 33.66152799912912],
            [-88.512655, 33.661234999129121],
            [-88.513104, 33.660696999129129],
            [-88.513339, 33.659310999129147],
            [-88.513515, 33.657957999129174],
            [-88.513574, 33.657012999129179],
            [-88.513608, 33.656651999129181],
            [-88.513698, 33.655700999129195],
            [-88.513938, 33.65448499912921],
            [-88.514421, 33.653631999129239],
            [-88.514482, 33.653029999129231],
            [-88.514315, 33.651804999129247],
            [-88.513987, 33.650856999129253],
            [-88.513955, 33.650610999129249],
            [-88.513933, 33.650437999129267],
            [-88.513917, 33.650208999129262],
            [-88.472714, 33.524450999130863],
            [-88.67125, 33.506167999131101],
            [-88.671526, 33.56513499913035],
            [-88.770596, 33.565211999130327],
            [-89.018613, 33.562113999130382],
            [-89.018343, 33.625118999129583],
            [-89.034924, 33.654711999129212],
            [-89.034498, 33.739429999128134]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "089",
        COUNTYNS: "01804525",
        AFFGEOID: "0500000US72089",
        GEOID: "72089",
        NAME: "Luquillo",
        LSAD: "13",
        ALAND: 66851395,
        AWATER: 53659423
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-65.770431, 18.366142999435112],
            [-65.741796465289397, 18.398181744571964],
            [-65.718618, 18.391905999434439],
            [-65.6613516940984, 18.389038930797913],
            [-65.732065, 18.288168999437175],
            [-65.753591, 18.296754999436949],
            [-65.758861, 18.290603999437113],
            [-65.770431, 18.366142999435112]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "029",
        COUNTYNS: "00659460",
        AFFGEOID: "0500000US27029",
        GEOID: "27029",
        NAME: "Clearwater",
        LSAD: "06",
        ALAND: 2587409023,
        AWATER: 79744538
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.582887, 48.020556999062158],
            [-95.229133, 48.019957999062164],
            [-95.277834, 47.925345999061832],
            [-95.193705, 47.872543999061655],
            [-95.183216, 47.412772999060252],
            [-95.169633, 47.325398999059992],
            [-95.169172, 47.152514999059541],
            [-95.551186, 47.151452999059536],
            [-95.551384, 47.482365999060441],
            [-95.554248, 47.499892999060499],
            [-95.553392, 47.673609999061014],
            [-95.578839, 47.673676999061016],
            [-95.582155, 47.933965999061861],
            [-95.582887, 48.020556999062158]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "091",
        COUNTYNS: "00485010",
        AFFGEOID: "0500000US20091",
        GEOID: "20091",
        NAME: "Johnson",
        LSAD: "06",
        ALAND: 1226262480,
        AWATER: 16736159
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.056258, 38.982119999077177],
            [-94.923349, 39.002632999077029],
            [-94.908765, 38.991400999077115],
            [-94.874812, 39.058039999076655],
            [-94.607870353373997, 39.044085095555246],
            [-94.608334, 38.981805999077181],
            [-94.608960343749501, 38.847211314523108],
            [-94.609456, 38.740699999078863],
            [-94.609489672345802, 38.73810170855208],
            [-94.908606, 38.738280999078874],
            [-95.056412, 38.738586999078869],
            [-95.056258, 38.982119999077177]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "093",
        COUNTYNS: "00295760",
        AFFGEOID: "0500000US12093",
        GEOID: "12093",
        NAME: "Okeechobee",
        LSAD: "06",
        ALAND: 1991433070,
        AWATER: 318578493
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.213717, 27.528653999226059],
            [-81.140472, 27.601301999224699],
            [-81.142164, 27.643237999223913],
            [-80.87315, 27.642287999223928],
            [-80.778047, 27.643194999223912],
            [-80.777166, 27.558732999225498],
            [-80.679821, 27.558469999225501],
            [-80.677862, 27.205987999232189],
            [-80.677427, 27.121618999233807],
            [-80.885639, 26.95891899923695],
            [-80.878669, 27.169220999232905],
            [-80.943186, 27.210598999232104],
            [-80.970916, 27.217697999231966],
            [-81.034429, 27.359159999229263],
            [-81.09845, 27.379107999228896],
            [-81.205096, 27.489701999226799],
            [-81.213717, 27.528653999226059]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "503",
        COUNTYNS: "01384037",
        AFFGEOID: "0500000US48503",
        GEOID: "48503",
        NAME: "Young",
        LSAD: "06",
        ALAND: 2368463881,
        AWATER: 42447713
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.953938, 33.397533999132499],
            [-98.420666, 33.396194999132518],
            [-98.426553, 33.007912999137609],
            [-98.427211, 32.952299999138361],
            [-98.575616, 32.954348999138332],
            [-98.950875, 32.956917999138305],
            [-98.953938, 33.397533999132499]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "077",
        COUNTYNS: "00066866",
        AFFGEOID: "0500000US05077",
        GEOID: "05077",
        NAME: "Lee",
        LSAD: "06",
        ALAND: 1560781749,
        AWATER: 43720910
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.099961, 34.867119999114635],
            [-91.099201, 34.911795999114133],
            [-90.40854, 34.90412099911422],
            [-90.407982753794286, 34.835265978395711],
            [-90.463795, 34.834922999115001],
            [-90.473527, 34.788834999115537],
            [-90.505494, 34.764567999115805],
            [-90.546053, 34.702075999116538],
            [-90.550158, 34.663444999116969],
            [-90.573288412618297, 34.633670505585322],
            [-90.589517, 34.641393999117234],
            [-91.051918, 34.645720999117188],
            [-91.049474, 34.734183999116155],
            [-91.102542, 34.734863999116151],
            [-91.099961, 34.867119999114635]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "021",
        COUNTYNS: "01581070",
        AFFGEOID: "0500000US55021",
        GEOID: "55021",
        NAME: "Columbia",
        LSAD: "06",
        ALAND: 1982754434,
        AWATER: 78836820
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.784901, 43.641050999057761],
            [-89.599547, 43.642635999057745],
            [-89.245437, 43.643082999057739],
            [-89.12717, 43.631723999057755],
            [-89.006848, 43.633044999057759],
            [-89.006117, 43.458758999058041],
            [-89.009139, 43.28482999905836],
            [-89.363085, 43.281304999058364],
            [-89.720463, 43.293083999058354],
            [-89.677613, 43.361196999058215],
            [-89.60073, 43.380665999058181],
            [-89.599357, 43.558040999057859],
            [-89.732238, 43.571825999057857],
            [-89.785809, 43.641048999057745],
            [-89.784901, 43.641050999057761]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "103",
        COUNTYNS: "00485016",
        AFFGEOID: "0500000US20103",
        GEOID: "20103",
        NAME: "Leavenworth",
        LSAD: "06",
        ALAND: 1200335284,
        AWATER: 14221659
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.187103, 39.044108999076755],
            [-95.180125, 39.128888999076189],
            [-95.180891, 39.419217999074299],
            [-94.997852, 39.418857999074312],
            [-94.965747418633498, 39.421681743730694],
            [-94.946662, 39.399716999074428],
            [-94.888972, 39.392431999074475],
            [-94.908065, 39.323662999074912],
            [-94.857072, 39.273824999075238],
            [-94.825663, 39.241728999075448],
            [-94.799663, 39.206017999075677],
            [-94.791994673743304, 39.201260249315901],
            [-94.900191, 39.202910999075698],
            [-94.908765, 38.991400999077115],
            [-94.923349, 39.002632999077029],
            [-95.056258, 38.982119999077177],
            [-95.186189, 38.964541999077291],
            [-95.187103, 39.044108999076755]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "133",
        COUNTYNS: "00295762",
        AFFGEOID: "0500000US12133",
        GEOID: "12133",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1513019369,
        AWATER: 82294771
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.859769, 30.490133999174517],
            [-85.915297, 30.636426999172187],
            [-85.844421, 30.703129999171136],
            [-85.821616, 30.790774999169763],
            [-85.790185, 30.790350999169778],
            [-85.736051, 30.746369999170465],
            [-85.619011, 30.743867999170501],
            [-85.599934, 30.830551999169142],
            [-85.482276, 30.829508999169157],
            [-85.432274, 30.785456999169856],
            [-85.434782, 30.567559999173287],
            [-85.486358, 30.567573999173266],
            [-85.487968, 30.436654999175371],
            [-85.8535, 30.440622999175304],
            [-85.992736, 30.389319999176127],
            [-85.859769, 30.490133999174517]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "047",
        COUNTYNS: "01035620",
        AFFGEOID: "0500000US38047",
        GEOID: "38047",
        NAME: "Logan",
        LSAD: "06",
        ALAND: 2571326104,
        AWATER: 47715596
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.915508, 46.632924999058389],
            [-99.44972, 46.63162999905839],
            [-99.036625, 46.630210999058377],
            [-99.037367, 46.283150999057781],
            [-99.878431, 46.28281099905778],
            [-99.916758, 46.283170999057781],
            [-99.915508, 46.632924999058389]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "201",
        COUNTYNS: "00485061",
        AFFGEOID: "0500000US20201",
        GEOID: "20201",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 2317419238,
        AWATER: 10139803
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.369199035314992, 40.001939304831311],
            [-97.009165, 40.00146299907081],
            [-96.916407005465771, 40.001454090114706],
            [-96.873812, 40.001449999070815],
            [-96.805768, 40.001368454166219],
            [-96.806201, 39.827537999071822],
            [-96.806544, 39.566422999073389],
            [-96.958719, 39.566400999073394],
            [-97.368574, 39.567017999073386],
            [-97.36867, 39.65404299907285],
            [-97.369199035314992, 40.001939304831311]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "123",
        COUNTYNS: "01581121",
        AFFGEOID: "0500000US55123",
        GEOID: "55123",
        NAME: "Vernon",
        LSAD: "06",
        ALAND: 2050135759,
        AWATER: 64289523
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.257000156005688, 43.725658736280025],
            [-90.910653, 43.725333999057625],
            [-90.312194, 43.731478999057629],
            [-90.312404, 43.640987999057749],
            [-90.311069, 43.553990999057874],
            [-90.671652, 43.552855999057883],
            [-90.668561, 43.4229939990581],
            [-91.18346, 43.423016999058106],
            [-91.210663357772702, 43.41944178196141],
            [-91.232276, 43.450951999058056],
            [-91.217706, 43.500549999057974],
            [-91.232812, 43.564841999057855],
            [-91.252926, 43.600362999057822],
            [-91.273252, 43.666622999057722],
            [-91.257000156005688, 43.725658736280025]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "441",
        COUNTYNS: "01384006",
        AFFGEOID: "0500000US48441",
        GEOID: "48441",
        NAME: "Taylor",
        LSAD: "06",
        ALAND: 2371267408,
        AWATER: 9721167
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.146543, 32.522792999144215],
            [-99.656569, 32.520639999144244],
            [-99.629579, 32.514656999144329],
            [-99.631413, 32.08127399915044],
            [-99.713971, 32.082088999150415],
            [-100.151911, 32.082637999150407],
            [-100.146543, 32.522792999144215]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "001",
        COUNTYNS: "00974099",
        AFFGEOID: "0500000US36001",
        GEOID: "36001",
        NAME: "Albany",
        LSAD: "06",
        ALAND: 1354083642,
        AWATER: 27161700
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.263185, 42.423212999060439],
            [-74.241572, 42.550801999060077],
            [-74.169725, 42.667425999059773],
            [-74.180274, 42.729978999059604],
            [-73.832618, 42.772262999059492],
            [-73.809369, 42.778868999059476],
            [-73.726663, 42.822576999059365],
            [-73.676762, 42.783276999059467],
            [-73.761265, 42.610378999059904],
            [-73.773161, 42.509376999060187],
            [-73.783721, 42.46423099906032],
            [-74.254303, 42.408206999060468],
            [-74.263185, 42.423212999060439]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "039",
        COUNTYNS: "01074032",
        AFFGEOID: "0500000US39039",
        GEOID: "39039",
        NAME: "Defiance",
        LSAD: "06",
        ALAND: 1065649891,
        AWATER: 7073996
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.804253257611506, 41.426049710613796],
            [-84.341664, 41.427567999063903],
            [-84.228454, 41.427809999063889],
            [-84.228222, 41.165861999064994],
            [-84.341602, 41.165523999064995],
            [-84.341601, 41.209029999064796],
            [-84.457059, 41.209433999064807],
            [-84.457217, 41.253484999064604],
            [-84.803644982255591, 41.252561682612821],
            [-84.803703572616897, 41.271258329635131],
            [-84.804133, 41.408291999063962],
            [-84.804253257611506, 41.426049710613796]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "065",
        COUNTYNS: "00295733",
        AFFGEOID: "0500000US12065",
        GEOID: "12065",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 1549056458,
        AWATER: 99567329
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.075539, 30.273616999177992],
            [-84.074691, 30.43430199917541],
            [-84.041293, 30.473539999174783],
            [-83.977424, 30.522233999173999],
            [-84.007454, 30.672069967640823],
            [-83.820973, 30.662602999171778],
            [-83.743729, 30.658526912922351],
            [-83.61170405882639, 30.651560092880562],
            [-83.604186, 30.581915999173056],
            [-83.736954, 30.507568999174236],
            [-83.72172, 30.4343539991754],
            [-83.806667, 30.371688999176413],
            [-83.820235, 30.303539999177509],
            [-83.856987, 30.273317999178001],
            [-83.915827, 30.245151999178454],
            [-83.992310340477999, 30.089268736909684],
            [-84.000716, 30.096208999180874],
            [-84.06299, 30.101377999180784],
            [-84.076132071911402, 30.099089882910931],
            [-84.075539, 30.273616999177992]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "163",
        COUNTYNS: "00516928",
        AFFGEOID: "0500000US21163",
        GEOID: "21163",
        NAME: "Meade",
        LSAD: "06",
        ALAND: 791044349,
        AWATER: 49413697
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.471903, 38.046217999084043],
            [-86.434046, 38.086762999083724],
            [-86.433574364088088, 38.087144386663212],
            [-86.387216, 38.124631999083441],
            [-86.356407267971008, 38.135277835849145],
            [-86.321274, 38.147417999083267],
            [-86.278656, 38.098508999083641],
            [-86.206439, 38.021875999084237],
            [-86.095766, 38.00892999908433],
            [-86.033386, 37.970381999084644],
            [-85.997351659754301, 37.991225601383597],
            [-85.975948, 37.875792999085405],
            [-86.007432, 37.818438999085863],
            [-86.151285, 37.798877999086024],
            [-86.242777, 37.877001999085394],
            [-86.488052467128298, 38.043665307983765],
            [-86.471903, 38.046217999084043]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "101",
        COUNTYNS: "00695773",
        AFFGEOID: "0500000US28101",
        GEOID: "28101",
        NAME: "Newton",
        LSAD: "06",
        ALAND: 1496636739,
        AWATER: 4525334
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.322598, 32.576432999143478],
            [-89.317588, 32.576457999143479],
            [-88.914516, 32.576954999143467],
            [-88.913847, 32.224185999148403],
            [-89.322692, 32.223471999148408],
            [-89.322598, 32.576432999143478]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "099",
        COUNTYNS: "00161576",
        AFFGEOID: "0500000US01099",
        GEOID: "01099",
        NAME: "Monroe",
        LSAD: "06",
        ALAND: 2656517454,
        AWATER: 22609680
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.784796, 31.324671999161559],
            [-87.665572, 31.423165999160076],
            [-87.573733, 31.435087999159901],
            [-87.619844, 31.519048999158638],
            [-87.565413, 31.553572999158135],
            [-87.566841, 31.697114999156003],
            [-87.516131, 31.697823999155997],
            [-87.50093, 31.829250999154073],
            [-86.906899, 31.830627999154061],
            [-86.905899, 31.753034999155187],
            [-87.0511, 31.718339999155702],
            [-87.135051, 31.642416999156822],
            [-87.166581, 31.519560999158639],
            [-87.427455, 31.26038599916253],
            [-87.559287, 31.227290999163046],
            [-87.61589, 31.24445799916278],
            [-87.71765, 31.303123999161883],
            [-87.765152, 31.297345999161969],
            [-87.784796, 31.324671999161559]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "121",
        COUNTYNS: "01694833",
        AFFGEOID: "0500000US13121",
        GEOID: "13121",
        NAME: "Fulton",
        LSAD: "06",
        ALAND: 1364081871,
        AWATER: 19876730
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.815869, 33.520248999130914],
            [-84.808934, 33.574084999130235],
            [-84.796536, 33.595591999129951],
            [-84.630117, 33.693115999128715],
            [-84.578132, 33.74350699912808],
            [-84.480134, 33.81731899912716],
            [-84.455493, 33.826807999127041],
            [-84.460129, 33.851803999126737],
            [-84.442708, 33.901542999126114],
            [-84.374743, 33.985907999125075],
            [-84.418927, 34.073297999124001],
            [-84.352302, 34.176716999122746],
            [-84.258743, 34.185908999122631],
            [-84.258934, 34.109538999123558],
            [-84.097693, 34.05070799912427],
            [-84.182023, 33.997596999124923],
            [-84.262276, 33.987404999125047],
            [-84.277093, 33.957613999125428],
            [-84.346955, 33.968142999125284],
            [-84.348315, 33.89090299912624],
            [-84.348298, 33.857485999126666],
            [-84.348091, 33.845292999126812],
            [-84.350224, 33.647907999129295],
            [-84.457726, 33.648865999129285],
            [-84.458053, 33.629240999129529],
            [-84.458556, 33.594435999129978],
            [-84.458665, 33.550932999130531],
            [-84.556857, 33.528404999130814],
            [-84.60954, 33.502510999131147],
            [-84.627734, 33.511037999131034],
            [-84.699178, 33.511372999131041],
            [-84.850713, 33.511456999131035],
            [-84.815869, 33.520248999130914]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "265",
        COUNTYNS: "00347654",
        AFFGEOID: "0500000US13265",
        GEOID: "13265",
        NAME: "Taliaferro",
        LSAD: "06",
        ALAND: 504031039,
        AWATER: 1915743
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.014038, 33.580382999130144],
            [-82.951163, 33.661271999129113],
            [-82.995602, 33.693582999128715],
            [-82.949046, 33.733332999128216],
            [-82.879449, 33.620409999129642],
            [-82.812958, 33.655835999129195],
            [-82.679975, 33.599791999129899],
            [-82.751275, 33.510142999131048],
            [-82.815315, 33.521136999130903],
            [-82.866605, 33.466598999131605],
            [-82.851954, 33.443542999131914],
            [-82.983657, 33.447676999131843],
            [-83.012853, 33.469177999131574],
            [-83.014038, 33.580382999130144]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "051",
        COUNTYNS: "00974124",
        AFFGEOID: "0500000US36051",
        GEOID: "36051",
        NAME: "Livingston",
        LSAD: "06",
        ALAND: 1636256623,
        AWATER: 22006106
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.060617, 42.53281399906011],
            [-77.956334, 42.667321999059759],
            [-77.954964, 42.862753999059272],
            [-77.95553, 42.905860999059165],
            [-77.909832, 42.987761999058975],
            [-77.730957, 42.98837199905897],
            [-77.759052, 42.940461999059082],
            [-77.580377, 42.943962999059075],
            [-77.61167, 42.763168999059516],
            [-77.598815, 42.671964999059746],
            [-77.486875, 42.670278999059754],
            [-77.490889, 42.577287999060005],
            [-77.659917, 42.580408999059991],
            [-77.661315, 42.535066999060106],
            [-77.720617, 42.535065999060109],
            [-77.722964, 42.471215999060306],
            [-77.840231, 42.474575999060292],
            [-77.840694, 42.517770999060161],
            [-78.038261, 42.521521999060141],
            [-78.060617, 42.53281399906011]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "069",
        COUNTYNS: "01622977",
        AFFGEOID: "0500000US26069",
        GEOID: "26069",
        NAME: "Iosco",
        LSAD: "06",
        ALAND: 1422175726,
        AWATER: 3473066854
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.886634, 44.508975999056879],
            [-83.316963875440194, 44.511683010816576],
            [-83.31761, 44.486057999056882],
            [-83.336988, 44.332918999056979],
            [-83.401822, 44.301830999056996],
            [-83.442731, 44.265360999057023],
            [-83.524817, 44.261557999057032],
            [-83.564645792496705, 44.163524817460114],
            [-83.883977, 44.161785999057123],
            [-83.886634, 44.508975999056879]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "053",
        COUNTYNS: "00295751",
        AFFGEOID: "0500000US12053",
        GEOID: "12053",
        NAME: "Hernando",
        LSAD: "06",
        ALAND: 1224692722,
        AWATER: 300863005
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.665055, 28.484433999208498],
            [-82.656694, 28.54481399920741],
            [-82.668149, 28.622410999206021],
            [-82.668711402144794, 28.694303267789952],
            [-82.418353, 28.694858999204737],
            [-82.417684, 28.665896999205252],
            [-82.263052, 28.667633999205229],
            [-82.274823, 28.656197999205428],
            [-82.208184, 28.572053999206926],
            [-82.054469, 28.521366999207835],
            [-82.054694, 28.478396999208602],
            [-82.253293, 28.478490999208599],
            [-82.253439, 28.434823999209385],
            [-82.690795013376089, 28.433341924972108],
            [-82.665055, 28.484433999208498]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "135",
        COUNTYNS: "01581127",
        AFFGEOID: "0500000US55135",
        GEOID: "55135",
        NAME: "Waupaca",
        LSAD: "06",
        ALAND: 1936513136,
        AWATER: 45278485
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.223745, 44.681364999056811],
            [-88.605159, 44.678330999056804],
            [-88.606083, 44.59051499905685],
            [-88.736802, 44.590604999056843],
            [-88.73977, 44.24330299905705],
            [-88.7662, 44.243365999057055],
            [-88.886673, 44.24262199905705],
            [-89.224813, 44.24339299905705],
            [-89.223745, 44.681364999056811]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "049",
        COUNTYNS: "01383810",
        AFFGEOID: "0500000US48049",
        GEOID: "48049",
        NAME: "Brown",
        LSAD: "06",
        ALAND: 2446084213,
        AWATER: 32411546
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.195871, 32.079234999150458],
            [-99.118665, 32.079584999150455],
            [-98.924401, 32.078017999150497],
            [-98.668441, 31.700513999155959],
            [-98.760057, 31.687895999156147],
            [-98.779951, 31.610734999157284],
            [-98.991608, 31.484070999159165],
            [-99.041741, 31.490301999159076],
            [-99.090622, 31.460926999159515],
            [-99.098794, 31.448469999159698],
            [-99.202768, 31.467233999159422],
            [-99.195871, 32.079234999150458]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "143",
        COUNTYNS: "00835893",
        AFFGEOID: "0500000US31143",
        GEOID: "31143",
        NAME: "Polk",
        LSAD: "06",
        ALAND: 1135309852,
        AWATER: 5947394
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.828256, 41.173438999064949],
            [-97.658554, 41.3048619990644],
            [-97.604363, 41.313850999064364],
            [-97.598253, 41.333118999064283],
            [-97.522412, 41.361933999064156],
            [-97.368186, 41.396415999064011],
            [-97.368118, 41.046946999065511],
            [-97.826287, 41.046580999065512],
            [-97.828256, 41.173438999064949]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "013",
        COUNTYNS: "00581292",
        AFFGEOID: "0500000US23013",
        GEOID: "23013",
        NAME: "Knox",
        LSAD: "06",
        ALAND: 945693972,
        AWATER: 2017106965
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-68.935327, 44.130379999057141],
              [-68.888597, 44.159549999057127],
              [-68.934976431761783, 44.202907472994369],
              [-68.896384, 44.209934999057083],
              [-68.828471, 44.209006999057081],
              [-68.720917, 44.115259999057173],
              [-68.548472, 44.12969899905714],
              [-68.531414202351797, 44.089852379313697],
              [-68.584101, 44.071588999057205],
              [-68.617085, 44.010096999057282],
              [-68.657031, 44.003822999057277],
              [-68.669383, 44.076358999057206],
              [-68.77965, 44.057753999057219],
              [-68.874139, 44.025358999057261],
              [-68.905098, 44.077343999057199],
              [-68.935327, 44.130379999057141]
            ]
          ],
          [
            [
              [-68.92401, 43.885406999057416],
              [-68.874784, 43.90471499905739],
              [-68.849009, 43.849840999057463],
              [-68.888483, 43.803780999057523],
              [-68.944433, 43.835325999057474],
              [-68.92401, 43.885406999057416]
            ]
          ],
          [
            [
              [-69.434992, 44.33186599905698],
              [-69.394361, 44.342933999056974],
              [-69.346616, 44.308336999056998],
              [-69.268882, 44.364652999056958],
              [-69.121622, 44.25564399905705],
              [-69.021074545004595, 44.230435313179967],
              [-69.040193, 44.233672999057063],
              [-69.054546, 44.171541999057105],
              [-69.075667, 44.129990999057142],
              [-69.031878, 44.079035999057197],
              [-69.068112, 44.03976799905724],
              [-69.043912, 44.006335999057264],
              [-69.077028, 43.973653999057298],
              [-69.131536, 43.976088999057296],
              [-69.17498, 43.976948999057306],
              [-69.212939, 43.921403999057368],
              [-69.242812, 43.918817999057381],
              [-69.279918, 43.879578999057429],
              [-69.321031, 43.856707999057448],
              [-69.354577, 43.917764999057376],
              [-69.380489719343103, 43.943640232613838],
              [-69.363996, 44.012872999057272],
              [-69.282268, 44.052583999057212],
              [-69.337672, 44.209369999057074],
              [-69.432837, 44.226739999057074],
              [-69.451251, 44.313949999056994],
              [-69.434992, 44.33186599905698]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "167",
        COUNTYNS: "00659528",
        AFFGEOID: "0500000US27167",
        GEOID: "27167",
        NAME: "Wilkin",
        LSAD: "06",
        ALAND: 1945079886,
        AWATER: 482554
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.7857861825985, 46.629590848319289],
            [-96.280977, 46.630764999058385],
            [-96.28, 46.28221999905778],
            [-96.265366, 46.108609999057535],
            [-96.26614, 46.021609999057411],
            [-96.572697494194003, 46.021891670415108],
            [-96.554507, 46.083977999057495],
            [-96.59567, 46.219849999057686],
            [-96.60104, 46.319553999057831],
            [-96.647296, 46.358498999057893],
            [-96.709095, 46.435293999058032],
            [-96.744436, 46.565959999058258],
            [-96.7857861825985, 46.629590848319289]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "023",
        COUNTYNS: "00929106",
        AFFGEOID: "0500000US35023",
        GEOID: "35023",
        NAME: "Hidalgo",
        LSAD: "06",
        ALAND: 8905571558,
        AWATER: 23650187
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.049195, 31.796550999154551],
            [-109.048296, 32.084092999150393],
            [-109.047612003219982, 32.42637538765976],
            [-109.047612, 32.426376999145546],
            [-109.047117, 32.777569999140724],
            [-109.047117062309994, 32.77779400008842],
            [-108.854581, 32.777275999140727],
            [-108.852919, 32.603373999143102],
            [-108.646523, 32.603729999143091],
            [-108.64677, 32.516520999144298],
            [-108.537011, 32.516617999144302],
            [-108.537676, 32.07983199915045],
            [-108.524538, 31.863613999153575],
            [-108.217143, 31.864138999153571],
            [-108.217134, 31.820474999154207],
            [-108.208394, 31.783598999154748],
            [-108.208572, 31.499741999158935],
            [-108.208573, 31.333394999161431],
            [-108.707657, 31.333190999161438],
            [-108.861028, 31.332314999161444],
            [-109.050044, 31.332501999161444],
            [-109.049195, 31.796550999154551]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "063",
        COUNTYNS: "01155135",
        AFFGEOID: "0500000US41063",
        GEOID: "41063",
        NAME: "Wallowa",
        LSAD: "06",
        ALAND: 8147836572,
        AWATER: 14191789
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.977657306531995, 46.000168201439415],
            [-117.717852, 45.999865999057398],
            [-117.603425834504009, 45.998760172500091],
            [-117.47993726365398, 45.997566765899592],
            [-117.353928, 45.996348999057382],
            [-116.915989, 45.995412999057393],
            [-116.886843, 45.958616999057341],
            [-116.859795, 45.907263999057278],
            [-116.799204532500994, 45.851045434540126],
            [-116.78752, 45.840203999057216],
            [-116.736268, 45.826178999057191],
            [-116.665344, 45.781997999057154],
            [-116.593004, 45.778540999057164],
            [-116.535698, 45.734230999057104],
            [-116.528272, 45.681472999057071],
            [-116.463504, 45.61578499905702],
            [-116.502756, 45.566607999056977],
            [-116.588195, 45.442919999056905],
            [-116.674648, 45.314341999056836],
            [-116.690916254857996, 45.26898413702672],
            [-116.696047, 45.2546789990568],
            [-116.754643, 45.11397199905678],
            [-116.783128263266988, 45.077712504066781],
            [-117.266573, 45.080569999056763],
            [-117.266006, 45.16653899905679],
            [-117.476684, 45.16653899905679],
            [-117.520941, 45.209767999056801],
            [-117.522249, 45.267730999056816],
            [-117.582344, 45.338095999056847],
            [-117.663391, 45.365748999056869],
            [-117.727235, 45.514779999056941],
            [-117.727225, 45.616989999057012],
            [-117.787623, 45.689566999057078],
            [-117.788395, 45.773514999057156],
            [-117.74765, 45.773624999057148],
            [-117.747327, 45.861071999057245],
            [-117.97298, 45.860594999057227],
            [-117.977613, 45.883430999057268],
            [-117.977657306531995, 46.000168201439415]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "079",
        COUNTYNS: "00066873",
        AFFGEOID: "0500000US05079",
        GEOID: "05079",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 1454438336,
        AWATER: 27514921
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.976439, 33.977296999125187],
            [-91.953799, 34.064140999124106],
            [-91.953629, 34.093379999123762],
            [-91.74499, 34.09402099912375],
            [-91.726625, 34.065113999124101],
            [-91.712255, 34.173457999122775],
            [-91.602334, 34.088055999123817],
            [-91.445614, 34.080146999123912],
            [-91.436548, 34.030185999124527],
            [-91.421715, 34.014471999124723],
            [-91.548668, 33.97679899912518],
            [-91.557648, 33.783383999127587],
            [-91.663738, 33.792245999127466],
            [-91.975974, 33.791786999127488],
            [-91.976439, 33.977296999125187]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "139",
        COUNTYNS: "01784885",
        AFFGEOID: "0500000US17139",
        GEOID: "17139",
        NAME: "Moultrie",
        LSAD: "06",
        ALAND: 870088233,
        AWATER: 22103176
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.812709, 39.740485999072334],
            [-88.745671, 39.792145999072034],
            [-88.473182, 39.791836999072032],
            [-88.472073, 39.651587999072866],
            [-88.470505, 39.447040999074133],
            [-88.584273, 39.447581999074124],
            [-88.641468, 39.520957999073666],
            [-88.716875, 39.521208999073657],
            [-88.717539, 39.579298999073302],
            [-88.809051, 39.580240999073311],
            [-88.810575, 39.653221999072855],
            [-88.812709, 39.740485999072334]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "107",
        COUNTYNS: "00695776",
        AFFGEOID: "0500000US28107",
        GEOID: "28107",
        NAME: "Panola",
        LSAD: "06",
        ALAND: 1774512450,
        AWATER: 51769784
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.198631, 34.554424999118254],
            [-89.721341, 34.554273999118251],
            [-89.72105, 34.192544999122546],
            [-89.721431, 34.163512999122908],
            [-89.931485, 34.164302999122889],
            [-89.958579, 34.159553999122942],
            [-90.135456, 34.160073999122943],
            [-90.134441, 34.265194999121675],
            [-90.194086, 34.301406999121234],
            [-90.198536, 34.511087999118757],
            [-90.198631, 34.554424999118254]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "053",
        COUNTYNS: "01622969",
        AFFGEOID: "0500000US26053",
        GEOID: "26053",
        NAME: "Gogebic",
        LSAD: "06",
        ALAND: 2854517985,
        AWATER: 970153168
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.418136, 46.566093999058253],
            [-90.327626, 46.607743999058336],
            [-90.237609, 46.624484999058382],
            [-90.04542, 46.668271999058462],
            [-89.918466, 46.740323999058596],
            [-89.884329580411489, 46.765471148335656],
            [-89.863793, 46.765404999058653],
            [-89.86442, 46.592642999058306],
            [-89.740001, 46.592493999058298],
            [-89.740568, 46.505641999058142],
            [-89.365094, 46.505948999058155],
            [-89.365507, 46.333077999057856],
            [-88.991012, 46.332307999057853],
            [-88.991217028396008, 46.096536353493512],
            [-89.09163, 46.138504999057581],
            [-89.638416, 46.243803999057725],
            [-89.929125625664796, 46.299915727465901],
            [-90.120489, 46.33685199905787],
            [-90.158241, 46.420484999057997],
            [-90.214866, 46.499946999058132],
            [-90.285707, 46.518845999058158],
            [-90.331887, 46.553277999058238],
            [-90.387228, 46.533662999058194],
            [-90.418136, 46.566093999058253]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "33",
        COUNTYFP: "011",
        COUNTYNS: "00873179",
        AFFGEOID: "0500000US33011",
        GEOID: "33011",
        NAME: "Hillsborough",
        LSAD: "06",
        ALAND: 2269883907,
        AWATER: 41627731
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.057949, 43.018921999058911],
            [-72.03838, 43.10752999905872],
            [-72.038149, 43.128806999058661],
            [-72.035544, 43.180282999058562],
            [-72.009074, 43.187881999058547],
            [-71.913215, 43.207098999058523],
            [-71.8561, 43.115134999058697],
            [-71.810277, 43.12427099905868],
            [-71.73256, 43.139785999058653],
            [-71.667771, 43.152533999058619],
            [-71.641291, 43.054167999058834],
            [-71.517467, 43.068908999058799],
            [-71.512767, 43.049614999058825],
            [-71.392388, 43.009308999058923],
            [-71.377186, 42.94714499905907],
            [-71.451154, 42.930899999059108],
            [-71.442301, 42.890398999059201],
            [-71.427067, 42.824839999059364],
            [-71.360679, 42.798961999059422],
            [-71.364418, 42.785236999059464],
            [-71.245384032920697, 42.736555327634186],
            [-71.255110121810603, 42.736397052346774],
            [-71.255605, 42.736388999059578],
            [-71.294205, 42.696989999059696],
            [-71.351874, 42.698153999059677],
            [-71.636214, 42.70488799905965],
            [-71.745817, 42.707286999059662],
            [-71.805389602738288, 42.708914942588144],
            [-71.898768771936588, 42.711466719868447],
            [-71.929030059053503, 42.712293671592249],
            [-71.974572, 42.789432999059443],
            [-72.001215, 42.919442999059136],
            [-72.062222, 42.944795999059075],
            [-72.057949, 43.018921999058911]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "007",
        COUNTYNS: "00040471",
        AFFGEOID: "0500000US04007",
        GEOID: "04007",
        NAME: "Gila",
        LSAD: "06",
        ALAND: 12323146398,
        AWATER: 97119865
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.674412, 34.306162999121184],
            [-111.659644, 34.386250999120243],
            [-111.554216, 34.4394369991196],
            [-111.554681, 34.471732999119205],
            [-111.501724, 34.497936999118913],
            [-111.472046, 34.462264999119327],
            [-111.512419, 34.415659999119882],
            [-111.435634, 34.446102999119525],
            [-111.426854, 34.385992999120234],
            [-111.397229, 34.42606199911976],
            [-111.324303, 34.42259399911979],
            [-111.250302, 34.453273999119432],
            [-111.147094, 34.375184999120364],
            [-111.096661, 34.396430999120113],
            [-110.958863, 34.314468999121083],
            [-110.848059, 34.267204999121645],
            [-110.783111, 34.299860999121258],
            [-110.750678, 34.263313999121699],
            [-110.750672, 34.000047999124895],
            [-110.00066, 34.0000459991249],
            [-110.000649, 33.576929999130193],
            [-110.000646, 33.465915999131624],
            [-110.168992, 33.451697999131795],
            [-110.340949, 33.427065999132111],
            [-110.441544, 33.381669999132697],
            [-110.457194, 33.295267999133827],
            [-110.400676, 33.198813999135091],
            [-110.449021, 33.194525999135145],
            [-110.593459, 33.13392599913594],
            [-110.632529, 33.14277099913582],
            [-110.725583, 33.069895999136804],
            [-110.781076, 32.983737999137936],
            [-110.955497, 33.279138999134041],
            [-110.974706, 33.342094999133217],
            [-111.039976, 33.466018999131606],
            [-111.153354, 33.677605999128915],
            [-111.222055, 33.602445999129877],
            [-111.28649, 33.657374999129175],
            [-111.38235, 33.758246999127905],
            [-111.372317, 33.823923999127075],
            [-111.450904, 33.919876999125883],
            [-111.445079, 34.001175999124889],
            [-111.494778, 33.999816999124896],
            [-111.477853, 34.151107999123056],
            [-111.72057, 34.161428999122933],
            [-111.674412, 34.306162999121184]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "049",
        COUNTYNS: "00516871",
        AFFGEOID: "0500000US21049",
        GEOID: "21049",
        NAME: "Clark",
        LSAD: "06",
        ALAND: 653878979,
        AWATER: 6972749
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.348874, 37.898542999085208],
            [-84.282629, 38.019102999084268],
            [-84.286461, 38.067027999083876],
            [-84.080527, 38.115117999083502],
            [-83.96631, 37.930767999084964],
            [-84.02737, 37.869453999085451],
            [-84.001967, 37.83759199908571],
            [-84.075107, 37.823271999085826],
            [-84.080337, 37.85306599908558],
            [-84.261183, 37.875985999085394],
            [-84.272406, 37.917545999085071],
            [-84.337393, 37.892006999085268],
            [-84.348874, 37.898542999085208]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "045",
        COUNTYNS: "01008547",
        AFFGEOID: "0500000US37045",
        GEOID: "37045",
        NAME: "Cleveland",
        LSAD: "06",
        ALAND: 1202395052,
        AWATER: 10205340
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.702853, 35.357303999109206],
            [-81.691989, 35.580040999106821],
            [-81.56725, 35.558200999107051],
            [-81.537599, 35.564227999106983],
            [-81.515009, 35.55778199910705],
            [-81.4556, 35.41981099910852],
            [-81.356682, 35.330603999109485],
            [-81.321729, 35.26044899911026],
            [-81.328090989291809, 35.162285914668729],
            [-81.367604910676405, 35.164092160315811],
            [-81.494265, 35.169881999111254],
            [-81.768134407773886, 35.179707705495041],
            [-81.702853, 35.357303999109206]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "457",
        COUNTYNS: "01384014",
        AFFGEOID: "0500000US48457",
        GEOID: "48457",
        NAME: "Tyler",
        LSAD: "06",
        ALAND: 2394430926,
        AWATER: 28821288
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.561943, 31.058951999165618],
            [-94.477026, 31.027740999166085],
            [-94.457816, 31.033324999165995],
            [-94.45327, 31.006236999166426],
            [-94.353782, 31.039370999165914],
            [-94.238424, 31.013704999166304],
            [-94.244888, 30.968173999167011],
            [-94.200441, 30.929255999167605],
            [-94.215213, 30.900399999168059],
            [-94.179343, 30.794973999169699],
            [-94.150119, 30.790202999169772],
            [-94.125824, 30.684051999171444],
            [-94.089369, 30.686977999171397],
            [-94.05096, 30.638567999172167],
            [-94.094812, 30.575542999173159],
            [-94.071689, 30.526032999173943],
            [-94.545717, 30.526976999173929],
            [-94.657992, 31.012006999166328],
            [-94.561943, 31.058951999165618]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "049",
        COUNTYNS: "01135860",
        AFFGEOID: "0500000US41049",
        GEOID: "41049",
        NAME: "Morrow",
        LSAD: "06",
        ALAND: 5259049905,
        AWATER: 44372295
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.99950515346201, 45.811684922139086],
            [-119.965744, 45.824364999057202],
            [-119.868153889391024, 45.838226601692924],
            [-119.802655, 45.847529999057222],
            [-119.669877, 45.856866999057232],
            [-119.600549, 45.91958099905731],
            [-119.571584, 45.925455999057313],
            [-119.487829, 45.906306999057286],
            [-119.432138558816007, 45.913209143291191],
            [-119.43464, 45.603055999057005],
            [-119.248547, 45.601934999057008],
            [-119.248214, 45.516075999056945],
            [-119.145144, 45.515908999056954],
            [-119.146424, 45.082939999056777],
            [-119.162966, 44.996240999056766],
            [-119.643745, 44.99459599905677],
            [-119.672228, 44.995116999056769],
            [-119.791162, 44.994754999056759],
            [-119.790699, 45.067822999056773],
            [-119.759759, 45.169210999056787],
            [-119.883914, 45.169151999056787],
            [-119.882346, 45.256915999056815],
            [-120.006555, 45.25770599905681],
            [-119.99950515346201, 45.811684922139086]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "107",
        COUNTYNS: "01265761",
        AFFGEOID: "0500000US46107",
        GEOID: "46107",
        NAME: "Potter",
        LSAD: "06",
        ALAND: 2230356770,
        AWATER: 97171992
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.391818, 45.026005999056757],
            [-100.27965, 45.019557999056765],
            [-100.325451, 45.099336999056774],
            [-100.260223, 45.246809999056822],
            [-99.708987, 45.245865999056811],
            [-99.573445, 45.245363999056799],
            [-99.572699, 44.897262999056771],
            [-99.67305, 44.897491999056761],
            [-100.404753, 44.89769499905676],
            [-100.391818, 45.026005999056757]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "013",
        COUNTYNS: "00424208",
        AFFGEOID: "0500000US17013",
        GEOID: "17013",
        NAME: "Calhoun",
        LSAD: "06",
        ALAND: 657402915,
        AWATER: 77057164
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.935349946368603, 39.399519527981532],
            [-90.613694, 39.395774999074462],
            [-90.59123, 39.198623999075714],
            [-90.61454, 39.142289999076091],
            [-90.603569, 39.117591999076261],
            [-90.534657, 38.958498999077335],
            [-90.467766, 38.96920799907727],
            [-90.450969940891895, 38.961395002401822],
            [-90.467784, 38.961808999077299],
            [-90.500117, 38.91040799907767],
            [-90.555693, 38.870784999077948],
            [-90.595354, 38.875049999077916],
            [-90.657254, 38.920269999077597],
            [-90.661582906876788, 38.934703306830691],
            [-90.676397, 38.984095999077162],
            [-90.713629, 39.053976999076689],
            [-90.681086, 39.100589999076369],
            [-90.707902, 39.150859999076047],
            [-90.723283623412698, 39.224102969133149],
            [-90.72996, 39.25589399907534],
            [-90.840106, 39.340437999074801],
            [-90.935349946368603, 39.399519527981532]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "139",
        COUNTYNS: "00659514",
        AFFGEOID: "0500000US27139",
        GEOID: "27139",
        NAME: "Scott",
        LSAD: "06",
        ALAND: 922693644,
        AWATER: 31374929
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.902479, 44.587646999056844],
            [-93.768039, 44.640302999056814],
            [-93.618141, 44.707095999056804],
            [-93.594234, 44.776214999056783],
            [-93.578663, 44.794372999056776],
            [-93.520431, 44.804262999056782],
            [-93.438089, 44.813006999056775],
            [-93.329615, 44.791085999056769],
            [-93.318157, 44.717535999056793],
            [-93.318731, 44.630577999056818],
            [-93.278169, 44.630750999056822],
            [-93.281521, 44.54395699905686],
            [-93.357704, 44.544005999056843],
            [-93.524327, 44.543617999056849],
            [-93.910405, 44.543162999056861],
            [-93.902479, 44.587646999056844]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "131",
        COUNTYNS: "00835887",
        AFFGEOID: "0500000US31131",
        GEOID: "31131",
        NAME: "Otoe",
        LSAD: "06",
        ALAND: 1594610575,
        AWATER: 8640423
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.463764, 40.783959999066738],
            [-95.834243816714405, 40.783784386109836],
            [-95.834156, 40.783015999066734],
            [-95.888697, 40.736291999066957],
            [-95.846034, 40.682604999067209],
            [-95.781909, 40.653271999067364],
            [-95.748626, 40.603354999067612],
            [-95.765645, 40.585207999067698],
            [-95.75711, 40.525989999068003],
            [-95.714291, 40.52720799906799],
            [-95.712280371801072, 40.523754425275413],
            [-96.067012, 40.523147999068016],
            [-96.463632, 40.523012999068023],
            [-96.463764, 40.783959999066738]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "051",
        COUNTYNS: "01209179",
        AFFGEOID: "0500000US42051",
        GEOID: "42051",
        NAME: "Fayette",
        LSAD: "06",
        ALAND: 2046968026,
        AWATER: 20644021
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.998014, 39.983321999070924],
            [-79.877048, 40.036825999070608],
            [-79.838999, 40.090600999070318],
            [-79.877385, 40.126791999070115],
            [-79.744404, 40.128167999070094],
            [-79.641098, 40.088316999070329],
            [-79.480367, 40.143123999070021],
            [-79.293682, 40.040412999070604],
            [-79.363739, 39.947777999071121],
            [-79.417558, 39.853775999071658],
            [-79.356757, 39.781476999072083],
            [-79.409959, 39.734977999072356],
            [-79.392458405022396, 39.721439357712548],
            [-79.476662, 39.721077999072449],
            [-79.763774, 39.720775999072458],
            [-79.916017356037798, 39.721055932591248],
            [-79.923325, 39.921546999071268],
            [-79.998014, 39.983321999070924]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "073",
        COUNTYNS: "00198152",
        AFFGEOID: "0500000US08073",
        GEOID: "08073",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 6676158689,
        AWATER: 22780366
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.055528, 38.868867999077949],
            [-103.719664, 38.866826999077979],
            [-103.714355, 39.566330999073394],
            [-103.707072, 39.566294999073385],
            [-103.154376, 39.565653999073383],
            [-103.155253, 39.1279839990762],
            [-103.163025, 39.0376099990768],
            [-103.164159, 38.690132999079218],
            [-103.172943, 38.612449999079757],
            [-103.172878, 38.5253149990804],
            [-103.504666, 38.516408999080468],
            [-103.61299, 38.52254799908043],
            [-104.053921, 38.522392999080431],
            [-104.055528, 38.868867999077949]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "077",
        COUNTYNS: "00695762",
        AFFGEOID: "0500000US28077",
        GEOID: "28077",
        NAME: "Lawrence",
        LSAD: "06",
        ALAND: 1115424613,
        AWATER: 13213419
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.245191, 31.717523999155706],
            [-90.195422, 31.727914999155566],
            [-90.122565, 31.752679999155188],
            [-89.974586, 31.761654999155066],
            [-89.976052, 31.522476999158599],
            [-89.95894, 31.390488999160574],
            [-90.040485, 31.394672999160509],
            [-90.040466, 31.336013999161395],
            [-90.108651, 31.350868999161165],
            [-90.24389, 31.350273999161178],
            [-90.245191, 31.717523999155706]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "163",
        COUNTYNS: "01026131",
        AFFGEOID: "0500000US37163",
        GEOID: "37163",
        NAME: "Sampson",
        LSAD: "06",
        ALAND: 2449357429,
        AWATER: 4923826
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.672292, 35.085280999112193],
            [-78.639478, 35.115773999111859],
            [-78.617127, 35.245577999110417],
            [-78.583451, 35.296778999109868],
            [-78.538349, 35.316409999109638],
            [-78.492042, 35.263420999110224],
            [-78.411734, 35.254662999110316],
            [-78.30658, 35.287603999109962],
            [-78.246217, 35.225995999110623],
            [-78.173532, 35.207347999110837],
            [-78.16342, 35.18971599911103],
            [-78.147129, 34.902505999114226],
            [-78.198642, 34.741636999116075],
            [-78.114007, 34.721794999116298],
            [-78.113995, 34.706875999116484],
            [-78.254441, 34.55359499911826],
            [-78.374363, 34.700721999116553],
            [-78.391978, 34.741264999116076],
            [-78.390748, 34.749462999115984],
            [-78.494705, 34.85618199911476],
            [-78.647033, 34.992253999113217],
            [-78.672292, 35.085280999112193]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "019",
        COUNTYNS: "01639730",
        AFFGEOID: "0500000US47019",
        GEOID: "47019",
        NAME: "Carter",
        LSAD: "06",
        ALAND: 883865752,
        AWATER: 16654827
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.299968, 36.37113999909878],
            [-82.299859, 36.396504999098539],
            [-82.188653, 36.400407999098491],
            [-81.983843, 36.50831399909746],
            [-81.94984, 36.472384999097798],
            [-82.058545, 36.367214999098813],
            [-81.934369244492586, 36.264716203479637],
            [-81.960101, 36.228130999100181],
            [-82.02874, 36.12432199910122],
            [-82.080143, 36.105719999101396],
            [-82.081148917132396, 36.105692113433996],
            [-82.127146, 36.104416999101417],
            [-82.140847, 36.13621599910109],
            [-82.211251, 36.159011999100862],
            [-82.220253010286598, 36.153820038944019],
            [-82.284691, 36.251685999099955],
            [-82.34046, 36.252806999099938],
            [-82.299968, 36.37113999909878]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "230",
        COUNTYNS: "02339479",
        AFFGEOID: "0500000US02230",
        GEOID: "02230",
        NAME: "Skagway",
        LSAD: "12",
        ALAND: 1124107036,
        AWATER: 28607173
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-135.477436, 59.799625999180527],
            [-135.231148, 59.697175999178874],
            [-135.214344, 59.664342999178345],
            [-135.114588, 59.623414999177669],
            [-135.027456, 59.563691999176712],
            [-135.026328, 59.474657999175292],
            [-135.067356, 59.421854999174457],
            [-135.010033, 59.381287999173807],
            [-135.029120466615012, 59.345596860786841],
            [-135.37502, 59.350032999173308],
            [-135.717316842186989, 59.730076945753801],
            [-135.477436, 59.799625999180527]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "031",
        COUNTYNS: "01074028",
        AFFGEOID: "0500000US39031",
        GEOID: "39031",
        NAME: "Coshocton",
        LSAD: "06",
        ALAND: 1460530916,
        AWATER: 9243155
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.184607, 40.456279999068357],
            [-81.709132, 40.444774999068414],
            [-81.712288, 40.370803999068798],
            [-81.61603, 40.368117999068815],
            [-81.622683, 40.221307999069602],
            [-81.670899, 40.150975999069985],
            [-81.716276, 40.152165999069979],
            [-82.187105, 40.166879999069891],
            [-82.183492, 40.22725999906956],
            [-82.195911, 40.239070999069504],
            [-82.184607, 40.456279999068357]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "25",
        COUNTYFP: "019",
        COUNTYNS: "00606936",
        AFFGEOID: "0500000US25019",
        GEOID: "25019",
        NAME: "Nantucket",
        LSAD: "06",
        ALAND: 119431680,
        AWATER: 667032050
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.275526, 41.310463999064375],
            [-70.193712, 41.313786999064355],
            [-70.079133, 41.319503999064331],
            [-70.049053, 41.39170199906404],
            [-69.984869, 41.358817999064165],
            [-69.960181, 41.264545999064559],
            [-70.015225, 41.237963999064682],
            [-70.096967, 41.240849999064658],
            [-70.211479, 41.24876499906464],
            [-70.275526, 41.310463999064375]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "033",
        COUNTYNS: "00399755",
        AFFGEOID: "0500000US16033",
        GEOID: "16033",
        NAME: "Clark",
        LSAD: "06",
        ALAND: 4566524491,
        AWATER: 2476454
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.995091, 44.234125999057049],
            [-112.813792, 44.230280999057058],
            [-112.826826881265006, 44.405202430824438],
            [-112.821896, 44.407435999056929],
            [-112.828191, 44.442471999056899],
            [-112.735084, 44.499158999056874],
            [-112.707815, 44.503022999056874],
            [-112.601863, 44.49101499905688],
            [-112.473207, 44.480026999056889],
            [-112.387389, 44.448057999056914],
            [-112.358917, 44.528846999056867],
            [-112.286187, 44.568471999056847],
            [-112.221698, 44.54351899905685],
            [-112.125101, 44.528526999056858],
            [-112.034133, 44.537715999056857],
            [-111.870504, 44.564032999056856],
            [-111.807914, 44.511715999056861],
            [-111.704218, 44.560204999056836],
            [-111.617107034305008, 44.557127247475456],
            [-111.617437, 44.401253999056934],
            [-111.791624, 44.401609999056944],
            [-111.791541, 44.317020999056993],
            [-111.852276, 44.316995999056999],
            [-111.857851, 44.187758999057102],
            [-111.913601, 44.144336999057138],
            [-112.033664, 44.144048999057127],
            [-112.034317, 44.057357999057224],
            [-112.155935, 44.057779999057232],
            [-112.694744, 44.058463999057231],
            [-112.695242, 43.971940999057324],
            [-112.994323, 43.972361999057327],
            [-112.995091, 44.234125999057049]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "003",
        COUNTYNS: "00929108",
        AFFGEOID: "0500000US35003",
        GEOID: "35003",
        NAME: "Catron",
        LSAD: "06",
        ALAND: 17933560026,
        AWATER: 14194439
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.047298, 33.409782999132332],
            [-109.046607473921995, 33.778223799478162],
            [-109.046426, 33.875051999126448],
            [-109.046182, 34.522392999118622],
            [-109.04613893536299, 34.579291011935851],
            [-107.724794, 34.578131999117971],
            [-107.725427, 34.274223999121567],
            [-107.716301, 34.25985599912174],
            [-107.712754, 33.476831999131477],
            [-108.000602, 33.478013999131463],
            [-108.000598, 33.201252999135058],
            [-108.449988, 33.20099699913505],
            [-108.543178, 33.207954999134969],
            [-109.047236980537008, 33.20889503068576],
            [-109.047237, 33.208964999134956],
            [-109.047298, 33.409782999132332]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "015",
        COUNTYNS: "00450406",
        AFFGEOID: "0500000US18015",
        GEOID: "18015",
        NAME: "Carroll",
        LSAD: "06",
        ALAND: 964054634,
        AWATER: 7239098
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.750011, 40.736026999066958],
            [-86.581739, 40.735764999066959],
            [-86.525164, 40.692418999067179],
            [-86.373521, 40.692459999067168],
            [-86.374417, 40.561360999067823],
            [-86.375762, 40.43185099906848],
            [-86.694665, 40.432155999068478],
            [-86.695353, 40.561873999067807],
            [-86.771293, 40.562081999067814],
            [-86.750011, 40.736026999066958]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "133",
        COUNTYNS: "01480152",
        AFFGEOID: "0500000US51133",
        GEOID: "51133",
        NAME: "Northumberland",
        LSAD: "06",
        ALAND: 495728041,
        AWATER: 243986806
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.617781, 38.000404999084409],
            [-76.510693961317699, 38.039488926898294],
            [-76.491998, 38.017221999084271],
            [-76.427487, 37.977037999084601],
            [-76.316952, 37.934928999084924],
            [-76.236725, 37.889173999085294],
            [-76.251358, 37.83307199908576],
            [-76.310307, 37.794848999086057],
            [-76.312858, 37.720337999086674],
            [-76.325299149690608, 37.682573884523677],
            [-76.419511, 37.823436999085821],
            [-76.507828, 37.838813999085694],
            [-76.556042, 37.931523999084938],
            [-76.63423, 37.966629999084674],
            [-76.617781, 38.000404999084409]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "063",
        COUNTYNS: "01622974",
        AFFGEOID: "0500000US26063",
        GEOID: "26063",
        NAME: "Huron",
        LSAD: "06",
        ALAND: 2165446362,
        AWATER: 3368847813
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.479567, 43.793624999057528],
            [-83.43261, 43.88527299905742],
            [-83.407146, 43.919806999057379],
            [-83.28231, 43.938030999057361],
            [-83.26153, 43.973524999057311],
            [-83.134881, 43.993146999057288],
            [-83.046577, 44.015709999057258],
            [-83.024604, 44.045173999057226],
            [-82.928884, 44.06938899905721],
            [-82.793205, 44.023246999057264],
            [-82.709839, 43.94822599905735],
            [-82.633641, 43.831223999057478],
            [-82.612224, 43.73977099905759],
            [-82.606479082838078, 43.690449269767171],
            [-83.117844, 43.675492999057695],
            [-83.466592, 43.667620999057704],
            [-83.5060855525428, 43.745157212197498],
            [-83.479567, 43.793624999057528]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "189",
        COUNTYNS: "01383880",
        AFFGEOID: "0500000US48189",
        GEOID: "48189",
        NAME: "Hale",
        LSAD: "06",
        ALAND: 2602109431,
        AWATER: 246678
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.090415, 34.31313199912109],
            [-101.99802, 34.313038999121098],
            [-101.564856, 34.312492999121098],
            [-101.564563, 34.074227999123991],
            [-101.56358, 33.830450999126995],
            [-102.085733, 33.824674999127076],
            [-102.090415, 34.31313199912109]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "089",
        COUNTYNS: "01639759",
        AFFGEOID: "0500000US47089",
        GEOID: "47089",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 711873311,
        AWATER: 102176879
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.70925, 36.093233999101521],
            [-83.66746, 36.080586999101655],
            [-83.674237, 36.125007999101207],
            [-83.541099, 36.181986999100637],
            [-83.467097, 36.174687999100712],
            [-83.426, 36.154549999100908],
            [-83.274501, 36.130793999101158],
            [-83.234585, 36.085093999101602],
            [-83.259059, 35.999842999102462],
            [-83.276677, 36.007651999102393],
            [-83.296531, 35.904519999103435],
            [-83.310782, 35.895845999103528],
            [-83.489344, 35.927659999103199],
            [-83.673297, 36.038485999102072],
            [-83.70925, 36.093233999101521]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "149",
        COUNTYNS: "00347067",
        AFFGEOID: "0500000US13149",
        GEOID: "13149",
        NAME: "Heard",
        LSAD: "06",
        ALAND: 766640653,
        AWATER: 13190449
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.294346864345286, 33.4279931454967],
            [-85.015358, 33.425505999132127],
            [-84.958646, 33.277052999134057],
            [-84.939015, 33.224692999134746],
            [-85.088036, 33.171700999135439],
            [-85.10798, 33.195097999135143],
            [-85.117402, 33.163653999135555],
            [-85.236595371252776, 33.129544027386707],
            [-85.294346864345286, 33.4279931454967]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "447",
        COUNTYNS: "01384009",
        AFFGEOID: "0500000US48447",
        GEOID: "48447",
        NAME: "Throckmorton",
        LSAD: "06",
        ALAND: 2363499442,
        AWATER: 7586186
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.472444, 33.399022999132484],
            [-98.953938, 33.397533999132499],
            [-98.950875, 32.956917999138305],
            [-99.096016, 32.957036999138296],
            [-99.471258, 32.957024999138298],
            [-99.472444, 33.399022999132484]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "077",
        COUNTYNS: "00161564",
        AFFGEOID: "0500000US01077",
        GEOID: "01077",
        NAME: "Lauderdale",
        LSAD: "06",
        ALAND: 1730742958,
        AWATER: 133012067
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.202959, 35.008027999113047],
            [-88.000032, 35.005938999113063],
            [-87.984916043698902, 35.005910123438888],
            [-87.851886, 35.005655999113081],
            [-87.625025, 35.0037319991131],
            [-87.60609786765329, 35.003519525317401],
            [-87.224053641875699, 34.999230741085249],
            [-87.216683, 34.999147999113148],
            [-87.210758579982297, 34.999049180112848],
            [-87.214914, 34.816010999115221],
            [-87.278302, 34.773562999115704],
            [-87.260676, 34.758625999115885],
            [-87.297905, 34.75081199911596],
            [-87.344224, 34.796492999115443],
            [-87.42651, 34.800021999115408],
            [-87.524363, 34.832383999115038],
            [-87.806963, 34.732060999116186],
            [-87.92029, 34.804467999115346],
            [-87.973222, 34.882549999114467],
            [-88.097888, 34.892201999114356],
            [-88.154617, 34.922391999114012],
            [-88.200064, 34.995633999113196],
            [-88.202959, 35.008027999113047]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "073",
        COUNTYNS: "01213676",
        AFFGEOID: "0500000US42073",
        GEOID: "42073",
        NAME: "Lawrence",
        LSAD: "06",
        ALAND: 927644463,
        AWATER: 11783868
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.519891, 40.906660999066148],
            [-80.519643772547283, 40.987392653071595],
            [-80.519222086080902, 41.125093565860972],
            [-80.258201, 41.127963999065152],
            [-80.147164, 41.113794999065227],
            [-80.096861, 41.0698039990654],
            [-80.16529, 41.000469999065722],
            [-80.162547, 40.937750999065997],
            [-80.158534, 40.855156999066395],
            [-80.519705112599198, 40.851336777751804],
            [-80.519869707686183, 40.900323933315086],
            [-80.519876846740402, 40.902448674485875],
            [-80.519891, 40.906660999066148]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "011",
        COUNTYNS: "00933054",
        AFFGEOID: "0500000US35011",
        GEOID: "35011",
        NAME: "De Baca",
        LSAD: "06",
        ALAND: 6016761648,
        AWATER: 29147345
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.891745, 34.347042999120696],
            [-104.892023, 34.604425999117652],
            [-104.444762, 34.604951999117652],
            [-104.444935, 34.691654999116643],
            [-104.339725, 34.692233999116652],
            [-104.339817, 34.77906199911564],
            [-104.129143, 34.779336999115642],
            [-104.12888, 34.605200999117656],
            [-103.948778, 34.605057999117662],
            [-103.946019, 34.082462999123884],
            [-104.1556, 34.083204999123872],
            [-104.156269, 33.995412999124952],
            [-104.786104, 34.000136999124891],
            [-104.786429, 34.087663999123819],
            [-104.893377, 34.088406999123812],
            [-104.891745, 34.347042999120696]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "097",
        COUNTYNS: "00465237",
        AFFGEOID: "0500000US19097",
        GEOID: "19097",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 1647332487,
        AWATER: 35141496
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.89802, 42.295235999060822],
            [-90.665551, 42.294698999060834],
            [-90.665029, 42.382154999060553],
            [-90.484345672716287, 42.381597777252459],
            [-90.44632, 42.357040999060636],
            [-90.417125, 42.319942999060736],
            [-90.430884, 42.278229999060869],
            [-90.400653, 42.239292999060972],
            [-90.338169, 42.203320999061091],
            [-90.315696514271508, 42.193946431312526],
            [-90.26908, 42.174499999061197],
            [-90.207421, 42.149108999061276],
            [-90.161159, 42.106371999061416],
            [-90.163446, 42.040406999061638],
            [-90.159682451122677, 42.033087256647562],
            [-90.898373, 42.033547999061646],
            [-90.89802, 42.295235999060822]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "125",
        COUNTYNS: "01500936",
        AFFGEOID: "0500000US51125",
        GEOID: "51125",
        NAME: "Nelson",
        LSAD: "06",
        ALAND: 1219239663,
        AWATER: 8987274
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.144388, 37.856095999085561],
            [-79.15739, 37.891002999085266],
            [-79.062454, 37.91759999908507],
            [-79.005129, 37.881689999085353],
            [-78.902282, 37.951401999084801],
            [-78.838874, 38.047369999084033],
            [-78.682986, 37.782059999086151],
            [-78.668862, 37.724751999086628],
            [-78.643918, 37.733083999086553],
            [-78.641666, 37.683674999086982],
            [-78.677134, 37.69623999908687],
            [-78.739437, 37.631541999087403],
            [-78.82254, 37.630641999087402],
            [-78.825238, 37.552541999088071],
            [-78.869245, 37.54209199908815],
            [-78.986017, 37.695545999086875],
            [-79.067355, 37.72025499908667],
            [-79.071417, 37.778302999086193],
            [-79.137964, 37.814140999085907],
            [-79.172131, 37.803096999085994],
            [-79.144388, 37.856095999085561]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "137",
        COUNTYNS: "00835890",
        AFFGEOID: "0500000US31137",
        GEOID: "31137",
        NAME: "Phelps",
        LSAD: "06",
        ALAND: 1398048570,
        AWATER: 1646534
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.643462, 40.684910999067199],
            [-99.444401, 40.677891999067242],
            [-99.417316, 40.670824999067264],
            [-99.380544, 40.664859999067311],
            [-99.179248, 40.660540999067322],
            [-99.179403, 40.350680999068913],
            [-99.630459, 40.351119999068906],
            [-99.642209, 40.351199999068903],
            [-99.643462, 40.684910999067199]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "047",
        COUNTYNS: "00424225",
        AFFGEOID: "0500000US17047",
        GEOID: "17047",
        NAME: "Edwards",
        LSAD: "06",
        ALAND: 576062000,
        AWATER: 781203
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.147814, 38.569085999080087],
            [-87.953898, 38.570093999080072],
            [-87.97607, 38.399927999081328],
            [-87.951122, 38.290610999082155],
            [-87.9906, 38.259714999082391],
            [-88.027749, 38.255660999082423],
            [-88.1509, 38.256096999082416],
            [-88.147814, 38.569085999080087]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "039",
        COUNTYNS: "01622962",
        AFFGEOID: "0500000US26039",
        GEOID: "26039",
        NAME: "Crawford",
        LSAD: "06",
        ALAND: 1440969294,
        AWATER: 18026370
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.847309, 44.858036999056772],
            [-84.371737, 44.855038999056767],
            [-84.370643, 44.507222999056864],
            [-84.850929, 44.511068999056867],
            [-84.847309, 44.858036999056772]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "033",
        COUNTYNS: "00974115",
        AFFGEOID: "0500000US36033",
        GEOID: "36033",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 4219632482,
        AWATER: 176707575
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.611048, 44.99920299905677],
            [-74.436934, 44.996176999056757],
            [-74.234136, 44.992147999056762],
            [-74.02743141336849, 44.997365358296456],
            [-73.986382, 44.7077729990568],
            [-73.966148, 44.709117999056794],
            [-73.909687, 44.42969899905691],
            [-74.141424, 44.407267999056934],
            [-74.09349, 44.137614999057135],
            [-74.28187, 44.120551999057156],
            [-74.535156, 44.099249999057172],
            [-74.525683, 44.170635999057119],
            [-74.644739319825689, 44.997027559473459],
            [-74.611048, 44.99920299905677]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "039",
        COUNTYNS: "00558551",
        AFFGEOID: "0500000US22039",
        GEOID: "22039",
        NAME: "Evangeline",
        LSAD: "15",
        ALAND: 1715552082,
        AWATER: 44665790
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.630347, 30.487872999174549],
            [-92.583508, 30.562689999173354],
            [-92.597741, 30.896100999168119],
            [-92.508854, 30.896002999168132],
            [-92.48033, 30.952270999167254],
            [-92.383033, 31.001453999166493],
            [-92.280738, 30.96507099916705],
            [-92.237698, 30.848209999168862],
            [-92.212711, 30.848606999168865],
            [-92.172415, 30.767934999170119],
            [-92.172408, 30.673766999171615],
            [-92.211243, 30.568124999173275],
            [-92.262719, 30.538737999173733],
            [-92.458914, 30.52792999917391],
            [-92.493259, 30.480498999174671],
            [-92.631987, 30.481146999174658],
            [-92.630347, 30.487872999174549]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "079",
        COUNTYNS: "00424241",
        AFFGEOID: "0500000US17079",
        GEOID: "17079",
        NAME: "Jasper",
        LSAD: "06",
        ALAND: 1280788076,
        AWATER: 9334509
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.361745, 38.91084699907767],
            [-88.360654, 39.171117999075896],
            [-88.007766, 39.173924999075879],
            [-87.950385, 39.174881999075879],
            [-87.945923, 38.850107999078084],
            [-88.258608, 38.847520999078114],
            [-88.36176, 38.851948999078076],
            [-88.361745, 38.91084699907767]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "103",
        COUNTYNS: "00424253",
        AFFGEOID: "0500000US17103",
        GEOID: "17103",
        NAME: "Lee",
        LSAD: "06",
        ALAND: 1877336099,
        AWATER: 10634483
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.62933, 41.901616999062099],
            [-89.426727, 41.908417999062081],
            [-89.414627, 41.886174999062163],
            [-88.941279, 41.891751999062144],
            [-88.938618, 41.628318999063097],
            [-89.1672, 41.628706999063098],
            [-89.166561, 41.585288999063266],
            [-89.631494, 41.584948999063279],
            [-89.62933, 41.901616999062099]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "171",
        COUNTYNS: "00326713",
        AFFGEOID: "0500000US13171",
        GEOID: "13171",
        NAME: "Lamar",
        LSAD: "06",
        ALAND: 475262778,
        AWATER: 6046030
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.269169, 33.031437999137303],
            [-84.248659, 33.039329999137209],
            [-84.248185, 33.189018999135222],
            [-84.123981, 33.178619999135357],
            [-84.123767, 33.202823999135035],
            [-84.041498, 33.202628999135044],
            [-84.054175, 32.931496999138652],
            [-84.12334, 32.93218399913863],
            [-84.122361, 32.989575999137863],
            [-84.27014, 32.991010999137842],
            [-84.269169, 33.031437999137303]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "013",
        COUNTYNS: "00465196",
        AFFGEOID: "0500000US19013",
        GEOID: "19013",
        NAME: "Black Hawk",
        LSAD: "06",
        ALAND: 1465369216,
        AWATER: 17862718
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.554492, 42.642313999059823],
            [-92.081661, 42.642055999059821],
            [-92.064187, 42.468220999060307],
            [-92.064766, 42.297258999060816],
            [-92.298792, 42.2975269990608],
            [-92.53351, 42.297000999060813],
            [-92.534015, 42.469591999060292],
            [-92.5543, 42.555853999060062],
            [-92.554492, 42.642313999059823]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "101",
        COUNTYNS: "01383836",
        AFFGEOID: "0500000US48101",
        GEOID: "48101",
        NAME: "Cottle",
        LSAD: "06",
        ALAND: 2332448739,
        AWATER: 2723996
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.51734, 34.314101999121092],
            [-100.417783, 34.313523999121095],
            [-99.99772, 34.311828999121104],
            [-99.997621, 34.224354999122163],
            [-100.037319, 34.238777999121986],
            [-100.047028, 34.229779999122101],
            [-100.04848, 33.835973999126935],
            [-100.518691, 33.835649999126929],
            [-100.51734, 34.314101999121092]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "063",
        COUNTYNS: "00659477",
        AFFGEOID: "0500000US27063",
        GEOID: "27063",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 1820689421,
        AWATER: 42120425
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.452502, 43.847952999057455],
            [-95.100586, 43.847936999057467],
            [-94.859388, 43.848092999057464],
            [-94.854444, 43.848098999057463],
            [-94.85455506761069, 43.500553418210174],
            [-94.874235, 43.500556999057984],
            [-94.914613484713598, 43.500595872044059],
            [-95.214938, 43.500884999057966],
            [-95.387787594021873, 43.500478727965373],
            [-95.454432238660601, 43.500322084307975],
            [-95.452502, 43.847952999057455]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "055",
        COUNTYNS: "00516874",
        AFFGEOID: "0500000US21055",
        GEOID: "21055",
        NAME: "Crittenden",
        LSAD: "06",
        ALAND: 932275951,
        AWATER: 28974004
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.358436, 37.404859999089318],
            [-88.281667, 37.452595999088906],
            [-88.157061, 37.466936999088794],
            [-88.062294, 37.487836999088607],
            [-88.066250320097097, 37.50413832643666],
            [-88.015572, 37.547209999088111],
            [-87.932952, 37.480051999088687],
            [-87.929999, 37.40916699908928],
            [-87.847694, 37.420700999089185],
            [-87.801046, 37.379444999089543],
            [-88.089493, 37.222065999090916],
            [-88.095781, 37.179683999091282],
            [-88.184188, 37.12618899909176],
            [-88.193519, 37.147255999091577],
            [-88.21898, 37.273144999090455],
            [-88.318134, 37.398006999089382],
            [-88.358436, 37.404859999089318]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "165",
        COUNTYNS: "00342985",
        AFFGEOID: "0500000US13165",
        GEOID: "13165",
        NAME: "Jenkins",
        LSAD: "06",
        ALAND: 899563041,
        AWATER: 13338830
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.144977, 32.812740999140239],
            [-82.081349, 32.916761999138842],
            [-81.857985, 32.953881999138346],
            [-81.767553, 32.909410999138942],
            [-81.865987, 32.662989999142283],
            [-81.841005, 32.649092999142468],
            [-82.001236, 32.606909999143056],
            [-82.082871, 32.663323999142278],
            [-82.144977, 32.812740999140239]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "015",
        COUNTYNS: "01265768",
        AFFGEOID: "0500000US46015",
        GEOID: "46015",
        NAME: "Brule",
        LSAD: "06",
        ALAND: 2116643860,
        AWATER: 75721265
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.406961, 43.705276999057652],
            [-99.41015, 43.774618999057566],
            [-99.310826, 43.855215999057457],
            [-99.355864, 43.93437099905735],
            [-98.926997, 43.935142999057355],
            [-98.807771, 43.935222999057359],
            [-98.796965, 43.848574999057469],
            [-98.794588, 43.499186999057976],
            [-99.297882, 43.499855999057978],
            [-99.375378, 43.656868999057728],
            [-99.469097, 43.672802999057694],
            [-99.406961, 43.705276999057652]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "043",
        COUNTYNS: "00450350",
        AFFGEOID: "0500000US18043",
        GEOID: "18043",
        NAME: "Floyd",
        LSAD: "06",
        ALAND: 383225658,
        AWATER: 2708194
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.032817, 38.417897999081198],
            [-85.99462, 38.418344999081192],
            [-85.807704, 38.404442999081304],
            [-85.760844, 38.350217999081707],
            [-85.794510906770086, 38.277954734877554],
            [-85.816164, 38.282968999082215],
            [-85.839664, 38.23976999908254],
            [-85.894764, 38.188468999082943],
            [-85.895911787735585, 38.179926919953409],
            [-85.957576, 38.186071999082962],
            [-85.955224, 38.260500999082389],
            [-86.032559, 38.33065999908186],
            [-86.032817, 38.417897999081198]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "061",
        COUNTYNS: "00835852",
        AFFGEOID: "0500000US31061",
        GEOID: "31061",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1491355768,
        AWATER: 487899
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.179403, 40.350680999068913],
            [-98.726827, 40.35039799906891],
            [-98.726372963939014, 40.002336272237713],
            [-99.067018349898092, 40.002143512123006],
            [-99.085597, 40.002132999070824],
            [-99.179133150861077, 40.002108951765713],
            [-99.179403, 40.350680999068913]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "057",
        COUNTYNS: "00516875",
        AFFGEOID: "0500000US21057",
        GEOID: "21057",
        NAME: "Cumberland",
        LSAD: "06",
        ALAND: 790416910,
        AWATER: 14830126
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.601624, 36.872195999094039],
            [-85.560719, 36.850856999094248],
            [-85.45183, 36.938137999093435],
            [-85.232026, 36.925068999093568],
            [-85.27495, 36.897900999093807],
            [-85.215405, 36.854466999094214],
            [-85.246357, 36.744293999095227],
            [-85.295812485727481, 36.626149558463837],
            [-85.436404272885895, 36.618003844123422],
            [-85.496932, 36.690717999095725],
            [-85.471021, 36.733215999095329],
            [-85.517429, 36.731325999095354],
            [-85.596214, 36.818038999094547],
            [-85.601624, 36.872195999094039]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "125",
        COUNTYNS: "01383848",
        AFFGEOID: "0500000US48125",
        GEOID: "48125",
        NAME: "Dickens",
        LSAD: "06",
        ALAND: 2335454690,
        AWATER: 9057848
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.041158, 33.833624999126961],
            [-100.518691, 33.835649999126929],
            [-100.517449, 33.397865999132492],
            [-101.038788, 33.397213999132497],
            [-101.041158, 33.833624999126961]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "149",
        COUNTYNS: "01101862",
        AFFGEOID: "0500000US40149",
        GEOID: "40149",
        NAME: "Washita",
        LSAD: "06",
        ALAND: 2598189859,
        AWATER: 14579002
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.364217, 35.465327999108034],
            [-98.623336, 35.464224999108048],
            [-98.61824, 35.117173999111827],
            [-98.621054, 35.097351999112057],
            [-98.651267, 35.124180999111758],
            [-98.750344, 35.116342999111851],
            [-99.360226, 35.116737999111834],
            [-99.364217, 35.465327999108034]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "015",
        COUNTYNS: "01213660",
        AFFGEOID: "0500000US42015",
        GEOID: "42015",
        NAME: "Bradford",
        LSAD: "06",
        ALAND: 2971750814,
        AWATER: 35275587
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.926846362954294, 42.000723450879079],
            [-76.558118, 42.00015499906177],
            [-76.557624143569385, 42.000148715403476],
            [-76.473030885808001, 41.999072380068782],
            [-76.462155, 41.998933999061769],
            [-76.145518994782094, 41.998866462369769],
            [-76.115172, 41.651824999063003],
            [-76.197998, 41.647822999063024],
            [-76.220139, 41.541284999063436],
            [-76.813731, 41.590033999063259],
            [-76.874714, 41.596918999063227],
            [-76.926846362954294, 42.000723450879079]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "155",
        COUNTYNS: "00516921",
        AFFGEOID: "0500000US21155",
        GEOID: "21155",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 888390643,
        AWATER: 9967834
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.52129, 37.554342999088036],
            [-85.450493, 37.685947999086956],
            [-85.401784, 37.730657999086581],
            [-85.349334, 37.655662999087205],
            [-85.166084, 37.6311099990874],
            [-85.030172, 37.631269999087408],
            [-85.039674, 37.545231999088124],
            [-85.045023, 37.412245999089258],
            [-85.073772, 37.413668999089253],
            [-85.128685, 37.453190999088903],
            [-85.246759, 37.420204999089187],
            [-85.340446, 37.469511999088766],
            [-85.46625, 37.4655949990888],
            [-85.503001, 37.549089999088096],
            [-85.52129, 37.554342999088036]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "099",
        COUNTYNS: "01034214",
        AFFGEOID: "0500000US38099",
        GEOID: "38099",
        NAME: "Walsh",
        LSAD: "06",
        ALAND: 3319505703,
        AWATER: 32184979
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.318098, 48.543772999064153],
            [-97.927154, 48.543123999064164],
            [-97.148103466133591, 48.540744045250854],
            [-97.149122, 48.532304999064124],
            [-97.139173, 48.430527999063699],
            [-97.137904, 48.344584999063358],
            [-97.129533, 48.257814999063044],
            [-97.141910387198294, 48.19362761793731],
            [-97.903434, 48.194896999062806],
            [-98.292184, 48.195176999062795],
            [-98.31867, 48.369721999063465],
            [-98.318098, 48.543772999064153]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "231",
        COUNTYNS: "00516962",
        AFFGEOID: "0500000US21231",
        GEOID: "21231",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 1186687833,
        AWATER: 67286710
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.064307, 36.858742999094176],
            [-84.960878, 36.916337999093642],
            [-84.945527, 36.959746999093248],
            [-84.835712, 36.997611999092911],
            [-84.770172, 36.957734999093276],
            [-84.679165, 36.980913999093062],
            [-84.578158, 36.867132999094089],
            [-84.581516, 36.80368299909469],
            [-84.62256, 36.793932999094764],
            [-84.778455864350491, 36.603210511312454],
            [-84.785341, 36.603371999096552],
            [-84.785399978550302, 36.603375419032155],
            [-84.943948, 36.612568999096467],
            [-84.974868435837408, 36.61458335841364],
            [-84.999464, 36.624554999096354],
            [-85.004099, 36.756236999095123],
            [-85.064307, 36.858742999094176]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "069",
        COUNTYNS: "00066862",
        AFFGEOID: "0500000US05069",
        GEOID: "05069",
        NAME: "Jefferson",
        LSAD: "06",
        ALAND: 2253166949,
        AWATER: 113500176
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.212906, 34.230399999122092],
            [-92.208041, 34.492764999118975],
            [-92.029996, 34.489351999119016],
            [-91.974805, 34.488682999119021],
            [-91.705185, 34.482669999119089],
            [-91.709709, 34.307763999121171],
            [-91.711795, 34.233918999122054],
            [-91.537991, 34.223806999122168],
            [-91.514374, 34.142292999123157],
            [-91.451931, 34.133864999123254],
            [-91.445614, 34.080146999123912],
            [-91.602334, 34.088055999123817],
            [-91.712255, 34.173457999122775],
            [-91.726625, 34.065113999124101],
            [-91.74499, 34.09402099912375],
            [-91.953629, 34.093379999123762],
            [-91.953799, 34.064140999124106],
            [-92.233376, 34.062311999124134],
            [-92.212906, 34.230399999122092]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "073",
        COUNTYNS: "00424238",
        AFFGEOID: "0500000US17073",
        GEOID: "17073",
        NAME: "Henry",
        LSAD: "06",
        ALAND: 2131552073,
        AWATER: 6862245
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.433771, 41.326982999064306],
            [-90.43193, 41.456833999063782],
            [-90.332657, 41.514000999063548],
            [-90.196535, 41.540125999063441],
            [-90.185609, 41.584652999063273],
            [-89.862351, 41.584004999063275],
            [-89.857798, 41.234482999064703],
            [-89.867895, 41.216229999064765],
            [-89.868384, 41.148954999065069],
            [-89.984559, 41.149365999065061],
            [-90.437657, 41.151251999065046],
            [-90.433771, 41.326982999064306]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "113",
        COUNTYNS: "00835878",
        AFFGEOID: "0500000US31113",
        GEOID: "31113",
        NAME: "Logan",
        LSAD: "06",
        ALAND: 1478003937,
        AWATER: 1203586
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.711711, 41.739760999062682],
            [-100.265474, 41.739855999062691],
            [-100.251224, 41.739816999062683],
            [-100.251098, 41.393297999064025],
            [-100.713243, 41.393510999064034],
            [-100.711711, 41.739760999062682]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "055",
        COUNTYNS: "01248007",
        AFFGEOID: "0500000US45055",
        GEOID: "45055",
        NAME: "Kershaw",
        LSAD: "06",
        ALAND: 1881777515,
        AWATER: 35709172
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.879227, 34.457775999119377],
            [-80.743345, 34.539915999118413],
            [-80.739966, 34.479956999119111],
            [-80.527434, 34.50393499911884],
            [-80.550552, 34.560115999118175],
            [-80.408373, 34.61476499911754],
            [-80.32759, 34.497759999118905],
            [-80.285538, 34.393677999120136],
            [-80.288596, 34.366206999120465],
            [-80.288692, 34.364138999120506],
            [-80.353031, 34.30332999912121],
            [-80.437359, 34.284501999121431],
            [-80.479857, 34.168695999122825],
            [-80.58011, 34.099948999123669],
            [-80.618542, 34.109175999123565],
            [-80.616186, 34.099604999123677],
            [-80.692965, 34.0812799991239],
            [-80.719318, 34.068971999124045],
            [-80.869524, 34.182270999122686],
            [-80.825921, 34.268759999121635],
            [-80.769926, 34.377110999120333],
            [-80.879227, 34.457775999119377]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "055",
        COUNTYNS: "01804508",
        AFFGEOID: "0500000US72055",
        GEOID: "72055",
        NAME: "Guánica",
        LSAD: "13",
        ALAND: 95959910,
        AWATER: 109853315
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.961935, 17.991591999445053],
            [-66.958748, 18.032476999443965],
            [-66.910887, 18.02388299944419],
            [-66.884653, 18.024815999444172],
            [-66.863219, 17.998028999444884],
            [-66.858320862140388, 17.95107281229733],
            [-66.88344, 17.952525999446095],
            [-66.927261, 17.926874999446781],
            [-66.955577, 17.931556999446652],
            [-66.97870064243881, 17.95729094152686],
            [-66.961935, 17.991591999445053]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "479",
        COUNTYNS: "01384025",
        AFFGEOID: "0500000US48479",
        GEOID: "48479",
        NAME: "Webb",
        LSAD: "06",
        ALAND: 8706186700,
        AWATER: 36555096
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.197510055500999, 28.197000730353579],
            [-100.113719, 28.197818999213666],
            [-99.394177, 28.204626999213545],
            [-99.393972, 28.197494999213671],
            [-99.388946, 28.030418999216735],
            [-98.803325, 28.057479999216234],
            [-98.798323, 27.354028999229367],
            [-98.798087, 27.268011999231003],
            [-98.954669, 27.269396999230981],
            [-99.33361, 27.273222999230907],
            [-99.370621, 27.319173999230024],
            [-99.460825799134284, 27.262240196273918],
            [-99.463309, 27.268436999230996],
            [-99.487937, 27.294940999230491],
            [-99.529654, 27.306050999230283],
            [-99.487521, 27.412395999228259],
            [-99.495104, 27.451517999227516],
            [-99.497519, 27.500495999226601],
            [-99.52832, 27.498895999226626],
            [-99.530138, 27.580206999225087],
            [-99.556812, 27.614335999224451],
            [-99.624515, 27.634514999224074],
            [-99.704601, 27.6549539992237],
            [-99.758534, 27.717070999222528],
            [-99.801651, 27.741770999222073],
            [-99.844737, 27.778808999221383],
            [-99.87784, 27.824375999220525],
            [-99.904385, 27.875283999219587],
            [-99.917461, 27.917972999218797],
            [-99.932161, 27.967709999217885],
            [-99.984923, 27.990728999217449],
            [-100.028725, 28.073117999215942],
            [-100.075474, 28.124881999214999],
            [-100.174413, 28.179447999214002],
            [-100.197510055500999, 28.197000730353579]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "185",
        COUNTYNS: "00758547",
        AFFGEOID: "0500000US29185",
        GEOID: "29185",
        NAME: "St. Clair",
        LSAD: "06",
        ALAND: 1748192189,
        AWATER: 70138955
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.058285, 38.036626999084113],
            [-94.049895, 38.213984999082733],
            [-93.557009, 38.20107399908283],
            [-93.523484, 38.207215999082798],
            [-93.49728, 38.199086999082859],
            [-93.503946, 38.073122999083836],
            [-93.512103, 37.913534999085087],
            [-93.568709, 37.914917999085084],
            [-93.573202, 37.828034999085794],
            [-93.628404, 37.829434999085777],
            [-93.811402, 37.83457099908572],
            [-93.808648, 37.892774999085262],
            [-94.065675, 37.900986999085205],
            [-94.058285, 38.036626999084113]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "035",
        COUNTYNS: "01693324",
        AFFGEOID: "0500000US06035",
        GEOID: "06035",
        NAME: "Lassen",
        LSAD: "06",
        ALAND: 11761615611,
        AWATER: 463426050
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.331786, 41.183885999064913],
            [-120.501404, 41.183939999064918],
            [-119.999866, 41.183973999064918],
            [-119.999231, 40.865898999066339],
            [-119.997533, 40.720991999067024],
            [-119.996155, 40.32124999906906],
            [-119.997124, 40.126362999070118],
            [-119.997634, 39.956504999071079],
            [-119.999935448084997, 39.72240692379664],
            [-120.015734, 39.708720999072526],
            [-120.147149, 39.707657999072531],
            [-120.110627, 39.765778999072168],
            [-120.108761, 39.939509999071163],
            [-120.201264, 40.01347399907074],
            [-120.209641, 40.08600799907034],
            [-120.341385, 40.11524299907019],
            [-120.445892, 40.176853999069841],
            [-120.510817, 40.248944999069444],
            [-120.652158, 40.307655999069141],
            [-120.764403, 40.316009999069095],
            [-120.928582, 40.191930999069754],
            [-121.061493, 40.256416999069408],
            [-121.061417, 40.446535999068395],
            [-121.327826, 40.445366999068405],
            [-121.319976, 40.905885999066157],
            [-121.331786, 41.183885999064913]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "043",
        COUNTYNS: "00395408",
        AFFGEOID: "0500000US16043",
        GEOID: "16043",
        NAME: "Fremont",
        LSAD: "06",
        ALAND: 4827632203,
        AWATER: 83441579
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.155935, 44.057779999057232],
            [-112.034317, 44.057357999057224],
            [-112.033664, 44.144048999057127],
            [-111.913601, 44.144336999057138],
            [-111.857851, 44.187758999057102],
            [-111.852276, 44.316995999056999],
            [-111.791541, 44.317020999056993],
            [-111.791624, 44.401609999056944],
            [-111.617437, 44.401253999056934],
            [-111.617107034305008, 44.557127247475456],
            [-111.562814, 44.555208999056845],
            [-111.519126, 44.582915999056837],
            [-111.468833, 44.679334999056799],
            [-111.456947232493988, 44.695640736896301],
            [-111.438793, 44.720545999056789],
            [-111.385005, 44.755127999056782],
            [-111.377138236734993, 44.751196413020388],
            [-111.323669, 44.724473999056791],
            [-111.26875, 44.66827899905681],
            [-111.224161, 44.623401999056817],
            [-111.201459, 44.575695999056848],
            [-111.143557, 44.535731999056857],
            [-111.122654, 44.493658999056883],
            [-111.048974, 44.474071999056889],
            [-111.049148, 44.374924999056958],
            [-111.048452, 44.114830999057162],
            [-111.047219183001999, 43.983431291664999],
            [-111.187256, 43.932113999057364],
            [-111.398781, 43.922889999057354],
            [-111.501232, 43.929205999057373],
            [-111.558833, 43.883304999057415],
            [-111.83759, 43.898260999057399],
            [-111.976849, 43.927384999057359],
            [-111.977016, 43.970908999057315],
            [-112.15637, 43.971246999057314],
            [-112.155935, 44.057779999057232]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "071",
        COUNTYNS: "01720023",
        AFFGEOID: "0500000US30071",
        GEOID: "30071",
        NAME: "Phillips",
        LSAD: "06",
        ALAND: 13313706952,
        AWATER: 185563449
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-108.888316, 47.922721999061828],
            [-108.625587, 47.920410999061815],
            [-108.607579, 47.990996999062062],
            [-108.43398, 47.976552999062022],
            [-108.411239, 48.219549999062892],
            [-108.40987, 48.448577999063765],
            [-108.346316, 48.422304999063662],
            [-108.319011, 48.568430999064262],
            [-108.319119, 48.742208999065006],
            [-108.259384, 48.742224999064995],
            [-108.259372, 48.913591999065773],
            [-108.236393, 48.99955811636756],
            [-107.704696, 48.999871999066158],
            [-107.441017, 48.99936299906615],
            [-107.363582, 49.00001899906615],
            [-107.179812012537994, 48.999911600813554],
            [-107.206032, 48.912709999065761],
            [-107.206316, 48.568169999064267],
            [-107.192691, 48.509401999064025],
            [-107.25803, 48.51482099906405],
            [-107.301721, 48.480942999063906],
            [-107.301073, 48.393999999063567],
            [-107.260279, 48.394079999063564],
            [-107.260458, 48.304767999063216],
            [-107.369252, 48.305162999063214],
            [-107.36973, 48.218372999062886],
            [-107.404513, 48.218439999062902],
            [-107.404676, 47.870508999061656],
            [-107.414886, 47.691733999061057],
            [-107.440064, 47.62546199906086],
            [-107.610852, 47.647368999060937],
            [-107.694687, 47.629761999060875],
            [-107.761921, 47.548049999060623],
            [-107.864922, 47.513739999060533],
            [-107.90912, 47.450691999060354],
            [-107.928591, 47.567611999060688],
            [-108.065418, 47.598293999060786],
            [-108.313048, 47.583649999060732],
            [-108.335819, 47.567399999060683],
            [-108.526067, 47.620823999060846],
            [-108.771619, 47.645522999060923],
            [-108.891115, 47.734455999061204],
            [-108.888316, 47.922721999061828]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "44",
        COUNTYFP: "003",
        COUNTYNS: "01219778",
        AFFGEOID: "0500000US44003",
        GEOID: "44003",
        NAME: "Kent",
        LSAD: "06",
        ALAND: 436556188,
        AWATER: 50710341
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.789695431682389, 41.725198243871844],
            [-71.456412, 41.732388999062714],
            [-71.399378, 41.768263999062583],
            [-71.378273, 41.755296999062637],
            [-71.365207, 41.735648999062704],
            [-71.33097, 41.686961999062881],
            [-71.380542, 41.650325999063028],
            [-71.484427, 41.60259099906321],
            [-71.789358672742296, 41.596852159405927],
            [-71.789356, 41.596909999063222],
            [-71.789464590839003, 41.640017189769949],
            [-71.789678, 41.724733999062742],
            [-71.789695431682389, 41.725198243871844]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "071",
        COUNTYNS: "00451678",
        AFFGEOID: "0500000US18071",
        GEOID: "18071",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 1319087093,
        AWATER: 11923424
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.318133, 39.048809999076724],
            [-86.080272, 39.050269999076704],
            [-85.860573, 39.040909999076774],
            [-85.798804, 39.068535999076595],
            [-85.795357, 38.807507999078382],
            [-85.804554, 38.779905999078579],
            [-85.869559, 38.726170999078953],
            [-85.888334, 38.734442999078901],
            [-85.901055, 38.753813999078758],
            [-86.091705, 38.783398999078543],
            [-86.275281, 38.763794999078691],
            [-86.280389, 38.991027999077126],
            [-86.317474, 38.990869999077113],
            [-86.318133, 39.048809999076724]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "179",
        COUNTYNS: "00516936",
        AFFGEOID: "0500000US21179",
        GEOID: "21179",
        NAME: "Nelson",
        LSAD: "06",
        ALAND: 1081354955,
        AWATER: 16997387
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.737777, 37.811789999085924],
            [-85.680471, 37.815119999085901],
            [-85.576259, 37.885128999085325],
            [-85.489202, 37.990653999084479],
            [-85.399559, 37.939928999084891],
            [-85.167834, 37.971808999084644],
            [-85.152797, 37.897660999085225],
            [-85.204253, 37.846452999085642],
            [-85.254333, 37.866422999085472],
            [-85.401784, 37.730657999086581],
            [-85.450493, 37.685947999086956],
            [-85.52129, 37.554342999088036],
            [-85.62394, 37.540488999088176],
            [-85.592088, 37.693478999086885],
            [-85.680488, 37.732283999086576],
            [-85.736295, 37.781025999086175],
            [-85.737777, 37.811789999085924]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "021",
        COUNTYNS: "00758465",
        AFFGEOID: "0500000US29021",
        GEOID: "29021",
        NAME: "Buchanan",
        LSAD: "06",
        ALAND: 1056742981,
        AWATER: 17211398
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.113557, 39.553940999073461],
            [-95.076688, 39.576763999073329],
            [-95.047165, 39.595116999073213],
            [-95.044050752570399, 39.613668295893994],
            [-95.037464, 39.652904999072852],
            [-94.971317, 39.686409999072644],
            [-94.971078, 39.723145999072436],
            [-94.899316, 39.724041999072426],
            [-94.860371, 39.749529999072273],
            [-94.871144, 39.772993999072142],
            [-94.8778175298512, 39.820414775107352],
            [-94.602819, 39.81960799907187],
            [-94.602257, 39.747194999072285],
            [-94.601246, 39.530371999073616],
            [-94.627599, 39.531992999073601],
            [-95.091419402824002, 39.5332578842836],
            [-95.113557, 39.553940999073461]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "013",
        COUNTYNS: "01639728",
        AFFGEOID: "0500000US47013",
        GEOID: "47013",
        NAME: "Campbell",
        LSAD: "06",
        ALAND: 1243615773,
        AWATER: 46494677
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.329927, 36.285085999099621],
            [-84.349939, 36.466291999097862],
            [-84.330434, 36.509479999097444],
            [-84.266877, 36.511697999097422],
            [-84.251132, 36.534179999097219],
            [-84.2613212015047, 36.592741696363653],
            [-84.227332, 36.592180999096662],
            [-84.227193961998296, 36.592179511451761],
            [-83.987842, 36.589599999096684],
            [-83.987610950682793, 36.589592285169182],
            [-83.984327, 36.560101999096965],
            [-83.95696, 36.501554999097522],
            [-83.943139, 36.473563999097784],
            [-83.922618, 36.427778999098237],
            [-83.905638, 36.419978999098312],
            [-83.921217, 36.387878999098611],
            [-83.954908, 36.387622999098618],
            [-83.981016, 36.284977999099617],
            [-84.004317, 36.270377999099772],
            [-84.032519, 36.301778999099447],
            [-84.069918, 36.230377999100163],
            [-84.227527, 36.24487899910001],
            [-84.241825, 36.177577999100684],
            [-84.372731, 36.216777999100294],
            [-84.329927, 36.285085999099621]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "043",
        COUNTYNS: "01266978",
        AFFGEOID: "0500000US46043",
        GEOID: "46043",
        NAME: "Douglas",
        LSAD: "06",
        ALAND: 1118358767,
        AWATER: 4832944
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.705782, 43.499318999057976],
            [-98.320595, 43.498650999057972],
            [-98.114758, 43.498296999057978],
            [-98.109492, 43.19683799905853],
            [-98.706855, 43.37007499905819],
            [-98.705782, 43.499318999057976]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "021",
        COUNTYNS: "00450341",
        AFFGEOID: "0500000US18021",
        GEOID: "18021",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 926189694,
        AWATER: 7038300
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.240379, 39.259063999075323],
            [-87.238964, 39.520619999073674],
            [-87.199012, 39.607136999073141],
            [-87.013062, 39.604786999073156],
            [-87.014533, 39.473571999073961],
            [-86.939981, 39.473344999073959],
            [-86.942473, 39.342042999074792],
            [-87.053646, 39.342620999074796],
            [-87.054578, 39.16808599907592],
            [-87.240737, 39.171772999075905],
            [-87.240379, 39.259063999075323]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "167",
        COUNTYNS: "01074095",
        AFFGEOID: "0500000US39167",
        GEOID: "39167",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1636799188,
        AWATER: 20784929
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.844863, 39.450215999074103],
            [-81.823158, 39.494071999073832],
            [-81.708527, 39.480774999073923],
            [-81.712706, 39.585106999073275],
            [-81.588185, 39.586972999073268],
            [-81.473471, 39.58331799907328],
            [-81.47316, 39.645975999072895],
            [-81.394934, 39.601546999073165],
            [-81.283075, 39.605795999073145],
            [-81.036791, 39.572117999073349],
            [-81.037365041987087, 39.538055711546164],
            [-81.07595, 39.50965999907374],
            [-81.121273856107791, 39.457697412963363],
            [-81.128533, 39.449374999074116],
            [-81.185946, 39.430730999074243],
            [-81.249088, 39.389991999074489],
            [-81.347567, 39.345769999074776],
            [-81.370389808826289, 39.348700671478156],
            [-81.393794, 39.351705999074731],
            [-81.412706, 39.394617999074462],
            [-81.456143, 39.409273999074358],
            [-81.503189, 39.373241999074587],
            [-81.559647, 39.330773999074864],
            [-81.565247, 39.276174999075224],
            [-81.613896, 39.275338999075224],
            [-81.678331, 39.27375499907523],
            [-81.711628, 39.219227999075592],
            [-81.721468139172501, 39.210960808279353],
            [-81.721808, 39.269596999075254],
            [-81.818361, 39.272580999075238],
            [-81.853668, 39.318164999074945],
            [-81.844863, 39.450215999074103]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "107",
        COUNTYNS: "01074066",
        AFFGEOID: "0500000US39107",
        GEOID: "39107",
        NAME: "Mercer",
        LSAD: "06",
        ALAND: 1197738404,
        AWATER: 28425902
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.804119, 40.352843999068888],
            [-84.8029313665713, 40.465386620788102],
            [-84.802547, 40.501809999068122],
            [-84.802413878579401, 40.572212879714478],
            [-84.802119032263789, 40.728145935939608],
            [-84.456172, 40.728305999066997],
            [-84.45618, 40.684861999067209],
            [-84.455713, 40.567887999067786],
            [-84.455029, 40.451304999068384],
            [-84.455532, 40.363962999068832],
            [-84.434387, 40.354533999068884],
            [-84.434631, 40.35425899906889],
            [-84.804120527014902, 40.352762229365993],
            [-84.804119, 40.352843999068888]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "001",
        COUNTYNS: "01448015",
        AFFGEOID: "0500000US49001",
        GEOID: "49001",
        NAME: "Beaver",
        LSAD: "06",
        ALAND: 6689681822,
        AWATER: 5298466
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.050153620806, 38.572975212083463],
            [-112.976724, 38.572429999080065],
            [-112.515394, 38.572844999080061],
            [-112.518495, 38.510412999080522],
            [-112.519522, 38.479901999080738],
            [-112.335381, 38.332826999081846],
            [-112.35706, 38.224957999082662],
            [-112.444214, 38.150000999083233],
            [-112.47868, 38.147418999083257],
            [-113.4716, 38.148664999083252],
            [-114.049903375658005, 38.148763025753837],
            [-114.050138, 38.249959999082471],
            [-114.05012, 38.404535999081297],
            [-114.050154, 38.57291999908005],
            [-114.050153620806, 38.572975212083463]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "15",
        COUNTYFP: "009",
        COUNTYNS: "00365283",
        AFFGEOID: "0500000US15009",
        GEOID: "15009",
        NAME: "Maui",
        LSAD: "06",
        ALAND: 3008348865,
        AWATER: 3203294203
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-156.69989, 20.920628999370006],
              [-156.680905, 20.980261999368551],
              [-156.619581, 21.027792999367392],
              [-156.562773, 21.01616699936767],
              [-156.518707, 20.954661999369176],
              [-156.481055, 20.898198999370564],
              [-156.403304, 20.915825999370131],
              [-156.332817, 20.946449999369381],
              [-156.242555, 20.937837999369581],
              [-156.19471, 20.891974999370714],
              [-156.132669, 20.861368999371464],
              [-156.059788, 20.810539999372715],
              [-156.003532, 20.795544999373096],
              [-155.985413, 20.744244999374349],
              [-156.00187, 20.698063999375496],
              [-156.043786, 20.664901999376308],
              [-156.129898, 20.627522999377241],
              [-156.210258, 20.628517999377213],
              [-156.284391, 20.596487999378006],
              [-156.377633, 20.578426999378451],
              [-156.431872, 20.59814299937797],
              [-156.443673, 20.65601799937653],
              [-156.458438, 20.736675999374533],
              [-156.462242, 20.75395199937411],
              [-156.473562, 20.790755999373204],
              [-156.506026, 20.799462999372988],
              [-156.537752, 20.77840799937351],
              [-156.554617, 20.78609599937332],
              [-156.631794, 20.821239999372452],
              [-156.687804, 20.890719999370738],
              [-156.69989, 20.920628999370006]
            ]
          ],
          [
            [
              [-156.670469, 20.559908999378909],
              [-156.610734, 20.593769999378072],
              [-156.56714, 20.604894999377802],
              [-156.543034, 20.580114999378413],
              [-156.539643, 20.527643999379709],
              [-156.586238, 20.511710999380107],
              [-156.668809, 20.504737999380279],
              [-156.702265, 20.532450999379588],
              [-156.670469, 20.559908999378909]
            ]
          ],
          [
            [
              [-157.05913, 20.913406999370189],
              [-157.010001, 20.929756999369783],
              [-156.937529, 20.925273999369896],
              [-156.873125, 20.894678999370651],
              [-156.837047, 20.863574999371412],
              [-156.808469, 20.820395999372472],
              [-156.838321, 20.764574999373849],
              [-156.890295, 20.744854999374336],
              [-156.909081, 20.739532999374468],
              [-156.96789, 20.735079999374577],
              [-156.990678, 20.77590199937357],
              [-156.991834, 20.826602999372316],
              [-157.010911, 20.854475999371633],
              [-157.059663, 20.884633999370891],
              [-157.05913, 20.913406999370189]
            ]
          ],
          [
            [
              [-157.27722, 21.158430999364196],
              [-157.249695, 21.184400999363568],
              [-157.26069, 21.225683999362563],
              [-157.202125, 21.219297999362713],
              [-157.128207, 21.201487999363145],
              [-157.039987, 21.190908999363408],
              [-157.014267869299005, 21.200694265621468],
              [-157.014612, 21.182348999363615],
              [-156.973643, 21.175239999363789],
              [-156.942135, 21.160172999364153],
              [-156.918072, 21.129478999364906],
              [-156.900241, 21.131624999364849],
              [-156.917859561199009, 21.169021345645042],
              [-156.841592, 21.167925999363963],
              [-156.742231, 21.17621399936376],
              [-156.709106, 21.158654999364192],
              [-156.739342, 21.111335999365345],
              [-156.8022, 21.067094999366425],
              [-156.877137, 21.049299999366863],
              [-156.953872, 21.066127999366447],
              [-157.02617, 21.089014999365887],
              [-157.08066, 21.101975999365571],
              [-157.171606, 21.090700999365847],
              [-157.252534, 21.087669999365922],
              [-157.310748, 21.101626999365585],
              [-157.27722, 21.158430999364196]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "059",
        COUNTYNS: "00695753",
        AFFGEOID: "0500000US28059",
        GEOID: "28059",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 1872033050,
        AWATER: 830368430
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.884533, 30.677291999171544],
            [-88.884534, 30.735590999170629],
            [-88.834081, 30.734235999170647],
            [-88.41246743135919, 30.735597458385925],
            [-88.41227, 30.731770999170685],
            [-88.403931, 30.543358999173673],
            [-88.395023, 30.369424999176449],
            [-88.409927, 30.342114999176882],
            [-88.446495, 30.347752999176798],
            [-88.471875, 30.320019999177241],
            [-88.522494, 30.340091999176913],
            [-88.581929, 30.331062999177064],
            [-88.613006, 30.353963999176695],
            [-88.66382, 30.36209899917656],
            [-88.700587, 30.343688999176855],
            [-88.746945, 30.347621999176795],
            [-88.800343515203494, 30.357264172271542],
            [-88.818762, 30.36058999917659],
            [-88.89393, 30.393397999176056],
            [-88.884533, 30.677291999171544]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "059",
        COUNTYNS: "01672699",
        AFFGEOID: "0500000US13059",
        GEOID: "13059",
        NAME: "Clarke",
        LSAD: "06",
        ALAND: 308764509,
        AWATER: 4715185
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.537385, 33.965911999125311],
            [-83.503054, 33.999576999124898],
            [-83.454025, 34.00480899912484],
            [-83.384863, 34.037804999124432],
            [-83.360028, 34.040571999124403],
            [-83.294479, 34.008143999124805],
            [-83.258413, 33.999097999124899],
            [-83.278645, 33.951744999125488],
            [-83.24086, 33.90442999912608],
            [-83.275933, 33.847976999126786],
            [-83.415512, 33.918540999125902],
            [-83.508452, 33.931232999125747],
            [-83.537385, 33.965911999125311]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "481",
        COUNTYNS: "01384026",
        AFFGEOID: "0500000US48481",
        GEOID: "48481",
        NAME: "Wharton",
        LSAD: "06",
        ALAND: 2813116790,
        AWATER: 21288266
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.349217, 29.406051999192382],
            [-96.307672, 29.51452299919054],
            [-96.175422, 29.633805999188532],
            [-96.10965, 29.622523999188729],
            [-96.088912, 29.601657999189065],
            [-96.044596, 29.562237999189733],
            [-96.059627, 29.473328999191249],
            [-95.97343, 29.411444999192295],
            [-95.94681, 29.333432999193622],
            [-95.847656, 29.262589999194834],
            [-95.874028, 29.229701999195402],
            [-95.965196, 29.146864999196829],
            [-96.309178, 28.963290999200009],
            [-96.640315, 29.247803999195096],
            [-96.349217, 29.406051999192382]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "650",
        COUNTYNS: "01498554",
        AFFGEOID: "0500000US51650",
        GEOID: "51650",
        NAME: "Hampton",
        LSAD: "25",
        ALAND: 133308912,
        AWATER: 219591270
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.4355, 37.094865999092036],
            [-76.40029, 37.095850999092022],
            [-76.395795, 37.107173999091927],
            [-76.38369, 37.111579999091873],
            [-76.293126918301013, 37.11416369997216],
            [-76.271262, 37.084543999092126],
            [-76.283888905381005, 37.052731541869818],
            [-76.304272, 37.00137799909286],
            [-76.296792290295201, 36.99379096554464],
            [-76.341825, 36.924771999093565],
            [-76.384419, 36.95105899909332],
            [-76.443117, 37.019808999092703],
            [-76.4355, 37.094865999092036]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "329",
        COUNTYNS: "01383950",
        AFFGEOID: "0500000US48329",
        GEOID: "48329",
        NAME: "Midland",
        LSAD: "06",
        ALAND: 2331647582,
        AWATER: 4520751
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.287048, 32.086990999150345],
            [-102.211249, 32.086799999150365],
            [-101.776085, 32.086924999150355],
            [-101.775802, 31.651318999156683],
            [-102.287345, 31.651275999156685],
            [-102.287048, 32.086990999150345]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "179",
        COUNTYNS: "00485052",
        AFFGEOID: "0500000US20179",
        GEOID: "20179",
        NAME: "Sheridan",
        LSAD: "06",
        ALAND: 2320518307,
        AWATER: 647342
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.720213, 39.568033999073371],
            [-100.180351, 39.566889999073389],
            [-100.161667, 39.567276999073371],
            [-100.1642, 39.132070999076163],
            [-100.721296, 39.133380999076167],
            [-100.720213, 39.568033999073371]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "595",
        COUNTYNS: "01498422",
        AFFGEOID: "0500000US51595",
        GEOID: "51595",
        NAME: "Emporia",
        LSAD: "25",
        ALAND: 17881071,
        AWATER: 165621
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.560198, 36.706686999095574],
            [-77.51445, 36.720587999095443],
            [-77.509246, 36.682997999095797],
            [-77.525725, 36.672830999095886],
            [-77.556524, 36.674873999095865],
            [-77.560198, 36.706686999095574]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "149",
        COUNTYNS: "00835896",
        AFFGEOID: "0500000US31149",
        GEOID: "31149",
        NAME: "Rock",
        LSAD: "06",
        ALAND: 2611466209,
        AWATER: 8807750
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.677684, 42.730902999059595],
            [-99.537491, 42.717625999059628],
            [-99.415222, 42.77209799905949],
            [-99.257039, 42.804295999059413],
            [-99.245803, 42.782498999059477],
            [-99.234629, 42.087994999061479],
            [-99.662379, 42.086008999061477],
            [-99.663752, 42.434608999060394],
            [-99.677684, 42.730902999059595]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "115",
        COUNTYNS: "01266987",
        AFFGEOID: "0500000US46115",
        GEOID: "46115",
        NAME: "Spink",
        LSAD: "06",
        ALAND: 3894132582,
        AWATER: 16076446
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.717759, 45.24373199905682],
            [-97.981457, 45.240414999056824],
            [-97.981792, 45.153294999056783],
            [-97.977791, 44.631601999056826],
            [-98.705762, 44.634158999056815],
            [-98.705381, 44.880528999056764],
            [-98.716498, 44.896924999056758],
            [-98.717759, 45.24373199905682]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "149",
        COUNTYNS: "01074087",
        AFFGEOID: "0500000US39149",
        GEOID: "39149",
        NAME: "Shelby",
        LSAD: "06",
        ALAND: 1055921780,
        AWATER: 7808224
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.434387, 40.354533999068884],
            [-84.3386, 40.379198999068748],
            [-84.339137, 40.481075999068239],
            [-84.002372, 40.483114999068221],
            [-84.0037, 40.312926999069099],
            [-84.014763, 40.273458999069319],
            [-84.022919, 40.183944999069794],
            [-84.218658, 40.200003999069722],
            [-84.432575, 40.197036999069731],
            [-84.434631, 40.35425899906889],
            [-84.434387, 40.354533999068884]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "183",
        COUNTYNS: "00835913",
        AFFGEOID: "0500000US31183",
        GEOID: "31183",
        NAME: "Wheeler",
        LSAD: "06",
        ALAND: 1489717867,
        AWATER: 1059454
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.761155, 42.08851799906148],
            [-98.300592, 42.088852999061466],
            [-98.295421, 42.070494999061523],
            [-98.295602, 41.914953999062057],
            [-98.29576, 41.741177999062678],
            [-98.752225, 41.740371999062681],
            [-98.759133, 41.740381999062684],
            [-98.761155, 42.08851799906148]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "019",
        COUNTYNS: "01560095",
        AFFGEOID: "0500000US54019",
        GEOID: "54019",
        NAME: "Fayette",
        LSAD: "06",
        ALAND: 1713507808,
        AWATER: 17505425
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.380829, 37.969108999084661],
            [-81.329265, 38.182480999082983],
            [-81.231636, 38.263513999082356],
            [-81.070465, 38.210102999082764],
            [-80.96942, 38.215684999082725],
            [-80.882187, 38.103456999083598],
            [-80.769866, 37.98526999908453],
            [-80.810866, 37.949140999084818],
            [-80.748757, 37.865096999085495],
            [-80.806316, 37.868914999085462],
            [-80.94509, 37.819120999085861],
            [-81.057286, 37.835536999085718],
            [-81.073249, 37.875864999085408],
            [-81.295127, 37.889310999085289],
            [-81.34733, 37.976708999084593],
            [-81.380829, 37.969108999084661]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "059",
        COUNTYNS: "00659475",
        AFFGEOID: "0500000US27059",
        GEOID: "27059",
        NAME: "Isanti",
        LSAD: "06",
        ALAND: 1128567937,
        AWATER: 41166465
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.513683, 45.734317999057112],
            [-93.141793, 45.730656999057111],
            [-93.141974, 45.558108999056969],
            [-93.022274, 45.557153999056979],
            [-93.019563, 45.411769999056879],
            [-93.51007, 45.414797999056887],
            [-93.510704, 45.558771999056972],
            [-93.513683, 45.734317999057112]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "435",
        COUNTYNS: "01384003",
        AFFGEOID: "0500000US48435",
        GEOID: "48435",
        NAME: "Sutton",
        LSAD: "06",
        ALAND: 3765674653,
        AWATER: 1277066
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.960587, 30.7060709991711],
            [-100.116234, 30.710365999171028],
            [-100.116461, 30.290295999177722],
            [-100.700393, 30.288275999177756],
            [-100.960643, 30.28777599917775],
            [-100.960587, 30.7060709991711]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "161",
        COUNTYNS: "00516924",
        AFFGEOID: "0500000US21161",
        GEOID: "21161",
        NAME: "Mason",
        LSAD: "06",
        ALAND: 621927922,
        AWATER: 16277777
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.991605, 38.593700999079914],
            [-83.904375086104807, 38.767283874346468],
            [-83.852085, 38.75143299907878],
            [-83.834015, 38.716007999079032],
            [-83.78362, 38.69564099907916],
            [-83.77216, 38.658149999079441],
            [-83.705861635585592, 38.638037864145588],
            [-83.679484, 38.630035999079638],
            [-83.646911404126683, 38.641851933625368],
            [-83.624596, 38.61142499907978],
            [-83.641852, 38.525380999080404],
            [-83.85928, 38.456347999080911],
            [-83.930348, 38.492277999080656],
            [-83.970778, 38.587207999079958],
            [-83.991605, 38.593700999079914]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "047",
        COUNTYNS: "00395441",
        AFFGEOID: "0500000US16047",
        GEOID: "16047",
        NAME: "Gooding",
        LSAD: "06",
        ALAND: 1888836285,
        AWATER: 12568998
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-115.086155, 43.198063999058533],
            [-114.594605, 43.198344999058527],
            [-114.594375, 42.850900999059292],
            [-114.617401, 42.850887999059296],
            [-114.616487, 42.649297999059812],
            [-114.812167, 42.664414999059765],
            [-114.857807, 42.755300999059521],
            [-114.933622, 42.766930999059504],
            [-114.901636, 42.859107999059276],
            [-114.960322, 42.914924999059146],
            [-115.037774, 42.91186399905915],
            [-115.086852, 42.914559999059151],
            [-115.086155, 43.198063999058533]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "127",
        COUNTYNS: "01265763",
        AFFGEOID: "0500000US46127",
        GEOID: "46127",
        NAME: "Union",
        LSAD: "06",
        ALAND: 1193294712,
        AWATER: 16562654
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.805682, 43.08366999905877],
            [-96.452097516053684, 43.082552924253271],
            [-96.458201, 43.067553999058802],
            [-96.511605, 43.039926999058871],
            [-96.492693, 43.005088999058941],
            [-96.520246, 42.977642999059007],
            [-96.500308, 42.959390999059039],
            [-96.541689, 42.922575999059127],
            [-96.540472329193278, 42.908595697015166],
            [-96.537851, 42.878474999059236],
            [-96.577937, 42.827644999059359],
            [-96.621875, 42.779254999059475],
            [-96.624704, 42.725496999059608],
            [-96.591602, 42.688080999059693],
            [-96.526766, 42.641183999059827],
            [-96.480022478980004, 42.561324775620747],
            [-96.476952, 42.556078999060055],
            [-96.49297, 42.517281999060174],
            [-96.477454, 42.509588999060185],
            [-96.445508, 42.490629999060239],
            [-96.501321, 42.482748999060263],
            [-96.525142, 42.510233999060183],
            [-96.611489, 42.506087999060206],
            [-96.627945456901699, 42.527096138201941],
            [-96.658754, 42.566425999060023],
            [-96.7093, 42.603752999059935],
            [-96.697639, 42.659142999059782],
            [-96.778182, 42.662992999059774],
            [-96.801652, 42.698773999059675],
            [-96.807370600098196, 42.70067898443267],
            [-96.805682, 43.08366999905877]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "015",
        COUNTYNS: "01648572",
        AFFGEOID: "0500000US47015",
        GEOID: "47015",
        NAME: "Cannon",
        LSAD: "06",
        ALAND: 687992432,
        AWATER: 156144
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.141766, 35.91437399910334],
            [-86.153214, 35.954398999102928],
            [-86.090502, 35.974450999102721],
            [-86.014849, 35.961059999102851],
            [-85.88921, 35.857542999103913],
            [-85.885156, 35.839657999104098],
            [-85.997426, 35.711954999105423],
            [-85.985062, 35.660107999105975],
            [-86.08889, 35.646822999106099],
            [-86.207147, 35.70378999910551],
            [-86.141766, 35.91437399910334]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "139",
        COUNTYNS: "01581129",
        AFFGEOID: "0500000US55139",
        GEOID: "55139",
        NAME: "Winnebago",
        LSAD: "06",
        ALAND: 1125258751,
        AWATER: 373232751
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.886673, 44.24262199905705],
            [-88.7662, 44.243365999057055],
            [-88.73977, 44.24330299905705],
            [-88.524791, 44.244027999057046],
            [-88.40407, 44.244101999057051],
            [-88.404187, 43.93819999905736],
            [-88.403195, 43.892976999057396],
            [-88.885697, 43.895183999057402],
            [-88.886193, 43.983232999057314],
            [-88.886673, 44.24262199905705]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "083",
        COUNTYNS: "00835863",
        AFFGEOID: "0500000US31083",
        GEOID: "31083",
        NAME: "Harlan",
        LSAD: "06",
        ALAND: 1433471291,
        AWATER: 53339808
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.630459, 40.351119999068906],
            [-99.179403, 40.350680999068913],
            [-99.179133150861077, 40.002108951765713],
            [-99.501792, 40.00202599907081],
            [-99.625326726399592, 40.00177782671782],
            [-99.628253849224492, 40.001771946339204],
            [-99.630459, 40.351119999068906]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "117",
        COUNTYNS: "00974156",
        AFFGEOID: "0500000US36117",
        GEOID: "36117",
        NAME: "Wayne",
        LSAD: "06",
        ALAND: 1563903159,
        AWATER: 2018194942
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.376051995953375, 43.274033762545379],
            [-77.341092, 43.280660999058362],
            [-77.264177, 43.277362999058369],
            [-77.130429, 43.285634999058367],
            [-76.999691, 43.271455999058396],
            [-76.952174, 43.270691999058386],
            [-76.841675, 43.305398999058319],
            [-76.769025, 43.31845199905829],
            [-76.722002565712799, 43.337580195039649],
            [-76.702324, 43.080158999058774],
            [-76.713806, 43.024034999058898],
            [-76.963926, 43.013156999058928],
            [-77.133397, 43.012462999058933],
            [-77.215839, 43.03984399905886],
            [-77.246804, 43.034662999058881],
            [-77.371478, 43.034695999058883],
            [-77.376051995953375, 43.274033762545379]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "013",
        COUNTYNS: "01383792",
        AFFGEOID: "0500000US48013",
        GEOID: "48013",
        NAME: "Atascosa",
        LSAD: "06",
        ALAND: 3158596670,
        AWATER: 4995711
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.804763, 29.250692999195042],
            [-98.441548, 29.126126999197194],
            [-98.407336, 29.114434999197393],
            [-98.190991, 28.882332999201438],
            [-98.098315, 28.786948999203119],
            [-98.335031, 28.612657999206203],
            [-98.335047, 28.648274999205565],
            [-98.800848, 28.647305999205592],
            [-98.800841, 28.647486999205583],
            [-98.8049, 29.090433999197806],
            [-98.804763, 29.250692999195042]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "41",
        COUNTYFP: "071",
        COUNTYNS: "01135866",
        AFFGEOID: "0500000US41071",
        GEOID: "41071",
        NAME: "Yamhill",
        LSAD: "06",
        ALAND: 1854169174,
        AWATER: 6491586
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.78454, 45.216293999056802],
            [-123.463518, 45.216311999056785],
            [-123.46488, 45.433331999056897],
            [-123.135427, 45.433458999056896],
            [-123.032161, 45.40496199905688],
            [-122.970229, 45.346453999056848],
            [-122.867891, 45.317344999056829],
            [-122.849925, 45.259696999056821],
            [-122.968666, 45.284728999056831],
            [-123.04117, 45.22078799905681],
            [-122.996693, 45.11706299905677],
            [-123.068667, 45.07485999905677],
            [-123.724368, 45.076225999056781],
            [-123.784218, 45.076654999056771],
            [-123.78454, 45.216293999056802]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "015",
        COUNTYNS: "00936829",
        AFFGEOID: "0500000US35015",
        GEOID: "35015",
        NAME: "Eddy",
        LSAD: "06",
        ALAND: 10816855244,
        AWATER: 56325882
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.851524, 32.520540999144245],
            [-104.841576, 32.963212999138221],
            [-103.814507, 32.96511399913819],
            [-103.814216, 32.522367999144215],
            [-103.722938, 32.522343999144219],
            [-103.722881979148013, 32.000165567827985],
            [-103.980213477381014, 32.000032850514195],
            [-104.024521, 32.000009999151601],
            [-104.643526, 32.000442999151595],
            [-104.84774, 32.0004646019866],
            [-104.851524, 32.520540999144245]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "073",
        COUNTYNS: "00516883",
        AFFGEOID: "0500000US21073",
        GEOID: "21073",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 538073033,
        AWATER: 11025563
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.998898, 38.274978999082279],
            [-84.956959, 38.332270999081842],
            [-84.997667, 38.335585999081815],
            [-84.906256, 38.374835999081519],
            [-84.870482, 38.356750999081648],
            [-84.793412, 38.33866899908179],
            [-84.740594, 38.352414999081688],
            [-84.730989, 38.216337999082725],
            [-84.725168, 38.195425999082886],
            [-84.864804, 38.141372999083309],
            [-84.864908, 38.116925999083499],
            [-84.902266, 38.093436999083679],
            [-85.023711, 38.129051999083401],
            [-84.998898, 38.274978999082279]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "341",
        COUNTYNS: "01383956",
        AFFGEOID: "0500000US48341",
        GEOID: "48341",
        NAME: "Moore",
        LSAD: "06",
        ALAND: 2330188755,
        AWATER: 25718180
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.163015, 36.055248999101899],
            [-101.623466, 36.055404999101896],
            [-101.622831, 35.624056999106351],
            [-101.6228, 35.620195999106393],
            [-102.162752, 35.620034999106387],
            [-102.162809, 35.62751499910631],
            [-102.163015, 36.055248999101899]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "097",
        COUNTYNS: "00516895",
        AFFGEOID: "0500000US21097",
        GEOID: "21097",
        NAME: "Harrison",
        LSAD: "06",
        ALAND: 793480924,
        AWATER: 9057368
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.485295, 38.508551999080538],
            [-84.476299, 38.542722999080283],
            [-84.448556, 38.529421999080384],
            [-84.400321, 38.560602999080153],
            [-84.205346, 38.582038999079991],
            [-84.215625, 38.554975999080192],
            [-84.162031, 38.554124999080202],
            [-84.183903, 38.498018999080607],
            [-84.149769, 38.479091999080751],
            [-84.1021, 38.45937899908089],
            [-84.19405, 38.371749999081558],
            [-84.281844, 38.312460999081999],
            [-84.306169, 38.313099999081992],
            [-84.333623, 38.288974999082171],
            [-84.442661, 38.283235999082208],
            [-84.433007, 38.299057999082095],
            [-84.467748, 38.393057999081378],
            [-84.557375, 38.492916999080641],
            [-84.485295, 38.508551999080538]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "187",
        COUNTYNS: "00465282",
        AFFGEOID: "0500000US19187",
        GEOID: "19187",
        NAME: "Webster",
        LSAD: "06",
        ALAND: 1853586077,
        AWATER: 7278162
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.443083, 42.645163999059825],
            [-93.971714, 42.644706999059814],
            [-93.971583, 42.558138999060048],
            [-93.931684, 42.472010999060295],
            [-93.93158, 42.20990799906108],
            [-94.164704, 42.209919999061071],
            [-94.397526, 42.209160999061083],
            [-94.397671, 42.473299999060295],
            [-94.443033, 42.47337799906029],
            [-94.443024, 42.558842999060047],
            [-94.443083, 42.645163999059825]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "045",
        COUNTYNS: "01804502",
        AFFGEOID: "0500000US72045",
        GEOID: "72045",
        NAME: "Comerío",
        LSAD: "13",
        ALAND: 73557184,
        AWATER: 319738
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.266466, 18.245269999438314],
            [-66.228094, 18.254026999438079],
            [-66.206135, 18.276659999437481],
            [-66.190676, 18.258839999437949],
            [-66.169126, 18.22428999943887],
            [-66.211851, 18.173086999440223],
            [-66.24046, 18.184793999439915],
            [-66.263553, 18.18495899943991],
            [-66.266466, 18.245269999438314]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "023",
        COUNTYNS: "00484981",
        AFFGEOID: "0500000US20023",
        GEOID: "20023",
        NAME: "Cheyenne",
        LSAD: "06",
        ALAND: 2641501922,
        AWATER: 2738021
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.051744, 40.003077999070797],
            [-101.832161, 40.002932999070808],
            [-101.542273, 40.002608999070809],
            [-101.411028987801998, 40.002582568638402],
            [-101.413913, 39.56836099907337],
            [-102.049961857827995, 39.568178979746477],
            [-102.049992135644004, 39.574056096494353],
            [-102.051254, 39.818991999071876],
            [-102.051744, 40.003077999070797]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "165",
        COUNTYNS: "00424283",
        AFFGEOID: "0500000US17165",
        GEOID: "17165",
        NAME: "Saline",
        LSAD: "06",
        ALAND: 983755534,
        AWATER: 17999636
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.70676, 37.863337999085502],
            [-88.706622, 37.906796999085159],
            [-88.37453, 37.907677999085145],
            [-88.375332, 37.599562999087667],
            [-88.412112, 37.599911999087666],
            [-88.708546, 37.599276999087671],
            [-88.70676, 37.863337999085502]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "017",
        COUNTYNS: "00484978",
        AFFGEOID: "0500000US20017",
        GEOID: "20017",
        NAME: "Chase",
        LSAD: "06",
        ALAND: 2002208724,
        AWATER: 12087996
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.837654, 38.348635999081722],
            [-96.819723, 38.522463999080429],
            [-96.35378, 38.521656999080435],
            [-96.357277, 38.172659999083059],
            [-96.358099, 38.085816999083733],
            [-96.522782, 38.086369999083729],
            [-96.840772, 38.085621999083735],
            [-96.837654, 38.348635999081722]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "107",
        COUNTYNS: "01383839",
        AFFGEOID: "0500000US48107",
        GEOID: "48107",
        NAME: "Crosby",
        LSAD: "06",
        ALAND: 2331503437,
        AWATER: 3873557
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.56358, 33.830450999126995],
            [-101.041158, 33.833624999126961],
            [-101.038788, 33.397213999132497],
            [-101.556884, 33.394759999132532],
            [-101.56358, 33.830450999126995]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "067",
        COUNTYNS: "01622976",
        AFFGEOID: "0500000US26067",
        GEOID: "26067",
        NAME: "Ionia",
        LSAD: "06",
        ALAND: 1479659350,
        AWATER: 22641874
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.312513, 43.118792999058691],
            [-85.1936, 43.119533999058689],
            [-84.836889, 43.118850999058701],
            [-84.837085, 42.770478999059499],
            [-85.074245, 42.770783999059503],
            [-85.309626, 42.769877999059496],
            [-85.312513, 43.118792999058691]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "073",
        COUNTYNS: "01622979",
        AFFGEOID: "0500000US26073",
        GEOID: "26073",
        NAME: "Isabella",
        LSAD: "06",
        ALAND: 1483229495,
        AWATER: 12959299
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.088811, 43.813675999057509],
            [-84.606035, 43.815217999057516],
            [-84.60754, 43.466005999058027],
            [-84.845962, 43.466157999058041],
            [-85.084996, 43.466189999058031],
            [-85.088811, 43.813675999057509]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "007",
        COUNTYNS: "01719625",
        AFFGEOID: "0500000US30007",
        GEOID: "30007",
        NAME: "Broadwater",
        LSAD: "06",
        ALAND: 3088195835,
        AWATER: 118487318
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.787949, 46.569464999058269],
            [-111.631906, 46.569971999058268],
            [-111.639261, 46.738179999058595],
            [-111.602294, 46.778260999058674],
            [-111.498095, 46.762501999058642],
            [-111.455397, 46.742532999058596],
            [-111.411196, 46.639975999058393],
            [-111.330729, 46.633764999058386],
            [-111.344946, 46.545939999058227],
            [-111.257378, 46.498484999058128],
            [-111.246519, 46.438108999058024],
            [-111.125122, 46.439754999058046],
            [-111.057226, 46.403406999057978],
            [-111.126849, 46.273104999057757],
            [-111.075133, 46.250798999057736],
            [-111.061956, 46.192833999057648],
            [-111.346652, 46.187784999057641],
            [-111.349372, 46.122606999057538],
            [-111.423831, 46.061950999057466],
            [-111.419618, 45.996321999057386],
            [-111.587696, 45.906681999057284],
            [-111.660057, 45.833499999057203],
            [-111.660422, 46.049191999057463],
            [-111.784565, 46.049812999057451],
            [-111.787949, 46.569464999058269]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "331",
        COUNTYNS: "01383951",
        AFFGEOID: "0500000US48331",
        GEOID: "48331",
        NAME: "Milam",
        LSAD: "06",
        ALAND: 2633798194,
        AWATER: 12758799
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.315507, 30.75237099917037],
            [-97.259082, 30.889595999168225],
            [-97.070188, 30.986219999166728],
            [-96.849079, 31.100470999164973],
            [-96.829121, 31.105870999164889],
            [-96.793139, 31.0657179991655],
            [-96.75633, 30.92720599916764],
            [-96.68497, 30.906190999167965],
            [-96.694853, 30.864516999168622],
            [-96.611097, 30.757353999170284],
            [-96.621281, 30.730818999170712],
            [-96.963629, 30.557168999173445],
            [-97.155219, 30.457343999175048],
            [-97.271418, 30.735691999170623],
            [-97.315507, 30.75237099917037]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "005",
        COUNTYNS: "01605068",
        AFFGEOID: "0500000US56005",
        GEOID: "56005",
        NAME: "Campbell",
        LSAD: "06",
        ALAND: 12437264300,
        AWATER: 11038017
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.024880029032005, 44.997584122612857],
            [-105.848065, 45.000395999056757],
            [-105.076600952253997, 45.000296612464162],
            [-105.085451, 44.786800999056773],
            [-105.079281, 44.176181999057107],
            [-105.079797, 43.498444999057966],
            [-106.017366, 43.494968999057988],
            [-106.009201, 43.821578999057493],
            [-106.014477, 44.520979999056863],
            [-106.009676, 44.563980999056845],
            [-106.024880029032005, 44.997584122612857]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "295",
        COUNTYNS: "00354216",
        AFFGEOID: "0500000US13295",
        GEOID: "13295",
        NAME: "Walker",
        LSAD: "06",
        ALAND: 1156145994,
        AWATER: 1672676
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.534405257815393, 34.623790337553238],
            [-85.490795, 34.669448999116916],
            [-85.49053, 34.699109999116565],
            [-85.450409, 34.759240999115868],
            [-85.450561, 34.831676999115039],
            [-85.43371, 34.875076999114555],
            [-85.363919028931093, 34.983376562253824],
            [-85.277556, 34.984974999113312],
            [-85.265055021715796, 34.985077643855007],
            [-85.264762, 34.854276999114781],
            [-85.20218, 34.854450999114789],
            [-85.201856, 34.77507999911569],
            [-85.144789, 34.767638999115775],
            [-85.168851, 34.72027899911631],
            [-85.050847, 34.719979999116326],
            [-85.050446, 34.622481999117461],
            [-85.069159, 34.587183999117869],
            [-85.107748, 34.58748299911786],
            [-85.360191, 34.581798999117929],
            [-85.526894818161011, 34.588685691851346],
            [-85.534405257815393, 34.623790337553238]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "169",
        COUNTYNS: "00758539",
        AFFGEOID: "0500000US29169",
        GEOID: "29169",
        NAME: "Pulaski",
        LSAD: "06",
        ALAND: 1416985288,
        AWATER: 11341981
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.409231, 37.859179999085526],
            [-92.406872, 37.977801999084583],
            [-92.406275, 38.021178999084249],
            [-92.18537, 38.016337999084278],
            [-92.021165, 38.010638999084321],
            [-92.029258, 37.602541999087641],
            [-92.183261, 37.605242999087622],
            [-92.249463, 37.604542999087634],
            [-92.248865, 37.648441999087261],
            [-92.413669, 37.713940999086716],
            [-92.409231, 37.859179999085526]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "177",
        COUNTYNS: "01639796",
        AFFGEOID: "0500000US47177",
        GEOID: "47177",
        NAME: "Warren",
        LSAD: "06",
        ALAND: 1120619142,
        AWATER: 3524884
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.997426, 35.711954999105423],
            [-85.885156, 35.839657999104098],
            [-85.868174, 35.846359999104024],
            [-85.682095, 35.831253999104192],
            [-85.663609, 35.819588999104312],
            [-85.602876, 35.794995999104557],
            [-85.615164, 35.761063999104913],
            [-85.594632, 35.617897999106411],
            [-85.557535, 35.532976999107312],
            [-85.607326, 35.53165099910732],
            [-85.820334, 35.504572999107616],
            [-85.876962, 35.524094999107405],
            [-85.948192, 35.573421999106884],
            [-85.985062, 35.660107999105975],
            [-85.997426, 35.711954999105423]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "123",
        COUNTYNS: "01265784",
        AFFGEOID: "0500000US46123",
        GEOID: "46123",
        NAME: "Tripp",
        LSAD: "06",
        ALAND: 4176247355,
        AWATER: 13272785
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.230845, 43.713855999057643],
            [-100.115169, 43.685433999057693],
            [-100.00693, 43.704732999057654],
            [-99.981465, 43.687118999057688],
            [-99.837825, 43.689070999057684],
            [-99.745836, 43.710294999057645],
            [-99.656634, 43.766068999057559],
            [-99.623587, 43.742413999057597],
            [-99.623578, 43.49985899905797],
            [-99.534481, 43.499800999057975],
            [-99.534055014809013, 42.998197487377062],
            [-99.850037, 42.998170999058964],
            [-100.198412611565999, 42.997976883690441],
            [-100.21385, 43.128387999058667],
            [-100.214221, 43.390320999058162],
            [-100.230991, 43.477980999058012],
            [-100.230845, 43.713855999057643]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "033",
        COUNTYNS: "00161542",
        AFFGEOID: "0500000US01033",
        GEOID: "01033",
        NAME: "Colbert",
        LSAD: "06",
        ALAND: 1534875463,
        AWATER: 80027136
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.134263, 34.622659999117452],
            [-88.097888, 34.892201999114356],
            [-87.973222, 34.882549999114467],
            [-87.92029, 34.804467999115346],
            [-87.806963, 34.732060999116186],
            [-87.524363, 34.832383999115038],
            [-87.42651, 34.800021999115408],
            [-87.44577, 34.650967999117128],
            [-87.529667, 34.567080999118105],
            [-88.139559441247386, 34.581696745001821],
            [-88.134263, 34.622659999117452]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "373",
        COUNTYNS: "01383972",
        AFFGEOID: "0500000US48373",
        GEOID: "48373",
        NAME: "Polk",
        LSAD: "06",
        ALAND: 2737733230,
        AWATER: 136271619
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.20018, 30.824565999169238],
            [-94.939127, 31.042047999165863],
            [-94.842947, 31.146577999164265],
            [-94.738594, 31.103689999164928],
            [-94.573675, 31.068431999165455],
            [-94.561943, 31.058951999165618],
            [-94.657992, 31.012006999166328],
            [-94.545717, 30.526976999173929],
            [-94.537926, 30.491058999174498],
            [-94.732732, 30.490065999174515],
            [-94.777341, 30.489001999174519],
            [-94.849414, 30.493552999174458],
            [-94.829984, 30.541111999173701],
            [-94.965729, 30.576585999173144],
            [-95.054602, 30.680172999171511],
            [-95.135384, 30.693815999171292],
            [-95.131205, 30.781702999169909],
            [-95.20018, 30.824565999169238]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "023",
        COUNTYNS: "01697238",
        AFFGEOID: "0500000US54023",
        GEOID: "54023",
        NAME: "Grant",
        LSAD: "06",
        ALAND: 1236390684,
        AWATER: 7545524
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.486873, 39.205960999075678],
            [-79.424413, 39.22817099907553],
            [-79.35375, 39.278038999075214],
            [-79.283723, 39.30963999907501],
            [-79.262392117176091, 39.326243827542299],
            [-79.134456, 39.312500999074977],
            [-78.979898, 39.237623999075467],
            [-79.089655, 39.038207999076789],
            [-79.046853, 38.92720999907754],
            [-79.134296, 38.81333999907833],
            [-79.349867, 38.957508999077348],
            [-79.357666, 38.964508999077296],
            [-79.298164, 39.073209999076553],
            [-79.355468, 39.086507999076467],
            [-79.306461, 39.209202999075643],
            [-79.487175, 39.19490599907575],
            [-79.486873, 39.205960999075678]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "007",
        COUNTYNS: "00303634",
        AFFGEOID: "0500000US12007",
        GEOID: "12007",
        NAME: "Bradford",
        LSAD: "06",
        ALAND: 761362376,
        AWATER: 16904945
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.306677, 29.956611999183171],
            [-82.259971, 30.028089999181987],
            [-82.14734, 30.10998499918065],
            [-82.142578, 30.143116999180108],
            [-82.049425, 30.1431359991801],
            [-82.046112, 29.747128999186639],
            [-82.049244, 29.71866999918711],
            [-82.055625, 29.718231999187122],
            [-82.133126, 29.83594899918516],
            [-82.272563, 29.843010999185037],
            [-82.418728, 29.923092999183723],
            [-82.306677, 29.956611999183171]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "145",
        COUNTYNS: "00326700",
        AFFGEOID: "0500000US13145",
        GEOID: "13145",
        NAME: "Harris",
        LSAD: "06",
        ALAND: 1201447999,
        AWATER: 23502960
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.18611740178919, 32.870138269111074],
            [-84.881702, 32.868760999139504],
            [-84.861768, 32.872494999139434],
            [-84.751338, 32.869199999139482],
            [-84.700538, 32.844639999139808],
            [-84.682876, 32.730349999141367],
            [-84.693841, 32.685771999141984],
            [-84.694603, 32.583944999143377],
            [-84.907883, 32.583432999143383],
            [-84.907693, 32.607607999143049],
            [-84.912792, 32.607628999143039],
            [-85.076072372604798, 32.608067474300135],
            [-85.088533, 32.657957999142354],
            [-85.11425, 32.730446999141364],
            [-85.124533222750586, 32.751629605892482],
            [-85.160963, 32.826671999140054],
            [-85.1844, 32.861316999139596],
            [-85.18611740178919, 32.870138269111074]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "057",
        COUNTYNS: "00395613",
        AFFGEOID: "0500000US16057",
        GEOID: "16057",
        NAME: "Latah",
        LSAD: "06",
        ALAND: 2786482704,
        AWATER: 2370320
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.039833386027993, 47.127268991313585],
            [-116.967735, 47.115231999059446],
            [-116.835269, 47.032451999059248],
            [-116.45812, 47.036595999059266],
            [-116.329418, 47.021914999059227],
            [-116.329436, 46.934668999059021],
            [-116.32954, 46.627956999058377],
            [-116.456098, 46.629096999058369],
            [-116.61174, 46.631244999058381],
            [-116.719085, 46.542873999058223],
            [-117.039777655561991, 46.541708991096321],
            [-117.039833386027993, 47.127268991313585]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "157",
        COUNTYNS: "01623019",
        AFFGEOID: "0500000US26157",
        GEOID: "26157",
        NAME: "Tuscola",
        LSAD: "06",
        ALAND: 2080718000,
        AWATER: 286299930
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.699417091881102, 43.601637149314314],
            [-83.683351, 43.590583999057834],
            [-83.529640471733998, 43.719244918162524],
            [-83.51234, 43.733725999057619],
            [-83.5060855525428, 43.745157212197498],
            [-83.466592, 43.667620999057704],
            [-83.117844, 43.675492999057695],
            [-83.104136, 43.33820599905826],
            [-83.120396, 43.327048999058277],
            [-83.354418, 43.32225699905829],
            [-83.351709, 43.235192999058448],
            [-83.460708, 43.232790999058466],
            [-83.460733, 43.223130999058469],
            [-83.695621, 43.221421999058485],
            [-83.698509, 43.392710999058153],
            [-83.698816, 43.478956999057999],
            [-83.699484, 43.565822999057865],
            [-83.699417091881102, 43.601637149314314]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "013",
        COUNTYNS: "01026333",
        AFFGEOID: "0500000US37013",
        GEOID: "37013",
        NAME: "Beaufort",
        LSAD: "06",
        ALAND: 2154872483,
        AWATER: 338892301
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.174, 35.732830999105204],
            [-76.985842, 35.65884799910598],
            [-76.847264, 35.71998199910535],
            [-76.844279, 35.705716999105512],
            [-76.637513, 35.705214999105493],
            [-76.588191, 35.620653999106388],
            [-76.467761, 35.556503999107065],
            [-76.509605, 35.51835599910747],
            [-76.599085, 35.519959999107449],
            [-76.522131, 35.353622999109234],
            [-76.590497, 35.361856999109143],
            [-76.632561, 35.235323999110527],
            [-76.845505, 35.216058999110743],
            [-76.895919, 35.253888999110323],
            [-76.958887, 35.301012999109808],
            [-77.189115, 35.418361999108548],
            [-77.176178, 35.518811999107477],
            [-77.100803, 35.551454999107115],
            [-77.195772, 35.699778999105561],
            [-77.174, 35.732830999105204]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "021",
        COUNTYNS: "01804490",
        AFFGEOID: "0500000US72021",
        GEOID: "72021",
        NAME: "Bayamón",
        LSAD: "13",
        ALAND: 114816256,
        AWATER: 477626
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.204734, 18.31603499943644],
            [-66.197293, 18.360198999435273],
            [-66.19698, 18.389288999434505],
            [-66.169516, 18.430997999433405],
            [-66.13158, 18.424655999433572],
            [-66.121725, 18.414459999433838],
            [-66.143861, 18.279589999437405],
            [-66.190676, 18.258839999437949],
            [-66.206135, 18.276659999437481],
            [-66.204734, 18.31603499943644]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "423",
        COUNTYNS: "01383997",
        AFFGEOID: "0500000US48423",
        GEOID: "48423",
        NAME: "Smith",
        LSAD: "06",
        ALAND: 2386701635,
        AWATER: 73066272
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.594541, 32.687025999141952],
            [-95.469093, 32.605641999143067],
            [-95.413061, 32.607110999143053],
            [-95.332164, 32.604251999143088],
            [-95.164973, 32.544582999143927],
            [-95.15341, 32.57011499914357],
            [-95.009992, 32.5164779991443],
            [-94.986935, 32.537245999144012],
            [-94.985411, 32.370819999146342],
            [-94.985272, 32.13798899914962],
            [-95.459908, 32.135558999149659],
            [-95.478585, 32.147223999149503],
            [-95.488733, 32.232485999148281],
            [-95.449165, 32.355232999146544],
            [-95.5943, 32.479857999144812],
            [-95.594541, 32.687025999141952]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "049",
        COUNTYNS: "00974123",
        AFFGEOID: "0500000US36049",
        GEOID: "36049",
        NAME: "Lewis",
        LSAD: "06",
        ALAND: 3301307137,
        AWATER: 39612943
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.84056, 43.883975999057419],
            [-75.758157, 43.87878499905743],
            [-75.60367, 43.97136299905732],
            [-75.542898, 43.967794999057304],
            [-75.484528, 44.074171999057214],
            [-75.545886, 44.102977999057181],
            [-75.446124, 44.217654999057075],
            [-75.170159, 44.096958999057179],
            [-75.11016, 43.615228999057784],
            [-75.5335, 43.419755999058111],
            [-75.756213, 43.470387999058033],
            [-75.774553, 43.68888399905768],
            [-75.786759, 43.788319999057542],
            [-75.850534, 43.791885999057534],
            [-75.84056, 43.883975999057419]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "021",
        COUNTYNS: "01101798",
        AFFGEOID: "0500000US40021",
        GEOID: "40021",
        NAME: "Cherokee",
        LSAD: "06",
        ALAND: 1940958254,
        AWATER: 69624105
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.278701, 35.96410199910283],
            [-95.207946, 36.074707999101712],
            [-95.118377, 36.074543999101699],
            [-95.1185, 36.161629999100839],
            [-95.011303, 36.161814999100848],
            [-94.797279, 36.161389999100841],
            [-94.807297, 35.638603999106202],
            [-95.127464, 35.638893999106195],
            [-95.127163, 35.812754999104378],
            [-95.265679, 35.813265999104374],
            [-95.231459, 35.851194999103996],
            [-95.278701, 35.96410199910283]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "013",
        COUNTYNS: "01034235",
        AFFGEOID: "0500000US38013",
        GEOID: "38013",
        NAME: "Burke",
        LSAD: "06",
        ALAND: 2858266385,
        AWATER: 65700367
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.938777019428017, 48.999350249351259],
            [-102.850455, 48.999430999066163],
            [-102.216993, 48.998552999066156],
            [-102.021149424659001, 48.998756604477052],
            [-102.021655, 48.807107999065295],
            [-102.152164, 48.80719999906529],
            [-102.152545, 48.720293999064907],
            [-102.2336, 48.72028999906491],
            [-102.233892, 48.546338999064162],
            [-102.885763, 48.546389999064175],
            [-102.885775, 48.633297999064531],
            [-102.885686, 48.720487999064908],
            [-102.939932, 48.720468999064906],
            [-102.938777019428017, 48.999350249351259]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "041",
        COUNTYNS: "01480111",
        AFFGEOID: "0500000US51041",
        GEOID: "51041",
        NAME: "Chesterfield",
        LSAD: "06",
        ALAND: 1096572169,
        AWATER: 35123317
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.855148, 37.418362999089211],
            [-77.809003, 37.426233999089135],
            [-77.655399, 37.563985999087961],
            [-77.595462, 37.555749999088043],
            [-77.596849, 37.534651999088219],
            [-77.420875, 37.447100999088953],
            [-77.418589, 37.421255999089183],
            [-77.277085, 37.35223099908977],
            [-77.249665, 37.381999999089523],
            [-77.270675, 37.320755999090039],
            [-77.281955, 37.319011999090058],
            [-77.284019, 37.313011999090122],
            [-77.287284, 37.316851999090083],
            [-77.290628, 37.313251999090106],
            [-77.29627, 37.309169999090152],
            [-77.297739, 37.312935999090115],
            [-77.30571, 37.312333999090114],
            [-77.315456, 37.308819999090147],
            [-77.322813, 37.308791999090147],
            [-77.334549, 37.312467999090103],
            [-77.353016, 37.308174999090163],
            [-77.360455, 37.277332999090426],
            [-77.377381, 37.255540999090606],
            [-77.376785, 37.244848999090706],
            [-77.385773, 37.238191999090766],
            [-77.403671, 37.23734599909077],
            [-77.382521, 37.242836999090727],
            [-77.379193, 37.267579999090501],
            [-77.362322, 37.282161999090377],
            [-77.368203, 37.298548999090237],
            [-77.378294, 37.284505999090364],
            [-77.431705, 37.276341999090427],
            [-77.414406, 37.260948999090552],
            [-77.416501, 37.233207999090808],
            [-77.447408, 37.223065999090906],
            [-77.521624, 37.21699399909096],
            [-77.65061, 37.265111999090522],
            [-77.6522, 37.265821999090527],
            [-77.75862, 37.268716999090486],
            [-77.877171, 37.365170999089663],
            [-77.855148, 37.418362999089211]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "161",
        COUNTYNS: "01623021",
        AFFGEOID: "0500000US26161",
        GEOID: "26161",
        NAME: "Washtenaw",
        LSAD: "06",
        ALAND: 1828420670,
        AWATER: 42642348
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.131136, 42.424566999060438],
            [-83.94465, 42.424148999060435],
            [-83.664808, 42.431178999060407],
            [-83.551907, 42.435165999060395],
            [-83.547548, 42.349216999060651],
            [-83.543728, 42.262461999060911],
            [-83.542102, 42.174343999061186],
            [-83.539396, 42.085597999061477],
            [-83.773922, 42.082429999061482],
            [-84.131963, 42.071576999061527],
            [-84.131136, 42.424566999060438]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "063",
        COUNTYNS: "00516878",
        AFFGEOID: "0500000US21063",
        GEOID: "21063",
        NAME: "Elliott",
        LSAD: "06",
        ALAND: 606875936,
        AWATER: 2608208
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.263289, 38.115385999083507],
            [-83.198994, 38.16864299908309],
            [-83.205257, 38.17551299908304],
            [-83.215454, 38.177071999083026],
            [-83.226762, 38.173791999083058],
            [-83.23793, 38.177721999083019],
            [-83.243324, 38.176097999083026],
            [-83.248937, 38.180249999083003],
            [-83.264057, 38.187214999082947],
            [-83.259942, 38.190082999082925],
            [-83.247194, 38.185360999082953],
            [-83.244306, 38.192379999082902],
            [-83.177781, 38.266962999082345],
            [-83.053081, 38.189544999082926],
            [-82.924805, 38.175113999083038],
            [-82.888644, 38.112805999083527],
            [-83.022115, 38.007046999084359],
            [-83.194445, 38.010648999084331],
            [-83.267544, 38.058949999083943],
            [-83.263289, 38.115385999083507]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "231",
        COUNTYNS: "01383901",
        AFFGEOID: "0500000US48231",
        GEOID: "48231",
        NAME: "Hunt",
        LSAD: "06",
        ALAND: 2176470753,
        AWATER: 107957790
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.297227, 32.981751999137977],
            [-96.295413, 33.35193599913309],
            [-95.858723, 33.409529999132332],
            [-95.861778, 33.219329999134814],
            [-95.862521, 32.979570999137998],
            [-95.9453, 32.979876999138],
            [-95.987259, 32.876414999139385],
            [-95.934433, 32.837216999139898],
            [-96.076801, 32.838488999139891],
            [-96.297322, 32.841722999139861],
            [-96.297227, 32.981751999137977]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "113",
        COUNTYNS: "00450377",
        AFFGEOID: "0500000US18113",
        GEOID: "18113",
        NAME: "Noble",
        LSAD: "06",
        ALAND: 1064071452,
        AWATER: 17074609
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.654747, 41.523346999063499],
            [-85.194084, 41.526436999063492],
            [-85.192094, 41.264208999064572],
            [-85.307781, 41.264157999064558],
            [-85.53718, 41.266156999064577],
            [-85.651928, 41.294775999064434],
            [-85.653227, 41.351194999064198],
            [-85.653507, 41.436855999063852],
            [-85.654747, 41.523346999063499]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "195",
        COUNTYNS: "00516944",
        AFFGEOID: "0500000US21195",
        GEOID: "21195",
        NAME: "Pike",
        LSAD: "06",
        ALAND: 2037621821,
        AWATER: 4756763
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.710805, 37.285322999090354],
            [-82.672375, 37.306840999090163],
            [-82.584711, 37.466573999088801],
            [-82.623112, 37.503540999088479],
            [-82.614356, 37.67069599908708],
            [-82.563561, 37.684843999086958],
            [-82.404105, 37.671301999087071],
            [-82.413011, 37.712464999086734],
            [-82.3206749571778, 37.745965842545857],
            [-82.296118, 37.686173999086947],
            [-82.226111, 37.653091999087231],
            [-82.141555, 37.595165999087705],
            [-82.064418, 37.544515999088134],
            [-81.968297, 37.537797999088184],
            [-82.201745, 37.375107999089572],
            [-82.309415, 37.300065999090222],
            [-82.314370452929595, 37.296306250945655],
            [-82.355343, 37.265219999090533],
            [-82.449164, 37.243907999090716],
            [-82.553639544903675, 37.201450373553797],
            [-82.558178, 37.199605999091105],
            [-82.56527570279701, 37.195901105974229],
            [-82.628921, 37.247803999090678],
            [-82.684264, 37.228795999090856],
            [-82.732693, 37.271501999090475],
            [-82.710805, 37.285322999090354]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "061",
        COUNTYNS: "00277295",
        AFFGEOID: "0500000US06061",
        GEOID: "06061",
        NAME: "Placer",
        LSAD: "06",
        ALAND: 3644244131,
        AWATER: 246575767
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.469356, 38.92599199907756],
            [-121.414399, 38.926213999077568],
            [-121.414779, 38.996451999077088],
            [-121.365643, 39.03174299907684],
            [-121.332135, 39.042020999076769],
            [-121.305992, 39.052942999076713],
            [-121.279533, 39.034617999076815],
            [-121.137979, 39.037910999076807],
            [-121.067546, 39.005370999077016],
            [-120.908996, 39.170172999075916],
            [-120.727893, 39.288080999075142],
            [-120.5546, 39.315595999074958],
            [-120.322378, 39.316426999074963],
            [-120.036657, 39.316333999074963],
            [-120.004795512304, 39.316475011033461],
            [-120.005142, 39.291257999075128],
            [-120.003364028833005, 39.165626763144743],
            [-120.002614842913005, 39.112689373172095],
            [-120.001975250867005, 39.0674958735449],
            [-120.143663, 39.067284999076598],
            [-120.184098, 39.031013999076848],
            [-120.435299, 39.028162999076862],
            [-120.470985, 38.965506999077277],
            [-120.563747, 38.913704999077652],
            [-120.642458, 38.94440399907743],
            [-120.746395, 39.010351999076981],
            [-120.812295, 39.00013299907706],
            [-120.858903, 38.952255999077387],
            [-120.940281, 38.962338999077311],
            [-121.037502, 38.915688999077638],
            [-121.040511, 38.915537999077635],
            [-121.057993, 38.847845999078096],
            [-121.141009, 38.711979999079063],
            [-121.30883, 38.722789999078977],
            [-121.381658, 38.72779599907895],
            [-121.484396, 38.7345979990789],
            [-121.469356, 38.92599199907756]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "291",
        COUNTYNS: "00353193",
        AFFGEOID: "0500000US13291",
        GEOID: "13291",
        NAME: "Union",
        LSAD: "06",
        ALAND: 834083256,
        AWATER: 18082753
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.129446962635882, 34.98794695306858],
            [-84.005336733438284, 34.987649583125574],
            [-83.936646, 34.987484999113285],
            [-83.93642734425201, 34.987484382492987],
            [-83.915104, 34.926886999113968],
            [-83.810399, 34.906490999114183],
            [-83.780401, 34.792892999115487],
            [-83.825465, 34.778971999115647],
            [-83.856506, 34.722190999116293],
            [-83.939007, 34.740858999116078],
            [-84.03651, 34.641933999117235],
            [-84.158035, 34.648242999117137],
            [-84.093193, 34.80141099911539],
            [-84.107422, 34.886895999114415],
            [-84.178834, 34.952085999113685],
            [-84.129446962635882, 34.98794695306858]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "127",
        COUNTYNS: "01074076",
        AFFGEOID: "0500000US39127",
        GEOID: "39127",
        NAME: "Perry",
        LSAD: "06",
        ALAND: 1056682426,
        AWATER: 11653682
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.462813, 39.930379999071228],
            [-82.418666, 39.922446999071262],
            [-82.233974, 39.913259999071322],
            [-82.162385, 39.909370999071356],
            [-82.170047, 39.820749999071857],
            [-82.072934, 39.816226999071887],
            [-82.076639, 39.770959999072147],
            [-82.080224, 39.727672999072411],
            [-82.024219, 39.724685999072442],
            [-82.047528, 39.550824999073484],
            [-82.159161, 39.556573999073443],
            [-82.263566, 39.562136999073417],
            [-82.37989, 39.596737999073198],
            [-82.374531, 39.654958999072846],
            [-82.396594, 39.833056999071772],
            [-82.472402, 39.837222999071756],
            [-82.462813, 39.930379999071228]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "121",
        COUNTYNS: "01383847",
        AFFGEOID: "0500000US48121",
        GEOID: "48121",
        NAME: "Denton",
        LSAD: "06",
        ALAND: 2275424546,
        AWATER: 192588409
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.39267, 33.183508999135299],
            [-97.383095, 33.430449999132072],
            [-96.94386, 33.416409999132256],
            [-96.85369, 33.41361299913229],
            [-96.83411, 33.405497999132393],
            [-96.837795, 33.262834999134242],
            [-96.841849, 33.086455999136568],
            [-96.843979, 32.98755399913788],
            [-96.958665, 32.989012999137877],
            [-96.980069, 32.989202999137859],
            [-96.990214, 32.989286999137867],
            [-97.032287, 32.989323999137866],
            [-97.180907, 32.989812999137861],
            [-97.356048, 32.990196999137858],
            [-97.39848, 32.990838999137843],
            [-97.39267, 33.183508999135299]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "095",
        COUNTYNS: "01008564",
        AFFGEOID: "0500000US37095",
        GEOID: "37095",
        NAME: "Hyde",
        LSAD: "06",
        ALAND: 1585933592,
        AWATER: 2192961833
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.637513, 35.705214999105493],
            [-76.405971, 35.697578999105581],
            [-76.269247, 35.690753999105652],
            [-76.285839, 35.604463999106557],
            [-76.213895, 35.598237999106615],
            [-76.144653, 35.702487999105522],
            [-76.027479, 35.668848999105897],
            [-75.901342, 35.668951999105872],
            [-75.814021, 35.50018199910766],
            [-75.749561423962191, 35.185616361303282],
            [-75.757916, 35.183078999111096],
            [-75.912985, 35.119599999111806],
            [-76.013145, 35.061854999112448],
            [-76.065774, 35.124936999111746],
            [-76.304136, 35.201452999110906],
            [-76.454738, 35.334375999109447],
            [-76.522131, 35.353622999109234],
            [-76.599085, 35.519959999107449],
            [-76.509605, 35.51835599910747],
            [-76.467761, 35.556503999107065],
            [-76.588191, 35.620653999106388],
            [-76.637513, 35.705214999105493]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "095",
        COUNTYNS: "00450370",
        AFFGEOID: "0500000US18095",
        GEOID: "18095",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 1170457563,
        AWATER: 2559342
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.861903, 40.218935999069608],
            [-85.862296, 40.378366999068753],
            [-85.578589, 40.379523999068745],
            [-85.576197, 40.077142999070389],
            [-85.576192, 39.945755999071125],
            [-85.862489, 39.943617999071144],
            [-85.861903, 40.218935999069608]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "095",
        COUNTYNS: "01209185",
        AFFGEOID: "0500000US42095",
        GEOID: "42095",
        NAME: "Northampton",
        LSAD: "06",
        ALAND: 957324105,
        AWATER: 19452870
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.577488, 40.799413999066658],
            [-75.474193, 40.814745999066588],
            [-75.383266, 40.831036999066505],
            [-75.29494, 40.862199999066362],
            [-75.217802, 40.928101999066058],
            [-75.123253944208486, 40.965305533453588],
            [-75.09772, 40.926678999066063],
            [-75.065438, 40.88568199906625],
            [-75.090962, 40.849186999066418],
            [-75.108505, 40.791093999066703],
            [-75.111358731672297, 40.789982288281088],
            [-75.177477, 40.764224999066826],
            [-75.192612, 40.715873999067064],
            [-75.191841871748295, 40.677242124508645],
            [-75.191059, 40.637970999067441],
            [-75.189236401355899, 40.609056960189676],
            [-75.258151, 40.58200899906771],
            [-75.333514, 40.53705699906795],
            [-75.404412, 40.58115399906773],
            [-75.430404, 40.674151999067263],
            [-75.481469, 40.655743999067347],
            [-75.530236, 40.736398999066964],
            [-75.608985, 40.787386999066712],
            [-75.577488, 40.799413999066658]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "199",
        COUNTYNS: "00516946",
        AFFGEOID: "0500000US21199",
        GEOID: "21199",
        NAME: "Pulaski",
        LSAD: "06",
        ALAND: 1705274742,
        AWATER: 48546459
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.901112, 37.116296999091837],
            [-84.720712, 37.238327999090764],
            [-84.556461, 37.355728999089749],
            [-84.502932, 37.328800999089978],
            [-84.303191, 37.166117999091391],
            [-84.289076, 37.151739999091525],
            [-84.303016, 37.038395999092529],
            [-84.358024, 36.95939999909325],
            [-84.416816, 36.94691999909336],
            [-84.578158, 36.867132999094089],
            [-84.679165, 36.980913999093062],
            [-84.770172, 36.957734999093276],
            [-84.835712, 36.997611999092911],
            [-84.905778, 37.04718699909246],
            [-84.901112, 37.116296999091837]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "047",
        COUNTYNS: "00835845",
        AFFGEOID: "0500000US31047",
        GEOID: "31047",
        NAME: "Dawson",
        LSAD: "06",
        ALAND: 2623913292,
        AWATER: 16405784
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.224235, 41.046596999065521],
            [-99.425986, 41.046470999065512],
            [-99.426353, 40.699771999067131],
            [-99.417316, 40.670824999067264],
            [-99.444401, 40.677891999067242],
            [-99.643462, 40.684910999067199],
            [-99.699269, 40.699968999067138],
            [-99.981743, 40.700002999067138],
            [-100.223311, 40.700253999067137],
            [-100.224235, 41.046596999065521]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "081",
        COUNTYNS: "00974139",
        AFFGEOID: "0500000US36081",
        GEOID: "36081",
        NAME: "Queens",
        LSAD: "06",
        ALAND: 281697156,
        AWATER: 179401845
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.962478, 40.736801999066955],
            [-73.920999, 40.781748999066735],
            [-73.912456, 40.796095999066672],
            [-73.846654, 40.801023999066651],
            [-73.827583, 40.803935999066631],
            [-73.778958, 40.8117129990666],
            [-73.750295, 40.782477999066735],
            [-73.701633, 40.752492999066881],
            [-73.724722, 40.724313999067014],
            [-73.729176, 40.719166999067042],
            [-73.727047, 40.709496999067092],
            [-73.72563, 40.679587999067238],
            [-73.72807, 40.67160799906727],
            [-73.727814, 40.66322399906732],
            [-73.724874, 40.653446999067363],
            [-73.741661, 40.64219799906742],
            [-73.750621721656302, 40.589319766195388],
            [-73.774928, 40.590758999067674],
            [-73.825494001131077, 40.576149578061646],
            [-73.848338, 40.643520999067405],
            [-73.863787, 40.681113999067229],
            [-73.868717, 40.694621999067166],
            [-73.879616, 40.691105999067183],
            [-73.896466, 40.682335999067227],
            [-73.922711, 40.716477999067038],
            [-73.923127, 40.717023999067052],
            [-73.962478, 40.736801999066955]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "071",
        COUNTYNS: "01622978",
        AFFGEOID: "0500000US26071",
        GEOID: "26071",
        NAME: "Iron",
        LSAD: "06",
        ALAND: 3019973510,
        AWATER: 116746381
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.991236, 46.419703999058001],
            [-88.679392, 46.420138999058004],
            [-88.116571, 46.41995099905801],
            [-88.117407, 46.246617999057719],
            [-88.116856537376094, 45.922811241319998],
            [-88.178008, 45.947110999057337],
            [-88.246307, 45.962982999057346],
            [-88.30952, 45.959368999057347],
            [-88.380183, 45.991653999057384],
            [-88.409864, 45.979687999057354],
            [-88.526673, 46.020821999057411],
            [-88.59386, 46.015131999057417],
            [-88.613063, 45.990626999057383],
            [-88.65776, 45.989286999057384],
            [-88.679132, 46.013537999057412],
            [-88.683232208499376, 46.014465669377707],
            [-88.739994, 46.027307999057427],
            [-88.811948, 46.021608999057413],
            [-88.932768451009593, 46.072107162833483],
            [-88.991217028396008, 46.096536353493512],
            [-88.991012, 46.332307999057853],
            [-88.991236, 46.419703999058001]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "267",
        COUNTYNS: "01383919",
        AFFGEOID: "0500000US48267",
        GEOID: "48267",
        NAME: "Kimble",
        LSAD: "06",
        ALAND: 3240036002,
        AWATER: 493584
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.116234, 30.710365999171028],
            [-99.483869, 30.710770999171018],
            [-99.484493, 30.499640999174357],
            [-99.303996, 30.499831999174354],
            [-99.301718, 30.286652999177782],
            [-99.754142, 30.290697999177702],
            [-100.116461, 30.290295999177722],
            [-100.116234, 30.710365999171028]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "371",
        COUNTYNS: "01383971",
        AFFGEOID: "0500000US48371",
        GEOID: "48371",
        NAME: "Pecos",
        LSAD: "06",
        ALAND: 12338274939,
        AWATER: 2532275
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.585084, 30.766469999170152],
            [-103.01104, 31.37130599916086],
            [-102.933578, 31.350080999161175],
            [-102.826735, 31.266880999162428],
            [-102.767365, 31.293802999162036],
            [-102.676104, 31.330083999161477],
            [-102.600677, 31.26709699916243],
            [-102.523063, 31.262697999162505],
            [-102.429545, 31.193556999163558],
            [-102.388804, 31.087155999165187],
            [-102.292681, 31.036676999165962],
            [-102.202032, 31.031025999166033],
            [-102.191731, 31.003787999166462],
            [-101.98364, 30.98716999916671],
            [-101.883436, 30.925646999167672],
            [-101.80742, 30.699503999171188],
            [-101.76842, 30.65307699917193],
            [-101.768377, 30.652937999171925],
            [-101.769353, 30.653368999171924],
            [-101.997614, 30.654085999171915],
            [-102.000408, 30.654932999171908],
            [-102.031813, 30.655502999171897],
            [-102.032467, 30.655384999171893],
            [-102.033231, 30.655495999171887],
            [-102.034002, 30.655432999171893],
            [-102.034385, 30.655267999171897],
            [-102.137778, 30.655981999171875],
            [-102.138406, 30.597520999172804],
            [-102.342986, 30.598758999172791],
            [-102.343085, 30.284115999177814],
            [-102.566937, 30.283268999177839],
            [-102.567049, 30.052807999181574],
            [-103.439976, 30.665938999171729],
            [-103.585084, 30.766469999170152]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "043",
        COUNTYNS: "01074034",
        AFFGEOID: "0500000US39043",
        GEOID: "39043",
        NAME: "Erie",
        LSAD: "06",
        ALAND: 651357083,
        AWATER: 969654879
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.952224, 41.458238999063774],
            [-82.73044, 41.479342999063682],
            [-82.690567160664898, 41.496705794501807],
            [-82.687921, 41.492323999063636],
            [-82.616952, 41.428424999063878],
            [-82.533208, 41.391156999064052],
            [-82.460599, 41.38631599906406],
            [-82.361784, 41.426643999063891],
            [-82.348019206891195, 41.427263307798391],
            [-82.345202, 41.357429999064173],
            [-82.345048, 41.353478999064194],
            [-82.342314, 41.283553999064488],
            [-82.841475, 41.290022999064469],
            [-82.844565, 41.36239399906416],
            [-82.847804, 41.430515999063878],
            [-82.952224, 41.458238999063774]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "067",
        COUNTYNS: "01008552",
        AFFGEOID: "0500000US37067",
        GEOID: "37067",
        NAME: "Forsyth",
        LSAD: "06",
        ALAND: 1056115821,
        AWATER: 11818425
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.505532, 36.109154999101371],
            [-80.439104, 36.139948999101065],
            [-80.432241, 36.217994999100277],
            [-80.452322, 36.241418999100048],
            [-80.451696, 36.261501999099856],
            [-80.035119, 36.257183999099894],
            [-80.038554, 36.146462999100997],
            [-80.043238, 36.010757999102353],
            [-80.21373, 36.026807999102189],
            [-80.213842, 35.996698999102499],
            [-80.394307, 35.97276199910273],
            [-80.463592, 36.062540999101834],
            [-80.496283, 36.046544999101997],
            [-80.505532, 36.109154999101371]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "041",
        COUNTYNS: "00659466",
        AFFGEOID: "0500000US27041",
        GEOID: "27041",
        NAME: "Douglas",
        LSAD: "06",
        ALAND: 1649200283,
        AWATER: 215604217
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.76975, 46.107449999057529],
            [-95.14588, 46.106760999057535],
            [-95.139731, 45.773415999057143],
            [-95.13967, 45.758890999057137],
            [-95.758508, 45.759931999057137],
            [-95.76975, 46.107449999057529]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "077",
        COUNTYNS: "01265780",
        AFFGEOID: "0500000US46077",
        GEOID: "46077",
        NAME: "Kingsbury",
        LSAD: "06",
        ALAND: 2155483525,
        AWATER: 81655092
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.853028, 44.544397999056862],
            [-97.492266, 44.54388399905686],
            [-97.128024, 44.543125999056862],
            [-97.129671, 44.195932999057085],
            [-97.370115, 44.194970999057084],
            [-97.849492, 44.195234999057085],
            [-97.85366, 44.195232999057083],
            [-97.853028, 44.544397999056862]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "097",
        COUNTYNS: "01581109",
        AFFGEOID: "0500000US55097",
        GEOID: "55097",
        NAME: "Portage",
        LSAD: "06",
        ALAND: 2074125052,
        AWATER: 56939082
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.844931, 44.68494499905681],
            [-89.223745, 44.681364999056811],
            [-89.224813, 44.24339299905705],
            [-89.59798, 44.245724999057053],
            [-89.724746, 44.247677999057046],
            [-89.726622, 44.511190999056872],
            [-89.84441, 44.511541999056874],
            [-89.844931, 44.68494499905681]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "159",
        COUNTYNS: "01480164",
        AFFGEOID: "0500000US51159",
        GEOID: "51159",
        NAME: "Richmond",
        LSAD: "06",
        ALAND: 495991737,
        AWATER: 64392325
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.936959, 38.077073999083801],
            [-76.877062, 38.124357999083429],
            [-76.764716, 38.0019789990844],
            [-76.63423, 37.966629999084674],
            [-76.556042, 37.931523999084938],
            [-76.507828, 37.838813999085694],
            [-76.564735, 37.83728799908571],
            [-76.668182, 37.778385999086197],
            [-76.783745, 37.895478999085242],
            [-76.909028, 37.988474999084502],
            [-76.936959, 38.077073999083801]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "085",
        COUNTYNS: "01213678",
        AFFGEOID: "0500000US42085",
        GEOID: "42085",
        NAME: "Mercer",
        LSAD: "06",
        ALAND: 1741978098,
        AWATER: 25963051
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.519196690325003, 41.133386501614041],
            [-80.518893, 41.232555999064701],
            [-80.51900013136428, 41.333502143257874],
            [-80.519165169895601, 41.489012189218251],
            [-80.125316, 41.480394999063684],
            [-79.999779, 41.490048999063653],
            [-79.999779, 41.171860999064968],
            [-80.096861, 41.0698039990654],
            [-80.147164, 41.113794999065227],
            [-80.258201, 41.127963999065152],
            [-80.519222086080902, 41.125093565860972],
            [-80.519196690325003, 41.133386501614041]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "113",
        COUNTYNS: "01622999",
        AFFGEOID: "0500000US26113",
        GEOID: "26113",
        NAME: "Missaukee",
        LSAD: "06",
        ALAND: 1462807689,
        AWATER: 23574739
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.334, 44.512302999056871],
            [-84.850929, 44.511068999056867],
            [-84.851705, 44.161374999057131],
            [-85.087403, 44.164241999057118],
            [-85.334777, 44.165117999057124],
            [-85.334, 44.512302999056871]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "025",
        COUNTYNS: "01622955",
        AFFGEOID: "0500000US26025",
        GEOID: "26025",
        NAME: "Calhoun",
        LSAD: "06",
        ALAND: 1829198151,
        AWATER: 31268858
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.298879, 42.419848999060449],
            [-85.071609, 42.421427999060441],
            [-84.718493, 42.42151999906045],
            [-84.709556, 42.070365999061522],
            [-84.826491, 42.07246799906153],
            [-85.293626, 42.071552999061531],
            [-85.298879, 42.419848999060449]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "185",
        COUNTYNS: "00485054",
        AFFGEOID: "0500000US20185",
        GEOID: "20185",
        NAME: "Stafford",
        LSAD: "06",
        ALAND: 2051389756,
        AWATER: 7435417
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.022136, 38.00023599908441],
            [-98.913136, 38.0004509990844],
            [-98.912583, 38.261087999082392],
            [-98.479841, 38.260789999082384],
            [-98.472455, 38.238646999082555],
            [-98.472794, 38.172756999083056],
            [-98.472257, 37.824496999085817],
            [-99.013318, 37.825335999085809],
            [-99.022547, 37.839898999085683],
            [-99.022136, 38.00023599908441]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "069",
        COUNTYNS: "00161560",
        AFFGEOID: "0500000US01069",
        GEOID: "01069",
        NAME: "Houston",
        LSAD: "06",
        ALAND: 1501807104,
        AWATER: 4730565
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.666121, 31.267315999162438],
            [-85.485854, 31.246095999162762],
            [-85.417434, 31.314972999161707],
            [-85.179131, 31.307674999161819],
            [-85.088829963530486, 31.308647754658399],
            [-85.089774, 31.295025999162011],
            [-85.108192, 31.258590999162557],
            [-85.107516, 31.186450999163657],
            [-85.035615, 31.108191999164848],
            [-85.021107562362801, 31.075463871471758],
            [-85.011392, 31.053545999165692],
            [-85.002499, 31.000681999166513],
            [-85.031285, 31.000646999166509],
            [-85.145959, 31.000692999166507],
            [-85.333319, 30.999554999166516],
            [-85.488298288939092, 30.997964622453846],
            [-85.48576, 31.199885999163449],
            [-85.710333, 31.195172999163521],
            [-85.666121, 31.267315999162438]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "081",
        COUNTYNS: "00399297",
        AFFGEOID: "0500000US16081",
        GEOID: "16081",
        NAME: "Teton",
        LSAD: "06",
        ALAND: 1163139958,
        AWATER: 2868476
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.398781, 43.922889999057354],
            [-111.187256, 43.932113999057364],
            [-111.047219183001999, 43.983431291664999],
            [-111.046515, 43.908375999057384],
            [-111.04611, 43.687847999057681],
            [-111.045360560970011, 43.501051450170173],
            [-111.207561, 43.543856999057894],
            [-111.243839, 43.621852999057786],
            [-111.399765, 43.621975999057774],
            [-111.398781, 43.922889999057354]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "127",
        COUNTYNS: "01671513",
        AFFGEOID: "0500000US13127",
        GEOID: "13127",
        NAME: "Glynn",
        LSAD: "06",
        ALAND: 1086361034,
        AWATER: 429423812
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.782363, 31.168147999163946],
            [-81.731694, 31.330047999161486],
            [-81.691911, 31.40052399916042],
            [-81.62429, 31.45298199915964],
            [-81.481696, 31.336087999161393],
            [-81.264377948088395, 31.294599063907519],
            [-81.282842, 31.24432999916278],
            [-81.304957, 31.206172999163361],
            [-81.368241, 31.136533999164417],
            [-81.402096, 31.125382999164586],
            [-81.401267, 31.072780999165396],
            [-81.420474, 31.016702999166263],
            [-81.412518035579495, 30.990834502675053],
            [-81.572692, 31.097268999165017],
            [-81.562606, 31.130284999164516],
            [-81.631624, 31.108116999164849],
            [-81.766322, 31.169594999163909],
            [-81.782363, 31.168147999163946]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "119",
        COUNTYNS: "01008570",
        AFFGEOID: "0500000US37119",
        GEOID: "37119",
        NAME: "Mecklenburg",
        LSAD: "06",
        ALAND: 1356030322,
        AWATER: 56685361
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.058029, 35.073189999112323],
            [-81.036759, 35.122551999111771],
            [-81.042302450138592, 35.146768648579709],
            [-81.005806, 35.157022999111391],
            [-81.01505, 35.268654999110169],
            [-80.972597, 35.350337999109279],
            [-80.922998, 35.360240999109166],
            [-80.95486, 35.400077999108738],
            [-80.94238, 35.455146999108145],
            [-80.948122, 35.491166999107755],
            [-80.907512, 35.514838999107511],
            [-80.784167, 35.506016999107601],
            [-80.766414, 35.401477999108728],
            [-80.693061, 35.346378999109319],
            [-80.66648, 35.26793399911017],
            [-80.599733, 35.234104999110556],
            [-80.550349, 35.20841199911083],
            [-80.77248, 35.033156999112769],
            [-80.840566909021376, 35.001471528507416],
            [-80.903481186712099, 35.072087773158437],
            [-80.906239954082494, 35.075184268877898],
            [-80.93495, 35.107408999111939],
            [-81.041489, 35.044702999112637],
            [-81.058029, 35.073189999112323]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "093",
        COUNTYNS: "01034224",
        AFFGEOID: "0500000US38093",
        GEOID: "38093",
        NAME: "Stutsman",
        LSAD: "06",
        ALAND: 5754301968,
        AWATER: 197312587
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.480726, 47.327075999060007],
            [-99.26628, 47.327193999059993],
            [-98.499246, 47.326537999060001],
            [-98.467265, 47.325357999059996],
            [-98.467476, 47.240452999059762],
            [-98.439466, 46.979657999059128],
            [-98.439056, 46.631119999058377],
            [-99.036625, 46.630210999058377],
            [-99.44972, 46.63162999905839],
            [-99.446423, 46.980888999059133],
            [-99.481085, 46.980693999059127],
            [-99.480726, 47.327075999060007]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "491",
        COUNTYNS: "01384031",
        AFFGEOID: "0500000US48491",
        GEOID: "48491",
        NAME: "Williamson",
        LSAD: "06",
        ALAND: 2896480321,
        AWATER: 41629535
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.96286, 30.785640999169846],
            [-97.828512, 30.906187999167972],
            [-97.62491, 30.870333999168523],
            [-97.315507, 30.75237099917037],
            [-97.271418, 30.735691999170623],
            [-97.155219, 30.457343999175048],
            [-97.334463, 30.40284299917591],
            [-97.369539, 30.419562999175643],
            [-97.596236, 30.501512999174334],
            [-97.773458, 30.430653999175473],
            [-97.848384, 30.47266199917479],
            [-97.857505, 30.501609999174324],
            [-97.956734, 30.628248999172328],
            [-98.049886, 30.62415499917239],
            [-97.96286, 30.785640999169846]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "009",
        COUNTYNS: "00465194",
        AFFGEOID: "0500000US19009",
        GEOID: "19009",
        NAME: "Audubon",
        LSAD: "06",
        ALAND: 1147264553,
        AWATER: 1152260
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.092861, 41.863373999062247],
            [-94.744876, 41.862393999062242],
            [-94.744204, 41.602184999063205],
            [-94.700745, 41.602183999063215],
            [-94.700629, 41.504147999063584],
            [-95.04077, 41.504689999063579],
            [-95.040748, 41.602151999063203],
            [-95.09252, 41.602054999063213],
            [-95.092861, 41.863373999062247]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "025",
        COUNTYNS: "00835834",
        AFFGEOID: "0500000US31025",
        GEOID: "31025",
        NAME: "Cass",
        LSAD: "06",
        ALAND: 1443578140,
        AWATER: 22073649
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.463861, 41.016068999065652],
            [-96.349471, 41.015210999065651],
            [-96.319191, 41.044997999065529],
            [-96.218939, 40.994307999065747],
            [-96.056256, 41.065355999065432],
            [-96.005191, 41.060623999065463],
            [-95.978778, 41.05336199906548],
            [-95.8647847640853, 41.052845503678988],
            [-95.865878, 41.017402999065652],
            [-95.828329, 40.972377999065849],
            [-95.837774, 40.924711999066076],
            [-95.818727278569881, 40.897948065472697],
            [-95.810709, 40.886680999066243],
            [-95.841309, 40.845603999066441],
            [-95.834243816714405, 40.783784386109836],
            [-96.463764, 40.783959999066738],
            [-96.463861, 41.016068999065652]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "087",
        COUNTYNS: "01035896",
        AFFGEOID: "0500000US38087",
        GEOID: "38087",
        NAME: "Slope",
        LSAD: "06",
        ALAND: 3146544262,
        AWATER: 10978000
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.045469, 46.324544999057842],
            [-104.045045, 46.509787999058162],
            [-104.045125413323007, 46.540929927607216],
            [-103.800881, 46.540746999058207],
            [-103.800825, 46.629562999058379],
            [-103.60921, 46.629796999058378],
            [-103.231569, 46.629763999058369],
            [-102.928003, 46.630064999058369],
            [-102.924547, 46.281518999057774],
            [-102.995454, 46.280712999057776],
            [-104.045465958927991, 46.280188047268467],
            [-104.045469, 46.324544999057842]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "117",
        COUNTYNS: "01209189",
        AFFGEOID: "0500000US42117",
        GEOID: "42117",
        NAME: "Tioga",
        LSAD: "06",
        ALAND: 2936469292,
        AWATER: 8257078
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.610020114476001, 41.999149853141375],
            [-77.007635, 42.000847999061769],
            [-76.965728759426696, 42.000783394108652],
            [-76.926846362954294, 42.000723450879079],
            [-76.874714, 41.596918999063227],
            [-76.960481, 41.551516999063395],
            [-77.599278, 41.542270999063433],
            [-77.602566, 41.687373999062871],
            [-77.606887, 41.882539999062175],
            [-77.610020114476001, 41.999149853141375]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "077",
        COUNTYNS: "00326666",
        AFFGEOID: "0500000US13077",
        GEOID: "13077",
        NAME: "Coweta",
        LSAD: "06",
        ALAND: 1141973686,
        AWATER: 12727950
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.015358, 33.425505999132127],
            [-84.935378, 33.43742099913198],
            [-84.850713, 33.511456999131035],
            [-84.699178, 33.511372999131041],
            [-84.627734, 33.511037999131034],
            [-84.60954, 33.502510999131147],
            [-84.62713, 33.43982099913196],
            [-84.497527, 33.257421999134316],
            [-84.502352, 33.2210549991348],
            [-84.85236, 33.223589999134774],
            [-84.862359, 33.191172999135183],
            [-84.862135, 33.223857999134765],
            [-84.939015, 33.224692999134746],
            [-84.958646, 33.277052999134057],
            [-85.015358, 33.425505999132127]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "183",
        COUNTYNS: "00450369",
        AFFGEOID: "0500000US18183",
        GEOID: "18183",
        NAME: "Whitley",
        LSAD: "06",
        ALAND: 869120483,
        AWATER: 6073937
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.686574, 41.178375999064947],
            [-85.651928, 41.294775999064434],
            [-85.53718, 41.266156999064577],
            [-85.307781, 41.264157999064558],
            [-85.338552, 41.179119999064937],
            [-85.335643, 41.005249999065697],
            [-85.643841, 41.002304999065721],
            [-85.684181, 41.046715999065512],
            [-85.686574, 41.178375999064947]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "121",
        COUNTYNS: "00835882",
        AFFGEOID: "0500000US31121",
        GEOID: "31121",
        NAME: "Merrick",
        LSAD: "06",
        ALAND: 1258350306,
        AWATER: 28301129
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.286584, 41.393897999064031],
            [-98.275408, 41.264340999064558],
            [-98.101546, 41.264040999064562],
            [-98.053137, 41.293122999064444],
            [-97.705497, 41.309133999064393],
            [-97.703765, 41.394875999064027],
            [-97.598461, 41.395067999064011],
            [-97.598253, 41.333118999064283],
            [-97.604363, 41.313850999064364],
            [-97.658554, 41.3048619990644],
            [-97.828256, 41.173438999064949],
            [-97.874073, 41.123147999065175],
            [-98.010548, 41.072760999065409],
            [-98.282578, 40.868676999066331],
            [-98.282395, 41.026596999065603],
            [-98.287168, 41.046362999065522],
            [-98.286584, 41.393897999064031]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "191",
        COUNTYNS: "01383881",
        AFFGEOID: "0500000US48191",
        GEOID: "48191",
        NAME: "Hall",
        LSAD: "06",
        ALAND: 2288233624,
        AWATER: 53366404
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.944939, 34.748280999115991],
            [-100.540703, 34.747722999116007],
            [-100.415895, 34.747524999116003],
            [-100.417783, 34.313523999121095],
            [-100.51734, 34.314101999121092],
            [-100.946132, 34.312758999121108],
            [-100.944939, 34.748280999115991]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "053",
        COUNTYNS: "01265765",
        AFFGEOID: "0500000US46053",
        GEOID: "46053",
        NAME: "Gregory",
        LSAD: "06",
        ALAND: 2628707523,
        AWATER: 99900775
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.534481, 43.499800999057975],
            [-99.297998, 43.499668999057974],
            [-99.310073, 43.467934999058023],
            [-99.152889, 43.426907999058109],
            [-99.069285, 43.3206879990583],
            [-98.981253, 43.2589449990584],
            [-98.896783, 43.246908999058427],
            [-98.868762, 43.1620089990586],
            [-98.773406, 43.145918999058637],
            [-98.69046, 43.076555999058783],
            [-98.524871, 43.039346999058864],
            [-98.49855, 42.998559999058955],
            [-98.847992, 42.998254999058972],
            [-99.254455012316583, 42.998220925845651],
            [-99.534055014809013, 42.998197487377062],
            [-99.534481, 43.499800999057975]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "145",
        COUNTYNS: "00069906",
        AFFGEOID: "0500000US05145",
        GEOID: "05145",
        NAME: "White",
        LSAD: "06",
        ALAND: 2680852086,
        AWATER: 18293044
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.111463, 35.36272499910914],
            [-91.851958, 35.358286999109183],
            [-91.850144, 35.436441999108354],
            [-91.795855, 35.455573999108147],
            [-91.793958, 35.534113999107298],
            [-91.58188, 35.530446999107347],
            [-91.584687, 35.442310999108287],
            [-91.347257, 35.439105999108321],
            [-91.356598, 35.408063999108656],
            [-91.438202, 35.369377999109069],
            [-91.437898, 35.297104999109855],
            [-91.407782, 35.212654999110775],
            [-91.465114, 35.089453999112138],
            [-91.584005, 35.09158399911211],
            [-91.608065, 35.02074099911291],
            [-91.695463, 35.062343999112443],
            [-91.802505, 35.030424999112803],
            [-91.87888, 35.02457699911286],
            [-92.012735, 35.07625099911229],
            [-92.119719, 35.066492999112398],
            [-92.111463, 35.36272499910914]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "035",
        COUNTYNS: "01581077",
        AFFGEOID: "0500000US55035",
        GEOID: "55035",
        NAME: "Eau Claire",
        LSAD: "06",
        ALAND: 1652211293,
        AWATER: 18848535
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.650455, 44.855950999056773],
            [-90.922247, 44.857308999056769],
            [-90.92235, 44.596292999056828],
            [-91.043815, 44.596639999056833],
            [-91.165619, 44.596986999056831],
            [-91.529101, 44.596196999056829],
            [-91.650248, 44.596648999056839],
            [-91.650361, 44.683632999056805],
            [-91.650455, 44.855950999056773]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "001",
        COUNTYNS: "01034210",
        AFFGEOID: "0500000US38001",
        GEOID: "38001",
        NAME: "Adams",
        LSAD: "06",
        ALAND: 2557960600,
        AWATER: 2833854
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.995454, 46.280712999057776],
            [-102.924547, 46.281518999057774],
            [-102.497449, 46.283195999057774],
            [-102.497475, 46.206076999057665],
            [-101.997888, 46.205479999057665],
            [-101.998575, 46.053460999057464],
            [-101.998617078234005, 45.944536976414234],
            [-102.000679921018005, 45.944538391784825],
            [-102.087555, 45.944597999057322],
            [-102.32823, 45.94480599905733],
            [-102.550947, 45.945014999057321],
            [-102.704871, 45.945071999057319],
            [-102.880252, 45.945068999057327],
            [-102.942069961191009, 45.945094410410519],
            [-102.995668019138009, 45.945116442828827],
            [-102.995454, 46.280712999057776]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "061",
        COUNTYNS: "00161556",
        AFFGEOID: "0500000US01061",
        GEOID: "01061",
        NAME: "Geneva",
        LSAD: "06",
        ALAND: 1487870866,
        AWATER: 11604964
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.193476, 31.192212999163566],
            [-86.125405, 31.182056999163734],
            [-85.791402, 31.196348999163508],
            [-85.710333, 31.195172999163521],
            [-85.48576, 31.199885999163449],
            [-85.488298288939092, 30.997964622453846],
            [-85.498001585089497, 30.997865048527952],
            [-85.579497576338284, 30.997028747577467],
            [-85.749715, 30.995281999166586],
            [-85.893632, 30.993454999166616],
            [-86.035038104950601, 30.99374960646891],
            [-86.187248048981303, 30.994066722628812],
            [-86.190559, 31.013629999166305],
            [-86.193476, 31.192212999163566]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "159",
        COUNTYNS: "01623020",
        AFFGEOID: "0500000US26159",
        GEOID: "26159",
        NAME: "Van Buren",
        LSAD: "06",
        ALAND: 1574166310,
        AWATER: 1248671230
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.356218, 42.254165999060945],
            [-86.284448, 42.394562999060518],
            [-86.276994044531506, 42.419310314986745],
            [-85.764697, 42.420714999060451],
            [-85.762943, 42.069326999061538],
            [-86.22294, 42.071483999061527],
            [-86.223563, 42.24337899906098],
            [-86.3663791270383, 42.243107726241867],
            [-86.356218, 42.254165999060945]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "043",
        COUNTYNS: "00835843",
        AFFGEOID: "0500000US31043",
        GEOID: "31043",
        NAME: "Dakota",
        LSAD: "06",
        ALAND: 684416197,
        AWATER: 8199748
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.726618, 42.280416999060861],
            [-96.722231, 42.524651999060147],
            [-96.627945456901699, 42.527096138201941],
            [-96.611489, 42.506087999060206],
            [-96.525142, 42.510233999060183],
            [-96.501321, 42.482748999060263],
            [-96.445508, 42.490629999060239],
            [-96.381307, 42.461693999060323],
            [-96.411808, 42.41089399906047],
            [-96.407998, 42.337407999060687],
            [-96.351957192496002, 42.280894703604154],
            [-96.380298, 42.276350999060867],
            [-96.726661, 42.278004999060862],
            [-96.726618, 42.280416999060861]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "111",
        COUNTYNS: "01209188",
        AFFGEOID: "0500000US42111",
        GEOID: "42111",
        NAME: "Somerset",
        LSAD: "06",
        ALAND: 2782597159,
        AWATER: 17002839
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.363739, 39.947777999071121],
            [-79.293682, 40.040412999070604],
            [-79.183619, 40.110685999070199],
            [-79.088656, 40.241258999069494],
            [-79.055983, 40.285088999069252],
            [-78.927601, 40.285040999069246],
            [-78.884862, 40.244630999069471],
            [-78.658409, 40.242932999069481],
            [-78.739847, 40.069570999070429],
            [-78.763202, 40.056744999070496],
            [-78.757241, 39.823483999071833],
            [-78.808299529621593, 39.723070955349328],
            [-78.928416129888902, 39.722998586513341],
            [-79.045576, 39.722927999072432],
            [-79.392458405022396, 39.721439357712548],
            [-79.409959, 39.734977999072356],
            [-79.356757, 39.781476999072083],
            [-79.417558, 39.853775999071658],
            [-79.363739, 39.947777999071121]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "151",
        COUNTYNS: "00424277",
        AFFGEOID: "0500000US17151",
        GEOID: "17151",
        NAME: "Pope",
        LSAD: "06",
        ALAND: 955109669,
        AWATER: 14328523
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.708546, 37.599276999087671],
            [-88.412112, 37.599911999087666],
            [-88.415902178775681, 37.421220636808073],
            [-88.418594, 37.421986999089171],
            [-88.465861, 37.40054699908935],
            [-88.486947, 37.339595999089894],
            [-88.514661, 37.290947999090314],
            [-88.471753, 37.22015499909093],
            [-88.424776, 37.14990099909155],
            [-88.444605, 37.098600999092],
            [-88.476127, 37.068222999092264],
            [-88.483803276979998, 37.068080268987771],
            [-88.490399446324702, 37.067957622040169],
            [-88.490336, 37.159357999091455],
            [-88.71065, 37.337089999089905],
            [-88.708546, 37.599276999087671]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "025",
        COUNTYNS: "01101800",
        AFFGEOID: "0500000US40025",
        GEOID: "40025",
        NAME: "Cimarron",
        LSAD: "06",
        ALAND: 4752113920,
        AWATER: 15923999
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.002518, 36.675185999095874],
            [-103.001964, 36.909572999093712],
            [-103.002199, 37.000103999092886],
            [-102.841989, 36.999597999092892],
            [-102.698142, 36.995148999092919],
            [-102.355288, 36.994505999092944],
            [-102.04224, 36.993082999092934],
            [-102.028204191044992, 36.993145146201243],
            [-102.032339018963, 36.500065672107631],
            [-102.162463, 36.500325999097534],
            [-103.002434, 36.500396999097525],
            [-103.002188, 36.60271599909656],
            [-103.002518, 36.675185999095874]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "161",
        COUNTYNS: "01480165",
        AFFGEOID: "0500000US51161",
        GEOID: "51161",
        NAME: "Roanoke",
        LSAD: "06",
        ALAND: 648883978,
        AWATER: 1762701
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.246508, 37.353941999089756],
            [-80.074119, 37.422468999089183],
            [-80.062622, 37.408324999089281],
            [-79.945616, 37.362996999089688],
            [-79.865099, 37.328416999089988],
            [-79.847476, 37.30935199909014],
            [-79.858405, 37.267154999090508],
            [-79.847217, 37.225405999090874],
            [-79.961627, 37.137541999091653],
            [-80.082765, 37.160907999091442],
            [-80.128674, 37.122900999091783],
            [-80.178125, 37.113399999091875],
            [-80.189592, 37.233448999090804],
            [-80.262184, 37.341525999089868],
            [-80.246508, 37.353941999089756]
          ],
          [
            [-80.081631, 37.265951999090532],
            [-80.033464, 37.262889999090547],
            [-79.946817, 37.213036999090981],
            [-79.87855, 37.291889999090287],
            [-79.974023, 37.335418999089917],
            [-80.022226, 37.308337999090149],
            [-80.12049, 37.287052999090342],
            [-80.081631, 37.265951999090532]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "247",
        COUNTYNS: "00357592",
        AFFGEOID: "0500000US13247",
        GEOID: "13247",
        NAME: "Rockdale",
        LSAD: "06",
        ALAND: 336212809,
        AWATER: 5918272
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.184143, 33.64615699912931],
            [-84.115791, 33.61466599912972],
            [-84.023713, 33.752807999127981],
            [-83.982033, 33.786053999127553],
            [-83.914823, 33.744202999128071],
            [-83.930863, 33.651822999129244],
            [-84.003278, 33.554874999130476],
            [-84.044493, 33.52577599913085],
            [-84.04972, 33.541080999130649],
            [-84.097471, 33.56000899913041],
            [-84.125073, 33.561247999130387],
            [-84.184143, 33.64615699912931]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "027",
        COUNTYNS: "01719614",
        AFFGEOID: "0500000US30027",
        GEOID: "30027",
        NAME: "Fergus",
        LSAD: "06",
        ALAND: 11238811043,
        AWATER: 27700320
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.213596, 47.416656999060251],
            [-110.159579, 47.415311999060243],
            [-110.030854, 47.479550999060429],
            [-109.850793, 47.539141999060611],
            [-109.836861, 47.712040999061131],
            [-109.722711, 47.702620999061104],
            [-109.691058, 47.728553999061184],
            [-109.538677, 47.738863999061216],
            [-109.425315, 47.720152999061163],
            [-109.274171, 47.793238999061401],
            [-109.132768, 47.781121999061355],
            [-108.978383, 47.802657999061431],
            [-108.891115, 47.734455999061204],
            [-108.771619, 47.645522999060923],
            [-108.526067, 47.620823999060846],
            [-108.335819, 47.567399999060683],
            [-108.313048, 47.583649999060732],
            [-108.315481, 47.315151999059971],
            [-108.590835, 47.30651599905994],
            [-108.591111, 47.270134999059842],
            [-108.719189, 47.270341999059852],
            [-108.736838, 47.098217999059393],
            [-108.608954, 47.098125999059413],
            [-108.611116, 46.836775999058801],
            [-108.631462, 46.749391999058624],
            [-109.00957, 46.75070199905862],
            [-109.389208, 46.751350999058616],
            [-109.389163, 46.693624999058507],
            [-109.745745, 46.694096999058509],
            [-109.756666, 46.78038899905868],
            [-109.835424, 46.809160999058747],
            [-109.755423, 46.896022999058935],
            [-109.756568, 47.185693999059623],
            [-109.871985, 47.185322999059629],
            [-109.872475, 47.228882999059742],
            [-110.000024, 47.228715999059737],
            [-110.000289, 47.272132999059849],
            [-110.127699, 47.271909999059858],
            [-110.127952, 47.359019999060095],
            [-110.213285, 47.358770999060091],
            [-110.213596, 47.416656999060251]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "169",
        COUNTYNS: "01383870",
        AFFGEOID: "0500000US48169",
        GEOID: "48169",
        NAME: "Garza",
        LSAD: "06",
        ALAND: 2313930840,
        AWATER: 7215530
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.556884, 33.394759999132532],
            [-101.038788, 33.397213999132497],
            [-101.03866, 32.970224999138125],
            [-101.173378, 32.963596999138211],
            [-101.557434, 32.961024999138253],
            [-101.556884, 33.394759999132532]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "135",
        COUNTYNS: "01266975",
        AFFGEOID: "0500000US46135",
        GEOID: "46135",
        NAME: "Yankton",
        LSAD: "06",
        ALAND: 1349873574,
        AWATER: 28676624
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.637496, 43.168769999058583],
            [-97.399191, 43.169418999058585],
            [-97.160544, 43.169977999058581],
            [-97.160543, 43.083144999058774],
            [-97.16036, 42.811794999059394],
            [-97.165070392373806, 42.791616640527145],
            [-97.213957, 42.820142999059371],
            [-97.237868, 42.853138999059297],
            [-97.302075, 42.865659999059261],
            [-97.341181, 42.855881999059299],
            [-97.417066, 42.865917999059256],
            [-97.452177, 42.846047999059309],
            [-97.484916876342012, 42.850003213927209],
            [-97.515948, 42.853751999059291],
            [-97.59926, 42.85622899905929],
            [-97.635442090441501, 42.851809074244791],
            [-97.637496, 43.168769999058583]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "057",
        COUNTYNS: "01480118",
        AFFGEOID: "0500000US51057",
        GEOID: "51057",
        NAME: "Essex",
        LSAD: "06",
        ALAND: 665909923,
        AWATER: 74784989
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.115997, 38.149930999083224],
            [-77.073796, 38.141330999083308],
            [-77.061695, 38.161630999083158],
            [-77.031794, 38.091332999083683],
            [-76.936959, 38.077073999083801],
            [-76.909028, 37.988474999084502],
            [-76.783745, 37.895478999085242],
            [-76.668182, 37.778385999086197],
            [-76.723086, 37.756696999086373],
            [-76.751072, 37.728604999086606],
            [-76.804423, 37.794891999086062],
            [-76.943221, 37.782954999086158],
            [-77.040713, 37.868936999085449],
            [-77.070115, 37.964769999084695],
            [-77.1701, 38.079542999083792],
            [-77.115997, 38.149930999083224]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "071",
        COUNTYNS: "00695759",
        AFFGEOID: "0500000US28071",
        GEOID: "28071",
        NAME: "Lafayette",
        LSAD: "06",
        ALAND: 1636137315,
        AWATER: 123056595
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.721341, 34.554273999118251],
            [-89.668448, 34.554366999118244],
            [-89.476642, 34.554167999118249],
            [-89.424459, 34.519128999118657],
            [-89.32582, 34.525973999118577],
            [-89.246133, 34.495632999118932],
            [-89.246339, 34.379343999120309],
            [-89.245485, 34.161185999122928],
            [-89.509748, 34.162081999122925],
            [-89.50944, 34.190959999122562],
            [-89.72105, 34.192544999122546],
            [-89.721341, 34.554273999118251]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "173",
        COUNTYNS: "00485049",
        AFFGEOID: "0500000US20173",
        GEOID: "20173",
        NAME: "Sedgwick",
        LSAD: "06",
        ALAND: 2583496854,
        AWATER: 30113787
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.807823, 37.733854999086553],
            [-97.698692, 37.735055999086548],
            [-97.701969, 37.911324999085124],
            [-97.152476, 37.912732999085108],
            [-97.153331, 37.475543999088714],
            [-97.8076, 37.474183999088723],
            [-97.807823, 37.733854999086553]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "029",
        COUNTYNS: "00198130",
        AFFGEOID: "0500000US08029",
        GEOID: "08029",
        NAME: "Delta",
        LSAD: "06",
        ALAND: 2958099789,
        AWATER: 16777978
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-108.378953, 38.829107999078225],
            [-108.140323, 38.908766999077677],
            [-108.094786, 38.979683999077203],
            [-107.982173, 39.059877999076654],
            [-107.85824, 39.079803999076518],
            [-107.76549, 39.043293999076759],
            [-107.637893, 39.142019999076098],
            [-107.500606, 39.217915999075608],
            [-107.500655, 38.66855299907936],
            [-108.378698, 38.668096999079381],
            [-108.378953, 38.829107999078225]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "187",
        COUNTYNS: "01026331",
        AFFGEOID: "0500000US37187",
        GEOID: "37187",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 897456200,
        AWATER: 195282200
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.847264, 35.71998199910535],
            [-76.777404, 35.857838999103912],
            [-76.760598, 35.864886999103838],
            [-76.683751, 35.953100999102944],
            [-76.606214, 35.978050999102692],
            [-76.511899, 35.98072599910266],
            [-76.396794, 36.026442999102201],
            [-76.358393, 36.020018999102255],
            [-76.380009, 35.914665999103327],
            [-76.353554, 35.861300999103875],
            [-76.405603, 35.786293999104643],
            [-76.405971, 35.697578999105581],
            [-76.637513, 35.705214999105493],
            [-76.844279, 35.705716999105512],
            [-76.847264, 35.71998199910535]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "157",
        COUNTYNS: "00450393",
        AFFGEOID: "0500000US18157",
        GEOID: "18157",
        NAME: "Tippecanoe",
        LSAD: "06",
        ALAND: 1292138000,
        AWATER: 11256312
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.095357, 40.562894999067815],
            [-86.771293, 40.562081999067814],
            [-86.695353, 40.561873999067807],
            [-86.694665, 40.432155999068478],
            [-86.695637, 40.214366999069632],
            [-87.092563, 40.214805999069632],
            [-87.092148, 40.36661199906883],
            [-87.093676, 40.475744999068247],
            [-87.095357, 40.562894999067815]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "003",
        COUNTYNS: "01716521",
        AFFGEOID: "0500000US30003",
        GEOID: "30003",
        NAME: "Big Horn",
        LSAD: "06",
        ALAND: 12942465259,
        AWATER: 49210403
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-108.69962, 45.348945999056852],
            [-108.682223, 45.433131999056904],
            [-108.645325, 45.46407299905691],
            [-108.316374, 45.460508999056906],
            [-108.316245, 45.489502999056938],
            [-108.193191, 45.489525999056923],
            [-108.069752, 45.518534999056946],
            [-108.069572, 45.783506999057167],
            [-108.047352, 45.899478999057287],
            [-107.881846, 45.899232999057276],
            [-107.881873, 45.928148999057314],
            [-107.798821, 45.956722999057334],
            [-107.798753, 45.985679999057368],
            [-107.674468, 45.985758999057374],
            [-107.674454, 46.043609999057459],
            [-107.511534, 46.043402999057442],
            [-107.424929, 46.043373999057444],
            [-107.425128, 45.956673999057344],
            [-107.176358, 45.956760999057344],
            [-107.17636, 45.913322999057293],
            [-107.051946, 45.913488999057293],
            [-107.052159, 45.870051999057253],
            [-106.939095, 45.870040999057245],
            [-106.939077, 45.831607999057191],
            [-106.877217, 45.831713999057214],
            [-106.9128, 45.788220999057152],
            [-106.912959, 45.683914999057066],
            [-106.727573, 45.676468999057057],
            [-106.768676, 45.352825999056847],
            [-106.768014, 45.179727999056787],
            [-106.279709, 45.179727999056787],
            [-106.263684, 45.173352999056796],
            [-106.263715092464992, 44.993788432058267],
            [-106.888773, 44.99588499905677],
            [-107.351441, 45.001406999056755],
            [-107.911522788162003, 45.001544003668165],
            [-107.997353, 45.001564999056761],
            [-108.248525185308011, 45.000629826474267],
            [-108.258603, 45.008179999056757],
            [-108.127979, 45.217910999056805],
            [-108.098228, 45.220422999056801],
            [-108.699616, 45.217708999056804],
            [-108.69962, 45.348945999056852]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "095",
        COUNTYNS: "01101832",
        AFFGEOID: "0500000US40095",
        GEOID: "40095",
        NAME: "Marshall",
        LSAD: "06",
        ALAND: 962336585,
        AWATER: 143420175
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.968415, 34.172616999122788],
            [-96.933671, 34.172552999122786],
            [-96.620784, 34.162040999122922],
            [-96.585355, 34.114458999123492],
            [-96.548028, 34.063301999124128],
            [-96.624803, 34.009723999124773],
            [-96.57701, 33.96440599912534],
            [-96.594674307377105, 33.883018656524648],
            [-96.659896, 33.916665999125918],
            [-96.682103, 33.87664499912642],
            [-96.712422, 33.831632999126981],
            [-96.776766, 33.841975999126852],
            [-96.794276, 33.868885999126512],
            [-96.850593, 33.847210999126801],
            [-96.897194, 33.902953999126098],
            [-96.905253, 33.947218999125546],
            [-96.934343303133005, 33.945588928378363],
            [-96.934791, 34.012941999124727],
            [-96.969627, 34.071019999124019],
            [-96.968415, 34.172616999122788]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "085",
        COUNTYNS: "01669428",
        AFFGEOID: "0500000US30085",
        GEOID: "30085",
        NAME: "Roosevelt",
        LSAD: "06",
        ALAND: 6097970108,
        AWATER: 37765673
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.804599, 48.219036999062887],
            [-105.804436, 48.563357999064245],
            [-104.973354, 48.563189999064242],
            [-104.757619, 48.563126999064245],
            [-104.757643, 48.476555999063891],
            [-104.627726, 48.476348999063894],
            [-104.62776, 48.389361999063546],
            [-104.046782161706005, 48.389300730209044],
            [-104.045692, 48.241414999062968],
            [-104.044093272821016, 47.996107173249186],
            [-104.098921, 48.000018999062085],
            [-104.14247, 48.055004999062291],
            [-104.245936, 48.031004999062205],
            [-104.544517, 48.126552999062547],
            [-104.681633, 48.103322999062456],
            [-104.822581, 48.118944999062514],
            [-104.891321, 48.150689999062635],
            [-104.947319, 48.094201999062427],
            [-105.057163, 48.077670999062363],
            [-105.177722, 48.103099999062465],
            [-105.194783, 48.066616999062333],
            [-105.503195, 48.09779199906243],
            [-105.647941, 48.077303999062373],
            [-105.843891, 48.010288999062126],
            [-105.804599, 48.219036999062887]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "043",
        COUNTYNS: "00422191",
        AFFGEOID: "0500000US17043",
        GEOID: "17043",
        NAME: "DuPage",
        LSAD: "06",
        ALAND: 848222200,
        AWATER: 22887251
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.262876, 41.986226999061813],
            [-88.174096, 41.987698999061806],
            [-87.938989, 41.993428999061777],
            [-87.920251, 41.941599999061971],
            [-87.920445, 41.906840999062084],
            [-87.917304, 41.79639699906248],
            [-87.917089, 41.791232999062494],
            [-87.916322, 41.771514999062575],
            [-87.914552, 41.720168999062764],
            [-87.94138, 41.703323999062825],
            [-87.942462, 41.699179999062828],
            [-87.949758, 41.695230999062851],
            [-87.955558, 41.693630999062869],
            [-87.966758, 41.686829999062887],
            [-87.978956, 41.687431999062888],
            [-87.982659, 41.686230999062886],
            [-88.004941, 41.685651999062891],
            [-88.028985, 41.685517999062881],
            [-88.030352, 41.72889399906272],
            [-88.148194, 41.726116999062732],
            [-88.261273, 41.724533999062736],
            [-88.261952, 41.72465199906275],
            [-88.26279, 41.81213199906243],
            [-88.26279, 41.812300999062423],
            [-88.262492, 41.882885999062182],
            [-88.26241, 41.899233999062112],
            [-88.262876, 41.986226999061813]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "035",
        COUNTYNS: "00308547",
        AFFGEOID: "0500000US12035",
        GEOID: "12035",
        NAME: "Flagler",
        LSAD: "06",
        ALAND: 1257367545,
        AWATER: 221045256
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.52366, 29.622431999188723],
            [-81.324059, 29.625609999188676],
            [-81.210409596553802, 29.670640804892212],
            [-81.163581, 29.555289999189856],
            [-81.102967351745392, 29.426996649644028],
            [-81.155881, 29.410953999192305],
            [-81.150081, 29.265956999194781],
            [-81.41729, 29.26115599919487],
            [-81.433992, 29.398551999192509],
            [-81.476893, 29.396551999192553],
            [-81.520596, 29.500248999190791],
            [-81.52366, 29.622431999188723]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "151",
        COUNTYNS: "01671894",
        AFFGEOID: "0500000US13151",
        GEOID: "13151",
        NAME: "Henry",
        LSAD: "06",
        ALAND: 825201574,
        AWATER: 20443113
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.353824, 33.397290999132508],
            [-84.29468, 33.43571499913201],
            [-84.283879, 33.549077999130553],
            [-84.243897, 33.590293999130026],
            [-84.281273, 33.647410999129299],
            [-84.266882, 33.647169999129304],
            [-84.245453, 33.630729999129514],
            [-84.184143, 33.64615699912931],
            [-84.125073, 33.561247999130387],
            [-84.097471, 33.56000899913041],
            [-84.04972, 33.541080999130649],
            [-84.044493, 33.52577599913085],
            [-84.054396, 33.514906999131],
            [-83.946526, 33.48176099913141],
            [-83.923913, 33.444193999131897],
            [-83.964374, 33.375153999132792],
            [-84.042533, 33.341112999133223],
            [-84.061322, 33.30966799913363],
            [-84.102582, 33.29819099913378],
            [-84.150581, 33.335638999133302],
            [-84.3544, 33.35251399913308],
            [-84.353824, 33.397290999132508]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "103",
        COUNTYNS: "00069170",
        AFFGEOID: "0500000US05103",
        GEOID: "05103",
        NAME: "Ouachita",
        LSAD: "06",
        ALAND: 1897898732,
        AWATER: 18050431
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.116361, 33.452908999131786],
            [-93.10429, 33.777010999127668],
            [-93.030156, 33.76988099912775],
            [-92.893534, 33.809942999127252],
            [-92.583054, 33.802197999127344],
            [-92.58503, 33.736513999128171],
            [-92.647931, 33.663784999129099],
            [-92.735617, 33.624140999129594],
            [-92.718156, 33.551992999130505],
            [-92.776729, 33.537647999130698],
            [-92.716346, 33.481584999131421],
            [-92.705093, 33.43121499913206],
            [-92.653211, 33.433967999132037],
            [-92.567156, 33.3671999991329],
            [-92.660238, 33.355612999133044],
            [-92.978469, 33.377279999132753],
            [-93.119014, 33.38029299913272],
            [-93.116361, 33.452908999131786]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "047",
        COUNTYNS: "00695747",
        AFFGEOID: "0500000US28047",
        GEOID: "28047",
        NAME: "Harrison",
        LSAD: "06",
        ALAND: 1486713863,
        AWATER: 1063618187
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.341446, 30.647719999172011],
            [-89.240073, 30.67715999917155],
            [-88.884533, 30.677291999171544],
            [-88.89393, 30.393397999176056],
            [-88.971233, 30.390797999176108],
            [-89.083237, 30.368096999176473],
            [-89.18684, 30.33119699917706],
            [-89.294444, 30.307595999177448],
            [-89.307023760844501, 30.303994779421597],
            [-89.340847, 30.357994999176622],
            [-89.341446, 30.647719999172011]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "465",
        COUNTYNS: "01384018",
        AFFGEOID: "0500000US48465",
        GEOID: "48465",
        NAME: "Val Verde",
        LSAD: "06",
        ALAND: 8144863674,
        AWATER: 227711349
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.7584, 30.288043999177763],
            [-100.960643, 30.28777599917775],
            [-100.700393, 30.288275999177756],
            [-100.699932, 29.623896999188702],
            [-100.699141, 29.419746999192153],
            [-100.757822, 29.238324999195253],
            [-100.801867546029001, 29.232831567293342],
            [-100.848664, 29.271420999194689],
            [-100.886842, 29.307847999194063],
            [-100.995607, 29.36340299919312],
            [-101.060151, 29.458660999191487],
            [-101.137503, 29.47354199919123],
            [-101.19272, 29.520284999190448],
            [-101.254895, 29.520341999190453],
            [-101.305533, 29.577924999189477],
            [-101.307332, 29.640715999188405],
            [-101.367198, 29.664040999188035],
            [-101.400636, 29.738078999186797],
            [-101.453499, 29.759670999186422],
            [-101.503223, 29.764581999186337],
            [-101.561569, 29.794657999185848],
            [-101.654578, 29.765162999186334],
            [-101.714224, 29.7676599991863],
            [-101.761619209528007, 29.778860095908296],
            [-101.7584, 30.288043999177763]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "243",
        COUNTYNS: "01383907",
        AFFGEOID: "0500000US48243",
        GEOID: "48243",
        NAME: "Jeff Davis",
        LSAD: "06",
        ALAND: 5865172197,
        AWATER: 206206
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.917163, 30.663625999171764],
            [-104.102351, 31.105202999164899],
            [-103.585084, 30.766469999170152],
            [-103.439976, 30.665938999171729],
            [-103.80068, 30.412526999175746],
            [-103.90101, 30.412189999175759],
            [-104.980753891324994, 30.628812017960616],
            [-104.917163, 30.663625999171764]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "023",
        COUNTYNS: "00659457",
        AFFGEOID: "0500000US27023",
        GEOID: "27023",
        NAME: "Chippewa",
        LSAD: "06",
        ALAND: 1505064475,
        AWATER: 17233195
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.036698, 45.15220599905679],
            [-95.24649, 45.152383999056788],
            [-95.24852, 44.891309999056766],
            [-95.482889, 44.890711999056762],
            [-95.482843, 44.752504999056789],
            [-95.498078, 44.789460999056786],
            [-95.736696, 44.93603999905676],
            [-95.804281, 44.949196999056767],
            [-95.824614, 44.986883999056765],
            [-96.036698, 45.15220599905679]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "057",
        COUNTYNS: "01265766",
        AFFGEOID: "0500000US46057",
        GEOID: "46057",
        NAME: "Hamlin",
        LSAD: "06",
        ALAND: 1313733694,
        AWATER: 79864241
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.491346, 44.804034999056775],
            [-96.88457, 44.804435999056778],
            [-96.885504, 44.543772999056863],
            [-97.128024, 44.543125999056862],
            [-97.492266, 44.54388399905686],
            [-97.491346, 44.804034999056775]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "22",
        COUNTYFP: "099",
        COUNTYNS: "01629486",
        AFFGEOID: "0500000US22099",
        GEOID: "22099",
        NAME: "St. Martin",
        LSAD: "15",
        ALAND: 1910534476,
        AWATER: 204161447
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-91.471951, 29.95598899918317],
              [-91.255229, 29.971456999182919],
              [-91.182817, 29.846171999184985],
              [-91.092682, 29.800999999185745],
              [-91.10001, 29.699401999187437],
              [-91.215195, 29.751210999186572],
              [-91.386491, 29.783680999186032],
              [-91.373642, 29.834400999185185],
              [-91.471951, 29.95598899918317]
            ]
          ],
          [
            [
              [-91.978184, 30.407402999175829],
              [-91.678896, 30.394730999176037],
              [-91.756193, 30.497737999174394],
              [-91.700933, 30.497432999174393],
              [-91.640099, 30.442727999175283],
              [-91.621055, 30.3099379991774],
              [-91.56276, 30.241948999178504],
              [-91.475303, 30.229490999178715],
              [-91.463833, 30.102492999180772],
              [-91.368835, 30.058823999181485],
              [-91.600354, 30.033168999181903],
              [-91.658886, 30.108225999180675],
              [-91.729927, 30.122483999180449],
              [-91.819216, 30.044012999181724],
              [-91.950998, 30.073212999181248],
              [-91.905544, 30.154615999179921],
              [-91.979932, 30.182584999179465],
              [-91.942818, 30.23659899917859],
              [-91.988718, 30.368597999176462],
              [-91.978184, 30.407402999175829]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "105",
        COUNTYNS: "01213684",
        AFFGEOID: "0500000US42105",
        GEOID: "42105",
        NAME: "Potter",
        LSAD: "06",
        ALAND: 2800612691,
        AWATER: 559779
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.206603037858201, 41.999088304819388],
            [-78.031177, 41.999414999061777],
            [-77.83203, 41.99852399906176],
            [-77.749930925127288, 41.99875543939077],
            [-77.610020114476001, 41.999149853141375],
            [-77.606887, 41.882539999062175],
            [-77.602566, 41.687373999062871],
            [-77.599278, 41.542270999063433],
            [-77.598129, 41.478575999063686],
            [-77.989194, 41.4748219990637],
            [-78.050442, 41.475461999063711],
            [-78.203422, 41.618156999063132],
            [-78.206603037858201, 41.999088304819388]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "011",
        COUNTYNS: "01497238",
        AFFGEOID: "0500000US51011",
        GEOID: "51011",
        NAME: "Appomattox",
        LSAD: "06",
        ALAND: 865622497,
        AWATER: 3234361
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.949942, 37.503141999088477],
            [-78.869245, 37.54209199908815],
            [-78.825238, 37.552541999088071],
            [-78.635714, 37.416192999089233],
            [-78.63065, 37.384436999089488],
            [-78.594436, 37.39797399908938],
            [-78.594394, 37.345541999089832],
            [-78.681573, 37.248758999090676],
            [-78.777293, 37.229106999090838],
            [-78.824209, 37.205360999091049],
            [-78.921665, 37.245911999090687],
            [-79.021687, 37.429242999089112],
            [-78.949942, 37.503141999088477]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "011",
        COUNTYNS: "00450405",
        AFFGEOID: "0500000US18011",
        GEOID: "18011",
        NAME: "Boone",
        LSAD: "06",
        ALAND: 1095336143,
        AWATER: 877936
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.695804, 40.17865699906983],
            [-86.39885, 40.177189999069839],
            [-86.356454, 40.178108999069835],
            [-86.242365, 40.180777999069818],
            [-86.239739, 39.926054999071241],
            [-86.326293, 39.924028999071247],
            [-86.355262, 39.924040999071252],
            [-86.695071, 39.922769999071257],
            [-86.695804, 40.17865699906983]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "087",
        COUNTYNS: "00659488",
        AFFGEOID: "0500000US27087",
        GEOID: "27087",
        NAME: "Mahnomen",
        LSAD: "06",
        ALAND: 1444886580,
        AWATER: 64851262
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.067621, 47.499135999060485],
            [-95.554248, 47.499892999060499],
            [-95.551384, 47.482365999060441],
            [-95.551186, 47.151452999059536],
            [-96.067069, 47.151812999059537],
            [-96.067621, 47.499135999060485]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "083",
        COUNTYNS: "00758496",
        AFFGEOID: "0500000US29083",
        GEOID: "29083",
        NAME: "Henry",
        LSAD: "06",
        ALAND: 1805075858,
        AWATER: 91636450
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.065713, 38.447086999080973],
            [-94.06782, 38.466015999080824],
            [-94.064317, 38.567379999080103],
            [-93.51103, 38.556209999080181],
            [-93.512743, 38.512475999080507],
            [-93.523484, 38.207215999082798],
            [-93.557009, 38.20107399908283],
            [-94.049895, 38.213984999082733],
            [-94.075182, 38.215439999082733],
            [-94.065713, 38.447086999080973]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "315",
        COUNTYNS: "01383940",
        AFFGEOID: "0500000US48315",
        GEOID: "48315",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 986503062,
        AWATER: 102126745
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.705878, 32.879176999139347],
            [-94.653994, 32.87950599913934],
            [-94.043002621563303, 32.881088978063318],
            [-94.043026, 32.797475999140453],
            [-94.043051527617592, 32.69302997580747],
            [-94.092738, 32.734268999141314],
            [-94.176401, 32.694693999141855],
            [-94.242809, 32.750729999141079],
            [-94.390783, 32.694376999141845],
            [-94.507323, 32.693737999141867],
            [-94.70214, 32.793087999140496],
            [-94.672351, 32.834755999139944],
            [-94.705878, 32.879176999139347]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "187",
        COUNTYNS: "01674058",
        AFFGEOID: "0500000US51187",
        GEOID: "51187",
        NAME: "Warren",
        LSAD: "06",
        ALAND: 555629182,
        AWATER: 8590279
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.300414, 38.943591999077441],
            [-78.31408, 39.007993999077001],
            [-78.163871, 39.017927999076932],
            [-78.151614, 39.036628999076804],
            [-78.058571, 39.019205999076924],
            [-78.004313, 38.979437999077184],
            [-78.062016, 38.891925999077792],
            [-78.130574, 38.864864999077987],
            [-78.200764, 38.776664999078605],
            [-78.284805, 38.759314999078718],
            [-78.290403, 38.780372999078565],
            [-78.394704, 38.822789999078267],
            [-78.300414, 38.943591999077441]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "077",
        COUNTYNS: "01804519",
        AFFGEOID: "0500000US72077",
        GEOID: "72077",
        NAME: "Juncos",
        LSAD: "13",
        ALAND: 68605410,
        AWATER: 327694
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-65.945842, 18.252654999438121],
            [-65.918535, 18.270129999437653],
            [-65.852616, 18.254971999438052],
            [-65.869006, 18.219455999438996],
            [-65.928341, 18.142844999441021],
            [-65.92957, 18.197452999439573],
            [-65.951019, 18.229291999438733],
            [-65.945842, 18.252654999438121]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "035",
        COUNTYNS: "01529223",
        AFFGEOID: "0500000US53035",
        GEOID: "53035",
        NAME: "Kitsap",
        LSAD: "06",
        ALAND: 1023306116,
        AWATER: 442189824
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.950621, 47.60630799906081],
            [-122.783237, 47.672753999061015],
            [-122.721971, 47.787664999061384],
            [-122.595535, 47.882297999061706],
            [-122.634102865279985, 47.923035298800635],
            [-122.616701, 47.925138999061829],
            [-122.573745864315001, 47.951004166271026],
            [-122.501809, 47.929712999061863],
            [-122.429625, 47.828097999061505],
            [-122.438093, 47.777813999061358],
            [-122.445026, 47.542712999060612],
            [-122.536993, 47.403354999060205],
            [-122.801199, 47.403577999060204],
            [-122.801012, 47.520405999060543],
            [-123.038404, 47.520235999060539],
            [-122.950621, 47.60630799906081]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "201",
        COUNTYNS: "00344088",
        AFFGEOID: "0500000US13201",
        GEOID: "13201",
        NAME: "Miller",
        LSAD: "06",
        ALAND: 731467676,
        AWATER: 3215444
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.917421, 31.255989999162594],
            [-84.641673, 31.258966999162553],
            [-84.537101, 31.255931999162602],
            [-84.542653, 31.079028999165292],
            [-84.644678, 31.079503999165293],
            [-84.730855, 31.069189999165445],
            [-84.922423, 31.072589999165395],
            [-84.917421, 31.255989999162594]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "171",
        COUNTYNS: "00835907",
        AFFGEOID: "0500000US31171",
        GEOID: "31171",
        NAME: "Thomas",
        LSAD: "06",
        ALAND: 1846403658,
        AWATER: 2028358
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.846129, 42.088165999061474],
            [-100.267622, 42.086146999061476],
            [-100.265474, 41.739855999062691],
            [-100.711711, 41.739760999062682],
            [-100.842459, 41.740403999062686],
            [-100.846129, 42.088165999061474]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "101",
        COUNTYNS: "00451679",
        AFFGEOID: "0500000US18101",
        GEOID: "18101",
        NAME: "Martin",
        LSAD: "06",
        ALAND: 869615710,
        AWATER: 12033246
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.922943, 38.817237999078316],
            [-86.904252, 38.904203999077716],
            [-86.682853, 38.904696999077707],
            [-86.683102, 38.686560999079241],
            [-86.682359, 38.526377999080395],
            [-86.837462, 38.52646799908041],
            [-86.888634, 38.494984999080636],
            [-86.924186, 38.505357999080559],
            [-86.922943, 38.817237999078316]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "161",
        COUNTYNS: "00758535",
        AFFGEOID: "0500000US29161",
        GEOID: "29161",
        NAME: "Phelps",
        LSAD: "06",
        ALAND: 1739898257,
        AWATER: 6587831
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.021165, 38.010638999084321],
            [-91.895347, 38.051198999084015],
            [-91.632431, 38.050950999084009],
            [-91.630554, 38.153518999083204],
            [-91.534384, 38.152592999083211],
            [-91.525245, 38.135627999083354],
            [-91.528797, 37.788988999086108],
            [-91.806219, 37.791285999086092],
            [-91.809105, 37.598862999087686],
            [-92.029258, 37.602541999087641],
            [-92.021165, 38.010638999084321]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "059",
        COUNTYNS: "00484998",
        AFFGEOID: "0500000US20059",
        GEOID: "20059",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1480855486,
        AWATER: 13920174
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.508267, 38.433932999081087],
            [-95.508042, 38.695267999079178],
            [-95.500738, 38.738807999078865],
            [-95.056412, 38.738586999078869],
            [-95.065772, 38.694902999079169],
            [-95.065831, 38.389944999081408],
            [-95.508328, 38.390277999081405],
            [-95.508267, 38.433932999081087]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "023",
        COUNTYNS: "00450342",
        AFFGEOID: "0500000US18023",
        GEOID: "18023",
        NAME: "Clinton",
        LSAD: "06",
        ALAND: 1049119206,
        AWATER: 461117
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.695637, 40.214366999069632],
            [-86.694665, 40.432155999068478],
            [-86.375762, 40.43185099906848],
            [-86.309249, 40.431711999068483],
            [-86.242992, 40.373660999068775],
            [-86.242743, 40.215833999069631],
            [-86.242365, 40.180777999069818],
            [-86.356454, 40.178108999069835],
            [-86.39885, 40.177189999069839],
            [-86.695804, 40.17865699906983],
            [-86.695637, 40.214366999069632]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "079",
        COUNTYNS: "00695763",
        AFFGEOID: "0500000US28079",
        GEOID: "28079",
        NAME: "Leake",
        LSAD: "06",
        ALAND: 1509662463,
        AWATER: 6411187
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.730304, 32.634227999142681],
            [-89.730016, 32.885071999139271],
            [-89.730154, 32.929923999138666],
            [-89.319163, 32.931653999138639],
            [-89.317588, 32.576457999143479],
            [-89.322598, 32.576432999143478],
            [-89.730152, 32.577247999143474],
            [-89.730304, 32.634227999142681]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "113",
        COUNTYNS: "01025833",
        AFFGEOID: "0500000US37113",
        GEOID: "37113",
        NAME: "Macon",
        LSAD: "06",
        ALAND: 1335355308,
        AWATER: 10593473
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.690212, 35.237941999110504],
            [-83.702772, 35.248519999110385],
            [-83.680114, 35.279499999110051],
            [-83.467181, 35.297860999109844],
            [-83.339609, 35.330992999109476],
            [-83.276955, 35.228044999110601],
            [-83.224672, 35.238725999110493],
            [-83.223796, 35.157385999111384],
            [-83.143257, 35.077926999112272],
            [-83.10861299650729, 35.000658842790834],
            [-83.322768, 34.995873999113179],
            [-83.482873204290698, 34.990873960400044],
            [-83.659824, 35.160303999111342],
            [-83.738129, 35.155949999111392],
            [-83.690212, 35.237941999110504]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "105",
        COUNTYNS: "00758507",
        AFFGEOID: "0500000US29105",
        GEOID: "29105",
        NAME: "Laclede",
        LSAD: "06",
        ALAND: 1980565357,
        AWATER: 8515352
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.855384, 37.895735999085247],
            [-92.620181, 37.892520999085271],
            [-92.549074, 37.800538999086008],
            [-92.409231, 37.859179999085526],
            [-92.413669, 37.713940999086716],
            [-92.248865, 37.648441999087261],
            [-92.249463, 37.604542999087634],
            [-92.252261, 37.472943999088741],
            [-92.686671, 37.481544999088662],
            [-92.853481, 37.483969999088643],
            [-92.846281, 37.721038999086666],
            [-92.855384, 37.895735999085247]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "015",
        COUNTYNS: "00161533",
        AFFGEOID: "0500000US01015",
        GEOID: "01015",
        NAME: "Calhoun",
        LSAD: "06",
        ALAND: 1569244078,
        AWATER: 16573120
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.145562, 33.679097999128899],
            [-86.043993, 33.763594999127832],
            [-86.065272, 33.842197999126853],
            [-85.96917, 33.914597999125952],
            [-85.846174, 33.957259999125434],
            [-85.740968, 33.935300999125694],
            [-85.738975, 33.968459999125294],
            [-85.542214, 33.956731999125424],
            [-85.530094, 33.941422999125621],
            [-85.638049, 33.773338999127716],
            [-85.65581, 33.627165999129559],
            [-85.796054, 33.556219999130462],
            [-85.794559, 33.585564999130085],
            [-85.994935, 33.586474999130076],
            [-86.050669, 33.674589999128962],
            [-86.145562, 33.679097999128899]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "007",
        COUNTYNS: "01675842",
        AFFGEOID: "0500000US06007",
        GEOID: "06007",
        NAME: "Butte",
        LSAD: "06",
        ALAND: 4238438196,
        AWATER: 105310997
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.044865, 39.883770999071487],
            [-121.804041, 39.88434399907149],
            [-121.703241, 39.984229999070905],
            [-121.646032, 39.98252699907092],
            [-121.586407, 40.10052199907026],
            [-121.436886, 40.151904999069977],
            [-121.366818, 40.086053999070337],
            [-121.41948, 40.015765999070737],
            [-121.429957, 39.900312999071382],
            [-121.350821, 39.82570399907182],
            [-121.210878, 39.725650999072428],
            [-121.136715, 39.628168999073011],
            [-121.076695, 39.597263999073199],
            [-121.149907, 39.526445999073637],
            [-121.305122, 39.519584999073679],
            [-121.33442, 39.425211999074257],
            [-121.407527, 39.339753999074823],
            [-121.62376, 39.295620999075084],
            [-121.638066, 39.305565999075043],
            [-121.908269, 39.303877999075041],
            [-121.899109, 39.357285999074691],
            [-121.890013, 39.383863999074521],
            [-121.856532, 39.536903999073566],
            [-121.994021, 39.533925999073588],
            [-121.994343, 39.67145799907275],
            [-121.949868, 39.733000999072381],
            [-121.967868, 39.722396999072444],
            [-122.026498, 39.800136999071981],
            [-122.046471, 39.797647999071991],
            [-122.044865, 39.883770999071487]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "013",
        COUNTYNS: "00451684",
        AFFGEOID: "0500000US18013",
        GEOID: "18013",
        NAME: "Brown",
        LSAD: "06",
        ALAND: 808043862,
        AWATER: 12025993
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.381395, 39.339751999074814],
            [-86.251684, 39.341671999074805],
            [-86.085601, 39.344188999074788],
            [-86.07903, 39.079640999076517],
            [-86.080272, 39.050269999076704],
            [-86.318133, 39.048809999076724],
            [-86.370066, 39.048374999076728],
            [-86.381395, 39.339751999074814]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "053",
        COUNTYNS: "00659472",
        AFFGEOID: "0500000US27053",
        GEOID: "27053",
        NAME: "Hennepin",
        LSAD: "06",
        ALAND: 1433839320,
        AWATER: 137173629
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.767367, 44.977963999056769],
            [-93.761874, 45.081243999056781],
            [-93.676267, 45.151043999056789],
            [-93.667203, 45.154442999056783],
            [-93.661545, 45.153273999056786],
            [-93.647747, 45.158608999056788],
            [-93.645168, 45.161560999056782],
            [-93.638622, 45.16561999905678],
            [-93.638653, 45.174160999056788],
            [-93.65881, 45.194115999056798],
            [-93.521653, 45.246617999056809],
            [-93.51219, 45.245682999056804],
            [-93.425922, 45.214731999056802],
            [-93.373301, 45.178168999056787],
            [-93.311544, 45.143777999056788],
            [-93.296092, 45.121735999056781],
            [-93.280815, 45.071715999056771],
            [-93.226929, 45.035670999056762],
            [-93.208092, 45.035743999056763],
            [-93.200334, 44.908933999056757],
            [-93.178539, 44.888337999056773],
            [-93.19794, 44.861999999056763],
            [-93.251873, 44.811475999056775],
            [-93.329615, 44.791085999056769],
            [-93.438089, 44.813006999056775],
            [-93.520431, 44.804262999056782],
            [-93.520706, 44.891501999056757],
            [-93.767041, 44.890861999056767],
            [-93.767367, 44.977963999056769]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "123",
        COUNTYNS: "00974159",
        AFFGEOID: "0500000US36123",
        GEOID: "36123",
        NAME: "Yates",
        LSAD: "06",
        ALAND: 875785691,
        AWATER: 97504774
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.366996, 42.655069999059791],
            [-77.337605, 42.732665999059591],
            [-77.313004, 42.761264999059513],
            [-76.971392, 42.76422299905952],
            [-76.947641, 42.759178999059522],
            [-76.895349, 42.65625499905979],
            [-76.895596, 42.541536999060099],
            [-76.889805, 42.463053999060314],
            [-76.987913, 42.463238999060337],
            [-76.989329, 42.484820999060261],
            [-77.107203, 42.483770999060255],
            [-77.143795, 42.576868999059997],
            [-77.366505, 42.576367999060011],
            [-77.366996, 42.655069999059791]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "333",
        COUNTYNS: "01383952",
        AFFGEOID: "0500000US48333",
        GEOID: "48333",
        NAME: "Mills",
        LSAD: "06",
        ALAND: 1937867082,
        AWATER: 4022957
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.991608, 31.484070999159165],
            [-98.779951, 31.610734999157284],
            [-98.760057, 31.687895999156147],
            [-98.668441, 31.700513999155959],
            [-98.492802, 31.723607999155615],
            [-98.463736, 31.683988999156202],
            [-98.27107, 31.416397999160179],
            [-98.281557, 31.401450999160403],
            [-98.562739, 31.230581999162975],
            [-98.626926, 31.281181999162222],
            [-98.587268, 31.325462999161552],
            [-98.643921, 31.314069999161731],
            [-98.65456, 31.37002199916088],
            [-98.713131, 31.348829999161197],
            [-98.696634, 31.407286999160331],
            [-98.7244, 31.427173999160026],
            [-98.854292, 31.413424999160224],
            [-98.8725, 31.444298999159766],
            [-98.933188, 31.436927999159874],
            [-98.991608, 31.484070999159165]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "131",
        COUNTYNS: "01639779",
        AFFGEOID: "0500000US47131",
        GEOID: "47131",
        NAME: "Obion",
        LSAD: "06",
        ALAND: 1411163261,
        AWATER: 27434034
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.444587, 36.235537999100117],
            [-89.36012, 36.409276999098417],
            [-89.345194319538791, 36.501343207734429],
            [-89.211409, 36.505629999097472],
            [-88.964471, 36.502190999097515],
            [-88.834589022874297, 36.501980341356813],
            [-88.827178036340484, 36.501968321354418],
            [-88.81642, 36.410731999098388],
            [-88.949163, 36.410098999098388],
            [-88.959839, 36.222833999100239],
            [-88.961232, 36.201634999100435],
            [-89.154464, 36.204677999100419],
            [-89.482971, 36.212658999100334],
            [-89.444587, 36.235537999100117]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "177",
        COUNTYNS: "00835910",
        AFFGEOID: "0500000US31177",
        GEOID: "31177",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1009985069,
        AWATER: 8388604
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.444785, 41.683632999062901],
            [-96.134276, 41.682729999062907],
            [-96.107937871746472, 41.676508988825034],
            [-96.111483, 41.668547999062952],
            [-96.118105, 41.613494999063164],
            [-96.09182, 41.561085999063359],
            [-96.080493, 41.528198999063491],
            [-96.005079, 41.544003999063435],
            [-95.994020393011894, 41.506890640005068],
            [-95.982962, 41.46977799906373],
            [-95.922529, 41.455765999063772],
            [-95.927336242320095, 41.389987936523646],
            [-95.945045, 41.393094999064033],
            [-96.329012, 41.393135999064036],
            [-96.434792, 41.494927999063627],
            [-96.444785, 41.683632999062901]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "12",
        COUNTYFP: "125",
        COUNTYNS: "00306913",
        AFFGEOID: "0500000US12125",
        GEOID: "12125",
        NAME: "Union",
        LSAD: "06",
        ALAND: 630806849,
        AWATER: 16049467
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.575991, 29.990856999182604],
            [-82.458364, 30.136448999180224],
            [-82.142578, 30.143116999180108],
            [-82.14734, 30.10998499918065],
            [-82.259971, 30.028089999181987],
            [-82.306677, 29.956611999183171],
            [-82.418728, 29.923092999183723],
            [-82.510819, 29.945233999183344],
            [-82.529705, 29.940880999183424],
            [-82.575991, 29.990856999182604]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "161",
        COUNTYNS: "01074092",
        AFFGEOID: "0500000US39161",
        GEOID: "39161",
        NAME: "Van Wert",
        LSAD: "06",
        ALAND: 1059698928,
        AWATER: 3281853
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.802859621951384, 40.989374051074371],
            [-84.399486, 40.99031499906576],
            [-84.398407, 40.903264999066167],
            [-84.340531, 40.859098999066376],
            [-84.397374, 40.815940999066576],
            [-84.396778, 40.684925999067218],
            [-84.45618, 40.684861999067209],
            [-84.456172, 40.728305999066997],
            [-84.802119032263789, 40.728145935939608],
            [-84.802119, 40.728162999066996],
            [-84.802670206153394, 40.922568712987093],
            [-84.802859621951384, 40.989374051074371]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "099",
        COUNTYNS: "00198165",
        AFFGEOID: "0500000US08099",
        GEOID: "08099",
        NAME: "Prowers",
        LSAD: "06",
        ALAND: 4243428647,
        AWATER: 15345957
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.740464, 37.824063999085816],
            [-102.74233, 38.266970999082332],
            [-102.044650359146985, 38.268749221585416],
            [-102.044634270290999, 38.262411566831879],
            [-102.044255, 38.113010999083528],
            [-102.041965658628001, 37.738540572724723],
            [-102.041876, 37.72387499908664],
            [-102.041890869664996, 37.644277684379098],
            [-102.747615, 37.643641999087301],
            [-102.740464, 37.824063999085816]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "027",
        COUNTYNS: "00695738",
        AFFGEOID: "0500000US28027",
        GEOID: "28027",
        NAME: "Coahoma",
        LSAD: "06",
        ALAND: 1431035290,
        AWATER: 79322563
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.938064, 34.14875399912308],
            [-90.894385, 34.160952999122934],
            [-90.882701, 34.184363999122652],
            [-90.89456, 34.224379999122156],
            [-90.839981, 34.236113999122033],
            [-90.812829, 34.279437999121505],
            [-90.752681, 34.289265999121376],
            [-90.765174, 34.342817999120761],
            [-90.729131, 34.364205999120486],
            [-90.660404, 34.335759999120832],
            [-90.641398, 34.38386899912026],
            [-90.575336, 34.415151999119878],
            [-90.583717, 34.458828999119369],
            [-90.569347, 34.524866999118601],
            [-90.399498, 34.423806999119776],
            [-90.399014, 34.261523999121721],
            [-90.450572, 34.262401999121714],
            [-90.450981, 34.072931999124009],
            [-90.451012, 33.985688999125081],
            [-90.657002, 33.987585999125038],
            [-90.659457, 34.118866999123441],
            [-90.944080760193799, 34.120065293900822],
            [-90.938064, 34.14875399912308]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "005",
        COUNTYNS: "01008533",
        AFFGEOID: "0500000US37005",
        GEOID: "37005",
        NAME: "Alleghany",
        LSAD: "06",
        ALAND: 607101004,
        AWATER: 3715040
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.353221224602194, 36.576238230253203],
            [-81.176712, 36.571925999096848],
            [-81.061866, 36.5670199990969],
            [-80.901726, 36.561750999096951],
            [-80.901661118920998, 36.561751186264942],
            [-80.973643, 36.45347399909798],
            [-80.967153, 36.402019999098485],
            [-81.026688, 36.373194999098757],
            [-81.095112, 36.440772999098108],
            [-81.162956, 36.432514999098174],
            [-81.253649, 36.366600999098814],
            [-81.255438, 36.398236999098522],
            [-81.353221224602194, 36.576238230253203]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "53",
        COUNTYFP: "057",
        COUNTYNS: "01531402",
        AFFGEOID: "0500000US53057",
        GEOID: "53057",
        NAME: "Skagit",
        LSAD: "06",
        ALAND: 4481040001,
        AWATER: 484058876
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.689121, 48.476848999063876],
            [-122.650307, 48.530155999064107],
            [-122.642597, 48.588338999064348],
            [-122.671345425666985, 48.645298997584788],
            [-122.607778, 48.626073999064502],
            [-122.565915, 48.629912999064523],
            [-122.501489, 48.645319999064583],
            [-122.215836, 48.645689999064572],
            [-121.564067, 48.642331999064581],
            [-120.908358, 48.640900999064577],
            [-120.908357, 48.657002999064638],
            [-120.751904, 48.657001999064633],
            [-120.788307, 48.625114999064508],
            [-120.688515, 48.5757049990643],
            [-120.702069, 48.53158899906412],
            [-120.787146, 48.506343999064015],
            [-120.823947, 48.54502999906417],
            [-121.049714, 48.485398999063932],
            [-121.071589, 48.317801999063256],
            [-121.001658, 48.296008999063183],
            [-121.577501, 48.298966999063197],
            [-122.201274, 48.29763399906318],
            [-122.458596, 48.297702999063191],
            [-122.58717, 48.416852999063657],
            [-122.66698252821601, 48.412466991107941],
            [-122.665338, 48.416452999063651],
            [-122.689121, 48.476848999063876]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "021",
        COUNTYNS: "00424212",
        AFFGEOID: "0500000US17021",
        GEOID: "17021",
        NAME: "Christian",
        LSAD: "06",
        ALAND: 1837278858,
        AWATER: 16221514
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.535028, 39.641137999072924],
            [-89.478473, 39.640841999072933],
            [-89.478923, 39.683963999072667],
            [-89.425051, 39.683695999072675],
            [-89.425535, 39.747675999072285],
            [-89.303122, 39.775812999072123],
            [-89.246235, 39.825947999071822],
            [-89.217523, 39.813223999071894],
            [-89.141817, 39.800909999071962],
            [-89.139125, 39.655130999072846],
            [-89.02568, 39.654182999072844],
            [-89.025633, 39.345837999074767],
            [-89.139807, 39.348887999074755],
            [-89.530836, 39.348863999074752],
            [-89.533655, 39.52459199907365],
            [-89.534998, 39.612225999073104],
            [-89.535028, 39.641137999072924]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "019",
        COUNTYNS: "00277274",
        AFFGEOID: "0500000US06019",
        GEOID: "06019",
        NAME: "Fresno",
        LSAD: "06",
        ALAND: 15431410219,
        AWATER: 137339029
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.65595, 36.95283199909332],
            [-120.590566, 36.952640999093319],
            [-120.541696, 37.044504999092482],
            [-120.450341, 36.911171999093696],
            [-120.456246, 36.862862999094126],
            [-120.370256, 36.784939999094853],
            [-120.229179, 36.769686999094986],
            [-120.057776, 36.822859999094497],
            [-120.028143, 36.814484999094567],
            [-119.932338, 36.843322999094312],
            [-119.814421, 36.850137999094258],
            [-119.713182, 36.991637999092958],
            [-119.705356, 36.999793999092894],
            [-119.621116, 37.02660499909264],
            [-119.604908, 37.071015999092239],
            [-119.562979, 37.064094999092312],
            [-119.567234, 37.115945999091849],
            [-119.506733, 37.150553999091535],
            [-119.470844, 37.110547999091899],
            [-119.43306, 37.161515999091442],
            [-119.36275, 37.167200999091385],
            [-119.330573, 37.205790999091043],
            [-119.335189, 37.310773999090117],
            [-119.286658, 37.374942999089576],
            [-119.022363, 37.585736999087786],
            [-118.91706, 37.550335999088091],
            [-118.850474, 37.47579599908871],
            [-118.775014, 37.463051999088819],
            [-118.786736, 37.343379999089841],
            [-118.716016, 37.328207999089983],
            [-118.654593, 37.141825999091616],
            [-118.592661, 37.138146999091653],
            [-118.437137, 37.059817999092346],
            [-118.360831, 36.887733999093903],
            [-118.389758, 36.834465999094391],
            [-118.360586, 36.744772999095225],
            [-118.982441, 36.741645999095248],
            [-118.984779, 36.657146999096042],
            [-119.088295, 36.657513999096032],
            [-119.174791, 36.658877999096028],
            [-119.304625, 36.660605999096013],
            [-119.305102, 36.573724999096832],
            [-119.466322, 36.575237999096828],
            [-119.546131, 36.508962999097456],
            [-119.573194, 36.488834999097641],
            [-119.6664, 36.418879999098323],
            [-119.754213, 36.402022999098484],
            [-119.959227, 36.400974999098487],
            [-119.959058, 36.181746999100646],
            [-120.315068, 35.907185999103405],
            [-120.433053, 35.968932999102783],
            [-120.667551, 36.138056999101082],
            [-120.62691, 36.203226999100437],
            [-120.678582, 36.2673189990998],
            [-120.596562, 36.328487999099195],
            [-120.597155, 36.488234999097642],
            [-120.918731, 36.740380999095265],
            [-120.65595, 36.95283199909332]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "019",
        COUNTYNS: "00465199",
        AFFGEOID: "0500000US19019",
        GEOID: "19019",
        NAME: "Buchanan",
        LSAD: "06",
        ALAND: 1478934776,
        AWATER: 5427666
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.081661, 42.642055999059821],
            [-91.607059, 42.64397299905982],
            [-91.596981, 42.296407999060818],
            [-91.831089, 42.299062999060801],
            [-92.064766, 42.297258999060816],
            [-92.064187, 42.468220999060307],
            [-92.081661, 42.642055999059821]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "155",
        COUNTYNS: "01026130",
        AFFGEOID: "0500000US37155",
        GEOID: "37155",
        NAME: "Robeson",
        LSAD: "06",
        ALAND: 2453425443,
        AWATER: 5097482
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.461791031001297, 34.630030677708859],
            [-79.330825, 34.774077999115704],
            [-79.347823, 34.838616999114961],
            [-79.191391, 34.833716999115012],
            [-79.038747, 34.95271499911366],
            [-78.940844, 34.903367999114231],
            [-78.901998, 34.835267999115004],
            [-78.805721, 34.68920199911669],
            [-78.868961, 34.484777999119068],
            [-78.960011, 34.442683999119566],
            [-79.071243612225302, 34.299303950155874],
            [-79.071254201048191, 34.299313025963158],
            [-79.358317, 34.545357999118352],
            [-79.450277289043385, 34.620608996126172],
            [-79.461791031001297, 34.630030677708859]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "077",
        COUNTYNS: "00277303",
        AFFGEOID: "0500000US06077",
        GEOID: "06077",
        NAME: "San Joaquin",
        LSAD: "06",
        ALAND: 3606190671,
        AWATER: 89046837
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.580022, 38.09441399908367],
            [-121.472138, 38.25965899908239],
            [-121.448142, 38.255007999082423],
            [-121.398193, 38.227426999082631],
            [-121.282267, 38.24983599908245],
            [-121.222512, 38.244405999082502],
            [-121.027084, 38.300251999082093],
            [-120.995497, 38.225401999082649],
            [-120.942113, 38.096327999083655],
            [-120.926449, 38.077420999083799],
            [-120.920993, 37.737946999086525],
            [-120.995696, 37.760177999086338],
            [-121.110049, 37.742127999086492],
            [-121.241219, 37.66400899908713],
            [-121.471925, 37.481782999088672],
            [-121.556655, 37.542731999088154],
            [-121.556959, 37.743050999086485],
            [-121.556997, 37.816487999085879],
            [-121.534108, 37.84933899908561],
            [-121.579425, 37.872299999085428],
            [-121.580022, 38.09441399908367]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "159",
        COUNTYNS: "00516923",
        AFFGEOID: "0500000US21159",
        GEOID: "21159",
        NAME: "Martin",
        LSAD: "06",
        ALAND: 594701951,
        AWATER: 2564962
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.670812, 37.852090999085597],
            [-82.612393, 37.879271999085368],
            [-82.580864, 37.954073999084777],
            [-82.479420123222994, 37.938562523783006],
            [-82.487556, 37.916974999085085],
            [-82.41869, 37.87237499908543],
            [-82.398464816258894, 37.843054149548557],
            [-82.369973, 37.801748999086008],
            [-82.327356, 37.762232999086322],
            [-82.3206749571778, 37.745965842545857],
            [-82.413011, 37.712464999086734],
            [-82.404105, 37.671301999087071],
            [-82.563561, 37.684843999086958],
            [-82.576737, 37.694576999086877],
            [-82.64075, 37.720181999086677],
            [-82.670812, 37.852090999085597]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "186",
        COUNTYNS: "00765806",
        AFFGEOID: "0500000US29186",
        GEOID: "29186",
        NAME: "Ste. Genevieve",
        LSAD: "06",
        ALAND: 1292800497,
        AWATER: 19739775
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.32287, 37.98173099908454],
            [-90.416022, 38.042314999084077],
            [-90.253076, 38.115537999083514],
            [-90.2527463239757, 38.127773825312701],
            [-90.252484, 38.127570999083403],
            [-90.218708, 38.094364999083666],
            [-90.205728625882699, 38.088233182192305],
            [-90.126006, 38.050569999084011],
            [-90.080959, 38.015427999084295],
            [-90.008353, 37.970178999084659],
            [-89.95491, 37.966646999084681],
            [-89.974221, 37.919216999085052],
            [-89.933095790915004, 37.880099057337766],
            [-89.999923, 37.821522999085843],
            [-90.110537, 37.671339999087074],
            [-90.200208, 37.669627999087076],
            [-90.459133, 37.878923999085373],
            [-90.32287, 37.98173099908454]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "069",
        COUNTYNS: "00835856",
        AFFGEOID: "0500000US31069",
        GEOID: "31069",
        NAME: "Garden",
        LSAD: "06",
        ALAND: 4414069977,
        AWATER: 68874632
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.677582, 42.005281999061751],
            [-102.06665, 42.009194999061741],
            [-102.067918, 41.742098999062677],
            [-101.985523, 41.742289999062685],
            [-101.98463, 41.394711999064029],
            [-102.055256, 41.395161999064008],
            [-102.055535, 41.221546999064756],
            [-102.610413, 41.221613999064743],
            [-102.609386, 41.39511499906402],
            [-102.636695, 41.439052999063847],
            [-102.636476, 41.743138999062666],
            [-102.677582, 42.005281999061751]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "197",
        COUNTYNS: "01383884",
        AFFGEOID: "0500000US48197",
        GEOID: "48197",
        NAME: "Hardeman",
        LSAD: "06",
        ALAND: 1800337387,
        AWATER: 4548860
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.997629328163001, 34.561136623678266],
            [-99.929334, 34.576713999117985],
            [-99.842063884146398, 34.506932768000198],
            [-99.818186, 34.487839999119025],
            [-99.764882, 34.435265999119636],
            [-99.696462, 34.381035999120293],
            [-99.600026, 34.374687999120354],
            [-99.58448, 34.407672999119974],
            [-99.475022137038394, 34.396870988910607],
            [-99.475294, 34.085617999123848],
            [-99.5485, 34.05577399912422],
            [-99.65301, 34.098970999123686],
            [-99.772043, 34.093285999123751],
            [-99.997621, 34.224354999122163],
            [-99.99772, 34.311828999121104],
            [-99.997629328163001, 34.561136623678266]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "083",
        COUNTYNS: "00082850",
        AFFGEOID: "0500000US05083",
        GEOID: "05083",
        NAME: "Logan",
        LSAD: "06",
        ALAND: 1834026181,
        AWATER: 60685645
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.140571, 35.099677999112025],
            [-94.139307, 35.143360999111536],
            [-94.031236, 35.14085699911157],
            [-94.028653, 35.212130999110784],
            [-94.028547, 35.213825999110774],
            [-93.920167, 35.229426999110601],
            [-93.917127, 35.319045999109619],
            [-93.810087, 35.31309499910968],
            [-93.806849, 35.430010999108426],
            [-93.710076, 35.375241999109015],
            [-93.59459, 35.383687999108915],
            [-93.435463, 35.427870999108436],
            [-93.417895, 35.326778999109543],
            [-93.299014, 35.327424999109532],
            [-93.278293, 35.317401999109642],
            [-93.279959, 35.212551999110779],
            [-93.457022, 35.215781999110753],
            [-93.529838, 35.145440999111514],
            [-93.706692, 35.134942999111644],
            [-93.704885, 35.019714999112914],
            [-94.074347, 35.026584999112842],
            [-94.141765, 35.055684999112522],
            [-94.140571, 35.099677999112025]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "039",
        COUNTYNS: "01448033",
        AFFGEOID: "0500000US49039",
        GEOID: "49039",
        NAME: "Sanpete",
        LSAD: "06",
        ALAND: 4117901449,
        AWATER: 32049371
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.016003, 39.314560999074956],
            [-111.922389, 39.381590999074547],
            [-111.747044, 39.382749999074541],
            [-111.747389, 39.416959999074322],
            [-111.713224, 39.46029399907404],
            [-111.717482, 39.694559999072602],
            [-111.584754, 39.740140999072338],
            [-111.584858, 39.784234999072069],
            [-111.641944, 39.81286899907191],
            [-111.247496, 39.813027999071906],
            [-111.247391, 39.704349999072541],
            [-111.247748, 39.467198999074007],
            [-111.30092, 39.422599999074286],
            [-111.29936, 39.032263999076832],
            [-111.853297, 39.032983999076826],
            [-112.014083, 39.045517999076743],
            [-112.016003, 39.314560999074956]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "065",
        COUNTYNS: "00659478",
        AFFGEOID: "0500000US27065",
        GEOID: "27065",
        NAME: "Kanabec",
        LSAD: "06",
        ALAND: 1350910899,
        AWATER: 31322650
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.51808, 45.981788999057379],
            [-93.432492, 45.981397999057371],
            [-93.431831, 46.153990999057591],
            [-93.284475, 46.159442999057603],
            [-93.053871, 46.158119999057597],
            [-93.055081, 45.981232999057362],
            [-93.142869, 45.981004999057369],
            [-93.141793, 45.730656999057111],
            [-93.513683, 45.734317999057112],
            [-93.51808, 45.981788999057379]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "005",
        COUNTYNS: "00581288",
        AFFGEOID: "0500000US23005",
        GEOID: "23005",
        NAME: "Cumberland",
        LSAD: "06",
        ALAND: 2164393695,
        AWATER: 988818232
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.821803, 44.077088999057203],
            [-70.624329, 44.171035999057111],
            [-70.587331, 44.122515999057157],
            [-70.595631, 44.032056999057254],
            [-70.544094, 44.005646999057277],
            [-70.480078, 44.03207799905725],
            [-70.363874, 43.986444999057298],
            [-70.315374, 44.037806999057246],
            [-70.229244, 43.974063999057314],
            [-70.11586, 43.906709999057384],
            [-70.080839, 43.926457999057362],
            [-70.034238, 43.975660999057297],
            [-69.976226, 43.915275999057378],
            [-69.857032, 43.944741999057342],
            [-69.893232, 43.878517999057429],
            [-69.887406124157692, 43.766593378509377],
            [-69.915593, 43.775111999057565],
            [-69.983685, 43.744394999057604],
            [-70.001273, 43.710387999057652],
            [-70.062776660236494, 43.713359945570438],
            [-70.071304, 43.713771999057649],
            [-70.096039, 43.672275999057703],
            [-70.168227, 43.675135999057694],
            [-70.190704, 43.645581999057747],
            [-70.217087, 43.596716999057804],
            [-70.206123, 43.557626999057874],
            [-70.245499, 43.539634999057903],
            [-70.321116, 43.527261999057927],
            [-70.338737477248401, 43.528109278426832],
            [-70.492009, 43.606768999057799],
            [-70.457743, 43.642371999057744],
            [-70.549965, 43.715795999057647],
            [-70.659622, 43.710901999057654],
            [-70.653533, 43.787737999057541],
            [-70.782984, 43.814146999057506],
            [-70.801612, 43.856082999057456],
            [-70.715418, 43.961814999057324],
            [-70.821803, 44.077088999057203]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "317",
        COUNTYNS: "01383941",
        AFFGEOID: "0500000US48317",
        GEOID: "48317",
        NAME: "Martin",
        LSAD: "06",
        ALAND: 2369724595,
        AWATER: 1931832
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.211037, 32.523243999144206],
            [-102.202703, 32.523270999144216],
            [-101.68874, 32.525221999144186],
            [-101.695011, 32.087531999150336],
            [-101.776085, 32.086924999150355],
            [-102.211249, 32.086799999150365],
            [-102.211037, 32.523243999144206]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "061",
        COUNTYNS: "00758485",
        AFFGEOID: "0500000US29061",
        GEOID: "29061",
        NAME: "Daviess",
        LSAD: "06",
        ALAND: 1458906364,
        AWATER: 14913879
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.216696, 40.136625999070056],
            [-93.764021, 40.132908999070075],
            [-93.761973, 39.959551999071053],
            [-93.758992, 39.784673999072062],
            [-94.205844, 39.788953999072042],
            [-94.203595, 40.025047999070686],
            [-94.2188, 40.034884999070627],
            [-94.216696, 40.136625999070056]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "237",
        COUNTYNS: "00516965",
        AFFGEOID: "0500000US21237",
        GEOID: "21237",
        NAME: "Wolfe",
        LSAD: "06",
        ALAND: 575419099,
        AWATER: 1505157
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.63106, 37.825371999085824],
            [-83.55141, 37.821614999085845],
            [-83.49508, 37.861905999085508],
            [-83.484258, 37.843909999085668],
            [-83.386437, 37.813674999085912],
            [-83.262172, 37.712656999086732],
            [-83.248692, 37.669615999087085],
            [-83.418474, 37.690728999086907],
            [-83.495488, 37.619594999087496],
            [-83.522308, 37.638515999087339],
            [-83.616649, 37.666924999087108],
            [-83.707974, 37.716462999086694],
            [-83.63106, 37.825371999085824]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "013",
        COUNTYNS: "01213659",
        AFFGEOID: "0500000US42013",
        GEOID: "42013",
        NAME: "Blair",
        LSAD: "06",
        ALAND: 1361815246,
        AWATER: 3285511
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.564085, 40.370608999068807],
            [-78.556958, 40.409135999068603],
            [-78.53984, 40.50615399906809],
            [-78.485958, 40.540846999067924],
            [-78.35043, 40.724826999067012],
            [-78.359912, 40.732591999066983],
            [-78.153768, 40.74216599906692],
            [-78.116521, 40.739126999066947],
            [-78.22197, 40.674538999067252],
            [-78.166371, 40.617947999067546],
            [-78.197083, 40.55844399906784],
            [-78.130081, 40.481989999068226],
            [-78.191046, 40.391869999068696],
            [-78.245798, 40.318631999069076],
            [-78.257305, 40.297882999069188],
            [-78.288764, 40.242106999069492],
            [-78.434288, 40.302300999069161],
            [-78.447895, 40.24439499906947],
            [-78.620383, 40.327024999069039],
            [-78.564085, 40.370608999068807]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "077",
        COUNTYNS: "01720021",
        AFFGEOID: "0500000US30077",
        GEOID: "30077",
        NAME: "Powell",
        LSAD: "06",
        ALAND: 6024448193,
        AWATER: 16220811
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.466485, 47.600056999060797],
            [-113.325845, 47.595506999060781],
            [-113.144368, 47.595506999060781],
            [-113.123098, 47.515729999060532],
            [-113.059199, 47.488598999060464],
            [-113.059053, 47.179569999059623],
            [-112.795808, 47.1770039990596],
            [-112.795642, 46.831664999058795],
            [-112.535748, 46.834233999058803],
            [-112.535597, 46.69948199905852],
            [-112.410846, 46.699504999058512],
            [-112.410638, 46.656010999058438],
            [-112.320871, 46.655926999058423],
            [-112.298531, 46.625972999058369],
            [-112.316754, 46.574417999058269],
            [-112.310573, 46.421170999058013],
            [-112.449521, 46.33409799905786],
            [-112.570046, 46.30497199905782],
            [-112.558599, 46.265820999057752],
            [-113.036825, 46.266825999057744],
            [-113.039134, 46.735549999058584],
            [-113.115614, 46.713386999058542],
            [-113.302903, 46.832158999058784],
            [-113.302943, 47.179642999059617],
            [-113.46645, 47.179185999059612],
            [-113.466485, 47.600056999060797]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "101",
        COUNTYNS: "00659496",
        AFFGEOID: "0500000US27101",
        GEOID: "27101",
        NAME: "Murray",
        LSAD: "06",
        ALAND: 1825163785,
        AWATER: 38338284
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.063778, 44.196424999057101],
            [-95.59357, 44.195529999057086],
            [-95.462547, 44.195941999057077],
            [-95.462427, 43.847910999057454],
            [-96.05232, 43.849069999057456],
            [-96.06462, 43.849038999057463],
            [-96.063778, 44.196424999057101]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "147",
        COUNTYNS: "00659518",
        AFFGEOID: "0500000US27147",
        GEOID: "27147",
        NAME: "Steele",
        LSAD: "06",
        ALAND: 1112775698,
        AWATER: 6976791
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.406565, 44.196376999057094],
            [-93.162285, 44.196567999057095],
            [-93.04591, 44.196715999057083],
            [-93.045966, 43.848466999057464],
            [-93.049524, 43.848442999057461],
            [-93.406642, 43.848119999057459],
            [-93.406565, 44.196376999057094]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "101",
        COUNTYNS: "00198166",
        AFFGEOID: "0500000US08101",
        GEOID: "08101",
        NAME: "Pueblo",
        LSAD: "06",
        ALAND: 6180089507,
        AWATER: 30094812
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.049215, 38.257972999082398],
            [-104.940366, 38.258274999082396],
            [-104.941533, 38.519568999080441],
            [-104.053921, 38.522392999080431],
            [-104.058242, 38.146491999083253],
            [-104.061132, 37.734703999086548],
            [-104.351109, 37.81748799908587],
            [-104.646383, 37.900526999085201],
            [-105.013729, 37.881270999085352],
            [-105.049917, 37.915478999085082],
            [-105.049215, 38.257972999082398]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "059",
        COUNTYNS: "01581089",
        AFFGEOID: "0500000US55059",
        GEOID: "55059",
        NAME: "Kenosha",
        LSAD: "06",
        ALAND: 704208614,
        AWATER: 1248708387
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.305891, 42.610816999059907],
            [-88.188173, 42.611455999059899],
            [-88.188306, 42.66970899905975],
            [-87.802015055109592, 42.668311060236455],
            [-87.814674, 42.644019999059815],
            [-87.813273, 42.579219999060001],
            [-87.800477, 42.491919999060244],
            [-87.897998770543495, 42.49285745844044],
            [-88.199529001448497, 42.495756014750533],
            [-88.2169, 42.495922999060227],
            [-88.304692, 42.495608171811732],
            [-88.305891, 42.610816999059907]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "131",
        COUNTYNS: "01581125",
        AFFGEOID: "0500000US55131",
        GEOID: "55131",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 1115370272,
        AWATER: 12999403
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.418439, 43.370174999058207],
            [-88.400426, 43.543531999057898],
            [-88.160873, 43.542942999057907],
            [-88.040528, 43.542360999057912],
            [-88.039985, 43.367754999058199],
            [-88.063353, 43.192116999058541],
            [-88.417987, 43.194696999058536],
            [-88.418439, 43.370174999058207]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "163",
        COUNTYNS: "00835903",
        AFFGEOID: "0500000US31163",
        GEOID: "31163",
        NAME: "Sherman",
        LSAD: "06",
        ALAND: 1465489230,
        AWATER: 15062146
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.203261, 41.394139999064045],
            [-98.751558, 41.39408399906403],
            [-98.74433, 41.394177999064027],
            [-98.748526, 41.046639999065512],
            [-99.207564, 41.047002999065512],
            [-99.203261, 41.394139999064045]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "005",
        COUNTYNS: "01675841",
        AFFGEOID: "0500000US06005",
        GEOID: "06005",
        NAME: "Amador",
        LSAD: "06",
        ALAND: 1539933576,
        AWATER: 29470568
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.027507, 38.508291999080527],
            [-120.813554, 38.562192999080146],
            [-120.627604, 38.503142999080573],
            [-120.510596, 38.511466999080497],
            [-120.301721, 38.549108999080232],
            [-120.212787, 38.629370999079647],
            [-120.140084, 38.63837399907959],
            [-120.098322, 38.709028999079081],
            [-120.072392, 38.702766999079124],
            [-120.072484, 38.50986899908051],
            [-120.205702, 38.501038999080592],
            [-120.33104, 38.465191999080844],
            [-120.423215, 38.473321999080788],
            [-120.614881, 38.389407999081413],
            [-120.63129, 38.340262999081787],
            [-120.810126, 38.28200999908222],
            [-120.892864, 38.221573999082686],
            [-120.995497, 38.225401999082649],
            [-121.027084, 38.300251999082093],
            [-121.027507, 38.508291999080527]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "415",
        COUNTYNS: "01383993",
        AFFGEOID: "0500000US48415",
        GEOID: "48415",
        NAME: "Scurry",
        LSAD: "06",
        ALAND: 2345090489,
        AWATER: 5436958
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.173378, 32.963596999138211],
            [-101.03866, 32.970224999138125],
            [-100.990181, 32.965022999138185],
            [-100.65587, 32.963468999138215],
            [-100.660626, 32.525311999144179],
            [-101.174562, 32.527702999144147],
            [-101.173378, 32.963596999138211]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "045",
        COUNTYNS: "01622965",
        AFFGEOID: "0500000US26045",
        GEOID: "26045",
        NAME: "Eaton",
        LSAD: "06",
        ALAND: 1489714027,
        AWATER: 11011332
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.074245, 42.770783999059503],
            [-84.837085, 42.770478999059499],
            [-84.602761, 42.769879999059505],
            [-84.600403, 42.421983999060437],
            [-84.718493, 42.42151999906045],
            [-85.071609, 42.421427999060441],
            [-85.074245, 42.770783999059503]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "049",
        COUNTYNS: "01265760",
        AFFGEOID: "0500000US46049",
        GEOID: "46049",
        NAME: "Faulk",
        LSAD: "06",
        ALAND: 2542575647,
        AWATER: 62678931
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.573445, 45.245363999056799],
            [-98.722481, 45.24376399905681],
            [-98.717759, 45.24373199905682],
            [-98.716498, 44.896924999056758],
            [-99.311754, 44.897226999056763],
            [-99.572699, 44.897262999056771],
            [-99.573445, 45.245363999056799]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "269",
        COUNTYNS: "00344156",
        AFFGEOID: "0500000US13269",
        GEOID: "13269",
        NAME: "Taylor",
        LSAD: "06",
        ALAND: 975612265,
        AWATER: 7802363
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.444353, 32.562082999143676],
            [-84.408866, 32.561922999143675],
            [-84.33794, 32.720646999141501],
            [-84.286246, 32.747625999141135],
            [-84.235803, 32.73828399914126],
            [-84.202628, 32.690017999141922],
            [-84.105924, 32.685048999141969],
            [-84.062735, 32.578431999143447],
            [-84.003363, 32.529926999144124],
            [-84.00849, 32.521768999144228],
            [-84.111226, 32.514123999144338],
            [-84.197912, 32.400851999145907],
            [-84.254613, 32.37205299914632],
            [-84.381817, 32.428046999145536],
            [-84.392316, 32.414045999145728],
            [-84.453154, 32.534335999144048],
            [-84.444353, 32.562082999143676]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "157",
        COUNTYNS: "01008582",
        AFFGEOID: "0500000US37157",
        GEOID: "37157",
        NAME: "Rockingham",
        LSAD: "06",
        ALAND: 1464974390,
        AWATER: 18468477
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.0273389151412, 36.54249538471133],
            [-80.027269, 36.54249499909713],
            [-79.891670166953872, 36.542033835713333],
            [-79.714850811052003, 36.541432483838449],
            [-79.513647662508589, 36.540748204167841],
            [-79.531865, 36.249672999099971],
            [-79.53241, 36.241462999100044],
            [-80.035119, 36.257183999099894],
            [-80.0273389151412, 36.54249538471133]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "095",
        COUNTYNS: "00424249",
        AFFGEOID: "0500000US17095",
        GEOID: "17095",
        NAME: "Knox",
        LSAD: "06",
        ALAND: 1855506547,
        AWATER: 8834348
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.439423, 41.063994999065436],
            [-90.437657, 41.151251999065046],
            [-89.984559, 41.149365999065061],
            [-89.985479, 40.97449499906584],
            [-89.986068, 40.712256999067073],
            [-90.444343, 40.71466699906707],
            [-90.439423, 41.063994999065436]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "125",
        COUNTYNS: "01074075",
        AFFGEOID: "0500000US39125",
        GEOID: "39125",
        NAME: "Paulding",
        LSAD: "06",
        ALAND: 1078561239,
        AWATER: 6245392
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.803644982255591, 41.252561682612821],
            [-84.457217, 41.253484999064604],
            [-84.457059, 41.209433999064807],
            [-84.341601, 41.209029999064796],
            [-84.341602, 41.165523999064995],
            [-84.341911, 40.990691999065753],
            [-84.399486, 40.99031499906576],
            [-84.802859621951384, 40.989374051074371],
            [-84.803234, 41.12141399906519],
            [-84.803644982255591, 41.252561682612821]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "099",
        COUNTYNS: "01101837",
        AFFGEOID: "0500000US40099",
        GEOID: "40099",
        NAME: "Murray",
        LSAD: "06",
        ALAND: 1078624775,
        AWATER: 22365113
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.35211, 34.506787999118806],
            [-97.143958, 34.506531999118806],
            [-97.200073, 34.622761999117451],
            [-96.932328, 34.636810999117287],
            [-96.932423, 34.593260999117803],
            [-96.82729, 34.593219999117785],
            [-96.827288, 34.505988999118813],
            [-96.880814, 34.506052999118808],
            [-96.881005, 34.332976999120859],
            [-96.933457, 34.332660999120868],
            [-97.028704, 34.332637999120863],
            [-97.036488, 34.375414999120359],
            [-97.352158, 34.376226999120348],
            [-97.35211, 34.506787999118806]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "157",
        COUNTYNS: "00659523",
        AFFGEOID: "0500000US27157",
        GEOID: "27157",
        NAME: "Wabasha",
        LSAD: "06",
        ALAND: 1354359268,
        AWATER: 68954671
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.550733, 44.368520999056969],
            [-92.430629, 44.368290999056967],
            [-92.430277, 44.455320999056909],
            [-92.245364649005396, 44.454251123408717],
            [-92.232472, 44.44543399905691],
            [-92.111085, 44.413947999056916],
            [-92.084528580492787, 44.404614313857941],
            [-91.9636, 44.362111999056964],
            [-91.916191, 44.318093999056984],
            [-91.892698, 44.231104999057067],
            [-91.854499975297685, 44.197226518609298],
            [-92.079236, 44.192965999057094],
            [-92.079491, 44.106987999057175],
            [-92.318971, 44.107733999057167],
            [-92.318986, 44.194056999057089],
            [-92.550871, 44.194915999057102],
            [-92.550733, 44.368520999056969]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "073",
        COUNTYNS: "01804517",
        AFFGEOID: "0500000US72073",
        GEOID: "72073",
        NAME: "Jayuya",
        LSAD: "13",
        ALAND: 115340543,
        AWATER: 3091
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.597475, 18.284103999437281],
            [-66.56662, 18.298548999436896],
            [-66.531023, 18.180136999440037],
            [-66.543133, 18.163097999440488],
            [-66.54722, 18.153129999440754],
            [-66.552766, 18.152056999440784],
            [-66.591005, 18.172478999440241],
            [-66.649467, 18.158856999440601],
            [-66.597475, 18.284103999437281]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "155",
        COUNTYNS: "00424279",
        AFFGEOID: "0500000US17155",
        GEOID: "17155",
        NAME: "Putnam",
        LSAD: "06",
        ALAND: 414813860,
        AWATER: 31243032
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.466534, 41.233872999064694],
            [-89.356671, 41.233234999064699],
            [-89.334901, 41.300876999064414],
            [-89.163705, 41.310186999064371],
            [-89.162238, 41.104079999065256],
            [-89.358597, 41.103664999065266],
            [-89.32927, 41.147930999065068],
            [-89.466421, 41.148557999065062],
            [-89.466534, 41.233872999064694]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "117",
        COUNTYNS: "01383845",
        AFFGEOID: "0500000US48117",
        GEOID: "48117",
        NAME: "Deaf Smith",
        LSAD: "06",
        ALAND: 3876750570,
        AWATER: 3926557
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.042738818608015, 34.954142039475059],
            [-103.042711, 35.144734999111527],
            [-103.042617950564988, 35.183157258119515],
            [-102.167471, 35.183232999111098],
            [-102.168839, 34.747416999116005],
            [-102.525184, 34.74693099911601],
            [-103.042769, 34.747360999116005],
            [-103.042738818608015, 34.954142039475059]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "135",
        COUNTYNS: "00485031",
        AFFGEOID: "0500000US20135",
        GEOID: "20135",
        NAME: "Ness",
        LSAD: "06",
        ALAND: 2783557951,
        AWATER: 667491
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.2472, 38.698164999079154],
            [-100.153823, 38.697340999079167],
            [-99.598323, 38.696513999079173],
            [-99.585087, 38.696536999079164],
            [-99.584794, 38.349385999081711],
            [-99.584207, 38.262178999082373],
            [-100.227137, 38.262236999082376],
            [-100.244393, 38.26228999908237],
            [-100.2472, 38.698164999079154]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "099",
        COUNTYNS: "00485014",
        AFFGEOID: "0500000US20099",
        GEOID: "20099",
        NAME: "Labette",
        LSAD: "06",
        ALAND: 1671303693,
        AWATER: 20263208
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.52113, 37.383989999089501],
            [-95.088189, 37.383836999089496],
            [-95.075166, 37.339936999089879],
            [-95.073503966630298, 36.999488133771983],
            [-95.322565, 36.999357999092901],
            [-95.407620005877092, 36.999341735731988],
            [-95.5224145523987, 36.999319785875791],
            [-95.52113, 37.383989999089501]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "101",
        COUNTYNS: "01581111",
        AFFGEOID: "0500000US55101",
        GEOID: "55101",
        NAME: "Racine",
        LSAD: "06",
        ALAND: 861408885,
        AWATER: 1190236349
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.306384, 42.84209499905932],
            [-88.069924, 42.843322999059325],
            [-87.821162938034007, 42.842273545794725],
            [-87.766675, 42.784895999059458],
            [-87.785074, 42.700818999059678],
            [-87.802015055109592, 42.668311060236455],
            [-88.188306, 42.66970899905975],
            [-88.188173, 42.611455999059899],
            [-88.305891, 42.610816999059907],
            [-88.306384, 42.84209499905932]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "109",
        COUNTYNS: "00974152",
        AFFGEOID: "0500000US36109",
        GEOID: "36109",
        NAME: "Tompkins",
        LSAD: "06",
        ALAND: 1229335996,
        AWATER: 43792937
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.696655, 42.546789999060088],
            [-76.585989, 42.54990999906007],
            [-76.666543, 42.623456999059869],
            [-76.4584, 42.618614999059886],
            [-76.265584, 42.623587999059879],
            [-76.253359, 42.40756799906049],
            [-76.250149, 42.296675999060817],
            [-76.415305, 42.318367999060754],
            [-76.416199, 42.262975999060906],
            [-76.538349, 42.281754999060851],
            [-76.619303, 42.28285299906085],
            [-76.691406, 42.284306999060853],
            [-76.696655, 42.546789999060088]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "127",
        COUNTYNS: "00758517",
        AFFGEOID: "0500000US29127",
        GEOID: "29127",
        NAME: "Marion",
        LSAD: "06",
        ALAND: 1131627484,
        AWATER: 19114157
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.840353, 39.948234999071119],
            [-91.436843268599091, 39.94524346274963],
            [-91.428956, 39.907728999071345],
            [-91.436051, 39.845509999071723],
            [-91.397853, 39.821121999071856],
            [-91.361571, 39.787547999072054],
            [-91.364616603323299, 39.758718225394517],
            [-91.367753, 39.729028999072391],
            [-91.305760334965186, 39.686215469150554],
            [-91.715497, 39.686491999072658],
            [-91.715247, 39.657643999072832],
            [-91.846743, 39.658512999072819],
            [-91.840353, 39.948234999071119]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "077",
        COUNTYNS: "01639754",
        AFFGEOID: "0500000US47077",
        GEOID: "47077",
        NAME: "Henderson",
        LSAD: "06",
        ALAND: 1346816726,
        AWATER: 15219341
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.603575, 35.626083999106328],
            [-88.606862, 35.78935399910462],
            [-88.56955, 35.824450999104258],
            [-88.179177, 35.816784999104328],
            [-88.190465, 35.609087999106499],
            [-88.243057, 35.528857999107359],
            [-88.247243, 35.43929599910831],
            [-88.241677, 35.423262999108495],
            [-88.258409, 35.416688999108551],
            [-88.360829, 35.418971999108528],
            [-88.364005, 35.492800999107743],
            [-88.505354, 35.51202999910754],
            [-88.56557, 35.58708799910675],
            [-88.613611, 35.588088999106724],
            [-88.603575, 35.626083999106328]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "199",
        COUNTYNS: "01480182",
        AFFGEOID: "0500000US51199",
        GEOID: "51199",
        NAME: "York",
        LSAD: "06",
        ALAND: 271042751,
        AWATER: 285421540
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.755401, 37.354384999089753],
            [-76.658651, 37.380138999089525],
            [-76.501939, 37.241755999090728],
            [-76.376936809717506, 37.24949176145396],
            [-76.394132, 37.225149999090888],
            [-76.347189928967595, 37.189644492746503],
            [-76.412885, 37.155807999091493],
            [-76.395795, 37.107173999091927],
            [-76.40029, 37.095850999092022],
            [-76.4355, 37.094865999092036],
            [-76.565214, 37.220494999090924],
            [-76.591577, 37.213832999090982],
            [-76.601115, 37.238192999090764],
            [-76.680151, 37.265996999090525],
            [-76.68167, 37.292607999090301],
            [-76.727859, 37.305926999090161],
            [-76.755401, 37.354384999089753]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "013",
        COUNTYNS: "00161532",
        AFFGEOID: "0500000US01013",
        GEOID: "01013",
        NAME: "Butler",
        LSAD: "06",
        ALAND: 2011985458,
        AWATER: 2718248
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.908939, 31.96167299915216],
            [-86.857583, 31.962166999152149],
            [-86.448198, 31.964628999152104],
            [-86.448635, 31.655616999156624],
            [-86.499533, 31.655246999156631],
            [-86.499213, 31.525330999158548],
            [-86.701554, 31.523945999158574],
            [-86.839279, 31.525364999158562],
            [-86.906769, 31.632670999156961],
            [-86.905899, 31.753034999155187],
            [-86.906899, 31.830627999154061],
            [-86.908939, 31.96167299915216]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "067",
        COUNTYNS: "01686112",
        AFFGEOID: "0500000US13067",
        GEOID: "13067",
        NAME: "Cobb",
        LSAD: "06",
        ALAND: 879926969,
        AWATER: 12497709
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.737836, 34.079398999123917],
            [-84.659241, 34.078239999123952],
            [-84.624215, 34.081778999123891],
            [-84.571683, 34.076298999123964],
            [-84.515431, 34.078697999123939],
            [-84.418927, 34.073297999124001],
            [-84.374743, 33.985907999125075],
            [-84.442708, 33.901542999126114],
            [-84.460129, 33.851803999126737],
            [-84.455493, 33.826807999127041],
            [-84.480134, 33.81731899912716],
            [-84.578132, 33.74350699912808],
            [-84.622692, 33.804841999127312],
            [-84.724139, 33.806169999127299],
            [-84.72423, 33.90359599912609],
            [-84.739636, 33.947601999125546],
            [-84.737836, 34.079398999123917]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "027",
        COUNTYNS: "01709077",
        AFFGEOID: "0500000US24027",
        GEOID: "24027",
        NAME: "Howard",
        LSAD: "06",
        ALAND: 649942723,
        AWATER: 6345542
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.168801, 39.353501999074723],
            [-77.16808, 39.353956999074725],
            [-77.101678, 39.369295999074623],
            [-76.882274, 39.350184999074749],
            [-76.793626, 39.313654999074977],
            [-76.79379, 39.265862999075289],
            [-76.717258, 39.225478999075541],
            [-76.697085, 39.214091999075627],
            [-76.712164, 39.201932999075709],
            [-76.713346, 39.193738999075762],
            [-76.722606, 39.182856999075831],
            [-76.746544, 39.179591999075853],
            [-76.765016, 39.164836999075945],
            [-76.781511, 39.145497999076085],
            [-76.786966, 39.128781999076189],
            [-76.813312, 39.122463999076224],
            [-76.826665, 39.109947999076311],
            [-76.840362, 39.103141999076364],
            [-76.888505, 39.130966999076179],
            [-76.958517, 39.134022999076151],
            [-76.973666, 39.162208999075965],
            [-77.070112, 39.254103999075369],
            [-77.134466, 39.276833999075222],
            [-77.186937, 39.339264999074807],
            [-77.168801, 39.353501999074723]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "153",
        COUNTYNS: "01101864",
        AFFGEOID: "0500000US40153",
        GEOID: "40153",
        NAME: "Woodward",
        LSAD: "06",
        ALAND: 3217798488,
        AWATER: 9693376
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.605565, 36.592752999096646],
            [-99.295257, 36.595122999096631],
            [-99.295186, 36.819565999094529],
            [-99.250383, 36.785014999094855],
            [-99.139855, 36.783039999094875],
            [-98.959973, 36.5989909990966],
            [-98.960334, 36.506913999097478],
            [-98.955849, 36.161576999100845],
            [-99.382074, 36.164300999100824],
            [-99.596079, 36.164060999100812],
            [-99.605565, 36.592752999096646]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "031",
        COUNTYNS: "01448029",
        AFFGEOID: "0500000US49031",
        GEOID: "49031",
        NAME: "Piute",
        LSAD: "06",
        ALAND: 1964345915,
        AWATER: 20285697
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.518495, 38.510412999080522],
            [-112.125753, 38.512139999080503],
            [-111.766393, 38.502242999080586],
            [-111.848761, 38.42493299908115],
            [-111.843215, 38.151279999083229],
            [-111.951349, 38.145982999083273],
            [-112.444214, 38.150000999083233],
            [-112.35706, 38.224957999082662],
            [-112.335381, 38.332826999081846],
            [-112.519522, 38.479901999080738],
            [-112.518495, 38.510412999080522]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "012",
        COUNTYNS: "00043540",
        AFFGEOID: "0500000US04012",
        GEOID: "04012",
        NAME: "La Paz",
        LSAD: "06",
        ALAND: 11653980557,
        AWATER: 36567994
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.725282, 33.4050479991324],
            [-114.673901, 33.418298999132226],
            [-114.635183, 33.422725999132169],
            [-114.629146518188989, 33.433544999131826],
            [-114.597283, 33.490652999131306],
            [-114.524599, 33.552230999130508],
            [-114.529186, 33.606649999129814],
            [-114.525201, 33.661582999129116],
            [-114.504993, 33.693021999128725],
            [-114.496565, 33.719154999128392],
            [-114.504863, 33.760464999127876],
            [-114.520465, 33.827777999127022],
            [-114.505638, 33.864275999126569],
            [-114.508708, 33.90063999912612],
            [-114.534987, 33.928498999125772],
            [-114.509568, 33.957263999125416],
            [-114.454807, 34.010967999124759],
            [-114.435504, 34.042614999124368],
            [-114.430091138520012, 34.078931405403431],
            [-114.428026, 34.09278699912376],
            [-114.405941, 34.111539999123529],
            [-114.348052, 34.134457999123242],
            [-114.292806, 34.166724999122856],
            [-114.229715, 34.186927999122616],
            [-114.17805, 34.239968999121977],
            [-114.139055, 34.25953799912174],
            [-114.140817151280004, 34.30312751300292],
            [-114.031132, 34.259818999121748],
            [-113.851708, 34.251264999121837],
            [-113.679109, 34.210028999122336],
            [-113.606093, 34.229333999122119],
            [-113.539524, 34.306376999121177],
            [-113.370507, 34.291967999121347],
            [-113.333508, 34.31787699912104],
            [-113.333753, 33.999226999124907],
            [-113.333331, 33.566288999130336],
            [-113.33392, 33.377424999132749],
            [-113.958046, 33.377577999132754],
            [-113.958042, 33.464654999131639],
            [-114.267815, 33.463573999131654],
            [-114.268748, 33.029814999137322],
            [-114.517066790281007, 33.024628762705895],
            [-114.575161, 33.036541999137228],
            [-114.628293, 33.031051999137304],
            [-114.670803, 33.037983999137218],
            [-114.706175, 33.105334999136318],
            [-114.679359, 33.159518999135607],
            [-114.678097, 33.230299999134672],
            [-114.674491, 33.25559699913434],
            [-114.723259, 33.288078999133916],
            [-114.707962, 33.323420999133461],
            [-114.707348, 33.376627999132765],
            [-114.725282, 33.4050479991324]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "039",
        COUNTYNS: "00400252",
        AFFGEOID: "0500000US16039",
        GEOID: "16039",
        NAME: "Elmore",
        LSAD: "06",
        ALAND: 7964192255,
        AWATER: 68555510
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.266207, 43.112268999058706],
            [-115.978651, 43.113431999058712],
            [-115.974881, 43.365019999058219],
            [-115.975719, 43.591437999057824],
            [-115.875927, 43.591264999057827],
            [-115.696456, 43.666504999057715],
            [-115.627317, 43.722864999057634],
            [-115.592796, 43.798288999057533],
            [-115.547538, 43.792666999057538],
            [-115.508722, 43.88485499905741],
            [-115.287226, 43.935519999057348],
            [-115.197067, 44.068551999057206],
            [-115.230528, 44.098765999057179],
            [-115.181931, 44.090532999057181],
            [-115.136209, 43.983858999057297],
            [-115.067482, 43.940744999057351],
            [-114.990777, 43.949722999057329],
            [-114.971351, 43.938307999057358],
            [-114.996687, 43.914406999057384],
            [-114.989258, 43.85856299905744],
            [-115.04234, 43.745581999057599],
            [-115.033834, 43.618635999057787],
            [-115.083071, 43.604398999057821],
            [-115.086155, 43.198063999058533],
            [-115.086852, 42.914559999059151],
            [-115.037774, 42.91186399905915],
            [-115.037697, 42.768413999059504],
            [-115.454224, 42.767935999059503],
            [-115.441522, 42.931126999059117],
            [-115.60443, 42.937696999059099],
            [-115.771505, 42.938663999059081],
            [-115.791812, 42.972347999059011],
            [-115.935655, 43.001732999058945],
            [-115.964966, 42.943672999059075],
            [-116.145817, 43.013415999058928],
            [-116.20174, 43.048929999058849],
            [-116.191672, 43.082472999058773],
            [-116.266207, 43.112268999058706]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "28",
        COUNTYFP: "135",
        COUNTYNS: "00695788",
        AFFGEOID: "0500000US28135",
        GEOID: "28135",
        NAME: "Tallahatchie",
        LSAD: "06",
        ALAND: 1671109461,
        AWATER: 17929624
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.451012, 33.985688999125081],
            [-90.450981, 34.072931999124009],
            [-90.135695, 34.072899999124004],
            [-90.135456, 34.160073999122943],
            [-89.958579, 34.159553999122942],
            [-89.931485, 34.164302999122889],
            [-89.925497, 34.140552999123173],
            [-89.926099, 33.897956999126158],
            [-89.926294, 33.823853999127074],
            [-90.136491, 33.809318999127264],
            [-90.136787, 33.721647999128358],
            [-90.239603, 33.737245999128163],
            [-90.299057, 33.697588999128669],
            [-90.294502, 33.750980999127989],
            [-90.185599, 33.765513999127798],
            [-90.182584, 33.809321999127263],
            [-90.451643, 33.811389999127236],
            [-90.451012, 33.985688999125081]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "017",
        COUNTYNS: "00516855",
        AFFGEOID: "0500000US21017",
        GEOID: "21017",
        NAME: "Bourbon",
        LSAD: "06",
        ALAND: 750370820,
        AWATER: 4849018
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.442661, 38.283235999082208],
            [-84.333623, 38.288974999082171],
            [-84.306169, 38.313099999081992],
            [-84.281844, 38.312460999081999],
            [-84.19405, 38.371749999081558],
            [-84.095324, 38.259013999082391],
            [-83.969281, 38.209329999082783],
            [-83.977924, 38.19211799908291],
            [-83.992845, 38.173363999083051],
            [-84.080527, 38.115117999083502],
            [-84.286461, 38.067027999083876],
            [-84.379613, 38.111455999083532],
            [-84.35523, 38.178473999083025],
            [-84.401847, 38.20788899908279],
            [-84.419918, 38.214244999082737],
            [-84.442661, 38.283235999082208]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "091",
        COUNTYNS: "00277310",
        AFFGEOID: "0500000US06091",
        GEOID: "06091",
        NAME: "Sierra",
        LSAD: "06",
        ALAND: 2468694586,
        AWATER: 23299110
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.058203, 39.53704299907357],
            [-121.009477, 39.639458999072936],
            [-120.949435, 39.672547999072734],
            [-120.873261, 39.776167999072115],
            [-120.792484, 39.709793999072517],
            [-120.68076, 39.676832999072708],
            [-120.654227, 39.706628999072535],
            [-120.147149, 39.707657999072531],
            [-120.015734, 39.708720999072526],
            [-119.999935448084997, 39.72240692379664],
            [-120.00174, 39.538851999073557],
            [-120.003028919656998, 39.44504577523464],
            [-120.505445, 39.446116999074142],
            [-120.575059, 39.522279999073653],
            [-120.654332, 39.526888999073634],
            [-120.755729, 39.4526629990741],
            [-121.022085, 39.391557999074479],
            [-121.058203, 39.53704299907357]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "031",
        COUNTYNS: "01784766",
        AFFGEOID: "0500000US17031",
        GEOID: "17031",
        NAME: "Cook",
        LSAD: "06",
        ALAND: 2447469694,
        AWATER: 1786452809
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.263378, 42.066625999061543],
            [-88.238291, 42.092347999061474],
            [-88.238369, 42.154252999061256],
            [-88.199584, 42.15425999906126],
            [-88.02375, 42.153734999061264],
            [-88.004466, 42.153644999061257],
            [-87.946149, 42.153172999061255],
            [-87.759326565238595, 42.152361978041156],
            [-87.741689929189789, 42.128265158529146],
            [-87.741662, 42.12822699906134],
            [-87.741355233404292, 42.127955433832945],
            [-87.689275620328289, 42.081851939452299],
            [-87.682359, 42.075728999061511],
            [-87.668982, 42.029141999061672],
            [-87.634367533887001, 41.932910239387503],
            [-87.624982113481011, 41.906817809765677],
            [-87.624052, 41.904231999062105],
            [-87.612291, 41.893334999062141],
            [-87.613556, 41.884479999062165],
            [-87.616293, 41.870928999062208],
            [-87.60945, 41.845232999062311],
            [-87.588375942514702, 41.811034075228825],
            [-87.581602660733182, 41.800042411762462],
            [-87.560646, 41.766033999062593],
            [-87.530745, 41.748234999062653],
            [-87.524141, 41.723989999062738],
            [-87.524044, 41.708334999062799],
            [-87.52494, 41.529734999063479],
            [-87.525409353347499, 41.470280665125422],
            [-87.787302, 41.469838999063725],
            [-87.790408, 41.528040999063485],
            [-87.812262, 41.558290999063381],
            [-87.909377, 41.556818999063381],
            [-87.91193, 41.643932999063047],
            [-88.028557, 41.669914999062954],
            [-88.028985, 41.685517999062881],
            [-88.004941, 41.685651999062891],
            [-87.982659, 41.686230999062886],
            [-87.978956, 41.687431999062888],
            [-87.966758, 41.686829999062887],
            [-87.955558, 41.693630999062869],
            [-87.949758, 41.695230999062851],
            [-87.942462, 41.699179999062828],
            [-87.94138, 41.703323999062825],
            [-87.914552, 41.720168999062764],
            [-87.916322, 41.771514999062575],
            [-87.917089, 41.791232999062494],
            [-87.917304, 41.79639699906248],
            [-87.920445, 41.906840999062084],
            [-87.920251, 41.941599999061971],
            [-87.938989, 41.993428999061777],
            [-88.174096, 41.987698999061806],
            [-88.262876, 41.986226999061813],
            [-88.263255, 42.015491999061716],
            [-88.263378, 42.066625999061543]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "36",
        COUNTYFP: "027",
        COUNTYNS: "00974112",
        AFFGEOID: "0500000US36027",
        GEOID: "36027",
        NAME: "Dutchess",
        LSAD: "06",
        ALAND: 2060678182,
        AWATER: 76956282
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.000108, 41.45654899906377],
            [-73.953307, 41.589976999063261],
            [-73.941081, 41.735992999062702],
            [-73.964413, 41.913147999062055],
            [-73.929626, 42.078777999061501],
            [-73.71093, 42.005487999061756],
            [-73.527072, 41.97797999906183],
            [-73.489679942032097, 42.053797708648283],
            [-73.487314, 42.049637999061595],
            [-73.505008, 41.823772999062378],
            [-73.517918790638191, 41.666720529772967],
            [-73.520017, 41.641196999063062],
            [-73.529677714301286, 41.527161020191798],
            [-73.579783, 41.526460999063509],
            [-73.673285, 41.514700999063542],
            [-73.933775, 41.488278999063652],
            [-73.981486, 41.438904999063844],
            [-74.000108, 41.45654899906377]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "001",
        COUNTYNS: "01383786",
        AFFGEOID: "0500000US48001",
        GEOID: "48001",
        NAME: "Anderson",
        LSAD: "06",
        ALAND: 2752129268,
        AWATER: 39880658
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.052786, 32.005894999151515],
            [-95.428512, 32.084474999150387],
            [-95.446449, 31.998516999151626],
            [-95.423832, 31.932008999152583],
            [-95.446747, 31.843115999153881],
            [-95.412908, 31.835156999153991],
            [-95.396531, 31.765265999155005],
            [-95.275849, 31.657228999156597],
            [-95.258859, 31.6099589991573],
            [-95.273203, 31.592885999157552],
            [-95.651764, 31.541790999158309],
            [-95.739279, 31.504055999158869],
            [-95.710112, 31.615586999157212],
            [-95.735681, 31.653821999156651],
            [-95.7873, 31.618384999157172],
            [-95.794081, 31.660309999156553],
            [-95.861262, 31.687450999156145],
            [-95.875937, 31.755502999155155],
            [-95.980568, 31.784560999154731],
            [-96.022491, 31.957548999152216],
            [-96.062172, 31.956339999152217],
            [-96.052786, 32.005894999151515]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "45",
        COUNTYFP: "017",
        COUNTYNS: "01247986",
        AFFGEOID: "0500000US45017",
        GEOID: "45017",
        NAME: "Calhoun",
        LSAD: "06",
        ALAND: 987179165,
        AWATER: 29350862
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.058521, 33.747052999128044],
            [-81.01233, 33.880079999126394],
            [-80.91731, 33.813734999127206],
            [-80.78486, 33.761007999127862],
            [-80.620107, 33.743236999128094],
            [-80.627531, 33.731211999128242],
            [-80.534085, 33.643910999129332],
            [-80.540514, 33.629031999129531],
            [-80.496273, 33.558480999130438],
            [-80.552272, 33.56541399913035],
            [-80.616575, 33.46600599913161],
            [-80.807631, 33.604080999129849],
            [-80.93979, 33.608797999129791],
            [-80.98421, 33.644796999129333],
            [-81.042854, 33.707130999128552],
            [-80.924968, 33.75680299912792],
            [-81.014003, 33.781240999127618],
            [-81.058521, 33.747052999128044]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "029",
        COUNTYNS: "01209174",
        AFFGEOID: "0500000US42029",
        GEOID: "42029",
        NAME: "Chester",
        LSAD: "06",
        ALAND: 1943847580,
        AWATER: 22560875
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.102479, 39.724032999072428],
            [-76.049293, 39.802002999071959],
            [-76.017384, 39.833487999071778],
            [-75.987846, 39.872114999071556],
            [-75.994629, 39.952428999071088],
            [-75.93587, 40.037465999070605],
            [-75.94244, 40.106514999070214],
            [-75.873375, 40.137106999070056],
            [-75.743178, 40.216720999069622],
            [-75.696782, 40.241862999069482],
            [-75.560154, 40.209445999069658],
            [-75.461729, 40.103034999070246],
            [-75.456501, 40.088057999070323],
            [-75.361066, 40.065542999070452],
            [-75.562539, 39.90163799907139],
            [-75.597596, 39.874160999071542],
            [-75.594316905220083, 39.834594972163075],
            [-75.662846, 39.821424999071859],
            [-75.717059, 39.792324999072029],
            [-75.753228, 39.757988999072218],
            [-75.773786, 39.722199999072437],
            [-75.788596, 39.72219899907244],
            [-75.812079984846804, 39.722169808322342],
            [-76.135697426990191, 39.721767549679136],
            [-76.102479, 39.724032999072428]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "49",
        COUNTYFP: "021",
        COUNTYNS: "01448025",
        AFFGEOID: "0500000US49021",
        GEOID: "49021",
        NAME: "Iron",
        LSAD: "06",
        ALAND: 8537474409,
        AWATER: 11390956
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.051728, 37.745996999086451],
            [-114.049658, 37.881367999085349],
            [-114.049903, 38.14860099908325],
            [-114.049903375658005, 38.148763025753837],
            [-113.4716, 38.148664999083252],
            [-112.47868, 38.147418999083257],
            [-112.468177, 37.890463999085291],
            [-112.588402, 37.890422999085288],
            [-112.57855, 37.804539999085982],
            [-112.689221, 37.805589999085967],
            [-112.68375, 37.543691999088139],
            [-112.901162, 37.543485999088141],
            [-112.900993, 37.500020999088505],
            [-113.037088, 37.499747999088513],
            [-113.037048, 37.47447399908873],
            [-113.25349, 37.482166999088655],
            [-113.25334, 37.528982999088264],
            [-113.474029, 37.529084999088255],
            [-113.474485, 37.618288999087511],
            [-113.587797, 37.604610999087626],
            [-114.052471996614003, 37.604776641807035],
            [-114.051728, 37.745996999086451]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "095",
        COUNTYNS: "01719572",
        AFFGEOID: "0500000US30095",
        GEOID: "30095",
        NAME: "Stillwater",
        LSAD: "06",
        ALAND: 4653551003,
        AWATER: 23732519
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.064581, 45.348754999056858],
            [-109.931984, 45.348754999056844],
            [-109.932025, 45.522455999056952],
            [-109.808841, 45.522469999056952],
            [-109.808793, 45.565924999056989],
            [-109.685385, 45.565609999056988],
            [-109.685385, 45.609021999057006],
            [-109.56207, 45.60911899905701],
            [-109.563907, 45.783470999057158],
            [-109.548822, 45.870748999057248],
            [-109.505963, 45.870217999057246],
            [-109.506604, 45.959111999057349],
            [-109.423026, 45.960010999057339],
            [-109.417158, 46.044758999057443],
            [-109.416227, 46.132370999057564],
            [-108.924589, 46.132369999057566],
            [-108.901655, 45.959415999057349],
            [-108.904726, 45.682820999057064],
            [-108.843016, 45.611041999057008],
            [-108.97281, 45.616553999057025],
            [-109.068498, 45.58324099905699],
            [-109.127343, 45.587670999056996],
            [-109.128225, 45.52302499905695],
            [-109.191551, 45.523094999056951],
            [-109.191761, 45.464503999056909],
            [-109.356417, 45.464325999056911],
            [-109.520884, 45.391851999056875],
            [-109.608831, 45.304831999056837],
            [-109.608504, 45.26144499905682],
            [-109.687857, 45.261439999056812],
            [-109.687792, 45.167338999056796],
            [-109.798673, 45.167338999056803],
            [-109.812233, 45.172128999056795],
            [-110.064518, 45.172128999056795],
            [-110.064581, 45.348754999056858]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "155",
        COUNTYNS: "01639789",
        AFFGEOID: "0500000US47155",
        GEOID: "47155",
        NAME: "Sevier",
        LSAD: "06",
        ALAND: 1534562002,
        AWATER: 13491054
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.794275, 35.887124999103612],
            [-83.650957, 35.986367999102605],
            [-83.673297, 36.038485999102072],
            [-83.489344, 35.927659999103199],
            [-83.310782, 35.895845999103528],
            [-83.261806, 35.825023999104246],
            [-83.256141552303603, 35.715124061763191],
            [-83.264744168290008, 35.70308949527562],
            [-83.297154, 35.657749999106002],
            [-83.347262, 35.660473999105953],
            [-83.421576, 35.611185999106489],
            [-83.452431, 35.602917999106573],
            [-83.498335, 35.562980999106998],
            [-83.587827, 35.566962999106956],
            [-83.653159, 35.568308999106939],
            [-83.662911901589297, 35.567799792315249],
            [-83.662814, 35.690617999105648],
            [-83.70525, 35.711579999105432],
            [-83.794275, 35.887124999103612]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "037",
        COUNTYNS: "01383804",
        AFFGEOID: "0500000US48037",
        GEOID: "48037",
        NAME: "Bowie",
        LSAD: "06",
        ALAND: 2292153750,
        AWATER: 98319961
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.746096, 33.700374999128641],
            [-94.731932901995904, 33.720830515408679],
            [-94.714865, 33.707260999128536],
            [-94.630586, 33.673400999128965],
            [-94.572872, 33.669885999129001],
            [-94.528928, 33.621839999129612],
            [-94.485875, 33.637866999129415],
            [-94.419057, 33.577216999130187],
            [-94.388052, 33.565510999130332],
            [-94.354165, 33.556451999130452],
            [-94.338422, 33.567081999130316],
            [-94.303742, 33.564485999130348],
            [-94.238868, 33.5767219991302],
            [-94.213605, 33.570621999130275],
            [-94.183395, 33.592211999130001],
            [-94.143024, 33.577724999130183],
            [-94.07267, 33.572233999130262],
            [-94.043833747801088, 33.55171373471611],
            [-94.043428, 33.551424999130525],
            [-94.042988, 33.435823999131998],
            [-94.043067, 33.330497999133364],
            [-94.042945847176, 33.271242083273947],
            [-94.218517, 33.296935999133801],
            [-94.231333, 33.241072999134531],
            [-94.379409, 33.255650999134346],
            [-94.415739, 33.284016999133968],
            [-94.517868, 33.282095999133993],
            [-94.572463, 33.249825999134423],
            [-94.65226, 33.268860999134169],
            [-94.692262, 33.312404999133612],
            [-94.746925, 33.328937999133387],
            [-94.746096, 33.700374999128641]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "089",
        COUNTYNS: "01502770",
        AFFGEOID: "0500000US51089",
        GEOID: "51089",
        NAME: "Henry",
        LSAD: "06",
        ALAND: 990272714,
        AWATER: 5281696
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.094389, 36.779551999094906],
            [-80.041493, 36.794364999094768],
            [-79.999437, 36.830617999094414],
            [-79.741417, 36.806805999094649],
            [-79.669153, 36.827837999094456],
            [-79.640652, 36.856138999094192],
            [-79.714850811052003, 36.541432483838449],
            [-79.891670166953872, 36.542033835713333],
            [-80.027269, 36.54249499909713],
            [-80.0273389151412, 36.54249538471133],
            [-80.053455043350098, 36.542639427183737],
            [-80.094389, 36.779551999094906]
          ],
          [
            [-79.894181, 36.672035999095904],
            [-79.85926, 36.661736999096],
            [-79.859761, 36.650367999096105],
            [-79.831433, 36.646320999096154],
            [-79.826619, 36.659378999096027],
            [-79.834624, 36.663158999095991],
            [-79.836739, 36.668544999095943],
            [-79.843737, 36.672442999095907],
            [-79.837359, 36.704136999095596],
            [-79.865814, 36.712581999095534],
            [-79.88126, 36.711536999095536],
            [-79.904077, 36.684126999095788],
            [-79.894181, 36.672035999095904]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "25",
        COUNTYFP: "011",
        COUNTYNS: "00606932",
        AFFGEOID: "0500000US25011",
        GEOID: "25011",
        NAME: "Franklin",
        LSAD: "06",
        ALAND: 1810952650,
        AWATER: 65556538
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.018648613350393, 42.740883005459374],
            [-72.930262612517993, 42.739068339517175],
            [-72.864291854845391, 42.73771388421877],
            [-72.809113, 42.736580999059584],
            [-72.516711182782203, 42.72846766888879],
            [-72.458519, 42.726852999059609],
            [-72.451195051822694, 42.726650884553209],
            [-72.412030064752301, 42.725570072422904],
            [-72.283034092510903, 42.722010249683628],
            [-72.271956, 42.67467199905974],
            [-72.224932, 42.638936999059844],
            [-72.227752, 42.615866999059904],
            [-72.271469, 42.547229999060079],
            [-72.244868, 42.513438999060178],
            [-72.291288, 42.479524999060281],
            [-72.314599, 42.343525999060667],
            [-72.356086, 42.303279999060784],
            [-72.375022, 42.420818999060451],
            [-72.483696, 42.407480999060468],
            [-72.489891, 42.433815999060407],
            [-72.669138, 42.409710999060472],
            [-72.700877, 42.452919999060342],
            [-72.757758, 42.445882999060366],
            [-72.871136, 42.484040999060255],
            [-72.876849, 42.541196999060098],
            [-72.975409, 42.556036999060055],
            [-72.951039, 42.641005999059843],
            [-72.948758, 42.704091999059663],
            [-73.023017893290088, 42.740972711786476],
            [-73.018648613350393, 42.740883005459374]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "50",
        COUNTYFP: "009",
        COUNTYNS: "01461761",
        AFFGEOID: "0500000US50009",
        GEOID: "50009",
        NAME: "Essex",
        LSAD: "06",
        ALAND: 1718717381,
        AWATER: 28683328
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.974318, 44.786518999056774],
            [-71.874433, 44.876381999056768],
            [-71.92851, 44.907297999056759],
            [-71.897711511858802, 45.008072272893457],
            [-71.691898, 45.011418999056765],
            [-71.60984, 45.01270899905677],
            [-71.501088, 45.01337699905676],
            [-71.531605, 44.976022999056759],
            [-71.494403, 44.911836999056767],
            [-71.522393, 44.880810999056756],
            [-71.570402, 44.805275999056782],
            [-71.626909, 44.747223999056779],
            [-71.584574, 44.665350999056812],
            [-71.551722, 44.627597999056832],
            [-71.544922, 44.579277999056828],
            [-71.588076, 44.547849999056851],
            [-71.579974, 44.501777999056877],
            [-71.646551, 44.468868999056895],
            [-71.69092, 44.42123399905693],
            [-71.7631925213134, 44.403568261833335],
            [-71.778613, 44.399798999056934],
            [-71.818838, 44.352938999056967],
            [-71.837790626388795, 44.347764541533977],
            [-71.933288, 44.441173999056907],
            [-71.856637, 44.496177999056876],
            [-71.908657, 44.547079999056862],
            [-71.842732, 44.611164999056825],
            [-71.910282, 44.647037999056806],
            [-71.837294, 44.716636999056789],
            [-71.939936, 44.769031999056772],
            [-71.974318, 44.786518999056774]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "30",
        COUNTYFP: "013",
        COUNTYNS: "01719622",
        AFFGEOID: "0500000US30013",
        GEOID: "30013",
        NAME: "Cascade",
        LSAD: "06",
        ALAND: 6988237777,
        AWATER: 34396942
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.047781, 47.516033999060532],
            [-111.927109, 47.500145999060493],
            [-111.922936, 47.611230999060815],
            [-111.66626, 47.611252999060831],
            [-111.666482, 47.697760999061103],
            [-111.40885, 47.698122999061084],
            [-110.976171, 47.698737999061088],
            [-111.049847, 47.598332999060787],
            [-110.86463, 47.524713999060559],
            [-110.768465, 47.525637999060571],
            [-110.769129, 47.44609599906034],
            [-110.639589, 47.445424999060336],
            [-110.639559, 47.416485999060249],
            [-110.638911, 47.359031999060086],
            [-110.767125, 47.359127999060092],
            [-110.788302, 47.307847999059952],
            [-110.770698, 47.096545999059401],
            [-110.645212, 47.095869999059403],
            [-110.636381, 46.837160999058796],
            [-110.652877, 46.823625999058777],
            [-110.751586, 46.844530999058819],
            [-110.752715, 46.890183999058927],
            [-110.856038, 46.92134699905899],
            [-110.913035, 46.98055899905912],
            [-111.104937, 47.028805999059237],
            [-111.080621, 47.087780999059369],
            [-111.158559, 47.088300999059392],
            [-111.158386, 47.011770999059202],
            [-111.285268, 47.001025999059166],
            [-111.539373, 47.000558999059166],
            [-111.539214, 46.913370999058976],
            [-111.658132, 46.913422999058973],
            [-111.790236, 46.913763999058972],
            [-111.789278, 47.128921999059479],
            [-111.942139, 47.143581999059514],
            [-111.977739, 47.184102999059625],
            [-112.044311, 47.192825999059636],
            [-112.047781, 47.516033999060532]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "181",
        COUNTYNS: "01785113",
        AFFGEOID: "0500000US17181",
        GEOID: "17181",
        NAME: "Union",
        LSAD: "06",
        ALAND: 1070855939,
        AWATER: 22502264
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.501791049499801, 37.558895718411208],
            [-89.497745926039187, 37.569985913016104],
            [-89.453355, 37.600194999087662],
            [-89.153668, 37.600468999087667],
            [-89.041401, 37.596575999087698],
            [-89.044787, 37.329845999089962],
            [-89.248439, 37.335108999089918],
            [-89.473679434541197, 37.334853901284511],
            [-89.428185, 37.356157999089746],
            [-89.42594, 37.407470999089291],
            [-89.471201, 37.466472999088793],
            [-89.5124, 37.529809999088258],
            [-89.501791049499801, 37.558895718411208]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "101",
        COUNTYNS: "00758505",
        AFFGEOID: "0500000US29101",
        GEOID: "29101",
        NAME: "Johnson",
        LSAD: "06",
        ALAND: 2147833558,
        AWATER: 10122996
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.119096, 38.833166999078202],
            [-94.114175, 38.917303999077618],
            [-93.834719, 38.937908999077472],
            [-93.497278, 38.928429999077544],
            [-93.49311, 38.899015999077747],
            [-93.51103, 38.556209999080181],
            [-94.064317, 38.567379999080103],
            [-94.119664, 38.568711999080101],
            [-94.119096, 38.833166999078202]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "47",
        COUNTYFP: "111",
        COUNTYNS: "01639768",
        AFFGEOID: "0500000US47111",
        GEOID: "47111",
        NAME: "Macon",
        LSAD: "06",
        ALAND: 795498349,
        AWATER: 246690
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.218902, 36.50005599909754],
            [-86.205565269855981, 36.639246869425016],
            [-86.081944, 36.633847999096275],
            [-85.975713174144005, 36.628637717778012],
            [-85.873857, 36.623641999096364],
            [-85.788556543613893, 36.621711858131071],
            [-85.796462, 36.613540999096443],
            [-85.813885, 36.499472999097542],
            [-85.828026, 36.411835999098386],
            [-85.895732, 36.420445999098305],
            [-85.978893, 36.426072999098238],
            [-86.168261, 36.444292999098074],
            [-86.228434, 36.48747199909765],
            [-86.218902, 36.50005599909754]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "037",
        COUNTYNS: "01074031",
        AFFGEOID: "0500000US39037",
        GEOID: "39037",
        NAME: "Darke",
        LSAD: "06",
        ALAND: 1549071817,
        AWATER: 4245786
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.810161238474493, 40.005067542919001],
            [-84.808706, 40.107215999070227],
            [-84.804917302578403, 40.310095914103215],
            [-84.804120527014902, 40.352762229365993],
            [-84.434631, 40.35425899906889],
            [-84.432575, 40.197036999069731],
            [-84.425902, 39.919621999071282],
            [-84.485367, 39.918490999071288],
            [-84.811417106380489, 39.916913628651109],
            [-84.810161238474493, 40.005067542919001]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "085",
        COUNTYNS: "00758497",
        AFFGEOID: "0500000US29085",
        GEOID: "29085",
        NAME: "Hickory",
        LSAD: "06",
        ALAND: 1032907248,
        AWATER: 33455656
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.568709, 37.914917999085084],
            [-93.512103, 37.913534999085087],
            [-93.503946, 38.073122999083836],
            [-93.065199, 38.062478999083915],
            [-93.072447, 37.902626999085179],
            [-93.182648, 37.904231999085184],
            [-93.187915, 37.802736999085987],
            [-93.561399, 37.812981999085913],
            [-93.573202, 37.828034999085794],
            [-93.568709, 37.914917999085084]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "173",
        COUNTYNS: "01008588",
        AFFGEOID: "0500000US37173",
        GEOID: "37173",
        NAME: "Swain",
        LSAD: "06",
        ALAND: 1366812262,
        AWATER: 32414382
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.916801, 35.473611999107945],
            [-83.848502, 35.519258999107457],
            [-83.771736, 35.562117999107002],
            [-83.662911901589297, 35.567799792315249],
            [-83.653159, 35.568308999106939],
            [-83.587827, 35.566962999106956],
            [-83.498335, 35.562980999106998],
            [-83.452431, 35.602917999106573],
            [-83.421576, 35.611185999106489],
            [-83.347262, 35.660473999105953],
            [-83.297154, 35.657749999106002],
            [-83.264744168290008, 35.70308949527562],
            [-83.181977, 35.671326999105851],
            [-83.155835, 35.55262199910711],
            [-83.186322, 35.514367999107513],
            [-83.236073, 35.524563999107407],
            [-83.351726, 35.468091999108019],
            [-83.339609, 35.330992999109476],
            [-83.467181, 35.297860999109844],
            [-83.680114, 35.279499999110051],
            [-83.585756, 35.434831999108368],
            [-83.953231380150399, 35.460020888684788],
            [-83.916801, 35.473611999107945]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "081",
        COUNTYNS: "01101828",
        AFFGEOID: "0500000US40081",
        GEOID: "40081",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 2466490647,
        AWATER: 34606636
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.141072, 35.724440999105298],
            [-97.140583, 35.941990999103055],
            [-96.621097, 35.94151899910306],
            [-96.620648, 35.639004999106191],
            [-96.624865, 35.462705999108067],
            [-97.14149, 35.463976999108056],
            [-97.141072, 35.724440999105298]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "117",
        COUNTYNS: "00451681",
        AFFGEOID: "0500000US18117",
        GEOID: "18117",
        NAME: "Orange",
        LSAD: "06",
        ALAND: 1031786463,
        AWATER: 25425748
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.683102, 38.686560999079241],
            [-86.308701, 38.688100999079225],
            [-86.308674, 38.422874999081159],
            [-86.308868, 38.394068999081391],
            [-86.614784, 38.395460999081365],
            [-86.681424, 38.394766999081376],
            [-86.682359, 38.526377999080395],
            [-86.683102, 38.686560999079241]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "163",
        COUNTYNS: "01784987",
        AFFGEOID: "0500000US17163",
        GEOID: "17163",
        NAME: "St. Clair",
        LSAD: "06",
        ALAND: 1703659884,
        AWATER: 42054455
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.255294887669393, 38.530877710901365],
            [-90.248913, 38.544751999080262],
            [-90.18451, 38.611550999079775],
            [-90.18111, 38.659549999079438],
            [-90.18152438153129, 38.660372884389915],
            [-90.037105, 38.658448999079432],
            [-89.707024, 38.655151999079472],
            [-89.704417, 38.415666999081225],
            [-89.703256, 38.219403999082708],
            [-89.899061, 38.220754999082693],
            [-89.903937, 38.297202999082096],
            [-90.036018, 38.308849999082021],
            [-90.260976059287003, 38.518526786488252],
            [-90.255294887669393, 38.530877710901365]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "225",
        COUNTYNS: "00758565",
        AFFGEOID: "0500000US29225",
        GEOID: "29225",
        NAME: "Webster",
        LSAD: "06",
        ALAND: 1534730262,
        AWATER: 3110547
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.07338, 37.414988999089225],
            [-93.071584, 37.487443999088605],
            [-92.853481, 37.483969999088643],
            [-92.686671, 37.481544999088662],
            [-92.685867, 37.067050999092281],
            [-92.82467, 37.068673999092269],
            [-92.903273, 37.070650999092251],
            [-92.911057, 37.08557199909211],
            [-93.065274, 37.088693999092072],
            [-93.062142, 37.235116999090792],
            [-93.076779, 37.270039999090486],
            [-93.07338, 37.414988999089225]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "025",
        COUNTYNS: "01493961",
        AFFGEOID: "0500000US51025",
        GEOID: "51025",
        NAME: "Brunswick",
        LSAD: "06",
        ALAND: 1466529494,
        AWATER: 8387116
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.027411, 36.777857999094913],
            [-78.003639, 37.022755999092681],
            [-77.968114, 36.987291999092996],
            [-77.889813, 36.98881799909298],
            [-77.839834, 36.995911999092925],
            [-77.658036, 36.894513999093853],
            [-77.655841, 36.713538999095519],
            [-77.767103969926694, 36.545442408432599],
            [-77.899774136046673, 36.544850732139508],
            [-78.046208304360277, 36.544197671783721],
            [-78.027411, 36.777857999094913]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "027",
        COUNTYNS: "00758468",
        AFFGEOID: "0500000US29027",
        GEOID: "29027",
        NAME: "Callaway",
        LSAD: "06",
        ALAND: 2161546696,
        AWATER: 33099111
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.216388, 38.714494999079037],
            [-92.135245, 38.813893999078338],
            [-92.1625, 38.865584999077967],
            [-92.110387, 39.064203999076625],
            [-91.633998, 39.059056999076653],
            [-91.647171, 38.70339599907912],
            [-91.854867, 38.670335999079349],
            [-92.008983, 38.570899999080069],
            [-92.161663, 38.578056999080012],
            [-92.220661, 38.64316699907954],
            [-92.216388, 38.714494999079037]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "013",
        COUNTYNS: "00835991",
        AFFGEOID: "0500000US31013",
        GEOID: "31013",
        NAME: "Box Butte",
        LSAD: "06",
        ALAND: 2784994726,
        AWATER: 6588657
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.444532, 42.437353999060392],
            [-102.773366, 42.439921999060381],
            [-102.749611, 42.430453999060418],
            [-102.742269, 42.092387999061465],
            [-102.697835, 42.004842999061765],
            [-102.827941, 42.000863999061764],
            [-103.363337, 42.002929999061756],
            [-103.401639, 42.003539999061758],
            [-103.401667, 42.090561999061457],
            [-103.444108, 42.090707999061458],
            [-103.444532, 42.437353999060392]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "099",
        COUNTYNS: "01213682",
        AFFGEOID: "0500000US42099",
        GEOID: "42099",
        NAME: "Perry",
        LSAD: "06",
        ALAND: 1428244405,
        AWATER: 10589698
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.671761, 40.289824999069225],
            [-77.540141, 40.399220999068646],
            [-77.182789, 40.582210999067719],
            [-76.949414, 40.628162999067492],
            [-76.992488, 40.567533999067784],
            [-76.952838, 40.462443999068334],
            [-77.01627, 40.425172999068522],
            [-77.020799, 40.366229999068828],
            [-76.916811, 40.331675999069006],
            [-76.91497, 40.328483999069022],
            [-76.974219, 40.310329999069118],
            [-77.083942, 40.301219999069161],
            [-77.239163, 40.284101999069257],
            [-77.280548, 40.275922999069302],
            [-77.420845, 40.255367999069421],
            [-77.360082, 40.314074999069099],
            [-77.605448, 40.185361999069805],
            [-77.614665, 40.198548999069722],
            [-77.602717, 40.22819999906956],
            [-77.671761, 40.289824999069225]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "033",
        COUNTYNS: "01008542",
        AFFGEOID: "0500000US37033",
        GEOID: "37033",
        NAME: "Caswell",
        LSAD: "06",
        ALAND: 1101692694,
        AWATER: 8643882
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.513647662508589, 36.540748204167841],
            [-79.510647, 36.540737999097146],
            [-79.470056673924304, 36.540836148883749],
            [-79.343115919369495, 36.541143099065643],
            [-79.218455495922285, 36.541444535273243],
            [-79.138335898871404, 36.54163826915304],
            [-79.153997, 36.241874999100048],
            [-79.257952, 36.243444999100028],
            [-79.531865, 36.249672999099971],
            [-79.513647662508589, 36.540748204167841]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "007",
        COUNTYNS: "00929117",
        AFFGEOID: "0500000US35007",
        GEOID: "35007",
        NAME: "Colfax",
        LSAD: "06",
        ALAND: 9733110215,
        AWATER: 26299618
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.351385, 36.636904999096238],
            [-105.370638, 36.681031999095822],
            [-105.311842, 36.724470999095409],
            [-105.230632, 36.723783999095417],
            [-105.229372, 36.84770999909427],
            [-105.200117, 36.915627999093651],
            [-105.220505200781005, 36.995563235579425],
            [-105.155042748048004, 36.995474444703532],
            [-105.1208, 36.995427999092925],
            [-104.732031, 36.993446999092953],
            [-104.338833, 36.993534999092937],
            [-104.007849409613002, 36.995984093802015],
            [-104.008823, 36.21811499910028],
            [-104.436056, 36.218533999100273],
            [-104.865402, 36.217868999100283],
            [-104.865244, 36.261418999099853],
            [-105.341264, 36.261862999099847],
            [-105.327301, 36.405429999098438],
            [-105.356826, 36.44950399909802],
            [-105.318718, 36.491692999097609],
            [-105.377745, 36.552095999097041],
            [-105.351385, 36.636904999096238]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "017",
        COUNTYNS: "01074021",
        AFFGEOID: "0500000US39017",
        GEOID: "39017",
        NAME: "Butler",
        LSAD: "06",
        ALAND: 1209498280,
        AWATER: 7863952
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.817453, 39.391752999074477],
            [-84.816160194910793, 39.521968108066559],
            [-84.815705943255495, 39.567721663100777],
            [-84.478927, 39.568789999073381],
            [-84.479213, 39.591023999073236],
            [-84.365232, 39.589492999073236],
            [-84.332089, 39.548802999073487],
            [-84.353209, 39.292286999075124],
            [-84.630047, 39.312055999074985],
            [-84.8188770978104, 39.305166168127329],
            [-84.817453, 39.391752999074477]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "117",
        COUNTYNS: "01500747",
        AFFGEOID: "0500000US51117",
        GEOID: "51117",
        NAME: "Mecklenburg",
        LSAD: "06",
        ALAND: 1619956537,
        AWATER: 139243978
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.711702, 36.64107799909619],
            [-78.653551, 36.690849999095732],
            [-78.54923, 36.62662899909634],
            [-78.648541, 36.697908999095652],
            [-78.493028, 36.89121999909387],
            [-78.421187, 36.851651999094237],
            [-78.027411, 36.777857999094913],
            [-78.046208304360277, 36.544197671783721],
            [-78.132911, 36.543810999097118],
            [-78.323718509559001, 36.542421390655726],
            [-78.457277896255889, 36.541448707496627],
            [-78.509965, 36.541064999097145],
            [-78.734122801117195, 36.541608733937935],
            [-78.711702, 36.64107799909619]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "56",
        COUNTYFP: "033",
        COUNTYNS: "01605081",
        AFFGEOID: "0500000US56033",
        GEOID: "56033",
        NAME: "Sheridan",
        LSAD: "06",
        ALAND: 6535475121,
        AWATER: 7986323
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.911522788162003, 45.001544003668165],
            [-107.351441, 45.001406999056755],
            [-106.888773, 44.99588499905677],
            [-106.263715092464992, 44.993788432058267],
            [-106.263586, 44.993787999056757],
            [-106.024880029032005, 44.997584122612857],
            [-106.009676, 44.563980999056845],
            [-106.400677, 44.561928999056846],
            [-106.428897, 44.556776999056851],
            [-107.369621, 44.559836999056856],
            [-107.615891, 44.701296999056801],
            [-107.743467, 44.715422999056798],
            [-107.832319, 44.790160999056781],
            [-107.911522788162003, 45.001544003668165]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "105",
        COUNTYNS: "00161579",
        AFFGEOID: "0500000US01105",
        GEOID: "01105",
        NAME: "Perry",
        LSAD: "06",
        ALAND: 1863885725,
        AWATER: 10952676
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.525198, 32.655712999142381],
            [-87.473915, 32.65586699914239],
            [-87.472174, 32.830619999139998],
            [-87.4212, 32.874507999139404],
            [-87.319473, 32.875123999139397],
            [-87.319184, 32.831521999139987],
            [-87.019157, 32.837033999139912],
            [-87.017762, 32.729531999141379],
            [-87.110817, 32.489947999144675],
            [-87.423153, 32.482964999144777],
            [-87.421744, 32.308100999147229],
            [-87.47308, 32.307613999147229],
            [-87.52429, 32.307292999147229],
            [-87.524485, 32.482027999144783],
            [-87.525198, 32.655712999142381]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "013",
        COUNTYNS: "00037026",
        AFFGEOID: "0500000US04013",
        GEOID: "04013",
        NAME: "Maricopa",
        LSAD: "06",
        ALAND: 23825875275,
        AWATER: 64310266
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.33392, 33.377424999132749],
            [-113.333331, 33.566288999130336],
            [-113.333753, 33.999226999124907],
            [-112.743951, 33.999897999124904],
            [-112.27604, 33.882311999126351],
            [-112.195658, 33.974501999125202],
            [-112.164758, 34.048169999124298],
            [-111.72531, 33.9998489991249],
            [-111.494778, 33.999816999124896],
            [-111.445079, 34.001175999124889],
            [-111.450904, 33.919876999125883],
            [-111.372317, 33.823923999127075],
            [-111.38235, 33.758246999127905],
            [-111.28649, 33.657374999129175],
            [-111.222055, 33.602445999129877],
            [-111.153354, 33.677605999128915],
            [-111.039976, 33.466018999131606],
            [-111.580634, 33.465799999131619],
            [-111.582709, 33.205722999134991],
            [-111.686035, 33.204636999135005],
            [-111.869945, 33.20358299913503],
            [-111.893085, 33.204810999135006],
            [-112.083488, 33.204559999135014],
            [-112.203288, 33.310532999133628],
            [-112.202163, 33.24686199913446],
            [-112.203509, 32.683595999142007],
            [-112.203719, 32.506759999144442],
            [-112.568482, 32.505218999144461],
            [-113.333897, 32.504937999144467],
            [-113.333709, 32.767850999140862],
            [-113.33392, 33.377424999132749]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "177",
        COUNTYNS: "00516935",
        AFFGEOID: "0500000US21177",
        GEOID: "21177",
        NAME: "Muhlenberg",
        LSAD: "06",
        ALAND: 1209734539,
        AWATER: 31905594
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.38703, 37.273605999090464],
            [-87.295965, 37.391818999089431],
            [-87.210958, 37.380929999089531],
            [-87.099803, 37.416041999089217],
            [-87.136983, 37.335523999089915],
            [-87.07133, 37.370297999089622],
            [-86.985957, 37.316565999090081],
            [-86.98267, 37.269631999090507],
            [-86.899268, 37.212312999090997],
            [-86.924273, 37.144622999091588],
            [-86.894067, 37.088348999092098],
            [-86.941391, 37.068980999092268],
            [-86.976399, 37.073587999092226],
            [-87.053164, 37.06101899909234],
            [-87.250256, 37.040556999092523],
            [-87.259371, 37.072400999092238],
            [-87.334277, 37.131817999091709],
            [-87.333741, 37.157185999091467],
            [-87.38703, 37.273605999090464]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "093",
        COUNTYNS: "01622989",
        AFFGEOID: "0500000US26093",
        GEOID: "26093",
        NAME: "Livingston",
        LSAD: "06",
        ALAND: 1463693558,
        AWATER: 52500785
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.158189, 42.776638999059479],
            [-83.922516, 42.780820999059472],
            [-83.686493, 42.783262999059467],
            [-83.68278, 42.695579999059674],
            [-83.664808, 42.431178999060407],
            [-83.94465, 42.424148999060435],
            [-84.131136, 42.424566999060438],
            [-84.140713, 42.424610999060434],
            [-84.158189, 42.776638999059479]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "159",
        COUNTYNS: "00835901",
        AFFGEOID: "0500000US31159",
        GEOID: "31159",
        NAME: "Seward",
        LSAD: "06",
        ALAND: 1479990669,
        AWATER: 11547536
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.368118, 41.046946999065511],
            [-96.91094, 41.04611599906552],
            [-96.911049, 40.714101999067069],
            [-96.913493, 40.697947999067139],
            [-97.368401, 40.69862499906715],
            [-97.368118, 41.046946999065511]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "073",
        COUNTYNS: "00758491",
        AFFGEOID: "0500000US29073",
        GEOID: "29073",
        NAME: "Gasconade",
        LSAD: "06",
        ALAND: 1344215195,
        AWATER: 18009291
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.644729, 38.288509999082166],
            [-91.640372, 38.703791999079122],
            [-91.558185, 38.676634999079312],
            [-91.418637, 38.709777999079073],
            [-91.369192, 38.699323999079155],
            [-91.367482, 38.209740999082776],
            [-91.535332, 38.210973999082761],
            [-91.534384, 38.152592999083211],
            [-91.630554, 38.153518999083204],
            [-91.644847, 38.164736999083118],
            [-91.644729, 38.288509999082166]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "037",
        COUNTYNS: "01101806",
        AFFGEOID: "0500000US40037",
        GEOID: "40037",
        NAME: "Creek",
        LSAD: "06",
        ALAND: 2460877094,
        AWATER: 50957689
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.621441, 36.159928999100863],
            [-96.598914, 36.163568999100818],
            [-96.297888, 36.162278999100835],
            [-96.297786, 36.075778999101701],
            [-96.029583, 36.075365999101706],
            [-96.029544, 35.988386999102573],
            [-96.02957, 35.901105999103471],
            [-96.033118, 35.856823999103923],
            [-96.141561, 35.857261999103933],
            [-96.192382, 35.857047999103926],
            [-96.192563, 35.63908699910619],
            [-96.620648, 35.639004999106191],
            [-96.621097, 35.94151899910306],
            [-96.621441, 36.159928999100863]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "063",
        COUNTYNS: "01213673",
        AFFGEOID: "0500000US42063",
        GEOID: "42063",
        NAME: "Indiana",
        LSAD: "06",
        ALAND: 2142009116,
        AWATER: 18802621
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.450176, 40.530148999067983],
            [-79.215235, 40.776015999066772],
            [-79.21531, 40.911345999066128],
            [-78.805167, 40.905979999066155],
            [-78.806375, 40.743159999066926],
            [-78.801697, 40.724538999067015],
            [-78.834641, 40.661677999067322],
            [-78.974649, 40.395971999068671],
            [-79.016617, 40.420278999068543],
            [-79.121634, 40.370575999068805],
            [-79.155789, 40.411764999068581],
            [-79.395461, 40.457131999068352],
            [-79.453765, 40.484249999068204],
            [-79.450176, 40.530148999067983]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "453",
        COUNTYNS: "01384012",
        AFFGEOID: "0500000US48453",
        GEOID: "48453",
        NAME: "Travis",
        LSAD: "06",
        ALAND: 2569073026,
        AWATER: 80592292
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.125556, 30.426185999175527],
            [-98.12342, 30.486481999174568],
            [-98.049886, 30.62415499917239],
            [-97.956734, 30.628248999172328],
            [-97.857505, 30.501609999174324],
            [-97.848384, 30.47266199917479],
            [-97.773458, 30.430653999175473],
            [-97.596236, 30.501512999174334],
            [-97.369539, 30.419562999175643],
            [-97.400089, 30.367905999176468],
            [-97.491254, 30.212109999178992],
            [-97.49135, 30.211929999178992],
            [-97.64937, 30.067943999181338],
            [-97.665761, 30.063861999181398],
            [-97.710215, 30.02449899918205],
            [-97.781369, 30.075669999181205],
            [-97.813018, 30.098612999180837],
            [-97.857161, 30.131195999180306],
            [-97.857222, 30.131239999180309],
            [-98.172977, 30.356311999176661],
            [-98.125556, 30.426185999175527]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "089",
        COUNTYNS: "01265762",
        AFFGEOID: "0500000US46089",
        GEOID: "46089",
        NAME: "McPherson",
        LSAD: "06",
        ALAND: 2943892857,
        AWATER: 38569612
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.718071042882201, 45.940907002553715],
            [-99.61116, 45.94109799905732],
            [-99.490254, 45.94036199905733],
            [-99.34496, 45.940298999057326],
            [-99.092868, 45.940183999057325],
            [-99.0057543501525, 45.93994430818573],
            [-99.005642, 45.93994399905732],
            [-98.7243744138881, 45.93867473231883],
            [-98.725002, 45.591251999056993],
            [-99.707705, 45.593390999057],
            [-99.717466, 45.593427999056992],
            [-99.718071042882201, 45.940907002553715]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "365",
        COUNTYNS: "01383968",
        AFFGEOID: "0500000US48365",
        GEOID: "48365",
        NAME: "Panola",
        LSAD: "06",
        ALAND: 2101400666,
        AWATER: 25810622
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.5805, 32.201362999148728],
            [-94.530714, 32.271841999147732],
            [-94.491818, 32.394136999146006],
            [-94.385839, 32.367199999146393],
            [-94.350303, 32.32661399914695],
            [-94.042788232805307, 32.392283192427229],
            [-94.042739, 32.363558999146434],
            [-94.042695923740098, 32.1960049739737],
            [-94.042681, 32.137955999149618],
            [-94.041833, 31.992401999151713],
            [-94.029428406596011, 31.979686963319899],
            [-94.511431, 31.973983999151979],
            [-94.599978, 31.973192999151994],
            [-94.5805, 32.201362999148728]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "101",
        COUNTYNS: "00277315",
        AFFGEOID: "0500000US06101",
        GEOID: "06101",
        NAME: "Sutter",
        LSAD: "06",
        ALAND: 1560275634,
        AWATER: 15717389
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.908269, 39.303877999075041],
            [-121.638066, 39.305565999075043],
            [-121.62376, 39.295620999075084],
            [-121.58591, 39.089690999076446],
            [-121.610138, 39.057846999076666],
            [-121.575415, 38.918347999077611],
            [-121.543306, 38.972403999077237],
            [-121.414779, 38.996451999077088],
            [-121.414399, 38.926213999077568],
            [-121.469356, 38.92599199907756],
            [-121.484396, 38.7345979990789],
            [-121.559868, 38.736301999078883],
            [-121.602894, 38.735837999078889],
            [-121.673033, 38.742846999078836],
            [-121.723149, 38.851305999078086],
            [-121.814814, 38.876581999077899],
            [-121.835488, 38.924480999077581],
            [-121.795366, 38.995685999077082],
            [-121.889101, 39.07237299907657],
            [-121.945514, 39.179842999075859],
            [-121.908269, 39.303877999075041]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "027",
        COUNTYNS: "00484983",
        AFFGEOID: "0500000US20027",
        GEOID: "20027",
        NAME: "Clay",
        LSAD: "06",
        ALAND: 1671314205,
        AWATER: 26701337
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.369839, 39.306010999075021],
            [-97.368574, 39.567017999073386],
            [-96.958719, 39.566400999073394],
            [-96.961693, 39.220075999075583],
            [-96.961219, 39.151119999076045],
            [-96.963177, 39.13229999907616],
            [-97.371154, 39.131960999076178],
            [-97.369839, 39.306010999075021]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "125",
        COUNTYNS: "01623005",
        AFFGEOID: "0500000US26125",
        GEOID: "26125",
        NAME: "Oakland",
        LSAD: "06",
        ALAND: 2246869568,
        AWATER: 102863438
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.689384, 42.871262999059248],
            [-83.453364, 42.880431999059233],
            [-83.102891, 42.888646999059205],
            [-83.091449, 42.62420799905987],
            [-83.0899, 42.593008999059947],
            [-83.086882, 42.534791999060118],
            [-83.083393, 42.447152999060364],
            [-83.151986, 42.44600699906038],
            [-83.203558, 42.444747999060368],
            [-83.316825, 42.442032999060388],
            [-83.433734, 42.438939999060388],
            [-83.551907, 42.435165999060395],
            [-83.664808, 42.431178999060407],
            [-83.68278, 42.695579999059674],
            [-83.686493, 42.783262999059467],
            [-83.689384, 42.871262999059248]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "077",
        COUNTYNS: "01035302",
        AFFGEOID: "0500000US38077",
        GEOID: "38077",
        NAME: "Richland",
        LSAD: "06",
        ALAND: 3718608511,
        AWATER: 25035912
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.279948, 46.629347999058375],
            [-96.821202, 46.630185999058376],
            [-96.789786513049791, 46.635746671775095],
            [-96.7857861825985, 46.629590848319289],
            [-96.744436, 46.565959999058258],
            [-96.709095, 46.435293999058032],
            [-96.647296, 46.358498999057893],
            [-96.60104, 46.319553999057831],
            [-96.59567, 46.219849999057686],
            [-96.554507, 46.083977999057495],
            [-96.572697494194003, 46.021891670415108],
            [-96.574264, 46.016544999057423],
            [-96.563672, 45.935244999057318],
            [-97.082093, 45.935841999057324],
            [-97.228291, 45.935656594703417],
            [-97.259632, 45.997184999057389],
            [-97.25934, 46.272785999057767],
            [-97.280568, 46.282320999057788],
            [-97.279948, 46.629347999058375]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "499",
        COUNTYNS: "01384035",
        AFFGEOID: "0500000US48499",
        GEOID: "48499",
        NAME: "Wood",
        LSAD: "06",
        ALAND: 1671154351,
        AWATER: 130748983
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.665389, 32.960433999138253],
            [-95.308957, 32.962571999138227],
            [-95.231193, 32.962195999138231],
            [-95.172947, 32.961828999138234],
            [-95.152206, 33.013449999137542],
            [-95.15211, 32.902640999139031],
            [-95.15274, 32.660948999142306],
            [-95.15341, 32.57011499914357],
            [-95.164973, 32.544582999143927],
            [-95.332164, 32.604251999143088],
            [-95.413061, 32.607110999143053],
            [-95.469093, 32.605641999143067],
            [-95.594541, 32.687025999141952],
            [-95.635017, 32.7203799991415],
            [-95.665389, 32.960433999138253]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "117",
        COUNTYNS: "01581118",
        AFFGEOID: "0500000US55117",
        GEOID: "55117",
        NAME: "Sheboygan",
        LSAD: "06",
        ALAND: 1324905935,
        AWATER: 1967592839
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.162274, 43.891510999057409],
            [-88.041794, 43.891691999057407],
            [-87.728579542308893, 43.892208886514197],
            [-87.736017, 43.873720999057433],
            [-87.726407, 43.810444999057516],
            [-87.700251, 43.767349999057572],
            [-87.708185, 43.722894999057644],
            [-87.706204, 43.679541999057697],
            [-87.790135, 43.563053999057864],
            [-87.791020140028493, 43.543015443985901],
            [-88.040528, 43.542360999057912],
            [-88.160873, 43.542942999057907],
            [-88.162274, 43.891510999057409]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "079",
        COUNTYNS: "00277304",
        AFFGEOID: "0500000US06079",
        GEOID: "06079",
        NAME: "San Luis Obispo",
        LSAD: "06",
        ALAND: 8548552397,
        AWATER: 815578711
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-121.347053956004004, 35.795186202919162],
            [-120.681959, 35.790811999104612],
            [-120.213979, 35.789275999104625],
            [-120.194146, 35.789203999104622],
            [-120.193918, 35.614358999106457],
            [-120.085922, 35.614523999106446],
            [-120.086674, 35.526553999107378],
            [-119.913659, 35.439261999108318],
            [-119.880045, 35.439132999108317],
            [-119.880172, 35.35121099910927],
            [-119.809346, 35.350864999109277],
            [-119.809449, 35.263583999110217],
            [-119.666663, 35.262526999110236],
            [-119.667056, 35.174808999111193],
            [-119.553641, 35.179974999111145],
            [-119.560975, 35.087672999112158],
            [-119.472719, 35.076884999112281],
            [-119.472754, 34.901173999114256],
            [-119.535757, 34.897575999114302],
            [-119.672963, 34.973374999113432],
            [-119.745566, 34.973675999113432],
            [-119.928419, 35.059793999112472],
            [-119.980122, 35.057572999112494],
            [-120.082084, 35.114677999111855],
            [-120.167616, 35.075296999112304],
            [-120.188383, 35.030377999112794],
            [-120.334548, 35.006314999113066],
            [-120.301827, 34.905570999114204],
            [-120.435473, 34.986758999113285],
            [-120.496222, 34.993154999113223],
            [-120.650305211840006, 34.975166301382124],
            [-120.63357, 35.033084999112766],
            [-120.629583, 35.078361999112261],
            [-120.635787, 35.123804999111755],
            [-120.675074, 35.153060999111439],
            [-120.714185, 35.175997999111182],
            [-120.756086, 35.160458999111349],
            [-120.846674, 35.204428999110874],
            [-120.89679, 35.247876999110396],
            [-120.87957, 35.29418399910989],
            [-120.862133, 35.360762999109163],
            [-120.884757, 35.430195999108406],
            [-120.955863, 35.453742999108158],
            [-121.003359, 35.460709999108083],
            [-121.11424, 35.571719999106897],
            [-121.166712, 35.635398999106236],
            [-121.272322, 35.666710999105895],
            [-121.314632, 35.713309999105419],
            [-121.332449, 35.783105999104677],
            [-121.347053956004004, 35.795186202919162]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "073",
        COUNTYNS: "00450494",
        AFFGEOID: "0500000US18073",
        GEOID: "18073",
        NAME: "Jasper",
        LSAD: "06",
        ALAND: 1449422928,
        AWATER: 4569484
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.275882, 41.218594999064777],
            [-87.218862, 41.24202699906467],
            [-87.131339, 41.286244999064479],
            [-86.968125, 41.219657999064758],
            [-86.930079, 41.236797999064684],
            [-86.93017, 41.172317999064958],
            [-86.930759, 40.912417999066115],
            [-86.987438, 40.912176999066133],
            [-86.986438, 40.837920999066469],
            [-87.099793, 40.837606999066487],
            [-87.09839, 40.73654099906696],
            [-87.267298, 40.736581999066956],
            [-87.275882, 41.218594999064777]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "123",
        COUNTYNS: "00198177",
        AFFGEOID: "0500000US08123",
        GEOID: "08123",
        NAME: "Weld",
        LSAD: "06",
        ALAND: 10323705895,
        AWATER: 79558079
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.05672, 40.34928099906891],
            [-104.945551, 40.349098999068907],
            [-104.943371, 40.998074102795542],
            [-104.855273, 40.998047999065733],
            [-104.497058, 41.001804999065712],
            [-104.053249, 41.001405999065717],
            [-103.574522, 41.001720999065718],
            [-103.573774006195009, 41.001721798149816],
            [-103.581844, 40.523382999068012],
            [-104.147714, 40.524388999068009],
            [-104.150494, 40.350244999068913],
            [-104.150332, 40.000855999070822],
            [-104.489757, 40.000612999070825],
            [-104.961405, 40.000336999070811],
            [-104.961071, 40.044143999070563],
            [-105.052823, 40.000259999070813],
            [-105.05541, 40.017709999070725],
            [-105.055393, 40.138262999070051],
            [-105.055089, 40.261792999069378],
            [-105.05672, 40.34928099906891]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "061",
        COUNTYNS: "00516877",
        AFFGEOID: "0500000US21061",
        GEOID: "21061",
        NAME: "Edmonson",
        LSAD: "06",
        ALAND: 784468319,
        AWATER: 13258106
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.469126, 37.32109899909004],
            [-86.187534, 37.340795999089863],
            [-86.157589, 37.335648999089912],
            [-86.049133, 37.215284999090969],
            [-86.056443, 37.167207999091382],
            [-86.074267, 37.053284999092398],
            [-86.113512, 37.061071999092334],
            [-86.218607, 37.104028999091945],
            [-86.28175, 37.080596999092151],
            [-86.399165, 37.169895999091374],
            [-86.469126, 37.32109899909004]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "077",
        COUNTYNS: "00835860",
        AFFGEOID: "0500000US31077",
        GEOID: "31077",
        NAME: "Greeley",
        LSAD: "06",
        ALAND: 1475804206,
        AWATER: 2188873
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.752225, 41.740371999062681],
            [-98.29576, 41.741177999062678],
            [-98.290992, 41.719786999062769],
            [-98.291344, 41.48072699906367],
            [-98.291419, 41.393893999064034],
            [-98.74433, 41.394177999064027],
            [-98.751558, 41.39408399906403],
            [-98.752225, 41.740371999062681]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "10",
        COUNTYFP: "003",
        COUNTYNS: "00217270",
        AFFGEOID: "0500000US10003",
        GEOID: "10003",
        NAME: "New Castle",
        LSAD: "06",
        ALAND: 1104150372,
        AWATER: 175375756
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.773786, 39.722199999072437],
            [-75.753228, 39.757988999072218],
            [-75.717059, 39.792324999072029],
            [-75.662846, 39.821424999071859],
            [-75.594316905220083, 39.834594972163075],
            [-75.570433, 39.839184999071747],
            [-75.481207, 39.829190999071805],
            [-75.415062, 39.801918999071958],
            [-75.459439, 39.765812999072182],
            [-75.47764, 39.715012999072485],
            [-75.509742, 39.686112999072655],
            [-75.535144, 39.647211999072887],
            [-75.559446, 39.629811999073006],
            [-75.543965, 39.595999999073214],
            [-75.512732, 39.57799999907332],
            [-75.527676, 39.535277999073578],
            [-75.528088, 39.498113999073816],
            [-75.593068, 39.479185999073927],
            [-75.57183, 39.438896999074174],
            [-75.521682, 39.387870999074501],
            [-75.505643167352886, 39.370394559154207],
            [-75.584341, 39.308717999075],
            [-75.619631, 39.310057999074992],
            [-75.651159, 39.291593999075111],
            [-75.714901, 39.299365999075079],
            [-75.76044141645049, 39.296789620175183],
            [-75.766895, 39.377498999074575],
            [-75.76690460670919, 39.377651592625767],
            [-75.788596, 39.72219899907244],
            [-75.773786, 39.722199999072437]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "25",
        COUNTYFP: "027",
        COUNTYNS: "00606940",
        AFFGEOID: "0500000US25027",
        GEOID: "25027",
        NAME: "Worcester",
        LSAD: "06",
        ALAND: 3912560126,
        AWATER: 177461132
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.291288, 42.479524999060281],
            [-72.244868, 42.513438999060178],
            [-72.271469, 42.547229999060079],
            [-72.227752, 42.615866999059904],
            [-72.224932, 42.638936999059844],
            [-72.271956, 42.67467199905974],
            [-72.283034092510903, 42.722010249683628],
            [-72.203613479806791, 42.71981852773952],
            [-72.124526, 42.717635999059624],
            [-72.081365050187998, 42.716456537600834],
            [-71.929030059053503, 42.712293671592249],
            [-71.898768771936588, 42.711466719868447],
            [-71.858483, 42.633814999059837],
            [-71.775755, 42.644229999059817],
            [-71.664601, 42.61159799905991],
            [-71.678969, 42.530429999060125],
            [-71.635869, 42.524023999060155],
            [-71.53878, 42.543029999060096],
            [-71.5591, 42.411942999060464],
            [-71.624702, 42.350464999060648],
            [-71.585168, 42.31097699906077],
            [-71.551126, 42.326394999060717],
            [-71.486797, 42.330188999060702],
            [-71.506478, 42.264275999060906],
            [-71.522178, 42.266453999060893],
            [-71.549453, 42.266094999060897],
            [-71.586759, 42.259544999060921],
            [-71.555738, 42.182502999061157],
            [-71.502626, 42.191415999061135],
            [-71.478119, 42.156781999061252],
            [-71.478522, 42.131377999061336],
            [-71.499689, 42.103489999061424],
            [-71.498287, 42.064877999061558],
            [-71.498224013124698, 42.015874110080205],
            [-71.559439, 42.01434199906172],
            [-71.591096527631009, 42.013513342618907],
            [-71.606203441134099, 42.013117909300526],
            [-71.799242, 42.008064999061745],
            [-71.80065, 42.023568999061695],
            [-71.884676835903704, 42.025059350545988],
            [-71.987326, 42.02687999906167],
            [-72.102159434526797, 42.028630239116474],
            [-72.135731461946691, 42.029141929003266],
            [-72.135011, 42.161783999061228],
            [-72.263924, 42.183830999061165],
            [-72.221218, 42.245251999060976],
            [-72.211079, 42.251261999060951],
            [-72.213973, 42.294256999060806],
            [-72.210795, 42.311379999060762],
            [-72.288734, 42.352023999060648],
            [-72.314599, 42.343525999060667],
            [-72.291288, 42.479524999060281]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "23",
        COUNTYFP: "015",
        COUNTYNS: "00581293",
        AFFGEOID: "0500000US23015",
        GEOID: "23015",
        NAME: "Lincoln",
        LSAD: "06",
        ALAND: 1180630792,
        AWATER: 631357625
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.791476, 44.040903999057235],
            [-69.758208, 44.139333999057136],
            [-69.666298, 44.124002999057154],
            [-69.656942, 44.125460999057154],
            [-69.665441, 44.227575999057066],
            [-69.650171, 44.27962499905702],
            [-69.547398, 44.260987999057036],
            [-69.528223, 44.275446999057031],
            [-69.506382, 44.342869999056973],
            [-69.434992, 44.33186599905698],
            [-69.451251, 44.313949999056994],
            [-69.432837, 44.226739999057074],
            [-69.337672, 44.209369999057074],
            [-69.282268, 44.052583999057212],
            [-69.363996, 44.012872999057272],
            [-69.380489719343103, 43.943640232613838],
            [-69.393288, 43.956419999057331],
            [-69.422048, 43.923046999057362],
            [-69.438066, 43.909538999057396],
            [-69.50329, 43.837672999057467],
            [-69.552606, 43.841346999057471],
            [-69.578527, 43.823315999057492],
            [-69.650818, 43.80378499905752],
            [-69.695815456259893, 43.796054966348834],
            [-69.692076, 43.857174999057449],
            [-69.743263, 43.893925999057409],
            [-69.700152, 44.006584999057281],
            [-69.813517, 44.02682099905725],
            [-69.791476, 44.040903999057235]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "143",
        COUNTYNS: "01480157",
        AFFGEOID: "0500000US51143",
        GEOID: "51143",
        NAME: "Pittsylvania",
        LSAD: "06",
        ALAND: 2509683753,
        AWATER: 23925747
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.640652, 36.856138999094192],
            [-79.594069, 37.042205999092502],
            [-79.479921, 37.006034999092833],
            [-79.442859, 37.05586699909238],
            [-79.341422, 37.13716599909165],
            [-79.217104, 37.118873999091811],
            [-79.194525, 37.055815999092381],
            [-79.092264, 37.061497999092339],
            [-79.218455495922285, 36.541444535273243],
            [-79.343115919369495, 36.541143099065643],
            [-79.302564, 36.577430999096791],
            [-79.365013, 36.607796999096507],
            [-79.398094, 36.644036999096166],
            [-79.422191, 36.600517999096581],
            [-79.500848, 36.618261999096411],
            [-79.518995, 36.603358999096557],
            [-79.456471, 36.576970999096815],
            [-79.470056673924304, 36.540836148883749],
            [-79.510647, 36.540737999097146],
            [-79.513647662508589, 36.540748204167841],
            [-79.714850811052003, 36.541432483838449],
            [-79.640652, 36.856138999094192]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "107",
        COUNTYNS: "00758508",
        AFFGEOID: "0500000US29107",
        GEOID: "29107",
        NAME: "Lafayette",
        LSAD: "06",
        ALAND: 1627640379,
        AWATER: 27215050
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.110005, 39.01498699907696],
            [-94.104823, 39.143506999076088],
            [-94.024206, 39.154536999076015],
            [-93.980088, 39.205873999075692],
            [-93.988892, 39.152785999076031],
            [-93.840147, 39.215466999075609],
            [-93.758463, 39.207020999075674],
            [-93.650526, 39.248213999075404],
            [-93.49196, 39.22346099907557],
            [-93.477233, 39.292795999075118],
            [-93.496377, 38.942836999077436],
            [-93.497278, 38.928429999077544],
            [-93.834719, 38.937908999077472],
            [-94.114175, 38.917303999077618],
            [-94.110005, 39.01498699907696]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "133",
        COUNTYNS: "00758521",
        AFFGEOID: "0500000US29133",
        GEOID: "29133",
        NAME: "Mississippi",
        LSAD: "06",
        ALAND: 1065995413,
        AWATER: 44494832
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.519809, 36.869616999094077],
            [-89.501683, 36.906261999093736],
            [-89.465393, 36.935728999093463],
            [-89.375064, 36.964946999093208],
            [-89.307436691172981, 37.028759448720535],
            [-89.257608, 37.015495999092749],
            [-89.195039, 36.989767999092983],
            [-89.132915, 36.982056999093039],
            [-89.098843, 36.957849999093263],
            [-89.103135448326597, 36.944760899415684],
            [-89.120472, 36.891895999093855],
            [-89.147674, 36.847147999094275],
            [-89.155891, 36.789125999094814],
            [-89.155984909070384, 36.786292662466252],
            [-89.15699, 36.755967999095112],
            [-89.202511, 36.716617999095476],
            [-89.165488, 36.662425999095994],
            [-89.175649772346873, 36.651319261649597],
            [-89.199136, 36.625648999096342],
            [-89.227319, 36.569374999096873],
            [-89.278935, 36.577698999096789],
            [-89.324658, 36.62403199909636],
            [-89.327319777009009, 36.62394628786685],
            [-89.373741, 36.70294799909562],
            [-89.519701, 36.84789599909427],
            [-89.519809, 36.869616999094077]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "019",
        COUNTYNS: "00066845",
        AFFGEOID: "0500000US05019",
        GEOID: "05019",
        NAME: "Clark",
        LSAD: "06",
        ALAND: 2243130473,
        AWATER: 43387485
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.478646, 34.340850999120775],
            [-93.408124, 34.339526999120778],
            [-93.35684, 34.280305999121488],
            [-93.199428, 34.248063999121875],
            [-93.035039, 34.236663999122008],
            [-93.038142, 34.155058999123],
            [-92.886285, 34.155876999122995],
            [-92.893116, 33.983283999125106],
            [-92.961419, 33.974158999125216],
            [-92.89314, 33.902750999126091],
            [-92.893534, 33.809942999127252],
            [-93.030156, 33.76988099912775],
            [-93.10429, 33.777010999127668],
            [-93.139279, 33.814819999127195],
            [-93.264485, 33.83228199912697],
            [-93.373406, 33.95706999912543],
            [-93.434165, 34.049738999124287],
            [-93.46169, 34.205750999122387],
            [-93.502208, 34.214105999122289],
            [-93.478646, 34.340850999120775]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "093",
        COUNTYNS: "01266991",
        AFFGEOID: "0500000US46093",
        GEOID: "46093",
        NAME: "Meade",
        LSAD: "06",
        ALAND: 8989706542,
        AWATER: 30712698
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.567653, 44.604097999056826],
            [-102.964125, 44.604286999056832],
            [-102.957371, 45.039568999056769],
            [-101.99999, 45.038273999056777],
            [-102.000432, 44.511662999056888],
            [-102.001068, 44.510925999056859],
            [-102.112535, 44.435885999056914],
            [-102.189438, 44.427193999056925],
            [-102.282876, 44.448625999056908],
            [-102.300002, 44.37498599905696],
            [-102.341635, 44.348771999056964],
            [-102.393522, 44.202965999057085],
            [-102.388045, 44.140182999057146],
            [-103.452453, 44.140771999057144],
            [-103.453016, 44.256608999057043],
            [-103.574396, 44.256076999057044],
            [-103.567653, 44.604097999056826]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "027",
        COUNTYNS: "01034222",
        AFFGEOID: "0500000US38027",
        GEOID: "38027",
        NAME: "Eddy",
        LSAD: "06",
        ALAND: 1632134148,
        AWATER: 36114199
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.297204, 47.846810999061567],
            [-98.556378, 47.847458999061573],
            [-98.525516, 47.846275999061561],
            [-98.525178, 47.672496999061003],
            [-98.500181, 47.672412999061017],
            [-98.500085, 47.587227999060737],
            [-99.265846, 47.587617999060761],
            [-99.297318, 47.673003999061009],
            [-99.297204, 47.846810999061567]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "25",
        COUNTYFP: "007",
        COUNTYNS: "00606930",
        AFFGEOID: "0500000US25007",
        GEOID: "25007",
        NAME: "Dukes",
        LSAD: "06",
        ALAND: 267309256,
        AWATER: 1004275270
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.833802, 41.353385999064187],
              [-70.757797, 41.365701999064143],
              [-70.686881, 41.441333999063843],
              [-70.603555, 41.482383999063671],
              [-70.553277, 41.452954999063785],
              [-70.496197, 41.42490799906389],
              [-70.463833, 41.419144999063924],
              [-70.448262, 41.3536509990642],
              [-70.577454, 41.349162999064212],
              [-70.693635, 41.34283299906425],
              [-70.768687, 41.303701999064408],
              [-70.821284, 41.251013999064625],
              [-70.833397, 41.31677799906435],
              [-70.833802, 41.353385999064187]
            ]
          ],
          [
            [
              [-70.934986, 41.454698999063787],
              [-70.806861, 41.49758299906361],
              [-70.726088944807003, 41.543236930981038],
              [-70.697498, 41.527157999063505],
              [-70.669058366600495, 41.512929661738156],
              [-70.734306, 41.486334999063658],
              [-70.79027, 41.446338999063826],
              [-70.857528, 41.425766999063896],
              [-70.948431, 41.409192999063954],
              [-70.934986, 41.454698999063787]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "02",
        COUNTYFP: "282",
        COUNTYNS: "01419986",
        AFFGEOID: "0500000US02282",
        GEOID: "02282",
        NAME: "Yakutat",
        LSAD: "03",
        ALAND: 19746741191,
        AWATER: 35390272145
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-143.893325, 60.024165999184213],
            [-143.193519, 60.52182799919256],
            [-141.790619, 60.521898999192558],
            [-141.790601, 60.435237999191088],
            [-141.213451, 60.43526799919109],
            [-141.213444, 60.391675999190348],
            [-141.001849665644016, 60.39168801108665],
            [-141.00184, 60.306104999188904],
            [-140.53509, 60.224223999187551],
            [-140.472292, 60.310589999188977],
            [-139.989142, 60.185239999186898],
            [-139.698361, 60.34042099918949],
            [-139.086669, 60.357653999189779],
            [-139.082246, 60.323824999189206],
            [-139.200346, 60.090700999185316],
            [-139.046426, 59.998234999183779],
            [-138.702053, 59.910244999182332],
            [-138.643422, 59.792501999180423],
            [-138.584819, 59.752452999179759],
            [-137.604277, 59.243056999171621],
            [-137.498558, 58.986693999167613],
            [-137.52567710531298, 58.908974493306523],
            [-137.936159895178008, 58.810650222615408],
            [-137.924608, 58.843927999165423],
            [-137.951995, 58.886028999166065],
            [-138.066332, 58.957125999167154],
            [-138.224323, 59.032215999168322],
            [-138.357909, 59.069393999168895],
            [-138.636702, 59.130584999169855],
            [-138.807186, 59.208771999171077],
            [-138.919749, 59.248530999171699],
            [-139.07148, 59.287149999172307],
            [-139.271031, 59.337420999173112],
            [-139.420168, 59.379759999173778],
            [-139.632896, 59.459905999175049],
            [-139.861306, 59.546677999176445],
            [-139.74266, 59.623870999177683],
            [-139.585789, 59.642764999177984],
            [-139.588777, 59.708967999179066],
            [-139.608545, 59.821960999180888],
            [-139.776836, 59.8338349991811],
            [-139.871222, 59.802610999180573],
            [-140.080423, 59.756298999179826],
            [-140.176224, 59.735964999179487],
            [-140.242577, 59.687887999178713],
            [-140.4119, 59.699648999178905],
            [-140.792511, 59.72857299917937],
            [-140.922635, 59.751686999179761],
            [-141.156497, 59.813581999180748],
            [-141.392811, 59.87002799918168],
            [-141.485207, 59.925023999182585],
            [-141.595376, 59.96190499918319],
            [-141.73624, 59.96190499918319],
            [-141.912218, 60.009778999183965],
            [-142.062454, 60.023780999184204],
            [-142.426572, 60.071065999184995],
            [-142.744868, 60.094129999185377],
            [-142.908859, 60.09032799918532],
            [-143.0687, 60.068602999184961],
            [-143.267818, 60.058654999184775],
            [-143.624152, 60.037256999184436],
            [-143.781649, 60.010353999183991],
            [-143.893326, 59.9867216050772],
            [-143.893325, 60.024165999184213]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "139",
        COUNTYNS: "00069903",
        AFFGEOID: "0500000US05139",
        GEOID: "05139",
        NAME: "Union",
        LSAD: "06",
        ALAND: 2691555882,
        AWATER: 41400824
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.978469, 33.377279999132753],
            [-92.660238, 33.355612999133044],
            [-92.567156, 33.3671999991329],
            [-92.431092, 33.269789999134161],
            [-92.350432, 33.298940999133784],
            [-92.356064, 33.281158999134014],
            [-92.22406, 33.251781999134387],
            [-92.222362, 33.209257999134948],
            [-92.137527, 33.162955999135569],
            [-92.143596, 33.09824199913642],
            [-92.069146592481289, 33.008482429394505],
            [-92.222825, 33.00907999913759],
            [-92.501383, 33.012159999137573],
            [-92.723553, 33.014327999137542],
            [-92.724743173179291, 33.014341766812343],
            [-92.971137, 33.017191999137495],
            [-92.988707610715011, 33.017250939289688],
            [-92.978469, 33.377279999132753]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "019",
        COUNTYNS: "01026340",
        AFFGEOID: "0500000US37019",
        GEOID: "37019",
        NAME: "Brunswick",
        LSAD: "06",
        ALAND: 2199384961,
        AWATER: 518918118
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.640688, 34.009853999124772],
            [-78.555043, 34.067070999124077],
            [-78.545218, 34.145746999123119],
            [-78.507338, 34.169245999122836],
            [-78.422734, 34.139630999123199],
            [-78.374295, 34.2048929991224],
            [-78.254622, 34.216304999122265],
            [-78.16235, 34.357006999120571],
            [-78.137302, 34.371710999120403],
            [-78.029922, 34.331767999120878],
            [-77.956605, 34.234490999122052],
            [-77.885455075521094, 34.038240983977445],
            [-77.915536, 33.971722999125248],
            [-77.934827135085698, 33.920548504923183],
            [-77.960172, 33.853314999126702],
            [-78.006765, 33.858703999126639],
            [-78.018689, 33.888288999126281],
            [-78.136952, 33.91217799912598],
            [-78.276147, 33.912363999125979],
            [-78.383964, 33.901945999126106],
            [-78.541087, 33.851111999126736],
            [-78.615932, 33.91552299912594],
            [-78.650871280533295, 33.945055719658463],
            [-78.640688, 34.009853999124772]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "011",
        COUNTYNS: "00349230",
        AFFGEOID: "0500000US13011",
        GEOID: "13011",
        NAME: "Banks",
        LSAD: "06",
        ALAND: 601117836,
        AWATER: 4651151
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.66967, 34.366099999120472],
            [-83.615251, 34.431747999119686],
            [-83.537436, 34.491733999118985],
            [-83.45977, 34.481141999119103],
            [-83.398396, 34.460882999119349],
            [-83.393915, 34.32480099912096],
            [-83.337849, 34.261032999121724],
            [-83.355269, 34.223729999122178],
            [-83.402428, 34.197498999122487],
            [-83.480361, 34.262037999121709],
            [-83.620115, 34.295275999121323],
            [-83.66967, 34.366099999120472]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "077",
        COUNTYNS: "00198154",
        AFFGEOID: "0500000US08077",
        GEOID: "08077",
        NAME: "Mesa",
        LSAD: "06",
        ALAND: 8621911419,
        AWATER: 31428360
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.051512, 39.126094999076201],
            [-109.051223831016998, 39.366677548660938],
            [-108.106015, 39.366150999074641],
            [-107.430949, 39.366177999074644],
            [-107.465187, 39.31451499907498],
            [-107.394486, 39.256298999075348],
            [-107.428073, 39.257595999075335],
            [-107.500606, 39.217915999075608],
            [-107.637893, 39.142019999076098],
            [-107.76549, 39.043293999076759],
            [-107.85824, 39.079803999076518],
            [-107.982173, 39.059877999076654],
            [-108.094786, 38.979683999077203],
            [-108.140323, 38.908766999077677],
            [-108.378953, 38.829107999078225],
            [-108.378698, 38.668096999079381],
            [-108.379136, 38.499986999080583],
            [-109.05996197258898, 38.499989030101396],
            [-109.051512, 39.126094999076201]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "195",
        COUNTYNS: "01688004",
        AFFGEOID: "0500000US13195",
        GEOID: "13195",
        NAME: "Madison",
        LSAD: "06",
        ALAND: 731184825,
        AWATER: 8484621
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.402428, 34.197498999122487],
            [-83.355269, 34.223729999122178],
            [-83.313591, 34.259143999121754],
            [-83.172745, 34.238521999121993],
            [-83.11336, 34.273529999121571],
            [-83.078004, 34.223605999122171],
            [-83.103264, 34.170527999122811],
            [-82.976294, 34.04321899912437],
            [-83.048889, 34.015803999124692],
            [-83.123878, 34.047557999124308],
            [-83.258413, 33.999097999124899],
            [-83.294479, 34.008143999124805],
            [-83.360028, 34.040571999124403],
            [-83.386763, 34.050240999124284],
            [-83.357051, 34.116198999123476],
            [-83.402428, 34.197498999122487]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "283",
        COUNTYNS: "00347505",
        AFFGEOID: "0500000US13283",
        GEOID: "13283",
        NAME: "Treutlen",
        LSAD: "06",
        ALAND: 516529395,
        AWATER: 7805621
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.748732, 32.329149999146914],
            [-82.647733, 32.512506999144364],
            [-82.488941, 32.491829999144642],
            [-82.38194, 32.440595999145359],
            [-82.409118, 32.353829999146576],
            [-82.40913, 32.353737999146574],
            [-82.649298, 32.311131999147179],
            [-82.655486, 32.297560999147365],
            [-82.67377, 32.293594999147423],
            [-82.721964, 32.309282999147207],
            [-82.748732, 32.329149999146914]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "55",
        COUNTYFP: "073",
        COUNTYNS: "01581096",
        AFFGEOID: "0500000US55073",
        GEOID: "55073",
        NAME: "Marathon",
        LSAD: "06",
        ALAND: 4001465174,
        AWATER: 81219897
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.315038, 45.033828999056759],
            [-90.197514, 45.033639999056767],
            [-90.197765, 45.120603999056769],
            [-90.043685, 45.12021399905678],
            [-89.425971, 45.119097999056784],
            [-89.224214, 45.118566999056775],
            [-89.223814, 45.029245999056762],
            [-89.223745, 44.681364999056811],
            [-89.844931, 44.68494499905681],
            [-89.963861, 44.684827999056793],
            [-90.316253, 44.685153999056816],
            [-90.315038, 45.033828999056759]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "001",
        COUNTYNS: "00161526",
        AFFGEOID: "0500000US01001",
        GEOID: "01001",
        NAME: "Autauga",
        LSAD: "06",
        ALAND: 1539614693,
        AWATER: 25744269
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.917595, 32.664168999142269],
            [-86.71339, 32.661731999142312],
            [-86.714219, 32.705693999141701],
            [-86.413116, 32.707385999141678],
            [-86.411172, 32.409936999145785],
            [-86.459552, 32.405440999145846],
            [-86.496774, 32.344436999146701],
            [-86.653419, 32.39724699914597],
            [-86.781354, 32.392493999146026],
            [-86.816107, 32.309969999147192],
            [-86.814912, 32.34080299914676],
            [-86.906403, 32.536711999144032],
            [-86.917595, 32.664168999142269]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "31",
        COUNTYFP: "029",
        COUNTYNS: "00835836",
        AFFGEOID: "0500000US31029",
        GEOID: "31029",
        NAME: "Chase",
        LSAD: "06",
        ALAND: 2316533465,
        AWATER: 7978174
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.051304798597016, 40.440007992445445],
            [-102.051294151579, 40.697546831456151],
            [-101.345365, 40.69809299906715],
            [-101.342788, 40.350443999068908],
            [-102.051308551844016, 40.349221355941118],
            [-102.051304798597016, 40.440007992445445]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "143",
        COUNTYNS: "00485035",
        AFFGEOID: "0500000US20143",
        GEOID: "20143",
        NAME: "Ottawa",
        LSAD: "06",
        ALAND: 1866683174,
        AWATER: 1567629
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.931614, 39.277093999075213],
            [-97.929097, 39.306396999075034],
            [-97.369839, 39.306010999075021],
            [-97.371154, 39.131960999076178],
            [-97.371614, 38.957851999077342],
            [-97.92856, 38.958394999077335],
            [-97.929746, 39.219272999075599],
            [-97.931614, 39.277093999075213]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "055",
        COUNTYNS: "00277292",
        AFFGEOID: "0500000US06055",
        GEOID: "06055",
        NAME: "Napa",
        LSAD: "06",
        ALAND: 1938165958,
        AWATER: 104249021
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.627396, 38.667505999079367],
            [-122.46389, 38.705202999079106],
            [-122.397989, 38.804000999078411],
            [-122.395056, 38.864244999077982],
            [-122.287998, 38.839930999078156],
            [-122.224206, 38.699983999079144],
            [-122.168301, 38.655295999079463],
            [-122.103281, 38.513347999080487],
            [-122.126389, 38.428917999081115],
            [-122.061379, 38.32741099908187],
            [-122.205982, 38.31571299908196],
            [-122.195971, 38.162990999083135],
            [-122.213464, 38.154893999083193],
            [-122.406786, 38.155631999083191],
            [-122.349564, 38.193971999082891],
            [-122.543893, 38.519965999080455],
            [-122.602659, 38.557495999080174],
            [-122.646421, 38.598592999079884],
            [-122.627396, 38.667505999079367]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "137",
        COUNTYNS: "01101856",
        AFFGEOID: "0500000US40137",
        GEOID: "40137",
        NAME: "Stephens",
        LSAD: "06",
        ALAND: 2253930695,
        AWATER: 54690951
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.141799, 34.681157999116763],
            [-98.089098, 34.681118999116762],
            [-97.668425, 34.681343999116763],
            [-97.562757, 34.68114699911677],
            [-97.562323, 34.507035999118798],
            [-97.562575, 34.289181999121382],
            [-98.139226, 34.289774999121377],
            [-98.139293, 34.489278999119009],
            [-98.142148, 34.506993999118798],
            [-98.141799, 34.681157999116763]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "063",
        COUNTYNS: "01383817",
        AFFGEOID: "0500000US48063",
        GEOID: "48063",
        NAME: "Camp",
        LSAD: "06",
        ALAND: 507184763,
        AWATER: 19186958
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.152206, 33.013449999137542],
            [-95.12613, 33.034580999137262],
            [-94.936129, 33.073832999136748],
            [-94.819828, 32.982885999137956],
            [-94.768855, 32.925833999138717],
            [-94.719942, 32.904501999139008],
            [-95.15211, 32.902640999139031],
            [-95.152206, 33.013449999137542]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "045",
        COUNTYNS: "01673664",
        AFFGEOID: "0500000US51045",
        GEOID: "51045",
        NAME: "Craig",
        LSAD: "06",
        ALAND: 849759834,
        AWATER: 2803739
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.46482, 37.426143999089135],
            [-80.39988, 37.462313999088835],
            [-80.291644, 37.536504999088201],
            [-80.28244, 37.585480999087792],
            [-80.223386, 37.623184999087478],
            [-80.224303244376799, 37.623991370258366],
            [-80.145565, 37.596427999087702],
            [-80.020554, 37.647441999087256],
            [-79.969071, 37.544407999088129],
            [-80.074119, 37.422468999089183],
            [-80.246508, 37.353941999089756],
            [-80.262184, 37.341525999089868],
            [-80.32484, 37.368832999089626],
            [-80.430943, 37.316377999090086],
            [-80.444612, 37.328472999089982],
            [-80.469568890504192, 37.4290254650818],
            [-80.46482, 37.426143999089135]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "021",
        COUNTYNS: "01689673",
        AFFGEOID: "0500000US54021",
        GEOID: "54021",
        NAME: "Gilmer",
        LSAD: "06",
        ALAND: 876717634,
        AWATER: 4082111
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.002777, 38.846024999078118],
            [-81.033632, 39.009583999076987],
            [-81.007231, 39.008784999076987],
            [-80.81297, 39.109400999076314],
            [-80.728326, 39.095678999076405],
            [-80.716815, 39.008429999076988],
            [-80.605799, 38.904166999077724],
            [-80.669909, 38.892772999077792],
            [-80.98495, 38.717897999079007],
            [-81.051909, 38.779995999078572],
            [-81.002777, 38.846024999078118]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "063",
        COUNTYNS: "01804512",
        AFFGEOID: "0500000US72063",
        GEOID: "72063",
        NAME: "Gurabo",
        LSAD: "13",
        ALAND: 72228545,
        AWATER: 1101116
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.021747, 18.305033999436731],
            [-65.968053, 18.3099909994366],
            [-65.942429, 18.293468999437035],
            [-65.918535, 18.270129999437653],
            [-65.945842, 18.252654999438121],
            [-65.951019, 18.229291999438733],
            [-65.99765, 18.206991999439325],
            [-66.029894, 18.290869999437103],
            [-66.021747, 18.305033999436731]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "227",
        COUNTYNS: "01383899",
        AFFGEOID: "0500000US48227",
        GEOID: "48227",
        NAME: "Howard",
        LSAD: "06",
        ALAND: 2333039139,
        AWATER: 8841781
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.68874, 32.525221999144186],
            [-101.174571, 32.524111999144196],
            [-101.183997, 32.087207999150351],
            [-101.264216, 32.087135999150355],
            [-101.695011, 32.087531999150336],
            [-101.68874, 32.525221999144186]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "085",
        COUNTYNS: "01480132",
        AFFGEOID: "0500000US51085",
        GEOID: "51085",
        NAME: "Hanover",
        LSAD: "06",
        ALAND: 1211828533,
        AWATER: 12648364
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.797306, 37.729357999086602],
            [-77.687469, 38.007805999084347],
            [-77.642518, 37.990687999084479],
            [-77.48417, 37.883368999085334],
            [-77.397317, 37.777288999086203],
            [-77.345215, 37.789174999086107],
            [-77.292404, 37.714066999086718],
            [-77.186674, 37.657707999087194],
            [-77.108191, 37.660210999087155],
            [-77.123675, 37.62688099908744],
            [-77.140457, 37.592168999087733],
            [-77.240988, 37.538087999088177],
            [-77.391227, 37.598196999087683],
            [-77.407189, 37.612523999087557],
            [-77.445858, 37.684055999086965],
            [-77.63067, 37.705672999086779],
            [-77.711709, 37.706284999086783],
            [-77.797306, 37.729357999086602]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "46",
        COUNTYFP: "129",
        COUNTYNS: "01266973",
        AFFGEOID: "0500000US46129",
        GEOID: "46129",
        NAME: "Walworth",
        LSAD: "06",
        ALAND: 1835347958,
        AWATER: 93215243
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.430079, 45.594822999056994],
            [-99.717466, 45.593427999056992],
            [-99.707705, 45.593390999057],
            [-99.708987, 45.245865999056811],
            [-100.260223, 45.246809999056822],
            [-100.307384, 45.29190899905683],
            [-100.274176, 45.381844999056867],
            [-100.341924, 45.472903999056918],
            [-100.486561, 45.536520999056954],
            [-100.430079, 45.594822999056994]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "067",
        COUNTYNS: "00198148",
        AFFGEOID: "0500000US08067",
        GEOID: "08067",
        NAME: "La Plata",
        LSAD: "06",
        ALAND: 4376522002,
        AWATER: 25375721
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-108.290459, 37.145974999091578],
            [-108.290423, 37.223583999090891],
            [-108.219688, 37.287414999090338],
            [-108.197776, 37.354938999089761],
            [-108.105857, 37.382067999089514],
            [-108.038409, 37.451930999088908],
            [-108.022895, 37.590586999087755],
            [-107.970086, 37.639586999087335],
            [-107.482179, 37.639500999087332],
            [-107.482131, 37.422672999089166],
            [-107.481736999155004, 37.000004579407182],
            [-108.000623, 37.000000999092883],
            [-108.379302950094001, 36.999564685404394],
            [-108.290459, 37.145974999091578]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "159",
        COUNTYNS: "01074091",
        AFFGEOID: "0500000US39159",
        GEOID: "39159",
        NAME: "Union",
        LSAD: "06",
        ALAND: 1118204745,
        AWATER: 13288624
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.520227, 40.504076999068104],
            [-83.418378, 40.505231999068101],
            [-83.267767, 40.506905999068096],
            [-83.248298, 40.500996999068114],
            [-83.248595, 40.443988999068409],
            [-83.248428, 40.244465999069469],
            [-83.17233, 40.244256999069464],
            [-83.169981, 40.143086999070029],
            [-83.206273, 40.107732999070215],
            [-83.503714, 40.111467999070193],
            [-83.494498, 40.225466999069575],
            [-83.551338, 40.229369999069547],
            [-83.520227, 40.504076999068104]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "42",
        COUNTYFP: "005",
        COUNTYNS: "01213658",
        AFFGEOID: "0500000US42005",
        GEOID: "42005",
        NAME: "Armstrong",
        LSAD: "06",
        ALAND: 1691718473,
        AWATER: 27625399
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.69293, 40.669743999067279],
            [-79.690711, 41.170690999064973],
            [-79.679288, 41.11624199906521],
            [-79.620787, 40.975166999065834],
            [-79.284998, 41.009294999065695],
            [-79.215214, 41.050514999065499],
            [-79.210722, 41.032420999065579],
            [-79.21531, 40.911345999066128],
            [-79.215235, 40.776015999066772],
            [-79.450176, 40.530148999067983],
            [-79.668345, 40.680048999067225],
            [-79.692587, 40.669731999067281],
            [-79.69293, 40.669743999067279]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "089",
        COUNTYNS: "00516891",
        AFFGEOID: "0500000US21089",
        GEOID: "21089",
        NAME: "Greenup",
        LSAD: "06",
        ALAND: 892135587,
        AWATER: 25884322
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.071005, 38.595199999079888],
            [-83.024075, 38.683305999079266],
            [-83.030334931231096, 38.716867675735124],
            [-83.011816, 38.730056999078926],
            [-82.943147, 38.743279999078837],
            [-82.889193, 38.756075999078746],
            [-82.882289993609291, 38.741619222348447],
            [-82.871192, 38.718376999079013],
            [-82.869592, 38.678176999079291],
            [-82.851314, 38.604333999079827],
            [-82.811542384650409, 38.572369588641962],
            [-82.800112, 38.563182999080119],
            [-82.724846, 38.557599999080168],
            [-82.675724, 38.515503999080472],
            [-82.664116907515691, 38.507715589337529],
            [-82.769047, 38.433714999081083],
            [-82.810637, 38.393023999081386],
            [-82.817454, 38.373934999081527],
            [-82.970648, 38.393665999081378],
            [-83.051751, 38.465403999080849],
            [-83.166419, 38.503964999080566],
            [-83.071005, 38.595199999079888]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "293",
        COUNTYNS: "00346759",
        AFFGEOID: "0500000US13293",
        GEOID: "13293",
        NAME: "Upson",
        LSAD: "06",
        ALAND: 837697011,
        AWATER: 10663282
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.52702, 32.970547999138127],
            [-84.490015, 32.993486999137822],
            [-84.27014, 32.991010999137842],
            [-84.122361, 32.989575999137863],
            [-84.12334, 32.93218399913863],
            [-84.124275, 32.849561999139745],
            [-84.124332, 32.800934999140402],
            [-84.202628, 32.690017999141922],
            [-84.235803, 32.73828399914126],
            [-84.286246, 32.747625999141135],
            [-84.380398, 32.779175999140705],
            [-84.506888, 32.881787999139313],
            [-84.526902, 32.914097999138875],
            [-84.52702, 32.970547999138127]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "05",
        COUNTYFP: "017",
        COUNTYNS: "00069160",
        AFFGEOID: "0500000US05017",
        GEOID: "05017",
        NAME: "Chicot",
        LSAD: "06",
        ALAND: 1668823393,
        AWATER: 120315319
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.45737, 33.38897399913261],
            [-91.453438, 33.563702999130356],
            [-91.34954, 33.563645999130365],
            [-91.35081, 33.519475999130925],
            [-91.24576, 33.517147999130962],
            [-91.205644985947899, 33.546978370063471],
            [-91.215671, 33.529422999130809],
            [-91.189375, 33.493004999131266],
            [-91.171799, 33.46234199913166],
            [-91.147663, 33.427171999132113],
            [-91.113764, 33.393123999132555],
            [-91.142219, 33.34898899913312],
            [-91.125539, 33.280254999134023],
            [-91.086137, 33.273651999134117],
            [-91.068708, 33.232935999134632],
            [-91.084366, 33.180855999135325],
            [-91.104317, 33.13159799913597],
            [-91.153015, 33.135092999135921],
            [-91.180836, 33.098363999136403],
            [-91.120379, 33.054529999136996],
            [-91.159606098012986, 33.011242320444374],
            [-91.166073, 33.004105999137671],
            [-91.2645640502205, 33.004738823408061],
            [-91.435931080331798, 33.005839890109748],
            [-91.460391771831908, 33.005997054839149],
            [-91.45737, 33.38897399913261]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "023",
        COUNTYNS: "00198127",
        AFFGEOID: "0500000US08023",
        GEOID: "08023",
        NAME: "Costilla",
        LSAD: "06",
        ALAND: 3179452304,
        AWATER: 8828906
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.720308, 37.285613999090344],
            [-105.743339, 37.356797999089729],
            [-105.500567, 37.560140999087992],
            [-105.485516, 37.57789899908785],
            [-105.295587, 37.654415999087213],
            [-105.187549, 37.619668999087502],
            [-105.154176, 37.293128999090285],
            [-105.155042748048004, 36.995474444703532],
            [-105.220505200781005, 36.995563235579425],
            [-105.251296, 36.995604999092933],
            [-105.41931, 36.995855999092925],
            [-105.533922, 36.995874999092919],
            [-105.716471, 36.995848999092921],
            [-105.718469683991003, 36.995845926393926],
            [-105.770673, 37.041776999092505],
            [-105.720308, 37.285613999090344]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "029",
        COUNTYNS: "00395406",
        AFFGEOID: "0500000US16029",
        GEOID: "16029",
        NAME: "Caribou",
        LSAD: "06",
        ALAND: 4569240963,
        AWATER: 89216874
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-112.149835, 42.8955579990592],
            [-112.082053, 42.950424999059059],
            [-112.063023, 43.022363999058896],
            [-111.589489, 43.020051999058914],
            [-111.044052705117991, 43.019407031957918],
            [-111.043959, 42.964449999059021],
            [-111.043564, 42.722623999059621],
            [-111.045530292713011, 42.513912876835271],
            [-111.171174, 42.473199999060284],
            [-111.234827, 42.478328999060274],
            [-111.241099, 42.602456999059946],
            [-111.279388, 42.552860999060066],
            [-111.368062, 42.590293999059973],
            [-111.61763, 42.583036999059978],
            [-111.599227, 42.41539499906046],
            [-111.733742, 42.430634999060416],
            [-111.875636, 42.416332999060465],
            [-111.916927, 42.617263999059901],
            [-111.969449, 42.71803899905963],
            [-112.10588, 42.699202999059679],
            [-112.141034, 42.763092999059523],
            [-112.120335, 42.831007999059352],
            [-112.149835, 42.8955579990592]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "163",
        COUNTYNS: "00659526",
        AFFGEOID: "0500000US27163",
        GEOID: "27163",
        NAME: "Washington",
        LSAD: "06",
        ALAND: 995558956,
        AWATER: 99517524
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.01944, 45.296928999056831],
            [-92.748267674685891, 45.296059385566622],
            [-92.761868, 45.284937999056815],
            [-92.766091408673688, 45.210021701479498],
            [-92.766932, 45.195110999056787],
            [-92.743823479365574, 45.123646261379783],
            [-92.740509, 45.113395999056777],
            [-92.802911, 45.065402999056772],
            [-92.761904, 45.022466999056761],
            [-92.750802, 44.941566999056761],
            [-92.767023265751888, 44.861976736154467],
            [-92.767117334357195, 44.861515184932664],
            [-92.768574, 44.854367999056763],
            [-92.805287, 44.768360999056789],
            [-92.792360799670504, 44.75898381275389],
            [-93.015961, 44.775563999056772],
            [-93.010032, 44.845988999056765],
            [-93.020044, 44.890749999056766],
            [-92.984192, 44.890813999056761],
            [-92.984619, 45.123621999056773],
            [-93.020518, 45.12386599905679],
            [-93.01944, 45.296928999056831]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "27",
        COUNTYFP: "011",
        COUNTYNS: "00659451",
        AFFGEOID: "0500000US27011",
        GEOID: "27011",
        NAME: "Big Stone",
        LSAD: "06",
        ALAND: 1292477791,
        AWATER: 75340289
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.835419161978095, 45.586128691937795],
            [-96.253026, 45.585525999056983],
            [-96.241328, 45.412666999056881],
            [-96.117036, 45.411992999056885],
            [-96.118054, 45.239783999056812],
            [-96.103614, 45.176674999056793],
            [-96.311413, 45.251596999056815],
            [-96.457553498485012, 45.268897927775214],
            [-96.457781, 45.307609999056837],
            [-96.47007760304929, 45.326799648449153],
            [-96.482556, 45.346272999056858],
            [-96.521787, 45.375644999056867],
            [-96.562142, 45.386089999056857],
            [-96.617726, 45.408091999056879],
            [-96.675447, 45.410215999056881],
            [-96.710786, 45.436929999056908],
            [-96.742509, 45.47872299905692],
            [-96.781036, 45.535971999056962],
            [-96.835419161978095, 45.586128691937795]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "04",
        COUNTYFP: "025",
        COUNTYNS: "00042809",
        AFFGEOID: "0500000US04025",
        GEOID: "04025",
        NAME: "Yavapai",
        LSAD: "06",
        ALAND: 21039796372,
        AWATER: 11501575
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.334161, 35.528036999107364],
            [-113.174458, 35.5064449991076],
            [-113.148055, 35.478594999107891],
            [-112.975855, 35.392903999108817],
            [-112.95341, 35.356109999109215],
            [-112.754838, 35.306300999109752],
            [-112.694846, 35.311585999109695],
            [-112.541805, 35.236337999110503],
            [-112.440751, 35.260467999110247],
            [-112.4423, 35.149407999111475],
            [-112.335716, 35.14827899911149],
            [-112.334536, 34.973023999113444],
            [-112.00807, 34.975897999113414],
            [-111.777815, 34.981004999113352],
            [-111.778291, 34.803550999115359],
            [-111.552106, 34.802519999115376],
            [-111.554681, 34.471732999119205],
            [-111.554216, 34.4394369991196],
            [-111.659644, 34.386250999120243],
            [-111.674412, 34.306162999121184],
            [-111.72057, 34.161428999122933],
            [-111.477853, 34.151107999123056],
            [-111.494778, 33.999816999124896],
            [-111.72531, 33.9998489991249],
            [-112.164758, 34.048169999124298],
            [-112.195658, 33.974501999125202],
            [-112.27604, 33.882311999126351],
            [-112.743951, 33.999897999124904],
            [-113.333753, 33.999226999124907],
            [-113.333508, 34.31787699912104],
            [-113.334105, 35.508415999107577],
            [-113.334161, 35.528036999107364]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "045",
        COUNTYNS: "01101810",
        AFFGEOID: "0500000US40045",
        GEOID: "40045",
        NAME: "Ellis",
        LSAD: "06",
        ALAND: 3189606147,
        AWATER: 952235
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.00316, 36.593249999096649],
            [-99.605565, 36.592752999096646],
            [-99.596079, 36.164060999100812],
            [-99.382074, 36.164300999100824],
            [-99.381022, 36.012924999102331],
            [-99.495812, 35.978731999102685],
            [-99.558486, 35.865987999103837],
            [-99.619813, 35.842088999104071],
            [-99.717101, 35.859768999103892],
            [-99.801389, 35.910644999103376],
            [-99.809444, 36.000043999102459],
            [-99.888309, 35.987846999102587],
            [-99.925147, 35.913663999103342],
            [-100.000396198329014, 35.880947999100471],
            [-100.000399, 36.055676999101891],
            [-100.000406, 36.499701999097539],
            [-100.003761993368016, 36.499701847835134],
            [-100.00316, 36.593249999096649]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "18",
        COUNTYFP: "127",
        COUNTYNS: "00450382",
        AFFGEOID: "0500000US18127",
        GEOID: "18127",
        NAME: "Porter",
        LSAD: "06",
        ALAND: 1082777926,
        AWATER: 268688087
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.222799949118397, 41.628889838023113],
            [-87.125835, 41.650301999063011],
            [-87.027888, 41.674660999062937],
            [-86.932849028494388, 41.71649693696498],
            [-86.930079, 41.236797999064684],
            [-86.968125, 41.219657999064758],
            [-87.131339, 41.286244999064479],
            [-87.218862, 41.24202699906467],
            [-87.222799949118397, 41.628889838023113]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "06",
        COUNTYFP: "001",
        COUNTYNS: "01675839",
        AFFGEOID: "0500000US06001",
        GEOID: "06001",
        NAME: "Alameda",
        LSAD: "06",
        ALAND: 1909616630,
        AWATER: 216916717
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.333711, 37.80979699908594],
            [-122.303931, 37.830086999085765],
            [-122.32871428740701, 37.893832345406054],
            [-122.27108, 37.90582399908515],
            [-122.14026, 37.804561999085976],
            [-122.045473, 37.798125999086032],
            [-122.004809, 37.770570999086253],
            [-121.997771, 37.763226999086321],
            [-122.011771, 37.747427999086447],
            [-121.989971, 37.733627999086558],
            [-121.97247, 37.728527999086602],
            [-121.96077, 37.718628999086683],
            [-121.873542, 37.739316999086526],
            [-121.577005, 37.811526999085928],
            [-121.556997, 37.816487999085879],
            [-121.556959, 37.743050999086485],
            [-121.556655, 37.542731999088154],
            [-121.471925, 37.481782999088672],
            [-121.472648, 37.482169999088654],
            [-121.855762, 37.484536999088647],
            [-121.925041, 37.454185999088899],
            [-122.059673, 37.464086999088813],
            [-122.081473, 37.477837999088692],
            [-122.11724, 37.506720999088458],
            [-122.129199702224, 37.521321997679138],
            [-122.111998, 37.528850999088263],
            [-122.144396, 37.581865999087825],
            [-122.152905, 37.64077099908733],
            [-122.162802519461991, 37.667273012456803],
            [-122.163049, 37.6679329990871],
            [-122.213774, 37.698694999086847],
            [-122.24981, 37.726405999086623],
            [-122.252452, 37.755128999086381],
            [-122.312974, 37.777243999086203],
            [-122.333711, 37.80979699908594]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "16",
        COUNTYFP: "059",
        COUNTYNS: "00399754",
        AFFGEOID: "0500000US16059",
        GEOID: "16059",
        NAME: "Lemhi",
        LSAD: "06",
        ALAND: 11820055094,
        AWATER: 14109855
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.812407, 44.808345999056776],
            [-114.723294, 44.891176999056768],
            [-114.73227, 45.151154999056779],
            [-114.694001, 45.197181999056795],
            [-114.60394, 45.295825999056838],
            [-114.793207, 45.436333999056892],
            [-114.793778, 45.508308999056936],
            [-114.663725, 45.471004999056909],
            [-114.523773044931005, 45.585326505663581],
            [-114.506341, 45.559215999056974],
            [-114.456764, 45.543982999056972],
            [-114.36852, 45.492715999056927],
            [-114.279217, 45.480615999056916],
            [-114.251836, 45.537811999056949],
            [-114.18647, 45.545538999056959],
            [-114.083149, 45.603995999057005],
            [-114.014973, 45.65400799905705],
            [-114.015633, 45.696126999057071],
            [-113.971565, 45.700635999057084],
            [-113.948321482569995, 45.682577357498573],
            [-113.898883, 45.644166999057042],
            [-113.861404, 45.623659999057026],
            [-113.806729, 45.602145999057008],
            [-113.802849, 45.523158999056953],
            [-113.759986, 45.480734999056921],
            [-113.763368, 45.427731999056881],
            [-113.73239, 45.385057999056876],
            [-113.735601, 45.325264999056834],
            [-113.650064, 45.234709999056811],
            [-113.57467, 45.128410999056783],
            [-113.510819, 45.099901999056762],
            [-113.45197, 45.059246999056768],
            [-113.437726, 45.006966999056758],
            [-113.448958, 44.953543999056755],
            [-113.474573, 44.910845999056761],
            [-113.422376, 44.842594999056764],
            [-113.377153, 44.834857999056766],
            [-113.301508, 44.798984999056778],
            [-113.247166, 44.822949999056775],
            [-113.131387, 44.764737999056777],
            [-113.101154, 44.708577999056793],
            [-113.049349, 44.629379999056816],
            [-113.061071, 44.577328999056839],
            [-113.006828, 44.518438999056876],
            [-113.006846, 44.4717149990569],
            [-112.951146, 44.416698999056933],
            [-112.881769, 44.380314999056942],
            [-112.826826881265006, 44.405202430824438],
            [-112.813792, 44.230280999057058],
            [-112.995091, 44.234125999057049],
            [-113.320609, 44.233163999057048],
            [-113.339445, 44.316076999056996],
            [-113.451832, 44.454935999056893],
            [-113.501882, 44.422062999056934],
            [-113.816398, 44.494054999056878],
            [-114.003915, 44.634700999056825],
            [-114.084861, 44.755946999056782],
            [-114.205244, 44.744021999056784],
            [-114.191722, 44.837932999056775],
            [-114.221534, 44.879076999056757],
            [-114.404154, 44.739546999056785],
            [-114.372843, 44.709716999056788],
            [-114.45184, 44.611740999056828],
            [-114.566975, 44.575731999056849],
            [-114.662687, 44.662108999056819],
            [-114.710871, 44.650821999056816],
            [-114.820083, 44.747376999056783],
            [-114.812407, 44.808345999056776]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "013",
        COUNTYNS: "01696228",
        AFFGEOID: "0500000US24013",
        GEOID: "24013",
        NAME: "Carroll",
        LSAD: "06",
        ALAND: 1159279222,
        AWATER: 13186154
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.217023755237392, 39.720217247457853],
            [-76.999318026333199, 39.720067671425056],
            [-76.991062, 39.720061999072449],
            [-76.787097199757596, 39.721048145183744],
            [-76.804304, 39.675881999072729],
            [-76.8931, 39.457401999074065],
            [-76.8873, 39.440501999074165],
            [-76.873378, 39.357149999074693],
            [-76.882274, 39.350184999074749],
            [-77.101678, 39.369295999074623],
            [-77.16808, 39.353956999074725],
            [-77.107711, 39.494555999073839],
            [-77.198024, 39.575429999073329],
            [-77.307416, 39.619100999073069],
            [-77.217023755237392, 39.720217247457853]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "38",
        COUNTYFP: "059",
        COUNTYNS: "01034207",
        AFFGEOID: "0500000US38059",
        GEOID: "38059",
        NAME: "Morton",
        LSAD: "06",
        ALAND: 4988626570,
        AWATER: 50054878
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.096675, 46.981158999059133],
            [-101.764392, 46.981305999059124],
            [-100.935983, 46.982839999059131],
            [-100.907902, 46.904366999058958],
            [-100.779477, 46.7365709990586],
            [-100.787809, 46.690629999058501],
            [-100.66189, 46.634449999058397],
            [-100.561035, 46.5878469990583],
            [-100.590199, 46.427893999058007],
            [-100.721261, 46.39008399905795],
            [-100.884552, 46.409095999057989],
            [-101.025662, 46.284218999057785],
            [-101.048532, 46.371392999057925],
            [-101.29899, 46.37145099905792],
            [-101.299052, 46.630309999058369],
            [-101.718105, 46.630998999058377],
            [-101.718155, 46.71801899905855],
            [-102.096239, 46.717705999058545],
            [-102.096675, 46.981158999059133]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "107",
        COUNTYNS: "01480141",
        AFFGEOID: "0500000US51107",
        GEOID: "51107",
        NAME: "Loudoun",
        LSAD: "06",
        ALAND: 1335692947,
        AWATER: 14556679
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.862825, 39.080978999076514],
            [-77.82830183326729, 39.132422453908674],
            [-77.828157, 39.132328999076158],
            [-77.809125, 39.168566999075921],
            [-77.778068, 39.229304999075516],
            [-77.719519, 39.321313999074931],
            [-77.677695762078685, 39.317940816162952],
            [-77.66613, 39.317007999074953],
            [-77.588235, 39.301954999075043],
            [-77.553114, 39.279267999075202],
            [-77.496606, 39.251044999075383],
            [-77.46006562649778, 39.218842942934991],
            [-77.459883, 39.218681999075592],
            [-77.485971, 39.185664999075819],
            [-77.521222, 39.161056999075967],
            [-77.519929, 39.120924999076244],
            [-77.481279, 39.105657999076335],
            [-77.462617, 39.076247999076543],
            [-77.359702, 39.062003999076651],
            [-77.330037705954084, 39.055952112567681],
            [-77.400955, 38.984291999077165],
            [-77.432198, 38.952672999077372],
            [-77.535991, 38.847367999078116],
            [-77.549001, 38.846552999078106],
            [-77.55224, 38.886587999077825],
            [-77.655169, 38.942655999077445],
            [-77.962203, 39.013732999076964],
            [-77.862825, 39.080978999076514]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "24",
        COUNTYFP: "031",
        COUNTYNS: "01712500",
        AFFGEOID: "0500000US24031",
        GEOID: "24031",
        NAME: "Montgomery",
        LSAD: "06",
        ALAND: 1276943098,
        AWATER: 35936935
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.521222, 39.161056999075967],
            [-77.485971, 39.185664999075819],
            [-77.459883, 39.218681999075592],
            [-77.46006562649778, 39.218842942934991],
            [-77.285719, 39.299376999075072],
            [-77.262529, 39.310279999075],
            [-77.168801, 39.353501999074723],
            [-77.186937, 39.339264999074807],
            [-77.134466, 39.276833999075222],
            [-77.070112, 39.254103999075369],
            [-76.973666, 39.162208999075965],
            [-76.958517, 39.134022999076151],
            [-76.888505, 39.130966999076179],
            [-76.907292, 39.117907999076259],
            [-76.974565, 39.014713999076953],
            [-77.002546244247, 38.965531699523183],
            [-77.041018, 38.995547999077083],
            [-77.119759, 38.934342999077501],
            [-77.146601, 38.964209999077305],
            [-77.202502, 38.967909999077264],
            [-77.249803, 38.985908999077147],
            [-77.248403704975402, 39.026888353369166],
            [-77.248403, 39.026908999076873],
            [-77.310705, 39.052007999076707],
            [-77.330037705954084, 39.055952112567681],
            [-77.359702, 39.062003999076651],
            [-77.462617, 39.076247999076543],
            [-77.481279, 39.105657999076335],
            [-77.519929, 39.120924999076244],
            [-77.521222, 39.161056999075967]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "37",
        COUNTYFP: "167",
        COUNTYNS: "01025844",
        AFFGEOID: "0500000US37167",
        GEOID: "37167",
        NAME: "Stanly",
        LSAD: "06",
        ALAND: 1023370455,
        AWATER: 25242751
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.49751, 35.201838999110898],
            [-80.295421, 35.502919999107633],
            [-80.182559, 35.504150999107623],
            [-80.108495, 35.4594509991081],
            [-80.051225, 35.371536999109047],
            [-80.098185, 35.255172999110314],
            [-80.075365, 35.143080999111532],
            [-80.112644, 35.193881999110978],
            [-80.172819, 35.147846999111501],
            [-80.276829, 35.195721999110958],
            [-80.356982, 35.164939999111311],
            [-80.504985, 35.184997999111083],
            [-80.49751, 35.201838999110898]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "109",
        COUNTYNS: "01383840",
        AFFGEOID: "0500000US48109",
        GEOID: "48109",
        NAME: "Culberson",
        LSAD: "06",
        ALAND: 9873439554,
        AWATER: 556134
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.918356994280003, 32.000472072225094],
            [-104.84774, 32.0004646019866],
            [-104.643526, 32.000442999151595],
            [-104.024521, 32.000009999151601],
            [-104.102351, 31.105202999164899],
            [-104.917163, 30.663625999171764],
            [-104.916487, 30.821033999169291],
            [-104.91289, 31.625119999157079],
            [-104.918356994280003, 32.000472072225094]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "21",
        COUNTYFP: "109",
        COUNTYNS: "00516901",
        AFFGEOID: "0500000US21109",
        GEOID: "21109",
        NAME: "Jackson",
        LSAD: "06",
        ALAND: 894055741,
        AWATER: 3407438
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.171836, 37.557548999088027],
            [-84.090364, 37.566226999087952],
            [-83.965244, 37.581420999087825],
            [-83.90557, 37.542855999088154],
            [-83.885262, 37.517687999088359],
            [-83.883953, 37.477334999088697],
            [-83.784975, 37.34871699908981],
            [-83.901574, 37.253739999090627],
            [-83.943718, 37.250550999090656],
            [-84.005606, 37.253342999090627],
            [-84.124945, 37.33562599908992],
            [-84.137898, 37.319758999090062],
            [-84.12913, 37.357834999089725],
            [-84.199489, 37.522857999088323],
            [-84.171836, 37.557548999088027]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "077",
        COUNTYNS: "01501196",
        AFFGEOID: "0500000US51077",
        GEOID: "51077",
        NAME: "Grayson",
        LSAD: "06",
        ALAND: 1144219726,
        AWATER: 9792918
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.6469, 36.61191799909647],
            [-81.605865, 36.638078999096223],
            [-81.543957, 36.701516999095624],
            [-81.261987, 36.763032999095053],
            [-81.08851, 36.767421999095014],
            [-81.042428, 36.806447999094644],
            [-80.932271, 36.671592999095907],
            [-80.944109, 36.67630299909586],
            [-80.949599, 36.662991999095986],
            [-80.947754, 36.648360999096141],
            [-80.921711, 36.643532999096173],
            [-80.931024, 36.625444999096345],
            [-80.916043, 36.629342999096309],
            [-80.914173, 36.6502459990961],
            [-80.840213077246005, 36.561928450734243],
            [-80.901661118920998, 36.561751186264942],
            [-80.901726, 36.561750999096951],
            [-81.061866, 36.5670199990969],
            [-81.176712, 36.571925999096848],
            [-81.353221224602194, 36.576238230253203],
            [-81.499831, 36.579819999096777],
            [-81.677535, 36.588116999096698],
            [-81.6469, 36.61191799909647]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "20",
        COUNTYFP: "109",
        COUNTYNS: "00485019",
        AFFGEOID: "0500000US20109",
        GEOID: "20109",
        NAME: "Logan",
        LSAD: "06",
        ALAND: 2779039722,
        AWATER: 274632
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.478195, 39.134760999076143],
            [-101.391717, 39.135116999076146],
            [-100.811858, 39.133359999076156],
            [-100.818698, 38.699860999079142],
            [-101.128379, 38.700602999079145],
            [-101.484383, 38.700165999079132],
            [-101.478195, 39.134760999076143]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "125",
        COUNTYNS: "00424261",
        AFFGEOID: "0500000US17125",
        GEOID: "17125",
        NAME: "Mason",
        LSAD: "06",
        ALAND: 1396619099,
        AWATER: 62745252
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.355198, 40.124244999070122],
            [-90.206144, 40.155788999069955],
            [-90.199556, 40.183944999069794],
            [-90.118966, 40.235262999069526],
            [-90.03795, 40.375040999068787],
            [-89.92468, 40.435920999068465],
            [-89.717104, 40.435654999068454],
            [-89.714927, 40.319217999069075],
            [-89.602979, 40.32012899906907],
            [-89.601604, 40.122431999070137],
            [-89.638262, 40.154869999069959],
            [-89.749196, 40.126851999070112],
            [-89.969618, 40.142357999070036],
            [-89.994724, 40.10837299907022],
            [-90.134517, 40.062022999070471],
            [-90.286724, 40.055320999070503],
            [-90.355198, 40.124244999070122]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "137",
        COUNTYNS: "01074081",
        AFFGEOID: "0500000US39137",
        GEOID: "39137",
        NAME: "Putnam",
        LSAD: "06",
        ALAND: 1249724511,
        AWATER: 4603965
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.399486, 40.99031499906576],
            [-84.341911, 40.990691999065753],
            [-84.341602, 41.165523999064995],
            [-84.228222, 41.165861999064994],
            [-83.881149, 41.167823999064979],
            [-83.880423, 40.920428999066097],
            [-84.109516, 40.904729999066163],
            [-84.109586, 40.860993999066366],
            [-84.340531, 40.859098999066376],
            [-84.398407, 40.903264999066167],
            [-84.399486, 40.99031499906576]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "08",
        COUNTYFP: "039",
        COUNTYNS: "00198136",
        AFFGEOID: "0500000US08039",
        GEOID: "08039",
        NAME: "Elbert",
        LSAD: "06",
        ALAND: 4793658887,
        AWATER: 442148
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.660626, 39.565908999073386],
            [-104.642125, 39.565889999073391],
            [-103.714355, 39.566330999073394],
            [-103.719664, 38.866826999077979],
            [-104.055528, 38.868867999077949],
            [-104.051568, 39.128137999076195],
            [-104.662896, 39.129526999076184],
            [-104.660626, 39.565908999073386]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "153",
        COUNTYNS: "01074088",
        AFFGEOID: "0500000US39153",
        GEOID: "39153",
        NAME: "Summit",
        LSAD: "06",
        ALAND: 1069192868,
        AWATER: 18948340
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.68699, 41.135955999065118],
            [-81.68495, 41.277145999064501],
            [-81.67051, 41.277254999064517],
            [-81.590542, 41.277553999064516],
            [-81.565139, 41.277757999064505],
            [-81.598052, 41.351156999064209],
            [-81.517658, 41.350153999064204],
            [-81.450331, 41.349147999064215],
            [-81.391694, 41.34827199906421],
            [-81.391831, 41.276653999064521],
            [-81.392114, 41.135721999065133],
            [-81.392534, 41.100434999065271],
            [-81.393282, 41.02543499906561],
            [-81.393249, 40.98853099906578],
            [-81.420418, 40.906503999066146],
            [-81.647735, 40.914015999066109],
            [-81.647689, 40.988562999065763],
            [-81.688491, 40.988589999065773],
            [-81.68699, 41.135955999065118]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "139",
        COUNTYNS: "01686953",
        AFFGEOID: "0500000US13139",
        GEOID: "13139",
        NAME: "Hall",
        LSAD: "06",
        ALAND: 1017805267,
        AWATER: 94061469
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.057632, 34.183276999122668],
            [-83.989059, 34.195731999122508],
            [-83.925575, 34.285269999121439],
            [-83.957077, 34.334010999120849],
            [-83.981433, 34.357828999120564],
            [-83.980649, 34.418388999119848],
            [-83.931886, 34.469344999119244],
            [-83.843405, 34.505493999118819],
            [-83.788604, 34.514095999118716],
            [-83.666415, 34.50360199911885],
            [-83.615251, 34.431747999119686],
            [-83.66967, 34.366099999120472],
            [-83.620115, 34.295275999121323],
            [-83.817682, 34.127492999123326],
            [-83.890097, 34.106178999123586],
            [-84.062841, 34.167872999122835],
            [-84.057632, 34.183276999122668]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "469",
        COUNTYNS: "01384020",
        AFFGEOID: "0500000US48469",
        GEOID: "48469",
        NAME: "Victoria",
        LSAD: "06",
        ALAND: 2284644914,
        AWATER: 17425945
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.305916, 28.864036999201765],
            [-96.976378, 29.104045999197581],
            [-96.938587, 29.063243999198271],
            [-96.832103, 29.021298999199008],
            [-96.792133, 28.918802999200796],
            [-96.711514, 28.851410999201978],
            [-96.643442, 28.712104999204431],
            [-96.659864, 28.70167099920462],
            [-96.665707, 28.705704999204546],
            [-96.722814, 28.66975899920519],
            [-96.853757, 28.635198999205802],
            [-96.930367, 28.586727999206666],
            [-96.890951, 28.507177999208086],
            [-96.9155, 28.485711999208469],
            [-97.048529, 28.549975999207319],
            [-97.160751, 28.55347499920725],
            [-97.182359, 28.610601999206242],
            [-97.158933, 28.776155999203315],
            [-97.202759, 28.853848999201936],
            [-97.305916, 28.864036999201765]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "035",
        COUNTYNS: "01074030",
        AFFGEOID: "0500000US39035",
        GEOID: "39035",
        NAME: "Cuyahoga",
        LSAD: "06",
        ALAND: 1184013873,
        AWATER: 2041890563
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.960324970003896, 41.500553291661397],
            [-81.937862, 41.491442999063644],
            [-81.822503579699401, 41.495259418353029],
            [-81.810758, 41.495647999063614],
            [-81.768575141621596, 41.491489645539943],
            [-81.738755, 41.48854999906365],
            [-81.633652, 41.540457999063449],
            [-81.488682733409007, 41.634463926883981],
            [-81.487842, 41.570050999063319],
            [-81.391005, 41.569715999063327],
            [-81.391518, 41.445221999063833],
            [-81.391826, 41.416316999063937],
            [-81.391694, 41.34827199906421],
            [-81.450331, 41.349147999064215],
            [-81.517658, 41.350153999064204],
            [-81.598052, 41.351156999064209],
            [-81.565139, 41.277757999064505],
            [-81.590542, 41.277553999064516],
            [-81.67051, 41.277254999064517],
            [-81.68495, 41.277145999064501],
            [-81.705151, 41.277253999064506],
            [-81.83911, 41.275561999064522],
            [-81.878053, 41.275043999064522],
            [-81.87696, 41.350684999064207],
            [-81.971262, 41.351267999064198],
            [-81.960324970003896, 41.500553291661397]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "48",
        COUNTYFP: "351",
        COUNTYNS: "01383961",
        AFFGEOID: "0500000US48351",
        GEOID: "48351",
        NAME: "Newton",
        LSAD: "06",
        ALAND: 2418212543,
        AWATER: 15672146
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.911126, 31.158070999164085],
            [-93.616168, 31.175251999163823],
            [-93.600603087362884, 31.182624954432516],
            [-93.552497307502307, 31.184820022610481],
            [-93.535097, 31.185613999163664],
            [-93.540278, 31.128867999164544],
            [-93.531219, 31.051677999165715],
            [-93.539526, 31.008497999166384],
            [-93.549841, 30.96711799916703],
            [-93.530936, 30.924533999167679],
            [-93.554575854345487, 30.877469501029513],
            [-93.558617, 30.869423999168539],
            [-93.569303, 30.802968999169572],
            [-93.617688, 30.738478999170585],
            [-93.629904, 30.679939999171506],
            [-93.685121, 30.625200999172371],
            [-93.684329, 30.592585999172879],
            [-93.729195, 30.544841999173645],
            [-93.710117, 30.506399999174263],
            [-93.702665, 30.429946999175474],
            [-93.738540238226008, 30.402263671656716],
            [-93.745333, 30.397021999176012],
            [-93.760328, 30.329923999177083],
            [-93.70719, 30.275512999177959],
            [-93.711062171300895, 30.243970714818769],
            [-93.901452, 30.242671999178494],
            [-93.865711, 30.947217999167336],
            [-93.911126, 31.158070999164085]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "01",
        COUNTYFP: "117",
        COUNTYNS: "00161584",
        AFFGEOID: "0500000US01117",
        GEOID: "01117",
        NAME: "Shelby",
        LSAD: "06",
        ALAND: 2034107820,
        AWATER: 62542790
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.026846, 33.246458999134468],
            [-86.97836, 33.256221999134333],
            [-86.882518, 33.332686999133337],
            [-86.830597, 33.332014999133342],
            [-86.674418, 33.466520999131603],
            [-86.517199, 33.524135999130863],
            [-86.516783, 33.545895999130586],
            [-86.481549, 33.546072999130587],
            [-86.481939, 33.502543999131149],
            [-86.378222, 33.502410999131151],
            [-86.378665, 33.390982999132582],
            [-86.341113, 33.354598999133067],
            [-86.35734, 33.296916999133806],
            [-86.458026, 33.241433999134529],
            [-86.503127, 33.179143999135349],
            [-86.491029, 33.102943999136357],
            [-86.511142, 33.088430999136548],
            [-86.517344, 33.020565999137446],
            [-86.610003, 33.07000299913679],
            [-86.881638, 33.07186099913676],
            [-86.881182, 33.049900999137058],
            [-87.025596, 33.165794999135521],
            [-87.026846, 33.246458999134468]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "17",
        COUNTYFP: "153",
        COUNTYNS: "01784966",
        AFFGEOID: "0500000US17153",
        GEOID: "17153",
        NAME: "Pulaski",
        LSAD: "06",
        ALAND: 515887465,
        AWATER: 10473563
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.248439, 37.335108999089918],
            [-89.044787, 37.329845999089962],
            [-89.044648, 37.294342999090276],
            [-88.929129, 37.3026469990902],
            [-88.928004829494299, 37.226389765343974],
            [-88.931745, 37.227592999090852],
            [-88.933517214747795, 37.227511279005462],
            [-89.000968, 37.224400999090889],
            [-89.058036, 37.1887669990912],
            [-89.099047, 37.140966999091617],
            [-89.168087, 37.074217999092212],
            [-89.166620212019694, 37.072110342892138],
            [-89.261779, 37.10574199909194],
            [-89.248439, 37.335108999089918]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "39",
        COUNTYFP: "001",
        COUNTYNS: "01074014",
        AFFGEOID: "0500000US39001",
        GEOID: "39001",
        NAME: "Adams",
        LSAD: "06",
        ALAND: 1512208934,
        AWATER: 6165939
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.705861635585592, 38.638037864145588],
            [-83.673018, 39.02042999907691],
            [-83.611592, 39.018889999076933],
            [-83.385637, 39.055196999076678],
            [-83.314589, 39.052194999076697],
            [-83.270817, 39.015790999076948],
            [-83.268292, 38.634011999079618],
            [-83.264298901958696, 38.613112495967265],
            [-83.286514, 38.599240999079861],
            [-83.320531, 38.622712999079695],
            [-83.376302, 38.661472999079415],
            [-83.440404, 38.669360999079366],
            [-83.533339, 38.702104999079133],
            [-83.626922, 38.679386999079291],
            [-83.642994, 38.643272999079556],
            [-83.646911404126683, 38.641851933625368],
            [-83.679484, 38.630035999079638],
            [-83.705861635585592, 38.638037864145588]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "167",
        COUNTYNS: "00346664",
        AFFGEOID: "0500000US13167",
        GEOID: "13167",
        NAME: "Johnson",
        LSAD: "06",
        ALAND: 784793323,
        AWATER: 9278537
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.946966, 32.75935799914096],
            [-82.802456, 32.809755999140293],
            [-82.768365, 32.769107999140829],
            [-82.521052, 32.822355999140115],
            [-82.458249, 32.768222999140853],
            [-82.434149, 32.762257999140928],
            [-82.499431, 32.686339999141978],
            [-82.465065, 32.646832999142511],
            [-82.594439, 32.57915799914344],
            [-82.564358, 32.53244899914408],
            [-82.647733, 32.512506999144364],
            [-82.668557, 32.612163999142979],
            [-82.794538, 32.660229999142317],
            [-82.862763, 32.715759999141554],
            [-82.957066, 32.708317999141663],
            [-82.946966, 32.75935799914096]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "40",
        COUNTYFP: "119",
        COUNTYNS: "01101847",
        AFFGEOID: "0500000US40119",
        GEOID: "40119",
        NAME: "Payne",
        LSAD: "06",
        ALAND: 1773393745,
        AWATER: 31771425
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.354132, 36.159071999100867],
            [-97.140668, 36.159230999100878],
            [-97.140968, 36.246434999099996],
            [-96.925069, 36.246473999099997],
            [-96.818968, 36.246477999099994],
            [-96.819005, 36.158885999100875],
            [-96.621441, 36.159928999100863],
            [-96.621097, 35.94151899910306],
            [-97.140583, 35.941990999103055],
            [-97.203592, 35.944794999103024],
            [-97.32362, 36.010876999102351],
            [-97.35387, 35.984933999102608],
            [-97.354132, 36.159071999100867]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "065",
        COUNTYNS: "00465221",
        AFFGEOID: "0500000US19065",
        GEOID: "19065",
        NAME: "Fayette",
        LSAD: "06",
        ALAND: 1892840950,
        AWATER: 1320705
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.081568, 42.907012999059162],
            [-92.081146, 43.082793999058765],
            [-91.605307, 43.081652999058775],
            [-91.607059, 42.64397299905982],
            [-92.081661, 42.642055999059821],
            [-92.081568, 42.907012999059162]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "35",
        COUNTYFP: "013",
        COUNTYNS: "00929109",
        AFFGEOID: "0500000US35013",
        GEOID: "35013",
        NAME: "Doña Ana",
        LSAD: "06",
        ALAND: 9863129996,
        AWATER: 17627587
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.299475, 32.780165999140685],
            [-106.887733, 32.779272999140694],
            [-106.887891, 32.822780999140114],
            [-106.340515, 33.052776999137009],
            [-106.340709, 32.958381999138275],
            [-106.376585, 32.958049999138296],
            [-106.377173188246999, 32.001240100189079],
            [-106.618486, 32.000494999151599],
            [-106.630114, 31.971257999152019],
            [-106.623445, 31.914033999152835],
            [-106.63588, 31.871513999153454],
            [-106.581344, 31.813905999154301],
            [-106.528242, 31.783147999154753],
            [-106.531731, 31.783909999154744],
            [-106.993544, 31.783688999154741],
            [-107.296792990414005, 31.783625336262741],
            [-107.299631, 32.605369999143072],
            [-107.299475, 32.780165999140685]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "54",
        COUNTYFP: "007",
        COUNTYNS: "01550010",
        AFFGEOID: "0500000US54007",
        GEOID: "54007",
        NAME: "Braxton",
        LSAD: "06",
        ALAND: 1322996693,
        AWATER: 14144661
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.98495, 38.717897999079007],
            [-80.669909, 38.892772999077792],
            [-80.605799, 38.904166999077724],
            [-80.58349, 38.858870999078029],
            [-80.474111, 38.8334039990782],
            [-80.457332, 38.739168999078856],
            [-80.508655, 38.645552999079527],
            [-80.650786, 38.526589999080407],
            [-80.785177, 38.557648999080172],
            [-80.881232, 38.507044999080534],
            [-80.913289, 38.563826999080121],
            [-81.031677, 38.667838999079379],
            [-80.98495, 38.717897999079007]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "26",
        COUNTYFP: "099",
        COUNTYNS: "01622992",
        AFFGEOID: "0500000US26099",
        GEOID: "26099",
        NAME: "Macomb",
        LSAD: "06",
        ALAND: 1241120517,
        AWATER: 237557908
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.102891, 42.888646999059205],
            [-82.983647, 42.893740999059197],
            [-82.73901, 42.897540999059188],
            [-82.729277, 42.706058999059657],
            [-82.707885, 42.675496999059746],
            [-82.801022, 42.62954499905986],
            [-82.766004, 42.600050999059938],
            [-82.755927, 42.564414999060027],
            [-82.859316, 42.541934999060089],
            [-82.859447065488098, 42.540853218669],
            [-82.860210200131007, 42.534554504152617],
            [-82.867531944981394, 42.47412272975339],
            [-82.87033226919759, 42.451009583265453],
            [-83.083393, 42.447152999060364],
            [-83.086882, 42.534791999060118],
            [-83.0899, 42.593008999059947],
            [-83.091449, 42.62420799905987],
            [-83.102891, 42.888646999059205]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "137",
        COUNTYNS: "01804549",
        AFFGEOID: "0500000US72137",
        GEOID: "72137",
        NAME: "Toa Baja",
        LSAD: "13",
        ALAND: 60201690,
        AWATER: 48075984
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.2581, 18.454975999432772],
            [-66.195882211469396, 18.470653763940565],
            [-66.186722, 18.469731999432383],
            [-66.137959, 18.473891999432276],
            [-66.129260345438709, 18.47217458928942],
            [-66.129403, 18.465631999432492],
            [-66.169516, 18.430997999433405],
            [-66.19698, 18.389288999434505],
            [-66.252554, 18.394186999434375],
            [-66.2581, 18.454975999432772]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "039",
        COUNTYNS: "01804499",
        AFFGEOID: "0500000US72039",
        GEOID: "72039",
        NAME: "Ciales",
        LSAD: "13",
        ALAND: 172310206,
        AWATER: 459605
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.590785, 18.33805999943586],
            [-66.533623, 18.351332999435506],
            [-66.463211, 18.371472999434975],
            [-66.464883, 18.283239999437306],
            [-66.455703, 18.25772599943798],
            [-66.501065, 18.256747999438005],
            [-66.512206, 18.166319999440404],
            [-66.543133, 18.163097999440488],
            [-66.531023, 18.180136999440037],
            [-66.56662, 18.298548999436896],
            [-66.606989, 18.328185999436119],
            [-66.590785, 18.33805999943586]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "51",
        COUNTYFP: "115",
        COUNTYNS: "01480145",
        AFFGEOID: "0500000US51115",
        GEOID: "51115",
        NAME: "Mathews",
        LSAD: "06",
        ALAND: 222554152,
        AWATER: 430025572
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.437561, 37.514359999088384],
            [-76.404118, 37.53554199908821],
            [-76.289492797178099, 37.536075287097006],
            [-76.273488, 37.495320999088548],
            [-76.250454, 37.42188599908917],
            [-76.24846, 37.375134999089575],
            [-76.275552, 37.309963999090144],
            [-76.366751, 37.374494999089571],
            [-76.393958, 37.395939999089386],
            [-76.402945875352898, 37.392600000880634],
            [-76.447944, 37.420063999089187],
            [-76.437561, 37.514359999088384]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "32",
        COUNTYFP: "005",
        COUNTYNS: "00858643",
        AFFGEOID: "0500000US32005",
        GEOID: "32005",
        NAME: "Douglas",
        LSAD: "06",
        ALAND: 1838170316,
        AWATER: 72317570
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.002614842913005, 39.112689373172095],
            [-119.761213, 39.114008999076276],
            [-119.55102, 39.08579099907648],
            [-119.311714, 39.083614999076488],
            [-119.310416, 39.019377999076923],
            [-119.401648, 38.983322999077167],
            [-119.439534, 38.88200099907786],
            [-119.349884, 38.72919299907894],
            [-119.328704521075991, 38.534351926877846],
            [-119.585406144839013, 38.713150899750246],
            [-119.587679, 38.714733999079037],
            [-119.904315, 38.933323999077508],
            [-120.001014, 38.999573999077057],
            [-120.001975250867005, 39.0674958735449],
            [-120.002614842913005, 39.112689373172095]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "29",
        COUNTYFP: "163",
        COUNTYNS: "00758536",
        AFFGEOID: "0500000US29163",
        GEOID: "29163",
        NAME: "Pike",
        LSAD: "06",
        ALAND: 1736428536,
        AWATER: 36976453
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.460442, 39.450721999074105],
            [-91.182875500819691, 39.598233114868592],
            [-91.174232, 39.59197499907323],
            [-91.148275, 39.545797999073507],
            [-91.100307, 39.538694999073556],
            [-91.064305, 39.494642999073832],
            [-91.03827, 39.448435999074121],
            [-90.937419, 39.400802999074422],
            [-90.935349946368603, 39.399519527981532],
            [-90.840106, 39.340437999074801],
            [-90.72996, 39.25589399907534],
            [-90.723283623412698, 39.224102969133149],
            [-91.182942, 39.22723299907554],
            [-91.18546, 39.139049999076114],
            [-91.260232, 39.139844999076118],
            [-91.409033, 39.142541999076101],
            [-91.438235, 39.318715999074939],
            [-91.460442, 39.450721999074105]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "72",
        COUNTYFP: "001",
        COUNTYNS: "01804480",
        AFFGEOID: "0500000US72001",
        GEOID: "72001",
        NAME: "Adjuntas",
        LSAD: "13",
        ALAND: 172725728,
        AWATER: 1051790
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.832736, 18.227989999438766],
            [-66.817271, 18.230335999438704],
            [-66.781701, 18.250247999438177],
            [-66.69373, 18.216890999439066],
            [-66.670494, 18.152830999440763],
            [-66.69407, 18.130533999441358],
            [-66.749618, 18.113663999441805],
            [-66.769049, 18.134982999441235],
            [-66.797557, 18.133444999441274],
            [-66.827327, 18.171241999440269],
            [-66.832736, 18.227989999438766]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "079",
        COUNTYNS: "00465228",
        AFFGEOID: "0500000US19079",
        GEOID: "19079",
        NAME: "Hamilton",
        LSAD: "06",
        ALAND: 1493777024,
        AWATER: 1965341
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.971583, 42.558138999060048],
            [-93.499485, 42.557699999060063],
            [-93.462635, 42.470801999060299],
            [-93.463043, 42.209301999061083],
            [-93.69839, 42.209338999061089],
            [-93.93158, 42.20990799906108],
            [-93.931684, 42.472010999060295],
            [-93.971583, 42.558138999060048]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "19",
        COUNTYFP: "027",
        COUNTYNS: "00465203",
        AFFGEOID: "0500000US19027",
        GEOID: "19027",
        NAME: "Carroll",
        LSAD: "06",
        ALAND: 1474835092,
        AWATER: 2145349
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.090851, 42.210404999061076],
            [-94.858412, 42.209691999061086],
            [-94.628806, 42.209395999061073],
            [-94.628724, 41.862762999062241],
            [-94.744876, 41.862393999062242],
            [-95.092861, 41.863373999062247],
            [-95.090851, 42.210404999061076]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        STATEFP: "13",
        COUNTYFP: "073",
        COUNTYNS: "00348865",
        AFFGEOID: "0500000US13073",
        GEOID: "13073",
        NAME: "Columbia",
        LSAD: "06",
        ALAND: 751323672,
        AWATER: 45373097
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.425328, 33.650628999129253],
            [-82.308246, 33.700435999128622],
            [-82.215938364747501, 33.687754974447486],
            [-82.199747, 33.657610999129169],
            [-82.161908, 33.610642999129759],
            [-82.142456474500889, 33.605399599121924],
            [-82.114653838584786, 33.59790505369994],
            [-82.10624, 33.595636999129951],
            [-82.028238, 33.544933999130606],
            [-82.28283, 33.362694999132941],
            [-82.294179, 33.354634999133054],
            [-82.437081, 33.550537999130533],
            [-82.425328, 33.650628999129253]
          ]
        ]
      }
    }
  ]
} as CountiesGEOJSON;
