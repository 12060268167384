export default function (
  date: string | undefined | Date,
  dateStyle: "full" | "long" | "medium" | "short" = "medium",
  withTime: Boolean = false
) {
  if (date === undefined || date === null) {
    return "";
  } else {
    const parsedDate = new Date(date);

    return !isNaN(parsedDate.getTime())
      ? new Intl.DateTimeFormat("en-US", {
          // @ts-ignore
          dateStyle,
          // @ts-ignore
          timeStyle: withTime ? "short" : undefined
        }).format(parsedDate)
      : "";
  }
}
export const getMonth = (date: string | undefined | Date) => {
  if (date === undefined || date === null) {
    return "";
  } else {
    const parsedDate = new Date(date);
    return !isNaN(parsedDate.getTime())
      ? new Intl.DateTimeFormat("en-US", { month: "long" }).format(parsedDate)
      : "";
  }
};

export const getElapsedTime = (date: string | undefined | Date) => {
  if (date === undefined || date === null) {
    return "";
  } else {
    const parsedDate = new Date(date);
    const now = new Date();

    const diff = now.getTime() - parsedDate.getTime();

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  }
};
export function addDaysToDate(inputDate: Date, days: number) {
  // Clone the input date to avoid modifying the original date
  const newDate = new Date(inputDate);

  // Add one day to the cloned date
  newDate.setUTCDate(newDate.getUTCDate() + days);

  // Set time to 00:00:00
  newDate.setUTCHours(0, 0, 0, 0);

  return newDate;
}
