import { ReserveType } from "components/PivotTable/types";
import React from "react";
import { EntityType } from "utils/entitySlice";
import { FormComponent } from "utils/models/fields";
import { generateForm, RenderSet, StateAccess } from "utils/models/formGenerator";
import { Permission } from "../Roles/types";
import { checkAccountingProfitsForDifferences } from "./model";
import { Deal } from "./types";

interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  section: string[];
  permissions?: Permission<EntityType>;
}
export const reserveStruct = (
  reserveMethod: ReserveType = "percentage",
  section: string[] = ["payment"]
): FormComponent<Deal> => {
  return {
    formComponent: "segment",
    width: "full",
    entities: [
      reserveMethod === "fixed"
        ? {
            formComponent: "currency-field",
            name: "reserve",
            label: "$ amount",
            width: "full",
            path: ["data", "info", ...section, "reserve"],
            conditionalStyle: (stateAccess) =>
              section[0] === "accounting"
                ? checkAccountingProfitsForDifferences(stateAccess, true, ["payment", "reserve"])
                : {},
            default: null
          }
        : {
            formComponent: "percentage-field",
            name: "reserve",
            label: "Percentage",
            width: "full",
            path: ["data", "info", ...section, "reserve"],
            conditionalStyle: (stateAccess) =>
              section[0] === "accounting"
                ? checkAccountingProfitsForDifferences(stateAccess, true, ["payment", "reserve"])
                : {},
            default: null
          }
    ]
  };
};
export default function ReservePreview({ renderSet, stateAccess, section }: Props) {
  const reserveMethod = stateAccess.get<Deal>(["data", "info", ...section, "reserveMethod"]);

  return (
    <>
      {generateForm(reserveStruct(reserveMethod, section), stateAccess, [], stateAccess, renderSet)}
    </>
  );
}
