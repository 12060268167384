import { Grid, Paper, Typography } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import { deleteDealership } from "components/Dealerships/deleteDealershipSlice";
import { getDealershipList } from "components/Dealerships/listDealershipSlice";
import { recoverDealership } from "components/Dealerships/recoverDealershipSlice";
import { FormState } from "components/Dealerships/types";
import DateFilter from "components/Filters/DateFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import PriceFilter from "components/Filters/PriceFilter";
import Table, { CellValue, Column } from "components/Table";
import React from "react";
import { useSelector } from "react-redux";
import AllocationDealsModal from "../Lender/AllocationDashboard/AllocationDealsModal";
import DealsModal from "./DealsModal";
import LimitedTitleIssuesModal from "./LimitedTitleIssuesModal";

export const projections = {
  "data.info.displayName": 1,
  "data.info.titleManagement": 1,
  _id: 1,
  createdAt: 1,
  deleted: 1
};

type Props = {
  slice: string;
  title: string;
  query: Record<string, any>;
  setResetStickyCallback: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
};

export default ({ query, slice, title, setResetStickyCallback }: Props): JSX.Element => {
  const dealershipsList = useSelector((state: RootState) => state.listDealershipSlice[slice]);

  const [resetStickyTableCallback, setResetStickyTableCallback] = React.useState<() => void>();
  const columns: Column<FormState>[] = [
    {
      getData: (entry): CellValue => entry.data.info.displayName,
      label: "Display Name",
      name: "displayName",
      show: (_userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.displayName?.show;
      },
      filters: [
        {
          path: ["_id"],
          preview: DealershipFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => {
        return <DealsModal dealIds={entry.data.info.titleManagement?.dealsIds} />;
      },
      label: "Deals",
      name: "deals",
      show: (_userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.deals?.show;
      }
    },
    {
      getData: (entry): CellValue => {
        return (
          <LimitedTitleIssuesModal
            titleIssuesIds={entry.data.info.titleManagement?.titleIssuesIds}
          />
        );
      },
      label: "Title Issues",
      name: "titleIssues",
      show: (_userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.deals?.show;
      }
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.titleManagement?.outstandingDeals?.toString(),
      label: "Outstanding deals",
      name: "outstandingDeals",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.outstandingDeals?.show;
      },
      filters: [
        {
          path: ["data", "info", "titleManagement", "outstandingDeals"],
          preview: PriceFilter
        }
      ]
    },
    ...(slice === "funding-limit"
      ? ([
          {
            getData: (entry): CellValue =>
              entry?.data?.info?.titleManagement?.outstandingTitlesFundingLimit,
            label: "Outstanding titles funding limit",
            name: "outstandingTitlesFundingLimit",
            show: (userPermissions, tableSettings) => {
              return tableSettings?.data?.columns?.outstandingTitlesFundingLimit?.show;
            },
            filters: [
              {
                path: ["data", "info", "titleManagement", "outstandingTitlesFundingLimit"],
                preview: PriceFilter
              }
            ]
          }
        ] as Column<FormState>[])
      : []),
    ...(slice === "penalty-box-limit"
      ? ([
          {
            getData: (entry): CellValue =>
              entry?.data?.info?.titleManagement?.outstandingTitlesApplicationsLimit,
            label: "Outstanding titles applications limit",
            name: "outstandingTitlesApplicationsLimit",
            show: (userPermissions, tableSettings) => {
              return tableSettings?.data?.columns?.outstandingTitlesApplicationsLimit?.show;
            },
            filters: [
              {
                path: ["data", "info", "titleManagement", "outstandingTitlesApplicationsLimit"],
                preview: PriceFilter
              }
            ]
          }
        ] as Column<FormState>[])
      : []),
    {
      getData: (entry): CellValue =>
        entry.data?.info?.titleManagement?.restrictionDate
          ? new Date(entry.data?.info?.titleManagement?.restrictionDate)
          : "",
      label: "Restriction Date",
      options: {
        sort: true,
        path: ["data", "info", "titleManagement", "restrictionDate"]
      },
      name: "restrictionDate",
      show: (_userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.restrictionDate?.show;
      },
      filters: [
        {
          path: ["data", "info", "titleManagement", "restrictionDate"],
          preview: DateFilter,
          label: "Restriction Date"
        }
      ]
    },
    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  const handleResetStickyState = () => {
    resetStickyTableCallback?.();
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, [resetStickyTableCallback]);
  return (
    <div style={{ position: "relative", height: "90%" }}>
      <Paper elevation={3} style={{ padding: "10px", height: "100%" }}>
        <Typography variant="h6">{title}</Typography>
        <AccessControl requiredPermissions={{ entity: "dealership", action: "read" }}>
          <Table
            setResetStickyCallback={setResetStickyTableCallback}
            elevation={0}
            tableContainerOverflowX="scroll"
            rowsPerPage={10}
            tableName={slice}
            entityName="dealership"
            listFunction={getDealershipList}
            listEntity={dealershipsList}
            deleteEntityFunction={deleteDealership}
            recoverEntityFunction={recoverDealership}
            aggregateFirst={true}
            slice={slice}
            query={query}
            projection={projections}
            title={title}
            columns={columns}
            hideAddButton
          />
        </AccessControl>
      </Paper>
    </div>
  );
};
