import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteVehicleInsuranceCompanyRequest {
  _id: string;
}

interface DeleteVehicleInsuranceCompanyResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteVehicleInsuranceCompanyRequest,
  DeleteVehicleInsuranceCompanyResponse
>("DeleteVehicleInsuranceCompany", {
  request: "delete_vehicle_insurance_company"
});

export const deleteVehicleInsuranceCompanyActions = socketMessageSlice.actions;
export const deleteVehicleInsuranceCompany = socketAction;
export default socketMessageSlice.reducer;
