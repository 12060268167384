import { EntityType, EntityDataTable } from "utils/entitySlice";
import React, { useRef, useState } from "react";
import { IconButton } from "@material-ui/core";
import { HistoryList } from "./HistoryList";
import History from "@material-ui/icons/History";
import { StateAccess } from "utils/models/formGenerator";

export const HistoryButton = <T extends EntityType>(props: {
  stateAccess: StateAccess;
  collection: EntityDataTable<T>;
  _id: string;
}) => {
  const [appear, setAppear] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const clickEvent = () => setAppear(true);

  return (
    <>
      <IconButton onClick={clickEvent} ref={buttonRef}>
        <History />
      </IconButton>
      {appear && buttonRef.current !== null ? (
        <HistoryList
          {...props}
          anchorElement={buttonRef.current}
          hideList={() => setAppear(false)}
        />
      ) : null}
    </>
  );
};
