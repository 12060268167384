import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteCreditScoreRangeRequest {
  _id: string;
}

interface DeleteCreditScoreRangeResponse {
  message: {
    _id: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteCreditScoreRangeRequest,
  DeleteCreditScoreRangeResponse
>("DeleteCreditScoreRange", {
  request: "delete_credit_score_range"
});

export const deleteCreditScoreRangeActions = socketMessageSlice.actions;
export const deleteCreditScoreRange = socketAction;
export default socketMessageSlice.reducer;
