import { Schema } from "ajv";
import { CustomRegexes } from "../../helpers";
import { lenderAgreement } from "../lenders/lenders";

const validDealStatusValues = [
  "lead",
  "credit check",
  "pending",
  "counter",
  "approved",
  "delivered",
  "cancelled",
  "denied",
  "signed",
  null
];

const term = {
  ProgramID: { type: "string", nullable: true },
  ProgramName: { type: "string", nullable: true },
  PlanID: { type: "string", nullable: true },
  PlanName: { type: "string", nullable: true },
  TermID: { type: "string", nullable: true },
  TermName: { type: "string", nullable: true },
  Miles: { type: "string", nullable: true },
  Months: { type: "string", nullable: true },
  FinalOdometer: { type: "string", nullable: true },
  Expires: { type: "string", nullable: true },
  FactoryWarranty: { type: "string", nullable: true },
  PlanCost: { type: "string", nullable: true },
  TotalCareCoverage: { type: "string", nullable: true },
  TechPackage: { type: "string", nullable: true },
  CommercialVehicle: { type: "string", nullable: true },
  TotalCost: { type: "string", nullable: true },
  LOL: { type: "string", nullable: true },
  IncreaseLiabilityLimit: { type: "string", nullable: true }
};
const fundingFees = {
  type: "array",
  nullable: true,
  items: {
    type: "object",
    properties: {
      amount: { type: "number", nullable: true },
      description: { type: "string", nullable: true },
      type: { type: "string", nullable: true },
      document: {
        type: "object",
        nullable: true,
        properties: {
          name: { type: "string", nullable: true },
          url: { type: "string", nullable: true },
          metadata: {
            type: "object",
            nullable: true,
            properties: {
              contentType: { type: "string", nullable: true }
            }
          }
        }
      }
    }
  }
};
const payment = {
  type: "object",
  nullable: true,
  properties: {
    taxableAmount: { type: "number", nullable: true },
    monthlyPayment: { type: "number", nullable: true },
    dealTotal: { type: "number", nullable: true },
    amountFinanced: { type: "number", nullable: true },
    downPayment: { type: "number", nullable: true },
    interestRate: { type: "number", nullable: true },
    numberOfPayments: { type: "integer", nullable: true },
    daysToFirstPayment: { type: "integer", nullable: true },
    totalAftermarket: { type: "number", nullable: true },
    totalFees: { type: "number", nullable: true },
    totalOfPayments: { type: "number", nullable: true },
    totalTaxes: { type: "number", nullable: true },
    localTaxes: { type: "number", nullable: true },
    stateTaxes: { type: "number", nullable: true },
    type: {
      type: "string",
      enum: ["monthly", "early", null],
      errorMessage: {
        enum: "must be one of the following: 'monthly' or 'early'"
      },
      nullable: true
    },
    totalSalePrice: { type: "number", nullable: true },
    buyPercent: { type: "number", nullable: true },
    sellPercent: { type: "number", nullable: true },
    fundedAmount: { type: "number", nullable: true },

    splitMethod: {
      type: "string",
      enum: ["fulltimeF&I", "correspondent", null],
      errorMessage: {
        enum: 'must be one of the following: "fulltimeF&I", "correspondent"'
      }
    },
    wfdSplit: { type: "number", nullable: true },
    dealershipSplit: { type: "number", nullable: true },
    wfdGAPSplit: { type: "number", nullable: true },
    dealershipGAPSplit: { type: "number", nullable: true },
    wfdWarrantySplit: { type: "number", nullable: true },
    dealershipWarrantySplit: { type: "number", nullable: true },
    correspondentPercent: { type: "number", nullable: true },
    reserve: { type: "number", nullable: true },
    maxReserve: { type: "number", nullable: true },
    reserveMethod: {
      type: ["string", "null"],
      enum: [
        "markup",
        "fixed",
        "percentage",
        "difference between buy / sell rate * total amount financed then / sell rate and then * reserve %",
        "markup foursight",
        null
      ],
      errorMessage: {
        enum:
          'must be one of the following: "markup", "markup foursight", "fixed", "percentage", "difference between buy / sell rate * total amount financed then / sell rate and then * reserve %"'
      }
    },
    lenderAgreement: lenderAgreement
  }
};
const profit = {
  type: "object",
  nullable: true,
  properties: {
    totalGAPProfit: { type: "number", nullable: true },
    totalServiceWarrantyProfit: { type: "number", nullable: true },
    dealershipProfit: {
      type: "object",
      nullable: true,
      properties: {
        reserveCommission: { type: "number", nullable: true },
        splitTotalFromGap: { type: "number", nullable: true },
        splitTotalFromServiceWarranty: { type: "number", nullable: true },
        splitFromDeal: { type: "number", nullable: true },
        gapInsuranceProfit: { type: "number", nullable: true },
        serviceWarrantyInsuranceProfit: { type: "number", nullable: true },
        profitFromOtherInsurances: { type: "number", nullable: true },
        splitTotalFromInsurances: { type: "number", nullable: true },
        totalProfit: { type: "number", nullable: true }
      }
    },
    wfdProfit: {
      type: "object",
      nullable: true,
      properties: {
        extraServiceWarrantyProfit: { type: "number", nullable: true },
        reserverPercentage: { type: "number", nullable: true },
        extraGAPProfit: { type: "number", nullable: true },
        reserveCommission: { type: "number", nullable: true },
        splitTotalFromServiceWarranty: { type: "number", nullable: true },
        extraReserveProfit: { type: "number", nullable: true },
        reserveMethodType: {
          type: ["string", "null"],
          nullable: true,
          enum: ["fixed", "percentage", "min(<interest>/2,2.25)", null],
          errorMessage: {
            enum: "must be one of the following: 'fixed', 'percentage', 'min(<interest>/2,2.25)'"
          }
        },
        splitTotalFromGap: { type: "number", nullable: true },
        splitTotal: { type: "number", nullable: true },
        splitFromDeal: { type: "number", nullable: true },
        gapInsuranceProfit: { type: "number", nullable: true },
        serviceWarrantyInsuranceProfit: { type: "number", nullable: true },
        extraServiceWarrantyInsuranceProfit: {
          type: "number",
          nullable: true
        },
        profitFromOtherInsurances: { type: "number", nullable: true },
        splitTotalFromInsurances: { type: "number", nullable: true },
        totalProfit: { type: "number", nullable: true },
        splitFromVehicleCoverage: { type: "number", nullable: true }
      }
    },
    representativeProfit: {
      type: "object",
      nullable: true,
      properties: { commission: { type: "number", nullable: true } }
    },
    managerProfit: {
      type: "object",
      nullable: true,
      properties: { commission: { type: "number", nullable: true } }
    }
  }
};
export const dealInfoProperties: { [name: string]: Schema } = {
  refNumber: { type: "string", nullable: true },
  pendingDate: { type: "string", nullable: true },
  dateAudits: {
    type: "array",
    nullable: true,
    items: {
      type: "object",
      properties: {
        type: {
          type: ["string", "null"],
          nullable: true,
          enum: ["submittedForFunding", null],
          errorMessage: {
            enum: "must be one of the following: 'submittedForFunding'"
          }
        },
        oldDate: {
          type: "string",
          format: "date-time",
          errorMessage: { format: "must be a valid date" },
          nullable: true
        },
        newDate: {
          type: "string",
          format: "date-time",
          errorMessage: { format: "must be a valid date" },
          nullable: true
        },
        updatedAt: {
          type: "string",
          format: "date-time",
          errorMessage: { format: "must be a valid date" },
          nullable: true
        },
        updatedBy: {
          type: "string",
          nullable: true
        }
      }
    }
  },
  creditScores: {
    type: "array",
    nullable: true,
    items: {
      type: "object",
      nullable: true,
      properties: {
        scores: {
          type: "object",
          properties: {
            applicant: { type: "string", nullable: true },
            coApplicant: { type: "string", nullable: true }
          }
        },
        url: { type: "string", nullable: true },
        type: { type: "string", nullable: true },
        applicantName: {
          type: "string",
          nullable: true,
          pattern: CustomRegexes.LETTERS,
          errorMessage: { pattern: "should not contain numbers" }
        },
        coApplicantName: {
          type: "string",
          nullable: true,
          pattern: CustomRegexes.LETTERS,
          errorMessage: { pattern: "should not contain numbers" }
        },
        applicantId: { type: "string", nullable: true },
        coApplicantId: { type: "string", nullable: true },
        isDuplicate: { type: "boolean", nullable: true },
        duplicateInfo: { type: "string", nullable: true }
      }
    }
  },
  type: { type: "string", nullable: true },
  paymentGoal: { type: "number", nullable: true },
  status: {
    type: "string",
    nullable: true
    // enum: validDealStatusValues,
    // errorMessage: {
    //   enum: `Deal's status must be one of the following:
    //   'lead',
    //   'credit check',
    //   'pending',
    //   'counter',
    //   'approved',
    //   'delivered',
    //   'denied',
    //   'signed'`,
    // },
  },
  collateralReceivedDate: {
    type: "string",
    format: "date-time",
    errorMessage: { format: "must be a valid date" },
    nullable: true
  },
  creditCheckDate: {
    type: "string",
    format: "date-time",
    errorMessage: { format: "must be a valid date" },
    nullable: true
  },
  vehicle: {
    type: "object",
    nullable: true,
    properties: {
      vehicleDrivers: {
        type: "array",
        nullable: true,
        items: {
          type: "object",
          properties: {
            firstName: {
              type: "string",
              nullable: true,
              pattern: CustomRegexes.LETTERS,
              errorMessage: { pattern: "should not contain numbers" }
            },
            middleName: {
              type: "string",
              nullable: true,
              pattern: CustomRegexes.LETTERS,
              errorMessage: { pattern: "should not contain numbers" }
            },
            lastName: {
              type: "string",
              nullable: true,
              pattern: CustomRegexes.LETTERS,
              errorMessage: { pattern: "should not contain numbers" }
            },
            homePhone: {
              type: "string",
              nullable: true,
              pattern: CustomRegexes.PHONE,
              errorMessage: {
                pattern: "should not contain letters and should not start with 0 or 1"
              }
            },
            mobilePhone: {
              type: "string",
              nullable: true,
              pattern: CustomRegexes.PHONE,
              errorMessage: {
                pattern: "should not contain letters and should not start with 0 or 1"
              }
            },
            businessPhone: {
              type: "string",
              nullable: true,
              pattern: CustomRegexes.PHONE,
              errorMessage: {
                pattern: "should not contain letters and should not start with 0 or 1"
              }
            },
            birthDate: {
              type: "string",
              format: "age-restriction",
              nullable: true,
              errorMessage: { format: "should be a date between 18 and 100" }
            },
            email: {
              type: "string",
              nullable: true,
              pattern: CustomRegexes.EMAIL,
              errorMessage: {
                pattern: "should match the format <emailname>@<provider>.<org>"
              }
            },
            driverLicenseNumber: { type: "string", nullable: true },
            familyRelation: { type: "string", nullable: true },
            propertyAddress: { type: "string", nullable: true },
            propertyOwnership: { type: "string", nullable: true }
          }
        }
      },
      unitStatus: { type: "string", nullable: true },
      invoice: { type: "number", nullable: true },
      unitType: { type: "string", nullable: true },
      odometer: { type: "integer", nullable: true },
      MSRP: { type: "number", nullable: true },
      inServiceDate: { type: "string", nullable: true },
      VIN: { type: "string", nullable: true },
      isVinDecoded: { type: "boolean", nullable: true },
      year: {
        type: "integer",
        minimum: 1900,
        maximum: new Date().getFullYear() + 1,
        //"Year" is purposely skipped in the start of the string
        //since when we format the errors we take the key
        //whic in this case is "year"
        errorMessage: "of vehicle must be >= 1900 and not higher than the current or next year",
        nullable: true
      },
      make: { type: "string", nullable: true },
      model: { type: "string", nullable: true },
      currentInsuranceCompany: { type: "string", nullable: true },
      customizations: {
        type: "array",
        nullable: true,
        items: {
          type: "object",
          properties: {
            customization: { type: "string" }
          }
        }
      },
      yearMakeModel: { type: "string", nullable: true },
      hasTrailer: { type: "boolean" },
      powerSport: {
        type: "object",
        nullable: true,
        properties: { cc: { type: "string", nullable: true } }
      },
      trailer: {
        type: "object",
        nullable: true,
        properties: {
          unitStatus: {
            type: ["string", "null"],
            nullable: true,
            enum: ["new", "used", null, ""],
            errorMessage: {
              enum: "must be one of the following: 'new', 'used'"
            }
          },
          year: { type: "integer", nullable: true },
          make: { type: "string", nullable: true },
          model: { type: "string", nullable: true },
          serialNumber: { type: "string", nullable: true }
        }
      },
      boat: {
        type: "object",
        nullable: true,
        properties: {
          type: { type: "string", nullable: true },
          length: { type: "integer", nullable: true },
          engineType: { type: "string", nullable: true },
          numberOfEngines: { type: "integer", nullable: true },
          engine: {
            type: "object",
            nullable: true,
            properties: {
              engineNumber: { type: "string", nullable: true },
              unitStatus: {
                type: ["string", "null"],
                nullable: true,
                enum: ["new", "used", null, ""],
                errorMessage: {
                  enum: "must be one of the following: 'new', 'used'"
                }
              },
              year: { type: "integer", nullable: true },
              make: { type: "string", nullable: true },
              model: { type: "string", nullable: true }
            }
          },
          secondEngine: {
            type: "object",
            nullable: true,
            properties: {
              engineNumber: { type: "string", nullable: true },
              unitStatus: {
                type: ["string", "null"],
                nullable: true,
                enum: ["new", "used", null],
                errorMessage: {
                  enum: "must be one of the following: 'new', 'used'"
                }
              },
              year: { type: "integer", nullable: true },
              make: { type: "string", nullable: true },
              model: { type: "string", nullable: true }
            }
          },
          hull: {
            type: "object",
            nullable: true,
            properties: {
              hullNumber: { type: "string", nullable: true },
              year: { type: "integer", nullable: true },
              make: { type: "string", nullable: true },
              model: { type: "string", nullable: true },
              hullMaterial: { type: "string", nullable: true },
              hullValue: { type: "number", nullable: true }
            }
          },
          horsepower: { type: "integer", nullable: true },
          cc: { type: "integer", nullable: true },
          maxSpeed: { type: "integer", nullable: true },
          enginesValue: { type: "number", nullable: true }
        }
      }
    }
  },
  price: {
    type: "object",
    nullable: true,
    required: [],
    properties: {
      price: { type: "number", nullable: true },
      options: { type: "number", nullable: true },
      netTrade: { type: "number", nullable: true },
      rebates: { type: "number", nullable: true },
      payoff: { type: "number", nullable: true },
      totalCash: { type: "number", nullable: true },
      totalTrade: { type: "number", nullable: true },
      trade: {
        type: "array",
        nullable: true,
        items: {
          type: "object",
          nullable: true,
          properties: {
            value: { type: "number", nullable: true },
            year: {
              type: "integer",
              minimum: 1900,
              maximum: new Date().getFullYear() + 1,
              errorMessage:
                //"Year" is purposely skipped in the start of the string
                //since when we format the errors we take the key
                //whic in this case is "year"
                "of trade-in vehicle must be >= 1900 and not higher than the current or next year",
              nullable: true
            },
            model: { type: "string", nullable: true },
            make: { type: "string", nullable: true },
            VIN: { type: "string", nullable: true }
          }
        }
      }
    }
  },
  payment,
  accounting: {
    type: "object",
    nullable: true,
    properties: {
      copied: {
        type: "boolean",
        nullable: true
      },
      fundingNoticeDate: {
        type: "string",
        format: "date-time",
        errorMessage: { format: "must be a valid date" },
        nullable: true
      },
      collateralFundingDate: {
        type: "string",
        format: "date-time",
        errorMessage: { format: "must be a valid date" },
        nullable: true
      },
      reserveFundingDate: {
        type: "string",
        format: "date-time",
        errorMessage: { format: "must be a valid date" },
        nullable: true
      },
      warrantyPrice: {
        type: "number",
        nullable: true
      },
      warrantyCost: {
        type: "number",
        nullable: true
      },
      gapPrice: {
        type: "number",
        nullable: true
      },
      ecoPrice: {
        type: "number",
        nullable: true
      },
      wheelAndTirePrice: {
        type: "number",
        nullable: true
      },
      paintAndFabricationPrice: {
        type: "number",
        nullable: true
      },
      prePaidMaintenancePrice: {
        type: "number",
        nullable: true
      },
      gapRemit: {
        type: "number",
        nullable: true
      },
      warrantyRemit: {
        type: "number",
        nullable: true
      },
      gapCost: {
        type: "number",
        nullable: true
      },
      profit,
      payment,
      taxesAndFees: {
        type: "object",
        nullable: true,
        properties: {
          totalMembershipFees: {
            type: "number",
            nullable: true
          },
          totalUCCFees: {
            type: "number",
            nullable: true
          },
          fundingFees
        }
      }
    }
  },
  aftermarketOptions: {
    type: "object",
    nullable: true,
    properties: {
      totalGAPRemitPrice: { type: "number", nullable: true },
      totalServiceWarrantyRemitPrice: { type: "number", nullable: true },
      totalServiceWarrantyDealerCostPrice: { type: "number", nullable: true },
      totalGAPDealerCostPrice: { type: "number", nullable: true },
      totalServiceWarrantySellPrice: { type: "number", nullable: true },
      totalPaintAndFabricationPrice: { type: "number", nullable: true },
      totalPrePaidMaintenancePrice: { type: "number", nullable: true },
      totalEcoPrice: { type: "number", nullable: true },
      totalWheelAndTirePrice: { type: "number", nullable: true },
      totalGAPSellPrice: { type: "number", nullable: true },
      insurances: {
        type: "array",
        nullable: true,
        items: {
          anyOf: [
            {
              type: "object",
              properties: {
                id: { type: "string", nullable: true },
                type: {
                  type: "string",
                  enum: ["F&I Express"],
                  errorMessage: {
                    enum: "must be one of the following: 'F&I Express'"
                  }
                },
                fundingDocumentId: { type: "string", nullable: true },
                provider: { type: "string", nullable: true },
                originalRate: {
                  type: "object",
                  nullable: true,
                  additionalProperties: true
                },
                chosenRate: {
                  type: "object",
                  properties: {
                    EX1RateResponseID: { type: "string", nullable: true },
                    ContractNumber: { type: "string", nullable: true },
                    months: { type: "integer", nullable: true },
                    insuranceType: {
                      type: ["string", "null"],
                      enum: ["gap", "service warranty", null],
                      errorMessage: {
                        enum: "must be one of the following: 'gap', 'service warranty'"
                      }
                    },
                    dealerCostPrice: { type: "number", nullable: true },
                    retailPrice: { type: "number", nullable: true },
                    remitPrice: { type: "number", nullable: true },
                    profit: { type: "number", nullable: true },
                    ContractFormID: { type: "string", nullable: true },
                    product: {
                      type: "object",
                      nullable: true,
                      properties: {
                        Approved: { type: "string", nullable: true },
                        ContractPrefix: { type: "string", nullable: true },
                        EX1ProductID: { type: "string", nullable: true },
                        ProductCode: { type: "string", nullable: true },
                        ProductName: { type: "string", nullable: true },
                        ProviderDealerID: { type: "string", nullable: true },
                        ProviderName: { type: "string", nullable: true }
                      }
                    },
                    dynamicSurcharges: {
                      type: "array",
                      nullable: true,
                      items: {
                        type: "object",
                        properties: {
                          Cost: { type: "string", nullable: true },
                          Description: { type: "string", nullable: true },
                          IsEditable: { type: "string", nullable: true },
                          Price: { type: "string", nullable: true },
                          SurchargeCode: { type: "string", nullable: true },
                          Value: { type: "string", nullable: true },
                          selected: { type: "boolean", nullable: true }
                        }
                      }
                    },
                    surcharges: { type: "array", nullable: true },
                    ContractForm: { type: "string", nullable: true },
                    EX1ProviderID: { type: "string", nullable: true },
                    Coverage: { type: "string", nullable: true },
                    soldBy: {
                      type: "string",
                      enum: ["WFD", "Dealer"],
                      errorMessage: {
                        enum: "must be one of the following: 'WFD', 'Dealer'"
                      },
                      nullable: true
                    }
                  }
                }
              }
              // required: ["type", "provider", "chosenRate"],
            },
            {
              type: "object",
              properties: {
                id: { type: "string", nullable: true },
                provider: { type: "string" },
                type: {
                  type: "string",
                  enum: ["Protective"],
                  errorMessage: {
                    enum: "must be one of the following: 'Protective'"
                  }
                },
                fundingDocumentId: { type: "string", nullable: true },
                ContractNumber: { type: "string", nullable: true },
                ContractPrefix: { type: "string", nullable: true },
                VSCRateOptions: {
                  type: "object",
                  properties: {
                    BeginningOdometer: { type: "integer", nullable: true },
                    VehiclePlan: {
                      enum: [
                        "ExtendedEligibilityProgram",
                        "New",
                        "PostSale",
                        "PreOwned",
                        "Renewal",
                        "Rental",
                        "ThreeMonthTerm",
                        "VehiclesWithout6MonthManufacturersWarranty",
                        "VehiclesWithoutCoachManufacturersWarranty",
                        null
                      ],
                      type: ["string", "null"],
                      nullable: true,
                      errorMessage: {
                        enum: `must be one of the following:
                        'ExtendedEligibilityProgram',
                        'New',
                        'PostSale',
                        'PreOwned',
                        'Renewal',
                        'Rental',
                        'ThreeMonthTerm',
                        'VehiclesWithout6MonthManufacturersWarranty',
                        'VehiclesWithoutCoachManufacturersWarranty'`
                      }
                    },
                    InServiceDate: { type: "string", nullable: true },
                    Surcharges: {
                      type: "object",
                      properties: {
                        TwoYearMfg: { type: "boolean", nullable: true },
                        ThreeYearMfg: { type: "boolean", nullable: true },
                        ThreePlus: { type: "boolean", nullable: true },
                        BusinessUse: { type: "boolean", nullable: true },
                        ConsequentialFailure: {
                          type: "boolean",
                          nullable: true
                        },
                        RearDiesel: { type: "boolean", nullable: true },
                        FrontDiesel: { type: "boolean", nullable: true }
                      }
                      // required: [
                      //   "BusinessUse",
                      //   "ConsequentialFailure",
                      //   "ThreePlus",
                      //   "ThreeYearMfg",
                      //   "TwoYearMfg",
                      // ],
                    },
                    EffectiveDate: { type: "string", nullable: true }
                  }
                  // required: [
                  //   "BeginningOdometer",
                  //   "InServiceDate",
                  //   "Surcharges",
                  //   "VehiclePlan",
                  // ],
                },
                VehicleDetails: {
                  type: "object",
                  properties: {
                    VehicleYear: { type: "integer", nullable: true },
                    VehicleMake: { type: "string", nullable: true },
                    VehicleModel: { type: "string", nullable: true },
                    VehicleMsrp: { type: "number", nullable: true },
                    ChassisYear: { type: "integer", nullable: true },
                    ChassisMake: { type: "string", nullable: true },
                    ChassisModel: { type: "string", nullable: true },
                    VehicleClassCode: { type: "string", nullable: true },
                    VehicleType: {
                      enum: [
                        "FifthWheel",
                        "LiveInHorseTrailer",
                        "MotorHomeClassA",
                        "MotorHomeClassB",
                        "MotorHomeClassC",
                        "ParkModel",
                        "PopUpCamper",
                        "SlideInCamper",
                        "TrailerOnly",
                        "TravelTrailer",
                        null
                      ],
                      type: ["string", "null"],
                      errorMessage: {
                        enum: `must be one of the following:
                        'FifthWheel',
                        'LiveInHorseTrailer',
                        'MotorHomeClassA',
                        'MotorHomeClassB',
                        'MotorHomeClassC',
                        'ParkModel',
                        'PopUpCamper',
                        'SlideInCamper',
                        'TrailerOnly',
                        'TravelTrailer',
                        `
                      }
                    },
                    InServiceDate: { type: "string", nullable: true }
                  }

                  // required: [
                  //   "ChassisYear",
                  //   "InServiceDate",
                  //   "VehicleClassCode",
                  //   "VehicleMake",
                  //   "VehicleModel",
                  //   "VehicleType",
                  //   "VehicleYear",
                  // ],
                },
                chosenRate: {
                  type: "object",
                  properties: {
                    ContractFormID: { type: "integer", nullable: true },
                    Coverage: { type: "string", nullable: true },
                    CoverageCode: { type: "string", nullable: true },
                    CoverageSortOrder: { type: "integer", nullable: true },
                    CoverageTermMiles: { type: "integer", nullable: true },
                    CoverageTermMinMonths: { type: "integer", nullable: true },
                    months: { type: "integer", nullable: true },
                    dealerCostPrice: { type: "number", nullable: true },
                    Deductible: { type: "number", nullable: true },
                    OrderNumber: { type: "integer", nullable: true },
                    ProductClass: { type: "string", nullable: true },
                    ProductClassCode: { type: "number", nullable: true },
                    ProductType: { type: "string", nullable: true },
                    RateNumber: { type: "string", nullable: true },
                    retailPrice: { type: "number", nullable: true },
                    remitPrice: { type: "number", nullable: true },
                    profit: { type: "number", nullable: true },
                    insuranceType: {
                      type: ["string", "null"],
                      enum: [
                        "gap",
                        "service warranty",
                        "paint and fabrication",
                        "pre-paid maintenance",
                        "eco",
                        "wheel and tire",
                        null
                      ],
                      errorMessage: {
                        enum:
                          "must be one of the following: 'gap', 'service warranty', 'paint and fabrication', 'pre-paid maintenance','eco', 'wheel and tire'"
                      },
                      nullable: true
                    },
                    soldBy: {
                      type: "string",
                      enum: ["WFD", "Dealer"],
                      errorMessage: {
                        enum: "must be one of the following: 'WFD', 'Dealer'"
                      },
                      nullable: true
                    }
                  }

                  // required: [
                  //   "ContractFormID",
                  //   "Coverage",
                  //   "CoverageCode",
                  //   "CoverageSortOrder",
                  //   "CoverageTermMiles",
                  //   "CoverageTermMinMonths",
                  //   "Deductible",
                  //   "OrderNumber",
                  //   "ProductClass",
                  //   "ProductClassCode",
                  //   "ProductType",
                  //   "RateNumber",
                  //   "dealerCostPrice",
                  //   "months",
                  //   "profit",
                  //   "remitPrice",
                  //   "retailPrice",
                  // ],
                }
              }

              // required: [
              //   "ContractNumber",
              //   "ContractPrefix",
              //   "VSCRateOptions",
              //   "VehicleDetails",
              //   "chosenRate",
              //   "type",
              //   "provider",
              // ],
            },
            {
              type: "object",
              properties: {
                type: {
                  type: "string",
                  enum: ["Custom"],
                  errorMessage: {
                    enum: "must be one of the following: 'Custom'"
                  }
                },
                id: { type: "string", nullable: true },
                fileName: { type: "string", nullable: true },
                url: { type: "string", nullable: true },
                fundingDocumentId: { type: "string", nullable: true },
                provider: { type: "string", nullable: true },
                customProvider: { type: "string", nullable: true },
                sequence: {
                  type: "object",
                  nullable: true,
                  additionalProperties: true,
                  properties: {
                    _id: { type: "string", nullable: true },
                    seq: { type: "integer", nullable: true }
                  }
                },
                chosenRate: {
                  type: "object",
                  properties: {
                    months: { type: "integer", nullable: true },
                    insuranceType: {
                      type: ["string", "null"],
                      enum: [
                        "gap",
                        "service warranty",
                        "paint and fabrication",
                        "pre-paid maintenance",
                        "eco",
                        "wheel and tire",
                        null
                      ],
                      errorMessage: {
                        enum:
                          "must be one of the following: 'gap', 'service warranty', 'paint and fabrication', 'pre-paid maintenance','eco', 'wheel and tire'"
                      },
                      nullable: true
                    },
                    customMonths: { type: "number", nullable: true },
                    customMonthsEnabled: { type: "boolean", nullable: true },
                    dealerCostPrice: { type: "number", nullable: true },
                    retailPrice: { type: "number", nullable: true },
                    remitPrice: { type: "number", nullable: true },
                    profit: { type: "number", nullable: true },
                    deductible: { type: "number", nullable: true },
                    soldBy: {
                      type: "string",
                      enum: ["WFD", "Dealer"],
                      errorMessage: {
                        enum: "must be one of the following: 'WFD', 'Dealer'"
                      },
                      nullable: true
                    }
                  }
                }
              }
              // required: ["provider", "chosenRate", "type"],
            },
            {
              type: "object",
              nullable: true,
              properties: {
                id: { type: "string", nullable: true },
                type: {
                  type: "string",
                  enum: ["ASC"],
                  errorMessage: { enum: "must be one of the following: 'ASC" }
                },
                AccountNumber: { type: "string", nullable: true },
                originalRate: {
                  type: "object",
                  nullable: true,
                  properties: term
                },
                provider: { type: "string", nullable: true },
                chosenRate: {
                  type: "object",
                  properties: {
                    ...term,
                    surcharges: {
                      type: "array",
                      nullable: true,
                      items: {
                        type: "object",
                        required: [],
                        properties: {
                          name: {
                            type: ["string", "null"],
                            enum: [
                              "TechPackage",
                              "CommercialVehicle",
                              "IncreaseLiabilityLimit",
                              null
                            ],
                            errorMessage: {
                              enum:
                                "must be one of the following: 'TechPackage', 'CommercialVehicle', 'IncreaseLiabilityLimit'"
                            }
                          },
                          value: { type: "string", nullable: true },
                          checked: { type: "boolean", nullable: true }
                        }
                      }
                    },
                    DRFC: { type: "number", nullable: true },
                    EngineCC: { type: "integer", nullable: true },
                    insuranceType: {
                      type: ["string", "null"],
                      enum: ["service warranty", null],
                      errorMessage: {
                        enum: "must be one of the following: 'service warranty'"
                      },
                      nullable: true
                    },
                    dealerCostPrice: { type: "number", nullable: true },
                    remitPrice: { type: "number", nullable: true },
                    retailPrice: { type: "number", nullable: true },
                    profit: { type: "number", nullable: true },
                    AgreementType: {
                      type: ["string", "null"],
                      enum: ["Quote", "Agreement", "Cancellation", null],
                      errorMessage: {
                        enum: "must be one of the following: 'Quote', 'Agreement', 'Cancellation'"
                      },
                      nullable: true
                    },
                    LiftedVehicle: { type: "string", nullable: true },
                    DisplayCharges: { type: "string", nullable: true },
                    soldBy: {
                      type: "string",
                      enum: ["WFD", "Dealer"],
                      errorMessage: {
                        enum: "must be one of the following: 'WFD', 'Dealer'"
                      },
                      nullable: true
                    }
                  }
                },
                AgreementType: {
                  type: ["string", "null"],
                  enum: ["Quote", "Agreement", "Cancellation", null],
                  errorMessage: {
                    enum: "must be one of the following: 'Quote', 'Agreement', 'Cancellation'"
                  },
                  nullable: true
                },
                LiftedVehicle: { type: "string", nullable: true },
                DisplayCharges: { type: "string", nullable: true },
                ID: { type: "string", nullable: true },
                AgreementNumber: { type: "string", nullable: true },
                PDF: { type: "string", nullable: true }
              }
              // required: ["provider", "chosenRate", "type"],
            }
          ]
        }
      }
    }
  },
  taxesAndFees: {
    type: "object",
    nullable: true,
    required: [],
    properties: {
      VSIAmount: { type: "number", nullable: true },
      documentRegistrationFees: { type: "number", nullable: true },
      filingFees: { type: "number", nullable: true },
      license: { type: "number", nullable: true },
      otherFees: { type: "number", nullable: true },
      titleTransfer: { type: "number", nullable: true },
      totalTax: { type: "number", nullable: true },
      isFixedTax: { type: "boolean", nullable: true },
      percentTax: { type: "number", nullable: true },
      fixedTax: { type: "number", nullable: true },
      totalMembershipFees: { type: "number", nullable: true },
      totalUCCFees: { type: "number", nullable: true },
      detailed: {
        type: "object",
        nullable: true,
        properties: {
          registrationTax: { type: "number", nullable: true },
          tempPlateFee: { type: "number", nullable: true },
          contributionFee: { type: "number", nullable: true },
          plateFee: { type: "number", nullable: true },
          wheelageTax: { type: "number", nullable: true },
          psVehicleFee: { type: "number", nullable: true },
          transferTax: { type: "number", nullable: true },
          titleTransferFee: { type: "number", nullable: true },
          techSurchargeFee: { type: "number", nullable: true },
          lienFee: { type: "number", nullable: true },
          deputyFilingFee: { type: "number", nullable: true },
          processingFee: { type: "number", nullable: true },
          exciseTax: { type: "number", nullable: true },
          licenseAndRegFee: { type: "number", nullable: true }
        }
      },
      fundingFees
    }
  },
  profit,
  dealDates: {
    type: "object",
    nullable: true,
    properties: {
      contractDate: {
        type: "string",
        format: "date-time",
        errorMessage: { format: "must be a valid date" },
        nullable: true
      },
      fundedAt: {
        type: "string",
        format: "date-time",
        errorMessage: { format: "must be a valid date" },
        nullable: true
      },
      submittedForFunding: {
        type: "string",
        format: "date-time",
        errorMessage: { format: "must be a valid date" },
        nullable: true
      },
      firstPaymentDay: {
        type: "string",
        format: "date-time",
        errorMessage: { format: "must be a valid date" },
        nullable: true
      },
      payrollDate: {
        type: "string",
        format: "date-time",
        errorMessage: { format: "must be a valid date" },
        nullable: true
      }
    }
  },
  firstPaymentAfter: { type: "string", nullable: true },
  firstPaymentDate: {
    type: "string",
    format: "date-time",
    errorMessage: { format: "must be a valid date" },
    nullable: true
  },
  collateral: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: { pattern: "should not contain numbers" }
  },
  floorplanXpress: {
    type: "object",
    nullable: true,
    properties: {
      isConnected: { type: "boolean" },
      data: {
        type: "object",
        nullable: true,
        properties: {
          VIN: { type: "string", nullable: true },
          year: { type: "integer", nullable: true },
          make: { type: "string", nullable: true },
          model: { type: "string", nullable: true },
          color: { type: "string", nullable: true },
          titleStatus: { type: "string", nullable: true },
          payoffToday: { type: "number", nullable: true },
          payoffTwoDays: { type: "number", nullable: true },
          note: { type: "string", nullable: true },
          dealer: { type: "string", nullable: true },
          branchEmail: {
            type: "string",
            pattern: CustomRegexes.EMAIL,
            errorMessage: {
              pattern: "must match the format <emailname>@<provider>.<org>"
            },
            nullable: true
          },
          branchPhone: {
            type: "string",
            pattern: CustomRegexes.PHONE_WITH_DASHES,
            nullable: true,
            errorMessage: {
              pattern: "should not contain letters and should not start with 0 or 1"
            }
          }
        }
      },
      isSold: { type: "boolean" }
    }
  },
  vehicleCoverage: {
    type: "object",
    nullable: true,
    properties: {
      amountOfPremium: { type: "number", nullable: true },
      commissionPercent: { type: "number", nullable: true }
    }
  },
  emailInfo: {
    type: "object",
    nullable: true,
    properties: {
      totalValueOfUnit: { type: "number", nullable: true },
      applicant: { type: "boolean" },
      coApplicant: { type: "boolean" }
    }
  }
};
