import { Box, InputBase, Paper } from "@material-ui/core";
import { alpha, createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { useSnackbar } from "notistack";
import React from "react";
import ResultsList from "./ResultsList";
import { ResultsTableProps, SearchState } from "./types";
import useDebounce from "Chat/hooks/useDebounce";
import useGlobalSearch from "hooks/useGlobalSearch/useGlobalSearch";
import { Applicant } from "components/Applicants/types";
import { Deal } from "components/Deals/types";
import { Dealership } from "components/Dealerships/types";
import { Lender } from "components/Lenders/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto"
      }
    },
    searchIcon: {
      width: theme.spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    inputRoot: {
      color: "inherit"
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: 200
      }
    },
    resultsWrapper: {
      padding: theme.spacing(1, 1, 1, 3),
      position: "absolute",
      width: "50%",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "-24px"
      }
    }
  })
);

const transformSearchToSearchTerm = (search: string) => {
  if (!search) return {};
  return search?.length > 3 ? { search: { term: search } } : {};
};
const transformRequestDataToResultsTableProps = (requestData: {
  data: {
    applicants: Applicant[] | undefined;
    deals: Deal[] | undefined;
    lenders: Lender[] | undefined;
    dealerships: Dealership[] | undefined;
  };
  isLoading: {
    applicantsAreLoading: boolean;
    dealsAreLoading: boolean;
    lendersAreLoading: boolean;
    dealershipsAreLoading: boolean;
  };
  error: {
    applicantsError: unknown;
    dealsError: unknown;
    lendersError: unknown;
    dealershipsError: unknown;
  };
}): ResultsTableProps => {
  return {
    applicants: {
      data: requestData.data.applicants,
      loading: requestData.isLoading.applicantsAreLoading,
      error: requestData.error.applicantsError
    },
    deals: {
      data: requestData.data.deals,
      loading: requestData.isLoading.dealsAreLoading,
      error: requestData.error.dealsError
    },
    lenders: {
      data: requestData.data.lenders,
      loading: requestData.isLoading.lendersAreLoading,
      error: requestData.error.lendersError
    },
    dealerships: {
      data: requestData.data.dealerships,
      loading: requestData.isLoading.dealershipsAreLoading,
      error: requestData.error.dealershipsError
    }
  };
};
export default function GlobalSearch() {
  const classes = useStyles();
  const [state, setState] = React.useState<SearchState>({ value: "", focus: false });
  const debouncedValue = useDebounce<SearchState>(state, 1000);
  const requestResult = useGlobalSearch(transformSearchToSearchTerm(debouncedValue.value));
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          id="searchInput"
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          inputProps={{ "aria-label": "search" }}
          value={state.value}
          autoComplete="new-password"
          onChange={(e) => setState((prev) => ({ ...prev, value: e.target.value }))}
          onFocus={() => setState((prev) => ({ ...prev, focus: true }))}
          onBlur={() => setState((prev) => ({ ...prev, focus: false }))}
        />
      </div>
      {state.focus && (
        <Box
          className={classes.resultsWrapper}
          style={{
            display: state.focus && state.value.length > 3 ? "block" : "none"
          }}
        >
          <Paper elevation={3}>
            <ResultsList {...transformRequestDataToResultsTableProps(requestResult)} />
          </Paper>
        </Box>
      )}
    </Box>
  );
}
