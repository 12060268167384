import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { RootState } from "app/rootReducer";
import AlertDialog from "components/common/AlertDialog";
import formEditContext from "components/Content/FormEditContext";
import CommonDialog from "components/CreditScore/CreditScoreDealStatusDialog";
import {
  getLatestReports,
  getLatestVerifications,
  isAAMVAVerificationValid,
  isDatabaseVerificationValid,
  isEmailReportValid,
  isPhoneReportValid
} from "components/Deals/FraudValidation/FraudValidationPreview";
import useCreditPrequalify from "hooks/useCreditPrequalify/useCreditPrequalify";
import useGetPersonaReports from "hooks/usePersonaReports/usePersonaReports";
import useGetPersonaVerifications from "hooks/usePersonaVerifications/usePersonaVerifications";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sanitizeAddress } from "utils/functions";
import { notifyWebhooks } from "utils/logging";
import { StateAccess } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import { Applicant } from "../Applicants/types";
import { Deal, DealStatus } from "../Deals/types";
import { VerificationContext } from "../Layout/Main";
import {
  checkCreditScore,
  checkCreditScoreActions,
  isErrorResponse,
  isSuccessResponse
} from "./checkCreditScoreSlice";
import FraudVerificationsDialog from "./FraudVerificationsDialog";
import { CreditCheckMode, CreditCheckTypes } from "./types";

interface Props {
  stateAccess: StateAccess;
}

const softPullStatuses = [
  DealStatus.Lead,
  DealStatus.CreditCheck,
  DealStatus.WaitingForDealDetails,
  DealStatus.DealCreated,
  DealStatus.PendingLenderDecision
];

const dealValidation = (deal: Deal) => [
  { value: deal?._id, name: `Deal needs to be created before the credit check!` },
  { value: deal?.data?.dealership?._id, name: `Deal -> Dealership` }
];

const validationFields = (
  applicantData: Applicant["data"]["info"] | undefined,
  type: "Applicant" | "Co-Applicant"
) => [
  { value: applicantData?.firstName, name: `${type} -> First name` },
  { value: applicantData?.lastName, name: `${type} -> Last name` },
  { value: applicantData?.currentAddress, name: `${type} -> Current address` },
  { value: applicantData?.currentAddressNumber, name: `${type} -> Current address number` },
  { value: applicantData?.currentCity, name: `${type} -> City` },
  { value: applicantData?.currentState, name: `${type} -> State` },
  {
    value: (applicantData?.currentZipCode ?? "")?.toString(),
    name: `${type} -> Zip Code`
  },
  { value: applicantData?.socialSecurityNumber, name: `${type} -> SSN` }
];

const creditCheckData = (applicantData: Applicant["data"]["info"] | undefined) => ({
  firstName: applicantData?.firstName.replace(/[^A-Za-z\s]/gi, ""),
  lastName: applicantData?.lastName.replace(/[^A-Za-z\s]/gi, ""),
  address: sanitizeAddress(
    [applicantData?.currentAddressNumber, applicantData?.currentAddress].join(" ")
  ),
  city: (applicantData?.currentCity ?? "").replace(/[^a-zA-Z ]/gi, ""),
  state: applicantData?.currentState.replace(/[^a-zA-Z ]/gi, ""),
  zipCode: applicantData?.currentZipCode,
  ssn: applicantData?.socialSecurityNumber
});

export const formatScores = (el: any) => {
  switch (el?.type) {
    case "applicant":
      return `${el?.applicantName} - ${el?.scores?.applicant ? el?.scores?.applicant : "N/A"}`;
    case "coApplicant":
      return `${el?.coApplicantName} - ${el?.scores.coApplicant ? el?.scores?.coApplicant : "N/A"}`;
    case "joint":
      return `${el?.applicantName} - ${el?.scores.applicant ? el?.scores?.applicant : "N/A"} / ${
        el?.coApplicantName
      } - ${el?.scores.coApplicant ? el?.scores?.coApplicant : "N/A"}`;
    default:
      return `${el?.applicantName} - ${el?.scores?.applicant ? el?.scores?.applicant : "N/A"}`;
  }
};

export default function CheckCreditLookup({ stateAccess }: Props) {
  const [alertState, setAlertState] = useState(false);
  const [fraudVerificationsDialogOpen, setFraudVerificationsDialogOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const deal: Deal = stateAccess.get([] as any);
  const dispatch = useDispatch();
  const [requestId, setRequestId] = useState(uuidv4());
  const actionState = useSelector((state: RootState) => state.checkCreditScoreSlice[requestId]);
  const [dismisDuplicateCheck, setDismisDuplicateCheck] = useState(false);
  const [creditCheckMode, setCreditCheckMode] = useState<CreditCheckMode>(
    softPullStatuses.includes(deal.data.info.status) ? CreditCheckMode.Soft : CreditCheckMode.Hard
  );
  const [isLoading, setIsLoading] = useState(false);
  const [creditCheckType, setCreditCheckType] = useState<CreditCheckTypes>(
    CreditCheckTypes.Applicant
  );
  const checkDataValidity = useContext(VerificationContext);
  const { enqueueSnackbar } = useSnackbar();
  const { enabled: editMode, edited: dealEdited } = useContext(formEditContext);
  const { checkCreditPrequalify } = useCreditPrequalify(setIsLoading);
  const query = {
    "data.applicantId": {
      $in: [deal.data.applicantId, deal.data.coApplicantId].filter((x) => x) as string[]
    }
  };
  const { data: personaVerificationsData } = useGetPersonaVerifications(
    deal.data.applicantId as string,
    {
      query
    }
  );
  const { data: personaReportsData } = useGetPersonaReports(deal.data.applicantId as string, {
    query
  });
  const latestReports = getLatestReports(personaReportsData);
  const latestVerifications = getLatestVerifications(personaVerificationsData);

  const handleCheckCreditScore = (forceCheck: boolean, statusChange: boolean) => {
    if (!dealEdited && deal.data.applicant) {
      const info =
        creditCheckType === CreditCheckTypes.Joint
          ? {
              dealId: deal._id,
              applicantId: deal.data.applicant._id,
              coApplicantId: deal.data.coApplicant?._id,
              type: creditCheckType,
              applicant: creditCheckData(deal.data.applicant?.data.info),
              coApplicant: creditCheckData(deal.data.coApplicant?.data?.info),
              ...(forceCheck && { appModified: true }),
              dealershipId: deal.data?.dealership?._id
            }
          : creditCheckType === CreditCheckTypes.Applicant
          ? {
              dealId: deal._id,
              applicantId: deal.data.applicant._id,
              coApplicantId: deal.data.coApplicant?._id,
              type: creditCheckType,
              applicant: creditCheckData(deal.data.applicant?.data.info),
              ...(forceCheck && { appModified: true }),
              dealershipId: deal.data?.dealership?._id
            }
          : {
              dealId: deal._id,
              applicantId: deal.data.applicant._id,
              coApplicantId: deal.data.coApplicant?._id,
              type: creditCheckType,
              applicant: creditCheckData(deal.data.coApplicant?.data.info),
              ...(forceCheck && { appModified: true }),
              dealershipId: deal.data?.dealership?._id
            };
      if (creditCheckMode === CreditCheckMode.Hard)
        dispatch(
          checkCreditScore({
            statusChange,
            data: {
              info
            },
            requestId
          })
        );
      else {
        checkCreditPrequalify({
          data: {
            info
          }
        });
      }
      setIsLoading(true);
    } else {
      // throw new Error("Credit check was submitted without applicant!");
      setAlertContent("Please Save the Deal before accessing Credit Score.");
      setAlertState(true);
    }
  };

  const handleClick = (forceCheck: boolean, statusChange: boolean) => {
    if (
      !fraudVerificationsDialogOpen &&
      ((!latestReports.email?.data?.info?.webhookResponse &&
        !latestReports.phone?.data?.info?.webhookResponse &&
        !latestVerifications?.aamvaVerification?.data?.info?.webhookResponse &&
        !latestVerifications?.databaseVerification?.data?.info?.webhookResponse) ||
        (!isAAMVAVerificationValid(latestVerifications?.aamvaVerification) &&
          !isDatabaseVerificationValid(
            latestVerifications?.databaseVerification?.data?.info?.verification?.response
          ) &&
          !isEmailReportValid(latestReports?.email?.data?.info?.report?.response) &&
          !isPhoneReportValid(latestReports?.phone?.data?.info?.report?.response)))
    ) {
      setFraudVerificationsDialogOpen(true);
      return;
    } else {
      checkDataValidity(
        [
          ...dealValidation(deal),
          ...([CreditCheckTypes.Joint, CreditCheckTypes.Applicant].includes(creditCheckType)
            ? validationFields(deal.data.applicant?.data.info, "Applicant")
            : []),
          ...([CreditCheckTypes.Joint, CreditCheckTypes.CoApplicant].includes(creditCheckType)
            ? validationFields(deal.data.coApplicant?.data.info, "Co-Applicant")
            : [])
        ],
        () => handleCheckCreditScore(forceCheck, statusChange)
      );
    }
  };

  const handleChangeJoint = (type: CreditCheckTypes) => {
    setCreditCheckType(type);
  };

  useEffect(() => {
    if (actionState !== undefined && actionState.status !== undefined) {
      switch (actionState.status) {
        case "error":
          dispatch({
            type: checkCreditScoreActions.none.type,
            payload: { requestId }
          });
          enqueueSnackbar(`${"Error on getting credit score!"} ${actionState.message}`, {
            variant: "error"
          });
          setRequestId(uuidv4());
          notifyWebhooks(actionState?.message);
          setIsLoading(false);
          break;
        case "waiting":
          setIsLoading(true);
          break;
        case "success":
          const result = actionState?.data?.message;
          if (!isSuccessResponse(result.data["700CreditResponse"]?.Results)) {
            try {
              notifyWebhooks(result);
              const errorResult = result.data["700CreditResponse"]?.Results;
              if (isErrorResponse(errorResult)) {
                const messages: string[] = Object.values(
                  errorResult?.Creditsystem_Error ?? {}
                )?.map((e: any) => e?.attributes?.message);

                if (messages.length > 0) {
                  enqueueSnackbar(`${"Error on getting credit score!"} ${messages.join("</br>")}`, {
                    variant: "error"
                  });
                }
              }
            } catch (error) {
              notifyWebhooks(result.data["700CreditResponse"]);
            }
          } else {
            if (
              !(stateAccess.get(["data", "creditBureaus"]) ?? []).find(
                (cb: any) => cb?._id === result?._id
              )
            ) {
              stateAccess.set(
                ["data", "creditBureaus"],
                [...(stateAccess.get(["data", "creditBureaus"]) ?? []), result]
              );
            }
          }
          setRequestId(uuidv4());
          setIsLoading(false);
          dispatch({
            type: checkCreditScoreActions.none.type,
            payload: { requestId }
          });
          break;
      }
    }
  }, [deal, actionState, dispatch, requestId, stateAccess, creditCheckType, enqueueSnackbar]);

  return (
    <>
      {fraudVerificationsDialogOpen ? (
        <FraudVerificationsDialog
          submitFunction={handleClick}
          deal={deal}
          closeFunction={() => setFraudVerificationsDialogOpen(false)}
          statusChange={dismisDuplicateCheck}
        />
      ) : null}
      <AlertDialog open={alertState} closeFunc={setAlertState} content={alertContent} />
      {Object.entries(CreditCheckTypes).map(([label, type]) => {
        return (
          <FormControlLabel
            key={label}
            control={
              <Radio
                disabled={
                  (editMode !== undefined && !editMode) ||
                  isLoading ||
                  (CreditCheckMode.Soft === creditCheckMode && type === CreditCheckTypes.Joint)
                }
                checked={creditCheckType === type}
                onChange={() => handleChangeJoint(type)}
              />
            }
            id={`creditScore-${type}-radio`}
            label={label}
          />
        );
      })}

      <FormControlLabel
        id="creditScore-ignore-duplicate-check"
        control={
          <Checkbox
            disabled={(editMode !== undefined && !editMode) || isLoading}
            id="ignore-duplicate-checkbox"
            checked={dismisDuplicateCheck}
            value={dismisDuplicateCheck}
            onChange={() => setDismisDuplicateCheck((x) => !x)}
          />
        }
        label="Force credit check"
      />

      <FormControlLabel
        id="creditScore-Quick--check"
        control={
          <Checkbox
            disabled={(editMode !== undefined && !editMode) || isLoading}
            id="check-Quick-checkbox"
            checked={creditCheckMode === CreditCheckMode.Soft}
            value={creditCheckMode === CreditCheckMode.Soft}
            onChange={() => {
              handleChangeJoint(CreditCheckTypes.Applicant);
              setCreditCheckMode(CreditCheckMode.Soft);
            }}
          />
        }
        label="Quick check(soft)"
      />

      <FormControlLabel
        id="creditScore-Hard--check"
        control={
          <Checkbox
            disabled={(editMode !== undefined && !editMode) || isLoading}
            id="check-Hard-checkbox"
            checked={creditCheckMode === CreditCheckMode.Hard}
            value={creditCheckMode === CreditCheckMode.Hard}
            onChange={() => setCreditCheckMode(CreditCheckMode.Hard)}
          />
        }
        label="Hard check"
      />

      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "auto"
        }}
      >
        <Button
          style={{ marginRight: "3px", flex: 3 }}
          fullWidth
          suppressContentEditableWarning={true}
          contentEditable={!((editMode !== undefined && !editMode) || isLoading)}
          size="small"
          id="creditScore-button"
          variant="contained"
          color="primary"
          startIcon={
            <>
              <SearchIcon />
              <CircularProgress
                style={{
                  position: "absolute",
                  color: "#ffffff",
                  marginLeft: "-3px",
                  marginTop: "-3px",
                  display: isLoading ? "block" : "none"
                }}
                size={25}
              />
            </>
          }
          disabled={
            (editMode !== undefined && !editMode) ||
            isLoading ||
            !stateAccess.get(["data", "dealership", "approved"])
          }
          onClick={() =>
            deal.data.info.status === "lead"
              ? handleClick(dismisDuplicateCheck, true)
              : (editMode === undefined || editMode) &&
                !isLoading &&
                handleClick(dismisDuplicateCheck, false)
          }
        >
          Credit Score
        </Button>
      </Box>
    </>
  );
}
