import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "app/rootReducer";
import DateFilter from "components/Filters/DateFilter";
import TextFilter from "components/Filters/TextFilter";
import AccessControl from "components/Access/AccessControl";

import { FormState } from "./types";
import Table, { CellValue, Column } from "../Table";
import { deleteLenderTicketType } from "./deleteLenderTicketTypeSlice";
import { getLenderTicketTypesList } from "./listLenderTicketTypeSlice";
import { recoverLenderTicketType } from "./recoverLenderTicketTypeSlice";

export const projections = {
  "data.info.type": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};

export default (): JSX.Element => {
  const rangeList = useSelector((state: RootState) => state.listLenderTicketTypeSlice["table"]);
  const columns: Column<FormState>[] = [
    {
      label: "Name",
      getData: (el): CellValue => el.data.info.type,
      name: "name",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      },
      filters: [
        {
          path: ["data", "info", "name"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true,
          label: "Name",
          name: "name"
        }
      ]
    },

    {
      label: "Date added",
      getData: (el): CellValue => new Date(el.createdAt),
      options: {},
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter,
          label: "Created At",
          name: "createdAt"
        }
      ]
    },
    {
      label: "Actions",
      getData: (el): CellValue => el._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <>
      <AccessControl requiredPermissions={{ entity: "lender_ticket_type", action: "read" }}>
        <Table
          tableName="lender_ticket_type"
          entityName="lender_ticket_type"
          listFunction={getLenderTicketTypesList}
          listEntity={rangeList}
          deleteEntityFunction={deleteLenderTicketType}
          recoverEntityFunction={recoverLenderTicketType}
          title={"Lender ticket types"}
          columns={columns}
          projection={projections}
        />
      </AccessControl>
    </>
  );
};
