import { Button, Grid } from "@material-ui/core";
import Save from "@material-ui/icons/Save";
import React from "react";
import { StateAccess } from "./formGenerator";

export type ButtonProperties = {
  text: string;
  startIcon: React.ReactNode;
};

const defaultButtonProperties = {
  text: "Save",
  startIcon: <Save />
};

interface ButtonProps {
  stateAccess: StateAccess;
  formName: string;
  buttonProps?: ButtonProperties;
  disabled?: boolean;
}

export default ({ stateAccess, formName, buttonProps, disabled = false }: ButtonProps) => {
  const buttonProperties = {
    ...defaultButtonProperties,
    ...buttonProps
  };

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="flex-end"
      direction="row"
      style={{
        position: "sticky",
        background: "white",
        padding: "5px",
        zIndex: 1,
        borderRadius: "6px",
        right: "0px",
        marginTop: "5px",
        bottom: "10px",
        width: "100px",
        marginLeft: "calc(100% - 99px)",
        boxShadow:
          "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)"
      }}
    >
      <Button
        disabled={disabled}
        suppressContentEditableWarning={true}
        contentEditable={false}
        form={formName}
        id="submit_button"
        type="submit"
        color="primary"
        variant="contained"
        startIcon={buttonProperties.startIcon}
      >
        {buttonProperties.text}
      </Button>
    </Grid>
  );
};
