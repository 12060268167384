import React from "react";
import { Button } from "@material-ui/core";

import AccessControl from "components/Access/AccessControl";
import { useDisableUser } from "hooks/useDisableUsers/useDisableUsers";

import { User } from "./types";

const DisableUser = ({ user }: { user: User }) => {
  const { disableUser } = useDisableUser();

  const handleDisableUser = () => {
    if (user) disableUser(user);
  };

  return (
    <AccessControl requiredPermissions={{ entity: "wfd_user", action: "delete" }}>
      <div style={{ position: "absolute", right: 20 }}>
        <Button
          variant="contained"
          color={user?.data?.firebaseDisabled ? "primary" : "secondary"}
          onClick={handleDisableUser}
        >{`${user?.data?.firebaseDisabled ? "Enable" : "Disable"} user`}</Button>
      </div>
    </AccessControl>
  );
};

export default DisableUser;
