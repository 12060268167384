import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

export default function CloseDialog({ closeFunction }: { closeFunction: () => void }) {
  return (
    <IconButton
      id="close-dialog-button"
      style={{ position: "absolute", top: "5px", right: "5px" }}
      onClick={closeFunction}
    >
      <CloseIcon />
    </IconButton>
  );
}
