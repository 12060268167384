import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteStipulationRequest {
  _id: string;
}

interface DeleteStipulationResponse {
  message: {
    _id: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteStipulationRequest,
  DeleteStipulationResponse
>("DeleteStipulation", {
  request: "delete_stipulation"
});

export const deleteStipulationActions = socketMessageSlice.actions;
export const deleteStipulation = socketAction;
export default socketMessageSlice.reducer;
