import { Schema } from "ajv";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import notes from "../common/notes_schema";
import contacts_properties from "./contacts_properties";
import info_properties from "./info_properties";

export const dealershipAgreement: Schema = {
  type: "object",
  required: [],
  properties: {
    conditions: {
      type: "array",
      nullable: true,
      items: {
        type: "object",
        required: [],
        properties: {
          field: {
            anyOf: [
              { type: "array", nullable: true },
              { type: "string", nullable: true }
            ]
          },
          type: { type: "string" },
          value: { type: "number" }
        }
      }
    },
    type: { type: "string", nullable: true },
    field: {
      anyOf: [
        { type: "array", nullable: true },
        { type: "string", nullable: true }
      ]
    },
    value: { type: "number", nullable: true },
    dealershipProfit: { type: "number", nullable: true },
    wfdProfit: { type: "number", nullable: true },
    method: { type: "number", nullable: true },
    splitMethod: { type: "string", nullable: true },
    correspondentPercent: { type: "number", nullable: true }
  }
};

export const data: Schema = {
  type: "object",
  // required: ["info", "contacts"],
  // nullable: true,
  properties: {
    info: {
      type: "object",
      nullable: true,
      // required: ["name"],
      properties: info_properties
    },
    contacts: {
      type: "array",
      nullable: true,
      items: {
        type: "object",
        required: [],
        properties: contacts_properties
      }
    },
    representativeId: { type: "string", nullable: true },
    customerCareAssigneeId: { type: "string", nullable: true },
    source: { type: "string", nullable: true },
    sourceId: { type: "string", nullable: true },
    hubspotId: { type: "string", nullable: true },
    notes: notes,
    dealershipProgramId: {
      type: "string",
      nullable: true
    }
  }
};

export default {
  new_dealership: {
    $id: "newDealership",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      }
    }
  },
  update_dealership: {
    $id: "updateDealership",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      },
      approved: {
        type: "boolean",
        nullable: true
      },
      approvedAt: {
        type: "string",
        nullable: true
      },
      approvedByUserId: {
        type: "string",
        nullable: true
      }
    }
  },
  delete_dealership: {
    $id: "deleteDealership",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" }
    }
  },
  recover_dealership: {
    $id: "recoverDealership",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" }
    }
  },
  get_dealership: {
    $id: "getDealership",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" }
    }
  },
  all_dealerships: {
    $id: "allDealerships",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties }
    ]
  }
};
