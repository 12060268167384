import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { CreditBureauData } from "./types";

type AddCreditScoreRequest = CreditBureauData;

export interface AddCreditScoreResponse {
  message: {
    data: CreditBureauData;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddCreditScoreRequest,
  AddCreditScoreResponse
>("NewCreditScore", {
  request: "new_credit_score"
});

export const addCreditScoreActions = socketMessageSlice.actions;
export const addCreditScore = socketAction;
export default socketMessageSlice.reducer;
