
import { CreditBureau } from "./types";
import { createListSlice } from "../Middlewares/listSliceCreator";


const { ListSlice, getList } = createListSlice<CreditBureau>({
  name: "CreditScoreList",
  request_topic: "all_credit_scores",
  reducers: {}
});
export default ListSlice.reducer;
export const getCreditScoreList = getList;
export const removeCreditScoreList = ListSlice.actions.removeList;
export const listCreditScoreActions = ListSlice.actions;
