import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

const data = {
  type: "object",
  required: ["dealershipIds"],
  properties: {
    dealershipIds: {
      type: "array",
      items: {
        type: "string",
      },
    },
    dealershipProgramId: {
      type: "string",
    },
    month: {
      type: "number",
    },
    year: {
      type: "number",
    },
  },
};

export default {
  new_dealership_bills_report: {
    $id: "newDealershipBillsReport",
    type: "object",
    required: ["data"],
    properties: {
      data,
    },
  },
  update_dealership_bills_report: {
    $id: "updateDealershipBillsReport",
    type: "object",
    required: ["data"],
    properties: {
      data,
    },
  },
  all_dealership_bills_reports: {
    $id: "allDealershipBillsReport",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
