import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import TextLoop from "./TextLoop";

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      position: "fixed",
      width: "100%",
      height: "100%",
      background: "rgba(255,255,255,0.2)",
      textAlign: "center",
      zIndex: 1301,
      lineHeight: "100vh",
      fontSize: "40px"
    }
  })
);
export default function Spinner({ text = "Loading", style = {} }: { text?: string; style?: any }) {
  const classes = useStyles();
  return (
    <div className={classes.loader} id="loading-shadow" style={style}>
      <TextLoop text={text} />
    </div>
  );
}
