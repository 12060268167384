import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { DealerNote } from "./types";

interface EditDealerNoteResponse {
  message: DealerNote;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DealerNote,
  EditDealerNoteResponse
>("EditDealerNote", {
  request: "update_dealer_note"
});

export const editDealerNote = socketAction;
export const editDealerNoteActions = socketMessageSlice.actions;
export const editDealerNoteError = socketMessageSlice.actions.error;

export default socketMessageSlice.reducer;
