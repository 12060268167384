import infoProperties from "./info_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import { Schema } from "ajv";

const unrequitedExternalId = { type: "string", nullable: true };

const data: Schema = {
  type: "object",
  required: ["info"],
  properties: {
    info: { type: "object", properties: infoProperties },
    dealId: unrequitedExternalId,
    contractId: unrequitedExternalId,
    lenderId: unrequitedExternalId,
    documentTemplateId: unrequitedExternalId,
  },
};

export default {
  new_file: {
    $id: "newFile",
    type: "object",
    required: ["data"],
    properties: {
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      data,
    },
  },
  update_file: {
    $id: "updateFile",
    type: "object",
    required: ["_id", "data"],
    properties: {
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      _id: { type: "string" },
      data,
    },
  },
  delete_file: {
    $id: "deleteFile",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  recover_file: {
    $id: "recoverFile",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_file: {
    $id: "getFile",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_files: {
    $id: "allFiles",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
