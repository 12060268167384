import React from "react";
import { Box, Button, Paper, TextField, Typography } from "@material-ui/core";
import { TitleIssueNote } from "components/TitleIssueNotes/types";
import formatDate from "utils/formatDate";
import { TabContext } from "components/Layout/LayoutWrapper";
import { LenderNote } from "components/LenderTickets/LenderNotes/types";

type Props<T extends TitleIssueNote | LenderNote> = {
  notes: T[];
  type: "lender" | "dealer";
};

const renderEntities = <T extends TitleIssueNote | LenderNote>(
  notes: T[],
  handleOpen: (id: string) => void
) => {
  return notes
    .map((n, index) => ({ ...n, index }))
    .filter((n) => !n.deleted)
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    ?.map((note, index) => {
      const id = ("titleIssueId" in note.data
        ? note?.data?.titleIssueId
        : note?.data?.ticketId) as string;
      return (
        <Paper
          key={index}
          style={{
            padding: "10px",
            marginBottom: "20px",
            display: "block"
          }}
        >
          <Box display="flex" alignContent="flex-start">
            <Box flexGrow={1}>
              <TextField
                multiline
                value={note?.data?.info?.note}
                name="note"
                inputProps={{
                  readOnly: true
                }}
                placeholder="Note"
                fullWidth
                label={"Note"}
                variant={"outlined"}
                size="small"
              />
              <Box
                fontSize={12}
                justifyContent="space-between"
                display={"flex"}
                marginTop={"10px"}
                style={{ maxHeight: 20 }}
              >
                {[
                  note.data?.info?.user?.firstName,
                  note?.data?.info?.user?.lastName,
                  formatDate(note?.createdAt, "medium", true)
                ]
                  .filter((x) => x)
                  .join(" ")}
              </Box>
              <Button variant="contained" style={{ float: "right" }} onClick={() => handleOpen(id)}>
                {`Open ${"titleIssueId" in note.data ? "title" : "ticket"}`}
              </Button>
            </Box>
          </Box>
        </Paper>
      );
    });
};
export default <T extends TitleIssueNote | LenderNote>({ notes, type }: Props<T>) => {
  const createOrFocusTab = React.useContext(TabContext);
  const handleOpenTitle = (id: string) => {
    return createOrFocusTab({
      label: "Show page",
      index: "showPage",
      isForSidebar: false,
      isForQuickAccess: false,
      isPersistent: false,
      props: {
        _id: id,
        type: type === "dealer" ? "title_issue" : "lender_ticket"
      }
    });
  };
  return (
    <Paper elevation={3} style={{ padding: "10px", height: "100%" }}>
      <Typography variant="h6" style={{ textAlign: "center" }}>
        {`Unread Notes from ${type}`}
      </Typography>
      {renderEntities<T>(notes, handleOpenTitle)}
    </Paper>
  );
};
