import React, { useEffect } from "react";
import { StateAccess } from "utils/models/formGenerator";

export default function ({
  stateAccess,
  reserveMethod
}: {
  stateAccess: StateAccess;
  reserveMethod: "fixed" | "percentage";
}) {
  const reserveFixed = stateAccess.get(["content", "reserveFixed"]);
  const dealerSplitFixed = stateAccess.get(["content", "dealerSplitFixed"]);
  const extraReserveFixed = stateAccess.get(["content", "extraReserveFixed"]);

  const reservePercentage = stateAccess.get(["content", "reservePercentage"]);
  const dealerSplitPercentage = stateAccess.get(["content", "dealerSplitPercentage"]);
  const extraReservePercentage = stateAccess.get(["content", "extraReservePercentage"]);

  const extraReserveFixedCurr =
    ((reserveMethod === "fixed" ? reserveFixed : 0) || 0) - (dealerSplitFixed || 0);

  const extraReservePercentageCurr =
    ((reserveMethod === "percentage" ? reservePercentage : 0) || 0) -
    ((reserveMethod === "percentage" ? dealerSplitPercentage : 0) || 0);

  useEffect(() => {
    if (extraReservePercentageCurr !== extraReservePercentage) {
      stateAccess.set(["content", "extraReservePercentage"], extraReservePercentageCurr);
    }
    if (extraReserveFixedCurr !== extraReserveFixed) {
      stateAccess.set(["content", "extraReserveFixed"], extraReserveFixedCurr);
    }
  }, [
    extraReservePercentage,
    extraReservePercentageCurr,
    extraReserveFixedCurr,
    extraReserveFixed
  ]);
  return <></>;
}
