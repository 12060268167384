import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

import { SendGeneratedContract } from "./types";
import { Deal } from "../types";
import { Checklist } from "../Checklist/types";
export interface SendGeneratedContractResponse {
  deal: Deal;
  checklist: Checklist;
}

const { socketMessageSlice, socketAction, createAction } = createSocketMessageSlice<
  SendGeneratedContract,
  SendGeneratedContractResponse
>("SendGeneratedContract", {
  request: "send_generated_contract"
});
export const sendSendGeneratedContract = socketAction;
export const sendSendGeneratedContractAction = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
export const sendGeneratedContractActions = createAction;
