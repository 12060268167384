import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeletePrintedDocumentRequest {
  _id: string;
}

interface DeletePrintedDocumentResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeletePrintedDocumentRequest,
  DeletePrintedDocumentResponse
>("DeletePrintedDocument", {
  request: "delete_printed_document"
});

export const deletePrintedDocumentActions = socketMessageSlice.actions;
export const deletePrintedDocument = socketAction;
export default socketMessageSlice.reducer;
