import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DealershipTicketNote } from "./types";
import { RootState } from "app/rootReducer";
import { v4 as uuidv4 } from "uuid";
import formEditContext from "components/Content/FormEditContext";
import {
  Box,
  Button,
  CircularProgress,
  DialogContentText,
  IconButton,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { HintTooltip } from "components/common/HintTooltip";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelCircleIcon from "@material-ui/icons/Cancel";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import formatDate from "utils/formatDate";
import { useNotifyDealershipTicketNotes } from "hooks/useNotifyDealerEmails/useNotifyDealerEmails";
import { Warning, Help } from "@material-ui/icons";
import MailIcon from "@material-ui/icons/Mail";
import { DealershipTicket } from "components/DealershipTickets/types";
import {
  getDealershipTicketNotesList,
  listDealershipTicketNotesActions
} from "./listDealershipTicketNoteSlice";
import { addDealershipTicketNote } from "./addDealershipTicketNoteSlice";
import { deleteDealershipTicketNote } from "./deleteDealershipTicketNoteSlice";
import { editDealershipTicketNote } from "./editDealershipTicketNoteSlice";
interface Props {
  dealershipTicketNotes: DealershipTicketNote[];
  dealershipTicket: DealershipTicket;
}
export default function DealershipTicketNotesPreview({
  dealershipTicketNotes,
  dealershipTicket
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const [addRequestId] = React.useState(uuidv4());
  const [deleteRequestId] = React.useState(uuidv4());

  const [timeout, setTimeoutState] = React.useState<undefined | NodeJS.Timeout>();

  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);

  const addDealershipTicketNoteState = useSelector(
    (state: RootState) => state.addDealershipTicketNoteSlice[addRequestId]
  );

  const deleteDealershipTicketNoteState = useSelector(
    (state: RootState) => state.deleteDealershipTicketNoteSlice[deleteRequestId]
  );

  const listDealershipTicketNoteState = useSelector(
    (state: RootState) => state.listDealershipTicketNoteSlice[dealershipTicket._id]
  );
  const allEditDealershipTicketNoteStates = useSelector(
    (state: RootState) => state.editDealershipTicketNoteSlice
  );
  const editDealershipTicketNoteStates = listDealershipTicketNoteState?.entities?.map((note) => ({
    _id: note._id,
    state: allEditDealershipTicketNoteStates[note._id]
  }));
  const isLoading =
    addDealershipTicketNoteState?.status === "waiting" ||
    deleteDealershipTicketNoteState?.status === "waiting";

  const { enabled: editMode } = React.useContext(formEditContext);

  const {
    notifyDealershipTicketNotes,
    loading: notifyDealershipTicketNotesLoading
  } = useNotifyDealershipTicketNotes();
  React.useEffect(() => {
    if (!listDealershipTicketNoteState && editMode)
      dispatch(
        getDealershipTicketNotesList(dealershipTicket._id, {
          query: { deleted: false, "data.dealershipTicketId": dealershipTicket._id },
          options: { sort: { createdAt: -1 } }
        })
      );
  }, [dispatch, editMode]);

  React.useEffect(() => {
    if (
      deleteDealershipTicketNoteState !== undefined &&
      deleteDealershipTicketNoteState.status === "success" &&
      deleteDealershipTicketNoteState.data !== null
    ) {
      const index = listDealershipTicketNoteState.entities?.findIndex(
        (n: DealershipTicketNote) => n._id === deleteDealershipTicketNoteState?.data?.message?._id
      );
      dispatch({
        type: listDealershipTicketNotesActions.editList.type,

        payload: {
          index: index,
          payload: deleteDealershipTicketNoteState?.data?.message,
          listId: dealershipTicket._id
        }
      });
    }
  }, [listDealershipTicketNoteState, dispatch]);
  const handleNotifyDealerEmail = () => {
    const notes = (listDealershipTicketNoteState?.entities ?? dealershipTicketNotes)
      .filter((n) => !n.deleted && !n.data.info.sentToDealerEmail)
      .map((n) => n._id);
    if (notes?.length) {
      notifyDealershipTicketNotes({ dealershipTicketNotesIds: notes });
    }
  };
  const handleAdd = () => {
    const defaultDealershipTicketNote = {
      data: {
        dealershipTicketId: dealershipTicket._id,
        info: {
          note: "",
          user: {
            firstName: currentUser?.data?.info?.firstName || null,
            lastName: currentUser?.data?.info?.lastName || null,
            email: currentUser?.data?.info?.email || null
          },
          seen: {
            wfd: true,
            dealer: false
          },
          creator: "wfd"
        }
      }
    };
    dispatch(
      addDealershipTicketNote({
        requestId: addRequestId,
        ...(defaultDealershipTicketNote as DealershipTicketNote)
      })
    );
  };

  const handleDelete = (dealershipTicketNote: DealershipTicketNote) => {
    dispatch(
      deleteDealershipTicketNote({ requestId: deleteRequestId, _id: dealershipTicketNote._id })
    );
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    dealershipTicketNote: DealershipTicketNote & { index: number }
  ) => {
    const newDealershipTicketNote = {
      ...dealershipTicketNote,
      data: {
        ...dealershipTicketNote.data,
        info: { ...dealershipTicketNote.data.info, note: event.target.value }
      },
      updatedAt: new Date().toISOString()
    };
    dispatch({
      type: listDealershipTicketNotesActions.editList.type,

      payload: {
        index: dealershipTicketNote.index,
        payload: newDealershipTicketNote,
        listId: dealershipTicket._id
      }
    });
    return newDealershipTicketNote;
  };
  const handleUpdate = (dealershipTicketNote: DealershipTicketNote) => {
    dispatch(
      editDealershipTicketNote({ requestId: dealershipTicketNote._id, ...dealershipTicketNote })
    );
  };

  const renderEntities = () => {
    return (listDealershipTicketNoteState?.entities ?? dealershipTicketNotes)
      .map((n, index) => ({ ...n, index }))
      .filter((n) => !n.deleted)
      .sort((a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime())
      ?.map((dealershipTicketNote, index) => {
        const state = editDealershipTicketNoteStates?.find(
          (state) => state._id === dealershipTicketNote._id
        )?.state;

        if (dealershipTicketNote.data.info.creator === "dealer")
          return (
            <Paper
              key={index}
              style={{
                padding: "10px",
                marginBottom: "20px",
                display: "block",
                backgroundColor: "#6b97f6",
                color: "#fff"
              }}
            >
              <Box display="flex" alignContent="flex-start" flexDirection="column">
                <Typography variant="h6">Note from dealer</Typography>
                <Box fontSize={12} display="flex" style={{ justifyContent: "space-between" }}>
                  <Typography variant="body1"> {dealershipTicketNote?.data?.info?.note}</Typography>

                  {[
                    dealershipTicketNote.data?.info?.user?.firstName,
                    dealershipTicketNote?.data?.info?.user?.lastName,
                    formatDate(dealershipTicketNote?.createdAt, "medium", true)
                  ]
                    .filter((x) => x)
                    .join(" ")}
                </Box>
              </Box>
            </Paper>
          );
        return (
          <Paper
            key={index}
            style={{
              padding: "10px",
              marginBottom: "20px",
              display: "block",
              background: state?.status === "error" ? "#ffe3e3" : undefined
            }}
          >
            <Box display="flex" alignContent="flex-start">
              <Box flexGrow={1}>
                <TextField
                  multiline
                  onChange={(event) => {
                    if (timeout) {
                      clearTimeout(timeout);
                    }
                    const newNote = handleChange(event, dealershipTicketNote);
                    setTimeoutState(
                      setTimeout(() => {
                        handleUpdate(newNote);
                      }, 500)
                    );
                  }}
                  value={dealershipTicketNote?.data?.info?.note}
                  name="dealershipTicketNote"
                  inputProps={
                    editMode && listDealershipTicketNoteState?.status !== "waiting"
                      ? {}
                      : {
                          readOnly: true
                        }
                  }
                  placeholder="Note"
                  fullWidth
                  label={"Note"}
                  variant={
                    editMode && listDealershipTicketNoteState?.status !== "waiting"
                      ? "filled"
                      : "outlined"
                  }
                  size="small"
                  onBlur={(event) => {
                    if (event.target.value !== dealershipTicketNote?.data?.info?.note) {
                      const newNote = handleChange(event, dealershipTicketNote);
                      handleUpdate(newNote);
                    }
                  }}
                />
                <Box
                  fontSize={12}
                  justifyContent="space-between"
                  display={"flex"}
                  marginTop={"10px"}
                  style={{ maxHeight: 20 }}
                >
                  <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                    {(() => {
                      switch (state?.status) {
                        case "waiting":
                          return (
                            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                              <CircularProgress
                                size={17}
                                style={{
                                  color: "#50A538",
                                  // marginRight: 5,
                                  minHeight: 20.5,
                                  maxHeight: 20.5
                                }}
                              />
                              Saving
                            </div>
                          );
                        case "error":
                          return (
                            <button
                              className="pulse hover"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                border: "none",
                                background: "#ffe3e3",
                                fontWeight: "bold",
                                columnGap: "3px",
                                borderRadius: 3
                              }}
                              onClick={() => {
                                handleUpdate(dealershipTicketNote);
                              }}
                            >
                              <CancelCircleIcon
                                style={{
                                  color: "#E34C28"
                                }}
                              />
                              Error! Click here to retry!
                            </button>
                          );
                        default:
                          return (
                            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                              <CheckCircleIcon
                                style={{
                                  color: "#50A538"
                                }}
                              />
                              Saved
                            </div>
                          );
                      }
                    })()}
                    {(() => {
                      if (
                        notifyDealershipTicketNotesLoading &&
                        !dealershipTicketNote?.data?.info?.sentToDealerEmail
                      ) {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <CircularProgress
                              size={17}
                              style={{
                                color: "#50A538",
                                minHeight: 20.5,
                                maxHeight: 20.5
                              }}
                            />
                            Sending
                          </div>
                        );
                      }
                      if (dealershipTicketNote?.data?.info?.sentToDealerEmail) {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <MailIcon
                              style={{
                                color: "rgb(37, 78, 110)"
                              }}
                            />
                            Sent to dealer email at{" "}
                            {formatDate(
                              dealershipTicketNote?.data?.info?.sentToDealerEmailDate,
                              "short"
                            )}
                          </div>
                        );
                      } else {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <MailIcon
                              style={{
                                color: "#E34C28"
                              }}
                            />
                            Not sent to dealer
                          </div>
                        );
                      }
                    })()}
                  </div>
                  {[
                    dealershipTicketNote.data?.info?.user?.firstName,
                    dealershipTicketNote?.data?.info?.user?.lastName,
                    formatDate(dealershipTicketNote?.createdAt, "medium", true)
                  ]
                    .filter((x) => x)
                    .join(" ")}
                </Box>
              </Box>
              {editMode && (
                <Box>
                  <IconButton
                    style={{
                      color: "#E34C28"
                    }}
                    key={index}
                    aria-label={`remove dealership ticket note`}
                    onClick={() => handleDelete(dealershipTicketNote)}
                    disabled={deleteDealershipTicketNoteState?.status === "waiting"}
                  >
                    <HintTooltip title={`Click here to remove the dealership ticket note.`}>
                      <RemoveCircleIcon />
                    </HintTooltip>
                  </IconButton>
                </Box>
              )}
            </Box>
          </Paper>
        );
      });
  };
  const cantSendEmail =
    notifyDealershipTicketNotesLoading ||
    editDealershipTicketNoteStates?.some((state) => state?.state?.status === "waiting");

  return (
    <Paper
      elevation={0}
      style={{
        breakInside: "avoid",
        pageBreakInside: "avoid",
        transform: "translateZ(1)"
      }}
    >
      <Box style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <DialogContentText
          style={{
            color: "#254e6e",
            fontSize: "19px",
            margin: "5px 0px 5px 0px",

            fontWeight: "bold"
          }}
        >
          Dealership Ticket Notes
        </DialogContentText>
        <HintTooltip
          style={{ color: "#254e6e", fontSize: "20px", marginLeft: 4 }}
          title={
            <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
              Autosaved / Visible to dealer
            </div>
          }
        >
          <Help />
        </HintTooltip>

        <>
          <IconButton
            style={{
              color: "#50A538"
            }}
            aria-label={`add new dealership ticket note`}
            onClick={handleAdd}
            disabled={isLoading}
          >
            <HintTooltip title={`Click here to add new dealership ticket note.`}>
              {isLoading ? <CircularProgress size={19} /> : <AddCircleIcon />}
            </HintTooltip>
          </IconButton>
          <HintTooltip
            title={
              <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
                You should first select emails to notify in the deal in order to send emails to the
                dealership
              </div>
            }
          >
            <Button
              variant="outlined"
              style={{ fontSize: "10px", marginLeft: "auto", padding: 5, height: 30 }}
              startIcon={cantSendEmail && <Warning style={{ color: "#eed202" }} />}
              onClick={cantSendEmail ? undefined : handleNotifyDealerEmail}
            >
              Send notes to dealer
            </Button>
          </HintTooltip>
        </>
      </Box>

      {renderEntities()}
    </Paper>
  );
}
