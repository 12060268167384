import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { CreditBureauData } from "./types";

interface EditCreditScoreRequest {
  data: CreditBureauData;
}

export interface EditCreditScoreResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditCreditScoreRequest,
  EditCreditScoreResponse
>("EditCreditScore", {
  request: "update_credit_score"
});

export const editCreditScoreActions = socketMessageSlice.actions;
export const editCreditScore = socketAction;
export default socketMessageSlice.reducer;
