import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import React, { useEffect, useState } from "react";
import { EntityType, EntityData, entityConfigs } from "utils/entitySlice";
import { genTabId } from "components/Tabs/tabSlice";
import { ShowForm } from "utils/models/ShowForm";
import { v4 as uuidv4 } from "uuid";
import Spinner from "components/Loader/Spinner";
import { getLockList } from "utils/models/LockEntity/listLockSlice";

interface Props {
  _id: string;
  type: EntityType;
  model?: string | null;
  hideEdit?: boolean;
}

export default ({ _id, type, model = null, hideEdit }: Props) => {
  const entity = useSelector((state: RootState) => state.entitySlice[type]?.[_id]);
  const allLocks = useSelector((state: RootState) => state.listLockSlice["all"]);
  const dispatch = useDispatch();
  const tabId = genTabId("showPage", { _id, type: type });
  const [lockEntityRequestId] = useState(uuidv4());

  useEffect(() => {
    if (allLocks?.entities === undefined) dispatch(getLockList("all"));
  }, [lockEntityRequestId, entity, dispatch, allLocks]);

  useEffect(() => {
    const requestId = uuidv4();
    dispatch({
      type: entityConfigs[type]?.edit.sliceActions.add.type,
      payload: { requestId }
    });
    return () => {
      dispatch({
        type: entityConfigs[type]?.edit.sliceActions.remove.type,
        payload: { requestId }
      });
    };
  }, [type, dispatch]);
  const handleSubmit = (state: EntityData<typeof type>) => (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const { availableToRoles, deleted, ...data } = state;
    const roleIds = availableToRoles?.map((role: any) => role._id) ?? [];

    const editFunction = entityConfigs[type].edit.do;
    const validate = entityConfigs[type].edit.validate;
    const validateResult = validate?.(state);
    if (!validate || validateResult?.valid) {
      const requestId = uuidv4();
      dispatch(editFunction({ ...(data as any), availableToRolesIds: roleIds, requestId }));
    } else {
      alert(validateResult?.message);
    }
  };

  return entity && allLocks?.entities ? (
    <ShowForm
      tabId={tabId}
      initialState={entity}
      hideEdit={hideEdit}
      model={entityConfigs[type].model as any}
      handleSubmit={handleSubmit}
    />
  ) : (
    <Spinner text={"Loading "} style={{ position: "relative" }} />
  );
};
