import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { RootState } from "app/rootReducer";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    currentUser: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(1),
      marginRight: "60px",
      fontSize: theme.spacing(2)
    }
  })
);
export default function CurrentUser() {
  const classes = useStyles();
  const currentUser = useSelector((state: RootState) => state.authSlice);
  const user = currentUser?.user?.databaseData?.data?.info;
  const loginAsUserName =
    currentUser.user?.email !== user?.email
      ? `As ${user?.firstName ?? ""} ${user?.lastName ?? ""}`
      : undefined;
  return (
    <>
      <Typography className={classes.currentUser}>
        {currentUser.user?.email} {loginAsUserName}
      </Typography>
    </>
  );
}
