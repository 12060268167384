import Grid from "@material-ui/core/Grid";
import React from "react";
import { trimString } from "utils/functions";
import { editRenderSet } from "utils/models/formRenderers";
import { FilterProps, filterStateAccess } from "./types";
export default function TextFilter<T extends unknown>({
  setFilter,
  path,
  filterType,
  label,
  filter,
  caseInsensitive,
  partialSearch
}: FilterProps<T>) {
  const stateAccess = filterStateAccess(filter, setFilter, (values: any) => {
    if (values && values?.length > 0) {
      return {
        [path.join(".")]: {
          $in: values.map((value: any) => ({
            value: trimString(value),
            ...(partialSearch ? { partialSearch: true } : {}),
            ...(caseInsensitive ? { caseInsensitive: true } : {})
          }))
        },
        ...(filterType === "aggregatedColumnFilter" ? { filterType } : {})
      };
    } else {
      setFilter(undefined);
    }
  });
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {editRenderSet(false).multiTextInputFieldRenderer(
          {
            width: "full",
            type: "text",
            name: label?.toLowerCase() ?? "text-multi-filter",
            label: label ?? "N/A",
            required: false,
            path: [] as []
          },
          stateAccess,
          editRenderSet(false),
          false,
          []
        )}
      </Grid>
    </Grid>
  );
}
