import { PrintedDocument } from "./types";
import { permissionNode, PermissionStruct, relationPermissionNode } from "components/Roles/types";

export type PrintedDocumentsPermissionsStruct = PermissionStruct<PrintedDocument>;

export const printedDocumentsPermissionStruct: PrintedDocumentsPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    dealId: permissionNode("Deal Id"),
    documentTemplateId: permissionNode("Document Template Id"),
    documentTemplateName: permissionNode("Document Template Name"),
    fundingDocuments: relationPermissionNode("Funding documents", "funding_document"),
    info: {
      status: permissionNode("Status"),
      pages: permissionNode("Pages"),
      name: permissionNode("Name"),
      forApplicant: permissionNode("For Applicant"),
      forDealership: permissionNode("For Dealership"),
      type: permissionNode("Type"),
      rejectionInfo: {
        userId: permissionNode("Reject info user Id"),
        note: permissionNode("Reject info note")
      }
    }
  }
};
