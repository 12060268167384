import React from "react";
import States from "us-states";
import { Prescreen } from "./types";
import { Deal } from "components/DealerTrack/types";
import { getStateLabelByState } from "utils/functions";
import { Applicant } from "components/Applicants/types";
import FilesPreview from "components/Files/FilesPreview";
import AccessControl from "components/Access/AccessControl";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { FormComponent, Model, createOptionsForSelect } from "../../utils/models/fields";

const files: FormComponent<Prescreen> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (
        stateAccess: StateAccess,
        _mainstateAccess: StateAccess,
        _renderSet: RenderSet
      ) => {
        return (
          <AccessControl requiredPermissions={{ entity: "file", action: "read" }}>
            <FilesPreview
              tableName="files"
              type="prescreen"
              _id={stateAccess.get(["_id"])}
              types={stateAccess.get<Deal>(["data", "files"])}
            />
          </AccessControl>
        );
      },
      name: "file",
      label: "Files",
      width: "full",
      path: null,
      valueToString: (el) => el.name
    }
  ]
};

const driverLicense: FormComponent<Applicant> = {
  formComponent: "segment",
  width: "full",
  name: "Driver License",
  entities: [
    {
      formComponent: "license-number-field",
      name: "licenseNumber",
      label: "License number",
      width: "1/4",
      path: ["data", "info", "applicant", "driverLicenseNumber"],
      default: null
    },

    {
      formComponent: "select-field",
      name: "state",
      label: "State",
      width: "1/4",
      path: ["data", "info", "applicant", "drivingLicenseState"],
      options: createOptionsForSelect({
        possibleValues: () => Object.keys(States),
        getOptionLabel: (x) => getStateLabelByState(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },

    {
      formComponent: "date-field",
      name: "licenseIssueDate",
      label: "License Issue date",
      width: "1/4",
      path: ["data", "info", "applicant", "driverLicenseIssued"],
      disableRegion: "future",
      default: null
    },
    {
      formComponent: "date-field",
      name: "licenseExpiryDate",
      label: "License Expiry date",
      width: "1/4",
      disableRegion: "past",
      path: ["data", "info", "applicant", "driverLicenseExpires"],
      default: null
    }
  ]
};

export const getCurrentAddressStruct = (): FormComponent<Applicant> => {
  return {
    formComponent: "segment",
    width: "full",
    name: "Current Address",
    entities: [
      {
        formComponent: "segment",
        width: "2/3",
        entities: [
          {
            formComponent: "text-field",
            name: "currentAddressNumber",
            label: "#",
            path: ["data", "info", "applicant", "currentAddressNumber"],
            width: "1/4",
            default: null
          },
          {
            formComponent: "address-field",
            name: "currentAddress",
            required: true,
            label: "Address",
            path: ["data", "info", "applicant", "currentAddress"],
            width: "3/4",
            default: null
          }
        ]
      },

      {
        formComponent: "zip-code-field",
        name: "zipCode",
        label: "Zip code",
        path: ["data", "info", "applicant", "currentZipCode"],
        width: "1/3",
        default: null
      },
      {
        formComponent: "hidden-field",
        name: "currentCountry",
        label: "Country",
        width: "1/4",
        path: ["data", "info", "applicant", "currentCountry"],
        default: "USA"
      },
      {
        formComponent: "select-field",
        name: "state",
        label: "State",
        width: "1/3",
        path: ["data", "info", "applicant", "currentState"],
        autoSelect: true,
        options: createOptionsForSelect({
          possibleValues: () => Object.keys(States),
          getOptionLabel: (x) => getStateLabelByState(x),
          getSelectedOption: (x, y) => x === y
        }),
        default: null
      },
      {
        formComponent: "select-field",
        name: "county",
        label: "County",
        width: "1/3",
        autoSelect: true,
        path: ["data", "info", "applicant", "currentCounty"],
        options: createOptionsForSelect({
          possibleValues: (stateAccess: StateAccess) =>
            States[stateAccess.get<Prescreen>(["data", "info", "currentState"]) as string]
              ?.counties,
          getOptionLabel: (x) => x ?? " ",
          getSelectedOption: (x, y) => x === y
        }),
        default: null
      },
      {
        formComponent: "city-field",
        name: "city",
        label: "City",
        minLength: 3,
        path: ["data", "info", "applicant", "currentCity"],
        width: "1/3",
        default: null
      }
    ]
  };
};

export const prescreenStruct: Model<Prescreen> = {
  formComponent: "model",
  schema: "new_prescreen",
  name: "prescreen",
  entities: [
    {
      formComponent: "segment",
      name: "Preescreen Info",
      entities: [
        {
          formComponent: "name-field",
          name: "firstName",
          label: "First name",
          required: true,
          width: "1/4",
          path: ["data", "info", "applicant", "firstName"],
          default: null
        },
        {
          formComponent: "name-field",
          name: "middleName",
          label: "Middle name",
          width: "1/4",
          path: ["data", "info", "applicant", "middleName"],
          default: null
        },
        {
          formComponent: "name-field",
          name: "lastName",
          label: "Last name",
          required: true,
          width: "1/4",
          path: ["data", "info", "applicant", "lastName"],
          default: null
        },
        {
          formComponent: "date-with-age-field",
          name: "birthDate",
          label: "Date of birth",
          width: "1/2",
          path: ["data", "info", "applicant", "birthDate"],
          disableRegion: "future",
          default: null
        },
        getCurrentAddressStruct(),
        driverLicense,
        files
      ]
    }
  ]
};
