import { createListSlice } from "../Middlewares/listSliceCreator";
import { DealershipTicket } from "./types";

const { ListSlice, getList } = createListSlice<DealershipTicket>({
  name: "DealershipTicketList",
  request_topic: "all_dealership_tickets",
  reducers: {}
});

export default ListSlice.reducer;
export const getDealershipTicketList = getList;
export const listDealershipTicketActions = ListSlice.actions;
export const removeDealershipTicketList = ListSlice.actions.removeList;
