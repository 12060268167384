import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteAdditionalRequiredDocumentRequest {
  _id: string;
}

interface DeleteAdditionalRequiredDocumentResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteAdditionalRequiredDocumentRequest,
  DeleteAdditionalRequiredDocumentResponse
>("DeleteAdditionalRequiredDocument", {
  request: "delete_additional_required_document"
});

export const deleteAdditionalRequiredDocumentActions = socketMessageSlice.actions;
export const deleteAdditionalRequiredDocument = socketAction;
export default socketMessageSlice.reducer;
