import Grid from "@material-ui/core/Grid";
import { RootState } from "app/rootReducer";
import {
  getOtherVendorList,
  removeOtherVendorList
} from "components/OtherVendors/listOtheVendorsSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOptionsForSelect } from "utils/models/fields";
import { editRenderSet } from "utils/models/formRenderers";
import { FilterProps, filterStateAccess } from "./types";

export default function OtherVendorFilter<T extends unknown>({
  setFilter,
  path,
  filterType,
  filter,
  label
}: FilterProps<T>) {
  const dispatch = useDispatch();
  const listId = `list-other-vendor-filter`;
  const otherVendorList = useSelector((state: RootState) => state.listOtherVendorSlice[listId]);
  const handleOpen = () => {
    if (!otherVendorList)
      dispatch(
        getOtherVendorList(listId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } }
        })
      );
    return () => {
      dispatch(removeOtherVendorList(listId));
      return;
    };
  };
  const stateAccess = filterStateAccess(filter, setFilter, (values: any) => {
    if (values && values?.length > 0 && values?.every((value: any) => value?._id) !== undefined) {
      return {
        [path.join(".")]: {
          $in: values.map((value: any) => ({ value: value?._id }))
        },
        ...(filterType === "aggregatedColumnFilter" ? { filterType } : {})
      };
    } else {
      setFilter([]);
    }
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {editRenderSet(false).multiSelectInputRenderer(
          {
            formComponent: "multiselect-field",
            name: label?.toLowerCase() ?? "otherVendor",
            label: label ?? "Other Vendor",
            required: false,
            path: [] as [],
            onOpen: handleOpen,
            options: createOptionsForSelect({
              possibleValues: () => otherVendorList?.entities ?? [],
              getOptionLabel: (otherVendor) => otherVendor?.data?.info?.name,
              getSelectedOption: (x, y) => x?._id === y?._id
            }),
            valueToString: (otherVendor) => otherVendor?.data?.info?.name
          },
          stateAccess,
          [],
          stateAccess,
          editRenderSet(false)
        )}
      </Grid>
    </Grid>
  );
}
