import { Button } from "@material-ui/core";
import React from "react";
import { FundingDocument } from "./types";

interface Props {
  setFundingDocumentForPreview: React.Dispatch<React.SetStateAction<FundingDocument | undefined>>;
  fundingDocument: FundingDocument;
}

export default function RecognizeDocumentButton({
  setFundingDocumentForPreview,
  fundingDocument
}: Props) {
  const handleClick = () => setFundingDocumentForPreview(fundingDocument);
  const pages = fundingDocument.data?.uploadedDocumentStatus?.pages;
  const allPages = Object.keys(pages ?? {})?.length || 0;
  const recognizedPages = Object.values(pages ?? {}).filter((x) => x.recognized)?.length || 0;
  return (
    <div style={{ textAlign: "center" }}>
      <Button
        variant="contained"
        style={{
          background: allPages === recognizedPages ? "#254e70" : "#eed202",
          padding: "1px 10px",
          color: allPages === recognizedPages ? "#fff" : "#000"
        }}
        onClick={handleClick}
        fullWidth
      >
        {`${allPages === recognizedPages ? "Show" : "Recognize"} ${recognizedPages}/${allPages}`}
      </Button>
    </div>
  );
}
