import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import request from "./request";
import creditResponse from "./700CreditResponse";
import { CustomRegexes } from "../../helpers";
const data = {
  type: "object",
  properties: {
    request,
    "700CreditResponse": creditResponse,
    type: {
      type: "string",
      enum: ["applicant", "coApplicant", "joint"],
      errorMessage: {
        enum: "must be one of the following: 'applicant', 'coApplicant', 'joint'"
      }
    },
    source: {
      type: "string",
      enum: ["700Credit", "local"],
      errorMessage: {
        enum: "must be one of the following: '700Credit', 'local'"
      }
    },
    dealId: { type: "string" },
    applicantId: { type: "string" },
    coApplicantId: { type: "string", nullable: true },
    dealershipId: { type: "string", nullable: true },
    createdAt: { type: "string", nullable: true }
  }
};

const applicant = {
  type: "object",
  nullable: true,
  properties: {
    address: { type: "string", nullable: true },
    city: { type: "string", nullable: true },
    firstName: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.LETTERS,
      errorMessage: { pattern: "should not contain numbers" }
    },
    lastName: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.LETTERS,
      errorMessage: { pattern: "should not contain numbers" }
    },
    ssn: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.SSN,
      errorMessage: {
        pattern: `
        SSN cannot
        begin with 666 or 900-999,
        be 078-05-1120 or 219-09-9999,
        contain all zeroes in a group(i.e., 000-##-####, ###-00-####, or ###-##-0000)
        or contain all matching values(i.e., 000-00-0000, 111-11-1111, 222-22-2222, etc.)
      `
      }
    },
    state: { type: "string", nullable: true },
    zipCode: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.ZIP_CODE,
      errorMessage: {
        pattern: "should match either of the formats: 'XXXXX', 'XXXXX-XXXX'"
      }
    }
  }
};

export default {
  new_credit_score: {
    $id: "creditScore",
    type: "object",
    required: ["data"],
    properties: {
      data: {
        anyOf: [
          data,
          {
            type: "object",
            nullable: true,
            properties: {
              info: {
                type: "object",
                nullable: true,
                properties: {
                  ...data.properties,
                  applicant: applicant,
                  appModified: { type: "boolean", nullable: true },
                  coApplicant: applicant
                }
              }
            }
          }
        ]
      }
    }
  },
  get_credit_score: {
    $id: "getCreditScore",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" }
    }
  },
  all_credit_scores: {
    $id: "allCreditScores",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties }
    ]
  }
};
