import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { HttpQuery } from "Chat/types/http";
import { Deal } from "components/Deals/types";

import { auth } from "../../firebase/firebase";

export const getApplications = (query?: HttpQuery): (() => Promise<Deal[]>) => {
  const options = {
    pagination: true,
    limit: 100,
    projection: {
      _id: 1,
      createdAt: 1,
      "data.applicant.data.info.firstName": 1,
      "data.applicant.data.info.lastName": 1,
      "data.applicant._id": 1,
      "data.lender._id": 1,
      "data.dealership._id": 1,
      "data.user._id": 1,
      "data.user.data.info.firstName": 1,
      "data.user.data.info.lastName": 1,
      "data.info.vehicle": 1,
      "data.info.type": 1,
      "data.info.payment.interestRate": 1,
      "data.info.payment.term": 1,
      "data.info.payment.numberOfPayments": 1,
      "data.info.profit": 1,
      "data.info.accounting": 1,
      "data.info.dealDates": 1,
      "data.info.refNumber": 1,
      "data.dealership.data.info.displayName": 1,
      "data.dealership.approved": 1,
      "data.lender.data.info.name": 1,
      "data.info.status": 1
    },
    sort: { createdAt: "desc" }
  };

  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${process.env.REACT_APP_HTTP_URL}/api/v1/deal/`, {
      headers: {
        authorization: `Bearer ${token}`,
        ...(query && Object.keys(query)?.length
          ? { query: JSON.stringify({ ...query, options }) }
          : { query: JSON.stringify({ options }) })
      }
    });

    return response.data.result.docs ?? [];
  };
};
const useDealSearch = (query: HttpQuery, enabled = true) => {
  const { data: deals, isLoading: dealsAreLoading, error: dealsError, fetchStatus } = useQuery({
    queryKey: ["deals-global-search", query ?? {}],
    queryFn: getApplications(query),
    enabled: enabled && !!Object.keys(query)?.length
  });

  return {
    data: deals,
    loading: dealsAreLoading,
    isFetching: fetchStatus === "fetching",
    error: dealsError
  };
};
export default useDealSearch;
