import { RootState } from "app/rootReducer";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import RolesFilter from "components/Filters/RolesFilter";
import TextFilter from "components/Filters/TextFilter";
import React from "react";
import { useSelector } from "react-redux";
import AccessControl from "../Access/AccessControl";
import Table, { CellValue, Column } from "../Table";
import ApproveUserDialog from "./ApproveUserDialog";
import { deleteUnapprovedUser } from "./deleteUnapprovedUserSlice";
import { deleteUser } from "./deleteUserSlice";
import { getUnapprovedUserList } from "./listUnapprovedUserSlice";
import { getUserList } from "./listUserSlice";
import { recoverUser } from "./recoverUserSlice";
import { FirebaseUser, FormState } from "./types";
export const projections = {
  "data.info.firstName": 1,
  "data.info.lastName": 1,
  "data.info.email": 1,
  "data.info.userType": 1,
  "data.roles": 1,
  "data.email": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};
export default function Users(): JSX.Element {
  const userList = useSelector((state: RootState) => state.listUserSlice["table"]);
  const unapprovedUserList = useSelector(
    (state: RootState) => state.listUnapprovedUserSlice["table"]
  );

  const columns: Column<FormState>[] = [
    {
      getData: (entry): CellValue =>
        `${entry.data.info.firstName ?? ""} ${entry.data.info.lastName ?? ""}`,
      label: "Name",
      name: "name",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      },
      filters: [
        {
          path: [
            ["data", "info", "firstName"],
            ["data", "info", "lastName"]
          ],
          preview: ApplicantFilter,
          label: "Names",
          name: "names",
          caseInsensitive: true
        }
      ]
    },
    {
      label: "Email",
      getData: (entry): CellValue => entry.data.info.email,
      name: "email",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.email?.show;
      },
      filters: [
        {
          path: ["data", "info", "email"],
          preview: TextFilter,
          label: "Email",
          name: "email"
        }
      ]
    },
    {
      label: "Type",
      name: "userType",
      getData: (entry): CellValue => entry.data.info.userType,
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.userType?.show;
      },
      filters: [
        {
          path: ["data", "info", "userType"],
          preview: MultiSelectFilter,
          label: "User Type",
          name: "userType",
          valuesForSelect: [
            "representative",
            "f&i manager",
            "dealership-member",
            "dealership-admin",
            "lender-user",
            "lender-admin"
          ],
          optionLabelForSelect: (userType) => {
            switch (userType) {
              case "representative":
                return "Representative";
              case "f&i manager":
                return "F&I Manager";
              case "dealership-admin":
                return "Dealership Admin";
              case "dealership-member":
                return "Dealership Member";
              case "lender-admin":
                return "Lender Admin";
              case "lender-user":
                return "Lender Member";
              default:
                return "";
            }
          }
        }
      ]
    },
    {
      label: "Roles",
      getData: (entry): CellValue => entry.data.roles?.map((r) => r.data.info.name).join(", "),
      name: "roles",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.roles?.show;
      },
      filters: [
        {
          path: ["data", "rolesIds"],
          preview: RolesFilter,
          label: "Roles",
          name: "roles"
        }
      ]
    },
    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];
  const columnsForUnapprovedUsers: Column<FirebaseUser>[] = [
    {
      label: "Email",
      getData: (entry): CellValue => entry?.data?.email ?? "",
      name: "email",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.email?.show;
      },
      filters: [
        {
          path: ["data", "email"],
          preview: TextFilter,
          label: "Email",
          name: "email"
        }
      ]
    },
    {
      label: "Actions",
      getData: (entry): CellValue => entry.data.email,
      options: {
        customBodyRender: (value): JSX.Element | string => {
          return (
            <div style={{ maxWidth: "5px" }}>
              <ApproveUserDialog email={value ? value.toString() : ""} />
            </div>
          );
        }
      },
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  const reducedUsers = unapprovedUserList.entities?.reduce(
    (acc: any, item: any) => {
      return { ...acc, entities: [...acc.entities, { data: item, _id: item.uid }] };
    },
    { entities: [], total: 0 }
  );
  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={{ entity: "wfd_user", action: "read" }}>
        <Table
          tableName="users"
          entityName="wfd_user"
          listFunction={getUserList}
          listEntity={userList}
          deleteEntityFunction={deleteUser}
          recoverEntityFunction={recoverUser}
          title={"Users"}
          sort={{ "data.info.firstName": "asc" }}
          columns={columns}
          projection={projections}
        />
        <div style={{ marginTop: "8px" }}>
          <Table
            tableName="unapproved_users"
            entityName="unapproved_user"
            listFunction={getUnapprovedUserList}
            listEntity={reducedUsers}
            hideAddButton={true}
            deleteEntityFunction={deleteUnapprovedUser}
            hideRecoverButton={true}
            hideShowButton={true}
            pagination={false}
            title={"Unapproved Users"}
            columns={columnsForUnapprovedUsers}
            projection={projections}
          />
        </div>
      </AccessControl>
    </div>
  );
}
