import React from "react";

import { generateForm, StateAccess, RenderSet } from "utils/models/formGenerator";
import AccessControl from "components/Access/AccessControl";
import { profitsSegmentAccounting } from "../model";
import { Permission } from "components/Roles/types";
import { EntityType } from "utils/entitySlice";
import { RootState } from "app/rootReducer";
import { useSelector } from "react-redux";

export default function AccountingProfitPreview({
  stateAccess,
  renderSet,
  permissions,
  background
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  permissions?: Permission<EntityType>;
  background?: string;
}) {
  const user = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);
  return (
    <AccessControl requiredPermissions={{ entity: "deal", action: "read" }}>
      {generateForm(
        profitsSegmentAccounting(background),
        stateAccess,
        [],
        stateAccess,
        renderSet,
        permissions,
        undefined,
        user
      ) ?? <></>}
    </AccessControl>
  );
}
