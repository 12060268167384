import { Dealership } from "./types";

export default function (dealership: Dealership, oldDealership: Dealership): Dealership {
  // if (dealership?.data?.info?.name && !dealership.data.info.uniqueDisplayName) {
  //   dealership.data.info.displayName = parseDealershipDisplayName(dealership.data.info.name);
  // }
  if (
    dealership?.data?.info?.agreement?.wfdSplit !==
      oldDealership?.data?.info?.agreement?.wfdSplit &&
    typeof dealership?.data?.info?.agreement?.wfdSplit === "number"
  ) {
    return {
      ...dealership,
      data: {
        ...dealership?.data,
        info: {
          ...dealership?.data?.info,
          ...(dealership?.data?.info?.name && !dealership.data.info.uniqueDisplayName
            ? { displayName: parseDealershipDisplayName(dealership.data.info.name) }
            : {}),

          agreement: {
            ...dealership?.data?.info?.agreement,
            dealershipSplit: 100 - (dealership?.data?.info?.agreement?.wfdSplit || 0)
          }
        }
      }
    };
  }
  if (
    dealership?.data?.info?.agreement?.dealershipSplit !==
      oldDealership?.data?.info?.agreement?.dealershipSplit &&
    typeof dealership?.data?.info?.agreement?.dealershipSplit === "number"
  ) {
    return {
      ...dealership,
      data: {
        ...dealership?.data,
        info: {
          ...dealership?.data?.info,
          ...(dealership?.data?.info?.name && !dealership.data.info.uniqueDisplayName
            ? { displayName: parseDealershipDisplayName(dealership.data.info.name) }
            : {}),
          agreement: {
            ...dealership?.data?.info?.agreement,
            wfdSplit: 100 - (dealership?.data?.info?.agreement?.dealershipSplit || 0)
          }
        }
      }
    };
  }
  return {
    ...dealership,
    data: {
      ...dealership.data,
      info: {
        ...dealership.data.info,
        ...(dealership?.data?.info?.name && !dealership.data.info.uniqueDisplayName
          ? { displayName: parseDealershipDisplayName(dealership.data.info.name) }
          : {})
      }
    }
  };
}
export function parseDealershipDisplayName(legalName: string) {
  if (legalName.split(" dba ")?.[1]) {
    return legalName.split(" dba ")[1]?.trim();
  }
  if (legalName.split(" DBA ")?.[1]) {
    return legalName.split(" DBA ")[1]?.trim();
  }
  return legalName;
}
