import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import { MoreHoriz, Warning } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { RootState } from "app/rootReducer";
import CloseDialogButton from "components/common/CloseDialogButton";
import { HintTooltip } from "components/common/HintTooltip";
import { getDealList } from "components/Deals/listDealSlice";
import { Deal, DealData } from "components/Deals/types";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import TextFilter from "components/Filters/TextFilter";
import UserFilter from "components/Filters/UserFilter";
import { TabContext } from "components/Layout/LayoutWrapper";
import Table, { CellValue, Column } from "components/Table";
import { genTabId, removeTab } from "components/Tabs/tabSlice";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import formatDate from "utils/formatDate";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import { getApplicantList } from "./listApplicantSlice";
import { Applicant } from "./types";
const columns = (handleShowDeal: (id: string) => void): Column<DealData>[] => {
  return [
    {
      getData: (entry): CellValue =>
        `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
          entry.data?.applicant?.data?.info?.lastName ?? ""
        }`,
      label: "Customer",
      name: "customer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.customer?.show;
      },
      filters: [
        {
          path: [
            ["data", "applicant", "data", "info", "firstName"],
            ["data", "applicant", "data", "info", "lastName"]
          ],
          preview: ApplicantFilter,
          caseInsensitive: true,
          name: "customer",
          label: "Customer"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.refNumber,
      label: "Ref #",
      options: {
        sort: true,
        path: ["data", "info", "refNumber"]
      },
      name: "refNumber",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.refNumber?.show;
      },
      filters: [
        {
          path: ["data", "info", "refNumber"],
          preview: TextFilter,
          name: "refNumber",
          label: "Ref Number"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.dealership?.data?.info?.name ?? "",
      label: "Dealership",
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      },
      filters: [
        {
          path: ["data", "dealershipId"],
          preview: DealershipFilter,
          name: "dealership",
          label: "Dealership"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
      label: "Make",
      name: "make",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.make?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "make"],
          preview: TextFilter,
          partialSearch: true,
          caseInsensitive: true,
          name: "make",
          label: "Make"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
      label: "Model",
      name: "model",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.model?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "model"],
          preview: TextFilter,
          partialSearch: true,
          caseInsensitive: true,
          name: "model",
          label: "Model"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.VIN,
      label: "VIN",
      name: "vin",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vin?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "VIN"],
          preview: TextFilter,
          partialSearch: true,
          caseInsensitive: true,
          name: "vin",
          label: "VIN"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
      label: "Lender",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      },
      filters: [
        {
          path: ["data", "lenderId"],
          preview: LenderFilter,
          name: "lender",
          label: "Lender"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.user?.data?.info?.firstName ?? ""} ${
          entry.data?.user?.data?.info?.lastName ?? ""
        }`,
      label: "F&I mgr",
      name: "f&iManager",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.["f&iManager"]?.show;
      },
      filters: [
        {
          path: ["data", "userId"],
          preview: UserFilter,
          type: "f&i manager",
          name: "f&i mgr",
          label: "F&I mgr"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.dealership?.data?.representative?.data?.info?.firstName ?? ""} ${
          entry.data?.dealership?.data?.representative?.data?.info?.lastName ?? ""
        }`,
      label: "Rep",
      name: "rep",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.rep?.show;
      },
      filters: [
        {
          path: ["data", "dealership", "data", "representativeId"],
          preview: UserFilter,
          type: "representative",
          name: "rep",
          label: "Rep"
        }
      ]
    },
    {
      label: "Show",
      getData: (entry): CellValue => entry._id,
      options: {
        customBodyRender: (value, entry): JSX.Element | string => {
          return (
            <IconButton
              size="small"
              color="primary"
              aria-label="show more"
              id={`showMore-${entry._id}`}
              style={{ marginRight: "10px" }}
              onClick={() => handleShowDeal(entry._id)}
            >
              <MoreHoriz />
            </IconButton>
          );
        }
      },
      name: "show",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.show?.show;
      }
    }
  ];
};

export default function SSNPreview({
  applicantType,
  mainStateAccess,
  stateAccess,
  renderSet
}: {
  applicantType: "applicant" | "coApplicant";
  mainStateAccess: StateAccess;
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) {
  const createOrFocusTab = useContext(TabContext);
  const [requestId] = useState(uuidv4());
  const [duplicateDialog, setDuplicateDialog] = useState(false);
  const dispatch = useDispatch();
  const applicantSliceState = useSelector(
    (state: RootState) => state.listApplicantSlice[requestId]
  );
  const applicant: Applicant = stateAccess.get<Applicant>([] as any);
  const ssn = applicant?.data?.info?.socialSecurityNumber;
  const id = applicant?._id;
  const handleCloseDialog = () => {
    setDuplicateDialog(false);
  };
  const handleShowDeal = (id: string) => {
    dispatch(removeTab(genTabId("addPage", { type: "deal" })));
    createOrFocusTab({
      label: "Show page",
      index: "showPage",
      isForSidebar: false,
      isForQuickAccess: false,
      isPersistent: false,
      props: {
        _id: id,
        type: "deal"
      }
    });
    setDuplicateDialog(false);
  };
  const handleApplicantChange = (applicant: Applicant) => {
    setDuplicateDialog(false);
    mainStateAccess.set<Deal>(["data", applicantType], applicant);
  };
  useEffect(() => {
    if (ssn?.length === 9 && !id) {
      dispatch(
        getApplicantList(requestId, {
          query: {
            "data.info.socialSecurityNumber": ssn
          }
        })
      );
    }
  }, [dispatch, requestId, ssn, id]);
  const style = { fontWeight: 700 };
  const dealLists = useSelector((state: RootState) => state.listDealSlice);
  return (
    <>
      <Dialog
        id="duplicate-applicants-dialog"
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={duplicateDialog}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle id="customized-dialog-title">Duplicated applicants</DialogTitle>
        <CloseDialogButton closeFunction={handleCloseDialog} />
        <DialogContent dividers>
          {applicantSliceState?.entities?.map((applicant, index) => {
            return (
              <Accordion defaultExpanded={index === 0} key={`applicant-accordion-${index}`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">{`${applicant?.data?.info?.firstName} ${
                    applicant?.data?.info?.middleName ?? ""
                  } ${applicant?.data?.info?.lastName}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        padding: "10px",
                        marginBottom: "10px",
                        borderRadius: "8px",
                        backgroundColor: "#f1f1f1"
                      }}
                    >
                      <Grid item xs={4}>
                        <Typography style={style} variant="body1">
                          SSN
                        </Typography>
                        <Typography variant="body1">{`${applicant?.data?.info?.socialSecurityNumber} `}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography style={style} variant="body1">
                          Address
                        </Typography>
                        <Typography variant="body1">{`${
                          applicant?.data?.info?.currentAddressNumber ?? ""
                        } ${applicant?.data?.info?.currentAddress}, ${
                          applicant?.data?.info?.currentZipCode ?? ""
                        } ${applicant?.data?.info?.currentState ?? ""} ${
                          applicant?.data?.info?.currentCounty ?? ""
                        } ${applicant?.data?.info?.currentCity ?? ""}`}</Typography>
                      </Grid>
                      {applicant?.data?.info?.email && (
                        <Grid item xs={4}>
                          <Typography style={style} variant="body1">
                            Email
                          </Typography>
                          <Typography variant="body1">{`${applicant.data.info.email}`}</Typography>
                        </Grid>
                      )}
                      {applicant?.data?.info?.mobilePhone && (
                        <Grid item xs={4}>
                          <Typography style={style} variant="body1">
                            Phone
                          </Typography>
                          <Typography variant="body1">{`${applicant.data.info.mobilePhone}`}</Typography>
                        </Grid>
                      )}
                      {applicant?.data?.info?.driverLicenseNumber && (
                        <Grid item xs={4}>
                          <Typography style={style} variant="body1">
                            Driver license
                          </Typography>
                          <Typography variant="body1">{`${applicant.data.info.driverLicenseNumber}`}</Typography>
                        </Grid>
                      )}
                      <Grid item xs={4}>
                        <Typography style={style} variant="body1">
                          Date of birth
                        </Typography>
                        <Typography variant="body1">{`${formatDate(
                          applicant?.data?.info?.birthDate ?? "",
                          "short",
                          false
                        )}`}</Typography>
                      </Grid>
                      <Grid item container xs={12} justifyContent="flex-end">
                        <Button
                          id="select-applicant"
                          onClick={() => handleApplicantChange(applicant)}
                          color="primary"
                          variant="contained"
                          size="small"
                        >
                          Select applicant
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Table
                        tableName={"ssn_preview"}
                        entityName="deal"
                        listFunction={getDealList}
                        listEntity={dealLists[`table-deals-applicant-${applicant?._id}`]}
                        query={{ [`data.${applicantType}Id`]: applicant?._id }}
                        sort={{ createdAt: "desc" }}
                        title={"Deals"}
                        parentId={applicant?._id}
                        slice={`table-deals-applicant-${applicant?._id}`}
                        columns={columns(handleShowDeal)}
                        hideDeleteButton={true}
                        hideAddButton={true}
                        hideRecoverButton={true}
                        dateFilters={[
                          { label: "Created At", path: ["createdAt"] },
                          { label: "Updated At", path: ["updatedAt"] },
                          { label: "Funded At", path: ["data", "info", "dealDates", "fundedAt"] }
                        ]}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Box display="flex" style={{ position: "relative" }}>
        <Box flexGrow={1}>
          {renderSet.SSNFieldRenderer<Applicant>(
            {
              name: "socialSecurityNumber",
              label: "Social security number",
              required: (stateAccess) =>
                !stateAccess.get(["data", "info", "warrantyOrPreApprovalOnly"]),
              width: "full",
              path: ["data", "info", "socialSecurityNumber"],
              default: null,
              type: "text"
            },
            stateAccess,
            renderSet
          )}
        </Box>
        {(applicantSliceState?.entities?.length || 0) > 0 &&
        applicant?.data?.info?.socialSecurityNumber?.length === 9 ? (
          <HintTooltip title="There is applicant with the same information in the system!">
            <IconButton id="duplicate-applicant-button" onClick={() => setDuplicateDialog(true)}>
              <Warning style={{ color: "#eed202" }} />
            </IconButton>
          </HintTooltip>
        ) : null}
      </Box>
    </>
  );
}
