import { createListSlice } from "../Middlewares/listSliceCreator";
import { DocumentTemplate } from "./types";
import { getListProps } from "components/Middlewares/listSliceCreator";
const { ListSlice, getList, getListAction } = createListSlice<DocumentTemplate>({
  name: "DocumentTemplateList",
  request_topic: "all_document_templates",
  reducers: {}
});
export default ListSlice.reducer;
export const getDocumentTemplateList = getList;

export const removeDocumentTemplateList = ListSlice.actions.removeList;
export const listDocumentTemplateActions = ListSlice.actions;
export const getDocumentTemplateListActions = getListAction;
