import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { LenderTicketType, AddLenderTicketTypeRequest } from "./types";

interface AddLenderTicketTypeResponse {
  message: LenderTicketType;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddLenderTicketTypeRequest,
  AddLenderTicketTypeResponse
>("AddLenderTicketTypeResponse", {
  request: "new_lender_ticket_type"
});

export const addLenderTicketTypeActions = socketMessageSlice.actions;

export const addLenderTicketType = socketAction;

export default socketMessageSlice.reducer;
