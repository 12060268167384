import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { auth } from "../../firebase/firebase";
import { HttpQuery } from "Chat/types/http";

import { MutationError } from "Chat/types";
import {
  PersonaVerification,
  PersonaVerificationInternalRequest
} from "components/Deals/FraudValidation/types/internal/personaVerifications";
import { PersonaVerificationResponse } from "components/Deals/FraudValidation/types/external/verifications/http";

export const getPersonaVerifications = (
  query?: HttpQuery
): (() => Promise<PersonaVerification[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/persona_verification`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          ...(query && Object.keys(query)?.length ? { query: JSON.stringify(query) } : {})
        }
      }
    );

    return response.data.result?.docs ?? [];
  };
};

export const performPersonaVerifications = (): ((
  request: PersonaVerificationInternalRequest
) => Promise<PersonaVerificationResponse>) => {
  return async (request) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/persona_verification/`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return response.data.result;
  };
};
const useGetPersonaVerifications = (applicantId: string, query?: HttpQuery, enabled = true) => {
  const queryKey = [`persona-verifications-${applicantId}`, query ?? []];

  const { data, isLoading, isFetching, error } = useQuery<
    PersonaVerification[],
    MutationError,
    PersonaVerification[]
  >({
    queryKey: queryKey,
    queryFn: getPersonaVerifications(query),
    enabled: applicantId ? enabled : false
  });

  return {
    data,
    isLoading,
    isFetching,
    error
  };
};

export const usePerformPersonaVerifications = () => {
  const mutation = useMutation<
    PersonaVerificationResponse,
    MutationError,
    PersonaVerificationInternalRequest
  >(performPersonaVerifications(), {
    onSuccess: () => {
      return;
    }
  });
  return {
    performPersonaVerifications: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};
export default useGetPersonaVerifications;
