import React, { useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { StateAccess } from "utils/models/formGenerator";
import { Product } from "./types";
export default function ({
  state,
  stateAccess,
  setRateState
}: {
  state: Product[];
  stateAccess: StateAccess;
  setRateState: React.Dispatch<any>;
}) {
  const [value, setValue] = useState("");

  const handleChange = (value: string, provider: string) => {
    setValue(value);
    stateAccess.set(["Product"], JSON.parse(value));
    setRateState((state: any) => {
      return { ...state, provider };
    });
  };

  const reducedProducts = state.reduce((acc: { [key: string]: Product[] }, curr) => {
    if (curr.Approved === "false") return acc;
    return {
      ...acc,
      [curr.ProviderName]: [...(acc[curr.ProviderName] ?? []), curr]
    };
  }, {});

  return (
    <div style={{ margin: "10px" }}>
      {Object.keys(reducedProducts).length > 0
        ? Object.entries(reducedProducts).map((el, index) => (
            <div key={index}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{el[0]}</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange((event.target as HTMLInputElement).value, el[0])
                  }
                  row
                >
                  {el[1].map((x, key) => (
                    <FormControlLabel
                      key={key}
                      value={JSON.stringify(x)}
                      control={<Radio />}
                      label={x.ProductName}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          ))
        : "No available products!"}
    </div>
  );
}
