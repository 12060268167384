import React from "react";
import { StateAccess, RenderSet } from "utils/models/formGenerator";
import { createOptionsForSelect } from "utils/models/fields";
import Grid from "@material-ui/core/Grid";

import LendersPreview from "components/DocumentTemplates/LendersPreview";
import DealershipPreview from "components/Dealerships/MultiDealershipPreview";
import OtherVendorPreview from "components/OtherVendors/OtherVendorPreview";
import { Contract } from "./types";

export default function VendorPreview({
  stateAccess,
  renderSet,
  required,
  label,
  name
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  required: boolean;
  label: string;
  name: string;
}) {
  const selectedVendorTypes: Contract["data"]["vendorTypes"] = stateAccess.get<Contract>([
    "data",
    "vendorTypes"
  ]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={3} key={`vendor_type_multiselect`}>
          {renderSet.multiSelectInputRenderer(
            {
              formComponent: "multiselect-field",
              name,
              label,
              required,
              path: ["data", "vendorTypes"],
              options: createOptionsForSelect({
                possibleValues: () => [
                  "Lenders",
                  "Dealerships",
                  "Products",
                  "External Providers",
                  "Other vendors"
                ],
                getOptionLabel: (el) => el,
                getSelectedOption: (x, y) => x === y
              })
            },
            stateAccess,
            [],
            stateAccess,
            renderSet
          )}
        </Grid>
        {selectedVendorTypes?.map((vendor) => {
          switch (vendor) {
            case "Dealerships":
              return (
                <Grid item xs={3} key={`dealerships_multiselect`}>
                  <DealershipPreview
                    stateAccess={stateAccess}
                    renderSet={renderSet}
                    path={["data", "dealerships"]}
                    name="Dealership"
                    label="Dealership"
                  />
                </Grid>
              );
            case "Lenders":
              return (
                <Grid item xs={3} key={`lenders_multiselect`}>
                  <LendersPreview
                    stateAccess={stateAccess}
                    renderSet={renderSet}
                    path={["data", "lenders"]}
                    name="lender"
                    label="Lender"
                  />
                </Grid>
              );
            case "Other vendors":
              return (
                <Grid item xs={3} key={`other_vendors_multiselect`}>
                  <OtherVendorPreview
                    stateAccess={stateAccess}
                    renderSet={renderSet}
                    path={["data", "otherVendors"]}
                    name="otherVendor"
                    label="Other vendor"
                  />
                </Grid>
              );
            case "Products":
              return (
                <Grid item xs={3} key={`products_multiselect`}>
                  {renderSet.multiSelectInputRenderer(
                    {
                      formComponent: "multiselect-field",
                      name,
                      label: vendor,
                      required,
                      path: ["data", "products"],
                      options: createOptionsForSelect({
                        possibleValues: () => ["F&I Express", "Protective", "ASC"],
                        getOptionLabel: (el) => el,
                        getSelectedOption: (x, y) => x === y
                      })
                    },
                    stateAccess,
                    [],
                    stateAccess,
                    renderSet
                  )}
                </Grid>
              );
            case "External Providers":
              return (
                <Grid item xs={3} key={`external_providers_multiselect`}>
                  {renderSet.multiSelectInputRenderer(
                    {
                      formComponent: "multiselect-field",
                      name,
                      label: vendor,
                      required,
                      path: ["data", "externalProviders"],
                      options: createOptionsForSelect({
                        possibleValues: () => ["Dealertrack", "Appone"],

                        getOptionLabel: (el) => el,
                        getSelectedOption: (x, y) => x === y
                      })
                    },
                    stateAccess,
                    [],
                    stateAccess,
                    renderSet
                  )}
                </Grid>
              );
            default:
              return <></>;
          }
        })}
      </Grid>
    </>
  );
}
