import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteLenderDecisionRequest {
  _id: string;
}

export interface DeleteLenderDecisionResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteLenderDecisionRequest,
  DeleteLenderDecisionResponse
>("DeleteLenderDecision", {
  request: "delete_lender_decision"
});

export const deleteLenderDecisionActions = socketMessageSlice.actions;
export const deleteLenderDecision = socketAction;
export default socketMessageSlice.reducer;
