import React, { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import WarningIcon from "@material-ui/icons/Warning";
import AddIcon from "@material-ui/icons/Add";
import { LenderDescision } from "./types";
import TableDealPreview from "./TableDealPreview";
export default function ({ lenderDecision }: { lenderDecision: LenderDescision }) {
  const dealRef = lenderDecision?.data?.refNumber;
  const [tableDialog, setTableDialog] = useState(false);
  const [tableWarningDialog, setTableWarningDialog] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <IconButton
        onClick={() => {
          setTableDialog(true);
        }}
        size={"small"}
        color="primary"
      >
        <Tooltip title={dealRef ? "Edit deal" : "Add deal"}>
          {dealRef ? <BorderColorIcon /> : <AddIcon />}
        </Tooltip>
      </IconButton>
      {lenderDecision.data.possibleDeals && lenderDecision.data.possibleDeals.length > 1 && (
        <IconButton
          onClick={() => {
            setTableWarningDialog(true);
          }}
          size={"small"}
          color="secondary"
        >
          <Tooltip
            title={`The system has founded ${lenderDecision.data.possibleDeals.length} deals for this decision`}
          >
            <WarningIcon />
          </Tooltip>
        </IconButton>
      )}
      {tableDialog && (
        <TableDealPreview lenderDecision={lenderDecision} setTableDialog={setTableDialog} />
      )}
      {tableWarningDialog && (
        <TableDealPreview
          onlyRefNumbers={(lenderDecision.data.possibleDeals ?? [])?.map((x) => x.refNumber)}
          lenderDecision={lenderDecision}
          setTableDialog={setTableWarningDialog}
        />
      )}
    </div>
  );
}
