import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface VinCheckRequest {
  data: {
    info: Info;
  };
}

interface Info {
  vinNumber: string;
}

interface VinCheckResponse {
  status: "success" | "error";
  message: {
    REPORTS: {
      REPORT: {
        VINPOWER: {
          VIN: {
            number: string;
            DECODED: {
              Make: string;
              Model: string;
              Model_Year: string;
            };
          };
        };
      };
    };
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  VinCheckRequest,
  VinCheckResponse
>("VinCheck", {
  request: "vin_check"
});

export const vinCheckActions = socketMessageSlice.actions;
export const vinCheck = socketAction;
export default socketMessageSlice.reducer;
