import {
  createSocketMessageSlice,
  Identifiable
} from "../../Middlewares/socketMessageSliceCreator";

interface ListProductsRequest {
  requestId: string;
  data: {
    params: {
      EX1DealerProductRequest: {
        EX1DealerID: string;
      };
    };
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<ListProductsRequest, any>(
  "ListProducts",
  {
    request: "express_ex1_dealer_product"
  }
);

export const listProducts = ({ requestId }: Identifiable) => {
  return socketAction({
    requestId,
    data: {
      params: {
        EX1DealerProductRequest: {
          EX1DealerID: process.env.REACT_APP_EX1DealerID as string
        }
      }
    }
  });
};

export const listProductsActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
