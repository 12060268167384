import { RootState } from "app/rootReducer";
import { getChargebackList } from "components/Chargebacks/listChargebackSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateAccess, getByPath, generateForm } from "utils/models/formGenerator";
import { showRenderSet } from "utils/models/formRenderers";
import { listchargebackAmounts } from "components/Chargebacks/model";

export default function PreviewChargebacks({ dealId }: { dealId: string }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);
  const chargebacksList = useSelector(
    (state: RootState) => state.listChargebackSlice[`deal-${dealId}`]
  );

  const chargebacks =
    chargebacksList?.entities?.filter((x) => x.data.managerId === currentUser?._id) ?? [];

  const stateAccess: StateAccess = {
    get: (path) => getByPath(chargebacks, path),
    set: (path, value): any => {}
  };

  useEffect(() => {
    dispatch(
      getChargebackList(`deal-${dealId}`, {
        query: {
          "data.dealId": dealId
        }
      })
    );
  }, [dealId]);

  return (
    <>
      {chargebacks?.length
        ? generateForm(listchargebackAmounts, stateAccess, [], stateAccess, showRenderSet(false))
        : null}
    </>
  );
}
