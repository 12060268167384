import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { RootState } from "app/rootReducer";
import { addColumnGroup } from "components/GroupedTable/ColumnGroups/addColumnGroupSlice";

import { ColumnGroup, TableGroupByItem } from "components/GroupedTable/ColumnGroups/types";
import CloseDialogButton from "components/common/CloseDialogButton";
import React from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import RemoveIcon from "@material-ui/icons/Remove";
import { Column, ColumnAggregation, ColumnGrouping, TableAggregation } from ".";
import { deleteColumnGroup } from "./ColumnGroups/deleteColumnGroupSlice";
import { editColumnGroup } from "./ColumnGroups/editColumnGroupSlice";
import { capitalize, formatNumberAsCurrency, totalsDefault } from "utils/functions";
import ShareIcon from "@material-ui/icons/Share";
import { uuid } from "uuidv4";
import { getUserList, removeUserList } from "components/Users/listUserSlice";
import { User } from "components/Users/types";
import { exportColumnGroup } from "./ColumnGroups/exportColumnGroupSlice";
import { hideLoader, showLoader } from "components/Loader/loaderSlice";
import { useSnackbar } from "notistack";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { filterColumnsByParent, getParents } from "./TableSettings/TableSettings";
import { HintTooltip } from "components/common/HintTooltip";

type GroupingProps<T extends unknown> = {
  groupName: string;
  groupIsDefault: boolean;
  setGroupName: React.Dispatch<React.SetStateAction<string>>;
  setGroupIsDefault: React.Dispatch<React.SetStateAction<boolean>>;
  setGroupColumnsAutocompleteState: React.Dispatch<React.SetStateAction<Column<T>[]>>;
  setSelectedColumnGrouping: React.Dispatch<React.SetStateAction<ColumnGroup | null>>;
  selectedColumnGrouping: ColumnGroup | null;
  groupColumnsAutocompleteState: Column<T>[];
  aggregationColumns: Column<T>[];
  setAggregationColumns: React.Dispatch<React.SetStateAction<Column<T>[]>>;
  defaultColumns: Column<T>[];
  columnGroupsList: ColumnGroup[] | undefined;
  clearFunction: () => void;
  groupingType: string;
  requestId: string;
  columnGroupRequestId: string;
  groupColumns: Column<T>[];
  compareMode: boolean;
  setGroupColumns: React.Dispatch<React.SetStateAction<Column<T>[]>>;
  setCompareMode: React.Dispatch<React.SetStateAction<boolean>>;
  setTableGroupBy: React.Dispatch<React.SetStateAction<TableGroupByItem[]>>;
  setTableAggregation: React.Dispatch<React.SetStateAction<TableAggregation[]>>;
  setColumns: React.Dispatch<React.SetStateAction<Column<T>[]>>;
};

type Option = {
  value: string;
  label: string;
};
const aggregationFunctions: Option[] = [
  {
    value: "first",
    label: "First"
  },
  {
    value: "last",
    label: "Last"
  },
  {
    value: "sum",
    label: "Total/Count"
  },
  {
    value: "avg",
    label: "Average"
  },
  {
    value: "list",
    label: "List"
  },
  {
    value: "list-count",
    label: "List Count"
  },
  {
    value: "list-count-unique",
    label: "List Count Unique"
  }
];

const dateOptions: Option[] = [
  {
    value: "%Y-%m",
    label: "YR-Month"
  },
  {
    value: "%Y",
    label: "YR"
  },
  {
    value: "qtr",
    label: "QTR"
  },
  {
    value: "yr-month-wk",
    label: "YR-Month-WK"
  },
  {
    value: "half",
    label: "HALF"
  }
];
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

export default <T extends unknown>({
  columnGroupsList,
  groupName,
  groupIsDefault,
  setGroupName,
  setGroupIsDefault,
  setGroupColumnsAutocompleteState,
  setSelectedColumnGrouping,
  selectedColumnGrouping,
  groupColumnsAutocompleteState,
  aggregationColumns,
  setAggregationColumns,
  defaultColumns,
  groupColumns,
  compareMode,
  groupingType,
  setCompareMode,
  setGroupColumns,
  clearFunction,
  requestId,
  columnGroupRequestId,
  setTableGroupBy,
  setTableAggregation,
  setColumns
}: GroupingProps<T>) => {
  const [tabValue, setTabValue] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = React.useState(false);
  const [exportImportDialogOpen, setExportImportDialogOpen] = React.useState(false);

  const addColumnGroupStatus = useSelector(
    (state: RootState) => state.addColumnGroupSlice[columnGroupRequestId]
  );
  const [columnGroupType, setColumnGroupType] = React.useState<"own" | "general">("own");

  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);

  const ownColumnGroups = (columnGroupsList ?? [])?.filter(
    (columnGroup) =>
      currentUser?._id === columnGroup.data.userId && !columnGroup.data.info.isSnapshot
  );
  const generalColumnGroups = (columnGroupsList ?? [])?.filter(
    (columnGroup) => !columnGroup.data.userId && !columnGroup.data.info.isSnapshot
  );

  const handleGroup = (groupColumns: Column<any>[], aggregationColumns: Column<any>[]) => {
    // clearFunction();
    setTableGroupBy(
      groupColumns.map((column) => ({
        key: (column?.grouping as TableGroupByItem)?.key,
        path: (column?.grouping as TableGroupByItem)?.path,
        transformDate: (column?.grouping as TableGroupByItem)?.transformDate
      }))
    );
    setTableAggregation(
      aggregationColumns
        .map((c) => {
          return c.columnAggregation && c.columnAggregation?.aggregated
            ? { columnLabel: c.name, columnAggregation: c.columnAggregation }
            : null;
        })
        .filter((x) => x) as TableAggregation[]
    );
    setColumns([...groupColumns, ...aggregationColumns]);
  };

  const handleSaveCurrentGrouping = () => {
    const newColumnGroup = {
      data: {
        ...(columnGroupType === "own" ? { userId: currentUser?._id as string } : {}),
        info: {
          type: groupingType,
          isSnapshot: false,
          grouping: groupColumns.map((column) => ({
            key: (column?.grouping as TableGroupByItem)?.key,
            path: (column?.grouping as TableGroupByItem)?.path,
            transformDate: (column?.grouping as TableGroupByItem)?.transformDate
          })),
          isDefault: groupIsDefault,
          groupColumns: groupColumns.map((c) => c.name),
          aggregationColumns: aggregationColumns.map((c) => ({
            columnName: c.name,
            ...(c?.customLabel ? { customLabel: c?.customLabel } : {}),
            ...(typeof c?.decimalPlaces === "number" ? { decimalPlaces: c?.decimalPlaces } : {}),
            funcName: c.columnAggregation?.funcName,
            transformDate: c.columnAggregation?.transformDate
          })),
          name: groupName
        }
      }
    } as ColumnGroup;
    dispatch(
      addColumnGroup({
        requestId: columnGroupRequestId,
        ...newColumnGroup
      })
    );
    setSaveDialogOpen(false);
  };

  React.useEffect(() => {
    switch (addColumnGroupStatus?.status) {
      case "success":
        const foundUpdatedInList = ownColumnGroups?.find(
          (columnGroup) => columnGroup?.data?.info?.name === groupName
        );
        if (foundUpdatedInList) {
          setSelectedColumnGrouping(foundUpdatedInList);
        }
        setGroupName("");
        break;
    }
  }, [addColumnGroupStatus?.status]);

  const isNameTaken = (ownColumnGroups ?? [])
    .filter((x) => !x.deleted)
    .some((columnGroup) => columnGroup.data.info.name === groupName?.trim());

  return (
    <React.Fragment>
      <HintTooltip title={"Grouping"}>
        <IconButton onClick={() => setOpen(!open)}>
          <ViewWeekIcon style={{ color: "#0000008a" }} />
        </IconButton>
      </HintTooltip>
      <Dialog
        disableEnforceFocus
        maxWidth="md"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Table Grouping</DialogTitle>
        <CloseDialogButton closeFunction={() => setOpen(false)} />
        <AppBar position="static">
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => {
              switch (newValue) {
                case 0:
                  setColumnGroupType("general");
                  setSelectedColumnGrouping(null);
                  break;
                case 1:
                  setColumnGroupType("own");
                  setSelectedColumnGrouping(null);
                  break;
                default:
                  break;
              }
              setTabValue(newValue);
            }}
            aria-label="groups tabs"
          >
            <Tab label="General groups" {...a11yProps(0)} />
            <Tab label="My groups" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={tabValue} index={0}>
          <GroupingDialogContentAndActions
            saveGroupingCallback={handleSaveCurrentGrouping}
            type="general"
            groupingType={groupingType}
            requestId={requestId}
            columnGroupRequestId={columnGroupRequestId}
            defaultColumns={defaultColumns}
            groupIsDefault={groupIsDefault}
            clearFunction={clearFunction}
            groupColumns={groupColumns}
            aggregationColumns={aggregationColumns}
            currentUser={currentUser}
            columnGroups={generalColumnGroups}
            setAggregationColumns={setAggregationColumns}
            compareMode={compareMode}
            setCompareMode={setCompareMode}
            setColumns={setColumns}
            setExportImportDialogOpen={setExportImportDialogOpen}
            setGroupColumnsAutocompleteState={setGroupColumnsAutocompleteState}
            setGroupColumns={setGroupColumns}
            handleGroup={handleGroup}
            setGroupIsDefault={setGroupIsDefault}
            selectedColumnGrouping={selectedColumnGrouping}
            setOpen={setOpen}
            setGroupName={setGroupName}
            setSaveDialogOpen={setSaveDialogOpen}
            setSelectedColumnGrouping={setSelectedColumnGrouping}
            setTableAggregation={setTableAggregation}
            setTableGroupBy={setTableGroupBy}
            groupColumnsAutocompleteState={groupColumnsAutocompleteState}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <GroupingDialogContentAndActions
            groupingType={groupingType}
            type="own"
            requestId={requestId}
            columnGroupRequestId={columnGroupRequestId}
            defaultColumns={defaultColumns}
            groupIsDefault={groupIsDefault}
            clearFunction={clearFunction}
            groupColumns={groupColumns}
            aggregationColumns={aggregationColumns}
            currentUser={currentUser}
            columnGroups={ownColumnGroups}
            setAggregationColumns={setAggregationColumns}
            compareMode={compareMode}
            setCompareMode={setCompareMode}
            setColumns={setColumns}
            setExportImportDialogOpen={setExportImportDialogOpen}
            setGroupColumnsAutocompleteState={setGroupColumnsAutocompleteState}
            setGroupColumns={setGroupColumns}
            handleGroup={handleGroup}
            setGroupIsDefault={setGroupIsDefault}
            selectedColumnGrouping={selectedColumnGrouping}
            setOpen={setOpen}
            setGroupName={setGroupName}
            setSaveDialogOpen={setSaveDialogOpen}
            setSelectedColumnGrouping={setSelectedColumnGrouping}
            setTableAggregation={setTableAggregation}
            setTableGroupBy={setTableGroupBy}
            groupColumnsAutocompleteState={groupColumnsAutocompleteState}
            saveGroupingCallback={handleSaveCurrentGrouping}
          />
        </TabPanel>
      </Dialog>
      {saveDialogOpen && (
        <SaveDialog
          isNameTaken={isNameTaken}
          saveGroupingCallback={handleSaveCurrentGrouping}
          groupName={groupName}
          setGroupName={setGroupName}
          closeFunction={() => setSaveDialogOpen(false)}
        />
      )}
      {exportImportDialogOpen && selectedColumnGrouping && (
        <ExportImportDialog
          columnGroupRequestId={columnGroupRequestId}
          groupingType={groupingType}
          currentUser={currentUser}
          columnGroup={
            {
              data: {
                info: {
                  ...selectedColumnGrouping?.data?.info,
                  isSnapshot: true
                }
              }
            } as ColumnGroup
          }
          closeFunction={() => setExportImportDialogOpen(false)}
        />
      )}
    </React.Fragment>
  );
};

type ExportImportDialogProps = {
  closeFunction: () => void;
  groupingType: string;
  columnGroupRequestId: string;
  currentUser: User | undefined;
  columnGroup: ColumnGroup;
};
type SaveDialogProps = {
  closeFunction: () => void;
  isNameTaken: boolean;
  setGroupName: React.Dispatch<React.SetStateAction<string>>;
  groupName: string;
  saveGroupingCallback: () => void;
};
type GroupingDialogContentAndActionsProps<T> = {
  saveGroupingCallback: () => void;
  columnGroupRequestId: string;
  requestId: string;
  type: "own" | "general";
  selectedColumnGrouping: ColumnGroup | null;
  defaultColumns: Column<T>[];
  groupIsDefault: boolean;
  groupingType: string;
  groupColumns: Column<T>[];
  aggregationColumns: Column<T>[];
  setTableGroupBy: React.Dispatch<React.SetStateAction<TableGroupByItem[]>>;
  setTableAggregation: React.Dispatch<React.SetStateAction<TableAggregation[]>>;
  setColumns: React.Dispatch<React.SetStateAction<Column<T>[]>>;
  compareMode: boolean;
  setCompareMode: React.Dispatch<React.SetStateAction<boolean>>;
  setExportImportDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setGroupColumns: React.Dispatch<React.SetStateAction<Column<T>[]>>;
  setGroupColumnsAutocompleteState: React.Dispatch<React.SetStateAction<Column<T>[]>>;
  groupColumnsAutocompleteState: Column<T>[];
  setAggregationColumns: React.Dispatch<React.SetStateAction<Column<T>[]>>;
  setGroupName: React.Dispatch<React.SetStateAction<string>>;
  setGroupIsDefault: React.Dispatch<React.SetStateAction<boolean>>;
  setSaveDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedColumnGrouping: React.Dispatch<React.SetStateAction<ColumnGroup | null>>;
  columnGroups: ColumnGroup[];
  handleGroup: (groupColumns: Column<T>[], aggregationColumns: Column<T>[]) => void;
  clearFunction: () => void;
  currentUser: User | undefined;
};
const GroupingDialogContentAndActions = <T extends any>({
  type,
  currentUser,
  requestId,
  columnGroupRequestId,
  handleGroup,
  setTableGroupBy,
  clearFunction,
  setSaveDialogOpen,
  compareMode,
  setCompareMode,
  setColumns,
  setTableAggregation,
  selectedColumnGrouping,
  aggregationColumns,
  setExportImportDialogOpen,
  setGroupColumns,
  setGroupColumnsAutocompleteState,
  groupColumnsAutocompleteState,
  setAggregationColumns,
  setOpen,
  setGroupName,
  setGroupIsDefault,
  setSelectedColumnGrouping,
  columnGroups,
  defaultColumns,
  groupIsDefault,
  groupingType,
  saveGroupingCallback,
  groupColumns
}: GroupingDialogContentAndActionsProps<T>) => {
  const dispatch = useDispatch();
  const isAdmin = currentUser?.data?.rolesIds?.includes("5f17e21d46a2b90e45bb5a90");
  const isReadOnlyField = !isAdmin && type === "general";

  const handleReset = () => {
    clearFunction();
    setSelectedColumnGrouping(null);
    setTableGroupBy([]);
    setColumns(defaultColumns);
    setGroupColumns([]);
    setGroupName("");
    setGroupIsDefault(false);
    setTableAggregation([]);
    setGroupColumnsAutocompleteState([]);
    setAggregationColumns([({} as unknown) as Column<any>]);
  };
  const handleExportCurrentGroupingToOwn = () => {
    const ownColumnGroup = {
      data: {
        userId: currentUser?._id as string,
        info: {
          ...selectedColumnGrouping?.data?.info
        }
      }
    } as ColumnGroup;
    dispatch(
      addColumnGroup({
        requestId: columnGroupRequestId,
        ...ownColumnGroup
      })
    );
    setSaveDialogOpen(false);
  };
  const handleEditSelectedGrouping = () => {
    const editedColumnGrouping = {
      ...selectedColumnGrouping,
      data: {
        userId: currentUser?._id as string,
        info: {
          ...(selectedColumnGrouping?.data?.info ?? {}),
          type: groupingType,
          grouping: groupColumns.map((column) => ({
            key: (column?.grouping as TableGroupByItem)?.key,
            path: (column?.grouping as TableGroupByItem)?.path,
            transformDate: (column?.grouping as TableGroupByItem)?.transformDate
          })),
          name: selectedColumnGrouping?.data?.info?.name,
          isDefault: groupIsDefault ?? selectedColumnGrouping?.data?.info?.isDefault,
          groupColumns: groupColumns.map((c) => c.name),
          aggregationColumns: aggregationColumns.map((c) => ({
            ...(c?.customLabel ? { customLabel: c?.customLabel } : {}),
            ...(typeof c?.decimalPlaces === "number" ? { decimalPlaces: c?.decimalPlaces } : {}),
            ...(c?.columnAggregation?.totalType
              ? { totalType: c?.columnAggregation?.totalType }
              : {}),
            columnName: c.name,
            funcName: c.columnAggregation?.funcName,
            transformDate: c.columnAggregation?.transformDate
          }))
        }
      }
    } as ColumnGroup;
    dispatch(
      editColumnGroup({
        requestId: columnGroupRequestId,
        ...editedColumnGrouping
      })
    );
    setSelectedColumnGrouping(editedColumnGrouping);
  };
  const handleDeleteColumnGroup = (id: string) => {
    dispatch(deleteColumnGroup({ requestId: columnGroupRequestId, _id: id }));
    handleReset();
  };

  const handleChangeDateFormat = (column: Column<any>, dateFormat: string | undefined) => {
    const newColumns = groupColumns.map((c) => {
      if (c.name === column.name) {
        if (dateFormat) {
          return {
            ...c,
            grouping: {
              ...(c?.grouping ?? ({} as ColumnGrouping<any>)),
              transformDate: dateFormat
            }
          };
        } else {
          const { transformDate, ...grouping } = c?.grouping ?? ({} as ColumnGrouping<any>);
          return {
            ...c,
            grouping
          };
        }
      }
      return c;
    });
    setGroupColumnsAutocompleteState(newColumns);
    setGroupColumns(newColumns);
  };
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newOptions = groupColumnsAutocompleteState ?? [];
    const [reorderedItem] = newOptions.splice(result.source.index, 1);
    newOptions.splice(result.destination.index, 0, reorderedItem);

    setGroupColumnsAutocompleteState(newOptions);
    setGroupColumns(
      newOptions.map((c) => ({
        ...c,
        grouping: {
          ...(c?.grouping ?? ({} as ColumnGrouping<any>)),
          grouped: true
        }
      }))
    );
  };

  const onDragEndColumnAggregations = (result: DropResult) => {
    if (!result.destination) return;
    const newOptions = aggregationColumns ?? [];
    const [reorderedItem] = newOptions.splice(result.source.index, 1);
    newOptions.splice(result.destination.index, 0, reorderedItem);
    setAggregationColumns(Array.from([...newOptions]));
  };
  const handleChangeAggregationColumnFunction = (
    c: Column<any> | null,
    funcName: string | undefined,
    index: number
  ) => {
    const filtersForSelectedFunction = c?.columnAggregation?.filters?.filter((f) =>
      f.applyOnFunctions.includes(funcName ?? "")
    );
    const aggregationPath = funcName ? `${c?.name}-${funcName}` : c?.name;
    const newLabel = `${
      /(last|first|sum|avg|list|list-count|list-count-unique)$/.test(c?.label ?? "")
        ? c?.label?.split(" ")?.slice(0, -1)?.join(" ")
        : c?.label
    }${funcName ? ` ${funcName}` : ""}`;
    const newColumns = [...aggregationColumns];
    newColumns.splice(index, 1, {
      ...c,
      ...(aggregationPath
        ? {
            getData: (entry) => {
              if (
                c?.type === "number" ||
                ["avg", "sum", "list-count", "list-count-unique"].includes(funcName as string)
              ) {
                if (c?.columnAggregation?.dataModifier) {
                  return (
                    formatNumberAsCurrency(
                      (c?.columnAggregation?.dataModifier(
                        entry?.data?.[aggregationPath],
                        funcName
                      ) as unknown) as number | undefined | null,
                      c?.sign?.(funcName),
                      typeof c?.decimalPlaces === "number" ? c.decimalPlaces : undefined
                    ) ??
                    c?.default ??
                    "N/A"
                  );
                }
                return (
                  formatNumberAsCurrency(
                    entry?.data?.[aggregationPath],
                    c?.sign?.(funcName),
                    typeof c?.decimalPlaces === "number" ? c.decimalPlaces : undefined
                  ) ??
                  c?.default ??
                  "N/A"
                );
              } else {
                if (c?.columnAggregation?.dataModifier) {
                  return c?.columnAggregation?.dataModifier(
                    entry?.data?.[aggregationPath] ?? c?.default ?? "N/A",
                    funcName
                  );
                }
                return entry?.data?.[aggregationPath] ?? c?.default ?? "N/A";
              }
            }
          }
        : {}),
      getIds: (entry) => entry?.data?.[`${aggregationPath}-ids`],
      label: c?.customLabel ? c.customLabel : newLabel,
      columnAggregation: {
        ...(c?.columnAggregation as ColumnAggregation),
        funcName: funcName
      },
      ...(aggregationPath
        ? {
            options: {
              path: [aggregationPath],
              sort: true
            }
          }
        : {}),
      ...(aggregationPath
        ? {
            filters:
              funcName === "list"
                ? []
                : filtersForSelectedFunction?.length
                ? filtersForSelectedFunction.map((filter) => ({
                    ...filter,
                    filterType: "aggregatedColumnFilter",
                    path: [aggregationPath],
                    name: c?.name,
                    label: c?.label
                  }))
                : c?.filters
          }
        : {})
    } as Column<any>);
    setAggregationColumns(newColumns);
  };
  const handleChangeAggregationColumnTransformDate = (
    c: Column<any> | null,
    transformDate: string | undefined,
    index: number
  ) => {
    const newColumns = [...aggregationColumns];
    newColumns.splice(index, 1, {
      ...c,
      columnAggregation: {
        ...c?.columnAggregation,
        ...(transformDate ? { transformDate } : {})
      }
    } as Column<any>);
    setAggregationColumns(newColumns);
  };
  const handleChangeAggregationColumnTotalType = (
    c: Column<any> | null,
    totalType: "total" | "average" | undefined,
    index: number
  ) => {
    const newColumns = [...aggregationColumns];
    newColumns.splice(index, 1, {
      ...c,
      total: totalsDefault(c?.sign?.(c?.columnAggregation?.funcName)),
      totalType,
      columnAggregation: {
        ...c?.columnAggregation,
        totalType
      }
    } as Column<any>);
    setAggregationColumns(newColumns);
  };
  const handleChangeAggregationColumn = (c: Column<any> | null, index: number) => {
    const newColumns = [...aggregationColumns];
    newColumns.splice(index, 1, {
      ...c,
      type: c?.type,
      ...(c?.customLabel ? {} : { label: c?.label }),
      name: c?.name as string,
      columnAggregation: {
        ...c?.columnAggregation,
        aggregated: true
      }
    } as Column<any>);
    setAggregationColumns(newColumns);
  };
  const handleChangeDecimalPlaces = (
    c: Column<any> | null,
    decimalPlaces: number,
    index: number
  ) => {
    const aggregationPath = `${c?.name}-${c?.columnAggregation?.funcName}`;
    const newColumns = [...aggregationColumns];
    newColumns.splice(index, 1, {
      ...c,
      getData: (entry) => {
        if (
          c?.type === "number" ||
          ["avg", "list-count", "sum", "list-count-unique"].includes(
            c?.columnAggregation?.funcName as string
          )
        ) {
          if (c?.columnAggregation?.dataModifier) {
            return (
              formatNumberAsCurrency(
                (c?.columnAggregation?.dataModifier(
                  entry?.data?.[aggregationPath],
                  c?.columnAggregation?.funcName
                ) as unknown) as number | undefined | null,
                c?.sign?.(c?.columnAggregation?.funcName),
                typeof c?.decimalPlaces === "number" ? c.decimalPlaces : undefined
              ) ??
              c?.default ??
              "N/A"
            );
          }
          return (
            formatNumberAsCurrency(
              entry?.data?.[aggregationPath],
              c?.sign?.(c?.columnAggregation?.funcName),
              decimalPlaces
            ) ??
            c?.default ??
            "N/A"
          );
        } else {
          if (c?.columnAggregation?.dataModifier) {
            return c?.columnAggregation?.dataModifier(
              entry?.data?.[aggregationPath] ?? c?.default ?? "N/A",
              c?.columnAggregation?.funcName
            );
          }
          return entry?.data?.[aggregationPath] ?? c?.default ?? "N/A";
        }
      },
      decimalPlaces
    } as Column<any>);
    setAggregationColumns(newColumns);
  };
  const handleChangeAggregationLabel = (c: Column<any> | null, newLabel: string, index: number) => {
    const newColumns = [...aggregationColumns];
    newColumns.splice(index, 1, {
      ...c,
      label: newLabel,
      customLabel: newLabel
    } as Column<any>);
    setAggregationColumns(newColumns);
  };
  const handleRemoveAggregationColumn = (index: number) => {
    const newAggregationColumns = [...aggregationColumns];
    newAggregationColumns.splice(index, 1);
    setAggregationColumns(newAggregationColumns);
  };

  const aggregationColumnsAreFilled = aggregationColumns.every(
    (c) =>
      c.getData !== undefined &&
      c.columnAggregation &&
      c?.columnAggregation?.aggregated &&
      c?.columnAggregation?.funcName
  );
  const createOptionsForExpandableList = () => {
    const parents = getParents(defaultColumns);
    const columnsNoParent = filterColumnsByParent(defaultColumns, undefined);
    return [
      ...parents.map((parent) => ({
        label: capitalize(parent),
        options: filterColumnsByParent(defaultColumns, parent)
      })),
      { label: "Other", options: columnsNoParent }
    ];
  };
  return (
    <React.Fragment>
      <DialogContent>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={8}>
            <Autocomplete
              value={selectedColumnGrouping}
              getOptionLabel={(columnGroup) => {
                return columnGroup?.data?.info?.name;
              }}
              getOptionSelected={(x, y) => x?._id === y?._id && x === y}
              options={columnGroups?.filter((x) => !x.deleted)}
              onChange={(event, columnGroup) => {
                let newColumnGrouping: ColumnGroup | null = selectedColumnGrouping;
                if (typeof columnGroup === "string") {
                  newColumnGrouping = {
                    ...(selectedColumnGrouping ?? {}),
                    data: {
                      ...(selectedColumnGrouping?.data ?? {}),
                      info: {
                        ...(selectedColumnGrouping?.data?.info ?? {}),
                        name: columnGroup
                      }
                    }
                  } as ColumnGroup;
                } else {
                  newColumnGrouping = columnGroup;
                }

                setSelectedColumnGrouping(newColumnGrouping);
                setGroupName(newColumnGrouping?.data?.info?.name ?? "");
                setGroupIsDefault(newColumnGrouping?.data?.info?.isDefault ?? false);
                if (newColumnGrouping) {
                  const newAggregationColumns = defaultColumns
                    .filter((c) =>
                      newColumnGrouping?.data.info.aggregationColumns.find(
                        (ac) => ac.columnName === c.name
                      )
                    )
                    .map((c) => {
                      const foundInColumnGroup = newColumnGrouping?.data.info.aggregationColumns.find(
                        (ac) => ac.columnName === c.name
                      );
                      const aggregationPath = `${foundInColumnGroup?.columnName}-${foundInColumnGroup?.funcName}`;
                      const filtersForFunction = c?.columnAggregation?.filters?.filter((f) =>
                        f.applyOnFunctions.includes(foundInColumnGroup?.funcName ?? "")
                      );
                      return {
                        getData: (entry) => {
                          if (
                            c?.type === "number" ||
                            ["avg", "list-count", "sum", "list-count-unique"].includes(
                              foundInColumnGroup?.funcName as string
                            )
                          ) {
                            if (c?.columnAggregation?.dataModifier) {
                              return (
                                formatNumberAsCurrency(
                                  (c?.columnAggregation?.dataModifier(
                                    entry?.data?.[aggregationPath],
                                    foundInColumnGroup?.funcName
                                  ) as unknown) as number | undefined | null,
                                  c?.sign?.(foundInColumnGroup?.funcName),
                                  typeof c?.decimalPlaces === "number" ? c.decimalPlaces : undefined
                                ) ??
                                c?.default ??
                                "N/A"
                              );
                            }
                            return (
                              formatNumberAsCurrency(
                                entry?.data?.[aggregationPath],
                                c?.sign?.(foundInColumnGroup?.funcName),
                                typeof foundInColumnGroup?.decimalPlaces === "number"
                                  ? foundInColumnGroup.decimalPlaces
                                  : undefined
                              ) ??
                              c?.default ??
                              "N/A"
                            );
                          } else {
                            if (c?.columnAggregation?.dataModifier) {
                              return c?.columnAggregation?.dataModifier(
                                entry?.data?.[aggregationPath] ?? c?.default ?? "N/A",
                                foundInColumnGroup?.funcName
                              );
                            }
                            return entry?.data?.[aggregationPath] ?? c?.default ?? "N/A";
                          }
                        },
                        getIds: (entry) => entry?.data?.[`${aggregationPath}-ids`],
                        label: foundInColumnGroup?.customLabel
                          ? foundInColumnGroup?.customLabel
                          : `${c?.label} ${foundInColumnGroup?.funcName}`,
                        ...(typeof foundInColumnGroup?.decimalPlaces === "number"
                          ? { decimalPlaces: foundInColumnGroup?.decimalPlaces }
                          : {}),

                        name: c?.name,
                        type: c?.type,
                        truncate: c?.truncate,
                        columnAggregation: {
                          ...(c?.columnAggregation ?? {}),
                          totalType: foundInColumnGroup?.totalType,
                          funcName: foundInColumnGroup?.funcName,
                          transformDate: foundInColumnGroup?.transformDate,
                          aggregated: true
                        },
                        options: {
                          path: [aggregationPath],
                          sort: true
                        },
                        filters:
                          foundInColumnGroup?.funcName === "list"
                            ? []
                            : filtersForFunction?.length
                            ? filtersForFunction.map((filter) => ({
                                ...filter,
                                filterType: "aggregatedColumnFilter",
                                path: [aggregationPath],
                                name: c?.name,
                                label: `${c?.label} ${foundInColumnGroup?.funcName}`
                              }))
                            : c?.filters
                      } as Column<any>;
                    });
                  const filteredGroupColumns = defaultColumns
                    .filter(
                      (defaultColumn) =>
                        newColumnGrouping?.data.info.groupColumns.includes(defaultColumn.name) &&
                        defaultColumn?.grouping?.enabled
                    )
                    .map((column) => {
                      const transformDate = newColumnGrouping?.data?.info?.grouping?.find(
                        (groupEntry) => groupEntry?.key === column?.grouping?.key
                      )?.transformDate;
                      return {
                        ...column,
                        name: column.name,
                        grouping: {
                          ...(column?.grouping ?? ({} as ColumnGrouping<any>)),
                          transformDate,
                          grouped: true
                        }
                      };
                    });
                  setGroupColumns(filteredGroupColumns);
                  setGroupColumnsAutocompleteState(filteredGroupColumns);
                  setAggregationColumns(newAggregationColumns);
                }
              }}
              freeSolo={selectedColumnGrouping?._id !== undefined}
              forcePopupIcon
              autoSelect
              loading={!Array.isArray(columnGroups)}
              openOnFocus
              id="column-groups-select"
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ ...params.InputProps }}
                  label={"Column Groups"}
                  variant="filled"
                  size="small"
                />
              )}
            />
          </Grid>
          {!isReadOnlyField && (
            <Grid container item xs={4} style={{ alignItems: "center" }}>
              <Grid item xs={10}>
                <FormControlLabel
                  onChange={() => setGroupIsDefault((prevDefault) => !prevDefault)}
                  control={<Checkbox id={"isDefault"} checked={groupIsDefault} />}
                  label={
                    <Tooltip title="Checking this will overwrite existing default group column.">
                      <Typography>Is default?</Typography>
                    </Tooltip>
                  }
                />
              </Grid>
              {type === "own" && (
                <Grid item xs={2}>
                  <Tooltip title="Export options. (Selected column grouping required).">
                    <span>
                      <IconButton
                        disabled={
                          !(
                            selectedColumnGrouping &&
                            groupColumns.length &&
                            aggregationColumns.length &&
                            aggregationColumnsAreFilled &&
                            isAdmin
                          )
                        }
                        onClick={() => setExportImportDialogOpen(true)}
                      >
                        <ShareIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          )}
          <Grid item xs={6}>
            <Autocomplete
              multiple
              disabled={isReadOnlyField}
              id="checkboxes-tags-demo"
              options={defaultColumns.filter((column) => column?.grouping?.enabled)}
              disableCloseOnSelect
              getOptionLabel={(column) => column.label}
              value={groupColumnsAutocompleteState}
              onChange={(e, newColumns) => {
                // if (newColumns.some((c) => c.type === "date")) {
                //   setCompareMode(false);
                // }
                setGroupColumnsAutocompleteState(newColumns);
                setGroupColumns(
                  newColumns.map((c) => ({
                    ...c,
                    grouping: {
                      ...(c?.grouping ?? ({} as ColumnGrouping<any>)),
                      grouped: true
                    }
                  }))
                );
              }}
              renderOption={(column, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {column.label}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  ref={params.InputProps.ref}
                  variant="filled"
                  label="Columns to group"
                  placeholder="Columns to group"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            {groupColumnsAutocompleteState.length > 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppableColumnGroups" direction="vertical">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      style={{
                        border: "1px dashed black"
                      }}
                    >
                      <Typography style={{ fontWeight: "bold", padding: "10px" }}>
                        Columns to group order
                      </Typography>
                      <List>
                        {groupColumnsAutocompleteState.map((groupColumn, index) => (
                          <Draggable
                            key={groupColumn.name}
                            draggableId={groupColumn.name}
                            index={index}
                          >
                            {(provided) => (
                              <ListItem
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                button
                                ref={provided.innerRef}
                              >
                                <ListItemText
                                  primary={
                                    <Grid container style={{ alignItems: "center" }}>
                                      <Grid item xs={groupColumn?.type === "date" ? 6 : 10}>
                                        {groupColumn.label}
                                      </Grid>
                                      {groupColumn?.type === "date" && (
                                        <Grid item xs={4}>
                                          <Autocomplete
                                            disabled={isReadOnlyField}
                                            value={{
                                              value: groupColumn?.grouping?.transformDate,
                                              label: dateOptions?.find(
                                                (option) =>
                                                  option.value ===
                                                  groupColumn?.grouping?.transformDate
                                              )?.label
                                            }}
                                            getOptionLabel={(dateOption) => {
                                              return dateOption?.label as string;
                                            }}
                                            getOptionSelected={(x, y) => x?.value === y?.value}
                                            options={dateOptions}
                                            onChange={(event, x) => {
                                              handleChangeDateFormat(groupColumn, x?.value);
                                            }}
                                            openOnFocus
                                            id="date-type-column-group-select"
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{ ...params.InputProps }}
                                                label={"Date format"}
                                                variant="filled"
                                                fullWidth
                                                size="small"
                                              />
                                            )}
                                          />
                                        </Grid>
                                      )}
                                      <Grid item xs={2}>
                                        <IconButton style={{ cursor: "grab" }}>
                                          <DragIndicatorIcon />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  }
                                />
                              </ListItem>
                            )}
                          </Draggable>
                        ))}
                      </List>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Grid>
          <Grid item xs={12}>
            <DragDropContext onDragEnd={onDragEndColumnAggregations}>
              <Droppable droppableId="droppableAggregations" direction="vertical">
                {(provided) => (
                  <div ref={provided.innerRef}>
                    <Paper elevation={2} style={{ padding: "5px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "10px 0px 0px 10px",
                          gap: "20px"
                        }}
                      >
                        <Typography variant="subtitle2">Aggregation columns</Typography>
                        <Button
                          variant="contained"
                          disabled={isReadOnlyField}
                          size="small"
                          color="primary"
                          onClick={() =>
                            setAggregationColumns((prev) => [
                              ({} as unknown) as Column<any>,
                              ...prev
                            ])
                          }
                        >
                          Add new row
                        </Button>
                      </div>
                      {(aggregationColumns ?? [])?.map((aggregationColumn, index) => (
                        <Draggable
                          key={`${aggregationColumn.name}-${index}`}
                          draggableId={`${aggregationColumn.name}-${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  margin: "10px 0px",
                                  background: "#0000000a",
                                  justifyContent: "space-between",
                                  alignItems: "center"
                                }}
                              >
                                <div style={{ display: "flex", width: "100%", gap: "10px" }}>
                                  <div style={{ display: "flex", flex: 1 }}>
                                    <ExpandableList<Column<any>>
                                      placeholder={"Column"}
                                      label={"Column"}
                                      required
                                      disabled={isReadOnlyField}
                                      value={aggregationColumn}
                                      getOptionLabel={(c) => {
                                        const found = defaultColumns?.find(
                                          (defaultColumn) => defaultColumn.name === c.name
                                        );
                                        return c?.customLabel ?? c?.label ?? found?.label;
                                      }}
                                      onSelect={(c) => {
                                        handleChangeAggregationColumn(c, index);
                                      }}
                                      onClear={() => {
                                        handleChangeAggregationColumn(null, index);
                                      }}
                                      id={`aggregations-${index}`}
                                      options={createOptionsForExpandableList()}
                                    />
                                  </div>
                                  <div style={{ display: "flex", flex: 1 }}>
                                    <TextField
                                      onChange={(event) =>
                                        handleChangeAggregationLabel(
                                          aggregationColumn,
                                          event.target.value,
                                          index
                                        )
                                      }
                                      disabled={isReadOnlyField}
                                      InputLabelProps={{ shrink: true }}
                                      value={aggregationColumn?.label}
                                      name="columnLabel"
                                      required
                                      placeholder="Label"
                                      fullWidth
                                      label={"Label"}
                                      variant={"filled"}
                                      size="small"
                                    />
                                  </div>
                                  <div style={{ display: "flex", flex: 1 }}>
                                    <Autocomplete
                                      disabled={isReadOnlyField || !(groupColumns.length || 0)}
                                      id={`aggregationFunction-${index}`}
                                      options={aggregationFunctions.filter(
                                        (aggregationFunction) =>
                                          aggregationColumn?.columnAggregation?.possibleFunctions?.includes(
                                            aggregationFunction.value
                                          ) &&
                                          !aggregationColumns.find(
                                            (ac) =>
                                              ac.name === aggregationColumn.name &&
                                              ac?.columnAggregation?.funcName ===
                                                aggregationFunction.value
                                          )
                                      )}
                                      fullWidth
                                      value={{
                                        value: aggregationColumn?.columnAggregation?.funcName,
                                        label: aggregationFunctions?.find(
                                          (option) =>
                                            option.value ===
                                            aggregationColumn?.columnAggregation?.funcName
                                        )?.label
                                      }}
                                      getOptionLabel={(option) => {
                                        return option?.label as string;
                                      }}
                                      onChange={(e, fnType) => {
                                        handleChangeAggregationColumnFunction(
                                          aggregationColumn,
                                          fnType?.value ?? undefined,
                                          index
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          InputLabelProps={{ shrink: true }}
                                          InputProps={{ ...params.InputProps }}
                                          variant="filled"
                                          fullWidth
                                          label="Function"
                                          required
                                          size="small"
                                          placeholder="Function"
                                        />
                                      )}
                                    />
                                  </div>
                                  {["sum", "avg", "list-count", "list-count-unique"].includes(
                                    aggregationColumn?.columnAggregation?.funcName ?? ""
                                  ) && (
                                    <div style={{ display: "flex", flex: 1 }}>
                                      <Autocomplete
                                        disabled={isReadOnlyField || !(groupColumns.length || 0)}
                                        id={`aggregationTotalType-${index}`}
                                        options={["total", "average"]}
                                        fullWidth
                                        value={aggregationColumn?.columnAggregation?.totalType}
                                        getOptionLabel={(option) => {
                                          return capitalize(option) as string;
                                        }}
                                        onChange={(e, totalType) => {
                                          handleChangeAggregationColumnTotalType(
                                            aggregationColumn,
                                            (totalType as "total" | "average") ?? undefined,
                                            index
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ ...params.InputProps }}
                                            variant="filled"
                                            fullWidth
                                            label="Column aggr. type"
                                            size="small"
                                            placeholder="Column aggr. type"
                                          />
                                        )}
                                      />
                                    </div>
                                  )}
                                  {(aggregationColumn?.type === "number" ||
                                    ["avg", "sum", "list-count", "list-count-unique"].includes(
                                      aggregationColumn?.columnAggregation?.funcName as string
                                    )) && (
                                    <div style={{ display: "flex", flex: 1 }}>
                                      <Autocomplete
                                        id={`decimal-points-${index}`}
                                        options={[0, 1, 2, 3, 4, 5]}
                                        getOptionLabel={(x) => x?.toString()}
                                        fullWidth
                                        value={aggregationColumn?.decimalPlaces}
                                        onChange={(e, decimalPlaces) => {
                                          handleChangeDecimalPlaces(
                                            aggregationColumn,
                                            decimalPlaces ?? 0,
                                            index
                                          );
                                        }}
                                        disabled={
                                          !aggregationColumn?.columnAggregation?.funcName &&
                                          isReadOnlyField
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ ...params.InputProps }}
                                            variant="filled"
                                            size="small"
                                            fullWidth
                                            label="Decimal places"
                                            placeholder="Decimal places"
                                          />
                                        )}
                                      />
                                    </div>
                                  )}
                                  {aggregationColumn?.type === "date" && (
                                    <div style={{ display: "flex", flex: 1 }}>
                                      <Autocomplete
                                        disabled={isReadOnlyField}
                                        id={`aggregationTransformDate-${index}`}
                                        value={{
                                          value:
                                            aggregationColumn?.columnAggregation?.transformDate,
                                          label: dateOptions?.find(
                                            (option) =>
                                              option.value ===
                                              aggregationColumn?.columnAggregation?.transformDate
                                          )?.label
                                        }}
                                        getOptionLabel={(dateOption) => {
                                          return dateOption?.label as string;
                                        }}
                                        fullWidth
                                        getOptionSelected={(x, y) => x?.value === y?.value}
                                        options={dateOptions}
                                        onChange={(event, transformDate) => {
                                          handleChangeAggregationColumnTransformDate(
                                            aggregationColumn,
                                            transformDate?.value ?? undefined,
                                            index
                                          );
                                        }}
                                        openOnFocus
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ ...params.InputProps }}
                                            label={"Date format"}
                                            placeholder="Date format"
                                            variant="filled"
                                            fullWidth
                                            size="small"
                                          />
                                        )}
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <IconButton
                                      disabled={isReadOnlyField}
                                      onClick={() => handleRemoveAggregationColumn(index)}
                                    >
                                      <RemoveIcon
                                        style={{
                                          color: "red"
                                        }}
                                      />
                                    </IconButton>
                                    <IconButton style={{ cursor: "grab" }}>
                                      <DragIndicatorIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </Paper>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <div>
          <Button onClick={() => setOpen(!open)} variant="contained" color="primary" id="cancel">
            Cancel
          </Button>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            disabled={!(groupColumns.length && aggregationColumns.length)}
            variant="contained"
            color="primary"
            autoFocus
            onClick={handleReset}
          >
            Reset
          </Button>
          {!isReadOnlyField && selectedColumnGrouping ? (
            <Button
              variant="contained"
              color="secondary"
              autoFocus
              onClick={() => handleDeleteColumnGroup(selectedColumnGrouping?._id)}
            >
              Delete selected grouping
            </Button>
          ) : null}
          {groupColumns?.some((c) => c.type === "date") && compareMode ? (
            <HintTooltip title="Grouping is not available when compare mode is enabled and groups include date fields.">
              <div>
                <Button
                  disabled={true}
                  variant="contained"
                  color="primary"
                  autoFocus
                  onClick={() => handleGroup(groupColumns, aggregationColumns)}
                >
                  Group
                </Button>
              </div>
            </HintTooltip>
          ) : (
            <Button
              disabled={
                !(groupColumns.length && aggregationColumns.length && aggregationColumnsAreFilled)
              }
              variant="contained"
              color="primary"
              autoFocus
              onClick={() => handleGroup(groupColumns, aggregationColumns)}
            >
              Group
            </Button>
          )}

          {type === "general" && (
            <Button
              disabled={selectedColumnGrouping === undefined}
              variant="contained"
              color="primary"
              autoFocus
              onClick={() => handleExportCurrentGroupingToOwn()}
            >
              Export to my groups
            </Button>
          )}
          {!isReadOnlyField ? (
            selectedColumnGrouping ? (
              <React.Fragment>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !(
                      groupColumns.length &&
                      aggregationColumns.length &&
                      aggregationColumnsAreFilled
                    )
                  }
                  autoFocus
                  onClick={() => handleEditSelectedGrouping()}
                >
                  Save changes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !(
                      groupColumns.length &&
                      aggregationColumns.length &&
                      aggregationColumnsAreFilled
                    )
                  }
                  autoFocus
                  onClick={saveGroupingCallback}
                >
                  Save as new
                </Button>
              </React.Fragment>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !(groupColumns.length && aggregationColumns.length && aggregationColumnsAreFilled)
                }
                autoFocus
                onClick={() => {
                  setGroupName("");
                  setSaveDialogOpen(true);
                }}
              >
                Save current grouping
              </Button>
            )
          ) : null}
        </div>
      </DialogActions>
    </React.Fragment>
  );
};

const SaveDialog = ({
  closeFunction,
  setGroupName,
  isNameTaken,
  groupName,
  saveGroupingCallback
}: SaveDialogProps) => {
  return (
    <Dialog maxWidth="xs" fullWidth open={true} onClose={closeFunction}>
      <DialogTitle>Name the grouping</DialogTitle>
      <CloseDialogButton closeFunction={closeFunction} />
      <DialogContent>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12}>
            <TextField
              onChange={(event) => {
                setGroupName(event.target.value);
              }}
              error={isNameTaken}
              FormHelperTextProps={isNameTaken ? { style: { color: "#f19c65" } } : {}}
              helperText={isNameTaken ? "Group name is already taken" : undefined}
              value={groupName}
              name="groupName"
              required
              placeholder="Group name"
              fullWidth
              label={"Group name"}
              variant={"filled"}
              size="small"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeFunction} variant="contained" color="primary" id="cancel">
          Cancel
        </Button>
        <Button
          disabled={isNameTaken || !groupName.length}
          variant="contained"
          color="primary"
          autoFocus
          onClick={saveGroupingCallback}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const ExportImportDialog = ({
  closeFunction,
  groupingType,
  columnGroup,
  columnGroupRequestId,
  currentUser
}: ExportImportDialogProps) => {
  const dispatch = useDispatch();
  const [requestId] = React.useState(uuid());
  const userList = useSelector((state: RootState) => state.listUserSlice[requestId]);
  const [selectedUsers, setSelectedUsers] = React.useState<User[]>([]);
  const [generatedLink, setGeneratedLink] = React.useState<string | undefined>();
  const [sendEmail, setSendEmail] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const userNames = [currentUser?.data?.info?.firstName, currentUser?.data?.info?.lastName]
    ?.filter((x) => x)
    ?.join(" ");
  const addColumnGroupStatus = useSelector(
    (state: RootState) => state.addColumnGroupSlice[columnGroupRequestId]
  );
  const exportColumnGroupStatus = useSelector(
    (state: RootState) => state.exportColumnGroupSlice[columnGroupRequestId]
  );
  const [snapshotColumnGroup, setSnapshotColumnGroup] = React.useState<ColumnGroup | undefined>();
  React.useEffect(() => {
    switch (addColumnGroupStatus?.status) {
      case "success":
        switch (groupingType) {
          case "salesrep":
            setGeneratedLink(
              `${process.env.REACT_APP_URL}/sales_representative_dashboard?columnGroupId=${addColumnGroupStatus?.data?.message?._id}&dashboardType=salesRep`
            );
            break;
          default:
            setGeneratedLink(
              `${process.env.REACT_APP_URL}/${groupingType}?columnGroupId=${addColumnGroupStatus?.data?.message?._id}&page=${groupingType}`
            );
            break;
        }
        setSnapshotColumnGroup(addColumnGroupStatus?.data?.message as ColumnGroup);
        break;
    }
  }, [addColumnGroupStatus?.status]);
  React.useEffect(() => {
    if (exportColumnGroupStatus?.status === "waiting") {
      dispatch(showLoader());
    }
    if (exportColumnGroupStatus?.status === "success") {
      enqueueSnackbar(`Successfully exported a column group.`, { variant: "success" });
      dispatch(hideLoader());
    }
    if (exportColumnGroupStatus?.status === "error") {
      enqueueSnackbar(exportColumnGroupStatus?.message ?? "Something went wrong", {
        variant: "error"
      });
      dispatch(hideLoader());
    }
  }, [exportColumnGroupStatus?.status]);
  React.useEffect(() => {
    if (!userList)
      dispatch(
        getUserList(requestId, {
          options: { pagination: false, sort: { "data.info.firstName": "asc" } }
        })
      );
    return () => {
      dispatch(removeUserList(requestId));
      return;
    };
  }, []);
  const handleGenerateLink = () => {
    dispatch(
      addColumnGroup({
        requestId: columnGroupRequestId,
        ...{
          ...columnGroup,
          data: {
            ...columnGroup.data,
            info: {
              ...columnGroup.data.info,
              name: `${columnGroup.data.info.name}${userNames ? ` - shared by ${userNames}` : ""}`
            }
          }
        }
      })
    );
  };
  const handleExportToSelectedUsers = () => {
    if (selectedUsers?.length && generatedLink) {
      dispatch(
        exportColumnGroup({
          requestId: columnGroupRequestId,
          usersToNotify: selectedUsers.map((user) => ({
            userEmail: user?.data?.info?.email,
            userId: user?._id
          })),
          generatedLink,
          sendEmail
        })
      );
    }
  };
  const handleExportToGeneralGroups = () => {
    if (snapshotColumnGroup) {
      dispatch(
        addColumnGroup({
          requestId: columnGroupRequestId,
          ...({
            data: {
              info: {
                ...snapshotColumnGroup.data.info,
                name: `${columnGroup.data.info.name}${
                  userNames ? ` - shared by ${userNames}` : ""
                }`,
                isSnapshot: false
              }
            }
          } as ColumnGroup)
        })
      );
    }
  };
  return (
    <Dialog maxWidth="md" fullWidth open={true} onClose={closeFunction}>
      <DialogTitle>Export column group</DialogTitle>
      <CloseDialogButton closeFunction={closeFunction} />
      <DialogContent>
        <Grid container xs={12} style={{ alignItems: "center" }} spacing={2}>
          <Stepper orientation="vertical" connector={<div style={{ height: "10px" }}></div>}>
            <Step key={"Generate link"} active={true}>
              <StepLabel>{"Generate link"}</StepLabel>
              <StepContent>
                <Button variant="contained" color="primary" autoFocus onClick={handleGenerateLink}>
                  Generate link for grouping
                </Button>
              </StepContent>
            </Step>
            <Step key={"Users"} active={generatedLink !== undefined}>
              <StepLabel>Select users to notify</StepLabel>
              <StepContent>
                {generatedLink ? (
                  <React.Fragment>
                    <Autocomplete
                      style={{ zIndex: 6 }}
                      value={selectedUsers}
                      getOptionLabel={(user) => {
                        return (
                          [user?.data?.info?.firstName, user?.data?.info?.lastName]
                            .filter((x) => x)
                            .join(" ") ?? ""
                        );
                      }}
                      multiple
                      getOptionSelected={(x, y) => x?._id === y?._id && x === y}
                      options={(userList?.entities ?? [])?.filter(
                        (user) => user?.data?.info?.dashboardTypes?.salesRep
                      )}
                      onChange={(event, newUsers) => {
                        setSelectedUsers(newUsers);
                      }}
                      loading={!Array.isArray(userList?.entities)}
                      openOnFocus
                      id="rep-dashboard-export-to-users-select"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ ...params.InputProps }}
                          label={"Users"}
                          variant="filled"
                          size="small"
                        />
                      )}
                    />
                    <FormControlLabel
                      onChange={() => setSendEmail((prevSendEmail) => !prevSendEmail)}
                      control={<Checkbox id={"sendEmail"} checked={sendEmail} />}
                      label={"Send email"}
                    />
                  </React.Fragment>
                ) : (
                  "Please generate the link first!"
                )}
              </StepContent>
            </Step>
            <Step key={"Manual export"} active={generatedLink !== undefined}>
              <StepLabel>or manually export to anyone using the link</StepLabel>
              <StepContent>
                {generatedLink ? (
                  <a rel="noopener noreferrer" target="_blank" href={generatedLink}>
                    {generatedLink}
                  </a>
                ) : (
                  "Please generate the link first!"
                )}
              </StepContent>
            </Step>
          </Stepper>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeFunction} variant="contained" color="primary" id="cancel">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!generatedLink}
          autoFocus
          onClick={handleExportToGeneralGroups}
        >
          Export to general groups
        </Button>
        <Button
          disabled={!generatedLink || selectedUsers?.length < 1}
          variant="contained"
          color="primary"
          autoFocus
          onClick={handleExportToSelectedUsers}
        >
          Notify selected users
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type ExpandableListProps<T> = {
  id: string;
  label?: string;
  value: T;
  required?: boolean;
  getOptionLabel?: (option: T) => string;
  onClear?: () => void;
  placeholder?: string;
  disabled: boolean;
  options: {
    label: string;
    options: T[];
  }[];
  onSelect: (option: T) => void;
};
function ExpandableList<T extends any>({
  label,
  value,
  getOptionLabel,
  placeholder,
  required = false,
  disabled,
  onSelect,
  options,
  onClear,
  id
}: ExpandableListProps<T>) {
  const [inputValue, setInputValue] = React.useState<string>(getOptionLabel?.(value) ?? "");
  const [openCategories, setOpenCategories] = React.useState<Record<string, boolean>>({});
  const [maxHeight, setMaxHeight] = React.useState(window.innerHeight * 0.85);
  const [listOpen, setListOpen] = React.useState(false);
  const [fieldHovered, setFieldHovered] = React.useState(false);
  const ref = React.createRef<HTMLDivElement>();
  const [anchorEl, setAnchorEl] = React.useState<null | (EventTarget & HTMLDivElement)>(null);
  const calculateMaxHeight = () => {
    if (!anchorEl) return;

    const viewportHeight = window.innerHeight;
    const anchorRect = anchorEl.getBoundingClientRect();
    const distanceToBottom = viewportHeight - anchorRect.bottom;

    const calculatedMaxHeight = distanceToBottom * 0.85;
    setMaxHeight(calculatedMaxHeight);
  };
  const filteredOptions = options.filter(
    (category) =>
      category.label.toLowerCase().includes(inputValue?.toLowerCase()) ||
      category?.options?.some((option: any) =>
        option.label.toLowerCase().includes(inputValue?.toLowerCase())
      )
  );
  React.useEffect(() => {
    if (anchorEl) {
      calculateMaxHeight();
    }
  }, [anchorEl]);

  React.useEffect(() => {
    function handleResize() {
      calculateMaxHeight();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputValue(event.target.value);
    setListOpen(true);
  };

  const handleToggle = (category: string) => {
    setOpenCategories((prev) => ({ ...prev, [category]: !prev[category] }));
  };

  const handleFocus = (event: React.FocusEvent<HTMLDivElement, Element>) => {
    setListOpen(true);

    setAnchorEl(event.currentTarget);
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement, Element>) => {
    if (!event.currentTarget?.contains(document.activeElement)) {
      setListOpen(false);
    }
  };
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
  };
  const handleOnMouseOver = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setFieldHovered(true);
  };
  const handleOnMouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setFieldHovered(false);
  };

  return (
    <Box
      id={id}
      style={{ position: "relative", display: "flex", flexDirection: "row", width: "100%" }}
    >
      <div
        tabIndex={0}
        onBlur={handleBlur}
        ref={ref}
        onMouseOver={handleOnMouseOver}
        onMouseLeave={handleOnMouseLeave}
        onFocus={handleFocus}
      >
        <TextField
          disabled={disabled}
          label={label}
          variant="filled"
          size="small"
          required={required}
          fullWidth
          value={inputValue}
          placeholder={placeholder}
          InputLabelProps={{ shrink: true }}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {inputValue && fieldHovered ? (
                  <IconButton
                    onClick={() => {
                      setInputValue("");
                      onClear?.();
                    }}
                    style={{ padding: 0 }}
                  >
                    <ClearIcon fontSize="small" style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                  </IconButton>
                ) : null}
                {listOpen ? (
                  <IconButton onClick={() => setListOpen(false)} style={{ padding: 0 }}>
                    <ArrowDropUpIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={(e) => {
                      setListOpen(true);
                      setAnchorEl(ref?.current);
                      ref?.current?.focus();
                    }}
                    style={{ padding: 0 }}
                  >
                    <ArrowDropDownIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                  </IconButton>
                )}
              </InputAdornment>
            )
          }}
        />
      </div>
      <Popper
        onMouseDown={handleMouseDown}
        style={{ zIndex: 9999, width: anchorEl?.clientWidth }}
        placement="bottom-start"
        id={listOpen ? "autocomplete-pop" : undefined}
        open={listOpen}
        anchorEl={anchorEl}
      >
        {({ TransitionProps }) => (
          <List
            style={{
              marginTop: "2px",
              borderRadius: "4px",
              position: "absolute",
              maxHeight: `${maxHeight}px`,
              overflow: "auto",
              width: "100%",
              zIndex: 9999,
              background: "white"
            }}
          >
            {filteredOptions?.length ? (
              filteredOptions.map((category, index) => (
                <React.Fragment key={index}>
                  <ListItem button onClick={() => handleToggle(category.label)}>
                    <ListItemText primary={category.label} />
                    {openCategories[category.label] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openCategories[category.label]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {category?.options?.length > 0
                        ? category.options.map((option: T, index) => (
                            <ListItem
                              key={index}
                              button
                              onClick={() => {
                                onSelect(option);
                                setInputValue((option as any).label);
                              }}
                              style={{ paddingLeft: 32 }}
                            >
                              <ListItemText primary={(option as any).label} />
                            </ListItem>
                          ))
                        : null}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))
            ) : (
              <ListItem button>
                <ListItemText primary={"No options"} />
              </ListItem>
            )}
          </List>
        )}
      </Popper>
    </Box>
  );
}
