import { queryClient } from "app/App";
import axios from "axios";
import { auth } from "../../firebase/firebase";
import { HttpQuery } from "Chat/types/http";
import { CreateMessageRequest, Message } from "Chat/types/messages";
import { convertFileToBase64 } from "utils/functions";

export const getMessages = (channelId: string, query?: HttpQuery): (() => Promise<Message[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/messages/${channelId}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          ...(query && Object.keys(query)?.length ? { query: JSON.stringify(query) } : {})
        }
      }
    );

    return response.data.result?.docs ?? [];
  };
};
export const createMessage = (): ((message: CreateMessageRequest) => Promise<Message>) => {
  return async (message) => {
    const token = await auth.currentUser?.getIdToken();

    const { files, ...restMessage } = message.data;
    queryClient.setQueriesData<Message[]>([`messages-${message.data.channelId}`], (oldData) => {
      return [...(oldData ?? []), (message as unknown) as Message];
    });
    let base64Files = null;
    if (files && files.length)
      base64Files = await Promise.all(files.map(async (file) => await convertFileToBase64(file)));

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/messages/`,
      { data: restMessage, files: base64Files },

      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return response.data.result;
  };
};
export const updateMessage = (): ((messageId: string) => Promise<Message>) => {
  return async (messageId) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/messages/${messageId}`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return response.data.result;
  };
};
