import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { auth } from "../../firebase/firebase";
import { MutationError } from "Chat/types";
import { TitleIssue } from "components/TitleIssues/types";

export const updateTitleIssueNotes = (): ((request: TitleIssue) => Promise<TitleIssue>) => {
  return async (request) => {
    try {
      const token = await auth.currentUser?.getIdToken();

      const response = await axios.put(
        `${process.env.REACT_APP_HTTP_URL}/api/v1/update_title_issue_notes/${request._id}`,
        request,
        {
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      );
      return response.data.result;
    } catch (err: any) {
      throw new Error(
        err?.response?.data?.message ??
          err?.response?.data?.result ??
          err?.message ??
          "Unknown error!"
      );
    }
  };
};

const useUpdateTitleIssueNotes = () => {
  const mutation = useMutation<TitleIssue, MutationError, TitleIssue>(updateTitleIssueNotes(), {
    onSuccess: () => {
      return;
    }
  });
  return {
    updateTitleIssueNotes: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};
export default useUpdateTitleIssueNotes;
