import { Lock } from "./types";
import { permissionNode, relationPermissionNode, PermissionStruct } from "components/Roles/types";

export type LockPermissionsStruct = PermissionStruct<Required<Lock>>;

export const lockPermissionStruct: LockPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      entityId: permissionNode("Entity Id"),
      entityName: permissionNode("Entity Name"),
      userEmail: permissionNode("User Email")
    }
  }
};
