import { useHideChannel } from "Chat/hooks/useChannel";
import React from "react";
import { BsPerson } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { Channel } from "Chat/types/channels";
import styles from "./Chat.module.css";
import Badge from "./Badge";

const ApplicantsChat = ({
  channels,
  onClick
}: {
  onClick: (index: string) => void;
  channels?: Channel[];
}) => {
  const { hideChannel } = useHideChannel();
  const handleHideChannel = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, channel: Channel) => {
    e.stopPropagation();
    hideChannel(channel);
  };
  return (
    <div className={styles.listContainer}>
      <p>Applicants</p>
      <ul className={`${styles.list}`}>
        {(channels ?? [])?.map((channel, index) => (
          <li className={styles.listItem} key={index} onClick={() => onClick(channel._id)}>
            <div>
              <BsPerson size={25} color={"#9E9CA7"} width={42} />
            </div>
            <div className={styles.text}>{channel.data.info.name}</div>
            <div style={{ marginLeft: "auto", marginRight: "5px" }}>
              <Badge badgeCount={channel.data.info.unseenMessages} />
            </div>
            <div onClick={(e) => handleHideChannel(e, channel)} className={styles.closeIcon}>
              <IoMdClose size={16} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ApplicantsChat;
