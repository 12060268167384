import {
  Button,
  Grid,
  Slider,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import React from "react";
import { FilterProps } from "./types";
const theme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "#2196f3"
      },
      track: {
        color: "#2196f3"
      },
      rail: {
        color: "#2196f3"
      }
    }
  }
});
interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}
function ValueLabelComponent(props: Props) {
  const { children, value, open } = props;
  return (
    <Tooltip enterTouchDelay={0} open={open} placement="top" title={`$${value}`} arrow>
      {children}
    </Tooltip>
  );
}

function formatValue(value: number) {
  return `$${value}`;
}

export default function PriceFilter<T extends unknown>({
  setFilter,
  path,
  filter,
  filterType,
  label,
  valuesForSelect = [0, 50000] // Add a default parameter for defaultRange
}: FilterProps<T> & { defaultRange?: number[] }) {
  const [timeout, setTimeoutState] = React.useState<undefined | NodeJS.Timeout>();
  const [selectedValues, setSelectedValues] = React.useState(filter?.values ?? valuesForSelect);
  const handleChange = (_: React.ChangeEvent<{}>, newValues: number | number[]) => {
    const isValueValid = Array.isArray(newValues) && newValues?.length > 0;
    if (timeout) clearTimeout(timeout);
    if (isValueValid) setSelectedValues(newValues);
    setTimeoutState(
      setTimeout(() => {
        if (isValueValid) {
          setFilter({
            query: {
              [path.join(".")]: {
                $gte: newValues[0],
                $lte: newValues[1]
              },
              ...(filterType === "aggregatedColumnFilter" ? { filterType } : {})
            },
            values: newValues
          });
        } else {
          setFilter(undefined);
        }
      }, 500)
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={1}>
        {label ? (
          <Grid item xs={12}>
            <Typography variant="subtitle2">{label}</Typography>
          </Grid>
        ) : null}

        <Grid container item xs={12}>
          {[0, 1].map((x) => (
            <Grid item xs={6} key={x} style={{ padding: "0px 15px" }}>
              <TextField
                color="primary"
                label={x ? "To" : "From"}
                style={{ width: "100%" }}
                onChange={(event) => {
                  handleChange(
                    event,
                    x
                      ? [selectedValues[0], parseInt(event.target.value || "0")]
                      : [parseInt(event.target.value || "0"), selectedValues[1]]
                  );
                }}
                value={selectedValues[x]}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} style={{ padding: "40px 20px" }}>
          <Slider
            step={valuesForSelect[1] / 100}
            valueLabelDisplay="on"
            ValueLabelComponent={ValueLabelComponent}
            defaultValue={valuesForSelect}
            value={selectedValues}
            min={valuesForSelect[0]}
            max={valuesForSelect[1]}
            onChange={handleChange}
            getAriaValueText={formatValue}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            style={{ float: "right" }}
            onClick={() => {
              setFilter(undefined);
              setSelectedValues(valuesForSelect);
            }}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
