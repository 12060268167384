import React, { useState, useEffect, useContext } from "react";
import { Button } from "@material-ui/core";
import { DealershipSendGeneratedLink } from "./sendGeneratedLinkSlice";
import { useDispatch, useSelector } from "react-redux";
import LinkIcon from "@material-ui/icons/Link";
import { useSnackbar } from "notistack";
import { RootState } from "app/rootReducer";
import { v4 as uuidv4 } from "uuid";
import formEditContext from "components/Content/FormEditContext";
import { StateAccess } from "utils/models/formGenerator";
import { Dealership } from "./types";

const SendGeneratedLink = ({ stateAccess }: { stateAccess: StateAccess }) => {
  const dispatch = useDispatch();
  const dealership: Dealership = stateAccess.get<Dealership>([]);
  const [requestId] = useState(uuidv4());
  const { enqueueSnackbar } = useSnackbar();
  const { enabled: editMode, edited } = useContext(formEditContext);
  const handleSendGeneratedLink = () => {
    if (!dealership?.data?.info?.adminRegistrationLink) {
      return alert("Please generate the link before sending it");
    }
    if (edited) {
      return alert("Please save the dealership first.");
    }

    dispatch(DealershipSendGeneratedLink({ requestId, _id: dealership._id }));
  };

  const sendGeneratedLinkSlice = useSelector(
    (state: RootState) => state.sendGeneratedLinkSlice[requestId]
  );

  useEffect(() => {
    if (sendGeneratedLinkSlice?.status === "success") {
      enqueueSnackbar(sendGeneratedLinkSlice?.data?.message, { variant: "success" });
    } else if (sendGeneratedLinkSlice?.status === "error") {
      enqueueSnackbar(sendGeneratedLinkSlice?.message, { variant: "error" });
    }
  }, [sendGeneratedLinkSlice]);

  return (
    <>
      {editMode && (
        <Button
          onClick={handleSendGeneratedLink}
          style={{ width: "100%" }}
          variant="contained"
          component="label"
          color="primary"
          startIcon={<LinkIcon />}
        >
          Send email for admin registration
        </Button>
      )}
    </>
  );
};

export default SendGeneratedLink;
