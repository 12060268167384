import { RootState } from "app/rootReducer";
import { getLenderList } from "components/Lenders/listLenderSlice";
import { Lender } from "components/Lenders/types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Model } from "utils/models/fields";
import { StateAccess, RenderSet, generateForm } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import { UserAllocationPeriod } from "./types";

type AllocationPeriodProps = {
  stateAccess: StateAccess;
  renderSet: RenderSet;
};
const allocationStruct = (lenders: Lender[]): Model<UserAllocationPeriod> => ({
  formComponent: "model",
  schema: false,
  name: "wfd_user",
  entities: [
    {
      formComponent: "segment",
      name: "Allocation periods",
      width: "full",
      entities: lenders.map((lender) => ({
        formComponent: "tab-list-model",
        name: `${lender?.data?.info?.name}`,
        width: "full",
        getTabName: (entity) => {
          return `Period: ${entity.from.month}-${entity.from.year} / ${entity.to.month}-${entity.to.year}`;
        },
        path: ["data", "info", "allocationPeriods", lender?._id],
        entity: {
          formComponent: "segment",
          width: "full",
          entities: [
            {
              formComponent: "segment",
              name: "From",
              width: "1/2",
              entities: [
                {
                  formComponent: "year-field",
                  name: "Month",
                  label: "Month",
                  width: "1/2",
                  path: ["from", "month"],
                  default: null,
                  max: 12,
                  min: 1,
                  required: true
                },
                {
                  formComponent: "year-field",
                  name: "Year",
                  label: "Year",
                  width: "1/2",
                  path: ["from", "year"],
                  default: null,
                  required: true
                }
              ]
            },
            {
              formComponent: "segment",
              name: "To",
              width: "1/2",
              entities: [
                {
                  formComponent: "year-field",
                  name: "Month",
                  label: "Month",
                  max: 12,
                  min: 1,
                  width: "1/2",
                  path: ["to", "month"],
                  default: null,
                  required: true
                },
                {
                  formComponent: "year-field",
                  name: "Year",
                  label: "Year",
                  width: "1/2",
                  path: ["to", "year"],
                  default: null,
                  required: true
                }
              ]
            },
            {
              formComponent: "list-model",
              name: "Custom Allocations(Per month)",
              entity: {
                formComponent: "segment",
                entities: [
                  {
                    formComponent: "year-field",
                    name: "CustomAllocationMonth",
                    label: "Month",
                    max: 12,
                    min: 1,
                    width: "1/3",
                    path: ["month"],
                    default: null,
                    required: true
                  },
                  {
                    formComponent: "year-field",
                    name: "CustomAllocationYear",
                    label: "Year",
                    width: "1/3",
                    path: ["year"],
                    default: null,
                    required: true
                  },
                  {
                    formComponent: "currency-field",
                    name: "Allocation",
                    label: "Allocation",
                    width: "1/3",
                    path: ["allocation"],

                    required: true,
                    default: null
                  }
                ]
              },
              width: "full",
              required: false,
              path: ["customAllocations"],
              renderOrder: "desc"
            }
          ]
        }
      }))
    }
  ]
});

const AllocationPeriods = ({ stateAccess, renderSet }: AllocationPeriodProps) => {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const lenderList =
    useSelector((state: RootState) => state.listLenderSlice[requestId])?.entities ?? [];

  useEffect(() => {
    dispatch(
      getLenderList(requestId, {
        options: { pagination: false, sort: { "data.info.name": "asc" } },
        query: { "data.info.allocation": true }
      })
    );
  }, []);

  return <>{generateForm(allocationStruct(lenderList), stateAccess, [], stateAccess, renderSet)}</>;
};
export default AllocationPeriods;
