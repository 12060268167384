import { Menu, MenuItem } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { HintTooltip } from "components/common/HintTooltip";
import { editDealStruct } from "components/Deals/model";
import interact from "interactjs";
import NestedMenuItem from "material-ui-nested-menu-item";
import { PDFDocumentProxy } from "pdfjs-dist";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { EntityData, EntityType } from "utils/entitySlice";
import { FormComponent, Path } from "utils/models/fields";
import { getByPath, StateAccess } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import SignaturePlacement from "./SignaturePlacement";
import { Draggable, DraggableInputWithId, Positions, PositionsWithName } from "./types";
import { File } from "components/Files/types";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const initialContextState = {
  mouseX: 0,
  mouseY: 0,
  open: false
};

const initialDropzoneCoordinates = {
  x: 0,
  y: 0
};

const positions: Positions = {};

type Coordinates = { x: number; y: number };

const getCoordinates = (ref: HTMLElement): Coordinates => {
  const bounding = ref.getBoundingClientRect();
  return { x: bounding.x + window.pageXOffset, y: bounding.y + window.pageYOffset };
};

const PlaceholderMenuItem = (
  draggable: Draggable,
  addDraggable: (item: DraggableInputWithId) => void,
  handleContextClose: () => void,
  lastContextPositionCoordinates: {
    mouseX: number;
    mouseY: number;
  },
  dropzoneCoordinates: {
    x: number;
    y: number;
  },
  open: boolean,
  acc: JSX.Element[] = [],
  dropzone: HTMLElement
): JSX.Element[] => {
  let id: string;

  switch (draggable.type) {
    case "input":
    case "virtual":
    case "currency":
      id = uuidv4();
      return acc.concat([
        <HintTooltip
          key={id}
          title={
            draggable.description ? (
              <span dangerouslySetInnerHTML={{ __html: draggable.description }} />
            ) : (
              ""
            )
          }
        >
          <MenuItem
            key={id}
            onClick={(): void => {
              const newPosition = {
                x:
                  lastContextPositionCoordinates.mouseX +
                  window.pageXOffset +
                  dropzone.scrollLeft -
                  dropzoneCoordinates.x,
                y:
                  lastContextPositionCoordinates.mouseY +
                  window.pageYOffset +
                  dropzone.scrollTop -
                  dropzoneCoordinates.y
              };

              addDraggable({
                id: id,
                path: draggable.path,
                label: draggable.label,
                tooltipLabel: draggable.tooltipLabel,
                type: draggable.type,
                ...newPosition
              });
              handleContextClose();
            }}
          >
            {draggable.label}
          </MenuItem>
        </HintTooltip>
      ]);
    case "input-date":
      const dateFormatsInfo = [
        { label: "Day", format: "day" },
        { label: "Month (Number)", format: "month-number" },
        { label: "Month (Text)", format: "month-text" },
        { label: "Year", format: "year" },
        { label: "Short Year", format: "short-year" },
        { label: "Full Date", format: "full-date" },
        { label: "MM-DD-YYYY", format: "mm-dd-yyyy" }
      ] as const;
      id = uuidv4();

      return acc.concat([
        <NestedMenuItem key={draggable.label} label={draggable.label} parentMenuOpen={open}>
          {dateFormatsInfo.map((el) => {
            return (
              <MenuItem
                key={id}
                onClick={(): void => {
                  const newPosition = {
                    x:
                      lastContextPositionCoordinates.mouseX +
                      window.pageXOffset +
                      dropzone.scrollLeft -
                      dropzoneCoordinates.x,
                    y:
                      lastContextPositionCoordinates.mouseY +
                      window.pageYOffset +
                      dropzone.scrollTop -
                      dropzoneCoordinates.y
                  };

                  addDraggable({
                    id: id,
                    path: draggable.path,
                    label: el.label,
                    tooltipLabel: draggable.tooltipLabel,
                    type: "input-date",
                    format: el.format,
                    ...newPosition
                  });
                  handleContextClose();
                }}
              >
                {el.label}
              </MenuItem>
            );
          })}
        </NestedMenuItem>
      ]);
    case "input-phone":
      const phoneFormatsInfo = [
        { label: "+1 (XXX) XXX-XXXX", format: "+1 (XXX) XXX-XXXX" },
        { label: "(XXX) XXX-XXXX", format: "(XXX) XXX-XXXX" }
      ] as const;
      id = uuidv4();

      return acc.concat([
        <NestedMenuItem key={draggable.label} label={draggable.label} parentMenuOpen={open}>
          {phoneFormatsInfo.map((el) => {
            return (
              <MenuItem
                key={id}
                onClick={(): void => {
                  const newPosition = {
                    x:
                      lastContextPositionCoordinates.mouseX +
                      window.pageXOffset +
                      dropzone.scrollLeft -
                      dropzoneCoordinates.x,
                    y:
                      lastContextPositionCoordinates.mouseY +
                      window.pageYOffset +
                      dropzone.scrollTop -
                      dropzoneCoordinates.y
                  };

                  addDraggable({
                    id: id,
                    path: draggable.path,
                    label: `${draggable.label} (${el.label})`,
                    tooltipLabel: draggable.tooltipLabel,
                    type: "input-phone",
                    format: el.format,
                    ...newPosition
                  });
                  handleContextClose();
                }}
              >
                {el.label}
              </MenuItem>
            );
          })}
        </NestedMenuItem>
      ]);
    case "segment":
      if (draggable.name !== null) {
        return acc.concat([
          <NestedMenuItem key={draggable.name} label={draggable.name} parentMenuOpen={open}>
            {draggable?.entities?.map((x) =>
              PlaceholderMenuItem(
                x,
                addDraggable,
                handleContextClose,
                lastContextPositionCoordinates,
                dropzoneCoordinates,
                open,
                [],
                dropzone
              )
            )}
          </NestedMenuItem>
        ]);
      } else {
        return acc.concat(
          draggable.entities.flatMap((x) =>
            PlaceholderMenuItem(
              x,
              addDraggable,
              handleContextClose,
              lastContextPositionCoordinates,
              dropzoneCoordinates,
              open,
              [],
              dropzone
            )
          )
        );
      }
    case "model":
      return acc.concat(
        draggable.entities.flatMap((x) =>
          PlaceholderMenuItem(
            x,
            addDraggable,
            handleContextClose,
            lastContextPositionCoordinates,
            dropzoneCoordinates,
            open,
            [],
            dropzone
          )
        )
      );
  }
};
export type VirtualFields = {
  [x: string]: (y: any) => any;
};
export const modelToVirtualMap = <T extends EntityType>(
  model: FormComponent<EntityData<T>>,
  pathSoFar: any = [],
  labelSoFar: string[] = []
): VirtualFields => {
  const name =
    typeof model.name === "function"
      ? model.name(({} as unknown) as StateAccess)
      : (model.name as string);
  switch (model.formComponent) {
    case "model":
      return model.entities.reduce(
        (acc, x) => ({ ...acc, ...modelToVirtualMap<T>(x, pathSoFar, labelSoFar) }),
        {}
      );
    case "segment":
      const newLabel =
        model.name !== null && model.name !== undefined ? labelSoFar.concat([name]) : labelSoFar;
      return model.entities.reduce(
        (acc, x) => ({
          ...acc,
          ...modelToVirtualMap(x, pathSoFar.concat((model.path as any) ?? []), newLabel)
        }),
        {}
      );
    case "one-to-many-field":
      if (model.hasOwnProperty("struct") && model.struct !== undefined) {
        return modelToVirtualMap(
          typeof model.struct === "function" ? model.struct() : model.struct,
          pathSoFar.concat((model.path as any) ?? []),
          labelSoFar.concat([model.name])
        );
      } else {
        return {};
      }
    case "virtual-field":
      return {
        [pathSoFar.concat((model.path as any) ?? []).join(".")]: (stateAccess) => {
          const x = stateAccess.get(pathSoFar);
          return model.value({
            get: (path: any) => getByPath(x, path),
            set: (path: any, value: any): any => null
          });
        }
      };
    default:
      return {};
  }
};

export const modelToDraggable = <T extends EntityType>(
  model: FormComponent<EntityData<T>>,
  pathSoFar: any = [],
  labelSoFar: string[] = []
): Draggable | null => {
  const name =
    typeof model.name === "function"
      ? model.name(({} as unknown) as StateAccess)
      : (model.name as string);
  switch (model.formComponent) {
    case "model":
      return {
        type: "model",
        name: name,
        entities: model.entities
          .map((x) => modelToDraggable(x, pathSoFar, labelSoFar))
          .filter((x): x is Draggable => x !== null)
      };
    case "list-model":
    case "tab-list-model":
    case "read-only-list-model":
      return null;
    case "segment":
      const newLabel = name !== null && name !== undefined ? labelSoFar.concat([name]) : labelSoFar;
      return {
        type: "segment",
        name: name ?? null,
        entities: model.entities
          .map((x) => modelToDraggable(x, pathSoFar.concat((model.path as any) ?? []), newLabel))
          .filter((x): x is Draggable => x !== null)
      };
    case "one-to-many-field":
      if (model.hasOwnProperty("struct") && model.struct !== undefined) {
        const { entities } = modelToDraggable(
          typeof model.struct === "function" ? model.struct() : model.struct,
          pathSoFar.concat((model.path as any) ?? []),
          labelSoFar.concat([name])
        ) as any;
        return {
          type: "segment",
          name: name,
          entities
        };
      } else {
        return null;
      }
    case "date-with-age-field":
    case "date-field":
      return {
        type: "input-date",
        path: pathSoFar.concat((model.path as any) ?? []),
        tooltipLabel: labelSoFar,
        label: model.label,
        x: 0,
        y: 0
      };
    case "phone-field":
      return {
        type: "input-phone",
        path: pathSoFar.concat((model.path as any) ?? []),
        tooltipLabel: labelSoFar,
        label: model.label,
        x: 0,
        y: 0
      };
    case "virtual-field":
      return {
        type: "virtual",
        description: model?.description,
        path: pathSoFar.concat((model.path as any) ?? []),
        tooltipLabel: labelSoFar,
        label: model.label,
        x: 0,
        y: 0
      };
    case "currency-field":
    case "read-only-number-field":
    case "percentage-field":
      return {
        type: "currency",
        path: pathSoFar.concat((model.path as any) ?? []),
        tooltipLabel: labelSoFar,
        label: model.label,
        x: 0,
        y: 0
      };

    default:
      return {
        type: "input",
        path: pathSoFar.concat((model.path as any) ?? []),
        tooltipLabel: labelSoFar,
        model: model,
        label: model.label,
        x: 0,
        y: 0
      };
  }
};

const inDropzone: PositionsWithName = {};

interface Props<T> {
  stateAccess: StateAccess;
  filePath: Path<T>;
  coordinatesPath: Path<T>;
}

export default <T extends unknown>({
  stateAccess,
  filePath,
  coordinatesPath
}: Props<T>): JSX.Element => {
  const [contextState, setContextState] = React.useState<{
    mouseX: number;
    mouseY: number;
    open: boolean;
  }>(initialContextState);

  const [choosenDragable, setChoosenDragable] = React.useState<DraggableInputWithId | undefined>(
    undefined
  );

  const [dropzoneCoordinates, setDropzoneCoordinates] = React.useState<{
    x: number;
    y: number;
  }>(initialDropzoneCoordinates);

  const dropzone = useRef(null);

  const handleContextClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();

    setContextState({
      mouseX: event.clientX,
      mouseY: event.clientY,
      open: true
    });
  };

  const handleContextClose = (): void => {
    setContextState(initialContextState);
  };

  const removeDraggable = (id: string): void => {
    if (window.confirm("Are you sure?")) {
      delete positions[id];

      stateAccess.set(
        coordinatesPath,
        stateAccess
          .get(coordinatesPath)
          .filter(({ id: itemId }: DraggableInputWithId) => itemId !== id)
      );
    }
  };

  useEffect(() => {
    const current = dropzone.current;
    if (current !== null) {
      const { x, y } = getCoordinates(current);
      setDropzoneCoordinates({ x, y });
    }
  }, [dropzone, setDropzoneCoordinates]);

  function downHandler(event: KeyboardEvent) {
    if (choosenDragable) {
      const key = event.key;
      if (["ArrowDown", "ArrowUp", "ArrowRight", "ArrowLeft"].includes(key)) {
        event.preventDefault();
        const id = choosenDragable.id;
        const position = { x: positions[id].x, y: positions[id].y };
        if (key === "ArrowDown") {
          position.y = position.y + 1;
        }
        if (key === "ArrowUp") {
          position.y = position.y - 1;
        }
        if (key === "ArrowRight") {
          position.x = position.x + 1;
        }
        if (key === "ArrowLeft") {
          position.x = position.x - 1;
        }

        stateAccess.set(
          coordinatesPath,
          (stateAccess.get(coordinatesPath) ?? []).map((draggable: DraggableInputWithId) =>
            draggable.id === id ? { ...draggable, ...position } : draggable
          )
        );
      }
      if (key === "d" && event.ctrlKey) {
        event.preventDefault();
        const newDraggable = {
          ...choosenDragable,
          id: uuidv4(),
          x: choosenDragable.x + 10,
          y: choosenDragable.y + 10
        };
        stateAccess.set(coordinatesPath, [
          ...(stateAccess.get(coordinatesPath) ?? []),
          newDraggable
        ]);

        setChoosenDragable(newDraggable);
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  });

  useEffect(() => {
    interact(".draggable").draggable({
      inertia: false,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: "parent"
        })
      ],
      listeners: {
        move(event): void {
          const id = event.target.dataset["id"];
          positions[id] = {
            x: positions[id].x + event.dx,
            y: positions[id].y + event.dy
          };

          event.target.style.transform = `translate(${positions[id].x}px, ${positions[id].y}px)`;
        },

        end(event): void {
          const id = event.target.dataset["id"];
          stateAccess.set(
            coordinatesPath,
            (stateAccess.get(coordinatesPath) ?? []).map((draggable: DraggableInputWithId) =>
              draggable.id === id
                ? { ...draggable, x: positions[id].x, y: positions[id].y }
                : draggable
            )
          );
        }
      }
    });

    interact(".dropzone").dropzone({
      accept: ".draggable",
      overlap: 0.75,
      ondragleave(event) {
        const id = event.relatedTarget.dataset["id"];
        delete inDropzone[id];
      },
      ondrop: (event) => {
        const { x, y } = getCoordinates(event.relatedTarget);
        const id = event.relatedTarget.dataset["id"];
        const path = event.relatedTarget.dataset["path"];

        inDropzone[id] = {
          path,
          x: x - dropzoneCoordinates.x,
          y: y - dropzoneCoordinates.y
        };
      }
    });
  }, [dropzoneCoordinates, stateAccess, coordinatesPath]);
  const [numPages, setNumPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy): void => {
    setNumPages(numPages);
  };
  const changeFontSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (choosenDragable) {
      setChoosenDragable({
        ...choosenDragable,
        fontSize: parseFloat(event.target.value) >= 8 ? event.target.value : "8"
      });
      stateAccess.set(
        coordinatesPath,
        (stateAccess.get(coordinatesPath) ?? []).map((draggable: DraggableInputWithId) =>
          draggable.id === choosenDragable.id
            ? {
                ...draggable,
                fontSize: parseFloat(event.target.value) >= 8 ? event.target.value : "8"
              }
            : draggable
        )
      );
    }
  };
  const changeRadioGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (choosenDragable) {
      setChoosenDragable({
        ...choosenDragable,
        radioGroup: Number(event.target.value) || 0
      });
      stateAccess.set(
        coordinatesPath,
        (stateAccess.get(coordinatesPath) ?? []).map((draggable: DraggableInputWithId) =>
          draggable.id === choosenDragable.id
            ? {
                ...draggable,
                radioGroup: Number(event.target.value) || 0
              }
            : draggable
        )
      );
    }
  };
  const changeSegmentSize = (prop: "height" | "width") => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (choosenDragable) {
      setChoosenDragable({
        ...choosenDragable,
        [prop]: parseFloat(event.target.value)
      });
      stateAccess.set(
        coordinatesPath,
        (stateAccess.get(coordinatesPath) ?? []).map((draggable: DraggableInputWithId) =>
          draggable.id === choosenDragable.id
            ? {
                ...draggable,
                [prop]: parseFloat(event.target.value)
              }
            : draggable
        )
      );
    }
  };
  const changeAdditionalProperties = (field: "required" | "initial" | "requiredToBeFilled") => {
    if (choosenDragable) {
      setChoosenDragable({
        ...choosenDragable,
        [field]: choosenDragable[field] ? !choosenDragable[field] : true
      });
      stateAccess.set(
        coordinatesPath,
        (stateAccess.get(coordinatesPath) ?? []).map((draggable: DraggableInputWithId) =>
          draggable.id === choosenDragable.id
            ? { ...draggable, [field]: draggable[field] ? !draggable[field] : true }
            : draggable
        )
      );
    }
  };

  return (
    <>
      <style>
        {`@media print {.dropzone{ overflow: visible !important; height: auto !important;}}`}
      </style>
      {choosenDragable && (
        <>
          Font size ({choosenDragable.label ?? ""}):{" "}
          <input
            type="number"
            min={8}
            value={
              choosenDragable.fontSize && parseInt(choosenDragable.fontSize) >= 8
                ? choosenDragable.fontSize
                : 12
            }
            onChange={changeFontSize}
          />
          {choosenDragable.path?.join("").includes("radio") && (
            <>
              Radio Group: :
              <input
                type="number"
                value={choosenDragable?.radioGroup ?? 0}
                onChange={changeRadioGroup}
              />
            </>
          )}
          <input
            type="checkbox"
            id="required"
            name="required"
            onChange={() => changeAdditionalProperties("required")}
            checked={choosenDragable?.required}
          />
          <label htmlFor="required">Is required?</label>
          {choosenDragable.path?.join("").includes("Signature") && (
            <>
              <input
                type="checkbox"
                id="initial"
                name="initial"
                onChange={() => changeAdditionalProperties("initial")}
                checked={choosenDragable?.initial}
              />
              <label htmlFor="initial">Is initial?</label>
            </>
          )}
          {(choosenDragable.path?.join("").includes("radio") ||
            choosenDragable.path?.join("").includes("inputFor") ||
            choosenDragable.path?.join("").includes("checkboxFor") ||
            choosenDragable.path?.join("").includes("datepickerFor") ||
            choosenDragable.path?.join("").includes("textareaFor")) && (
            <>
              {" "}
              width:
              <input
                type="number"
                value={choosenDragable.width ?? 50}
                onChange={changeSegmentSize("width")}
              />
              height:
              <input
                type="number"
                value={choosenDragable.height ?? 20}
                onChange={changeSegmentSize("height")}
              />
              <label htmlFor="requiredToBeFilled">Required To Be Filled?</label>
              <input
                type="checkbox"
                id="requiredToBeFilled"
                name="requiredToBeFilled"
                onChange={() => changeAdditionalProperties("requiredToBeFilled")}
                checked={choosenDragable?.requiredToBeFilled}
              />
            </>
          )}
          <span
            style={{
              cursor: "pointer",
              color: "red"
            }}
            onClick={(): void => choosenDragable && removeDraggable(choosenDragable?.id)}
          >
            <CancelIcon fontSize="small" />
          </span>
        </>
      )}
      <div
        onContextMenu={handleContextClick}
        className="dropzone"
        ref={dropzone}
        style={{
          backgroundSize: "cover",
          position: "relative",
          height: `500px`,
          overflowY: "scroll",
          overflowX: "hidden",
          width: "24cm",
          margin: "auto",
          border: "1px solid black"
        }}
      >
        <div style={{ position: "absolute" }}>
          <Document file={stateAccess.get(filePath)} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <>
                <div
                  style={{
                    height: `29.68cm`,
                    width: "21cm",
                    pageBreakAfter: "always",
                    overflow: "hidden",
                    margin: 0,
                    padding: 0
                  }}
                >
                  <Page
                    className="full-page"
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    width={1500}
                    height={1027}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                </div>
              </>
            ))}
          </Document>
        </div>
        {(stateAccess.get(coordinatesPath) ?? []).map((draggable: DraggableInputWithId) => {
          const transform = `translate(${draggable.x}px, ${draggable.y}px)`;
          positions[draggable.id] = draggable;
          return (
            <HintTooltip
              key={draggable.id}
              title={[...draggable.tooltipLabel, draggable.label].join("->")}
              placement="top"
            >
              <div
                style={{
                  display: "inline-block",
                  position: "absolute",
                  transform,
                  zIndex: 99999,
                  padding: 0,
                  fontSize: `${draggable.fontSize ?? 12}px`,
                  background: choosenDragable?.id === draggable.id ? "#00a8e92e" : "#e9e9002e"
                }}
                onClick={() => setChoosenDragable(draggable)}
                className="draggable"
                data-id={draggable.id}
                data-path={draggable.path}
              >
                {[
                  "Circle Signature Placement",
                  "Circle Signature Placement co Applicant Exists",
                  "Circle Line Signature Placement co Applicant Exists",
                  "Circle Line Signature Placement",
                  "Circle Signature Placement Co Applicant",
                  "Circle Line Signature Placement Co Applicant",
                  "Circle Signature Placement For Married CoApplicant",
                  "Circle Signature Placement For Married Applicant",
                  "Circle Signature Placement Applicant for Insurances",
                  "Circle Signature Placement Co Applicant for Insurances"
                ].includes(draggable.label ?? "") ? (
                  <SignaturePlacement
                    initial={draggable.initial}
                    fontSize={"40px"}
                    fontStyle={"italic"}
                    text={"O"}
                    line={[
                      "Circle Line Signature Placement",
                      "Circle Line Signature Placement Co Applicant"
                    ].includes(draggable.label ?? "")}
                  />
                ) : draggable.label === "Y Signature Placement" ? (
                  <SignaturePlacement fontSize={"36px"} text={"Y"} />
                ) : draggable.label === "Y Line Signature Placement" ? (
                  <SignaturePlacement fontSize={"36px"} text={"Y"} line={true} />
                ) : [
                    "Input For Applicant",
                    "Input For Married Applicant",
                    "Input For Co-Applicant",
                    "Input For Married Co-Applicant",
                    "Input For Dealership",
                    "Textarea For Applicant",
                    "Textarea For Co-Applicant",
                    "Textarea For Dealership",
                    "Checkbox For Applicant",
                    "Radio For Applicant",
                    "Radio For Co-Applicant",
                    "Datepicker For Applicant"
                  ].includes(draggable.label ?? "") ? (
                  <div
                    style={{
                      whiteSpace: "nowrap",

                      width:
                        parseInt(draggable?.width?.toString() ?? "") < 10
                          ? 10
                          : parseInt(draggable?.width?.toString() ?? ""),
                      height:
                        parseInt(draggable?.height?.toString() ?? "") < 10
                          ? 10
                          : parseInt(draggable?.height?.toString() ?? ""),
                      border: "1px solid black",
                      borderColor: draggable?.requiredToBeFilled ? "red" : "black"
                    }}
                  >
                    {draggable.label}
                  </div>
                ) : (
                  draggable.label
                )}
              </div>
            </HintTooltip>
          );
        })}

        <Menu
          open={contextState.open}
          anchorReference="anchorPosition"
          anchorPosition={
            contextState.mouseY !== null && contextState.mouseX !== null
              ? { top: contextState.mouseY, left: contextState.mouseX + 15 }
              : undefined
          }
          onClose={handleContextClose}
        >
          {PlaceholderMenuItem(
            modelToDraggable<"deal">(editDealStruct) as Draggable,
            (item) => {
              return stateAccess.set(
                coordinatesPath,
                (stateAccess.get(coordinatesPath) ?? []).concat([item])
              );
            },
            handleContextClose,
            contextState,
            dropzoneCoordinates,
            contextState.open,
            [],
            (dropzone.current as unknown) as HTMLElement
          )}
        </Menu>
      </div>
    </>
  );
};
