import Paper from "@material-ui/core/Paper";
import { RootState } from "app/rootReducer";
import filtersContext from "components/Content/FiltersContext";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getDealCountByProducts } from "./dealCountByProsuctsSlice";
interface Props {
  from: Date;
  to: Date;
  userIds: string[];
}
const idToCategory = (id: string) => {
  switch (id) {
    case "service warranty":
      return "Warranty";
    case "gap":
      return "GAP";
    case "gap,service warranty":
      return "Warranty and GAP";

    default:
      return "None";
  }
};
export default ({ from, to, userIds }: Props) => {
  const requestId = "dealCountByProducts";
  const dispatch = useDispatch();

  const categories = ["None", "Warranty", "GAP", "Warranty and GAP"];

  // @ts-ignore
  const state = useSelector(
    (state: RootState) => state.listDealCountByProductsSlice[requestId]
    // @ts-ignore
  )?.entities?.reduce((acc, { _id, count }) => ({ ...acc, [idToCategory(_id)]: count }), {});
  // @ts-ignore
  const items = [{ data: categories.map((status) => state?.[status] || 0) }];

  useEffect(() => {
    dispatch(
      getDealCountByProducts(requestId, {
        query: {
          from,
          to,
          userIds
        }
      })
    );
  }, [from, to]);
  return (
    <Paper elevation={3} style={{ height: "275px" }}>
      <ReactApexChart
        options={{
          tooltip: {
            y: {
              title: {
                formatter: function (val: any) {
                  return "";
                }
              }
            }
          },
          chart: {
            toolbar: {
              show: false
            }
          },
          legend: { offsetY: 50, position: "right" },
          plotOptions: {
            bar: { barHeight: "100%", distributed: true, horizontal: false }
          },
          colors: ["#eed238", "#33b2df", "#52a543", "#ffa500"],
          dataLabels: {
            dropShadow: { enabled: false },
            style: {
              colors: ["black"]
            }
          },
          stroke: { width: 1, colors: ["#fff"] },
          xaxis: { categories },
          yaxis: { labels: { show: true } },
          title: { text: `Deals count by products sold`, align: "center" }
        }}
        series={items}
        type="bar"
        height={250}
      />
    </Paper>
  );
};
