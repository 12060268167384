import Big from "big.js";
import {
  Columns,
  columns,
  DealReport,
  IncomeValues,
  Month,
  months,
  Months,
  Year,
  Years
} from "./types";

const defaultIncomeValues = () =>
  (({
    count: new Big(0),
    ...Object.fromEntries(columns.map((column) => [column, new Big(0)]))
  } as unknown) as IncomeValues & { count: Big });

const defaultYear = (): Year =>
  (Object.fromEntries(months.map((x) => [x, defaultIncomeValues()])) as unknown) as Year;

const updateMonth = (month: Month, deal: DealReport) => ({
  count: month.count.add(1),
  ...Object.fromEntries(columns.map((column) => [column, month[column].add(deal[column])]))
});

const percentageDifference = (x: Big, y: Big): Big => {
  if (y.eq(x)) {
    return new Big(0);
  }
  if (x.eq(0)) {
    return new Big(-100);
  }
  return x.sub(y).div(x).mul(100);
};

const fillDiff = (x: Years, year: number, month: Months | "annual") => {
  x[year.toString()][month].incomeChange = defaultIncomeValues();
  for (const column of columns) {
    x[year][month].incomeChange[column] = percentageDifference(
      x[year][month][column],
      x[year - 1] ? x[year - 1][month][column] : new Big(0)
    );
  }
};

const fillAverage = (afterReduce: Years, year: number) => {
  afterReduce[year].averages = (Object.fromEntries(
    columns.map((column) => {
      if (afterReduce[year].annual.count.eq(0)) {
        return [column, new Big(0)];
      }
      return [column, afterReduce[year].annual[column].div(afterReduce[year].annual.count)];
    })
  ) as unknown) as IncomeValues;
};

export const calculate = (result: DealReport[]) => {
  const afterReduce = result.reduce((acc, oldDeal) => {
    const deal = { ...oldDeal };
    deal.contractDate = new Date(deal.contractDate);
    const year = deal.contractDate.getFullYear();
    const month = deal.contractDate.getMonth() as Months;
    const yearObject = typeof acc[year] !== "undefined" ? acc[year] : defaultYear();
    const monthObject = yearObject[month];

    return {
      ...acc,
      [year]: {
        ...yearObject,
        [month]: updateMonth(monthObject, deal)
      }
    };
  }, {} as Years);

  for (const year of Object.keys(afterReduce).sort()) {
    const intYear = parseInt(year);
    afterReduce[year].annual = {
      incomeChange: defaultIncomeValues(),
      ...Object.entries(afterReduce[year])
        .filter(([month]) => month !== "averages")
        .reduce((acc, [month, x]) => {
          (["count", ...columns] as (Columns | "count")[]).forEach((column) => {
            acc[column] = acc[column].add((x as Month)[column]);
          });

          fillDiff(afterReduce, intYear, (month as unknown) as Months);
          return acc;
        }, defaultIncomeValues())
    };

    fillDiff(afterReduce, intYear, "annual");
    fillAverage(afterReduce, intYear);
  }

  return afterReduce;
};
