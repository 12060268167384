import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { RootState } from "app/rootReducer";
import { isAllowed } from "components/Access/AccessControl";
import Spinner from "components/Loader/Spinner";
import { getSettingsList, removeSettingsList } from "components/Settings/listSettingsSlice";
import {
  getTableSettingsList,
  removeTableSettingsList
} from "components/Table/TableSettings/listTableSettingsSlice";
import { addNewTab, genTabId, genTabProps, getTabUrl } from "components/Tabs/tabSlice";
import { Tab } from "components/Tabs/types";
import { SnackbarProvider, WithSnackbarProps } from "notistack";
import queryString from "query-string";
import React, { createContext, Dispatch, ReactText, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { capitalize } from "utils/functions";
import AppBar from "../AppBar/AppBar";
import Drawer from "../Drawer/Drawer";
import Loader from "../Loader/Loader";
import { SocketConnectionStatus } from "../Loader/SocketConnectionStatus";
import Main from "./Main";
import SnackbarEventHandler from "./SnackbarEventHandler";
import { isEntityTableUrls, isEntityUrls } from "./types";
import { useStickyState } from "index";
import ChatSocketConnection from "Chat/socket";
export type CreateOrFocusTabType = Omit<Omit<Tab, "_id">, "screenId">;
export const TabContext = createContext<(tabInfo: CreateOrFocusTabType) => void>(() => {});
export const ChatContext = createContext({
  chatOpen: false,
  openChat: () => {},
  closeChat: () => {}
});
const tabExists = (tabSliceState: RootState["tabSlice"], id: string) => {
  return tabSliceState.some((el) => el._id === id);
};

export interface ActiveTab {
  leftScreen: string;
  rightScreen: undefined | string;
}

const createOrFocusTab = (
  dispatch: Dispatch<any>,
  activeTab: ActiveTab,
  setActiveTab: React.Dispatch<React.SetStateAction<ActiveTab>>,
  tabSliceState: RootState["tabSlice"],
  navigate: any
) => (tabInfo: CreateOrFocusTabType) => {
  const { props, index } = tabInfo;
  const tabId = genTabId(index, props);
  if (!tabExists(tabSliceState, tabId)) {
    dispatch(addNewTab(tabInfo));
  }
  window.scrollTo(0, 0);
  // console.log(tabInfo, props, index, getTabUrl(props, index));
  //@ts-ignore
  if (!window?.event?.ctrlKey) {
    navigate.push(`${getTabUrl(props, index) ?? ""}`);
    setActiveTab({ ...activeTab, leftScreen: tabId });
  }
};

const getUrl = (entity?: any, param?: any) => {
  if (entity && param && isEntityUrls(entity)) return `/${entity}/${param}`;
  else if (entity && !param && isEntityTableUrls(entity)) return `/${entity}`;
  else return null;
};

const Layout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useHistory();
  const { entity, param } = useParams<{ entity?: string; param?: string }>();
  const currentUser = useSelector((state: RootState) => state.authSlice.user);
  const values = queryString.parse(location.search);
  if (values?.columnGroupId) {
    localStorage.setItem(
      "columnGroupData",
      JSON.stringify({
        columnGroupId: values?.columnGroupId,
        ...(values?.page ? { page: values?.page } : {}),
        ...(values?.dashboardType ? { dashboardType: values?.dashboardType } : {})
      })
    );
  }
  const notistackRef = React.createRef<WithSnackbarProps>();
  const [activeTab, setActiveTab] = useState<ActiveTab>({
    leftScreen: "dashboard",
    rightScreen: undefined
  });
  const tabSliceState = useSelector((state: RootState) => state.tabSlice);
  const createOrFocusTabWIthDeps = createOrFocusTab(
    dispatch,
    activeTab,
    setActiveTab,
    tabSliceState,
    navigate
  );
  const onClickDismiss = (key: ReactText) => () => {
    if (notistackRef !== null && notistackRef.current !== null) {
      notistackRef.current.closeSnackbar(key);
    }
  };
  useEffect(() => {
    if (
      !tabSliceState.some((tab) => tab._id === activeTab.leftScreen) &&
      tabSliceState.length > 0
    ) {
      setActiveTab({
        leftScreen: tabSliceState[tabSliceState.length - 1]._id,
        rightScreen: undefined
      });
    }
  }, [tabSliceState, setActiveTab, activeTab.leftScreen]);
  useEffect(() => {
    const tabUrl = getUrl(entity, param);

    if (tabUrl === null) {
      const dashboard = tabSliceState.find((el) => el.index === "dashboard");
      if (dashboard) createOrFocusTabWIthDeps(dashboard);
    } else if (entity && tabUrl) {
      const { props, index } = genTabProps(entity, param);
      createOrFocusTabWIthDeps({
        label: capitalize(index),
        index: index as any,
        isForSidebar: false,
        isForQuickAccess: false,
        isPersistent: false,
        props: props
      });
    }
  }, []);
  useEffect(() => {
    if (isAllowed({ entity: "settings", action: "read" }))
      dispatch(getSettingsList("settingsData"));
    dispatch(
      getTableSettingsList("table", {
        query: {
          "data.userId": currentUser?.databaseData?._id
        }
      })
    );
    return () => {
      dispatch(removeSettingsList("settingsData"));
      dispatch(removeTableSettingsList("table"));
      return;
    };
  }, [dispatch]);
  const [chatOpen, setShowChat] = useStickyState<boolean>(false, "openChat");
  return (
    <>
      <SocketConnectionStatus />
      <SnackbarProvider
        ref={notistackRef}
        autoHideDuration={values.test === "true" ? 2000 : 6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transitionDuration={values.test === "true" ? { appear: 0 } : undefined}
        action={(key) => {
          return (
            <IconButton
              id="close-snackbar"
              size="small"
              aria-label="close"
              color="inherit"
              onClick={onClickDismiss(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          );
        }}
        maxSnack={3}
      >
        <ChatSocketConnection>
          <>
            <Loader />
            <SnackbarEventHandler />
            <ChatContext.Provider
              value={{
                chatOpen: chatOpen,
                openChat: () => {
                  setShowChat(true);
                },
                closeChat: () => {
                  setShowChat(false);
                }
              }}
            >
              <TabContext.Provider value={createOrFocusTabWIthDeps}>
                <div style={{ display: "flex" }}>
                  {React.useMemo(
                    () => (
                      <>
                        <AppBar />
                        <Drawer createOrFocusTab={createOrFocusTabWIthDeps} />
                      </>
                    ),
                    [dispatch, activeTab, setActiveTab, tabSliceState]
                  )}

                  <Main activeTab={activeTab} setActiveTab={setActiveTab} />
                </div>
              </TabContext.Provider>
            </ChatContext.Provider>
          </>
        </ChatSocketConnection>
      </SnackbarProvider>
    </>
  );
};

export default function LayoutWrapper() {
  const { isAuthenticated, user } = useSelector((state: RootState) => state.authSlice);

  return (
    <>
      {isAuthenticated && (user?.permissions ?? false) ? (
        <Layout />
      ) : (
        <Spinner text="Checking permissions" />
      )}
    </>
  );
}
