import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState, FormStateToSend, BusinessReportType } from "./types";

interface EditSettingsData {
  _id: string;
  updatedAt: string;
  data: FormState;
}
interface EditSettingsRequest {
  _id: string;
  data: FormStateToSend;
}

interface EditSettingsResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditSettingsRequest,
  EditSettingsResponse
>("EditSettings", {
  request: "update_settings"
});

export const editSettingsActions = socketMessageSlice.actions;
export const editSettings = (request: EditSettingsData & Identifiable) => {
  const { updatedAt, ...rest } = request;

  const businessReportTypes: BusinessReportType[] = request.data.info.businessReportTypes.map(
    (b) => {
      const parameters = b.parameters ?? [];
      return {
        ...b,
        defaultParameters: {
          ...b.defaultParameters,
          defaultDealershipId: parameters.includes("dealership")
            ? b.defaultParameters?.defaultDealership?._id
            : null,
          defaultDealershipRequired: parameters.includes("dealership")
            ? b?.defaultParameters?.defaultDealershipRequired
            : false,
          defaultDealership: undefined,

          defaultLenderId: parameters.includes("lender")
            ? b.defaultParameters?.defaultLender?._id
            : null,
          defaultLenderRequired: parameters.includes("lender")
            ? b?.defaultParameters?.defaultLenderRequired
            : false,
          defaultLender: undefined,

          defaultRepresentativeId: parameters.includes("representative")
            ? b.defaultParameters?.defaultRepresentative?._id
            : null,
          defaultRepresentativeRequired: parameters.includes("representative")
            ? b?.defaultParameters?.defaultRepresentativeRequired
            : false,
          defaultRepresentative: undefined,

          defaultTimePeriod: parameters.includes("time period")
            ? b.defaultParameters?.defaultTimePeriod
            : undefined,
          defaultTimePeriodRequired: parameters.includes("time period")
            ? b.defaultParameters?.defaultTimePeriodRequired
            : false
        }
      };
    }
  );
  return socketAction({
    ...rest,
    data: {
      ...request.data,
      info: {
        ...request.data.info,
        businessReportTypes: businessReportTypes
      },
      entityAprovalRoles: {
        dealershipApprovalRoleIds:
          request.data?.entityAprovalRoles?.dealershipApprovalRoles?.map((r) => r._id) ?? [],
        contractApprovalRoleIds:
          request.data?.entityAprovalRoles?.contractApprovalRoles?.map((r) => r._id) ?? [],
        contractTypeApprovalRoleIds:
          request.data?.entityAprovalRoles?.contractTypeApprovalRoles?.map((r) => r._id) ?? [],
        lenderApprovalRoleIds:
          request.data?.entityAprovalRoles?.lenderApprovalRoles?.map((r) => r._id) ?? [],
        dealApprovalRoleIds:
          request.data?.entityAprovalRoles?.dealApprovalRoles?.map((r) => r._id) ?? [],
        applicantApprovalRoleIds:
          request.data?.entityAprovalRoles?.applicantApprovalRoles?.map((r) => r._id) ?? [],
        userApprovalRoleIds:
          request.data?.entityAprovalRoles?.userApprovalRoles?.map((r) => r._id) ?? [],
        dmvApprovalRoleIds:
          request.data?.entityAprovalRoles?.dmvApprovalRoles?.map((r) => r._id) ?? [],
        customReportApprovalRoleIds:
          request.data?.entityAprovalRoles?.customReportApprovalRoles?.map((r) => r._id) ?? [],
        documentTemplateApprovalRoleIds:
          request.data?.entityAprovalRoles?.documentTemplateApprovalRoles?.map((r) => r._id) ?? [],
        documentValidationApprovalRoleIds:
          request.data?.entityAprovalRoles?.documentValidationApprovalRoles?.map((r) => r._id) ??
          [],
        fileApprovalRoleIds:
          request.data?.entityAprovalRoles?.fileApprovalRoles?.map((r) => r._id) ?? [],
        payoffBankApprovalRoleIds:
          request.data?.entityAprovalRoles?.payoffBankApprovalRoles?.map((r) => r._id) ?? [],
        otherVendorApprovalRoleIds:
          request.data?.entityAprovalRoles?.otherVendorApprovalRoles?.map((r) => r._id) ?? [],
        stateApprovalRoleIds:
          request.data?.entityAprovalRoles?.stateApprovalRoles?.map((r) => r._id) ?? [],
        fundingDocumentApprovalRoleIds:
          request.data?.entityAprovalRoles?.fundingDocumentApprovalRoles?.map((r) => r._id) ?? [],
        lenderDecisionApprovalRoleIds:
          request.data?.entityAprovalRoles?.lenderApprovalRoles?.map((r) => r._id) ?? [],
        titleIssueApprovalRoleIds:
          request.data?.entityAprovalRoles?.titleIssueApprovalRoles?.map((r) => r._id) ?? []
      }
    }
  });
};
export default socketMessageSlice.reducer;
