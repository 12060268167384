import { CreditScoreRange } from "./types";
import { createListSlice } from "../Middlewares/listSliceCreator";

const { ListSlice, getList } = createListSlice<CreditScoreRange>({
  name: "CreditScoreRangeList",
  request_topic: "all_credit_score_ranges",
  reducers: {}
});
export default ListSlice.reducer;
export const getCreditScoreRangesList = getList;
export const listCreditScoreRangesActions = ListSlice.actions;
export const removeCreditScoreRangesList = ListSlice.actions.removeList;
