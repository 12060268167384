import { createListSlice } from "../Middlewares/listSliceCreator";
import { ManagerProgram } from "./types";

const { ListSlice, getList } = createListSlice<ManagerProgram>({
  name: "ManagerProgramList",
  request_topic: "all_manager_programs",
  reducers: {}
});

export default ListSlice.reducer;
export const getManagerProgramList = getList;
export const removeManagerProgramList = ListSlice.actions.removeList;
export const listManagerProgramActions = ListSlice.actions;
