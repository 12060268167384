import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { DealershipTicket } from "./types";
interface DeleteDealershipTicketRequest {
  _id: string;
}

interface DeleteDealershipTicketResponse {
  message: DealershipTicket;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteDealershipTicketRequest,
  DeleteDealershipTicketResponse
>("DeleteDealershipTicket", {
  request: "delete_dealership_ticket"
});

export const deleteDealershipTicketActions = socketMessageSlice.actions;
export const deleteDealershipTicket = socketAction;
export default socketMessageSlice.reducer;
