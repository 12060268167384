import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { DeleteOrRecoverContractRequest, DeleteOrRecoverContractResponse } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteOrRecoverContractRequest,
  DeleteOrRecoverContractResponse
>("DeleteContract", {
  request: "delete_contract"
});

export const deleteContractActions = socketMessageSlice.actions;
export const deleteContract = socketAction;
export default socketMessageSlice.reducer;
