import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import React from "react";
import formatDate from "utils/formatDate";
import { capitalize } from "utils/functions";
import { StateAccess } from "utils/models/formGenerator";
import { Deal } from "../types";
import { Checklist, SendEmails } from "../Checklist/types";

const DocumentsHistory = ({
  stateAccess,
  checklistStateAccess
}: {
  checklistStateAccess: StateAccess;
  stateAccess: StateAccess;
}) => {
  const deal: Deal = stateAccess.get([]);
  const documentsHistory = deal.data?.info?.documentsHistory ?? [];
  const emailHistory: SendEmails[] =
    checklistStateAccess.get<Checklist>(["data", "info", "sendEmails"]) ?? [];

  return (
    <Paper elevation={3} style={{ height: "100%", overflowY: "auto", border: "1px solid #cecece" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{
              background: "#e8e8e8",
              padding: "8px",
              fontWeight: "bold"
            }}
          >
            Generated packs history
          </Typography>
        </Grid>
        {documentsHistory?.map((x, index) => (
          <Grid item xs={12} key={index} container>
            <Grid
              item
              xs={12}
              style={{
                background: "#e8e8e8",
                borderRadius: "5px",
                margin: "5px",
                textAlign: "center"
              }}
            >
              <Typography variant="subtitle2">
                <span style={{ marginRight: "0.5em" }}>
                  {formatDate(x.printedAt, "short", true)}
                </span>
                {x.userEmail}
              </Typography>
            </Grid>
            {x.documents.map(({ url, packType }, index) => (
              <Grid item xs={6} key={index} style={{ paddingLeft: "10px" }}>
                <Typography variant="subtitle2">
                  <a href={`${url}`} target="_blank" rel="noopener noreferrer">
                    {`${capitalize(packType)} pack.pdf`}
                  </a>
                </Typography>
              </Grid>
            ))}
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{
              background: "#e8e8e8",
              padding: "8px",
              fontWeight: "bold"
            }}
          >
            Send emails history
          </Typography>
        </Grid>
        {emailHistory?.map((emailData, index) => (
          <Paper elevation={3} key={index} style={{ margin: 10 }}>
            <Grid item xs={12} container>
              <Grid
                item
                xs={12}
                style={{
                  background: "#e8e8e8",
                  padding: 5,
                  textAlign: "center"
                }}
              >
                <Typography variant="subtitle2">
                  <span>{formatDate(emailData.sendAt, "short", true)}</span>
                  {emailData.userEmail}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ padding: "5px" }}>
                <TextField
                  disabled={true}
                  label="To"
                  placeholder=""
                  margin="dense"
                  fullWidth
                  value={emailData?.recipients?.join(", ") ?? ""}
                />

                <TextField
                  disabled={true}
                  label="Subject"
                  placeholder=""
                  margin="dense"
                  fullWidth
                  value={emailData?.subject ?? ""}
                />
                <TextField
                  label="Body"
                  placeholder=""
                  margin="dense"
                  fullWidth
                  multiline
                  value={emailData?.body ?? ""}
                  disabled={true}
                />
                {(emailData?.attachments ?? [])?.map((attachment, index: number) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "3px",
                      paddingLeft: "5px"
                    }}
                    key={index}
                  >
                    <a
                      href={attachment.url || "#"}
                      style={{ marginRight: "5px", fontStyle: "italic", flex: "1" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {attachment.name || "Document name not found"}
                    </a>
                  </div>
                ))}
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Grid>
    </Paper>
  );
};

export default DocumentsHistory;
