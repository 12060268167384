import { createListSlice } from "../Middlewares/listSliceCreator";
import { OtherVendor } from "./types";

const { ListSlice, getList } = createListSlice<OtherVendor>({
  name: "OtherVendorsList",
  request_topic: "all_other_vendors",
  reducers: {}
});

export default ListSlice.reducer;
export const getOtherVendorList = getList;
export const removeOtherVendorList = ListSlice.actions.removeList;
export const listOtherVendorActions = ListSlice.actions;
