import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteCustomReportRequest {
  _id: string;
}

export interface DeleteCustomReportResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteCustomReportRequest,
  DeleteCustomReportResponse
>("DeleteCustomReport", {
  request: "delete_custom_report"
});

export const deleteCustomReportActions = socketMessageSlice.actions;
export const deleteCustomReport = socketAction;
export default socketMessageSlice.reducer;
