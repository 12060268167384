import { Deal } from "components/Deals/types";
import { createListSlice } from "../../Middlewares/listSliceCreator";

const { ListSlice, getList } = createListSlice<Deal>({
  name: "dealCommissionsByStatus",
  request_topic: "deals_commision_by_status",
  reducers: {}
});

export default ListSlice.reducer;
export const getDealCommissionByStatus = getList;
export const removeDealList = ListSlice.actions.removeList;
export const listDealActions = ListSlice.actions;
