import {
  Grid,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { Big } from "big.js";
import { DealStatus } from "components/Deals/types";
import ProgressBar from "components/ProgressBar/ProgressBar";
import {
  LenderAllocationStatistic,
  UserAllocationStatistic,
  ValueByStatus
} from "hooks/useLenderAllocationStatistics/types";
import React from "react";
import { formatNumberAsCurrency, typedObjectEntries } from "utils/functions";
import styles from "../../LenderDashboard.module.css";
import LenderChartByStatus from "./../LenderChartByStatus";
const calculateTotalAllocation = (statistics: UserAllocationStatistic[]) => {
  return statistics.reduce((acc, curr) => curr.totalAllocation + acc, 0);
};
const YearlyManagerTable = ({
  data,
  selectedStatuses
}: {
  selectedStatuses: DealStatus[];
  data: LenderAllocationStatistic | undefined;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const statistics2 = Object.values(data?.usersStatistics?.statistics ?? {})
    .map((x) =>
      Object.entries(x).reduce(
        (acc: UserAllocationStatistic, [key, curr]) => ({
          id: curr.id,

          names: curr.names,
          totalAmountFinanced: new Big(acc.totalAmountFinanced)
            .add(curr.totalAmountFinanced)
            .toNumber(),
          approvedAllocated: key === "approved" ? curr.allocated : acc.approvedAllocated,
          allocated: new Big(acc.allocated).add(curr.allocated).toNumber(),
          totalAllocation: curr.totalAllocation || 0,
          dealCount: new Big(acc.dealCount).add(curr.dealCount).toNumber()
        }),
        {
          id: "",
          names: "",
          totalAmountFinanced: 0,
          approvedAllocated: 0,
          allocated: 0,
          totalAllocation: 0,
          dealCount: 0
        }
      )
    )
    .sort((a, b) => a.names.localeCompare(b.names));
  const totals = statistics2.reduce(
    (acc, x) => ({
      totalAveragedAmountFinanced: new Big(acc.totalAveragedAmountFinanced)
        .add(new Big(x.totalAmountFinanced).div(x.dealCount || 1).round(2, 1))
        .toNumber()
    }),
    { totalAveragedAmountFinanced: 0 }
  );
  return (
    <Paper>
      <Grid container spacing={3} style={{ padding: "15px 0px" }}>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "20px" }}>
            Allocated financed amount by manager
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table aria-label="able" style={{ overflow: "hidden" }}>
              <TableHead
                style={{ background: "#e8e8e8", whiteSpace: "nowrap", textAlign: "center" }}
              >
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Allocation</TableCell>
                  <TableCell align="left">Percentage allocated</TableCell>
                  <TableCell align="left">Available</TableCell>
                  <TableCell align="left">Allocated</TableCell>
                  <TableCell align="left">Average amount financed</TableCell>
                  <TableCell align="left">Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statistics2?.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ fontWeight: 500, fontSize: 19 }} align="left">
                        {row.names ?? ""}
                      </TableCell>
                      <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                        {row?.totalAllocation
                          ? formatNumberAsCurrency(row.totalAllocation, "$")
                          : "No allocation"}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 400, fontSize: 16, cursor: "pointer" }}
                        align="left"
                      >
                        {row?.totalAllocation ? (
                          <>
                            <ProgressBar
                              percents={[
                                row?.totalAllocation
                                  ? new Big(row?.allocated || 0)
                                      .sub(new Big(row?.approvedAllocated || 0))
                                      .div(new Big(row?.totalAllocation || 1))
                                      .mul(new Big(100))
                                      .toNumber()
                                  : 0,
                                row?.totalAllocation
                                  ? new Big(row?.approvedAllocated)
                                      .div(new Big(row.totalAllocation || 1))
                                      .mul(100)
                                      .toNumber()
                                  : 0
                              ]}
                              onClick={handleOnClick}
                            />
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                              PaperProps={{
                                style: {
                                  width: 500
                                }
                              }}
                            >
                              <LenderChartByStatus
                                selectedStatuses={selectedStatuses}
                                data={typedObjectEntries(
                                  data?.usersStatistics?.statistics?.[row.id] ?? {}
                                ).reduce(
                                  (acc: ValueByStatus, [key, entry]) => ({
                                    ...acc,
                                    [key]: entry?.allocated || 0
                                  }),
                                  {} as ValueByStatus
                                )}
                                chartType="pie"
                                title="Amount financed by status"
                              />
                            </Menu>
                          </>
                        ) : (
                          "No allocation"
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: 400,
                          fontSize: 16,
                          color:
                            (row?.totalAllocation || 0) - (row.allocated || 0) < 0 ? "red" : "#000"
                        }}
                        align="left"
                      >
                        {row?.totalAllocation
                          ? formatNumberAsCurrency(row.totalAllocation - row.allocated, "$")
                          : "No allocation"}
                      </TableCell>
                      <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                        {formatNumberAsCurrency(row.allocated, "$")}
                      </TableCell>
                      <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                        {formatNumberAsCurrency(
                          new Big(row.totalAmountFinanced)
                            .div(row.dealCount || 1)
                            .round(2, 1)
                            .toNumber(),
                          "$"
                        )}
                      </TableCell>
                      <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                        {row.dealCount}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

              <TableFooter>
                <TableRow key={"totals"} style={{ background: "#E8E8E8", marginTop: "auto" }}>
                  <TableCell style={{ fontWeight: 500, fontSize: 19 }} align="left">
                    Total
                  </TableCell>

                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    {formatNumberAsCurrency(calculateTotalAllocation(statistics2), "$")}
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    {data?.totalAllocation ? (
                      <ProgressBar
                        percents={[
                          data?.totalAllocation
                            ? new Big(data?.totalAllocated || 0)
                                .sub(new Big(data?.allocatedAmount?.approved || 0))
                                .div(new Big(data?.totalAllocation || 1))
                                .mul(new Big(100))
                                .toNumber()
                            : 0,
                          data?.totalAllocation
                            ? new Big(data?.allocatedAmount?.approved || 0)
                                .div(new Big(data.totalAllocation || 1))
                                .mul(100)
                                .toNumber()
                            : 0
                        ]}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 400,
                      fontSize: 16,
                      color:
                        new Big(data?.totalAllocation || 0)
                          .sub(new Big(data?.totalAllocated || 0))
                          .toNumber() < 0
                          ? "red"
                          : "fff"
                    }}
                    align="left"
                  >
                    {formatNumberAsCurrency(
                      new Big(data?.totalAllocation || 0)
                        .sub(new Big(data?.totalAllocated || 0))
                        .toNumber(),
                      "$"
                    )}
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    {formatNumberAsCurrency(data?.totalAllocated, "$")}
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    {formatNumberAsCurrency(
                      new Big(totals.totalAveragedAmountFinanced)
                        .div(new Big(statistics2.length || 1))
                        .round(2, 1)
                        .toNumber(),
                      "$"
                    )}
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    {data?.totalDeals}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default YearlyManagerTable;
