import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  withStyles,
  capitalize,
  Toolbar,
  Typography
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

import formatDate from "utils/formatDate";
import { RootState } from "app/rootReducer";
import { getEntity } from "utils/entitySlice";
import Spinner from "components/Loader/Spinner";
import { StateAccess } from "utils/models/formGenerator";
import CloseDialogButton from "components/common/CloseDialogButton";

import { TitleDocumentInfo } from "./types";
import DocumentsSection from "./DocumentsSection";
type Props = {
  titleIssueId: string;
};
export default ({ titleIssueId }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const entity = useSelector(
    (state: RootState) => state.entitySlice["title_issue"]?.[titleIssueId]
  );

  useEffect(() => {
    if (titleIssueId) dispatch(getEntity("title_issue", titleIssueId));
  }, [titleIssueId]);

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleOpenDialog = () => {
    setOpen(true);
  };

  const stateAccess: StateAccess = {
    get: () => entity,
    set: (_, value): any => {}
  };

  return entity ? (
    <>
      <TitleIssueDocumentTable titleIssueInfo={entity?.data?.info} handleAdd={handleOpenDialog} />

      {open && (
        <Dialog
          fullWidth
          open={true}
          maxWidth="xl"
          id="document-upload-dialog"
          onClose={handleCloseDialog}
          aria-labelledby="document-upload-title"
        >
          <DialogTitle id="customized-dialog-title">Upload Title Information Documents</DialogTitle>
          <CloseDialogButton closeFunction={handleCloseDialog} />
          <DialogContent dividers>
            {entity ? (
              <Grid container xs={12}>
                <DocumentsSection stateAccess={stateAccess} />
              </Grid>
            ) : (
              <Spinner text="Loading..." />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary" variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  ) : (
    <></>
  );
};

const StyledTableCell = withStyles({
  root: {
    padding: "4px 8px"
  }
})(TableCell);

const StyledTableContainer = withStyles({
  root: {
    margin: "8px"
  }
})(TableContainer);

interface DocumentTableProps {
  titleIssueInfo: {
    receiptInfo: TitleDocumentInfo;
    titleRegistrationInfo: TitleDocumentInfo;
    oldTitleRegistrationInfo: TitleDocumentInfo;
  };
  handleAdd: () => void;
}

const TitleIssueDocumentTable: React.FC<DocumentTableProps> = ({ titleIssueInfo, handleAdd }) => {
  const documents = [
    { type: "Receipt Info", ...titleIssueInfo?.receiptInfo },
    { type: "Title Registration Info", ...titleIssueInfo?.titleRegistrationInfo },
    { type: "Old Title Registration Info", ...titleIssueInfo?.oldTitleRegistrationInfo }
  ];

  return (
    <Paper>
      <Toolbar>
        <Typography variant="body1" style={{ flexGrow: 1 }} component="div">
          Title Documents
        </Typography>
        <IconButton onClick={handleAdd}>
          <Add />
        </IconButton>
      </Toolbar>
      <StyledTableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>Document Type</StyledTableCell>
              <StyledTableCell>Document link</StyledTableCell>
              <StyledTableCell>WFD Document Used</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Uploaded Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.map((docInfo) =>
              docInfo?.documents?.map((document, index) => (
                <TableRow key={`${docInfo?.type}-${index}`}>
                  <StyledTableCell>{`${docInfo?.type} - ${index + 1}`}</StyledTableCell>
                  <StyledTableCell>
                    <Link href={document} target="_blank" rel="noopener noreferrer">
                      View Document
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell>{docInfo?.WFDDocumentUsed ? "Yes" : "No"}</StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        docInfo?.status === "rejected"
                          ? "red"
                          : docInfo?.status === "resolved"
                          ? "green"
                          : "orange"
                    }}
                  >
                    {capitalize(docInfo?.status)}
                  </StyledTableCell>
                  <TableCell>{formatDate(docInfo?.uploadedDate || "")}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Paper>
  );
};
