import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

interface DeleteNotificationRequest {
    requestId:string
  _id: string;
}

export interface DeleteNotificationResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteNotificationRequest,
  DeleteNotificationResponse
>("DeleteNotification", {
  request: "delete_notification"
});

export const deleteNotificationActions = socketMessageSlice.actions;
export const deleteNotification = socketAction;
export default socketMessageSlice.reducer;
