import { Deal } from "components/Deals/types";
import { createListSlice } from "../../Middlewares/listSliceCreator";

const { ListSlice, getList } = createListSlice<Deal>({
  name: "dealCountByProducts",
  request_topic: "deals_count_by_products",
  reducers: {}
});

export default ListSlice.reducer;
export const getDealCountByProducts = getList;
export const removeDealList = ListSlice.actions.removeList;
export const listDealActions = ListSlice.actions;
