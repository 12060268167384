import * as React from "react";
import { StateAccess, RenderSet, generateForm } from "utils/models/formGenerator";
import { collateralTypes, Deal } from "components/Deals/types";
import { createOptionsForSelect, FormComponent } from "utils/models/fields";
import { Lender } from "./types";
import { Grid, Typography } from "@material-ui/core";
import { getTargetPlatForms } from "components/DealerTrack/model";

const routeTypes = ["dealertrack", "appone", "custom"] as const;
export const isDefaultRoute = (stateAccess: StateAccess, routeType: typeof routeTypes[number]) => {
  const collateralType = stateAccess.get<Deal>(["data", "info", "type"]);
  return (
    stateAccess.get<Deal>([
      "data",
      "lender",
      "data",
      "info",
      "routes",
      collateralType,
      "defaultRoute"
    ]) === routeType
  );
};
export const generateFields = (
  collateralType: typeof collateralTypes[number]
): FormComponent<Lender> => {
  return {
    formComponent: "segment",
    width: "full",
    entities: [
      {
        formComponent: "select-field",
        name: "Default",
        label: "Default route",
        width: "1/2",
        path: ["data", "info", "routes", collateralType, "defaultRoute"],
        options: createOptionsForSelect({
          possibleValues: () => [...routeTypes],
          getOptionLabel: (x) => x?.charAt(0)?.toUpperCase() + x?.slice(1) ?? "",
          getSelectedOption: (x, y) => x === y
        }),
        default: null
      },
      {
        formComponent: "segment",
        width: "full",
        name: "Available routes",

        entities: [
          {
            formComponent: "checkbox-field",
            name: "Dealertrack",
            label: "Dealertrack",
            width: "full",
            valueType: "boolean",
            path: ["data", "info", "routes", collateralType, "dealertrack", "isAvailable"],
            isDisabled: () => false,
            isChecked: (stateAccess: StateAccess) =>
              stateAccess.get<Lender>([
                "data",
                "info",
                "routes",
                collateralType,
                "dealertrack",
                "isAvailable"
              ]),
            toggle: (stateAccess: StateAccess) =>
              stateAccess.set<Lender>(
                ["data", "info", "routes", collateralType, "dealertrack", "isAvailable"],
                !stateAccess.get<Lender>([
                  "data",
                  "info",
                  "routes",
                  collateralType,
                  "dealertrack",
                  "isAvailable"
                ])
              ),
            default: false
          },
          {
            formComponent: "select-field",
            name: "Account",
            label: "Account",
            width: "1/2",
            show: (stateAccess) =>
              stateAccess.get<Lender>([
                "data",
                "info",
                "routes",
                collateralType,
                "dealertrack",
                "isAvailable"
              ]),
            path: ["data", "info", "routes", collateralType, "dealertrack", "partyId"],
            options: createOptionsForSelect({
              possibleValues: () => getTargetPlatForms(),
              getOptionLabel: (x) => x,
              getSelectedOption: (x, y) => x === y
            }),
            default: null
          },
          {
            formComponent: "checkbox-field",
            name: "Appone",
            label: "Appone",
            width: "full",
            valueType: "boolean",
            path: ["data", "info", "routes", collateralType, "appone", "isAvailable"],
            isDisabled: () => false,
            isChecked: (stateAccess: StateAccess) =>
              stateAccess.get<Lender>([
                "data",
                "info",
                "routes",
                collateralType,
                "appone",
                "isAvailable"
              ]),

            toggle: (stateAccess: StateAccess) =>
              stateAccess.set<Lender>(
                ["data", "info", "routes", collateralType, "appone", "isAvailable"],
                !stateAccess.get<Lender>([
                  "data",
                  "info",
                  "routes",
                  collateralType,
                  "appone",
                  "isAvailable"
                ])
              ),
            default: false
          },
          {
            formComponent: "checkbox-field",
            name: "Custom",
            label: "Custom",
            width: "full",
            valueType: "boolean",
            path: ["data", "info", "routes", collateralType, "custom", "isAvailable"],
            isDisabled: () => false,
            isChecked: (stateAccess: StateAccess) =>
              stateAccess.get<Lender>([
                "data",
                "info",
                "routes",
                collateralType,
                "custom",
                "isAvailable"
              ]),

            toggle: (stateAccess: StateAccess) =>
              stateAccess.set<Lender>(
                ["data", "info", "routes", collateralType, "custom", "isAvailable"],
                !stateAccess.get<Lender>([
                  "data",
                  "info",
                  "routes",
                  collateralType,
                  "custom",
                  "isAvailable"
                ])
              ),
            default: false
          }
        ]
      }
    ]
  };
};

export default function ({
  stateAccess,
  renderSet
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) {
  const availableCollateralTypes = stateAccess.get(["data", "info", "collateralTypes"]) ?? {};

  return (
    <Grid container>
      {collateralTypes
        .filter((type) => availableCollateralTypes[type])
        .map((collateralType, index) => (
          <Grid item xs={3} key={index}>
            <Typography component="h6" color="primary">
              {collateralType}
            </Typography>
            <Grid item container>
              <Grid item xs={12}>
                {generateForm(
                  generateFields(collateralType),
                  stateAccess,
                  [],
                  stateAccess,
                  renderSet
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
}
