import { Deal } from "components/Deals/types";
import { createListSlice } from "../../Middlewares/listSliceCreator";

const { ListSlice, getList } = createListSlice<Deal>({
  name: "DealershipBillsList",
  request_topic: "all_dealership_bills_reports",
  reducers: {}
});

export default ListSlice.reducer;
export const getDealershipBillsList = getList;
export const listDealershipBillsActions = ListSlice.actions;
export const removeDealershipBillsList = ListSlice.actions.removeList;
