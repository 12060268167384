import { ApiStatus } from "./types";
import { permissionNode, relationPermissionNode, PermissionStruct } from "components/Roles/types";

export type ApiStatusPermissionsStruct = PermissionStruct<ApiStatus>;

export const apiStatusPermissionStruct: ApiStatusPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  id: permissionNode("Id"),
  name: permissionNode("Name"),
  status: permissionNode("Status"),
  description: permissionNode("Description"),
  created_at: permissionNode("Created At"),
  updated_at: permissionNode("Updated At")
};
