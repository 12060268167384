import { CustomRegexes } from "../../helpers";

export default {
  name: { type: "string" },
  tiers: {
    type: "array",
    nullable: true,
    items: {
      type: "object",
      nullable: true,
      properties: {
        conditions: {
          type: "array",
          nullable: true,
          items: {
            type: "object",
            nullable: true,
            properties: {
              criteria: {
                type: ["string", "null"],
                enum: ["deals count", "deals amount financed", null],
                nullable: true,
                errorMessage: {
                  enum: "must be one of the following: 'deals count' or 'deals amount financed'",
                },
              },
              type: { type: "string", nullable: true },
              value: { type: "number", nullable: true },
            },
          },
        },
        commisionAmount: { type: "number", nullable: true },
        commissionType: {
          type: ["string", "null"],
          enum: ["percentage", "fixed", null],
          nullable: true,
          errorMessage: {
            enum: "must be one of the following: 'percentage' or 'fixed'",
          },
        },
      },
    },
  },
};
