import { Schema } from "ajv";

const data: Schema = {
  type: "object",
  properties: { params: { type: "object", additionalProperties: true } },
  additionalProperties: true,
};

export default {
  protective_get_dealer_details: {
    $id: "protectiveGetDealerDetails",
    type: "object",
    properties: { data: {} },
  },
  protective_get_rv_rates: {
    $id: "protectiveGetRvRates",
    type: "object",
    properties: { data: data },
  },
  protective_get_marine_rates: {
    $id: "protectiveGetMarineRates",
    type: "object",
    properties: { data: data },
  },
  protective_get_contract_numbers: {
    $id: "protectiveGetContractNumbers",
    type: "object",
    properties: { data: data },
  },
  protective_submit_rv_contracts: {
    $id: "protectiveSubmitRvContracts",
    type: "object",
    properties: { data: data },
  },
  protective_delete_contract: {
    $id: "protectiveDeleteContract",
    type: "object",
    properties: { data: data },
  },
};
