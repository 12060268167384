import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  useMediaQuery
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import lender from "_tests_/deals/lender";
import { RootState } from "app/rootReducer";
import { DealStatus } from "components/Deals/types";
import { getLenderList, removeLenderList } from "components/Lenders/listLenderSlice";
import {
  Lender,
  LifetimeAllocationPeriod,
  MonthlyAllocationPeriod,
  YearlyAllocationPeriod
} from "components/Lenders/types";
import { LenderAllocationStatisticRequest } from "hooks/useLenderAllocationStatistics/types";
import { useStickyState } from "index";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, getFirstAndLastDateOfGivenYearAndMonth, parseMonth } from "utils/functions";
import { v4 as uuidv4 } from "uuid";
import LifetimeDashboard from "./Lifetime/LifetimeDashboard";
import MonthlyDashboard from "./Monthly/MonthlyDashboard";
import YearlyDashboard from "./Yearly/YearlyDashboard";

export type ToggleState = "yearly" | "monthly" | "lifetime" | undefined;

export const generateYearArray = () =>
  Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - 4 + i).reverse();
const MonthYearToggle = ({
  requestState,
  setRequestState,
  selected,
  setSelected,
  lender
}: {
  requestState: LenderAllocationStatisticRequest;
  setRequestState: React.Dispatch<React.SetStateAction<LenderAllocationStatisticRequest>>;
  selected: ToggleState;
  setSelected: (newState: ToggleState) => void;
  lender: Lender | undefined;
}) => {
  const classes = useStyles({ selected });
  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>, type: "year" | "month") => {
    const period = { ...requestState, [type]: Number(e.target.value) };
    setRequestState((prevRequestState) => ({
      ...prevRequestState,
      [type]: Number(e.target.value),
      ...fromToByPeriod(lender, selected, period.year, period.month)
    }));
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          minWidth: "289px",
          justifyContent: "flex-end"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
            padding: "0px 10px",
            ...(selected !== "lifetime" ? { border: "1px solid #2b4e70" } : {}),
            flexDirection: "row",
            flexGrow: 3,
            width: "140px"
          }}
        >
          {selected === "monthly" ? (
            <div style={{ display: "flex", gap: "5px", alignItems: "center", width: "100%" }}>
              <select
                value={requestState.year}
                className={classes.periodSelect}
                onChange={(e) => handlePeriodChange(e, "year")}
              >
                {generateYearArray().map((yr, i) => (
                  <option value={yr} key={i}>
                    {yr}
                  </option>
                ))}
              </select>
              <select
                value={requestState.month}
                className={classes.periodSelect}
                onChange={(e) => handlePeriodChange(e, "month")}
              >
                {Array.from({ length: 12 }, (_, i) => (
                  <option value={i + 1} key={i}>
                    {parseMonth(i + 1)}
                  </option>
                ))}
              </select>
            </div>
          ) : selected === "yearly" ? (
            <div style={{ display: "flex", gap: "5px", alignItems: "center", width: "100%" }}>
              <select
                style={{ width: "100%" }}
                value={requestState.year}
                className={classes.periodSelect}
                onChange={(e) => handlePeriodChange(e, "year")}
              >
                {generateYearArray().map((yr, i) => (
                  <option value={yr} key={i}>
                    {yr}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
        </div>
        {(lender?.data?.info?.lifetimeAllocationPeriods?.length || 0) > 0 && (
          <div
            style={{ flexGrow: 1 }}
            className={[classes.periodToggle, classes.lifetimeToggle].join(" ")}
            onClick={() => setSelected("lifetime")}
          >
            LF
          </div>
        )}
        {(lender?.data?.info?.yearlyAllocationPeriods?.length || 0) > 0 && (
          <div
            style={{ flexGrow: 1 }}
            className={[classes.periodToggle, classes.yearlyToggle].join(" ")}
            onClick={() => setSelected("yearly")}
          >
            {/* it is actually period to date(PTD) */}
            YTD
          </div>
        )}
        {(lender?.data?.info?.monthlyAllocationPeriods?.length || 0) > 0 && (
          <div
            style={{ flexGrow: 1 }}
            className={[classes.periodToggle, classes.timeframeToggle].join(" ")}
            onClick={() => setSelected("monthly")}
          >
            MTD
          </div>
        )}
      </div>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxLabel: {
      fontSize: "12px"
    },
    periodToggle: {
      display: "flex",

      padding: 15,
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
    periodSelect: {
      appearance: "none" as const,
      WebkitAppearance: "none" as const,
      MozAppearance: "none" as const,
      border: "none",
      background: "#ffffff",
      flexGrow: 1
    },
    yearlyToggle: {
      color: (props: { selected: ToggleState }) => (props.selected === "yearly" ? "#fff" : "#000"),
      background: (props: { selected: ToggleState }) =>
        props.selected === "yearly" ? "#2B4E70" : "#E8E8E8"
    },
    timeframeToggle: {
      color: (props: { selected: ToggleState }) => (props.selected === "monthly" ? "#fff" : "#000"),
      background: (props: { selected: ToggleState }) =>
        props.selected === "monthly" ? "#2B4E70" : "#E8E8E8"
    },
    lifetimeToggle: {
      color: (props: { selected: ToggleState }) =>
        props.selected === "lifetime" ? "#fff" : "#000",
      background: (props: { selected: ToggleState }) =>
        props.selected === "lifetime" ? "#2B4E70" : "#E8E8E8"
    }
  })
);
const defaultStatuses = [
  DealStatus.Approved,
  DealStatus.Funded,
  DealStatus.FundingHeld,
  DealStatus.SentToDealer,
  DealStatus.SubmittedForFunding
];
const EAST_WISCONSIN_SAVINGS_BANK_ID = "5f888b8086ce1d1b6441c0fc";
const getLastAllocationPeriodOfLender = (periods: LifetimeAllocationPeriod[] | undefined) =>
  (periods ?? [])
    ?.sort(
      (a, b) =>
        new Date(a?.to?.year || 3000, a?.to?.month || 1).getTime() -
        new Date(b?.to?.year || 3000, b?.to?.month || 1).getTime()
    )
    .at(-1);

const fromToByPeriod = (
  lender: Lender | undefined,
  period: ToggleState,
  year: number,
  month: number
) => {
  switch (period) {
    case "monthly":
      const { from, to } = getFirstAndLastDateOfGivenYearAndMonth(year, month);
      return { from: from.toISOString(), to: to.toISOString() };
    case "yearly":
      return {
        from: new Date(year, 0, 0, 0, 0, 0).toISOString(),
        to: new Date(
          year,
          year === new Date().getFullYear() ? new Date().getMonth() + 1 : 12,
          1,
          0,
          0,
          0
        ).toISOString()
      };
    case "lifetime":
      const period = getLastAllocationPeriodOfLender(lender?.data.info.lifetimeAllocationPeriods);
      return {
        from: period
          ? new Date(period.from.year, period.from.month - 1, 1).toISOString()
          : new Date().toISOString(),
        to:
          period?.to?.year && period?.to?.month
            ? new Date(period.to.year, period.to.month, 0).toISOString()
            : new Date(3000, 1, 1).toISOString()
      };
  }
};

const getPeriodsByToggle = (
  toggle: ToggleState,
  lender: Lender
): (MonthlyAllocationPeriod | YearlyAllocationPeriod | LifetimeAllocationPeriod)[] | undefined => {
  switch (toggle) {
    case "monthly":
      return lender?.data?.info?.monthlyAllocationPeriods ?? [];

    case "yearly":
      return lender?.data?.info?.yearlyAllocationPeriods ?? [];

    case "lifetime":
      return lender?.data?.info?.lifetimeAllocationPeriods ?? [];

    default:
      return undefined;
  }
};
const handleSetToggleByLenderPeriod = (
  lender: Lender,
  state: LenderAllocationStatisticRequest,
  toggle: ToggleState,
  setToggle: React.Dispatch<React.SetStateAction<ToggleState>>,
  setState: React.Dispatch<React.SetStateAction<LenderAllocationStatisticRequest>>
) => {
  const period = getPeriodsByToggle(
    toggle || lender.data.info.allocationType,
    lender
  )?.find((period) =>
    period?.to?.year && period?.to?.month
      ? new Date().getTime() >= new Date(period.from.year, period.from.month - 1).getTime() &&
        new Date().getTime() <= new Date(period?.to?.year, period?.to?.month - 1).getTime()
      : new Date().getTime() >= new Date(period.from.year, period.from.month - 1).getTime()
  );
  if (period) {
    setToggle((old) => (!old ? lender.data.info.allocationType : old));
    setState({
      ...state,
      lenderId: lender._id,
      ...fromToByPeriod(lender, toggle, state.year, state.month)
    });
  }
};

const handleSetToggle = (
  period: ToggleState,
  lender: Lender | undefined,
  state: LenderAllocationStatisticRequest,
  setToggle: React.Dispatch<React.SetStateAction<ToggleState>>,
  setState: React.Dispatch<React.SetStateAction<LenderAllocationStatisticRequest>>
) => {
  setToggle(period);
  const { from, to } = getFirstAndLastDateOfGivenYearAndMonth(state.year, state.month);
  setState({
    ...state,
    ...fromToByPeriod(lender, period, state.year, state.month)
  });
};
export default function LenderAllocationDashbord({
  setResetStickyCallback
}: {
  setResetStickyCallback?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}) {
  const matches = useMediaQuery("(min-width:800px)");
  const [requestId] = useState(uuidv4());
  const dispatch = useDispatch();
  const listId = `list-autocomplete-lender-statistics-${requestId}`;
  const lenderList = useSelector((state: RootState) => state.listLenderSlice[listId]);

  const [periodToggle, setToggle] = useStickyState<ToggleState>(
    undefined,
    "allocation_dashboard_periodToggle"
  );

  const [lender, setLender] = useStickyState<Lender | undefined>(
    undefined,
    "allocation_dashboard_lender"
  );
  React.useEffect(() => {
    if (!lenderList)
      dispatch(
        getLenderList(listId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } },
          query: { "data.info.allocation": true }
        })
      );
    return () => {
      dispatch(removeLenderList(listId));
      return;
    };
  }, []);
  React.useEffect(() => {
    const defaultLender = (lenderList?.entities ?? [])?.find(
      (lender) => lender._id === (state.lenderId || EAST_WISCONSIN_SAVINGS_BANK_ID)
    );
    if (defaultLender) {
      setLender(defaultLender);
      handleSetToggleByLenderPeriod(defaultLender, state, periodToggle, setToggle, setState);
    }
  }, [lenderList]);

  const classes = useStyles({ selected: periodToggle });
  const [state, setState] = useStickyState<LenderAllocationStatisticRequest>(
    {
      year: new Date().getUTCFullYear(),
      lenderId: "",
      allocationType: periodToggle,
      statuses: defaultStatuses,
      month: new Date().getMonth() + 1,
      from: new Date(new Date().getUTCFullYear(), 0, 0, 0, 0, 0).toISOString(),
      to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString()
    },
    "allocationDashboardState"
  );
  const [viewDealsButton, setViewDealsButton] = useState<JSX.Element | null>(null);

  const handleResetStickyState = () => {
    setLender(undefined);
    setToggle(undefined);
    setState({
      year: new Date().getUTCFullYear(),
      lenderId: "",
      allocationType: periodToggle,
      statuses: defaultStatuses,
      month: new Date().getMonth() + 1,
      from: new Date(new Date().getUTCFullYear(), 0, 0, 0, 0, 0).toISOString(),
      to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString()
    });
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, []);
  return (
    <>
      {setResetStickyCallback === undefined && (
        <Button
          onClick={() => handleResetStickyState()}
          style={{ margin: "5px 0px", float: "right" }}
          color="primary"
          variant="contained"
        >
          Reset state
        </Button>
      )}
      <Grid container xs={12} spacing={0} style={{ margin: "10px 0px" }}>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            style={matches ? {} : { display: "flex", flexDirection: "column", gap: 0 }}
          >
            <Grid
              style={{
                fontWeight: "500",
                display: "inline-flex",
                padding: "0px 14px",

                verticalAlign: "middle",
                alignItems: "center",
                background: "rgb(224 224 224)",
                borderRadius: "4px 0px 0px 4px "
              }}
            >
              <FormControlLabel
                classes={{ label: classes.checkboxLabel }}
                control={
                  <Checkbox
                    indeterminate={
                      state?.statuses?.length > 0 &&
                      state?.statuses?.length !== defaultStatuses.length
                    }
                    checked={state?.statuses?.length === defaultStatuses.length}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: defaultStatuses
                        }));
                      } else {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: []
                        }));
                      }
                    }}
                    name="All"
                    color="primary"
                  />
                }
                label={`All statuses`}
              />
            </Grid>

            {defaultStatuses.map((status, index) => (
              <FormControlLabel
                classes={{ label: classes.checkboxLabel }}
                style={{ marginLeft: "4px" }}
                key={index}
                control={
                  <Checkbox
                    onChange={(event) => {
                      if (event.target.checked) {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: [...(prevState.statuses ?? []), status]
                        }));
                      } else {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: prevState.statuses.filter((prevStatus) => prevStatus !== status)
                        }));
                      }
                    }}
                    checked={state?.statuses?.findIndex((prevStatus) => prevStatus === status) > -1}
                    name={capitalize(status)}
                    color="primary"
                  />
                }
                label={capitalize(status)}
              />
            ))}
          </Paper>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
          <Grid container spacing={2}>
            <Grid item md={2} xs={12}>
              <Autocomplete
                style={{ zIndex: 6 }}
                value={lender || null}
                getOptionLabel={(lender) => lender.data.info.name ?? ""}
                getOptionSelected={(x, y) => x._id === y._id}
                options={lenderList?.entities ?? []}
                onChange={(event, newValue) => {
                  if (newValue !== null && typeof newValue !== "string") {
                    setLender(newValue);
                    handleSetToggleByLenderPeriod(
                      newValue,
                      state,
                      periodToggle,
                      setToggle,
                      setState
                    );
                  }
                }}
                loading={!Array.isArray(lenderList?.entities)}
                openOnFocus
                id="lender-statistics-select"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ ...params.InputProps }}
                    label={"Lender"}
                    variant="filled"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              {viewDealsButton}
            </Grid>

            <Grid
              item
              md={2}
              xs={12}
              style={
                matches
                  ? {
                      marginLeft: "auto",
                      justifyContent: "flex-end",
                      display: "flex"
                    }
                  : { display: "flex" }
              }
            >
              <MonthYearToggle
                requestState={state}
                setRequestState={setState}
                selected={periodToggle}
                setSelected={(newState) =>
                  handleSetToggle(newState, lender, state, setToggle, setState)
                }
                lender={lender}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {periodToggle === "lifetime" ? (
        <LifetimeDashboard
          lender={lender}
          state={state}
          periodToggle={periodToggle}
          setViewDealsButton={setViewDealsButton}
        />
      ) : periodToggle === "yearly" ? (
        <YearlyDashboard
          lender={lender}
          state={state}
          periodToggle={periodToggle}
          setViewDealsButton={setViewDealsButton}
        />
      ) : periodToggle === "monthly" ? (
        <MonthlyDashboard
          lender={lender}
          state={state}
          periodToggle={periodToggle}
          setViewDealsButton={setViewDealsButton}
        />
      ) : (
        <></>
      )}
    </>
  );
}
