import { createListSlice } from "../Middlewares/listSliceCreator";
import { CustomReport } from "./types";

const { ListSlice, getList } = createListSlice<CustomReport>({
  name: "CustomReportList",
  request_topic: "all_custom_reports",
  reducers: {}
});

export default ListSlice.reducer;
export const getCustomReportList = getList;
export const listCustomReportActions = ListSlice.actions;
