import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

export default {
  get_floorplanxpress: {
    $id: "getFloorplanxpress",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_floorplanxpress: {
    $id: "allFloorplanxpress",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
