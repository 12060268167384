import { queryClient } from "app/App";
import { Channel } from "Chat/types/channels";
import { Message } from "Chat/types/messages";
import { Deal, Applicant } from "components/DealerTrack/types";
import { Lender } from "components/Lenders/types";
import { User } from "components/Users/types";
import { onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { useBrowserNotifications } from "Chat/hooks/useBrowserNotifications";
import { useSnackbar } from "notistack";

type Payload = {
  action: "create" | "update";
  entity:
    | "deal"
    | "applicant"
    | "user"
    | "lender"
    | "document"
    | "collateral"
    | "channel"
    | "message";
  entry: Deal | Applicant | Document | Lender | User | Channel | Message;
};

export const getChatSocket = (
  currentUser: FirebaseUser | null,
  showNotification: (title: string, options?: NotificationOptions) => void
) => {
  return currentUser?.getIdToken().then(async (token) => {
    const socket = io(process.env.REACT_APP_SOCKET_URL + "/admin_chat", {
      auth: {
        token,
        query: { connectedAtChat: new Date().getTime() },
        firebaseName: process.env.REACT_APP_FIREBASE_PROJECT_ID
      }
    });
    socket.on("connect", () => {
      console.log("Chat Connected!");
    });
    socket.on(`${currentUser?.email}_live_updates`, (payload: Payload) => {
      switch (payload.action) {
        case "create":
          switch (payload.entity) {
            case "channel": {
              const entry = payload.entry as Channel;
              queryClient.setQueriesData<Channel[]>(["channels"], (oldData) => {
                if (oldData?.find((x) => x._id === entry._id)) return oldData;
                return [entry, ...(oldData ?? [])];
              });
              break;
            }
            case "message": {
              const entry = payload.entry as Message;
              queryClient.setQueryData<Message[]>(
                [`messages-${entry.data.channelId}`],
                (oldData) => {
                  const foundIndex = (oldData ?? [])?.findIndex(
                    (oldEntry) =>
                      oldEntry?.data?.internalMessageId === entry?.data?.internalMessageId ||
                      oldEntry?._id === entry?._id
                  );
                  if (foundIndex >= 0) {
                    return oldData?.map((x, index) =>
                      index === foundIndex
                        ? {
                            ...entry,
                            data: {
                              ...entry.data,
                              info: {
                                ...entry.data.info,
                                attachments:
                                  x?.data?.info?.attachments || entry?.data?.info?.attachments || []
                              }
                            }
                          }
                        : x
                    );
                  } else {
                    const currentChat = JSON.parse(
                      window.localStorage.getItem("currentChat") as string
                    );
                    const chatNotificationsEnabled = JSON.parse(
                      window.localStorage.getItem("chatNotificationsEnabled") as string
                    );
                    if (
                      entry?.data?.channelId !== currentChat?.channelId &&
                      chatNotificationsEnabled
                    )
                      showNotification(
                        `New message from: ${entry?.data?.chatMember?.data?.info?.names}.`
                      );
                    return [...(oldData ?? []), entry];
                  }
                }
              );
              queryClient.setQueriesData<Channel[]>(["channels"], (oldData) => {
                const channelIndex = (oldData ?? [])?.findIndex(
                  (x) => x._id === entry.data.channelId
                );
                if (channelIndex >= 0 && oldData) {
                  oldData.unshift(oldData.splice(channelIndex, 1)[0]);
                }
                return oldData;
              });
              break;
            }
            default:
              break;
          }
          break;
        case "update":
          switch (payload.entity) {
            case "message": {
              const entry = payload.entry as Message;
              queryClient.setQueriesData<Message[]>(
                [`messages-${entry.data.channelId}`],
                (oldData) => {
                  const foundIndex = (oldData ?? [])?.findIndex(
                    (oldEntry) => oldEntry?._id === entry?._id
                  );
                  return oldData?.map((x, index) => (index === foundIndex ? entry : x));
                }
              );
              break;
            }
            case "deal": {
              const entry = payload.entry as Deal;
              queryClient.setQueriesData<Deal>(["deal", entry._id], () => {
                return entry;
              });
              queryClient.setQueriesData<Deal[]>(["deals"], (oldData) => {
                return (oldData ?? [])?.map((oldDeal) =>
                  oldDeal._id === entry._id ? entry : oldDeal
                );
              });
              break;
            }
            case "channel": {
              const entry = payload.entry as Channel;
              queryClient.setQueriesData<Channel[]>(["channels"], (oldData) => {
                const channelIndex = (oldData ?? [])?.findIndex((x) => x._id === entry._id);
                if (channelIndex >= 0) {
                  return oldData?.map((x, index) => (index === channelIndex ? entry : x));
                } else {
                  return [...(oldData ?? []), entry];
                }
              });
              break;
            }
            default:
              break;
          }
          break;

        default:
          break;
      }
    });
    return socket;
  });
};

const ChatSocketConnection = ({ children }: { children: JSX.Element }) => {
  const [currentUser, setCurrentUser] = useState<FirebaseUser | null>(null);

  const { showNotification } = useBrowserNotifications();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });
  }, []);
  useEffect(() => {
    const socket = getChatSocket(currentUser, showNotification);

    return () => {
      socket?.then((resolvedSocket) => resolvedSocket.close());
    };
  }, [currentUser]);
  return children;
};

export default ChatSocketConnection;
