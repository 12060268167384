import { Deal } from "components/Deals/types";
import { createListSlice } from "../../Middlewares/listSliceCreator";

const { ListSlice, getList } = createListSlice<Deal>({
  name: "salesRepDeals",
  request_topic: "salesrep_deals",
  reducers: {}
});

export default ListSlice.reducer;
export const getSalesRepDeals = getList;
export const removeSalesRepDealList = ListSlice.actions.removeList;
export const clearSalesRepDealList = ListSlice.actions.clearList;
export const listSalesRepDealActions = ListSlice.actions;
