import { createListSlice } from "../Middlewares/listSliceCreator";
import { TitleIssue } from "./types";

const { ListSlice, getList } = createListSlice<TitleIssue>({
  name: "TitleIssueListByIds",
  request_topic: "all_title_issues_by_ids",
  reducers: {}
});
export default ListSlice.reducer;
export const getTitleIssuesByIds = getList;
export const listTitleIssueByIdsActions = ListSlice.actions;
export const removeTitleIssueByIdsList = ListSlice.actions.removeList;
