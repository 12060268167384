import { CustomRegexes } from "../../helpers";

const emailData = {
  type: "object",
  nullable: true,
  properties: {
    subject: {
      type: "string",
      nullable: true,
    },
    recipients: {
      type: "array",
      nullable: true,
      items: {
        type: "string",
        nullable: true,
      },
    },
    body: {
      type: "string",
      nullable: true,
    },
    attachments: {
      type: "array",
      nullable: true,
      items: {
        type: "object",
        nullable: true,
        properties: {
          name: {
            type: "string",
            nullable: true,
          },
          url: {
            type: "string",
            nullable: true,
          },
        },
      },
    },
  },
};

export default {
  optionalText: {
    type: "string",
    nullable: true,
  },
  eSign: {
    type: "boolean",
    nullable: true,
  },
  generatedPacks: {
    type: "array",
    nullable: true,
    items: {
      type: "object",
      nullable: true,
      properties: {
        url: {
          type: "string",
          nullable: true,
        },
        warning: {
          type: "string",
          nullable: true,
        },
        name: {
          type: "string",
          nullable: true,
        },
        oneSpanRequestPackUrl: {
          type: "string",
          nullable: true,
        },

        packType: {
          type: ["string", "null"],
          nullable: true,
          enum: ["email", "eSign", null],
          errorMessage: {
            enum: `must be one of the following: 
                              'email', 
                              'eSign'`,
          },
        },
      },
    },
  },
  emailData,
  send: {
    type: "object",
    nullable: true,
    properties: {
      email: {
        type: "boolean",
        nullable: true,
      },
      eSign: {
        type: "boolean",
        nullable: true,
      },
    },
  },
  sendEmails: {
    type: "array",
    nullable: true,
    items: {
      type: "object",
      nullable: true,
      properties: {
        sendAt: {
          type: "string",
          nullable: true,
        },
        userEmail: {
          type: "string",
          nullable: true,
        },
        emailData,
      },
    },
  },
  documents: {
    type: "array",
    nullable: true,
    items: {
      type: "object",
      nullable: true,
      properties: {
        _id: {
          type: "string",
          nullable: true,
        },
        type: {
          type: ["string", "null"],
          nullable: true,
          enum: [
            "additional_required_document",
            "document_template",
            "other_required_document",
            "insurance_document",
            "credit_score",
            null,
          ],
          errorMessage: {
            enum: `must be one of the following: 
                  'additional_required_document', 
                  'document_template', 
                  'other_required_document', 
                  'insurance_document',
                  'credit_score'`,
          },
        },
        name: {
          type: "string",
          nullable: true,
        },
        packType: {
          type: ["string", "null"],
          nullable: true,
          enum: ["email", "eSign", null],
          errorMessage: {
            enum: `must be one of the following: 
                    'email', 
                    'eSign'`,
          },
        },
        fileUrl: {
          type: "string",
          nullable: true,
        },
        fileName: {
          type: "string",
          nullable: true,
        },
        displayName: {
          type: "string",
          nullable: true,
        },
        documentData: {
          type: "object",
          nullable: true,
          properties: {
            name: {
              type: "string",
              nullable: true,
            },
          },
        },
        additionalProperties: {
          type: "object",
          nullable: true,
          properties: {
            required: {
              type: "boolean",
              nullable: true,
            },
            applicant: {
              type: "boolean",
              nullable: true,
            },
            dealerships: {
              type: "boolean",
              nullable: true,
            },
            eSign: {
              type: "boolean",
              nullable: true,
            },
          },
        },
      },
    },
  },
};
