import React, { useContext, useEffect, useState } from "react";
import { StateAccess } from "utils/models/formGenerator";
import { createOptionsForSelect } from "utils/models/fields";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { getCreditScoreRangesList, removeCreditScoreRangesList } from "./listCreditScoreRangeSlice";
import { v4 as uuidv4 } from "uuid";
import { CreditScoreRange } from "./types";
import { editRenderSet } from "utils/models/formRenderers";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { TabContext } from "components/Layout/LayoutWrapper";
interface Props {
  selectedRange: CreditScoreRange | null;
  setCreditScoreRange: React.Dispatch<React.SetStateAction<CreditScoreRange | null>>;
}

export default function CreditScoreRangePreview({ selectedRange, setCreditScoreRange }: Props) {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const createOrFocusTab = useContext(TabContext);
  const listId = `list-autocomplete-range-${requestId}`;

  const rangeList = useSelector((state: RootState) => state.listCreditScoreRangeSlice[listId]);

  const defaultRange = (rangeList?.entities ?? []).find(
    (r) => r._id === "66a8b0ecefaeccf5abda70c1"
  );

  const stateAccess: StateAccess = {
    get: (path) => selectedRange,
    set: (_, value): any => {
      setCreditScoreRange(value);
    }
  };

  const handleLoadRanges = () => {
    if (!rangeList)
      dispatch(
        getCreditScoreRangesList(listId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } }
        })
      );
    return () => {
      dispatch(removeCreditScoreRangesList(listId));
      return;
    };
  };

  useEffect(() => {
    if (defaultRange && !selectedRange) {
      setCreditScoreRange(defaultRange);
    }
    handleLoadRanges();
  }, [defaultRange?._id]);

  useEffect(() => handleLoadRanges(), []);

  return (
    <Grid container alignItems="center" xs={12} spacing={2}>
      <Grid item xs={selectedRange ? 10 : 12}>
        {editRenderSet(false).selectInputRenderer(
          {
            formComponent: "select-field",
            name: "Credit score range",
            label: "Credit score range",
            path: [],
            onOpen: handleLoadRanges,
            options: createOptionsForSelect({
              possibleValues: () => rangeList?.entities ?? [],
              getOptionLabel: (range) => {
                return range?.data?.info?.name ?? "";
              },
              getSelectedOption: (x, y) => {
                return x._id === y._id;
              }
            }),
            valueToString: (el) => el?.data?.info?.name,
            required: true
          },
          stateAccess,
          [],
          stateAccess,
          editRenderSet(false)
        )}
      </Grid>
      {selectedRange && (
        <Grid item xs={2}>
          <Tooltip title={"Click to edit credit score range."}>
            <IconButton
              size="small"
              style={{
                color: "#254e6f"
              }}
              onClick={() => {
                createOrFocusTab({
                  label: "Show page",
                  index: "showPage",
                  isForSidebar: false,
                  isForQuickAccess: false,
                  isPersistent: false,
                  props: {
                    _id: selectedRange._id,
                    type: "credit_score_range"
                  }
                });
              }}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}
