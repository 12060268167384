import { Deal } from "components/Deals/types";
import { Lender } from "components/Lenders/types";
import { LenderTicketType } from "components/LenderTicketTypes/types";
import { Note } from "components/Notes/types";
import { TitleIssue } from "components/TitleIssues/types";
import { User } from "components/Users/types";
import { CrudEntity } from "utils/types";
import { LenderNote } from "./LenderNotes/types";

export enum LenderTicketStatus {
  Open = "open",
  InProgress = "in progress",
  Solved = "solved",
  Closed = "closed"
}
export type TicketSource = "Blackbird" | "Lender app";

export interface FormState {
  info: Info;
  notes: Note[];
  lenderNotes?: LenderNote[];
  dealId?: string | null;
  deal?: Deal | null;
  titleIssue?: TitleIssue;
  titleIssueId?: string | null;
  lenderId?: string | null;
  lender?: Lender;
  userId?: string;
  replyTo: string[];
  assignees?: User[];
  assigneeIds?: string[] | null;
  typeId: string | null;
  type?: LenderTicketType;
}

export interface Info {
  subject: string;
  refNumber: string;
  status: LenderTicketStatus;
  description: string;
  priority: Priority;
  source: TicketSource;
}

enum Priority {
  Low = "low",
  Medium = "medium",
  High = "high"
}

export type LenderTicket = CrudEntity & {
  data: FormState;
};

export const possibleValues = [
  LenderTicketStatus.Open,
  LenderTicketStatus.InProgress,
  LenderTicketStatus.Solved,
  LenderTicketStatus.Closed
];

export const PriorityValues = [Priority.Low, Priority.Medium, Priority.High];
