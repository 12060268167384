import React, { useEffect, useContext } from "react";
import { Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { editDeal } from "components/Deals/editDealSlice";
import { RootState } from "app/rootReducer";
import {
  StateAccess,
  RenderSet,
  generateDefault,
  fillDefaultsByPath
} from "utils/models/formGenerator";
import { Deal } from "./types";
import Save from "@material-ui/icons/Save";
import History from "@material-ui/icons/History";
import { initializeAppOneData } from "../../Deals/AppOneModal";
import { getModel } from "../../../utils/entitySlice";
import formEditContext from "components/Content/FormEditContext";
export default function SaveButton({
  stateAccess,
  renderSet
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) {
  const requestId = uuidv4();
  const dispatch = useDispatch();
  const { enabled: editMode } = useContext(formEditContext);
  const appOneData = stateAccess.get([]) as Deal;
  const dealId = appOneData?.dealId;
  const borrowerType = appOneData?.data?.Borrowers?.Borrower?.Type;
  const coBorrowerType = appOneData?.data?.Borrowers?.CoBorrower?.Type;
  const borrowerEmploymentStatus =
    appOneData?.data?.Borrowers?.Borrower?.EmploymentInfo?.Employment[0]?.Status;
  const borrowerEmployerName =
    appOneData?.data?.Borrowers?.Borrower?.EmploymentInfo?.Employment[0]?.EmployerName;
  const borrowerOccupation =
    appOneData?.data?.Borrowers?.Borrower?.EmploymentInfo?.Employment[0]?.Occupation;
  const coBorrowerEmploymentStatus =
    appOneData?.data?.Borrowers?.CoBorrower?.EmploymentInfo?.Employment[0]?.Status;
  const coBorrowerEmployerName =
    appOneData?.data?.Borrowers?.CoBorrower?.EmploymentInfo?.Employment[0]?.EmployerName;
  const coBorrowerOccupation =
    appOneData?.data?.Borrowers?.CoBorrower?.EmploymentInfo?.Employment[0]?.Occupation;

  const parentDeal = useSelector((state: RootState) => state.entitySlice["deal"][dealId]);

  const handleAppOneDataSave = () => {
    dispatch(
      editDeal({
        requestId,
        ...parentDeal,
        data: { ...parentDeal.data, appOneData: appOneData }
      })
    );
  };

  const handleAppOneDataReset = () => {
    const appOne = initializeAppOneData({
      ...parentDeal,
      data: { ...parentDeal.data, appOneData: null }
    })(
      generateDefault(getModel("appone_import_application") as any, {}, fillDefaultsByPath as any)
    );
    stateAccess.set([], appOne);

    dispatch(
      editDeal({
        requestId,
        ...parentDeal,
        data: { ...parentDeal.data, appOneData: null }
      })
    );
  };

  useEffect(() => {
    if (borrowerEmploymentStatus === "RETIRED") {
      if (borrowerEmployerName !== "Retired") {
        stateAccess.set(
          // @ts-ignore
          ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "EmployerName"],
          "Retired"
        );
      }
      if (borrowerOccupation !== "Retired") {
        stateAccess.set(
          // @ts-ignore
          ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "Occupation"],
          "Retired"
        );
      }
    }
    if (coBorrowerEmploymentStatus === "RETIRED") {
      if (coBorrowerEmployerName !== "Retired")
        stateAccess.set(
          // @ts-ignore
          ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "EmployerName"],
          "Retired"
        );
      if (coBorrowerOccupation !== "Retired")
        stateAccess.set(
          // @ts-ignore
          ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "Occupation"],
          "Retired"
        );
    }
  }, [
    borrowerEmploymentStatus,
    stateAccess,
    borrowerEmployerName,
    borrowerOccupation,
    coBorrowerEmployerName,
    coBorrowerOccupation,
    coBorrowerEmploymentStatus
  ]);

  useEffect(() => {
    if (borrowerType === "BUSINESS" && coBorrowerType === "BUSINESS")
      stateAccess.set(["data", "Borrowers", "CoBorrower", "Type"], "INDIVIDUAL");
  }, [stateAccess, borrowerType, coBorrowerType]);
  return editMode ? (
    <>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-end"
        direction="row"
        style={{
          position: "absolute",
          bottom: "17px",
          zIndex: 1,
          right: "175px",
          background: "white",
          padding: "5px",
          borderRadius: "6px",
          width: "auto",
          boxShadow:
            "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)"
        }}
      >
        {parentDeal.data.appOneData !== null && parentDeal.data.appOneData !== undefined && (
          <Button
            startIcon={<History />}
            color="secondary"
            variant="contained"
            onClick={handleAppOneDataReset}
            style={{
              marginRight: "10px"
            }}
          >
            Load data from deal
          </Button>
        )}
        <Button
          startIcon={<Save />}
          color="primary"
          variant="contained"
          onClick={handleAppOneDataSave}
        >
          Save
        </Button>
      </Grid>
    </>
  ) : null;
}
