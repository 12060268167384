import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

type AddLenderTicketRequest = { data: FormState };

export interface AddLenderTicketResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddLenderTicketRequest,
  AddLenderTicketResponse
>("AddLenderTicket", {
  request: "new_lender_ticket"
});

export const addLenderTicketActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
export const addLenderTicket = (request: AddLenderTicketRequest & Identifiable) => {
  const { lender, assignees, deal, titleIssue, type, ...rest } = request.data;

  return socketAction({
    ...request,
    data: {
      ...rest,
      dealId: deal ? deal?._id : null,
      assigneeIds: assignees && assignees.length ? assignees.map((x) => x._id) : null,
      typeId: type ? type._id : null,
      lenderId: lender ? lender._id : null,
      info: {
        ...rest.info,
        source: "Blackbird"
      }
    }
  });
};
