import { Typography, IconButton, Grid, TextField, Button } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import React, { useContext, useEffect, useState } from "react";
import formatDate from "utils/formatDate";
import { StateAccess } from "utils/models/formGenerator";
import { Deal, DealStatus } from "./types";
import ReplayIcon from "@material-ui/icons/Replay";
import { RootState } from "app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { addRollbackStatus } from "components/RollbackStatus/rollbackStatusSlice";
import { FormState } from "components/RollbackStatus/types";
import { capitalize } from "utils/functions";
import formEditContext from "components/Content/FormEditContext";
import { useSnackbar } from "notistack";
import { dealStatusChipTooltip } from "./StatusToChipTooltip";

export default function StatusHistory({ stateAccess }: { stateAccess: StateAccess }) {
  const { enabled: editMode } = useContext(formEditContext);
  const [requestId] = useState(uuidv4());
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const deal: Deal = stateAccess.get([]);
  const user = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);
  const addRollbackResponseStatus = useSelector(
    (state: RootState) => state.rollbackStatusSlice[requestId]
  );
  const [open, setOpen] = React.useState(false);
  const [formState, setFormState] = React.useState<FormState>({} as FormState);
  const handleClickRollback = (newStatus: DealStatus) => {
    setFormState({
      dealId: deal?._id,
      userId: user?._id,
      info: {
        reason: "",
        newStatus
      }
    });
    setOpen(true);
  };
  const addRollbackStatusFunction = () => {
    if (!formState.info.reason)
      enqueueSnackbar("Please enter reason for status rollback.", {
        variant: "error"
      });
    else dispatch(addRollbackStatus({ requestId, data: formState }));
  };
  useEffect(() => {
    if (
      addRollbackResponseStatus?.status === "success" ||
      addRollbackResponseStatus?.status === "error"
    ) {
      enqueueSnackbar(
        addRollbackResponseStatus?.status === "success"
          ? "Successfully rollbacked status."
          : addRollbackResponseStatus?.message,
        {
          variant: addRollbackResponseStatus.status
        }
      );
      setOpen(false);
    }
  }, [addRollbackResponseStatus?.status]);
  const sortedStatuses = [...(deal?.data?.info?.statusHistory ?? [])]?.sort(
    (a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime()
  );
  return (
    <Grid container spacing={1}>
      <Grid item xs={open ? 7 : 12}>
        <Timeline>
          {sortedStatuses?.map((history, index) => {
            return (
              <TimelineItem key={index}>
                <TimelineOppositeContent>
                  <Typography
                    color="textSecondary"
                    style={
                      open
                        ? {
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "200px"
                          }
                        : undefined
                    }
                  >
                    {history.userEmail}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatDate(history.date, "short", true)}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  {deal?.data?.info?.statusHistory?.length !== index + 1 ? (
                    <TimelineConnector />
                  ) : null}
                </TimelineSeparator>
                <TimelineContent
                  style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                >
                  <Typography>
                    {dealStatusChipTooltip(history.status, history?.reason ?? "")}
                  </Typography>
                  {editMode && deal?.data?.info?.status !== history?.status && (
                    <IconButton onClick={() => handleClickRollback(history.status)}>
                      <ReplayIcon />
                    </IconButton>
                  )}
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Grid>
      {open && (
        <Grid container item xs={5} style={{ maxHeight: "200px" }} justifyContent="flex-end">
          <Grid item xs={12}>
            <Typography variant="body1">
              <span style={{ color: "red" }}>{`${capitalize(deal?.data?.info?.status)}`}</span>
              <span>{" -> "}</span>
              <span style={{ color: "green" }}>{`${capitalize(formState.info.newStatus)} `}</span>
            </Typography>
            <TextField
              style={{ marginTop: "10px" }}
              minRows={5}
              multiline
              value={formState.info.reason ?? ""}
              onChange={(e) =>
                setFormState((oldFormState) => ({
                  ...oldFormState,
                  info: { ...oldFormState.info, reason: e.target.value ?? "" }
                }))
              }
              name="reasonState"
              fullWidth
              label="Status rollback reason"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={2} style={{ marginRight: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={addRollbackStatusFunction}
              disabled={formState.info.reason === undefined || formState.info.reason === ""}
            >
              Send
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
