import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface AddDocumentTemplateResponse {
  message: string;
}
interface AddDocumentTemplateRequest {
  data: FormState;
  availableToRolesIds?: string[] | null;
  requestId: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddDocumentTemplateRequest,
  AddDocumentTemplateResponse
>("Add Document Template", {
  request: "new_document_template"
});

export const addDocumentTemplate = ({
  data,
  requestId,
  availableToRolesIds
}: AddDocumentTemplateRequest & Identifiable) => {
  const {
    info: {
      dealTypeProperties: { lenders, ...dealTypeProperties },
      ...info
    },
    ...rest
  } = data;
  return socketAction({
    requestId,
    availableToRolesIds: availableToRolesIds ?? [],
    data: {
      info: {
        ...info,
        dealTypeProperties: {
          ...dealTypeProperties,
          lendersIds: lenders?.map((el) => el._id) ?? []
        }
      },
      ...rest
    }
  });
};

export const addDocumentTemplateActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
