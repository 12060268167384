import { Prescreen } from "./types";
import { createListSlice } from "../Middlewares/listSliceCreator";

const { ListSlice, getList } = createListSlice<Prescreen>({
  name: "PrescreenList",
  request_topic: "all_prescreens",
  reducers: {}
});

export default ListSlice.reducer;
export const getPrescreenList = getList;
export const listPrescreenActions = ListSlice.actions;
