import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { GetDealerDetails } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<GetDealerDetails, any>(
  "GetProtectiveDealerInfo",
  {
    request: "protective_get_dealer_details"
  }
);

export const getDealerDeatails = socketAction;
export const listProductsActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
