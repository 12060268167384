import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { TitleIssue } from "./types";
interface DeleteTitleIssueRequest {
  _id: string;
}

interface DeleteTitleIssueResponse {
  message: TitleIssue;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteTitleIssueRequest,
  DeleteTitleIssueResponse
>("DeleteTitleIssue", {
  request: "delete_title_issue"
});

export const deleteTitleIssueActions = socketMessageSlice.actions;
export const deleteTitleIssue = socketAction;
export default socketMessageSlice.reducer;
