import { IconButton } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ClearIcon from "@material-ui/icons/Clear";
import { RootState } from "app/rootReducer";
import { Applicant } from "components/Applicants/types";
import { HintTooltip } from "components/common/HintTooltip";
import formEditContext from "components/Content/FormEditContext";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { entitySlice } from "utils/entitySlice";
import { FormComponent, Model, Path } from "utils/models/fields";
import {
  fillDefaultsByPath,
  generateDefault,
  generateForm,
  getByPath,
  RenderSet,
  StateAccess
} from "utils/models/formGenerator";
import { showRenderSet } from "utils/models/formRenderers";
import { getApplicantList, removeApplicantList } from "../Applicants/listApplicantSlice";
import AddApplicant from "./AddApplicant";
import ApplicantSearch from "./ApplicantSearch";
import EditApplicant from "./EditApplicant";
import { Deal } from "./types";

const simpleApplicantStruct: Model<Applicant> = {
  formComponent: "model",
  schema: "new_applicant",
  name: "applicant",
  entities: [
    {
      formComponent: "segment",
      name: "",
      entities: [
        {
          formComponent: "date-field",
          name: "firstName",
          label: "Birth Date",
          width: "1/3",

          path: ["data", "info", "birthDate"],
          default: ""
        },
        {
          formComponent: "phone-field",
          name: "birthDate",
          label: "Mobile Phone",

          width: "1/3",
          path: ["data", "info", "mobilePhone"],
          default: ""
        },
        {
          formComponent: "ssn-field",
          name: "ssn",
          label: "SSN",
          width: "1/3",
          path: ["data", "info", "socialSecurityNumber"],
          default: ""
        }
      ]
    }
  ]
};

export const searchApplicantStruct: FormComponent<Applicant> = {
  formComponent: "segment",

  width: "1/2",
  entities: [
    {
      formComponent: "text-field",
      name: "lastName",
      label: "Last name",
      width: "1/2",
      path: ["data", "info", "lastName"],
      default: ""
    },
    {
      formComponent: "text-field",
      name: "ssn",
      label: "SSN (last 4 digits)",
      width: "1/2",
      maxLength: 4,
      path: ["data", "info", "socialSecurityNumber"],
      default: ""
    }
  ]
};

interface Props {
  stateAccess: StateAccess;
  path: ["data", "applicant"] | ["data", "coApplicant"];
  renderSet: RenderSet;
  name: string;
  label: string;
  required: boolean;
  applicant?: Applicant;
  showAdditionalInfo?: boolean;
}

export default function ApplicantPreview({
  applicant: externalApplicant,
  required,
  stateAccess,
  path,
  renderSet,
  name,
  label,
  showAdditionalInfo = true
}: Props) {
  const listId = `${name}-list-autocomplete-deal-${stateAccess.get(["_id"])}`;
  const applicantList = useSelector((state: RootState) => state.listApplicantSlice[listId]);
  const dispatch = useDispatch();
  const applicant: Applicant = stateAccess.get<Deal>(path);
  const selectedApplicant = useSelector(
    (state: RootState) => state.entitySlice["applicant"][applicant?._id]
  );
  const { enabled: editMode } = useContext(formEditContext);
  useEffect(() => {
    if (applicant?._id !== selectedApplicant?._id) {
      dispatch({
        type: entitySlice.actions.add.type,
        payload: { type: "applicant", message: applicant }
      });
    }
  }, [applicant, selectedApplicant, dispatch]);

  const defaultState = generateDefault(searchApplicantStruct, {}, fillDefaultsByPath as any) as any;
  const initialState = { ...defaultState, data: { ...defaultState.data } } as any;

  const [formState, setFormState] = useState(initialState);
  const [query, setQuery] = useState({
    "data.info.lastName": {
      $regex: `^${formState.data.info.lastName?.trim()}`,
      $options: "i"
    },
    "data.info.lastFourSSNDigits": {
      $regex: `.*${formState.data.info?.socialSecurityNumber?.slice(-4)}$`
    }
  });

  const applicantStateAccess: StateAccess = {
    get: (path) => getByPath<Applicant>(selectedApplicant, path as Path<Applicant>),
    set: (path, value): any => {
      return;
    }
  };
  useEffect(() => {
    setQuery({
      "data.info.lastName": { $regex: `^${formState.data.info.lastName?.trim()}`, $options: "i" },
      "data.info.lastFourSSNDigits": {
        $regex: `.*${formState.data.info?.socialSecurityNumber?.slice(-4)}$`
      }
    });
  }, [formState]);

  return (
    <>
      {!selectedApplicant && (
        <Box display="flex">
          <Box flexGrow={1}>
            <ApplicantSearch
              id={listId}
              formState={formState}
              applicantList={applicantList}
              setFormState={setFormState}
              path={path}
              required={required}
              entityName="applicant"
              query={query}
              getFunction={getApplicantList}
              removeFunction={removeApplicantList}
              renderSet={renderSet}
              stateAccess={stateAccess}
              searchStruct={searchApplicantStruct}
            />
          </Box>
          {editMode && (
            <Box>
              <AddApplicant
                path={path}
                stateAccess={stateAccess}
                applicant={externalApplicant}
                setSelected={(applicant: Applicant) => {
                  return stateAccess.set<Deal>(path, applicant);
                }}
                editMode={editMode}
              />
            </Box>
          )}
        </Box>
      )}

      {selectedApplicant && (
        <>
          <Box display="flex">
            <Box flexGrow={1}>
              {showRenderSet(false).readOnlyRenderer<Applicant>(
                {
                  formComponent: "read-only-field",
                  required,
                  name,
                  width: "1/3",
                  label,
                  path: ([] as unknown) as Path<Applicant>,
                  valueToString: (el) => {
                    return (
                      [el?.data?.info?.firstName, el?.data?.info?.lastName]
                        .filter((name) => name !== undefined)
                        .join(" ") || " "
                    );
                  }
                },
                applicantStateAccess,
                renderSet
              )}
            </Box>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <EditApplicant
                path={path}
                selectedApplicant={selectedApplicant}
                stateAccess={stateAccess}
                applicant={externalApplicant}
                setSelected={(applicant: Applicant) => stateAccess.set<Deal>(path, applicant)}
                editMode={editMode}
              />
              {editMode ? (
                <IconButton
                  id="remove-applicant"
                  size="small"
                  style={{ margin: "3px 0px 0px 10px" }}
                  onClick={() => stateAccess.set<Deal>(path, null)}
                  disabled={!editMode}
                >
                  <HintTooltip title="Click here to remove the current applicant.">
                    <ClearIcon />
                  </HintTooltip>
                </IconButton>
              ) : null}
            </Box>
          </Box>
          {showAdditionalInfo &&
            generateForm(
              simpleApplicantStruct,
              applicantStateAccess,
              [],
              applicantStateAccess,
              showRenderSet(false)
            )}
        </>
      )}
    </>
  );
}
