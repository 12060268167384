import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

export default {
  all_histories: {
    $id: "allHistories",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
  get_history: {
    $id: "getHistory",
    type: "object",
    required: ["_id", "collection"],
    properties: {
      _id: { type: "string" },
      collection: { type: "string" },
    },
  },
};
