import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { closeDrawer } from "./drawerSlice";
import { IconButton } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DrawerLink from "./DrawerLinks";
import { Tab } from "components/Tabs/types";
import { RootState } from "app/rootReducer";
import useIsMobile from "hooks/isMobile/isMobile";

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap"
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1)
    }
  })
);

export default function CustomDrawer({
  createOrFocusTab
}: {
  createOrFocusTab: (tabInfo: Tab) => void;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { drawerSlice } = useSelector((state: RootState) => state);
  const isMobile = useIsMobile(); // Hook to determine if the current device is mobile
  return (
    <Drawer
      id="drawer"
      variant={isMobile ? "temporary" : "permanent"} // different drawers based on device type
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: drawerSlice.isOpen,
        [classes.drawerClose]: !drawerSlice.isOpen
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: drawerSlice.isOpen,
          [classes.drawerClose]: !drawerSlice.isOpen
        })
      }}
      open={drawerSlice.isOpen}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={() => dispatch(closeDrawer())}>
          {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <DrawerLink createOrFocusTab={createOrFocusTab} />
    </Drawer>
  );
}
