import info_properties from "./info_properties";

import common from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
const data = {
  type: "object",
  required: ["info"],
  properties: {
    info: { type: "object", required: ["name"], properties: info_properties },
  },
};
export default {
  new_insurance_company: {
    $id: "newInsuranceCompany",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_insurance_company: {
    $id: "updateInsuranceCompany",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      approved: {
        type: "boolean",
        nullable: true,
      },
      approvedAt: {
        type: "string",
        nullable: true,
      },
      approvedByUserId: {
        type: "string",
        nullable: true,
      },
    },
  },
  delete_insurance_company: {
    $id: "deleteInsuranceCompany",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  recover_insurance_company: {
    $id: "recoverInsuranceCompany",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_insurance_company: {
    $id: "getInsuranceCompany",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_insurance_companies: {
    $id: "allInsuranceCompanies",
    anyOf: [
      { type: "object", properties: common },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
