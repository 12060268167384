import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import DateFilter from "components/Filters/DateFilter";
import TextFilter from "components/Filters/TextFilter";
import Table, { CellValue, Column } from "components/Table";
import React from "react";
import { useSelector } from "react-redux";
import { deleteContractType } from "./deleteContractTypeSlice";
import { getContractTypeList } from "./listContractTypeSlice";
import { recoverContractType } from "./recoverContractTypeSlice";
import { FormState } from "./types";
export const projections = {
  "data.info.type": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};

export default (): JSX.Element => {
  const typeList = useSelector((state: RootState) => state.listContractTypeSlice["table"]);
  const columns: Column<FormState>[] = [
    {
      label: "Type",
      getData: (el): CellValue => el.data.info.type,
      name: "type",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.type?.show;
      },
      filters: [
        {
          path: ["data", "info", "type"],
          preview: TextFilter,
          label: "Type",
          name: "type",
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      label: "Date added",
      getData: (el): CellValue => new Date(el.createdAt),
      options: {},
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter,
          label: "Created At",
          name: "createdAt"
        }
      ]
    },
    {
      label: "Actions",
      getData: (el): CellValue => el._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <>
      <AccessControl requiredPermissions={{ entity: "contract_type", action: "read" }}>
        <Table
          tableName={"contract_types"}
          entityName="contract_type"
          listFunction={getContractTypeList}
          listEntity={typeList}
          deleteEntityFunction={deleteContractType}
          recoverEntityFunction={recoverContractType}
          title={"Contract types"}
          columns={columns}
          projection={projections}
        />
      </AccessControl>
    </>
  );
};
