import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { Note } from "./types";

interface EditNoteResponse {
  message: Note;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<Note, EditNoteResponse>(
  "EditNote",
  {
    request: "update_note"
  }
);

export const editNote = socketAction;
export const editNoteActions = socketMessageSlice.actions;
export const editNoteError = socketMessageSlice.actions.error;

export default socketMessageSlice.reducer;
