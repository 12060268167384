import { DialogTitle, DialogContent, DialogActions, Dialog, Button } from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";
import React from "react";
import TrendsChartByStatus from "./TrendsChartByStatus";
import TrendsChartByType from "./TrendsChartByType";
import { CollateralTypes, DealStatus } from "components/Deals/types";
import { ValueByStatus } from "hooks/useLenderAllocationStatistics/types";

type Props = {
  title: string;
  chartBy: "status" | "type";
  currencyFormat: boolean;
  data: ((ValueByStatus | (ValueByStatus & { unallocated: number })) & { period: string })[];
  selectedStatuses: DealStatus[];
  selectedCollateralTypes: CollateralTypes[];
  closeFunction: () => void;
};
const TrendsDialog = ({
  title,
  closeFunction,
  selectedStatuses,
  selectedCollateralTypes,
  data,
  currencyFormat,
  chartBy
}: Props) => {
  return (
    <Dialog maxWidth="lg" id={`trends-dialog-${title}`} open={true} onClose={closeFunction}>
      <DialogTitle id={`trends-dialog-${title}-title`}>
        {title}
        <CloseDialogButton closeFunction={closeFunction} />
      </DialogTitle>
      <DialogContent>
        {chartBy === "status" ? (
          <TrendsChartByStatus
            selectedStatuses={selectedStatuses}
            title={title}
            data={data}
            currencyFormat={currencyFormat}
          />
        ) : (
          <TrendsChartByType
            selectedCollateralTypes={selectedCollateralTypes}
            title={title}
            data={data}
          />
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <Button onClick={closeFunction} variant="contained" color="primary" id="cancel">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default TrendsDialog;
