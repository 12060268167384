import { StateAccess, RenderSet } from "utils/models/formGenerator";
import { createOptionsForSelect } from "utils/models/fields";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  getVehicleInsuranceCompanyList,
  removeVehicleInsuranceCompanyList
} from "./listVehicleInsuranceCompanySlice";
import React from "react";

interface Props {
  stateAccess: StateAccess;
  path: string[];
  renderSet: RenderSet;
  name: string;
  label: string;
}

export default function VehicleInsuranceCompanyPreview({
  stateAccess,
  path,
  renderSet,
  name,
  label
}: Props) {
  const dispatch = useDispatch();
  const listId = `list-autocomplete-deal-${stateAccess.get(["_id"])}`;
  const vehicleInsuranceCompanyList = useSelector(
    (state: RootState) => state.listVehicleInsuranceCompanySlice[listId]
  );

  const handleLoadInsuranceCompany = () => {
    if (!vehicleInsuranceCompanyList) dispatch(getVehicleInsuranceCompanyList(listId));
    return () => {
      dispatch(removeVehicleInsuranceCompanyList(listId));
      return;
    };
  };
  return (
    <>
      {renderSet.selectInputRenderer(
        {
          formComponent: "select-field",
          name,
          label,
          path: path as [],
          onOpen: handleLoadInsuranceCompany,
          options: createOptionsForSelect({
            possibleValues: () => vehicleInsuranceCompanyList?.entities ?? [],
            getOptionLabel: (el) => {
              return el?.data?.info?.name;
            },
            getSelectedOption: (x, y) => x._id === y._id
          }),
          valueToString: (el) => el?.data?.info?.name
        },
        stateAccess,
        [],
        stateAccess,
        renderSet
      )}
    </>
  );
}
