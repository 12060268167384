import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverDealershipRequest {
  _id: string;
}

export interface RecoverDealershipResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverDealershipRequest,
  RecoverDealershipResponse
>("RecoverDealership", {
  request: "recover_dealership"
});

export const recoverDealershipActions = socketMessageSlice.actions;
export const recoverDealership = socketAction;
export default socketMessageSlice.reducer;
