import { Contract } from "components/Contracts/types";
import { CustomerCareNote } from "components/CustomerCareNotes/types";
import { DealershipProgram } from "components/DealershipPrograms/types";
import { collateralTypes, RecursivePartial } from "components/Deals/types";
import { User } from "components/Users/types";
import { AddRequest, CrudEntity, One, RequestSend } from "utils/types";

type Bill = {
  [key: string]: {
    status: "waiting" | "success" | "error" | "warning";
    message: string;
    internalId?: number;
  };
};
export interface FormState {
  info: Info;
  dealershipSettings?: DealershipSettings;
  representative: User | null;
  representativeId?: string | null;
  customerCareAssignee: User | null;
  customerCareAssigneeId?: string | null;
  contacts: Contact[];
  contracts?: Contract[];
  notes: Note[];
  customerCareNotes: CustomerCareNote[];
  source?: string;
  sourceId?: string;
  netsuiteId?: string;
  hubspotId?: string;
  statistics?: DealershipStatistics[];
  bills?: Bill;
  chargebackBills?: Bill;
}

export enum ApplicationParserType {
  Automatic = "automatic",
  General = "general",
  Frazer = "frazer",
  Reynolds = "reynolds",
  Dealertrack = "dealertrack",
  Carforsale = "carforsale",
  Dealercarsearch = "dealercarsearch"
}

export interface DealershipStatistics {
  _id: {
    year: number;
    month: number;
    dealershipId: string;
  };
  year: number;
  month: number;
  dealershipId: string;
  totalGrossProfit: number;
  totalAmountFinanced: number;
  dealsCount: number;
}
export type Dealership = CrudEntity & { data: FormState };
export interface Condition {
  field: string[];
  type: string;
  value: number;
}

export interface Agreement {
  wfdSplit?: number;
  dealershipSplit?: number;
  wfdGAPSplit?: number;
  dealershipGAPSplit?: number;
  wfdWarrantySplit?: number;
  dealershipWarrantySplit?: number;
}

export type ProfitType = "dealershipProfit" | "wfdProfit";
export type RelationTypes = null | "fulltimeF&I" | "correspondent" | undefined;

export type DealershipProgramWithPeriods = {
  dealershipProgram?: DealershipProgram | null;
  startDate?: string;
  endDate?: string;
  dealershipProgramId?: string;
};
type ActivityWarning = {
  warning?: boolean;
  reCheckDays?: number;
  snoozeType?: "twoWeeks" | "oneMonth" | "twoMonths";
  checked?: boolean;
  checkedOrSnoozedDate?: string;
};

export type Suspensions = {
  funding: boolean;
  applicationProcessing: boolean;
};

export type TitleManagement = {
  suspensions?: Suspensions;
  isRestricted?: boolean;
  outstandingTitlesFundingLimit?: number;
  outstandingTitlesApplicationsLimit?: number;
  outstandingDeals?: number;
  restrictionDate?: string;
  dealsIds?: string[];
  titleIssuesIds?: string[];
};

export interface Info {
  funding: {
    paymentMethod?: "ACH" | "Check";
    businessDaysToFund: number;
    enabled?: boolean;
  };
  coordinates?: {
    lat: number;
    lon: number;
  };
  applicationParserType?: ApplicationParserType;
  eSign: boolean;
  domain: string;
  isActive?: boolean;
  activityWarning?: ActivityWarning;
  titleManagement?: TitleManagement;
  statusHistory?: DealerStatusHistory[];
  adminEmail?: string;
  adminLastLogin?:string
  adminFirstName?: string;
  adminLastName?: string;
  adminRegistrationLink?: string;
  isPlatform?: boolean;
  uniqueDisplayName: boolean;
  displayName: string;
  name: string;
  address: string;
  secondaryAddress?: string;
  shippingAddress?: string;
  city: string;
  zipCode: string | undefined;
  county?: string;
  relationType: RelationTypes;
  state: string;
  phone: string;
  collateralTypes: Record<typeof collateralTypes[number], boolean>;
  faxNumber: string;
  logo?: Logo | undefined;
  abbreviation?: string;
  agreement?: Agreement;
  titleDate?: string;
  dealershipPrograms?: DealershipProgramWithPeriods[];
}

interface Logo {
  name: string;
  url: string;
  metadata: string;
}
export type DealerStatusHistory = {
  status: "active" | "inactive";
  comment: string;
  date: string;
  userEmail: string;
};
export interface Contact {
  title: string;
  firstName: string;
  lastName: string;
  phone: string | undefined;
  email: string;
  role?: ContactRole;
  notificationOnlineApplication: boolean | null;
}

export type ContactRole = "Primary Contact" | "Finance Contact" | "Business Manager";
export interface Note {
  note: string;
}

export interface DealershipToSend {
  _id: string;
  data: FormState;
  availableToRolesIds?: string[] | null;
}

interface DealershipRequest {
  _id: string;
  data: FormState;
  approved?: boolean | null;
  approvedAt?: string;
  approvedByUserId?: string;
  availableToRolesIds?: string[] | null;
}

type ExternalFields = One<"representative" | "customerCareAssignee">;
type ExternalFieldsIds = "representativeId" | "customerCareAssigneeId";
export type EditDealershipRequest = DealershipRequest;
export type AddDealershipRequest = AddRequest<DealershipRequest>;
export type EditDealershipRequestSend = RequestSend<
  EditDealershipRequest,
  ExternalFields,
  ExternalFieldsIds
>;
export type AddDealershipRequestSend = RequestSend<
  AddDealershipRequest,
  ExternalFields,
  ExternalFieldsIds
>;

export interface PartialDealershipToEdit {
  _id: string;
  data: RecursivePartial<FormState>;
  approved?: boolean | null;
  approvedAt?: string;
  approvedByUserId?: string;
}
export interface PartialDealershipToSend {
  _id: string;
  data: RecursivePartial<FormState>;
  type: "partial";
}

export type DealershipSettings = {
  data: {
    info: {
      adfEmails: (SftpAdfData | EmailAdfData)[];
    };
  };
};

export type SftpAdfData = {
  sftp: true;
  host: string;
  port: number;
  username: string;
  password: string;
  directory: string;
  passwordEncrypted: boolean;
  dcid: string;
};
export type EmailAdfData = {
  sftp: false;
  email: string;
};
