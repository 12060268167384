import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverDealRequest {
  _id: string;
}

export interface RecoverDealResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverDealRequest,
  RecoverDealResponse
>("RecoverDeal", {
  request: "recover_deal"
});

export const recoverDealActions = socketMessageSlice.actions;
export const recoverDeal = socketAction;
export default socketMessageSlice.reducer;
