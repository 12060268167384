import React from "react";

import { Deal } from "./types";
import { StateAccess, RenderSet, setByPath } from "utils/models/formGenerator";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Toolbar from "@material-ui/core/Toolbar";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import formatDate from "utils/formatDate";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { calcAmounts, calcFinanceCharge } from "./Calculations";

const useStyles = makeStyles(() =>
  createStyles({
    numbers: {
      color: "#254e70",
      textAlign: "center"
    },
    text: {
      fontSize: "10px"
    }
  })
);

export default function LendingDisclosures({
  stateAccess,
  renderSet
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) {
  const styles = useStyles();
  //@ts-ignore
  const deal: Deal = stateAccess.get<Deal>([] as any);
  const intrestRate = deal?.data?.info?.payment?.interestRate ?? 0;
  const financeCharge = calcFinanceCharge(deal);

  const dealTotal = deal?.data?.info?.payment?.dealTotal ?? 0;
  const totalPayments = deal?.data?.info?.payment?.totalOfPayments ?? 0;
  const downPayment = deal?.data?.info?.payment?.downPayment ?? 0;
  const totalSalePrice = deal?.data?.info?.payment?.totalSalePrice ?? 0;
  const numberOfPayments = deal?.data?.info?.payment?.numberOfPayments ?? 0;
  const monthlyPayment = deal?.data?.info?.payment?.monthlyPayment ?? 0;

  const aftermarketOption = deal.data.info.aftermarketOptions;
  const dealWithoutGAPandVSC: Deal = setByPath(deal, ["data", "info", "aftermarketOptions"], {
    ...aftermarketOption,
    totalServiceWarrantySellPrice: 0,
    totalGAPSellPrice: 0
  });
  const dealWithoutVSC: Deal = setByPath(deal, ["data", "info", "aftermarketOptions"], {
    ...aftermarketOption,
    totalServiceWarrantySellPrice: 0
  });
  const dealWithoutGAP: Deal = setByPath(deal, ["data", "info", "aftermarketOptions"], {
    ...aftermarketOption,
    totalGAPSellPrice: 0
  });
  const dealAmountWithoutGapAndServiceWarranty = calcAmounts(dealWithoutGAPandVSC)?.data?.info
    ?.payment;
  const dealAmountWithoutServiceWarranty = calcAmounts(dealWithoutVSC)?.data?.info?.payment;
  const dealAmountWithoutGap = calcAmounts(dealWithoutGAP)?.data?.info?.payment;

  const monthlyPaymentWithoutGapAndServiceWarranty =
    dealAmountWithoutGapAndServiceWarranty?.monthlyPayment || 0;
  const monthlyPaymentWithoutServiceWarranty =
    dealAmountWithoutServiceWarranty?.monthlyPayment || 0;
  const monthlyPaymentWithoutGap = dealAmountWithoutGap?.monthlyPayment || 0;

  const daysToFirstPayment = deal?.data?.info?.payment?.daysToFirstPayment ?? 30;
  const contractDate = new Date(deal?.data?.info?.dealDates?.contractDate ?? "");

  return (
    <Paper elevation={3}>
      <Toolbar
        variant="dense"
        style={{
          justifyContent: "center",
          borderBottom: "solid 1px #e0e0e0",
          padding: "0px"
        }}
      >
        <Typography style={{ textAlign: "center" }} variant="subtitle2" component="div">
          TRUTH IN LENDING DISCLOSURES
        </Typography>
      </Toolbar>
      <Table size="small" padding="normal">
        <TableHead style={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <TableRow>
            <TableCell align="center">ANNUAL PERCENTAGE RATE</TableCell>
            <TableCell align="center">FINANCE CHARGE</TableCell>
            <TableCell align="center">AMOUNT FINANCED</TableCell>
            <TableCell align="center">TOTAL OF PAYMENTS</TableCell>
            <TableCell align="center">TOTAL SALE PRICE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={styles.text} align="center">
              The cost of your credit as a yearly rate.
            </TableCell>
            <TableCell className={styles.text} align="center">
              The dollar amount the credit will cost you.
            </TableCell>

            <TableCell className={styles.text} align="center">
              The amount of credit provided to you or on your behalf.
            </TableCell>
            <TableCell className={styles.text} align="center">
              The amount you will have paid when you have made all scheduled payments.
            </TableCell>
            <TableCell className={styles.text} align="center">
              The total cost of your purchase on credit, including your down payment of
              <Typography className={styles.numbers} variant="subtitle1" id="downPayment">
                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  downPayment
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">
              <Typography variant="subtitle1" className={styles.numbers} id="intrestRate">
                {intrestRate}%
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography className={styles.numbers} variant="subtitle1" id="financeCharge">
                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  financeCharge
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography className={styles.numbers} variant="subtitle1" id="dealTotal">
                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  dealTotal
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography className={styles.numbers} variant="subtitle1" id="totalPayments">
                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  totalPayments
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography className={styles.numbers} variant="subtitle1" id="totalSalePrice">
                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  totalSalePrice
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={5}>
              <Typography variant="subtitle1" component="div">
                <b>Payment Schedule: </b>Your payment schedule will be
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" variant="head">
              Number of Payments
            </TableCell>
            <TableCell align="center" variant="head">
              Monthly Payment
            </TableCell>
            <TableCell align="center" variant="head" colSpan={3}>
              First Payment Date
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">
              <Typography style={{ color: "#254e70" }} variant="subtitle1" id="numberOfPayments">
                {numberOfPayments}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={{ color: "#254e70" }} variant="subtitle1" id="monthlyPayment">
                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  monthlyPayment
                )}
              </Typography>
            </TableCell>
            <TableCell colSpan={3}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <b>Pmt/Month: </b>

                {deal?.data?.info?.dealDates?.contractDate
                  ? [
                      formatDate(
                        new Date(
                          new Date(contractDate).setDate(
                            contractDate.getDate() + daysToFirstPayment
                          )
                        ),
                        "full"
                      ),
                      " ",
                      <span style={{ color: "red" }} key="restDaysToFirstPayment">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          [
                          <Autocomplete
                            options={[30, 45, 60, 90]}
                            autoHighlight
                            openOnFocus
                            disableClearable
                            disabled={false}
                            freeSolo={true}
                            getOptionLabel={(x) => (x ? x.toString() : "")}
                            getOptionSelected={(a, b) => a === b}
                            value={deal?.data?.info?.payment?.daysToFirstPayment ?? 30}
                            key={"DaysToFirstPayment"}
                            onInputChange={(e, value) => {
                              if (deal?.data?.info?.payment?.daysToFirstPayment !== parseInt(value))
                                stateAccess.set<Deal>(
                                  ["data", "info", "payment", "daysToFirstPayment"],
                                  parseInt(value)
                                );
                            }}
                            id={"DaysToFirstPayment"}
                            renderInput={(params) =>
                              renderSet.type === "show" ? (
                                <>
                                  &nbsp;
                                  <span id={"DaysToFirstPayment"}>
                                    {deal?.data?.info?.payment?.daysToFirstPayment}
                                  </span>
                                  &nbsp;
                                </>
                              ) : (
                                <TextField
                                  {...params}
                                  required={false}
                                  variant="standard"
                                  size="small"
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    style: {
                                      width: "45px"
                                    }
                                  }}
                                  inputProps={{
                                    ...params.inputProps,

                                    style: {
                                      color: "red",
                                      padding: "0px",
                                      textAlign: "center",
                                      width: "45px",
                                      fontSize: "0.75rem"
                                    }
                                  }}
                                />
                              )
                            }
                            onChange={(event, value) => {
                              if (value !== null) {
                                stateAccess.set<Deal>(
                                  ["data", "info", "payment", "daysToFirstPayment"],
                                  parseFloat(value.toString())
                                );
                              } else {
                                stateAccess.set<Deal>(
                                  ["data", "info", "payment", "daysToFirstPayment"],
                                  value
                                );
                              }
                              return value;
                            }}
                          />
                          days to first payment]
                        </div>
                      </span>
                    ]
                  : "No signed at date!"}
              </div>
            </TableCell>
          </TableRow>
          <TableRow style={{ background: "#f1f1f1" }}>
            <TableCell align="center" variant="head">
              Payment
            </TableCell>
            <TableCell align="center" variant="head">
              Payment + GAP
            </TableCell>
            <TableCell align="center" variant="head">
              Payment + VSC
            </TableCell>
            <TableCell align="center" variant="head">
              Payment + GAP + VSC
            </TableCell>
            <TableCell align="center" variant="head"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" variant="head">
              <Typography style={{ color: "#254e70" }} variant="subtitle1">
                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  parseFloat(monthlyPaymentWithoutGapAndServiceWarranty.toFixed(2))
                )}
              </Typography>
            </TableCell>
            <TableCell align="center" variant="head">
              <Typography style={{ color: "#254e70" }} variant="subtitle1">
                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  parseFloat(monthlyPaymentWithoutServiceWarranty.toFixed(2))
                )}
              </Typography>
            </TableCell>
            <TableCell align="center" variant="head">
              <Typography style={{ color: "#254e70" }} variant="subtitle1">
                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  parseFloat(monthlyPaymentWithoutGap.toFixed(2))
                )}
              </Typography>
            </TableCell>
            <TableCell align="center" variant="head">
              <Typography style={{ color: "#254e70" }} variant="subtitle1">
                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  monthlyPayment
                )}
              </Typography>
            </TableCell>
            <TableCell align="center" variant="head"></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
}
