import { FormLabel, Grid, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";
import { ColumnFilter, Filters } from "./types";

export default <T extends unknown>({
  setFilters,
  columnFilters,
  label,
  filters,
  parentRef,
  menuOpen,
  setMenuOpen
}: {
  setFilters: (s: Filters[string]) => void;
  columnFilters: ColumnFilter<T>[];
  filters?: Filters[string];
  label?: string;
  parentRef: Element | undefined;
  menuOpen: boolean;
  setMenuOpen: (s: boolean) => void;
}): JSX.Element => {
  const theme = createTheme({
    overrides: {
      MuiMenu: {
        list: {
          padding: "0px",
          margin: "10px"
        }
      }
    }
  });
  return (
    <ThemeProvider theme={theme}>
      <Menu
        id="simple-menu"
        transformOrigin={{ vertical: -80, horizontal: "left" }}
        anchorEl={parentRef}
        autoFocus={false}
        keepMounted
        open={menuOpen}
        onClose={() => {
          setMenuOpen(false);
        }}
        transitionDuration={300}
      >
        <Grid onKeyDown={(e) => e.stopPropagation()} container spacing={1}>
          <Grid item xs={10} style={{ alignItems: "center", display: "grid" }}>
            <FormLabel component="legend">
              <Typography variant="subtitle1" style={{ color: "#000" }}>
                {label ?? "Column"} filters
              </Typography>
            </FormLabel>
          </Grid>
          <Grid item xs={2} style={{ justifyContent: "right", display: "grid" }}>
            <IconButton
              onClick={() => {
                setMenuOpen(false);
              }}
              aria-label="close"
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            {columnFilters.map((filter, index) => (
              <filter.preview
                key={index}
                setFilter={(filter) => {
                  const x = [...(filters ?? [])];
                  x[index] = filter;
                  setFilters(x);
                }}
                filter={filters?.[index]}
                path={filter.path}
                type={filter?.type}
                filterType={filter?.filterType}
                partialSearch={filter?.partialSearch}
                caseInsensitive={filter?.caseInsensitive}
                label={filter?.label ?? label}
                valuesForSelect={filter?.valuesForSelect}
                optionLabelForSelect={filter?.optionLabelForSelect}
                valueToString={filter?.valueToString}
                closeFilter={() => {
                  setMenuOpen(false);
                }}
              />
            ))}
          </Grid>
        </Grid>
      </Menu>
    </ThemeProvider>
  );
};
