import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeletePayoffBankRequest {
  _id: string;
}

interface DeletePayoffBankResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeletePayoffBankRequest,
  DeletePayoffBankResponse
>("DeletePayoffBank", {
  request: "delete_payoff_bank"
});

export const deletePayoffBankActions = socketMessageSlice.actions;
export const deletePayoffBank = socketAction;
export default socketMessageSlice.reducer;
