import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

type AddApplicantRequest = { data: FormState };

interface AddApplicantResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
    creatorId: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddApplicantRequest,
  AddApplicantResponse
>("AddApplicant", {
  request: "new_applicant"
});

export const addApplicantActions = socketMessageSlice.actions;
export const addApplicant = socketAction;
export default socketMessageSlice.reducer;
