import { Badge, Box, Button, createStyles, Theme, withStyles } from "@material-ui/core";
import React from "react";
import { Filters } from "./types";

export const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      boxShadow: "0px 0px 4px 1px rgba(0,0,0,0.35)"
    }
  })
)(Badge);

export default <T extends unknown>({
  setFilters,
  filtersCount
}: {
  setFilters: (
    state: (Filters | undefined) | ((oldState: Filters | undefined) => Filters | undefined)
  ) => void;
  filtersCount: number;
}): JSX.Element => {
  const handleResetFilters = () => {
    setFilters({});
  };

  return filtersCount ? (
    <Box
      component="span"
      style={{
        position: "relative",
        whiteSpace: "nowrap"
      }}
    >
      <StyledBadge badgeContent={filtersCount} color="primary">
        <Button
          style={{ color: "#1c4e6e", background: "#fff", fontSize: "12px" }}
          variant="outlined"
          onClick={(_: React.MouseEvent) => {
            handleResetFilters();
          }}
        >
          Clear Filters
        </Button>
      </StyledBadge>
    </Box>
  ) : (
    <></>
  );
};
