import { Grid } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import DateFilter from "components/Filters/DateFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import PhoneFilter from "components/Filters/PhoneFilter";
import TextFilter from "components/Filters/TextFilter";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLockList } from "utils/models/LockEntity/listLockSlice";
import Table, { CellValue, Column } from "../Table";
import { deleteLenderDecision } from "./deleteLenderDecisionSlice";
import EditLenderDecisionDeal from "./EditLenderDecisionDeal";
import { getLenderDecision } from "./listLenderDecisionsSlice";
import { recoverLenderDecision } from "./recoverLenderDecisionSlice";
import { FormState } from "./types";

export default ({ dealId }: { dealId?: string }): JSX.Element => {
  const sliceId = dealId ?? "table";
  const listFunction = dealId
    ? useCallback(
        (id, props) => {
          const andQuery = [
            ...(dealId ? [{ "data.dealId": dealId }] : []),
            ...(Array.isArray(props.query["$and"]) && props.query["$and"].length > 0
              ? props.query["$and"]
              : [])
          ];

          return getLenderDecision(sliceId, {
            ...props,
            query:
              andQuery?.length > 0
                ? {
                    $and: andQuery
                  }
                : {}
          });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dealId, sliceId]
      )
    : getLenderDecision;

  const dispatch = useDispatch();
  const lenderDecisionsList = useSelector(
    (state: RootState) => state.listLenderDecisionSlice[sliceId]
  );
  const lockData = useSelector((state: RootState) => state.listLockSlice["all"]);

  useEffect(() => {
    if (lockData?.entities === undefined) dispatch(getLockList("all"));
  }, [dispatch, lockData]);
  const columns: Column<FormState>[] = [
    {
      getData: (entry): CellValue => entry?._id,
      label: "Deal ref",
      options: {
        customBodyRender: (x: CellValue, lenderDecision): JSX.Element | string => {
          return (
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={7}>
                {lenderDecision?.data?.refNumber ?? "No deal found!"}
              </Grid>
              <Grid item xs={5}>
                <EditLenderDecisionDeal lenderDecision={lenderDecision} />
              </Grid>
            </Grid>
          );
        }
      },
      name: "refNumber",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.refNumber?.show;
      },
      filters: [
        {
          path: ["data", "refNumber"],
          preview: TextFilter
        }
      ]
    },
    {
      label: "Decision",
      getData: (el): CellValue => el?.data?.info?.decision ?? "",
      name: "decision",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.decision?.show;
      },
      filters: [
        {
          path: ["data", "info", "decision"],
          preview: MultiSelectFilter,
          valuesForSelect: [
            "Approved",
            "Conditionally Approved",
            "Declined",
            "Decision Canceled",
            "Pending"
          ],
          optionLabelForSelect: (decision) => decision ?? ""
        }
      ]
    },
    {
      label: "Lender",
      getData: (el): CellValue => el?.data?.info?.lender ?? "",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      },
      filters: [
        {
          path: ["data", "info", "lender"],
          preview: TextFilter,
          partialSearch: true,
          caseInsensitive: true
        }
      ]
    },
    {
      label: "Applicant",
      getData: (el): CellValue => el?.data?.info?.applicant ?? "",
      name: "applicant",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.applicant?.show;
      },
      filters: [
        {
          path: ["data", "info", "applicant"],
          preview: TextFilter,
          partialSearch: true,
          caseInsensitive: true
        }
      ]
    },
    {
      label: "Phone number",
      getData: (el): CellValue => el?.data?.info?.phoneNumber ?? "",
      name: "phoneNumber",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.phoneNumber?.show;
      },
      filters: [
        {
          path: ["data", "info", "phoneNumber"],
          preview: PhoneFilter
        }
      ]
    },
    {
      label: "Date submitted",
      getData: (el): CellValue => new Date(el?.data?.info?.dateSubmitted ?? ""),
      name: "dateSubmitted",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dateSubmitted?.show;
      },
      filters: [
        {
          path: ["data", "info", "dateSubmitted"],
          preview: DateFilter
        }
      ]
    },
    {
      label: "Actions",
      getData: (el): CellValue => el._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <>
      <AccessControl requiredPermissions={{ entity: "dmv", action: "read" }}>
        <Table
          tableName="lender_decisions"
          slice={sliceId}
          entityName="lender_decision"
          showUpload={true}
          listFunction={listFunction}
          listEntity={lenderDecisionsList}
          deleteEntityFunction={deleteLenderDecision}
          projection={{
            "data.refNumber": 1,
            "data.info.decision": 1,
            "data.info.lender": 1,
            "data.info.applicant": 1,
            "data.info.phoneNumber": 1,
            "data.info.dateSubmitted": 1
          }}
          recoverEntityFunction={recoverLenderDecision}
          sort={{ "data.info.dateSubmitted": "desc" }}
          title={"Lender decisions"}
          columns={columns}
        />
      </AccessControl>
    </>
  );
};
