import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { ManagerProgram } from "./types";
interface RecoverManagerProgramRequest {
  _id: string;
}

export interface RecoverManagerProgramResponse {
  message: ManagerProgram;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverManagerProgramRequest,
  RecoverManagerProgramResponse
>("RecoverManagerProgram", {
  request: "recover_manager_program"
});

export const recoverManagerProgramActions = socketMessageSlice.actions;
export const recoverManagerProgram = socketAction;
export default socketMessageSlice.reducer;
