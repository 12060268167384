import { Schema } from "ajv";

const data: Schema = {
  type: "object",
  required: [],
  properties: {
    params: {
      type: "object",
      additionalProperties: true,
    },
  },
  additionalProperties: true,
};

export default {
  asc_get_rate: {
    $id: "ascGetRate",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      chosenRate: {
        type: "object",
        nullable: true,
        properties: {
          months: {
            type: "number",
            nullable: true,
          },
          remitPrice: {
            type: "number",
            nullable: true,
          },
          retailPrice: {
            type: "number",
            nullable: true,
          },
          dealerCostPrice: {
            type: "number",
            nullable: true,
          },
        },
      },
    },
  },
  asc_process_transaction: {
    $id: "ascProcessTransaction",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
  asc_cancel_transaction: {
    $id: "ascCancelTransaction",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
};
