import {
  HorizontalSetting,
  VerticalSetting,
  Requirement,
  HorizontalRequirement,
  isRequirement
} from "./types";
import { Path } from "utils/models/fields";
import { Deal } from "components/Deals/types";

const defaultChild = (name: string, path: Path<Deal>, rest: {}) => ({
  name,
  path,
  requirements: [
    {
      type: "==",
      criteria: "",
      ...rest
    }
  ]
});

export const addLeavesHorizontal = (
  x: HorizontalSetting,
  name: string,
  path: Path<Deal>,
  possibleValues?: any
): HorizontalSetting => {
  return {
    ...x,
    requirements: (x.requirements as (HorizontalRequirement | Requirement<"horizontal">)[]).map(
      (y): Requirement<"horizontal"> => {
        if (isRequirement(y)) {
          const { child, ...rest } = y;
          return {
            ...rest,
            child: addLeavesHorizontal(child, name, path, possibleValues)
          } as Requirement<"horizontal">;
        } else {
          const { vertical, ...rest } = y;
          return {
            ...rest,
            child: defaultChild(name, path, { vertical, possibleValues })
          } as Requirement<"horizontal">;
        }
      }
    )
  };
};

export const addLeavesVertical = (
  x: HorizontalSetting,
  name: string,
  path: any,
  possibleValues?: any
): HorizontalSetting => {
  return {
    ...x,
    requirements: (x.requirements as any[]).map((y) => {
      const { child, vertical, ...rest } = y;
      return {
        ...rest,
        ...(child === undefined
          ? { vertical: addLeaves(vertical, name, path, possibleValues) }
          : { child: addLeavesVertical(child, name, path, possibleValues) })
      };
    })
  };
};

const addLeaves = <T extends VerticalSetting | HorizontalSetting>(
  x: T,
  name: string,
  path: any,
  possibleValues?: any
): T => {
  return {
    ...x,
    requirements: (
      x.requirements as Requirement<T extends VerticalSetting ? "vertical" : "horizontal">[]
    ).map((y) => {
      const { child, content, ...rest } = y as any;
      return {
        ...rest,
        child: y.hasOwnProperty("child")
          ? addLeaves(y.child, name, path, possibleValues)
          : defaultChild(name, path, { content, possibleValues })
      };
    })
  };
};

export const removeLeavesHorizontal = <T extends HorizontalSetting>(x: T): T => {
  return {
    ...x,
    requirements: (x.requirements as any[])
      .map((y) => {
        const { child, ...rest } = y;
        return child === undefined
          ? undefined
          : {
              ...rest,
              ...(child?.requirements[0].child === undefined
                ? child
                  ? { vertical: child?.requirements[0].vertical }
                  : null
                : { child: removeLeavesHorizontal(child) })
            };
      })
      .filter((x) => x)
  };
};

export const removeLeavesVertical = <T extends HorizontalSetting>(x: T): T => {
  return {
    ...x,
    requirements: (x.requirements as any[]).map((y) => {
      const { child, vertical, ...rest } = y;
      return {
        ...rest,
        ...(child === undefined
          ? { vertical: vertical ? removeLeaves(vertical) : null }
          : { child: removeLeavesVertical(child) })
      };
    })
  };
};

const removeLeaves = <T extends VerticalSetting | HorizontalSetting>(x: T): T => {
  return {
    ...x,
    requirements: (x.requirements as any).map(({ child, content, ...rest }: any) => {
      return {
        ...rest,
        ...(child?.requirements?.[0]?.hasOwnProperty("child")
          ? { child: removeLeaves(child) }
          : child
          ? { content: child.requirements[0].content as any }
          : null)
      };
    })
  };
};
