import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { auth } from "../../firebase/firebase";
import { ChargebackStatisticsRequest, ChargebackStatisticsResponse } from "./types";

export const getChargebackStatistics = (
  request: ChargebackStatisticsRequest
): (() => Promise<ChargebackStatisticsResponse>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/chargeback_statistics`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    );

    return response.data.result;
  };
};

const useChargebackStatistics = (request: ChargebackStatisticsRequest) => {
  const queryKey = ["chargeback_statistics", request];

  const { data, isLoading, isSuccess, error } = useQuery({
    queryKey: queryKey,
    queryFn: getChargebackStatistics(request)
  });

  return {
    data,
    isSuccess,
    isLoading,
    error
  };
};

export default useChargebackStatistics;
