import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverLenderTicketTypeRequest {
  _id: string;
}

export interface RecoverLenderTicketTypeResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverLenderTicketTypeRequest,
  RecoverLenderTicketTypeResponse
>("RecoverLenderTicketType", {
  request: "recover_lender_ticket_type"
});

export const recoverLenderTicketTypeActions = socketMessageSlice.actions;
export const recoverLenderTicketType = socketAction;
export default socketMessageSlice.reducer;
