import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

interface DeleteNotificationRequest {
  requestId: string;
  userEmail: string;
}

export interface DeleteNotificationResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteNotificationRequest,
  DeleteNotificationResponse
>("DeleteNotification", {
  request: "delete_all_notifications"
});

export const deleteAllNotificationsActions = socketMessageSlice.actions;
export const deleteAllNotifications = socketAction;
export default socketMessageSlice.reducer;
