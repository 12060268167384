import React, { useContext } from "react";
import { Button } from "@material-ui/core";

import { capitalize } from "utils/functions";
import AccessControl from "components/Access/AccessControl";
import { TabContext } from "components/Layout/LayoutWrapper";

export default ({ _id, entityName }: { _id?: string; entityName: "deal" | "title_issue" }) => {
  const createOrFocusTab = useContext(TabContext);

  const handlePreview = () =>
    createOrFocusTab({
      label: "Show page",
      index: "showPage",
      isForSidebar: false,
      isForQuickAccess: false,
      isPersistent: false,
      props: {
        _id,
        type: entityName
      }
    });

  return _id ? (
    <div>
      <AccessControl requiredPermissions={{ entity: entityName, action: "read" }}>
        <Button color="primary" variant="contained" onClick={handlePreview}>
          {`Preview ${capitalize(entityName).replace("_", " ")}`}
        </Button>
      </AccessControl>
    </div>
  ) : null;
};
