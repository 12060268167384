import React, { useState } from "react";
import Iframe from "react-iframe";

import { Dialog, Grid, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";

interface Props {
  url: string;
  extension: string;
}

export default function PrviewDialog({ url, extension }: Props) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Button onClick={handleOpen} size="small">
        {extension}
      </Button>
      <Dialog fullWidth onClose={handleClose} open={open}>
        <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
          <Grid item xs={6} key="title">
            <DialogTitle>
              Preview
              <CloseDialogButton closeFunction={handleClose} />
            </DialogTitle>
          </Grid>
        </Grid>
        <DialogContent>
          <Iframe url={url} width="100%" height="100%" display="block" position="relative" />
        </DialogContent>
        <DialogActions>
          <Button target="_blank" href={url} color="primary" variant="contained">
            Download
          </Button>
          <Button onClick={handleClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
