import React, { useState } from "react";
import { StateAccess } from "utils/models/formGenerator";
import { CreditScore, Deal } from "../Deals/types";
import { CreditScoreDialog } from "./CreditScoreDialog";
import CreditScoreLookup from "./CreditScoreLookup";
import { CreditBureauView } from "./CreditBureauView";
import { CreditScoreView } from "./CreditScoresView";
import { CreditPrequalifyView } from "./CreditPrequalifyView";

interface Props {
  stateAccess: StateAccess;
}

export const formatScores = (el: CreditScore | undefined) => {
  if (!el) return "";
  switch (el?.type) {
    case "applicant":
      return `${el?.applicantName ?? ""} - ${
        el?.scores?.applicant ? el?.scores?.applicant : "N/A"
      }`;
    case "coApplicant":
      return `${el?.coApplicantName ?? ""} - ${
        el?.scores.coApplicant ? el?.scores?.coApplicant : "N/A"
      }`;
    case "joint":
      return `${el?.applicantName ?? ""} - ${
        el?.scores.applicant ? el?.scores?.applicant : "N/A"
      } / ${el?.coApplicantName ?? ""} - ${
        el?.scores.coApplicant ? el?.scores?.coApplicant : "N/A"
      }`;
    default:
      return `${el?.applicantName ?? ""} - ${
        el?.scores?.applicant ? el?.scores?.applicant : "N/A"
      }`;
  }
};

export default function CheckCreditScore({ stateAccess }: Props) {
  const deal: Deal = stateAccess.get<Deal>([] as any);

  const [url, setUrl] = useState<string>("");
  const [open, setOpen] = useState(false);

  const handleShowDialog = (url: string) => {
    setUrl(url);
    setOpen(true);
  };

  const handleClose = () => {
    setUrl("");
    setOpen(false);
  };

  return (
    <>
      <CreditScoreLookup stateAccess={stateAccess} />
      <CreditScoreDialog url={url} handleClose={handleClose} open={open} />
      {deal.data.creditBureaus?.length > 0 ? (
        <CreditBureauView
          creditBureaus={deal.data.creditBureaus}
          handleShowDialog={handleShowDialog}
        />
      ) : deal.data.info.creditScores ? (
        <CreditScoreView
          creditScores={deal.data.info.creditScores}
          formatScores={formatScores}
          handleShowDialog={handleShowDialog}
        />
      ) : (
        <> </>
      )}

      {deal?.data?.creditPrequalify && deal?.data?.creditPrequalify?.length > 0 ? (
        <CreditPrequalifyView
          creditPrequalify={deal.data.creditPrequalify}
          handleShowDialog={handleShowDialog}
        />
      ) : (
        <> </>
      )}
    </>
  );
}
