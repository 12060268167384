import React from "react";
import { Authentication } from "components/SignIn/types";

export interface FormEditContext {
  enabled: boolean | undefined;
  edited: boolean;
  initialState: any;
  auth: Authentication["user"];
}
const formEditContext = React.createContext<FormEditContext>({
  enabled: undefined,
  edited: false,
  initialState: undefined,
  auth: undefined
});

export default formEditContext;
