import React, { useState } from "react";
import { Button } from "@material-ui/core/";
import { StateAccess } from "utils/models/formGenerator";
import ResetPasswordDialog from "components/Users/ForgotPassword/ResetPasswordDialog";

interface Props {
  email: string;
}

export default function ResetPassButton({ email }: Props) {
  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleDialogOpen}>
        Reset password
      </Button>
      <ResetPasswordDialog open={open} setOpen={setOpen} email={email} />
    </>
  );
}
