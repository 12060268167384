import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "app/store";
import { CreateOrFocusTabType } from "components/Layout/LayoutWrapper";
import { EntityTableUrls, EntityUrls, getEnumKeyByEnumValue } from "components/Layout/types";
import { Props, Tab, TabTypes } from "./types";

export const genTabId = <T extends TabTypes>(index: T, props?: Props<T>) => {
  if (index === "showPage") {
    return `showPage-${(props as Props<"showPage">).type}-${(props as Props<"showPage">)._id}`;
  } else if (index === "addPage") {
    return `addPage-${(props as Props<"addPage">).type}`;
  } else {
    return index;
  }
};
export const genTabProps = (
  entityType: string,
  param?: string
): { index: Tab["index"]; props: Tab["props"] } => {
  if (param === "new") {
    return {
      index: "addPage",
      props: {
        type: getEnumKeyByEnumValue(EntityUrls, entityType)
      }
    };
  } else if (param) {
    return {
      index: "showPage",
      props: {
        _id: param,
        type: getEnumKeyByEnumValue(EntityUrls, entityType)
      }
    };
  }
  return {
    index: getEnumKeyByEnumValue(EntityTableUrls, entityType) ?? EntityTableUrls["dashboard"],
    props: undefined
  };
};
const tabs = localStorage.getItem("tabs");
const parsedTabs = tabs !== null ? JSON.parse(tabs) : null;
const initialState: Tab[] = parsedTabs
  ? parsedTabs
  : [
      {
        type: "dashboard",
        label: "Dashboard",
        _id: genTabId("dashboard", {}),
        index: "dashboard",
        screenId: "leftScreen",
        isForSidebar: true,
        isForQuickAccess: false,
        isPersistent: true,
        props: {}
      }
    ];
interface NameSet {
  name: string;
  _id: string;
}
interface EditTabProps {
  id: string;
  unsavedChanges: boolean;
}
export type ScreenIds = "leftScreen" | "rightScreen";
interface ScreenSet {
  id: string;
  screenId: ScreenIds;
  destinationIndex: number;
}
export const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    addNewTab: (state: Tab[], action: PayloadAction<Tab>) => {
      state.push(action.payload as any);
      localStorage.setItem("tabs", JSON.stringify(state));
    },
    removeTab(state, action: PayloadAction<string>) {
      localStorage.setItem("tabs", JSON.stringify(state.filter((el) => el._id !== action.payload)));
      return state.filter((el) => el._id !== action.payload);
    },
    removeTabWithError(state, action: PayloadAction<any>) {
      alert(action.payload.message);
      localStorage.setItem(
        "tabs",
        JSON.stringify(
          state.filter(
            (el) =>
              el.index !== "showPage" || el.props?._id !== action?.payload?.action?.payload?._id
          )
        )
      );
      const newState = state.filter(
        (el) => el.index !== "showPage" || el.props?._id !== action?.payload?.action?.payload?._id
      );
      return newState;
    },
    setTabScreen(state, action: PayloadAction<ScreenSet>) {
      const tab = state.find((el) => el._id === action.payload.id);
      if (tab) {
        tab.screenId = action.payload.screenId;
        const index = state.indexOf(tab);
        state.splice(index, 1);
        const otherTabs = state.filter((el) => tab.screenId !== el.screenId);
        const currentTabs = state.filter((el) => tab.screenId === el.screenId);
        currentTabs.splice(action.payload.destinationIndex, 0, tab);
        state.splice(0, state.length);
        currentTabs.concat(otherTabs).forEach((el) => {
          state.push(el);
        });
        localStorage.setItem("tabs", JSON.stringify(state));
      }
    },
    setTabName(state, action: PayloadAction<NameSet>) {
      state
        .filter((el) => el._id === action.payload._id)
        .map((el) => (el.label = action.payload.name as any));
    },
    setTabUnsavedChanges(state, action: PayloadAction<EditTabProps>) {
      const tab = state.find((el) => el._id === action.payload.id);
      if (tab) {
        tab.unsavedChanges = action.payload.unsavedChanges;
      }
    }
  }
});

export const getTabUrl = (props: Tab["props"], index: Tab["index"]) =>
  index === "addPage" && props && "type" in props
    ? `/${EntityUrls[props.type]}/new`
    : index == "showPage" && props && "type" in props && "_id" in props
    ? `/${EntityUrls[props.type]}/${props._id}`
    : index !== "addPage" && index !== "showPage"
    ? `/${EntityTableUrls[index]}`
    : "";

export const addNewTab: (tab: CreateOrFocusTabType) => AppThunk = ({
  label,
  index,
  isForSidebar,
  isForQuickAccess,
  isPersistent,
  props
}: CreateOrFocusTabType): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(
    tabSlice.actions.addNewTab({
      label,
      _id: genTabId(index, props),
      index,
      url: getTabUrl(props, index),
      screenId: "leftScreen",
      isForSidebar,
      isForQuickAccess,
      isPersistent,
      props
    } as Tab)
  );
};
export const removeTab = (tabId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(tabSlice.actions.removeTab(tabId));
};

export const setTabName = (props: NameSet): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(tabSlice.actions.setTabName(props));
};
export const setTabUnsavedChanges = (props: EditTabProps): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(tabSlice.actions.setTabUnsavedChanges(props));
};
export const setTabScreen = (props: ScreenSet): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(tabSlice.actions.setTabScreen(props));
};
export default tabSlice.reducer;
