import { Model, createOptionsForSelect, OptionsForSelect } from "../../utils/models/fields";
import { FundingDocument } from "./types";
import { StateAccess } from "utils/models/formGenerator";
import DocumentTemplates from "components/DocumentTemplates/DocumentTemplates";
import React from "react";

export const generateFundingDocumentStruct = <T extends any>(
  optionsForSelect: OptionsForSelect<T>
): Model<FundingDocument> => {
  return {
    formComponent: "model",
    schema: "new_funding_document",
    name: "funding_document",
    entities: [
      {
        formComponent: "segment",
        name: "FundingDocument",
        entities: [
          {
            formComponent: "file-preview-field",
            name: "FundingDocumentPreview",
            label: "Funding document preview",

            path: ["data", "info"],
            default: null,
            required: false
          },

          {
            formComponent: "segment",
            entities: [
              {
                formComponent: "text-field",
                name: "FundingDocumentName",
                label: "Funding document name",
                width: "full",
                path: ["data", "info", "name"],
                default: null,
                required: true
              },
              {
                formComponent: "multiselect-field",
                name: "types",
                label: "Type",
                width: "full",
                path: ["data", "info", "types"],
                options: optionsForSelect
              },
              {
                formComponent: "text-field",
                name: "FundingDocumentDescription",
                label: "Funding document description",
                width: "full",
                path: ["data", "info", "description"],
                default: null,
                required: false
              }
            ]
          }
        ]
      }
    ]
  };
};

export const FundingDocumentStruct = generateFundingDocumentStruct(
  createOptionsForSelect({
    possibleValues: (stateAccess: StateAccess) => [],
    getOptionLabel: (name) => name
  })
);

export const templateFundingDocumentStruct: Model<FundingDocument> = {
  formComponent: "model",
  schema: "new_funding_document",
  name: "funding_document",
  entities: [
    {
      formComponent: "segment",
      name: "FundingDocument",
      entities: [
        {
          formComponent: "segment",
          width: "full",
          entities: [
            {
              formComponent: "text-field",
              name: "FundingDocumentName",
              label: "Funding document name",
              width: "1/2",
              path: ["data", "info", "name"],
              default: null,
              required: true
            },
            {
              formComponent: "text-field",
              name: "FundingDocumentDescription",
              label: "Funding document description",
              width: "1/2",
              path: ["data", "info", "description"],
              default: null,
              required: false
            },
            {
              formComponent: "one-to-many-field",
              component: (stateAccess: StateAccess) => (
                <DocumentTemplates
                  stateAccess={stateAccess}
                  // TODO: fix Types
                  filePath={["data", "info", "url"] as any}
                  // TODO: fix Types
                  coordinatesPath={["data", "info", "coordinates"] as any}
                />
              ),
              width: "full",
              name: "templateModal",
              label: "Template Edit Modal",
              path: null
            }
          ]
        }
      ]
    }
  ]
};
