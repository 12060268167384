import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  DialogContentText,
  Grid
} from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";

interface Props {
  RejectPrintedDocumentFunction: (value: string) => void;
  dialogState: boolean;
  setDialogState: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function RejectDialog({
  RejectPrintedDocumentFunction,
  dialogState,
  setDialogState
}: Props) {
  const [value, setValue] = useState("");

  const handleClose = () => {
    setDialogState(false);
  };

  return (
    <>
      <Dialog
        id="reject-dialog"
        open={dialogState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid container alignItems="center">
          <Grid item xs={10} key="title">
            <DialogTitle id="alert-dialog-title">
              Are you sure that you want to reject this document?
            </DialogTitle>
          </Grid>
        </Grid>
        <CloseDialogButton closeFunction={handleClose} />
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            Provide reason for rejection
          </DialogContentText>
          <TextField
            id="alert-dialog-multiline"
            label="Reason"
            multiline
            fullWidth
            maxRows={4}
            variant="filled"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained" id="cancel">
            Cancel
          </Button>
          <Button
            onClick={() => {
              RejectPrintedDocumentFunction(value);
              handleClose();
            }}
            variant="contained"
            color="primary"
            autoFocus
            id="reject"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
