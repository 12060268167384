import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

export interface RecoverApplicantRequest {
  _id: string;
}

export interface RecoverApplicantResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverApplicantRequest,
  RecoverApplicantResponse
>("RecoverApplicant", {
  request: "recover_applicant"
});

export const recoverApplicantActions = socketMessageSlice.actions;
export const recoverApplicant = socketAction;
export default socketMessageSlice.reducer;
