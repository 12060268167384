import { Badge, Box, IconButton } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { HintTooltip } from "components/common/HintTooltip";
import React, { useState } from "react";
import CustomFiltersMenu from "./CustomFiltersMenu";
import { ColumnFilter, Filters } from "./types";

export default <T extends unknown>({
  setFilters,
  columnFilters,
  label,
  filters
}: {
  setFilters: (s: Filters[string]) => void;
  columnFilters: ColumnFilter<T>[];
  label?: string;
  filters: Filters[string] | undefined;
}): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [ref, setRef] = useState<Element | undefined>();
  return (
    <>
      <Box component="span" style={{ position: "relative" }}>
        <HintTooltip title="Filter by">
          <IconButton
            size="small"
            component="button"
            onClick={(event: React.MouseEvent) => {
              setMenuOpen(true);
              setRef(event.currentTarget);
            }}
          >
            <Badge
              badgeContent={
                (filters ?? [])?.filter((filter) => filter && Object.keys(filter)?.length > 0)
                  ?.length ?? 0
              }
              color="primary"
              variant="dot"
            >
              <FilterList fontSize="small" />
            </Badge>
          </IconButton>
        </HintTooltip>
      </Box>
      {menuOpen && (
        <CustomFiltersMenu
          setFilters={setFilters}
          label={label}
          columnFilters={columnFilters}
          parentRef={ref}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          filters={filters}
        />
      )}
    </>
  );
};
