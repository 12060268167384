import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { GetRVRatesActions } from "./getRVRatesSlice";
import { RateExternal } from "./types";
import {
  ProtectiveInsurance,
  VehicleType,
  Surcharges,
  VehiclePlan
} from "components/Protective/types";

import {
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

interface Props {
  requestId: string;
  contractPrefix: string;
  insuranceState: ProtectiveInsurance;
  setInsuranceState: React.Dispatch<React.SetStateAction<ProtectiveInsurance>>;
}
interface VehicleExternal {
  VehicleYear: string;
  VehicleMake: string;
  VehicleModel: string;
  VehicleMsrp: number;
  ChassisYear: string;
  ChassisMake: string;
  ChassisModel: string;
  VehicleClassCode: string;
  VehicleType: VehicleType;
  InServiceDate: string;
}
export interface VSCRateOptionsExternal {
  BeginningOdometer: string;
  VehiclePlan: VehiclePlan;
  InServiceDate: string;
  Surcharges: Surcharges;
  EffectiveDate?: string;
}
interface DefaultState {
  error: boolean;
  isLoading: boolean;
  ratesList: RateExternal[];
  VSCRateOptions: VSCRateOptionsExternal;
  VehicleDetails: VehicleExternal;
  errorMessage: string | undefined;
}
const defaultState: DefaultState = {
  error: false,
  isLoading: false,
  VSCRateOptions: {
    BeginningOdometer: "",
    VehiclePlan: "PreOwned",
    InServiceDate: "",
    Surcharges: {
      TwoYearMfg: false,
      ThreeYearMfg: false,
      ThreePlus: false,
      BusinessUse: false,
      ConsequentialFailure: false,
      RearDiesel: false,
      FrontDiesel: false
    }
  },
  VehicleDetails: {
    VehicleYear: "",
    VehicleMake: "",
    VehicleModel: "",
    VehicleMsrp: 0,
    ChassisYear: "",
    ChassisMake: "",
    ChassisModel: "",
    VehicleClassCode: "",
    VehicleType: "MotorHomeClassA",
    InServiceDate: ""
  },
  ratesList: [],
  errorMessage: undefined
};

export default function ProtectiveRates({
  requestId,
  contractPrefix,
  insuranceState,
  setInsuranceState
}: Props) {
  const dispatch = useDispatch();
  const [state, setState] = useState(defaultState);

  const protectivePreRatesState = useSelector(
    (state: RootState) => state.getRVRatesSlice[requestId]
  );
  const handleChooseRate = (rate: RateExternal) => {
    setInsuranceState({
      ...insuranceState,
      ContractNumber: insuranceState.ContractNumber,
      ContractPrefix: insuranceState.ContractPrefix,
      VSCRateOptions: {
        ...state.VSCRateOptions,
        BeginningOdometer: parseInt(state.VSCRateOptions.BeginningOdometer),
        Surcharges: {
          RearDiesel: state.VSCRateOptions.Surcharges.RearDiesel ?? false,
          FrontDiesel: state.VSCRateOptions.Surcharges.FrontDiesel ?? false,
          BusinessUse: state.VSCRateOptions.Surcharges.BusinessUse,
          TwoYearMfg: state.VSCRateOptions.Surcharges.TwoYearMfg,
          ThreeYearMfg: state.VSCRateOptions.Surcharges.ThreeYearMfg,
          ConsequentialFailure: state.VSCRateOptions.Surcharges.ConsequentialFailure,
          ThreePlus: state.VSCRateOptions.Surcharges.ThreePlus
        }
      },

      VehicleDetails: {
        VehicleYear: parseInt(state.VehicleDetails.VehicleYear),
        VehicleMake: state.VehicleDetails.VehicleMake,
        VehicleModel: state.VehicleDetails.VehicleModel,
        VehicleMsrp: state.VehicleDetails.VehicleMsrp,
        ChassisYear: parseInt(state.VehicleDetails.ChassisYear),
        ChassisMake: state.VehicleDetails.ChassisMake ?? null,
        ChassisModel: state.VehicleDetails.ChassisModel ?? null,
        VehicleClassCode: state.VehicleDetails.VehicleClassCode ?? "0",
        VehicleType: state.VehicleDetails.VehicleType,
        InServiceDate: state.VehicleDetails.InServiceDate
      },
      type: "Protective",
      provider: "Protective",
      chosenRate: {
        ContractFormID: rate.ContractFormID,
        Coverage: rate.Coverage,
        CoverageCode: rate.CoverageCode,
        CoverageSortOrder: rate.CoverageSortOrder,
        CoverageTermMiles: rate.CoverageTermMiles,
        CoverageTermMinMonths: rate.CoverageTermMinMonths,
        months: rate.CoverageTermMonths,
        dealerCostPrice: rate.DealerCost,
        Deductible: rate.Deductible,
        OrderNumber: rate.OrderNumber,
        ProductClass: rate.ProductClass,
        ProductClassCode: rate.ProductClassCode,
        ProductType: rate.ProductType,
        RateNumber: rate.RateNumber,
        retailPrice: rate.RetailPrice,
        remitPrice: rate.DealerCost,
        profit: parseFloat((rate.RetailPrice - rate.DealerCost).toFixed(2)),
        insuranceType: "service warranty",
        soldBy: insuranceState?.chosenRate?.soldBy
      }
    });
  };

  useEffect(() => {
    if (protectivePreRatesState !== undefined && protectivePreRatesState.status !== undefined) {
      switch (protectivePreRatesState.status) {
        case "error":
          setState({ ...state, isLoading: false, error: true });
          dispatch({
            type: GetRVRatesActions.none.type,
            payload: { requestId }
          });
          break;
        case "waiting":
          setState({ ...state, isLoading: true, error: false });
          dispatch({
            type: GetRVRatesActions.none.type,
            payload: { requestId }
          });
          break;
        case "success":
          const RVRateQuoteResponse =
            protectivePreRatesState?.data?.message?.GetRVRatesResult?.RVs?.RVRateQuoteResponse[0];
          let error = "";
          let hasError = false;

          if (RVRateQuoteResponse?.RVErrors?.ErrorMessage) {
            error = RVRateQuoteResponse?.RVErrors?.ErrorMessage.map(
              (x: { Message: string }) => x.Message
            ).join(",");
            hasError = true;
          }
          if (RVRateQuoteResponse?.RateQuoteNumbers?.RateQuoteNumber[0]?.RateNumberErrors) {
            hasError = true;
            error +=
              ",\n" +
              RVRateQuoteResponse?.RateQuoteNumbers?.RateQuoteNumber[0]?.RateNumberErrors?.RateNumberError.map(
                (x: { Message: string }) => x.Message
              ).join(",");
          }
          const rateList = RVRateQuoteResponse?.RVRateQuotes?.RVRateQuote?.RVRateQuote;
          const VSCRateOptions = RVRateQuoteResponse.VSCRateOptions;
          const VehicleDetails = RVRateQuoteResponse.VehicleDetails;

          setState({
            ...state,
            ratesList: rateList,
            VSCRateOptions,
            VehicleDetails,
            isLoading: false,
            error: hasError,
            errorMessage: error ? error : ""
          });
          dispatch({
            type: GetRVRatesActions.none.type,
            payload: { requestId }
          });
      }
    }
  }, [dispatch, requestId, state, protectivePreRatesState]);
  const reducedRates =
    state?.ratesList?.reduce(
      (acc: { [key: string]: RateExternal[] }, curr: RateExternal) =>
        acc[curr.Coverage]
          ? { ...acc, [curr.Coverage]: [...acc[curr.Coverage], curr] }
          : { ...acc, [curr.Coverage]: [curr] },
      {}
    ) ?? {};

  return (
    <>
      {!state?.error ? (
        state?.isLoading ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : Object.values(reducedRates).length > 0 ? (
          Object.values(reducedRates).map((el) => {
            return (
              <List
                key={`${el[0].Coverage}`}
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#E8E8E8",
                  marginTop: "10px",
                  marginBottom: "5px",
                  height: "auto"
                }}
              >
                <ListItem>
                  <div style={{ width: "100%" }}>
                    <Typography variant="h6" component="h6">
                      {el[0].Coverage}
                    </Typography>
                    {el.map((rate) => (
                      <div
                        key={`rate-${rate.OrderNumber}`}
                        style={{
                          display: "flex",
                          width: "auto",
                          marginTop: "10px",
                          borderRadius: "8px",
                          backgroundColor: "#F8F8F8"
                        }}
                      >
                        <ListItemText
                          style={{ paddingTop: "8px", paddingLeft: "10px" }}
                          primary={`Months: ${rate.CoverageTermMonths}  /  Miles: ${rate.CoverageTermMiles}  /  Dealer cost: ${rate.DealerCost} / Retail price: ${rate.RetailPrice} `}
                        />
                        <IconButton
                          aria-label="add"
                          onClick={() => handleChooseRate(rate)}
                          style={{ alignSelf: "flex-end" }}
                        >
                          <AddCircleIcon style={{ color: "#50A538" }} />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                </ListItem>
              </List>
            );
          })
        ) : (
          "No rates for this vehicle!"
        )
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6">{state.errorMessage}</Typography>
        </div>
      )}
    </>
  );
}
