import { createListSlice } from "../Middlewares/listSliceCreator";
import { Note } from "./types";

const { ListSlice, getList } = createListSlice<Note>({
  name: "NoteList",
  request_topic: "all_notes",
  reducers: {}
});
export default ListSlice.reducer;
export const getNotesList = getList;
export const listNotesActions = ListSlice.actions;
export const removeNotesList = ListSlice.actions.removeList;
