import UserIdPreview from "components/Users/UserIdPreview";
import queryString from "query-string";
import { default as React } from "react";
import States from "us-states";
import { EntityType, PermissionEntityData, permissionStructs } from "utils/entitySlice";
import { capitalize, getStateLabelByState } from "utils/functions";
import { FormComponent, Model, createOptionsForSelect } from "utils/models/fields";
import { RenderSet, StateAccess, setByPath } from "utils/models/formGenerator";
import { EntityTypes } from "utils/types";
import { DefaultAction, PermissionStruct, Role, defaultActions } from "./types";
const changeValue = (obj: any, value: any) => {
  const newObj: any = {};
  if (typeof obj === "object") {
    for (const keys in obj) {
      if (typeof obj[keys] === "object" && obj[keys].hasPermission === undefined) {
        newObj[keys] = changeValue(obj[keys], value);
      } else {
        newObj[keys] = { ...obj[keys], hasPermission: value };
      }
    }
  }
  return newObj;
};
export const hasPermissionMatch = (state: any, value: any): any => {
  if (!state) return false;
  return Object.values(state).some((x: any) =>
    x && typeof x === "object" && !x.hasOwnProperty("hasPermission")
      ? hasPermissionMatch(x, value) === true
      : x?.hasPermission === value
  );
};

const rKeys = (o: any, path = ""): any => {
  if (!o || typeof o !== "object" || o?._type) return path;
  return Object.keys(o).map((key) => rKeys(o[key], path ? [path, key].join(".") : key));
};

const objectPaths = (o: any) => {
  return rKeys(o).toString().split(",");
};

const isCheckedLeaf = (
  stateAccess: StateAccess,
  entityType: EntityType,
  action: DefaultAction,
  prevPath: string[],
  key: string
) => {
  const checked = stateAccess.get<Role>([
    "data",
    "info",
    "permissions",
    entityType,
    action,
    "dataPermissions",
    ...prevPath,
    key,
    "hasPermission"
  ]);

  return checked;
};
const isChecked = (
  stateAccess: StateAccess,
  entityType: EntityType,
  action: DefaultAction,
  prevPath: string[],
  key: string
) => {
  const state = stateAccess.get<Role>([
    "data",
    "info",
    "permissions",
    entityType,
    action,
    "dataPermissions",
    ...prevPath,
    key
  ]);
  return state && hasPermissionMatch(state, true) && !hasPermissionMatch(state, false);
};

const isIndeterminate = (
  stateAccess: StateAccess,
  entityType: EntityType,
  action: DefaultAction,
  prevPath: string[],
  key: string
) => {
  const state = stateAccess.get<Role>([
    "data",
    "info",
    "permissions",
    entityType,
    action,
    "dataPermissions",
    ...prevPath,
    key
  ]);
  return state && hasPermissionMatch(state, true) && hasPermissionMatch(state, false);
};

const isDisabledLeaf = (
  stateAccess: StateAccess,
  entityType: EntityType,
  action: DefaultAction,
  prevPath: string[],
  key: string
) =>
  action === "update" &&
  stateAccess.get<Role>([
    "data",
    "info",
    "permissions",
    entityType,
    "read",
    "dataPermissions",
    ...prevPath,
    key,
    "hasPermission"
  ]) === false;

const isDisabled = (
  stateAccess: StateAccess,
  entityType: EntityType,
  action: DefaultAction,
  prevPath: string[],
  key: string
) => {
  if (action === "update") {
    const read: Role["data"]["info"]["permissions"][EntityType]["read"] = stateAccess.get<Role>([
      "data",
      "info",
      "permissions",
      entityType,
      "read"
    ]);
    if (read?.hasCustomPermission) {
      return !hasPermissionMatch(
        stateAccess.get<Role>([
          "data",
          "info",
          "permissions",
          entityType,
          "read",
          "dataPermissions",
          ...prevPath,
          key
        ]),
        true
      );
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const toggle = (
  stateAccess: StateAccess,
  checked: boolean,
  entityType: EntityType,
  action: DefaultAction,
  prevPath: string[],
  key: string
) => {
  const newStruct = changeValue(
    stateAccess.get<Role>([
      "data",
      "info",
      "permissions",
      entityType,
      action,
      "dataPermissions",
      ...prevPath,
      key
    ]),
    checked
  );
  let state = stateAccess.get([]);
  state = setByPath(
    state,
    ["data", "info", "permissions", entityType, action, "dataPermissions", ...prevPath, key],
    newStruct
  );

  if (action === "read" && checked === false) {
    state = setByPath(
      state,
      ["data", "info", "permissions", entityType, "update", "dataPermissions", ...prevPath, key],
      newStruct
    );
  }
  stateAccess.set<Role>([], state);
};
const toggleLeaf = (
  stateAccess: StateAccess,
  checked: boolean,
  entityType: EntityType,
  action: DefaultAction,
  prevPath: string[],
  key: string
) => {
  let state = setByPath(
    stateAccess.get([]),
    [
      "data",
      "info",
      "permissions",
      entityType,
      action,
      "dataPermissions",
      ...prevPath,
      key,
      "hasPermission"
    ],
    checked
  );
  if (action === "read" && checked === false) {
    state = setByPath(
      state,
      [
        "data",
        "info",
        "permissions",
        entityType,
        "update",
        "dataPermissions",
        ...prevPath,
        key,
        "hasPermission"
      ],
      checked
    );
  }
  stateAccess.set<Role>(["data"], state.data);
};

const structToModel = <T extends EntityType>(
  struct: PermissionStruct<PermissionEntityData<T>>,
  entityType: EntityType,
  action: DefaultAction,
  prevPath: string[] = []
): FormComponent<T>[] => {
  return Object.entries(struct)
    .sort(([key1], [key2]) => key1.localeCompare(key2))
    .map(([key, child]) => {
      if (
        child?._type === "node" ||
        child?._type === "multy-node" ||
        child?._type === "relation-node"
      ) {
        return {
          formComponent: "segment",
          width: "full",
          entities: [
            ...(child?._type === "relation-node"
              ? [
                  {
                    formComponent: "hidden-field",
                    name: "relationParent",
                    label: "relationParent",
                    width: "1/4",
                    path: [
                      "data",
                      "info",
                      "permissions",
                      entityType,
                      action,
                      "dataPermissions",
                      ...prevPath,
                      key,
                      "relationName"
                    ],
                    default: child.relationName
                  } as FormComponent<T>
                ]
              : []),

            {
              formComponent: "checkbox-field",
              name: key,
              label: child.name,
              width: "1/2",
              toggle: (stateAccess, checked) =>
                toggleLeaf(stateAccess, checked, entityType, action, prevPath, key),
              isDisabled: (stateAccess) =>
                isDisabledLeaf(stateAccess, entityType, action, prevPath, key),
              isChecked: (stateAccess) =>
                isDisabledLeaf(stateAccess, entityType, action, prevPath, key)
                  ? false
                  : isCheckedLeaf(stateAccess, entityType, action, prevPath, key),
              path: [
                "data",
                "info",
                "permissions",
                entityType,
                action,
                "dataPermissions",
                ...prevPath,
                key,
                "hasPermission"
              ],
              default: false
            },
            {
              formComponent: "list-model",
              width: "1/2",
              name: "Filters",
              show: (stateAccess) => {
                return stateAccess.get<Role>([
                  "data",
                  "info",
                  "permissions",
                  entityType,
                  action,
                  "dataPermissions",
                  ...prevPath,
                  key,
                  "hasPermission"
                ]);
              },
              path: [
                "data",
                "info",
                "permissions",
                entityType,
                action,
                "dataPermissions",
                ...prevPath,
                key,
                "filters"
              ],
              entity: {
                formComponent: "segment",
                width: "full",
                entities: [
                  {
                    formComponent: "select-field",
                    name: "Path",
                    label: "Path",
                    width: "1/4",
                    path: ["path"],
                    options: createOptionsForSelect({
                      possibleValues: () =>
                        objectPaths(permissionStructs[entityType as EntityType]),
                      getOptionLabel: (x) => x,
                      getSelectedOption: (x, y) => x === y
                    }),
                    default: null
                  },
                  {
                    formComponent: "select-field",
                    name: "Operation",
                    label: "Operation",
                    width: "1/4",
                    path: ["operation"],
                    options: createOptionsForSelect({
                      possibleValues: () => ["==", "!=", ">", ">=", "<", "<="],
                      getOptionLabel: (x) => x,
                      getSelectedOption: (x, y) => x === y
                    }),
                    default: queryString.parse(location.search).test === "true" ? null : "=="
                  },
                  {
                    formComponent: "select-field",
                    name: "criteriaType",
                    label: "Criteria Type",
                    width: "1/4",
                    path: ["criteriaType"],
                    options: createOptionsForSelect({
                      possibleValues: () => [
                        "text",
                        "currentUserId",
                        "user",
                        "state",
                        "boolean",
                        "date"
                      ],
                      getOptionLabel: (x) =>
                        x === "currentUserId" ? "Current User" : capitalize(x) ?? "",
                      getSelectedOption: (x, y) => x === y
                    }),
                    default: queryString.parse(location.search).test === "true" ? null : "text"
                  },
                  {
                    formComponent: "text-field",
                    name: "criteriaText",
                    label: "Criteria Text",
                    width: "1/4",
                    show: (stateAccess) => stateAccess.get(["criteriaType"]) === "text",
                    required: true,
                    path: ["criteria"],
                    default: null
                  },
                  {
                    formComponent: "date-field",
                    name: "criteriaText",
                    label: "Criteria Date",
                    width: "1/4",
                    show: (stateAccess) => stateAccess.get(["criteriaType"]) === "date",
                    required: true,
                    path: ["criteria"],
                    default: null
                  },
                  {
                    formComponent: "select-field",
                    name: "criteriaBoolean",
                    label: "Criteria Boolean",
                    width: "1/4",
                    show: (stateAccess) => stateAccess.get(["criteriaType"]) === "boolean",
                    path: ["criteria"],
                    options: createOptionsForSelect({
                      possibleValues: () => [true, false],
                      getOptionLabel: (x) => x?.toString(),
                      getSelectedOption: (x, y) => x === y
                    }),
                    default: null
                  },
                  {
                    formComponent: "select-field",
                    name: "criteriaState",
                    label: "Criteria State",
                    width: "1/4",
                    show: (stateAccess) => stateAccess.get(["criteriaType"]) === "state",
                    path: ["criteria"],
                    options: createOptionsForSelect({
                      possibleValues: () => Object.keys(States),
                      getOptionLabel: (x) => getStateLabelByState(x),
                      getSelectedOption: (x, y) => x === y
                    }),
                    default: null
                  },
                  {
                    formComponent: "one-to-many-field",
                    component: (
                      stateAccess: StateAccess,
                      mainstateAccess: StateAccess,
                      renderSet: RenderSet
                    ) => (
                      <UserIdPreview
                        stateAccess={stateAccess}
                        renderSet={renderSet}
                        path={["criteria"]}
                        name="criteria"
                        label="Users"
                      />
                    ),
                    name: "User",
                    label: "User",
                    width: "1/6",
                    path: ["criteria"],
                    show: (stateAccess) => stateAccess.get(["criteriaType"]) === "user",
                    default: null,
                    valueToString: (el) => el.firstName
                  }
                ]
              }
            }
          ]
        };
      }
      return {
        formComponent: "segment",
        width: "full",
        entities: [
          {
            formComponent: "checkbox-field",
            name: key,
            style: { fontWeight: "bold" },
            label: capitalize(key),
            width: "full",
            toggle: (stateAccess, checked) =>
              toggle(stateAccess, checked, entityType, action, prevPath, key),
            isDisabled: (stateAccess) => isDisabled(stateAccess, entityType, action, prevPath, key),
            isChecked: (stateAccess) => isChecked(stateAccess, entityType, action, prevPath, key),
            isIndeterminate: (stateAccess) =>
              isDisabled(stateAccess, entityType, action, prevPath, key)
                ? false
                : isIndeterminate(stateAccess, entityType, action, prevPath, key),
            path: [
              "data",
              "info",
              "permissions",
              entityType,
              action,
              "dataPermissions",
              ...prevPath,
              key
            ],
            default: false
          },
          {
            formComponent: "segment",
            width: "full",
            style: { marginLeft: "20px" },
            entities: structToModel(child, entityType, action, [...prevPath, key])
          }
        ]
      };
    });
};

export const roleStruct = (): Model<Role> => {
  return {
    formComponent: "model",
    schema: "update_role",
    name: "role",
    entities: [
      {
        formComponent: "segment",
        name: "Role info",
        entities: [
          {
            formComponent: "text-field",
            name: "name",
            label: "Name",
            width: "1/4",
            required: true,
            path: ["data", "info", "name"],
            default: null
          }
        ]
      },
      {
        formComponent: "segment",
        name: "Permissions",
        width: "full",
        style: { whiteSpace: "nowrap" },
        entities: Object.keys(EntityTypes)
          .sort((a, b) => a.localeCompare(b))
          .map((entityType) => ({
            formComponent: "segment",
            width: "full",
            style: {
              paddingLeft: "10px",
              boxShadow:
                "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
            },
            name: capitalize(entityType.replace(/_/g, " ")),
            entities: [
              ...defaultActions.map(
                (action) =>
                  ({
                    formComponent: "segment",

                    show: (stateAccess) =>
                      action === "read" ||
                      stateAccess.get<Role>([
                        "data",
                        "info",
                        "permissions",
                        entityType,
                        "read",
                        "hasPermission"
                      ]),
                    width: ["read", "update"].includes(action) ? "1/3" : "1/6",
                    entities: [
                      {
                        formComponent: "checkbox-field",
                        name: "hasPermission",
                        label: capitalize(action),
                        width: "1/2",
                        toggle: (stateAccess: StateAccess, checked) => {
                          stateAccess.set<Role>(
                            ["data", "info", "permissions", entityType, action, "hasPermission"],
                            checked
                          );
                        },
                        isDisabled: () => false,
                        isChecked: (stateAccess: StateAccess) =>
                          stateAccess.get<Role>([
                            "data",
                            "info",
                            "permissions",
                            entityType,
                            action,
                            "hasPermission"
                          ]),
                        path: ["data", "info", "permissions", entityType, action, "hasPermission"],
                        default: false
                      },

                      ...(["read", "update"].includes(action)
                        ? [
                            {
                              formComponent: "checkbox-field",
                              name: "hasCustomPermission",
                              label: "Custom",
                              width: "1/2",
                              show: (stateAccess) =>
                                stateAccess.get<Role>([
                                  "data",
                                  "info",
                                  "permissions",
                                  entityType,
                                  action,
                                  "hasPermission"
                                ]),
                              toggle: (stateAccess: StateAccess, checked) => {
                                stateAccess.set<Role>(
                                  [
                                    "data",
                                    "info",
                                    "permissions",
                                    entityType,
                                    action,
                                    "hasCustomPermission"
                                  ],
                                  checked
                                );
                              },
                              isChecked: (stateAccess: StateAccess) =>
                                stateAccess.get<Role>([
                                  "data",
                                  "info",
                                  "permissions",
                                  entityType,
                                  action,
                                  "hasCustomPermission"
                                ]),
                              path: [
                                "data",
                                "info",
                                "permissions",
                                entityType,
                                action,
                                "hasCustomPermission"
                              ],
                              default: false
                            } as FormComponent<EntityTypes>
                          ]
                        : []),

                      {
                        formComponent: "segment",
                        width: "full",
                        show: (stateAccess) =>
                          stateAccess.get<Role>([
                            "data",
                            "info",
                            "permissions",
                            entityType,
                            action,
                            "hasCustomPermission"
                          ]),
                        entities: [
                          ...(["read", "update"].includes(action)
                            ? structToModel(
                                permissionStructs[entityType as EntityType],
                                entityType as EntityType,
                                action
                              )
                            : [])
                        ]
                      }
                    ]
                  } as FormComponent<Role>)
              ),
              {
                formComponent: "list-model",
                width: "full",
                name: "Filters",
                show: (stateAccess) =>
                  stateAccess.get<Role>([
                    "data",
                    "info",
                    "permissions",
                    entityType,
                    "read",
                    "hasPermission"
                  ]),
                path: ["data", "info", "permissions", entityType, "read", "filters"],
                entity: {
                  formComponent: "segment",
                  width: "full",
                  entities: [
                    {
                      formComponent: "text-field",
                      name: "Path",
                      label: "Path",
                      width: "1/4",
                      path: ["path"],

                      default: null
                    },
                    {
                      formComponent: "select-field",
                      name: "Operation",
                      label: "Operation",
                      width: "1/4",
                      path: ["operation"],
                      options: createOptionsForSelect({
                        possibleValues: () => ["==", "!=", ">", ">=", "<", "<="],
                        getOptionLabel: (x) => x,
                        getSelectedOption: (x, y) => x === y
                      }),
                      default: queryString.parse(location.search).test === "true" ? null : "=="
                    },
                    {
                      formComponent: "select-field",
                      name: "criteriaType",
                      label: "Criteria Type",
                      width: "1/4",
                      path: ["criteriaType"],
                      options: createOptionsForSelect({
                        possibleValues: () => [
                          "text",
                          "currentUserId",
                          "user",
                          "state",
                          "boolean",
                          "date"
                        ],
                        getOptionLabel: (x) =>
                          x === "currentUserId" ? "Current User" : capitalize(x) ?? "",
                        getSelectedOption: (x, y) => x === y
                      }),
                      default: queryString.parse(location.search).test === "true" ? null : "text"
                    },
                    {
                      formComponent: "text-field",
                      name: "criteriaText",
                      label: "Criteria Text",
                      width: "1/4",
                      show: (stateAccess) => stateAccess.get(["criteriaType"]) === "text",
                      required: true,
                      path: ["criteria"],
                      default: null
                    },
                    {
                      formComponent: "date-field",
                      name: "criteriaText",
                      label: "Criteria Date",
                      width: "1/4",
                      show: (stateAccess) => stateAccess.get(["criteriaType"]) === "date",
                      required: true,
                      path: ["criteria"],
                      default: null
                    },
                    {
                      formComponent: "select-field",
                      name: "criteriaBoolean",
                      label: "Criteria Boolean",
                      width: "1/4",
                      show: (stateAccess) => stateAccess.get(["criteriaType"]) === "boolean",
                      path: ["criteria"],
                      options: createOptionsForSelect({
                        possibleValues: () => [true, false],
                        getOptionLabel: (x) => x?.toString(),
                        getSelectedOption: (x, y) => x === y
                      }),
                      default: null
                    },
                    {
                      formComponent: "select-field",
                      name: "criteriaState",
                      label: "Criteria State",
                      width: "1/4",
                      show: (stateAccess) => stateAccess.get(["criteriaType"]) === "state",
                      path: ["criteria"],
                      options: createOptionsForSelect({
                        possibleValues: () => Object.keys(States),
                        getOptionLabel: (x) => getStateLabelByState(x),
                        getSelectedOption: (x, y) => x === y
                      }),
                      default: null
                    },
                    {
                      formComponent: "one-to-many-field",
                      component: (
                        stateAccess: StateAccess,
                        mainstateAccess: StateAccess,
                        renderSet: RenderSet
                      ) => (
                        <UserIdPreview
                          stateAccess={stateAccess}
                          renderSet={renderSet}
                          path={["criteria"]}
                          name="criteria"
                          label="Users"
                        />
                      ),
                      name: "User",
                      label: "User",
                      width: "1/6",
                      path: ["criteria"],
                      show: (stateAccess) => stateAccess.get(["criteriaType"]) === "user",
                      default: null,
                      valueToString: (el) => el.firstName
                    }
                  ]
                }
              }
            ]
          }))
      }
    ]
  };
};
