import {
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from "@material-ui/core";

import CancelIcon from "@material-ui/icons/Cancel";
import WarningIcon from "@material-ui/icons/Warning";
import { AdditionalAddress, Lender } from "components/Lenders/types";
import { HintTooltip } from "components/common/HintTooltip";
import React, { useEffect, useState } from "react";
import { StateAccess, setByPath } from "utils/models/formGenerator";
import { Deal, Insurances, isCustom } from "../types";
import AdditionalText from "./AdditionalText";
import {
  DocumentsTypesLiterals,
  ModalAdditionalRequiredDocumentType,
  ModalAllInsurancesDocumentType,
  ModalCreditScoreType,
  ModalDocumentTemplateType,
  ModalDocuments,
  ModalOtherRequiredDocumentType,
  PackType,
  checkboxType,
  checkboxTypes,
  isInsuranceDocument,
  isModalDocumentTemplateType
} from "./types";
import States from "us-states";

interface HeaderProps {
  eSign: boolean;
  eSignDisabled: boolean;
  handleChange: () => void;
  stateAccess: StateAccess;
}

function HeaderText({ stateAccess, eSign, eSignDisabled, handleChange }: HeaderProps) {
  const dealerSupportsEsigning = stateAccess.get<Deal>([
    "data",
    "dealership",
    "data",
    "info",
    "eSign"
  ]);

  const lenderSupportsEsigning = stateAccess.get<Deal>(["data", "lender", "data", "info", "eSign"]);

  return (
    <Grid container style={{ borderBottom: "1px solid #bfbcbc", marginBottom: 10, height: 60 }}>
      <Grid item xs={7}>
        <Typography variant="h6">Checklist</Typography>
        {lenderSupportsEsigning && dealerSupportsEsigning && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={eSign && !eSignDisabled}
                  disabled={eSignDisabled}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label={`eSign`}
            />
            {eSignDisabled && (
              <HintTooltip title={"Disabled because of custom insurance"} arrow>
                <WarningIcon style={{ color: "#eed202" }} />
              </HintTooltip>
            )}
          </>
        )}
      </Grid>

      <Grid item xs={5} container style={{ textAlign: "center" }}>
        <Grid item xs={12} container>
          <Typography style={{ width: "100%" }} variant="subtitle2">
            Can be returned by:
          </Typography>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={eSign ? 3 : 4}>
            <Typography style={{ width: "100%" }} variant="caption">
              Returned?
            </Typography>
          </Grid>
          <Grid item xs={eSign ? 3 : 4}>
            <Typography style={{ width: "100%" }} variant="caption">
              Dealer
            </Typography>
          </Grid>
          <Grid item xs={eSign ? 3 : 4}>
            <Typography style={{ width: "100%" }} variant="caption">
              Applicant
            </Typography>
          </Grid>
          {eSign && (
            <Grid item xs={3}>
              <Typography style={{ width: "100%" }} variant="caption">
                eSign
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

function Body({
  eSign,
  stateAccess,
  documents,
  handleChange,
  handleChangeAddress,
  setDocuments
}: {
  eSign: boolean;
  stateAccess: StateAccess;
  documents: ModalDocuments;
  handleChange: (
    id: string,
    checkboxType: checkboxType | "optionalSignature",
    documents: ModalDocuments
  ) => void;
  handleChangeAddress: (
    id: string,
    address: AdditionalAddress | undefined,
    documents: ModalDocuments
  ) => void;
  setDocuments: (newDocuments: ModalDocuments) => void;
}) {
  const dealProducts: Insurances =
    stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "insurances"]) ?? [];
  const lender: Lender = stateAccess.get<Deal>(["data", "lender"]);
  console.log(documents);
  return (
    <Grid container>
      {documents.map((document) => {
        const { _id, name, additionalProperties, type, address } = document;
        const isInvalid =
          type === DocumentsTypesLiterals.insurance_document &&
          !dealProducts?.find((product) => product.id === _id);
        return (
          <Grid
            item
            xs={12}
            container
            key={name}
            style={{
              borderRadius: 5,
              background: isInvalid ? "#ffcfcf" : "white"
            }}
          >
            <Grid item xs={7} container alignItems="center">
              <Typography>
                <HintTooltip title="Click here to remove from checklist.">
                  <IconButton
                    id="close-tabs-button"
                    size="small"
                    style={{ marginRight: 5, marginTop: -2 }}
                    onClick={() => {
                      setDocuments(documents.filter((d) => d._id !== _id));
                    }}
                  >
                    <CancelIcon style={{ color: "red" }} fontSize="inherit" />
                  </IconButton>
                </HintTooltip>

                {isInvalid ? (
                  <HintTooltip
                    title={
                      <span style={{ fontSize: 14 }}>
                        The product has been removed from the deal.
                      </span>
                    }
                    style={{ marginLeft: "5px" }}
                    arrow
                  >
                    <span>{name}</span>
                  </HintTooltip>
                ) : (
                  <>{name}</>
                )}
              </Typography>
            </Grid>

            <Grid item xs={5} container>
              {checkboxTypes.map((item, index) =>
                item === "eSign" ? (
                  eSign &&
                  ((isModalDocumentTemplateType(document) && document?.forEsign) ||
                    isInsuranceDocument(document)) ? (
                    <Grid item xs={3} key={index} style={{ textAlign: "center" }}>
                      <Checkbox
                        checked={additionalProperties[item]}
                        onChange={() => handleChange(_id ?? "", item, documents)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  ) : null
                ) : (
                  <Grid item xs={eSign ? 3 : 4} key={index} style={{ textAlign: "center" }}>
                    <Checkbox
                      checked={additionalProperties[item]}
                      disabled={eSign && additionalProperties["eSign"]}
                      onChange={() => handleChange(_id ?? "", item, documents)}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </Grid>
                )
              )}
            </Grid>
            {isModalDocumentTemplateType(document) &&
              document?.documentData?.data?.info?.multipleAddresses &&
              lender?.data?.info?.additionalAddresses?.length > 0 && (
                <Grid item xs={12} container>
                  <Tooltip
                    interactive
                    title={[
                      lender?.data?.info?.address,
                      lender?.data?.info?.city,
                      [States[lender?.data?.info?.state]?.prefix, lender?.data?.info?.zipCode]
                        .filter((x) => x)
                        .join(" ")
                    ]
                      .filter((x) => x)
                      .join(", ")}
                  >
                    <Chip
                      label="Default"
                      style={{ marginRight: 5, borderRadius: 5 }}
                      color={
                        lender.data?.info?.additionalAddresses?.find((a) => {
                          return a.name === address?.name;
                        }) === undefined
                          ? "primary"
                          : "default"
                      }
                      size="small"
                      onClick={() => handleChangeAddress(_id ?? "", undefined, documents)}
                    />
                  </Tooltip>
                  {lender.data.info.additionalAddresses.map((a, index) => {
                    return (
                      <Tooltip
                        key={index}
                        interactive
                        title={[
                          a?.address,
                          a?.city,
                          [States[a?.state]?.prefix, a?.zipCode].filter((x) => x).join(" ")
                        ]
                          .filter((x) => x)
                          .join(", ")}
                      >
                        <Chip
                          label={a.name}
                          style={{ marginRight: 5, borderRadius: 5 }}
                          color={a.name === address?.name ? "primary" : "default"}
                          size="small"
                          onClick={() => handleChangeAddress(_id ?? "", a, documents)}
                        />
                      </Tooltip>
                    );
                  })}
                </Grid>
              )}
          </Grid>
        );
      })}
    </Grid>
  );
}

interface Props {
  stateAccess: StateAccess;
  documents: ModalDocuments;
  setDocuments: (newDocuments: ModalDocuments) => void;
  checklistStateAccess: StateAccess;
}

const Checklist = ({ stateAccess, documents, setDocuments, checklistStateAccess }: Props) => {
  const eSign: boolean = checklistStateAccess.get(["data", "info", "eSign"]) || false;
  const [eSignDisabled, setEsignDisabled] = useState(false);
  const handleChangeESign = (documents: ModalDocuments) => () => {
    const newEsign = !eSign;

    const newDocuments = documents.map((document) => {
      return {
        ...document,
        ...(newEsign && isDocumentForEsign(document)
          ? { packType: PackType.eSign }
          : { packType: PackType.email }),
        additionalProperties: {
          ...(newEsign && isDocumentForEsign(document)
            ? { required: true, applicant: true, dealerships: true, eSign: true }
            : {
                // @ts-ignore
                required: document?.documentData?.data?.info?.requiredToBeReturned || true,
                // @ts-ignore
                dealerships: document?.documentData?.data?.info?.requestedBy?.dealership || false,
                // @ts-ignore
                applicant: document?.documentData?.data?.info?.requestedBy?.applicant || false,
                eSign: false
              })
        }
      };
    });
    checklistStateAccess.set(
      [],
      setByPath(
        setByPath(checklistStateAccess.get([]), ["data", "info", "eSign"], newEsign),
        ["data", "info", "documents"],
        newDocuments
      )
    );
  };
  const handleChange = (
    id: string,
    checkboxType: checkboxType | "optionalSignature",
    documents: ModalDocuments
  ) => {
    setDocuments(
      documents.map((document) => {
        if (document._id === id) {
          const additionalProperties = {
            ...document.additionalProperties,
            [checkboxType]: !document.additionalProperties[checkboxType],
            ...(checkboxType === "required" && document.additionalProperties[checkboxType]
              ? { dealerships: false, applicant: false, eSign: false }
              : {})
          };
          return {
            ...document,
            ...(additionalProperties["eSign"]
              ? { packType: PackType.eSign }
              : { packType: PackType.email }),
            additionalProperties: additionalProperties
          };
        } else return document;
      })
    );
  };
  const handleChangeAddress = (
    id: string,
    address: AdditionalAddress | undefined,
    documents: ModalDocuments
  ) => {
    setDocuments(
      documents.map((document) => {
        if (document._id === id) {
          return {
            ...document,
            address: address
          };
        } else return document;
      })
    );
  };
  const isESignProvider = (provider: string) => {
    switch (provider) {
      case "ASC Warranty":
      case "APCO - EASYCARE":
      case "Gold Standard Automotive Network":
      case "GWC":
        return true;
      default:
        return false;
    }
  };
  const isInsuranceDocumentForEsign = (
    doc:
      | ModalDocumentTemplateType
      | ModalAdditionalRequiredDocumentType
      | ModalAllInsurancesDocumentType
      | ModalOtherRequiredDocumentType
      | ModalCreditScoreType
  ) => isInsuranceDocument(doc) && isESignProvider(doc.provider);

  const isDocumentForEsign = (
    document:
      | ModalDocumentTemplateType
      | ModalAdditionalRequiredDocumentType
      | ModalAllInsurancesDocumentType
      | ModalOtherRequiredDocumentType
      | ModalCreditScoreType
  ) =>
    (isModalDocumentTemplateType(document) && document?.forEsign) ||
    isInsuranceDocumentForEsign(document);

  useEffect(() => {
    const customInsuranceDoc = documents.find(
      (document) =>
        document.type === DocumentsTypesLiterals.insurance_document &&
        isCustom(document.documentData)
    );
    if (!customInsuranceDoc) {
      setEsignDisabled(false);
    } else if (customInsuranceDoc) {
      setEsignDisabled(true);
    }
    if (eSign) {
      setDocuments(
        documents.map((document) => {
          return {
            ...document,
            packType: isDocumentForEsign(document) ? PackType.eSign : PackType.email,
            additionalProperties: {
              ...(isDocumentForEsign(document)
                ? {
                    required: true,
                    applicant: true,
                    dealerships: true,
                    eSign: true
                  }
                : {
                    required: true,
                    dealerships: false,
                    applicant: false,
                    eSign: false
                  })
            }
          };
        })
      );
    }
  }, [documents.length]);
  return (
    <Paper
      elevation={3}
      style={{
        border: "1px solid #cecece",
        height: "100%",
        overflowY: "auto",
        padding: 10,
        display: "flex",
        flexDirection: "column"
      }}
    >
      <HeaderText
        stateAccess={stateAccess}
        eSign={eSign}
        eSignDisabled={eSignDisabled}
        handleChange={handleChangeESign(documents)}
      />
      <Body
        eSign={eSign}
        setDocuments={setDocuments}
        handleChange={handleChange}
        handleChangeAddress={handleChangeAddress}
        documents={documents}
        stateAccess={stateAccess}
      />
      <AdditionalText
        checklistStateAccess={checklistStateAccess}
        path={["data", "info", "optionalText"]}
      />
    </Paper>
  );
};

export default Checklist;
