import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { auth } from "../../firebase/firebase";
import { TitleIssue } from "components/TitleIssues/types";

const getTitleIssues = (query?: object): (() => Promise<TitleIssue[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${process.env.REACT_APP_HTTP_URL}/api/v1/title_issue/`, {
      headers: {
        authorization: `Bearer ${token}`,
        ...(query && Object.keys(query)?.length ? { query: JSON.stringify(query) } : {})
      }
    });
    return response.data.result.docs;
  };
};
const useGetTitleIssues = (query?: object) => {
  const queryKey = ["title_issues", query];

  const { data, isLoading, isSuccess, error, isFetching, isFetched } = useQuery({
    queryKey: queryKey,
    queryFn: getTitleIssues(query)
  });
  return {
    data,
    isSuccess,
    isLoading,
    isFetching,
    isFetched,
    error
  };
};
export default useGetTitleIssues;
