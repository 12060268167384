import React, { useEffect } from "react";
import { StateAccess, RenderSet } from "utils/models/formGenerator";
import { createOptionsForSelect } from "utils/models/fields";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  getAdditionalRequiredDocumentList,
  removeAdditionalRequiredDocumentList
} from "./listAdditionalRequiredDocumentSlice";
import InputLoader from "components/Loader/InputLoader";

interface Props {
  stateAccess: StateAccess;
  path: string[];
  renderSet: RenderSet;
  name: string;
  label: string;
}

export default function AdditionalRequiredDocumentsPreview({
  stateAccess,
  path,
  renderSet,
  name,
  label
}: Props) {
  const dispatch = useDispatch();
  const listId = `list-autocomplete-${name}-${stateAccess.get(["_id"])}`;
  const documentsList = useSelector(
    (state: RootState) => state.listAdditionalRequiredDocumentSlice[listId]
  );
  useEffect(() => {
    dispatch(
      getAdditionalRequiredDocumentList(listId, {
        options: { pagination: false, sort: { "data.info.name": "asc" } }
      })
    );
    return () => {
      dispatch(removeAdditionalRequiredDocumentList(listId));
      return;
    };
  }, [dispatch, listId]);

  return (
    <>
      {documentsList !== undefined ? (
        renderSet.multiSelectInputRenderer(
          {
            formComponent: "multiselect-field",
            name,
            label,
            path: path as [],
            valueToString: (x) => x?.map((r: any) => r.data.info.name)?.join(", "),
            options: createOptionsForSelect({
              possibleValues: () => documentsList?.entities?.map((el) => el) || [],
              getOptionLabel: (el) => el.data.info.name
            })
          },
          stateAccess,
          [],
          stateAccess,
          renderSet
        )
      ) : (
        <InputLoader label={label} renderSet={renderSet} />
      )}
    </>
  );
}
