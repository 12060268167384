import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { deleteASCInsuranceRequest, deleteASCInsuranceResponse } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  deleteASCInsuranceRequest,
  deleteASCInsuranceResponse
>("DeleteASCInsurance", {
  request: "asc_cancel_transaction"
});

export const deleteASCInsurance = socketAction;
export const deleteASCInsuranceActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
