import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { TitleIssueNote } from "./types";

interface EditTitleIssueNoteResponse {
  message: TitleIssueNote;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  TitleIssueNote,
  EditTitleIssueNoteResponse
>("EditTitleIssueNote", {
  request: "update_title_issue_note"
});

export const editTitleIssueNote = socketAction;
export const editTitleIssueNoteActions = socketMessageSlice.actions;
export const editTitleIssueNoteError = socketMessageSlice.actions.error;

export default socketMessageSlice.reducer;
