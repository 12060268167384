import { Schema } from "ajv";

const data: Schema = {
  type: "object",
  properties: { params: { type: "object", additionalProperties: true } },
  additionalProperties: true,
};

export default {
  express_ex1_provider_list: {
    $id: "expressEx1ProviderList",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
  express_ex1_product_list: {
    $id: "expressEx1ProductList",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
  express_ex1_rate: {
    $id: "expressEx1Rate",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
  express_ex1_pre_rate: {
    $id: "expressEx1PreRate",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
  express_ex1_dealer_product: {
    $id: "expressEx1DealerProduct",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
  express_ex1_contract: {
    $id: "expressEx1Contract",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
  express_ex1_form: {
    $id: "expressEx1Form",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
  express_ex1_confirm_contract: {
    $id: "expressEx1ConfirmContract",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
  express_ex1_contract_void: {
    $id: "expressEx1ContractVoid",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
};
