import { createListSlice } from "../Middlewares/listSliceCreator";
import { FundingDocument } from "./types";

const { ListSlice, getList } = createListSlice<FundingDocument>({
  name: "FundingDocumentList",
  request_topic: "all_funding_documents",
  reducers: {}
});

export default ListSlice.reducer;
export const getFundingDocumentList = getList;
export const listFundingDocumentActions = ListSlice.actions;
