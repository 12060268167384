import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { Sequence } from "./types";

interface SequenseNumberRequest {
  _id: string;
}
interface SequenseNumberResponse {
  message: Sequence;
}
const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  SequenseNumberRequest,
  SequenseNumberResponse
>("UpdateInsuranceSequenceNumber", {
  request: "update_sequence"
});

export const editSequenceNumber = socketAction;

export const editSequenceNumberActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
