import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import Loader from "components/Loader/Loader";
import useGetPersonaVerifications, {
  usePerformPersonaVerifications
} from "hooks/usePersonaVerifications/usePersonaVerifications";
import React from "react";
import { StateAccess } from "utils/models/formGenerator";
import useGetPersonaReports from "hooks/usePersonaReports/usePersonaReports";
import formEditContext from "components/Content/FormEditContext";
import {
  EmailRiskReportPayloadAttributes,
  PhoneRiskReportPayloadAttributes,
  ReportStatus
} from "./types/external/reports/common";
import {
  VerificationCheckName,
  VerificationCheckStatus,
  VerificationStatus,
  VerificationType
} from "./types/external/verifications/common";
import { PersonaReport } from "./types/internal/personaReports";
import { PersonaVerification, Verification } from "./types/internal/personaVerifications";
import { PersonaVerificationResponse } from "./types/external/verifications/http";
import { PersonaReportPayload } from "./types/external/reports/http";
import { capitalize } from "utils/functions";
import { Deal } from "../types";
import { Applicant } from "components/Applicants/types";
import { VerificationContext } from "components/Layout/Main";
import EmailReportsHistoryDialog from "./dialogs/reports/EmailReportsHistoryDialog";
import PhoneReportsHistoryDialog from "./dialogs/reports/PhoneReportsHistoryDialog";
import DatabaseVerificationsHistoryDialog from "./dialogs/verifications/DatabaseVerificationsHistoryDialog";
import AAMVAVerificationsHistoryDialog from "./dialogs/verifications/AAMVAVerificationsHistoryDialog";
import WarningDialog from "./dialogs/WarningDialog";

type Props = {
  stateAccess: StateAccess;
  applicantType: "applicant" | "coApplicant";
};

function isOneMinuteOlder(date: string | undefined) {
  if (!date) return true;
  return Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000 / 60) >= 1;
}

export const isEmailReportValid = (
  report: PersonaReportPayload<EmailRiskReportPayloadAttributes> | undefined
) => {
  const reportAttributes = report?.data?.attributes;
  return (
    reportAttributes?.status === "ready" &&
    !reportAttributes?.email_is_spam &&
    !reportAttributes?.email_is_suspicious &&
    !reportAttributes?.email_malicious_activity_detected &&
    !reportAttributes?.email_is_disposable &&
    !reportAttributes?.email_malicious_activity_detected_recent
  );
};
export const isPhoneReportValid = (
  report: PersonaReportPayload<PhoneRiskReportPayloadAttributes> | undefined
) => {
  const reportAttributes = report?.data?.attributes;
  return (
    reportAttributes?.status === ReportStatus.Ready &&
    reportAttributes?.phone_risk_recommendation === "allow" &&
    !reportAttributes?.phone_risk_level?.includes("high")
  );
};
export const isDatabaseVerificationValid = (
  verification: PersonaVerificationResponse | undefined
) => {
  const verificationChecks = verification?.data?.attributes?.checks ?? [];
  if (Array.isArray(verificationChecks) && verificationChecks?.length) {
    return (
      verificationChecks.find(
        (check) => check?.name === VerificationCheckName.Database_Identity_Comparison
      )?.status === VerificationCheckStatus.Passed
    );
  }
  return false;
};
export const isDatabaseVerificationFailed = (
  verification: PersonaVerificationResponse | undefined
) => {
  const verificationChecks = verification?.data?.attributes?.checks ?? [];
  if (Array.isArray(verificationChecks) && verificationChecks?.length) {
    return (
      verificationChecks.find(
        (check) => check?.name === VerificationCheckName.Database_Identity_Comparison
      )?.status === VerificationCheckStatus.Failed
    );
  }
  return false;
};
export const isAAMVAVerificationValid = (verification: PersonaVerification | undefined) => {
  const verificationChecks =
    verification?.data?.info?.verification?.response?.data?.attributes?.checks ?? [];

  if (Array.isArray(verificationChecks) && verificationChecks.length) {
    const requiredChecks = verificationChecks.filter((check) => check.requirement === "required");

    if (requiredChecks.length === 0) {
      return false;
    }

    return requiredChecks.every(
      (check) =>
        Object.values(VerificationCheckName)
          .filter((x) => x?.startsWith("aamva"))
          .includes(check?.name) && check?.status === VerificationCheckStatus.Passed
    );
  } else if (
    verification?.data?.info?.verification?.minnesotaDMVresponse?.dl_status?.includes("Valid")
  ) {
    return true;
  }

  return false;
};

export const isAAMVAVerificationInvalid = (verification: PersonaVerification | undefined) => {
  const verificationChecks =
    verification?.data?.info?.verification?.response?.data?.attributes?.checks ?? [];
  if (Array.isArray(verificationChecks) && verificationChecks.length) {
    return (
      verificationChecks.filter(
        (check) =>
          Object.values(VerificationCheckName)
            ?.filter((x) => x?.startsWith("aamva"))
            .includes(check?.name) && check?.status === VerificationCheckStatus.Failed
      )?.length > 0
    );
  } else if (verification?.data?.info?.verification?.minnesotaDMVresponse) {
    return !verification?.data?.info?.verification?.minnesotaDMVresponse?.dl_status?.includes(
      "Valid"
    );
  }
  return false;
};
const reportHasPhoneRiskReportPayloadAttributes = (
  report: PersonaReport<PhoneRiskReportPayloadAttributes | EmailRiskReportPayloadAttributes>
): report is PersonaReport<PhoneRiskReportPayloadAttributes> => {
  return report?.data?.info?.report?.type === "phone";
};
export const getLatestReports = (reports: PersonaReport[] | undefined) => {
  if (!reports) return { email: undefined, phone: undefined };
  return reports.reduce(
    (
      acc: {
        email: PersonaReport<EmailRiskReportPayloadAttributes> | undefined;
        phone: PersonaReport<PhoneRiskReportPayloadAttributes> | undefined;
      },
      current
    ) => {
      if (!current.updatedAt) return acc;

      if (current.data.info?.report?.type === "email") {
        if (!acc.email || new Date(current.updatedAt) > new Date(acc.email.updatedAt)) {
          acc.email = current;
        }
      } else if (reportHasPhoneRiskReportPayloadAttributes(current)) {
        if (!acc.phone || new Date(current.updatedAt) > new Date(acc.phone.updatedAt)) {
          acc.phone = current;
        }
      }

      return acc;
    },
    { email: undefined, phone: undefined }
  );
};
export const getLatestVerifications = (verifications: PersonaVerification[] | undefined) => {
  if (!verifications) return { databaseVerification: undefined, aamvaVerification: undefined };
  return verifications.reduce(
    (
      acc: {
        databaseVerification: PersonaVerification | undefined;
        aamvaVerification: PersonaVerification | undefined;
      },
      current
    ) => {
      if (!current.updatedAt) return acc;

      if (
        current.data.info?.verification?.response?.data?.type ===
        VerificationType.Verification_Database
      ) {
        if (
          !acc.databaseVerification ||
          new Date(current.updatedAt) > new Date(acc.databaseVerification.updatedAt)
        ) {
          acc.databaseVerification = current;
        }
      } else if (
        current.data.info?.verification?.response?.data?.type ===
          VerificationType.Verification_AAMVA ||
        current.data.info?.verification?.minnesotaDMVresponse
      ) {
        if (
          !acc.aamvaVerification ||
          new Date(current.updatedAt) > new Date(acc.aamvaVerification.updatedAt)
        ) {
          acc.aamvaVerification = current;
        }
      }

      return acc;
    },
    { databaseVerification: undefined, aamvaVerification: undefined }
  );
};
const getButtonsColors = (
  personaVerificationsData: PersonaVerification[] | undefined,
  personaReportsData: PersonaReport[] | undefined
) => {
  const { email, phone } = getLatestReports(personaReportsData);
  const { databaseVerification, aamvaVerification } = getLatestVerifications(
    personaVerificationsData
  );

  return {
    emailRisk: {
      color: "#000",
      background: email?.data?.info?.report?.response
        ? isEmailReportValid(email?.data?.info?.report?.response)
          ? "rgb(80, 165, 56)"
          : [ReportStatus.Errored, ReportStatus.Ready].includes(
              email?.data?.info?.report?.response?.data?.attributes?.status as ReportStatus
            )
          ? "red"
          : [ReportStatus.Pending].includes(
              email?.data?.info?.report?.response?.data?.attributes?.status as ReportStatus
            ) && isOneMinuteOlder(email?.createdAt)
          ? "red"
          : "rgba(0, 0, 0, 0.12)"
        : "rgba(0, 0, 0, 0.12)"
    },
    phoneRisk: {
      color: "#000",
      background: phone?.data?.info?.report?.response
        ? isPhoneReportValid(phone?.data?.info?.report?.response)
          ? "rgb(80, 165, 56)"
          : [ReportStatus.Errored, ReportStatus.Ready].includes(
              phone?.data?.info?.report?.response?.data?.attributes?.status as ReportStatus
            )
          ? "red"
          : [ReportStatus.Pending].includes(
              phone?.data?.info?.report?.response?.data?.attributes?.status as ReportStatus
            ) && isOneMinuteOlder(phone?.createdAt)
          ? "red"
          : "rgba(0, 0, 0, 0.12)"
        : "rgba(0, 0, 0, 0.12)"
    },
    databaseVerification: {
      color: "#000",
      background: databaseVerification?.data?.info?.verification
        ? isDatabaseVerificationValid(databaseVerification?.data?.info?.verification?.response)
          ? "rgb(80, 165, 56)"
          : isDatabaseVerificationFailed(databaseVerification?.data?.info?.verification?.response)
          ? "red"
          : [VerificationStatus.Submitted, VerificationStatus.Initiated].includes(
              (databaseVerification?.data?.info?.verification?.response?.data?.attributes
                ?.status as unknown) as VerificationStatus
            ) && isOneMinuteOlder(databaseVerification?.createdAt)
          ? "red"
          : "rgba(0, 0, 0, 0.12)"
        : "rgba(0, 0, 0, 0.12)"
    },
    aamvaVerification: {
      color: "#000",
      background:
        aamvaVerification?.data?.info?.verification?.response ||
        aamvaVerification?.data?.info?.verification?.minnesotaDMVresponse
          ? isAAMVAVerificationValid(aamvaVerification)
            ? "rgb(80, 165, 56)"
            : isAAMVAVerificationInvalid(aamvaVerification)
            ? "red"
            : [VerificationStatus.Submitted, VerificationStatus.Initiated].includes(
                (aamvaVerification?.data?.info?.verification?.response?.data?.attributes
                  ?.status as unknown) as VerificationStatus
              ) && isOneMinuteOlder(aamvaVerification?.createdAt)
            ? "red"
            : "rgba(0, 0, 0, 0.12)"
          : "rgba(0, 0, 0, 0.12)"
    }
  };
};
type DialogState = {
  aamva: boolean;
  database: boolean;
  email: boolean;
  phone: boolean;
  warning: boolean;
};
const defaultLoadingState = {
  aamvaVerification: false,
  databaseVerification: false,
  emailRiskReport: false,
  phoneRiskReport: false
};
const FraudValidationPreview = ({ stateAccess, applicantType }: Props) => {
  const { enabled: editMode } = React.useContext(formEditContext);
  const [loadingState, setLoadingState] = React.useState(defaultLoadingState);
  const [dialogState, setDialogState] = React.useState<DialogState>({
    aamva: false,
    database: false,
    email: false,
    phone: false,
    warning: false
  });
  const checkDataValidity = React.useContext(VerificationContext);
  const [selectedVerificationType, setSelectedVerificationType] = React.useState<
    keyof Verification | "all"
  >("all");
  const { performPersonaVerifications, loading } = usePerformPersonaVerifications();
  const defaultVerifications: Verification = {
    databaseVerification: false,
    aamvaVerification: false,
    phoneRiskReport: false,
    emailRiskReport: false
  };
  const deal: Deal = stateAccess.get([]);
  const query = {
    "data.applicantId": deal.data?.[applicantType]?._id as string
  };
  const { data: personaVerificationsData } = useGetPersonaVerifications(
    deal.data?.[applicantType]?._id as string,
    {
      query
    }
  );
  const { data: personaReportsData } = useGetPersonaReports(
    deal.data?.[applicantType]?._id as string,
    {
      query
    }
  );
  const latestReports = getLatestReports(personaReportsData);
  const latestVerifications = getLatestVerifications(personaVerificationsData);

  React.useEffect(() => {
    if (selectedVerificationType !== "all") {
      setLoadingState((prevLoadingState) => ({
        ...prevLoadingState,
        [selectedVerificationType]: loading
      }));
    }
    if (selectedVerificationType === "all") {
      setLoadingState((prevLoadingState) => ({
        aamvaVerification: loading,
        databaseVerification: loading,
        phoneRiskReport: loading,
        emailRiskReport: loading
      }));
    }
  }, [loading]);

  const requiredFieldsAAMVAVerification = [
    {
      name: `${capitalize(applicantType)} driving license number`,
      value: deal?.data?.[applicantType]?.data?.info?.driverLicenseNumber
    },
    {
      name: `${capitalize(applicantType)} driving license issue state`,
      value: deal?.data?.[applicantType]?.data?.info?.drivingLicenseState
    },
    {
      name: `${capitalize(applicantType)} driving license issue date`,
      value: deal?.data?.[applicantType]?.data?.info?.driverLicenseIssued
    },
    {
      name: `${capitalize(applicantType)} driving license expires`,
      value: deal?.data?.[applicantType]?.data?.info?.driverLicenseExpires
    }
  ];
  const requiredFieldsEmailReport = [
    {
      name: `${capitalize(applicantType)} email`,
      value: deal?.data?.[applicantType]?.data?.info?.email
    }
  ];
  const requiredFieldsPhoneReport = [
    {
      name: `${capitalize(applicantType)} phone`,
      value: deal?.data?.[applicantType]?.data?.info?.mobilePhone
    }
  ];
  const buttonsColors = getButtonsColors(personaVerificationsData, personaReportsData);
  const performSingleValidation = (selectedVerificationType: keyof Verification | "all") => {
    performPersonaVerifications({
      deal: { _id: deal._id },
      verifications: {
        ...(applicantType === "applicant"
          ? {
              applicant: {
                ...defaultVerifications,
                [selectedVerificationType]: true
              },
              coApplicant: defaultVerifications
            }
          : {
              coApplicant: {
                ...defaultVerifications,
                [selectedVerificationType]: true
              },
              applicant: defaultVerifications
            })
      }
    });
  };
  const performValidation = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedVerificationType: keyof Verification | "all"
  ) => {
    switch (selectedVerificationType) {
      case "all":
        checkDataValidity(
          [
            ...requiredFieldsAAMVAVerification,
            ...requiredFieldsEmailReport,
            ...requiredFieldsPhoneReport
          ],
          () => {
            performPersonaVerifications({
              deal: { _id: deal._id },
              verifications: {
                ...(applicantType === "applicant"
                  ? {
                      applicant: {
                        databaseVerification: true,
                        aamvaVerification: true,
                        phoneRiskReport: true,
                        emailRiskReport: true
                      },
                      coApplicant: defaultVerifications
                    }
                  : {
                      coApplicant: {
                        databaseVerification: true,
                        aamvaVerification: true,
                        phoneRiskReport: true,
                        emailRiskReport: true
                      },
                      applicant: defaultVerifications
                    })
              }
            });
          }
        );

        break;
      case "aamvaVerification":
        checkDataValidity(requiredFieldsAAMVAVerification, () => {
          performSingleValidation(selectedVerificationType);
        });
        break;
      case "databaseVerification":
        performSingleValidation(selectedVerificationType);
        break;
      case "emailRiskReport":
        checkDataValidity(requiredFieldsEmailReport, () => {
          performSingleValidation(selectedVerificationType);
        });
        break;
      case "phoneRiskReport":
        checkDataValidity(requiredFieldsPhoneReport, () => {
          performSingleValidation(selectedVerificationType);
        });
        break;
    }
  };
  return (
    <React.Fragment key={applicantType}>
      {dialogState?.warning ? (
        <WarningDialog
          selectedVerificationType={selectedVerificationType}
          retryFunction={performValidation}
          closeFunction={() =>
            setDialogState((prevDialogState) => ({ ...prevDialogState, warning: false }))
          }
        />
      ) : (
        <></>
      )}
      {dialogState?.email ? (
        <EmailReportsHistoryDialog
          isLoading={loading}
          reports={
            personaReportsData?.filter((report) => report.data.info?.report?.type === "email") ?? []
          }
          applicant={deal.data[applicantType] as Applicant}
          retryFunction={performValidation}
          closeFunction={() =>
            setDialogState((prevDialogState) => ({ ...prevDialogState, email: false }))
          }
        />
      ) : (
        <></>
      )}
      {dialogState?.phone ? (
        <PhoneReportsHistoryDialog
          isLoading={loading}
          reports={
            ((personaReportsData?.filter((report) => report.data.info?.report?.type === "phone") ??
              []) as unknown) as PersonaReport<PhoneRiskReportPayloadAttributes>[]
          }
          applicant={deal.data[applicantType] as Applicant}
          retryFunction={performValidation}
          closeFunction={() =>
            setDialogState((prevDialogState) => ({ ...prevDialogState, phone: false }))
          }
        />
      ) : (
        <></>
      )}
      {dialogState?.database ? (
        <DatabaseVerificationsHistoryDialog
          isLoading={loading}
          verifications={
            personaVerificationsData?.filter(
              (verification) =>
                verification.data.info?.verification?.response?.data?.type ===
                VerificationType.Verification_Database
            ) ?? []
          }
          applicant={deal.data[applicantType] as Applicant}
          retryFunction={performValidation}
          closeFunction={() =>
            setDialogState((prevDialogState) => ({ ...prevDialogState, database: false }))
          }
        />
      ) : (
        <></>
      )}
      {dialogState?.aamva ? (
        <AAMVAVerificationsHistoryDialog
          isLoading={loading}
          verifications={
            personaVerificationsData?.filter(
              (verification) => verification?.data?.info?.type === "aamva"
            ) ?? []
          }
          applicant={deal.data[applicantType] as Applicant}
          retryFunction={performValidation}
          closeFunction={() =>
            setDialogState((prevDialogState) => ({ ...prevDialogState, aamva: false }))
          }
        />
      ) : (
        <></>
      )}
      <Grid container xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography
            style={{
              color: "#254e6e",
              fontSize: "19px",
              margin: "5px 0px 10px 0px",
              fontWeight: "bold"
            }}
          >
            {capitalize(applicantType)} Fraud Validations
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            id={`databaseVerificationButton_${applicantType}_${deal.data?.[applicantType]?._id}`}
            style={{
              width: "100%",
              height: "100%",
              ...buttonsColors.databaseVerification
            }}
            endIcon={
              loadingState?.databaseVerification ||
              (![VerificationStatus.Failed, VerificationStatus.Passed].includes(
                latestVerifications.databaseVerification?.data?.info?.verification?.response?.data
                  ?.attributes?.status as VerificationStatus
              ) &&
                !isOneMinuteOlder(latestVerifications?.databaseVerification?.createdAt)) ? (
                <CircularProgress style={{ color: "#000" }} size={20} />
              ) : null
            }
            disabled={loadingState.databaseVerification}
            variant="contained"
            onClick={(e) => {
              setSelectedVerificationType("databaseVerification");
              if (
                personaVerificationsData?.some(
                  (verification) =>
                    verification?.data?.info?.verification?.response?.data?.type ===
                    VerificationType.Verification_Database
                )
              ) {
                setDialogState((prevDialogState) => ({ ...prevDialogState, database: true }));
              } else {
                performValidation(e, "databaseVerification");
              }
            }}
            color="primary"
          >
            Database
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            id={`aamvaVerificationButton_${applicantType}_${deal.data?.[applicantType]?._id}`}
            style={{
              width: "100%",
              height: "100%",
              ...buttonsColors.aamvaVerification
            }}
            endIcon={
              loadingState?.aamvaVerification ||
              (![VerificationStatus.Failed, VerificationStatus.Passed].includes(
                latestVerifications.aamvaVerification?.data?.info?.verification?.response?.data
                  ?.attributes?.status as VerificationStatus
              ) &&
                !isOneMinuteOlder(latestVerifications?.aamvaVerification?.createdAt)) ? (
                <CircularProgress style={{ color: "#000" }} size={20} />
              ) : null
            }
            disabled={loadingState.aamvaVerification}
            variant="contained"
            onClick={(e) => {
              setSelectedVerificationType("aamvaVerification");
              if (
                personaVerificationsData?.some(
                  (verification) =>
                    verification?.data?.info?.verification?.response?.data?.type ===
                      VerificationType.Verification_AAMVA ||
                    verification?.data?.info?.verification?.minnesotaDMVresponse
                )
              ) {
                setDialogState((prevDialogState) => ({ ...prevDialogState, aamva: true }));
              } else {
                performValidation(e, "aamvaVerification");
              }
            }}
            color="primary"
          >
            AAMVA
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            id={`emailRiskReportButton_${applicantType}_${deal.data?.[applicantType]?._id}`}
            style={{
              width: "100%",
              height: "100%",
              ...buttonsColors.emailRisk
            }}
            endIcon={
              loadingState?.emailRiskReport ||
              (![ReportStatus.Errored, VerificationStatus.Passed].includes(
                latestReports?.email?.data?.info?.report?.response?.data?.attributes
                  ?.status as ReportStatus
              ) &&
                !isOneMinuteOlder(latestReports?.email?.createdAt)) ? (
                <CircularProgress style={{ color: "#000" }} size={20} />
              ) : null
            }
            disabled={loadingState?.emailRiskReport}
            variant="contained"
            color="primary"
            onClick={(e) => {
              setSelectedVerificationType("emailRiskReport");
              if (
                personaReportsData?.some(
                  (report) =>
                    report.data.info.report?.type === "email" && report.data.info?.report?.response
                )
              ) {
                setDialogState((prevDialogState) => ({ ...prevDialogState, email: true }));
              } else {
                performValidation(e, "emailRiskReport");
              }
            }}
          >
            Email Risk
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            id={`phoneRiskReportButton_${applicantType}_${deal.data?.[applicantType]?._id}`}
            style={{
              width: "100%",
              height: "100%",
              ...buttonsColors.phoneRisk
            }}
            endIcon={
              loadingState?.phoneRiskReport ||
              (![ReportStatus.Errored, VerificationStatus.Passed].includes(
                latestReports?.phone?.data?.info?.report?.response?.data?.attributes
                  ?.status as ReportStatus
              ) &&
                !isOneMinuteOlder(latestReports?.phone?.createdAt)) ? (
                <CircularProgress style={{ color: "#000" }} size={20} />
              ) : null
            }
            disabled={loadingState?.phoneRiskReport}
            variant="contained"
            color="primary"
            onClick={(e) => {
              setSelectedVerificationType("phoneRiskReport");
              if (
                personaReportsData?.some(
                  (report) =>
                    report.data.info.report?.type === "phone" && report.data.info?.report?.response
                )
              ) {
                setDialogState((prevDialogState) => ({ ...prevDialogState, phone: true }));
              } else {
                performValidation(e, "phoneRiskReport");
              }
            }}
          >
            Phone Risk
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button
            id={`allFraudValidationsButton_${applicantType}_${deal.data?.[applicantType]?._id}`}
            style={{ width: "100%" }}
            variant="contained"
            color="primary"
            onClick={(e) => {
              setSelectedVerificationType("all");
              if (
                (personaReportsData ?? [])?.filter(
                  (report) => report?.data?.info?.report?.type === "email"
                )?.length >= 3 &&
                (personaReportsData ?? [])?.filter(
                  (report) => report?.data?.info?.report?.type === "phone"
                )?.length >= 3 &&
                (personaVerificationsData ?? [])?.filter(
                  (verification) => verification?.data?.info?.type === "aamva"
                )?.length >= 3 &&
                (personaVerificationsData ?? [])?.filter(
                  (verification) => verification?.data?.info?.type === "database"
                )?.length >= 3
              ) {
                setDialogState((prevDialogState) => ({ ...prevDialogState, warning: true }));
              } else {
                performValidation(e, "all");
              }
            }}
            disabled={(editMode !== undefined && !editMode) || loading}
            endIcon={
              loading && selectedVerificationType === "all" ? (
                <CircularProgress style={{ color: "#fff" }} size={20} />
              ) : null
            }
          >
            Perform Fraud Validations
          </Button>
          <Loader />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default FraudValidationPreview;
