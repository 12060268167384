import { createListSlice } from "components/Middlewares/listSliceCreator";
import { Lock } from "./types";

const { ListSlice, getList } = createListSlice<Lock>({
  name: "LockList",
  request_topic: "all_locks",
  reducers: {}
});

export default ListSlice.reducer;
export const getLockList = (requestId: string) => {
  const date = new Date();
  // add a day
  date.setDate(date.getDate() - 5);
  date.setMilliseconds(0);
  date.setSeconds(0);
  date.setMinutes(0);
  return getList(requestId, {
    query: {
      updatedAt: { $gte: date.toISOString() },
      "data.info.userEmail": { $ne: null }
    },
    options: {
      limit: 10000
    }
  });
};
export const listLockActions = ListSlice.actions;
