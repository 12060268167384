import { permissionNode, relationPermissionNode, PermissionStruct } from "components/Roles/types";
import { Netsuite } from "utils/entitySlice";

export type NesuitePermissionStruct = PermissionStruct<Netsuite>;

export const netsuitePermissionsStruct: NesuitePermissionStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: { dealId: permissionNode("Data") }
};
