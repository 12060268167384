import info_properties from "./info_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

const data = {
  type: "object",
  required: ["info", "rawData"],
  properties: {
    dealershipId: { type: "string", nullable: true },
    applicantId: { type: "string", nullable: true },
    coApplicantId: { type: "string", nullable: true },
    info: {
      type: "object",
      required: ["dealer", "purchaseVehicle", "applicant"],
      properties: info_properties,
    },
    rawData: { type: "string" },
  },
};

export default {
  new_credit_smarts_application: {
    $id: "newCreditSmarts",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_credit_smarts_application: {
    $id: "updateCreditSmarts",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      approved: {
        type: "boolean",
        nullable: true,
      },
      approvedAt: {
        type: "string",
        nullable: true,
      },
      approvedByUserId: {
        type: "string",
        nullable: true,
      },
    },
  },
  delete_credit_smarts_application: {
    $id: "deleteCreditSmarts",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  recover_credit_smarts_application: {
    $id: "recoverCreditSmart",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_credit_smarts_application: {
    $id: "getCreditSmarts",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_credit_smarts_application: {
    $id: "allCreditSmarts",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
