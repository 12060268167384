import Grid from "@material-ui/core/Grid";
import { RootState } from "app/rootReducer";
import { Deal } from "components/Deals/types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOptionsForSelect, Path } from "utils/models/fields";
import { getByPath, RenderSet, StateAccess } from "utils/models/formGenerator";
import {
  getDealershipProgramList,
  removeDealershipProgramList
} from "./listDealershipProgramSlice";
import { DealershipProgram } from "./types";

interface Props {
  stateAccess: StateAccess;
  path: Path<Deal>;
  renderSet: RenderSet;
  name: string;
  label: string;
  required: boolean;
  requiredFunction?: (stateAccess: StateAccess) => any;
}

export default function DealershipProgramPreview({
  stateAccess,
  path,
  renderSet,
  name,
  label,
  required,
  requiredFunction
}: Props) {
  const dispatch = useDispatch();
  const listId = `list-autocomplete-dealership-program-${stateAccess.get(["_id"])}`;
  const dealershipList = useSelector(
    (state: RootState) => state.listDealershipProgramSlice[listId]
  );

  const dealershipProgram = stateAccess.get(path);

  const dealershipProgramStateAccess: StateAccess = {
    get: (path) =>
      dealershipProgram
        ? getByPath<DealershipProgram>(dealershipProgram, path as Path<DealershipProgram>)
        : undefined,
    set: (_, value): any => {
      stateAccess.set<Deal>(path, value);
    }
  };

  const handleOpen = () => {
    if (!dealershipList)
      dispatch(
        getDealershipProgramList(listId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } }
        })
      );
    return () => {
      dispatch(removeDealershipProgramList(listId));
      return;
    };
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {renderSet.selectInputRenderer(
            {
              formComponent: "select-field",
              name,
              label,
              required: requiredFunction ? requiredFunction(stateAccess) : required,
              onOpen: handleOpen,
              path: [] as [],
              options: createOptionsForSelect({
                possibleValues: () => dealershipList?.entities ?? [],
                getOptionLabel: (dealershipProgram) => dealershipProgram?.data?.info?.name,
                getSelectedOption: (x, y) => x?._id === y?._id
              }),
              valueToString: (dealershipProgram) => dealershipProgram?.data?.info?.name
            },
            dealershipProgramStateAccess,
            [],
            dealershipProgramStateAccess,
            renderSet
          )}
        </Grid>
      </Grid>
    </>
  );
}
