import { Permissions } from "components/Roles/types";
import { User } from "components/Users/types";
export const topics = [
  "contract",
  "contract_type",
  "deal",
  "dealership",
  "lender",
  "wfd_user",
  "applicant",
  "role",
  "dmv",
  "custom_report",
  "title_issue",
  "document_template",
  "gap_company",
  "insurance_company",
  "vehicle_insurance_company",
  "file",
  "funding_document",
  "payoff_bank",
  "other_vendor",
  "unapproved_user",
  "appone_import_application",
  "state",
  "credit_application",
  "express_api",
  "dealertrack_deal",
  "dealertrack_api",
  "external_credit_application",
  "history",
  "email_send",
  "protective_services",
  "credit_score",
  "vin_check",
  "appone_api",
  "document_validation",
  "floorplanxpress",
  "lock",
  "sequence",
  "asc_api",
  "credit_smarts_application",
  "notification",
  "lender_decision",
  "api_status",
  "settings",
  "netsuite"
] as const;

export type authenticationCodeErrors =
  | "auth/multi-factor-auth-required"
  | "auth/invalid-verification-code";

export const checkIfAuthenticationCodeError = (code?: string): code is authenticationCodeErrors =>
  code !== undefined &&
  ["auth/invalid-verification-code", "auth/multi-factor-auth-required"].includes(code);

export interface Authentication {
  id: string;
  data?: any;
  isSigningIn: boolean;
  isSigningOut: boolean;
  isVerifying: boolean;
  signInError: boolean;
  signInErrorCode?: string;
  signOutError: boolean;
  isAuthenticated: boolean;
  errorMessage: string | undefined;
  user:
    | { token: string; email: string; permissions?: Permissions; databaseData?: User }
    | undefined;
}
