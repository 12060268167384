import React from "react";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";

import { Deal, Info } from "./types";
import { Collateral } from "types/collateral";
import { StateAccess, RenderSet } from "utils/models/formGenerator";

interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  collateral: Collateral;
}

export default function SelectCollateralButton({ renderSet, stateAccess, collateral }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const deal: Info = stateAccess.get(["data", "info"]);
  const financialInfo = collateral?.data?.financialInfo;
  const vehicle = collateral?.data?.collateral;
  const newDeal: Info = {
    ...deal,
    vehicle: {
      ...deal?.vehicle,
      make: vehicle?.make,
      model: vehicle?.model,
      odometer: vehicle?.odometer,
      year: vehicle?.year,
      MSRP: vehicle?.MSRP,
      VIN: vehicle?.VIN,
      unitStatus: vehicle?.unitStatus,
      unitType: vehicle?.unitType
    },
    price: {
      ...deal?.price,
      price: financialInfo?.price?.price,
      totalCash: financialInfo?.price?.totalCash,
      payoff: financialInfo?.price?.payoff,
      totalTrade: financialInfo?.price?.totalTrade
    },
    taxesAndFees: {
      ...deal?.taxesAndFees,
      documentRegistrationFees: financialInfo?.taxesAndFees?.documentRegistrationFees,
      titleTransfer: financialInfo?.taxesAndFees?.titleTransfer
    },
    payment: {
      ...deal?.payment,
      totalTaxes: financialInfo?.payment?.totalTaxes
    }
  };
  const handleSelect = () => {
    stateAccess.set<Deal>(["data", "info"], newDeal);
    enqueueSnackbar("Successfully changed collateral information.", { variant: "success" });
  };
  if (renderSet.type === "show") return null;
  return (
    <Button variant="contained" color="primary" onClick={handleSelect}>
      Select Collateral
    </Button>
  );
}
