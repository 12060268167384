import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { auth } from "../../firebase/firebase";
import { MutationError } from "Chat/types";
import { DealershipTicket } from "components/DealershipTickets/types";

export const updateDealershipTicketNotes = (): ((
  request: DealershipTicket
) => Promise<DealershipTicket>) => {
  return async (request) => {
    try {
      const token = await auth.currentUser?.getIdToken();

      const response = await axios.put(
        `${process.env.REACT_APP_HTTP_URL}/api/v1/update_dealership_ticket_notes/${request._id}`,
        request,
        {
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      );
      return response.data.result;
    } catch (err: any) {
      throw new Error(
        err?.response?.data?.message ??
          err?.response?.data?.result ??
          err?.message ??
          "Unknown error!"
      );
    }
  };
};

const useUpdateDealershipTicketNotes = () => {
  const mutation = useMutation<DealershipTicket, MutationError, DealershipTicket>(
    updateDealershipTicketNotes(),
    {
      onSuccess: () => {
        return;
      }
    }
  );
  return {
    updateDealershipTicketNotes: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};
export default useUpdateDealershipTicketNotes;
