import { createListSlice } from "../Middlewares/listSliceCreator";
import { TitleIssueNote } from "./types";

const { ListSlice, getList } = createListSlice<TitleIssueNote>({
  name: "TitleIssueNoteList",
  request_topic: "all_title_issue_notes",
  reducers: {}
});
export default ListSlice.reducer;
export const getTitleIssueNotesList = getList;
export const listTitleIssueNotesActions = ListSlice.actions;
export const removeTitleIssueNotesList = ListSlice.actions.removeList;
