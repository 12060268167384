import { Action, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { createMySocketMiddleware } from "../components/Middlewares/SocketMiddleware";
import { verifyAuth } from "../components/SignIn/authSlice";
import rootReducer, { RootState } from "./rootReducer";

// dev: 35.239.108.178:8998
// stable: 34.70.57.152:8998
// demo_ip: 35.222.55.194
// https://wfd-api.com/ (.152 стейбъл сървъра)
// https://dev.wfd-api.com/ (.178 дев сървъра)
// https://demo.wfd-api.com/ (.194 демо сървъра)

const socketUrl = process.env.REACT_APP_SOCKET_URL as string;
// const socketUrl = "http://localhost:8998";

const store = configureStore({
  reducer: rootReducer,
  middleware: [
    // @ts-ignore
    ...getDefaultMiddleware<RootState>({ immutableCheck: false, serializableCheck: false }),
    createMySocketMiddleware(socketUrl)
  ]
});
// @ts-ignore
window.redux_store = store;
export const verifyAuthOnLoad = () => {
  // TODO: This any should be changed to whatever is adequate
  store.dispatch<any>(verifyAuth());
};

verifyAuthOnLoad();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
