import { IconButton, CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { StateAccess } from "utils/models/formGenerator";
import { Deal } from "components/Deals/types";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { checkContract } from "./SignInsuranceContract";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { contractVoid } from "./contractVoidSlice";
import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";
import { useSnackbar } from "notistack";
interface Props {
  index: number;
  stateAccess: StateAccess;
}

const handleDelete = (index: number, stateAccess: StateAccess) => {
  const insurances = stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "insurances"]);
  stateAccess.set<Deal>(
    ["data", "info", "aftermarketOptions", "insurances"],
    insurances.filter((el: any, insuranceIndex: number) => index !== insuranceIndex)
  );
};

const handleVoidDelete = (
  requestId: string,
  index: number,
  stateAccess: StateAccess,
  dispatch: Dispatch<any>
) => {
  const insurance = stateAccess.get<Deal>([
    "data",
    "info",
    "aftermarketOptions",
    "insurances",
    index
  ]);

  dispatch(
    contractVoid({
      requestId,
      EX1DealerID: process.env.REACT_APP_EX1DealerID as string,
      EX1ProviderID: insurance.chosenRate.EX1ProviderID,
      ContractNumber: insurance.chosenRate.ContractNumber
    })
  );
};

export const DeleteInsuranceContract = ({ stateAccess, index }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const contractVoid = useSelector((state: RootState) => state.contractVoidSlice[requestId]);
  const isSigned = checkContract(stateAccess, index);
  const [isWaiting, setIsWaiting] = useState(false);
  const [canBeForceDeleted, setCanBeForceDeleted] = useState(false);

  useEffect(() => {
    const insurances = stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "insurances"]);
    switch (contractVoid?.status) {
      case "success":
        if (
          contractVoid.data.message.data.EX1ContractVoidResponse.MsgHeader.Message[0].StatusCode ===
          "0000" // success status code
        ) {
          stateAccess.set<Deal>(
            ["data", "info", "aftermarketOptions", "insurances"],
            insurances.filter((el: any, insuranceIndex: number) => index !== insuranceIndex)
          );
        } else {
          const message =
            contractVoid.data.message.data.EX1ContractVoidResponse.MsgHeader.Message[0].Description;
          enqueueSnackbar(
            `Unable to delete the contract for this provider please go to the provider dashboard and manualy delete it. API response: ${message}`,
            {
              variant: "error",
              persist: true
            }
          );
          setCanBeForceDeleted(true);
        }
        setIsWaiting(false);
        break;
      case "waiting":
        setIsWaiting(true);
        break;
      case "error":
        setIsWaiting(false);
        break;
    }
  }, [contractVoid, stateAccess, index, enqueueSnackbar]);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <IconButton
        style={{
          color: isSigned && !canBeForceDeleted ? "#254e70" : "red"
        }}
        onClick={() =>
          isSigned && !canBeForceDeleted
            ? handleVoidDelete(requestId, index, stateAccess, dispatch)
            : handleDelete(index, stateAccess)
        }
      >
        <RemoveCircleIcon />
        {isWaiting && <CircularProgress style={{ position: "absolute", display: "block" }} />}
      </IconButton>
    </div>
  );
};
