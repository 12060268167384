import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/performance";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};
export const app = firebase.initializeApp(firebaseConfig);

export const performance = firebase.performance();
export const storage = firebase.storage();
export const auth = firebase.auth();
