import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import info_properties from "./info_properties";

const data = {
  type: "object",
  required: ["info"],
  properties: {
    info: { type: "object", properties: info_properties },
    userId: { type: "string" },
    additionalProperties: true
  }
};
export default {
  export_column_group: {
    $id: "exportColumnGroup",
    type: "object",
    required: ["usersToNotify", "sendEmail", "generatedLink"],
    properties: {
      additionalProperties: true,
      usersToNotify: {
        type: "array",
        nullable: true,
        items: {
          type: "object",
          properties: {
            userId: {
              type: "string",
              nullable: true
            },
            userEmail: {
              type: "string",
              nullable: true
            },
            nullable: true,
            additionalProperties: true
          }
        }
      },
      sendEmail: {
        type: "boolean",
        nullable: true
      },
      generatedLink: {
        type: "string",
        nullable: true
      },
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      }
    }
  },
  new_column_group: {
    $id: "newColumnGroup",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      }
    }
  },
  update_column_group: {
    $id: "updateColumnGroup",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      }
    }
  },
  delete_column_group: {
    $id: "deleteColumnGroup",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" }
    }
  },
  all_column_groups: {
    $id: "allColumnGroups",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties }
    ]
  }
};
