import { capitalize, withStyles, Theme, Tooltip, Chip } from "@material-ui/core";
import React from "react";
import { DealStatus } from "./types";

const ChipTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    maxHeight: "300px",
    overflow: "auto"
  }
}))(Tooltip);

export const dealStatusChipTooltip = (
  status: DealStatus,
  message: string,
  chipStyle?: React.CSSProperties
): JSX.Element => {
  return (
    <ChipTooltip interactive open={message ? undefined : false} title={message}>
      <Chip size="small" label={capitalize(status)} style={chipStyle} />
    </ChipTooltip>
  );
};
