import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { Button, Grid, Typography } from "@material-ui/core/";
import { enrollUserMFA, verficicateMFACode } from "components/SignIn/authSlice";
import TextField from "@material-ui/core/TextField";
import { StateAccess } from "utils/models/formGenerator";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { auth } from "../../firebase/firebase";

interface Props {
  stateAccess: StateAccess;
}

export default function ResetPassButton({ stateAccess }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const state = stateAccess.get([]);
  const phoneNumber = state?.data?.info?.phoneNumber;
  const userId = state?._id;
  const user = useSelector((state: RootState) => state.authSlice);

  const [emailVerified, setEmailVerified] = useState<boolean | undefined>(
    auth.currentUser?.emailVerified
  );
  const [enrolledFactors, setEnrolledFactors] = useState<any[] | undefined>(
    auth.currentUser?.multiFactor.enrolledFactors
  );
  const [verificationId, setVerificationID] = useState<null | string>(null);
  const [verificationCode, setVerificationCode] = useState<null | string>(null);

  const reloadUser = async () => {
    await auth.currentUser?.reload();
    setEmailVerified(auth.currentUser?.emailVerified);
    setEnrolledFactors(auth.currentUser?.multiFactor.enrolledFactors);
  };

  const sendVerificationEmail = async () => {
    await auth.currentUser?.sendEmailVerification();
    enqueueSnackbar("Verification email sent!", {
      variant: "success"
    });
  };
  const sendVerificationCode = async () => {
    if (phoneNumber) {
      const { verificationId, error } = await enrollUserMFA("+1" + phoneNumber);
      if (verificationId) setVerificationID(verificationId);
      else if (error) enqueueSnackbar(error, { variant: "error" });
    } else
      enqueueSnackbar("Enter a phone number to add multi factor authentication.", {
        variant: "error"
      });
  };

  const enalbleMFA = async () => {
    if (verificationId && verificationCode) {
      const { status, message } = await verficicateMFACode(verificationId, verificationCode);
      enqueueSnackbar(message, { variant: status });
      reloadUser();
    }
  };

  const disableMFA = async () => {
    const factor = auth.currentUser?.multiFactor.enrolledFactors?.[0];
    if (factor && auth.currentUser) {
      return auth.currentUser.multiFactor
        .unenroll(factor)
        .then(function () {
          enqueueSnackbar("Multi factor authentication has been disabled for this account.", {
            variant: "success"
          });
          reloadUser();
        })
        .catch(function (error) {
          enqueueSnackbar(error.message, { variant: "error" });
        });
    }
  };

  return (
    <>
      {userId === user?.user?.databaseData?._id ? (
        <Grid container spacing={1}>
          {enrolledFactors && enrolledFactors.length > 0 ? (
            <>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="caption">
                  Multi factor authentication has been enabled for this account.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button variant="contained" color="secondary" onClick={disableMFA}>
                  Disable MFA
                </Button>
              </Grid>
            </>
          ) : (
            <>
              {emailVerified && verificationId ? (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      Enter the authentication code to turn on MFA.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      style={{ marginTop: "0px" }}
                      variant="filled"
                      margin="normal"
                      required
                      fullWidth
                      name="code"
                      label="Authentication code"
                      type="text"
                      size="small"
                      id="code"
                      autoComplete="mfacode"
                      value={verificationCode}
                      onChange={(e): void => setVerificationCode(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="contained" color="primary" onClick={enalbleMFA}>
                      Enable TFA
                    </Button>
                  </Grid>
                </Grid>
              ) : emailVerified ? (
                <Grid container item xs={12} spacing={1} style={{ textAlign: "center" }}>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      In order to activate multi-factor authentication please enter the phone number
                      in the User info section and click on the &quot;SEND CODE&quot; button.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={sendVerificationCode}>
                      Send code
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    This site is protected by reCAPTCHA and the Google <br />
                    <a href="https://policies.google.com/privacy">Privacy Policy</a> and&nbsp;
                    <a href="https://policies.google.com/terms">Terms of Service</a>&nbsp; apply.
                  </Grid>
                </Grid>
              ) : (
                <Grid container item xs={12} spacing={1} style={{ textAlign: "center" }}>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      Verify your email address, in order to use multi-factor authentication.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      Already verified? Click
                      <a style={{ cursor: "pointer" }} onClick={reloadUser}>
                        <b> here </b>
                      </a>
                      to check.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={sendVerificationEmail}>
                      Send link
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="caption">
              Log in with <b>{state?.data?.info?.email}</b> to enable MFA!
            </Typography>
          </Grid>
        </>
      )}
    </>
  );
}
