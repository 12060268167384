import {
  createSocketMessageSlice,
  Identifiable
} from "../../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditTableSettingsData {
  _id: string;
  updatedAt: string;
  data: FormState;
}
interface EditTableSettingsRequest {
  _id: string;
  data: FormState;
}

interface EditTableSettingsResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditTableSettingsRequest,
  EditTableSettingsResponse
>("EditTableSettings", {
  request: "update_table_settings"
});

export const editTableSettingsActions = socketMessageSlice.actions;
export const editTableSettings = (request: EditTableSettingsData & Identifiable) => {
  const { updatedAt, ...rest } = request;
  return socketAction({
    ...rest,
    data: {
      ...request.data
    }
  });
};
export default socketMessageSlice.reducer;
