import { IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { RootState } from "app/rootReducer";
import { appOnePhoneNumberFormat } from "components/AppOne/Deal/addDealSlice";
import { Info as ApplicantInfo } from "components/Applicants/types";
import formEditContext from "components/Content/FormEditContext";
import { editDeal } from "components/Deals/editDealSlice";
import {
  Deal,
  DealDates,
  ExpressInsurance,
  Payment,
  Vehicle as VehicleType
} from "components/Deals/types";
import { VerificationContext } from "components/Layout/Main";
import { Info as LenderInfo } from "components/Lenders/types";
import AlertDialog from "components/common/AlertDialog";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import { States } from "../../../us-states";
import { signContract, signContractAction } from "./signContractSlice";

interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  index: number;
}

export const checkContract = (stateAccess: StateAccess, index: number) => {
  const insurance = stateAccess.get<Deal>([
    "data",
    "info",
    "aftermarketOptions",
    "insurances",
    index
  ]);
  return insurance.chosenRate?.ContractForm !== undefined;
};

export const checkInsurance = (stateAccess: StateAccess, index: number) => {
  const insurance = stateAccess.get<Deal>([
    "data",
    "info",
    "aftermarketOptions",
    "insurances",
    index
  ]);
  return insurance.originalRate === undefined;
};

export default function SignInsuranceContract({ stateAccess, renderSet, index }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<{
    isLoading: boolean;
    error: boolean;
    errorMessage: string | undefined;
    updateDeal: boolean;
  }>({
    isLoading: false,
    error: false,
    errorMessage: undefined,
    updateDeal: false
  });

  const dispatch = useDispatch();
  const { edited: unsavedDeal } = useContext(formEditContext);
  const [alertState, setAlertState] = useState(false);

  const [requestId] = useState(uuidv4());
  const checkDataValidity = useContext(VerificationContext);
  const actionStateSign = useSelector((state: RootState) => state.signContractSlice[requestId]);

  const isAlreadySigned = checkContract(stateAccess, index);
  const isCustomInsurance = checkInsurance(stateAccess, index);

  const handleSignContract = (index: number) => {
    const vehicle: VehicleType = stateAccess.get<Deal>(["data", "info", "vehicle"]);
    const lender: LenderInfo = stateAccess.get<Deal>(["data", "lender", "data", "info"]);
    const dealInfo: Payment = stateAccess.get<Deal>(["data", "info", "payment"]);
    const insurance: ExpressInsurance = stateAccess.get<Deal>([
      "data",
      "info",
      "aftermarketOptions",
      "insurances",
      index
    ]);
    const surcharges = insurance?.chosenRate?.dynamicSurcharges;
    const dealDates: DealDates = stateAccess.get<Deal>(["data", "info", "dealDates"]);
    const buyer: ApplicantInfo = stateAccess.get<Deal>(["data", "applicant", "data", "info"]);
    const coBuyer: ApplicantInfo = stateAccess.get<Deal>(["data", "coApplicant", "data", "info"]);
    const price = stateAccess.get<Deal>(["data", "info", "price", "price"]) ?? 0;

    const gapAttributes = {
      Coverage: insurance.chosenRate?.Coverage,
      Months: insurance.chosenRate.months,
      Term: stateAccess.get<Deal>(["data", "info", "payment", "numberOfPayments"]),
      Deductible: "",
      ServiceInterval: "",
      TireRotations: 0
    };

    const vscAttributes = {
      Coverage: insurance.chosenRate?.Coverage,
      Months: insurance.chosenRate.months,
      Term: stateAccess.get<Deal>(["data", "info", "payment", "numberOfPayments"]),
      Miles: insurance.originalRate.miles,
      Deductible: insurance.originalRate?.deductible?.Value,
      ServiceInterval: "",
      TireRotations: 0
    };

    const surchargeAttributes = surcharges?.reduce(
      (acc: { SurchargeCode: string; Value: string }[], surcharge) =>
        surcharge.Value === "true"
          ? [
              ...acc,
              {
                SurchargeCode: surcharge.SurchargeCode,
                Value: surcharge.Value
              }
            ]
          : acc,
      []
    );
    const SaleDate =
      dealDates.contractDate !== null &&
      dealDates.contractDate !== undefined &&
      !isNaN(Date.parse(dealDates.contractDate as any))
        ? ((typeof dealDates.contractDate === "string"
            ? new Date(dealDates.contractDate)
            : dealDates.contractDate) as Date)
            .toISOString()
            .slice(0, 10)
        : undefined;

    const Vehicle = {
      VIN: vehicle.VIN,
      InServiceDate: SaleDate,
      Odometer: vehicle?.odometer?.toString(),
      CarStatus:
        (vehicle?.unitStatus?.charAt(0)?.toUpperCase() ?? "") + vehicle?.unitStatus?.slice(1) ?? "",
      Make: vehicle?.make,
      Model: vehicle?.model,
      Year: vehicle?.year?.toString()
    };

    const DealType = "Loan";

    const MSRP = vehicle?.MSRP;

    const VehicleSalePrice = price;

    const FinanceTerms = {
      Lienholder: {
        Name: lender?.name, //TO-DO: ASK ALEX FOR THIS FIELD
        Contact: {
          AddressLine1: lender?.address,
          AddressLine2: "",
          City: lender?.city,
          State: States[lender?.state]?.prefix,
          PostalCode: lender?.zipCode,
          Country: "USA",
          Phone: appOnePhoneNumberFormat(lender?.phone),
          TimeZone: "",
          Facsimile: "",
          eMail: lender?.email
        }
      },
      FinanceAmount: dealInfo?.dealTotal,
      FinanceRate: dealInfo?.interestRate,
      MonthlyPayment: dealInfo?.monthlyPayment,
      FinanceTerm: dealInfo?.numberOfPayments,
      // ??
      ResidualAmount: "0",
      // ??
      AmortTerm: "0"
    };
    const Products = {
      Product: {
        EX1RateResponseID: insurance.chosenRate.EX1RateResponseID,
        RetailPrice: insurance.chosenRate.retailPrice,
        DealerCost: insurance.chosenRate.dealerCostPrice,
        SellingPrice: insurance.chosenRate.retailPrice,
        Attributes: insurance.chosenRate.insuranceType === "gap" ? gapAttributes : vscAttributes,
        ContractFormID:
          insurance.chosenRate.insuranceType === "gap"
            ? insurance.originalRate?.ContractFormID
            : insurance.originalRate.deductible?.ContractFormID,
        ProductCode: insurance.chosenRate.product.ProductCode,
        ...(surchargeAttributes?.length > 0
          ? { Surcharges: { Surcharge: surchargeAttributes } }
          : {})
      }
    };

    const Buyer = {
      FirstName: buyer.firstName,
      LastName: buyer.lastName,
      Contact: {
        AddressLine1: buyer.currentAddressNumber + " " + buyer.currentAddress,
        AddressLine2: "",
        City: buyer.currentCity,
        State: States[buyer.currentState]?.prefix,
        PostalCode: buyer.currentZipCode,
        Country: "US",
        Phone: appOnePhoneNumberFormat(buyer.mobilePhone),
        TimeZone: "",
        Facsimile: "",
        eMail: buyer.email
      }
    };
    const CoBuyer = {
      FirstName: coBuyer?.firstName,
      LastName: coBuyer?.lastName,
      Contact: {
        AddressLine1: coBuyer?.currentAddressNumber + " " + coBuyer?.currentAddress,
        AddressLine2: "",
        City: coBuyer?.currentCity,
        State: States[coBuyer?.currentState]?.prefix,
        PostalCode: coBuyer?.currentZipCode,
        Country: "US",
        Phone: appOnePhoneNumberFormat(coBuyer?.mobilePhone ?? ""),
        TimeZone: "",
        Facsimile: "",
        eMail: coBuyer?.email
      }
    };

    const DealNumber = requestId; // just for now

    const FormFields = buyer.email
      ? {
          FormField: [
            {
              FieldName: "CUSTOMER_EMAIL_ADDRESS",
              Value: buyer.email
            }
          ]
        }
      : undefined;

    const EX1ProviderID = insurance.chosenRate.EX1ProviderID;

    const Deal = {
      Vehicle,
      DealType,
      MSRP,
      SaleDate,
      VehicleSalePrice,
      FinanceTerms,
      Products,
      Buyer,
      ...(coBuyer ? { CoBuyer } : {}),
      DealNumber
    };

    const requiredFields = [
      { name: "Vehicle -> VIN", value: Vehicle.VIN },
      { name: "Vehicle -> In service date", value: Vehicle.InServiceDate },
      { name: "Vehicle -> Odometer", value: Vehicle.Odometer },
      { name: "Vehicle -> Car status", value: Vehicle.CarStatus },
      { name: "Vehicle -> Make", value: Vehicle.Make },
      { name: "Vehicle -> Model", value: Vehicle.Model },
      { name: "Vehicle -> Yaer", value: Vehicle.Year },
      { name: "Deal type", value: DealType },
      { name: "MSRP", value: MSRP },
      { name: "Deal type", value: DealType },
      { name: "Sale date", value: SaleDate },
      { name: "Vehicle -> Sale Price", value: VehicleSalePrice },
      { name: "Lender -> Bank name", value: FinanceTerms.Lienholder.Name },
      { name: "Lender -> Address", value: FinanceTerms.Lienholder.Contact.AddressLine1 },
      { name: "Lender -> City", value: FinanceTerms.Lienholder.Contact.City },
      { name: "Lender -> state", value: FinanceTerms.Lienholder.Contact.State },
      { name: "Lender -> PostalCode", value: FinanceTerms.Lienholder.Contact.PostalCode },
      { name: "Lender -> Country", value: FinanceTerms.Lienholder.Contact.Country },
      { name: "Lender -> Phone", value: FinanceTerms.Lienholder.Contact.Phone },
      { name: "Lender -> email", value: FinanceTerms.Lienholder.Contact.eMail },
      { name: "Deal -> Deal Total", value: FinanceTerms.FinanceAmount },
      { name: "Interest rate", value: FinanceTerms.FinanceRate },
      { name: "Monthly payment", value: FinanceTerms.MonthlyPayment },
      { name: "Number of payments", value: FinanceTerms.FinanceTerm },
      { name: "Residual amount", value: FinanceTerms.ResidualAmount },
      { name: "Amortization term", value: FinanceTerms.AmortTerm },
      { name: "EX1 rate response ID", value: Products.Product.EX1RateResponseID },
      { name: "DealerCost", value: Products.Product.DealerCost },
      { name: "SellingPrice", value: Products.Product.SellingPrice },
      { name: "Attributes", value: Products.Product.Attributes },
      { name: "Contract form ID", value: Products.Product.ContractFormID },
      { name: "Product code", value: Products.Product.ProductCode },
      { name: "Applicant -> first name", value: Buyer.FirstName },
      { name: "Applicant -> last name", value: Buyer.LastName },
      { name: "Applicant -> address", value: Buyer.Contact.AddressLine1 },
      { name: "Applicant -> city", value: Buyer.Contact.City },
      { name: "Applicant -> state", value: Buyer.Contact.State },
      { name: "Applicant -> postal code", value: Buyer.Contact.PostalCode },
      { name: "Applicant -> country", value: Buyer.Contact.Country },
      { name: "Applicant -> phone", value: Buyer.Contact.Phone },
      { name: "Deal number", value: DealNumber },
      { name: "EX1 Provider Id", value: EX1ProviderID }
    ];

    const handleSignContract = () => {
      if (unsavedDeal) {
        setAlertState(true);
        return;
      }
      dispatch(
        signContract({
          requestId,
          Deal,
          EX1DealerID: process.env.REACT_APP_EX1DealerID as string,
          FormFields,
          EX1ProviderID
        })
      );
    };
    checkDataValidity(requiredFields, handleSignContract);
  };

  const handleDownload = () => {
    window.open(
      stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "insurances", index])?.chosenRate
        ?.ContractForm
    );
  };

  useEffect(() => {
    if (actionStateSign !== undefined && actionStateSign.status !== undefined) {
      switch (actionStateSign.status) {
        case "error":
          setState({ ...state, isLoading: false, error: true });

          enqueueSnackbar(state.errorMessage, {
            variant: "error"
          });

          dispatch({
            type: signContractAction.none.type,
            payload: { requestId }
          });
          break;
        case "waiting":
          setState({ ...state, isLoading: true, error: false });
          dispatch({
            type: signContractAction.none.type,
            payload: { requestId }
          });
          break;
        case "success":
          const { contractResponse, formResponse } = actionStateSign.data.message.data;
          const contractResponseCode =
            contractResponse?.EX1ContractResponse?.MsgHeader?.Message[0]?.StatusCode || "1000";
          const formResponseCode =
            formResponse?.EX1FormResponse?.MsgHeader?.Message[0]?.StatusCode || "1000";
          const errorMessageContract =
            contractResponse?.EX1ContractResponse?.MsgHeader?.Message[0]?.Description;
          const errorMessageForm =
            formResponse?.EX1ContractResponse?.MsgHeader?.Message[0]?.Description ||
            "Unexpected error";

          if (contractResponseCode === "0000" && formResponseCode === "0000") {
            const ContractFormID =
              contractResponse.EX1ContractResponse.Products.Product[0].ContractFormID;

            const ContractNumber =
              contractResponse.EX1ContractResponse.Products.Product[0].ContractNumber;

            const ContractForm = formResponse.EX1FormResponse.Contracts[0].Product.ContractForm;

            const oldInsurance = stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index
            ]);

            stateAccess.set<Deal>(["data", "info", "aftermarketOptions", "insurances", index], {
              ...oldInsurance,
              chosenRate: {
                ...oldInsurance.chosenRate,
                ContractFormID,
                ContractNumber,
                ContractForm
              }
            });

            setState({
              ...state,
              isLoading: false,
              error: false,
              errorMessage: undefined,
              updateDeal: true
            });
          } else {
            const errorMessage = errorMessageContract ? errorMessageContract : errorMessageForm;
            enqueueSnackbar(errorMessage, {
              variant: "error",
              persist: true
            });

            setState({
              ...state,
              isLoading: false,
              error: true,
              errorMessage: errorMessage
            });
          }

          dispatch({
            type: signContractAction.none.type,
            payload: { requestId }
          });
          break;
      }
    }
  }, [
    actionStateSign,
    dispatch,
    requestId,
    state,
    stateAccess,
    index,
    isAlreadySigned,
    enqueueSnackbar
  ]);

  useEffect(() => {
    if (state.updateDeal) {
      dispatch(editDeal({ ...stateAccess.get([]), requestId: uuidv4() }));
      setState({
        ...state,
        updateDeal: false
      });
    }
  }, [state, stateAccess, dispatch]);

  return (
    <>
      {!isCustomInsurance && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isAlreadySigned ? (
            <IconButton color="primary" aria-label="download" onClick={handleDownload}>
              <CloudDownloadIcon style={{ color: "#254E70" }} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => handleSignContract(index)}
              color="primary"
              aria-label="search"
            >
              <BorderColorIcon style={{ color: "#254E70" }} />
              {state.isLoading && (
                <CircularProgress style={{ position: "absolute", display: "block" }} />
              )}
            </IconButton>
          )}
        </div>
      )}
      <AlertDialog
        open={alertState}
        closeFunc={setAlertState}
        content={"Please save the deal before signing contract."}
      />
    </>
  );
}
