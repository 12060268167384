import Grid from "@material-ui/core/Grid";
import React from "react";
import { createOptionsForSelect } from "utils/models/fields";
import { editRenderSet } from "utils/models/formRenderers";
import toCamelCase from "utils/toCamelCase";
import { FilterProps, filterStateAccess } from "./types";
const exists = (value: "With" | "Without") => {
  switch (value) {
    case "With":
      return { $exists: true, $nin: ["", null] };
    case "Without":
      return null;
  }
};
export default function NetsuiteFilter<T extends unknown>({
  setFilter,
  filter,
  path,
  filterType,
  label = ""
}: FilterProps<T>) {
  const stateAccess = filterStateAccess(filter, setFilter, (value: any) => {
    if (value) {
      return {
        [path.join(".")]: exists(value),
        ...(filterType === "aggregatedColumnFilter" ? { filterType } : {})
      };
    } else {
      setFilter(undefined);
    }
  });
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {editRenderSet(false).selectInputRenderer(
          {
            formComponent: "select-field",
            name: toCamelCase(label) ?? "netsuite-select",
            label,
            required: false,
            path: [] as [],
            options: createOptionsForSelect({
              possibleValues: () => ["With", "Without"],
              getOptionLabel: (x) => x,
              getSelectedOption: (x, y) => x === y
            }),
            valueToString: (x) => x
          },
          stateAccess,
          [],
          stateAccess,
          editRenderSet(false)
        )}
      </Grid>
    </Grid>
  );
}
