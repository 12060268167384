import { IconButton } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { HintTooltip } from "components/common/HintTooltip";
import { generateAndPrintContract } from "components/DocumentTemplates/PrintTemplate";
import { DocumentTemplate } from "components/DocumentTemplates/types";
import { addAdditionalPropertiesDocumentTemplate } from "components/Deals/DocumentsModal";
import { hideLoader, showLoader } from "components/Loader/loaderSlice";
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import React from "react";
import { useDispatch } from "react-redux";
import { StateAccess } from "utils/models/formGenerator";
import { getDocumentTemplates } from "utils/httpRequestsLibrary/documentTemplatesRequests";
import { getDealershipProgramByDeal } from "./Calculations";
import { Deal } from "./types";
import { notifyWebhooks } from "utils/logging";
interface Props {
  stateAccess: StateAccess;
}

export default function DocumentsModal({ stateAccess }: Props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const documentTemplateId =
    getDealershipProgramByDeal(stateAccess.get<Deal>([]))?.data?.info?.name ===
    "Performance Program"
      ? (process.env.REACT_APP_PRINT_PERFORMANCE_RECAP_SHEET_ID as string)
      : (process.env.REACT_APP_PRINT_RECAP_SHEET_ID as string);

  const handleDocumentPrint = async () => {
    dispatch(showLoader());
    await printTemplatesWithDataById(
      documentTemplateId,
      stateAccess,
      enqueueSnackbar,
      "recap_sheet"
    );
    dispatch(hideLoader());
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "0px",
        position: "absolute",
        right: "0px",
        top: "0px"
      }}
    >
      <IconButton onClick={handleDocumentPrint}>
        <HintTooltip title="Click here to print.">
          <PrintIcon />
        </HintTooltip>
      </IconButton>
    </div>
  );
}
export const printTemplatesWithDataById = async (
  documentTemplateId: string,
  stateAccess: StateAccess,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  type: "external_credit_application" | "recap_sheet"
) => {
  const templates = await getDocumentTemplates({
    query: { _id: documentTemplateId },
    options: { limit: 10000 }
  });

  if (
    Array.isArray(templates?.result?.docs) &&
    templates?.result?.docs.length === 0 &&
    templates?.status === "success"
  ) {
    const err = "Error while fetching documents. No document was found.";
    try {
      notifyWebhooks(err);
      enqueueSnackbar(err, { variant: "error" });
    } catch (error) {
      console.error(error);
    }
    return;
  }
  const document = addAdditionalPropertiesDocumentTemplate(
    templates?.result?.docs?.[0] as DocumentTemplate
  );
  document.documentData = templates?.result?.docs?.[0];
  await generateAndPrintContract(stateAccess, [document], type, enqueueSnackbar);
};
