import { createSocketMessageSlice } from "components/Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

export interface AddContractTypeResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}
const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  FormState,
  AddContractTypeResponse
>("Add Contract Type", {
  request: "new_contract_type"
});

export const addContractType = socketAction;
export const addContractTypeActions = socketMessageSlice.actions;

export default socketMessageSlice.reducer;
