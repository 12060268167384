import { Button, CircularProgress, Typography } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import { Deal } from "components/Deals/types";
import { VerificationContext } from "components/Layout/Main";
import Loader from "components/Loader/Loader";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fillDefaultsByPath,
  generateDefault,
  generateForm,
  getByPath,
  RenderSet,
  setByPath,
  StateAccess
} from "utils/models/formGenerator";
import store from "../../app/store";
import ASCRatesList from "./ASCRateList";
import { getASCRates, getAScRatesActions } from "./getASCRatesSlice";
import { ASCStruct } from "./model";
import { ASCInsurance, ASCPreRatesData, surchargesTypes, Term } from "./types";
interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  requestId: string;
  ascState: ASCInsurance;
  setASCState: React.Dispatch<React.SetStateAction<ASCInsurance>>;
}
const initialStateModifier = (initialState: ASCPreRatesData, deal: Deal): ASCPreRatesData => {
  return [
    { value: deal.data.info?.vehicle.make, path: ["VehicleMakeName"] },
    { value: deal.data.info?.vehicle.model, path: ["VehicleModelName"] },
    { value: deal.data.info?.vehicle.year, path: ["VehicleYear"] },
    { value: deal.data.info?.vehicle.VIN, path: ["VIN"] }
  ].reduce((acc, { path, value }) => setByPath(acc, path as any, value), initialState);
};
export default function ASCPreRates({
  stateAccess,
  renderSet,
  requestId,
  ascState,
  setASCState
}: Props) {
  const deal: Deal = stateAccess.get<Deal>([] as any);
  const numberOfPayments = deal?.data?.info?.payment?.numberOfPayments;
  const vehicle = deal?.data?.info?.vehicle;

  const dispatch = useDispatch();
  const checkDataValidity = useContext(VerificationContext);
  const rateState = useSelector((state: RootState) => state.getASCRates[requestId]);
  const initialState = initialStateModifier(
    generateDefault(ASCStruct, {}, fillDefaultsByPath as any) as any,
    deal
  );
  const settings = useSelector((state: RootState) => state.listSettingsSlice["settingsData"])
    ?.entities?.[0];
  const ascExtraProfit = settings?.data?.info.ascWarranty ?? 0;
  const [requestState, setRequestState] = useState<{
    terms: Term[];
    isLoading: boolean;
    error: boolean;
  }>({ terms: [], isLoading: false, error: false });
  const [state, setState] = useState<ASCPreRatesData>(initialState);
  const ASCPreRatesStateAccess: StateAccess = {
    get: (path) => getByPath(state as any, path),
    set: (path, value): any => setState(setByPath(state as any, path, value))
  };

  const requiredFields = [
    { name: "Vehicle -> VIN", value: vehicle.VIN },
    { name: "Vehicle -> Make", value: vehicle.make },
    { name: "Vehicle -> Year", value: vehicle.year },
    { name: "Vehicle -> Model", value: vehicle.model },
    { name: "Vehicle -> Odometer", value: vehicle.odometer }
  ];
  const reducedRates =
    requestState?.terms?.reduce(
      (acc: { [key: string]: Term[] }, curr: Term) =>
        acc[`${curr.ProgramName} ${curr.PlanName}`]
          ? {
              ...acc,
              [`${curr.ProgramName} ${curr.PlanName}`]: [
                ...acc[`${curr.ProgramName} ${curr.PlanName}`],
                curr
              ]
            }
          : { ...acc, [`${curr.ProgramName} ${curr.PlanName}`]: [curr] },
      {}
    ) ?? {};
  const handleClick = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const dataToSend = {
      AccountNumber: state?.AccountNumber,
      DRFC: state?.DRFC + (state?.AccountNumber === "42035N" ? ascExtraProfit : 0),
      Mileage: vehicle?.odometer,
      VIN: vehicle?.VIN,
      VehicleYear: vehicle?.year?.toString(),
      VehicleMakeName: vehicle?.make,
      VehicleMakeID: "",
      VehicleModelName: vehicle?.model,
      VehicleModelID: "",
      ManufacturerWarranty: state?.ManufacturerWarranty,
      BrandedOrSalvaged: state?.BrandedOrSalvaged,
      EngineCC: state?.EngineCC || 0,
      LiftedVehicle: state?.LiftedVehicle
    };
    checkDataValidity(requiredFields, () => {
      dispatch(
        getASCRates({
          requestId,
          data: dataToSend
        })
      );
    });
  };

  const handleChoseTerm = (
    term: Term,
    surcharges: { name: typeof surchargesTypes[number]; value: string; checked: boolean }[]
  ) => {
    const totalSurchargesPrice =
      surcharges?.reduce((acc, curr) => (curr.checked ? parseFloat(curr.value) + acc : acc), 0) ||
      0;
    const totalSurchargesCost =
      surcharges?.reduce((acc, curr) => (curr.checked ? parseFloat(curr.value) + acc : acc), 0) ||
      0;

    setASCState({
      ...ascState,
      AccountNumber: state?.AccountNumber,
      originalRate: term,
      provider: "ASC Warranty",
      LiftedVehicle: state?.LiftedVehicle,
      chosenRate: {
        surcharges: surcharges,
        ProgramID: term?.ProgramID,
        ProgramName: term?.ProgramName,
        PlanID: term?.PlanID,
        PlanName: term?.PlanName,
        TermID: term?.TermID,
        TermName: term?.TermName,
        Miles: term?.Miles,
        Months: term?.Months,
        FinalOdometer: term?.FinalOdometer,
        Expires: term?.Expires,
        FactoryWarranty: term?.FactoryWarranty,
        PlanCost: term?.PlanCost,
        TotalCareCoverage: term?.TotalCareCoverage,
        TotalCost: term?.TotalCost,
        LOL: term?.LOL,
        DRFC: state?.DRFC + (state?.AccountNumber === "42035N" ? ascExtraProfit : 0),
        EngineCC: state?.EngineCC,
        insuranceType: "service warranty",
        dealerCostPrice: parseFloat(term?.TotalCost) - (state?.DRFC ?? 0) + totalSurchargesCost,
        remitPrice:
          parseFloat(term?.TotalCost) -
          (state?.DRFC ?? 0) +
          totalSurchargesPrice -
          (state?.AccountNumber === "42035N" ? ascExtraProfit : 0),
        retailPrice: parseFloat(term?.TotalCost) + totalSurchargesPrice,
        profit: parseFloat(
          (
            parseFloat(term?.TotalCost) -
            (parseFloat(term?.TotalCost) - (state?.DRFC ?? 0))
          ).toFixed(2)
        ),
        soldBy: ascState?.chosenRate?.soldBy
      }
    });
  };
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (rateState !== undefined && rateState.status !== undefined) {
      switch (rateState.status) {
        case "error":
          enqueueSnackbar(`Error: ${rateState.message}`, {
            variant: "error"
          });
          setRequestState({ ...requestState, isLoading: false, error: true });
          dispatch({
            type: getAScRatesActions.none.type,
            payload: { requestId }
          });
          break;
        case "waiting":
          setRequestState({ ...requestState, isLoading: true, error: false });
          dispatch({
            type: getAScRatesActions.none.type,
            payload: { requestId }
          });
          break;
        case "success":
          setRequestState({
            terms: rateState?.data?.message?.data?.Terms?.Term,
            isLoading: false,
            error: false
          });
          dispatch({
            type: getAScRatesActions.none.type,
            payload: { requestId }
          });
      }
    }
  }, [dispatch, requestId, state, rateState, requestState]);
  return settings ? (
    <>
      <form id="preRates-form" onSubmit={handleClick}>
        {generateForm(ASCStruct, ASCPreRatesStateAccess, [], stateAccess, renderSet)}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
            padding: "10px"
          }}
        >
          <Button
            form="preRates-form"
            type="submit"
            color="primary"
            variant="contained"
            id="get-rates-button"
          >
            Get rates
          </Button>
        </div>
      </form>
      {!requestState?.error ? (
        requestState?.isLoading ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : Object.values(reducedRates).length > 0 ? (
          Object.values(reducedRates).map((rates, index) => {
            return (
              <ASCRatesList
                key={index}
                rates={rates}
                numberOfPayments={numberOfPayments}
                handleChoseTerm={handleChoseTerm}
              />
            );
          })
        ) : (
          "No rates for this vehicle!"
        )
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6">There seems to be a problem! Please try again later.</Typography>
        </div>
      )}
    </>
  ) : (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <CircularProgress />
    </div>
  );
}
