import React, { useState, useEffect } from "react";

import { StateAccess, getByPath, setByPath } from "utils/models/formGenerator";
import UserPreview from "components/Users/UserPreview";
import { User } from "components/Users/types";
import { showRenderSet } from "utils/models/formRenderers";
import { useDispatch, useSelector } from "react-redux";
import { editRenderSet } from "utils/models/formRenderers";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "app/rootReducer";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Checkbox, FormControlLabel, Grid, IconButton, TextField } from "@material-ui/core";
import { lockEntityFunction, unlockEntityFunction } from "utils/models/ShowForm";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import { Dealership } from "components/Dealerships/types";
import { editPartialDealership } from "components/Dealerships/editDealershipSlice";
import CloseDialogButton from "components/common/CloseDialogButton";

export default function ({ dealership }: { dealership: Dealership }) {
  const { user } = useSelector((state: RootState) => state.authSlice);
  const [requestId] = useState(uuidv4());
  const [data] = useState(dealership);
  const [initialSnoozeTypeState, setInitialSnoozeTypeState] = useState(
    dealership?.data?.info?.activityWarning?.snoozeType
  );
  const [initialActiveState, setInitialActiveState] = useState(dealership?.data?.info?.isActive);

  const [snooze, setSnooze] = useState<"twoWeeks" | "oneMonth" | "twoMonths" | undefined>(
    dealership?.data?.info?.activityWarning?.snoozeType
  );
  const [inactive, setInactive] = useState(false);
  const [snoozeTypeFormState, setSnoozeTypeFormState] = useState(initialSnoozeTypeState);
  const [activeFormState, setActiveFormState] = useState(initialActiveState);
  const editDealershipState = useSelector(
    (state: RootState) => state?.editDealershipSlice[requestId]
  );
  const lockData = useSelector((state: RootState) => state.listLockSlice["all"])?.entities?.find(
    (lock) =>
      lock?.data?.info?.entityId === dealership?._id &&
      lock?.data?.info?.entityName === "dealership"
  );
  const dispatch = useDispatch();
  const [lockedDialogOpen, setLockedDialogOpen] = useState(false);
  const [snoozeDialogOpen, setSnoozeDialogOpen] = useState(false);
  const [comment, setComment] = useState("");

  const dealershipId = dealership?._id;
  const currentlyLockedEmail = lockData?.data?.info?.userEmail ?? null;
  const canEdit = user?.email === currentlyLockedEmail;

  const isLocked = currentlyLockedEmail !== null && !canEdit;

  useEffect(() => {
    if (editDealershipState?.status === "success") {
      unlockEntityFunction(dispatch, dealershipId as string, "dealership");
    }
  }, [editDealershipState, dealershipId, dispatch]);
  useEffect(() => {
    if (canEdit || initialSnoozeTypeState !== dealership?.data?.info?.activityWarning?.snoozeType) {
      setSnoozeTypeFormState(dealership?.data?.info?.activityWarning?.snoozeType);
    }
  }, [dealership?.data?.info?.activityWarning?.snoozeType, canEdit, initialSnoozeTypeState]);
  useEffect(() => {
    if (canEdit || initialActiveState !== dealership?.data?.info?.isActive) {
      setInitialActiveState(dealership?.data?.info?.isActive);
    }
  }, [dealership?.data?.info?.isActive, canEdit, initialActiveState]);
  useEffect(() => {
    if (
      snoozeTypeFormState !== undefined &&
      initialSnoozeTypeState !== snoozeTypeFormState &&
      editDealershipState?.status !== "waiting" &&
      snooze &&
      (canEdit || currentlyLockedEmail === null)
    ) {
      dispatch(
        editPartialDealership({
          _id: dealershipId as string,
          ...{
            data: {
              info: {
                activityWarning: {
                  snoozeType: snooze,
                  checkedOrSnoozedDate: new Date().toISOString(),
                  warning: false
                }
              }
            }
          },
          requestId
        })
      );
      setInitialSnoozeTypeState(snoozeTypeFormState);
    }
  }, [
    snoozeTypeFormState,
    dispatch,
    requestId,
    canEdit,
    initialSnoozeTypeState,
    data,
    editDealershipState,
    dealershipId
  ]);
  useEffect(() => {
    if (
      activeFormState !== undefined &&
      initialActiveState !== activeFormState &&
      editDealershipState?.status !== "waiting" &&
      (canEdit || currentlyLockedEmail === null)
    ) {
      dispatch(
        editPartialDealership({
          _id: dealershipId as string,
          ...{
            data: {
              info: {
                isActive: false,
                statusHistory: [
                  ...(dealership?.data?.info?.statusHistory ?? []),
                  {
                    date: new Date().toISOString(),
                    userEmail: user?.databaseData?.data?.info?.email,
                    comment,
                    status: "inactive"
                  }
                ]
              }
            }
          },
          requestId
        })
      );
      setInitialActiveState(activeFormState);
    }
  }, [
    activeFormState,
    dispatch,
    requestId,
    canEdit,
    initialActiveState,
    data,
    editDealershipState,
    dealershipId
  ]);

  const handleClick = () => {
    if (isLocked) {
      setLockedDialogOpen(true);
    } else if (canEdit || currentlyLockedEmail === null) {
      lockEntityFunction(dispatch, dealershipId as string, "dealership");
      setSnoozeDialogOpen(true);
    }
  };
  const handleSave = () => {
    if (snooze) {
      setSnoozeTypeFormState(snooze);
    } else {
      setActiveFormState(!inactive);
    }
    setSnoozeDialogOpen(false);
  };
  const handleClickUnlock = () => {
    lockEntityFunction(dispatch, dealershipId as string, "dealership");
    setLockedDialogOpen(false);
    setSnoozeDialogOpen(true);
  };

  const handleCloseLockedDialog = () => {
    setLockedDialogOpen(false);
  };
  const handleCloseSnoozeDialog = () => {
    setSnoozeDialogOpen(false);
  };
  return (
    <>
      <Dialog
        id="confirm-delete-dialog"
        open={lockedDialogOpen}
        onClose={handleCloseLockedDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to unlock this dealership?
        </DialogTitle>
        <CloseDialogButton closeFunction={handleCloseLockedDialog} />

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {lockData?.updatedAt &&
              `Locked by ${lockData?.data?.info?.userEmail}
       ${new Intl.DateTimeFormat("en", {
         day: "2-digit",
         month: "2-digit",
         year: "2-digit",
         hour: "2-digit",
         minute: "2-digit",
         second: "2-digit"
       }).format(new Date(lockData?.updatedAt))}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLockedDialog} variant="contained" color="primary" id="cancel">
            Cancel
          </Button>
          <Button
            onClick={handleClickUnlock}
            variant="contained"
            color="secondary"
            autoFocus
            id="confirm"
          >
            Unlock
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        id="snooze-dialog"
        open={snoozeDialogOpen}
        onClose={handleCloseSnoozeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Snooze dealership warnings</DialogTitle>
        <CloseDialogButton closeFunction={handleCloseSnoozeDialog} />
        <DialogContent>
          <Grid container>
            <Grid item xs={3}>
              <FormControlLabel
                id="snooze-two-weeks"
                control={
                  <Checkbox
                    id="snooze-two-weeks-checkbox"
                    checked={snooze === "twoWeeks"}
                    value={snooze === "twoWeeks"}
                    onChange={() => {
                      setSnooze("twoWeeks");
                      setInactive(false);
                    }}
                  />
                }
                label="Two weeks"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                id="snooze-one-month"
                control={
                  <Checkbox
                    id="snooze-one-month-checkbox"
                    checked={snooze === "oneMonth"}
                    value={snooze === "oneMonth"}
                    onChange={() => {
                      setSnooze("oneMonth");
                      setInactive(false);
                    }}
                  />
                }
                label="One month"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                id="snooze-two-months"
                control={
                  <Checkbox
                    id="snooze-two-months-checkbox"
                    checked={snooze === "twoMonths"}
                    value={snooze === "twoMonths"}
                    onChange={() => {
                      setSnooze("twoMonths");
                      setInactive(false);
                    }}
                  />
                }
                label="Two months"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                id="set-inactive"
                control={
                  <Checkbox
                    id="inactive-checkbox"
                    checked={inactive}
                    value={inactive}
                    onChange={() => {
                      setInactive(!inactive);
                      setSnooze(undefined);
                    }}
                  />
                }
                label="Mark as inactive"
              />
            </Grid>
            {inactive && (
              <Grid item xs={12}>
                <TextField
                  label="Comment"
                  fullWidth
                  margin="dense"
                  type="text"
                  required
                  multiline
                  minRows={3}
                  variant="filled"
                  value={comment}
                  onChange={(event) => setComment(event.target.value)}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSnoozeDialog} variant="contained" color="primary" id="cancel">
            Cancel
          </Button>
          <Button
            disabled={
              snooze === dealership?.data?.info?.activityWarning?.snoozeType ||
              (inactive && !comment)
            }
            onClick={handleSave}
            variant="contained"
            color="secondary"
            autoFocus
            id="confirm"
          >
            Snooze
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ position: "relative" }}>
        <div style={{ textAlign: "center", padding: "10px" }}>
          <Button
            disabled={dealership?.data?.info?.activityWarning?.warning !== true}
            color="primary"
            variant="contained"
            onClick={() => {
              handleClick();
            }}
          >
            Snooze
          </Button>
        </div>
      </div>
    </>
  );
}
