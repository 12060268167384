import { createListSlice } from "../Middlewares/listSliceCreator";
import { Chargeback } from "./types";

const { ListSlice, getList } = createListSlice<Chargeback>({
  name: "ChargebackList",
  request_topic: "all_chargebacks",
  reducers: {}
});

export default ListSlice.reducer;
export const getChargebackList = getList;
export const listChargebackActions = ListSlice.actions;
