import { createListSlice } from "../Middlewares/listSliceCreator";
import { CustomerCareNote } from "./types";

const { ListSlice, getList } = createListSlice<CustomerCareNote>({
  name: "CustomerCareNoteNoteList",
  request_topic: "all_customer_care_notes",
  reducers: {}
});
export default ListSlice.reducer;
export const getCustomerCareNotesList = getList;
export const listCustomerCareNotesActions = ListSlice.actions;
export const removeCustomerCareNoteNotesList = ListSlice.actions.removeList;
