import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

interface RecoverBussinesReportRequest {
  _id: string;
}

export interface RecoverBussinesReportResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverBussinesReportRequest,
  RecoverBussinesReportResponse
>("RecoverBussinesReport", {
  request: "recover_business_report"
});

export const recoverBussinesReportActions = socketMessageSlice.actions;
export const recoverBussinesReport = socketAction;
export default socketMessageSlice.reducer;
