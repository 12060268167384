import React from "react";
import { Chip, Typography } from "@material-ui/core";
import { TitleManagement } from "./types";

export default function Suspended({ titleManagement }: { titleManagement: TitleManagement }) {
  const {
    isRestricted,
    outstandingDeals,
    outstandingTitlesFundingLimit,
    outstandingTitlesApplicationsLimit
  } = titleManagement || {};

  if (!isRestricted) {
    return null;
  }

  const applicationProcessing =
    (titleManagement?.outstandingDeals || 0) >
    (titleManagement?.outstandingTitlesApplicationsLimit || 0);
  const funding =
    (titleManagement?.outstandingDeals || 0) >
    (titleManagement?.outstandingTitlesFundingLimit || 0);

  const isSuspended = applicationProcessing || funding;
  const label = isSuspended ? "Suspended" : "Operational";
  const color = isSuspended ? "secondary" : "primary";

  return (
    <div style={{ display: "flex", gap: 5, flexDirection: "column" }}>
      <Typography variant="body1" color={`${funding ? "error" : "primary"}`}>{`Funding limit: ${
        outstandingDeals ?? 0
      } / ${outstandingTitlesFundingLimit || 0}`}</Typography>
      <Typography
        variant="body1"
        color={`${applicationProcessing ? "error" : "primary"}`}
      >{`Penalty box limit: ${outstandingDeals ?? 0} / ${
        outstandingTitlesApplicationsLimit || 0
      }`}</Typography>

      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="h6">
          {`Status: `}
          &nbsp;&nbsp;
        </Typography>
        <Chip label={label} color={color} />
      </div>
    </div>
  );
}
