import { InputAdornment } from "@material-ui/core";

import React, { useCallback, useEffect, useState } from "react";
import { RenderSet, StateAccess } from "utils/models/formGenerator";

import { Deal, ReserveMethod } from "./types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Big from "big.js";

type MarkupData = {
  markup: number | string | null;
  reservePercentage?: number;
  title?: string;
  extraReservePercentage?: number;
  extraReserveFixed?: number;
  dealerSplitPercentage?: number;
};

const CustomSelectField = ({
  stateAccess,
  renderSet,
  options,
  path
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  options: MarkupData[];
  path: string[];
}) => {
  const value = stateAccess.get(path);
  const length = value?.markup?.toString()?.split(".")?.[1]?.length;
  const decimalPlaces = length && length > 1 ? length : 2;

  const [state, setState] = useState({
    markup:
      Number.isNaN(value?.markup) || !value?.markup
        ? null
        : parseFloat(value?.markup).toFixed(decimalPlaces > 1 ? decimalPlaces : 2),
    reservePercentage: value?.reservePercentage ?? null
  });

  useEffect(() => {
    const markup = state?.markup ? parseFloat(state?.markup) : state?.markup;

    if (value?.markup !== markup)
      setState({
        markup:
          Number.isNaN(value?.markup) || typeof value?.markup !== "number"
            ? null
            : parseFloat(value?.markup).toFixed(decimalPlaces > 1 ? decimalPlaces : 2),
        reservePercentage: value?.reservePercentage ?? 0
      });
  }, [value?.markup, setState, decimalPlaces, state?.markup]);

  return renderSet.type === "edit" ? (
    <Autocomplete
      value={state}
      options={options}
      onChange={(event, newValue: any) => {
        if (newValue) {
          if (Number.isNaN(parseFloat(newValue?.markup))) return stateAccess.set(path, null);

          stateAccess.set(path, newValue);
          setState({
            markup: newValue?.markup,
            reservePercentage: newValue?.reservePercentage ?? 0
          });
        } else stateAccess.set(path, null);
      }}
      onBlur={() => {
        setState({
          markup: parseFloat(value?.markup).toFixed(decimalPlaces > 1 ? decimalPlaces : 2),
          reservePercentage: value?.reservePercentage ?? 0
        });
      }}
      onInputChange={(event, newValue) => {
        if (newValue) {
          const markup = options.find((markup) => markup.markup === parseFloat(newValue));

          if (Number.isNaN(parseFloat(newValue))) return stateAccess.set(path, null);
          const newMarkup = {
            markup: markup?.markup || parseFloat(newValue),
            reservePercentage: markup?.reservePercentage || 0,
            extraReservePercentage: markup?.extraReservePercentage || 0,
            extraReserveFixed: markup?.extraReserveFixed || 0,
            dealerSplitPercentage: markup?.dealerSplitPercentage || 0
          };
          if (JSON.stringify(newMarkup) !== JSON.stringify(value)) {
            stateAccess.set(path, newMarkup);
          }

          setState({
            markup: newValue,
            reservePercentage: markup?.reservePercentage || 0
          });
        } else stateAccess.set(path, null);
      }}
      openOnFocus
      id="markup-select"
      getOptionLabel={(option) => {
        if (option.inputValue) {
          return option?.inputValue?.toString();
        }
        return option?.markup?.toString();
      }}
      renderOption={(option) => option.title || option.markup}
      freeSolo
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            value={value?.markup ?? ""}
            label="Markup"
            size="small"
            variant="filled"
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              className: "test",
              startAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
          />
        );
      }}
    />
  ) : (
    <TextField
      id="markup-select"
      value={value?.markup ?? ""}
      name="Markup"
      fullWidth
      label="Markup"
      variant="outlined"
      size="small"
      InputProps={{ style: { textAlign: "right" } }}
    />
  );
};

export default function MarkupReservePreview({
  stateAccess,
  renderSet,
  markupReserveTableData
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  markupReserveTableData: {
    reservePercentage: number;
    markup: number;
    isDefault?: boolean | undefined;
  }[];
}) {
  return markupReserveTableData?.length > 0 ? (
    <CustomSelectField
      stateAccess={stateAccess}
      renderSet={renderSet}
      options={markupReserveTableData}
      path={["data", "info", "payment", "markupData"]}
    />
  ) : null;
}
