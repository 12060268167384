import { Model } from "../../utils/models/fields";
import React from "react";
import { LenderDescision } from "./types";
import DealPreview from "./DealPreview";
export const lenderDecisionStruct: Model<LenderDescision> = {
  formComponent: "model",
  schema: "new_lender_decision",
  name: "lender_decision",
  entities: [
    {
      formComponent: "segment",
      name: "Lender Decision",
      entities: [
        {
          formComponent: "radio-field",
          name: "decision",
          label: "Decision",
          width: "full",
          path: ["data", "info", "decision"],
          possibleValues: [
            { label: "Approved", value: "Approved" },
            { label: "Conditionally Approved", value: "Conditionally Approved" },
            { label: "Declined", value: "Declined" },
            { label: "Decision Canceled", value: "Decision Canceled" },
            { label: "Pending", value: "Pending" }
          ],
          required: true,
          default: null
        },
        {
          formComponent: "one-to-many-field",
          component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
            <DealPreview stateAccess={stateAccess} renderSet={renderSet} />
          ),
          name: "DealPreview",
          label: "Deal ref",
          width: "1/3",
          path: [] as any,
          default: {}
        },
        {
          formComponent: "name-field",
          name: "lenderName",
          label: "Lender name",
          required: true,
          width: "1/3",
          path: ["data", "info", "lender"],
          default: null
        },
        {
          formComponent: "name-field",
          name: "product",
          label: "Product",
          required: true,
          width: "1/3",
          path: ["data", "info", "product"],
          default: null
        },
        {
          formComponent: "name-field",
          name: "applicantName",
          label: "Applicant name",
          required: true,
          width: "1/3",
          path: ["data", "info", "applicant"],
          default: null
        },
        {
          formComponent: "phone-field",
          name: "Applicant phone",
          label: "Applicant phone",
          required: true,
          width: "1/3",
          path: ["data", "info", "phoneNumber"],
          default: null
        },
        {
          formComponent: "date-field",
          name: "dateSubmitted",
          label: "Date sumbitted",
          width: "1/3",
          path: ["data", "info", "dateSubmitted"],
          required: true,
          default: null
        }
      ]
    }
  ]
};
