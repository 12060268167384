import Grid from "@material-ui/core/Grid";
import { RootState } from "app/rootReducer";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOptionsForSelect } from "utils/models/fields";
import { editRenderSet } from "utils/models/formRenderers";
import { getUserList, removeUserList } from "../Users/listUserSlice";
import { FilterProps, filterStateAccess } from "./types";

export default function UserFilter<T extends unknown>({
  setFilter,
  path,
  type,
  filterType,
  filter,
  label
}: FilterProps<T>) {
  const dispatch = useDispatch();
  const listId = `list-user-filter-${type}`;
  const userList = useSelector((state: RootState) => state.listUserSlice[listId]);
  const handleOpen = () => {
    if (!userList)
      dispatch(
        getUserList(listId, {
          options: {
            projection: {
              _id: 1,
              "data.info.firstName": 1,
              "data.info.lastName": 1,
              "data.info.userType": 1,
              insensitiveFirstName: {
                $toLower: "$data.info.firstName"
              }
            },
            pagination: false,
            sort: { insensitiveFirstName: "asc" }
          },
          query: {
            "data.info.userType": type
          }
        })
      );
    return () => {
      dispatch(removeUserList(listId));
      return;
    };
  };

  const stateAccess = filterStateAccess(filter, setFilter, (values: any) => {
    if (values && values?.length > 0 && values?.every((value: any) => value?._id) !== undefined) {
      return {
        [path.join(".")]: {
          $in: values.map((value: any) => ({ value: value?._id }))
        },
        ...(filterType === "aggregatedColumnFilter" ? { filterType } : {})
      };
    } else {
      setFilter(undefined);
    }
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {editRenderSet(false).multiSelectInputRenderer(
          {
            formComponent: "multiselect-field",
            name: label?.toLowerCase() ?? "user-multi-filter",
            label: label ?? "User",
            required: false,
            path: [] as [],
            onOpen: handleOpen,
            options: createOptionsForSelect({
              possibleValues: () => userList?.entities ?? [],
              getOptionLabel: (user) =>
                `${user?.data?.info?.firstName} ${user?.data?.info?.lastName}`,
              getSelectedOption: (x, y) => x?._id === y?._id
            }),
            valueToString: (user) =>
              `${user?.data?.info?.firstName ?? "-"} ${user?.data?.info?.lastName ?? "-"}`
          },
          stateAccess,
          [],
          stateAccess,
          editRenderSet(false)
        )}
      </Grid>
    </Grid>
  );
}
