import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import infoProperties from "./info_properties";

const data = {
  type: "object",
  required: ["info"],
  properties: {
    entityAprovalRoles: {
      type: "object",
      nullable: true,
      properties: {
        applicantApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        contractApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        contractTypeApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        customReportApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        dealApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        dealershipApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        dmvApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        documentTemplateApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        documentValidationApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        fileApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        fundingDocumentApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        lenderApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        lenderDecisionApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        otherVendorApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        payoffBankApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        stateApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        titleIssueApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
        userApprovalRoleIds: {
          type: "array",
          items: {
            type: "string",
            nullable: true,
          },
        },
      },
    },
    info: { type: "object", properties: infoProperties },
  },
};
export default {
  update_settings: {
    $id: "updateSettings",
    type: "object",
    properties: {
      _id: { type: "string" },
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      approved: {
        type: "boolean",
        nullable: true,
      },
      approvedAt: {
        type: "string",
        nullable: true,
      },
      approvedByUserId: {
        type: "string",
        nullable: true,
      },
    },
  },

  get_settings: {
    $id: "getSettings",
    type: "object",
    properties: {
      _id: { type: "string" },
    },
  },
  all_settings: {
    $id: "allSettings",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
