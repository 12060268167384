import React, { useState } from "react";
import { StateAccess, RenderSet, getByPath } from "utils/models/formGenerator";
import { createOptionsForSelect, Path } from "utils/models/fields";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { getContractTypeList, removeContractTypeList } from "./listContractTypeSlice";
import { v4 as uuidv4 } from "uuid";
import { Contract } from "components/Contracts/types";
import { ContractType } from "./types";
import AddContractType from "components/OtherVendors/AddEntitiy";
import { Box, Grid } from "@material-ui/core";
import { addContractType } from "./addContractTypeSlice";
import { contractTypeStruct } from "./model";
interface Props<T> {
  stateAccess: StateAccess;
  path: Path<T>;
  renderSet: RenderSet;
  name: string;
  label: string;
}

export default function ContractTypePreview<T extends Contract>({
  stateAccess,
  path,
  renderSet,
  name,
  label
}: Props<T>) {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const listId = `list-autocomplete-contract_types-${requestId}`;
  const contractTypeList = useSelector((state: RootState) => state.listContractTypeSlice[listId]);

  const contractTypes: ContractType[] = stateAccess.get<T>(path) ?? [];
  const contractTypeStateAccess: StateAccess = {
    get: (path) => getByPath<ContractType[]>(contractTypes, path as Path<ContractType[]>),
    set: (_, value): any => stateAccess.set<T>(path, value)
  };

  const handleLoadContractTypes = () => {
    if (!contractTypeList)
      dispatch(
        getContractTypeList(listId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } }
        })
      );
    return () => {
      dispatch(removeContractTypeList(listId));
      return;
    };
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1}>
              {renderSet.multiSelectInputRenderer(
                {
                  formComponent: "multiselect-field",
                  name,
                  label,
                  path: [],
                  onOpen: handleLoadContractTypes,
                  options: createOptionsForSelect({
                    possibleValues: () => contractTypeList?.entities ?? [],
                    getOptionLabel: (contractType) => {
                      return contractType?.data?.info?.type ?? "";
                    },
                    getSelectedOption: (x, y) => {
                      return x._id === y._id;
                    }
                  }),
                  valueToString: (el) => {
                    return el.map((el: ContractType) => el.data.info.type).join(", ");
                  }
                },
                contractTypeStateAccess,
                [],
                contractTypeStateAccess,
                renderSet
              )}
            </Box>
            <Box>
              <AddContractType
                type="contract_type"
                addFunction={addContractType}
                struct={contractTypeStruct}
                addSlice={"addContractTypeSlice"}
                setSelected={(type) => {
                  stateAccess.set(path as [], [...(stateAccess.get(path) || []), type]);
                }}
                renderSet={renderSet}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
