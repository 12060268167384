import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

type AddAdditionalRequiredDocument = { data: FormState };

interface AddAdditionalRequiredDocumentResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddAdditionalRequiredDocument,
  AddAdditionalRequiredDocumentResponse
>("AddAdditionalRequiredDocument", {
  request: "new_additional_required_document"
});

export const addAdditionalRequiredDocumentActions = socketMessageSlice.actions;
export const addAdditionalRequiredDocument = socketAction;
export default socketMessageSlice.reducer;
