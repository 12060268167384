import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { CreditScoreRange, AddCreditScoreRangeRequest } from "./types";

interface AddCreditScoreRangeResponse {
  message: CreditScoreRange;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddCreditScoreRangeRequest,
  AddCreditScoreRangeResponse
>("AddCreditScoreRangeResponse", {
  request: "new_credit_score_range"
});

export const addCreditScoreRangeActions = socketMessageSlice.actions;

export const addCreditScoreRange = socketAction;

export default socketMessageSlice.reducer;
