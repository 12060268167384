import { createListSlice } from "../Middlewares/listSliceCreator";
import { Role } from "./types";

const { ListSlice, getList } = createListSlice<Role>({
  name: "RoleList",
  request_topic: "all_roles",
  reducers: {}
});
export default ListSlice.reducer;
export const getRoleList = getList;
export const listRoleActions = ListSlice.actions;
export const removeRoleList = ListSlice.actions.removeList;
