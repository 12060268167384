import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorIcon from "@material-ui/icons/Error";
import SearchIcon from "@material-ui/icons/Search";
import { RootState } from "app/rootReducer";
import { HintTooltip } from "components/common/HintTooltip";
import formEditContext from "components/Content/FormEditContext";
import { ExternalCreditApplication } from "components/ExternalCreditApplications/types";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityType } from "utils/entitySlice";
import { Path } from "utils/models/fields";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import { VerificationContext } from "../Layout/Main";
import { Deal } from "./types";
import { vinCheck, vinCheckActions } from "./vinCheckSlice";
interface Props {
  stateAccess: StateAccess;
  type: EntityType;
  path: any;
  isVinDecodedPath: string[];
  renderSet: RenderSet;
  name: string;
  label: string;
}

export default function VinCheck<T extends ExternalCreditApplication | Deal>({
  stateAccess,
  type,
  path,
  isVinDecodedPath,
  renderSet,
  name,
  label
}: Props) {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const { enqueueSnackbar } = useSnackbar();
  const vinCheckStatus = useSelector(
    (state: RootState) => state.listApiStatusSlice["table"]
  )?.entities?.find((el) => el.id === process.env.REACT_APP_VIN_CHECK_API_ID);
  const actionState = useSelector((state: RootState) => state.vinCheckSlice[requestId]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDecoded, setIsDecoded] = useState(false);
  const pathToSet = path.slice(0, path.length - 1) as Path<T>;
  const { enabled: editMode } = useContext(formEditContext);
  const VIN = stateAccess.get<T>(path);
  const isVinDecoded = stateAccess.get<T>(isVinDecodedPath);
  const neededData = [{ name: "Vehicle -> VIN", value: VIN }];
  const vinCheckIsOperational =
    vinCheckStatus?.status === "operational" || vinCheckStatus === undefined;

  const checkDataValidity = useContext(VerificationContext);
  const performVinSearch = () => {
    dispatch(vinCheck({ data: { info: { vinNumber: VIN } }, requestId }));
    setIsLoading(true);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    checkDataValidity(neededData, performVinSearch);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Enter") {
      event?.preventDefault();
      checkDataValidity(neededData, performVinSearch);
    }
  };
  useEffect(() => {
    if (actionState !== undefined && actionState.status !== undefined) {
      switch (actionState.status) {
        case "error":
          dispatch({
            type: vinCheckActions.none.type,
            payload: { requestId }
          });
          setIsLoading(false);
          enqueueSnackbar(
            <span
              dangerouslySetInnerHTML={{
                __html: actionState.message
              }}
            />,
            {
              variant: "error"
            }
          );
          break;
        case "waiting":
          setIsLoading(true);
          break;
        case "success":
          const { status, message } = actionState.data;
          const response = message?.REPORTS?.REPORT?.VINPOWER?.VIN?.DECODED;
          if (status === "success") {
            stateAccess.set(pathToSet, {
              ...stateAccess.get(pathToSet),
              make: response?.Make,
              model: response?.Model,
              year: type === "deal" ? parseInt(response?.Model_Year) : response?.Model_Year,
              isVinDecoded: true
            });
            enqueueSnackbar(`VIN successfully decoded!`, {
              variant: "success"
            });
          } else
            enqueueSnackbar(`Unable to decode this VIN!`, {
              variant: "error"
            });
          setIsLoading(false);
          dispatch({
            type: vinCheckActions.none.type,
            payload: { requestId }
          });
          break;
      }
    }
    // @ts-ignore
  }, [actionState, dispatch, requestId, stateAccess, enqueueSnackbar, pathToSet, type]);

  useEffect(() => {
    dispatch({
      type: vinCheckActions.add.type,
      payload: { requestId }
    });

    return () => {
      dispatch({
        type: vinCheckActions.remove.type,
        payload: { requestId }
      });
    };
  }, [requestId, dispatch]);

  return (
    <>
      <Grid container>
        <Grid item xs={vinCheckIsOperational ? 12 : 11}>
          <Box style={{ display: "flex" }}>
            {renderSet.upperCaseTextInputRenderer(
              {
                type: "text",
                name,
                label,
                path: path as []
              },
              stateAccess,
              renderSet
            )}
            <IconButton
              color="primary"
              aria-label="search"
              disabled={editMode === undefined ? false : !editMode}
              style={{
                marginLeft: "10px",
                color: isVinDecoded ? "green" : undefined
              }}
              onClick={handleClick}
              id="vincheck-button"
              onKeyDown={handleKeyDown}
            >
              <HintTooltip
                title={"Click here to search for a vehicle automatically by VIN number."}
              >
                {isVinDecoded && !isLoading ? <CheckIcon fontSize="medium" /> : <SearchIcon />}
              </HintTooltip>
              <CircularProgress
                style={{ position: "absolute", display: isLoading ? "block" : "none" }}
              />
            </IconButton>
          </Box>
        </Grid>
        {!vinCheckIsOperational && (
          <Grid container item xs={1} justifyContent="center" alignItems="center">
            <Tooltip placement="top" title="Vin Check service is unavailable!">
              <ErrorIcon color="secondary" />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </>
  );
}
