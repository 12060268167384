import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { DealershipTicket } from "./types";
interface RecoverDealershipTicketRequest {
  _id: string;
}

export interface RecoverDealershipTicketResponse {
  message: DealershipTicket;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverDealershipTicketRequest,
  RecoverDealershipTicketResponse
>("RecoverDealershipTicket", {
  request: "recover_dealership_ticket"
});

export const recoverDealershipTicketActions = socketMessageSlice.actions;
export const recoverDealershipTicket = socketAction;
export default socketMessageSlice.reducer;
