import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stipulation } from "./types";
import { RootState } from "app/rootReducer";
import { v4 as uuidv4 } from "uuid";
import formEditContext from "components/Content/FormEditContext";
import {
  Box,
  CircularProgress,
  DialogContentText,
  IconButton,
  Paper,
  TextField
} from "@material-ui/core";
import { HintTooltip } from "components/common/HintTooltip";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { deleteStipulation } from "./deleteStipulationSlice";
import { addStipulation } from "./addStipulationSlice";
import { editStipulation } from "./editStipulationSlice";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { storage } from "../../firebase/firebase";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelCircleIcon from "@material-ui/icons/Cancel";
import { useSnackbar } from "notistack";
import { getStipulationsList, listStipulationsActions } from "./listStipulationsSlice";
import { Help } from "@material-ui/icons";
import formatDate from "utils/formatDate";
interface Props {
  stipulations: Stipulation[];
  dealId: string;
}
export default function StipulationsPreview({ stipulations, dealId }: Props): JSX.Element {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [addRequestId] = React.useState(uuidv4());
  const [deleteRequestId] = React.useState(uuidv4());
  const [uploadsInProgress, setUploadsInProgress] = React.useState<{ [id: string]: boolean }>({});
  const [timeout, setTimeoutState] = React.useState<undefined | NodeJS.Timeout>();

  const addStipulationState = useSelector(
    (state: RootState) => state.addStipulationSlice[addRequestId]
  );
  const listStipulationsState = useSelector(
    (state: RootState) => state.listStipulationSlice[dealId]
  );
  const allEditStipulationStates = useSelector((state: RootState) => state.editStipulationSlice);
  const editStipulationStates = listStipulationsState?.entities?.map((stipulation) => ({
    _id: stipulation._id,
    state: allEditStipulationStates[stipulation._id]
  }));
  const deleteStipulationState = useSelector(
    (state: RootState) => state.deleteStipulationSlice[deleteRequestId]
  );
  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);

  const isLoading =
    addStipulationState?.status === "waiting" || deleteStipulationState?.status === "waiting";

  const { enabled: editMode } = React.useContext(formEditContext);
  React.useEffect(() => {
    if (!listStipulationsState && editMode)
      dispatch(
        getStipulationsList(dealId, {
          query: { deleted: false, "data.dealId": dealId },
          options: { sort: { createdAt: -1 } }
        })
      );
  }, [dispatch, editMode]);
  // React.useEffect(() => {
  //   if (
  //     deleteStipulationState !== undefined &&
  //     deleteStipulationState.status === "success" &&
  //     deleteStipulationState.data !== null
  //   ) {
  //     const index = listStipulationsState.entities?.findIndex(
  //       (s: Stipulation) => s._id === deleteStipulationState?.data?.message?._id
  //     );
  //     dispatch({
  //       type: listStipulationsActions.editList.type,

  //       payload: { index: index, payload: deleteStipulationState?.data?.message, listId: dealId }
  //     });
  //   }
  // }, [deleteStipulationState, dispatch]);

  const handleAdd = () => {
    const defaultStipulation = {
      data: {
        dealId,
        info: {
          description: "",
          user: {
            firstName: currentUser?.data?.info?.firstName || null,
            lastName: currentUser?.data?.info?.lastName || null,
            email: currentUser?.data?.info?.email || null
          }
        }
      }
    };
    dispatch(addStipulation({ requestId: addRequestId, ...(defaultStipulation as Stipulation) }));
  };

  const handleDelete = (id: string) => {
    dispatch(deleteStipulation({ requestId: deleteRequestId, _id: id }));
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    stipulation: Stipulation & { index: number }
  ) => {
    const newStipulation = {
      ...stipulation,
      data: {
        ...stipulation.data,
        info: { ...stipulation.data.info, description: event.target.value }
      },
      updatedAt: new Date().toISOString()
    };
    dispatch({
      type: listStipulationsActions.editList.type,
      payload: { index: stipulation.index, payload: newStipulation, listId: dealId }
    });
    return newStipulation;
  };

  const handleUpdate = (stipulation: Stipulation) => {
    dispatch(editStipulation({ requestId: stipulation._id, ...stipulation }));
  };
  const handleUploadStipulation = (
    event: React.ChangeEvent<HTMLInputElement>,
    stipulation: Stipulation & { index: number }
  ) => {
    setUploadsInProgress((prevUploads) => ({
      ...prevUploads,
      [stipulation._id]: true
    }));
    const uploadedFiles = event.target.files ?? [];

    if (uploadedFiles.length !== 0) {
      const file = uploadedFiles[0];
      const firebaseFileName = `${uuidv4()}-${file.name}`;

      const uploadTask = storage.ref(`/files/Deal/${dealId}/${firebaseFileName}`).put(file);

      uploadTask.on(
        "state_changed",
        (_snapShot) => {},
        (err) => {
          setUploadsInProgress((prevUploads) => ({
            ...prevUploads,
            [stipulation._id]: false
          }));
          enqueueSnackbar(err.message, {
            variant: "error"
          });
        },
        () => {
          const ref = storage.ref(`/files/Deal/${dealId}`).child(firebaseFileName);

          ref.getDownloadURL().then((fireBaseUrl) => {
            ref.getMetadata().then((metadata) => {
              setUploadsInProgress((prevUploads) => ({
                ...prevUploads,
                [stipulation._id]: false
              }));
              const editedStipulation = {
                ...stipulation,
                fileName: file.name,
                metadata,
                data: {
                  ...stipulation.data,
                  info: {
                    ...stipulation.data.info,
                    url: fireBaseUrl
                  }
                }
              };

              dispatch({
                type: listStipulationsActions.editList.type,
                payload: { index: stipulation.index, payload: editedStipulation, listId: dealId }
              });
              dispatch(editStipulation({ requestId: stipulation._id, ...editedStipulation }));
            });
          });
        }
      );
    }
  };
  const renderEntities = () => {
    return (listStipulationsState?.entities ?? stipulations)
      .map((s, index) => ({ ...s, index }))
      .filter((s) => !s.deleted)
      .sort((a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime())
      ?.map((stipulation, index) => {
        const state = editStipulationStates?.find((state) => state._id === stipulation._id)?.state;

        return (
          <Paper
            key={index}
            style={{
              padding: "10px",
              marginBottom: "20px",
              display: "block",
              background: state?.status === "error" ? "#ffe3e3" : undefined
            }}
          >
            <Box display="flex" alignContent="flex-start">
              <Box flexGrow={1}>
                <TextField
                  multiline
                  onChange={(event) => {
                    if (timeout) {
                      clearTimeout(timeout);
                    }
                    const newStipulation = handleChange(event, stipulation);
                    setTimeoutState(
                      setTimeout(() => {
                        handleUpdate(newStipulation);
                      }, 500)
                    );
                  }}
                  value={stipulation?.data?.info?.description}
                  name="stipulation"
                  inputProps={
                    editMode && listStipulationsState?.status !== "waiting"
                      ? {}
                      : {
                          readOnly: true
                        }
                  }
                  placeholder="Stipulation"
                  fullWidth
                  label={"Stipulation"}
                  variant={
                    editMode && listStipulationsState?.status !== "waiting" ? "filled" : "outlined"
                  }
                  size="small"
                  onBlur={(event) => {
                    if (event.target.value !== stipulation?.data?.info?.description) {
                      const newStipulation = handleChange(event, stipulation);
                      handleUpdate(newStipulation);
                    }
                  }}
                />
                <Box
                  fontSize={12}
                  justifyContent="space-between"
                  display={"flex"}
                  marginTop={"10px"}
                  style={{ maxHeight: 20 }}
                >
                  <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                    {(() => {
                      switch (state?.status) {
                        case "waiting":
                          return (
                            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                              <CircularProgress
                                size={17}
                                style={{
                                  color: "#50A538",
                                  minHeight: 20.5,
                                  maxHeight: 20.5
                                }}
                              />
                              Saving
                            </div>
                          );
                        case "error":
                          return (
                            <button
                              className="pulse hover"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                border: "none",
                                background: "#ffe3e3",
                                fontWeight: "bold",
                                columnGap: "3px",
                                borderRadius: 3
                              }}
                              onClick={() => {
                                handleUpdate(stipulation);
                              }}
                            >
                              <CancelCircleIcon
                                style={{
                                  color: "#E34C28"
                                }}
                              />
                              Error! Click here to retry!
                            </button>
                          );
                        default:
                          return (
                            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                              <CheckCircleIcon
                                style={{
                                  color: "#50A538"
                                }}
                              />
                              Saved
                            </div>
                          );
                      }
                    })()}
                  </div>
                  {[
                    stipulation.data?.info?.user?.firstName,
                    stipulation?.data?.info?.user?.lastName,
                    formatDate(stipulation?.createdAt, "medium", true)
                  ]
                    .filter((x) => x)
                    .join(" ")}
                </Box>
              </Box>
              {editMode && (
                <Box style={{ display: "flex", alignItems: "start" }}>
                  {!stipulation?.data?.info?.url && (
                    <input
                      accept=".pdf"
                      id={`upload-stipulation-${stipulation._id}`}
                      type="file"
                      onChange={(e) => handleUploadStipulation(e, stipulation)}
                      hidden
                    />
                  )}
                  {uploadsInProgress?.[stipulation._id] ? (
                    <CircularProgress
                      size={20}
                      style={{
                        padding: 12,
                        color: "#50A538"
                      }}
                    />
                  ) : (
                    <label
                      htmlFor={`upload-stipulation-${stipulation._id}`}
                      style={{ display: "flex" }}
                    >
                      <IconButton
                        component="span"
                        style={{
                          color: stipulation?.data?.info?.url ? "green" : "#E34C28"
                        }}
                        key={index}
                        aria-label={`edit stipulation`}
                        disabled={state?.status === "waiting"}
                      >
                        <HintTooltip title={`Click here to upload the file for the stipulation.`}>
                          <AttachFileIcon />
                        </HintTooltip>
                      </IconButton>
                    </label>
                  )}
                  {editMode && (
                    <IconButton
                      style={{
                        color: "#E34C28"
                      }}
                      key={index}
                      aria-label={`remove stipulation`}
                      onClick={() => handleDelete(stipulation._id)}
                      disabled={deleteStipulationState?.status === "waiting"}
                    >
                      <HintTooltip title={`Click here to remove the stipulation.`}>
                        <RemoveCircleIcon />
                      </HintTooltip>
                    </IconButton>
                  )}
                </Box>
              )}
            </Box>
          </Paper>
        );
      });
  };

  return (
    <Paper
      elevation={0}
      style={{
        breakInside: "avoid",
        pageBreakInside: "avoid",
        transform: "translateZ(1)"
      }}
    >
      <Box style={{ display: "flex", alignItems: "center" }} key={"Stipulation"}>
        <DialogContentText
          style={{
            color: "#254e6e",
            fontSize: "19px",
            margin: "5px 0px 5px 0px",
            fontWeight: "bold"
          }}
        >
          Stipulations
        </DialogContentText>
        <HintTooltip
          style={{ color: "#254e6e", fontSize: "20px", marginLeft: 4 }}
          title={
            <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
              Autosaved / Visible to dealer / Notofication is autosend
            </div>
          }
        >
          <Help />
        </HintTooltip>
        {editMode && (
          <Box>
            <IconButton
              style={{
                color: "#50A538"
              }}
              aria-label={`add new Stipulation`}
              onClick={handleAdd}
              disabled={isLoading}
            >
              <HintTooltip title={`Click here to add new Stipulation.`}>
                {isLoading ? <CircularProgress size={19} /> : <AddCircleIcon />}
              </HintTooltip>
            </IconButton>
          </Box>
        )}
      </Box>
      {renderEntities()}
    </Paper>
  );
}
