import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverStateRequest {
  _id: string;
}

export interface RecoverStateResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverStateRequest,
  RecoverStateResponse
>("RecoverState", {
  request: "recover_state"
});

export const recoverStateActions = socketMessageSlice.actions;
export const recoverState = socketAction;
export default socketMessageSlice.reducer;
