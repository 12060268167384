import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

type AddState = { data: FormState };

interface AddStateResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<AddState, AddStateResponse>(
  "AddState",
  {
    request: "new_state"
  }
);

export const addStateActions = socketMessageSlice.actions;
export const addState = socketAction;
export default socketMessageSlice.reducer;
