import {
  createSocketMessageSlice,
  Identifiable
} from "../../Middlewares/socketMessageSliceCreator";

interface GetProductPreRatesRequest {
  requestId: string;
  data: {
    params: {
      EX1PreRateRequest: {
        EX1DealerID: string;
        EX1ProductID: string;
        Vehicle: Vehicle;
      };
    };
  };
}

interface Vehicle {
  VIN: string;
  Odometer: string;
  CarStatus: string;
  DealType: string;
  Optional: null;
  Supplemental: null;
}

interface RequestData {
  productId: string;
  VIN: string;
  Odometer: string;
  CarStatus: string;
  DealType: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  GetProductPreRatesRequest,
  any
>("GetProductPreRates", {
  request: "express_ex1_pre_rate"
});

export const getProductPreRates = (request: RequestData & Identifiable) => {
  const { requestId, productId, VIN, Odometer, CarStatus, DealType } = request;
  return socketAction({
    requestId,
    data: {
      params: {
        EX1PreRateRequest: {
          EX1DealerID: process.env.REACT_APP_EX1DealerID as string,
          EX1ProductID: productId,
          Vehicle: {
            VIN,
            Odometer,
            CarStatus,
            DealType,
            Optional: null,
            Supplemental: null
          }
        }
      }
    }
  });
};

export const getProductPreRatesAction = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
