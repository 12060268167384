import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { AddDealRequestSend, AddDealRequest, DealResponse } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddDealRequestSend,
  DealResponse
>("Add Deal", {
  request: "new_deal"
});

export const addDeal = ({
  data,
  requestId,
  availableToRolesIds
}: AddDealRequest & Identifiable) => {
  const {
    lender,
    dealership,
    vehicleInsuranceCompany,
    payoffBank,
    applicant,
    coApplicant,
    user,
    stateTax,
    notes,
    dealerNotes,
    appOneData,
    dealertrackDealNumbers,
    ...rest
  } = data;
  return socketAction({
    requestId,
    availableToRolesIds: availableToRolesIds ?? [],
    data: {
      ...rest,
      stateTaxId: stateTax?._id ?? null,
      lenderId: lender?._id ?? null,
      dealershipId: dealership?._id ?? null,
      payoffBankId: payoffBank?._id ?? null,
      vehicleInsuranceCompanyId: vehicleInsuranceCompany?._id ?? null,
      applicantId: applicant?._id ?? null,
      coApplicantId: coApplicant?._id ?? null,
      userId: user?._id ?? null,
      appOneData: appOneData ?? null,
      dealertrackDealNumbers: dealertrackDealNumbers ?? null
    }
  });
};

export const addDealActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
