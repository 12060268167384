import { CustomRegexes } from "../../helpers";

export default {
  firstName: {
    type: "string",
    pattern: CustomRegexes.LETTERS,
    nullable: true,
    errorMessage: {
      type: "Contact's first name must be a string.",
      pattern: "Contact's first name should not contain numbers.",
    },
  },
  lastName: {
    type: "string",
    pattern: CustomRegexes.LETTERS,
    nullable: true,
    errorMessage: {
      type: "Contact's last name must be a string.",
      pattern: "Contact's last name should not contain numbers.",
    },
  },
  phone: {
    type: "string",
    pattern: CustomRegexes.PHONE,
    nullable: true,
    errorMessage: {
      type: "Contact's phone number must be a string.",
      pattern:
        "Contact's phone number should not contain letters and should not start with 0 or 1.",
    },
  },
  email: {
    type: "string",
    pattern: CustomRegexes.EMAIL,
    nullable: true,
    errorMessage: {
      pattern:
        "should match the following format: <emailname>@<provider>.<org>",
    },
  },
  title: { type: "string", nullable: true },
  notificationOnlineApplication: { type: "boolean", nullable: true },
  hubspotId: { type: "string", nullable: true },
  role: {
    type: "string",
    enum: ["Primary Contact", "Finance Contact", "Business Manager", null],
    errorMessage: {
      enum: "must be one of the following: 'Primary', 'Finance' or 'Business Manager'",
    },
    nullable: true,
  },
};
