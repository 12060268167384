import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import Typography from "@material-ui/core/Typography";
import { getMonthDaysAsAnArray } from "utils/functions";
import ReactApexChart from "react-apexcharts";
import Paper from "@material-ui/core/Paper";
import { getDealCommissionByDays } from "./dealCommissionByDaysSlice";
import { DealStatus } from "components/Deals/types";
import { randomNumber } from "./FIManagerDashboard";
import { User, UserStatistics } from "components/Users/types";
interface Props {
  from: Date;
  to: Date;
  users: User[];
  showNumbers: boolean;
}
const round = (number: number) => {
  return Math.round(number * 100) / 100;
};
const commissionStatuses = [
  DealStatus.SubmittedForFunding,
  DealStatus.Funded,
  DealStatus.FundingHeld
];
const getPvr = (year: number, month: number, statistics?: UserStatistics[]) =>
  Array.isArray(statistics) && statistics?.length
    ? statistics?.find((stat) => stat.year === year && stat.month === month)?.pvr || 0
    : 0;

export default ({ showNumbers, from, to, users }: Props) => {
  const dispatch = useDispatch();
  const requestId = "dealCommissionByStatus";
  const requestIdByDay = "dealCommissionByDay";

  useEffect(() => {
    dispatch(
      getDealCommissionByDays(requestIdByDay, {
        query: {
          from,
          to,
          statuses: commissionStatuses,
          userIds: users.map((user) => user._id)
        }
      })
    );
  }, [from, to]);

  // @ts-ignore
  const commissionsByDay = useSelector(
    (state: RootState) => state.listDealCommisionByDaysSlice[requestIdByDay]
    // @ts-ignore
  )?.entities?.reduce((acc, { _id, sum }) => {
    return { ...acc, [_id]: sum + (acc[_id] || 0) };
  }, {});
  // @ts-ignore

  const fundedCommissionsByDay = useSelector(
    (state: RootState) => state.listDealCommisionByDaysSlice[requestIdByDay]
    // @ts-ignore
  )?.entities?.reduce((acc, { _id, funded }) => {
    return { ...acc, [_id]: funded + (acc[_id] || 0) };
  }, {});
  const series = [
    {
      name: "Total daily commission",
      data: getMonthDaysAsAnArray(from).map((day) => {
        const amount =
          commissionsByDay?.[
            `${from.getFullYear()}-${(from.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${day.toString().padStart(2, "0")}`
          ] || 0;
        return round(showNumbers ? amount : amount === 0 ? amount : randomNumber());
      })
    },
    {
      name: "Funded commission",
      data: getMonthDaysAsAnArray(from).map((day) => {
        const amount =
          fundedCommissionsByDay?.[
            `${from.getFullYear()}-${(from.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${day.toString().padStart(2, "0")}`
          ] || 0;
        return round(showNumbers ? amount : amount === 0 ? amount : randomNumber());
      })
    }
  ];

  return (
    <Paper style={{ height: "275px" }} elevation={3}>
      <Typography
        gutterBottom
        align="center"
        style={{
          color: "#3e4446",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontSize: "14px",
          fontWeight: 900,
          paddingTop: 5
        }}
      >
        Total commission by day
      </Typography>

      <ReactApexChart
        options={{
          chart: {
            id: Math.random().toString(),
            toolbar: { show: false },
            zoom: { enabled: false },
            offsetY: 0
          },
          dataLabels: { enabled: false },
          yaxis: {
            labels: {
              formatter: (value) => {
                return showNumbers ? value.toString() : "xxx";
              }
            }
          },
          xaxis: {
            labels: { show: true },
            categories: getMonthDaysAsAnArray(from)
          }
        }}
        series={series}
        type="bar"
        width={"100%"}
        height={180}
      />
    </Paper>
  );
};
