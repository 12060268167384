import { RootState } from "app/rootReducer";
import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";

import BusinessReport from "./BusinessReport";
import BusinessReportsTable from "./BusinessReportsTable";
export default function () {
  return (
    <Grid container style={{ padding: "10px" }}>
      <Grid item xs={12} container>
        <Typography variant="h6" color="primary">
          Business Reports
        </Typography>
      </Grid>

      <Grid item xs={12} container>
        <BusinessReport />
      </Grid>

      <Grid item xs={12} container>
        <BusinessReportsTable />
      </Grid>
    </Grid>
  );
}
