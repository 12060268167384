import { createListSlice } from "../Middlewares/listSliceCreator";

const { ListSlice, getList } = createListSlice<any>({
  name: "HistoriesList",
  request_topic: "all_histories",
  reducers: {}
});

export default ListSlice.reducer;
export const getHistoriesList = (collection: string, _id: string) => (id: any) =>
  getList(id, {
    withDeleted: true,
    options: { pagination: false },
    query: {
      "data.collection": collection,
      "data.oldDocument._id": _id
    }
  });
export const removeHistoriesList = ListSlice.actions.removeList;
export const listHistoriesActions = ListSlice.actions;
