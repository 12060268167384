import { Model } from "utils/models/fields";
import { ContractType } from "./types";

export const contractTypeStruct: Model<ContractType> = {
  formComponent: "model",
  schema: "update_contract_type",
  name: "contract_type",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      name: "Contract type",
      width: "full",
      entities: [
        {
          formComponent: "text-field",
          name: "Type",
          label: "Type",
          width: "full",
          path: ["data", "info", "type"],
          required: true
        }
      ]
    }
  ]
};
