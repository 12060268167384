import { createSocketMessageSlice } from "components/Middlewares/socketMessageSliceCreator";
import { getASCRatesReqeust, getASCRatesResponse } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  getASCRatesReqeust,
  getASCRatesResponse
>("GetProductRates", {
  request: "asc_get_rate"
});

export const getASCRates = socketAction;
export const getAScRatesActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
