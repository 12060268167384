import info_properties from "./info_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import { Schema } from "ajv";

const data: Schema = {
  type: "object",
  required: ["info"],
  properties: {
    info: { type: "object", properties: info_properties },
  },
};

export default {
  new_document_validation: {
    $id: "newDocumentValidation",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
  update_document_validation: {
    $id: "updateDocumentValidation",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: data,
    },
  },
  delete_document_validation: {
    $id: "deleteDocumentValidation",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  recover_document_validation: {
    $id: "recoverDocumentValidation",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_document_validation: {
    $id: "getDocumentValidation",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_document_validations: {
    $id: "allDocumentValidations",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
