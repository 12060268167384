import React from "react";
import { PersonaReport } from "../../types/internal/personaReports";
import { PhoneRiskReportPayloadAttributes } from "../../types/external/reports/common";
import {
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Grid,
  Paper,
  capitalize,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead
} from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";
import { useSnackbar } from "notistack";
import { Applicant } from "components/Applicants/types";
import { Verification } from "../../types/internal/personaVerifications";
import { isPhoneReportValid } from "../../FraudValidationPreview";
import {
  VerificationOrReportDialogActions,
  getColorByStatus,
  renderVerificationOrReportHeader
} from "../common";
import { renderTableHeadRow, renderTableRowWithData } from "./common";

type Props = {
  reports: PersonaReport<PhoneRiskReportPayloadAttributes>[];
  applicant: Applicant;
  isLoading: boolean;
  retryFunction: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedVerificationType: keyof Verification | "all"
  ) => void;
  closeFunction: () => void;
};
const formatRiskScore = (riskScore: number) => {
  return `${riskScore / 10}/100`;
};
const PhoneReportsHistoryDialog = ({
  reports,
  applicant,
  isLoading,
  closeFunction,
  retryFunction
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dangerousRiskScores = ["100/100", "90/100", "85/100", "95/100", "80/100"];
  const [rawJSONDialogOpen, setRawJSONDialogOpen] = React.useState(false);
  const [rawJSON, setRawJSON] = React.useState("");
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (copied) {
      enqueueSnackbar("Successfully copied!", {
        variant: "success"
      });
    }
  }, [copied]);
  const closeRawDialogFunction = () => {
    setRawJSONDialogOpen(false);
    setCopied(false);
  };
  const copyRawJSON = async () => {
    await navigator.clipboard.writeText(rawJSON);
    setCopied(true);
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        id="raw-json-dialog"
        open={rawJSONDialogOpen}
        onClose={closeRawDialogFunction}
      >
        <DialogTitle id="raw-json-dialog-title">
          <CloseDialogButton closeFunction={closeRawDialogFunction} />
        </DialogTitle>
        <DialogContent>
          <pre>{rawJSON}</pre>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button
            style={copied ? { background: "green" } : {}}
            onClick={copyRawJSON}
            variant="contained"
            autoFocus
            color="primary"
            id="copy"
          >
            Copy
          </Button>
          <Button onClick={closeRawDialogFunction} variant="contained" color="primary" id="cancel">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        id="fraud-validation-reports-dialog"
        open={true}
        onClose={closeFunction}
      >
        <DialogTitle id="fraud-validation-reports-dialog-title">
          {`Phone Report History - ${applicant.data.info.firstName} ${applicant.data.info.lastName}`}
          <CloseDialogButton closeFunction={closeFunction} />
        </DialogTitle>
        <DialogContent>
          <Grid container xs={12}>
            {reports.reverse().map((report, index) => {
              const status = isPhoneReportValid(report?.data?.info?.report?.response)
                ? "Valid"
                : "Invalid";

              const statusColor = getColorByStatus(status);
              return (
                <Paper key={index} elevation={3} style={{ width: "100%", marginBottom: "10px" }}>
                  <Grid container spacing={1} item xs={12} style={{ padding: "5px", margin: 0 }}>
                    {renderVerificationOrReportHeader(
                      status,
                      statusColor,
                      report?.createdAt,
                      report?.data?.info?.performedByUserEmail,
                      () => {
                        setRawJSON(JSON.stringify(report, null, 2));
                        setRawJSONDialogOpen(true);
                      }
                    )}
                    <Grid item xs={12}>
                      <TableContainer style={{ marginTop: "10px" }} component={Paper}>
                        <Table size="medium">
                          <TableHead>{renderTableHeadRow()}</TableHead>
                          <TableBody>
                            {renderTableRowWithData(
                              "Risk Level (max: 5)",
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.phone_risk_level as null | undefined
                              )
                                ? undefined
                                : capitalize(
                                    report?.data?.info?.report?.response?.data?.attributes
                                      ?.phone_risk_level ?? ""
                                  ),
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.phone_risk_level as null | undefined
                              )
                                ? undefined
                                : !report?.data?.info?.report?.response?.data?.attributes?.phone_risk_level?.includes(
                                    "High"
                                  )
                            )}
                            {renderTableRowWithData(
                              "Risk Recommendation",
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.phone_risk_recommendation as null | undefined
                              )
                                ? undefined
                                : capitalize(
                                    report?.data?.info?.report?.response?.data?.attributes
                                      ?.phone_risk_recommendation ?? ""
                                  ),
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.phone_risk_recommendation as null | undefined
                              )
                                ? undefined
                                : !report?.data?.info?.report?.response?.data?.attributes?.phone_risk_level?.includes(
                                    "High"
                                  )
                            )}
                            {renderTableRowWithData(
                              "Risk Score",
                              typeof report?.data?.info?.report?.response?.data?.attributes
                                ?.phone_risk_score === "number"
                                ? formatRiskScore(
                                    report?.data?.info?.report?.response?.data?.attributes
                                      ?.phone_risk_score
                                  )
                                : undefined,
                              typeof report?.data?.info?.report?.response?.data?.attributes
                                ?.phone_risk_score === "number"
                                ? !dangerousRiskScores.includes(
                                    formatRiskScore(
                                      report?.data?.info?.report?.response?.data?.attributes
                                        ?.phone_risk_score || 0
                                    )
                                  )
                                : undefined
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          </Grid>
        </DialogContent>
        <VerificationOrReportDialogActions
          recordsLength={reports?.length || 0}
          retryFunction={retryFunction}
          selectedVerificationType="phoneRiskReport"
          isLoading={isLoading}
          closeFunction={closeFunction}
        />
      </Dialog>
    </React.Fragment>
  );
};
export default PhoneReportsHistoryDialog;
