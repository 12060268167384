import { auth } from "../../firebase/firebase";
import axios from "axios";
import { Channel, CreateChannelRequest, MemberChannel } from "Chat/types/channels";
import { HttpQuery } from "Chat/types/http";

export const getChannels = (email?: string, query?: HttpQuery): (() => Promise<Channel[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${process.env.REACT_APP_HTTP_URL}/api/v1/channels/${email}`, {
      headers: {
        authorization: `Bearer ${token}`,
        ...(query && Object.keys(query)?.length ? { query: JSON.stringify(query) } : {})
      }
    });

    return response.data.result?.docs ?? [];
  };
};
export const createChannel = (): ((channel: CreateChannelRequest) => Promise<Channel>) => {
  return async (channel) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/channel/`,
      channel,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return response.data.result;
  };
};
export const hideChannel = (): ((channel: Channel) => Promise<MemberChannel>) => {
  return async (channel) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/hide_channel/${channel._id}`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return response.data.result;
  };
};
