import { Schema } from "ajv";
import { CustomRegexes } from "../../helpers";

const data: Schema = {
  type: "object",
  properties: {
    templateName: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.LETTERS,
      errorMessage: { pattern: "must not contain numbers" },
    },
    templateData: { type: "object", nullable: true },
    to: { type: "array", items: { type: "string" } },
    subject: { type: "string" },
    body: { type: "string", nullable: true },
  },
};

export default {
  email_send: {
    $id: "emailSend",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
};
