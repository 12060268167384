import React from "react";
import { IconButton } from "@material-ui/core";
import { OptionsObject, useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { Tooltip } from "@material-ui/core";
import { storage } from "../../firebase/firebase";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { addManyLenderDecisions } from "./addManyLenderDecisionsSlice";
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";

const handleCSVUpload = async (
  event: React.ChangeEvent<HTMLInputElement>,
  enqueueSnackbar: (
    message: React.ReactNode,
    options?: OptionsObject | undefined
  ) => React.ReactText,
  requestId: string,
  dispatch: Dispatch<any>
) => {
  const file = event.target.files ?? [];
  if (file[0]?.type !== "text/csv") {
    enqueueSnackbar(`Unsupported file type!`, {
      variant: "error"
    });
    return;
  }
  const fileUrl = await uploadFileToFirebase(file[0], enqueueSnackbar);
  const content = await readFileContent(file[0]);
  let rows = [];
  if (content.includes("\r\n")) rows = content?.split("\r\n");
  else rows = content?.split("\n");

  const rowsWithoutHeader = rows?.splice(1, rows.length);
  const data = rowsWithoutHeader
    .filter((row) => row !== "")
    .map((row, index) => {
      // Make sure we don't split on commas which are inside a cell value

      const cells = row.split(/,(?=(?:[^"]*"[^"]*")*(?![^"]*"))/);
      const decisions: { decision: string; lender: string }[] = parseDecisions(
        cells.splice(6, cells.length),
        []
      );

      return {
        data: {
          info: {
            dateSubmitted: cells[0],
            applicant: cells[1],
            createdBy: cells[2],
            modifiedBy: cells[3],
            phoneNumber: cells[4],
            product: cells[5],
            decisions: decisions,
            fileUrl
          }
        },
        deleted: false
      };
    });

  dispatch(addManyLenderDecisions({ requestId, data: data }));
};
const parseDecisions = (decisions: any, acc: any): any => {
  if (decisions.length === 0) return acc;

  const [lender, decision] = decisions.splice(0, 2);
  const newAcc = [...acc, { lender: lender.replace(/"/g, ""), decision }];
  return parseDecisions(decisions, newAcc);
};

const uploadFileToFirebase = (
  file: File,
  enqueueSnackbar: (
    message: React.ReactNode,
    options?: OptionsObject | undefined
  ) => React.ReactText
) => {
  return new Promise<string>((resolve) => {
    const randomID = uuidv4();
    const firebaseFileName = `${randomID}-${file.name}`;

    const uploadTask = storage.ref(`/files/LenderDecisions}/${firebaseFileName}`).put(file);
    uploadTask.on(
      "state_changed",
      (_snapShot) => {},
      (err) => {
        enqueueSnackbar(err.message, {
          variant: "error"
        });
      },
      () => {
        const ref = storage.ref(`/files/LenderDecisions}`).child(firebaseFileName);

        ref.getDownloadURL().then((fireBaseUrl: string) => resolve(fireBaseUrl));
      }
    );
  });
};
const readFileContent = async (file: File) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = (event) => resolve(event?.target?.result as string);
  });
};

export default (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const requestId = uuidv4();
  return (
    <>
      <input
        accept=".csv"
        id={`upload-csv`}
        type="file"
        onChange={(e) => handleCSVUpload(e, enqueueSnackbar, requestId, dispatch)}
        onClick={(event) => {
          //@ts-ignore
          event.target.value = null;
        }}
        hidden
      />
      <label htmlFor={`upload-csv`}>
        <Tooltip title="Upload lender decisions">
          <IconButton component="span">
            <CloudUploadIcon />
          </IconButton>
        </Tooltip>
      </label>
    </>
  );
};
