import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditLenderTicketRequest {
  data: FormState;
}

export interface EditLenderTicketResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditLenderTicketRequest,
  EditLenderTicketResponse
>("EditLenderTicket", {
  request: "update_lender_ticket"
});

export const editLenderTicketActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
export const editLenderTicket = (request: EditLenderTicketRequest & Identifiable) => {
  const { lender, assignees, deal, titleIssue, type, ...rest } = request.data;

  return socketAction({
    ...request,
    data: {
      ...rest,
      dealId: deal ? deal?._id : null,
      typeId: type ? type._id : null,
      assigneeIds: assignees && assignees.length ? assignees.map((x) => x._id) : null,
      lenderId: lender ? lender._id : null,
      titleIssueId: titleIssue ? titleIssue?._id : null
    }
  });
};
