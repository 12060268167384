import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { Model, createOptionsForSelect, OptionsForSelect } from "../../utils/models/fields";
import PrintedDocumentsPreview from "./PrintedDocumentsPreview";
import { UnparsedRequiredDocument } from "./types";
import React from "react";
export const unparsedRequiredDocumentsModel: Model<UnparsedRequiredDocument> = {
  formComponent: "model",
  schema: false,
  name: "unparsed_required_document",
  entities: [
    {
      formComponent: "segment",
      entities: [
        {
          formComponent: "one-to-many-field",
          component: (
            stateAccess: StateAccess,
            mainstateAccess: StateAccess,
            renderSet: RenderSet
          ) => (
            <PrintedDocumentsPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "printedDocument"]}
              name="documentTemplate"
              label="Document Template"
            />
          ),
          required: true,
          name: "printedDocument",
          width: "full",
          path: ["data", "printedDocument"],
          default: null
        },
        {
          formComponent: "select-field",
          name: "page",
          label: "Page",
          width: "full",
          path: ["data", "page"],
          options: createOptionsForSelect({
            possibleValues: () => Array.from({ length: 20 }, (_, i) => i + 1),
            getOptionLabel: (x) => x.toString(),
            getSelectedOption: (x, y) => x === y
          }),
          required: true,
          default: null
        }
      ]
    }
  ]
};
