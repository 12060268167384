import Button from "@material-ui/core/Button";
import React from "react";
import { StateAccess } from "utils/models/formGenerator";
import { setReserve, setSplit } from "./Calculations";
import { Deal } from "./types";
import { useSnackbar } from "notistack";
import { Settings } from "components/Settings/types";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import Big from "big.js";

const logging = <T extends any>(x: T) => {
  const errorMesages: (string | boolean)[] = [];
  const successMesages: (string | boolean)[] = [];

  let currentResult = x;
  const a = {
    map: (f: (x: T) => T | string | boolean, successMessage: string | boolean) => {
      const result = f(currentResult);

      if (typeof result === "string") {
        errorMesages.push(result);
      } else if (typeof result === "object") {
        successMesages.push(successMessage);
        currentResult = result;
      }
      return a;
    },
    log: (
      errorFunction: (messages: (string | boolean)[]) => void,
      successFunction: (messages: (string | boolean)[]) => void
    ) => {
      errorFunction(errorMesages);
      successFunction(successMesages);
      return currentResult;
    }
  };
  return a;
};

export default function ({ stateAccess }: { stateAccess: StateAccess }) {
  const deal = stateAccess.get<Deal>([]);
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSelector((state: RootState) => state.listSettingsSlice["settingsData"])
    ?.entities?.[0] as Settings;
  const setExtraGapCommissions = (deal: Deal): Deal | string | false => {
    let gapExtraCommission = undefined;

    const settingsInfo = settings?.data?.info;
    const insurances = deal?.data?.info?.aftermarketOptions?.insurances;

    const gap = insurances?.find((el) => el.chosenRate.insuranceType === "gap");
    if (!gap && !deal?.data?.info?.profit?.wfdProfit?.extraGAPProfit) return false;
    if (!gap && deal?.data?.info?.profit?.wfdProfit?.extraGAPProfit)
      return {
        ...deal,
        data: {
          ...deal?.data,
          info: {
            ...deal?.data?.info,
            profit: {
              ...deal?.data?.info?.profit,
              wfdProfit: {
                ...deal?.data?.info?.profit?.wfdProfit,
                extraGAPProfit: 0
              }
            }
          }
        }
      };

    switch (gap?.provider) {
      case "Protective":
        gapExtraCommission = settingsInfo?.protectiveGap;
        break;
      case "Compass":
        gapExtraCommission = settingsInfo?.compassGap;
        break;
      case "GWC":
      case "APCO - EASYCARE":
        gapExtraCommission = settingsInfo?.gwcGap;
        break;
      case "XtraRide":
        gapExtraCommission = settingsInfo?.xtraRideGap;
        break;
      case "NSD":
      case "Nation Safe Drivers":
        gapExtraCommission = settingsInfo?.nsdGap;
        break;
      default:
    }
    if (gapExtraCommission === undefined) {
      return "Extra GAP commission not found!";
    }
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          profit: {
            ...deal?.data?.info?.profit,
            wfdProfit: {
              ...deal?.data?.info?.profit?.wfdProfit,
              extraGAPProfit: gapExtraCommission || 0
            }
          }
        }
      }
    };
  };
  const setExtraWarrantyCommissions = (deal: Deal): Deal | string | false => {
    let warrantyExtraCommission = undefined;

    const settingsInfo = settings.data?.info;
    const insurances = deal?.data?.info?.aftermarketOptions?.insurances;

    const warranty = insurances?.find((el) => el.chosenRate.insuranceType === "service warranty");
    const amountFinanced = new Big(deal?.data?.info?.payment?.dealTotal || 0);
    const unitType = deal?.data?.info?.vehicle?.unitType ?? "";
    if (!warranty && !deal?.data?.info?.profit?.wfdProfit?.extraServiceWarrantyProfit) return false;
    if (!warranty && deal?.data?.info?.profit?.wfdProfit?.extraServiceWarrantyProfit)
      return {
        ...deal,
        data: {
          ...deal?.data,
          info: {
            ...deal?.data?.info,
            profit: {
              ...deal?.data?.info?.profit,
              wfdProfit: {
                ...deal?.data?.info?.profit?.wfdProfit,
                extraServiceWarrantyProfit: 0
              }
            }
          }
        }
      };
    switch (warranty?.provider) {
      case "Gold Standard Automotive Network":
        warrantyExtraCommission = settingsInfo?.goldStandartAutomotiveNetworkWarranty;
        break;
      case "ASC":
      case "ASC Warranty":
        warrantyExtraCommission = settingsInfo?.ascWarranty;
        break;
      case "GWC":
        warrantyExtraCommission = settingsInfo?.gwcWarranty;
        break;
      case "FPC":
        warrantyExtraCommission = settingsInfo?.fpcWarranty;
        break;
      case "XtraRide":
        warrantyExtraCommission = settingsInfo?.xtraRideWarranty;
        break;
      case "Compass":
        if (["Class A", "Class B", "Class C"].includes(unitType)) {
          warrantyExtraCommission = settingsInfo?.compasMotorhome;
        }
        if (["Travel Trailer", "5th Wheel", "Trl"].includes(unitType)) {
          if (amountFinanced.lte(new Big(20_000)))
            warrantyExtraCommission = settingsInfo?.compassTT5LessThan20k;
          else if (amountFinanced.gt(new Big(20_000)))
            warrantyExtraCommission = settingsInfo?.compassTT5MoreThan20k;
        }
        break;

      case "American Guardian Warranty Services":
        if (["Pop-up", "Tent Camper"].includes(unitType)) {
          if (amountFinanced.lte(new Big(20_000)))
            warrantyExtraCommission = settingsInfo?.campersPopSlideLessThan20k;
          else if (amountFinanced.gt(new Big(20_000)))
            warrantyExtraCommission = settingsInfo?.campersPopSlideMoreThan20k;
        }
        break;

      default:
    }
    if (warrantyExtraCommission === undefined) return "Extra warranty commission not found!";
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          profit: {
            ...deal?.data?.info?.profit,
            wfdProfit: {
              ...deal?.data?.info?.profit?.wfdProfit,
              extraServiceWarrantyProfit: warrantyExtraCommission || 0
            }
          }
        }
      }
    };
  };
  return (
    <Button
      variant="contained"
      style={{
        backgroundColor: "#000",
        position: "relative"
      }}
      fullWidth
      disableElevation
      onClick={() => {
        const newDeal = logging(deal)
          .map(setExtraWarrantyCommissions, "Extra warranty commissions are filled!")
          .map(setExtraGapCommissions, "Extra GAP commissions are filled!")
          .map(setSplit, "Reserve split is filled!")
          .map(setReserve, "Reserve is filled!")
          .log(
            (result) => {
              if (result.filter((x) => x).length > 0)
                enqueueSnackbar(
                  <>
                    {result
                      .filter((x) => x)
                      .map((message) => (
                        <>
                          {message}
                          <br />
                        </>
                      ))}
                  </>,
                  {
                    variant: "error"
                  }
                );
            },
            (result) => {
              if (result.filter((x) => x).length > 0)
                enqueueSnackbar(
                  <>
                    {result
                      .filter((x) => x)
                      .map((message) => (
                        <>
                          {message}
                          <br />
                        </>
                      ))}
                  </>,
                  {
                    variant: "success"
                  }
                );
            }
          );
        stateAccess.set([], newDeal);
      }}
    >
      <div style={{ color: "#fff" }}>Auto Fill</div>
    </Button>
  );
}
