import {
  Grid,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { Big } from "big.js";
import { DealStatus } from "components/Deals/types";
import {
  LenderLifetimeAllocationStatistic,
  LifetimeAllocationStatistics,
  UserAllocationStatistic,
  UserAllocationStatisticById
} from "hooks/useLenderAllocationStatistics/types";
import React from "react";
import { formatNumberAsCurrency, typedObjectEntries } from "utils/functions";
import styles from "../../LenderDashboard.module.css";

const LifetimeManagerTable = ({
  data
}: {
  data: {
    userStatistics: UserAllocationStatisticById;
    allocationStatistic: LenderLifetimeAllocationStatistic;
    lifetimeStatistics: LifetimeAllocationStatistics | undefined;
  }; // LenderAllocationStatistic | undefined;
}) => {
  const statistics2 = Object.values(data?.userStatistics?.statistics ?? {})
    .map((x) =>
      Object.entries(x).reduce(
        (acc: UserAllocationStatistic, [key, curr]) => ({
          id: curr.id,

          names: curr.names,
          totalAmountFinanced: new Big(acc.totalAmountFinanced)
            .add(curr.totalAmountFinanced)
            .toNumber(),
          approvedAllocated: key === "approved" ? curr.allocated : acc.approvedAllocated,
          allocated: new Big(acc.allocated).add(curr.allocated).toNumber(),
          totalAllocation: curr.totalAllocation || 0,
          dealCount: new Big(acc.dealCount).add(curr.dealCount).toNumber()
        }),
        {
          id: "",
          names: "",
          totalAmountFinanced: 0,
          approvedAllocated: 0,
          allocated: 0,
          totalAllocation: 0,
          dealCount: 0
        }
      )
    )
    .sort((a, b) => a.names.localeCompare(b.names));
  const totals = statistics2.reduce(
    (acc, x) => ({
      totalAveragedAmountFinanced: new Big(acc.totalAveragedAmountFinanced)
        .add(new Big(x.totalAmountFinanced).div(x.dealCount || 1).round(2, 1))
        .toNumber()
    }),
    { totalAveragedAmountFinanced: 0 }
  );
  return (
    <Paper className={styles.dashboardItem}>
      <Grid container spacing={3} style={{ padding: "15px 0px" }}>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "20px" }}>
            Allocated financed amount by manager
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table aria-label="able" style={{ overflow: "hidden" }}>
              <TableHead
                style={{ background: "#e8e8e8", whiteSpace: "nowrap", textAlign: "center" }}
              >
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Allocated (Without Approved)</TableCell>
                  <TableCell align="left">Allocated (With Approved)</TableCell>
                  <TableCell align="left">Average amount financed</TableCell>
                  <TableCell align="left">Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statistics2?.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ fontWeight: 500, fontSize: 19 }} align="left">
                        {row.names ?? ""}
                      </TableCell>
                      <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                        {formatNumberAsCurrency(
                          new Big(row.allocated || 0).sub(row.approvedAllocated || 0).toNumber(),
                          "$"
                        )}
                      </TableCell>
                      <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                        {formatNumberAsCurrency(row.allocated, "$")}
                      </TableCell>
                      <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                        {formatNumberAsCurrency(
                          new Big(row.totalAmountFinanced)
                            .div(row.dealCount || 1)
                            .round(2, 1)
                            .toNumber(),
                          "$"
                        )}
                      </TableCell>
                      <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                        {row.dealCount}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

              <TableFooter>
                <TableRow key={"totals"} style={{ background: "#E8E8E8", marginTop: "auto" }}>
                  <TableCell style={{ fontWeight: 500, fontSize: 19 }} align="left">
                    Total
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    {formatNumberAsCurrency(
                      new Big(
                        data.lifetimeStatistics?.totalAmountFinancedNoApproved || 0
                      ).toNumber(),
                      "$"
                    )}
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    {formatNumberAsCurrency(data.allocationStatistic?.totalAllocated, "$")}
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    {formatNumberAsCurrency(
                      new Big(totals.totalAveragedAmountFinanced)
                        // .div(new Big(data?.totalDeals || 1)) //commented line was prev to this one
                        .div(new Big(statistics2.length || 1))
                        .round(2, 1)
                        .toNumber(),
                      "$"
                    )}
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    {data.allocationStatistic?.totalDeals}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LifetimeManagerTable;
