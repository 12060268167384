import { createListSlice } from "../Middlewares/listSliceCreator";
import { PrintedDocument } from "./types";

const { ListSlice, getList } = createListSlice<PrintedDocument>({
  name: "PrintedDocumentList",
  request_topic: "all_printed_documents",
  reducers: {}
});

export default ListSlice.reducer;
export const getPrintedDocumentList = getList;
export const listPrintedDocumentActions = ListSlice.actions;
export const removePrintedDocumentList = ListSlice.actions.removeList;
