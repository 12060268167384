import { IconButton, Box } from "@material-ui/core";
import React from "react";
import { StateAccess, RenderSet, generateForm } from "utils/models/formGenerator";
import { Dealership } from "./types";
import { FormComponent } from "utils/models/fields";
import RedoIcon from "@material-ui/icons/Redo";
import { HintTooltip } from "components/common/HintTooltip";

export const shippingAddress: FormComponent<Dealership> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "text-field",
      name: "shippingAddress",
      label: "Shipping address",
      width: "full",
      path: ["data", "info", "shippingAddress"],
      default: null
    }
  ]
};

export default function ShippingAddress({
  stateAccess,
  renderSet
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) {
  const dealership = stateAccess.get<Dealership>([] as any);
  return (
    <>
      <Box display="flex">
        <Box style={{ padding: "5px 2px 5px 0px" }}>
          <IconButton
            size="small"
            onClick={() => {
              stateAccess.set<Dealership>(
                ["data", "info", "shippingAddress"],
                dealership.data?.info?.address
              );
            }}
          >
            <HintTooltip title="Same as the adress">
              <RedoIcon />
            </HintTooltip>
          </IconButton>
        </Box>

        <Box flexGrow={1}>
          {generateForm(shippingAddress, stateAccess, [], stateAccess, renderSet)}
        </Box>
      </Box>
    </>
  );
}
