import {
  Behaviors,
  CommonAttributes,
  CommonFields,
  Field,
  IncludedAttributes,
  Relationships
} from "../common";

export type CommonReportPayloadAttributes = {
  status: ReportStatus;
  report_template_version_name: string;
};

export type PersonaReportPayloadData<T> = {
  type: ReportType;
  id: string;
  attributes: T;
  relationships: Pick<Relationships, "inquiry" | "report_template" | "account">;
};

export type PhoneRiskReportPayloadAttributes = CommonReportPayloadAttributes & {
  phone_number: string;
  phone_type: string | null;
  phone_carrier: string | null;
  phone_risk_level: string | null;
  phone_risk_recommendation: string | null;
  phone_risk_score: number | null;
  phone_risk_sim_swap: string | null;
};
export type EmailRiskReportPayloadAttributes = CommonReportPayloadAttributes & {
  email_address: string;
  email_reputation: string;
  email_reference_count: number;
  email_domain_reputation: string;
  email_first_seen_days: number;
  email_last_seen_days: number;
  email_domain_age_days: number;
  email_credentials_leaked: boolean | null;
  email_credentials_leaked_recent: boolean | null;
  email_data_breached: boolean | null;
  email_malicious_activity_detected: boolean | null;
  email_malicious_activity_detected_recent: boolean | null;
  email_is_blocklisted: boolean | null;
  email_is_spam: boolean | null;
  email_is_free_provider: boolean | null;
  email_is_disposable: boolean | null;
  email_is_deliverable: boolean | null;
  email_is_valid_mx: boolean | null;
  email_is_spoofable: boolean | null;
  email_is_spf_strict: boolean | null;
  email_is_dmarc_enforced: boolean | null;
  email_is_suspicious: boolean | null;
  email_domain_exists: boolean | null;
  email_domain: string;
};

export type ReportIncluded = {
  type: string;
  id: string;
  attributes: IncludedAttributes<ReportFields>;
};

export type ReportFields = Pick<
  CommonFields,
  | "name"
  | "address"
  | "identification_numbers"
  | "birthdate"
  | "phone_number"
  | "email_address"
  | "selfie_photo"
>;

export enum ReportName {
  Email_Risk_Report_Ready = "report/email-address.ready",
  Email_Risk_Report_Errored = "report/email-address.errored",
  Phone_Risk_Report_Ready = "report/phone-number.ready",
  Phone_Risk_Report_Errored = "report/phone-number.errored"
}
export enum ReportType {
  Email_Address = "report/email-address",
  Phone_Number = "report/phone-number"
}

export enum ReportStatus {
  Pending = "pending",
  Ready = "ready",
  Errored = "errored"
}
