import React, { useState } from "react";
import AccessControl from "components/Access/AccessControl";
import IconButton from "@material-ui/core/IconButton";
import { DeleteSharp } from "@material-ui/icons";
import { EntityType } from "utils/entitySlice";
import ConfirmDeleteDialog from "components/common/ConfirmDeleteDialog";
import { AppDispatch } from "app/store";
import { v4 as uuidv4 } from "uuid";
import { HintTooltip } from "components/common/HintTooltip";
interface Request {
  requestId: string;
  _id: string;
}

export default ({
  entityName,
  _id,
  deleteEntityFunction,
  callback
}: {
  entityName: EntityType;
  _id: string;
  deleteEntityFunction?: (request: Request) => (dispatch: AppDispatch) => Promise<void>;
  callback: () => void;
}) => {
  const [dialogState, setDialogState] = useState<
    { showDialog: true; _id: string } | { showDialog: false }
  >({
    showDialog: false
  });

  const handleDeleteDialog = (_id: string) => {
    setDialogState({ ...dialogState, _id: _id, showDialog: true });
  };
  return (
    <AccessControl requiredPermissions={{ entity: entityName, action: "delete" }}>
      <>
        <IconButton
          size="small"
          color="secondary"
          aria-label="delete"
          id={`delete-${entityName}-${_id}`}
          onClick={() => handleDeleteDialog(_id)}
        >
          <HintTooltip title={`Click here to delete the ${entityName}.`}>
            <DeleteSharp />
          </HintTooltip>
        </IconButton>
        {dialogState.showDialog && deleteEntityFunction !== undefined && (
          <ConfirmDeleteDialog
            deleteEntry={(request) => {
              const requestId = uuidv4();

              return deleteEntityFunction({ requestId, ...request });
            }}
            callback={callback}
            setDialogState={setDialogState}
            _id={dialogState?._id}
          />
        )}
      </>
    </AccessControl>
  );
};
