import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FundingDocument, AddFundingDocumentRequest, AddFundingDocumentRequestSend } from "./types";

interface AddFundingDocumentResponse {
  message: FundingDocument;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddFundingDocumentRequestSend,
  AddFundingDocumentResponse
>("AddFundingDocumentResponse", {
  request: "new_funding_document"
});

export const addFundingDocumentActions = socketMessageSlice.actions;

export const addFundingDocument = (request: AddFundingDocumentRequest & Identifiable) => {
  const { deal, printedDocument, ...rest } = request.data;
  return socketAction({
    ...request,
    requestId: request.requestId,
    data: {
      ...rest,
      dealId: deal?._id ?? null,
      printedDocumentId: printedDocument?._id ?? null
    }
  });
};
export default socketMessageSlice.reducer;
