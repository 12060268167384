import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverOtherVendorRequest {
  _id: string;
}

export interface RecoverOtherVendorResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverOtherVendorRequest,
  RecoverOtherVendorResponse
>("RecoverOtherVendor", {
  request: "recover_other_vendor"
});

export const recoverOtherVendorActions = socketMessageSlice.actions;
export const recoverOtherVendor = socketAction;
export default socketMessageSlice.reducer;
