import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";

import { RootState } from "app/rootReducer";
import Spinner from "components/Loader/Spinner";
import { ShowForm } from "utils/models/ShowForm";
import { StateAccess } from "utils/models/formGenerator";
import { entityConfigs, getEntity } from "utils/entitySlice";
import CloseDialogButton from "components/common/CloseDialogButton";

import { Chargeback } from "../types";
import { initialChargebackDealState } from "../AddChargeback";

export default ({ stateAccess }: { stateAccess: StateAccess }) => {
  const chargeback = stateAccess.get<Chargeback>([]);
  const id = chargeback?.data?.dealId;

  const entity = useSelector((state: RootState) => state.entitySlice["deal"]?.[id]);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (id) dispatch(getEntity("deal", id));
  }, [dispatch, id]);

  const hanldeRemoveDeal = () => {
    stateAccess.set<Chargeback>(["data"], {
      ...chargeback?.data,
      dealership: null,
      manager: null,
      lender: null,
      applicant: null,
      dealId: null,
      deal: initialChargebackDealState
    } as Chargeback);
  };
  return (
    <Grid container xs={12}>
      <Grid item xs={8}>
        <Typography variant="h5">
          Selected deal:
          <span style={{ fontWeight: 700 }}>
            {` ${" "} ${entity?.data?.info?.refNumber ?? ""}-${
              entity?.data?.applicant?.data?.info?.firstName ?? ""
            }-${entity?.data?.applicant?.data?.info?.lastName ?? ""}-${
              entity?.data?.lender?.data?.info.name ?? ""
            }`}
          </span>
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          Preview Deal
        </Button>
      </Grid>

      <Grid item xs={2}>
        <Button variant="contained" color="secondary" onClick={() => hanldeRemoveDeal()}>
          Remove Deal
        </Button>
      </Grid>
      <Dialog
        id="confirm-delete-dialog"
        open={open}
        maxWidth="xl"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Deal Preview <CloseDialogButton closeFunction={handleClose} />
        </DialogTitle>
        <DialogContent>
          {entity ? (
            <>
              <ShowForm
                tabId="preview-deal"
                initialState={entity}
                hideEdit={true}
                model={entityConfigs["deal"].model}
              />
            </>
          ) : (
            <Spinner text={"Loading "} style={{ position: "relative" }} />
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
