import React, { useState } from "react";
import { Button } from "@material-ui/core/";
import CodeIcon from "@material-ui/icons/Code";
import WidgetDialog from "./WidgetDialog";
import { StateAccess } from "utils/models/formGenerator";
import { Dealership } from "components/Dealerships/types";

interface Props {
  stateAccess: StateAccess;
}

export default function WidgetButton({ stateAccess }: Props) {
  const [open, setOpen] = useState(false);
  const dealership: Dealership = stateAccess.get([]);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Button
        variant="contained"
        id="generate-widgets"
        color="primary"
        style={{ width: "100%" }}
        startIcon={<CodeIcon />}
        onClick={handleDialogOpen}
      >
        Generate widgets
      </Button>
      <WidgetDialog
        open={open}
        setOpen={setOpen}
        dealershipId={dealership._id}
        dealershipName={dealership.data.info.name}
      />
    </>
  );
}
