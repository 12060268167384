import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteLenderTicketRequest {
  _id: string;
}

export interface DeleteLenderTicketResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteLenderTicketRequest,
  DeleteLenderTicketResponse
>("DeleteLenderTicket", {
  request: "delete_lender_ticket"
});

export const deleteLenderTicketActions = socketMessageSlice.actions;
export const deleteLenderTicket = socketAction;
export default socketMessageSlice.reducer;
