import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, { useState } from "react";
import { formatNumberAsCurrency } from "utils/functions";
import ASCSurcharges from "./ASCSurcharges";
import { SurchargeName, surchargesTypes, Term } from "./types";

interface Props {
  handleChoseTerm: (
    term: Term,
    surchage: { name: typeof surchargesTypes[number]; value: string; checked: boolean }[]
  ) => void;
  rates: Term[];
  numberOfPayments: number;
}

const onChangeSurchargeFunction = (
  surcharges: {
    [key: number]: { name: typeof surchargesTypes[number]; value: string; checked: boolean }[];
  },
  index: number,
  setSurcharges: React.Dispatch<
    React.SetStateAction<{
      [key: number]: {
        name: typeof surchargesTypes[number];
        value: string;
        checked: boolean;
      }[];
    }>
  >,
  rate: Term
) => (surcharge: SurchargeName) => {
  const found = surcharges[index] ?? [];

  const surchargesToSet = found?.map((s) =>
    s.name === surcharge ? { ...s, checked: !s.checked } : s
  );

  setSurcharges((surcharges) => ({
    ...surcharges,
    [index]: surchargesToSet
  }));
};

const isSurchargeChecked = (
  surcharges: {
    [key: number]: { name: typeof surchargesTypes[number]; value: string; checked: boolean }[];
  },
  index: number
) => (surcharge: SurchargeName) =>
  surcharges[index]?.some((s) => s.name === surcharge && s.checked);

const rateToSurcharges = (rate: Term) => {
  return [...surchargesTypes].reduce(
    (acc: { name: SurchargeName; value: string }[], type) =>
      Object.keys(rate).includes(type) ? [...acc, { name: type, value: rate[type] ?? "" }] : acc,
    []
  );
};

const ASCRatesList = ({ handleChoseTerm, rates, numberOfPayments }: Props) => {
  const [showAll, setShowAll] = useState(false);
  const [surcharges, setSurcharges] = useState<{
    [key: number]: { name: typeof surchargesTypes[number]; value: string; checked: boolean }[];
  }>(
    rates.reduce((acc, rate, currentIndex) => {
      return {
        ...acc,
        [currentIndex]: rateToSurcharges(rate)
      };
    }, {})
  );

  return (
    <>
      <List
        key={`${rates[0].ProgramID}`}
        style={{
          borderRadius: "8px",
          backgroundColor: "#E8E8E8",
          marginTop: "10px",
          marginBottom: "5px",
          height: "auto"
        }}
      >
        <ListItem>
          <div style={{ width: "100%" }}>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography variant="h6" component="h6">
                  {`${rates[0].ProgramName} ${rates[0].PlanName}`}
                </Typography>
              </Box>
              <Box>
                <Button
                  id={`show-all-rates-button-${rates[0].ProgramName} ${rates[0].PlanName}`}
                  onClick={() => setShowAll((x) => !x)}
                >
                  {showAll ? "Hide all" : "Show all"}
                </Button>
              </Box>
            </Box>
            {rates?.map((rate, index) => {
              return numberOfPayments === parseInt(`${rate.Months}`) || showAll ? (
                <div
                  key={`rate-${rate.TermID}`}
                  style={{
                    display: "flex",
                    width: "auto",
                    marginTop: "10px",
                    borderRadius: "8px",
                    flexDirection: "column",
                    backgroundColor: "#F8F8F8"
                  }}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    <ListItemText
                      style={{ paddingTop: "8px", paddingLeft: "10px" }}
                      primary={`Months: ${rate.Months}  /  Miles: ${
                        rate.Miles
                      }  /  Plan cost: ${formatNumberAsCurrency(
                        parseFloat(rate.PlanCost)
                      )} / Total price: ${formatNumberAsCurrency(
                        parseFloat(rate.TotalCost)
                      )} / Total care coverage: ${formatNumberAsCurrency(
                        parseFloat(rate.TotalCareCoverage)
                      )} `}
                    />
                    <IconButton
                      aria-label="add"
                      id={`add-rate-${rate.TermID}`}
                      onClick={() => handleChoseTerm(rate, surcharges[index])}
                      style={{ alignSelf: "flex-end" }}
                    >
                      <AddCircleIcon style={{ color: "#50A538" }} />
                    </IconButton>
                  </div>
                  <ASCSurcharges
                    onChangeSurchargeFunction={onChangeSurchargeFunction(
                      surcharges,
                      index,
                      setSurcharges,
                      rate
                    )}
                    isSurchargeChecked={isSurchargeChecked(surcharges, index)}
                    rateSurcharges={rateToSurcharges(rate)}
                  />
                </div>
              ) : null;
            })}
          </div>
        </ListItem>
      </List>
    </>
  );
};

export default ASCRatesList;
