import DateFnsUtils from "@date-io/date-fns";

import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import TextLoop from "components/Loader/TextLoop";
import { thirdPartyDocumentsUsageRequest } from "hooks/useTitleDocumentsUsage/types";
import useThirdPartyDocumentsUsage from "hooks/useTitleDocumentsUsage/useTitleDocumentsUsage";
import React from "react";
import { isValidDate } from "utils/models/formRenderers";
export default () => {
  const [requestState, setRequestState] = React.useState<thirdPartyDocumentsUsageRequest>({});
  const [enabled, setEnabled] = React.useState(false);

  const { data, isLoading, isFetched, isFetching } = useThirdPartyDocumentsUsage(
    requestState,
    enabled
  );

  React.useEffect(() => {
    setEnabled(false);
  }, [isFetched]);

  return (
    <Grid container xs={12} spacing={2} style={{ padding: "10px" }}>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: "bold", fontSize: "24px", color: "#254e70" }}>
          Third Party Documents Usage Report
        </Typography>
      </Grid>
      <Paper elevation={2} style={{ width: "100%", padding: "20px", marginBottom: "10px" }}>
        <Grid item container xs={12} spacing={2} style={{ alignItems: "center" }}>
          <Grid item xs={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id={"ThirdPartyDocumentsUsageFrom"}
                name={"ThirdPartyDocumentsUsageFrom"}
                autoOk
                fullWidth
                minDate={undefined}
                InputLabelProps={{ shrink: true }}
                inputVariant="filled"
                variant="inline"
                label={"From"}
                required={true}
                format="MM/dd/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                size="small"
                value={requestState?.from ?? null}
                onChange={(value) => {
                  if (isValidDate(value) && value) {
                    value.setHours(0);
                    value.setMinutes(0);
                    value.setMilliseconds(0);
                    setRequestState((prev) => ({ ...prev, from: value?.toISOString() }));
                  } else {
                    setRequestState((prev) => ({ ...prev, from: value?.toString() }));
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id={"ThirdPartyDocumentsUsageTo"}
                name={"ThirdPartyDocumentsUsageTo"}
                autoOk
                fullWidth
                minDate={undefined}
                InputLabelProps={{ shrink: true }}
                inputVariant="filled"
                variant="inline"
                label={"To"}
                required={true}
                format="MM/dd/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                size="small"
                value={requestState?.to ?? null}
                onChange={(value) => {
                  if (isValidDate(value) && value) {
                    value.setHours(0);
                    value.setMinutes(0);
                    value.setMilliseconds(0);
                    setRequestState((prev) => ({ ...prev, to: value?.toISOString() }));
                  } else {
                    setRequestState((prev) => ({ ...prev, to: value?.toString() }));
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              disabled={
                Object.keys(requestState)?.length !== 2 ||
                !(
                  Object.keys(requestState)?.length === 2 &&
                  Object.values(requestState).every((x) => isValidDate(x))
                )
              }
              onClick={() => setEnabled(true)}
              color="primary"
            >
              Show Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={2} style={{ width: "100%", padding: "10px" }}>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold", fontSize: "16px", color: "#254e70" }}>
              Third Party Documents Usage
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Document</TableCell>
                  <TableCell>Number of times used</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(data?.length || 0) > 1 ? (
                  (data ?? []).map((record, index) => (
                    <TableRow key={index}>
                      <TableCell>{record.document}</TableCell>
                      <TableCell>{record.count}</TableCell>
                    </TableRow>
                  ))
                ) : (data?.length as number) === 0 ? (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
                        No data
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : isLoading && isFetching ? (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={2}>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          position: "relative"
                        }}
                      >
                        <TextLoop style={{ fontSize: "20px" }} />
                      </div>
                    </td>
                  </tr>
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {Object.values(requestState)?.filter((x) => x)?.length === 2
                          ? "Use the button to show the chosen report"
                          : "Fill the from/to range"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
