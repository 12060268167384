import { createListSlice } from "../Middlewares/listSliceCreator";
import { Dealership } from "./types";

const { ListSlice, getList } = createListSlice<Dealership>({
  name: "DealershipList",
  request_topic: "all_dealerships",
  reducers: {}
});

export default ListSlice.reducer;
export const getDealershipList = getList;
export const listDealershipActions = ListSlice.actions;
export const removeDealershipList = ListSlice.actions.removeList;
export const clearDealershipList = ListSlice.actions.clearList;
