import { Grid, useMediaQuery } from "@material-ui/core";
import { Lender } from "components/Lenders/types";
import Spinner from "components/Loader/Spinner";
import {
  LenderAllocationStatisticRequest,
  ManyAllocationPeriodsStatistics,
  PeriodAllocationStatistics
} from "hooks/useLenderAllocationStatistics/types";
import useLenderAllocationYearlyStatistics from "hooks/useLenderAllocationStatistics/useLenderAllocationYearlyStatistics";
import React, { useEffect } from "react";
import AllocationDealsModal from "../AllocationDealsModal";
import { ToggleState } from "../LenderAllocationDashboard";
import LenderChartByStatus from "../LenderChartByStatus";
import YearlyAllocationInformation from "./YearlyAllocationInformation";
import YearlyManagerTable from "./YearlyManagerTable";

function findLatestPeriod(
  periodStatisticss: ManyAllocationPeriodsStatistics
): PeriodAllocationStatistics {
  const today = new Date();

  const dateDiff = (date1: Date, date2: Date) => Math.abs(date1.getTime() - date2.getTime());

  periodStatisticss.statistics
    .filter((period) => {
      const fromDate = new Date(
        period.allocationPeriod.from.year,
        period.allocationPeriod.from.month - 1,
        1
      );
      const toDate = period.allocationPeriod.to
        ? new Date(period.allocationPeriod.to.year, period.allocationPeriod.to.month - 1, 31)
        : new Date(2030);

      return today.getTime() >= fromDate.getTime() && today.getTime() <= toDate.getTime();
    })
    .sort((a, b) => {
      const toDateA = a.allocationPeriod.to
        ? new Date(a.allocationPeriod.to.year, a.allocationPeriod.to.month - 1)
        : new Date();
      const toDateB = b.allocationPeriod.to
        ? new Date(b.allocationPeriod.to.year, b.allocationPeriod.to.month - 1)
        : new Date();

      const todayIsEarlierThanToA = today < toDateA;
      const todayIsEarlierThanToB = today < toDateB;

      if (todayIsEarlierThanToA && todayIsEarlierThanToB) {
        const fromDateA = new Date(a.allocationPeriod.from.year, a.allocationPeriod.from.month - 1);
        const fromDateB = new Date(b.allocationPeriod.from.year, b.allocationPeriod.from.month - 1);
        return dateDiff(fromDateA, today) - dateDiff(fromDateB, today);
      } else if (todayIsEarlierThanToA) {
        return -1;
      } else if (todayIsEarlierThanToB) {
        return 1;
      } else {
        return dateDiff(toDateA, today) - dateDiff(toDateB, today);
      }
    });
  return periodStatisticss.statistics[0];
}
const YearlyDashboard = ({
  lender,
  state,
  periodToggle,
  setViewDealsButton
}: {
  lender: Lender | undefined;
  state: LenderAllocationStatisticRequest;
  periodToggle: ToggleState;
  setViewDealsButton: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
}) => {
  const matches = useMediaQuery("(min-width:800px)");
  const { data, isLoading } = useLenderAllocationYearlyStatistics({
    ...state,
    allocationType: periodToggle,
    lenderId: lender?._id ?? ""
  });
  useEffect(() => {
    if (data && data.data) {
      setViewDealsButton(
        <AllocationDealsModal dealIds={data?.data?.periodStatistics.dealIds} lender={lender} />
      );
    } else {
      setViewDealsButton(null);
    }
  }, [data, lender, setViewDealsButton]);
  return (
    <>
      <Grid container xs={12} spacing={0} style={{ margin: "10px 0px" }}>
        {isLoading ? (
          <Spinner
            text={`Loading: ${lender?.data?.info?.name}`}
            style={{ position: "relative", zIndex: 5 }}
          />
        ) : (
          data &&
          data.data && (
            <>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12}>
                    <LenderChartByStatus
                      selectedStatuses={state.statuses}
                      currencyFormat={true}
                      data={findLatestPeriod(data.data.periodStatistics)?.amountFinancedByStatus}
                      chartType="bar"
                      title="Amount financed by status"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <LenderChartByStatus
                      selectedStatuses={state.statuses}
                      unallocated={true}
                      data={findLatestPeriod(data.data.periodStatistics)?.allocatedAmount}
                      chartType="pie"
                      title="Allocated amount"
                      currencyFormat={true}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <LenderChartByStatus
                      selectedStatuses={state.statuses}
                      data={findLatestPeriod(data.data.periodStatistics)?.dealCountByStatus}
                      chartType="bar"
                      title={"Deal count by status"}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    style={{
                      maxWidth: matches ? "auto" : "calc(100vw - 60px)",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: " flex-start"
                    }}
                  >
                    <YearlyManagerTable
                      selectedStatuses={state.statuses}
                      data={findLatestPeriod(data.data.periodStatistics)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start"
                    }}
                  >
                    <YearlyAllocationInformation
                      toggleState={periodToggle}
                      state={state}
                      overviewStatistics={data.data.periodStatistics?.overview}
                      data={data.data?.periodStatistics?.statistics}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )
        )}
      </Grid>
    </>
  );
};

export default YearlyDashboard;
