import { Box, createStyles, List, ListSubheader, makeStyles } from "@material-ui/core";
import { AssignmentTurnedIn } from "@material-ui/icons";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { Applicant } from "components/Applicants/types";
import { Dealership } from "components/Dealerships/types";
import { Deal } from "components/Deals/types";
import { Lender } from "components/Lenders/types";
import React from "react";
import { ReactComponent as LendIcon } from "../../icons/lenders.svg";
import ResultsTable from "./ResultsTable";
import { GlobalSearchEntityType, ResultsTableProps } from "./types";

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      marginTop: "10px",
      marginLeft: "10px",
      fontSize: "30px",
      fill: "#505050"
    },
    listItemWrapper: {
      display: "flex",
      backgroundColor: "#E3E3E3"
    },
    list: {
      maxHeight: "calc(100vh - 100px)",
      overflow: "auto",
      paddingTop: "0px",
      paddingBottom: "0px",
      borderRadius: "5px"
    }
  })
);

export default function ResultsList({
  applicants,
  deals,
  lenders,
  dealerships
}: ResultsTableProps) {
  const classes = useStyles();
  const getEntityIcon = (entityType: GlobalSearchEntityType) => {
    switch (entityType) {
      case "deal":
        return <AssignmentTurnedIn className={classes.icon} />;
      case "lender":
        return <LendIcon className={classes.icon} />;
      case "applicant":
        return <PeopleAltIcon className={classes.icon} />;
      case "dealership":
        return <DriveEtaIcon className={classes.icon} />;
    }
  };
  const renderListItem = (
    entities: Deal[] | Lender[] | Applicant[] | Dealership[] | undefined | null,
    entityType: GlobalSearchEntityType
  ) => {
    return (
      <>
        <Box className={classes.listItemWrapper}>
          {getEntityIcon(entityType)}
          <ListSubheader>
            {entityType.charAt(0).toUpperCase() + entityType.substr(1) + "s"}
          </ListSubheader>
        </Box>
        <ResultsTable entities={entities} entityType={entityType} />
      </>
    );
  };

  return (
    <List className={classes.list}>
      {renderListItem(deals.data, "deal")}
      {renderListItem(dealerships.data, "dealership")}
      {renderListItem(lenders.data, "lender")}
      {renderListItem(applicants.data, "applicant")}
    </List>
  );
}
