import { Button } from "@material-ui/core";
import TextLoop from "components/Loader/TextLoop";
import { ListState, Normalized } from "components/Middlewares/listSliceCreator";
import { PDFDocumentProxy } from "pdfjs-dist";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ReactToPrint from "react-to-print";
import { CrudEntity } from "utils/types";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Entry<T> = CrudEntity & {
  data: T;
} & Normalized;

export default <T extends unknown>({
  listEntity
}: {
  listEntity: ListState<Entry<T>>;
}): JSX.Element => {
  const [numPages, setNumPages] = useState<{ [key: string]: number }>({});
  const [renderedPagesCount, setRenderedPagesCount] = useState<number>(0);
  const printButton = useRef<null | HTMLButtonElement>(null);
  const ref = useRef<null | HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy, file: string): void => {
    setNumPages((x) => {
      return { ...x, [file]: pdf.numPages };
    });
  };

  useEffect(() => {
    if (
      renderedPagesCount !== 0 &&
      Object.values(numPages).reduce(function (a, b) {
        return a + b;
      }, 0) === renderedPagesCount
    ) {
      setIsLoading(false);
      const current = printButton.current;
      if (current !== null) {
        current.click();
      }
    }
  }, [numPages, renderedPagesCount, printButton]);

  return (
    <>
      <style>
        {`@media print {.printZone{display: block !important; overflow: visible !important; height: auto !important;}}`}
      </style>
      <ReactToPrint
        trigger={(): JSX.Element => {
          return (
            <Button type="button" ref={printButton} style={{ display: "none" }}>
              Print this out!
            </Button>
          );
        }}
        content={(): HTMLDivElement | null => ref.current}
      />
      {isLoading ? (
        <TextLoop
          text=""
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            textAlign: "center",
            background: "rgba(0,0,0,0.2)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        />
      ) : null}
      <div
        className="printZone"
        ref={ref}
        style={{
          display: "none",
          backgroundSize: "cover",
          position: "relative",
          height: `500px`,
          overflowY: "scroll",
          overflowX: "hidden",
          width: "21cm",
          margin: "auto"
        }}
      >
        {listEntity.entities !== undefined
          ? listEntity.entities.map((file: any, index: number) => {
              return (
                <div key={index}>
                  <Document
                    file={file.data.info.url}
                    onLoadSuccess={(pdf: PDFDocumentProxy): void => {
                      onDocumentLoadSuccess(pdf, file._id);
                    }}
                  >
                    {Array.from(new Array(numPages[file._id]), (el, index) => (
                      <div
                        key={index}
                        style={{
                          width: "21cm",
                          pageBreakAfter: "always",
                          overflow: "hidden",
                          margin: 0,
                          padding: 0
                        }}
                      >
                        <Page
                          className="full-page"
                          renderTextLayer={false}
                          renderAnnotationLayer={false}
                          width={1500}
                          height={1027}
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          onRenderSuccess={(): void => {
                            setRenderedPagesCount((count) => count + 1);
                          }}
                        />
                      </div>
                    ))}
                  </Document>
                </div>
              );
            })
          : null}
      </div>
    </>
  );
};
