import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import { StateAccess } from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";

export default function ApprovedFilter<T extends unknown>({ setFilters, path }: any) {
  const [approved, setApproved] = useState(false);
  const stateAccess: StateAccess = {
    get: (path) => approved,
    set: (_, value): any => {
      setApproved(value);

      setFilters((filters: any) => {
        if (value) return { ...filters, [path.join(".")]: null };
        else {
          const { [path.join(".")]: _, ...Rest } = filters;
          return Rest;
        }
      });
    }
  };
  return (
    <Grid style={{ paddingLeft: "20px" }} container spacing={1}>
      <Grid item xs={12}>
        {editRenderSet(false).checkboxInputRenderer(
          {
            formComponent: "checkbox-field",
            name: "Unapproved",
            label: "Show only unapproved records",
            required: false,
            path: [] as [],
            isDisabled: () => false,
            isChecked: () => approved,
            toggle: (s) => stateAccess.set([], !approved),
            default: false
          },
          stateAccess,
          stateAccess,
          editRenderSet(false)
        )}
      </Grid>
    </Grid>
  );
}
