import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { RootState } from "app/rootReducer";
import CloseDialogButton from "components/common/CloseDialogButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormComponent } from "utils/models/fields";
import { generateForm, getByPath, setByPath, StateAccess } from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";
import { v4 as uuidv4 } from "uuid";
import { Column } from "..";
import { addTableSettings } from "./addTableSettingsSlice";
import { editTableSettings } from "./editTableSettingsSlice";
import { TableSettings } from "./types";

type TableSettingsProps<T> = {
  columns: Column<T>[];
  tableName: string;
};
const struct = <T extends unknown>(columns: Column<T>[]): FormComponent<TableSettings> => {
  return {
    formComponent: "segment",
    name: "Columns",
    entities: [
      ...(columns.map((column) => {
        return {
          formComponent: "checkbox-field",
          name: column.name,
          style: { fontWeight: "bold" },
          label: column.label,
          isChecked: (stateAccess: StateAccess) =>
            stateAccess.get<TableSettings>(["data", "columns", column.name, "show"]),
          width: "full",
          toggle: (stateAccess, checked) => {
            stateAccess.set(["data", "columns", column.name, "show"], checked);
          },
          path: ["data", "columns", column.name, "show"],
          default: false
        } as FormComponent<TableSettings>;
      }) as FormComponent<TableSettings>[])
    ]
  };
};
export function initializeColumnsSettingsData<T extends unknown>(columns: Column<T>[]) {
  return columns.reduce((acc, column) => {
    return {
      ...acc,
      data: {
        ...acc?.data,
        columns: {
          ...acc?.data?.columns,
          [column.name]: {
            show: true
          }
        }
      }
    };
  }, {} as TableSettings);
}
export default <T extends unknown>({ columns, tableName }: TableSettingsProps<T>): JSX.Element => {
  const dispatch = useDispatch();
  const listId = "table";
  const currentUser = useSelector((state: RootState) => state.authSlice.user);
  const allTableSettings = useSelector((state: RootState) => state.listTableSettingsSlice[listId])
    ?.entities;
  const tableSettings = allTableSettings?.find((setting) => setting?.data?.tableName === tableName);

  const initialState = tableSettings ?? initializeColumnsSettingsData<T>(columns);

  const [requestId] = useState(uuidv4());
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    if (tableSettings) setFormState(tableSettings);
  }, [tableSettings]);

  const handleSubmit = () => {
    const formStateToSend = {
      ...formState,
      data: {
        ...formState.data,
        tableName,
        userId: currentUser?.databaseData?._id
      }
    };
    if (!tableSettings) {
      dispatch(addTableSettings({ ...(formStateToSend as any), requestId }));
    } else {
      dispatch(editTableSettings({ ...(formStateToSend as any), requestId }));
    }
    setOpen(false);
  };

  const stateAccess: StateAccess = {
    get: (path) => getByPath(formState as any, path),
    set: (path, value): any => {
      setFormState((previousFormState: any) => setByPath(previousFormState as any, path, value));
    }
  };
  return (
    <>
      <IconButton onClick={() => setOpen(!open)}>
        <Settings style={{ color: tableName === "deals_log" ? "fff" : "#0000008a" }} />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Table Settings</DialogTitle>
        <CloseDialogButton closeFunction={() => setOpen(false)} />
        <DialogContent>
          {generateForm(struct(columns), stateAccess, [], stateAccess, editRenderSet(false))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(!open)} variant="contained" color="primary" id="cancel">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            autoFocus
            onClick={handleSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
