import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { DealershipTicketNote } from "./types";

interface EditDealershipTicketNoteResponse {
  message: DealershipTicketNote;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DealershipTicketNote,
  EditDealershipTicketNoteResponse
>("EditDealershipTicketNote", {
  request: "update_dealership_ticket_note"
});

export const editDealershipTicketNote = socketAction;
export const editDealershipTicketNoteActions = socketMessageSlice.actions;
export const editDealershipTicketNoteError = socketMessageSlice.actions.error;

export default socketMessageSlice.reducer;
