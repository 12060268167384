import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { MoreHoriz } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { RootState } from "app/rootReducer";
import formEditContext from "components/Content/FormEditContext";
import {
  getStyleByStatus,
  projections,
  statusToChipTooltip,
  statusToLabel
} from "components/Deals/Deals";
import { moveDate } from "components/Deals/DealsLog/DealsLog";
import { getDealList } from "components/Deals/listDealSlice";
import { DealData, DealStatus } from "components/Deals/types";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DateFilter from "components/Filters/DateFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import PriceFilter from "components/Filters/PriceFilter";
import TextFilter from "components/Filters/TextFilter";
import UserFilter from "components/Filters/UserFilter";
import { TabContext } from "components/Layout/LayoutWrapper";
import Table, { CellValue, Column } from "components/Table";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { FormComponent } from "utils/models/fields";
import { generateForm, RenderSet, StateAccess } from "utils/models/formGenerator";
import CloseDialogButton from "../common/CloseDialogButton";
import { LenderDescision } from "./types";

const dealRefField: FormComponent<LenderDescision> = {
  formComponent: "read-only-field",
  name: "dealRef",
  label: "Deal ref",
  width: "1/3",
  path: ["data", "refNumber"],
  default: null
};
export default function ({
  stateAccess,
  renderSet
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) {
  const decision = stateAccess.get<LenderDescision>([] as any);
  const decisionId = decision?._id;
  const dealRef = decision?.data?.refNumber;
  const slice = `lender-decision-deals-${decisionId}`;
  const dealList = useSelector((state: RootState) => state.listDealSlice[slice]);
  const createOrFocusTab = useContext(TabContext);
  const { enabled: editMode } = useContext(formEditContext);

  const [date] = useState(moveDate(new Date(), -31));
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const columns: Column<DealData>[] = [
    {
      getData: (entry): CellValue => entry.data?.info?.refNumber,
      label: "Ref #",
      options: {
        sort: true,
        path: ["data", "info", "refNumber"]
      },
      name: "refNumber",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.refNumber?.show;
      },
      filters: [
        {
          path: ["data", "info", "refNumber"],
          preview: TextFilter,
          name: "refNumber",
          label: "Ref Number"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
          entry.data?.applicant?.data?.info?.lastName ?? ""
        }`,
      label: "Customer",
      name: "customer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.customer?.show;
      },
      filters: [
        {
          path: [
            ["data", "applicant", "data", "info", "firstName"],
            ["data", "applicant", "data", "info", "lastName"]
          ],
          preview: ApplicantFilter,
          caseInsensitive: true,
          name: "customer",
          label: "Customer"
        }
      ]
    },

    {
      getData: (entry): CellValue => entry.data?.dealership?.data?.info?.name ?? "",
      label: "Dealership",
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      },
      filters: [
        {
          path: ["data", "dealershipId"],
          preview: DealershipFilter,
          name: "dealership",
          label: "Dealership"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
      label: "Make",
      name: "make",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.make?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "make"],
          preview: TextFilter,
          partialSearch: true,
          caseInsensitive: true,
          name: "make",
          label: "Make"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
      label: "Model",
      name: "model",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.model?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "model"],
          preview: TextFilter,
          partialSearch: true,
          caseInsensitive: true,
          name: "model",
          label: "Model"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
      label: "Lender",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      },
      filters: [
        {
          path: ["data", "lenderId"],
          preview: LenderFilter,
          name: "lender",
          label: "Lender"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.user?.data?.info?.firstName ?? ""} ${
          entry.data?.user?.data?.info?.lastName ?? ""
        }`,
      label: "F&I mgr",
      name: "f&iManager",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.["f&iManager"]?.show;
      },
      filters: [
        {
          path: ["data", "userId"],
          preview: UserFilter,
          type: "f&i manager",
          name: "f&i mgr",
          label: "F&I mgr"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.dealership?.data?.representative?.data?.info?.firstName ?? ""} ${
          entry.data?.dealership?.data?.representative?.data?.info?.lastName ?? ""
        }`,
      label: "Rep",
      name: "rep",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.rep?.show;
      },
      filters: [
        {
          path: ["data", "dealership", "data", "representativeId"],
          preview: UserFilter,
          type: "representative",
          name: "rep",
          label: "Rep"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.status,
      label: "Status",
      options: {
        customBodyRender: (x: CellValue, deal): JSX.Element | string => {
          const dealStatus = x as DealStatus;
          return statusToChipTooltip(dealStatus, deal, getStyleByStatus(dealStatus));
        },
        sort: true,
        path: ["data", "info", "status"]
      },
      name: "status",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.status?.show;
      },
      filters: [
        {
          path: ["data", "info", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: Object.values(DealStatus),
          optionLabelForSelect: (status) => statusToLabel(status)
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.payment?.fundedAmount ?? 0,
      label: "Funded",
      name: "fundedAmount",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.fundedAmount?.show;
      },
      filters: [
        {
          name: "fundedAmount",
          label: "Funded Amount",
          path: ["data", "info", "payment", "fundedAmount"],
          preview: PriceFilter
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.dealDates?.fundedAt
          ? new Date(entry.data?.info?.dealDates?.fundedAt)
          : "",
      label: "Funded At",
      options: {
        sort: true,
        path: ["data", "info", "dates", "fundedAt"]
      },
      name: "fundedAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.fundedAt?.show;
      },
      filters: [
        {
          path: ["data", "info", "dealDates", "fundedAt"],
          preview: DateFilter,
          label: "Funded At",
          name: "fundedAt"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry?._id,
      label: "Actions",
      options: {
        customBodyRender: (_x: CellValue, deal): JSX.Element | string => (
          <div style={{ whiteSpace: "nowrap", textAlign: "right" }}>
            <IconButton
              color="primary"
              size="small"
              id={`add-${deal?._id}`}
              onClick={() => {
                setOpen(false);
                const data = stateAccess.get(["data"]);
                stateAccess.set<LenderDescision>(["data"], {
                  ...data,
                  refNumber: deal?.data?.info?.refNumber,
                  dealId: deal?._id
                });
              }}
            >
              <Tooltip title={"Select deal"}>
                <AddIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              aria-label="show more"
              id={`showMore-${deal?._id}`}
              onClick={() =>
                createOrFocusTab({
                  label: "Show page",
                  index: "showPage",
                  isForSidebar: false,
                  isForQuickAccess: false,
                  isPersistent: false,
                  props: {
                    _id: deal?._id,
                    type: "deal"
                  }
                })
              }
            >
              <Tooltip title={"Show deal"}>
                <MoreHoriz />
              </Tooltip>
            </IconButton>
          </div>
        )
      },
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={editMode ? 11 : 12}>
          {generateForm(dealRefField, stateAccess, [], stateAccess, renderSet)}
        </Grid>
        {editMode && (
          <Grid item xs={1}>
            <IconButton onClick={handleOpen} color="primary" id="add-deal-button">
              <Tooltip title={dealRef ? "Edit deal " : "Add deal"}>
                {dealRef ? <BorderColorIcon /> : <AddIcon />}
              </Tooltip>
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Dialog fullWidth onClose={handleClose} open={open} maxWidth="xl">
        <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
          <Grid item xs={6} key="title">
            <DialogTitle>
              Deals
              <CloseDialogButton closeFunction={handleClose} />
            </DialogTitle>
          </Grid>
        </Grid>
        <DialogContent>
          <Table
            tableName="lender_decisions_deal_preview"
            entityName="deal"
            listFunction={getDealList}
            listEntity={dealList}
            slice={slice}
            sort={{ createdAt: "desc" }}
            title={"Deals"}
            query={{ createdAt: { $gte: date } }}
            columns={columns}
            projection={projections}
            hideDeleteButton={true}
            hideAddButton={true}
            hideRecoverButton={true}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
