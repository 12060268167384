import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Typography
} from "@material-ui/core";
import { Deal } from "components/Deals/types";
import CloseDialogButton from "components/common/CloseDialogButton";
import React from "react";

type Props = {
  submitFunction: (forceCheck: boolean, statusChange: boolean) => void;
  closeFunction: () => void;
  deal: Deal;
  statusChange: boolean;
};

const FraudVerificationsDialog = ({ closeFunction, submitFunction, statusChange, deal }: Props) => {
  return (
    <Dialog id="fraud-validations-credit-score-dialog" open={true} onClose={closeFunction}>
      <DialogTitle id="fraud-validations-credit-score-dialog-title">
        <CloseDialogButton closeFunction={closeFunction} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          There are no performed applicant fraud verifications, have not been resolved yet, or are
          invalid, do you still want to perform a credit score lookup?
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button
          onClick={(_) => {
            if (deal.data.info.status === "lead") {
              submitFunction(statusChange, true);
            } else {
              submitFunction(statusChange, false);
            }
            closeFunction();
          }}
          variant="contained"
          autoFocus
          color="primary"
          id="confirm"
        >
          Yes, perform credit score lookup
        </Button>
        <Button onClick={closeFunction} variant="contained" color="primary" id="cancel">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FraudVerificationsDialog;
