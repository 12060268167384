import ApiStatusSchemas from "./api_status/api_status";
import ApplicantSchemas from "./applicants/applicants";
import apponeApiSchemas from "./appone_api/appone_api";
import AscApiSchemas from "./asc_api/asc_api";
import BusinessReportSchemas from "./business_report/business_report";
import ContractsSchemas from "./contracts/contracts";
import ContractTypesSchemas from "./contract_types/contract_types";
import CreditScoreSchemas from "./credit_score/credit_score";
import CreditSmartsSchemas from "./credit_smarts/credit_smarts";
import CustomReportSchemas from "./custom_report/custom_report";
import DealershipSchemas from "./dealerships/dealerships";
import dealertrackApiSchemas from "./dealertrack_api/dealertrack_api";
import DealSchemas from "./deals/deals";
import DeliveredDealsSchemas from "./delivered_deals/delivered_deals";
import DmvSchemas from "./dmvs/dmvs";
import DocumentSchemas from "./documents/documents";
import DocumentValidationSchemas from "./documents_validations/documents_validations";
import EmailSendSchemas from "./email_send/email_send";
import expressApiSchemas from "./express_api/express_api";
import ExternalCreditApplicationSchemas from "./external_credit_applications/external_credit_applications";
import FileSchemas from "./files/files";
import FloorplanxpressSchemas from "./floorplanxpress/floorplanxpress";
import FundingDocumentSchemas from "./funding_documents/funding_documents";
import GapCompanySchemas from "./gap_companies/gap_companies";
import HistoriesSchemas from "./histories/histories";
import IncomeReportSchemas from "./income_report/income_report";
import InsuranceCompanySchemas from "./insurance_companies/insurance_companies";
import LenderSchemas from "./lenders/lenders";
import LenderDecisionSchemas from "./lender_decision/lender_decisions";
import LockSchemas from "./lock/lock";
import NetsuitSchemas from "./netsuite/netsuite";
import NotificationSchemas from "./notifications/notifications";
import OtherVendorsSchemas from "./other_vendors/other_vendors";
import PayoffBankSchemas from "./payoff_banks/payoff_banks";
import ProtectiveServicesSchemas from "./protective_services/protective_services";
import RoleSchemas from "./roles/roles";
import SequenceSchemas from "./sequence/sequence";
import SettingsSchemas from "./settings/settings";
import StateSchemas from "./states/states";
import TableSettingsSchema from "./table_settings/table_settings";
import TitleIssueSchemas from "./title_issues/title_issues";
import UserSchemas from "./users/users";
import VehicleInsuranceCompanySchemas from "./vehicle_insurance_companies/vehicle_insurance_companies";
import VinCheckSchemas from "./vin_check/vin_check";
import ZipCodeLookupSchemas from "./zip_code_lookup/zip_code_lookup";
import DealershipProgramsSchemas from "./dealership_programs/dealership_programs";
import DealershipBillsReportSchemas from "./dealership_bills_report/dealership_bills_report";
import AdditionalRequiredDocumentsSchemas from "./additional_required_documents/additional_required_document";
import PrintedDocumentsSchemas from "./printed_documents/printed_documents";
import OneSpanSigningsSchemas from "./one_span_signings/one_span_signings";
import StipulationSchemas from "./stipulations/stipulations";
import ChecklistSchemas from "./checklists/checklists";
import NotesSchemas from "./notes/notes";
import DealerNotesSchemas from "./dealer_notes/dealer_notes";
import ExternalCreditApplicationDeal from "./external_credit_application_deal/external_credit_application_deal";
import TitleIssueNotesSchema from "./title_issue_notes/title_issue_notes";
import ColumnGroupsSchemas from "./column_groups/column_groups";
import DealershipTicketsSchemas from "./dealership_tickets/dealership_tickets";
import DealershipTicketNotesSchemas from "./dealership_ticket_notes/dealership_ticket_notes";

export const allSchemas = {
  ...DealershipTicketsSchemas,
  ...DealershipTicketNotesSchemas,
  ...ColumnGroupsSchemas,
  ...ExternalCreditApplicationDeal,
  ...DealerNotesSchemas,
  ...NotesSchemas,
  ...TitleIssueNotesSchema,
  ...ChecklistSchemas,
  ...StipulationSchemas,
  ...OneSpanSigningsSchemas,
  ...DealershipBillsReportSchemas,
  ...DealershipProgramsSchemas,
  ...NetsuitSchemas,
  ...OtherVendorsSchemas,
  ...ApiStatusSchemas,
  ...DeliveredDealsSchemas,
  ...DealershipSchemas,
  ...UserSchemas,
  ...RoleSchemas,
  ...LenderSchemas,
  ...ApplicantSchemas,
  ...DealSchemas,
  ...DmvSchemas,
  ...TitleIssueSchemas,
  ...CreditScoreSchemas,
  ...VinCheckSchemas,
  ...DocumentSchemas,
  ...GapCompanySchemas,
  ...InsuranceCompanySchemas,
  ...FileSchemas,
  ...VehicleInsuranceCompanySchemas,
  ...dealertrackApiSchemas,
  ...apponeApiSchemas,
  ...expressApiSchemas,
  ...PayoffBankSchemas,
  ...StateSchemas,
  ...HistoriesSchemas,
  ...FundingDocumentSchemas,
  ...LockSchemas,
  ...EmailSendSchemas,
  ...IncomeReportSchemas,
  ...ExternalCreditApplicationSchemas,
  ...ProtectiveServicesSchemas,
  ...ZipCodeLookupSchemas,
  ...CustomReportSchemas,
  ...DocumentValidationSchemas,
  ...FloorplanxpressSchemas,
  ...SequenceSchemas,
  ...AscApiSchemas,
  ...CreditSmartsSchemas,
  ...ContractsSchemas,
  ...ContractTypesSchemas,
  ...NotificationSchemas,
  ...LenderDecisionSchemas,
  ...SettingsSchemas,
  ...BusinessReportSchemas,
  ...TableSettingsSchema,
  ...AdditionalRequiredDocumentsSchemas,
  ...PrintedDocumentsSchemas
};
