import { Box } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

const style = {
  display: "flex",
  position: "absolute",
  right: "0px",
  bottom: "0px",
  left: "0px",
  top: "0px",
  cursor: "pointer",
  zIndex: 1200,
  justifyContent: "center",
  alignItems: "center",
  borderWidth: "2px",
  borderColor: "rgb(102, 102, 102)",
  borderStyle: "dashed",
  borderRadius: "5px",
  backgroundColor: "rgba(240,248,255,0.5)"
} as React.CSSProperties;
interface Props {
  containerRef: React.RefObject<HTMLDivElement>;
  createDocument: (file: File) => void;
  showDropzone?: boolean;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}

export default function DropZoneNoUpload({
  containerRef,
  createDocument,
  setFile,
  showDropzone = false
}: Props): JSX.Element {
  const fileSizeLimit = 2147483648; //2GB
  const [show, setShow] = useState(showDropzone);

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const container = containerRef?.current;
    if (container !== null) {
      let lastTarget: EventTarget | null = null;
      container.addEventListener("dragenter", function (e) {
        if (e?.dataTransfer?.items?.[0]?.kind === "file") {
          lastTarget = e.target;
        }
        setShow(true);
      });
      container.addEventListener("dragleave", function (e) {
        if (e.target === lastTarget || e.target === document) {
          setShow(showDropzone);
        }
      });
    }
  }, [containerRef, setShow]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (droppedfile) => {
      if (droppedfile.length > 1) {
        enqueueSnackbar("Please upload only one file at once!", {
          variant: "error"
        });
        setShow(showDropzone);
        return;
      }
      if (droppedfile[0].size < fileSizeLimit) {
        setFile(droppedfile[0]);
        createDocument(droppedfile[0]);
      } else {
        enqueueSnackbar("Invalid file. Please try again.", {
          variant: "error"
        });
      }
      setShow(showDropzone);
    }
  });

  return (
    <section>
      {show ? (
        <div {...getRootProps({ style })} id="drop">
          <input
            data-testid="upload_input"
            {...getInputProps()}
            id="upload_input"
            draggable="true"
          />
        </div>
      ) : null}
    </section>
  );
}
