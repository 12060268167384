import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import CloseDialogButton from "components/common/CloseDialogButton";
import React from "react";

interface Props {
  open: boolean;
  currentTabId: string;
  handleClose: () => void;
  handleActionAndClose: (tabId: string) => void;
  dialogText: string;
  dialogTitle?: string;
}
export default function CloseTabDialog({
  open,
  currentTabId,
  handleClose,
  handleActionAndClose,
  dialogText,
  dialogTitle = "Warning"
}: Props) {
  return (
    <Dialog
      id="confirm-delete-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Warning style={{ color: "#eed202", verticalAlign: "text-bottom" }} /> {dialogTitle}
        <CloseDialogButton closeFunction={handleClose} />
      </DialogTitle>
      <DialogContent>
        {dialogText}
        <br />
        <i> All the unsaved data will be lost. </i>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleActionAndClose(currentTabId)}
          variant="contained"
          color="secondary"
          autoFocus
          id="confirm-asc-submit"
        >
          Close without saving
        </Button>
        <Button onClick={handleClose} variant="contained" color="primary" id="cancel">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
