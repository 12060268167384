import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormStateUpload, FormState } from "./types";

type AddLenderDecisionRequest = {
  data: {
    data: FormStateUpload;
    deleted: boolean;
  }[];
};

export interface AddLenderDecisionResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  }[];
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddLenderDecisionRequest,
  AddLenderDecisionResponse
>("AddManyLenderDecisions", {
  request: "new_lender_decisions"
});

export const addManyLenderDecisionsActions = socketMessageSlice.actions;
export const addManyLenderDecisions = socketAction;
export default socketMessageSlice.reducer;
