import {
  Model,
  createOptionsForSelect,
  OptionsForSelect,
  FormComponent
} from "../../utils/models/fields";
import { File } from "./types";
import { StateAccess } from "utils/models/formGenerator";
import DocumentTemplates from "components/DocumentTemplates/DocumentTemplates";
import React from "react";
import DropZonePreview from "./DropZonePreview";
import { EntityType } from "utils/entitySlice";
import DocumentTemplatesPreview from "components/DocumentTemplates/DocumentTemplatesPreview";

export const generateFileStruct = <T extends any>(
  optionsForSelect: OptionsForSelect<T>,
  additionalFields?: FormComponent<File>
): Model<File> => {
  return {
    formComponent: "model",
    schema: "new_file",
    name: "file",
    entities: [
      {
        formComponent: "segment",
        name: "File",
        entities: [
          {
            formComponent: "file-preview-field",
            name: "filePreview",
            label: "File preview",

            path: ["data", "info"],
            default: null,
            required: false
          },

          {
            formComponent: "segment",
            entities: [
              {
                formComponent: "text-field",
                name: "fileName",
                label: "File name",
                width: "full",
                path: ["data", "info", "name"],
                default: null,
                required: true
              },
              {
                formComponent: "text-field",
                name: "fileDescription",
                label: "File description",
                width: "full",
                path: ["data", "info", "description"],
                default: null,
                required: false
              }, //@ts-ignore
              ...(optionsForSelect?.possibleValues()?.length > 0
                ? [
                    {
                      formComponent: "multiselect-field",
                      name: "types",
                      label: "Type",
                      width: "full",
                      path: ["data", "info", "types"],
                      options: optionsForSelect
                    }
                  ]
                : [])
            ]
          }
        ]
      },
      ...(additionalFields ? [additionalFields] : [])
    ]
  };
};

export const generateAddFileStruct = <T extends any>(
  optionsForSelect: OptionsForSelect<T>,
  entityType: EntityType,
  additionalFields?: FormComponent<File>
): Model<File> => {
  return {
    formComponent: "model",
    schema: "update_file",
    name: "file",
    entities: [
      {
        formComponent: "segment",
        name: "File",
        entities: [
          {
            formComponent: "one-to-many-field",
            component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
              <DropZonePreview stateAccess={stateAccess} entityType={entityType} />
            ),
            name: "DropZone",
            width: "1/2",
            path: [] as any,
            default: {}
          },

          {
            formComponent: "segment",
            entities: [
              {
                formComponent: "text-field",
                name: "fileName",
                label: "File name",
                width: "full",
                path: ["data", "info", "name"],
                default: null,
                required: true
              },

              {
                formComponent: "text-field",
                name: "fileDescription",
                label: "File description",
                width: "full",
                path: ["data", "info", "description"],
                default: null,
                required: false
              }, //@ts-ignore
              ...(optionsForSelect?.possibleValues()?.length > 0
                ? [
                    {
                      formComponent: "multiselect-field",
                      name: "types",
                      label: "Type",
                      width: "full",
                      path: ["data", "info", "types"],
                      options: optionsForSelect
                    }
                  ]
                : [])
            ]
          }
        ]
      },
      ...(additionalFields ? [additionalFields] : [])
    ]
  };
};

export const fileStruct = generateFileStruct(
  createOptionsForSelect({
    possibleValues: (stateAccess: StateAccess) => [],
    getOptionLabel: (name) => name
  })
);

export const templateFileStruct: Model<File> = {
  formComponent: "model",
  schema: "new_file",
  name: "file",
  entities: [
    {
      formComponent: "segment",
      name: "File",
      entities: [
        {
          formComponent: "segment",
          width: "full",
          entities: [
            {
              formComponent: "text-field",
              name: "fileName",
              label: "File name",
              width: "1/3",
              path: ["data", "info", "name"],
              default: null,
              required: true
            },
            {
              formComponent: "text-field",
              name: "fileDescription",
              label: "File description",
              width: "1/3",
              path: ["data", "info", "description"],
              default: null,
              required: false
            },
            {
              formComponent: "one-to-many-field",
              width: "1/3",
              component: (stateAccess: StateAccess) => (
                <DocumentTemplatesPreview stateAccess={stateAccess} />
              ),

              name: "templateModal",
              label: "Template Edit Modal",
              path: null
            },
            {
              formComponent: "checkbox-field",
              path: ["data", "info", "hasBarcode"],
              name: "hasBarcode",
              label: "Has Barcode?",
              width: "1/4",
              default: false,
              isDisabled: () => false,
              isChecked: (stateAccess: StateAccess) => {
                return stateAccess.get(["data", "info", "hasBarcode"]);
              },
              toggle: (stateAccess: StateAccess) => {
                const hasBarcode = stateAccess.get(["data", "info", "hasBarcode"]);
                stateAccess.set<File>(["data", "info", "hasBarcode"], !hasBarcode);
              }
            },

            {
              formComponent: "one-to-many-field",
              component: (stateAccess: StateAccess) => (
                <DocumentTemplates
                  stateAccess={stateAccess}
                  // TODO: fix Types
                  filePath={["data", "info", "url"] as any}
                  // TODO: fix Types
                  coordinatesPath={["data", "info", "coordinates"] as any}
                />
              ),
              width: "full",
              name: "templateModal",
              label: "Template Edit Modal",
              path: null
            }
          ]
        }
      ]
    }
  ]
};
