import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteUserRequest {
  _id: string;
}

interface DeleteUserResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteUserRequest,
  DeleteUserResponse
>("DeleteWfdUser", {
  request: "delete_wfd_user"
});

export const deleteUserActions = socketMessageSlice.actions;
export const deleteUser = socketAction;
export default socketMessageSlice.reducer;
