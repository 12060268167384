import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteApplicantRequest {
  _id: string;
}

interface DeleteApplicantResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteApplicantRequest,
  DeleteApplicantResponse
>("DeleteApplicant", {
  request: "delete_applicant"
});

export const deleteApplicantActions = socketMessageSlice.actions;
export const deleteApplicant = socketAction;
export default socketMessageSlice.reducer;
