import { FundingDocument } from "./types";
import {
  multyPermissionNode,
  permissionNode,
  relationPermissionNode,
  PermissionStruct
} from "components/Roles/types";

export type FundingDocumentPermissionsStruct = PermissionStruct<FundingDocument>;

export const fundingDocumentPermissionStruct: FundingDocumentPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      name: permissionNode("Name"),
      url: permissionNode("Url"),
      urls: multyPermissionNode("Urls"),
      type: permissionNode("Type"),
      isCombined: permissionNode("Is combined?"),
      ids: multyPermissionNode("Ids"),
      source: {
        mobileApp: permissionNode("Mobile app"),
        dealershipApp: permissionNode("Dealership app"),
        adminApp: permissionNode("Admin app")
      },
      coordinates: {
        label: permissionNode("Label"),
        id: permissionNode("Id"),
        path: multyPermissionNode("Path"),
        fontSize: permissionNode("Font size"),
        x: permissionNode("X"),
        y: permissionNode("Y"),
        required: permissionNode("required"),
        initial: permissionNode("initial"),
        tooltipLabel: multyPermissionNode("Tooltip label"),
        type: permissionNode("Type"),
        width: permissionNode("width"),
        height: permissionNode("height")
      },
      description: permissionNode("Description"),
      types: relationPermissionNode("Types", "document_template"),
      metadata: {
        contentType: permissionNode("Content type")
      }
    },
    uploadedDocumentStatus: {
      status: permissionNode("status"),
      pages: multyPermissionNode("pages")
    },
    printedDocumentId: permissionNode("printedDocumentId"),
    printedDocument: relationPermissionNode("Printed documnet", "printed_document"),
    deal: relationPermissionNode("Deal", "deal"),
    dealId: permissionNode("data")
  }
};
