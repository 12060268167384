import { Chargeback } from "./types";
import {
  multyPermissionNode,
  permissionNode,
  PermissionStruct,
  relationPermissionNode
} from "components/Roles/types";

export type ChargebackPermissionsStruct = PermissionStruct<Chargeback>;

export const chargebackPermissionsStruct: ChargebackPermissionsStruct = {
  _id: permissionNode("id"),
  createdAt: permissionNode("Created at"),
  updatedAt: permissionNode("Updated at"),
  deleted: permissionNode("Deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    lender: relationPermissionNode("Lender", "lender"),
    lenderId: permissionNode("Lender id"),
    manager: relationPermissionNode("Manager", "wfd_user"),
    managerId: permissionNode("Manager id"),
    dealership: relationPermissionNode("Dealership", "dealership"),
    dealershipId: permissionNode("dealershipId"),
    dealId: permissionNode("dealId"),
    deal: {
      applicantFirstName: permissionNode("applicantFirstName"),
      applicantLastName: permissionNode("applicantLastName"),
      refNumber: permissionNode("refNumber"),
      vehicle: {
        make: permissionNode("Make"),
        model: permissionNode("Model"),
        VIN: permissionNode("Vin")
      },
      contractDate: permissionNode("Contract date"),
      term: permissionNode("Term"),
      interestRate: permissionNode("Interest rate"),
      fundingDate: permissionNode("Fundig date"),
      collateralType: permissionNode("Collateral type"),
      profit: {
        grossRevenue: permissionNode("Gross Revenue"),
        productGrossRevenue: permissionNode("Product Gross Revenue"),
        totalServiceWarrantyProfit: permissionNode("Total service warranty profit"),
        totalGAPProfit: permissionNode("Total gap profit"),
        grossProfit: permissionNode("Gross profit"),
        totalExtraProfit: permissionNode("Gross profit"),
        grossProfitWithExtra: permissionNode("WFD Gross profit"),
        dealershipProfit: {
          totalProfitFees: permissionNode("Total Profit Fees"),
          totalDealProfit: permissionNode("Total Deal Profit"),
          reserveCommission: permissionNode("Reserve commission"),
          splitFromDeal: permissionNode("Split from deal"),
          splitTotalFromServiceWarranty: permissionNode("Split total from service warranty"),
          splitTotalFromGap: permissionNode("Split total from gap"),
          totalProfit: permissionNode("Total profit"),
          totalProfitWFD: permissionNode("Total profit wfd")
        },
        wfdProfit: {
          totalProductsProfit: permissionNode("Total Products Profit"),
          totalReserveProfit: permissionNode("Total Reserve Profit"),
          reserveCommission: permissionNode("Reserve commission"),
          splitFromDeal: permissionNode("Split from deal"),
          splitTotalFromServiceWarranty: permissionNode("Split total from service warranty"),
          splitTotalFromGap: permissionNode("Split total from gap"),
          totalProfit: permissionNode("Total profit"),
          splitFromVehicleCoverage: permissionNode("Split from vehicle coverage"),
          extraGAPProfit: permissionNode("Extra gap profit"),
          extraServiceWarrantyProfit: permissionNode("Extra service warranty profit"),
          reserveMethodType: permissionNode("Reserve method type"),
          extraReserveProfit: permissionNode("Extra reserve profit"),
          reserverPercentage: permissionNode("Reserver percentage")
        },
        representativeProfit: { commission: permissionNode("Commission") },
        managerProfit: {
          commission: permissionNode("Commission"),
          chargebackPercentProduct: permissionNode("Product chargeback"),
          chargebackPercentReserve: permissionNode("Reserve chargeback"),
          ascCommission: permissionNode("ASC Commission"),
          gapCommission: permissionNode("Gap Commission"),
          productCommission: permissionNode("Product Commission"),
          reserveCommission: permissionNode("Reserve Commission"),
          commissionableAmount: permissionNode("Commissionable Amount")
        }
      },
      accounting: {
        copied: permissionNode("copied"),
        fundingNoticeDate: permissionNode("fundingNoticeDate"),
        collateralFundingDate: permissionNode("collateralFundingDate"),
        reserveFundingDate: permissionNode("reserveFundingDate"),
        warrantyPrice: permissionNode("warrantyPrice"),
        warrantyCost: permissionNode("warrantyCost"),
        gapPrice: permissionNode("gapPrice"),
        prePaidMaintenancePrice: permissionNode("prePaidMaintenancePrice"),
        paintAndFabricationPrice: permissionNode("paintAndFabricationPrice"),
        ecoPrice: permissionNode("ecoPrice"),
        wheelAndTirePrice: permissionNode("wheelAndTirePrice"),
        gapRemit: permissionNode("gapRemit"),
        warrantyRemit: permissionNode("warrantyRemit"),
        gapCost: permissionNode("gapCost"),
        payment: {
          type: permissionNode("Type"),
          numberOfPayments: permissionNode("Number of payments"),
          daysToFirstPayment: permissionNode("Days to first payment"),
          interestRate: permissionNode("Interest rate"),
          dealTotal: permissionNode("Deal total"),
          downPayment: permissionNode("Down payment"),
          amountFinanced: permissionNode("Amount financed"),
          totalAftermarket: permissionNode("Total aftermarket"),
          totalTaxes: permissionNode("Total taxes"),
          localTaxes: permissionNode("Local taxes"),
          stateTaxes: permissionNode("State taxes"),
          taxableAmount: permissionNode("Taxable amount"),
          totalFees: permissionNode("Total fees"),
          monthlyPayment: permissionNode("Monthly payment"),
          totalOfPayments: permissionNode("Total of payments"),
          totalSalePrice: permissionNode("Total sale price"),
          fundedAmount: permissionNode("Funded amount"),
          buyPercent: permissionNode("Buy percent"),
          wfdSplit: permissionNode("Wfd split"),
          splitMethod: permissionNode("Split method"),
          reserve: permissionNode("Reserve"),
          reserveMethod: permissionNode("Reserve method"),
          correspondentPercent: permissionNode("Correspondent Percent"),
          dealershipSplit: permissionNode("Dealership split"),
          wfdGAPSplit: permissionNode("Wfd GAP split"),
          dealershipGAPSplit: permissionNode("Dealership split"),
          wfdWarrantySplit: permissionNode("Wfd Warranty split"),
          dealershipWarrantySplit: permissionNode("Dealership split"),
          maxReserve: permissionNode("Max reserve"),
          markupData: {
            isDefault: permissionNode("isDefault"),
            markup: permissionNode("markup"),
            reservePercentage: permissionNode("reservePercentage"),
            dealerSplitPercentage: permissionNode("dealerSplitPercentage"),
            extraReserveFixed: permissionNode("extraReserveFixed"),
            extraReservePercentage: permissionNode("extraReservePercentage")
          }
        },
        profit: {
          grossRevenue: permissionNode("Gross Revenue"),
          productGrossRevenue: permissionNode("Product Gross Revenue"),
          totalServiceWarrantyProfit: permissionNode("Total service warranty profit"),
          totalGAPProfit: permissionNode("Total gap profit"),
          grossProfit: permissionNode("Gross profit"),
          totalExtraProfit: permissionNode("Gross profit"),
          grossProfitWithExtra: permissionNode("WFD Gross profit"),
          dealershipProfit: {
            reserveCommission: permissionNode("Reserve commission"),
            splitFromDeal: permissionNode("Split from deal"),
            totalProfitFees: permissionNode("Total Profit Fees"),
            totalDealProfit: permissionNode("Total Deal Profit"),
            splitTotalFromServiceWarranty: permissionNode("Split total from service warranty"),
            splitTotalFromGap: permissionNode("Split total from gap"),
            totalProfit: permissionNode("Total profit"),
            totalProfitWFD: permissionNode("Total profit wfd")
          },
          wfdProfit: {
            totalProductsProfit: permissionNode("Total Products Profit"),
            totalReserveProfit: permissionNode("Total Reserve Profit"),
            reserveCommission: permissionNode("Reserve commission"),
            splitFromDeal: permissionNode("Split from deal"),
            splitTotalFromServiceWarranty: permissionNode("Split total from service warranty"),
            splitTotalFromGap: permissionNode("Split total from gap"),
            totalProfit: permissionNode("Total profit"),
            splitFromVehicleCoverage: permissionNode("Split from vehicle coverage"),
            extraGAPProfit: permissionNode("Extra gap profit"),
            extraServiceWarrantyProfit: permissionNode("Extra service warranty profit"),
            reserveMethodType: permissionNode("Reserve method type"),
            extraReserveProfit: permissionNode("Extra reserve profit"),
            reserverPercentage: permissionNode("Reserver percentage")
          },
          representativeProfit: { commission: permissionNode("Commission") },
          managerProfit: {
            commissionableAmount: permissionNode("Commissionable Amount"),
            chargebackPercentProduct: permissionNode("Product chargeback"),
            chargebackPercentReserve: permissionNode("Reserve chargeback"),
            gapCommission: permissionNode("Gap Commission"),
            ascCommission: permissionNode("ASC Commission"),
            commission: permissionNode("Commission"),
            productCommission: permissionNode("Product Commission"),
            reserveCommission: permissionNode("Reserve Commission")
          }
        },
        taxesAndFees: {
          isFixedTax: permissionNode("Is fixed tax"),
          titleTransfer: permissionNode("Title transfer"),
          license: permissionNode("License"),
          documentRegistrationFees: permissionNode("Document registration fees"),
          filingFees: permissionNode("Filing fees"),
          otherFees: permissionNode("Other fees"),
          fundingFees: multyPermissionNode("Funding Fees"),
          totalMembershipFees: permissionNode("Total membership fees"),
          totalUCCFees: permissionNode("Total ucc fees"),
          wfdMinimumFee: permissionNode("WFD minimum fee"),
          acquisitionFee: permissionNode("Acquisition fee"),
          lenderAcquisitionFee: permissionNode("Lender Acquisition fee"),
          detailed: {
            registrationTax: permissionNode("Registration tax"),
            plateFee: permissionNode("Plate fee"),
            tempPlateFee: permissionNode("Temp Plate fee"),
            wheelageTax: permissionNode("Wheelage tax"),
            psVehicleFee: permissionNode("Ps vehicle fee"),
            transferTax: permissionNode("Transfer tax"),
            titleTransferFee: permissionNode("Title transfer fee"),
            techSurchargeFee: permissionNode("Tech surcharge fee"),
            lienFee: permissionNode("Lien fee"),
            deputyFilingFee: permissionNode("Deputy filing fee"),
            exciseTax: permissionNode("Excise tax"),
            licenseAndRegFee: permissionNode("License and reg fee"),
            processingFee: permissionNode("Processing fee"),
            contributionFee: permissionNode("Contribution fee")
          }
        }
      }
    },
    info: {
      vendor: permissionNode("Vendor"),
      customVendor: permissionNode("Custom vendor"),
      netsuiteStatus: permissionNode("Pushed to Netsuite"),
      refId: permissionNode("Ref id"),
      useExtraProfit: permissionNode("Use extra profit"),
      reason: permissionNode("cancellationReason"),
      wfdChargebackAmount: permissionNode("WFD Chargeback Amount"),
      dealerChargebackCalculation: permissionNode("Dealer Chargeback Amount"),
      wfdChargebackCalculation: permissionNode("WFD Chargeback Amount"),
      dealerChargebackAmount: permissionNode("Dealer Chargeback Amount"),
      managerChargebackAmount: permissionNode("Manager Chargeback Amount"),
      managerChargebackCalculation: permissionNode("Manager Chargeback Amount"),
      netWFDChargeBackAmount: permissionNode("Net WFD Amount"),
      netWFDChargeBackCalculation: permissionNode("Net WFD Amount"),
      totalChargebackAmount: permissionNode("Total chargeback amount"),
      percentUsed: permissionNode("Percent used"),
      chargebackDate: permissionNode("Chargeback Date"),
      payoffDate: permissionNode("Payoff date"),
      type: permissionNode("Deal chargeback type"),
      extraProfitIncluded: permissionNode("ExtraProfitIncluded"),
      dealProfitPercentages: {
        Warranty: {
          dealer: permissionNode("Dealer"),
          manager: permissionNode("Manager"),
          wfd: permissionNode("WFD")
        },
        GAP: {
          dealer: permissionNode("Dealer"),
          manager: permissionNode("Manager"),
          wfd: permissionNode("WFD")
        },
        Reserve: {
          dealer: permissionNode("Dealer"),
          manager: permissionNode("Manager"),
          wfd: permissionNode("WFD")
        }
      }
    }
  }
};
