import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeletePrescreenRequest {
  _id: string;
}

export interface DeletePrescreenResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeletePrescreenRequest,
  DeletePrescreenResponse
>("DeletePrescreen", {
  request: "delete_prescreen"
});

export const deletePrescreenActions = socketMessageSlice.actions;
export const deletePrescreen = socketAction;
export default socketMessageSlice.reducer;
