import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { File } from "./types";

interface RecoverFileRequest {
  _id: string;
}

export interface RecoverFileResponse {
  message: File;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverFileRequest,
  RecoverFileResponse
>("RecoverFile", {
  request: "recover_file"
});

export const recoverFileActions = socketMessageSlice.actions;
export const recoverFile = socketAction;
export default socketMessageSlice.reducer;
