import { Grid, DialogTitle, DialogContent, DialogActions, Dialog, Button } from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";
import React from "react";
interface Props {
  url: string;
  handleClose: () => void;
  title?: string;
}

export default function IFrameURLModal({ title = "Contract Preview", url, handleClose }: Props) {
  return (
    <Dialog fullWidth maxWidth="xl" onClose={handleClose} open={true}>
      <Grid
        container
        alignItems="center"
        spacing={1}
        style={{ paddingRight: "4px", width: "100%" }}
      >
        <Grid item xs={6} key="title">
          <DialogTitle>{title}</DialogTitle>
          <CloseDialogButton closeFunction={handleClose} />
        </Grid>
      </Grid>

      <DialogContent style={{ height: "666px" }}>
        <iframe src={url} frameBorder="0" width="100%" height="100%"></iframe>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          id="close-validate-documents"
          color="primary"
          variant="contained"
        >
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
