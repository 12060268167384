import { Schema } from "ajv";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import info_properties from "./info_properties";

export const data: Schema = {
  type: "object",
  required: ["info"],
  properties: {
    info: {
      type: "object",
      required: ["name"],
      properties: info_properties,
    },
  },
};

export default {
  new_dealership_program: {
    $id: "newDealershipProgram",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_dealership_program: {
    $id: "updateDealershipProgram",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      approved: {
        type: "boolean",
        nullable: true,
      },
      approvedAt: {
        type: "string",
        nullable: true,
      },
      approvedByUserId: {
        type: "string",
        nullable: true,
      },
    },
  },
  delete_dealership_program: {
    $id: "deleteDealershipProgram",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  recover_dealership_program: {
    $id: "recoverDealershipProgram",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_dealership_program: {
    $id: "getDealershipProgram",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_dealership_programs: {
    $id: "allDealershipPrograms",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
