import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { TitleIssue } from "./types";
interface RecoverTitleIssueRequest {
  _id: string;
}

export interface RecoverTitleIssueResponse {
  message: TitleIssue;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverTitleIssueRequest,
  RecoverTitleIssueResponse
>("RecoverTitleIssue", {
  request: "recover_title_issue"
});

export const recoverTitleIssueActions = socketMessageSlice.actions;
export const recoverTitleIssue = socketAction;
export default socketMessageSlice.reducer;
