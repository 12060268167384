import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";

import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { RootState } from "app/rootReducer";
import {
  getDealershipList,
  removeDealershipList
} from "components/Dealerships/listDealershipSlice";
import { Dealership } from "components/Dealerships/types";
import { DealStatus, collateralTypes } from "components/Deals/types";
import { getLenderList, removeLenderList } from "components/Lenders/listLenderSlice";
import { Lender } from "components/Lenders/types";
import Spinner from "components/Loader/Spinner";
import {
  CollateralTypesEstimation,
  DealershipsEstimation,
  EstimatedData,
  EstimationResult,
  EstimationStructure,
  LenderEstimationStatisticRequest,
  LendersEstimation,
  RangesEstimation,
  StatisticsData,
  StatusesEstimation
} from "hooks/useLenderEstimationStatistics/types";
import useLenderEstimationStatistics from "hooks/useLenderEstimationStatistics/useLenderEstimationStatistics";

import { useStickyState } from "index";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalize,
  getCurrentQuarterDatesISO,
  getFirstAndLastDateOfCurrentMonth,
  getFirstAndLastDateOfGivenYearAndMonth,
  getLastQuarterDates
} from "utils/functions";
import { isValidDate } from "utils/models/formRenderers";
import { v4 as uuidv4 } from "uuid";
import EstimationPerformance from "./EstimationPerformance";
import StatisticsTable from "./StatisticsTable";

export type ToggleState = "yearly" | "monthly" | "quarterly";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxLabel: {
      fontSize: "12px"
    },
    periodToggle: {
      display: "flex",
      flex: 1,
      padding: 15,
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
    periodSelect: {
      appearance: "none" as const,
      WebkitAppearance: "none" as const,
      MozAppearance: "none" as const,
      border: "none",
      background: "#ffffff"
    },
    yearlyToggle: {
      color: (props: { selected: ToggleState | undefined }) =>
        props.selected === "yearly" ? "#fff" : "#000",
      background: (props: { selected: ToggleState | undefined }) =>
        props.selected === "yearly" ? "#2B4E70" : "#E8E8E8"
    },
    monthlyToggle: {
      color: (props: { selected: ToggleState | undefined }) =>
        props.selected === "monthly" ? "#fff" : "#000",
      background: (props: { selected: ToggleState | undefined }) =>
        props.selected === "monthly" ? "#2B4E70" : "#E8E8E8"
    },
    quarterlyToggle: {
      color: (props: { selected: ToggleState | undefined }) =>
        props.selected === "quarterly" ? "#fff" : "#000",
      background: (props: { selected: ToggleState | undefined }) =>
        props.selected === "quarterly" ? "#2B4E70" : "#E8E8E8"
    }
  })
);

const MonthYearToggle = ({
  selected,
  setSelected,
  type = "current"
}: {
  type?: "current" | "compare";
  selected: ToggleState | undefined;
  setSelected: (newState: ToggleState | undefined) => void;
}) => {
  const classes = useStyles({ selected });

  return (
    <>
      <div style={{ display: "flex", width: "100%" }}>
        <div
          className={[classes.periodToggle, classes.yearlyToggle].join(" ")}
          onClick={() => setSelected("yearly")}
        >
          {/* it is actually period to date(PTD) */}
          {type === "current" ? "CY" : "LY"}
        </div>
        <div
          className={[classes.periodToggle, classes.quarterlyToggle].join(" ")}
          onClick={() => setSelected("quarterly")}
        >
          {type === "current" ? "CQ" : "LQ"}
        </div>

        <div
          className={[classes.periodToggle, classes.monthlyToggle].join(" ")}
          onClick={() => setSelected("monthly")}
        >
          {type === "current" ? "CM" : "LM"}
        </div>
      </div>
    </>
  );
};

const getDefaultRollbackPointDate = () => {
  const today = new Date();
  today.setHours(23);
  today.setMinutes(59);
  today.setSeconds(59);
  today.setMilliseconds(59);
  return today.toISOString();
};
type Threshold = {
  value: number | null;
  timeout?: NodeJS.Timeout;
};
export default ({
  setResetStickyCallback
}: {
  setResetStickyCallback?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}) => {
  const [lenders, setLenders] = useStickyState<Lender[]>([], "estimation_dashboard_lenders");
  const [dealerships, setDealerships] = useStickyState<Dealership[]>(
    [],
    "estimation_dashboard_dealerships"
  );
  const [periodToggle, setToggle] = useStickyState<ToggleState | undefined>(
    "monthly",
    "estimation_dashboard_periodToggle"
  );
  const [periodToggleCompare, setToggleCompare] = useStickyState<ToggleState | undefined>(
    undefined,
    "estimation_dashboard_periodToggleCompare"
  );
  const classes = useStyles({ selected: periodToggle });
  const [requestId] = React.useState(uuidv4());
  const dispatch = useDispatch();
  const listId = `list-autocomplete-lender-estimation-statistics-${requestId}`;
  const lenderList = useSelector((state: RootState) => state.listLenderSlice[listId]);
  const dealershipList = useSelector((state: RootState) => state.listDealershipSlice[listId]);

  const [requestEnabled, setRequestEnabled] = React.useState(true);

  const [thresholds, setThresholds] = useStickyState<{
    estimationThresholdAmountPercentage: Threshold;
    estimationThresholdDealCount: Threshold;
  }>(
    {
      estimationThresholdAmountPercentage: {
        value: 90
      },
      estimationThresholdDealCount: {
        value: 3
      }
    },
    "estimation_dashboard_tresholds"
  );
  const [state, setState] = useStickyState<LenderEstimationStatisticRequest>(
    {
      lenderIds: [],
      allLenders: true,
      dealershipIds: [],
      allDealerships: true,
      collateralTypes: ["Automotive", "Marine", "Power Sport", "Recreational Vehicles"],
      estimationConfig: {
        statusToCompareTo: DealStatus.Funded,
        from: new Date(
          new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setDate(1)
        ).toISOString(),
        to: getFirstAndLastDateOfCurrentMonth().from
      },
      statisticsConfig: {
        ...getFirstAndLastDateOfCurrentMonth(),
        rollbackPointDate: getDefaultRollbackPointDate(),
        estimationThresholdAmountPercentage: 90,
        estimationThresholdDealCount: 10
      },
      statusesToCompare: [
        DealStatus.Approved,
        DealStatus.SentToDealer,
        DealStatus.SubmittedForFunding,
        DealStatus.FundingHeld,
        DealStatus.Funded
      ]
    },
    "estimation_dashboard_state"
  );
  const handleSetToggle = (period: ToggleState | undefined) => {
    setToggle(period);
    setState({
      ...state,
      statisticsConfig: {
        ...state.statisticsConfig,
        ...(period === "monthly"
          ? getFirstAndLastDateOfCurrentMonth()
          : period === "quarterly"
          ? getCurrentQuarterDatesISO()
          : {
              from: new Date(new Date().getFullYear(), 0, 1, 0, 0, 0).toISOString(),
              to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString()
            })
      }
    });
  };
  const handleSetToggleCompare = (period: ToggleState | undefined) => {
    const date = new Date();
    const rollbackPointDate = new Date(state.statisticsConfig.rollbackPointDate);
    const { from: quarterFrom, to: quarterTo } = getLastQuarterDates();
    const { from: monthlyFrom, to: monthlyTo } = getFirstAndLastDateOfGivenYearAndMonth(
      date.getFullYear(),
      date.getMonth()
    );
    setToggleCompare(period);
    setState({
      ...state,
      statisticsConfig: {
        ...state.statisticsConfig,
        ...(period === "monthly"
          ? {
              compareRollbackPointDate: new Date(
                rollbackPointDate.setMonth(rollbackPointDate.getMonth() - 1)
              ).toISOString(),
              compareFrom: monthlyFrom?.toISOString(),
              compareTo: monthlyTo?.toISOString()
            }
          : period === "quarterly"
          ? { compareFrom: quarterFrom, compareTo: quarterTo }
          : {
              compareFrom: new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0).toISOString(),
              compareTo: new Date(
                new Date().getFullYear() - 1,
                new Date().getMonth() + 1,
                1
              ).toISOString()
            })
      }
    });
  };
  const [currentData, setCurrentData] = React.useState<
    | {
        data:
          | EstimationResult<
              | CollateralTypesEstimation
              | LendersEstimation
              | DealershipsEstimation
              | StatusesEstimation
              | RangesEstimation
            >
          | EstimationStructure
          | undefined;
        title: string | undefined;
        subtitle: string | undefined;
      }
    | undefined
  >(undefined);
  const [oldRequest, setOldRequest] = React.useState<string>();
  const request = {
    ...state,
    lenderIds: lenders?.map((lender) => lender?._id ?? "")?.filter((x) => x),
    dealershipIds: dealerships?.map((dealership) => dealership?._id ?? "")?.filter((x) => x)
  };

  const requestWasChanged = JSON.stringify(request) !== oldRequest;
  const { data, isFetching } = useLenderEstimationStatistics(
    request,
    (data) => {
      setCurrentData(undefined);
      setNavigationStack([]);
      setRequestEnabled(false);
      setOldRequest(JSON.stringify(data.data.request));
    },
    requestEnabled
  );

  const [navigationStack, setNavigationStack] = React.useState<
    {
      data:
        | EstimationResult<
            | CollateralTypesEstimation
            | LendersEstimation
            | DealershipsEstimation
            | StatusesEstimation
            | RangesEstimation
          >
        | EstimationStructure
        | undefined;
      title: string | undefined;
      subtitle: string | undefined;
    }[]
  >([]);

  const handleBack = () => {
    const previousData = navigationStack.pop();
    setCurrentData(previousData);
    setNavigationStack([...navigationStack]);
  };

  const handleDiveDeeper = (
    title: string,
    subtitle: string,
    data:
      | EstimationResult<
          | CollateralTypesEstimation
          | LendersEstimation
          | DealershipsEstimation
          | StatusesEstimation
          | RangesEstimation
        >
      | EstimationStructure
  ) => {
    if (currentData) {
      setNavigationStack([...navigationStack, currentData]);
    }
    setCurrentData({ data, title, subtitle });
  };
  React.useEffect(() => {
    if (!lenderList)
      dispatch(
        getLenderList(listId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } }
        })
      );
    if (!dealershipList)
      dispatch(
        getDealershipList(listId, {
          options: { pagination: false, sort: { "data.info.displayName": "asc" } }
        })
      );
    return () => {
      dispatch(removeLenderList(listId));
      dispatch(removeDealershipList(listId));
      return;
    };
  }, []);

  const compareMode =
    data?.data?.statistics?.comparedEstimatedData !== undefined &&
    data?.data?.statistics?.comparedTruthfulData !== undefined &&
    data?.data?.statistics?.comparedTruthfulDataAfterRollback !== undefined;

  const handleResetStickyState = () => {
    setLenders([]);
    setDealerships([]);
    setToggle("monthly");
    setToggleCompare(undefined);
    setState({
      lenderIds: [],
      allLenders: true,
      dealershipIds: [],
      allDealerships: true,
      collateralTypes: ["Automotive", "Marine", "Power Sport", "Recreational Vehicles"],
      estimationConfig: {
        statusToCompareTo: DealStatus.Funded,
        from: new Date(
          new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setDate(1)
        ).toISOString(),
        to: getFirstAndLastDateOfCurrentMonth().from
      },
      statisticsConfig: {
        ...getFirstAndLastDateOfCurrentMonth(),
        rollbackPointDate: getDefaultRollbackPointDate(),
        estimationThresholdAmountPercentage: 90,
        estimationThresholdDealCount: 10
      },
      statusesToCompare: [
        DealStatus.Approved,
        DealStatus.SentToDealer,
        DealStatus.SubmittedForFunding,
        DealStatus.FundingHeld,
        DealStatus.Funded
      ]
    });
    setThresholds({
      estimationThresholdAmountPercentage: {
        value: 90
      },
      estimationThresholdDealCount: {
        value: 3
      }
    });
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, []);

  return (
    <Grid container xs={12} style={{ margin: 5 }}>
      {setResetStickyCallback === undefined && (
        <Button
          onClick={() => handleResetStickyState()}
          style={{ margin: "5px 0px", float: "right" }}
          color="primary"
          variant="contained"
        >
          Reset state
        </Button>
      )}
      <Grid container xs={12} style={{ marginBottom: 5, marginTop: 5 }}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ width: "100%" }}>
              <Grid container item xs={12}>
                <Grid container item xs={6}>
                  <Grid item container xs={12}>
                    <Grid item xs={6} style={{ padding: "10px" }}>
                      <Autocomplete
                        style={{ zIndex: 6 }}
                        value={
                          state.allLenders
                            ? ((["All lenders"] as unknown) as Lender[])
                            : lenders || null
                        }
                        getOptionLabel={(lender) => {
                          if (((lender as unknown) as string) === "All lenders") {
                            return (lender as unknown) as string;
                          }
                          return lender.data.info.name ?? "";
                        }}
                        multiple
                        getOptionSelected={(x, y) => x?._id === y?._id && x === y}
                        options={[
                          ("All lenders" as unknown) as Lender,
                          ...(lenderList?.entities ?? [])
                        ]}
                        onChange={(event, newValue) => {
                          if (newValue?.length === 0) {
                            setState((prevState) => ({
                              ...prevState,
                              allLenders: true
                            }));
                            setLenders((["All lenders"] as unknown) as Lender[]);
                          } else {
                            setLenders(
                              newValue.filter((x) => x !== (("All lenders" as unknown) as Lender))
                            );
                            setState((prevState) => ({
                              ...prevState,
                              allLenders: false
                            }));
                          }
                        }}
                        loading={!Array.isArray(lenderList?.entities)}
                        openOnFocus
                        id="lender-estimation-statistics-lender-select"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ ...params.InputProps }}
                            label={"Lender"}
                            variant="filled"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ padding: "10px" }}>
                      <Autocomplete
                        style={{ zIndex: 6 }}
                        value={
                          state.allDealerships
                            ? ((["All dealerships"] as unknown) as Dealership[])
                            : dealerships || null
                        }
                        getOptionLabel={(dealership) => {
                          if (((dealership as unknown) as string) === "All dealerships") {
                            return (dealership as unknown) as string;
                          }
                          return dealership.data.info.displayName ?? "";
                        }}
                        multiple
                        getOptionSelected={(x, y) => x?._id === y?._id && x === y}
                        options={[
                          ("All dealerships" as unknown) as Dealership,
                          ...(dealershipList?.entities ?? [])
                        ]}
                        onChange={(event, newValue) => {
                          if (newValue?.length === 0) {
                            setState((prevState) => ({
                              ...prevState,
                              allDealerships: true
                            }));
                            setDealerships((["All dealerships"] as unknown) as Dealership[]);
                          } else {
                            setDealerships(
                              newValue.filter(
                                (x) => x !== (("All dealerships" as unknown) as Dealership)
                              )
                            );
                            setState((prevState) => ({
                              ...prevState,
                              allDealerships: false
                            }));
                          }
                        }}
                        loading={!Array.isArray(dealershipList?.entities)}
                        openOnFocus
                        id="lender-estimation-statistics-dealership-select"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ ...params.InputProps }}
                            label={"Dealership"}
                            variant="filled"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={6}>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        fontWeight: "500",
                        display: "inline-flex",
                        padding: "0px 14px",
                        marginRight: "10px",
                        width: "115px",
                        verticalAlign: "middle",
                        alignItems: "center",
                        background: "rgb(224 224 224)",
                        borderRadius: "4px 0px 0px 4px "
                      }}
                    >
                      <FormControlLabel
                        classes={{ label: classes.checkboxLabel }}
                        control={
                          <Checkbox
                            indeterminate={
                              state?.collateralTypes?.length > 0 &&
                              state?.collateralTypes?.length !== collateralTypes.length
                            }
                            checked={state?.collateralTypes?.length === collateralTypes.length}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setState((prevState) => ({
                                  ...prevState,
                                  collateralTypes: [
                                    "Automotive",
                                    "Marine",
                                    "Power Sport",
                                    "Recreational Vehicles"
                                  ]
                                }));
                              } else {
                                setState((prevState) => ({
                                  ...prevState,
                                  collateralTypes: []
                                }));
                              }
                            }}
                            name="AllCollateralTypesEstimation"
                            color="primary"
                          />
                        }
                        label={`All types`}
                      />
                    </div>

                    {Object.values(collateralTypes).map((collateralType, index) => (
                      <FormControlLabel
                        classes={{ label: classes.checkboxLabel }}
                        key={index}
                        control={
                          <Checkbox
                            onChange={(event) => {
                              if (event.target.checked) {
                                setState((prevState) => ({
                                  ...prevState,
                                  collateralTypes: [
                                    ...(prevState.collateralTypes ?? []),
                                    collateralType
                                  ]
                                }));
                              } else {
                                setState((prevState) => ({
                                  ...prevState,
                                  collateralTypes: prevState.collateralTypes.filter(
                                    (prevCollateralType) => prevCollateralType !== collateralType
                                  )
                                }));
                              }
                            }}
                            checked={
                              state?.collateralTypes?.findIndex(
                                (prevCollateralType) => prevCollateralType === collateralType
                              ) > -1
                            }
                            name={`${collateralType}-estimation`}
                            color="primary"
                          />
                        }
                        label={collateralType}
                      />
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: "0px 5px 0px 5px"
                    }}
                  >
                    From status
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ padding: "0px 5px 5px 10px" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      display: "inline-flex",
                      padding: "0px 14px",
                      marginRight: "10px",
                      width: "115px",
                      verticalAlign: "middle",
                      alignItems: "center",
                      background: "rgb(224 224 224)",
                      borderRadius: "4px 0px 0px 4px "
                    }}
                  >
                    <FormControlLabel
                      classes={{ label: classes.checkboxLabel }}
                      control={
                        <Checkbox
                          indeterminate={
                            state?.statusesToCompare?.length > 0 &&
                            state?.statusesToCompare?.length !== Object.keys(DealStatus).length
                          }
                          checked={
                            state?.statusesToCompare?.length === Object.keys(DealStatus).length
                          }
                          onChange={(event) => {
                            if (event.target.checked) {
                              setState((prevState) => ({
                                ...prevState,
                                statusesToCompare: Object.values(DealStatus) as DealStatus[]
                              }));
                            } else {
                              setState((prevState) => ({
                                ...prevState,
                                statuses: []
                              }));
                            }
                          }}
                          name="All"
                          color="primary"
                        />
                      }
                      label={`All statuses`}
                    />
                  </div>
                  {Object.values(DealStatus).map((status, index) => (
                    <FormControlLabel
                      classes={{ label: classes.checkboxLabel }}
                      key={index}
                      control={
                        <Checkbox
                          onChange={(event) => {
                            if (event.target.checked) {
                              setState((prevState) => ({
                                ...prevState,
                                statusesToCompare: [
                                  ...(prevState.statusesToCompare ?? []),
                                  status as DealStatus
                                ]
                              }));
                            } else {
                              setState((prevState) => ({
                                ...prevState,
                                statusesToCompare: prevState.statusesToCompare.filter(
                                  (prevStatus) => prevStatus !== status
                                )
                              }));
                            }
                          }}
                          checked={
                            state?.statusesToCompare?.findIndex(
                              (prevStatus) => prevStatus === status
                            ) > -1
                          }
                          name={capitalize(status)}
                          color="primary"
                        />
                      }
                      label={capitalize(status)}
                    />
                  ))}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item container xs={6}>
            <Paper elevation={3} style={{ width: "100%" }}>
              <Typography style={{ textAlign: "center", fontWeight: "bold", padding: "5px" }}>
                Statistics params
              </Typography>

              <Grid item xs={12} style={{ padding: "5px" }}>
                <Grid container item xs={12}>
                  <Grid xs={6} item style={{ padding: "5px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        id={"estimationFrom"}
                        name={"estimationFrom"}
                        error={!isValidDate(state.estimationConfig.from)}
                        helperText={
                          !isValidDate(state.estimationConfig.from) ? "Invalid date" : undefined
                        }
                        autoOk
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        inputVariant="filled"
                        variant="inline"
                        label={"From"}
                        required={true}
                        format="MM/dd/yyyy"
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        size="small"
                        value={state.estimationConfig.from ?? null}
                        onChange={(value) => {
                          if (isValidDate(value) && value) {
                            value.setHours(0);
                            value.setMinutes(0);
                            value.setSeconds(0);
                            value.setMilliseconds(0);
                            setState((prevState) => ({
                              ...prevState,
                              estimationConfig: {
                                ...prevState.estimationConfig,
                                from: value.toISOString()
                              }
                            }));
                          } else {
                            setState((prevState) => ({
                              ...prevState,
                              estimationConfig: {
                                ...prevState.estimationConfig,
                                from: value?.toString() as string
                              }
                            }));
                          }
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid xs={6} item style={{ padding: "5px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        id={"estimationTo"}
                        name={"estimationTo"}
                        error={!isValidDate(state.estimationConfig.to)}
                        helperText={
                          !isValidDate(state.estimationConfig.to) ? "Invalid date" : undefined
                        }
                        autoOk
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        inputVariant="filled"
                        variant="inline"
                        label={"To"}
                        required={true}
                        format="MM/dd/yyyy"
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        size="small"
                        value={state.estimationConfig.to ?? null}
                        onChange={(value) => {
                          if (isValidDate(value) && value) {
                            value.setHours(23);
                            value.setMinutes(59);
                            value.setSeconds(59);
                            value.setMilliseconds(59);
                            setState((prevState) => ({
                              ...prevState,
                              estimationConfig: {
                                ...prevState.estimationConfig,
                                to: value.toISOString()
                              }
                            }));
                          } else {
                            setState((prevState) => ({
                              ...prevState,
                              estimationConfig: {
                                ...prevState.estimationConfig,
                                to: value?.toString() as string
                              }
                            }));
                          }
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={12} style={{ padding: "5px" }}>
                <Grid item xs={6} style={{ padding: "5px" }}>
                  <Autocomplete
                    style={{ zIndex: 6 }}
                    value={state.estimationConfig.statusToCompareTo || null}
                    getOptionLabel={(status) => {
                      return capitalize(status);
                    }}
                    getOptionSelected={(x, y) => x === y}
                    options={[DealStatus.Funded, DealStatus.Dead]}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setState((prevState) => ({
                          ...prevState,
                          estimationConfig: {
                            ...prevState.estimationConfig,
                            statusToCompareTo: newValue as DealStatus.Funded | DealStatus.Dead
                          }
                        }));
                      }
                    }}
                    openOnFocus
                    id="lender-estimation-statistics-status-to-compare-to-select"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ ...params.InputProps }}
                        label={"Status to compare to"}
                        variant="filled"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item container xs={6}>
            <Paper elevation={3} style={{ width: "100%" }}>
              <Typography style={{ textAlign: "center", fontWeight: "bold", padding: "5px" }}>
                Estimation params
              </Typography>

              <Grid item xs={12} style={{ padding: "5px" }}>
                <Grid container item xs={12}>
                  <Grid xs={4} item style={{ padding: "5px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        id={"statisticsFrom"}
                        name={"statisticsFrom"}
                        error={!isValidDate(state.statisticsConfig.from)}
                        helperText={
                          !isValidDate(state.statisticsConfig.from) ? "Invalid date" : undefined
                        }
                        autoOk
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        inputVariant="filled"
                        variant="inline"
                        label={"From"}
                        required={true}
                        format="MM/dd/yyyy"
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        size="small"
                        value={state.statisticsConfig.from ?? null}
                        onChange={(value) => {
                          if (isValidDate(value) && value) {
                            value.setHours(0);
                            value.setMinutes(0);
                            value.setSeconds(0);
                            value.setMilliseconds(0);
                            setState((prevState) => ({
                              ...prevState,
                              statisticsConfig: {
                                ...prevState.statisticsConfig,
                                from: value.toISOString()
                              }
                            }));
                            setToggle(undefined);
                          } else {
                            setState((prevState) => ({
                              ...prevState,
                              statisticsConfig: {
                                ...prevState.statisticsConfig,
                                from: value?.toString() as string
                              }
                            }));
                            setToggle(undefined);
                          }
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid xs={4} item style={{ padding: "5px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        id={"statisticsTo"}
                        name={"statisticsTo"}
                        error={!isValidDate(state.statisticsConfig.to)}
                        helperText={
                          !isValidDate(state.statisticsConfig.to) ? "Invalid date" : undefined
                        }
                        autoOk
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        inputVariant="filled"
                        variant="inline"
                        label={"To"}
                        required={true}
                        format="MM/dd/yyyy"
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        size="small"
                        value={state.statisticsConfig.to ?? null}
                        onChange={(value) => {
                          if (isValidDate(value) && value) {
                            value.setHours(23);
                            value.setMinutes(59);
                            value.setSeconds(59);
                            value.setMilliseconds(59);
                            setState((prevState) => ({
                              ...prevState,
                              statisticsConfig: {
                                ...prevState.statisticsConfig,
                                to: value.toISOString()
                              }
                            }));
                            setToggle(undefined);
                          } else {
                            setState((prevState) => ({
                              ...prevState,
                              statisticsConfig: {
                                ...prevState.statisticsConfig,
                                to: value?.toString() as string
                              }
                            }));
                            setToggle(undefined);
                          }
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      padding: "5px",
                      marginLeft: "auto",
                      justifyContent: "flex-end",
                      alignItems: "flex-start",
                      display: "flex"
                    }}
                  >
                    <MonthYearToggle selected={periodToggle} setSelected={handleSetToggle} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ padding: "5px" }}>
                <Grid container item xs={12}>
                  <Grid item xs={3} style={{ padding: "5px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        id={"compareRollbackDate"}
                        name={"Rollback Point Date (Compare)"}
                        error={
                          new Date(
                            state.statisticsConfig.compareRollbackPointDate ?? ""
                          ).getTime() <
                            new Date(state.statisticsConfig.compareFrom ?? "").getTime() ||
                          new Date(
                            state.statisticsConfig.compareRollbackPointDate ?? ""
                          ).getTime() > new Date(state.statisticsConfig.compareTo ?? "").getTime()
                        }
                        helperText={
                          new Date(
                            state.statisticsConfig.compareRollbackPointDate ?? ""
                          ).getTime() <
                            new Date(state.statisticsConfig.compareFrom ?? "").getTime() ||
                          new Date(
                            state.statisticsConfig.compareRollbackPointDate ?? ""
                          ).getTime() > new Date(state.statisticsConfig.compareTo ?? "").getTime()
                            ? "Date should be in between the selected comparing range"
                            : undefined
                        }
                        autoOk
                        fullWidth
                        minDate={state.statisticsConfig.compareFrom}
                        InputLabelProps={{ shrink: true }}
                        inputVariant="filled"
                        variant="inline"
                        label={"Rollb. P. Date (Compare)"}
                        format="MM/dd/yyyy"
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        size="small"
                        value={state.statisticsConfig.compareRollbackPointDate ?? null}
                        onChange={(value) => {
                          if (isValidDate(value) && value) {
                            value.setHours(23);
                            value.setMinutes(59);
                            value.setSeconds(59);
                            value.setMilliseconds(59);
                            setState((prevState) => ({
                              ...prevState,
                              statisticsConfig: {
                                ...prevState.statisticsConfig,
                                compareRollbackPointDate: value.toISOString()
                              }
                            }));
                          }
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid xs={3} item style={{ padding: "5px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        id={"statisticsCompareFrom"}
                        name={"statisticsCompareFrom"}
                        error={
                          state.statisticsConfig.compareFrom
                            ? !isValidDate(state.statisticsConfig.compareFrom)
                            : false
                        }
                        helperText={
                          state.statisticsConfig.compareFrom
                            ? !isValidDate(state.statisticsConfig.compareFrom)
                              ? "Invalid date"
                              : undefined
                            : undefined
                        }
                        autoOk
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        inputVariant="filled"
                        variant="inline"
                        label={"From (Compare)"}
                        format="MM/dd/yyyy"
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        size="small"
                        value={state.statisticsConfig.compareFrom ?? null}
                        onChange={(value) => {
                          if (isValidDate(value) && value) {
                            value.setHours(0);
                            value.setMinutes(0);
                            value.setSeconds(0);
                            value.setMilliseconds(0);
                            setState((prevState) => ({
                              ...prevState,
                              statisticsConfig: {
                                ...prevState.statisticsConfig,
                                compareFrom: value.toISOString()
                              }
                            }));
                            setToggle(undefined);
                          } else {
                            setState((prevState) => ({
                              ...prevState,
                              statisticsConfig: {
                                ...prevState.statisticsConfig,
                                compareFrom: value?.toString() as string
                              }
                            }));
                            setToggle(undefined);
                          }
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid xs={3} item style={{ padding: "5px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        id={"statisticsToCompare"}
                        name={"statisticsToCompare"}
                        error={
                          state.statisticsConfig.compareTo
                            ? !isValidDate(state.statisticsConfig.compareTo)
                            : false
                        }
                        helperText={
                          state.statisticsConfig.compareTo
                            ? !isValidDate(state.statisticsConfig.compareTo)
                              ? "Invalid date"
                              : undefined
                            : undefined
                        }
                        autoOk
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        inputVariant="filled"
                        variant="inline"
                        label={"To (Compare)"}
                        format="MM/dd/yyyy"
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        size="small"
                        value={state.statisticsConfig.compareTo ?? null}
                        onChange={(value) => {
                          if (isValidDate(value) && value) {
                            value.setHours(23);
                            value.setMinutes(59);
                            value.setSeconds(59);
                            value.setMilliseconds(59);
                            setState((prevState) => ({
                              ...prevState,
                              statisticsConfig: {
                                ...prevState.statisticsConfig,
                                compareTo: value.toISOString()
                              }
                            }));
                            setToggle(undefined);
                          } else {
                            setState((prevState) => ({
                              ...prevState,
                              statisticsConfig: {
                                ...prevState.statisticsConfig,
                                compareTo: value?.toString() as string
                              }
                            }));
                            setToggle(undefined);
                          }
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    style={{
                      padding: "5px",
                      marginLeft: "auto",
                      justifyContent: "flex-end",
                      alignItems: "flex-start",
                      display: "flex"
                    }}
                  >
                    <MonthYearToggle
                      selected={periodToggleCompare}
                      setSelected={handleSetToggleCompare}
                      type="compare"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} style={{ padding: "5px" }}>
                <Grid item xs={3} style={{ padding: "5px" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id={"rollbackDate"}
                      name={"Rollback Point Date"}
                      error={
                        new Date(state.statisticsConfig.rollbackPointDate).getTime() <
                          new Date(state.statisticsConfig.from).getTime() ||
                        new Date(state.statisticsConfig.rollbackPointDate).getTime() >
                          new Date(state.statisticsConfig.to).getTime()
                      }
                      helperText={
                        new Date(state.statisticsConfig.rollbackPointDate).getTime() <
                          new Date(state.statisticsConfig.from).getTime() ||
                        new Date(state.statisticsConfig.rollbackPointDate).getTime() >
                          new Date(state.statisticsConfig.to).getTime()
                          ? "Date should be in between the selected range"
                          : undefined
                      }
                      autoOk
                      fullWidth
                      minDate={state.statisticsConfig.from}
                      InputLabelProps={{ shrink: true }}
                      inputVariant="filled"
                      variant="inline"
                      label={"Rollback Point Date"}
                      required={true}
                      format="MM/dd/yyyy"
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      size="small"
                      value={state.statisticsConfig.rollbackPointDate ?? null}
                      onChange={(value) => {
                        if (isValidDate(value) && value) {
                          value.setHours(23);
                          value.setMinutes(59);
                          value.setSeconds(59);
                          value.setMilliseconds(59);
                          setState((prevState) => ({
                            ...prevState,
                            statisticsConfig: {
                              ...prevState.statisticsConfig,
                              rollbackPointDate: value.toISOString()
                            }
                          }));
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3} style={{ padding: "5px" }}>
                  <Tooltip
                    style={{ fontSize: "14px" }}
                    arrow
                    title={
                      <React.Fragment>
                        <Typography variant="subtitle2" style={{ padding: "5px" }}>
                          Minimum amount financed percentage condition in the statistics performance
                          data for a deal to match.
                        </Typography>
                        <Typography variant="subtitle2" style={{ padding: "5px" }}>
                          Deals that match the condition will use 100% of their amount financed.
                        </Typography>
                        <Typography variant="subtitle2" style={{ padding: "5px" }}>
                          If the condition is not met, the deal will be excluded from the
                          estimation.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <TextField
                      id="estimationThresholdAmountPercentage"
                      variant="filled"
                      label={"Est. Thres. (Amount)"}
                      required
                      type="number"
                      size="small"
                      fullWidth
                      autoComplete="new-password"
                      value={thresholds.estimationThresholdAmountPercentage.value}
                      onChange={(event) => {
                        let currentValue = parseFloat(event.target.value);
                        if (currentValue > 100) currentValue = 100;
                        if (currentValue < 1) currentValue = 1;
                        setThresholds((prevThresholds) => ({
                          ...prevThresholds,
                          estimationThresholdAmountPercentage: {
                            ...prevThresholds.estimationThresholdAmountPercentage,
                            value: currentValue
                          }
                        }));

                        if (thresholds.estimationThresholdAmountPercentage.timeout)
                          clearTimeout(thresholds.estimationThresholdAmountPercentage.timeout);

                        if (
                          Number.isNaN(parseFloat(event.target.value)) ||
                          event.target.value === ""
                        ) {
                          setThresholds((prevThresholds) => ({
                            ...prevThresholds,
                            estimationThresholdAmountPercentage: {
                              timeout: setTimeout(() => {
                                setState((prevState) => ({
                                  ...prevState,
                                  statisticsConfig: {
                                    ...prevState.statisticsConfig,
                                    estimationThresholdAmountPercentage: null
                                  }
                                }));
                              }, 1_000),
                              value: null
                            }
                          }));
                        }
                        setThresholds((prevThresholds) => ({
                          ...prevThresholds,
                          estimationThresholdAmountPercentage: {
                            ...prevThresholds.estimationThresholdAmountPercentage,
                            timeout: setTimeout(() => {
                              setState((prevState) => ({
                                ...prevState,
                                statisticsConfig: {
                                  ...prevState.statisticsConfig,
                                  estimationThresholdAmountPercentage: currentValue
                                }
                              }));
                            }, 1_000)
                          }
                        }));
                      }}
                      InputProps={{
                        inputProps: {
                          inputMode: "numeric",
                          pattern: "[1-100]*"
                        },
                        startAdornment: <InputAdornment position="start">%</InputAdornment>
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={3} style={{ padding: "5px" }}>
                  <Tooltip
                    style={{ fontSize: "14px" }}
                    arrow
                    title={
                      <React.Fragment>
                        <Typography variant="subtitle2" style={{ padding: "5px" }}>
                          Minimum deal count condition in a section in the statistics performance
                          data for a deal to match.
                        </Typography>
                        <Typography variant="subtitle2" style={{ padding: "5px" }}>
                          Deals that do not match will traverse the tree backwards until the
                          condition is met.
                        </Typography>
                        <Typography variant="subtitle2" style={{ padding: "5px" }}>
                          If the condition is not met on the top level, the deal will be excluded
                          from the statistics.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <TextField
                      id="estimationThresholdDealCount"
                      variant="filled"
                      label={"Est. Thres. (Deal Count)"}
                      required
                      type="number"
                      size="small"
                      fullWidth
                      autoComplete="new-password"
                      value={thresholds.estimationThresholdDealCount.value}
                      onChange={(event) => {
                        let currentValue = parseFloat(event.target.value);

                        if (currentValue < 0) currentValue = 0;
                        setThresholds((prevThresholds) => ({
                          ...prevThresholds,
                          estimationThresholdDealCount: {
                            ...prevThresholds.estimationThresholdDealCount,
                            value: currentValue
                          }
                        }));

                        if (thresholds.estimationThresholdDealCount.timeout)
                          clearTimeout(thresholds.estimationThresholdDealCount.timeout);

                        if (
                          Number.isNaN(parseFloat(event.target.value)) ||
                          event.target.value === ""
                        ) {
                          setThresholds((prevThresholds) => ({
                            ...prevThresholds,
                            estimationThresholdDealCount: {
                              timeout: setTimeout(() => {
                                setState((prevState) => ({
                                  ...prevState,
                                  statisticsConfig: {
                                    ...prevState.statisticsConfig,
                                    estimationThresholdDealCount: null
                                  }
                                }));
                              }, 1_000),
                              value: null
                            }
                          }));
                        }
                        setThresholds((prevThresholds) => ({
                          ...prevThresholds,
                          estimationThresholdDealCount: {
                            ...prevThresholds.estimationThresholdDealCount,
                            timeout: setTimeout(() => {
                              setState((prevState) => ({
                                ...prevState,
                                statisticsConfig: {
                                  ...prevState.statisticsConfig,
                                  estimationThresholdDealCount: currentValue
                                }
                              }));
                            }, 1_000)
                          }
                        }));
                      }}
                      InputProps={{
                        inputProps: {
                          inputMode: "numeric",
                          pattern: "[0-10000]*"
                        }
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={3} style={{ display: "flex", alignItems: "center", padding: "5px" }}>
                  <Button
                    onClick={() => setRequestEnabled(true)}
                    fullWidth
                    style={{ height: "100%" }}
                    color="primary"
                    variant="contained"
                    disabled={!requestWasChanged || isFetching}
                  >
                    Load Report
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {isFetching ? (
        <Spinner
          text={`Loading: ${
            state.allLenders
              ? `All lenders (May take a minute)`
              : lenders?.map((lender) => lender?.data?.info?.name).join(" ,")
          }`}
          style={{ position: "relative", lineHeight: "20vh", zIndex: 5 }}
        />
      ) : (
        data && (
          <Grid container item xs={12} spacing={1}>
            <Grid container item xs={12} spacing={1} style={{ marginBottom: 10 }}>
              {!compareMode ? (
                <Grid item container xs={4} style={{ minHeight: "628px" }}>
                  <Paper elevation={3} style={{ width: "100%" }}>
                    <Grid item xs={12} style={{ padding: "5px 5px 0px 5px" }}>
                      <Typography
                        style={{ fontSize: "22px", textAlign: "center", fontWeight: "bold" }}
                      >
                        Performance statistics
                      </Typography>
                    </Grid>
                    <EstimationPerformance
                      statusToCompareTo={state.estimationConfig.statusToCompareTo}
                      data={currentData?.data || data.data.estimations}
                      onBack={handleBack}
                      title={currentData?.title}
                      subtitle={currentData?.subtitle}
                      onDiveDeeper={handleDiveDeeper}
                    />
                  </Paper>
                </Grid>
              ) : null}
              {compareMode && (
                <Grid container item xs={6}>
                  <StatisticsTable
                    type="compare"
                    estimatedData={data.data.statistics.comparedEstimatedData as EstimatedData}
                    truthfulDataAfterRollback={
                      data.data.statistics.comparedTruthfulDataAfterRollback as StatisticsData
                    }
                    truthfulData={data.data.statistics.comparedTruthfulData as StatisticsData}
                    selectedStatuses={data.data.request.statusesToCompare}
                    statusToCompareTo={data.data.request.estimationConfig.statusToCompareTo}
                  />
                </Grid>
              )}
              <Grid container item xs={compareMode ? 6 : 8}>
                <StatisticsTable
                  type="current"
                  estimatedData={data.data.statistics.estimatedData}
                  comparedTruthfulDataAfterRollback={
                    data.data.statistics.comparedTruthfulDataAfterRollback
                  }
                  comparedTruthfulData={data.data.statistics.comparedTruthfulData}
                  comparedEstimatedData={data.data.statistics.comparedEstimatedData}
                  truthfulDataAfterRollback={data.data.statistics.truthfulDataAfterRollback}
                  truthfulData={data.data.statistics.truthfulData}
                  selectedStatuses={data.data.request.statusesToCompare}
                  statusToCompareTo={data.data.request.estimationConfig.statusToCompareTo}
                />
              </Grid>
            </Grid>
          </Grid>
        )
      )}
    </Grid>
  );
};
