import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import infoProperties from "./info_properties";

const data = {
  type: "object",
  required: ["info"],
  properties: {
    info: {
      type: "object",
      required: ["name"],
      properties: infoProperties,
      additionalProperties: true,
    },
  },
  additionalProperties: true,
};
export default {
  new_role: {
    $id: "newRole",
    type: "object",
    required: ["data"],
    properties: {
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
    additionalProperties: true,
  },
  update_role: {
    $id: "updateRole",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      approved: {
        type: "boolean",
        nullable: true,
      },
      approvedAt: {
        type: "string",
        nullable: true,
      },
      approvedByUserId: {
        type: "string",
        nullable: true,
      },
    },
    additionalProperties: true,
  },
  delete_role: {
    $id: "deleteRole",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  recover_role: {
    $id: "recoverRole",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  get_role: {
    $id: "getRole",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  all_roles: {
    $id: "allRoles",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
  current_user_permissions: { $id: "currentUserPermissions", type: "object" },
  all_permissions: { $id: "allPermissions", type: "object" },
};
