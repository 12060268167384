import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface ChangeUserPINRequest {
  data: {
    newPIN: string;
    oldPIN: string;
  };
}

interface ChangeUserPINResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  ChangeUserPINRequest,
  ChangeUserPINResponse
>("ChangeUserPin", {
  request: "change_user_pin"
});

export const changeUserPinAction = socketMessageSlice.actions;
export const changeUserPin = socketAction;
export default socketMessageSlice.reducer;
