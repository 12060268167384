import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

const data = {
  type: "object",
  required: ["info"],
  properties: {
    info: {
      type: "object",
      required: ["type"],
      properties: {
        type: {
          type: "string",
        },
      },
    },
  },
};

export default {
  new_contract_type: {
    $id: "newContractType",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_contract_type: {
    $id: "updateContractType",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      approved: {
        type: "boolean",
        nullable: true,
      },
      approvedAt: {
        type: "string",
        nullable: true,
      },
      approvedByUserId: {
        type: "string",
        nullable: true,
      },
    },
  },
  delete_contract_type: {
    $id: "deleteContractType",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  recover_contract_type: {
    $id: "recoverContractType",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_contract_type: {
    $id: "getContractType",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_contract_types: {
    $id: "allContractsTypes",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
