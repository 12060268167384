import { createListSlice } from "../../Middlewares/listSliceCreator";
import { LenderNote } from "./types";

const { ListSlice, getList } = createListSlice<LenderNote>({
  name: "LenderNoteList",
  request_topic: "all_lender_notes",
  reducers: {}
});
export default ListSlice.reducer;
export const getLenderNotesList = getList;
export const listLenderNotesActions = ListSlice.actions;
export const removeLenderNotesList = ListSlice.actions.removeList;
