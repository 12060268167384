import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@material-ui/core";
import { getPrefixByState } from "utils/functions";
import CloseDialogButton from "components/common/CloseDialogButton";

type Props = {
  street: string;
  state: string;
  city: string;
  coordinates: {
    lat: number;
    lon: number;
  };
  type: "applicant" | "lender" | "dealership";
};

export default ({ street, state, city, coordinates, type }: Props) => {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      {open && type === "applicant" && (
        <Dialog
          id={`static-location-map-${type}`}
          open={true}
          fullWidth
          maxWidth="md"
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Location
            <CloseDialogButton closeFunction={() => setOpen(false)} />
          </DialogTitle>
          <DialogContent>
            <Grid container xs={12}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <img
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${street},${city},${getPrefixByState(
                    state
                  )}&zoom=15&size=600x400&maptype=roadmap
                    &markers=color:red%7C${coordinates?.lat},${coordinates?.lon}
                    &key=${process.env.REACT_APP_GOOGLE_STATIC_MAPS_API_KEY}`}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              color="primary"
              id={`close-location-map-${type}`}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Grid container xs={12}>
        <Grid item xs={12}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography
              style={{
                fontWeight: "bold",
                color: "rgb(37, 78, 110)",
                fontSize: "19px",
                margin: "5px 0px 10px"
              }}
            >
              Location
            </Typography>
            {type === "applicant" && (
              <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Show on map
              </Button>
            )}
          </div>
        </Grid>
        {type !== "applicant" && (
          <Grid item xs={12}>
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${street},${city},${getPrefixByState(
                state
              )}&zoom=15&size=600x300&maptype=roadmap
            &markers=color:red%7C${coordinates.lat},${coordinates.lon}
            &key=${process.env.REACT_APP_GOOGLE_STATIC_MAPS_API_KEY}`}
            />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};
