import { createListSlice } from "../../Middlewares/listSliceCreator";
import { BusinessReport } from "./types";

const { ListSlice, getList } = createListSlice<BusinessReport>({
  name: "BusinessReportList",
  request_topic: "all_business_reports",
  reducers: {}
});

export default ListSlice.reducer;
export const getBusinessReportList = getList;
export const listBusinessReporActions = ListSlice.actions;
export const removeBusinessReporrList = ListSlice.actions.removeList;
