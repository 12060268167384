import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverChargebackRequest {
  _id: string;
}

export interface RecoverChargebackResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverChargebackRequest,
  RecoverChargebackResponse
>("RecoverChargeback", {
  request: "recover_chargeback"
});

export const recoverChargebackActions = socketMessageSlice.actions;
export const recoverChargeback = socketAction;
export default socketMessageSlice.reducer;
