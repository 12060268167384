const data = {
  type: "object",
  nullable: true,
  properties: {
    info: {
      type: "object",
      nullable: true,
      properties: {
        dealVariant: {
          type: "array",
          items: { type: "string", nullable: true },
        },
        dealId: { type: "string", nullable: true },
        lenderId: { type: "string", nullable: true },
        collateralType: {
          type: "string",
          nullable: true,
          enum: [
            "Automotive",
            "Recreational Vehicles",
            "Marine",
            "Power Sport",
          ],
          errorMessage: {
            enum: "must be one of the following: 'Automotive, 'Recreational Vehicles, 'Marine', 'Power Sport'",
          },
        },
        printedDocuments: {
          type: "array",
          items: { type: "string", nullable: true },
        },
      },
    },
  },
};

export default {
  new_delivered_deal: {
    $id: "newDeliveredDeal",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
    additionalProperties: false,
  },
  update_delivered_deal: {
    $id: "newDeliveredDeal",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
    additionalProperties: false,
  },
};
