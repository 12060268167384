import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState, FormStateToSend } from "./types";

interface EditExternalCreditApplicationData {
  _id: string;
  data: FormState;
}
interface EditExternalCreditApplicationDataToSend {
  _id: string;
  data: FormStateToSend;
}

export interface DealResponse {}

interface EditExternalCreditApplicationResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  };
}
const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditExternalCreditApplicationDataToSend,
  EditExternalCreditApplicationResponse
>("EditExternalCreditApplication", {
  request: "update_external_credit_application"
});

export const editExternalCreditApplicationActions = socketMessageSlice.actions;
export const editExternalCreditApplication = (
  request: EditExternalCreditApplicationData & Identifiable
) => {
  const { dealership, payoffBank, payoffBankId, user, dealType, ...data } = request.data;
  return socketAction({
    ...request,
    data: {
      ...data,
      dealershipId: request.data.dealership._id,
      payoffBankId: request.data?.payoffBank?._id ?? payoffBankId
    }
  });
};
export default socketMessageSlice.reducer;
