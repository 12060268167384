import { FormComponent, Model } from "utils/models/fields";

import { FloorplanXpress } from "./types";
export const floorplanXpressData: FormComponent<FloorplanXpress> = {
  formComponent: "segment",
  name: "Floorplann Xpress Info",
  width: "full",
  entities: [
    {
      formComponent: "read-only-field",
      name: "vin",
      label: "Vin",
      width: "full",
      required: true,
      path: ["data", "info", "data", "VIN"],
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "year",
      label: "Year",
      width: "1/2",
      required: true,
      path: ["data", "info", "data", "year"],
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "make",
      label: "Make",
      width: "1/2",
      required: true,
      path: ["data", "info", "data", "make"],
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "model",
      label: "Model",
      width: "1/2",
      required: true,
      path: ["data", "info", "data", "model"],
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "color",
      label: "Color",
      width: "1/2",
      required: true,
      path: ["data", "info", "data", "color"],
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "titleStatus",
      label: "Title status",
      width: "1/2",
      required: true,
      path: ["data", "info", "data", "titleStatus"],
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "payoffToday",
      label: "Payoff today",
      width: "1/2",
      required: true,
      path: ["data", "info", "data", "payoffToday"],
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "payoffTwoDays",
      label: "Payoff two days",
      width: "1/2",
      required: true,
      path: ["data", "info", "data", "payoffTwoDays"],
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "dealer",
      label: "Dealer",
      width: "full",
      required: true,
      path: ["data", "info", "data", "dealer"],
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "branchEmail",
      label: "Branch email",
      width: "1/2",
      required: true,
      path: ["data", "info", "data", "branchEmail"],
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "branchPhone",
      label: "Branch phone",
      width: "1/2",
      required: true,
      path: ["data", "info", "data", "branchPhone"],
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "note",
      label: "Note",
      width: "full",
      required: true,
      path: ["data", "info", "data", "note"],
      default: null
    }
  ]
};

export const floorplanXpressStruct: Model<FloorplanXpress> = {
  formComponent: "model",
  schema: "get_floorplanxpress",
  name: "floorplanxpress",
  entities: [{ formComponent: "segment", entities: [floorplanXpressData] }]
};
