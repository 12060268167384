import React, { Dispatch, useEffect, useState } from "react";
import { getByPath, setByPath, StateAccess } from "utils/models/formGenerator";
import { createOptionsForSelect } from "utils/models/fields";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { editRenderSet } from "utils/models/formRenderers";
import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import { FundingDocument } from "components/FundingDocuments/types";
import { Deal } from "../types";
import {
  approvePrintedDocument,
  approvePrintedDocumentActions
} from "components/PrintedDocuments/approvePrintedDocumentSlice";
import {
  rejectPrintedDocument,
  rejectPrintedDocumentActions
} from "components/PrintedDocuments/rejectPrintedDocumentSlice";
import { SnackbarMessage, OptionsObject, SnackbarKey, useSnackbar } from "notistack";
import { RequiredDocumentStatus } from "components/PrintedDocuments/types";
import { recongizeFundingDocument } from "components/FundingDocuments/recognizeFundingDocumentSlice";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import RejectDialog from "./RejectDialog";

interface Props {
  deal: Deal;
  pageNumber: number;
  fundingDocument?: FundingDocument;
  isLastPage: boolean;
  recognized: boolean;
}

interface PrintedDocumentsAndPages {
  printedDocumentId?: string;
  page?: number;
}

export const handleApprovePrintedDocument = (
  dispatch: Dispatch<any>,
  listId: string,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  printedDocumentId?: string
) => {
  if (printedDocumentId)
    dispatch(
      approvePrintedDocument({
        requestId: listId,
        _id: printedDocumentId
      })
    );
  else
    enqueueSnackbar(`Please select document type.`, {
      variant: "error"
    });
};
export const handleRejectPrintedDocument = (
  dispatch: Dispatch<any>,
  listId: string,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  note: string,
  userId: string,
  printedDocumentId?: string
) => {
  if (printedDocumentId)
    dispatch(
      rejectPrintedDocument({
        requestId: listId,
        _id: printedDocumentId,
        data: {
          info: {
            rejectionInfo: {
              note,
              userId
            }
          }
        }
      })
    );
  else
    enqueueSnackbar(`Please select document type.`, {
      variant: "error"
    });
};

export default function RecognizeFundingDocumentPageForm({
  deal,
  pageNumber,
  fundingDocument,
  isLastPage,
  recognized
}: Props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const listId = `approve-reject-file-${fundingDocument?._id ?? ""}${pageNumber}`;
  const printedDocumentslistId = `printed-documents-${deal._id}`;

  const printedDocumentList = deal.data?.printedDocuments;

  const [dialogState, setDialogState] = useState(false);

  const { user } = useSelector((state: RootState) => state.authSlice);

  fundingDocument = useSelector(
    (state: RootState) => state.listFundingDocumentSlice[deal._id] || []
  )?.entities?.find((x) => x._id === fundingDocument?._id);

  const [printedDocumentsAndPages, setPrintedDocumentsAndPages] = useState<
    PrintedDocumentsAndPages
  >({
    page: fundingDocument?.data?.uploadedDocumentStatus?.pages?.[pageNumber]?.page,
    printedDocumentId:
      fundingDocument?.data?.uploadedDocumentStatus?.pages?.[pageNumber]?.printedDocumentId
  });
  const printedDocument = printedDocumentList?.find(
    (x) => x._id === printedDocumentsAndPages?.printedDocumentId
  );
  const selectedPrintedDocumentStateAccess: StateAccess = {
    get: (path) => getByPath(printedDocumentsAndPages, path),
    set: (path, value): any => {
      setPrintedDocumentsAndPages((state) => setByPath(state, path, value));
    }
  };

  const handleRecongizeFundingDocument = (unuseful = false) => {
    if (
      printedDocumentsAndPages.page &&
      printedDocumentsAndPages.printedDocumentId &&
      fundingDocument
    )
      dispatch(
        recongizeFundingDocument({
          requestId: printedDocumentslistId,
          _id: fundingDocument._id,
          fundingDocumentPage: pageNumber,
          printedDocumentPage: printedDocumentsAndPages.page,
          printedDocumentId: printedDocumentsAndPages.printedDocumentId,
          unuseful
        })
      );
  };
  const handleRecongizeAsUnusefullFundingDocument = () => {
    if (fundingDocument)
      dispatch(
        recongizeFundingDocument({
          requestId: printedDocumentslistId,
          _id: fundingDocument._id,
          fundingDocumentPage: pageNumber,
          unuseful: true
        })
      );
  };

  const approvePrintedDocumentSlice = useSelector(
    (state: RootState) => state.approvePrintedDocumentSlice[listId]
  );
  const rejectPrintedDocumentSlice = useSelector(
    (state: RootState) => state.rejectPrintedDocumentSlice[listId]
  );

  useEffect(() => {
    if (approvePrintedDocumentSlice?.status === "success") {
      enqueueSnackbar("Successfully approved document.", { variant: "success" });
      dispatch({
        type: approvePrintedDocumentActions.none.type,
        payload: { requestId: listId }
      });
    }
    if (rejectPrintedDocumentSlice?.status === "success") {
      enqueueSnackbar("Successfully rejected document.", { variant: "success" });
      dispatch({
        type: rejectPrintedDocumentActions.none.type,
        payload: { requestId: listId }
      });
    }
  }, [approvePrintedDocumentSlice?.status, rejectPrintedDocumentSlice?.status]);

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={3}>
        {editRenderSet(false).selectInputRenderer(
          {
            formComponent: "select-field",
            name: `Required ${pageNumber}`,
            label: "Required Document",
            path: ["printedDocumentId"],
            options: createOptionsForSelect({
              possibleValues: () => printedDocumentList?.map((x) => x._id) ?? [],
              getOptionLabel: (_id) => {
                return (
                  printedDocumentList?.find((x) => x._id === _id)?.data?.info.name ?? "No label"
                );
              },
              getSelectedOption: (x, y) => {
                return x === y;
              }
            }),
            required: true
          },
          selectedPrintedDocumentStateAccess,
          ["printedDocumentId"],
          selectedPrintedDocumentStateAccess,
          editRenderSet(false)
        )}
      </Grid>
      <Grid item xs={3}>
        {editRenderSet(false).selectInputRenderer(
          {
            formComponent: "select-field",
            name: `Page ${pageNumber}`,
            label: "Page",
            path: ["page"],
            options: createOptionsForSelect({
              possibleValues: () => [...Array(20).keys()].map((x) => x + 1),
              getOptionLabel: (page) => {
                return page?.toString() ?? "";
              },
              getSelectedOption: (x, y) => {
                return x === y;
              }
            }),
            required: true
          },
          selectedPrintedDocumentStateAccess,
          ["page"],
          selectedPrintedDocumentStateAccess,
          editRenderSet(false)
        )}
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        <Button
          onClick={() => handleRecongizeFundingDocument()}
          disabled={!printedDocumentsAndPages.page || !printedDocumentsAndPages.printedDocumentId}
          color="primary"
          size="large"
          variant="contained"
        >
          Recognize
        </Button>
      </Grid>
      <Grid item xs={1} style={{ textAlign: "center" }}>
        <Tooltip placement="top" title="Recognize and mark page as unuseful.">
          <IconButton
            id={`mark-as-unuseful-${pageNumber}`}
            onClick={() => handleRecongizeAsUnusefullFundingDocument()}
          >
            <DeleteForeverIcon color="secondary" fontSize="large" />
          </IconButton>
        </Tooltip>
      </Grid>
      {recognized &&
        isLastPage &&
        ![undefined, RequiredDocumentStatus.approved, RequiredDocumentStatus.rejected].includes(
          printedDocument?.data.info.status
        ) && (
          <Grid item xs={4}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  style={{ margin: 4, background: "#50a538" }}
                  onClick={() =>
                    handleApprovePrintedDocument(
                      dispatch,
                      listId,
                      enqueueSnackbar,
                      printedDocument?._id
                    )
                  }
                  color="primary"
                  size="large"
                  variant="contained"
                >
                  Approve
                </Button>
              </Grid>
              <Grid item xs={6}>
                <RejectDialog
                  setDialogState={setDialogState}
                  dialogState={dialogState}
                  RejectPrintedDocumentFunction={(note: string) => {
                    handleRejectPrintedDocument(
                      dispatch,
                      listId,
                      enqueueSnackbar,
                      note,
                      user?.databaseData?._id as string,
                      printedDocument?._id
                    );
                  }}
                />
                <Button
                  fullWidth
                  style={{ margin: 4 }}
                  onClick={() => setDialogState(true)}
                  color="secondary"
                  size="large"
                  variant="contained"
                >
                  Reject
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      {printedDocument?.data.info.status === RequiredDocumentStatus.approved && (
        <Grid
          item
          xs={3}
          style={{
            border: "2px dashed green",
            borderRadius: "4px",
            padding: "5px",
            color: "green",
            fontWeight: "bold"
          }}
        >
          Approved
        </Grid>
      )}
      {printedDocument?.data.info.status === RequiredDocumentStatus.rejected && (
        <Grid
          item
          xs={3}
          style={{
            border: "2px dashed red",
            borderRadius: "4px",
            padding: "5px",
            color: "red",
            fontWeight: "bold"
          }}
        >
          Rejected
        </Grid>
      )}
    </Grid>
  );
}
