import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DealershipSendGeneratedLinkRequest {
  _id: string;
}

export interface DealershipSendGeneratedLinkResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DealershipSendGeneratedLinkRequest,
  DealershipSendGeneratedLinkResponse
>("SendGeneratedLinkDealership", {
  request: "dealership_send_generated_link"
});

export const DealershipSendGeneratedLinkActions = socketMessageSlice.actions;
export const DealershipSendGeneratedLink = socketAction;
export default socketMessageSlice.reducer;
