import { CustomRegexes } from "../../helpers";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";

const data = {
  type: "object",
  properties: {
    requestId: { type: "string", nullable: true },
    query: allQuerySchemaProperties.query,
    years: {
      type: "array",
      nullable: true,
      items: {
        type: "string",
        pattern: CustomRegexes.NUMBERS,
        errorMessage: { pattern: "should not contain letters" },
      },
    },
  },
};

export default {
  get_income_report: {
    $id: "income_report",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
};
