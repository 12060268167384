import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Big from "big.js";

interface StyleProps {
  percents: number[];
  colors: string[];
  progressContainerColor: string;
}

const adjustPercents = (percents: number[]) => {
  const totalPercent = percents.reduce((acc, cur) => acc + cur, 0);
  if (totalPercent <= 100) {
    return percents;
  } else {
    return percents.map((percent) => (percent / totalPercent) * 100);
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progressBar: {
      position: "relative",
      height: "15px",
      zIndex: 998
    },
    progressBarContainer: (props: StyleProps) => ({
      background: props.progressContainerColor,
      height: "15px",
      position: "relative",
      width: "100%"
    }),
    text: {
      zIndex: 999,
      fontSize: 14,
      fontWeight: 500,
      position: "absolute"
    },
    alignItemsCneter: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  })
);

type Props = {
  percents: number[];
  colors?: string[];
  onClick?: (props: any) => void;
  progressContainerColor?: string;
};

const ProgressBar = ({
  percents,
  onClick,
  colors = ["#51bde3", "#f0d855"],
  progressContainerColor = "#52a543"
}: Props) => {
  const realPercents = percents.filter((x) => x);
  const adjustedPercents = adjustPercents(realPercents);
  const classes = useStyles({ percents: realPercents, colors, progressContainerColor });
  return (
    <div onClick={onClick} className={classes.alignItemsCneter}>
      <div className={classes.progressBarContainer}>
        {adjustedPercents.map((adjustedPercent, index) => (
          <div
            key={index}
            className={classes.progressBar}
            style={{
              background: colors[index],
              width: `${adjustedPercent}%`,
              position: "absolute",
              top: 0,
              left:
                index > 0
                  ? `${adjustedPercents
                      .slice(0, index)
                      .reduce((sum, x) => new Big(sum).add(new Big(x)).toNumber(), 0)}%`
                  : 0
            }}
          />
        ))}
        {realPercents.map((percent, index) => (
          <div
            key={index}
            style={{
              color: colors[index],
              textShadow: `${colors[index]} 0px 0px 1px`,
              left: `${
                adjustedPercents
                  .slice(0, index)
                  .reduce((sum, x) => new Big(sum).add(new Big(x)).toNumber(), 0) +
                adjustedPercents[index]
              }%`,

              position: "absolute",
              top: index % 2 === 0 ? -15 : 15
            }}
            className={classes.text}
          >
            {`${new Big(
              realPercents
                .slice(0, index)
                .reduce((sum, x) => new Big(sum).add(new Big(x)).toNumber(), 0)
            )
              .add(new Big(percent))
              .toFixed(2)}%`}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
