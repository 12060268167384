import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Note } from "./types";
import { RootState } from "app/rootReducer";
import { v4 as uuidv4 } from "uuid";
import formEditContext from "components/Content/FormEditContext";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { HintTooltip } from "components/common/HintTooltip";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelCircleIcon from "@material-ui/icons/Cancel";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { deleteNote } from "./deleteNoteSlice";
import { addNote } from "./addNoteSlice";
import { editNote } from "./editNoteSlice";
import formatDate from "utils/formatDate";
import { getNotesList, listNotesActions } from "./listNoteSlice";
import { Help } from "@material-ui/icons";
import CloseDialogButton from "components/common/CloseDialogButton";

type PropsWithId = {
  type: "deal" | "lenderTicket" | "dealershipTicket" | "titleIssue";
  notes: Note[];
  editable?: boolean;
} & (
  | { dealId: string }
  | { lenderTicketId: string }
  | { dealershipTicketId: string }
  | { titleIssueId: string }
);

export default function NotesPreview(props: PropsWithId): JSX.Element {
  let id: string;
  if ("dealId" in props) {
    id = props.dealId;
  } else if ("lenderTicketId" in props) {
    id = props.lenderTicketId;
  } else if ("dealershipTicketId" in props) {
    id = props.dealershipTicketId;
  } else if ("titleIssueId" in props) {
    id = props.titleIssueId;
  }
  const dispatch = useDispatch();

  const [addRequestId] = React.useState(uuidv4());
  const [deleteRequestId] = React.useState(uuidv4());
  const [deleteDialog, setDeleteDialog] = React.useState<{ open: Boolean; note: Note | undefined }>(
    { open: false, note: undefined }
  );
  const [timeout, setTimeoutState] = React.useState<undefined | NodeJS.Timeout>();

  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);

  const addNoteState = useSelector((state: RootState) => state.addNoteSlice[addRequestId]);

  const deleteNoteState = useSelector((state: RootState) => state.deleteNoteSlice[deleteRequestId]);
  const listNoteState = useSelector((state: RootState) => state.listNoteSlice[id]);
  const allEditNoteStates = useSelector((state: RootState) => state.editNoteSlice);
  const editNoteStates = listNoteState?.entities?.map((note) => ({
    _id: note._id,
    state: allEditNoteStates[note._id]
  }));
  const isLoading = addNoteState?.status === "waiting" || deleteNoteState?.status === "waiting";

  const { enabled: editMode } = React.useContext(formEditContext);
  const canEdit = editMode || props.editable;
  React.useEffect(() => {
    if (!listNoteState && canEdit)
      dispatch(
        getNotesList(id, {
          query: { deleted: false, [`data.${props.type}Id`]: id },
          options: { sort: { createdAt: -1 } }
        })
      );
  }, [dispatch, canEdit]);

  React.useEffect(() => {
    if (
      deleteNoteState !== undefined &&
      deleteNoteState.status === "success" &&
      deleteNoteState.data !== null
    ) {
      const index = listNoteState.entities?.findIndex(
        (n: Note) => n._id === deleteNoteState?.data?.message?._id
      );
      dispatch({
        type: listNotesActions.editList.type,

        payload: { index: index, payload: deleteNoteState?.data?.message, listId: id }
      });
    }
  }, [deleteNoteState, dispatch]);
  const handleAdd = () => {
    const defaultNote = {
      data: {
        ...(props.type === "deal" ? { dealId: id } : {}),
        ...(props.type === "lenderTicket" ? { lenderTicketId: id } : {}),
        ...(props.type === "dealershipTicket" ? { dealershipTicketId: id } : {}),
        ...(props.type === "titleIssue" ? { titleIssueId: id } : {}),

        info: {
          type: "default",
          note: "",
          user: {
            firstName: currentUser?.data?.info?.firstName || null,
            lastName: currentUser?.data?.info?.lastName || null,
            email: currentUser?.data?.info?.email || null
          }
        }
      }
    };
    dispatch(addNote({ requestId: addRequestId, ...(defaultNote as Note) }));
  };

  const handleDelete = (note: Note) => {
    dispatch(deleteNote({ requestId: deleteRequestId, _id: note._id }));
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    note: Note & { index: number }
  ) => {
    const newNote = {
      ...note,
      data: { ...note.data, info: { ...note.data.info, note: event.target.value } },
      updatedAt: new Date().toISOString()
    };
    dispatch({
      type: listNotesActions.editList.type,

      payload: { index: note.index, payload: newNote, listId: id }
    });
    return newNote;
  };
  const handleUpdate = (note: Note) => {
    dispatch(editNote({ requestId: note._id, ...note }));
  };
  const renderEntities = () => {
    return (listNoteState?.entities ?? props.notes)
      .map((n, index) => ({ ...n, index }))
      .filter((n) => !n.deleted)
      .sort((a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime())
      ?.map((note, index) => {
        const state = editNoteStates?.find((state) => state._id === note._id)?.state;
        return (
          <Paper
            key={note._id}
            style={{
              padding: "10px",
              marginBottom: "20px",
              display: "block",
              background: state?.status === "error" ? "#ffe3e3" : undefined
            }}
          >
            <Box display="flex" alignContent="flex-start">
              <Box flexGrow={1}>
                <TextField
                  multiline
                  onChange={(event) => {
                    if (timeout) {
                      clearTimeout(timeout);
                    }
                    const newNote = handleChange(event, note);
                    setTimeoutState(
                      setTimeout(() => {
                        handleUpdate(newNote);
                      }, 500)
                    );
                  }}
                  value={note?.data?.info?.note}
                  name="note"
                  inputProps={
                    canEdit && listNoteState?.status !== "waiting"
                      ? {}
                      : {
                          readOnly: true
                        }
                  }
                  placeholder="Note"
                  fullWidth
                  label={"Note"}
                  variant={canEdit && listNoteState?.status !== "waiting" ? "filled" : "outlined"}
                  size="small"
                  onBlur={(event) => {
                    if (event.target.value !== note?.data?.info?.note) {
                      const newNote = handleChange(event, note);
                      handleUpdate(newNote);
                    }
                  }}
                />
                <Box
                  fontSize={12}
                  justifyContent="space-between"
                  display={"flex"}
                  marginTop={"10px"}
                  style={{ maxHeight: 20 }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {(() => {
                      switch (state?.status) {
                        case "waiting":
                          return (
                            <>
                              <CircularProgress
                                size={17}
                                style={{
                                  color: "#50A538",
                                  marginRight: 5,
                                  minHeight: 20.5,
                                  maxHeight: 20.5
                                }}
                              />
                              Saving
                            </>
                          );
                        case "error":
                          return (
                            <button
                              className="pulse hover"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                border: "none",
                                background: "#ffe3e3",
                                fontWeight: "bold",
                                borderRadius: 3
                              }}
                              onClick={() => {
                                handleUpdate(note);
                              }}
                            >
                              <CancelCircleIcon
                                style={{
                                  color: "#E34C28",
                                  marginRight: 5
                                }}
                              />
                              Error! Click here to retry!
                            </button>
                          );

                        default:
                          return (
                            <>
                              <CheckCircleIcon
                                style={{
                                  color: "#50A538",
                                  marginRight: 5
                                }}
                              />
                              Saved
                            </>
                          );
                      }
                    })()}
                  </div>

                  <div style={{ color: "#8c8c8c" }}>
                    {[
                      note.data?.info?.user?.firstName,
                      note?.data?.info?.user?.lastName,
                      formatDate(note?.createdAt, "medium", true)
                    ]
                      .filter((x) => x)
                      .join(" ")}
                  </div>
                </Box>
              </Box>
              {canEdit && (
                <Box>
                  <IconButton
                    style={{
                      color: "#E34C28"
                    }}
                    key={index}
                    aria-label={`remove note`}
                    onClick={() => setDeleteDialog({ open: true, note })}
                    disabled={deleteNoteState?.status === "waiting"}
                  >
                    <HintTooltip title={`Click here to remove the note.`}>
                      <RemoveCircleIcon />
                    </HintTooltip>
                  </IconButton>
                </Box>
              )}
            </Box>
          </Paper>
        );
      });
  };
  return (
    <Paper
      elevation={0}
      style={{
        breakInside: "avoid",
        pageBreakInside: "avoid",
        transform: "translateZ(1)"
      }}
    >
      <Box style={{ display: "flex", alignItems: "center" }}>
        <DialogContentText
          style={{
            color: "#254e6e",
            fontSize: "19px",
            margin: "5px 0px 5px 0px",
            fontWeight: "bold"
          }}
        >
          Notes (Internal Only)
        </DialogContentText>
        <HintTooltip
          style={{ color: "#254e6e", fontSize: "20px", marginLeft: 4 }}
          title={
            <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
              Autosaved / Internal Only
            </div>
          }
        >
          <Help />
        </HintTooltip>
        {canEdit && (
          <Box>
            <IconButton
              style={{
                color: "#50A538"
              }}
              aria-label={`add new Note`}
              onClick={handleAdd}
              disabled={isLoading}
            >
              <HintTooltip title={`Click here to add new Note.`}>
                {isLoading ? <CircularProgress size={19} /> : <AddCircleIcon />}
              </HintTooltip>
            </IconButton>
          </Box>
        )}
      </Box>

      {renderEntities()}
      {deleteDialog.open && (
        <Dialog
          id="delete-note-dialog"
          open={true}
          onClose={() => setDeleteDialog({ open: false, note: undefined })}
        >
          <DialogTitle id="delete-note-dialog-title">
            <CloseDialogButton
              closeFunction={() => setDeleteDialog({ open: false, note: undefined })}
            />
            <Typography variant="h6">Delete note</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle2" style={{ fontSize: "14px" }}>
              Are you sure you want to delete this note?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDeleteDialog({ open: false, note: undefined })}
              variant="contained"
              color="primary"
              id="cancel"
            >
              Close
            </Button>
            <Button
              onClick={() => {
                if (deleteDialog.note) {
                  handleDelete(deleteDialog.note);
                  setDeleteDialog({ open: false, note: undefined });
                }
              }}
              variant="contained"
              autoFocus
              color="secondary"
              id="confirm"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  );
}
