import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import {
  dealSourceToLabel,
  projections as defaultDealProjections,
  getStyleByStatus,
  statusToChip,
  statusToChipTooltip,
  statusToLabel
} from "components/Deals/Deals";
import { getDealList } from "components/Deals/listDealSlice";
import { DealData, DealSourceTypes } from "components/Deals/types";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DateFilter from "components/Filters/DateFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import PriceFilter from "components/Filters/PriceFilter";
import TextFilter from "components/Filters/TextFilter";
import UserFilter from "components/Filters/UserFilter";
import { Column } from "components/GroupedTable";
import { TableGroupByItem } from "components/GroupedTable/ColumnGroups/types";

import Table, { CellValue } from "components/Table";
import CloseDialogButton from "components/common/CloseDialogButton";
import React from "react";
import { useSelector } from "react-redux";
import { DealStatus } from "utils/dealStateMachine/dealStateMachine";
import { getSalesRepDeals } from "./salesRepDealsSlice";
import { capitalize, formatNumberAsCurrency } from "utils/functions";
import { getDealListByIds } from "components/Deals/listDealSliceByIds";

interface ModalData {
  period: string;
  dealIds: string[];
}
interface Props {
  dealIds?: string[];
  tableGroupBy: TableGroupByItem[];
}
const columns: Column<DealData>[] = [
  {
    getData: (entry): CellValue =>
      [
        entry.data?.applicant
          ? `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
              entry.data?.applicant?.data?.info?.lastName ?? ""
            }`
          : undefined,
        entry.data?.coApplicant
          ? `${entry.data?.coApplicant?.data?.info?.firstName ?? ""} ${
              entry.data?.coApplicant?.data?.info?.lastName ?? ""
            }`
          : undefined
      ]
        .filter((x) => x)
        .join(" / "),
    label: "Customer",
    name: "customer",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.customer?.show;
    },
    filters: [
      {
        path: [
          ["data", "applicant", "data", "info", "firstName"],
          ["data", "applicant", "data", "info", "lastName"],
          ["data", "coApplicant", "data", "info", "firstName"],
          ["data", "coApplicant", "data", "info", "lastName"]
        ],
        preview: ApplicantFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.applicant?.data?.info?.currentCity ?? "N/A";
    },
    label: "Applicant city",
    options: {
      sort: true,
      path: ["data", "applicant", "data", "info", "currentCity"]
    },
    name: "applicantCurrentCity",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicantCurrentCity?.show;
    },
    filters: [
      {
        path: ["data", "applicant", "data", "info", "currentCity"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.applicant?.data?.info?.currentState ?? "N/A";
    },
    label: "Applicant state",
    options: {
      sort: true,
      path: ["data", "applicant", "data", "info", "currentState"]
    },
    name: "applicantCurrentState",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicantCurrentState?.show;
    },
    filters: [
      {
        path: ["data", "applicant", "data", "info", "currentState"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.applicant?.data?.info?.currentZipCode ?? "N/A";
    },
    label: "Applicant zip",
    options: {
      sort: true,
      path: ["data", "applicant", "data", "info", "currentZipCode"]
    },
    name: "applicantCurrentZipCode",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicantCurrentZipCode?.show;
    },
    filters: [
      {
        path: ["data", "applicant", "data", "info", "currentZipCode"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.applicant?.data?.info?.currentCounty;
    },
    label: "Applicant county",
    options: {
      sort: true,
      path: ["data", "applicant", "data", "info", "currentCounty"]
    },
    name: "applicantCurrentCounty",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicantCurrentCounty?.show;
    },
    filters: [
      {
        path: ["data", "applicant", "data", "info", "currentCounty"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.coApplicant?.data?.info?.currentCity ?? "N/A";
    },
    label: "Co-Applicant city",
    options: {
      sort: true,
      path: ["data", "coApplicant", "data", "info", "currentCity"]
    },
    name: "coApplicantCurrentCity",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.coApplicantCurrentCity?.show;
    },
    filters: [
      {
        path: ["data", "coApplicant", "data", "info", "currentCity"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.coApplicant?.data?.info?.currentState ?? "N/A";
    },
    label: "Co-Applicant state",
    options: {
      sort: true,
      path: ["data", "coApplicant", "data", "info", "currentState"]
    },
    name: "coApplicantCurrentState",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.coApplicantCurrentState?.show;
    },
    filters: [
      {
        path: ["data", "coApplicant", "data", "info", "currentState"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.coApplicant?.data?.info?.currentZipCode ?? "N/A";
    },
    label: "Co-Applicant zip",
    options: {
      sort: true,
      path: ["data", "coApplicant", "data", "info", "currentZipCode"]
    },
    name: "coApplicantCurrentZipCode",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.coApplicantCurrentZipCode?.show;
    },
    filters: [
      {
        path: ["data", "coApplicant", "data", "info", "currentZipCode"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.coApplicant?.data?.info?.currentCounty ?? "N/A";
    },
    label: "Co-Applicant county",
    options: {
      sort: true,
      path: ["data", "coApplicant", "data", "info", "currentCounty"]
    },
    name: "coApplicantCurrentCounty",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.coApplicantCurrentCounty?.show;
    },
    filters: [
      {
        path: ["data", "coApplicant", "data", "info", "currentCounty"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.dealership?.data?.info?.displayName ?? "N/A";
    },
    label: "Dealership",
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "displayName"]
    },
    name: "dealership",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealership?.show;
    },
    filters: [
      {
        path: ["data", "dealershipId"],
        preview: DealershipFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.dealership?.data?.info?.city ?? "N/A";
    },
    label: "Dealership city",
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "city"]
    },
    name: "dealershipCity",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipCity?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "info", "city"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.dealership?.data?.info?.state ?? "N/A";
    },
    label: "Dealership state",
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "state"]
    },
    name: "dealershipState",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipState?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "info", "state"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.dealership?.data?.info?.zipCode ?? "N/A";
    },
    label: "Dealership zip",
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "zipCode"]
    },
    name: "dealershipZipCode",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipZipCode?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "info", "zipCode"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.dealership?.data?.info?.county ?? "N/A";
    },
    label: "Dealership county",
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "county"]
    },
    name: "dealershipCounty",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipCounty?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "info", "county"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.refNumber,
    label: "Ref #",
    options: {
      sort: true,
      path: ["data", "info", "refNumber"]
    },
    name: "refNumber",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.refNumber?.show;
    },
    filters: [
      {
        path: ["data", "info", "refNumber"],
        preview: TextFilter
      }
    ]
  },

  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
    label: "Make",
    name: "make",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.make?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "make"],
        preview: TextFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
    label: "Model",
    name: "model",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.model?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "model"],
        preview: TextFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.VIN,
    label: "VIN",
    name: "vin",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.vin?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "VIN"],
        preview: TextFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
    label: "Lender",
    name: "lender",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.lender?.show;
    },
    filters: [
      {
        path: ["data", "lenderId"],
        preview: LenderFilter
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      `${entry.data?.user?.data?.info?.firstName ?? ""} ${
        entry.data?.user?.data?.info?.lastName ?? ""
      }`,
    label: "F&I mgr",
    name: "f&iManager",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.["f&iManager"]?.show;
    },
    filters: [
      {
        path: ["data", "userId"],
        preview: UserFilter,
        type: "f&i manager"
      }
    ]
  },
  {
    getData: (entry): CellValue => dealSourceToLabel(entry.data?.source?.type),
    label: "Application source",
    options: {
      sort: true,
      path: ["data", "source", "type"]
    },
    name: "source",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.source?.show;
    },
    filters: [
      {
        path: ["data", "source", "type"],
        preview: MultiSelectFilter,
        valuesForSelect: Object.values(DealSourceTypes),
        optionLabelForSelect: (source) => dealSourceToLabel(source)
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.status,
    label: "Status",
    options: {
      customBodyRender: (x: CellValue, deal): JSX.Element | string => {
        const dealStatus = x as DealStatus;
        return statusToChip(dealStatus, false);
      },
      sort: true,
      path: ["data", "info", "status"]
    },
    name: "status",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.status?.show;
    },
    filters: [
      {
        path: ["data", "info", "status"],
        preview: MultiSelectFilter,
        valuesForSelect: Object.values(DealStatus),
        optionLabelForSelect: (status) => statusToLabel(status)
      }
    ]
  },
  ...(Object.values(DealStatus).map((status) => ({
    getData: (entry): CellValue => (entry.data?.info?.wasInStatus?.[status] ? "Yes" : "No"),
    label: `Was in status ${status}`,
    options: {
      sort: true,
      path: ["data", "info", "wasInStatus", status]
    },
    name: `wasInStatus${capitalize(status)}`,
    show: (userPermissions, tableSettings) => {
      return false;
    },
    filters: [
      {
        path: ["data", "info", "wasInStatus", status],
        preview: MultiSelectFilter,
        valuesForSelect: [1, 0],
        optionLabelForSelect: (wasInStatusBinary) => (wasInStatusBinary === 1 ? "Yes" : "No")
      }
    ]
  })) as Column<DealData>[]),
  ...(Object.values(DealStatus).map((status) => ({
    getData: (entry): CellValue => (entry.data?.info?.isInStatus?.[status] ? "Yes" : "No"),
    label: `Is in status ${status}`,
    options: {
      sort: true,
      path: ["data", "info", "isInStatus", status]
    },
    name: `isInStatus${capitalize(status)}`,
    show: (userPermissions, tableSettings) => {
      return false;
    },
    filters: [
      {
        path: ["data", "info", "isInStatus", status],
        preview: MultiSelectFilter,
        valuesForSelect: [1, 0],
        optionLabelForSelect: (isInStatusBinary) => (isInStatusBinary === 1 ? "Yes" : "No")
      }
    ]
  })) as Column<DealData>[]),
  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.submittedForFunding
        ? new Date(entry.data?.info?.dealDates?.submittedForFunding)
        : "",
    label: "Submitted at",
    options: {
      sort: true,
      path: ["data", "info", "dates", "submittedForFunding"]
    },
    name: "fundedAt",
    show: () => {
      return true;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "submittedForFunding"],
        preview: DateFilter,
        label: "Submitted for funding"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.payment?.dealTotal ?? 0,
    label: "Amount Financed",
    name: "amountFinanced",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.amountFinanced?.show;
    },
    filters: [
      {
        path: ["data", "info", "payment", "dealTotal"],
        preview: PriceFilter,
        name: "amountFinanced",
        label: "Amount Financed"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.payment?.fundedAmount ?? 0,
    label: "Funded",
    name: "fundedAmount",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.fundedAmount?.show;
    },
    options: {
      sort: true,
      path: ["data", "info", "payment", "fundedAmount"]
    },
    filters: [
      {
        path: ["data", "info", "payment", "fundedAmount"],
        preview: PriceFilter
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.fundedAt ? new Date(entry.data?.info?.dealDates?.fundedAt) : "",
    label: "Funded At",
    options: {
      sort: true,
      path: ["data", "info", "dealDates", "fundedAt"]
    },
    name: "fundedAt",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.fundedAt?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "fundedAt"],
        preview: DateFilter,
        label: "Funded At"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.profit?.dealershipProfit?.totalProfit ?? 0, "$"),

    label: "Dealership Profit",
    tableSettingsParent: "dealership",
    type: "number",
    name: "dealershipProfit",
    sign: (funcName) => "$",
    default: "$0.00",
    options: {
      sort: true,
      path: ["data", "info", "dealershipProfit", "totalProfit"]
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipProfit?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealershipProfit", "totalProfit"],
        preview: PriceFilter,
        name: "dealershipProfit",
        label: "Dealership Profit Total"
      }
    ]
  },
  {
    getData: (entry): CellValue => (entry?.createdAt ? new Date(entry?.createdAt) : ""),
    label: "Created At",
    options: {
      sort: true,
      path: ["createdAt"]
    },
    name: "createdAt",
    show: (userPermissions, tableSettings) => {
      return true;
    },
    filters: [
      {
        path: ["createdAt"],
        preview: DateFilter,
        label: "Created At"
      }
    ]
  }
];
export default ({ dealIds, tableGroupBy }: Props) => {
  const slice = `childDeals-${dealIds?.join("-")}`;

  const dealList = useSelector((state: RootState) => state.listDealSliceByIds);
  //const dealList = useSelector((state: RootState) => state.listSalesRepDealsSlice);
  const previousGroupedColumnKeys = tableGroupBy.map((group) => group.key);
  const filteredDefaultColumnsByPreviousGroups = columns.filter(
    (column) => !previousGroupedColumnKeys.includes(column.grouping?.key ?? "")
  );

  //const dealList = useSelector((state: RootState) => state.listSalesRepDealsSlice[slice]);

  return (
    <div style={{ padding: 5, background: "#8ac67e" }}>
      <Table
        tableContainerOverflowX="scroll"
        projection={defaultDealProjections}
        tableName={`childDeals-${dealIds?.join("-")}`}
        entityName="deal"
        listFunction={getDealListByIds}
        listEntity={dealList[slice]}
        query={{ _id: { $in: dealIds } }}
        sort={{ createdAt: "desc" }}
        title={""}
        showToolbar={false}
        slice={slice}
        columns={filteredDefaultColumnsByPreviousGroups}
        hideDeleteButton={true}
        hideAddButton={true}
        hideRecoverButton={true}
      />
    </div>
  );
};
