import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditDmvRequest {
  data: FormState;
}

export interface EditDmvResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditDmvRequest,
  EditDmvResponse
>("EditDmv", {
  request: "update_dmv"
});

export const editDmvActions = socketMessageSlice.actions;
export const editDmv = socketAction;
export default socketMessageSlice.reducer;
