import React, { useState } from "react";
import { Button } from "@material-ui/core/";
import { StateAccess } from "utils/models/formGenerator";
import ResetPasswordDialog from "components/Users/ForgotPassword/ResetPasswordDialog";
import { auth } from "../../firebase/firebase";
import axios from "axios";

interface Props {
  stateAccess: StateAccess;
}

export default function LoginAsDealer({ stateAccess }: Props) {
  const [link, setLink] = useState<string | undefined>();
  const handleDialogOpen = () => {
    auth.currentUser?.getIdToken().then(async (token) => {
      const result = await axios({
        method: "post",
        url: `${process.env.REACT_APP_HTTP_URL as string}/api/v1/generate_dealership_app_token`,
        data: { userId: stateAccess.get(["_id"]) },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const generatedToken = result.data.result;
      const link =
        process.env.REACT_APP_DEALERSHIP_APP_URL + `/sign-in?customToken=${generatedToken}`;
      setLink(link);
    });
  };
  return (
    <>
      <Button variant="contained" color="primary" onClick={handleDialogOpen}>
        Generate link for login in the dealership app
      </Button>
      {link && (
        <a target="_blank" rel="noreferrer" href={link}>
          Click here to login
        </a>
      )}
    </>
  );
}
