import Grid from "@material-ui/core/Grid";
import { RootState } from "app/rootReducer";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOptionsForSelect } from "utils/models/fields";
import { editRenderSet } from "utils/models/formRenderers";
import { getDealershipList, removeDealershipList } from "../Dealerships/listDealershipSlice";
import { FilterProps, filterStateAccess } from "./types";

export default function DealershipFilter<T extends unknown>({
  setFilter,
  path,
  filterType,
  filter,
  label
}: FilterProps<T>) {
  const dispatch = useDispatch();
  const listId = `list-dealership-filter`;
  const dealershipList = useSelector((state: RootState) => state.listDealershipSlice[listId]);

  const handleOpen = () => {
    if (!dealershipList)
      dispatch(
        getDealershipList(listId, {
          options: {
            projection: {
              _id: 1,
              "data.info.name": 1,
              "data.info.displayName": 1,
              insensitiveName: {
                $toLower: "$data.info.name"
              }
            },
            pagination: false,
            sort: { insensitiveName: "asc" }
          }
        })
      );
    return () => {
      dispatch(removeDealershipList(listId));
      return;
    };
  };

  const stateAccess = filterStateAccess(filter, setFilter, (values: any) => {
    if (values && values?.length > 0 && values?.every((value: any) => value?._id) !== undefined) {
      return {
        [path.join(".")]: { $in: values.map((value: any) => ({ value: value?._id })) },
        ...(filterType === "aggregatedColumnFilter" ? { filterType } : {})
      };
    } else {
      setFilter([]);
    }
  });
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {editRenderSet(false).multiSelectInputRenderer(
          {
            formComponent: "multiselect-field",
            name: label?.toLowerCase() ?? "dealership",
            label: label ?? "Dealership",
            required: false,
            path: [] as [],
            onOpen: handleOpen,
            options: createOptionsForSelect({
              possibleValues: () => dealershipList?.entities ?? [],
              getOptionLabel: (dealership) => dealership?.data?.info?.name,
              getSelectedOption: (x, y) => x?._id === y?._id
            }),
            valueToString: (dealership) => dealership?.data?.info?.name
          },
          stateAccess,
          [],
          stateAccess,
          editRenderSet(false)
        )}
      </Grid>
    </Grid>
  );
}
