import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditVehicleInsuranceCompanyRequest {
  _id: string;
  data: FormState;
}

interface EditVehicleInsuranceCompanyResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditVehicleInsuranceCompanyRequest,
  EditVehicleInsuranceCompanyResponse
>("EditVehicleInsuranceCompany", {
  request: "update_vehicle_insurance_company"
});

export const editVehicleInsuranceCompanyActions = socketMessageSlice.actions;
export const editVehicleInsuranceCompany = socketAction;
export default socketMessageSlice.reducer;
