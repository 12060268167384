import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";

import DealershipsWithActivityWarningTable from "./DealershipsWithActivityWarningTable";
import useGetDealershipActivity from "hooks/useDealershipActivity/useDealershipActivity";

export default function DealershipsWithActivityWarningReport() {
  const user = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);
  const isUserAdministartor = user?.data?.roles?.find(
    (role) => role.data.info.name === "Administrator"
  );
  const { data: dealershipActivityData } = useGetDealershipActivity(
    !isUserAdministartor ? { customerCareAssigneeId: user?._id } : {},
    user?._id !== undefined
  );

  return (
    <>
      <DealershipsWithActivityWarningTable
        dealershipIds={dealershipActivityData?.dealershipIds as string[]}
      />
    </>
  );
}
