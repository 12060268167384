import { RenderSet } from "utils/models/formGenerator";
import { Box } from "@material-ui/core";
import React from "react";
import TextLoop from "./TextLoop";

export default ({ renderSet, label }: { renderSet: RenderSet; label: string }) => {
  return (
    <Box display="flex" style={{ position: "relative" }}>
      <TextLoop
        text=""
        style={{
          position: "absolute",
          background: "rgba(0 ,0 , 0 , 0.01)",
          width: "100%",
          height: "100%",
          textAlign: "center"
        }}
      />
      <Box flexGrow={1}>
        {renderSet.textInputRenderer(
          {
            type: "text",
            required: true,
            path: [],
            name: label,
            label
          },
          {
            set: (): any => {
              return;
            },
            get: () => {
              return;
            }
          },
          renderSet
        )}
      </Box>
    </Box>
  );
};
