import { DealResponse } from "components/Deals/types";
import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { AddExternalCreditApplicationDealRequest } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddExternalCreditApplicationDealRequest,
  DealResponse
>("Add External Application Deal", {
  request: "new_external_credit_application_deal"
});

export const addExternalApplicationDeal = ({
  data,
  requestId,
  availableToRolesIds,
  externalApplicationId
}: AddExternalCreditApplicationDealRequest & Identifiable) => {
  const { lender, dealership, payoffBank, user, stateTax, notes, dealerNotes, ...rest } = data;
  return socketAction({
    externalApplicationId,
    requestId,
    availableToRolesIds: availableToRolesIds ?? [],
    data: {
      ...rest,
      dealershipId: dealership?._id ?? null
    }
  });
};

export const addDealActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
