import { RootState } from "app/rootReducer";
import TextFilter from "components/Filters/TextFilter";
import React from "react";
import { useSelector } from "react-redux";
import AccessControl from "../Access/AccessControl";
import Table, { CellValue, Column } from "../Table";
import { deleteAdditionalRequiredDocument } from "./deleteAdditionalRequiredDocumentSlice";
import { getAdditionalRequiredDocumentList } from "./listAdditionalRequiredDocumentSlice";
import { recoverAdditionalRequiredDocument } from "./recoverAdditionalRequiredDocumentSlice";
import { FormState } from "./types";
export const projections = {
  "data.info.name": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};
export default function InsuranceCompanies(): JSX.Element {
  const AdditionalRequiredDocumentList = useSelector(
    (state: RootState) => state.listAdditionalRequiredDocumentSlice["table"]
  );

  const columns: Column<FormState>[] = [
    {
      getData: (entry): CellValue => entry.data.info.name,
      label: "Additional Required Documents",
      name: "name",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      },
      filters: [
        {
          path: ["data", "info", "name"],
          preview: TextFilter,
          name: "name",
          label: "Name",
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={{ entity: "role", action: "read" }}>
        <Table
          tableName="additional_required_documents"
          entityName="additional_required_document"
          listFunction={getAdditionalRequiredDocumentList}
          listEntity={AdditionalRequiredDocumentList}
          deleteEntityFunction={deleteAdditionalRequiredDocument}
          recoverEntityFunction={recoverAdditionalRequiredDocument}
          title={"Additional Required Documents"}
          columns={columns}
        />
      </AccessControl>
    </div>
  );
}
