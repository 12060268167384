import { CustomRegexes } from "../../helpers";

export default {
  applicant: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: { pattern: "should not contain numbers" },
  },
  createdBy: { type: "string", nullable: true },
  dateSubmitted: {
    type: "string",
    format: "date-time",
    errorMessage: { format: "must be a valid date" },
    nullable: true,
  },
  modifiedBy: { type: "string", nullable: true },
  phoneNumber: {
    type: "string",
    pattern: CustomRegexes.PHONE,
    errorMessage: {
      pattern: "must not contain letters and should not start with 0 or 1",
    },
    nullable: true,
  },
  product: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: { pattern: "should not contain numbers" },
  },
  decision: { type: "string", nullable: true },
  lender: { type: "string", nullable: true },
  decisionDate: {
    type: "string",
    format: "date-time",
    errorMessage: { format: "must be a valid date" },
    nullable: true,
  },
  fileUrl: { type: "string", nullable: true },
};
