import info_properties from "./info_properties";

const data = {
  type: "object",
  required: ["info"],
  properties: {
    info: {
      type: "object",
      required: ["vinNumber"],
      properties: info_properties,
    },
  },
};

export default {
  vin_check: {
    $id: "vinCheck",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
};
