import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";

import { Column, FilteredEntities, renderEntryData } from ".";
const height = 25;
const useStyles = makeStyles(() => ({
  expanded: {
    maxHeight: height,
    margin: "5px 0px",
    padding: 0
  },
  root: {
    padding: 0,
    minHeight: height,
    maxHeight: height,
    margin: "5px 0px",
    "&$expanded": {
      minHeight: height,
      maxHeight: height,
      padding: 0
    }
  }
}));
const getUnique = <T extends any>(col: Column<T>, entries: FilteredEntities<T>) => {
  return Array.from(
    new Set(
      entries?.map((entry) => {
        return col?.grouping?.getData(entry.curr as any);
      })
    )
  );
};
const MobileTable = <T extends any>({
  columns,
  entries,
  level = 1
}: {
  columns: Column<T>[];
  entries: FilteredEntities<T>;
  level?: number;
}) => {
  const curr = columns[0];
  const uniqueGroups = getUnique(curr, entries);
  const classes = useStyles();
  if (curr?.grouping) {
    return (
      <>
        {uniqueGroups?.map((currentGroup, index) => {
          return (
            <Accordion key={index} style={{ margin: 4 }} elevation={3}>
              <AccordionSummary
                classes={{ root: classes.root, expanded: classes.expanded }}
                style={{
                  position: "sticky",
                  top: 50 + (level - 1) * 30,
                  zIndex: 1000 - level,
                  background: level === 1 ? "lightgray" : "white",

                  padding: 0
                }}
              >
                <div style={{ paddingLeft: 10 }}>
                  {curr.label} - {currentGroup}
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 5, display: "block" }}>
                <MobileTable
                  columns={columns.slice(1)}
                  entries={entries.filter((entry) => {
                    return curr?.grouping?.getData(entry.curr as any) === currentGroup;
                  })}
                  level={level + 1}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </>
    );
  } else {
    return (
      <>
        {entries?.map((entry, i) => {
          return (
            <Grid
              container
              key={i}
              style={{ padding: 3, margin: 6, width: "auto", border: "1px solid lightgray" }}
            >
              {columns.map((c, index) => {
                return (
                  <Grid xs={6} key={i}>
                    <div style={{ padding: 3, margin: 2 }}>
                      <div style={{ fontWeight: "bold" }}>{c.label}</div>
                      <div>{renderEntryData(c.getData(entry.curr as any), false)}</div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </>
    );
  }
};
export default MobileTable;
