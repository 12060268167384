import info_properties from "./info_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import { Schema } from "ajv";

const data: Schema = {
  type: "object",
  required: ["info"],
  properties: {
    info: {
      type: "object",
      required: ["baseUrl", "chartId", "title"],
      properties: info_properties,
    },
  },
};

export default {
  new_custom_report: {
    $id: "newCustomReport",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_custom_report: {
    $id: "updateCustomReport",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      approved: {
        type: "boolean",
        nullable: true,
      },
      approvedAt: {
        type: "string",
        nullable: true,
      },
      approvedByUserId: {
        type: "string",
        nullable: true,
      },
    },
  },
  delete_custom_report: {
    $id: "deleteCustomReport",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  recover_custom_report: {
    $id: "recoverCustomReport",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_custom_report: {
    $id: "getCustomReport",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_custom_reports: {
    $id: "allCustomReports",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
