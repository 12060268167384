import {
  HttpRequestData,
  makeHttpRequestWithResponse
} from "components/Middlewares/SocketMiddleware";

export const makeHttpRequest = async (request: HttpRequestData, action: any) => {
  try {
    const response = await makeHttpRequestWithResponse(request, action.http.path);
    if (response.data.status !== "success") {
      alert(response.data.result);
    }
    return response.data;
  } catch (e: any) {
    alert(e?.message);
    return { status: "error", message: e?.message };
  }
};
