import { User } from "components/Users/types";
import { useCreateChannel } from "Chat/hooks/useChannel";
import { BiUserCircle } from "react-icons/bi";
import { ResultsTableProps } from "./types";
import React from "react";
import { RootState } from "app/rootReducer";
import Loader from "components/Loader/Loader";
import { useSelector } from "react-redux";
import styles from "./Search.module.css";
import { Applicant } from "components/Applicants/types";
import { formatFirstAndLastNames } from "utils/functions";
import { RxPerson } from "react-icons/rx";
import { DealershipUser } from "types/dealeship_users";

const ResultsTable = ({ users, applicants, dealershipUsers }: ResultsTableProps) => {
  const user = useSelector((state: RootState) => state.authSlice);

  const { createChannel } = useCreateChannel();

  if (!user) return <Loader />;
  const handleCreateChannelWithApplicant = (applicant: Applicant) => {
    createChannel({
      data: {
        sender: {
          memberId: user?.user?.databaseData?._id as string,
          email: user?.user?.databaseData?.data?.info?.email ?? "",
          type: "user"
        },
        receivers: [
          {
            memberId: applicant._id,
            email: applicant?.data?.info?.email,
            type: "applicant"
          }
        ]
      }
    });
  };
  const handleCreateChannelWithUser = (selectedUser: User) => {
    createChannel({
      data: {
        sender: {
          memberId: user?.user?.databaseData?._id as string,
          email: user?.user?.databaseData?.data?.info?.email ?? "",
          type: "user"
        },
        receivers: [
          {
            memberId: selectedUser._id,
            email: selectedUser?.data?.info?.email,
            type: "user"
          }
        ]
      }
    });
  };

  const handleCreateChannelWithDealershipUser = (selectedUser: DealershipUser) => {
    createChannel({
      data: {
        sender: {
          memberId: user?.user?.databaseData?._id as string,
          email: user?.user?.databaseData?.data?.info?.email ?? "",
          type: "user"
        },
        receivers: [
          {
            memberId: selectedUser._id,
            email: selectedUser?.data?.info?.email,
            type: "dealership_user"
          }
        ]
      }
    });
  };
  return (
    <div className={styles.tableContainer}>
      <div>
        <div className={styles.tableHeaderContainer}>
          <BiUserCircle size={30} className={styles.headerIcon} />
          {"Managers"}
        </div>
        <table className={styles.table}>
          <tbody>
            {users?.loading && (
              <tr>
                <td align="center" colSpan={4}>
                  <Loader />
                </td>
              </tr>
            )}
            {users?.data?.map((user, index) => (
              <tr key={index} onMouseDown={() => handleCreateChannelWithUser(user)}>
                <>
                  <td align="left">
                    {formatFirstAndLastNames(
                      user?.data?.info?.firstName,
                      user?.data?.info?.lastName
                    )}
                  </td>
                </>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <div className={styles.tableHeaderContainer}>
          <BiUserCircle size={30} className={styles.headerIcon} />
          {"Dealerships"}
        </div>
        <table className={styles.table}>
          <tbody>
            {dealershipUsers?.loading && (
              <tr>
                <td align="center" colSpan={4}>
                  <Loader />
                </td>
              </tr>
            )}
            {dealershipUsers?.data?.map((user, index) => (
              <tr key={index} onMouseDown={() => handleCreateChannelWithDealershipUser(user)}>
                <>
                  <td align="left">
                    {user?.data?.dealership?.data?.info?.name +
                      " - " +
                      formatFirstAndLastNames(
                        user?.data?.info?.firstName,
                        user?.data?.info?.lastName
                      )}
                  </td>
                </>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.tableHeaderContainer}>
        <RxPerson size={30} className={styles.headerIcon} />
        Applicants
      </div>
      <table className={styles.table}>
        <tbody>
          {applicants?.loading && (
            <tr>
              <td align="center" colSpan={4}>
                <Loader />
              </td>
            </tr>
          )}
          {applicants.data?.map((applicant, index) => (
            <tr key={index} onMouseDown={() => handleCreateChannelWithApplicant(applicant)}>
              <>
                <td align="left">
                  {formatFirstAndLastNames(
                    applicant?.data?.info?.firstName,
                    applicant?.data?.info?.lastName
                  )}
                </td>
              </>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default ResultsTable;
