import { createOptionsForSelect, FormComponent, Model } from "../../utils/models/fields";
import { Tier, Condition, ManagerProgram } from "./types";
import { StateAccess, RenderSet } from "utils/models/formGenerator";
import UserPreview from "../Users/UserPreview";

import React from "react";
import toNormalCase from "utils/toNormalCase";

const conditionsStruct: FormComponent<Condition> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "select-field",
      options: createOptionsForSelect({
        possibleValues: (stateAccess, path, mainStateAccess) => {
          return ["pvr"];
        },
        getOptionLabel: (x) => toNormalCase(x)
      }),
      name: "criteria",
      label: "Criteria",
      width: "1/3",
      path: ["criteria"],
      default: null
    },
    {
      formComponent: "select-field",
      options: createOptionsForSelect({
        possibleValues: () => ["<=", ">="],
        getOptionLabel: (x: string | undefined) => x ?? ""
      }),
      name: "type",
      label: "Type",
      width: "1/3",
      path: ["type"],
      default: null
    },
    {
      formComponent: "number-field",
      name: "value",
      label: "Value",
      width: "1/3",
      path: ["value"],
      default: null
    }
  ]
};

const commissionsStruct: FormComponent<Tier> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "segment",
      width: "1/2",
      entities: [
        {
          formComponent: "select-field",
          options: createOptionsForSelect({
            possibleValues: () => ["percentage"],
            getOptionLabel: (x) => x?.charAt(0).toUpperCase() + x?.slice(1),
            getSelectedOption: (x, y) => x === y
          }),
          name: "commissionType",
          label: "Commission Type",
          width: "1/4",
          path: ["commissionType"],
          default: null
        },
        {
          formComponent: "number-field",
          name: "reserveCommissionAmount",
          label: "Reserve Commission Amount",
          width: "1/4",
          path: ["reserveCommissionAmount"],
          default: null
        },
        {
          formComponent: "number-field",
          name: "productCommissionAmount",
          label: "Rroduct Commission Amount",
          width: "1/4",
          path: ["productCommissionAmount"],
          default: null
        },
        {
          formComponent: "number-field",
          name: "chargebackPercent",
          label: "Chargeback %",
          width: "1/4",
          path: ["chargebackPercent"],
          default: null
        }
      ]
    },
    {
      formComponent: "list-model",
      name: "Conditions",
      width: "1/2",
      entity: conditionsStruct,
      path: ["conditions"],
      required: false
    }
  ]
};

export const managerProgramStruct: Model<ManagerProgram> = {
  formComponent: "model",
  schema: "new_manager_program",
  name: "manager_program",
  entities: [
    {
      formComponent: "segment",
      name: "Manager Program",
      entities: [
        {
          formComponent: "text-field",
          name: "name",
          label: "Name",
          width: "full",
          path: ["data", "info", "name"],
          default: "",
          required: true
        },
        {
          formComponent: "list-model",
          name: "Tiers",
          width: "full",
          entity: commissionsStruct,
          path: ["data", "info", "tiers"],
          required: false
        }
      ]
    }
  ]
};
