import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteLenderRequest {
  _id: string;
}

interface DeleteLenderResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteLenderRequest,
  DeleteLenderResponse
>("DeleteLender", {
  request: "delete_lender"
});

export const deleteLenderActions = socketMessageSlice.actions;
export const deleteLender = socketAction;
export default socketMessageSlice.reducer;
