import { v4 as uuidv4 } from "uuid";
import { Grid } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "app/rootReducer";
import { createOptionsForSelect } from "utils/models/fields";
import formEditContext from "components/Content/FormEditContext";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { getLenderTicketTypesList, removeLenderTicketTypesList } from "./listLenderTicketTypeSlice";

import { LenderTicketType } from "./types";
import AddLenderTicketType from "./AddLenderTicketType";

interface Props {
  type: LenderTicketType | null;
  renderSet: RenderSet;
  setType: React.Dispatch<React.SetStateAction<LenderTicketType | null>>;
}

export default function LenderTicketTypePreview({ type, setType, renderSet }: Props) {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const listId = `list-autocomplete-type-${requestId}`;
  const { enabled: editMode } = useContext(formEditContext);
  const list = useSelector((state: RootState) => state.listLenderTicketTypeSlice[listId]);

  const stateAccess: StateAccess = {
    get: () => type,
    set: (_, value): any => {
      setType(value);
    }
  };

  const handleLoadType = () => {
    if (!list)
      dispatch(
        getLenderTicketTypesList(listId, {
          options: { pagination: false, sort: { "data.info.type": "asc" } }
        })
      );
    return () => {
      dispatch(removeLenderTicketTypesList(listId));
      return;
    };
  };

  return (
    <Grid container alignItems="center" xs={12}>
      <Grid item xs={10}>
        {renderSet.selectInputRenderer(
          {
            formComponent: "select-field",
            name: "Type",
            label: "Reason",
            path: [],
            onOpen: handleLoadType,
            options: createOptionsForSelect({
              possibleValues: () => list?.entities ?? [],
              getOptionLabel: (x) => {
                return x?.data?.info?.type ?? "";
              },
              getSelectedOption: (x, y) => {
                return x._id === y._id;
              }
            }),
            valueToString: (el) => el?.data?.info?.type
          },
          stateAccess,
          [],
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={2}>
        <AddLenderTicketType
          selected={type}
          setSelected={(type: LenderTicketType) => stateAccess.set([], type)}
          editMode={editMode}
        />
      </Grid>
    </Grid>
  );
}
