import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { DocumentTemplateToEdit, FormState } from "./types";

interface EditDocumentTemplateData {
  _id: string;
  data: FormState;
}

interface EditDocumentTemplateResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DocumentTemplateToEdit,
  EditDocumentTemplateResponse
>("Edit Document Template", {
  request: "update_document_template"
});

export const editDocumentTemplate = (request: EditDocumentTemplateData & Identifiable) => {
  const {
    data: {
      files,
      info: {
        dealTypeProperties: { lenders, ...dealTypeProperties },
        ...info
      },
      ...data
    }
  } = request;

  return socketAction({
    ...request,
    data: {
      ...data,
      info: {
        ...info,
        dealTypeProperties: {
          ...dealTypeProperties,
          lendersIds: lenders?.map((el) => el._id) ?? []
        }
      }
    }
  });
};
export const editDocumentTemplateActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
