import React, { useState, useEffect, useContext } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { StateAccess, generateDefault, fillDefaultsByPath } from "utils/models/formGenerator";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { getEntity } from "utils/entitySlice";
import { storage } from "../../firebase/firebase";
import { IconButton } from "@material-ui/core";
import { sumbitRVContracts, sumbitRVContractsActions } from "./submitContractSlice";
import { v4 as uuidv4 } from "uuid";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { Deal, DealData, ExpressInsurance, CustomInsurance } from "components/Deals/types";
import { VerificationContext } from "components/Layout/Main";
import CircularProgress from "@material-ui/core/CircularProgress";
import States from "us-states";
import { ProtectiveInsurance, SubmitRvContractRequest } from "components/Protective/types";
import { RootState } from "app/rootReducer";
import {
  addFundingDocument,
  addFundingDocumentActions
} from "components/FundingDocuments/addFundingDocumentSlice";
import { templateFundingDocumentStruct } from "components/FundingDocuments/model";
import { ASCInsurance } from "components/ASCWarranty/types";

const stateToPrefix = (state: string) => States[state]?.prefix;

export const isProtectiveInsurance = (
  insurance: ExpressInsurance | ProtectiveInsurance | ASCInsurance | CustomInsurance
): insurance is ProtectiveInsurance => insurance?.type === "Protective";

interface Props {
  stateAccess: StateAccess;
  index: number;
  insurance: ProtectiveInsurance;
}

export default function SubmitContract({ stateAccess, index, insurance }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const checkDataValidity = useContext(VerificationContext);
  const [requestId] = useState(uuidv4());
  const submitState = useSelector((state: RootState) => state?.sumbitRVContractSlice[requestId]);
  const [fundingDocumentRequestId] = useState(uuidv4());
  const fundingDocumentState = useSelector(
    (state: RootState) => state?.addFundingDocumentSlice[fundingDocumentRequestId]
  );

  const [state, setState] = useState<{
    isLoading: boolean;
    error: boolean;
    errorMessage: string | undefined;
    updateDeal: boolean;
  }>({
    isLoading: false,
    error: false,
    errorMessage: undefined,
    updateDeal: false
  });
  const deal: Deal = stateAccess.get([]);
  const dealId = deal._id;
  const dealdata: DealData = deal.data;

  const fundingDocumentId = insurance?.fundingDocumentId ?? null;

  const fundingDocumentUrl = useSelector(
    (state: RootState) => state.entitySlice["funding_document"][insurance?.fundingDocumentId]
  )?.data?.info?.url;

  useEffect(() => {
    if (fundingDocumentId) dispatch(getEntity("funding_document", fundingDocumentId));
  }, [dispatch, fundingDocumentId]);

  useEffect(() => {
    if (submitState !== undefined && submitState.status !== undefined) {
      switch (submitState.status) {
        case "error":
          setState({ ...state, isLoading: false, error: true });
          dispatch({
            type: sumbitRVContractsActions.none.type,
            payload: { requestId }
          });
          break;
        case "waiting":
          setState({ ...state, isLoading: true, error: false });
          dispatch({
            type: sumbitRVContractsActions.none.type,
            payload: { requestId }
          });
          break;
        case "success":
          const response =
            submitState?.data?.message?.SubmitContractsResult?.RVs?.RVContractEntryResponse[0];
          const error =
            response?.VSCAcknowledgement?.ContractErrors?.ContractError[0]?.Message ??
            response?.RVErrors?.AutomobileError[0].Message;
          const success = response?.VSCAcknowledgement?.Success;
          const pdf = response?.VSCAcknowledgement?.FormPDF;
          if (error) {
            setState({ ...state, isLoading: false, error: true });
            enqueueSnackbar(`${error}`, {
              variant: "error"
            });
          }
          if (success) {
            fetch("data:application/pdf;base64," + pdf)
              .then((res) => res.blob())
              .then((blob) => {
                const uploadTask = storage.ref(`/files/Deal/${dealId}/${requestId}`).put(blob);
                uploadTask.on(
                  "state_changed",
                  (_snapShot) => {},
                  (err) => {
                    enqueueSnackbar(err.message, {
                      variant: "error"
                    });
                  },
                  () => {
                    const ref = storage.ref(`/files/Deal/${dealId}`).child(requestId);
                    const defaultState = generateDefault(
                      templateFundingDocumentStruct,
                      {},
                      fillDefaultsByPath as any
                    ) as any;
                    ref.getDownloadURL().then((fireBaseUrl) => {
                      ref.getMetadata().then((metadata) => {
                        dispatch(
                          addFundingDocument({
                            ...defaultState,
                            data: {
                              ...defaultState.data,
                              info: {
                                url: fireBaseUrl,
                                name: `Protective contract - ${response?.VSCContract?.VSCContractDetails?.ContractNumber}`,
                                metadata
                              },
                              deal: deal
                            },
                            requestId: fundingDocumentRequestId
                          })
                        );
                        stateAccess.set<Deal>(
                          ["data", "info", "aftermarketOptions", "insurances", index],
                          {
                            ...insurance,
                            ContractNumber:
                              response?.VSCContract?.VSCContractDetails?.ContractNumber,
                            ContractPrefix:
                              response?.VSCContract?.VSCContractDetails?.ContractPrefix
                          }
                        );
                      });
                    });
                  }
                );

                enqueueSnackbar(`Successfully submited an insurance.`, {
                  variant: "success"
                });
              });
          }
          dispatch({
            type: sumbitRVContractsActions.none.type,
            payload: { requestId }
          });
      }
    }
  }, [
    dispatch,
    requestId,
    state,
    submitState,
    enqueueSnackbar,
    index,
    insurance,
    stateAccess,
    deal,
    dealId,
    fundingDocumentRequestId
  ]);
  useEffect(() => {
    if (fundingDocumentState?.status !== undefined) {
      switch (fundingDocumentState.status) {
        case "error":
          setState({ ...state, isLoading: false, error: true });
          dispatch({
            type: addFundingDocumentActions.none.type,
            payload: { requestId: fundingDocumentRequestId }
          });
          break;
        case "waiting":
          setState({ ...state, isLoading: true, error: false });
          dispatch({
            type: addFundingDocumentActions.none.type,
            payload: { requestId: fundingDocumentRequestId }
          });
          break;
        case "success":
          stateAccess.set<Deal>(["data", "info", "aftermarketOptions", "insurances", index], {
            ...insurance,
            fundingDocumentId: fundingDocumentState.data.message._id
          });
          dispatch({
            type: addFundingDocumentActions.none.type,
            payload: { requestId: fundingDocumentRequestId }
          });
          setState({ ...state, isLoading: false });
      }
    }
  }, [
    dispatch,
    requestId,
    state,
    fundingDocumentState,
    enqueueSnackbar,
    index,
    insurance,
    stateAccess,
    fundingDocumentRequestId
  ]);
  //This may need to be changed!
  const getContractPrefix = (vehicleType: string) => {
    if (
      vehicleType === "MotorHomeClassA" ||
      vehicleType === "MotorHomeClassB" ||
      vehicleType === "MotorHomeClassC"
    )
      return "RV17";
    else return "RV19";
  };

  if (isProtectiveInsurance(insurance)) {
    const VIN = dealdata?.info?.vehicle?.VIN;
    const vehicle = dealdata?.info?.vehicle;
    const FirstPurchaserName = dealdata?.applicant?.data?.info?.firstName;
    const FirstPurchaserLastName = dealdata?.applicant?.data?.info?.lastName;
    const FirtstPurchaserMiddleInitial = dealdata?.applicant?.data?.info?.middleName?.substr(0, 1);
    const SecondPurchaserName = dealdata?.coApplicant?.data?.info?.firstName;
    const SecondPurchaserLastName = dealdata?.coApplicant?.data?.info?.lastName;
    const SecondPurchaserMiddleInitial = dealdata?.applicant?.data?.info?.middleName?.substr(0, 1);

    const Address = dealdata?.applicant?.data?.info?.currentAddress;
    const PurchaserCity = dealdata?.applicant?.data?.info?.currentCity;
    const ZipCode = dealdata?.applicant?.data?.info?.currentZipCode?.toString() ?? "";
    const State = stateToPrefix(dealdata?.applicant?.data?.info?.currentState ?? "");
    const Country = dealdata?.applicant?.data?.info?.currentCountry ?? null;

    const LienHolderName = dealdata?.lender?.data?.info?.name;

    const LienHolderAddress = dealdata?.lender?.data?.info?.address;
    const LienHolderCity = dealdata?.lender?.data?.info?.city;
    const LienHolderZipCode = dealdata?.lender?.data?.info?.zipCode ?? "";
    const LienHolderState = stateToPrefix(dealdata?.lender?.data?.info?.state ?? "") ?? "";

    const RateQuote = insurance?.chosenRate;

    const dataToSubmit: SubmitRvContractRequest = {
      requestId,
      data: {
        RVs: {
          RVContractEntryRequest: {
            VIN: VIN,
            Purchaser: {
              FirstPurchaser: {
                FirstName: FirstPurchaserName ?? null,

                MiddleInitial: FirtstPurchaserMiddleInitial ?? null,
                LastName: FirstPurchaserLastName ?? null
              },
              SecondPurchaser: {
                FirstName: SecondPurchaserName ?? null,
                MiddleInitial: SecondPurchaserMiddleInitial ?? null,
                LastName: SecondPurchaserLastName ?? null
              },
              Address: {
                Address1: Address ?? "",
                Address2: null,
                City: PurchaserCity ?? "",
                State: State,
                StateCode: State,
                ZipCode: ZipCode,
                Country: Country,
                CountryCode: "UnitedStatesOfAmerica"
              }
            },

            VSCContract: {
              VehiclePlan: insurance.VSCRateOptions?.VehiclePlan,
              BeginningOdometer: vehicle.odometer,

              InServiceDate: insurance.VSCRateOptions?.InServiceDate,
              VehiclePurchasePrice: dealdata?.info?.price?.price,
              FinancingType: "Lease",
              VSCContractDetails: {
                ContractPrefix: getContractPrefix(insurance.VehicleDetails?.VehicleType ?? ""),
                EffectiveDate: new Date().toISOString(),
                PurchaseDate: new Date().toISOString(),
                RateQuote: {
                  Coverage: RateQuote.Coverage,
                  ProductClass: RateQuote.ProductClass,
                  ProductClassCode: RateQuote.ProductClassCode,
                  CoverageCode: RateQuote.CoverageCode,
                  OrderNumber: RateQuote.OrderNumber,
                  CoverageTermMonths: RateQuote.months,
                  CoverageTermMinMonths: RateQuote.CoverageTermMinMonths,
                  CoverageTermMiles: RateQuote.CoverageTermMiles,
                  CoverageSortOrder: RateQuote.OrderNumber,
                  Deductible: RateQuote.Deductible,
                  DealerCost: RateQuote.dealerCostPrice,
                  RetailPrice: RateQuote.retailPrice,
                  ContractFormID: RateQuote.ContractFormID,
                  RateNumber: RateQuote.RateNumber,
                  ContractPrefix: getContractPrefix(insurance.VehicleDetails?.VehicleType ?? ""),
                  ProductType: RateQuote.ProductType
                }
              },

              Surcharges: {
                BusinessUse: insurance.VSCRateOptions.Surcharges.BusinessUse,
                RearDiesel: insurance.VSCRateOptions.Surcharges.RearDiesel,
                FrontDiesel: insurance.VSCRateOptions.Surcharges.FrontDiesel,
                TwoYearMfg: insurance.VSCRateOptions.Surcharges.TwoYearMfg,
                ThreeYearMfg: insurance.VSCRateOptions.Surcharges.ThreeYearMfg,
                ConsequentialFailure: insurance.VSCRateOptions.Surcharges.ConsequentialFailure,
                ThreePlus: insurance.VSCRateOptions.Surcharges.ThreePlus
              }
            },
            VehicleDetails: {
              VehicleYear: vehicle.year,
              VehicleModel: vehicle.model,
              VehicleMake: vehicle.make,
              VIN: VIN,
              VehicleMsrp: vehicle.MSRP ?? 0,
              ChassisYear: insurance.VehicleDetails?.ChassisYear,
              ChassisMake: insurance.VehicleDetails?.ChassisMake ?? "",
              ChassisModel: insurance.VehicleDetails?.ChassisModel ?? "",
              LienHolder: LienHolderName ?? "",
              LienHolderAddress: LienHolderAddress ?? "",
              LienHolderCity: LienHolderCity ?? "",
              LienHolderState: LienHolderState,
              LienHolderZip: LienHolderZipCode,
              InServiceDate: insurance.VehicleDetails?.InServiceDate,
              PurchasePrice: dealdata?.info?.price?.price,
              VehicleType: insurance.VehicleDetails?.VehicleType ?? ""
            },
            SaveWithoutRating: false,
            ProvideFormsWithResponse: true
          }
        }
      }
    };

    const isAlreadySigned = insurance.fundingDocumentId;
    const requiredFieldsInsurance = [
      {
        name: "Vehicle make",
        value: vehicle.model
      },
      {
        name: "Vehicle year",
        value: vehicle.year
      },

      {
        name: "Vehicle model",
        value: vehicle.make
      },
      {
        name: "Vehicle odometer",
        value: vehicle.odometer
      },
      {
        name: "Loan price",
        value: dealdata?.info?.price?.price
      }
    ];
    return (
      <>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isAlreadySigned ? (
            <IconButton
              color="primary"
              aria-label="download"
              onClick={() => window.open(fundingDocumentUrl)}
            >
              <CloudDownloadIcon style={{ color: "#254E70" }} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() =>
                checkDataValidity(requiredFieldsInsurance, () =>
                  dispatch(sumbitRVContracts(dataToSubmit))
                )
              }
              color="primary"
              aria-label="search"
            >
              <BorderColorIcon style={{ color: "#254E70" }} />
              {state.isLoading && (
                <CircularProgress style={{ position: "absolute", display: "block" }} />
              )}
            </IconButton>
          )}
        </div>
      </>
    );
  } else return <></>;
}
