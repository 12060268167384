import { Button, Grid, TextField } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { addColumnGroup } from "components/GroupedTable/ColumnGroups/addColumnGroupSlice";
import {
  getColumnGroupList,
  removeColumnGroupList
} from "components/GroupedTable/ColumnGroups/listColumnGroupSlice";
import { ColumnGroup } from "components/GroupedTable/ColumnGroups/types";
import { getUserList, removeUserList } from "components/Users/listUserSlice";
import { User } from "components/Users/types";
import { useSnackbar } from "notistack";
import { uuid } from "uuidv4";
import RepDashboardDealTable from "./RepDashboardDealTable";
import { useStickyState } from "index";
import { Dealership } from "components/Dealerships/types";
import {
  getDealershipList,
  removeDealershipList
} from "components/Dealerships/listDealershipSlice";

export const randomNumber = (): number => {
  return parseInt((Math.random() * 1000).toString());
};

export default function SalesRepresentativeDashboard({
  setResetStickyCallback
}: {
  setResetStickyCallback?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}) {
  const [groupedTableResetStickyCallback, setGroupedTableResetStickyCallback] = React.useState<
    () => void
  >();
  const tableName = "salesRepDeals";
  const snapshotColumnGroupId = JSON.parse(localStorage.getItem("columnGroupData") ?? "{}")
    ?.columnGroupId;
  const { enqueueSnackbar } = useSnackbar();
  const [requestId] = useState(uuid());
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);
  const snapshotColumnGroupsList = useSelector(
    (state: RootState) => state.listColumnGroupSlice[requestId]
  );
  const addColumnGroupStatus = useSelector(
    (state: RootState) => state.addColumnGroupSlice[requestId]
  );
  const [state, setState] = useStickyState<{
    representativeIds: string[];
    allRepresentatives: boolean;
    dealershipIds: string[];
    allDealerships: boolean;
  }>(
    {
      allRepresentatives: true,
      representativeIds: [],
      allDealerships: true,
      dealershipIds: []
    },
    "salesrep_dashboard_state"
  );
  const [representatives, setRepresentatives] = useStickyState<User[]>(
    [],
    "salesrep_dashboard_representatives"
  );
  const [dealerships, setDealerships] = useStickyState<Dealership[]>(
    [],
    "salesrep_dashboard_dealerships"
  );
  const userListId = "salesRepDashboard";
  const userList = useSelector((state: RootState) => state.listUserSlice[userListId]);
  const dealershipList = useSelector((state: RootState) => state.listDealershipSlice[userListId]);

  React.useEffect(() => {
    if (!snapshotColumnGroupsList)
      dispatch(
        getColumnGroupList(requestId, {
          options: { pagination: false },
          query: {
            "data.info.type": "salesrep",
            "data.info.isSnapshot": true,
            deleted: false
          }
        })
      );
    return () => {
      dispatch(removeColumnGroupList(requestId));
      return;
    };
  }, []);
  React.useEffect(() => {
    switch (addColumnGroupStatus?.status) {
      case "success":
        enqueueSnackbar("Successfully imported a column grouping!", {
          variant: "success"
        });
        break;
    }
  }, [addColumnGroupStatus?.status]);
  React.useEffect(() => {
    if (user?._id && snapshotColumnGroupsList?.entities?.length && snapshotColumnGroupId) {
      const foundGroup = snapshotColumnGroupsList?.entities?.find(
        (x) => x._id === snapshotColumnGroupId
      );
      if (foundGroup) {
        const nonSnapshotGroupToCreate = {
          data: {
            userId: user?._id as string,
            info: {
              ...foundGroup.data.info,
              isSnapshot: false
            }
          }
        } as ColumnGroup;
        dispatch(
          addColumnGroup({
            requestId,
            ...nonSnapshotGroupToCreate
          })
        );
        localStorage.removeItem("columnGroupData");
      }
    }
  }, [snapshotColumnGroupsList?.entities]);
  React.useEffect(() => {
    if (!userList)
      dispatch(
        getUserList(userListId, {
          options: { pagination: false, sort: { "data.info.firstName": "asc" } },
          query: { "data.info.userType": "representative" }
        })
      );
    return () => {
      dispatch(removeUserList(userListId));
      return;
    };
  }, []);
  React.useEffect(() => {
    if (!dealershipList)
      dispatch(
        getDealershipList(userListId, {
          options: {
            projection: {
              _id: 1,
              "data.info.name": 1,
              "data.info.displayName": 1,
              insensitiveName: {
                $toLower: "$data.info.name"
              }
            },
            pagination: false,
            sort: { insensitiveName: "asc" }
          }
        })
      );
    return () => {
      dispatch(removeDealershipList(userListId));
      return;
    };
  }, []);
  const handleResetStickyState = () => {
    setRepresentatives([]);
    setState({
      allRepresentatives: true,
      representativeIds: [],
      allDealerships: true,
      dealershipIds: []
    });
    groupedTableResetStickyCallback?.();
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, [groupedTableResetStickyCallback]);
  const isAdmin = user?.data?.rolesIds?.includes("5f17e21d46a2b90e45bb5a90");
  return (
    <>
      {setResetStickyCallback === undefined && (
        <Button
          onClick={() => handleResetStickyState()}
          style={{ margin: "5px 0px", float: "right" }}
          color="primary"
          variant="contained"
        >
          Reset state
        </Button>
      )}
      <Grid style={{ marginTop: "4px" }} container spacing={1}>
        {isAdmin && (
          <Grid item container xs={12}>
            <Grid item md={2} sm={12}>
              <Autocomplete
                style={{ zIndex: 6 }}
                value={
                  state.allRepresentatives
                    ? ((["All representatives"] as unknown) as User[])
                    : representatives || null
                }
                getOptionLabel={(representative) => {
                  if (((representative as unknown) as string) === "All representatives") {
                    return (representative as unknown) as string;
                  }
                  return (
                    [representative?.data?.info?.firstName, representative?.data?.info?.lastName]
                      .filter((x) => x)
                      .join(" ") ?? ""
                  );
                }}
                multiple
                getOptionSelected={(x, y) => x?._id === y?._id && x === y}
                options={[
                  ("All representatives" as unknown) as User,
                  ...(userList?.entities ?? [])
                ]}
                onChange={(event, newValue) => {
                  if (newValue?.length === 0) {
                    setState((prevState) => ({
                      ...prevState,
                      allRepresentatives: true
                    }));
                    setRepresentatives((["All representatives"] as unknown) as User[]);
                  } else {
                    setRepresentatives(
                      newValue.filter((x) => x !== (("All representatives" as unknown) as User))
                    );
                    setState((prevState) => ({
                      ...prevState,
                      representativeIds: newValue
                        .filter((x) => x !== (("All representatives" as unknown) as User))
                        .map((x) => x._id),
                      allRepresentatives: false
                    }));
                  }
                }}
                loading={!Array.isArray(userList?.entities)}
                openOnFocus
                id="rep-dashboard-rep-select"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ ...params.InputProps }}
                    label={"Representative"}
                    variant="filled"
                    size="small"
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
        <Grid item container xs={12}>
          <Grid item md={2} sm={12}>
            <Autocomplete
              style={{ zIndex: 6 }}
              value={
                state.allDealerships
                  ? ((["All dealerships"] as unknown) as Dealership[])
                  : dealerships || null
              }
              getOptionLabel={(dealership) => {
                if (((dealership as unknown) as string) === "All dealerships") {
                  return (dealership as unknown) as string;
                }
                return dealership?.data?.info?.displayName;
              }}
              multiple
              getOptionSelected={(x, y) => x?._id === y?._id && x === y}
              options={[
                ("All dealerships" as unknown) as Dealership,
                ...(dealershipList?.entities ?? [])
              ]}
              onChange={(event, newValue) => {
                if (newValue?.length === 0) {
                  setState((prevState) => ({
                    ...prevState,
                    allDealerships: true
                  }));
                  setDealerships((["All dealerships"] as unknown) as Dealership[]);
                } else {
                  setDealerships(
                    newValue.filter((x) => x !== (("All dealerships" as unknown) as Dealership))
                  );
                  setState((prevState) => ({
                    ...prevState,
                    dealershipIds: newValue
                      .filter((x) => x !== (("All dealerships" as unknown) as Dealership))
                      .map((x) => x._id),
                    allDealerships: false
                  }));
                }
              }}
              loading={!Array.isArray(dealershipList?.entities)}
              openOnFocus
              id="rep-dashboard-dealerships-select"
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ ...params.InputProps }}
                  label={"Dealership"}
                  variant="filled"
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RepDashboardDealTable
            setGroupedTableResetStickyCallback={setGroupedTableResetStickyCallback}
            tableName={tableName}
            userId={user?._id}
            repIds={
              isAdmin
                ? state.allRepresentatives
                  ? undefined
                  : state.representativeIds
                : [user?._id as string]
            }
            dealershipIds={state.allDealerships ? undefined : state.dealershipIds}
          />
        </Grid>
      </Grid>
    </>
  );
}
