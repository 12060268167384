import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import CloseDialogButton from "./CloseDialogButton";

interface Props {
  deleteFunction: () => void;
  dialogState: boolean;
  setDialogState: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
}

export default function ConfirmDeleteDialog({
  deleteFunction,
  dialogState,
  setDialogState,
  message
}: Props) {
  const handleClose = () => {
    setDialogState(false);
  };

  return (
    <div>
      <Dialog
        id="confirm-delete-dialog"
        open={dialogState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm delete
          <CloseDialogButton closeFunction={handleClose} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary" id="cancel">
            Cancel
          </Button>
          <Button
            onClick={deleteFunction}
            variant="contained"
            color="secondary"
            autoFocus
            id="confirm"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
