import {
  createSocketMessageSlice,
  Identifiable
} from "../../Middlewares/socketMessageSliceCreator";
import { NotificationToSend, Notification, NotificationResponse } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  NotificationToSend,
  NotificationResponse
>("EditNotification", {
  request: "update_notification"
});

export const editNotificationActions = socketMessageSlice.actions;
export const editNotification = (request: Notification & Identifiable) => {
  return socketAction({
    ...{_id: request._id, data: { ...request.data }},
    requestId: request.requestId,
  });
};
export default socketMessageSlice.reducer;
