import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { DeleteOrRecoverContractRequest, DeleteOrRecoverContractResponse } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteOrRecoverContractRequest,
  DeleteOrRecoverContractResponse
>("RecoverContract", {
  request: "recover_contract"
});

export const recoverContractActions = socketMessageSlice.actions;
export const recoverContract = socketAction;
export default socketMessageSlice.reducer;
