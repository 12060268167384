export default {
  name: { type: "string" },
  costs: {
    type: "array",
    nullable: true,
    items: {
      type: "object",
      required: ["type", "costPrice", "sellPrice", "conditions"],
      properties: {
        type: {
          type: "string",
          enum: ["fixed", "percent"],
          errorMessage: {
            enum: "must be one of the following: 'fixed' or 'percent'",
          },
        },
        costPrice: { type: "number" },
        sellPrice: { type: "number" },
        conditions: {
          type: "array",
          items: {
            type: "object",
            required: ["type", "value", "field"],
            properties: {
              type: {
                type: "string",
                enum: ["<", ">", "=", "in"],
                errorMessage: {
                  enum: "must be one of the following: '<', '>', '=', 'in'",
                },
              },
              value: {
                anyOf: [
                  { type: "number" },
                  { type: "string" },
                  { type: "array" },
                ],
              },
              field: { type: "array", items: { type: "string" } },
            },
          },
        },
      },
    },
  },
};
