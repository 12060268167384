import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteDealerNoteRequest {
  _id: string;
}

interface DeleteDealerNoteResponse {
  message: {
    _id: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteDealerNoteRequest,
  DeleteDealerNoteResponse
>("DeleteDealerNote", {
  request: "delete_dealer_note"
});

export const deleteDealerNoteActions = socketMessageSlice.actions;
export const deleteDealerNote = socketAction;
export default socketMessageSlice.reducer;
