import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { ManagerProgram, AddManagerProgramRequest, FormState } from "./types";

interface AddManagerProgramResponse {
  message: ManagerProgram;
}
interface AddManagerProgramsRequestSend {
  availableToRolesIds: string[];
  data: FormState;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddManagerProgramsRequestSend,
  AddManagerProgramResponse
>("AddManagerProgram", {
  request: "new_manager_program"
});

export const addManagerProgramActions = socketMessageSlice.actions;

export const addManagerProgram = (request: AddManagerProgramRequest & Identifiable) => {
  return socketAction({
    requestId: request.requestId,
    availableToRolesIds: request.availableToRolesIds ?? [],
    data: request.data
  });
};
export default socketMessageSlice.reducer;
