import axios from "axios";
import { ChatMemberType } from "Chat/types/channels";
import { ChatMember } from "Chat/types/chat_members";
import { auth } from "../../firebase/firebase";

export const updateChatMember = (): ((_id: string) => Promise<ChatMember>) => {
  return async (_id) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/chat_member/${_id}`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return response.data.result;
  };
};

export const getChatMember = (email: string, type: ChatMemberType): (() => Promise<ChatMember>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/chat_member/${email}/${type}`,
      {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    );

    return response.data.result;
  };
};
