import { InputAdornment, TextField } from "@material-ui/core";
import { User } from "components/Users/types";
import useChatSearch from "Chat/hooks/useChatSearch";
import useDebounce from "Chat/hooks/useDebounce";
import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import ResultsTable from "./ResultsTable";
import { ResultsTableProps, SearchState } from "./types";
import styles from "./Search.module.css";
import { Applicant } from "components/Applicants/types";
import { RootState } from "app/rootReducer";
import { useSelector } from "react-redux";
import { DealershipUser } from "types/dealeship_users";

const transformSearchToSearchTerm = (search: string) => {
  if (!search) return {};
  return search?.length > 1 ? { search: { term: search } } : {};
};
const transformRequestDataToResultsTableProps = (requestData: {
  data: {
    applicants: Applicant[] | undefined;
    users: User[] | undefined;
    dealershipUsers: DealershipUser[] | undefined;
  };
  isLoading: {
    applicantsAreLoading: boolean;
    usersAreLoading: boolean;
    dealershipUsersAreLoading: boolean;
  };
  error: {
    applicantsError: unknown;
    usersError: unknown;
    dealershipUsersError: unknown;
  };
}): ResultsTableProps => {
  return {
    applicants: {
      data: requestData.data.applicants,
      loading: requestData.isLoading.applicantsAreLoading,
      error: requestData.error.applicantsError
    },
    users: {
      data: requestData.data.users,
      loading: requestData.isLoading.usersAreLoading,
      error: requestData.error.usersError
    },
    dealershipUsers: {
      data: requestData.data.dealershipUsers,
      loading: requestData.isLoading.applicantsAreLoading,
      error: requestData.error.dealershipUsersError
    }
  };
};
const Search = () => {
  const [state, setState] = useState<SearchState>({ value: "", focus: false });
  const currentUser = useSelector((state: RootState) => state?.authSlice);

  const debouncedValue = useDebounce<SearchState>(state, 1000);

  const requestResult = useChatSearch({
    ...transformSearchToSearchTerm(debouncedValue.value),
    //@ts-ignore
    query: {
      pipeline: "true",
      //@ts-ignore
      "data.info.email": { $ne: currentUser?.user?.databaseData?.data?.info?.email }
    }
  });

  return (
    <div className={styles.container}>
      <TextField
        value={state.value}
        type="text"
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <BiSearch style={{ color: "#373739", marginLeft: 10 }} size={25} />
            </InputAdornment>
          )
        }}
        style={{ background: "#F3F3F3", borderRadius: "5px", width: "100%", padding: "5px 0px" }}
        placeholder=""
        onChange={(e) => setState((prev) => ({ ...prev, value: e.target.value }))}
        onFocus={() => setState((prev) => ({ ...prev, focus: true }))}
        onBlur={() => setState((prev) => ({ ...prev, focus: false }))}
      />
      {state.focus && state.value.length > 1 && (
        <ResultsTable {...transformRequestDataToResultsTableProps(requestResult)} />
      )}
    </div>
  );
};
export default Search;
