import { RootState } from "app/rootReducer";
import LayoutWrapper from "components/Layout/LayoutWrapper";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import SignIn from "components/SignIn/SignIn";
import SignUp from "components/SignUp/SignUp";
import VerifyEmail from "components/SignUp/VerifyEmail";
import ForgotPassword from "components/Users/ForgotPassword/ForgotPassword";

import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error) => {}
  }),
  queryCache: new QueryCache({
    onError: (error) => {}
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default function App() {
  const { isAuthenticated, isVerifying } = useSelector((state: RootState) => state.authSlice);
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Switch>
          <ProtectedRoute
            path="/sign-up"
            component={SignUp}
            redirectTo="/"
            isAuthenticated={!isAuthenticated}
            isVerifying={false}
          />
          <ProtectedRoute
            exact
            path="/sign-in"
            component={SignIn}
            redirectTo="/"
            isAuthenticated={!isAuthenticated}
            isVerifying={false}
          />

          <ProtectedRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
            redirectTo="/"
            isAuthenticated={!isAuthenticated}
            isVerifying={false}
          />

          <ProtectedRoute
            exact
            path="/verify-email"
            component={VerifyEmail}
            redirectTo="/"
            isAuthenticated={!isAuthenticated}
            isVerifying={false}
          />

          <ProtectedRoute
            exact
            path={["/:entity", "/", "/:entity/:param"]}
            component={() => <LayoutWrapper />}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
        </Switch>
        <div id="sign-in-button"></div>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
