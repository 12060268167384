import Box from "@material-ui/core/Box";
import formEditContext from "components/Content/FormEditContext";
import React, { useContext, useState } from "react";
import { capitalize, formatNumberAsCurrency } from "utils/functions";
import { HTML_VIRTUAL_FIELD_SPLITTER } from "utils/htmlVirtualFieldsSplitter";
import { FormComponent, createOptionsForSelect } from "utils/models/fields";
import { RenderSet, StateAccess, generateForm } from "utils/models/formGenerator";
import VinCheck from "./VinCheck";
import { Customization, Deal, Driver } from "./types";
const canHaveTrailer = (stateAccess: StateAccess) =>
  stateAccess.get<Deal>(["data", "info", "type"]) === "Marine" ||
  stateAccess.get<Deal>(["data", "info", "type"]) === "Power Sport";

interface Props {
  stateAccess: StateAccess;
  path: string[];
  renderSet: RenderSet;
  name: string;
  label: string;
}

const trailerInfo: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Trailer",
  width: "full",
  entities: [
    {
      formComponent: "virtual-field",
      description: "Unit Status Year Make Model Serial",
      name: "unitStatusYearMakeModelSerial",
      label: "Unit Status  Year Make Model Serial",
      path: ["data", "info", "vehicle", "trailer", "unitStatusYearMakeModelSerial"],
      value: (stateAccess) => {
        return [
          capitalize(
            stateAccess.get<Deal>(["data", "info", "vehicle", "trailer", "unitStatus"])
          ),
          stateAccess.get<Deal>(["data", "info", "vehicle", "trailer", "year"]),
          stateAccess.get<Deal>(["data", "info", "vehicle", "trailer", "make"]),
          stateAccess.get<Deal>(["data", "info", "vehicle", "trailer", "model"]),
          stateAccess.get<Deal>(["data", "info", "vehicle", "trailer", "serialNumber"])
        ].join(" ");
      },
      default: null
    },
    {
      formComponent: "virtual-field",
      description: "Year Make Model Serial Or N/A",
      name: "yearMakeModelSerialOrNA",
      label: "Year Make Model Serial Or N/A",
      path: ["data", "info", "vehicle", "trailer", "yearMakeModelSerialOrNA"],
      value: (stateAccess) => {
        const dealType = stateAccess.get(["data", "info", "type"]);
        if (dealType === "Marine" || dealType === "Power Sport") {
          return [
            stateAccess.get<Deal>(["data", "info", "vehicle", "trailer", "year"]),
            stateAccess.get<Deal>(["data", "info", "vehicle", "trailer", "make"]),
            stateAccess.get<Deal>(["data", "info", "vehicle", "trailer", "model"]),
            stateAccess.get<Deal>(["data", "info", "vehicle", "trailer", "serialNumber"])
          ].join(" ");
        }
        return "N/A";
      },
      default: null
    },
    {
      formComponent: "uppercase-text-field",
      name: "serialNumber",
      label: "Serial number",
      width: "1/3",
      path: ["data", "info", "vehicle", "trailer", "serialNumber"]
    },
    {
      formComponent: "select-field",
      options: createOptionsForSelect({
        possibleValues: () => ["new", "used"],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      name: "unitStatus",
      label: "Unit Status",
      width: "1/3",
      path: ["data", "info", "vehicle", "trailer", "unitStatus"],
      default: null
    },
    {
      formComponent: "year-field",
      name: "year",
      label: "Year",
      width: "1/3",
      path: ["data", "info", "vehicle", "trailer", "year"],
      show: (stateAccess) => stateAccess.get<Deal>(["data", "info", "vehicle", "hasTrailer"]),
      default: null
    },
    {
      formComponent: "text-field",
      name: "make",
      label: "Make",
      width: "1/2",
      show: (stateAccess) => stateAccess.get<Deal>(["data", "info", "vehicle", "hasTrailer"]),
      path: ["data", "info", "vehicle", "trailer", "make"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "model",
      label: "Model",
      width: "1/2",
      show: (stateAccess) => stateAccess.get<Deal>(["data", "info", "vehicle", "hasTrailer"]),
      path: ["data", "info", "vehicle", "trailer", "model"],
      default: null
    }
  ]
};
const engine: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Main engine",
  width: "full",
  entities: [
    {
      formComponent: "uppercase-text-field",
      name: "engineNumber",
      label: "Engine number",
      width: "1/3",
      path: ["data", "info", "vehicle", "boat", "engine", "engineNumber"]
    },
    {
      formComponent: "select-field",
      options: createOptionsForSelect({
        possibleValues: () => ["new", "used"],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      name: "unitStatus",
      label: "Unit Status",
      width: "1/3",
      path: ["data", "info", "vehicle", "boat", "engine", "unitStatus"],
      default: null
    },
    {
      formComponent: "virtual-field",
      description: "Main and second engine numbers",
      name: "engineNumbers",
      label: "Engine Numbers",
      path: ["data", "info", "vehicle", "boat", "engine", "engineNumbers"],
      value: (stateAccess) => {
        return [
          stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "engine", "engineNumber"])
        ]
          .concat(
            !["", undefined, ""].includes(
              stateAccess.get<Deal>([
                "data",
                "info",
                "vehicle",
                "boat",
                "secondEngine",
                "engineNumber"
              ])
            )
              ? [
                  "/",
                  stateAccess.get<Deal>([
                    "data",
                    "info",
                    "vehicle",
                    "boat",
                    "secondEngine",
                    "engineNumber"
                  ])
                ]
              : []
          )
          .join(" ");
      },
      default: null
    },
    {
      formComponent: "year-field",
      name: "year",
      label: "Year",
      width: "1/3",
      path: ["data", "info", "vehicle", "boat", "engine", "year"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "make",
      label: "Make",
      width: "1/2",
      path: ["data", "info", "vehicle", "boat", "engine", "make"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "model",
      label: "Model",
      width: "1/2",
      path: ["data", "info", "vehicle", "boat", "engine", "model"],
      default: null
    },
    {
      formComponent: "virtual-field",
      description: "Main Engine Year Make Model Number or N/A",
      name: "yearMakeModelEngineNumberOrNA",
      label: "Year Make Model and Engine Number of the main and second or N/A",
      path: ["data", "info", "vehicle", "boat", "engine", "yearMakeModelEngineNumberOrNA"],
      value: (stateAccess) => {
        const dealType = stateAccess.get(["data", "info", "type"]);
        if (dealType === "Marine") {
          return [
            stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "engine", "year"]),
            stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "engine", "make"]),
            stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "engine", "model"]),
            stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "engine", "engineNumber"])
          ]
            .concat(
              stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "numberOfEngines"]) > 1
                ? [
                    "/",
                    stateAccess.get<Deal>([
                      "data",
                      "info",
                      "vehicle",
                      "boat",
                      "secondEngine",
                      "year"
                    ]),
                    stateAccess.get<Deal>([
                      "data",
                      "info",
                      "vehicle",
                      "boat",
                      "secondEngine",
                      "make"
                    ]),
                    stateAccess.get<Deal>([
                      "data",
                      "info",
                      "vehicle",
                      "boat",
                      "secondEngine",
                      "model"
                    ]),
                    stateAccess.get<Deal>([
                      "data",
                      "info",
                      "vehicle",
                      "boat",
                      "secondEngine",
                      "engineNumber"
                    ])
                  ]
                : []
            )
            .join(" ");
        }
        return "N/A";
      },
      default: null
    },
    {
      formComponent: "virtual-field",
      description: "Main Engine Status Year Make Model Number",
      name: "statusYearMakeModelEngineNumber",
      label: "Status Year Make Model and Engine Number of the main and second ",
      path: ["data", "info", "vehicle", "boat", "engine", "statusYearMakeModelEngineNumber"],
      value: (stateAccess) => {
        return [
          capitalize(
            stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "engine", "unitStatus"])
          ),
          stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "engine", "year"]),
          stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "engine", "make"]),
          stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "engine", "model"]),
          stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "engine", "engineNumber"])
        ]
          .concat(
            stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "numberOfEngines"]) > 1
              ? [
                  "/",
                  capitalize(
                    stateAccess.get<Deal>([
                      "data",
                      "info",
                      "vehicle",
                      "boat",
                      "secondEngine",
                      "unitStatus"
                    ])
                  ),
                  stateAccess.get<Deal>([
                    "data",
                    "info",
                    "vehicle",
                    "boat",
                    "secondEngine",
                    "year"
                  ]),
                  stateAccess.get<Deal>([
                    "data",
                    "info",
                    "vehicle",
                    "boat",
                    "secondEngine",
                    "make"
                  ]),
                  stateAccess.get<Deal>([
                    "data",
                    "info",
                    "vehicle",
                    "boat",
                    "secondEngine",
                    "model"
                  ]),
                  stateAccess.get<Deal>([
                    "data",
                    "info",
                    "vehicle",
                    "boat",
                    "secondEngine",
                    "engineNumber"
                  ])
                ]
              : []
          )
          .join(" ");
      },
      default: null
    },
    {
      formComponent: "virtual-field",
      description: "Main and second Engine Status Year Make Model",
      name: "statusYearMakeModel",
      label: "Status Year Make and Model of the main and second",
      path: ["data", "info", "vehicle", "boat", "engine", "statusYearMakeModel"],
      value: (stateAccess) => {
        return [
          capitalize(
            stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "engine", "unitStatus"])
          ),
          stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "engine", "year"]),
          stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "engine", "make"]),
          stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "engine", "model"])
        ]
          .concat(
            stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "numberOfEngines"]) > 1
              ? [
                  "/",
                  capitalize(
                    stateAccess.get<Deal>([
                      "data",
                      "info",
                      "vehicle",
                      "boat",
                      "secondEngine",
                      "unitStatus"
                    ])
                  ),
                  stateAccess.get<Deal>([
                    "data",
                    "info",
                    "vehicle",
                    "boat",
                    "secondEngine",
                    "year"
                  ]),
                  stateAccess.get<Deal>([
                    "data",
                    "info",
                    "vehicle",
                    "boat",
                    "secondEngine",
                    "make"
                  ]),
                  stateAccess.get<Deal>([
                    "data",
                    "info",
                    "vehicle",
                    "boat",
                    "secondEngine",
                    "model"
                  ])
                ]
              : []
          )
          .join(" ");
      },
      default: null
    }
  ]
};
const secondEngine: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Second engine",
  width: "full",
  entities: [
    {
      formComponent: "uppercase-text-field",
      name: "engineNumber",
      label: "Engine number",
      width: "1/3",
      path: ["data", "info", "vehicle", "boat", "secondEngine", "engineNumber"]
    },
    {
      formComponent: "select-field",
      options: createOptionsForSelect({
        possibleValues: () => ["new", "used"],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      name: "unitStatus",
      label: "Unit Status",
      width: "1/3",
      path: ["data", "info", "vehicle", "boat", "secondEngine", "unitStatus"],
      default: null
    },
    {
      formComponent: "year-field",
      name: "year",
      label: "Year",
      width: "1/3",
      path: ["data", "info", "vehicle", "boat", "secondEngine", "year"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "make",
      label: "Make",
      width: "1/2",
      path: ["data", "info", "vehicle", "boat", "secondEngine", "make"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "model",
      label: "Model",
      width: "1/2",
      path: ["data", "info", "vehicle", "boat", "secondEngine", "model"],
      default: null
    }
  ]
};
export const boatFields: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "radio-field",
      name: "boatType",
      label: "Boat type",
      width: "full",
      path: ["data", "info", "vehicle", "boat", "type"],
      possibleValues: [
        { label: "Fresh", value: "Fresh" },
        { label: "Salt", value: "Salt" },
        { label: "Both", value: "Both" }
      ]
    },
    {
      formComponent: "number-field",
      name: "length",
      label: "Length",
      width: "1/3",
      path: ["data", "info", "vehicle", "boat", "length"]
    },

    {
      formComponent: "number-field",
      name: "numberOfEngines",
      label: "Number of engines",
      width: "1/3",
      path: ["data", "info", "vehicle", "boat", "numberOfEngines"]
    },
    {
      formComponent: "radio-field",
      name: "engineType",
      label: "Engine type",
      width: "full",
      path: ["data", "info", "vehicle", "boat", "engineType"],
      possibleValues: [
        { label: "O/B", value: "0/B" },
        { label: "I/0", value: "I/0" },
        { label: "INBD", value: "INBD" },
        { label: "JET", value: "JET" }
      ]
    },
    {
      formComponent: "segment",
      width: "full",
      entities: [engine]
    },
    {
      formComponent: "virtual-field",
      description: "Engine #1 , Engine #2 , Trailer ot three rows",
      name: "Engine #1 , Engine #2 , Trailer",
      label: "Engine #1 , Engine #2 , Trailer",
      path: ["data", "info", "vehicle", "engine_1_engine_2_trailer"],
      value: (stateAccess) => {
        const dealData: Deal["data"] = stateAccess.get<Deal>(["data"]);
        let stringifiedValue = "";
        if (dealData.info.type === "Marine") {
          if (dealData?.info?.vehicle?.boat?.engine?.make) {
            stringifiedValue += [
              "Engine #1:",
              dealData?.info?.vehicle?.boat?.engine?.year,
              dealData?.info?.vehicle?.boat?.engine?.make,
              dealData?.info?.vehicle?.boat?.engine?.model,
              dealData?.info?.vehicle?.boat?.engine?.engineNumber
            ].join(" ");
          }
          if (dealData?.info?.vehicle?.boat?.secondEngine?.make) {
            stringifiedValue +=
              HTML_VIRTUAL_FIELD_SPLITTER +
              [
                "Engine #2:",
                dealData?.info?.vehicle?.boat?.secondEngine?.year,
                dealData?.info?.vehicle?.boat?.secondEngine?.make,
                dealData?.info?.vehicle?.boat?.secondEngine?.model,
                dealData?.info?.vehicle?.boat?.secondEngine?.engineNumber
              ].join(" ");
          }
        }
        if (dealData?.info?.vehicle?.hasTrailer) {
          stringifiedValue +=
            HTML_VIRTUAL_FIELD_SPLITTER +
            [
              "Trailer:",
              dealData?.info?.vehicle?.trailer?.year,
              dealData?.info?.vehicle?.trailer?.make,
              dealData?.info?.vehicle?.trailer?.model,
              dealData?.info?.vehicle?.trailer?.serialNumber
            ].join(" ");
        }
        return stringifiedValue;
      },
      default: null
    },
    {
      formComponent: "number-field",
      name: "horsepower",
      label: "Horsepower",
      width: "1/3",
      path: ["data", "info", "vehicle", "boat", "horsepower"]
    },
    {
      formComponent: "number-field",
      name: "CC",
      label: "CC",
      width: "1/3",
      path: ["data", "info", "vehicle", "boat", "cc"]
    },
    {
      formComponent: "number-field",
      name: "maxSpeed",
      label: "Max speed",
      width: "1/3",
      path: ["data", "info", "vehicle", "boat", "maxSpeed"]
    },
    {
      formComponent: "segment",
      width: "full",
      show: (stateAccess) =>
        stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "numberOfEngines"]) > 1,
      entities: [secondEngine]
    },
    {
      formComponent: "radio-field",
      name: "hullMaterial",
      label: "Hull material",
      width: "full",
      path: ["data", "info", "vehicle", "boat", "hull", "hullMaterial"],
      possibleValues: [
        { label: "FIBGL", value: "FIBGL" },
        { label: "ALUM", value: "ALUM" },
        { label: "OTHER", value: "OTHER" }
      ]
    },
    {
      formComponent: "currency-field",
      name: "hullValue",
      label: "Hull value",
      width: "1/3",
      path: ["data", "info", "vehicle", "boat", "hull", "hullValue"]
    },
    {
      formComponent: "virtual-field",
      name: "hullValueOrBlank",
      label: "Hull value or blank",
      description: "Hull value or blank",
      value: (stateAccess) =>
        typeof stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "hull", "hullValue"]) ===
        "number"
          ? formatNumberAsCurrency(
              stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "hull", "hullValue"])
            ) ?? ""
          : "",
      path: ["data", "info", "vehicle", "boat", "hull", "hullValueOrBlank"]
    },
    {
      formComponent: "currency-field",
      name: "engineValue",
      label: "Engine(s) value",
      width: "1/3",
      path: ["data", "info", "vehicle", "boat", "enginesValue"]
    },
    {
      formComponent: "virtual-field",
      name: "engineValueOrBlank",
      label: "Engine(s) value or blank",
      description: "Engine(s) value or blank",
      value: (stateAccess) =>
        typeof stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "enginesValue"]) ===
        "number"
          ? formatNumberAsCurrency(
              stateAccess.get<Deal>(["data", "info", "vehicle", "boat", "enginesValue"])
            ) ?? ""
          : "",
      path: ["data", "info", "vehicle", "boat", "enginesValueOrBlank"]
    }
  ]
};

export const powerSportFields: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  name: "Power sport",
  entities: [
    {
      formComponent: "text-field",
      name: "CC",
      label: "CC",
      width: "1/3",
      path: ["data", "info", "vehicle", "powerSport", "cc"]
    }
  ]
};

export const customization: FormComponent<Customization> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "text-field",
      name: "customization",
      label: "Customization",
      width: "full",
      path: ["customization"],
      required: true
    }
  ]
};
export const driver: FormComponent<Driver> = {
  formComponent: "segment",
  width: "1/2",
  entities: [
    {
      formComponent: "text-field",
      name: "firstName",
      label: "First name",
      width: "1/3",
      path: ["firstName"],
      required: true
    },
    {
      formComponent: "text-field",
      name: "middleName",
      label: "Middle name",
      width: "1/3",
      path: ["middleName"],
      required: true
    },
    {
      formComponent: "text-field",
      name: "lastName",
      label: "Last name",
      width: "1/3",
      path: ["lastName"],
      required: true
    },
    {
      formComponent: "text-field",
      name: "licenseNumber",
      label: "License number",
      width: "1/3",
      path: ["driverLicenseNumber"],
      default: null
    },
    {
      formComponent: "phone-field",
      name: "mobilePhone",
      label: "Mobile phone",
      width: "1/3",
      path: ["mobilePhone"],
      default: null
    },
    {
      formComponent: "phone-field",
      name: "homePhone",
      label: "Home phone",
      width: "1/3",
      path: ["homePhone"],
      default: null
    },
    {
      formComponent: "phone-field",
      name: "businessPhone",
      label: "Business phone",
      width: "1/3",
      path: ["businessPhone"],
      default: null
    },
    {
      formComponent: "date-with-age-field",
      name: "birthDate",
      label: "Date of birth",
      required: true,
      width: "2/3",
      path: ["birthDate"],
      disableRegion: "future",
      default: null
    },
    {
      formComponent: "email-field",
      name: "Email",
      label: "Email",
      required: true,
      width: "full",
      path: ["email"],
      default: null
    },
    {
      formComponent: "address-field",
      name: "Address",
      required: true,
      label: "Address",
      path: ["propertyAddress"],
      width: "full",
      default: null
    },
    {
      formComponent: "radio-field",
      name: "propertyOwnership",
      label: "Property ownership",
      width: "1/2",
      path: ["propertyOwnership"],
      possibleValues: [
        { label: "Owner", value: "Owner" },
        { label: "Tenant", value: "Tenant" }
      ],
      default: null
    },
    {
      formComponent: "radio-field",
      name: "FamilyRelation",
      label: "Family Relation",
      width: "1/2",
      path: ["familyRelation"],
      possibleValues: [
        { label: "Spouse", value: "Spouse" },
        { label: "Child", value: "Child" },
        { label: "Other", value: "Other" }
      ],
      default: null
    }
  ]
};
export const powerSportsTypes = [
  "ATV",
  "UTV",
  "Sport",
  "Can-Am",
  "Dual bikes",
  "Snowmobile",
  "Motorcycle",
  "Sport Bike",
  "Jet Ski",
  "Scooter / Moped",
  "Cruiser / Touring",
  "Lehman Trike",
  "Off-Road"
];
export const vehicleForm: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "virtual-field",
      name: "isMotorVehicle",
      label: "X Is Motor Vehicle?",
      value: (stateAccess) => stateAccess.get<Deal>(["data", "info", "type"]) !== "Marine",
      path: ["data", "info", "vehicle", "isMotorVehicle"],
      description: "Displays X if collateral is Marine one",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "isAutomotiveVehicle",
      label: "X Is Automotive Vehicle?",
      value: (stateAccess) => stateAccess.get<Deal>(["data", "info", "type"]) === "Automotive",
      path: ["data", "info", "vehicle", "isAutomotiveVehicle"],
      description: "Displays X if collateral is Automotive one",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "isPowerSportVehicle",
      label: "X Is Power Sport Vehicle?",
      value: (stateAccess) => stateAccess.get<Deal>(["data", "info", "type"]) === "Power Sport",
      path: ["data", "info", "vehicle", "isPowerSportVehicle"],
      description: "Displays X if collateral is Power Sport one",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "isRVVehicle",
      label: "X Is RV Vehicle?",
      value: (stateAccess) =>
        stateAccess.get<Deal>(["data", "info", "type"]) === "Recreational Vehicles",
      path: ["data", "info", "vehicle", "isRVVehicle"],
      description: "Displays X if collateral is RV one",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "isBoat",
      label: "X Is Boat?",
      value: (stateAccess) => stateAccess.get<Deal>(["data", "info", "type"]) === "Marine",
      path: ["data", "info", "vehicle", "isBoat"],
      description: "Displays X if collateral is Boat one",
      default: null
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess, mainStateAccess, renderSet: RenderSet) => (
        <VinCheck
          stateAccess={stateAccess}
          renderSet={renderSet}
          type={"deal"}
          path={["data", "info", "vehicle", "VIN"]}
          isVinDecodedPath={["data", "info", "vehicle", "isVinDecoded"]}
          name="vin"
          label="Vin"
        />
      ),
      name: "vin",
      label: "Vin",
      width: "full",
      path: ["data", "info", "vehicle", "VIN"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "vin",
      label: "VIN",
      value: (stateAccess) => stateAccess.get<Deal>(["data", "info", "vehicle", "VIN"]),
      path: ["data", "info", "vehicle", "VIN"],
      description: "displays VIN number",
      width: "hidden",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "lastSixVINDigits",
      label: "Last six VIN digits",
      value: (stateAccess) =>
        stateAccess
          .get<Deal>(["data", "info", "vehicle", "VIN"])
          ?.slice(-6) ?? "",
      path: ["data", "info", "vehicle", "lastSixVINDigits"],
      description: "displays the last six VIN digits",
      width: "hidden",
      default: null
    },
    {
      formComponent: "segment",
      name: "VIN digits",
      show: () => false,
      entities: new Array(17).fill(0).map(
        (x, index) =>
          ({
            formComponent: "virtual-field",
            name: `vin${index}`,
            label: index.toString(),
            value: (stateAccess) =>
              stateAccess.get<Deal>(["data", "info", "vehicle", "VIN"])?.[index] ?? "",
            path: ["data", "info", "vehicle", `VIN${index}`],
            description: "displays VIN digit",
            width: "hidden",
            default: null
          } as FormComponent<Deal>)
      )
    },
    {
      formComponent: "virtual-field",
      name: "unitStatusYearMakeModel",
      label: "UnitStatus Year Make Model",
      value: (stateAccess) => {
        const vehicle: Deal["data"]["info"]["vehicle"] = stateAccess.get<Deal>([
          "data",
          "info",
          "vehicle"
        ]);
        const unitStatus = vehicle?.unitStatus ?? null;
        const year = vehicle?.year ?? null;
        const make = vehicle?.make ?? null;
        const model = vehicle?.model ?? null;

        return [capitalize(unitStatus ?? undefined), year, make, model].join(" ");
      },
      description: "Displays: unitStatus year make model of the vehicle",
      path: ["data", "info", "vehicle", "unitStatusYearMakeModel"],
      width: "hidden",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "unitStatusYearMakeModelVIN",
      label: "UnitStatus Year Make Model VIN",
      value: (stateAccess) => {
        const vehicle: Deal["data"]["info"]["vehicle"] = stateAccess.get<Deal>([
          "data",
          "info",
          "vehicle"
        ]);
        const unitStatus = vehicle?.unitStatus ?? null;
        const year = vehicle?.year ?? null;
        const make = vehicle?.make ?? null;
        const model = vehicle?.model ?? null;
        const VIN = vehicle?.VIN ?? null;

        return [capitalize(unitStatus ?? undefined), year, make, model, VIN].join(" ");
      },
      description: "Displays: unitStatus year make model VIN of the vehicle",
      path: ["data", "info", "vehicle", "unitStatusYearMakeModelVIN"],
      width: "hidden",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "yearMakeModel",
      label: "Year Make Model",
      value: (stateAccess) => {
        const vehicle = stateAccess.get<Deal>(["data", "info", "vehicle"]);
        const year = vehicle?.year ?? null;
        const make = vehicle?.make ?? null;
        const model = vehicle?.model ?? null;

        return [year, make, model].join(" ");
      },
      description: "Displays: year make model of the vehicle",
      path: ["data", "info", "vehicle", "yearMakeModel"],
      width: "hidden",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "makeModel",
      label: "Make Model",
      value: (stateAccess) => {
        const vehicle = stateAccess.get<Deal>(["data", "info", "vehicle"]);
        const make = vehicle?.make ?? null;
        const model = vehicle?.model ?? null;

        return [make, model].join(" ");
      },
      description: "Displays: year make model of the vehicle",
      path: ["data", "info", "vehicle", "makeModel"],
      width: "hidden",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "yearMakeModelVIN",
      label: "Year Make Model VIN",
      value: (stateAccess) => {
        const vehicle = stateAccess.get<Deal>(["data", "info", "vehicle"]);
        const year = vehicle?.year ?? null;
        const make = vehicle?.make ?? null;
        const model = vehicle?.model ?? null;
        const VIN = vehicle?.VIN ?? null;

        return [year, make, model, VIN].join(" ");
      },
      description: "Displays: year make model VIN of the vehicle",
      path: ["data", "info", "vehicle", "yearMakeModelVIN"],
      width: "hidden",
      default: null
    },
    {
      formComponent: "year-field",
      name: "year",
      label: "Year",
      width: "1/3",
      path: ["data", "info", "vehicle", "year"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "make",
      label: "Make",
      width: "1/3",
      path: ["data", "info", "vehicle", "make"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "model",
      label: "Model",
      width: "1/3",
      path: ["data", "info", "vehicle", "model"],
      default: null
    },
    {
      formComponent: "segment",
      name: "Boat/Other utils",
      entities: [
        {
          formComponent: "virtual-field",
          name: "boatYear",
          description: "If the vehicle is a boat, the year of it, otherwise empty",
          label: "Boat year",
          width: "1/3",
          path: ["data", "info", "vehicle", "boatOrOther", "boatYear"],
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "info", "type"]) === "Marine"
              ? stateAccess.get<Deal>(["data", "info", "vehicle", "year"])
              : "",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "boatMake",
          description: "If the vehicle is a boat, the make of it, otherwise empty",
          label: "Boat make",
          width: "1/3",
          path: ["data", "info", "vehicle", "boatOrOther", "boatMake"],
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "info", "type"]) === "Marine"
              ? stateAccess.get<Deal>(["data", "info", "vehicle", "make"])
              : "",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "boatModel",
          description: "If the vehicle is a boat, the model of it, otherwise empty",
          label: "Boat model",
          width: "1/3",
          path: ["data", "info", "vehicle", "boatOrOther", "boatModel"],
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "info", "type"]) === "Marine"
              ? stateAccess.get<Deal>(["data", "info", "vehicle", "model"])
              : "",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "boatVin",
          description: "If the vehicle is a boat, the vin of it, otherwise empty",
          label: "Boat VIN",
          width: "1/3",
          path: ["data", "info", "vehicle", "boatOrOther", "boatVIN"],
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "info", "type"]) === "Marine"
              ? stateAccess.get<Deal>(["data", "info", "vehicle", "VIN"])
              : "",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "notBoatYear",
          description: "If the vehicle is NOT a boat, the year of it, otherwise empty",
          label: "(Not boat) year",
          width: "1/3",
          path: ["data", "info", "vehicle", "boatOrOther", "notBoatYear"],
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "info", "type"]) !== "Marine"
              ? stateAccess.get<Deal>(["data", "info", "vehicle", "year"])
              : "",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "notBoatMake",
          description: "If the vehicle is NOT a boat, the make of it, otherwise empty",
          label: "(Not boat) make",
          width: "1/3",
          path: ["data", "info", "vehicle", "boatOrOther", "notBoatMake"],
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "info", "type"]) !== "Marine"
              ? stateAccess.get<Deal>(["data", "info", "vehicle", "make"])
              : "",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "notBoatModel",
          description: "If the vehicle is NOT a boat, the model of it, otherwise empty",
          label: "(Not boat) model",
          width: "1/3",
          path: ["data", "info", "vehicle", "boatOrOther", "notBoatModel"],
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "info", "type"]) !== "Marine"
              ? stateAccess.get<Deal>(["data", "info", "vehicle", "model"])
              : "",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "notBoatVin",
          description: "If the vehicle is a boat, the vin of it, otherwise empty",
          label: "(Not boat) VIN",
          width: "1/3",
          path: ["data", "info", "vehicle", "boatOrOther", "notBoatVIN"],
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "info", "type"]) !== "Marine"
              ? stateAccess.get<Deal>(["data", "info", "vehicle", "VIN"])
              : "",
          default: null
        }
      ],
      show: () => false
    },

    {
      formComponent: "segment",
      name: "Land vehicle",
      show: () => false,
      entities: [
        {
          formComponent: "virtual-field",
          name: "year",
          description:
            "If the vehicle is a boat, the year of the trailer, otherwise the year of the vehicle",
          label: "Land year",
          width: "1/3",
          path: ["data", "info", "vehicle", "land", "year"],
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "info", "type"]) === "Marine"
              ? stateAccess.get<Deal>(["data", "info", "vehicle", "trailer", "year"])
              : stateAccess.get<Deal>(["data", "info", "vehicle", "year"]),
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "make",
          description:
            "If the vehicle is a boat, the make of the trailer, otherwise the make of the vehicle",
          label: "Land make",
          width: "1/3",
          path: ["data", "info", "vehicle", "land", "make"],
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "info", "type"]) === "Marine"
              ? stateAccess.get<Deal>(["data", "info", "vehicle", "trailer", "make"])
              : stateAccess.get<Deal>(["data", "info", "vehicle", "make"]),
          default: null
        },
        {
          formComponent: "virtual-field",
          description:
            "If the vehicle is a boat, the model of the trailer, otherwise the model of the vehicle",
          name: "model",
          label: "Land model",
          width: "1/3",
          path: ["data", "info", "vehicle", "land", "model"],
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "info", "type"]) === "Marine"
              ? stateAccess.get<Deal>(["data", "info", "vehicle", "trailer", "model"])
              : stateAccess.get<Deal>(["data", "info", "vehicle", "model"]),
          default: null
        },
        {
          formComponent: "virtual-field",
          description:
            "If the vehicle is a boat, the SerialNumber of the trailer, otherwise the VIN of the vehicle",
          name: "VIN",
          label: "Land VIN",
          width: "1/3",
          path: ["data", "info", "vehicle", "land", "VIN"],
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "info", "type"]) === "Marine"
              ? stateAccess.get<Deal>(["data", "info", "vehicle", "trailer", "serialNumber"])
              : stateAccess.get<Deal>(["data", "info", "vehicle", "VIN"]),
          default: null
        },
        {
          formComponent: "virtual-field",
          description:
            "If the vehicle is a boat, 'trailer', otherwise the unity type of the vehicle",
          name: "unitType",
          label: "Land unit type",
          width: "1/3",
          path: ["data", "info", "vehicle", "land", "unitType"],
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "info", "type"]) === "Marine"
              ? "trailer"
              : stateAccess.get<Deal>(["data", "info", "vehicle", "unitType"]),
          default: null
        }
      ]
    },

    {
      formComponent: "virtual-field",
      name: "otherinfo",
      description:
        "If the vehicle is a boat, the trailer and engine year/make/model, otherwise N/A",
      label: "Other info",
      path: ["data", "info", "vehicle", "otherInfo"],
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get([]);
        const trailer = deal?.data?.info?.vehicle?.trailer;
        const engine = deal?.data?.info?.vehicle?.boat?.engine;
        const secondEngine = deal?.data?.info?.vehicle?.boat?.secondEngine;

        const firstLine =
          trailer?.make && canHaveTrailer(stateAccess)
            ? [trailer.year, trailer.make, trailer.model, trailer.serialNumber].join(" ")
            : null;
        const secondLine =
          engine?.make && deal?.data?.info?.type === "Marine"
            ? [engine.year, engine.make, engine.model, engine.engineNumber].join(" ")
            : null;
        const thirdLine =
          secondEngine?.make && deal?.data?.info?.type === "Marine"
            ? [
                secondEngine.year,
                secondEngine.make,
                secondEngine.model,
                secondEngine.engineNumber
              ].join(" ")
            : null;

        return (
          [firstLine, secondLine, thirdLine]
            .filter((x) => x !== null)
            .join(HTML_VIRTUAL_FIELD_SPLITTER) || "N/A"
        );
      }
    },
    {
      formComponent: "virtual-field",
      name: "otherInfoEngineOneLine",
      description:
        "If the vehicle is a boat, the trailer and engine year/make/model, otherwise N/A",
      label: "Other info Engine on one line",
      path: ["data", "info", "vehicle", "otherInfoEngineOneLine"],
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get([]);
        const trailer = deal?.data?.info?.vehicle?.trailer;
        const engine = deal?.data?.info?.vehicle?.boat?.engine;
        const secondEngine = deal?.data?.info?.vehicle?.boat?.secondEngine;

        const firstLine =
          trailer?.make && canHaveTrailer(stateAccess)
            ? ["Trailer:", trailer.year, trailer.make, trailer.model, trailer.serialNumber].join(
                " "
              )
            : null;
        const mainEngineInfo =
          engine?.make && deal?.data?.info?.type === "Marine"
            ? ["Engine(s):", engine?.year, engine?.make, engine?.model, engine?.engineNumber].join(
                " "
              )
            : null;
        const secondEngineInfo =
          secondEngine?.make && deal?.data?.info?.type === "Marine"
            ? [
                secondEngine?.year,
                secondEngine?.make,
                secondEngine?.model,
                secondEngine?.engineNumber
              ].join(" ")
            : null;

        const secondLine = [mainEngineInfo, secondEngineInfo].filter((x) => x !== null).join(" / ");

        return (
          [firstLine, ...(secondLine.length > 0 ? [secondLine] : [])]
            .filter((x) => x !== null)
            .join(HTML_VIRTUAL_FIELD_SPLITTER) || "N/A"
        );
      }
    },

    {
      formComponent: "number-field",
      name: "odometer",
      label: "Odometer",
      width: "1/3",
      path: ["data", "info", "vehicle", "odometer"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "odometerFormatted",
      label: "Odometer Formatted",
      width: "hidden",
      description: "displays formatted odometer",
      value: (stateAccess) => {
        return new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }).format(
          stateAccess.get<Deal>(["data", "info", "vehicle", "odometer"]) || 0
        );
      },
      path: ["data", "info", "vehicle", "odometerFormatted"]
    },
    {
      formComponent: "virtual-field",
      name: "unitIsNew",
      label: "X Unit is new?",
      value: (stateAccess) =>
        stateAccess.get<Deal>(["data", "info", "vehicle", "unitStatus"]) === "new",
      description: "Displays X if unitStatus is set to new",
      path: ["data", "info", "vehicle", "unitIsNew"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "unitIsUsed",
      label: "X Unit is used?",
      value: (stateAccess) =>
        stateAccess.get<Deal>(["data", "info", "vehicle", "unitStatus"]) === "used",
      description: "Displays X if unitStatus is set to used",
      path: ["data", "info", "vehicle", "unitIsUsed"],
      width: "hidden"
    },
    {
      formComponent: "select-field",
      options: createOptionsForSelect({
        possibleValues: () => ["new", "used"],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      name: "unitStatus",
      label: "Unit Status",
      width: "1/3",
      path: ["data", "info", "vehicle", "unitStatus"],
      default: ""
    },

    {
      formComponent: "select-field",
      options: createOptionsForSelect({
        possibleValues: (stateAccess: StateAccess) => {
          switch (stateAccess.get<Deal>(["data", "info", "type"])) {
            case "Automotive":
              return ["Aut", "Truck"];
            case "Marine":
              return ["Runabout", "Pontoon", "Fishing Boat", "Sport Boat", "Cabin Cruiser"];
            case "Recreational Vehicles":
              return [
                "Travel Trailer",
                "5th Wheel",
                "Trl",
                "Park Model",
                "Class A",
                "Class B",
                "Class C",
                "Pop-up",
                "Tent Camper"
              ];
            case "Power Sport":
              return powerSportsTypes;
            default:
              return [];
          }
        },
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      name: "unitType",
      label: "Unit Type",
      width: "1/3",
      path: ["data", "info", "vehicle", "unitType"],
      default: "",
      required: (stateAccess) => stateAccess.get<Deal>(["data", "info", "vehicle", "VIN"])
    },
    ...(powerSportsTypes.map((type) => ({
      formComponent: "virtual-field",
      name: `is${type}`,
      label: `X is ${type}?`,
      value: (stateAccess) =>
        stateAccess.get<Deal>(["data", "info", "vehicle", "unitType"]) === type,
      description: `Displays X if unitType is ${type}`,
      path: ["data", "info", "vehicle", `unitTypeIs${type}`],
      width: "hidden"
    })) as FormComponent<Deal>[]),
    {
      formComponent: "currency-field",
      name: "invoice",
      label: "Invoice",
      width: "1/2",
      path: ["data", "info", "vehicle", "invoice"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "msrp",
      label: "MSRP",
      width: "1/2",
      path: ["data", "info", "vehicle", "MSRP"],
      show: (stateAccess: StateAccess) =>
        stateAccess.get<Deal>(["data", "info", "vehicle", "unitStatus"]) !== "used",
      default: null
    },
    {
      formComponent: "currency-field",
      name: "nada",
      label: "NADA",
      width: "1/2",
      path: ["data", "info", "vehicle", "MSRP"],
      show: (stateAccess: StateAccess) =>
        stateAccess.get<Deal>(["data", "info", "vehicle", "unitStatus"]) === "used",
      default: null
    },
    {
      formComponent: "segment",
      width: "full",
      entities: [boatFields],
      show: (stateAccess) => stateAccess.get<Deal>(["data", "info", "type"]) === "Marine"
    },
    {
      formComponent: "segment",
      width: "full",
      entities: [powerSportFields],
      show: (stateAccess) => stateAccess.get<Deal>(["data", "info", "type"]) === "Power Sport"
    },
    {
      formComponent: "checkbox-field",
      name: "hasTrailer",
      label: "Has trailer?",
      width: "1/3",
      show: (stateAccess) => canHaveTrailer(stateAccess),
      toggle: (stateAccess: StateAccess) =>
        stateAccess.set<Deal>(
          ["data", "info", "vehicle", "hasTrailer"],
          !stateAccess.get<Deal>(["data", "info", "vehicle", "hasTrailer"])
        ),
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<Deal>(["data", "info", "vehicle", "hasTrailer"]) === true,
      path: ["data", "info", "vehicle", "hasTrailer"],
      default: false
    },
    {
      formComponent: "segment",
      width: "full",
      show: (stateAccess) =>
        canHaveTrailer(stateAccess) &&
        stateAccess.get<Deal>(["data", "info", "vehicle", "hasTrailer"]),
      entities: [trailerInfo]
    }
  ]
};

export default function VehiclePreview({ stateAccess, renderSet }: Props) {
  const [open, setOpen] = useState(false);

  const { enabled: showButton } = useContext(formEditContext);
  if (!showButton && open) setOpen(!open);

  return <Box>{generateForm(vehicleForm, stateAccess, [], stateAccess, renderSet)}</Box>;
}
