import { Schema } from "ajv";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import { CustomRegexes } from "../../helpers";

export const data: Schema = {
  type: "object",
  properties: {
    info: {
      type: "object",
      properties: {
        entityName: {
          type: "string",
          pattern: CustomRegexes.LETTERS,
          errorMessage: {
            pattern: "should not contain numbers",
          },
        },
        entityId: { type: "string" },
        action: {
          type: "string",
          pattern: CustomRegexes.LETTERS,
          errorMessage: {
            pattern: "should not contain numbers",
          },
          nullable: true,
        },
        userEmail: {
          type: "string",
          nullable: true,
          pattern: CustomRegexes.EMAIL,
          errorMessage: {
            pattern: "should match the format <emailname>@<provider>.<org>",
          },
        },
      },
    },
  },
};

export default {
  update_lock: {
    $id: "updateLock",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
  all_locks: {
    $id: "allLock",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
