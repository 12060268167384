import React, { useState, useContext } from "react";

import { Button, Grid } from "@material-ui/core";
import { StateAccess } from "utils/models/formGenerator";
import DocumentsToBeReturnedModal from "./DocumentsToBeReturnedModal";
import { OneSpanSigning, OneSpanStatus } from "./types";
import formEditContext from "../../Content/FormEditContext";

import BorderColorIcon from "@material-ui/icons/BorderColor";
import { Deal } from "../types";
const oneSpanStatusButtonStyle = (eSignings: OneSpanSigning[] | undefined) =>
  eSignings && eSignings?.every((x) => x.data.info.status.overall === OneSpanStatus.notSigned)
    ? { backgroundColor: "#f50057", color: "#fff" }
    : eSignings?.every((x) => x.data.info.status.overall === OneSpanStatus.signed)
    ? { backgroundColor: "#50a538", color: "#fff" }
    : { backgroundColor: "#eed202", color: "#000" };

export default function ({ stateAccess }: { stateAccess: StateAccess }) {
  const [open, setOpen] = useState(false);
  const { enabled: editMode } = useContext(formEditContext);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const eSignings = stateAccess.get<Deal>(["data", "oneSpanSignings"]) ?? [];
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            id="documents-to-be-returned-modal-button"
            startIcon={<BorderColorIcon />}
            disabled={!editMode}
            disableElevation
            onClick={handleClickOpen}
          >
            Funding documents
          </Button>
        </Grid>
      </Grid>
      {open && <DocumentsToBeReturnedModal stateAccess={stateAccess} handleClose={handleClose} />}
    </>
  );
}
