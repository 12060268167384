import { CustomRegexes } from "../../helpers";

export default {
  state: {
    type: "string",
  },
  contactNumbers: {
    type: "array",
    items: {
      type: "object",
      required: [],
      properties: {
        contactNumber: {
          type: "string",
          pattern: CustomRegexes.PHONE,
          errorMessage: {
            pattern: "must contain only numbers and cannot start with 0 or 1",
          },
          nullable: true,
        },
      },
    },
  },
};
