import { Box, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { HintTooltip } from "components/common/HintTooltip";
import React from "react";
import { ZipCodeField } from "utils/models/fields";
import { RenderSet, StateAccess } from "utils/models/formGenerator";

export default <T extends unknown>({
  props,
  stateAccess,
  renderSet
}: {
  props: ZipCodeField<T>;
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) => {
  const { path, name, label, required } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    if (props.zipCodeSearch) props.zipCodeSearch(stateAccess);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Enter" && props.zipCodeSearch) {
      event?.preventDefault();
      props.zipCodeSearch(stateAccess);
    }
  };
  return (
    <Box style={{ display: "flex" }}>
      {renderSet.textInputRenderer(
        {
          type: "text",
          name,
          label,
          required,
          path: path as [],
          removeEmptySpaces: true
        },
        stateAccess,
        renderSet
      )}
      {!props?.hideZipCodeSearch && (
        <IconButton
          color="primary"
          aria-label="search"
          style={{ marginLeft: "10px" }}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
        >
          <HintTooltip title="Click here to lookup address information based on ZIP code.">
            <SearchIcon />
          </HintTooltip>
        </IconButton>
      )}
    </Box>
  );
};
