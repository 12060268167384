import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { ContractResponse, ContractToEditSend, EditContractRequest } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  ContractToEditSend,
  ContractResponse
>("EditContract", {
  request: "update_contract"
});

export const editContract = (request: EditContractRequest & Identifiable) => {
  const { refNumber, ...rest } = request.data.info;

  return socketAction({
    requestId: request.requestId,
    _id: request._id,
    approved: request?.approved ?? null,
    approvedAt: request.approvedAt ?? undefined,
    approvedByUserId: request.approvedByUserId ?? undefined,
    availableToRolesIds: request.availableToRolesIds ?? [],
    data: {
      info: rest,
      contractTypeIds: request.data.contractTypes?.map((el) => el?._id) ?? [],
      lenderIds: request.data.lenders?.map((el) => el?._id) ?? [],
      userIds: request.data.users?.map((el) => el?._id) ?? [],
      dealershipIds: request.data.dealerships?.map((el) => el?._id) ?? [],
      otherVendorIds: request.data.otherVendors?.map((el) => el?._id) ?? [],
      vendorTypes: request?.data?.vendorTypes ?? [],
      products: request?.data?.products ?? [],
      externalProviders: request?.data?.externalProviders ?? []
    }
  });
};
export const editContractActions = socketMessageSlice.actions;

export default socketMessageSlice.reducer;
