import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import Typography from "@material-ui/core/Typography";
import { formatNumberAsCurrency, getMonthDaysAsAnArray } from "utils/functions";
import ReactApexChart from "react-apexcharts";
import Paper from "@material-ui/core/Paper";
import { DealStatus } from "components/Deals/types";
import { randomNumber } from "./FIManagerDashboard";
import { User, UserStatistics } from "components/Users/types";
import { getUserList, removeUserList } from "components/Users/listUserSlice";
import { findPeriodCommission } from "components/Deals/Calculations";
import { ManagerProgram } from "components/ManagerPrograms/types";
import {
  getManagerProgramList,
  removeManagerProgramList
} from "components/ManagerPrograms/listManagerProgramSlice";
import { getDealCommissionByDays } from "./dealCommissionByDaysSlice";
import { getChargebackList } from "components/Chargebacks/listChargebackSlice";
interface Props {
  from: Date;
  to: Date;
  user?: User;
  showNumbers: boolean;
}
const round = (number: number) => {
  return Math.round(number * 100) / 100;
};
const commissionStatuses = [DealStatus.SubmittedForFunding, DealStatus.Funded];
const getPvr = (year: number, month: number, statistics?: UserStatistics[]) =>
  Array.isArray(statistics) && statistics?.length
    ? statistics?.find((stat) => stat.year === year && stat.month === month)?.pvr || 0
    : 0;

const getCommissionPercentage = (
  user: User | undefined,
  managerPrograms: ManagerProgram[] | undefined,
  date: Date
) => {
  const commission = findPeriodCommission(user?.data?.info?.periodCommissions, date)
    ?.commissions[0];

  const program = managerPrograms?.find(
    (program) => program._id.valueOf() === commission?.managerProgramId?.valueOf()
  );
  const pvr = getPvr(date?.getFullYear(), date?.getMonth() + 1, user?.data?.statistics) || 0;
  const foundTier = program?.data?.info?.tiers?.filter((tier) => {
    return tier.conditions.every((cond) => {
      const criteria = cond.criteria === "pvr" ? pvr : 0;
      switch (cond?.type) {
        case ">=":
          return criteria >= cond?.value;
        case "<=":
          return criteria <= cond?.value;
        default:
          return [];
      }
    });
  })?.[0];

  return foundTier;
};
export default ({ showNumbers, from, to, user }: Props) => {
  const dispatch = useDispatch();
  const requestIdByDay = "dealCommissionByDay";

  useEffect(() => {
    dispatch(
      getDealCommissionByDays(requestIdByDay, {
        query: {
          from,
          to,
          statuses: commissionStatuses,
          userIds: users.map((user) => user._id)
        }
      })
    );
  }, [from, to]);

  const managerProgramRequestId = "managerPrograms";
  const requestId = "dealCommissionByStatus";
  const usersList = useSelector((state: RootState) => state.listUserSlice["users-for-pvr"]);
  const users =
    usersList?.entities?.filter((user) => {
      return user?.data?.rolesIds?.includes("5fc8ca4b06d7921827342b2d");
    }) || [];
  const managerProgramsList = useSelector(
    (state: RootState) => state.listManagerProgramSlice[managerProgramRequestId]
  );
  const totalCommission = useSelector(
    (state: RootState) => state.listDealCommisionByStatusSlice[requestId]
    // @ts-ignore
  )?.entities?.reduce((acc, { _id, commission }) => {
    // @ts-ignore
    return acc + (commissionStatuses.includes(_id) ? commission || 0 : 0);
  }, 0);
  const totalReserveCommission = useSelector(
    (state: RootState) => state.listDealCommisionByStatusSlice[requestId]
    // @ts-ignore
  )?.entities?.reduce((acc, { _id, reserveCommission }) => {
    // @ts-ignore
    return acc + (commissionStatuses.includes(_id) ? reserveCommission || 0 : 0);
  }, 0);
  const totalProductCommission = useSelector(
    (state: RootState) => state.listDealCommisionByStatusSlice[requestId]
    // @ts-ignore
  )?.entities?.reduce((acc, { _id, productCommission }) => {
    // @ts-ignore
    return acc + (commissionStatuses.includes(_id) ? productCommission || 0 : 0);
  }, 0);

  const chargebacksList = useSelector((state: RootState) => state.listChargebackSlice[requestId]);

  const totalChargebackAmount = chargebacksList?.entities?.reduce((acc, chargeback) => {
    return (
      acc +
      (chargeback.data.managerId === user?._id
        ? Number(chargeback.data.info.managerChargebackAmount) || 0
        : 0)
    );
  }, 0);

  React.useEffect(() => {
    dispatch(
      getChargebackList(requestId, {
        query: {
          "data.info.chargebackDate": {
            $gte: from.toISOString(),
            $lte: to.toISOString()
          },
          "data.managerId": user?._id
        },
        options: { limit: 10000 }
      })
    );
  }, [requestId, from, to]);

  React.useEffect(() => {
    if (!managerProgramsList) {
      dispatch(getManagerProgramList(managerProgramRequestId));
    }
    if (!usersList) {
      dispatch(
        getUserList("users-for-pvr", {
          options: {
            pagination: false,
            projection: {
              _id: 1,
              "data.info.firstName": 1,
              "data.info.lastName": 1,
              "data.info.userType": 1,
              "data.statistics": 1,
              "data.rolesIds": 1
            },
            sort: { "data.info.firstName": "asc" }
          },
          query: {
            "data.info.userType": "f&i manager"
          }
        })
      );
    }
    return () => {
      dispatch(removeUserList("users-for-pvr"));
      dispatch(removeManagerProgramList(managerProgramRequestId));
    };
  }, []);
  const commissionPercentages = getCommissionPercentage(
    user,
    managerProgramsList?.entities,
    new Date(from)
  );
  const series = [
    {
      name: "PVR",
      data: users.map((user) => {
        const amount = getPvr(to?.getFullYear(), to?.getMonth() + 1, user?.data?.statistics);
        return round(amount);
      })
    }
  ];

  return (
    <Paper style={{ height: "270px" }} elevation={3}>
      <Typography
        gutterBottom
        align="center"
        style={{
          color: "#3e4446",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontSize: "14px",
          fontWeight: 900,
          paddingTop: 5
        }}
      >
        PVR & Commissions
      </Typography>

      <div
        style={{
          fontSize: "12px",
          marginTop: -5,
          marginBottom: "25px",
          width: "100%",
          height: 50,
          padding: "0px 15px"
        }}
      >
        <table
          style={{
            height: "50px",
            marginTop: -5,
            width: "100%"
          }}
        >
          <tr>
            <td>
              PVR{" "}
              {commissionPercentages && showNumbers
                ? `(${commissionPercentages?.productCommissionAmount}% products, ${commissionPercentages?.reserveCommissionAmount}% reserve)`
                : ""}
              :
            </td>
            <td>
              {showNumbers
                ? `${formatNumberAsCurrency(
                    getPvr(to?.getFullYear(), to?.getMonth() + 1, user?.data?.statistics) || 0,
                    "$"
                  )} `
                : formatNumberAsCurrency(randomNumber(), "$")}
            </td>
          </tr>
          <tr>
            <td>Total Commissions:</td>
            <td style={showNumbers ? {} : { filter: "blur(7px)" }}>
              {showNumbers
                ? formatNumberAsCurrency(totalCommission || 0, "$")
                : formatNumberAsCurrency(randomNumber(), "$")}
            </td>
          </tr>

          <tr>
            <td>Reserve Commission Total:</td>
            <td style={showNumbers ? {} : { filter: "blur(7px)" }}>
              {showNumbers
                ? formatNumberAsCurrency(totalReserveCommission, "$")
                : formatNumberAsCurrency(randomNumber(), "$")}
            </td>
          </tr>
          <tr>
            <td>Product Commission Total:</td>
            <td style={showNumbers ? {} : { filter: "blur(7px)" }}>
              {showNumbers
                ? formatNumberAsCurrency(totalProductCommission, "$")
                : formatNumberAsCurrency(randomNumber(), "$")}
            </td>
          </tr>
          <tr>
            <td>Chargebacks: </td>
            <td style={showNumbers ? { color: "red" } : { filter: "blur(7px)", color: "red" }}>
              {showNumbers
                ? `-${formatNumberAsCurrency(totalChargebackAmount, "$")}`
                : formatNumberAsCurrency(randomNumber(), "$")}
            </td>
          </tr>
        </table>
      </div>
      {user && users && (
        <ReactApexChart
          options={{
            chart: {
              id: "pvrAndCommission",
              toolbar: { show: false },
              zoom: { enabled: false },
              offsetY: 0
            },
            dataLabels: { enabled: false },
            plotOptions: {
              bar: {
                distributed: true // this line is mandatory
              }
            },
            yaxis: {
              labels: {
                formatter: (value) => {
                  return value?.toString();
                }
              }
            },
            colors: users?.map((u) => {
              console.log(u, user);
              return u._id === user?._id ? "#52a543" : "#33b2df";
            }),

            xaxis: {
              labels: { show: true },
              categories: users?.map((user) => {
                return `${user?.data?.info?.firstName || ""} ${
                  user?.data?.info?.lastName?.slice(0, 1) || ""
                }.`;
              })
            }
          }}
          series={series}
          type="bar"
          width={"100%"}
          height={160}
        />
      )}
    </Paper>
  );
};
