import Big from "big.js";
import { ProfitPercentages } from "./types";
import { DealershipProfit, ManagerProfit, Profit, WfdProfit } from "components/Deals/types";

type ProfitType = "reserve" | "warranty" | "gap";

export const calculateCargebackPercentages = (profit: Profit) => {
  const dealershipProfit = profit?.dealershipProfit;
  const managerProfit = profit?.managerProfit;
  const wfdProfit = profit?.wfdProfit;

  const totalReserveProfit = new Big(dealershipProfit?.reserveCommission || 0).toNumber();

  const totalServiceWarrantyProfit = new Big(profit?.totalServiceWarrantyProfit || 0).toNumber();

  const totalGAPProfit = new Big(profit?.totalGAPProfit || 0).toNumber();

  const reservePercentages = calculateProfitPercentages(
    "reserve",
    totalReserveProfit,
    dealershipProfit,
    wfdProfit,
    managerProfit
  );
  const ascPercentages = calculateProfitPercentages(
    "warranty",
    totalServiceWarrantyProfit,
    dealershipProfit,
    wfdProfit,
    managerProfit
  );
  const gapPercentages = calculateProfitPercentages(
    "gap",
    totalGAPProfit,
    dealershipProfit,
    wfdProfit,
    managerProfit
  );
  console.log({ ASC: ascPercentages, GAP: gapPercentages, Reserve: reservePercentages });

  return {
    Warranty: ascPercentages,
    GAP: gapPercentages,
    Reserve: reservePercentages
  };
};
const getWFDTotalProfit = (type: ProfitType, wfdProfit?: WfdProfit): number | undefined => {
  if (type === "reserve") {
    return new Big(wfdProfit?.splitFromDeal || 0).toNumber();
  } else if (type === "gap") {
    const totalGapProfit = wfdProfit?.splitTotalFromGap || 0;
    return totalGapProfit;
  } else if (type === "warranty") {
    const totalWarrantyProfit = wfdProfit?.splitTotalFromServiceWarranty || 0;
    return totalWarrantyProfit;
  }
};
const getSplitFromDeal = (type: ProfitType) => {
  switch (type) {
    case "warranty":
      return "splitTotalFromServiceWarranty";
    case "gap":
      return "splitTotalFromGap";

    default:
      return "splitFromDeal";
  }
};

const calculateProfitPercentages = (
  type: ProfitType,
  totalProfit: number | undefined,
  dealershipProfit?: DealershipProfit,
  wfdProfit?: WfdProfit,
  managerProfit?: ManagerProfit
): ProfitPercentages => {
  if (totalProfit) {
    const totalProfitAmount = Big(totalProfit);
    const wfdTotalProfit = getWFDTotalProfit(type, wfdProfit);

    const dealerPercentage = Big(dealershipProfit?.[getSplitFromDeal(type)] || 0)
      .div(totalProfitAmount)
      .times(100)
      .toNumber();
    const wfdTotalPercentage = Big(wfdTotalProfit || 0)
      .div(totalProfitAmount)
      .times(100)
      .toNumber();

    let managerPercentage = 0;

    if (wfdTotalProfit) {
      if (type === "reserve") {
        managerPercentage = managerProfit?.chargebackPercentReserve || 0;
      } else {
        managerPercentage = managerProfit?.chargebackPercentProduct || 0;
      }

      console.log(managerProfit?.chargebackPercentReserve, managerProfit?.chargebackPercentProduct);
    }

    return {
      dealer: dealerPercentage,
      wfd: wfdTotalPercentage,
      manager: managerPercentage
    };
  }
  return {
    dealer: 0,
    wfd: 0,
    manager: 0
  };
};
