import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";

import { AddContractRequest, ContractResponse } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddContractRequest,
  ContractResponse
>("Add Contract", {
  request: "new_contract"
});

export const addContract = ({
  data,
  requestId,
  availableToRolesIds
}: AddContractRequest & Identifiable) => {
  const {
    contractTypes,
    lenders,
    users,
    dealerships,
    otherVendors,
    products,
    externalProviders,
    vendorTypes,
    contractDates,
    ...rest
  } = data;
  return socketAction({
    requestId,
    availableToRolesIds: availableToRolesIds ?? [],
    data: {
      ...rest,
      contractTypeIds: contractTypes?.map((el) => el?._id) ?? [],
      lenderIds: lenders?.map((el) => el?._id) ?? [],
      userIds: users?.map((el) => el?._id) ?? [],
      dealershipIds: dealerships?.map((el) => el?._id) ?? [],
      otherVendorIds: otherVendors?.map((el) => el?._id) ?? [],
      vendorTypes: vendorTypes ?? [],
      products: products ?? [],
      externalProviders: externalProviders ?? []
    }
  });
};
export const addContractActions = socketMessageSlice.actions;

export default socketMessageSlice.reducer;
