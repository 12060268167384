import { createListSlice } from "../Middlewares/listSliceCreator";
import { Applicant } from "./types";

const { ListSlice, getList } = createListSlice<Applicant>({
  name: "ApplicantList",
  request_topic: "all_applicants",
  reducers: {}
});

export default ListSlice.reducer;
export const getApplicantList = getList;
export const removeApplicantList = ListSlice.actions.removeList;
export const listApplicantActions = ListSlice.actions;
