import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

interface ResendEsignEmailRequest {
  packageId: string;
  email: string;
  oneSpanSigningId: string;
  role: "applicant" | "dealership" | "coApplicant";
}

export interface ResendEsignEmailResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  ResendEsignEmailRequest,
  ResendEsignEmailResponse
>("ResendEsignEmail", {
  request: "resend_esign_email"
});

export const resendEsignEmailActions = socketMessageSlice.actions;
export const resendEsignEmail = socketAction;
export default socketMessageSlice.reducer;
