import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface LenderSendGeneratedLinkRequest {
  _id: string;
}

export interface LenderSendGeneratedLinkResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  LenderSendGeneratedLinkRequest,
  LenderSendGeneratedLinkResponse
>("SendGeneratedLinkLender", {
  request: "lender_send_generated_link"
});

export const LenderSendGeneratedLinkActions = socketMessageSlice.actions;
export const LenderSendGeneratedLink = socketAction;
export default socketMessageSlice.reducer;
