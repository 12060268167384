import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

import { GenerateContract, GeneratedContractURL } from "./types";
import { Deal } from "../types";
export interface GenerateContractResponse {
  data: { generatedPacks: GeneratedContractURL[]; deal: Deal };
}

const { socketMessageSlice, socketAction, createAction } = createSocketMessageSlice<
  GenerateContract,
  GenerateContractResponse
>("GenerateContract", {
  request: "new_generate_contract"
});
export const sendGenerateContract = socketAction;
export const sendGenerateContractAction = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
export const addGenerateContractActions = createAction;
