import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { auth } from "../../firebase/firebase";
import { LenderStatisticRequest, LenderStatisticsResponse } from "./types";

export const getLenderStatistics = (
  request: LenderStatisticRequest
): (() => Promise<LenderStatisticsResponse>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/lender_statistics`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    );

    return response.data.result;
  };
};

const useLenderStatistics = (request: LenderStatisticRequest, enabled = true) => {
  const queryKey = ["lender_statistics", request];

  const { data, isLoading, isSuccess, error } = useQuery({
    queryKey: queryKey,
    queryFn: getLenderStatistics(request),
    enabled:
      enabled && (request.lenderIds?.length || request.allLenders) && request.year ? true : false
  });

  return {
    data,
    isSuccess,
    isLoading,
    error
  };
};

export default useLenderStatistics;
