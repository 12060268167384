import Big from "big.js";
import { FilterQuery } from "components/Filters/types";

export type GetIncomeReportData = {
  query: FilterQuery;
};

export type DealReport = {
  amountFinanced: number;
  totalWebIncome: number;
  dealerIncome: number;
  grossIncome: number;
  contractDate: Date;
};

export type GetIncomeReportResponse = {
  message: DealReport[];
};
export const columns = ["amountFinanced", "totalWebIncome", "dealerIncome", "grossIncome"] as const;

export type Columns = typeof columns[number];

export const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] as const;

export type Months = typeof months[number];

export type IncomeValues = {
  [k in Columns]: Big;
};

export type Year = {
  [k in Months]: Month;
} & {
  annual: Month;
  averages: IncomeValues;
};

export type Years = {
  [k: string]: Year;
};

export type Month = IncomeValues & {
  count: Big;
  incomeChange: IncomeValues;
};
