import React from "react";
import { notifyWebhooks } from "utils/logging";
import StackTrace from "stacktrace-js";

const clearStorage = () => {
  window.localStorage.clear();
  window.location.href = "/dashboard";
};

export class ErrorBoundary extends React.Component<
  {},
  { hasError: boolean; error?: any; errorInfo?: any; componentStack?: any }
> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    StackTrace.fromError(error)
      .then((stackFrames) => {
        const componentStack = stackFrames.map((sf) => sf.toString()).join("\n");
        this.setState({ componentStack });
      })
      .catch(console.error);
    try {
      notifyWebhooks({ error: error.toString(), errorInfo });
    } catch (e) {
      // @ts-ignore
      notifyWebhooks(e.toString());
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>Something went wrong.</h1>
          <button onClick={clearStorage}>Clear saved tabs (in the app)</button>
          <p>{this.state?.error && this.state?.error?.toString()}</p>
          <pre>{this.state?.componentStack}</pre>
        </>
      );
    }
    return this.props.children;
  }
}
