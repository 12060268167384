import store from "app/store";
import React from "react";
import Iframe from "react-iframe";
import { Model } from "utils/models/fields";
import { StateAccess } from "utils/models/formGenerator";
import { extractUrlFromResponse } from "./CreditBureauView";
import { CreditBureau } from "./types";

export const creditScoreStruct: Model<CreditBureau> = {
  formComponent: "model",
  schema: "new_credit_score",
  name: "credit_score",
  entities: [
    {
      formComponent: "segment",
      name: "Credit Bureau",
      entities: [
        {
          formComponent: "segment",
          name: "Applicant",
          width: (stateAccess: StateAccess) => {
            return typeof stateAccess.get(["data", "request", "SPOUSE"]) === "string"
              ? "1/2"
              : "full";
          },
          entities: [
            {
              formComponent: "text-field",
              name: "name",
              label: "Name",
              width: "1/2",
              path: ["data", "request", "NAME"],
              required: true,
              default: null
            },
            {
              formComponent: "ssn-field",
              name: "ssn",
              label: "SSN",
              width: "1/2",
              path: ["data", "request", "SSN"],
              required: true,
              default: null
            },
            {
              formComponent: "text-field",
              name: "address",
              label: "Address",
              width: "full",
              path: ["data", "request", "ADDRESS"],
              required: true,
              default: null
            },
            {
              formComponent: "text-field",
              name: "city",
              label: "City",
              width: "1/3",
              path: ["data", "request", "CITY"],
              required: true,
              default: null
            },
            {
              formComponent: "text-field",
              name: "state",
              label: "State",
              width: "1/3",
              path: ["data", "request", "STATE"],
              required: true,
              default: null
            },
            {
              formComponent: "text-field",
              name: "zip",
              label: "Zip",
              width: "1/3",
              path: ["data", "request", "ZIP"],
              required: true,
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          name: "Co Applicant",
          show: (stateAccess: StateAccess) => {
            return typeof stateAccess.get(["data", "request", "SPOUSE"]) === "string";
          },
          entities: [
            {
              formComponent: "text-field",
              name: "Name",
              label: "Name",
              width: "1/2",
              path: ["data", "request", "SPOUSE"],
              required: true,
              default: null
            },
            {
              formComponent: "ssn-field",
              name: "SSN",
              label: "SSN",
              width: "1/2",
              path: ["data", "request", "SPOUSESSN"],
              required: true,
              default: null
            },
            {
              formComponent: "text-field",
              name: "Address",
              label: "Address",
              width: "full",
              path: ["data", "request", "SPOUSEADDRESS"],
              required: true,
              default: null
            },
            {
              formComponent: "text-field",
              name: "City",
              label: "City",
              width: "1/3",
              path: ["data", "request", "SPOUSECITY"],
              required: true,
              default: null
            },
            {
              formComponent: "text-field",
              name: "State",
              label: "State",
              width: "1/3",
              path: ["data", "request", "SPOUSESTATE"],
              required: true,
              default: null
            },
            {
              formComponent: "text-field",
              name: "Zip",
              label: "Zip",
              width: "1/3",
              path: ["data", "request", "SPOUSEZIP"],
              required: true,
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          name: "Dealership",
          width: "1/2",
          entities: [
            {
              formComponent: "text-field",
              name: "Name",
              label: "Name",
              width: "full",
              path: ["data", "dealership", "data", "info", "name"],
              required: true,
              default: null
            }
          ]
        },

        {
          formComponent: "segment",
          name: "Credit Check",
          width: "full",
          entities: [
            {
              formComponent: "one-to-many-field",
              path: null,
              name: "creditCheck",
              component: (stateAccess: StateAccess) => (
                <Iframe
                  url={extractUrlFromResponse(stateAccess.get<CreditBureau>([] as any)) ?? ""}
                  width="800px"
                  height="700px"
                  display="block"
                  position="relative"
                />
              )
            }
          ]
        },
        {
          formComponent: "segment",
          name: "Raw response",
          width: "full",
          show: (stateAccess: StateAccess) => {
            const state = store.getState();
            return state.authSlice.user?.email === "wfd@wfd.com";
          },
          entities: [
            {
              formComponent: "raw-field",
              name: "Name",
              label: "Name",
              width: "1/2",
              path: ["data", "700CreditResponse"],
              required: true,
              default: null
            }
          ]
        }
      ]
    }
  ]
};
