import React from "react";
import States from "us-states";
import { useSelector } from "react-redux";

import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";

import { FormState } from "./types";
import Table, { CellValue, Column } from "../Table";
import { deletePrescreen } from "./deletePrescreenSlice";
import { getPrescreenList } from "./listPrescreenSlice";
import { recoverPrescreen } from "./recoverPrescreenSlice";

export const projections = {
  "data.info.applicant.firstName": 1,
  "data.info.applicant.lastName": 1,
  "data.creditChecks": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};

export default (): JSX.Element => {
  const PrescreenList = useSelector((state: RootState) => state.listPrescreenSlice["table"]);

  const columns: Column<FormState>[] = [
    {
      label: "Name",
      getData: (el): CellValue =>
        `${el.data.info?.applicant?.firstName ?? ""} ${el.data.info?.applicant?.lastName ?? ""}`,
      name: "Name",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.Name?.show;
      },
      filters: [
        {
          path: ["data", "info", "firstName"],
          preview: MultiSelectFilter,
          valuesForSelect: Object.keys(States).sort(),
          label: "Name",
          name: "Name"
        }
      ]
    },

    {
      label: "Actions",
      getData: (el): CellValue => el._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <>
      <AccessControl requiredPermissions={{ entity: "prescreen", action: "read" }}>
        <Table
          tableName="prescreens"
          entityName="prescreen"
          listFunction={getPrescreenList}
          listEntity={PrescreenList}
          hideAddButton={true}
          deleteEntityFunction={deletePrescreen}
          recoverEntityFunction={recoverPrescreen}
          title={"Prescreens"}
          columns={columns}
          projection={projections}
        />
      </AccessControl>
    </>
  );
};
