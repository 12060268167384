import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { PrintedDocument, RejectionInfo } from "./types";

interface RejectPrintedDocumentRequest {
  _id: string;
  data: {
    info: {
      rejectionInfo: RejectionInfo;
    };
  };
}

interface RejectPrintedDocumentResponse {
  message: PrintedDocument;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RejectPrintedDocumentRequest,
  RejectPrintedDocumentResponse
>("RejectPrintedDocumentResponse", {
  request: "reject_printed_document"
});

export const rejectPrintedDocumentActions = socketMessageSlice.actions;
export const rejectPrintedDocument = socketAction;
export default socketMessageSlice.reducer;
