import React from "react";

import { StateAccess, RenderSet, generateForm } from "utils/models/formGenerator";

import { typesStruct } from "./model";
import { TextField } from "@material-ui/core";

interface Props {
  stateAccess: StateAccess;
  mainStateAccess: StateAccess;
  path: string[];
  renderSet: RenderSet;
}

export default function DealTypes({ stateAccess, mainStateAccess, path, renderSet }: Props) {
  return (
    <div style={{ position: "relative" }}>
      <TextField
        value={stateAccess.get(path as [])}
        required={true}
        id="text-field-cut2stom"
        style={{ opacity: 0, position: "absolute", top: "16px", left: "15px" }}
      />
      {generateForm(typesStruct, stateAccess, [], mainStateAccess, renderSet)}
    </div>
  );
}
