import {
  permissionNode,
  PermissionStruct,
  multyPermissionNode,
  relationPermissionNode
} from "components/Roles/types";
import { Settings } from "./types";

export type SettingsPermissionsStruct = PermissionStruct<Settings>;

export const settingsPermissionsStruct: SettingsPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      insuranceCosts: {
        powersport: {
          name: permissionNode("name"),
          path: permissionNode("path"),
          requirements: multyPermissionNode("req")
        },
        automotive: {
          name: permissionNode("name"),
          path: permissionNode("path"),
          requirements: multyPermissionNode("req")
        }
      },
      offDaysByYear: multyPermissionNode("Off days by year"),
      ascWarranty: permissionNode("ASc Warranty"),
      gwcWarranty: permissionNode("GWC Warranty"),
      goldStandartAutomotiveNetworkWarranty: permissionNode(
        "Gold Standart Automotive Network Warranty"
      ),
      gwcGap: permissionNode("GWC GAP"),
      protectiveGap: permissionNode("Protective GAP"),
      xtraRideGap: permissionNode("XtraRide Gap"),
      xtraRideWarranty: permissionNode("XtraRide Warranty"),
      fpcWarranty: permissionNode("FPC Warranty"),
      nsdGap: permissionNode("NSD GAP"),
      compassGap: permissionNode("Compass GAP"),
      compasMotorhome: permissionNode("Compass Motorhome"),
      compassTT5MoreThan20k: permissionNode("Compass TT5 More than 20k"),
      compassTT5LessThan20k: permissionNode("Compass TT5 Less than 20k"),
      compasMotorhomeCoach: permissionNode("Compass Motorhome Coach"),
      campersPopSlideLessThan20k: permissionNode("Campers Pop Slice Less than 20k"),
      campersPopSlideMoreThan20k: permissionNode("Campers Pop Slice More than 20k"),
      maxWFDProfit: permissionNode("Max WFD Profit"),
      businessReportTypes: multyPermissionNode("Business report types"),
      defaultStipulations: multyPermissionNode("Default Stipulations"),
      wfdMinimumFee: permissionNode("WFD Mininum Fee"),
      acquisitionFee: permissionNode("Acquisition Fee"),
      defaultNumberRanges: multyPermissionNode("defaultNumberRanges")
    },
    entityAprovalRoles: {
      dealershipApprovalRoleIds: multyPermissionNode("Dealership Roles Ids"),
      dealershipApprovalRoles: multyPermissionNode("Dealership Roles"),
      contractApprovalRoles: multyPermissionNode("Contract Roles"),
      contractApprovalRoleIds: multyPermissionNode("Contract Roles Ids"),
      contractTypeApprovalRoles: multyPermissionNode("Contract Type Roles"),
      contractTypeApprovalRoleIds: multyPermissionNode("Contract Type Roles Ids"),
      lenderApprovalRoles: multyPermissionNode("Lender Roles"),
      lenderApprovalRoleIds: multyPermissionNode("Lender Roles Ids"),
      dealApprovalRoles: multyPermissionNode("Deal Roles"),
      dealApprovalRoleIds: multyPermissionNode("Deal Roles Ids"),
      applicantApprovalRoles: multyPermissionNode("Applicant Roles"),
      applicantApprovalRoleIds: multyPermissionNode("Applicant Roles Ids"),
      userApprovalRoles: multyPermissionNode("User Roles"),
      userApprovalRoleIds: multyPermissionNode("User Roles Ids"),
      dmvApprovalRoles: multyPermissionNode("Dmv Roles"),
      dmvApprovalRoleIds: multyPermissionNode("Dmv Roles Ids"),
      customReportApprovalRoles: multyPermissionNode("Custom report Roles"),
      customReportApprovalRoleIds: multyPermissionNode("Custom report Roles Ids"),
      documentTemplateApprovalRoles: multyPermissionNode("Document Template Roles"),
      documentTemplateApprovalRoleIds: multyPermissionNode("Document Template Roles Ids"),
      documentValidationApprovalRoles: multyPermissionNode("Document Valdation Roles"),
      documentValidationApprovalRoleIds: multyPermissionNode("Document Valdation Roles Ids"),
      fileApprovalRoles: multyPermissionNode("File Roles"),
      fileApprovalRoleIds: multyPermissionNode("File Roles Ids"),
      payoffBankApprovalRoles: multyPermissionNode("Payoff bank Roles"),
      payoffBankApprovalRoleIds: multyPermissionNode("Payoff bank Roles Ids"),
      otherVendorApprovalRoles: multyPermissionNode("Other vendor Roles"),
      otherVendorApprovalRoleIds: multyPermissionNode("Other vendor Roles Ids"),
      stateApprovalRoles: multyPermissionNode("State Roles"),
      stateApprovalRoleIds: multyPermissionNode("State Roles Ids"),
      fundingDocumentApprovalRoles: multyPermissionNode("Funding Document Roles"),
      fundingDocumentApprovalRoleIds: multyPermissionNode("Funding Document Roles Ids"),
      lenderDecisionApprovalRoles: multyPermissionNode("Lender Roles"),
      lenderDecisionApprovalRoleIds: multyPermissionNode("Lender Roles Ids"),
      titleIssueApprovalRoles: multyPermissionNode("Title Issue Roles"),
      titleIssueApprovalRoleIds: multyPermissionNode("Title Issue Ids")
    }
  }
};
