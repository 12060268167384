import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

const data = {
  type: "object",
  // required: ["tableName, columns, userId"],
  properties: {
    tableName: {
      type: "string",
    },
    userId: {
      type: "string",
    },
    columns: {
      type: "object",
      additionalProperties: true,
    },
  },
};
export default {
  new_table_settings: {
    $id: "newTableSettings",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
    additionalProperties: true,
  },
  update_table_settings: {
    $id: "updateTableSettings",
    type: "object",
    properties: {
      _id: { type: "string" },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      approved: {
        type: "boolean",
        nullable: true,
      },
      approvedAt: {
        type: "string",
        nullable: true,
      },
      approvedByUserId: {
        type: "string",
        nullable: true,
      },
    },
  },
  all_table_settings: {
    $id: "allTableSettings",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
