import { createSlice } from "@reduxjs/toolkit";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/rootReducer";

export interface InteractionBlockerState {
  connected: boolean;
}

const initialState: InteractionBlockerState = {
  connected: false
};

const socketStatusSlice = createSlice({
  name: "socketStatusSlice",
  initialState,
  reducers: {
    setConnected(state): void {
      state.connected = true;
    },
    setDisconnected(state: InteractionBlockerState): void {
      state.connected = false;
    }
  }
});

export const { setConnected, setDisconnected } = socketStatusSlice.actions;

export default socketStatusSlice.reducer;

export const SocketConnectionStatus = (_props: unknown): JSX.Element => {
  const { connected } = useSelector((state: RootState) => state.socketStatusSlice);

  return (
    <div
      style={{
        top: "0px",
        right: "5px",
        position: "fixed",
        zIndex: 1202,
        color: "white",
        fontSize: "10px"
      }}
    >
      <span
        style={{
          color: connected ? "green" : "red",
          fontSize: "42px",
          marginRight: "10px",
          position: "absolute",
          left: "-15px",
          lineHeight: "14px"
        }}
      >
        •
      </span>
      {connected ? "Connected" : "Disconnected"}
    </div>
  );
};
