import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { Role } from "./types";

interface EditRoleResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<Role, EditRoleResponse>(
  "EditRole",
  {
    request: "update_role"
  }
);

export const editRole = socketAction;
export const editRoleActions = socketMessageSlice.actions;
export const editRoleError = socketMessageSlice.actions.error;

export default socketMessageSlice.reducer;
