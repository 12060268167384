import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { Role } from "./types";

interface AddRoleResponse {
  message: Role;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<Role, AddRoleResponse>(
  "Add Role",
  {
    request: "new_role"
  }
);

export const addRoleActions = socketMessageSlice.actions;
export const addRole = socketAction;
export default socketMessageSlice.reducer;
