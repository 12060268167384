import { AdditionalRequiredDocument } from "components/AdditionalRequiredDocuments/types";
import { CreditBureau } from "components/CreditScore/types";
import { Coordinates, DocumentTemplate } from "components/DocumentTemplates/types";
import { AdditionalAddress } from "components/Lenders/types";
import { AllInsurancesTypes, Deal } from "../types";
import { CreditPrequalify } from "types/credit_prequalify";

export interface OtherRequiredDocument {
  name: string;
}

export const checkboxTypes = ["required", "dealerships", "applicant", "eSign"] as const;
export type checkboxType = typeof checkboxTypes[number];

export enum DocumentsType {
  "generate" = "generate",
  "applicant" = "applicant",
  "outside" = "outside",
  "other" = "other"
}

interface AdditionalProperties {
  required: boolean;
  dealerships: boolean;
  applicant: boolean;
  eSign: boolean;
  optionalSignature?: boolean;
}
export type AllInsurancesDocumentsTypes = {
  provider: string;
  type: "Custom" | "ASC" | "F&I Express";
};
export interface SortedDocumentTemplates {
  otherDocuments: DocumentTemplate[];
  suggestedDocuments: DocumentTemplate[];
}
export interface ModalDocumentTemplateType {
  type: DocumentsTypesLiterals.document_template;
  _id: string;
  name: string;
  displayName: string;
  address?: AdditionalAddress;
  packType: PackType;
  documentData?: DocumentTemplate;
  forEsign?: boolean;
  additionalProperties: AdditionalProperties;
}
export interface ModalAdditionalRequiredDocumentType {
  type: DocumentsTypesLiterals.additional_required_document;
  _id: string;
  name: string;
  address?: AdditionalAddress;
  displayName: string;
  packType: PackType;
  documentData: AdditionalRequiredDocument;
  additionalProperties: AdditionalProperties;
}
export interface ModalCreditScoreType {
  type: DocumentsTypesLiterals.credit_score;
  _id: string;
  fileUrl: string;
  name: string;
  address?: AdditionalAddress;
  packType: PackType;
  displayName: string;
  documentData: CreditBureau | CreditPrequalify;
  additionalProperties: AdditionalProperties;
}
export interface ModalAllInsurancesDocumentType {
  type: DocumentsTypesLiterals.insurance_document;
  _id: string;
  fileUrl: string;
  name: string;
  packType: PackType;
  address?: AdditionalAddress;
  provider: string;
  displayName: string;
  documentData: AllInsurancesTypes;
  additionalProperties: AdditionalProperties;
}
export interface ModalOtherRequiredDocumentType {
  type: DocumentsTypesLiterals.other_required_document;
  _id: string;
  name: string;
  packType: PackType;
  fileUrl: string;
  address?: AdditionalAddress;
  fileName: string;
  displayName: string;
  documentData: OtherRequiredDocument;
  additionalProperties: AdditionalProperties;
}
export type ModalDocumentType =
  | ModalDocumentTemplateType
  | ModalAdditionalRequiredDocumentType
  | ModalAllInsurancesDocumentType
  | ModalCreditScoreType
  | ModalOtherRequiredDocumentType;
export type ModalDocumentTemplate = ModalDocumentType[];
export enum DocumentsTypesLiterals {
  "additional_required_document" = "additional_required_document",
  "document_template" = "document_template",
  "other_required_document" = "other_required_document",
  "insurance_document" = "insurance_document",
  "credit_score" = "credit_score"
}
export type DocumentTypes =
  | AdditionalRequiredDocument
  | DocumentTemplate
  | OtherRequiredDocument
  | AllInsurancesDocumentsTypes;

export type ModalDocuments = (
  | ModalDocumentTemplateType
  | ModalAdditionalRequiredDocumentType
  | ModalAllInsurancesDocumentType
  | ModalOtherRequiredDocumentType
  | ModalCreditScoreType
)[];

export interface GenerateContractFile {
  url: string;
  packType: PackType;
  fileId: string;
  name: string;
  documentTemplateId?: string | null;
  coordinates: GenerateContractCoordinates[];
  oneSpanRequestPackUrl?: string;
  required: {
    default: boolean;
    applicant: boolean;
    dealership: boolean;
    optionalSignature?: boolean;
  };
}
export type GenerateContractCoordinates = Omit<Coordinates, "id" | "tooltipLabel"> & {
  value: string;
};

export interface GenerateContract {
  _id?: string;
  data: {
    deal: Deal;
    info: {
      includeBarcodes: boolean;
      generateContractFiles: GenerateContractFile[];
      generatedBy: string;
    };
  };
}
export type GenerateContractForPrint = Omit<GenerateContract, "data"> & {
  data: {
    dealId?: string;
    info: {
      type: "external_credit_application" | "recap_sheet";
      generateContractFiles: GenerateContractFile[];
    };
  };
};
export interface SendGeneratedContract {
  deal: Deal;
}

export interface GeneratedContractURL {
  url: string;
  name: string;
  packType: PackType;
  warning?: string;
}
export type GeneratedContractForPrintURL = Omit<GeneratedContractURL, "packType">;
export enum PackType {
  "eSign" = "eSign",
  "email" = "email"
}

export const isModalDocumentTemplateType = (
  doc:
    | ModalDocumentTemplateType
    | ModalAdditionalRequiredDocumentType
    | ModalAllInsurancesDocumentType
    | ModalOtherRequiredDocumentType
    | ModalCreditScoreType
): doc is ModalDocumentTemplateType => doc.type === DocumentsTypesLiterals.document_template;
export const isInsuranceDocument = (
  doc:
    | ModalDocumentTemplateType
    | ModalAdditionalRequiredDocumentType
    | ModalAllInsurancesDocumentType
    | ModalOtherRequiredDocumentType
    | ModalCreditScoreType
): doc is ModalAllInsurancesDocumentType => doc.type === DocumentsTypesLiterals.insurance_document;
