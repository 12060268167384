import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { GetRVRatesRequest } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<GetRVRatesRequest, any>(
  "getRVRates",
  {
    request: "protective_get_rv_rates"
  }
);

export const getRVRates = socketAction;
export const GetRVRatesActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
