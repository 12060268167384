import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { auth } from "../../firebase/firebase";
import { DealershipPerformanceReportRequest, DealershipPerformanceReportResponse } from "./types";

export const getDealershipPerformanceReport = (
  request: DealershipPerformanceReportRequest
): (() => Promise<DealershipPerformanceReportResponse>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/dealership_performance_report`,
      {
        ...request,
        dealershipStates:
          request.dealershipStates?.length === 1 && request?.dealershipStates?.[0] === "All states"
            ? undefined
            : request?.dealershipStates?.filter((x) => x !== "All states"),
        customerCareAssigneeIds:
          request.customerCareAssigneeIds?.length === 1 &&
          request?.customerCareAssigneeIds?.[0] === "All customer care assignees"
            ? undefined
            : request?.customerCareAssigneeIds?.filter((x) => x !== "All customer care assignees")
      },
      {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    );

    return response.data.result;
  };
};

const useDealershipPerformanceReport = (
  request: DealershipPerformanceReportRequest,
  dealershipName: string,
  dealershipId?: string,
  enabled = true
) => {
  const queryKey = [
    "dealership_performance_report",
    (request.dealershipStates ?? [])?.join(","),
    (request.customerCareAssigneeIds ?? [])?.join(","),
    request.fromMonth,
    request.toMonth,
    request.fromYear,
    request.toYear,
    request.groupBy,
    dealershipName
  ];

  const { data, isLoading, isSuccess, isFetching, error } = useQuery({
    queryKey: queryKey,
    queryFn: getDealershipPerformanceReport({ ...request, dealershipId, dealershipName }),
    enabled:
      enabled && request.fromMonth && request.toMonth && request.fromYear && request.toYear
        ? true
        : false
  });

  return {
    data,
    isFetching,
    isSuccess,
    isLoading,
    error
  };
};

export default useDealershipPerformanceReport;
