import {
  Button,
  FormControlLabel,
  Grid,
  Input,
  Menu,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableCell as MuiTableCell,
  TableContainer,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import { Deal } from "components/Deals/types";
import { modelToDraggable } from "components/DocumentTemplates/DocumentTemplates";
import { Draggable, DraggableInputWithId } from "components/DocumentTemplates/types";
import { Lender } from "components/Lenders/types";
import React, { useEffect, useState } from "react";
import { FormComponent, Model, Path } from "utils/models/fields";
import { generateForm, StateAccess } from "utils/models/formGenerator";
import { editRenderSet, showRenderSet } from "utils/models/formRenderers";
import { initialContextState, PlaceholderMenuItem } from "./ContextMenu";
import { convertToTableRows, getVerticalTree } from "./creatingRows";
import {
  addLeavesHorizontal,
  addLeavesVertical,
  removeLeavesHorizontal,
  removeLeavesVertical
} from "./treeManipulation";
import {
  HeaderCell,
  HorizontalSetting,
  isBodyCell,
  isHeaderCell,
  isMainCell,
  MainCell,
  Requirement,
  requirementTypes
} from "./types";

const TableCell = withStyles({
  root: {
    border: "1px solid #cecece",
    padding: "3px"
  }
})(MuiTableCell);

export const WholeTable = (props: {
  tableData: HorizontalSetting;
  setTableData: (x: HorizontalSetting) => void;
  edit: boolean;
  editValues: boolean;
  stateAccess: StateAccess;
  mainStateAccess: StateAccess;
  pivotTableContent: FormComponent<Deal>;
  pivotTableMenuFields: Model<Deal>;
}) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        {convertToTableRows(props.tableData, props.setTableData).map((row, index) => {
          return (
            <TableRow key={`pivot-row-${index}`}>
              {row.map((cell) => {
                return isHeaderCell(cell) ? (
                  <TableCell
                    rowSpan={cell.height}
                    ref={React.createRef()}
                    colSpan={cell.width}
                    style={{ textAlign: "center", position: "relative", whiteSpace: "nowrap" }}
                  >
                    {props.edit ? <EditableHeaderCell cell={cell} /> : formatHeaderCell(cell)}
                  </TableCell>
                ) : isMainCell(cell) ? (
                  <TableCell
                    style={{ position: "relative" }}
                    rowSpan={cell.height}
                    colSpan={cell.width}
                  >
                    {props.edit ? (
                      <MainHeaderCell
                        cell={cell}
                        pivotTableMenuFields={props.pivotTableMenuFields}
                      />
                    ) : null}
                  </TableCell>
                ) : isBodyCell(cell) ? (
                  <TableCell rowSpan={cell.height} colSpan={cell.width}>
                    {generateForm(
                      props.pivotTableContent,
                      cell.stateAccess,
                      [],
                      props.mainStateAccess,
                      props.editValues ? editRenderSet(false) : showRenderSet(false)
                    )}
                  </TableCell>
                ) : (
                  <></>
                );
              })}
            </TableRow>
          );
        })}
      </Table>
    </TableContainer>
  );
};
const formatHeaderCell = ({ condition: { type, criteria }, name }: HeaderCell) => {
  return `${name} ${type} ${criteria}`;
};

const MainHeaderCell = (props: { cell: MainCell; pivotTableMenuFields: Model<Deal> }) => {
  const [contextState, setContextState] = React.useState<{
    mouseX: number;
    mouseY: number;
    open: boolean;
    action: (item: any) => void;
  }>(initialContextState);

  const handleContextClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    action: (item: any) => void
  ) => {
    event.preventDefault();

    setContextState({
      mouseX: event.clientX,
      mouseY: event.clientY,
      open: true,
      action
    });
  };

  const handleContextClose = () => {
    setContextState(initialContextState);
  };

  return (
    <div style={{ minHeight: "40px", minWidth: "80px" }}>
      <Button
        style={{ position: "absolute", bottom: "0px", left: "70px" }}
        onClick={(event) => {
          handleContextClick(event, (item) => {
            const horizontal = props.cell.stateAccess.get([]) as HorizontalSetting;
            props.cell.stateAccess.set(
              [],
              addLeavesHorizontal(
                horizontal,
                [item.tooltipLabel, item.label].join(" "),
                item.path,
                item.possibleValues
              )
            );
          });
        }}
      >
        Add cond.
      </Button>
      <Button
        style={{ position: "absolute", bottom: "0px", left: "5px" }}
        onClick={() => {
          const horizontal = props.cell.stateAccess.get([]) as HorizontalSetting;
          props.cell.stateAccess.set([], removeLeavesHorizontal(horizontal));
        }}
      >
        <DeleteIcon />
      </Button>

      <Button
        style={{ position: "absolute", top: "0px", right: "70px" }}
        onClick={() => {
          const horizontal = props.cell.stateAccess.get([]) as HorizontalSetting;
          props.cell.stateAccess.set([], removeLeavesVertical(horizontal));
        }}
      >
        <DeleteIcon />
      </Button>
      <Button
        style={{ position: "absolute", top: "0px", right: "5px" }}
        onClick={(event) => {
          handleContextClick(event, (item) => {
            const horizontal = props.cell.stateAccess.get([]) as HorizontalSetting;
            props.cell.stateAccess.set(
              [],
              addLeavesVertical(
                horizontal,
                [item.tooltipLabel, item.label].join(" "),
                item.path,
                item.possibleValues
              )
            );
          });
        }}
      >
        Add cond.
      </Button>
      <Menu
        open={contextState.open}
        anchorReference="anchorPosition"
        onClose={handleContextClose}
        anchorPosition={
          contextState.mouseY !== null && contextState.mouseX !== null
            ? { top: contextState.mouseY, left: contextState.mouseX + 15 }
            : undefined
        }
      >
        {PlaceholderMenuItem(
          modelToDraggable(props.pivotTableMenuFields) as Draggable,
          contextState.action,
          handleContextClose,
          contextState.open,
          []
        )}
      </Menu>
    </div>
  );
};
const renderConditions = (props: { cell: HeaderCell }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  return (
    <>
      <select
        style={{ marginRight: "10px" }}
        value={props.cell.stateAccess.get<Requirement<"horizontal" | "vertical">>(["type"])}
        onChange={(event) => {
          props.cell.stateAccess.set<Requirement<"horizontal" | "vertical">>(
            ["type"],
            props.cell.direction === "horizontal" ? () => event.target.value : event.target.value
          );
        }}
      >
        {requirementTypes.map((type) => (
          <option key={`option-${type}`} value={type}>
            {type}
          </option>
        ))}
      </select>
      {props.cell.stateAccess.get<Requirement<"horizontal" | "vertical">>(["possibleValues"]) ? (
        <Select
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          multiple
          value={
            props.cell.stateAccess.get<Requirement<"horizontal" | "vertical">>(["criteria"]) || []
          }
          onChange={(event) => {
            props.cell.stateAccess.set<Requirement<"horizontal" | "vertical">>(
              ["criteria"],
              props.cell.direction === "horizontal" ? () => event.target.value : event.target.value
            );
          }}
          input={<Input />}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
              }
            }
          }}
        >
          {props.cell.stateAccess
            .get<Requirement<"horizontal" | "vertical">>(["possibleValues"])
            .map((possibleValue: any) => (
              <MenuItem key={`option-${possibleValue}`} value={possibleValue}>
                {possibleValue}
              </MenuItem>
            ))}
        </Select>
      ) : (
        <input
          style={{ width: "55px", textAlign: "center" }}
          value={props.cell.stateAccess.get<Requirement<"horizontal" | "vertical">>(["criteria"])}
          onChange={(event) => {
            props.cell.stateAccess.set<Requirement<"horizontal" | "vertical">>(
              ["criteria"],
              props.cell.direction === "horizontal" ? () => event.target.value : event.target.value
            );
          }}
        />
      )}
    </>
  );
};
const EditableHeaderCell = (props: { cell: HeaderCell }) => {
  return (
    <div style={{ whiteSpace: "nowrap" }}>
      {(props.cell.groupStateAccess.get([]) as Array<any>).length > 1 ? (
        <Button
          style={{ minWidth: "0px" }}
          size="small"
          onClick={() => {
            const currentList = props.cell.groupStateAccess.get([]) as Array<any>;

            if (currentList.length <= 2) {
              const requiredIndex = props.cell.indexInGroup === 1 ? 0 : 1;
              props.cell.groupStateAccess.set(
                [],

                props.cell.direction === "horizontal"
                  ? (x: any) => {
                      return [{ ...x[requiredIndex], type: "==", criteria: "" }];
                    }
                  : [
                      {
                        ...props.cell.groupStateAccess.get([requiredIndex] as any),
                        type: "==",
                        criteria: ""
                      }
                    ]
              );
            } else {
              props.cell.groupStateAccess.set(
                [],
                props.cell.direction === "horizontal"
                  ? (x: any) => {
                      return [
                        ...x.slice(0, props.cell.indexInGroup),
                        ...x.slice(props.cell.indexInGroup + 1)
                      ];
                    }
                  : [
                      ...currentList.slice(0, props.cell.indexInGroup),
                      ...currentList.slice(props.cell.indexInGroup + 1)
                    ]
              );
            }
          }}
        >
          <DeleteIcon />
        </Button>
      ) : null}
      <span style={{ marginRight: "10px" }}>{props.cell.name}</span>

      {props.cell.name.trim() !== "No condition" && renderConditions(props)}
      <Button
        style={{ minWidth: "0px" }}
        size="small"
        onClick={() => {
          if (props.cell.direction === "horizontal") {
            props.cell.groupStateAccess.set([], (x: any) => {
              return [
                ...x.slice(0, props.cell.indexInGroup),
                x[props.cell.indexInGroup],
                ...x.slice(props.cell.indexInGroup)
              ];
            });
          } else {
            const currentList = props.cell.groupStateAccess.get([]) as Array<any>;

            props.cell.groupStateAccess.set(
              [],
              [
                ...currentList.slice(0, props.cell.indexInGroup),
                props.cell.stateAccess.get([]),
                ...currentList.slice(props.cell.indexInGroup)
              ]
            );
          }
        }}
      >
        {props.cell.direction === "horizontal" ? "Add col" : "Add row"}
      </Button>
    </div>
  );
};

const renderTable = (
  edit: boolean,
  editValues: boolean,
  tableData: HorizontalSetting,
  setTableData: (x: HorizontalSetting) => void,
  pivotTableContent: FormComponent<Deal>,
  pivotTableMenuFields: Model<Deal>,
  stateAccess: StateAccess,
  mainStateAccess: StateAccess
) => {
  return (
    <WholeTable
      stateAccess={stateAccess}
      mainStateAccess={mainStateAccess}
      pivotTableContent={pivotTableContent}
      pivotTableMenuFields={pivotTableMenuFields}
      tableData={tableData}
      setTableData={setTableData}
      edit={edit}
      editValues={editValues}
    />
  );
};

const InitialSetup = ({
  setTableData,
  tableData,
  pivotTableMenuFields
}: {
  setTableData: (x: HorizontalSetting) => void;
  tableData: any;
  pivotTableMenuFields: Model<Deal>;
}) => {
  const [vertical, setVertical] = useState<DraggableInputWithId | null>(null);
  const [horizontal, setHorizontal] = useState<DraggableInputWithId | null>(null);
  useEffect(() => {
    if (horizontal !== null && vertical !== null) {
      setTableData({
        name: [...horizontal.tooltipLabel, horizontal.label].join(" "),
        path: horizontal.path as any,
        requirements: [
          {
            type: "==",
            criteria: "",
            possibleValues: horizontal.possibleValues,
            vertical: {
              name: [...vertical.tooltipLabel, vertical.label].join(" "),
              path: vertical.path as any,

              requirements: [
                {
                  type: "==",
                  criteria: "",
                  possibleValues: vertical.possibleValues,
                  content: {}
                }
              ]
            }
          }
        ]
      });
    }
  }, [vertical, horizontal, setTableData]);

  const [contextState, setContextState] = React.useState<{
    mouseX: number;
    mouseY: number;
    open: boolean;
    action: (item: any) => void;
  }>(initialContextState);

  const handleContextClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    action: (item: any) => void
  ) => {
    event.preventDefault();

    setContextState({
      mouseX: event.clientX,
      mouseY: event.clientY,
      open: true,
      action
    });
  };

  const handleContextClose = () => {
    setContextState((state) => ({ ...state, open: false }));
  };

  return (
    <div style={{}}>
      {horizontal === null ? (
        <Button
          onClick={(event) => {
            handleContextClick(event, (item) => {
              setHorizontal(item);
            });
          }}
        >
          Horizontal
        </Button>
      ) : null}{" "}
      {vertical === null ? (
        <Button
          onClick={(event) => {
            handleContextClick(event, (item) => {
              setVertical(item);
            });
          }}
        >
          Vertical
        </Button>
      ) : null}
      <Menu
        open={contextState.open}
        onClose={handleContextClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextState.mouseY !== null && contextState.mouseX !== null
            ? { top: contextState.mouseY, left: contextState.mouseX + 15 }
            : undefined
        }
      >
        {PlaceholderMenuItem(
          modelToDraggable(pivotTableMenuFields) as Draggable,
          contextState.action,
          // (item) => {
          //   return stateAccess.set(
          //     coordinatesPath,
          //     (stateAccess.get(coordinatesPath) ?? []).concat([item])
          //   );
          // },
          handleContextClose,
          contextState.open,
          []
        )}
      </Menu>
    </div>
  );
};

export default ({
  stateAccess,
  mainStateAccess,
  path,
  tableContent,
  menuFields
}: {
  stateAccess: StateAccess;
  mainStateAccess: StateAccess;
  path: Path<Lender>;
  menuFields: Model<Deal>;
  tableContent: FormComponent<Deal>;
}) => {
  // const [tableData, setTableData] = useState<HorizontalSetting | null>(
  //   stateAccess.get<Lender>(path) ?? null
  // );

  const tableData = stateAccess.get(path);

  const setTableData = (data: any) => stateAccess.set(path, data);
  const [edit, setEdit] = useState(true);
  const [editValues, setEditValues] = useState(false);
  // useEffect(() => {
  //   const stateAccessState = stateAccess.get<Lender>(path) ;
  //   if (stateAccessState!== tableData) stateAccess.set<Lender>(path, tableData);
  // }, [tableData, stateAccess]);

  return (
    <>
      {!tableData ||
      tableData?.requirements?.length === 0 ||
      getVerticalTree(tableData) === null ? (
        <InitialSetup
          setTableData={setTableData}
          tableData={tableData}
          pivotTableMenuFields={menuFields}
        />
      ) : (
        <>
          <Grid container direction="row" justify="flex-end" alignItems="center">
            <FormControlLabel
              control={
                <Switch
                  checked={edit}
                  onChange={(event, checked) => setEdit(checked)}
                  color="primary"
                />
              }
              label="Edit Table"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={editValues}
                  onChange={(event, checked) => setEditValues(checked)}
                  color="primary"
                />
              }
              label="Edit Values"
            />
          </Grid>
          {renderTable(
            edit,
            editValues,
            tableData,
            setTableData,
            tableContent,
            menuFields,
            stateAccess,
            mainStateAccess
          )}
        </>
      )}
    </>
  );
};
