import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";
import { FormStateToUpdate } from "./types";

interface EditOneSpanSigningRequest {
  data: FormStateToUpdate;
}

export interface EditOneSpanSigningResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditOneSpanSigningRequest,
  EditOneSpanSigningResponse
>("EditOneSpanSigning", {
  request: "update_one_span_signing"
});

export const editOneSpanSigningActions = socketMessageSlice.actions;
export const editOneSpanSigning = socketAction;
export default socketMessageSlice.reducer;
