import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CancelIcon from "@material-ui/icons/Cancel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateRange, DateRangePicker } from "DateRangePicker";
import { Filters } from "components/Filters/types";
import CloseDialogButton from "components/common/CloseDialogButton";
import {
  addMonths,
  addWeeks,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear
} from "date-fns";
import React from "react";
import { isValidDate } from "utils/models/formRenderers";
import { Column, filterDefaultDateFilter } from ".";
import { HintTooltip } from "components/common/HintTooltip";
type Props<T extends unknown> = {
  dateState: {
    from: string;
    to: string;
    fromCompare?: string;
    toCompare?: string;
  };
  filterState:
    | {
        values: {
          startDate: Date;
          endDate: Date;
        };
      }
    | undefined;
  setFilterState: React.Dispatch<
    React.SetStateAction<
      | {
          values: {
            startDate: Date;
            endDate: Date;
          };
        }
      | undefined
    >
  >;
  setDateState: React.Dispatch<
    React.SetStateAction<{
      from: string;
      to: string;
      fromCompare?: string;
      toCompare?: string;
    }>
  >;
  defaultDateColumns: Column<T>[];
  dateFilterColumns: Column<T>[] | undefined;
  setDateFilterColumns: React.Dispatch<React.SetStateAction<Column<T>[] | undefined>>;
  groupColumns: Column<T>[];
  compareMode: boolean;
  setCompareMode: React.Dispatch<React.SetStateAction<boolean>>;
  defaultDateFilterColumnName?: string;
  filters: Filters | undefined;
  setFilters: (
    state: (Filters | undefined) | ((oldState: Filters | undefined) => Filters | undefined)
  ) => void;
};
export const formatRange = (range: DateRange, label: string) => {
  if (range.startDate !== undefined && range.endDate !== undefined)
    return (
      <span style={{ fontSize: "14px", marginRight: "15px", whiteSpace: "nowrap" }}>
        <strong>{label}</strong>: {new Date(range.startDate).toLocaleDateString("en-US")}
        {" - "}
        {new Date(range.endDate).toLocaleDateString("en-US")}
      </span>
    );
  else {
    return null;
  }
};

export default <T extends unknown>({
  defaultDateColumns,
  filters,
  setFilters,
  dateFilterColumns,
  setDateFilterColumns,
  groupColumns,
  dateState,
  setDateState,
  compareMode,
  filterState,
  setFilterState,
  setCompareMode,
  defaultDateFilterColumnName
}: Props<T>) => {
  const [date] = React.useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [filterType, setFilterType] = React.useState<string>("$and");
  const isMobile = useMediaQuery("(max-width:800px)");

  const handleFilter = (columns: Column<T>[]) => {
    if (filterType === "$or") {
      const filter = columns
        .filter((c) => c?.dateFilterPath)
        .map((c) => ({
          query: {
            [c?.dateFilterPath?.join(".") as string]: {
              $gte: dateState.from,
              $lte: dateState.to,
              ...(dateState?.fromCompare && dateState?.toCompare && compareMode
                ? {
                    compare: {
                      $gte: dateState.fromCompare,
                      $lte: dateState.toCompare
                    }
                  }
                : {})
            }
          },
          values: {
            startDate: new Date(dateState.from),
            endDate: new Date(dateState.to)
          }
        }));
      setFilters((prev) => {
        return {
          ...filterDefaultDateFilter(prev, [
            defaultDateFilterColumnName,
            ...columns.map((c) => c.name)
          ]),
          ["$or"]: [...(prev?.["$or"] ?? []), ...filter]
        };
      });
    } else {
      const filter = columns.reduce((acc: Filters, c) => {
        if (c?.dateFilterPath && c?.name) {
          const columnFilter = {
            query: {
              [c.dateFilterPath.join(".")]: {
                $gte: dateState.from,
                $lte: dateState.to,
                ...(dateState?.fromCompare && dateState?.toCompare && compareMode
                  ? {
                      compare: {
                        $gte: dateState.fromCompare,
                        $lte: dateState.toCompare
                      }
                    }
                  : {})
              }
            },
            values: {
              startDate: new Date(dateState.from),
              endDate: new Date(dateState.to)
            }
          };

          const x = [...(filters?.[c?.name] ?? [])];
          x[0] = columnFilter;
          return {
            ...acc,
            [c.name]: x
          };
        }
        return acc;
      }, {});
      setFilters((prev) => ({
        ...filterDefaultDateFilter(prev, [defaultDateFilterColumnName]),
        ...filter
      }));
    }

    setFilterState({
      values: {
        startDate: new Date(dateState.from),
        endDate: new Date(dateState.to)
      }
    });
  };

  const handleClear = (column: Column<T>, index: number) => {
    const newColumns = [...(dateFilterColumns ?? [])];
    newColumns.splice(index, 1);

    if (column?.name) {
      const x = [...(filters?.[column?.name] ?? [])];
      x[0] = undefined;

      const newOr =
        filters?.["$or"]?.filter(
          (x) => x?.query?.[column?.dateFilterPath?.join(".") as string] === undefined
        ) ?? [];
      if (newColumns.length === 1) {
        const restColumns = (dateFilterColumns ?? [])
          .filter((c) => c.name !== column.name)
          .reduce((acc: Filters, c) => {
            if (c?.dateFilterPath && c?.name) {
              const columnFilter = {
                query: {
                  [c.dateFilterPath.join(".")]: {
                    $gte: dateState.from,
                    $lte: dateState.to
                  }
                },
                values: {
                  startDate: new Date(dateState.from),
                  endDate: new Date(dateState.to)
                }
              };

              const x = [...(filters?.[c?.name] ?? [])];
              x[0] = columnFilter;
              return {
                ...acc,
                [c.name]: x
              };
            }
            return acc;
          }, {});
        setFilters((prev) => {
          const { $or, ...restFilters } = prev as Filters;
          return {
            ...restFilters,
            ...restColumns,
            ...(x.length ? { [column.name]: x } : {})
          };
        });
        setFilterType("$and");
      } else {
        setFilters((prev) => ({
          ...prev,
          ...(newOr?.length ? { $or: newOr } : {}),
          ...(x.length ? { [column.name]: x } : {})
        }));
      }
    }
    if (!newColumns.length) {
      setFilterState(undefined);
    }
    setDateFilterColumns(newColumns);
  };
  return (
    <React.Fragment>
      <div
        style={{
          display: isMobile ? "inline-block" : "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {(dateFilterColumns ?? []).map((column, index) => {
          return column?.label && filterState?.values ? (
            <IconButton
              key={index}
              size="small"
              style={{
                color: "#254e6f"
              }}
              onClick={() => handleClear(column, index)}
            >
              {formatRange(filterState?.values, column.label)}
              <CancelIcon />
            </IconButton>
          ) : null;
        })}
        <HintTooltip title={"Date filters"}>
          <IconButton onClick={() => setOpen(!open)}>
            <CalendarTodayIcon style={{ color: "#0000008a" }} />
          </IconButton>
        </HintTooltip>
      </div>

      <Dialog maxWidth="md" fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Date filter</DialogTitle>
        <CloseDialogButton closeFunction={() => setOpen(false)} />
        <DialogContent>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={(dateFilterColumns ?? [])?.length > 1 ? 9 : 12}>
              <Autocomplete
                value={dateFilterColumns}
                getOptionLabel={(column) => {
                  return column.label;
                }}
                getOptionSelected={(x, y) => x.name === y.name}
                options={defaultDateColumns}
                onChange={(event, newColumns) => {
                  setDateFilterColumns(newColumns);
                }}
                multiple
                loading={false}
                openOnFocus
                id="date-column-select"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ ...params.InputProps }}
                    label={"Date column to filter on"}
                    variant="filled"
                  />
                )}
              />
            </Grid>
            {(dateFilterColumns ?? [])?.length > 1 && (
              <Grid item xs={3}>
                <RadioGroup
                  row
                  aria-label="andOr"
                  name="andOr"
                  value={filterType}
                  onChange={(e) => setFilterType(e.target.value)}
                >
                  <FormControlLabel value="$and" control={<Radio />} label="And" />
                  <FormControlLabel value="$or" control={<Radio />} label="Or" />
                </RadioGroup>
              </Grid>
            )}

            <Grid container item xs={12} style={{ alignItems: "center" }}>
              <Grid xs={5} item style={{ padding: "5px" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    id={"from"}
                    name={"from"}
                    error={!isValidDate(dateState.from)}
                    helperText={!isValidDate(dateState.from) ? "Invalid date" : undefined}
                    autoOk
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputVariant="filled"
                    variant="inline"
                    label={"From"}
                    required={true}
                    format="MM/dd/yyyy"
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    size="small"
                    value={dateState.from ?? null}
                    onChange={(value) => {
                      if (isValidDate(value) && value) {
                        value.setHours(0);
                        value.setMinutes(0);
                        value.setSeconds(0);
                        value.setMilliseconds(0);
                        setDateState((prevDateState) => ({
                          ...prevDateState,
                          from: value.toISOString()
                        }));
                      } else {
                        setDateState((prevDateState) => ({
                          ...prevDateState,
                          from: value?.toString() as string
                        }));
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid xs={5} item style={{ padding: "5px" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    id={"to"}
                    name={"to"}
                    error={!isValidDate(dateState.to)}
                    helperText={!isValidDate(dateState.to) ? "Invalid date" : undefined}
                    autoOk
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputVariant="filled"
                    variant="inline"
                    label={"To"}
                    required={true}
                    format="MM/dd/yyyy"
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    size="small"
                    value={dateState.to ?? null}
                    onChange={(value) => {
                      if (isValidDate(value) && value) {
                        value.setHours(23);
                        value.setMinutes(59);
                        value.setSeconds(59);
                        value.setMilliseconds(59);
                        setDateState((prevDateState) => ({
                          ...prevDateState,
                          to: value.toISOString()
                        }));
                      } else {
                        setDateState((prevDateState) => ({
                          ...prevDateState,
                          to: value?.toString() as string
                        }));
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={2}>
                {groupColumns.some((c) => c.type === "date") ? (
                  <HintTooltip title="Compare mode not available when there are groups using a date field.">
                    <FormControlLabel
                      disabled={true}
                      control={<Checkbox id={"compare"} checked={compareMode} />}
                      label={"Compare mode"}
                    />
                  </HintTooltip>
                ) : (
                  <FormControlLabel
                    onChange={() => setCompareMode((prev) => !prev)}
                    control={<Checkbox id={"compare"} checked={compareMode} />}
                    label={"Compare mode"}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {React.useMemo(
                () => (
                  <DateRangePicker
                    initialDateRange={
                      dateState?.from && dateState?.to
                        ? { startDate: new Date(dateState?.from), endDate: new Date(dateState?.to) }
                        : {}
                    }
                    onChange={(range) => {
                      if (range?.startDate !== undefined && range?.endDate !== undefined) {
                        const beginningOfTheDay = new Date(range?.startDate);
                        beginningOfTheDay.setHours(0, 0, 0, 0);
                        const endOfTheDay = new Date(range?.endDate);
                        endOfTheDay.setHours(23, 59, 59, 999);
                        setDateState((prev) => ({
                          ...prev,
                          from: beginningOfTheDay.toISOString(),
                          to: endOfTheDay.toISOString()
                        }));
                      }
                    }}
                    definedRanges={[
                      {
                        label: "This Week",
                        startDate: startOfWeek(date),
                        endDate: endOfWeek(date)
                      },
                      {
                        label: "Last Week",
                        startDate: startOfWeek(addWeeks(date, -1)),
                        endDate: endOfWeek(addWeeks(date, -1))
                      },
                      {
                        label: "This Month",
                        startDate: startOfMonth(date),
                        endDate: endOfMonth(date)
                      },
                      {
                        label: "Last Month",
                        startDate: startOfMonth(addMonths(date, -1)),
                        endDate: endOfMonth(addMonths(date, -1))
                      },
                      {
                        label: "Quarter",
                        startDate: startOfQuarter(date),
                        endDate: endOfQuarter(date)
                      },
                      {
                        label: "Past 12 Months",
                        startDate: startOfMonth(addMonths(date, -12)),
                        endDate: endOfMonth(date)
                      },
                      {
                        label: "First half year",
                        startDate: startOfYear(date),
                        endDate: addMonths(startOfYear(date), 6)
                      },
                      {
                        label: "Second half year",
                        startDate: addMonths(startOfYear(date), 6),
                        endDate: addMonths(startOfYear(date), 12)
                      }
                    ]}
                  />
                ),
                [dateState]
              )}
            </Grid>
          </Grid>
          {compareMode && (
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Compare settings</Typography>
              </Grid>
              <Grid xs={6} item style={{ padding: "5px" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    id={"fromCompare"}
                    name={"fromCompare"}
                    error={!isValidDate(dateState.fromCompare)}
                    helperText={!isValidDate(dateState.fromCompare) ? "Invalid date" : undefined}
                    autoOk
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputVariant="filled"
                    variant="inline"
                    label={"From (Compare)"}
                    required={true}
                    format="MM/dd/yyyy"
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    size="small"
                    value={dateState.fromCompare ?? null}
                    onChange={(value) => {
                      if (isValidDate(value) && value) {
                        value.setHours(0);
                        value.setMinutes(0);
                        value.setSeconds(0);
                        value.setMilliseconds(0);
                        setDateState((prevDateState) => ({
                          ...prevDateState,
                          fromCompare: value.toISOString()
                        }));
                      } else {
                        setDateState((prevDateState) => ({
                          ...prevDateState,
                          fromCompare: value?.toString() as string
                        }));
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid xs={6} item style={{ padding: "5px" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    id={"toCompare"}
                    name={"toCompare"}
                    error={!isValidDate(dateState.toCompare)}
                    helperText={!isValidDate(dateState.toCompare) ? "Invalid date" : undefined}
                    autoOk
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputVariant="filled"
                    variant="inline"
                    label={"To (Compare)"}
                    required={true}
                    format="MM/dd/yyyy"
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    size="small"
                    value={dateState.toCompare ?? null}
                    onChange={(value) => {
                      if (isValidDate(value) && value) {
                        value.setHours(23);
                        value.setMinutes(59);
                        value.setSeconds(59);
                        value.setMilliseconds(59);
                        setDateState((prevDateState) => ({
                          ...prevDateState,
                          toCompare: value.toISOString()
                        }));
                      } else {
                        setDateState((prevDateState) => ({
                          ...prevDateState,
                          toCompare: value?.toString() as string
                        }));
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <DateRangePicker
                  initialDateRange={
                    dateState?.fromCompare && dateState?.toCompare
                      ? {
                          startDate: new Date(dateState?.fromCompare),
                          endDate: new Date(dateState?.toCompare)
                        }
                      : {}
                  }
                  onChange={(range) => {
                    if (range?.startDate !== undefined && range?.endDate !== undefined) {
                      const beginningOfTheDay = new Date(range?.startDate);
                      beginningOfTheDay.setHours(0, 0, 0, 0);
                      const endOfTheDay = new Date(range?.endDate);
                      endOfTheDay.setHours(23, 59, 59, 999);
                      setDateState((prev) => ({
                        ...prev,
                        fromCompare: beginningOfTheDay.toISOString(),
                        toCompare: endOfTheDay.toISOString()
                      }));
                    }
                  }}
                  definedRanges={[
                    {
                      label: "This Week",
                      startDate: startOfWeek(date),
                      endDate: endOfWeek(date)
                    },
                    {
                      label: "Last Week",
                      startDate: startOfWeek(addWeeks(date, -1)),
                      endDate: endOfWeek(addWeeks(date, -1))
                    },
                    {
                      label: "This Month",
                      startDate: startOfMonth(date),
                      endDate: endOfMonth(date)
                    },
                    {
                      label: "Last Month",
                      startDate: startOfMonth(addMonths(date, -1)),
                      endDate: endOfMonth(addMonths(date, -1))
                    },
                    {
                      label: "Quarter",
                      startDate: startOfQuarter(date),
                      endDate: endOfQuarter(date)
                    },
                    {
                      label: "Past 12 Months",
                      startDate: startOfMonth(addMonths(date, -12)),
                      endDate: endOfMonth(date)
                    },
                    {
                      label: "First half year",
                      startDate: startOfYear(date),
                      endDate: addMonths(startOfYear(date), 6)
                    },
                    {
                      label: "Second half year",
                      startDate: addMonths(startOfYear(date), 6),
                      endDate: addMonths(startOfYear(date), 12)
                    }
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <div>
            <Button onClick={() => setOpen(!open)} variant="contained" color="primary" id="cancel">
              Cancel
            </Button>
          </div>
          <Button
            disabled={
              compareMode
                ? (dateFilterColumns ?? []).length === 0 ||
                  dateState.fromCompare === undefined ||
                  dateState.toCompare === undefined
                : (dateFilterColumns ?? []).length === 0
            }
            variant="contained"
            color="primary"
            autoFocus
            onClick={() => {
              handleFilter(dateFilterColumns ?? []);
              setOpen(!open);
            }}
          >
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
