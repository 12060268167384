import { Grid } from "@material-ui/core";
import React from "react";
import { StateAccess } from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";
import { Checklist } from "../Checklist/types";

const AdditionalText = ({
  checklistStateAccess,
  path
}: {
  checklistStateAccess: StateAccess;
  path: string[];
}) => {
  return (
    <Grid container style={{ padding: 10, marginTop: "auto" }}>
      {editRenderSet(false).textInputRenderer<Checklist>(
        {
          type: "text",
          name: "Optional text",
          label: "Optional text",
          path: path,
          multiline: true,
          rows: 3
        },
        checklistStateAccess,
        editRenderSet(false)
      )}
    </Grid>
  );
};

export default AdditionalText;
