import { permissionNode, PermissionStruct, relationPermissionNode } from "components/Roles/types";
import { HistoryData } from "utils/entitySlice";

export type HistoryPermissionsStruct = PermissionStruct<HistoryData>;

export const historyPermissionStruct: HistoryPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: permissionNode("Data")
};
