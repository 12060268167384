import { Typography } from "@material-ui/core";
import firebase from "firebase/compat";
import React, { useEffect, useRef, useState } from "react";
import { EntityType } from "utils/entitySlice";
import { StateAccess } from "utils/models/formGenerator";
import DropZone from "../../utils/DropZone";

export default function DropZonePreview({
  stateAccess,
  entityType
}: {
  stateAccess: StateAccess;
  entityType: EntityType;
}): JSX.Element {
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const fileInfo = stateAccess.get(["data", "info"]);
  const [firebaseFileInfo, setFirebaseFileInfo] = useState<{
    url: string;
    name: string;
    metadata: any;
  } | null>(null);

  const addFileOnClick = (fileName: string, ref: firebase.storage.Reference) => {
    ref.getDownloadURL().then((fireBaseUrl) => {
      ref.getMetadata().then((metadata) => {
        setFirebaseFileInfo({
          url: fireBaseUrl,
          name: fileName,
          metadata
        });
      });
    });
  };

  useEffect(() => {
    if (firebaseFileInfo)
      stateAccess.set(["data", "info"], {
        ...fileInfo,
        ...firebaseFileInfo
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseFileInfo]);
  return (
    <div style={{ position: "relative", height: "100%" }} ref={dropZoneRef}>
      {fileInfo.url ? (
        <Typography component="p"> {fileInfo.name}</Typography>
      ) : (
        <DropZone
          addFunction={addFileOnClick}
          type={entityType}
          id={stateAccess.get(["_id"])}
          containerRef={dropZoneRef}
          isInAddFileDialog={true}
        />
      )}
    </div>
  );
}
