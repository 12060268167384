import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteDealershipTicketNoteRequest {
  _id: string;
}

interface DeleteDealershipTicketNoteResponse {
  message: {
    _id: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteDealershipTicketNoteRequest,
  DeleteDealershipTicketNoteResponse
>("DeleteDealershipTicketNote", {
  request: "delete_dealership_ticket_note"
});

export const deleteDealershipTicketNoteActions = socketMessageSlice.actions;
export const deleteDealershipTicketNote = socketAction;
export default socketMessageSlice.reducer;
