import { SurchargeName } from "./types";
import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";

interface Props {
  onChangeSurchargeFunction: (surchargeName: SurchargeName) => void;
  isSurchargeChecked: (surcharge: SurchargeName) => boolean;
  rateSurcharges: { name: SurchargeName; value: string }[];
  disabled?: boolean;
}
const ASCSurcharges = ({
  rateSurcharges,
  onChangeSurchargeFunction,
  isSurchargeChecked,
  disabled
}: Props) => {
  return (
    <>
      <div style={{ display: "flex" }}>
        {rateSurcharges?.map((surcharge, index) => (
          <div
            key={index}
            style={{
              border: " 1px solid",
              padding: "0 5px ",
              borderRadius: "15px",
              margin: "5px",
              borderColor: "#254e70"
            }}
          >
            <FormControlLabel
              onChange={() => onChangeSurchargeFunction(surcharge.name)}
              control={
                <Checkbox
                  id={surcharge.name}
                  disabled={disabled}
                  checked={isSurchargeChecked(surcharge.name)}
                />
              }
              label={`${surcharge.name} - $${surcharge.value}`}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ASCSurcharges;
