import { IconButton } from "@material-ui/core";
import React, { useState } from "react";
import { StateAccess } from "utils/models/formGenerator";
import { Deal } from "components/Deals/types";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import DeleteDialog from "components/common/DeleteDialog";
interface Props {
  index: number;
  stateAccess: StateAccess;
}

const handleDelete = (index: number, stateAccess: StateAccess) => {
  const insurances = stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "insurances"]);
  stateAccess.set<Deal>(
    ["data", "info", "aftermarketOptions", "insurances"],
    insurances.filter((el: any, insuranceIndex: number) => index !== insuranceIndex)
  );
};

export default function DeleteInsuranceContract({ stateAccess, index }: Props) {
  const insurance = stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "insurances"])[
    index
  ];
  const [dialogState, setDialogState] = useState(false);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <DeleteDialog
        message="Are you sure you want to delete this insurance? This opration will delete the generated contract number!"
        deleteFunction={() => {
          handleDelete(index, stateAccess);
          setDialogState(false);
        }}
        setDialogState={setDialogState}
        dialogState={dialogState}
      />
      <IconButton
        style={{
          color: "red"
        }}
        onClick={() =>
          insurance?.sequence?.seq ? setDialogState(true) : handleDelete(index, stateAccess)
        }
      >
        <RemoveCircleIcon />
      </IconButton>
    </div>
  );
}
