import { useQuery } from "@tanstack/react-query";

import { HttpQuery } from "Chat/types/http";
import axios from "axios";
import { Applicant } from "components/Applicants/types";
import { Dealership } from "components/Dealerships/types";
import { Deal } from "components/Deals/types";
import { Lender } from "components/Lenders/types";
import { auth } from "../../firebase/firebase";
import { GlobalSearchEntityType } from "components/GlobalSearch/types";

const injectOptionsToQuery = (entity: GlobalSearchEntityType, query?: HttpQuery) => {
  switch (entity) {
    case "deal": {
      const options = {
        pagination: false,
        projection: {
          _id: 1,
          createdAt: 1,
          "data.applicant.data.info.firstName": 1,
          "data.applicant.data.info.lastName": 1,
          "data.coApplicant.data.info.firstName": 1,
          "data.coApplicant.data.info.lastName": 1,
          "data.info.refNumber": 1,
          "data.dealership.data.info.name": 1,
          "data.lender.data.info.name": 1,
          "data.info.status": 1
        },
        sort: { createdAt: "desc" }
      };
      return query && Object.keys(query)?.length
        ? { query: JSON.stringify({ ...query, options }) }
        : { query: JSON.stringify({ options }) };
    }
    case "applicant": {
      const options = {
        projection: {
          _id: 1,
          createdAt: 1,
          "data.info.firstName": 1,
          "data.info.lastName": 1,
          "data.info.currentCity": 1,
          "data.info.currentZipCode": 1,
          "data.info.mobilePhone": 1
        },
        sort: { createdAt: "desc" },
        limit: 10
      };
      return query && Object.keys(query)?.length
        ? { query: JSON.stringify({ ...query, options }) }
        : { query: JSON.stringify({ options }) };
    }
    case "lender": {
      const options = {
        projection: {
          _id: 1,
          createdAt: 1,
          "data.info.name": 1
        },
        sort: { createdAt: "desc" },
        limit: 10
      };
      return query && Object.keys(query)?.length
        ? { query: JSON.stringify({ ...query, options }) }
        : { query: JSON.stringify({ options }) };
    }
    case "dealership": {
      const options = {
        projection: {
          _id: 1,
          createdAt: 1,
          "data.contacts": 1,
          "data.info.name": 1,
          "data.info.displayName": 1,
          "data.info.zipCode": 1,
          "data.info.city": 1,
          "data.info.phone": 1
        },
        sort: { createdAt: "desc" },
        limit: 10
      };
      return query && Object.keys(query)?.length
        ? { query: JSON.stringify({ ...query, options }) }
        : { query: JSON.stringify({ options }) };
    }
  }
};

export const getLenders = (query?: HttpQuery): (() => Promise<Lender[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(`${process.env.REACT_APP_HTTP_URL}/api/v1/lender/`, {
      headers: {
        authorization: `Bearer ${token}`,
        ...injectOptionsToQuery("lender", query)
      }
    });

    return response.data.result?.docs ?? [];
  };
};
export const getApplicants = (query?: HttpQuery): (() => Promise<Applicant[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${process.env.REACT_APP_HTTP_URL}/api/v1/applicant/`, {
      headers: {
        authorization: `Bearer ${token}`,
        ...injectOptionsToQuery("applicant", query)
      }
    });

    return response.data.result?.docs ?? [];
  };
};
export const getApplications = (query?: HttpQuery): (() => Promise<Deal[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${process.env.REACT_APP_HTTP_URL}/api/v1/deal/`, {
      headers: {
        authorization: `Bearer ${token}`,
        ...injectOptionsToQuery("deal", query)
      }
    });

    return response.data.result.docs ?? [];
  };
};
export const getDealerships = (query?: HttpQuery): (() => Promise<Dealership[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${process.env.REACT_APP_HTTP_URL}/api/v1/dealership/`, {
      headers: {
        authorization: `Bearer ${token}`,
        ...injectOptionsToQuery("dealership", query)
      }
    });

    return response.data.result.docs ?? [];
  };
};
const useGlobalSearch = (query: HttpQuery) => {
  const { data: deals, isLoading: dealsAreLoading, error: dealsError } = useQuery({
    queryKey: ["deals-global-search", query ?? {}],
    queryFn: getApplications(query),
    enabled: !!Object.keys(query)?.length
  });
  const { data: applicants, isLoading: applicantsAreLoading, error: applicantsError } = useQuery({
    queryKey: ["applicants-global-search", query ?? {}],
    queryFn: getApplicants(query),
    enabled: !!Object.keys(query)?.length
  });
  const { data: lenders, isLoading: lendersAreLoading, error: lendersError } = useQuery({
    queryKey: ["lenders-global-search", query ?? {}],
    queryFn: getLenders(query),
    enabled: !!Object.keys(query)?.length
  });
  const { data: dealerships, isLoading: dealershipsAreLoading, error: dealershipsError } = useQuery(
    {
      queryKey: ["dealerships-global-search", query ?? {}],
      queryFn: getDealerships(query),
      enabled: !!Object.keys(query)?.length
    }
  );
  return {
    data: { applicants, deals, lenders, dealerships },
    isLoading: { applicantsAreLoading, dealsAreLoading, lendersAreLoading, dealershipsAreLoading },
    error: { dealsError, applicantsError, lendersError, dealershipsError }
  };
};
export default useGlobalSearch;
