import axios from "axios";
import { User } from "components/Users/types";
import { auth } from "../../firebase/firebase";
import { HttpQuery } from "Chat/types/http";

export const getUsers = (query?: HttpQuery): (() => Promise<User[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${process.env.REACT_APP_HTTP_URL}/api/v1/wfd_user/`, {
      headers: {
        authorization: `Bearer ${token}`,
        ...(query && Object.keys(query)?.length ? { query: JSON.stringify(query) } : {})
      }
    });

    return response.data.result?.docs ?? [];
  };
};
