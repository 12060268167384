import React, { useState } from "react";
import { StateAccess, RenderSet, getByPath } from "utils/models/formGenerator";
import { createOptionsForSelect, Path } from "utils/models/fields";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { getLenderList, removeLenderList } from "../Lenders/listLenderSlice";
import { v4 as uuidv4 } from "uuid";
import { DocumentTemplate } from "./types";
import { Lender } from "components/Lenders/types";
import { lenderStruct } from "components/Lenders/model";
import { addLender } from "components/Lenders/addLenderSlice";
import { Box, Grid } from "@material-ui/core";
import AddVendor from "components/OtherVendors/AddEntitiy";
interface Props<T> {
  stateAccess: StateAccess;
  path: Path<T>;
  renderSet: RenderSet;
  name: string;
  label: string;
}

export default function LendersPreview<T extends DocumentTemplate>({
  stateAccess,
  path,
  renderSet,
  name,
  label
}: Props<T>) {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const listId = `list-autocomplete-lenders-${requestId}`;
  const lenderList = useSelector((state: RootState) => state.listLenderSlice[listId]);

  const lenders: Lender[] = stateAccess.get<T>(path) ?? [];
  const lenderStateAccess: StateAccess = {
    get: (path) => getByPath<Lender[]>(lenders, path as Path<Lender[]>),
    set: (_, value): any => stateAccess.set<T>(path, value)
  };

  const handleLoadLenders = () => {
    if (!lenderList)
      dispatch(
        getLenderList(listId, { options: { pagination: false, sort: { "data.info.name": "asc" } } })
      );
    return () => {
      dispatch(removeLenderList(listId));
      return;
    };
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1}>
              {renderSet.multiSelectInputRenderer(
                {
                  formComponent: "multiselect-field",
                  name,
                  label,
                  path: [],
                  onOpen: handleLoadLenders,
                  options: createOptionsForSelect({
                    possibleValues: () => lenderList?.entities ?? [],
                    getOptionLabel: (lender) => {
                      return lender?.data?.info?.name ?? "";
                    },
                    getSelectedOption: (x, y) => {
                      return x._id === y._id;
                    }
                  }),
                  valueToString: (el) => {
                    return el.map((el: Lender) => el.data.info.name).join(", ");
                  }
                },
                lenderStateAccess,
                [],
                lenderStateAccess,
                renderSet
              )}
            </Box>
            <Box>
              <AddVendor
                type="lender"
                addFunction={addLender}
                struct={lenderStruct}
                addSlice={"addLenderSlice"}
                setSelected={(vendor) => {
                  stateAccess.set(path as [], [...(stateAccess.get(path) || []), vendor]);
                }}
                renderSet={renderSet}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
