import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteDocumentTemplateRequest {
  _id: string;
}

interface DeleteDocumentTemplateResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteDocumentTemplateRequest,
  DeleteDocumentTemplateResponse
>("Delete Document Template", {
  request: "delete_document_template"
});

export const deleteDocumentTemplate = socketAction;
export const deleteDocumentTemplateActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
