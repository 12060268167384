import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import Big from "big.js";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { CollateralTypes, DealStatus, collateralTypes } from "components/Deals/types";
import {
  CollateralTypesEstimation,
  DealershipsEstimation,
  EstimationResult,
  EstimationStructure,
  LendersEstimation,
  RangesEstimation,
  StatusesEstimation
} from "hooks/useLenderEstimationStatistics/types";
import React from "react";
import { capitalize } from "utils/functions";
import DealsModal from "../DealsModal";
import { columns } from "./StatisticsTable";
type EstimationProps = {
  data:
    | EstimationResult<
        | CollateralTypesEstimation
        | LendersEstimation
        | DealershipsEstimation
        | StatusesEstimation
        | RangesEstimation
      >
    | EstimationStructure;
  title: string | undefined;
  subtitle: string | undefined;
  onBack: () => void;
  onDiveDeeper: (
    title: string,
    subtitle: string,
    data:
      | EstimationResult<
          | CollateralTypesEstimation
          | LendersEstimation
          | DealershipsEstimation
          | StatusesEstimation
          | RangesEstimation
        >
      | EstimationStructure
  ) => void;
  statusToCompareTo: DealStatus.Funded | DealStatus.Dead;
};

const dataIsEstimationStructure = (data: any): data is EstimationStructure =>
  Object.hasOwn(data, "types");

const EstimationPerformance: React.FC<EstimationProps> = ({
  statusToCompareTo,
  title,
  subtitle,
  data,
  onBack,
  onDiveDeeper
}) => {
  const [dealIdsForModal, setDealIdsForModal] = React.useState<string[]>([]);
  return (
    <Grid container xs={12} item spacing={1} style={{ padding: "5px" }}>
      {dataIsEstimationStructure(data) ? (
        <Grid container item xs={12}>
          <Grid item xs={12} style={{ padding: "5px 0px" }}>
            <Typography style={{ fontSize: "22px", fontWeight: "bold" }}>Results</Typography>
            <Typography>
              Average days it takes for deals to get {statusToCompareTo}:{" "}
              {new Big(data.results.days || 0).round(2, 1).toNumber()}
            </Typography>
            <Typography>
              Percentage of deals that get {statusToCompareTo}:{" "}
              {new Big(data.results.percentage || 0).mul(100).round(2, 1).toNumber()}%
            </Typography>
            <Typography>
              Deal count that are included in this section: {data?.dealsIds.length || 0}
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                setDealIdsForModal(data?.dealsIds || []);
              }}
            >
              Show deals
            </Button>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            {Object.keys(data.types).map((collateralType, index) => (
              <Grid item xs={12} key={index}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{ height: "100%" }}
                  onClick={() =>
                    onDiveDeeper(
                      collateralType,
                      data.types[collateralType as CollateralTypes].child as string,
                      data.types[collateralType as CollateralTypes]
                    )
                  }
                >
                  {collateralType} ({data.types[collateralType as CollateralTypes]?.dealsIds.length}{" "}
                  /
                  {new Big(data.types[collateralType as CollateralTypes]?.results?.percentage || 0)
                    .mul(100)
                    .round(2, 1)
                    .toNumber()}
                  %)
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          <Grid item xs={12} container style={{ alignItems: "center" }}>
            <Grid item xs={1}>
              <IconButton onClick={onBack} style={{ padding: "0px 0px 0px 15px" }}>
                <KeyboardBackspaceIcon />
              </IconButton>
            </Grid>
            <Grid item xs={11}>
              <Typography style={{ fontSize: "22px", fontWeight: "bold" }}>{title}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} style={{ padding: "5px 0px" }}>
              <Typography style={{ fontSize: "22px", fontWeight: "bold" }}>Results</Typography>
              <Typography>
                Average days it takes for deals to get {statusToCompareTo}:{" "}
                {new Big(data.results.days || 0).round(2, 1).toNumber()}
              </Typography>
              <Typography>
                Percentage of deals that get {statusToCompareTo}:{" "}
                {new Big(data.results.percentage || 0).mul(100).round(2, 1).toNumber()}%
              </Typography>
              <Typography>
                Deal count that get included in this section: {data.dealsIds.length || 0}
              </Typography>
              <Button
                variant="outlined"
                onClick={() => {
                  setDealIdsForModal(data?.dealsIds || []);
                }}
              >
                Show deals
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {data?.child && (
              <Grid item container xs={12} style={{ padding: "5px 0px" }}>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: "22px", fontWeight: "bold" }}>
                    {capitalize(subtitle)}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ maxHeight: "300px", overflowY: "auto" }}>
                  <List>
                    {/* @ts-ignore */}
                    {Object.entries(data[data.child])
                      .sort(([aKey, aValue], [bKey, bValue]) => {
                        //@ts-ignore
                        if (!isNaN(parseInt(aValue?.name)) && !isNaN(parseInt(bValue?.name))) {
                          //@ts-ignore
                          return parseInt(aValue?.name) - parseInt(bValue?.name);
                        } else {
                          //@ts-ignore
                          return aValue?.name?.localeCompare(bValue?.name);
                        }
                      })
                      .filter(([key, value]) => key !== "child" && key !== "results")
                      .map(([key, value], index) => (
                        <ListItem
                          button
                          component="nav"
                          key={index}
                          onClick={() =>
                            onDiveDeeper(
                              `${capitalize(title)} / ${capitalize(
                                data.child as string
                              )} / ${capitalize(
                                // @ts-ignore
                                data?.[data.child]?.[key].name ?? "No name"
                              )}`,
                              // @ts-ignore
                              data[data.child][key].child,
                              // @ts-ignore
                              data[data.child][key]
                            )
                          }
                        >
                          <ListItemText
                            primary={`${
                              // @ts-ignore
                              data?.[
                                // @ts-ignore
                                data.child
                              ]?.[key].name || "No name"
                            } (${
                              // @ts-ignore
                              data?.[
                                // @ts-ignore
                                data.child
                              ]?.[key]?.dealsIds?.length
                            }/${new Big(
                              // @ts-ignore
                              data?.[data.child]?.[key]?.results?.percentage || 0
                            )
                              .mul(100)
                              .round(2, 1)
                              .toNumber()}%)`}
                          />
                        </ListItem>
                      ))}
                  </List>
                </Grid>
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
      {dealIdsForModal.length > 0 ? (
        <DealsModal
          columns={columns}
          data={[
            {
              period: "Deals",
              dealIds: dealIdsForModal
            }
          ]}
          closeFunction={() => setDealIdsForModal([])}
        />
      ) : null}
    </Grid>
  );
};

export default EstimationPerformance;
