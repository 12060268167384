import { createSocketMessageSlice } from "components/Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditContractTypeRequest {
  data: FormState;
}

interface EditContractTypeResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditContractTypeRequest,
  EditContractTypeResponse
>("EditContractType", {
  request: "update_contract_type"
});

export const editContractType = socketAction;
export const editContractTypeActions = socketMessageSlice.actions;

export default socketMessageSlice.reducer;
