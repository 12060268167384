import { createListSlice } from "../Middlewares/listSliceCreator";
import { Contract } from "./types";

const { ListSlice, getList } = createListSlice<Contract>({
  name: "ContractList",
  request_topic: "all_contracts",
  reducers: {}
});
export default ListSlice.reducer;
export const getContractList = getList;
export const listContractActions = ListSlice.actions;
export const removeContractList = ListSlice.actions.removeList;
