import { Chip } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import { Dealership } from "components/Dealerships/types";
import ContractStatusFilter from "components/Filters/ContractStatusFilter";
import TextFilter from "components/Filters/TextFilter";
import { Lender } from "components/Lenders/types";
import Table, { CellValue, Column } from "components/Table";
import React from "react";
import { useSelector } from "react-redux";
import { StateAccess } from "utils/models/formGenerator";
import { getLatestContractFile } from "./ContractDatesPreview";
import { getContractList } from "./listContractSlice";
import { ContractData } from "./types";
const projection = {
  "data.info.name": 1,
  "data.files.data.info.status": 1
};

type Props = {
  stateAccess: StateAccess;
  type: "lender" | "dealership";
};
export default ({ stateAccess, type }: Props): JSX.Element => {
  const sliceId = stateAccess.get(["_id"]);
  const contractList = useSelector((state: RootState) => state.listContractSlice[sliceId]);

  const columns: Column<ContractData>[] = [
    {
      getData: (entry): CellValue => entry.data.info.name ?? "",
      label: "Name",
      name: "name",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      },
      filters: [
        {
          path: ["data", "info", "name"],
          preview: TextFilter,
          label: "Name",
          name: "name",
          partialSearch: true,
          caseInsensitive: true
        }
      ]
    },
    {
      getData: (entry): CellValue => (entry.data.info.status ? "Active" : "Inactive"),
      options: {
        customBodyRender: (x: CellValue, contractData): JSX.Element | string => {
          const lastestContractFile = getLatestContractFile(contractData?.data?.files);
          switch (lastestContractFile?.data?.info?.status ?? "Inactive") {
            case "Active":
              return (
                <Chip
                  size="small"
                  label="Active"
                  style={{ backgroundColor: "#50a538", margin: "2px 0 2px 0" }}
                />
              );
            case "Inactive":
              return (
                <Chip
                  size="small"
                  label="Inactive"
                  style={{ backgroundColor: "#f44336", margin: "5px 0 5px 0" }}
                />
              );
            default:
              return "N/A";
          }
        }
      },
      label: "Status",
      name: "status",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.status?.show;
      },
      filters: [
        {
          path: ["data", "files", "data", "info", "status"],
          preview: ContractStatusFilter,
          label: "Status",
          name: "status"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Actions",
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={{ entity: "contract", action: "read" }}>
        <Table
          tableName="contracts_preview"
          hideSearch={true}
          hideRecoverButton={true}
          hidePrintButton={true}
          slice={sliceId}
          hideDeleteButton={true}
          listFunction={getContractList}
          query={
            type === "dealership"
              ? { "data.dealershipIds": { $in: [stateAccess.get<Dealership>(["_id"])] } }
              : { "data.lenderIds": { $in: [stateAccess.get<Lender>(["_id"])] } }
          }
          entityName="contract"
          listEntity={contractList}
          sort={{ createdAt: "desc" }}
          title={"Contracts"}
          columns={columns}
          projection={projection}
        />
      </AccessControl>
    </div>
  );
};
