import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

const documentTemplateProperties = {
  pages: { type: "object" },
  documentTemplateNumber: { type: "number", nullable: true },
  documentTemplateName: { type: "string", nullable: true },
  documentTemplateId: { type: "string", nullable: true },
};

const data = {
  type: "object",
  required: [],
  properties: {
    dealId: { type: "string", nullable: true },
    dealRefNumber: { type: "string", nullable: true },
    ...documentTemplateProperties,
    documentTemplateNumbers: {
      type: "array",
      nullable: true,
      items: {
        type: "object",
        nullable: true,
        properties: {
          ...documentTemplateProperties,
        },
      },
    },
  },
};
export default {
  new_printed_document: {
    $id: "newPrintedDocument",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
  delete_printed_document: {
    $id: "deletePrintedDocument",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_printed_documents: {
    $id: "allPrintedDocuments",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
