import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { ExternalCreditApplication } from "./types";

interface RecoverExternalCreditApplicationRequest {
  _id: string;
}

export interface RecoverExternalCreditApplicationResponse {
  message: ExternalCreditApplication;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverExternalCreditApplicationRequest,
  RecoverExternalCreditApplicationResponse
>("RecoverExternalCreditApplication", {
  request: "recover_external_credit_application"
});

export const recoverExternalCreditApplicationActions = socketMessageSlice.actions;
export const recoverExternalCreditApplication = socketAction;
export default socketMessageSlice.reducer;
