import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import AccessControl from "components/Access/AccessControl";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../Dashboard/dashboardSlice";
import { signOutUser } from "../SignIn/authSlice";
import CurrentUser from "../UserProfile/userInfo";
import Notifications from "./Notifications/Notifications";

import EditProfileDialog from "components/Users/EditProfileDialog";
import ResetPasswordDialog from "components/Users/ForgotPassword/ResetPasswordDialog";
import { RootState } from "app/rootReducer";
import ChangePINDialog from "components/Users/ChangePINDialog";
import Chat from "../../Chat";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex"
      }
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none"
      }
    }
  })
);

export default function MenuLinks() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const [chpassopen, setOpenChPass] = useState(false);
  const [editProfileOpen, setOpenEditProfile] = useState(false);
  const [changePINOpen, setChangePINOpen] = useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const currentUser = useSelector((state: RootState) => state.authSlice);

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    dispatch(signOutUser());
  };
  const handleOpenDialog = () => {
    setMobileMoreAnchorEl(null);
    setAnchorEl(null);
    dispatch(openDialog());
  };

  const handleEditProfile = () => {
    setMobileMoreAnchorEl(null);
    setAnchorEl(null);
    setOpenEditProfile(true);
  };

  const handleChangePass = () => {
    setMobileMoreAnchorEl(null);
    setAnchorEl(null);
    setOpenChPass(true);
  };
  const handleChangePIN = () => {
    setChangePINOpen(true);
    setAnchorEl(null);
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <AccessControl requiredPermissions={{ entity: "wfd_user", action: "update" }}>
        <MenuItem onClick={handleEditProfile}>User preferences</MenuItem>
      </AccessControl>
      <MenuItem onClick={handleChangePIN}>Change user PIN</MenuItem>
      <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleOpenDialog}>Edit Dashboard</MenuItem>
      <AccessControl requiredPermissions={{ entity: "wfd_user", action: "update" }}>
        <MenuItem onClick={handleEditProfile}>User preferences</MenuItem>
      </AccessControl>
      <MenuItem onClick={handleChangePIN}>Change user PIN</MenuItem>
      <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
    </Menu>
  );

  return (
    <>
      <div className={classes.sectionDesktop}>
        <CurrentUser />
        <div>
          <Chat />
        </div>
        <div style={{ width: "48px", height: "20px" }}>
          <AccessControl requiredPermissions={{ entity: "notification", action: "read" }}>
            <Notifications />
          </AccessControl>
        </div>
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleMenuOpen}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
      </div>
      <div className={classes.sectionMobile}>
        <IconButton
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <MoreIcon />
        </IconButton>
      </div>
      {renderMobileMenu}
      {renderMenu}
      <ResetPasswordDialog
        open={chpassopen}
        setOpen={setOpenChPass}
        email={currentUser.user?.email ?? ""}
      />
      <EditProfileDialog open={editProfileOpen} setOpen={setOpenEditProfile} />
      {currentUser?.user?.databaseData?.data?.info?.dashboardTypes?.["f&i"] && (
        <ChangePINDialog
          open={changePINOpen}
          setOpen={setChangePINOpen}
          oldPIN={currentUser?.user?.databaseData?.data?.info?.PIN}
        />
      )}
    </>
  );
}
