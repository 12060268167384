import { Model } from "../../utils/models/fields";
import { CustomReport } from "./types";

export const customReportStruct: Model<CustomReport> = {
  formComponent: "model",
  schema: "new_custom_report",
  name: "custom_report",
  entities: [
    {
      formComponent: "segment",
      name: "Custom Report",
      entities: [
        {
          formComponent: "text-field",
          name: "Title",
          label: "Title",
          width: "full",
          required: true,
          path: ["data", "info", "title"]
        },
        {
          formComponent: "text-field",
          name: "URL",
          label: "URL",
          width: "full",
          required: true,
          path: ["data", "info", "baseUrl"]
        },
        {
          formComponent: "text-field",
          name: "ID",
          label: "ID",
          width: "full",
          required: true,
          path: ["data", "info", "chartId"]
        }
      ]
    }
  ]
};
