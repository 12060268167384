import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

type AddRollbackStatus = { data: FormState };

interface AddRollbackStatusResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddRollbackStatus,
  AddRollbackStatusResponse
>("AddRollbackStatus", {
  request: "new_rollback_status"
});

export const addRollbackStatusActions = socketMessageSlice.actions;
export const addRollbackStatus = socketAction;
export default socketMessageSlice.reducer;
