import React, { useState, useContext, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { Deal, DealData } from "components/Deals/types";
import { VerificationContext } from "components/Layout/Main";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RootState } from "app/rootReducer";
import { ASCInsurance, sumbitASCRequest } from "./types";
import { submitASC, sumbitASCActions } from "./submitASCInsuranceSlice";
import CloseDialogButton from "../common/CloseDialogButton";
import { createOptionsForSelect, FormComponent } from "utils/models/fields";
import { generateForm, StateAccess, RenderSet } from "utils/models/formGenerator";
import { editDeal } from "components/Deals/editDealSlice";
import AlertDialog from "components/common/AlertDialog";
import formEditContext from "components/Content/FormEditContext";
interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  index: number;
  insurance: ASCInsurance;
}
export const additionaFieldsStruct = (index: number): FormComponent<Deal> => ({
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "select-field",
      name: "AgreementType",
      label: "Agreement type",
      width: "full",
      autoSelect: true,
      path: [
        "data",
        "info",
        "aftermarketOptions",
        "insurances",
        index,
        "chosenRate",
        "AgreementType"
      ],
      options: createOptionsForSelect({
        possibleValues: () => ["Quote", "Agreement", "Cancellation"],
        getOptionLabel: (x) => x ?? " ",
        getSelectedOption: (x, y) => x === y
      }),
      required: true,
      default: "Agreement"
    },
    {
      formComponent: "radio-field",
      name: "LiftedVehicle",
      label: "Lifted vehicle",
      width: "full",
      path: ["data", "info", "aftermarketOptions", "insurances", index, "LiftedVehicle"],
      possibleValues: [
        { label: "Yes", value: "Y" },
        { label: "No", value: "N" }
      ],
      required: true,
      default: "N"
    },
    {
      formComponent: "radio-field",
      name: "DisplayCharges",
      label: "Display charges",
      width: "full",
      path: [
        "data",
        "info",
        "aftermarketOptions",
        "insurances",
        index,
        "chosenRate",
        "DisplayCharges"
      ],
      possibleValues: [
        { label: "Yes", value: "Y" },
        { label: "No", value: "N" }
      ],
      required: true,
      default: "Y"
    }
  ]
});
const dataToSubmit = (deal: Deal, insurance: ASCInsurance, requestId: string): sumbitASCRequest => {
  return {
    requestId: requestId,
    data: {
      YourEmailAddress: "info@webfinancedirect.com",
      AccountNumber: insurance?.AccountNumber,
      AgreementType: insurance?.AgreementType ?? "Agreement",
      ID: 0,
      Program: insurance?.chosenRate?.ProgramID,
      Plan: insurance?.chosenRate?.PlanID,
      TermID: insurance?.chosenRate?.TermID,
      VIN: deal?.data?.info?.vehicle?.VIN,
      VehicleYear: deal?.data?.info?.vehicle?.year?.toString(),
      VehicleMakeID: "",
      VehicleModelID: "",
      Mileage: deal?.data?.info?.vehicle?.odometer,
      VehicleCost: deal?.data?.info?.price?.price?.toString(),
      FirstName: deal?.data?.applicant?.data?.info?.firstName,
      LastName: deal?.data?.applicant?.data?.info?.lastName,
      Address: `${deal?.data?.applicant?.data?.info?.currentAddressNumber ?? ""} ${
        deal?.data?.applicant?.data?.info?.currentAddress ?? ""
      }`,
      AddressCont: "",
      City: deal?.data?.applicant?.data?.info?.currentCity ?? "",
      State: deal?.data?.applicant?.data?.info?.currentState ?? "",
      Zip: deal?.data?.applicant?.data?.info?.currentZipCode?.toString() ?? "",
      Phone: deal?.data?.applicant?.data?.info?.mobilePhone ?? "",
      LienholderID: 0,
      LienholderName: deal.data?.lender?.data?.info?.name ?? "",
      EffectiveDate: deal?.data?.info?.dealDates?.contractDate
        ? new Date(deal?.data?.info?.dealDates?.contractDate)
        : new Date(),
      DRFC: insurance?.chosenRate?.DRFC,
      SalesRep: 0,
      SalesRepFirstName: deal.data?.user?.data?.info?.firstName ?? "",
      SalesRepLastName: deal.data?.user?.data?.info?.lastName ?? "",
      TotalCareCoverage:
        parseFloat(insurance?.chosenRate?.TotalCareCoverage || "0") > 0 ? "Y" : "N",
      CommercialVehicle: insurance?.chosenRate?.surcharges?.find(
        (s) => s.name === "CommercialVehicle" && s.checked
      )?.value
        ? "Y"
        : "N",
      IncreaseLiabilityLimit: insurance?.chosenRate?.surcharges?.find(
        (s) => s.name === "IncreaseLiabilityLimit" && s.checked
      )?.value
        ? "Y"
        : "N",
      TechPackage: insurance?.chosenRate?.surcharges?.find(
        (s) => s.name === "TechPackage" && s.checked
      )?.value
        ? "Y"
        : "N",
      EngineCC: insurance?.chosenRate?.EngineCC ?? 0,
      PowerSportsWarrantyRemaining: "",
      PowerSportsInServiceDate: "",
      LiftedVehicle: insurance?.LiftedVehicle ?? "N",
      DisplayCharges: insurance?.DisplayCharges ?? "Y"
    }
  };
};

export default function SubmitASCInsurance({ stateAccess, index, insurance, renderSet }: Props) {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const { enqueueSnackbar } = useSnackbar();
  const checkDataValidity = useContext(VerificationContext);
  const submitState = useSelector((state: RootState) => state.sumibtASCInsurance[requestId]);
  const [open, setOpen] = useState(false);
  const { edited: unsavedDeal } = useContext(formEditContext);
  const [alertState, setAlertState] = useState(false);
  const [state, setState] = useState<{
    isLoading: boolean;
    error: boolean;
    updateDeal: boolean;
  }>({
    isLoading: false,
    error: false,
    updateDeal: false
  });
  const deal: Deal = stateAccess.get([]);
  const dealId = deal?._id;
  const dealdata: DealData = deal?.data;
  const isAlreadySigned = insurance?.ID;

  const requiredFieldsInsurance = [
    {
      name: "Applicant first name",
      value: deal?.data?.applicant?.data?.info?.firstName
    },
    {
      name: "Applicant last name",
      value: deal?.data?.applicant?.data?.info?.lastName
    },
    {
      name: "Applicant address",
      value: deal?.data?.applicant?.data?.info?.currentAddress
    },
    {
      name: "Applicant address number",
      value: deal?.data?.applicant?.data?.info?.currentAddressNumber
    },
    {
      name: "Applicant city",
      value: deal?.data?.applicant?.data?.info?.currentCity
    },
    {
      name: "Applicant phone",
      value: deal?.data?.applicant?.data?.info?.mobilePhone
    },
    {
      name: "Applicant zipcode",
      value: deal?.data?.applicant?.data?.info?.currentZipCode
    },
    {
      name: "Applicant state",
      value: deal?.data?.applicant?.data?.info?.currentState
    },
    {
      name: "Lender ",
      value: deal?.data?.lender?.data?.info?.name
    },
    {
      name: "Deal signed at ",
      value: deal?.data?.info?.dealDates?.contractDate?.toString()
    },
    {
      name: "Vehicle make",
      value: deal?.data?.info?.vehicle?.model
    },
    {
      name: "Vehicle year",
      value: deal?.data?.info?.vehicle?.year
    },

    {
      name: "Vehicle model",
      value: deal?.data?.info?.vehicle?.make
    },
    {
      name: "Vehicle odometer",
      value: deal?.data?.info?.vehicle?.odometer
    },
    {
      name: "Loan price",
      value: dealdata?.info?.payment?.dealTotal
    },
    {
      name: "F&I manager",
      value: dealdata?.user?.data?.info?.firstName
    }
  ];
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (deal?.data?.info?.aftermarketOptions?.insurances) {
      const insurance = deal?.data?.info?.aftermarketOptions?.insurances[index];
      const ASCInsurance = insurance.type === "ASC" ? insurance : null;
      if (ASCInsurance) {
        checkDataValidity(requiredFieldsInsurance, () =>
          dispatch(submitASC(dataToSubmit(deal, ASCInsurance, requestId)))
        );
      } else {
        enqueueSnackbar(`Unable to submit data to ASC API`, {
          variant: "error"
        });
      }
    } else {
      enqueueSnackbar(`Unable to submit data to ASC API`, {
        variant: "error"
      });
    }
  };
  useEffect(() => {
    if (submitState !== undefined && submitState.status !== undefined) {
      switch (submitState.status) {
        case "error":
          setState({ ...state, isLoading: false, error: true });
          dispatch({
            type: sumbitASCActions.none.type,
            payload: { requestId }
          });
          break;
        case "waiting":
          setState({ ...state, isLoading: true, error: false });
          dispatch({
            type: sumbitASCActions.none.type,
            payload: { requestId }
          });
          break;
        case "success":
          const response = submitState?.data?.message?.data?.ProcessTransactionResult?.Agreement;
          stateAccess.set<Deal>(["data", "info", "aftermarketOptions", "insurances", index], {
            ...insurance,
            ID: response?.ID,
            AgreementNumber: response?.AgreementNumber,
            PDF: response?.PDF
          });
          enqueueSnackbar(`Successfully submited an insurance.`, {
            variant: "success"
          });
          setState({ ...state, isLoading: true, error: false, updateDeal: true });
          handleClose();
          dispatch({
            type: sumbitASCActions.none.type,
            payload: { requestId }
          });
      }
    }
  }, [
    dispatch,
    requestId,
    state,
    submitState,
    enqueueSnackbar,
    index,
    insurance,
    stateAccess,
    deal,
    dealId
  ]);

  const handleSubmitDialogOpen = () => {
    if (unsavedDeal) {
      setAlertState(true);
      return;
    }
    setOpen(true);
  };
  useEffect(() => {
    if (state.updateDeal) {
      setState({
        ...state,
        updateDeal: false
      });
      dispatch(editDeal({ ...stateAccess.get([]), requestId: uuidv4() }));
    }
  }, [state, stateAccess, dispatch]);
  return (
    <>
      <Dialog
        id="confirm-delete-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ASC Warranty
          <CloseDialogButton closeFunction={handleClose} />
        </DialogTitle>
        <DialogContent>
          <form id="asc-form" onSubmit={handleSubmit}>
            {generateForm(additionaFieldsStruct(index), stateAccess, [], stateAccess, renderSet)}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary" id="cancel">
            Cancel
          </Button>
          <Button
            form="asc-form"
            type="submit"
            variant="contained"
            color="secondary"
            autoFocus
            id="confirm-asc-submit"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {isAlreadySigned ? (
          <IconButton
            color="primary"
            aria-label="download"
            onClick={() => window.open(insurance?.PDF)}
          >
            <CloudDownloadIcon style={{ color: "#254E70" }} />
          </IconButton>
        ) : (
          <IconButton
            onClick={handleSubmitDialogOpen}
            color="primary"
            aria-label="search"
            id={`edit-insurance-${insurance.chosenRate.TermID}`}
          >
            <BorderColorIcon style={{ color: "#254E70" }} />
            {state.isLoading && (
              <CircularProgress style={{ position: "absolute", display: "block" }} />
            )}
          </IconButton>
        )}
      </div>
      <AlertDialog
        open={alertState}
        closeFunc={setAlertState}
        content={"Please save the deal before signing contract."}
      />
    </>
  );
}
