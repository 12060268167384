import { dealLabels } from "components/Deals/model";
import React from "react";
import { Change } from "./types";

const defaultPathPart = (part: string) => {
  return part !== "data" && part !== "info"
    ? part.charAt(0).toUpperCase() + part.slice(1)
    : undefined;
};

const printPath = (path: string[]): (string | undefined)[] => {
  if (path.length === 0) {
    return [];
  } else {
    return [
      ...printPath(path.slice(0, path.length - 1)),
      dealLabels[path.join(".")] ?? defaultPathPart(path[path.length - 1])
    ];
  }
};

const printValue = (value: any) => {
  if (typeof value === "object") {
    return JSON.stringify(value);
  }
  return value;
};

export const DisplayChange = (props: { change: Change }) => {
  const span = (change: Change) => {
    switch (change.operation) {
      case "add":
        return [
          "",
          <span key={change.value[0]} style={{ color: "green" }}>
            {printValue(change.value[0])}
          </span>
        ];
      case "delete":
        return [
          <span key={change.value[0]} style={{ color: "red" }}>
            {printValue(change.value[0])}
          </span>,
          ""
        ];
      case "update":
        return [
          <span key={change.value[0]} style={{ color: "red" }}>
            {" "}
            {printValue(change.value[0])}
          </span>,
          <span key={change.value[0]} style={{ color: "green" }}>
            {" "}
            {printValue(props.change.value[1])}{" "}
          </span>
        ];
    }
  };

  return [
    printPath(props.change.path)
      .filter((x) => x)
      .join(" > "),
    ...span(props.change)
  ];
};
