import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { CollateralTypes } from "components/Deals/types";
import { Lender, OperatesInStateStatus } from "components/Lenders/types";
import { auth } from "../../firebase/firebase";
import States from "../../us-states";

export type LenderCount = {
  name: string;
  total: number;
  fundedTotal: number;
  approvedTotal: number;
  declinedTotal: number;
  conditionallyApprovedTotal: number;
  otherTotal: number;
  totalFundedAmount: number;

  collateralTypes?: { [key in CollateralTypes]: boolean };
  status: OperatesInStateStatus;
};

type LenderCreditStatisticsRequest = {
  filter?: "active" | "inactive";
  states: string[];
  creditScoreRangeId?: string;
  from?: Date;
  to?: Date;
};

type LenderCreditStatisticsDealRequest = {
  collateralType?: CollateralTypes;
  state?: string;
  county?: string;
  creditScore?: number;
  from?: Date;
  to?: Date;
};

export type LendersAndApprovals = { lenders: LenderCount[]; totalLendersCount: number };

export type CreditScores = {
  [key: string]: LendersAndApprovals;
};

export type LenderCreditStatisticsCollateralType = { [key in CollateralTypes]: CreditScores };
export type LenderCreditStatisticsResponse = {
  [key in keyof typeof States]: LenderCreditStatisticsCollateralType;
};
export type LenderCreditStatisticsDealResponse = {
  lenders: (Lender & { statistics: LenderCount })[];
};

export const getLenderCreditStatistics = (
  request: LenderCreditStatisticsRequest
): (() => Promise<LenderCreditStatisticsResponse>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/lender_credit_statistics`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`
        },
        withCredentials: true
      }
    );

    return response.data.result;
  };
};

export const getLenderCreditStatisticsDeal = (
  request: LenderCreditStatisticsDealRequest
): (() => Promise<LenderCreditStatisticsDealResponse>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/lender_credit_statistics_deal`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`
        },
        withCredentials: true
      }
    );

    return response.data.result;
  };
};

const useLenderCreditStatistics = (request: LenderCreditStatisticsRequest) => {
  const queryKey = [
    `lender_credit_statistics-${request.states.length}-${request.from}-${request.to}-${request.creditScoreRangeId}-${request.filter}`
  ];

  const { data, isLoading, isSuccess, error, isFetching } = useQuery({
    queryKey: queryKey,
    onSuccess: (data) => {},
    queryFn: getLenderCreditStatistics(request),
    enabled: request.states.length && request.creditScoreRangeId ? true : false
  });

  return {
    data,
    isSuccess,
    isLoading,
    isFetching,
    error
  };
};

export const useLenderCreditStatisticsInDeal = (request: LenderCreditStatisticsDealRequest) => {
  const queryKey = [
    `lender_credit_statistics-${request.state}-${request.from}-${request.to}-${request.creditScore}-${request.collateralType}-${request.county}`
  ];

  const { data, isLoading, isSuccess, error, isFetching } = useQuery({
    queryKey: queryKey,
    onSuccess: (data) => {},
    queryFn: getLenderCreditStatisticsDeal(request),
    enabled: request.state && request.collateralType ? true : false
  });

  return {
    data,
    isSuccess,
    isLoading,
    isFetching,
    error
  };
};
export default useLenderCreditStatistics;
