import {
  createSocketMessageSlice,
  Identifiable
} from "../../Middlewares/socketMessageSliceCreator";
interface Request {
  EX1DealerID: string;
  Contract: { EX1ProviderID: string; ContractNumber: string };
}
interface ContractVoidRequest {
  requestId: string;
  data: {
    params: {
      EX1ContractVoidRequest: Request;
    };
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<ContractVoidRequest, any>(
  "ContractVoid",
  {
    request: "express_ex1_contract_void"
  }
);

export const contractVoid = (
  request: { EX1DealerID: string; ContractNumber: string; EX1ProviderID: string } & Identifiable
) => {
  const { requestId, EX1DealerID, ContractNumber, EX1ProviderID } = request;

  return socketAction({
    requestId,
    data: {
      params: {
        EX1ContractVoidRequest: {
          EX1DealerID,
          Contract: {
            EX1ProviderID,
            ContractNumber
          }
        }
      }
    }
  });
};

export const contractVoidAction = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
