import Grid from "@material-ui/core/Grid";
import React from "react";
import { createOptionsForSelect } from "utils/models/fields";
import { editRenderSet } from "utils/models/formRenderers";
import { FilterProps, filterStateAccess } from "./types";

export default function MultiSelectFilter<T extends unknown>({
  setFilter,
  path,
  filter,
  label,
  filterType,
  valuesForSelect,
  optionLabelForSelect,
  valueToString
}: FilterProps<T>) {
  const stateAccess = filterStateAccess(filter, setFilter, (values: any) => {
    if (values && values?.length > 0) {
      return {
        [path.join(".")]: {
          $in: values.map((value: any) => value)
        },
        ...(filterType === "aggregatedColumnFilter" ? { filterType } : {})
      };
    } else {
      setFilter(undefined);
    }
  });
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {editRenderSet(false).multiSelectInputRenderer(
          {
            formComponent: "multiselect-field",
            name: label?.toLowerCase() ?? "status-multi-filter",
            label: label ?? "Status",
            required: false,
            path: [] as [],
            options: createOptionsForSelect({
              possibleValues: () => valuesForSelect ?? [],
              getOptionLabel: (el) => (optionLabelForSelect ? optionLabelForSelect(el) : el),
              getSelectedOption: (x, y) => x === y
            }),
            valueToString: (x) => (valueToString ? valueToString(x) : x ?? "")
          },
          stateAccess,
          [],
          stateAccess,
          editRenderSet(false)
        )}
      </Grid>
    </Grid>
  );
}
