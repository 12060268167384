import React from "react";
import { LenderStatusHistory, OperatesInStateStatus } from "./types";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Paper,
  Theme,
  Tooltip,
  Typography,
  withStyles
} from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import formatDate from "utils/formatDate";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import { capitalize } from "utils/functions";

function compareData(oldData: any, newData: any) {
  function deepCompare(oldObj: any, newObj: any, statesLevel = false) {
    const result: any = {
      added: {},
      changed: {},
      removed: {}
    };

    for (const key in newObj) {
      if (!(key in oldObj) && statesLevel) {
        result.added[key] = newObj[key];
      } else if (typeof newObj[key] === "object" && newObj[key] !== null) {
        if (Array.isArray(newObj[key])) {
          if (JSON.stringify(newObj[key]) !== JSON.stringify(oldObj[key] ?? [])) {
            result.changed[key] = {
              old: oldObj[key] ?? "No data",
              new: newObj[key]
            };
          }
        } else {
          const nestedResult = deepCompare(oldObj[key], newObj[key], key === "states");

          if (Object.keys(nestedResult.added).length) {
            result.added[key] = nestedResult.added;
          }
          if (Object.keys(nestedResult.changed).length) {
            result.changed[key] = nestedResult.changed;
          }
          if (Object.keys(nestedResult.removed).length) {
            result.removed[key] = nestedResult.removed;
          }
        }
      } else if (newObj[key] !== oldObj[key]) {
        result.changed[key] = { old: oldObj[key] ?? "No data", new: newObj[key] };
      }
    }

    for (const key in oldObj) {
      if (!(key in newObj) && statesLevel && !result.changed[key]) {
        result.removed[key] = oldObj[key];
      }
    }

    return result;
  }

  return deepCompare(oldData, newData);
}

function transformData(data: any) {
  const newStates: any = {};

  data.states.forEach((stateObj: any) => {
    const { state, ...rest } = stateObj;
    newStates[state] = rest;
  });
  return {
    ...data,
    states: newStates
  };
}
const RED = "rgb(244, 67, 54)";
const GREEN = "rgb(76, 175, 80)";
const YELLOW = "#eed202";
const LIGHT_BLUE = "#3bb2d9";
const ChipTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    maxHeight: "300px",
    overflow: "auto"
  }
}))(Tooltip);
export const lenderStatusChipTooltip = (
  status: OperatesInStateStatus,
  message: string,
  onClick: () => void,
  chipStyle?: React.CSSProperties
): JSX.Element => {
  return (
    <ChipTooltip onClick={onClick} interactive open={message ? undefined : false} title={message}>
      <Chip size="small" label={capitalize(status)} style={chipStyle} />
    </ChipTooltip>
  );
};

type LenderStatusHistoryDialogProps = {
  statusHistory: LenderStatusHistory[];
  handleClose: () => void;
};
const getColorByStatus = (status: OperatesInStateStatus) => {
  switch (status) {
    case OperatesInStateStatus.ACTIVE:
      return GREEN;
    case OperatesInStateStatus.INACTIVE:
      return RED;
    default:
      return YELLOW;
  }
};
export default ({ statusHistory, handleClose }: LenderStatusHistoryDialogProps) => {
  const sortedStatusHistory = [...statusHistory]?.sort(
    (a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime()
  );
  const [statusHistoryDetailed, setStatusHistoryDetailed] = React.useState<
    LenderStatusHistory | undefined
  >();
  const [detailedActiveIndex, setDetailedActiveIndex] = React.useState<number | undefined>();
  return (
    <Dialog fullWidth onClose={handleClose} maxWidth="md" open={true}>
      <CloseDialogButton closeFunction={handleClose} />
      <DialogTitle>Status history</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={statusHistoryDetailed ? 6 : 12}>
            <Timeline>
              {sortedStatusHistory?.map((history, index) => {
                return (
                  <TimelineItem key={index}>
                    <TimelineOppositeContent>
                      <Typography color="textSecondary">{history.userEmail}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {formatDate(history.date, "short", true)}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        style={{
                          background: detailedActiveIndex === index ? LIGHT_BLUE : undefined
                        }}
                      />
                      {statusHistory?.length !== index + 1 ? <TimelineConnector /> : null}
                    </TimelineSeparator>
                    <TimelineContent
                      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                    >
                      <Typography>
                        {lenderStatusChipTooltip(
                          history.status,
                          history?.comment ?? "",
                          () => {
                            setStatusHistoryDetailed(history);
                            setDetailedActiveIndex(index);
                          },
                          {
                            background: getColorByStatus(history.status),
                            color: "#fff"
                          }
                        )}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          </Grid>
          {statusHistoryDetailed ? (
            <Grid item xs={6}>
              <StatusHistoryDetailed
                statusHistory={statusHistoryDetailed}
                handleClose={() => {
                  setStatusHistoryDetailed(undefined);
                  setDetailedActiveIndex(undefined);
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setStatusHistoryDetailed(undefined);
            setDetailedActiveIndex(undefined);
            handleClose();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
type StatusHistoryDetailedProps = {
  statusHistory: LenderStatusHistory;
  handleClose: () => void;
};
const StatusHistoryDetailed = ({ statusHistory }: StatusHistoryDetailedProps) => {
  const dataSeparatedInSections = compareData(
    transformData(statusHistory.oldData ?? {}),
    transformData(statusHistory.newData)
  );
  console.log(dataSeparatedInSections);
  const styles = {
    differencesDisplay: {
      padding: "20px",
      backgroundColor: "#f9f9f9",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      margin: "0 auto"
    },
    sectionHeader: {
      fontSize: "1.2em",
      marginBottom: "10px",
      color: "#333",
      fontWeight: "bold",
      paddingBottom: "8px",
      borderBottom: "2px solid #e2e2e2"
    },
    differenceList: {
      listStyleType: "none",
      padding: 0,
      margin: 0
    },
    differenceItem: {
      padding: "8px 12px",
      marginBottom: "5px",
      backgroundColor: "#fff",
      borderRadius: "4px",
      transition: "background-color 0.3s ease"
    },
    addedItem: {
      backgroundColor: "#e6ffed" // Light green background for added items
    },
    changedItem: {
      backgroundColor: "#fff3e0" // Light orange background for changed items
    },
    removedItem: {
      backgroundColor: "#ffebee" // Light red background for removed items
    },
    itemKey: {
      color: "#333"
    },
    oldValue: {
      color: "#757575",
      textDecoration: "line-through",
      marginRight: "5px"
    },
    newValue: {
      color: "#4caf50",
      fontWeight: "bold"
    },
    changedValues: {
      display: "flex",
      alignItems: "center"
    },
    valueText: {
      fontSize: "14px",
      color: "#555"
    }
  };
  const renderList = (data: any, title = "", section = "") => {
    return (
      <List style={styles.differenceList}>
        {title ? <Typography style={styles.sectionHeader}>{title}</Typography> : null}
        {Object.entries(data).map(([key, value]: [any, any], index: number) => {
          const isObject = typeof value === "object" && !Array.isArray(value);
          const sectionStyle =
            section === "added"
              ? styles.addedItem
              : section === "changed"
              ? styles.changedItem
              : section === "removed"
              ? styles.removedItem
              : {};

          if (isObject) {
            if (section === "changed" && value.old !== undefined && value.new !== undefined) {
              // Special handling for changed items: show old and new value side by side
              return (
                <ListItem
                  key={`${key}-${index}`}
                  style={{
                    ...styles.differenceItem,
                    ...styles.changedItem
                  }}
                >
                  <span style={styles.itemKey}>
                    {capitalize(key === "collateralTypes" ? "Collateral types" : key)}:
                  </span>{" "}
                  <span style={styles.changedValues}>
                    <span style={styles.oldValue}>
                      {typeof value.old === "boolean"
                        ? value.old
                          ? "Yes"
                          : "No"
                        : Array.isArray(value.old)
                        ? value.old.map((x: any) => capitalize(x)).join(", ")
                        : capitalize(value.old)}
                    </span>{" "}
                    →
                    <span style={styles.newValue}>
                      {typeof value.new === "boolean"
                        ? value.new
                          ? "Yes"
                          : "No"
                        : Array.isArray(value.new)
                        ? value.new.map((x: any) => capitalize(x)).join(", ")
                        : capitalize(value.new)}
                    </span>
                  </span>
                </ListItem>
              );
            } else {
              return (
                <ListItem
                  key={`${key}-${index}`}
                  style={{
                    ...styles.differenceItem,
                    ...sectionStyle
                  }}
                >
                  {renderList(
                    value,
                    capitalize(key === "collateralTypes" ? "Collateral types" : key),
                    section
                  )}
                </ListItem>
              );
            }
          } else if (Array.isArray(value)) {
            return (
              <ListItem
                key={`${key}-${index}`}
                style={{
                  ...styles.differenceItem,
                  ...sectionStyle
                }}
              >
                <span style={styles.itemKey}>{capitalize(key)}:</span>{" "}
                <span style={styles.valueText}>{value.join(", ")}</span>
              </ListItem>
            );
          } else {
            return (
              <ListItem
                key={`${key}-${index}`}
                style={{
                  ...styles.differenceItem,
                  ...sectionStyle
                }}
              >
                <span style={styles.itemKey}>{capitalize(key)}:</span>{" "}
                <span style={styles.valueText}>
                  {typeof value === "boolean" ? (value ? "Yes" : "No") : capitalize(value)}
                </span>
              </ListItem>
            );
          }
        })}
      </List>
    );
  };

  return (
    <Grid container style={styles.differencesDisplay}>
      <Grid item xs={12} style={{ maxHeight: "400px", overflowY: "auto" }}>
        {Object.keys(dataSeparatedInSections).map((section) => (
          <div key={section}>
            {renderList(dataSeparatedInSections[section], capitalize(section), section)}
          </div>
        ))}
      </Grid>
    </Grid>
  );
};
