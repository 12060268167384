import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

type AddOtherVendor = { data: FormState };

interface AddOtherVendorResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddOtherVendor,
  AddOtherVendorResponse
>("AddOtherVendor", {
  request: "new_other_vendor"
});

export const addOtherVendorActions = socketMessageSlice.actions;
export const addOtherVendor = socketAction;
export default socketMessageSlice.reducer;
