import React from "react";
import LenderDecisions from "../LenderDecisions/LenderDecisions";

interface Props {
  dealId: string;
}

export default ({ dealId }: Props) => {
  return <LenderDecisions dealId={dealId} />;
};
