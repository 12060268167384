import { ClickAwayListener, styled, Typography } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { RootState } from "app/rootReducer";
import { TabContext } from "components/Layout/LayoutWrapper";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityTypes } from "utils/types";
import { v4 as uuidv4 } from "uuid";
import { editNotification } from "./editNotificationSlice";
import { getNotificationList } from "./listNotificationSlice";
import { deleteAllNotifications } from "./removeAllNotificationsSlice";
import { deleteNotification } from "./removeNotificationSlice";
import { Notification } from "./types";
import formatDate from "utils/formatDate";

const StyledBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    color: "black",
    backgroundColor: "#eed202"
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    nested: {
      padding: 0,
      paddingBottom: 1,
      display: "flex",
      gap: 10,
      margin: 0
    },
    list: {
      minWidth: "500px",
      maxWidth: "600px",
      zIndex: 1000,
      position: "absolute",
      top: "50px",
      right: 80
    },

    text: {
      wordWrap: "break-word",
      paddingRight: "45px"
    },
    subheader: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      position: "inherit"
    },
    listAction: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    closeButton: {
      color: "#fff",
      position: "absolute",
      right: "9px",
      top: "-8px"
    }
  })
);

export default function Notifications() {
  const sliceId = "table";
  const classes = useStyles();
  const dispatch = useDispatch();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const createOrFocusTab = useContext(TabContext);
  const [requestId] = useState(uuidv4());
  const { enqueueSnackbar } = useSnackbar();
  const notifications = useSelector((state: RootState) => state.listNotificationSlice[sliceId]);
  const deleteNotifications = useSelector(
    (state: RootState) => state.removeNotificationSlice[requestId]
  );
  const { user } = useSelector((state: RootState) => state.authSlice);
  const [open, setOpen] = useState(false);
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDeleteNotification = (id: string) => {
    dispatch(deleteNotification({ requestId, _id: id }));
  };
  const handleDeleteAllNotifications = () => {
    dispatch(deleteAllNotifications({ requestId, userEmail: user?.email ?? "" }));
  };
  useEffect(() => {
    if (deleteNotifications?.status === "success") {
      dispatch(
        getNotificationList(sliceId, {
          withDeleted: false,
          options: {
            sort: { "data.info.level": "asc", createdAt: "desc" },
            pagination: true,
            offset: notifications?.entities?.length,
            limit: 1
          },
          query: { "data.info.userEmail": user?.email }
        })
      );
    } else if (deleteNotifications?.status === "error") {
      enqueueSnackbar(deleteNotifications.message, {
        variant: "error"
      });
    }
  }, [enqueueSnackbar, deleteNotifications, dispatch, user]);

  useEffect(() => {
    dispatch(
      getNotificationList(sliceId, {
        withDeleted: false,
        options: {
          sort: { "data.info.level": "asc", createdAt: "desc" },
          pagination: true,
          limit: 10
        },

        query: { "data.info.userEmail": user?.email }
      })
    );
  }, [dispatch, user]);

  const handleNotificationClick = (notification: Notification) => {
    dispatch(
      editNotification({
        requestId,
        ...{
          ...notification,
          data: {
            ...notification.data,
            info: {
              ...notification.data.info,
              seenAt: notification.data.info.seenAt
                ? notification.data.info.seenAt
                : new Date().toISOString()
            }
          }
        }
      })
    );

    const tabType =
      notification.data.info.type === "one_span" ||
      notification.data.info.type === "mobile_application" ||
      notification.data.info.type === "dealership_application" ||
      notification.data.info.type === "deal_co_applicant" ||
      notification.data.info.type === "note"
        ? "deal"
        : notification.data.info.type;

    return createOrFocusTab({
      label: "Show page",
      index: "showPage",
      isForSidebar: false,
      isForQuickAccess: false,
      isPersistent: false,
      props: {
        _id: notification.data.info.referenceId,
        type: tabType
      }
    });
  };

  const handleShowMoreNotifactions = () => {
    dispatch(
      getNotificationList(sliceId, {
        withDeleted: false,
        options: {
          sort: { "data.info.level": "asc", createdAt: "desc" },
          pagination: true,
          offset: notifications?.entities?.length,
          limit: 10
        },
        query: { "data.info.userEmail": user?.email }
      })
    );
  };

  const renderNotification = (notification: Notification) => {
    switch (notification.data.info.type) {
      case EntityTypes.external_credit_application:
      case "mobile_application":
      case "dealership_application":
        return (
          <React.Fragment>
            <Typography component="span" variant="body2" color="textPrimary">
              {notification?.data?.info?.body?.customer +
                " " +
                notification?.data?.info?.body?.dealership}
            </Typography>
          </React.Fragment>
        );
      case EntityTypes.contract:
        return (
          <React.Fragment>
            <Typography component="span" variant="body2" color="textPrimary">
              {notification?.data?.info?.body?.contract +
                " " +
                [notification?.data?.info?.body?.dealership, notification?.data?.info?.body?.lender]
                  .filter((x) => x)
                  .join("—")}
            </Typography>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <Typography component="span" variant="body2" color="textPrimary">
              {notification?.data?.info?.body?.description ?? ""}
            </Typography>
          </React.Fragment>
        );
    }
  };

  return (
    <>
      <IconButton ref={anchorRef} onClick={handleClick} color="inherit" id="notifications-button">
        {notifications?.entities?.find((notification) => notification.data.info.level === 0) ? (
          <Badge color={"secondary"} badgeContent={notifications?.total}>
            <NotificationsIcon />
          </Badge>
        ) : (
          <StyledBadge badgeContent={notifications?.total}>
            <NotificationsIcon />
          </StyledBadge>
        )}
      </IconButton>
      <ClickAwayListener onClickAway={handleClose}>
        <Collapse className={classes.list} in={open} timeout="auto" unmountOnExit>
          <Paper className={classes.root} elevation={3}>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  className={classes.subheader}
                  component="div"
                  id="nested-list-subheader"
                >
                  Notifications
                  <IconButton
                    onClick={() => setOpen(false)}
                    className={classes.closeButton}
                    edge="end"
                    aria-label="delete"
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                  <Divider />
                </ListSubheader>
              }
            >
              <List component="div" disablePadding>
                {(notifications?.entities?.length || 0) > 0 ? (
                  <>
                    <div style={{ overflow: "auto", maxHeight: 350 }}>
                      {notifications?.entities
                        ?.filter((x) => !x.deleted)
                        // ?.sort((x, y) => x.data.info.level - y.data.info.level)
                        ?.map((el: Notification) => (
                          <ListItem
                            button
                            className={classes.nested}
                            id={`open-notification-${el._id}`}
                            key={el._id}
                          >
                            <ListItemIcon>
                              {el?.data?.info?.seenAt ? (
                                <DraftsOutlinedIcon
                                  style={{
                                    background: el.data.info.level === 0 ? "#3fa863" : "#449fff",
                                    padding: "10px",
                                    color: "#fff"
                                  }}
                                  fontSize="large"
                                />
                              ) : (
                                <EmailOutlinedIcon
                                  style={{
                                    background: el.data.info.level === 0 ? "#3fa863" : "#449fff",
                                    padding: "10px",
                                    color: "#fff"
                                  }}
                                  fontSize="large"
                                />
                              )}
                            </ListItemIcon>

                            <ListItemText
                              onClick={() => handleNotificationClick(el)}
                              className={classes.text}
                              primary={el?.data?.info?.title}
                              secondary={renderNotification(el)}
                            ></ListItemText>
                            <div
                              style={{
                                position: "absolute",
                                top: "5px",
                                right: "45px",
                                fontSize: "10px",
                                color: "grey"
                              }}
                            >
                              {formatDate(el?.createdAt, "short", true)}
                            </div>
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={() => handleDeleteNotification(el._id)}
                                edge="end"
                                aria-label="delete"
                                id={`delete-${el._id}`}
                              >
                                <ClearIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                            <ListItemSecondaryAction style={{ top: "0%", marginTop: "10px" }}>
                              {el.data.info?.seenAt ? (
                                <Typography style={{ fontSize: "10px", color: "grey" }}>
                                  Seen at: {new Date(el.data.info?.seenAt)?.toLocaleString()}
                                </Typography>
                              ) : null}
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                    </div>
                    <Divider />
                    <ListItem
                      id="show-older-notifications"
                      button
                      onClick={handleShowMoreNotifactions}
                    >
                      <ListItemText className={classes.listAction}>Show older</ListItemText>
                    </ListItem>
                    <ListItem
                      id="clear-all-notifications-button"
                      onClick={handleDeleteAllNotifications}
                      button
                    >
                      <ListItemText className={classes.listAction}>Clear all</ListItemText>
                    </ListItem>
                  </>
                ) : (
                  <ListItem>
                    <ListItemText className={classes.listAction}>No notifications</ListItemText>
                  </ListItem>
                )}
              </List>
            </List>
          </Paper>
        </Collapse>
      </ClickAwayListener>
    </>
  );
}
