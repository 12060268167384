import { createListSlice } from "../Middlewares/listSliceCreator";
import { Validation } from "./types";

const { ListSlice, getList } = createListSlice<Validation>({
  name: "ValidationsList",
  request_topic: "all_document_validations",
  reducers: {}
});

export default ListSlice.reducer;
export const getValidationsList = getList;
export const listValidationActions = ListSlice.actions;
