import React from "react";
import { DealershipTicket } from "./types";
import { Grid, Typography } from "@material-ui/core";
type Props = {
  dealershipTicket: DealershipTicket;
};

export default ({ dealershipTicket }: Props) => {
  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Typography>{dealershipTicket.data.info.description}</Typography>
      </Grid>
      {dealershipTicket?.data?.info?.url ? (
        <Grid item xs={12} container>
          <Grid item xs={12}>
            <Typography style={{ fontSize: "19px", color: "#254e6e", fontWeight: "bold" }}>
              Image
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <a href={dealershipTicket?.data?.info?.url} target="_blank" rel="noreferrer">
              Click here to open the image of the issue in an external window.
            </a>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
