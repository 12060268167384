import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverAdditionalRequiredDocumentRequest {
  _id: string;
}

export interface RecoverAdditionalRequiredDocumentResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverAdditionalRequiredDocumentRequest,
  RecoverAdditionalRequiredDocumentResponse
>("RecoverAdditionalRequiredDocument", {
  request: "recover_additional_required_document"
});

export const recoverAdditionalRequiredDocumentActions = socketMessageSlice.actions;
export const recoverAdditionalRequiredDocument = socketAction;
export default socketMessageSlice.reducer;
