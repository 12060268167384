import { createSocketMessageSlice } from "components/Middlewares/socketMessageSliceCreator";

const actions = ["lock", "unlock"] as const;
export interface LockEntityRequest {
  requestId: string;
  data: {
    info: {
      entityId: string;
      entityName: string;
      action: typeof actions[number];
    };
  };
}

export interface UnlockEntityResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  LockEntityRequest,
  UnlockEntityResponse
>("Lock entity", {
  request: "update_lock"
});

export const lockEntityActions = socketMessageSlice.actions;
export const lockEntity = socketAction;
export default socketMessageSlice.reducer;
