import { createSlice } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "app/store";

const drawerSlice = createSlice({
  name: "drawer",
  initialState: { isOpen: false },
  reducers: {
    openDrawer(state) {
      return { ...state, isOpen: true };
    },
    closeDrawer(state) {
      return { ...state, isOpen: false };
    }
  }
});

export const openDrawer = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(drawerSlice.actions.openDrawer());
};

export const closeDrawer = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(drawerSlice.actions.closeDrawer());
};

export default drawerSlice.reducer;
