import { RootState } from "app/rootReducer";
import { Dealership } from "components/Dealerships/types";
import EntitySearch from "components/Deals/EntitySearch";
import { Deal } from "components/Deals/types";
import { ListState } from "components/Middlewares/listSliceCreator";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { entitySlice } from "utils/entitySlice";
import { Path } from "utils/models/fields";
import { getByPath, RenderSet, StateAccess } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import { getUserList, removeUserList } from "./listUserSlice";
import { User } from "./types";

interface Props<T> {
  stateAccess: StateAccess;
  path: Path<T>;
  renderSet: RenderSet;
  name: string;
  label: string;
  type?: string;
  style?: object;
  outerQuery?: object;
  lockEntity?: () => void;
  required?: (stateAccess: StateAccess) => any;
}

export const formatName = (user: User) =>
  [user?.data?.info?.firstName, user?.data?.info?.lastName]
    .filter((name) => name !== undefined)
    .join(" ");
export default function UserPreview<T extends Deal | User | Dealership>({
  stateAccess,
  path,
  renderSet,
  name,
  label,
  type,
  lockEntity,
  required,
  style,
  outerQuery
}: Props<T>) {
  const dispatch = useDispatch();
  const [uniqueId] = useState(uuidv4());

  const listId = `list-autocomplete-${uniqueId}`;
  const userList = useSelector((state: RootState) => state.listUserSlice[listId]);
  const user = stateAccess.get<T>(path);

  const selectedUser = useSelector((state: RootState) => state.entitySlice["wfd_user"][user?._id]);
  useEffect(() => {
    if (user?._id !== selectedUser?._id) {
      dispatch({
        type: entitySlice.actions.add.type,
        payload: { type: "wfd_user", message: user }
      });
    }
  }, [user, selectedUser, dispatch]);

  const userStateAccess: StateAccess = {
    get: (path) => getByPath<User>(selectedUser, path as Path<User>),
    set: (_, value): any => {
      stateAccess.set<T>(path, value);
    }
  };

  const [query] = useState({
    ...(type ? { "data.info.userType": type } : {}),
    ...(outerQuery ?? {})
  });
  return (
    <EntitySearch
      lockEntity={lockEntity}
      renderSet={renderSet}
      stateAccess={userStateAccess}
      parentStateAccess={stateAccess}
      id={listId}
      name={name}
      getFunction={getUserList}
      removeFunction={removeUserList}
      path={[] as any}
      label={label}
      entityList={userList as ListState<T>}
      formatLabel={formatName}
      query={query}
      options={{ pagination: false, sort: { "data.info.firstName": "asc" } }}
      style={style}
      required={required}
    />
  );
}
