import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  StateAccess,
  RenderSet,
  generateForm,
  getByPath,
  setByPath
} from "utils/models/formGenerator";
import { FormComponent } from "utils/models/fields";
import { ListFunction } from "components/Table";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { ClickAwayListener } from "@material-ui/core";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { ListState } from "components/Middlewares/listSliceCreator";
import { EntityType, EntityData } from "utils/entitySlice";
import { TextField } from "@material-ui/core";
import { Applicant } from "components/Applicants/types";
import { Path } from "utils/models/fields";
import { Deal } from "./types";

import TextLoop from "components/Loader/TextLoop";
interface Props {
  renderSet: RenderSet;
  stateAccess: StateAccess;
  id: string;
  getFunction: ListFunction;
  removeFunction: ActionCreatorWithPayload<string, string>;
  path: Path<Deal>;
  entityName: EntityType;
  query: any;
  searchStruct: FormComponent<any>;
  formState: {};
  applicantList: ListState<any>;
  setFormState: React.Dispatch<any>;
  required: boolean;
}

export default function Search({
  renderSet,
  stateAccess,
  id,
  getFunction,
  entityName,
  removeFunction,
  query,
  searchStruct,
  applicantList,
  formState,
  path,
  required,
  setFormState
}: Props) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const [loader, setLoader] = useState(false);

  const handleClickAway = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setOpen(true);
  };

  const handleSelect = (applicant: EntityData<typeof entityName>) => {
    stateAccess.set<Deal>(path, applicant);
  };

  const internalStateAccess: StateAccess = {
    get: (path) => getByPath(formState as any, path),
    set: (path, value): any => {
      setFormState(setByPath(formState as any, path, value));
    }
  };

  useEffect(() => {
    if (query?.["data.info.lastFourSSNDigits"]?.$regex?.length === 7) {
      setLoader(true);
      dispatch(
        getFunction(id, {
          query
        })
      );
    } else dispatch(removeFunction(id));
  }, [dispatch, getFunction, removeFunction, id, query]);

  useEffect(() => {
    if (applicantList) setLoader(false);
  }, [applicantList]);
  return (
    <>
      <div>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box display="flex" onClick={handleClick}>
            <Box id="applicant-box" flexGrow={1} style={{ position: "relative" }}>
              {loader && (
                <TextLoop
                  text=""
                  style={{
                    position: "absolute",
                    background: "rgba(0 ,0 , 0 , 0.01)",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: "10px"
                  }}
                />
              )}
              <TextField
                value={stateAccess.get<Deal>([...path])?._id ?? ""}
                required={required}
                style={{ opacity: 0, position: "absolute", top: "16px", left: "15px" }}
                inputProps={{
                  onInvalid: (e: any) =>
                    e.target.setCustomValidity(
                      "Please use the fields to find a applicant or create one."
                    )
                }}
              />
              {generateForm(searchStruct, internalStateAccess, [], internalStateAccess, renderSet)}
            </Box>
          </Box>
        </ClickAwayListener>

        {applicantList && open && (
          <Paper id="long-menu" elevation={3} style={{ maxHeight: 40 * 4.5, overflow: "auto" }}>
            {applicantList?.entities?.map((entity: Applicant) => (
              <MenuItem key={entity._id} onClick={() => handleSelect(entity)}>
                {`${entity.data.info.firstName} ${entity.data.info.lastName}`}
              </MenuItem>
            ))}
          </Paper>
        )}
      </div>
    </>
  );
}
