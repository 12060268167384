import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import History from "@material-ui/icons/History";
import CloseDialogButton from "components/common/CloseDialogButton";
import React, { useState } from "react";
import { StateAccess } from "utils/models/formGenerator";
import StatusHistory from "./StatusHistory";
import { Deal } from "./types";
export default function StatusHistoryButton({ stateAccess }: { stateAccess: StateAccess }) {
  const deal: Deal = stateAccess.get([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        style={{ position: "absolute", right: 0, top: -5, zIndex: 1 }}
      >
        <History />
      </IconButton>
      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        <CloseDialogButton closeFunction={handleClose} />
        <DialogTitle id="customized-dialog-title">Status history</DialogTitle>
        <DialogContent>
          <StatusHistory stateAccess={stateAccess} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
