import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { Note, AddNoteRequest } from "./types";

interface AddNoteResponse {
  message: Note;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddNoteRequest,
  AddNoteResponse
>("AddNoteResponse", {
  request: "new_note"
});

export const addNoteActions = socketMessageSlice.actions;

export const addNote = socketAction;

export default socketMessageSlice.reducer;
