import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { RootState } from "app/rootReducer";
import { hideLoader, showLoader } from "components/Loader/loaderSlice";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { entityConfigs, EntityType } from "utils/entitySlice";
import { notifyWebhooks } from "utils/logging";

const resolveMessage = (action: string, type: string) => {
  console.log(type, action);

  if (action === "edit" && type === "sequence") return `Successfully created a contract number!`;
  return `Successfully ${action}${action.slice(-1) === "e" ? "d" : "ed"} a ${type.replace(
    /_/g,
    " "
  )}!`;
};

export default ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const addApplicantSlice = useSelector((state: RootState) => state.addApplicantSlice);
  const deleteApplicantSlice = useSelector((state: RootState) => state.deleteApplicantSlice);
  const editApplicantSlice = useSelector((state: RootState) => state.editApplicantSlice);
  const recoverApplicantSlice = useSelector((state: RootState) => state.recoverApplicantSlice);
  const addExternalCreditApplication = useSelector(
    (state: RootState) => state.addExternalCreditApplicationSlice
  );
  const deleteExternalCreditApplication = useSelector(
    (state: RootState) => state.deleteExternalCreditApplicationSlice
  );
  const editExternalCreditApplication = useSelector(
    (state: RootState) => state.editExternalCreditApplicationSlice
  );
  const recoverExternalCreditApplication = useSelector(
    (state: RootState) => state.recoverExternalCreditApplicationSlice
  );

  const addDealershipSlice = useSelector((state: RootState) => state.addDealershipSlice);
  const deleteDealershipSlice = useSelector((state: RootState) => state.deleteDealershipSlice);
  const editDealershipSlice = useSelector((state: RootState) => state.editDealershipSlice);
  const recoverDealershipSlice = useSelector((state: RootState) => state.recoverDealershipSlice);
  const addDealershipProgramSlice = useSelector(
    (state: RootState) => state.addDealershipProgramSlice
  );
  const deleteDealershipProgramSlice = useSelector(
    (state: RootState) => state.deleteDealershipProgramSlice
  );
  const editDealershipProgramSlice = useSelector(
    (state: RootState) => state.editDealershipProgramSlice
  );
  const recoverDealershipProgramSlice = useSelector(
    (state: RootState) => state.recoverDealershipProgramSlice
  );
  const addLenderSlice = useSelector((state: RootState) => state.addLenderSlice);
  const deleteLenderSlice = useSelector((state: RootState) => state.deleteLenderSlice);
  const editLenderSlice = useSelector((state: RootState) => state.editLenderSlice);
  const recoverLenderSlice = useSelector((state: RootState) => state.recoverLenderSlice);
  const addDealSlice = useSelector((state: RootState) => state.addDealSlice);
  const deleteDealSlice = useSelector((state: RootState) => state.deleteDealSlice);
  const editDealSlice = useSelector((state: RootState) => state.editDealSlice);
  const recoverDealSlice = useSelector((state: RootState) => state.recoverDealSlice);
  const addDmvSlice = useSelector((state: RootState) => state.addDmvSlice);
  const deleteDmvSlice = useSelector((state: RootState) => state.deleteDmvSlice);
  const editDmvSlice = useSelector((state: RootState) => state.editDmvSlice);
  const recoverDmvSlice = useSelector((state: RootState) => state.recoverDmvSlice);
  const deleteTitleIssueSlice = useSelector((state: RootState) => state.deleteTitleIssueSlice);
  const editTitleIssueSlice = useSelector((state: RootState) => state.editTitleIssueSlice);
  const recoverTitleIssueSlice = useSelector((state: RootState) => state.recoverTitleIssueSlice);
  const addRoleSlice = useSelector((state: RootState) => state.addRoleSlice);
  const deleteRoleSlice = useSelector((state: RootState) => state.deleteRoleSlice);
  const editRoleSlice = useSelector((state: RootState) => state.editRoleSlice);
  const recoverRoleSlice = useSelector((state: RootState) => state.recoverRoleSlice);
  const addUserSlice = useSelector((state: RootState) => state.addUserSlice);
  const deleteUserSlice = useSelector((state: RootState) => state.deleteUserSlice);
  const editUserSlice = useSelector((state: RootState) => state.editUserSlice);
  const recoverUserSlice = useSelector((state: RootState) => state.recoverUserSlice);

  const addDocumentTemplateSlice = useSelector(
    (state: RootState) => state.addDocumentTemplateSlice
  );
  const deleteDocumentTemplateSlice = useSelector(
    (state: RootState) => state.deleteDocumentTemplateSlice
  );
  const editDocumentTemplateSlice = useSelector(
    (state: RootState) => state.editDocumentTemplateSlice
  );
  const recoverDocumentTemplateSlice = useSelector(
    (state: RootState) => state.recoverDocumentTemplateSlice
  );

  const addValidationSlice = useSelector((state: RootState) => state.addValidationSlice);
  const deleteValidationSlice = useSelector((state: RootState) => state.deleteValidationSlice);
  const editValidationSlice = useSelector((state: RootState) => state.editValidationSlice);

  const addAppOneDealSlice = useSelector((state: RootState) => state.addAppOneDealSlice);
  const addDealerTrackDealSlice = useSelector((state: RootState) => state.addDealerTrackSlice);

  const addPayoffBankSlice = useSelector((state: RootState) => state.addPayoffBankSlice);
  const editPayoffBankSlice = useSelector((state: RootState) => state.editPayoffBankSlice);
  const deletePayoffBankSlice = useSelector((state: RootState) => state.deletePayoffBankSlice);
  const recoverPayoffBankSlice = useSelector((state: RootState) => state.recoverPayoffBankSlice);

  const addOtherVendorSlice = useSelector((state: RootState) => state.addOtherVendorSlice);
  const editOtherVendorSlice = useSelector((state: RootState) => state.editOtherVendorSlice);
  const deleteOtherVendorSlice = useSelector((state: RootState) => state.deleteOtherVendorSlice);
  const recoverOtherVendorSlice = useSelector((state: RootState) => state.recoverOtherVendorSlice);

  const addStateSlice = useSelector((state: RootState) => state.addStateSlice);
  const editStateSlice = useSelector((state: RootState) => state.editStateSlice);
  const deleteStateSlice = useSelector((state: RootState) => state.deleteStateSlice);
  const recoverStateSlice = useSelector((state: RootState) => state.recoverStateSlice);
  const addVehicleInsuranceCompanySlice = useSelector(
    (state: RootState) => state.addVehicleInsuranceCompanySlice
  );
  const editVehicleInsuranceCompanySlice = useSelector(
    (state: RootState) => state.editVehicleInsuranceCompanySlice
  );
  const deleteVehicleInsuranceCompanySlice = useSelector(
    (state: RootState) => state.deleteVehicleInsuranceCompanySlice
  );
  const recoverVehicleInsuranceCompanySlice = useSelector(
    (state: RootState) => state.recoverVehicleInsuranceCompanySlice
  );
  const addFileSlice = useSelector((state: RootState) => state.addFileSlice);
  const editFileSlice = useSelector((state: RootState) => state.editFileSlice);
  const deleteFileSlice = useSelector((state: RootState) => state.deleteFileSlice);
  const recoverFileSlice = useSelector((state: RootState) => state.recoverFileSlice);

  const addLenderTicketSLice = useSelector((state: RootState) => state.addLenderTicketSlice);
  const editLenderTicketSlice = useSelector((state: RootState) => state.editLenderTIcketSlice);
  const deleteLenderTicketSlice = useSelector((state: RootState) => state.deleteLenderTicketSlice);
  const recoverLenderTicketSlice = useSelector(
    (state: RootState) => state.recoverLenderTicketSlice
  );

  const addFundingDocumentSlice = useSelector((state: RootState) => state.addFundingDocumentSlice);
  const editFundingDocumentSlice = useSelector(
    (state: RootState) => state.editFundingDocumentSlice
  );
  const deleteFundingDocumentSlice = useSelector(
    (state: RootState) => state.deleteFundingDocumentSlice
  );
  const recoverFundingDocumentSlice = useSelector(
    (state: RootState) => state.recoverFundingDocumentSlice
  );

  const addCustomReportSlice = useSelector((state: RootState) => state.addCustomReportSlice);
  const editCustomReportSlice = useSelector((state: RootState) => state.editCustomReportSlice);
  const deleteCustomReportSlice = useSelector((state: RootState) => state.deleteCustomReportSlice);
  const recoverCustomReportSlice = useSelector(
    (state: RootState) => state.recoverCustomReportSlice
  );
  const addContractSlice = useSelector((state: RootState) => state.addContractSlice);
  const editContractSlice = useSelector((state: RootState) => state.editContractSlice);
  const deleteContractSlice = useSelector((state: RootState) => state.deleteContractSlice);
  const recoverContractSlice = useSelector((state: RootState) => state.recoverContractSlice);

  const addContractTypeSlice = useSelector((state: RootState) => state.addContractTypeSlice);
  const editContractTypeSlice = useSelector((state: RootState) => state.editContractTypeSlice);
  const deleteContractTypeSlice = useSelector((state: RootState) => state.deleteContractTypeSlice);
  const recoverContractTypeSlice = useSelector(
    (state: RootState) => state.recoverContractTypeSlice
  );

  const editSequenceSlice = useSelector((state: RootState) => state.editSequenceNumberSlice);

  const addLenderDecisionSlice = useSelector((state: RootState) => state.addLenderDecisionSlice);
  const addManyLenderDecisionsSlice = useSelector(
    (state: RootState) => state.addManyLenderDecisions
  );
  const editLenderDecisionSlice = useSelector((state: RootState) => state.editLenderDecisionSlice);
  const deleteLenderDecisionSlice = useSelector(
    (state: RootState) => state.deleteLenderDecisionSlice
  );
  const recoverLenderDecisionSlice = useSelector(
    (state: RootState) => state.recoverLenderDecisionSlice
  );

  const editSettingsSlice = useSelector((state: RootState) => state.editSettingsSlice);
  const editTableSettingsSlice = useSelector((state: RootState) => state.editTableSettingsSlice);
  const addTableSettingsSlice = useSelector((state: RootState) => state.addTableSettingsSlice);

  const addColumnGroupSlice = useSelector((state: RootState) => state.addColumnGroupSlice);
  const editColumnGroupSlice = useSelector((state: RootState) => state.editColumnGroupSlice);
  const deleteColumnGroupSlice = useSelector((state: RootState) => state.deleteColumnGroupSlice);

  const addBusinessReportSlice = useSelector((state: RootState) => state.addBusinessReportSlice);
  const deleteBusinessReportSlice = useSelector(
    (state: RootState) => state.deleteBusinessReportSlice
  );
  const recoverBusinessReportSlice = useSelector(
    (state: RootState) => state.recoverBusinesReportSlice
  );
  const netSuiteInvoiceSlice = useSelector(
    (state: RootState) => state.createInvoiceAndVendorBillSlice
  );

  const addAdditionalRequiredDocument = useSelector(
    (state: RootState) => state.addAdditionalRequiredDocumentSlice
  );
  const editAdditionalRequiredDocument = useSelector(
    (state: RootState) => state.editAdditionalRequiredDocumentSlice
  );
  const deleteAdditionalRequiredDocument = useSelector(
    (state: RootState) => state.deleteAdditionalRequiredDocumentSlice
  );
  const recoverAdditionalRequiredDocument = useSelector(
    (state: RootState) => state.recoverAdditionalRequiredDocumentSlice
  );

  const editOneSpanSigning = useSelector((state: RootState) => state.editOneSpanSigningSlice);

  const addPrintedDocumentSlice = useSelector((state: RootState) => state.addPrintedDocumentSlice);
  const editPrintedDocumentSlice = useSelector(
    (state: RootState) => state.editPrintedDocumentSlice
  );

  const deletePrintedDocumentSlice = useSelector(
    (state: RootState) => state.deletePrintedDocumentSlice
  );

  const addChargebackSlice = useSelector((state: RootState) => state.addChargebackSlice);
  const deleteChargebackSlice = useSelector((state: RootState) => state.deleteChargebackSlice);
  const editChargebackSlice = useSelector((state: RootState) => state.editChargebackSlice);
  const recoverChargebackSlice = useSelector((state: RootState) => state.recoverChargebackSlice);

  const addCreditScoreRangeSlice = useSelector(
    (state: RootState) => state.addCreditScoreRangeSlice
  );
  const deleteCreditScoreRangeSlice = useSelector(
    (state: RootState) => state.deleteCreditScoreRangeSlice
  );
  const editCreditScoreRangeSlice = useSelector(
    (state: RootState) => state.editCreditScoreRangeSlice
  );

  const recoverCreditScoreRangeSlice = useSelector(
    (state: RootState) => state.recoverCreditScoreRangeSlice
  );
  const deleteDealershipTicketSlice = useSelector(
    (state: RootState) => state.deleteDealershipTicketSlice
  );
  const editDealershipTicketSlice = useSelector(
    (state: RootState) => state.editDealershipTicketSlice
  );
  const recoverDealershipTicketSlice = useSelector(
    (state: RootState) => state.recoverDealershipTicketSlice
  );

  const deleteLenderTicketTypeSlice = useSelector(
    (state: RootState) => state.deleteLenderTicketTypeSlice
  );
  const editLenderTicketTypeSlice = useSelector(
    (state: RootState) => state.editLenderTicketTypeSlice
  );
  const recoverLenderTicketTypeSlice = useSelector(
    (state: RootState) => state.recoverLenderTicketTypeSlice
  );
  const addLenderTicketTypeSlice = useSelector(
    (state: RootState) => state.addLenderTicketTypeSlice
  );

  const deletePrescreenSlice = useSelector((state: RootState) => state.deletePrescreenSlice);
  const recoverPrescreenSlice = useSelector((state: RootState) => state.recoverPrescreenSlice);
  const editPrescreenSlice = useSelector((state: RootState) => state.editPrescreenSlice);

  // TODO: add type
  const slices = {
    applicant: {
      add: addApplicantSlice,
      delete: deleteApplicantSlice,
      edit: editApplicantSlice,
      recover: recoverApplicantSlice
    },
    dealership: {
      add: addDealershipSlice,
      delete: deleteDealershipSlice,
      edit: editDealershipSlice,
      recover: recoverDealershipSlice
    },
    dealership_program: {
      add: addDealershipProgramSlice,
      delete: deleteDealershipProgramSlice,
      edit: editDealershipProgramSlice,
      recover: recoverDealershipProgramSlice
    },
    external_credit_application: {
      add: addExternalCreditApplication,
      delete: deleteExternalCreditApplication,
      edit: editExternalCreditApplication,
      recover: recoverExternalCreditApplication
    },
    lender: {
      add: addLenderSlice,
      delete: deleteLenderSlice,
      edit: editLenderSlice,
      recover: recoverLenderSlice
    },
    lender_decision: {
      add: addLenderDecisionSlice,
      addMany: addManyLenderDecisionsSlice,
      delete: deleteLenderDecisionSlice,
      edit: editLenderDecisionSlice,
      recover: recoverLenderDecisionSlice
    },
    deal: {
      add: addDealSlice,
      delete: deleteDealSlice,
      edit: editDealSlice,
      recover: recoverDealSlice
    },
    chargeback: {
      add: addChargebackSlice,
      delete: deleteChargebackSlice,
      edit: editChargebackSlice,
      recover: recoverChargebackSlice
    },
    dmv: {
      add: addDmvSlice,
      delete: deleteDmvSlice,
      edit: editDmvSlice,
      recover: recoverDmvSlice
    },
    custom_report: {
      add: addCustomReportSlice,
      delete: deleteCustomReportSlice,
      edit: editCustomReportSlice,
      recover: recoverCustomReportSlice
    },
    title_issue: {
      delete: deleteTitleIssueSlice,
      edit: editTitleIssueSlice,
      recover: recoverTitleIssueSlice
    },
    dealership_ticket: {
      delete: deleteDealershipTicketSlice,
      edit: editDealershipTicketSlice,
      recover: recoverDealershipTicketSlice
    },
    role: {
      add: addRoleSlice,
      delete: deleteRoleSlice,
      edit: editRoleSlice,
      recover: recoverRoleSlice
    },
    wfd_user: {
      add: addUserSlice,
      delete: deleteUserSlice,
      edit: editUserSlice,
      recover: recoverUserSlice
    },
    payoff_bank: {
      add: addPayoffBankSlice,
      delete: deletePayoffBankSlice,
      edit: editPayoffBankSlice,
      recover: recoverPayoffBankSlice
    },
    other_vendor: {
      add: addOtherVendorSlice,
      delete: deleteOtherVendorSlice,
      edit: editOtherVendorSlice,
      recover: recoverOtherVendorSlice
    },
    state: {
      add: addStateSlice,
      delete: deleteStateSlice,
      edit: editStateSlice,
      recover: recoverStateSlice
    },
    document_template: {
      add: addDocumentTemplateSlice,
      delete: deleteDocumentTemplateSlice,
      edit: editDocumentTemplateSlice,
      recover: recoverDocumentTemplateSlice
    },
    document_validation: {
      add: addValidationSlice,
      delete: deleteValidationSlice,
      edit: editValidationSlice
    },
    appone_import_application: {
      add: addAppOneDealSlice
    },
    dealertrack_deal: {
      add: addDealerTrackDealSlice
    },
    vehicle_insurance_company: {
      add: addVehicleInsuranceCompanySlice,
      delete: deleteVehicleInsuranceCompanySlice,
      edit: editVehicleInsuranceCompanySlice,
      recover: recoverVehicleInsuranceCompanySlice
    },
    file: {
      add: addFileSlice,
      delete: deleteFileSlice,
      edit: editFileSlice,
      recover: recoverFileSlice
    },
    funding_document: {
      add: addFundingDocumentSlice,
      delete: deleteFundingDocumentSlice,
      edit: editFundingDocumentSlice,
      recover: recoverFundingDocumentSlice
    },
    sequence: {
      edit: editSequenceSlice
    },
    contract: {
      add: addContractSlice,
      delete: deleteContractSlice,
      edit: editContractSlice,
      recover: recoverContractSlice
    },
    contract_type: {
      add: addContractTypeSlice,
      delete: deleteContractTypeSlice,
      edit: editContractTypeSlice,
      recover: recoverContractTypeSlice
    },
    settings: {
      edit: editSettingsSlice
    },
    table_settings: {
      edit: editTableSettingsSlice,
      add: addTableSettingsSlice
    },
    column_group: {
      edit: editColumnGroupSlice,
      add: addColumnGroupSlice,
      delete: deleteColumnGroupSlice
    },
    business_report: {
      add: addBusinessReportSlice,
      delete: deleteBusinessReportSlice,
      recover: recoverBusinessReportSlice
    },
    additional_required_document: {
      add: addAdditionalRequiredDocument,
      delete: deleteAdditionalRequiredDocument,
      edit: editAdditionalRequiredDocument,
      recover: recoverAdditionalRequiredDocument
    },
    one_span_signing: {
      edit: editOneSpanSigning
    },
    printed_document: {
      add: addPrintedDocumentSlice,
      edit: editPrintedDocumentSlice,
      delete: deletePrintedDocumentSlice
    },
    lender_ticket: {
      add: addLenderTicketSLice,
      delete: deleteLenderTicketSlice,
      edit: editLenderTicketSlice,
      recover: recoverLenderTicketSlice
    },
    credit_score_range: {
      add: addCreditScoreRangeSlice,
      delete: deleteCreditScoreRangeSlice,
      edit: editCreditScoreRangeSlice,
      recover: recoverCreditScoreRangeSlice
    },
    lender_ticket_type: {
      add: addLenderTicketTypeSlice,
      delete: deleteLenderTicketTypeSlice,
      edit: editLenderTicketTypeSlice,
      recover: recoverLenderTicketTypeSlice
    },
    prescreen: {
      edit: editPrescreenSlice,
      delete: deletePrescreenSlice,
      recover: recoverPrescreenSlice
    }
  };

  useEffect(() => {
    Object.entries(slices).forEach(([type, actions]) =>
      Object.entries(actions).forEach(([action, slice]) => {
        Object.entries(slice).forEach(([requestId, value]: any) => {
          const actionType = action as "add" | "addMany" | "delete" | "edit" | "recover";
          const config = entityConfigs[type as EntityType];
          switch (value.status) {
            case "error":
              dispatch({
                type: config[actionType].sliceActions.none.type,
                payload: { requestId }
              });
              dispatch(hideLoader());
              if (typeof value?.error?.message === "string") {
                enqueueSnackbar(
                  <span
                    dangerouslySetInnerHTML={{
                      __html: value.error.message
                    }}
                  />,
                  {
                    variant: "error"
                  }
                );
              } else if (typeof value?.message === "string") {
                enqueueSnackbar(
                  <span
                    dangerouslySetInnerHTML={{
                      __html: value.message
                    }}
                  />,
                  {
                    variant: "error"
                  }
                );
              } else {
                try {
                  notifyWebhooks(value);
                } catch (error) {
                  console.error(error);
                }
                console.error(value);
                enqueueSnackbar(
                  "It seems like you found a bug! Please contact the support team for help!",
                  {
                    variant: "error"
                  }
                );
              }
              break;
            case "waiting":
              dispatch(showLoader());
              break;
            case "success":
              dispatch(hideLoader());
              if (action === "addMany" && type === "lender_decision") {
                const responseData = slice[requestId]?.data?.message;
                enqueueSnackbar(
                  <div>
                    Upload completed!
                    <TableContainer style={{ marginTop: "10px" }} component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>Result:</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Rows processed</TableCell>
                            <TableCell>{responseData.countOfRows}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Duplicates found</TableCell>
                            <TableCell>{responseData.countOfDuplicatedRows}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Records Created</TableCell>
                            <TableCell>{responseData.countOfAddedRecords}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Records connected to single deal</TableCell>
                            <TableCell>{responseData.countOfRecordsWithSingleRelation}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Records connected to multiple deals</TableCell>
                            <TableCell>{responseData.countOfRecordsWithMultyRelation}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Records not connected to deal</TableCell>
                            <TableCell>{responseData.countOfRecordsWithputRelation}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>,
                  {
                    persist: true,
                    variant: "success"
                  }
                );
              } else
                enqueueSnackbar(resolveMessage(action, type), {
                  variant: "success"
                });
              dispatch({
                type: config[actionType].sliceActions.none.type,
                payload: { requestId }
              });
              break;
          }
        });
      })
    );
  }, [slices, dispatch, enqueueSnackbar]);
  return children;
};
