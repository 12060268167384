import { additionalInsuranceTypes, CustomInsurance, Deal } from "components/Deals/types";
import React, { useEffect } from "react";
import { createOptionsForSelect, FormComponent } from "utils/models/fields";
import { generateForm, RenderSet, StateAccess } from "utils/models/formGenerator";
import { allProviders } from "./types";

const selectField: FormComponent<CustomInsurance> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "select-field",
      name: "provider",
      label: "Provider",
      width: (stateAccess) => (stateAccess.get(["provider"]) === "Other" ? "1/2" : "full"),
      path: ["provider"],
      options: createOptionsForSelect({
        possibleValues: () => [...allProviders],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      required: true,
      default: ""
    },
    {
      formComponent: "text-field",
      name: "customProvider",
      label: "Custom Provider",
      width: (stateAccess) => (stateAccess.get(["provider"]) === "Other" ? "1/2" : "full"),
      path: ["customProvider"],
      show: (stateAccess) => stateAccess.get(["provider"]) === "Other",
      required: true,
      default: ""
    }
  ]
};
const textField: FormComponent<CustomInsurance> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "text-field",
      name: "provider",
      label: "Provider",
      width: "full",
      path: ["provider"],
      required: true,
      default: ""
    }
  ]
};

const getPowerSportCost = (apr: number, term: number, unitType?: string) => {
  const type = unitType
    ? ["Cruiser / Touring", "Scooter / Moped"].includes(unitType)
      ? "Bike" //Cruiser/Touring & Scooter
      : [
          "ATV",
          "UTV",
          "Snowmobile",
          "Sport Bike",
          "Jet Ski",
          "Lehman Trike",
          "Off-Road",
          "Sport",
          "Can-Am",
          "Dual bikes"
        ].includes(unitType)
      ? "Powersport" // Snowmoblile, ATV, UTV etc..
      : false
    : false;
  if (unitType === "Scooter / Moped") {
    return 50;
  } else if (term >= 1 && term <= 60) {
    if (apr <= 13) {
      return type === "Bike" ? 218 : type === "Powersport" ? 293 : 0;
    } else if (apr > 13) {
      return type === "Bike" ? 246 : type === "Powersport" ? 345 : 0;
    }
  } else if (term >= 61 && term <= 72) {
    if (apr <= 13) {
      return type === "Bike" ? 267 : type === "Powersport" ? 383 : 0;
    } else if (apr > 13) {
      return type === "Bike" ? 311 : type === "Powersport" ? 465 : 0;
    }
  } else if (term >= 73 && term <= 84) {
    if (apr <= 13) {
      return type === "Bike" ? 327 : type === "Powersport" ? 494 : 0;
    } else if (apr > 13) {
      return type === "Bike" ? 389 : type === "Powersport" ? 609 : 0;
    }
  }
  return 0;
};

const getAutomotiveCost = (amountFinanced: number, term: number, date?: string) => {
  let price = 0;
  const changeDate = new Date("2024-09-03");

  if (!date || new Date(date).getTime() >= changeDate.getTime()) {
    if (term >= 1 && term <= 12) {
      price = 301;
    } else if (term >= 13 && term <= 24) {
      price = 312;
    } else if (term >= 25 && term <= 36) {
      price = 326;
    } else if (term >= 37 && term <= 48) {
      price = 349;
    } else if (term >= 49 && term <= 60) {
      price = 356;
    } else if (term >= 61 && term <= 72) {
      price = 454;
    } else if (term >= 73 && term <= 84) {
      price = 582;
    }
  } else {
    if (term >= 1 && term <= 12) {
      price = 281;
    } else if (term >= 13 && term <= 24) {
      price = 289;
    } else if (term >= 25 && term <= 36) {
      price = 300;
    } else if (term >= 37 && term <= 48) {
      price = 318;
    } else if (term >= 49 && term <= 60) {
      price = 324;
    } else if (term >= 61 && term <= 72) {
      price = 407;
    } else if (term >= 73 && term <= 84) {
      price = 513;
    }
    if (amountFinanced > 100_000 && price > 0) {
      return price + 70;
    }
  }

  return price;
};
const setInsuranceCost = (deal: Deal, insurance: CustomInsurance, stateAccess: StateAccess) => {
  const interestRate = deal?.data?.info?.payment?.interestRate;
  const dealType = deal?.data?.info?.type;
  const unitType = deal?.data?.info?.vehicle?.unitType;
  const amountFinanced = deal?.data?.info?.payment?.dealTotal;
  const insuranceType = insurance.chosenRate.insuranceType;
  const provider = insurance?.provider;
  let term = insurance?.chosenRate?.months;

  let cost = 0;
  if (dealType === "Automotive" && provider === "Protective" && insuranceType === "gap") {
    if (
      typeof insurance?.chosenRate?.customMonths === "number" &&
      insurance?.chosenRate?.customMonthsEnabled &&
      deal?.data?.info?.payment?.numberOfPayments === 87
    ) {
      term = insurance?.chosenRate?.customMonths;
    }
    cost = getAutomotiveCost(amountFinanced, term, deal?.data?.info?.dealDates?.contractDate);
  } else if (dealType === "Power Sport" && provider === "XtraRide") {
    cost = getPowerSportCost(interestRate, term, unitType);
  }

  stateAccess.set(["chosenRate"], {
    ...insurance?.chosenRate,
    dealerCostPrice: cost,
    months: term,
    remitPrice: cost
  });
};
export default function ProviderPreview({
  mainStateAccess,
  stateAccess,
  renderSet
}: {
  mainStateAccess: StateAccess;
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) {
  const deal = mainStateAccess.get<Deal>([]);
  const insurance = stateAccess.get<CustomInsurance>([]);
  const term = insurance?.chosenRate?.months;
  const customTerm = insurance?.chosenRate?.customMonths;
  const insuranceType = insurance?.chosenRate?.insuranceType;
  const provider = insurance?.provider;

  useEffect(() => {
    setInsuranceCost(deal, insurance, stateAccess);
  }, [provider, term, customTerm, insuranceType]);

  return (
    <>
      {generateForm(
        additionalInsuranceTypes.includes(insuranceType) ? textField : selectField,
        stateAccess,
        [],
        stateAccess,
        renderSet
      )}
    </>
  );
}
