import React from "react";
import { createOptionsForSelect } from "utils/models/fields";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { MarkupTable, MarkupType } from "components/Lenders/types";
const MarkupTableSelect = ({
  mainStateAccess,
  stateAccess,
  renderSet,
  path,
  type
}: {
  mainStateAccess: StateAccess;
  stateAccess: StateAccess;
  renderSet: RenderSet;
  path: string[];
  type: MarkupType;
}) => {
  return (
    <>
      {renderSet.selectInputRenderer(
        {
          formComponent: "select-field",
          name: "Markup table",
          label: "Markup table",
          path,
          options: createOptionsForSelect({
            possibleValues: () =>
              mainStateAccess
                .get(["data", "info", "markupTables"])
                ?.filter((mt: MarkupTable) => mt.markupType === type)
                ?.map((mt: any) => mt?.id) ?? [],
            getOptionLabel: (id) =>
              mainStateAccess
                .get(["data", "info", "markupTables"])
                ?.find((mt: MarkupTable) => mt?.id === id)?.name,
            getSelectedOption: (x, y) => x === y
          })
        },
        stateAccess,
        [],
        stateAccess,
        renderSet
      )}
    </>
  );
};

export default MarkupTableSelect;
