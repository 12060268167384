import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { SubmitRvContractRequest } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<SubmitRvContractRequest, any>(
  "SubmitRvContractRequest",
  {
    request: "protective_submit_rv_contracts"
  }
);

export const sumbitRVContracts = socketAction;
export const sumbitRVContractsActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
