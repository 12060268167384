import React from "react";
import ReactApexChart from "react-apexcharts";
import Paper from "@material-ui/core/Paper";
import styles from "../LenderDashboard.module.css";
import { CollateralTypes } from "components/Deals/types";
import { ValueByType } from "hooks/useLenderStatistics/types";

interface Props {
  data: (ValueByType & { period: string })[];
  title: string;
  selectedCollateralTypes: CollateralTypes[];
}

const addSelectedCollateralTypesToData = (
  data: (ValueByType & { period: string }) | undefined,
  selectedCollateralTypes: CollateralTypes[]
): ValueByType & { period: string } => {
  const dataWithAllCollateralTypes = {
    Automotive: data?.Automotive ?? 0,
    Marine: data?.Marine ?? 0,
    ["Recreational Vehicles"]: data?.["Recreational Vehicles"] ?? 0,
    ["Power Sport"]: data?.["Power Sport"] ?? 0,
    period: data?.period
  };
  return Object.fromEntries(
    Object.entries(dataWithAllCollateralTypes).filter(
      ([key, _]) => selectedCollateralTypes.includes(key as CollateralTypes) || key === "period"
    )
  ) as ValueByType & { period: string };
};

const sortData = (
  data: (ValueByType & { period: string }) | undefined,
  selectedCollateralTypes: CollateralTypes[]
): {
  data: Record<CollateralTypes, { data: number; color: string }>;
  period: string;
} => {
  const dataWithSelectedCollateralTypes = addSelectedCollateralTypesToData(
    data,
    selectedCollateralTypes
  );
  const order = ["Automotive", "Marine", "Recreational Vehicles", "Power Sport"];
  const orderMap = new Map();
  order.forEach((value, index) => {
    orderMap.set(value, index);
  });

  const sortedDataEntries = Object.entries(dataWithSelectedCollateralTypes ?? {}).sort(
    ([keyA, valueA], [keyB, valueB]) => {
      const indexA = orderMap.get(keyA);
      const indexB = orderMap.get(keyB);

      if (indexA === undefined && indexB === undefined) {
        return 0;
      } else if (indexA === undefined) {
        return 1;
      } else if (indexB === undefined) {
        return -1;
      }

      return indexA - indexB;
    }
  );
  const sortedData = {
    ...Object.fromEntries(sortedDataEntries)
  } as ValueByType & { period: string };
  const sortedDataWithColors = addColors(sortedData);
  return { data: sortedDataWithColors, period: data?.period as string };
};
const addColors = (obj: ValueByType): Record<CollateralTypes, { data: number; color: string }> => ({
  ...Object.entries(obj).reduce(
    (acc: Record<CollateralTypes, { data: number; color: string }>, [key, value]) => ({
      ...acc,
      [key]: { data: value || 0, color: getColorByCollateralType(key as CollateralTypes) }
    }),
    {} as Record<CollateralTypes, { data: number; color: string }>
  )
});
const getColorByCollateralType = (collateralType: CollateralTypes) => {
  switch (collateralType) {
    case "Automotive":
      return "#f0d855";
    case "Marine":
      return "#51bde3";
    case "Recreational Vehicles":
      return "#52a543";
    case "Power Sport":
      return "#ffa500";
  }
};
const constructSeries = (
  sortedData: {
    data: Record<
      CollateralTypes,
      {
        data: number;
        color: string;
      }
    >;
    period: string;
  }[]
) => {
  return sortedData.reduce((acc: { name: string; data: number[] }[], obj) => {
    Object.entries(obj.data)
      .filter(([key]) => key !== "period")
      .forEach(([name, { data }]) => {
        if (typeof data === "number") {
          const existingItem = acc.find((item) => item.name === name);
          if (existingItem) {
            existingItem.data.push(data);
          } else {
            acc.push({ name, data: [data] });
          }
        }
      });

    return acc;
  }, []);
};
export default function TrendsChartByStatus({ data, selectedCollateralTypes, title }: Props) {
  const sortedData = data.map((x) => sortData(x, selectedCollateralTypes));
  const categories = data.map((x) => x.period);
  const series = constructSeries(sortedData);
  const colors = Object.values(sortedData?.[0]?.data)
    .map((x) => x.color)
    .filter((x) => x);
  return (
    <Paper style={{ height: "500px" }} className={styles.dashboardItem}>
      <ReactApexChart
        options={{
          chart: {
            stacked: false,
            toolbar: {
              show: false
            }
          },
          legend: {
            offsetY: 5
          },
          plotOptions: {
            bar: { barHeight: "100%", distributed: true, horizontal: false }
          },

          labels: categories,
          colors: colors,
          dataLabels: {
            dropShadow: { enabled: false },
            style: {
              colors: ["black"]
            },
            formatter: (val, opts) => {
              return typeof opts.w.config.series[opts.seriesIndex] === "number"
                ? opts.w.config.series[opts.seriesIndex]
                : "";
            }
          },
          stroke: { width: 2 },
          xaxis: { categories },
          yaxis: {
            labels: {
              show: true
            }
          },
          title: { text: title, align: "center", style: { fontSize: "22px", fontWeight: 600 } }
        }}
        series={series}
        type="line"
        height={270}
        width={500}
      />
    </Paper>
  );
}
