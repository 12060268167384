import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Button,
  Switch
} from "@material-ui/core";
import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import TextLoop from "components/Loader/TextLoop";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";

import { getLockList } from "./models/LockEntity/listLockSlice";
import { unlockEntityFunction, lockEntityFunction } from "./models/ShowForm";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Model } from "./models/fields";

interface Props<T> {
  entityId: string;
  model: Model<T>;
}
export const LockEntity = <T extends any>({ entityId, model }: Props<T>) => {
  const entityName = model.name;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const [lockEntityRequestId] = useState(uuidv4());
  const lockRequestId = `${entityName} - ${entityId}`;
  const { user } = useSelector((state: RootState) => state.authSlice);
  const lockEntityState = useSelector((state: RootState) => state.lockEntitySlice[lockRequestId]);
  const allLocks = useSelector((state: RootState) => state.listLockSlice["all"]);
  const lockData = allLocks?.entities?.find(
    (lock) => lock?.data?.info?.entityId === entityId && lock?.data?.info?.entityName === entityName
  );
  const edit = user?.email === lockData?.data?.info?.userEmail;
  const [oldEdit, setOldEdit] = useState<boolean>(edit);

  const handleChangeLock = () => {
    setLoading(true);
    if (edit) {
      unlockEntityFunction(dispatch, entityId, entityName, lockRequestId);
    } else {
      lockEntityFunction(dispatch, entityId, entityName, lockRequestId);
    }
  };

  const handleClick = () => {
    lockEntityFunction(dispatch, entityId, entityName, lockEntityRequestId);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (lockEntityState?.status === "error") {
      enqueueSnackbar(lockEntityState?.message, {
        variant: "error"
      });
      setLoading(false);
    }
  }, [enqueueSnackbar, lockEntityState, dispatch]);
  useEffect(() => {
    if (allLocks?.entities === undefined) dispatch(getLockList("all"));
  }, [lockEntityRequestId, dispatch, lockEntityState, allLocks, lockRequestId]);

  useEffect(() => {
    if (oldEdit !== edit) {
      setOldEdit(edit);
      setLoading(false);
    }
  }, [edit, setLoading, loading, oldEdit]);

  return (
    <>
      <Dialog
        id="confirm-delete-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to unlock this {entityName}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {lockData &&
              `Locked by ${lockData?.data?.info?.userEmail}  
           ${new Intl.DateTimeFormat("en", {
             day: "2-digit",
             month: "2-digit",
             year: "2-digit",
             hour: "2-digit",
             minute: "2-digit",
             second: "2-digit"
           }).format(new Date(lockData?.updatedAt))}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary" id="cancel">
            Cancel
          </Button>
          <Button
            onClick={handleClick}
            variant="contained"
            color="secondary"
            autoFocus
            id="confirm"
          >
            Unlock
          </Button>
        </DialogActions>
      </Dialog>

      <AccessControl
        requiredPermissions={[
          { entity: entityName, action: "update" },
          { entity: "lock", action: "update" }
        ]}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          {!lockData?.data?.info?.userEmail || user?.email === lockData?.data?.info?.userEmail ? (
            loading ? (
              <TextLoop
                text=""
                style={{
                  background: "rgba(0 ,0 , 0 , 0.01)",
                  width: "90px",
                  marginTop: "10px",
                  height: "100%",
                  textAlign: "center",
                  color: "#000"
                }}
              />
            ) : (
              <FormControlLabel
                id={`toggle-${model.name}-edit`}
                control={
                  <Switch
                    id={`toggle-${model.name}-edit-input`}
                    value={edit}
                    checked={edit}
                    onChange={() => {
                      handleChangeLock();
                    }}
                  />
                }
                label="Edit"
              />
            )
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleOpen}
              endIcon={<LockOpenIcon />}
            >
              {lockData?.data?.info?.userEmail}
            </Button>
          )}
        </div>
      </AccessControl>
    </>
  );
};
