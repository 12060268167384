import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { editDeal, editPartialDeal } from "../editDealSlice";
import { Deal, ReasonsDead, ReasonsDidNotProcess, StatusReason, StatusReasons } from "../types";
import { RootState } from "app/rootReducer";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { IconButton, TextField } from "@material-ui/core";
import { lockEntityFunction, unlockEntityFunction } from "utils/models/ShowForm";

import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { getReasonsByStatus } from "../DealStatuses";

export default function ({ deal }: { deal: Deal }) {
  const [requestId] = useState(uuidv4());
  const editDealState = useSelector((state: RootState) => state?.editDealSlice[requestId]);

  const allLocks = useSelector((state: RootState) => state.listLockSlice["all"]);
  const lockData = allLocks?.entities?.find(
    (lock) => lock?.data?.info?.entityId === deal?._id && lock?.data?.info?.entityName === "deal"
  );
  const [reason, setReason] = useState<ReasonsDead | ReasonsDidNotProcess | null>(
    deal?.data?.info?.statusReasons?.manager?.reason || null
  );
  const dispatch = useDispatch();

  const dealId = deal._id;
  const currentlyLockedEmail = lockData?.data?.info?.userEmail ?? null;
  const isLocked = currentlyLockedEmail !== null;
  useEffect(() => {
    if (editDealState?.status === "success") {
      unlockEntityFunction(dispatch, dealId, "deal");
    }
  }, [editDealState, dealId, dispatch]);
  useEffect(() => {
    if (
      reason !== deal?.data?.info?.statusReasons?.manager?.reason &&
      deal?.data?.info?.statusReasons?.manager?.reason
    )
      setReason(deal?.data?.info?.statusReasons?.manager?.reason);
  }, [reason, deal?.data?.info?.statusReasons?.manager?.reason]);
  useEffect(() => {
    if (
      reason !== deal?.data?.info?.statusReasons?.manager?.reason &&
      editDealState?.status !== "waiting" &&
      reason
    ) {
      dispatch(
        editPartialDeal({
          _id: deal._id,
          ...{
            data: {
              info: {
                statusReasons: {
                  manager: {
                    ...((deal?.data?.info?.statusReasons?.manager ?? {}) as StatusReason),
                    reason,
                    date: new Date().toISOString(),
                    marked: true,
                    userId: (deal?.data?.userId || deal?.data?.user?._id) as string
                  }
                }
              }
            }
          },
          requestId
        })
      );
      // dispatch(
      //   editDeal({
      //     requestId,
      //     ...deal,
      //     data: {
      //       ...deal.data,
      //       info: {
      //         ...deal.data.info,
      //         statusReasons: {
      //           ...((deal?.data?.info?.statusReasons ?? {}) as StatusReasons),
      //           manager: {
      //             ...((deal?.data?.info?.statusReasons?.manager ?? {}) as StatusReason),
      //             reason,
      //             date: new Date().toISOString(),
      //             marked: true,
      //             userId: (deal?.data?.userId || deal?.data?.user?._id) as string
      //           }
      //         }
      //       }
      //     }
      //   })
      // );
      setReason(deal?.data?.info?.statusReasons?.manager?.reason || null);
    }
  }, [dispatch, requestId, reason, editDealState, dealId]);

  const handleClick = () => {
    lockEntityFunction(dispatch, dealId, "deal");
  };

  return (
    <div style={{ position: "relative" }}>
      {isLocked ? (
        <Autocomplete
          style={{ zIndex: 6 }}
          value={reason || null}
          getOptionLabel={(reason) => reason ?? ""}
          getOptionSelected={(x, y) => x === y}
          options={getReasonsByStatus(deal.data.info.status)}
          onChange={(event, newValue) => {
            setReason(newValue);
          }}
          openOnFocus
          id="reason-select"
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              InputProps={{ ...params.InputProps }}
              label={"Status Reason"}
              variant="filled"
              size="small"
            />
          )}
        />
      ) : (
        <TextField
          id={"reason-readonly-select"}
          value={reason || null}
          inputProps={{
            readOnly: true
          }}
          name={"reason-readonly-select"}
          fullWidth
          variant="outlined"
          size="small"
        />
      )}
      {!isLocked && (
        <IconButton
          id="unlock-deal-button"
          size="small"
          style={{ position: "absolute", bottom: "-1px", right: "5px" }}
          onClick={handleClick}
        >
          <LockOpenIcon style={{ fontSize: "18px" }} />
        </IconButton>
      )}
    </div>
  );
}
