import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

export type DealsOrderRequest = {
  data: { dealId: any; order: any }[] | undefined;
};

interface DealsOrderResponse {
  message: string;
}
const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DealsOrderRequest,
  DealsOrderResponse
>("Drag reorder  deals", {
  request: "deals_order_many"
});

export const dealsDragOrder = socketAction;
export const dealsDragOrdesActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
