import { Paper, Typography } from "@material-ui/core";
import { LenderStatistic } from "hooks/useLenderStatistics/types";
import React from "react";
import { capitalize, formatNumberAsCurrency } from "utils/functions";
import styles from "../LenderDashboard.module.css";
import { Big } from "big.js";
const AmountFinancedInformation = ({
  statistic,
  period
}: {
  statistic: LenderStatistic | undefined;
  period: string;
}) => {
  return (
    <Paper style={{ padding: "20px 50px" }} className={styles.dashboardItem}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom: 30,
          gap: "10px"
        }}
      >
        <Typography
          style={{
            fontWeight: 500,
            fontSize: 22,
            lineHeight: "20px",
            marginBottom: 10,
            textAlign: "center"
          }}
        >
          Amount financed information
        </Typography>

        <Typography style={{ textAlign: "center" }}>{period}</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total Amount Financed (per selection)
          </Typography>

          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
            {formatNumberAsCurrency(statistic?.totalAmountFinanced || 0, "$")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total Amount Financed (Funded)
          </Typography>

          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
            {formatNumberAsCurrency(statistic?.amountFinancedByStatus?.funded || 0, "$")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total Potentional Funded Amount
          </Typography>

          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
            {formatNumberAsCurrency(
              new Big(statistic?.totalAmountFinanced || 0)
                .sub(new Big(statistic?.amountFinancedByStatus?.funded || 0))
                .toNumber(),
              "$"
            )}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Average amount financed
          </Typography>
          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
            {formatNumberAsCurrency(
              new Big(statistic?.totalAmountFinanced || 0)
                .div(new Big(statistic?.totalDeals || 1))
                .round(2, 1)
                .toNumber() || 0,
              "$"
            )}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total Gross Revenue (per selection)
          </Typography>

          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
            {formatNumberAsCurrency(statistic?.totalGrossRevenue || 0, "$")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total Gross Revenue (Funded)
          </Typography>

          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
            {formatNumberAsCurrency(statistic?.grossRevenueByStatus?.funded || 0, "$")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total Product Gross Revenue (per selection)
          </Typography>

          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
            {formatNumberAsCurrency(statistic?.totalProductGrossRevenue || 0, "$")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total Product Gross Revenue (Funded)
          </Typography>

          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
            {formatNumberAsCurrency(statistic?.productGrossRevenueByStatus?.funded || 0, "$")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total deal count
          </Typography>
          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
            {statistic?.totalDeals || 0}
          </Typography>
        </div>
      </div>
    </Paper>
  );
};

export default AmountFinancedInformation;
