import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { auth } from "../../firebase/firebase";

export const getDealershipActivity = (request: {
  customerCareAssigneeId?: string;
}): (() => Promise<{ dealershipIds: string[] }>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/dealership_activity`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    );

    return response.data.result;
  };
};

const useGetDealershipActivity = (
  request: { customerCareAssigneeId?: string },
  enabled = false
) => {
  const queryKey = ["dealership_activity", request];

  const { data, isLoading, isSuccess, error } = useQuery({
    queryKey: queryKey,
    queryFn: getDealershipActivity(request),
    enabled
  });

  return {
    data,
    isSuccess,
    isLoading,
    error
  };
};

export default useGetDealershipActivity;
