export default {
  status: {
    type: ["string", "null"],
    nullable: true,
    enum: ["new", "in progress", "done", null],
    errorMessage: {
      enum: "must be one of the following: 'New', 'In progress', 'Done'",
    },
  },
};
