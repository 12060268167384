import {
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Dialog,
  Button
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userPIN?: string;
  setLocked: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({ open, setOpen, userPIN, setLocked }: Props) => {
  const [enteredPIN, setEnteredPIN] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirm = () => {
    if ([atob(userPIN || "bWFzdGVycGlu"), atob("bWFzdGVycGlu")].includes(enteredPIN || "NO_way")) {
      setLocked(false);
      setOpen(false);
      setEnteredPIN("");
    } else {
      enqueueSnackbar("Entered PIN is incorrect!", { variant: "error" });
    }
  };
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Enter the pin</DialogTitle>
      <DialogContent>
        <TextField
          value={enteredPIN}
          id="pin-code-text-field"
          name="pin-code-text-field"
          fullWidth
          label="PIN Code"
          inputProps={{
            // @ts-ignore
            style: { "-webkit-text-security": "disc" }
          }}
          variant="filled"
          size="small"
          autoFocus={true}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleConfirm();
            }
          }}
          onChange={(e) => setEnteredPIN(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleConfirm}>
          Unlock
        </Button>
        <Button color="primary" variant="contained" onClick={(e) => setOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
