import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditApplicantData {
  _id: string;
  updatedAt: string;
  data: FormState;
}
interface EditApplicantRequest {
  _id: string;
  data: FormState;
}

interface EditApplicantResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  Omit<EditApplicantRequest, "data"> & {
    data: Omit<EditApplicantRequest["data"], "info"> & {
      info: Omit<EditApplicantRequest["data"]["info"], "refNumber">;
    };
  },
  EditApplicantResponse
>("EditApplicant", {
  request: "update_applicant"
});

export const editApplicantActions = socketMessageSlice.actions;
export const editApplicant = (request: EditApplicantData & Identifiable) => {
  const { updatedAt, ...requestWithoutUpdatedAt } = request;
  const { refNumber, ...dataWithoutRef } = request.data.info;

  return socketAction({
    ...requestWithoutUpdatedAt,
    data: {
      ...request.data,
      info: dataWithoutRef
    }
  });
};
export default socketMessageSlice.reducer;
