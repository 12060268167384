import React from "react";
import { Dialog, Grid, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  addFunction: () => void;
  renewFunction: () => void;
}

export default function RenewDialog({ setOpen, open, addFunction, renewFunction }: Props) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
        <Grid item xs={6} key="title">
          <DialogTitle>
            Create or renew a contract
            <CloseDialogButton closeFunction={handleClose} />
          </DialogTitle>
        </Grid>
      </Grid>
      <DialogContent>
        If you want to create a renew contract click on &quot;Renew&quot; button and then click on
        the renew button in the table. Otherwise click on the &apos;Add new contract&apos; button.
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={renewFunction}
          color="primary"
          variant="contained"
          id="renew-contract"
        >
          Renew
        </Button>
        <Button
          autoFocus
          onClick={addFunction}
          color="primary"
          variant="contained"
          id="add-new-contract"
        >
          Add new contract
        </Button>
      </DialogActions>
    </Dialog>
  );
}
