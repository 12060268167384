import React, { useRef, useContext } from "react";

import { SearchContext } from "components/Layout/Main";
import { Search } from "@material-ui/icons";
import { InputBase, IconButton, makeStyles, Paper } from "@material-ui/core";
import { StateAccess } from "utils/models/formGenerator";
import { HintTooltip } from "components/common/HintTooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: 0,
    transition: "all 0.5s"
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

export default function CountySearch({ stateAccess }: { stateAccess: StateAccess }) {
  const ref = useRef<HTMLInputElement>();
  const classes = useStyles();

  const id = stateAccess.get([])?._id;

  const [search, setSearch] = useContext(SearchContext);

  return (
    <Paper className={classes.root} elevation={2}>
      <InputBase
        className={classes.input}
        style={{ width: 300 }}
        placeholder="Search counties"
        ref={ref}
        onChange={(event) => {
          setSearch({ ...search, [id]: event.target.value });
        }}
      />
      <IconButton className={classes.iconButton} aria-label="search">
        <HintTooltip title="Click here to search for counties.">
          <Search />
        </HintTooltip>
      </IconButton>
    </Paper>
  );
}
