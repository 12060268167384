import React from "react";

const LogoIcon = ({ color = "#5E5D65" }: { color?: string }) => {
  return (
    <svg
      color="red"
      width="33"
      height="17"
      viewBox="0 0 33 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.2574 0.630859H16.0866L13.3804 5.36662L10.6743 0.630859H6.53986L11.3508 8.89965L10.0729 10.9293L4.1344 0.630859H0L9.0205 16.2664H11.1253L13.3052 12.4327L15.4852 16.2664H17.59L21.0478 10.2527H24.5809L26.6105 6.7197H19.0182L16.5376 10.9293L15.41 8.89965L18.1162 4.16389H25.2574C27.5877 4.16389 29.5421 6.04316 29.5421 8.44863C29.5421 10.8541 27.6629 12.7334 25.2574 12.7334H21.9499L19.9954 16.2664H25.1822C29.467 16.2664 33 12.7334 33 8.44863C33 4.16389 29.5421 0.630859 25.2574 0.630859Z"
        fill={color}
      />
    </svg>
  );
};

export default LogoIcon;
