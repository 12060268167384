import React from "react";
import LenderStatusHistory from "./LenderStatusHistory";
import { StateAccess } from "utils/models/formGenerator";
import { Grid, IconButton } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";

type Props = {
  stateAccess: StateAccess;
};
export default ({ stateAccess }: Props) => {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <IconButton
        onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
          event.preventDefault();
          event.stopPropagation();
          setOpen(true);
        }}
        component="span"
      >
        <Visibility />
      </IconButton>

      {open ? (
        <LenderStatusHistory
          handleClose={() => setOpen(false)}
          statusHistory={stateAccess.get(["data", "info", "statusHistory"])}
        />
      ) : null}
    </React.Fragment>
  );
};
