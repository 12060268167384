import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditCustomReportRequest {
  data: FormState;
}

export interface EditCustomReportResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditCustomReportRequest,
  EditCustomReportResponse
>("EditCustomReport", {
  request: "update_custom_report"
});

export const editCustomReportActions = socketMessageSlice.actions;
export const editCustomReport = socketAction;
export default socketMessageSlice.reducer;
