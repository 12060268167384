import { Deal, ReserveMethod } from "components/Deals/types";
import { Reserves } from "components/Lenders/types";
import { Path } from "utils/models/fields";
import { StateAccess } from "utils/models/formGenerator";

export interface BaseCell {
  width: number;
  height: number;
  stateAccess: StateAccess;
  groupStateAccess: StateAccess;
  indexInGroup: number;
}

export interface HeaderCell extends BaseCell {
  type: "header";
  direction: "vertical" | "horizontal";
  name: string;
  condition: Condition;
  accumulatedConditions: Condition[];
}

export interface MainCell extends BaseCell {
  type: "main";
  conditions: Condition[];
}

export interface BodyCell extends BaseCell {
  type: "body";
  conditions: Condition[];
}

export type Cell = HeaderCell | BodyCell | MainCell;

export const requirementTypes = ["==", ">", "<", ">=", "<=", "in"] as const;

export type RequirementType = typeof requirementTypes[number];

export interface BaseRequirement {
  type: RequirementType;
  criteria: number | boolean | string;
  possibleValues: any;
}

export interface Requirement<T extends "horizontal" | "vertical"> extends BaseRequirement {
  child: T extends "vertical" ? VerticalSetting : HorizontalSetting;
}

export interface HorizontalRequirement extends BaseRequirement {
  vertical: VerticalSetting;
}

export const contentFormulas = ["min(<interest>/2,2.25)"] as const;

export type Formula = typeof contentFormulas[number];

export const methods = ["fixed", "percentage"] as const;
export type Method = typeof methods[number];

export const tableReserveTypes = [
  "fixed",
  "percentage",
  "formula",
  "markup",
  "markup foursight"
] as const;

export type ReserveType = typeof tableReserveTypes[number];
export interface Content extends ExtraReserve {
  reserveMethod?: ReserveType;
  reserveFixed?: number;
  reservePercentage?: number;
  correspondentReservePercentage?: number;
  maxReserve?: number;
  formula?: Formula;
  markup?: number;
  markupTableId?: string;
  dealerSplitPercentage?: number;
  dealerSplitFixed?: number;
  cost?: number;
}

interface ExtraReserve {
  extraReserveFixed?: number;
  extraReservePercentage?: number;
  extraReserveType?: ReserveMethod;
}

export interface BottomRequirement extends BaseRequirement {
  content: Content;
}

export type Setting = VerticalSetting | HorizontalSetting;

export interface VerticalSetting {
  name: string;
  path: Path<Deal>;
  requirements: Requirement<"vertical">[] | BottomRequirement[];
}

export interface HorizontalSetting {
  name: string;
  path: Path<Deal>;
  requirements: Requirement<"horizontal">[] | HorizontalRequirement[];
}

export interface Condition {
  path: Path<Deal>;
  type: RequirementType;
  criteria: number | boolean | string;
}

export const isMainCell = (x: Cell): x is MainCell => {
  return x.type === "main";
};
export const isHeaderCell = (x: Cell): x is HeaderCell => {
  return x.type === "header";
};
export const isBodyCell = (x: Cell): x is BodyCell => {
  return x.type === "body";
};
export const isRequirement = (x: BaseRequirement): x is Requirement<"horizontal" | "vertical"> => {
  return x.hasOwnProperty("child");
};

export const isBottomRequirement = (x: BaseRequirement): x is BottomRequirement => {
  return x.hasOwnProperty("content");
};

export const isHorizontalRequirement = (x: BaseRequirement): x is HorizontalRequirement => {
  return x.hasOwnProperty("vertical");
};

// const isBottomRequirements = (
//   x: BaseRequirement | Requirement<"horizontal" | "vertical">
// ): x is BaseRequirement => {
//   return !x.hasOwnProperty("children");
// };

// const isRequirements = <T extends "horizontal" | "vertical">(
//   x: BaseRequirement | Requirement<T>
// ): x is Requirement<T> => {
//   return true;
// };
