import { createListSlice } from "../Middlewares/listSliceCreator";
import { TitleIssue } from "./types";

const { ListSlice, getList } = createListSlice<TitleIssue>({
  name: "TitleIssueList",
  request_topic: "all_title_issues",
  reducers: {}
});

export default ListSlice.reducer;
export const getTitleIssueList = getList;
export const listTitleIssueActions = ListSlice.actions;
export const removeTitleIssueList = ListSlice.actions.removeList;
