import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface GenerateDealershipLinkRequest {
  _id: string;
}

export interface GenerateDealershipLinkResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  GenerateDealershipLinkRequest,
  GenerateDealershipLinkResponse
>("GenerateLinkDealership", {
  request: "dealership_link_generator"
});

export const GenerateDealershipLinkActions = socketMessageSlice.actions;
export const GenerateDealershipLink = socketAction;
export default socketMessageSlice.reducer;
