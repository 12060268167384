import { createListSlice } from "../../Middlewares/listSliceCreator";
import { Checklist } from "./types";

const { ListSlice, getList } = createListSlice<Checklist>({
  name: "ChecklistList",
  request_topic: "all_checklists",
  reducers: {}
});

export default ListSlice.reducer;
export const getChecklistList = getList;
export const listChecklistActions = ListSlice.actions;
export const removeChecklistList = ListSlice.actions.removeList;
export const clearChecklistList = ListSlice.actions.clearList;
