import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

type createInvoiceRequest = {
  data: {
    dealId: string;
    invoice: boolean;
    gapBill: boolean;
    warrantyBill: boolean;
    dealerBill: boolean;
  };
};

interface createInvoiceResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  createInvoiceRequest,
  createInvoiceResponse
>("CreateInvoice", {
  request: "sales_order_to_invoice_and_vendor_bill"
});

export const createInvoiceActions = socketMessageSlice.actions;
export const createInvoiceAndVendorBill = socketAction;
export default socketMessageSlice.reducer;
