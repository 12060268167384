import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { auth } from "../../firebase/firebase";
import { LenderEstimationStatisticRequest, LenderEstimationStatisticsResponse } from "./types";

export const getLenderEstimationStatistics = (
  request: LenderEstimationStatisticRequest
): (() => Promise<LenderEstimationStatisticsResponse>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/lender_estimation_statistics`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`
        },
        withCredentials: true // make the req to be send to the same instance because of cookie
      }
    );

    return response.data.result;
  };
};

const useLenderEstimationStatistics = (
  request: LenderEstimationStatisticRequest,
  onSuccessCallback: (data: LenderEstimationStatisticsResponse) => void,
  enabled = true
) => {
  const queryKey = ["lender_estimation_statistics"];

  const { data, isLoading, isSuccess, error, isFetching } = useQuery({
    queryKey: queryKey,
    onSuccess: (data) => {
      onSuccessCallback(data);
    },
    queryFn: getLenderEstimationStatistics(request),
    enabled:
      enabled &&
      (request.lenderIds?.length || request.allLenders) &&
      (request.dealershipIds?.length || request.allDealerships) &&
      new Date(request.statisticsConfig.from).getTime() <=
        new Date(request.statisticsConfig.rollbackPointDate).getTime() &&
      new Date(request.statisticsConfig.rollbackPointDate).getTime() <=
        new Date(request.statisticsConfig.to).getTime() &&
      typeof request.statisticsConfig.estimationThresholdAmountPercentage === "number" &&
      request.statisticsConfig.estimationThresholdAmountPercentage >= 1 &&
      request.statisticsConfig.estimationThresholdAmountPercentage <= 100 &&
      typeof request.statisticsConfig.estimationThresholdDealCount === "number" &&
      request.statisticsConfig.estimationThresholdDealCount >= 0
        ? true
        : false
  });

  return {
    data,
    isSuccess,
    isLoading,
    isFetching,
    error
  };
};

export default useLenderEstimationStatistics;
