import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverDocumentTemplateRequest {
  _id: string;
}

export interface RecoverDocumentTemplateResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverDocumentTemplateRequest,
  RecoverDocumentTemplateResponse
>("Recover document template", {
  request: "recover_document_template"
});

export const recoverDocumentTemplateActions = socketMessageSlice.actions;
export const recoverDocumentTemplate = socketAction;
export default socketMessageSlice.reducer;
