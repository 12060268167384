import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { SendEmailData, DealResponse } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<SendEmailData, DealResponse>(
  "Send email",
  {
    request: "email_send"
  }
);

export const sendEmail = socketAction;
export const sendEmailAction = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
