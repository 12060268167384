import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { RootState } from "app/rootReducer";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateAccess } from "utils/models/formGenerator";
import { getDocumentTemplateList, removeDocumentTemplateList } from "./listDocumentTemplateSlice";
import { DocumentTemplate } from "./types";
import { File } from "../Files/types";
import { getFileList } from "components/Files/listFileSlice";
interface Props {
  stateAccess: StateAccess;
}

export default function DocumentTemplatesPreview({ stateAccess }: Props) {
  const [startFromPage, setStartFromPage] = React.useState(0);
  const dispatch = useDispatch();
  const documentTemplateslistId = `list-autocomplete-document-temlate-preview-${stateAccess.get([
    "_id"
  ])}`;
  const filesListId = `list-autocomplete-files-preview-${stateAccess.get(["_id"])}`;
  const documentTemplatesList = useSelector(
    (state: RootState) => state.listDocumentTemplateSlice[documentTemplateslistId]
  );

  const handleOpen = () => {
    if (!documentTemplatesList)
      dispatch(
        getDocumentTemplateList(documentTemplateslistId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } }
        })
      );
    return () => {
      dispatch(removeDocumentTemplateList(documentTemplateslistId));
      return;
    };
  };
  const fileList = useSelector((state: RootState) => state.listFileSlice[filesListId] || []);
  const handleSelectDocumentTemplate = (
    _: React.ChangeEvent<{}>,
    documentTemplate: DocumentTemplate | null
  ) => {
    if (documentTemplate)
      dispatch(
        getFileList(filesListId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } },
          query: {
            "data.documentTemplateId": documentTemplate._id
          }
        })
      );
  };
  const handleChangeCoordinates = (_: React.ChangeEvent<{}>, file: File | null) => {
    if (file) {
      const coordinates = file.data.info.coordinates?.map((coordinate) => ({
        ...coordinate,
        y: coordinate.y + (startFromPage - 1) * 1120 * 1.001
      }));
      console.log(startFromPage, coordinates);
      stateAccess.set<File>(["data", "info", "coordinates"], coordinates);
    }
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Autocomplete<DocumentTemplate>
            options={documentTemplatesList?.entities ?? []}
            autoHighlight
            openOnFocus
            fullWidth
            onOpen={handleOpen}
            getOptionLabel={(x) => x.data.info.name ?? ""}
            getOptionSelected={(x, y) => x._id === y._id}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...params.InputProps }}
                label={"Select template"}
                variant="filled"
                size="small"
              />
            )}
            onChange={handleSelectDocumentTemplate}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            type="number"
            label={"Start from page"}
            variant="filled"
            size="small"
            value={startFromPage}
            onChange={(e) => {
              setStartFromPage(parseInt(e.target.value));
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete<File>
            options={fileList?.entities ?? []}
            autoHighlight
            openOnFocus
            fullWidth
            onOpen={handleOpen}
            getOptionLabel={(x) => x.data.info.name ?? ""}
            getOptionSelected={(x, y) => x._id === y._id}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...params.InputProps }}
                label={"Select coordinates"}
                variant="filled"
                size="small"
              />
            )}
            onChange={handleChangeCoordinates}
          />
        </Grid>
      </Grid>
    </>
  );
}
