import FilesPreview from "components/Files/FilesPreview";
import { generateFileStruct } from "components/Files/model";
import { File } from "components/Files/types";
import UsersPreview from "components/Users/UserMultiSelect";
import React from "react";
import { createOptionsForSelect, FormComponent, Model } from "utils/models/fields";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import ContractDatesPreview from "./ContractDatesPreview";
import ContractTypeMultiSelect from "./ContractTypes/ContractTypeMultiSelect";
import { Contract, EmailsToNotify } from "./types";
import VendorPreview from "./VendorPreview";

export const fileDates: FormComponent<File> = {
  formComponent: "segment",
  name: "Dates",
  width: "full",
  entities: [
    {
      formComponent: "date-field",
      name: "signedDate",
      label: "Signed date",
      required: true,
      width: "1/3",
      path: ["data", "info", "contractDates", "signedDate"]
    },
    {
      formComponent: "date-field",
      name: "startDate",
      required: true,
      label: "Start date",
      width: "1/3",
      path: ["data", "info", "contractDates", "startDate"]
    },
    {
      formComponent: "date-field",
      name: "endDate",
      label: "End date",
      required: false,
      width: "1/3",
      path: ["data", "info", "contractDates", "endDate"]
    }
  ]
};
const dates: FormComponent<Contract> = {
  formComponent: "segment",
  width: "full",
  name: "Dates",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (stateAccess, _mainstateAccess, renderSet) => {
        return <ContractDatesPreview stateAccess={stateAccess} renderSet={renderSet} />;
      },
      name: "dates",
      width: "full",
      path: null
    }
  ]
};
const email: FormComponent<EmailsToNotify> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "text-field",
      name: "Email",
      label: "Email",
      width: "full",
      required: true,
      path: ["email"]
    }
  ]
};

const renewal: FormComponent<Contract> = {
  formComponent: "segment",
  name: "Notify renewal",
  width: "full",
  entities: [
    {
      formComponent: "select-field",
      name: "notifyRenewal",
      label: "Notify renewal weeks",
      width: "1/4",
      path: ["data", "info", "notifyRenewal"],
      options: createOptionsForSelect({
        possibleValues: () => [...Array(53).keys()].slice(1),
        getOptionLabel: (x) => (x ? `${x} ${x > 1 ? "Weeks" : "Week"}` : ""),
        getSelectedOption: (x, y) => x === y
      }),
      default: 2
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
        <UsersPreview
          name={"users"}
          label={"Users to notify"}
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["data", "users"]}
        />
      ),
      name: "Users",
      width: "1/4",
      path: [] as any
    },
    {
      formComponent: "list-model",
      name: "Emails to notify",
      width: "1/4",
      entity: email,
      required: false,
      path: ["data", "info", "emailsToNotify"]
    }
  ]
};
const dealershipsAndLenders: FormComponent<Contract> = {
  formComponent: "segment",
  name: "Applies to",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <VendorPreview
          stateAccess={stateAccess}
          renderSet={renderSet}
          name="vendorTypes"
          label="Vendor type"
          required={false}
        />
      ),
      name: "vendor",
      label: "Vendor",
      default: null,
      path: [],
      width: "full"
    }
  ]
};
const info: FormComponent<Contract> = {
  formComponent: "segment",
  name: "Info",
  width: "full",
  entities: [
    {
      formComponent: "text-field",
      name: "Name",
      label: "Name",
      width: "1/4",
      path: ["data", "info", "name"],
      required: true
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
        <ContractTypeMultiSelect
          stateAccess={stateAccess}
          path={["data", "contractTypes"]}
          renderSet={renderSet}
          name={"contractType"}
          label="Contract type"
        />
      ),
      name: "Contract type",
      width: "1/4",
      path: []
    },

    {
      formComponent: "segment",
      width: "1/2",
      entities: []
    }
  ]
};
const files: FormComponent<Contract> = {
  formComponent: "segment",
  name: "Contracts",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      name: "contracts",
      width: "full",
      path: null,
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => {
        return (
          <FilesPreview
            tableName="contracts"
            type="contract"
            isForAdditionalContractDocuments={false}
            _id={stateAccess.get(["_id"])}
            struct={generateFileStruct(
              createOptionsForSelect({
                possibleValues: (stateAccess: StateAccess) => [],
                getOptionLabel: (name) => name
              }),
              fileDates
            )}
          />
        );
      }
    }
  ]
};
const additionalDocuments: FormComponent<Contract> = {
  formComponent: "segment",
  name: "Additional documents",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      name: "documents",
      width: "full",
      path: null,
      component: (
        stateAccess: StateAccess,
        _mainstateAccess: StateAccess,
        _renderSet: RenderSet
      ) => {
        return (
          <FilesPreview
            tableName="additionalDocuments"
            type="contract"
            _id={stateAccess.get(["_id"])}
            isForAdditionalContractDocuments={true}
            struct={generateFileStruct(
              createOptionsForSelect({
                possibleValues: () => [],
                getOptionLabel: (name) => name
              })
            )}
          />
        );
      }
    }
  ]
};

const warning: FormComponent<Contract> = {
  formComponent: "segment",
  name: "Notice: Once you save the contract you will be able to upload a document!",
  entities: [],
  width: "full"
};

export const contractStruct: Model<Contract> = {
  formComponent: "model",
  schema: "new_contract",
  name: "contract",
  width: "full",
  entities: [dealershipsAndLenders, info, dates, renewal, warning]
};

export const editContractStruct: Model<Contract> = {
  formComponent: "model",
  schema: "update_contract",
  name: "contract",
  width: "full",
  entities: [dealershipsAndLenders, info, dates, renewal, files, additionalDocuments]
};
