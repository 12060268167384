import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from "@material-ui/core";
import { StateAccess } from "utils/models/formGenerator";
import { Change } from "components/Histories/types";
import { Delta, formatters } from "jsondiffpatch";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityDataTable, entitySlice, EntityType } from "utils/entitySlice";
import { RootState } from "../../app/rootReducer";
import { DisplayChange } from "./DisplayChange";

const displayDifferences = (x: Delta, path: string[]): Change[] => {
  if (Array.isArray(x)) {
    if (x.length === 2) {
      return [{ path, operation: "update", value: x }];
    } else if (x.length === 1) {
      return [{ path, operation: "add", value: x }];
    } else if (x.length === 3) {
      return [{ path, operation: "delete", value: x }];
    }
  }
  if (typeof x === "object") {
    if (x.hasOwnProperty("_t") && x._t === "a") {
      return Object.entries(x).reduce((acc, [key, value]): Change[] => {
        if (key === "_t") {
          return acc;
          // Just moved, no changes
        } else if (key.startsWith("_") && value[0] === "") {
          return acc;
          // Deleted property
        } else if (key.startsWith("_") && value[2] === 0) {
          return [...acc, { path, operation: "delete", value: [value[0]] }];
          // Added new element
        } else if (!key.startsWith("_") && Array.isArray(value)) {
          return [...acc, { path, operation: "add", value: [JSON.stringify(value[0])] }];
        }

        return [...acc, ...displayDifferences(value, [...path, key])];
      }, [] as Change[]);
    }
    return Object.entries(x).flatMap(([key, value]) => displayDifferences(value, [...path, key]));
  }
  return [];
};

export const History = <T extends EntityType>({
  _id,
  collection,
  stateAccess,
  setOpen
}: {
  _id: string;
  stateAccess: StateAccess;
  collection: EntityDataTable<T>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const entity = useSelector((state: RootState) => state.entitySlice.history?.[_id]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: `getEntity/socketAction`,
      payload: { _id, collection },
      http: {
        path: "get_history",
        success: {
          type: entitySlice.actions.add.type,
          additionalOptions: { type: "history" }
        },
        error: {
          type: "fix"
        }
      },
      socket: {
        topic: "get_history",
        success: {
          type: entitySlice.actions.add.type,
          additionalOptions: { type: "history" }
        },
        error: {
          type: "fix"
        }
      }
    });

    return () => {
      dispatch({
        type: entitySlice.actions.remove.type,
        payload: { type: "history", _id }
      });
    };
  }, [_id, collection, dispatch]);
  if (entity) {
    return (
      <TableContainer component={Paper}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            stateAccess.set<T>(["data"], entity?.data?.oldDocument?.data);
            setOpen(false);
          }}
          style={{ margin: "5px" }}
        >
          Rollback to previous state
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            stateAccess.set<T>(["data"], entity?.data?.newDocument?.data);
            setOpen(false);
          }}
          style={{ margin: "5px" }}
        >
          Rollback to new state
        </Button>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell component="th">Field</TableCell>
              <TableCell component="th">Previous value</TableCell>
              <TableCell component="th">New Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayDifferences(entity.data.delta as Delta, []).map((change, index) => (
              <TableRow key={index}>
                {DisplayChange({ change }).map((cell, index) => (
                  <TableCell key={index}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div
          className="jsondiffpatch-unchanged-hidden"
          dangerouslySetInnerHTML={{
            __html: formatters.html.format(entity.data.delta as Delta, entity.data.oldDocument)
          }}
        />
      </TableContainer>
    );
  } else {
    return null;
  }
};
