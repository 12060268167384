import { CustomRegexes } from "../../helpers";

const addressData = {
  attributes: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string", nullable: true },
    },
    additionalProperties: true,
  },
  totalmonths: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.NUMBERS,
    errorMessage: { pattern: "should not contain letters" },
  },
  yearsfractional: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.DECIMAL_STRING,
    errorMessage: { pattern: "should not contain letters" },
  },
  yearsrounded: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.NUMBERS,
    errorMessage: { pattern: "should not contain letters" },
  },
  street1: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string", nullable: true },
    },
    additionalProperties: true,
  },
  street2: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string", nullable: true },
    },
    additionalProperties: true,
  },
  city: {
    type: "object",
    nullable: true,
    properties: {
      value: {
        type: "string",
        pattern: CustomRegexes.LETTERS,
        errorMessage: { pattern: "should not contain numbers" },
        nullable: true,
      },
    },
    additionalProperties: true,
  },
  state: {
    type: "object",
    nullable: true,
    properties: {
      value: {
        type: "string",
        pattern: CustomRegexes.LETTERS,
        errorMessage: { pattern: "should not contain numbers" },
        nullable: true,
      },
    },
    additionalProperties: true,
  },
  zip: {
    type: "object",
    nullable: true,
    properties: {
      value: {
        type: "string",
        nullable: true,
        pattern: CustomRegexes.ZIP_CODE,
        errorMessage: {
          pattern:
            "should match either of the number formats: 'XXXXX' or 'XXXXX-XXXX'",
        },
      },
    },
    additionalProperties: true,
  },
  years: {
    type: "string",
    pattern: CustomRegexes.NUMBERS,
    errorMessage: { pattern: "should not contain letters" },
    nullable: true,
  },
  months: {
    type: "string",
    pattern: CustomRegexes.NUMBERS,
    errorMessage: { pattern: "should not contain letters" },
    nullable: true,
  },
};

const employmentData = {
  type: "object",
  properties: {
    yearsfractional: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.DECIMAL_STRING,
      errorMessage: { pattern: "should not contain letters" },
    },
    yearsrounded: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.NUMBERS,
      errorMessage: { pattern: "should not contain letters" },
    },
    flags: {
      type: "object",
      properties: {},
      additionalProperties: true,
    },
    classification: {
      type: "object",
      nullable: true,
      properties: {
        value: { type: "string", nullable: true },
      },
      additionalProperties: true,
    },
    totalmonths: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.NUMBERS,
      errorMessage: { pattern: "should not contain letters" },
    },
    company: {
      type: "object",
      nullable: true,
      properties: {
        value: { type: "string", nullable: true },
      },
      additionalProperties: true,
    },
    position: {
      type: "object",
      nullable: true,
      properties: {
        value: { type: "string", nullable: true },
      },
      additionalProperties: true,
    },
    militaryRank: {
      type: "object",
      nullable: true,
      properties: {
        value: {
          type: ["string", "null"],
          enum: ["E1", "E2", "E3", "E4", "E5", "E6", "E7", "NONE", null],
          errorMessage: {
            enum: "should be one of the following: 'E1', 'E2', 'E3', 'E4', 'E5', 'E6', 'E7', 'NONE'",
          },
          nullable: true,
        },
      },
      additionalProperties: true,
    },
    phone: {
      type: "object",
      nullable: true,
      properties: {
        value: { type: "string", nullable: true },
      },
      additionalProperties: true,
    },
    address: {
      type: "object",
      properties: addressData,
    },
    previousAddress: {
      type: "object",
      properties: addressData,
    },
    monthlyGross: {
      type: "object",
      nullable: true,
      properties: {
        value: { type: "string", nullable: true },
      },
      additionalProperties: true,
    },
    years: {
      type: "string",
      pattern: CustomRegexes.NUMBERS,
      errorMessage: { pattern: "should not contain letters" },
      nullable: true,
    },
    months: {
      type: "string",
      pattern: CustomRegexes.NUMBERS,
      errorMessage: { pattern: "should not contain letters" },
      nullable: true,
    },
  },
};

const applicantData = {
  firstName: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string" },
    },
    additionalProperties: true,
  },
  middleInitial: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string", nullable: true },
    },
    additionalProperties: true,
  },
  maidenName: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string" },
    },
    additionalProperties: true,
  },
  suffix: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string", nullable: true },
    },
    additionalProperties: true,
  },
  lastName: {
    type: "object",
    nullable: true,
    properties: {
      value: {
        type: "string",
        pattern: CustomRegexes.LETTERS,
        errorMessage: { pattern: "should not contain numbers" },
      },
    },
    additionalProperties: true,
  },
  maritalStatus: {
    type: "object",
    nullable: true,
    properties: {
      value: {
        type: ["string", "null"],
        enum: ["Married", "Unmarried", "Separated", null],
        errorMessage: {
          enum: "should be one of the following: 'Married', 'Unmarried', 'Separated'",
        },
      },
    },
    additionalProperties: true,
  },
  relationshipToPrimary: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string" },
    },
    additionalProperties: true,
  },
  dob: {
    type: "object",
    nullable: true,
    properties: {
      value: {
        type: "string",
        format: "age-restriction",
        errorMessage: {
          format: "should be a date between the age of 18 and 100.",
        },
      },
    },
    additionalProperties: true,
  },
  ssn: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string" },
    },
    additionalProperties: true,
  },
  homePhone: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string", nullable: true },
    },
    additionalProperties: true,
  },
  mobilePhone: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string", nullable: true },
    },
    additionalProperties: true,
  },
  emailAddress: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string", nullable: true },
    },
    additionalProperties: true,
  },
  address: {
    type: "object",
    nullable: true,
    properties: addressData,
  },
  previousAddress: {
    type: "object",
    nullable: true,
    properties: addressData,
  },
  employment: employmentData,
  previousEmployment: employmentData,
  otherIncome: {
    type: "object",
    nullable: true,
    properties: {
      classification: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: ["string", "null"],
            enum: [
              "Alimony",
              "Bonus",
              "ChildSupport",
              "Disability",
              "FosterCare",
              "Investment",
              "Miscellaneous",
              "Other",
              "Pension",
              "Rental",
              "SSI",
              "SelfEmployed",
              "SocialSecurity",
              "Unemployment",
              "VaDisability",
              "Welfare",
              "WorksmenComp",
              null,
            ],
            errorMessage: {
              enum: `
              should be one of the following:
              'Alimony', 'Bonus', 'ChildSupport',
              'Disability', 'FosterCare', 'Investment',
              'Miscellaneous', 'Other', 'Pension',
              'Rental', 'SSI', 'SelfEmployed',
              'SocialSecurity', 'Unemployment',
              'VaDisability', 'Welfare', 'WorksmenComp'
              `,
            },
          },
        },
        additionalProperties: true,
      },
      description: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string", nullable: true },
        },
        additionalProperties: true,
      },
      monthlyGross: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string", nullable: true },
        },
        additionalProperties: true,
      },
    },
  },
  driversLicense: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string", nullable: true },
    },
    additionalProperties: true,
  },
};

export default {
  date: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string", nullable: true },
    },
    additionalProperties: true,
  },
  branch: {
    type: "object",
    nullable: true,
    additionalProperties: true,
    properties: {
      name: {
        type: "object",
        properties: {
          value: { type: "string", nullable: true },
        },
        nullable: true,
        additionalProperties: true,
      },
      address: {
        type: "object",
        properties: addressData,
        additionalProperties: true,
      },
      phone: {
        type: "object",
        properties: {
          value: { type: "string", nullable: true },
        },
        nullable: true,
        additionalProperties: true,
      },
      fax: {
        type: "object",
        properties: {
          value: { type: "string", nullable: true },
        },
        nullable: true,
        additionalProperties: true,
      },
      email: {
        type: "object",
        properties: {
          value: { type: "string", nullable: true },
        },
        nullable: true,
        additionalProperties: true,
      },
      creditsmartsId: {
        type: "object",
        properties: {
          value: { type: "string", nullable: true },
        },
        nullable: true,
        additionalProperties: true,
      },
    },
  },
  applicationNumber: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string" },
    },
    additionalProperties: true,
  },
  applicationNumberOriginal: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string" },
    },
    additionalProperties: true,
  },
  loanNumberOriginal: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string" },
    },
    additionalProperties: true,
  },
  bidNumberOriginal: {
    type: "object",
    nullable: true,
    properties: {
      value: { type: "string" },
    },
    additionalProperties: true,
  },
  applicationType: {
    type: "object",
    properties: {
      value: { type: "string", nullable: true },
      applicants: { type: "string", nullable: true },
    },
  },
  loanWorksheet: {
    type: "object",
    properties: {
      deposit: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not contain letters" },
          },
          additionalProperties: true,
        },
      },
      smogCertifyFee: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not contain letters" },
          },
          additionalProperties: true,
        },
      },
      tradeInNet: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not contain letters" },
          },
          additionalProperties: true,
        },
      },
      warranty: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not contain letters" },
          },
          additionalProperties: true,
        },
      },
      residual: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not contain letters" },
          },
          additionalProperties: true,
        },
      },
      warrantyTerm: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not contain letters" },
          },
          additionalProperties: true,
        },
      },
      purchaseType: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: ["string", "null"],
            nullable: true,
            enum: ["PURCHASE", "REFINANCE", "LEASE"],
            errorMessage: {
              enum: "must be one of the following: 'PURCHASE', 'REFINANCE', 'LEASE'",
            },
          },
        },
        additionalProperties: true,
      },
      estimatedPayment: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not include letters" },
          },
        },
        additionalProperties: true,
      },
      totalLoanAmount: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not include letters" },
          },
        },
        additionalProperties: true,
      },
      salesTaxRate: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not include letters" },
          },
        },
        additionalProperties: true,
      },
      tax: {
        anyOf: [
          {
            type: "array",
            nullable: true,
            items: {
              type: "object",
              properties: {
                value: {
                  type: "string",
                  nullable: true,
                  pattern: CustomRegexes.DECIMAL_STRING,
                  errorMessage: { pattern: "should not include letters" },
                },
              },
              additionalProperties: true,
            },
          },
          {
            type: "object",
            nullable: true,
            properties: {
              value: {
                type: "string",
                nullable: true,
                pattern: CustomRegexes.DECIMAL_STRING,
                errorMessage: { pattern: "should not include letters" },
              },
            },
            additionalProperties: true,
          },
        ],
      },
      downPayment: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not include letters" },
          },
        },
        additionalProperties: true,
      },
      license: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string", nullable: true },
        },
        additionalProperties: true,
      },
      misc: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not include letters" },
          },
        },
        additionalProperties: true,
      },
      misc2: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not include letters" },
          },
        },
        additionalProperties: true,
      },
      smogSellerFee: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not include letters" },
          },
        },
        additionalProperties: true,
      },
      dueAtSigning: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not include letters" },
          },
        },
        additionalProperties: true,
      },
      purchasePrice: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not include letters" },
          },
        },
        additionalProperties: true,
      },
      gap: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not include letters" },
          },
        },
        additionalProperties: true,
      },
      docFee: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not include letters" },
          },
        },
        additionalProperties: true,
      },
      lease: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            enum: ["0", "1", "false", "true"],
            errorMessage: {
              enum: "must be one of the following: '0', '1', 'false', 'true'",
            },
          },
        },
        additionalProperties: true,
      },
      paymentType: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: ["string", "null"],
            nullable: true,
            enum: ["DEBIT", "INVOICE", "UNSPECIFIED", null],
            errorMessage: {
              enum: "must be one of the following: 'DEBIT', 'INVOICE', 'UNSPECIFIED'",
            },
          },
        },
        additionalProperties: true,
      },
      anticipatedRate: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not include letters" },
          },
        },
        additionalProperties: true,
      },
      termRequested: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not include letters" },
          },
        },
        additionalProperties: true,
      },
    },
  },
  housing: {
    type: "object",
    nullable: true,
    properties: {
      own: {
        type: ["string", "null"],
        enum: ["0", "1", "false", "true", null],
        errorMessage: {
          enum: "must be one of the following: '0', '1', 'false', 'true'",
        },
        nullable: true,
      },
      payment: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            nullable: true,
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "must not contain letters" },
          },
        },
        additionalProperties: true,
      },
      homeOwner: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: ["string", "null"],
            enum: ["1", "0", "true", "false", null],
            errorMessage: {
              enum: "must be one of the following: '0', '1', 'false', 'true'",
            },
          },
        },
        additionalProperties: true,
      },
      owedTo: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string", nullable: true },
        },
        additionalProperties: true,
      },
    },
  },
  liquidAssets: {
    type: "object",
    properties: {
      value: { type: "string", nullable: true },
    },
    additionalProperties: true,
  },
  comments: {
    type: "object",
    properties: {
      value: { type: "string", nullable: true },
    },
    additionalProperties: true,
  },
  dealer: {
    type: "object",
    properties: {
      dealerType: {
        type: "object",
        properties: {
          value: { type: "string", nullable: true },
        },
        additionalProperties: true,
      },
      contact: {
        type: "object",
        properties: {
          value: { type: "string", nullable: true },
        },
        additionalProperties: true,
      },
      name: {
        type: "object",
        properties: {
          value: { type: "string" },
        },
        additionalProperties: true,
      },
      address: {
        type: "object",
        properties: addressData,
      },
      email: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string" },
        },
        additionalProperties: true,
      },
      phone: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string" },
        },
        additionalProperties: true,
      },
      fax: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string" },
        },
        additionalProperties: true,
      },
      creditsmartsId: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string" },
        },
        additionalProperties: true,
      },
    },
  },

  applicant: {
    type: "object",
    properties: applicantData,
  },

  coapplicant: {
    type: "object",
    nullable: true,
    properties: applicantData,
  },

  purchaseVehicle: {
    type: "object",
    properties: {
      year: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            pattern: CustomRegexes.NUMBERS,
            errorMessage: { pattern: "should not contain letters" },
            nullable: true,
          },
        },
        additionalProperties: true,
      },
      make: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string", nullable: true },
        },
        additionalProperties: true,
      },
      model: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string", nullable: true },
        },
        additionalProperties: true,
      },
      vin: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string", nullable: true },
        },
        additionalProperties: true,
      },
      mileage: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            pattern: CustomRegexes.NUMBERS,
            errorMessage: { pattern: "should not contain letters" },
            nullable: true,
          },
        },
        additionalProperties: true,
      },
      description: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string" },
          nullable: true,
        },
        additionalProperties: true,
      },
      new: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: ["string", "null"],
            enum: ["1", "0", "false", "true", null],
            errorMessage: {
              enum: "must be one of the following: '0', '1', 'false', 'true'",
            },
            nullable: true,
          },
        },
        additionalProperties: true,
      },
      type: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: ["string", "null"],
            enum: [
              "CAR",
              "TRUCK",
              "SUV",
              "MOTORCYCLE",
              "RV",
              "WATERCRAFT",
              "ATV",
              null,
            ],
            errorMessage: {
              enum: "must be one of the following: 'CAR', 'TRUCK', 'SUV', 'MOTORCYCLE', 'RV', 'WATERCRAFT', 'ATV'",
            },
            nullable: true,
          },
        },
        additionalProperties: true,
      },
      bookValue: {
        type: "object",
        nullable: true,
        properties: {
          source: {
            type: "object",
            nullable: true,
            properties: {
              value: { type: "string", nullable: true },
            },
            additionalProperties: true,
          },
          wholesale: {
            type: "object",
            nullable: true,
            properties: {
              value: { type: "string", nullable: true },
            },
            additionalProperties: true,
          },
          wholesaleAdjusted: {
            type: "object",
            nullable: true,
            properties: {
              value: { type: "string", nullable: true },
            },
            additionalProperties: true,
          },
          retail: {
            type: "object",
            nullable: true,
            properties: {
              value: { type: "string", nullable: true },
            },
            additionalProperties: true,
          },
          retailAdjusted: {
            type: "object",
            nullable: true,
            properties: {
              value: { type: "string", nullable: true },
            },
            additionalProperties: true,
          },
        },
      },
    },
  },
  tradeIn: {
    type: "object",
    nullable: true,
    properties: {
      vin: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string", nullable: true },
        },
        additionalProperties: true,
      },
      mileage: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            pattern: CustomRegexes.NUMBERS,
            errorMessage: { pattern: "should not contain letters" },
            nullable: true,
          },
        },
        additionalProperties: true,
      },
      creditor: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string", nullable: true },
        },
        additionalProperties: true,
      },
      debt: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not contain letters" },
            nullable: true,
          },
        },
        additionalProperties: true,
      },
      allowance: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not contain letters" },
            nullable: true,
          },
        },
        additionalProperties: true,
      },
      payment: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            pattern: CustomRegexes.DECIMAL_STRING,
            errorMessage: { pattern: "should not contain letters" },
            nullable: true,
          },
        },
        additionalProperties: true,
      },

      year: {
        type: "object",
        nullable: true,
        properties: {
          value: {
            type: "string",
            pattern: CustomRegexes.NUMBERS,
            errorMessage: { pattern: "should not contain letters" },
            nullable: true,
          },
        },
        additionalProperties: true,
      },
      make: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string", nullable: true },
        },
        additionalProperties: true,
      },
      model: {
        type: "object",
        nullable: true,
        properties: {
          value: { type: "string", nullable: true },
        },
        additionalProperties: true,
      },
    },
  },
};
