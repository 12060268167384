import {
  generateContractForPrintActions,
  GenerateContractForPrintResponse
} from "components/Deals/Documents/generateContractForPrintSlice";
import { transformOldTopicToAxiosRequest } from "components/Middlewares/SocketMiddleware";
import { makeHttpRequest } from "./common";

export const generateContractForPrint = async (
  request: any
): Promise<
  | {
      result: GenerateContractForPrintResponse;
      status: "success" | "error";
    }
  | undefined
> => {
  const action = generateContractForPrintActions("", request);
  if (action?.http) {
    const request = transformOldTopicToAxiosRequest(action.http.path, action.payload);
    //@ts-ignore
    return await makeHttpRequest(request, action);
  } else {
    return new Promise(() => undefined);
  }
};
