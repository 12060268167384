import Big from "big.js";
import React, { useContext, useState } from "react";
import InfoIcon from "@material-ui/icons/Info";
import {
  Grid,
  Button,
  Dialog,
  Tooltip,
  Typography,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent
} from "@material-ui/core";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

import { getFirstAndLastDateOfAYear } from "utils/functions";
import { StateAccess, RenderSet } from "utils/models/formGenerator";
import CloseDialogButton from "components/common/CloseDialogButton";
import { Lender, OperatesInStateStatus } from "components/Lenders/types";
import { useLenderCreditStatisticsInDeal } from "hooks/useLenderDecisionStatistics/useLenderDecisionStatistics";

import { Deal } from "../types";
import { getCreditBureausScore } from "../model";
import formEditContext from "components/Content/FormEditContext";

interface Props<T> {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}

export default function LenderSuggestions<T extends Deal>({ stateAccess, renderSet }: Props<T>) {
  const deal: Deal = stateAccess.get([]);
  const { enabled: editMode } = useContext(formEditContext);
  const creditScore = getCreditBureausScore(deal, "applicant")?.score;
  const collateralType = deal?.data?.info?.type;
  const state = deal?.data?.applicant?.data?.info?.currentState;
  const county = deal?.data?.applicant?.data?.info?.currentCounty;
  const lenderPrediction = deal?.data?.info?.lenderPrediction;

  const [open, setOpen] = useState(false);
  const { from, to } = getFirstAndLastDateOfAYear(new Date().getFullYear());
  const { data } = useLenderCreditStatisticsInDeal({
    creditScore: creditScore ? Number(creditScore) : 0,
    state,
    county,
    collateralType,
    from,
    to
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSelect = (lender: Lender) => {
    stateAccess.set(["data", "lender"], lender);
    handleClose();
  };

  if (data && data.lenders.length)
    return (
      <div style={{ position: "absolute", top: "-48px", left: "65px" }}>
        <Tooltip arrow title={"Suggested lenders"} color="primary" placement="top">
          <IconButton onClick={handleOpen}>
            <InfoIcon fontSize="medium" style={{ color: "#254e70" }} />
          </IconButton>
        </Tooltip>

        <Dialog fullWidth onClose={handleClose} open={open}>
          <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
            <Grid item xs={6} key="title">
              <DialogTitle>
                Lender Suggestions
                <CloseDialogButton closeFunction={handleClose} />
              </DialogTitle>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container style={{}}>
              <Typography style={{ fontSize: 15, fontWeight: 400, marginBottom: 5 }}>
                Lenders categorized by credit score, state, collateral type, and historical data.
              </Typography>
              {data.lenders
                ?.sort((a, b) => b?.statistics?.total - a?.statistics?.total)
                ?.map((lender) => {
                  const prediction = lenderPrediction?.[lender.data.info.name];
                  const lookedToApproved = lender?.statistics?.total
                    ? new Big(lender?.statistics?.approvedTotal || 0)
                        .div(new Big(lender?.statistics?.total))
                        .mul(new Big(100))
                        .toNumber()
                    : 0;
                  return (
                    <Grid
                      item
                      key={lender._id}
                      container
                      style={{
                        alignItems: "center",
                        gap: 5,
                        margin: "5px 0px",
                        padding: 5,
                        justifyContent: "space-between",
                        borderRadius: 6,
                        border: "1px solid #254e70"
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 500
                        }}
                      >
                        {`${lender.data.info.name}`}
                        {lender.statistics.status === OperatesInStateStatus.ACTIVE && (
                          <>
                            <VerifiedUserIcon fontSize="inherit" style={{ color: "#52a543" }} />
                            (Active)
                          </>
                        )}
                        {" - "}
                        <span
                          style={{
                            color:
                              lookedToApproved >= 40
                                ? "#006400"
                                : lookedToApproved === 0
                                ? "#8B0000"
                                : "#FF8C00"
                          }}
                        >
                          {`Looked to Approved ${lookedToApproved.toFixed(2)}%`}
                        </span>
                      </Typography>
                      {prediction && (
                        <Typography
                          color="primary"
                          style={{
                            fontSize: "13x",
                            fontWeight: "bold"
                          }}
                        >
                          Prediction:{" "}
                          <span
                            style={{
                              color:
                                prediction?.["softmax_decision.label"] === "approved"
                                  ? "green"
                                  : "red"
                            }}
                          >
                            {(prediction?.softmax_decision_probability * 100).toFixed(2)}%
                          </span>{" "}
                          {prediction?.["softmax_decision.label"] === "approved"
                            ? "approval chance"
                            : "chance of not being approved."}
                        </Typography>
                      )}
                      {editMode && (
                        <Button color="primary" onClick={() => handleSelect(lender)}>
                          Select
                        </Button>
                      )}
                    </Grid>
                  );
                })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  else return null;
}
