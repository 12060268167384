import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { RootState } from "app/rootReducer";
import formEditContext from "components/Content/FormEditContext";
import { HintTooltip } from "components/common/HintTooltip";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { CustomerCareNote } from "./types";

import { Help, Warning } from "@material-ui/icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelCircleIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MailIcon from "@material-ui/icons/Mail";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CloseDialogButton from "components/common/CloseDialogButton";
import { useNotifyCustomerCareNotes } from "hooks/useNotifyDealerEmails/useNotifyDealerEmails";
import formatDate from "utils/formatDate";
import {
  getCustomerCareNotesList,
  listCustomerCareNotesActions
} from "./listCustomerCareNoteSlice";
import { editCustomerCareNote } from "./editCustomerCareNoteSlice";
import { addCustomerCareNote } from "./addCustomerCareNoteSlice";
import { deleteCustomerCareNote } from "./deleteCustomerCareNoteSlice";

interface Props {
  customerCareNotes: CustomerCareNote[];
  dealershipId: string;
  repEmail: string | undefined;
  editable?: boolean;
}
export default function DealerNotesPreview({
  customerCareNotes,
  dealershipId,
  repEmail,
  editable = false
}: Props): JSX.Element {
  const dispatch = useDispatch();

  const [addRequestId] = React.useState(uuidv4());
  const [deleteRequestId] = React.useState(uuidv4());
  const [deleteDialog, setDeleteDialog] = React.useState<{
    open: Boolean;
    note: CustomerCareNote | undefined;
  }>({ open: false, note: undefined });
  const [timeout, setTimeoutState] = React.useState<undefined | NodeJS.Timeout>();

  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);

  const addCustomerCareNoteState = useSelector(
    (state: RootState) => state.addCustomerCareNoteSlice[addRequestId]
  );

  const deleteCustomerCareNoteState = useSelector(
    (state: RootState) => state.deleteCustomerCareNoteSlice[deleteRequestId]
  );

  const listCustomerCareNoteState = useSelector(
    (state: RootState) => state.listCustomerCareNoteSlice[dealershipId]
  );
  const allEditCustomerCareNoteStates = useSelector(
    (state: RootState) => state.editCustomerCareNoteSlice
  );
  const editCustomerCareNoteStates = listCustomerCareNoteState?.entities?.map((note) => ({
    _id: note._id,
    state: allEditCustomerCareNoteStates[note._id]
  }));
  const isLoading =
    addCustomerCareNoteState?.status === "waiting" ||
    deleteCustomerCareNoteState?.status === "waiting";

  const { enabled: editMode } = React.useContext(formEditContext);

  const canEdit = editMode || editable;
  const {
    notifyCustomerCareNotes,
    loading: notifyCustomerCareNotesLoading
  } = useNotifyCustomerCareNotes();
  React.useEffect(() => {
    if (!listCustomerCareNoteState && canEdit)
      dispatch(
        getCustomerCareNotesList(dealershipId, {
          query: { deleted: false, "data.dealershipId": dealershipId },
          options: { sort: { createdAt: -1 }, limit: 99999999 }
        })
      );
  }, [dispatch, canEdit]);

  React.useEffect(() => {
    if (
      deleteCustomerCareNoteState !== undefined &&
      deleteCustomerCareNoteState.status === "success" &&
      deleteCustomerCareNoteState.data !== null
    ) {
      const index = listCustomerCareNoteState.entities?.findIndex(
        (n: CustomerCareNote) => n._id === deleteCustomerCareNoteState?.data?.message?._id
      );
      dispatch({
        type: listCustomerCareNotesActions.editList.type,

        payload: {
          index: index,
          payload: deleteCustomerCareNoteState?.data?.message,
          listId: dealershipId
        }
      });
    }
  }, [deleteCustomerCareNoteState, dispatch]);
  const handleNotifyRepEmail = () => {
    const notes = (listCustomerCareNoteState?.entities ?? customerCareNotes)
      .filter((n) => !n.deleted && !n.data.info.sentToRepEmail)
      .map((n) => n._id);
    if (notes?.length && repEmail) {
      notifyCustomerCareNotes({
        customerCareNotesIds: notes,
        email: repEmail
      });
    }
  };
  const handleAdd = () => {
    const defaultCustomerCareNote = {
      data: {
        dealershipId: dealershipId,
        info: {
          note: "",
          user: {
            firstName: currentUser?.data?.info?.firstName || null,
            lastName: currentUser?.data?.info?.lastName || null,
            email: currentUser?.data?.info?.email || null
          }
        }
      }
    };
    dispatch(
      addCustomerCareNote({
        requestId: addRequestId,
        ...(defaultCustomerCareNote as CustomerCareNote)
      })
    );
  };

  const handleDelete = (customerCareNote: CustomerCareNote) => {
    dispatch(deleteCustomerCareNote({ requestId: deleteRequestId, _id: customerCareNote._id }));
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    customerCareNote: CustomerCareNote & { index: number }
  ) => {
    const newCustomerCareNote = {
      ...customerCareNote,
      data: {
        ...customerCareNote.data,
        info: { ...customerCareNote.data.info, note: event.target.value }
      },
      updatedAt: new Date().toISOString()
    };
    dispatch({
      type: listCustomerCareNotesActions.editList.type,

      payload: {
        index: customerCareNote.index,
        payload: newCustomerCareNote,
        listId: dealershipId
      }
    });
    return newCustomerCareNote;
  };
  const handleUpdate = (customerCareNote: CustomerCareNote) => {
    dispatch(editCustomerCareNote({ requestId: customerCareNote._id, ...customerCareNote }));
  };
  const renderEntities = () => {
    return (listCustomerCareNoteState?.entities ?? customerCareNotes)
      .map((n, index) => ({ ...n, index }))
      .filter((n) => !n.deleted)
      .sort((a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime())
      ?.map((customerCareNote, index) => {
        const state = editCustomerCareNoteStates?.find(
          (state) => state._id === customerCareNote._id
        )?.state;

        return (
          <Paper
            key={index}
            style={{
              padding: "10px",
              marginBottom: "20px",
              display: "block",
              background: state?.status === "error" ? "#ffe3e3" : undefined
            }}
          >
            <Box display="flex" alignContent="flex-start">
              <Box flexGrow={1}>
                <TextField
                  multiline
                  onChange={(event) => {
                    if (timeout) {
                      clearTimeout(timeout);
                    }
                    const newNote = handleChange(event, customerCareNote);
                    setTimeoutState(
                      setTimeout(() => {
                        handleUpdate(newNote);
                      }, 500)
                    );
                  }}
                  value={customerCareNote?.data?.info?.note}
                  name="customerCareNote"
                  inputProps={
                    canEdit && listCustomerCareNoteState?.status !== "waiting"
                      ? {}
                      : {
                          readOnly: true
                        }
                  }
                  placeholder="Note"
                  fullWidth
                  label={"Note"}
                  variant={
                    canEdit && listCustomerCareNoteState?.status !== "waiting"
                      ? "filled"
                      : "outlined"
                  }
                  size="small"
                  onBlur={(event) => {
                    if (event.target.value !== customerCareNote?.data?.info?.note) {
                      const newNote = handleChange(event, customerCareNote);
                      handleUpdate(newNote);
                    }
                  }}
                />
                <Box
                  fontSize={12}
                  justifyContent="space-between"
                  display={"flex"}
                  marginTop={"10px"}
                  style={{ maxHeight: 20 }}
                >
                  <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                    {(() => {
                      switch (state?.status) {
                        case "waiting":
                          return (
                            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                              <CircularProgress
                                size={17}
                                style={{
                                  color: "#50A538",
                                  // marginRight: 5,
                                  minHeight: 20.5,
                                  maxHeight: 20.5
                                }}
                              />
                              Saving
                            </div>
                          );
                        case "error":
                          return (
                            <button
                              className="pulse hover"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                border: "none",
                                background: "#ffe3e3",
                                fontWeight: "bold",
                                columnGap: "3px",
                                borderRadius: 3
                              }}
                              onClick={() => {
                                handleUpdate(customerCareNote);
                              }}
                            >
                              <CancelCircleIcon
                                style={{
                                  color: "#E34C28"
                                }}
                              />
                              Error! Click here to retry!
                            </button>
                          );
                        default:
                          return (
                            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                              <CheckCircleIcon
                                style={{
                                  color: "#50A538"
                                }}
                              />
                              Saved
                            </div>
                          );
                      }
                    })()}
                    {(() => {
                      if (
                        notifyCustomerCareNotesLoading &&
                        !customerCareNote?.data?.info?.sentToRepEmail
                      ) {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <CircularProgress
                              size={17}
                              style={{
                                color: "#50A538",
                                minHeight: 20.5,
                                maxHeight: 20.5
                              }}
                            />
                            Sending
                          </div>
                        );
                      }
                      if (customerCareNote?.data?.info?.sentToRepEmail) {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <MailIcon
                              style={{
                                color: "rgb(37, 78, 110)"
                              }}
                            />
                            Sent email at{" "}
                            {formatDate(customerCareNote?.data?.info?.sentToRepEmailDate, "short")}
                          </div>
                        );
                      } else {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <MailIcon
                              style={{
                                color: "#E34C28"
                              }}
                            />
                            Not sent to rep
                          </div>
                        );
                      }
                    })()}
                  </div>
                  {[
                    customerCareNote.data?.info?.user?.firstName,
                    customerCareNote?.data?.info?.user?.lastName,
                    formatDate(customerCareNote?.createdAt, "medium", true)
                  ]
                    .filter((x) => x)
                    .join(" ")}
                </Box>
              </Box>
              {canEdit && (
                <Box>
                  <IconButton
                    style={{
                      color: "#E34C28"
                    }}
                    key={index}
                    aria-label={`remove customer care note`}
                    onClick={() => setDeleteDialog({ open: true, note: customerCareNote })}
                    disabled={deleteCustomerCareNoteState?.status === "waiting"}
                  >
                    <HintTooltip title={`Click here to remove the customer care note.`}>
                      <RemoveCircleIcon />
                    </HintTooltip>
                  </IconButton>
                </Box>
              )}
            </Box>
          </Paper>
        );
      });
  };
  const cantSendEmail =
    notifyCustomerCareNotesLoading ||
    editCustomerCareNoteStates?.some((state) => state?.state?.status === "waiting");

  return (
    <Paper
      elevation={0}
      style={{
        breakInside: "avoid",
        pageBreakInside: "avoid",
        transform: "translateZ(1)"
      }}
    >
      <Box style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <DialogContentText
          style={{
            color: "#254e6e",
            fontSize: "19px",
            margin: "5px 0px 5px 0px",

            fontWeight: "bold"
          }}
        >
          Customer Care Notes (Internal)
        </DialogContentText>
        <HintTooltip
          style={{ color: "#254e6e", fontSize: "20px", marginLeft: 4 }}
          title={
            <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
              Autosaved
            </div>
          }
        >
          <Help />
        </HintTooltip>

        {canEdit && (
          <>
            <IconButton
              style={{
                color: "#50A538"
              }}
              aria-label={`add new customer care note`}
              onClick={handleAdd}
              disabled={isLoading}
            >
              <HintTooltip title={`Click here to add new customer care note.`}>
                {isLoading ? <CircularProgress size={19} /> : <AddCircleIcon />}
              </HintTooltip>
            </IconButton>
            <HintTooltip
              title={
                <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
                  You should first select a representative in order to send the notes
                </div>
              }
            >
              <Button
                variant="outlined"
                style={{ fontSize: "10px", marginLeft: "auto", padding: 5, height: 30 }}
                startIcon={(!repEmail || cantSendEmail) && <Warning style={{ color: "#eed202" }} />}
                onClick={!repEmail || cantSendEmail ? undefined : handleNotifyRepEmail}
              >
                Send notes to rep
              </Button>
            </HintTooltip>
          </>
        )}
      </Box>

      {renderEntities()}
      {deleteDialog.open && (
        <Dialog
          id="delete-customer-care-note-dialog"
          open={true}
          onClose={() => setDeleteDialog({ open: false, note: undefined })}
        >
          <DialogTitle id="delete-customer-care-note-dialog-title">
            <CloseDialogButton
              closeFunction={() => setDeleteDialog({ open: false, note: undefined })}
            />
            <Typography variant="h6">Delete customer care note</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle2" style={{ fontSize: "14px" }}>
              Are you sure you want to delete this customer care note?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDeleteDialog({ open: false, note: undefined })}
              variant="contained"
              color="primary"
              id="cancel"
            >
              Close
            </Button>
            <Button
              onClick={() => {
                if (deleteDialog.note) {
                  handleDelete(deleteDialog.note);
                  setDeleteDialog({ open: false, note: undefined });
                }
              }}
              variant="contained"
              autoFocus
              color="secondary"
              id="confirm"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  );
}
