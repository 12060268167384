import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import {
  EditManagerProgramsRequest,
  EditManagerProgramsRequestSend,
  ManagerProgram
} from "./types";

interface EditManagerProgramResponse {
  message: ManagerProgram;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditManagerProgramsRequestSend,
  EditManagerProgramResponse
>("EditManagerProgram", {
  request: "update_manager_program"
});

export const editManagerProgramActions = socketMessageSlice.actions;
export const editManagerProgram = (request: EditManagerProgramsRequest & Identifiable) => {
  console.log(request);
  return socketAction(request);
};
export default socketMessageSlice.reducer;
