import info_properties from "./info_properties";
import notes from "../common/notes_schema";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import { Schema } from "ajv";

const unrequiredExternalId = { type: "string", nullable: true };
const requiredExternalId = { type: "string" };

const data: Schema = {
  type: "object",
  required: ["info", "dealId"],
  properties: {
    info: { type: "object", properties: info_properties },
    dealId: requiredExternalId,
    userId: unrequiredExternalId,
    notes: notes,
  },
};

export default {
  new_title_issue: {
    $id: "newTitleIssue",
    type: "object",
    required: ["data"],
    properties: {
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_title_issue: {
    $id: "updateTitleIssue",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  delete_title_issue: {
    $id: "deleteTitleIssue",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  recover_title_issue: {
    $id: "recoverTitleIssue",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  get_title_issue: {
    $id: "getTitleIssue",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  all_title_issues: {
    $id: "allTitleIssues",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
