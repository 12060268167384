import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverPayoffBankRequest {
  _id: string;
}

export interface RecoverPayoffBankResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverPayoffBankRequest,
  RecoverPayoffBankResponse
>("RecoverPayoffBank", {
  request: "recover_payoff_bank"
});

export const recoverPayoffBankActions = socketMessageSlice.actions;
export const recoverPayoffBank = socketAction;
export default socketMessageSlice.reducer;
