import upload_info_properties from "./upload_info_properties";
import info_properties from "./info_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

const data = {
  type: "object",
  properties: {
    info: { type: "object", properties: info_properties },
    possibleDeals: {
      type: "array",
      nullable: true,
      items: {
        type: "object",
        properties: {
          dealId: { type: "string", nullable: true },
          refNumber: { type: "string", nullable: true },
        },
      },
    },
    dealId: { type: "string", nullable: true },
    refNumber: { type: "string", nullable: true },
  },
};

const uploadData = {
  type: "object",
  properties: {
    info: { type: "object", properties: upload_info_properties },
    dealId: { type: "string", nullable: true },
    refNumber: { type: "string", nullable: true },
  },
};

export default {
  new_lender_decision: {
    $id: "newLenderDecision",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  new_lender_decisions: {
    $id: "newLenderDecisions",
    type: "object",
    required: ["data"],
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          required: ["data"],
          properties: {
            data: uploadData,
            deleted: {
              type: "boolean",
            },
          },
        },
      },
    },
  },
  update_lender_decision: {
    $id: "updateLenderDecision",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      type: { type: "string", nullable: true },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      approved: {
        type: "boolean",
        nullable: true,
      },
      approvedAt: {
        type: "string",
        nullable: true,
      },
      approvedByUserId: {
        type: "string",
        nullable: true,
      },
    },
  },
  delete_lender_decision: {
    $id: "deleteLenderDecision",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  recover_lender_decision: {
    $id: "recoverLenderDecision",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_lender_decision: {
    $id: "getLenderDecision",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_lender_decisions: {
    $id: "allLenderDecisions",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
