import {
  CommonAttributes,
  CommonFields,
  Behaviors,
  PayloadAttributes,
  Relationships
} from "../common";

export type PersonaVerificationResponseData = {
  type: VerificationType;
  id: string;
  attributes?: PayloadAttributes<"verification">;
  relationships?: Pick<
    Relationships,
    | "inquiry"
    | "template"
    | "inquiry_template_version"
    | "inquiry_template"
    | "verification_template"
    | "verification_template_version"
  >;
};

export type VerificationPayloadAttributes = CommonAttributes & {
  status: VerificationStatus;
  checks?: VerificationCheck[];
  fields?: Pick<
    CommonFields,
    | "address_street_1"
    | "address_street_2"
    | "address_city"
    | "address_country_code"
    | "address_postal_code"
    | "address_subdivision"
    | "birthdate"
    | "email_address"
    | "identification_number"
    | "identification_class"
    | "name_first"
    | "name_middle"
    | "name_last"
    | "phone_number"
    | "social_security_number"
    | "selected_country_code"
  >;
  previous_step_name: string | null;
  next_step_name: string | null;
  behaviors?: Behaviors;
};
export type VerificationCheck = {
  name: VerificationCheckName;
  status: VerificationCheckStatus;
  reasons: string[];
  requirement: VerificationCheckRequirement;
  metadata: Metadata;
};
export type Metadata = {
  check_requirements: CheckRequirement[];
  match_result?: MatchResult;
};
export type CheckRequirement = {
  name: string;
  match_result: MatchResult;
  status: VerificationCheckStatus;
  comparisons: Comparison[];
};

export type Comparison = {
  type: ComparisonType;
  match_level_minimum: MatchResult;
  match_result: MatchResult;
};
export enum MatchResult {
  Full = "full",
  Partial = "partial",
  None = "none"
}
export enum ComparisonType {
  String_Similarity = "string_similarity",
  String_Difference = "string_difference",
  Substring = "substring",
  Tokenization = "tokenization"
}
export enum VerificationCheckRequirement {
  Required = "required",
  Not_Required = "not_required"
}
export enum VerificationStatus {
  Passed = "passed",
  Confirmed = "confirmed",
  Submitted = "submitted",
  Initiated = "initiated",
  Requires_Retry = "requires_retry",
  Failed = "failed",
  Skipped = "skipped",
  Canceled = "canceled"
}
export enum VerificationName {
  Verification_Passed = "verification.passed",
  Verification_Created = "verification.created",
  Verification_Submitted = "verification.submitted",
  Verification_Failed = "verification.failed",
  Verification_Initiated = "verification.initiated",
  Verification_Requires_Retry = "verification.requires_retry",
  Verification_Skipped = "verification.skipped",
  Verification_Canceled = "verification.canceled"
}
export enum VerificationCheckName {
  Database_Identity_Comparison = "database_identity_comparison",
  Database_Deceased_Detection = "database_deceased_detection",
  Database_Po_Box_Detection = "database_po_box_detection",
  Database_Address_Residential_Detection = "database_address_residential_detection",
  Database_Address_Deliverable_Detection = "database_address_deliverable_detection",
  Database_Inquiry_Comparison = "database_inquiry_comparison",
  AAMVA_Identification_Number_Comparison = "aamva_identification_number_comparison",
  AAMVA_Name_Comparison = "aamva_name_comparison",
  AAMVA_Birthdate_Comparison = "aamva_birthdate_comparison",
  AAMVA_Address_Comparison = "aamva_address_comparison",
  AAMVA_Issue_Date_Comparison = "aamva_issue_date_comparison",
  AAMVA_Expiration_Date_Comparison = "aamva_expiration_date_comparison"
}
export enum VerificationCheckStatus {
  Passed = "passed",
  Failed = "failed",
  Not_Applicable = "not_applicable"
}
export enum VerificationType {
  Verification_Database = "verification/database",
  Verification_AAMVA = "verification/aamva"
}
