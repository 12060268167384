import { IconButton, Box } from "@material-ui/core";
import React from "react";
import { StateAccess, RenderSet, generateForm } from "utils/models/formGenerator";
import { Applicant } from "./types";
import { FormComponent } from "utils/models/fields";
import RedoIcon from "@material-ui/icons/Redo";
import { HintTooltip } from "components/common/HintTooltip";

export const HomePhone: FormComponent<Applicant> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "phone-field",
      name: "homePhone",
      label: "Home phone",
      width: "full",
      path: ["data", "info", "homePhone"],
      default: null
    }
  ]
};
const fillInHomePhone = (stateAccess: StateAccess, aplicant: Applicant) => {
  stateAccess.set<Applicant>(["data", "info", "homePhone"], aplicant.data?.info?.mobilePhone);
};

export default function PhoneFields({
  stateAccess,
  renderSet
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) {
  const applicantInfo = stateAccess.get<Applicant>([] as any);
  return (
    <>
      <Box display="flex">
        <Box style={{ padding: "5px 2px 5px 0px" }}>
          <IconButton
            size="small"
            onClick={() => {
              fillInHomePhone(stateAccess, applicantInfo);
            }}
          >
            <HintTooltip title="Same as the mobile phone">
              <RedoIcon />
            </HintTooltip>
          </IconButton>
        </Box>

        <Box flexGrow={1}>{generateForm(HomePhone, stateAccess, [], stateAccess, renderSet)}</Box>
      </Box>
    </>
  );
}
