import {
  SendGeneratedContractResponse,
  sendGeneratedContractActions
} from "components/Deals/Documents/sendGeneratedContractSlice";
import { transformOldTopicToAxiosRequest } from "components/Middlewares/SocketMiddleware";
import { makeHttpRequest } from "./common";

export const sendGeneratedContract = async (
  request: any
): Promise<
  | {
      result: SendGeneratedContractResponse;
      status: "success" | "error";
    }
  | undefined
> => {
  const action = sendGeneratedContractActions("", request);
  if (action?.http) {
    const request = transformOldTopicToAxiosRequest(action.http.path, action.payload);
    //@ts-ignore
    return await makeHttpRequest(request, action);
  } else {
    return new Promise(() => undefined);
  }
};
