import React from "react";
import { Tooltip } from "@material-ui/core";
import { generateForm, RenderSet, StateAccess } from "utils/models/formGenerator";
import { createOptionsForSelect, FormComponent } from "utils/models/fields";
import { Lender } from "./types";

const priority = (path: string[]): FormComponent<Lender> => ({
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "select-field",
      name: "priority",
      label: "Priority",
      width: "full",
      path: ["priority"],
      autoSelect: true,
      required: true,
      options: createOptionsForSelect({
        possibleValues: (stateAccess, _path, mainStateAccess) =>
          mainStateAccess.get(path)?.map((_el: any, index: any) => index) ?? [],
        getOptionLabel: (x) => x?.toString(),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    }
  ]
});
export default ({
  stateAccess,
  mainStateAccess,
  renderSet,
  path
}: {
  mainStateAccess: StateAccess;
  stateAccess: StateAccess;
  renderSet: RenderSet;
  path: string[];
}) => {
  return (
    <Tooltip placement="top-start" title="Higher numbers mean higher priority" arrow>
      <div>{generateForm(priority(path), stateAccess, [], mainStateAccess, renderSet)}</div>
    </Tooltip>
  );
};
