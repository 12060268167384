import { DraggableInputWithId, Draggable } from "components/DocumentTemplates/types";
import { MenuItem } from "@material-ui/core";
import React from "react";
import NestedMenuItem from "material-ui-nested-menu-item";
import { v4 as uuidv4 } from "uuid";

export const initialContextState = {
  mouseX: 0,
  mouseY: 0,
  open: false,
  action: () => {}
};

export const PlaceholderMenuItem = (
  draggable: Draggable,
  handleClick: (item: DraggableInputWithId) => void,
  handleContextClose: () => void,
  open: boolean,
  acc: JSX.Element[] = []
): Array<any> => {
  let id: string;
  switch (draggable.type) {
    case "input":
    case "virtual":
    case "currency":
    case "input-date":
    case "input-phone":
      id = uuidv4();
      return acc.concat([
        <MenuItem
          key={id}
          onClick={() => {
            handleClick({
              id: id,
              path: draggable.path,
              label: draggable.label,
              tooltipLabel: draggable.tooltipLabel,
              possibleValues: draggable.model?.possibleValues,
              type: "input",
              x: 0,
              y: 0
            });
            handleContextClose();
          }}
        >
          {draggable.label}
        </MenuItem>
      ]);
    case "segment":
      if (draggable.name !== null) {
        return acc.concat([
          <NestedMenuItem key={draggable.name} label={draggable.name} parentMenuOpen={open}>
            {draggable.entities.map((x) =>
              PlaceholderMenuItem(x, handleClick, handleContextClose, open, [])
            )}
          </NestedMenuItem>
        ]);
      } else {
        return acc.concat(
          draggable.entities.flatMap((x) =>
            PlaceholderMenuItem(x, handleClick, handleContextClose, open, [])
          )
        );
      }
    case "model":
      return acc.concat(
        draggable.entities.flatMap((x) =>
          PlaceholderMenuItem(x, handleClick, handleContextClose, open, [])
        )
      );
  }
};
