import { Button } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import TextFilter from "components/Filters/TextFilter";
import React from "react";
import { useSelector } from "react-redux";
import AccessControl from "../Access/AccessControl";
import Table, { CellValue, Column, Entry } from "../Table";
import { deleteDocumentTemplate } from "./deleteDocumentTemplateSlice";
import { getDocumentTemplateList } from "./listDocumentTemplateSlice";
import { recoverDocumentTemplate } from "./recoverDocumentTemplateSlice";
import { DocumentType, FormState } from "./types";

export const projections = {
  "data.info.name": 1,
  "data.info.type": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};

const downloadFile = async (url: string, name: string) => {
  await fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);

      document.body.appendChild(link);

      link.click();

      link?.parentNode?.removeChild(link);
    });
};

export default function DocumentTemplates({
  type,
  title,
  showDownload = false
}: {
  type?: DocumentType;
  title?: string;
  showDownload?: boolean;
}): JSX.Element {
  const documentTemplateList = useSelector(
    (state: RootState) => state.listDocumentTemplateSlice[type ?? "table"]
  );

  const columns: Column<FormState>[] = [
    {
      getData: (entry): CellValue => entry.data.info.name,
      label: "Required Document",
      name: "name",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      },
      filters: [
        {
          path: ["data", "info", "name"],
          preview: TextFilter,
          name: "name",
          label: "Name",
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data.info.type
          ? entry.data.info.type === "dealDocument"
            ? "Deal document"
            : "Representative document"
          : "",
      label: "Type",
      name: "type",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.type?.show;
      },
      filters: [
        {
          path: ["data", "info", "type"],
          preview: MultiSelectFilter,
          label: "Type",
          name: "type",
          valuesForSelect: ["dealDocument", "representativeDocument"],
          optionLabelForSelect: (type) => {
            switch (type) {
              case "dealDocument":
                return "Deal document";
              case "representativeDocument":
                return "Representative document";
              default:
                return "";
            }
          }
        }
      ]
    },

    ...(showDownload
      ? [
          {
            label: "Download",
            getData: (entry: Entry<FormState>): CellValue => entry._id,
            options: {
              customBodyRender: (
                _: any,
                entry: Entry<FormState>
              ): JSX.Element[] | JSX.Element | string => {
                return entry.data.files?.map((file) => (
                  <Button
                    onClick={() => downloadFile(file?.data.info.url, file?.data.info.name)}
                    key={file._id}
                    size="small"
                  >
                    {file?.data.info.name}
                  </Button>
                ));
              }
            },
            name: "download",
            show: (userPermissions: any, tableSettings: any) => {
              return tableSettings?.data?.columns?.download?.show;
            }
          }
        ]
      : []),

    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={{ entity: "document_template", action: "read" }}>
        <Table
          tableName="document_templates"
          entityName="document_template"
          listFunction={getDocumentTemplateList}
          listEntity={documentTemplateList}
          deleteEntityFunction={deleteDocumentTemplate}
          recoverEntityFunction={recoverDocumentTemplate}
          sort={{ "data.info.name": "asc" }}
          slice={type ? type : undefined}
          query={type ? { "data.info.type": type } : undefined}
          title={title ?? ""}
          columns={columns}
          projection={projections}
        />
      </AccessControl>
    </div>
  );
}
