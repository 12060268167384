import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Link, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import {
  capitalize,
  parseMonth,
  getFirstAndLastDateOfAYear,
  getFirstAndLastDateOfGivenYearAndMonth
} from "utils/functions";
import { RootState } from "app/rootReducer";
import Spinner from "components/Loader/Spinner";
import { ChargebackStatisticsRequest } from "hooks/useChargebackStatistics/types";
import useChargebackStatistics from "hooks/useChargebackStatistics/useChargebackStatistics";
import { generateYearArray } from "components/Dashboard/Lender/AllocationDashboard/LenderAllocationDashboard";

import Chargebacks from "../Chargebacks";
import ChargebackChart from "./ChargebackChart";
import { TabContext } from "components/Layout/LayoutWrapper";

export type ToggleState = "yearly" | "monthly";
export type ToggleQuery = {
  year: number;
  month: number;
};
const useStyles = makeStyles(() =>
  createStyles({
    checkboxLabel: {
      fontSize: "12px"
    },
    periodToggle: {
      display: "flex",
      width: 47,
      padding: 15,
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
    periodSelect: {
      appearance: "none" as const,
      WebkitAppearance: "none" as const,
      MozAppearance: "none" as const,
      border: "none",
      background: "#ffffff"
    }
  })
);

export const MonthYearToggle = ({
  requestState,
  setRequestState,
  selected,
  setSelected
}: {
  requestState: ToggleQuery;
  setRequestState: React.Dispatch<React.SetStateAction<ToggleQuery>>;
  selected: ToggleState;
  setSelected: (newState: ToggleState) => void;
}) => {
  const classes = useStyles({ selected });
  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>, type: "year" | "month") => {
    setRequestState((prevRequestState) => ({
      ...prevRequestState,
      [type]: Number(e.target.value)
    }));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          minWidth: "289px",
          justifyContent: "flex-end"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
            border: "1px solid #2b4e70",
            padding: "0px 10px",
            flexDirection: "row",
            flexGrow: 3,
            width: "140px"
          }}
        >
          {selected === "monthly" ? (
            <div style={{ display: "flex", gap: "5px", alignItems: "center", width: "100%" }}>
              <select
                value={requestState.year}
                className={classes.periodSelect}
                onChange={(e) => handlePeriodChange(e, "year")}
              >
                {generateYearArray().map((yr, i) => (
                  <option value={yr} key={i}>
                    {yr}
                  </option>
                ))}
              </select>
              <select
                value={requestState.month}
                className={classes.periodSelect}
                onChange={(e) => handlePeriodChange(e, "month")}
              >
                {Array.from({ length: 12 }, (_, i) => (
                  <option value={i + 1} key={i}>
                    {parseMonth(i + 1)}
                  </option>
                ))}
              </select>
            </div>
          ) : selected === "yearly" ? (
            <div style={{ display: "flex", gap: "5px", alignItems: "center", width: "100%" }}>
              <select
                style={{ width: "100%" }}
                value={requestState.year}
                className={classes.periodSelect}
                onChange={(e) => handlePeriodChange(e, "year")}
              >
                {generateYearArray().map((yr, i) => (
                  <option value={yr} key={i}>
                    {yr}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
        </div>

        <div
          className={[classes.periodToggle].join(" ")}
          style={{
            background: selected === "yearly" ? "#2B4E70" : "#E8E8E8",
            color: selected === "yearly" ? "#fff" : "#000"
          }}
          onClick={() => setSelected("yearly")}
        >
          Yearly
        </div>
        <div
          className={[classes.periodToggle].join(" ")}
          onClick={() => setSelected("monthly")}
          style={{
            background: selected === "monthly" ? "#2B4E70" : "#E8E8E8",
            color: selected === "monthly" ? "#fff" : "#000"
          }}
        >
          Monthly
        </div>
      </div>
    </>
  );
};

export default function LenderDashboard() {
  const [type, setType] = useState<ToggleState>("monthly");
  const chargebackList = (
    useSelector((state: RootState) => state.listChargebackSlice["chargeback_dashboard"])
      ?.entities ?? []
  ).reduce(
    (acc, curr) => {
      const netsuiteStatus = curr?.data?.info?.netsuiteStatus;
      if (netsuiteStatus) {
        return {
          ...acc,
          [capitalize(netsuiteStatus)]: acc[netsuiteStatus === "success" ? "Success" : "Error"] + 1
        };
      } else {
        return { ...acc, ["Not pushed to Netsuite"]: acc["Not pushed to Netsuite"] + 1 };
      }
    },
    { Success: 0, Error: 0, "Not pushed to Netsuite": 0 }
  );

  const [state, setState] = useState<Omit<ChargebackStatisticsRequest, "type">>({
    year: new Date().getUTCFullYear(),
    month: new Date().getMonth() + 1
  });
  const { from, to } =
    type === "yearly"
      ? getFirstAndLastDateOfAYear(state.year)
      : getFirstAndLastDateOfGivenYearAndMonth(state.year, state.month);

  const handleSetToggle = (period: ToggleState) => {
    setType(period);
  };
  const { data, isLoading } = useChargebackStatistics({ ...state, type });

  const createOrFocusTab = React.useContext(TabContext);

  const handleOpenNetsuiteChargebacks = () =>
    createOrFocusTab({
      label: "Monthly Chargebacks",
      index: "chargeback_monthly_bills",
      isForSidebar: false,
      isForQuickAccess: false,
      isPersistent: false
    });

  const quantity =
    Object.keys(data?.dealerStatistics ?? {}).length > 30 ||
    Object.keys(data?.lenderStatistics ?? {}).length > 30;
  const chartSize = quantity ? 12 : 8;

  return (
    <>
      <Grid container xs={12} spacing={2} style={{ margin: 10 }}>
        <Grid container xs={12} spacing={2} style={{ marginBottom: 10, marginTop: 10 }}>
          <Grid
            item
            xs={10}
            style={{
              alignItems: "flex-start",
              display: "flex"
            }}
          >
            <Typography variant="h5">Chargeback Dashboard</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              marginLeft: "auto",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              display: "flex"
            }}
          >
            <MonthYearToggle
              requestState={state}
              setRequestState={setState}
              selected={type}
              setSelected={handleSetToggle}
            />
          </Grid>
        </Grid>

        {isLoading ? (
          <Spinner text="Loading..." style={{ position: "relative", zIndex: 5 }} />
        ) : (
          data && (
            <>
              <Grid item xs={12} style={{ marginBottom: 10 }} container spacing={2}>
                <Grid item md={chartSize} xs={12}>
                  <ChargebackChart
                    data={data.dealerStatistics}
                    chartType="bar"
                    title="Chargebacks By Dealer"
                  />
                </Grid>
                {!quantity && (
                  <Grid item md={4} xs={12}>
                    <ChargebackChart
                      data={data.managerStatistics}
                      chartType="pie"
                      title="Chargeback Amount By Manager"
                      currencyFormat={true}
                    />
                  </Grid>
                )}
                <Grid item md={chartSize} xs={12}>
                  <ChargebackChart
                    data={data.lenderStatistics}
                    chartType="bar"
                    title={"Chargebacks by Lender"}
                  />
                </Grid>
                {quantity && (
                  <Grid item md={6} xs={12}>
                    <ChargebackChart
                      data={data.managerStatistics}
                      chartType="pie"
                      title="Chargeback Amount By Manager"
                      currencyFormat={true}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={quantity ? 6 : 4}>
                  <Paper
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "space-evenly",
                      flexDirection: "column",
                      position: "relative"
                    }}
                  >
                    <Link
                      onClick={handleOpenNetsuiteChargebacks}
                      style={{
                        position: "absolute",
                        top: 25,
                        zIndex: 999,
                        cursor: "pointer",
                        fontSize: 20,
                        fontWeight: 600,
                        marginLeft: "10px"
                      }}
                    >
                      See Netsuite Chargebacks
                    </Link>
                    <ChargebackChart
                      elevation={0}
                      horizontal={true}
                      data={chargebackList}
                      chartType="bar"
                      title={"Netsuite Chargebacks Status"}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} style={{ overflow: "auto" }}>
                  <Chargebacks
                    listId="chargeback_dashboard"
                    query={{
                      "data.info.chargebackDate": {
                        $gte: from.toISOString(),
                        $lte: to.toISOString()
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )
        )}
      </Grid>
    </>
  );
}
