import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { MatchResult } from "../../types/external/verifications/common";

export const renderTableRowWithData = (
  propertyName: string | undefined,
  submitted: string | undefined,
  matchResult: MatchResult | string | undefined,
  valid: boolean | undefined
) => {
  return (
    <TableRow>
      <TableCell>{propertyName ?? "No information"}</TableCell>
      <TableCell>{submitted ?? "No information"}</TableCell>
      <TableCell>{matchResult ?? "No information"}</TableCell>
      <TableCell>
        {typeof valid === "boolean" ? (
          valid ? (
            <CheckCircleOutlineIcon fontSize="large" style={{ color: "rgb(80, 165, 56)" }} />
          ) : (
            <HighlightOffIcon fontSize="large" style={{ color: "red" }} />
          )
        ) : (
          "No information"
        )}
      </TableCell>
    </TableRow>
  );
};
export const renderTableHeadRow = () => {
  return (
    <TableRow>
      <TableCell>Property</TableCell>
      <TableCell>Submitted</TableCell>
      <TableCell>Match Result</TableCell>
      <TableCell>Status</TableCell>
    </TableRow>
  );
};
