import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverLenderDecisionRequest {
  _id: string;
}

export interface RecoverLenderDecisionResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverLenderDecisionRequest,
  RecoverLenderDecisionResponse
>("RecoverLenderDecision", {
  request: "recover_lender_decision"
});

export const recoverLenderDecisionActions = socketMessageSlice.actions;
export const recoverLenderDecision = socketAction;
export default socketMessageSlice.reducer;
