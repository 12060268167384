import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState, PartialLenderDecisionToEdit, PartialDealToEditSend } from "./types";

interface EditLenderDecisionRequest {
  data: FormState;
  approved?: boolean | null;
  approvedAt?: string;
  approvedByUserId?: string;
  availableToRolesIds?: string[] | null;
}

export interface EditLenderDecisionResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditLenderDecisionRequest | PartialDealToEditSend,
  EditLenderDecisionResponse
>("EditLenderDecision", {
  request: "update_lender_decision"
});

export const editLenderDecisionActions = socketMessageSlice.actions;
export const editLenderDecision = socketAction;

export const editPartialLenderDecisison = (request: PartialLenderDecisionToEdit & Identifiable) => {
  return socketAction({
    requestId: request.requestId,
    availableToRolesIds: request.availableToRolesIds ?? [],
    _id: request._id,
    approved: request?.approved ?? null,
    approvedAt: request.approvedAt ?? undefined,
    approvedByUserId: request.approvedByUserId ?? undefined,

    type: "partial",
    data: { dealId: request.data.dealId, refNumber: request.data.refNumber }
  });
};
export default socketMessageSlice.reducer;
