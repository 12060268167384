import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteTitleIssueNoteRequest {
  _id: string;
}

interface DeleteTitleIssueNoteResponse {
  message: {
    _id: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteTitleIssueNoteRequest,
  DeleteTitleIssueNoteResponse
>("DeleteTitleIssueNote", {
  request: "delete_title_issue_note"
});

export const deleteTitleIssueNoteActions = socketMessageSlice.actions;
export const deleteTitleIssueNote = socketAction;
export default socketMessageSlice.reducer;
