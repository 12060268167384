import { createListSlice } from "../Middlewares/listSliceCreator";
import { DealershipTicketNote } from "./types";

const { ListSlice, getList } = createListSlice<DealershipTicketNote>({
  name: "DealershipTicketNoteList",
  request_topic: "all_dealership_ticket_notes",
  reducers: {}
});
export default ListSlice.reducer;
export const getDealershipTicketNotesList = getList;
export const listDealershipTicketNotesActions = ListSlice.actions;
export const removeDealershipTicketNotesList = ListSlice.actions.removeList;
