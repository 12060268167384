import { BusinessReport } from "./types";
import {
  permissionNode,
  PermissionStruct,
  relationPermissionNode,
  multyPermissionNode
} from "components/Roles/types";

export type BusinessReportStruct = PermissionStruct<BusinessReport>;

export const businessReportPermissionStruct: BusinessReportStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    createdByUser: relationPermissionNode("Created by user", "wfd_user"),
    status: permissionNode("Status"),
    info: {
      request: {
        type: permissionNode("Type"),
        params: {
          dealershipId: permissionNode("Dealer"),
          dealership: relationPermissionNode("Dealership", "dealership"),
          lenderId: permissionNode("Lender"),
          lender: relationPermissionNode("Lender", "lender"),
          representativeId: permissionNode("Representative"),
          representative: relationPermissionNode("Representative", "wfd_user"),
          dealStatus: permissionNode("Status"),
          dateRanges: multyPermissionNode("Date Ranges"),
          dateRange: {
            dateType: permissionNode("Date type"),
            from: permissionNode("From"),
            to: permissionNode("To")
          },
          timePeriod: multyPermissionNode("Time period")
        }
      },
      response: {
        urls: multyPermissionNode("urls"),
        error: multyPermissionNode("Error")
      }
    }
  }
};
