import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import CloseDialogButton from "components/common/CloseDialogButton";
import React, { useState } from "react";
import { FormComponent } from "utils/models/fields";
import { generateForm, RenderSet, StateAccess } from "utils/models/formGenerator";
import { Deal, NetsuiteVendorBillId } from "../types";
interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}

const netsuiteVendorBill: FormComponent<NetsuiteVendorBillId> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "radio-field",
      name: "type",
      label: "Type",
      width: "1/2",
      path: ["type"],
      possibleValues: [
        { label: "Gap", value: "gap" },
        { label: "Service warranty", value: "service warranty" },
        { label: "Dealer", value: "dealer" }
      ],
      default: "gap"
    },
    {
      formComponent: "text-field",
      name: "vendorBillId",
      label: "Vendor Bill Id",
      width: "1/2",
      path: ["vendorBillId"],
      required: true
    }
  ]
};

export const netsuiteIds: FormComponent<Deal> = {
  formComponent: "segment",
  width: "1/2",
  entities: [
    {
      formComponent: "text-field",
      name: "netsuiteEstimateId",
      label: "Netsuite Estimate Id",
      width: "1/2",
      path: ["data", "netsuiteEstimateId"],
      default: ""
    },
    {
      formComponent: "text-field",
      name: "netsuiteSalesOrderId",
      label: "Netsuite Sales Order Id",
      width: "1/2",
      path: ["data", "netsuiteSalesOrderId"],
      default: ""
    },
    {
      formComponent: "text-field",
      name: "netsuiteInvoiceId",
      label: "Netsuite Invoice Id",
      width: "1/2",
      path: ["data", "netsuiteInvoiceId"],
      default: ""
    },
    {
      formComponent: "list-model",
      name: "Netsuite Vendor Bill Ids",
      entity: netsuiteVendorBill,
      width: "full",
      path: ["data", "netsuiteVendorBillIds"]
    }
  ]
};

export default function NetsuiteIds({ stateAccess, renderSet }: Props) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <IconButton
        size="small"
        color="primary"
        aria-label="open"
        id={`netsuite-open-settings`}
        style={{ position: "absolute", top: "-33px", left: "90px" }}
        onClick={() => setOpen(true)}
      >
        <Tooltip placement="top" title="Click here to edit the netsuite data.">
          <SettingsIcon />
        </Tooltip>
      </IconButton>

      <Dialog fullWidth onClose={handleClose} open={open}>
        <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
          <Grid item xs={6} key="title">
            <DialogTitle>
              Netsuite Ids
              <CloseDialogButton closeFunction={handleClose} />
            </DialogTitle>
          </Grid>
        </Grid>
        <DialogContent>
          {generateForm(netsuiteIds, stateAccess, [], stateAccess, renderSet)}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary" variant="contained">
            Ok
          </Button>
          <Button autoFocus onClick={handleClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
