import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "app/rootReducer";
import { capitalize } from "utils/functions";
import UserFilter from "components/Filters/UserFilter";
import TextFilter from "components/Filters/TextFilter";
import Table, { CellValue, Column } from "components/Table";
import LenderFilter from "components/Filters/LenderFilter";
import AccessControl from "components/Access/AccessControl";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import { getStyleByStatus, statusToChipTooltip } from "components/Deals/Deals";

import { deleteDeal } from "../deleteDealSlice";
import { getDealList } from "../listDealSlice";
import { DealData, DealStatus } from "../types";
import { recoverDeal } from "../recoverDealSlice";

export const projections = {
  "data.applicant.data.info.firstName": 1,
  "data.applicant.data.info.lastName": 1,
  "data.info.status": 1,
  "data.info.refNumber": 1,
  "data.dealership.data.info.displayName": 1,
  "data.info.vehicle.make": 1,
  "data.info.vehicle.model": 1,
  "data.info.vehicle.VIN": 1,
  "data.lender.data.info.name": 1,
  "data.user.data.info.firstName": 1,
  "data.user.data.info.lastName": 1,
  "data.info.extractedCreditScores": 1,
  "data.userId": 1,
  "data.user._id": 1,
  "data.order": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};

export default function (): JSX.Element {
  const dealList = useSelector((state: RootState) => state.listDealSlice["suspended-deals"]);

  const columns: Column<DealData>[] = [
    {
      getData: (entry): CellValue =>
        [
          entry.data?.applicant
            ? `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
                entry.data?.applicant?.data?.info?.lastName ?? ""
              }`
            : undefined
        ]
          .filter((x) => x)
          .join(" / "),
      label: "Customer",
      name: "customer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.customer?.show;
      },
      filters: [
        {
          path: [
            ["data", "applicant", "data", "info", "firstName"],
            ["data", "applicant", "data", "info", "lastName"]
          ],
          preview: ApplicantFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.refNumber,
      label: "Ref #",
      options: {
        sort: true,
        path: ["data", "info", "refNumber"]
      },
      name: "refNumber",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.refNumber?.show;
      },
      filters: [
        {
          path: ["data", "info", "refNumber"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.dealership?.data?.info?.displayName ?? "",
      label: "Dealership",
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      },
      filters: [
        {
          path: ["data", "dealershipId"],
          preview: DealershipFilter
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.info?.extractedCreditScores?.applicant?.score} ${
          entry.data?.info?.extractedCreditScores?.coApplicant?.score
            ? "/ " + entry.data?.info?.extractedCreditScores?.coApplicant?.score
            : ""
        }`,
      label: "Credit Scores",
      name: "creditScores",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.creditScores?.show;
      },
      filters: []
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
      label: "Make",
      name: "make",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.make?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "make"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
      label: "Model",
      name: "model",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.model?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "model"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.VIN,
      label: "VIN",
      name: "vin",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vin?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "VIN"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
      label: "Lender",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      },
      filters: [
        {
          path: ["data", "lenderId"],
          preview: LenderFilter
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.user?.data?.info?.firstName ?? ""} ${
          entry.data?.user?.data?.info?.lastName ?? ""
        }`,
      label: "F&I mgr",
      name: "f&iManager",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.["f&iManager"]?.show;
      },
      filters: [
        {
          path: ["data", "userId"],
          preview: UserFilter,
          type: "f&i manager"
        }
      ]
    },

    {
      getData: (entry): CellValue => entry.data?.info?.status,
      label: "Status",
      options: {
        customBodyRender: (x: CellValue, deal): JSX.Element | string => {
          const dealStatus = x as DealStatus;
          return statusToChipTooltip(dealStatus, deal, getStyleByStatus(dealStatus));
        },
        sort: true,
        path: ["data", "info", "status"]
      },
      name: "status",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.status?.show;
      },
      filters: [
        {
          path: ["data", "info", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: [DealStatus.Lead, DealStatus.CreditCheck],
          optionLabelForSelect: (status) => capitalize(status)
        }
      ]
    },

    {
      getData: (entry): CellValue => entry._id,
      label: "Actions",
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <AccessControl
        requiredPermissions={[
          { entity: "deal", action: "read" },
          { entity: "deal", action: "menu" }
        ]}
      >
        <Table
          tableName="suspended-deals"
          entityName="deal"
          listFunction={getDealList}
          listEntity={dealList}
          rowsPerPage={50}
          deleteEntityFunction={deleteDeal}
          recoverEntityFunction={recoverDeal}
          sort={{ createdAt: "desc" }}
          slice={"suspended-deals"}
          title={"Deals by Suspended Dealers"}
          query={{
            "data.dealership.data.info.titleManagement.isRestricted": true,
            "data.info.status": {
              $nin: [
                DealStatus.Cancelled,
                DealStatus.Dead,
                DealStatus.Denied,
                DealStatus.Funded,
                DealStatus.DidNotProcess
              ]
            },
            $or: [
              { "data.dealership.data.info.titleManagement.suspensions.funding": true },
              {
                "data.dealership.data.info.titleManagement.suspensions.applicationProcessing": true
              }
            ]
          }}
          columns={columns}
          projection={projections}
          hideAddButtonByDocument={false}
          hideAddButton={true}
        />
      </AccessControl>
    </div>
  );
}
