import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { AddDealershipRequest, AddDealershipRequestSend, FormState } from "./types";

export interface AddDealershipResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
    creatorId: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddDealershipRequestSend,
  AddDealershipResponse
>("AddDealership", {
  request: "new_dealership"
});

export const addDealership = (request: AddDealershipRequest & Identifiable) => {
  const { representative, customerCareAssignee, ...rest } = request.data;
  return socketAction({
    requestId: request.requestId,
    availableToRolesIds: request.availableToRolesIds ?? [],
    data: {
      ...rest,
      representativeId: representative?._id ?? null,
      customerCareAssigneeId: customerCareAssignee?._id ?? null,
      info: {
        ...rest.info,
        dealershipPrograms: rest?.info?.dealershipPrograms?.map((x) => ({
          endDate: x.endDate,
          startDate: x.startDate,
          dealershipProgramId: x?.dealershipProgram?._id
        })),
        titleManagement: {
          dealsIds: rest.info.titleManagement?.dealsIds,
          titleIssuesIds: rest.info.titleManagement?.titleIssuesIds,
          outstandingDeals: rest.info.titleManagement?.outstandingDeals,
          isRestricted: rest.info.titleManagement?.isRestricted,
          outstandingTitlesFundingLimit: rest.info.titleManagement?.outstandingTitlesFundingLimit,
          outstandingTitlesApplicationsLimit:
            rest.info.titleManagement?.outstandingTitlesApplicationsLimit,
          restrictionDate: rest.info.titleManagement?.restrictionDate,
          suspensions: {
            funding:
              (rest.info.titleManagement?.outstandingDeals || 0) >
              (rest.info.titleManagement?.outstandingTitlesFundingLimit || 0),
            applicationProcessing:
              (rest.info.titleManagement?.outstandingDeals || 0) >
              (rest.info.titleManagement?.outstandingTitlesApplicationsLimit || 0)
          }
        }
      }
    }
  });
};

export const addDealershipActions = socketMessageSlice.actions;

export default socketMessageSlice.reducer;
