import React, { useContext, useState } from "react";
import Big from "big.js";
import { tradeInStruct, tradeIns } from "./model";
import { XlStyledDialog } from "components/common/StyledDialog";
import { StateAccess, RenderSet, generateForm } from "utils/models/formGenerator";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseDialogButton from "components/common/CloseDialogButton";
import { IconButton, DialogContent, Button, DialogActions } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Warning from "@material-ui/icons/Warning";
import Box from "@material-ui/core/Box";
import { Deal } from "./types";
import formEditContext from "components/Content/FormEditContext";
import { HintTooltip } from "components/common/HintTooltip";
interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  name: string;
  label: string;
  required: boolean;
}

export default function TradeInPreview({ required, stateAccess, renderSet, name, label }: Props) {
  const formName = `deal-tradeIn`;
  const { enabled: editMode } = useContext(formEditContext);
  const [open, setOpen] = useState(false);
  const tradeIn: Deal["data"]["info"]["price"]["trade"] = stateAccess.get<Deal>([
    "data",
    "info",
    "price",
    "trade"
  ]);
  const totalTrade: Deal["data"]["info"]["price"]["trade"] = stateAccess.get<Deal>([
    "data",
    "info",
    "price",
    "totalTrade"
  ]);
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleSubmit = () => {};
  const showWarning =
    (tradeIn?.reduce((acc, curr) => acc.add(curr?.value ?? 0), new Big(0)).toNumber() || 0) !==
    (totalTrade || 0);
  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          {renderSet.currencyInputRenderer<Deal>(
            {
              required,
              name,
              label,
              path: ["data", "info", "price", "totalTrade"]
            },
            stateAccess,
            renderSet
          )}
        </Box>
        <Box>
          <IconButton
            size="small"
            style={{ margin: "3px 0px 0px 10px" }}
            aria-label={`add-trade`}
            id="add-trade-button"
            onClick={() => setOpen(true)}
          >
            {editMode ? (
              showWarning ? (
                <HintTooltip title="The total trade is not equal to the sum of the all trade ins">
                  <Warning style={{ color: "#eed202" }} />
                </HintTooltip>
              ) : (
                <HintTooltip title="Click here to add a trade-in.">
                  <AddIcon />
                </HintTooltip>
              )
            ) : showWarning ? (
              <HintTooltip title="The total trade is not equal to the sum of the all trade ins">
                <Warning style={{ color: "#eed202" }} />
              </HintTooltip>
            ) : (
              <HintTooltip title="Click here to view trade-in.">
                <VisibilityIcon />
              </HintTooltip>
            )}
          </IconButton>
        </Box>
      </Box>
      {tradeIn &&
        tradeIn?.length > 0 &&
        tradeIn?.map((el, index) => (
          <>
            <span key={index} style={{ fontSize: "12px" }}>
              Trade: {el.year ?? ""} {el.make ?? ""} {el.model ?? ""} {el.VIN ?? ""}
            </span>
            <br />
          </>
        ))}
      {open ? (
        <XlStyledDialog>
          <DialogContent>
            <CloseDialogButton closeFunction={handleCloseDialog} />
            <form id={formName} autoComplete="off" onSubmit={handleSubmit}>
              {generateForm(tradeIns, stateAccess, [], stateAccess, renderSet)}
            </form>
          </DialogContent>
          <DialogActions>
            {editMode ? (
              <Button
                type="submit"
                color="primary"
                variant="contained"
                id="submit-trade-button"
                onClick={() => setOpen(false)}
              >
                Add
              </Button>
            ) : null}
          </DialogActions>
        </XlStyledDialog>
      ) : null}
    </>
  );
}
