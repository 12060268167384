import axios from "axios";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";

import { MutationError } from "Chat/types";
import { User } from "components/Users/types";

import { auth } from "../../firebase/firebase";
import { entitySlice } from "../../utils/entitySlice";

export const useDisableUser = () => {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation<User, MutationError, User>(disableUser(), {
    onSuccess: (user) => {
      dispatch({
        type: entitySlice.actions.update.type,
        payload: { payload: user, entity: "wfd_user", _id: user._id }
      });
      enqueueSnackbar(
        `Successfully ${user.data.firebaseDisabled ? "disabled" : "enabled"} a user.`,
        {
          variant: "success"
        }
      );
    },
    onError: (error) => {
      enqueueSnackbar(error?.message ?? "Error", {
        variant: "error"
      });
    }
  });
  return {
    disableUser: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};
export const disableUser = (): ((user: User) => Promise<User>) => async (user) => {
  const token = await auth.currentUser?.getIdToken();

  const response = await axios.put(
    `${process.env.REACT_APP_HTTP_URL}/api/v1/disable_user/${user._id}`,
    {},
    {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  );

  return response.data.result;
};
