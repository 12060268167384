import React, { useEffect } from "react";
import { StateAccess, RenderSet } from "utils/models/formGenerator";
import { createOptionsForSelect } from "utils/models/fields";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { getRoleList, removeRoleList } from "./listRoleSlice";
import InputLoader from "components/Loader/InputLoader";

interface Props {
  stateAccess: StateAccess;
  path: string[];
  renderSet: RenderSet;
  name: string;
  label: string;
}

export default function RolesPreview({ stateAccess, path, renderSet, name, label }: Props) {
  const dispatch = useDispatch();
  const listId = `list-autocomplete-${name}-${stateAccess.get(["_id"])}`;
  const roleList = useSelector((state: RootState) => state.listRoleSlice[listId]);
  useEffect(() => {
    dispatch(
      getRoleList(listId, {
        options: { pagination: false, sort: { "data.info.name": "asc" } }
      })
    );
    return () => {
      dispatch(removeRoleList(listId));
      return;
    };
  }, [dispatch, listId]);
  return (
    <>
      {roleList !== undefined ? (
        renderSet.multiSelectInputRenderer(
          {
            formComponent: "multiselect-field",
            name,
            label,
            path: path as [],
            valueToString: (x) => x?.map((r: any) => r.data.info.name)?.join(", "),
            options: createOptionsForSelect({
              possibleValues: () => roleList?.entities?.map((el) => el) || [],
              getOptionLabel: (el) => el.data.info.name
            })
          },
          stateAccess,
          [],
          stateAccess,
          renderSet
        )
      ) : (
        <InputLoader label={label} renderSet={renderSet} />
      )}
    </>
  );
}
