/* eslint-disable jsx-a11y/no-static-element-interactions */

import * as React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";

import DateRangePicker from "./DateRangePicker";

// eslint-disable-next-line no-unused-vars
import { DateRange, DefinedRange } from "../types";

const useStyles = makeStyles(() => ({
  dateRangePickerContainer: {
    position: "relative"
  },
  dateRangePicker: {
    position: "relative",
    zIndex: 1
  }
}));

export interface DateRangePickerWrapperProps {
  open?: boolean;
  toggle?: () => void;
  initialDateRange?: DateRange;
  definedRanges?: DefinedRange[];
  minDate?: Date | string;
  maxDate?: Date | string;
  onChange: (dateRange: DateRange) => void;
  closeOnClickOutside?: boolean;
  wrapperClassName?: object;
}

const DateRangePickerWrapper: React.FunctionComponent<DateRangePickerWrapperProps> = (
  props: DateRangePickerWrapperProps
) => {
  const classes = useStyles();

  const { wrapperClassName } = props;

  const wrapperClasses = classNames(classes.dateRangePicker, wrapperClassName);

  return (
    <div className={classes.dateRangePickerContainer}>
      <div className={wrapperClasses}>
        <DateRangePicker {...props} />
      </div>
    </div>
  );
};

export default DateRangePickerWrapper;
