export type MachineTrigger<T, S extends string[]> = {
  [key in S[number]]: {
    onLeave: <CT extends unknown>(data: T, customParams?: CT) => Promise<T>;
    onEnter: <CT extends unknown>(data: T, customParams?: CT) => Promise<T>;
  };
};

export type MachineSchema<T, S extends string[]> = {
  [key in S[number]]: {
    possibleStates: Partial<S>;
    can: (data: T) => ResolvedCondition;
  };
};
export interface Condition {
  can: boolean;
  reason: string;
}
export interface ResolvedCondition {
  can: boolean;
  reasons: string[];
}
export const machineGenerator = <T, S extends string[]>(
  machineSchema: MachineSchema<T, S>,
  getState: (data: T) => keyof typeof machineSchema,
  setState: (data: T, newState: S[number]) => T,
  triggers: MachineTrigger<T, S>,
  disaggregateFunction: (data: T) => T
) => (data: T) => {
  const currentState = getState(data);
  const can = (state: S[number]) => machineSchema[state].can(data);

  const goto = async <CT>(state: S[number], oldState: S[number], data: T, customParams?: CT) => {
    if (can(state).can) {
      data = (await triggers[oldState]?.onLeave<CT>(data, customParams)) ?? data;
      data = setState(data, state);
      data = await triggers[state].onEnter<CT>(data, customParams);
      return data;
    } else {
      throw new Error(`You cannot go from ${oldState} to ${state}`);
    }
  };
  const simpleGoTo = async <CT>(state: S[number], customParams?: CT) => {
    return await goto<CT>(state, currentState, data, customParams);
  };
  return {
    can,
    goto: async <CT>(state: S[number], customParams?: CT) => {
      const data = await simpleGoTo(state, customParams);
      return disaggregateFunction(data);
    },
    getPossibleStates: () => machineSchema?.[currentState]?.possibleStates
  };
};
