// import webhookDiscord from "webhook-discord";
import axios from "axios";
const discordHook = {
  err: (x: any, y: any) => {
    console.error(x, y);
  }
} as any;
if (process.env.NODE_ENV !== "development") {
  // discordHook = new webhookDiscord.Webhook(process.env.REACT_APP_DISCORD_WEBHOOK_URL ?? "");
}
export const notifyWebhooks = async (error: any) => {
  try {
    discordHook.err(
      process.env.REACT_APP_FIREBASE_PROJECT_ID ?? "",
      (typeof error === "string"
        ? error
        : error instanceof Error
        ? JSON.stringify({ error: error.toString(), trace: error.stack?.toString() })
        : JSON.stringify(error)
      ).slice(0, 1000)
    );
    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "wfd-frontend") {
      axios.post(
        process.env.REACT_APP_SLACK_WEBHOOK_URL ?? "",
        JSON.stringify({
          username: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? "",
          text: JSON.stringify(error).slice(0, 100000)
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
};

export const devlog = (...loggingInfo: any[]) => {
  if (process.env.NODE_ENV === "development" && module.hot) {
    console.log(...loggingInfo);
  }
};
