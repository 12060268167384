import { CustomRegexes } from "../../helpers";

export default {
  name: { type: "string" },
  address: { type: "string", nullable: true },
  email: {
    type: "string",
    pattern: CustomRegexes.EMAIL,
    errorMessage: {
      pattern: "must match the format <emailname>@<provider>.<org>",
    },
    nullable: true,
  },
  phone: {
    type: "string",
    pattern: CustomRegexes.PHONE,
    errorMessage: {
      pattern: "should not contain letters and should not start with 0 or 1",
    },
    nullable: true,
  },
};
