import axios from "axios";
import { auth } from "../../firebase/firebase";
import { HttpQuery } from "Chat/types/http";
import { DealershipUser } from "types/dealeship_users";

export const getDealershipUsers = (query?: HttpQuery): (() => Promise<DealershipUser[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${process.env.REACT_APP_HTTP_URL}/api/v1/dealership_user/`, {
      headers: {
        authorization: `Bearer ${token}`,
        ...(query && Object.keys(query)?.length ? { query: JSON.stringify(query) } : {})
      }
    });

    return response.data.result?.docs ?? [];
  };
};
