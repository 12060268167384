import { XlStyledDialog } from "components/common/StyledDialog";
import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import {
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Dialog,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import {
  generateForm,
  StateAccess,
  getByPath,
  setByPath,
  RenderSet
} from "utils/models/formGenerator";
import { lockEntity } from "../../utils/models/LockEntity/lockEntitySlice";
import { applicantStruct } from "components/Applicants/model";
import { coApplicantStruct } from "components/Applicants/model";
import { useDispatch, useSelector } from "react-redux";
import { EntityData, entityConfigs } from "utils/entitySlice";
import SaveIcon from "@material-ui/icons/Save";
import { editRenderSet, showRenderSet } from "utils/models/formRenderers";
import AccessControl from "components/Access/AccessControl";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "app/rootReducer";
import { Applicant } from "components/Applicants/types";
import { editApplicant } from "components/Applicants/editApplicantSlice";
import CloseDialogButton from "../common/CloseDialogButton";
import { FormComponent } from "utils/models/fields";
import { modifyApplicant } from "components/Applicants/modifyApplicant";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { HintTooltip } from "components/common/HintTooltip";

const handleFillWithOtherPersonAddressInfo = (
  externalApplicant: Applicant,
  setFormState: Dispatch<SetStateAction<Applicant>>
) => {
  setFormState((formState) => ({
    ...formState,
    data: {
      ...formState.data,
      info: {
        ...formState.data.info,
        currentAddressNumber: externalApplicant.data.info.currentAddressNumber,
        currentAddress: externalApplicant.data.info.currentAddress,
        currentZipCode: externalApplicant.data.info.currentZipCode,
        currentCountry: externalApplicant.data.info.currentCountry,
        currentState: externalApplicant.data.info.currentState,
        currentCounty: externalApplicant.data.info.currentCounty,
        currentCity: externalApplicant.data.info.currentCity,
        monthsAtCurrentAddress: externalApplicant.data.info.monthsAtCurrentAddress,
        yearsAtCurrentAddress: externalApplicant.data.info.yearsAtCurrentAddress
      }
    }
  }));
};

const handleFillWithOtherPersonPreviousAddressInfo = (
  externalApplicant: Applicant,
  setFormState: Dispatch<SetStateAction<Applicant>>
) => {
  setFormState((formState) => ({
    ...formState,
    data: {
      ...formState.data,
      info: {
        ...formState.data.info,
        previousAddressNumber: externalApplicant.data.info.previousAddressNumber,
        previousAddress: externalApplicant.data.info.previousAddress,
        previousZipCode: externalApplicant.data.info.previousZipCode,
        previousCountry: externalApplicant.data.info.previousCountry,
        previousState: externalApplicant.data.info.previousState,
        previousCounty: externalApplicant.data.info.previousCounty,
        previousCity: externalApplicant.data.info.previousCity,
        monthsAtPreviousAddress: externalApplicant.data.info.monthsAtPreviousAddress,
        yearsAtPreviousAddress: externalApplicant.data.info.yearsAtPreviousAddress
      }
    }
  }));
};
export default ({
  selectedApplicant,
  setSelected,
  applicant: externalApplicant,
  path,
  stateAccess: dealStateAccess,
  editMode
}: {
  selectedApplicant: Applicant;
  setSelected: (applicant: Applicant) => void;
  applicant?: Applicant;
  path: ["data", "applicant"] | ["data", "coApplicant"];
  stateAccess: StateAccess;
  editMode: boolean | undefined;
}) => {
  const currentUser = useSelector((state: RootState) => state.authSlice);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [requestId] = useState(uuidv4());
  const [openAlert, setOpenAlert] = useState(false);
  const formName = `deal-applicant-edit`;
  const lockData = useSelector((state: RootState) => state.listLockSlice["all"])?.entities?.find(
    (lock) =>
      lock?.data?.info?.entityId === selectedApplicant?._id &&
      lock?.data?.info?.entityName === "applicant"
  );
  const [formState, setFormState] = useState(selectedApplicant);

  const stateAccess: StateAccess = {
    get: (path) => getByPath(formState as any, path),
    set: (path, value): any =>
      setFormState(modifyApplicant(setByPath(formState as any, path, value), formState))
  };

  const actionState = useSelector((state: RootState) => state.editApplicantSlice[requestId]);

  useEffect(() => {
    setFormState(selectedApplicant);
  }, [selectedApplicant]);

  useEffect(() => {
    if (actionState?.status === "success" && actionState?.data !== null) {
      setSelected({
        data: actionState.data.message.data,
        _id: actionState.data.message._id,
        createdAt: actionState.data.message.createdAt,
        updatedAt: actionState.data.message.updatedAt
      });
      dispatch(
        lockEntity({
          requestId: uuidv4(),
          data: {
            info: {
              entityId: actionState.data.message._id,
              entityName: "applicant",
              action: "unlock"
            }
          }
        })
      );
      setOpen(false);
    }
  }, [actionState, setSelected, dispatch]);

  useEffect(() => {
    dispatch({
      type: entityConfigs.applicant.edit.sliceActions.add.type,
      payload: { requestId }
    });

    return () => {
      dispatch({
        type: entityConfigs.applicant.edit.sliceActions.remove.type,
        payload: { requestId }
      });
    };
  }, [requestId, dispatch]);

  const handleSubmit = (state: EntityData<"applicant">) => (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const { _id, createdAt, deleted, ...rest } = state;
    dispatch(editApplicant({ _id: selectedApplicant._id, ...rest, requestId }));
  };

  const handleClose = () => {
    if (selectedApplicant._id && lockData?.data?.info?.userEmail === currentUser?.user?.email) {
      dispatch(
        lockEntity({
          requestId,
          data: {
            info: { entityId: selectedApplicant._id, entityName: "applicant", action: "unlock" }
          }
        })
      );
    }
    setOpen(false);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleClick = () => {
    dispatch(
      lockEntity({
        requestId,
        data: { info: { entityId: selectedApplicant._id, entityName: "applicant", action: "lock" } }
      })
    );
    setOpen(true);
    setOpenAlert(false);
  };

  const hanldeOpenDialog = () => {
    if (
      lockData?.data?.info?.userEmail &&
      lockData?.data?.info?.userEmail !== currentUser?.user?.email
    ) {
      setOpenAlert(true);
    } else {
      if (selectedApplicant._id)
        dispatch(
          lockEntity({
            requestId,
            data: {
              info: { entityId: selectedApplicant._id, entityName: "applicant", action: "lock" }
            }
          })
        );
      setOpen(true);
    }
  };

  const addressSegment: FormComponent<Applicant, any> = {
    formComponent: "one-to-many-field",
    component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => {
      return externalApplicant ? (
        <div style={{ position: "relative" }}>
          <Button
            style={{ position: "absolute", left: "149px", top: "14px" }}
            color="primary"
            size="small"
            variant="contained"
            onClick={() =>
              handleFillWithOtherPersonAddressInfo(externalApplicant || formState, setFormState)
            }
          >
            Same as the applicant
          </Button>
        </div>
      ) : (
        <></>
      );
    },
    name: "",
    path: null,
    width: "full",
    default: null
  };
  const previousAddressSegment: FormComponent<Applicant, any> = {
    formComponent: "one-to-many-field",
    component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => {
      return externalApplicant ? (
        <div style={{ position: "relative" }}>
          <Button
            style={{ position: "absolute", left: "160px", top: "14px" }}
            color="primary"
            size="small"
            variant="contained"
            onClick={() =>
              handleFillWithOtherPersonPreviousAddressInfo(
                externalApplicant || formState,
                setFormState
              )
            }
          >
            Same as the applicant
          </Button>
        </div>
      ) : (
        <></>
      );
    },
    name: "",
    path: null,
    width: "full",
    default: null
  };

  return (
    <>
      <AccessControl
        key={`add-applicant-button`}
        requiredPermissions={{ entity: "applicant", action: "create" }}
      >
        <Dialog
          id="confirm-delete-dialog"
          open={openAlert}
          onClose={handleCloseAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to unlock this applicant?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {lockData &&
                `Locked by ${lockData.data.info.userEmail}  
           ${new Intl.DateTimeFormat("en", {
             day: "2-digit",
             month: "2-digit",
             year: "2-digit",
             hour: "2-digit",
             minute: "2-digit",
             second: "2-digit"
           }).format(new Date(lockData.updatedAt))}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAlert} variant="contained" color="primary" id="cancel">
              Cancel
            </Button>
            <Button
              onClick={handleClick}
              variant="contained"
              color="secondary"
              autoFocus
              id="confirm"
            >
              Unlock
            </Button>
          </DialogActions>
        </Dialog>
        <IconButton
          size="small"
          style={{ margin: "3px 0px 0px 10px" }}
          aria-label={`add-applicant`}
          id={path?.join(".")}
          onClick={hanldeOpenDialog}
        >
          {!selectedApplicant ? (
            <HintTooltip title={"Click here to add an applicant."}>
              <AddIcon />
            </HintTooltip>
          ) : editMode ? (
            <HintTooltip title="Click here to edit the current applicant.">
              <EditIcon />
            </HintTooltip>
          ) : (
            <HintTooltip title="Click here to view the current applicant.">
              <VisibilityIcon />
            </HintTooltip>
          )}
        </IconButton>
      </AccessControl>
      {open &&
      (lockData?.data?.info?.userEmail === currentUser?.user?.email || !selectedApplicant) ? (
        <XlStyledDialog>
          <DialogContent>
            <CloseDialogButton closeFunction={handleClose} />
            <form id={formName} autoComplete="off" onSubmit={handleSubmit(formState)}>
              {generateForm(
                externalApplicant
                  ? coApplicantStruct(addressSegment, previousAddressSegment)
                  : applicantStruct,
                stateAccess,
                [],
                stateAccess,
                editMode ? editRenderSet("update_applicant") : showRenderSet("update_applicant")
              )}
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="contained">
              Cancel
            </Button>
            {editMode ? (
              <Button
                form={formName}
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            ) : null}
          </DialogActions>
        </XlStyledDialog>
      ) : null}
    </>
  );
};
