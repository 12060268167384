import React from "react";
import { auth } from "../../../firebase/firebase";
import { Dialog, Button, DialogActions, DialogTitle, DialogContent } from "@material-ui/core";

import { useSnackbar } from "notistack";

interface Props {
  open: boolean;
  setOpen: (x: boolean) => void;
  email: string;
}

export default function ResetPasswordDialog({ open, setOpen, email }: Props) {
  const handleDialogClose = () => {
    setOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handlePassReset = () => {
    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        enqueueSnackbar("Please, check your email for password reset link!", {
          variant: "info"
        });
        setOpen(false);
      })
      .catch(function (error) {
        enqueueSnackbar("Something went wrong during password reset link generation!", {
          variant: "error"
        });
      });
  };

  return (
    <div>
      <Dialog onClose={handleDialogClose} aria-labelledby="widget-dialog" open={open}>
        <DialogTitle id="widget-dialog">Password reset</DialogTitle>
        <DialogContent> Email Address: {email} </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" color="primary" onClick={handlePassReset}>
            Send link
          </Button>
          <Button variant="contained" size="small" color="primary" onClick={handleDialogClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
