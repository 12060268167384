import React from "react";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { User } from "components/Users/types";
import { Permissions } from "components/Roles/types";

export type View = "normal" | "accounting" | null;
export default function DealView({
  user,
  view,
  setView
}: {
  setView: React.Dispatch<React.SetStateAction<View>>;
  view: View;
  user:
    | {
        token: string;
        email: string;
        permissions?: Permissions;
        databaseData?: User;
      }
    | undefined;
}) {
  const isUserAdministartor = user?.databaseData?.data?.roles?.find(
    (role) => role.data.info.name === "Administrator"
  );

  const handleChange = (event: React.MouseEvent<HTMLElement>, value: View) => {
    setView(value);
  };

  return isUserAdministartor ? (
    <ToggleButtonGroup size="small" value={view} exclusive color="primary" onChange={handleChange}>
      <ToggleButton value="normal">
        <ViewCompactIcon color={view === "normal" ? "primary" : "inherit"} fontSize="small" />
      </ToggleButton>
      <ToggleButton value="accounting">
        <AccountBalanceWalletIcon
          color={view === "accounting" ? "primary" : "inherit"}
          fontSize="small"
        />
      </ToggleButton>
    </ToggleButtonGroup>
  ) : null;
}
