import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

const data = {
  type: "object",
  required: ["info"],
  properties: {
    status: {
      type: "string",
      nullable: true,
    },
    info: {
      type: "object",
      required: ["request"],
      properties: {
        request: {
          type: "object",
          properties: {
            type: {
              type: "string",
            },
            params: {
              type: "object",
              nullable: true,
              properties: {
                dateRange: {
                  type: "object",
                  nullable: true,
                  properties: {
                    from: {
                      type: "string",
                      nullable: true,
                    },
                    to: {
                      type: "string",
                      nullable: true,
                    },
                  },
                },
                timePeriod: {
                  type: "array",
                  nullable: true,
                  items: {
                    type: "string",
                    nullable: true,
                  },
                },
                lenderId: { type: "string", nullable: true },
                dealershipId: { type: "string", nullable: true },
                representativeId: { type: "string", nullable: true },
              },
            },
          },
        },
        response: {
          type: "object",
          nullable: true,
          properties: {
            urls: {
              type: "array",
              nullable: true,
              items: {
                type: "string",
              },
            },
            error: {
              type: "string",
              nullable: true,
            },
          },
        },
      },
    },
  },
};

export default {
  new_business_report: {
    $id: "newBusinessReport",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_business_report: {
    $id: "updateBusinessReport",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      approved: {
        type: "boolean",
        nullable: true,
      },
      approvedAt: {
        type: "string",
        nullable: true,
      },
      approvedByUserId: {
        type: "string",
        nullable: true,
      },
    },
  },
  delete_business_report: {
    $id: "deleteBusinessReport",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  recover_business_report: {
    $id: "recoverBusinessReport",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_business_report: {
    $id: "getBusinessReport",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_business_reports: {
    $id: "allBusinessReports",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
