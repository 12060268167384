import {
  PermissionStruct,
  permissionNode,
  multyPermissionNode,
  relationPermissionNode
} from "components/Roles/types";
import { Deal } from "./types";

export type DealertrackPermissionsStruct = PermissionStruct<Deal>;

export const dealertrackPermissionsStruct: DealertrackPermissionsStruct = {
  _id: permissionNode(" id"),
  createdAt: permissionNode("Created at"),
  updatedAt: permissionNode("Updated at"),
  deleted: permissionNode("Deleted"),
  dealId: permissionNode("Deal id"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    dealership: relationPermissionNode("Dealership", "dealership"),
    sourcePartnerId: permissionNode("Source partner id"),
    targetPlatforms: multyPermissionNode("Target platforms"),
    financeMethod: permissionNode("Finance method"),
    applicant: {
      relationship: permissionNode("Relationship"),
      salutation: permissionNode("Salutation"),
      firstName: permissionNode("First name"),
      lastName: permissionNode("Last name"),
      middleName: permissionNode("Middle name"),
      suffix: permissionNode("Suffix"),
      phone: permissionNode("Phone"),
      ssn: permissionNode("Ssn"),
      dateOfBirth: permissionNode("Date of birth"),
      address: {
        line1: permissionNode("Line1"),
        line2: permissionNode("Line2"),
        city: permissionNode("City"),
        state: permissionNode("State"),
        postalCode: permissionNode("Postal code")
      },
      driversLicenseNumber: permissionNode("Drivers license number"),
      driversLicenseState: permissionNode("Drivers license state"),
      monthsAtCurrentAddress: permissionNode("Months at current address"),
      yearsAtCurrentAddress: permissionNode("Years at current address"),
      otherPhone: permissionNode("Other phone"),
      email: permissionNode("Email"),
      preferredContactMethod: permissionNode("Preferred contact method"),
      preferredLanguage: permissionNode("Preferred language"),
      currentEmployment: {
        employerName: permissionNode("Employer name"),
        totalMonthsEmployed: permissionNode("Total months employed"),
        totalYearsEmployed: permissionNode("Total years employed"),
        occupation: permissionNode("Occupation"),
        workPhone: permissionNode("Work phone"),
        status: permissionNode("Status"),
        employerAddress: {
          line1: permissionNode("Line1"),
          line2: permissionNode("Line2"),
          city: permissionNode("City"),
          state: permissionNode("State"),
          postalCode: permissionNode("Postal code")
        }
      },
      income: permissionNode("Income"),
      incomeFrequency: permissionNode("Income frequency"),
      otherMonthlyIncome: permissionNode("Other monthly income"),
      otherMonthlyIncomeSource: permissionNode("Other monthly income source"),
      housingStatus: permissionNode("Housing status"),
      maritalStatus: permissionNode("Marital status"),
      mortgageOrRentAmount: permissionNode("Mortgage or rent amount"),
      previousAddress: {
        line1: permissionNode("Line1"),
        line2: permissionNode("Line2"),
        city: permissionNode("City"),
        state: permissionNode("State"),
        postalCode: permissionNode("Postal code")
      },
      monthsAtPreviousAddress: permissionNode("Months at previous address"),
      yearsAtPreviousAddress: permissionNode("Years at previous address"),
      previousEmployment: {
        employerName: permissionNode("Employer name"),
        totalMonthsEmployed: permissionNode("Total months employed"),
        totalYearsEmployed: permissionNode("Total years employed"),
        occupation: permissionNode("Occupation"),
        workPhone: permissionNode("Work phone"),
        status: permissionNode("Status"),
        employerAddress: {
          line1: permissionNode("Line1"),
          line2: permissionNode("Line2"),
          city: permissionNode("City"),
          state: permissionNode("State"),
          postalCode: permissionNode("Postal code")
        }
      },
      spouse: {
        firstName: permissionNode("First name"),
        lastName: permissionNode("Last name"),
        middleName: permissionNode("Middle name"),
        suffix: permissionNode("Suffix"),
        address: {
          line1: permissionNode("Line1"),
          line2: permissionNode("Line2"),
          city: permissionNode("City"),
          state: permissionNode("State"),
          postalCode: permissionNode("Postal code")
        },
        income: permissionNode("Income"),
        incomeFrequency: permissionNode("Income frequency"),
        otherMonthlyIncome: permissionNode("Other monthly income"),
        otherMonthlyIncomeSource: permissionNode("Other monthly income source")
      },
      references: multyPermissionNode("References")
    },
    includeCoApplicant: permissionNode("Include co applicant"),
    coApplicant: {
      relationship: permissionNode("Relationship"),
      salutation: permissionNode("Salutation"),
      firstName: permissionNode("First name"),
      lastName: permissionNode("Last name"),
      middleName: permissionNode("Middle name"),
      suffix: permissionNode("Suffix"),
      phone: permissionNode("Phone"),
      ssn: permissionNode("Ssn"),
      dateOfBirth: permissionNode("Date of birth"),
      address: {
        line1: permissionNode("Line1"),
        line2: permissionNode("Line2"),
        city: permissionNode("City"),
        state: permissionNode("State"),
        postalCode: permissionNode("Postal code")
      },
      driversLicenseNumber: permissionNode("Drivers license number"),
      driversLicenseState: permissionNode("Drivers license state"),
      monthsAtCurrentAddress: permissionNode("Months at current address"),
      yearsAtCurrentAddress: permissionNode("Years at current address"),
      otherPhone: permissionNode("Other phone"),
      email: permissionNode("Email"),
      preferredContactMethod: permissionNode("Preferred contact method"),
      preferredLanguage: permissionNode("Preferred language"),
      currentEmployment: {
        employerName: permissionNode("Employer name"),
        totalMonthsEmployed: permissionNode("Total months employed"),
        totalYearsEmployed: permissionNode("Total years employed"),
        occupation: permissionNode("Occupation"),
        workPhone: permissionNode("Work phone"),
        status: permissionNode("Status"),
        employerAddress: {
          line1: permissionNode("Line1"),
          line2: permissionNode("Line2"),
          city: permissionNode("City"),
          state: permissionNode("State"),
          postalCode: permissionNode("Postal code")
        }
      },
      income: permissionNode("Income"),
      incomeFrequency: permissionNode("Income frequency"),
      otherMonthlyIncome: permissionNode("Other monthly income"),
      otherMonthlyIncomeSource: permissionNode("Other monthly income source"),
      housingStatus: permissionNode("Housing status"),
      maritalStatus: permissionNode("Marital status"),
      mortgageOrRentAmount: permissionNode("Mortgage or rent amount"),
      previousAddress: {
        line1: permissionNode("Line1"),
        line2: permissionNode("Line2"),
        city: permissionNode("City"),
        state: permissionNode("State"),
        postalCode: permissionNode("Postal code")
      },
      monthsAtPreviousAddress: permissionNode("Months at previous address"),
      yearsAtPreviousAddress: permissionNode("Years at previous address"),
      previousEmployment: {
        employerName: permissionNode("Employer name"),
        totalMonthsEmployed: permissionNode("Total months employed"),
        totalYearsEmployed: permissionNode("Total years employed"),
        occupation: permissionNode("Occupation"),
        workPhone: permissionNode("Work phone"),
        status: permissionNode("Status"),
        employerAddress: {
          line1: permissionNode("Line1"),
          line2: permissionNode("Line2"),
          city: permissionNode("City"),
          state: permissionNode("State"),
          postalCode: permissionNode("Postal code")
        }
      },
      spouse: {
        firstName: permissionNode("First name"),
        lastName: permissionNode("Last name"),
        middleName: permissionNode("Middle name"),
        suffix: permissionNode("Suffix"),
        address: {
          line1: permissionNode("Line1"),
          line2: permissionNode("Line2"),
          city: permissionNode("City"),
          state: permissionNode("State"),
          postalCode: permissionNode("Postal code")
        },
        income: permissionNode("Income"),
        incomeFrequency: permissionNode("Income frequency"),
        otherMonthlyIncome: permissionNode("Other monthly income"),
        otherMonthlyIncomeSource: permissionNode("Other monthly income source")
      },
      references: multyPermissionNode("References")
    },
    guarantor: {
      relationship: permissionNode("Relationship"),
      salutation: permissionNode("Salutation"),
      firstName: permissionNode("First name"),
      lastName: permissionNode("Last name"),
      middleName: permissionNode("Middle name"),
      suffix: permissionNode("Suffix"),
      phone: permissionNode("Phone"),
      ssn: permissionNode("Ssn"),
      dateOfBirth: permissionNode("Date of birth"),
      address: {
        line1: permissionNode("Line1"),
        line2: permissionNode("Line2"),
        city: permissionNode("City"),
        state: permissionNode("State"),
        postalCode: permissionNode("Postal code")
      },
      driversLicenseNumber: permissionNode("Drivers license number"),
      driversLicenseState: permissionNode("Drivers license state"),
      monthsAtCurrentAddress: permissionNode("Months at current address"),
      yearsAtCurrentAddress: permissionNode("Years at current address"),
      otherPhone: permissionNode("Other phone"),
      email: permissionNode("Email"),
      preferredContactMethod: permissionNode("Preferred contact method"),
      preferredLanguage: permissionNode("Preferred language"),
      currentEmployment: {
        employerName: permissionNode("Employer name"),
        totalMonthsEmployed: permissionNode("Total months employed"),
        totalYearsEmployed: permissionNode("Total years employed"),
        occupation: permissionNode("Occupation"),
        workPhone: permissionNode("Work phone"),
        status: permissionNode("Status"),
        employerAddress: {
          line1: permissionNode("Line1"),
          line2: permissionNode("Line2"),
          city: permissionNode("City"),
          state: permissionNode("State"),
          postalCode: permissionNode("Postal code")
        }
      },
      income: permissionNode("Income"),
      incomeFrequency: permissionNode("Income frequency"),
      otherMonthlyIncome: permissionNode("Other monthly income"),
      otherMonthlyIncomeSource: permissionNode("Other monthly income source"),
      housingStatus: permissionNode("Housing status"),
      maritalStatus: permissionNode("Marital status"),
      mortgageOrRentAmount: permissionNode("Mortgage or rent amount"),
      previousAddress: {
        line1: permissionNode("Line1"),
        line2: permissionNode("Line2"),
        city: permissionNode("City"),
        state: permissionNode("State"),
        postalCode: permissionNode("Postal code")
      },
      monthsAtPreviousAddress: permissionNode("Months at previous address"),
      yearsAtPreviousAddress: permissionNode("Years at previous address"),
      previousEmployment: {
        employerName: permissionNode("Employer name"),
        totalMonthsEmployed: permissionNode("Total months employed"),
        totalYearsEmployed: permissionNode("Total years employed"),
        occupation: permissionNode("Occupation"),
        workPhone: permissionNode("Work phone"),
        status: permissionNode("Status"),
        employerAddress: {
          line1: permissionNode("Line1"),
          line2: permissionNode("Line2"),
          city: permissionNode("City"),
          state: permissionNode("State"),
          postalCode: permissionNode("Postal code")
        }
      },
      spouse: {
        firstName: permissionNode("First name"),
        lastName: permissionNode("Last name"),
        middleName: permissionNode("Middle name"),
        suffix: permissionNode("Suffix"),
        address: {
          line1: permissionNode("Line1"),
          line2: permissionNode("Line2"),
          city: permissionNode("City"),
          state: permissionNode("State"),
          postalCode: permissionNode("Postal code")
        },
        income: permissionNode("Income"),
        incomeFrequency: permissionNode("Income frequency"),
        otherMonthlyIncome: permissionNode("Other monthly income"),
        otherMonthlyIncomeSource: permissionNode("Other monthly income source")
      },
      references: multyPermissionNode("References")
    },
    driver: {
      salutation: permissionNode("Salutation"),
      firstName: permissionNode("First name"),
      lastName: permissionNode("Last name"),
      middleName: permissionNode("Middle name"),
      suffix: permissionNode("Suffix"),
      phone: permissionNode("Phone"),
      ssn: permissionNode("Ssn"),
      dateOfBirth: permissionNode("Date of birth"),
      address: {
        line1: permissionNode("Line1"),
        line2: permissionNode("Line2"),
        city: permissionNode("City"),
        state: permissionNode("State"),
        postalCode: permissionNode("Postal code")
      }
    },
    vehicle: {
      inventoryVehicleCondition: permissionNode("Inventory vehicle condition"),
      stockNumber: permissionNode("Stock number"),
      vin: permissionNode("Vin"),
      vehicleType: permissionNode("Vehicle type"),
      chromeYear: permissionNode("Chrome year"),
      chromeMake: permissionNode("Chrome make"),
      chromeModel: permissionNode("Chrome model"),
      chromeStyle: permissionNode("Chrome style"),
      chromeMakeId: permissionNode("Chrome make id"),
      chromeModelId: permissionNode("Chrome model id"),
      chromeStyleId: permissionNode("Chrome style id"),
      otherYear: permissionNode("Other year"),
      otherMake: permissionNode("Other make"),
      otherModel: permissionNode("Other model"),
      otherTrim: permissionNode("Other trim"),
      otherOptions: permissionNode("Other options"),
      certifiedUsed: permissionNode("Certified used"),
      odometerReading: permissionNode("Odometer reading"),
      odometerType: permissionNode("Odometer type"),
      interiorColor: permissionNode("Interior color"),
      exteriorColor: permissionNode("Exterior color"),
      transmissionType: permissionNode("Transmission type"),
      url: permissionNode("Url")
    },
    tradeIns: multyPermissionNode("Trade ins"),
    financeSummary: {
      vehicleSellingPrice: permissionNode("Vehicle selling price"),
      salesTax: permissionNode("Sales tax"),
      governmentFees: permissionNode("Government fees"),
      cashDown: permissionNode("Cash down"),
      rebate: permissionNode("Rebate"),
      creditLifeIns: permissionNode("Credit life ins"),
      term: permissionNode("Term"),
      requestedAPR: permissionNode("Requested apr"),
      acquisitionFees: permissionNode("Acquisition fees"),
      invoiceAmount: permissionNode("Invoice amount"),
      warranty: permissionNode("Warranty"),
      gap: permissionNode("Gap"),
      accidentHealthIns: permissionNode("Accident health ins"),
      frontEndFees: permissionNode("Front end fees"),
      msrp: permissionNode("Msrp"),
      estimatedBalloonAmount: permissionNode("Estimated balloon amount"),
      estimatedPayment: permissionNode("Estimated payment"),
      usedCarBook: permissionNode("Used car book"),
      mileage: permissionNode("Mileage"),
      otherFees: permissionNode("Other fees"),
      retailBookDetails: multyPermissionNode("Retail book details"),
      wholesaleBookDetails: multyPermissionNode("Wholesale book details"),
      bookValueType: permissionNode("Book value type"),
      netTrade: permissionNode("Net trade"),
      amountRequested: permissionNode("Amount requested"),
      creditScoreClassification: permissionNode("Credit score classification"),
      unpaidBalance: permissionNode("Unpaid balance"),
      customerNegotiatedPrice: permissionNode("Customer negotiated price"),
      dealerNegotiatedPrice: permissionNode("Dealer negotiated price")
    },
    partnerSource: permissionNode("Partner source"),
    comments: permissionNode("Comments"),
    regulationBIndicator: permissionNode("Regulation b indicator"),
    privacyNoticeIndicator: permissionNode("Privacy notice indicator"),
    communityPropertyDisclosureIndicator: permissionNode("Community property disclosure indicator"),
    extraData: multyPermissionNode("Extra data")
  }
};
