import { getDealershipProgramByDeal } from "components/Deals/Calculations";
import { Deal } from "components/Deals/types";
import { Category, Validation } from "components/Validations/types";

type CombinedVariant = {
  [key in VariantType]: Variant;
};

export type Variant =
  | "Warranty"
  | "Gap"
  | "TRADE WITH PAYOFF - NEGATIVE EQUITY"
  | "Trade"
  | "GAP & Warranty"
  | "Cash Down"
  | "Base";

const Variants: CombinedVariant = {
  hasWarranty: "Warranty",
  isNetTradeNegative: "TRADE WITH PAYOFF - NEGATIVE EQUITY",
  hasTradeIn: "Trade",
  hasGap: "Gap",
  hasGAPhasWarranty: "GAP & Warranty",
  hasCashDown: "Cash Down",
  Base: "Base"
};

export type VariantType =
  | "isNetTradeNegative"
  | "hasWarranty"
  | "hasTradeIn"
  | "hasGap"
  | "hasCashDown"
  | "hasGAPhasWarranty"
  | "Base";

export default function getCategoryData(deal: Deal): Category {
  const dealData = deal.data;
  const dealershipProgram = getDealershipProgramByDeal(deal);
  const hasCoApplicant = dealData?.coApplicantId ? true : false;
  const hasOneApplicant = !dealData?.coApplicantId && dealData?.applicantId ? true : false;
  const collateralType = dealData?.info?.type;
  const lender = dealData?.lender?._id;
  const state = dealData?.dealership?.data?.info?.state;
  const applicantState = dealData?.applicant?.data?.info?.currentState;
  const hasTradeIn = dealData?.info?.price?.totalTrade ? true : false;
  const hasPayoff = dealData?.info?.price?.payoff ? true : false;
  const hasCashDown = dealData?.info?.price?.totalCash ? true : false;
  const isNetTradeNegative = dealData?.info?.price?.netTrade < 0 ? true : false;
  const insurances = dealData?.info?.aftermarketOptions?.insurances;
  const programType = dealershipProgram?.data?.info?.name;
  const hasWarranty = insurances?.find(
    (insurance) => insurance.chosenRate.insuranceType === "service warranty"
  )
    ? true
    : false;
  const hasGap = insurances?.find((insurance) => insurance.chosenRate.insuranceType === "gap")
    ? true
    : false;
  return {
    hasOneApplicant,
    state: state ?? "",
    applicantState: applicantState ?? "",
    lenderId: lender,
    collateralType: collateralType,
    hasWarranty: hasWarranty,
    hasGap: hasGap,
    hasTradeIn: hasTradeIn,
    hasPayoff: hasPayoff,
    hasCashDown: hasCashDown,
    isNetTradeNegative: isNetTradeNegative,
    hasCoApplicant: hasCoApplicant,
    programType: programType
  };
}
export const getDealVariant = (deal: Deal) => {
  const category = getCategoryData(deal);
  let allCategories: Variant[] = [];

  if (category["hasGap"] && !category["hasWarranty"]) {
    allCategories = [...allCategories, Variants["hasGap"]];
  }
  if (category["hasWarranty"] && !category["hasGap"]) {
    allCategories = [...allCategories, Variants["hasWarranty"]];
  }

  if (category["hasWarranty"] && category["hasGap"]) {
    allCategories = [...allCategories, Variants["hasGAPhasWarranty"]];
  }

  if (category["isNetTradeNegative"]) {
    allCategories = [...allCategories, Variants["isNetTradeNegative"]];
  }
  if (category["hasTradeIn"]) {
    allCategories = [...allCategories, Variants["hasTradeIn"]];
  }
  if (category["hasCashDown"]) {
    allCategories = [...allCategories, Variants["hasCashDown"]];
  }
  if (allCategories.length === 0) {
    allCategories = [...allCategories, Variants["Base"]];
  }
  return allCategories;
};

export const getVariant = (validation: Validation) => {
  const category = validation.data.info.category;

  let allCategories: Variant[] = [];

  if (category["hasGap"] && !category["hasWarranty"]) {
    allCategories = [...allCategories, Variants["hasGap"]];
  }
  if (category["hasWarranty"] && !category["hasGap"]) {
    allCategories = [...allCategories, Variants["hasWarranty"]];
  }

  if (category["hasWarranty"] && category["hasGap"]) {
    allCategories = [...allCategories, Variants["hasGAPhasWarranty"]];
  }

  if (category["isNetTradeNegative"]) {
    allCategories = [...allCategories, Variants["isNetTradeNegative"]];
  }
  if (category["hasTradeIn"]) {
    allCategories = [...allCategories, Variants["hasTradeIn"]];
  }
  if (category["hasCashDown"]) {
    allCategories = [...allCategories, Variants["hasCashDown"]];
  }
  if (allCategories.length === 0) {
    allCategories = [...allCategories, Variants["Base"]];
  }

  return { validation: validation, variant: allCategories };
};
