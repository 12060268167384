import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useContext } from "react";

import { Button } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";

import { RootState } from "app/rootReducer";
import { StateAccess } from "utils/models/formGenerator";
import formEditContext from "components/Content/FormEditContext";

import { Lender } from "./types";
import { GenerateLenderLink } from "./generateLinkSlice";

const GenerateLink = ({ stateAccess }: { stateAccess: StateAccess }) => {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const { enqueueSnackbar } = useSnackbar();

  const [link, setLink] = useState("");
  const lender: Lender = stateAccess.get<Lender>([]);
  const { enabled: editMode, edited } = useContext(formEditContext);

  const generateLenderLinkSlice = useSelector(
    (state: RootState) => state.generateLenderLinkSlice[requestId]
  );

  const handleGenerateLink = () => {
    if (
      !lender?.data?.info?.adminEmail ||
      !lender?.data?.info?.adminFirstName ||
      !lender?.data?.info?.adminLastName
    ) {
      return alert("Please fill all the information in lender platform before generating a link");
    }
    if (edited) {
      return alert("Please save the lender first.");
    }

    dispatch(GenerateLenderLink({ requestId, _id: lender._id }));
  };

  useEffect(() => {
    if (generateLenderLinkSlice?.status === "success") {
      enqueueSnackbar("Invitation was send to the lender via email!", { variant: "success" });
      setLink(generateLenderLinkSlice.data.message);
    }
  }, [generateLenderLinkSlice]);

  return (
    <>
      {editMode && (
        <Button
          onClick={handleGenerateLink}
          style={{ width: "100%" }}
          variant="contained"
          component="label"
          color="primary"
          startIcon={<LinkIcon />}
        >
          Generate link for lender admin registration
        </Button>
      )}
      {link && (
        <a
          target="_blank"
          rel="noreferrer"
          style={{ margin: "10px 0px", display: "block" }}
          href={link}
        >
          Open Link
        </a>
      )}
    </>
  );
};

export default GenerateLink;
