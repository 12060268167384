import { LenderTicketType } from "./types";
import { createListSlice } from "../Middlewares/listSliceCreator";

const { ListSlice, getList } = createListSlice<LenderTicketType>({
  name: "LenderTicketTypeList",
  request_topic: "all_lender_ticket_types",
  reducers: {}
});
export default ListSlice.reducer;
export const getLenderTicketTypesList = getList;
export const listLenderTicketTypesActions = ListSlice.actions;
export const removeLenderTicketTypesList = ListSlice.actions.removeList;
