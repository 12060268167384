import Big from "big.js";
import { Column, Entry } from "components/Table/index";
import { formatNumberAsCurrency } from "./functions";

export function sumNumbers<T>(entities: Entry<T>[], column: Column<T>): string {
  const total = entities.reduce(function (totalAcc: number, x: Entry<T>) {
    const unformattedNumber = (column.getData(x) as string)
      ?.toString()
      ?.replace(/[^\d.-]/g, "")
      ?.replace(",", "");

    return totalAcc + (parseFloat(unformattedNumber) || 0);
    // return totalAcc + (parseFloat(column.getData(x) as string) || 0);
  }, 0);

  return formatNumberAsCurrency(total) ?? "0.00";
}
export function sumNumbersWithSign<T>(entities: Entry<T>[], column: Column<T>): string {
  const total = entities.reduce(function (totalAcc: number, x: Entry<T>) {
    const unformattedNumber = (column.getData(x) as string)
      ?.toString()
      ?.replace(/[^\d.-]/g, "")
      ?.replace(",", "");

    return totalAcc + (parseFloat(unformattedNumber) || 0);
    // return totalAcc + (parseFloat(column.getData(x) as string) || 0);
  }, 0);

  return formatNumberAsCurrency(total, "$") ?? "$0.00";
}

export function averageNumbers<T>(entities: Entry<T>[], column: Column<T>): string {
  const total = entities.reduce(function (totalAcc: number, x: Entry<T>) {
    const unformattedNumber = (column.getData(x) as string)
      ?.toString()
      ?.replace(/[^\d.-]/g, "")
      ?.replace(",", "");

    return totalAcc + (parseFloat(unformattedNumber) || 0);
    // return totalAcc + (parseFloat(column.getData(x) as string) || 0);
  }, 0);

  const average = entities.length > 0 ? new Big(total).div(entities.length).toNumber() : 0;
  return formatNumberAsCurrency(average) ?? "0.00";
}
