import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { RootState } from "app/rootReducer";
import { LogoWithText } from "components/AppBar/Logo";
import { useHistory } from "react-router-dom";
import { signUp } from "./signUpSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(4)
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: "90%",
      marginTop: theme.spacing(3)
    },
    submit: {
      backgroundColor: "#19181f",
      margin: theme.spacing(3, 0, 2)
    },
    errorText: {
      color: "#a4031f",
      marginBottom: 5,
      textAlign: "center"
    },
    successText: {
      color: "#697a21",
      marginBottom: 5,
      textAlign: "center"
    },
    container: {
      position: "absolute",
      width: "100%",
      height: "100%",
      background: "#19181f"
    },
    logo: { textAlign: "center" }
  })
);

export default function SignUp() {
  return <>Page not available</>;
  //   const classes = useStyles();
  //   const navigate = useHistory();
  //   const dispatch = useDispatch();
  //   const { signUpSlice } = useSelector((state: RootState) => state);
  //   const { signUpError, signUpSuccess, errorMessage } = signUpSlice;

  //   const [userCredentials, setUserCredentials] = useState({
  //     email: "",
  //     password: "",
  //     confirm_password: ""
  //   });

  //   const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //     setUserCredentials({ ...userCredentials, email: event.target.value });
  //   };

  //   const handlePasswordChange = (
  //     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  //   ) => {
  //     setUserCredentials({ ...userCredentials, password: event.target.value });
  //   };

  //   const handleConfrimPasswordChange = (
  //     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  //   ) => {
  //     setUserCredentials({
  //       ...userCredentials,
  //       confirm_password: event.target.value
  //     });
  //   };

  //   const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     const { email, password, confirm_password } = userCredentials;
  //     dispatch(signUp(email, password, confirm_password, navigate));
  //   };

  //   return (
  //     <div className={classes.container}>
  //       <div className={classes.logo}>
  //         <LogoWithText />
  //       </div>
  //       <Container component="main" maxWidth="sm">
  //         <CssBaseline />
  //         <Paper className={classes.paper}>
  //           <Avatar className={classes.avatar}>
  //             <LockOutlinedIcon />
  //           </Avatar>
  //           <Typography component="h1" variant="h5">
  //             Sign up
  //           </Typography>
  //           <form className={classes.form} noValidate onSubmit={handleSubmit}>
  //             <Grid item xs={12}>
  //               <TextField
  //                 variant="outlined"
  //                 margin="normal"
  //                 required
  //                 fullWidth
  //                 id="email"
  //                 label="Email Address"
  //                 name="email"
  //                 autoComplete="email"
  //                 onChange={(event) => handleEmailChange(event)}
  //               />
  //             </Grid>
  //             <Grid item xs={12}>
  //               <TextField
  //                 variant="outlined"
  //                 margin="normal"
  //                 required
  //                 fullWidth
  //                 name="password"
  //                 label="Password"
  //                 type="password"
  //                 id="password"
  //                 autoComplete="current-password"
  //                 onChange={(event) => handlePasswordChange(event)}
  //               />
  //             </Grid>
  //             <Grid item xs={12}>
  //               <TextField
  //                 variant="outlined"
  //                 margin="normal"
  //                 required
  //                 fullWidth
  //                 name="confirm password"
  //                 label="Confirm password"
  //                 type="password"
  //                 id="confirm-password"
  //                 autoComplete="current-password"
  //                 onChange={(event) => handleConfrimPasswordChange(event)}
  //               />
  //             </Grid>
  //             <Button
  //               type="submit"
  //               fullWidth
  //               variant="contained"
  //               color="primary"
  //               className={classes.submit}
  //             >
  //               Sign Up
  //             </Button>
  //             {signUpError && (
  //               <Typography component="p" className={classes.errorText}>
  //                 {errorMessage}
  //               </Typography>
  //             )}

  //             <Grid container justifyContent="flex-end">
  //               <Grid item>
  //                 <Link to="/sign-in">Already have an account? Sign in</Link>
  //               </Grid>
  //             </Grid>
  //           </form>
  //         </Paper>
  //       </Container>
  //     </div>
  //   );
}
