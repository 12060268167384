import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { LenderTicketType } from "./types";

interface EditLenderTicketTypeResponse {
  message: LenderTicketType;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  LenderTicketType,
  EditLenderTicketTypeResponse
>("EditLenderTicketType", {
  request: "update_lender_ticket_type"
});

export const editLenderTicketType = socketAction;
export const editLenderTicketTypeActions = socketMessageSlice.actions;
export const editLenderTicketTypeError = socketMessageSlice.actions.error;

export default socketMessageSlice.reducer;
