import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverLenderRequest {
  _id: string;
}

interface RecoverLenderResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverLenderRequest,
  RecoverLenderResponse
>("RecoverLender", {
  request: "recover_lender"
});

export const recoverLenderActions = socketMessageSlice.actions;
export const recoverLender = socketAction;
export default socketMessageSlice.reducer;
