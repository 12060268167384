import { createListSlice } from "components/Middlewares/listSliceCreator";
import { ColumnGroup } from "./types";

const { ListSlice, getList } = createListSlice<ColumnGroup>({
  name: "allColumnGroups",
  request_topic: "all_column_groups",
  reducers: {}
});

export default ListSlice.reducer;
export const getColumnGroupList = getList;
export const removeColumnGroupList = ListSlice.actions.removeList;
export const listColumnGroupActions = ListSlice.actions;
