import {
  multyPermissionNode,
  permissionNode,
  PermissionStruct,
  relationPermissionNode
} from "components/Roles/types";
import { CreditScoreRange } from "./types";

export type CreditScoreRangePermissionsStruct = PermissionStruct<CreditScoreRange>;

export const creditScoreRangePermissionStruct: CreditScoreRangePermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      name: permissionNode("name"),
      ranges: multyPermissionNode("Ranges")
    }
  }
};
