import { CustomRegexes } from "../../helpers";

export default {
  referenceId: { type: "string", nullable: true },
  type: {
    type: "string",
    nullable: true,
  },
  level: { type: "number", nullable: true },
  title: { type: "string", nullable: true },
  body: {
    type: "object",
    properties: {
      contract: { type: "string", nullable: true },
      customer: { type: "string", nullable: true },
      dealership: { type: "string", nullable: true },
      lender: { type: "string", nullable: true },
      description: { type: "string", nullable: true },
    },
  },
  seenAt: { type: "string", nullable: true },
  userEmail: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.EMAIL,
    errorMessage: {
      pattern: "must match the format <emailname>@<provider>.<org>",
    },
  },
};
