import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Room from "@material-ui/icons/Room";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { capitalizeFirstLetterLowerCaseOthers, getStateByPrefix } from "utils/functions";
import { FormComponent, Path } from "utils/models/fields";
import { generateForm, RenderSet, StateAccess } from "utils/models/formGenerator";
import { auth } from "../../firebase/firebase";
import { NormalizedGeocodingResult } from "./ZipCodeLookupService";
interface ZipCodeLookupResult {
  local?: { primary_city: string; county: string; state: string };
  google?: NormalizedGeocodingResult[];
  usps?: {
    CityStateLookupResponse?: { ZipCode?: { City: string; State: string } };
  };
}
interface ZipCodeLookupResponse {
  status: "success" | "error";
  result: ZipCodeLookupResult;
}
export const performLocalCheck = (zip: string): Promise<ZipCodeLookupResult> => {
  return new Promise<any>((resolve, reject) => {
    auth.currentUser
      ?.getIdToken()
      .then((token) => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_HTTP_URL as string}/api/v1/zip_code_lookup/${zip}`,
          data: {},
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          // @ts-ignore
          .then((res: any) => {
            return resolve(res.data.result);
          })
          .catch((err) => {
            return reject({});
          });
      })
      .then((payload: unknown) => {
        return payload;
      });
  });
};

const resultToCities = (result: ZipCodeLookupResult): string[] => {
  const x = [
    ...(result?.google?.map((x) => x?.city?.long_name) ?? []),
    result?.local?.primary_city,
    result?.usps?.CityStateLookupResponse?.ZipCode?.City
  ]
    .map((x) => capitalizeFirstLetterLowerCaseOthers(x))
    .filter((x): x is string => typeof x === "string");

  const cities = [...new Set(x)];

  return cities;
};

const handleAddressClick = (
  stateAccess: StateAccess,
  city: string | undefined,
  type?: "current" | "previous"
) => {
  stateAccess.set(
    ["data", "info", type ? (type === "current" ? "currentCity" : "previousCity") : "city"],
    city ?? "unknown"
  );
};

export default <T extends unknown>({
  type,
  stateAccess,
  renderSet,
  getStruct,
  zipCodePath,
  addressSetter
}: {
  type?: "current" | "previous";
  stateAccess: StateAccess;
  renderSet: RenderSet;
  getStruct: (func: () => void) => FormComponent<T>;
  zipCodePath: Path<T>;
  addressSetter: (
    stateAccess: StateAccess,
    address: { state: string | null; county: string | null; city: string | null }
  ) => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const ZIP = stateAccess.get<T>(zipCodePath);
  const [cities, setCities] = useState<string[]>([]);

  const handleClick = () => {
    if (ZIP) {
      performLocalCheck(ZIP).then((result) => {
        addressSetter(stateAccess, {
          state:
            getStateByPrefix(result?.usps?.CityStateLookupResponse?.ZipCode?.State ?? "") ?? null,
          county:
            result?.google?.[0]?.county?.long_name?.replace(" County", "") ??
            result?.local?.county?.replace(" County", "") ??
            null,
          city:
            capitalizeFirstLetterLowerCaseOthers(
              result?.usps?.CityStateLookupResponse?.ZipCode?.City
            ) ?? null
        });
        setCities(resultToCities(result));
      });
    } else {
      enqueueSnackbar(`Please enter zip code!`, {
        variant: "error"
      });
    }
  };

  return (
    <>
      {generateForm(getStruct(handleClick), stateAccess, [], stateAccess, renderSet)}
      <List component="nav" aria-label="main mailbox folders">
        {cities.length > 1 &&
          cities.map((city, index) => (
            <div key={index}>
              <ListItem button onClick={() => handleAddressClick(stateAccess, city, type)}>
                <ListItemIcon>
                  <Room />
                </ListItemIcon>
                <ListItemText>{city}</ListItemText>
              </ListItem>
              <Divider />
            </div>
          ))}
      </List>
    </>
  );
};
