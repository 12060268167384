import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditPayoffBankData {
  _id: string;
  updatedAt: string;
  data: FormState;
}
interface EditPayoffBankRequest {
  _id: string;
  data: FormState;
}

interface EditPayoffBankResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditPayoffBankRequest,
  EditPayoffBankResponse
>("EditPayoffBank", {
  request: "update_payoff_bank"
});

export const editPayoffBankActions = socketMessageSlice.actions;
export const editPayoffBank = (request: EditPayoffBankData & Identifiable) => {
  const { updatedAt, ...rest } = request;

  return socketAction({
    ...rest,
    data: {
      ...request.data,
      info: request.data.info
    }
  });
};
export default socketMessageSlice.reducer;
