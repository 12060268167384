import { storage } from "../../firebase/firebase";
import { useDropzone } from "react-dropzone";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { FundingFee } from "./types";
import { Box, Typography, IconButton } from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { StateAccess } from "utils/models/formGenerator";

const style = {
  display: "flex",
  position: "absolute",
  right: "0px",
  bottom: "0px",
  left: "0px",
  top: "0px",
  zIndex: 1200,
  justifyContent: "center",
  alignItems: "center",
  borderWidth: "2px",
  borderColor: "rgb(102, 102, 102)",
  borderStyle: "dashed",
  borderRadius: "5px",
  backgroundColor: "rgba(240,248,255,0.5)"
} as React.CSSProperties;
export default function AddFundingFeeFile({
  _id,
  stateAccess
}: {
  _id: string;
  stateAccess: StateAccess;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const dealId = _id;
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progress, setProgress] = useState(0);
  const file: FundingFee["document"] = stateAccess.get<FundingFee>(["document"]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFile) => {
      const file = acceptedFile[0];
      const randomID = uuidv4();
      const firebaseFileName = `${randomID}-${file.name}`;

      const uploadTask = storage.ref(`/files/${dealId}/fundingFees/${firebaseFileName}`).put(file);
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          setShowProgressBar(true);
          setProgress((snapShot.bytesTransferred / snapShot.totalBytes) * 100);
        },
        (err) => {
          enqueueSnackbar(err.message, {
            variant: "error"
          });

          setShowProgressBar(false);
        },
        () => {
          const ref = storage.ref(`/files/${dealId}/fundingFees/`).child(firebaseFileName);

          ref.getDownloadURL().then((fireBaseUrl) => {
            ref.getMetadata().then((metadata) => {
              stateAccess.set<FundingFee>(["document"], {
                name: file.name,
                url: fireBaseUrl,
                metadata
              });
            });
          });

          setShowProgressBar(false);
        }
      );
    }
  });

  const handleDelete = () => stateAccess.set<FundingFee>(["document"], null);

  return file ? (
    <>
      <a href={file.url} target="_blank" rel="noopener noreferrer">
        {file.name}
      </a>
      <IconButton
        style={{
          color: "#E34C28"
        }}
        aria-label={`remove ${file.name}`}
        onClick={handleDelete}
      >
        <RemoveCircleIcon />
      </IconButton>
    </>
  ) : (
    <div style={{ position: "relative", width: "100%", height: "50px" }}>
      <section>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} id="upload_input" draggable="true" />

          {showProgressBar ? (
            <Box display="flex" alignItems="center">
              <Box minWidth={35}>
                <Typography variant="h5" color="primary">
                  Loading {`${Math.round(progress)}%`}
                </Typography>
              </Box>
            </Box>
          ) : (
            <div>
              <Typography variant="h5" color="primary">
                Drag and drop a file here to upload.
              </Typography>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
