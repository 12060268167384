import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DealershipEmailVerificationLinkRequest {
  _id: string;
}

export interface DealershipEmailVerificationLinkResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DealershipEmailVerificationLinkRequest,
  DealershipEmailVerificationLinkResponse
>("EmailVerificationLinkDealership", {
  request: "dealership_email_verification_link"
});

export const DealershipEmailVerificationLinkActions = socketMessageSlice.actions;
export const DealershipEmailVerificationLink = socketAction;
export default socketMessageSlice.reducer;
