import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { ExternalCreditApplication } from "./types";

interface DeleteExternalCreditApplicationRequest {
  _id: string;
}

interface DeleteExternalCreditApplicationResponse {
  message: ExternalCreditApplication;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteExternalCreditApplicationRequest,
  DeleteExternalCreditApplicationResponse
>("DeleteExternalCreditApplicationResponse", {
  request: "delete_external_credit_application"
});

export const deleteExternalCreditApplicationActions = socketMessageSlice.actions;
export const deleteExternalCreditApplication = socketAction;
export default socketMessageSlice.reducer;
