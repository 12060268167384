import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import React from "react";
import { useSelector } from "react-redux";
import { EntityType } from "utils/entitySlice";
import { generateForm, RenderSet, StateAccess } from "utils/models/formGenerator";
import { Permission } from "../Roles/types";
import { profitsSegment } from "./model";
import { FormEditContext } from "components/Content/FormEditContext";

export default function ProfitPreview({
  stateAccess,
  renderSet,
  permissions,
  background,
  withInsurances = false,
  formEditContext
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  permissions?: Permission<EntityType>;
  background?: string;
  withInsurances?: boolean;
  formEditContext?: FormEditContext;
}) {
  const user = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);
  return (
    <AccessControl requiredPermissions={{ entity: "deal", action: "read" }}>
      {generateForm(
        profitsSegment(background, withInsurances),
        stateAccess,
        [],
        stateAccess,
        renderSet,
        permissions,
        formEditContext,
        user
      ) ?? <></>}
    </AccessControl>
  );
}
