// More information https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object

import { Role } from "components/Roles/types";
import { User } from "components/Users/types";

export enum EntityTypes {
  "column_group" = "column_group",
  "chargeback" = "chargeback",
  "lender_ticket" = "lender_ticket",
  "dealer_note" = "dealer_note",
  "lender_note" = "lender_note",
  "dealership_ticket" = "dealership_ticket",
  "dealership_ticket_note" = "dealership_ticket_note",
  "note" = "note",
  "customer_care_note" = "customer_care_note",
  "title_issue_note" = "title_issue_note",
  "stipulation" = "stipulation",
  "checklist" = "checklist",
  "table_settings" = "table_settings",
  "contract" = "contract",
  "settings" = "settings",
  "dealership_bills_report" = "dealership_bills_report",
  "contract_type" = "contract_type",
  "credit_score" = "credit_score",
  "lender" = "lender",
  "dealership" = "dealership",
  "applicant" = "applicant",
  "wfd_user" = "wfd_user",
  "prescreen" = "prescreen",
  "custom_report" = "custom_report",
  "business_report" = "business_report",
  "role" = "role",
  "deal" = "deal",
  "dmv" = "dmv",
  "state" = "state",
  "title_issue" = "title_issue",
  "document_template" = "document_template",
  "document_validation" = "document_validation",
  "vehicle_insurance_company" = "vehicle_insurance_company",
  "file" = "file",
  "payoff_bank" = "payoff_bank",
  "credit_score_range" = "credit_score_range",
  "lender_ticket_type" = "lender_ticket_type",
  "appone_import_application" = "appone_import_application",
  "dealertrack_deal" = "dealertrack_deal",
  "unapproved_user" = "unapproved_user",
  "funding_document" = "funding_document",
  "external_credit_application" = "external_credit_application",
  "floorplanxpress" = "floorplanxpress",
  "lock" = "lock",
  "sequence" = "sequence",
  "notification" = "notification",
  "lender_decision" = "lender_decision",
  "api_status" = "api_status",
  "history" = "history",
  "delivered_deal" = "delivered_deal",
  "other_vendor" = "other_vendor",
  "netsuite" = "netsuite",
  "dealership_program" = "dealership_program",
  "manager_program" = "manager_program",
  "additional_required_document" = "additional_required_document",
  "printed_document" = "printed_document",
  "unparsed_required_document" = "unparsed_required_document",
  "one_span_signing" = "one_span_signing"
}

export type Cons<H, T> = T extends readonly any[]
  ? ((h: H, ...t: T) => void) extends (...r: infer R) => void
    ? R
    : never
  : never;

export type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[]
];

// export type Paths<T, D extends number = 10> = [D] extends [never]
//   ? never
//   : T extends object
//   ? {
//       [K in keyof T]-?:
//         | [K]
//         | (Paths<T[K], Prev[D]> extends infer P ? (P extends [] ? never : Cons<K, P>) : never)
//         | [];
//     }[keyof T]
//   : [];

// Use this for faster compilation time

export type Paths<_, D extends number = 10> = any[_] | any[D];

export interface CrudEntity {
  _id: string;
  createdAt: string;
  creatorId?: string;
  updatedAt: string;
  deleted?: boolean;
  approved?: boolean;
  approvedAt?: string;
  approvedByUserId?: string;
  approvedByUser?: User | null;
  availableToRolesIds?: string[] | null;
  availableToRoles?: Role[] | null;
}

export type One<T> = { key: T; count: "one" };
export type Many<T> = { key: T; count: "many" };
type Relation<T> = One<T> | Many<T>;

export type AddRequest<T> = Omit<T, "_id">;
export type RequestSend<T extends { data: any }, U extends Relation<any>, Q extends string> = Omit<
  T,
  "data"
> & {
  data: Omit<T["data"], U["key"]> &
    {
      [key in Q]: U["count"] extends "one"
        ? null extends T["data"][U["key"]]
          ? string | null
          : string
        : string[];
    };
};
export interface Condition {
  field: string[];
  type: string;
  value: number;
}

export interface HasConditions {
  conditions?: Condition[];
}
