import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverCreditScoreRangeRequest {
  _id: string;
}

export interface RecoverCreditScoreRangeResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverCreditScoreRangeRequest,
  RecoverCreditScoreRangeResponse
>("RecoverCreditScoreRange", {
  request: "recover_credit_score_range"
});

export const recoverCreditScoreRangeActions = socketMessageSlice.actions;
export const recoverCreditScoreRange = socketAction;
export default socketMessageSlice.reducer;
