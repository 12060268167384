import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormStateToSend, FormState } from "./types";

type AddExternalCreditApplicationToSend = { data: FormStateToSend };

type AddExternalCreditApplication = { data: FormState };
interface AddExternalCreditApplicationResponese {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddExternalCreditApplicationToSend,
  AddExternalCreditApplicationResponese
>("AddExternalCreditApplication", {
  request: "new_external_credit_application"
});

export const addExternalCreditApplicationActions = socketMessageSlice.actions;

export const addExternalCreditApplication = (
  request: AddExternalCreditApplication & Identifiable
) => {
  const { dealership, ...rest } = request.data;
  return socketAction({
    ...request,
    data: { ...rest, dealershipId: request.data.dealership._id }
  });
};
export default socketMessageSlice.reducer;
