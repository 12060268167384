import React, { useState, useEffect, useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { GenerateDealershipLink } from "./generateLinkSlice";
import { useDispatch, useSelector } from "react-redux";
import LinkIcon from "@material-ui/icons/Link";
import { useSnackbar } from "notistack";
import { RootState } from "app/rootReducer";
import { v4 as uuidv4 } from "uuid";
import formEditContext from "components/Content/FormEditContext";
import { StateAccess } from "utils/models/formGenerator";
import { Dealership } from "./types";
import CloseDialogButton from "components/common/CloseDialogButton";

const GenerateLink = ({ stateAccess }: { stateAccess: StateAccess }) => {
  const dispatch = useDispatch();
  const dealership: Dealership = stateAccess.get<Dealership>([]);
  const [requestId] = useState(uuidv4());
  const { enqueueSnackbar } = useSnackbar();
  const { enabled: editMode, edited } = useContext(formEditContext);
  const handleGenerateLink = () => {
    if (!dealership?.data?.info?.adminEmail || !dealership?.data?.info?.adminFirstName || !dealership?.data?.info?.adminLastName) {
      return alert("Please fill all the information in dealership platform before generating a link");
    }
    if (edited) {
      return alert("Please save the dealership first.");
    }

    dispatch(GenerateDealershipLink({ requestId, _id: dealership._id }));
  };

  const generateDealershipLinkSlice = useSelector(
    (state: RootState) => state.generateLinkSlice[requestId]
  );
  const [link, setLink] = useState("");
  useEffect(() => {
    if (generateDealershipLinkSlice?.status === "success") {
      enqueueSnackbar("Invitation was send to the dealership via email!", { variant: "success" });
      setLink(generateDealershipLinkSlice.data.message);
    }
  }, [generateDealershipLinkSlice]);

  return (
    <>
      {editMode && (
        <Button
          onClick={handleGenerateLink}
          style={{ width: "100%" }}
          variant="contained"
          component="label"
          color="primary"
          startIcon={<LinkIcon />}
        >
          Generate link for admin registration
        </Button>
      )}
      {link && (
        <a
          target="_blank"
          rel="noreferrer"
          style={{ margin: "10px 0px", display: "block" }}
          href={link}
        >
          Open Link
        </a>
      )}
    </>
  );
};

export default GenerateLink;
