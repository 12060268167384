import infoProperties from "./info_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

const data = {
  type: "object",
  required: ["info"],
  properties: {
    info: {
      type: "object",
      required: ["name"],
      properties: infoProperties,
    },
  },
};

export default {
  new_other_vendor: {
    $id: "newOtherVendor",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_other_vendor: {
    $id: "updateOtherVendor",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  delete_other_vendor: {
    $id: "deleteOtherVendor",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  recover_other_vendor: {
    $id: "recoverOtherVendor",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  get_other_vendor: {
    $id: "getOtherVendor",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  all_other_vendors: {
    $id: "allOtherVendors",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
