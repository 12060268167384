import React from "react";
import { Box, createStyles, List, ListSubheader, makeStyles } from "@material-ui/core";

import { Deal } from "components/Deals/types";

import ResultsTable from "./ResultsTable";
import { StateAccess } from "utils/models/formGenerator";

const useStyles = makeStyles(() =>
  createStyles({
    listItemWrapper: {
      display: "flex",
      backgroundColor: "#E3E3E3"
    },
    list: {
      maxHeight: "calc(100vh - 100px)",
      overflow: "auto",
      paddingTop: "0px",
      paddingBottom: "0px",
      borderRadius: "5px"
    }
  })
);
export type Props = {
  loading: boolean;
  data: Deal[] | undefined;
  error: unknown;
  setFocus?: (focus: boolean) => void;
  stateAccess: StateAccess;
};

export default function ResultsList({ data, loading, stateAccess }: Props) {
  const classes = useStyles();

  const renderListItem = (entities?: Deal[]) => {
    return (
      <>
        <Box className={classes.listItemWrapper}>
          <ListSubheader>Deals</ListSubheader>
        </Box>
        <ResultsTable entities={entities} stateAccess={stateAccess} />
      </>
    );
  };

  return <List className={classes.list}>{renderListItem(data)}</List>;
}
