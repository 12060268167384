import { CreditBureau } from "./types";
import {
  PermissionStruct,
  permissionNode,
  multyPermissionNode,
  relationPermissionNode
} from "components/Roles/types";

export type CreditScorePermissionsStruct = PermissionStruct<CreditBureau>;

export const creditScorePermissionsStruct: CreditScorePermissionsStruct = {
  _id: permissionNode("_id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Deleted deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    type: permissionNode("Type"),
    dealId: permissionNode("DealId"),
    applicantId: permissionNode("ApplicantId"),
    coApplicantId: permissionNode("CoApplicantId"),
    "700CreditResponse": {
      Results: {
        bureau_xml_data: multyPermissionNode("Bureau xml data"),
        custom_report_url: multyPermissionNode("Custom report url"),
        Transaction_Information: multyPermissionNode("Transaction Information"),
        custom_report: multyPermissionNode("Custom report")
      }
    },
    dealershipId: permissionNode("DealershipId"),
    dealership: relationPermissionNode("Dealership", "dealership"),
    request: {
      PRODUCT: permissionNode("Product"),
      BUREAU: permissionNode("Bureau"),
      PASS: permissionNode("Pass"),
      PROCESS: permissionNode("Process"),
      NAME: permissionNode("Name"),
      ADDRESS: permissionNode("Address"),
      CITY: permissionNode("City"),
      STATE: permissionNode("State"),
      ZIP: permissionNode("Zip"),
      SSN: permissionNode("SSN"),
      SPOUSE: permissionNode("Spouse"),
      SPOUSEADDRESS: permissionNode("Spouse Address"),
      SPOUSECITY: permissionNode("Spouse City"),
      SPOUSESTATE: permissionNode("Spouse State"),
      SPOUSEZIP: permissionNode("Spouse Zip"),
      SPOUSESSN: permissionNode("Spouse SSN")
    },
    source: permissionNode("Source"),
    createdAt: permissionNode("CreatedAt")
  }
};
