import { RootState } from "app/rootReducer";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { getDocumentTemplateList } from "components/DocumentTemplates/listDocumentTemplateSlice";
import ValidationPreview from "components/DocumentTemplates/ValidationPreview";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import formatDate from "utils/formatDate";

export default function DocumentValidations() {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const documents = useSelector((state: RootState) => state.listDocumentTemplateSlice[requestId]);

  const counter = useSelector(
    (state: RootState) => state.changedCounterSlice["document_validation"] || 0
  );

  useEffect(() => {
    dispatch(getDocumentTemplateList(requestId, { query: { "data.info.type": "dealDocument" } }));
  }, [dispatch, requestId, counter]);
  const documentsEntities = documents?.entities || [];
  const sorted = [...documentsEntities]?.sort((a, b) =>
    a?.data?.info?.name?.localeCompare(b?.data?.info?.name)
  );
  return (
    <>
      {sorted?.map((document, index) => (
        <div key={index} style={{ paddingBottom: "20px" }}>
          <Typography variant="h6">
            {document.data.info.name}{" "}
            <span style={{ fontSize: "10px" }}>
              Last update: {formatDate(document.updatedAt, "short", true)}
            </span>
          </Typography>

          <ValidationPreview document={document} index={index} />
          <Divider />
        </div>
      ))}
    </>
  );
}
