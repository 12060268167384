import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

type AddPayoffBank = { data: FormState };

interface AddPayoffBankResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddPayoffBank,
  AddPayoffBankResponse
>("AddPayoffBank", {
  request: "new_payoff_bank"
});

export const addPayoffBankActions = socketMessageSlice.actions;
export const addPayoffBank = socketAction;
export default socketMessageSlice.reducer;
