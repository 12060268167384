import {
  createSocketMessageSlice,
  Identifiable
} from "../../Middlewares/socketMessageSliceCreator";
import { FormState, AddFormState } from "./types";
type AddBusinessReportData = { data: AddFormState; availableToRolesIds?: string[] | null };
type AddBusinessReportRequest = { data: FormState; availableToRolesIds?: string[] | null };

interface AddBusinessReportResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddBusinessReportRequest,
  AddBusinessReportResponse
>("AddBusinessReport", {
  request: "new_business_report"
});

export const addBusinessReportActions = socketMessageSlice.actions;
export const addBusinessReport = ({
  data,
  requestId,
  availableToRolesIds
}: AddBusinessReportData & Identifiable) => {
  const { lender, dealership, representative, ...rest } = data;
  const { timePeriod, dealStatus, dateRanges, dateRange, ...request } = rest?.info?.request;
  const { type } = request.businessReportType;

  return socketAction({
    requestId,
    availableToRolesIds: availableToRolesIds ?? [],
    data: {
      info: {
        request: {
          type,
          params: {
            // ...(dateRange?.to || dateRange?.from
            //   ? {
            //       dateRange: {
            //         ...(dateRange?.dateType ? { dateType: dateRange?.dateType } : {}),
            //         ...(dateRange?.to ? { to: dateRange?.to } : {}),
            //         ...(dateRange?.from ? { from: dateRange?.from } : {})
            //       }
            //     }
            //   : {}),            dealStatus: dealStatus ?? undefined,
            dateRanges: dateRanges,
            dateRange: dateRange,
            timePeriod: timePeriod && timePeriod?.length > 0 ? timePeriod : undefined,
            lenderId: lender?._id ?? undefined,
            dealershipId: dealership?._id ?? undefined,
            representativeId: representative?._id ?? undefined
          }
        }
      }
    }
  });
};
export default socketMessageSlice.reducer;
