import Calendar, { OnDayClickEvent, OnYearClickEvent } from "rc-year-calendar";
import React from "react";
import { StateAccess } from "utils/models/formGenerator";
import { OffDaysByYear } from "./types";
import { Button } from "@material-ui/core";
import formEditContext from "components/Content/FormEditContext";
type CalendarProps = {
  stateAccess: StateAccess;
};
const DEFAULT_COLOR = "rgb(44, 143, 201)";

function parseDateString(dateString: string) {
  const parts = dateString.split("-");
  const year = parseInt(parts[0]);
  const month = parseInt(parts[1]) - 1;
  const day = parseInt(parts[2]);

  const parsedDate = new Date(year, month, day, 0, 0, 0, 0);

  // const timeZoneOffset = parsedDate.getTimezoneOffset();
  // parsedDate.setMinutes(parsedDate.getMinutes() + timeZoneOffset);

  return parsedDate;
}

function getWeekends(year: number) {
  const weekends: OffDaysByYear[number] = [];

  for (let month = 0; month < 12; month++) {
    for (let day = 1; day <= 31; day++) {
      const date = new Date(year, month, day);
      if (date.getFullYear() !== year || date.getMonth() !== month) {
        break; // Stop if we've moved to the next month
      }

      const dayOfWeek = date.getDay();
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        weekends.push(formattedDate);
      }
    }
  }

  return weekends;
}
function convertDateStringsToDates(offDays: string[]) {
  return (offDays ?? [])?.map((offDay) => {
    const parsedDate = parseDateString(offDay);
    return {
      startDate: parsedDate,
      endDate: parsedDate,
      color: DEFAULT_COLOR
    };
  });
}
export default ({ stateAccess }: CalendarProps) => {
  const { enabled: editMode } = React.useContext(formEditContext);

  const [year, setYear] = React.useState<number>(2023);
  const [state, setState] = React.useState<OffDaysByYear>(
    stateAccess.get(["data", "info", "offDaysByYear"]) ?? {}
  );
  const handleDayClick = (e: OnDayClickEvent) => {
    const year = e.date.getFullYear();
    const formattedEventDate = `${e.date.getFullYear()}-${
      e.date.getMonth() + 1
    }-${e.date.getDate()}`;
    setState((prevState) => {
      const foundIndex = (prevState[year] ?? [])?.findIndex(
        (offDay) => offDay === formattedEventDate
      );
      if (foundIndex > -1) {
        const newState = (prevState[year] ?? []).filter((_, index) => index !== foundIndex);
        if (newState.length) {
          return {
            ...prevState,
            [year]: newState
          };
        } else {
          //@ts-ignore
          const copyState = structuredClone(prevState);
          delete copyState?.[year];
          return copyState;
        }
      } else {
        return {
          ...prevState,
          [year]: [...(prevState?.[year] ?? []), formattedEventDate]
        };
      }
    });
  };
  const handleSetWeekendsAsOffDays = () => {
    const weekends = getWeekends(year);
    setState((prevState) => {
      const selectedStartDateTimes = new Set(
        (prevState[year] ?? []).map((offDay) => new Date(offDay).getTime())
      );

      const unselectedWeekends = weekends.filter(
        (weekend) => !selectedStartDateTimes.has(new Date(weekend).getTime())
      );
      return {
        ...prevState,
        [year]: [...(prevState?.[year] ?? []), ...unselectedWeekends]
      };
    });
  };
  React.useEffect(() => {
    stateAccess.set(["data", "info", "offDaysByYear"], state);
  }, [state]);
  // console.log(convertDateStringsToDates(state[year]));
  return (
    <React.Fragment>
      <Calendar
        weekStart={1}
        onYearChanged={editMode ? (e) => setYear(e.currentYear) : undefined}
        onDayClick={editMode ? handleDayClick : undefined}
        style="background" //styles the way selected days are selected
        dataSource={convertDateStringsToDates(state[year])}
      />
      <Button
        onClick={handleSetWeekendsAsOffDays}
        disabled={!editMode}
        color="primary"
        variant="contained"
        style={{ float: "right" }}
      >
        Mark weekends as offdays
      </Button>
    </React.Fragment>
  );
};
