import {
  Badge,
  Box,
  Dialog,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  IconButton,
  Menu,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { FilterList } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { HintTooltip } from "components/common/HintTooltip";
import { DateRange, DateRangePicker } from "DateRangePicker";
import React, { useEffect, useState } from "react";
import { Path } from "utils/models/fields";
import ObjectLength from "utils/objectLength";
import { DateFilters, ExistFilters, FilterQuery, RelationsFilters } from "../Filters/types";
import ApprovedEntityFilters from "./ApprovedEntityFilters";
import { Entry } from "./index";

const calculateFiltersCount = (filters: FilterQuery) => {
  return !filters ? 0 : ObjectLength(filters);
};

const formatRange = (range: DateRange) => {
  if (range.startDate !== undefined && range.endDate !== undefined)
    return (
      <span style={{ fontSize: "18px", marginRight: "15px" }}>
        {new Date(range.startDate).toLocaleDateString("en-US")}
        {" - "}
        {new Date(range.endDate).toLocaleDateString("en-US")}
      </span>
    );
  else {
    return null;
  }
};

export default function OldFilters<T extends unknown>({
  setFilters,
  filters,
  relationFilters,
  existFilters = {
    filters: [],
    name: ""
  },
  dateFilters,
  hideCalendar,
  showApprovedFilter
}: {
  setFilters: (s: any) => void;
  filters: any;
  relationFilters: RelationsFilters<T>;
  existFilters?: ExistFilters<T>;
  dateFilters: DateFilters<T>;
  hideCalendar: boolean;
  showApprovedFilter?: boolean;
}): JSX.Element {
  const [prevPath, setPrevPath] = useState<Path<Entry<T>>>(dateFilters[0]?.path);
  const [dateRange, setDateRange] = useState<DateRange>();
  const [filterBy, setFilterBy] = useState<Path<Entry<T>>>(dateFilters[0]?.path);
  const [open, setOpen] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [ref, setRef] = useState<Element | undefined>();

  const theme = createTheme({
    overrides: {
      MuiMenu: {
        list: {
          padding: "0px"
        }
      }
    }
  });

  const handleClose = () => {
    setDialogOpen(false);
  };

  const path = filterBy.join(".");
  const oldPath = prevPath.join(".");

  const handleChange = (prevPath: any, path: Path<Entry<T>>) => {
    setDateRange({});
    setPrevPath(prevPath);
    setFilterBy(path);
  };
  const handleRemoveDateFilters = () => {
    setDateRange({});
    setFilters((oldFilters: any) => {
      if (oldFilters !== undefined) {
        const { [oldPath]: _, [path]: __, ...restFilters } = oldFilters;
        return restFilters;
      } else {
        return oldFilters;
      }
    });
  };

  useEffect(() => {
    if (
      dateRange?.startDate !== undefined &&
      dateRange?.endDate !== undefined &&
      filterBy !== undefined
    ) {
      const beginningOfTheDay = new Date(dateRange.startDate);
      beginningOfTheDay.setHours(0, 0, 0, 0);
      const endOfTheDay = new Date(dateRange.endDate);
      endOfTheDay.setHours(23, 59, 59, 999);

      setFilters((oldFilters: any) => ({
        ...oldFilters,
        [path]: {
          $gte: beginningOfTheDay.toISOString(),
          $lte: endOfTheDay.toISOString()
        }
      }));
    } else if (filters !== undefined && filters[oldPath]) {
      const { [oldPath]: _, ...restFilters } = filters;
      setFilters(restFilters);
    }
    // eslint-disable-next-line
  }, [dateRange, filterBy]);

  return (
    <>
      <Box component="span" style={{ position: "relative" }}>
        <HintTooltip title="Filter by">
          <IconButton
            component="button"
            onClick={(event: React.MouseEvent) => {
              setOpen(true);
              setRef(event.currentTarget);
            }}
          >
            <Badge badgeContent={calculateFiltersCount(filters)} color="primary">
              <FilterList />
            </Badge>
          </IconButton>
        </HintTooltip>
      </Box>
      <ThemeProvider theme={theme}>
        <Menu
          id="simple-menu"
          anchorEl={ref}
          autoFocus={false}
          keepMounted
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          transitionDuration={300}
        >
          <FormLabel
            style={{ width: "100%", marginTop: "15px", marginLeft: "20px" }}
            component="legend"
          >
            <Typography variant="subtitle1" style={{ color: "#000" }}>
              Filters
            </Typography>
          </FormLabel>
          <IconButton
            style={{ position: "absolute", right: "35px", top: "-7px" }}
            onClick={() => {
              setOpen(false);
            }}
            edge="end"
            aria-label="close"
          >
            <ClearIcon fontSize="small" />
          </IconButton>
          {showApprovedFilter && (
            <ApprovedEntityFilters setFilters={setFilters} path={["approved"]} />
          )}
          <div style={{ width: "390px", margin: "20px" }}>
            {relationFilters.map((relationFilter: any, index: number) => {
              const Filter = relationFilter.preview;

              return (
                <div key={index}>
                  <Filter setFilters={setFilters} path={relationFilter.path} />
                </div>
              );
            })}
          </div>
          {!hideCalendar && (
            <div>
              <FormLabel
                style={{ width: "100%", marginTop: "15px", marginLeft: "20px" }}
                component="legend"
              >
                <Typography variant="subtitle1" style={{ color: "#000" }}>
                  Date filters
                </Typography>
              </FormLabel>
              <div style={{ margin: "20px" }}>
                <RadioGroup>
                  {dateFilters.map((dateFilter: any, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <FormControlLabel
                          key={index}
                          value="end"
                          control={
                            <Radio
                              checked={filterBy?.join(".") === dateFilter?.path?.join(".")}
                              onChange={() => handleChange(filterBy, dateFilter?.path)}
                              color="primary"
                            />
                          }
                          label={dateFilter.label}
                        />
                        {dateFilter.path.slice(-1).toString() === filterBy.slice(-1).toString() && (
                          <div>
                            <IconButton
                              component="button"
                              onClick={(event: React.MouseEvent) => {
                                setDialogOpen(true);
                              }}
                              style={
                                dateRange?.startDate !== undefined
                                  ? {
                                      border: "1px solid #e0e0e0",
                                      borderRadius: "5px",
                                      background: "#e0e0e0",
                                      marginRight: "10px",
                                      whiteSpace: "nowrap"
                                    }
                                  : {}
                              }
                            >
                              {dateRange && formatRange(dateRange)}
                              <DateRangeIcon />
                            </IconButton>
                            {dateRange?.startDate !== undefined ? (
                              <IconButton
                                size="small"
                                style={{
                                  position: "absolute",
                                  right: "13px",
                                  marginTop: "-15px",
                                  color: "#254e6f"
                                }}
                                onClick={() => handleRemoveDateFilters()}
                              >
                                <CancelIcon />
                              </IconButton>
                            ) : null}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </RadioGroup>
              </div>
              <div>
                {existFilters?.filters?.length > 0 && (
                  <FormLabel
                    style={{ width: "100%", marginTop: "15px", marginLeft: "20px" }}
                    component="legend"
                  >
                    <Typography variant="subtitle1" style={{ color: "#000" }}>
                      {existFilters?.name}
                    </Typography>
                  </FormLabel>
                )}
                <div style={{ width: "390px", margin: "20px" }}>
                  {existFilters?.filters?.map((existFilter: any, index: number) => {
                    const Filter = existFilter.preview;

                    return (
                      <div key={index}>
                        <Filter
                          setFilters={setFilters}
                          path={existFilter.path}
                          name={existFilter.name}
                          label={existFilter.label}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </Menu>
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={dialogOpen}
          maxWidth="md"
        >
          <DialogTitle id="simple-dialog-title">Choose date range</DialogTitle>
          <DateRangePicker
            onChange={(range) => {
              setDateRange({
                startDate: range.startDate,
                endDate: range.endDate
              });
              if (range.endDate !== undefined) {
                setDialogOpen(false);
              }
            }}
          />
        </Dialog>
      </ThemeProvider>
    </>
  );
}
