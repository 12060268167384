import { AddCustomerCareNoteRequest, CustomerCareNote } from "./types";
import { createSocketMessageSlice } from "components/Middlewares/socketMessageSliceCreator";

interface AddCustomerCareNoteResponse {
  message: CustomerCareNote;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddCustomerCareNoteRequest,
  AddCustomerCareNoteResponse
>("AddCustomerCareResponse", {
  request: "new_customer_care_note"
});

export const addCustomerCareNoteActions = socketMessageSlice.actions;

export const addCustomerCareNote = socketAction;

export default socketMessageSlice.reducer;
