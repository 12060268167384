import { createListSlice } from "../Middlewares/listSliceCreator";
import { VehicleInsuranceCompany } from "./types";

const { ListSlice, getList } = createListSlice<VehicleInsuranceCompany>({
  name: "VehicleInsuranceCompanyList",
  request_topic: "all_vehicle_insurance_companies",
  reducers: {}
});

export default ListSlice.reducer;
export const getVehicleInsuranceCompanyList = getList;
export const removeVehicleInsuranceCompanyList = ListSlice.actions.removeList;
export const listVehicleInsuranceCompanyActions = ListSlice.actions;
