import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "app/rootReducer";
import { capitalize, formatNumberAsCurrency } from "utils/functions";
import DateFilter from "components/Filters/DateFilter";
import AccessControl from "components/Access/AccessControl";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";

import { FormState } from "../../Chargebacks/types";
import Table, { CellValue, Column } from "../../Table";
import { getChargebackList } from "../../Chargebacks/listChargebackSlice";
import { projections } from "components/Chargebacks/Chargebacks";

interface Props {
  from: Date;
  to: Date;
  userId: string;
}
export default ({ from, to, userId }: Props): JSX.Element => {
  const chargebackList = useSelector((state: RootState) => state.listChargebackSlice["table"]);
  const columns: Column<FormState>[] = [
    {
      label: "Type",
      getData: (el): CellValue => capitalize(el.data.info.type),
      name: "type",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.type?.show;
      },
      filters: [
        {
          path: ["data", "info", "type"],
          preview: MultiSelectFilter,
          valuesForSelect: ["Reserve", "Warranty", "GAP"],
          label: "Type",
          name: "type"
        }
      ]
    },
    {
      label: "Total Chargeback Amount",
      name: "amount",
      getData: (el): CellValue =>
        formatNumberAsCurrency(Number(el.data.info.wfdChargebackAmount || 0), "$"),
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.amount?.show;
      }
    },
    {
      label: "F&I Amount",
      name: "managerAmount",
      getData: (el): CellValue =>
        formatNumberAsCurrency(Number(el.data.info.managerChargebackAmount || 0), "$"),
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.managerAmount?.show;
      },
      onHover: (entry) => {
        return entry.data.info.managerChargebackCalculation ?? "";
      }
    },
    {
      getData: (entry): CellValue =>
        `${entry?.data?.manager?.data?.info?.firstName ?? ""} ${
          entry?.data?.manager?.data?.info?.lastName ?? ""
        } `,
      label: "F&I Manager",
      name: "manager",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.manager?.show;
      }
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.deal?.applicantFirstName ?? ""} ${
          entry.data?.deal?.applicantLastName ?? ""
        } `,
      label: "Customer",
      name: "customer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.customer?.show;
      }
    },
    {
      getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
      label: "Lender",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      }
    },
    {
      getData: (entry): CellValue => entry.data?.dealership?.data?.info?.displayName ?? "",
      label: "Dealership",
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      }
    },
    {
      label: "Term",
      name: "Term",
      getData: (el): CellValue => el?.data?.deal?.term ?? "",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.amount?.show;
      }
    },

    {
      label: "Interest Rate",
      name: "Interest Rate",
      getData: (el): CellValue => el?.data?.deal?.interestRate ?? "",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.amount?.show;
      }
    },

    {
      label: "Chargeback date",
      name: "chargebackDate",
      getData: (el): CellValue =>
        el?.data?.info?.chargebackDate ? new Date(el.data.info.chargebackDate) : "",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.chargebackDate?.show;
      },
      filters: [
        {
          path: ["data", "info", "chargebackDate"],
          preview: DateFilter
        }
      ]
    },
    {
      label: "Contract date",
      name: "contractDate",
      getData: (el): CellValue =>
        el?.data?.deal?.contractDate ? new Date(el.data.deal.contractDate) : "",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.contractDate?.show;
      },
      filters: [
        {
          path: ["data", "info", "contractDate"],
          preview: DateFilter
        }
      ]
    },
    {
      label: "Actions",
      getData: (el): CellValue => el._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <>
      <AccessControl requiredPermissions={{ entity: "chargeback", action: "read" }}>
        <Table
          hideAddButton={true}
          tableName="chargebacks"
          entityName="chargeback"
          query={{
            "data.info.chargebackDate": {
              $gte: from.toISOString(),
              $lte: to.toISOString()
            },
            "data.managerId": userId
          }}
          hideDeleteButton={true}
          listFunction={getChargebackList}
          listEntity={chargebackList}
          title={"Chargebacks"}
          columns={columns}
          projection={projections}
        />
      </AccessControl>
    </>
  );
};
