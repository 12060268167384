import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverCustomReportRequest {
  _id: string;
}

export interface RecoverCustomReportResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverCustomReportRequest,
  RecoverCustomReportResponse
>("RecoverCustomReport", {
  request: "recover_custom_report"
});

export const recoverCustomReportActions = socketMessageSlice.actions;
export const recoverCustomReport = socketAction;
export default socketMessageSlice.reducer;
