import React from "react";
import { Route, Redirect } from "react-router-dom";
import Spinner from "components/Loader/Spinner";

const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  isVerifying,
  redirectTo,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isVerifying) {
          return <Spinner text="Authenticating" />;
        } else {
          if (isAuthenticated) {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  search: window.location.search,
                  pathname: redirectTo || "/sign-in",
                  state: { from: props.location }
                }}
              />
            );
          }
        }
      }}
    />
  );
};
export default ProtectedRoute;
