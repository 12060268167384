import { useSnackbar } from "notistack";
import React, { useEffect } from "react";

export default function () {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const eventHandler = ((e: CustomEvent) => {
      enqueueSnackbar(e.detail.message, { variant: "info" });
    }) as EventListener;
    window.addEventListener("display_notification", eventHandler);

    return () => {
      window.removeEventListener("display_notification", eventHandler);
    };
  }, []);

  return <></>;
}
