import { CustomRegexes } from "../../helpers";

export default {
  deal: { type: "object", additionalProperties: true },
  filledCoordinates: {
    type: "array",
    items: {
      type: "object",
      properties: {
        id: { type: "string", nullable: true },
        path: { type: "array", items: { type: "string", nullable: true } },
        label: { type: "string", nullable: true },
        tooltipLabel: {
          type: "array",
          items: { type: "string", nullable: true },
        },
        type: { type: "string", nullable: true },
        x: { type: "number", nullable: true },
        y: { type: "number", nullable: true },
        fontSize: { type: "string", nullable: true },
        value: {
          anyOf: [
            { type: "string", nullable: true },
            { type: "object", nullable: true, additionalProperties: true },
          ],
        },
        format: { type: "string", nullable: true },
      },
    },
  },
  category: {
    type: "object",
    properties: {
      state: {
        type: "string",
        nullable: true,
        pattern: CustomRegexes.LETTERS,
        errorMessage: { pattern: "must not contain numbers" },
      },
      lenderId: { type: "string", nullable: true },
      applicantState: {
        type: "string",
        nullable: true,
        pattern: CustomRegexes.LETTERS,
        errorMessage: { pattern: "must not contain numbers" },
      },
      collateralType: {
        type: ["string", "null"],
        enum: [
          "Automotive",
          "Marine",
          "Recreational Vehicles",
          "Power Sport",
          null,
        ],
        nullable: true,
        errorMessage: {
          enum: "must be one of the following: 'Automotive', 'Marine', 'Recreational Vehicles', 'Power Sport'",
        },
      },
      hasWarranty: { type: "boolean" },
      hasGap: { type: "boolean" },
      hasTradeIn: { type: "boolean" },
      hasPayoff: { type: "boolean" },
      hasCashDown: { type: "boolean" },
      isNetTradeNegative: { type: "boolean" },
      hasCoApplicant: { type: "boolean" },
    },
  },
  documentTemplateId: { type: "string" },
};
