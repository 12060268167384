import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import SaveIcon from "@material-ui/icons/Save";
import { useDispatch, useSelector } from "react-redux";
import { DialogContent, DialogActions, Button } from "@material-ui/core";

import {
  setByPath,
  getByPath,
  StateAccess,
  generateForm,
  generateDefault,
  fillDefaultsByPath
} from "utils/models/formGenerator";
import { RootState } from "app/rootReducer";
import { EntityData } from "utils/entitySlice";
import { TitleIssue } from "components/TitleIssues/types";
import { editRenderSet } from "utils/models/formRenderers";
import { XlStyledDialog } from "components/common/StyledDialog";
import CloseDialogButton from "components/common/CloseDialogButton";

import { addLenderTicketStruct } from "./model";
import { addLenderTicket } from "./addLenderTicketSlice";

export default ({
  titleIssueId,
  setSelected
}: {
  titleIssueId: string;
  setSelected: (data: TitleIssue) => void;
}) => {
  const initialState = generateDefault(addLenderTicketStruct, {}, fillDefaultsByPath as any) as any;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [requestId] = useState(uuidv4());
  const [canSubmit, setCanSubmit] = useState(true);
  const formName = `titileIssue-ticket-new`;
  const [formState, setFormState] = React.useState(initialState);

  const stateAccess: StateAccess = {
    get: (path) => getByPath(formState as any, path),
    set: (path, value): any => setFormState(setByPath(formState as any, path, value))
  };
  const actionState = useSelector((state: RootState) => state.addLenderTicketSlice[requestId]);

  const handleSubmit = (state: EntityData<"lender_ticket">) => (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(false);
    if (canSubmit) {
      setCanSubmit(false);

      dispatch(
        addLenderTicket({ ...{ ...state, data: { ...state.data, titleIssueId } }, requestId })
      );

      setTimeout(() => {
        setCanSubmit(true);
      }, 2000);
    } else {
      alert("Please dont submit the form twice!");
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const addTicket = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (actionState?.status === "success" && actionState?.data !== null) {
      setSelected((actionState.data.message as unknown) as TitleIssue);
    }
  }, [actionState]);

  return (
    <>
      <Button onClick={addTicket} variant="contained" style={{ margin: 5, background: "white" }}>
        Add ticket
      </Button>

      {open ? (
        <XlStyledDialog>
          <DialogContent>
            <CloseDialogButton closeFunction={handleClose} />
            <form id={formName} autoComplete="off" onSubmit={handleSubmit(formState as any)}>
              {generateForm(
                addLenderTicketStruct,
                stateAccess,
                [],
                stateAccess,
                editRenderSet("new_lender_ticket")
              )}
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="contained">
              Cancel
            </Button>

            <Button
              disabled={!canSubmit}
              form={formName}
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </DialogActions>
        </XlStyledDialog>
      ) : null}
    </>
  );
};
