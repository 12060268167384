import { ColumnGroup } from "./types";
import {
  multyPermissionNode,
  permissionNode,
  PermissionStruct,
  relationPermissionNode
} from "components/Roles/types";

export type ColumnGroupPermissionStruct = PermissionStruct<ColumnGroup>;

export const columnGroupPermissionStruct: ColumnGroupPermissionStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    userId: permissionNode("User id"),
    info: {
      type: permissionNode("Type"),
      isDefault: permissionNode("Is default"),
      isSnapshot: permissionNode("Is snapshot"),
      grouping: multyPermissionNode("Grouping"),
      groupColumns: multyPermissionNode("Group columns"),
      aggregationColumns: multyPermissionNode("Aggregation columns"),
      name: permissionNode("Name")
    }
  }
};
