import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteDealRequest {
  _id: string;
}

interface DeleteDealResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteDealRequest,
  DeleteDealResponse
>("DeleteDeal", {
  request: "delete_deal"
});

export const deleteDealActions = socketMessageSlice.actions;
export const deleteDeal = socketAction;
export default socketMessageSlice.reducer;
