import React from "react";

import { capitalize } from "utils/functions";
import { userStruct } from "components/Users/model";
import UserPreview from "components/Users/UserPreview";
import { lenderStruct } from "components/Lenders/model";
import { collateralTypes } from "components/Deals/types";
import FilesPreview from "components/Files/FilesPreview";
import LenderPreview from "components/Lenders/LenderPreview";
import { generateFileStruct } from "components/Files/model";
import { dealershipStruct } from "components/Dealerships/model";
import { allProviders } from "components/ExpressApi/Deal/types";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import DealershipPreview from "components/Dealerships/DealershipPreview";
import CalculateChargebackAmount from "./CalculateChargebackAmountButton";

import { Chargeback } from "./types";
import AddChargeback from "./AddChargeback";
import SelectChargebackDeal from "./SearchChargebackDeal/Search";
import PreviewDealModal from "./PreviewDealModal/PreviewDealModal";
import { Model, createOptionsForSelect, FormComponent } from "../../utils/models/fields";

const vendor: FormComponent<Chargeback> = {
  formComponent: "segment",
  width: "1/3",
  entities: [
    {
      formComponent: "select-field",
      name: "vendor",
      label: "Vendor",
      show: (stateAccess) => stateAccess.get(["data", "info", "type"]) !== "Reserve",
      width: (stateAccess) =>
        stateAccess.get(["data", "info", "vendor"]) === "Other" ? "1/2" : "full",
      path: ["data", "info", "vendor"],
      options: createOptionsForSelect({
        possibleValues: () => [...allProviders],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      default: ""
    },
    {
      formComponent: "text-field",
      name: "customVendor",
      label: "Custom Vendor",
      width: (stateAccess) =>
        stateAccess.get(["data", "info", "vendor"]) === "Other" ? "1/2" : "full",
      path: ["data", "info", "customVendor"],
      show: (stateAccess) =>
        stateAccess.get(["data", "info", "vendor"]) === "Other" &&
        stateAccess.get(["data", "info", "type"]) !== "Reserve",
      required: true,
      default: ""
    }
  ]
};
const perviewDeal: FormComponent<Chargeback> = {
  formComponent: "segment",
  width: "2/3",
  style: { marginTop: 10 },
  entities: [
    {
      formComponent: "one-to-many-field",
      name: "Preview Deal",
      show: (stateAccess) => stateAccess.get(["data", "dealId"]),
      width: "full",
      path: null,
      component: (stateAccess: StateAccess) => {
        return <PreviewDealModal stateAccess={stateAccess} />;
      }
    }
  ]
};
const files: FormComponent<Chargeback> = {
  formComponent: "segment",
  name: "Files",
  width: "1/2",
  entities: [
    {
      formComponent: "one-to-many-field",
      name: "documents",
      width: "full",
      path: null,
      component: (stateAccess: StateAccess) => {
        return (
          <FilesPreview
            tableName="files"
            type="chargeback"
            _id={stateAccess.get(["_id"])}
            struct={generateFileStruct(
              createOptionsForSelect({
                possibleValues: (stateAccess: StateAccess) => [],
                getOptionLabel: (name) => name
              })
            )}
          />
        );
      }
    }
  ]
};

const assignee: FormComponent<Chargeback> = {
  formComponent: "segment",
  width: "1/4",
  entities: [
    {
      formComponent: "one-to-many-field",
      struct: () => userStruct,
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <UserPreview
          type="f&i manager"
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["data", "manager"]}
          name="user"
          label="Manager"
        />
      ),
      name: "user",
      label: "Assignee",
      width: "full",
      path: ["data", "manager"],
      default: null,
      valueToString: (el) => el.firstName
    }
  ]
};
const dealership: FormComponent<Chargeback> = {
  formComponent: "segment",
  width: "1/4",
  entities: [
    {
      formComponent: "one-to-many-field",
      struct: () => dealershipStruct,
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <DealershipPreview
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["data", "dealership"]}
          name="dealership"
          label="Dealership"
          required={false}
          type="chargeback"
        />
      ),
      name: "dealership",
      label: "Dealership",
      default: null,
      path: ["data", "dealership"],
      width: "full"
    }
  ]
};
const lender: FormComponent<Chargeback> = {
  formComponent: "segment",
  name: "Lender",
  width: "1/3",
  entities: [
    {
      formComponent: "one-to-many-field",
      struct: () => lenderStruct,
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <LenderPreview
          stateAccess={stateAccess}
          renderSet={renderSet}
          lenderIdPath={["data", "lenderId"]}
          path={["data", "lender"]}
          name="lender"
          label="Lender"
        />
      ),
      name: "lender",
      label: "Lender",
      required: true,
      width: "full",
      path: ["data", "lender"],
      default: null
    }
  ]
};
const calculate: FormComponent<Chargeback> = {
  formComponent: "segment",
  width: "1/6",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <CalculateChargebackAmount stateAccess={stateAccess} renderSet={renderSet} />
      ),
      name: "CalculateChargebackAmount",
      label: "CalculateChargebackAmount",
      width: "full",
      path: [],
      default: null
    }
  ]
};

export const chargebackAmounts: FormComponent<Chargeback> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "currency-field",
      name: "dealerChargebackAmount",
      label: "Dealer Chargeback Amount",
      path: ["data", "info", "dealerChargebackAmount"],
      default: null,
      width: "1/4"
    },
    {
      formComponent: "currency-field",
      name: "wfdChargebackAmount",
      label: "WFD Chargeback Amount",
      path: ["data", "info", "wfdChargebackAmount"],
      default: null,
      width: "1/4"
    },
    {
      formComponent: "currency-field",
      name: "managerChargebackAmount",
      label: "Manager Chargeback Amount",
      path: ["data", "info", "managerChargebackAmount"],
      default: null,
      width: "1/4"
    },
    {
      formComponent: "currency-field",
      name: "managerChargebackAmount",
      label: "NET WFD Chargeback Amount",
      path: ["data", "info", "netWFDChargeBackAmount"],
      default: null,
      width: "1/4"
    }
  ]
};
export const listchargebackAmounts: FormComponent<Chargeback> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "list-model",
      name: "Chargebacks",
      width: "full",
      entity: chargebackAmounts,
      path: [],
      required: false
    }
  ]
};
export const dealChargebackStruct: FormComponent<Chargeback> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "segment",
      name: "Chargeback Info",
      width: "full",
      entities: [
        {
          formComponent: "read-only-field",
          name: "Ref Id",
          label: "Ref Id",
          width: "1/3",
          path: ["data", "info", "refId"],
          show: (stateAccess) => stateAccess.get(["data", "info", "refId"]),
          required: true,
          default: null
        },
        {
          formComponent: "segment",
          width: "3/4",
          entities: [
            {
              formComponent: "select-field",
              name: "Chargeback reason",
              label: "Chargeback Reason",
              width: "1/3",
              path: ["data", "info", "reason"],
              options: createOptionsForSelect({
                possibleValues: () => ["repo", "customer request", "paid off"],
                getOptionLabel: (x) => capitalize(x),
                getSelectedOption: (x, y) => x === y
              }),
              default: null,
              required: true
            },
            {
              formComponent: "select-field",
              name: "type",
              label: "Type",
              width: "1/3",
              path: ["data", "info", "type"],
              options: createOptionsForSelect({
                possibleValues: () => ["Reserve", "Warranty", "GAP"],
                getOptionLabel: (x) => x,
                getSelectedOption: (x, y) => x === y
              }),
              default: null,
              required: true
            },
            {
              formComponent: "one-to-many-field",
              show: (stateAccess) => stateAccess.get(["data", "info", "type"]) === "Reserve",
              component: (
                stateAccess: StateAccess,
                _mainstateAccess: StateAccess,
                renderSet: RenderSet
              ) => (
                <LenderPreview
                  stateAccess={stateAccess}
                  renderSet={renderSet}
                  lenderIdPath={["data", "lenderId"]}
                  path={["data", "lender"]}
                  name="Lender"
                  label="Lender"
                />
              ),
              name: "Lender",
              label: "Lender",
              width: "1/3",
              path: ["data", "lender"],
              default: null
            },
            vendor
          ]
        },
        {
          formComponent: "segment",
          width: "1/4",
          entities: [
            {
              formComponent: "date-field",
              name: "payoffDate",
              label: "Payoff Date",
              width: "1/2",
              path: ["data", "info", "payoffDate"],
              default: null
            },
            {
              formComponent: "date-field",
              name: "chargebackDate",
              label: "Chargeback Date",
              width: "1/2",
              path: ["data", "info", "chargebackDate"],
              required: true,
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: "full",
          entities: [
            {
              formComponent: "currency-field",
              name: "totalChargebackAmount",
              label: "Total Chargeback Amount",
              path: ["data", "info", "totalChargebackAmount"],
              required: true,
              default: null,
              width: "1/6"
            },
            {
              formComponent: "number-field",
              name: "percentUsed",
              label: "% used",
              required: true,
              path: ["data", "info", "percentUsed"],
              default: null,
              width: "1/6"
            },
            {
              formComponent: "currency-field",
              name: "dealerChargebackAmount",
              label: "Dealer Chargeback Amount",
              path: ["data", "info", "dealerChargebackAmount"],
              default: null,
              width: "1/6"
            },
            {
              formComponent: "currency-field",
              name: "wfdChargebackAmount",
              label: "WFD Chargeback Amount",
              path: ["data", "info", "wfdChargebackAmount"],
              default: null,
              width: "1/6"
            },
            {
              formComponent: "currency-field",
              name: "managerChargebackAmount",
              label: "Manager Chargeback Amount",
              path: ["data", "info", "managerChargebackAmount"],
              default: null,
              width: "1/6"
            },
            {
              formComponent: "currency-field",
              name: "managerChargebackAmount",
              label: "NET WFD Chargeback Amount",
              path: ["data", "info", "netWFDChargeBackAmount"],
              default: null,
              width: "1/6"
            }
          ]
        },
        {
          formComponent: "checkbox-field",
          name: "Extra Profit",
          label: "Extra Product Commission",
          width: "1/6",
          valueType: "boolean",
          path: ["data", "info", "extraProfitIncluded"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<Chargeback>(["data", "info", "extraProfitIncluded"]),
          toggle: (stateAccess: StateAccess): Chargeback =>
            stateAccess.set<Chargeback>(
              ["data", "info", "extraProfitIncluded"],
              !stateAccess.get<Chargeback>(["data", "info", "extraProfitIncluded"])
            ),
          default: false
        },
        {
          formComponent: "checkbox-field",
          name: "Use Extra Profit",
          label: "Extra Reserve",
          width: "1/6",
          valueType: "boolean",
          path: ["data", "info", "useExtraProfit"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<Chargeback>(["data", "info", "useExtraProfit"]),
          toggle: (stateAccess: StateAccess): Chargeback =>
            stateAccess.set<Chargeback>(
              ["data", "info", "useExtraProfit"],
              !stateAccess.get<Chargeback>(["data", "info", "useExtraProfit"])
            ),
          default: false
        },
        calculate
      ]
    },
    {
      formComponent: "segment",
      name: "Search Deal",
      width: "full",
      entities: [
        {
          formComponent: "text-field",
          name: "Ref #",
          label: "Ref #",
          width: "1/6",
          path: ["data", "deal", "refNumber"],
          default: null
        },
        {
          formComponent: "one-to-many-field",
          component: (
            stateAccess: StateAccess,
            _mainstateAccess: StateAccess,
            renderSet: RenderSet
          ) => (
            <LenderPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              lenderIdPath={["data", "lenderId"]}
              path={["data", "lender"]}
              name="lender"
              required={() => true}
              label="Lender"
            />
          ),
          name: "lender",
          label: "Lender",
          required: true,
          width: "1/6",
          path: ["data", "lender"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "VIN",
          label: "VIN",
          width: "1/6",
          path: ["data", "deal", "vehicle", "VIN"],
          default: null
        },
        {
          formComponent: "segment",
          width: "1/6",
          entities: [
            {
              formComponent: "text-field",
              name: "Applicant",
              label: "Applicant First Name",
              width: "1/2",
              path: ["data", "deal", "applicantFirstName"],
              default: null
            },
            {
              formComponent: "text-field",
              name: "Applicant",
              label: "Applicant Last Name",
              width: "1/2",
              path: ["data", "deal", "applicantLastName"],
              default: null
            }
          ]
        },

        {
          formComponent: "segment",
          width: "1/6",
          entities: [
            {
              formComponent: "text-field",
              name: "make",
              label: "Make",
              width: "1/2",
              path: ["data", "deal", "vehicle", "make"],
              default: null
            },
            {
              formComponent: "text-field",
              name: "model",
              label: "Model",
              width: "1/2",
              path: ["data", "deal", "vehicle", "model"],
              default: null
            }
          ]
        },
        {
          formComponent: "date-field",
          name: "contractDate",
          label: "Contract Date",
          width: "1/6",
          path: ["data", "deal", "contractDate"],
          default: null
        },
        {
          formComponent: "one-to-many-field",
          width: "full",
          component: (stateAccess, _, renderSet) => (
            <SelectChargebackDeal stateAccess={stateAccess} renderSet={renderSet} />
          ),
          label: "selectDeal",
          name: "selectDeal",
          default: null,
          path: null
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Deal Info",
      width: "full",
      entities: [
        {
          formComponent: "radio-field",
          possibleValues: collateralTypes.map((type) => ({
            label: type === "Recreational Vehicles" ? "RV" : type,
            value: type,
            labelStyle: { whiteSpace: "nowrap", display: "inline-block" }
          })),
          name: "type",
          label: "Type",
          width: "1/3",
          path: ["data", "deal", "collateralType"],
          default: "Automotive"
        },
        perviewDeal,
        {
          formComponent: "percentage-field",
          name: "interestRate",
          label: "Interest rate %",
          width: "1/6",
          path: ["data", "deal", "interestRate"],

          default: null
        },
        {
          formComponent: "months-field",
          name: "term",
          label: "Term",
          width: "1/6",
          path: ["data", "deal", "term"],
          default: null
        },
        {
          formComponent: "date-field",
          name: "chargebackDate",
          label: "Funding date",
          width: "1/6",
          path: ["data", "deal", "fundingDate"],
          required: true,
          default: null
        },
        dealership,
        assignee
      ]
    }
  ]
};

export const addChargebackStruct: Model<Chargeback> = {
  formComponent: "model",
  schema: "new_chargeback",
  name: "chargeback",
  entities: [
    dealChargebackStruct,
    {
      formComponent: "one-to-many-field",
      width: "full",
      component: (stateAccess, _, renderSet) => (
        <AddChargeback stateAccess={stateAccess} renderSet={renderSet} />
      ),
      label: "selectDeal",
      name: "selectDeal",
      default: null,
      path: null
    }
  ]
};
export const chargebackStruct: Model<Chargeback> = {
  formComponent: "model",
  schema: "new_chargeback",
  name: "chargeback",
  entities: [dealChargebackStruct, files]
};
