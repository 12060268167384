import { Prescreen } from "./types";
import { PermissionStruct, permissionNode, relationPermissionNode } from "components/Roles/types";

export type PrescreenPermissionsStruct = PermissionStruct<Prescreen>;

export const prescreenPermissionsStruct: PrescreenPermissionsStruct = {
  _id: permissionNode(" id"),
  createdAt: permissionNode("Created at"),
  updatedAt: permissionNode("Updated at"),
  deleted: permissionNode("Deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    dealershipId: permissionNode("dealershipId"),
    creditPrequalify: relationPermissionNode("creditChecks", "credit_score"),
    files: relationPermissionNode("files", "file"),
    info: {
      refNumber: permissionNode("Ref Number"),
      type: permissionNode("type"),
      vehicle: {
        VIN: permissionNode("VIN"),
        make: permissionNode("make"),
        model: permissionNode("model"),
        year: permissionNode("year")
      },
      applicant: {
        firstName: permissionNode("firstName"),
        middleName: permissionNode("middleName"),
        lastName: permissionNode("lastName"),
        birthDate: permissionNode("birthDate"),
        currentState: permissionNode("currentState"),
        currentAddress: permissionNode("currentAddress"),
        currentAddressNumber: permissionNode("currentAddressNumber"),
        currentCity: permissionNode("currentCity"),
        currentZipCode: permissionNode("currentZipCode"),
        driverLicenseNumber: permissionNode("driverLicenseNumber"),
        drivingLicenseState: permissionNode("drivingLicenseState"),
        driverLicenseIssued: permissionNode("driverLicenseIssued"),
        driverLicenseExpires: permissionNode("driverLicenseExpires")
      }
    }
  }
};
