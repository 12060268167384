import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverRoleRequest {
  _id: string;
}

export interface RecoverRoleResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverRoleRequest,
  RecoverRoleResponse
>("RecoverRole", {
  request: "recover_role"
});

export const recoverRoleActions = socketMessageSlice.actions;
export const recoverRole = socketAction;
export default socketMessageSlice.reducer;
