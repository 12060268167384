import { DeliveredDeal } from "./types";
import {
  PermissionStruct,
  permissionNode,
  multyPermissionNode,
  relationPermissionNode
} from "components/Roles/types";

export type DeliveredDealPermissionStruct = PermissionStruct<DeliveredDeal>;

export const deliveredDealPermissionStruct: DeliveredDealPermissionStruct = {
  _id: permissionNode(" id"),
  createdAt: permissionNode("Created at"),
  updatedAt: permissionNode("Updated at"),
  deleted: permissionNode("Deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      dealVariant: multyPermissionNode("Variant"),
      lenderId: permissionNode("Lender ID"),
      collateralType: permissionNode("Collateral Type"),
      dealId: permissionNode("Deal ID"),
      printedDocuments: multyPermissionNode("Printed Documents")
    }
  }
};
