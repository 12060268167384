import { AdditionalRequiredDocument } from "./types";
import { permissionNode, PermissionStruct, relationPermissionNode } from "components/Roles/types";

export type AdditionalRequiredDocumentPermissionsStruct = PermissionStruct<
  AdditionalRequiredDocument
>;

export const additionalRequiredDocumentPermissionStruct: AdditionalRequiredDocumentPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      name: permissionNode("Name"),
      requestedBy: {
        dealership: permissionNode("Dealership"),
        applicant: permissionNode("Applicant"),
        eSign: permissionNode("eSign")
      }
    }
  }
};
