import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { DealershipProgram } from "./types";
interface RecoverDealershipProgramRequest {
  _id: string;
}

export interface RecoverDealershipProgramResponse {
  message: DealershipProgram;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverDealershipProgramRequest,
  RecoverDealershipProgramResponse
>("RecoverDealershipProgram", {
  request: "recover_dealership_program"
});

export const recoverDealershipProgramActions = socketMessageSlice.actions;
export const recoverDealershipProgram = socketAction;
export default socketMessageSlice.reducer;
