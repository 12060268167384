import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteDmvRequest {
  _id: string;
}

export interface DeleteDmvResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteDmvRequest,
  DeleteDmvResponse
>("DeleteDmv", {
  request: "delete_dmv"
});

export const deleteDmvActions = socketMessageSlice.actions;
export const deleteDmv = socketAction;
export default socketMessageSlice.reducer;
