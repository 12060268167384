import { createListSlice } from "../Middlewares/listSliceCreator";
import { ExternalCreditApplication } from "./types";

const { ListSlice, getList } = createListSlice<ExternalCreditApplication>({
  name: "ExternalCreditApplicationList",
  request_topic: "all_external_credit_applications",
  reducers: {}
});

export default ListSlice.reducer;
export const getExternalCreditApplicationList = getList;
export const listExternalCreditApplicationActions = ListSlice.actions;
