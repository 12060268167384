import { createListSlice } from "../Middlewares/listSliceCreator";
import { Deal } from "./types";

const { ListSlice, getList } = createListSlice<Deal>({
  name: "DealListByIds",
  request_topic: "all_deals_by_ids",
  reducers: {}
});
export default ListSlice.reducer;
export const getDealListByIds = getList;
export const listDealByIdsActions = ListSlice.actions;
export const removeDealByIdsList = ListSlice.actions.removeList;
