import DateFnsUtils from "@date-io/date-fns";
import { Button } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { RootState } from "app/rootReducer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLockList } from "utils/models/LockEntity/listLockSlice";
import AccessControl from "../../Access/AccessControl";
import DealsLogDateTable from "./DealsLogDateTable";

export const moveDate = (x: Date, offset: number): Date => {
  const date = new Date(x);
  date.setDate(x.getDate() + offset);

  return date;
};

const checkScroll = (dates: Date[], seLazyDates: React.Dispatch<React.SetStateAction<Date[]>>) => {
  seLazyDates((prev) => [...prev, moveDate(prev[prev.length - 1], -1)]);
};

export default function (): JSX.Element {
  const [dates, setDates] = useState([
    new Date(),
    moveDate(new Date(), -1),
    moveDate(new Date(), -2)
  ]);
  const [lazyDates, setLazyDates] = useState([moveDate(new Date(), -dates.length)]);

  const dispatch = useDispatch();
  const handleDateChange = (date: Date | null) => {
    if (date) {
      const dateToSet =
        date.getMonth() === new Date().getMonth() && date.getFullYear() === new Date().getFullYear()
          ? new Date()
          : new Date(date?.getFullYear(), date?.getMonth() + 1, 0);
      setDates([dateToSet, moveDate(dateToSet, -1), moveDate(dateToSet, -2)]);
      setLazyDates([moveDate(dateToSet, -dates.length)]);
    }
  };

  const lockData = useSelector((state: RootState) => state.listLockSlice["all"]);
  useEffect(() => {
    if (lockData?.entities === undefined) dispatch(getLockList("all"));
  }, [dispatch, lockData]);

  return (
    <div style={{ position: "relative" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            variant="inline"
            inputVariant="filled"
            openTo="month"
            margin="dense"
            size="small"
            disableFuture
            views={["year", "month"]}
            value={dates[0]}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
      </div>

      <AccessControl
        requiredPermissions={[
          { entity: "deal", action: "read" },
          { entity: "deal", action: "menu" }
        ]}
      >
        <>
          {[...dates, ...lazyDates].map((date, index) => {
            const sliceName = "table_" + date.toISOString().slice(0, 10);
            return (
              <DealsLogDateTable sliceName={sliceName} index={index} date={date} key={index} />
            );
          })}
        </>
        <div style={{ textAlign: "center" }}>
          <Button
            variant={"contained"}
            onClick={() => {
              checkScroll(dates, setLazyDates);
            }}
          >
            Show older
          </Button>
        </div>
      </AccessControl>
    </div>
  );
}
