import { FormComponent, Model, createOptionsForSelect, Path } from "utils/models/fields";
import { State, DealType, County, Percent } from "./types";
import { StateAccess, RenderSet } from "utils/models/formGenerator";

import States from "../../us-states";
import DealTypes from "./DealTypes";
import CountySearch from "./CountySearch";
import React from "react";
import { Deal, DealData, Info, TaxesAndFees } from "../Deals/types";
import { getStateLabelByState } from "../../utils/functions";
export interface TaxableItem {
  name: string;
  label: string;
  path: Path<OmittedDeal>;
}

type OmittedTaxesAndFees = Omit<TaxesAndFees, "stateTaxes">;

type OmittedDealData = Omit<DealData, "info"> & {
  info: Omit<Info, "taxesAndFees"> & { taxesAndFees: OmittedTaxesAndFees };
};

export type OmittedDeal = Omit<Deal, "data"> & { data: Omit<OmittedDealData, "stateTax"> };

const data: TaxableItem[] = [
  {
    name: "serviceWarrSellPrice",
    label: "Service Warr. Sell Price",
    path: ["data", "info", "aftermarketOptions", "totalServiceWarrantySellPrice"]
  },
  {
    name: "GAPSellPrice",
    label: "GAP Sell Price",
    path: ["data", "info", "aftermarketOptions", "totalGAPSellPrice"]
  },
  {
    name: "PaintAndFabricationPrice",
    label: "Paint and Fabrication Sell Price",
    path: ["data", "info", "aftermarketOptions", "totalPaintAndFabricationPrice"]
  },
  {
    name: "prePaidMaintenancePrice",
    label: "Pre-Paid Maintenance Sell Price",
    path: ["data", "info", "aftermarketOptions", "totalPrePaidMaintenancePrice"]
  },
  {
    name: "wheelAndTirePrice",
    label: "Wheel & Tire Sell Price",
    path: ["data", "info", "aftermarketOptions", "totalWheelAndTirePrice"]
  },
  {
    name: "ecoPrice",
    label: "Eco Sell Price",
    path: ["data", "info", "aftermarketOptions", "totalEcoPrice"]
  },
  {
    name: "titleTransfer",
    label: "Title Transfer",
    path: ["data", "info", "taxesAndFees", "titleTransfer"]
  },
  { name: "License", label: "License", path: ["data", "info", "taxesAndFees", "license"] },
  {
    name: "documentRegistrationFees",
    label: "Document Registration Fees",
    path: ["data", "info", "taxesAndFees", "documentRegistrationFees"]
  },
  {
    name: "filingFees",
    label: "Filling Fees",
    path: ["data", "info", "taxesAndFees", "filingFees"]
  },
  { name: "otherFees", label: "Other fees", path: ["data", "info", "taxesAndFees", "otherFees"] }
];

const types = ["Marine", "Recreational Vehicles", "Automotive", "Power Sport"];

const taxPercentageStruct: FormComponent<Percent> = {
  formComponent: "segment",
  width: "1/2",
  entities: [
    {
      formComponent: "percentage-field",
      name: "percent",
      label: "Percent",
      width: "1/2",
      required: true,
      path: ["percent"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "description",
      label: "Description",
      width: "1/2",
      path: ["description"],
      required: false
    },
    {
      formComponent: "date-field",
      name: "startDate",
      label: "Start Date",
      width: "1/2",
      path: ["startDate"],
      required: false
    },
    {
      formComponent: "date-field",
      name: "endDate",
      label: "End Date",
      width: "1/2",
      path: ["endDate"],
      required: false
    }
  ]
};

const countyStruct: FormComponent<County> = {
  formComponent: "segment",
  width: "full",

  entities: [
    {
      formComponent: "select-field",
      name: "county",
      label: "County",
      path: ["county"],
      options: createOptionsForSelect({
        possibleValues: (stateAccess, path, mainStateAccess) => {
          const currentCounties = mainStateAccess
            .get<State>((path as any).slice(0, 4))
            .counties.reduce((acc: any, curr: any) => {
              return [...acc, curr.county];
            }, []);
          return States[mainStateAccess.get([]).data.info.name]?.counties.filter(
            (x: any) => !currentCounties.includes(x)
          );
        },
        getOptionLabel: (x) => x ?? " ",
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },

    {
      formComponent: "list-model",
      width: "full",
      name: "County tax",
      entity: taxPercentageStruct,
      path: ["percents"]
    }
  ]
};

export const typesStruct: FormComponent<DealType> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      name: "Type",
      width: "1/2",
      entities: types.map((el) => ({
        formComponent: "checkbox-field",
        name: el,
        label: el,
        path: ["type"],
        default: [],
        isDisabled: (stateAccess: StateAccess) =>
          stateAccess.get([]).data?.info?.dealTypes?.some((x: DealType) => x?.type?.includes(el)),
        isChecked: (stateAccess: StateAccess) =>
          stateAccess
            .get<DealType>(["type"])
            ?.some((x: string) => x === el),
        toggle: (stateAccess: StateAccess) => {
          if (
            stateAccess
              .get<DealType>(["type"])
              ?.some((x: string) => x === el)
          ) {
            stateAccess.set(
              ["type"],
              stateAccess
                .get<DealType>(["type"])
                .filter((x: string) => x !== el)
            );
          } else {
            stateAccess.set(
              ["type"],
              stateAccess.get<DealType>(["type"])
                ? stateAccess
                    .get<DealType>(["type"])
                    .concat(el)
                : [el]
            );
          }
        }
      }))
    }
  ]
};

export const dealTypeStruct: FormComponent<DealType> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      width: "1/2",
      entities: [
        {
          formComponent: "one-to-many-field",
          component: (
            stateAccess: StateAccess,
            mainStateAccess: StateAccess,
            renderSet: RenderSet
          ) => (
            <DealTypes
              stateAccess={stateAccess}
              mainStateAccess={mainStateAccess}
              renderSet={renderSet}
              path={["type"]}
            />
          ),
          required: true,
          name: "type",
          label: "type",
          width: "full",
          path: ["type"],
          default: null
        },
        {
          formComponent: "list-model",
          name: "County",
          width: "full",
          path: ["counties"],
          entity: countyStruct,
          search: (item, searchTerm) => {
            return new RegExp(`^${searchTerm}`, "i").test(item.county ?? "");
          }
        }
      ]
    },

    {
      formComponent: "list-model",
      width: "1/2",
      name: "State tax",
      entity: taxPercentageStruct,
      path: ["percents"]
    }
  ]
};

export const newStateStruct: Model<State> = {
  formComponent: "model",
  schema: "new_state",
  name: "state",
  entities: [
    {
      formComponent: "segment",
      name: "State",
      width: "full",
      entities: [
        {
          formComponent: "select-field",
          name: "state",
          label: "State",
          path: ["data", "info", "name"],
          options: createOptionsForSelect({
            possibleValues: () => Object.keys(States),
            getOptionLabel: (x) => getStateLabelByState(x),
            getSelectedOption: (x, y) => x === y
          }),
          default: "",
          required: true
        },
        {
          formComponent: "segment",
          name: "Taxable Items",
          width: "full",
          entities: data.map((x) => ({
            formComponent: "checkbox-field",
            name: x.name,
            label: x.label,
            path: ["data", "info", "taxableItems"],
            default: [],
            isDisabled: () => false,
            isChecked: (stateAccess: StateAccess) =>
              stateAccess
                .get<State>(["data", "info", "taxableItems"])
                ?.some((el: any) => JSON.stringify(el) === JSON.stringify(x.path)),
            toggle: (stateAccess: StateAccess) => {
              if (
                stateAccess
                  .get<State>(["data", "info", "taxableItems"] as any)
                  ?.some((el: any) => JSON.stringify(el) === JSON.stringify(x.path))
              ) {
                stateAccess.set(
                  ["data", "info", "taxableItems"] as any,
                  stateAccess
                    .get<State>(["data", "info", "taxableItems"] as any)
                    .filter((el: any) => JSON.stringify(el) !== JSON.stringify(x.path)) || []
                );
              } else {
                stateAccess.set(
                  ["data", "info", "taxableItems"] as any,
                  (stateAccess.get<State>(["data", "info", "taxableItems"]) || []).concat([x.path])
                );
              }
            }
          }))
        },
        {
          formComponent: "one-to-many-field",
          component: (stateAccess, mainStateAccess) => (
            <CountySearch stateAccess={mainStateAccess} />
          ),
          name: "search",
          width: "1/3",
          path: null,
          default: null
        },
        {
          formComponent: "list-model",
          width: "full",
          name: "Taxes",
          entity: dealTypeStruct,
          path: ["data", "info", "dealTypes"],
          search: (item, searchTerm) => {
            return (
              item.counties.filter((el: any) => {
                return new RegExp(`^${searchTerm}`, "i").test(el.county ?? "");
              }).length > 0
            );
          }
        }
      ]
    }
  ]
};
