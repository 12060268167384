import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverPrescreenRequest {
  _id: string;
}

export interface RecoverPrescreenResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverPrescreenRequest,
  RecoverPrescreenResponse
>("RecoverPrescreen", {
  request: "recover_prescreen"
});

export const recoverPrescreenActions = socketMessageSlice.actions;
export const recoverPrescreen = socketAction;
export default socketMessageSlice.reducer;
