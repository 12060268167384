import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { auth } from "../../firebase/firebase";
import { LenderTicket } from "components/LenderTickets/types";

const getLenderTickets = (query?: object): (() => Promise<LenderTicket[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${process.env.REACT_APP_HTTP_URL}/api/v1/lender_ticket/`, {
      headers: {
        authorization: `Bearer ${token}`,
        ...(query && Object.keys(query)?.length ? { query: JSON.stringify(query) } : {})
      }
    });
    return response.data.result.docs;
  };
};
const useGetLenderTickets = (query?: object) => {
  const queryKey = ["lender_tickets", query];

  const { data, isLoading, isSuccess, error, isFetching, isFetched } = useQuery({
    queryKey: queryKey,
    queryFn: getLenderTickets(query)
  });
  return {
    data,
    isSuccess,
    isLoading,
    isFetching,
    isFetched,
    error
  };
};
export default useGetLenderTickets;
