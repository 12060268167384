import { XlStyledDialog } from "components/common/StyledDialog";
import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  generateDefault,
  fillDefaultsByPath,
  generateForm,
  StateAccess,
  getByPath,
  setByPath,
  RenderSet
} from "utils/models/formGenerator";

import { applicantStruct } from "components/Applicants/model";
import { coApplicantStruct } from "components/Applicants/model";
import { addApplicant } from "components/Applicants/addApplicantSlice";
import { useDispatch, useSelector } from "react-redux";
import { EntityData, entityConfigs } from "utils/entitySlice";
import SaveIcon from "@material-ui/icons/Save";
import { editRenderSet, showRenderSet } from "utils/models/formRenderers";
import AccessControl from "components/Access/AccessControl";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "app/rootReducer";
import { Applicant } from "components/Applicants/types";
import CloseDialogButton from "../common/CloseDialogButton";
import { FormComponent } from "utils/models/fields";
import { Deal } from "./types";
import { modifyApplicant } from "components/Applicants/modifyApplicant";
import { HintTooltip } from "components/common/HintTooltip";

const handleFillWithOtherPersonAddressInfo = (
  externalApplicant: Applicant,
  setFormState: Dispatch<SetStateAction<Applicant>>
) => {
  setFormState((formState) => ({
    ...formState,
    data: {
      ...formState.data,
      info: {
        ...formState.data.info,
        currentAddressNumber: externalApplicant.data.info.currentAddressNumber,
        currentAddress: externalApplicant.data.info.currentAddress,
        currentZipCode: externalApplicant.data.info.currentZipCode,
        currentCountry: externalApplicant.data.info.currentCountry,
        currentState: externalApplicant.data.info.currentState,
        currentCounty: externalApplicant.data.info.currentCounty,
        currentCity: externalApplicant.data.info.currentCity,
        monthsAtCurrentAddress: externalApplicant.data.info.monthsAtCurrentAddress,
        yearsAtCurrentAddress: externalApplicant.data.info.yearsAtCurrentAddress
      }
    }
  }));
};

const handleFillWithOtherPersonPreviousAddressInfo = (
  externalApplicant: Applicant,
  setFormState: Dispatch<SetStateAction<Applicant>>
) => {
  setFormState((formState) => ({
    ...formState,
    data: {
      ...formState.data,
      info: {
        ...formState.data.info,
        previousAddressNumber: externalApplicant.data.info.previousAddressNumber,
        previousAddress: externalApplicant.data.info.previousAddress,
        previousZipCode: externalApplicant.data.info.previousZipCode,
        previousCountry: externalApplicant.data.info.previousCountry,
        previousState: externalApplicant.data.info.previousState,
        previousCounty: externalApplicant.data.info.previousCounty,
        previousCity: externalApplicant.data.info.previousCity,
        monthsAtPreviousAddress: externalApplicant.data.info.monthsAtPreviousAddress,
        yearsAtPreviousAddress: externalApplicant.data.info.yearsAtPreviousAddress
      }
    }
  }));
};
export default ({
  setSelected,
  applicant: externalApplicant,
  path,
  stateAccess: dealStateAccess,
  editMode
}: {
  setSelected: (applicant: Applicant) => void;
  applicant?: Applicant;
  path: ["data", "applicant"] | ["data", "coApplicant"];
  stateAccess: StateAccess;
  editMode: boolean | undefined;
}) => {
  const initialState = generateDefault(applicantStruct, {}, fillDefaultsByPath as any) as any;
  const coApplicantInitialState = generateDefault(
    applicantStruct,
    {},
    fillDefaultsByPath as any
  ) as any;

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const [requestId] = useState(uuidv4());
  const coApplicantRequestId = dealStateAccess.get(["_id"]);
  const [withoutCoApplicant, setWithoutCoApplicant] = useState(true);
  const formName = `deal-applicant-new`;

  const [formState, setFormState] = useState(initialState);
  const [coApplicantFormState, setCoApplicantFormState] = useState<Applicant>(
    coApplicantInitialState
  );
  const stateAccess: StateAccess = {
    get: (path) => getByPath(formState as any, path),
    set: (path, value): any =>
      setFormState(modifyApplicant(setByPath(formState as any, path, value), formState))
  };
  const coApplicantStateAccess: StateAccess = {
    get: (path) => getByPath(coApplicantFormState as any, path),
    set: (path, value): any =>
      setCoApplicantFormState(
        modifyApplicant(
          setByPath<Applicant>(coApplicantFormState as any, path as any, value),
          coApplicantFormState
        )
      )
  };
  const actionState = useSelector((state: RootState) => state.addApplicantSlice[requestId]);

  const coApplicantActionState = useSelector(
    (state: RootState) => state.addApplicantSlice[coApplicantRequestId]
  );
  const handleChangeWithoutCoApplicant = () => setWithoutCoApplicant(!withoutCoApplicant);
  const isMarried =
    stateAccess.get<Applicant>(["data", "info", "maritalStatus"]) === "Married" ? true : false;

  const showCoApplicantForm = !withoutCoApplicant;
  useEffect(() => {
    setFormState(generateDefault(applicantStruct, {}, fillDefaultsByPath as any) as any);
  }, []);
  useEffect(() => {
    if (actionState?.status === "success" && actionState?.data !== null) {
      setSelected({
        data: actionState.data.message.data,
        _id: actionState.data.message._id,
        createdAt: actionState.data.message.createdAt,
        updatedAt: actionState.data.message.updatedAt
      });
      setOpen(false);
    }
  }, [actionState, setSelected, dispatch]);
  useEffect(() => {
    if (coApplicantActionState?.status === "success" && coApplicantActionState?.data !== null) {
      dealStateAccess.set<Deal>(["data", "coApplicant"], {
        data: coApplicantActionState.data.message.data,
        _id: coApplicantActionState.data.message._id,
        createdAt: coApplicantActionState.data.message.createdAt,
        updatedAt: coApplicantActionState.data.message.updatedAt
      });
    }
  }, [coApplicantActionState, dealStateAccess]);

  useEffect(() => {
    dispatch({
      type: entityConfigs.applicant.edit.sliceActions.add.type,
      payload: { requestId }
    });

    return () => {
      dispatch({
        type: entityConfigs.applicant.edit.sliceActions.remove.type,
        payload: { requestId }
      });
    };
  }, [requestId, dispatch]);

  useEffect(() => {
    if (isMarried && path?.[1] === "applicant") setWithoutCoApplicant(false);
    else setWithoutCoApplicant(true);
  }, [isMarried, path]);

  const handleSubmit = (
    state: EntityData<"applicant">,
    coApplicantState: EntityData<"applicant">
  ) => (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (canSubmit) {
      setCanSubmit(false);
      dispatch(addApplicant({ ...state, requestId }));
      if (!withoutCoApplicant)
        dispatch(addApplicant({ ...coApplicantState, requestId: coApplicantRequestId }));
      setTimeout(() => {
        setCanSubmit(true);
      }, 2000);
    } else {
      alert("Please dont submit the form twice!");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hanldeOpenDialog = () => {
    setOpen(true);
  };

  const addressSegment: FormComponent<Applicant, any> = {
    formComponent: "one-to-many-field",
    component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => {
      return externalApplicant || !withoutCoApplicant ? (
        <div style={{ position: "relative" }}>
          <Button
            style={{ position: "absolute", left: "149px", top: "14px" }}
            color="primary"
            size="small"
            variant="contained"
            onClick={() =>
              handleFillWithOtherPersonAddressInfo(
                externalApplicant || formState,
                externalApplicant ? setFormState : setCoApplicantFormState
              )
            }
          >
            Same as the applicant
          </Button>
        </div>
      ) : (
        <></>
      );
    },
    name: "",
    path: null,
    width: "full",
    default: null
  };
  const previousAddressSegment: FormComponent<Applicant, any> = {
    formComponent: "one-to-many-field",
    component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => {
      return externalApplicant || !withoutCoApplicant ? (
        <div style={{ position: "relative" }}>
          <Button
            style={{ position: "absolute", left: "160px", top: "14px" }}
            color="primary"
            size="small"
            variant="contained"
            onClick={() =>
              handleFillWithOtherPersonPreviousAddressInfo(
                externalApplicant || formState,
                externalApplicant ? setFormState : setCoApplicantFormState
              )
            }
          >
            Same as the applicant
          </Button>
        </div>
      ) : (
        <></>
      );
    },
    name: "",
    path: null,
    width: "full",
    default: null
  };

  return (
    <>
      <AccessControl
        key={`add-applicant-button`}
        requiredPermissions={{ entity: "applicant", action: "create" }}
      >
        <IconButton
          size="small"
          style={{ margin: "3px 0px 0px 10px" }}
          aria-label={`add-applicant`}
          id={path?.join(".")}
          onClick={hanldeOpenDialog}
        >
          {editMode ? (
            <HintTooltip title="Click here to add an applicant.">
              <AddIcon />
            </HintTooltip>
          ) : (
            <HintTooltip title="Click here to view the current applicant.">
              <VisibilityIcon />
            </HintTooltip>
          )}
        </IconButton>
      </AccessControl>
      {open ? (
        <XlStyledDialog>
          <DialogContent>
            <CloseDialogButton closeFunction={handleClose} />
            <form
              id={formName}
              autoComplete="off"
              onSubmit={handleSubmit(formState, coApplicantFormState)}
            >
              {generateForm(
                externalApplicant
                  ? coApplicantStruct(addressSegment, previousAddressSegment)
                  : applicantStruct,
                stateAccess,
                [],
                dealStateAccess,
                editMode ? editRenderSet("new_applicant") : showRenderSet("new_applicant")
              )}
              {isMarried && path?.[1] === "applicant" ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      id="no-coapplicant"
                      checked={withoutCoApplicant}
                      onChange={handleChangeWithoutCoApplicant}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  id="Without-CoApplicant-check"
                  label="Without CoApplicant"
                />
              ) : null}

              {showCoApplicantForm ? (
                <>
                  <div
                    style={{
                      backgroundColor: "#254e70",
                      margin: "10px 0px",
                      width: "100%",
                      height: "5px",
                      borderRadius: "15px"
                    }}
                  ></div>

                  {generateForm(
                    coApplicantStruct(addressSegment, previousAddressSegment),
                    coApplicantStateAccess,
                    [],
                    dealStateAccess,
                    editRenderSet("new_applicant")
                  )}
                </>
              ) : null}
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="contained">
              Cancel
            </Button>
            {editMode ? (
              <Button
                form={formName}
                type="submit"
                color="primary"
                variant="contained"
                disabled={!canSubmit}
                suppressContentEditableWarning={true}
                contentEditable={canSubmit}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            ) : null}
          </DialogActions>
        </XlStyledDialog>
      ) : null}
    </>
  );
};
