import React from "react";
import { useDispatch } from "react-redux";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import CloseDialogButton from "./CloseDialogButton";

interface deleteRequest {
  _id: string;
}

interface Props {
  setDialogState: (x: { showDialog: false }) => void;
  deleteEntry: (request: deleteRequest) => (dispatch: Dispatch<AnyAction>) => Promise<void>;
  _id: string;
  callback: () => void;
}

export default function ConfirmDeleteDialog({ setDialogState, _id, deleteEntry, callback }: Props) {
  const dispatch = useDispatch();

  const handleClose = () => {
    setDialogState({ showDialog: false });
  };
  const deleteItem = () => {
    dispatch(deleteEntry({ _id }));
    callback();
    setDialogState({ showDialog: false });
  };

  return (
    <div>
      <Dialog
        id="confirm-delete-dialog"
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm delete
          <CloseDialogButton closeFunction={handleClose} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary" id="cancel">
            Cancel
          </Button>
          <Button onClick={deleteItem} variant="contained" color="secondary" autoFocus id="confirm">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
