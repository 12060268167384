import { StateAccess } from "utils/models/formGenerator";
import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  createStyles,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import { Deal } from "./types";
import { Condition, Tier } from "../DealershipPrograms/types";
import { calcCommissionableAmount, getDealershipProgramByDeal, resolveTier } from "./Calculations";
import Big from "big.js";
import { DealershipStatistics } from "components/Dealerships/types";
import InfoIcon from "@material-ui/icons/Info";
import { HintTooltip } from "components/common/HintTooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: { background: "rgba(0,0,0,0.1)" },
    cell: {
      color: "black"
    },
    greenColor: {
      background: "#ff7fff99"
    },
    grayColor: {
      background: "rgba(255,255,255,0.1)"
    }
  })
);

const groupBy = <T extends any>(items: any[], key: string): { [key: string]: T[] } =>
  items?.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item]
    }),
    {}
  );
const formatConditions = (conditions: Condition[]) => {
  const groupedConditionsByCriteria = groupBy<Condition>(conditions, "criteria");

  return Object.entries(groupedConditionsByCriteria).map(([criteria, conditions]) => {
    const criteriaText = criteria === "deals count" ? "Transactions" : "Total Amount Financed";
    if (conditions.length === 1) {
      const condition = conditions[0];
      switch (condition.type) {
        case ">=":
          return <div>{`${condition.value}+ ${criteriaText}/Month`}</div>;
        case "<=":
          return <div>{`1-${condition.value} ${criteriaText}/Month`}</div>;
        default:
          return <div>Unknown criteria</div>;
      }
    } else {
      const min = Math.min(conditions[0].value, conditions[1].value);
      const max = Math.max(conditions[0].value, conditions[1].value);
      return <div>{`${min}-${max} ${criteriaText}/Month`}</div>;
    }
  });
};

const DealershipProfitPreview = ({ stateAccess }: { stateAccess: StateAccess }) => {
  const classes = useStyles();

  const deal: Deal = stateAccess.get([]);
  const statistics = deal?.data?.dealership?.data?.statistics ?? [];
  const dealershipProgram = getDealershipProgramByDeal(deal);

  const tiers = dealershipProgram?.data?.info?.tiers ?? [];
  const contractDate = deal.data.info.dealDates.contractDate as string;
  const statisticForTheMonth = statistics.find(
    (statistic) =>
      statistic.month === new Date(contractDate).getMonth() + 1 &&
      statistic.year === new Date(contractDate).getFullYear()
  );

  const foundTierIndex = resolveTier(statisticForTheMonth, tiers, "findIndex");

  const colorClass =
    new Date(contractDate).getMonth() < new Date().getMonth() &&
    new Date(contractDate).getFullYear() <= new Date().getFullYear()
      ? "greenColor"
      : "grayColor";

  const formatTotalProfit = () => {
    return (
      <table style={{ fontSize: "12px" }}>
        <tr>
          <td>Reserve:</td>
          <td>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
              deal?.data?.info?.profit?.wfdProfit?.reserveCommission ?? 0
            )}
          </td>
        </tr>
        <tr>
          <td>GAP Profit:</td>
          <td>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
              deal?.data?.info?.profit?.totalGAPProfit ?? 0
            )}
          </td>
        </tr>
        <tr>
          <td>Service Warranty Profit:</td>
          <td>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
              deal?.data?.info?.profit?.totalServiceWarrantyProfit ?? 0
            )}
          </td>
        </tr>
      </table>
    );
  };
  const formatSplitProfit = (tier: Tier) => {
    return (
      <table style={{ fontSize: "12px" }}>
        <tr>
          <td>Reserve Split:</td>
          <td>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
              new Big(deal?.data?.info?.profit?.wfdProfit?.reserveCommission ?? 0)
                .mul(tier?.commissionAmount ?? 0)
                .div(100)
                .toNumber()
            )}
          </td>
        </tr>
        <tr>
          <td>GAP Profit Split:</td>
          <td>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
              new Big(deal?.data?.info?.profit?.totalGAPProfit ?? 0)
                .mul(tier?.commissionAmount ?? 0)
                .div(100)
                .toNumber()
            )}
          </td>
        </tr>
        <tr>
          <td>Service Warranty Profit Split:</td>
          <td>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
              new Big(deal?.data?.info?.profit?.totalServiceWarrantyProfit ?? 0)
                .mul(tier?.commissionAmount ?? 0)
                .div(100)
                .toNumber()
            )}
          </td>
        </tr>
      </table>
    );
  };
  return (
    <>
      <Table className={classes.table} size={"medium"}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align={"center"}></TableCell>
            {tiers.map((tier, index) => (
              <TableCell
                align={"center"}
                key={index}
                className={foundTierIndex === index ? classes[colorClass] : classes.cell}
              >
                {formatConditions(tier.conditions)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.cell}>Dealer total backend</TableCell>
            {tiers.map((tier, index) => (
              <TableCell
                key={index}
                align={"center"}
                className={foundTierIndex === index ? classes[colorClass] : classes.cell}
              >
                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  deal.data.info.profit?.grossProfit ?? 0
                )}
                <HintTooltip title={formatTotalProfit()}>
                  <InfoIcon style={{ color: "#d06c1f" }} />
                </HintTooltip>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>Dealer Profit %</TableCell>
            {tiers.map((tier, index) => (
              <TableCell
                key={index}
                className={foundTierIndex === index ? classes[colorClass] : classes.cell}
                align={"center"}
              >
                {tier.commissionAmount} {tier.commissionType === "fixed" ? "$" : "%"}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>Total Dealership Profit</TableCell>
            {tiers.map((tier, index) => (
              <TableCell
                key={index}
                className={foundTierIndex === index ? classes[colorClass] : classes.cell}
                align={"center"}
              >
                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  tier.commissionType === "fixed"
                    ? tier.commissionAmount
                    : new Big(deal?.data?.info?.profit?.grossProfit ?? 0)
                        .mul(tier?.commissionAmount ?? 0)
                        .div(100)
                        .round(2, 0)
                        .toNumber()
                )}
                <HintTooltip title={formatSplitProfit(tier)}>
                  <InfoIcon style={{ color: "#d06c1f" }} />
                </HintTooltip>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      <div style={{ fontSize: "12px", marginTop: "5px" }}>
        Dealership progress for {new Date(contractDate).getMonth() + 1}/
        {new Date(contractDate).getFullYear()}: ({statisticForTheMonth?.dealsCount ?? 0})
        transactions
      </div>
    </>
  );
};

export default DealershipProfitPreview;
