import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { Validation, FormState } from "./types";

interface AddValidationResponse {
  data: Validation;
}

interface AddValidationRequest {
  data: FormState;
}
const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddValidationRequest,
  AddValidationResponse
>("AddValidation", {
  request: "new_document_validation"
});

export const addValidationActions = socketMessageSlice.actions;
export const addValidation = socketAction;
export default socketMessageSlice.reducer;
