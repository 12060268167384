import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

type AddCustomReportRequest = FormState;

export interface AddCustomReportResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddCustomReportRequest,
  AddCustomReportResponse
>("AddCustomReport", {
  request: "new_custom_report"
});

export const addCustomReportActions = socketMessageSlice.actions;
export const addCustomReport = socketAction;
export default socketMessageSlice.reducer;
