import { BusinessReportAdd } from "./types";
export default (
  newBusinessReport: BusinessReportAdd,
  oldBusinessReport: BusinessReportAdd
): BusinessReportAdd => {
  if (
    newBusinessReport.data.info.request.businessReportType &&
    newBusinessReport.data.info.request.businessReportType.type !==
      oldBusinessReport?.data?.info?.request?.businessReportType?.type
  ) {
    return {
      ...newBusinessReport,
      data: {
        ...newBusinessReport.data,
        lender:
          newBusinessReport.data.info.request.businessReportType.defaultParameters.defaultLender ||
          undefined,
        dealership:
          newBusinessReport.data.info.request.businessReportType.defaultParameters
            .defaultDealership || undefined,
        representative:
          newBusinessReport.data.info.request.businessReportType.defaultParameters
            .defaultRepresentative || undefined,
        info: {
          ...newBusinessReport.data.info,
          request: {
            ...newBusinessReport.data.info.request,
            timePeriod:
              newBusinessReport.data.info.request.businessReportType.defaultParameters
                .defaultTimePeriod || undefined
          }
        }
      }
    };
  }
  return newBusinessReport;
};
