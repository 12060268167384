import { XlStyledDialog } from "components/common/StyledDialog";
import React, { useState } from "react";

import { userStruct } from "components/Users/model";

import { generateForm, StateAccess, getByPath, setByPath } from "utils/models/formGenerator";

import { addUser } from "components/Users/addUserSlice";
import { useDispatch } from "react-redux";
import { EntityData } from "utils/entitySlice";
import SaveIcon from "@material-ui/icons/Save";
import { editRenderSet } from "utils/models/formRenderers";
import AccessControl from "components/Access/AccessControl";
import { v4 as uuidv4 } from "uuid";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import { DialogContent, DialogActions, Button, IconButton } from "@material-ui/core";
import CloseDialogButton from "../common/CloseDialogButton";
import { HintTooltip } from "components/common/HintTooltip";
interface Props {
  email: string;
}
export default ({ email }: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [requestId] = useState(uuidv4());
  const formName = `user-new`;
  const [formState, setFormState] = useState({
    data: {
      info: {
        firstName: "",
        lastName: "",
        email: email
      },
      roles: [],
      vehicleInsuranceCompany: null
    }
  });

  const stateAccess: StateAccess = {
    get: (path) => getByPath(formState as any, path),
    set: (path, value): any => setFormState(setByPath(formState as any, path, value))
  };

  const handleSubmit = (state: EntityData<"wfd_user">) => (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(false);
    dispatch(addUser({ ...state, requestId }));
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <AccessControl
        key={`add-user-button`}
        requiredPermissions={{ entity: "applicant", action: "create" }}
      >
        <IconButton size="small" aria-label={`add-user`} onClick={() => setOpen(true)}>
          <HintTooltip title={`Approve ${email}`}>
            <AddCircleIcon color="primary" />
          </HintTooltip>
        </IconButton>
      </AccessControl>
      {open && (
        <XlStyledDialog>
          <DialogContent>
            <CloseDialogButton closeFunction={handleClose} />
            <form id={formName} onSubmit={handleSubmit(formState as any)} autoComplete="off">
              {generateForm(
                userStruct,
                stateAccess,
                [],
                stateAccess,
                editRenderSet("update_wfd_user")
              )}
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary" variant="contained">
              Cancel
            </Button>

            <Button
              form={formName}
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </DialogActions>
        </XlStyledDialog>
      )}
    </>
  );
};
