import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

export type AddChargebackBillsRequest = {
  data: {
    month: number;
    year: number;
    dealershipIds: string[];
  };
};

export interface AddChargebackBillsResponse {
  message: string;
  status: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddChargebackBillsRequest,
  AddChargebackBillsResponse
>("AddChargebackBills", {
  request: "add_chargeback_bills"
});

export const addChargebackBillsActions = socketMessageSlice.actions;
export const addChargebackBills = socketAction;
export default socketMessageSlice.reducer;
