export default {
  name: { type: "string" },
  type: { type: "string" },
  isDefault: { type: "boolean", nullable: true },
  grouping: {
    type: "array",
    items: {
      type: "object",
      nullable: true,
      additionalProperties: true
    },
    nullable: true
  },
  groupColumns: {
    type: "array",
    nullable: true,
    items: {
      type: "string"
    }
  },
  aggregationColumns: {
    type: "array",
    nullable: true,
    items: {
      type: "object",
      nullable: true,
      additionalProperties: true
    }
  }
};
