import { FundingDocument } from "components/FundingDocuments/types";
import { CrudEntity, AddRequest } from "utils/types";

export type PrintedDocument = CrudEntity & {
  data: FormState;
};
export enum RequiredDocumentStatus {
  approved = "approved",
  pending = "pending",
  missing = "missing",
  rejected = "rejected"
}
export enum PrintedDocumentType {
  document_template = "document_template",
  other_document = "other_document"
}

export interface RejectionInfo {
  userId: string;
  note: string;
}

export interface PrintedDocumentRequest {
  data: FormState;
  availableToRolesIds?: string[] | null;
}

export type AddPrintedDocumentRequest = AddRequest<PrintedDocumentRequest>;

export interface FormState {
  dealId: string;
  fundingDocuments?: FundingDocument[];
  documentTemplateName?: string;
  documentTemplateId?: string;
  info: Info;
}
export interface Info {
  type: PrintedDocumentType;
  forApplicant?: boolean;
  forDealership?: boolean;
  name: string;
  pages?: { [key: number]: boolean };
  status: RequiredDocumentStatus;
  rejectionInfo?: RejectionInfo;
}
