import { useQuery } from "@tanstack/react-query";
import { getDealershipUsers } from "Chat/http/dealership_users";
import { HttpQuery } from "Chat/types/http";

const useDealershipUsers = (query?: HttpQuery) => {
  const queryKey = ["dealership-user", query ?? []];

  const {
    data: dealershipUsers,
    isLoading: dealershipUsersAreLoading,
    error: dealershipUsersError
  } = useQuery({
    queryKey,
    queryFn: getDealershipUsers(query),
    enabled: query !== undefined
  });

  return {
    data: dealershipUsers,
    isLoading: dealershipUsersAreLoading,
    error: dealershipUsersError
  };
};
export default useDealershipUsers;
