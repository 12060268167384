import { EntityType, EntityData } from "utils/entitySlice";
import { FormComponent } from "utils/models/fields";
import { Settings } from "./types";

export interface RangeField {
  name: string;
  label?: string;
  path: string[];
}
export const modelToData = <T extends EntityType>(
  model: FormComponent<EntityData<T>>,
  fields: RangeField[] = []
): RangeField[] => {
  switch (model.formComponent) {
    case "model":
      return [...fields, ...model.entities.map((x) => modelToData(x, fields)).flat()];
    case "segment":
      return [...fields, ...model.entities.map((x) => modelToData(x, fields)).flat()];
    case "one-to-many-field":
      if (model.struct)
        return [...fields, ...modelToData(model.struct as FormComponent<EntityData<T>>, fields)];
      return fields;
    case "currency-field":
    case "percentage-field":
    case "months-field":
    case "number-field":
    case "year-field":
      return [...fields, { name: model.name, path: model.path, label: model.label }];

    default:
      return fields;
  }
};

export const getFieldWarning = (
  fieldName: string,
  fieldValue: number,
  settings?: Settings
): string | false => {
  const fieldRange = settings?.data?.info?.defaultNumberRanges?.find(
    (el) => el.field.name === fieldName
  );
  return fieldRange &&
    typeof fieldValue === "number" &&
    (fieldValue > fieldRange.max || fieldValue < fieldRange.min)
    ? `Field is outside the default range.`
    : false;
};
