import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

type AddTableSettings = { data: FormState };
interface AddTableSettingsResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddTableSettings,
  AddTableSettingsResponse
>("AddTableSettings", {
  request: "new_table_settings"
});

export const addTableSettingsActions = socketMessageSlice.actions;
export const addTableSettings = socketAction;
export default socketMessageSlice.reducer;
