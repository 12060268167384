import { IconButton } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { StateAccess } from "utils/models/formGenerator";
import { Deal } from "components/Deals/types";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useSnackbar } from "notistack";
import { ASCInsurance } from "./types";
import { deleteASCInsurance, deleteASCInsuranceActions } from "./deleteASCInsuranceSlice";

interface Props {
  index: number;
  stateAccess: StateAccess;
}

const handleDelete = (index: number, stateAccess: StateAccess) => {
  const insurances = stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "insurances"]);
  stateAccess.set<Deal>(
    ["data", "info", "aftermarketOptions", "insurances"],
    insurances?.filter((el: any, insuranceIndex: number) => index !== insuranceIndex)
  );
};

export default function DeleteASCInsurance({ stateAccess, index }: Props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteContractRequestId] = useState(uuidv4());

  const cancelASCState = useSelector(
    (state: RootState) => state.deleteASCInsurance[deleteContractRequestId]
  );

  const insurance: ASCInsurance = stateAccess.get<Deal>([
    "data",
    "info",
    "aftermarketOptions",
    "insurances",
    index
  ]);
  const isSigned = insurance?.ID;

  const handleDeleteContract = () => {
    if (insurance?.ID)
      dispatch(
        deleteASCInsurance({
          requestId: deleteContractRequestId,
          data: {
            AccountNumber: insurance?.AccountNumber,
            ID: insurance?.ID
          }
        })
      );
  };

  useEffect(() => {
    const insurances = stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "insurances"]);
    if (cancelASCState !== undefined && cancelASCState.status !== undefined) {
      switch (cancelASCState?.status) {
        case "success":
          stateAccess.set<Deal>(
            ["data", "info", "aftermarketOptions", "insurances"],
            insurances?.filter((el: any, insuranceIndex: number) => index !== insuranceIndex)
          );
          enqueueSnackbar(`Successfully deleted a contract.`, {
            variant: "success"
          });

          dispatch({
            type: deleteASCInsuranceActions.none.type,
            payload: { requestId: deleteContractRequestId }
          });
          break;
        case "waiting":
          break;
        case "error":
          enqueueSnackbar(`Unable to delete the contract.`, {
            variant: "error"
          });
          dispatch({
            type: deleteASCInsuranceActions.none.type,
            payload: { requestId: deleteContractRequestId }
          });
          break;
      }
    }
  }, [cancelASCState, stateAccess, index, enqueueSnackbar, deleteContractRequestId, dispatch]);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <IconButton
        id={`delete-${insurance?.chosenRate?.TermID}`}
        style={{
          color: isSigned ? "#254e70" : "red"
        }}
        onClick={() => (isSigned ? handleDeleteContract() : handleDelete(index, stateAccess))}
      >
        <RemoveCircleIcon />
      </IconButton>
    </div>
  );
}
