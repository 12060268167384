import { InsuranceSoldBy } from "components/Deals/types";

export interface ASCPreRatesData {
  AccountNumber: string;
  DRFC: number;
  ManufacturerWarranty?: string;
  BrandedOrSalvaged?: string;
  LiftedVehicle?: string;
  EngineCC: number;
  VIN?: string;
  VehicleYear?: string;
  VehicleMakeName?: string;
  VehicleModelName?: string;
  VehicleMakeID?: string;
  VehicleModelID?: string;
}

export interface getASCRatesReqeust {
  requestId: string;
  data: ASCPreRatesData & {
    AccountNumber: string;
    Mileage: number;
  };
}
export interface getASCRatesResponse {
  message: {
    data: {
      Terms: {
        Term: Term[];
      };
    };
  };
}
const aggrementType = ["Quote", "Agreement", "Cancellation"] as const;
export interface sumbitASCRequest {
  requestId: string;
  data: {
    YourEmailAddress?: string;
    AccountNumber: string;
    AgreementType?: typeof aggrementType[number];
    ID: number;
    Program: number;
    Plan: number;
    TermID: number;
    VIN?: string;
    VehicleYear?: string;
    VehicleMakeID?: string;
    VehicleModelID?: string;
    Mileage: number;
    VehicleCost: string;
    FirstName?: string;
    LastName?: string;
    Address?: string;
    AddressCont?: string;
    City?: string;
    State?: string;
    Zip?: string;
    Phone?: string;
    LienholderID: number;
    LienholderName?: string;
    EffectiveDate: Date;
    DRFC: number;
    SalesRep: number;
    SalesRepFirstName?: string;
    SalesRepLastName?: string;
    TotalCareCoverage?: string;
    IncreaseLiabilityLimit?: string;
    TechPackage?: string;
    CommercialVehicle?: string;
    EngineCC: number;
    PowerSportsWarrantyRemaining?: string;
    PowerSportsInServiceDate?: string;
    LiftedVehicle?: string;
    DisplayCharges?: string;
  };
}
export interface sumbitASCResponse {
  message: {
    data: {
      ProcessTransactionResult: {
        Agreement: {
          ID: string;
          AgreementNumber: string;
          URL: string;
          PDF: string;
          TotalCost: string;
          CustomerCost: string;
          DealerCost: string;
        };
      };
    };
  };
}
export interface deleteASCInsuranceRequest {
  requestId: string;
  data: {
    AccountNumber: string;
    ID: string;
  };
}
export interface deleteASCInsuranceResponse {
  message: {
    data: {
      CancelTransactionResult: {
        Agreement: {
          ID: string;
          Status: string;
        };
      };
    };
  };
}

export const surchargesTypes = [
  "TechPackage",
  "CommercialVehicle",
  "IncreaseLiabilityLimit"
] as const;
export type SurchargeName = typeof surchargesTypes[number];
export interface Surcharges {
  IncreaseLiabilityLimit?: string;
  CommercialVehicle?: string;
  TechPackage?: string;
}
export interface Term extends Surcharges {
  ProgramID: number;
  ProgramName: string;
  PlanID: number;
  PlanName: string;
  TermID: number;
  TermName: string;
  Miles: number;
  Months: number;
  FinalOdometer: number;
  Expires: string;
  FactoryWarranty: string;
  PlanCost: string;
  TotalCareCoverage: string;
  TotalCost: string;
  LOL: number;
}

export interface ASCInsurance {
  type: "ASC";
  provider: string;
  id: string;
  ID?: string;
  AccountNumber: string;
  AgreementNumber?: string;
  PDF?: string;
  AgreementType?: typeof aggrementType[number];
  LiftedVehicle?: string;
  DisplayCharges?: string;
  chosenRate: Term & {
    surcharges: { name: typeof surchargesTypes[number]; value: string; checked: boolean }[];
    DRFC: number;
    EngineCC: number;
    dealerCostPrice: number;
    remitPrice: number;
    retailPrice: number;
    profit: number;
    insuranceType: "service warranty";
    AgreementType?: typeof aggrementType[number];
    LiftedVehicle?: string;
    DisplayCharges?: string;
    soldBy?: InsuranceSoldBy;
  };
  originalRate?: Term;
}
