import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "app/store";

import { auth } from "../../firebase/firebase";
import { SignUp } from "./types";

const initialState: SignUp = {
  signUpSuccess: false,
  signUpError: false,
  errorMessage: null
};

const signUpSlice = createSlice({
  name: "singUp",
  initialState,
  reducers: {
    requestSignUp(state) {
      return {
        ...state,
        signUpSuccess: false,
        signUpError: false
      };
    },
    receiveSignUp(state) {
      return {
        ...state,
        signUpSuccess: true,
        signUpError: false
      };
    },
    signUpError(state, action: PayloadAction<{ code: string; message: string }>) {
      return {
        ...state,
        signUpSuccess: false,
        signUpError: true,
        errorMessage: action.payload.message
      };
    }
  }
});

export const signUp = (
  email: string,
  password: string,
  confirm_password: string,
  navigate: any
): AppThunk => async (dispatch) => {
  if (password === confirm_password) {
    dispatch(signUpSlice.actions.requestSignUp());
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        dispatch(signUpSlice.actions.receiveSignUp());
        auth.signOut();
        navigate.push("/verify-email");
        response.user?.sendEmailVerification();
        return response.user;
      })
      .catch((error) => {
        dispatch(
          signUpSlice.actions.signUpError({
            code: error.code,
            message: error.message
          })
        );
      });
  } else {
    dispatch(
      signUpSlice.actions.signUpError({
        code: "auth/passwords-mismatch",
        message: "Passwords don't match"
      })
    );
  }
};

export default signUpSlice.reducer;
