import React, { useState } from "react";
import {
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Tooltip
} from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { StateAccess } from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";
import RolesPreview from "components/Roles/RolesPreview";
import { EntityType } from "utils/entitySlice";

export default function RolesAvailabilityDialog({
  stateAccess,
  entityName
}: {
  stateAccess: StateAccess;
  entityName: EntityType;
}) {
  const availabilityRoles = stateAccess.get(["availableToRoles"]) ?? [];
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Tooltip title="Click here to change the availability to roles.">
          {availabilityRoles?.length > 0 ? <LockIcon /> : <LockOpenIcon />}
        </Tooltip>
      </IconButton>
      <Dialog fullWidth onClose={handleClose} open={open}>
        <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
          <Grid item xs={6} key="title">
            <DialogTitle>
              Change availability to roles
              <CloseDialogButton closeFunction={handleClose} />
            </DialogTitle>
          </Grid>
        </Grid>
        <DialogContent>
          <RolesPreview
            stateAccess={stateAccess}
            renderSet={editRenderSet(entityName === "netsuite" ? false : `update_${entityName}`)}
            path={["availableToRoles"]}
            label="Available to roles"
            name="availableToRoles"
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary" variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
