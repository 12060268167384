import Grid from "@material-ui/core/Grid";
import React from "react";
import { createOptionsForSelect } from "utils/models/fields";
import { editRenderSet } from "utils/models/formRenderers";
import { FilterProps, filterStateAccess } from "./types";

export default function RelationTypeFilter<T extends unknown>({
  setFilter,
  path,
  filterType,
  filter,
  label
}: FilterProps<T>) {
  const stateAccess = filterStateAccess(filter, setFilter, (values: any) => {
    if (values && values?.length > 0) {
      return {
        [path.join(".")]: {
          $in: values.map((value: any) => ({ value }))
        },
        ...(filterType === "aggregatedColumnFilter" ? { filterType } : {})
      };
    } else {
      setFilter([]);
    }
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {editRenderSet(false).multiSelectInputRenderer(
          {
            formComponent: "multiselect-field",
            name: label?.toLowerCase() ?? "relation-type-multi-filter",
            label: label ?? "Relation Type",
            required: false,
            path: [] as [],
            options: createOptionsForSelect({
              possibleValues: () => ["fulltimeF&I", "correspondent"],

              getOptionLabel: (relationType) => {
                switch (relationType) {
                  case "fulltimeF&I":
                    return "Full Time F&I";
                  case "correspondent":
                    return "Correspondent";
                  default:
                    return "";
                }
              },
              getSelectedOption: (x, y) => x === y
            }),
            valueToString: (x) => x
          },
          stateAccess,
          [],
          stateAccess,
          editRenderSet(false)
        )}
      </Grid>
    </Grid>
  );
}
