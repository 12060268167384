import { createListSlice } from "components/Middlewares/listSliceCreator";
import { ApiStatus } from "./types";

const { ListSlice, getList, getListAction } = createListSlice<ApiStatus>({
  name: "ApiStatus",
  request_topic: "api_status",
  reducers: {}
});

export default ListSlice.reducer;
export const getApiStatus = getList;
export const getApiStatusAction = getListAction;
export const listApiStatusActions = ListSlice.actions;
