import { DefaultAction, Permissions } from "components/Roles/types";
import React from "react";
import { EntityType } from "utils/entitySlice";
import store from "../../app/store";

interface RequiredPermission {
  entity: EntityType;
  action: DefaultAction;
}
interface AccessControlOptions {
  requiredPermissions: RequiredPermission | RequiredPermission[];
  children: JSX.Element | JSX.Element[];
  renderNoAccess?: () => JSX.Element | null;
}

const checkPermissions = (
  permissions: Permissions,
  requiredPermission: RequiredPermission
): boolean => {
  return permissions?.[requiredPermission.entity]?.[requiredPermission.action]?.hasPermission;
};

export const isAllowed = (
  requiredPermissions: RequiredPermission | RequiredPermission[]
): boolean => {
  const state = store.getState();
  const permissions = state.authSlice.user?.permissions;
  if (!permissions) return false;
  if (Array.isArray(requiredPermissions)) {
    return requiredPermissions?.every((requiredPermission) => {
      return checkPermissions(permissions, requiredPermission);
    });
  } else {
    return checkPermissions(permissions, requiredPermissions);
  }
};

export default ({
  requiredPermissions,
  children,
  renderNoAccess = () => null
}: AccessControlOptions) => {
  if (isAllowed(requiredPermissions)) {
    return <>{children}</>;
  }
  return renderNoAccess();
};
