export default {
  name: { type: "string", nullable: true },
  requiredForChecklist: { type: "boolean", nullable: true },
  relatedToWarranty: { type: "boolean", nullable: true },
  relatedToGap: { type: "boolean", nullable: true },
  requestedBy: {
    type: "object",
    properties: {
      applicant: { type: "boolean", nullable: true },
      dealership: { type: "boolean", nullable: true },
    },
  },
};
