import { CustomRegexes } from "../../helpers";

export default {
  email: {
    type: "string",
    pattern: CustomRegexes.EMAIL,
    errorMessage: {
      pattern: "must match the format <emailname>@<provider>.<org>"
    }
  },
  enabled: { type: "boolean", nullable: true },
  firstName: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: { pattern: "should not contain numbers" }
  },
  lastName: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: { pattern: "should not contain numbers" }
  },
  dashboard: {
    type: "array",
    nullable: true,
    items: {
      type: "object",
      required: ["type", "order"],
      properties: {
        type: { type: "string", nullable: true },
        order: { type: "integer", nullable: true }
      }
    }
  },
  creditSmartsNotification: { type: "boolean", nullable: true },
  dashboardTypes: {
    type: "object",
    nullable: true,
    properties: {
      "f&i": {
        type: "boolean",
        nullable: true
      },
      salesRep: {
        type: "boolean",
        nullable: true
      },
      accountant: {
        type: "boolean",
        nullable: true
      },
      allocation: {
        type: "boolean",
        nullable: true
      },
      lender: {
        type: "boolean",
        nullable: true
      },
      custom: {
        type: "boolean",
        nullable: true
      },
      coverageStatistics: {
        type: "boolean",
        nullable: true
      }
    }
  },
  onlineCreditApplicationNotification: { type: "boolean", nullable: true },
  contractNotification: { type: "boolean", nullable: true },
  commissions: {
    type: "array",
    nullable: true,
    items: {
      type: "object",
      properties: {
        type: { type: "string", nullable: true },
        amount: { type: "number", nullable: true },
        conditions: {
          type: "array",
          nullable: true,
          items: {
            type: "object",
            properties: {
              field: {
                type: "array",
                nullable: true,
                items: { type: "string" }
              },
              type: {
                type: ["string", "null"],
                nullable: true,
                enum: ["<", ">", "=", null],
                errorMessage: {
                  enum: "must be one of the following: '<', '>', '='"
                }
              },
              value: { type: "number", nullable: true }
            }
          }
        }
      }
    }
  },
  userType: {
    type: "string",
    nullable: true
  }
};
