import infoProperties from "./info_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import { CustomRegexes } from "../../helpers";

const data = {
  type: "object",
  required: ["info"],
  properties: {
    info: { type: "object", required: ["email"], properties: infoProperties },
    vehicleInsuranceCompanyId: { type: "string", nullable: true },
    rolesIds: { type: ["array", "null"], items: { type: "string" } },
    token: { type: "string", nullable: true }
  }
};

export default {
  new_wfd_user: {
    $id: "newWfdUser",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      }
    }
  },
  update_wfd_user: {
    $id: "updateWfdUser",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      }
    }
  },
  delete_wfd_user: {
    $id: "deleteWfdUser",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } }
  },
  recover_wfd_user: {
    $id: "recoverWfdUser",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } }
  },
  get_wfd_user: {
    $id: "getWfdUser",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } }
  },
  current_user: {
    $id: "currentUser",
    type: "object",
    properties: {}
  },
  update_current_user: {
    $id: "updateCurrentUser",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: {
        type: "object",
        properties: {
          info: {
            type: "object",
            properties: {
              firstName: { type: "string", nullable: true },
              lastName: { type: "string", nullable: true },
              email: {
                type: "string",
                pattern: CustomRegexes.EMAIL,
                nullable: true,
                errorMessage: {
                  pattern: "must match the format <emailname>@<provider>.<org>"
                }
              }
            }
          }
        }
      }
    }
  },
  all_wfd_users: {
    $id: "allWfdUsers",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties }
    ]
  },
  unapproved_users: {
    $id: "unapprovedUsers",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties }
    ]
  },
  delete_firebase_user: {
    $id: "deleteFirebaseUser",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } }
  },
  report_token: {
    $id: "reportToken",
    type: "object",
    properties: {}
  }
};
