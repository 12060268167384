import { Deal } from "components/DealerTrack/types";
import { FormComponent, Model } from "utils/models/fields";
import { StateAccess } from "utils/models/formGenerator";
import { AdditionalRequiredDocument } from "./types";

export const additionalRequiredDocumentInfo: FormComponent<AdditionalRequiredDocument> = {
  formComponent: "segment",
  name: "Additional Required Document",
  width: "full",
  entities: [
    {
      formComponent: "text-field",
      name: "name",
      label: "Name",
      width: "full",
      required: true,
      path: ["data", "info", "name"],
      default: null
    },

    {
      formComponent: "segment",
      name: "Requested by",
      width: "1/3",
      entities: [
        {
          formComponent: "checkbox-field",
          name: "applicant",
          label: "Applicant",
          width: "1/2",
          valueType: "boolean",
          path: ["data", "info", "requestedBy", "applicant"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<AdditionalRequiredDocument>([
              "data",
              "info",
              "requestedBy",
              "applicant"
            ]),
          toggle: (stateAccess: StateAccess): AdditionalRequiredDocument =>
            stateAccess.set<AdditionalRequiredDocument>(
              ["data", "info", "requestedBy", "applicant"],
              !stateAccess.get<Deal>(["data", "info", "requestedBy", "applicant"])
            ),
          required: true,
          default: false
        },
        {
          formComponent: "checkbox-field",
          name: "dealership",
          label: "Dealership",
          width: "1/2",
          valueType: "boolean",
          path: ["data", "info", "requestedBy", "dealership"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<AdditionalRequiredDocument>([
              "data",
              "info",
              "requestedBy",
              "dealership"
            ]),
          toggle: (stateAccess: StateAccess): AdditionalRequiredDocument =>
            stateAccess.set<AdditionalRequiredDocument>(
              ["data", "info", "requestedBy", "dealership"],
              !stateAccess.get<AdditionalRequiredDocument>([
                "data",
                "info",
                "requestedBy",
                "dealership"
              ])
            ),
          required: true,
          default: false
        },
        {
          formComponent: "checkbox-field",
          name: "eSign",
          label: "Required for eSign",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "requestedBy", "eSign"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<AdditionalRequiredDocument>(["data", "info", "requestedBy", "eSign"]),
          toggle: (stateAccess: StateAccess) =>
            stateAccess.set<AdditionalRequiredDocument>(
              ["data", "info", "requestedBy", "eSign"],
              !stateAccess.get<AdditionalRequiredDocument>(["data", "info", "requestedBy", "eSign"])
            ),
          required: true,
          default: false
        }
      ]
    }
  ]
};

export const AdditionalRequiredDocumentStruct: Model<AdditionalRequiredDocument> = {
  formComponent: "model",
  schema: "new_additional_required_document",
  name: "additional_required_document",

  entities: [{ formComponent: "segment", entities: [additionalRequiredDocumentInfo] }]
};
