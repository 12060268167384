import {
  createSocketMessageSlice,
  Identifiable
} from "../../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditChecklistData {
  _id: string;
  updatedAt: string;
  data: FormState;
}
interface EditChecklistRequest {
  _id: string;
  data: FormState;
}

interface EditChecklistResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditChecklistRequest,
  EditChecklistResponse
>("EditChecklist", {
  request: "update_checklist"
});

export const editChecklistActions = socketMessageSlice.actions;
export const editChecklist = (request: EditChecklistData & Identifiable) => {
  return socketAction(request);
};
export default socketMessageSlice.reducer;
