import React from "react";

import { Button, Dialog, DialogContent, DialogActions } from "@material-ui/core";

import Iframe from "react-iframe";
import CloseDialogButton from "../common/CloseDialogButton";

interface Props {
  url: string;
  handleClose: () => void;
  open: boolean;
}
export const CreditScoreDialog = ({ url, handleClose, open }: Props) => {
  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={handleClose}>
      <CloseDialogButton closeFunction={handleClose} />
      <DialogContent style={{ display: "flex", justifyContent: "center" }}>
        {typeof url === "string" ? (
          <Iframe url={url} width="800px" height="700px" display="block" position="relative" />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
