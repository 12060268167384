import { createListSlice } from "components/Middlewares/listSliceCreator";
import { ContractType } from "./types";

const { ListSlice, getList } = createListSlice<ContractType>({
  name: "ContractTypeList",
  request_topic: "all_contract_types",
  reducers: {}
});
export default ListSlice.reducer;
export const getContractTypeList = getList;
export const listContractTypeActions = ListSlice.actions;
export const removeContractTypeList = ListSlice.actions.removeList;
