import React, { useState, useEffect, useContext } from "react";
import {
  StateAccess,
  RenderSet,
  generateForm,
  getByPath,
  setByPath
} from "utils/models/formGenerator";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { CircularProgress, Button, Typography } from "@material-ui/core";
import { getProductPreRates, getProductPreRatesAction } from "./getProductPreRatesSlice";
import { FormComponent, createOptionsForSelect } from "utils/models/fields";
import { Surcharge } from "./types";
import { getProductRates } from "./getProductRatesSlice";
import { VerificationContext } from "components/Layout/Main";
import { Deal } from "components/Deals/types";
import { notifyWebhooks } from "utils/logging";

interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  productId: string;
  VIN: string;
  Odometer: string;
  CarStatus: string;
  DealType: string;
  surcharges: {
    [key: string]: string | number | Date;
  };
  setSurchargesState: any;
  requestId: string;
  productState: any;
}

const defaultState = {
  isLoading: false,
  surchargesList: [],
  error: false,
  errorMessage: undefined
};

export default function InsuranceProductPreRates({
  stateAccess,
  renderSet,
  productId,
  VIN,
  Odometer,
  CarStatus,
  DealType,
  surcharges,
  setSurchargesState,
  requestId,
  productState
}: Props) {
  const dispatch = useDispatch();
  const [state, setState] = useState<{
    isLoading: boolean;
    surchargesList: Surcharge[];
    error: boolean;
    errorMessage: string | undefined;
  }>(defaultState);

  const surchargesStateAccess = {
    get: (path: any) => getByPath(surcharges, path),
    set: (path: any, value: any) => setSurchargesState(setByPath(surcharges, path, value))
  };

  const actionStatePreRates = useSelector(
    // @ts-ignore
    (state: RootState) => state.getProductPreRatesSlice[requestId]
  );

  const checkDataValidity = useContext(VerificationContext);

  const requiredFields = [
    { name: "Product Id", value: productId },
    { name: "Request Id", value: requestId },
    { name: "Vehicle -> VIN", value: VIN },
    { name: "Vehicle -> Odometer", value: Odometer },
    { name: "Vehicle -> Unit Status", value: CarStatus },
    { name: "Deal type", value: DealType }
  ];

  useEffect(() => {
    const getDefaultValue = (surchargeCode: string) => {
      const currentValue = surchargesStateAccess.get([surchargeCode]);
      const contractDate = stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"]);
      const parsedContractDate = contractDate ? new Date(contractDate) : null;
      if (currentValue) return currentValue;
      switch (surchargeCode) {
        case "CONTRACTDATE":
        case "VEHPURDATE":
        case "INSDATE":
          return parsedContractDate;

        case "AFINANCED":
          return stateAccess.get<Deal>(["data", "info", "payment", "dealTotal"]);

        case "FTERM":
          return stateAccess.get<Deal>(["data", "info", "payment", "numberOfPayments"]);

        case "MSRP":
          return stateAccess.get<Deal>(["data", "info", "vehicle", "MSRP"]);

        case "VEHPURPRICE":
          return stateAccess.get<Deal>(["data", "info", "price", "price"]);

        case "APR":
          return stateAccess.get<Deal>(["data", "info", "payment", "interestRate"]);

        default:
          return null;
      }
    };

    const surchargeEntities = state.surchargesList.reduce((acc, el) => {
      return { ...acc, [el.SurchargeCode]: getDefaultValue(el.SurchargeCode) };
    }, {});

    setSurchargesState(surchargeEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productState, setSurchargesState, state.surchargesList]);

  const surchargeEntities = state.surchargesList
    .map((el) => {
      switch (el.DataType) {
        case "Date":
          return {
            formComponent: "date-field",
            name: `${el.SurchargeCode}`,
            label: `${el.Prompt}`,
            width: "1/3",
            path: [el.SurchargeCode],
            required: el.SurchargeType !== "Optional",
            default: null
          };
        case "Decimal":
          return {
            formComponent: "currency-field",
            name: `${el.SurchargeCode}`,
            label: `${el.Prompt}`,
            width: "1/3",
            path: [el.SurchargeCode],
            required: el.SurchargeType !== "Optional",
            default: null
          };
        case "Numeric":
          return {
            formComponent: "currency-field",
            currencySymbol: "",
            name: `${el.SurchargeCode}`,
            label: `${el.Prompt}`,
            width: "1/3",
            path: [el.SurchargeCode],
            required: el.SurchargeType !== "Optional",
            default: null
          };
        case "String":
          return {
            formComponent: "text-field",
            name: `${el.SurchargeCode}`,
            label: `${el.Prompt}`,
            width: "1/3",
            path: [el.SurchargeCode],
            required: el.SurchargeType !== "Optional",
            default: null
          };
        case "Boolean":
          return {
            formComponent: "select-field",
            name: `${el.SurchargeCode}`,
            label: `${el.Prompt}`,
            width: "1/3",
            path: [el.SurchargeCode],
            options: createOptionsForSelect({
              possibleValues: () => ["true", "false"],
              getOptionLabel: (x) => (x === "true" ? "Yes" : x === "false" ? "No" : " "),
              getSelectedOption: (x, y) => x === y
            }),
            required: el.SurchargeType !== "Optional",
            default: null
          };
        case "Enum":
          return {
            formComponent: "select-field",
            name: `${el.SurchargeCode}`,
            label: `${el.Prompt}`,
            width: "1/3",
            path: [el.SurchargeCode],
            options: createOptionsForSelect({
              possibleValues: () => el.SurchargeOptions.SurchargeOption,
              getOptionLabel: (x) => x,
              getSelectedOption: (x, y) => x === y
            }),
            required: el.SurchargeType !== "Optional",
            default: null
          };

        default:
          notifyWebhooks({
            message: "A field fron F&I express apeared that is not handled by the system",
            field: el
          });
          return undefined;
      }
    })
    .filter((x) => typeof x === "object");
  const surchargesStruct: FormComponent<Surcharge> = {
    formComponent: "segment",
    width: "full",
    //@ts-ignore
    entities: surchargeEntities
  };

  useEffect(() => {
    if (productId !== null && productId !== undefined) {
      dispatch(
        getProductPreRates({
          productId,
          requestId,
          VIN,
          Odometer,
          CarStatus: CarStatus.charAt(0).toUpperCase() + CarStatus.slice(1),
          DealType
        })
      );
    }
  }, [dispatch, requestId, productId, VIN, Odometer, CarStatus, DealType]);

  useEffect(() => {
    if (actionStatePreRates !== undefined && actionStatePreRates.status !== undefined) {
      switch (actionStatePreRates.status) {
        case "error":
          setState({ ...state, isLoading: false, error: true });
          dispatch({
            type: getProductPreRatesAction.none.type,
            payload: { requestId }
          });
          break;
        case "waiting":
          setState({ ...state, isLoading: true });
          dispatch({
            type: getProductPreRatesAction.none.type,
            payload: { requestId }
          });
          break;
        case "success":
          const { EX1PreRateResponse } = actionStatePreRates.data.message.data;
          const statusCode = EX1PreRateResponse.MsgHeader.Message[0].StatusCode || "1000";
          const errorMessage =
            EX1PreRateResponse.MsgHeader.Message[0].Description || "Unexpected error";

          if (statusCode === "0000") {
            setState({
              ...state,
              isLoading: false,
              surchargesList: EX1PreRateResponse.Surcharges.Surcharge,
              error: false,
              errorMessage: undefined
            });
          } else {
            setState({ ...state, isLoading: false, error: true, errorMessage });
          }
          dispatch({
            type: getProductPreRatesAction.none.type,
            payload: { requestId }
          });
          break;
      }
    }
  }, [dispatch, requestId, state, actionStatePreRates]);

  const surchargesTemp = Object.entries(surcharges).map(([key, value]) => ({
    SurchargeCode: key,
    Value:
      ["VEHPURDATE", "INSDATE", "CONTRACTDATE"].includes(key) &&
      value !== null &&
      !isNaN(Date.parse(value as string))
        ? new Date(value as Date)?.toISOString()?.slice(0, 10)
        : value
  }));

  const handleGetRates = () => {
    dispatch(
      getProductRates({
        productId,
        requestId,
        VIN,
        Odometer,
        CarStatus: CarStatus.charAt(0).toUpperCase() + CarStatus.slice(1),
        DealType,
        Surcharge: surchargesTemp
      })
    );
  };

  const handleClick = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    checkDataValidity(requiredFields, handleGetRates);
  };

  return (
    <>
      {!state.error ? (
        state.isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px"
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <form id="preRates-form" onSubmit={handleClick}>
            {generateForm(
              surchargesStruct,
              surchargesStateAccess,
              [],
              surchargesStateAccess,
              renderSet
            )}
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
              {state.surchargesList.length !== 0 && (
                <Button form="preRates-form" type="submit" color="primary" variant="contained">
                  Get rates
                </Button>
              )}
            </div>
          </form>
        )
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6">{state.errorMessage}</Typography>
        </div>
      )}
    </>
  );
}
