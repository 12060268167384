import info_properties from "./info_properties";
import notes from "../common/notes_schema";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import { Schema } from "ajv";

const unrequiredExternalId = { type: "string", nullable: true };

const data: Schema = {
  type: "object",
  properties: {
    info: { type: "object", properties: info_properties },
    userId: unrequiredExternalId,
    notes: notes
  }
};

export default {
  new_dealership_ticket: {
    $id: "newDealershipTicket",
    type: "object",
    required: ["data"],
    properties: {
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      }
    }
  },
  update_dealership_ticket: {
    $id: "updateDealershipTicket",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      }
    }
  },
  delete_dealership_ticket: {
    $id: "deleteDealershipTicket",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } }
  },
  recover_dealership_ticket: {
    $id: "recoverDealershipTicket",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } }
  },
  get_dealership_ticket: {
    $id: "getDealershipTicket",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } }
  },
  all_dealership_tickets: {
    $id: "allDealershipTickets",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties }
    ]
  }
};
