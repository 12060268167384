import { Model } from "utils/models/fields";
import { VehicleInsuranceCompany } from "./types";

export const vehicleInsuranceCompanyStruct: Model<VehicleInsuranceCompany> = {
  formComponent: "model",
  schema: "new_vehicle_insurance_company",
  name: "vehicle_insurance_company",
  entities: [
    {
      formComponent: "text-field",
      name: "name",
      label: "Name",
      width: "full",
      required: true,
      path: ["data", "info", "name"],
      default: null
    }
  ]
};
