import { Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";

import { RootState } from "app/rootReducer";
import { VerificationContext } from "components/Layout/Main";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { addChargeback } from "components/Chargebacks/addChargebackSlice";

import { Chargeback, ChargebackType, FormState, Reason } from "./types";

export const initialChargebackDealState = {
  applicantFirstName: "",
  applicantLastName: "",
  refNumber: "",
  contractDate: null,
  collateralType: "Automotive",
  interestRate: "",
  term: "",
  vehicle: { make: "", model: "", VIN: "" }
};
const initialChargebackState: { data: FormState } = {
  data: {
    deal: initialChargebackDealState,
    info: {
      refId: "",
      reason: "" as Reason,
      type: "" as ChargebackType,
      chargebackDate: null,
      payoffDate: null,
      totalChargebackAmount: "",
      dealerChargebackAmount: "",
      wfdChargebackAmount: "",
      managerChargebackAmount: "",
      netWFDChargeBackAmount: "",
      wfdChargebackCalculation: "",
      netWFDChargeBackCalculation: "",
      dealerChargebackCalculation: "",
      managerChargebackCalculation: "",
      percentUsed: ""
    },
    dealership: null,
    lender: null,
    manager: null
  }
};

export default ({ stateAccess }: { stateAccess: StateAccess; renderSet: RenderSet }) => {
  const dispatch = useDispatch();
  const chargeback = stateAccess.get([]) as Chargeback;
  const [requestId] = useState("add-chargeback-no-redirect");
  const checkDataValidity = useContext(VerificationContext);
  const requiredFields = [
    { name: "Type", value: chargeback?.data.info?.type },
    { name: "Chargeback date", value: chargeback?.data.info?.chargebackDate },
    { name: "Chargback amount", value: chargeback?.data.info?.totalChargebackAmount },
    { name: "Chargback reason", value: chargeback?.data.info?.reason },
    { name: "Percent used", value: chargeback?.data.info?.percentUsed }
  ];

  const handleAdd = () => dispatch(addChargeback({ data: chargeback.data, requestId }));
  const addFunction = () => {
    checkDataValidity(requiredFields, handleAdd);
  };
  const addingState = useSelector((state: RootState) => state.addChargebackSlice[requestId]);

  useEffect(() => {
    if (
      addingState !== undefined &&
      addingState.status === "success" &&
      addingState.data !== null
    ) {
      stateAccess.set([], initialChargebackState);
    }
  }, [addingState, dispatch]);

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="flex-end"
      direction="row"
      style={{
        position: "sticky",
        background: "white",
        padding: "5px",
        zIndex: 1,
        borderRadius: "6px",
        right: "0px",
        marginTop: "5px",
        bottom: "10px",
        width: "100px",
        marginLeft: "calc(100% - 99px)",
        boxShadow:
          "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)"
      }}
    >
      <Button onClick={addFunction} color="primary" variant="contained">
        Save/Add New
      </Button>
    </Grid>
  );
};
