import {
  createSocketMessageSlice,
  Identifiable
} from "../../Middlewares/socketMessageSliceCreator";
import { Checklist, FormState } from "./types";

export interface AddChecklistResponse {
  message: Checklist;
}
type AddChecklistRequest = { data: FormState };
const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddChecklistRequest,
  AddChecklistResponse
>("AddChecklist", {
  request: "new_checklist"
});

export const addChecklistActions = socketMessageSlice.actions;
export const addChecklist = socketAction;
export default socketMessageSlice.reducer;
