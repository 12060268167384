import { Deal } from "./types";
import {
  multyPermissionNode,
  permissionNode,
  PermissionStruct,
  relationPermissionNode
} from "components/Roles/types";

export type AppOnePermissionsStruct = PermissionStruct<Deal>;

export const appOnePermissionsStruct: AppOnePermissionsStruct = {
  _id: permissionNode("id"),
  createdAt: permissionNode("Created at"),
  updatedAt: permissionNode("Updated at"),
  deleted: permissionNode("Deleted"),
  dealId: permissionNode("Deal id"),
  refNumber: permissionNode("Ref number"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    Borrowers: {
      includeCoBorrower: permissionNode("Include co borrower"),
      Borrower: {
        NumOrder: permissionNode("Num order"),
        Type: permissionNode("Type"),
        FirstName: permissionNode("First name"),
        MiddleName: permissionNode("Middle name"),
        LastName: permissionNode("Last name"),
        Suffix: permissionNode("Suffix"),
        SSN: permissionNode("Ssn"),
        DOB: permissionNode("Dob"),
        HomePhone: permissionNode("Home phone"),
        MobilePhone: permissionNode("Mobile phone"),
        DLNo: permissionNode("Dl no"),
        DLState: permissionNode("Dl state"),
        Email: permissionNode("Email"),
        BusinessName: permissionNode("Business name"),
        BusinessType: permissionNode("Business type"),
        BusinessTaxID: permissionNode("Business tax id"),
        BusinessPhone: permissionNode("Business phone"),
        BusinessHowLongYears: permissionNode("Business how long years"),
        BusinessHowLongMonths: permissionNode("Business how long months"),
        Addresses: { Address: multyPermissionNode("Address") },
        EmploymentInfo: {
          includePreviousEmployment: permissionNode("Include previous employment"),
          Employment: multyPermissionNode("Employment")
        },
        OtherIncome: {
          Amount: permissionNode("Amount"),
          AmountType: permissionNode("Amount type"),
          Source: permissionNode("Source"),
          Description: permissionNode("Description")
        },
        References: {
          Reference: {
            Type: permissionNode("Type"),
            NumOrder: permissionNode("Num order"),
            Name: permissionNode("Name"),
            Address: permissionNode("Address"),
            City: permissionNode("City"),
            State: permissionNode("State"),
            ZipCode: permissionNode("Zip code"),
            Phone: permissionNode("Phone"),
            Checking: permissionNode("Checking"),
            Savings: permissionNode("Savings")
          }
        },
        CreditBureaus: { CreditBureau: multyPermissionNode("CreditBureau") }
      },
      CoBorrower: {
        NumOrder: permissionNode("Num order"),
        Type: permissionNode("Type"),
        FirstName: permissionNode("First name"),
        MiddleName: permissionNode("Middle name"),
        LastName: permissionNode("Last name"),
        Suffix: permissionNode("Suffix"),
        SSN: permissionNode("Ssn"),
        DOB: permissionNode("Dob"),
        HomePhone: permissionNode("Home phone"),
        MobilePhone: permissionNode("Mobile phone"),
        DLNo: permissionNode("Dl no"),
        DLState: permissionNode("Dl state"),
        Email: permissionNode("Email"),
        BusinessName: permissionNode("Business name"),
        BusinessType: permissionNode("Business type"),
        BusinessTaxID: permissionNode("Business tax id"),
        BusinessPhone: permissionNode("Business phone"),
        BusinessHowLongYears: permissionNode("Business how long years"),
        BusinessHowLongMonths: permissionNode("Business how long months"),
        Addresses: { Address: multyPermissionNode("Address") },
        EmploymentInfo: {
          includePreviousEmployment: permissionNode("Include previous employment"),
          Employment: multyPermissionNode("Employment")
        },
        OtherIncome: {
          Amount: permissionNode("Amount"),
          AmountType: permissionNode("Amount type"),
          Source: permissionNode("Source"),
          Description: permissionNode("Description")
        },
        References: {
          Reference: {
            Type: permissionNode("Type"),
            NumOrder: permissionNode("Num order"),
            Name: permissionNode("Name"),
            Address: permissionNode("Address"),
            City: permissionNode("City"),
            State: permissionNode("State"),
            ZipCode: permissionNode("Zip code"),
            Phone: permissionNode("Phone"),
            Checking: permissionNode("Checking"),
            Savings: permissionNode("Savings")
          }
        },
        CreditBureaus: { CreditBureau: multyPermissionNode("CreditBureau") }
      }
    },
    Collateral: {
      Type: permissionNode("Type"),
      Age: permissionNode("Age"),
      Serial: permissionNode("Serial"),
      Year: permissionNode("Year"),
      Make: permissionNode("Make"),
      Model: permissionNode("Model"),
      Style: permissionNode("Style"),
      Color: permissionNode("Color"),
      Mileage: permissionNode("Mileage"),
      FuelType: permissionNode("Fuel type"),
      MSRP: permissionNode("Msrp"),
      RVClass: permissionNode("Rv class"),
      IsCPO: permissionNode("Is cpo"),
      EnableManualEntry: permissionNode("Enable manual entry"),
      Horsepower: permissionNode("Horsepower"),
      DriveType: permissionNode("Drive type"),
      Length: permissionNode("Length"),
      CostPrice: permissionNode("Cost price"),
      SellingPrice: permissionNode("Selling price"),
      hasTrailer: permissionNode("Has trailer"),
      Trailer: {
        Age: permissionNode("Age"),
        Year: permissionNode("Year"),
        Make: permissionNode("Make"),
        Model: permissionNode("Model"),
        Serial: permissionNode("Serial")
      },
      Motors: { Motor: multyPermissionNode("Motor") },
      Options: {
        Option: {
          NumOrder: permissionNode("Num order"),
          Name: permissionNode("Name"),
          SellingPrice: permissionNode("Selling price"),
          IsTaxable: permissionNode("Is taxable")
        }
      },
      Valuation: {
        Source: permissionNode("Source"),
        Region: permissionNode("Region"),
        ValuationDate: permissionNode("Valuation date"),
        VehicleCode: permissionNode("Vehicle code"),
        Condition: permissionNode("Condition"),
        MSRP: permissionNode("Msrp"),
        BaseTradeInValue: permissionNode("Base trade in value"),
        BaseLoanValue: permissionNode("Base loan value"),
        BaseRetailValue: permissionNode("Base retail value"),
        BaseAvgTradeInValue: permissionNode("Base avg trade in value"),
        BaseRoughTradeInValue: permissionNode("Base rough trade in value"),
        MileageAdjustmentValue: permissionNode("Mileage adjustment value"),
        TradeInAdjustmentValue: permissionNode("Trade in adjustment value"),
        LoanAdjustmentValue: permissionNode("Loan adjustment value"),
        RetailAdjustmentValue: permissionNode("Retail adjustment value"),
        AvgTradeInAdjustmentValue: permissionNode("Avg trade in adjustment value"),
        RoughTradeInAdjustmentValue: permissionNode("Rough trade in adjustment value"),
        FinalTradeInValue: permissionNode("Final trade in value"),
        FinalLoanValue: permissionNode("Final loan value"),
        FinalRetailValue: permissionNode("Final retail value"),
        FinalAvgTradeInValue: permissionNode("Final avg trade in value"),
        FinalRoughTradeInValue: permissionNode("Final rough trade in value"),
        FinalCPOValue: permissionNode("Final cpo value"),
        Options: {
          Option: {
            Code: permissionNode("Code"),
            Name: permissionNode("Name"),
            IsIncluded: permissionNode("Is included"),
            IsIncludedInTrim: permissionNode("Is included in trim"),
            TradeInValue: permissionNode("Trade in value"),
            LoanValue: permissionNode("Loan value"),
            RetailValue: permissionNode("Retail value"),
            AvgTradeInValue: permissionNode("Avg trade in value"),
            RoughTradeInValue: permissionNode("Rough trade in value")
          }
        }
      }
    },
    Structure: {
      SellingPrice: permissionNode("Selling price"),
      TradeInAllowance: permissionNode("Trade in allowance"),
      TradeInPayoff: permissionNode("Trade in payoff"),
      Rebate: permissionNode("Rebate"),
      CashDown: permissionNode("Cash down"),
      TotalTaxes: permissionNode("Total taxes"),
      TotalFees: permissionNode("Total fees"),
      TotalProducts: permissionNode("Total products"),
      TotalAmountFinanced: permissionNode("Total amount financed"),
      Term: permissionNode("Term"),
      Rate: permissionNode("Rate"),
      DaysToFirstPayment: permissionNode("Days to first payment"),
      Fees: { Fee: multyPermissionNode("Fee") },
      Products: {
        Product: {
          Code: permissionNode("Code"),
          Name: permissionNode("Name"),
          CompanyName: permissionNode("Company name"),
          CostPriceAmount: permissionNode("Cost price amount"),
          SellingPriceAmount: permissionNode("Selling price amount"),
          IsTaxed: permissionNode("Is taxed"),
          CoverageDescription: permissionNode("Coverage description"),
          CoverageType: permissionNode("Coverage type")
        }
      },
      Taxes: {
        Tax: {
          Code: permissionNode("Code"),
          Name: permissionNode("Name"),
          ItemType: permissionNode("Item type"),
          ItemCode: permissionNode("Item code"),
          IsOverridden: permissionNode("Is overridden"),
          Rate: permissionNode("Rate"),
          Amount: permissionNode("Amount")
        }
      }
    },
    hasTradeIn: permissionNode("Has trade in"),
    TradeInInfo: multyPermissionNode("TradeInInfo"),
    InsuranceInfo: {
      Company: permissionNode("Company"),
      Agent: permissionNode("Agent"),
      AgentPhone: permissionNode("Agent phone"),
      PolicyNumber: permissionNode("Policy number"),
      Deductible: permissionNode("Deductible")
    }
  }
};
