import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditChargebackRequest {
  data: FormState;
}

export interface EditChargebackResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditChargebackRequest,
  EditChargebackResponse
>("EditChargeback", {
  request: "update_chargeback"
});

export const editChargebackActions = socketMessageSlice.actions;

export const editChargeback = (request: EditChargebackRequest & Identifiable) => {
  const { dealership, lender, manager, ...rest } = request.data;
  return socketAction({
    ...request,
    data: {
      ...rest,
      dealershipId: dealership?._id ?? null,
      lenderId: lender?._id ?? null,
      managerId: manager?._id ?? null
    }
  });
};
export default socketMessageSlice.reducer;
