import { clearPhone } from "components/DocumentTemplates/PrintTemplate";
import { formatSimpleSSN } from "utils/models/formRenderers";
import {
  createSocketMessageSlice,
  Identifiable
} from "../../Middlewares/socketMessageSliceCreator";
import { AddDealRequest, Address, Deal } from "./types";

interface AddDealResponse {
  message: {
    data: Deal;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddDealRequest,
  AddDealResponse
>("AddApponeDeal", {
  request: "appone_import_application"
});

export const addDealActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;

export const appOnePhoneNumberFormat = (number: string) => {
  if (typeof number !== "string") return null;

  const clearedPhone = clearPhone(number);
  if (clearedPhone?.length < 5) return null;

  return `${clearedPhone.slice(0, 3)}-${clearedPhone.slice(3, 6)}-${clearedPhone.slice(6, 10)}`;
};

const appOneDateFormat = (date: string) => {
  const d = new Date(date);
  const year = d.getFullYear();
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const removeEmpty = (obj: any) => {
  const newObj: any = {};

  Object.keys(obj).forEach((key: any) => {
    if (obj[key] && Array.isArray(obj[key])) {
      newObj[key] = obj[key].map((el: any) => removeEmpty(el));
    } else if (obj[key] && typeof obj[key] === "object") {
      const cleanedObject = removeEmpty(obj[key]);
      if (Object.keys(cleanedObject).length > 0) newObj[key] = cleanedObject; // recurse
    } else if (obj[key] !== null && obj[key] !== "") {
      newObj[key] = obj[key]; // copy value
    }
  });

  return newObj;
};
const formatAddressForAppOne = (address: Address): Address => ({
  IsCurrent: address.IsCurrent,
  IsMailing: address.IsMailing,
  StreetNo: address.StreetNo,
  StreetName: address?.StreetName ?? "",
  AptNo: address?.AptNo ?? "",
  City: address?.City ?? "",
  County: address?.County ?? "",
  State: address?.State ?? null,
  ZipCode: address?.ZipCode ?? "",
  Status: address?.Status ?? "",
  HowLongYears: address?.HowLongYears ?? "",
  HowLongMonths: address?.HowLongMonths ?? "",
  MonthlyPayment: address?.MonthlyPayment ?? ""
});
export const addDeal = ({ dealId, refNumber, data, requestId }: Deal & Identifiable) => {
  const borrowerEmployment = {
    IsCurrent: data.Borrowers.Borrower.EmploymentInfo.Employment[0].IsCurrent,
    NumOrder: "1",
    Status: data.Borrowers.Borrower.EmploymentInfo.Employment[0].Status,
    Occupation: data.Borrowers.Borrower.EmploymentInfo.Employment[0].Occupation,
    EmployerName: data.Borrowers.Borrower.EmploymentInfo.Employment[0].EmployerName,
    Address: data.Borrowers.Borrower.EmploymentInfo.Employment[0].Address,
    City: data.Borrowers.Borrower.EmploymentInfo.Employment[0].City,
    State: data.Borrowers.Borrower.EmploymentInfo.Employment[0].State,
    ZipCode: data.Borrowers.Borrower.EmploymentInfo.Employment[0].ZipCode,
    GrossSalary: data.Borrowers.Borrower.EmploymentInfo.Employment[0].GrossSalary,
    GrossSalaryType: data.Borrowers.Borrower.EmploymentInfo.Employment[0].GrossSalaryType,
    WorkPhone:
      data.Borrowers.Borrower.EmploymentInfo.Employment[0].WorkPhone &&
      appOnePhoneNumberFormat(data.Borrowers.Borrower.EmploymentInfo.Employment[0].WorkPhone),
    HowLongYears: data.Borrowers.Borrower.EmploymentInfo.Employment[0].HowLongYears,
    HowLongMonths: data.Borrowers.Borrower.EmploymentInfo.Employment[0].HowLongMonths
  };
  const borrowerPreviousEmployment = {
    IsCurrent: data.Borrowers.Borrower.EmploymentInfo.Employment[1]?.IsCurrent,
    NumOrder: "1",
    Status: data.Borrowers.Borrower.EmploymentInfo.Employment[1]?.Status,
    Occupation: data.Borrowers.Borrower.EmploymentInfo.Employment[1]?.Occupation,
    EmployerName: data.Borrowers.Borrower.EmploymentInfo.Employment[1]?.EmployerName,
    Address: data.Borrowers.Borrower.EmploymentInfo.Employment[1]?.Address,
    City: data.Borrowers.Borrower.EmploymentInfo.Employment[1]?.City,
    State: data.Borrowers.Borrower.EmploymentInfo.Employment[1]?.State,
    ZipCode: data.Borrowers.Borrower.EmploymentInfo.Employment[1]?.ZipCode,
    GrossSalary: data.Borrowers.Borrower.EmploymentInfo.Employment[1]?.GrossSalary,
    GrossSalaryType: data.Borrowers.Borrower.EmploymentInfo.Employment[1]?.GrossSalaryType,
    WorkPhone:
      data.Borrowers.Borrower.EmploymentInfo.Employment[1]?.WorkPhone &&
      appOnePhoneNumberFormat(data.Borrowers.Borrower.EmploymentInfo.Employment[1]?.WorkPhone),
    HowLongYears: data.Borrowers.Borrower.EmploymentInfo.Employment[1]?.HowLongYears,
    HowLongMonths: data.Borrowers.Borrower.EmploymentInfo.Employment[1]?.HowLongMonths
  };
  const borrowerEmploymentInfo = data?.Borrowers?.Borrower?.EmploymentInfo;
  const borrowerFullEmployment =
    borrowerEmploymentInfo?.includePreviousEmployment &&
    parseInt(borrowerEmploymentInfo?.Employment[0]?.HowLongYears ?? 0) < 2
      ? [borrowerEmployment, borrowerPreviousEmployment]
      : [borrowerEmployment];
  const coBorrowerEmployment = {
    IsCurrent: data.Borrowers.CoBorrower.EmploymentInfo.Employment[0].IsCurrent,
    NumOrder: "1",
    Status: data.Borrowers.CoBorrower.EmploymentInfo.Employment[0].Status,
    Occupation: data.Borrowers.CoBorrower.EmploymentInfo.Employment[0].Occupation,
    EmployerName: data.Borrowers.CoBorrower.EmploymentInfo.Employment[0].EmployerName,
    Address: data.Borrowers.CoBorrower.EmploymentInfo.Employment[0].Address,
    City: data.Borrowers.CoBorrower.EmploymentInfo.Employment[0].City,
    State: data.Borrowers.CoBorrower.EmploymentInfo.Employment[0].State,
    ZipCode: data.Borrowers.CoBorrower.EmploymentInfo.Employment[0].ZipCode,
    GrossSalary: data.Borrowers.CoBorrower.EmploymentInfo.Employment[0].GrossSalary,
    GrossSalaryType: data.Borrowers.CoBorrower.EmploymentInfo.Employment[0].GrossSalaryType,
    WorkPhone:
      data.Borrowers.CoBorrower.EmploymentInfo.Employment[0].WorkPhone &&
      appOnePhoneNumberFormat(data.Borrowers.CoBorrower.EmploymentInfo.Employment[0].WorkPhone),
    HowLongYears: data.Borrowers.CoBorrower.EmploymentInfo.Employment[0].HowLongYears,
    HowLongMonths: data.Borrowers.CoBorrower.EmploymentInfo.Employment[0].HowLongMonths
  };
  const coBorrowerPreviousEmployment = {
    IsCurrent: data.Borrowers.CoBorrower.EmploymentInfo.Employment[1]?.IsCurrent,
    NumOrder: "1",
    Status: data.Borrowers.CoBorrower.EmploymentInfo.Employment[1]?.Status,
    Occupation: data.Borrowers.CoBorrower.EmploymentInfo.Employment[1]?.Occupation,
    EmployerName: data.Borrowers.CoBorrower.EmploymentInfo.Employment[1]?.EmployerName,
    Address: data.Borrowers.CoBorrower.EmploymentInfo.Employment[1]?.Address,
    City: data.Borrowers.CoBorrower.EmploymentInfo.Employment[1]?.City,
    State: data.Borrowers.CoBorrower.EmploymentInfo.Employment[1]?.State,
    ZipCode: data.Borrowers.CoBorrower.EmploymentInfo.Employment[1]?.ZipCode,
    GrossSalary: data.Borrowers.CoBorrower.EmploymentInfo.Employment[1]?.GrossSalary,
    GrossSalaryType: data.Borrowers.CoBorrower.EmploymentInfo.Employment[1]?.GrossSalaryType,
    WorkPhone:
      data.Borrowers.CoBorrower.EmploymentInfo.Employment[1]?.WorkPhone &&
      appOnePhoneNumberFormat(data.Borrowers.CoBorrower.EmploymentInfo.Employment[1]?.WorkPhone),
    HowLongYears: data.Borrowers.CoBorrower.EmploymentInfo.Employment[1]?.HowLongYears,
    HowLongMonths: data.Borrowers.CoBorrower.EmploymentInfo.Employment[1]?.HowLongMonths
  };
  const coborrowerEmploymentInfo = data?.Borrowers?.CoBorrower?.EmploymentInfo;
  const coBorrowerFullEmployment =
    coborrowerEmploymentInfo?.includePreviousEmployment &&
    parseInt(coborrowerEmploymentInfo?.Employment[0]?.HowLongYears ?? 0) < 2
      ? [coBorrowerEmployment, coBorrowerPreviousEmployment]
      : [coBorrowerEmployment];
  const borrower = {
    ...data.Borrowers.Borrower,
    FirstName:
      data.Borrowers.Borrower.Type === "INDIVIDUAL" ? data.Borrowers.Borrower.FirstName : null,
    LastName:
      data.Borrowers.Borrower.Type === "INDIVIDUAL" ? data.Borrowers.Borrower.LastName : null,
    MiddleName:
      data.Borrowers.Borrower.Type === "INDIVIDUAL" ? data.Borrowers.Borrower.MiddleName : null,
    Suffix: data.Borrowers.Borrower.Type === "INDIVIDUAL" ? data.Borrowers.Borrower.Suffix : null,
    SSN:
      data.Borrowers.Borrower.Type === "INDIVIDUAL"
        ? formatSimpleSSN(data.Borrowers.Borrower.SSN)
        : null,
    HomePhone:
      data.Borrowers.Borrower.Type === "INDIVIDUAL"
        ? data.Borrowers.Borrower.HomePhone &&
          appOnePhoneNumberFormat(data.Borrowers.Borrower.HomePhone)
        : null,

    MobilePhone:
      data.Borrowers.Borrower.Type === "INDIVIDUAL"
        ? data.Borrowers.Borrower.MobilePhone &&
          appOnePhoneNumberFormat(data.Borrowers.Borrower.MobilePhone)
        : null,
    DLNo: data.Borrowers.Borrower.Type === "INDIVIDUAL" ? data.Borrowers.Borrower.DLNo : null,
    DLState: data.Borrowers.Borrower.Type === "INDIVIDUAL" ? data.Borrowers.Borrower.DLState : null,
    Email: data.Borrowers.Borrower.Type === "INDIVIDUAL" ? data.Borrowers.Borrower.Email : null,
    DOB:
      data.Borrowers.Borrower.Type === "INDIVIDUAL"
        ? data.Borrowers.Borrower.DOB && appOneDateFormat(data.Borrowers.Borrower.DOB)
        : null,
    BusinessName:
      data.Borrowers.Borrower.Type === "BUSINESS" ? data.Borrowers.Borrower.BusinessName : null,
    BusinessType:
      data.Borrowers.Borrower.Type === "BUSINESS" ? data.Borrowers.Borrower.BusinessType : null,
    BusinessTaxID:
      data.Borrowers.Borrower.Type === "BUSINESS" ? data.Borrowers.Borrower.BusinessTaxID : null,
    BusinessPhone:
      data.Borrowers.Borrower.Type === "BUSINESS"
        ? data.Borrowers.Borrower.BusinessPhone &&
          appOnePhoneNumberFormat(data.Borrowers.Borrower.BusinessPhone)
        : null,
    BusinessHowLongYears:
      data.Borrowers.Borrower.Type === "BUSINESS"
        ? data.Borrowers.Borrower.BusinessHowLongYears
        : null,
    BusinessHowLongMonths:
      data.Borrowers.Borrower.Type === "BUSINESS"
        ? data.Borrowers.Borrower.BusinessHowLongMonths
        : null,
    Addresses: {
      Address: [
        formatAddressForAppOne(data.Borrowers.Borrower.Addresses.Address[0]),
        ...(data.Borrowers.Borrower.Addresses.Address[0].IsMailing
          ? []
          : [formatAddressForAppOne(data.Borrowers.Borrower.Addresses.Address[1])]),
        ...(parseInt(data.Borrowers.Borrower.Addresses.Address[0].HowLongYears) < 2
          ? [formatAddressForAppOne(data.Borrowers.Borrower.Addresses.Address[2])]
          : [])
      ]
    },
    EmploymentInfo: {
      Employment: borrowerFullEmployment
    },
    OtherIncome:
      data?.Borrowers?.Borrower?.OtherIncome?.Amount > 0
        ? data?.Borrowers?.Borrower?.OtherIncome
        : null
    //References: {
    //  Reference: {
    //    ...data.Borrowers.Borrower.References.Reference,
    //    Phone:
    //      data.Borrowers.Borrower.References.Reference.Phone &&
    //      appOnePhoneNumberFormat(data.Borrowers.Borrower.References.Reference.Phone)
    //  }
    //}
  };
  const coBorrower = {
    ...data.Borrowers.CoBorrower,
    FirstName:
      data.Borrowers.CoBorrower.Type === "INDIVIDUAL" ? data.Borrowers.CoBorrower.FirstName : null,
    LastName:
      data.Borrowers.CoBorrower.Type === "INDIVIDUAL" ? data.Borrowers.CoBorrower.LastName : null,
    MiddleName:
      data.Borrowers.CoBorrower.Type === "INDIVIDUAL" ? data.Borrowers.CoBorrower.MiddleName : null,
    Suffix:
      data.Borrowers.CoBorrower.Type === "INDIVIDUAL" ? data.Borrowers.CoBorrower.Suffix : null,
    SSN:
      data.Borrowers.CoBorrower.Type === "INDIVIDUAL" && data?.Borrowers?.CoBorrower?.SSN
        ? formatSimpleSSN(data.Borrowers.CoBorrower.SSN)
        : null,
    HomePhone:
      data.Borrowers.CoBorrower.Type === "INDIVIDUAL"
        ? data.Borrowers.CoBorrower.HomePhone &&
          appOnePhoneNumberFormat(data.Borrowers.CoBorrower.HomePhone)
        : null,

    MobilePhone:
      data.Borrowers.CoBorrower.Type === "INDIVIDUAL"
        ? data.Borrowers.CoBorrower.MobilePhone &&
          appOnePhoneNumberFormat(data.Borrowers.CoBorrower.MobilePhone)
        : null,
    DLNo: data.Borrowers.CoBorrower.Type === "INDIVIDUAL" ? data.Borrowers.CoBorrower.DLNo : null,
    DLState:
      data.Borrowers.CoBorrower.Type === "INDIVIDUAL" ? data.Borrowers.CoBorrower.DLState : null,
    Email: data.Borrowers.CoBorrower.Type === "INDIVIDUAL" ? data.Borrowers.CoBorrower.Email : null,
    DOB:
      data.Borrowers.CoBorrower.Type === "INDIVIDUAL"
        ? data.Borrowers.CoBorrower.DOB && appOneDateFormat(data.Borrowers.CoBorrower.DOB)
        : null,
    BusinessName:
      data.Borrowers.CoBorrower.Type === "BUSINESS" ? data.Borrowers.CoBorrower.BusinessName : null,
    BusinessType:
      data.Borrowers.CoBorrower.Type === "BUSINESS" ? data.Borrowers.CoBorrower.BusinessType : null,
    BusinessTaxID:
      data.Borrowers.CoBorrower.Type === "BUSINESS"
        ? data.Borrowers.CoBorrower.BusinessTaxID
        : null,
    BusinessPhone:
      data.Borrowers.CoBorrower.Type === "BUSINESS"
        ? data.Borrowers.CoBorrower.BusinessPhone &&
          appOnePhoneNumberFormat(data.Borrowers.CoBorrower.BusinessPhone)
        : null,
    BusinessHowLongYears:
      data.Borrowers.CoBorrower.Type === "BUSINESS"
        ? data.Borrowers.CoBorrower.BusinessHowLongYears
        : null,
    BusinessHowLongMonths:
      data.Borrowers.CoBorrower.Type === "BUSINESS"
        ? data.Borrowers.CoBorrower.BusinessHowLongMonths
        : null,
    Addresses: {
      Address: [
        formatAddressForAppOne(data.Borrowers.CoBorrower.Addresses.Address[0]),
        ...(data.Borrowers.CoBorrower.Addresses.Address[0].IsMailing
          ? []
          : [formatAddressForAppOne(data.Borrowers.CoBorrower.Addresses.Address[1])]),
        ...(parseInt(data.Borrowers.CoBorrower.Addresses.Address[0].HowLongYears) < 2
          ? [formatAddressForAppOne(data.Borrowers.CoBorrower.Addresses.Address[2])]
          : [])
      ]
    },
    EmploymentInfo: {
      Employment: coBorrowerFullEmployment
    },
    OtherIncome:
      data?.Borrowers?.CoBorrower?.OtherIncome?.Amount > 0
        ? data?.Borrowers?.CoBorrower?.OtherIncome
        : null
  };
  const borrowers = data.Borrowers.includeCoBorrower ? [borrower, coBorrower] : [borrower];
  const { hasTradeIn, TradeInInfo, ...restData } = data;

  return socketAction({
    requestId,
    data: {
      dealId,
      refNumber,
      params: removeEmpty({
        ...restData,
        Borrowers: {
          Borrower: borrowers
        },
        Collateral: {
          ...data.Collateral,
          ...(data.Collateral.hasTrailer
            ? { Trailer: data.Collateral.Trailer }
            : { Trailer: null }),
          hasTrailer: null,
          FuelType:
            data?.Collateral?.FuelType === "NotApplicable" ? null : data?.Collateral?.FuelType,
          Motors: {
            Motor: data?.Collateral?.Motors?.Motor?.map((motor, index) => {
              const { NumOrder, ...restMotor } = motor;
              return {
                NumOrder: index.toString(),
                ...restMotor
              };
            })
          }
        },
        ...(hasTradeIn ? { TradeInInfo } : {})
      })
    }
  });
};
