import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { RootState } from "app/rootReducer";
import { Link } from "react-router-dom";
import { signInUser, signInWithPhoneCode } from "./authSlice";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { LogoWithText } from "components/AppBar/Logo";
import { devlog } from "utils/logging";
import { checkIfAuthenticationCodeError } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "absolute",
      width: "100%",
      height: "100%",
      background: "#19181f"
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(4)
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: "80%",
      marginTop: theme.spacing(1)
    },
    submit: {
      backgroundColor: "#19181f",
      margin: theme.spacing(3, 0, 2)
    },
    errorText: {
      marginTop: "2px",
      textAlign: "center"
    },
    logo: { textAlign: "center" }
  })
);

export default function SignIn(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authenticationCode, setAuthenticationCode] = useState("");

  const { authSlice } = useSelector((state: RootState) => state);
  const { signInError, errorMessage, signInErrorCode, data } = authSlice;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    devlog(email);
    if (data) {
      dispatch(signInWithPhoneCode(data.resolver, data.verificationId, authenticationCode));
    } else dispatch(signInUser(email, password));
  };

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <LogoWithText />
      </div>
      <div id="recaptcha-container"></div>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e): void => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e): void => setPassword(e.target.value)}
            />
            {signInError && signInErrorCode && checkIfAuthenticationCodeError(signInErrorCode) && (
              <>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="code"
                  label="Authentication code"
                  type="text"
                  id="code"
                  autoComplete=",facode"
                  value={authenticationCode}
                  onChange={(e): void => setAuthenticationCode(e.target.value)}
                />
                This site is protected by reCAPTCHA and the Google <br />
                <a href="https://policies.google.com/privacy"> Privacy Policy </a> and
                <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
                <br />
              </>
            )}
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {signInError && (
              <Typography
                component="p"
                style={{
                  color: checkIfAuthenticationCodeError(signInErrorCode) ? "#000" : "#a4031f"
                }}
                className={classes.errorText}
              >
                {errorMessage} <br />
                <Link to="/forgot-password">Forgot password?</Link>
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              id="sign-in"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              {/* <Grid item xs={12}>
                <Link to="/sign-up">Don&apos;t have an account? Sign Up</Link>
              </Grid> */}
              <Grid item xs={12}>
                <Link to="/forgot-password"> Forgot password?</Link>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </div>
  );
}
