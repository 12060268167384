export default {
  options: { type: "object", additionalProperties: true },
  search: {
    type: "object",
    required: ["term"],
    properties: {
      term: { type: "string" }
    }
  },
  with_deleted: { type: "boolean" },
  aggregateFirst: { type: "boolean" }
};
