import {
  addGenerateContractActions,
  GenerateContractResponse
} from "components/Deals/Documents/generateContractSlice";
import { transformOldTopicToAxiosRequest } from "components/Middlewares/SocketMiddleware";
import { makeHttpRequest } from "./common";

export const addGenerateContract = async (
  request: any
): Promise<
  | {
      result: GenerateContractResponse;
      status: "success" | "error";
    }
  | undefined
> => {
  const action = addGenerateContractActions("", request);
  if (action?.http) {
    const request = transformOldTopicToAxiosRequest(action.http.path, action.payload);
    //@ts-ignore
    return await makeHttpRequest(request, action);
  } else {
    return new Promise(() => undefined);
  }
};
