import { createListSlice } from "../Middlewares/listSliceCreator";
import { DealershipProgram } from "./types";

const { ListSlice, getList } = createListSlice<DealershipProgram>({
  name: "DealershipProgramList",
  request_topic: "all_dealership_programs",
  reducers: {}
});

export default ListSlice.reducer;
export const getDealershipProgramList = getList;
export const removeDealershipProgramList = ListSlice.actions.removeList;
export const listDealershipProgramActions = ListSlice.actions;
