import Chip from "@material-ui/core/Chip";
import { createSlice } from "@reduxjs/toolkit";
import * as React from "react";
import { useSelector } from "react-redux";
import semver from "semver";
import { RootState } from "../../app/rootReducer";

export interface VersionState {
  version: string;
}

const initialState: VersionState = {
  version: process.env.REACT_APP_VERSION ?? ""
};

const versionSlice = createSlice({
  name: "version",
  initialState,
  reducers: {
    sendNewVersionNotification(state, message): void {
      state.version = message.payload;
    }
  }
});

export const { sendNewVersionNotification } = versionSlice.actions;

export default versionSlice.reducer;

const reloadApp = () => {
  //@ts-ignore
  window.location.reload(true);
};
export const VersionNotification = () => {
  const { version } = useSelector((state: RootState) => state.versionSlice);

  return semver.gte(version, process.env.REACT_APP_VERSION ?? "", true) &&
    process.env.REACT_APP_VERSION !== version ? (
    <Chip
      onClick={() => reloadApp()}
      style={{ position: "fixed", bottom: "10px", zIndex: 9999 }}
      label="New version available."
      color="primary"
      clickable
    />
  ) : null;
};
