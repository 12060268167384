import { Info as ApplicantInfo } from "components/Applicants/types";
import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { DealResponse, Vehicle } from "./types";

export type AddDealFromDocumentRequest = {
  applicant?: Partial<ApplicantInfo>;
  coApplicant?: Partial<ApplicantInfo>;
  vehicle?: Partial<Vehicle>;
};

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddDealFromDocumentRequest,
  DealResponse
>("Add Deal From Parsed Document", {
  request: "new_deal_from_parsed_document"
});

export const addDealFromParsedDocumentActions = socketMessageSlice.actions;
export const addDealFromParsedDocument = socketAction;
export default socketMessageSlice.reducer;
