import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { RootState } from "app/rootReducer";
import formEditContext from "components/Content/FormEditContext";
import { PayoffBank } from "components/PayoffBanks/types";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOptionsForSelect } from "utils/models/fields";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { getPayoffBankList, removePayoffBankList } from "../PayoffBanks/listPayoffBankSlice";
import AddPayoffBank from "./AddPayoffBank";
import { Deal } from "./types";

interface Props {
  stateAccess: StateAccess;
  path: string[];
  type: "deal" | "external_credit_application";
  renderSet: RenderSet;
  name: string;
  label: string;
  required: boolean;
}

export default function PayoffBankPreview({
  required,
  stateAccess,
  type,
  path,
  renderSet,
  name,
  label
}: Props) {
  const dispatch = useDispatch();
  const { enabled: editMode } = useContext(formEditContext);
  const listId = `list-autocomplete-payoffBank-${stateAccess.get(["_id"])}`;
  const payoffBankList = useSelector((state: RootState) => state.listPayoffBankSlice[listId]);
  const selectedPayoffBank = stateAccess.get(path as [string]);
  const price: Deal["data"]["info"]["price"] = stateAccess.get<Deal>(["data", "info", "price"]);
  const payoff = price?.payoff ?? 0;
  const trade = price?.totalTrade ?? 0;
  const handleLoadBanks = () => {
    if (!payoffBankList)
      dispatch(
        getPayoffBankList(listId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } }
        })
      );
    return () => {
      dispatch(removePayoffBankList(listId));
      return;
    };
  };

  return (
    <>
      <Grid container spacing={1}>
        {type === "deal" && (
          <Grid item xs={12}>
            {renderSet.currencyInputRenderer<Deal>(
              {
                name: "payoff",
                label: "Payoff",
                path: ["data", "info", "price", "payoff"],
                required: trade > 0 ? true : false
              },
              stateAccess,
              renderSet
            )}
          </Grid>
        )}

        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1}>
              {renderSet.selectInputRenderer(
                {
                  formComponent: "select-field",
                  required: payoff > 0 ? true : false,
                  name,
                  label,
                  onOpen: handleLoadBanks,
                  path: path as [],
                  options: createOptionsForSelect({
                    possibleValues: () => payoffBankList?.entities ?? [],
                    getOptionLabel: (el) => el?.data?.info?.name,
                    getSelectedOption: (x, y) => x._id === y._id
                  }),
                  valueToString: (el) => el.data.info.name
                },
                stateAccess,
                [],
                stateAccess,
                renderSet
              )}
            </Box>
            <Box>
              {type === "deal" && (
                <AddPayoffBank
                  selectedPayoffBank={selectedPayoffBank}
                  setSelected={(payoffBank: PayoffBank) => stateAccess.set(path as [], payoffBank)}
                  editMode={editMode}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
