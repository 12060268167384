import { RootState } from "app/rootReducer";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DateFilter from "components/Filters/DateFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import PriceFilter from "components/Filters/PriceFilter";
import TextFilter from "components/Filters/TextFilter";
import React from "react";
import { useSelector } from "react-redux";
import { sumNumbers } from "utils/calcReportTotal";
import AccessControl from "../../Access/AccessControl";
import { deleteDeal } from "../../Deals/deleteDealSlice";
import { getDealList } from "../../Deals/listDealSlice";
import { recoverDeal } from "../../Deals/recoverDealSlice";
import { DealData } from "../../Deals/types";
import Table, { CellValue, Column } from "../../Table";
export const projections = {
  "data.applicant.data.info.firstName": 1,
  "data.applicant.data.info.lastName": 1,
  "data.info.refNumber": 1,
  "data.dealership.data.info.displayName": 1,
  "data.info.profit": 1,
  "data.info.dealDates": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};
export default function Payroll(): JSX.Element {
  const dealList = useSelector((state: RootState) => state.listDealSlice["table"]);

  const columns: Column<DealData>[] = [
    {
      getData: (entry): CellValue =>
        entry.data?.info?.dealDates?.contractDate
          ? new Date(entry.data?.info?.dealDates?.contractDate)
          : "",
      label: "Signed At",
      total: () => "Total:",
      name: "signedAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.signedAt?.show;
      },
      filters: [
        {
          path: ["data", "info", "dealDates", "signedAt"],
          preview: DateFilter,
          name: "signedAt",
          label: "Signed At"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.dealDates?.fundedAt
          ? new Date(entry.data?.info?.dealDates?.fundedAt)
          : "",
      label: "Date Funded",
      name: "fundedAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.fundedAt?.show;
      },
      filters: [
        {
          path: ["data", "info", "dealDates", "fundedAt"],
          preview: DateFilter,
          name: "fundedAt",
          label: "Date Funded"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
          entry.data?.applicant?.data?.info?.lastName ?? ""
        }`,
      label: "Customer Name",
      name: "customer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.customer?.show;
      },
      filters: [
        {
          path: [
            ["data", "applicant", "data", "info", "firstName"],
            ["data", "applicant", "data", "info", "lastName"]
          ],
          preview: ApplicantFilter,
          caseInsensitive: true,
          label: "Customer",
          name: "customer"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.dealership?.data?.info?.name ?? "",
      label: "Dealership",
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      },
      filters: [
        {
          path: ["data", "dealershipId"],
          preview: DealershipFilter,
          name: "dealership",
          label: "Dealership"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.refNumber ?? "",
      label: "Reference Number",
      name: "refNumber",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.refNumber?.show;
      },
      filters: [
        {
          path: ["data", "info", "refNumber"],
          preview: TextFilter,
          name: "refNumber",
          label: "Reference Number"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.profit?.wfdProfit?.totalProfit ?? 0,
      label: "WFD Profit",
      total: sumNumbers,
      name: "totalProfit",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.totalProfit?.show;
      },
      filters: [
        {
          path: ["data", "info", "profit", "wfdProfit", "totalProfit"],
          preview: PriceFilter,
          name: "wfdProfit",
          label: "WFD Profit"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Actions",
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={{ entity: "deal", action: "read" }}>
        <Table
          tableName="payrolls"
          entityName="deal"
          listFunction={getDealList}
          listEntity={dealList}
          deleteEntityFunction={deleteDeal}
          recoverEntityFunction={recoverDeal}
          sort={{ createdAt: "asc" }}
          title={"Payroll"}
          columns={columns}
          isReport={true}
          showTotalFooter={true}
          projection={projections}
        />
      </AccessControl>
    </div>
  );
}
