import {
  createSocketMessageSlice,
  Identifiable
} from "../../Middlewares/socketMessageSliceCreator";

interface SignContractRequest {
  requestId: string;
  data: {
    params: {
      EX1ContractRequest: {
        Deal: {
          Vehicle: Vehicle;
          DealType: string; // Loan
          MSRP: string; // 10000
          SaleDate: string;
          VehicleSalePrice: string;
          FinanceTerms: FinanceTerms;
          Products: Products;
          Buyer: Buyer;
          CoBuyer?: Buyer;
          DealNumber: string; // random uuid
        };
        EX1DealerID: string;
        FormFields: FormFields;
      };
      EX1FormRequest: {
        EX1DealerID: string;
        Contract: { EX1ProviderID: string; ContractType: string };
      };
    };
  };
}

interface FormFields {
  FormField: FormField[];
}

interface FormField {
  FieldName: string;
  Value: string;
}

interface Buyer {
  FirstName: string;
  LastName: string;
  Contact: BuyerContact;
}

interface BuyerContact {
  AddressLine1: string;
  AddressLine2: string | null;
  City: string;
  State: string;
  PostalCode: string;
  Country: string;
  Phone: string;
  TimeZone: string | null;
  Facsimile: string | null;
  eMail: string;
}

interface Products {
  Product: Product;
}

interface Product {
  EX1RateResponseID: string;
  RetailPrice: string;
  DealerCost: string;
  SellingPrice: string;
  Attributes: Attributes | null;
  ContractFormID: string;
  ProductCode: string;
  Surcharges: Surcharge[];
}

interface Surcharge {}

interface Attributes {
  Coverage: string;
  Months: string;
  Term: string;
  Miles: string;
  Deductible: number;
  ServiceInterval: string | null;
  TireRotations: string;
}

interface Vehicle {
  VIN: string;
  InServiceDate: string;
  Odometer: string;
  CarStatus: string;
  Make: string;
  Model: string;
  Year: string;
}

interface FinanceTerms {
  Lienholder: Lienholder;
  FinanceAmount: string;
  FinanceRate: string;
  MonthlyPayment: string;
  FinanceTerm: string;
  ResidualAmount: string;
  AmortTerm: string;
}

interface Lienholder {
  Name: string;
  Contact: LienholderContact;
}

interface LienholderContact {
  AddressLine1: string;
  AddressLine2: string | null;
  City: string;
  State: string;
  PostalCode: string;
  Country: string;
  Phone: string | null;
  TimeZone: string | null;
  Facsimile: string | null;
  eMail: string | null;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<SignContractRequest, any>(
  "SignContract",
  {
    request: "express_ex1_contract"
  }
);

export const signContract = (request: any & Identifiable) => {
  const { requestId, Deal, EX1DealerID, FormFields, EX1ProviderID } = request;

  return socketAction({
    requestId,
    data: {
      params: {
        EX1ContractRequest: {
          Deal,
          EX1DealerID,
          FormFields
        },
        EX1FormRequest: {
          EX1DealerID,
          Contract: {
            EX1ProviderID,
            ContractType: "Link"
          }
        }
      }
    }
  });
};

export const signContractAction = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
