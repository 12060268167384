import contractInfoProperties from "./info_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

const data = {
  type: "object",
  required: ["info"],
  properties: {
    info: {
      type: "object",
      required: ["name"],
      properties: contractInfoProperties,
    },
    contractTypeIds: {
      type: "array",
      items: { type: "string", nullable: true },
    },
    lenderIds: {
      type: "array",
      items: { type: "string", nullable: true },
    },
    userIds: {
      type: "array",
      items: { type: "string", nullable: true },
    },
    dealershipIds: {
      type: "array",
      items: { type: "string", nullable: true },
    },
    otherVendorIds: {
      type: "array",
      items: { type: "string", nullable: true },
    },
    products: {
      type: "array",
      items: { type: "string", nullable: true },
    },
    externalProviders: {
      type: "array",
      items: { type: "string", nullable: true },
    },
    vendorTypes: {
      type: "array",
      items: { type: "string", nullable: true },
    },
  },
};

export default {
  new_contract: {
    $id: "newContract",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_contract: {
    $id: "updateContract",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      approved: {
        type: "boolean",
        nullable: true,
      },
      approvedAt: {
        type: "string",
        nullable: true,
      },
      approvedByUserId: {
        type: "string",
        nullable: true,
      },
      data: data,
    },
  },
  delete_contract: {
    $id: "deleteContract",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  recover_contract: {
    $id: "recoverContract",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_contract: {
    $id: "getContract",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_contracts: {
    $id: "allContracts",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
