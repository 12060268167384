import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import React, { useEffect, useState } from "react";
import { Deal } from "components/Deals/types";
import {
  Grid,
  createStyles,
  Theme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Chip,
  capitalize,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { addPrintedDocument } from "components/PrintedDocuments/addPrintedDocumentSlice";
import { makeStyles } from "@material-ui/core/styles";
import CloseDialogButton from "components/common/CloseDialogButton";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import {
  RequiredDocumentStatus,
  PrintedDocumentType,
  PrintedDocument,
  FormState
} from "components/PrintedDocuments/types";
import { FundingDocument } from "components/FundingDocuments/types";
import { deletePrintedDocument } from "components/PrintedDocuments/deletePrintedDocumentSlice";
import { getPrintedDocumentList } from "components/PrintedDocuments/listPrintedDocumentsSlice";
import Table, { CellValue, Column } from "components/Table";
import TextFilter from "components/Filters/TextFilter";

import { editPrintedDocument } from "components/PrintedDocuments/editPrintedDocumentSlice";
import { Visibility } from "@material-ui/icons";
interface Props {
  deal: Deal;
  setPrintedDocumentForPreview: React.Dispatch<React.SetStateAction<PrintedDocument | undefined>>;
  printedDocumentForPreview: PrintedDocument | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center"
    },
    root: {
      borderRadius: "3px",
      width: "100%",
      height: "100%",
      alignItems: "baseline"
    }
  })
);
const hasRelatedDocuments = (
  fundingDocuments?: FundingDocument[],
  printedDocument?: PrintedDocument
) => {
  return fundingDocuments?.find((fundingDocument) =>
    Object.values(fundingDocument?.data?.uploadedDocumentStatus?.pages).find(
      (page) => page?.printedDocumentId === printedDocument?._id
    )
  );
};

const getColorByStatus = (status: RequiredDocumentStatus) => {
  switch (status) {
    case RequiredDocumentStatus.approved:
      return "#43a047";
    case RequiredDocumentStatus.rejected:
      return "#f50057";
    case RequiredDocumentStatus.missing:
      return "#ababab";
    case RequiredDocumentStatus.pending:
      return "#eed238";
    default:
      return "gray";
  }
};

export default function RequiredDocumentsPreview({ setPrintedDocumentForPreview, deal }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [requestId] = useState(uuidv4());
  const listId = `printed_documents_table-${deal._id}`;

  const printedDocumentList = useSelector(
    (state: RootState) => state.listPrintedDocumentSlice[listId]
  );
  const [open, setOpen] = useState(false);
  const addPrintedDocumentState = useSelector(
    (state: RootState) => state.addPrintedDocumentSlice[requestId]
  );
  const [additionalDocumnetName, setAdditionalDocumentName] = useState("");

  useEffect(() => {
    if (addPrintedDocumentState?.status === "success") {
      setOpen(false);
      setAdditionalDocumentName("");
    }
  }, [addPrintedDocumentState]);

  const handleAddAdditionalDocument = () => {
    if (additionalDocumnetName)
      dispatch(
        addPrintedDocument({
          requestId,
          data: {
            dealId: deal._id,

            info: {
              status: RequiredDocumentStatus.missing,
              type: PrintedDocumentType.other_document,
              name: additionalDocumnetName
            }
          }
        })
      );
    else enqueueSnackbar("Please enter required document name.", { variant: "error" });
  };

  const handleEditPrintedDocument = (
    document: PrintedDocument,
    field: "forApplicant" | "forDealership"
  ) => {
    dispatch(
      editPrintedDocument({
        requestId,
        ...document,
        data: {
          ...document?.data,
          info: {
            ...document?.data?.info,
            [field]: !document?.data?.info?.[field]
          }
        }
      })
    );
  };
  const handleCloseAdditionalDocumentDialog = () => setOpen(false);

  const columns: Column<FormState>[] = [
    {
      getData: (entry): CellValue => entry?.data?.info?.name,
      label: "Name",
      name: "name",
      show: (_, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      },
      filters: [
        {
          path: ["data", "info", "name"],
          preview: TextFilter,
          name: "name",
          label: "Name",
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      getData: (entry): CellValue => entry?.data?.info?.status,
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (x): JSX.Element | string => {
          const status = x as RequiredDocumentStatus;
          return (
            <div style={{ whiteSpace: "nowrap" }}>
              <Chip
                size="small"
                label={capitalize(status)}
                style={{ background: getColorByStatus(status) }}
              />
            </div>
          );
        }
      },
      show: (_, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      },
      filters: [
        {
          path: ["data", "info", "status"],
          preview: TextFilter,
          name: "name",
          label: "Name",
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      label: "Preview",
      getData: (entry): CellValue => entry?._id,
      name: "Preview",
      options: {
        customBodyRender: (_, document): JSX.Element | string => {
          return (
            <Button
              startIcon={<Visibility />}
              style={{ padding: 10 }}
              color="primary"
              onClick={() => setPrintedDocumentForPreview(document)}
            >
              Preview
            </Button>
          );
        }
      }
    },
    {
      label: "Can be uploaded by",
      getData: (entry): CellValue => entry._id,
      name: "can be uploaded by",
      options: {
        customBodyRender: (_, document): JSX.Element | string => {
          return (
            <div style={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={document?.data?.info?.forApplicant ?? false}
                    onChange={() => handleEditPrintedDocument(document, "forApplicant")}
                    name="Applicant"
                  />
                }
                label="Applicant"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={document?.data?.info?.forDealership ?? false}
                    onChange={() => handleEditPrintedDocument(document, "forDealership")}
                    name="Dealership"
                  />
                }
                label="Dealership"
              />
            </div>
          );
        }
      }
    },
    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (_, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div
      style={{
        marginBottom: "15px"
      }}
    >
      <Grid container>
        <Table
          hideRecoverButton={true}
          addEntityFunction={() => setOpen(true)}
          tableName="printed_documents"
          entityName="printed_document"
          listFunction={getPrintedDocumentList}
          listEntity={printedDocumentList}
          deleteEntityFunction={deletePrintedDocument}
          title={"Required Documents"}
          query={{ dealId: deal._id }}
          slice={listId}
          columns={columns}
          hideShowButton={true}
        />
      </Grid>
      {open && (
        <Dialog
          id="add-additional-document-dialog"
          open={true}
          onClose={handleCloseAdditionalDocumentDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Grid container spacing={2}>
              <Grid item xs={11} style={{ whiteSpace: "nowrap" }}>
                Add additional required document
              </Grid>
              <Grid item xs={1}>
                <CloseDialogButton closeFunction={handleCloseAdditionalDocumentDialog} />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
            <TextField
              id={`add-new-additional-required-document`}
              variant="standard"
              label={"New  additional required document"}
              required={false}
              type="text"
              size="small"
              fullWidth
              autoComplete="new-password"
              value={additionalDocumnetName}
              onChange={(e) => setAdditionalDocumentName(e.target.value ?? "")}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleAddAdditionalDocument();
                }
              }}
              InputLabelProps={{ shrink: true }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseAdditionalDocumentDialog}
              variant="contained"
              color="secondary"
              id="cancel"
            >
              Cancel
            </Button>
            <Button
              onClick={handleAddAdditionalDocument}
              variant="contained"
              color="primary"
              autoFocus
              id="confirm"
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
