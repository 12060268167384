import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Deal } from "components/Deals/types";
import React, { useState } from "react";
import { formatNumberAsCurrency } from "utils/functions";
import { getByPath, setByPath, StateAccess } from "utils/models/formGenerator";
import { handleChooseRateType } from "./InsuranceProductRates";
import Surcharges, { handleChangeSurchargeForRate } from "./Surcharges";
import { DynamicSurcharge } from "./types";
import store from "../../../app/store";

const Rate = ({
  key,
  show,
  primaryText,
  surcharges,
  action
}: {
  key: number;
  show: boolean;
  primaryText: string;
  surcharges: DynamicSurcharge[];
  action: (surcharges: DynamicSurcharge[]) => void;
}) => {
  const [selectedSurcharges, setSelectedSurcharges] = useState<DynamicSurcharge[]>(surcharges);
  const stateAccess: StateAccess = {
    get: (path) => getByPath(selectedSurcharges as any, path),
    set: (path, value): any => {
      setSelectedSurcharges(setByPath(selectedSurcharges as any, path, value));
    }
  };

  return (
    <div
      key={key}
      style={{
        display: show ? "flex" : "none",
        flexWrap: "wrap",
        width: "auto",
        marginTop: "10px",
        borderRadius: "8px",
        backgroundColor: "#F8F8F8"
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <ListItemText style={{ paddingTop: "8px", paddingLeft: "10px" }} primary={primaryText} />
        <IconButton
          aria-label="add"
          onClick={() => action(selectedSurcharges)}
          style={{ alignSelf: "flex-end" }}
        >
          <AddCircleIcon style={{ color: "#50A538" }} />
        </IconButton>
      </div>
      <Surcharges
        stateAccess={stateAccess}
        surcharges={stateAccess.get<DynamicSurcharge>([] as any)}
        handleChange={handleChangeSurchargeForRate}
      />
    </div>
  );
};

const primaryText = (text: [string, string][]) => {
  return text.map(([k, v]) => `${k}: ${v}`).join(" / ");
};

const RateList = ({
  el,
  providerId,
  index,
  handleChooseRate,
  deal,
  product
}: {
  product: any;
  el: any;
  providerId: string;
  index: number;
  handleChooseRate: handleChooseRateType;
  deal: Deal;
}) => {
  const [showAll, setShowAll] = useState(false);
  const ascExtraProfit =
    store?.getState()?.listSettingsSlice?.settingsData?.entities?.[0]?.data?.info.ascWarranty ?? 0;
  return (
    <List
      key={index}
      style={{
        borderRadius: "8px",
        backgroundColor: "#E8E8E8",
        marginTop: "10px",
        marginBottom: "5px",
        height: "auto"
      }}
    >
      <ListItem>
        <div style={{ width: "100%" }}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="h6" component="h6">
                {el.Value}
              </Typography>
            </Box>
            <Box>
              <Button onClick={() => setShowAll((x) => !x)}>
                {showAll ? "Hide all" : "Show all"}
              </Button>
            </Box>
          </Box>

          {el.Months.map(
            (
              monthRates: {
                Miles: [];
                Value: string;
                DealerCost: string;
                RetailPrice: string;
                RemitPrice: string;
                DynamicSurcharge?: DynamicSurcharge[];
              },
              monthIndex: number
            ) => {
              return monthRates.hasOwnProperty("Miles") ? (
                monthRates.Miles.map((mileObj: any, mileIndex: number) => {
                  return mileObj.Deductible.map(
                    (
                      deductible: {
                        DealerCost: string;
                        RetailPrice: string;
                        RemitPrice: string;
                        Value: string;
                        DynamicSurcharge?: DynamicSurcharge[];
                      },
                      deductibleIndex: number
                    ) => {
                      return (
                        <Rate
                          key={monthIndex + mileIndex + deductibleIndex}
                          show={
                            deal.data.info.payment?.numberOfPayments ===
                              parseFloat(monthRates.Value) || showAll
                          }
                          primaryText={primaryText([
                            ["Months", monthRates.Value],
                            ["Miles", mileObj.Value],
                            ["Deductible", deductible.Value],
                            [
                              "Dealer cost",
                              formatNumberAsCurrency(
                                parseFloat(deductible.DealerCost) +
                                  (product?.ProviderName === "ASC Warranty" ? ascExtraProfit : 0)
                              )
                            ],
                            [
                              "Retail price",
                              formatNumberAsCurrency(parseFloat(deductible.RetailPrice))
                            ],
                            [
                              "Remit price",
                              formatNumberAsCurrency(parseFloat(deductible.RemitPrice))
                            ]
                          ])}
                          surcharges={deductible?.DynamicSurcharge ?? []}
                          action={(surcharges) =>
                            handleChooseRate(
                              providerId,
                              "VSC",
                              el,
                              monthIndex,
                              surcharges,
                              mileIndex,
                              deductibleIndex
                            )
                          }
                        />
                      );
                    }
                  );
                })
              ) : (
                <Rate
                  key={monthIndex}
                  show={
                    deal.data.info.payment?.numberOfPayments === parseFloat(monthRates.Value) ||
                    showAll
                  }
                  primaryText={primaryText([
                    ["Months", monthRates.Value],
                    ["Dealer cost", monthRates.DealerCost],
                    ["Retail price", monthRates.RetailPrice],
                    ["Remit price", monthRates.RemitPrice]
                  ])}
                  surcharges={monthRates?.DynamicSurcharge ?? []}
                  action={(surcharges) =>
                    handleChooseRate(providerId, "GAP", el, monthIndex, surcharges)
                  }
                />
              );
            }
          )}
        </div>
      </ListItem>
    </List>
  );
};

export default RateList;
