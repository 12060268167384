import React from "react";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { FormComponent, createOptionsForSelect } from "../../utils/models/fields";
import { AdditionalRVData, ProtectiveInsurance } from "./types";
import ValueField from "./ValueField";

const additionalRVFieldsStruct: FormComponent<AdditionalRVData> = {
  formComponent: "segment",

  width: "full",
  entities: [
    {
      formComponent: "select-field",
      name: "vehicleType",
      label: "Vehicle Type",

      path: ["VehicleDetails", "VehicleType"],
      options: createOptionsForSelect({
        possibleValues: () => [
          "MotorHomeClassA",
          "MotorHomeClassB",
          "MotorHomeClassC",
          "TravelTrailer",
          "LiveInHorseTrailer",
          "FifthWheel",
          "ParkModel",
          "PopUpCamper",
          "SlideInCamper",
          "TrailerOnly"
        ],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      default: "",
      width: "1/4",
      required: true
    },
    {
      formComponent: "text-field",
      name: "chasisMake",
      label: "Chasis make",
      path: ["VehicleDetails", "ChassisMake"],
      default: null,
      show: (stateAccess) =>
        stateAccess.get<AdditionalRVData>(["VehicleDetails", "VehicleType"]) === "TravelTrailer",
      width: "1/4",
      required: true
    },
    {
      formComponent: "text-field",
      name: "chasisModel",
      label: "Chasis model",
      path: ["VehicleDetails", "ChassisModel"],
      default: null,
      show: (stateAccess) =>
        stateAccess.get<AdditionalRVData>(["VehicleDetails", "VehicleType"]) === "TravelTrailer",
      width: "1/4",
      required: true
    },
    {
      formComponent: "select-field",
      name: "ChasisYear",
      label: "Chasis year",
      path: ["VehicleDetails", "ChassisYear"],
      default: null,
      width: "1/4",
      required: true,
      show: (stateAccess) =>
        stateAccess.get<AdditionalRVData>(["VehicleDetails", "VehicleType"]) === "TravelTrailer",
      options: createOptionsForSelect({
        numeric: true,
        possibleValues: () =>
          new Array(new Date().getFullYear() - 1899)
            .fill(1900)
            .map((x, i) => x + i + 1)
            .reverse(),
        getOptionLabel: (x) => (x === null || x === undefined ? "" : x.toString()),
        getSelectedOption: (x, y) => x === y
      })
    }
  ]
};

const markUpStruct: FormComponent<AdditionalRVData> = {
  formComponent: "segment",

  entities: [
    {
      formComponent: "select-field",
      name: "MarkUp",
      label: "MarkUp Type",
      width: "1/2",
      required: true,
      path: ["MarkUp", "Type"],
      options: createOptionsForSelect({
        possibleValues: () => ["ByPercent", "ByAmount"],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      default: "ByAmount"
    },
    {
      formComponent: "one-to-many-field",
      component: (
        stateAccess: StateAccess,
        _mainstateAccess: StateAccess,
        renderSet: RenderSet
      ) => (
        <ValueField
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["MarkUp", "Value"]}
          name="value"
          label="Value"
          required={true}
        />
      ),
      width: "1/2",
      name: "value",
      label: "Value",
      path: ["MarkUp", "Value"],
      required: true,
      default: null
    }
  ]
};

const VSCRateOptionsSrtuct: FormComponent<AdditionalRVData> = {
  formComponent: "segment",

  entities: [
    {
      formComponent: "date-field",
      name: "InServiceDate",
      label: "In Service Date",
      width: "1/2",
      path: ["VSCRateOptions", "InServiceDate"],
      default: null,
      required: true
    },
    {
      formComponent: "select-field",
      name: "vehiclePlan",
      label: "Vehicle plan",

      path: ["VSCRateOptions", "VehiclePlan"],
      options: createOptionsForSelect({
        possibleValues: () => [
          "New",
          "PreOwned",
          "PostSale",
          "Renewal",
          "Rental",
          "ThreeMonthTerm",
          "VehiclesWithout6MonthManufacturersWarranty",
          "VehiclesWithoutCoachManufacturersWarranty"
        ],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      default: "",
      width: "1/2",
      required: true
    }
  ]
};

const surchargesStruct: FormComponent<AdditionalRVData> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "checkbox-field",
      name: "twoYearMfg",
      label: "Two Year Mfg",
      width: "1/6",
      toggle: (stateAccess) =>
        stateAccess.set<AdditionalRVData>(
          ["VSCRateOptions", "Surcharges", "TwoYearMfg"],
          !stateAccess.get<AdditionalRVData>(["VSCRateOptions", "Surcharges", "TwoYearMfg"])
        ),
      isDisabled: () => false,
      isChecked: (stateAccess) =>
        stateAccess.get<AdditionalRVData>(["VSCRateOptions", "Surcharges", "TwoYearMfg"]),
      path: ["VSCRateOptions", "Surcharges", "TwoYearMfg"],
      default: false
    },
    {
      formComponent: "checkbox-field",
      name: "threeYearMfg",
      label: "Three Year Mfg",
      width: "1/6",
      toggle: (stateAccess) =>
        stateAccess.set<AdditionalRVData>(
          ["VSCRateOptions", "Surcharges", "ThreeYearMfg"],
          !stateAccess.get<AdditionalRVData>(["VSCRateOptions", "Surcharges", "ThreeYearMfg"])
        ),
      isDisabled: () => false,
      isChecked: (stateAccess) =>
        stateAccess.get<AdditionalRVData>(["VSCRateOptions", "Surcharges", "ThreeYearMfg"]),
      path: ["VSCRateOptions", "Surcharges", "ThreeYearMfg"],
      default: false
    },
    {
      formComponent: "checkbox-field",
      name: "threePlus",
      label: "Three Plus",
      width: "1/6",
      toggle: (stateAccess) =>
        stateAccess.set<AdditionalRVData>(
          ["VSCRateOptions", "Surcharges", "ThreePlus"],
          !stateAccess.get<AdditionalRVData>(["VSCRateOptions", "Surcharges", "ThreePlus"])
        ),
      isDisabled: () => false,
      isChecked: (stateAccess) =>
        stateAccess.get<AdditionalRVData>(["VSCRateOptions", "Surcharges", "ThreePlus"]),
      path: ["VSCRateOptions", "Surcharges", "ThreePlus"],
      default: false
    },
    {
      formComponent: "checkbox-field",
      name: "businessUse",
      label: "Business Use",
      width: "1/6",
      toggle: (stateAccess) =>
        stateAccess.set<AdditionalRVData>(
          ["VSCRateOptions", "Surcharges", "BusinessUse"],
          !stateAccess.get<AdditionalRVData>(["VSCRateOptions", "Surcharges", "BusinessUse"])
        ),
      isDisabled: () => false,
      isChecked: (stateAccess) =>
        stateAccess.get<AdditionalRVData>(["VSCRateOptions", "Surcharges", "BusinessUse"]),
      path: ["VSCRateOptions", "Surcharges", "BusinessUse"],
      default: false
    },
    {
      formComponent: "checkbox-field",
      name: "ConsequentialFailure",
      label: "Cosequential Failure",
      width: "1/6",
      toggle: (stateAccess) =>
        stateAccess.set<AdditionalRVData>(
          ["VSCRateOptions", "Surcharges", "ConsequentialFailure"],
          !stateAccess.get<AdditionalRVData>([
            "VSCRateOptions",
            "Surcharges",
            "ConsequentialFailure"
          ])
        ),
      isDisabled: () => false,
      isChecked: (stateAccess) =>
        stateAccess.get<AdditionalRVData>(["VSCRateOptions", "Surcharges", "ConsequentialFailure"]),
      path: ["VSCRateOptions", "Surcharges", "ConsequentialFailure"],
      default: false
    }
  ]
};

export const RVRatesStruct: FormComponent<AdditionalRVData> = {
  formComponent: "segment",
  entities: [additionalRVFieldsStruct, markUpStruct, VSCRateOptionsSrtuct, surchargesStruct]
};
export const ProtectiveInsuranceStruct: FormComponent<ProtectiveInsurance> = {
  formComponent: "segment",
  width: "full",
  name: "Insurance info",
  entities: [
    {
      formComponent: "read-only-field",
      name: "Provider",
      label: "Provider",
      path: ["provider"],
      width: "1/4",
      required: true
    },
    {
      formComponent: "read-only-field",
      name: "insuranceType",
      label: "Type",
      path: ["chosenRate", "insuranceType"],
      width: "1/4",
      required: true
    },

    {
      formComponent: "read-only-field",
      name: "months",
      label: "Months",
      path: ["chosenRate", "months"],
      width: "1/4",
      required: true
    },
    {
      formComponent: "read-only-field",
      name: "dealerCostPrice",
      label: "Cost price",
      path: ["chosenRate", "dealerCostPrice"],
      width: "1/4",
      required: true
    },
    {
      formComponent: "currency-field",
      name: "retailPrice",
      label: "Retail price",
      width: "1/4",
      path: ["chosenRate", "retailPrice"],
      default: null,
      required: true
    },
    {
      formComponent: "read-only-field",
      name: "remitPrice",
      label: "Remit price",
      width: "1/4",
      path: ["chosenRate", "dealerCostPrice"],
      required: true
    },
    {
      formComponent: "read-only-field",
      name: "profit",
      label: "Profit",
      width: "1/4",
      path: ["chosenRate", "profit"],
      required: true,
      default: null
    },
    {
      formComponent: "radio-field",
      name: "soldBY",
      label: "Sold by",
      width: "1/2",
      path: ["chosenRate", "soldBy"],
      required: true,
      possibleValues: [
        { label: "WFD", value: "WFD" },
        { label: "Dealer", value: "Dealer" }
      ],
      default: null
    }
  ]
};
export const requiredProtectiveFields = (stateAccess: StateAccess) => [
  {
    name: "Proivder",
    value: stateAccess.get<ProtectiveInsurance>(["provider"])
  },
  {
    name: "Insurance type",
    value: stateAccess.get<ProtectiveInsurance>(["chosenRate", "insuranceType"])
  },
  {
    name: "Months",
    value: stateAccess.get<ProtectiveInsurance>(["chosenRate", "months"])
  },

  {
    name: "Cost price",
    value: stateAccess.get<ProtectiveInsurance>(["chosenRate", "dealerCostPrice"])
  },
  {
    name: "Retail Price",
    value: stateAccess.get<ProtectiveInsurance>(["chosenRate", "retailPrice"])
  },
  {
    name: "Remit Price",
    value: stateAccess.get<ProtectiveInsurance>(["chosenRate", "remitPrice"])
  },
  {
    name: "Sold by",
    value: stateAccess.get<ProtectiveInsurance>(["chosenRate", "soldBy"])
  }
];
