import { Schema } from "ajv";

const oneSpanSigningStatusObject = {
  type: ["string", "null"],
  enum: ["signed", "notSigned", "partiallySigned", "viewed", null],
  nullable: true,
  errorMessage: {
    enum: "must be one of the following: 'signed', 'partiallySigned', 'notSigned' or 'viewed'"
  }
};

export default {
  status: {
    type: "object",
    nullable: true,
    properties: {
      applicant: oneSpanSigningStatusObject,
      coApplicant: oneSpanSigningStatusObject,
      dealership: oneSpanSigningStatusObject
    }
  },
  oneSpanRequestPackUrl: {
    type: "string",
    nullable: true
  },
  generatedPackUrl: {
    type: "string",
    nullable: true
  },
  generatedBy: {
    type: "string",
    nullable: true
  },
  invalidEmails: {
    type: "array",
    nullable: true,
    items: {
      type: "string",
      nullable: true
    }
  },
  generatedAt: {
    type: "string",
    format: "date-time",
    errorMessage: { format: "must be a valid date" },
    nullable: true
  },
  oneSpanEvents: {
    type: "array",
    nullable: true,
    items: {
      type: "object",
      nullable: true,
      properties: {
        sessionUser: {
          type: "string",
          nullable: true
        },
        packageId: {
          type: "string",
          nullable: true
        },
        message: {
          type: "string",
          nullable: true
        },
        documentId: {
          type: "string",
          nullable: true
        },
        createdDate: {
          type: "string",
          format: "date-time",
          errorMessage: { format: "must be a valid date" },
          nullable: true
        },
        "@class": {
          type: "string",
          nullable: true
        },
        name: {
          type: "string",
          nullable: true
        }
      }
    }
  }
} as { [p: string]: Schema };
