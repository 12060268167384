import { createListSlice } from "../../Middlewares/listSliceCreator";
import { TableSettings } from "./types";

const { ListSlice, getList } = createListSlice<TableSettings>({
  name: "TableSettingsList",
  request_topic: "all_table_settings",
  reducers: {}
});

export default ListSlice.reducer;
export const getTableSettingsList = getList;
export const listTableSettingsActions = ListSlice.actions;
export const removeTableSettingsList = ListSlice.actions.removeList;
