import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { Stipulation } from "./types";

interface EditStipulationResponse {
  message: Stipulation;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  Stipulation & { fileName?: string; metadata?: object },
  EditStipulationResponse
>("EditStipulation", {
  request: "update_stipulation"
});

export const editStipulation = socketAction;
export const editStipulationActions = socketMessageSlice.actions;
export const editStipulationError = socketMessageSlice.actions.error;

export default socketMessageSlice.reducer;
