import { generateForm, getByPath, setByPath, StateAccess } from "utils/models/formGenerator";
import { showRenderSet } from "utils/models/formRenderers";
import { FormComponent } from "utils/models/fields";
import React, { useEffect, useState } from "react";
import { Deal, NetsuiteTotals } from "../types";
import Big from "big.js";
import { getDealershipProgramByDeal } from "../Calculations";

const netsuiteTotalsStruct: FormComponent<NetsuiteTotals> = {
  formComponent: "segment",
  width: "full",
  titleStyle: { color: "black" },
  name: "Netsuite estimated totals",
  entities: [
    {
      formComponent: "read-only-number-field",
      name: "Invoice Total",
      label: "Invoice Total",
      width: "1/2",
      path: ["invoiceTotal"],
      default: null
    },
    {
      formComponent: "read-only-number-field",
      name: "Warranty Bill Total",
      label: "Warranty Bill Total",
      width: "1/2",
      path: ["warrantyBillTotal"],
      default: null
    },
    {
      formComponent: "read-only-number-field",
      name: "Gap Bill Total",
      label: "Gap Bill Total",
      width: "1/2",
      path: ["gapBillTotal"],
      default: null
    },
    {
      formComponent: "read-only-number-field",
      name: "Dealer Bill Total",
      label: "Dealer Bill Total",
      width: "1/2",
      path: ["dealerBillTotal"],
      default: null
    }
  ]
};

const calcNetsuiteTotals = (deal: Deal): NetsuiteTotals => {
  const accounting = deal.data.info.accounting;
  const insurances = deal.data.info.aftermarketOptions?.insurances;
  const gapInsurance = insurances
    ? insurances.find((i) => i.chosenRate.insuranceType === "gap")
    : null;
  const warrantyInsurance = insurances
    ? insurances.find((i) => i.chosenRate.insuranceType === "service warranty")
    : null;

  const amountFinancedItems = new Big(deal.data.info?.price?.price || 0)
    .add(new Big(deal.data.info?.price?.options || 0))
    .sub(new Big(deal.data.info?.price?.rebates || 0))
    .sub(new Big(deal.data.info?.price?.totalCash || 0))
    .sub(new Big(deal.data.info?.price?.netTrade || 0))
    .add(new Big(deal.data.info?.taxesAndFees?.titleTransfer || 0))
    .add(new Big(deal.data.info?.taxesAndFees?.documentRegistrationFees || 0))
    .add(new Big(deal.data.info?.taxesAndFees?.filingFees || 0))
    .add(new Big(deal.data.info?.taxesAndFees?.otherFees || 0))
    .add(new Big(deal.data.info?.payment?.totalTaxes || 0))
    .add(new Big(deal.data.info?.vehicleCoverage?.amountOfPremium || 0));

  //Invoice
  const invoiceTotal = amountFinancedItems
    .add(new Big(accounting?.warrantyPrice || 0))
    .add(new Big(accounting?.gapPrice || 0))
    .add(new Big(accounting?.profit?.dealershipProfit?.reserveCommission || 0))
    .add(new Big(accounting?.profit?.wfdProfit?.extraReserveProfit || 0))
    .add(new Big(deal.data.info.taxesAndFees.wfdMinimumFee || 0))
    .add(new Big(deal.data.info?.taxesAndFees?.acquisitionFee || 0))
    .add(new Big(accounting?.paintAndFabricationPrice || 0))
    .add(new Big(accounting?.ecoPrice || 0))
    .add(new Big(accounting?.prePaidMaintenancePrice || 0))
    .add(new Big(accounting?.wheelAndTirePrice || 0))
    .toNumber();

  // Dealer bill
  const soldByDealerGapInsurancePrice =
    gapInsurance?.chosenRate?.soldBy === "Dealer" ? accounting?.gapPrice : 0;

  const soldByDealerWarrantyInsurancePrice =
    warrantyInsurance?.chosenRate?.soldBy === "Dealer" ? accounting?.warrantyPrice : 0;
  const dealershipProgram = getDealershipProgramByDeal(deal);
  const dealershipProgramProfit = (split: number | undefined) =>
    dealershipProgram?.data?.info?.name === "Custom Program" ? split : 0;

  const dealerBillTotal = amountFinancedItems
    .add(new Big(dealershipProgramProfit(accounting?.profit?.dealershipProfit?.splitFromDeal) || 0))
    .add(
      new Big(dealershipProgramProfit(accounting?.profit?.dealershipProfit?.splitTotalFromGap) || 0)
    )
    .add(
      new Big(
        dealershipProgramProfit(
          accounting?.profit?.dealershipProfit?.splitTotalFromServiceWarranty
        ) || 0
      )
    )
    .add(new Big(soldByDealerGapInsurancePrice || 0))
    .add(new Big(soldByDealerWarrantyInsurancePrice || 0))
    .sub(new Big(accounting?.taxesAndFees?.totalUCCFees || 0))
    .sub(new Big(accounting?.taxesAndFees?.totalMembershipFees || 0))
    .sub(new Big(deal.data.info?.taxesAndFees?.wfdMinimumFee || 0))
    .sub(new Big(deal.data.info?.taxesAndFees?.acquisitionFee || 0))
    .sub(new Big(deal.data.info?.taxesAndFees?.lenderAcquisitionFee || 0))
    .add(new Big(accounting?.paintAndFabricationPrice || 0))
    .add(new Big(accounting?.ecoPrice || 0))
    .add(new Big(accounting?.prePaidMaintenancePrice || 0))
    .add(new Big(accounting?.wheelAndTirePrice || 0))
    .toNumber();

  //Gap bill
  const gapBillTotal =
    gapInsurance?.chosenRate?.soldBy === "WFD" ? gapInsurance?.chosenRate?.dealerCostPrice || 0 : 0;

  //Warranty bill
  const warrantyBillTotal =
    warrantyInsurance?.chosenRate?.soldBy === "WFD"
      ? warrantyInsurance?.chosenRate?.dealerCostPrice || 0
      : 0;

  return {
    dealerBillTotal,
    gapBillTotal,
    warrantyBillTotal,
    invoiceTotal
  };
};

export default function ({ deal }: { deal: Deal }) {
  const netsuiteTotals = calcNetsuiteTotals(deal);

  const stateAccess: StateAccess = {
    get: (path) => getByPath(netsuiteTotals as any, path),
    set: (): any => {}
  };

  return (
    <>{generateForm(netsuiteTotalsStruct, stateAccess, [], stateAccess, showRenderSet(false))}</>
  );
}
