import {
  Box,
  Button,
  CircularProgress,
  DialogContentText,
  IconButton,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { RootState } from "app/rootReducer";
import formEditContext from "components/Content/FormEditContext";
import { HintTooltip } from "components/common/HintTooltip";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { LenderNote } from "./types";

import { Help, Warning } from "@material-ui/icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelCircleIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MailIcon from "@material-ui/icons/Mail";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import formatDate from "utils/formatDate";
import { addLenderNote } from "./addLenderNoteSlice";
import { deleteLenderNote } from "./deleteLenderNoteSlice";
import { editLenderNote } from "./editLenderNoteSlice";
import { getLenderNotesList, listLenderNotesActions } from "./listLenderNotesSlice";
import useNotifyLenderNotes from "hooks/useNotifyLenderEmails/useNotifyLenderEmails";
interface Props {
  lenderNotes: LenderNote[];
  ticketId: string;
  assignedLenderEmails: string[];
  editable?: boolean;
}
export default function LenderNotesPreview({
  lenderNotes,
  editable,
  ticketId,
  assignedLenderEmails
}: Props): JSX.Element {
  const dispatch = useDispatch();

  const [addRequestId] = React.useState(uuidv4());
  const [deleteRequestId] = React.useState(uuidv4());

  const [timeout, setTimeoutState] = React.useState<undefined | NodeJS.Timeout>();

  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);

  const addLenderNoteState = useSelector(
    (state: RootState) => state.addLenderNoteSlice[addRequestId]
  );

  const deleteLenderNoteState = useSelector(
    (state: RootState) => state.deleteLenderNoteSlice[deleteRequestId]
  );

  const listLenderNoteState = useSelector(
    (state: RootState) => state.listLenderNoteSlice[ticketId]
  );
  const allEditNoteStates = useSelector((state: RootState) => state.editLenderNoteSlice);
  const editLenderNoteStates = listLenderNoteState?.entities?.map((note) => ({
    _id: note._id,
    state: allEditNoteStates[note._id]
  }));
  const isLoading =
    addLenderNoteState?.status === "waiting" || deleteLenderNoteState?.status === "waiting";

  const { enabled: editMode } = React.useContext(formEditContext);

  const canEdit = editMode || editable;
  const { notifyLenderNotes, loading: notifyLenderNotesLoading } = useNotifyLenderNotes();
  React.useEffect(() => {
    if (!listLenderNoteState && canEdit)
      dispatch(
        getLenderNotesList(ticketId, {
          query: { deleted: false, "data.ticketId": ticketId },
          options: { sort: { createdAt: -1 } }
        })
      );
  }, [dispatch, canEdit]);

  React.useEffect(() => {
    if (
      deleteLenderNoteState !== undefined &&
      deleteLenderNoteState.status === "success" &&
      deleteLenderNoteState.data !== null
    ) {
      const index = listLenderNoteState.entities?.findIndex(
        (n: LenderNote) => n._id === deleteLenderNoteState?.data?.message?._id
      );
      dispatch({
        type: listLenderNotesActions.editList.type,

        payload: { index: index, payload: deleteLenderNoteState?.data?.message, listId: ticketId }
      });
    }
  }, [deleteLenderNoteState, dispatch]);
  const handleNotifyLenderEmail = () => {
    const notes = (listLenderNoteState?.entities ?? lenderNotes)
      .filter((n) => !n.deleted && !n.data.info.sentToLenderEmail)
      .map((n) => n._id);
    if (notes?.length) {
      notifyLenderNotes({ lenderNotesIds: notes, emails: assignedLenderEmails });
    }
  };
  const handleAdd = () => {
    const defaultLenderNote = {
      data: {
        ticketId,
        info: {
          note: "",
          creator: "wfd",
          user: {
            firstName: currentUser?.data?.info?.firstName || null,
            lastName: currentUser?.data?.info?.lastName || null,
            email: currentUser?.data?.info?.email || null
          }
        }
      }
    };
    dispatch(addLenderNote({ requestId: addRequestId, ...(defaultLenderNote as LenderNote) }));
  };

  const handleDelete = (lenderNote: LenderNote) => {
    dispatch(deleteLenderNote({ requestId: deleteRequestId, _id: lenderNote._id }));
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    lenderNote: LenderNote & { index: number }
  ) => {
    const newLenderNote = {
      ...lenderNote,
      data: { ...lenderNote.data, info: { ...lenderNote.data.info, note: event.target.value } },
      updatedAt: new Date().toISOString()
    };
    dispatch({
      type: listLenderNotesActions.editList.type,

      payload: { index: lenderNote.index, payload: newLenderNote, listId: ticketId }
    });
    return newLenderNote;
  };
  const handleUpdate = (lenderNote: LenderNote) => {
    dispatch(editLenderNote({ requestId: lenderNote._id, ...lenderNote }));
  };
  const renderEntities = () => {
    return (listLenderNoteState?.entities ?? lenderNotes)
      .map((n, index) => ({ ...n, index }))
      .filter((n) => !n.deleted)
      .sort((a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime())
      ?.map((lenderNote, index) => {
        const state = editLenderNoteStates?.find((state) => state._id === lenderNote._id)?.state;
        return lenderNote.data.info.creator === "lender" ? (
          <Paper
            key={index}
            style={{
              padding: "10px",
              marginBottom: "20px",
              display: "block",
              backgroundColor: "#6b97f6",
              color: "#fff"
            }}
          >
            <Box display="flex" alignContent="flex-start" flexDirection="column">
              <Typography variant="h6">Note from lender</Typography>
              <Box fontSize={12} display="flex" style={{ justifyContent: "space-between" }}>
                <Typography variant="body1" style={{ overflowWrap: "anywhere" }}>
                  {" "}
                  {lenderNote?.data?.info?.note}
                </Typography>

                {[
                  lenderNote.data?.info?.user?.firstName,
                  lenderNote?.data?.info?.user?.lastName,
                  formatDate(lenderNote?.createdAt, "medium", true)
                ]
                  .filter((x) => x)
                  .join(" ")}
              </Box>
            </Box>
          </Paper>
        ) : (
          <Paper
            key={index}
            style={{
              padding: "10px",
              marginBottom: "20px",
              display: "block",
              background: state?.status === "error" ? "#ffe3e3" : undefined
            }}
          >
            <Box display="flex" alignContent="flex-start">
              <Box flexGrow={1}>
                <TextField
                  multiline
                  onChange={(event) => {
                    if (timeout) {
                      clearTimeout(timeout);
                    }
                    const newNote = handleChange(event, lenderNote);
                    setTimeoutState(
                      setTimeout(() => {
                        handleUpdate(newNote);
                      }, 500)
                    );
                  }}
                  value={lenderNote?.data?.info?.note}
                  name="lenderNote"
                  inputProps={
                    canEdit && listLenderNoteState?.status !== "waiting"
                      ? {}
                      : {
                          readOnly: true
                        }
                  }
                  placeholder="Note"
                  fullWidth
                  label={"Note"}
                  variant={
                    canEdit && listLenderNoteState?.status !== "waiting" ? "filled" : "outlined"
                  }
                  size="small"
                  onBlur={(event) => {
                    if (event.target.value !== lenderNote?.data?.info?.note) {
                      const newNote = handleChange(event, lenderNote);
                      handleUpdate(newNote);
                    }
                  }}
                />
                <Box
                  fontSize={12}
                  justifyContent="space-between"
                  display={"flex"}
                  marginTop={"10px"}
                  style={{ maxHeight: 20 }}
                >
                  <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                    {(() => {
                      switch (state?.status) {
                        case "waiting":
                          return (
                            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                              <CircularProgress
                                size={17}
                                style={{
                                  color: "#50A538",
                                  // marginRight: 5,
                                  minHeight: 20.5,
                                  maxHeight: 20.5
                                }}
                              />
                              Saving
                            </div>
                          );
                        case "error":
                          return (
                            <button
                              className="pulse hover"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                border: "none",
                                background: "#ffe3e3",
                                fontWeight: "bold",
                                columnGap: "3px",
                                borderRadius: 3
                              }}
                              onClick={() => {
                                handleUpdate(lenderNote);
                              }}
                            >
                              <CancelCircleIcon
                                style={{
                                  color: "#E34C28"
                                }}
                              />
                              Error! Click here to retry!
                            </button>
                          );
                        default:
                          return (
                            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                              <CheckCircleIcon
                                style={{
                                  color: "#50A538"
                                }}
                              />
                              Saved
                            </div>
                          );
                      }
                    })()}
                    {(() => {
                      if (notifyLenderNotesLoading && !lenderNote?.data?.info?.sentToLenderEmail) {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <CircularProgress
                              size={17}
                              style={{
                                color: "#50A538",
                                minHeight: 20.5,
                                maxHeight: 20.5
                              }}
                            />
                            Sending
                          </div>
                        );
                      }
                      if (lenderNote?.data?.info?.sentToLenderEmail) {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <MailIcon
                              style={{
                                color: "rgb(37, 78, 110)"
                              }}
                            />
                            Sent notification and email at{" "}
                            {formatDate(lenderNote?.data?.info?.sentToLenderEmailDate, "short")}
                          </div>
                        );
                      } else {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <MailIcon
                              style={{
                                color: "#E34C28"
                              }}
                            />
                            Not sent to lender
                          </div>
                        );
                      }
                    })()}
                  </div>
                  {[
                    lenderNote.data?.info?.user?.firstName,
                    lenderNote?.data?.info?.user?.lastName,
                    formatDate(lenderNote?.createdAt, "medium", true)
                  ]
                    .filter((x) => x)
                    .join(" ")}
                </Box>
              </Box>
              {canEdit && (
                <Box>
                  <IconButton
                    style={{
                      color: "#E34C28"
                    }}
                    key={index}
                    aria-label={`remove lender note`}
                    onClick={() => handleDelete(lenderNote)}
                    disabled={deleteLenderNoteState?.status === "waiting"}
                  >
                    <HintTooltip title={`Click here to remove the lender note.`}>
                      <RemoveCircleIcon />
                    </HintTooltip>
                  </IconButton>
                </Box>
              )}
            </Box>
          </Paper>
        );
      });
  };
  const cantSendEmail = !(
    notifyLenderNotesLoading ||
    editLenderNoteStates?.some((state) => state?.state?.status === "waiting") ||
    assignedLenderEmails.length > 0
  );

  return (
    <Paper
      elevation={0}
      style={{
        breakInside: "avoid",
        pageBreakInside: "avoid",
        transform: "translateZ(1)"
      }}
    >
      <Box style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <DialogContentText
          style={{
            color: "#254e6e",
            fontSize: "19px",
            margin: "5px 0px 5px 0px",

            fontWeight: "bold"
          }}
        >
          Lender Notes (External)
        </DialogContentText>
        <HintTooltip
          style={{ color: "#254e6e", fontSize: "20px", marginLeft: 4 }}
          title={
            <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
              Autosaved / Visible to lender
            </div>
          }
        >
          <Help />
        </HintTooltip>

        {canEdit && (
          <>
            <IconButton
              style={{
                color: "#50A538"
              }}
              aria-label={`add new lender note`}
              onClick={handleAdd}
              disabled={isLoading}
            >
              <HintTooltip title={`Click here to add new lender note.`}>
                {isLoading ? <CircularProgress size={19} /> : <AddCircleIcon />}
              </HintTooltip>
            </IconButton>

            <Button
              variant="outlined"
              style={{ fontSize: "10px", marginLeft: "auto", padding: 5, height: 30 }}
              startIcon={cantSendEmail && <Warning style={{ color: "#eed202" }} />}
              onClick={cantSendEmail ? undefined : handleNotifyLenderEmail}
            >
              Send notes to lender
            </Button>
          </>
        )}
      </Box>

      {renderEntities()}
    </Paper>
  );
}
