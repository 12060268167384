import { EntityType, PermissionEntityData } from "utils/entitySlice";
import { CrudEntity, EntityTypes } from "utils/types";

export const defaultActions = ["read", "update", "create", "delete", "menu"] as const;
export type DefaultAction = typeof defaultActions[number];
export type DataPermissionsNode = {
  hasPermission: boolean;
  filters: any[];
};
export type DataPermissions<T> = Required<
  {
    [a in keyof T]: T[a] extends object ? DataPermissions<Required<T[a]>> : DataPermissionsNode;
  }
>;
export interface PermissionFields<T extends EntityType = EntityType> {
  hasPermission: boolean;
  hasCustomPermission: boolean;
  dataPermissions?: DataPermissions<PermissionStruct<PermissionEntityData<T>>>;
  filters: any[];
}
export type Permission<T extends EntityType = EntityType> = {
  [key in DefaultAction]: key extends "delete" | "create" | "menu"
    ? Omit<PermissionFields<T>, "filters" | "hasCustomPermission" | "dataPermissions">
    : key extends "read"
    ? PermissionFields<T>
    : Omit<PermissionFields<T>, "filters">;
};
export type Permissions = {
  [key in EntityTypes]: Permission<key>;
};

export type Role = CrudEntity & { data: FormState };
export interface FormState {
  info: {
    name: string;
    permissions: Permissions;
  };
}

export type PermissionNode = {
  name: string;
} | null;
export type ManyPermissionNode = {
  multy: boolean;
  name: string;
};

export type RelationPermissionNode = {
  relationName: EntityType;
  name: string;
};
export type PermissionStruct<T> = Required<
  {
    [a in keyof T]: T[a] extends CrudEntity[] | undefined
      ? RelationPermissionNode & { _type: string }
      : T[a] extends Array<any>
      ? ManyPermissionNode & { _type: string }
      : T[a] extends Date
      ? PermissionNode
      : T[a] extends CrudEntity | null
      ? RelationPermissionNode & { _type: string }
      : T[a] extends object
      ? PermissionStruct<Required<T[a]>>
      : PermissionNode;
  }
>;

export const permissionNode = (name: string) => ({
  _type: "node",
  name
});
export const multyPermissionNode = (name: string) => ({
  _type: "multy-node",
  multy: true,
  name
});
export const relationPermissionNode = (
  name: string,
  relationName: EntityType
): RelationPermissionNode & { _type: string } => ({
  _type: "relation-node",
  name,
  relationName
});
