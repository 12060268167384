import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import DateFilter from "components/Filters/DateFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import PhoneFilter from "components/Filters/PhoneFilter";
import TextFilter from "components/Filters/TextFilter";
import React from "react";
import { useSelector } from "react-redux";
import States from "us-states";
import Table, { CellValue, Column } from "../Table";
import { deleteDmv } from "./deleteDmvSlice";
import { getDmvList } from "./listDmvSlice";
import { recoverDmv } from "./recoverDmvSlice";
import { FormState } from "./types";
export const projections = {
  "data.info.state": 1,
  "data.info.contactNumbers": 1,
  "data.notes": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};

export default (): JSX.Element => {
  const dmvList = useSelector((state: RootState) => state.listDmvSlice["table"]);
  const columns: Column<FormState>[] = [
    {
      label: "State",
      getData: (el): CellValue => el.data.info.state,
      name: "state",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.state?.show;
      },
      filters: [
        {
          path: ["data", "info", "state"],
          preview: MultiSelectFilter,
          valuesForSelect: Object.keys(States).sort(),
          label: "State",
          name: "state"
        }
      ]
    },
    {
      label: "Contact Number",
      getData: (el): CellValue =>
        el.data.info.contactNumbers[0]
          ? el.data.info.contactNumbers[0].contactNumber
              ?.toString()
              .replace(/(\d{3})(\d{3})(\d{4})/, "#($1) $2-$3")
          : "",
      name: "contactNumber",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.contactNumber?.show;
      },
      filters: [
        {
          path: ["data", "info", "contactNumbers", "0", "contactNumber"],
          preview: PhoneFilter,
          label: "Contact phone",
          name: "contactPhone"
        }
      ]
    },
    {
      label: "Note",
      getData: (el): CellValue => el.data.notes[0]?.note ?? "",
      name: "note",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.note?.show;
      },
      filters: [
        {
          path: ["data", "notes", "0", "note"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true,
          label: "Note",
          name: "note"
        }
      ]
    },
    {
      label: "Date added",
      getData: (el): CellValue => new Date(el.createdAt),
      options: {},
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter,
          label: "Created At",
          name: "createdAt"
        }
      ]
    },
    {
      label: "Actions",
      getData: (el): CellValue => el._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <>
      <AccessControl requiredPermissions={{ entity: "dmv", action: "read" }}>
        <Table
          tableName="dmvs"
          entityName="dmv"
          listFunction={getDmvList}
          listEntity={dmvList}
          deleteEntityFunction={deleteDmv}
          recoverEntityFunction={recoverDmv}
          title={"DMVs"}
          columns={columns}
          projection={projections}
        />
      </AccessControl>
    </>
  );
};
