import Paper from "@material-ui/core/Paper";
import { RootState } from "app/rootReducer";
import filtersContext from "components/Content/FiltersContext";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { formatNumberAsCurrency } from "utils/functions";
import { defaultStatuses } from "../common";
import { getDealCommissionByStatus } from "./dealCommisionByStatusSlice";
import { randomNumber } from "./FIManagerDashboard";

interface Props {
  from: Date;
  to: Date;
  userIds: string[];
  showNumbers: boolean;
}

export default function SumChart({ showNumbers, from, to, userIds }: Props) {
  const requestId = "dealCommissionByStatus";
  const dispatch = useDispatch();

  const categories = defaultStatuses;

  const { setFilters: setFiltersState } = React.useContext(filtersContext);
  // @ts-ignore
  const state = useSelector(
    (state: RootState) => state.listDealCommisionByStatusSlice[requestId]
    // @ts-ignore
  )?.entities?.reduce((acc, { _id, commission }) => ({ ...acc, [_id]: commission }), {});
  // @ts-ignore
  const items = defaultStatuses.map(
    (status) =>
      // @ts-ignore
      state?.[status] || 0
  );
  useEffect(() => {
    dispatch(
      getDealCommissionByStatus(requestId, {
        query: {
          from,
          to,
          statuses: defaultStatuses,
          userIds
        }
      })
    );
  }, [from, to]);

  return (
    <Paper elevation={3} style={{ height: "275px" }}>
      <ReactApexChart
        options={{
          chart: {
            id: Math.random().toString(),
            events: {
              dataPointSelection: (event, chartContext, config) => {
                setFiltersState((old) => ({
                  ...old,
                  ["fImanagerDeals"]: {
                    status: [
                      {
                        query: {
                          "data.info.status": {
                            $in: [defaultStatuses[config.dataPointIndex]]
                          }
                        },
                        values: [defaultStatuses[config.dataPointIndex]]
                      }
                    ]
                  }
                }));
              }
            }
          },
          labels: categories,
          colors: ["#eed238", "#33b2df", "#52a543", "#ffa500"],
          title: { text: `Commission amount by status`, align: "center" },
          tooltip: {
            enabled: showNumbers
          },
          legend: {
            offsetY: 50,
            position: "right",
            formatter: (value, opts) => {
              return (
                value +
                " <b>" +
                (showNumbers
                  ? formatNumberAsCurrency(opts.w.config.series[opts.seriesIndex], "$")
                  : "") +
                "</b>"
              );
            }
          },
          dataLabels: {
            // distributed: true,
            style: {
              colors: ["black"]
            },
            dropShadow: { enabled: false },
            formatter: (val, opts) => {
              return showNumbers
                ? formatNumberAsCurrency(opts.w.config.series[opts.seriesIndex], "$") ?? ""
                : "xxxx";
            }
          }
        }}
        series={items}
        type="pie"
        height={250}
      />
    </Paper>
  );
}
