import { permissionNode, relationPermissionNode, PermissionStruct } from "components/Roles/types";
import { Notification } from "./types";

export type NotificationPermissionsStruct = PermissionStruct<Required<Notification>>;

export const notificationPermissionStruct: NotificationPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      level: permissionNode("Level"),
      userId: permissionNode("User"),
      referenceId: permissionNode("Reference"),
      title: permissionNode("Title"),
      seenAt: permissionNode("SeenAt"),
      type: permissionNode("Type"),
      body: {
        customer: permissionNode("Customer"),
        dealership: permissionNode("Dealership"),
        contract: permissionNode("Contract"),
        lender: permissionNode("Lender")
      }
    }
  }
};
