import { collateralTypes } from "components/Deals/types";
import FilesPreview from "components/Files/FilesPreview";
import { templateFileStruct } from "components/Files/model";
import React from "react";
import States from "us-states";
import { getStateLabelByState } from "utils/functions";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { FormComponent, Model, createOptionsForSelect } from "../../utils/models/fields";
import LendersPreview from "./LendersPreview";
import ValidationPreview from "./ValidationPreview";
import { DocumentTemplate, Note } from "./types";

export const defaultStates = [
  "Minnesota",
  "Iowa",
  "Wisconsin",
  "North Dakota",
  "South Dakota"
] as const;

const noteStruct: FormComponent<Note> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "text-field",
      name: "note",
      label: "Note",
      required: true,
      width: "2/3",
      path: ["note"],
      default: null
    }
  ]
};
const additionalProperties: FormComponent<DocumentTemplate> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",

      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <LendersPreview<DocumentTemplate>
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["data", "info", "dealTypeProperties", "lenders"]}
          name="lender"
          label="Lender"
        />
      ),
      name: "lender",
      label: "Lender",
      width: "1/4",
      path: ["data", "info", "dealTypeProperties", "lenders"],
      default: null
    },
    {
      formComponent: "multiselect-field",
      name: "state",
      label: "Dealership state",
      width: "1/4",
      path: ["data", "info", "dealTypeProperties", "states"],
      options: createOptionsForSelect({
        possibleValues: () => Object.keys(States),
        getOptionLabel: (x) => getStateLabelByState(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: defaultStates
    },
    {
      formComponent: "select-field",
      name: "state",
      label: "Dealership program type",
      width: "1/4",
      path: ["data", "info", "dealTypeProperties", "programType"],
      options: createOptionsForSelect({
        possibleValues: () => ["Performance Program", "Custom Program"],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "multiselect-field",
      name: "applicantState",
      label: "Applicant state",
      width: "1/4",
      path: ["data", "info", "dealTypeProperties", "applicantStates"],
      options: createOptionsForSelect({
        possibleValues: () => Object.keys(States),
        getOptionLabel: (x) => getStateLabelByState(x),
        getSelectedOption: (x, y) => x === y
      })
    },
    {
      formComponent: "multiselect-field",
      name: "collateralType",
      label: "Collateral type",
      width: "1/4",
      path: ["data", "info", "dealTypeProperties", "types"],
      options: createOptionsForSelect({
        possibleValues: () => [...collateralTypes],
        getOptionLabel: (x) => x ?? "",
        getSelectedOption: (x, y) => x === y
      })
    },
    {
      formComponent: "checkbox-field",
      name: "hasOneApplicant?",
      label: "Only for deals with one Applicant?",
      required: true,
      width: "1/4",
      valueType: "boolean",
      path: ["data", "info", "dealTypeProperties", "hasOneApplicant"],
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<DocumentTemplate>([
          "data",
          "info",
          "dealTypeProperties",
          "hasOneApplicant"
        ]),
      toggle: (stateAccess: StateAccess) =>
        stateAccess.set<DocumentTemplate>(
          ["data", "info", "dealTypeProperties", "hasOneApplicant"],
          !stateAccess.get<DocumentTemplate>([
            "data",
            "info",
            "dealTypeProperties",
            "hasOneApplicant"
          ])
        ),
      default: false
    },
    {
      formComponent: "checkbox-field",
      name: "hasCoApplicant?",
      label: "Only for deals with CoApplicant?",
      required: true,
      width: "1/4",
      valueType: "boolean",
      path: ["data", "info", "dealTypeProperties", "hasCoApplicant"],
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<DocumentTemplate>(["data", "info", "dealTypeProperties", "hasCoApplicant"]),
      toggle: (stateAccess: StateAccess) =>
        stateAccess.set<DocumentTemplate>(
          ["data", "info", "dealTypeProperties", "hasCoApplicant"],
          !stateAccess.get<DocumentTemplate>([
            "data",
            "info",
            "dealTypeProperties",
            "hasCoApplicant"
          ])
        ),
      default: false
    },
    {
      formComponent: "checkbox-field",
      name: "hasGap?",
      label: "Only for deals with Gap?",
      required: true,
      width: "1/4",
      valueType: "boolean",
      path: ["data", "info", "dealTypeProperties", "hasGAP"],
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<DocumentTemplate>(["data", "info", "dealTypeProperties", "hasGAP"]),
      toggle: (stateAccess: StateAccess) =>
        stateAccess.set<DocumentTemplate>(
          ["data", "info", "dealTypeProperties", "hasGAP"],
          !stateAccess.get<DocumentTemplate>(["data", "info", "dealTypeProperties", "hasGAP"])
        ),
      default: false
    },
    {
      formComponent: "checkbox-field",
      name: "hasVSC?",
      label: "Only for deals with VSC?",
      required: true,
      width: "1/4",
      valueType: "boolean",
      path: ["data", "info", "dealTypeProperties", "hasVSC"],
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<DocumentTemplate>(["data", "info", "dealTypeProperties", "hasVSC"]),
      toggle: (stateAccess: StateAccess) =>
        stateAccess.set<DocumentTemplate>(
          ["data", "info", "dealTypeProperties", "hasVSC"],
          !stateAccess.get<DocumentTemplate>(["data", "info", "dealTypeProperties", "hasVSC"])
        ),
      default: false
    },
    {
      formComponent: "checkbox-field",
      name: "hasTrailer?",
      label: "Only for deals with Trailer?",
      required: true,
      width: "1/4",
      valueType: "boolean",
      path: ["data", "info", "dealTypeProperties", "hasTrailer"],
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<DocumentTemplate>(["data", "info", "dealTypeProperties", "hasTrailer"]),
      toggle: (stateAccess: StateAccess) =>
        stateAccess.set<DocumentTemplate>(
          ["data", "info", "dealTypeProperties", "hasTrailer"],
          !stateAccess.get<DocumentTemplate>(["data", "info", "dealTypeProperties", "hasTrailer"])
        ),
      default: false
    },
    {
      formComponent: "checkbox-field",
      name: "multipleAddresses",
      label: "Can have multiple addresses?",
      required: true,
      width: "1/4",
      valueType: "boolean",
      path: ["data", "info", "multipleAddresses"],
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<DocumentTemplate>(["data", "info", "multipleAddresses"]),
      toggle: (stateAccess: StateAccess) =>
        stateAccess.set<DocumentTemplate>(
          ["data", "info", "multipleAddresses"],
          !stateAccess.get<DocumentTemplate>(["data", "info", "multipleAddresses"])
        ),
      default: false
    },
    {
      formComponent: "segment",
      name: "Requirements",
      width: "full",
      entities: [
        {
          formComponent: "checkbox-field",
          name: "requiredToBeReturned",
          label: "Required to be returned",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "requiredToBeReturned"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<DocumentTemplate>(["data", "info", "requiredToBeReturned"]),
          toggle: (stateAccess: StateAccess): DocumentTemplate =>
            stateAccess.set<DocumentTemplate>(
              ["data", "info", "requiredToBeReturned"],
              !stateAccess.get<DocumentTemplate>(["data", "info", "requiredToBeReturned"])
            ),
          required: true,
          default: false
        },
        {
          formComponent: "checkbox-field",
          name: "requiredToBeReturnedbyapplicant",
          label: "Can be returned by applicant",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "requestedBy", "applicant"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<DocumentTemplate>(["data", "info", "requestedBy", "applicant"]),
          toggle: (stateAccess: StateAccess): DocumentTemplate =>
            stateAccess.set<DocumentTemplate>(
              ["data", "info", "requestedBy", "applicant"],
              !stateAccess.get<DocumentTemplate>(["data", "info", "requestedBy", "applicant"])
            ),
          required: true,
          default: false
        },
        {
          formComponent: "checkbox-field",
          name: "requiredToBeReturnedbydealership",
          label: "Can be returned by dealership",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "requestedBy", "dealership"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<DocumentTemplate>(["data", "info", "requestedBy", "dealership"]),
          toggle: (stateAccess: StateAccess): DocumentTemplate =>
            stateAccess.set<DocumentTemplate>(
              ["data", "info", "requestedBy", "dealership"],
              !stateAccess.get<DocumentTemplate>(["data", "info", "requestedBy", "dealership"])
            ),
          required: true,
          default: false
        },
        {
          formComponent: "checkbox-field",
          name: "eSign",
          label: "Required for eSign",
          width: "1/2",
          valueType: "boolean",
          path: ["data", "info", "requestedBy", "eSign"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<DocumentTemplate>(["data", "info", "requestedBy", "eSign"]),
          toggle: (stateAccess: StateAccess): DocumentTemplate =>
            stateAccess.set<DocumentTemplate>(
              ["data", "info", "requestedBy", "eSign"],
              !stateAccess.get<DocumentTemplate>(["data", "info", "requestedBy", "eSign"])
            ),
          required: true,
          default: false
        },
        {
          formComponent: "checkbox-field",
          name: "optionalEsignSignature",
          label: "Optional eSign signature",
          width: "1/2",
          valueType: "boolean",
          path: ["data", "info", "requestedBy", "optionalSignature"],
          isDisabled: (): boolean => false,
          show: (stateAccess: StateAccess): boolean =>
            stateAccess.get<DocumentTemplate>(["data", "info", "requestedBy", "eSign"]),
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<DocumentTemplate>(["data", "info", "requestedBy", "optionalSignature"]),
          toggle: (stateAccess: StateAccess): DocumentTemplate =>
            stateAccess.set<DocumentTemplate>(
              ["data", "info", "requestedBy", "optionalSignature"],
              !stateAccess.get<DocumentTemplate>([
                "data",
                "info",
                "requestedBy",
                "optionalSignature"
              ])
            ),
          required: true,
          default: false
        }
      ]
    }
  ]
};

const files: FormComponent<DocumentTemplate> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess, mainStateAccess, renderSet: RenderSet) => {
        const id = stateAccess.get(["_id"]);
        return (
          <FilesPreview
            showSignatureWarning={
              stateAccess.get(["data", "info", "requestedBy", "eSign"]) === true ? false : true
            }
            tableName={"document_templates_files"}
            type="document_template"
            _id={id}
            struct={templateFileStruct}
          />
        );
      },
      name: "file",
      label: "Files",
      width: "full",
      path: null,
      valueToString: (el) => el.name
    }
  ]
};

const documentTemplate: FormComponent<DocumentTemplate>[] = [
  {
    formComponent: "segment",
    name: "Document template",
    entities: [
      {
        formComponent: "text-field",
        name: "name",
        label: "Document",
        width: "1/3",
        path: ["data", "info", "name"],
        default: "",
        required: true
      },
      {
        formComponent: "text-field",
        name: "name",
        label: "Display name",
        width: "1/3",
        path: ["data", "info", "displayName"],
        default: "",
        required: true
      },
      {
        formComponent: "select-field",
        name: "Type",
        label: "Type",
        width: "1/3",
        path: ["data", "info", "type"],
        options: createOptionsForSelect({
          possibleValues: () => ["representativeDocument", "dealDocument"],
          getOptionLabel: (x) =>
            x === "representativeDocument" ? "Representative document" : "Deal document",
          getSelectedOption: (x, y) => x === y
        }),
        required: true,
        default: null
      }
    ]
  }
];

const warning: FormComponent<DocumentTemplate> = {
  formComponent: "segment",
  name: "Warning: you will be able to upload documents after naming the pack",
  entities: [],
  width: "full"
};

export const addDocumentTemplateStruct: Model<DocumentTemplate> = {
  formComponent: "model",
  schema: "new_document_template",
  name: "document_template",
  entities: [
    ...documentTemplate,
    {
      formComponent: "segment",
      width: "1/2",
      entities: [
        {
          formComponent: "segment",
          width: "1/2",
          entities: [additionalProperties]
        }
      ]
    },
    warning
  ]
};

export const editDocumentTemplateStruct: Model<DocumentTemplate> = {
  formComponent: "model",
  schema: "update_document_template",
  name: "document_template",
  entities: [
    ...documentTemplate,
    {
      formComponent: "segment",
      width: "1/2",
      entities: [
        {
          formComponent: "segment",
          width: "1/2",
          entities: [files, additionalProperties]
        },
        {
          formComponent: "one-to-many-field",
          component: (stateAccess: StateAccess, mainStateAccess, renderSet: RenderSet) => {
            return (
              <ValidationPreview
                index={1}
                document={stateAccess.get<DocumentTemplate>([] as any)}
              />
            );
          },
          name: "validationPreview",
          label: "Validation",
          width: "1/2",
          path: null,
          valueToString: (el) => el.name
        }
      ]
    }
  ]
};
