import Grid from "@material-ui/core/Grid";
import React from "react";
import { editRenderSet } from "utils/models/formRenderers";
import { FilterProps, filterStateAccess } from "./types";
function formatPhoneNumber(phone: string) {
  const formattedPhone = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (formattedPhone) {
    return "(" + formattedPhone[1] + ") " + formattedPhone[2] + "-" + formattedPhone[3];
  }
  return null;
}
export default function PhoneFilter<T extends unknown>({
  setFilter,
  path,
  label,
  filterType,
  filter
}: FilterProps<T>) {
  const stateAccess = filterStateAccess(filter, setFilter, (values: any) => {
    if (values && values?.length > 0) {
      return {
        [path.join(".")]: {
          $in: values.map((value: any) => ({ value }))
        },
        ...(filterType === "aggregatedColumnFilter" ? { filterType } : {})
      };
    } else {
      setFilter(undefined);
    }
  });
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {editRenderSet(false).multiPhoneInputFieldRenderer(
          {
            type: "text",
            width: "full",
            name: label?.toLowerCase() ?? "phone-multi-filter",
            label: label ?? "Phone",
            required: false,
            path: [] as [],
            valueToString: (x) => formatPhoneNumber(x) ?? ""
          },
          stateAccess,
          editRenderSet(false),
          []
        )}
      </Grid>
    </Grid>
  );
}
