import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverLenderTicketRequest {
  _id: string;
}

export interface RecoverLenderTicketResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverLenderTicketRequest,
  RecoverLenderTicketResponse
>("RecoverLenderTicket", {
  request: "recover_lender_ticket"
});

export const recoverLenderTicketActions = socketMessageSlice.actions;
export const recoverLenderTicket = socketAction;
export default socketMessageSlice.reducer;
