import { createListSlice } from "../Middlewares/listSliceCreator";
import { DealerNote } from "./types";

const { ListSlice, getList } = createListSlice<DealerNote>({
  name: "DealerNoteList",
  request_topic: "all_dealer_notes",
  reducers: {}
});
export default ListSlice.reducer;
export const getDealerNotesList = getList;
export const listDealerNotesActions = ListSlice.actions;
export const removeDealerNotesList = ListSlice.actions.removeList;
