import { queryClient } from "app/App";
import { onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { PersonaReport } from "components/Deals/FraudValidation/types/internal/personaReports";
import { PersonaVerification } from "components/Deals/FraudValidation/types/internal/personaVerifications";

type Payload = {
  action: "create" | "update";
  entity: "persona_report" | "persona_verification";
  entry: PersonaReport | PersonaVerification;
};

export const getReactQuerySocket = (currentUser: FirebaseUser | null) => {
  return currentUser?.getIdToken().then(async (token) => {
    const socket = io(process.env.REACT_APP_SOCKET_URL + "/admin", {
      auth: {
        token,
        query: { connectedAt: new Date().getTime() },
        firebaseName: process.env.REACT_APP_FIREBASE_PROJECT_ID
      }
    });
    socket.on("connect", () => {
      console.log("React Query Socket Connected!");
    });
    socket.on(`${currentUser?.email}_live_updates`, (payload: Payload) => {
      //   console.log("message!", payload);
      switch (payload.action) {
        case "create":
          switch (payload.entity) {
            case "persona_verification": {
              const entry = payload.entry as PersonaVerification;
              queryClient.setQueriesData<PersonaVerification[]>(
                [`persona-verifications-${entry.data.applicantId}`],
                (oldData) => {
                  return [...(oldData ?? []), entry];
                }
              );
              break;
            }
            case "persona_report": {
              const entry = payload.entry as PersonaReport;
              queryClient.setQueriesData<PersonaReport[]>(
                [`persona-reports-${entry.data.applicantId}`],
                (oldData) => {
                  return [...(oldData ?? []), entry];
                }
              );
              break;
            }
            default:
              break;
          }
          break;
        case "update":
          switch (payload.entity) {
            case "persona_verification": {
              const entry = payload.entry as PersonaVerification;
              queryClient.setQueriesData<PersonaVerification[]>(
                [`persona-verifications-${entry.data.applicantId}`],
                (oldData) => {
                  const foundIndex = (oldData ?? [])?.findIndex(
                    (oldEntry) => oldEntry?._id === entry?._id
                  );
                  return oldData?.map((x, index) => (index === foundIndex ? entry : x));
                }
              );
              break;
            }
            case "persona_report": {
              const entry = payload.entry as PersonaReport;
              queryClient.setQueriesData<PersonaReport[]>(
                [`persona-reports-${entry.data.applicantId}`],
                (oldData) => {
                  const foundIndex = (oldData ?? [])?.findIndex(
                    (oldEntry) => oldEntry?._id === entry?._id
                  );
                  return oldData?.map((x, index) => (index === foundIndex ? entry : x));
                }
              );
              break;
            }
            default:
              break;
          }
          break;

        default:
          break;
      }
    });
    return socket;
  });
};

const ReactQuerySocketConnection = ({ children }: { children: JSX.Element }) => {
  const [currentUser, setCurrentUser] = useState<FirebaseUser | null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });
  }, []);
  useEffect(() => {
    const socket = getReactQuerySocket(currentUser);

    return () => {
      socket?.then((resolvedSocket) => resolvedSocket.close());
    };
  }, [currentUser]);
  return children;
};

export default ReactQuerySocketConnection;
