import { createSlice } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "app/store";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: { isOpen: false },
  reducers: {
    openEditDashboardDialog(state) {
      return { ...state, isOpen: true };
    },
    closeEditDashboardDialog(state) {
      return { ...state, isOpen: false };
    }
  }
});

export const openDialog = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(dashboardSlice.actions.openEditDashboardDialog());
};

export const closeDialog = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(dashboardSlice.actions.closeEditDashboardDialog());
};

export default dashboardSlice.reducer;
