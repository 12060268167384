import { createSocketMessageSlice } from "components/Middlewares/socketMessageSliceCreator";

type ExportColumnGroupRequest = {
  usersToNotify: { userId: string; userEmail: string }[];
  sendEmail: boolean;
  generatedLink: string;
};

interface ExportColumnGroupResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  ExportColumnGroupRequest,
  ExportColumnGroupResponse
>("ExportColumnGroup", {
  request: "export_column_group"
});

export const exportColumnGroupActions = socketMessageSlice.actions;
export const exportColumnGroup = socketAction;
export default socketMessageSlice.reducer;
