import React, { useState, useContext } from "react";
import {
  StateAccess,
  RenderSet,
  generateForm,
  getByPath,
  setByPath,
  generateDefault,
  fillDefaultsByPath
} from "utils/models/formGenerator";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { getRVRates } from "./getRVRatesSlice";
import { RVRatesStruct } from "./model";
import { VerificationContext } from "components/Layout/Main";
import { AdditionalRVData } from "./types";
import { Deal } from "components/Deals/types";
import { removeEmpty } from "components/AppOne/Deal/addDealSlice";
interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;

  requestId: string;
}

export default function ProtectivePreRates({
  stateAccess,
  renderSet,

  requestId
}: Props) {
  const vehicle: Deal["data"]["info"]["vehicle"] = stateAccess.get<Deal>([
    "data",
    "info",
    "vehicle"
  ]);

  const dispatch = useDispatch();

  const checkDataValidity = useContext(VerificationContext);
  const initialState = generateDefault(RVRatesStruct, {}, fillDefaultsByPath as any) as any;
  const [state, setState] = useState<AdditionalRVData>(initialState);

  const additionalRVDataStateAccess: StateAccess = {
    get: (path) => getByPath(state as any, path),
    set: (path, value): any => setState(setByPath(state as any, path, value))
  };

  const additionalRVData: AdditionalRVData = additionalRVDataStateAccess.get([]);

  const requiredFields = [
    { name: "Vehicle -> VIN", value: vehicle.VIN },
    { name: "Vehicle -> Make", value: vehicle.make },
    { name: "Vehicle -> Year", value: vehicle.year },
    { name: "Vehicle -> Model", value: vehicle.model },
    { name: "Vehicle -> Odometer", value: vehicle.odometer }
  ];
  const handleClick = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const vehicleData = {
      RVs: {
        RVRateQuoteRequest: {
          VehicleDetails: {
            VehicleYear: vehicle.year,
            VehicleModel: vehicle.model,
            VehicleMake: vehicle.make,
            VehicleMsrp: vehicle.MSRP,
            VehicleType: additionalRVData?.VehicleDetails.VehicleType,
            VehicleClassCode: additionalRVData?.VehicleDetails.VehicleClassCode,
            ChassisModel: additionalRVData?.VehicleDetails.ChassisModel ?? null,
            ChassisMake: additionalRVData?.VehicleDetails.ChassisMake ?? null,
            ChassisYear: additionalRVData?.VehicleDetails.ChassisYear ?? null,
            InServiceDate: additionalRVData?.VSCRateOptions.InServiceDate
          },
          VSCRateOptions: {
            BeginningOdometer: vehicle.odometer,
            InServiceDate: additionalRVData?.VSCRateOptions.InServiceDate,
            Surcharges: {
              BusinessUse: additionalRVData?.VSCRateOptions.Surcharges.BusinessUse,
              TwoYearMfg: additionalRVData?.VSCRateOptions.Surcharges.TwoYearMfg,
              ThreeYearMfg: additionalRVData?.VSCRateOptions.Surcharges.ThreeYearMfg,
              ThreePlus: additionalRVData?.VSCRateOptions.Surcharges.ThreePlus,
              ConsequentialFailure: additionalRVData?.VSCRateOptions.Surcharges.ConsequentialFailure
            },
            VehiclePlan: additionalRVData?.VSCRateOptions.VehiclePlan
          },
          MarkUp: {
            Type: additionalRVData?.MarkUp.Type,
            Value: additionalRVData?.MarkUp.Value
          }
        }
      }
    };

    checkDataValidity(requiredFields, () => {
      dispatch(
        getRVRates({
          requestId,
          data: removeEmpty(vehicleData)
        })
      );
    });
  };

  return (
    <>
      <form id="preRates-form" onSubmit={handleClick}>
        {generateForm(RVRatesStruct, additionalRVDataStateAccess, [], stateAccess, renderSet)}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
            padding: "10px"
          }}
        >
          <Button form="preRates-form" type="submit" color="primary" variant="contained">
            Get rates
          </Button>
        </div>
      </form>
    </>
  );
}
