import {
  Button,
  Dialog,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";

import {
  getByPath,
  setByPath,
  StateAccess,
  generateForm,
  generateDefault,
  fillDefaultsByPath
} from "utils/models/formGenerator";
import { RootState } from "app/rootReducer";
import { HintTooltip } from "components/common/HintTooltip";
import AccessControl from "components/Access/AccessControl";
import { EntityData, entityConfigs } from "utils/entitySlice";
import { XlStyledDialog } from "components/common/StyledDialog";
import CloseDialogButton from "components/common/CloseDialogButton";
import { lockEntity } from "utils/models/LockEntity/lockEntitySlice";
import { editRenderSet, showRenderSet } from "utils/models/formRenderers";

import { LenderTicketType } from "./types";
import { lenderTicketTypeModel } from "./model";
import { addLenderTicketType } from "./addLenderTicketTypeSlice";
import { editLenderTicketType } from "./editLenderTicketTypeSlice";

export default ({
  setSelected,
  selected,
  editMode
}: {
  selected: LenderTicketType | null;
  setSelected: (types: LenderTicketType) => void;
  editMode: boolean | undefined;
}) => {
  const initialState =
    selected ?? (generateDefault(lenderTicketTypeModel, {}, fillDefaultsByPath as any) as any);
  const currentUser = useSelector((state: RootState) => state.authSlice);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [requestId] = useState(uuidv4());
  const [canSubmit, setCanSubmit] = useState(true);
  const formName = `new-types`;
  const [formState, setFormState] = React.useState(initialState);
  const lockData = useSelector((state: RootState) => state.listLockSlice["all"])?.entities?.find(
    (lock) =>
      lock?.data?.info?.entityId === initialState?._id &&
      lock?.data?.info?.entityName === "lender_ticket_type"
  );
  const stateAccess: StateAccess = {
    get: (path) => getByPath(formState as any, path),
    set: (path, value): any => setFormState(setByPath(formState as any, path, value))
  };
  const actionState = useSelector((state: RootState) =>
    selected?._id
      ? state.editLenderTicketTypeSlice[requestId]
      : state.addLenderTicketTypeSlice[requestId]
  );

  const editActionState = useSelector(
    (state: RootState) => state.editLenderTicketTypeSlice[requestId]
  );

  useEffect(() => {
    if (selected === null) {
      setFormState(generateDefault(lenderTicketTypeModel, {}, fillDefaultsByPath as any) as any);
    } else {
      setFormState(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (actionState?.status === "success" && actionState?.data !== null) {
      setSelected({
        data: actionState.data.message.data,
        _id: actionState.data.message._id,
        createdAt: actionState.data.message.createdAt,
        updatedAt: actionState.data.message.updatedAt
      });
    }
  }, [actionState, setSelected]);

  useEffect(() => {
    if (editActionState?.status === "success" && editActionState?.data !== null) {
      dispatch(
        lockEntity({
          requestId,
          data: {
            info: {
              entityId: editActionState?.data.message?._id,
              entityName: "lender_ticket_type",
              action: "unlock"
            }
          }
        })
      );
    }
  }, [editActionState, setSelected, dispatch, requestId]);

  useEffect(() => {
    dispatch({
      type: entityConfigs.lender_ticket_type.edit.sliceActions.add.type,
      payload: { requestId }
    });

    return () => {
      dispatch({
        type: entityConfigs.lender_ticket_type.edit.sliceActions.remove.type,
        payload: { requestId }
      });
    };
  }, [requestId, dispatch]);

  const handleSubmit = (state: EntityData<"lender_ticket_type">) => (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(false);
    if (canSubmit) {
      setCanSubmit(false);
      if (!selected?._id) {
        dispatch(addLenderTicketType({ ...state, requestId }));
      } else {
        const { _id, deleted, ...rest } = state;
        dispatch(editLenderTicketType({ _id: selected._id, ...rest, requestId }));
      }
      setTimeout(() => {
        setCanSubmit(true);
      }, 2000);
    } else {
      alert("Please dont submit the form twice!");
    }
  };

  const handleOpen = () => {
    if (
      lockData?.data?.info?.userEmail &&
      lockData?.data?.info?.userEmail !== currentUser?.user?.email
    ) {
      setOpenAlert(true);
    } else {
      if (selected?._id)
        dispatch(
          lockEntity({
            requestId,
            data: {
              info: {
                entityId: selected?._id,
                entityName: "lender_ticket_type",
                action: "lock"
              }
            }
          })
        );
      setOpen(true);
    }
  };

  const handleLock = () => {
    if (selected)
      dispatch(
        lockEntity({
          requestId,
          data: {
            info: { entityId: selected?._id, entityName: "lender_ticket_type", action: "lock" }
          }
        })
      );
    setOpen(true);
    setOpenAlert(false);
  };

  const handleClose = () => {
    if (selected?._id) {
      dispatch(
        lockEntity({
          requestId,
          data: {
            info: { entityId: selected?._id, entityName: "lender_ticket_type", action: "unlock" }
          }
        })
      );
    }
    setOpen(false);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <AccessControl
        key={`add-lender_ticket_type-button`}
        requiredPermissions={{ entity: "lender_ticket_type", action: "create" }}
      >
        <Dialog
          id="confirm-unlock-dialog"
          open={openAlert}
          onClose={handleCloseAlert}
          aria-labelledby="unlock-lender_ticket_type-bank"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to unlock this reason?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {lockData &&
                `Locked by ${lockData?.data?.info?.userEmail}  
           ${new Intl.DateTimeFormat("en", {
             day: "2-digit",
             month: "2-digit",
             year: "2-digit",
             hour: "2-digit",
             minute: "2-digit",
             second: "2-digit"
           }).format(new Date(lockData?.updatedAt))}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAlert} variant="contained" color="primary" id="cancel">
              Cancel
            </Button>
            <Button
              onClick={handleLock}
              variant="contained"
              color="secondary"
              autoFocus
              id="confirm"
            >
              Unlock
            </Button>
          </DialogActions>
        </Dialog>
        <IconButton
          size="small"
          style={{ margin: "3px 0px 0px 10px" }}
          aria-label={`add-lender_ticket_type`}
          id={"add-lender_ticket_type-button"}
          onClick={handleOpen}
        >
          {editMode ? (
            !selected?._id ? (
              <HintTooltip title="Click here to add a new reason.">
                <AddIcon />
              </HintTooltip>
            ) : (
              <HintTooltip title="Click here to edit the reason.">
                <EditIcon />
              </HintTooltip>
            )
          ) : (
            <HintTooltip title="Click here to view the reason.">
              <VisibilityIcon />
            </HintTooltip>
          )}
        </IconButton>
      </AccessControl>
      {open ? (
        <XlStyledDialog>
          <DialogContent>
            <CloseDialogButton closeFunction={handleClose} />
            <form id={formName} autoComplete="off" onSubmit={handleSubmit(formState as any)}>
              {generateForm(
                lenderTicketTypeModel,
                stateAccess,
                [],
                stateAccess,
                editMode
                  ? editRenderSet("new_lender_ticket_type")
                  : showRenderSet("new_lender_ticket_type")
              )}
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="contained">
              Cancel
            </Button>
            {editMode ? (
              <Button
                disabled={!canSubmit}
                form={formName}
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            ) : null}
          </DialogActions>
        </XlStyledDialog>
      ) : null}
    </>
  );
};
