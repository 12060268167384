import Grid from "@material-ui/core/Grid";
import { RootState } from "app/rootReducer";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOptionsForSelect } from "utils/models/fields";
import { editRenderSet } from "utils/models/formRenderers";
import { getRoleList, removeRoleList } from "../Roles/listRoleSlice";
import { FilterProps, filterStateAccess } from "./types";

export default function RolesFilter<T extends unknown>({
  setFilter,
  path,
  filterType,
  label,
  filter
}: FilterProps<T>) {
  const dispatch = useDispatch();
  const listId = `list-role-filter`;
  const rolesList = useSelector((state: RootState) => state.listRoleSlice[listId]);

  const handleOpen = () => {
    if (!rolesList)
      dispatch(
        getRoleList(listId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } }
        })
      );
    return () => {
      dispatch(removeRoleList(listId));
      return;
    };
  };

  const stateAccess = filterStateAccess(filter, setFilter, (values: any) => {
    if (values && values?.length > 0 && values?.every((value: any) => value?._id) !== undefined) {
      return {
        [path.join(".")]: {
          $in: values.map((value: any) => ({ value: value?._id }))
        },
        ...(filterType === "aggregatedColumnFilter" ? { filterType } : {})
      };
    } else {
      setFilter(undefined);
    }
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {editRenderSet(false).multiSelectInputRenderer(
          {
            formComponent: "multiselect-field",
            name: label?.toLowerCase() ?? "roles-multi-filter",
            label: label ?? "Roles",
            required: false,
            path: [] as [],
            onOpen: handleOpen,
            options: createOptionsForSelect({
              possibleValues: () => rolesList?.entities ?? [],
              getOptionLabel: (role) => role.data.info.name,
              getSelectedOption: (x, y) => x?._id === y?._id
            }),
            valueToString: (role) => role.data.info.name
          },
          stateAccess,
          [],
          stateAccess,
          editRenderSet(false)
        )}
      </Grid>
    </Grid>
  );
}
