import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseDialogButton from "components/common/CloseDialogButton";
import { HintTooltip } from "components/common/HintTooltip";
import formEditContext from "components/Content/FormEditContext";
import React, { useContext, useState } from "react";
import { FormComponent, Path } from "utils/models/fields";
import { generateForm, RenderSet, StateAccess } from "utils/models/formGenerator";
import { Deal } from "./types";
export const maxReserveStruct: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "currency-field",
      name: "Max Reserve",
      label: "Max Reserve",
      width: "full",
      path: ["maxReserve"],
      required: true,
      default: null
    }
  ]
};

export default function ({
  stateAccess,
  renderSet,
  path
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  path: Path<Deal>;
}) {
  const [open, setOpen] = useState(false);
  const initialMaxState = stateAccess.get<Deal>(path);
  const [maxReserve, setMaxReserve] = useState(initialMaxState);
  const { enabled: editMode } = useContext(formEditContext);
  const maxReserveStateAccess: StateAccess = {
    get: (path) => {},
    set: (path, value): any => setMaxReserve(value)
  };

  const handleClose = () => setOpen(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    stateAccess.set<Deal>(path, maxReserve);
    handleClose();
  };

  return (
    <>
      {editMode &&
        (initialMaxState ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0px",
              position: "absolute",
              right: "0px",
              top: "105px",
              padding: "1px",
              border: "solid 1px",
              background: "#000",
              color: "#fff",
              borderRadius: "4px"
            }}
          >
            <div style={{ flexGrow: 1 }}>Max: {initialMaxState}</div>
            <div>
              <IconButton
                style={{ padding: "0px", filter: "invert(1)" }}
                onClick={() => stateAccess.set<Deal>(path, null)}
              >
                <CancelIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        ) : (
          <div style={{ position: "absolute", top: "110px", right: "-5px" }}>
            <IconButton
              style={{
                color: "#af5ac7"
              }}
              aria-label={`add max reserve`}
              onClick={() => setOpen(true)}
            >
              <HintTooltip title={`Click here to add max reserve.`}>
                <AddCircleIcon />
              </HintTooltip>
            </IconButton>
            <Dialog
              id="add-max-reserve"
              open={open}
              onClose={handleClose}
              aria-labelledby="add-max-reserve"
              aria-describedby="add-max-reserve"
            >
              <DialogTitle id="add-max-reserve=title">
                <CloseDialogButton closeFunction={handleClose} />
              </DialogTitle>
              <DialogContent>
                <DialogContent>
                  <form id="max-reserve-form" onSubmit={handleSubmit}>
                    {generateForm(
                      maxReserveStruct,
                      maxReserveStateAccess,
                      [],
                      maxReserveStateAccess,
                      renderSet
                    )}
                  </form>
                </DialogContent>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="contained" color="primary" id="cancel">
                  Cancel
                </Button>
                <Button
                  form="max-reserve-form"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  autoFocus
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ))}
    </>
  );
}
