import React from "react";
import { capitalize } from "utils/functions";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { Model, createOptionsForSelect, FormComponent } from "../../utils/models/fields";
import { LenderTicket, possibleValues, PriorityValues } from "./types";
import FilesPreview from "components/Files/FilesPreview";
import { generateFileStruct } from "components/Files/model";
import PreviewDealOrTitleIssue from "./PreviewDealOrTitleIssue";
import { userStruct } from "components/Users/model";
import UserPreview from "components/Users/UserPreview";
import LenderNotesPreview from "./LenderNotes/LenderNotesPreview";
import AccessControl from "components/Access/AccessControl";
import NotesPreview from "components/Notes/NotesPreview";
import formEditContext from "components/Content/FormEditContext";
import useUpdateLenderTicketNotes from "hooks/useUpdateLenderTicketNotes/useUpdateLenderTicketNotes";
import LenderPreview from "components/Lenders/LenderPreview";
import { lenderStruct } from "components/Lenders/model";
import DealSearch from "components/Deals/DealSearch/Search";
import { Button, TextField } from "@material-ui/core";
import { Deal } from "components/Deals/types";
import TitleIssuePreview from "components/TitleIssues/TitleIssuePreview";
import UsersPreview from "components/Users/UserMultiSelect";
import LenderTicketTypePreview from "components/LenderTicketTypes/LenderTicketTypePreview";

const titlePreview: FormComponent<LenderTicket> = {
  formComponent: "segment",
  name: "Title Information",
  titleStyle: { color: "white", margin: 10, padding: 10 },
  style: { background: "#254e6e", color: "white" },
  show: (stateAccess) =>
    window.location.href.includes("lender_ticket") && stateAccess.get(["data", "titleIssue"]),
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (stateAccess) => (
        <AccessControl requiredPermissions={{ entity: "lender_ticket", action: "read" }}>
          <TitleIssuePreview
            path={["data", "titleIssue"]}
            stateAccess={stateAccess}
            deal={stateAccess.get(["data", "deal"])}
          />
        </AccessControl>
      ),
      path: null,
      name: "Notes",
      width: "full"
    }
  ]
};

const lenderNotes: FormComponent<LenderTicket> = {
  formComponent: "segment",
  width: "1/2",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "one-to-many-field",
          component: (stateAccess) => (
            <AccessControl requiredPermissions={{ entity: "lender_note", action: "create" }}>
              <LenderNotesPreview
                editable={window.location.href.includes("titleIssue")}
                lenderNotes={stateAccess.get(["data", "lenderNotes"]) ?? []}
                assignedLenderEmails={stateAccess.get(["data", "replyTo"]) ?? []}
                ticketId={stateAccess.get(["_id"])}
              />
            </AccessControl>
          ),
          path: null,
          name: "Notes",
          width: "full"
        }
      ]
    }
  ]
};

const files: FormComponent<LenderTicket> = {
  formComponent: "segment",
  name: "Ticket documents",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      name: "documents",
      width: "full",
      path: null,
      component: (stateAccess: StateAccess) => {
        return (
          <FilesPreview
            tableName="files"
            type="lender_ticket"
            _id={stateAccess.get(["_id"])}
            struct={generateFileStruct(
              createOptionsForSelect({
                possibleValues: (stateAccess: StateAccess) => [],
                getOptionLabel: (name) => name
              })
            )}
          />
        );
      }
    }
  ]
};

const assignee: FormComponent<LenderTicket> = {
  formComponent: "segment",
  name: "Assignees",
  width: "1/2",
  entities: [
    {
      formComponent: "one-to-many-field",
      struct: () => userStruct,
      component: (stateAccess: StateAccess, _: StateAccess, renderSet: RenderSet) => (
        <UsersPreview
          outerQuery={{ "data.info.lenderSupport": true }}
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["data", "assignees"]}
          name="user"
          label="Assignees"
        />
      ),
      name: "user",
      label: "Assignees",
      width: "full",
      path: ["data", "assignee"],
      default: null,
      valueToString: (el) => el.firstName
    }
  ]
};

const previewDeal: FormComponent<LenderTicket> = {
  formComponent: "segment",
  width: "1/3",
  style: { marginTop: 10 },
  show: (stateAccess) => stateAccess.get(["data", "deal", "_id"]),
  entities: [
    {
      formComponent: "one-to-many-field",
      name: "previewDeal",
      width: "full",
      path: null,
      component: (stateAccess: StateAccess) => {
        return (
          <PreviewDealOrTitleIssue entityName="deal" _id={stateAccess.get(["data", "dealId"])} />
        );
      }
    }
  ]
};
const previewTitleIssue: FormComponent<LenderTicket> = {
  formComponent: "segment",
  width: "1/3",
  style: { marginTop: 10 },
  show: (stateAccess) => stateAccess.get(["data", "deal", "_id"]),
  entities: [
    {
      formComponent: "one-to-many-field",
      name: "Preview Title Information",
      width: "full",
      path: null,
      component: (stateAccess: StateAccess) => {
        return (
          <PreviewDealOrTitleIssue
            entityName="title_issue"
            _id={stateAccess.get(["data", "titleIssueId"])}
          />
        );
      }
    }
  ]
};
const dealInfoSegment: FormComponent<LenderTicket> = {
  formComponent: "segment",
  width: "full",
  show: (stateAccess) => stateAccess.get(["data", "deal", "_id"]),

  name: "Deal Info",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (stateAccess) => {
        const deal: Deal = stateAccess.get(["data", "deal"]);
        const names = [
          deal.data?.applicant
            ? `${deal.data?.applicant?.data?.info?.firstName ?? ""} ${
                deal.data?.applicant?.data?.info?.lastName ?? ""
              }`
            : undefined,
          deal.data?.coApplicant
            ? `${deal.data?.coApplicant?.data?.info?.firstName ?? ""} ${
                deal.data?.coApplicant?.data?.info?.lastName ?? ""
              }`
            : undefined
        ]
          .filter((x) => x)
          .join(" / ");
        return (
          <TextField
            id={"dealCustomerNames"}
            value={names || " "}
            inputProps={{
              readOnly: true
            }}
            name={"Customer"}
            fullWidth
            label={"Customer"}
            variant="outlined"
            size="small"
          />
        );
      },
      name: "Customer",
      label: "Customer",
      width: "1/2",
      path: null
    },
    {
      formComponent: "read-only-field",
      name: "State",
      label: "State",
      width: "1/2",
      required: true,
      path: ["data", "deal", "data", "applicant", "data", "info", "currentState"],
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "Status",
      label: "Status",
      width: "1/2",
      required: true,
      path: ["data", "deal", "data", "info", "status"],
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "VIN",
      label: "VIN",
      width: "1/2",
      required: true,
      path: ["data", "deal", "data", "info", "vehicle", "VIN"],
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "Type",
      label: "Type",
      width: "1/2",
      required: true,
      path: ["data", "deal", "data", "info", "type"],
      default: null
    },
    {
      formComponent: "read-only-date-field",
      name: "Contract date",
      label: "Contract date",
      width: "1/2",
      required: true,
      path: ["data", "deal", "data", "info", "dealDates", "contractDate"],
      default: null
    }
  ]
};
export const lenderTicketStruct: Model<LenderTicket> = {
  formComponent: "model",
  schema: "new_lender_ticket",
  name: "lender_ticket",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      entities: [
        {
          formComponent: "one-to-many-field",
          path: [],
          name: "notesUpdater",
          component: (stateAccess) => {
            const { enabled: editMode } = React.useContext(formEditContext);

            const { updateLenderTicketNotes } = useUpdateLenderTicketNotes();
            React.useEffect(() => {
              const ticket = stateAccess.get([]) as LenderTicket;
              if (
                editMode &&
                ticket?.data?.lenderNotes?.some((note) => !note?.data?.info?.seen?.wfd)
              ) {
                updateLenderTicketNotes(ticket);
              }
            }, [editMode]);
            return null;
          }
        },
        {
          formComponent: "segment",
          width: "full",
          entities: [
            {
              formComponent: "segment",
              name: (stateAccess) => {
                return `Ticket info: ${stateAccess?.get?.(["data", "info", "refNumber"])}`;
              },
              width: "1/2",
              entities: [
                {
                  formComponent: "text-field",
                  name: "subject",
                  label: "Subject",
                  width: "1/2",
                  required: true,
                  path: ["data", "info", "subject"],
                  default: null
                },

                {
                  formComponent: "select-field",
                  name: "priority",
                  label: "Priority",
                  width: "1/2",
                  required: true,
                  path: ["data", "info", "priority"],
                  options: createOptionsForSelect({
                    possibleValues: () => PriorityValues,
                    getOptionLabel: (x) => capitalize(x),
                    getSelectedOption: (x, y) => x === y
                  }),
                  default: null
                },
                {
                  formComponent: "select-field",
                  name: "status",
                  label: "Status",
                  width: "1/2",
                  path: ["data", "info", "status"],
                  options: createOptionsForSelect({
                    possibleValues: () => possibleValues,
                    getOptionLabel: (x) => capitalize(x),
                    getSelectedOption: (x, y) => x === y
                  }),
                  default: null
                },

                {
                  formComponent: "read-only-field",
                  name: "Source",
                  label: "Source",
                  width: "1/2",
                  required: true,
                  path: ["data", "info", "source"],
                  default: null
                },
                {
                  formComponent: "text-field",
                  multiline: true,
                  name: "description",
                  label: "Description",
                  width: "1/2",
                  required: true,
                  path: ["data", "info", "description"],
                  default: null
                },
                {
                  formComponent: "one-to-many-field",
                  component: (
                    stateAccess: StateAccess,
                    _mainstateAccess: StateAccess,
                    renderSet: RenderSet
                  ) => (
                    <LenderTicketTypePreview
                      renderSet={renderSet}
                      type={stateAccess.get(["data", "type"])}
                      setType={(type) => {
                        stateAccess.set(["data", "type"], type);
                      }}
                    />
                  ),
                  name: "lender_ticket_type",
                  label: "lender_ticket_type",
                  width: "1/2",
                  path: [],
                  default: null
                },
                {
                  formComponent: "segment",
                  name: "Contact Info",
                  width: "1/2",
                  entities: [
                    {
                      formComponent: "one-to-many-field",
                      struct: () => lenderStruct,
                      component: (
                        stateAccess: StateAccess,
                        _mainstateAccess: StateAccess,
                        renderSet: RenderSet
                      ) => (
                        <LenderPreview
                          stateAccess={stateAccess}
                          renderSet={renderSet}
                          lenderIdPath={["data", "lenderId"]}
                          path={["data", "lender"]}
                          name="lender"
                          label="Lender"
                          type="lender_ticket"
                        />
                      ),
                      name: "lender",
                      label: "Lender",
                      width: "1/2",
                      path: ["data", "lender"],
                      default: null
                    },
                    {
                      formComponent: "multi-email-field",
                      name: "Reply To",
                      valueToString: (emails) => emails?.map((email: any) => email)?.join(", "),
                      label: "Reply to",
                      required: true,
                      width: "1/2",
                      path: ["data", "replyTo"],
                      default: null
                    }
                  ]
                },
                assignee
              ]
            },
            {
              formComponent: "segment",
              width: "1/2",
              //@ts-ignore
              name: (stateAccess) => {
                if (!stateAccess.get(["data", "deal", "_id"])) return "Select Deal";
              },

              entities: [
                {
                  formComponent: "one-to-many-field",
                  component: (
                    stateAccess: StateAccess,
                    _mainstateAccess: StateAccess,
                    renderSet: RenderSet
                  ) => (
                    <DealSearch
                      stateAccess={stateAccess}
                      renderSet={renderSet}
                      handleSelectDeal={(deal) => {
                        stateAccess.set(["data", "deal"], deal);
                      }}
                    />
                  ),
                  name: "search",
                  label: "search",
                  width: "full",
                  show: (stateAccess) => !stateAccess.get(["data", "deal", "_id"]),
                  path: [],
                  default: null
                },
                {
                  formComponent: "segment",
                  width: "full",
                  show: (stateAccess) => stateAccess.get(["data", "deal", "_id"]),
                  entities: [
                    dealInfoSegment,
                    previewDeal,
                    previewTitleIssue,
                    {
                      formComponent: "one-to-many-field",
                      component: (
                        stateAccess: StateAccess,
                        _mainstateAccess: StateAccess,
                        renderSet: RenderSet
                      ) => {
                        return (
                          <Button
                            variant="contained"
                            onClick={() => stateAccess.set(["data", "deal"], null)}
                          >
                            Remove deal
                          </Button>
                        );
                      },
                      name: "removeDealButton",
                      label: "removeDealButton",
                      width: "1/3",
                      path: [],
                      default: null
                    }
                  ]
                }
              ]
            }
          ]
        },

        files,
        {
          formComponent: "segment",
          width: "full",
          entities: [
            lenderNotes,
            {
              formComponent: "segment",
              width: "1/2",
              entities: [
                {
                  formComponent: "one-to-many-field",
                  component: (stateAccess) => (
                    <AccessControl requiredPermissions={{ entity: "note", action: "update" }}>
                      <NotesPreview
                        editable={window.location.href.includes("titleIssue")}
                        type="lenderTicket"
                        notes={stateAccess.get(["data", "notes"]) ?? []}
                        lenderTicketId={stateAccess.get(["_id"])}
                      />
                    </AccessControl>
                  ),
                  path: null,
                  name: "Notes",
                  width: "full"
                }
              ]
            }
          ]
        },
        titlePreview
      ]
    }
  ]
};
export const addLenderTicketStruct: Model<LenderTicket> = {
  formComponent: "model",
  schema: "new_lender_ticket",
  name: "lender_ticket",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      entities: [
        {
          formComponent: "segment",
          name: "Ticket Info",
          width: "1/2",
          entities: [
            {
              formComponent: "text-field",
              name: "subject",
              label: "Subject",
              width: "1/2",
              required: true,
              path: ["data", "info", "subject"],
              default: null
            },

            {
              formComponent: "select-field",
              name: "priority",
              label: "Priority",
              width: "1/2",
              required: true,
              path: ["data", "info", "priority"],
              options: createOptionsForSelect({
                possibleValues: () => PriorityValues,
                getOptionLabel: (x) => capitalize(x),
                getSelectedOption: (x, y) => x === y
              }),
              default: null
            },

            {
              formComponent: "select-field",
              name: "status",
              label: "Status",
              width: "1/3",
              path: ["data", "info", "status"],
              options: createOptionsForSelect({
                possibleValues: () => possibleValues,
                getOptionLabel: (x) => capitalize(x),
                getSelectedOption: (x, y) => x === y
              }),
              default: null
            },
            {
              formComponent: "text-field",
              multiline: true,
              name: "description",
              label: "Description",
              width: "1/3",
              required: true,
              path: ["data", "info", "description"],
              default: null
            },
            {
              formComponent: "one-to-many-field",
              component: (
                stateAccess: StateAccess,
                _mainstateAccess: StateAccess,
                renderSet: RenderSet
              ) => (
                <LenderTicketTypePreview
                  renderSet={renderSet}
                  type={stateAccess.get(["data", "type"])}
                  setType={(type) => {
                    stateAccess.set(["data", "type"], type);
                  }}
                />
              ),
              name: "lender_ticket_type",
              label: "lender_ticket_type",
              width: "1/3",
              path: [],
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: "1/2",
          name: "Select Deal",
          entities: [
            {
              formComponent: "one-to-many-field",
              component: (
                stateAccess: StateAccess,
                _mainstateAccess: StateAccess,
                renderSet: RenderSet
              ) => (
                <DealSearch
                  stateAccess={stateAccess}
                  renderSet={renderSet}
                  handleSelectDeal={(deal) => {
                    stateAccess.set(["data", "deal"], deal);
                  }}
                />
              ),
              name: "search",
              label: "search",
              width: "full",
              show: (stateAccess) => !stateAccess.get(["data", "deal", "_id"]),
              path: [],
              default: null
            },
            {
              formComponent: "segment",
              width: "full",
              show: (stateAccess) => stateAccess.get(["data", "deal", "_id"]),
              entities: [
                dealInfoSegment,
                {
                  formComponent: "one-to-many-field",
                  component: (
                    stateAccess: StateAccess,
                    _mainstateAccess: StateAccess,
                    renderSet: RenderSet
                  ) => {
                    return (
                      <Button
                        variant="contained"
                        onClick={() => stateAccess.set(["data", "deal"], null)}
                      >
                        Remove deal
                      </Button>
                    );
                  },
                  name: "removeDealButton",
                  label: "removeDealButton",
                  width: "full",
                  path: [],
                  default: null
                }
              ]
            }
          ]
        },

        {
          formComponent: "segment",
          name: "Contact Info",
          width: "1/2",
          entities: [
            {
              formComponent: "one-to-many-field",
              struct: () => lenderStruct,
              component: (
                stateAccess: StateAccess,
                _mainstateAccess: StateAccess,
                renderSet: RenderSet
              ) => (
                <LenderPreview
                  stateAccess={stateAccess}
                  renderSet={renderSet}
                  lenderIdPath={["data", "lenderId"]}
                  path={["data", "lender"]}
                  name="lender"
                  label="Lender"
                  type="lender_ticket"
                />
              ),
              name: "lender",
              label: "Lender",
              width: "1/2",
              path: ["data", "lender"],
              default: null
            },
            {
              formComponent: "multi-email-field",
              name: "Reply To",
              valueToString: (emails) => emails?.map((email: any) => email)?.join(", "),
              label: "Reply to",
              width: "1/2",
              required: true,
              path: ["data", "replyTo"],
              default: null
            }
          ]
        },
        assignee
      ]
    }
  ]
};
