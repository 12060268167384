import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteLenderTicketTypeRequest {
  _id: string;
}

interface DeleteLenderTicketTypeResponse {
  message: {
    _id: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteLenderTicketTypeRequest,
  DeleteLenderTicketTypeResponse
>("DeleteLenderTicketType", {
  request: "delete_lender_ticket_type"
});

export const deleteLenderTicketTypeActions = socketMessageSlice.actions;
export const deleteLenderTicketType = socketAction;
export default socketMessageSlice.reducer;
