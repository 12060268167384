import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { auth } from "../../firebase/firebase";
import { MixPanelQuery, requestBodyData } from "./types";

export const getMixPanelReports = async (request: requestBodyData): Promise<any> => {
  try {
    const token = await auth.currentUser?.getIdToken();
    const postResponse = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/mixpanel_reports`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-type": "application/json"
        }
      }
    );
    return postResponse.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const useGetMixPanelReports = (query: requestBodyData) => {
  const queryKey = [`mix-panel-reports-${query.fromDate}-${query.toDate}`];
  const { data, isLoading, isFetching, error, isSuccess, isFetched } = useQuery<
    MixPanelQuery,
    Error,
    MixPanelQuery
  >({
    queryKey: queryKey,
    queryFn: () => getMixPanelReports(query),
    enabled: query.fromDate && query.toDate ? true : false
  });
  return {
    data,
    isLoading,
    isFetching,
    error,
    isSuccess,
    isFetched
  };
};

export default useGetMixPanelReports;
