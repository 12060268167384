import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import info_properties from "./info_properties";
const data = {
  type: "object",
  required: ["info"],
  properties: {
    info: { type: "object", properties: info_properties },
    additionalProperties: true
  }
};

export default {
  new_dealer_note: {
    $id: "newDealerNote",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      }
    }
  },
  update_dealer_note: {
    $id: "updateDealerNote",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      }
    }
  },
  delete_dealer_note: {
    $id: "deleteDealerNote",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" }
    }
  },
  all_dealer_notes: {
    $id: "allDealerNotes",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties }
    ]
  }
};
