import { Dialog, DialogContent, DialogTitle, Grid, Paper } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import CloseDialogButton from "components/common/CloseDialogButton";
import { getCustomReportList } from "components/CustomReport/listCustomReportSlice";
import { deleteCustomReport } from "components/CustomReport/deleteCustomReportSlice";
import { getReportToken } from "./getReportTokenSlice";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomReportConfig from "./CustomReportConfig";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import IconButton from "@material-ui/core/IconButton";
import { Delete } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { HintTooltip } from "components/common/HintTooltip";
export default () => {
  const [open, setOpen] = useState(false);
  const [deleteButtonVisibility, setDeleteButtonVisibility] = useState(false);
  const [report, setReport] = useState({ baseUrl: "", chartId: "", title: "" });
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const customReportList = useSelector((state: RootState) => state.listCustomReportSlice["table"]);
  const token = useSelector((state: RootState) => state.getReportTokenSlice["reportToken"]);
  const [requestId] = useState(uuidv4());

  const deleteStatus = useSelector((state: RootState) => state.deleteCustomReportSlice[requestId]);

  useEffect(() => {
    switch (deleteStatus?.status) {
      case "success":
        dispatch(getCustomReportList("table"));
    }
  }, [deleteStatus, dispatch]);

  useEffect(() => {
    dispatch(getCustomReportList("table"));
    dispatch(getReportToken({ requestId: "reportToken" }));
  }, [dispatch]);

  const [, setContainer] = useState<HTMLDivElement | null>(null);

  const onRefChange = useCallback(
    (node) => {
      setContainer(node);
      if (node !== null) {
        try {
          const sdk = new ChartsEmbedSDK({
            baseUrl: report.baseUrl,
            chartId: report.chartId,
            getUserToken: async function () {
              if (token.status === "success") return token?.data?.message?.data?.info?.token;
            }
          });
          sdk.createChart({ chartId: report.chartId }).render(node);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [report, token]
  );

  return (
    <>
      <CustomReportConfig setDeleteButtonVisibility={setDeleteButtonVisibility} />
      <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
        <DialogTitle>
          <CloseDialogButton closeFunction={handleClose} />
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "grid", minHeight: "800px" }} ref={onRefChange} />
        </DialogContent>
      </Dialog>
      <Grid container spacing={3}>
        {customReportList?.entities?.map((report, index) => {
          return (
            <Grid item key={index}>
              <Paper
                onClick={() => {
                  setOpen(true);
                  setReport(report.data.info);
                }}
                style={{
                  padding: "20px",
                  cursor: "pointer",
                  background: "#254e70",
                  color: "white",
                  position: "relative"
                }}
              >
                {deleteButtonVisibility && (
                  <IconButton
                    onClick={(event: any) => {
                      if (window.confirm("You want to delete?")) {
                        dispatch(deleteCustomReport({ requestId: requestId, _id: report._id }));
                      }
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                    color="primary"
                    component="span"
                    id={`delete-${report.data.info.title}`}
                    style={{
                      position: "absolute",
                      top: "-20px",
                      right: "-20px",
                      color: "red",
                      background: "white"
                    }}
                  >
                    <HintTooltip title="Click here to delete the custom report.">
                      <Delete />
                    </HintTooltip>
                  </IconButton>
                )}
                {report.data.info.title}
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
