import React, { useState, useEffect } from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { Dialog, IconButton, Typography, Button, DialogActions } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { userPinStruct } from "components/Users/model";
import { generateForm, StateAccess, getByPath, setByPath } from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";
import { uuid } from "uuidv4";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useSnackbar } from "notistack";
import { changeUserPin } from "./changeUserPinSlice";
import { hideLoader, showLoader } from "components/Loader/loaderSlice";

interface Props {
  open: boolean;
  oldPIN?: string;
  setOpen: (x: boolean) => void;
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export default function UserPinDialog({ open, setOpen, oldPIN }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [requestId] = useState(uuid());
  const dispatch = useDispatch();
  const [pinState, setPinState] = useState<{
    oldPIN?: string;
    newPIN?: string;
    confirmPIN?: string;
  }>({});
  const userPinStateAccess: StateAccess = {
    get: (path) => getByPath(pinState as any, path),
    set: (path, value): any => {
      setPinState(setByPath(pinState as any, path, value));
    }
  };
  const changeUserPinState = useSelector((state: RootState) => state.changeUserPinSlice[requestId]);
  const handleDialogClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (changeUserPinState?.status === "success") {
      enqueueSnackbar(changeUserPinState?.data?.message, {
        variant: "success"
      });
      handleDialogClose();
      dispatch(hideLoader());
    }
    if (changeUserPinState?.status === "error") {
      enqueueSnackbar(changeUserPinState.message, {
        variant: "error"
      });
      dispatch(hideLoader());
    }
  }, [changeUserPinState?.status]);

  const handleChangePIN = () => {
    if (pinState?.newPIN && pinState?.confirmPIN && pinState?.newPIN === pinState?.confirmPIN) {
      dispatch(showLoader());
      dispatch(
        changeUserPin({
          requestId,
          data: {
            oldPIN: oldPIN ?? "",
            newPIN: btoa(pinState?.newPIN)
          }
        })
      );
    } else {
      enqueueSnackbar("The new pin values should match. Try again.", {
        variant: "error"
      });
    }
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        onClose={handleDialogClose}
        aria-labelledby="widget-dialog"
        open={open}
      >
        <DialogTitle id="widget-dialog" onClose={handleDialogClose}>
          Change User PIN
        </DialogTitle>
        <DialogContent>
          {generateForm(
            userPinStruct(oldPIN),
            userPinStateAccess,
            [],
            userPinStateAccess,
            editRenderSet(false),
            undefined
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={!pinState?.confirmPIN || !pinState.newPIN}
            onClick={handleChangePIN}
          >
            Save
          </Button>
          <Button variant="contained" color="primary" onClick={handleDialogClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
