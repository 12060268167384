import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { EditDealershipTicketRequest, DealershipTicket } from "./types";

interface EditDealershipTicketResponse {
  message: DealershipTicket;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditDealershipTicketRequest,
  EditDealershipTicketResponse
>("EditDealershipTicket", {
  request: "update_dealership_ticket"
});

export const editDealershipTicketActions = socketMessageSlice.actions;
export const editDealershipTicket = (request: DealershipTicket & Identifiable) => {
  const { dealershipTicketNotes, dealership, ...rest } = request.data;
  return socketAction({
    ...request,
    data: { ...rest }
  });
};
export default socketMessageSlice.reducer;
