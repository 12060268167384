import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { EditTitleIssuesRequest, EditTitleIssuesRequestSend, TitleIssue } from "./types";

interface EditTitleIssueResponse {
  message: TitleIssue;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditTitleIssuesRequestSend,
  EditTitleIssueResponse
>("EditTitleIssue", {
  request: "update_title_issue"
});

export const editTitleIssueActions = socketMessageSlice.actions;
export const editTitleIssue = (request: EditTitleIssuesRequest & Identifiable) => {
  const { deal, ...rest } = request.data;
  return socketAction({
    ...request,
    data: { ...rest, dealId: deal?._id ?? ((null as unknown) as string) }
  });
};
export default socketMessageSlice.reducer;
