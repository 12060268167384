import { Button, DialogActions, DialogContent } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Autorenew, MoreHoriz } from "@material-ui/icons";
import SaveIcon from "@material-ui/icons/Save";
import { RootState } from "app/rootReducer";
import { HintTooltip } from "components/common/HintTooltip";
import { XlStyledDialog } from "components/common/StyledDialog";
import { TabContext } from "components/Layout/LayoutWrapper";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { entityConfigs, EntityData, EntityType, getEntity, removeEntity } from "utils/entitySlice";
import { generateForm, getByPath, setByPath, StateAccess } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import { editRenderSet } from "../../utils/models/formRenderers";
import CloseDialogButton from "../common/CloseDialogButton";

export default ({
  entityName,
  _id,
  showModel,
  openShowEditInModal,
  renewMode
}: {
  entityName: EntityType;
  _id: string;
  showModel: undefined | string;
  openShowEditInModal?: boolean;
  renewMode?: boolean;
}) => {
  const dispatch = useDispatch();
  const requestId = uuidv4();
  const editFormName = `${entityName}-edit`;
  const createOrFocusTab = useContext(TabContext);

  const [open, setOpen] = useState(false);
  const initialState = useSelector((state: RootState) => state.entitySlice[entityName][_id]);
  const [entity, setEntity] = useState(initialState);

  const stateAccess: StateAccess = {
    get: (path) => getByPath(entity as any, path),
    set: (path, value): any => setEntity(setByPath(entity as any, path, value))
  };

  useEffect(() => {
    setEntity(initialState);
  }, [initialState]);

  useEffect(() => {
    if (openShowEditInModal) {
      dispatch(getEntity(entityName, _id));
      return () => {
        dispatch(removeEntity(entityName, _id));
      };
    }
  }, [dispatch, entityName, _id, openShowEditInModal]);

  const handleSubmit = (state: EntityData<typeof entityName>) => (event: React.FormEvent) => {
    event.preventDefault();

    const { createdAt, ...data } = state;
    const editFunction = entityConfigs[entityName].edit.do;
    dispatch(editFunction({ ...(data as any), requestId }));
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {open && (
        <XlStyledDialog>
          <DialogContent>
            <CloseDialogButton closeFunction={handleClose} />
            <form id={editFormName} autoComplete="off" onSubmit={handleSubmit(entity)}>
              {generateForm(
                entityConfigs[entityName].model as any,
                stateAccess,
                [],
                stateAccess,
                editRenderSet(entityConfigs[entityName].model?.schema)
              )}
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              color="primary"
              variant="contained"
            >
              Cancel
            </Button>

            <Button
              form={editFormName}
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </DialogActions>
        </XlStyledDialog>
      )}
      <IconButton
        size="small"
        color="primary"
        aria-label="show more"
        id={`showMore-${_id}`}
        style={{ marginRight: "10px" }}
        onClick={() =>
          openShowEditInModal
            ? setOpen(true)
            : createOrFocusTab({
                label: "Show page",
                index: "showPage",
                isForSidebar: false,
                isForQuickAccess: false,
                isPersistent: false,
                props: {
                  _id: _id,
                  type: entityName
                }
              })
        }
      >
        {renewMode ? (
          <HintTooltip title={`Click here to renew the ${entityName}.`}>
            <Autorenew />
          </HintTooltip>
        ) : (
          <HintTooltip title={`Click here to view/edit the ${entityName}.`}>
            <MoreHoriz />
          </HintTooltip>
        )}
      </IconButton>
    </>
  );
};
