import {
  multyPermissionNode,
  permissionNode,
  PermissionStruct,
  relationPermissionNode
} from "components/Roles/types";
import { Checklist } from "./types";

export type ChecklistPermissionsStruct = PermissionStruct<Checklist>;

export const checklistPermissionsStruct: ChecklistPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    dealId: permissionNode("Deal Id"),
    info: {
      eSign: permissionNode("eSign"),
      sendEmails: multyPermissionNode("Send Emails"),
      documents: multyPermissionNode("Documents"),
      optionalText: permissionNode("Text"),
      emailData: {
        recipients: multyPermissionNode("Recipients"),
        subject: permissionNode("Subject"),
        body: permissionNode("Body"),
        attachments: multyPermissionNode("Attachments")
      },
      eSignEmailData: {
        applicant: permissionNode("applicant email"),
        coApplicant: permissionNode("coapplicant email"),
        dealership: permissionNode("dealership email")
      },
      send: {
        email: permissionNode("Email"),
        eSign: permissionNode("eSign")
      },
      generatedPacks: multyPermissionNode("Generated Packs")
    }
  }
};
