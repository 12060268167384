import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { AddTitleIssueNoteRequest, TitleIssueNote } from "./types";

interface AddTitleIssueNoteResponse {
  message: TitleIssueNote;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddTitleIssueNoteRequest,
  AddTitleIssueNoteResponse
>("AddTitleIssueNoteResponse", {
  request: "new_title_issue_note"
});

export const addTitleIssueNoteActions = socketMessageSlice.actions;

export const addTitleIssueNote = socketAction;

export default socketMessageSlice.reducer;
