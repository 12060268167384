import React, { ReactNode } from "react";
import styles from "./IconButton.module.css";

interface IconButtonProps {
  children: ReactNode;
  className?: string;
  outerClassName?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  width?: number;
  height?: number;
  type?: "submit" | "button" | "reset";
  style?: React.CSSProperties;
  ref?: React.MutableRefObject<HTMLButtonElement | null>;
  flex?: boolean;
  disabled?: boolean;
  id?: string;
  dataTestId?: string;
}
const IconButton = ({
  id,
  children,
  dataTestId,
  onClick,
  type,
  style,
  disabled = false,
  className,
  outerClassName,
  ref,
  flex = false
}: IconButtonProps) => {
  return (
    <button
      id={id}
      data-testid={dataTestId}
      ref={ref}
      type={type}
      onClick={onClick}
      style={style}
      disabled={disabled}
      className={
        outerClassName
          ? outerClassName
          : className
          ? flex
            ? [styles[className], styles.flex].join(" ")
            : styles[className]
          : flex
          ? [styles.iconButton, styles.flex].join(" ")
          : styles.iconButton
      }
    >
      {children}
    </button>
  );
};
export default IconButton;
