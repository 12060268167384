import { permissionNode, PermissionStruct, relationPermissionNode } from "components/Roles/types";
import { TableSettings } from "./types";

export type TableSettingsPermissionsStruct = PermissionStruct<TableSettings>;

export const tableSettingsPermissionStruct: TableSettingsPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    userId: permissionNode("User Id"),
    tableName: permissionNode("Table Name"),
    columns: {}
  }
};
