export default {
  url: { type: "string", nullable: true },
  name: { type: "string", nullable: true },
  metadata: {
    type: "object",
    nullable: true,
    properties: {
      bucket: { type: "string", nullable: true },
      contentDisposition: { type: "string", nullable: true },
      contentEncoding: { type: "string", nullable: true },
      contentType: { type: "string", nullable: true },
      fullPath: { type: "string", nullable: true },
      generation: { type: "string", nullable: true },
      md5Hash: { type: "string", nullable: true },
      metageneration: { type: "string", nullable: true },
      name: { type: "string", nullable: true },
      size: { type: "number", nullable: true },
      timeCreated: { type: "string", nullable: true },
      type: { type: "string", nullable: true },
      updated: {
        type: "string",
        format: "date-time",
        errorMessage: { format: "must be a valid date" },
        nullable: true,
      },
    },
  },
  types: {
    type: "array",
    nullable: true,
    items: {
      type: "object",
      properties: {
        _id: { type: "string", nullable: true },
        data: {
          type: "object",
          nullable: true,
          properties: {
            info: {
              type: "object",
              nullable: true,
              properties: {
                name: { type: "string", nullable: true },
                dealTypeProperties: {
                  type: "object",
                  nullable: true,
                  properties: {
                    lendersIds: {
                      type: "array",
                      nullable: true,
                      items: { type: "string", nullable: true },
                    },
                  },
                },
              },
            },
            notes: {
              type: "array",
              items: {
                type: "object",
                required: ["note"],
                properties: { note: { type: "string" } },
              },
            },
          },
        },
        createdAt: {
          type: "string",
          format: "date-time",
          errorMessage: { format: "must be a valid date" },
          nullable: true,
        },
        updatedAt: {
          type: "string",
          format: "date-time",
          errorMessage: { format: "must be a valid date" },
          nullable: true,
        },
        deleted: { type: "boolean", nullable: true },
      },
    },
  },
  description: { type: "string", nullable: true },
  coordinates: {
    type: "array",
    nullable: true,
    items: {
      type: "object",
      properties: {
        _id: { type: "string", nullable: true },
        id: { type: "string", nullable: true },
        type: { type: "string", nullable: true },
        x: { type: "number", nullable: true },
        y: { type: "number", nullable: true },
        path: {
          type: "array",
          nullable: true,
          items: { type: "string", nullable: true },
        },
        format: { type: "string", nullable: true },
        tooltipLabel: {
          type: "array",
          nullable: true,
          items: { type: "string", nullable: true },
        },
        label: { type: "string", nullable: true },
      },
    },
  },
};
