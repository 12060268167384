import React, { MutableRefObject, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { IconButton } from "@material-ui/core";
import { Print } from "@material-ui/icons";
import { EntityType } from "utils/entitySlice";
import PrintAllDocuments from "components/FundingDocuments/PrintAllDocuments";
import { v4 as uuidv4 } from "uuid";
import { CrudEntity } from "utils/types";
import { Normalized, ListState } from "components/Middlewares/listSliceCreator";
import { HintTooltip } from "components/common/HintTooltip";

type Entry<T> = CrudEntity & {
  data: T;
} & Normalized;

export default <T extends unknown>({
  tableRef: ref,
  entityName,
  listEntity
}: {
  tableRef: MutableRefObject<null>;
  entityName: EntityType;
  listEntity: ListState<Entry<T>>;
}): JSX.Element => {
  const [filePreview, setFilePreview] = useState(<></>);

  const handlePrint = useReactToPrint({
    content: () => ref.current
  });

  const mergeAndPrintDocuments = () => {
    setFilePreview(<PrintAllDocuments key={uuidv4()} listEntity={listEntity} />);
  };

  return (
    <>
      <div>{filePreview}</div>
      <HintTooltip title="Print">
        <IconButton
          onClick={entityName === "funding_document" ? mergeAndPrintDocuments : handlePrint}
          aria-label={`Print`}
        >
          <Print />
        </IconButton>
      </HintTooltip>
    </>
  );
};
