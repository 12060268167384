import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FundingDocument } from "./types";

interface RecoverFundingDocumentRequest {
  _id: string;
}

export interface RecoverFundingDocumentResponse {
  message: FundingDocument;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverFundingDocumentRequest,
  RecoverFundingDocumentResponse
>("RecoverFundingDocument", {
  request: "recover_funding_document"
});

export const recoverFundingDocumentActions = socketMessageSlice.actions;
export const recoverFundingDocument = socketAction;
export default socketMessageSlice.reducer;
