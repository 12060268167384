import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

type AddChargebackRequest = { data: FormState };

export interface AddChargebackResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddChargebackRequest,
  AddChargebackResponse
>("AddChargeback", {
  request: "new_chargeback"
});

export const addChargebackActions = socketMessageSlice.actions;
export const addChargeback = (request: AddChargebackRequest & Identifiable) => {
  const { dealership, lender, manager, ...rest } = request.data;
  return socketAction({
    ...request,
    data: {
      ...rest,
      dealershipId: dealership?._id ?? null,
      lenderId: lender?._id ?? null,
      managerId: manager?._id ?? null
    }
  });
};
export default socketMessageSlice.reducer;
