import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

interface DeleteBussinesReportRequest {
  _id: string;
}

interface DeleteBussinesReportResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteBussinesReportRequest,
  DeleteBussinesReportResponse
>("DeleteBussinesReport", {
  request: "delete_business_report"
});

export const deleteBussinesReportActions = socketMessageSlice.actions;
export const deleteBussinesReport = socketAction;
export default socketMessageSlice.reducer;
