import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Menu,
  MenuItem
} from "@material-ui/core";
import { RootState } from "app/rootReducer";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { EntityDataTable, EntityType } from "utils/entitySlice";
import { StateAccess } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import CloseDialogButton from "../common/CloseDialogButton";
import modalContainerContext from "../Content/modalContainer";
import { History } from "./History";
import { getHistoriesList, removeHistoriesList } from "./listHistoriesSlice";

export const HistoryList = <T extends EntityType>({
  collection,
  _id,
  anchorElement,
  hideList,
  stateAccess
}: {
  collection: EntityDataTable<T>;
  _id: string;
  anchorElement: HTMLButtonElement;
  hideList: () => void;
  stateAccess: StateAccess;
}) => {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const [open, setOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState<any | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const historiesList = useSelector((state: RootState) => state.listHistoriesSlice[requestId]);

  useEffect(() => {
    dispatch(getHistoriesList(collection, _id)(requestId));
    return () => {
      dispatch(removeHistoriesList(requestId));
      return;
    };
  }, [dispatch, requestId, collection, _id]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const modalContainer = useContext(modalContainerContext);

  const handleChange = (x: any) => () => {
    setSelectedEntity(x);
    handleClickOpen();
  };

  useEffect(() => {
    if (historiesList !== undefined) {
      setAnchorEl(anchorElement);
    }
  }, [anchorElement, historiesList, setAnchorEl]);
  return (
    <FormControl>
      {historiesList !== undefined ? (
        (historiesList?.entities?.length || 0) === 0 ? null : (
          <>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={hideList}
            >
              {historiesList.entities?.map((x, index) => (
                <MenuItem key={index} onClick={handleChange(x)}>
                  {new Date(x.createdAt).toLocaleString()} - {x.data.modifiedBy}
                </MenuItem>
              ))}
            </Menu>
            {open && selectedEntity !== null
              ? ReactDOM.createPortal(
                  <Dialog
                    fullWidth
                    maxWidth="lg"
                    onClose={handleClose}
                    open={open}
                    style={{ minHeight: "50vh" }}
                  >
                    <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
                      <Grid item xs={6} key="title">
                        <CloseDialogButton closeFunction={handleClose} />
                        <DialogTitle>Change made by {selectedEntity.data.modifiedBy}</DialogTitle>
                      </Grid>
                    </Grid>
                    <DialogContent>
                      <History
                        _id={selectedEntity._id}
                        collection={collection}
                        stateAccess={stateAccess}
                        setOpen={setOpen}
                      />
                    </DialogContent>
                  </Dialog>,
                  modalContainer
                )
              : null}
          </>
        )
      ) : (
        "Loading"
      )}
    </FormControl>
  );
};
