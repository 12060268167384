import { createListSlice } from "../Middlewares/listSliceCreator";
import { File } from "./types";

const { ListSlice, getList, getListAction } = createListSlice<File>({
  name: "FileList",
  request_topic: "all_files",
  reducers: {}
});

export default ListSlice.reducer;
export const getFileList = getList;
export const listFileActions = ListSlice.actions;
export const getFileListActions = getListAction;
