import { UnparsedRequiredDocument } from "./types";
import { permissionNode, relationPermissionNode, PermissionStruct } from "components/Roles/types";

export type UnparsedRequiredDocumentPermissionsStruct = PermissionStruct<UnparsedRequiredDocument>;

export const unparsedRequiredDocumentPermissionsStruct: UnparsedRequiredDocumentPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    printedDocument: relationPermissionNode("Printed Document", "printed_document"),
    printedDocumentId: permissionNode("Printed Document Id"),
    page: permissionNode("Page"),
    userId: permissionNode("User Id"),
    fundingDocumentId: permissionNode("Funding Document Id")
  }
};
