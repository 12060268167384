import React from "react";

import LogoImage from "../../images/wfd.png";
import UpdateLogo from "../../images/wfd_updated_logo.svg";

const styles = {
  width: "60px",
  marginTop: "9px"
};
export const LogoWithText = () => {
  return <img style={{ marginTop: "60px" }} src={UpdateLogo} alt="Web Finance Direct" />;
};
export default function Logo() {
  return <img style={styles} src={LogoImage} alt="Web Finance Direct" />;
}
