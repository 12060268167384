import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

import { GenerateContractForPrint, GeneratedContractForPrintURL } from "./types";
export interface GenerateContractForPrintResponse {
  data: { generatedPack: GeneratedContractForPrintURL };
}

const { socketMessageSlice, socketAction, createAction } = createSocketMessageSlice<
  GenerateContractForPrint,
  GenerateContractForPrintResponse
>("GenerateContractForPrint", {
  request: "new_generate_contract_for_print"
});
export const generateContractForPrint = socketAction;
export const generateContractForPrintAction = socketMessageSlice.actions;
export const generateContractForPrintActions = createAction;
export default socketMessageSlice.reducer;
