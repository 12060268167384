import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { Help, Warning } from "@material-ui/icons";
import Big from "big.js";
import { AllocationPeriod, LifetimeAllocationPeriod } from "components/Lenders/types";
import ProgressBar from "components/ProgressBar/ProgressBar";
import {
  LifetimeAllocationStatistics,
  OverviewStatistics
} from "hooks/useLenderAllocationStatistics/types";
import React from "react";
import formatDate from "utils/formatDate";
import {
  calculateMonthsBetweenByPeriod,
  formatNumberAsCurrency,
  parseMonth
} from "utils/functions";
import styles from "../../LenderDashboard.module.css";

const hasRunoffForCurrentMonth = (period: LifetimeAllocationPeriod | undefined) => {
  if (Array.isArray(period?.runoff) && period?.runoff?.length) {
    const today = new Date();
    return (
      period.runoff.findIndex(
        (runoff) => runoff.month === today.getMonth() + 1 && runoff.year === today.getFullYear()
      ) !== -1
    );
  }
  return false;
};

const LifetimeAllocationInformation = ({
  data,
  overviewStatistics
}: {
  overviewStatistics: OverviewStatistics[];
  data: LifetimeAllocationStatistics | undefined;
}) => {
  const [detailedAllocationCollapse, setDetailedAllocationCollapse] = React.useState(false);
  const percentAllocatedWithoutApproved = data?.period?.allocation
    ? new Big(data?.totalAmountFinanced || 0)
        .sub(new Big(data?.totalAmountFinancedApproved || 0))
        .div(new Big(data?.period?.allocation || 1))
        .mul(new Big(100))
        .toNumber()
    : 0;
  const percentageAllocated = data?.period?.allocation
    ? new Big(data?.totalAmountFinancedApproved || 0)
        .div(new Big(data?.period?.allocation || 1))
        .mul(new Big(100))
        .toNumber()
    : 0;
  const usedAmount = new Big(data?.seed?.amount || 0)
    .sub(new Big(data?.totalRunoff || 0))
    .toNumber();
  const percentageUsedAmount = data?.period?.allocation
    ? new Big(usedAmount)
        .div(new Big(data?.period?.allocation || 1))
        .mul(new Big(100))
        .toNumber()
    : 0;
  return (
    <Paper
      style={{
        padding: "20px 20px"
      }}
      className={styles.dashboardItem}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom: 30,
          gap: "10px"
        }}
      >
        <Typography
          style={{
            fontWeight: 500,
            fontSize: 22,
            lineHeight: "20px",
            marginBottom: 10,
            textAlign: "center"
          }}
        >
          Lifetime allocation information {data?.period?.suggested && "(suggested)"}
        </Typography>
        <Typography
          style={{
            fontWeight: 500,
            fontSize: 20,
            lineHeight: "20px",
            marginBottom: 10,
            color: "orange",
            textAlign: "center"
          }}
        >
          {(data?.period?.allocation || 0) * ((data?.period?.softAllocation || 0) / 100) <=
            new Big(data?.totalAmountFinanced || 0).add(new Big(usedAmount || 0)).toNumber() &&
            "Soft allocation is reached!"}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <Typography
            style={{
              fontWeight: 500,
              fontSize: 19,
              lineHeight: "20px",
              color: "#6A6A6A"
            }}
          >
            Allocation
          </Typography>

          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
            {formatNumberAsCurrency(data?.period?.allocation || 0, "$")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 0
          }}
        >
          <Typography
            style={{
              fontWeight: 500,
              fontSize: 19,
              lineHeight: "20px",
              color: "#6A6A6A"
            }}
          >
            Allocated Amount
          </Typography>
          <Typography
            style={{
              fontWeight: 500,
              fontSize: 22,
              lineHeight: "23px"
            }}
          >
            {formatNumberAsCurrency(
              new Big(data?.totalAmountFinancedNoApproved || 0)
                .add(new Big(usedAmount || 0))
                .toNumber(),
              "$"
            )}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 30
          }}
        >
          <Typography
            style={{
              fontWeight: 500,
              fontSize: 19,
              lineHeight: "20px",
              color: "#6A6A6A"
            }}
          >
            Allocated Amount With Approved
          </Typography>
          <Typography
            style={{
              fontWeight: 500,
              fontSize: 22,
              lineHeight: "23px"
            }}
          >
            {formatNumberAsCurrency(
              new Big(data?.totalAmountFinanced || 0).add(new Big(usedAmount || 0)).toNumber(),
              "$"
            )}
          </Typography>
        </div>
        {!hasRunoffForCurrentMonth(data?.period) && (
          <div style={{ marginBottom: "10px", display: "flex", alignItems: "center", gap: "10px" }}>
            <Warning style={{ color: "#eed202" }} />

            <Typography
              style={{
                fontWeight: 500,
                fontSize: 19,
                fontStyle: "italic",
                lineHeight: "20px",
                color: "#eed202"
              }}
            >
              There is no runoff for current month!
            </Typography>
          </div>
        )}

        <div style={{ marginBottom: "10px" }}>
          <ProgressBar
            colors={["#51bde3", "#f0d855"]}
            percents={[percentAllocatedWithoutApproved + percentageUsedAmount, percentageAllocated]}
          />
        </div>
        <Grid container item xs={12} spacing={2}>
          <Grid item container xs={12} alignItems="center">
            <Grid item container xs={9} alignItems="center">
              <Grid item xs={1}>
                <div
                  style={{
                    width: "75%",
                    height: "20px",
                    background: "#51BDE3"
                  }}
                ></div>
              </Grid>
              <Grid item xs={11}>
                <Typography style={{ marginLeft: "10px" }}>
                  Allocated Amount
                  <IconButton
                    size="small"
                    onClick={(e) => setDetailedAllocationCollapse(!detailedAllocationCollapse)}
                  >
                    <Help fontSize="medium" />
                  </IconButton>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={3} style={{ fontWeight: "bold" }}>
              {formatNumberAsCurrency(
                new Big(data?.totalAmountFinancedNoApproved || 0)
                  .add(new Big(usedAmount || 0))
                  .toNumber(),
                "$"
              )}
            </Grid>
          </Grid>
          {detailedAllocationCollapse && (
            <React.Fragment>
              <Grid item container xs={12} alignItems="center">
                <Grid item container xs={9} alignItems="center">
                  <Grid item xs={2}>
                    <div
                      style={{
                        width: "75%"
                        // height: "20px",
                        // background: "#F0D855"
                      }}
                    ></div>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography style={{ marginLeft: "10px" }}>
                      Seed ({formatDate(data?.seed?.date, "short")})
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ fontWeight: "bold" }}>
                  {formatNumberAsCurrency(data?.seed?.amount || 0, "$")}
                </Grid>
              </Grid>
              <Grid item container xs={12} alignItems="center">
                <Grid item container xs={9} alignItems="center">
                  <Grid item xs={2}>
                    <div
                      style={{
                        width: "75%"
                      }}
                    ></div>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography style={{ marginLeft: "10px" }}>Runoff</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ fontWeight: "bold" }}>
                  ({formatNumberAsCurrency(data?.totalRunoff || 0, "$")})
                </Grid>
              </Grid>
              <Grid item container xs={12} alignItems="center">
                <Grid item container xs={9} alignItems="center">
                  <Grid item xs={2}>
                    <div
                      style={{
                        width: "75%"
                      }}
                    ></div>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography style={{ marginLeft: "10px", fontWeight: "bold" }}>
                      Sent to dealer, submitted for funding, funding held, funded
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ fontWeight: "bold" }}>
                  {formatNumberAsCurrency(data?.totalAmountFinancedNoApproved || 0, "$")}
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          <Grid item container xs={12} alignItems="center">
            <Grid item container xs={9} alignItems="center">
              <Grid item xs={1}>
                <div
                  style={{
                    width: "75%",
                    height: "20px",
                    background: "#F0D855"
                  }}
                ></div>
              </Grid>
              <Grid item xs={11}>
                <Typography style={{ marginLeft: "10px" }}>Aproved Deals Amount</Typography>
              </Grid>
            </Grid>
            <Grid item xs={3} style={{ fontWeight: "bold" }}>
              {formatNumberAsCurrency(data?.totalAmountFinancedApproved, "$")}
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems="center">
            <Grid item container xs={9} alignItems="center">
              <Grid item xs={1}>
                <div
                  style={{
                    width: "75%",
                    height: "20px",
                    background: "#52a543"
                  }}
                ></div>
              </Grid>
              <Grid item xs={11}>
                <Typography
                  style={{
                    marginLeft: "10px",
                    fontWeight: "bold",
                    fontSize: "20px"
                  }}
                >{`${
                  new Big(data?.period?.allocation || 0)
                    .sub(new Big(data?.totalAmountFinanced || 0))
                    .toNumber() < 0
                    ? "Overallocated"
                    : "Unallocated"
                }`}</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                color:
                  new Big(data?.period?.allocation || 0)
                    .sub(new Big(data?.totalAmountFinancedNoApproved || 0))
                    .toNumber() < 0
                    ? "red"
                    : "#000"
              }}
            >
              {formatNumberAsCurrency(data?.unallocated || 0, "$")}
            </Grid>
          </Grid>
        </Grid>
        <Typography
          style={{
            fontWeight: 500,
            fontSize: 22,
            lineHeight: "20px",
            marginBottom: 10,
            textAlign: "center"
          }}
        >
          Average funded per period
        </Typography>
        {overviewStatistics?.map((overview, index) => (
          <Grid key={index} item container xs={12} alignItems="center">
            <Grid item container xs={9} alignItems="center">
              <Grid item xs={12}>
                <Typography>
                  Period:{" "}
                  {`${parseMonth(overview?.funded?.period?.from?.month)}/${
                    overview?.funded?.period?.from?.year
                  } - ${parseMonth(overview?.funded?.period?.to?.month)}/${
                    overview?.funded?.period?.to?.year
                  }`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={3} style={{ fontWeight: "bold" }}>
              {formatNumberAsCurrency(
                new Big(overview?.funded?.amount || 0)
                  .div(
                    overview?.funded?.period?.from && overview?.funded?.period?.to
                      ? calculateMonthsBetweenByPeriod(overview?.funded?.period) || 1
                      : 1
                  )
                  .toNumber(),
                "$"
              )}
            </Grid>
          </Grid>
        ))}
      </div>
    </Paper>
  );
};

export default LifetimeAllocationInformation;
