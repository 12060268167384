import { Button, Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import CloseDialogButton from "components/common/CloseDialogButton";
import Deals, {
  getStyleByStatus,
  statusToChipTooltip,
  statusToLabel
} from "components/Deals/Deals";
import { DealData, DealStatus } from "components/Deals/types";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DateFilter from "components/Filters/DateFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import PriceFilter from "components/Filters/PriceFilter";
import TextFilter from "components/Filters/TextFilter";
import UserFilter from "components/Filters/UserFilter";
import { Lender } from "components/Lenders/types";
import Table, { CellValue, Column, Entry } from "components/Table";
import { deleteTitleIssue } from "components/TitleIssues/deleteTitleIssueSlice";
import { dmvStatusToChip, titleStatusToChip } from "components/TitleIssues/DocumentsSection";
import { getTitleIssueList } from "components/TitleIssues/listTitleIssueSlice";
import { formatDmvStatus } from "components/TitleIssues/Overview";
import { recoverTitleIssue } from "components/TitleIssues/recoverTitleIssueSlice";
import TitleIssues from "components/TitleIssues/TitleIssues";
import { DmvStatus, FormState, TitleIssueStatus } from "components/TitleIssues/types";
import { LenderAllocationStatisticsResponse } from "hooks/useLenderAllocationStatistics/types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import States from "us-states";
import { sumNumbers } from "utils/calcReportTotal";
import { capitalize, formatNumberAsCurrency, parseMonth } from "utils/functions";
import DealsModal from "../Lender/DealsModal";

const columns: Column<FormState>[] = [
  {
    getData: (entry): CellValue => entry?.data?.deal?.data?.info?.refNumber,
    label: "Deal ref.",
    name: "dealRef",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealRef?.show;
    },
    filters: [
      {
        path: ["data", "deal", "data", "info", "refNumber"],
        preview: TextFilter
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      [
        entry?.data?.deal?.data?.applicant?.data?.info?.firstName,
        entry?.data?.deal?.data?.applicant?.data?.info?.lastName
      ]
        ?.filter((x) => x)
        .join(" "),
    label: "Applicant",
    name: "applicant",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicant?.show;
    },
    filters: [
      {
        path: [
          ["data", "deal", "data", "applicant", "data", "info", "firstName"],
          ["data", "deal", "data", "applicant", "data", "info", "lastName"]
        ],
        preview: ApplicantFilter,
        caseInsensitive: true,
        partialSearch: true,
        name: "customer",
        label: "Customer"
      }
    ]
  },
  {
    label: "State",
    getData: (entry): CellValue =>
      entry?.data?.deal?.data?.applicant?.data?.info?.currentState ?? "",
    options: {
      sort: true,
      path: ["data", "deal", "data", "applicant", "data", "info", "currentState"]
    },
    name: "state",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.state?.show;
    },
    filters: [
      {
        path: ["data", "deal", "data", "applicant", "data", "info", "currentState"],
        preview: MultiSelectFilter,
        valuesForSelect: Object.keys(States).sort()
      }
    ]
  },
  {
    getData: (entry): CellValue => entry?.data?.deal?.data?.info?.vehicle?.VIN,
    label: "VIN",
    name: "vin",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.vin?.show;
    },
    filters: [
      {
        path: ["data", "deal", "data", "info", "vehicle", "VIN"],
        preview: TextFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry?.data?.info?.dmvCheckedCount,
    label: "DMV Checked count",
    name: "dmvCheckedCount",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dmvCheckedCount?.show;
    },
    filters: [
      {
        path: ["data", "info", "dmvCheckedCount"],
        preview: PriceFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.dmvInfo?.status,
    label: "DMV Status",
    options: {
      customBodyRender: (x: CellValue, entry): JSX.Element | string => {
        if (
          ["Minnesota", "North Dakota", "South Dakota", "Illinois"].includes(
            entry.data.deal?.data.applicant?.data?.info?.currentState ?? ""
          ) &&
          entry.data.deal?.data.info.type === "Automotive"
        ) {
          return dmvStatusToChip(x as DmvStatus);
        } else return dmvStatusToChip("not_supported");
      },
      sort: true,
      path: ["data", "info", "dmvInfo", "status"]
    },
    name: "dmvStatus",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dmvStatus?.show;
    },
    filters: [
      {
        path: ["data", "info", "dmvInfo", "status"],
        preview: MultiSelectFilter,
        valuesForSelect: ["titled", "untitled", "old_title", "error"],
        optionLabelForSelect: (status) => formatDmvStatus(status)
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.titleRegistrationInfo?.status,
    label: "New Title Document Status",
    options: {
      customBodyRender: (x: CellValue): JSX.Element | string => {
        return titleStatusToChip(x as TitleIssueStatus);
      },
      sort: true,
      path: ["data", "info", "titleRegistrationInfo", "status"]
    },
    name: "titleRegistrationInfoStatus",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.titleRegistrationInfoStatus?.show;
    },
    filters: [
      {
        path: ["data", "info", "titleRegistrationInfo", "status"],
        preview: MultiSelectFilter,
        valuesForSelect: ["awaiting", "pending", "resolved", "rejected"],
        optionLabelForSelect: (status) => capitalize(status)
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.oldTitleRegistrationInfo?.status,
    label: "Original Title Document Status",
    options: {
      customBodyRender: (x: CellValue): JSX.Element | string => {
        return titleStatusToChip(x as TitleIssueStatus);
      },
      sort: true,
      path: ["data", "info", "oldTitleRegistrationInfo", "status"]
    },
    name: "oldTitleRegistrationInfoStatus",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.oldTitleRegistrationInfoStatus?.show;
    },
    filters: [
      {
        path: ["data", "info", "oldTitleRegistrationInfo", "status"],
        preview: MultiSelectFilter,
        valuesForSelect: ["awaiting", "pending", "resolved", "rejected"],
        optionLabelForSelect: (status) => capitalize(status)
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.receiptInfo?.status,
    label: "Receipt Status",
    options: {
      customBodyRender: (x: CellValue): JSX.Element | string => {
        return titleStatusToChip(x as TitleIssueStatus);
      },
      sort: true,
      path: ["data", "info", "receiptInfo", "status"]
    },
    name: "receiptStatus",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.receiptStatus?.show;
    },
    filters: [
      {
        path: ["data", "info", "receiptInfo", "status"],
        preview: MultiSelectFilter,
        valuesForSelect: ["awaiting", "pending", "resolved", "rejected"],
        optionLabelForSelect: (status) => capitalize(status)
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.deal?.data?.info?.dealDates?.contractDate
        ? new Date(entry.data?.deal?.data?.info?.dealDates?.contractDate)
        : "",
    label: "Contract",
    options: {
      sort: true,
      path: ["data", "deal", "data", "info", "dealDates", "contractDate"]
    },
    name: "contractDate",
    show: (_userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.contractDate?.show;
    },
    filters: [
      {
        path: ["data", "deal", "data", "info", "dealDates", "contractDate"],
        preview: DateFilter,
        label: "Contract Date"
      }
    ]
  },
  {
    getData: (entry): CellValue => (entry.createdAt ? new Date(entry.createdAt) : ""),
    label: "Created At",
    options: {
      sort: true,
      path: ["createdAt"]
    },
    name: "createdAt",
    show: (userPermissions, tableSettings) => {
      return true;
    },
    filters: [
      {
        path: ["createdAt"],
        preview: DateFilter,
        label: "Created At"
      }
    ]
  },
  {
    label: "Actions",
    getData: (entry): CellValue => entry._id,
    name: "actions",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.actions?.show;
    }
  }
];
export default ({ titleIssuesIds }: { titleIssuesIds: string[] | undefined }) => {
  const listId = "limitedTitleIssues";
  const titleIssuesList = useSelector((state: RootState) => state.listTitleIssueSlice[listId]);
  const [showDealsModal, setShowDealsModal] = useState(false);
  const closeFunction = () => setShowDealsModal(false);
  return (
    <>
      <Grid item md={12} xs={12} style={{ marginTop: 5 }}>
        <Button
          color="primary"
          style={{ width: "100%" }}
          variant="contained"
          onClick={() => setShowDealsModal(true)}
        >
          title issues ({titleIssuesIds?.length})
        </Button>
      </Grid>
      {showDealsModal ? (
        <Dialog
          id="lender-statistics-deals-dialog"
          onClose={closeFunction}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle id="customized-dialog-title">Titles</DialogTitle>
          <CloseDialogButton closeFunction={closeFunction} />
          <DialogContent>
            <AccessControl requiredPermissions={{ entity: "title_issue", action: "read" }}>
              <Table
                tableName="title_issues"
                entityName="title_issue"
                slice={listId}
                listFunction={getTitleIssueList}
                deleteEntityFunction={deleteTitleIssue}
                recoverEntityFunction={recoverTitleIssue}
                sort={{ createdAt: "desc" }}
                listEntity={titleIssuesList}
                aggregateFirst={true}
                query={{ _id: { $in: titleIssuesIds } }}
                title={"Title Information"}
                columns={columns}
                hideAddButton
              />
            </AccessControl>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
};
