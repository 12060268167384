import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "app/store";
import { Permissions } from "components/Roles/types";
import { User as DatabaseUser } from "components/Users/types";
import firebase from "firebase/compat/app";
import { VariantType } from "notistack";
import { uuid } from "uuidv4";
import { auth } from "../../firebase/firebase";
import { Authentication } from "./types";

const recaptchaVerifier = new firebase.auth.RecaptchaVerifier("captcha-container", {
  size: "invisible",
  callback: function (response: any) {}
});
const initialState: Authentication = {
  id: "",
  isSigningIn: false,
  isSigningOut: false,
  isVerifying: false,
  signInError: false,
  signOutError: false,
  isAuthenticated: false,
  errorMessage: undefined,
  user: undefined
};

interface InitialUserPayload {
  email: string;
  token: string;
}

interface ReceiveInvalidUserPayload {
  user: firebase.User;
}

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    requestSignIn(state) {
      return {
        ...state,
        isSigningIn: true,
        signInError: false
      };
    },
    requestSignInCode(
      state,
      { payload }: PayloadAction<{ resolver: any; verificationId: string }>
    ) {
      return {
        ...state,
        data: payload,
        isSigningIn: true,
        signInError: true
      };
    },

    requestSignOut(state) {
      return {
        ...state,
        isSigningOut: true,
        signOutError: false
      };
    },
    signOutError(state, { payload }: PayloadAction<string>) {
      return {
        ...state,
        isSigningOut: false,
        signOutError: true,
        errorMessage: payload
      };
    },
    receiveSignOut(state) {
      return {
        ...state,
        isSigningOut: false,
        isAuthenticated: false,
        user: undefined
      };
    },
    verifyRequest(state) {
      return {
        ...state,
        isVerifying: true,
        verifyingError: false
      };
    },
    verifySuccess(state) {
      return {
        ...state,
        isVerifying: false
      };
    },
    verifyError(state) {
      return {
        ...state,
        isVerifying: false,
        isAuthenticated: false
      };
    },
    receiveSignIn(state, action: PayloadAction<InitialUserPayload>) {
      return {
        id: uuid(),
        isSigningIn: false,
        isSigningOut: false,
        isVerifying: false,
        signInError: false,
        signOutError: false,
        isAuthenticated: true,
        errorMessage: undefined,
        user: action.payload
      };
    },
    setPermissions(state, action: PayloadAction<{ permissions: Permissions; user: DatabaseUser }>) {
      if (state.user !== undefined) {
        return {
          ...state,
          user: {
            ...state.user,
            permissions: action.payload.permissions,
            databaseData: action.payload.user
          }
        };
      } else {
        return state;
      }
    },
    signInError(state, action: PayloadAction<{ errorMessage: string; errorCode?: string }>) {
      return {
        ...state,
        isSigningIn: false,
        isAuthenticated: false,
        signInError: true,
        signInErrorCode: action.payload.errorCode,
        errorMessage: action.payload.errorMessage
      };
    },
    receiveInvalidUser(state, { payload: { user } }: PayloadAction<ReceiveInvalidUserPayload>) {
      return {
        ...state,
        errorMessage: `Unable to log in, user has empty e-mail address: ${JSON.stringify(user)}`,
        isAuthenticated: false,
        isSigningIn: false,
        isVerifying: false,
        isSigningOut: false
      };
    }
  }
});

export const signInUser = (email: string, password: string) => async (dispatch: AppDispatch) => {
  dispatch(authSlice.actions.requestSignIn());
  auth
    .signInWithEmailAndPassword(email, password)
    .then((user: any) => {
      // if (!user.user.emailVerified) {
      //   dispatch(
      //     authSlice.actions.signInError({
      //       errorMessage: `Email is not verified. Please check your email for verification link.`
      //     })
      //   );
      // }
      user.user.getIdToken().then(function (token: string) {
        dispatch(
          authSlice.actions.receiveSignIn({
            token: token,
            email: user.user.email
          })
        );
      });
    })
    .catch((error) => {
      if (error.code == "auth/multi-factor-auth-required") {
        dispatch(
          authSlice.actions.signInError({ errorMessage: error.message, errorCode: error.code })
        );
        const resolver = error.resolver;
        // Ask user which second factor to use.
        const selectedIndex = 0;
        if (
          resolver.hints[selectedIndex].factorId ===
          firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
        ) {
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[selectedIndex],
            session: resolver.session
          };
          const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
          // Send SMS verification code
          return phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(function (verificationId) {
              dispatch(authSlice.actions.requestSignInCode({ resolver, verificationId }));
            });
        }
      } else {
        dispatch(
          authSlice.actions.signInError({ errorMessage: error.message, errorCode: error.code })
        );
      }
    });
};

export const signInWithPhoneCode = (
  resolver: any,
  verificationId: string,
  verificationCode: string
) => async (dispatch: AppDispatch) => {
  // Ask user for the SMS verification code.
  const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
  const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
  // Complete sign-in.

  return resolver
    .resolveSignIn(multiFactorAssertion)
    .then(function (userCredential: any) {
      // User successfully signed in with the second factor phone number.
      userCredential.user.getIdToken().then(function (token: string) {
        dispatch(
          authSlice.actions.receiveSignIn({
            token: token,
            email: userCredential.user.email
          })
        );
      });
    })
    .catch((error: any) => {
      dispatch(
        authSlice.actions.signInError({ errorMessage: error.message, errorCode: error.code })
      );
    });
};

export const signOutUser = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(authSlice.actions.requestSignOut());
  auth
    .signOut()
    .then(() => {
      dispatch(authSlice.actions.receiveSignOut());
    })
    .catch((error) => {
      dispatch(authSlice.actions.signOutError(error));
    });
};

export const verifyAuth = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(authSlice.actions.verifyRequest());
  auth.onAuthStateChanged((user: firebase.User | null) => {
    if (
      user !== null
      // nNcomment if we want to force two factor auth
      // && user.emailVerified
    ) {
      // TODO: make the user conversion a function
      user.getIdToken().then(function (token: string) {
        if (user.email !== null) {
          dispatch(authSlice.actions.receiveSignIn({ email: user.email, token }));
        } else {
          dispatch(authSlice.actions.receiveInvalidUser({ user }));
        }
      });
    } else {
      dispatch(authSlice.actions.verifyError());
    }
  });
};

export const enrollUserMFA = async (phoneNumber: string) => {
  const user = auth.currentUser;
  if (user) {
    const multiFactorSession = await user.multiFactor.getSession();
    const phoneInfoOptions = {
      phoneNumber: phoneNumber,
      session: multiFactorSession
    };

    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

    const res = await phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then((verificationId) => ({
        verificationId,
        error: null
      }))
      .catch((error) => ({
        verificationId: null,
        error: error.message
      }));

    return res;
  } else
    return {
      verificationId: null,
      error: "It seems like you found a bug! Please contact the support team for help!"
    };
};

export const verficicateMFACode = async (
  verificationId: string,
  verificationCode: string
): Promise<{
  status: VariantType;
  message: string;
}> => {
  const user = auth.currentUser;
  if (user) {
    const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    // Complete enrollment.
    const res = await user.multiFactor
      .enroll(multiFactorAssertion)
      .then(() => ({
        status: "success" as VariantType,
        message: "Successfully enabled TFA."
      }))
      .catch((e) => ({ status: "error" as VariantType, message: e.message as string }));

    return res;
  }
  return {
    status: "error",
    message: "It seems like you found a bug! Please contact the support team for help!"
  };
};
export default authSlice.reducer;

export const { receiveSignIn, setPermissions, receiveSignOut } = authSlice.actions;
