import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteValidationRequest {
  _id: string;
}

interface DeleteValidationResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteValidationRequest,
  DeleteValidationResponse
>("DeleteValidation", {
  request: "delete_document_validation"
});

export const deleteValidationActions = socketMessageSlice.actions;
export const deleteValidation = socketAction;
export default socketMessageSlice.reducer;
