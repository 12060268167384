import { Schema } from "ajv";

import infoProperties from "./info_properties";
import getAllQuerySchemaProperties from "../common/get_all_query_schema_properties";
import getAllSearchSchemaProperties from "../common/get_all_search_schema_properties";

const unrequitedExternalId = { type: "string", nullable: true };

const data: Schema = {
  type: "object",
  required: ["info"],
  properties: {
    info: { type: "object", properties: infoProperties },
    dealId: unrequitedExternalId,
    requiredDocumentId: unrequitedExternalId,
  },
};

export default {
  new_funding_document: {
    $id: "newFundingDocument",
    type: "object",
    required: ["data"],
    properties: {
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_funding_document: {
    $id: "updateFundingDocument",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  delete_funding_document: {
    $id: "deleteFundingDocument",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  recover_funding_document: {
    $id: "recoverFundingDocument",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_funding_document: {
    $id: "getFundingDocument",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_funding_documents: {
    $id: "allFundingDocuments",
    anyOf: [
      { type: "object", properties: getAllQuerySchemaProperties },
      { type: "object", properties: getAllSearchSchemaProperties },
    ],
  },
};
