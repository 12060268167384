import { Box, InputBase, Paper } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import ResultsList from "./ResultsList";
import useDebounce from "Chat/hooks/useDebounce";

import useDealSearch from "hooks/useSearchDeal/useSearchDeal";
import { StateAccess, RenderSet } from "utils/models/formGenerator";
import { Deal } from "../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      padding: "10px",
      borderRadius: theme.shape.borderRadius,
      border: "1px solid #254e70",
      gap: 10,
      display: "flex",
      alignItems: "center"
    },
    input: {
      width: "100%",
      fontSize: 18
    },
    resultsWrapper: {
      paddingTop: 10
    }
  })
);

const transformSearchToSearchTerm = (search: string) => {
  if (!search) return {};
  return search?.length > 3 ? { search: { term: search } } : {};
};
interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  handleSelectDeal: (deal: Deal) => void;
}

export default function DealSearch({ renderSet, handleSelectDeal }: Props) {
  const classes = useStyles();
  const [state, setState] = React.useState<string>("");
  const debouncedValue = useDebounce<string>(state, 1000);
  const { loading, data, error } = useDealSearch(transformSearchToSearchTerm(debouncedValue));

  return renderSet.type === "edit" ? (
    <Box>
      <div className={classes.search}>
        <div>
          <SearchIcon fontSize="large" />
        </div>
        <InputBase
          className={classes.input}
          id="searchInput"
          placeholder="Search..."
          inputProps={{ "aria-label": "search" }}
          value={state}
          autoComplete="search"
          onChange={(e) => setState(e.target.value)}
        />
      </div>
      {state.length > 3 && (
        <Box className={classes.resultsWrapper}>
          <Paper elevation={3}>
            <ResultsList
              data={data}
              loading={loading}
              error={error}
              handleSelectDeal={handleSelectDeal}
            />
          </Paper>
        </Box>
      )}
    </Box>
  ) : null;
}
