import React from "react";
import ReactApexChart from "react-apexcharts";
import Paper from "@material-ui/core/Paper";
import { ValueByStatus } from "hooks/useLenderAllocationStatistics/types";
import styles from "../LenderDashboard.module.css";
import { formatNumberAsCurrency } from "utils/functions";
import { DealStatus } from "components/Deals/types";

interface Props {
  data: ((ValueByStatus | (ValueByStatus & { unallocated?: number })) & { period: string })[];
  title: string;
  unallocated?: boolean;
  currencyFormat?: boolean;
  selectedStatuses: DealStatus[];
}

const addSelectedStatusesToData = (
  data:
    | ((ValueByStatus | (ValueByStatus & { unallocated?: number })) & { period: string })
    | undefined,
  selectedStatuses: DealStatus[]
): ValueByStatus & { period: string } => {
  const dataWithAllStatuses = {
    [DealStatus.Lead]: data?.[DealStatus.Lead] ?? 0,
    [DealStatus.DealCreated]: data?.[DealStatus.DealCreated] ?? 0,
    [DealStatus.WaitingForDealDetails]: data?.[DealStatus.WaitingForDealDetails] ?? 0,
    [DealStatus.CreditCheck]: data?.[DealStatus.CreditCheck] ?? 0,
    [DealStatus.PendingLenderDecision]: data?.[DealStatus.PendingLenderDecision] ?? 0,
    [DealStatus.Approved]: data?.[DealStatus.Approved] ?? 0,
    [DealStatus.SentToDealer]: data?.[DealStatus.SentToDealer] ?? 0,
    [DealStatus.SubmittedForFunding]: data?.[DealStatus.SubmittedForFunding] ?? 0,
    [DealStatus.FundingHeld]: data?.[DealStatus.FundingHeld] ?? 0,
    [DealStatus.Funded]: data?.[DealStatus.Funded] ?? 0,
    [DealStatus.Countered]: data?.[DealStatus.Countered] ?? 0,
    [DealStatus.Cancelled]: data?.[DealStatus.Cancelled] ?? 0,
    [DealStatus.Denied]: data?.[DealStatus.Denied] ?? 0,
    [DealStatus.Dead]: data?.[DealStatus.Dead] ?? 0,
    period: data?.period
  };
  return Object.fromEntries(
    Object.entries(dataWithAllStatuses).filter(
      ([key, value]) => selectedStatuses.includes(key as DealStatus) || key === "period"
    )
  ) as ValueByStatus & { period: string };
};

const dataHasPropertyUnallocated = (
  data:
    | ((ValueByStatus | (ValueByStatus & { unallocated?: number })) & { period: string })
    | undefined
): data is ValueByStatus & { unallocated?: number } & { period: string } =>
  Object.hasOwn(data ?? {}, "unallocated");
const sortData = (
  data:
    | ((ValueByStatus | (ValueByStatus & { unallocated?: number })) & { period: string })
    | undefined,
  selectedStatuses: DealStatus[],
  unallocated: boolean
): {
  data: Record<DealStatus | "unallocated", { data: number; color: string }>;
  period: string;
} => {
  const dataWithSelectedStatuses = addSelectedStatusesToData(data, selectedStatuses);
  const order = [
    DealStatus.Lead,
    DealStatus.DealCreated,
    DealStatus.WaitingForDealDetails,
    DealStatus.CreditCheck,
    DealStatus.PendingLenderDecision,
    DealStatus.Approved,
    DealStatus.SentToDealer,
    DealStatus.SubmittedForFunding,
    DealStatus.FundingHeld,
    DealStatus.Funded,
    DealStatus.Countered,
    DealStatus.Cancelled,
    DealStatus.Denied,
    DealStatus.Dead
  ];
  const orderMap = new Map();
  order.forEach((value, index) => {
    orderMap.set(value, index);
  });

  const sortedDataEntries = Object.entries(dataWithSelectedStatuses ?? {}).sort(
    ([keyA, valueA], [keyB, valueB]) => {
      const indexA = orderMap.get(keyA);
      const indexB = orderMap.get(keyB);

      if (indexA === undefined && indexB === undefined) {
        return 0;
      } else if (indexA === undefined) {
        return 1;
      } else if (indexB === undefined) {
        return -1;
      }

      return indexA - indexB;
    }
  );
  const sortedData = {
    ...Object.fromEntries(sortedDataEntries),
    ...(unallocated
      ? { unallocated: dataHasPropertyUnallocated(data) ? data?.unallocated : 0 }
      : {})
  } as ValueByStatus & { unallocated?: number } & { period: string };
  const sortedDataWithColors = addColors(sortedData);
  return { data: sortedDataWithColors, period: data?.period as string };
};
const addColors = (
  obj: ValueByStatus & {
    unallocated?: number | undefined;
  }
): Record<DealStatus | "unallocated", { data: number; color: string }> => ({
  ...Object.entries(obj).reduce(
    (acc: Record<DealStatus | "unallocated", { data: number; color: string }>, [key, value]) => ({
      ...acc,
      [key]: { data: value || 0, color: getColorByStatus(key as DealStatus | "unallocated") }
    }),
    {} as Record<DealStatus | "unallocated", { data: number; color: string }>
  )
});
const getColorByStatus = (status: DealStatus | "unallocated") => {
  switch (status) {
    case DealStatus.Lead:
      return "#a7b7d1";
    case DealStatus.Cancelled:
      return "#ed621c";
    case DealStatus.Dead:
      return "#383736";
    case DealStatus.Countered:
      return "#173de6";
    case DealStatus.CreditCheck:
      return "#40e3d3";
    case DealStatus.DealCreated:
      return "#82e0ad";
    case DealStatus.PendingLenderDecision:
      return "#aec973";
    case DealStatus.WaitingForDealDetails:
      return "#8642a8";
    case DealStatus.Denied:
      return "#b81c1a";
    case DealStatus.Approved:
      return "#f0d855";
    case DealStatus.Funded:
      return "#51bde3";
    case DealStatus.FundingHeld:
      return "#a54343";
    case DealStatus.SentToDealer:
      return "#ffa500";
    case DealStatus.SubmittedForFunding:
      return "#e0c0f0";
    case "unallocated":
      return "#bababa";
  }
};
const constructSeries = (
  sortedData: {
    data: Record<
      DealStatus | "unallocated",
      {
        data: number;
        color: string;
      }
    >;
    period: string;
  }[]
) => {
  return sortedData.reduce((acc: { name: string; data: number[] }[], obj) => {
    Object.entries(obj.data)
      .filter(([key]) => key !== "period")
      .forEach(([name, { data }]) => {
        if (typeof data === "number") {
          const existingItem = acc.find((item) => item.name === name);
          if (existingItem) {
            existingItem.data.push(data);
          } else {
            acc.push({ name, data: [data] });
          }
        }
      });

    return acc;
  }, []);
};
export default function TrendsChartByStatus({
  unallocated = false,
  data,
  selectedStatuses,
  title,
  currencyFormat = false
}: Props) {
  const sortedData = data.map((x) => sortData(x, selectedStatuses, unallocated));
  const categories = data.map((x) => x.period);
  const series = constructSeries(sortedData);
  const colors = Object.values(sortedData?.[0]?.data)
    .map((x) => x.color)
    .filter((x) => x);

  return (
    <Paper style={{ height: "500px" }} className={styles.dashboardItem}>
      <ReactApexChart
        options={{
          chart: {
            stacked: false,
            toolbar: {
              show: false
            }
          },
          legend: {
            offsetY: 5
          },
          plotOptions: {
            bar: { barHeight: "100%", distributed: true, horizontal: false }
          },

          labels: categories,
          colors: colors,
          dataLabels: {
            dropShadow: { enabled: false },
            style: {
              colors: ["black"]
            },
            ...(currencyFormat
              ? {
                  formatter: (val, opts) => {
                    return (
                      formatNumberAsCurrency(opts.w.config.series[opts.seriesIndex], "$") ?? ""
                    );
                  }
                }
              : {
                  formatter: (val, opts) => {
                    return typeof opts.w.config.series[opts.seriesIndex] === "number"
                      ? opts.w.config.series[opts.seriesIndex]
                      : "";
                  }
                })
          },
          stroke: { width: 2 },
          xaxis: { categories },
          yaxis: {
            labels: {
              show: true,
              ...(currencyFormat
                ? {
                    formatter: (val: any) => {
                      return formatNumberAsCurrency(val, "$") ?? "";
                    }
                  }
                : {})
            }
          },
          title: { text: title, align: "center", style: { fontSize: "22px", fontWeight: 600 } }
        }}
        series={series}
        type="line"
        height={270}
        width={500}
      />
    </Paper>
  );
}
