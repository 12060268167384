import styles from "./Chat.module.css";

import { RiCarLine } from "react-icons/ri";
import React, { MouseEvent, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useHideChannel } from "Chat/hooks/useChannel";
import { Channel } from "Chat/types/channels";
import Badge from "./Badge";
import { getElapsedTime } from "utils/formatDate";

const WFDChat = ({
  onClick,
  channels
}: {
  onClick: (index: string) => void;
  channels?: Channel[];
}) => {
  const [time, setTime] = useState(new Date());

  const { hideChannel } = useHideChannel();
  const handleHideChannel = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    channel: Channel
  ) => {
    e.stopPropagation();
    hideChannel(channel);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, [time]);
  return (
    <div className={styles.listContainer}>
      <p>Members</p>
      <ul className={styles.list}>
        {(channels ?? [])?.map((channel, index) => (
          <li className={styles.listItem} key={index} onClick={() => onClick(channel._id)}>
            <div>
              <RiCarLine size={25} color={"#9E9CA7"} width={42} />
            </div>
            <div className={styles.text}>{channel.data.info.name}</div>
            <div style={{ marginLeft: "auto", marginRight: "5px" }}>
              <Badge badgeCount={channel.data.info.unseenMessages} />
            </div>
            {channel.data.info.unseenMessages > 0 && channel.data.info.unseenMessagesTimestamp && (
              <div className={styles.unseenMessagesTimestamp}>
                {getElapsedTime(channel.data.info.unseenMessagesTimestamp)}
              </div>
            )}
            <div onClick={(e) => handleHideChannel(e, channel)} className={styles.closeIcon}>
              <IoMdClose size={16} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WFDChat;
