import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverVehicleInsuranceCompanyRequest {
  _id: string;
}

export interface RecoverVehicleInsuranceCompanyResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverVehicleInsuranceCompanyRequest,
  RecoverVehicleInsuranceCompanyResponse
>("RecoverVehicleInsuranceCompany", {
  request: "recover_vehicle_insurance_company"
});

export const recoverVehicleInsuranceCompanyActions = socketMessageSlice.actions;
export const recoverVehicleInsuranceCompany = socketAction;
export default socketMessageSlice.reducer;
