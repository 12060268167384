import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface LenderEmailVerificationLinkRequest {
  _id: string;
}

export interface LenderEmailVerificationLinkResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  LenderEmailVerificationLinkRequest,
  LenderEmailVerificationLinkResponse
>("EmailVerificationLinkLender", {
  request: "lender_email_verification_link"
});

export const LenderEmailVerificationLinkActions = socketMessageSlice.actions;
export const LenderEmailVerificationLink = socketAction;
export default socketMessageSlice.reducer;
