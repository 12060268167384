import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Tooltip
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { RootState } from "app/rootReducer";
import ASCPreRates from "components/ASCWarranty/ASCRates";
import { ascInsuranceStruct, requiredFieldsASCInsurance } from "components/ASCWarranty/model";
import { ASCInsurance } from "components/ASCWarranty/types";
import formEditContext from "components/Content/FormEditContext";
import { VerificationContext } from "components/Layout/Main";
import { ProtectiveInsuranceStruct, requiredProtectiveFields } from "components/Protective/model";
import { ProtectiveInsurance } from "components/Protective/types";
import { OptionsObject, useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assertNever } from "utils/functions";
import { createOptionsForSelect, FormComponent } from "utils/models/fields";
import {
  generateForm,
  getByPath,
  RenderSet,
  setByPath,
  StateAccess
} from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";
import { v4 as uuidv4 } from "uuid";
import store from "../../../app/store";
import { capitalize } from "../../../utils/functions";
import CloseDialogButton from "../../common/CloseDialogButton";
import { CustomInsurance, Deal, ExpressInsurance, Insurances } from "../../Deals/types";
import { getDealerDeatails } from "../../Protective/getDealerDetailsSlice";
import ProtectivePreRates from "../../Protective/ProtectivePreRates";
import ProtectiveRates from "../../Protective/ProtectiveRates";
import { editSequenceNumber } from "../../Sequence/editSequenceNumberSlice";
import DisplayInsurances from "./DisplayInsurances";
import InsurancePreview from "./InsurancePreview";
import InsuranceProductPreRates from "./InsuranceProductPreRates";
import InsuranceProductRates from "./InsuranceProductRates";
import InsuranceTypeAndDeductiblePreview from "./InsuranceTypeAndDeductiblePreview";
import { listProducts, listProductsActions } from "./listProductsSlice";
import ProviderPreview from "./ProviderPreview";
import Surcharges, { handleChangeSurchargeForInsurance } from "./Surcharges";
import { Product } from "./types";

const initialProtectiveState: ProtectiveInsurance = {
  id: "",
  type: "Protective",
  provider: "",
  ContractNumber: "",
  ContractPrefix: "",
  VSCRateOptions: {
    BeginningOdometer: 0,
    VehiclePlan: "PreOwned",
    InServiceDate: "",
    Surcharges: {
      TwoYearMfg: false,
      ThreeYearMfg: false,
      ThreePlus: false,
      BusinessUse: false,
      ConsequentialFailure: false,
      RearDiesel: false,
      FrontDiesel: false
    }
  },
  VehicleDetails: {
    VehicleYear: 0,
    VehicleMake: "",
    VehicleModel: "",
    VehicleMsrp: 0,
    ChassisYear: 0,
    ChassisMake: "",
    ChassisModel: "",
    VehicleClassCode: "",
    VehicleType: "MotorHomeClassA",
    InServiceDate: ""
  },
  chosenRate: {
    ContractFormID: 0,
    Coverage: "",
    CoverageCode: "",
    CoverageSortOrder: 0,
    CoverageTermMiles: 0,
    CoverageTermMinMonths: 0,
    months: 0,
    dealerCostPrice: 0,
    Deductible: 0,
    OrderNumber: 0,
    ProductClass: "",
    ProductClassCode: 0,
    ProductType: "",
    RateNumber: "",
    retailPrice: 0,
    remitPrice: 0,
    profit: 0,
    insuranceType: undefined
  },
  fundingDocumentId: ""
};
const initialASCState: ASCInsurance = {
  id: "",
  type: "ASC",
  AccountNumber: "",
  provider: "",
  chosenRate: {
    surcharges: [],
    remitPrice: 0,
    retailPrice: 0,
    dealerCostPrice: 0,
    profit: 0,
    insuranceType: "service warranty",
    ProgramID: 0,
    ProgramName: "",
    PlanID: 0,
    PlanName: "",
    TermID: 0,
    TermName: "",
    Miles: 0,
    Months: 0,
    FinalOdometer: 0,
    Expires: "",
    FactoryWarranty: "",
    PlanCost: "0",
    TotalCareCoverage: "",
    DRFC: 0,
    EngineCC: 0,
    TotalCost: "0",
    LOL: 0
  }
};
const initialCustomInsuranceState: CustomInsurance = {
  id: "",
  type: "Custom",
  provider: "",
  url: undefined,
  fileName: undefined,
  chosenRate: {
    months: 0,
    insuranceType: "gap",
    dealerCostPrice: 0,
    retailPrice: 0,
    remitPrice: 0,
    profit: 0
  }
};

const insuranceTypeError = (
  insuranceType: string | undefined,
  enqueueSnackbar: (
    message: React.ReactNode,
    options?: OptionsObject | undefined
  ) => React.ReactText
) => {
  enqueueSnackbar(
    `${capitalize(
      insuranceType
    )} insurance has already been added! The old one must be removed in order to add a new one of the same type.`,
    {
      variant: "error"
    }
  );
};
const insuranceAlreadyExists = (
  provider: "Express" | "Protective" | "ASC" | null,
  protectiveState: ProtectiveInsurance,
  ascState: ASCInsurance,
  customState: CustomInsurance,
  expressState: ExpressInsurance,
  insurances: Insurances
) => {
  switch (provider) {
    case "Express":
      return insurances?.some(
        (insurance) => insurance.chosenRate.insuranceType === expressState.chosenRate.insuranceType
      );

    case "Protective":
      return insurances?.some(
        (insurance) =>
          insurance.chosenRate.insuranceType === protectiveState.chosenRate.insuranceType
      );
    case "ASC":
      return insurances?.some(
        (insurance) => insurance.chosenRate.insuranceType === ascState.chosenRate.insuranceType
      );
    default:
      return insurances?.some(
        (insurance) => insurance.chosenRate.insuranceType === customState.chosenRate.insuranceType
      );
  }
};
interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}

export default function InsuranceProductsPreview({ stateAccess, renderSet }: Props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [requestId] = useState(uuidv4());
  const [preRateRequestId] = useState(uuidv4());
  const actionStateList = useSelector((state: RootState) => state.listProductsSlice[requestId]);
  const getDealerDeatailsState = useSelector(
    (state: RootState) => state.getDealerDetailsSlice[requestId]
  );
  const sequenceNumber = useSelector(
    (state: RootState) => state.editSequenceNumberSlice[requestId]
  );
  const [state, setState] = useState<{
    isDialogOpen: boolean;
    isLoading: boolean;
    productsList: Product[];
    error: boolean;
    errorMessage: string | undefined;
    provider: "Express" | "Protective" | "ASC" | null;
    id: string;
  }>({
    isDialogOpen: false,
    isLoading: false,
    productsList: [],
    error: false,
    errorMessage: undefined,
    provider: null,
    id: ""
  });
  const ascExtraProfit =
    store?.getState()?.listSettingsSlice?.settingsData?.entities?.[0]?.data?.info.ascWarranty ?? 0;
  const checkDataValidity = useContext(VerificationContext);
  const expressAPIStatus = useSelector(
    (state: RootState) => state.listApiStatusSlice["table"]
  )?.entities?.find((el) => el.id === process.env.REACT_APP_EXPRESS_API_ID);

  const ascAPIStatus = useSelector(
    (state: RootState) => state.listApiStatusSlice["table"]
  )?.entities?.find((el) => el.id === process.env.REACT_APP_ASC_API_ID);

  const isExpressAPIOperational =
    expressAPIStatus?.status === "operational" || expressAPIStatus === undefined;
  const isASCOpertational = ascAPIStatus?.status === "operational" || ascAPIStatus === undefined;
  const [rateState, setRateState] = useState<any>({});
  const [customInsuranceState, setCustomInsuranceState] = useState<CustomInsurance>(
    initialCustomInsuranceState
  );
  const [insuranceState, setInsuranceState] = useState<ProtectiveInsurance>(initialProtectiveState);
  const [ascState, setASCState] = useState<ASCInsurance>(initialASCState);
  const [dealerDetatils, setDealerDetails] = useState<string>("");

  const [products, setProductsState] = useState<any>({});
  const [surcharges, setSurchargesState] = useState<any>({});
  const { enabled: showButton } = useContext(formEditContext);

  const deal: Deal = stateAccess.get<Deal>([] as any);
  const dealType = deal?.data?.info?.type;

  const insurances = deal.data?.info?.aftermarketOptions?.insurances ?? [];

  const VIN = stateAccess.get<Deal>(["data", "info", "vehicle", "VIN"]);
  const Odometer = stateAccess.get<Deal>(["data", "info", "vehicle", "odometer"]);
  const CarStatus = stateAccess.get<Deal>(["data", "info", "vehicle", "unitStatus"]);
  const DealType = "Loan";

  const productsStateAccess = {
    get: (path: any) => getByPath(products, path),
    set: (path: any, value: any): any => setProductsState(setByPath(products, path, value))
  };

  const tempFormStateAccess = {
    get: (path: any) => getByPath(rateState, path),
    set: (path: any, value: any): any => setRateState(setByPath(rateState, path, value))
  };
  const ascStateAccess = {
    get: (path: any) => getByPath(ascState, path),
    set: (path: any, value: any): any => setASCState(setByPath(ascState, path, value))
  };
  const customInsuranceStateAccess = {
    get: (path: any) => getByPath(customInsuranceState, path),
    set: (path: any, value: any): any =>
      setCustomInsuranceState(setByPath(customInsuranceState, path, value))
  };

  const protectiveFormStateAccess = {
    get: (path: any) => getByPath(insuranceState, path),
    set: (path: any, value: any): any => setInsuranceState(setByPath(insuranceState, path, value))
  };

  const productId = productsStateAccess.get(["Product", "EX1ProductID"]);
  const product = productsStateAccess.get(["Product"]);

  const vechicleStruct: FormComponent<Deal> = {
    formComponent: "segment",
    name: "Vehicle info",
    width: "full",
    entities: [
      {
        formComponent: "select-field",
        options: createOptionsForSelect({
          possibleValues: () => ["new", "used"],
          getOptionLabel: (x) => x,
          getSelectedOption: (x, y) => x === y
        }),
        name: "carStatus",
        label: "Car status",
        width: "1/3",
        required: true,
        path: ["data", "info", "vehicle", "unitStatus"],
        default: stateAccess.get<Deal>(["data", "info", "vehicle", "unitStatus"])
      },
      {
        formComponent: "number-field",
        name: "odometer",
        label: "Odometer",
        width: "1/3",
        required: true,
        path: ["data", "info", "vehicle", "odometer"],
        default: stateAccess.get<Deal>(["data", "info", "vehicle", "odometer"])
      },
      {
        formComponent: "text-field",
        name: "vin",
        label: "VIN",
        width: "1/3",
        required: true,
        path: ["data", "info", "vehicle", "VIN"],
        default: stateAccess.get<Deal>(["data", "info", "vehicle", "VIN"])
      },
      ...((state.provider === "Protective"
        ? [
            {
              formComponent: "text-field",
              name: "make",
              label: "Make",
              width: "1/3",
              required: true,
              path: ["data", "info", "vehicle", "make"],
              default: stateAccess.get<Deal>(["data", "info", "vehicle", "make"])
            },
            {
              formComponent: "text-field",
              name: "model",
              label: "Model",
              width: "1/3",
              required: true,
              path: ["data", "info", "vehicle", "model"],
              default: stateAccess.get<Deal>(["data", "info", "vehicle", "model"])
            },
            {
              formComponent: "year-field",
              name: "year",
              label: "year",
              width: "1/3",
              required: true,
              path: ["data", "info", "vehicle", "year"],
              default: null
            }
          ]
        : []) as FormComponent<Deal>[])
    ]
  };

  const customInsuranceStruct: FormComponent<CustomInsurance> = {
    formComponent: "segment",
    width: "full",
    name: "Insurance info",
    entities: [
      {
        formComponent: "one-to-many-field",
        component: (
          stateAccess: StateAccess,
          mainStateAccess: StateAccess,
          renderSet: RenderSet
        ): JSX.Element => (
          <ProviderPreview
            stateAccess={stateAccess}
            mainStateAccess={mainStateAccess}
            renderSet={{ ...renderSet }}
          />
        ),
        name: "",
        width: "1/2",
        path: [] as any,
        default: {}
      },
      {
        formComponent: "one-to-many-field",
        component: (
          stateAccess: StateAccess,
          _mainStateAccess: StateAccess,
          renderSet: RenderSet
        ): JSX.Element => (
          <InsuranceTypeAndDeductiblePreview
            stateAccess={stateAccess}
            renderSet={{ ...renderSet }}
          />
        ),
        name: "",
        width: "1/2",
        path: [] as any,
        default: {}
      },
      {
        formComponent: "number-field",
        name: "months",
        label: "Months",
        width: "1/2",
        show: (stateAccess) => {
          const provider = stateAccess.get(["provider"]);
          const insuranceType = stateAccess.get(["chosenRate", "insuranceType"]);
          switch (provider) {
            case "Protective":
              if (insuranceType === "gap") {
                return false;
              }
              return true;
            case "XtraRide":
              return deal?.data?.info?.type !== "Power Sport";
            default:
              return true;
          }
        },
        path: ["chosenRate", "months"],
        default: null
      },
      {
        width: (stateAccess) =>
          stateAccess.get(["chosenRate", "customMonthsEnabled"]) ? "1/4" : "1/2",
        show: (stateAccess, formEditContext, mainStateAccess) =>
          stateAccess.get(["provider"]) === "Protective" &&
          stateAccess.get(["chosenRate", "insuranceType"]) === "gap" &&
          mainStateAccess?.get(["data", "info", "type"]) === "Automotive" &&
          mainStateAccess?.get(["data", "info", "payment", "numberOfPayments"]) === 87,
        formComponent: "checkbox-field",
        isChecked: (stateAccess) => stateAccess.get(["chosenRate", "customMonthsEnabled"]),
        toggle: (stateAccess) =>
          stateAccess.set(
            ["chosenRate", "customMonthsEnabled"],
            !stateAccess.get<Deal>(["chosenRate", "customMonthsEnabled"])
          ),
        name: "customMonths",
        label: "Custom months?",
        path: ["chosenRate", "customMonthsEnabled"]
      },
      {
        formComponent: "number-field",
        width: "1/4",
        show: (stateAccess) => stateAccess.get(["chosenRate", "customMonthsEnabled"]),
        name: "months",
        label: "Custom months",
        path: ["chosenRate", "customMonths"]
      },
      {
        formComponent: "read-only-field",
        name: "months",
        label: "Months",
        width: "1/2",
        show: (stateAccess) => {
          const provider = stateAccess.get(["provider"]);
          const insuranceType = stateAccess.get(["chosenRate", "insuranceType"]);
          switch (provider) {
            case "Protective":
              if (insuranceType === "gap") {
                return true;
              }
              return false;
            case "XtraRide":
              return deal?.data?.info?.type === "Power Sport";
            default:
              return false;
          }
        },
        path: ["chosenRate", "months"],
        default: null
      },
      {
        formComponent: "currency-field",
        name: "dealerCostPrice",
        label: "Cost price",
        width: "1/4",
        show: (stateAccess) => {
          const provider = stateAccess.get(["provider"]);
          const insuranceType = stateAccess.get(["chosenRate", "insuranceType"]);
          switch (provider) {
            case "Protective":
              if (insuranceType === "gap") {
                return false;
              }
              return true;
            case "XtraRide":
              return deal?.data?.info?.type !== "Power Sport";
            default:
              return true;
          }
        },
        path: ["chosenRate", "dealerCostPrice"],
        default: null
      },
      {
        formComponent: "read-only-number-field",
        name: "dealerCostPrice",
        label: "Cost price",
        width: "1/4",
        show: (stateAccess) => {
          const provider = stateAccess.get(["provider"]);
          const insuranceType = stateAccess.get(["chosenRate", "insuranceType"]);
          switch (provider) {
            case "Protective":
              if (insuranceType === "gap") {
                return true;
              }
              return false;
            case "XtraRide":
              return deal?.data?.info?.type === "Power Sport";
            default:
              return false;
          }
        },
        path: ["chosenRate", "dealerCostPrice"],
        default: null
      },
      {
        formComponent: "currency-field",
        name: "retailPrice",
        label: "Retail price",
        width: "1/4",
        path: ["chosenRate", "retailPrice"],
        default: null
      },
      {
        formComponent: "currency-field",
        name: "remitPrice",
        label: "Remit price",
        show: (stateAccess) => {
          const provider = stateAccess.get(["provider"]);
          const insuranceType = stateAccess.get(["chosenRate", "insuranceType"]);

          switch (provider) {
            case "Protective":
              if (insuranceType === "gap") {
                return false;
              }
              return true;
            case "XtraRide":
              return deal?.data?.info?.type !== "Power Sport";
            default:
              return true;
          }
        },
        width: "1/4",
        path: ["chosenRate", "remitPrice"],
        default: null
      },
      {
        formComponent: "read-only-number-field",
        name: "remitPrice",
        label: "Remit price",
        show: (stateAccess) => {
          const provider = stateAccess.get(["provider"]);
          const insuranceType = stateAccess.get(["chosenRate", "insuranceType"]);

          switch (provider) {
            case "Protective":
              if (insuranceType === "gap") {
                return true;
              }
              return false;
            case "XtraRide":
              return deal?.data?.info?.type === "Power Sport";
            default:
              return false;
          }
        },
        width: "1/4",
        path: ["chosenRate", "remitPrice"],
        default: null
      },
      {
        formComponent: "read-only-number-field",
        name: "profit",
        label: "Profit",
        width: "1/4",
        path: ["chosenRate", "profit"],
        default: null
      },
      {
        formComponent: "radio-field",
        name: "soldBY",
        label: "Sold by",
        width: "1/2",
        path: ["chosenRate", "soldBy"],
        required: true,
        possibleValues: [
          { label: "WFD", value: "WFD" },
          { label: "Dealer", value: "Dealer" }
        ],
        default: null
      }
    ]
  };

  const chosenInsuranceStruct: FormComponent<ExpressInsurance> = {
    formComponent: "segment",
    width: "full",
    name: "Insurance info",
    entities: [
      {
        formComponent: "read-only-field",
        name: "provider",
        label: "Provider",
        path: ["provider"],
        width: "1/2"
      },
      {
        formComponent: "read-only-field",
        name: "insuranceType",
        label: "Type",
        path: ["chosenRate", "insuranceType"],
        width: "1/2"
      },
      {
        formComponent: "read-only-field",
        name: "months",
        label: "Months",
        path: ["chosenRate", "months"],
        width: "1/2"
      },
      {
        formComponent: "read-only-number-field",
        name: "dealerCostPrice",
        label: "Cost price",
        path: ["chosenRate", "dealerCostPrice"],
        width: "1/4"
      },
      {
        formComponent: "currency-field",
        name: "retailPrice",
        label: "Retail price",
        width: "1/4",
        path: ["chosenRate", "retailPrice"],
        default: null
      },
      {
        formComponent: "read-only-number-field",
        name: "remitPrice",
        label: "Remit price",
        width: "1/4",
        path: ["chosenRate", "remitPrice"]
      },
      {
        formComponent: "read-only-number-field",
        name: "profit",
        label: "Profit",
        width: "1/4",
        path: ["chosenRate", "profit"],
        default: null
      },
      {
        formComponent: "radio-field",
        name: "soldBY",
        label: "Sold by",
        width: "1/2",
        path: ["chosenRate", "soldBy"],
        required: true,
        possibleValues: [
          { label: "WFD", value: "WFD" },
          { label: "Dealer", value: "Dealer" }
        ],
        default: null
      },
      {
        formComponent: "one-to-many-field",
        component: (
          stateAccess: StateAccess,
          _mainStateAccess: StateAccess,
          renderSet: RenderSet
        ): JSX.Element => (
          <Surcharges
            handleChange={handleChangeSurchargeForInsurance}
            stateAccess={stateAccess}
            surcharges={stateAccess.get(["chosenRate", "dynamicSurcharges"])}
          />
        ),
        name: "",
        width: "full",
        path: [] as any
      }
    ]
  };

  const requiredFields = [
    { name: "Vehicle->VIN", value: VIN },
    { name: "Vehicle->Odometer", value: Odometer },
    { name: "Vehicle->Unit status", value: CarStatus }
  ];

  const handleOpenDialog = () => {
    if (dealType === "Recreational Vehicles") setState({ ...state, isDialogOpen: true });
    else {
      checkDataValidity(requiredFields, () => {
        setState({ ...state, isDialogOpen: true });
      });
    }
    setCustomInsuranceState({
      ...customInsuranceState,
      id: uuidv4(),
      chosenRate: {
        ...customInsuranceState.chosenRate,
        months: stateAccess.get(["data", "info", "payment", "numberOfPayments"])
      }
    });
  };
  const handleASClick = () => {
    checkDataValidity(requiredFields, () => {
      setState({ ...state, provider: "ASC" });
      setASCState((state) => ({ ...state, id: uuidv4() }));
    });
  };
  const handleExpressClick = () => {
    checkDataValidity(requiredFields, () => {
      setState({ ...state, isLoading: true, provider: "Express" });
      setRateState((state: any) => ({ ...state, id: uuidv4() }));

      handleGetProvidersList();
    });
  };
  const handleGetProvidersList = () => {
    dispatch(listProducts({ requestId }));
  };
  const handleGetDealerDetails = () => {
    setState({ ...state, isLoading: true, provider: "Protective" });
    setInsuranceState((state: any) => ({ ...state, id: uuidv4() }));

    dispatch(getDealerDeatails({ requestId, data: {} }));
  };

  const handleClose = () => {
    productsStateAccess.set(["Product"], null);
    setState({ ...state, provider: null, isDialogOpen: false, error: false });
    setSurchargesState({});
    setRateState({});
    setCustomInsuranceState(initialCustomInsuranceState);
    setInsuranceState(initialProtectiveState);
    setASCState(initialASCState);
  };

  useEffect(() => {
    if (actionStateList !== undefined && actionStateList.status !== undefined) {
      switch (actionStateList.status) {
        case "error":
          setState({ ...state, isLoading: false, error: true });
          dispatch({
            type: listProductsActions.none.type,
            payload: { requestId }
          });
          break;
        case "waiting":
          setState({ ...state, isLoading: true });
          dispatch({
            type: listProductsActions.none.type,
            payload: { requestId }
          });
          break;
        case "success":
          const { EX1DealerProductResponse } = actionStateList.data.message.data;

          const statusCode = EX1DealerProductResponse?.MsgHeader.Message[0].StatusCode || "1000";
          const errorMessage =
            EX1DealerProductResponse?.MsgHeader.Message[0].Description || "Unexpected error";

          if (statusCode === "0000") {
            setState({
              ...state,
              isLoading: false,
              isDialogOpen: true,
              productsList: EX1DealerProductResponse?.Products?.Product ?? [],
              provider: "Express",
              error: false,
              errorMessage: undefined
            });
          } else {
            setState({ ...state, isLoading: false, error: true, errorMessage });
          }

          dispatch({
            type: listProductsActions.none.type,
            payload: { requestId }
          });
          break;
      }
    }
  }, [actionStateList, dispatch, requestId, state]);
  useEffect(() => {
    if (
      getDealerDeatailsState !== undefined &&
      getDealerDeatailsState.status === "success" &&
      getDealerDeatailsState.data !== null
    ) {
      setState((prev) => ({
        ...prev,
        isLoading: false
      }));
      setDealerDetails(
        getDealerDeatailsState.data.message?.GetDealerDetailsResult?.Dealers?.Dealer[0]
          ?.DealerProducts?.ProductDetails?.ProductDetail[0]?.Prefixes?.Prefix[0]?.ContractPrefix
      );
    }
  }, [getDealerDeatailsState]);

  useEffect(() => {
    if (sequenceNumber?.status === "success") {
      stateAccess.set<Deal>(
        ["data", "info", "aftermarketOptions", "insurances"],
        [...insurances, { ...customInsuranceState, sequence: sequenceNumber?.data?.message }]
      );
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequenceNumber, customInsuranceState, insurances, stateAccess]);

  const calculateProfit = (stateAccess: StateAccess) => {
    const chosenRate = stateAccess.get<ExpressInsurance | ProtectiveInsurance | ASCInsurance>([
      "chosenRate"
    ]);
    const oldProfit = chosenRate?.profit ?? 0;
    const dealerCostPrice = chosenRate?.dealerCostPrice ?? 0;
    const retailPrice = chosenRate?.retailPrice ?? 0;
    const newProfit = parseFloat((retailPrice - dealerCostPrice).toFixed(2));

    if (oldProfit !== newProfit) {
      stateAccess.set<ExpressInsurance | ProtectiveInsurance | ASCInsurance>(["chosenRate"], {
        ...chosenRate,
        profit: newProfit
      });
    }
  };

  useEffect(() => {
    calculateProfit(tempFormStateAccess);
    calculateProfit(customInsuranceStateAccess);
    calculateProfit(protectiveFormStateAccess);
    calculateProfit(ascStateAccess);
  }, [tempFormStateAccess, protectiveFormStateAccess, customInsuranceStateAccess, ascStateAccess]);

  const addInsurance = () => {
    const deal: Deal = stateAccess.get([]);
    const insurances = deal.data?.info?.aftermarketOptions?.insurances ?? [];

    if (state?.provider === "Protective")
      stateAccess.set<Deal>(
        ["data", "info", "aftermarketOptions", "insurances"],
        [...insurances, insuranceState]
      );

    if (state?.provider === "Express") {
      stateAccess.set<Deal>(
        ["data", "info", "aftermarketOptions", "insurances"],
        [...insurances, rateState]
      );
      if (
        rateState?.provider === "ASC Warranty" &&
        rateState?.chosenRate?.insuranceType === "service warranty"
      ) {
        stateAccess.set<Deal>(
          ["data", "info", "profit", "wfdProfit", "extraServiceWarrantyProfit"],
          ascExtraProfit
        );
      }
    }
    if (state?.provider === "ASC") {
      stateAccess.set<Deal>(
        ["data", "info", "aftermarketOptions", "insurances"],
        [...insurances, ascState]
      );
      stateAccess.set<Deal>(
        ["data", "info", "profit", "wfdProfit", "extraServiceWarrantyProfit"],
        ascExtraProfit
      );
    }
    if (state?.provider === null) {
      if (
        dealType === "Automotive" &&
        customInsuranceState.provider === "Protective" &&
        customInsuranceState.chosenRate.insuranceType === "gap"
      )
        dispatch(
          editSequenceNumber({
            requestId,
            _id: `Insurance - ${customInsuranceState.provider} ${customInsuranceState.chosenRate.insuranceType}`
          })
        );
      else {
        stateAccess.set<Deal>(
          ["data", "info", "aftermarketOptions", "insurances"],
          [...insurances, customInsuranceState]
        );
        handleClose();
      }
    } else handleClose();
  };

  const requiredFieldsInsurance = (stateAccess: StateAccess) => [
    {
      name: "Provider",
      value: stateAccess.get(["provider"])
    },
    {
      name: "Insurance type",
      value: stateAccess.get(["chosenRate", "insuranceType"])
    },
    {
      name: "Months",
      value: stateAccess.get(["chosenRate", "months"])
    },

    {
      name: "Cost price",
      value: stateAccess.get(["chosenRate", "dealerCostPrice"])
    },
    {
      name: "Retail Price",
      value: stateAccess.get(["chosenRate", "retailPrice"])
    },
    {
      name: "Remit Price",
      value: stateAccess.get(["chosenRate", "remitPrice"])
    },
    {
      name: "Sold by",
      value: stateAccess.get(["chosenRate", "soldBy"])
    }
  ];

  const handleAddInsurance = () => {
    if (
      insuranceAlreadyExists(
        state.provider,
        insuranceState,
        ascState,
        customInsuranceState,
        rateState,
        insurances
      )
    )
      insuranceTypeError(
        state.provider === "Protective"
          ? insuranceState?.chosenRate?.insuranceType
          : state.provider === "Express"
          ? rateState?.chosenRate?.insuranceType
          : state.provider === "ASC"
          ? ascState?.chosenRate?.insuranceType
          : customInsuranceState?.chosenRate.insuranceType,
        enqueueSnackbar
      );
    else
      checkDataValidity(
        state.provider === "Protective"
          ? requiredProtectiveFields(protectiveFormStateAccess)
          : state.provider === "ASC"
          ? requiredFieldsASCInsurance(ascStateAccess)
          : requiredFieldsInsurance(
              state.provider === "Express" ? tempFormStateAccess : customInsuranceStateAccess
            ),
        addInsurance
      );
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          display: "flex",
          marginTop: "-40px",
          marginLeft: "85px"
        }}
      >
        {showButton && (
          <IconButton
            aria-label="add"
            style={{ color: "rgb(80, 165, 56)", marginLeft: "11px", marginBottom: "3px" }}
            onClick={handleOpenDialog}
          >
            <AddCircleIcon />
          </IconButton>
        )}
      </div>
      <Dialog open={state.isDialogOpen} fullWidth maxWidth="md" onClose={handleClose}>
        <CloseDialogButton closeFunction={handleClose} />

        <DialogContent>
          <Box>{generateForm(vechicleStruct, stateAccess, [], stateAccess, renderSet)}</Box>
          {!state.provider ? (
            <Grid justifyContent="center" container spacing={2} style={{ padding: "5px" }}>
              <Grid
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                item
                xs={3}
              >
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={handleExpressClick}
                >
                  F&I Express
                </Button>
                {!isExpressAPIOperational && (
                  <Grid container item xs={1} justifyContent="center" alignItems="center">
                    <Tooltip placement="top" title="F&I Express service is unavailable!">
                      <ErrorIcon color="secondary" />
                    </Tooltip>
                  </Grid>
                )}
              </Grid>

              {(dealType === "Automotive" || dealType === "Power Sport") && (
                <Grid
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  item
                  xs={3}
                >
                  <Button
                    size="medium"
                    id="asc-warranty-button"
                    variant="contained"
                    color="primary"
                    onClick={handleASClick}
                  >
                    ASC Warranty
                  </Button>
                  {!isASCOpertational && (
                    <Grid container item xs={1} justifyContent="center" alignItems="center">
                      <Tooltip placement="top" title="ASC API is unavailable!">
                        <ErrorIcon color="secondary" />
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              )}
              {/* {dealType === "Recreational Vehicles" && (
                <Grid
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  item
                  xs={3}
                >
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={handleGetDealerDetails}
                    color="primary"
                  >
                    Protective
                  </Button>
                </Grid>
              )} */}
            </Grid>
          ) : (
            <></>
          )}
          {!state.error ? (
            state.isLoading ? (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              <>
                {state.provider === "Express" && (
                  <Box>
                    <InsurancePreview
                      state={state.productsList}
                      stateAccess={productsStateAccess}
                      setRateState={setRateState}
                    />
                    <InsuranceProductPreRates
                      requestId={requestId}
                      stateAccess={stateAccess}
                      renderSet={editRenderSet("express_ex1_pre_rate")}
                      surcharges={surcharges}
                      productId={productId}
                      VIN={VIN}
                      Odometer={Odometer}
                      CarStatus={CarStatus}
                      DealType={DealType}
                      setSurchargesState={setSurchargesState}
                      productState={products}
                    />
                    <InsuranceProductRates
                      surcharges={surcharges}
                      product={product}
                      requestId={requestId}
                      productState={products}
                      rateState={rateState}
                      setRateState={setRateState}
                      deal={deal}
                    />
                  </Box>
                )}
                {state.provider === "Protective" && (
                  <Box>
                    <ProtectivePreRates
                      requestId={preRateRequestId}
                      stateAccess={stateAccess}
                      renderSet={editRenderSet("protective_get_rv_rates")}
                    />
                    <ProtectiveRates
                      requestId={preRateRequestId}
                      insuranceState={insuranceState}
                      setInsuranceState={setInsuranceState}
                      contractPrefix={dealerDetatils}
                    />
                  </Box>
                )}
              </>
            )
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="h6">
                Oops, something went wrong. Please try again later.
              </Typography>
            </div>
          )}
          {state.provider === "ASC" && (
            <Box>
              <ASCPreRates
                ascState={ascState}
                setASCState={setASCState}
                requestId={preRateRequestId}
                stateAccess={stateAccess}
                renderSet={editRenderSet("asc_get_rate")}
              />
            </Box>
          )}
          {state.provider === "Protective"
            ? generateForm(
                ProtectiveInsuranceStruct,
                protectiveFormStateAccess,
                [],
                protectiveFormStateAccess,
                editRenderSet("express_ex1_provider_list")
              )
            : state.provider === "Express"
            ? generateForm(
                chosenInsuranceStruct,
                tempFormStateAccess,
                [],
                tempFormStateAccess,
                editRenderSet("express_ex1_provider_list")
              )
            : state.provider === "ASC"
            ? generateForm(
                ascInsuranceStruct,
                ascStateAccess,
                [],
                ascStateAccess,
                editRenderSet("asc_get_rate")
              )
            : generateForm(
                customInsuranceStruct,
                customInsuranceStateAccess,
                [],
                stateAccess,
                editRenderSet("asc_get_rate")
              )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleAddInsurance}
            color="primary"
            variant="contained"
            id="add-insurance"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <DisplayInsurances stateAccess={stateAccess} renderSet={renderSet} />
    </>
  );
}
