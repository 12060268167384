import { FormComponent, Model } from "utils/models/fields";
import { PayoffBank } from "./types";

export const payoffBankInfo: FormComponent<PayoffBank> = {
  formComponent: "segment",
  name: "Payoff Bank",
  width: "full",
  entities: [
    {
      formComponent: "text-field",
      name: "name",
      label: "Name",
      width: "full",
      required: true,
      path: ["data", "info", "name"],
      default: null
    }
  ]
};

export const payoffBankStruct: Model<PayoffBank> = {
  formComponent: "model",
  schema: "new_payoff_bank",
  name: "payoff_bank",

  entities: [{ formComponent: "segment", entities: [payoffBankInfo] }]
};
