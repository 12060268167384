import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { CustomerCareNote } from "./types";

interface EditCustomerCareNoteResponse {
  message: CustomerCareNote;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  CustomerCareNote,
  EditCustomerCareNoteResponse
>("EditCustomerCareNote", {
  request: "update_customer_care_note"
});

export const editCustomerCareNote = socketAction;
export const editCustomerCareNoteActions = socketMessageSlice.actions;
export const editCustomerCareNoteError = socketMessageSlice.actions.error;

export default socketMessageSlice.reducer;
