import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { DeliveredDeal, FormState } from "./types";

interface AddDeliveredDealRequestSend {
  data: FormState;
}
interface AddDeliveredDealResponse {
  message: DeliveredDeal;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddDeliveredDealRequestSend,
  AddDeliveredDealResponse
>("AddDeliveredDealResponse", {
  request: "new_delivered_deal"
});

export const addDeliveredDealActions = socketMessageSlice.actions;
export const addDeliveredDeal = socketAction;
export default socketMessageSlice.reducer;
