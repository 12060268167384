import React, { useState, useEffect } from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { Dialog, IconButton, Typography, Button, DialogActions } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import {
  userStruct,
  userStructProfile,
  readOnlyUserStruct,
  userPinStruct
} from "components/Users/model";
import { EntityType, EntityData, entityConfigs, getEntity } from "utils/entitySlice";
import { generateForm, StateAccess, getByPath, setByPath } from "utils/models/formGenerator";
import { editRenderSet, showRenderSet } from "utils/models/formRenderers";
import { editUser } from "components/Users/editUserSlice";
import { uuid } from "uuidv4";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { User } from "./types";
import { LockEntity } from "utils/LockEntity";
import ShowEditEntity from "components/Content/showEditEntity";
import AccessControl from "components/Access/AccessControl";

interface Props {
  open: boolean;
  setOpen: (x: boolean) => void;
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export default function EditProfileDialog({ open, setOpen }: Props) {
  const formName = "user-edit-profile";
  const [requestId] = useState(uuid());
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state?.authSlice?.user);
  const { user } = useSelector((state: RootState) => state.authSlice);
  const updateUserSlice = useSelector((state: RootState) => state.editUserSlice[requestId]);
  const [formState, setFormState] = useState<User | undefined>(user?.databaseData);

  const allLocks = useSelector((state: RootState) => state.listLockSlice["all"]);
  const lockData = allLocks?.entities?.find(
    (lock) =>
      lock?.data?.info?.entityId === formState?._id && lock?.data?.info?.entityName === "wfd_user"
  );
  const edit = user?.email === lockData?.data?.info?.userEmail;

  const stateAccess: StateAccess = {
    get: (path) => getByPath(formState as any, path),
    set: (path, value): any => {
      setFormState(setByPath(formState as any, path, value));
    }
  };

  const [pinState, setPinState] = useState<
    { oldPin: string; newPin: string; confirmPin: string } | undefined
  >();
  const userPinStateAccess: StateAccess = {
    get: (path) => getByPath(pinState as any, path),
    set: (path, value): any => {
      setPinState(setByPath(pinState as any, path, value));
    }
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleProfileUpdate = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (formState) {
      const { availableToRoles, updatedAt, deleted, ...data } = formState;
      const roleIds = availableToRoles?.map((role: any) => role._id) ?? [];

      dispatch(editUser({ ...(data as any), availableToRolesIds: roleIds, requestId }));
    }
  };
  useEffect(() => {
    const requestId = uuid();
    dispatch({
      type: entityConfigs["wfd_user"]?.edit.sliceActions.add.type,
      payload: { requestId }
    });
    return () => {
      dispatch({
        type: entityConfigs["wfd_user"]?.edit.sliceActions.remove.type,
        payload: { requestId }
      });
    };
  }, [dispatch]);

  return (
    <div>
      {formState && (
        <Dialog onClose={handleDialogClose} aria-labelledby="widget-dialog" open={open}>
          <DialogTitle id="widget-dialog" onClose={handleDialogClose}>
            Update your profile
          </DialogTitle>
          <DialogContent>
            <LockEntity model={entityConfigs["wfd_user"].model} entityId={formState?._id} />
            <form
              data-id={formState._id}
              data-editable={edit}
              id={formName}
              onSubmit={handleProfileUpdate}
              autoComplete="off"
            >
              {edit ? (
                <>
                  {generateForm(
                    userStructProfile,
                    stateAccess,
                    [],
                    stateAccess,
                    editRenderSet("update_wfd_user"),
                    auth?.permissions?.[entityConfigs["wfd_user"].model.name],
                    {
                      enabled: edit,
                      edited: true,
                      initialState: formState,
                      auth
                    },
                    auth?.databaseData
                  )}
                  {generateForm(
                    readOnlyUserStruct,
                    stateAccess,
                    [],
                    stateAccess,
                    showRenderSet(false),
                    auth?.permissions?.[entityConfigs["wfd_user"].model.name],
                    {
                      enabled: edit,
                      edited: true,
                      initialState: formState,
                      auth
                    },
                    auth?.databaseData
                  )}
                </>
              ) : (
                <>
                  {generateForm(
                    userStruct,
                    stateAccess,
                    [],
                    stateAccess,
                    showRenderSet(false),
                    auth?.permissions?.[entityConfigs["wfd_user"].model.name],
                    {
                      enabled: edit,
                      edited: true,
                      initialState: formState,
                      auth
                    },
                    auth?.databaseData
                  )}
                </>
              )}
            </form>
          </DialogContent>
          <DialogActions>
            {edit && (
              <AccessControl requiredPermissions={{ entity: "wfd_user", action: "update" }}>
                <Button variant="contained" color="primary" onClick={handleProfileUpdate}>
                  Save
                </Button>
              </AccessControl>
            )}
            <Button variant="contained" color="primary" onClick={handleDialogClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
