import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import {
  EditDealershipProgramsRequest,
  EditDealershipProgramsRequestSend,
  DealershipProgram
} from "./types";

interface EditDealershipProgramResponse {
  message: DealershipProgram;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditDealershipProgramsRequestSend,
  EditDealershipProgramResponse
>("EditDealershipProgram", {
  request: "update_dealership_program"
});

export const editDealershipProgramActions = socketMessageSlice.actions;
export const editDealershipProgram = (request: EditDealershipProgramsRequest & Identifiable) => {
  console.log(request);
  return socketAction(request);
};
export default socketMessageSlice.reducer;
