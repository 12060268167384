import { combineReducers } from "@reduxjs/toolkit";
import editNotificationSlice from "components/AppBar/Notifications/editNotificationSlice";
import listNotificationSlice from "components/AppBar/Notifications/listNotificationSlice";
import removeAllNotificationsSlice from "components/AppBar/Notifications/removeAllNotificationsSlice";
import removeNotificationSlice from "components/AppBar/Notifications/removeNotificationSlice";
import addContractTypeSlice from "components/Contracts/ContractTypes/addContractTypeSlice";
import deleteContractTypeSlice from "components/Contracts/ContractTypes/deleteContractTypeSlice";
import editContractTypeSlice from "components/Contracts/ContractTypes/editContractTypeSlice";
import listContractTypeSlice from "components/Contracts/ContractTypes/listContractTypeSlice";
import recoverContractTypeSlice from "components/Contracts/ContractTypes/recoverContractTypeSlice";
import addContractSlice from "components/Contracts/addContractSlice";
import deleteContractSlice from "components/Contracts/deleteContractSlice";
import editContractSlice from "components/Contracts/editContractSlice";
import listContractSlice from "components/Contracts/listContractSlice";
import recoverContractSlice from "components/Contracts/recoverContractSlice";
import createInvoiceAndVendorBillSlice from "components/Deals/Netsuite/createInvoiceAndVendorBillSlice";
import addDeliveredDealSlice from "components/DeliveredDeals/addDeliveredDealSlice";
import addManyLenderDecisions from "components/LenderDecisions/addManyLenderDecisionsSlice";
import deleteRVContract from "components/Protective/deleteContractSlice";
import getDealerDetailsSlice from "components/Protective/getDealerDetailsSlice";
import getRVRatesSlice from "components/Protective/getRVRatesSlice";
import sumbitRVContractSlice from "components/Protective/submitContractSlice";
import deleteBusinessReportSlice from "components/Reports/BusinessReports/deleteBusinessReportSlice";
import recoverBusinesReportSlice from "components/Reports/BusinessReports/recoverBusinessReportSlice";
import editSettingsSlice from "components/Settings/editSettingsSlice";
import addTableSettingsSlice from "components/Table/TableSettings/addTableSettingsSlice";
import editTableSettingsSlice from "components/Table/TableSettings/editTableSettingsSlice";
import listTableSettingsSlice from "components/Table/TableSettings/listTableSettingsSlice";
import listApiStatusSlice from "utils/Monitoring/listApiStatusSlice";
import deleteASCInsurance from "../components/ASCWarranty/deleteASCInsuranceSlice";
import getASCRates from "../components/ASCWarranty/getASCRatesSlice";
import sumibtASCInsurance from "../components/ASCWarranty/submitASCInsuranceSlice";
import addAppOneDealSlice from "../components/AppOne/Deal/addDealSlice";
import addApplicantSlice from "../components/Applicants/addApplicantSlice";
import deleteApplicantSlice from "../components/Applicants/deleteApplicantSlice";
import editApplicantSlice from "../components/Applicants/editApplicantSlice";
import listApplicantSlice from "../components/Applicants/listApplicantSlice";
import recoverApplicantSlice from "../components/Applicants/recoverApplicantSlice";
import changedCounterSlice from "../components/ChangedCounter/ChangedCounterSlice";
import checkCreditScoreSlice from "../components/CreditScore/checkCreditScoreSlice";
import listCreditScoreSlice from "../components/CreditScore/listCreditScoreSlice";
import addCustomReportSlice from "../components/CustomReport/addCustomReportSlice";
import deleteCustomReportSlice from "../components/CustomReport/deleteCustomReportSlice";
import editCustomReportSlice from "../components/CustomReport/editCustomReportSlice";
import listCustomReportSlice from "../components/CustomReport/listCustomReportSlice";
import recoverCustomReportSlice from "../components/CustomReport/recoverCustomReportSlice";
import dashboardSlice from "../components/Dashboard/dashboardSlice";
import addDealerTrackSlice from "../components/DealerTrack/addDealerTrackSlice";
import addDealershipSlice from "../components/Dealerships/addDealershipSlice";
import deleteDealershipSlice from "../components/Dealerships/deleteDealershipSlice";
import editDealershipSlice from "../components/Dealerships/editDealershipSlice";
import listDealershipSlice from "../components/Dealerships/listDealershipSlice";
import recoverDealershipSlice from "../components/Dealerships/recoverDealershipSlice";
import dealOrderSlice from "../components/Deals/DealsLog/dealOrderSlice";
import addDealFromParsedDocumentSlice from "../components/Deals/addDealFromParsedDocumentSlice";
import addDealSlice from "../components/Deals/addDealSlice";
import deleteDealSlice from "../components/Deals/deleteDealSlice";
import editDealSlice from "../components/Deals/editDealSlice";
import listDealSlice from "../components/Deals/listDealSlice";
import listDealSliceByIds from "../components/Deals/listDealSliceByIds";
import recoverDealSlice from "../components/Deals/recoverDealSlice";
import sendEmailSlice from "../components/Deals/sendEmailSlice";
import vinCheckSlice from "../components/Deals/vinCheckSlice";
import addDmvSlice from "../components/Dmv/addDmvSlice";
import deleteDmvSlice from "../components/Dmv/deleteDmvSlice";
import editDmvSlice from "../components/Dmv/editDmvSlice";
import listDmvSlice from "../components/Dmv/listDmvSlice";
import recoverDmvSlice from "../components/Dmv/recoverDmvSlice";
import addDocumentTemplateSlice from "../components/DocumentTemplates/addDocumentTemplateSlice";
import deleteDocumentTemplateSlice from "../components/DocumentTemplates/deleteDocumentTemplateSlice";
import editDocumentTemplateSlice from "../components/DocumentTemplates/editDocumentTemplateSlice";
import listDocumentTemplateSlice from "../components/DocumentTemplates/listDocumentTemplateSlice";
import recoverDocumentTemplateSlice from "../components/DocumentTemplates/recoverDocumentTemplateSlice";
import drawerSlice from "../components/Drawer/drawerSlice";
import contractVoidSlice from "../components/ExpressApi/Deal/contractVoidSlice";
import getProductPreRatesSlice from "../components/ExpressApi/Deal/getProductPreRatesSlice";
import getProductRatesSlice from "../components/ExpressApi/Deal/getProductRatesSlice";
import listProductsSlice from "../components/ExpressApi/Deal/listProductsSlice";
import signContractSlice from "../components/ExpressApi/Deal/signContractSlice";
import addExternalApplicationDealSlice from "../components/ExternalCreditApplications/addExternalApplicationDealSlice";
import addExternalCreditApplicationSlice from "../components/ExternalCreditApplications/addExternalCreditApplicationSlice";
import deleteExternalCreditApplicationSlice from "../components/ExternalCreditApplications/deleteExternalCreditApplicationSlice";
import editExternalCreditApplicationSlice from "../components/ExternalCreditApplications/editExternalCreditApplicationSlice";
import listExternalCreditApplicationSlice from "../components/ExternalCreditApplications/listExternalCreditApplicationSlice";
import recoverExternalCreditApplicationSlice from "../components/ExternalCreditApplications/recoverExternalCreditApplicationSlice";
import addFileSlice from "../components/Files/addFileSlice";
import deleteFileSlice from "../components/Files/deleteFileSlice";
import editFileSlice from "../components/Files/editFileSlice";
import listFileSlice from "../components/Files/listFileSlice";
import recoverFileSlice from "../components/Files/recoverFileSlice";
import listFloorplanXpressSlice from "../components/FloorplanXpress/listFloorplanXpressSlice";
import addFundingDocumentSlice from "../components/FundingDocuments/addFundingDocumentSlice";
import deleteFundingDocumentSlice from "../components/FundingDocuments/deleteFundingDocumentSlice";
import editFundingDocumentSlice from "../components/FundingDocuments/editFundingDocumentSlice";
import listFundingDocumentSlice from "../components/FundingDocuments/listFundingDocumentSlice";
import recoverFundingDocumentSlice from "../components/FundingDocuments/recoverFundingDoucmentSlice";
import listHistoriesSlice from "../components/Histories/listHistoriesSlice";
import versionSlice from "../components/Layout/VersionNotification";
import addLenderDecisionSlice from "../components/LenderDecisions/addLenderDecisionsSlice";
import deleteLenderDecisionSlice from "../components/LenderDecisions/deleteLenderDecisionSlice";
import editLenderDecisionSlice from "../components/LenderDecisions/editLenderDecisionSlice";
import listLenderDecisionSlice from "../components/LenderDecisions/listLenderDecisionsSlice";
import recoverLenderDecisionSlice from "../components/LenderDecisions/recoverLenderDecisionSlice";
import addLenderSlice from "../components/Lenders/addLenderSlice";
import deleteLenderSlice from "../components/Lenders/deleteLenderSlice";
import editLenderSlice from "../components/Lenders/editLenderSlice";
import listLenderSlice from "../components/Lenders/listLenderSlice";
import recoverLenderSlice from "../components/Lenders/recoverLenderSlice";
import socketStatusSlice from "../components/Loader/SocketConnectionStatus";
import loaderSlice from "../components/Loader/loaderSlice";
import addOtherVendorSlice from "../components/OtherVendors/addOtherVendorSlice";
import deleteOtherVendorSlice from "../components/OtherVendors/deleteOtherVendorsSlice";
import editOtherVendorSlice from "../components/OtherVendors/editOtherVendorSlice";
import listOtherVendorSlice from "../components/OtherVendors/listOtheVendorsSlice";
import recoverOtherVendorSlice from "../components/OtherVendors/recoverOtherVendoSlicer";
import addPayoffBankSlice from "../components/PayoffBanks/addPayoffBankSlice";
import deletePayoffBankSlice from "../components/PayoffBanks/deletePayoffBankSlice";
import editPayoffBankSlice from "../components/PayoffBanks/editPayoffBankSlice";
import listPayoffBankSlice from "../components/PayoffBanks/listPayoffBankSlice";
import recoverPayoffBankSlice from "../components/PayoffBanks/recoverPayoffBankSlice";
import addBusinessReportSlice from "../components/Reports/BusinessReports/addBussinesReportSlice";
import listBusinessReportSlice from "../components/Reports/BusinessReports/listBusinessReportsSlice";
import getReportTokenSlice from "../components/Reports/CustomReports/getReportTokenSlice";
import getIncomeReportSlice from "../components/Reports/IncomeReports/getIncomeReportSlice";
import addRoleSlice from "../components/Roles/addRoleSlice";
import deleteRoleSlice from "../components/Roles/deleteRoleSlice";
import editRoleSlice from "../components/Roles/editRoleSlice";
import listRoleSlice from "../components/Roles/listRoleSlice";
import recoverRoleSlice from "../components/Roles/recoverRoleSlice";
import editSequenceNumberSlice from "../components/Sequence/editSequenceNumberSlice";
import listSettingsSlice from "../components/Settings/listSettingsSlice";
import authSlice from "../components/SignIn/authSlice";
import signUpSlice from "../components/SignUp/signUpSlice";
import addStateSlice from "../components/States/addStateSlice";
import deleteStateSlice from "../components/States/deleteStateSlice";
import editStateSlice from "../components/States/editStateSlice";
import listStateSlice from "../components/States/listStateSlice";
import recoverStateSlice from "../components/States/recoverStateSlice";
import tabSlice from "../components/Tabs/tabSlice";
import deleteTitleIssueSlice from "../components/TitleIssues/deleteTitleIssueSlice";
import editTitleIssueSlice from "../components/TitleIssues/editTitleIssueSlice";
import listTitleIssueSlice from "../components/TitleIssues/listTitleIssueSlice";
import listTitleIssueSliceByIds from "../components/TitleIssues/listTitleIssueSliceByIds";
import recoverTitleIssueSlice from "../components/TitleIssues/recoverTitleIssueSlice";

import addDealershipProgramSlice from "../components/DealershipPrograms/addDealershipProgramSlice";
import deleteDealershipProgramSlice from "../components/DealershipPrograms/deleteDealershipProgramSlice";
import editDealershipProgramSlice from "../components/DealershipPrograms/editDealershipProgramSlice";
import listDealershipProgramSlice from "../components/DealershipPrograms/listDealershipProgramSlice";
import recoverDealershipProgramSlice from "../components/DealershipPrograms/recoverDealershipProgramSlice";

import addManagerProgramSlice from "../components/ManagerPrograms/addManagerProgramSlice";
import deleteManagerProgramSlice from "../components/ManagerPrograms/deleteManagerProgramSlice";
import editManagerProgramSlice from "../components/ManagerPrograms/editManagerProgramSlice";
import listManagerProgramSlice from "../components/ManagerPrograms/listManagerProgramSlice";
import recoverManagerProgramSlice from "../components/ManagerPrograms/recoverManagerProgramSlice";

import addDealershipBillsSlice from "../components/Reports/DealershipBillsReport/addDealershipBillsSlice";
import listDealershipBillsSlice from "../components/Reports/DealershipBillsReport/listDealershipBillsSlice";

import dragOrderSlice from "components/Deals/DealsLog/dealDragOrderSlice";
import listDealCommisionByStatusSlice from "../components/Dashboard/FIManager/dealCommisionByStatusSlice";
import listDealCommisionByDaysSlice from "../components/Dashboard/FIManager/dealCommissionByDaysSlice";
import listDealCountByProductsSlice from "../components/Dashboard/FIManager/dealCountByProsuctsSlice";
import listDealCountByStatusSlice from "../components/Dashboard/FIManager/dealCountByStatusSlice";
import listSalesRepDealsSlice from "../components/Dashboard/SalesRepresentative/salesRepDealsSlice";
import listDealershipStatisticsSlice from "../components/DealershipStatistics/listDealershipStatisticSlice";
import addUserSlice from "../components/Users/addUserSlice";
import deleteUnapprovedUserSlice from "../components/Users/deleteUnapprovedUserSlice";
import deleteUserSlice from "../components/Users/deleteUserSlice";
import editUserSlice from "../components/Users/editUserSlice";
import listUnapprovedUserSlice from "../components/Users/listUnapprovedUserSlice";
import listUserSlice from "../components/Users/listUserSlice";
import recoverUserSlice from "../components/Users/recoverUserSlice";
import addValidationSlice from "../components/Validations/addValidationSlice";
import deleteValidationSlice from "../components/Validations/deleteValidationSlice";
import editValidationSlice from "../components/Validations/editValidationSlice";
import listValidationsSlice from "../components/Validations/listValidationSlice";
import addVehicleInsuranceCompanySlice from "../components/VehicleInsuranceCompany/addVehicleInsuranceCompanySlice";
import deleteVehicleInsuranceCompanySlice from "../components/VehicleInsuranceCompany/deleteVehicleInsuranceCompanySlice";
import editVehicleInsuranceCompanySlice from "../components/VehicleInsuranceCompany/editVehicleInsuranceCompanySlice";
import listVehicleInsuranceCompanySlice from "../components/VehicleInsuranceCompany/listVehicleInsuranceCompanySlice";
import recoverVehicleInsuranceCompanySlice from "../components/VehicleInsuranceCompany/recoverVehicleInsuranceCompanySlice";
import entitySlice from "../utils/entitySlice";
import listLockSlice from "../utils/models/LockEntity/listLockSlice";
import lockEntitySlice from "../utils/models/LockEntity/lockEntitySlice";

import addAdditionalRequiredDocumentSlice from "components/AdditionalRequiredDocuments/addAdditionalRequiredDocumentSlice";
import deleteAdditionalRequiredDocumentSlice from "components/AdditionalRequiredDocuments/deleteAdditionalRequiredDocumentSlice";
import editAdditionalRequiredDocumentSlice from "components/AdditionalRequiredDocuments/editAdditionalRequiredDocumentSlice";
import listAdditionalRequiredDocumentSlice from "components/AdditionalRequiredDocuments/listAdditionalRequiredDocumentSlice";
import recoverAdditionalRequiredDocumentSlice from "components/AdditionalRequiredDocuments/recoverAdditionalRequiredDocumentSlice";
import addChargebackBills from "components/Chargebacks/MonthlyChargebacks/addChargebackBillsSlice";
import listChargebackBills from "components/Chargebacks/MonthlyChargebacks/listChargebackBillsSlice";
import addChargebackSlice from "components/Chargebacks/addChargebackSlice";
import deleteChargebackSlice from "components/Chargebacks/deleteChargebackSlice";
import editChargebackSlice from "components/Chargebacks/editChargebackSlice";
import listChargebackSlice from "components/Chargebacks/listChargebackSlice";
import recoverChargebackSlice from "components/Chargebacks/recoverChargebackSlice";
import recoverCreditScoreRangeSlice from "components/CreditScoreRanges/recoverCreditScoreRangeSlice";
import emailVerificationLinkSlice from "components/Dealerships/emailVerificationLinkSlice";
import generateLinkSlice from "components/Dealerships/generateLinkSlice";
import sendGeneratedLinkSlice from "components/Dealerships/sendGeneratedLinkSlice";
import sendApprovedDocumentsSlice from "components/Deals/Documents/sendApprovedDocumentsSlice";
import editOneSpanSigningSlice from "components/Deals/OneSpan/editOneSpanSigningSlice";
import recognizeFundingDocumentSlice from "components/FundingDocuments/recognizeFundingDocumentSlice";
import addLenderNoteSlice from "components/LenderTickets/LenderNotes/addLenderNoteSlice";
import deleteLenderNoteSlice from "components/LenderTickets/LenderNotes/deleteLenderNoteSlice";
import editLenderNoteSlice from "components/LenderTickets/LenderNotes/editLenderNoteSlice";
import listLenderNoteSlice from "components/LenderTickets/LenderNotes/listLenderNotesSlice";
import addLenderTicketSlice from "components/LenderTickets/addLenderTicketSlice";
import deleteLenderTicketSlice from "components/LenderTickets/deleteLenderTicketSlice";
import editLenderTIcketSlice from "components/LenderTickets/editLenderTicketSlice";
import listLenderTicketSlice from "components/LenderTickets/listLenderTicketSlice";
import recoverLenderTicketSlice from "components/LenderTickets/recoverLenderTicketSlice";
import emailVerificationLenderLinkSlice from "components/Lenders/emailVerificationLinkSlice";
import generateLenderLinkSlice from "components/Lenders/generateLinkSlice";
import sendGeneratedLenderLinkSlice from "components/Lenders/sendGeneratedLinkSlice";
import addPrintedDocumentSlice from "components/PrintedDocuments/addPrintedDocumentSlice";
import approvePrintedDocumentSlice from "components/PrintedDocuments/approvePrintedDocumentSlice";
import deletePrintedDocumentSlice from "components/PrintedDocuments/deletePrintedDocumentSlice";
import editPrintedDocumentSlice from "components/PrintedDocuments/editPrintedDocumentSlice";
import listPrintedDocumentSlice from "components/PrintedDocuments/listPrintedDocumentsSlice";
import rejectPrintedDocumentSlice from "components/PrintedDocuments/rejectPrintedDocumentSlice";
import unparsedRequiredDocumentSlice from "components/UnparsedRequiredDocuments/unparsedRequiredDocumentSlice";
import addCreditScoreRangeSlice from "../components/CreditScoreRanges/addCreditScoreRangeSlice";
import deleteCreditScoreRangeSlice from "../components/CreditScoreRanges/deleteCreditScoreRangeSlice";
import editCreditScoreRangeSlice from "../components/CreditScoreRanges/editCreditScoreRangeSlice";
import listCreditScoreRangeSlice from "../components/CreditScoreRanges/listCreditScoreRangeSlice";
import addDealerNoteSlice from "../components/DealerNotes/addDealerNoteSlice";
import deleteDealerNoteSlice from "../components/DealerNotes/deleteDealerNoteSlice";
import editDealerNoteSlice from "../components/DealerNotes/editDealerNoteSlice";
import listDealerNoteSlice from "../components/DealerNotes/listDealerNoteSlice";
import addChecklistSlice from "../components/Deals/Checklist/addChecklistSlice";
import editChecklistSlice from "../components/Deals/Checklist/editChecklistSlice";
import listChecklistSlice from "../components/Deals/Checklist/listChecklistSlice";
import generateContractForPrintSlice from "../components/Deals/Documents/generateContractForPrintSlice";
import generateContractSlice from "../components/Deals/Documents/generateContractSlice";
import resendEsignEmailSlice from "../components/Deals/OneSpan/resendEmailSlice";
import addColumnGroupSlice from "../components/GroupedTable/ColumnGroups/addColumnGroupSlice";
import deleteColumnGroupSlice from "../components/GroupedTable/ColumnGroups/deleteColumnGroupSlice";
import editColumnGroupSlice from "../components/GroupedTable/ColumnGroups/editColumnGroupSlice";
import exportColumnGroupSlice from "../components/GroupedTable/ColumnGroups/exportColumnGroupSlice";
import listColumnGroupSlice from "../components/GroupedTable/ColumnGroups/listColumnGroupSlice";
import addNoteSlice from "../components/Notes/addNoteSlice";
import deleteNoteSlice from "../components/Notes/deleteNoteSlice";
import editNoteSlice from "../components/Notes/editNoteSlice";
import listNoteSlice from "../components/Notes/listNoteSlice";
import addCustomerCareNoteSlice from "../components/CustomerCareNotes/addCustomerCareNoteSlice";
import deleteCustomerCareNoteSlice from "../components/CustomerCareNotes/deleteCustomerCareNoteSlice";
import editCustomerCareNoteSlice from "../components/CustomerCareNotes/editCustomerCareNoteSlice";
import listCustomerCareNoteSlice from "../components/CustomerCareNotes/listCustomerCareNoteSlice";
import rollbackStatusSlice from "../components/RollbackStatus/rollbackStatusSlice";
import addStipulationSlice from "../components/Stipulations/addStipulationSlice";
import deleteStipulationSlice from "../components/Stipulations/deleteStipulationSlice";
import editStipulationSlice from "../components/Stipulations/editStipulationSlice";
import listStipulationSlice from "../components/Stipulations/listStipulationsSlice";
import addTitleIssueNoteSlice from "../components/TitleIssueNotes/addTitleIssueNoteSlice";
import deleteTitleIssueNoteSlice from "../components/TitleIssueNotes/deleteTitleIssueNoteSlice";
import editTitleIssueNoteSlice from "../components/TitleIssueNotes/editTitleIssueNoteSlice";
import listTitleIssueNoteSlice from "../components/TitleIssueNotes/listTitleIssueNoteSlice";
import changeUserPinSlice from "../components/Users/changeUserPinSlice";

import editDealershipTicketSlice from "../components/DealershipTickets/editDealershipTicketSlice";
import deleteDealershipTicketSlice from "../components/DealershipTickets/deleteDealershipTicketSlice";
import listDealershipTicketSlice from "../components/DealershipTickets/listDealershipTicketSlice";
import recoverDealershipTicketSlice from "components/DealershipTickets/recoverDealershipTicketSlice";

import editDealershipTicketNoteSlice from "../components/DealershipTicketNotes/editDealershipTicketNoteSlice";
import deleteDealershipTicketNoteSlice from "../components/DealershipTicketNotes/deleteDealershipTicketNoteSlice";
import listDealershipTicketNoteSlice from "../components/DealershipTicketNotes/listDealershipTicketNoteSlice";
import addDealershipTicketNoteSlice from "components/DealershipTicketNotes/addDealershipTicketNoteSlice";

import addLenderTicketTypeSlice from "components/LenderTicketTypes/addLenderTicketTypeSlice";
import deleteLenderTicketTypeSlice from "components/LenderTicketTypes/deleteLenderTicketTypeSlice";
import editLenderTicketTypeSlice from "components/LenderTicketTypes/editLenderTicketTypeSlice";
import listLenderTicketTypeSlice from "components/LenderTicketTypes/listLenderTicketTypeSlice";
import recoverLenderTicketTypeSlice from "components/LenderTicketTypes/recoverLenderTicketTypeSlice";

import addPrescreenSlice from "components/Prescreens/addPrescreenSlice";
import deletePrescreenSlice from "components/Prescreens/deletePrescreenSlice";
import editPrescreenSlice from "components/Prescreens/editPrescreenSlice";
import listPrescreenSlice from "components/Prescreens/listPrescreenSlice";
import recoverPrescreenSlice from "components/Prescreens/recoverPrescreenSlice";

const rootReducer = combineReducers({
  addCustomerCareNoteSlice,
  editCustomerCareNoteSlice,
  deleteCustomerCareNoteSlice,
  listCustomerCareNoteSlice,
  editDealershipTicketNoteSlice,
  deleteDealershipTicketNoteSlice,
  listDealershipTicketNoteSlice,
  addDealershipTicketNoteSlice,
  editDealershipTicketSlice,
  listDealershipTicketSlice,
  recoverDealershipTicketSlice,
  deleteDealershipTicketSlice,
  listColumnGroupSlice,
  editColumnGroupSlice,
  deleteColumnGroupSlice,
  addColumnGroupSlice,
  exportColumnGroupSlice,
  addExternalApplicationDealSlice,
  addDealerNoteSlice,
  editDealerNoteSlice,
  listDealerNoteSlice,
  deleteDealerNoteSlice,
  addTitleIssueNoteSlice,
  editTitleIssueNoteSlice,
  listTitleIssueNoteSlice,
  deleteTitleIssueNoteSlice,
  addNoteSlice,
  editNoteSlice,
  listNoteSlice,
  deleteNoteSlice,
  addStipulationSlice,
  editStipulationSlice,
  listStipulationSlice,
  deleteStipulationSlice,
  resendEsignEmailSlice,
  addChecklistSlice,
  editChecklistSlice,
  listChecklistSlice,
  listAdditionalRequiredDocumentSlice,
  editAdditionalRequiredDocumentSlice,
  addAdditionalRequiredDocumentSlice,
  recoverAdditionalRequiredDocumentSlice,
  deleteAdditionalRequiredDocumentSlice,
  changeUserPinSlice,
  generateContractSlice,
  generateContractForPrintSlice,
  rollbackStatusSlice,
  addDealershipBillsSlice,
  listDealershipBillsSlice,
  addContractSlice,
  editContractSlice,
  listContractSlice,
  recoverContractSlice,
  deleteContractSlice,
  versionSlice,
  drawerSlice,
  loaderSlice,
  tabSlice,
  authSlice,
  signUpSlice,
  addDealershipSlice,
  listDealershipSlice,
  deleteDealershipSlice,
  editDealershipSlice,
  recoverDealershipSlice,
  addUserSlice,
  deleteUserSlice,
  editUserSlice,
  listUserSlice,
  recoverUserSlice,
  listUnapprovedUserSlice,
  addLenderSlice,
  listLenderSlice,
  editLenderSlice,
  deleteLenderSlice,
  recoverLenderSlice,
  addRoleSlice,
  listRoleSlice,
  deleteRoleSlice,
  editRoleSlice,
  recoverRoleSlice,
  addApplicantSlice,
  listApplicantSlice,
  deleteApplicantSlice,
  editApplicantSlice,
  recoverApplicantSlice,
  entitySlice,
  listTitleIssueSlice,
  editTitleIssueSlice,
  deleteTitleIssueSlice,
  recoverTitleIssueSlice,
  addDealershipProgramSlice,
  deleteDealershipProgramSlice,
  editDealershipProgramSlice,
  listDealershipProgramSlice,
  recoverDealershipProgramSlice,
  addManagerProgramSlice,
  deleteManagerProgramSlice,
  editManagerProgramSlice,
  listManagerProgramSlice,
  recoverManagerProgramSlice,
  getASCRates,
  sumibtASCInsurance,
  deleteASCInsurance,
  listStateSlice,
  addStateSlice,
  editStateSlice,
  deleteStateSlice,
  recoverStateSlice,
  addDmvSlice,
  listDmvSlice,
  deleteDmvSlice,
  editDmvSlice,
  recoverDmvSlice,
  addCustomReportSlice,
  listCustomReportSlice,
  deleteCustomReportSlice,
  editCustomReportSlice,
  recoverCustomReportSlice,
  addDealFromParsedDocumentSlice,
  addDealSlice,
  listDealSlice,
  listDealSliceByIds,
  listTitleIssueSliceByIds,
  deleteDealSlice,
  editDealSlice,
  recoverDealSlice,
  getIncomeReportSlice,
  sendEmailSlice,
  vinCheckSlice,
  listDocumentTemplateSlice,
  addDocumentTemplateSlice,
  editDocumentTemplateSlice,
  deleteDocumentTemplateSlice,
  recoverDocumentTemplateSlice,
  listPayoffBankSlice,
  editPayoffBankSlice,
  addPayoffBankSlice,
  recoverPayoffBankSlice,
  deletePayoffBankSlice,
  listOtherVendorSlice,
  addOtherVendorSlice,
  editOtherVendorSlice,
  deleteOtherVendorSlice,
  recoverOtherVendorSlice,
  checkCreditScoreSlice,
  addAppOneDealSlice,
  listFileSlice,
  addFileSlice,
  editFileSlice,
  deleteFileSlice,
  recoverFileSlice,
  listFundingDocumentSlice,
  addFundingDocumentSlice,
  editFundingDocumentSlice,
  deleteFundingDocumentSlice,
  recoverFundingDocumentSlice,
  dashboardSlice,
  listCreditScoreSlice,
  listExternalCreditApplicationSlice,
  addExternalCreditApplicationSlice,
  editExternalCreditApplicationSlice,
  deleteExternalCreditApplicationSlice,
  recoverExternalCreditApplicationSlice,
  listValidationsSlice,
  addValidationSlice,
  editValidationSlice,
  deleteValidationSlice,
  listVehicleInsuranceCompanySlice,
  addVehicleInsuranceCompanySlice,
  editVehicleInsuranceCompanySlice,
  deleteVehicleInsuranceCompanySlice,
  recoverVehicleInsuranceCompanySlice,
  deleteUnapprovedUserSlice,
  editSequenceNumberSlice,
  listProductsSlice,
  getProductPreRatesSlice,
  getProductRatesSlice,
  signContractSlice,
  contractVoidSlice,
  addDealerTrackSlice,
  listHistoriesSlice,
  changedCounterSlice,
  lockEntitySlice,
  listLockSlice,
  getDealerDetailsSlice,
  getRVRatesSlice,
  sumbitRVContractSlice,
  deleteRVContract,
  socketStatusSlice,
  dealOrderSlice,
  dragOrderSlice,
  getReportTokenSlice,
  listFloorplanXpressSlice,
  listNotificationSlice,
  editNotificationSlice,
  removeNotificationSlice,
  removeAllNotificationsSlice,
  addDeliveredDealSlice,
  addLenderDecisionSlice,
  addManyLenderDecisions,
  listLenderDecisionSlice,
  deleteLenderDecisionSlice,
  editLenderDecisionSlice,
  recoverLenderDecisionSlice,
  addContractTypeSlice,
  editContractTypeSlice,
  listContractTypeSlice,
  recoverContractTypeSlice,
  deleteContractTypeSlice,
  listApiStatusSlice,
  editSettingsSlice,
  listSettingsSlice,
  addBusinessReportSlice,
  listBusinessReportSlice,
  deleteBusinessReportSlice,
  recoverBusinesReportSlice,
  listTableSettingsSlice,
  editTableSettingsSlice,
  addTableSettingsSlice,
  createInvoiceAndVendorBillSlice,
  listDealershipStatisticsSlice,
  listDealCountByStatusSlice,
  listDealCountByProductsSlice,
  listDealCommisionByStatusSlice,
  listDealCommisionByDaysSlice,
  listSalesRepDealsSlice,
  deletePrintedDocumentSlice,
  unparsedRequiredDocumentSlice,
  addPrintedDocumentSlice,
  listPrintedDocumentSlice,
  approvePrintedDocumentSlice,
  rejectPrintedDocumentSlice,
  editPrintedDocumentSlice,
  recognizeFundingDocumentSlice,
  editOneSpanSigningSlice,
  generateLinkSlice,
  emailVerificationLinkSlice,
  sendGeneratedLinkSlice,
  sendApprovedDocumentsSlice,
  generateLenderLinkSlice,
  emailVerificationLenderLinkSlice,
  sendGeneratedLenderLinkSlice,
  listLenderTicketSlice,
  editLenderTIcketSlice,
  deleteLenderTicketSlice,
  recoverLenderTicketSlice,
  addLenderTicketSlice,
  addLenderNoteSlice,
  editLenderNoteSlice,
  listLenderNoteSlice,
  deleteLenderNoteSlice,
  addChargebackSlice,
  editChargebackSlice,
  listChargebackSlice,
  deleteChargebackSlice,
  recoverChargebackSlice,
  addChargebackBills,
  listChargebackBills,
  addCreditScoreRangeSlice,
  deleteCreditScoreRangeSlice,
  editCreditScoreRangeSlice,
  listCreditScoreRangeSlice,
  recoverCreditScoreRangeSlice,
  addLenderTicketTypeSlice,
  deleteLenderTicketTypeSlice,
  editLenderTicketTypeSlice,
  listLenderTicketTypeSlice,
  recoverLenderTicketTypeSlice,
  addPrescreenSlice,
  deletePrescreenSlice,
  editPrescreenSlice,
  listPrescreenSlice,
  recoverPrescreenSlice
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
