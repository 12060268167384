import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";
import { statusToChipTooltip } from "components/Deals/Deals";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import UserFilter from "components/Filters/UserFilter";
import { TabContext } from "components/Layout/LayoutWrapper";
import { DashboardType } from "components/Users/types";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModel } from "utils/entitySlice";
import { capitalize, formatNumberAsCurrency, truncate } from "utils/functions";
import { fillDefaultsByPath, generateDefault } from "utils/models/formGenerator";
import { lockEntityFunction } from "utils/models/ShowForm";
import { v4 as uuidv4 } from "uuid";
import AccessControl from "../Access/AccessControl";
import { addDeal } from "../Deals/addDealSlice";
import {
  calcReserveCommission,
  calcSplitPercentage,
  calculate,
  calcWfdProfit
} from "../Deals/Calculations";
import { deleteDeal } from "../Deals/deleteDealSlice";
import { getDealList } from "../Deals/listDealSlice";
import { recoverDeal } from "../Deals/recoverDealSlice";
import { Deal, DealData, DealStatus, collateralTypes } from "../Deals/types";
import ApplicantFilter from "../Filters/ApplicantFilter";
import DateFilter from "../Filters/DateFilter";
import DealershipFilter from "../Filters/DealershipFilter";
import LenderFilter from "../Filters/LenderFilter";
import PriceFilter from "../Filters/PriceFilter";
import TextFilter from "../Filters/TextFilter";
import Table, { CellValue, Column } from "../Table";
import { defaultStatuses } from "./common";
import Big from "big.js";
import { Chip } from "@material-ui/core";
import md5 from "md5";

export const projections = {
  "data.info.type": 1,
  "data.info.accounting": 1,
  "data.info.aftermarketOptions": 1,
  "data.applicant.data.info.firstName": 1,
  "data.applicant.data.info.lastName": 1,
  "data.coApplicant.data.info.firstName": 1,
  "data.coApplicant.data.info.lastName": 1,
  "data.info.status": 1,
  "data.notes": 1,
  "data.info.refNumber": 1,
  "data.dealership.data.info.displayName": 1,
  "data.dealership.data.representative.data.info.firstName": 1,
  "data.dealership.data.representative.data.info.lastName": 1,
  "data.info.vehicle.make": 1,
  "data.info.vehicle.model": 1,
  "data.info.vehicle.VIN": 1,
  "data.lender.data.info.name": 1,
  "data.user.data.info.firstName": 1,
  "data.user.data.info.lastName": 1,
  "data.info.creditScores": 1,
  "data.userId": 1,
  "data.user._id": 1,
  "data.info.payment": 1,
  "data.info.dealDates": 1,
  "data.info.profit": 1,
  "data.info.taxesAndFees": 1,
  "data.order": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};

const calculateProfit = (deal: Deal) => {
  deal = calcSplitPercentage(deal, deal);
  deal = calcReserveCommission(deal);
  deal = calcWfdProfit(deal);
  return deal;
};

const addEntityFunction = (dispatch: Dispatch<any>, requestId: string) => () => {
  const defaultState: Deal = generateDefault(
    getModel("deal") as any,
    {},
    fillDefaultsByPath as any
  ) as Deal;
  const newDeal: Deal = calculate(defaultState, defaultState);
  dispatch(
    addDeal({
      requestId,
      ...newDeal
    })
  );
};
const getChipBackgroundByStatus = (status: DealStatus) => {
  switch (status) {
    case DealStatus.SentToDealer:
      return "#eed238";
    case DealStatus.SubmittedForFunding:
      return "#33b2df";
    case DealStatus.Funded:
      return "#52a543";
    case DealStatus.FundingHeld:
      return "#ffa500";
    default:
      return "none";
  }
};
export default function ({
  from,
  setResetStickyCallback,
  to,
  type,
  userIds,
  tableName,
  showNumbers
}: {
  from: Date;
  setResetStickyCallback: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
  to: Date;
  type: DashboardType;
  tableName: string;
  userIds: string[];
  showNumbers: boolean;
}): JSX.Element {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const addDealState = useSelector((state: RootState) => state.addDealSlice[requestId]);
  const createOrFocusTab = useContext(TabContext);
  useEffect(() => {
    if (
      addDealState !== undefined &&
      addDealState.status === "success" &&
      addDealState.data !== null
    ) {
      lockEntityFunction(dispatch, addDealState.data.message._id, "deal", requestId);
      createOrFocusTab({
        label: "Show page",
        index: "showPage",
        isForSidebar: false,
        isForQuickAccess: false,
        isPersistent: false,
        props: {
          _id: addDealState.data.message._id,
          type: "deal"
        }
      });
    }
  }, [addDealState, createOrFocusTab, dispatch, requestId]);

  const columns: Column<DealData>[] = [
    {
      getData: (entry): CellValue => entry.data?.info?.refNumber,
      label: "Ref #",
      options: {
        sort: true,
        path: ["data", "info", "refNumber"]
      },
      name: "refNumber",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.refNumber?.show;
      },
      filters: [
        {
          path: ["data", "info", "refNumber"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => {
        switch (entry.data?.info?.type) {
          case "Recreational Vehicles":
            return "RV";
          case "Automotive":
            return "Auto";
          case "Marine":
            return "Marine";
          case "Power Sport":
            return "Power";

          default:
            return "";
        }
      },
      label: "Type",
      truncate: 15,
      name: "type",
      show: (userPermissions, tableSettings) => {
        return true;
      },
      filters: [
        {
          path: ["data", "info", "type"],
          preview: MultiSelectFilter,
          valuesForSelect: [...collateralTypes],
          optionLabelForSelect: (status) => capitalize(status)
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        [
          entry.data?.applicant
            ? `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
                entry.data?.applicant?.data?.info?.lastName ?? ""
              }`
            : undefined,
          entry.data?.coApplicant
            ? `${entry.data?.coApplicant?.data?.info?.firstName ?? ""} ${
                entry.data?.coApplicant?.data?.info?.lastName ?? ""
              }`
            : undefined
        ]
          .filter((x) => x)
          .join(" / "),
      truncate: 30,
      label: "Customer",
      name: "customer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.customer?.show;
      },
      filters: [
        {
          path: [
            ["data", "applicant", "data", "info", "firstName"],
            ["data", "applicant", "data", "info", "lastName"],
            ["data", "coApplicant", "data", "info", "firstName"],
            ["data", "coApplicant", "data", "info", "lastName"]
          ],
          preview: ApplicantFilter
        }
      ]
    },

    {
      getData: (entry): CellValue => entry.data?.dealership?.data?.info?.displayName,
      label: "Dealer",
      truncate: 15,
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      },
      filters: [
        {
          path: ["data", "dealershipId"],
          preview: DealershipFilter
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.info?.vehicle?.make} ${entry.data?.info?.vehicle?.model}`,
      truncate: 10,
      label: "Vehicle",
      name: "make",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.make?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "make"],
          preview: TextFilter
        },
        {
          path: ["data", "info", "vehicle", "model"],
          preview: TextFilter
        }
      ]
    },

    {
      getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
      truncate: 15,
      label: "Lender",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      },
      filters: [
        {
          path: ["data", "lenderId"],
          preview: LenderFilter
        }
      ]
    },
    ...(type === "accountant"
      ? [
          {
            getData: (entry): CellValue =>
              `${entry.data?.user?.data?.info?.firstName ?? ""} ${
                entry.data?.user?.data?.info?.lastName ?? ""
              }`,
            label: "F&I M",
            name: "f&iManager",
            show: (userPermissions, tableSettings) => {
              return tableSettings?.data?.columns?.["f&iManager"]?.show;
            },
            filters: [
              {
                path: ["data", "userId"],
                preview: UserFilter,
                type: "f&i manager"
              }
            ]
          } as Column<DealData>,
          {
            getData: (entry): CellValue =>
              `${entry.data?.dealership?.data?.representative?.data?.info?.firstName ?? ""} ${
                entry.data?.dealership?.data?.representative?.data?.info?.lastName ?? ""
              }`,
            label: "Rep",
            truncate: 10,
            name: "representative",
            show: (userPermissions, tableSettings) => {
              return true;
            },
            filters: []
          } as Column<DealData>
        ]
      : []),
    {
      getData: (entry): CellValue =>
        entry.data?.info?.dealDates?.contractDate
          ? new Date(entry.data?.info?.dealDates?.contractDate)
          : "",
      label: "Cont.",
      options: {
        sort: true,
        path: ["data", "info", "dates", "contractDate"]
      },
      name: "contractDate",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.contractDate?.show;
      },
      filters: [
        {
          path: ["data", "info", "dealDates", "contractDate"],
          preview: DateFilter,
          label: "Contract Date"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.status,
      label: "Status",
      options: {
        customBodyRender: (x: CellValue, deal): JSX.Element | string => {
          const dealStatus = x as DealStatus;
          return statusToChipTooltip((truncate(dealStatus, 12) || "") as DealStatus, deal, {
            padding: 0,
            height: 15,
            marginTop: -2,
            background: getChipBackgroundByStatus(dealStatus)
          });
        },
        sort: true,
        path: ["data", "info", "status"]
      },
      name: "status",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.status?.show;
      },
      filters: [
        {
          path: ["data", "info", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: defaultStatuses,
          optionLabelForSelect: (status) => capitalize(status)
        }
      ]
    },
    {
      getData: (deal): CellValue => {
        const insurances = deal.data?.info?.aftermarketOptions?.insurances ?? [];
        const hasGap = insurances?.some(
          (el) => el?.chosenRate?.insuranceType === "gap" && el?.chosenRate?.soldBy === "WFD"
        );
        const hasWarranty = insurances?.some(
          (el) =>
            el?.chosenRate?.insuranceType === "service warranty" && el?.chosenRate?.soldBy === "WFD"
        );
        return [hasGap ? "Gap" : "", hasWarranty ? "Warranty" : ""];
      },
      excelColumns: ["Gap", "Warranty"],
      label: "Prod.",
      options: {
        customBodyRender: (x: CellValue, deal): JSX.Element | string => {
          const insurances = deal.data?.info?.aftermarketOptions?.insurances ?? [];
          const hasGap = insurances.some(
            (el) => el?.chosenRate?.insuranceType === "gap" && el?.chosenRate?.soldBy === "WFD"
          );
          const hasWarranty = insurances.some(
            (el) =>
              el?.chosenRate?.insuranceType === "service warranty" &&
              el?.chosenRate?.soldBy === "WFD"
          );
          return (
            <div style={{ whiteSpace: "nowrap" }}>
              {hasGap && <Chip size="small" label="G" style={{ fontFamily: "monospace" }} />}
              {hasWarranty && (
                <Chip
                  size="small"
                  label="W"
                  style={{ backgroundColor: "#eba93f", fontFamily: "monospace" }}
                />
              )}
            </div>
          );
        },
        sort: true,
        path: ["data"]
      },
      name: "products",
      show: (userPermissions, tableSettings) => {
        return true;
      }
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.dealDates?.submittedForFunding
          ? new Date(entry.data?.info?.dealDates?.submittedForFunding)
          : "",
      label: "Submit.",
      options: {
        sort: true,
        path: ["data", "info", "dates", "submittedForFunding"]
      },
      name: "Submit.",
      show: (userPermissions, tableSettings) => {
        return true;
      },
      filters: [
        {
          path: ["data", "info", "dealDates", "submittedForFunding"],
          preview: DateFilter,
          label: "Submitted for funding"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.dealDates?.fundedAt
          ? new Date(entry.data?.info?.dealDates?.fundedAt)
          : "",
      label: "Fund.",
      options: {
        sort: true,
        path: ["data", "info", "dates", "fundedAt"]
      },
      name: "fundedAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.fundedAt?.show;
      },
      filters: [
        {
          path: ["data", "info", "dealDates", "fundedAt"],
          preview: DateFilter,
          label: "Funded At"
        }
      ]
    },
    {
      getData: (entry): CellValue => {
        return formatNumberAsCurrency(entry.data.info.profit?.wfdProfit?.totalProfit ?? 0, "$");
      },
      label: "WFD Profit",
      name: "Total WFD Profit"
    },
    {
      getData: (entry): CellValue =>
        formatNumberAsCurrency(entry.data.info.profit?.wfdProfit?.totalReserveProfit, "$") ?? 0,
      label: "Reserve p.",
      name: "Total WFD Reserve profit"
    },

    {
      getData: (entry): CellValue =>
        formatNumberAsCurrency(entry.data.info.profit?.wfdProfit?.totalProductsProfit, "$") ?? 0,
      label: "Products p.",
      name: "Total WFD Product profit"
    },
    {
      getData: (entry): CellValue =>
        formatNumberAsCurrency(
          new Big(entry.data?.info?.payment?.fundedAmount || 0).round(2, 1).toNumber(),
          "$"
        ) ?? 0,
      label: "Fund. Am.",
      name: "fundedAmount",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.fundedAmount?.show;
      },
      filters: [
        {
          path: ["data", "info", "payment", "fundedAmount"],
          preview: PriceFilter
        }
      ]
    },
    {
      getData: (entry, showHiddenValues): CellValue => {
        return (
          formatNumberAsCurrency(
            new Big(entry.data?.info?.profit?.managerProfit?.commission || 0)
              .round(2, 1)
              .toNumber(),
            "$"
          ) ?? 0
        );
      },
      bold: (entry) => entry.data?.info?.accounting?.copied ?? false,
      label: "Commis.",
      name: "commission",
      show: (userPermissions, tableSettings) => {
        return true;
      }
      // filters: [
      //   {
      //     path: ["data", "info", "profit", "managerProfit", "commission"],
      //     preview: PriceFilter
      //   }
      // ]
    },

    {
      getData: (entry): CellValue => entry._id,
      label: "Actions",
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];
  const query = {
    "data.info.status": { $in: defaultStatuses },
    $or: [
      {
        $and: [
          {
            "data.info.dealDates.contractDate": {
              $gte: from.toISOString(),
              $lt: to.toISOString()
            }
          },
          {
            $or: [
              {
                "data.info.dealDates.submittedForFunding": {
                  $exists: false
                }
              },
              {
                "data.info.dealDates.submittedForFunding": {
                  $eq: null
                }
              }
            ]
          }
        ]
      },
      {
        "data.info.dealDates.submittedForFunding": {
          $gte: from.toISOString(),
          $lt: to.toISOString()
        }
      }
    ],
    "data.user._id": { $in: userIds }
  };
  const queryHash = md5(JSON.stringify(query));
  const slice = `${queryHash}-${type}-Deals`;
  const dealList = useSelector((state: RootState) => state.listDealSlice[slice]);
  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={{ entity: "deal", action: "read" }}>
        <Table
          setResetStickyCallback={setResetStickyCallback}
          showHiddenValues={showNumbers}
          toolbarStyle={{ minHeight: "48px" }}
          tableName={tableName}
          customActionsStyle={{ height: 10, marginTop: "-10px", transform: "scale(0.7)" }}
          entityName="deal"
          listFunction={getDealList}
          listEntity={{
            // @ts-ignore
            entities:
              dealList?.entities?.map(
                (deal: Deal): Deal => {
                  deal = calculateProfit(deal);
                  return {
                    ...deal,
                    data: {
                      ...deal.data,
                      info: {
                        ...deal.data.info,
                        profit: {
                          ...(deal?.data?.info?.profit ?? {}),
                          managerProfit: {
                            commission: showNumbers
                              ? deal?.data?.info?.profit?.managerProfit?.commission || 0
                              : 0,
                            commissionableAmount:
                              deal?.data?.info?.profit?.managerProfit?.commissionableAmount || 0
                          }
                        }
                      }
                    }
                  };
                }
              ) ?? [],
            total: dealList?.total || 0,
            status: dealList?.status
          }}
          rowsPerPage={50}
          deleteEntityFunction={deleteDeal}
          addEntityFunction={addEntityFunction(dispatch, requestId)}
          recoverEntityFunction={recoverDeal}
          sort={{ createdAt: "desc" }}
          slice={slice}
          title={"My deals"}
          columns={columns}
          customCellFontSize="12px"
          query={query}
          projection={projections}
        />
      </AccessControl>
    </div>
  );
}
