import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { auth } from "../../firebase/firebase";
import {
  LenderAllocationStatisticRequest,
  LenderAllocationYearlyStatisticsResponse
} from "./types";

export const getLenderAllocationYearlyStatistics = (
  request: LenderAllocationStatisticRequest
): (() => Promise<LenderAllocationYearlyStatisticsResponse>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/lender_allocation_statistics_yearly`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    );

    return response.data.result;
  };
};

const useLenderAllocationYearlyStatistics = (
  request: LenderAllocationStatisticRequest,
  enabled = true
) => {
  const queryKey = ["lender_allocation_statistics", request];

  const { data, isLoading, isSuccess, error } = useQuery({
    queryKey: queryKey,
    queryFn: getLenderAllocationYearlyStatistics(request),
    enabled: enabled && request.lenderId && request.year ? true : false
  });

  return {
    data,
    isSuccess,
    isLoading,
    error
  };
};

export default useLenderAllocationYearlyStatistics;
