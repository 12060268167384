import { Entry } from "components/Table";
import { OptionsForSelect, Path } from "utils/models/fields";
import { StateAccess } from "utils/models/formGenerator";

export interface ColumnFilter<T extends unknown> {
  path: string[][] | string[];
  partialSearch?: boolean;
  valuesForSelect?: any[];
  valueToString?: (x: any) => string;
  optionLabelForSelect?: (x: any) => any;
  caseInsensitive?: boolean;
  options?: OptionsForSelect<T>;
  preview: (props: FilterProps<T>) => JSX.Element;
  type?: string;
  filterType?: string;
  name?: string;
  label?: string;
  textFilterType?: "text" | "number" | "phone" | "email";
}
export type DateFilters<T> = { label: string; path: Path<Entry<T>> }[];
export type RelationsFilters<T> = {
  path: Path<Entry<T>>;
  preview: (props: FilterProps<T>) => JSX.Element;
}[];
export type ExistFilter<T> = {
  path: Path<Entry<T>>;
  preview: (props: FilterProps<T>) => JSX.Element;
  name?: string;
  label?: string;
};
export type ExistFilters<T> = {
  filters: ExistFilter<T>[];
  name: string;
};
export type FilterQuery = {
  [key in string]: string | object;
}[];
export interface FilterProps<T> {
  caseInsensitive?: boolean;
  partialSearch?: boolean;
  setFilter: React.Dispatch<any>;
  filter?: Filters[string][number];
  valueToString?: (x: any) => string;
  path: string[] | string[][];
  type?: string;
  filterType?: string;
  valuesForSelect?: any[];
  closeFilter?: () => void;
  optionLabelForSelect?: (x: T) => any;
  label?: string;
}

export function hasMultiplePaths<T>(paths: any[]): paths is string[][] {
  return paths.every((path) => Array.isArray(path));
}
interface Filter {
  values: any;
  query: Record<any, any>;
}

export const filterStateAccess = (
  filter: Filter | undefined,
  setFilter: any,
  queryCallback: (values: any) => any
): StateAccess => {
  return {
    get: () => filter?.values,
    set: (_, values): any => {
      const query = queryCallback(values);
      if (query)
        setFilter({
          values,
          query
        });
      else setFilter(undefined);
    }
  };
};
export type Filters = {
  [columnName: string]: ({ query: { [key: string]: string | object }; values: any } | undefined)[];
};
