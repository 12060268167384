import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditOtherVendorData {
  _id: string;
  updatedAt: string;
  data: FormState;
}
interface EditOtherVendorRequest {
  _id: string;
  data: FormState;
}

interface EditOtherVendorResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditOtherVendorRequest,
  EditOtherVendorResponse
>("EditOtherVendor", {
  request: "update_other_vendor"
});

export const editOtherVendorActions = socketMessageSlice.actions;
export const editOtherVendor = (request: EditOtherVendorData & Identifiable) => {
  const { updatedAt, ...rest } = request;

  return socketAction({
    ...rest,
    data: {
      ...request.data,
      info: request.data.info
    }
  });
};
export default socketMessageSlice.reducer;
