import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { Validation } from "./types";

interface EditValidationResponse {
  message: Validation;
}
interface EditValidationRequest {
  message: Validation;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditValidationRequest,
  EditValidationResponse
>("EditValidation", {
  request: "edit_document_validation"
});

export const editValidationActions = socketMessageSlice.actions;
export const editValidation = socketAction;

export default socketMessageSlice.reducer;
