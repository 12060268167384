import { getDocumentTemplateListActions } from "components/DocumentTemplates/listDocumentTemplateSlice";
import { getListProps } from "components/Middlewares/listSliceCreator";
import { transformOldTopicToAxiosRequest } from "components/Middlewares/SocketMiddleware";
import { makeHttpRequest } from "./common";

export const getDocumentTemplates = async (
  props: getListProps
): Promise<
  | {
      result: any;
      status: "success" | "error";
    }
  | undefined
> => {
  const action = getDocumentTemplateListActions("", props);
  if (action?.http) {
    const request = transformOldTopicToAxiosRequest(action.http.path, action.payload);
    //@ts-ignore
    return await makeHttpRequest(request, action);
  } else {
    return new Promise(() => undefined);
  }
};
