import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Switch,
  Theme
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import formEditContext from "components/Content/FormEditContext";
import DealView, { View } from "components/Deals/DealView";
import { accountingView } from "components/Deals/model";
import { HistoryButton } from "components/Histories/HistoryButton";
import TextLoop from "components/Loader/TextLoop";
import RolesAvailabilityDialog from "components/Roles/AvailableToRolesDialog";
import CloseTabDialog from "components/Tabs/CloseTabDialog";
import { setTabUnsavedChanges } from "components/Tabs/tabSlice";
import { useStickyState } from "index";
import { useSnackbar } from "notistack";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { collectionFromName, entitySlice, EntityType } from "../entitySlice";
import ApproveEntity from "./ApproveEntity";
import { Model } from "./fields";
import { generateForm, getByPath, setByPath, StateAccess } from "./formGenerator";
import { editRenderSet, showRenderSet } from "./formRenderers";
import { getLockList } from "./LockEntity/listLockSlice";
import { lockEntity } from "./LockEntity/lockEntitySlice";
import SubmitButton from "./SubmitButton";
interface ShowFormProps<T> {
  initialState: any;
  model: Model<T>;
  handleSubmit?: (state: any) => (event: React.FormEvent) => void;
  hideEdit?: boolean;
  tabId: string;
  editable?: boolean;
}
const theme = (theme: Theme) =>
  createTheme({
    ...theme,
    typography: {
      fontSize: 12
    }
  });
export const lockEntityFunction = (
  dispatch: Dispatch<any>,
  entityId: string,
  entityName: EntityType,
  idRequest?: string
) => {
  const requestId = idRequest ?? uuidv4();
  dispatch(
    lockEntity({
      requestId,
      data: {
        info: {
          entityId: entityId,
          entityName: entityName,
          action: "lock"
        }
      }
    })
  );
};

export const unlockEntityFunction = (
  dispatch: Dispatch<any>,
  entityId: string,
  entityName: EntityType,
  idRequest?: string
) => {
  const requestId = idRequest ?? uuidv4();
  dispatch(
    lockEntity({
      requestId,
      data: {
        info: {
          entityId: entityId,
          entityName: entityName,
          action: "unlock"
        }
      }
    })
  );
};
export const ShowForm = <T extends any>({
  initialState,
  model,
  handleSubmit,
  hideEdit,
  tabId,
  editable = true
}: ShowFormProps<T>) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state: RootState) => state.authSlice);
  const [open, setOpen] = useState(false);
  const [changeModeDialog, setChangeModeDialog] = useState(false);
  const [oldState, setOldState] = useState(initialState);
  const tabs = useSelector((state: RootState) => state.tabSlice);
  const tabEdited = tabs.find((tab) => tab._id === tabId)?.unsavedChanges ?? false;
  const [view, setView] = useStickyState<View>("normal", "dealView");

  useEffect(() => {
    if (initialState.updatedAt !== oldState.updatedAt) {
      setOldState(initialState);

      dispatch(setTabUnsavedChanges({ id: tabId, unsavedChanges: false }));
    }
  }, [initialState, oldState, tabId, dispatch]);

  const stateAccess: StateAccess = {
    get: (path) => getByPath(initialState, path),
    set: (path, value): any => {
      dispatch(setTabUnsavedChanges({ id: tabId, unsavedChanges: true }));
      dispatch({
        type: entitySlice.actions.partialUpdate.type,
        payload: { path, value, entity: model.name, _id: initialState._id }
      });
    }
  };
  const entityName = model.name;
  const entityId = stateAccess.get(["_id"]);
  const lockRequestId = `${entityName} - ${entityId}`;
  const lockEntityState = useSelector((state: RootState) => state.lockEntitySlice[lockRequestId]);

  useEffect(() => {
    if (lockEntityState?.status === "error") {
      enqueueSnackbar(lockEntityState?.message, {
        variant: "error"
      });
      setLoading(false);
    }
  }, [enqueueSnackbar, lockEntityState, tabId, dispatch]);

  const mainStateAccess: StateAccess = {
    get: (path) => getByPath(initialState, path),
    set: (path, value): any => {
      const newState = setByPath(initialState, path, value);
      dispatch(setTabUnsavedChanges({ id: tabId, unsavedChanges: true }));
      dispatch({
        type: entitySlice.actions.update.type,
        payload: { payload: newState, entity: model.name, _id: initialState._id }
      });

      return newState;
    }
  };

  const formName = `edit-${model.name}-${stateAccess.get(["_id"])}`;
  const allLocks = useSelector((state: RootState) => state.listLockSlice["all"]);
  const lockData = allLocks?.entities?.find(
    (lock) => lock?.data?.info?.entityId === entityId && lock?.data?.info?.entityName === entityName
  );
  const edit = user?.email === lockData?.data?.info?.userEmail;
  const [oldEdit, setOldEdit] = useState<boolean>(edit);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = () => {
    setLoading(true);
    if (edit) {
      unlockEntityFunction(dispatch, entityId, entityName, lockRequestId);
      stateAccess.set([], oldState);
      mainStateAccess.set([], oldState);
      dispatch(setTabUnsavedChanges({ id: tabId, unsavedChanges: false }));
    } else {
      lockEntityFunction(dispatch, entityId, entityName, lockRequestId);
    }
  };

  useEffect(() => {
    if (oldEdit !== edit) {
      setOldEdit(edit);
      setLoading(false);
    }
  }, [edit, setLoading, loading, oldEdit]);
  const handleClick = () => {
    lockEntityFunction(dispatch, entityId, entityName, lockRequestId);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleActionAndClose = () => {
    setChangeModeDialog(false);
    handleChange();
  };
  const auth = useSelector((state: RootState) => state?.authSlice?.user);

  return (
    <>
      <CloseTabDialog
        open={changeModeDialog}
        handleClose={() => setChangeModeDialog(false)}
        currentTabId={tabId}
        handleActionAndClose={handleActionAndClose}
        dialogText={"You have unsaved changes. Are you sure you want to exit edit mode?"}
      />
      <Dialog
        id="confirm-delete-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to unlock this {entityName}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {lockData &&
              `Locked by ${lockData?.data?.info?.userEmail}  
           ${new Intl.DateTimeFormat("en", {
             day: "2-digit",
             month: "2-digit",
             year: "2-digit",
             hour: "2-digit",
             minute: "2-digit",
             second: "2-digit"
           }).format(new Date(lockData?.updatedAt))}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary" id="cancel">
            Cancel
          </Button>
          <Button
            onClick={handleClick}
            variant="contained"
            color="secondary"
            autoFocus
            id="confirm"
          >
            Unlock
          </Button>
        </DialogActions>
      </Dialog>
      <ThemeProvider theme={theme}>
        <formEditContext.Provider
          value={{ enabled: edit, edited: tabEdited, initialState: oldState, auth }}
        >
          <form
            id={formName}
            data-id={initialState._id}
            autoComplete="off"
            onSubmit={handleSubmit ? handleSubmit(initialState) : undefined}
            data-editable={edit}
          >
            <Paper elevation={3} style={{ padding: "10px 20px", minWidth: 1600 }}>
              <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                {entityName === "deal" && <DealView user={user} view={view} setView={setView} />}
                {edit && (
                  <>
                    <FormGroup>
                      <ApproveEntity state={initialState} entityName={entityName} />
                    </FormGroup>
                    <FormGroup>
                      <RolesAvailabilityDialog stateAccess={stateAccess} entityName={entityName} />
                    </FormGroup>
                  </>
                )}
                <AccessControl requiredPermissions={[{ entity: "history", action: "read" }]}>
                  <FormGroup style={{ marginRight: "10px" }}>
                    <HistoryButton
                      stateAccess={stateAccess}
                      _id={stateAccess.get(["_id"])}
                      collection={collectionFromName(model.name)}
                    />
                  </FormGroup>
                </AccessControl>
                <FormGroup>
                  <AccessControl
                    requiredPermissions={[
                      { entity: entityName, action: "update" },
                      { entity: "lock", action: "update" }
                    ]}
                  >
                    {!hideEdit ? (
                      !lockData?.data?.info?.userEmail ||
                      user?.email === lockData?.data?.info?.userEmail ? (
                        loading ? (
                          <TextLoop
                            text=""
                            style={{
                              background: "rgba(0 ,0 , 0 , 0.01)",
                              width: "90px",
                              marginTop: "10px",
                              height: "100%",
                              textAlign: "center",
                              color: "#000"
                            }}
                          />
                        ) : (
                          <FormControlLabel
                            id={`toggle-${model.name}-edit`}
                            control={
                              <Switch
                                id={`toggle-${model.name}-edit-input`}
                                value={edit}
                                checked={edit}
                                onChange={() => {
                                  tabEdited && edit ? setChangeModeDialog(true) : handleChange();
                                }}
                              />
                            }
                            label="Edit"
                          />
                        )
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={handleOpen}
                          endIcon={<LockOpenIcon />}
                        >
                          {lockData?.data?.info?.userEmail}
                        </Button>
                      )
                    ) : (
                      <></>
                    )}
                  </AccessControl>
                </FormGroup>
              </Grid>
              <div>
                {!edit || !editable
                  ? generateForm(
                      entityName === "deal" && view === "accounting" ? accountingView : model,
                      stateAccess,
                      [],
                      mainStateAccess,
                      showRenderSet(false),
                      auth?.permissions?.[model.name],
                      {
                        enabled: edit,
                        edited: tabEdited,
                        initialState: oldState,
                        auth
                      },
                      auth?.databaseData
                    )
                  : generateForm(
                      entityName === "deal" && view === "accounting" ? accountingView : model,
                      stateAccess,
                      [],
                      mainStateAccess,
                      editRenderSet(
                        model.name === "netsuite"
                          ? "sales_order_to_invoice_and_vendor_bill"
                          : `update_${model.name}`
                      ),
                      auth?.permissions?.[model.name],
                      {
                        enabled: edit,
                        edited: tabEdited,
                        initialState: oldState,
                        auth
                      },
                      auth?.databaseData
                    )}
                {edit ? (
                  <AccessControl requiredPermissions={{ entity: entityName, action: "update" }}>
                    <SubmitButton
                      disabled={!tabEdited || loading}
                      formName={formName}
                      stateAccess={stateAccess}
                    />
                  </AccessControl>
                ) : null}
              </div>
            </Paper>
          </form>
        </formEditContext.Provider>
      </ThemeProvider>
    </>
  );
};
