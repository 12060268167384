import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteStateRequest {
  _id: string;
}

interface DeleteStateResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteStateRequest,
  DeleteStateResponse
>("DeleteState", {
  request: "delete_state"
});

export const deleteStateActions = socketMessageSlice.actions;
export const deleteState = socketAction;
export default socketMessageSlice.reducer;
