import {
  multyPermissionNode,
  permissionNode,
  PermissionStruct,
  relationPermissionNode
} from "components/Roles/types";
import { Note } from "./types";

export type NotePermissionsStruct = PermissionStruct<Note>;

export const notePermissionStruct: NotePermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      note: permissionNode("Note"),
      type: permissionNode("Type"),
      user: {
        firstName: permissionNode("User First name"),
        lastName: permissionNode("User Last name"),
        email: permissionNode("User Email")
      }
    },
    dealId: permissionNode("Deal Id"),
    lenderTicketId: permissionNode("Lender Ticket Id"),
    dealershipTicketId: permissionNode("Dealership Ticket Id")
  }
};
