import infoProperties from "./info_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

const data = {
  type: "object",
  required: ["info"],
  properties: {
    info: {
      type: "object",
      required: ["name", "taxableItems", "dealTypes"],
      properties: infoProperties,
    },
  },
};
export default {
  new_state: {
    $id: "newState",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_state: {
    $id: "updateState",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  delete_state: {
    $id: "deleteState",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  recover_state: {
    $id: "recoverState",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  get_state: {
    $id: "getState",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  all_states: {
    $id: "allStates",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
