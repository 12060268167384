import React from "react";
import { StateAccess } from "utils/models/formGenerator";
import { createOptionsForSelect, FormComponent } from "../../utils/models/fields";
import ASCSurcharges from "./ASCSurcharges";
import { ASCInsurance, ASCPreRatesData, SurchargeName } from "./types";
import store from "../../app/store";

export const onChangeSurchargeFunction = (stateAccess: StateAccess) => (
  surchargeName: SurchargeName
) => {
  const ascExtraProfit =
    store?.getState()?.listSettingsSlice?.settingsData?.entities?.[0]?.data?.info.ascWarranty ?? 0;
  const insurance: ASCInsurance = stateAccess.get<ASCInsurance>([] as any);
  const originalRate = insurance?.originalRate;
  const surcharges = insurance?.chosenRate?.surcharges?.map((s) =>
    s.name === surchargeName ? { ...s, checked: s.checked ? false : true } : s
  );
  const totalSurchargesPrice =
    surcharges?.reduce((acc, curr) => (curr.checked ? parseFloat(curr.value) + acc : acc), 0) || 0;
  const totalSurchargesCost =
    surcharges?.reduce((acc, curr) => (curr.checked ? parseFloat(curr.value) + acc : acc), 0) || 0;

  const totalCost = parseFloat(originalRate?.TotalCost ?? "");
  const retailPrice = totalCost + totalSurchargesPrice;
  const remitPrice = totalCost + totalSurchargesPrice;
  const dealerCostPrice =
    totalCost - (insurance?.chosenRate?.DRFC ?? 0) + totalSurchargesCost + ascExtraProfit;

  stateAccess.set<ASCInsurance>([] as any, {
    ...insurance,
    chosenRate: {
      ...insurance.chosenRate,
      surcharges,
      retailPrice,
      remitPrice,
      dealerCostPrice,
      profit: parseFloat((totalCost - (totalCost - (insurance?.chosenRate?.DRFC ?? 0))).toFixed(2))
    }
  });
};

export const isSurchargeChecked = (stateAccess: StateAccess) => (surchargeName: SurchargeName) => {
  const surcharges: ASCInsurance["chosenRate"]["surcharges"] = stateAccess.get<ASCInsurance>([
    "chosenRate",
    "surcharges"
  ]);
  return surcharges.some((s) => s.name === surchargeName && s.checked);
};
export const ASCStruct: FormComponent<ASCPreRatesData> = {
  formComponent: "segment",
  name: "ASC",
  width: "full",
  entities: [
    {
      formComponent: "read-only-field",
      name: "VIN",
      label: "VIN",
      width: "1/4",
      path: ["VIN"],
      required: true,
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "VehicleYear",
      label: "Vehicle year",
      width: "1/4",
      path: ["VehicleYear"],
      required: true,
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "VehicleMakeName",
      label: "Vehicle make",
      width: "1/4",
      path: ["VehicleMakeName"],
      required: true,
      default: null
    },
    {
      formComponent: "read-only-field",
      name: "VehicleModelName",
      label: "Vehicle model",
      width: "1/4",
      path: ["VehicleModelName"],
      required: true,
      default: null
    },
    {
      formComponent: "select-field",
      name: "Account#",
      label: "Account #",
      width: "1/3",
      path: ["AccountNumber"],
      options: createOptionsForSelect({
        possibleValues: () => ["39245N", "42035N"],
        getOptionLabel: (x) => (x === "39245N" ? "WEB FINANCE DIRECT" : "WEB FINANCE DIRECT 2"),
        getSelectedOption: (x, y) => x === y
      }),
      required: true,
      default: "42035N"
    },
    {
      formComponent: "currency-field",
      name: "DRFC",
      label: "DRFC",
      width: "1/3",
      path: ["DRFC"],
      default: 0
    },
    {
      formComponent: "number-field",
      name: "EngineCC",
      label: "Engine CC",
      width: "1/3",
      path: ["EngineCC"],
      default: 0
    },
    {
      formComponent: "radio-field",
      name: "ManufacturerWarranty",
      label: "Manufacturer warranty",
      width: "1/3",
      path: ["ManufacturerWarranty"],
      possibleValues: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" }
      ],
      default: "No"
    },
    {
      formComponent: "radio-field",
      name: "LiftedVehicle",
      label: "Lifted vehicle",
      width: "1/3",
      path: ["LiftedVehicle"],
      possibleValues: [
        { label: "Yes", value: "Y" },
        { label: "No", value: "N" }
      ],
      default: "N"
    },
    {
      formComponent: "radio-field",
      name: "BrandedOrSalvaged",
      label: "Branded or salvaged",
      width: "1/3",
      path: ["BrandedOrSalvaged"],
      possibleValues: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" }
      ],
      default: "No"
    }
  ]
};
export const ascInsuranceStruct: FormComponent<ASCInsurance> = {
  formComponent: "segment",
  width: "full",
  name: "Insurance info",
  entities: [
    {
      formComponent: "read-only-field",
      name: "provider",
      label: "Provider",
      path: ["provider"],
      width: "1/2"
    },
    {
      formComponent: "read-only-field",
      name: "insuranceType",
      label: "Type",
      path: ["chosenRate", "insuranceType"],
      width: "1/2"
    },
    {
      formComponent: "read-only-field",
      name: "months",
      label: "Months",
      path: ["chosenRate", "Months"],
      width: "1/2"
    },
    {
      formComponent: "read-only-number-field",
      name: "dealerCostPrice",
      label: "Dealer Cost price",
      path: ["chosenRate", "dealerCostPrice"],
      width: "1/4"
    },
    {
      formComponent: "read-only-number-field",
      name: "retailPrice",
      label: "Retail price",
      width: "1/4",
      path: ["chosenRate", "retailPrice"],
      default: null
    },
    {
      formComponent: "read-only-number-field",
      name: "remitPrice",
      label: "Remit price",
      width: "1/4",
      path: ["chosenRate", "remitPrice"]
    },
    {
      formComponent: "read-only-number-field",
      name: "profit",
      label: "Profit",
      width: "1/4",
      path: ["chosenRate", "profit"],
      default: null
    },
    {
      formComponent: "radio-field",
      name: "soldBY",
      label: "Sold by",
      width: "1/2",
      path: ["chosenRate", "soldBy"],
      required: true,
      possibleValues: [
        { label: "WFD", value: "WFD" },
        { label: "Dealer", value: "Dealer" }
      ],
      default: "null"
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
        <ASCSurcharges
          rateSurcharges={stateAccess.get<ASCInsurance>(["chosenRate", "surcharges"])}
          onChangeSurchargeFunction={onChangeSurchargeFunction(stateAccess)}
          isSurchargeChecked={isSurchargeChecked(stateAccess)}
        />
      ),
      name: "Surcharges",
      width: "full",
      path: [] as any,
      default: {}
    }
  ]
};

export const requiredFieldsASCInsurance = (stateAccess: StateAccess) => [
  {
    name: "Provider",
    value: stateAccess.get(["provider"])
  },
  {
    name: "Insurance type",
    value: stateAccess.get(["chosenRate", "insuranceType"])
  },
  {
    name: "Months",
    value: stateAccess.get(["chosenRate", "Months"])
  },

  {
    name: "Cost price",
    value: stateAccess.get(["chosenRate", "dealerCostPrice"])
  },
  {
    name: "Retail Price",
    value: stateAccess.get(["chosenRate", "retailPrice"])
  },
  {
    name: "Remit Price",
    value: stateAccess.get(["chosenRate", "remitPrice"])
  },
  {
    name: "Sold by",
    value: stateAccess.get(["chosenRate", "soldBy"])
  }
];
