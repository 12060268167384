import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Settings } from "@material-ui/icons";
import { RootState } from "app/rootReducer";
import CloseDialogButton from "components/common/CloseDialogButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormComponent } from "utils/models/fields";
import { generateForm, getByPath, setByPath, StateAccess } from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";
import { v4 as uuidv4 } from "uuid";
import { Column } from "..";
import { addTableSettings } from "./addTableSettingsSlice";
import { editTableSettings } from "./editTableSettingsSlice";
import { TableSettings } from "./types";

import { makeStyles } from "@material-ui/core/styles";
import { capitalize } from "utils/functions";
import { HintTooltip } from "components/common/HintTooltip";

type TableSettingsProps<T> = {
  columns: Column<T>[];
  tableName: string;
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 650,
    minWidth: 450,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: 0,
    paddingBottom: 0
  },
  parentListItemTypography: {
    fontSize: 16,
    fontWeight: "bold"
  },
  listItemTypography: {
    fontSize: 14
  }
}));

export function initializeColumnsSettingsData<T extends unknown>(columns: Column<T>[]) {
  return columns.reduce((acc, column) => {
    return {
      ...acc,
      data: {
        ...acc?.data,
        columns: {
          ...acc?.data?.columns,
          [column.name]: {
            show: true
          }
        }
      }
    };
  }, {} as TableSettings);
}
export const getParents = <T extends any>(columns: Column<T>[]) => {
  return Array.from(
    new Set(columns.map((c) => c.tableSettingsParent).filter((x) => x) as string[])
  ).sort();
};
export const filterColumnsByParent = <T extends any>(
  columns: Column<T>[],
  parent: string | undefined
) => {
  return columns.filter((c) => c.tableSettingsParent === parent);
};
export default <T extends unknown>({ columns, tableName }: TableSettingsProps<T>): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const listId = "table";
  const currentUser = useSelector((state: RootState) => state.authSlice.user);
  const allTableSettings = useSelector((state: RootState) => state.listTableSettingsSlice[listId])
    ?.entities;
  const tableSettings = allTableSettings?.find((setting) => setting?.data?.tableName === tableName);

  const initialState = tableSettings ?? initializeColumnsSettingsData<T>(columns);
  const [expanded, setExpanded] = useState<{ [columnName: string]: boolean } | undefined>();
  const [requestId] = useState(uuidv4());
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    if (tableSettings) setFormState(tableSettings);
  }, [tableSettings]);

  const handleSubmit = () => {
    const formStateToSend = {
      ...formState,
      data: {
        ...formState.data,
        tableName,
        userId: currentUser?.databaseData?._id
      }
    };
    if (!tableSettings) {
      dispatch(addTableSettings({ ...(formStateToSend as any), requestId }));
    } else {
      dispatch(editTableSettings({ ...(formStateToSend as any), requestId }));
    }
    setOpen(false);
  };

  const stateAccess: StateAccess = {
    get: (path) => getByPath(formState as any, path),
    set: (path, value): any => {
      setFormState((previousFormState: any) => setByPath(previousFormState as any, path, value));
    }
  };

  const columnsNoParent = filterColumnsByParent(columns, undefined);

  return (
    <>
      <HintTooltip title="Column settings">
        <IconButton onClick={() => setOpen(!open)}>
          <Settings style={{ color: tableName === "deals_log" ? "fff" : "#0000008a" }} />
        </IconButton>
      </HintTooltip>

      <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Table Settings</DialogTitle>
        <CloseDialogButton closeFunction={() => setOpen(false)} />
        <DialogContent>
          <List className={classes.root}>
            {getParents(columns).map((parent, i) => {
              return (
                <React.Fragment key={i}>
                  <ListItem
                    dense
                    button
                    onClick={() => {
                      setExpanded((prev) => ({ ...(prev ?? {}), [parent]: !prev?.[parent] }));
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={columns
                          .filter((c) => c.tableSettingsParent === parent)
                          .every((c) => stateAccess.get(["data", "columns", c.name, "show"]))}
                        indeterminate={
                          columns
                            .filter((c) => c.tableSettingsParent === parent)
                            .some((c) => stateAccess.get(["data", "columns", c.name, "show"])) &&
                          !columns
                            .filter((c) => c.tableSettingsParent === parent)
                            .every((c) => stateAccess.get(["data", "columns", c.name, "show"]))
                        }
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e, checked) => {
                          const filteredColumns = columns.filter(
                            (col) => col.tableSettingsParent === parent
                          );

                          const reducedColumns = filteredColumns.reduce((acc, col) => {
                            return {
                              ...acc,
                              [col.name]: {
                                show: checked
                              }
                            };
                          }, {} as { [name: string]: { show: boolean } });

                          stateAccess.set(["data", "columns"], {
                            ...stateAccess.get(["data", "columns"]),
                            ...reducedColumns
                          });
                        }}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={parent}
                      primary={
                        <Typography className={classes.parentListItemTypography}>
                          {capitalize(parent)}
                        </Typography>
                      }
                    />

                    {expanded?.[parent] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={expanded?.[parent]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {filterColumnsByParent(columns, parent).map((c, y) => {
                        return (
                          <ListItem key={y} dense className={classes.nested}>
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={
                                  stateAccess.get<TableSettings>([
                                    "data",
                                    "columns",
                                    c.name,
                                    "show"
                                  ]) ?? false
                                }
                                onChange={() =>
                                  stateAccess.set(
                                    ["data", "columns", c.name, "show"],
                                    !stateAccess.get<TableSettings>([
                                      "data",
                                      "columns",
                                      c.name,
                                      "show"
                                    ])
                                  )
                                }
                                tabIndex={-1}
                                disableRipple
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography className={classes.listItemTypography}>
                                  {c.label}
                                </Typography>
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            })}
            {columnsNoParent?.length > 0 && (
              <React.Fragment>
                <ListItem
                  dense
                  button
                  onClick={() => {
                    setExpanded((prev) => ({ ...(prev ?? {}), other: !prev?.other }));
                  }}
                >
                  <ListItemText
                    id={"other"}
                    primary={
                      <Typography className={classes.parentListItemTypography}>Other</Typography>
                    }
                  />

                  {expanded?.other ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={expanded?.other} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {columnsNoParent.map((c, y) => {
                      return (
                        <ListItem key={y} className={classes.nested}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={stateAccess.get<TableSettings>([
                                "data",
                                "columns",
                                c.name,
                                "show"
                              ])}
                              onChange={() =>
                                stateAccess.set(
                                  ["data", "columns", c.name, "show"],
                                  !stateAccess.get<TableSettings>([
                                    "data",
                                    "columns",
                                    c.name,
                                    "show"
                                  ])
                                )
                              }
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography className={classes.listItemTypography}>
                                {c.label}
                              </Typography>
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </React.Fragment>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(!open)} variant="contained" color="primary" id="cancel">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            autoFocus
            onClick={handleSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
