import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

type AddDmvRequest = FormState;

export interface AddDmvResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddDmvRequest,
  AddDmvResponse
>("AddDmv", {
  request: "new_dmv"
});

export const addDmvActions = socketMessageSlice.actions;
export const addDmv = socketAction;
export default socketMessageSlice.reducer;
