import { CustomRegexes } from "../../helpers";

const request = {
  type: "object",
  properties: {
    APP_MODIFIED: { type: "string", nullable: true },
    PRODUCT: { type: "string", nullable: true },
    BUREAU: { type: "string", nullable: true },
    PASS: { type: "string", nullable: true },
    PROCESS: { type: "string", nullable: true },
    NAME: { type: "string", nullable: true },
    ADDRESS: { type: "string", nullable: true },
    CITY: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.LETTERS,
      errorMessage: { pattern: "should not contain numbers" },
    },
    STATE: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.LETTERS,
      errorMessage: { pattern: "should not contain numbers" },
    },
    ZIP: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.ZIP_CODE,
      errorMessage: {
        pattern:
          "should match either of the number formats: 'XXXXX' or 'XXXXX-XXXX'",
      },
    },
    SSN: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.SSN,
      errorMessage: {
        pattern: `
        SSN cannot
        begin with 666 or 900-999,
        be 078-05-1120 or 219-09-9999,
        contain all zeroes in a group(i.e., 000-##-####, ###-00-####, or ###-##-0000)
        or contain all matching values(i.e., 000-00-0000, 111-11-1111, 222-22-2222, etc.)
      `,
      },
    },
    SPOUSE: { type: "string", nullable: true },
    SPOUSEADDRESS: { type: "string", nullable: true },
    SPOUSESTATE: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.LETTERS,
      errorMessage: { pattern: "should not contain numbers" },
    },
    SPOUSECITY: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.LETTERS,
      errorMessage: { pattern: "should not contain numbers" },
    },
    SPOUSEZIP: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.ZIP_CODE,
      errorMessage: {
        pattern:
          "should match either of the number formats: 'XXXXX' or 'XXXXX-XXXX'",
      },
    },
    SPOUSESSN: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.SSN,
      errorMessage: {
        pattern: `
      SSN cannot
      begin with 666 or 900-999,
      be 078-05-1120 or 219-09-9999,
      contain all zeroes in a group(i.e., 000-##-####, ###-00-####, or ###-##-0000)
      or contain all matching values(i.e., 000-00-0000, 111-11-1111, 222-22-2222, etc.)
    `,
      },
    },
  },
};
export default request;
