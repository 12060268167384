import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Deal,
  ExpressInsurance,
  CustomInsurance,
  additionalInsuranceTypes,
  AllInsuranceTypes,
  allInsuranceTypes
} from "components/Deals/types";
import { createOptionsForSelect } from "utils/models/fields";
import { StateAccess, RenderSet } from "utils/models/formGenerator";
import formEditContext from "components/Content/FormEditContext";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { IconButton, Typography } from "@material-ui/core";
import SignInsuranceContract from "./SignInsuranceContract";
import { DeleteInsuranceContract } from "./DeleteInsuranceContract";
import DeleteCustomInsurance from "./DeleteCustomInsurance";
import { ProtectiveInsurance } from "components/Protective/types";
import { DeleteInsurance as DeleteProtectiveInsurance } from "components/Protective/DeleteContract";
import SubmitProtectiveContract from "components/Protective/SubmitContract";
import UploadContract from "./UploadContract";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Surcharges, { handleChangeSurchargeForDeal } from "./Surcharges";
import { ASCInsurance } from "components/ASCWarranty/types";
import SubmitASCInsurance from "components/ASCWarranty/SubmitASCInsurance";
import DeleteASCInsurance from "components/ASCWarranty/DeleteASCContract";
import ASCSurcharges from "components/ASCWarranty/ASCSurcharges";
import { onChangeSurchargeFunction, isSurchargeChecked } from "components/ASCWarranty/model";
import { allProviders } from "./types";
import CalculateCustomInsuranceCost from "components/Deals/CalculateCustomInsuranceCost";

interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}

export default function DisplayInsurances({ stateAccess, renderSet }: Props) {
  const { enabled: showButton } = useContext(formEditContext);
  const insurances: (
    | ProtectiveInsurance
    | ExpressInsurance
    | ASCInsurance
    | CustomInsurance
  )[] = stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "insurances"]);

  const [insurancesState, setInsurancesState] = useState(insurances);
  const _id = stateAccess.get(["_id"]);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleRemoveContract = (insurance: CustomInsurance, index: number) => {
    stateAccess.set<Deal>(["data", "info", "aftermarketOptions", "insurances", index], {
      ...insurance,
      url: ""
    });
  };

  const displayHeaderInfo = (
    insurance: ProtectiveInsurance | ExpressInsurance | ASCInsurance | CustomInsurance,
    rate: null | any
  ): string => {
    const providerName = insurance.provider;
    if (rate === null) {
      return "";
    }
    switch (insurance.type) {
      case "F&I Express":
        if (insurance.chosenRate.insuranceType === "gap")
          return `${providerName}: ${rate.product?.ProductName ?? ""}  ${
            insurance?.chosenRate?.Coverage
          }`;
        else
          return `${providerName}: ${rate.product?.ProductName ?? ""}  ${
            insurance?.chosenRate?.Coverage
          } | Miles: ${rate?.miles} | Deductible: ${rate?.deductible?.Value ?? "N/A"}`;
      case "Protective":
        return `${providerName}: ${rate.ProductType} | Months: ${rate.months} | Cost price: ${rate.dealerCostPrice} | Sell price: ${rate.retailPrice}`;
      case "ASC":
        return `${providerName}: ${rate?.PlanName} | ${rate?.TermName} `;
      case "Custom":
        return `Custom insurance  ${insurance?.sequence?.seq ? "# " + insurance.sequence.seq : ""}`;
      default:
        return "";
    }
  };

  useEffect(() => {
    // @TODO(rickard): This manual content comparison should never have to be done.
    if (JSON.stringify(insurances) !== JSON.stringify(insurancesState)) {
      const newInsurances = stateAccess
        .get<Deal>(["data", "info", "aftermarketOptions", "insurances"])
        ?.map((insurance: any) => ({
          ...insurance,
          chosenRate: {
            ...insurance.chosenRate,
            profit: parseFloat(
              (
                (insurance.chosenRate?.retailPrice ?? 0) -
                (insurance.chosenRate?.dealerCostPrice ?? 0)
              ).toFixed(2)
            )
          }
        }));
      stateAccess.set<Deal>(["data", "info", "aftermarketOptions", "insurances"], newInsurances);
      setInsurancesState(newInsurances);
    }
  }, [stateAccess, insurances, insurancesState]);
  return (
    <>
      {insurances?.length > 0 ? (
        insurances?.map((insurance, index: number) => {
          const rate =
            (insurance.type === "F&I Express" ? insurance.originalRate : insurance?.chosenRate) ??
            null;

          return (
            <div
              style={{ position: "relative" }}
              ref={insurance.type === "Custom" ? containerRef : null}
              key={index}
            >
              <Paper elevation={3} style={{ padding: "10px", marginTop: "10px" }}>
                <div
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#E8E8E8",
                    marginBottom: "15px",
                    height: "auto",
                    padding: "5px"
                  }}
                >
                  <Typography variant="body1" style={{ display: "flex", justifyContent: "center" }}>
                    {displayHeaderInfo(insurance, rate)}
                  </Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid container spacing={1}>
                    {insurance.type === "Custom"
                      ? renderCustomInsuranceFields(
                          stateAccess,
                          renderSet,
                          stateAccess.get([]),
                          index
                        )
                      : insurance.type === "ASC"
                      ? renderASCInsurance(stateAccess, renderSet, rate, index)
                      : renderOriginalRate(stateAccess, renderSet, rate, index)}
                    <Grid item xs={3}>
                      {renderSet.readOnlyNumberRenderer<Deal>(
                        {
                          formComponent: "read-only-number-field",
                          name: "profit",
                          label: "Profit",
                          path: [
                            "data",
                            "info",
                            "aftermarketOptions",
                            "insurances",
                            index,
                            "chosenRate",
                            "profit"
                          ]
                        },
                        stateAccess,
                        renderSet
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {renderSet.radioInputRenderer<Deal>(
                        {
                          formComponent: "radio-field",
                          name: "soldBY",
                          label: "Sold by",
                          path: [
                            "data",
                            "info",
                            "aftermarketOptions",
                            "insurances",
                            index,
                            "chosenRate",
                            "soldBy"
                          ],
                          required: true,
                          possibleValues: [
                            { label: "WFD", value: "WFD" },
                            { label: "Dealer", value: "Dealer" }
                          ],
                          default: null
                        },
                        stateAccess,
                        renderSet
                      )}
                    </Grid>
                    {insurance.type === "F&I Express" && (
                      <Grid item xs={12}>
                        <Surcharges
                          handleChange={handleChangeSurchargeForDeal(index)}
                          stateAccess={stateAccess}
                          surcharges={stateAccess.get<Deal>([
                            "data",
                            "info",
                            "aftermarketOptions",
                            "insurances",
                            index,
                            "chosenRate",
                            "dynamicSurcharges"
                          ])}
                        />
                      </Grid>
                    )}
                    {insurance.type === "ASC" && (
                      <Grid item xs={12}>
                        <ASCSurcharges
                          disabled={stateAccess.get<Deal>([
                            "data",
                            "info",
                            "aftermarketOptions",
                            "insurances",
                            index,
                            "PDF"
                          ])}
                          rateSurcharges={stateAccess.get<Deal>([
                            "data",
                            "info",
                            "aftermarketOptions",
                            "insurances",
                            index,
                            "chosenRate",
                            "surcharges"
                          ])}
                          onChangeSurchargeFunction={onChangeSurchargeFunction({
                            set: (path: any, value: any): any =>
                              stateAccess.set<Deal>(
                                ["data", "info", "aftermarketOptions", "insurances", index],
                                value
                              ),
                            get: (path: any) =>
                              stateAccess.get<Deal>([
                                "data",
                                "info",
                                "aftermarketOptions",
                                "insurances",
                                index,
                                ...path
                              ] as any)
                          })}
                          isSurchargeChecked={isSurchargeChecked({
                            set: (path: any, value: any): any =>
                              stateAccess.set<Deal>(
                                ["data", "info", "aftermarketOptions", "insurances", index],
                                value
                              ),
                            get: (path: any) =>
                              stateAccess.get<Deal>([
                                "data",
                                "info",
                                "aftermarketOptions",
                                "insurances",
                                index,
                                ...path
                              ] as any)
                          })}
                        />
                      </Grid>
                    )}
                  </Grid>

                  {showButton && (
                    <div style={{ marginLeft: "7px", display: "flex", flexDirection: "column" }}>
                      {insurance.type === "F&I Express" ? (
                        <>
                          <DeleteInsuranceContract stateAccess={stateAccess} index={index} />
                          <SignInsuranceContract
                            renderSet={renderSet}
                            stateAccess={stateAccess}
                            index={index}
                          />
                        </>
                      ) : insurance.type === "Protective" ? (
                        <>
                          <DeleteProtectiveInsurance stateAccess={stateAccess} index={index} />
                          <SubmitProtectiveContract
                            stateAccess={stateAccess}
                            index={index}
                            insurance={insurance}
                          />
                        </>
                      ) : insurance.type === "ASC" ? (
                        <>
                          <DeleteASCInsurance stateAccess={stateAccess} index={index} />
                          <SubmitASCInsurance
                            renderSet={renderSet}
                            stateAccess={stateAccess}
                            index={index}
                            insurance={insurance}
                          />
                        </>
                      ) : insurance.type === "Custom" ? (
                        <>
                          <DeleteCustomInsurance stateAccess={stateAccess} index={index} />
                          <UploadContract
                            name={"upload-contract"}
                            stateAccess={stateAccess}
                            index={index}
                            insurance={insurance}
                            _id={_id}
                            containerRef={containerRef}
                          />
                          <CalculateCustomInsuranceCost
                            stateAccess={stateAccess}
                            insurance={insurance}
                            index={index}
                          />
                        </>
                      ) : null}
                    </div>
                  )}
                </div>
                {insurance.type === "Custom" && insurance.url && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "3px"
                    }}
                  >
                    <a
                      href={insurance.url || "#"}
                      style={{ marginRight: "5px", fontStyle: "italic" }}
                    >
                      {insurance.fileName || "Document not found"}
                    </a>
                    <IconButton
                      style={{ color: "red" }}
                      onClick={() => handleRemoveContract(insurance, index)}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  </div>
                )}
              </Paper>
            </div>
          );
        })
      ) : showButton ? null : (
        <></>
      )}
    </>
  );
}

const renderCustomInsuranceFields = (
  stateAccess: StateAccess,
  renderSet: RenderSet,
  deal: Deal,
  index: number
): JSX.Element => {
  const provider = stateAccess.get<Deal>([
    "data",
    "info",
    "aftermarketOptions",
    "insurances",
    index,
    "provider"
  ]);
  const customMonthsEnabled = stateAccess.get<Deal>([
    "data",
    "info",
    "aftermarketOptions",
    "insurances",
    index,
    "chosenRate",
    "customMonthsEnabled"
  ]);
  const dealType = stateAccess.get<Deal>(["data", "info", "type"]);

  const numberOfPayments = stateAccess.get<Deal>(["data", "info", "payment", "numberOfPayments"]);
  return (
    <>
      <Grid item xs={4}>
        {additionalInsuranceTypes.includes(
          stateAccess.get<Deal>([
            "data",
            "info",
            "aftermarketOptions",
            "insurances",
            index,
            "chosenRate",
            "insuranceType"
          ] as any)
        )
          ? renderSet.textInputRenderer<Deal>(
              {
                type: "text",
                name: "provider",
                label: "Provider",
                path: ["data", "info", "aftermarketOptions", "insurances", index, "provider"] as any
              },
              stateAccess,
              renderSet
            )
          : renderSet.selectInputRenderer<Deal>(
              {
                formComponent: "select-field",
                name: "provider",
                label: "Provider",
                path: ["data", "info", "aftermarketOptions", "insurances", index, "provider"],
                options: createOptionsForSelect({
                  possibleValues: () => [...allProviders],
                  getOptionLabel: (x) => x,
                  getSelectedOption: (x, y) => x === y
                }),
                required: true
              },
              stateAccess,
              [],
              stateAccess,
              renderSet
            )}
      </Grid>
      {provider === "Other" ? (
        <Grid item xs={2}>
          {renderSet.textInputRenderer<Deal>(
            {
              type: "text",
              name: "customProvider",
              label: "Custom Provider",
              path: [
                "data",
                "info",
                "aftermarketOptions",
                "insurances",
                index,
                "customProvider"
              ] as any
            },
            stateAccess,
            renderSet
          )}
        </Grid>
      ) : null}
      <Grid item xs={4}>
        {renderSet.selectInputRenderer<Deal>(
          {
            formComponent: "select-field",
            name: "insuranceType",
            label: "Type",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "insuranceType"
            ],
            options: createOptionsForSelect({
              possibleValues: (): AllInsuranceTypes[] => [...allInsuranceTypes],
              getOptionLabel: (x) =>
                x !== undefined && x !== null ? `${x[0].toUpperCase()}${x.slice(1)}` : "",
              getSelectedOption: (x, y) => x === y
            })
          },
          stateAccess,
          [],
          stateAccess,
          renderSet
        )}
      </Grid>
      {provider === "XtraRide" ? (
        <Grid item xs={2}>
          {renderSet.currencyInputRenderer<Deal>(
            {
              name: "Deductible",
              label: "Deductible",
              width: "1/2",
              path: [
                "data",
                "info",
                "aftermarketOptions",
                "insurances",
                index,
                "chosenRate",
                "deductible"
              ],
              default: null
            },
            stateAccess,
            renderSet
          )}
        </Grid>
      ) : null}
      <Grid item xs={["XtraRide", "Other"].includes(provider) ? 2 : 4}>
        {provider === "Protective" ||
        (provider === "XtraRide" && deal.data.info.type === "Power Sport")
          ? renderSet.readOnlyRenderer<Deal>(
              {
                formComponent: "read-only-field",
                name: "moths",
                label: "Months",
                path: [
                  "data",
                  "info",
                  "aftermarketOptions",
                  "insurances",
                  index,
                  "chosenRate",
                  "months"
                ]
              },
              stateAccess,
              renderSet
            )
          : renderSet.numberInputRenderer<Deal>(
              {
                name: "moths",
                label: "Months",
                path: [
                  "data",
                  "info",
                  "aftermarketOptions",
                  "insurances",
                  index,
                  "chosenRate",
                  "months"
                ]
              },
              stateAccess,
              renderSet
            )}
      </Grid>
      {provider === "Protective" && dealType === "Automotive" && numberOfPayments === 87 && (
        <Grid item xs={customMonthsEnabled ? 6 : 12}>
          {renderSet.checkboxInputRenderer<Deal>(
            {
              formComponent: "checkbox-field",
              isChecked: (stateAccess) =>
                stateAccess.get([
                  "data",
                  "info",
                  "aftermarketOptions",
                  "insurances",
                  index,
                  "chosenRate",
                  "customMonthsEnabled"
                ]),
              toggle: (stateAccess) =>
                stateAccess.set(
                  [
                    "data",
                    "info",
                    "aftermarketOptions",
                    "insurances",
                    index,
                    "chosenRate",
                    "customMonthsEnabled"
                  ],
                  !stateAccess.get<Deal>([
                    "data",
                    "info",
                    "aftermarketOptions",
                    "insurances",
                    index,
                    "chosenRate",
                    "customMonthsEnabled"
                  ])
                ),
              name: "customMonths",
              label: "Custom months?",
              path: [
                "data",
                "info",
                "aftermarketOptions",
                "insurances",
                index,
                "chosenRate",
                "customMonthsEnabled"
              ]
            },
            stateAccess,
            stateAccess,
            renderSet
          )}
        </Grid>
      )}
      {provider === "Protective" &&
        customMonthsEnabled &&
        dealType === "Automotive" &&
        numberOfPayments === 87 && (
          <Grid item xs={6}>
            {renderSet.numberInputRenderer<Deal>(
              {
                name: "customMonths",
                label: "Custom months",
                path: [
                  "data",
                  "info",
                  "aftermarketOptions",
                  "insurances",
                  index,
                  "chosenRate",
                  "customMonths"
                ]
              },
              stateAccess,
              renderSet
            )}
          </Grid>
        )}
      <Grid item xs={3}>
        {provider === "Protective" ||
        (provider === "XtraRide" && deal.data.info.type === "Power Sport")
          ? renderSet.readOnlyNumberRenderer<Deal>(
              {
                name: "costPrice",
                formComponent: "read-only-number-field",
                label: "Dealer cost price",
                path: [
                  "data",
                  "info",
                  "aftermarketOptions",
                  "insurances",
                  index,
                  "chosenRate",
                  "dealerCostPrice"
                ]
              },
              stateAccess,
              renderSet
            )
          : renderSet.currencyInputRenderer<Deal>(
              {
                name: "costPrice",
                label: "Dealer cost price",
                path: [
                  "data",
                  "info",
                  "aftermarketOptions",
                  "insurances",
                  index,
                  "chosenRate",
                  "dealerCostPrice"
                ]
              },
              stateAccess,
              renderSet
            )}
      </Grid>
      <Grid item xs={3}>
        {renderSet.currencyInputRenderer<Deal>(
          {
            name: "retailPrice",
            label: "Retail price",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "retailPrice"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={3}>
        {provider === "Protective" ||
        (provider === "XtraRide" && deal.data.info.type === "Power Sport")
          ? renderSet.readOnlyNumberRenderer<Deal>(
              {
                name: "remitPrice",
                formComponent: "read-only-number-field",
                label: "Remit price",
                path: [
                  "data",
                  "info",
                  "aftermarketOptions",
                  "insurances",
                  index,
                  "chosenRate",
                  "remitPrice"
                ]
              },
              stateAccess,
              renderSet
            )
          : renderSet.currencyInputRenderer<Deal>(
              {
                name: "remitPrice",
                label: "Remit price",
                path: [
                  "data",
                  "info",
                  "aftermarketOptions",
                  "insurances",
                  index,
                  "chosenRate",
                  "remitPrice"
                ]
              },
              stateAccess,
              renderSet
            )}
      </Grid>
    </>
  );
};

const renderOriginalRate = (
  stateAccess: StateAccess,
  renderSet: RenderSet,
  rate: number | null,
  index: number
): JSX.Element => {
  return rate !== null ? (
    <>
      <Grid item xs={4}>
        {renderSet.readOnlyRenderer<Deal>(
          {
            formComponent: "read-only-field",
            name: "Provider",
            label: "Provider",
            path: ["data", "info", "aftermarketOptions", "insurances", index, "provider"]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={4}>
        {renderSet.readOnlyRenderer<Deal>(
          {
            formComponent: "read-only-field",
            name: "insuranceType",
            label: "Type",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "insuranceType"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={4}>
        {renderSet.readOnlyRenderer<Deal>(
          {
            formComponent: "read-only-field",
            name: "moths",
            label: "Months",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "months"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={3}>
        {renderSet.readOnlyNumberRenderer<Deal>(
          {
            formComponent: "read-only-number-field",
            name: "costPrice",
            label: "Dealer cost price",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "dealerCostPrice"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={3}>
        {renderSet.currencyInputRenderer<Deal>(
          {
            name: "retailPrice",
            label: "Retail price",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "retailPrice"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={3}>
        {renderSet.readOnlyNumberRenderer<Deal>(
          {
            formComponent: "read-only-number-field",
            name: "remitPrice",
            label: "Remit price",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "remitPrice"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
    </>
  ) : (
    <>
      <Grid item xs={4}>
        {renderSet.readOnlyRenderer<Deal>(
          {
            formComponent: "read-only-field",
            name: "Provider",
            label: "Provider",
            path: ["data", "info", "aftermarketOptions", "insurances", index, "provider"]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={4}>
        {renderSet.selectInputRenderer<Deal>(
          {
            formComponent: "select-field",
            name: "insuranceType",
            label: "Type",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "insuranceType"
            ],
            options: createOptionsForSelect({
              possibleValues: () => ["gap", "service warranty"],
              getOptionLabel: (x) => x
            })
          },
          stateAccess,
          [],
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={4}>
        {renderSet.numberInputRenderer<Deal>(
          {
            name: "moths",
            label: "Months",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "months"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={3}>
        {renderSet.numberInputRenderer<Deal>(
          {
            name: "costPrice",
            label: "Dealer cost price",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "dealerCostPrice"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={3}>
        {renderSet.numberInputRenderer<Deal>(
          {
            name: "retailPrice",
            label: "Retail price",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "retailPrice"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={3}>
        {renderSet.numberInputRenderer<Deal>(
          {
            name: "remitPrice",
            label: "Remit price",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "remitPrice"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
    </>
  );
};
const renderASCInsurance = (
  stateAccess: StateAccess,
  renderSet: RenderSet,
  rate: number | null,
  index: number
): JSX.Element => {
  return rate !== null ? (
    <>
      <Grid item xs={4}>
        {renderSet.readOnlyRenderer<Deal>(
          {
            formComponent: "read-only-field",
            name: "Provider",
            label: "Provider",
            path: ["data", "info", "aftermarketOptions", "insurances", index, "provider"]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={4}>
        {renderSet.readOnlyRenderer<Deal>(
          {
            formComponent: "read-only-field",
            name: "insuranceType",
            label: "Type",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "insuranceType"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={4}>
        {renderSet.readOnlyRenderer<Deal>(
          {
            formComponent: "read-only-field",
            name: "Months",
            label: "Months",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "Months"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={3}>
        {renderSet.readOnlyNumberRenderer<Deal>(
          {
            formComponent: "read-only-number-field",
            name: "costPrice",
            label: "Dealer cost price",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "dealerCostPrice"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={3}>
        {renderSet.readOnlyNumberRenderer<Deal>(
          {
            formComponent: "read-only-number-field",
            name: "retailPrice",
            label: "Retail price",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "retailPrice"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={3}>
        {renderSet.readOnlyNumberRenderer<Deal>(
          {
            formComponent: "read-only-number-field",
            name: "remitPrice",
            label: "Remit price",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "remitPrice"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
    </>
  ) : (
    <>
      <Grid item xs={4}>
        {renderSet.readOnlyRenderer<Deal>(
          {
            formComponent: "read-only-field",
            name: "Provider",
            label: "Provider",
            path: ["data", "info", "aftermarketOptions", "insurances", index, "provider"]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={4}>
        {renderSet.selectInputRenderer<Deal>(
          {
            formComponent: "select-field",
            name: "insuranceType",
            label: "Type",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "insuranceType"
            ],
            options: createOptionsForSelect({
              possibleValues: () => ["gap", "service warranty"],
              getOptionLabel: (x) => x
            })
          },
          stateAccess,
          [],
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={4}>
        {renderSet.numberInputRenderer<Deal>(
          {
            name: "moths",
            label: "Months",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "Months"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={3}>
        {renderSet.numberInputRenderer<Deal>(
          {
            name: "costPrice",
            label: "Dealer cost price",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "dealerCostPrice"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={3}>
        {renderSet.readOnlyRenderer<Deal>(
          {
            formComponent: "read-only-field",
            name: "retailPrice",
            label: "Retail price",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "retailPrice"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
      <Grid item xs={3}>
        {renderSet.numberInputRenderer<Deal>(
          {
            name: "remitPrice",
            label: "Remit price",
            path: [
              "data",
              "info",
              "aftermarketOptions",
              "insurances",
              index,
              "chosenRate",
              "remitPrice"
            ]
          },
          stateAccess,
          renderSet
        )}
      </Grid>
    </>
  );
};
