import {
  permissionNode,
  PermissionStruct,
  multyPermissionNode,
  relationPermissionNode
} from "components/Roles/types";
import { CrudEntity } from "utils/types";
export type RolePermissionData = CrudEntity & {
  data: { info: { name: string; permissions: string[] } };
};
export type RolePermissionsStruct = PermissionStruct<RolePermissionData>;

export const rolePermissionStruct: RolePermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      name: permissionNode("Name"),
      permissions: multyPermissionNode("Permissions")
    }
  }
};
