import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TitleIssueNote } from "./types";
import { RootState } from "app/rootReducer";
import { v4 as uuidv4 } from "uuid";
import formEditContext from "components/Content/FormEditContext";
import {
  Box,
  Button,
  CircularProgress,
  DialogContentText,
  IconButton,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { HintTooltip } from "components/common/HintTooltip";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelCircleIcon from "@material-ui/icons/Cancel";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { deleteTitleIssueNote } from "./deleteTitleIssueNoteSlice";
import { addTitleIssueNote } from "./addTitleIssueNoteSlice";
import { editTitleIssueNote } from "./editTitleIssueNoteSlice";
import formatDate from "utils/formatDate";
import { getTitleIssueNotesList, listTitleIssueNotesActions } from "./listTitleIssueNoteSlice";
import { useNotifyTitleIssueNotes } from "hooks/useNotifyDealerEmails/useNotifyDealerEmails";
import { Warning, Help } from "@material-ui/icons";
import MailIcon from "@material-ui/icons/Mail";
interface Props {
  titleIssueNotes: TitleIssueNote[];
  titleIssueId: string;
  assignedDealershipEmails: string[];
  editable?: boolean;
}
export default function TitleIssueNotesPreview({
  titleIssueNotes,
  titleIssueId,
  editable,
  assignedDealershipEmails
}: Props): JSX.Element {
  const dispatch = useDispatch();

  const [addRequestId] = React.useState(uuidv4());
  const [deleteRequestId] = React.useState(uuidv4());

  const [timeout, setTimeoutState] = React.useState<undefined | NodeJS.Timeout>();

  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);

  const addTitleIssueNoteState = useSelector(
    (state: RootState) => state.addTitleIssueNoteSlice[addRequestId]
  );

  const deleteTitleIssueNoteState = useSelector(
    (state: RootState) => state.deleteTitleIssueNoteSlice[deleteRequestId]
  );

  const listTitleIssueNoteState = useSelector(
    (state: RootState) => state.listTitleIssueNoteSlice[titleIssueId]
  );
  const allEditTitleIssueNoteStates = useSelector(
    (state: RootState) => state.editTitleIssueNoteSlice
  );
  const editTitleIssueNoteStates = listTitleIssueNoteState?.entities?.map((note) => ({
    _id: note._id,
    state: allEditTitleIssueNoteStates[note._id]
  }));
  const isLoading =
    addTitleIssueNoteState?.status === "waiting" || deleteTitleIssueNoteState?.status === "waiting";

  const { enabled: editMode } = React.useContext(formEditContext);
  const canEdit = editMode || editable;

  const {
    notifyTitleIssueNotes,
    loading: notifyTitleIssueNotesLoading
  } = useNotifyTitleIssueNotes();
  React.useEffect(() => {
    if (!listTitleIssueNoteState && canEdit)
      dispatch(
        getTitleIssueNotesList(titleIssueId, {
          query: { deleted: false, "data.titleIssueId": titleIssueId },
          options: { sort: { createdAt: -1 } }
        })
      );
  }, [dispatch, canEdit]);

  React.useEffect(() => {
    if (
      deleteTitleIssueNoteState !== undefined &&
      deleteTitleIssueNoteState.status === "success" &&
      deleteTitleIssueNoteState.data !== null
    ) {
      const index = listTitleIssueNoteState.entities?.findIndex(
        (n: TitleIssueNote) => n._id === deleteTitleIssueNoteState?.data?.message?._id
      );
      dispatch({
        type: listTitleIssueNotesActions.editList.type,

        payload: {
          index: index,
          payload: deleteTitleIssueNoteState?.data?.message,
          listId: titleIssueId
        }
      });
    }
  }, [listTitleIssueNoteState, dispatch]);
  const handleNotifyDealerEmail = () => {
    const notes = (listTitleIssueNoteState?.entities ?? titleIssueNotes)
      .filter((n) => !n.deleted && !n.data.info.sentToDealerEmail)
      .map((n) => n._id);
    if (notes?.length) {
      notifyTitleIssueNotes({ titleIssueNotesIds: notes, emails: assignedDealershipEmails });
    }
  };
  const handleAdd = () => {
    const defaultTitleIssueNote = {
      data: {
        titleIssueId,
        info: {
          note: "",
          user: {
            firstName: currentUser?.data?.info?.firstName || null,
            lastName: currentUser?.data?.info?.lastName || null,
            email: currentUser?.data?.info?.email || null
          },
          seen: {
            wfd: true,
            dealer: false
          },
          creator: "wfd"
        }
      }
    };
    dispatch(
      addTitleIssueNote({ requestId: addRequestId, ...(defaultTitleIssueNote as TitleIssueNote) })
    );
  };

  console.log(listTitleIssueNoteState?.entities, titleIssueNotes);

  const handleDelete = (titleIssueNote: TitleIssueNote) => {
    dispatch(deleteTitleIssueNote({ requestId: deleteRequestId, _id: titleIssueNote._id }));
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    titleIssueNote: TitleIssueNote & { index: number }
  ) => {
    const newTitleIssueNote = {
      ...titleIssueNote,
      data: {
        ...titleIssueNote.data,
        info: { ...titleIssueNote.data.info, note: event.target.value }
      },
      updatedAt: new Date().toISOString()
    };
    dispatch({
      type: listTitleIssueNotesActions.editList.type,

      payload: { index: titleIssueNote.index, payload: newTitleIssueNote, listId: titleIssueId }
    });
    return newTitleIssueNote;
  };
  const handleUpdate = (titleIssueNote: TitleIssueNote) => {
    dispatch(editTitleIssueNote({ requestId: titleIssueNote._id, ...titleIssueNote }));
  };
  const renderEntities = () => {
    return (listTitleIssueNoteState?.entities ?? titleIssueNotes)
      .map((n, index) => ({ ...n, index }))
      .filter((n) => !n.deleted)
      .sort((a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime())
      ?.map((titleIssueNote, index) => {
        const state = editTitleIssueNoteStates?.find((state) => state._id === titleIssueNote._id)
          ?.state;

        if (titleIssueNote.data.info.creator === "dealer")
          return (
            <Paper
              key={index}
              style={{
                padding: "10px",
                marginBottom: "20px",
                display: "block",
                backgroundColor: "#6b97f6",
                color: "#fff"
              }}
            >
              <Box display="flex" alignContent="flex-start" flexDirection="column">
                <Typography variant="h6">Note from dealer</Typography>
                <Box fontSize={12} display="flex" style={{ justifyContent: "space-between" }}>
                  <Typography variant="body1" style={{ overflowWrap: "anywhere" }}>
                    {" "}
                    {titleIssueNote?.data?.info?.note}
                  </Typography>

                  {[
                    titleIssueNote.data?.info?.user?.firstName,
                    titleIssueNote?.data?.info?.user?.lastName,
                    formatDate(titleIssueNote?.createdAt, "medium", true)
                  ]
                    .filter((x) => x)
                    .join(" ")}
                </Box>
              </Box>
            </Paper>
          );
        return (
          <Paper
            key={index}
            style={{
              padding: "10px",
              marginBottom: "20px",
              display: "block",
              background: state?.status === "error" ? "#ffe3e3" : undefined
            }}
          >
            <Box display="flex" alignContent="flex-start">
              <Box flexGrow={1}>
                <TextField
                  multiline
                  onChange={(event) => {
                    if (timeout) {
                      clearTimeout(timeout);
                    }
                    const newNote = handleChange(event, titleIssueNote);
                    setTimeoutState(
                      setTimeout(() => {
                        handleUpdate(newNote);
                      }, 500)
                    );
                  }}
                  value={titleIssueNote?.data?.info?.note}
                  name="titleIssueNote"
                  inputProps={
                    canEdit && listTitleIssueNoteState?.status !== "waiting"
                      ? {}
                      : {
                          readOnly: true
                        }
                  }
                  placeholder="Note"
                  fullWidth
                  label={"Note"}
                  variant={
                    canEdit && listTitleIssueNoteState?.status !== "waiting" ? "filled" : "outlined"
                  }
                  size="small"
                  onBlur={(event) => {
                    if (event.target.value !== titleIssueNote?.data?.info?.note) {
                      const newNote = handleChange(event, titleIssueNote);
                      handleUpdate(newNote);
                    }
                  }}
                />
                <Box
                  fontSize={12}
                  justifyContent="space-between"
                  display={"flex"}
                  marginTop={"10px"}
                  style={{ maxHeight: 20 }}
                >
                  <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                    {(() => {
                      switch (state?.status) {
                        case "waiting":
                          return (
                            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                              <CircularProgress
                                size={17}
                                style={{
                                  color: "#50A538",
                                  // marginRight: 5,
                                  minHeight: 20.5,
                                  maxHeight: 20.5
                                }}
                              />
                              Saving
                            </div>
                          );
                        case "error":
                          return (
                            <button
                              className="pulse hover"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                border: "none",
                                background: "#ffe3e3",
                                fontWeight: "bold",
                                columnGap: "3px",
                                borderRadius: 3
                              }}
                              onClick={() => {
                                handleUpdate(titleIssueNote);
                              }}
                            >
                              <CancelCircleIcon
                                style={{
                                  color: "#E34C28"
                                }}
                              />
                              Error! Click here to retry!
                            </button>
                          );
                        default:
                          return (
                            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                              <CheckCircleIcon
                                style={{
                                  color: "#50A538"
                                }}
                              />
                              Saved
                            </div>
                          );
                      }
                    })()}
                    {(() => {
                      if (
                        notifyTitleIssueNotesLoading &&
                        !titleIssueNote?.data?.info?.sentToDealerEmail
                      ) {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <CircularProgress
                              size={17}
                              style={{
                                color: "#50A538",
                                minHeight: 20.5,
                                maxHeight: 20.5
                              }}
                            />
                            Sending
                          </div>
                        );
                      }
                      if (titleIssueNote?.data?.info?.sentToDealerEmail) {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <MailIcon
                              style={{
                                color: "rgb(37, 78, 110)"
                              }}
                            />
                            Sent to dealer email at{" "}
                            {formatDate(titleIssueNote?.data?.info?.sentToDealerEmailDate, "short")}
                          </div>
                        );
                      } else {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <MailIcon
                              style={{
                                color: "#E34C28"
                              }}
                            />
                            Not sent to dealer
                          </div>
                        );
                      }
                    })()}
                  </div>
                  {[
                    titleIssueNote.data?.info?.user?.firstName,
                    titleIssueNote?.data?.info?.user?.lastName,
                    formatDate(titleIssueNote?.createdAt, "medium", true)
                  ]
                    .filter((x) => x)
                    .join(" ")}
                </Box>
              </Box>
              {canEdit && (
                <Box>
                  <IconButton
                    style={{
                      color: "#E34C28"
                    }}
                    key={index}
                    aria-label={`remove title information note`}
                    onClick={() => handleDelete(titleIssueNote)}
                    disabled={deleteTitleIssueNoteState?.status === "waiting"}
                  >
                    <HintTooltip title={`Click here to remove the title information note.`}>
                      <RemoveCircleIcon />
                    </HintTooltip>
                  </IconButton>
                </Box>
              )}
            </Box>
          </Paper>
        );
      });
  };
  const cantSendEmail = !(
    notifyTitleIssueNotesLoading ||
    editTitleIssueNoteStates?.some((state) => state?.state?.status === "waiting") ||
    assignedDealershipEmails.length > 0
  );

  return (
    <Paper
      elevation={0}
      style={{
        breakInside: "avoid",
        pageBreakInside: "avoid",
        transform: "translateZ(1)"
      }}
    >
      <Box style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <DialogContentText
          style={{
            color: "#254e6e",
            fontSize: "19px",
            margin: "5px 0px 5px 0px",

            fontWeight: "bold"
          }}
        >
          Dealer Title Notes (External)
        </DialogContentText>
        <HintTooltip
          style={{ color: "#254e6e", fontSize: "20px", marginLeft: 4 }}
          title={
            <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
              Autosaved / Visible to dealer
            </div>
          }
        >
          <Help />
        </HintTooltip>
        {canEdit && (
          <>
            <IconButton
              style={{
                color: "#50A538"
              }}
              aria-label={`add new dealer note`}
              onClick={handleAdd}
              disabled={isLoading}
            >
              <HintTooltip title={`Click here to add new dealer note.`}>
                {isLoading ? <CircularProgress size={19} /> : <AddCircleIcon />}
              </HintTooltip>
            </IconButton>
            <HintTooltip
              title={
                <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
                  You should first select emails to notify in the deal in order to send emails to
                  the dealership
                </div>
              }
            >
              <Button
                variant="outlined"
                style={{ fontSize: "10px", marginLeft: "auto", padding: 5, height: 30 }}
                startIcon={cantSendEmail && <Warning style={{ color: "#eed202" }} />}
                onClick={cantSendEmail ? undefined : handleNotifyDealerEmail}
              >
                Send notes to dealer
              </Button>
            </HintTooltip>
          </>
        )}
      </Box>

      {renderEntities()}
    </Paper>
  );
}
