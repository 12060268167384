import {
  createSocketMessageSlice,
  Identifiable
} from "../../Middlewares/socketMessageSliceCreator";
import { GetIncomeReportData, GetIncomeReportResponse } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  { data: GetIncomeReportData },
  GetIncomeReportResponse
>("Get income report", {
  request: "get_income_report"
});

export const getIncomeReport = (request: GetIncomeReportData & Identifiable) => {
  return socketAction({
    requestId: request.requestId,
    data: request
  });
};

export const getIncomeReportAction = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
