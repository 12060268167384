export default {
  update_sequence: {
    $id: "updateSequence",
    type: "object",
    properties: {
      _id: { type: "string" },
      seq: { type: "integer", nullable: true },
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
};
