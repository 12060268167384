import { Dmv } from "./types";
import {
  PermissionStruct,
  permissionNode,
  multyPermissionNode,
  relationPermissionNode
} from "components/Roles/types";

export type DmvPermissionsStruct = PermissionStruct<Dmv>;

export const dmvPermissionsStruct: DmvPermissionsStruct = {
  _id: permissionNode(" id"),
  createdAt: permissionNode("Created at"),
  updatedAt: permissionNode("Updated at"),
  deleted: permissionNode("Deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      state: permissionNode("State"),
      contactNumbers: multyPermissionNode("Contact Numbers")
    },
    notes: multyPermissionNode("Notes")
  }
};
