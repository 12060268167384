export default {
  ascWarranty: { type: "number", nullable: true },
  gwcWarranty: { type: "number", nullable: true },
  goldStandartAutomotiveNetworkWarranty: { type: "number", nullable: true },
  gwcGap: { type: "number", nullable: true },
  protectiveGap: { type: "number", nullable: true },
  xtraRideGap: { type: "number", nullable: true },
  xtraRideWarranty: { type: "number", nullable: true },
  fpcWarranty: { type: "number", nullable: true },
  nsdGap: { type: "number", nullable: true },
  compasMotorhome: { type: "number", nullable: true },
  compassGap: { type: "number", nullable: true },
  compasMotorhomeCoach: { type: "number", nullable: true },
  compassTT5MoreThan20k: { type: "number", nullable: true },
  compassTT5LessThan20k: { type: "number", nullable: true },
  campersPopSlideLessThan20k: { type: "number", nullable: true },
  campersPopSlideMoreThan20k: { type: "number", nullable: true },
  maxWFDProfit: { type: "number", nullable: true },
  businessReportTypes: {
    type: "array",
    nullable: true,
    items: {
      type: "object",
      nullable: true,
      properties: {
        type: { type: "string" },
        parameters: {
          type: "array",
          nullable: true,
          items: {
            type: "string"
          }
        }
      }
    }
  }
};
