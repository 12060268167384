import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState, AddLenderRequest, AddLenderRequestSend } from "./types";

interface AddLenderResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddLenderRequestSend,
  AddLenderResponse
>("AddLender", {
  request: "new_lender"
});

export const addLenderActions = socketMessageSlice.actions;
export const addLender = (request: AddLenderRequest & Identifiable) => {
  const { additionalRequiredDocuments, currentUser, oldOperatingStates, ...rest } = request.data;
  const statusComment = rest?.info?.operatingStates?.comment;

  return socketAction({
    requestId: request.requestId,
    availableToRolesIds: request.availableToRolesIds ?? [],
    data: {
      ...rest,
      info: {
        ...rest.info,
        ...(rest?.info?.operatingStates
          ? {
              operatingStates: {
                ...rest?.info?.operatingStates,
                comment: undefined
              }
            }
          : {}),
        statusHistory: [
          ...(statusComment && currentUser?.data?.info?.email && rest?.info?.operatingStates
            ? [
                {
                  comment: statusComment,
                  userEmail: currentUser?.data?.info?.email,
                  date: new Date().toISOString(),
                  oldData: oldOperatingStates,
                  newData: {
                    ...rest?.info?.operatingStates,
                    comment: undefined
                  },
                  status: rest?.info?.operatingStates?.status
                }
              ]
            : [])
        ]
      },
      additionalRequiredDocumentsIds: additionalRequiredDocuments?.map((el) => el._id)
    }
  });
};
export default socketMessageSlice.reducer;
