import { Button, Chip, Grid, Paper, Typography } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import formEditContext from "components/Content/FormEditContext";
import IFrameURLModal from "components/Deals/Documents/IFrameURLModal";
import { hideLoader, showLoader } from "components/Loader/loaderSlice";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch } from "react-redux";
import { capitalize } from "utils/functions";
import { StateAccess } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../../firebase/firebase";
import { editTitleIssue } from "./editTitleIssueSlice";
import { getDmvColorByStatus } from "./Overview";
import { DmvStatus, TitleDocumentInfo, TitleIssue, TitleIssueStatus } from "./types";
type Props = {
  stateAccess: StateAccess;
};
type DocumentActionsProps = {
  stateAccess: StateAccess;
  type: DocumentType;
  _id: string;
};
type DocumentProps = {
  url: string;
  status: TitleIssueStatus;
  type: "old" | "new" | "receipt";
  index: number;
};

type DocumentType = "titleRegistrationInfo" | "oldTitleRegistrationInfo" | "receiptInfo";

const DocumentActions = ({ stateAccess, type, _id }: DocumentActionsProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { enabled: editMode } = React.useContext(formEditContext);

  const dispatch = useDispatch();

  const [requestId] = React.useState(uuidv4());

  const titleIssue = stateAccess.get([]);
  const hasDocuments = titleIssue?.data?.info?.[type]?.documents?.length > 0;

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(showLoader());
    const uploadedFiles = event.target.files ?? [];

    if (uploadedFiles.length !== 0) {
      const file = uploadedFiles[0];
      const firebaseFileName = `${requestId}-${type}-${file.name}`;

      const uploadTask = storage.ref(`/files/TitleIssues/${_id}/${firebaseFileName}`).put(file);

      uploadTask.on(
        "state_changed",
        (_snapShot) => {},
        (err) => {
          enqueueSnackbar(err.message, {
            variant: "error"
          });
        },
        () => {
          const ref = storage.ref(`/files/TitleIssues/${_id}`).child(firebaseFileName);

          ref.getDownloadURL().then((fireBaseUrl) => {
            ref.getMetadata().then((metadata) => {
              dispatch(hideLoader());
              const newDocuments = [
                ...(titleIssue?.data?.info?.[type]?.documents ?? []),
                fireBaseUrl
              ];
              const updatedTitleIssue: TitleIssue = {
                ...titleIssue,
                data: {
                  ...titleIssue?.data,
                  info: {
                    ...titleIssue?.data?.info,
                    [type]: {
                      ...titleIssue?.data?.info?.[type],
                      uploadedDate: new Date().toISOString(),
                      documents: newDocuments,
                      status: "pending"
                    }
                  }
                }
              };
              dispatch(editTitleIssue({ requestId, ...updatedTitleIssue }));
            });
          });
        }
      );
    }
  };

  const handleApproveOrReject = (action: TitleIssueStatus) => {
    const updatedTitleIssue: TitleIssue = {
      ...titleIssue,
      data: {
        ...titleIssue?.data,
        info: {
          ...titleIssue?.data?.info,
          [type]: {
            ...titleIssue?.data?.info?.[type],
            status: action
          }
        }
      }
    };
    dispatch(editTitleIssue({ requestId, ...updatedTitleIssue }));
  };
  return (
    <Grid container item xs={12} style={{ padding: "10px" }}>
      <Grid item xs={2} style={{ textAlign: "left" }}>
        <input accept=".pdf" id={type} type="file" onChange={handleUpload} hidden />
        <label htmlFor={type}>
          <Button variant="contained" color="primary" component="span" disabled={!editMode}>
            Upload
          </Button>
        </label>
      </Grid>
      <Grid item xs={hasDocuments ? 8 : 10}></Grid>
      {hasDocuments && (
        <Grid container item xs={2} style={{ textAlign: "right" }}>
          <Grid item xs={6}>
            <Button
              disabled={!editMode}
              variant="contained"
              style={{
                background: editMode ? "rgb(255,0,0)" : "rgba(255,0,0,0.2)",
                color: "#ffffff"
              }}
              onClick={() => handleApproveOrReject("rejected")}
            >
              Decline
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              disabled={!editMode}
              variant="contained"
              style={{
                background: editMode ? "rgb(80, 165, 56)" : "rgba(80, 165, 56, 0.2)",
                color: "#ffffff"
              }}
              onClick={() => handleApproveOrReject("resolved")}
            >
              Approve
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export const titleStatusToColor = (status: TitleIssueStatus) => {
  switch (status) {
    case "resolved":
      return "rgba(0, 128, 0, 0.2)";
    case "rejected":
      return "rgba(250, 155, 155, 0.4)";
    case "awaiting":
      return "#CECECE";
    case "pending":
      return "rgba(238, 210, 2, 0.2)";
  }
};
export const dmvStatusToChip = (status: DmvStatus) => {
  switch (status) {
    case "not_supported":
      return (
        <Chip
          size="small"
          label={"Not supported"}
          style={{
            fontWeight: "bold",
            border: "1px solid gray",
            background: "darkgray"
          }}
        />
      );
    case "titled":
      return (
        <Chip
          size="small"
          label={"Titled"}
          style={{
            fontWeight: "bold",
            border: "1px solid green",
            background: getDmvColorByStatus(status)
          }}
        />
      );
    case "error":
      return (
        <Chip
          size="small"
          label={"Error"}
          style={{
            fontWeight: "bold",
            border: "1px solid red",
            background: getDmvColorByStatus(status)
          }}
        />
      );
    case "old_title":
      return (
        <Chip
          size="small"
          label={"Old title"}
          style={{
            border: "1px solid yellow",
            fontWeight: "bold",
            background: getDmvColorByStatus(status)
          }}
        />
      );
    case "untitled":
      return (
        <Chip
          size="small"
          label={"Untitled"}
          style={{
            border: "1px solid yellow",
            fontWeight: "bold",
            background: getDmvColorByStatus(status)
          }}
        />
      );
    case "not_checked":
      return (
        <Chip
          size="small"
          label={"Not checked"}
          style={{
            border: "1px solid blue",
            fontWeight: "bold",
            background: getDmvColorByStatus(status)
          }}
        />
      );
  }
};
export const titleStatusToChip = (status: TitleIssueStatus) => {
  switch (status) {
    case "resolved":
      return (
        <Chip
          size="small"
          label={"Approved"}
          style={{
            fontWeight: "bold",
            border: "1px solid green",
            background: titleStatusToColor(status)
          }}
        />
      );
    case "rejected":
      return (
        <Chip
          size="small"
          label={"Rejected"}
          style={{
            fontWeight: "bold",
            border: "1px solid red",
            background: titleStatusToColor(status)
          }}
        />
      );
    case "awaiting":
      return (
        <Chip
          size="small"
          label={"Awaiting"}
          style={{
            border: "1px solid gray",
            fontWeight: "bold",
            background: titleStatusToColor(status)
          }}
        />
      );
    case "pending":
      return (
        <Chip
          size="small"
          label={"Pending Approval"}
          style={{
            border: "1px solid yellow",
            fontWeight: "bold",
            background: titleStatusToColor(status)
          }}
        />
      );
  }
};
const Document = ({ url, status, type, index }: DocumentProps) => {
  const [preview, setPreview] = React.useState(false);
  const [documentStyle, setDocumentStyle] = React.useState<React.CSSProperties>({});

  const handlePreview = () => {
    setPreview(true);
  };
  return (
    <React.Fragment>
      <Grid
        container
        xs={10}
        style={{
          ...documentStyle,
          padding: "5px",
          textAlign: "center",
          cursor: "pointer"
        }}
        onMouseEnter={() => setDocumentStyle({ background: "#cecece33" })}
        onMouseLeave={() => setDocumentStyle({})}
        onClick={handlePreview}
      >
        <Grid item xs={12}>
          <DescriptionIcon style={{ fontSize: "60px", color: titleStatusToColor(status) }} />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Title Document {capitalize(type)} - {index}.pdf
          </Typography>
        </Grid>
      </Grid>
      {preview && (
        <IFrameURLModal
          title="Title Document Preview"
          url={url}
          handleClose={() => setPreview(false)}
        />
      )}
    </React.Fragment>
  );
};
const titleTypeToShortType = (type: DocumentType) => {
  switch (type) {
    case "oldTitleRegistrationInfo":
      return "old";
    case "titleRegistrationInfo":
      return "new";
    case "receiptInfo":
      return "receipt";
  }
};
const renderSingleDocumentSection = (
  stateAccess: StateAccess,
  titleIssue: TitleIssue,
  title: string,
  type: DocumentType,
  handleDelete: (url: string, type: DocumentType) => void
) => {
  return (
    <Paper elevation={2} style={{ width: "100%" }}>
      <Grid item container xs={12}>
        <Grid item xs={12} style={{ textAlign: "left" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px"
              }}
            >
              {capitalize(title)}
            </Typography>

            {titleStatusToChip(titleIssue?.data?.info?.[type]?.status)}
          </div>
        </Grid>
        <Grid container item xs={12} style={{ padding: "10px" }}>
          {titleIssue?.data?.info?.[type]?.documents.map((doc, index) => (
            <Grid item xs={2} key={index} justifyContent="center" container>
              <Document
                type={titleTypeToShortType(type)}
                status={titleIssue?.data?.info?.[type]?.status}
                index={index + 1}
                url={doc}
              />
              {titleIssue?.data?.info?.[type]?.status === "rejected" && (
                <Button color="secondary" onClick={() => handleDelete(doc, type)}>
                  Delete
                </Button>
              )}
            </Grid>
          ))}
        </Grid>
        <DocumentActions stateAccess={stateAccess} type={type} _id={titleIssue._id} />
      </Grid>
    </Paper>
  );
};
export default ({ stateAccess }: Props) => {
  const dispatch = useDispatch();
  const [requestId] = React.useState(uuidv4());

  const titleIssue: TitleIssue = stateAccess.get([]);
  const handleDelete = (url: string, type: DocumentType) => {
    const updatedTitleIssue: TitleIssue = {
      ...titleIssue,
      data: {
        ...titleIssue?.data,
        info: {
          ...titleIssue?.data?.info,
          [type]: {
            ...titleIssue?.data?.info?.[type],
            documents: titleIssue?.data?.info?.[type]?.documents?.filter((doc) => doc !== url)
          }
        }
      }
    };
    dispatch(editTitleIssue({ requestId, ...updatedTitleIssue }));
  };

  return (
    <Paper elevation={3}>
      <Grid container xs={12} style={{ padding: "10px", rowGap: "10px" }}>
        <Grid item xs={12} style={{ textAlign: "left" }}>
          <Typography
            style={{
              color: "rgb(37, 78, 110)",
              fontSize: "19px",
              fontWeight: "bold"
            }}
          >
            Title Documents
          </Typography>
        </Grid>
        {renderSingleDocumentSection(
          stateAccess,
          titleIssue,
          "Copy of the original title (signed by dealer and buyer) if done in person",
          "oldTitleRegistrationInfo",
          handleDelete
        )}
        {renderSingleDocumentSection(
          stateAccess,
          titleIssue,
          "Copy of the completed title application (with lien-holder) for the appropriate state",
          "titleRegistrationInfo",
          handleDelete
        )}
        {renderSingleDocumentSection(
          stateAccess,
          titleIssue,
          "Paid receipt from appropriate state DMV",
          "receiptInfo",
          handleDelete
        )}
      </Grid>
    </Paper>
  );
};
