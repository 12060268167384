import React, { useState, useEffect } from "react";

import { StateAccess, getByPath, setByPath } from "utils/models/formGenerator";
import UserPreview from "components/Users/UserPreview";
import { User } from "components/Users/types";
import { showRenderSet } from "utils/models/formRenderers";
import { useDispatch, useSelector } from "react-redux";
import { editRenderSet } from "utils/models/formRenderers";
import { v4 as uuidv4 } from "uuid";
import { editPartialDeal } from "./editDealSlice";
import { Deal } from "./types";
import { RootState } from "app/rootReducer";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { IconButton } from "@material-ui/core";
import { lockEntityFunction, unlockEntityFunction } from "utils/models/ShowForm";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";

export default function ({ deal }: { deal: Deal }) {
  const { user } = useSelector((state: RootState) => state.authSlice);
  const [data] = useState(deal);
  const [initialState, setInitialState] = useState<User | null | undefined>(deal.data.user);
  const [formState, setFormState] = useState(initialState);
  const [requestId] = useState(uuidv4());
  const editDealState = useSelector((state: RootState) => state?.editDealSlice[requestId]);
  const lockData = useSelector((state: RootState) => state.listLockSlice["all"])?.entities?.find(
    (lock) => lock?.data?.info?.entityId === deal?._id && lock?.data?.info?.entityName === "deal"
  );

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const dealId = deal._id;
  const currentlyLockedEmail = lockData?.data?.info?.userEmail ?? null;

  const canEdit = user?.email === currentlyLockedEmail;
  const isLocked = currentlyLockedEmail !== null && !canEdit;

  const stateAccess: StateAccess = {
    get: (path) => getByPath(formState ?? {}, path as any),
    set: (path, value) => {
      const newState = setByPath(formState ?? {}, path as any, value);
      setFormState(newState);
      return newState;
    }
  };
  useEffect(() => {
    if (editDealState?.status === "success") {
      unlockEntityFunction(dispatch, dealId, "deal");
    }
  }, [editDealState, dealId, dispatch]);
  useEffect(() => {
    if (canEdit || initialState?._id !== deal?.data?.user?._id) {
      setFormState(deal.data.user);
      setInitialState(deal.data.user);
    }
  }, [deal.data.user, canEdit, initialState]);

  useEffect(() => {
    if (
      formState &&
      initialState?._id !== formState?._id &&
      editDealState?.status !== "waiting" &&
      canEdit
    ) {
      dispatch(
        editPartialDeal({
          _id: dealId,
          ...{ data: { userId: formState?._id ?? null } },
          requestId
        })
      );

      setInitialState(formState);
    }
  }, [formState, dispatch, requestId, canEdit, initialState, data, editDealState, dealId]);

  const handleClick = () => {
    if (isLocked) setOpen(true);
    else if (!canEdit) {
      lockEntityFunction(dispatch, dealId, "deal");
    }
  };
  const lockEntity = () => {
    if (!canEdit) {
      lockEntityFunction(dispatch, dealId, "deal");
    }
  };

  const handleClickUnlock = () => {
    lockEntityFunction(dispatch, dealId, "deal");
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        id="confirm-delete-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to unlock this deal?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {lockData?.updatedAt &&
              `Locked by ${lockData?.data?.info?.userEmail}
       ${new Intl.DateTimeFormat("en", {
         day: "2-digit",
         month: "2-digit",
         year: "2-digit",
         hour: "2-digit",
         minute: "2-digit",
         second: "2-digit"
       }).format(new Date(lockData?.updatedAt))}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary" id="cancel">
            Cancel
          </Button>
          <Button
            onClick={handleClickUnlock}
            variant="contained"
            color="secondary"
            autoFocus
            id="confirm"
          >
            Unlock
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ position: "relative" }}>
        <UserPreview
          lockEntity={lockEntity}
          type="f&i manager"
          stateAccess={stateAccess}
          renderSet={!canEdit ? showRenderSet(false) : editRenderSet(false)}
          path={[] as any}
          name="wfd_user"
          style={{ padding: 5, fontSize: 12 }}
          label={""}
        />
        {!canEdit && (
          <IconButton
            id="unlock-manager-button"
            size="small"
            style={{ position: "absolute", bottom: "-1px", right: "5px" }}
            onClick={handleClick}
          >
            <LockOpenIcon style={{ fontSize: "18px" }} />
          </IconButton>
        )}
      </div>
    </>
  );
}
