import { CustomRegexes } from "../../helpers";

export default {
  firstName: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: { pattern: "should not contain numbers" },
  },
  lastName: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: { pattern: "should not contain numbers" },
  },
  phone: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.PHONE,
    errorMessage: "should not contain letters and should not start with 0 or 1",
  },
  email: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.EMAIL,
    errorMessage: "should match the format <emailname>@<provider>.<org>",
  },
};
