import { createSocketMessageSlice } from "components/Middlewares/socketMessageSliceCreator";
import { DeleteOrRecoverContractTypeRequest, DeleteOrRecoverContractTypeResponse } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteOrRecoverContractTypeRequest,
  DeleteOrRecoverContractTypeResponse
>("DeleteContractType", {
  request: "delete_contract_type"
});

export const deleteContractTypeActions = socketMessageSlice.actions;
export const deleteContractType = socketAction;
export default socketMessageSlice.reducer;
