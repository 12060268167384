import { Button, DialogActions, DialogContent, Link, Popover } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { RootState } from "app/rootReducer";
import { XlStyledDialog } from "components/common/StyledDialog";
import formEditContext from "components/Content/FormEditContext";
import { fileDates } from "components/Contracts/model";
import { Coordinates, DocumentTemplate } from "components/DocumentTemplates/types";
import DateFilter from "components/Filters/DateFilter";
import TextFilter from "components/Filters/TextFilter";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityData, EntityType } from "utils/entitySlice";
import formatDate from "utils/formatDate";
import { createOptionsForSelect, Model } from "utils/models/fields";
import {
  fillDefaultsByPath,
  generateDefault,
  generateForm,
  getByPath,
  setByPath,
  StateAccess
} from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";
import { lockEntity } from "utils/models/LockEntity/lockEntitySlice";
import { v4 as uuidv4 } from "uuid";
import DropZone from "../../utils/DropZone";
import AccessControl from "../Access/AccessControl";
import CloseDialogButton from "../common/CloseDialogButton";
import Table, { CellValue, Column, Entry } from "../Table";
import AddFileDialog from "./AddFileDialog";
import { addFile } from "./addFileSlice";
import { deleteFile } from "./deleteFileSlice";
import { editFile } from "./editFileSlice";
import { getFileList } from "./listFileSlice";
import { fileStruct, generateAddFileStruct, generateFileStruct } from "./model";
import { modifyFile } from "./modifyFile";
import { recoverFile } from "./recoverFileSlice";
import { File, FormState } from "./types";

export interface UploadedFile {
  url: string;
  name: string;
  metadata: any;
}
interface Props<T> {
  type: EntityType;
  struct?: Model<T> | ((files: File[]) => Model<T>);
  isForAdditionalContractDocuments?: boolean;
  types?: DocumentTemplate[] | null | undefined;
  _id: string;
  tableName: string;
  showSignatureWarning?: boolean;
}

const checkCoordinatesAndShowWarning = (
  coordinates: Coordinates[],
  showSignatureWarning: boolean
) => {
  const signature = coordinates.find((coordinate) =>
    coordinate?.path?.join("")?.includes("Signature")
  );
  if (signature && showSignatureWarning)
    alert(
      "File with signature placements detected. Check the eSign checkbox in the document template if needed."
    );
};
export default function FilesPreview({
  _id,
  type,
  struct = fileStruct,
  types = null,
  isForAdditionalContractDocuments,
  tableName,
  showSignatureWarning = false
}: Props<File>): JSX.Element {
  const dispatch = useDispatch();
  const sliceId = isForAdditionalContractDocuments
    ? _id + "_additionalContractDocumentsSlice"
    : _id;
  const fileList = useSelector((state: RootState) => state.listFileSlice[sliceId] || []);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [editFormState, setEditFormState] = useState<File | null>(null);
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
  const snakeToCamel = (str: string): string =>
    str.replace(/([_][a-z])/g, (group) => group.toUpperCase().replace("_", ""));

  const typeColumn: Column<FormState> = {
    getData: (entry): CellValue =>
      (entry.data?.info?.types ?? [])
        .map(
          ({
            data: {
              info: { name }
            }
          }) => name
        )
        .join(", "),
    label: "Type",
    name: "type",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.type?.show;
    },
    filters: [
      {
        path: ["data", "info", "types", "data", "info", "name"],
        preview: TextFilter,
        name: "type",
        label: "Type",
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  };
  const columns: Column<FormState>[] = [
    {
      label: "File",
      getData: (entry): CellValue => entry.data.info.name,
      options: {
        customBodyRender: (value, entry): JSX.Element | string => (
          <Link style={{ cursor: "pointer" }} target="_blank" href={entry.data.info.url}>
            {entry.data.info.name}
          </Link>
        )
      },
      name: "name",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      },
      filters: [
        {
          path: ["data", "info", "name"],
          preview: TextFilter,
          name: "name",
          label: "Name",
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      label: "Description",
      getData: (entry): CellValue => entry?.data?.info?.description ?? "",
      name: "description",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.description?.show;
      },
      filters: [
        {
          path: ["data", "info", "description"],
          preview: TextFilter,
          name: "description",
          label: "Description",
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      label: "Date Added",
      getData: (entry): CellValue => formatDate(entry?.createdAt, "medium", true) ?? "",
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter,
          name: "createdAt",
          label: "CreatedAt"
        }
      ]
    },
    {
      label: "Creator",
      // @ts-ignore
      getData: (entry): CellValue => entry?.creator?.data?.info?.email ?? "",
      name: "Creator",
      show: (userPermissions, tableSettings) => {
        return true;
      },
      filters: []
    },
    ...(type === "contract" && !isForAdditionalContractDocuments
      ? [
          {
            label: "Signed date",
            getData: (entry: Entry<FormState>): CellValue =>
              formatDate(entry?.data?.info.contractDates?.signedDate, "medium", false) ?? "",
            name: "signedDate",
            show: (userPermissions: any, tableSettings: any) => {
              return tableSettings?.data?.columns?.signedDate?.show;
            },
            filters: [
              {
                path: ["data", "info", "contractDates", "signedDate"],
                preview: DateFilter,
                name: "signedDate",
                label: "Signed Date"
              }
            ]
          },
          {
            label: "Start date",
            getData: (entry: Entry<FormState>): CellValue =>
              formatDate(entry?.data?.info.contractDates?.startDate, "medium", false) ?? "",
            name: "startDate",
            show: (userPermissions: any, tableSettings: any) => {
              return tableSettings?.data?.columns?.startDate?.show;
            },
            filters: [
              {
                path: ["data", "info", "contractDates", "startDate"],
                preview: DateFilter,
                name: "startDate",
                label: "Start date"
              }
            ]
          },
          {
            label: "End date",
            getData: (entry: Entry<FormState>): CellValue =>
              formatDate(entry?.data?.info.contractDates?.endDate, "medium", false) ?? "",
            name: "endDate",
            show: (userPermissions: any, tableSettings: any) => {
              return tableSettings?.data?.columns?.endDate?.show;
            },
            filters: [
              {
                path: ["data", "info", "contractDates", "endDate"],
                preview: DateFilter,
                name: "endDate",
                label: "End date"
              }
            ]
          },
          {
            label: "Description",
            getData: (entry: Entry<FormState>): CellValue => entry?.data?.info?.description ?? "",
            name: "description",
            show: (userPermissions: any, tableSettings: any) => {
              return tableSettings?.data?.columns?.description?.show;
            },
            filters: [
              {
                path: ["data", "info", "description"],
                preview: TextFilter,
                name: "description",
                label: "Description",
                caseInsensitive: true,
                partialSearch: true
              }
            ]
          }
        ]
      : []),
    {
      label: "Actions",
      getData: (entry: Entry<FormState>): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  if (types !== null && types !== undefined) {
    columns.splice(2, 0, typeColumn);
  }

  const editFormName = `${type}-file-edit`;
  const [editRequestId] = useState(uuidv4());

  const [renderTime, setRenderTime] = useState<undefined | number>();
  const addFileState = useSelector((state: RootState) => state.addFileSlice[editRequestId]);
  const editFileState = useSelector((state: RootState) => state.editFileSlice[editRequestId]);

  const containerRef = useRef<HTMLDivElement>(null);
  const editStateAccess: StateAccess = {
    get: (path) => getByPath(editFormState as any, path),
    set: (path, value): any => {
      setEditFormState(modifyFile(setByPath(editFormState as any, path, value)));
    }
  };

  useEffect(() => {
    if (editFileState?.status === "success") {
      checkCoordinatesAndShowWarning(
        editFileState.data.message.data.info.coordinates ?? [],
        showSignatureWarning
      );
      dispatch(
        lockEntity({
          requestId: uuidv4(),
          data: {
            info: {
              entityId: editFileState.data.message._id || "",
              entityName: "file",
              action: "unlock"
            }
          }
        })
      );
      setOpenEdit(false);
    }
  }, [editFileState, dispatch]);

  useEffect(() => {
    if (addFileState?.status === "success") {
      checkCoordinatesAndShowWarning(
        addFileState.data.message.data.info.coordinates ?? [],
        showSignatureWarning
      );
      setRenderTime(new Date().getTime());
    }
  }, [addFileState, dispatch]);

  const editHandleSubmit = (state: EntityData<"file">) => (event: React.FormEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    const { _id, deleted, createdAt, updatedAt, ...rest } = state;
    dispatch(editFile({ _id, ...rest, requestId: editRequestId }));
  };
  const listFunction = useCallback(
    (_, props) => {
      const andQuery = [
        ...[{ [`data.${snakeToCamel(type)}Id`]: _id }],
        ...(isForAdditionalContractDocuments !== undefined
          ? [{ "data.isAdditionalContractDocument": isForAdditionalContractDocuments }]
          : []),
        ...(Array.isArray(props.query["$and"]) && props.query["$and"].length > 0
          ? props.query["$and"]
          : [])
      ];

      return getFileList(sliceId, {
        ...props,
        query:
          andQuery?.length > 0
            ? {
                $and: andQuery
              }
            : {}
      });
    },
    // eslint-disable-next-line
    [_id, sliceId, type]
  );
  const handleClose = (): void => {
    dispatch(
      lockEntity({
        requestId: uuidv4(),
        data: { info: { entityId: editFormState?._id || "", entityName: "file", action: "unlock" } }
      })
    );
    setOpenEdit(false);
  };
  const { enabled: editMode } = useContext(formEditContext);
  const defaultState = generateDefault(
    typeof struct === "object" ? struct : struct(fileList?.entities ?? ([] as File[])),
    {},
    fillDefaultsByPath as any
  ) as any;

  return (
    <AccessControl requiredPermissions={{ entity: "applicant", action: "read" }}>
      <div id="drag-enter" style={{ position: "relative" }} ref={containerRef}>
        {openEdit ? (
          <XlStyledDialog>
            <DialogContent>
              <CloseDialogButton closeFunction={handleClose} />
              <form
                id={editFormName}
                autoComplete="off"
                onSubmit={editHandleSubmit(editFormState as any)}
              >
                {types !== null
                  ? generateForm(
                      generateFileStruct(
                        createOptionsForSelect({
                          possibleValues: () => types,
                          getOptionLabel: ({
                            data: {
                              info: { name }
                            }
                          }) => name
                        })
                      ),
                      editStateAccess,
                      [],
                      editStateAccess,
                      editRenderSet("update_file")
                    )
                  : generateForm(
                      typeof struct === "object"
                        ? struct
                        : struct(fileList?.entities ?? ([] as File[])),
                      editStateAccess,
                      [],
                      editStateAccess,
                      editRenderSet("update_file")
                    )}
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="contained">
                Cancel
              </Button>

              <Button
                form={editFormName}
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </DialogActions>
          </XlStyledDialog>
        ) : null}
        {openAdd && (
          <AddFileDialog
            requestId={editRequestId}
            renderSet={editRenderSet("update_file")}
            setOpen={setOpenAdd}
            isAdditionalContractDocument={isForAdditionalContractDocuments}
            setUploadedFile={setUploadedFile}
            type={type}
            parentId={_id}
            struct={generateAddFileStruct(
              createOptionsForSelect({
                possibleValues: (stateAccess: StateAccess) => [],
                getOptionLabel: (name) => name
              }),
              type,
              type === "contract" && !isForAdditionalContractDocuments ? fileDates : undefined
            )}
            uploadedFile={uploadedFile}
          />
        )}
        {editMode && (
          <DropZone
            defaultState={defaultState}
            contractFileAddFunction={
              type === "contract"
                ? (file: UploadedFile) => {
                    setOpenAdd(true);
                    setUploadedFile(file);
                  }
                : undefined
            }
            addFunction={addFile}
            type={type}
            id={_id}
            containerRef={containerRef}
            requestId={editRequestId}
          />
        )}
        <Table
          tableName={tableName}
          externalRenderTime={renderTime}
          parentId={_id}
          entityName="file"
          listFunction={listFunction}
          listEntity={fileList}
          deleteEntityFunction={deleteFile}
          recoverEntityFunction={recoverFile}
          slice={sliceId}
          title={
            <>
              <h4 style={{ display: "inline-block" }}>Files</h4>
              <span style={{ paddingLeft: "5px", fontSize: "10px" }}>(Drag files to upload)</span>
            </>
          }
          columns={columns}
          hideAddButton={false}
          openShowEditInModal={true}
          addEntityFunction={() => setOpenAdd(true)}
          editAction={(entry): void => {
            dispatch(
              lockEntity({
                requestId: uuidv4(),
                data: {
                  info: {
                    entityId: entry._id,
                    entityName: "file",
                    action: "lock"
                  }
                }
              })
            );
            setEditFormState(entry);
            setOpenEdit(true);
          }}
        />
      </div>
    </AccessControl>
  );
}
