import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import EditIcon from "@material-ui/icons/Edit";
import { css, StyleSheet } from "aphrodite";
import clsx from "clsx";
import { TabContext } from "components/Layout/LayoutWrapper";
import { QuickAccessTab } from "components/Tabs/types";
import { useStickyState } from "index";
import React, { useContext, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
  NotDraggingStyle
} from "react-beautiful-dnd";
import toCamelCase from "utils/toCamelCase";
import CloseDialogButton from "../common/CloseDialogButton";
import { pages } from "../Drawer/DrawerLinks";
import { Pages } from "../Drawer/types";
import { IndexedQuickAccessItem, QuickAccessTabType } from "./types";

const animationStyles = StyleSheet.create({
  shake: {
    animationName: {
      "25%": {
        transform: "rotate(1deg)"
      },
      "50%": {
        transform: "rotate(-1deg)"
      }
    },
    animationDuration: "1s",
    animationIterationCount: "infinite"
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerPaper: {
      flex: 1,
      height: "60px",
      display: "flex",
      color: "#ffffff",
      backgroundColor: "#254e70"
    },
    quickAccessButton: {
      margin: "auto",
      textAlign: "center",
      userSelect: "none"
    },
    paperWrapper: {
      padding: "25px 25px 15px 25px",
      paddingTop: "5px",
      marginBottom: "17px"
    },
    quickAccessButtonsBox: {
      display: "flex",
      marginBottom: "8px"
    },
    quickAccessLabel: {
      marginTop: "2px",
      flex: 8,
      alignSelf: "flex-start",
      fontSize: "19px"
    },
    removeButton: {
      position: "absolute",
      right: 0,
      marginTop: "-20px",
      marginRight: "-20px"
    },
    removeButtonIcon: {
      fill: "#ffffff",
      backgroundColor: "#254E70",
      borderRadius: "30px"
    },
    column: {
      flexBasis: "33.33%"
    },
    helper: {
      marginLeft: "15px",
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2)
    },
    draggableWrapper: {
      "&:hover": {
        cursor: "pointer"
      }
    }
  })
);

const indexSidebarLinks = <T extends QuickAccessTabType>(links: Pages) => {
  const indexedSidebarLinks: IndexedQuickAccessItem<T>[] = [];
  const visibleLinks = Object.fromEntries(
    Object.entries(links).filter(([id, el]) => el.show())
  ) as Pages;

  Object.keys(visibleLinks).map((key, index: number) => {
    return (indexedSidebarLinks[index] = {
      ...visibleLinks[key as QuickAccessTabType],
      ...{ key: key as QuickAccessTabType, _id: index.toString() }
    } as any);
  });

  return Object.values(indexedSidebarLinks).filter((el) => el.isForQuickAccess === true);
};

export const sidebarLinksToTabs = (links: Pages): QuickAccessTab[] =>
  Object.entries(links).reduce((acc, [, link]): QuickAccessTab[] => {
    if (link.index === "showPage" || link.index === "addPage" || !link.show()) {
      return acc;
    }
    return [
      ...acc,
      {
        _id: link.index,
        label: link.label,
        index: link.index,
        isForSidebar: link.isForSidebar,
        isForQuickAccess: link.isForQuickAccess,
        isPersistent: link.isPersistent
      } as QuickAccessTab
    ];
  }, [] as QuickAccessTab[]);
const reorder = (list: QuickAccessTab[], startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  flex: 1,
  margin: "0 5px",
  ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean) => ({
  backgroundColor: isDraggingOver ? "lightblue" : "white",
  padding: "5px",
  borderRadius: "7px",
  display: "flex",
  justifyContent: "space-between",
  margin: "0 -15px"
});

const RealQuickAccess = ({
  createOrFocusTab
}: {
  createOrFocusTab: (tabInfo: QuickAccessTab) => void;
}) => {
  const [toggleEdit, setToggleEdit] = useState(true);
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [quickAccessState, setQuickAccessState] = useStickyState<QuickAccessTab[]>(
    sidebarLinksToTabs(pages),
    "quick-access-buttons"
  );

  return React.useMemo(() => {
    const handleToggleDialog = () => {
      setOpenDialog(!openDialog);
    };
    const handleToggleVisible = (el: any, add?: boolean) => {
      setQuickAccessState(
        add
          ? [...quickAccessState, el]
          : quickAccessState.filter((x: { index: string }) => x.index !== el.index)
      );
    };
    const handleEditButton = () => {
      setToggleEdit((toggleEdit) => !toggleEdit);
    };
    const onDragEnd = (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const reorderedItems = reorder(
        quickAccessState,
        result.source.index,
        result.destination.index
      );

      setQuickAccessState(reorderedItems);
    };

    return (
      <Paper className={classes.paperWrapper} elevation={3}>
        <Box className={classes.quickAccessButtonsBox}>
          <Typography className={classes.quickAccessLabel} variant="subtitle1">
            Quick access
          </Typography>
          <IconButton aria-label="edit" size="small" onClick={() => handleEditButton()}>
            <EditIcon />
          </IconButton>
        </Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal" isDropDisabled={toggleEdit}>
            {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
              <div
                ref={provided.innerRef}
                style={{ flexWrap: "wrap", ...getListStyle(snapshot.isDraggingOver) }}
                {...provided.droppableProps}
              >
                {quickAccessState.map((el: QuickAccessTab, index: number) => (
                  <Draggable
                    key={el.index}
                    draggableId={el.index}
                    index={index}
                    isDragDisabled={toggleEdit}
                  >
                    {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div
                          className={
                            !toggleEdit ? css(animationStyles.shake) : classes.draggableWrapper
                          }
                          style={{ marginBottom: "10px" }}
                        >
                          <IconButton
                            aria-label="remove-from-list"
                            className={classes.removeButton}
                            style={{ display: !toggleEdit ? "block" : "none" }}
                            onClick={() => handleToggleVisible(el)}
                          >
                            <CancelRoundedIcon className={classes.removeButtonIcon} />
                          </IconButton>
                          <Paper
                            className={classes.innerPaper}
                            id={`quickAccess-${toCamelCase(el.label)}`}
                            elevation={3}
                            onClick={() => {
                              createOrFocusTab(el);
                            }}
                          >
                            <p
                              style={{ padding: "0px 5px", whiteSpace: "nowrap" }}
                              className={classes.quickAccessButton}
                            >
                              {el.label}
                            </p>
                          </Paper>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!toggleEdit ? (
          <Box style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
            <Button variant="outlined" color="primary" onClick={handleToggleDialog}>
              Show/hide shortcuts
            </Button>
          </Box>
        ) : undefined}
        <Dialog open={openDialog} keepMounted onClose={handleToggleDialog}>
          <DialogTitle id="alert-dialog-slide-title">
            Select quick access shortcuts <CloseDialogButton closeFunction={handleToggleDialog} />
          </DialogTitle>
          <DialogContent>
            {indexSidebarLinks(pages).map(
              <T extends QuickAccessTabType>(el: IndexedQuickAccessItem<T>, index: number) => (
                <Box key={index} style={{ display: "flex", marginBottom: 15 }}>
                  <Switch
                    checked={
                      quickAccessState.find(
                        ({ index }: { index: string }) => el.index === index
                      ) !== undefined
                    }
                    onChange={(event) => handleToggleVisible(el, event.target.checked)}
                    color="primary"
                  />
                  <div className={clsx(classes.column, classes.helper)}>
                    <Typography variant="body1">{el.label}</Typography>
                  </div>
                </Box>
              )
            )}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleToggleDialog} color="primary" variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }, [classes, createOrFocusTab, openDialog, quickAccessState, setQuickAccessState, toggleEdit]);
};

export default () => {
  const createOrFocusTab = useContext(TabContext);

  return <RealQuickAccess createOrFocusTab={createOrFocusTab} />;
};
