import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Applicant } from "components/Applicants/types";
import { Dealership } from "components/Dealerships/types";
import { Deal } from "components/Deals/types";
import { CreateOrFocusTabType, TabContext } from "components/Layout/LayoutWrapper";
import { Lender } from "components/Lenders/types";
import React, { useContext } from "react";
import { GlobalSearchEntityType } from "./types";

const useStyles = makeStyles(() =>
  createStyles({
    tableRow: {
      "&:hover": {
        cursor: "pointer"
      }
    }
  })
);
const getColSpan = (entityType: GlobalSearchEntityType) => {
  switch (entityType) {
    case "applicant":
    case "dealership":
      return 4;
    case "deal":
      return 5;
    case "lender":
      return 1;
  }
};
const hasOwnDeepProperty = (obj: any, path: string) => {
  if (!path) return false;

  const properties = path.split(".");

  for (let i = 0; i < properties.length; i++) {
    const prop = properties[i];

    if (!obj || !obj.hasOwnProperty(prop)) {
      return false;
    } else {
      obj = obj[prop];
    }
  }

  return true;
};
const renderTableCells = (
  entity: Applicant | Deal | Dealership | Lender,
  entityType: GlobalSearchEntityType
) => {
  switch (entityType) {
    case "deal":
      const deal = entity as Deal;
      return (
        <>
          <TableCell component="th" scope="row">
            {`${deal.data?.applicant?.data?.info?.firstName} ${deal.data?.applicant?.data?.info?.lastName}`}
            {deal.data?.coApplicantId
              ? ` / ${deal.data?.coApplicant?.data?.info?.firstName} ${deal.data?.coApplicant?.data?.info?.lastName}`
              : ""}
          </TableCell>
          <TableCell align="right">{deal.data.info.refNumber}</TableCell>
          <TableCell align="right">{deal.data?.dealership?.data?.info?.name}</TableCell>
          <TableCell align="right">{deal.data?.lender?.data?.info?.name}</TableCell>
          <TableCell align="right">{deal.data?.info?.status}</TableCell>
        </>
      );
    case "dealership":
      const dealership = entity as Dealership;
      return (
        <>
          <TableCell component="th" scope="row">
            {dealership.data.info.displayName}
          </TableCell>
          <TableCell align="right">{dealership.data.info.city}</TableCell>
          <TableCell align="right">{dealership.data.info.zipCode}</TableCell>
          <TableCell align="right">
            {dealership?.data?.contacts?.[0]
              ? dealership.data.contacts[0].phone
              : "No phone provided"}
          </TableCell>
        </>
      );
    case "applicant":
      const applicant = entity as Applicant;
      return (
        <>
          <TableCell component="th" scope="row">
            {applicant.data.info.firstName} {applicant.data.info.lastName}
          </TableCell>
          <TableCell align="right">{applicant.data.info.currentCity}</TableCell>
          <TableCell align="right">{applicant.data.info.currentZipCode}</TableCell>
          <TableCell align="right">{applicant.data.info.mobilePhone}</TableCell>
        </>
      );
    case "lender":
      const lender = entity as Lender;
      return (
        <>
          <TableCell component="th" scope="row">
            {lender.data.info.name}
          </TableCell>
        </>
      );
    default:
      return <></>;
  }
};

const renderTableBody = (
  entity: Applicant | Deal | Dealership | Lender,
  entityType: GlobalSearchEntityType,
  index: number,
  createOrFocusTab: (tabInfo: CreateOrFocusTabType) => void,
  classes: ClassNameMap<"tableRow">
) => {
  return (
    <TableRow
      id={`${entityType}-result-${index}`}
      hover
      className={classes.tableRow}
      onMouseDown={() =>
        createOrFocusTab({
          label: "Show page",
          index: "showPage",
          isForSidebar: false,
          isForQuickAccess: false,
          isPersistent: false,
          props: {
            _id: entity._id,
            type: entityType
          }
        })
      }
      key={index}
    >
      {renderTableCells(entity, entityType)}
    </TableRow>
  );
};
const renderTableHead = (entityType: GlobalSearchEntityType) => {
  switch (entityType) {
    case "applicant":
    case "dealership":
      return (
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="right">City</TableCell>
          <TableCell align="right">Zip code</TableCell>
          <TableCell align="right">Phone</TableCell>
        </TableRow>
      );
    case "deal":
      return (
        <TableRow>
          <TableCell>Customer</TableCell>
          <TableCell align="right">Ref #</TableCell>
          <TableCell align="right">Dealership</TableCell>
          <TableCell align="right">Lender</TableCell>
          <TableCell align="right">Status</TableCell>
        </TableRow>
      );
    case "lender":
      return (
        <TableRow>
          <TableCell>Name</TableCell>
        </TableRow>
      );
  }
};

export default function ResultsTable({
  entities,
  entityType
}: {
  entities: Applicant[] | Deal[] | Lender[] | Dealership[] | undefined | null;
  entityType: GlobalSearchEntityType;
}) {
  const classes = useStyles();
  const createOrFocusTab = useContext(TabContext);

  return (
    <TableContainer>
      <Table size="small" aria-label={`${entityType} results table`}>
        {entities && entities.length > 0 ? (
          <>
            <TableHead>{renderTableHead(entityType)}</TableHead>
            <TableBody>
              {entities.map((el: Lender | Dealership | Applicant | Deal, index: number) =>
                renderTableBody(el, entityType, index, createOrFocusTab, classes)
              )}
            </TableBody>
          </>
        ) : entities && entities.length === 0 ? (
          <TableRow key={"no-results"}>
            <TableCell key="no-results" colSpan={getColSpan(entityType)}>
              <Typography style={{ fontStyle: "italic", margin: "auto", textAlign: "center" }}>
                No matching records found
              </Typography>
            </TableCell>
          </TableRow>
        ) : (
          <TableRow key={"loading-row"}>
            <TableCell
              colSpan={getColSpan(entityType)}
              style={{
                textAlign: "center",
                padding: "10px"
              }}
              key={"loading-row"}
            >
              <CircularProgress style={{ margin: "auto" }} size="1.8rem" />
            </TableCell>
          </TableRow>
        )}
      </Table>
    </TableContainer>
  );
}
