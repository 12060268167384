import Grid from "@material-ui/core/Grid";
import React from "react";
import { trimString } from "utils/functions";
import { editRenderSet } from "utils/models/formRenderers";
import { FilterProps, filterStateAccess, hasMultiplePaths } from "./types";

export default function ApplicantFilter<T extends unknown>({
  setFilter,
  filter,
  filterType,
  path,
  label
}: FilterProps<T>) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {editRenderSet(false).multiTextInputFieldRenderer(
          {
            width: "full",
            type: "text",
            name: label?.toLowerCase() ?? "customer-multi-filter",
            label: label ?? "N/A",
            required: false,
            path: [] as []
          },
          filterStateAccess(filter, setFilter, (values: any) => {
            if (hasMultiplePaths(path) && values && values?.length > 0) {
              return {
                ...(filterType === "aggregatedColumnFilter" ? { filterType } : {}),
                $or: [
                  ...path.map((childPath) => ({
                    [childPath.join(".")]: {
                      $in: values.map((value: any) => ({
                        value: trimString(value),
                        caseInsensitive: true,
                        partialSearch: true
                      }))
                    }
                  }))
                ]
              };
            } else {
              setFilter(undefined);
            }
          }),
          editRenderSet(false),
          false,
          []
        )}
      </Grid>
    </Grid>
  );
}
