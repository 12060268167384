import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { EditLenderRequestSend, EditLenderRequest, OperatesInStateStatus } from "./types";

interface EditLenderResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditLenderRequestSend,
  EditLenderResponse
>("EditLender", {
  request: "update_lender"
});

export const editLenderActions = socketMessageSlice.actions;

export const editLender = (request: EditLenderRequest & Identifiable) => {
  const {
    additionalRequiredDocuments,
    requiredContractTypes,
    currentUser,
    oldOperatingStates,
    ...rest
  } = request.data;
  const statusComment = rest?.info?.operatingStates?.comment;

  return socketAction({
    ...request,
    data: {
      ...rest,
      info: {
        ...rest.info,
        ...(rest?.info?.operatingStates
          ? {
              operatingStates: {
                ...rest?.info?.operatingStates,
                comment: undefined
              }
            }
          : {}),
        statusHistory: [
          ...(rest?.info?.statusHistory ?? []),
          ...(statusComment && currentUser?.data?.info?.email && rest?.info?.operatingStates
            ? [
                {
                  comment: statusComment,
                  userEmail: currentUser?.data?.info?.email,
                  date: new Date().toISOString(),
                  oldData: oldOperatingStates,
                  newData: {
                    ...rest?.info?.operatingStates,
                    comment: undefined
                  },
                  status: rest?.info?.operatingStates?.status
                }
              ]
            : [])
        ]
      },
      additionalRequiredDocumentsIds: additionalRequiredDocuments?.map((el) => el._id),
      requiredContractTypesIds: requiredContractTypes?.map((el) => el._id)
    }
  });
};

export default socketMessageSlice.reducer;
