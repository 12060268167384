import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { RootState } from "app/rootReducer";
import formEditContext from "components/Content/FormEditContext";
import { HintTooltip } from "components/common/HintTooltip";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { DealerNote } from "./types";

import { Help, Warning } from "@material-ui/icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelCircleIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MailIcon from "@material-ui/icons/Mail";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CloseDialogButton from "components/common/CloseDialogButton";
import useNotifyDealerNotes from "hooks/useNotifyDealerEmails/useNotifyDealerEmails";
import formatDate from "utils/formatDate";
import { addDealerNote } from "./addDealerNoteSlice";
import { deleteDealerNote } from "./deleteDealerNoteSlice";
import { editDealerNote } from "./editDealerNoteSlice";
import { getDealerNotesList, listDealerNotesActions } from "./listDealerNoteSlice";

interface Props {
  dealerNotes: DealerNote[];
  dealId: string;
  assignedDealershipEmails: string[];
}
export default function DealerNotesPreview({
  dealerNotes,
  dealId,
  assignedDealershipEmails
}: Props): JSX.Element {
  const dispatch = useDispatch();

  const [addRequestId] = React.useState(uuidv4());
  const [deleteRequestId] = React.useState(uuidv4());
  const [deleteDialog, setDeleteDialog] = React.useState<{
    open: Boolean;
    note: DealerNote | undefined;
  }>({ open: false, note: undefined });
  const [timeout, setTimeoutState] = React.useState<undefined | NodeJS.Timeout>();

  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);

  const addDealerNoteState = useSelector(
    (state: RootState) => state.addDealerNoteSlice[addRequestId]
  );

  const deleteDealerNoteState = useSelector(
    (state: RootState) => state.deleteDealerNoteSlice[deleteRequestId]
  );

  const listDealerNoteState = useSelector((state: RootState) => state.listDealerNoteSlice[dealId]);
  const allEditNoteStates = useSelector((state: RootState) => state.editDealerNoteSlice);
  const editDealerNoteStates = listDealerNoteState?.entities?.map((note) => ({
    _id: note._id,
    state: allEditNoteStates[note._id]
  }));
  const isLoading =
    addDealerNoteState?.status === "waiting" || deleteDealerNoteState?.status === "waiting";

  const { enabled: editMode } = React.useContext(formEditContext);

  const { notifyDealerNotes, loading: notifyDealerNotesLoading } = useNotifyDealerNotes();
  React.useEffect(() => {
    if (!listDealerNoteState && editMode)
      dispatch(
        getDealerNotesList(dealId, {
          query: { deleted: false, "data.dealId": dealId },
          options: { sort: { createdAt: -1 }, limit: 99999999 }
        })
      );
  }, [dispatch, editMode]);

  React.useEffect(() => {
    if (
      deleteDealerNoteState !== undefined &&
      deleteDealerNoteState.status === "success" &&
      deleteDealerNoteState.data !== null
    ) {
      const index = listDealerNoteState.entities?.findIndex(
        (n: DealerNote) => n._id === deleteDealerNoteState?.data?.message?._id
      );
      dispatch({
        type: listDealerNotesActions.editList.type,

        payload: { index: index, payload: deleteDealerNoteState?.data?.message, listId: dealId }
      });
    }
  }, [deleteDealerNoteState, dispatch]);
  const handleNotifyDealerEmail = () => {
    const notes = (listDealerNoteState?.entities ?? dealerNotes)
      .filter((n) => !n.deleted && !n.data.info.sentToDealerEmail)
      .map((n) => n._id);
    if (notes?.length) {
      notifyDealerNotes({ dealerNotesIds: notes, emails: assignedDealershipEmails });
    }
  };
  const handleAdd = () => {
    const defaultDealerNote = {
      data: {
        dealId,
        info: {
          type: "f&i manager",
          note: "",
          user: {
            firstName: currentUser?.data?.info?.firstName || null,
            lastName: currentUser?.data?.info?.lastName || null,
            email: currentUser?.data?.info?.email || null
          }
        }
      }
    };
    dispatch(addDealerNote({ requestId: addRequestId, ...(defaultDealerNote as DealerNote) }));
  };

  const handleDelete = (dealerNote: DealerNote) => {
    dispatch(deleteDealerNote({ requestId: deleteRequestId, _id: dealerNote._id }));
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    dealerNote: DealerNote & { index: number }
  ) => {
    const newDealerNote = {
      ...dealerNote,
      data: { ...dealerNote.data, info: { ...dealerNote.data.info, note: event.target.value } },
      updatedAt: new Date().toISOString()
    };
    dispatch({
      type: listDealerNotesActions.editList.type,

      payload: { index: dealerNote.index, payload: newDealerNote, listId: dealId }
    });
    return newDealerNote;
  };
  const handleUpdate = (dealerNote: DealerNote) => {
    dispatch(editDealerNote({ requestId: dealerNote._id, ...dealerNote }));
  };
  const renderEntities = () => {
    return (listDealerNoteState?.entities ?? dealerNotes)
      .map((n, index) => ({ ...n, index }))
      .filter((n) => !n.deleted)
      .sort((a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime())
      ?.map((dealerNote, index) => {
        const state = editDealerNoteStates?.find((state) => state._id === dealerNote._id)?.state;

        return dealerNote.data.info.type === "dealer" ? (
          <Paper
            key={index}
            style={{
              padding: "10px",
              marginBottom: "20px",
              display: "block",
              backgroundColor: "#6b97f6",
              color: "#fff"
            }}
          >
            <Box display="flex" alignContent="flex-start" flexDirection="column">
              <Typography variant="h6">Note from dealer</Typography>
              <Box fontSize={12} display="flex" style={{ justifyContent: "space-between" }}>
                <Typography variant="body1" style={{ overflowWrap: "anywhere" }}>
                  {" "}
                  {dealerNote?.data?.info?.note}
                </Typography>

                {[
                  dealerNote.data?.info?.user?.firstName,
                  dealerNote?.data?.info?.user?.lastName,
                  formatDate(dealerNote?.createdAt, "medium", true)
                ]
                  .filter((x) => x)
                  .join(" ")}
              </Box>
            </Box>
          </Paper>
        ) : (
          <Paper
            key={index}
            style={{
              padding: "10px",
              marginBottom: "20px",
              display: "block",
              background: state?.status === "error" ? "#ffe3e3" : undefined
            }}
          >
            <Box display="flex" alignContent="flex-start">
              <Box flexGrow={1}>
                <TextField
                  multiline
                  onChange={(event) => {
                    if (timeout) {
                      clearTimeout(timeout);
                    }
                    const newNote = handleChange(event, dealerNote);
                    setTimeoutState(
                      setTimeout(() => {
                        handleUpdate(newNote);
                      }, 500)
                    );
                  }}
                  value={dealerNote?.data?.info?.note}
                  name="dealerNote"
                  inputProps={
                    editMode && listDealerNoteState?.status !== "waiting"
                      ? {}
                      : {
                          readOnly: true
                        }
                  }
                  placeholder="Note"
                  fullWidth
                  label={"Note"}
                  variant={
                    editMode && listDealerNoteState?.status !== "waiting" ? "filled" : "outlined"
                  }
                  size="small"
                  onBlur={(event) => {
                    if (event.target.value !== dealerNote?.data?.info?.note) {
                      const newNote = handleChange(event, dealerNote);
                      handleUpdate(newNote);
                    }
                  }}
                />
                <Box
                  fontSize={12}
                  justifyContent="space-between"
                  display={"flex"}
                  marginTop={"10px"}
                  style={{ maxHeight: 20 }}
                >
                  <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                    {(() => {
                      switch (state?.status) {
                        case "waiting":
                          return (
                            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                              <CircularProgress
                                size={17}
                                style={{
                                  color: "#50A538",
                                  // marginRight: 5,
                                  minHeight: 20.5,
                                  maxHeight: 20.5
                                }}
                              />
                              Saving
                            </div>
                          );
                        case "error":
                          return (
                            <button
                              className="pulse hover"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                border: "none",
                                background: "#ffe3e3",
                                fontWeight: "bold",
                                columnGap: "3px",
                                borderRadius: 3
                              }}
                              onClick={() => {
                                handleUpdate(dealerNote);
                              }}
                            >
                              <CancelCircleIcon
                                style={{
                                  color: "#E34C28"
                                }}
                              />
                              Error! Click here to retry!
                            </button>
                          );
                        default:
                          return (
                            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                              <CheckCircleIcon
                                style={{
                                  color: "#50A538"
                                }}
                              />
                              Saved
                            </div>
                          );
                      }
                    })()}
                    {(() => {
                      if (notifyDealerNotesLoading && !dealerNote?.data?.info?.sentToDealerEmail) {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <CircularProgress
                              size={17}
                              style={{
                                color: "#50A538",
                                minHeight: 20.5,
                                maxHeight: 20.5
                              }}
                            />
                            Sending
                          </div>
                        );
                      }
                      if (dealerNote?.data?.info?.sentToDealerEmail) {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <MailIcon
                              style={{
                                color: "rgb(37, 78, 110)"
                              }}
                            />
                            Sent notification and email at{" "}
                            {formatDate(dealerNote?.data?.info?.sentToDealerEmailDate, "short")}
                          </div>
                        );
                      } else {
                        return (
                          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <MailIcon
                              style={{
                                color: "#E34C28"
                              }}
                            />
                            Not sent to dealer
                          </div>
                        );
                      }
                    })()}
                  </div>
                  {[
                    dealerNote.data?.info?.user?.firstName,
                    dealerNote?.data?.info?.user?.lastName,
                    formatDate(dealerNote?.createdAt, "medium", true)
                  ]
                    .filter((x) => x)
                    .join(" ")}
                </Box>
              </Box>
              {editMode && (
                <Box>
                  <IconButton
                    style={{
                      color: "#E34C28"
                    }}
                    key={index}
                    aria-label={`remove dealer note`}
                    onClick={() => setDeleteDialog({ open: true, note: dealerNote })}
                    disabled={deleteDealerNoteState?.status === "waiting"}
                  >
                    <HintTooltip title={`Click here to remove the dealer note.`}>
                      <RemoveCircleIcon />
                    </HintTooltip>
                  </IconButton>
                </Box>
              )}
            </Box>
          </Paper>
        );
      });
  };
  const cantSendEmail = !(
    notifyDealerNotesLoading ||
    editDealerNoteStates?.some((state) => state?.state?.status === "waiting") ||
    assignedDealershipEmails.length > 0
  );

  return (
    <Paper
      elevation={0}
      style={{
        breakInside: "avoid",
        pageBreakInside: "avoid",
        transform: "translateZ(1)"
      }}
    >
      <Box style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <DialogContentText
          style={{
            color: "#254e6e",
            fontSize: "19px",
            margin: "5px 0px 5px 0px",

            fontWeight: "bold"
          }}
        >
          Dealer Notes
        </DialogContentText>
        <HintTooltip
          style={{ color: "#254e6e", fontSize: "20px", marginLeft: 4 }}
          title={
            <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
              Autosaved / Visible to dealer
            </div>
          }
        >
          <Help />
        </HintTooltip>

        {editMode && (
          <>
            <IconButton
              style={{
                color: "#50A538"
              }}
              aria-label={`add new dealer note`}
              onClick={handleAdd}
              disabled={isLoading}
            >
              <HintTooltip title={`Click here to add new dealer note.`}>
                {isLoading ? <CircularProgress size={19} /> : <AddCircleIcon />}
              </HintTooltip>
            </IconButton>
            <HintTooltip
              title={
                <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
                  You should first select emails to notify in order to send emails to the dealership
                </div>
              }
            >
              <Button
                variant="outlined"
                style={{ fontSize: "10px", marginLeft: "auto", padding: 5, height: 30 }}
                startIcon={cantSendEmail && <Warning style={{ color: "#eed202" }} />}
                onClick={cantSendEmail ? undefined : handleNotifyDealerEmail}
              >
                Send notes to dealer
              </Button>
            </HintTooltip>
          </>
        )}
      </Box>

      {renderEntities()}
      {deleteDialog.open && (
        <Dialog
          id="delete-dealer-note-dialog"
          open={true}
          onClose={() => setDeleteDialog({ open: false, note: undefined })}
        >
          <DialogTitle id="delete-dealer-note-dialog-title">
            <CloseDialogButton
              closeFunction={() => setDeleteDialog({ open: false, note: undefined })}
            />
            <Typography variant="h6">Delete dealer note</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle2" style={{ fontSize: "14px" }}>
              Are you sure you want to delete this dealer note?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDeleteDialog({ open: false, note: undefined })}
              variant="contained"
              color="primary"
              id="cancel"
            >
              Close
            </Button>
            <Button
              onClick={() => {
                if (deleteDialog.note) {
                  handleDelete(deleteDialog.note);
                  setDeleteDialog({ open: false, note: undefined });
                }
              }}
              variant="contained"
              autoFocus
              color="secondary"
              id="confirm"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  );
}
