import Big from "big.js";
import React from "react";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";

import { getPercentOfNumber } from "utils/functions";
import { RenderSet, StateAccess } from "utils/models/formGenerator";

import { Chargeback } from "./types";
import { calculateCargebackPercentages } from "./calculateChargeback";

type SnackbarProps = { type: "success" | "error"; message: string };

export const calculateAmounts = (
  stateAccess: StateAccess,
  showSnackBar: (props: SnackbarProps) => void
) => {
  const chargeback: Chargeback = stateAccess.get([]);
  const vendor = chargeback?.data?.info?.vendor;
  const {
    type,
    percentUsed,
    useExtraProfit,
    extraProfitIncluded,
    totalChargebackAmount
  } = chargeback?.data?.info;

  const profit = chargeback?.data?.deal?.profit;
  const accounting = chargeback?.data?.deal?.accounting?.copied
    ? chargeback?.data?.deal?.accounting?.profit
    : profit;

  const extraProfit =
    (type === "Reserve"
      ? accounting?.wfdProfit?.extraReserveProfit
      : type === "GAP"
      ? accounting?.wfdProfit?.extraGAPProfit
      : accounting?.wfdProfit?.extraServiceWarrantyProfit) || 0;

  if (
    type &&
    totalChargebackAmount &&
    profit &&
    percentUsed !== null &&
    percentUsed !== undefined
  ) {
    const actualExtraProfit = useExtraProfit
      ? getPercentOfNumber(extraProfit, Number(percentUsed))
      : extraProfitIncluded
      ? getPercentOfNumber(extraProfit, new Big(100).sub(new Big(percentUsed)).toNumber())
      : 0;
    console.log(actualExtraProfit, extraProfit, extraProfitIncluded, useExtraProfit);

    const dealProfitPercentages = calculateCargebackPercentages(profit);

    const percentages = dealProfitPercentages[type];

    const total =
      useExtraProfit || extraProfitIncluded
        ? new Big(totalChargebackAmount || 0).sub(actualExtraProfit)
        : new Big(totalChargebackAmount || 0);

    const wfdChargebackAmountWithoutExtra = total.times(new Big(percentages?.wfd || 0).div(100));

    const wfdChargebackAmount =
      useExtraProfit || extraProfitIncluded
        ? wfdChargebackAmountWithoutExtra.add(new Big(actualExtraProfit))
        : wfdChargebackAmountWithoutExtra;

    const dealerChargebackAmount = total
      .times(new Big(percentages?.dealer || 0).div(100))
      .toNumber();
    const managerChargebackAmount = wfdChargebackAmount
      .times(new Big(percentages?.manager || 0).div(100))
      .toNumber();

    const wfdChargebackCalculation = `${wfdChargebackAmountWithoutExtra}(${percentages.wfd}% of ${total} ${type} profit) + ${actualExtraProfit}(Extra profit Total: ${extraProfit} Used: ${percentUsed}%) = ${wfdChargebackAmount}`;
    const dealerChargebackCalculation = `${percentages.dealer}% of ${total}( ${type} profit) = ${dealerChargebackAmount}`;
    const managerChargebackCalculation = `${percentages.manager}% of ${wfdChargebackAmount} = ${managerChargebackAmount}`;
    const netWFDChargeBackAmount = wfdChargebackAmount.sub(managerChargebackAmount).toNumber();
    stateAccess.set(["data", "info"], {
      ...chargeback.data.info,
      wfdChargebackAmount: wfdChargebackAmount.toNumber(),
      dealerChargebackAmount,
      netWFDChargeBackAmount,
      managerChargebackAmount,
      wfdChargebackCalculation,
      dealerChargebackCalculation,
      managerChargebackCalculation
    });
    showSnackBar({ message: "Successfully calculated chargeback amounts.", type: "success" });
  } else {
    showSnackBar({
      message: "Fill in Total Amount and Chargeback Type to calculate amounts.",
      type: "error"
    });
  }
};

export default function CalculateChargebackAmount({
  stateAccess,
  renderSet
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) {
  const chargeback: Chargeback = stateAccess.get([]);
  const { enqueueSnackbar } = useSnackbar();

  const showSnackBar = ({ message, type }: SnackbarProps) =>
    enqueueSnackbar(message, { variant: type });

  if (renderSet.type === "edit" && chargeback?.data?.deal?.profit)
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={() => calculateAmounts(stateAccess, showSnackBar)}
      >
        Calculate Chargeback Amounts
      </Button>
    );
  return null;
}
