import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import {
  EditFundingDocumentRequestSend,
  EditFundingDocumentRequest,
  FundingDocument
} from "./types";
import { AppDispatch } from "../../app/store";

interface EditFundingDocumentResponse {
  message: FundingDocument;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditFundingDocumentRequestSend,
  EditFundingDocumentResponse
>("EditFundingDocument", {
  request: "update_funding_document"
});

export const editFundingDocumentActions = socketMessageSlice.actions;
export const editFundingDocument = (
  request: EditFundingDocumentRequest & Identifiable
): ((dispatch: AppDispatch) => Promise<void>) => {
  const { updatedAt, ...rest } = request;
  const { deal, printedDocument, ...restData } = rest.data;
  return socketAction({
    ...rest,
    data: {
      ...restData,
      dealId: deal?._id ?? null,
      printedDocumentId: printedDocument?._id ?? null
    }
  });
};
export default socketMessageSlice.reducer;
