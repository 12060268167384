import { CustomRegexes } from "../../helpers";

export default {
  name: {
    type: "string",
    pattern: CustomRegexes.LETTERS,
    errorMessage: {
      pattern: "should not contain numbers",
    },
  },
  address: { type: "string", nullable: true },
  agent: { type: "string", nullable: true },
  phone: {
    type: "string",
    pattern: CustomRegexes.PHONE,
    nullable: true,
    errorMessage: {
      pattern: "should not contain letters and should not start with 0 or 1",
    },
  },
  policyNumber: { type: "string", nullable: true },
};
