import DealershipPreview from "components/Dealerships/DealershipPreview";
import PayoffBankPreview from "components/Deals/PayoffBankPreview";
import VinCheck from "components/Deals/VinCheck";
import { payoffBankStruct } from "components/PayoffBanks/model";
import React from "react";
import States from "us-states";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { getStateLabelByState } from "../../utils/functions";
import { createOptionsForSelect, FormComponent, Model } from "../../utils/models/fields";
import { dealershipStruct } from "../Dealerships/model";
import CreateDealModalButton from "./CreateDealModalButton";
import Print from "./Print";
import { ExternalCreditApplication } from "./types";

const retirementIncome = (
  type: "applicant" | "coApplicant"
): FormComponent<ExternalCreditApplication> => ({
  formComponent: "segment",
  show: (stateAccess) => stateAccess.get(["data", "info", type, "employmentStatus"]) === "Retired",
  width: "full",
  entities: [
    {
      formComponent: "currency-field",
      name: "retirementIncome",
      label: "Retirement income",
      width: "1/2",
      path: ["data", "info", type, "retirementIncome"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "retirementIncomeSource",
      label: "Retirement Income source",
      width: "1/2",
      path: ["data", "info", type, "sourceOfRetirementIncome"],
      default: null
    }
  ]
});

const applicantCurrentAddress: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  width: "full",
  name: "Current Address",
  entities: [
    {
      formComponent: "text-field",
      name: "addressNumber",
      label: "#",
      width: "1/6",
      path: ["data", "info", "applicant", "currentAddress", "addressNumber"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "address",
      label: "Address",
      width: "2/3",
      path: ["data", "info", "applicant", "currentAddress", "address"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "apt",
      label: "Apartment",
      width: "1/6",
      path: ["data", "info", "applicant", "currentAddress", "apt"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "city",
      label: "City",
      width: "1/3",
      path: ["data", "info", "applicant", "currentAddress", "city"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "state",
      label: "State",
      width: "1/3",
      path: ["data", "info", "applicant", "currentAddress", "state"],
      options: createOptionsForSelect({
        possibleValues: () => Object.keys(States),
        getOptionLabel: (x) => getStateLabelByState(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },

    {
      formComponent: "zip-code-field",
      name: "zip",
      label: "Zip",
      width: "1/3",
      hideZipCodeSearch: true,
      path: ["data", "info", "applicant", "currentAddress", "zip"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "years",
      label: "Years at current address",
      width: "1/3",
      path: ["data", "info", "applicant", "currentAddress", "time", "years"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "months",
      label: "Months at current address",
      width: "1/3",
      path: ["data", "info", "applicant", "currentAddress", "time", "months"],
      default: null
    }
  ]
};
const applicantPreviousAddress: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  width: "full",
  name: "Previous Address",
  entities: [
    {
      formComponent: "text-field",
      name: "addressNumber",
      label: "#",
      width: "1/6",
      path: ["data", "info", "applicant", "previousAddress", "addressNumber"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "address",
      label: "Address",
      width: "2/3",
      path: ["data", "info", "applicant", "previousAddress", "address"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "apt",
      label: "Apartment",
      width: "1/6",
      path: ["data", "info", "applicant", "previousAddress", "apt"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "city",
      label: "City",
      width: "1/3",
      path: ["data", "info", "applicant", "previousAddress", "city"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "state",
      label: "State",
      width: "1/3",
      path: ["data", "info", "applicant", "previousAddress", "state"],
      options: createOptionsForSelect({
        possibleValues: () => Object.keys(States),
        getOptionLabel: (x) => getStateLabelByState(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },

    {
      formComponent: "zip-code-field",
      name: "zip",
      label: "Zip",
      width: "1/3",
      hideZipCodeSearch: true,
      path: ["data", "info", "applicant", "previousAddress", "zip"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "years",
      label: "Years at previous address",
      width: "1/3",
      path: ["data", "info", "applicant", "previousAddress", "time", "years"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "months",
      label: "Months at previous address",
      width: "1/3",
      path: ["data", "info", "applicant", "previousAddress", "time", "months"],
      default: null
    }
  ]
};

const applicantCurrentEmployer: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  width: "full",
  name: "Current Employer",
  entities: [
    {
      formComponent: "radio-field",
      name: "employmentStatus",
      label: "Employment status",
      width: "full",
      path: ["data", "info", "applicant", "employmentStatus"],
      possibleValues: [
        { label: "Employee", value: "Employee" },
        { label: "Business owner", value: "Business owner" },
        { label: "Retired", value: "Retired" }
      ],
      default: null
    },
    retirementIncome("applicant"),
    {
      formComponent: "text-field",
      name: "name",
      label: "Current employer name",
      width: "1/2",
      path: ["data", "info", "applicant", "currentEmployer", "name"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "grossMonthlySalary",
      label: "Gross monthly salary",
      width: "1/2",
      path: ["data", "info", "applicant", "currentEmployer", "grossMonthlySalary"],
      default: null
    },
    {
      formComponent: "phone-field",
      name: "workPhone",
      label: "Work phone",
      width: "1/2",
      path: ["data", "info", "applicant", "currentEmployer", "workPhone"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "occupationOrJobTitle",
      label: "Occupation or Job Title",
      width: "1/2",
      path: ["data", "info", "applicant", "currentEmployer", "occupationOrJobTitle"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "years",
      label: "Years at current job",
      width: "1/2",
      path: ["data", "info", "applicant", "currentEmployer", "time", "years"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "months",
      label: "Months at current job",
      width: "1/2",
      path: ["data", "info", "applicant", "currentEmployer", "time", "months"],
      default: null
    }
  ]
};
const applicantPrevEmployer: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  width: "full",
  name: "Previous Employer",
  entities: [
    {
      formComponent: "text-field",
      name: "name",
      label: "Previous employer name",
      width: "1/2",
      path: ["data", "info", "applicant", "prevEmployer", "name"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "grossMonthlySalary",
      label: "Gross monthly salary",
      width: "1/2",
      show: (stateAccess) =>
        stateAccess.get(["data", "info", "applicant", "employmentStatus"]) === "Retired",
      path: ["data", "info", "applicant", "currentEmployer", "grossMonthlySalary"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "occupationOrJobTitle",
      label: "Occupation or Job Title",
      width: "1/2",
      path: ["data", "info", "applicant", "prevEmployer", "occupationOrJobTitle"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "years",
      label: "Years",
      width: "1/2",
      path: ["data", "info", "applicant", "prevEmployer", "time", "years"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "months",
      label: "Months",
      width: "1/2",
      path: ["data", "info", "applicant", "prevEmployer", "time", "months"],
      default: null
    }
  ]
};

const applicant: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  name: "Personal info",
  width: "full",
  entities: [
    {
      formComponent: "name-field",
      name: "firstName",
      label: "First name",
      required: true,
      width: "1/3",
      path: ["data", "info", "applicant", "firstName"],
      default: null
    },
    {
      formComponent: "name-field",
      name: "middleName",
      label: "Middle name",
      width: "1/3",
      path: ["data", "info", "applicant", "middleName"],
      default: null
    },
    {
      formComponent: "name-field",
      name: "lastName",
      label: "Last name",
      required: true,
      width: "1/3",
      path: ["data", "info", "applicant", "lastName"],
      default: null
    },

    {
      formComponent: "date-field",
      name: "dateOfBirth",
      label: "Date of birth",
      required: true,
      width: "1/3",
      path: ["data", "info", "applicant", "dateOfBirth"],
      default: null
    },
    {
      formComponent: "ssn-field",
      name: "ssn",
      label: "SSN",
      required: true,
      width: "1/3",
      path: ["data", "info", "applicant", "ssn"],
      default: null
    },
    {
      formComponent: "phone-field",
      name: "primaryPhone",
      label: "Primary phone",
      required: true,
      width: "1/2",
      path: ["data", "info", "applicant", "primaryPhone"],
      default: null
    },
    {
      formComponent: "phone-field",
      name: "Secondary phone",
      label: "secondaryPhone",
      width: "1/2",
      path: ["data", "info", "applicant", "secondaryPhone"],
      default: null
    },
    {
      formComponent: "email-field",
      name: "email",
      label: "Email",
      width: "1/3",
      path: ["data", "info", "applicant", "email"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "residentialStatus",
      label: "Residential status",
      width: "1/3",
      path: ["data", "info", "applicant", "residentialStatus"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "rentOrMortgageAmount",
      label: "Rent or Mortgage amount",
      width: "1/3",
      path: ["data", "info", "applicant", "rentOrMortgageAmount"],
      default: null
    },
    {
      formComponent: "radio-field",
      name: "usCitizen",
      label: "Is US citizen?",
      width: "1/2",
      path: ["data", "info", "applicant", "isUSCitizen"],
      possibleValues: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" }
      ],
      default: null,
      required: true
    },
    {
      formComponent: "radio-field",
      name: "maritalStatus",
      label: "Marital status",
      width: "1/2",
      path: ["data", "info", "applicant", "maritalStatus"],
      possibleValues: [
        { label: "Married", value: "Married" },
        { label: "Not married", value: "Not married" }
      ],
      default: null
    },
    {
      formComponent: "segment",
      name: "Document info",
      width: "full",
      entities: [
        {
          formComponent: "text-field",
          name: "idType",
          label: "ID type",
          width: "1/2",
          path: ["data", "info", "applicant", "idType"],
          default: null
        },
        {
          formComponent: "license-number-field",
          name: "idNumber",
          label: "ID number",
          width: "1/2",
          path: ["data", "info", "applicant", "idNumber"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "idIssuedBy",
          label: "ID Issued by",
          width: "1/3",
          path: ["data", "info", "applicant", "idIssuedBy"],
          default: null
        },
        {
          formComponent: "date-field",
          name: "idIssuedDate",
          label: "ID issued date",
          width: "1/3",
          path: ["data", "info", "applicant", "idIssuedDate"],
          default: null
        },
        {
          formComponent: "date-field",
          name: "idExpDate",
          label: "ID expire date",
          width: "1/3",
          path: ["data", "info", "applicant", "idExpDate"],
          default: null
        }
      ]
    }
  ]
};

const coApplicantCurrentAddress: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  width: "full",
  name: "Current Address",
  entities: [
    {
      formComponent: "text-field",
      name: "addressNumber",
      label: "#",
      width: "1/6",
      path: ["data", "info", "coApplicant", "currentAddress", "addressNumber"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "address",
      label: "Address",
      width: "2/3",
      path: ["data", "info", "coApplicant", "currentAddress", "address"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "apt",
      label: "Apartment",
      width: "1/6",
      path: ["data", "info", "coApplicant", "currentAddress", "apt"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "city",
      label: "City",
      width: "1/3",
      path: ["data", "info", "coApplicant", "currentAddress", "city"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "state",
      label: "State",
      width: "1/3",
      path: ["data", "info", "coApplicant", "currentAddress", "state"],
      options: createOptionsForSelect({
        possibleValues: () => Object.keys(States),
        getOptionLabel: (x) => getStateLabelByState(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },

    {
      formComponent: "zip-code-field",
      name: "zip",
      label: "Zip",
      width: "1/3",
      hideZipCodeSearch: true,
      path: ["data", "info", "coApplicant", "currentAddress", "zip"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "years",
      label: "Years at current address",
      width: "1/3",
      path: ["data", "info", "coApplicant", "currentAddress", "time", "years"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "months",
      label: "Months at current address",
      width: "1/3",
      path: ["data", "info", "coApplicant", "currentAddress", "time", "months"],
      default: null
    }
  ]
};
const coApplicantPreviousAddress: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  width: "full",
  name: "Previous Address",
  entities: [
    {
      formComponent: "text-field",
      name: "addressNumber",
      label: "#",
      width: "1/6",
      path: ["data", "info", "coApplicant", "previousAddress", "addressNumber"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "address",
      label: "Address",
      width: "2/3",
      path: ["data", "info", "coApplicant", "previousAddress", "address"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "apt",
      label: "Apartment",
      width: "1/6",
      path: ["data", "info", "coApplicant", "previousAddress", "apt"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "city",
      label: "City",
      width: "1/3",
      path: ["data", "info", "coApplicant", "previousAddress", "city"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "state",
      label: "State",
      width: "1/3",
      path: ["data", "info", "coApplicant", "previousAddress", "state"],
      options: createOptionsForSelect({
        possibleValues: () => Object.keys(States),
        getOptionLabel: (x) => getStateLabelByState(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },

    {
      formComponent: "zip-code-field",
      name: "zip",
      label: "Zip",
      width: "1/3",
      hideZipCodeSearch: true,
      path: ["data", "info", "coApplicant", "previousAddress", "zip"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "years",
      label: "Years at previous address",
      width: "1/3",
      path: ["data", "info", "coApplicant", "previousAddress", "time", "years"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "months",
      label: "Months at previous address",
      width: "1/3",
      path: ["data", "info", "coApplicant", "previousAddress", "time", "months"],
      default: null
    }
  ]
};

const coApplicantCurrentEmployer: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  width: "full",
  name: "Current Employer",

  entities: [
    {
      formComponent: "radio-field",
      name: "employmentStatus",
      label: "Employment status",
      width: "full",
      path: ["data", "info", "coApplicant", "employmentStatus"],
      possibleValues: [
        { label: "Employee", value: "Employee" },
        { label: "Business owner", value: "Business owner" },
        { label: "Retired", value: "Retired" }
      ],
      default: null
    },
    retirementIncome("coApplicant"),
    {
      formComponent: "text-field",
      name: "name",
      label: "Current employer name",
      width: "1/2",
      path: ["data", "info", "coApplicant", "currentEmployer", "name"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "grossMonthlySalary",
      label: "Gross monthly salary",
      width: "1/2",
      path: ["data", "info", "coApplicant", "currentEmployer", "grossMonthlySalary"],
      default: null
    },
    {
      formComponent: "phone-field",
      name: "workPhone",
      label: "Work phone",
      width: "1/2",
      path: ["data", "info", "coApplicant", "currentEmployer", "workPhone"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "occupationOrJobTitle",
      label: "Occupation or Job Title",
      width: "1/2",
      path: ["data", "info", "coApplicant", "currentEmployer", "occupationOrJobTitle"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "years",
      label: "Years at current job",
      width: "1/2",
      path: ["data", "info", "coApplicant", "currentEmployer", "time", "years"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "months",
      label: "Months at current job",
      width: "1/2",
      path: ["data", "info", "coApplicant", "currentEmployer", "time", "months"],
      default: null
    }
  ]
};
const coApplicantPrevEmployer: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  width: "full",
  name: "Previous Employer",
  entities: [
    {
      formComponent: "text-field",
      name: "name",
      label: "Previous employer name",
      width: "1/2",
      path: ["data", "info", "coApplicant", "prevEmployer", "name"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "grossMonthlySalary",
      label: "Gross monthly salary",
      width: "1/2",
      show: (stateAccess) =>
        stateAccess.get(["data", "info", "coApplicant", "employmentStatus"]) === "Retired",
      path: ["data", "info", "coApplicant", "currentEmployer", "grossMonthlySalary"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "occupationOrJobTitle",
      label: "Occupation or Job Title",
      width: "1/2",
      path: ["data", "info", "coApplicant", "prevEmployer", "occupationOrJobTitle"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "years",
      label: "Years",
      width: "1/2",
      path: ["data", "info", "coApplicant", "prevEmployer", "time", "years"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "months",
      label: "Months",
      width: "1/2",
      path: ["data", "info", "coApplicant", "prevEmployer", "time", "months"],
      default: null
    }
  ]
};

const coApplicant: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  name: "Personal info",
  width: "full",
  entities: [
    {
      formComponent: "name-field",
      name: "firstName",
      label: "First name",
      required: true,
      width: "1/3",
      path: ["data", "info", "coApplicant", "firstName"],
      default: null
    },
    {
      formComponent: "name-field",
      name: "middleName",
      label: "Middle name",
      width: "1/3",
      path: ["data", "info", "coApplicant", "middleName"],
      default: null
    },
    {
      formComponent: "name-field",
      name: "lastName",
      label: "Last name",
      required: true,
      width: "1/3",
      path: ["data", "info", "coApplicant", "lastName"],
      default: null
    },

    {
      formComponent: "date-field",
      name: "dateOfBirth",
      label: "Date of birth",
      required: true,
      width: "1/3",
      path: ["data", "info", "coApplicant", "dateOfBirth"],
      default: null
    },
    {
      formComponent: "ssn-field",
      name: "ssn",
      label: "SSN",
      required: true,
      width: "1/3",
      path: ["data", "info", "coApplicant", "ssn"],
      default: null
    },
    {
      formComponent: "phone-field",
      name: "primaryPhone",
      label: "Primary phone",
      required: true,
      width: "1/2",
      path: ["data", "info", "coApplicant", "primaryPhone"],
      default: null
    },
    {
      formComponent: "phone-field",
      name: "Secondary phone",
      label: "secondaryPhone",
      width: "1/2",
      path: ["data", "info", "coApplicant", "secondaryPhone"],
      default: null
    },
    {
      formComponent: "email-field",
      name: "email",
      label: "Email",
      width: "1/3",
      path: ["data", "info", "coApplicant", "email"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "residentialStatus",
      label: "Residential status",
      width: "1/3",
      path: ["data", "info", "coApplicant", "residentialStatus"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "rentOrMortgageAmount",
      label: "Rent or Mortgage amount",
      width: "1/3",
      path: ["data", "info", "coApplicant", "rentOrMortgageAmount"],
      default: null
    },
    {
      formComponent: "radio-field",
      name: "usCitizen",
      label: "Is US citizen?",
      width: "1/2",
      path: ["data", "info", "coApplicant", "isUSCitizen"],
      possibleValues: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" }
      ],
      default: null,
      required: true
    },
    {
      formComponent: "radio-field",
      name: "maritalStatus",
      label: "Marital status",
      width: "1/2",
      path: ["data", "info", "coApplicant", "maritalStatus"],
      possibleValues: [
        { label: "Married", value: "Married" },
        { label: "Not married", value: "Not married" }
      ],
      default: null
    },
    {
      formComponent: "segment",
      name: "Document info",
      width: "full",
      entities: [
        {
          formComponent: "text-field",
          name: "idType",
          label: "ID type",
          width: "1/2",
          path: ["data", "info", "coApplicant", "idType"],
          default: null
        },
        {
          formComponent: "license-number-field",
          name: "idNumber",
          label: "ID number",
          width: "1/2",
          path: ["data", "info", "coApplicant", "idNumber"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "idIssuedBy",
          label: "ID Issued by",
          width: "1/3",
          path: ["data", "info", "coApplicant", "idIssuedBy"],
          default: null
        },
        {
          formComponent: "date-field",
          name: "idIssuedDate",
          label: "ID issued date",
          width: "1/3",
          path: ["data", "info", "coApplicant", "idIssuedDate"],
          default: null
        },
        {
          formComponent: "date-field",
          name: "idExpDate",
          label: "ID expire date",
          width: "1/3",
          path: ["data", "info", "coApplicant", "idExpDate"],
          default: null
        }
      ]
    }
  ]
};

const TradeIn: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  name: "TradeIn",
  entities: [
    {
      formComponent: "text-field",
      name: "vin",
      label: "Vin",
      width: "1/3",
      path: ["data", "info", "tradeIn", "vin"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "make",
      label: "Make",
      width: "1/3",
      path: ["data", "info", "tradeIn", "make"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "model",
      label: "Model",
      width: "1/3",
      path: ["data", "info", "tradeIn", "model"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "year",
      label: "year",
      width: "1/3",
      path: ["data", "info", "tradeIn", "year"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "Miles",
      label: "Miles",
      width: "1/3",
      path: ["data", "info", "tradeIn", "miles"],
      default: null
    }
  ]
};
const priceFields: FormComponent<ExternalCreditApplication>[] = [
  {
    formComponent: "currency-field",
    name: "salePrice",
    label: "Sale Price",
    width: "1/3",
    path: ["data", "info", "price", "price"],
    default: null
  },
  {
    formComponent: "currency-field",
    name: "totalCash",
    label: "Cash Down",
    width: "1/3",
    path: ["data", "info", "price", "totalCash"],
    default: null
  },
  {
    formComponent: "currency-field",
    name: "tradeAllowance",
    label: "Trade Allowance",
    width: "1/3",
    path: ["data", "info", "price", "tradeAllowance"],
    default: null
  }
];
const payoffFields: FormComponent<ExternalCreditApplication>[] = [
  {
    formComponent: "currency-field",
    name: "payoff",
    label: "Payoff",
    width: "1/3",
    path: ["data", "info", "payoff", "payoff"],
    default: null,
    required: (stateAccess: StateAccess) =>
      Number(
        stateAccess.get<ExternalCreditApplication>(["data", "info", "price", "tradeAllowance"]) ?? 0
      ) > 0
  },
  {
    formComponent: "one-to-many-field",
    struct: payoffBankStruct,
    component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
      <PayoffBankPreview
        stateAccess={stateAccess}
        renderSet={renderSet}
        type="external_credit_application"
        path={["data", "payoffBank"]}
        name="payoffBank"
        label="Payoff Bank"
        required={false}
      />
    ),
    required: (stateAccess: StateAccess) =>
      Number(
        stateAccess.get<ExternalCreditApplication>(["data", "info", "payoff", "payoff"]) ?? 0
      ) > 0,
    name: "payoffBank",
    width: "1/3",
    path: ["data", "payoffBank"],
    default: null,
    show: (stateAccess: StateAccess) =>
      stateAccess.get<ExternalCreditApplication>(["data", "payoffBank"])
  },
  {
    formComponent: "text-field",
    name: "payoffBank",
    label: "Payoff Bank",
    width: "1/3",
    path: ["data", "info", "payoff", "payoffBank"],
    default: null,
    required: (stateAccess: StateAccess) =>
      Number(
        stateAccess.get<ExternalCreditApplication>(["data", "info", "payoff", "payoff"]) ?? 0
      ) > 0,
    show: (stateAccess: StateAccess) =>
      !stateAccess.get<ExternalCreditApplication>(["data", "payoffBank"])
  }
];
const feesFields: FormComponent<ExternalCreditApplication>[] = [
  {
    formComponent: "currency-field",
    name: "titleFees",
    label: "Title/Transfer Fees",
    width: "1/3",
    path: ["data", "info", "fees", "titleFees"],
    default: null
  },
  {
    formComponent: "currency-field",
    name: "docFee",
    label: "Doc Fee",
    width: "1/3",
    path: ["data", "info", "fees", "docFee"],
    default: null
  }
];
const taxesFields: FormComponent<ExternalCreditApplication>[] = [
  {
    formComponent: "currency-field",
    name: "stateTax",
    label: "State Tax",
    width: "1/3",
    path: ["data", "info", "taxes", "stateTax"],
    default: null
  }
];
const FinancialInfo: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  name: "Financial Info",
  width: "full",
  entities: [...priceFields, ...payoffFields, ...feesFields, ...taxesFields]
};

const Collateral: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  name: "Collateral",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (stateAccess, mainstateAccess, renderSet) => (
        <VinCheck
          stateAccess={stateAccess}
          renderSet={renderSet}
          type={"external_credit_application"}
          path={["data", "info", "collateral", "vin"]}
          isVinDecodedPath={["data", "info", "collateral", "isVinDecoded"]}
          name="vin"
          label="Vin"
        />
      ),
      name: "vin",
      label: "Vin",
      width: "1/3",
      path: ["data", "info", "collateral", "vin"]
    },
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "text-field",
          name: "make",
          label: "Make",
          width: "1/4",
          path: ["data", "info", "collateral", "make"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "model",
          label: "Model",
          width: "1/4",
          path: ["data", "info", "collateral", "model"],
          default: null
        },
        {
          formComponent: "digits-only-field",
          name: "year",
          label: "year",
          width: "1/4",
          path: ["data", "info", "collateral", "year"],
          default: null
        },
        {
          formComponent: "digits-only-field",
          name: "Miles",
          label: "Miles",
          width: "1/4",
          path: ["data", "info", "collateral", "miles"],
          default: null
        },
        {
          formComponent: "select-field",
          options: createOptionsForSelect({
            possibleValues: () => ["new", "used"],
            getOptionLabel: (x) => x,
            getSelectedOption: (x, y) => x === y
          }),
          name: "unitStatus",
          label: "Unit Status",
          width: "1/3",
          path: ["data", "info", "collateral", "unitStatus"],
          default: ""
        },
        {
          formComponent: "currency-field",
          name: "msrp",
          label: "MSRP",
          width: "1/2",
          path: ["data", "info", "collateral", "MSRP"],
          show: (stateAccess: StateAccess) =>
            stateAccess.get<ExternalCreditApplication>([
              "data",
              "info",
              "collateral",
              "unitStatus"
            ]) !== "used",
          default: null
        },
        {
          formComponent: "currency-field",
          name: "nada",
          label: "NADA",
          width: "1/2",
          path: ["data", "info", "collateral", "MSRP"],
          show: (stateAccess: StateAccess) =>
            stateAccess.get<ExternalCreditApplication>([
              "data",
              "info",
              "collateral",
              "unitStatus"
            ]) === "used",
          default: null
        }
      ]
    }
  ]
};
const applicantOtherIncome: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "text-field",
      name: "Source",
      label: "Additional Income Source",
      width: "2/3",
      path: ["data", "info", "applicant", "otherIncomeSource"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "Amount",
      label: "Additional Income Amount",
      width: "1/3",
      path: ["data", "info", "applicant", "grossMonthlyOtherIncome"],
      default: null
    }
  ]
};
const coApplicantOtherIncome: FormComponent<ExternalCreditApplication> = {
  formComponent: "segment",
  width: "full",

  entities: [
    {
      formComponent: "text-field",
      name: "Source",
      label: "Additional Income Source",
      width: "2/3",
      path: ["data", "info", "coApplicant", "otherIncomeSource"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "Amount",
      label: "Additional Income Amount",
      width: "1/3",
      path: ["data", "info", "coApplicant", "grossMonthlyOtherIncome"],
      default: null
    }
  ]
};

export const ExternalCreditApplicationStruct: Model<ExternalCreditApplication> = {
  formComponent: "model",
  schema: "new_external_credit_application",
  name: "external_credit_application",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      name: "Credit info",
      entities: [
        {
          formComponent: "radio-field",
          name: "creditType",
          label: "Credit type",
          width: "2/3",
          required: true,
          path: ["data", "info", "creditType"],
          default: null,
          possibleValues: [
            { label: "Individual", value: "individual" },
            { label: "Joint", value: "joint" }
          ]
        },
        {
          formComponent: "one-to-many-field",
          struct: () => dealershipStruct,
          component: (stateAccess, mainstateAccess, renderSet) => (
            <DealershipPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "dealership"]}
              name="dealership"
              label="Dealership"
              required={true}
              type="external_credit_application"
            />
          ),
          name: "dealership",
          label: "Dealership",
          required: true,
          default: null,
          path: ["data", "dealership"],
          width: "full"
        },
        {
          formComponent: "one-to-many-field",
          component: (stateAccess, mainstateAccess, renderSet) => {
            return (
              <>
                <Print stateAccess={stateAccess} type="external application" />

                {stateAccess.get(["_id"]) ? (
                  <CreateDealModalButton stateAccess={stateAccess} renderSet={renderSet} />
                ) : (
                  <></>
                )}
              </>
            );
          },
          name: "dialog",
          width: "1/3",
          path: null
        }
      ]
    },
    {
      formComponent: "segment",
      width: "full",
      name: "Applicant",
      entities: [
        {
          formComponent: "segment",
          width: "1/3",
          entities: [applicant]
        },
        {
          formComponent: "segment",
          width: "1/3",
          entities: [applicantCurrentAddress, applicantPreviousAddress]
        },
        {
          formComponent: "segment",
          width: "1/3",
          entities: [applicantCurrentEmployer, applicantOtherIncome, applicantPrevEmployer]
        }
      ]
    },
    {
      formComponent: "segment",
      width: "full",
      name: "coApplicant",
      show: (stateAccess) =>
        stateAccess.get<ExternalCreditApplication>(["data", "info", "creditType"]) === "joint",
      entities: [
        {
          formComponent: "segment",
          width: "1/3",
          entities: [coApplicant]
        },
        {
          formComponent: "segment",
          width: "1/3",
          entities: [coApplicantCurrentAddress, coApplicantPreviousAddress]
        },
        {
          formComponent: "segment",
          width: "1/3",
          entities: [coApplicantCurrentEmployer, coApplicantOtherIncome, coApplicantPrevEmployer]
        }
      ]
    },
    { formComponent: "segment", width: "full", entities: [Collateral, TradeIn] },
    FinancialInfo
  ]
};
