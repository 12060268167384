import React from "react";
import { Grid } from "@material-ui/core";

import { StateAccess } from "utils/models/formGenerator";
import { Dealership } from "components/Dealerships/types";
import { showRenderSet } from "utils/models/formRenderers";
import { getBorderColor, RestrictedWarnings } from "components/Dealerships/DealershipPreview";

export default function DealershipPreview({ dealership }: { dealership: Dealership }) {
  if (!dealership) return null;

  const name = dealership?.data?.info?.displayName;
  const titleManagement = dealership?.data?.info?.titleManagement;

  const stateAccess: StateAccess = {
    get: (path) => name,
    set: (path, value): any => {
      return;
    }
  };

  return (
    <Grid
      container
      spacing={1}
      style={
        titleManagement?.isRestricted
          ? {
              border: `2px solid ${getBorderColor(titleManagement, "accounting")}`,
              borderRadius: "5px",
              padding: 10,
              marginBottom: 5
            }
          : {}
      }
    >
      {showRenderSet(false).readOnlyRenderer(
        {
          formComponent: "read-only-field",
          name,
          width: "1/3",
          label: "Dealership",
          path: [],
          valueToString: (el) => el
        },
        stateAccess,
        showRenderSet(false)
      )}

      {titleManagement && (
        <RestrictedWarnings titleManagement={titleManagement} view="accounting" />
      )}
    </Grid>
  );
}
