import React from "react";
import { HintTooltip } from "components/common/HintTooltip";
import IconButton from "@material-ui/core/IconButton";
import { StateAccess } from "utils/models/formGenerator";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Menu, MenuItem } from "@material-ui/core";
import { capitalize } from "utils/functions";

export default ({
  stateAccess,
  type,
  types
}: {
  stateAccess: StateAccess;
  type: string;
  types: string[];
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (chosenType: string) => {
    if (
      typeof chosenType === "string" &&
      confirm("Are you sure? This will erese the data in the current table")
    ) {
      stateAccess.set(
        ["data", "info", "reserves", type],
        stateAccess.get(["data", "info", "reserves", chosenType])
      );
    }
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <HintTooltip title={`Choose where you want to copy from.`}>
          <FileCopyIcon />
        </HintTooltip>
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {types
          .sort((a, b) => a.localeCompare(b))
          .map((type: string) => (
            <MenuItem key={type} onClick={() => handleClose(type)}>
              {capitalize(type)}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};
