import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CancelIcon from "@material-ui/icons/Cancel";
import { DateRange, DateRangePicker } from "DateRangePicker";
import React from "react";
import { FilterProps } from "./types";

export const formatRange = (range: DateRange) => {
  if (range.startDate !== undefined && range.endDate !== undefined)
    return (
      <span style={{ fontSize: "18px", marginRight: "15px" }}>
        {new Date(range.startDate).toLocaleDateString("en-US")}
        {" - "}
        {new Date(range.endDate).toLocaleDateString("en-US")}
      </span>
    );
  else {
    return null;
  }
};

export default function DateFilter<T extends unknown>({
  setFilter,
  path,
  filterType,
  closeFilter,
  filter
}: FilterProps<T>) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ padding: "10px 0px" }}>
        {filter?.values?.startDate !== undefined ? (
          <IconButton
            size="small"
            style={{
              position: "absolute",
              right: "13px",
              marginTop: "-15px",
              color: "#254e6f"
            }}
            onClick={() => {
              setFilter(undefined);
              closeFilter && closeFilter();
            }}
          >
            {filter?.values && formatRange(filter?.values)}
            <CancelIcon />
          </IconButton>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <DateRangePicker
          initialDateRange={
            filter?.values?.startDate && filter?.values?.endDate
              ? { startDate: filter?.values?.startDate, endDate: filter?.values?.endDate }
              : {}
          }
          onChange={(range) => {
            if (range?.startDate !== undefined && range?.endDate !== undefined) {
              const beginningOfTheDay = new Date(range?.startDate ?? filter?.values?.startDate);
              beginningOfTheDay.setHours(0, 0, 0, 0);
              const endOfTheDay = new Date(range?.endDate ?? filter?.values?.endDate);
              endOfTheDay.setHours(23, 59, 59, 999);
              setFilter({
                query: {
                  ...(filterType === "aggregatedColumnFilter" ? { filterType } : {}),
                  [path.join(".")]: {
                    $gte: beginningOfTheDay.toISOString(),
                    $lte: endOfTheDay.toISOString()
                  }
                },
                values: range
              });
            } else {
              setFilter(undefined);
            }
          }}
        />
      </Grid>
    </Grid>
  );
}
