import { Box, Button, Paper } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useState } from "react";
import ResultsList from "./ResultsList";

import useDealSearch from "hooks/useSearchDeal/useSearchDeal";
import { StateAccess, RenderSet } from "utils/models/formGenerator";

import { HttpQuery } from "Chat/types/http";
import { ChargebackDeal } from "../types";
import { Lender } from "components/Lenders/types";
import { Deal } from "components/Deals/types";
import { areDatesTheSameDay } from "utils/functions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      padding: "10px",
      borderRadius: theme.shape.borderRadius,
      border: "1px solid #254e70",
      gap: 10,
      display: "flex",
      alignItems: "center"
    },
    input: {
      width: "100%",
      fontSize: 18
    },
    resultsWrapper: {
      paddingTop: 10
    }
  })
);

const sortDealsByMatchScore = (deals: Deal[], searchTerms: ChargebackDeal) => {
  const dealsWithScores = deals.reduce((acc: (Deal & { score: number })[], deal) => {
    const vehicle = deal.data.info.vehicle;

    let score = 0;
    if (searchTerms.vehicle?.make && vehicle.make === searchTerms.vehicle?.make) {
      score++;
    }
    if (searchTerms.vehicle?.model && vehicle.model === searchTerms.vehicle?.model) {
      score++;
    }
    if (searchTerms.vehicle?.VIN && vehicle.VIN === searchTerms.vehicle?.VIN) {
      score++;
    }
    if (
      searchTerms.applicantFirstName &&
      deal.data.applicant?.data.info.firstName === searchTerms.applicantFirstName
    ) {
      score++;
    }
    if (
      searchTerms.applicantLastName &&
      deal.data.applicant?.data.info.lastName === searchTerms.applicantLastName
    ) {
      score++;
    }
    if (searchTerms.refNumber && deal.data.info.refNumber === searchTerms.refNumber) {
      score++;
    }
    if (
      searchTerms.contractDate &&
      deal.data.info.dealDates.contractDate &&
      areDatesTheSameDay(
        new Date(deal.data.info.dealDates.contractDate),
        new Date(searchTerms.contractDate)
      )
    ) {
      score++;
    }
    return [...acc, { ...deal, score }];
  }, []);

  return dealsWithScores.sort((a, b) => b.score - a.score);
};

function transformSearchToSearchQuery(deal: ChargebackDeal, lender: Lender) {
  if (!lender || !deal) {
    return {};
  }

  const { vehicle, applicantFirstName, applicantLastName } = deal;

  const orQuery = [
    ...(vehicle?.VIN ? [{ "data.info.vehicle.VIN": vehicle.VIN.trim() }] : []),
    ...(applicantFirstName
      ? [
          {
            "data.applicant.data.info.firstName": {
              $regex: applicantFirstName.trim().replace(/\s+/g, ""),
              $options: "i"
            }
          }
        ]
      : []),
    ...(applicantLastName
      ? [
          {
            "data.applicant.data.info.lastName": {
              $regex: applicantLastName.trim().replace(/\s+/g, ""),
              $options: "i"
            }
          }
        ]
      : [])
  ];
  return {
    query: {
      $and: [
        { "data.info.status": "funded" },
        { "data.lenderId": lender._id },
        ...(orQuery.length ? [{ $or: orQuery }] : [])
      ]
    }
  };
}
interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}

export default function DealSearch({ stateAccess, renderSet }: Props) {
  const classes = useStyles();
  const [enabled, setEnabled] = useState(false);
  const dealSearchTerms: ChargebackDeal = stateAccess.get(["data", "deal"]);

  const lender = stateAccess.get(["data", "lender"]);

  const transformedQuery = (transformSearchToSearchQuery(
    dealSearchTerms,
    lender
  ) as unknown) as HttpQuery;
  const { loading, data, error, isFetching } = useDealSearch(transformedQuery, enabled);

  const showResultsOrLoading = (loading && isFetching) || (data && data?.length > 0);

  React.useEffect(() => {
    if (isFetching || loading) {
      setEnabled(false);
    }
  }, [isFetching, loading]);

  const handleSetEnabled = () => setEnabled(true);

  return renderSet.type === "edit" ? (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSetEnabled}
        disabled={!lender || isFetching}
      >
        Search deal
      </Button>
      {showResultsOrLoading && (
        <Box className={classes.resultsWrapper}>
          <Paper elevation={3}>
            <ResultsList
              data={sortDealsByMatchScore(data ?? [], dealSearchTerms)}
              loading={loading}
              error={error}
              stateAccess={stateAccess}
            />
          </Paper>
        </Box>
      )}
    </Box>
  ) : null;
}
