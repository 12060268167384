import { LenderDescision } from "components/LenderDecisions/types";
import {
  PermissionStruct,
  permissionNode,
  multyPermissionNode,
  relationPermissionNode
} from "components/Roles/types";

export type LenderDescisionPermissionsStruct = PermissionStruct<Required<LenderDescision>>;

export const lenderDescisionPermissionStruct: LenderDescisionPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      applicant: permissionNode("Applicant"),
      createdBy: permissionNode("Created by"),
      dateSubmitted: permissionNode("Date Submitted"),
      modifiedBy: permissionNode("Modified By"),
      phoneNumber: permissionNode("Phone Number"),
      product: permissionNode("Product"),
      decision: permissionNode("Decision"),
      lender: permissionNode("Lender"),
      fileUrl: permissionNode("File Url")
    },
    dealType: permissionNode("Deal Type"),
    dealId: permissionNode("Deal"),
    state: permissionNode("State"),
    county: permissionNode("County"),
    refNumber: permissionNode("Ref Number"),
    possibleDeals: multyPermissionNode("Possible Deals")
  }
};
