import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface AddUserRequest {
  _id: string;
  data: FormState;
  availableToRolesIds?: string[] | null;
}

interface AddUserRequestSend {
  availableToRolesIds?: string[] | null;
  data: Omit<Omit<Omit<FormState, "roles">, "vehicleInsuranceCompany">, "loginAsUser"> & {
    rolesIds: string[];
  };
}

interface AddUserResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddUserRequestSend,
  AddUserResponse
>("AddWfdUser", {
  request: "new_wfd_user"
});

export const addUserActions = socketMessageSlice.actions;
export const addUser = (request: AddUserRequest & Identifiable) => {
  const { roles, loginAsUser, ...rest } = request.data;

  return socketAction({
    availableToRolesIds: request.availableToRolesIds ?? [],
    requestId: request.requestId,
    data: {
      ...rest,
      loginAsUserId: loginAsUser?._id ?? undefined,
      rolesIds: roles.map((r) => r?._id) ?? []
    }
  });
};
export default socketMessageSlice.reducer;
