import { createListSlice } from "../Middlewares/listSliceCreator";
import { Settings } from "./types";

const { ListSlice, getList } = createListSlice<Settings>({
  name: "SettingsList",
  request_topic: "all_settings",
  reducers: {}
});

export default ListSlice.reducer;
export const getSettingsList = getList;
export const listSettingsActions = ListSlice.actions;
export const removeSettingsList = ListSlice.actions.removeList;
