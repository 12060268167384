import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { DealerNote, AddDealerNoteRequest } from "./types";

interface AddDealerNoteResponse {
  message: DealerNote;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddDealerNoteRequest,
  AddDealerNoteResponse
>("AddDealerNoteResponse", {
  request: "new_dealer_note"
});

export const addDealerNoteActions = socketMessageSlice.actions;

export const addDealerNote = socketAction;

export default socketMessageSlice.reducer;
