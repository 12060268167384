import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ContentWrapper from "components/Content/ContentWrapper";
import CRUDHelper from "components/Content/CRUDHelper";
import TabsWrapper from "components/Tabs/TabsWrapper";
import { OptionsObject, useSnackbar } from "notistack";
import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react";
import { ActiveTab, ChatContext } from "./LayoutWrapper";
import { VersionNotification } from "./VersionNotification";
export const VerificationContext = createContext<
  (x: { name: string; value: any }[], y?: () => void) => boolean
>(() => true);
export const SearchContext = createContext<
  [{ [key: string]: string }, Dispatch<SetStateAction<{}>>]
>([{}, () => {}]);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      marginTop: "50px"
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1)
    }
  })
);

const theme = (theme: Theme) =>
  createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      action: {
        ...theme.palette.action,
        disabled: "#545454"
      },
      text: {
        ...theme.palette.text,
        disabled: "#545454"
      }
    },
    overrides: {
      MuiIconButton: {
        root: {
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            color: "#254e70"
          }
        }
      },
      MuiInputLabel: {
        root: {
          color: "black",
          textOverflow: "initial !important"
        },
        outlined: {
          "&$shrink$marginDense": {
            transform: "translate(14px, -6px) scale(0.87) !important",
            paddingRight: "2px"
          }
        },
        filled: {
          "&$shrink$marginDense": {
            transform: "translate(12px, 7px) scale(0.87) !important"
          }
        }
      },
      MuiInput: {
        input: {
          textOverflow: "initial !important"
        }
      },
      MuiFilledInput: {
        input: {
          paddingLeft: "5px !important",
          paddingRight: "2px !important",
          textOverflow: "initial !important"
        }
      },
      MuiOutlinedInput: {
        input: {
          paddingLeft: "5px !important",
          paddingRight: "2px !important",
          textOverflow: "initial !important",
          height: "24px"
        },
        notchedOutline: {
          "& > legend": {
            fontSize: "0.87em !important"
          }
        }
      },
      MuiTableCell: {
        sizeSmall: {
          padding: "0px 5px"
        }
      }
    }
  });

const checkDataValidity = (
  enqueueSnackbar: (
    message: React.ReactNode,
    options?: OptionsObject | undefined
  ) => React.ReactText
) => (requiredData: { value: string; name: string }[], callback?: () => void): boolean => {
  const missingData = requiredData.filter(({ value }) => [undefined, null, ""].includes(value));

  if (missingData.length > 0) {
    const missingFieldsNames = missingData.map(({ name }) => name).join(", ");
    enqueueSnackbar(
      missingData.length > 1
        ? `Fields: ${missingFieldsNames} are required!`
        : `Field "${missingFieldsNames}" is required!`,
      {
        variant: "error"
      }
    );
    return false;
  } else {
    callback?.();
    return true;
  }
};

export interface Props {
  setActiveTab: React.Dispatch<React.SetStateAction<ActiveTab>>;
  activeTab: ActiveTab;
}
export default function Main({ setActiveTab, activeTab }: Props) {
  const classes = useStyles();
  const [search, setSearch] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const chat = useContext(ChatContext);
  return (
    <main
      style={chat.chatOpen ? { width: "calc(100% - 438px)", flexGrow: "0" } : {}}
      className={classes.content}
    >
      <div className={classes.toolbar} />
      <TabsWrapper activeTab={activeTab} setActiveTab={setActiveTab} />
      <ThemeProvider theme={theme}>
        <CRUDHelper>
          <VerificationContext.Provider value={checkDataValidity(enqueueSnackbar)}>
            <SearchContext.Provider value={[search, setSearch]}>
              <ContentWrapper activeTab={activeTab} />
            </SearchContext.Provider>
          </VerificationContext.Provider>
        </CRUDHelper>
      </ThemeProvider>
      <VersionNotification />
    </main>
  );
}
