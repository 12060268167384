import React from "react";
import {
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog
} from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";
import { Verification } from "../types/internal/personaVerifications";
import WarningIcon from "@material-ui/icons/Warning";

const formatVerificationType = (verificationType: keyof Verification | "all") => {
  switch (verificationType) {
    case "aamvaVerification":
      return "AAMVA Verifications";
    case "databaseVerification":
      return "Database Verifications";
    case "emailRiskReport":
      return "Email Risk Reports";
    case "phoneRiskReport":
      return "Phone Risk Reports";
    case "all":
      return "verifications of each type";
  }
};
type WarningDialogProps = {
  closeFunction: () => void;
  retryFunction: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedVerificationType: keyof Verification | "all"
  ) => void;
  selectedVerificationType: keyof Verification | "all";
};
const WarningDialog = ({
  closeFunction,
  retryFunction,
  selectedVerificationType
}: WarningDialogProps) => {
  return (
    <Dialog maxWidth="md" id="confirm-performing-dialog" open={true} onClose={closeFunction}>
      <DialogTitle id="confirm-performing-dialog-title">
        <Typography variant="h6" style={{ textAlign: "center" }}>
          Warning!{" "}
          <WarningIcon fontSize="large" style={{ color: "#eed202", verticalAlign: "middle" }} />
        </Typography>

        <CloseDialogButton closeFunction={closeFunction} />
      </DialogTitle>
      <DialogContent style={{ padding: "20px" }}>
        <Typography variant="h6">
          There are already 3 or more {formatVerificationType(selectedVerificationType)} performed,
          are you sure you want to force perform again?
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button
          onClick={(e) => {
            retryFunction(e, selectedVerificationType);
            closeFunction();
          }}
          variant="contained"
          autoFocus
          color="primary"
          id="confirm-force-perform"
        >
          Yes, force perform
        </Button>
        <Button onClick={closeFunction} variant="contained" color="primary" id="cancel">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default WarningDialog;
