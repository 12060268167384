import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { AddUnparsedRequiredDocumentRequest, FormState } from "./types";

export interface UnparsedRequiredDocumentResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
    creatorId: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddUnparsedRequiredDocumentRequest,
  UnparsedRequiredDocumentResponse
>("UnparsedRequiredDocument", {
  request: "unparsed_required_document"
});

export const unparsedRequiredDocumentRequest = (
  request: AddUnparsedRequiredDocumentRequest & Identifiable
) => {
  const { printedDocument, ...rest } = request.data;

  return socketAction({
    requestId: request.requestId,
    availableToRolesIds: request.availableToRolesIds ?? [],
    data: {
      ...rest,
      printedDocumentId: printedDocument?._id ?? null
    }
  });
};

export const unparsedRequiredDocumentRequestActions = socketMessageSlice.actions;

export default socketMessageSlice.reducer;
