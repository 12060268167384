import { CustomRegexes } from "../../helpers";

const collateralAge = {
  type: "string",
  nullable: true,
  enum: ["NEW", "USED", "DEMO", null],
  errorMessage: {
    enum: "must be one of the following: 'NEW', 'USED', 'DEMO'",
  },
};
const name = {
  type: "string",
  pattern: CustomRegexes.LETTERS,
  errorMessage: {
    pattern: "should not contain numbers",
  },
  nullable: true,
};
const phone = {
  oneOf: [
    {
      type: "string",
      pattern: CustomRegexes.PHONE_WITH_DASHES,
      errorMessage: {
        pattern: "should not contain letters and should not start with 0 or 1",
      },
      nullable: true,
    },
    {
      type: "string",
      pattern: CustomRegexes.PHONE,
      errorMessage: {
        pattern: "should not contain letters and should not start with 0 or 1",
      },
      nullable: true,
    },
  ],
};
const howLongYearsMonths = {
  type: "string",
  pattern: CustomRegexes.NUMBERS,
  errorMessage: { pattern: "should not contain letters" },
  nullable: true,
};
const priceField = { type: "number", nullable: true };
const zipCode = {
  type: "string",
  pattern: CustomRegexes.ZIP_CODE,
  errorMessage: {
    pattern: "must match either of the formats: 'XXXXX', 'XXXXX-XXXX'",
  },
  nullable: true,
};
const booleanString = {
  type: "string",
  nullable: true,
  enum: ["true", "false", null],
  errorMessage: { enum: "must be one of the following: 'true', 'false'" },
};
const address = {
  type: "object",
  nullable: true,
  properties: {
    IsCurrent: booleanString,
    IsMailing: booleanString,
    StreetNo: { type: "string", nullable: true },
    StreetName: { type: "string", nullable: true },
    AptNo: { type: "string", nullable: true },
    City: { type: "string", nullable: true },
    County: { type: "string", nullable: true },
    State: { type: "string", nullable: true },
    ZipCode: zipCode,
    Status: { type: "string", nullable: true },
    HowLongYears: howLongYearsMonths,
    HowLongMonths: howLongYearsMonths,
    MonthlyPayment: priceField,
  },
};

const employment = {
  type: "object",
  nullable: true,
  properties: {
    IsCurrent: booleanString,
    NumOrder: { type: "string", nullable: true },
    Status: { type: "string", nullable: true },
    Occupation: { type: "string", nullable: true },
    EmployerName: { type: "string", nullable: true },
    Address: { type: "string", nullable: true },
    City: { type: "string", nullable: true },
    State: { type: "string", nullable: true },
    ZipCode: zipCode,
    GrossSalary: priceField,
    GrossSalaryType: { type: "string", nullable: true },
    WorkPhone: phone,
    HowLongYears: howLongYearsMonths,
    HowLongMonths: howLongYearsMonths,
  },
};
const borrowerData = {
  type: "object",
  nullable: true,
  properties: {
    NumOrder: { type: "string", nullable: true },
    Type: { type: "string", nullable: true },
    FirstName: name,
    MiddleName: name,
    LastName: name,
    Suffix: { type: "string", nullable: true },
    SSN: {
      anyOf: [
        {
          type: "string",
          pattern: CustomRegexes.SSN,
          errorMessage: {
            pattern: `
                      SSN cannot
                      begin with 666 or 900-999,
                      be 078-05-1120 or 219-09-9999,
                      contain all zeroes in a group(i.e., 000-##-####, ###-00-####, or ###-##-0000)
                      or contain all matching values(i.e., 000-00-0000, 111-11-1111, 222-22-2222, etc.)
                    `,
          },
          nullable: true,
        },
        {
          type: "string",
          pattern: CustomRegexes.SSN_WITH_DASHES,
          errorMessage: {
            pattern: `
                      SSN cannot
                      begin with 666 or 900-999,
                      be 078-05-1120 or 219-09-9999,
                      contain all zeroes in a group(i.e., 000-##-####, ###-00-####, or ###-##-0000)
                      or contain all matching values(i.e., 000-00-0000, 111-11-1111, 222-22-2222, etc.)
                    `,
          },
          nullable: true,
        },
      ],
    },

    DOB: {
      type: "string",
      format: "age-restriction",
      errorMessage: {
        format: "must be a valid date between 18 and 100 years",
      },
      nullable: true,
    },
    HomePhone: phone,
    MobilePhone: phone,
    DLNo: { type: "string", nullable: true },
    DLState: { type: "string", nullable: true },
    Email: {
      type: "string",
      pattern: CustomRegexes.EMAIL,
      errorMessage: {
        pattern: "must match the format <emailname>@<provider>.<org>",
      },
      nullable: true,
    },
    BusinessName: { type: "string", nullable: true },
    BusinessType: { type: "string", nullable: true },
    BusinessTaxID: { type: "string", nullable: true },
    BusinessPhone: phone,
    BusinessHowLongYears: howLongYearsMonths,
    BusinessHowLongMonths: howLongYearsMonths,
    Addresses: {
      type: "object",
      nullable: true,
      properties: {
        Address: { type: "array", items: address, nullable: true },
      },
    },
    EmploymentInfo: {
      type: "object",
      nullable: true,
      properties: {
        includePreviousEmployment: { type: "boolean", nullable: true },
        Employment: {
          type: "array",
          items: employment,
          nullable: true,
        },
      },
    },
    OtherIncome: {
      type: "object",
      nullable: true,
      properties: {
        Amount: priceField,
        AmountType: { type: "string", nullable: true },
        Source: { type: "string", nullable: true },
        Description: { type: "string", nullable: true },
      },
    },
    References: {
      type: "object",
      nullable: true,
      properties: {
        Reference: {
          type: "object",
          nullable: true,
          properties: {
            Type: { type: "string", nullable: true },
            NumOrder: { type: "string", nullable: true },
            Name: { type: "string", nullable: true },
            Address: { type: "string", nullable: true },
            City: { type: "string", nullable: true },
            State: { type: "string", nullable: true },
            ZipCode: zipCode,
            Phone: phone,
            Checking: { type: "string", nullable: true },
            Savings: { type: "string", nullable: true },
          },
        },
      },
    },
    CreditBureaus: {
      type: "object",
      nullable: true,
      properties: {
        CreditBureau: {
          type: "array",
          nullable: true,
          items: {
            type: "object",
            nullable: true,
            properties: {
              Source: { type: "string", nullable: true },
              Score: { type: "string", nullable: true },
              ScoreRangeLow: { type: "string", nullable: true },
              ScoreRangeHigh: { type: "string", nullable: true },
              ScoreRankPercent: { type: "string", nullable: true },
            },
          },
        },
      },
    },
  },
};

export const borrowers = {
  type: "object",
  nullable: true,
  additionalProperties: true,
  anyOf: [
    {
      properties: {
        Borrower: borrowerData,
        includeCoBorrower: { type: "boolean", nullable: true },
        CoBorrower: borrowerData,
      },
    },
    {
      properties: {
        Borrower: {
          type: "array",
          nullable: true,
          items: borrowerData,
        },
      },
    },
  ],
};
// export const borrowers = {
//   type: "object",
//   nullable: true,
//   additionalProperties: true,
//   properties: {
//     Borrower: {
//       anyOf: [
//         {
//           type: "array",
//           nullable: true,
//           items: borrowerData,
//         },
//         {
//           type: "object",
//           nullable: true,
//           properties: borrowerData.properties,
//         },
//         // borrowerData,
//       ],
//     },
//     includeCoBorrower: { type: "boolean", nullable: true },
//     CoBorrower: borrowerData,
//   },
// };
export const options = {
  type: "object",
  nullable: true,
  properties: {
    Option: {
      type: "object",
      nullable: true,
      properties: {
        NumOrder: { type: "string", nullable: true },
        Name: { type: "string", nullable: true },
        SellingPrice: { type: "string", nullable: true },
        IsTaxable: { type: "boolean", nullable: true },
        Code: { type: "string", nullable: true },
        IsIncluded: { type: "boolean", nullable: true },
        IsIncludedInTrim: { type: "boolean", nullable: true },
        TradeInValue: { type: "string", nullable: true },
        LoanValue: { type: "string", nullable: true },
        RetailValue: { type: "string", nullable: true },
        AvgTradeInValue: { type: "string", nullable: true },
        RoughTradeInValue: { type: "string", nullable: true },
      },
    },
  },
};
export const valuation = {
  type: "object",
  nullable: true,
  properties: {
    Source: { type: "string", nullable: true },
    Region: { type: "string", nullable: true },
    ValuationDate: { type: "string", nullable: true },
    VehicleCode: { type: "string", nullable: true },
    Condition: { type: "string", nullable: true },
    MSRP: { type: "string", nullable: true },
    BaseTradeInValue: { type: "string", nullable: true },
    BaseLoanValue: { type: "string", nullable: true },
    BaseRetailValue: { type: "string", nullable: true },
    BaseAvgTradeInValue: { type: "string", nullable: true },
    BaseRoughTradeInValue: { type: "string", nullable: true },
    MileageAdjustmentValue: { type: "string", nullable: true },
    TradeInAdjustmentValue: { type: "string", nullable: true },
    LoanAdjustmentValue: { type: "string", nullable: true },
    RetailAdjustmentValue: { type: "string", nullable: true },
    AvgTradeInAdjustmentValue: { type: "string", nullable: true },
    RoughTradeInAdjustmentValue: { type: "string", nullable: true },
    FinalTradeInValue: { type: "string", nullable: true },
    FinalLoanValue: { type: "string", nullable: true },
    FinalRetailValue: { type: "string", nullable: true },
    FinalAvgTradeInValue: { type: "string", nullable: true },
    FinalRoughTradeInValue: { type: "string", nullable: true },
    FinalCPOValue: { type: "string", nullable: true },
    Options: options,
  },
};
export const collateral = {
  type: "object",
  nullable: true,
  properties: {
    Type: { type: "string", nullable: true },
    Age: { type: "string", nullable: true },
    Serial: { type: "string", nullable: true },
    Year: { type: "integer", nullable: true },
    Make: { type: "string", nullable: true },
    Model: { type: "string", nullable: true },
    Style: { type: "string", nullable: true },
    Color: { type: "string", nullable: true },
    Mileage: { type: "number", nullable: true },
    FuelType: { type: "string", nullable: true },
    MSRP: priceField,
    RVClass: { type: "string", nullable: true },
    IsCPO: { type: "boolean", nullable: true },
    EnableManualEntry: { type: "boolean", nullable: true },
    Horsepower: { type: "string", nullable: true },
    DriveType: { type: "string", nullable: true },
    Length: { type: "string", nullable: true },
    CostPrice: priceField,
    SellingPrice: priceField,
    hasTrailer: { type: "boolean", nullable: true },
    Trailer: {
      type: "object",
      nullable: true,
      properties: {
        Age: {
          type: ["string", "null"],
          nullable: true,
          enum: ["NEW", "USED", "DEMO", null],
          errorMessage: {
            enum: "must be one of the following: 'NEW', 'USED', 'DEMO'",
          },
        },
        Year: { type: "integer", nullable: true },
        Make: { type: "string", nullable: true },
        Model: { type: "string", nullable: true },
        Serial: { type: "string", nullable: true },
      },
    },
    Motors: {
      type: "object",
      nullable: true,
      properties: {
        Motor: {
          type: "array",
          nullable: true,
          items: {
            type: "object",
            nullable: true,
            properties: {
              NumOrder: { type: "string", nullable: true },
              Age: collateralAge,
              Serial: { type: "string", nullable: true },
              Year: { type: "integer", nullable: true },
              Make: { type: "string", nullable: true },
              Model: { type: "string", nullable: true },
            },
          },
        },
      },
    },
    Options: options,
    Valuation: valuation,
  },
};

export const structure = {
  type: "object",
  nullable: true,
  properties: {
    SellingPrice: priceField,
    TradeInAllowance: priceField,
    TradeInPayoff: priceField,
    Rebate: priceField,
    CashDown: priceField,
    TotalTaxes: priceField,
    TotalFees: priceField,
    TotalProducts: priceField,
    TotalAmountFinanced: priceField,
    Term: priceField,
    Rate: priceField,
    DaysToFirstPayment: { type: "integer", nullable: true },
    Fees: {
      type: "object",
      nullable: true,
      properties: {
        Fee: {
          type: "array",
          nullable: true,
          items: {
            type: "object",
            nullable: true,
            properties: {
              Code: { type: "string", nullable: true },
              Name: { type: "string", nullable: true },
              Amount: priceField,
              IsTaxed: { type: "boolean", nullable: true },
            },
          },
        },
      },
    },
    Products: {
      type: "object",
      nullable: true,
      properties: {
        Product: {
          type: "object",
          nullable: true,
          properties: {
            Code: { type: "string", nullable: true },
            Name: { type: "string", nullable: true },
            CompanyName: { type: "string", nullable: true },
            CostPriceAmount: { type: "string", nullable: true },
            SellingPriceAmount: { type: "string", nullable: true },
            IsTaxed: { type: "boolean", nullable: true },
            CoverageDescription: { type: "string", nullable: true },
            CoverageType: { type: "string", nullable: true },
          },
        },
      },
    },
    Taxes: {
      type: "object",
      nullable: true,
      properties: {
        Tax: {
          type: "object",
          nullable: true,
          properties: {
            Code: { type: "string", nullable: true },
            Name: { type: "string", nullable: true },
            ItemType: { type: "string", nullable: true },
            ItemCode: { type: "string", nullable: true },
            IsOverridden: { type: "string", nullable: true },
            Rate: { type: "string", nullable: true },
            Amount: { type: "string", nullable: true },
          },
        },
      },
    },
  },
};

export const tradeInInfo = {
  type: "object",
  properties: {
    TradeIn: {
      type: "array",
      nullable: true,
      items: {
        type: "object",
        nullable: true,
        properties: {
          NumOrder: { type: "string", nullable: true },
          Serial: { type: "string", nullable: true },
          Year: { type: ["string", "number"], nullable: true },
          Make: { type: "string", nullable: true },
          Model: { type: "string", nullable: true },
          Mileage: { type: ["string", "number"], nullable: true },
          TradeInAllowance: { type: ["string", "number"], nullable: true },
          TradeInPayoff: { type: ["string", "number"], nullable: true },
          LienHolder: { type: "string", nullable: true },
          LienHolderPhone: phone,
          LienHolderAccountNum: { type: "string", nullable: true },
        },
      },
    },
  },
  nullable: true,
};
export const insuranceInfo = {
  type: "object",
  nullable: true,
  properties: {
    Company: { type: "string", nullable: true },
    Agent: { type: "string", nullable: true },
    AgentPhone: phone,
    PolicyNumber: { type: "string", nullable: true },
    Deductible: { type: "string", nullable: true },
  },
};
