import React from "react";
import { Dialog, Grid, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import CloseDialogButton from "./CloseDialogButton";

interface Props {
  closeFunc: (x: boolean) => void;
  open: boolean;
  content: string;
}

export default function AlertDialog({ closeFunc, open, content }: Props) {
  const handleClose = () => {
    closeFunc(false);
  };

  return (
    <Dialog fullWidth onClose={closeFunc} open={open}>
      <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
        <Grid item xs={6} key="title">
          <DialogTitle>
            Alert
            <CloseDialogButton closeFunction={handleClose} />
          </DialogTitle>
        </Grid>
      </Grid>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary" variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
