import React, { useState, useContext } from "react";
import { storage } from "../../../firebase/firebase";
import { StateAccess } from "utils/models/formGenerator";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { Box, Button, CircularProgress, IconButton } from "@material-ui/core";
import { Dealership } from "../types";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import formEditContext from "components/Content/FormEditContext";
import LogoPreviewDialog from "./LogoPreviewDialog";

interface Props {
  stateAccess: StateAccess;
}

export default function UploadLogoPreview({ stateAccess }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { enabled: showButton } = useContext(formEditContext);
  const [state, setState] = useState({ showLoading: false });
  const [open, setOpen] = useState(false);
  const logo = stateAccess.get<Dealership>(["data", "info", "logo"]);

  const resetLogoState = () => {
    return stateAccess.set<Dealership>(["data", "info", "logo"], undefined);
  };

  const handleUploadClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event.target.files ?? [];
    const randomId = uuidv4();
    const dealershipId = stateAccess.get<Dealership>(["_id"]);

    if (uploadedFiles.length !== 0) {
      if (uploadedFiles[0]?.size <= 2097152) {
        const file = uploadedFiles[0];
        resetLogoState();
        const firebaseFileName = `${randomId}-${file.name}`;
        const uploadTask = storage
          .ref(`/dealerships/logos/${dealershipId}/${firebaseFileName}`)
          .put(file);

        uploadTask.on(
          "state_changed",
          () => {
            setState({
              ...state,
              showLoading: true
            });
          },
          (err) => {
            enqueueSnackbar(err.message, {
              variant: "error"
            });

            setState({ ...state, showLoading: false });
          },
          () => {
            const ref = storage.ref(`/dealerships/logos/${dealershipId}/`).child(firebaseFileName);

            ref.getDownloadURL().then((fireBaseUrl) => {
              ref.getMetadata().then((metadata) => {
                stateAccess.set<Dealership>(["data", "info", "logo"], {
                  name: file.name,
                  url: fireBaseUrl,
                  metadata
                });
              });
            });

            setState({ ...state, showLoading: false });
          }
        );
      } else {
        enqueueSnackbar("Logo size must be less than 2Mb.", {
          variant: "error"
        });
      }
    }
  };

  const handleDelete = () => resetLogoState();

  const handleOpenLogoPreviewDialog = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    setOpen(true);
  };

  return showButton ? (
    <div style={{ display: "flex" }}>
      <div>
        <Button
          variant="contained"
          component="label"
          color="primary"
          startIcon={<AddPhotoAlternateIcon />}
        >
          Upload Logo
          <input type="file" hidden accept="image/*" onChange={handleUploadClick} />
        </Button>
      </div>
      <div style={{ marginLeft: "10px" }}>
        {state.showLoading ? (
          <Box display="flex" alignItems="center">
            <Box minWidth={35}>
              <CircularProgress size={27} />
            </Box>
          </Box>
        ) : (
          logo?.url !== undefined && (
            <div style={{ fontStyle: "italic" }}>
              <a href="/" rel="noopener noreferrer" onClick={handleOpenLogoPreviewDialog}>
                {logo.name}
              </a>
              <IconButton
                style={{
                  color: "#E34C28"
                }}
                onClick={handleDelete}
              >
                <RemoveCircleIcon />
              </IconButton>
            </div>
          )
        )}
      </div>
      <LogoPreviewDialog open={open} setOpen={setOpen} logoUrl={logo?.url} />
    </div>
  ) : (
    <> </>
  );
}
