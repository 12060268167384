import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import CancelIcon from "@material-ui/icons/Cancel";
import { Dialog, IconButton, Typography } from "@material-ui/core";
import { DateRangePicker } from "DateRangePicker";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";

const formatRange = (from: Date, to: Date) => {
  if (from !== undefined && to !== undefined)
    return (
      <span style={{ fontSize: "18px", marginRight: "15px" }}>
        {new Date(from).toLocaleDateString("en-US")}
        {" - "}
        {new Date(to).toLocaleDateString("en-US")}
      </span>
    );
  else {
    return null;
  }
};
export interface DialogProps {
  open: boolean;
  filter: DateState;
  setFilter: React.Dispatch<DateState>;
  onClose: () => void;
}
export function DateFilterDialog(props: DialogProps) {
  const { onClose, open, filter, setFilter } = props;

  return (
    <Dialog onClose={onClose} aria-labelledby="date-filter-dialog" open={open} maxWidth="lg">
      <Grid item xs={12}>
        <DateRangePicker
          initialDateRange={
            filter?.from && filter?.to
              ? { startDate: new Date(filter?.from), endDate: new Date(filter?.to) }
              : {}
          }
          onChange={(range) => {
            if (range?.startDate !== undefined && range?.endDate !== undefined) {
              const beginningOfTheDay = new Date(range?.startDate);
              beginningOfTheDay.setHours(0, 0, 0, 0);
              const endOfTheDay = new Date(range?.endDate);
              endOfTheDay.setHours(23, 59, 59, 999);
              setFilter({ from: beginningOfTheDay, to: endOfTheDay });
            } else {
              setFilter(undefined);
            }
          }}
        />
        <div style={{ display: "flex" }}>
          <Button
            onClick={onClose}
            size="small"
            style={{ marginLeft: "auto", paddingRight: "10px" }}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setFilter(undefined);
              onClose();
            }}
            size="small"
            startIcon={<CancelIcon />}
            style={{ paddingRight: "15px" }}
          >
            Cancel
          </Button>
        </div>
      </Grid>
    </Dialog>
  );
}

export type DateState = { from: Date; to: Date } | undefined;

export interface FilterProps<T> {
  setFilter: React.Dispatch<DateState>;
  filter: DateState;
}
export default function DateFilterLenderDecisions<T extends unknown>({
  setFilter,
  filter
}: FilterProps<T>) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
      <Grid item>
        {filter?.from && filter?.to ? (
          <Grid container justifyContent="flex-end" alignItems="center">
            {filter?.from && filter?.to && formatRange(filter.from, filter.to)}
            <IconButton
              size="small"
              style={{
                color: "#254e6f"
              }}
              onClick={() => {
                setFilter(undefined);
              }}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        ) : (
          <Typography>Pick a date</Typography>
        )}
      </Grid>
      <Grid item style={{ padding: "10px 0px" }}>
        <IconButton onClick={handleClickOpen}>
          <CalendarTodayIcon style={{ color: "#254e6f" }} />
        </IconButton>
        <DateFilterDialog filter={filter} setFilter={setFilter} open={open} onClose={handleClose} />
      </Grid>
    </Grid>
  );
}
