import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

export const renderTableRowWithData = (
  propertyName: string,
  value: string | undefined,
  valid: boolean | undefined
) => {
  return (
    <TableRow>
      <TableCell>{propertyName}</TableCell>
      <TableCell>{value ?? "No information"}</TableCell>
      <TableCell>
        {valid === undefined ? (
          "No information"
        ) : valid ? (
          <CheckCircleOutlineIcon fontSize="large" style={{ color: "rgb(80, 165, 56)" }} />
        ) : (
          <HighlightOffIcon fontSize="large" style={{ color: "red" }} />
        )}
      </TableCell>
    </TableRow>
  );
};
export const renderTableHeadRow = () => {
  return (
    <TableRow>
      <TableCell>Risk property</TableCell>
      <TableCell>Value</TableCell>
      <TableCell>Status</TableCell>
    </TableRow>
  );
};
