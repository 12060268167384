import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteCustomerCareNoteRequest {
  _id: string;
}

interface DeleteCustomerCareNoteResponse {
  message: {
    _id: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteCustomerCareNoteRequest,
  DeleteCustomerCareNoteResponse
>("DeleteDealershipTicketNote", {
  request: "delete_customer_care_note"
});

export const deleteCustomerCareNoteActions = socketMessageSlice.actions;
export const deleteCustomerCareNote = socketAction;
export default socketMessageSlice.reducer;
