import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { CreditBureau, CreditCheckMode, CreditCheckTypes, SuccessResponse } from "./types";

interface CheckCreditScoreRequest {
  statusChange: boolean;
  data: {
    info: {
      appModified?: boolean;
      dealId: string;
      applicantId: string;
      coApplicantId?: string;
      type: CreditCheckTypes;
      applicant?: {
        firstName?: string;
        lastName?: string;
        address?: string;
        city?: string;
        state?: string;
        zipCode?: string | null;
        ssn?: string;
      };
      coApplicant?: {
        firstName?: string;
        lastName?: string;
        address?: string;
        city?: string;
        state?: string;
        zipCode?: string | null;
        ssn?: string;
      };
    };
  };
}

export type ErrorResponse = {
  Creditsystem_Error: [];
};
export type APIResponse = {
  Results?: SuccessResponse | ErrorResponse;
};

export const isSuccessResponse = (
  response: SuccessResponse | ErrorResponse | undefined
): response is SuccessResponse =>
  (response?.hasOwnProperty("bureau_xml_data") && response?.hasOwnProperty("custom_report_url")) ===
  true;

export const isErrorResponse = (
  response: SuccessResponse | ErrorResponse | undefined
): response is ErrorResponse => response?.hasOwnProperty("Creditsystem_Error") === true;

export interface CreditBureauResponse {
  data: {
    type: CreditCheckTypes;
    dealId: string;
    applicantId: string;
    coApplicantId: string;
    "700CreditResponse": APIResponse;
    request: CreditBureauRequest;
    source: "700Credit" | "local";
    createdAt?: string;
  };
  _id: string;
  createdAt: string;
}
export interface CreditBureauRequest {
  PRODUCT: string;
  BUREAU: string;
  PASS: string;
  PROCESS: string;
  NAME: string;
  ADDRESS: string;
  CITY: string;
  STATE: string;
  ZIP: string;
  SSN: string;
  SPOUSE: string;
  SPOUSEADDRESS: string;
  SPOUSECITY: string;
  SPOUSESTATE: string;
  SPOUSEZIP: string;
  SPOUSESSN: string;
}

interface CheckCreditScoreResponse {
  message: CreditBureauResponse;
  status: "success";
}

export const isLocalResult = (result: CreditBureau) => result.data.source === "local";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  CheckCreditScoreRequest,
  CheckCreditScoreResponse
>("CheckCreditScore", {
  request: "new_credit_score"
});

export const checkCreditScoreActions = socketMessageSlice.actions;
export const checkCreditScore = socketAction;
export default socketMessageSlice.reducer;
