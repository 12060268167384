import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

interface DeleteLenderNoteRequest {
  _id: string;
}

interface DeleteLenderNoteResponse {
  message: {
    _id: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteLenderNoteRequest,
  DeleteLenderNoteResponse
>("DeleteLenderNote", {
  request: "delete_lender_note"
});

export const deleteLenderNoteActions = socketMessageSlice.actions;
export const deleteLenderNote = socketAction;
export default socketMessageSlice.reducer;
