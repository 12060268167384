import React, { useState } from "react";
import { auth } from "../../../firebase/firebase";

import { Link } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

import { devlog } from "utils/logging";
import { LogoWithText } from "components/AppBar/Logo";

export default function ForgotPassword(): JSX.Element {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(4)
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
      },
      form: {
        width: "80%",
        marginTop: theme.spacing(1)
      },
      submit: {
        backgroundColor: "#19181f",
        margin: theme.spacing(3, 0, 2)
      },
      errorText: {
        color: "#a4031f",
        marginTop: "2px",
        textAlign: "center"
      },
      container: {
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "#19181f"
      },
      logo: { textAlign: "center" }
    })
  );
  const classes = useStyles();

  const initInfoText = "Enter your email address to receive a password reset link.";
  const [email, setEmail] = useState("");
  const [infotext, updateInfoText] = useState(initInfoText);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    devlog(email);

    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        updateInfoText(
          "Password reset link sent! Please, check your email to complete the update password process."
        );
      })
      .catch(function (error) {
        updateInfoText(
          `Please, check the email you typed! ${
            email
              ? email + " not found among registered users. " + error
              : "Email field cannot be empty!"
          }`
        );
      });
  };

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <LogoWithText />
      </div>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Forgot your password?
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e): void => {
                setEmail(e.target.value);
                if (e.target.value !== "") updateInfoText(initInfoText);
              }}
            />

            <Typography component="p">{infotext}</Typography>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Send link
            </Button>
            <Grid container>
              <Grid item>
                <Link to="/sign-up">Don&apos; have an account? Sign Up</Link>
                <br />
                <Link to="/sign-in">Sign In</Link>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </div>
  );
}
