import { getPrefixByState } from "utils/functions";
import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { Deal, FormState } from "./types";

interface DealRequest {
  data: Partial<FormState>;
}
interface AddDealResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<DealRequest, AddDealResponse>(
  "AddDealerTrackDeal",
  {
    request: "dealertrack_deal_add"
  }
);
const yearsToMonths = (years: number | null | undefined, months: number | null | undefined) => {
  return (years || 0) * 12 + (months || 0);
};

const BMOIds: (keyof typeof TargetPlatForms)[] = ["Web Finance Direct", "BMO RV"];
export enum TestTargetPlatForms {
  "Web Finance Direct" = "1234",
  "BMO RV" = "test"
}
export enum TargetPlatForms {
  "Web Finance Direct" = "148625",
  "All Cars Inc" = "344410",
  "Domine Auto" = "567212",
  "Prospect Auto Sales" = "358624",
  "Wholesale Enterprise" = "500498",
  "Steve's Hillcrest" = "411703",
  "Car Connection" = "800118",
  "Cartiva" = "336364",
  "Koehn Auto Sales" = "340505",
  "BMO RV" = "193959",
  "Capital Motor Company" = "314080",
  "Christian Auto Sales" = "369184"
}

export const isDriverLicenseDisabled = (
  type: "applicant" | "coApplicant",
  targetId: keyof typeof TargetPlatForms
) => type === "applicant" && BMOIds.includes(targetId);

export const addDealerTrackDealActions = socketMessageSlice.actions;
export const addDealerTrackDeal = ({ data, requestId }: Deal & Identifiable) => {
  const {
    includeCoApplicant,
    applicant,
    coApplicant,
    tradeIns,
    dealership,
    targetPlatforms,
    ...rest
  } = data;

  const targetId = data?.targetPlatforms?.[0]?.partyId as keyof typeof TargetPlatForms;
  const isBMO = BMOIds.includes(targetId);

  const partyId =
    process.env.REACT_APP_FIREBASE_PROJECT_ID !== "wfd-frontend"
      ? TestTargetPlatForms[targetId as keyof typeof TestTargetPlatForms]
      : TargetPlatForms[targetId as keyof typeof TargetPlatForms];

  const applicantDriversLicenseNumber =
    isBMO && dealership
      ? `${dealership?.data?.info?.abbreviation}`
      : applicant?.driversLicenseNumber;

  const applicantDriversLicenseState =
    isBMO && dealership?.data?.info?.state
      ? `${getPrefixByState(dealership?.data?.info?.state)}`
      : applicant?.driversLicenseState;
  const applicantToSend = {
    ...applicant,
    maritalStatus:
      (applicant.maritalStatus as string) === "Not married" ? "Unmarried" : applicant.maritalStatus,
    monthsAtCurrentAddress: yearsToMonths(
      applicant?.yearsAtCurrentAddress,
      applicant?.monthsAtCurrentAddress
    ),
    monthsAtPreviousAddress: yearsToMonths(
      applicant?.yearsAtPreviousAddress,
      applicant?.monthsAtPreviousAddress
    ),
    currentEmployment: {
      ...applicant?.currentEmployment,
      totalMonthsEmployed: yearsToMonths(
        applicant?.currentEmployment?.totalYearsEmployed,
        applicant?.currentEmployment?.totalMonthsEmployed
      ),
      totalYearsEmployed: undefined
    },
    previousEmployment: {
      ...applicant?.previousEmployment,
      totalMonthsEmployed: yearsToMonths(
        applicant?.previousEmployment?.totalYearsEmployed,
        applicant?.previousEmployment?.totalMonthsEmployed
      ),
      totalYearsEmployed: undefined
    },
    driversLicenseNumber: applicantDriversLicenseNumber,
    driversLicenseState: applicantDriversLicenseState
  };

  const coApplicantToSend = {
    ...coApplicant,
    maritalStatus:
      (coApplicant?.maritalStatus as string) === "Not married"
        ? "Unmarried"
        : coApplicant?.maritalStatus,
    monthsAtCurrentAddress: yearsToMonths(
      coApplicant?.yearsAtCurrentAddress,
      coApplicant?.monthsAtCurrentAddress
    ),
    yearsAtCurrentAddress: undefined,
    monthsAtPreviousAddress: yearsToMonths(
      coApplicant?.yearsAtPreviousAddress,
      coApplicant?.monthsAtPreviousAddress
    ),
    yearsAtPreviousAddress: undefined,
    currentEmployment: {
      ...coApplicant?.currentEmployment,
      totalMonthsEmployed: yearsToMonths(
        coApplicant?.currentEmployment?.totalYearsEmployed,
        coApplicant?.currentEmployment?.totalMonthsEmployed
      ),
      totalYearsEmployed: undefined
    },
    previousEmployment: {
      ...coApplicant?.previousEmployment,
      totalMonthsEmployed: yearsToMonths(
        coApplicant?.previousEmployment?.totalYearsEmployed,
        coApplicant?.previousEmployment?.totalMonthsEmployed
      ),
      totalYearsEmployed: undefined
    }
  };

  return socketAction({
    requestId,
    data: {
      ...rest,
      applicant: applicantToSend,
      ...(includeCoApplicant ? { coApplicant: coApplicantToSend } : {}),
      targetPlatforms: [{ ...targetPlatforms[0], partyId }],
      tradeIns: !Object.values(tradeIns[0] ?? {}).every((value) => value === null) ? tradeIns : []
    }
  });
};
export default socketMessageSlice.reducer;
