import { Model, FormComponent, createOptionsForSelect } from "utils/models/fields";
import { Deal, Motor } from "./types";
import { StateAccess } from "utils/models/formGenerator";
import React from "react";
import States from "us-states";
import { getStateByPrefix, getStateLabelByStatePrefix } from "../../../utils/functions";

import SaveDealButton from "./AppOneButtons";
import { getYearsForSelectField } from "utils/functions";

const currentAddressStruct = (type: "Borrower" | "CoBorrower"): FormComponent<Deal> => ({
  formComponent: "segment",
  name: "Current Residence",
  width: "full",
  entities: [
    {
      formComponent: "digits-only-field",
      name: "StreetNo",
      label: "Address #",
      required: true,
      width: "1/3",
      path: ["data", "Borrowers", type, "Addresses", "Address", 0, "StreetNo"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "StreetName",
      label: "Street",
      width: "1/3",
      required: true,
      path: ["data", "Borrowers", type, "Addresses", "Address", 0, "StreetName"],
      default: null
    },
    {
      formComponent: "zip-code-field",
      name: "ZipCode",
      label: "Zip Code",
      required: true,
      hideZipCodeSearch: true,
      width: "1/3",
      path: ["data", "Borrowers", type, "Addresses", "Address", 0, "ZipCode"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "City",
      label: "City",
      width: "1/3",
      minLength: 3,
      required: true,
      path: ["data", "Borrowers", type, "Addresses", "Address", 0, "City"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "State",
      label: "State",
      width: "1/3",
      path: ["data", "Borrowers", type, "Addresses", "Address", 0, "State"],
      required: true,
      options: createOptionsForSelect({
        possibleValues: () => Object.values(States).map((x) => x.prefix),
        getOptionLabel: (x) => getStateLabelByStatePrefix(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "select-field",
      name: "county",
      label: "County",
      width: "1/3",
      path: ["data", "Borrowers", type, "Addresses", "Address", 0, "County"],
      options: createOptionsForSelect({
        possibleValues: (stateAccess: StateAccess) => {
          return States[
            getStateByPrefix(
              stateAccess.get<Deal>(["data", "Borrowers", type, "Addresses", "Address", 0, "State"])
            ) as string
          ]?.counties;
        },
        getOptionLabel: (x) => x ?? " ",
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "hidden-field",
      name: "IsCurrent",
      label: "Is Current?",
      width: "1/3",
      path: ["data", "Borrowers", type, "Addresses", "Address", 0, "IsCurrent"],
      default: "true"
    },
    {
      formComponent: "checkbox-field",
      path: ["data", "Borrowers", type, "Addresses", "Address", 0, "IsMailing"],
      name: "IsMailing",
      label: "Is Mailing?",
      width: "full",
      default: "true",
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) => {
        const data = stateAccess.get(["data"]);
        return data?.Borrowers?.type?.Addresses?.Address?.[0]?.IsMailing === "true";
      },
      toggle: (stateAccess: StateAccess) => {
        const IsMailing = stateAccess.get(["data"])?.Borrowers?.type?.Addresses?.Address?.[0]
          ?.IsMailing;

        stateAccess.set<Deal>(
          ["data", "Borrowers", type, "Addresses", "Address", 0, "IsMailing"],
          IsMailing === "true" ? "false" : "true"
        );
      }
    },
    {
      formComponent: "digits-only-field",
      name: "HowLongYears",
      label: "How Long Years?",
      required: true,
      width: "1/3",
      maxLength: 3,
      path: ["data", "Borrowers", type, "Addresses", "Address", 0, "HowLongYears"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "HowLongMonths",
      width: "1/3",
      label: "How Long Months?",
      required: true,
      maxLength: 3,
      path: ["data", "Borrowers", type, "Addresses", "Address", 0, "HowLongMonths"],
      default: null
    },
    {
      formComponent: "currency-field",
      width: "1/3",
      name: "MonthlyPayment",
      label: "Monthly Payment",
      required: true,
      max: 99999,
      path: ["data", "Borrowers", type, "Addresses", "Address", 0, "MonthlyPayment"],
      default: null
    },
    {
      formComponent: "radio-field",
      name: "Status",
      label: "Status",
      width: "1/2",
      required: true,
      path: ["data", "Borrowers", type, "Addresses", "Address", 0, "Status"],
      default: null,
      possibleValues: [
        { label: "Own", value: "OWN" },
        { label: "Rent", value: "RENT" },
        { label: "With relatives", value: "WITHRELATIVES" },
        { label: "With friends", value: "WITHFRIENDS" },
        { label: "Other", value: "OTHER" },
        { label: "Unknown", value: "UNKNOWN" }
      ]
    }
  ]
});
const mailingAddressStruct = (type: "Borrower" | "CoBorrower"): FormComponent<Deal> => ({
  formComponent: "segment",
  name: "Mailing Address",
  width: "full",
  show: (stateAccess) =>
    stateAccess.get<Deal>(["data", "Borrowers", type, "Addresses", "Address", 0, "IsMailing"]) ===
    "false",
  entities: [
    {
      formComponent: "hidden-field",
      name: "IsCurrent",
      label: "Is Current?",
      width: "1/3",
      path: ["data", "Borrowers", type, "Addresses", "Address", 1, "IsCurrent"],
      default: "true"
    },
    {
      formComponent: "hidden-field",
      path: ["data", "Borrowers", type, "Addresses", "Address", 1, "IsMailing"],
      name: "IsMailing",
      label: "Is Mailing?",
      width: "2/3",
      default: "true"
    },
    {
      formComponent: "digits-only-field",
      name: "Address",
      label: "Address #",
      width: "1/3",
      path: ["data", "Borrowers", type, "Addresses", "Address", 1, "StreetNo"],
      default: null,
      required: true
    },
    {
      formComponent: "text-field",
      name: "StreetName",
      label: "Street",
      width: "1/3",
      required: true,
      path: ["data", "Borrowers", type, "Addresses", "Address", 1, "StreetName"],
      default: null
    },
    // {
    //   formComponent: "zip-code-field",
    //   name: "ZipCode",
    //   label: "Zip Code",
    //   required: true,
    //   hideZipCodeSearch: true,
    //   width: "1/3",
    //   path: ["data", "Borrowers", type, "Addresses", "Address", 1, "ZipCode"],
    //   default: null
    // },
    {
      formComponent: "text-field",
      name: "City",
      label: "City",
      width: "1/3",
      minLength: 3,
      required: true,
      path: ["data", "Borrowers", type, "Addresses", "Address", 1, "City"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "State",
      label: "State",
      width: "1/3",
      required: true,
      path: ["data", "Borrowers", type, "Addresses", "Address", 1, "State"],
      options: createOptionsForSelect({
        possibleValues: () => Object.values(States).map((x) => x.prefix),
        getOptionLabel: (x) => getStateLabelByStatePrefix(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "select-field",
      name: "county",
      label: "County",
      width: "1/3",
      path: ["data", "Borrowers", type, "Addresses", "Address", 1, "County"],
      options: createOptionsForSelect({
        possibleValues: (stateAccess: StateAccess, path) => {
          return States[
            getStateByPrefix(
              stateAccess.get<Deal>([
                "data",
                "Borrowers",
                type,
                "Addresses",
                "Address",
                1,
                "County"
              ])
            ) as string
          ]?.counties;
        },
        getOptionLabel: (x) => x ?? " ",
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    }
  ]
});
const previousAddressStruct = (type: "Borrower" | "CoBorrower"): FormComponent<Deal> => ({
  formComponent: "segment",
  name: "Previous Residence",
  width: "full",
  show: (stateAccess) =>
    stateAccess.get<Deal>(["data", "Borrowers", type, "Addresses", "Address", 0, "HowLongYears"]) <
    2,
  entities: [
    {
      formComponent: "hidden-field",
      name: "IsCurrent",
      label: "Is Current?",
      width: "1/3",
      path: ["data", "Borrowers", type, "Addresses", "Address", 2, "IsCurrent"],
      default: "false"
    },
    {
      formComponent: "hidden-field",
      path: ["data", "Borrowers", type, "Addresses", "Address", 2, "IsMailing"],
      name: "IsMailing",
      label: "Is Mailing?",
      width: "2/3",
      default: "false"
    },
    {
      formComponent: "digits-only-field",
      name: "StreetNo",
      label: "Address #",
      required: true,
      width: "1/3",
      path: ["data", "Borrowers", type, "Addresses", "Address", 2, "StreetNo"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "StreetName",
      label: "Street",
      width: "1/3",
      required: true,
      path: ["data", "Borrowers", type, "Addresses", "Address", 2, "StreetName"],
      default: null
    },
    {
      formComponent: "zip-code-field",
      name: "ZipCode",
      label: "Zip Code",
      required: true,
      hideZipCodeSearch: true,
      width: "1/3",
      path: ["data", "Borrowers", type, "Addresses", "Address", 2, "ZipCode"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "City",
      label: "City",
      width: "1/3",
      minLength: 3,
      required: true,
      path: ["data", "Borrowers", type, "Addresses", "Address", 2, "City"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "State",
      label: "State",
      width: "1/3",
      required: true,
      path: ["data", "Borrowers", type, "Addresses", "Address", 2, "State"],
      options: createOptionsForSelect({
        possibleValues: () => Object.values(States).map((x) => x.prefix),
        getOptionLabel: (x) => getStateLabelByStatePrefix(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "select-field",
      name: "county",
      label: "County",
      width: "1/3",
      path: ["data", "Borrowers", type, "Addresses", "Address", 2, "County"],
      options: createOptionsForSelect({
        possibleValues: (stateAccess: StateAccess) => {
          return States[
            getStateByPrefix(
              stateAccess.get<Deal>(["data", "Borrowers", type, "Addresses", "Address", 2, "State"])
            ) as string
          ]?.counties;
        },
        getOptionLabel: (x) => x ?? " ",
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "HowLongYears",
      label: "How Long Years?",
      required: true,
      width: "1/3",
      maxLength: 3,
      path: ["data", "Borrowers", type, "Addresses", "Address", 2, "HowLongYears"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "HowLongMonths",
      width: "1/3",
      label: "How Long Months?",
      required: true,
      maxLength: 3,
      path: ["data", "Borrowers", type, "Addresses", "Address", 2, "HowLongMonths"],
      default: null
    },
    {
      formComponent: "radio-field",
      name: "Status",
      label: "Status",
      width: "1/2",
      required: true,
      path: ["data", "Borrowers", type, "Addresses", "Address", 2, "Status"],
      default: null,
      possibleValues: [
        { label: "Own", value: "OWN" },
        { label: "Rent", value: "RENT" },
        { label: "With relatives", value: "WITHRELATIVES" },
        { label: "With friends", value: "WITHFRIENDS" },
        { label: "Other", value: "OTHER" },
        { label: "Unknown", value: "UNKNOWN" }
      ]
    }
  ]
});

const borrower: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Borrower",
  width: "full",
  entities: [
    {
      formComponent: "hidden-field",
      name: "dealId",
      label: "dealId",
      width: "1/4",
      required: true,
      path: ["dealId"],
      default: null
    },
    {
      formComponent: "hidden-field",
      name: "refNumber",
      label: "refNumber",
      width: "1/4",
      required: true,
      path: ["refNumber"],
      default: null
    },
    {
      formComponent: "hidden-field",
      name: "NumOrder",
      label: "Num Order",
      width: "1/4",
      required: true,
      path: ["data", "Borrowers", "Borrower", "NumOrder"],
      default: "1"
    },
    {
      formComponent: "radio-field",
      name: "Type",
      label: "Type",
      width: "full",
      required: true,
      path: ["data", "Borrowers", "Borrower", "Type"],
      default: "INDIVIDUAL",
      possibleValues: [
        { label: "INDIVIDUAL", value: "INDIVIDUAL" },
        { label: "BUSINESS", value: "BUSINESS" }
      ]
    },
    {
      formComponent: "segment",
      name: "Borrower's business",
      width: "full",
      show: (stateAccess: StateAccess): boolean =>
        stateAccess.get<Deal>(["data", "Borrowers", "Borrower", "Type"]) === "BUSINESS",
      entities: [
        {
          formComponent: "text-field",
          name: "BusinessName",
          label: "Business name",
          width: "1/4",
          path: ["data", "Borrowers", "Borrower", "BusinessName"],
          minLength: 3,
          required: true,
          default: null
        },
        {
          formComponent: "radio-field",
          name: "BusinessType",
          label: "Business type",
          required: true,
          width: "full",
          path: ["data", "Borrowers", "Borrower", "BusinessType"],
          default: "",
          possibleValues: [
            { label: "Corporation", value: "CORPORATION" },
            { label: "LLC", value: "LLC" },
            { label: "Partnership", value: "PARTNERSHIP" },
            { label: "Shell corporation", value: "SHELLCORPORATION" },
            { label: "Sole proprietorship", value: "SOLEPROPRIETORSHIP" },
            { label: "Trust", value: "TRUST" },
            { label: "Other", value: "OTHER" }
          ]
        },

        {
          formComponent: "phone-field",
          name: "BusinessPhone",
          label: "Business Phone",
          width: "1/4",
          required: true,
          path: ["data", "Borrowers", "Borrower", "BusinessPhone"],
          default: null
        },

        // {
        //   formComponent: "digits-only-field",
        //   name: "BusinessTaxID",
        //   label: "Business Tax ID",
        //   width: "1/4",
        //   required: true,
        //   path: ["data", "Borrowers", "Borrower", "BusinessTaxID"],
        //   default: null
        // },

        {
          formComponent: "digits-only-field",
          name: "BusinessHowLongYears",
          label: "Business How Long Years?",
          required: true,
          width: "1/4",
          maxLength: 3,
          path: ["data", "Borrowers", "Borrower", "BusinessHowLongYears"],
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Applicant",
      width: "full",
      show: (stateAccess: StateAccess): boolean =>
        stateAccess.get<Deal>(["data", "Borrowers", "Borrower", "Type"]) === "INDIVIDUAL",
      entities: [
        {
          formComponent: "text-field",
          name: "FirstName",
          label: "First name",
          width: "1/6",
          path: ["data", "Borrowers", "Borrower", "FirstName"],
          required: true,
          default: null
        },
        {
          formComponent: "text-field",
          name: "MiddleName",
          label: "Middle name",
          width: "1/6",
          path: ["data", "Borrowers", "Borrower", "MiddleName"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "LastName",
          label: "Last name",
          width: "1/6",
          path: ["data", "Borrowers", "Borrower", "LastName"],
          required: true,
          default: null
        },
        {
          formComponent: "select-field",
          name: "Suffix",
          label: "Suffix",
          width: "1/6",
          path: ["data", "Borrowers", "Borrower", "Suffix"],
          options: createOptionsForSelect({
            possibleValues: () => ["", "SR", "JR", "I", "II", "III", "IV", "V"],
            getOptionLabel: (x) => x,
            getSelectedOption: (x, y) => x === y
          }),
          default: ""
        },
        {
          formComponent: "ssn-field",
          name: "SSN",
          label: "SSN",
          width: "1/6",
          path: ["data", "Borrowers", "Borrower", "SSN"],
          required: true,
          default: null
        },
        {
          formComponent: "date-field",
          name: "DOB",
          label: "Date of birth",
          width: "1/6",
          path: ["data", "Borrowers", "Borrower", "DOB"],
          required: true,
          default: null
        },
        {
          formComponent: "phone-field",
          name: "HomePhone",
          label: "Home Phone",
          required: true,
          width: "1/4",
          path: ["data", "Borrowers", "Borrower", "HomePhone"],
          default: null
        },
        {
          formComponent: "phone-field",
          name: "MobilePhone",
          label: "Mobile phone",
          width: "1/4",
          path: ["data", "Borrowers", "Borrower", "MobilePhone"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "DLNo",
          label: "Driver License Number",
          width: "1/4",
          path: ["data", "Borrowers", "Borrower", "DLNo"],
          default: null
        },
        {
          formComponent: "select-field",
          name: "Driver License State",
          label: "Driver License State",
          width: "1/4",
          path: ["data", "Borrowers", "Borrower", "DLState"],
          options: createOptionsForSelect({
            possibleValues: () => Object.values(States).map((x) => x.prefix),
            getOptionLabel: (x) => getStateLabelByStatePrefix(x),
            getSelectedOption: (x, y) => x === y
          }),
          default: null
        },
        {
          formComponent: "email-field",
          name: "Email",
          label: "Email",
          width: "1/4",
          path: ["data", "Borrowers", "Borrower", "Email"],
          default: null
        }
      ]
    },
    currentAddressStruct("Borrower"),
    mailingAddressStruct("Borrower"),
    previousAddressStruct("Borrower")
  ]
};

const borrowerEmploymentInfo: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Employment Info",
  width: "full",
  entities: [
    {
      formComponent: "checkbox-field",
      name: "IsCurrent",
      label: "Is Current?",
      width: "1/4",
      valueType: "boolean",
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "IsCurrent"],
      isDisabled: (): boolean => false,
      isChecked: (stateAccess: StateAccess): boolean =>
        stateAccess.get<Deal>([
          "data",
          "Borrowers",
          "Borrower",
          "EmploymentInfo",
          "Employment",
          0,
          "IsCurrent"
        ]),
      toggle: (stateAccess: StateAccess): Deal =>
        stateAccess.set<Deal>(
          ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "IsCurrent"],
          !stateAccess.get<Deal>([
            "data",
            "Borrowers",
            "Borrower",
            "EmploymentInfo",
            "Employment",
            0,
            "IsCurrent"
          ])
        ),
      required: true,
      default: true
    },
    {
      formComponent: "radio-field",
      name: "Status",
      label: "Employment Status",
      width: "full",
      required: true,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "Status"],
      default: "EMPLOYED",
      possibleValues: [
        { label: "Employed", value: "EMPLOYED" },
        { label: "Self employed", value: "SELFEMPLOYED" },
        { label: "Temp staffing agency", value: "TEMPSTAFFINGAGENCY" },
        { label: "Student", value: "STUDENT" },
        { label: "Retired", value: "RETIRED" },
        { label: "Military", value: "MILITARY" },
        { label: "Homemaker", value: "HOMEMAKER" },
        { label: "Unemployed", value: "UNEMPLOYED" },
        { label: "Unknown", value: "UNKNOWN" }
      ]
    },
    {
      formComponent: "text-field",
      name: "EmployerName",
      minLength: 3,
      label: "Employer Name",
      width: "1/4",
      required: true,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "EmployerName"],
      default: null
    },

    {
      formComponent: "text-field",
      name: "Occupation",
      label: "Occupation",
      width: "1/4",
      required: true,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "Occupation"],
      default: null
    },

    {
      formComponent: "text-field",
      name: "Address",
      label: "Address",
      width: "1/4",
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "Address"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "City",
      label: "City",
      minLength: 3,
      width: "1/4",
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "City"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "State",
      label: "State",
      required: false,
      width: "1/4",
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "State"],
      options: createOptionsForSelect({
        possibleValues: () => Object.values(States).map((x) => x.prefix),
        getOptionLabel: (x) => getStateLabelByStatePrefix(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "zip-code-field",
      name: "ZipCode",
      label: "Zip Code",
      width: "1/4",
      minLength: 5,
      maxLength: 5,
      hideZipCodeSearch: true,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "ZipCode"],
      default: null
    },

    {
      formComponent: "income-field",
      name: "GrossSalary",
      label: "Gross Salary",
      required: true,
      width: "1/4",
      max: 9999999999,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "GrossSalary"],
      default: null
    },
    {
      formComponent: "radio-field",
      name: "GrossSalaryType",
      label: "Gross Salary Type",
      width: "1/4",
      required: true,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "GrossSalaryType"],
      default: null,
      possibleValues: [
        { label: "Annually", value: "ANNUALLY" },
        { label: "Monthly", value: "MONTHLY" }
      ]
    },
    {
      formComponent: "phone-field",
      name: "WorkPhone",
      label: "Work Phone",
      width: "1/4",
      required: true,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "WorkPhone"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "HowLongYears",
      label: "How Long Years?",
      width: "1/4",
      required: true,
      maxLength: 3,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "HowLongYears"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "HowLongMonths",
      label: "How Long Months?",
      width: "1/4",
      required: true,
      maxLength: 3,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 0, "HowLongMonths"],
      default: null
    }
  ]
};

const borrowerPreviousEmploymentInfo: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Previous Employment Info",
  width: "full",
  entities: [
    {
      formComponent: "hidden-field",
      name: "IsCurrent",
      label: "Is Current?",
      width: "1/4",
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 1, "IsCurrent"],
      required: true,
      default: false
    },

    {
      formComponent: "radio-field",
      name: "Status",
      label: "Employment Status",
      width: "full",
      required: true,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 1, "Status"],
      default: "RETIRED",
      possibleValues: [
        { label: "Employed", value: "EMPLOYED" },
        { label: "Self employed", value: "SELFEMPLOYED" },
        { label: "Temp staffing agency", value: "TEMPSTAFFINGAGENCY" },
        { label: "Student", value: "STUDENT" },
        { label: "Retired", value: "RETIRED" },
        { label: "Military", value: "MILITARY" },
        { label: "Homemaker", value: "HOMEMAKER" },
        { label: "Unemployed", value: "UNEMPLOYED" },
        { label: "Unknown", value: "UNKNOWN" }
      ]
    },
    {
      formComponent: "text-field",
      name: "EmployerName",
      minLength: 3,
      label: "Employer Name",
      width: "1/4",
      required: true,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 1, "EmployerName"],
      default: null
    },

    {
      formComponent: "text-field",
      name: "Occupation",
      label: "Occupation",
      width: "1/4",
      required: true,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 1, "Occupation"],
      default: null
    },

    {
      formComponent: "text-field",
      name: "Address",
      label: "Address",
      width: "1/4",
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 1, "Address"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "City",
      label: "City",
      minLength: 3,
      width: "1/4",
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 1, "City"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "State",
      label: "State",
      required: false,
      width: "1/4",
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 1, "State"],
      options: createOptionsForSelect({
        possibleValues: () => Object.values(States).map((x) => x.prefix),
        getOptionLabel: (x) => getStateLabelByStatePrefix(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "zip-code-field",
      name: "ZipCode",
      label: "Zip Code",
      width: "1/4",
      minLength: 5,
      maxLength: 5,
      hideZipCodeSearch: true,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 1, "ZipCode"],
      default: null
    },

    {
      formComponent: "income-field",
      name: "GrossSalary",
      label: "Gross Salary",
      required: false,
      width: "1/4",
      max: 9999999999,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 1, "GrossSalary"],
      default: null
    },
    {
      formComponent: "radio-field",
      name: "GrossSalaryType",
      label: "Gross Salary Type",
      width: "1/4",
      required: false,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 1, "GrossSalaryType"],
      default: null,
      possibleValues: [
        { label: "Annually", value: "ANNUALLY" },
        { label: "Monthly", value: "MONTHLY" }
      ]
    },
    {
      formComponent: "phone-field",
      name: "WorkPhone",
      label: "Work Phone",
      width: "1/4",
      required: false,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 1, "WorkPhone"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "HowLongYears",
      label: "How Long Years?",
      width: "1/4",
      required: true,
      maxLength: 3,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 1, "HowLongYears"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "HowLongMonths",
      label: "How Long Months?",
      width: "1/4",
      required: true,
      maxLength: 3,
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment", 1, "HowLongMonths"],
      default: null
    }
  ]
};
const borrowerOtherIncome: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Other income",
  width: "full",
  entities: [
    {
      formComponent: "currency-field",
      name: "Amount",
      label: "Amount",
      width: "1/3",
      max: 9999999999,
      path: ["data", "Borrowers", "Borrower", "OtherIncome", "Amount"],
      default: null
    },
    {
      formComponent: "radio-field",
      name: "AmountType",
      label: "Amount Type",
      width: "1/3",
      path: ["data", "Borrowers", "Borrower", "OtherIncome", "AmountType"],
      default: null,
      show: (stateAccess) =>
        stateAccess.get<Deal>(["data", "Borrowers", "Borrower", "OtherIncome", "Amount"]),
      possibleValues: [
        { label: "Annually", value: "ANNUALLY" },
        { label: "Monthly", value: "MONTHLY" }
      ],
      required: true
    },
    {
      formComponent: "radio-field",
      name: "Source",
      label: "Source",
      width: "full",
      show: (stateAccess) =>
        stateAccess.get<Deal>(["data", "Borrowers", "Borrower", "OtherIncome", "Amount"]),
      required: true,
      path: ["data", "Borrowers", "Borrower", "OtherIncome", "Source"],
      default: "",
      possibleValues: [
        { label: "Aid for Dependent Children", value: "Aid for Dependent Children" },
        { label: "Child Support", value: "Child Support" },
        { label: "Cost of Living Allowance (COLA)", value: "Cost of Living Allowance (COLA)" },
        { label: "Public Assistance", value: "Public Assistance" },
        { label: "Disability", value: "Disability" },
        {
          label: "Family Subsistence Supplemental Allowance (FSSA)",
          value: "Family Subsistence Supplemental Allowance (FSSA)"
        },
        { label: "Housing Allowances", value: "Housing Allowances" },
        {
          label: "Military Basic Allowance for Subsistence (BAS)",
          value: "Military Basic Allowance for Subsistence (BAS)"
        },
        { label: "Municipal Bond Interest", value: "Municipal Bond Interest" },
        {
          label: "Other Non-Taxable Sources of Income",
          value: "Other Non-Taxable Sources of Income"
        },
        { label: "Social Security Benefits", value: "Social Security Benefits" },
        { label: "Railroad Pension", value: "Railroad Pension" },
        { label: "Workman's Compensation", value: "Workman's Compensation" },
        { label: "Other", value: "Other" },
        { label: "Unknown", value: "UNKNOWN" }
      ]
    },
    {
      formComponent: "text-field",
      name: "Description",
      show: (stateAccess) =>
        stateAccess.get<Deal>(["data", "Borrowers", "Borrower", "OtherIncome", "Amount"]),
      label: "Description",
      width: "1/3",
      path: ["data", "Borrowers", "Borrower", "OtherIncome", "Description"],
      default: null
    }
  ]
};

const coBorrower: FormComponent<Deal> = {
  formComponent: "segment",
  name: "CoBorrower",
  width: "full",
  entities: [
    {
      formComponent: "hidden-field",
      name: "NumOrder",
      label: "Num Order",
      width: "1/4",
      required: true,
      path: ["data", "Borrowers", "CoBorrower", "NumOrder"],
      default: "2"
    },
    {
      formComponent: "radio-field",
      name: "Type",
      label: "Type",
      width: "full",
      required: true,
      path: ["data", "Borrowers", "CoBorrower", "Type"],
      default: "INDIVIDUAL",
      possibleValues: [
        { label: "INDIVIDUAL", value: "INDIVIDUAL" },
        { label: "BUSINESS", value: "BUSINESS" }
      ]
    },
    {
      formComponent: "segment",
      name: "CoBorrower's business",
      width: "full",
      show: (stateAccess: StateAccess): boolean =>
        stateAccess.get<Deal>(["data", "Borrowers", "CoBorrower", "Type"]) === "BUSINESS",
      entities: [
        {
          formComponent: "text-field",
          name: "BusinessName",
          label: "Business name",
          width: "1/4",
          path: ["data", "Borrowers", "CoBorrower", "BusinessName"],
          minLength: 3,
          required: true,
          default: null
        },
        {
          formComponent: "radio-field",
          name: "BusinessType",
          label: "Business type",
          required: true,
          width: "full",
          path: ["data", "Borrowers", "CoBorrower", "BusinessType"],
          default: "",
          possibleValues: [
            { label: "Corporation", value: "CORPORATION" },
            { label: "LLC", value: "LLC" },
            { label: "Partnership", value: "PARTNERSHIP" },
            { label: "Shell corporation", value: "SHELLCORPORATION" },
            { label: "Sole proprietorship", value: "SOLEPROPRIETORSHIP" },
            { label: "Trust", value: "TRUST" },
            { label: "Other", value: "OTHER" }
          ]
        },

        {
          formComponent: "phone-field",
          name: "BusinessPhone",
          label: "Business Phone",
          width: "1/4",
          required: true,
          path: ["data", "Borrowers", "CoBorrower", "BusinessPhone"],
          default: null
        },

        // {
        //   formComponent: "digits-only-field",
        //   name: "BusinessTaxID",
        //   label: "Business Tax ID",
        //   width: "1/4",
        //   required: true,
        //   path: ["data", "Borrowers", "CoBorrower", "BusinessTaxID"],
        //   default: null
        // },

        {
          formComponent: "digits-only-field",
          name: "BusinessHowLongYears",
          label: "Business How Long Years?",
          required: true,
          width: "1/4",
          maxLength: 3,
          path: ["data", "Borrowers", "CoBorrower", "BusinessHowLongYears"],
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Applicant",
      width: "full",
      show: (stateAccess: StateAccess): boolean =>
        stateAccess.get<Deal>(["data", "Borrowers", "CoBorrower", "Type"]) === "INDIVIDUAL",
      entities: [
        {
          formComponent: "text-field",
          name: "FirstName",
          label: "First name",
          width: "1/6",
          path: ["data", "Borrowers", "CoBorrower", "FirstName"],
          required: true,
          default: null
        },
        {
          formComponent: "text-field",
          name: "MiddleName",
          label: "Middle name",
          width: "1/6",
          path: ["data", "Borrowers", "CoBorrower", "MiddleName"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "LastName",
          label: "Last name",
          width: "1/6",
          path: ["data", "Borrowers", "CoBorrower", "LastName"],
          required: true,
          default: null
        },
        {
          formComponent: "select-field",
          name: "Suffix",
          label: "Suffix",
          width: "1/6",
          path: ["data", "Borrowers", "CoBorrower", "Suffix"],
          options: createOptionsForSelect({
            possibleValues: () => ["", "SR", "JR", "I", "II", "III", "IV", "V"],
            getOptionLabel: (x) => x,
            getSelectedOption: (x, y) => x === y
          }),
          default: ""
        },
        {
          formComponent: "ssn-field",
          name: "SSN",
          label: "SSN",
          width: "1/6",
          path: ["data", "Borrowers", "CoBorrower", "SSN"],
          required: true,
          default: null
        },
        {
          formComponent: "date-field",
          name: "DOB",
          label: "Date of birth",
          width: "1/6",
          path: ["data", "Borrowers", "CoBorrower", "DOB"],
          required: true,
          default: null
        },
        {
          formComponent: "phone-field",
          name: "HomePhone",
          label: "Home Phone",
          required: true,
          width: "1/4",
          path: ["data", "Borrowers", "CoBorrower", "HomePhone"],
          default: null
        },
        {
          formComponent: "phone-field",
          name: "MobilePhone",
          label: "Mobile phone",
          width: "1/4",
          path: ["data", "Borrowers", "CoBorrower", "MobilePhone"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "DLNo",
          label: "Driver License Number",
          width: "1/4",
          path: ["data", "Borrowers", "CoBorrower", "DLNo"],
          default: null
        },
        {
          formComponent: "select-field",
          name: "Driver License State",
          label: "Driver License State",
          width: "1/4",
          path: ["data", "Borrowers", "CoBorrower", "DLState"],
          options: createOptionsForSelect({
            possibleValues: () => Object.values(States).map((x) => x.prefix),
            getOptionLabel: (x) => getStateLabelByStatePrefix(x),
            getSelectedOption: (x, y) => x === y
          }),
          default: null
        },
        {
          formComponent: "email-field",
          name: "Email",
          label: "Email",
          width: "1/4",
          path: ["data", "Borrowers", "CoBorrower", "Email"],
          default: null
        }
      ]
    },
    currentAddressStruct("CoBorrower"),
    mailingAddressStruct("CoBorrower"),
    previousAddressStruct("CoBorrower")
  ]
};
const coBorrowerOtherIncome: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Other income",
  width: "full",
  entities: [
    {
      formComponent: "currency-field",
      name: "Amount",
      label: "Amount",
      width: "1/3",
      max: 9999999999,
      path: ["data", "Borrowers", "CoBorrower", "OtherIncome", "Amount"],
      default: null
    },
    {
      formComponent: "radio-field",
      name: "AmountType",
      label: "Amount Type",
      width: "1/3",
      path: ["data", "Borrowers", "CoBorrower", "OtherIncome", "AmountType"],
      default: null,
      show: (stateAccess) =>
        stateAccess.get<Deal>(["data", "Borrowers", "CoBorrower", "OtherIncome", "Amount"]),
      possibleValues: [
        { label: "Annually", value: "ANNUALLY" },
        { label: "Monthly", value: "MONTHLY" }
      ],
      required: true
    },
    {
      formComponent: "radio-field",
      name: "Source",
      label: "Source",
      width: "full",
      show: (stateAccess) =>
        stateAccess.get<Deal>(["data", "Borrowers", "CoBorrower", "OtherIncome", "Amount"]),
      required: true,
      path: ["data", "Borrowers", "CoBorrower", "OtherIncome", "Source"],
      default: "",
      possibleValues: [
        { label: "Aid for Dependent Children", value: "Aid for Dependent Children" },
        { label: "Child Support", value: "Child Support" },
        { label: "Cost of Living Allowance (COLA)", value: "Cost of Living Allowance (COLA)" },
        { label: "Public Assistance", value: "Public Assistance" },
        { label: "Disability", value: "Disability" },
        {
          label: "Family Subsistence Supplemental Allowance (FSSA)",
          value: "Family Subsistence Supplemental Allowance (FSSA)"
        },
        { label: "Housing Allowances", value: "Housing Allowances" },
        {
          label: "Military Basic Allowance for Subsistence (BAS)",
          value: "Military Basic Allowance for Subsistence (BAS)"
        },
        { label: "Municipal Bond Interest", value: "Municipal Bond Interest" },
        {
          label: "Other Non-Taxable Sources of Income",
          value: "Other Non-Taxable Sources of Income"
        },
        { label: "Social Security Benefits", value: "Social Security Benefits" },
        { label: "Railroad Pension", value: "Railroad Pension" },
        { label: "Workman's Compensation", value: "Workman's Compensation" },
        { label: "Other", value: "Other" },
        { label: "Unknown", value: "UNKNOWN" }
      ]
    },
    {
      formComponent: "text-field",
      name: "Description",
      show: (stateAccess) =>
        stateAccess.get<Deal>(["data", "Borrowers", "CoBorrower", "OtherIncome", "Amount"]),
      label: "Description",
      width: "1/3",
      path: ["data", "Borrowers", "CoBorrower", "OtherIncome", "Description"],
      default: null
    }
  ]
};
const coBorrowerEmploymentInfo: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Employment Info",
  width: "full",
  entities: [
    {
      formComponent: "checkbox-field",
      name: "IsCurrent",
      label: "Is Current?",
      width: "1/4",
      valueType: "boolean",
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "IsCurrent"],
      isDisabled: (): boolean => false,
      isChecked: (stateAccess: StateAccess): boolean =>
        stateAccess.get<Deal>([
          "data",
          "Borrowers",
          "CoBorrower",

          "EmploymentInfo",
          "Employment",
          0,
          "IsCurrent"
        ]),
      toggle: (stateAccess: StateAccess): Deal =>
        stateAccess.set<Deal>(
          ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "IsCurrent"],
          !stateAccess.get<Deal>([
            "data",
            "Borrowers",
            "CoBorrower",

            "EmploymentInfo",
            "Employment",
            0,
            "IsCurrent"
          ])
        ),
      required: true,
      default: true
    },
    {
      formComponent: "radio-field",
      name: "Status",
      label: "Employment Status",
      width: "full",
      required: true,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "Status"],
      default: "EMPLOYED",
      possibleValues: [
        { label: "Employed", value: "EMPLOYED" },
        { label: "Self employed", value: "SELFEMPLOYED" },
        { label: "Temp staffing agency", value: "TEMPSTAFFINGAGENCY" },
        { label: "Student", value: "STUDENT" },
        { label: "Retired", value: "RETIRED" },
        { label: "Military", value: "MILITARY" },
        { label: "Homemaker", value: "HOMEMAKER" },
        { label: "Unemployed", value: "UNEMPLOYED" },
        { label: "Unknown", value: "UNKNOWN" }
      ]
    },
    {
      formComponent: "text-field",
      name: "EmployerName",
      minLength: 3,
      label: "Employer Name",
      width: "1/4",
      required: true,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "EmployerName"],
      default: null
    },

    {
      formComponent: "text-field",
      name: "Occupation",
      label: "Occupation",
      width: "1/4",
      required: true,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "Occupation"],
      default: null
    },

    {
      formComponent: "text-field",
      name: "Address",
      label: "Address",
      width: "1/4",
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "Address"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "City",
      label: "City",
      minLength: 3,
      width: "1/4",
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "City"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "State",
      label: "State",
      required: false,
      width: "1/4",
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "State"],
      options: createOptionsForSelect({
        possibleValues: () => Object.values(States).map((x) => x.prefix),
        getOptionLabel: (x) => getStateLabelByStatePrefix(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "zip-code-field",
      name: "ZipCode",
      label: "Zip Code",
      width: "1/4",
      minLength: 5,
      maxLength: 5,
      hideZipCodeSearch: true,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "ZipCode"],
      default: null
    },

    {
      formComponent: "income-field",
      name: "GrossSalary",
      label: "Gross Salary",
      required: true,
      width: "1/4",
      max: 9999999999,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "GrossSalary"],
      default: null
    },
    {
      formComponent: "radio-field",
      name: "GrossSalaryType",
      label: "Gross Salary Type",
      width: "1/4",
      required: true,
      path: [
        "data",
        "Borrowers",
        "CoBorrower",
        "EmploymentInfo",
        "Employment",
        0,
        "GrossSalaryType"
      ],
      default: null,
      possibleValues: [
        { label: "Annually", value: "ANNUALLY" },
        { label: "Monthly", value: "MONTHLY" }
      ]
    },
    {
      formComponent: "phone-field",
      name: "WorkPhone",
      label: "Work Phone",
      width: "1/4",
      required: true,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "WorkPhone"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "HowLongYears",
      label: "How Long Years?",
      width: "1/4",
      required: true,
      maxLength: 3,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "HowLongYears"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "HowLongMonths",
      label: "How Long Months?",
      width: "1/4",
      required: true,
      maxLength: 3,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 0, "HowLongMonths"],
      default: null
    }
  ]
};
const coBorrowerPreviousEmploymentInfo: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Previous Employment Info",
  width: "full",
  entities: [
    {
      formComponent: "hidden-field",
      name: "IsCurrent",
      label: "Is Current?",
      width: "1/4",
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 1, "IsCurrent"],
      required: true,
      default: false
    },

    {
      formComponent: "radio-field",
      name: "Status",
      label: "Employment Status",
      width: "full",
      required: true,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 1, "Status"],
      default: "EMPLOYED",
      possibleValues: [
        { label: "Employed", value: "EMPLOYED" },
        { label: "Self employed", value: "SELFEMPLOYED" },
        { label: "Temp staffing agency", value: "TEMPSTAFFINGAGENCY" },
        { label: "Student", value: "STUDENT" },
        { label: "Retired", value: "RETIRED" },
        { label: "Military", value: "MILITARY" },
        { label: "Homemaker", value: "HOMEMAKER" },
        { label: "Unemployed", value: "UNEMPLOYED" },
        { label: "Unknown", value: "UNKNOWN" }
      ]
    },
    {
      formComponent: "text-field",
      name: "EmployerName",
      minLength: 3,
      label: "Employer Name",
      width: "1/4",
      required: true,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 1, "EmployerName"],
      default: null
    },

    {
      formComponent: "text-field",
      name: "Occupation",
      label: "Occupation",
      width: "1/4",
      required: true,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 1, "Occupation"],
      default: null
    },

    {
      formComponent: "text-field",
      name: "Address",
      label: "Address",
      width: "1/4",
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 1, "Address"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "City",
      label: "City",
      minLength: 3,
      width: "1/4",
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 1, "City"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "State",
      label: "State",
      required: false,
      width: "1/4",
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 1, "State"],
      options: createOptionsForSelect({
        possibleValues: () => Object.values(States).map((x) => x.prefix),
        getOptionLabel: (x) => getStateLabelByStatePrefix(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "zip-code-field",
      name: "ZipCode",
      label: "Zip Code",
      width: "1/4",
      minLength: 5,
      maxLength: 5,
      hideZipCodeSearch: true,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 1, "ZipCode"],
      default: null
    },

    {
      formComponent: "income-field",
      name: "GrossSalary",
      label: "Gross Salary",
      required: false,
      width: "1/4",
      max: 9999999999,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 1, "GrossSalary"],
      default: null
    },
    {
      formComponent: "radio-field",
      name: "GrossSalaryType",
      label: "Gross Salary Type",
      width: "1/4",
      required: false,
      path: [
        "data",
        "Borrowers",
        "CoBorrower",
        "EmploymentInfo",
        "Employment",
        1,
        "GrossSalaryType"
      ],
      default: null,
      possibleValues: [
        { label: "Annually", value: "ANNUALLY" },
        { label: "Monthly", value: "MONTHLY" }
      ]
    },
    {
      formComponent: "phone-field",
      name: "WorkPhone",
      label: "Work Phone",
      width: "1/4",
      required: false,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 1, "WorkPhone"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "HowLongYears",
      label: "How Long Years?",
      width: "1/4",
      required: false,
      maxLength: 3,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 1, "HowLongYears"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "HowLongMonths",
      label: "How Long Months?",
      width: "1/4",
      required: false,
      maxLength: 3,
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment", 1, "HowLongMonths"],
      default: null
    }
  ]
};

const structure: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      name: "Structure",
      width: "full",
      entities: [
        {
          formComponent: "currency-field",
          name: "SellingPrice",
          label: "Selling Price",
          width: "1/4",
          required: true,
          path: ["data", "Structure", "SellingPrice"],
          default: 0
        },
        {
          formComponent: "currency-field",
          name: "TradeInAllowance",
          label: "Trade In Allowance",
          width: "1/4",
          required: true,
          path: ["data", "Structure", "TradeInAllowance"],
          default: 0
        },
        {
          formComponent: "currency-field",
          name: "TradeInPayoff",
          label: "Trade In Payoff",
          required: true,
          path: ["data", "Structure", "TradeInPayoff"],
          default: 0
        },
        {
          formComponent: "currency-field",
          name: "Rebate",
          label: "Rebate",
          width: "1/4",
          required: false,
          path: ["data", "Structure", "Rebate"],
          default: 0
        },
        {
          formComponent: "currency-field",
          name: "CashDown",
          label: "Cash Down",
          width: "1/4",
          required: true,
          path: ["data", "Structure", "CashDown"],
          default: 0
        },
        {
          formComponent: "currency-field",
          name: "TotalTaxes",
          label: "Total Taxes",
          width: "1/4",
          required: true,
          path: ["data", "Structure", "TotalTaxes"],
          default: 0
        },
        {
          formComponent: "currency-field",
          name: "TotalFees",
          label: "Total Fees",
          width: "1/4",
          required: true,
          path: ["data", "Structure", "TotalFees"],
          default: 0
        },
        {
          formComponent: "currency-field",
          name: "TotalProducts",
          label: "Total Products",
          width: "1/4",
          required: false,
          path: ["data", "Structure", "TotalProducts"],
          default: 0
        },
        {
          formComponent: "currency-field",
          name: "TotalAmountFinanced",
          label: "Total Amount Financed",
          width: "1/4",
          required: true,
          path: ["data", "Structure", "TotalAmountFinanced"],
          default: 0
        },
        {
          formComponent: "digits-only-field",
          name: "Term",
          label: "Term",
          width: "1/4",
          required: true,
          maxLength: 5,
          path: ["data", "Structure", "Term"],
          default: 0
        },
        {
          formComponent: "digits-only-field",
          name: "Rate",
          label: "Rate",
          width: "1/4",
          required: true,
          path: ["data", "Structure", "Rate"],
          default: 0
        },
        {
          formComponent: "digits-only-field",
          name: "DaysToFirstPayment",
          label: "Days To First Payment",
          width: "1/4",
          required: true,
          maxLength: 5,
          path: ["data", "Structure", "DaysToFirstPayment"],
          default: 0
        },
        {
          formComponent: "segment",
          name: "Fees",
          width: "full",
          entities: [
            {
              formComponent: "currency-field",
              name: "Amount",
              label: "Documentation Fee Amount",
              width: "1/4",
              required: true,
              path: ["data", "Structure", "Fees", "Fee", 3, "Amount"],
              default: null
            },
            {
              formComponent: "checkbox-field",
              name: "IsTaxed",
              label: " Is Taxed?",
              required: true,
              width: "3/4",
              valueType: "boolean",
              path: ["data", "Structure", "Fees", "Fee", 3, "IsTaxed"],
              isDisabled: () => false,
              isChecked: (stateAccess: StateAccess) =>
                stateAccess
                  .get<Deal>(["data", "Structure", "Fees", "Fee", 3, "IsTaxed"])
                  ?.toString() === "true"
                  ? true
                  : false,
              toggle: (stateAccess: StateAccess) =>
                stateAccess.set<Deal>(
                  ["data", "Structure", "Fees", "Fee", 3, "IsTaxed"],
                  !stateAccess.get<Deal>(["data", "Structure", "Fees", "Fee", 3, "IsTaxed"])
                ),
              default: false
            },
            {
              formComponent: "currency-field",
              name: "Amount",
              label: "Title Fee Amount",
              width: "1/4",
              required: true,
              path: ["data", "Structure", "Fees", "Fee", 0, "Amount"],
              default: null
            },
            {
              formComponent: "checkbox-field",
              name: "IsTaxed",
              label: "Is Taxed?",
              required: true,
              width: "3/4",
              valueType: "boolean",
              path: ["data", "Structure", "Fees", "Fee", 0, "IsTaxed"],
              isDisabled: () => false,
              isChecked: (stateAccess) =>
                stateAccess
                  .get<Deal>(["data", "Structure", "Fees", "Fee", 0, "IsTaxed"])
                  ?.toString() === "true"
                  ? true
                  : false,
              toggle: (stateAccess: StateAccess) =>
                stateAccess.set<Deal>(
                  ["data", "Structure", "Fees", "Fee", 0, "IsTaxed"],
                  !stateAccess.get<Deal>(["data", "Structure", "Fees", "Fee", 0, "IsTaxed"])
                ),
              default: false
            }
          ]
        }
      ]
    }
  ]
};

const collateralTypes = {
  MARINE: "Marine",
  RV: "RV"
} as const;

type CollateralTypeKeys = keyof typeof collateralTypes;

type CollateralTypeValues = typeof collateralTypes[CollateralTypeKeys];

const fuelTypes = {
  GAS: "Gas",
  DIESEL: "Diesel",
  NotApplicable: "N/A"
} as const;

type FuelTypeKeys = keyof typeof fuelTypes;

type FuelTypeValues = typeof fuelTypes[FuelTypeKeys];

export const RVClasses = {
  CLASSA: "CLASSA",
  CLASSB: "CLASSB",
  CLASSC: "CLASSC",
  TRAVELTRAILER: "TRAVELTRAILER",
  FIFTHWHEEL: "FIFTHWHEEL",
  TENTCAMPER: "TENTCAMPER",
  PARKMODEL: "PARKMODEL",
  TRUCKCAMPER: "TRUCKCAMPER",
  OTHER: "OTHER",
  UNKNOWN: "UNKNOWN"
} as const;

type RVClassesKeys = keyof typeof RVClasses;

export type RVClassesValues = typeof RVClasses[RVClassesKeys];

const motor: FormComponent<Motor> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "hidden-field",
      name: "NumOrder",
      label: "Num Order",
      width: "hidden",
      required: true,
      path: ["NumOrder"],
      default: "1"
    },
    {
      formComponent: "radio-field",
      name: "Motor age",
      label: "Motor Age",
      width: "1/4",
      required: true,
      path: ["Age"],
      default: "NEW",
      possibleValues: [
        { label: "New", value: "NEW" },
        { label: "Used", value: "USED" },
        { label: "Demo", value: "DEMO" }
      ]
    },
    {
      formComponent: "text-field",
      name: "Serial",
      label: "Serial",
      width: "1/4",
      required: false,
      path: ["Serial"],
      default: null
    },
    {
      formComponent: "select-field",
      options: getYearsForSelectField,
      name: "year",
      label: "Year",
      width: "1/4",
      required: true,
      path: ["Year"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "Make",
      label: "Make",
      width: "1/4",
      required: true,
      path: ["Make"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "Model",
      label: "Model",
      width: "1/4",
      required: true,
      path: ["Model"],
      default: null
    }
  ]
};
const trailer: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Trailer",
  width: "full",
  entities: [
    {
      formComponent: "radio-field",
      name: "TrailerAge",
      label: "Trailer Age",
      width: "1/3",
      required: true,
      path: ["data", "Collateral", "Trailer", "Age"],
      default: null,
      possibleValues: [
        { label: "New", value: "NEW" },
        { label: "Used", value: "USED" },
        { label: "Demo", value: "DEMO" }
      ]
    },
    {
      formComponent: "text-field",
      name: "Serial",
      label: "Serial",
      width: "2/3",
      required: false,
      path: ["data", "Collateral", "Trailer", "Serial"],
      default: null
    },
    {
      formComponent: "year-field",
      name: "year",
      label: "Year",
      width: "1/3",
      required: true,
      path: ["data", "Collateral", "Trailer", "Year"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "Make",
      label: "Make",
      width: "1/3",
      required: true,
      path: ["data", "Collateral", "Trailer", "Make"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "Model",
      label: "Model",
      width: "1/3",
      required: true,
      path: ["data", "Collateral", "Trailer", "Model"],
      default: null
    }
  ]
};
const collateral: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Collateral",
  width: "full",
  entities: [
    {
      formComponent: "select-field",
      name: "Type",
      label: "Collateral Type",
      width: "full",
      required: true,
      path: ["data", "Collateral", "Type"],
      default: null,
      options: createOptionsForSelect({
        possibleValues: () => Object.keys(collateralTypes),
        getOptionLabel: (x): CollateralTypeValues | "" =>
          Object.keys(collateralTypes).includes(x) ? collateralTypes[x as CollateralTypeKeys] : "",
        getSelectedOption: (x, y) => x === y
      })
    },

    {
      formComponent: "radio-field",
      name: "CollateralAge",
      label: "Collateral Age",
      width: "1/4",
      required: true,
      path: ["data", "Collateral", "Age"],
      default: "NEW",
      possibleValues: [
        { label: "New", value: "NEW" },
        { label: "Used", value: "USED" },
        { label: "Demo", value: "DEMO" }
      ]
    },

    {
      formComponent: "text-field",
      name: "Serial",
      label: "Serial",
      width: "1/4",
      required: false,
      path: ["data", "Collateral", "Serial"],
      default: null
    },
    {
      formComponent: "year-field",
      name: "year",
      label: "Year",
      width: "1/2",
      required: true,
      path: ["data", "Collateral", "Year"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "Make",
      label: "Make",
      width: "1/4",
      required: true,
      path: ["data", "Collateral", "Make"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "Model",
      label: "Model",
      width: "1/4",
      required: true,
      path: ["data", "Collateral", "Model"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "Style",
      label: "Style",
      width: "1/4",
      required: false,
      show: (stateAccess) => stateAccess.get<Deal>(["data", "Collateral", "Type"]) === "RV",
      path: ["data", "Collateral", "Style"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "Color",
      label: "Color",
      width: "1/4",
      required: false,
      path: ["data", "Collateral", "Color"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "Mileage",
      label: "Mileage",
      width: "1/4",
      required: false,
      max: 9999999999,
      path: ["data", "Collateral", "Mileage"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "FuelType",
      label: "Fuel Type",
      width: "1/4",
      required: true,
      path: ["data", "Collateral", "FuelType"],
      default: "NotApplicable",
      options: createOptionsForSelect({
        possibleValues: () => Object.keys(fuelTypes),
        getOptionLabel: (x): FuelTypeValues | "" =>
          Object.keys(fuelTypes).includes(x) ? fuelTypes[x as FuelTypeKeys] : "",
        getSelectedOption: (x, y) => x === y
      })
    },
    {
      formComponent: "select-field",
      name: "RVClass",
      label: "RVClass",
      width: "1/4",
      required: true,
      show: (stateAccess) => stateAccess.get<Deal>(["data", "Collateral", "Type"]) === "RV",
      path: ["data", "Collateral", "RVClass"],
      default: null,
      options: createOptionsForSelect({
        possibleValues: () => Object.keys(RVClasses),
        getOptionLabel: (x): RVClassesValues | "" =>
          Object.keys(RVClasses).includes(x) ? RVClasses[x as RVClassesKeys] : "",
        getSelectedOption: (x, y) => x === y
      })
    },
    {
      formComponent: "currency-field",
      name: "MSRP",
      label: "MSRP",
      width: "1/4",
      required: false,
      path: ["data", "Collateral", "MSRP"],
      default: 0
    },
    {
      formComponent: "currency-field",
      name: "CostPrice",
      label: "Invoice",
      width: "1/4",
      required: false,
      path: ["data", "Collateral", "CostPrice"],
      default: 0
    },
    {
      formComponent: "currency-field",
      name: "SellingPrice",
      label: "SellingPrice",
      width: "1/4",
      required: false,
      path: ["data", "Collateral", "SellingPrice"],
      default: 0
    },
    {
      formComponent: "checkbox-field",
      name: "IsCPO",
      label: "Is CPO?",
      width: "1/4",
      valueType: "boolean",
      path: ["data", "Collateral", "IsCPO"],
      isDisabled: (): boolean => false,
      isChecked: (stateAccess: StateAccess): boolean =>
        stateAccess.get<Deal>(["data", "Collateral", "IsCPO"]),
      toggle: (stateAccess: StateAccess): Deal =>
        stateAccess.set<Deal>(
          ["data", "Collateral", "IsCPO"],
          !stateAccess.get<Deal>(["data", "Collateral", "IsCPO"])
        ),
      required: true,
      default: false
    },
    {
      formComponent: "list-model",
      width: "full",
      name: "Motor",
      show: (stateAccess) => stateAccess.get<Deal>(["data", "Collateral", "Type"]) === "MARINE",
      path: ["data", "Collateral", "Motors", "Motor"],
      entity: motor
    },

    {
      formComponent: "checkbox-field",
      name: "hasTrailer",
      label: "Has trailer?",
      width: "1/3",
      show: (stateAccess) => stateAccess.get<Deal>(["data", "Collateral", "Type"]) === "MARINE",
      toggle: (stateAccess: StateAccess) =>
        stateAccess.set<Deal>(
          ["data", "Collateral", "hasTrailer"],
          !stateAccess.get<Deal>(["data", "Collateral", "hasTrailer"])
        ),
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<Deal>(["data", "Collateral", "hasTrailer"]),
      path: ["data", "Collateral", "hasTrailer"],
      default: false
    },
    {
      formComponent: "segment",
      width: "full",
      show: (stateAccess) =>
        stateAccess.get<Deal>(["data", "Collateral", "Type"]) === "MARINE" &&
        stateAccess.get<Deal>(["data", "Collateral", "hasTrailer"]),
      entities: [trailer]
    }
  ]
};

const tradeIn: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Trade In",
  width: "full",
  entities: [
    {
      formComponent: "checkbox-field",
      name: "hasTrade",
      label: "Has trade?",
      width: "full",
      toggle: (stateAccess: StateAccess) =>
        stateAccess.set<Deal>(
          ["data", "hasTradeIn"],
          !stateAccess.get<Deal>(["data", "hasTradeIn"])
        ),
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) => stateAccess.get<Deal>(["data", "hasTradeIn"]),
      path: ["data", "hasTradeIn"],
      default: false
    },
    {
      formComponent: "segment",
      width: "full",
      show: (stateAccess) => stateAccess.get<Deal>(["data", "hasTradeIn"]),
      entities: [
        {
          formComponent: "list-model",
          name: "Trade",
          width: "full",
          path: ["data", "TradeInInfo", "TradeIn"],
          entity: {
            formComponent: "segment",
            width: "full",
            entities: [
              {
                formComponent: "hidden-field",
                name: "NumOrder",
                label: "Num Order",
                width: "1/4",
                required: true,
                path: ["NumOrder"],
                default: "1"
              },

              {
                formComponent: "text-field",
                name: "Serial",
                label: "Serial",
                width: "1/4",
                required: false,
                path: ["Serial"],
                default: null
              },
              {
                formComponent: "year-field",
                name: "year",
                label: "Year",
                width: "1/4",
                required: true,
                path: ["Year"],
                default: null
              },
              {
                formComponent: "text-field",
                name: "Make",
                label: "Make",
                width: "1/4",
                required: true,
                path: ["Make"],
                default: null
              },
              {
                formComponent: "text-field",
                name: "Model",
                label: "Model",
                width: "1/4",
                required: true,
                path: ["Model"],
                default: null
              },
              {
                formComponent: "currency-field",
                name: "TradeInAllowance",
                label: "TradeIn Allowance",
                width: "1/4",
                required: false,
                path: ["TradeInAllowance"],
                default: null
              },
              {
                formComponent: "currency-field",
                name: "TradeInPayoff",
                label: "TradeIn Payoff",
                width: "1/4",
                required: false,
                path: ["TradeInPayoff"],
                default: null
              },
              {
                formComponent: "text-field",
                name: "LienHolder",
                label: "LienHolder",
                width: "1/4",
                required: false,
                path: ["LienHolder"],
                default: null
              }
            ]
          }
        }
      ]
    }
  ]
};

export const dealStruct: Model<Deal> = {
  formComponent: "model",
  schema: "appone_import_application",
  name: "appone_import_application",
  entities: [
    {
      formComponent: "hidden-field",
      name: "",
      label: "",
      width: "1/3",
      required: true,
      path: ["dealId"],
      default: null
    },
    {
      formComponent: "hidden-field",
      name: "",
      label: "",
      width: "2/3",
      required: true,
      path: ["refNumber"],
      default: null
    },
    {
      formComponent: "segment",
      width: "full",
      entities: [
        borrower,
        borrowerEmploymentInfo,
        {
          formComponent: "checkbox-field",
          name: "IncludePreviousEmployment",
          label: "Include previous employment?",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "includePreviousEmployment"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<Deal>([
              "data",
              "Borrowers",
              "Borrower",
              "EmploymentInfo",
              "includePreviousEmployment"
            ]),
          toggle: (stateAccess: StateAccess): Deal =>
            stateAccess.set<Deal>(
              ["data", "Borrowers", "Borrower", "EmploymentInfo", "includePreviousEmployment"],
              !stateAccess.get<Deal>([
                "data",
                "Borrowers",
                "Borrower",
                "EmploymentInfo",
                "includePreviousEmployment"
              ])
            ),
          show: (stateAccess) =>
            (stateAccess.get<Deal>([
              "data",
              "Borrowers",
              "Borrower",
              "EmploymentInfo",
              "Employment",
              0,
              "HowLongYears"
            ]) ?? 0) < 2,
          required: true,
          default: true
        },
        {
          formComponent: "segment",
          width: "full",
          show: (stateAccess) =>
            stateAccess.get<Deal>([
              "data",
              "Borrowers",
              "Borrower",
              "EmploymentInfo",
              "includePreviousEmployment"
            ]) &&
            (stateAccess.get<Deal>([
              "data",
              "Borrowers",
              "Borrower",
              "EmploymentInfo",
              "Employment",
              0,
              "HowLongYears"
            ]) ?? 0) < 2,
          entities: [borrowerPreviousEmploymentInfo]
        },
        borrowerOtherIncome
      ]
    },
    {
      formComponent: "checkbox-field",
      name: "coApplicant",
      label: "Include coBorrower?",
      width: "1/3",

      toggle: (stateAccess: StateAccess) =>
        stateAccess.set<Deal>(
          ["data", "Borrowers", "includeCoBorrower"],
          !stateAccess.get<Deal>(["data", "Borrowers", "includeCoBorrower"])
        ),
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<Deal>(["data", "Borrowers", "includeCoBorrower"]),

      path: ["data", "Borrowers", "includeCoBorrower"],
      default: false
    },
    {
      formComponent: "segment",
      show: (stateAccess) => stateAccess.get<Deal>(["data", "Borrowers", "includeCoBorrower"]),

      entities: [
        coBorrower,
        coBorrowerEmploymentInfo,
        {
          formComponent: "checkbox-field",
          name: "IncludePreviousEmployment",
          label: "Include previous employment?",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "includePreviousEmployment"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<Deal>([
              "data",
              "Borrowers",
              "CoBorrower",
              "EmploymentInfo",
              "includePreviousEmployment"
            ]),
          toggle: (stateAccess: StateAccess): Deal =>
            stateAccess.set<Deal>(
              ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "includePreviousEmployment"],
              !stateAccess.get<Deal>([
                "data",
                "Borrowers",
                "CoBorrower",
                "EmploymentInfo",
                "includePreviousEmployment"
              ])
            ),
          show: (stateAccess) =>
            (stateAccess.get<Deal>([
              "data",
              "Borrowers",
              "CoBorrower",
              "EmploymentInfo",
              "Employment",
              0,
              "HowLongYears"
            ]) ?? 0) < 2,
          required: true,
          default: true
        },
        {
          formComponent: "segment",
          width: "full",
          show: (stateAccess) =>
            stateAccess.get<Deal>([
              "data",
              "Borrowers",
              "CoBorrower",
              "EmploymentInfo",
              "includePreviousEmployment"
            ]) &&
            (stateAccess.get<Deal>([
              "data",
              "Borrowers",
              "CoBorrower",
              "EmploymentInfo",
              "Employment",
              0,
              "HowLongYears"
            ]) ?? 0) < 2,
          entities: [coBorrowerPreviousEmploymentInfo]
        },
        coBorrowerOtherIncome
      ]
    },
    collateral,
    structure,
    tradeIn,
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "one-to-many-field",
          component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
            <SaveDealButton stateAccess={stateAccess} renderSet={renderSet} />
          ),
          name: "",
          width: "full",
          path: null,
          default: {}
        }
      ]
    }
  ]
};
