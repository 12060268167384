import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { UserStatistic, LenderStatistic } from "hooks/useLenderStatistics/types";
import React from "react";
import Big from "big.js";
import { formatNumberAsCurrency } from "utils/functions";
import styles from "../LenderDashboard.module.css";

const calculateTotalAmountFinanced = (statistics: UserStatistic[]) => {
  return statistics.reduce((acc, curr) => curr.totalAmountFinanced + acc, 0);
};

const ManagerTable = ({ data }: { data: LenderStatistic | undefined }) => {
  const statistics2 = Object.values(data?.usersStatistics?.statistics ?? {})
    .map((x) =>
      Object.values(x).reduce(
        (acc: UserStatistic, curr) => ({
          id: curr.id,
          names: curr.names,
          totalCreditScore: new Big(acc.totalCreditScore).add(curr.totalCreditScore).toNumber(),
          totalAmountFinanced: new Big(acc.totalAmountFinanced)
            .add(curr.totalAmountFinanced)
            .toNumber(),
          dealCount: new Big(acc.dealCount).add(curr.dealCount).toNumber()
        }),
        {
          id: "",
          names: "",
          totalCreditScore: 0,
          totalAmountFinanced: 0,
          dealCount: 0
        }
      )
    )
    .sort((a, b) => a.names.localeCompare(b.names));
  const totals = statistics2.reduce(
    (acc, x) => ({
      totalAveragedCreditScore: new Big(acc.totalAveragedCreditScore)
        .add(new Big(x.totalCreditScore).div(x.dealCount || 1).round(2, 1))
        .toNumber(),
      totalAveragedAmountFinanced: new Big(acc.totalAveragedAmountFinanced)
        .add(new Big(x.totalAmountFinanced).div(x.dealCount || 1).round(2, 1))
        .toNumber()
    }),
    { totalAveragedCreditScore: 0, totalAveragedAmountFinanced: 0 }
  );
  return (
    <Paper className={styles.dashboardItem}>
      <Grid container spacing={3} style={{ padding: 15, height: "100%" }}>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", height: "10%" }}>
          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "20px" }}>
            Amount financed by manager
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ height: "90%" }}>
          <TableContainer>
            <Table stickyHeader aria-label="able">
              <TableHead style={{ whiteSpace: "nowrap", textAlign: "center" }}>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Total amount financed</TableCell>
                  <TableCell align="left">Average amount financed</TableCell>
                  <TableCell align="left">Average credit score</TableCell>
                  <TableCell align="left">Lender selected count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statistics2?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ fontWeight: 500, fontSize: 19 }} align="left">
                      {row.names ?? ""}
                    </TableCell>
                    <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                      {formatNumberAsCurrency(row.totalAmountFinanced, "$")}
                    </TableCell>
                    <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                      {formatNumberAsCurrency(
                        new Big(row.totalAmountFinanced)
                          .div(row.dealCount || 1)
                          .round(2, 1)
                          .toNumber(),
                        "$"
                      )}
                    </TableCell>
                    <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                      {new Big(row.totalCreditScore)
                        .div(new Big(row.dealCount || 1))
                        .round(2, 1)
                        .toNumber()}{" "}
                      {/* {new Big(row.avgCreditScore || 0).div(row.dealCount).toNumber()} */}
                    </TableCell>
                    <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                      {row.dealCount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              <TableFooter>
                <TableRow key={"totals"} style={{ background: "#E8E8E8", marginTop: "auto" }}>
                  <TableCell style={{ fontWeight: 500, fontSize: 19 }} align="left">
                    Total
                  </TableCell>

                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    {formatNumberAsCurrency(calculateTotalAmountFinanced(statistics2), "$")}
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    {formatNumberAsCurrency(
                      new Big(totals.totalAveragedAmountFinanced)
                        .div(new Big(statistics2.length || 1))
                        .round(2, 1)
                        .toNumber(),
                      "$"
                    )}
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    {new Big(totals.totalAveragedCreditScore)
                      .div(new Big(statistics2.length || 1))
                      .round(2, 1)
                      .toNumber()}
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    {data?.totalDeals}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ManagerTable;
