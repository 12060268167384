import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useContext } from "react";

import { Button } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import LinkIcon from "@material-ui/icons/Link";
import { StateAccess } from "utils/models/formGenerator";
import formEditContext from "components/Content/FormEditContext";

import { Lender } from "./types";
import { LenderEmailVerificationLink } from "./emailVerificationLinkSlice";

const EmailVerificationLink = ({ stateAccess }: { stateAccess: StateAccess }) => {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const { enqueueSnackbar } = useSnackbar();
  const lender: Lender = stateAccess.get<Lender>([]);

  const { enabled: editMode, edited } = useContext(formEditContext);

  const handleEmailVerificationLink = () => {
    if (!lender?.data?.info?.adminEmail) {
      return alert("Please enter 'Admin email' before generate the link");
    }
    if (edited) {
      return alert("Please save the lender first.");
    }

    dispatch(LenderEmailVerificationLink({ requestId, _id: lender._id }));
  };

  const emailVerificationLinkSlice = useSelector(
    (state: RootState) => state.emailVerificationLenderLinkSlice[requestId]
  );
  const [link, setLink] = useState("");
  useEffect(() => {
    if (emailVerificationLinkSlice?.status === "success") {
      enqueueSnackbar("Email validation link was generated successfully!", { variant: "success" });
      setLink(emailVerificationLinkSlice?.data?.message);
    } else if (emailVerificationLinkSlice?.status === "error") {
      enqueueSnackbar(emailVerificationLinkSlice?.message, { variant: "error" });
    }
  }, [emailVerificationLinkSlice]);

  return (
    <>
      {editMode && (
        <Button
          onClick={handleEmailVerificationLink}
          style={{ width: "100%" }}
          variant="contained"
          component="label"
          color="primary"
          startIcon={<LinkIcon />}
        >
          Email verification link for admin registration
        </Button>
      )}
      {link && (
        <a
          target="_blank"
          rel="noreferrer"
          style={{ margin: "10px 0px", display: "block" }}
          href={link}
        >
          Open Email verification link
        </a>
      )}
    </>
  );
};

export default EmailVerificationLink;
