import { EntityType, PermissionEntityData } from "utils/entitySlice";
import { getByPath } from "utils/models/formGenerator";
import { DataPermissions, DefaultAction, Permissions, PermissionStruct } from "./types";

export const hasPermission = (
  permissions?: DataPermissions<PermissionStruct<PermissionEntityData<EntityType>>>,
  path?: string[]
) => {
  if (!permissions) return true;
  return getByPath(permissions, path);
};
export const havePermission = (
  topic: EntityType,
  action: DefaultAction,
  permissions: Permissions
): boolean => {
  return permissions?.[topic]?.[action].hasPermission || false;
};
