import { AddRequest } from "utils/types";
import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { DealershipTicketNote } from "./types";

interface AddDealershipTicketNoteResponse {
  message: DealershipTicketNote;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddRequest<DealershipTicketNote>,
  AddDealershipTicketNoteResponse
>("AddDealershipTicketNoteResponse", {
  request: "new_dealership_ticket_note"
});

export const addDealershipTicketNoteActions = socketMessageSlice.actions;

export const addDealershipTicketNote = socketAction;

export default socketMessageSlice.reducer;
