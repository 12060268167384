import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../../../firebase/firebase";
import { IconButton, CircularProgress } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { StateAccess } from "utils/models/formGenerator";
import { CustomInsurance, Deal } from "components/Deals/types";

interface Props {
  stateAccess: StateAccess;
  name: string;
  index: number;
  insurance: CustomInsurance;
  _id: string;
  containerRef: React.RefObject<HTMLDivElement>;
}

export default function UploadContract({
  stateAccess,
  index,
  name,
  insurance,
  _id,
  containerRef
}: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [requestId] = useState(uuidv4());
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const handleContractUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadInProgress(true);
    const uploadedFiles = event.target.files ?? [];

    if (uploadedFiles.length !== 0) {
      const file = uploadedFiles[0];
      const firebaseFileName = `${requestId}-${file.name}`;

      const uploadTask = storage.ref(`/files/Deal/${_id}/${firebaseFileName}`).put(file);

      uploadTask.on(
        "state_changed",
        (_snapShot) => {},
        (err) => {
          setUploadInProgress(false);
          enqueueSnackbar(err.message, {
            variant: "error"
          });
        },
        () => {
          const ref = storage.ref(`/files/Deal/${_id}`).child(firebaseFileName);

          ref.getDownloadURL().then((fireBaseUrl) => {
            ref.getMetadata().then((metadata) => {
              setUploadInProgress(false);
              stateAccess.set<Deal>(["data", "info", "aftermarketOptions", "insurances", index], {
                ...insurance,
                url: fireBaseUrl,
                fileName: `Custom insurance contract - ${file.name}`
              });
            });
          });
        }
      );
    }
  };

  return !insurance.url ? (
    <>
      {!uploadInProgress ? (
        <>
          <input
            accept=".pdf"
            id={`${name}-${index}`}
            type="file"
            onChange={handleContractUpload}
            hidden
          />
          <label htmlFor={`${name}-${index}`}>
            <IconButton color="primary" component="span">
              <CloudUploadIcon />
            </IconButton>
          </label>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px"
          }}
        >
          <CircularProgress color="primary" size={19} />
        </div>
      )}
    </>
  ) : (
    <> </>
  );
}
