import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CounterState = {
  [key in string]: number;
};

const initialState: CounterState = {};

const changedCounterSlice = createSlice({
  name: "ChangedCounterSlice",
  initialState,
  reducers: {
    increment(state, action: PayloadAction<string[]>) {
      action.payload.forEach((key) => (state[key] !== undefined ? state[key]++ : (state[key] = 1)));
    }
  }
});

export const { increment } = changedCounterSlice.actions;
export default changedCounterSlice.reducer;
