import { Applicant } from "./types";

export const modifyApplicant = (applicant: Applicant, oldApplicant: Applicant): Applicant => {
  if (
    applicant?.data?.info?.employmentStatus !== oldApplicant?.data?.info?.employmentStatus &&
    applicant?.data?.info?.employmentStatus === "Retired"
  ) {
    return {
      ...applicant,
      data: {
        ...applicant?.data,
        info: {
          ...applicant?.data?.info,
          jobOccupation: "Retired"
        }
      }
    };
  }
  if (
    applicant?.data?.info?.annualIncome !== oldApplicant?.data?.info?.annualIncome &&
    typeof applicant?.data?.info?.annualIncome === "number"
  ) {
    return {
      ...applicant,
      data: {
        ...applicant?.data,
        info: {
          ...applicant?.data?.info,
          monthlyIncome: applicant?.data?.info?.annualIncome / 12
        }
      }
    };
  }
  if (
    applicant?.data?.info?.monthlyIncome !== oldApplicant?.data?.info?.monthlyIncome &&
    typeof applicant?.data?.info?.monthlyIncome === "number"
  ) {
    return {
      ...applicant,
      data: {
        ...applicant?.data,
        info: {
          ...applicant?.data?.info,
          annualIncome: applicant?.data?.info?.monthlyIncome * 12
        }
      }
    };
  }
  if (
    oldApplicant?.data?.info?.propertyOwnership &&
    oldApplicant?.data?.info?.propertyOwnership !== applicant?.data?.info?.propertyOwnership
  ) {
    const oldOwnershipType =
      oldApplicant?.data?.info?.propertyOwnership === "Rent" ? "rentPerMonth" : "mortgagePerMonth";
    const newOwnershipType =
      applicant?.data?.info?.propertyOwnership === "Rent" ? "rentPerMonth" : "mortgagePerMonth";

    return {
      ...applicant,
      data: {
        ...applicant?.data,
        info: {
          ...applicant?.data?.info,
          ...(newOwnershipType !== oldOwnershipType
            ? {
                [newOwnershipType]: applicant?.data?.info?.[oldOwnershipType],
                [oldOwnershipType]: undefined
              }
            : {})
        }
      }
    };
  }

  return applicant;
};
