import { IconButton } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { StateAccess } from "utils/models/formGenerator";
import { Deal } from "components/Deals/types";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { deleteContract, deleteContractActions } from "./deleteContractSlice";
import { RootState } from "app/rootReducer";
import { useSnackbar } from "notistack";
import { ProtectiveInsurance } from "components/Protective/types";
interface Props {
  index: number;
  stateAccess: StateAccess;
}

const handleDelete = (index: number, stateAccess: StateAccess) => {
  const insurances = stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "insurances"]);
  stateAccess.set<Deal>(
    ["data", "info", "aftermarketOptions", "insurances"],
    insurances.filter((el: any, insuranceIndex: number) => index !== insuranceIndex)
  );
};

export const DeleteInsurance = ({ stateAccess, index }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [deleteContractRequestId] = useState(uuidv4());
  const contractVoid = useSelector(
    (state: RootState) => state.deleteRVContract[deleteContractRequestId]
  );

  const VIN = stateAccess.get<Deal>(["data", "info", "vehicle", "VIN"]);
  const insurance: ProtectiveInsurance = stateAccess.get<Deal>([
    "data",
    "info",
    "aftermarketOptions",
    "insurances",
    index
  ]);

  const isSigned = insurance.fundingDocumentId;
  const handleDeleteContract = () => {
    const ContractPrefix = insurance.ContractPrefix;
    const ContractNumber = insurance.ContractNumber && parseInt(insurance.ContractNumber);
    if (ContractPrefix && ContractNumber)
      dispatch(
        deleteContract({
          requestId: deleteContractRequestId,
          data: {
            ContractPrefix,
            ContractNumber,
            VIN: VIN
          }
        })
      );
  };

  useEffect(() => {
    const insurances = stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "insurances"]);
    if (contractVoid !== undefined && contractVoid.status !== undefined) {
      switch (contractVoid?.status) {
        case "success":
          if (contractVoid?.data?.message?.DeleteContractResult?.Success) {
            stateAccess.set<Deal>(
              ["data", "info", "aftermarketOptions", "insurances"],
              insurances.filter((el: any, insuranceIndex: number) => index !== insuranceIndex)
            );
            enqueueSnackbar(`Successfully deleted a contract.`, {
              variant: "success"
            });
          } else {
            const message =
              contractVoid?.data?.message?.DeleteContractResult?.DeleteContractErrors
                .DeleteContractError[0]?.Message;
            enqueueSnackbar(`Unable to delete the contract.API response: ${message}`, {
              variant: "error"
            });
          }
          dispatch({
            type: deleteContractActions.none.type,
            payload: { requestId: deleteContractRequestId }
          });
          break;
        case "waiting":
          break;
        case "error":
          enqueueSnackbar(`Unable to delete the contract.`, {
            variant: "error"
          });
          dispatch({
            type: deleteContractActions.none.type,
            payload: { requestId: deleteContractRequestId }
          });
          break;
      }
    }
  }, [contractVoid, stateAccess, index, enqueueSnackbar, deleteContractRequestId, dispatch]);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <IconButton
        style={{
          color: isSigned ? "#254e70" : "red"
        }}
        onClick={() => (isSigned ? handleDeleteContract() : handleDelete(index, stateAccess))}
      >
        <RemoveCircleIcon />
      </IconButton>
    </div>
  );
};
