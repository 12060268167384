import { ColumnGroup, FormState } from "./types";
import { createSocketMessageSlice } from "components/Middlewares/socketMessageSliceCreator";

export interface EditColumnGroupResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  ColumnGroup,
  EditColumnGroupResponse
>("EditColumnGroup", {
  request: "update_column_group"
});

export const editColumnGroupActions = socketMessageSlice.actions;
export const editColumnGroup = socketAction;
export default socketMessageSlice.reducer;
