import representativeProperties from "../common/representative_properties";
import notes from "../common/notes_schema";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import { Schema } from "ajv";
import info_properties from "./info_properties";

export const lenderAgreement: Schema = {
  type: "object",
  properties: {
    conditions: {
      type: "array",
      nullable: true,
      items: {
        type: "object",
        properties: {
          field: {
            anyOf: [
              { type: "array", nullable: true },
              { type: "string", nullable: true },
            ],
          },
          type: { type: "string" },
          value: { type: "number" },
        },
      },
    },
    type: { type: "string" },
    field: {
      anyOf: [
        { type: "array", nullable: true },
        { type: "string", nullable: true },
      ],
    },
    value: { type: "number" },
    method: { type: "number" },
  },
};

const data: Schema = {
  type: "object",
  required: ["info"],
  properties: {
    info: { type: "object", required: ["name"], properties: info_properties },
    requiredDocumentsIds: {
      type: "array",
      nullable: true,
      items: { type: "string" },
    },
    representatives: {
      type: "array",
      nullable: true,
      items: { type: "object", properties: representativeProperties },
    },
    additionalRequiredDocumentsIds: {
      type: "array",
      nullable: true,
      items: { type: "string", nullable: true },
    },
    agreements: { type: "array", nullable: true, items: lenderAgreement },
    notes: notes,
  },
};
export default {
  new_lender: {
    $id: "newLender",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_lender: {
    $id: "updateLender",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      approved: {
        type: "boolean",
        nullable: true,
      },
      approvedAt: {
        type: "string",
        nullable: true,
      },
      approvedByUserId: {
        type: "string",
        nullable: true,
      },
    },
  },
  delete_lender: {
    $id: "deleteLender",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  recover_lender: {
    $id: "recoverLender",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_lender: {
    $id: "getLender",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_lender_rates: {
    $id: "getLenderRates",
    type: "object",
    required: ["dealId"],
    properties: {
      dealId: { type: "string" },
    },
  },
  all_lenders: {
    $id: "allLenders",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
  lenderAgreement,
};
