import React from "react";
import Paper from "@material-ui/core/Paper";
import ReactApexChart from "react-apexcharts";
import { useMediaQuery } from "@material-ui/core";

import { formatNumberAsCurrency } from "utils/functions";
import { ChargebackStatistic } from "hooks/useChargebackStatistics/types";

interface Props {
  data: ChargebackStatistic;
  chartType: "bar" | "pie";
  title: string;
  currencyFormat?: boolean;
  horizontal?: boolean;
  elevation?: number;
}

export default function ChargebackChart({
  data,
  chartType,
  title,
  currencyFormat,
  horizontal = false,
  elevation = 1
}: Props) {
  const matches = useMediaQuery("(min-width:800px)");

  const categories = Object.keys(data ?? {});

  const items =
    chartType === "bar"
      ? [{ data: Object.values(data).map((x) => x) }]
      : Object.values(data).map((x) => x);

  return (
    <Paper
      elevation={elevation}
      style={
        matches
          ? { height: "255px" }
          : {
              display: "flex",
              width: "100%",
              height: "370px",
              overflowX: "scroll",
              overflowY: "auto",
              position: "relative"
            }
      }
    >
      <div style={matches ? {} : { width: "100%" }}>
        <ReactApexChart
          options={{
            chart: {
              toolbar: {
                show: false
              }
            },
            legend: {
              formatter: (legendName, opts) => {
                const value =
                  chartType === "bar"
                    ? opts.w.config.series[0].data[opts.seriesIndex]
                    : opts.w.config.series[opts.seriesIndex];
                return `${opts.w.config.xaxis.categories[opts.seriesIndex]} - ${
                  currencyFormat ? formatNumberAsCurrency(value, "$") : value
                }`;
              },
              offsetY: matches ? 50 : 0,
              position: matches ? "right" : "bottom"
            },
            plotOptions: {
              bar: { barHeight: "100%", distributed: true, horizontal }
            },
            labels: categories,
            dataLabels: {
              dropShadow: { enabled: false },
              style: {
                colors: ["black"]
              },
              ...(currencyFormat
                ? {
                    formatter: (val, opts) => {
                      return (
                        formatNumberAsCurrency(opts.w.config.series[opts.seriesIndex], "$") ?? ""
                      );
                    }
                  }
                : {
                    formatter: (val, opts) => {
                      return typeof opts.w.config.series[opts.seriesIndex] === "number"
                        ? opts.w.config.series[opts.seriesIndex]
                        : "";
                    }
                  })
            },
            stroke: { width: 1, colors: ["#fff"] },
            xaxis: { categories },
            yaxis: {
              labels: {
                show: true,
                ...(currencyFormat
                  ? {
                      formatter: (val: any) => {
                        return formatNumberAsCurrency(val, "$") ?? "";
                      }
                    }
                  : {})
              }
            },
            title: {
              text: title,
              align: "center",
              style: { fontSize: matches ? "22px" : "16px", fontWeight: 600 }
            }
          }}
          series={items}
          type={chartType}
          height={matches ? 250 : 350}
        />
      </div>
    </Paper>
  );
}
