import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteChargebackRequest {
  _id: string;
}

interface DeleteChargebackResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteChargebackRequest,
  DeleteChargebackResponse
>("DeleteChargeback", {
  request: "delete_chargeback"
});

export const deleteChargebackActions = socketMessageSlice.actions;
export const deleteChargeback = socketAction;
export default socketMessageSlice.reducer;
