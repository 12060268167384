import { useQuery, useMutation } from "@tanstack/react-query";
import { queryClient } from "app/App";
import { getChannels, createChannel, hideChannel } from "Chat/http/channels";
import { MutationError } from "Chat/types";
import { Channel, CreateChannelRequest, MemberChannel } from "Chat/types/channels";
import { HttpQuery } from "Chat/types/http";

const useGetChannels = (email?: string, query?: HttpQuery, enabled = true) => {
  const queryKey = ["channels", query ?? []];

  const { data, isLoading, isSuccess, error } = useQuery<Channel[], MutationError, Channel[]>({
    queryKey: queryKey,
    queryFn: getChannels(email),
    enabled
  });

  return {
    data,
    isSuccess,
    isLoading,
    error
  };
};

export const useCreateChannel = () => {
  const mutation = useMutation<Channel, MutationError, CreateChannelRequest>(createChannel(), {
    onSuccess: () => {}
  });
  return {
    createChannel: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};
export const useHideChannel = () => {
  const mutation = useMutation<MemberChannel, MutationError, Channel>(hideChannel(), {
    onSuccess: (hiddenChannel) => {
      queryClient.setQueriesData<Channel[]>(["channels"], (oldData) => {
        return oldData?.filter((channel) => channel._id !== hiddenChannel.data.channelId);
      });
    }
  });
  return {
    hideChannel: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};

export default useGetChannels;
