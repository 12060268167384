import { Dealership } from "components/Dealerships/types";
import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { Commission, FormState } from "./types";

interface EditUserRequest {
  _id: string;
  data: FormState;
  approved?: boolean | null;
  approvedAt?: string;
  approvedByUserId?: string;
  availableToRolesIds?: string[] | null;
  dealership?: Dealership | null;
}

interface EditUserRequestSend {
  _id: string;
  approved?: boolean | null;
  approvedAt?: string;
  approvedByUserId?: string;
  availableToRolesIds?: string[] | null;
  dealershipId?: string;
  data: Omit<Omit<Omit<FormState, "roles">, "vehicleInsuranceCompany">, "loginAsUser"> & {
    rolesIds: string[];
  };
}

interface EditUserResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditUserRequestSend,
  EditUserResponse
>("EditWfdUser", {
  request: "update_wfd_user"
});

export const editUser = (request: EditUserRequest & Identifiable) => {
  const { roles, loginAsUser, dealership, ...rest } = request.data;
  const { periodCommissions, ...restInfo } = rest.info;
  return socketAction({
    requestId: request.requestId,
    availableToRolesIds: request.availableToRolesIds ?? [],
    _id: request._id,
    approved: request?.approved ?? null,
    approvedAt: request.approvedAt ?? undefined,
    approvedByUserId: request.approvedByUserId ?? undefined,
    data: {
      ...rest,
      loginAsUserId: loginAsUser?._id ?? undefined,
      rolesIds: roles.map((r) => r._id),
      dealershipId: dealership?._id ?? null,
      info: {
        ...restInfo,
        periodCommissions: periodCommissions?.map((periodCommission) => {
          return {
            ...periodCommission,
            commissions: periodCommission?.commissions?.map((commission) => {
              const { dealershipProgram, managerProgram, ...restCommission } = commission;

              return {
                ...restCommission,
                dealershipProgramId: dealershipProgram?._id,
                managerProgramId: managerProgram?._id
              };
            })
          };
        })
      }
    }
  });
};

export const editUserActions = socketMessageSlice.actions;

export default socketMessageSlice.reducer;
