import {
  borrowers,
  collateral,
  insuranceInfo,
  options,
  structure,
  tradeInInfo,
  valuation,
} from "./data_properties";
const dataProperties = {
  Borrowers: borrowers,
  Collateral: collateral,
  Options: options,
  Valuation: valuation,
  Structure: structure,
  hasTradeIn: { type: "boolean", nullable: true },
  TradeInInfo: tradeInInfo,
  InsuranceInfo: insuranceInfo,
};
const dealIdAndRefNumber = {
  dealId: { type: "string", nullable: true },
  refNumber: { type: "string", nullable: true },
};
const data = {
  type: "object",
  nullable: true,
  properties: {
    ...dataProperties,
    params: {
      type: "object",
      nullable: true,
      properties: dataProperties,
    },
    ...dealIdAndRefNumber,
  },
};
export default {
  appone_import_application: {
    $id: "apponeImportApplication",
    type: "object",
    required: ["data"],
    properties: {
      ...dealIdAndRefNumber,
      data: data,
    },
  },
  appone_get_application_data: {
    $id: "apponeGetApplicationData",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      ...dealIdAndRefNumber,
    },
  },
  appone_get_default_products_fees_taxes: {
    $id: "apponeGetDefaultProductsFeesTaxes",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      ...dealIdAndRefNumber,
    },
  },
  appone_get_application_efile: {
    $id: "apponeGetApplicationEfile",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      ...dealIdAndRefNumber,
    },
  },
};
