export default {
  type: "array",
  nullable: true,
  items: {
    type: "object",
    required: ["note"],
    properties: {
      note: { type: "string" }
    },
    additionalProperties: true
  }
};

export const dealNotes = {
  type: "array",
  nullable: true,
  items: {
    type: "object",
    required: ["note"],
    properties: {
      note: { type: "string" },
      type: {
        type: ["string", "null"],
        nullable: true,
        enum: ["default", "funding_held", null],
        errorMessage: {
          enum: `must be one of the following: 
        'default', 'funding_held'`
        }
      }
    },
    additionalProperties: true
  }
};
