import { createListSlice } from "../Middlewares/listSliceCreator";
import { FloorplanXpress } from "./types";

const { ListSlice, getList } = createListSlice<FloorplanXpress>({
  name: "FloorplanXpressList",
  request_topic: "all_floorplanxpress",
  reducers: {}
});

export default ListSlice.reducer;
export const getFloorplanXpressList = getList;
export const listFloorplanXpressActions = ListSlice.actions;
export const removeFloorplanXpressList = ListSlice.actions.removeList;
