import React from "react";
import { createOptionsForSelect } from "utils/models/fields";
import { StateAccess, RenderSet } from "utils/models/formGenerator";
import { CustomInsurance, allInsuranceTypes } from "components/Deals/types";
import { Grid } from "@material-ui/core";

export default ({ stateAccess, renderSet }: { stateAccess: StateAccess; renderSet: RenderSet }) => {
  const provider: CustomInsurance["provider"] = stateAccess.get<CustomInsurance>(["provider"]);
  return (
    <Grid container>
      <Grid item xs={provider === "XtraRide" ? 6 : 12} style={{ paddingRight: "4px" }}>
        {renderSet.selectInputRenderer<CustomInsurance>(
          {
            formComponent: "select-field",
            name: "insuranceType",
            label: "Type",
            width: "1/2",
            path: ["chosenRate", "insuranceType"],
            options: createOptionsForSelect({
              possibleValues: () => [...allInsuranceTypes],
              getOptionLabel: (x) => (x !== undefined ? `${x[0].toUpperCase()}${x.slice(1)}` : ""),
              getSelectedOption: (x, y) => x === y
            }),
            default: ""
          },
          stateAccess,
          [],
          stateAccess,
          renderSet
        )}
      </Grid>
      {provider === "XtraRide" ? (
        <Grid item xs={6} style={{ paddingLeft: "4px" }}>
          {renderSet.currencyInputRenderer<CustomInsurance>(
            {
              name: "Deductible",
              label: "Deductible",
              width: "1/2",
              path: ["chosenRate", "deductible"],
              default: null
            },
            stateAccess,
            renderSet
          )}
        </Grid>
      ) : null}
    </Grid>
  );
};
