import { createListSlice } from "../Middlewares/listSliceCreator";
import { State } from "./types";

const { ListSlice, getList } = createListSlice<State>({
  name: "StateList",
  request_topic: "all_states",
  reducers: {}
});

export default ListSlice.reducer;
export const getStateList = getList;
export const removeStateList = ListSlice.actions.removeList;
export const listStateActions = ListSlice.actions;
