import { AddRequest } from "utils/types";
import { ColumnGroup, FormState } from "./types";
import { createSocketMessageSlice } from "components/Middlewares/socketMessageSliceCreator";

type AddColumnGroupRequest = AddRequest<ColumnGroup>;

export interface AddColumnGroupResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddColumnGroupRequest,
  AddColumnGroupResponse
>("AddColumnGroup", {
  request: "new_column_group"
});

export const addColumnGroupActions = socketMessageSlice.actions;
export const addColumnGroup = socketAction;
export default socketMessageSlice.reducer;
