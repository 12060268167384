import { CustomRegexes } from "../../helpers";

export default {
  applicant: { type: "string", nullable: true },
  createdBy: { type: "string", nullable: true },
  dateSubmitted: {
    type: "string",
    format: "date-time",
    errorMessage: { format: "must be a valid date" },
    nullable: true,
  },
  modifiedBy: { type: "string", nullable: true },
  phoneNumber: {
    type: "string",
    pattern: CustomRegexes.PHONE,
    errorMessage: {
      pattern: "must not contain letters and should not start with 0 or 1",
    },
    nullable: true,
  },
  product: { type: "string", nullable: true },
  decisions: { type: "array", nullable: true },
  decisionDate: {
    type: "string",
    format: "date-time",
    errorMessage: { format: "must be a valid date" },
    nullable: true,
  },
  fileUrl: { type: "string", nullable: true },
};
