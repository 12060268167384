import { permissionNode, PermissionStruct, relationPermissionNode } from "components/Roles/types";
import { LenderNote } from "./types";

export type LenderNotePermissionsStruct = PermissionStruct<LenderNote>;

export const lenderNotePermissionStruct: LenderNotePermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      seen: {
        wfd: permissionNode("Seen by WFD"),
        lender: permissionNode("Seen by Lender")
      },
      creator: permissionNode("Creator"),
      sentToLenderEmail: permissionNode("Sent to Lender Email"),
      sentToLenderEmailDate: permissionNode("Sent to Lender Email Date"),
      note: permissionNode("Note"),
      user: {
        firstName: permissionNode("User First name"),
        lastName: permissionNode("User Last name"),
        email: permissionNode("User Email")
      }
    },
    ticketId: permissionNode("Ticket Id")
  }
};
