import Button from "@material-ui/core/Button";
import React from "react";
import { StateAccess } from "utils/models/formGenerator";
import { Accounting, Deal } from "../types";

const setAccountingInitialState = (deal: Deal): Accounting => {
  return {
    ...deal.data.info.accounting,
    copied: true,
    payment: deal.data.info.payment,
    profit: deal.data.info.profit,
    taxesAndFees: deal.data.info.taxesAndFees,
    warrantyPrice: deal.data.info.aftermarketOptions.totalServiceWarrantySellPrice,
    gapPrice: deal.data.info.aftermarketOptions.totalGAPSellPrice,
    ecoPrice: deal.data.info.aftermarketOptions.totalEcoPrice,
    wheelAndTirePrice: deal.data.info.aftermarketOptions.totalWheelAndTirePrice,
    prePaidMaintenancePrice: deal.data.info.aftermarketOptions.totalPrePaidMaintenancePrice,
    paintAndFabricationPrice: deal.data.info.aftermarketOptions.totalPaintAndFabricationPrice,
    gapCost: deal.data.info.aftermarketOptions.totalGAPDealerCostPrice,
    warrantyCost: deal.data.info.aftermarketOptions.totalServiceWarrantyDealerCostPrice,
    gapRemit: deal.data.info.aftermarketOptions.totalGAPRemitPrice,
    warrantyRemit: deal.data.info.aftermarketOptions.totalServiceWarrantyRemitPrice
  };
};
export default function ({ stateAccess }: { stateAccess: StateAccess }) {
  const deal = stateAccess.get<Deal>([]);
  return (
    <Button
      variant="contained"
      style={{
        backgroundColor: "#000",
        position: "relative",
        zIndex: "3"
      }}
      fullWidth
      disableElevation
      onClick={() => {
        stateAccess.set(["data", "info", "accounting"], setAccountingInitialState(deal));
      }}
    >
      <div style={{ color: "#fff" }}> Copy from profits</div>
    </Button>
  );
}
