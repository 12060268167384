import React from "react";
import { useDispatch } from "react-redux";
import { StateAccess, RenderSet } from "utils/models/formGenerator";
import { createOptionsForSelect, Path } from "utils/models/fields";
import { ListFunction } from "components/Table";

import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { ListState } from "components/Middlewares/listSliceCreator";

import { Deal } from "./types";
import { User } from "components/Users/types";
import { Dealership } from "components/Dealerships/types";
interface Props<T> {
  renderSet: RenderSet;
  stateAccess: StateAccess;
  id: string;
  getFunction: ListFunction;
  removeFunction: ActionCreatorWithPayload<string, string>;
  path: Path<T>;
  name: string;
  label: string;
  query: {};
  options: {};
  entityList: ListState<T>;
  formatLabel: any;
  lockEntity?: () => void;
  getSelectedOption?: (x: any, y: any) => boolean;
  style?: object;
  required?: (stateAccess: StateAccess) => any;
  parentStateAccess:StateAccess
}

export default function Search<T extends Deal | User | Dealership>({
  renderSet,
  stateAccess,
  parentStateAccess,
  id,
  name,
  getFunction,
  removeFunction,
  formatLabel,
  label,
  query,
  options = {},
  entityList,
  path,
  style,
  required,
  lockEntity = () => {},
  getSelectedOption = (x, y) => x._id === y._id
}: Props<T>) {

  const dispatch = useDispatch();
  const handleLoadEntity = () => {
    lockEntity();
    if (!entityList)
      dispatch(
        getFunction(id, {
          query,
          options
        })
      );
    return () => {
      dispatch(removeFunction(id));
    };
  };
  return (
    <div>
      {renderSet.selectInputRenderer(
        {
          formComponent: "select-field",
          name,
          label,
          required:required && required(parentStateAccess),
          path: (path as unknown) as [],
          onOpen: handleLoadEntity,
          options: createOptionsForSelect({
            possibleValues: () => entityList?.entities ?? [],
            getOptionLabel: (entity) => formatLabel(entity),
            getSelectedOption: getSelectedOption
          }),
          default: null,
          style: style,
          valueToString: (entity) => formatLabel(entity) || " "
        },
        stateAccess,
        [],
        stateAccess,
        renderSet
      )}
    </div>
  );
}
