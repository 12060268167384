import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { File, AddFileRequest, AddFileRequestSend } from "./types";

interface AddFileResponse {
  message: File;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddFileRequestSend,
  AddFileResponse
>("AddFileResponse", {
  request: "new_file"
});

export const addFileActions = socketMessageSlice.actions;

export const addFile = (request: AddFileRequest & Identifiable) => {
  const {
    deal,
    contract,
    document_template,
    chargeback,
    lender_ticket,
    prescreen,
    ...rest
  } = request.data;
  return socketAction({
    requestId: request.requestId,
    availableToRolesIds: request.availableToRolesIds ?? [],
    data: {
      ...rest,
      chargebackId: chargeback?._id ?? null,
      prescreenId: prescreen?._id ?? null,
      dealId: deal?._id ?? null,
      lenderTicketId: lender_ticket?._id ?? null,
      contractId: contract?._id ?? null,
      documentTemplateId: document_template?._id ?? null
    }
  });
};
export default socketMessageSlice.reducer;
