import representativeProperties from "../common/representative_properties";
import notes from "../common/notes_schema";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import { Schema } from "ajv";
import info_properties from "./info_properties";

const data: Schema = {
  type: "object",
  required: ["info"],
  properties: {
    info: { type: "object", properties: info_properties },
    dealId: {
      type: "string",
      nullable: true
    },
    oneSpanPackageId: {
      type: "string",
      nullable: true
    }
  }
};
export default {
  resend_esign_email: {
    $id: "resendEsignEmail",
    type: "object",
    properties: {
      packageId: {
        type: "string"
      },
      email: {
        type: "string"
      }
    }
  },
  update_one_span_signing: {
    $id: "updateOneSpanSigning",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      },
      approved: {
        type: "boolean",
        nullable: true
      },
      approvedAt: {
        type: "string",
        nullable: true
      },
      approvedByUserId: {
        type: "string",
        nullable: true
      }
    }
  }
};
