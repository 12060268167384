import React, { useState } from "react";
import { createStyles, Theme, withStyles, WithStyles, makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  Paper,
  ListItemText,
  Dialog,
  IconButton,
  Typography,
  Popover,
  Button
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ReactToPrint from "react-to-print";
import WidgetQRCode from "./WidgetQRCode";
interface Widget {
  label: string;
  code: string;
}

interface Props {
  open: boolean;
  setOpen: (x: boolean) => void;
  dealershipId: string;
  dealershipName: string;
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none"
    },
    paper: {
      padding: theme.spacing(1)
    }
  })
);

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export default function WidgetDialog({ open, setOpen, dealershipId, dealershipName }: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const show = Boolean(anchorEl);
  const qrCodeRef = React.useRef(null);
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleCopyToClipboard = (event: React.MouseEvent<HTMLButtonElement>, code: string) => {
    navigator.clipboard.writeText(code);
    setAnchorEl(event.currentTarget);
  };

  const widgets: Widget[] = [
    {
      label: "Link",
      code: `${process.env.REACT_APP_APPLICATION_FORM_URL}${dealershipId}`
    },
    {
      label: "Button",
      code: `<a
  href="${process.env.REACT_APP_APPLICATION_FORM_URL}${dealershipId}"
  rel="noreferrer"
  target="_blank"
  style="
    font-family: Arial, Helvetica, sans-serif !important;
    background-color: #ffffff !important;
    padding: 8px 20px !important;
    text-decoration: none !important;
    border: 1px solid #303030 !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.45) !important;
    color: #303030 !important;"
>
  Apply for credit
</a>`
    },
    {
      label: "Iframe",
      code: `<iframe
  src="${process.env.REACT_APP_APPLICATION_FORM_URL}${dealershipId}"
  rel="noreferrer"
  title="Credit application form"
  height="800px"
  width="800px"
  style="
    border: none !important;
    margin: 0px !important;
    padding: 0px !important;"
/>`
    }
  ];

  return (
    <div>
      <Dialog onClose={handleDialogClose} aria-labelledby="widget-dialog" open={open} maxWidth="md">
        <DialogTitle id="widget-dialog" onClose={handleDialogClose}>
          Credit application widgets
        </DialogTitle>
        <DialogContent dividers style={{ padding: "5px" }}>
          <List>
            <ListItem style={{ paddingBottom: "2px" }}>
              <Button
                id="eca-open-form"
                variant="contained"
                color="primary"
                href={process.env.REACT_APP_APPLICATION_FORM_URL + dealershipId}
              >
                Open form
              </Button>
            </ListItem>

            {widgets.map((widget: Widget, index: number) => {
              return (
                <div key={index}>
                  <ListItem style={{ width: "120px", paddingBottom: "2px" }}>
                    <ListItemText primary={widget.label} />
                    <IconButton
                      onMouseLeave={handlePopoverClose}
                      aria-label="copy"
                      onClick={(event) => handleCopyToClipboard(event, widget.code)}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </ListItem>
                  <Paper elevation={3} style={{ marginLeft: "15px", marginRight: "15px" }}>
                    <pre style={{ marginTop: "0px", padding: "10px" }}>{widget.code}</pre>
                  </Paper>
                </div>
              );
            })}
            <ListItem>
              <style>
                {`@media print {
                  .qrCode {
                    display: flex !important;
                    flex-direction: column !important;
                    align-items: center !important;
                    align-content: center !important;
                    flex-wrap: wrap !important;
                    text-align: center !important;
                  }
                  .qrCode-dealership-name {
                    margin-top: 2em !important;
                    margin-bottom: 2em !important;
                    display: flex !important;
                    font-weight: bold !important;
                    font-size: 3em !important;
                  }
                }`}
              </style>
              <div>
                <WidgetQRCode
                  dealershipId={dealershipId}
                  dealershipName={dealershipName}
                  ref={qrCodeRef}
                />
                <ReactToPrint
                  trigger={() => (
                    <Button variant="contained" color="primary">
                      Print QR Code
                    </Button>
                  )}
                  content={() => qrCodeRef.current}
                />
              </div>
            </ListItem>
          </List>
          <Popover
            id="mouse-over-popover"
            open={show}
            anchorEl={anchorEl}
            className={classes.popover}
            classes={{
              paper: classes.paper
            }}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography>Copied!</Typography>
          </Popover>
        </DialogContent>
      </Dialog>
    </div>
  );
}
