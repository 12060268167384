import { Deal } from "components/DealerTrack/types";
import { createOptionsForSelect, Model, FormComponent } from "utils/models/fields";
import MarkupTableSelect from "./MarkupTableSelect";
import { contentFormulas } from "./types";
import React from "react";
import CalculatePivotTableData from "./CalculatePivotTableData";
import { MarkupType } from "components/Lenders/types";
import { powerSportsTypes } from "components/Deals/VehiclePreview";

export const pivotTableMenuFields: Model<Deal> = {
  formComponent: "model",
  schema: "update_deal",
  name: "deal",
  entities: [
    {
      formComponent: "currency-field",
      name: "amountFinanced",
      label: "Amount financed",
      width: "1/3",
      path: ["data", "info", "payment", "dealTotal"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "creditScore",
      label: "Credit score",
      path: ["data", "info", "creditBureaus"]
    },
    {
      formComponent: "months-field",
      name: "Term",
      label: "Term",
      width: "1/2",
      path: ["data", "info", "payment", "numberOfPayments"],
      default: null
    },
    {
      formComponent: "percentage-field",
      name: "contractRate",
      label: "Contract Rate",
      width: "1/2",
      path: ["data", "info", "payment", "interestRate"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "No condition",
      label: "No condition",
      value: () => "No condition",
      path: [],
      description: "No condition",
      default: null
    }

    //   vehicle,
    //   priceSegment,
  ]
};

export const pivotTableContent = (type: MarkupType): FormComponent<Deal> => ({
  formComponent: "segment",

  width: "full",
  entities: [
    {
      formComponent: "select-field",
      name: "reserveType",
      label: "Reserve type",
      width: "1/4",
      path: ["content", "reserveMethod"],
      options: createOptionsForSelect({
        possibleValues: () => ["percentage", "fixed", "formula", "markup", "markup foursight"],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y,
        onInputChange: (x, stateAccess) => {
          if (x !== "markup") {
            stateAccess.set(["content", "markupTableId"], undefined);
          }
        }
      }),
      required: true,
      default: null
    },

    {
      formComponent: "one-to-many-field",
      component: (stateAccess, mainStateAccess, renderSet): JSX.Element => (
        <MarkupTableSelect
          stateAccess={stateAccess}
          mainStateAccess={mainStateAccess}
          renderSet={renderSet}
          type={type}
          path={["content", "markupTableId"]}
        />
      ),
      name: "Markup table",
      label: "Markup table",
      width: "1/4",
      path: ["content", "markupTable"],
      show: (stateAccess) => stateAccess.get(["content", "reserveMethod"]) === "markup",
      default: null
    },

    {
      formComponent: "select-field",
      name: "formula",
      label: "Formula",
      width: "1/4",
      path: ["content", "formula"],
      options: createOptionsForSelect({
        possibleValues: () => [...contentFormulas],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      show: (stateAccess) => stateAccess.get(["content", "reserveMethod"]) === "formula",
      required: true,
      default: null
    },

    {
      formComponent: "percentage-field",
      name: "Reserve",
      label: "Reserve paid by lender %",
      width: "1/4",
      path: ["content", "reservePercentage"],
      show: (stateAccess) =>
        ["percentage", "markup foursight"].includes(stateAccess.get(["content", "reserveMethod"])),
      default: null
    },
    {
      formComponent: "currency-field",
      name: "fixedReserve",
      label: "Reserve paid by lender",
      width: "1/4",
      path: ["content", "reserveFixed"],
      show: (stateAccess) => stateAccess.get(["content", "reserveMethod"]) === "fixed",
      default: null,
      required: true
    },

    {
      formComponent: "percentage-field",
      name: "Dealer split",
      label: "Dealer split %",
      width: "1/4",
      path: ["content", "dealerSplitPercentage"],
      show: (stateAccess) =>
        ["percentage", "formula"].includes(stateAccess.get(["content", "reserveMethod"])) &&
        type === "fulltimeF&I",
      default: null
    },
    {
      formComponent: "currency-field",
      name: "Dealer split",
      label: "Dealer split",
      width: "1/4",
      path: ["content", "dealerSplitFixed"],
      show: (stateAccess) => stateAccess.get(["content", "reserveMethod"]) === "fixed",
      default: null
    },
    {
      formComponent: "segment",
      show: (stateAccess) => stateAccess.get(["content", "reserveMethod"]) !== "formula",
      width: "1/4",
      entities: [
        {
          formComponent: "read-only-number-field",
          name: "extraReserve",
          label: "WFD extra reserve",
          width: "1/2",
          path: ["content", "extraReserveFixed"],
          show: (stateAccess) =>
            stateAccess.get(["content", "reserveMethod"]) === "fixed" && type === "fulltimeF&I",
          default: null
        },
        {
          formComponent: "read-only-percentage-field",
          name: "extraReserve",
          label: "WFD Extra reserve %",
          width: "1/2",
          path: ["content", "extraReservePercentage"],
          show: (stateAccess) =>
            stateAccess.get(["content", "reserveMethod"]) === "percentage" &&
            type === "fulltimeF&I",
          default: null
        },
        {
          formComponent: "percentage-field",
          name: "correspondentReserve",
          label: "Correspondent reserve %",
          width: "1/2",
          path: ["content", "correspondentReservePercentage"],
          show: () => type === "correspondent",
          default: null
        },
        {
          formComponent: "currency-field",
          name: "maxReserve",
          label: "Max reserve",
          width: "1/2",
          path: ["content", "maxReserve"],
          default: null
        },
        ...(type === "fulltimeF&I"
          ? [
              {
                formComponent: "one-to-many-field",
                component: (stateAccess, _mainStateAccess, _renderSet): JSX.Element => (
                  <CalculatePivotTableData
                    reserveMethod={stateAccess.get(["content", "reserveMethod"])}
                    stateAccess={stateAccess}
                  />
                ),
                name: "Calculate",
                label: "Calculate",
                width: "hidden",
                path: [],
                default: null
              } as FormComponent<Deal>
            ]
          : [])
      ]
    }
  ]
});

export const pivotMarkupTableMenuFields: Model<Deal> = {
  formComponent: "model",
  schema: "update_deal",
  name: "deal",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (_stateAccess, _mainStateAccess, _renderSet): JSX.Element => <>No condition</>,
      name: "No condition",
      label: "No condition",
      width: "1/3",
      path: [],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "No condition",
      label: "No condition",
      value: () => "No condition",
      path: [],
      description: "No condition",
      default: null
    }
  ]
};

export const pivotMarkupTableContent: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      width: "3/4",
      entities: [
        {
          formComponent: "percentage-field",
          name: "Markup",
          label: "Markup %",
          width: "1/4",
          path: ["content", "markup"],
          default: null
        },
        {
          formComponent: "percentage-field",
          name: "Reserve",
          label: "Reserve paid by lender %",
          width: "1/4",
          path: ["content", "reservePercentage"],
          default: null
        },

        {
          formComponent: "percentage-field",
          name: "Dealer split",
          label: "Dealer split %",
          width: "1/4",
          path: ["content", "dealerSplitPercentage"],
          default: null
        },
        {
          formComponent: "read-only-percentage-field",
          name: "extraReserve",
          label: "WFD Extra reserve %",
          width: "1/4",
          path: ["content", "extraReservePercentage"],
          default: null
        },
        {
          formComponent: "one-to-many-field",
          component: (stateAccess, _mainStateAccess, _renderSet): JSX.Element => (
            <CalculatePivotTableData reserveMethod="percentage" stateAccess={stateAccess} />
          ),
          name: "Calculate",
          label: "Calculate",
          width: "hidden",
          path: [],
          default: null
        }
      ]
    },
    {
      formComponent: "checkbox-field",
      name: "IsDefault",
      label: "Is default?",
      required: true,
      width: "1/4",
      valueType: "boolean",
      path: ["isDefault"],
      isDisabled: () => {
        return false;
      },
      isChecked: (stateAccess) => stateAccess.get(["content", "isDefault"]),

      toggle: (stateAccess) =>
        stateAccess.set(["content", "isDefault"], !stateAccess.get(["content", "isDefault"])),
      default: false
    }
  ]
};

export const pivotMarkupCorrespondentTableContent: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      width: "3/4",
      entities: [
        {
          formComponent: "percentage-field",
          name: "Markup",
          label: "Markup %",
          width: "1/4",
          path: ["content", "markup"],
          default: null
        },
        {
          formComponent: "percentage-field",
          name: "Percentage",
          label: "Percentage",
          width: "1/4",
          path: ["content", "dealerSplitPercentage"],
          default: null
        },

        {
          formComponent: "percentage-field",
          name: "correspondentPercentage",
          label: "Correspondent percentage",
          width: "1/4",
          path: ["content", "reservePercentage"],
          default: null
        },
        {
          formComponent: "one-to-many-field",
          component: (stateAccess, _mainStateAccess, _renderSet): JSX.Element => (
            <CalculatePivotTableData reserveMethod="percentage" stateAccess={stateAccess} />
          ),
          name: "Calculate",
          label: "Calculate",
          width: "hidden",
          path: [],
          default: null
        }
      ]
    },
    {
      formComponent: "checkbox-field",
      name: "IsDefault",
      label: "Is default?",
      required: true,
      width: "1/4",
      valueType: "boolean",
      path: ["isDefault"],
      isDisabled: () => {
        return false;
      },
      isChecked: (stateAccess) => stateAccess.get(["content", "isDefault"]),

      toggle: (stateAccess) =>
        stateAccess.set(["content", "isDefault"], !stateAccess.get(["content", "isDefault"])),
      default: false
    }
  ]
};

export const pivotPowerSportCostTableMenuFields: Model<Deal> = {
  formComponent: "model",
  schema: "update_deal",
  name: "deal",
  entities: [
    {
      formComponent: "percentage-field",
      name: "contractRate",
      label: "Contract Rate",
      width: "1/2",
      path: ["data", "info", "payment", "interestRate"],
      default: null
    },

    {
      formComponent: "select-field",
      name: "unitType",
      label: "Unit type",
      width: "1/4",
      path: ["data", "info", "vehicle", "unitType"],
      //@ts-ignore
      possibleValues: powerSportsTypes,
      options: createOptionsForSelect({
        possibleValues: () => powerSportsTypes,
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      required: true,
      default: null
    },

    {
      formComponent: "number-field",
      name: "term",
      label: "Term",
      width: "1/2",
      path: ["data", "info", "payment", "numberOfPayments"],
      default: null
    },
    {
      formComponent: "one-to-many-field",
      component: (_stateAccess, _mainStateAccess, _renderSet): JSX.Element => <>No condition</>,
      name: "No condition",
      label: "No condition",
      width: "1/3",
      path: [],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "No condition",
      label: "No condition",
      value: () => "No condition",
      path: [],
      description: "No condition",
      default: null
    }
  ]
};
export const pivotAutomotiveCostTableMenuFields: Model<Deal> = {
  formComponent: "model",
  schema: "update_deal",
  name: "deal",
  entities: [
    {
      formComponent: "number-field",
      name: "amountFinanced",
      label: "Amount Financed",
      width: "1/2",
      path: ["data", "info", "payment", "dealTotal"],
      default: null
    },

    {
      formComponent: "number-field",
      name: "term",
      label: "Term",
      width: "1/2",
      path: ["data", "info", "payment", "numberOfPayments"],
      default: null
    },
    {
      formComponent: "one-to-many-field",
      component: (_stateAccess, _mainStateAccess, _renderSet): JSX.Element => <>No condition</>,
      name: "No condition",
      label: "No condition",
      width: "1/3",
      path: [],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "No condition",
      label: "No condition",
      value: () => "No condition",
      path: [],
      description: "No condition",
      default: null
    }
  ]
};
export const pivotTableContentVehicleCost = (): FormComponent<Deal> => ({
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "currency-field",
      name: "Cost",
      label: "Cost",
      width: "full",
      path: ["content", "cost"],
      default: null
    }
  ]
});
