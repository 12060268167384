import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { VehicleInsuranceCompany } from "./types";
type AddVehicleInsuranceCompanyRequest = VehicleInsuranceCompany;

interface AddInsuranceComapanyResponse {
  message: {
    data: VehicleInsuranceCompany;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddVehicleInsuranceCompanyRequest,
  AddInsuranceComapanyResponse
>("AddVehicleInsuranceCompany", {
  request: "new_vehicle_insurance_company"
});

export const addVehicleInsuranceCompanyActions = socketMessageSlice.actions;
export const addVehicleInsuranceCompany = socketAction;
export default socketMessageSlice.reducer;
