import { IconButton } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Cancel from "@material-ui/icons/Cancel";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { RootState } from "app/rootReducer";
import { HintTooltip } from "components/common/HintTooltip";
import { TabContext } from "components/Layout/LayoutWrapper";
import { Props } from "components/Layout/Main";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { entityConfigs, EntityType, getEntity, removeEntity } from "utils/entitySlice";
import { lockEntity } from "utils/models/LockEntity/lockEntitySlice";
import { v4 as uuidv4 } from "uuid";
import CloseTabDialog from "./CloseTabDialog";
import { removeTab, ScreenIds, setTabScreen } from "./tabSlice";
import { Tab } from "./types";

const useStyles = makeStyles(() =>
  createStyles({
    selectedChip: {
      margin: "3px 3px 3px 3px",
      backgroundColor: "#254e70",
      color: "#ffffff",
      "&:hover": { backgroundColor: "#254e70" },
      "&:focus": { backgroundColor: "#254e70" }
    },
    defaultChip: {
      margin: "3px 3px 3px 3px",
      backgroundColor: "#CECECE",
      color: "#000000",
      "&:hover": { backgroundColor: "#CECECE" },
      "&:focus": { backgroundColor: "#CECECE" }
    }
  })
);
export const hasIdAndType = (
  props:
    | undefined
    | {
        type: EntityType;
        _id: string;
      }
    | {
        type: EntityType;
      }
): props is {
  type: EntityType;
  _id: string;
} => props !== undefined && props.hasOwnProperty("_id") && props.hasOwnProperty("type");

export default function TabsWrapper({ setActiveTab, activeTab }: Props) {
  const navigate = useHistory();
  const setActiveTabByScreen = (screenId: ScreenIds, tabId?: string, tabUrl?: string) => {
    setActiveTab({ ...activeTab, [screenId]: tabId });
    if (tabUrl) navigate.push(tabUrl ?? "/");
  };
  const createOrFocusTab = useContext(TabContext);

  const classes = useStyles();
  const dispatch = useDispatch();
  const tabSlice = useSelector((state: RootState) => state.tabSlice);
  const allLocks = useSelector((state: RootState) => state.listLockSlice["all"]);
  const { user } = useSelector((state: RootState) => state.authSlice);
  const [currentTabId, setCurrentTabId] = useState("");
  const [currentTabName, setCurrentTabName] = useState("");
  const [open, setOpen] = useState(false);
  const entitySlice = useSelector((state: RootState) => state.entitySlice);

  useEffect(() => {
    tabSlice.forEach(({ props }) => {
      if (hasIdAndType(props) && !entitySlice[props.type]?.[props._id]) {
        dispatch(getEntity(props.type, props._id));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSlice]);
  const handleOnDelete = (currentTabId: string) => {
    const currentTabIndex = tabSlice.findIndex((el) => el._id === currentTabId);
    const tab = tabSlice.find((el) => el._id === currentTabId);

    if (tabSlice[currentTabIndex - 1]) {
      const prevTab = tabSlice[currentTabIndex - 1];
      createOrFocusTab(prevTab);
    } else {
      const dashboard = tabSlice.find((el) => el.index === "dashboard");
      if (dashboard) createOrFocusTab(dashboard);
    }
    dispatch(removeTab(currentTabId));

    if (tab) {
      const props = tab.props;
      if (hasIdAndType(props)) {
        dispatch(removeEntity(props.type, props._id));

        const lockData = allLocks?.entities?.find(
          (lock) =>
            lock?.data?.info?.entityId === props._id && lock?.data?.info?.entityName === props.type
        );
        if (user?.email === lockData?.data?.info?.userEmail) {
          dispatch(
            lockEntity({
              requestId: uuidv4(),
              data: { info: { entityId: props._id, entityName: props.type, action: "unlock" } }
            })
          );
        }
      }
    }
    setCurrentTabId("");
    setCurrentTabName("");
  };
  const handleDeleteAndClose = (currentTabId: string) => {
    handleOnDelete(currentTabId);
    setOpen(false);
  };

  const handleConfirmBeforeDelete = (currentTabId: string, currentTabName: string) => {
    setOpen(true);
    setCurrentTabId(currentTabId);
    setCurrentTabName(`Warning  ${currentTabName}`);
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // maxWidth: "180px", // remove size limitation for better UX for F&I managers
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = () => ({
    display: "flex",
    overflow: "auto"
  });

  const onDragEnd = (result: DropResult) => {
    if (result.destination === null || result.destination === undefined) {
      return;
    }

    dispatch(
      setTabScreen({
        id: result.draggableId,
        screenId: result.destination.droppableId === "rightScreen" ? "rightScreen" : "leftScreen",
        destinationIndex: result.destination.index
      })
    );
    setActiveTabByScreen(
      result.destination.droppableId === "rightScreen" ? "rightScreen" : "leftScreen",
      result.draggableId
    );
    if (
      result.destination.droppableId === "leftScreen" &&
      tabSlice.filter((el) => el.screenId === "rightScreen").length === 1 &&
      result.source.droppableId !== "leftScreen"
    ) {
      setActiveTabByScreen("rightScreen", undefined);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAllTabs = () => {
    const unsaved = tabSlice.some((tab) => tab.unsavedChanges);

    if (
      unsaved &&
      window.confirm("You have unsaved tabs. Do you want to close them and lose the data?")
    ) {
      navigate.push("/");
      tabSlice.forEach((tab) => tab.index !== "dashboard" && dispatch(removeTab(tab._id)));
    } else if (!unsaved) {
      navigate.push("/");
      tabSlice.forEach((tab) => tab.index !== "dashboard" && dispatch(removeTab(tab._id)));
    }
  };
  return (
    <>
      <CloseTabDialog
        open={open}
        handleClose={handleClose}
        handleActionAndClose={handleDeleteAndClose}
        currentTabId={currentTabId}
        dialogText={"You have unsaved changes. Are you sure you want to close this tab?"}
        dialogTitle={currentTabName}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <div
          style={{
            display: "flex"
          }}
        >
          {(["leftScreen"] as const).map((screenId) => {
            let counter = 0;
            return (
              <Droppable key={screenId} droppableId={screenId} direction="horizontal">
                {(provided, snapshot) => (
                  <Paper
                    id={`tabsWrapper-${screenId}`}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      ...getListStyle(),
                      padding: "5px 10px 5px 10px",
                      margin: "0px 2px",
                      flex: "1",
                      display: "block",
                      position: "relative",
                      overflowX: "hidden"
                    }}
                    elevation={3}
                  >
                    {tabSlice.map((el: Tab) => {
                      const label = hasIdAndType(el.props)
                        ? entitySlice[el.props?.type]?.[el.props?._id]
                          ? entityConfigs[el.props.type]?.showName(
                              entitySlice[el.props?.type]?.[el.props?._id] as any
                            )
                          : "Loading"
                        : el.label;

                      if (screenId !== el.screenId) return null;
                      return (
                        <Draggable key={el._id} draggableId={el._id} index={counter++}>
                          {(provided, snapshot) => (
                            <Chip
                              id={`tab-${el._id}`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              key={el._id}
                              label={label}
                              className={
                                el._id === activeTab[screenId]
                                  ? classes.selectedChip
                                  : classes.defaultChip
                              }
                              size="small"
                              variant="default"
                              onClick={() => {
                                createOrFocusTab(el);
                              }}
                              deleteIcon={
                                <div style={{ fontSize: "17px" }} id={`close-tab-button-${el._id}`}>
                                  {el.unsavedChanges ? (
                                    <FiberManualRecordIcon
                                      style={{ color: "#fff" }}
                                      fontSize="inherit"
                                    />
                                  ) : (
                                    <Cancel fontSize="inherit" />
                                  )}
                                </div>
                              }
                              {...(el.label !== "Dashboard"
                                ? {
                                    onDelete: () =>
                                      el?.unsavedChanges
                                        ? handleConfirmBeforeDelete(el._id, label)
                                        : handleOnDelete(el._id)
                                  }
                                : {})}
                            />
                          )}
                        </Draggable>
                      );
                    })}
                    {tabSlice?.length > 2 && (
                      <HintTooltip title="Click here to close all tabs.">
                        <IconButton
                          id="close-tabs-button"
                          size="small"
                          style={{ position: "absolute", right: "-2px", top: "-2px" }}
                          onClick={handleCloseAllTabs}
                        >
                          <Cancel fontSize="inherit" />
                        </IconButton>
                      </HintTooltip>
                    )}
                    <div style={{ display: "none" }}> {provided.placeholder}</div>
                  </Paper>
                )}
              </Droppable>
            );
          })}
        </div>
      </DragDropContext>
    </>
  );
}
