import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import AccessControl from "components/Access/AccessControl";
import { ApplicationParserType } from "components/Dealerships/types";
import CloseDialogButton from "components/common/CloseDialogButton";
import React, { useEffect } from "react";
import { IoCheckmarkCircle, IoDocumentOutline } from "react-icons/io5";
import { capitalize } from "utils/functions";
import DealDropzone from "./DealDropzone";
import IFrameURLModal from "./Documents/IFrameURLModal";

import { makeStyles } from "@material-ui/core/styles";
import DealershipPreview from "components/Dealerships/DealershipPreview";
import { StateAccess, generateForm, getByPath, setByPath } from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";
import { parsedDealStruct } from "./parsedDealModel";

export interface ParsedDealData {
  applicationParserType: string;
  applicant: Applicant;
  coApplicant: Applicant;
  vehicle: Vehicle;
  price: Price;
}

export interface Applicant {
  firstName: string;
  middleName: string;
  lastName: string;
  mobilePhone: string;
  homePhone: string;
  socialSecurityNumber: string;
  usCitizen: string;
  maritalStatus: any;
  birthDate: string;
  email: string;

  currentAddressNumber: string;
  currentAddress: string;
  currentZipCode: string;
  currentState: string;
  currentStatePrefix: string;
  currentCounty: string;
  currentCity: string;
  currentCountry: string;
  monthsAtCurrentAddress: number;
  yearsAtCurrentAddress: number;

  mortgageOrRent: string;
  propertyOwnership: any;

  mortgagePerMonth: number;
  rentPerMonth: number;

  previousCountry: string;
  previousPropertyOwnership: any;
  callAtWork: boolean;
  previousOccupation: string;
  retirementIncome: number;

  drivingLicenseState: string;
  driverLicenseNumber: string;
  driverLicenseIssued: any;
  driverLicenseExpires: any;

  employer: string;
  jobOccupation: string;

  employmentStatus: string;
  monthlyIncome: number;
  annualIncome: number;
}

export interface Vehicle {
  year: number;
  model: string;
  make: string;
}

export interface Price {
  price: number;
}

export interface Metadata {
  name: string;
  type: string;
  bucket: string;
  generation: string;
  metageneration: string;
  fullPath: string;
  size: string;
  timeCreated: string;
  updated: string;
  md5Hash: string;
  contentDisposition: string;
  contentEncoding: string;
  contentType: string;
}

export interface Price {
  price: number;
}
type Props = {
  setState: React.Dispatch<React.SetStateAction<object>>;
  state: ParsedDealData | undefined;
};

type UploadedFile = { fileUrl: string; metadata: { name: string } };

export default ({ setState, state }: Props) => {
  const [isLearnTextHovered, setIsLearnTextHovered] = React.useState(false);
  const [
    applicationParserType,
    setApplicationParserType
  ] = React.useState<ApplicationParserType | null>(ApplicationParserType.Automatic);
  const [documentForDialog, setDocumentForDialog] = React.useState<UploadedFile | undefined>();
  const [uploadedFile, setUploadedFile] = React.useState<UploadedFile | undefined>();
  const [learnModalOpen, setLearnModalOpen] = React.useState(false);
  const useStyles = makeStyles({
    learnText: {
      color: "#2f6df2",
      cursor: "pointer",
      textDecoration: isLearnTextHovered ? "underline" : "none"
    }
  });

  const classes = useStyles();
  const stateAccess: StateAccess = {
    get: (path) => getByPath(state || {}, path),
    set: (path, value): any => setState(setByPath(state || {}, path, value))
  };
  const keys = [
    ...new Set([...Object.keys(state?.applicant ?? {}), ...Object.keys(state?.coApplicant ?? {})])
  ];
  return (
    <AccessControl
      key={`add-deal-from-document-button`}
      requiredPermissions={{ entity: "deal", action: "create" }}
    >
      <Grid container xs={12} spacing={2} style={{ maxHeight: "none" }}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Typography
              className={classes.learnText}
              style={{ marginBottom: 10 }}
              onClick={() => setLearnModalOpen(true)}
              onMouseEnter={() => setIsLearnTextHovered(true)}
              onMouseLeave={() => setIsLearnTextHovered(false)}
            >
              To learn what are the supported document types, click here.
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              style={{ zIndex: 6 }}
              value={applicationParserType}
              getOptionLabel={(x) => capitalize(x)}
              getOptionSelected={(x, y) => x === y}
              options={Object.values(ApplicationParserType)}
              onChange={(event, newValue) => {
                setApplicationParserType(newValue);
                setState((prev) => ({ ...prev, applicationParserType: newValue }));
              }}
              id="application-parser-type"
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ ...params.InputProps }}
                  label={"Document Type"}
                  variant="filled"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            {
              <DealershipPreview
                stateAccess={stateAccess}
                renderSet={editRenderSet(false)}
                path={["dealership"]}
                name="dealership"
                label="Dealership"
                required={true}
                type="external_credit_application"
              />
            }
            {
              //@ts-ignore
              state?.dealer_name && `Parsed dealer name: ${state?.dealer_name}`
            }
          </Grid>
          <Grid item xs={4}>
            {applicationParserType && (
              <>
                {!uploadedFile && (
                  <DealDropzone
                    applicationParserType={applicationParserType}
                    uploadCallback={(response) => {
                      if (response) {
                        console.log(response);
                        const { uploadedFile, ...rest } = response;
                        setUploadedFile(uploadedFile);

                        setState((prev) => ({
                          ...response,
                          //@ts-ignore
                          ...(prev?.dealership ? { dealership: prev?.dealership } : {})
                        }));
                      }
                    }}
                  />
                )}
                {uploadedFile && (
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                      margin: "5px 0px",
                      border: "2px dashed rgb(102, 102, 102)",
                      borderRadius: "10px",
                      cursor: "pointer"
                    }}
                    onClick={() => setDocumentForDialog(uploadedFile)}
                  >
                    <Grid xs={1}>
                      <IoDocumentOutline size={30} color="#6B97F6" />
                    </Grid>
                    <Grid xs={6}>
                      <Typography>{uploadedFile?.metadata?.name}</Typography>
                    </Grid>
                    <Grid
                      xs={5}
                      alignContent={"center"}
                      alignItems={"center"}
                      style={{ textAlign: "right" }}
                    >
                      <IoCheckmarkCircle
                        size={20}
                        color="green"
                        style={{
                          position: "relative",
                          bottom: -5,
                          marginRight: 10
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: 14,
                          position: "relative",
                          marginRight: 10,
                          color: "green"
                        }}
                      >
                        Successfully parsed
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {documentForDialog && (
                  <IFrameURLModal
                    title={documentForDialog.metadata.name}
                    url={documentForDialog.fileUrl}
                    handleClose={() => setDocumentForDialog(undefined)}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {generateForm(parsedDealStruct, stateAccess, [], stateAccess, editRenderSet(false))}
        </Grid>
        <Grid item xs={6}>
          {uploadedFile && (
            <iframe src={uploadedFile.fileUrl} frameBorder="0" width="100%" height="100%"></iframe>
          )}
        </Grid>

        {learnModalOpen && (
          <Dialog
            id="learn-modal"
            open={true}
            maxWidth="md"
            fullWidth
            onClose={() => setLearnModalOpen(false)}
          >
            <DialogTitle id="alert-dialog-title">
              Supported document types
              <CloseDialogButton closeFunction={() => setLearnModalOpen(false)} />
            </DialogTitle>
            <DialogContent>
              <Grid spacing={2} container xs={12}>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="h5" style={{ padding: "10px" }}>
                    Frazer Credit Application
                  </Typography>
                  <img
                    src="/frazer_example.png"
                    alt=""
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "50%",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px"
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="h5" style={{ padding: "10px" }}>
                    Reynolds Credit Application
                  </Typography>
                  <img
                    src="/raynalds_example.png"
                    alt=""
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "50%",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px"
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="h5" style={{ padding: "10px" }}>
                    Dealertrack Credit Application
                  </Typography>
                  <img
                    src="/dealertrack_example.png"
                    alt=""
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "50%",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px"
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="h5" style={{ padding: "10px" }}>
                    Carforsale Credit Application
                  </Typography>
                  <img
                    src="/carforsale_example.png"
                    alt=""
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "50%",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px"
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="h5" style={{ padding: "10px" }}>
                    Dealercarsearch Credit Application
                  </Typography>
                  <img
                    src="/dealercarsearch_example.png"
                    alt=""
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "50%",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px"
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setLearnModalOpen(false)}
                variant="contained"
                color="primary"
                id="cancel"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Grid>
    </AccessControl>
  );
};
