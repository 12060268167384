import React from "react";
import { PersonaReport } from "../../types/internal/personaReports";
import { EmailRiskReportPayloadAttributes } from "../../types/external/reports/common";
import {
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead
} from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";
import formEditContext from "components/Content/FormEditContext";
import { useSnackbar } from "notistack";
import { Applicant } from "components/Applicants/types";
import { Verification } from "../../types/internal/personaVerifications";
import { isEmailReportValid } from "../../FraudValidationPreview";
import {
  VerificationOrReportDialogActions,
  getColorByStatus,
  renderVerificationOrReportHeader
} from "../common";
import { renderTableHeadRow, renderTableRowWithData } from "./common";

type Props = {
  reports: PersonaReport<EmailRiskReportPayloadAttributes>[];
  applicant: Applicant;
  isLoading: boolean;
  retryFunction: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedVerificationType: keyof Verification | "all"
  ) => void;
  closeFunction: () => void;
};

const EmailReportsHistoryDialog = ({
  reports,
  applicant,
  isLoading,
  closeFunction,
  retryFunction
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [rawJSONDialogOpen, setRawJSONDialogOpen] = React.useState(false);
  const [rawJSON, setRawJSON] = React.useState("");
  const [copied, setCopied] = React.useState(false);
  const { enabled: editMode } = React.useContext(formEditContext);

  React.useEffect(() => {
    if (copied) {
      enqueueSnackbar("Successfully copied!", {
        variant: "success"
      });
    }
  }, [copied]);
  const closeRawDialogFunction = () => {
    setRawJSONDialogOpen(false);
    setCopied(false);
  };
  const copyRawJSON = async () => {
    await navigator.clipboard.writeText(rawJSON);
    setCopied(true);
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        id="raw-json-dialog"
        open={rawJSONDialogOpen}
        onClose={closeRawDialogFunction}
      >
        <DialogTitle id="raw-json-dialog-title">
          <CloseDialogButton closeFunction={closeRawDialogFunction} />
        </DialogTitle>
        <DialogContent>
          <pre>{rawJSON}</pre>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button
            style={copied ? { background: "green" } : {}}
            onClick={copyRawJSON}
            variant="contained"
            autoFocus
            color="primary"
            id="copy"
          >
            Copy
          </Button>
          <Button onClick={closeRawDialogFunction} variant="contained" color="primary" id="cancel">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        id="fraud-validation-reports-dialog"
        open={true}
        onClose={closeFunction}
      >
        <DialogTitle id="fraud-validation-reports-dialog-title">
          {`Email Report History - ${applicant.data.info.firstName} ${applicant.data.info.lastName}`}
          <CloseDialogButton closeFunction={closeFunction} />
        </DialogTitle>
        <DialogContent>
          <Grid container xs={12}>
            {reports.reverse().map((report, index) => {
              const status = isEmailReportValid(report?.data?.info?.report?.response)
                ? "Valid"
                : "Invalid";

              const statusColor = getColorByStatus(status);
              return (
                <Paper key={index} elevation={3} style={{ width: "100%", marginBottom: "10px" }}>
                  <Grid container spacing={1} item xs={12} style={{ padding: "5px", margin: 0 }}>
                    {renderVerificationOrReportHeader(
                      status,
                      statusColor,
                      report?.createdAt,
                      report?.data?.info?.performedByUserEmail,
                      () => {
                        setRawJSON(JSON.stringify(report, null, 2));
                        setRawJSONDialogOpen(true);
                      }
                    )}
                    <Grid item xs={12}>
                      <TableContainer style={{ marginTop: "10px" }} component={Paper}>
                        <Table size="medium">
                          <TableHead>{renderTableHeadRow()}</TableHead>
                          <TableBody>
                            {renderTableRowWithData(
                              "Suspicious",
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.email_is_suspicious as null | undefined
                              )
                                ? undefined
                                : report?.data?.info?.report?.response?.data?.attributes
                                    ?.email_is_suspicious
                                ? "Yes"
                                : "No",
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.email_is_suspicious as null | undefined
                              )
                                ? undefined
                                : !report?.data?.info?.report?.response?.data?.attributes
                                    ?.email_is_suspicious
                            )}
                            {renderTableRowWithData(
                              "Spam",
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.email_is_spam as null | undefined
                              )
                                ? undefined
                                : report?.data?.info?.report?.response?.data?.attributes
                                    ?.email_is_spam
                                ? "Yes"
                                : "No",
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.email_is_spam as null | undefined
                              )
                                ? undefined
                                : !report?.data?.info?.report?.response?.data?.attributes
                                    ?.email_is_spam
                            )}
                            {renderTableRowWithData(
                              "Disposable",
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.email_is_disposable as null | undefined
                              )
                                ? undefined
                                : report?.data?.info?.report?.response?.data?.attributes
                                    ?.email_is_disposable
                                ? "Yes"
                                : "No",
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.email_is_disposable as null | undefined
                              )
                                ? undefined
                                : !report?.data?.info?.report?.response?.data?.attributes
                                    ?.email_is_disposable
                            )}
                            {renderTableRowWithData(
                              "Malicious activity detected",
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.email_malicious_activity_detected as null | undefined
                              )
                                ? undefined
                                : report?.data?.info?.report?.response?.data?.attributes
                                    ?.email_malicious_activity_detected
                                ? "Yes"
                                : "No",
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.email_malicious_activity_detected as null | undefined
                              )
                                ? undefined
                                : !report?.data?.info?.report?.response?.data?.attributes
                                    ?.email_malicious_activity_detected
                            )}
                            {renderTableRowWithData(
                              "Malicious activity detected recently",
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.email_malicious_activity_detected_recent as null | undefined
                              )
                                ? undefined
                                : report?.data?.info?.report?.response?.data?.attributes
                                    ?.email_malicious_activity_detected_recent
                                ? "Yes"
                                : "No",
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.email_malicious_activity_detected_recent as null | undefined
                              )
                                ? undefined
                                : !report?.data?.info?.report?.response?.data?.attributes
                                    ?.email_malicious_activity_detected_recent
                            )}
                            {renderTableRowWithData(
                              "Days since first seen",

                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.email_first_seen_days as null | undefined
                              )
                                ? undefined
                                : report?.data?.info?.report?.response?.data?.attributes?.email_first_seen_days?.toString(),
                              [undefined, null].includes(
                                report?.data?.info?.report?.response?.data?.attributes
                                  ?.email_first_seen_days as null | undefined
                              )
                                ? undefined
                                : (report?.data?.info?.report?.response?.data?.attributes
                                    ?.email_first_seen_days || 0) >= 30
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          </Grid>
        </DialogContent>
        <VerificationOrReportDialogActions
          recordsLength={reports?.length || 0}
          retryFunction={retryFunction}
          selectedVerificationType="emailRiskReport"
          isLoading={isLoading}
          closeFunction={closeFunction}
        />
      </Dialog>
    </React.Fragment>
  );
};
export default EmailReportsHistoryDialog;
