import { Filters } from "components/Filters/types";
import React from "react";

export interface FiltersContext {
  filters: { [key: string]: Filters | undefined };
  setFilters: React.Dispatch<React.SetStateAction<{ [key: string]: Filters }>>;
}
const filtersContext = React.createContext<FiltersContext>({ filters: {}, setFilters: () => {} });

export default filtersContext;
