import { Box, Button, Dialog, DialogActions, DialogContent, IconButton } from "@material-ui/core";
import { Add, Warning } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { RootState } from "app/rootReducer";
import Big from "big.js";
import { HintTooltip } from "components/common/HintTooltip";
import formEditContext from "components/Content/FormEditContext";
import { getStateList } from "components/States/listStateSlice";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormComponent } from "utils/models/fields";
import { generateForm, RenderSet, StateAccess } from "utils/models/formGenerator";
import { showRenderSet } from "utils/models/formRenderers";
import CloseDialogButton from "../common/CloseDialogButton";
import { Deal } from "./types";

interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}

const isTaxesEqual = (deal: Deal) => {
  const totalTaxes = new Big(deal?.data?.info?.payment?.totalTaxes || 0);
  const localTaxes = new Big(deal?.data?.info?.payment?.localTaxes || 0);
  const stateTaxes = new Big(deal?.data?.info?.payment?.stateTaxes || 0);

  return totalTaxes.eq(localTaxes.add(stateTaxes));
};
export const detailedTaxes: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  name: "Detailed taxes",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "currency-field",
          name: "taxableAmount",
          label: "Taxable Amount",
          width: "1/2",
          path: ["data", "info", "payment", "taxableAmount"],
          default: null
        }
      ]
    },
    {
      formComponent: "currency-field",
      name: "stateTaxes",
      label: "State Taxes",
      width: "1/2",
      path: ["data", "info", "payment", "stateTaxes"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "localTaxes",
      label: "Local Taxes",
      width: "1/2",
      path: ["data", "info", "payment", "localTaxes"],
      default: null
    }
  ]
};
export default function TaxesPreview({ stateAccess, renderSet }: Props) {
  const { enabled: editMode } = useContext(formEditContext);
  const deal: Deal = stateAccess.get<Deal>([] as any);
  const dispatch = useDispatch();
  const listId = `list-state-taxes-in-deal`;
  const statesList = useSelector((state: RootState) => state.listStateSlice[listId]);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const isFixedTaxes: boolean = deal?.data?.info?.taxesAndFees?.isFixedTax;
  const taxesStruct: FormComponent<Deal> = {
    formComponent: "segment",
    width: "full",
    entities: [
      {
        formComponent: isFixedTaxes ? "currency-field" : "read-only-number-field",
        name: "totalTax",
        label: "Total Tax",
        width: "full",
        path: ["data", "info", "payment", "totalTaxes"],
        default: null
      }
    ]
  };

  useEffect(() => {
    if (statesList?.entities === undefined) {
      dispatch(getStateList(listId));
    }
  }, [dispatch, listId, statesList]);

  useEffect(() => {
    if (statesList !== undefined) {
      const applicantState = deal?.data?.applicant?.data?.info?.currentState;
      const newState = statesList.entities?.find((x) => x.data?.info?.name === applicantState);
      if (statesList.entities && newState?._id !== deal?.data?.stateTax?._id) {
        stateAccess.set<Deal>(["data", "stateTax"], newState);
      }
    }
  }, [statesList, stateAccess, deal]);
  return (
    <div style={{ display: "flex" }}>
      {generateForm(taxesStruct, stateAccess, [], stateAccess, renderSet)}
      <IconButton id="add-taxes-or-warning-button" size="small" onClick={handleOpenDialog}>
        {isTaxesEqual(deal) ? (
          editMode ? (
            <HintTooltip title="Click here to add taxes.">
              <Add />
            </HintTooltip>
          ) : (
            <HintTooltip title="Click here to view current taxes.">
              <VisibilityIcon />
            </HintTooltip>
          )
        ) : (
          <HintTooltip title="The total tax is not equal to the sum of the local and state taxes">
            <Warning style={{ color: "#eed202" }} />
          </HintTooltip>
        )}
      </IconButton>
      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        <CloseDialogButton closeFunction={handleClose} />
        <DialogContent>
          <Box>
            {generateForm(
              detailedTaxes,
              stateAccess,
              [],
              stateAccess,
              isFixedTaxes ? renderSet : showRenderSet(false)
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {editMode ? (
            <Button
              id="add-taxes-modal-button"
              onClick={handleClose}
              color="primary"
              variant="contained"
            >
              Add
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}
