import { Typography } from "@material-ui/core";
import QRCode from "qrcode.react";
import React from "react";
import QRCodeImage from "../../images/qr-code-logo.png";
interface Props {
  dealershipId: string;
  dealershipName: string;
}
const WidgetQRCode = React.forwardRef((props: Props, ref: React.ForwardedRef<any>) => {
  return (
    <div className="qrCode" ref={ref}>
      <Typography gutterBottom className="qrCode-dealership-name" style={{ display: "none" }}>
        {props.dealershipName}
      </Typography>
      <QRCode
        size={512}
        value={process.env.REACT_APP_APPLICATION_FORM_URL + props.dealershipId}
        imageSettings={{
          src: QRCodeImage,
          height: 100,
          width: 100,
          excavate: true
        }}
      />
    </div>
  );
});
export default WidgetQRCode;
