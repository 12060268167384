import { Schema } from "ajv";
import {
  dealership,
  targetPlatforms,
  applicant,
  driver,
  vehicle,
  tradeIn,
  financeSummary,
  extraData,
} from "./data_properties";

const data: Schema = {
  type: "object",
  properties: {
    dealership: dealership,
    sourcePartnerId: { type: "string", nullable: true },
    targetPlatforms,
    financeMethod: {
      type: ["string", "null"],
      enum: ["Finance", "Lease", "Balloon", "Cash", null],
      errorMessage: {
        enum: "must be one of the following: 'Finance', 'Lease', 'Balloon', 'Cash'",
      },
      nullable: true,
    },
    applicant,
    includeCoApplicant: { type: "boolean", nullable: true },
    coApplicant: applicant,
    guarantor: applicant,
    driver,
    vehicle: vehicle,
    tradeIns: {
      type: "array",
      nullable: true,
      items: tradeIn,
    },
    financeSummary: financeSummary,
    partnerSource: { type: "string", nullable: true },
    comments: { type: "string", nullable: true },
    regulationBIndicator: { type: "boolean", nullable: true },
    privacyNoticeIndicator: { type: "boolean", nullable: true },
    communityPropertyDisclosureIndicator: { type: "boolean", nullable: true },
    extraData: {
      type: "array",
      nullable: true,
      items: extraData,
    },
    params: { type: "object", nullable: true, additionalProperties: true },
    dealId: { type: "string", nullable: true },
  },
};

export default {
  dealertrack_deal_add: {
    $id: "dealertrackDealAdd",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_update: {
    $id: "dealertrackDealUpdate",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_search: {
    $id: "dealertrackDealSearch",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_lookup: {
    $id: "dealertrackDealLookup",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_in_use: {
    $id: "dealertrackDealInUse",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_lending_sources: {
    $id: "dealertrackDealLendingSources",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_gap_sources: {
    $id: "dealertrackDealGAPSources",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_insurance_sources: {
    $id: "dealertrackDealInsuranceSources",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_buyer_lien_holder_names: {
    $id: "dealertrackDealBuyerLienHolderNames",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_adds_to_cost: {
    $id: "dealertrackDealAddsToCost",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_salespersons: {
    $id: "dealertrackDealSalespersons",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_leasing_sources: {
    $id: "dealertrackDealLeasingSources",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_tax_group: {
    $id: "dealertrackDealTaxGroup",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_service_contracts: {
    $id: "dealertrackDealServiceContracts",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_service_contract_definition: {
    $id: "dealertrackDealServiceContractDefinition",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_settings: {
    $id: "dealertrackDealSettings",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_pdi_companies: {
    $id: "dealertrackPDICompanies",
    type: "object",
    required: ["data"],
    properties: { data: data },
  },
  dealertrack_deal_fees_loopkup: {
    $id: "dealertrackDealFeesLookup",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      dealId: { type: "string" },
    },
  },
};
