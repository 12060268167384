import { createListSlice } from "../../Middlewares/listSliceCreator";
import { Notification } from "./types";

const { ListSlice, getList } = createListSlice<Notification>({
  name: "NotificationList",
  request_topic: "all_notifications",
  reducers: {}
});
export default ListSlice.reducer;
export const getNotificationList = getList;
export const removeNotificationList = ListSlice.actions.removeList;
export const listNotificationActions = ListSlice.actions;
