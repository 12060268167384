import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { PrintedDocument, RequiredDocumentStatus } from "components/PrintedDocuments/types";
import { Deal } from "../types";
import { RootState } from "app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Paper } from "@material-ui/core";
import { Document, Page } from "react-pdf";
import { useSnackbar } from "notistack";
import {
  approvePrintedDocument,
  approvePrintedDocumentActions
} from "components/PrintedDocuments/approvePrintedDocumentSlice";
import {
  rejectPrintedDocument,
  rejectPrintedDocumentActions
} from "components/PrintedDocuments/rejectPrintedDocumentSlice";
import {
  handleApprovePrintedDocument,
  handleRejectPrintedDocument
} from "./RecognizeFundingDocumentPageForm";
import RejectDialog from "./RejectDialog";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}));

interface Props {
  printedDocument: PrintedDocument | undefined;
  setPrintedDocument: React.Dispatch<React.SetStateAction<PrintedDocument | undefined>>;
  deal: Deal;
  setAllDocumentsAreApproved: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function PreviewAndApprovePrintedDocument({
  printedDocument,
  setPrintedDocument,
  deal,
  setAllDocumentsAreApproved
}: Props) {
  const classes = useStyles();
  const listId = `approve-reject-file-${printedDocument?._id ?? ""}`;

  const { enqueueSnackbar } = useSnackbar();
  const printedDocuments = deal.data?.printedDocuments ?? [];
  const value = printedDocuments.findIndex((doc) => doc._id === printedDocument?._id);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setPrintedDocument(printedDocuments[newValue]);
  };
  const fundingDocuments =
    useSelector((state: RootState) => state.listFundingDocumentSlice[deal._id])?.entities ?? [];
  const dispatch = useDispatch();
  const approvePrintedDocumentSlice = useSelector(
    (state: RootState) => state.approvePrintedDocumentSlice[listId]
  );
  const rejectPrintedDocumentSlice = useSelector(
    (state: RootState) => state.rejectPrintedDocumentSlice[listId]
  );
  const { user } = useSelector((state: RootState) => state.authSlice);
  const moveToNextPending = () => {
    const nextIndex = (printedDocuments ?? []).findIndex(
      (doc) =>
        doc.data.info.status === RequiredDocumentStatus.pending && printedDocument?._id !== doc?._id
    );
    if (nextIndex > -1) {
      setPrintedDocument(printedDocuments[nextIndex]);
    } else if (
      (printedDocuments ?? []).every(
        (doc) => doc.data.info.status === RequiredDocumentStatus.approved
      )
    ) {
      setAllDocumentsAreApproved(true);
    }
  };

  const [dialogState, setDialogState] = useState(false);

  useEffect(() => {
    if (approvePrintedDocumentSlice?.status === "success") {
      enqueueSnackbar("Successfully approved document.", { variant: "success" });
      dispatch({
        type: approvePrintedDocumentActions.none.type,
        payload: { requestId: listId }
      });
      moveToNextPending();
    }

    if (rejectPrintedDocumentSlice?.status === "success") {
      enqueueSnackbar("Successfully rejected document.", { variant: "success" });
      dispatch({
        type: rejectPrintedDocumentActions.none.type,
        payload: { requestId: listId }
      });
      moveToNextPending();
    }
  }, [approvePrintedDocumentSlice?.status, rejectPrintedDocumentSlice?.status]);

  const filteredFundingDocuments = fundingDocuments.filter((fundingDocument, index) => {
    const pages = Object.entries(fundingDocument?.data?.uploadedDocumentStatus?.pages ?? {}).filter(
      ([pageNumber, page]) => page.printedDocumentId === printedDocument?._id
    );

    return pages.length > 0;
  });
  return (
    <div className={classes.root}>
      <AppBar position="sticky" color="primary">
        <Tabs
          value={value}
          onChange={handleChange}
          // indicatorColor="primary"
          // textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {printedDocuments?.map((doc, index) => (
            <Tab
              key={index}
              label={doc.data.info.name.slice(0, 15) + "..."}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      <div>
        {filteredFundingDocuments.length > 0 ? (
          <>
            {filteredFundingDocuments.map((fundingDocument, index) => {
              const pages = Object.entries(
                fundingDocument.data.uploadedDocumentStatus.pages ?? {}
              ).filter(([pageNumber, page]) => page.printedDocumentId === printedDocument?._id);
              return fundingDocument?.data?.info?.metadata?.contentType === "application/pdf" ? (
                <>
                  <Document
                    key={index}
                    file={fundingDocument.data.info.url}
                    noData={
                      <Paper
                        style={{
                          margin: "10px 0px",
                          display: "flex",
                          justifyContent: "center",
                          color: "black",
                          minHeight: 800
                        }}
                      ></Paper>
                    }
                    loading={
                      <Paper
                        style={{
                          margin: "10px 0px",
                          display: "flex",
                          justifyContent: "center",
                          color: "black",
                          minHeight: 800
                        }}
                      >
                        Loading...
                      </Paper>
                    }
                  >
                    {pages.map(([pageNumber, page]) => (
                      <Paper
                        key={pageNumber}
                        style={{
                          margin: "10px 0px",
                          display: "flex",
                          justifyContent: "center",
                          color: "black"
                        }}
                      >
                        <Page
                          className="full-page"
                          renderTextLayer={false}
                          renderAnnotationLayer={false}
                          width={1500}
                          height={1027}
                          pageNumber={parseInt(pageNumber)}
                        />
                      </Paper>
                    ))}
                  </Document>
                </>
              ) : (
                <Paper
                  style={{
                    margin: "10px 0px",
                    display: "flex",
                    justifyContent: "center",
                    color: "black"
                  }}
                >
                  <img style={{ width: "100%" }} src={fundingDocument?.data?.info.url} />
                </Paper>
              );
            })}
            <div style={{ position: "sticky", bottom: "-16px" }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    style={{ margin: 4, background: "#50a538" }}
                    onClick={() =>
                      handleApprovePrintedDocument(
                        dispatch,
                        listId,
                        enqueueSnackbar,
                        printedDocument?._id
                      )
                    }
                    color="primary"
                    size="large"
                    variant="contained"
                  >
                    Approve
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <RejectDialog
                    setDialogState={setDialogState}
                    dialogState={dialogState}
                    RejectPrintedDocumentFunction={(note: string) => {
                      handleRejectPrintedDocument(
                        dispatch,
                        listId,
                        enqueueSnackbar,
                        note,
                        user?.databaseData?._id as string,
                        printedDocument?._id
                      );
                    }}
                  />
                  <Button
                    fullWidth
                    style={{ margin: 4 }}
                    onClick={() => setDialogState(true)}
                    color="secondary"
                    size="large"
                    variant="contained"
                  >
                    Reject
                  </Button>
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <div
            style={{
              padding: "13px",
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold"
            }}
          >
            Missing documents!
          </div>
        )}
      </div>
    </div>
  );
}
