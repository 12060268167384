import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditStateData {
  _id: string;
  updatedAt: string;
  data: FormState;
}
interface EditStateRequest {
  _id: string;
  data: FormState;
}

interface EditStateResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditStateRequest,
  EditStateResponse
>("EditState", {
  request: "update_state"
});

export const editStateActions = socketMessageSlice.actions;
export const editState = (request: EditStateData & Identifiable) => {
  const { updatedAt, ...rest } = request;
  return socketAction({
    ...rest,
    data: {
      ...request.data,
      info: request.data.info
    }
  });
};
export default socketMessageSlice.reducer;
