import React from "react";
import { createOptionsForSelect, FormComponent, Model } from "../../utils/models/fields";
import AccessControl from "components/Access/AccessControl";
import formEditContext from "components/Content/FormEditContext";
import { capitalize } from "utils/functions";
import DealershipTicketNotesPreview from "components/DealershipTicketNotes/DealershipTicketNotesPreview";
import { DealershipTicket, DealershipTicketStatus } from "./types";
import useUpdateDealershipTicketNotes from "hooks/useUpdateDealershipTicketNotes/useUpdateDealershipTicketNotes";
import DealershipTicketDescription from "./DealershipTicketDescription";
import NotesPreview from "components/Notes/NotesPreview";

const dealershipTicketNotes = (action: "create" | "update"): FormComponent<DealershipTicket> => ({
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      width: "1/2",
      entities: [
        {
          formComponent: "one-to-many-field",
          component: (stateAccess) => (
            <AccessControl requiredPermissions={{ entity: "dealership_ticket_note", action }}>
              <DealershipTicketNotesPreview
                dealershipTicket={stateAccess.get([])}
                dealershipTicketNotes={stateAccess.get(["data", "dealershipTicketNotes"]) ?? []}
              />
            </AccessControl>
          ),
          path: null,
          name: "Notes",
          width: "full"
        }
      ]
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess) => (
        <AccessControl requiredPermissions={{ entity: "note", action: "update" }}>
          <NotesPreview
            type="dealershipTicket"
            notes={stateAccess.get(["data", "notes"]) ?? []}
            dealershipTicketId={stateAccess.get(["_id"])}
          />
        </AccessControl>
      ),
      path: null,
      name: "Notes",
      width: "1/2"
    }
  ]
});
export const dealershipTicketStruct: Model<DealershipTicket> = {
  formComponent: "model",
  schema: "new_dealership_ticket",
  name: "dealership_ticket",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "one-to-many-field",
          path: [],
          name: "notesUpdater",
          component: (stateAccess) => {
            const { enabled: editMode } = React.useContext(formEditContext);

            const { updateDealershipTicketNotes } = useUpdateDealershipTicketNotes();
            React.useEffect(() => {
              const dealershipTicket = stateAccess.get([]) as DealershipTicket;
              if (
                editMode &&
                dealershipTicket?.data?.dealershipTicketNotes?.some(
                  (note) => !note?.data?.info?.seen?.wfd
                )
              ) {
                updateDealershipTicketNotes(dealershipTicket);
              }
            }, [editMode]);
            return null;
          }
        },
        {
          formComponent: "segment",
          name: "Issue description",
          width: "1/2",
          entities: [
            {
              width: "full",
              formComponent: "one-to-many-field",
              path: [],
              name: "General issue description",
              component: (stateAccess) => (
                <DealershipTicketDescription dealershipTicket={stateAccess.get([])} />
              )
            },
            {
              formComponent: "segment",
              width: "full",
              entities: [dealershipTicketNotes("create")]
            }
          ]
        },
        {
          formComponent: "segment",
          name: "Issue information",
          width: "1/2",
          entities: [
            {
              formComponent: "segment",
              name: "Dealership",
              width: "full",
              entities: [
                {
                  formComponent: "read-only-field",
                  name: "Dealership",
                  label: "Dealership",
                  width: "full",
                  path: ["data", "dealership", "data", "info", "displayName"],
                  default: null
                }
              ]
            },
            {
              formComponent: "segment",
              name: "Status",
              width: "full",
              entities: [
                {
                  formComponent: "select-field",
                  width: "full",
                  options: createOptionsForSelect({
                    possibleValues: () => Object.values(DealershipTicketStatus),
                    getOptionLabel: (x) => capitalize(x),
                    getSelectedOption: (x, y) => x === y
                  }),
                  name: "status",
                  label: "Status",
                  path: ["data", "info", "status"]
                }
              ]
            },
            {
              formComponent: "segment",
              name: "User",
              width: "full",
              entities: [
                {
                  formComponent: "read-only-field",
                  name: "userFirstName",
                  label: "User's first name",
                  width: "1/3",
                  path: ["data", "info", "user", "firstName"],
                  default: null
                },
                {
                  formComponent: "read-only-field",
                  name: "userLastName",
                  label: "User's last name",
                  width: "1/3",
                  path: ["data", "info", "user", "lastName"],

                  default: null
                },
                {
                  formComponent: "read-only-field",
                  name: "userEmail",
                  label: "User's email",
                  width: "1/3",
                  path: ["data", "info", "user", "email"],
                  default: null
                }
              ]
            },
            {
              formComponent: "segment",
              name: "Device",
              width: "full",
              entities: [
                {
                  formComponent: "read-only-field",
                  name: "deviceType",
                  label: "Device type",
                  width: "1/3",
                  path: ["data", "info", "deviceInfo", "deviceType"],
                  default: null
                },
                {
                  formComponent: "read-only-field",
                  name: "osName",
                  label: "OS Name",
                  width: "1/3",
                  path: ["data", "info", "deviceInfo", "osName"],
                  default: null
                },
                {
                  formComponent: "read-only-field",
                  name: "osVersion",
                  label: "OS Version",
                  width: "1/3",
                  path: ["data", "info", "deviceInfo", "osVersion"],
                  default: null
                }
              ]
            },
            {
              formComponent: "segment",
              name: "Browser",
              width: "full",
              entities: [
                {
                  formComponent: "read-only-field",
                  name: "browserName",
                  label: "Browser name",
                  width: "1/2",
                  path: ["data", "info", "browserInfo", "browserName"],
                  default: null
                },
                {
                  formComponent: "read-only-field",
                  name: "browserVersion",
                  label: "Browser version",
                  width: "1/2",
                  path: ["data", "info", "deviceInfo", "browserVersion"],
                  default: null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
