import { XlStyledDialog } from "components/common/StyledDialog";
import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContentText
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import {
  generateDefault,
  fillDefaultsByPath,
  generateForm,
  StateAccess,
  getByPath,
  setByPath
} from "utils/models/formGenerator";
import { payoffBankStruct } from "components/PayoffBanks/model";
import { addPayoffBank } from "components/PayoffBanks/addPayoffBankSlice";
import { useDispatch, useSelector } from "react-redux";
import { EntityData, entityConfigs } from "utils/entitySlice";
import SaveIcon from "@material-ui/icons/Save";
import { editRenderSet, showRenderSet } from "utils/models/formRenderers";
import AccessControl from "components/Access/AccessControl";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "app/rootReducer";
import { PayoffBank } from "components/PayoffBanks/types";
import { editPayoffBank } from "components/PayoffBanks/editPayoffBankSlice";
import CloseDialogButton from "components/common/CloseDialogButton";
import { lockEntity } from "utils/models/LockEntity/lockEntitySlice";
import { HintTooltip } from "components/common/HintTooltip";

export default ({
  setSelected,
  selectedPayoffBank,
  editMode
}: {
  selectedPayoffBank: PayoffBank;
  setSelected: (payoffBank: PayoffBank) => void;
  editMode: boolean | undefined;
}) => {
  const initialState =
    selectedPayoffBank ?? (generateDefault(payoffBankStruct, {}, fillDefaultsByPath as any) as any);
  const currentUser = useSelector((state: RootState) => state.authSlice);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [requestId] = useState(uuidv4());
  const [canSubmit, setCanSubmit] = useState(true);
  const formName = `deal-payoffBank-new`;
  const [formState, setFormState] = React.useState(initialState);
  const lockData = useSelector((state: RootState) => state.listLockSlice["all"])?.entities?.find(
    (lock) =>
      lock?.data?.info?.entityId === initialState?._id &&
      lock?.data?.info?.entityName === "payoff_bank"
  );
  const stateAccess: StateAccess = {
    get: (path) => getByPath(formState as any, path),
    set: (path, value): any => setFormState(setByPath(formState as any, path, value))
  };
  const actionState = useSelector((state: RootState) =>
    selectedPayoffBank?._id
      ? state.editPayoffBankSlice[requestId]
      : state.addPayoffBankSlice[requestId]
  );

  const editActionState = useSelector((state: RootState) => state.editPayoffBankSlice[requestId]);

  useEffect(() => {
    if (selectedPayoffBank === null) {
      setFormState(generateDefault(payoffBankStruct, {}, fillDefaultsByPath as any) as any);
    } else {
      setFormState(selectedPayoffBank);
    }
  }, [selectedPayoffBank]);

  useEffect(() => {
    if (actionState?.status === "success" && actionState?.data !== null) {
      setSelected({
        data: actionState.data.message.data,
        _id: actionState.data.message._id,
        createdAt: actionState.data.message.createdAt,
        updatedAt: actionState.data.message.updatedAt
      });
    }
  }, [actionState, setSelected]);

  useEffect(() => {
    if (editActionState?.status === "success" && editActionState?.data !== null) {
      dispatch(
        lockEntity({
          requestId,
          data: {
            info: {
              entityId: editActionState?.data.message?._id,
              entityName: "payoff_bank",
              action: "unlock"
            }
          }
        })
      );
    }
  }, [editActionState, setSelected, dispatch, requestId]);

  useEffect(() => {
    dispatch({
      type: entityConfigs.payoff_bank.edit.sliceActions.add.type,
      payload: { requestId }
    });

    return () => {
      dispatch({
        type: entityConfigs.payoff_bank.edit.sliceActions.remove.type,
        payload: { requestId }
      });
    };
  }, [requestId, dispatch]);

  const handleSubmit = (state: EntityData<"payoff_bank">) => (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(false);
    if (canSubmit) {
      setCanSubmit(false);
      if (!selectedPayoffBank?._id) {
        dispatch(addPayoffBank({ ...state, requestId }));
      } else {
        const { _id, createdAt, deleted, ...rest } = state;
        dispatch(editPayoffBank({ _id: selectedPayoffBank._id, ...rest, requestId }));
      }
      setTimeout(() => {
        setCanSubmit(true);
      }, 2000);
    } else {
      alert("Please dont submit the form twice!");
    }
  };

  const handleOpen = () => {
    if (
      lockData?.data?.info?.userEmail &&
      lockData?.data?.info?.userEmail !== currentUser?.user?.email
    ) {
      setOpenAlert(true);
    } else {
      if (selectedPayoffBank?._id)
        dispatch(
          lockEntity({
            requestId,
            data: {
              info: {
                entityId: selectedPayoffBank?._id,
                entityName: "payoff_bank",
                action: "lock"
              }
            }
          })
        );
      setOpen(true);
    }
  };

  const handleLock = () => {
    dispatch(
      lockEntity({
        requestId,
        data: {
          info: { entityId: selectedPayoffBank?._id, entityName: "payoff_bank", action: "lock" }
        }
      })
    );
    setOpen(true);
    setOpenAlert(false);
  };

  const handleClose = () => {
    if (selectedPayoffBank?._id) {
      dispatch(
        lockEntity({
          requestId,
          data: {
            info: { entityId: selectedPayoffBank?._id, entityName: "payoff_bank", action: "unlock" }
          }
        })
      );
    }
    setOpen(false);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <AccessControl
        key={`add-payoffBank-button`}
        requiredPermissions={{ entity: "payoff_bank", action: "create" }}
      >
        <Dialog
          id="confirm-unlock-dialog"
          open={openAlert}
          onClose={handleCloseAlert}
          aria-labelledby="unlock-payoff-bank"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to unlock this payoff bank?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {lockData &&
                `Locked by ${lockData?.data?.info?.userEmail}  
           ${new Intl.DateTimeFormat("en", {
             day: "2-digit",
             month: "2-digit",
             year: "2-digit",
             hour: "2-digit",
             minute: "2-digit",
             second: "2-digit"
           }).format(new Date(lockData?.updatedAt))}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAlert} variant="contained" color="primary" id="cancel">
              Cancel
            </Button>
            <Button
              onClick={handleLock}
              variant="contained"
              color="secondary"
              autoFocus
              id="confirm"
            >
              Unlock
            </Button>
          </DialogActions>
        </Dialog>
        <IconButton
          size="small"
          style={{ margin: "3px 0px 0px 10px" }}
          aria-label={`add-payoffBank`}
          id={"add-payoffBank-button"}
          onClick={handleOpen}
        >
          {editMode ? (
            !selectedPayoffBank?._id ? (
              <HintTooltip title="Click here to add a payoff bank.">
                <AddIcon />
              </HintTooltip>
            ) : (
              <HintTooltip title="Click here to edit the payoff bank.">
                <EditIcon />
              </HintTooltip>
            )
          ) : (
            <HintTooltip title="Click here to view the payoff bank.">
              <VisibilityIcon />
            </HintTooltip>
          )}
        </IconButton>
      </AccessControl>
      {open ? (
        <XlStyledDialog>
          <DialogContent>
            <CloseDialogButton closeFunction={handleClose} />
            <form id={formName} autoComplete="off" onSubmit={handleSubmit(formState as any)}>
              {generateForm(
                payoffBankStruct,
                stateAccess,
                [],
                stateAccess,
                editMode ? editRenderSet("new_payoff_bank") : showRenderSet("new_payoff_bank")
              )}
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="contained">
              Cancel
            </Button>
            {editMode ? (
              <Button
                disabled={!canSubmit}
                form={formName}
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            ) : null}
          </DialogActions>
        </XlStyledDialog>
      ) : null}
    </>
  );
};
