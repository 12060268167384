import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import React, { Dispatch, useContext, useEffect } from "react";
import { StateAccess } from "utils/models/formGenerator";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import { DocumentTemplate } from "components/DocumentTemplates/types";
import { ListState } from "components/Middlewares/listSliceCreator";
import { useDispatch } from "react-redux";
import { VerificationContext } from "components/Layout/Main";
import { generateContract } from "components/DocumentTemplates/PrintTemplate";
import { showLoader, hideLoader } from "components/Loader/loaderSlice";
import { Deal } from "../types";
import {
  DocumentsTypesLiterals,
  ModalDocuments,
  ModalDocumentTemplateType,
  PackType
} from "./types";
import { File } from "components/Files/types";
import IFrameURLModal from "./IFrameURLModal";
import { Visibility } from "@material-ui/icons";
import SendDocumentsByEmailForm, { generateEmailData } from "./SendDocumentsByEmailForm";
import { capitalize } from "utils/functions";
import SendIcon from "@material-ui/icons/Send";
import { sendGeneratedContract } from "utils/httpRequestsLibrary/sendGeneratedContractRequests";
import { Dealership } from "components/Dealerships/types";
import { Checklist } from "../Checklist/types";
import { disaggregateDeal } from "../editDealSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    actionsContainer: {
      marginBottom: theme.spacing(2)
    },
    resetContainer: {
      padding: theme.spacing(3)
    },
    eSingHeaderTypography: {
      background: "#e8e8e8",
      padding: "8px",
      fontWeight: "bold"
    }
  })
);

const preloadFreshDocumentTempaltes = (
  documents: ModalDocuments,
  documentTemplates: DocumentTemplate[]
) => {
  return documents.map((document) => {
    if (document.type === DocumentsTypesLiterals.document_template) {
      return {
        ...document,
        documentData: documentTemplates.find((freshDocument) => freshDocument._id === document._id)
      };
    }
    return document;
  });
};

const getFilesFromDocuments = (documents: ModalDocuments) =>
  (documents.filter(
    (doc) => doc.type === DocumentsTypesLiterals.document_template
  ) as ModalDocumentTemplateType[])
    .map((doc) => doc?.documentData?.data?.files ?? [])
    ?.flatMap((file) => file)
    .filter((file) => file) as File[];

const getRequiredDocumentsFields = (files: File[], stateAccess: StateAccess) =>
  files?.reduce(
    (acc: { name: string; value: unknown }[], curr) => [
      ...acc,
      ...(curr.data.info.coordinates ?? []).reduce(
        (acc: { name: string; value: unknown }[], curr) =>
          curr.required
            ? [
                ...acc,
                {
                  name: curr.label,
                  value: stateAccess.get(curr.path as [string])
                }
              ]
            : acc,
        []
      )
    ],
    []
  ) ?? [];

export const checkDocumentRequiredFieldsAndGenerate = async (
  checklistStateAccess: StateAccess,
  checklistDealStateAccess: StateAccess,
  stateAccess: StateAccess,
  checkDataValidity: (
    x: {
      name: string;
      value: any;
    }[],
    y?: () => void
  ) => boolean,
  documentTemplates: ListState<DocumentTemplate>,
  dispatch: Dispatch<any>,
  includeBarcodes: boolean,
  dealership?: Dealership | null
) => {
  const documents: ModalDocuments = checklistStateAccess.get(["data", "info", "documents"]) ?? [];
  if (documents.length === 0) {
    return alert("No selected document!");
  }
  const preloadedDocuments = preloadFreshDocumentTempaltes(
    documents,
    documentTemplates?.entities ?? []
  );

  const files: File[] = getFilesFromDocuments(preloadedDocuments);

  const requiredDocumentsFields = getRequiredDocumentsFields(files, stateAccess);
  if (
    checkDataValidity([
      ...requiredDocumentsFields,
      {
        name: "Dealership -> Contact Info -> Email",
        value: dealership?.data?.contacts?.[0]?.email
      },
      {
        name: "Dealership -> Contact Info -> First name",
        value: dealership?.data?.contacts?.[0]?.firstName
      },
      {
        name: "Dealership -> Contact Info -> Last name",
        value: dealership?.data?.contacts?.[0]?.lastName
      }
    ])
  ) {
    dispatch(showLoader());

    const contract = await generateContract(
      checklistDealStateAccess,
      stateAccess,
      checklistStateAccess,
      preloadedDocuments,
      includeBarcodes
    );

    if (contract?.status) dispatch(hideLoader());
  }
};

const GenerateDocumentsStepper = ({
  stateAccess,
  editMode,
  checklistStateAccess,
  checklistDealStateAccess,
  documentTemplates
}: {
  checklistDealStateAccess: StateAccess;
  checklistStateAccess: StateAccess;
  stateAccess: StateAccess;
  editMode?: boolean;
  documentTemplates: ListState<DocumentTemplate>;
}) => {
  const deal: Deal = stateAccess.get<Deal>([]);
  const checklist: Checklist = checklistStateAccess.get<Checklist>([]);
  const autofillEmail = () => {
    const emailData = generateEmailData(deal, checklist);
    const assignedDealershipEmails = deal.data.info.assignedDealershipEmails;
    checklistStateAccess.set(["data", "info"], {
      ...(checklist?.data?.info ?? {}),
      emailData,
      send: {
        email: true,
        eSign: !!checklist?.data?.info?.generatedPacks?.find(
          (pack) => pack.packType === PackType.eSign
        )
      },
      eSignEmailData: {
        applicant: deal?.data?.applicant?.data?.info?.email,
        coApplicant: deal?.data?.coApplicant?.data?.info?.email,
        dealership:
          assignedDealershipEmails[0] || deal?.data?.dealership?.data?.contacts?.[0]?.email
      }
    });
  };

  const dispatch = useDispatch();
  const checkDataValidity = useContext(VerificationContext);
  const classes = useStyles();
  const [contractUrl, setContractURl] = React.useState<undefined | string>();
  useEffect(() => {
    //REQUIRED FOR VIRTUAL FIELDS IN DEAL MODEL WHICH USE CHECKLIST PATH
    const checklistForDeal = {
      documents: checklist?.data.info?.documents ?? [],
      optionalText: checklist?.data?.info?.optionalText
    };
    checklistDealStateAccess.set(["data", "checklist"], checklistForDeal);
  }, [checklist]);
  useEffect(() => {
    const applicantESignEmailData = checklist?.data?.info?.eSignEmailData?.applicant;
    const coApplicantESignEmailData = checklist?.data?.info?.eSignEmailData?.coApplicant;
    const assignedDealershipEmails = deal.data.info.assignedDealershipEmails;
    const dealershipESignEmailData = checklist?.data?.info?.eSignEmailData?.dealership;
    if (!applicantESignEmailData || !coApplicantESignEmailData || !dealershipESignEmailData) {
      checklistStateAccess.set(["data", "info", "eSignEmailData"], {
        applicant:
          typeof applicantESignEmailData === "string"
            ? applicantESignEmailData
            : deal?.data?.applicant?.data?.info?.email,
        coApplicant:
          typeof coApplicantESignEmailData === "string"
            ? coApplicantESignEmailData
            : applicantESignEmailData ?? deal?.data?.coApplicant?.data?.info?.email,
        dealership: assignedDealershipEmails?.length
          ? assignedDealershipEmails[0]
          : typeof dealershipESignEmailData === "string"
          ? dealershipESignEmailData
          : deal?.data?.dealership?.data?.contacts?.[0]?.email
      });
    }
  }, []);
  const [sendEmailStatus, setSendEmailStatus] = React.useState<
    undefined | "loading" | "success" | "error"
  >();
  const [includeBarcodes, setIncludeBarcodes] = React.useState(true);
  const sendEmail = async () => {
    if (!checklist?.data?.info?.send?.eSign && !checklist?.data?.info?.send?.email) {
      alert("Please choose email or esign to send a message!");
      return;
    }
    if (
      checklist?.data?.info?.send?.email &&
      (!checklist?.data?.info?.emailData?.recipients ||
        checklist?.data?.info?.emailData?.recipients?.length < 1)
    )
      return alert("Please add recepients!");

    try {
      setSendEmailStatus("loading");
      const response = await sendGeneratedContract({ deal: disaggregateDeal(deal), checklist });
      if (response && response.status === "success") {
        setSendEmailStatus("success");
        stateAccess.set<Deal>([], response.result.deal);
        checklistDealStateAccess.set<Deal>([], response.result.deal);
        checklistStateAccess.set<Checklist>([], response.result.checklist);
      }
    } catch (error) {
      setSendEmailStatus("error");
    }
  };

  return (
    <Paper
      elevation={3}
      style={{ height: "100%", overflowY: "auto", padding: 10, border: "1px solid #cecece" }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Generate</Typography>
        </Grid>
        <div className={classes.root}>
          <Stepper orientation="vertical" connector={<div style={{ height: "10px" }}></div>}>
            <Step key={"Generate"} active={true}>
              <StepLabel>{"Generate"}</StepLabel>
              <StepContent>
                <>
                  <Button
                    autoFocus
                    onClick={() =>
                      checkDocumentRequiredFieldsAndGenerate(
                        checklistStateAccess,
                        checklistDealStateAccess,
                        stateAccess,
                        checkDataValidity,
                        documentTemplates,
                        dispatch,
                        includeBarcodes,
                        deal?.data?.dealership
                      )
                    }
                    color="primary"
                    variant="contained"
                    disabled={!editMode || documentTemplates?.status !== "success"}
                    id="print-button-modal"
                  >
                    Generate
                  </Button>
                  <FormControlLabel
                    checked={includeBarcodes}
                    control={<Checkbox />}
                    style={{ marginLeft: 20 }}
                    onChange={() => {
                      setIncludeBarcodes((prevIncludeBarcodes) => !prevIncludeBarcodes);
                    }}
                    label="Include barcodes?"
                  />
                </>
              </StepContent>
            </Step>

            <Step key={"Preview"} active={true}>
              <StepLabel>{"Preview"}</StepLabel>
              <StepContent>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {checklist?.data?.info?.generatedPacks?.length
                    ? (checklist?.data?.info?.generatedPacks ?? [])?.map(({ url, packType }) => {
                        return (
                          <>
                            <Button
                              color="primary"
                              variant="contained"
                              style={{ marginRight: "10px" }}
                              onClick={() => setContractURl(url)}
                              endIcon={<Visibility />}
                            >
                              {capitalize(packType)} pack
                            </Button>
                          </>
                        );
                      })
                    : "Please generate contract first!"}
                  {contractUrl && (
                    <IFrameURLModal
                      url={contractUrl}
                      handleClose={() => setContractURl(undefined)}
                    />
                  )}
                </div>
              </StepContent>
            </Step>

            <Step key={"Email"} active={true}>
              <StepLabel>{"Email"}</StepLabel>
              <StepContent>
                {checklist?.data?.info?.generatedPacks?.length ? (
                  <>
                    <Button onClick={() => autofillEmail()} color="primary" variant="contained">
                      Auto Fill Email
                      {(checklist?.data?.info?.generatedPacks ?? [])?.find(
                        (pack) => pack.packType === PackType.eSign
                      )
                        ? " and E-sign"
                        : ""}
                    </Button>
                    <SendDocumentsByEmailForm
                      checklistStateAccess={checklistStateAccess}
                      deal={deal}
                    />
                  </>
                ) : (
                  "Please generate contract first!"
                )}
              </StepContent>
            </Step>
            {(checklist?.data?.info?.generatedPacks ?? [])?.find(
              (pack) => pack.packType === PackType.eSign
            ) && (
              <Step key={"eSign"} active={true}>
                <StepLabel>{"E-Sign information:"}</StepLabel>
                <StepContent>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Names</TableCell>
                          <TableCell>Email</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ fontWeight: "bold" }}>Applicant</TableCell>
                          <TableCell>{`${deal?.data?.applicant?.data?.info?.firstName ?? ""} ${
                            deal?.data?.applicant?.data?.info?.lastName ?? ""
                          }`}</TableCell>
                          <TableCell>
                            <TextField
                              value={
                                checklistStateAccess.get([
                                  "data",
                                  "info",
                                  "eSignEmailData",
                                  "applicant"
                                ]) ?? ""
                              }
                              type="email"
                              onChange={(e) =>
                                checklistStateAccess.set(["data", "info", "eSignEmailData"], {
                                  ...(checklist?.data?.info?.eSignEmailData ?? {}),
                                  applicant: e.target.value
                                })
                              }
                              name="applicant"
                              fullWidth
                              variant="standard"
                              size="small"
                              FormHelperTextProps={{ style: { color: "#f19c65" } }}
                              helperText={`${
                                deal?.data?.applicant?.data?.info?.email &&
                                checklist?.data?.info?.eSignEmailData?.applicant &&
                                deal?.data?.applicant?.data?.info?.email !==
                                  checklist?.data?.info?.eSignEmailData?.applicant
                                  ? "Email change will affect applicant email!"
                                  : ""
                              }`}
                            />
                          </TableCell>
                        </TableRow>
                        {deal?.data?.coApplicant ? (
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}>Co-Applicant</TableCell>
                            <TableCell>
                              {`${deal?.data?.coApplicant?.data?.info?.firstName ?? ""} ${
                                deal?.data?.coApplicant?.data?.info?.lastName ?? ""
                              }`}
                            </TableCell>
                            <TableCell>
                              <TextField
                                value={
                                  checklistStateAccess.get([
                                    "data",
                                    "info",
                                    "eSignEmailData",
                                    "coApplicant"
                                  ]) ?? ""
                                }
                                onChange={(e) =>
                                  checklistStateAccess.set(["data", "info", "eSignEmailData"], {
                                    ...(checklist?.data?.info?.eSignEmailData ?? {}),
                                    coApplicant: e.target.value
                                  })
                                }
                                name="coApplicant"
                                fullWidth
                                type="email"
                                variant="standard"
                                size="small"
                                FormHelperTextProps={{ style: { color: "#f19c65" } }}
                                helperText={`${
                                  deal?.data?.coApplicant?.data?.info?.email &&
                                  checklist?.data?.info?.eSignEmailData?.coApplicant &&
                                  deal?.data?.coApplicant?.data?.info?.email !==
                                    checklist?.data?.info?.eSignEmailData?.coApplicant
                                    ? "Email change will affect co-applicant email!"
                                    : ""
                                }`}
                              />
                            </TableCell>
                          </TableRow>
                        ) : null}
                        <TableRow>
                          <TableCell style={{ fontWeight: "bold" }}>Dealership</TableCell>
                          <TableCell>
                            {deal?.data?.dealership?.data?.info?.displayName ?? ""}
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={checklist?.data?.info?.eSignEmailData?.dealership ?? ""}
                              onChange={(e) =>
                                checklistStateAccess.set(["data", "info", "eSignEmailData"], {
                                  ...(checklist?.data?.info?.eSignEmailData ?? {}),
                                  dealership: e.target.value
                                })
                              }
                              name="dealership"
                              fullWidth
                              variant="standard"
                              size="small"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </StepContent>
              </Step>
            )}

            <Step key={"Send"} active={true}>
              <StepLabel>{"Send"}</StepLabel>
              <StepContent>
                <FormControlLabel
                  key="send-checkbox"
                  checked={checklist?.data?.info?.send?.email ?? false}
                  control={<Checkbox />}
                  onChange={() => {
                    checklistStateAccess.set(["data", "info", "send"], {
                      ...(checklist?.data?.info?.send ?? {}),
                      email: !checklist?.data?.info?.send?.email
                    });
                  }}
                  label="Email"
                />
                {(checklist?.data?.info?.generatedPacks ?? [])?.find(
                  (pack) => pack.packType === PackType.eSign
                ) && (
                  <FormControlLabel
                    onChange={() => {
                      checklistStateAccess.set(["data", "info", "send"], {
                        ...(checklist?.data?.info?.send ?? {}),
                        eSign: !checklist?.data?.info?.send?.eSign
                      });
                    }}
                    checked={checklist?.data?.info?.send?.eSign ?? false}
                    control={<Checkbox />}
                    label="eSign"
                  />
                )}
                <Button
                  disabled={!checklist?.data?.info?.emailData}
                  color="primary"
                  variant="contained"
                  onClick={sendEmail}
                  style={
                    sendEmailStatus === "success"
                      ? { background: "#50a538", float: "right", color: "black" }
                      : { float: "right" }
                  }
                  endIcon={
                    sendEmailStatus === "loading" ? (
                      <CircularProgress style={{ color: "#fff" }} size={19} />
                    ) : (
                      <SendIcon
                        style={
                          (checklist?.data?.info?.sendEmails ?? [])?.length > 0
                            ? { color: "black" }
                            : {}
                        }
                      />
                    )
                  }
                >
                  {sendEmailStatus === "success" ? "SENT" : "SEND"}
                </Button>
                <br />
                {sendEmailStatus === "success" ? (
                  <div style={{ color: "green" }}>
                    {checklist?.data?.info?.send?.eSign && checklist?.data?.info?.send?.email
                      ? "Email and eSign contracts were sent successfully!"
                      : checklist?.data?.info?.send?.eSign
                      ? "eSign contract was sent sucessfully!"
                      : checklist?.data?.info?.send?.email
                      ? "Email was sent sucessfully!"
                      : "Nothing was sent successfully!"}
                  </div>
                ) : sendEmailStatus === "error" ? (
                  <div style={{ color: "red" }}>
                    Error! The system was not able to send the email!
                  </div>
                ) : null}
              </StepContent>
            </Step>
            <>
              <Step key={""} active={false}>
                <StepLabel>{""}</StepLabel>
              </Step>
            </>
          </Stepper>
        </div>
      </Grid>
    </Paper>
  );
};

export default GenerateDocumentsStepper;
