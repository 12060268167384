import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import { projections as defaultDealProjections } from "components/Deals/Deals";
import { getDealListByIds } from "components/Deals/listDealSliceByIds";
import { DealData } from "components/Deals/types";

import Table, { Column } from "components/Table";
import CloseDialogButton from "components/common/CloseDialogButton";
import React from "react";
import { useSelector } from "react-redux";

interface ModalData {
  period: string;
  dealIds: string[];
}
interface Props {
  lenderIds?: string[];
  lenderNames?: string;
  data?: ModalData[];
  showTotalFooter?: boolean;
  columns: Column<DealData>[];
  closeFunction: () => void;
}

export default ({
  data,
  closeFunction,
  lenderIds,
  lenderNames,
  columns,
  showTotalFooter = false
}: Props) => {
  const slice = `lender-statistics-deals-${lenderIds?.join("-")}`;
  const dealList = useSelector((state: RootState) => state.listDealSliceByIds);

  return (
    <Dialog
      id="lender-statistics-deals-dialog"
      onClose={closeFunction}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="customized-dialog-title">
        Deals included in the lender statistics ({lenderNames})
      </DialogTitle>
      <CloseDialogButton closeFunction={closeFunction} />
      <DialogContent>
        {(data ?? [])?.map(({ period, dealIds }, index) => (
          <Table
            tableContainerOverflowX="scroll"
            key={index}
            projection={{
              ...defaultDealProjections
            }}
            showTotalFooter={showTotalFooter}
            tableName={`lender_statistics_deals_${lenderIds?.join("_")}_${period}_${index}`}
            entityName="deal"
            listFunction={getDealListByIds}
            listEntity={dealList[`${slice}_${period}_${index}`]}
            query={{ _id: { $in: dealIds } }}
            sort={{ createdAt: "desc" }}
            title={`${period}`}
            slice={`${slice}_${period}_${index}`}
            columns={columns}
            hideDeleteButton={true}
            hideAddButton={true}
            hideRecoverButton={true}
          />
        ))}
      </DialogContent>
    </Dialog>
  );
};
