import Grid from "@material-ui/core/Grid";
import { RootState } from "app/rootReducer";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOptionsForSelect } from "utils/models/fields";
import { StateAccess } from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";
import { getUserList, removeUserList } from "../Users/listUserSlice";

export default function OldUserFilter<T extends unknown>({ setFilters, path }: any) {
  const dispatch = useDispatch();
  const listId = `list-user-filter-old`;
  const userList = useSelector((state: RootState) => state.listUserSlice[listId]);

  const handleOpen = () => {
    if (!userList)
      dispatch(
        getUserList(listId, {
          options: { pagination: false, sort: { "data.info.firstName": "asc" } }
        })
      );
    return () => {
      dispatch(removeUserList(listId));
      return;
    };
  };
  const [chosen, setChosen] = useState();
  const stateAccess: StateAccess = {
    get: (path) => chosen,
    set: (_, value): any => {
      setChosen(value);

      setFilters((filters: any) => {
        if (value?._id !== undefined) {
          return { ...filters, [path.join(".")]: value?._id };
        } else {
          const { [path.join(".")]: _, ...Rest } = filters;
          return Rest;
        }
      });
    }
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {editRenderSet(false).selectInputRenderer(
          {
            formComponent: "select-field",
            name: "user",
            label: "Rep / F&I Manager",
            required: false,
            path: [] as [],
            onOpen: handleOpen,
            options: createOptionsForSelect({
              possibleValues: () =>
                userList?.entities?.filter(
                  (el) =>
                    el?.data?.info?.userType === "representative" ||
                    el?.data?.info?.userType === "f&i manager"
                ) || [],
              getOptionLabel: (user) =>
                `${user?.data?.info?.firstName} ${user?.data?.info?.lastName}`,
              getSelectedOption: (x, y) => x?._id === y?._id
            }),
            valueToString: (user) => `${user?.data?.info?.firstName} ${user?.data?.info?.lastName}`
          },
          stateAccess,
          [],
          stateAccess,
          editRenderSet(false)
        )}
      </Grid>
    </Grid>
  );
}
