import { Box, Grid } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import { Contract } from "components/Contracts/types";
import AddVendor from "components/OtherVendors/AddEntitiy";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOptionsForSelect, Path } from "utils/models/fields";
import { getByPath, RenderSet, StateAccess } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import { getDealershipList, removeDealershipList } from "../Dealerships/listDealershipSlice";
import { addDealership } from "./addDealershipSlice";
import { dealershipStruct } from "./model";
import { Dealership } from "./types";
interface Props<T> {
  stateAccess: StateAccess;
  path: Path<T>;
  renderSet: RenderSet;
  name: string;
  label: string;
}

export default function DealershipPReview<T extends Contract>({
  stateAccess,
  path,
  renderSet,
  name,
  label
}: Props<T>) {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const listId = `list-autocomplete-dealerships-${requestId}`;
  const dealershipList = useSelector((state: RootState) => state.listDealershipSlice[listId]);

  const dealerships: Dealership[] = stateAccess.get<T>(path) ?? [];
  const dealershipstateAccess: StateAccess = {
    get: (path) => getByPath<Dealership[]>(dealerships, path as Path<Dealership[]>),
    set: (_, value): any => stateAccess.set<T>(path, value)
  };

  const handleLoadDealerships = () => {
    if (!dealershipList)
      dispatch(
        getDealershipList(listId, {
          options: { pagination: false, sort: { "data.info.displayName": "asc" } }
        })
      );
    return () => {
      dispatch(removeDealershipList(listId));
      return;
    };
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1}>
              {renderSet.multiSelectInputRenderer(
                {
                  formComponent: "multiselect-field",
                  name,
                  label,
                  path: [],
                  onOpen: handleLoadDealerships,
                  options: createOptionsForSelect({
                    possibleValues: () => dealershipList?.entities ?? [],
                    getOptionLabel: (dealership) => {
                      return dealership?.data?.info?.displayName ?? "";
                    },
                    getSelectedOption: (x, y) => {
                      return x._id === y._id;
                    }
                  }),
                  valueToString: (el) => {
                    return el.map((el: Dealership) => el.data.info.displayName).join(", ");
                  }
                },
                dealershipstateAccess,
                [],
                dealershipstateAccess,
                renderSet
              )}
            </Box>
            <Box>
              <AddVendor
                type="dealership"
                addFunction={addDealership}
                struct={dealershipStruct}
                addSlice={"addDealershipSlice"}
                setSelected={(vendor) => {
                  stateAccess.set(path as [], [...(stateAccess.get(path) || []), vendor]);
                }}
                renderSet={renderSet}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
