import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { sumbitASCRequest, sumbitASCResponse } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  sumbitASCRequest,
  sumbitASCResponse
>("SubmitASCInsurance", {
  request: "asc_process_transaction"
});

export const submitASC = socketAction;
export const sumbitASCActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
