import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { Stipulation, AddStipulationRequest } from "./types";

interface AddStipulationResponse {
  message: Stipulation;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddStipulationRequest,
  AddStipulationResponse
>("AddStipulation", {
  request: "new_stipulation"
});

export const addStipulationActions = socketMessageSlice.actions;

export const addStipulation = socketAction;

export default socketMessageSlice.reducer;
