import FileCopyIcon from "@material-ui/icons/FileCopy";
import ContractsPreview from "components/Contracts/ContractsPreview";
import PivotTable from "components/PivotTable/PivotTable";
import {
  pivotMarkupCorrespondentTableContent,
  pivotMarkupTableContent,
  pivotMarkupTableMenuFields,
  pivotTableContent,
  pivotTableMenuFields
} from "components/PivotTable/model";
import React from "react";
import States from "us-states";
import { setByPath, StateAccess } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import {
  formatNumberAsCurrency,
  getStateLabelByState,
  getStateLabelByStatePrefix,
  getTabName,
  phoneWithBracesWithoutCode
} from "../../utils/functions";
import { FormComponent, Model, createOptionsForSelect } from "../../utils/models/fields";
import PriorityField from "./PriorityField";
import Routes from "./Routes";
import {
  Lender,
  Note,
  OperatesInStateStatus,
  OtherInfoStruct,
  Representative,
  reserversToTimelineItems
} from "./types";

import { IconButton } from "@material-ui/core";
import AdditionalRequiredDocumentsPreview from "components/AdditionalRequiredDocuments/AdditionalRequiredDocumentsPreview";
import Deals from "components/Deals/Deals";
import { collateralTypes } from "components/Deals/types";
import { User } from "components/Users/types";
import { HintTooltip } from "components/common/HintTooltip";
import { HTML_VIRTUAL_FIELD_SPLITTER } from "utils/htmlVirtualFieldsSplitter";
import CopyReserve from "./CopyReserve";
import EmailVerificationLink from "./EmailVerificationLink";
import SendGeneratedLink from "components/Lenders/SendGeneratedLink";
import GenerateLink from "./GenerateLink";
import StaticMap from "components/GoogleStaticMap/StaticMap";
import ContractTypeMultiSelect from "components/Contracts/ContractTypes/ContractTypeMultiSelect";
import OperatesInStates from "./OperatesInStates";

const allocationStruct: FormComponent<Lender | User> = {
  formComponent: "segment",
  name: "Allocation",
  width: "full",
  elevation: 3,
  style: { padding: "10px" },
  entities: [
    {
      formComponent: "checkbox-field",
      name: "allocation",
      label: "Has allocation?",
      width: "full",
      valueType: "boolean",
      path: ["data", "info", "allocation"],
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<Lender>(["data", "info", "allocation"]),
      toggle: (stateAccess: StateAccess) =>
        stateAccess.set<Lender>(
          ["data", "info", "allocation"],
          !stateAccess.get<Lender>(["data", "info", "allocation"])
        ),
      default: false
    },
    {
      formComponent: "radio-field",
      name: "AllocationType",
      label: "Default Allocation Type",
      width: "1/3",
      path: ["data", "info", "allocationType"],
      possibleValues: [
        { label: "Yearly", value: "yearly" },
        { label: "Monthly", value: "monthly" },
        { label: "Lifetime Allocation", value: "lifetime" }
      ],
      show: (stateAccess: StateAccess) => stateAccess.get(["data", "info", "allocation"]),
      default: "yearly",
      required: true
    },
    {
      formComponent: "segment",
      name: "Allocation periods",
      show: (stateAccess: StateAccess) => stateAccess.get(["data", "info", "allocation"]),
      elevation: 3,
      width: "full",
      style: { padding: "10px" },
      entities: [
        {
          formComponent: "tab-list-model",
          name: "Lifetime",
          width: "full",
          getTabName: (entity) => {
            if (entity?.to?.month && entity?.to?.year) {
              return `Period: ${entity.from.month}-${entity.from.year} / ${entity.to.month}-${entity.to.year}`;
            }
            return `Period: ${entity.from.month}-${entity.from.year} / Unlimited`;
          },
          path: ["data", "info", "lifetimeAllocationPeriods"],
          entity: {
            formComponent: "segment",
            width: "full",
            entities: [
              {
                formComponent: "segment",
                name: "From",
                width: "1/2",
                entities: [
                  {
                    formComponent: "year-field",
                    name: "Month",
                    max: 12,
                    min: 1,
                    label: "Month",
                    path: ["from", "month"],
                    default: null,
                    required: true
                  },
                  {
                    formComponent: "year-field",
                    name: "Year",
                    label: "Year",
                    width: "1/2",
                    path: ["from", "year"],
                    default: null,
                    required: true
                  }
                ]
              },
              {
                formComponent: "segment",
                name: "To",
                width: "1/2",
                entities: [
                  {
                    formComponent: "year-field",
                    name: "Month",
                    label: "Month",
                    width: "1/2",
                    max: 12,
                    min: 1,
                    path: ["to", "month"],
                    default: null
                  },
                  {
                    formComponent: "year-field",
                    name: "Year",
                    label: "Year",
                    path: ["to", "year"],
                    default: null
                  }
                ]
              },

              {
                formComponent: "currency-field",
                name: "allocation",
                label: "Allocation",
                width: "1/4",
                path: ["allocation"],
                default: null,
                required: true
              },
              {
                formComponent: "percentage-field",
                name: "soft allocation",
                label: "Soft Allocation",
                width: "1/4",
                path: ["softAllocation"],
                default: null,
                required: true
              },
              {
                formComponent: "date-field",
                name: "seedDate",
                label: "Seed date",
                width: "1/4",
                path: ["seed", "date"],
                default: null
              },
              {
                formComponent: "currency-field",
                name: "seedAmount",
                label: "Seed Amount",
                width: "1/4",
                path: ["seed", "amount"],
                default: null,
                required: true
              },
              {
                formComponent: "checkbox-field",
                name: "suggestedMonthly",
                label: "Is period suggested?",
                width: "full",
                valueType: "boolean",
                path: ["suggested"],
                isDisabled: () => false,
                isChecked: (stateAccess: StateAccess) => stateAccess.get<Lender>(["suggested"]),
                toggle: (stateAccess: StateAccess) =>
                  stateAccess.set<Lender>(
                    ["suggested"],
                    !stateAccess.get<Lender>(["suggested"])
                  ),
                default: false
              },
              {
                formComponent: "list-model",
                name: "Runoff",
                entity: {
                  formComponent: "segment",
                  width: "full",
                  entities: [
                    {
                      formComponent: "year-field",
                      name: "RunoffYear",
                      label: "Year",
                      width: "1/3",
                      path: ["year"],
                      default: null,
                      required: true
                    },

                    {
                      formComponent: "year-field",
                      name: "Runoff Month",
                      max: 12,
                      min: 1,
                      label: "Month",
                      width: "1/3",
                      path: ["month"],
                      default: null,
                      required: true
                    },
                    {
                      formComponent: "currency-field",
                      name: "runoffAmount",
                      label: "Amount",
                      width: "1/3",
                      path: ["amount"],
                      default: null,
                      required: true
                    }
                  ]
                },
                width: "full",
                path: ["runoff"],
                required: false
              }
            ]
          }
        },
        {
          formComponent: "tab-list-model",
          name: "Yearly",
          width: "full",
          getTabName: (entity) => {
            if (entity?.to?.month && entity?.to?.year) {
              return `Period: ${entity.from.month}-${entity.from.year} / ${entity.to.month}-${entity.to.year}`;
            }
            return `Period: ${entity.from.month}-${entity.from.year} / Unlimited`;
          },
          path: ["data", "info", "yearlyAllocationPeriods"],
          entity: {
            formComponent: "segment",
            width: "full",
            entities: [
              {
                formComponent: "segment",
                name: "From",
                width: "1/2",
                entities: [
                  {
                    formComponent: "year-field",
                    name: "Month",
                    max: 12,
                    min: 1,
                    label: "Month",
                    path: ["from", "month"],
                    default: null,
                    required: true
                  },
                  {
                    formComponent: "year-field",
                    name: "Year",
                    label: "Year",
                    width: "1/2",
                    path: ["from", "year"],
                    default: null,
                    required: true
                  }
                ]
              },
              {
                formComponent: "segment",
                name: "To",
                width: "1/2",
                entities: [
                  {
                    formComponent: "year-field",
                    name: "Month",
                    label: "Month",
                    width: "1/2",
                    max: 12,
                    min: 1,
                    path: ["to", "month"],
                    default: null,
                    required: true
                  },
                  {
                    formComponent: "year-field",
                    name: "Year",
                    label: "Year",
                    path: ["to", "year"],
                    default: null,
                    required: true
                  }
                ]
              },

              {
                formComponent: "currency-field",
                name: "allocation",
                label: "Allocation",
                width: "1/4",
                path: ["allocation"],
                required: true,
                default: null
              },
              {
                formComponent: "percentage-field",
                name: "softAllocation",
                label: "Soft Allocation",
                width: "1/4",
                path: ["softAllocation"],
                required: true,
                default: null
              },
              {
                formComponent: "checkbox-field",
                name: "suggestedYearly",
                label: "Is period suggested?",
                width: "full",
                valueType: "boolean",
                path: ["suggested"],
                isDisabled: () => false,
                isChecked: (stateAccess: StateAccess) => stateAccess.get<Lender>(["suggested"]),
                toggle: (stateAccess: StateAccess) =>
                  stateAccess.set<Lender>(
                    ["suggested"],
                    !stateAccess.get<Lender>(["suggested"])
                  ),
                default: false
              }
            ]
          }
        },
        {
          formComponent: "tab-list-model",
          name: "Monthly",
          width: "full",
          getTabName: (entity) => {
            if (entity?.to?.month && entity?.to?.year) {
              return `Period: ${entity.from.month}-${entity.from.year} / ${entity.to.month}-${entity.to.year}`;
            }
            return `Period: ${entity.from.month}-${entity.from.year} / Unlimited`;
          },
          path: ["data", "info", "monthlyAllocationPeriods"],
          entity: {
            formComponent: "segment",
            width: "full",
            entities: [
              {
                formComponent: "segment",
                name: "From",
                width: "1/2",
                entities: [
                  {
                    formComponent: "year-field",
                    name: "Month",
                    max: 12,
                    min: 1,
                    label: "Month",
                    path: ["from", "month"],
                    default: null,
                    required: true
                  },
                  {
                    formComponent: "year-field",
                    name: "Year",
                    label: "Year",
                    width: "1/2",
                    path: ["from", "year"],
                    default: null,
                    required: true
                  }
                ]
              },
              {
                formComponent: "segment",
                name: "To",
                width: "1/2",
                entities: [
                  {
                    formComponent: "year-field",
                    name: "Month",
                    label: "Month",
                    width: "1/2",
                    max: 12,
                    min: 1,
                    path: ["to", "month"],
                    default: null,
                    required: true
                  },
                  {
                    formComponent: "year-field",
                    name: "Year",
                    label: "Year",
                    path: ["to", "year"],
                    default: null,
                    required: true
                  }
                ]
              },

              {
                formComponent: "currency-field",
                name: "allocation",
                label: "Allocation",
                width: "1/4",
                path: ["allocation"],
                required: true,
                default: null
              },
              {
                formComponent: "percentage-field",
                name: "softAllocation",
                label: "Soft Allocation",
                width: "1/4",
                path: ["softAllocation"],
                required: true,
                default: null
              },
              {
                formComponent: "checkbox-field",
                name: "suggestedMonthly",
                label: "Is period suggested?",
                width: "full",
                valueType: "boolean",
                path: ["suggested"],
                isDisabled: () => false,
                isChecked: (stateAccess: StateAccess) => stateAccess.get<Lender>(["suggested"]),
                toggle: (stateAccess: StateAccess) =>
                  stateAccess.set<Lender>(
                    ["suggested"],
                    !stateAccess.get<Lender>(["suggested"])
                  ),
                default: false
              }
            ]
          }
        }
      ]
    }
  ]
};
const coverage: FormComponent<Lender> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "currency-field",
      name: "collisionDeductible",
      label: "Collision deductible",
      width: "1/2",
      path: ["data", "info", "coverage", "collisionDeductible"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "comprehensiveDeductible",
      label: "Comprehensive deductible",
      width: "1/2",
      path: ["data", "info", "coverage", "comprehensiveDeductible"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "collisionX",
      label: "X Collision?",
      value: (stateAccess) =>
        ![null, undefined].includes(
          stateAccess.get<Lender>(["data", "info", "coverage", "collisionDeductible"])
        ),
      description: "Displays X if there is collision Deductible",
      path: ["data", "info", "coverage", "collisionX"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "comprenhensiveX",
      label: "X Comprenhensive?",
      value: (stateAccess) =>
        ![null, undefined].includes(
          stateAccess.get<Lender>(["data", "info", "coverage", "comprehensiveDeductible"])
        ),
      description: "Displays X if there is comprehensive Deductible",
      path: ["data", "info", "coverage", "comprenhensiveX"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "comprehensiveDeductibleOrN/A",
      label: "Comprehensive deductible or N/A",
      value: (stateAccess) => {
        const coverage: Lender["data"]["info"]["coverage"] = stateAccess.get<Lender>([
          "data",
          "info",
          "coverage"
        ]);
        return coverage?.comprehensiveDeductible
          ? formatNumberAsCurrency(coverage?.comprehensiveDeductible) ?? "N/A"
          : "N/A";
      },
      description: "Displays Comprehensive deductible amount ot N/A",
      path: ["data", "info", "coverage", "comprehensiveDeductibleOrN/A"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "collisionDeductibleOrN/A",
      label: "Collision deductible or N/A",
      value: (stateAccess) => {
        const coverage: Lender["data"]["info"]["coverage"] = stateAccess.get<Lender>([
          "data",
          "info",
          "coverage"
        ]);
        return coverage?.collisionDeductible
          ? formatNumberAsCurrency(coverage?.collisionDeductible) ?? "N/A"
          : "N/A";
      },
      description: "Displays Collision deductible amount ot N/A",
      path: ["data", "info", "coverage", "collisionDeductibleOrN/A"],
      default: null
    }
  ]
};
const representativeStruct: FormComponent<Representative> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "name-field",
      name: "firstName",
      label: "First name",
      path: ["firstName"],
      required: true,
      default: null
    },
    {
      formComponent: "name-field",
      name: "lastName",
      label: "Last name",
      path: ["lastName"],
      default: null
    },
    {
      formComponent: "phone-field",
      name: "phoneNumber",
      label: "Phone",
      path: ["phone"],
      default: null
    },
    {
      formComponent: "email-field",
      name: "email",
      label: "Email",
      path: ["email"],
      default: null
    }
  ]
};

export const noteStruct: FormComponent<Note> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "text-field",
      name: "note",
      label: "Note",
      width: "full",
      multiline: true,
      path: ["note"],
      required: true,
      default: null
    }
  ]
};
export const infoStruct: FormComponent<OtherInfoStruct> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "text-field",
      name: "info",
      label: "Info",
      width: "full",
      path: ["info"],
      required: true,
      default: null
    }
  ]
};
export const aliasStruct: FormComponent<OtherInfoStruct> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "text-field",
      name: "alias",
      label: "Alias",
      width: "full",
      path: ["alias"],
      required: true,
      default: null
    }
  ]
};

export const lenderStruct: Model<Lender> = {
  formComponent: "model",
  schema: "new_lender",
  name: "lender",
  entities: [
    {
      formComponent: "segment",
      name: "Lender Info",
      elevation: 3,
      style: { padding: "10px" },
      entities: [
        {
          formComponent: "text-field",
          name: "name",
          label: "Name",
          width: "1/2",
          path: ["data", "info", "name"],
          required: true,
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "lenderFullName",
          label: "lender full name",
          value: (stateAccess) => stateAccess.get<Lender>(["data", "info", "name"]) ?? "",
          description:
            "Displays the lender full name as a title.<br/> Example: <br/><br/> Royal Credit Union",
          path: ["data", "info", "lenderFullName"],
          default: null
        },

        {
          formComponent: "virtual-field",
          name: "nameAndAddressWithLocationAndPhone",
          label: "Name, address with location and phone",
          value: (stateAccess) => {
            const info: Lender["data"]["info"] = stateAccess.get<Lender>(["data", "info"]);
            const thirdLine = [
              (info?.city ?? "") + ",",
              States[info?.state]?.prefix ?? "",
              info?.zipCode ?? ""
            ].join(" ");
            return `${info?.name ?? ""}${HTML_VIRTUAL_FIELD_SPLITTER}${
              info?.address ?? ""
            }${HTML_VIRTUAL_FIELD_SPLITTER}${
              thirdLine ?? ""
            }${HTML_VIRTUAL_FIELD_SPLITTER}${phoneWithBracesWithoutCode(
              info?.phone?.toString() ?? ""
            )}`;
          },
          description:
            "Displays the name, the address and phone on FOUR lines.<br/> Example: <br/><br/> 1st Class Auto Sales LLC<br/> 6370 Concord Blvd S<br/>Heights, La Crosse, WI<br/>(763) 478-8811",
          path: ["data", "info", "nameAndAddressWithLocationAndPhone"],
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "guaranteeOfLienText",
          label: "Guarantee of Lien Form Text",
          value: (stateAccess) => {
            const info: Lender["data"]["info"] = stateAccess.get<Lender>(["data", "info"]);
            return ` ${
              info?.name ?? ""
            } has financed and is lien holder of the vehicle/unit identified
            below. This letter will serve as our guarantee from you that a certificate of
            title, UCC form, or a preferred ships mortgage document (whichever is applicable)
            will be filed in the State in which our customer currently resides in, with
            ${info?.name ?? ""} as lien holder and first secured party.`;
          },
          description: "Displays the the text that should be displayed in Guarantee of Lien Form",
          path: ["data", "info", "guaranteeOfLienText"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "securedPartyNumber",
          label: "Secured party #",
          width: "1/2",
          path: ["data", "info", "securedPartyNumber"],
          default: null
        },
        {
          formComponent: "segment",
          name: "Default address",
          width: "full",
          entities: [
            {
              formComponent: "address-field",
              name: "address",
              label: "Address",
              path: ["data", "info", "address"],
              width: "1/3",
              default: null
            },
            {
              formComponent: "city-field",
              name: "city",
              label: "City",
              path: ["data", "info", "city"],
              width: "1/3",
              default: null
            },
            {
              formComponent: "select-field",
              name: "state",
              label: "State",
              width: "1/3",
              path: ["data", "info", "state"],
              options: createOptionsForSelect({
                possibleValues: () => Object.keys(States),
                getOptionLabel: (x) => getStateLabelByState(x),
                getSelectedOption: (x, y) => x === y
              }),
              default: null
            },
            {
              formComponent: "select-field",
              name: "county",
              label: "County",
              width: "1/4",
              autoSelect: true,
              path: ["data", "info", "county"],
              options: createOptionsForSelect({
                possibleValues: (stateAccess: StateAccess) =>
                  States[stateAccess.get<Lender>(["data", "info", "state"]) as string]?.counties,
                getOptionLabel: (x) => x ?? " ",
                getSelectedOption: (x, y) => x === y
              }),
              default: null
            },
            {
              formComponent: "virtual-field",
              name: "nameAndFullAddress",
              label: "Name and full address",
              value: (stateAccess) => {
                const info: Lender["data"]["info"] = stateAccess.get<Lender>(["data", "info"]);

                return [
                  info?.name,
                  info?.address,
                  `${info?.city}, ${States[info?.state]?.prefix} ${info?.zipCode}`
                ].join(HTML_VIRTUAL_FIELD_SPLITTER);
              },
              description: "Displays lender name , address , location  on 3 lines",
              path: ["data", "info", "nameAndFullAddress"],
              default: null
            },
            {
              formComponent: "virtual-field",
              name: "statePrefix",
              label: "State prefix",
              value: (stateAccess) => {
                const state: Lender["data"]["info"]["state"] = stateAccess.get<Lender>([
                  "data",
                  "info",
                  "state"
                ]);

                return States[state]?.prefix ?? "";
              },
              description: "Displays lender state prefix",
              path: ["data", "info", "statePrefix"],
              default: null
            },
            {
              formComponent: "virtual-field",
              name: "nameAndFullAdditionalAddress",
              label: "Name and full additional address",
              value: (stateAccess) => {
                const info: Lender["data"]["info"] = stateAccess.get<Lender>(["data", "info"]);
                const additionalAddress = info?.additionalAddresses?.[0];
                if (additionalAddress)
                  return [
                    additionalAddress?.name,
                    additionalAddress?.address,
                    `${additionalAddress?.city}, ${States[additionalAddress?.state]?.prefix} ${
                      additionalAddress?.zipCode
                    }`
                  ].join(HTML_VIRTUAL_FIELD_SPLITTER);
                return "";
              },
              description: "Displays lender name, additional address , location  on 3 lines",
              path: ["data", "info", "nameAndFullAdditionalAddress"],
              default: null
            },
            {
              formComponent: "virtual-field",
              name: "fullAddressWithLocation",
              label: "Full Address with Location",
              value: (stateAccess) => {
                const info: Lender["data"]["info"] = stateAccess.get<Lender>(["data", "info"]);

                return [
                  info.address,
                  info.city,
                  [States[info.state]?.prefix, info.zipCode].filter((x) => x).join(" ")
                ]
                  .filter((x) => x)
                  .join(", ");
              },
              description: "Displays address and location on one line",
              path: ["data", "info", "fullAddressWithLocation"],
              default: null
            },
            {
              formComponent: "virtual-field",
              name: "fullLocation",
              label: "Full Location",
              value: (stateAccess) => {
                const info: Lender["data"]["info"] = stateAccess.get<Lender>(["data", "info"]);

                return [info.city, States[info.state]?.prefix].join(" ");
              },
              description: "Displays lender state prefix",
              path: ["data", "info", "fullLocation"],
              default: null
            },
            {
              formComponent: "zip-code-field",
              name: "zipCode",
              label: "Zip Code",
              path: ["data", "info", "zipCode"],
              hideZipCodeSearch: true,
              width: "1/4",
              default: null
            },
            {
              formComponent: "one-to-many-field",
              path: [],
              name: "Map",
              show: (stateAccess) => stateAccess.get(["data", "info", "coordinates"]),
              component: (stateAccess) => {
                const lender: Lender = stateAccess.get([]);
                const street = lender?.data?.info?.address;
                const state = lender?.data?.info?.state;
                const city = lender?.data?.info?.city;
                const coordinates = lender?.data?.info?.coordinates as {
                  lat: number;
                  lon: number;
                };

                return (
                  <StaticMap
                    type="lender"
                    street={street}
                    state={state}
                    city={city}
                    coordinates={coordinates}
                  />
                );
              },
              width: "full"
            }
          ]
        },
        {
          formComponent: "list-model",
          name: "Additional Addresses",
          entity: {
            formComponent: "segment",
            width: "full",
            entities: [
              {
                formComponent: "text-field",
                name: "name",
                label: "Name (internal usage only)",
                path: ["name"],
                width: "1/2",
                default: null
              },
              {
                formComponent: "text-field",
                name: "securedPartyNumber",
                label: "Secured party #",
                width: "1/2",
                path: ["securedPartyNumber"],
                default: null
              },
              {
                formComponent: "address-field",
                name: "address",
                label: "Address",
                path: ["address"],
                width: "1/4",
                default: null
              },
              {
                formComponent: "city-field",
                name: "city",
                label: "City",
                path: ["city"],
                width: "1/4",
                default: null
              },
              {
                formComponent: "select-field",
                name: "state",
                label: "State",
                width: "1/4",
                path: ["state"],
                options: createOptionsForSelect({
                  possibleValues: () => Object.keys(States),
                  getOptionLabel: (x) => getStateLabelByState(x),
                  getSelectedOption: (x, y) => x === y
                }),
                default: null
              },

              {
                formComponent: "zip-code-field",
                name: "zipCode",
                label: "Zip Code",
                path: ["zipCode"],
                hideZipCodeSearch: true,
                width: "1/4",
                default: null
              }
            ]
          },
          width: "full",
          path: ["data", "info", "additionalAddresses"],
          required: false
        },
        {
          formComponent: "segment",
          name: "Contact information",
          width: "full",
          entities: [
            {
              formComponent: "phone-field",
              name: "phone",
              label: "Phone",
              width: "1/3",
              path: ["data", "info", "phone"],
              default: null
            },
            {
              formComponent: "phone-field",
              name: "faxNumber",
              label: "Fax number",
              width: "1/3",
              path: ["data", "info", "faxNumber"],
              default: null
            },
            {
              formComponent: "text-field",
              name: "email",
              label: "Email",
              path: ["data", "info", "email"],
              width: "1/3",
              default: null
            }
          ]
        },

        {
          formComponent: "list-model",
          name: "Representatives",
          entity: representativeStruct,
          width: "full",
          path: ["data", "representatives"],
          required: false
        },
        {
          formComponent: "list-model",
          name: "Aliases",
          entity: aliasStruct,
          width: "full",
          required: false,
          path: ["data", "info", "aliases"]
        },
        {
          formComponent: "list-model",
          name: "Notes",
          entity: infoStruct,
          width: "full",
          required: false,
          path: ["data", "info", "otherInfo"]
        }
      ]
    },
    {
      formComponent: "one-to-many-field",
      name: "Operating states",
      path: [],
      width: "full",
      component: (stateAccess, _mainStateAccess, renderSet) => {
        return <OperatesInStates stateAccess={stateAccess} renderSet={renderSet} />;
      }
    },
    {
      formComponent: "checkbox-field",
      name: "ShowLenderPlatform",
      label: "Show Lender Platform",
      width: "1/4",
      valueType: "boolean",
      path: ["data", "info", "isPlatform"],
      isDisabled: (): boolean => false,
      isChecked: (stateAccess: StateAccess): boolean =>
        stateAccess.get<Lender>(["data", "info", "isPlatform"]),
      toggle: (stateAccess: StateAccess): Lender =>
        stateAccess.set<Lender>(
          ["data", "info", "isPlatform"],
          !stateAccess.get<Lender>(["data", "info", "isPlatform"])
        ),
      required: false,
      default: false
    },
    {
      formComponent: "segment",
      name: "Lender platform",
      show: (stateAccess: StateAccess) => stateAccess.get(["data", "info", "isPlatform"]),
      width: "full",
      entities: [
        {
          formComponent: "text-field",
          name: "firstName",
          label: "First name",
          path: ["data", "info", "adminFirstName"],
          width: "1/4",
          default: null,
          required: true
        },
        {
          formComponent: "text-field",
          name: "lastName",
          label: "Last name",
          path: ["data", "info", "adminLastName"],
          width: "1/4",
          default: null,
          required: true
        },
        {
          formComponent: "email-field",
          name: "adminEmail",
          label: "Admin email",
          path: ["data", "info", "adminEmail"],
          width: "1/4",
          default: null,
          required: true
        },
        {
          formComponent: "segment",
          width: "full",
          entities: [
            {
              formComponent: "one-to-many-field",
              show: (stateAccess: StateAccess) => stateAccess.get(["_id"]),
              name: "GenerateLink",
              width: "1/6",
              label: "GenerateLink",
              path: [],
              component: (stateAccess: StateAccess) => <GenerateLink stateAccess={stateAccess} />
            },
            {
              formComponent: "one-to-many-field",
              name: "SendGeneratedLink",
              show: (stateAccess: StateAccess) =>
                stateAccess.get(["_id"]) &&
                stateAccess.get(["data", "info", "adminRegistrationLink"]),
              width: "1/6",
              label: "SendGeneratedLink",
              path: [],
              component: (stateAccess: StateAccess) => (
                <SendGeneratedLink stateAccess={stateAccess} />
              )
            },
            {
              formComponent: "one-to-many-field",
              show: (stateAccess: StateAccess) => stateAccess.get(["_id"]),
              name: "EmailVerificationLink",
              width: "1/6",
              label: "EmailVerificationLink",
              path: [],
              component: (stateAccess: StateAccess) => (
                <EmailVerificationLink stateAccess={stateAccess} />
              )
            }
          ]
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Netsuite",
      elevation: 3,
      style: { padding: "10px" },
      width: "1/4",
      entities: [
        {
          formComponent: "text-field",
          name: "Netsuite Internal Id",
          label: "Netsuite Internal Id",
          path: ["data", "netsuiteId"],
          width: "full",
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Allocation Dashboard Settings",
      elevation: 3,
      style: { padding: "10px" },
      width: "full",
      entities: [
        {
          formComponent: "radio-field",
          name: "dealDateToUseInDashboard",
          label: "Date that should be used for dashboard calculations",
          width: "1/3",
          path: ["data", "info", "allocationDashboardSettings", "dealDateToUse"],
          default: null,
          possibleValues: [
            { label: "Contract date", value: "contractDate" },
            { label: "Submitted for funding/Funded at date", value: "submittedOrFundedDate" }
          ]
        }
      ]
    },
    allocationStruct,
    {
      formComponent: "segment",
      name: "E-Sign",
      width: "1/4",
      elevation: 3,
      style: { padding: "10px" },
      entities: [
        {
          formComponent: "checkbox-field",
          name: "eSign",
          label: "E-Sign support",
          width: "full",
          valueType: "boolean",
          path: ["data", "info", "eSign"],
          isDisabled: () => false,
          isChecked: (stateAccess: StateAccess) =>
            stateAccess.get<Lender>(["data", "info", "eSign"]),
          toggle: (stateAccess: StateAccess) =>
            stateAccess.set<Lender>(
              ["data", "info", "eSign"],
              !stateAccess.get<Lender>(["data", "info", "eSign"])
            ),
          default: false
        },
        {
          formComponent: "checkbox-field",
          name: "eSignAuthKba",
          label: "KBA Authentication",
          width: "full",
          valueType: "boolean",
          path: ["data", "info", "eSignAuth", "kba"],
          isDisabled: () => false,
          show: (stateAccess) => stateAccess.get<Lender>(["data", "info", "eSign"]),
          isChecked: (stateAccess: StateAccess) =>
            stateAccess.get<Lender>(["data", "info", "eSignAuth", "kba"]),
          toggle: (stateAccess: StateAccess) =>
            stateAccess.set<Lender>(
              ["data", "info", "eSignAuth", "kba"],
              !stateAccess.get<Lender>(["data", "info", "eSignAuth", "kba"])
            ),
          default: false
        },
        {
          formComponent: "checkbox-field",
          name: "eSignAuthSms",
          label: "SMS Authentication",
          width: "full",
          valueType: "boolean",
          path: ["data", "info", "eSignAuth", "sms"],
          isDisabled: () => false,
          show: (stateAccess) => stateAccess.get<Lender>(["data", "info", "eSign"]),
          isChecked: (stateAccess: StateAccess) =>
            stateAccess.get<Lender>(["data", "info", "eSignAuth", "sms"]),
          toggle: (stateAccess: StateAccess) =>
            stateAccess.set<Lender>(
              ["data", "info", "eSignAuth", "sms"],
              !stateAccess.get<Lender>(["data", "info", "eSignAuth", "sms"])
            ),
          default: false
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Required contracts",
      elevation: 3,
      style: { padding: "10px" },
      entities: [
        {
          formComponent: "one-to-many-field",
          component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
            <ContractTypeMultiSelect
              stateAccess={stateAccess}
              path={["data", "requiredContractTypes"]}
              renderSet={renderSet}
              name={"contractType"}
              label="Contract type"
            />
          ),
          name: "Contract type",
          width: "1/4",
          path: []
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Additional docs",
      elevation: 3,
      style: { padding: "10px" },
      entities: [
        {
          formComponent: "one-to-many-field",
          name: "Contracts",
          width: "full",
          label: "Contracts",
          show: (stateAccess: StateAccess) => stateAccess.get<Lender>(["_id"]),
          path: ["_id"],
          component: (stateAccess: StateAccess, _, renderSet) => (
            <AdditionalRequiredDocumentsPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "additionalRequiredDocuments"]}
              name="additionalRequiredDocuments"
              label="Additional required documents"
            />
          )
        },
        {
          formComponent: "checkbox-field",
          name: "requestCreditScoreReport",
          label: "Request credit report",
          width: "1/2",
          valueType: "boolean",
          path: ["data", "info", "requestCreditScoreReport"],
          isDisabled: () => false,
          isChecked: (stateAccess: StateAccess) =>
            stateAccess.get<Lender>(["data", "info", "requestCreditScoreReport"]),
          toggle: (stateAccess: StateAccess) =>
            stateAccess.set<Lender>(
              ["data", "info", "requestCreditScoreReport"],
              !stateAccess.get<Lender>(["data", "info", "requestCreditScoreReport"])
            ),
          default: false
        }
      ]
    },
    {
      formComponent: "segment",
      elevation: 3,
      style: { padding: "10px" },
      name: "Coverage",
      width: "full",
      entities: [coverage]
    },
    {
      formComponent: "segment",
      width: "full",
      elevation: 3,
      style: { padding: "10px" },
      name: "Collateral Types",
      entities: collateralTypes.map((collateralType, index) => ({
        formComponent: "checkbox-field",
        name: collateralType,
        label: collateralType,
        width: "1/2",
        valueType: "boolean",
        path: ["data", "info", "collateralTypes", collateralType],
        isDisabled: () => false,
        isChecked: (stateAccess: StateAccess) =>
          stateAccess.get<Lender>(["data", "info", "collateralTypes", collateralType]),
        toggle: (stateAccess: StateAccess) =>
          stateAccess.set<Lender>(
            ["data", "info", "collateralTypes", collateralType],
            !stateAccess.get<Lender>(["data", "info", "collateralTypes", collateralType])
          ),
        default: false
      })) as FormComponent<Lender>[]
    },
    {
      formComponent: "segment",
      name: "Routes",
      elevation: 3,
      style: { padding: "10px" },
      entities: [
        {
          formComponent: "checkbox-field",
          name: "specificRouteMethod",
          label: "Specific route method",
          width: "full",
          toggle: (stateAccess: StateAccess) =>
            stateAccess.set<Lender>(
              ["data", "info", "specificRouteMethod"],
              !stateAccess.get<Lender>(["data", "info", "specificRouteMethod"])
            ),
          isDisabled: () => false,
          isChecked: (stateAccess: StateAccess) =>
            stateAccess.get<Lender>(["data", "info", "specificRouteMethod"]),
          path: ["data", "info", "specificRouteMethod"],
          default: false
        },
        {
          formComponent: "text-field",
          name: "routeDescription",
          label: "Description",
          show: (stateAccess) => stateAccess.get<Lender>(["data", "info", "specificRouteMethod"]),
          path: ["data", "info", "routeDescription"],
          width: "1/2",
          default: null
        },
        {
          formComponent: "one-to-many-field",
          component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
            <Routes stateAccess={stateAccess} renderSet={renderSet} />
          ),
          name: "Routes",
          width: "full",
          path: [] as any,
          default: {}
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Reserves",
      elevation: 3,
      style: { padding: "10px" },
      entities: [
        {
          formComponent: "checkbox-field",
          name: "roundToDollar",
          label: "Round to dollar?",
          width: "1/3",
          toggle: (stateAccess: StateAccess) =>
            stateAccess.set<Lender>(
              ["data", "roundToDollar"],
              !stateAccess.get<Lender>(["data", "roundToDollar"])
            ),
          isDisabled: () => false,
          isChecked: (stateAccess: StateAccess) =>
            stateAccess.get<Lender>(["data", "roundToDollar"]),
          path: ["data", "roundToDollar"],
          default: false
        },
        {
          formComponent: "list-model",
          name: "Markup",
          elevation: 3,
          style: { padding: "10px" },
          width: "full",
          entity: {
            formComponent: "segment",
            width: "full",
            entities: [
              {
                formComponent: "hidden-field",
                name: "id",
                label: "id",
                width: "1/3",
                path: ["id"],
                default: () => uuidv4()
              },
              {
                formComponent: "text-field",
                name: "Markup table name",
                label: "Markup table name",
                width: "1/3",
                path: ["name"],
                required: true,
                default: null
              },
              {
                formComponent: "radio-field",
                name: "Markup type",
                label: "Markup type",
                width: "1/3",
                required: true,
                path: ["markupType"],
                default: null,
                possibleValues: [
                  { label: "Correspondent", value: "correspondent" },
                  { label: "Full Time F&I", value: "fulltimeF&I" }
                ]
              },
              {
                formComponent: "one-to-many-field",
                name: "Markup",
                label: "Markup",
                path: ["table"],
                width: "full",
                component: (stateAccess: StateAccess, mainStateAccess, renderSet) => (
                  <PivotTable
                    path={["table"]}
                    stateAccess={stateAccess}
                    mainStateAccess={mainStateAccess}
                    menuFields={pivotMarkupTableMenuFields}
                    tableContent={
                      stateAccess.get(["markupType"]) === "correspondent"
                        ? pivotMarkupCorrespondentTableContent
                        : pivotMarkupTableContent
                    }
                  />
                ),
                default: null
              }
            ]
          },
          required: false,
          path: ["data", "info", "markupTables"]
        },

        {
          formComponent: "segment",
          name: "Reserve periods",
          elevation: 3,
          width: "full",
          style: { padding: "10px" },
          entities: [
            ...([
              { name: "Automotive", prefix: "automotive" },
              { name: "Marine", prefix: "marine" },
              { name: "Recreational Vehicles", prefix: "rv" },
              { name: "Power Sport", prefix: "powerSport" }
            ].map((type) => {
              return {
                formComponent: "tab-list-model",
                name: type.name,
                width: "full",
                show: (stateAccess: StateAccess) =>
                  stateAccess.get(["data", "info", "collateralTypes", type.name]),
                getTabName: getTabName,
                additionalOptions: (stateAccess: StateAccess) => {
                  return (
                    <CopyReserve
                      stateAccess={stateAccess}
                      type={type.prefix}
                      types={["automotive", "marine", "rv", "powerSport"].filter(
                        (x) => x !== type.prefix
                      )}
                    />
                  );
                },
                path: ["data", "info", "reserves", type.prefix],
                entity: {
                  formComponent: "segment",
                  width: "full",
                  entities: [
                    {
                      formComponent: "text-field",
                      name: "Name",
                      label: "Name",
                      width: "1/4",
                      path: ["name"],
                      required: true,
                      default: null
                    },
                    {
                      formComponent: "date-field",
                      name: "startDate",
                      label: "Start date",
                      width: "1/4",
                      path: ["startDate"],
                      required: true,
                      default: null
                    },
                    {
                      formComponent: "date-field",
                      name: "EndDate",
                      label: "End date",
                      width: "1/4",
                      path: ["endDate"],
                      required: false,
                      default: null
                    },
                    {
                      formComponent: "one-to-many-field",
                      name: "priority",
                      label: "Priority",
                      width: "1/4",
                      path: ["priority"],
                      component: (stateAccess: StateAccess, mainStateAccess, renderSet) => (
                        <PriorityField
                          stateAccess={stateAccess}
                          mainStateAccess={mainStateAccess}
                          renderSet={renderSet}
                          path={["data", "info", "reserves", type.prefix]}
                        />
                      ),
                      default: null
                    },
                    {
                      formComponent: "one-to-many-field",
                      name: "Reserve",
                      label: "Reserve",
                      path: ["reserves"],
                      width: "full",
                      component: (stateAccess: StateAccess, mainStateAccess, renderSet) => (
                        <>
                          <h4>Full time F&I</h4>
                          <PivotTable
                            path={["reserves", "fulltimeF&I"]}
                            stateAccess={stateAccess}
                            mainStateAccess={mainStateAccess}
                            menuFields={pivotTableMenuFields}
                            tableContent={pivotTableContent("fulltimeF&I")}
                          />
                          <h4>
                            Correspondent
                            <IconButton
                              onClick={() => {
                                if (
                                  !stateAccess.get(["reserves", "correspondent"]) ||
                                  confirm("Are you sure? this will erese the correspondent table?")
                                )
                                  stateAccess.set(
                                    ["reserves", "correspondent"],
                                    stateAccess.get(["reserves", "fulltimeF&I"])
                                  );
                              }}
                            >
                              <HintTooltip title={`Copy from Fulltime F&I table`}>
                                <FileCopyIcon />
                              </HintTooltip>
                            </IconButton>
                          </h4>

                          <PivotTable
                            path={["reserves", "correspondent"]}
                            stateAccess={stateAccess}
                            mainStateAccess={mainStateAccess}
                            menuFields={pivotTableMenuFields}
                            tableContent={pivotTableContent("correspondent")}
                          />
                        </>
                      ),
                      default: null
                    }
                  ]
                }
              };
            }) as FormComponent<Lender, any>[]),

            {
              formComponent: "timeline",
              name: "timeline",
              label: "timeline",
              onTimelineDataClick: (_event, _chartContext, config) => {
                const item = config.w.config.series[config.seriesIndex];
                const element = document.getElementById(`tab-${item.name}`);
                if (element) {
                  element.focus();
                  element.scrollIntoView({ block: "start", behavior: "smooth" });
                }
              },
              width: "full",
              items: (stateAccess) =>
                reserversToTimelineItems(stateAccess.get(["data", "info", "reserves"])),
              path: ["data", "info", "reserves", "timeline"]
            }
          ]
        }
      ]
    },

    {
      formComponent: "one-to-many-field",
      name: "Contracts",
      width: "full",
      label: "Contracts",
      show: (stateAccess: StateAccess) => stateAccess.get<Lender>(["_id"]),
      path: ["_id"],
      component: (stateAccess: StateAccess) => (
        <ContractsPreview stateAccess={stateAccess} type="lender" />
      )
    },
    {
      formComponent: "one-to-many-field",
      name: "Deals",
      width: "full",
      label: "Deals",
      path: [],
      component: (stateAccess: StateAccess) => (
        <Deals
          slice={`deals-lender-${stateAccess.get<Lender>(["_id"])}`}
          query={{ "data.lenderId": stateAccess.get<Lender>(["_id"]) }}
        />
      )
    }
  ]
};
