import Paper from "@material-ui/core/Paper";
import { RootState } from "app/rootReducer";
import filtersContext from "components/Content/FiltersContext";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { defaultStatuses } from "../common";
import { getDealCountByState } from "./dealCountByStatusSlice";
interface Props {
  from: Date;
  to: Date;
  userIds: string[];
}
export default function CountChart({ from, to, userIds }: Props) {
  const requestId = "dealCountByStatus";
  const dispatch = useDispatch();

  const categories = defaultStatuses;

  const { setFilters: setFiltersState } = React.useContext(filtersContext);
  // @ts-ignore
  const state = useSelector(
    (state: RootState) => state.listDealCountByStatusSlice[requestId]
    // @ts-ignore
  )?.entities?.reduce((acc, { _id, count }) => ({ ...acc, [_id]: count }), {});
  // @ts-ignore
  const items = [{ data: defaultStatuses.map((status) => state?.[status] || 0) }];

  useEffect(() => {
    dispatch(
      getDealCountByState(requestId, {
        query: {
          from,
          to,
          userIds
        }
      })
    );
  }, [from, to]);
  return (
    <Paper elevation={3} style={{ height: "275px" }}>
      <ReactApexChart
        options={{
          tooltip: {
            y: {
              title: {
                formatter: function (val: any) {
                  return "";
                }
              }
            }
          },
          chart: {
            toolbar: {
              show: false
            },
            events: {
              dataPointSelection: (event, chartContext, config) => {
                setFiltersState((old) => ({
                  ...old,
                  ["fImanagerDeals"]: {
                    status: [
                      {
                        query: {
                          "data.info.status": {
                            $in: [defaultStatuses[config.dataPointIndex]]
                          }
                        },
                        values: [defaultStatuses[config.dataPointIndex]]
                      }
                    ]
                  }
                }));
              }
            }
          },
          legend: { offsetY: 50, position: "right" },
          plotOptions: {
            bar: { barHeight: "100%", distributed: true, horizontal: false }
          },
          colors: ["#eed238", "#33b2df", "#52a543", "#ffa500"],
          dataLabels: {
            dropShadow: { enabled: false },
            style: {
              colors: ["black"]
            }
          },
          stroke: { width: 1, colors: ["#fff"] },
          xaxis: { categories },
          yaxis: { labels: { show: true } },
          title: { text: `Deals count by status`, align: "center" }
        }}
        series={items}
        type="bar"
        height={250}
      />
    </Paper>
  );
}
