const data = {
  type: "object",
  properties: {
    dealId: {
      type: "string",
    },
    invoice: {
      type: "boolean",
      nullable: true,
    },
    warrantyBill: {
      type: "boolean",
      nullable: true,
    },
    gapBill: {
      type: "boolean",
      nullable: true,
    },
    dealerBill: {
      type: "boolean",
      nullable: true,
    },
    additionalProperties: true,
  },
};

export default {
  sales_order_to_invoice_and_vendor_bill: {
    $id: "newDmv",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
    },
  },
};
