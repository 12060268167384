import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { History } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { Request } from "./index";
import { AppDispatch } from "app/store";

export default ({
  _id,
  recoverEntityFunction
}: {
  _id: string;
  recoverEntityFunction?: (request: Request) => (dispatch: AppDispatch) => Promise<void>;
}) => {
  const dispatch = useDispatch();
  const recoverEntity = () => {
    const requestId = uuidv4();
    recoverEntityFunction !== undefined &&
      dispatch(
        recoverEntityFunction({
          requestId,
          _id
        })
      );
  };
  return (
    <IconButton size="small" color="primary" onClick={recoverEntity}>
      <History />
    </IconButton>
  );
};
