import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverUserRequest {
  _id: string;
}

export interface RecoverUserResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverUserRequest,
  RecoverUserResponse
>("RecoverWfdUser", {
  request: "recover_wfd_user"
});

export const recoverUserActions = socketMessageSlice.actions;
export const recoverUser = socketAction;
export default socketMessageSlice.reducer;
