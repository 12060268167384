import { Typography } from "@material-ui/core";
import { AxiosProgressEvent } from "axios";
import { Info as ApplicantInfo } from "components/Applicants/types";
import { ApplicationParserType } from "components/Dealerships/types";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { IoReloadCircleOutline } from "react-icons/io5";
import DropZoneNoUpload from "utils/DropZoneNoUpload";
import { parseApplicationFile } from "utils/httpRequestsLibrary/dealFromDocument";
import { Vehicle } from "./types";

interface DealDropzoneProps {
  uploadCallback: (response: {
    applicant?: Partial<ApplicantInfo>;
    coApplicant?: Partial<ApplicantInfo>;
    vehicle?: Partial<Vehicle>;
    uploadedFile: { fileUrl: string; metadata: { name: string } };
  }) => void;
  applicationParserType: ApplicationParserType | null;
}

const DealDropzone = ({ uploadCallback, applicationParserType }: DealDropzoneProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dropzoneRef = useRef(null);
  const [file, setFile] = useState<File | undefined>(undefined);

  const [loading, setLoading] = useState<boolean>(false);
  const progressChange = (x: AxiosProgressEvent) => {
    Math.round((x.progress || 0) * 100);
  };

  const handleCreate = async (file: File) => {
    if (file && applicationParserType) {
      try {
        setLoading(true);
        const response = await parseApplicationFile(file, applicationParserType, progressChange);
        uploadCallback(response);
        setLoading(false);
        setFile(undefined);
        enqueueSnackbar("Document parsed successfully!", {
          variant: "success"
        });
      } catch (error: unknown) {
        setLoading(false);
        setFile(undefined);
        enqueueSnackbar("The system was not able to find information in this document!", {
          variant: "error"
        });
      }
    }
  };
  return (
    <div
      ref={dropzoneRef}
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "50px"
      }}
    >
      {loading && (
        <IoReloadCircleOutline className={"spin"} fontSize={80} style={{ color: "#6b97f6" }} />
      )}

      <div id={"application-dropzone-container"}>
        <DropZoneNoUpload
          showDropzone
          createDocument={handleCreate}
          setFile={setFile}
          containerRef={dropzoneRef}
        />

        {file ? (
          <Typography>Processing: {file.name ?? ""}</Typography>
        ) : (
          <Typography>Drag file to upload or click</Typography>
        )}
      </div>
    </div>
  );
};

export default DealDropzone;
