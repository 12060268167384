import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

export type DealsOrderRequest = {
  data: string[];
};

interface DealsOrderResponse {
  message: string;
}
const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DealsOrderRequest,
  DealsOrderResponse
>("Reorder two deals", {
  request: "deals_order"
});

export const dealsOrder = socketAction;
export const dealsOrdesActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
