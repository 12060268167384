import axios from "axios";
import { useMutation } from "@tanstack/react-query";

import { MutationError } from "Chat/types";
import { CheckCreditPrequalifyRequest, CreditPrequalify } from "types/credit_prequalify";

import { auth } from "../../firebase/firebase";

export const checkCreditPrequalify = (): ((
  request: CheckCreditPrequalifyRequest
) => Promise<CreditPrequalify>) => {
  return async (request) => {
    try {
      const token = await auth.currentUser?.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_HTTP_URL}/api/v1/credit_prequalify/`,
        request,
        {
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      );
      return response.data.result;
    } catch (err: any) {
      throw new Error(
        err?.response?.data?.message ??
          err?.response?.data?.result ??
          err?.message ??
          "Unknown error!"
      );
    }
  };
};

const useCreditPrequalify = (setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
  const mutation = useMutation<CreditPrequalify, MutationError, CheckCreditPrequalifyRequest>(
    checkCreditPrequalify(),
    {
      onSuccess: (prequalify) => {
        setIsLoading(false);
      }
    }
  );
  return {
    checkCreditPrequalify: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};
export default useCreditPrequalify;
