export default {
  status: {
    type: ["string", "null"],
    nullable: true,
    enum: ["new", "in progress", "resolved", "closed", null],
    errorMessage: {
      enum: "must be one of the following: 'New', 'In progress', 'Resolved', 'Closed'."
    }
  },
  user: {
    type: "object",
    nullable: true,
    properties: {
      firstName: {
        type: "string",
        nullable: true
      },
      lastName: {
        type: "string",
        nullable: true
      },
      email: {
        type: "string",
        nullable: true
      }
    }
  },
  url: {
    type: "string",
    nullable: true
  },
  description: {
    type: "string",
    nullable: true
  },
  deviceInfo: {
    type: "object",
    nullable: true,
    properties: {
      deviceType: {
        type: "string",
        nullable: true
      },
      osName: {
        type: "string",
        nullable: true
      },
      osVersion: {
        type: "string",
        nullable: true
      }
    }
  },
  browserInfo: {
    type: "object",
    nullable: true,
    properties: {
      browserName: {
        type: "string",
        nullable: true
      },
      browserVersion: {
        type: "string",
        nullable: true
      }
    }
  }
};
