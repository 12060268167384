import { createOptionsForSelect, FormComponent, Model } from "../../utils/models/fields";
import { Tier, Condition, DealershipProgram } from "./types";
import { StateAccess, RenderSet } from "utils/models/formGenerator";
import UserPreview from "../Users/UserPreview";

import React from "react";
import toNormalCase from "utils/toNormalCase";

const conditionsStruct: FormComponent<Condition> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "select-field",
      options: createOptionsForSelect({
        possibleValues: (stateAccess, path, mainStateAccess) => {
          const counts: { [key: string]: number } = (mainStateAccess.get(
            path.slice(0, -1)
          ) as Condition[]).reduce((acc, condition) => {
            return { ...acc, [condition.criteria]: 1 + (acc[condition.criteria] ?? 0) };
          }, {} as { [key: string]: number });
          console.log(counts);
          return ["deals count", "deals amount financed"].filter((type) => (counts[type] ?? 0) < 2);
        },
        getOptionLabel: (x) => toNormalCase(x)
      }),
      name: "criteria",
      label: "Criteria",
      width: "1/3",
      path: ["criteria"],
      default: null
    },
    {
      formComponent: "select-field",
      options: createOptionsForSelect({
        possibleValues: () => ["<=", ">="],
        getOptionLabel: (x: string | undefined) => x ?? ""
      }),
      name: "type",
      label: "Type",
      width: "1/3",
      path: ["type"],
      default: null
    },
    {
      formComponent: "number-field",
      name: "value",
      label: "Value",
      width: "1/3",
      path: ["value"],
      default: null
    }
  ]
};

const commissionsStruct: FormComponent<Tier> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "segment",
      width: "1/2",
      entities: [
        {
          formComponent: "select-field",
          options: createOptionsForSelect({
            possibleValues: () => ["fixed", "percentage"],
            getOptionLabel: (x) => x?.charAt(0).toUpperCase() + x?.slice(1),
            getSelectedOption: (x, y) => x === y
          }),
          name: "commissionType",
          label: "Commission Type",
          width: "1/2",
          path: ["commissionType"],
          default: null
        },
        {
          formComponent: "number-field",
          name: "commissionAmount",
          label: "Commission Amount",
          width: "1/2",
          path: ["commissionAmount"],
          default: null
        }
      ]
    },
    {
      formComponent: "list-model",
      name: "Conditions",
      width: "1/2",
      entity: conditionsStruct,
      path: ["conditions"],
      required: false
    }
  ]
};

export const dealershipProgramStruct: Model<DealershipProgram> = {
  formComponent: "model",
  schema: "new_dealership_program",
  name: "dealership_program",
  entities: [
    {
      formComponent: "segment",
      name: "Dealership Program",
      entities: [
        {
          formComponent: "read-only-field",
          name: "name",
          label: "Name",
          width: "full",
          path: ["data", "info", "name"],
          default: "",
          required: true
        },
        {
          formComponent: "list-model",
          name: "Tiers",
          width: "full",
          entity: commissionsStruct,
          path: ["data", "info", "tiers"],
          required: false
        }
      ]
    }
  ]
};
