import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "app/rootReducer";
import { StateAccess, RenderSet, getByPath } from "utils/models/formGenerator";
import { createOptionsForSelect, Path } from "utils/models/fields";

import { getOtherVendorList, removeOtherVendorList } from "../OtherVendors/listOtheVendorsSlice";
import { addOtherVendor } from "components/OtherVendors/addOtherVendorSlice";

import AddOtherVendor from "./AddEntitiy";
import { OtherVendor } from "components/OtherVendors/types";
import { otherVendorStruct } from "components/OtherVendors/model";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";

interface Props {
  stateAccess: StateAccess;
  path: string[];
  renderSet: RenderSet;
  name: string;
  label: string;
}

export default function OtherVendorPreview({ stateAccess, path, renderSet, name, label }: Props) {
  const dispatch = useDispatch();
  const listId = `list-autocomplete-otherVendor-${stateAccess.get(["_id"])}`;
  const otherVendorList = useSelector((state: RootState) => state.listOtherVendorSlice[listId]);

  const vendors: OtherVendor[] = stateAccess.get<OtherVendor>(path) ?? [];
  const otherVendorStateAccess: StateAccess = {
    get: (path) => getByPath<OtherVendor[]>(vendors, path as Path<OtherVendor[]>),
    set: (_, value): any => stateAccess.set<OtherVendor>(path, value)
  };
  const handleLoadVendors = () => {
    if (!otherVendorList)
      dispatch(
        getOtherVendorList(listId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } }
        })
      );
    return () => {
      dispatch(removeOtherVendorList(listId));
      return;
    };
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1}>
              {renderSet.multiSelectInputRenderer(
                {
                  formComponent: "multiselect-field",
                  required: false,
                  name,
                  label,
                  onOpen: handleLoadVendors,
                  path: [],
                  options: createOptionsForSelect({
                    possibleValues: () => otherVendorList?.entities ?? [],
                    getOptionLabel: (el) => el?.data?.info?.name,
                    getSelectedOption: (x, y) => x._id === y._id
                  }),
                  valueToString: (el) => {
                    return el?.map((el: OtherVendor) => el?.data?.info?.name).join(", ");
                  }
                },
                otherVendorStateAccess,
                [],
                otherVendorStateAccess,
                renderSet
              )}
            </Box>
            <Box>
              <AddOtherVendor
                type="other_vendor"
                addFunction={addOtherVendor}
                struct={otherVendorStruct}
                addSlice={"addOtherVendorSlice"}
                setSelected={(vendor) => {
                  stateAccess.set(path as [], [...(stateAccess.get(path) || []), vendor]);
                }}
                renderSet={renderSet}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
