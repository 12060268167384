import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import { RootState } from "app/rootReducer";
import { getUserList, removeUserList } from "components/Users/listUserSlice";
import { User } from "components/Users/types";
import { useStickyState } from "index";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFirstAndLastDateOfGivenYearAndMonth } from "utils/functions";
import DashboardDeals from "../DashboardDeals";
import CountByProductsChart from "../FIManager/CountByProductsChart ";
import CountChart from "../FIManager/CountChart";
import PVRAndCommission from "../FIManager/PVRAndCommission";
import SumChart from "../FIManager/SumChart";
import TotalCommission from "../FIManager/TotalCommission";
import { monthNames } from "../common";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function AccountantDashboard({
  setResetStickyCallback
}: {
  setResetStickyCallback?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}) {
  const tableName = `accountantDeals`;
  const [resetStickyTableCallback, setResetStickyTableCallback] = React.useState<() => void>();
  const usersListId = "accountant-dashboard-users";
  const userList = useSelector((state: RootState) => state.listUserSlice[usersListId]);
  const [selectedUsers, setSelectedUsers] = useStickyState<User[]>(
    userList?.entities ?? [],
    "accountant_dashboard_selectedUsers"
  );
  const dispatch = useDispatch();
  const [selectedMonth, setSelectedMonth] = useStickyState(
    new Date().getMonth(),
    "accountant_dashboard_selectedMonth"
  );
  const [selectedYear, setSelectedYear] = useStickyState(
    new Date().getFullYear(),
    "accountant_dashboard_selectedYear"
  );
  const { from, to } = getFirstAndLastDateOfGivenYearAndMonth(selectedYear, selectedMonth + 1);
  React.useEffect(() => {
    loadUsers();
  }, []);
  React.useEffect(() => {
    if (userList && !selectedUsers?.length) {
      setSelectedUsers(userList?.entities ?? []);
    }
  }, [userList]);
  const loadUsers = () => {
    if (!userList) {
      dispatch(
        getUserList(usersListId, {
          options: {
            pagination: false,
            projection: {
              _id: 1,
              "data.info.firstName": 1,
              "data.info.lastName": 1,
              "data.info.userType": 1,
              "data.statistics": 1
            },
            sort: { "data.info.firstName": "asc" }
          },
          query: {
            "data.info.userType": "f&i manager"
          }
        })
      );
    }
    return () => {
      dispatch(removeUserList(usersListId));
    };
  };
  const handleResetStickyState = () => {
    setSelectedUsers(userList?.entities ?? []);
    setSelectedMonth(new Date().getMonth());
    setSelectedYear(new Date().getFullYear());
    resetStickyTableCallback?.();
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, [resetStickyTableCallback]);
  return (
    <>
      {setResetStickyCallback === undefined && (
        <Button
          onClick={() => handleResetStickyState()}
          style={{ margin: "5px 0px", float: "right" }}
          color="primary"
          variant="contained"
        >
          Reset state
        </Button>
      )}
      <Grid style={{ marginTop: "4px" }} container spacing={1}>
        <Grid item xs={12} style={{ margin: "5px 0" }}>
          <Paper elevation={3}>
            <div
              style={{
                fontWeight: "500",
                display: "inline-flex",
                padding: "0px 14px",
                marginRight: "10px",
                verticalAlign: "middle",
                alignItems: "center",
                background: "rgb(224 224 224)",
                borderRadius: "4px 0px 0px 4px "
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    indeterminate={
                      selectedUsers?.length > 0 &&
                      selectedUsers?.length !== userList?.entities?.length
                    }
                    checked={selectedUsers?.length === userList?.entities?.length}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectedUsers(userList?.entities ?? []);
                      } else {
                        setSelectedUsers([]);
                      }
                    }}
                    name="All"
                    color="primary"
                  />
                }
                label={`All`}
              />
            </div>

            {userList?.entities?.map((user, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectedUsers((previousSelectedUsers) => [
                          ...previousSelectedUsers,
                          user
                        ]);
                      } else {
                        setSelectedUsers((previousSelectedUsers) =>
                          previousSelectedUsers.filter((prevUser) => prevUser._id !== user._id)
                        );
                      }
                    }}
                    checked={selectedUsers.findIndex((prevUser) => prevUser._id === user._id) > -1}
                    name={`${user?.data?.info?.firstName} ${user?.data?.info?.lastName}`}
                    color="primary"
                  />
                }
                label={`${user?.data?.info?.firstName} ${user?.data?.info?.lastName}`}
              />
            ))}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <CountChart
            from={from}
            to={to}
            userIds={(selectedUsers ?? [])?.map((user) => user?._id)}
          />
        </Grid>
        <Grid item xs={4}>
          <SumChart
            showNumbers={true}
            from={from}
            to={to}
            userIds={(selectedUsers ?? [])?.map((user) => user?._id)}
          />
        </Grid>
        <Grid item xs={4}>
          {selectedUsers.length === 1 ? (
            <PVRAndCommission showNumbers={true} from={from} to={to} user={selectedUsers[0]} />
          ) : (
            <TotalCommission showNumbers={true} from={from} to={to} users={selectedUsers ?? []} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Additional statistics</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid style={{ marginTop: "4px" }} container spacing={1}>
                <Grid item xs={4}>
                  <CountByProductsChart
                    from={from}
                    to={to}
                    userIds={(selectedUsers ?? [])?.map((user) => user?._id)}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={2} style={{ margin: "5px 0" }}>
          <Grid container spacing={1}>
            {[
              new Date().getFullYear() - 3,
              new Date().getFullYear() - 2,
              new Date().getFullYear() - 1,
              new Date().getFullYear()
            ].map((year, index) => (
              <Grid key={index} item xs={3}>
                <Button
                  onClick={() => setSelectedYear(year)}
                  variant={"contained"}
                  size={"small"}
                  style={{ width: "100%" }}
                  color={year === selectedYear ? "primary" : "default"}
                >
                  {year}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={10} style={{ margin: "5px 0" }}>
          <Grid container spacing={1}>
            {monthNames.map((month, index) => (
              <Grid key={index} item xs={1}>
                <Button
                  onClick={() => setSelectedMonth(index)}
                  disabled={
                    index > new Date().getMonth() && selectedYear === new Date().getFullYear()
                  }
                  variant={"contained"}
                  size={"small"}
                  style={{ width: "100%" }}
                  color={index === selectedMonth ? "primary" : "default"}
                >
                  {month}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {Array.isArray(selectedUsers) && selectedUsers?.length > 0 && (
          <Grid item xs={12}>
            <DashboardDeals
              setResetStickyCallback={setResetStickyTableCallback}
              tableName={tableName}
              showNumbers={true}
              from={from}
              to={to}
              type="accountant"
              userIds={selectedUsers.map((user) => user._id)}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
