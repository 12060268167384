import { createListSlice } from "../Middlewares/listSliceCreator";
import { PayoffBank } from "./types";

const { ListSlice, getList } = createListSlice<PayoffBank>({
  name: "PayoffBankList",
  request_topic: "all_payoff_banks",
  reducers: {}
});

export default ListSlice.reducer;
export const getPayoffBankList = getList;
export const removePayoffBankList = ListSlice.actions.removeList;
export const listPayoffBankActions = ListSlice.actions;
