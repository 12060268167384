import { createListSlice } from "../Middlewares/listSliceCreator";
import { DealershipStatistic } from "./types";

const { ListSlice, getList } = createListSlice<DealershipStatistic>({
  name: "DealershipStatisticList",
  request_topic: "all_dealership_statistics",
  reducers: {}
});
export default ListSlice.reducer;
export const getDealershipStatisticList = getList;
export const lisDealershipStatisticActions = ListSlice.actions;
export const removeDealershipStatisticList = ListSlice.actions.removeList;
