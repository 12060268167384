import Grid from "@material-ui/core/Grid";
import { RootState } from "app/rootReducer";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOptionsForSelect } from "utils/models/fields";
import { StateAccess } from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";
import { getDealershipList, removeDealershipList } from "../Dealerships/listDealershipSlice";

export default function OldDealershipFilter<T extends unknown>({ setFilters, path }: any) {
  const dispatch = useDispatch();
  const listId = `list-dealership-filter-old`;
  const dealershipList = useSelector((state: RootState) => state.listDealershipSlice[listId]);

  const handleOpen = () => {
    if (!dealershipList)
      dispatch(
        getDealershipList(listId, {
          options: { pagination: false, sort: { "data.info.displayName": "asc" } }
        })
      );
    return () => {
      dispatch(removeDealershipList(listId));
      return;
    };
  };
  const [chosen, setChosen] = useState();
  const stateAccess: StateAccess = {
    get: (path) => chosen,
    set: (_, value): any => {
      setChosen(value);

      setFilters((filters: any) => {
        if (value?._id !== undefined) {
          return { ...filters, [path.join(".")]: value?._id };
        } else {
          const { [path.join(".")]: _, ...Rest } = filters;
          return Rest;
        }
      });
    }
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {editRenderSet(false).selectInputRenderer(
          {
            formComponent: "select-field",
            name: "dealership",
            label: "Dealership",
            required: false,
            path: [] as [],
            onOpen: handleOpen,
            options: createOptionsForSelect({
              possibleValues: () => dealershipList?.entities ?? [],
              getOptionLabel: (dealership) => dealership?.data?.info?.displayName,
              getSelectedOption: (x, y) => x?._id === y?._id
            }),
            valueToString: (dealership) => dealership?.data?.info?.displayName
          },
          stateAccess,
          [],
          stateAccess,
          editRenderSet(false)
        )}
      </Grid>
    </Grid>
  );
}
