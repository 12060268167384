import { Chip } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import { File } from "components/Files/types";
import React from "react";
import { useSelector } from "react-redux";
import { FormComponent } from "utils/models/fields";
import { generateForm, getByPath, RenderSet, StateAccess } from "utils/models/formGenerator";
import { Contract } from "./types";

const dates: FormComponent<Contract> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      width: "3/4",
      entities: [
        {
          formComponent: "read-only-date-field",
          name: "signedDate",
          label: "Signed date",
          required: true,
          width: "1/4",
          path: ["signedDate"]
        },
        {
          formComponent: "read-only-date-field",
          name: "startDate",
          required: true,
          label: "Start date",
          width: "1/4",
          path: ["startDate"]
        },
        {
          formComponent: "read-only-date-field",
          name: "endDate",
          label: "End date",
          width: "1/4",
          path: ["endDate"]
        },
        {
          formComponent: "duration-field",
          name: "durationTime",
          label: "Duration time in years",
          width: "1/4",
          start: ["startDate"],
          end: ["endDate"],
          path: [],
          valueToString: (el) => el?.toString()
        }
      ]
    },
    {
      formComponent: "one-to-many-field",
      path: ["status"],
      name: "Status",
      label: "Status",
      width: "1/4",
      component: (stateAccess: StateAccess) => {
        const status = stateAccess.get(["status"]);
        return status ? (
          <Chip
            label={stateAccess.get(["status"])}
            style={{
              backgroundColor: stateAccess.get(["status"]) === "Active" ? "#50a538" : "#f44336",
              color: "#fff",
              fontWeight: "bold",
              fontSize: "12px"
            }}
          />
        ) : (
          <></>
        );
      }
    }
  ]
};
export const getLatestContractFile = (files: File[] | undefined) => {
  return files?.reduce((acc: File | undefined, curr: File) => {
    const signedDate = curr?.data?.info?.contractDates?.signedDate;
    const accSignedDate = acc?.data?.info?.contractDates?.signedDate;
    if (!acc) return curr;
    return accSignedDate &&
      signedDate &&
      new Date(signedDate).getTime() > new Date(accSignedDate).getTime()
      ? curr
      : acc;
  }, undefined);
};
export const getLatestActiveContractFile = (files: File[] | undefined) => {
  return files?.reduce((acc: File | undefined, curr: File) => {
    const signedDate = curr?.data?.info?.contractDates?.signedDate;
    const accSignedDate = acc?.data?.info?.contractDates?.signedDate;
    if (!acc && curr.data.info.status === "Active") return curr;
    return accSignedDate &&
      signedDate &&
      curr.data.info.status === "Active" &&
      new Date(signedDate).getTime() > new Date(accSignedDate).getTime()
      ? curr
      : acc;
  }, undefined);
};
export default function ContractDatesPreview({
  stateAccess,
  renderSet
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) {
  const datesList = useSelector(
    (state: RootState) => state.listFileSlice[stateAccess.get(["_id"])]
  );
  const lastestContract = getLatestContractFile(datesList?.entities);

  const datesStateAccess: StateAccess = {
    get: (path) =>
      getByPath(
        {
          ...lastestContract?.data?.info?.contractDates,
          status: lastestContract?.data?.info?.status
        } ?? {},
        path
      ),
    set: (): any => {}
  };

  return <>{generateForm(dates, datesStateAccess, [], datesStateAccess, renderSet)}</>;
}
