import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Badge, { BadgeProps } from "@material-ui/core/Badge";
const useStyles = makeStyles((theme: Theme) => ({
  customBadge: (props: { backgroundColor: string }) => ({
    backgroundColor: props.backgroundColor,
    color: "white"
  })
}));
interface StyledBadgeProps extends Omit<BadgeProps, "color"> {
  color: string;
}
export default ({ color, children, ...badgeProps }: StyledBadgeProps) => {
  const classes = useStyles({ backgroundColor: color });

  return (
    <Badge
      classes={{ badge: classes.customBadge }}
      badgeContent={badgeProps.badgeContent}
      variant={badgeProps.variant}
    >
      {children}
    </Badge>
  );
};
