import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AccessControl from "components/Access/AccessControl";
import AddDealByDocument from "components/Deals/AddDealByDocument";
import { TabContext } from "components/Layout/LayoutWrapper";
import CloseDialogButton from "components/common/CloseDialogButton";
import { HintTooltip } from "components/common/HintTooltip";
import React, { Dispatch, useContext } from "react";
import { EntityData, EntityType } from "utils/entitySlice";
export type AvailableEntityTypes = Exclude<EntityType, "sequence">;
export default ({
  entityName,
  addEntityFromDocumentFunction
}: {
  entityName: AvailableEntityTypes;
  addEntityFromDocumentFunction?: (state: object) => void;
}) => {
  const createOrFocusTab = useContext(TabContext);
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<any | undefined>();

  return (
    <AccessControl
      key={`add-${entityName}-button`}
      requiredPermissions={{ entity: entityName, action: "create" }}
    >
      <Dialog
        id="add-entity-by-document"
        open={open}
        maxWidth={"xl"}
        fullWidth
        onClose={() => {
          setState({});
          setOpen(false);
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Add {entityName} from a document
          <CloseDialogButton
            closeFunction={() => {
              setOpen(false);
              setState({});
            }}
          />
        </DialogTitle>
        <DialogContent>{renderByEntity(entityName, state, setState)}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setState({});
            }}
            variant="contained"
            color="primary"
            id="cancel"
          >
            Cancel
          </Button>
          <Button
            //@ts-ignore
            disabled={!(state?.dealership && state?.applicant)}
            onClick={() => {
              if (state) {
                if (addEntityFromDocumentFunction) {
                  addEntityFromDocumentFunction(state);
                } else {
                  createOrFocusTab({
                    label: "Add page",
                    index: "addPage",
                    isForSidebar: false,
                    isForQuickAccess: false,
                    isPersistent: false,
                    props: { type: entityName }
                  });
                }
              }
            }}
            variant="contained"
            color="secondary"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Box component="span">
        <HintTooltip title="Add new entry from a document">
          <IconButton
            aria-label={`add-from-document-${entityName}`}
            id={`add-from-document-${entityName}`}
            onClick={() => setOpen(true)}
          >
            <PostAddIcon />
          </IconButton>
        </HintTooltip>
      </Box>
    </AccessControl>
  );
};

const renderByEntity = <T extends AvailableEntityTypes>(
  entity: T,
  state: any | undefined,
  set: React.Dispatch<object | undefined>
) => {
  switch (entity) {
    case "deal": {
      return (
        <AddDealByDocument
          setState={set as React.Dispatch<React.SetStateAction<object>>}
          state={state}
        />
      );
    }
    default:
      return null;
  }
};
