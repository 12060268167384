import { createListSlice } from "../Middlewares/listSliceCreator";
import { User } from "./types";

const { ListSlice, getList } = createListSlice<User>({
  name: "WfdUserList",
  request_topic: "all_wfd_users",
  reducers: {}
});

export default ListSlice.reducer;
export const getUserList = getList;
export const listUserActions = ListSlice.actions;
export const removeUserList = ListSlice.actions.removeList;
