import React, { useState } from "react";
import { StateAccess, RenderSet, getByPath } from "utils/models/formGenerator";
import { createOptionsForSelect, Path } from "utils/models/fields";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { getUserList, removeUserList } from "../Users/listUserSlice";
import { v4 as uuidv4 } from "uuid";
import { User } from "components/Users/types";
import { Contract } from "components/Contracts/types";

interface Props {
  stateAccess: StateAccess;
  path: Path<Contract>;
  renderSet: RenderSet;
  name: string;
  label: string;
  outerQuery?: {};
}

export default function UsersPreview({
  stateAccess,
  path,
  renderSet,
  name,
  label,
  outerQuery
}: Props) {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const listId = `list-autocomplete-users-${requestId}`;
  const userList = useSelector((state: RootState) => state.listUserSlice[listId]);

  const users: User[] = stateAccess.get<Contract>(path) ?? [];
  const usersStateAccess: StateAccess = {
    get: (path) => getByPath<User[]>(users, path as Path<User[]>),
    set: (_, value): any => stateAccess.set<Contract>(path, value)
  };

  const handleLoadUsers = () => {
    if (!userList)
      dispatch(
        getUserList(listId, {
          query: outerQuery ?? {},
          options: { pagination: false, sort: { "data.info.name": "asc" } }
        })
      );
    return () => {
      dispatch(removeUserList(listId));
      return;
    };
  };

  return (
    <>
      {renderSet.multiSelectInputRenderer(
        {
          formComponent: "multiselect-field",
          name,
          label,
          path: [],
          onOpen: handleLoadUsers,
          options: createOptionsForSelect({
            possibleValues: () => userList?.entities ?? [],
            getOptionLabel: (user) => {
              return (
                `${user?.data?.info?.firstName ?? ""} ${user?.data?.info?.lastName ?? ""}` ?? ""
              );
            },
            getSelectedOption: (x, y) => {
              return x._id === y._id;
            }
          }),
          valueToString: (el) => {
            return el
              .map(
                (el: User) => `${el?.data?.info?.firstName ?? ""} ${el?.data?.info?.lastName ?? ""}`
              )
              .join(", ");
          }
        },
        usersStateAccess,
        [],
        usersStateAccess,
        renderSet
      )}
    </>
  );
}
