import React, { useEffect, useState } from "react";
import { StateAccess, RenderSet } from "utils/models/formGenerator";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { getUserList, removeUserList } from "./listUserSlice";
import { User } from "./types";
import EntitySearch from "components/Deals/EntitySearch";
import { Deal } from "components/Deals/types";
import { Path } from "utils/models/fields";
import { Dealership } from "components/Dealerships/types";
import { ListState } from "components/Middlewares/listSliceCreator";

interface Props<T> {
  stateAccess: StateAccess;
  path: Path<T>;
  renderSet: RenderSet;
  name: string;
  label: string;
  type?: string;
  style?: object;
  lockEntity?: () => void;
  
}

export default function UserIdPreview<T extends Deal | User | Dealership>({
  stateAccess,
  path,
  renderSet,
  name,
  label,
  type,
  lockEntity,
  style
}: Props<T>) {
  const dispatch = useDispatch();
  const listId = `${name}-users-autocomplete`;
  const userList = useSelector((state: RootState) => state.listUserSlice[listId]);
  const options = { pagination: false, sort: { "data.info.firstName": "asc" } };
  const userStateAccess: StateAccess = {
    get: (path) => stateAccess.get<T>(path),
    set: (_, value): any => {
      stateAccess.set<T>(path, value);
    }
  };

  const [query] = useState({
    ...(type ? { "data.info.userType": type } : {}),
    deleted: false
  });

  useEffect(() => {
    if (!userList) {
      dispatch(
        getUserList(listId, {
          query,
          options
        })
      );
    }
    return () => {
      dispatch(removeUserList(listId));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatName = (id: any) => {
    const user = userList?.entities?.find((x) => x._id === id);
    return user
      ? [user?.data?.info?.firstName, user?.data?.info?.lastName]
          .filter((name) => name !== undefined)
          .join(" ")
      : "";
  };

  return (
    <EntitySearch
      lockEntity={lockEntity}
      renderSet={renderSet}
      stateAccess={userStateAccess}
      parentStateAccess={stateAccess}
      id={listId}
      getSelectedOption={(x, y) => {
        return x === y;
      }}
      name={name}
      getFunction={getUserList}
      removeFunction={removeUserList}
      path={["criteria"] as any}
      label={label}
      entityList={{ entities: userList?.entities?.map((x) => x._id) } as ListState<T>}
      formatLabel={formatName}
      query={query}
      options={options}
      style={style}
    />
  );
}
