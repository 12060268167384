import { RootState } from "app/rootReducer";
import DateFilter from "components/Filters/DateFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import TextFilter from "components/Filters/TextFilter";
import React from "react";
import { useSelector } from "react-redux";
import formatDate from "utils/formatDate";
import { capitalize } from "utils/functions";
import AccessControl from "../Access/AccessControl";
import Table, { CellValue, Column } from "../Table";
import { getCreditScoreList } from "./listCreditScoreSlice";
import { CreditBureauData, CreditCheckTypes } from "./types";
export const projections = {
  "data.request.NAME": 1,
  "data.request.SPOUSE": 1,
  "data.type": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};

export default function CreditScores(): JSX.Element {
  const creditScoreList = useSelector((state: RootState) => state.listCreditScoreSlice["table"]);

  const columns: Column<CreditBureauData>[] = [
    {
      getData: (entry): CellValue => capitalize(entry?.data?.type) ?? "",
      label: "Type",
      name: "type",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.type?.show;
      },
      filters: [
        {
          path: ["data", "type"],
          preview: MultiSelectFilter,
          valuesForSelect: [
            CreditCheckTypes.Applicant,
            CreditCheckTypes.CoApplicant,
            CreditCheckTypes.Joint
          ],
          optionLabelForSelect: (creditCheckType) => {
            switch (creditCheckType) {
              case "applicant":
                return "Applicant";
              case "coApplicant":
                return "Co-Applicant";
              case "joint":
                return "Joint";
              default:
                return "";
            }
          }
        }
      ]
    },
    {
      getData: (entry): CellValue => `${entry?.data?.request?.NAME}`,
      label: "Applicant",
      name: "applicant",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.applicant?.show;
      },
      filters: [
        {
          path: ["data", "request", "NAME"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        ` ${entry?.data?.request?.SPOUSE ? entry?.data?.request?.SPOUSE : ""} `,
      label: "Co Applicant",
      name: "coApplicant",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.coApplicant?.show;
      },
      filters: [
        {
          path: ["data", "request", "SPOUSE"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },

    {
      getData: (entry): CellValue => `${formatDate(entry?.createdAt, "short", true)}`,
      label: "Created At",
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter
        }
      ]
    },
    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={{ entity: "role", action: "read" }}>
        <Table
          tableName="credit_scores"
          entityName="credit_score"
          listFunction={getCreditScoreList}
          listEntity={creditScoreList}
          title={"Credit Bureaus"}
          columns={columns}
          projection={projections}
          query={{ "data.source": "700Credit" }}
          sort={{ createdAt: "desc" }}
        />
      </AccessControl>
    </div>
  );
}
