import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { auth } from "../../firebase/firebase";
import { MutationError } from "Chat/types";
import { NotifyLenderNotesRequest } from "./types";

export const notifyLenderNotes = (): ((request: NotifyLenderNotesRequest) => Promise<string>) => {
  return async (request) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/notify_lender_notes/`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return response.data.result;
  };
};

export const useNotifyLenderNotes = () => {
  const mutation = useMutation<string, MutationError, NotifyLenderNotesRequest>(
    notifyLenderNotes(),
    {
      onSuccess: () => {
        return;
      }
    }
  );
  return {
    notifyLenderNotes: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};
export default useNotifyLenderNotes;
