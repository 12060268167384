import { Button, Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";
import Deals, {
  getStyleByStatus,
  statusToChipTooltip,
  statusToLabel
} from "components/Deals/Deals";
import { DealData, DealStatus } from "components/Deals/types";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DateFilter from "components/Filters/DateFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import PriceFilter from "components/Filters/PriceFilter";
import TextFilter from "components/Filters/TextFilter";
import UserFilter from "components/Filters/UserFilter";
import { Lender } from "components/Lenders/types";
import { CellValue, Column, Entry } from "components/Table";
import { LenderAllocationStatisticsResponse } from "hooks/useLenderAllocationStatistics/types";
import React, { useState } from "react";
import { sumNumbers } from "utils/calcReportTotal";
import { formatNumberAsCurrency, parseMonth } from "utils/functions";
import DealsModal from "../Lender/DealsModal";

function totalsFunction<T>(entities: Entry<T>[], column: Column<T>): string {
  return `Total: ${sumNumbers(entities, column)}`;
}
const columns: Column<DealData>[] = [
  {
    getData: (entry): CellValue =>
      [
        entry.data?.applicant
          ? `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
              entry.data?.applicant?.data?.info?.lastName ?? ""
            }`
          : undefined,
        entry.data?.coApplicant
          ? `${entry.data?.coApplicant?.data?.info?.firstName ?? ""} ${
              entry.data?.coApplicant?.data?.info?.lastName ?? ""
            }`
          : undefined
      ]
        .filter((x) => x)
        .join(" / "),
    label: "Customer",
    name: "customer",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.customer?.show;
    },
    filters: [
      {
        path: [
          ["data", "applicant", "data", "info", "firstName"],
          ["data", "applicant", "data", "info", "lastName"],
          ["data", "coApplicant", "data", "info", "firstName"],
          ["data", "coApplicant", "data", "info", "lastName"]
        ],
        preview: ApplicantFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.refNumber,
    label: "Ref #",
    options: {
      sort: true,
      path: ["data", "info", "refNumber"]
    },
    name: "refNumber",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.refNumber?.show;
    },
    filters: [
      {
        path: ["data", "info", "refNumber"],
        preview: TextFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.dealership?.data?.info?.displayName ?? "",
    label: "Dealership",
    name: "dealership",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealership?.show;
    },
    filters: [
      {
        path: ["data", "dealershipId"],
        preview: DealershipFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => `${entry.data?.dealership?.data?.info?.state ?? ""}`,
    label: "Dealer state",
    name: "dealerState",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealerState?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "info", "state"],
        preview: TextFilter,
        partialSearch: true,
        caseInsensitive: true
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
    label: "Make",
    name: "make",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.make?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "make"],
        preview: TextFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
    label: "Model",
    name: "model",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.model?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "model"],
        preview: TextFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.VIN,
    label: "VIN",
    name: "vin",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.vin?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "VIN"],
        preview: TextFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
    label: "Lender",
    name: "lender",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.lender?.show;
    },
    filters: [
      {
        path: ["data", "lenderId"],
        preview: LenderFilter
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      `${entry.data?.user?.data?.info?.firstName ?? ""} ${
        entry.data?.user?.data?.info?.lastName ?? ""
      }`,
    label: "F&I mgr",
    name: "f&iManager",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.["f&iManager"]?.show;
    },
    filters: [
      {
        path: ["data", "userId"],
        preview: UserFilter,
        type: "f&i manager"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      `${entry.data?.dealership?.data?.representative?.data?.info?.firstName ?? ""} ${
        entry.data?.dealership?.data?.representative?.data?.info?.lastName ?? ""
      }`,
    label: "Rep",
    name: "rep",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.rep?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "representativeId"],
        preview: UserFilter,
        type: "representative"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.contractDate
        ? new Date(entry.data?.info?.dealDates?.contractDate)
        : "",
    label: "Contract",
    options: {
      sort: true,
      path: ["data", "info", "dates", "contractDate"]
    },
    name: "contractDate",
    show: (_userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.contractDate?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "contractDate"],
        preview: DateFilter,
        label: "Contract Date"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.status,
    label: "Status",
    options: {
      customBodyRender: (x: CellValue, deal): JSX.Element | string => {
        const dealStatus = x as DealStatus;
        return statusToChipTooltip(dealStatus, deal, getStyleByStatus(dealStatus));
      },
      sort: true,
      path: ["data", "info", "status"]
    },
    name: "status",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.status?.show;
    },
    filters: [
      {
        path: ["data", "info", "status"],
        preview: MultiSelectFilter,
        valuesForSelect: Object.values(DealStatus),
        optionLabelForSelect: (status) => statusToLabel(status)
      }
    ]
  },

  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.submittedForFunding
        ? new Date(entry.data?.info?.dealDates?.submittedForFunding)
        : "",
    label: "Submitted at",
    options: {
      sort: true,
      path: ["data", "info", "dates", "submittedForFunding"]
    },
    name: "fundedAt",
    show: () => {
      return true;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "submittedForFunding"],
        preview: DateFilter,
        label: "Submitted for funding"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.payment?.dealTotal ?? 0,
    label: "Amount Financed",
    total: totalsFunction,
    name: "amountFinanced",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.amountFinanced?.show;
    },
    filters: [
      {
        path: ["data", "info", "payment", "dealTotal"],
        preview: PriceFilter,
        name: "amountFinanced",
        label: "Amount Financed"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.payment?.fundedAmount ?? 0,
    label: "Funded",
    name: "fundedAmount",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.fundedAmount?.show;
    },
    total: totalsFunction,
    options: {
      sort: true,
      path: ["data", "info", "payment", "fundedAmount"]
    },
    filters: [
      {
        path: ["data", "info", "payment", "fundedAmount"],
        preview: PriceFilter
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.fundedAt ? new Date(entry.data?.info?.dealDates?.fundedAt) : "",
    label: "Funded At",
    options: {
      sort: true,
      path: ["data", "info", "dealDates", "fundedAt"]
    },
    name: "fundedAt",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.fundedAt?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "fundedAt"],
        preview: DateFilter,
        label: "Funded At"
      }
    ]
  },
  {
    getData: (entry): CellValue => (entry?.createdAt ? new Date(entry?.createdAt) : ""),
    label: "Created At",
    options: {
      sort: true,
      path: ["createdAt"]
    },
    name: "createdAt",
    show: (userPermissions, tableSettings) => {
      return true;
    },
    filters: [
      {
        path: ["createdAt"],
        preview: DateFilter,
        label: "Created At"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry._id,
    label: "Actions",
    name: "actions",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.actions?.show;
    }
  }
];
export default ({ dealIds }: { dealIds: string[] | undefined }) => {
  const [showDealsModal, setShowDealsModal] = useState(false);
  const closeFunction = () => setShowDealsModal(false);
  return (
    <>
      <Grid item md={12} xs={12} style={{ marginTop: 5 }}>
        <Button
          color="primary"
          style={{ width: "100%" }}
          variant="contained"
          onClick={() => setShowDealsModal(true)}
        >
          deals ({dealIds?.length})
        </Button>
      </Grid>
      {showDealsModal ? (
        <Dialog
          id="lender-statistics-deals-dialog"
          onClose={closeFunction}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle id="customized-dialog-title">Deals</DialogTitle>
          <CloseDialogButton closeFunction={closeFunction} />
          <DialogContent>
            <Deals query={{ _id: { $in: dealIds } }} />
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
};
