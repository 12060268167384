import { Button } from "@material-ui/core";
import { collateralTypes } from "components/Deals/types";
import React from "react";
import { StateAccess } from "utils/models/formGenerator";
import { OperatesInStateStatus, OperatingStates } from "./types";

export default function OperatingStatesSetter({ stateAccess }: { stateAccess: StateAccess }) {
  const states: OperatingStates[] =
    stateAccess.get(["data", "info", "operatingStates", "states"]) ?? [];
  const supportedCollateralTypes = stateAccess.get(["data", "info", "collateralTypes"]);

  const handleSetCollateralTypes = () => {
    const supportedStates = getSupportedCollateralTypes(
      supportedCollateralTypes,
      states.map((s) => s.state)
    );

    stateAccess.set(["data", "info", "operatingStates", "states"], supportedStates);
  };

  if (states && states.length > 0)
    return (
      <Button variant="contained" color="primary" onClick={handleSetCollateralTypes}>
        Copy Collateral Types
      </Button>
    );

  return <></>;
}

export const getSupportedCollateralTypes = (
  supportedCollateralTypes: Record<typeof collateralTypes[number], boolean>,
  states: string[]
): OperatingStates[] => {
  return states.map((s) => ({
    state: s,
    status: OperatesInStateStatus.ACTIVE,
    collateralTypes: supportedCollateralTypes
  }));
};
