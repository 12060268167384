import React, { useState, useEffect } from "react";

import { StateAccess, getByPath, setByPath } from "utils/models/formGenerator";
import UserPreview from "components/Users/UserPreview";
import { User } from "components/Users/types";
import { showRenderSet } from "utils/models/formRenderers";
import { useDispatch, useSelector } from "react-redux";
import { editRenderSet } from "utils/models/formRenderers";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "app/rootReducer";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { IconButton } from "@material-ui/core";
import { lockEntityFunction, unlockEntityFunction } from "utils/models/ShowForm";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import { Dealership } from "components/Dealerships/types";
import { editPartialDealership } from "components/Dealerships/editDealershipSlice";

export default function ({ dealership }: { dealership: Dealership }) {
  const { user } = useSelector((state: RootState) => state.authSlice);
  const [requestId] = useState(uuidv4());
  const [data] = useState(dealership);

  const [initialState, setInitialState] = useState<boolean | undefined>(
    dealership?.data?.info?.activityWarning?.warning
  );
  const [formState, setFormState] = useState(initialState);
  const editDealershipState = useSelector(
    (state: RootState) => state?.editDealershipSlice[requestId]
  );
  const lockData = useSelector((state: RootState) => state.listLockSlice["all"])?.entities?.find(
    (lock) =>
      lock?.data?.info?.entityId === dealership?._id &&
      lock?.data?.info?.entityName === "dealership"
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const dealershipId = dealership?._id;
  const currentlyLockedEmail = lockData?.data?.info?.userEmail ?? null;
  const canEdit = user?.email === currentlyLockedEmail;

  const isLocked = currentlyLockedEmail !== null && !canEdit;

  useEffect(() => {
    if (editDealershipState?.status === "success") {
      unlockEntityFunction(dispatch, dealershipId as string, "dealership");
    }
  }, [editDealershipState, dealershipId, dispatch]);
  useEffect(() => {
    if (canEdit || initialState !== dealership?.data?.info?.activityWarning?.warning) {
      setFormState(dealership?.data?.info?.activityWarning?.warning);
      setInitialState(dealership?.data?.info?.activityWarning?.warning);
    }
  }, [dealership?.data?.info?.activityWarning, canEdit, initialState]);

  useEffect(() => {
    if (
      formState !== undefined &&
      initialState !== formState &&
      editDealershipState?.status !== "waiting" &&
      (canEdit || currentlyLockedEmail === null)
    ) {
      dispatch(
        editPartialDealership({
          _id: dealershipId as string,
          ...{
            data: {
              info: {
                activityWarning: {
                  warning: false,
                  checked: true,
                  checkedOrSnoozedDate: new Date().toISOString()
                }
              }
            }
          },
          requestId
        })
      );

      setInitialState(formState);
    }
  }, [
    formState,
    dispatch,
    requestId,
    canEdit,
    initialState,
    data,
    editDealershipState,
    dealershipId
  ]);
  const handleClick = () => {
    if (isLocked) setOpen(true);
    else if (canEdit || currentlyLockedEmail === null) {
      lockEntityFunction(dispatch, dealershipId as string, "dealership");
      setFormState(!formState);
    }
  };

  const handleClickUnlock = () => {
    lockEntityFunction(dispatch, dealershipId as string, "dealership");
    setFormState(!formState);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        id="confirm-delete-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to unlock this dealership?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {lockData?.updatedAt &&
              `Locked by ${lockData?.data?.info?.userEmail}
       ${new Intl.DateTimeFormat("en", {
         day: "2-digit",
         month: "2-digit",
         year: "2-digit",
         hour: "2-digit",
         minute: "2-digit",
         second: "2-digit"
       }).format(new Date(lockData?.updatedAt))}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary" id="cancel">
            Cancel
          </Button>
          <Button
            onClick={handleClickUnlock}
            variant="contained"
            color="secondary"
            autoFocus
            id="confirm"
          >
            Unlock
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ position: "relative" }}>
        <div style={{ textAlign: "center", padding: "10px" }}>
          <Button
            disabled={
              dealership?.data?.info?.activityWarning?.warning !== true ||
              dealership?.data?.info?.activityWarning?.snoozeType !== undefined
            }
            color="primary"
            variant="contained"
            onClick={() => {
              handleClick();
            }}
          >
            Mark as checked
          </Button>
        </div>
      </div>
    </>
  );
}
