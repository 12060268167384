import { VehicleInsuranceCompany } from "./types";
import { permissionNode, relationPermissionNode, PermissionStruct } from "components/Roles/types";

export type VehicleInsuranceCompanyPermissionsStruct = PermissionStruct<VehicleInsuranceCompany>;

export const vehicleInsuranceCompanyPermissionStruct: VehicleInsuranceCompanyPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      name: permissionNode("Name")
    }
  }
};
