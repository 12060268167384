import { Box } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { RootState } from "app/rootReducer";
import { DealData } from "components/Deals/types";
import OldDealershipFilter from "components/Filters/OldDealershipFilter";
import OldLenderFilter from "components/Filters/OldLenderFilter";
import OldUserFilter from "components/Filters/OldUserFilter";
import { FilterQuery } from "components/Filters/types";
import OldFilters from "components/Table/OldFilters";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, formatNumberAsCurrency } from "utils/functions";
import AccessControl from "../../Access/AccessControl";
import { calculate } from "./calculation";
import { getIncomeReport } from "./getIncomeReportSlice";
import { IncomeValues, Month, Years } from "./types";

const tableRows: { name: string; get: (x: Month | IncomeValues) => number | string }[] = [
  {
    name: "Amount financed",
    get: (x) => formatNumberAsCurrency(x.amountFinanced.toNumber(), " $") ?? ""
  },
  {
    name: "Total web income",
    get: (x) => formatNumberAsCurrency(x.totalWebIncome.toNumber(), " $") ?? ""
  },
  {
    name: "Dealer income",
    get: (x) => formatNumberAsCurrency(x.dealerIncome.toNumber(), " $") ?? ""
  },
  {
    name: "Gross income",
    get: (x) => formatNumberAsCurrency(x.grossIncome.toNumber(), " $") ?? ""
  },
  { name: "Count", get: (x) => ("count" in x ? x?.count.toNumber() : "") },

  {
    name: "Amount financed change",
    get: (x) => ("incomeChange" in x ? `${x?.incomeChange.amountFinanced.toFixed(3)} %` : "")
  },
  {
    name: "Total web income change",
    get: (x) => ("incomeChange" in x ? `${x?.incomeChange.totalWebIncome.toFixed(3)} %` : "")
  },
  {
    name: "Dealer income change",
    get: (x) => ("incomeChange" in x ? `${x?.incomeChange.dealerIncome.toFixed(3)} %` : "")
  },
  {
    name: "Gross income change",
    get: (x) => ("incomeChange" in x ? `${x?.incomeChange.grossIncome.toFixed(3)} %` : "")
  }
];

const tableColumn = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, "annual", "averages"] as const;

export default function IncomeReport() {
  const [data, setData] = useState<Years>({});
  const [filters, setFiltersState] = useState<FilterQuery>([]);
  const setFilters = (newState: FilterQuery | ((oldState: FilterQuery) => FilterQuery)) => {
    const newFilters = typeof newState === "function" ? newState(filters) : newState;
    setFiltersState(newFilters);
    refreshTableCallback(newFilters);
  };
  const listId = `income-report`;
  const reportData = useSelector((state: RootState) => state.getIncomeReportSlice[listId]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIncomeReport({ requestId: listId, query: filters }));
  }, [listId, dispatch, filters]);
  useEffect(() => {
    if (reportData?.status === "success") setData(calculate(reportData.data.message));
  }, [reportData]);
  const refreshTableCallback = (filters: FilterQuery) => {
    dispatch(getIncomeReport({ requestId: listId, query: filters }));
  };
  return (
    <div style={{ position: "relative" }}>
      <AccessControl
        requiredPermissions={{ entity: "deal", action: "read" }}
        renderNoAccess={() => <span>You don&apos;t have permissions to view this report!</span>}
      >
        <Box display="flex">
          <Box flexGrow={1}>
            <h3>Income Report</h3>
          </Box>
          <Box>
            <OldFilters<DealData>
              setFilters={setFilters}
              filters={filters}
              relationFilters={[
                { path: ["data", "dealershipId"], preview: OldDealershipFilter },
                { preview: OldUserFilter, path: ["data", "userId"] },
                { preview: OldLenderFilter, path: ["data", "lenderId"] }
              ]}
              dateFilters={[
                { label: "Created At", path: ["createdAt"] },
                { label: "Updated At", path: ["updatedAt"] },
                { path: ["data", "info", "dealDates", "fundedAt"], label: "Funded At" },
                { path: ["data", "info", "dealDates", "contractDate"], label: "Signed At" }
              ]}
              hideCalendar={false}
            />
          </Box>
        </Box>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableBody>
              {Object.entries(data)
                .sort(([firstYear], [secondYear]) => parseInt(secondYear) - parseInt(firstYear))
                .map(([year, months]) => (
                  <>
                    <TableRow hover>
                      <TableCell
                        style={{ textAlign: "center", background: "#f1f1f1" }}
                        colSpan={16}
                      >
                        <b>{year}</b>
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell style={{ background: "#f1f1f1" }}>
                        <b>Month</b>
                      </TableCell>
                      {tableColumn.map((column, index) => (
                        <TableCell
                          key={index}
                          style={{ textAlign: "center", background: "#f1f1f1" }}
                        >
                          <b>
                            {typeof column === "number"
                              ? new Date("2000-" + (column + 1) + "-01").toLocaleString("default", {
                                  month: "long"
                                })
                              : capitalize(column) ?? ""}
                          </b>
                        </TableCell>
                      ))}
                    </TableRow>
                    {tableRows.map((row, index) => (
                      <TableRow hover key={index}>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <b>{row.name}</b>
                        </TableCell>
                        {tableColumn.map((column, index) => (
                          <TableCell
                            key={index}
                            style={{ textAlign: "right", whiteSpace: "nowrap" }}
                          >
                            {row.get(months[column])}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={16}>{"\u00A0"}</TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccessControl>
    </div>
  );
}
