import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverDmvRequest {
  _id: string;
}

export interface RecoverDmvResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverDmvRequest,
  RecoverDmvResponse
>("RecoverDmv", {
  request: "recover_dmv"
});

export const recoverDmvActions = socketMessageSlice.actions;
export const recoverDmv = socketAction;
export default socketMessageSlice.reducer;
