// export interface Products {
//   product: Product[];
// }
export const allProviders = [
  "American Guardian Warranty Services",
  "Nation Safe Drivers",
  "APCO - EASYCARE",
  "Compass",
  "AUL",
  "ASC Warranty",
  "Protective",
  "GWC",
  "XtraRide",
  "Gold Standard",
  "Other"
] as const;

export interface Surcharge {
  SurchargeCode: string;
  SurchargeOptions: { SurchargeOption: string[] };
  DataType: string;
  Prompt: string;
  SurchargeType: string;
}

export interface Product {
  Approved: string;
  ContractPrefix: string | null;
  EX1ProductID: string;
  ProductCode: string;
  ProductName: string;
  ProviderDealerID: string;
  ProviderName: string;
}

export interface DynamicSurcharge {
  Cost: string;
  Description: string;
  IsEditable: string;
  Price: string;
  SurchargeCode: string;
  Value: string;
  selected: boolean;
}
