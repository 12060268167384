import React from "react";
import { createOptionsForSelect, FormComponent, Model } from "../../utils/models/fields";
import { TitleIssue } from "./types";
import { StateAccess } from "utils/models/formGenerator";
import { showRenderSet } from "utils/models/formRenderers";
import AccessControl from "components/Access/AccessControl";
import TitleIssueNotesPreview from "components/TitleIssueNotes/TitleIssueNotesPreview";
import { detailedFees } from "components/Deals/model";
import FeesModalPreview from "components/Deals/FeesModalPreview";
import Overview from "./Overview";
import DocumentsSection from "./DocumentsSection";
import useUpdateTitleIssueNotes from "hooks/useUpdateTitleIssueNotes/useUpdatetitleIssueNotes";
import formEditContext from "components/Content/FormEditContext";
import { capitalize } from "utils/functions";
import LenderTicketPreview from "components/LenderTickets/LenderTicketPreview";
import NotesPreview from "components/Notes/NotesPreview";
import DealershipPreview from "./DealershipPreview";

const ticketPreview: FormComponent<TitleIssue> = {
  formComponent: "segment",
  name: "Lender tickets",
  titleStyle: { color: "white", margin: 10, padding: 10 },
  style: { background: "#254e6e", color: "white" },
  width: "full",

  show: (stateAccess) =>
    window.location.href.includes("titleIssue") && stateAccess.get(["data", "lenderTicket"]),

  entities: [
    {
      formComponent: "one-to-many-field",
      component: (stateAccess) => (
        <AccessControl requiredPermissions={{ entity: "lender_ticket", action: "read" }}>
          <LenderTicketPreview path={["data", "lenderTickets"]} stateAccess={stateAccess} />
        </AccessControl>
      ),
      path: null,
      name: "Notes",
      width: "full"
    }
  ]
};

const titleIssueNotes = (action: "create" | "update"): FormComponent<TitleIssue> => ({
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "one-to-many-field",
          component: (stateAccess) => (
            <AccessControl requiredPermissions={{ entity: "title_issue_note", action }}>
              <TitleIssueNotesPreview
                editable={window.location.href.includes("lender_ticket")}
                titleIssueId={stateAccess.get(["_id"])}
                titleIssueNotes={stateAccess.get(["data", "titleIssueNotes"]) ?? []}
                assignedDealershipEmails={
                  stateAccess.get(["data", "deal", "data", "info", "assignedDealershipEmails"]) ??
                  []
                }
              />
            </AccessControl>
          ),
          path: null,
          name: "Notes",
          width: "full"
        },
        {
          formComponent: "one-to-many-field",
          component: (stateAccess) => (
            <AccessControl requiredPermissions={{ entity: "note", action: "update" }}>
              <NotesPreview
                editable={window.location.href.includes("lender_ticket")}
                type="titleIssue"
                notes={stateAccess.get(["data", "notes"]) ?? []}
                titleIssueId={stateAccess.get(["_id"])}
              />
            </AccessControl>
          ),
          path: null,
          name: "Notes",
          width: "full"
        }
      ]
    }
  ]
});
export const titleIssueStruct: Model<TitleIssue> = {
  formComponent: "model",
  schema: "new_title_issue",
  name: "title_issue",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      name: (stateAccess) =>
        `Title Information - Deal: ${stateAccess.get([
          "data",
          "deal",
          "data",
          "info",
          "refNumber"
        ])}`,
      entities: [
        {
          formComponent: "one-to-many-field",
          path: [],
          name: "notesUpdater",
          component: (stateAccess) => {
            const { enabled: editMode } = React.useContext(formEditContext);

            const { updateTitleIssueNotes } = useUpdateTitleIssueNotes();
            React.useEffect(() => {
              const titleIssue = stateAccess.get([]) as TitleIssue;
              if (
                editMode &&
                titleIssue?.data?.titleIssueNotes?.some((note) => !note?.data?.info?.seen?.wfd)
              ) {
                updateTitleIssueNotes(titleIssue);
              }
            }, [editMode]);
            return null;
          }
        },

        {
          formComponent: "segment",
          width: "1/3",
          entities: [
            {
              formComponent: "one-to-many-field",
              width: "full",
              component: (stateAccess: StateAccess) => (
                <DealershipPreview
                  dealership={stateAccess.get(["data", "deal", "data", "dealership"])}
                />
              ),
              path: ["data", "deal", "data", "dealership", "data", "info", "displayName"],
              name: "dealership",
              default: null
            },

            {
              width: "1/2",
              formComponent: "read-only-field",
              path: ["data", "deal", "data", "lender", "data", "info", "name"],
              name: "Lender",
              label: "Lender"
            },
            {
              formComponent: "select-field",
              name: "Status",
              label: "Status",
              width: "1/4",
              path: ["data", "info", "dmvInfo", "status"],
              options: createOptionsForSelect({
                possibleValues: () => ["titled", "untitled", "error", "old_title"],
                getOptionLabel: (x) => capitalize(x),
                getSelectedOption: (x, y) => x === y
              }),
              default: null
            },
            {
              formComponent: "segment",
              width: "full",
              name: "Fees",
              entities: [
                {
                  formComponent: "segment",
                  width: "1/4",
                  entities: [
                    {
                      formComponent: "read-only-number-field",
                      name: "titleTransfer",
                      label: "Title/Transfer",
                      width: "3/4",
                      path: ["data", "deal", "data", "info", "taxesAndFees", "titleTransfer"],
                      default: null
                    },
                    {
                      formComponent: "one-to-many-field",
                      width: "1/4",
                      component: (stateAccess: StateAccess) => (
                        <FeesModalPreview
                          type="title_issue"
                          stateAccess={stateAccess}
                          renderSet={showRenderSet("update_deal")}
                        />
                      ),
                      struct: detailedFees,
                      path: ["data", "deal"] as any,
                      name: "detailedFees",
                      default: null
                    }
                  ]
                },
                {
                  formComponent: "read-only-number-field",
                  name: "documentRegistrationFees",
                  label: "Doc",
                  width: "1/4",
                  path: [
                    "data",
                    "deal",
                    "data",
                    "info",
                    "taxesAndFees",
                    "documentRegistrationFees"
                  ],
                  default: null
                },
                {
                  formComponent: "read-only-number-field",
                  name: "filingFees",
                  label: "Filing Fees",
                  width: "1/4",
                  path: ["data", "deal", "data", "info", "taxesAndFees", "filingFees"],
                  default: null
                },
                {
                  formComponent: "read-only-number-field",
                  name: "dealerFees",
                  label: "Dealer Fees",
                  width: "1/4",
                  path: ["data", "deal", "data", "info", "taxesAndFees", "otherFees"],
                  default: null
                }
              ]
            },
            titleIssueNotes("create")
          ]
        },
        {
          formComponent: "segment",
          width: "2/3",
          entities: [
            {
              formComponent: "one-to-many-field",
              component: (stateAccess) => <Overview stateAccess={stateAccess} />,
              path: null,
              name: "Overview",
              width: "full"
            },
            {
              formComponent: "one-to-many-field",
              component: (stateAccess) => <DocumentsSection stateAccess={stateAccess} />,
              path: null,
              name: "Documents",
              width: "full"
            }
          ]
        },
        ticketPreview
      ]
    }
  ]
};
