import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { CreditScoreRange } from "./types";

interface EditCreditScoreRangeResponse {
  message: CreditScoreRange;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  CreditScoreRange,
  EditCreditScoreRangeResponse
>("EditCreditScoreRange", {
  request: "update_credit_score_range"
});

export const editCreditScoreRange = socketAction;
export const editCreditScoreRangeActions = socketMessageSlice.actions;
export const editCreditScoreRangeError = socketMessageSlice.actions.error;

export default socketMessageSlice.reducer;
