import { createListSlice } from "../Middlewares/listSliceCreator";
import { LenderTicket } from "./types";

const { ListSlice, getList } = createListSlice<LenderTicket>({
  name: "LenderTicketList",
  request_topic: "all_lender_tickets",
  reducers: {}
});

export default ListSlice.reducer;
export const getLenderTicketList = getList;
export const listLenderTicketActions = ListSlice.actions;
export const removeLenderTicketList = ListSlice.actions.removeList;
