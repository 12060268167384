import Grid from "@material-ui/core/Grid";
import React from "react";
import { trimString } from "utils/functions";
import { editRenderSet } from "utils/models/formRenderers";
import { FilterProps, filterStateAccess } from "./types";
export default function MultipleCheckboxFilter<T extends unknown>({
  setFilter,
  path,
  label,
  filter
}: FilterProps<T>) {
  const stateAccess = filterStateAccess(filter, setFilter, (value: any) => {});

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {path.map((p) => {
          return editRenderSet(false).checkboxInputRenderer(
            {
              width: "full",
              //@ts-ignore
              name: p.join(".")?.toLowerCase() ?? "text-multi-filter",
              label: p.at(-1) ?? "N/A",
              required: false,
              path: [p.at(-1)],
              //@ts-ignore
              isChecked: (stateAccess) => filter?.query?.[p.join(".")],
              toggle: (stateAccess, checked) => {
                const query = Object.fromEntries(
                  //@ts-ignore
                  Object.entries({ ...(filter?.query || {}), [p.join(".")]: checked }).filter(
                    ([key, value]) => value
                  )
                );
                if (Object.keys(query).length > 0) {
                  setFilter({
                    query: query
                  });
                } else {
                  setFilter(undefined);
                }
              },
              formComponent: "checkbox-field"
            },
            stateAccess,
            stateAccess,
            editRenderSet(false),
            false
          );
        })}
      </Grid>
    </Grid>
  );
}
