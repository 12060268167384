import infoProperties from "./info_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import { Schema } from "ajv";

const unrequitedExternalId = { type: "string", nullable: true };

const data: Schema = {
  type: "object",
  required: ["info"],
  properties: {
    info: { type: "object", properties: infoProperties },
    dealId: unrequitedExternalId,
  },
};

export default {
  new_stipulation: {
    $id: "newStipulation",
    type: "object",
    required: ["data"],
    properties: {
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      data,
    },
  },
  update_stipulation: {
    $id: "updateStipulation",
    type: "object",
    required: ["_id", "data"],
    properties: {
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      _id: { type: "string" },
      data,
    },
  },
  delete_stipulation: {
    $id: "deleteStipulation",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_stipulation: {
    $id: "getStipulation",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_stipulations: {
    $id: "allStipulations",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
