import { CrudEntity } from "utils/types";

export interface FormState {
  dealId: string;
  oneSpanPackageId: string;
  info: Info;
}
export interface FormStateToUpdate {
  dealId: string;
  oneSpanPackageId: string;
  info: Info;
  updateEmail: {
    type: "applicant" | "coApplicant" | "dealership";
    isChanged: boolean;
    _id: string | undefined;
    email: string | undefined;
  };
}
export interface Info {
  invalidEmails: string[];
  status: {
    applicant: OneSpanStatus;
    coApplicant?: OneSpanStatus;
    dealership?: OneSpanStatus;
    overall: OneSpanStatus;
  };
  oneSpanRequestPackUrl: string;
  generatedPackUrl: string;
  oneSpanEvents?: OneSpanEvent[];
  generatedBy: string;
}
export type OneSpanSigning = CrudEntity & {
  updatedAt: string;
  data: FormState;
};
export type SigningStatus = {};
export enum OneSpanStatus {
  signed = "signed",
  partiallySigned = "partiallySigned",
  viewed = "viewed",
  notSigned = "notSigned"
}
export interface OneSpanEvent {
  sessionUser: string;
  packageId: string;
  message: unknown;
  documentId: string;
  createdDate: string;
  "@class": string;
  name: string;
}
