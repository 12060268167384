import { Model, FormComponent, createOptionsForSelect } from "utils/models/fields";
import { Deal, TradeIns } from "./types";
import States from "us-states";
import { StateAccess } from "utils/models/formGenerator";
import { capitalize, getStateLabelByStatePrefix } from "../../utils/functions";
import { dealerTrackCollateralTypes } from "components/Deals/DealerTrackPreview";
import {
  isDriverLicenseDisabled,
  TargetPlatForms,
  TestTargetPlatForms
} from "./addDealerTrackSlice";
export const TestTargetPlatFormsList: (keyof typeof TestTargetPlatForms)[] = [
  "Web Finance Direct",
  "BMO RV"
];
export const TargetPlatFormsList: (keyof typeof TargetPlatForms)[] = [
  "Web Finance Direct",
  "All Cars Inc",
  "Domine Auto",
  "Prospect Auto Sales",
  "Wholesale Enterprise",
  "Steve's Hillcrest",
  "Car Connection",
  "Cartiva",
  "Koehn Auto Sales",
  "BMO RV",
  "Capital Motor Company",
  "Christian Auto Sales"
];

export const getTargetPlatForms = () => {
  return process.env.REACT_APP_FIREBASE_PROJECT_ID !== "wfd-frontend"
    ? TestTargetPlatFormsList
    : [...TargetPlatFormsList].sort((x, y) => x.localeCompare(y));
};
const applicantStrict = (type: "applicant" | "coApplicant"): FormComponent<Deal>[] => {
  return [
    {
      formComponent: "select-field",
      name: "salutation",
      label: "Salutation",
      width: "1/4",
      path: ["data", type, "salutation"],
      options: createOptionsForSelect({
        possibleValues: () => ["Dr.", "Mr.", "Mrs.", "Ms."],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "text-field",
      name: "firstName",
      label: "First name",
      width: "1/4",
      path: ["data", type, "firstName"],
      default: null,
      required: true
    },
    {
      formComponent: "text-field",
      name: "MiddleName",
      label: "Middle name",
      width: "1/4",
      maxLength: 1,
      path: ["data", type, "middleName"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "LastName",
      label: "Last name",
      width: "1/4",
      path: ["data", type, "lastName"],
      default: null,
      required: true
    },
    {
      formComponent: "select-field",
      name: "Suffix",
      label: "Suffix",
      width: "1/4",
      path: ["data", type, "suffix"],
      options: createOptionsForSelect({
        possibleValues: () => ["", "SR", "JR", "I", "II", "III", "IV", "V"],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "select-field",
      name: "Relationship",
      label: "Relationship",
      width: "1/4",
      path: ["data", type, "relationship"],
      options: createOptionsForSelect({
        possibleValues: () => [
          "Self",
          "Spouse",
          "Relative",
          "RegisteredDomesticPartner",
          "CivilUnion",
          "Other"
        ],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },

    {
      formComponent: "ssn-field",
      name: "SSN",
      label: "SSN",
      width: "1/4",
      path: ["data", type, "ssn"],
      default: null,
      required: true
    },
    {
      formComponent: "date-field",
      name: "DOB",
      label: "Date of birth",
      width: "1/4",
      path: ["data", type, "dateOfBirth"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "DLNo",
      label: "Driver License Number",
      width: "1/4",
      show: (stateAccess) =>
        !isDriverLicenseDisabled(
          type,
          stateAccess.get<Deal>(["data", "targetPlatforms", 0, "partyId"])
        ),
      path: ["data", type, "driversLicenseNumber"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "driversLicenseState",
      show: (stateAccess) =>
        !isDriverLicenseDisabled(
          type,
          stateAccess.get<Deal>(["data", "targetPlatforms", 0, "partyId"])
        ),
      label: "Driver license state",
      width: "1/4",
      path: ["data", type, "driversLicenseState"],
      options: createOptionsForSelect({
        possibleValues: () => Object.values(States).map((x) => x.prefix),
        getOptionLabel: (x) => getStateLabelByStatePrefix(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },

    {
      formComponent: "select-field",
      name: "preferredContactMethod",
      label: "Preferred contact method",
      width: "1/4",
      path: ["data", type, "preferredContactMethod"],
      options: createOptionsForSelect({
        possibleValues: () => [
          "Email",
          "HomePhone",
          "CellPhone",
          "WorkPhone",
          "Letter",
          "OtherPhone",
          "OtherEmail",
          "Fax",
          "Other"
        ],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "select-field",
      name: "preferredLanguage",
      label: "Preferred language",
      width: "1/4",
      path: ["data", type, "preferredLanguage"],
      options: createOptionsForSelect({
        possibleValues: () => ["English", "Spanish"],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "email-field",
      name: "Email",
      label: "Email",
      width: "1/4",
      path: ["data", type, "email"],
      default: null
    },
    {
      formComponent: "digits-only-field",
      name: "phoneNumber",
      label: "Phone number",
      width: "1/4",
      path: ["data", type, "phone"],
      default: null,
      maxLength: 10
    },
    {
      formComponent: "digits-only-field",
      name: "workPhone",
      label: "Work phone",
      width: "1/4",
      required: false,
      path: ["data", type, "currentEmployment", "workPhone"],
      default: null,
      maxLength: 10
    },
    {
      formComponent: "digits-only-field",
      name: "otherPhone",
      label: "Other phone",
      width: "1/4",
      required: false,
      path: ["data", type, "otherPhone"],
      default: null,
      maxLength: 10
    },

    {
      formComponent: "segment",
      name: `${capitalize(type)} address`,
      width: "full",
      entities: [
        {
          formComponent: "text-field",
          name: "line1",
          label: "Line 1",
          width: "1/2",
          path: ["data", type, "address", "line1"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "line2",
          label: "Line 2",
          width: "1/2",
          path: ["data", type, "address", "line2"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "city",
          label: "City",
          width: "1/4",
          path: ["data", type, "address", "city"],
          default: null
        },
        {
          formComponent: "select-field",
          name: "State",
          label: "State",
          width: "1/4",
          path: ["data", type, "address", "state"],
          options: createOptionsForSelect({
            possibleValues: () => Object.values(States).map((x) => x.prefix),
            getOptionLabel: (x) => getStateLabelByStatePrefix(x),
            getSelectedOption: (x, y) => x === y
          }),
          default: null
        },
        {
          formComponent: "zip-code-field",
          name: "postalCode",
          label: "Postal Code",
          width: "1/4",
          path: ["data", type, "address", "postalCode"],
          default: null,
          maxLength: 5
        },
        {
          formComponent: "segment",
          width: "1/4",
          entities: [
            {
              formComponent: "number-field",
              name: "yearsAtCurrentAddress",
              label: "Years at current address",
              width: "1/2",
              path: ["data", type, "yearsAtCurrentAddress"],
              default: null
            },
            {
              formComponent: "number-field",
              name: "monthsAtCurrentAddress",
              label: "Months at current address",
              width: "1/2",
              path: ["data", type, "monthsAtCurrentAddress"],
              default: null
            }
          ]
        }
      ]
    },
    {
      formComponent: "segment",
      name: `${capitalize(type)} previous address`,
      width: "full",
      entities: [
        {
          formComponent: "text-field",
          name: "line1",
          label: "Line 1",
          width: "1/3",
          path: ["data", type, "previousAddress", "line1"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "line2",
          label: "Line 2",
          width: "1/3",
          path: ["data", type, "previousAddress", "line2"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "city",
          label: "City",
          width: "1/3",
          path: ["data", type, "previousAddress", "city"],
          default: null
        },
        {
          formComponent: "select-field",
          name: "State",
          label: "State",
          width: "1/3",
          path: ["data", type, "previousAddress", "state"],
          options: createOptionsForSelect({
            possibleValues: () => Object.values(States).map((x) => x.prefix),
            getOptionLabel: (x) => getStateLabelByStatePrefix(x),
            getSelectedOption: (x, y) => x === y
          }),
          default: null
        },
        {
          formComponent: "zip-code-field",
          name: "postalCode",
          label: "Postal Code",
          width: "1/3",
          path: ["data", type, "previousAddress", "postalCode"],
          hideZipCodeSearch: true,
          default: null
        },
        {
          formComponent: "segment",
          width: "1/3",
          entities: [
            {
              formComponent: "number-field",
              name: "YearsAtPreviousAddress",
              label: "Years at previous address",
              width: "1/2",
              path: ["data", type, "yearsAtPreviousAddress"],
              default: null
            },
            {
              formComponent: "number-field",
              name: "monthsAtPreviousAddress",
              label: "Months at previous address",
              width: "1/2",
              path: ["data", type, "monthsAtPreviousAddress"],
              default: null
            }
          ]
        }
      ]
    },
    {
      formComponent: "segment",
      name: `${capitalize(type)} current employment`,
      width: "full",
      entities: [
        {
          formComponent: "radio-field",
          name: "Status",
          label: "Employment Status",
          width: "full",
          path: ["data", type, "currentEmployment", "status"],
          default: null,
          possibleValues: [
            { label: "Employed", value: "Employed" },
            { label: "Self employed", value: "SelfEmployed" },
            { label: "Student", value: "Student" },
            { label: "Retired", value: "Retired" },
            { label: "Active military", value: "ActiveMilitary" },
            { label: "Unemployed", value: "Unemployed" },
            { label: "Retired military", value: "RetiredMilitary" },
            { label: "Other", value: "Other" }
          ]
        },
        {
          formComponent: "text-field",
          name: "EmployerName",
          label: "Employer Name",
          width: "1/3",
          path: ["data", type, "currentEmployment", "employerName"],
          default: null
        },
        {
          formComponent: "segment",
          width: "1/3",
          entities: [
            {
              formComponent: "number-field",
              name: "totalYearsEmployed",
              label: "Total years employed",
              width: "1/2",
              path: ["data", type, "currentEmployment", "totalYearsEmployed"],
              default: null
            },
            {
              formComponent: "number-field",
              name: "totalMonthsEmployed",
              label: "Total months employed",
              width: "1/2",
              path: ["data", type, "currentEmployment", "totalMonthsEmployed"],
              default: null
            }
          ]
        },
        {
          formComponent: "text-field",
          name: "Occupation",
          label: "Occupation",
          width: "1/3",
          path: ["data", type, "currentEmployment", "occupation"],
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      name: `${capitalize(type)} income`,
      width: "full",
      entities: [
        {
          formComponent: "currency-field",
          name: "income",
          decimalPlaces: 0,
          label: "Income",
          width: "1/4",
          path: ["data", type, "income"],
          default: null,
          required: true
        },
        {
          formComponent: "select-field",
          name: "incomeFrequency",
          label: "Income frequency",
          width: "1/4",
          path: ["data", type, "incomeFrequency"],
          options: createOptionsForSelect({
            possibleValues: () => ["Weekly", "Biweekly", "Monthly", "Annually"],
            getOptionLabel: (x) => x,
            getSelectedOption: (x, y) => x === y
          }),
          default: "Monthly",
          required: true
        },
        {
          formComponent: "currency-field",
          name: "otherMonthlyIncome",
          label: "Other monthly income",
          decimalPlaces: 0,
          width: "1/4",
          path: ["data", type, "otherMonthlyIncome"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "otherMonthlyIncomeSource",
          label: "Other monthly income source",
          width: "1/4",
          path: ["data", type, "otherMonthlyIncomeSource"],
          default: null
        },
        {
          formComponent: "select-field",
          name: "housingStatus",
          label: "Housing Status",
          width: "1/3",
          path: ["data", type, "housingStatus"],
          options: createOptionsForSelect({
            possibleValues: () => [
              "Mortgage",
              "Rent",
              "Family",
              "OwnOutright",
              "Other",
              "Military"
            ],
            getOptionLabel: (x) => x,
            getSelectedOption: (x, y) => x === y
          }),
          default: null
        },
        {
          formComponent: "select-field",
          name: "maritalStatus",
          label: "Marital Status",
          width: "1/3",
          path: ["data", type, "maritalStatus"],
          options: createOptionsForSelect({
            possibleValues: () => [
              "Single",
              "Married",
              "Divorced",
              "Widowed",
              "Unmarried",
              "Separated"
            ],
            getOptionLabel: (x) => x,
            getSelectedOption: (x, y) => x === y
          }),
          default: null
        },
        {
          formComponent: "currency-field",
          decimalPlaces: 0,
          name: "mortgageOrRentAmount",
          label: "Mortgage or rent amount",
          width: "1/3",
          path: ["data", type, "mortgageOrRentAmount"],
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      name: `${capitalize(type)} previous employment`,
      width: "full",
      entities: [
        {
          formComponent: "text-field",
          name: "employerName",
          label: "Employer name",
          width: "1/4",
          path: ["data", type, "previousEmployment", "employerName"],
          default: null
        },
        {
          formComponent: "segment",
          width: "1/4",
          entities: [
            {
              formComponent: "number-field",
              name: "totalYearsEmployed",
              label: "Total Years employed",
              width: "1/2",
              path: ["data", type, "previousEmployment", "totalYearsEmployed"],
              default: null
            },
            {
              formComponent: "number-field",
              name: "totalMonthsEmployed",
              label: "Total months employed",
              width: "1/2",
              path: ["data", type, "previousEmployment", "totalMonthsEmployed"],
              default: null
            }
          ]
        },

        {
          formComponent: "text-field",
          name: "Occupation",
          label: "Occupation",
          width: "1/4",
          path: ["data", type, "previousEmployment", "occupation"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "workPhone",
          label: "Work phone",
          width: "1/4",
          required: false,
          path: ["data", type, "previousEmployment", "workPhone"],
          default: null
        },
        {
          formComponent: "radio-field",
          name: "Status",
          label: "Employment Status",
          width: "full",
          path: ["data", type, "previousEmployment", "status"],
          default: null,
          possibleValues: [
            { label: "Employed", value: "Employed" },
            { label: "Self employed", value: "SelfEmployed" },
            { label: "Student", value: "Student" },
            { label: "Retired", value: "Retired" },
            { label: "Active military", value: "ActiveMilitary" },
            { label: "Unemployed", value: "Unemployed" },
            { label: "Retired military", value: "RetiredMilitary" },
            { label: "Other", value: "Other" }
          ]
        }
      ]
    }
  ];
};

const applicant: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  name: "Applicant",
  entities: applicantStrict("applicant")
};
const coApplicant: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  name: "CoApplicant",
  entities: applicantStrict("coApplicant")
};

const financeSummary: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  name: "Finance summary",
  entities: [
    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "vehicleSellingPrice",
      label: "Vehicle selling price",
      width: "1/4",
      path: ["data", "financeSummary", "vehicleSellingPrice"],
      default: null
    },
    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "salesTax",
      label: "Sales tax",
      width: "1/4",
      path: ["data", "financeSummary", "salesTax"],
      default: null
    },
    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "governmentFees",
      label: "Government fees",
      width: "1/4",
      path: ["data", "financeSummary", "governmentFees"],
      default: null
    },
    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "cashDown",
      label: "Cash down",
      width: "1/4",
      path: ["data", "financeSummary", "cashDown"],
      default: null
    },
    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "rebate",
      label: "Rebate",
      width: "1/4",
      path: ["data", "financeSummary", "rebate"],
      default: null
    },

    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "term",
      label: "Term",
      width: "1/4",
      path: ["data", "financeSummary", "term"],
      max: 99,
      default: null
    },
    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "invoiceAmount",
      label: "Invoice Amount",
      width: "1/4",
      path: ["data", "financeSummary", "invoiceAmount"],
      default: null
    },
    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "warranty",
      label: "Warranty",
      width: "1/4",
      path: ["data", "financeSummary", "warranty"],
      default: null
    },
    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "gap",
      label: "Gap",
      width: "1/4",
      path: ["data", "financeSummary", "gap"],
      default: null
    },
    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "frontEndFees",
      label: "Front End Fees",
      width: "1/4",
      path: ["data", "financeSummary", "frontEndFees"],
      default: null
    },
    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "msrp",
      label: "MSRP",
      width: "1/4",
      path: ["data", "financeSummary", "msrp"],
      default: null
    },
    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "estimatedPayment",
      label: "Estimated Payment",
      width: "1/4",
      path: ["data", "financeSummary", "estimatedPayment"],
      default: null
    },
    {
      formComponent: "number-field",
      name: "mileage",
      label: "Mileage",
      width: "1/4",
      path: ["data", "financeSummary", "mileage"],
      default: null
    },

    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "otherFees",
      label: "Other Fees",
      width: "1/4",
      path: ["data", "financeSummary", "otherFees"],
      default: null
    },

    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "netTrade",
      label: "Net Trade",
      width: "1/3",
      path: ["data", "financeSummary", "netTrade"],
      default: null
    }
  ]
};

const vehicle: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  name: "Vehicle",
  entities: [
    {
      formComponent: "radio-field",
      possibleValues: dealerTrackCollateralTypes.map((type) => ({ value: type, label: type })),
      name: "type",
      label: "Type",
      width: "full",
      path: ["data", "vehicle", "vehicleType"],
      required: true,
      default: null
    },
    {
      formComponent: "select-field",
      name: "inventoryVehicleCondition",
      label: "Inventory Vehicle Condition",
      width: "1/3",
      path: ["data", "vehicle", "inventoryVehicleCondition"],
      options: createOptionsForSelect({
        possibleValues: () => ["New", "Used"],
        getOptionLabel: (x) => x,
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "text-field",
      name: "vin",
      label: "VIN",
      width: "1/3",
      path: ["data", "vehicle", "vin"],
      default: null
    },

    {
      formComponent: "year-field",
      name: "otherYear",
      label: "Year",
      width: "1/4",
      path: ["data", "vehicle", "otherYear"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "otherMake",
      label: "Make",
      width: "1/4",
      path: ["data", "vehicle", "otherMake"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "otherModel",
      label: "Model",
      width: "1/4",
      path: ["data", "vehicle", "otherModel"],
      default: null
    }
  ]
};

const tradeInsStruct: FormComponent<TradeIns> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "text-field",
      name: "vin",
      label: "VIN",
      width: "1/3",
      path: ["vin"],
      default: null
    },
    {
      formComponent: "year-field",
      name: "otherYear",
      label: "Year",
      width: "1/3",
      path: ["otherYear"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "otherMake",
      label: "Make",
      width: "1/3",
      path: ["otherMake"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "otherModel",
      label: "Model",
      width: "1/3",
      path: ["otherModel"],
      default: null
    },

    {
      formComponent: "currency-field",
      decimalPlaces: 0,
      name: "actualCashValue",
      label: "Value",
      width: "1/3",
      path: ["actualCashValue"],
      default: null
    }
  ]
};

export const dealerTypeStruct: Model<Deal> = {
  formComponent: "model",
  schema: "dealertrack_deal_add",
  name: "dealertrack_deal",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "select-field",
          name: "Dealership",
          label: "Dealership",
          width: "1/2",
          path: ["data", "targetPlatforms", 0, "partyId"],
          options: createOptionsForSelect({
            possibleValues: () => getTargetPlatForms(),
            getOptionLabel: (x) => x,
            getSelectedOption: (x, y) => x === y
          }),
          default: null
        },
        {
          formComponent: "select-field",
          name: "financeMethod",
          label: "Finance Method",
          width: "1/2",
          path: ["data", "financeMethod"],
          options: createOptionsForSelect({
            possibleValues: () => ["Finance", "Lease", "Balloon", "Cash"],
            getOptionLabel: (x) => x,
            getSelectedOption: (x, y) => x === y
          }),
          default: null
        }
      ]
    },

    {
      formComponent: "segment",
      entities: [applicant]
    },
    {
      formComponent: "checkbox-field",
      name: "coApplicant",
      label: "Include CoApplicant?",
      width: "1/3",

      toggle: (stateAccess: StateAccess) =>
        stateAccess.set<Deal>(
          ["data", "includeCoApplicant"],
          !stateAccess.get<Deal>(["data", "includeCoApplicant"])
        ),
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<Deal>(["data", "includeCoApplicant"]),

      path: ["data", "includeCoApplicant"],
      default: false
    },
    {
      formComponent: "segment",
      entities: [coApplicant],
      show: (stateAccess) => stateAccess.get<Deal>(["data", "includeCoApplicant"])
    },
    {
      formComponent: "segment",
      entities: [vehicle]
    },
    {
      formComponent: "list-model",
      name: "TradeIns",
      path: ["data", "tradeIns"],
      entity: tradeInsStruct
    },

    {
      formComponent: "segment",
      entities: [financeSummary]
    }
  ]
};
