import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import React from "react";
import { useSelector } from "react-redux";
import ApplicantFilter from "../Filters/ApplicantFilter";
import DateFilter from "../Filters/DateFilter";
import PhoneFilter from "../Filters/PhoneFilter";
import TextFilter from "../Filters/TextFilter";
import Table, { CellValue, Column } from "../Table";
import { deleteApplicant } from "./deleteApplicantSlice";
import { getApplicantList } from "./listApplicantSlice";
import { recoverApplicant } from "./recoverApplicantSlice";
import { FormState } from "./types";
export const projections = {
  "data.info.firstName": 1,
  "data.info.lastName": 1,
  "data.info.currentAddress": 1,
  "data.info.currentCounty": 1,
  "data.info.currentCity": 1,
  "data.info.mobilePhone": 1,
  "data.info.currentZipCode": 1,
  "data.info.email": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};

export default (): JSX.Element => {
  const applicantList = useSelector((state: RootState) => state.listApplicantSlice["table"]);

  const columns: Column<FormState>[] = [
    {
      name: "name",
      label: "Name",
      getData: (el): CellValue =>
        `${el?.data?.info.firstName ?? ""} ${el?.data?.info.lastName ?? ""}`,
      filters: [
        {
          path: [
            ["data", "info", "firstName"],
            ["data", "info", "lastName"]
          ],
          preview: ApplicantFilter
        }
      ],
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      }
    },
    {
      name: "currentAddress",
      label: "Address",
      getData: (el): CellValue => el?.data?.info.currentAddress,
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.currentAddress?.show;
      },
      filters: [
        {
          path: ["data", "info", "currentAddress"],
          preview: TextFilter,
          caseInsensitive: true
        }
      ]
    },
    {
      label: "City",
      name: "currentCity",
      getData: (el): CellValue => el?.data?.info.currentCity,
      options: {
        sort: true,
        path: ["data", "info", "currentCity"]
      },
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.currentCity?.show;
      },
      filters: [
        {
          path: ["data", "info", "currentCity"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      label: "County",
      getData: (entry): CellValue => entry?.data?.info?.currentCounty ?? "",
      options: {
        sort: true,
        path: ["data", "info", "currentCounty"]
      },
      name: "currentCounty",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.currentCounty?.show;
      },
      filters: [
        {
          path: ["data", "info", "currentCounty"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      label: "Zip code",
      name: "currentZipCode",
      getData: (el): CellValue => el?.data?.info?.currentZipCode?.toString() ?? "",
      options: {
        sort: true,
        path: ["data", "info", "currentZipCode"]
      },
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.currentZipCode?.show;
      },
      filters: [
        {
          path: ["data", "info", "currentZipCode"],
          preview: TextFilter
        }
      ]
    },
    {
      label: "Mobile phone",
      name: "mobilePhone",
      getData: (el): CellValue => el?.data?.info.mobilePhone,
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.mobilePhone?.show;
      },
      filters: [
        {
          path: ["data", "info", "mobilePhone"],
          preview: PhoneFilter
        }
      ]
    },
    {
      label: "Email address",
      name: "email",
      getData: (el): CellValue => el?.data?.info.email,
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.email?.show;
      },
      filters: [
        {
          path: ["data", "info", "email"],
          preview: TextFilter
        }
      ]
    },
    {
      label: "Date added",
      name: "createdAt",
      getData: (el): CellValue => new Date(el.createdAt),
      options: {
        sort: true,
        path: ["data", "createdAt"]
      },
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter
        }
      ]
    },
    {
      name: "actions",
      label: "Actions",
      getData: (el): CellValue => el._id,
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <AccessControl requiredPermissions={{ entity: "applicant", action: "read" }}>
      <div style={{ position: "relative" }}>
        <Table<FormState>
          tableName={"applicants"}
          title={"Applicants"}
          entityName="applicant"
          columns={columns}
          listEntity={applicantList}
          listFunction={getApplicantList}
          recoverEntityFunction={recoverApplicant}
          deleteEntityFunction={deleteApplicant}
          projection={projections}
        />
      </div>
    </AccessControl>
  );
};
