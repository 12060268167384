import { Button, Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import CloseDialogButton from "components/common/CloseDialogButton";

import ApplicantFilter from "components/Filters/ApplicantFilter";
import DateFilter from "components/Filters/DateFilter";
import LenderFilter from "components/Filters/LenderFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import TextFilter from "components/Filters/TextFilter";
import Table, { CellValue, Column } from "components/Table";

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { capitalize } from "utils/functions";
import { FormState, LenderTicketStatus } from "components/LenderTickets/types";

import { getLenderTicketList } from "components/LenderTickets/listLenderTicketSlice";
import { deleteLenderTicket } from "components/LenderTickets/deleteLenderTicketSlice";
import { recoverLenderTicket } from "components/LenderTickets/recoverLenderTicketSlice";
const columns: Column<FormState>[] = [
  {
    getData: (el): CellValue => capitalize(el.data.info.status) ?? "",
    label: "Status",
    name: "status",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.status?.show;
    },
    filters: [
      {
        path: ["data", "info", "status"],
        preview: MultiSelectFilter,
        valuesForSelect: Object.values(LenderTicketStatus),
        optionLabelForSelect: (status) => capitalize(status)
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.info?.refNumber;
    },
    label: "Ref #",
    options: {
      sort: true,
      path: ["data", "info", "refNumber"]
    },
    name: "refNumber",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.refNumber?.show;
    },
    filters: [
      {
        path: ["data", "info", "refNumber"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (el): CellValue => el.data.info.subject,
    label: "Subject",
    name: "subject",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.subject?.show;
    },
    filters: [
      {
        path: ["data", "info", "subject"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (el): CellValue => el.data.lender?.data?.info?.name,
    label: "Lender",
    name: "Lender",
    show: () => true,
    filters: [
      {
        path: ["data", "lenderId"],
        preview: LenderFilter
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      [
        entry.data?.deal?.data?.applicant
          ? `${entry.data?.deal?.data?.applicant?.data?.info?.firstName ?? ""} ${
              entry.data?.deal?.data?.applicant?.data?.info?.lastName ?? ""
            }`
          : undefined,
        entry.data?.deal?.data?.coApplicant
          ? `${entry.data?.deal?.data?.coApplicant?.data?.info?.firstName ?? ""} ${
              entry.data?.deal?.data?.coApplicant?.data?.info?.lastName ?? ""
            }`
          : undefined
      ]
        .filter((x) => x)
        .join(" / "),
    label: "Applicant",
    name: "applicant",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicant?.show;
    },
    filters: [
      {
        path: [
          ["data", "deal", "data", "applicant", "data", "info", "firstName"],
          ["data", "deal", "data", "applicant", "data", "info", "lastName"],
          ["data", "deal", "data", "coApplicant", "data", "info", "firstName"],
          ["data", "deal", "data", "coApplicant", "data", "info", "lastName"]
        ],
        preview: ApplicantFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.deal?.data?.info?.vehicle?.VIN,
    label: "VIN",
    name: "vin",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.vin?.show;
    },
    filters: [
      {
        path: ["data", "deal", "data", "info", "vehicle", "VIN"],
        preview: TextFilter
      }
    ]
  },
  {
    label: "Date updated",
    getData: (el): CellValue => new Date(el.updatedAt),
    options: {
      sort: true,
      path: ["updatedAt"]
    },
    name: "updatedAt",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.updatedAt?.show;
    },
    filters: [
      {
        path: ["updatedAt"],
        preview: DateFilter,
        label: "Updated At",
        name: "updatedAt"
      }
    ]
  },
  {
    label: "Date added",
    getData: (el): CellValue => new Date(el.createdAt),
    options: {
      sort: true,
      path: ["createdAt"]
    },
    name: "createdAt",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.createdAt?.show;
    },
    filters: [
      {
        path: ["createdAt"],
        preview: DateFilter,
        label: "Created At",
        name: "createdAt"
      }
    ]
  },
  {
    label: "Actions",
    getData: (el): CellValue => el._id,
    name: "actions",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.actions?.show;
    }
  }
];
export default ({ titleIssuesIds }: { titleIssuesIds: string[] | undefined }) => {
  const listId = "limitedTitleIssues";
  const ticketList = useSelector((state: RootState) => state.listLenderTicketSlice[listId]);
  const [showDealsModal, setShowDealsModal] = useState(false);
  const closeFunction = () => setShowDealsModal(false);
  return (
    <>
      <Grid item md={12} xs={12} style={{ marginTop: 5 }}>
        <Button
          color="primary"
          style={{ width: "100%" }}
          variant="contained"
          onClick={() => setShowDealsModal(true)}
        >
          Lender Tickets
        </Button>
      </Grid>
      {showDealsModal ? (
        <Dialog
          id="lender-statistics-deals-dialog"
          onClose={closeFunction}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle id="customized-dialog-title">Tickets</DialogTitle>
          <CloseDialogButton closeFunction={closeFunction} />
          <DialogContent>
            <AccessControl requiredPermissions={{ entity: "lender_ticket", action: "read" }}>
              <Table
                tableName="lender_ticket"
                entityName="lender_ticket"
                slice={listId}
                listFunction={getLenderTicketList}
                deleteEntityFunction={deleteLenderTicket}
                recoverEntityFunction={recoverLenderTicket}
                sort={{ createdAt: "desc" }}
                listEntity={ticketList}
                aggregateFirst={true}
                query={{ "data.titleIssueId": { $in: titleIssuesIds } }}
                title={"Tickets"}
                columns={columns}
                hideAddButton
              />
            </AccessControl>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
};
