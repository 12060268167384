import { TextField, Typography, IconButton, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { StateAccess } from "utils/models/formGenerator";
import { Deal, Vehicle } from "../types";
import React from "react";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { storage } from "../../../firebase/firebase";
import { Applicant } from "components/Applicants/types";
import { User } from "components/Users/types";
import { capitalize } from "utils/functions";
import { PackType } from "./types";
import { Checklist, ChecklistEmailData } from "../Checklist/types";

const formatSubject = (applicant: Applicant | null | undefined, vehicle: Vehicle) => {
  const applicantNames = `${applicant?.data?.info?.firstName} ${applicant?.data?.info?.lastName}`?.trim();
  return `${applicantNames} ${vehicle.year} ${vehicle.make} ${vehicle.model} - Contract`;
};
const formatBody = (applicant: Applicant | null | undefined, user: User | null | undefined) => {
  const managerNames = `${user?.data?.info?.firstName} ${user?.data?.info?.lastName}`?.trim();

  return `Hello, 
in the attachments you can find the contracts for ${applicant?.data?.info?.firstName}'s deal.

Best regards,
${managerNames}
F&I Manager in WFD`;
};
export const generateEmailData = (
  deal: Deal,
  checklist: Checklist | undefined
): ChecklistEmailData => {
  const dealershipEmails = deal.data?.dealership?.data?.contacts?.map((contact) => contact.email);
  const applicantEmail = deal.data?.applicant?.data?.info?.email;
  const coApplicantEmail = deal.data?.coApplicant?.data?.info?.email;
  return {
    recipients: [...(dealershipEmails || []), applicantEmail, coApplicantEmail].filter(
      (x) => x
    ) as string[],
    subject: formatSubject(deal.data.applicant, deal.data.info.vehicle),
    body: formatBody(deal.data.applicant, deal.data.user),
    attachments: (checklist?.data?.info?.generatedPacks ?? [])
      ?.filter((pack) => pack.packType === PackType.email)
      ?.map((pack) => ({
        name: capitalize(pack.name),
        url: pack.url
      }))
  };
};

interface Props {
  checklistStateAccess: StateAccess;
  deal: Deal;
}
export default ({ deal, checklistStateAccess }: Props) => {
  const checklist: Checklist = checklistStateAccess.get([]);
  const { enqueueSnackbar } = useSnackbar();
  const [uploadInProgress, setUploadInProgress] = React.useState(false);

  const onTagsChange = (event: React.ChangeEvent<{}>, values: string[]) => {
    checklistStateAccess.set(["data", "info", "emailData"], {
      ...(checklist?.data?.info?.emailData ?? {}),
      recipients: values
    });
  };
  const handleChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: "subject" | "body"
  ) => {
    checklistStateAccess.set(["data", "info", "emailData"], {
      ...(checklist?.data?.info?.emailData ?? {}),
      [field]: evt.target.value
    });
  };
  const handleUploadAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadInProgress(true);
    const uploadedFiles = event.target.files ?? [];
    const fileRequestId = uuidv4();
    if (uploadedFiles.length !== 0) {
      const file = uploadedFiles[0];
      const firebaseFileName = `${fileRequestId}-${file.name}`;
      const uploadTask = storage.ref(`/files/Deal/${deal._id}/${firebaseFileName}`).put(file);

      uploadTask.on(
        "state_changed",
        (_snapShot) => {},
        (err) => {
          setUploadInProgress(false);
          enqueueSnackbar(err.message, {
            variant: "error"
          });
        },
        () => {
          const ref = storage.ref(`/files/Deal/${deal._id}`).child(firebaseFileName);

          ref.getDownloadURL().then((fireBaseUrl) => {
            ref.getMetadata().then((metadata) => {
              setUploadInProgress(false);

              checklistStateAccess.set(["data", "info", "emailData"], {
                ...(checklist?.data?.info?.emailData ?? {}),
                attachments: [
                  ...(checklist?.data?.info?.emailData?.attachments ?? []),
                  { url: fireBaseUrl, name: file.name }
                ]
              });
            });
          });
        }
      );
    }
  };
  return (
    <>
      <Autocomplete
        multiple
        freeSolo
        autoSelect
        options={checklist?.data?.info?.emailData?.recipients ?? []}
        getOptionLabel={(option) => option}
        onChange={onTagsChange}
        value={checklist?.data?.info?.emailData?.recipients ?? []}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label="To"
            placeholder="Add recepients"
            margin="dense"
            fullWidth
          />
        )}
      />
      <TextField
        variant="filled"
        label="Subject"
        placeholder=""
        margin="dense"
        fullWidth
        value={checklist?.data?.info?.emailData?.subject ?? ""}
        onChange={(e) => {
          handleChange(e, "subject");
        }}
      />
      <TextField
        variant="filled"
        label="Body"
        placeholder=""
        margin="dense"
        fullWidth
        multiline
        rows={8}
        value={checklist?.data?.info?.emailData?.body ?? ""}
        onChange={(e) => {
          handleChange(e, "body");
        }}
      />
      <div
        style={{
          display: "flex",
          border: "2px solid #e8e8e8",
          paddingLeft: 10,
          borderRadius: "4px"
        }}
      >
        <Typography style={{ flex: "1", lineHeight: "24px" }} variant="button">
          Attachments
        </Typography>
        {uploadInProgress ? (
          <CircularProgress color="primary" size={19} />
        ) : (
          <>
            <input
              accept=".pdf"
              id={`upload-attachment`}
              type="file"
              onChange={handleUploadAttachment}
              hidden
            />
            <label style={{ display: "flex" }} htmlFor={`upload-attachment`}>
              <IconButton size="small" component="span">
                Add <AttachFileIcon />
              </IconButton>
            </label>
          </>
        )}
      </div>

      {(checklist?.data?.info?.emailData?.attachments ?? [])?.map((attachment, index: number) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "3px",
            background: "#e8e8e8",
            borderRadius: "3px",
            paddingLeft: "5px"
          }}
          key={index}
        >
          <a
            href={attachment.url || "#"}
            style={{ marginRight: "5px", fontStyle: "italic", flex: "1" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {attachment.name || "Document name not found"}
          </a>
          <IconButton
            size="small"
            style={{ color: "red" }}
            onClick={() => {
              const newAttachments = [
                ...(checklist?.data?.info?.emailData?.attachments ?? [])
              ].filter((att, innerIndex) => index !== innerIndex);
              checklistStateAccess.set(["data", "info", "emailData"], {
                ...(checklist?.data?.info?.emailData ?? {}),
                attachments: newAttachments
              });
            }}
          >
            <RemoveCircleIcon />
          </IconButton>
        </div>
      ))}
    </>
  );
};
