import React from "react";

import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";

export const StyledDialog = withStyles({
  root: {
    position: "absolute !important" as "absolute"
  },
  container: {
    backgroundColor: "rgba(0,0,0,0.2)",
    overflow: "visible"
  }
})((props) => (
  <Dialog open={true} {...props} aria-labelledby="form-dialog-title" maxWidth="sm" scroll="body" />
));

export const XlStyledDialog = withStyles({
  // root: {
  //   position: "absolute !important" as "absolute",
  //   zIndex: "1000 !important" as any
  // },
  // container: {
  //   backgroundColor: "rgba(0,0,0,0.2)",
  //   overflow: "visible"
  // }
})((props) => (
  <Dialog open={true} {...props} aria-labelledby="form-dialog-title" maxWidth="xl" scroll="body" />
));
