import {
  multyPermissionNode,
  permissionNode,
  PermissionStruct,
  relationPermissionNode
} from "components/Roles/types";
import { DocumentTemplate } from "./types";

export type DocumentTemplatePermissionsStruct = PermissionStruct<DocumentTemplate>;

export const documentTemplatePermissionStruct: DocumentTemplatePermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      multipleAddresses: permissionNode("Multiple addresses"),
      name: permissionNode("Name"),
      url: permissionNode("Url"),
      type: permissionNode("Type"),
      displayName: permissionNode("Display name"),
      requiredToBeReturned: permissionNode("Required To Be Returned"),
      requestedBy: {
        applicant: permissionNode("Applicant"),
        dealership: permissionNode("Dealership"),
        eSign: permissionNode("eSign"),
        optionalSignature: permissionNode("Optional signature")
      },
      dealTypeProperties: {
        hasOneApplicant: permissionNode("Has One Applicant"),
        lendersIds: multyPermissionNode("Lender Ids"),
        lenders: relationPermissionNode("Lenders", "lender"),
        states: multyPermissionNode("States"),
        applicantStates: multyPermissionNode("Applicant States"),
        types: multyPermissionNode("Types"),
        hasCoApplicant: permissionNode("Has CoApplicant"),
        hasGAP: permissionNode("has GAP"),
        hasVSC: permissionNode("has VSC"),
        hasTrailer: permissionNode("has Trailer"),
        programType: permissionNode("Program type")
      }
    },
    files: relationPermissionNode("Files", "file"),
    notes: multyPermissionNode("Notes")
  }
};
