import axios from "axios";
import { Applicant } from "components/Applicants/types";
import { auth } from "../../firebase/firebase";
import { HttpQuery } from "Chat/types/http";

export const getApplicants = (query?: HttpQuery): (() => Promise<Applicant[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${process.env.REACT_APP_HTTP_URL}/api/v1/applicant/`, {
      headers: {
        authorization: `Bearer ${token}`,
        ...(query && Object.keys(query)?.length ? { query: JSON.stringify(query) } : {})
      }
    });

    return response.data.result?.docs ?? [];
  };
};

export const getApplicant = (id: string): (() => Promise<Applicant>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(`${process.env.REACT_APP_HTTP_URL}/api/v1/applicant/${id}`, {
      headers: {
        authorization: `Bearer ${token}`
      }
    });
    return response.data.result;
  };
};
