import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

import { Deal } from "components/Deals/types";
import React from "react";
import { formatNumberAsCurrency } from "utils/functions";
import { StateAccess } from "utils/models/formGenerator";
import { Chargeback, ChargebackDeal } from "../types";

const useStyles = makeStyles(() =>
  createStyles({
    tableRow: {
      "&:hover": {
        cursor: "pointer"
      }
    }
  })
);

const renderTableCells = (deal: Deal, handleSelectDeal: (deal: Deal) => void) => {
  return (
    <>
      <TableCell>
        {`${deal.data?.applicant?.data?.info?.firstName} ${deal.data?.applicant?.data?.info?.lastName}`}
        {deal.data?.coApplicantId
          ? ` / ${deal.data?.coApplicant?.data?.info?.firstName} ${deal.data?.coApplicant?.data?.info?.lastName}`
          : ""}
      </TableCell>
      <TableCell>
        {formatNumberAsCurrency(deal.data.info.profit?.wfdProfit?.reserveCommission)}
      </TableCell>
      <TableCell>
        {formatNumberAsCurrency(deal.data.info.profit?.wfdProfit?.extraReserveProfit)}
      </TableCell>

      <TableCell>{deal.data.info.refNumber}</TableCell>
      <TableCell>{deal.data?.dealership?.data?.info?.displayName}</TableCell>
      <TableCell>{deal.data?.lender?.data?.info?.name}</TableCell>
      <TableCell>{deal.data?.info?.status}</TableCell>
      <TableCell>
        {deal.data.info?.dealDates?.fundedAt
          ? new Date(deal.data.info.dealDates.fundedAt)?.toLocaleDateString()
          : ""}
      </TableCell>
      <TableCell>
        <Button
          style={{ margin: "10px 0px" }}
          variant="contained"
          color="primary"
          onClick={() => handleSelectDeal(deal)}
        >
          Select
        </Button>
      </TableCell>
    </>
  );
};

const renderTableBody = (
  deal: Deal,
  index: number,
  classes: ClassNameMap<"tableRow">,
  stateAccess: StateAccess
) => {
  const handleSelectDeal = (deal: Deal) => {
    const chargeback = stateAccess.get<Chargeback>([]);
    const chargebackDeal: ChargebackDeal = {
      contractDate: deal?.data?.info?.dealDates?.contractDate ?? null,
      fundingDate: deal?.data?.info?.dealDates?.fundedAt,
      refNumber: deal?.data?.info?.refNumber,
      applicantFirstName: `${deal?.data?.applicant?.data?.info?.firstName ?? ""}`,
      applicantLastName: `${deal?.data?.applicant?.data?.info?.lastName ?? ""}`,
      vehicle: {
        VIN: deal?.data?.info?.vehicle?.VIN,
        make: deal?.data?.info?.vehicle?.make,
        model: deal?.data?.info?.vehicle?.model
      },
      collateralType: deal?.data?.info?.type,
      interestRate: deal?.data?.info?.payment?.interestRate,
      term: deal?.data?.info?.payment?.numberOfPayments,
      profit: deal?.data?.info?.profit,
      accounting: deal?.data?.info?.accounting
    };

    stateAccess.set<Chargeback>(["data"], {
      ...chargeback?.data,
      dealership: deal?.data?.dealership,
      manager: deal?.data?.user,
      lender: deal?.data?.lender,
      applicant: deal?.data?.applicant,
      dealId: deal._id,
      deal: chargebackDeal
    } as Chargeback);
  };

  return (
    <TableRow
      id={`deal-result-${index}`}
      hover
      className={classes.tableRow}
      onMouseDown={() => handleSelectDeal(deal)}
      key={index}
    >
      {renderTableCells(deal, handleSelectDeal)}
    </TableRow>
  );
};
const renderTableHead = () => {
  return (
    <TableRow>
      <TableCell>Customer</TableCell>
      <TableCell>Reserve</TableCell>
      <TableCell>Extra Reserve</TableCell>
      <TableCell>Ref #</TableCell>
      <TableCell>Dealership</TableCell>
      <TableCell>Lender</TableCell>
      <TableCell>Status</TableCell>
      <TableCell>Date funded</TableCell>
      <TableCell align="center">Action</TableCell>
    </TableRow>
  );
};

export default function ResultsTable({
  entities,
  stateAccess
}: {
  entities: Deal[] | undefined | null;
  stateAccess: StateAccess;
}) {
  const classes = useStyles();

  return (
    <TableContainer style={{ maxHeight: 300 }}>
      <Table size="small" aria-label={`Deals results`}>
        {entities && entities.length > 0 ? (
          <>
            <TableHead>{renderTableHead()}</TableHead>
            <TableBody>
              {entities.map((el: Deal, index: number) =>
                renderTableBody(el, index, classes, stateAccess)
              )}
            </TableBody>
          </>
        ) : entities && entities.length === 0 ? (
          <TableRow key={"no-results"}>
            <TableCell key="no-results" colSpan={5}>
              <Typography style={{ fontStyle: "italic", margin: "auto", textAlign: "center" }}>
                No matching records found
              </Typography>
            </TableCell>
          </TableRow>
        ) : (
          <TableRow key={"loading-row"}>
            <TableCell
              colSpan={5}
              style={{
                textAlign: "center",
                padding: "10px"
              }}
              key={"loading-row"}
            >
              <CircularProgress style={{ margin: "auto" }} size="1.8rem" />
            </TableCell>
          </TableRow>
        )}
      </Table>
    </TableContainer>
  );
}
