import { TitleIssue } from "./types";
import {
  multyPermissionNode,
  permissionNode,
  PermissionStruct,
  relationPermissionNode
} from "components/Roles/types";

export type TitleIssuePermissionsStruct = PermissionStruct<TitleIssue>;

export const titleIssuePermissionStruct: TitleIssuePermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    //@ts-ignore
    lenderTickets: multyPermissionNode("Lender Tickets"),
    titleIssueNotes: relationPermissionNode("Title Issue Notes", "title_issue_note"),
    info: {
      dmvHistory: multyPermissionNode("Dmv History"),
      dmvCheckedCount: permissionNode("Dmv Checked Count"),
      lastCheckedDate: permissionNode("Last Checked Date"),
      lastTaskNumber: permissionNode("Last Task Number"),
      receiptInfo: {
        documents: multyPermissionNode("Documents"),
        WFDDocumentUsed: permissionNode("WFD Documents Used"),
        status: permissionNode("Status"),
        uploadedDate: permissionNode("Uploaded Date")
      },
      titleRegistrationInfo: {
        documents: multyPermissionNode("Documents"),
        WFDDocumentUsed: permissionNode("WFD Documents Used"),
        status: permissionNode("Status"),
        uploadedDate: permissionNode("Uploaded Date")
      },
      oldTitleRegistrationInfo: {
        documents: multyPermissionNode("Documents"),
        WFDDocumentUsed: permissionNode("WFD Documents Used"),
        status: permissionNode("Status"),
        uploadedDate: permissionNode("Uploaded Date")
      },
      dmvInfo: {
        titleImageUrl: permissionNode("Image proof URL"),
        message: permissionNode("Message"),
        status: permissionNode("Status"),
        titledDate: permissionNode("Titled Date")
      }
    },
    dealId: permissionNode("Deal id"),
    deal: relationPermissionNode("Deal", "deal")
  }
};
