import { createSocketMessageSlice } from "components/Middlewares/socketMessageSliceCreator";
import { DeleteOrRecoverContractTypeRequest, DeleteOrRecoverContractTypeResponse } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteOrRecoverContractTypeRequest,
  DeleteOrRecoverContractTypeResponse
>("RecoverContractType", {
  request: "recover_contract_type"
});

export const recoverContractTypeActions = socketMessageSlice.actions;
export const recoverContractType = socketAction;
export default socketMessageSlice.reducer;
