import React, { useRef, useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";
import {
  fillDefaultsByPath,
  generateDefault,
  generateForm,
  getByPath,
  setByPath,
  StateAccess,
  RenderSet
} from "utils/models/formGenerator";
import SaveIcon from "@material-ui/icons/Save";
import { useDispatch } from "react-redux";
import { addFile } from "./addFileSlice";
import { EntityType } from "utils/entitySlice";
import { UploadedFile } from "./FilesPreview";
import { modifyFile } from "components/Files/modifyFile";

export default function AddFileDialog({
  parentId,
  requestId,
  setOpen,
  struct,
  renderSet,
  type,
  uploadedFile,
  isAdditionalContractDocument,
  setUploadedFile
}: {
  parentId: string;
  type: EntityType;
  struct: any;
  requestId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  renderSet: RenderSet;
  isAdditionalContractDocument?: boolean;
  uploadedFile?: UploadedFile | null;
  setUploadedFile: React.Dispatch<React.SetStateAction<UploadedFile | null>>;
}) {
  const dispatch = useDispatch();
  const addFornName = `${type}-file-add`;
  const containerRef = useRef<HTMLDivElement>(null);
  const [addFormState, setAddFormState] = useState<any>(
    generateDefault(
      struct,
      { data: { [type]: { _id: parentId }, isAdditionalContractDocument } },
      fillDefaultsByPath as any
    ) as any
  );

  const addStateAccess: StateAccess = {
    get: (path) => getByPath(addFormState as any, path),
    set: (path, value): any => setAddFormState(setByPath(addFormState as any, path, value))
  };
  useEffect(() => {
    if (uploadedFile) {
      setAddFormState({
        data: {
          ...addFormState.data,
          info: {
            ...addFormState.info,
            url: uploadedFile.url,
            name: uploadedFile.name,
            metadata: uploadedFile.metadata
          }
        }
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile]);

  const addHandleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(
      addFile({
        ...modifyFile(addFormState),
        requestId
      })
    );
    setUploadedFile(null);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
    setUploadedFile(null);
  };
  return (
    <Dialog fullWidth onClose={handleClose} open={true}>
      <DialogContent ref={containerRef}>
        <CloseDialogButton closeFunction={handleClose} />
        <form id={addFornName} autoComplete="off" onSubmit={addHandleSubmit}>
          {generateForm(struct, addStateAccess, [], addStateAccess, renderSet)}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Cancel
        </Button>

        <Button
          form={addFornName}
          type="submit"
          color="primary"
          variant="contained"
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
