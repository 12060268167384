import { useQuery, useMutation } from "@tanstack/react-query";
import { getMessages, createMessage, updateMessage } from "Chat/http/messages";
import { MutationError } from "Chat/types";
import { HttpQuery } from "Chat/types/http";
import { Message, CreateMessageRequest } from "Chat/types/messages";

const useGetMessages = (channelId: string, query?: HttpQuery, enabled = true) => {
  const queryKey = [`messages-${channelId}`];

  const { data, isLoading, isFetching, error } = useQuery<Message[], MutationError, Message[]>({
    queryKey: queryKey,
    queryFn: getMessages(channelId, query),
    enabled
  });

  return {
    data,
    isLoading,
    isFetching,
    error
  };
};

export const useCreateMessage = () => {
  const mutation = useMutation<Message, MutationError, CreateMessageRequest>(createMessage(), {
    onSuccess: () => {
      return;
    }
  });
  return {
    createMessage: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};

export const useUpdateMessage = () => {
  const mutation = useMutation<Message, MutationError, string>(updateMessage(), {
    onSuccess: () => {
      return;
    }
  });
  return {
    updateMessage: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};

export default useGetMessages;
