import { Paper, Theme } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { RootState } from "app/rootReducer";
import { modifyApplicant } from "components/Applicants/modifyApplicant";
import modifyDealership from "components/Dealerships/modifyDealership";
import { calculate } from "components/Deals/Calculations";
import RolesAvailabilityDialog from "components/Roles/AvailableToRolesDialog";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { EntityData, EntityType } from "utils/entitySlice";
import { Model } from "./fields";
import { generateForm, getByPath, setByPath, StateAccess } from "./formGenerator";
import { editRenderSet } from "./formRenderers";
import SubmitButton, { ButtonProperties } from "./SubmitButton";

interface AddFormProps<T> {
  canSubmit: boolean;
  initialState: any;
  model: Model<T>;
  handleSubmit: (state: any) => (event: React.FormEvent) => void;
  buttonProps?: ButtonProperties;
}
const theme = (theme: Theme) =>
  createTheme({
    ...theme,
    typography: {
      fontSize: 12
    }
  });

export const AddForm = <T extends EntityData<EntityType>>({
  canSubmit,
  initialState,
  model,
  handleSubmit,
  buttonProps
}: AddFormProps<T>) => {
  const [formState, setFormState] = React.useState(initialState);
  const get = useCallback((path) => getByPath(formState, path), [formState]);
  const set = useCallback(
    (path, value) => {
      switch (model.name) {
        case "deal":
          return setFormState((internalFormState: any) =>
            calculate(setByPath(internalFormState, path, value), internalFormState)
          );
        case "dealership":
          return setFormState((internalFormState: any) =>
            modifyDealership(setByPath(internalFormState, path, value), internalFormState)
          );
        case "applicant":
          return setFormState((internalFormState: any) =>
            modifyApplicant(setByPath(internalFormState, path, value), internalFormState)
          );
        default:
          return setFormState((internalFormState: any) =>
            setByPath(internalFormState, path, value)
          );
      }
    },
    [formState, model.name]
  );

  const stateAccess: StateAccess = {
    get: get,
    set: set as () => any
  };
  const mainStateAccees: StateAccess = {
    get: (path) => getByPath(formState, path),
    set: (path, value): any => setFormState(setByPath(formState, path, value))
  };
  const userPermissions = useSelector((state: RootState) => state?.authSlice?.user?.permissions);
  return (
    <ThemeProvider theme={theme}>
      <div id={"addEntitydiv-" + model.name}>
        <Paper elevation={3} style={{ padding: "10px 20px" }}>
          <form id={model.name} autoComplete="off" onSubmit={handleSubmit(formState)}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <RolesAvailabilityDialog stateAccess={stateAccess} entityName={model.name} />
            </div>

            {generateForm(
              model,
              stateAccess,
              [],
              mainStateAccees,
              editRenderSet(model.schema),
              userPermissions?.[model.name]
            )}
            <SubmitButton
              formName={model.name}
              stateAccess={stateAccess}
              buttonProps={buttonProps}
              disabled={!canSubmit}
            />
          </form>
        </Paper>
      </div>
    </ThemeProvider>
  );
};
