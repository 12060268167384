import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  TableSortLabel,
  TextField,
  Tooltip,
  useMediaQuery
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { History, MoreHoriz, Warning } from "@material-ui/icons";
import { AppDispatch } from "app/store";
import filtersContext from "components/Content/FiltersContext";
import formEditContext from "components/Content/FormEditContext";
import { Deal } from "components/Deals/types";
import {
  ColumnFilter,
  DateFilters,
  ExistFilters,
  FilterQuery,
  Filters,
  RelationsFilters
} from "components/Filters/types";
import UploadLendingDecisions from "components/LenderDecisions/UploadLendingDecisions";
import { ListState, Normalized, getListProps } from "components/Middlewares/listSliceCreator";
import { Permission } from "components/Roles/types";
import { User } from "components/Users/types";
import { HintTooltip } from "components/common/HintTooltip";
import { useSnackbar } from "notistack";
import React, { Dispatch, useContext, useEffect, useRef, useState } from "react";

import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import AccessControl from "components/Access/AccessControl";
import { ColumnGroup, TableGroupByItem } from "components/GroupedTable/ColumnGroups/types";
import { useDispatch, useSelector } from "react-redux";
import { EntityType } from "utils/entitySlice";
import {
  formatNumberAsCurrency,
  getFirstAndLastDateOfCurrentMonth,
  percentageDifference,
  removeKeyRecursively,
  sanitizeAndConvertToNumber,
  truncate
} from "utils/functions";
import { CrudEntity } from "utils/types";
import { uuid } from "uuidv4";
import { RootState } from "../../app/rootReducer";
import formatDate from "../../utils/formatDate";
import ClearFilters, { StyledBadge } from "../Filters/ClearFilters";
import CustomFilter from "../Filters/CustomFilters";
import AddButton from "./AddButton";
import DeleteButton from "./DeleteButton";
import ExportToExcelButton from "./ExportToExcelButton";
import Grouping from "./Grouping";
import MobileTable from "./MobileTable";
import Print from "./Print";
import RecoverButton from "./RecoverButton";
import Search from "./Search";
import ShowButton from "./ShowButton";
import TableDateFilter from "./TableDateFilter";
import TableSettings, { initializeColumnsSettingsData } from "./TableSettings/TableSettings";
import { getColumnGroupList, removeColumnGroupList } from "./ColumnGroups/listColumnGroupSlice";
import { getByPath, setByPath } from "utils/models/formGenerator";
import { useStickyState } from "index";
export type CellValue = string | number | Date | undefined | JSX.Element | string[];

interface Changeable<T extends any> {
  target: T;
  shouldUpdate: (x: T) => boolean;
  shouldLoad: (x: T) => boolean;
}

type CustomRowEntity = {
  onTransitionEnd?: React.TransitionEventHandler<any> | undefined;
};

export type PrevCurrNextDeals<T> = { prev?: Entry<T>; curr: Entry<T>; next?: Entry<T> };

type customBodyRender<T> = (
  x: CellValue,
  row: Entry<T>,
  rows?: PrevCurrNextDeals<T>
) => string | React.ReactElement | React.ReactElement[];

interface OptionsSort<T> {
  filter?: boolean;
  sort: boolean;
  path: string[];
  customBodyRender?: customBodyRender<T>;
}

interface OptionsNoSort<T> {
  filter?: boolean;
  sort?: never;
  path?: never;
  customBodyRender?: customBodyRender<T>;
}

type Options<T> = OptionsSort<T> | OptionsNoSort<T>;

type MongoCondition = { [key: string]: any };

export type ColumnAggregation = {
  path: string;
  funcName?: string;
  dataModifier?: (entry: Entry<any>, funcName: string | undefined) => Entry<any>;
  transformDate?: string;
  totalType?: "total" | "average";
  possibleFunctions: string[];
  filters?: (Omit<ColumnFilter<any>, "path"> & { path?: string[]; applyOnFunctions: string[] })[];
  testCondition?: MongoCondition;
  aggregated?: boolean;
};

export type TableAggregation = { columnLabel: string; columnAggregation: ColumnAggregation };

export type ColumnGrouping<T> = {
  enabled: boolean;
  grouped?: boolean;
  options?: OptionsSort<T>;
  getData: (el: Entry<T>, showHiddenValues?: boolean) => CellValue;
  getIds?: (el: Entry<T>) => string[];
} & TableGroupByItem;
export interface Column<T> {
  type?: string;
  customLabel?: string;
  compared?: boolean;
  decimalPlaces?: number;
  label: string; //Header of column
  name: string; //Uniqifying labels that are the same
  excelColumns?: string[];
  grouping?: ColumnGrouping<T>;
  disableElipsis?: boolean;
  dateFilterPath?: string[];
  truncate?: number;
  tableSettingsParent?: string;
  default?: string;
  bold?: (entry: Entry<T>) => boolean;
  getData: (el: Entry<T>, showHiddenValues?: boolean) => CellValue;
  getIds?: (el: Entry<T>) => string[];
  options?: Options<T>;
  total?: <T>(entities: Entry<T>[], column: Column<T>) => string | Node;
  sign?: (funcName: string | undefined) => string | undefined;
  columnAggregation?: ColumnAggregation;
  show?: (userPermissions?: Permission<EntityType>, selectedColumns?: any) => boolean;
  filters?: ColumnFilter<T>[];
}
const getTotalsKey = <T extends any>(c: Column<T>) => {
  const totalType = c?.columnAggregation?.totalType;
  switch (c?.columnAggregation?.funcName) {
    case "list-count":
      return totalType === "average" ? "totalsListCountAvg" : "totalsListCount";
    case "list-count-unique":
      return totalType === "average" ? "totalsListCountUniqueAvg" : "totalsListCountUnique";
    case "avg":
    case "sum":
      return totalType === "average" ? "totalsAvg" : "totals";
    default:
      return "";
  }
};
export interface Request {
  requestId: string;
  _id: string;
}

export type ListFunction = (
  _id: string,
  props: getListProps
) => (dispatch: AppDispatch) => Promise<void>;
export type ClearListFunction = ActionCreatorWithPayload<string, string>;

type SortColumn = [number, "asc" | "desc"] | undefined;

export interface TableSettings {
  pagination: boolean;
  page: number;
  rowsPerPage: number;
  sort?: { [x: string]: "asc" | "desc" };
  sortColumn: SortColumn;
  withDeleted: boolean;
  searchTerm: string;
}
export const createChangeable = <T extends any>(
  target: T,
  shouldUpdate: (x: T) => boolean,
  shouldLoad: (x: T) => boolean = (): boolean => false
): Changeable<T> => {
  return { target, shouldUpdate, shouldLoad };
};
const filtersToQuery = (filters: Filters | undefined): FilterQuery => {
  return Object.entries(filters ?? {}).reduce((acc, [key, filter]) => {
    if (key === "$or") {
      if (Array.isArray(filter) && filter?.length) {
        return [
          ...acc,
          { $or: filter.reduce((acc: FilterQuery, x) => [...acc, ...(x ? [x.query] : [])], []) }
        ];
      }
    } else {
      if (Array.isArray(filter)) {
        return [
          ...acc,
          ...filter.reduce((acc: FilterQuery, x) => [...acc, ...(x ? [x.query] : [])], [])
        ];
      }
    }
    return acc;
  }, [] as FilterQuery);
};
export function filterDefaultDateFilter(
  obj: Filters | undefined,
  excludedKeys: (string | undefined)[]
) {
  return Object.keys(obj ?? {}).reduce((acc, key) => {
    if (!excludedKeys.filter((x) => x)?.includes(key)) {
      acc[key] = obj?.[key] as any;
    }
    return acc;
  }, {} as Filters);
}
const refreshTable = (
  settings: TableSettings,
  dispatch: Dispatch<any>,
  listFunction: ListFunction,
  clearListFunction: ClearListFunction | undefined,
  filters: FilterQuery,
  query?: Record<string, unknown> | undefined,
  slice?: string | undefined,
  tableAggregation?: TableAggregation[],
  tableGroupBy?: TableGroupByItem[] | undefined,
  projection?: { [key: string]: number }
): void => {
  filters = (filters ?? [])?.filter((filter) => filter);
  if (query?.["$or"] || query?.["$and"]) {
    const { $or, $and, ...rest } = query;
    query = {
      ...rest,
      ...(Array.isArray($and)
        ? {
            $and: [...$and, ...(filters || [])]
          }
        : {}),
      ...(Array.isArray($or)
        ? {
            $or: [...(filters || []), { $or: $or }]
          }
        : {})
    };
  } else {
    query = {
      ...query,
      ...(Array.isArray(filters) && filters.length > 0 ? { $and: filters } : {})
    };
  }
  if (clearListFunction) {
    dispatch(clearListFunction(slice ?? "table"));
  }
  dispatch(
    listFunction(slice ?? "table", {
      options: {
        projection,
        offset: settings.page * settings.rowsPerPage,
        limit: settings.rowsPerPage,
        tableAggregation: (tableAggregation ?? [])?.map((ta) => {
          const {
            filters,
            aggregated,
            possibleFunctions,
            ...restColumnAggregation
          } = ta?.columnAggregation;
          return {
            ...ta,
            columnAggregation: restColumnAggregation
          };
        }) as TableAggregation[],
        tableGroupBy,
        ...(Object.keys(settings?.sort ?? {}).length > 0 ? { sort: settings.sort } : {})
      },
      withDeleted: settings.withDeleted,
      ...(settings.searchTerm !== "" ? { search: { term: settings.searchTerm } } : { query })
    })
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const useStylesTableSortLabel = makeStyles({
  root: {
    '&:hover:not([class*="active"])': {
      "& svg": {
        opacity: "0 !important"
      }
    }
  }
});

export type Entry<T> = CrudEntity & {
  data: T;
} & Normalized;
const renderEntryDataString = (value: CellValue, isReport: boolean): string => {
  if (React.isValidElement(value)) {
    return "";
  } else {
    return (renderEntryData(value, isReport) as unknown) as string;
  }
};
export const renderEntryData = (
  value: CellValue,
  isReport: boolean,
  truncateLength?: number
): string | JSX.Element => {
  if (value instanceof Date) {
    return formatDate(value, "short");
  } else if (typeof value === "number" && isReport) {
    return formatNumberAsCurrency(value) ?? "0.00";
  } else if (React.isValidElement(value)) {
    return value;
  } else {
    return value && truncateLength
      ? truncate(value.toString(), truncateLength) ?? ""
      : value
      ? value.toString()
      : "";
  }
};
export type customEntity = {
  customRow: (attrs: CustomRowEntity) => JSX.Element;
};

const isCustomEntity = <T extends unknown>(
  x: Entry<T> | customEntity | undefined
): x is customEntity => typeof x === "object" && x?.hasOwnProperty("customRow");

const dealLogFilter = (deal: Deal) =>
  deal.data.info.type &&
  deal.data.info.status !== "lead" &&
  deal.data.info.status !== "credit check";

const checkEntityAvailabilityRoles = <T extends unknown>(entry: Entry<T>, user?: User) => {
  if (user && entry?.availableToRolesIds && entry?.availableToRolesIds?.length > 0) {
    return entry?.availableToRolesIds?.some((r) => user?.data?.rolesIds?.includes(r));
  }
  return true;
};
const filterEntries = <T extends unknown>(
  entries: (Entry<T> | customEntity)[] | undefined,
  tableSettings: TableSettings,
  renderTimestamp: number,
  entitiesType: "regular" | "compared",
  user?: User,
  listEntity?: ListState<Entry<T> | customEntity>,
  modificationForNewEntries?: boolean
) =>
  entries
    ?.filter((entry) => {
      if (isCustomEntity(entry)) {
        return true;
      } else {
        return (!entry.deleted || tableSettings.withDeleted) && entry?.createdAt
          ? (renderTimestamp > new Date(entry.createdAt).getTime() || !modificationForNewEntries) &&
              checkEntityAvailabilityRoles(entry, user)
          : true;
      }
    })
    ?.map((entry, index) => ({
      prev:
        entitiesType === "compared"
          ? listEntity?.comparison?.entities?.[index - 1]
          : listEntity?.entities?.[index - 1],
      curr: entry,
      next:
        entitiesType === "compared"
          ? listEntity?.comparison?.entities?.[index + 1]
          : listEntity?.entities?.[index + 1]
    }));

const clearEntries = <T extends unknown>(entries: {
  prev: customEntity | Entry<T> | undefined;
  curr: Entry<T>;
  next: customEntity | Entry<T> | undefined;
}): {
  prev: Entry<T> | undefined;
  curr: Entry<T>;
  next: Entry<T> | undefined;
} => ({
  prev: isCustomEntity(entries.prev) ? undefined : entries.prev,
  curr: entries.curr,
  next: isCustomEntity(entries.next) ? undefined : entries.next
});

export type FilteredEntities<T> = {
  prev: customEntity | Entry<T> | undefined;
  curr: customEntity | Entry<T>;
  next: customEntity | Entry<T> | undefined;
}[];
const listWithoutCustomEntities = <T extends unknown>(
  listEntity: ListState<Entry<T> | customEntity> | undefined
) => ({
  total: listEntity?.total ?? 0,
  totals: {},
  entities: listEntity?.entities?.filter((el): el is Entry<T> => !isCustomEntity<T>(el))
});
const GroupedTable = <T extends unknown>({
  filterState,
  setFilterState,
  dateFilterColumns,
  setDateFilterColumns,
  dateFilterState,
  setDateFilterState,
  queryState,
  setQueryState,
  filters,
  setFilters,
  tableSettings,
  refreshTableCallback,
  setTableSettingsState,
  compareMode,
  setCompareMode,
  columnGroupsList,
  columns,
  tableGroupBy,
  tableAggregation,
  setTableAggregation,
  setColumns,
  groupName,
  groupIsDefault,
  setGroupName,
  setGroupIsDefault,
  groupColumns,
  setGroupColumns,
  setGroupColumnsAutocompleteState,
  setSelectedColumnGrouping,
  selectedColumnGrouping,
  groupColumnsAutocompleteState,
  aggregationColumns,
  setAggregationColumns,
  setTableGroupBy,
  showHiddenValues,
  tableName,
  title,
  defaultColumns,
  defaultColumnGroupQuery,
  entityName,
  showModel,
  modificationForNewEntries = true,
  showUpload = false,
  showTotalFooter = false,
  isReport = false,
  hideShowButton = false,
  openShowEditInModal = false,
  hideGrouping = false,
  defaultDateFilterColumnName,
  hideTableDateFilter = false,
  hideSearch = false,
  hideDeleteButton = false,
  hideAddButton = false,
  hideRecoverButton = false,
  hideExcelExportButton = false,
  hidePagination = false,
  hidePrintButton = false,
  hideFilters = false,
  pagination = true,
  withOrder = false,
  query,
  toolbarStyle,
  toolbarVariant = "regular",
  customCellFontSize,
  customActionsStyle = {},
  slice,
  groupingType,
  listEntity,
  listFunction,
  clearListFunction,
  deleteEntityFunction,
  addEntityFunction,
  recoverEntityFunction,
  editAction = undefined,
  elevation = 3,
  sort,
  rowsPerPage,
  dateFilters = [
    { label: "Created At", path: ["createdAt"] },
    { label: "Updated At", path: ["updatedAt"] }
  ],
  relationFilters = [],
  existFilters = {
    filters: [],
    name: ""
  },
  projection,
  columnGroupRequestId,
  dealLogTable = false,
  externalRenderTime,
  renewMode = false,
  filterByDateByDefault = false,
  hideSettings = false,
  showAdditionalColumns = false,
  tableContainerOverflowX = "inherit",
  renderChildTable = () => <></>,
  expandable = false
}: {
  filterState:
    | {
        values: {
          startDate: Date;
          endDate: Date;
        };
      }
    | undefined;
  setFilterState: React.Dispatch<
    React.SetStateAction<
      | {
          values: {
            startDate: Date;
            endDate: Date;
          };
        }
      | undefined
    >
  >;
  dateFilterColumns: Column<T>[] | undefined;
  setDateFilterColumns: React.Dispatch<React.SetStateAction<Column<T>[] | undefined>>;
  dateFilterState: {
    from: string;
    to: string;
    fromCompare?: string;
    toCompare?: string;
  };
  setDateFilterState: React.Dispatch<
    React.SetStateAction<{
      from: string;
      to: string;
      fromCompare?: string;
      toCompare?: string;
    }>
  >;
  queryState: Record<string, unknown> | undefined;
  setQueryState: React.Dispatch<React.SetStateAction<Record<string, unknown> | undefined>>;
  setTableSettingsState: React.Dispatch<React.SetStateAction<TableSettings>>;
  compareMode: boolean;
  setCompareMode: React.Dispatch<React.SetStateAction<boolean>>;
  filters: Filters | undefined;
  setFilters: (
    state: (Filters | undefined) | ((oldState: Filters | undefined) => Filters | undefined)
  ) => void;
  tableSettings: TableSettings;
  refreshTableCallback: (filters: FilterQuery, tableSettings: TableSettings, query?: any) => void;
  columnGroupsList: ColumnGroup[] | undefined;
  columns: Column<T>[];
  tableGroupBy: TableGroupByItem[];
  tableAggregation: TableAggregation[];
  setTableAggregation: React.Dispatch<React.SetStateAction<TableAggregation[]>>;
  setColumns: React.Dispatch<React.SetStateAction<Column<T>[]>>;
  groupName: string;
  groupIsDefault: boolean;
  setGroupName: React.Dispatch<React.SetStateAction<string>>;
  setGroupIsDefault: React.Dispatch<React.SetStateAction<boolean>>;
  groupColumns: Column<T>[];
  setGroupColumns: React.Dispatch<React.SetStateAction<Column<T>[]>>;
  setGroupColumnsAutocompleteState: React.Dispatch<React.SetStateAction<Column<T>[]>>;
  setSelectedColumnGrouping: React.Dispatch<React.SetStateAction<ColumnGroup | null>>;
  selectedColumnGrouping: ColumnGroup | null;
  groupColumnsAutocompleteState: Column<T>[];
  aggregationColumns: Column<T>[];
  setAggregationColumns: React.Dispatch<React.SetStateAction<Column<T>[]>>;
  setTableGroupBy: React.Dispatch<React.SetStateAction<TableGroupByItem[]>>;
  groupingType: string;
  filterByDateByDefault?: boolean;
  tableName: string;
  title: string | React.ReactElement;
  entityName: EntityType;
  defaultColumns: Column<T>[];
  defaultColumnGroupQuery: Record<string, any>;
  listEntity?: ListState<Entry<T> | customEntity>;
  modificationForNewEntries?: boolean;
  hideShowButton?: boolean;
  hideDeleteButton?: boolean;
  hideAddButton?: boolean;
  hideRecoverButton?: boolean;
  openShowEditInModal?: boolean;
  hideGrouping?: boolean;
  hideTableDateFilter?: boolean;
  defaultDateFilterColumnName?: string;
  hideSearch?: boolean;
  hidePagination?: boolean;
  hidePrintButton?: boolean;
  hideFilters?: boolean;
  showModel?: undefined | string;
  showUpload?: boolean | string;
  showTotalFooter?: boolean;
  isReport?: boolean;
  pagination?: boolean;
  withOrder?: boolean;
  columnGroupRequestId: string;
  query?: Record<string, unknown>;
  toolbarStyle?: Record<string, unknown>;
  toolbarVariant?: "regular" | "dense";
  customCellFontSize?: string;
  customActionsStyle?: React.CSSProperties;
  elevation?: number;
  slice?: string;
  sort?: { [_key: string]: "desc" | "asc" };
  rowsPerPage?: number;
  listFunction: ListFunction;
  clearListFunction?: ClearListFunction;
  editAction?: (entry: Entry<T>) => void;
  addAction?: () => void;
  deleteEntityFunction?: (request: Request) => (dispatch: AppDispatch) => Promise<void>;
  addEntityFunction?: () => void;
  recoverEntityFunction?: (request: Request) => (dispatch: AppDispatch) => Promise<void>;
  hideExcelExportButton?: boolean;
  dateFilters?: DateFilters<T>;
  relationFilters?: RelationsFilters<T>;
  existFilters?: ExistFilters<T>;
  projection?: { [key: string]: number };
  dealLogTable?: boolean;
  externalRenderTime?: number;
  renewMode?: boolean;
  hideSettings?: boolean;
  showAdditionalColumns?: boolean;
  showHiddenValues?: boolean;
  tableContainerOverflowX?: "inherit" | "scroll" | "auto" | "clip" | "hidden" | "visible";
  renderChildTable?: (ids: string[], tableGroupBy: TableGroupByItem[]) => JSX.Element;
  expandable: boolean;
}): JSX.Element => {
  const user = useSelector((state: RootState) => state?.authSlice?.user);
  const tableColumnSettings = useSelector((state: RootState) =>
    state.listTableSettingsSlice["table"]?.entities?.find(
      (setting) => setting?.data?.tableName === tableName
    )
  );
  const userPermissions = user?.permissions?.[entityName];

  const { enqueueSnackbar } = useSnackbar();
  const { enabled: editMode } = useContext(formEditContext);
  const [renderTimestamp, setRenderTimestamp] = useState(new Date().getTime());
  const hiddenEntries = modificationForNewEntries
    ? listWithoutCustomEntities(listEntity)?.entities?.filter((el) => {
        return (
          new Date(el.createdAt).getTime() > renderTimestamp &&
          (dealLogTable ? dealLogFilter(el as Deal) : true)
        );
      }) ?? []
    : [];

  const setTableSettings = (newState: TableSettings) => {
    setTableSettingsState(newState);
    refreshTableCallback(
      filtersToQuery(compareMode ? filters : removeKeyRecursively(filters, "compare")),
      newState
    );
  };
  const dispatch = useDispatch();

  const columnsFiltered = React.useCallback(() => {
    const columnsClone = [...columns];
    const duplicateColumnsToAdd: [Column<T>, number][] = [];
    columnsClone.map((c, i) => {
      if (!c?.grouping?.grouped && compareMode && listEntity?.comparison) {
        duplicateColumnsToAdd.push([c, i]);
      }
    });
    duplicateColumnsToAdd.reverse().map(([c, i]) => {
      columnsClone.splice(i + 1, 0, {
        ...c,
        compared: true,
        name: `${c.name} (Compared)`,
        label: `${c.label} (Compared)`,
        ...(c?.options ? ({ options: { ...c.options, sort: false } } as Options<T>) : {}),
        ...(c?.grouping
          ? {
              grouping: {
                ...(c?.grouping ?? ({} as ColumnGrouping<T>)),
                options: { ...(c?.grouping?.options ?? {}), sort: false } as OptionsSort<T>
              }
            }
          : {}),
        ...(c?.columnAggregation
          ? { columnAggregation: { ...c.columnAggregation, filters: undefined } }
          : {}),
        filters: undefined
      });
    });
    const permissionFiltered = columnsClone.filter((c) =>
      c.show
        ? c.show(userPermissions, tableColumnSettings ?? initializeColumnsSettingsData(columns))
        : true
    );

    return permissionFiltered;
  }, [
    userPermissions,
    tableColumnSettings,
    filters,
    columns,
    listEntity?.comparison,
    showAdditionalColumns
  ]);
  // Not filtering as we may want some calculations to show and will let show indicate whether to visualize

  useEffect(() => {
    refreshTableCallback(
      filtersToQuery(compareMode ? filters : removeKeyRecursively(filters, "compare")),
      tableSettings
    );
  }, [filters, columns]);

  useEffect(() => {
    if (JSON.stringify(queryState) !== JSON.stringify(query)) {
      setQueryState(query);
      refreshTableCallback(
        filtersToQuery(compareMode ? filters : removeKeyRecursively(filters, "compare")),
        tableSettings,
        query
      );
    }
  }, [query, queryState]);
  useEffect(() => {
    if (externalRenderTime && externalRenderTime !== renderTimestamp) {
      setRenderTimestamp(externalRenderTime);
    }
  }, [externalRenderTime, renderTimestamp, setRenderTimestamp]);
  useEffect(() => {
    if (listEntity?.entities === undefined) {
      refreshTable(
        tableSettings,
        dispatch,
        listFunction,
        clearListFunction,
        filtersToQuery(compareMode ? filters : removeKeyRecursively(filters, "compare")),
        queryState,
        slice,
        tableAggregation,
        tableGroupBy,
        projection
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = useStyles();
  const tableSortLabelClass = useStylesTableSortLabel();
  const tableRef = useRef(null);

  const handleChangePage = (_: unknown, newPage: number): void => {
    setTableSettings({ ...tableSettings, page: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTableSettings({ ...tableSettings, page: 0, rowsPerPage: parseInt(event.target.value) });
  };

  const actionsCell = (entry: Entry<T>): JSX.Element => (
    <TableCell key="actions" size="small" style={{ whiteSpace: "nowrap", textAlign: "right" }}>
      <Box displayPrint="none" style={customActionsStyle}>
        {editAction !== undefined ? (
          <IconButton
            id={entityName + "-" + entry._id}
            size="small"
            color="primary"
            aria-label="show more"
            style={{ marginRight: "10px" }}
            onClick={(): void => editAction(entry)}
            disabled={!editMode}
          >
            <MoreHoriz />
          </IconButton>
        ) : hideShowButton ? null : (
          <ShowButton
            renewMode={renewMode}
            entityName={entityName}
            _id={entry._id}
            showModel={showModel}
            openShowEditInModal={openShowEditInModal}
          />
        )}

        {hideDeleteButton || editMode === false ? null : (
          <DeleteButton
            entityName={entityName}
            _id={entry._id}
            deleteEntityFunction={deleteEntityFunction}
            callback={() => refreshTableCallback(filtersToQuery(filters), tableSettings)}
          />
        )}
      </Box>
    </TableCell>
  );

  const recoverCell = (_id: string): JSX.Element => (
    <TableCell key="actions" size="small" style={{ whiteSpace: "nowrap", textAlign: "right" }}>
      <Box displayPrint="none">
        {hideShowButton ? null : (
          <ShowButton entityName={entityName} _id={_id} showModel={showModel} />
        )}
        {hideRecoverButton ? null : (
          <RecoverButton _id={_id} recoverEntityFunction={recoverEntityFunction} />
        )}
      </Box>
    </TableCell>
  );
  const approvedCell = (entity?: Entry<T>): JSX.Element => (
    <TableCell key="approved" size="small" style={{ whiteSpace: "nowrap", textAlign: "left" }}>
      <Box displayPrint="none">
        {entity?.approved ? (
          <>{`${entity?.approvedByUser?.data?.info.firstName ?? ""} ${
            entity?.approvedByUser?.data?.info.lastName ?? ""
          }`}</>
        ) : (
          <>Not approved</>
        )}
      </Box>
    </TableCell>
  );

  const sortByColumn = (path: string[] | undefined, sort = {}): void => {
    if (path !== undefined) {
      const key = path.join(".");

      if (tableSettings?.sort?.[key] === undefined) {
        sort = { [key]: "asc" };
      } else if (tableSettings?.sort?.[key] === "asc") {
        sort = { [key]: "desc" };
      } else if (tableSettings?.sort?.[key] === "desc") {
        sort = removeKey(key, tableSettings?.sort);
      }
      setTableSettings({ ...tableSettings, sort });
    }
  };

  const removeKey = (key: string, { [key]: _, ...rest }): Record<string, unknown> => rest;
  const [requestId] = useState(uuid());

  const orderActionState = useSelector(
    // @ts-ignore
    (state: RootState) => state?.[`${entityName}OrderSlice`]?.[requestId]
  );

  useEffect(() => {
    if (orderActionState?.status === "success") {
      refreshTable(
        tableSettings,
        dispatch,
        listFunction,
        clearListFunction,
        filtersToQuery(compareMode ? filters : removeKeyRecursively(filters, "compare")),
        queryState,
        slice,
        tableAggregation,
        tableGroupBy,
        projection
      );
      enqueueSnackbar("Successfully reordered!", { variant: "success" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderActionState]);
  const filteredEntities: FilteredEntities<T> = (filterEntries(
    listEntity?.entities,
    tableSettings,
    renderTimestamp,
    "regular",
    user?.databaseData,
    listEntity,
    modificationForNewEntries
  ) as unknown) as FilteredEntities<T>;
  const filteredComparedEntities: FilteredEntities<T> = (filterEntries(
    listEntity?.comparison?.entities,
    tableSettings,
    renderTimestamp,
    "compared",
    user?.databaseData,
    listEntity,
    modificationForNewEntries
  ) as unknown) as FilteredEntities<T>;
  const [expanded, setExpanded] = useState<{ [key: string]: { expanded: boolean; ids: string[] } }>(
    {}
  );
  const ColumnsFiltered = columnsFiltered();

  const dataTree = ColumnsFiltered?.[0]?.grouping?.grouped
    ? filteredEntities?.reduce((acc, entry) => {
        const path = ColumnsFiltered?.filter((column) => column?.grouping?.grouped)?.reduce(
          (acc, column) => {
            return [
              ...acc,
              (column?.grouping?.getData(entry?.curr as Entry<T>) as string)?.toString() ?? "N/A"
            ];
          },
          [] as string[]
        );
        const oldEntries = getByPath(acc, path);
        return setByPath(acc, path, [...(oldEntries || []), entry]);
      }, {})
    : {};

  function includesSubarray(arr: string[][], subarr: string[]) {
    return arr.some(
      (item) =>
        Array.isArray(item) &&
        item.length === subarr.length &&
        item.every((val, index) => val === subarr[index])
    );
  }
  function sumArrayLengths(obj: Record<string, any>) {
    let totalLength = 0;

    function recurse(current: Record<string, any>) {
      if (Array.isArray(current)) {
        totalLength += current.length;
      } else if (typeof current === "object" && current !== null) {
        for (const key in current) {
          if (current.hasOwnProperty(key)) {
            recurse(current[key]);
          }
        }
      }
    }

    recurse(obj);
    return totalLength;
  }
  const pathsUsed: string[][] = [];
  const isMobile = useMediaQuery("(max-width: 800px)");
  const memoizedTableHead = React.useMemo(
    () => (
      <TableHead style={{ background: "#f1f1f1" }}>
        <TableRow>
          {columnsFiltered()?.map((column, index) => {
            return column.label === "Actions" ? (
              <TableCell
                key={column.label + index}
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "right",
                  border: "1px solid #76767673"
                }}
              >
                <Box displayPrint="none">{column.label}</Box>
              </TableCell>
            ) : (
              <TableCell
                key={column.label + index}
                size="small"
                style={{
                  whiteSpace: "normal",
                  maxWidth: "1%",
                  border: "1px solid #76767673"
                }}
              >
                {column?.grouping?.options?.sort || column.options?.sort ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <TableSortLabel
                      className={tableSortLabelClass.root}
                      active={
                        tableSettings.sort &&
                        tableSettings.sort[
                          column?.grouping?.grouped
                            ? (column?.grouping?.options?.path?.join(".") as string)
                            : (column?.options?.path?.join(".") as string)
                        ] !== undefined
                      }
                      direction={
                        tableSettings.sort &&
                        tableSettings.sort[
                          column?.grouping?.grouped
                            ? (column?.grouping?.options?.path?.join(".") as string)
                            : (column?.options?.path?.join(".") as string)
                        ]
                      }
                      onClick={(): void => {
                        sortByColumn(
                          column?.grouping?.grouped
                            ? column?.grouping?.options?.path
                            : column.options?.path
                        );
                      }}
                    >
                      {column.label}
                    </TableSortLabel>
                    {column?.columnAggregation?.filters?.every((filter) => filter?.path) &&
                    column?.columnAggregation?.aggregated ? (
                      <CustomFilter
                        setFilters={(filters: Filters[string]) => {
                          return setFilters((x) => ({ ...x, [column.name]: filters }));
                        }}
                        columnFilters={column.columnAggregation?.filters as ColumnFilter<T>[]}
                        label={column?.label}
                        filters={filters?.[column.name]}
                      />
                    ) : (
                      column?.filters && (
                        <CustomFilter
                          setFilters={(filters: Filters[string]) => {
                            return setFilters((x) => ({ ...x, [column.name]: filters }));
                          }}
                          columnFilters={column.filters}
                          label={column?.label}
                          filters={filters?.[column.name]}
                        />
                      )
                    )}
                  </div>
                ) : column.filters ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <Box displayPrint="none">{column.label}</Box>
                    {column.filters && (
                      <CustomFilter
                        setFilters={(filters: Filters[string]) =>
                          setFilters((x) => ({ ...x, [column.name]: filters }))
                        }
                        columnFilters={column.filters}
                        label={column?.label}
                        filters={filters?.[column.name]}
                      />
                    )}
                  </div>
                ) : (
                  column.label
                )}
              </TableCell>
            );
          })}
          {withOrder && (
            <TableCell style={{ width: "10px", border: "1px solid #76767673" }}></TableCell>
          )}
        </TableRow>
      </TableHead>
    ),
    [columnsFiltered, listEntity?.comparison, tableColumnSettings, tableSettings]
  );
  return (
    <Paper
      className={classes.root}
      elevation={elevation}
      style={{ tableLayout: "fixed", display: "table" }}
    >
      <Toolbar
        variant={toolbarVariant}
        style={{ ...(isMobile ? { display: "block" } : {}), ...toolbarStyle }}
      >
        <Typography variant="body1" style={isMobile ? {} : { flex: "1 1 100%" }} component="div">
          {title}
        </Typography>
        {hiddenEntries && hiddenEntries?.length > 0 && (
          <Chip
            color="primary"
            avatar={<Avatar>{hiddenEntries?.length}</Avatar>}
            label="New entries"
            id="new entries button"
            onClick={(): void => {
              // refreshTableCallback();
              setRenderTimestamp(new Date().getTime());
            }}
          />
        )}
        {!hideTableDateFilter && (
          <TableDateFilter
            filterState={filterState}
            setFilterState={setFilterState}
            groupColumns={groupColumns}
            compareMode={compareMode}
            setCompareMode={setCompareMode}
            filters={filters}
            setFilters={setFilters}
            dateState={dateFilterState}
            setDateState={setDateFilterState}
            setDateFilterColumns={setDateFilterColumns}
            dateFilterColumns={dateFilterColumns}
            defaultDateFilterColumnName={defaultDateFilterColumnName}
            defaultDateColumns={(defaultColumns ?? []).filter((column) => column.type === "date")}
          />
        )}
        {tableGroupBy.length > 0 && (
          <>
            <Box
              component="span"
              style={{
                position: "relative",
                whiteSpace: "nowrap"
              }}
            >
              <StyledBadge badgeContent={1} color="primary">
                <Button
                  style={{ color: "#1c4e6e", background: "#fff", fontSize: "12px" }}
                  variant="outlined"
                  onClick={(_: React.MouseEvent) => {
                    setTableGroupBy([]);
                    setTableAggregation([]);
                    dispatch(clearListFunction?.(slice ?? "table"));
                    setCompareMode(false);
                    setColumns(defaultColumns);
                  }}
                >
                  Remove grouping
                </Button>
              </StyledBadge>
            </Box>
          </>
        )}
        {!hideGrouping && (
          <AccessControl requiredPermissions={{ entity: "column_group", action: "read" }}>
            <Grouping<T>
              setGroupColumnsAutocompleteState={setGroupColumnsAutocompleteState}
              setSelectedColumnGrouping={setSelectedColumnGrouping}
              selectedColumnGrouping={selectedColumnGrouping}
              setAggregationColumns={setAggregationColumns}
              aggregationColumns={aggregationColumns}
              groupColumnsAutocompleteState={groupColumnsAutocompleteState}
              columnGroupsList={columnGroupsList}
              groupName={groupName}
              setGroupName={setGroupName}
              groupIsDefault={groupIsDefault}
              setGroupIsDefault={setGroupIsDefault}
              groupingType={groupingType}
              groupColumns={groupColumns}
              setGroupColumns={setGroupColumns}
              setCompareMode={setCompareMode}
              compareMode={compareMode}
              setTableAggregation={setTableAggregation}
              columnGroupRequestId={columnGroupRequestId}
              requestId={"table"}
              defaultColumns={defaultColumns}
              clearFunction={() => {
                if (clearListFunction) {
                  dispatch(clearListFunction?.(slice ?? "table"));
                }
              }}
              setTableGroupBy={setTableGroupBy}
              setColumns={setColumns}
            />
          </AccessControl>
        )}
        {!hideSearch && (
          <Search setTableSettings={setTableSettings} tableSettings={tableSettings} />
        )}
        {(relationFilters.length > 0 ||
          dateFilters?.length > 0 ||
          existFilters?.filters?.length > 0) &&
          !hideFilters && (
            <ClearFilters
              setFilters={setFilters}
              filtersCount={
                Object.values(filters ?? {})?.filter((x) => x.filter((x) => x)?.length > 0)?.length
              }
            />
          )}
        {listEntity && !hidePrintButton && (
          <Print
            tableRef={tableRef}
            entityName={entityName}
            listEntity={listWithoutCustomEntities(listEntity)}
          />
        )}
        {!hideRecoverButton && (
          <HintTooltip title={tableSettings.withDeleted ? "Hide deleted" : "Show deleted"}>
            <IconButton
              onClick={(): void => {
                setTableSettings({
                  ...tableSettings,
                  withDeleted: !tableSettings.withDeleted
                });
              }}
              aria-label={`Recover ${entityName}`}
            >
              <History />
            </IconButton>
          </HintTooltip>
        )}
        {!hideExcelExportButton && (
          <ExportToExcelButton
            entityName={entityName}
            entries={listWithoutCustomEntities(listEntity)?.entities ?? []}
            columns={columnsFiltered()}
          />
        )}
        {showUpload && <UploadLendingDecisions />}
        {hideAddButton ? null : (
          <AddButton entityName={entityName} addEntityFunction={addEntityFunction} />
        )}
        {!hideSettings && <TableSettings<T> {...{ tableName, columns: defaultColumns }} />}
      </Toolbar>

      {isMobile ? (
        filteredEntities ? (
          <MobileTable<T> columns={columnsFiltered()} entries={filteredEntities} />
        ) : (
          <div
            style={{
              textAlign: "center",
              fontSize: "20px"
            }}
          >
            Loading...
          </div>
        )
      ) : (
        <TableContainer
          style={{ overflowX: tableContainerOverflowX, overflowY: "auto", display: "block" }}
          ref={tableRef}
        >
          <Table size="small">
            {memoizedTableHead}
            {!filteredEntities && (
              <TableBody>
                <TableRow key={"loading-row"}>
                  <TableCell
                    colSpan={columnsFiltered().length}
                    style={{
                      textAlign: "center",
                      fontSize: "20px"
                    }}
                    key={"loading-row"}
                  >
                    Loading...
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {(filteredEntities?.reduce((acc, cur) => {
              if (isCustomEntity(cur.curr) || isCustomEntity(cur.prev)) {
                return [...acc, [cur]];
              }
              if (acc[acc.length - 1]) {
                acc[acc.length - 1] = [...acc[acc.length - 1], cur];
              } else {
                return [...acc, [cur]];
              }
              return acc;
            }, [] as FilteredEntities<T>[]) as FilteredEntities<T>[])?.map((entities, index) => (
              <TableBody key={index}>
                {entities?.map((entries, entityIndex) => {
                  const entry = entries.curr;
                  if (isCustomEntity(entry)) {
                    return entry.customRow({});
                  }
                  const comparedEntry = (filteredComparedEntities ?? [])?.find(
                    (entries) => (entries?.curr as Entry<T>)?._id === entry._id
                  )?.curr;
                  const clearedEntries = clearEntries({ ...entries, curr: entry });
                  return (
                    <>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={entry._id}
                        style={
                          entry.deleted
                            ? { background: "rgba(245, 0, 85, 0.16)" }
                            : {
                                background: entityIndex % 2 === 1 ? "#f1f1f1" : "none",
                                ...(expandable && expanded[entry._id]?.expanded
                                  ? { background: "#8ac67e" }
                                  : {})
                              }
                        }
                      >
                        {ColumnsFiltered.map((column, columnIndex) => {
                          const data = column?.getData(entry, showHiddenValues);
                          const comparedData = comparedEntry
                            ? column?.getData(comparedEntry as Entry<T>, showHiddenValues)
                            : undefined;
                          const cellValuesAreNumbers =
                            typeof data === "number" && typeof comparedData === "number";
                          let percentageDiff: number | undefined = undefined;
                          let percentageDiffIsPositive: boolean | undefined = undefined;
                          if (cellValuesAreNumbers) {
                            percentageDiff = percentageDifference(data, comparedData);
                            percentageDiffIsPositive = data >= comparedData;
                          }
                          const realEntry = (column.compared ? comparedEntry : entry) as Entry<T>;

                          const entryData = renderEntryData(
                            column?.grouping?.grouped
                              ? column.grouping.getData(realEntry, showHiddenValues)
                              : column.getData(realEntry, showHiddenValues),
                            isReport,
                            column?.truncate
                          );
                          let rowspan = 1;
                          if (column?.grouping?.grouped) {
                            const path = ColumnsFiltered.slice(0, columnIndex + 1).map(
                              (c) =>
                                c.grouping?.getData(entry, showHiddenValues)?.toString() ?? "N/A"
                            );

                            const groups = getByPath(dataTree, path);
                            if (includesSubarray(pathsUsed, path)) {
                              return <></>;
                            }
                            if (!Array.isArray(groups)) {
                              rowspan = sumArrayLengths(groups);
                              pathsUsed.push(path);
                            }
                          }

                          if (typeof column.options?.customBodyRender === "function") {
                            return (
                              <TableCell
                                rowSpan={rowspan}
                                key={realEntry._id + columnIndex}
                                onClick={() => {
                                  //@ts-ignore
                                  if (expandable && realEntry?.data?._ids?.length)
                                    setExpanded((prev) => ({
                                      ...prev,
                                      [entry._id]: {
                                        expanded: !prev?.[entry._id as string]?.expanded,
                                        ids: column?.getIds
                                          ? column?.getIds(realEntry) || []
                                          : //@ts-ignore
                                            realEntry?.data?._ids
                                      }
                                    }));
                                }}
                                size="small"
                                style={{
                                  fontSize: customCellFontSize,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  ...(column.disableElipsis
                                    ? {}
                                    : { textOverflow: "ellipsis", maxWidth: 150 }),
                                  ...(column?.grouping?.grouped && !expanded[entry._id]?.expanded
                                    ? {
                                        background: "white",
                                        border: "1px solid #c7c7c7",
                                        fontWeight: "bold"
                                      }
                                    : {})
                                }}
                              >
                                {column.options.customBodyRender(
                                  column?.grouping?.grouped
                                    ? column?.grouping?.getData(realEntry, showHiddenValues)
                                    : column.getData(realEntry, showHiddenValues),
                                  entry,
                                  clearedEntries
                                )}
                              </TableCell>
                            );
                          }
                          return column.label === "Actions" ? (
                            realEntry.deleted ? (
                              recoverCell(realEntry?._id)
                            ) : (
                              actionsCell(realEntry)
                            )
                          ) : column.label === "Approver" ? (
                            approvedCell(realEntry)
                          ) : (
                            <TableCell
                              onClick={() => {
                                //@ts-ignore
                                if (expandable && realEntry?.data?._ids?.length)
                                  setExpanded((prev) => ({
                                    ...prev,
                                    [entry._id]: {
                                      expanded: !prev?.[entry._id as string]?.expanded,
                                      ids: column?.getIds
                                        ? column?.getIds(realEntry) || []
                                        : //@ts-ignore
                                          realEntry?.data?._ids
                                    }
                                  }));
                              }}
                              rowSpan={rowspan}
                              style={{
                                fontSize: customCellFontSize,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                ...(column.disableElipsis
                                  ? {}
                                  : { textOverflow: "ellipsis", maxWidth: 150 }),
                                ...(column?.grouping?.grouped && !expanded[entry._id]?.expanded
                                  ? {
                                      background: "white",
                                      border: "1px solid #c7c7c7",
                                      fontWeight: "bold"
                                    }
                                  : {})
                              }}
                              title={renderEntryDataString(
                                column?.grouping?.grouped
                                  ? column?.grouping?.getData(realEntry, showHiddenValues)
                                  : column.getData(realEntry, showHiddenValues),
                                isReport
                              )}
                              key={realEntry?._id + columnIndex}
                              size="small"
                            >
                              {column.bold && column.bold(realEntry) ? (
                                <b>{entryData}</b>
                              ) : entryData ? (
                                cellValuesAreNumbers && compareMode && !column.compared ? (
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    {entryData}
                                    {typeof percentageDiff === "number" ? (
                                      <Typography
                                        style={{
                                          fontSize: "10px",
                                          color: percentageDiffIsPositive ? "green" : "red"
                                        }}
                                      >
                                        {` (${
                                          percentageDiffIsPositive ? "+" : "-"
                                        }${percentageDiff.toFixed(2)}%)`}
                                      </Typography>
                                    ) : null}
                                  </div>
                                ) : (
                                  entryData || "N/A"
                                )
                              ) : (
                                `N/A`
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      {expandable && expanded?.[entry._id]?.expanded ? (
                        <TableRow>
                          <TableCell
                            style={{ maxWidth: "1vw", overflowY: "auto", padding: 0 }}
                            colSpan={ColumnsFiltered.length}
                          >
                            {renderChildTable(
                              expanded?.[entry._id]?.ids?.filter((x) => x) ?? [],
                              tableGroupBy
                            )}
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  );
                })}
              </TableBody>
            ))}

            {showTotalFooter && listEntity?.entities && (
              <TableFooter>
                <TableRow>
                  {columnsFiltered()
                    ?.map((c) => ({
                      ...c,
                      name: c.name.replace(" (Compared)", ""),
                      label: c.label.replace(" (Compared)", "")
                    }))
                    .map((column, index) => {
                      const transformedListEntityPath = column?.compared
                        ? listEntity?.comparison
                        : listEntity;
                      const totalsKey = getTotalsKey(column);
                      let totalsValue: string | undefined = undefined;
                      let totalsValueAsNumber: number | undefined = undefined;
                      let comparedTotalsValueAsNumber: number | undefined = undefined;
                      let percentageDiff: number | undefined = undefined;
                      let percentageDiffIsPositive: boolean | undefined = undefined;
                      let subtotalValue: number | undefined = undefined;
                      let comparedSubtotalValue: number | undefined = undefined;
                      let subtotalPercentageDiff: number | undefined = undefined;
                      let subtotalPercentageDiffIsPositive: boolean | undefined = undefined;
                      if (totalsKey) {
                        if (column?.columnAggregation?.funcName === "avg") {
                          if (column?.columnAggregation?.totalType === "average") {
                            totalsValue =
                              formatNumberAsCurrency(
                                (transformedListEntityPath?.[totalsKey]?.[column.name + "-total"] ||
                                  0) /
                                  (transformedListEntityPath?.[totalsKey]?.[
                                    column.name + "-count"
                                  ] || 1),
                                column.sign?.(column?.columnAggregation?.funcName)
                              ) ??
                              `${column.sign?.(column?.columnAggregation?.funcName) ?? ""}0.00`;
                            totalsValueAsNumber =
                              (transformedListEntityPath?.[totalsKey]?.[column.name + "-total"] ||
                                0) /
                              (transformedListEntityPath?.[totalsKey]?.[column.name + "-count"] ||
                                1);
                            comparedTotalsValueAsNumber =
                              (listEntity?.comparison?.[totalsKey]?.[column.name + "-total"] || 0) /
                              (listEntity?.comparison?.[totalsKey]?.[column.name + "-count"] || 1);
                          } else {
                            totalsValue =
                              formatNumberAsCurrency(
                                transformedListEntityPath?.["totalsAvg"]?.[
                                  column.name + "-count"
                                ] || 0,
                                column.sign?.(column?.columnAggregation?.funcName)
                              ) ??
                              `${column.sign?.(column?.columnAggregation?.funcName) ?? ""}0.00`;
                            totalsValueAsNumber =
                              transformedListEntityPath?.["totalsAvg"]?.[column.name + "-count"] ||
                              0;
                            comparedTotalsValueAsNumber =
                              listEntity?.comparison?.["totalsAvg"]?.[column.name + "-count"] || 0;
                          }
                        } else {
                          if (column?.columnAggregation?.totalType === "average") {
                            totalsValue =
                              formatNumberAsCurrency(
                                (transformedListEntityPath?.[
                                  column.columnAggregation?.funcName === "sum"
                                    ? "totals"
                                    : totalsKey
                                ]?.[column.name + "-total"] || 0) /
                                  (transformedListEntityPath?.[totalsKey]?.[
                                    column.name + "-count"
                                  ] || 1),
                                column.sign?.(column?.columnAggregation?.funcName)
                              ) ??
                              `${column.sign?.(column?.columnAggregation?.funcName) ?? ""}0.00`;
                            totalsValueAsNumber =
                              (transformedListEntityPath?.[
                                column.columnAggregation?.funcName === "sum" ? "totals" : totalsKey
                              ]?.[column.name + "-total"] || 0) /
                              (transformedListEntityPath?.[totalsKey]?.[column.name + "-count"] ||
                                1);
                            comparedTotalsValueAsNumber =
                              (listEntity?.comparison?.[
                                column.columnAggregation?.funcName === "sum" ? "totals" : totalsKey
                              ]?.[column.name + "-total"] || 0) /
                              (listEntity?.comparison?.[totalsKey]?.[column.name + "-count"] || 1);
                          } else {
                            totalsValue =
                              formatNumberAsCurrency(
                                transformedListEntityPath?.[totalsKey]?.[column.name + "-total"] ||
                                  0,
                                column.sign?.(column?.columnAggregation?.funcName)
                              ) ??
                              `${column.sign?.(column?.columnAggregation?.funcName) ?? ""}0.00`;
                            totalsValueAsNumber =
                              transformedListEntityPath?.[totalsKey]?.[column.name + "-total"] || 0;
                            comparedTotalsValueAsNumber =
                              listEntity?.comparison?.[totalsKey]?.[column.name + "-total"] || 0;
                          }
                        }
                      }
                      if (comparedTotalsValueAsNumber && totalsValueAsNumber) {
                        percentageDiff = percentageDifference(
                          totalsValueAsNumber,
                          comparedTotalsValueAsNumber
                        );
                        percentageDiffIsPositive =
                          totalsValueAsNumber >= comparedTotalsValueAsNumber;
                      }
                      if (column?.total) {
                        subtotalValue = sanitizeAndConvertToNumber(
                          column.total(
                            listWithoutCustomEntities(listEntity).entities ?? [],
                            column
                          ) as string
                        );
                        comparedSubtotalValue = sanitizeAndConvertToNumber(
                          column.total(
                            listWithoutCustomEntities(listEntity?.comparison).entities ?? [],
                            column
                          ) as string
                        );
                        if (subtotalValue && comparedSubtotalValue) {
                          subtotalPercentageDiff = percentageDifference(
                            subtotalValue,
                            comparedSubtotalValue
                          );
                          subtotalPercentageDiffIsPositive = subtotalValue >= comparedSubtotalValue;
                        }
                      }
                      return (
                        <TableCell
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "14px",
                            verticalAlign: "top"
                          }}
                          key={index}
                        >
                          {listEntity?.columnAggs?.length > 0 &&
                            transformedListEntityPath?.columnAggs.length > 0 && (
                              <table style={{ whiteSpace: "nowrap" }}>
                                <tbody>
                                  {column?.total &&
                                    ["sum", "avg", "list-count", "list-count-unique"].includes(
                                      column.columnAggregation?.funcName ?? ""
                                    ) &&
                                    !(
                                      (transformedListEntityPath?.total || 0) <=
                                        (tableSettings?.rowsPerPage || 0) &&
                                      tableSettings.page === 0
                                    ) && (
                                      <tr>
                                        <td>
                                          {column?.columnAggregation?.totalType
                                            ? column?.columnAggregation?.totalType === "average"
                                              ? "Subaverage"
                                              : "Subtotal"
                                            : column.columnAggregation?.funcName === "avg"
                                            ? "Subaverage"
                                            : "Subtotal"}
                                          :
                                        </td>
                                        <td>
                                          {compareMode && !column.compared ? (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                              {column?.total &&
                                                column.total(
                                                  listWithoutCustomEntities(
                                                    transformedListEntityPath
                                                  ).entities ?? [],
                                                  column
                                                )}
                                              {typeof subtotalPercentageDiff === "number" ? (
                                                <Typography
                                                  style={{
                                                    fontSize: "10px",
                                                    color: subtotalPercentageDiffIsPositive
                                                      ? "green"
                                                      : "red"
                                                  }}
                                                >
                                                  {" "}
                                                  {`(${
                                                    subtotalPercentageDiffIsPositive ? "+" : "-"
                                                  }${subtotalPercentageDiff.toFixed(2)}%)`}
                                                </Typography>
                                              ) : null}
                                            </div>
                                          ) : (
                                            column?.total &&
                                            column.total(
                                              listWithoutCustomEntities(transformedListEntityPath)
                                                .entities ?? [],
                                              column
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                  {column?.total &&
                                    ["sum", "avg", "list-count", "list-count-unique"].includes(
                                      column.columnAggregation?.funcName ?? ""
                                    ) && (
                                      <tr>
                                        <td>
                                          {column?.columnAggregation?.totalType
                                            ? column?.columnAggregation?.totalType === "average"
                                              ? "Average"
                                              : "Total"
                                            : column.columnAggregation?.funcName === "avg"
                                            ? "Average"
                                            : "Total"}
                                          :
                                        </td>
                                        <td>
                                          {compareMode && !column.compared ? (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                              {totalsValue}
                                              {typeof percentageDiff === "number" ? (
                                                <Typography
                                                  style={{
                                                    fontSize: "10px",
                                                    color: percentageDiffIsPositive
                                                      ? "green"
                                                      : "red"
                                                  }}
                                                >
                                                  {" "}
                                                  {`(${
                                                    percentageDiffIsPositive ? "+" : "-"
                                                  }${percentageDiff.toFixed(2)}%)`}
                                                </Typography>
                                              ) : null}
                                            </div>
                                          ) : (
                                            totalsValue
                                          )}
                                        </td>
                                      </tr>
                                    )}

                                  {transformedListEntityPath?.columnAggs?.map(
                                    (content: { [key: string]: number }) => {
                                      return Object.keys(content)
                                        .sort()
                                        .map((keyString: string, innerIndex: number) => {
                                          const col = keyString.split("-")[0];
                                          const agg = keyString.split("-")[1];

                                          if (col === column.label) {
                                            const capitalized =
                                              agg?.charAt(0).toUpperCase() + agg.slice(1);
                                            return (
                                              <tr key={innerIndex}>
                                                <td>{capitalized.replace("Sum", "Total")}:</td>
                                                <td>
                                                  {formatNumberAsCurrency(content[keyString])}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        });
                                    }
                                  )}
                                </tbody>
                              </table>
                            )}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      )}
      {!hidePagination && (
        <TablePagination
          rowsPerPageOptions={[50, 200, 400, 800, listEntity?.total || 0].filter(
            (el) => el <= (listEntity ? listEntity?.total : 200)
          )}
          component="div"
          count={listEntity?.total || 0}
          rowsPerPage={tableSettings.rowsPerPage}
          page={tableSettings.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default <T extends unknown>({
  showHiddenValues,
  tableName,
  title,
  defaultColumns,
  defaultColumnGroupQuery,
  entityName,
  showModel,
  modificationForNewEntries = true,
  showUpload = false,
  showTotalFooter = false,
  isReport = false,
  hideShowButton = false,
  openShowEditInModal = false,
  hideGrouping = false,
  defaultDateFilterColumnName,
  hideTableDateFilter = false,
  hideSearch = false,
  hideDeleteButton = false,
  hideAddButton = false,
  hideRecoverButton = false,
  hideExcelExportButton = false,
  hidePagination = false,
  hidePrintButton = false,
  hideFilters = false,
  pagination = true,
  withOrder = false,
  query,
  toolbarStyle,
  toolbarVariant = "regular",
  customCellFontSize,
  customActionsStyle = {},
  slice,
  groupingType,
  listEntity,
  listFunction,
  clearListFunction,
  deleteEntityFunction,
  addEntityFunction,
  recoverEntityFunction,
  editAction = undefined,
  elevation = 3,
  sort,
  rowsPerPage,
  dateFilters = [
    { label: "Created At", path: ["createdAt"] },
    { label: "Updated At", path: ["updatedAt"] }
  ],
  relationFilters = [],
  existFilters = {
    filters: [],
    name: ""
  },
  projection,
  dealLogTable = false,
  externalRenderTime,
  renewMode = false,
  filterByDateByDefault = false,
  hideSettings = false,
  setGroupedTableResetStickyCallback,
  showAdditionalColumns = false,
  tableContainerOverflowX = "inherit",
  renderChildTable = () => <></>,
  expandable = false
}: {
  setGroupedTableResetStickyCallback?: React.Dispatch<
    React.SetStateAction<(() => void) | undefined>
  >;
  groupingType: string;
  filterByDateByDefault?: boolean;
  tableName: string;
  title: string | React.ReactElement;
  entityName: EntityType;
  defaultColumns: Column<T>[];
  defaultColumnGroupQuery: Record<string, any>;
  listEntity?: ListState<Entry<T> | customEntity>;
  modificationForNewEntries?: boolean;
  hideShowButton?: boolean;
  hideDeleteButton?: boolean;
  hideAddButton?: boolean;
  hideRecoverButton?: boolean;
  openShowEditInModal?: boolean;
  hideGrouping?: boolean;
  hideTableDateFilter?: boolean;
  defaultDateFilterColumnName?: string;
  hideSearch?: boolean;
  hidePagination?: boolean;
  hidePrintButton?: boolean;
  hideFilters?: boolean;
  showModel?: undefined | string;
  showUpload?: boolean | string;
  showTotalFooter?: boolean;
  isReport?: boolean;
  pagination?: boolean;
  withOrder?: boolean;
  query?: Record<string, unknown>;
  toolbarStyle?: Record<string, unknown>;
  toolbarVariant?: "regular" | "dense";
  customCellFontSize?: string;
  customActionsStyle?: React.CSSProperties;
  elevation?: number;
  slice?: string;
  sort?: { [_key: string]: "desc" | "asc" };
  rowsPerPage?: number;
  listFunction: ListFunction;
  clearListFunction?: ClearListFunction;
  editAction?: (entry: Entry<T>) => void;
  addAction?: () => void;
  deleteEntityFunction?: (request: Request) => (dispatch: AppDispatch) => Promise<void>;
  addEntityFunction?: () => void;
  recoverEntityFunction?: (request: Request) => (dispatch: AppDispatch) => Promise<void>;
  hideExcelExportButton?: boolean;
  dateFilters?: DateFilters<T>;
  relationFilters?: RelationsFilters<T>;
  existFilters?: ExistFilters<T>;
  projection?: { [key: string]: number };
  dealLogTable?: boolean;
  externalRenderTime?: number;
  renewMode?: boolean;
  hideSettings?: boolean;
  showAdditionalColumns?: boolean;
  showHiddenValues?: boolean;
  tableContainerOverflowX?: "inherit" | "scroll" | "auto" | "clip" | "hidden" | "visible";
  renderChildTable?: (ids: string[], tableGroupBy: TableGroupByItem[]) => JSX.Element;
  expandable: boolean;
}): JSX.Element => {
  const [refreshTimeout, setRefreshTimeoutState] = useState<NodeJS.Timeout | undefined>(undefined);
  const [queryState, setQueryState] = useState(query);
  const [compareMode, setCompareMode] = useStickyState(false, `${tableName}_compareMode`);
  const [tableSettings, setTableSettingsState] = useState<TableSettings>({
    pagination: pagination,
    page: 0,
    rowsPerPage: rowsPerPage ?? 50,
    sort,
    sortColumn: undefined,
    withDeleted: false,
    searchTerm: ""
  });

  const refreshTableCallback = (
    filters: FilterQuery,
    tableSettings: TableSettings,
    query: any = undefined
  ): void => {
    if (refreshTimeout) clearTimeout(refreshTimeout);
    setRefreshTimeoutState(
      setTimeout(() => {
        refreshTable(
          tableSettings,
          dispatch,
          listFunction,
          clearListFunction,
          filters,
          query ?? queryState,
          slice,
          tableAggregation,
          tableGroupBy,
          projection
        );
      }, 200)
    );
  };

  const { filters: allFilters, setFilters: setFiltersState } = React.useContext(filtersContext);
  const filters = allFilters[tableName];
  const setFilters = (
    state: (Filters | undefined) | ((oldState: Filters | undefined) => Filters | undefined),
    refreshTable = true
  ) => {
    const newState = typeof state === "function" ? state(filters) : state;
    setFiltersState((old) => ({ ...old, [tableName]: newState ?? {} }));

    if (refreshTable) {
      refreshTableCallback(
        filtersToQuery(compareMode ? newState : removeKeyRecursively(newState, "compare")),
        tableSettings
      );
    }
  };
  const [columnGroupsListRequestId] = React.useState(uuid());
  const dispatch = useDispatch();
  const columnGroupsList = useSelector(
    (state: RootState) => state.listColumnGroupSlice[columnGroupsListRequestId]
  );
  const [dateFilterColumns, setDateFilterColumns] = useStickyState<Column<T>[] | undefined>(
    undefined,
    `${tableName}_dateFilterColumns`
  );
  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);
  const [columns, setColumns] = React.useState<Column<T>[]>(defaultColumns);
  const [tableGroupBy, setTableGroupBy] = React.useState<TableGroupByItem[] | undefined>(undefined);
  const [tableAggregation, setTableAggregation] = React.useState<TableAggregation[]>([]);

  const modifyGroupColumns = (columns: { name: string; transformDate: string | undefined }[]) => {
    const columnNames = columns.map((c) => c.name);
    return defaultColumns
      .sort((a, b) => columnNames.indexOf(a.name) - columnNames.indexOf(b.name))
      .filter(
        (defaultColumn) =>
          columns.map((c) => c.name).includes(defaultColumn.name) &&
          defaultColumn?.grouping?.enabled
      )
      .map((column) => {
        return {
          ...column,
          name: column.name,
          grouping: {
            ...(column?.grouping ?? ({} as ColumnGrouping<any>)),
            transformDate: columns?.find((c) => c.name === column.name)?.transformDate,
            grouped: true
          }
        };
      });
  };

  const modifyAggregationColumns = (
    columns: {
      name: string;
      customLabel?: string;
      decimalPlaces?: number;
      funcName: string;
      totalType: "total" | "average" | undefined;
      total?: (<T>(entities: Entry<T>[], column: Column<T>) => string | Node) | undefined;
      transformDate: string | undefined;
    }[]
  ) => {
    return columns.map((c) => {
      if (Object.keys(c).length === 0) {
        return ({} as unknown) as Column<T>;
      }
      const foundInDefault = defaultColumns.find((defaultColumn) => defaultColumn.name === c.name);
      const aggregationPath = `${c?.name}-${c?.funcName}`;
      const filtersForFunction = foundInDefault?.columnAggregation?.filters?.filter((f) =>
        f.applyOnFunctions.includes(c?.funcName ?? "")
      );
      return {
        ...foundInDefault,
        getData: (entry) => {
          if (
            foundInDefault?.type === "number" ||
            ["avg", "list-count", "sum", "list-count-unique"].includes(c?.funcName as string)
          ) {
            if (foundInDefault?.columnAggregation?.dataModifier) {
              return (
                formatNumberAsCurrency(
                  (foundInDefault?.columnAggregation?.dataModifier(
                    entry?.data?.[aggregationPath],
                    c?.funcName
                  ) as unknown) as number | undefined | null,
                  foundInDefault?.sign?.(c?.funcName),
                  typeof foundInDefault?.decimalPlaces === "number"
                    ? foundInDefault.decimalPlaces
                    : undefined
                ) ??
                foundInDefault?.default ??
                "N/A"
              );
            }
            return (
              formatNumberAsCurrency(
                entry?.data?.[aggregationPath],
                foundInDefault?.sign?.(c?.funcName),
                typeof c?.decimalPlaces === "number" ? c?.decimalPlaces : undefined
              ) ??
              foundInDefault?.default ??
              "N/A"
            );
          } else {
            if (foundInDefault?.columnAggregation?.dataModifier) {
              return foundInDefault?.columnAggregation?.dataModifier(
                entry?.data?.[aggregationPath] ?? foundInDefault?.default ?? "N/A",
                foundInDefault?.columnAggregation?.funcName
              );
            }
            return entry?.data?.[aggregationPath] ?? foundInDefault?.default ?? "N/A";
          }
        },
        getIds: (entry) => entry?.data?.[`${aggregationPath}-ids`],
        customLabel: c?.customLabel,
        label:
          typeof c?.customLabel === "string"
            ? c.customLabel
            : [foundInDefault?.label, c?.funcName].filter((x) => x).join(" "),
        ...(typeof c?.decimalPlaces === "number" ? { decimalPlaces: c?.decimalPlaces } : {}),
        name: foundInDefault?.name,
        type: foundInDefault?.type,
        decimalPlaces: c?.decimalPlaces ?? foundInDefault?.decimalPlaces,
        total: c?.total ?? foundInDefault?.total,
        truncate: foundInDefault?.truncate,
        columnAggregation: {
          ...(foundInDefault?.columnAggregation ?? {}),
          funcName: c?.funcName,
          transformDate: c?.transformDate,
          totalType: c?.totalType,
          aggregated: true
        },
        options: {
          path: [aggregationPath],
          sort: true
        },
        filters:
          c?.funcName === "list"
            ? []
            : filtersForFunction?.length
            ? filtersForFunction.map((filter) => ({
                ...filter,
                filterType: "aggregatedColumnFilter",
                path: [aggregationPath],
                name: foundInDefault?.name,
                label: `${foundInDefault?.label} ${c?.funcName}`
              }))
            : foundInDefault?.filters
      } as Column<any>;
    });
  };

  const [groupColumns, setGroupColumnsSticky] = useStickyState<Column<T>[]>(
    [],
    `${tableName}_groupColumns`,
    modifyGroupColumns
  );
  const setGroupColumns = (state: Column<T>[] | ((oldState: Column<T>[]) => Column<T>[])) => {
    const newState = typeof state === "function" ? state(groupColumns) : state;

    setGroupColumnsSticky([
      ...((newState.map((c) => ({
        name: c.name,
        transformDate: c?.grouping?.transformDate
      })) as unknown) as Column<T>[])
    ]);
  };

  const [aggregationColumns, setAggregationColumnsSticky] = useStickyState<Column<T>[]>(
    [({} as unknown) as Column<T>],
    `${tableName}_aggregationColumns`,
    modifyAggregationColumns
  );
  const setAggregationColumns = (state: Column<T>[] | ((oldState: Column<T>[]) => Column<T>[])) => {
    const newState = typeof state === "function" ? state(aggregationColumns) : state;
    setAggregationColumnsSticky([
      ...((newState.map((c) =>
        Object.keys(c)?.length === 0
          ? {}
          : {
              name: c?.name,
              customLabel: c?.customLabel,
              funcName: c?.columnAggregation?.funcName,
              decimalPlaces: c?.decimalPlaces,
              totalType: c?.columnAggregation?.totalType,
              total: c?.total,
              transformDate: c?.grouping?.transformDate
            }
      ) as unknown) as Column<T>[])
    ]);
  };

  const [selectedColumnGrouping, setSelectedColumnGrouping] = useStickyState<ColumnGroup | null>(
    null,
    `${tableName}_selectedColumnGrouping`
  );

  const [groupColumnsAutocompleteState, setGroupColumnsAutocompleteStateSticky] = useStickyState<
    Column<any>[]
  >([], `${tableName}_groupColumnsAutocompleteState`, modifyGroupColumns);
  const setGroupColumnsAutocompleteState = (
    state: Column<T>[] | ((oldState: Column<T>[]) => Column<T>[])
  ) => {
    const newState = typeof state === "function" ? state(groupColumnsAutocompleteState) : state;

    setGroupColumnsAutocompleteStateSticky([
      ...((newState.map((c) => ({
        name: c.name,
        transformDate: c?.grouping?.transformDate
      })) as unknown) as Column<T>[])
    ]);
  };

  const [groupName, setGroupName] = useStickyState("", `${tableName}_groupName`);
  const [groupIsDefault, setGroupIsDefault] = useStickyState(false, `${tableName}_groupIsDefault`);
  const [dateFilterState, setDateFilterState] = useStickyState<{
    from: string;
    to: string;
    fromCompare?: string;
    toCompare?: string;
  }>(getFirstAndLastDateOfCurrentMonth(), `${tableName}_dateFilterState`);
  const [filterState, setFilterState] = useStickyState<
    | {
        values: {
          startDate: Date;
          endDate: Date;
        };
      }
    | undefined
  >(undefined, `${tableName}_filterState`);
  const defaultColumnGroup = (columnGroupsList?.entities ?? [])?.filter(
    (columnGroup) =>
      columnGroup?.data?.info?.isDefault &&
      !columnGroup.deleted &&
      columnGroup?.data?.userId === currentUser?._id
  )?.[0];
  const handleGroup = (groupColumns: Column<any>[], aggregationColumns: Column<any>[]) => {
    // clearFunction();
    setTableGroupBy(
      groupColumns.map((column) => ({
        key: (column?.grouping as TableGroupByItem)?.key,
        path: (column?.grouping as TableGroupByItem)?.path,
        transformDate: (column?.grouping as TableGroupByItem)?.transformDate
      }))
    );
    setTableAggregation(
      aggregationColumns
        .map((c) => {
          return c.columnAggregation && c.columnAggregation?.aggregated
            ? { columnLabel: c.name, columnAggregation: c.columnAggregation }
            : null;
        })
        .filter((x) => x) as TableAggregation[]
    );
    setColumns([...groupColumns, ...aggregationColumns]);
  };
  React.useEffect(() => {
    if (defaultDateFilterColumnName && filterByDateByDefault && !Array.isArray(dateFilterColumns)) {
      const foundColumn = (defaultColumns ?? [])
        .filter((column) => column.type === "date")
        .find((column) => column.name === defaultDateFilterColumnName);
      if (foundColumn) {
        setDateFilterColumns([foundColumn]);
        const filter = [foundColumn].reduce((acc: Filters, c) => {
          if (c?.dateFilterPath && c?.name) {
            const columnFilter = {
              query: {
                [c.dateFilterPath.join(".")]: {
                  $gte: dateFilterState.from,
                  $lte: dateFilterState.to,
                  ...(dateFilterState?.fromCompare && dateFilterState?.toCompare && compareMode
                    ? {
                        compare: {
                          $gte: dateFilterState.fromCompare,
                          $lte: dateFilterState.toCompare
                        }
                      }
                    : {})
                }
              },
              values: {
                startDate: new Date(dateFilterState.from),
                endDate: new Date(dateFilterState.to)
              }
            };

            const x = [...(filters?.[c?.name] ?? [])];
            x[0] = columnFilter;
            return {
              ...acc,
              [c.name]: x
            };
          }
          return acc;
        }, {});
        setFilters(
          (prev) => ({
            ...filterDefaultDateFilter(prev, [defaultDateFilterColumnName]),
            ...filter
          }),
          false
        );
        setFilterState({
          values: {
            startDate: new Date(dateFilterState.from),
            endDate: new Date(dateFilterState.to)
          }
        });
      }
    }
  }, []);
  React.useEffect(() => {
    if (!columnGroupsList)
      dispatch(
        getColumnGroupList(columnGroupsListRequestId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } },
          query: {
            deleted: false,
            ...(defaultColumnGroupQuery ?? {})
          }
        })
      );
    return () => {
      dispatch(removeColumnGroupList(columnGroupsListRequestId));
      return;
    };
  }, []);
  React.useEffect(() => {
    if (columnGroupsList?.entities !== undefined) {
      if (defaultColumnGroup && groupColumns?.length < 1) {
        dispatch(clearListFunction?.(slice ?? "table"));
        setSelectedColumnGrouping(defaultColumnGroup);
        const aggregationColumns = defaultColumnGroup.data.info.aggregationColumns.map((ac) => {
          const foundInDefault = defaultColumns.find(
            (defaultColumn) => defaultColumn.name === ac.columnName
          );
          const aggregationPath = `${ac?.columnName}-${ac?.funcName}`;
          const filtersForFunction = foundInDefault?.columnAggregation?.filters?.filter((f) =>
            f.applyOnFunctions.includes(ac?.funcName ?? "")
          );
          return {
            ...foundInDefault,
            getData: (entry) => {
              if (
                foundInDefault?.type === "number" ||
                ["avg", "list-count", "sum", "list-count-unique"].includes(ac?.funcName as string)
              ) {
                if (foundInDefault?.columnAggregation?.dataModifier) {
                  return (
                    formatNumberAsCurrency(
                      (foundInDefault?.columnAggregation?.dataModifier(
                        entry?.data?.[aggregationPath],
                        ac?.funcName
                      ) as unknown) as number | undefined | null,
                      foundInDefault?.sign?.(ac?.funcName),
                      typeof foundInDefault?.decimalPlaces === "number"
                        ? foundInDefault.decimalPlaces
                        : undefined
                    ) ??
                    foundInDefault?.default ??
                    "N/A"
                  );
                }
                return (
                  formatNumberAsCurrency(
                    entry?.data?.[aggregationPath],
                    foundInDefault?.sign?.(ac?.funcName),
                    typeof ac?.decimalPlaces === "number" ? ac?.decimalPlaces : undefined
                  ) ??
                  foundInDefault?.default ??
                  "N/A"
                );
              } else {
                if (foundInDefault?.columnAggregation?.dataModifier) {
                  return foundInDefault?.columnAggregation?.dataModifier(
                    entry?.data?.[aggregationPath] ?? foundInDefault?.default ?? "N/A",
                    foundInDefault?.columnAggregation?.funcName
                  );
                }
                return entry?.data?.[aggregationPath] ?? foundInDefault?.default ?? "N/A";
              }
            },
            getIds: (entry) => entry?.data?.[`${aggregationPath}-ids`],
            label: ac?.customLabel ? ac.customLabel : `${foundInDefault?.label} ${ac?.funcName}`,
            ...(typeof ac?.decimalPlaces === "number" ? { decimalPlaces: ac?.decimalPlaces } : {}),
            name: foundInDefault?.name,
            type: foundInDefault?.type,
            truncate: foundInDefault?.truncate,
            columnAggregation: {
              ...(foundInDefault?.columnAggregation ?? {}),
              funcName: ac?.funcName,
              transformDate: ac?.transformDate,
              aggregated: true
            },
            options: {
              path: [aggregationPath],
              sort: true
            },
            filters:
              ac?.funcName === "list"
                ? []
                : filtersForFunction?.length
                ? filtersForFunction.map((filter) => ({
                    ...filter,
                    filterType: "aggregatedColumnFilter",
                    path: [aggregationPath],
                    name: foundInDefault?.name,
                    label: `${foundInDefault?.label} ${ac?.funcName}`
                  }))
                : foundInDefault?.filters
          } as Column<any>;
        });
        setAggregationColumns(aggregationColumns);
        const filteredGroupColumns = defaultColumns
          .filter(
            (defaultColumn) =>
              defaultColumnGroup.data.info.groupColumns.includes(defaultColumn.name) &&
              defaultColumn?.grouping?.enabled
          )
          .map((column) => {
            const transformDate = defaultColumnGroup?.data?.info?.grouping?.find(
              (groupEntry) => groupEntry?.key === column?.grouping?.key
            )?.transformDate;
            return {
              ...column,
              name: column.name,
              grouping: {
                ...(column?.grouping ?? ({} as ColumnGrouping<any>)),
                transformDate,
                grouped: true
              }
            };
          });
        setGroupName(defaultColumnGroup?.data?.info?.name);
        setGroupIsDefault(defaultColumnGroup?.data?.info?.isDefault);
        setGroupColumns(filteredGroupColumns);
        setGroupColumnsAutocompleteState(filteredGroupColumns);
        handleGroup(filteredGroupColumns, aggregationColumns);
      } else if (
        groupColumns?.length &&
        aggregationColumns?.length &&
        groupColumnsAutocompleteState?.length
      ) {
        handleGroup(
          groupColumns,
          aggregationColumns?.filter((c) => c?.columnAggregation?.funcName)
        );
      } else {
        setTableGroupBy([]);
      }
    }
  }, [columnGroupsList?.entities]);

  const handleResetStickyState = () => {
    setFiltersState((old) => ({ ...old, [tableName]: {} }));
    setDateFilterColumns(undefined);
    setCompareMode(false);
    setGroupColumnsSticky([]);
    setAggregationColumnsSticky([]);
    setSelectedColumnGrouping(null);
    setGroupColumnsAutocompleteStateSticky([]);
    setGroupName("");
    setGroupIsDefault(false);
    setDateFilterState(getFirstAndLastDateOfCurrentMonth());
    setFilterState(undefined);
    setTableGroupBy([]);
    setTableAggregation([]);
  };

  React.useEffect(() => {
    setGroupedTableResetStickyCallback?.(() => handleResetStickyState);
  }, []);

  return ![undefined, "loading"].includes(columnGroupsList?.status) &&
    (filterByDateByDefault ? dateFilterColumns?.length || filters !== undefined : true) &&
    Array.isArray(tableGroupBy) ? (
    <GroupedTable<T>
      filterState={filterState}
      setFilterState={setFilterState}
      dateFilterColumns={dateFilterColumns}
      setDateFilterColumns={setDateFilterColumns}
      dateFilterState={dateFilterState}
      setDateFilterState={setDateFilterState}
      queryState={queryState}
      setQueryState={setQueryState}
      filters={filters}
      setFilters={setFilters}
      setTableSettingsState={setTableSettingsState}
      compareMode={compareMode}
      setCompareMode={setCompareMode}
      tableSettings={tableSettings}
      refreshTableCallback={refreshTableCallback}
      columnGroupsList={columnGroupsList?.entities}
      columns={columns}
      tableGroupBy={tableGroupBy}
      setTableGroupBy={setTableGroupBy as React.Dispatch<React.SetStateAction<TableGroupByItem[]>>}
      tableAggregation={tableAggregation}
      setTableAggregation={setTableAggregation}
      setColumns={setColumns}
      groupName={groupName}
      groupIsDefault={groupIsDefault}
      setGroupName={setGroupName}
      setGroupIsDefault={setGroupIsDefault}
      groupColumns={groupColumns}
      setGroupColumns={setGroupColumns}
      setGroupColumnsAutocompleteState={setGroupColumnsAutocompleteState}
      setSelectedColumnGrouping={setSelectedColumnGrouping}
      selectedColumnGrouping={selectedColumnGrouping}
      groupColumnsAutocompleteState={groupColumnsAutocompleteState}
      aggregationColumns={aggregationColumns}
      setAggregationColumns={setAggregationColumns}
      showHiddenValues={showHiddenValues}
      tableName={tableName}
      title={title}
      columnGroupRequestId={columnGroupsListRequestId}
      defaultColumns={defaultColumns}
      defaultColumnGroupQuery={defaultColumnGroupQuery}
      entityName={entityName}
      showModel={showModel}
      modificationForNewEntries={modificationForNewEntries}
      showUpload={showUpload}
      showTotalFooter={showTotalFooter}
      isReport={isReport}
      hideShowButton={hideShowButton}
      openShowEditInModal={openShowEditInModal}
      hideGrouping={hideGrouping}
      defaultDateFilterColumnName={defaultDateFilterColumnName}
      hideTableDateFilter={hideTableDateFilter}
      hideSearch={hideSearch}
      hideDeleteButton={hideDeleteButton}
      hideAddButton={hideAddButton}
      hideRecoverButton={hideRecoverButton}
      hideExcelExportButton={hideExcelExportButton}
      hidePagination={hidePagination}
      hidePrintButton={hidePrintButton}
      hideFilters={hideFilters}
      pagination={pagination}
      withOrder={withOrder}
      query={query}
      toolbarStyle={toolbarStyle}
      toolbarVariant={toolbarVariant}
      customCellFontSize={customCellFontSize}
      customActionsStyle={customActionsStyle}
      slice={slice}
      groupingType={groupingType}
      listEntity={listEntity}
      listFunction={listFunction}
      clearListFunction={clearListFunction}
      deleteEntityFunction={deleteEntityFunction}
      addEntityFunction={addEntityFunction}
      recoverEntityFunction={recoverEntityFunction}
      editAction={editAction}
      elevation={elevation}
      sort={sort}
      rowsPerPage={rowsPerPage}
      dateFilters={dateFilters}
      relationFilters={relationFilters}
      existFilters={existFilters}
      projection={projection}
      dealLogTable={dealLogTable}
      externalRenderTime={externalRenderTime}
      renewMode={renewMode}
      filterByDateByDefault={filterByDateByDefault}
      hideSettings={hideSettings}
      showAdditionalColumns={showAdditionalColumns}
      tableContainerOverflowX={tableContainerOverflowX}
      renderChildTable={renderChildTable}
      expandable={expandable}
    />
  ) : (
    <></>
  );
};
