import { FormControlLabel, Checkbox } from "@material-ui/core";
import { Deal, ExpressInsurance } from "components/Deals/types";
import React from "react";
import { StateAccess } from "utils/models/formGenerator";
import { DynamicSurcharge } from "./types";

export const handleChangeSurchargeForDeal = (index: number) => {
  return (
    selectedSurcharge: DynamicSurcharge,
    surcharges: DynamicSurcharge[],
    stateAccess: StateAccess
  ) => {
    const dynamicSurcharges = surcharges?.map((surcharge: DynamicSurcharge) =>
      surcharge.SurchargeCode === selectedSurcharge.SurchargeCode
        ? {
            ...surcharge,
            Value: surcharge.Value === "true" ? "false" : "true"
          }
        : surcharge
    );
    const chosenRate = stateAccess.get<Deal>([
      "data",
      "info",
      "aftermarketOptions",
      "insurances",
      index,
      "chosenRate"
    ]);
    stateAccess.set<Deal>(
      ["data", "info", "aftermarketOptions", "insurances", index, "chosenRate"],
      {
        ...chosenRate,
        dynamicSurcharges,
        retailPrice:
          selectedSurcharge.Value === "true"
            ? chosenRate.retailPrice - parseFloat(selectedSurcharge.Price)
            : chosenRate.retailPrice + parseFloat(selectedSurcharge.Price),
        remitPrice:
          selectedSurcharge.Value === "true"
            ? chosenRate.remitPrice - parseFloat(selectedSurcharge.Price)
            : chosenRate.remitPrice + parseFloat(selectedSurcharge.Price),
        dealerCostPrice:
          selectedSurcharge.Value === "true"
            ? chosenRate.dealerCostPrice - parseFloat(selectedSurcharge.Cost)
            : chosenRate.dealerCostPrice + parseFloat(selectedSurcharge.Cost)
      }
    );
  };
};
export const handleChangeSurchargeForInsurance = (
  selectedSurcharge: DynamicSurcharge,
  surcharges: DynamicSurcharge[],
  stateAccess: StateAccess
) => {
  const dynamicSurcharges = surcharges?.map((surcharge: DynamicSurcharge) =>
    surcharge.SurchargeCode === selectedSurcharge.SurchargeCode
      ? {
          ...surcharge,
          Value: surcharge.Value === "true" ? "false" : "true"
        }
      : surcharge
  );
  const chosenRate = stateAccess.get<ExpressInsurance>(["chosenRate"]);
  stateAccess.set<ExpressInsurance>(["chosenRate"], {
    ...chosenRate,
    dynamicSurcharges,
    retailPrice:
      selectedSurcharge.Value === "true"
        ? chosenRate.retailPrice - parseFloat(selectedSurcharge.Price)
        : chosenRate.retailPrice + parseFloat(selectedSurcharge.Price),
    remitPrice:
      selectedSurcharge.Value === "true"
        ? chosenRate.remitPrice - parseFloat(selectedSurcharge.Price)
        : chosenRate.remitPrice + parseFloat(selectedSurcharge.Price),
    dealerCostPrice:
      selectedSurcharge.Value === "true"
        ? chosenRate.dealerCostPrice - parseFloat(selectedSurcharge.Cost)
        : chosenRate.dealerCostPrice + parseFloat(selectedSurcharge.Cost)
  });
};
export const handleChangeSurchargeForRate = (
  selectedSurcharge: DynamicSurcharge,
  surcharges: DynamicSurcharge[],
  stateAccess: StateAccess
) => {
  stateAccess.set<DynamicSurcharge>(
    [] as any,
    surcharges?.map((surcharge: DynamicSurcharge) =>
      surcharge.SurchargeCode === selectedSurcharge.SurchargeCode
        ? {
            ...surcharge,
            Value: surcharge.Value === "true" ? "false" : "true"
          }
        : surcharge
    )
  );
};
export default ({
  stateAccess,
  surcharges,
  handleChange
}: {
  stateAccess: StateAccess;
  surcharges: DynamicSurcharge[];
  handleChange: (
    surcharge: DynamicSurcharge,
    surcharges: DynamicSurcharge[],
    stateAccess: StateAccess
  ) => void;
}) => {
  return (
    <div style={{ display: "flex", marginLeft: "10px" }}>
      {surcharges?.map((surcharge, index) => (
        <div
          key={index}
          style={{
            border: " 1px solid",
            padding: "0 10px 0 10px",
            borderRadius: "15px",
            margin: "5px",
            borderColor: "#254e70"
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                disabled={surcharge.Value === "true" && surcharge.IsEditable === "false"}
                checked={surcharge?.Value === "true"}
                onChange={() => handleChange(surcharge, surcharges, stateAccess)}
              />
            }
            label={`${surcharge.Description} - $${surcharge.Price}`}
          />
        </div>
      ))}
    </div>
  );
};
