import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

type AddLenderDecisionRequest = FormState;

export interface AddLenderDecisionResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddLenderDecisionRequest,
  AddLenderDecisionResponse
>("AddLenderDecision", {
  request: "new_lender_decision"
});

export const addLenderDecisionActions = socketMessageSlice.actions;
export const addLenderDecision = socketAction;
export default socketMessageSlice.reducer;
