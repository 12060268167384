export default {
  note: { type: "string" },
  type: {
    type: ["string", "null"],
    nullable: true,
    enum: ["default", "funding_held", null],
    errorMessage: {
      enum: `must be one of the following: 
      'default', 'funding_held'`
    }
  }
};
