import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FundingDocument } from "./types";

interface DeleteFundingDocumentRequest {
  _id: string;
}

interface DeleteFundingDocumentResponse {
  message: FundingDocument;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteFundingDocumentRequest,
  DeleteFundingDocumentResponse
>("DeleteFundingDocumentResponse", {
  request: "delete_funding_document"
});

export const deleteFundingDocumentActions = socketMessageSlice.actions;
export const deleteFundingDocument = socketAction;
export default socketMessageSlice.reducer;
