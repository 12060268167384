import { Contract } from "./types";
import {
  permissionNode,
  PermissionStruct,
  relationPermissionNode,
  multyPermissionNode
} from "components/Roles/types";

export type ContractPermissionsStruct = PermissionStruct<Contract>;

export const contractPermissionsStruct: ContractPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      name: permissionNode("Name"),
      durationTime: permissionNode("Duration time"),
      refNumber: permissionNode("Ref Number"),
      notifyRenewal: permissionNode("Notify Renewal"),
      emailsToNotify: multyPermissionNode("Emails to notify"),
      status: permissionNode("Status")
    },
    files: relationPermissionNode("Files", "file"),
    contractTypes: permissionNode("Contract Types"),
    contractTypeIds: permissionNode("Contract Types Ids"),
    lenders: permissionNode("Lender"),
    lenderIds: permissionNode("Lenders "),
    users: permissionNode("Created at date"),
    userIds: permissionNode("Created at date"),
    dealerships: permissionNode("Created at date"),
    dealershipIds: permissionNode("Created at date"),
    otherVendors: permissionNode("Created at date"),
    otherVendorIds: permissionNode("Created at date"),
    vendorTypes: permissionNode("Created at date"),
    products: permissionNode("Created at date"),
    externalProviders: permissionNode("Created at date"),
    contractDates: {
      signedDate: permissionNode("Created at date"),
      startDate: permissionNode("Created at date"),
      endDate: permissionNode("Created at date")
    }
  }
};
