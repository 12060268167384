import { useQuery } from "@tanstack/react-query";
import { getUsers } from "Chat/http/users";
import { HttpQuery } from "Chat/types/http";

const useWfdUsers = (query?: HttpQuery) => {
  const queryKey = ["wfd-user", query ?? []];

  const { data: wfdUsers, isLoading: wfdUsersAreLoading, error: wfdUsersError } = useQuery({
    queryKey,
    queryFn: getUsers(query),
    enabled: query !== undefined
  });

  return {
    data: wfdUsers,
    isLoading: wfdUsersAreLoading,
    error: wfdUsersError
  };
};
export default useWfdUsers;
