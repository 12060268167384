import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { PrintedDocument, AddPrintedDocumentRequest } from "./types";

interface AddPrintedDocumentResponse {
  message: PrintedDocument;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddPrintedDocumentRequest,
  AddPrintedDocumentResponse
>("AddPrintedDocument", {
  request: "new_printed_document"
});

export const addPrintedDocument = (request: AddPrintedDocumentRequest & Identifiable) => {
  return socketAction({
    requestId: request.requestId,
    availableToRolesIds: request.availableToRolesIds ?? [],
    data: request.data
  });
};
export default socketMessageSlice.reducer;
export const addPrintedDocumentActions = socketMessageSlice.actions;
