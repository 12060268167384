import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Theme,
  Tooltip,
  Typography,
  withStyles
} from "@material-ui/core";
import React from "react";
import { DealerStatusHistory, Dealership } from "./types";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseDialogButton from "components/common/CloseDialogButton";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import formatDate from "utils/formatDate";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineContent from "@material-ui/lab/TimelineContent";
import { capitalize } from "utils/functions";
import Timeline from "@material-ui/lab/Timeline";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { editDealership } from "./editDealershipSlice";
import { uuid } from "uuidv4";
import formEditContext from "components/Content/FormEditContext";

type Props = {
  dealership: Dealership;
};

const RED = "rgb(244, 67, 54)";
const GREEN = "rgb(76, 175, 80)";
export default ({ dealership }: Props) => {
  const [statusHistoryDialogOpen, setStatusHistoryDialogOpen] = React.useState(false);
  const [commentDialog, setCommentDialog] = React.useState<{
    open: boolean;
    status?: "active" | "inactive" | undefined;
  }>({ open: false });

  const { enabled: editMode } = React.useContext(formEditContext);

  return (
    <React.Fragment>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography
              style={{
                fontWeight: "bold",
                color: "rgb(37, 78, 110)",
                fontSize: "19px",
                margin: "5px 0px 10px"
              }}
            >
              Status
            </Typography>
            {typeof dealership?.data?.info?.isActive === "boolean" && (
              <React.Fragment>
                <Chip
                  size="small"
                  label={dealership.data.info.isActive ? "Active" : "Inactive"}
                  style={{
                    background: dealership.data.info.isActive ? GREEN : RED,
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "12px"
                  }}
                />
                <IconButton
                  onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setStatusHistoryDialogOpen(true);
                  }}
                  component="span"
                >
                  <VisibilityIcon />
                </IconButton>
              </React.Fragment>
            )}
          </div>
        </Grid>
        <Grid item container xs={12}>
          {typeof dealership?.data?.info?.isActive !== "boolean" ? (
            <Grid container item xs={2} spacing={1}>
              <Grid item xs={6}>
                <Button
                  disabled={!editMode}
                  fullWidth
                  variant="contained"
                  style={{ background: GREEN, color: "#fff" }}
                  onClick={() => {
                    setCommentDialog({ open: true, status: "active" });
                  }}
                >
                  Set active
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={!editMode}
                  fullWidth
                  variant="contained"
                  style={{ background: RED, color: "#fff" }}
                  onClick={() => {
                    setCommentDialog({ open: true, status: "inactive" });
                  }}
                >
                  Set inactive
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container item xs={2} spacing={1}>
              <Grid item xs={12}>
                <Button
                  disabled={!editMode}
                  fullWidth
                  style={{
                    background: dealership?.data?.info?.isActive ? RED : GREEN,
                    color: "#fff"
                  }}
                  variant="contained"
                  onClick={() => {
                    setCommentDialog({ open: true, status: undefined });
                  }}
                >
                  Set {dealership?.data?.info?.isActive ? "inactive" : "active"}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {commentDialog.open && (
        <CommentDialog
          setStatusHistoryTo={commentDialog.status}
          dealership={dealership}
          handleClose={() => setCommentDialog({ open: false, status: undefined })}
        />
      )}
      {statusHistoryDialogOpen && (
        <StatusHistoryDialog
          statusHistory={dealership?.data?.info?.statusHistory ?? []}
          handleClose={() => setStatusHistoryDialogOpen(false)}
        />
      )}
    </React.Fragment>
  );
};

const ChipTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    maxHeight: "300px",
    overflow: "auto"
  }
}))(Tooltip);
export const dealerActivityChipTooltip = (
  status: "active" | "inactive",
  message: string,
  chipStyle?: React.CSSProperties
): JSX.Element => {
  return (
    <ChipTooltip interactive open={message ? undefined : false} title={message}>
      <Chip size="small" label={capitalize(status)} style={chipStyle} />
    </ChipTooltip>
  );
};
type DealerStatusHistoryDialogProps = {
  statusHistory: DealerStatusHistory[];
  handleClose: () => void;
};
const StatusHistoryDialog = ({ statusHistory, handleClose }: DealerStatusHistoryDialogProps) => {
  const sortedStatusHistory = [...statusHistory]?.sort(
    (a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime()
  );
  return (
    <Dialog fullWidth onClose={handleClose} maxWidth="md" open={true}>
      <CloseDialogButton closeFunction={handleClose} />
      <DialogTitle>Status history</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Timeline>
              {sortedStatusHistory?.map((history, index) => {
                return (
                  <TimelineItem key={index}>
                    <TimelineOppositeContent>
                      <Typography color="textSecondary">{history.userEmail}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {formatDate(history.date, "short", true)}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      {statusHistory?.length !== index + 1 ? <TimelineConnector /> : null}
                    </TimelineSeparator>
                    <TimelineContent
                      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                    >
                      <Typography>
                        {dealerActivityChipTooltip(history.status, history?.comment ?? "", {
                          background: history.status === "active" ? GREEN : RED,
                          color: "#fff"
                        })}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
type CommentDialogProps = {
  dealership: Dealership;
  handleClose: () => void;
  setStatusHistoryTo?: "active" | "inactive";
};
const CommentDialog = ({ dealership, setStatusHistoryTo, handleClose }: CommentDialogProps) => {
  const [requestId] = React.useState(uuid());
  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);
  const dispatch = useDispatch();
  const [comment, setComment] = React.useState("");
  const editDealershipState = useSelector(
    (state: RootState) => state?.editDealershipSlice[requestId]
  );

  React.useEffect(() => {
    if (editDealershipState?.status === "success") {
      handleClose();
    }
  }, [editDealershipState?.status]);

  const handleSubmit = () => {
    if (comment && currentUser) {
      const newHistory: DealerStatusHistory = {
        date: new Date().toISOString(),
        userEmail: currentUser?.data?.info?.email,
        comment,
        status: setStatusHistoryTo
          ? setStatusHistoryTo
          : dealership?.data?.info?.isActive
          ? "inactive"
          : "active" //reverse
      };
      const dealershipToEdit: Dealership = {
        ...dealership,
        data: {
          ...dealership.data,
          info: {
            ...dealership.data.info,
            isActive: setStatusHistoryTo
              ? setStatusHistoryTo === "active"
              : !dealership?.data?.info?.isActive,
            statusHistory: [...(dealership?.data?.info?.statusHistory ?? []), newHistory]
          }
        }
      };
      dispatch(editDealership({ ...dealershipToEdit, requestId }));
    }
  };

  return (
    <Dialog fullWidth onClose={handleClose} maxWidth="md" open={true}>
      <CloseDialogButton closeFunction={handleClose} />
      <DialogTitle>Add a comment</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              label="Comment"
              fullWidth
              margin="dense"
              type="text"
              multiline
              minRows={3}
              variant="filled"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClose}>
          Close
        </Button>
        <Button disabled={!comment} color="primary" variant="contained" onClick={handleSubmit}>
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
};
