import { useDispatch, useSelector } from "react-redux";
import RGL, { WidthProvider } from "react-grid-layout";
import React, { useState, useEffect } from "react";

import Dmv from "components/Dmv/Dmv";
import QuickAccess from "./QuickAccess";
import Lenders from "../Lenders/Lenders";
import Applicants from "../Applicants/Applicants";
import Deal from "components/Deals/Deals";
import Users from "../Users/Users";
import TitleIssues from "../TitleIssues/TitleIssues";
import DealsLog from "../Deals/DealsLog/DealsLog";
import Dealerships from "../Dealerships/Dealerships";
import Roles from "../Roles/Roles";
import DocumentTemplates from "../DocumentTemplates/index";
import CloseDialogButton from "../common/CloseDialogButton";

import { closeDialog } from "./dashboardSlice";
import { DashboardComponent, DashboardComponents, LayoutItem, SortedLayout } from "./types";
import { RootState } from "app/rootReducer";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import "./styles.css";
import Leads from "components/Leads/Leads";

const ReactGridLayout = WidthProvider(RGL);

export const allDashboards: DashboardComponents = {
  quick_access: {
    type: "quick_access",
    label: "Quick access",
    component: <QuickAccess />
  },
  // chart1: {
  //   type: "chart1",
  //   label: "Test chart 1",
  //   component: <Chart1 />
  // },
  // chart2: {
  //   type: "chart2",
  //   label: "Test chart 2",
  //   component: <Chart2 />
  // },
  lender: {
    type: "lender",
    label: "Lenders",
    component: <Lenders />
  },
  dealership: {
    type: "dealership",
    label: "Dealership",
    component: <Dealerships />
  },
  applicant: {
    type: "applicant",
    label: "Applicants",
    component: <Applicants />
  },
  wfd_user: {
    type: "wfd_user",
    label: "Users",
    component: <Users />
  },
  deal: {
    type: "deal",
    label: "Deals",
    component: <Deal />
  },
  role: {
    type: "role",
    label: "Role",
    component: <Roles />
  },
  dmv: {
    type: "dmv",
    label: "DMV",
    component: <Dmv />
  },
  document_template: {
    type: "document_template",
    label: "Document templates",
    component: <DocumentTemplates />
  },
  representative_document: {
    type: "representative_document",
    label: "Representative documents",
    component: (
      <DocumentTemplates
        showDownload={true}
        type="representativeDocument"
        title={"Representative documents"}
      />
    )
  },
  title_issue: {
    type: "title_issue",
    label: "Title information",
    component: <TitleIssues />
  },
  deals_log: {
    type: "deals_log",
    label: "Deals log",
    component: <DealsLog />
  },
  leads: {
    type: "leads",
    label: "Leads log",
    component: <Leads />
  }
};
export default function CustomDashboard() {
  const dispatch = useDispatch();

  const { dashboardSlice } = useSelector((state: RootState) => state);

  const dashboardLayout = localStorage.getItem("dashboard");
  const parsedDashboardLayout = dashboardLayout !== null ? JSON.parse(dashboardLayout) : [];

  const [layout, setLayoutState] = useState<LayoutItem[]>(parsedDashboardLayout);

  useEffect(() => {
    window.localStorage.setItem("dashboard", JSON.stringify(layout));
  }, [layout]);

  const [options] = useState({
    isDraggable: true,
    isResizable: true,
    rowHeight: 50,
    autoSize: true,
    preventCollision: false,
    cols: 2
  });

  const handleChange = (el: DashboardComponent) => {
    layout.some((x) => x.i === el.type)
      ? setLayoutState(layout.filter((x) => x.i !== el.type))
      : setLayoutState(
          layout.concat({
            x: 0,
            y: layout.length + 1,
            w: 12,
            h: 1,
            i: `${el.type}`,
            minW: 1,
            maxW: 2,
            minH: 1,
            maxH: 1
          })
        );
  };

  const handleCloseDialog = () => {
    dispatch(closeDialog());
  };
  const sortLayoutItems = (a: LayoutItem, b: LayoutItem) => {
    if (a.y < b.y) return -1;
    else if (a.y === b.y && a.x < b.x) return -1;
    else return 1;
  };

  const sortedLayout = layout.sort(sortLayoutItems).reduce((acc, item) => {
    if (acc[item.y] === undefined) acc[item.y] = [];
    acc[item.y][item.x] = item;
    return acc;
  }, [] as SortedLayout);

  return (
    <>
      {dashboardSlice.isOpen && (
        <Dialog open={dashboardSlice.isOpen} onClose={handleCloseDialog} keepMounted>
          <DialogContent>
            <DialogTitle id="dashboard-dialog-title">
              Edit dashboard
              <CloseDialogButton closeFunction={handleCloseDialog} />
            </DialogTitle>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                {Object.values(allDashboards).map((el: DashboardComponent) => (
                  <Box key={el.type} style={{ display: "flex", marginBottom: 15 }}>
                    <FormControlLabel
                      checked={layout.some((x) => x.i === el.type)}
                      onChange={() => handleChange(el)}
                      value="start"
                      control={<Checkbox color="secondary" />}
                      label={el.label}
                      labelPlacement="end"
                    />
                  </Box>
                ))}
              </FormGroup>
            </FormControl>
            <ReactGridLayout {...options} onLayoutChange={(layout) => setLayoutState(layout)}>
              {layout.map((item) => (
                <div key={item.i} data-grid={item}>
                  {item.i}
                </div>
              ))}
            </ReactGridLayout>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary" variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {sortedLayout.map((row, index) => {
        return (
          <Grid key={index} style={{ marginTop: "4px" }} container spacing={1}>
            {[...Array(row.length).keys()].map((_el, index2) => {
              return (
                <Grid key={index + "" + index2} item xs={(row[index2]?.w ?? 1) === 1 ? 6 : 12}>
                  {allDashboards[row[index2]?.i]?.component}
                </Grid>
              );
            })}
          </Grid>
        );
      })}
    </>
  );
}
