import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

export interface SendApprovedDocumentsRequest {
  _id: string;
}
export interface SendApprovedDocumentsResponse {
  message: string;
}

const { socketMessageSlice, socketAction, createAction } = createSocketMessageSlice<
  SendApprovedDocumentsRequest,
  SendApprovedDocumentsResponse
>("SendApprovedDocuments", {
  request: "send_approved_documents"
});
export const sendApprovedDocuments = socketAction;
export const sendApprovedDocumnetsActions = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
