import { Schema } from "ajv";
import { CustomRegexes } from "../../helpers";

const routeInnerInfo = {
  type: "object",
  properties: {
    isAvailable: { type: "boolean", nullable: true },
    id: { type: "string", nullable: true },
    partyId: { type: "string", nullable: true }
  }
};
const routeInfo = {
  type: "object",
  properties: {
    defaultRoute: { type: "string", nullable: true },
    dealertrack: routeInnerInfo,
    appone: routeInnerInfo,
    custom: routeInnerInfo
  }
};

export default {
  name: {
    type: "string",
    pattern: CustomRegexes.LETTERS,
    errorMessage: { pattern: "should not contain numbers" }
  },
  email: {
    type: "string",
    pattern: CustomRegexes.EMAIL,
    errorMessage: {
      pattern: "should match the format <emailname>@<provider>.<org>"
    },
    nullable: true
  },
  allocationDashboardSettings: {
    type: "object",
    nullable: true,
    properties: {
      dealDateToUse: {
        type: ["string", "null"],
        enum: ["contractDate", "submittedOrFundedDate", null],
        errorMessage: {
          enum: "must be one of the following: 'contractDate', submittedOrFundedDate"
        }
      }
    }
  },
  securedPartyNumber: { type: "string", nullable: true },
  active: { type: "boolean", nullable: true },
  address: { type: "string", nullable: true },
  zipCode: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.ZIP_CODE,
    errorMessage: {
      pattern: "should match either of the number formats: 'XXXXX' or 'XXXXX-XXXX'"
    }
  },
  collateralTypes: {
    type: "object",
    properties: {
      Automotive: {
        type: "boolean",
        nullable: true
      },
      "Power Sport": {
        type: "boolean",
        nullable: true
      },
      "Recreational Vehicles": {
        type: "boolean",
        nullable: true
      },
      Marine: {
        type: "boolean",
        nullable: true
      }
    },
    nullable: true
  },
  phone: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.PHONE,
    errorMessage: {
      pattern: "should not contain letters and should not start with 0 or 1"
    }
  },
  faxNumber: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.PHONE,
    errorMessage: {
      pattern: "should not contain letters and should not start with 0 or 1"
    }
  },
  otherInfo: {
    type: "array",
    items: {
      type: "object",
      required: ["info"],
      properties: { info: { type: "string" } }
    }
  },
  idNumber: { type: "string", nullable: true },
  defaultVSIFee: { type: "number", nullable: true },
  defaultReservePercent: { type: "number", nullable: true },
  defaultBuyRate: { type: "number", nullable: true },
  recapCalcMethod: { type: "number", nullable: true, minimum: 1, maximum: 8 },
  specificRouteMethod: { type: "boolean", nullable: true },
  eSign: { type: "boolean", nullable: true },
  routeDescription: { type: "string", nullable: true },
  routes: {
    type: "object",
    properties: {
      Automotive: routeInfo,
      Marine: routeInfo,
      "Recreational Vehicles": routeInfo,
      "Power Sport": routeInfo
    }
  },
  rates: {
    type: "array",
    items: {
      type: "object",
      properties: {
        rate: { type: "number", nullable: true },
        requirements: {
          type: "array",
          items: {
            type: "object",
            properties: {
              field: {
                type: "array",
                items: { type: "string", nullable: true }
              },
              type: {
                type: ["string", "null"],
                nullable: true,
                enum: ["<", ">", "=", "in", null],
                errorMessage: {
                  enum: "must be one of the following: '<', '>', '=', 'in'"
                }
              },
              value: {
                anyOf: [
                  { type: "number", nullable: true },
                  { type: "string", nullable: true },
                  { type: "array", nullable: true }
                ]
              }
            }
          }
        }
      }
    }
  },
  profits: {
    type: "array",
    items: {
      type: "object",
      properties: {
        type: {
          type: "string",
          enum: ["fixed", "percent"],
          errorMessage: {
            enum: "must be one of the following: 'fixed', 'percent'"
          }
        },
        value: { type: "number", nullable: true },
        requirements: {
          type: "array",
          items: {
            type: "object",
            properties: {
              field: {
                type: "array",
                items: { type: "string", nullable: true }
              },
              type: { type: "string", nullable: true },
              value: {
                anyOf: [
                  { type: "number", nullable: true },
                  { type: "string", nullable: true },
                  { type: "array", nullable: true }
                ]
              }
            }
          }
        }
      }
    }
  },
  maximumGapAmount: {
    type: "object",
    properties: {
      field: { type: "array", items: { type: "string", nullable: true } },
      type: {
        type: ["string", "null"],
        nullable: true,
        enum: ["fixed", "percent", null],
        errorMessage: {
          enum: "must be one of the following: 'fixed', 'percent'"
        }
      },
      value: {
        anyOf: [
          { type: "number", nullable: true },
          { type: "string", nullable: true },
          { type: "array", nullable: true }
        ]
      }
    }
  },
  maximumInsuranceAmount: {
    type: "object",
    properties: {
      field: { type: "array", items: { type: "string", nullable: true } },
      type: {
        type: ["string", "null"],
        nullable: true,
        enum: ["fixed", "percent", null],
        errorMessage: {
          enum: "must be one of the following: 'fixed', 'percent'"
        }
      },
      value: {
        anyOf: [
          { type: "number", nullable: true },
          { type: "string", nullable: true },
          { type: "array", nullable: true }
        ]
      }
    }
  },
  reserves: { type: "object", nullable: true },
  coverage: {
    type: "object",
    nullable: true,
    properties: {
      collisionDeductible: { type: "number", nullable: true },
      comprehensiveDeductible: { type: "number", nullable: true }
    }
  },
  state: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: { pattern: "should not contain numbers" }
  },
  city: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: { pattern: "should not contain numbers" }
  }
} as { [p: string]: Schema };
