import notes from "../common/notes_schema";
import info_properties from "./info_properties";
import searchSchemaProperties from "../common/get_all_search_schema_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import { Schema } from "ajv";

const data: Schema = {
  type: "object",
  required: ["info"],
  properties: {
    info: {
      type: "object",
      required: [],
      properties: info_properties,
    },
    notes: notes,
  },
};

export default {
  new_document_template: {
    $id: "newDocument",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_document_template: {
    $id: "updateDocument",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
      approved: {
        type: "boolean",
        nullable: true,
      },
      approvedAt: {
        type: "string",
        nullable: true,
      },
      approvedByUserId: {
        type: "string",
        nullable: true,
      },
    },
  },
  delete_document_template: {
    $id: "deleteDocument",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  recover_document_template: {
    $id: "recoverDocument",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  get_document_template: {
    $id: "getDocument",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" },
    },
  },
  all_document_templates: {
    $id: "allDocuments",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: searchSchemaProperties },
    ],
  },
};
