import { createListSlice } from "../Middlewares/listSliceCreator";
import { Dmv } from "./types";

const { ListSlice, getList } = createListSlice<Dmv>({
  name: "DmvList",
  request_topic: "all_dmvs",
  reducers: {}
});

export default ListSlice.reducer;
export const getDmvList = getList;
export const listDmvActions = ListSlice.actions;
