import { RootState } from "app/rootReducer";
import {
  removePrintedDocumentList,
  getPrintedDocumentList
} from "components/PrintedDocuments/listPrintedDocumentsSlice";
import { PrintedDocument, RequiredDocumentStatus } from "components/PrintedDocuments/types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOptionsForSelect } from "utils/models/fields";
import { RenderSet, StateAccess } from "utils/models/formGenerator";

interface Props {
  stateAccess: StateAccess;
  path: string[];
  renderSet: RenderSet;
  name: string;
  label: string;
}

export default function PrintedDocumentsPreview({
  stateAccess,
  path,
  renderSet,
  name,
  label
}: Props) {
  const dispatch = useDispatch();
  const dealId = stateAccess.get<PrintedDocument>(["data", "dealId"]);
  const listId = `unparsed-required-documents-${dealId}`;
  const printedDocumentList = useSelector(
    (state: RootState) => state.listPrintedDocumentSlice[listId]
  );
  const handleLoadPrintedDocuments = () => {
    if (!printedDocumentList)
      dispatch(
        getPrintedDocumentList(listId, {
          options: { pagination: false },
          queryParams: {
            dealId
          },
          query: {
            "data.uploaded": {
              $in: [RequiredDocumentStatus.pending, RequiredDocumentStatus.missing]
            }
          }
        })
      );
    return () => {
      dispatch(removePrintedDocumentList(listId));
      return;
    };
  };

  return (
    <>
      {renderSet.selectInputRenderer(
        {
          formComponent: "select-field",
          name,
          label,
          onOpen: handleLoadPrintedDocuments,
          path: path as [],
          options: createOptionsForSelect({
            possibleValues: () => printedDocumentList?.entities ?? [],
            getOptionLabel: (el) => el?.data?.documentTemplateName ?? "",
            getSelectedOption: (x, y) => x._id === y._id
          }),
          valueToString: (el) => el?.data?.documentTemplateName
        },
        stateAccess,
        [],
        stateAccess,
        renderSet
      )}
    </>
  );
}
