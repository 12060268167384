import { createListSlice } from "../Middlewares/listSliceCreator";
import { UnapprovedUser } from "./types";

const { ListSlice, getList } = createListSlice<UnapprovedUser>({
  name: "UnapprovedUserList",
  request_topic: "unapproved_users",
  reducers: {}
});

export default ListSlice.reducer;
export const getUnapprovedUserList = getList;
export const removeUserList = ListSlice.actions.removeList;
export const listUnapprovedUserActions = ListSlice.actions;
