import { createListSlice } from "../Middlewares/listSliceCreator";
import { Stipulation } from "./types";

const { ListSlice, getList } = createListSlice<Stipulation>({
  name: "StipulationsList",
  request_topic: "all_stipulations",
  reducers: {}
});
export default ListSlice.reducer;
export const getStipulationsList = getList;
export const listStipulationsActions = ListSlice.actions;
export const removeStipulationsList = ListSlice.actions.removeList;
