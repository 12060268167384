import { createListSlice } from "../Middlewares/listSliceCreator";
import { LenderDescision } from "./types";

const { ListSlice, getList } = createListSlice<LenderDescision>({
  name: "LenderDecisionsList",
  request_topic: "all_lender_decisions",
  reducers: {}
});

export default ListSlice.reducer;
export const getLenderDecision = getList;
export const listLenderDecisionActions = ListSlice.actions;
export const removeLenderDecisionsList = ListSlice.actions.removeList;
