import { CreditScoreRange } from "./types";
import { FormComponent, Model } from "../../utils/models/fields";

const rangesStruct: FormComponent<CreditScoreRange> = {
  formComponent: "segment",
  name: "Range Info",
  entities: [
    {
      formComponent: "text-field",
      name: "name",
      label: "Name",
      width: "1/2",
      path: ["name"],
      required: true,
      default: null
    },
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "segment",
          width: "full",
          entities: [
            {
              formComponent: "number-field",
              name: "minimumScore",
              label: "Minimum score",
              width: "1/2",
              required: true,
              path: ["minimumScore"]
            },
            {
              formComponent: "number-field",
              name: "maximumScore",
              label: "Maximum Score",
              width: "1/2",
              required: true,
              path: ["maximumScore"]
            }
          ]
        }
      ]
    }
  ]
};
export const creditScoreRange: Model<CreditScoreRange> = {
  formComponent: "model",
  schema: "new_credit_score_range",
  name: "credit_score_range",
  entities: [
    {
      formComponent: "segment",
      name: "Credit Score Range",
      entities: [
        {
          formComponent: "text-field",
          name: "name",
          label: "Name",
          width: "1/2",
          path: ["data", "info", "name"],
          required: true,
          default: null
        },
        {
          formComponent: "list-model",
          name: "Ranges",
          width: "full",
          entity: rangesStruct,
          path: ["data", "info", "ranges"],
          required: false
        }
      ]
    }
  ]
};
