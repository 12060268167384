import { Grid, TextField, Button, CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  DocumentsTypesLiterals,
  ModalDocuments,
  ModalOtherRequiredDocumentType,
  PackType
} from "./types";
import { Deal } from "../types";
import { storage } from "../../../firebase/firebase";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { useSnackbar } from "notistack";
import { HintTooltip } from "components/common/HintTooltip";
const NewRequiredDocument = ({
  documents,
  setDocuments,
  dealId
}: {
  documents: ModalDocuments;
  dealId: string;
  setDocuments: (newDocuments: ModalDocuments) => void;
}) => {
  const [state, setState] = useState<{ name: string; fileName: string; url: string | undefined }>({
    name: "",
    fileName: "",
    url: undefined
  });

  const [uploadInProgress, setUploadInProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [fileRequestId] = useState(uuidv4());
  const handleAddAdditionalRequiredDocument = () => {
    if (state.name) {
      const document: ModalOtherRequiredDocumentType = {
        name: state.name,
        displayName: state.name,
        type: DocumentsTypesLiterals.other_required_document,
        _id: uuidv4(),
        packType: PackType.email,
        fileUrl: state.url || "",
        fileName: state.fileName || "",
        documentData: { name: state.name },
        additionalProperties: {
          required: true,
          dealerships: true,
          applicant: false,
          eSign: false
        }
      };
      setDocuments([...documents, document]);
      setState({ name: "", fileName: "", url: undefined });
    }
  };
  const handleUploadOtherRequiredDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadInProgress(true);
    const uploadedFiles = event.target.files ?? [];

    if (uploadedFiles.length !== 0) {
      const file = uploadedFiles[0];
      const firebaseFileName = `${fileRequestId}-${file.name}`;

      const uploadTask = storage.ref(`/files/Deal/${dealId}/${firebaseFileName}`).put(file);

      uploadTask.on(
        "state_changed",
        (_snapShot) => {},
        (err) => {
          setUploadInProgress(false);
          enqueueSnackbar(err.message, {
            variant: "error"
          });
        },
        () => {
          const ref = storage.ref(`/files/Deal/${dealId}`).child(firebaseFileName);

          ref.getDownloadURL().then((fireBaseUrl) => {
            ref.getMetadata().then((metadata) => {
              setUploadInProgress(false);
              setState((prevState) => ({ ...prevState, fileName: file.name, url: fireBaseUrl }));
            });
          });
        }
      );
    }
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={uploadInProgress ? 9 : state.url ? 10 : 6}>
        <TextField
          id={`add-new-other-additional-required-document`}
          variant="standard"
          label={"New other additional required document"}
          required={false}
          type="text"
          size="small"
          fullWidth
          autoComplete="new-password"
          value={state.name}
          onChange={(e) => setState((prevState) => ({ ...prevState, name: e.target.value }))}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleAddAdditionalRequiredDocument();
            }
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      {!state.url && (
        <Grid item xs={uploadInProgress ? 1 : 4}>
          {!uploadInProgress ? (
            <>
              <input
                accept=".pdf"
                id={`upload-other-required-document`}
                type="file"
                onChange={handleUploadOtherRequiredDocument}
                hidden
              />
              <HintTooltip title={!state.name ? "Please enter a name first." : ""}>
                <label htmlFor={`upload-other-required-document`} style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Upload document
                  </Button>
                </label>
              </HintTooltip>
            </>
          ) : (
            <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
              <CircularProgress color="primary" size={19} />
            </div>
          )}
        </Grid>
      )}

      <Grid item xs={2}>
        <Button
          disabled={!state.name}
          onClick={handleAddAdditionalRequiredDocument}
          color="primary"
          variant="contained"
        >
          Add
        </Button>
      </Grid>
      {state.url && (
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "3px"
            }}
          >
            <a
              href={state.url || "#"}
              style={{ marginRight: "5px", fontStyle: "italic" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {state.fileName || "Document not found"}
            </a>
            <IconButton
              style={{ color: "red" }}
              onClick={() => {
                setState((prevState) => ({ ...prevState, url: undefined }));
              }}
            >
              <RemoveCircleIcon />
            </IconButton>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default NewRequiredDocument;
