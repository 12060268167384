import React, { useContext } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography
} from "@material-ui/core";

import { Deal } from "components/Deals/types";
import { ShowForm } from "utils/models/ShowForm";
import { entityConfigs } from "utils/entitySlice";
import { StateAccess } from "utils/models/formGenerator";
import { TitleIssue } from "components/TitleIssues/types";
import { TabContext } from "components/Layout/LayoutWrapper";

import { LenderTicket } from "./types";
import AddLenderTicket from "./AddLenderTicket";
import { ExpandMore } from "@material-ui/icons";

export default ({ stateAccess, path }: { stateAccess: StateAccess; path: string[] }) => {
  const tittleIssueId = stateAccess.get(["_id"]);
  const tickets: LenderTicket[] = stateAccess.get<LenderTicket[]>(path);
  const createOrFocusTab = useContext(TabContext);

  const handlePreview = (ticketId: string) =>
    createOrFocusTab({
      label: "Show page",
      index: "showPage",
      isForSidebar: false,
      isForQuickAccess: false,
      isPersistent: false,
      props: {
        _id: ticketId,
        type: "lender_ticket"
      }
    });

  const setNewTicket = (data: TitleIssue) => stateAccess.set(path, data);

  return (
    <Grid container xs={12}>
      {tickets?.length > 0 ? (
        tickets.map((ticket, index) => (
          <Grid
            key={index}
            item
            xs={12}
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginBottom: index !== tickets.length - 1 ? "5px" : "0px"
            }}
          >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5">{ticket.data.info.subject}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12} container>
                  <ShowForm
                    editable={false}
                    tabId="preview-ticket"
                    initialState={ticket}
                    hideEdit={true}
                    model={entityConfigs["lender_ticket"].model}
                  />
                  <Button
                    color="default"
                    variant="contained"
                    onClick={() => handlePreview(ticket._id)}
                    style={{ margin: 5, marginLeft: "auto", background: "white" }}
                  >
                    Open ticket
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))
      ) : (
        <AddLenderTicket titleIssueId={tittleIssueId} setSelected={setNewTicket} />
      )}
    </Grid>
  );
};
