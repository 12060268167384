import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FundingDocument } from "./types";

interface RecongnizeFundingDocumentRequest {
  _id: string;
  unuseful: boolean;
  printedDocumentId?: string;
  fundingDocumentPage: number;
  printedDocumentPage?: number;
}
interface RecongnizFundingDocumenResponse {
  message: FundingDocument;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecongnizeFundingDocumentRequest,
  RecongnizFundingDocumenResponse
>("RecongizeFundingDocument", {
  request: "recognize_funding_document"
});

export const recongizeFundingDocumentActions = socketMessageSlice.actions;
export const recongizeFundingDocument = socketAction;
export default socketMessageSlice.reducer;
