import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";
import React, { useState } from "react";
import { FormComponent } from "utils/models/fields";
import {
  generateForm,
  getByPath,
  RenderSet,
  setByPath,
  StateAccess
} from "utils/models/formGenerator";
import { Deal } from "../types";
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  renderSet: RenderSet;
  stateAccess: StateAccess;
  createInvoiceAndVendorBills: (
    invoice: boolean,
    gapBill: boolean,
    warrantyBill: boolean,
    dealerBill: boolean
  ) => void;
}
interface InvoiceVendorBill {
  invoice: boolean;
  gapBill: boolean;
  warrantyBill: boolean;
  dealerBill: boolean;
}

export const struct = (
  hasGap: boolean,
  hasWarranty: boolean,
  hasGapBillId: boolean,
  hasServiceWarrantyBillId: boolean,
  hasInvoiceId: boolean,
  hasDealerBillId: boolean
): FormComponent<InvoiceVendorBill> => ({
  formComponent: "segment",
  width: "1/2",
  entities: [
    {
      formComponent: "checkbox-field",
      name: "invoice",
      label: `Invoice ${hasInvoiceId ? "(already exists)" : ""}`,
      width: "full",
      valueType: "boolean",
      path: ["invoice"],
      isDisabled: () => hasInvoiceId,
      isChecked: (stateAccess: StateAccess): boolean => stateAccess.get(["invoice"]),
      toggle: (stateAccess: StateAccess) =>
        stateAccess.set(["invoice"], !stateAccess.get(["invoice"])),
      required: true,
      default: false
    },
    {
      formComponent: "checkbox-field",
      name: "dealerBill",
      label: `Dealer Bill ${hasDealerBillId ? "(already exists)" : ""}`,
      width: "full",
      valueType: "boolean",
      path: ["dealerBill"],
      isDisabled: (stateAccess: StateAccess): boolean => hasDealerBillId,
      isChecked: (stateAccess: StateAccess): boolean => stateAccess.get(["dealerBill"]),
      toggle: (stateAccess: StateAccess) =>
        stateAccess.set(["dealerBill"], !stateAccess.get(["dealerBill"])),
      required: true,
      default: false
    },
    ...(hasGap
      ? [
          {
            formComponent: "checkbox-field",
            name: "gapBill",
            label: `Gap Bill ${hasGapBillId ? "(already exists)" : ""}`,
            width: "1/4",
            valueType: "boolean",
            path: ["gapBill"],
            isDisabled: (stateAccess: StateAccess): boolean => hasGapBillId,
            isChecked: (stateAccess: StateAccess): boolean => stateAccess.get(["gapBill"]),
            toggle: (stateAccess: StateAccess) =>
              stateAccess.set(["gapBill"], !stateAccess.get(["gapBill"])),
            required: true,
            default: false
          } as FormComponent<InvoiceVendorBill>
        ]
      : []),
    ...(hasWarranty
      ? [
          {
            formComponent: "checkbox-field",
            name: "warrantyBill",
            label: `Warranty Bill ${hasServiceWarrantyBillId ? "(already exists)" : ""}`,
            width: "full",
            valueType: "boolean",
            path: ["warrantyBill"],
            isDisabled: (stateAccess: StateAccess): boolean => hasServiceWarrantyBillId,
            isChecked: (stateAccess: StateAccess): boolean => stateAccess.get(["warrantyBill"]),
            toggle: (stateAccess: StateAccess) =>
              stateAccess.set(["warrantyBill"], !stateAccess.get(["warrantyBill"])),
            required: true,
            default: false
          } as FormComponent<InvoiceVendorBill>
        ]
      : [])
  ]
});

export default function CreateInvoiceAndVendorBillsModal({
  open,
  setOpen,
  renderSet,
  stateAccess,
  createInvoiceAndVendorBills
}: Props) {
  const deal: Deal = stateAccess.get([]);
  const insurances = deal.data?.info?.aftermarketOptions?.insurances ?? [];
  const hasGap = insurances.some(
    (el) => el.chosenRate.insuranceType === "gap" && el.chosenRate?.soldBy === "WFD"
  );
  const hasWarranty = insurances.some(
    (el) => el.chosenRate.insuranceType === "service warranty" && el.chosenRate?.soldBy === "WFD"
  );

  const hasGapBillId = deal.data.netsuiteVendorBillIds?.find((id) => id?.type === "gap")
    ? true
    : false;
  const hasServiceWarrantyBillId = deal.data.netsuiteVendorBillIds?.find(
    (id) => id?.type === "service warranty"
  )
    ? true
    : false;
  const hasInvoiceId = deal.data.netsuiteInvoiceId ? true : false;
  const hasDealerBillId = deal.data.netsuiteVendorBillIds?.find((id) => id?.type === "dealer")
    ? true
    : false;

  const [state, setState] = useState({
    invoice: !hasInvoiceId,
    gapBill: !hasGapBillId ? hasGap : false,
    warrantyBill: !hasServiceWarrantyBillId ? hasWarranty : false,
    dealerBill: !hasDealerBillId
  });
  const handleClose = () => {
    setOpen(false);
  };
  const modalStateAccess: StateAccess = {
    get: (path) => getByPath(state, path),
    set: (path, value): any => {
      setState(setByPath(state as any, path, value));
    }
  };

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
        <Grid item xs={6} key="title">
          <DialogTitle>
            Create Invoice/Vendor bills
            <CloseDialogButton closeFunction={handleClose} />
          </DialogTitle>
        </Grid>
      </Grid>
      <DialogContent>
        {generateForm(
          struct(
            hasGap,
            hasWarranty,
            hasGapBillId,
            hasServiceWarrantyBillId,
            hasInvoiceId,
            hasDealerBillId
          ),
          modalStateAccess,
          [],
          modalStateAccess,
          renderSet
        )}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            setOpen(false);
            createInvoiceAndVendorBills(
              state.invoice,
              state.gapBill,
              state.warrantyBill,
              state.dealerBill
            );
          }}
          color="primary"
          variant="contained"
        >
          Send
        </Button>
        <Button autoFocus onClick={handleClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
