export enum EntityUrls {
  "column_group" = "column_group",
  "note" = "note",
  "customer_care_note" = "customer_care_note",
  "dealership_ticket_note" = "dealership_ticket_note",
  "dealership_ticket" = "dealership_ticket",
  "dealer_note" = "dealer_note",
  "title_issue_note" = "title_issue_note",
  "lender_note" = "lender_note",
  "stipulation" = "stipulation",
  "checklist" = "checklist",
  "table_settings" = "table_settings",
  "contract" = "contract",
  "settings" = "settings",
  "contract_type" = "contractType",
  "credit_score" = "creditScore",
  "lender" = "lender",
  "dealership" = "dealership",
  "applicant" = "applicant",
  "wfd_user" = "wfd_user",
  "dmv" = "dmv",
  "lender_ticket" = "lender_ticket",
  "custom_report" = "customReport",
  "business_report" = "businessReport",
  "role" = "role",
  "deal" = "deal",
  "state" = "state",
  "title_issue" = "titleIssue",
  "dealership_bills_report" = "dealershipBillsReport",
  "dealership_program" = "dealershipProgram",
  "manager_program" = "managerProgram",
  "document_template" = "documentTemplate",
  "document_validation" = "documentValidation",
  "vehicle_insurance_company" = "vehicleInsuranceCompany",
  "file" = "file",
  "payoff_bank" = "payoffBank",
  "appone_import_application" = "apponeImportApplication",
  "dealertrack_deal" = "dealertrackDeal",
  "unapproved_user" = "unapprovedUser",
  "funding_document" = "fundingDocument",
  "external_credit_application" = "externalCreditApplication",
  "floorplanxpress" = "floorplanxpress",
  "lock" = "lock",
  "sequence" = "sequence",
  "notification" = "notification",
  "lender_decision" = "lenderDecision",
  "api_status" = "apiStatus",
  "history" = "history",
  "delivered_deal" = "deliveredDeal",
  "other_vendor" = "otherVendor",
  "netsuite" = "netsuite",
  "additional_required_document" = "additional_required_document",
  "printed_document" = "printed_document",
  "unparsed_required_document" = "unparsed_required_document",
  "one_span_signing" = "one_span_signing",
  "chargeback" = "chargeback",
  "credit_score_range" = "credit_score_range",
  "lender_ticket_type" = "lender_ticket_type",
  "prescreen" = "prescreen"
}

export const isEntityUrls = (x: string) => Object.values(EntityUrls).some((v) => v === x);
export const isEntityTableUrls = (x: string) => Object.values(EntityTableUrls).some((v) => v === x);

export const getEnumKeyByEnumValue = <T extends { [index: string]: string }>(
  targetEnum: T,
  value: string
): keyof T => {
  const found = Object.keys(targetEnum).find((x) => targetEnum[x] == value);
  if (found) {
    return found;
  } else {
    throw "Tab not found!";
  }
};

export enum EntityTableUrls {
  "status_reasons" = "status_reasons",
  "leads" = "leads",
  "credit_scores" = "creditScores",
  "dashboard" = "dashboard",
  "dealerships" = "dealerships",
  "suspended_deals_log" = "suspended_deals_log",
  "dealership_bills_report" = "dealershipBillsReport",
  "lenders" = "lenders",
  "wfd_users" = "wfd_users",
  "roles" = "roles",
  "applicants" = "applicants",
  "dmv" = "dmv",
  "lender_tickets" = "lender_tickets",
  "lender_ticket_types" = "lender_ticket_types",
  "deals" = "deals",
  "deals_log" = "dealsLog",
  "states" = "states",
  "chargeback_table" = "chargebacks",
  "chargeback_dashboard" = "chargeback_dashboard",
  "chargeback_monthly_bills" = "chargebacks_bills",
  "dealership_tickets" = "dealership_tickets",
  "title_issues" = "titleIssues",
  "lender_coverage_map" = "lender_coverage_map",
  "title_dashboard" = "title_dashboard",
  "dealership_programs" = "dealershipPrograms",
  "manager_programs" = "managerPrograms",
  "payoff_banks" = "payoffBanks",
  "other_vendors" = "otherVendors",
  "vehicle_insurance_company" = "vehicleInsuranceCompany",
  "external_credit_application" = "externalCreditApplication",
  "lender_decisions" = "lenderDecisions",
  "settings" = "settings",
  "deals_recap" = "dealsRecap  ",
  "funding_notification" = "fundingNotification",
  "payroll" = "payroll",
  "third_party_documents_usage" = "thirdPartyDocumentsUsage",
  "income_report" = "incomeReport",
  "dealer_activity" = "dealerActivity",
  "dealer_activity_warning" = "dealerActivityWarning",
  "custom_report" = "customReport",
  "dealership_performance_report" = "dealershipPerformanceReport",
  "business_report" = "businessReport",
  "lender_coverage_statistics" = "lenderCoverageStatistics",
  "contracts" = "contracts",
  "contract_types" = "contractTypes",
  "document_validations" = "documentValidations",
  "document_templates" = "documentTemplates",
  "additional_required_documents" = "additional_required_documents",
  "credit_score_range" = "credit_score_range",
  "lender_dashboard" = "lender_dashboard",
  "lender_allocation_dashboard" = "lender_allocation_dashboard",
  "lender_estimation_dashboard" = "lender_estimation_dashboard",
  "sales_representative_dashboard" = "sales_representative_dashboard",
  "accountant_dashboard" = "accountant_dashboard",
  "manager_dashboard" = "manager_dashboard",
  "mixpanel_reports" = "mixPanelReports",
  "prescreen" = "prescreen"
}
