import React, { useContext } from "react";
import { Button, Grid } from "@material-ui/core";

import { Deal } from "components/Deals/types";
import { ShowForm } from "utils/models/ShowForm";
import { entityConfigs } from "utils/entitySlice";
import { StateAccess } from "utils/models/formGenerator";
import { TitleIssue } from "components/TitleIssues/types";
import { TabContext } from "components/Layout/LayoutWrapper";

export default ({
  stateAccess,
  path,
  deal
}: {
  stateAccess: StateAccess;
  path: string[];
  deal: Deal[];
}) => {
  const titleIssue = stateAccess.get<TitleIssue>(path);

  const createOrFocusTab = useContext(TabContext);
  const handlePreview = () =>
    createOrFocusTab({
      label: "Show page",
      index: "showPage",
      isForSidebar: false,
      isForQuickAccess: false,
      isPersistent: false,
      props: {
        _id: titleIssue._id,
        type: "title_issue"
      }
    });

  return (
    <Grid container xs={12}>
      {titleIssue && titleIssue._id ? (
        <>
          <ShowForm
            editable={false}
            tabId="preview-titleIssue"
            initialState={{ ...titleIssue, data: { ...titleIssue.data, deal } }}
            hideEdit={true}
            model={entityConfigs["title_issue"].model}
          />
          <Button
            color="default"
            variant="contained"
            onClick={handlePreview}
            style={{ margin: 5, marginLeft: "auto", background: "white" }}
          >
            Open Title Information
          </Button>
        </>
      ) : null}
    </Grid>
  );
};
