import { FloorplanXpress } from "components/FloorplanXpress/types";
import { PermissionStruct, permissionNode, relationPermissionNode } from "components/Roles/types";

export type FloorplanXpressPermissionsStruct = PermissionStruct<Required<FloorplanXpress>>;

export const floorplanXpressPermissionStruct: FloorplanXpressPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      isConnected: permissionNode("isConnected"),
      isSold: permissionNode("isSold"),
      data: {
        VIN: permissionNode("VIN"),
        year: permissionNode("Year"),
        make: permissionNode("Make"),
        model: permissionNode("Model"),
        color: permissionNode("Color"),
        titleStatus: permissionNode("Title Status"),
        payoffToday: permissionNode("PayoffToday"),
        payoffTwoDays: permissionNode("PayoffTwoDays"),
        dealer: permissionNode("Dealer"),
        branchEmail: permissionNode("BranchEmail"),
        branchPhone: permissionNode("BranchPhone"),
        note: permissionNode("Note")
      }
    },
    dealId: permissionNode("Deal")
  }
};
