import { Button, IconButton } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import { HintTooltip } from "components/common/HintTooltip";
import { collateralTypes } from "components/Deals/types";
import { DocumentTemplate } from "components/DocumentTemplates/types";
import { TabContext } from "components/Layout/LayoutWrapper";
import TextLoop from "components/Loader/TextLoop";
import { getVariant } from "components/Validations/dealTypeGetter";
import { getValidationsList } from "components/Validations/listValidationSlice";
import { Validation } from "components/Validations/types";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import formatDate from "utils/formatDate";
import { v4 as uuidv4 } from "uuid";
import { defaultStates } from "./model";

interface Props {
  document: DocumentTemplate;
  index: number;
}

interface Tree {
  type: "category" | "detail";
  name?: string;
  color: string;
  children?: Tree | Tree[];
  filteredValidations?: Validation[];
  validatedCount?: number;
  allCount?: number;
}
const allHeadings = ["State", "Lender", "Collateral Type", "Variant", "Applicant"] as const;
const filterDeal = (
  data: {
    [x: string]: Validation[];
  },
  filters: Partial<{ [key in typeof allHeadings[number]]: string }>
) => {
  return filters["Variant"]
    ? data[filters["Variant"]]?.reduce(
        (acc: Validation[], validation) =>
          validation?.data?.info?.deal?.data?.dealership?.data?.info?.state === filters["State"] &&
          (validation?.data?.info?.deal?.data?.lender?.data?.info?.name === filters["Lender"] ||
            filters["Lender"] === "All Lenders") &&
          validation?.data?.info?.deal?.data?.info?.type === filters["Collateral Type"] &&
          (validation?.data?.info?.deal?.data?.coApplicantId
            ? "With co-applicant"
            : "One applicant") === filters["Applicant"]
            ? [...acc, validation]
            : acc,
        []
      )
    : [];
};

const generateTree = (
  headings: typeof allHeadings[number][],
  entities: string[][],
  sortedData: { [key: string]: Validation[] },
  document: DocumentTemplate,
  filters: Partial<{ [key in typeof allHeadings[number]]: string }>
): Tree | Tree[] => {
  const [firstEntity, ...otherEntities] = entities;
  const [firstHeading, ...otherHeadings] = headings;
  if (entities?.length > 0) {
    return firstEntity.map((el) => {
      const children = generateTree(otherHeadings, otherEntities, sortedData, document, {
        ...filters,
        [firstHeading]: el
      });
      return {
        children,
        color:
          (Array.isArray(children) && children.some((el) => el.color === "red")) ||
          (!Array.isArray(children) && children.color === "red")
            ? "red"
            : "green",
        validatedCount: Array.isArray(children)
          ? children?.reduce((acc, child) => acc + (child?.validatedCount || 0), 0)
          : children.validatedCount || 0,
        allCount: Array.isArray(children)
          ? children?.reduce((acc, child) => acc + (child?.allCount || 0), 0)
          : children.allCount || 0,
        type: "category",
        name: `${firstHeading} ${el}`
      };
    });
  }
  const filteredValidations = filterDeal(sortedData, filters);

  const color =
    filteredValidations?.length === 0 ||
    filteredValidations.some(
      (validation) =>
        new Date(validation.updatedAt).getTime() < new Date(document.updatedAt).getTime()
    )
      ? "red"
      : "green";
  return {
    type: "detail",
    color,
    filteredValidations,
    allCount: 1,
    validatedCount: color === "red" ? 0 : 1
  };
};

const CustomTreeView = ({ tree }: { tree: Tree[] | Tree }) => {
  if (Array.isArray(tree)) {
    return (
      <>
        {tree.map((lief, index) => (
          <Category tree={lief} key={index} />
        ))}
      </>
    );
  }
  return <Details tree={tree} />;
};

const Category = ({ tree }: { tree: Tree }) => {
  const [show, setShow] = useState(false);
  const children = tree.children;
  return children ? (
    <div style={{ color: tree.color, marginLeft: "20px" }}>
      <span onClick={() => setShow(!show)} style={{ cursor: "pointer" }}>
        <IconButton size="small">{show ? <ChevronRight /> : <ExpandMoreIcon />}</IconButton>
        {`${tree.name} (${tree.validatedCount}/${tree.allCount})`}
      </span>
      <div
        style={{
          display: show ? "block" : "none"
        }}
      >
        {show && <CustomTreeView tree={children} />}
      </div>
    </div>
  ) : (
    <>Why we are here?</>
  );
};

const Details = ({ tree }: { tree: Tree }) => {
  const createOrFocusTab = useContext(TabContext);
  const filteredValidations = tree?.filteredValidations;

  return filteredValidations ? (
    <div style={{ marginLeft: "60px" }}>
      <div style={{ color: tree.color }}>
        {filteredValidations.length > 0
          ? filteredValidations.map((filteredValidation, index) => (
              <HintTooltip
                key={index}
                title={` Last update: ${formatDate(filteredValidation?.updatedAt, "short", true)}`}
                aria-label="add"
              >
                <Button
                  variant={"contained"}
                  color={tree.color === "green" ? "primary" : "secondary"}
                  style={{
                    background: tree.color === "green" ? "#50a538" : "#f50057"
                  }}
                  key={index}
                  onClick={() => {
                    createOrFocusTab({
                      label: "Show page",
                      index: "showPage",
                      isForSidebar: false,
                      isForQuickAccess: false,
                      isPersistent: false,
                      props: {
                        _id: filteredValidation?.data?.info?.deal?._id,
                        type: "deal"
                      }
                    });
                  }}
                >
                  {filteredValidation?.data?.info?.deal?.data?.info?.refNumber}
                </Button>
              </HintTooltip>
            ))
          : "No validations available"}
      </div>
    </div>
  ) : (
    <>No validations available</>
  );
};

export default function ValidationPreview({ document }: Props) {
  const choosenLenders =
    document?.data?.info?.dealTypeProperties?.lenders?.map((el) => el.data.info.name) ?? [];

  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const validationsList = useSelector((state: RootState) => state.listValidationsSlice[requestId]);

  const lenders = choosenLenders?.length > 0 ? choosenLenders : ["All Lenders"];
  const states =
    Array.isArray(document?.data?.info?.dealTypeProperties?.states) &&
    document?.data?.info?.dealTypeProperties?.states?.length > 0
      ? document?.data?.info?.dealTypeProperties?.states
      : [...defaultStates];

  const dealTypes =
    Array.isArray(document?.data?.info?.dealTypeProperties?.types) &&
    document?.data?.info?.dealTypeProperties?.types.length > 0
      ? document?.data?.info?.dealTypeProperties?.types
      : [...collateralTypes];
  const data = validationsList?.entities?.map((el) => getVariant(el));

  useEffect(() => {
    dispatch(
      getValidationsList(requestId, {
        query: {
          "data.info.documentTemplateId": document?._id
        }
      })
    );
  }, [dispatch, document, requestId]);

  const sortedData =
    data?.reduce(
      (acc: { [key: string]: Validation[] }, curr) => {
        curr.variant.forEach((variant) => {
          acc[variant] = [...(acc[variant] ?? []), curr.validation];
        });

        return acc;
      },
      {
        Warranty: [],
        Gap: [],
        "TRADE WITH PAYOFF - NEGATIVE EQUITY": [],
        Trade: [],
        "GAP & Warranty": [],
        "Cash Down": [],
        Base: []
      }
    ) ?? {};
  return validationsList ? (
    <AccessControl requiredPermissions={{ entity: "document_validation", action: "read" }}>
      <CustomTreeView
        tree={generateTree(
          [...allHeadings],
          [
            states,
            lenders,
            dealTypes,
            Object.keys(sortedData ?? {}),
            document.data.info.dealTypeProperties.hasCoApplicant
              ? ["With co-applicant"]
              : ["One applicant", "With co-applicant"]
          ],
          sortedData,
          document,
          {}
        )}
      />
    </AccessControl>
  ) : (
    <TextLoop text="Loading..." />
  );
}
