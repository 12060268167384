import IconButton from "@material-ui/core/IconButton";
import { entityConfigs, EntityData, EntityType } from "utils/entitySlice";
import { Add } from "@material-ui/icons";
import { Box, Button } from "@material-ui/core";
import { HintTooltip } from "components/common/HintTooltip";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import CheckIcon from "@material-ui/icons/Check";
import { RootState } from "app/rootReducer";
import { EntityAprovalRolesToSend } from "components/Settings/types";
import { User } from "components/Users/types";

const approve = <T extends EntityType>(
  entityName: EntityType,
  state: EntityData<T>,
  dispatch: Dispatch<any>,
  user: User
) => {
  const { createdAt, updatedAt, deleted, ...data } = state;
  const editFunction = entityConfigs[entityName].edit.do;
  const requestId = uuidv4();
  dispatch(
    editFunction({
      ...(data as any),
      approved: true,
      approvedByUserId: user?._id,
      requestId,
      approvedAt: new Date().toISOString()
    })
  );
};

export const getEntityApprovalRoleKey = (entityName: EntityType) =>
  `${entityName.replace(/_\w/g, (g) =>
    g[1].toUpperCase()
  )}ApprovalRoleIds` as keyof EntityAprovalRolesToSend;

const showApporveButton = (
  entityAprovalRoles: EntityAprovalRolesToSend | undefined,
  userRolesIds: string[] | undefined,
  entityName: EntityType
) => {
  return (
    userRolesIds &&
    entityAprovalRoles &&
    userRolesIds?.some((r) =>
      entityAprovalRoles?.[getEntityApprovalRoleKey(entityName)]?.includes(r)
    )
  );
};
export default <T extends EntityType>({
  entityName,
  state
}: {
  entityName: EntityType;
  state: EntityData<T>;
}) => {
  const dispatch = useDispatch();
  const settings = useSelector((state: RootState) => state.listSettingsSlice["settingsData"])
    ?.entities?.[0];
  const { user } = useSelector((state: RootState) => state.authSlice);

  const userData = user?.databaseData;
  return userData &&
    showApporveButton(
      settings?.data?.entityAprovalRoles,
      userData?.data?.rolesIds ?? [],
      entityName
    ) ? (
    <Box component="span" style={{ padding: "10px" }}>
      {state?.approved ? (
        <HintTooltip title={`Approved ${entityName}.`}>
          <CheckIcon style={{ color: "#4caf50" }} />
        </HintTooltip>
      ) : (
        <Button
          variant="contained"
          color="primary"
          aria-label={`approve-${entityName}`}
          id={`approve-${entityName}`}
          onClick={() => approve(entityName, state, dispatch, userData)}
        >
          Approve
        </Button>
      )}
    </Box>
  ) : (
    <></>
  );
};
