import { FormComponent, Model } from "utils/models/fields";
import { OtherVendor } from "./types";

export const otherVendorInfo: FormComponent<OtherVendor> = {
  formComponent: "segment",
  name: "Other vendor",
  width: "full",
  entities: [
    {
      formComponent: "text-field",
      name: "name",
      label: "Name",
      width: "1/2",
      required: true,
      path: ["data", "info", "name"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "Address",
      label: "Address",
      width: "1/2",
      path: ["data", "info", "address"],
      default: null
    },
    {
      formComponent: "email-field",
      name: "email",
      label: "Email",
      width: "1/2",
      path: ["data", "info", "email"],
      default: null
    },
    {
      formComponent: "phone-field",
      name: "phone",
      label: "Phone",
      width: "1/2",
      path: ["data", "info", "phone"],
      default: null
    }
  ]
};

export const otherVendorStruct: Model<OtherVendor> = {
  formComponent: "model",
  schema: "update_other_vendor",
  name: "other_vendor",

  entities: [{ formComponent: "segment", entities: [otherVendorInfo] }]
};
