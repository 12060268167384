import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { DealershipSettings, SftpAdfData, EmailAdfData } from "./types";

export default function AdfSettings({
  dealershipSettings
}: {
  dealershipSettings?: DealershipSettings;
}) {
  const emailAdf = (dealershipSettings?.data?.info?.adfEmails?.filter(
    (adfData) => !adfData?.sftp
  ) ?? []) as EmailAdfData[];

  const sftpAdf =
    dealershipSettings?.data?.info?.adfEmails?.filter((adfData) => adfData?.sftp) ??
    ([] as SftpAdfData[]);


  if (emailAdf.length < 1 && sftpAdf.length < 1) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography color="primary" variant="h5">
            ADF Settings
          </Typography>
        </Grid>
        <Typography>No ADF records are configured at the moment.</Typography>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography color="primary" variant="h5">
          ADF Settings
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {emailAdf.length > 0 ? (
          <Grid style={{ flexDirection: "row", gap: 5, alignItems: "center", display: "flex" }}>
            <Typography>Email ADF Setup </Typography>
            <CheckCircleIcon style={{ color: "#4caf50" }} />
          </Grid>
        ) : (
          <p>No Email ADF setup available.</p>
        )}
      </Grid>
      <Grid item xs={6}>
        {sftpAdf.length > 0 ? (
          <Grid style={{ flexDirection: "row", gap: 5, alignItems: "center", display: "flex" }}>
            <Typography>SFTP ADF Setup</Typography>
            <CheckCircleIcon style={{ color: "#4caf50" }} />
          </Grid>
        ) : (
          <p>No SFTP ADF setup available.</p>
        )}
      </Grid>
    </Grid>
  );
}
