import React, { useState, useRef } from "react";
import { Search } from "@material-ui/icons";
import { InputBase, IconButton, makeStyles, Paper } from "@material-ui/core";
import { TableSettings } from ".";
import { HintTooltip } from "components/common/HintTooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: 0,
    transition: "all 0.5s"
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));
export default ({
  tableSettings,
  setTableSettings
}: {
  tableSettings: TableSettings;
  setTableSettings: (state: TableSettings) => void;
}) => {
  const [showSearch, setShowSearch] = useState(false);
  const ref = useRef<HTMLInputElement>();
  const classes = useStyles();
  return (
    <>
      <Paper
        elevation={showSearch || tableSettings.searchTerm !== "" ? 2 : 0}
        className={classes.root}
      >
        <InputBase
          style={{ width: showSearch || tableSettings.searchTerm !== "" ? 300 : 0 }}
          className={classes.input}
          placeholder="Search"
          ref={ref}
          onBlur={() => {
            setShowSearch(false);
          }}
          onChange={(event) => {
            setTableSettings({
              ...tableSettings,
              searchTerm: event.target.value,
              page: 0
            });
          }}
        />
        <HintTooltip title="Search">
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={() => {
              setShowSearch(true);

              if (ref.current !== undefined) {
                ref.current.getElementsByTagName("input")[0].focus();
              }
            }}
          >
            <Search />
          </IconButton>
        </HintTooltip>
      </Paper>
    </>
  );
};
