import { File } from "./types";
export const modifyFile = (file: File): File => {
  if (!file.data.info.contractDates) return file;
  const startDate = file.data.info.contractDates?.startDate
    ? new Date(file.data.info.contractDates?.startDate)
    : undefined;

  const endDate = file.data.info.contractDates.endDate
    ? new Date(
        file.data.info.contractDates.endDate
          ? new Date(file.data.info.contractDates.endDate)
          : new Date()
      )
    : new Date();
  if (!startDate)
    return {
      ...file,
      data: {
        ...file.data,

        info: {
          ...file.data.info,
          contractDates: {
            ...file.data.info.contractDates
          },
          status: "Inactive"
        }
      }
    };
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);
  return {
    ...file,
    data: {
      ...file.data,

      info: {
        ...file.data.info,
        contractDates: {
          ...file.data.info.contractDates
        },
        status: new Date(startDate) <= new Date() && new Date() <= endDate ? "Active" : "Inactive"
      }
    }
  };
};
