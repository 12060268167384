import React from "react";
import { StateAccess, RenderSet } from "utils/models/formGenerator";

interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
  path: string[];
  name: string;
  label: string;
  required: boolean;
}

export default function ValueField({
  stateAccess,
  renderSet,
  path,
  name,
  required,
  label
}: Props): JSX.Element {
  return (
    <>
      {(stateAccess.get(["MarkUp", "Type"]) === "ByPercent"
        ? renderSet.numberInputRenderer
        : renderSet.currencyInputRenderer)(
        { name, label, path: path as [], required },
        stateAccess,
        renderSet
      )}
    </>
  );
}
