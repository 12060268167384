import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { RenderSet, setByPath, StateAccess } from "utils/models/formGenerator";
import { Lender, OperatesInStateStatus } from "./types";
import OperatingStatesSetter, { getSupportedCollateralTypes } from "./OperatingStatesSetter";
import States from "us-states";
import { createOptionsForSelect } from "utils/models/fields";
import { getStateLabelByState } from "utils/functions";
import { collateralTypes } from "components/Deals/types";
import { User } from "components/Users/types";
import { RootState } from "app/rootReducer";
import { useSelector } from "react-redux";
import formEditContext from "components/Content/FormEditContext";
import StatusHistoryButton from "./StatusHistoryButton";

type Props = {
  stateAccess: StateAccess;
  renderSet: RenderSet;
};
export default ({ stateAccess, renderSet }: Props) => {
  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);
  const lenderCurrentUser: User | undefined = stateAccess.get<User>(["data", "currentUser"]);
  const oldOperatingStates: User | undefined = stateAccess.get<User>([
    "data",
    "oldOperatingStates"
  ]);
  const { initialState } = React.useContext(formEditContext);
  const initialLender = initialState as Lender;
  const changed =
    JSON.stringify(stateAccess.get(["data", "info", "operatingStates"])) !==
    JSON.stringify(initialLender?.data?.info?.operatingStates);
  React.useEffect(() => {
    if (currentUser && !lenderCurrentUser) {
      if (!oldOperatingStates) {
        stateAccess.set(
          [],
          setByPath(
            setByPath(
              stateAccess.get([]),
              ["data", "oldOperatingStates"],
              stateAccess.get(["data", "info", "operatingStates"])
            ),
            ["data", "currentUser"],
            currentUser
          )
        );
      } else {
        stateAccess.set(["data", "currentUser"], currentUser);
      }
    }
  }, [currentUser, lenderCurrentUser]);
  return (
    <Grid container>
      <Paper elevation={3} style={{ width: "100%", padding: "10px" }}>
        <Grid item xs={12}>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <Typography
              style={{
                color: "rgb(37, 78, 110)",
                fontSize: "19px",
                fontWeight: "bold",
                margin: "5px 0px"
              }}
            >
              Status (Operates in states)
            </Typography>
            <StatusHistoryButton stateAccess={stateAccess} />
          </div>
        </Grid>
        <Grid item xs={6}>
          {renderSet.radioInputRenderer<Lender>(
            {
              formComponent: "radio-field",
              name: "Operates IN",
              onChange: (stateAccess, stateStatus) => {
                if (stateStatus === OperatesInStateStatus.ACTIVE) {
                  const supportedStates = getSupportedCollateralTypes(
                    stateAccess.get(["data", "info", "collateralTypes"]),
                    Object.keys(States)
                  );
                  stateAccess.set(["data", "info", "operatingStates", "states"], supportedStates);
                } else if (stateStatus === OperatesInStateStatus.INACTIVE) {
                  stateAccess.set(["data", "info", "operatingStates", "states"], []);
                }
              },
              label: "Operates In",
              path: ["data", "info", "operatingStates", "status"],
              required: true,
              possibleValues: [
                { label: "All states", value: OperatesInStateStatus.ACTIVE },
                { label: "Some states", value: OperatesInStateStatus.STATE },
                { label: "Inactive", value: OperatesInStateStatus.INACTIVE }
              ],
              default: null
            },
            stateAccess,
            renderSet
          )}
        </Grid>
        {stateAccess.get(["_id"]) ? (
          <Grid item xs={2}>
            <OperatingStatesSetter stateAccess={stateAccess} />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {renderSet.tabListModelRenderer(
            {
              formComponent: "tab-list-model",
              name: "Add states",
              getTabName: (entity) => entity.state ?? "Select state",
              show: (stateAccess) =>
                [OperatesInStateStatus.STATE, OperatesInStateStatus.ACTIVE].includes(
                  stateAccess.get(["data", "info", "operatingStates", "status"])
                ),
              path: ["data", "info", "operatingStates", "states"],
              entity: {
                formComponent: "segment",
                entities: [
                  {
                    formComponent: "select-field",
                    name: "State",
                    label: "State",
                    width: "1/3",
                    path: ["state"],
                    options: createOptionsForSelect({
                      possibleValues: () => Object.keys(States),
                      getOptionLabel: (x) => getStateLabelByState(x),
                      getSelectedOption: (x, y) => x === y
                    })
                  },
                  {
                    formComponent: "radio-field",
                    name: "Status",
                    label: "Status In State",
                    width: "1/3",
                    required: true,
                    path: ["status"],
                    default: null,
                    possibleValues: [
                      { label: "Active", value: OperatesInStateStatus.ACTIVE },
                      { label: "Inactive", value: OperatesInStateStatus.INACTIVE },
                      { label: "Active In Some Counties", value: OperatesInStateStatus.COUNTY }
                    ]
                  },
                  {
                    formComponent: "multiselect-field",
                    name: "Counties",
                    label: "Counties",
                    show: (stateAccess: StateAccess) =>
                      stateAccess.get(["status"]) === OperatesInStateStatus.COUNTY,
                    width: "1/3",
                    path: ["counties"],
                    options: createOptionsForSelect({
                      possibleValues: (stateAccess) => {
                        return States[stateAccess.get(["state"])]?.counties ?? [];
                      },
                      getOptionLabel: (x) => x,
                      getSelectedOption: (x, y) => x === y
                    })
                  },
                  {
                    formComponent: "segment",
                    width: "full",
                    show: (stateAccess: StateAccess) =>
                      stateAccess.get(["status"]) !== OperatesInStateStatus.INACTIVE,
                    name: "Supported collateral types",
                    entities: collateralTypes.map((type) => ({
                      formComponent: "checkbox-field",
                      name: "automotiive",
                      label: type,
                      width: "1/4",
                      valueType: "boolean",
                      path: ["collateralTypes", type],
                      isDisabled: (): boolean => false,
                      isChecked: (stateAccess: StateAccess): boolean =>
                        stateAccess.get(["collateralTypes", type]),
                      toggle: (stateAccess: StateAccess) =>
                        stateAccess.set(
                          ["collateralTypes", type],
                          !stateAccess.get(["collateralTypes", type])
                        ),
                      required: true,
                      default: false
                    }))
                  }
                ]
              }
            },
            stateAccess,
            [],
            stateAccess,
            renderSet
          )}
        </Grid>
        {changed ? (
          <Grid item xs={6}>
            {renderSet.textInputRenderer(
              {
                type: "text",
                name: "status-comment",
                label: "Comment",
                required: true,
                path: ["data", "info", "operatingStates", "comment"],
                multiline: true,
                rows: 3
              },
              stateAccess,
              renderSet
            )}
          </Grid>
        ) : null}
      </Paper>
    </Grid>
  );
};
