import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteNoteRequest {
  _id: string;
}

interface DeleteNoteResponse {
  message: {
    _id: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteNoteRequest,
  DeleteNoteResponse
>("DeleteNote", {
  request: "delete_note"
});

export const deleteNoteActions = socketMessageSlice.actions;
export const deleteNote = socketAction;
export default socketMessageSlice.reducer;
