import { Validation } from "./types";
import {
  multyPermissionNode,
  permissionNode,
  relationPermissionNode,
  PermissionStruct
} from "components/Roles/types";

export type ValidationPermissionsStruct = PermissionStruct<Validation>;

export const validationPermissionStruct: ValidationPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      deal: relationPermissionNode("Deal", "deal"),
      documentTemplateId: permissionNode("Document template id"),
      filledCoordinates: multyPermissionNode("Filled coordinates"),
      category: {
        hasOneApplicant: permissionNode("Has One Applicant"),
        state: permissionNode("State"),
        applicantState: permissionNode("Applicant state"),
        lenderId: permissionNode("Lender id"),
        collateralType: permissionNode("Category type"),
        hasWarranty: permissionNode("Has warranty"),
        hasGap: permissionNode("Has gap"),
        hasTradeIn: permissionNode("Has trade in"),
        hasPayoff: permissionNode("Has payoff"),
        hasCashDown: permissionNode("Has cash down"),
        isNetTradeNegative: permissionNode("Is net trade negative"),
        hasCoApplicant: permissionNode("Has co applicant"),
        programType: permissionNode("programType")
      }
    }
  }
};
