import infoProperties from "./info_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

const data = {
  type: "object",
  required: ["info"],
  properties: {
    info: { type: "object", required: ["name"], properties: infoProperties },
  },
};

export default {
  new_vehicle_insurance_company: {
    $id: "newVehicleInsuranceCompany",
    type: "object",
    required: ["data"],
    properties: {
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  update_vehicle_insurance_company: {
    $id: "updateVehicleInsuranceCompany",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
        },
      },
    },
  },
  delete_vehicle_insurance_company: {
    $id: "deleteVehicleInsuranceCompany",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  recover_vehicle_insurance_company: {
    $id: "recoverVehicleInsuranceCompany",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  get_vehicle_insurance_company: {
    $id: "getVehicleInsuranceCompany",
    type: "object",
    required: ["_id"],
    properties: { _id: { type: "string" } },
  },
  all_vehicle_insurance_companies: {
    $id: "allVehicleInsuranceCompanies",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties },
    ],
  },
};
