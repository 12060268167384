import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

import { Settings } from "components/Settings/types";
import { StateAccess } from "utils/models/formGenerator";

import store from "../../app/store";
import { findRate } from "./Calculations";
import { CustomInsurance, Deal } from "./types";

const setInsuranceCost = (
  deal: Deal,
  insurance: CustomInsurance,
  settings?: Settings
): CustomInsurance => {
  const { type: dealType } = deal?.data?.info || {};
  const { provider, chosenRate } = insurance;

  const insuranceConditions =
    dealType === "Power Sport" && provider === "XtraRide"
      ? settings?.data?.info?.insuranceCosts?.powersport
      : dealType === "Automotive" && provider === "Protective"
      ? settings?.data?.info?.insuranceCosts?.automotive
      : null;

  const months =
    dealType === "Automotive" &&
    provider === "Protective" &&
    insurance?.chosenRate?.customMonthsEnabled &&
    typeof insurance?.chosenRate?.customMonths === "number" &&
    deal?.data?.info?.payment?.numberOfPayments === 87
      ? insurance?.chosenRate?.customMonths
      : deal?.data?.info?.payment?.numberOfPayments;

  const content =
    insuranceConditions &&
    findRate(
      {
        ...deal,
        data: {
          ...deal?.data,
          info: {
            ...deal?.data?.info,
            payment: { ...deal?.data?.info?.payment, numberOfPayments: months }
          }
        }
      },
      insuranceConditions
    );
  const cost = content && content?.cost ? content?.cost : 0;

  return {
    ...insurance,
    chosenRate: {
      ...chosenRate,
      months,
      dealerCostPrice: cost,
      remitPrice: cost,
      profit: parseFloat(((chosenRate?.retailPrice || 0) - cost).toFixed(2))
    }
  };
};

export default function CalculateCustomInsuranceCost({
  stateAccess,
  insurance,
  index
}: {
  index: number;
  stateAccess: StateAccess;
  insurance: CustomInsurance;
}) {
  const deal: Deal = stateAccess.get([]);
  const settings = store?.getState()?.listSettingsSlice?.settingsData?.entities?.[0];

  return (
    <Tooltip placement="top" title="Click here to calculate the insurance cost.">
      <IconButton
        onClick={() => {
          const newInsurance = setInsuranceCost(deal, insurance, settings);
          stateAccess.set<Deal>(
            ["data", "info", "aftermarketOptions", "insurances", index],
            newInsurance
          );
        }}
      >
        <MonetizationOnIcon color="primary" fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
}
