import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

type AddPrescreenRequest = FormState;

export interface AddPrescreenResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddPrescreenRequest,
  AddPrescreenResponse
>("AddPrescreen", {
  request: "new_prescreen"
});

export const addPrescreenActions = socketMessageSlice.actions;
export const addPrescreen = socketAction;
export default socketMessageSlice.reducer;
