import {
  createSocketMessageSlice,
  Identifiable
} from "../../Middlewares/socketMessageSliceCreator";

interface GetProductRatesRequest {
  requestId: string;
  data: {
    params: {
      EX1RateRequest: {
        EX1DealerID: string;
        EX1ProductID: string;
        Vehicle: Vehicle;
      };
    };
  };
}

interface RequestData {
  productId: string;
  VIN: string;
  Odometer: string;
  CarStatus: string;
  DealType: string;
  Surcharge: any;
}

interface Vehicle {
  VIN: string;
  Odometer: string;
  CarStatus: string;
  DealType: string;
  Supplemental: {
    Surcharge: Surcharge[];
  };
}

interface Surcharge {
  SurchargeCode: string;
  Value: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<GetProductRatesRequest, any>(
  "GetProductRates",
  {
    request: "express_ex1_rate"
  }
);

export const getProductRates = (request: RequestData & Identifiable) => {
  const { productId, requestId, Surcharge, VIN, DealType, Odometer, CarStatus } = request;
  return socketAction({
    requestId,
    data: {
      params: {
        EX1RateRequest: {
          EX1DealerID: process.env.REACT_APP_EX1DealerID as string,
          EX1ProductID: productId,
          Vehicle: {
            DealType,
            VIN,
            Odometer,
            CarStatus,
            Supplemental: {
              Surcharge
            }
          }
        }
      }
    }
  });
};

export const getProductsRatesAction = socketMessageSlice.actions;
export default socketMessageSlice.reducer;
