import { CustomRegexes } from "../../helpers";

export default {
  name: { type: "string" },
  url: { type: "string" },
  type: { type: "string" },
  dealTypeProperties: {
    type: "object",
    nullable: true,
    properties: {
      lendersIds: {
        type: "array",
        nullable: true,
        items: { type: "string" },
      },
      states: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
          pattern: CustomRegexes.LETTERS,
          errorMessage: { pattern: "must not contain numbers" },
        },
      },
      applicantStates: {
        type: "array",
        nullable: true,
        items: {
          type: "string",
          pattern: CustomRegexes.LETTERS,
          errorMessage: { pattern: "must not contain numbers" },
        },
      },
      types: {
        type: "array",
        nullable: true,
        items: { type: "string" },
      },
      hasCoApplicant: { type: "boolean", nullable: true },
      hasGAP: { type: "boolean", nullable: true },
      hasVSC: { type: "boolean", nullable: true },
      hasTrailer: { type: "boolean", nullable: true },
    },
  },
};
