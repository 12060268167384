import { RootState } from "app/rootReducer";
import Spinner from "components/Loader/Spinner";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { entityConfigs, EntityData, entitySlice } from "utils/entitySlice";
import { ShowForm } from "utils/models/ShowForm";
import { v4 as uuidv4 } from "uuid";
import { editSettings } from "./editSettingsSlice";
import { Link, Paper, TextField, Typography } from "@material-ui/core";

export default () => {
  const [requestId] = useState(uuidv4());
  const entity = useSelector((state: RootState) => state.listSettingsSlice["settingsData"])
    ?.entities?.[0];

  const entityData = useSelector(
    (state: RootState) => state.entitySlice.settings[entity?._id ?? ""]
  );
  const dispatch = useDispatch();

  const handleSubmit = (state: EntityData<"settings">) => (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const { createdAt, updatedAt, deleted, availableToRoles, ...data } = state;
    const roleIds = availableToRoles?.map((role: any) => role._id) ?? [];

    dispatch(editSettings({ ...(data as any), availableToRolesIds: roleIds, requestId }));
  };
  useEffect(() => {
    if (entity) {
      dispatch({
        type: entitySlice.actions.add.type,
        payload: { type: "settings", message: entity }
      });
    }
  }, [entity, dispatch]);
  const currentUser = useSelector((state: RootState) => state?.authSlice);
  const [dealId, setDealId] = useState("");
  return entityData ? (
    <>
      <ShowForm
        tabId={"settings"}
        initialState={entityData}
        hideEdit={false}
        model={entityConfigs["settings"].model}
        handleSubmit={handleSubmit}
      />
      {currentUser?.user?.email === "wfd@wfd.com" && (
        <Paper
          elevation={3}
          style={{
            padding: "20px",
            margin: "10px 0px"
          }}
        >
          <Typography
            style={{
              fontWeight: 500,
              fontSize: 22,
              lineHeight: "20px",
              marginBottom: 10,
              textAlign: "center"
            }}
          >
            Developer tools
          </Typography>
          <ul>
            <li>
              <Link
                target="_blank"
                href={`${process.env.REACT_APP_HTTP_URL as string}/api/update_deal_view`}
              >
                Update deal view
              </Link>
            </li>

            <li>
              <Link
                target="_blank"
                href={`${process.env.REACT_APP_HTTP_URL as string}/api/update_deal_view/${dealId}`}
              >
                Update deal view by id:
              </Link>
              <input
                style={{ marginLeft: 20 }}
                value={dealId}
                placeholder="Deal Id"
                onChange={(e) => setDealId(e.target.value)}
              />
            </li>

            <li>
              <Link
                target="_blank"
                href={`${process.env.REACT_APP_HTTP_URL as string}/api/migration?secret=123abc`}
              >
                Run migration
              </Link>
            </li>

            <li>
              <Link
                target="_blank"
                href={`${
                  process.env.REACT_APP_HTTP_URL as string
                }/api/update_dealership_statistics_view`}
              >
                Update dealership statistics
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                href={`${
                  process.env.REACT_APP_HTTP_URL as string
                }/api/update_manager_statistics_view`}
              >
                Update manager statistics
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                href={`${process.env.REACT_APP_HTTP_URL as string}/api/update_statistics_view`}
              >
                Update all statistics
              </Link>
            </li>
          </ul>
        </Paper>
      )}
    </>
  ) : (
    <Spinner text={"Loading "} style={{ position: "relative" }} />
  );
};
