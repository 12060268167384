import React from "react";

interface Props {
  fontSize: string;
  fontStyle?: string;
  text: string;
  line?: boolean;
  initial?: boolean;
}

export default function SignaturePlacement({
  fontSize,
  fontStyle = "normal",
  text,
  initial,
  line = false
}: Props): JSX.Element {
  return (
    <span
      style={{
        fontSize: fontSize,
        color: initial ? "green" : "red",
        fontStyle: fontStyle,
        fontFamily: "Bodoni Moda",
        position: "relative"
      }}
    >
      {text}
      {line && (
        <span
          style={{
            width: "46px",
            position: "absolute",
            top: "39px",
            left: "25px",
            height: "1px",
            backgroundColor: "black"
          }}
        />
      )}
    </span>
  );
}
