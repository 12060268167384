import { Schema } from "ajv";
import { CustomRegexes } from "../../helpers";
import { data as dealershipData } from "../dealerships/dealerships";

const address = {
  type: "object",
  nullable: true,
  properties: {
    line1: { type: "string", nullable: true },
    line2: { type: "string", nullable: true },
    city: {
      type: "string",
      pattern: CustomRegexes.LETTERS,
      errorMessage: {
        pattern: "should not contain numbers",
      },
      nullable: true,
    },
    state: {
      type: "string",
      pattern: CustomRegexes.LETTERS,
      errorMessage: {
        pattern: "should not contain numbers",
      },
      nullable: true,
    },
    postalCode: {
      type: "string",
      pattern: CustomRegexes.ZIP_CODE,
      nullable: true,
      errorMessage: {
        pattern:
          "should match either of the number formats: 'XXXXX' or 'XXXXX-XXXX'",
      },
    },
  },
};

const employmentStatus = {
  type: ["string", "null"],
  nullable: true,
  enum: [
    "Employed",
    "Unemployed",
    "Retired",
    "ActiveMilitary",
    "RetiredMilitary",
    "Other",
    "SelfEmployed",
    "Student",
    null,
  ],
  errorMessage: {
    enum: "must be one of the following: 'Employed','Unemployed','Retired','ActiveMilitary','RetiredMilitary','Other','SelfEmployed','Student'",
  },
};

const IncomeFrequency = {
  type: ["string", "null"],
  nullable: true,
  enum: ["Weekly", "Biweekly", "Monthly", "Annually", null],
  errorMessage: {
    enum: "must be one of the following: 'Weekly', 'Biweekly', 'Monthly', 'Annually'",
  },
};

const salutation = {
  type: ["string", "null"],
  nullable: true,
  enum: ["Dr.", "Mr.", "Mrs.", "Ms.", null],
  errorMessage: {
    enum: "must be one of the following: 'Dr.', 'Mr.', 'Mrs.', 'Ms.'",
  },
};
const suffix = {
  type: ["string", "null"],
  nullable: true,
  enum: ["I", "II", "III", "IV", "JR", "SR", null],
  errorMessage: {
    enum: "must be one of the following: 'I', 'II', 'III', 'IV', 'JR', 'SR'",
  },
};
const firstName = {
  type: "string",
  pattern: CustomRegexes.LETTERS,
  errorMessage: { pattern: "should not contain numbers" },
  nullable: true,
};
const middleName = {
  type: "string",
  pattern: CustomRegexes.LETTERS,
  errorMessage: { pattern: "should not contain numbers" },
  nullable: true,
};
const lastName = {
  type: "string",
  pattern: CustomRegexes.LETTERS,
  errorMessage: { pattern: "should not contain numbers" },
  nullable: true,
};
const ssn = {
  type: "string",
  pattern: CustomRegexes.SSN,
  errorMessage: {
    pattern: `
      SSN cannot
      begin with 666 or 900-999,
      be 078-05-1120 or 219-09-9999,
      contain all zeroes in a group(i.e., 000-##-####, ###-00-####, or ###-##-0000)
      or contain all matching values(i.e., 000-00-0000, 111-11-1111, 222-22-2222, etc.)
    `,
  },
  nullable: true,
};
const dob = {
  type: "string",
  format: "age-restriction",
  errorMessage: { format: "must be a valid date between 18 and 100" },
  nullable: true,
};
const phone = {
  type: "string",
  pattern: CustomRegexes.PHONE,
  errorMessage: {
    pattern: "should not contain letters and should not start with 0 or 1",
  },
  nullable: true,
};
const appraisalSource = {
  type: ["string", "null"],
  enum: [
    "ALG",
    "ALGE",
    "BB",
    "CBB",
    "CHRCA",
    "CHRUS",
    "GAL",
    "KBB",
    "MMR",
    "NAAA",
    "NADA",
    "POLK",
    "GALVES",
    "OTHER",
  ],
  errorMessage: {
    enum: `must be one of the following:
        'ALGE', 'BB', 'CBB', 'CHRCA', 'CHRUS',
        'GAL', 'KBB', 'MMR', 'NAAA', 'NADA',
        'POLK', 'GALVES', 'OTHER'`,
  },
  nullable: true,
};
const commonVehicleProperties = {
  chromeYear: { type: "number", nullable: true },
  chromeMake: { type: "string", nullable: true },
  chromeModel: { type: "string", nullable: true },
  chromeStyle: { type: "string", nullable: true },
  chromeMakeId: { type: "integer", nullable: true },
  chromeModelId: { type: "integer", nullable: true },
  chromeStyleId: { type: "integer", nullable: true },
  otherYear: { type: "number", nullable: true },
  otherMake: { type: "string", nullable: true },
  otherModel: { type: "string", nullable: true },
  otherTrim: { type: "string", nullable: true },
  otherOptions: { type: "string", nullable: true },
  certifiedUsed: { type: "boolean", nullable: true },
  vehicleType: {
    type: ["string", "null"],
    enum: [
      "Auto",
      "Motorcycle",
      "Industrial",
      "Marine",
      "RV",
      "Consumer",
      "Leisure",
      "HomeImp",
      "Generic",
      "ATV/Snowmobile",
      "PersonalWatercraft",
    ],
    errorMessage: {
      enum: `must be one of the following: 
                'Auto', 'Motorcycle', 'Industrial', 'RV', 
                  'Marine', 'Consumer', 'Leisure', 'HomeImp', 
                   'Generic', 'ATV/Snowmobile', 'PersonalWatercraft'`,
    },
  },
  odometerReading: { type: "number", nullable: true },
  odometerType: {
    type: ["string", "null"],
    enum: ["Miles", "Kilometers", null],
    errorMessage: {
      enum: "must be one of the following: 'Miles', 'Kilometers'",
    },
    nullable: true,
  },
  interiorColor: { type: "string", nullable: true },
  exteriorColor: { type: "string", nullable: true },
  stockNumber: { type: "string", nullable: true },
  vin: { type: "string", nullable: true },
  inventoryVehicleCondition: {
    type: ["string", "null"],
    enum: ["New", "Used", "Demo", null],
    errorMessage: {
      enum: "must be one of the following: 'New', 'Used', 'Demo'",
    },
    nullable: true,
  },
  transmissionType: {
    type: ["string", "null"],
    enum: ["Automatic", "Manual", null],
    errorMessage: {
      enum: "must be one of the following: 'Automatic', 'Manual'",
    },
    nullable: true,
  },
  url: { type: "string", nullable: true },
};
const vehicleBookCondition = {
  type: ["string", "null"],
  enum: [
    "AboveAverage",
    "Average",
    "BelowAverage",
    "BrandCertified",
    "CertifiedPreOwned",
    "Clean",
    "Excellent",
    "ExtraClean",
    "Fair",
    "Good",
    "MarketReady",
    "NonBrandCertified",
    "Rough",
    "VeryGood ",
    null,
  ],
  errorMessage: {
    enum: `must be one of the following: 
          'Average', 'BelowAverage', 'BrandCertified', 
          'CertifiedPreOwned', 'Clean', 'Excellent',
          'ExtraClean', 'Fair', 'Good', 'MarketReady',
          'NonBrandCertified', 'Rough', 'VeryGood'`,
  },
  nullable: true,
};
const bookDetails = {
  type: "object",
  nullable: true,
  properties: {
    source: appraisalSource,
    value: { type: "number", nullable: true },
    condition: vehicleBookCondition,
  },
};

const preferredLanguage = {
  type: ["string", "null"],
  nullable: true,
  enum: ["English", "Spanish", null],
  errorMessage: {
    enum: "must be one of the following: 'English', 'Spanish'",
  },
};

const employment = {
  type: "object",
  nullable: true,
  properties: {
    employerName: { type: "string", nullable: true },
    totalMonthsEmployed: { type: "number", nullable: true },
    totalYearsEmployed: { type: "number", nullable: true },
    occupation: { type: "string", nullable: true },
    workPhone: phone,
    status: employmentStatus,
    employerAddress: address,
  },
};

export const dealership: Schema = dealershipData;
export const targetPlatforms: Schema = {
  type: "array",
  nullable: true,
  items: {
    type: "object",
    properties: {
      id: { type: "string", nullable: true },
      partyId: { type: "string", nullable: true },
    },
    nullable: true,
  },
};

export const applicant: Schema = {
  type: "object",
  nullable: true,
  properties: {
    relationship: {
      type: ["string", "null"],
      nullable: true,
      enum: [
        "Self",
        "Spouse",
        "Relative",
        "RegisteredDomesticPartner",
        "CivilUnion",
        "Other",
        null,
      ],
      errorMessage: {
        enum: "must be one of the following: 'Self', 'Spouse', 'Relative', 'RegisteredDomesticPartner' ,'CivilUnion' ,'Other'",
      },
    },
    salutation: salutation,
    firstName: firstName,
    lastName: lastName,
    middleName: middleName,
    suffix: suffix,
    phone: phone,
    ssn: ssn,
    dateOfBirth: dob,
    address: address,
    driversLicenseNumber: { type: "string", nullable: true },
    driversLicenseState: { type: "string", nullable: true },
    monthsAtCurrentAddress: { type: "number", nullable: true },
    yearsAtCurrentAddress: { type: "number", nullable: true },
    otherPhone: phone,
    email: {
      type: "string",
      pattern: CustomRegexes.EMAIL,
      nullable: true,
      errorMessage: {
        pattern: "must match the format <emailname>@<provider>.<org>",
      },
    },
    preferredContactMethod: {
      type: ["string", "null"],
      nullable: true,
      enum: [
        "Email",
        "HomePhone",
        "CellPhone",
        "WorkPhone",
        "Letter",
        "OtherPhone",
        "OtherEmail",
        "Fax",
        "Other",
        null,
      ],
      errorMessage: {
        enum: "must be one of the following: 'Email', 'HomePhone','CellPhone','WorkPhone','Letter','OtherPhone','OtherEmail','Fax','Other'",
      },
    },
    preferredLanguage: preferredLanguage,
    currentEmployment: employment,
    income: { type: "number", nullable: true },
    incomeFrequency: IncomeFrequency,
    otherMonthlyIncome: { type: "number", nullable: true },
    otherMonthlyIncomeSource: { type: "string", nullable: true },
    housingStatus: {
      type: ["string", "null"],
      nullable: true,
      enum: [
        "Mortgage",
        "Rent",
        "Family",
        "OwnOutright",
        "Other",
        "Military",
        null,
      ],
      errorMessage: {
        enum: "must be one of the following: 'Mortgage','Rent','Family','OwnOutright','Other','Military'",
      },
    },
    maritalStatus: {
      type: ["string", "null"],
      nullable: true,
      enum: [
        "Single",
        "Married",
        "Divorced",
        "Widowed",
        "Unmarried",
        "Separated",
        null,
      ],
      errorMessage: {
        enum: "must be one of the following: 'Single','Married','Divorced','Widowed','Unmarried','Separated'",
      },
    },
    mortgageOrRentAmount: { type: "number", nullable: true },
    previousAddress: address,
    monthsAtPreviousAddress: { type: "number", nullable: true },
    yearsAtPreviousAddress: { type: "number", nullable: true },
    previousEmployment: employment,
    spouse: {
      type: "object",
      nullable: true,
      properties: {
        firstName: firstName,
        lastName: lastName,
        middleName: middleName,
        suffix: suffix,
        address: address,
        income: { type: "string", nullable: true },
        incomeFrequency: { type: "string", nullable: true },
        otherMonthlyIncome: { type: "string", nullable: true },
        otherMonthlyIncomeSource: { type: "string", nullable: true },
      },
    },
    references: {
      type: "array",
      nullable: true,
      items: {
        type: "object",
        properties: {
          salutation: salutation,
          firstName: firstName,
          lastName: lastName,
          middleName: middleName,
          suffix: suffix,
          phone: phone,
          ssn: ssn,
          dateOfBirth: dob,
          address: address,
        },
      },
    },
  },
};

export const driver: Schema = {
  type: "object",
  nullable: true,
  properties: {
    salutation: salutation,
    firstName: firstName,
    lastName: lastName,
    middleName: middleName,
    suffix: suffix,
    phone: phone,
    ssn: ssn,
    dateOfBirth: dob,
    address: address,
  },
};

export const vehicle: Schema = {
  type: "object",
  properties: { ...commonVehicleProperties },
  nullable: true,
};

export const tradeIn: Schema = {
  type: "object",
  nullable: true,
  properties: {
    ...commonVehicleProperties,
    tradeInType: {
      type: ["string", "null"],
      enum: ["Finance", "Leased", "Owned", null],
      errorMessage: {
        enum: "must be one of the following: 'Finance', 'Leased', 'Owned'",
      },
      nullable: true,
    },
    actualCashValue: { type: "number", nullable: true },
    monthlyPayment: { type: "number", nullable: true },
    payoffAmount: { type: "number", nullable: true },
    allowanceAmount: { type: "number", nullable: true },
    tradeInLicensePlateNumber: { type: "string", nullable: true },
    vehicleBookCondition: vehicleBookCondition,
    lienHolder: {
      type: "object",
      nullable: true,
      properties: { name: firstName, phone: phone, address: address },
    },
    engineType: { type: "string", nullable: true },
    appraisalDate: {
      type: "string",
      format: "date-time",
      errorMessage: { format: "must be a valid date" },
      nullable: true,
    },
    appraisalSource: appraisalSource,
    appraisalReferenceNumber: { type: "string", nullable: true },
  },
};

export const financeSummary: Schema = {
  type: "object",
  nullable: true,
  properties: {
    vehicleSellingPrice: { type: "number", nullable: true },
    salesTax: { type: "number", nullable: true },
    governmentFees: { type: "number", nullable: true },
    cashDown: { type: "number", nullable: true },
    rebate: { type: "number", nullable: true },
    creditLifeIns: { type: "number", nullable: true },
    term: { type: "number", nullable: true },
    requestedAPR: { type: "number", nullable: true },
    acquisitionFees: { type: "number", nullable: true },
    invoiceAmount: { type: "number", nullable: true },
    warranty: { type: "number", nullable: true },
    gap: { type: "number", nullable: true },
    accidentHealthIns: { type: "number", nullable: true },
    frontEndFees: { type: "number", nullable: true },
    msrp: { type: "number", nullable: true },
    estimatedBalloonAmount: { type: "number", nullable: true },
    estimatedPayment: { type: "number", nullable: true },
    usedCarBook: appraisalSource,
    mileage: { type: "number", nullable: true },
    otherFees: { type: "number", nullable: true },
    retailBookDetails: bookDetails,
    wholesaleBookDetails: bookDetails,
    bookValueType: {
      type: ["string", "null"],
      nullable: true,
      enum: ["Loan", "Trade", "Wholesale", "Retail", "NA", null],
      errorMessage: {
        enum: "must be one of the following: 'Loan', 'Trade', 'Wholesale', 'Retail', 'NA'",
      },
    },
    netTrade: { type: "number", nullable: true },
    amountRequested: { type: "number", nullable: true },
    creditScoreClassification: {
      type: ["string", "null"],
      nullable: true,
      enum: ["SuperPrime", "Prime", "NearPrime", "SubPrime", null],
      errorMessage: {
        enum: "must be one of the following: 'SuperPrime', 'Primer', 'NearPrime', 'SubPrime'",
      },
    },
    unpaidBalance: { type: "number", nullable: true },
    customerNegotiatedPrice: { type: "number", nullable: true },
    dealerNegotiatedPrice: { type: "number", nullable: true },
  },
};

export const extraData: Schema = {
  type: "object",
  nullable: true,
  properties: { name: firstName, value: { type: "string", nullable: true } },
};
