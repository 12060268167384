import axios, { AxiosProgressEvent } from "axios";
import { auth } from "../../firebase/firebase";
import { ApplicationParserType } from "components/Dealerships/types";

const toBase64 = (file: File): Promise<string | null | ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const parseApplicationFile = async (
  file: File,
  parserType: ApplicationParserType,
  onUploadProgress: (x: AxiosProgressEvent) => void
) => {
  const token = await auth.currentUser?.getIdToken();
  const base64File = (await toBase64(file)) as string;
  const response = await axios.post(
    `${process.env.REACT_APP_HTTP_URL}/api/v1/parse_application_file`,
    {
      file: base64File.replace("data:application/pdf;base64,", ""),
      name: file.name,
      parserType,
      type: file.type
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      onUploadProgress: onUploadProgress
    }
  );
  return response.data.result;
};
