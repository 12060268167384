import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditPrescreenRequest {
  data: FormState;
}

export interface EditPrescreenResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditPrescreenRequest,
  EditPrescreenResponse
>("EditPrescreen", {
  request: "update_prescreen"
});

export const editPrescreenActions = socketMessageSlice.actions;
export const editPrescreen = socketAction;
export default socketMessageSlice.reducer;
