import { XlStyledDialog } from "components/common/StyledDialog";
import React, { useState, useEffect } from "react";
import { DialogContent, DialogActions, Button, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import {
  generateDefault,
  fillDefaultsByPath,
  generateForm,
  StateAccess,
  getByPath,
  setByPath,
  RenderSet
} from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { EntityData } from "utils/entitySlice";
import SaveIcon from "@material-ui/icons/Save";
import AccessControl from "components/Access/AccessControl";
import { OtherVendor } from "components/OtherVendors/types";
import CloseDialogButton from "components/common/CloseDialogButton";
import { HintTooltip } from "components/common/HintTooltip";
import { Dealership } from "components/Dealerships/types";
import { Lender } from "components/Lenders/types";
import { Model } from "utils/models/fields";
import { AppDispatch } from "app/store";
import { RootState } from "app/rootReducer";
import { ContractType } from "components/Contracts/ContractTypes/types";

export default <T extends OtherVendor | Lender | Dealership | ContractType>({
  setSelected,
  renderSet,
  addSlice,
  struct,
  type,
  addFunction
}: {
  setSelected: (entity: any) => void;
  renderSet: RenderSet;
  type: "dealership" | "lender" | "other_vendor" | "contract_type";
  addSlice:
    | "addOtherVendorSlice"
    | "addLenderSlice"
    | "addDealershipSlice"
    | "addContractTypeSlice";
  struct: Model<T>;
  addFunction: (request: any) => (dispatch: AppDispatch) => Promise<void>;
}) => {
  const [requestId] = useState(uuidv4());
  const actionState = useSelector((state: RootState) => state[addSlice][requestId]);
  const initialState = generateDefault(struct, {}, fillDefaultsByPath as any) as any;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const formName = `${type}-new`;
  const [formState, setFormState] = useState(initialState);

  const stateAccess: StateAccess = {
    get: (path) => getByPath(formState as any, path),
    set: (path, value): any =>
      setFormState((previousFormState: any) => setByPath(previousFormState as any, path, value))
  };
  useEffect(() => {
    if (actionState?.status === "success" && actionState?.data !== null) {
      setSelected({
        data: actionState.data.message.data,
        _id: actionState.data.message._id,
        createdAt: actionState.data.message.createdAt,
        updatedAt: actionState.data.message.updatedAt
      });
    }
  }, [actionState, setSelected]);

  const handleSubmit = (state: EntityData<typeof type>) => (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleClose();
    if (canSubmit) {
      setCanSubmit(false);

      dispatch(addFunction({ ...state, requestId }));

      setTimeout(() => {
        setCanSubmit(true);
      }, 2000);
    } else {
      alert("Please dont submit the form twice!");
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormState(initialState);
    setOpen(false);
  };

  return (
    <>
      <AccessControl
        key={`add-${type}-button`}
        requiredPermissions={{ entity: type, action: "create" }}
      >
        <IconButton
          size="small"
          style={{ margin: "3px 0px 0px 10px" }}
          aria-label={`add-${type}`}
          id={`add-${type}-button`}
          onClick={handleOpen}
        >
          <HintTooltip title={`Click here to add a  ${type}.`}>
            <AddIcon />
          </HintTooltip>
        </IconButton>
      </AccessControl>
      {open ? (
        <XlStyledDialog>
          <DialogContent>
            <CloseDialogButton closeFunction={handleClose} />
            <form id={formName} autoComplete="off" onSubmit={handleSubmit(formState as any)}>
              {generateForm(struct, stateAccess, [], stateAccess, renderSet)}
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="contained">
              Cancel
            </Button>
            {renderSet.type === "edit" && (
              <Button
                disabled={!canSubmit}
                form={formName}
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            )}
          </DialogActions>
        </XlStyledDialog>
      ) : null}
    </>
  );
};
