import { OneSpanSigning } from "./types";
import {
  PermissionStruct,
  permissionNode,
  multyPermissionNode,
  relationPermissionNode
} from "components/Roles/types";

export type OneSpanSigningPermissionsStruct = PermissionStruct<OneSpanSigning>;

export const oneSpanPermissionsStruct: OneSpanSigningPermissionsStruct = {
  _id: permissionNode(" id"),
  createdAt: permissionNode("Created at"),
  updatedAt: permissionNode("Updated at"),
  deleted: permissionNode("Deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    dealId: permissionNode("Deal Id"),
    oneSpanPackageId: permissionNode("One Span Package Id"),
    info: {
      invalidEmails: multyPermissionNode("Invalid Emails"),
      status: {
        overall: permissionNode("REMOVETHIS"),
        applicant: permissionNode("Applicant"),
        dealership: permissionNode("Dealership"),
        coApplicant: permissionNode("Co-Applicant")
      },
      oneSpanRequestPackUrl: permissionNode("One span Request Pack URL"),
      generatedPackUrl: permissionNode("Generated pack url"),
      oneSpanEvents: multyPermissionNode("One Span events"),
      generatedBy: permissionNode("Generated by")
    }
  }
};
