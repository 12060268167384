const data = {
  components: {
    type: "array",
    items: {
      type: "object",
      properties: {
        id: { type: "string", nullable: true },
        page_id: { type: "string", nullable: true },
        group_id: { type: "string", nullable: true },
        group: { type: "boolean", nullable: true },
        showcase: { type: "boolean", nullable: true },
        only_show_if_degraded: { type: "boolean", nullable: true },
        name: {
          type: ["string", "null"],
          nullable: true,
          enum: [
            "VIN Check API",
            "Appone API",
            "Express API",
            "ASC API",
            "Dealertrack API",
            "Internal services",
            "PUB/SUB",
            "Mongo DB",
            "DEV External services",
            "DEV webfinancedirect",
            "DEV WFD services",
            "Api gateway",
            null,
          ],
          errorMessage: {
            enum: `must be one of the following: 
            'VIN Check API', 'Appone API', 'Express API', 'ASC API',
            'Dealertrack API', 'Internal services', 'PUB/SUB',
            'Mongo DB', 'DEV External services', 'Api gateway',
            'DEV webfinancedirect', 'DEV WFD services',`,
          },
        },
        status: {
          type: ["string", "null"],
          nullable: true,
          enum: [
            "operational",
            "Degraded performance",
            "Partial outage",
            "Major outage",
            "Under maintenance",
            null,
          ],
          errorMessage: {
            enum: "must be one of the following: 'operational', 'Degraded performance', 'Partial outage', 'Major outage', 'Under maintenance'",
          },
        },
        components: {
          type: "array",
          nullable: true,
          items: {
            type: "string",
          },
        },
        description: { type: "string", nullable: true },
        updated_at: { type: "string", nullable: true },
        start_date: { type: "string", nullable: true },
        position: { type: "number", nullable: true },
      },
    },
  },
  page: {
    type: "object",
    properties: {
      id: { type: "string", nullable: true },
      name: { type: "string", nullable: true },
      time_zone: { type: "string", nullable: true },
      updated_at: { type: "string", nullable: true },
      url: { type: "string", nullable: true },
    },
  },
};

export default {
  api_status_updated: {
    $id: "api_status_updated",
    type: "object",
    properties: data,
  },
  api_status: {
    $id: "api_status",
    type: "object",
    additionalProperties: true,
  },
};
