import React from "react";
import { VerificationStatus } from "../types/external/verifications/common";
import formEditContext from "components/Content/FormEditContext";
import { Verification } from "../types/internal/personaVerifications";
import {
  DialogActions,
  Button,
  Grid,
  Typography,
  capitalize,
  Dialog,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import formatDate from "utils/formatDate";
import CloseDialogButton from "components/common/CloseDialogButton";
import WarningDialog from "./WarningDialog";
export const getColorByStatus = (status: VerificationStatus | "Valid" | "Invalid") => {
  switch (status) {
    case "Valid":
    case VerificationStatus.Passed:
      return "rgb(80, 165, 56)";
    case "Invalid":
    case VerificationStatus.Failed:
      return "red";
    default:
      return "#eed202";
  }
};

type DialogActionsProps = {
  recordsLength: number;
  retryFunction: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedVerificationType: keyof Verification | "all"
  ) => void;
  selectedVerificationType: keyof Verification | "all";
  isLoading: boolean;
  closeFunction: () => void;
};
export const VerificationOrReportDialogActions = ({
  retryFunction,
  selectedVerificationType,
  isLoading,
  closeFunction,
  recordsLength
}: DialogActionsProps) => {
  const { enabled: editMode } = React.useContext(formEditContext);
  const [warningDialogOpen, setWarningDialogOpen] = React.useState(false);
  return (
    <React.Fragment>
      {warningDialogOpen ? (
        <WarningDialog
          closeFunction={() => setWarningDialogOpen(false)}
          retryFunction={retryFunction}
          selectedVerificationType={selectedVerificationType}
        />
      ) : null}
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button
          disabled={(editMode !== undefined && !selectedVerificationType) || isLoading}
          onClick={(e) => {
            recordsLength >= 3
              ? setWarningDialogOpen(true)
              : retryFunction(e, selectedVerificationType);
          }}
          variant="contained"
          autoFocus
          color="primary"
          id="confirm"
        >
          Perform again
        </Button>
        <Button onClick={closeFunction} variant="contained" color="primary" id="cancel">
          Close
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export const renderVerificationOrReportHeader = (
  status: string,
  statusColor: string,
  performedAt: string | undefined,
  performedByUserEmail: string | undefined,
  handleViewRaw: () => void
) => {
  return (
    <React.Fragment>
      <Grid item xs={2}>
        <div
          style={{
            border: `2px solid ${statusColor}`,
            borderRadius: "5px",
            textAlign: "center",
            verticalAlign: "middle",
            color: statusColor,
            fontWeight: "bold"
          }}
        >
          {capitalize(status)}
        </div>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h6">
          Performed at: {formatDate(new Date(performedAt ?? ""), "medium", true)}
          {performedByUserEmail ? ` by ${performedByUserEmail}` : ""}
        </Typography>
      </Grid>
      <Grid item xs={2} style={{ textAlign: "right" }}>
        <Button onClick={handleViewRaw}>View raw</Button>
      </Grid>
    </React.Fragment>
  );
};
