const factor = {
  type: "array",
  items: {
    type: "object",
    properties: {
      _: { type: "string", nullable: true },
      attributes: {
        type: "object",
        properties: {
          code: { type: "string", nullable: true },
        },
      },
    },
  },
};

const creditResponse = {
  type: "object",
  properties: {
    Results: {
      type: "object",
      nullable: true,
      properties: {
        XML_Version: {
          type: "array",
          items: { type: "string", nullable: true },
        },
        Transaction_Information: {
          type: "array",
          items: {
            type: "object",
            properties: {
              Transid: {
                type: "array",
                items: { type: "string", nullable: true },
              },
              Token: {
                type: "array",
                items: { type: "string", nullable: true },
              },
              DuplicateFound: {
                type: "array",
                items: { type: "string", nullable: true },
              },
              CreditReportSummary: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    DuplicateFound: {
                      type: "array",
                      items: { type: "string", nullable: true },
                    },
                    DuplicateMessage: {
                      type: "array",
                      items: { type: "string", nullable: true },
                    },
                    IframeUrl: {
                      type: "array",
                      items: { type: "string", nullable: true },
                    },
                  },
                },
              },
            },
          },
        },
        bureau_xml_data: {
          type: "array",
          items: {
            type: "object",
            nullable: true,
            properties: {
              TU_Report: {
                type: "array",
                items: {
                  type: "object",
                  nullable: true,
                  properties: {
                    subject_segments: {
                      type: "array",
                      items: {
                        type: "object",
                        nullable: true,
                        properties: {
                          attributes: {
                            type: "object",
                            nullable: true,
                            properties: {
                              id: { type: "string", nullable: true },
                              num: { type: "string", nullable: true },
                            },
                          },
                          scoring_segments: {
                            type: "array",
                            items: {
                              type: "object",
                              nullable: true,
                              properties: {
                                attributes: {
                                  type: "object",
                                  nullable: true,
                                  properties: {
                                    id: { type: "string", nullable: true },
                                    product: { type: "string", nullable: true },
                                  },
                                },
                                scoring: {
                                  type: "array",
                                  items: {
                                    type: "object",
                                    nullable: true,
                                    properties: {
                                      attributes: {
                                        type: "object",
                                        properties: {
                                          id: {
                                            type: "string",
                                            nullable: true,
                                          },
                                        },
                                      },
                                      ModelIndicator: {
                                        type: "array",
                                        items: {
                                          type: "object",
                                          properties: {
                                            _: {
                                              type: "string",
                                              nullable: true,
                                            },
                                            attributes: {
                                              type: "object",
                                              properties: {
                                                code: {
                                                  type: "string",
                                                  nullable: true,
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                      sign: {
                                        type: "array",
                                        items: {
                                          type: "string",
                                          nullable: true,
                                        },
                                      },
                                      score: {
                                        type: "array",
                                        items: {
                                          type: "string",
                                          nullable: true,
                                        },
                                      },
                                      ScoreRange: {
                                        type: "array",
                                        items: {
                                          type: "string",
                                          nullable: true,
                                        },
                                      },
                                      ScoreRank: {
                                        type: "array",
                                        items: {
                                          type: "string",
                                          nullable: true,
                                        },
                                      },
                                      factor1: factor,
                                      factor2: factor,
                                      factor3: factor,
                                      factor4: factor,
                                      factor5: factor,
                                    },
                                    // additionalProperties: true,
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        custom_report_url: {
          type: "array",
          items: {
            type: "object",
            nullable: true,
            properties: {
              iframe: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    attributes: {
                      type: "object",
                      properties: {
                        id: { type: "string", nullable: true },
                        src: { type: "string", nullable: true },
                        height: { type: "string", nullable: true },
                        width: { type: "string", nullable: true },
                        frameborder: { type: "string", nullable: true },
                        scrolling: { type: "string", nullable: true },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        custom_report: {
          type: "array",
          items: { type: "string", nullable: true },
        },
      },
    },
  },
};

export default creditResponse;
