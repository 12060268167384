import React, { useEffect } from "react";
import InfoIcon from "@material-ui/icons/Info";
import { Paper, IconButton, Typography } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import toNormalCase from "utils/toNormalCase";
import { ErrorResponse } from "./checkCreditScoreSlice";
import { CreditCheckTypes } from "./types";
import { HintTooltip } from "components/common/HintTooltip";
import { RootState } from "app/rootReducer";
import { getUserList, removeUserList } from "components/Users/listUserSlice";
import { useSelector, useDispatch } from "react-redux";
import { CreditPrequalify, SuccessResponsePrequalify } from "types/credit_prequalify";

const isSuccessScore = (
  score: SuccessResponsePrequalify | ErrorResponse | undefined
): score is SuccessResponsePrequalify => score?.hasOwnProperty("XML_Report") === true;

export const formatCreditBureausScores = (creditPrequalify: CreditPrequalify) => {
  const score = creditPrequalify?.data["700CreditResponse"]?.Results;
  let result = null;
  if (isSuccessScore(score)) {
    result = score.XML_Report?.[0].Prescreen_Report?.map((x) => x?.Score[0]);
    switch (creditPrequalify?.data.type) {
      case CreditCheckTypes.Joint:
        return `${creditPrequalify?.data.request.NAME ?? ""} - ${
          result?.[0] ? result[0] : "N/A"
        } / ${creditPrequalify?.data.request.SPOUSE} - ${result?.[1] ? result[1] : "N/A"}`;
      default:
        return `${creditPrequalify?.data.request.NAME ?? ""} - ${result?.[0] ? result[0] : "N/A"}`;
    }
  }
  return "";
};
export const extractUrlFromResponse = (
  creditPrequalify: CreditPrequalify
): string | undefined | null => {
  try {
    const srcMatch = creditPrequalify?.data?.[
      "700CreditResponse"
    ]?.Results?.custom_report?.[0].match(/<iframe[^>]+src="([^"]+)"/i);

    if (srcMatch && srcMatch[1]) {
      let url = srcMatch[1];
      url = url.replace(/&amp;/gi, "&");
      return url;
    }

    return null;
  } catch (error) {
    console.error("error from getting the iframe url", error);
    return undefined;
  }
};
export const CreditPrequalifyView = ({
  creditPrequalify,
  handleShowDialog
}: {
  creditPrequalify: CreditPrequalify[];
  handleShowDialog: (url: string) => void;
}) => {
  const dispatch = useDispatch();
  const listId = "creditScoresUsers";

  const userList = useSelector((state: RootState) => state.listUserSlice[listId]);

  useEffect(() => {
    if (!userList) {
      dispatch(
        getUserList(listId, {
          options: { pagination: false }
        })
      );
    }
    return () => {
      dispatch(removeUserList(listId));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {creditPrequalify?.map((creditPrequalify, index) => {
        const url = extractUrlFromResponse(creditPrequalify);
        return (
          <HintTooltip
            key={index}
            title={
              <span>
                Created by:{" "}
                {
                  userList?.entities?.find((x) => x._id === creditPrequalify.creatorId)?.data.info
                    .email
                }
              </span>
            }
          >
            <Paper
              elevation={3}
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "14px"
              }}
            >
              <div style={{ marginLeft: "5px" }}>{toNormalCase(creditPrequalify.data?.type)}</div>
              <div style={{ alignItems: "center", display: "flex", gap: 5 }}>
                {formatCreditBureausScores(creditPrequalify)}{" "}
                <div style={{ alignItems: "center", display: "flex", gap: 2 }}>
                  (<Typography variant="caption">Soft pull</Typography>
                  <InfoIcon fontSize="small" color="primary" />)
                </div>
              </div>

              <div>
                <IconButton
                  id="show-score-icon"
                  style={{ marginRight: "5px" }}
                  color="primary"
                  disabled={!url}
                  onClick={() => {
                    if (url) handleShowDialog(url);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </div>
            </Paper>
          </HintTooltip>
        );
      })}
    </>
  );
};
