import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteDealershipRequest {
  _id: string;
}

export interface DeleteDealershipResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteDealershipRequest,
  DeleteDealershipResponse
>("DeleteDealership", {
  request: "delete_dealership"
});

export const deleteDealershipActions = socketMessageSlice.actions;
export const deleteDealership = socketAction;
export default socketMessageSlice.reducer;
