import { createSocketMessageSlice } from "../../Middlewares/socketMessageSliceCreator";

interface DeleteColumnGroupRequest {
  _id: string;
}

export interface DeleteColumnGroupResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteColumnGroupRequest,
  DeleteColumnGroupResponse
>("DeleteColumnGroup", {
  request: "delete_column_group"
});

export const deleteColumnGroupActions = socketMessageSlice.actions;
export const deleteColumnGroup = socketAction;
export default socketMessageSlice.reducer;
