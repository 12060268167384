import React from "react";
import { Button, Dialog, DialogContent, DialogActions, DialogTitle } from "@material-ui/core";
import CloseDialogButton from "../common/CloseDialogButton";
import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import Table, { CellValue, Column } from "components/Table";
import { useSelector } from "react-redux";
import { getDealList } from "components/Deals/listDealSlice";
import { DealData } from "components/Deals/types";

interface Props {
  handleClose: () => void;
  open: boolean;
  applicantId: string;
}

export default ({ handleClose, open, applicantId }: Props) => {
  const dealLists = useSelector((state: RootState) => state.listDealSlice);

  const columns: Column<DealData>[] = [
    {
      getData: (entry): CellValue =>
        `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
          entry.data?.applicant?.data?.info?.lastName ?? ""
        }`,
      label: "Customer",
      name: "Customer"
    },
    {
      getData: (entry): CellValue => entry.data?.info?.refNumber,
      label: "Ref #",
      name: "Ref #",
      options: {
        sort: true,
        path: ["data", "info", "refNumber"]
      }
    },
    {
      getData: (entry): CellValue => entry.data?.dealership?.data?.info?.name ?? "",
      label: "Dealership",
      name: "Dealership"
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
      label: "Make",
      name: "Make"
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
      label: "Model",
      name: "Model"
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.VIN,
      label: "VIN",
      name: "VIN"
    },
    {
      getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
      label: "Lender",
      name: "Lender"
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.user?.data?.info?.firstName ?? ""} ${
          entry.data?.user?.data?.info?.lastName ?? ""
        }`,
      label: "F&I mgr",
      name: "F&I mgr"
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.dealership?.data?.representative?.data?.info?.firstName ?? ""} ${
          entry.data?.dealership?.data?.representative?.data?.info?.lastName ?? ""
        }`,
      label: "Rep",
      name: "Rep"
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Actions",
      name: "Actions"
    }
  ];

  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={handleClose}>
      <DialogTitle id="deal-duplicates-dialog">
        Deals <CloseDialogButton closeFunction={handleClose} />
      </DialogTitle>

      <DialogContent style={{ display: "flex", justifyContent: "center", position: "relative" }}>
        <div style={{ position: "relative", width: "100%", padding: "20px" }}>
          <AccessControl requiredPermissions={{ entity: "deal", action: "read" }}>
            <Table
              entityName="deal"
              tableName="credit-score-deals"
              listFunction={getDealList}
              listEntity={dealLists[`table-deals-duplicated-applicant-${applicantId}`]}
              query={{ [`data.applicantId`]: applicantId }}
              sort={{ createdAt: "desc" }}
              title={"Deals"}
              slice={`table-deals-duplicated-applicant-${applicantId}`}
              columns={columns}
              hideDeleteButton={true}
              hideAddButton={true}
              hideRecoverButton={true}
            />
          </AccessControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
