import { CustomRegexes } from "../../helpers";

export default {
  name: { type: "string" },
  refNumber: { type: "string", nullable: true },
  notifyRenewal: { type: "number", nullable: true },
  emailsToNotify: {
    type: "array",
    items: {
      type: "object",
      nullable: true,
      required: ["email"],
      properties: {
        email: {
          type: "string",
          pattern: CustomRegexes.EMAIL,
          errorMessage: {
            pattern: "must match the format <emailname>@<provider>.<org>",
          },
          nullable: true,
        },
      },
    },
  },
};
