import React, { useState } from "react";
import { StateAccess, RenderSet, getByPath, setByPath } from "utils/models/formGenerator";
import { createOptionsForSelect, Path } from "utils/models/fields";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { getLenderList, removeLenderList } from "./listLenderSlice";
import { v4 as uuidv4 } from "uuid";
import { Deal } from "../Deals/types";
import { Lender, OperatesInStateStatus } from "components/Lenders/types";
import { Contract } from "components/Contracts/types";
import { Chargeback } from "components/Chargebacks/types";

interface Props<T> {
  stateAccess: StateAccess;
  path: Path<T>;
  lenderIdPath: Path<T>;
  renderSet: RenderSet;
  name: string;
  label: string;
  type?: "lender_ticket" | "chargeback" | "wfd_user" | "settings" | "business_report";
  showCheckbox?: boolean;
  required?: (stateAccess: StateAccess) => any;
}

export default function LenderPreview<T extends Contract | Chargeback>({
  stateAccess,
  path,
  lenderIdPath,
  renderSet,
  name,
  label,
  type,
  required
}: Props<T>) {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const listId = `list-autocomplete-lender-${requestId}`;
  const lenderList = useSelector((state: RootState) => state.listLenderSlice[listId]);
  const state = stateAccess.get([]);
  const lender: Lender = stateAccess.get<T>(path);
  const lenderStateAccess: StateAccess = {
    get: (path) => getByPath<Lender>(lender, path as Path<Lender>),
    set: (_, value): any => {
      if (type === "lender_ticket") {
        stateAccess.set<T>(
          [],
          setByPath(
            setByPath(setByPath(state, lenderIdPath, value?._id), path, value),
            ["data", "replyTo"],
            [(value as Lender)?.data?.info?.email]?.filter((x) => x)
          )
        );
      } else {
        stateAccess.set<T>([], setByPath(setByPath(state, lenderIdPath, value?._id), path, value));
      }
    }
  };

  const dispatchGetLenderList = (showAllLenders = false) => {
    dispatch(
      getLenderList(listId, {
        options: { pagination: false, sort: { "data.info.name": "asc" } }
      })
    );
  };

  const handleLoadLenders = () => {
    if (!lenderList) dispatchGetLenderList();
    return () => {
      dispatch(removeLenderList(listId));
      return;
    };
  };

  return (
    <>
      {renderSet.selectInputRenderer(
        {
          formComponent: "select-field",
          name,
          label,
          path: [],
          onOpen: handleLoadLenders,
          options: createOptionsForSelect({
            possibleValues: () => lenderList?.entities ?? [],
            getOptionLabel: (lender) => {
              return lender?.data?.info?.name ?? "";
            },
            getSelectedOption: (x, y) => {
              return x._id === y._id;
            }
          }),
          valueToString: (el) => el?.data?.info?.name,
          required: required && required(stateAccess)
        },
        lenderStateAccess,
        [],
        lenderStateAccess,
        renderSet
      )}
    </>
  );
}
