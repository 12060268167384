import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  capitalize,
  Typography
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import CloseDialogButton from "components/common/CloseDialogButton";
import React, { useState } from "react";
import { StateAccess } from "utils/models/formGenerator";
import { Deal, StatusReasons } from "./types";
import formatDate from "utils/formatDate";
import useDealershipUsers from "Chat/hooks/useDealershipUsers";
import useWfdUsers from "Chat/hooks/useWfdUsers";
import { User } from "components/Users/types";
import { DealershipUser } from "types/dealeship_users";
export default function StatusReasonsButton({ stateAccess }: { stateAccess: StateAccess }) {
  const deal: Deal = stateAccess.get([]);
  const [open, setOpen] = useState(false);

  const { data: dealershipUsers } = useDealershipUsers(
    deal?.data?.info?.statusReasons?.dealership?.userId
      ? { query: { _id: deal?.data?.info?.statusReasons?.dealership?.userId } }
      : undefined
  );
  const { data: wfdUsers } = useWfdUsers(
    deal?.data?.info?.statusReasons?.manager?.userId
      ? { query: { _id: deal?.data?.info?.statusReasons?.manager?.userId } }
      : undefined
  );

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        style={{ position: "absolute", right: 22, top: -5, zIndex: 1 }}
      >
        <InfoOutlinedIcon />
      </IconButton>
      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        <CloseDialogButton closeFunction={handleClose} />
        <DialogTitle id="customized-dialog-title">Status Reasons</DialogTitle>
        <DialogContent>
          <Typography variant="h6">
            Previous status:{" "}
            {capitalize(
              structuredClone(deal?.data?.info?.statusHistory ?? [])?.sort(
                (b, a) => new Date(b?.date)?.getTime() - new Date(a?.date)?.getTime()
              )?.[1]?.status ?? "N/A"
            )}
          </Typography>
          <TableContainer style={{ marginTop: "10px" }}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Creator</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>User</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderTableRowWithData("applicant", deal?.data?.info?.statusReasons, undefined)}
                {renderTableRowWithData(
                  "dealership",
                  deal?.data?.info?.statusReasons,
                  dealershipUsers
                )}
                {renderTableRowWithData("manager", deal?.data?.info?.statusReasons, wfdUsers)}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const renderTableRowWithData = (
  type: keyof StatusReasons,
  data: StatusReasons | undefined,
  users: Array<User | DealershipUser> | undefined
) => {
  const user =
    type !== "applicant"
      ? (users ?? [])?.find((user) => user._id === data?.[type]?.userId)
      : undefined;
  return (
    <TableRow>
      <TableCell>{capitalize(type)}</TableCell>
      <TableCell>{data?.[type]?.reason || "N/A"}</TableCell>
      <TableCell>{formatDate(data?.[type]?.date, "medium") || "N/A"}</TableCell>
      <TableCell>
        {[user?.data?.info?.firstName, user?.data?.info?.lastName]?.filter((x) => x).join(" ") ||
          "N/A"}
      </TableCell>
    </TableRow>
  );
};
