import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { PrintedDocument } from "./types";

interface ApprovePrintedDocumentRequest {
  _id: string;
}
interface ApprovePrintedDocumentResponse {
  message: PrintedDocument;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  ApprovePrintedDocumentRequest,
  ApprovePrintedDocumentResponse
>("ApprovePrintedDocumentResponse", {
  request: "approve_printed_document"
});

export const approvePrintedDocumentActions = socketMessageSlice.actions;
export const approvePrintedDocument = socketAction;
export default socketMessageSlice.reducer;
