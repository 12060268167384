import {
  Setting,
  Condition,
  Cell,
  VerticalSetting,
  BaseRequirement,
  HorizontalSetting,
  HorizontalRequirement,
  isRequirement,
  BodyCell,
  BottomRequirement,
  isBottomRequirement,
  isHorizontalRequirement,
  MainCell,
  Requirement
} from "./types";
import { StateAccess, getByPath, setByPath } from "utils/models/formGenerator";
import { Path } from "utils/models/fields";

const sum = (xs: Array<number>) => xs.reduce((x, y) => x + y, 0);

const calcWidth = (x: BaseRequirement): number =>
  !isRequirement(x)
    ? 1
    : sum(
        (
          x.child.requirements as Array<
            BaseRequirement | Requirement<"horizontal"> | Requirement<"vertical">
          >
        ).map(calcWidth)
      );

const calcHeight = calcWidth;
const calcHeightNotSoOld = (x: BaseRequirement): number =>
  !isRequirement(x) ? 1 : 1 + calcHeightNotSoOld(x.child.requirements[0]);

// const calcHeightOld = (x: Setting): number =>
//   1 + (!isRequirement(x.requirements[0]) ? 0 : calcHeightOld(x.requirements[0].child));

const arrayZip = <T extends any>([x, ...rest]: Array<Array<Array<T>>>) => {
  return x.map((el, i) => {
    return el.concat(...rest.map((y) => y[i]));
  });
};

const createHorizontalHeaderRows = (
  x: Setting,
  accumulatedStateAccess: StateAccess,
  accumulatedConditions: Condition[]
): Cell[][] => {
  const accumulated =
    (x?.requirements[0] as Requirement<"vertical">)?.child === undefined
      ? []
      : arrayZip(
          (x.requirements as Array<Requirement<"vertical">>).map((y, yi) => {
            return createHorizontalHeaderRows(
              y.child,
              {
                get: (defaultPath: any) =>
                  accumulatedStateAccess.get<VerticalSetting>([
                    "requirements",
                    yi,
                    "child",
                    ...defaultPath
                  ] as Path<VerticalSetting>),
                set: (defaultPath: any, value: any): any =>
                  accumulatedStateAccess.set<VerticalSetting>(
                    ["requirements", yi, "child", ...defaultPath] as Path<VerticalSetting>,
                    (z: any) => {
                      return value(
                        getByPath(x, ["requirements", yi, "child", ...defaultPath] as any)
                      );
                    }
                  )
              },
              [...accumulatedConditions, { path: x.path, type: y.type, criteria: y.criteria }]
            );
          })
        );

  return [
    ((x?.requirements as Array<Requirement<"vertical"> | BaseRequirement>) || []).map(
      (y, index) => {
        const condition = { path: x.path, type: y.type, criteria: y.criteria };
        return {
          type: "header" as const,
          direction: "horizontal",
          condition: condition,
          name: x.name,
          accumulatedConditions: [...accumulatedConditions, condition],
          width: calcWidth(y as Requirement<"vertical">),
          height: 1,
          stateAccess: {
            get: (defaultPath: Path<Requirement<"vertical">> | Path<BaseRequirement> | []) =>
              accumulatedStateAccess.get<Setting>([
                "requirements",
                index,
                ...defaultPath
              ] as Path<Setting>),
            set: (
              defaultPath: Path<Requirement<"vertical">> | Path<BaseRequirement> | [],
              value: any
            ) => accumulatedStateAccess.set(["requirements", index, ...defaultPath] as any, value)
          } as StateAccess,
          groupStateAccess: {
            get: (defaultPath: Path<Requirement<"vertical">> | Path<BaseRequirement> | []) =>
              accumulatedStateAccess.get<Setting>(["requirements", ...defaultPath] as any),
            set: (defaultPath: any, value: any) =>
              accumulatedStateAccess.set(["requirements", ...defaultPath] as any, value)
          } as StateAccess,
          indexInGroup: index
        };
      }
    ),
    ...accumulated
  ];
};
const toConditionsHorizontal = (
  x: HorizontalSetting,
  conditions: Array<Condition>,
  stateAccess: StateAccess
): any => {
  return (x.requirements as Array<Requirement<"horizontal"> | HorizontalRequirement>).map(
    (y, index) => {
      const newConditions = [...conditions, { path: x.path, type: y.type, criteria: y.criteria }];
      const segment = isRequirement(y) ? "child" : "vertical";
      const newStateAccess: StateAccess = {
        get: (path) => stateAccess.get(["requirements", index, segment, ...path] as any),
        set: (path, value): any => {
          stateAccess.set(["requirements", index, segment, ...path] as any, value);
        }
      };

      if (!isRequirement(y)) {
        return toConditions((y as HorizontalRequirement).vertical, newConditions, newStateAccess);
      } else {
        return toConditionsHorizontal(
          (y as Requirement<"horizontal">).child,
          newConditions,
          newStateAccess
        );
      }
    }
  );
};
const toConditions = (
  x: VerticalSetting,
  conditions: Array<Condition>,
  stateAccess: StateAccess
): Array<BodyCell> => {
  return ((x?.requirements as Array<Requirement<"vertical"> | BottomRequirement>) || []).flatMap(
    (y, index) => {
      const newConditions = [...conditions, { path: x.path, type: y.type, criteria: y.criteria }];

      if (isBottomRequirement(y)) {
        return [
          {
            conditions: newConditions,
            type: "body" as const,
            height: 1,
            width: 1,
            stateAccess: {
              get: (path) =>
                stateAccess.get<BottomRequirement>(["requirements", index, ...path] as any),
              set: (path, value): any => {
                stateAccess.set(["requirements", index, ...path] as any, value);
              }
            },

            groupStateAccess: {
              get: (path) => stateAccess.get(["requirements", ...path] as any),
              set: (path, value): any => {
                stateAccess.set(["requirements", ...path] as any, value);
              }
            },
            indexInGroup: index
          }
        ];
      } else {
        return toConditions((y as Requirement<"vertical">).child, newConditions, {
          get: (path) => stateAccess.get(["requirements", index, "child", ...path] as any),
          set: (path, value): any => {
            stateAccess.set(["requirements", index, "child", ...path] as any, value);
          }
        });
      }
    }
  );
};
const createVerticalHeadersAndContent = (
  x: Setting,
  accumulatedStateAccess: StateAccess,
  accumulatedConditions: Condition[],
  conditions: Array<Condition>,
  def: Array<Array<BodyCell>>
): Array<Array<Cell>> => {
  return (x.requirements as Array<Requirement<"horizontal"> | BottomRequirement>).flatMap(
    (y, index) => {
      const newConditions = [...conditions, { path: x.path, type: y.type, criteria: y.criteria }];
      const newAccumulatedConditions = [
        ...accumulatedConditions,
        { path: x.path, type: y.type, criteria: y.criteria }
      ];
      const item: Cell = {
        type: "header" as const,
        direction: "vertical",
        name: x.name,
        condition: { path: x.path, type: y.type, criteria: y.criteria },
        accumulatedConditions: newAccumulatedConditions,
        height: calcHeight(y as Requirement<"horizontal">),
        width: 1,
        stateAccess: {
          get: (path) =>
            accumulatedStateAccess.get<Setting>(["requirements", index, ...path] as any),
          set: (path, value): any =>
            accumulatedStateAccess.set<Setting>(["requirements", index, ...path] as any, value)
        },
        groupStateAccess: {
          get: (path) => accumulatedStateAccess.get<Setting>(["requirements", ...path] as any),
          set: (path, value): any =>
            accumulatedStateAccess.set<Setting>(["requirements", ...path] as any, value)
        },
        indexInGroup: index
      };
      if (!isRequirement(y)) {
        return [
          [
            item,
            ...def[index].map((y) => ({
              ...y,
              conditions: [...newConditions, ...y.conditions]
            }))
          ]
        ];
      } else {
        const [initial, ...rest] = createVerticalHeadersAndContent(
          y.child,
          {
            get: (defaultPath: any) =>
              accumulatedStateAccess.get<Setting>([
                "requirements",
                index,
                "child",
                ...defaultPath
              ] as any),
            set: (defaultPath: any, value: any) =>
              accumulatedStateAccess.set(
                ["requirements", index, "child", ...defaultPath] as any,
                value
              )
          },
          newAccumulatedConditions,
          newConditions,
          def[index] as any
        );
        return [[item, ...initial], ...rest];
      }
    }
  );
};

export const getVerticalTree = (setting: HorizontalSetting): VerticalSetting => {
  return setting.requirements[0].hasOwnProperty("vertical")
    ? (setting.requirements[0] as HorizontalRequirement).vertical
    : getVerticalTree((setting.requirements[0] as Requirement<"horizontal">).child);
};

const changeVerticalTree = (
  setting: HorizontalSetting,
  newVertical: (x: VerticalSetting) => VerticalSetting
): HorizontalSetting => {
  return {
    ...setting,
    requirements: (setting.requirements as any[]).map(
      (x: HorizontalRequirement | Requirement<"horizontal">) =>
        isHorizontalRequirement(x)
          ? ({ ...x, vertical: newVertical(x.vertical) } as HorizontalRequirement)
          : ({
              ...x,
              child: changeVerticalTree((x as Requirement<"horizontal">).child, newVertical)
            } as unknown as Requirement<"horizontal">)
    ) as any
  };
};

export const convertToTableRows = (
  tableData: HorizontalSetting,
  setTableData: (x: HorizontalSetting) => void
) => {
  const vertical = getVerticalTree(tableData ?? []);
  const [initial, ...rest] = createHorizontalHeaderRows(
    vertical,
    {
      get: (path) => getByPath(vertical as any, path),
      set: (path, value): any => {
        const newVertical = (x: any) => {
          return setByPath(x as any, path, value(getByPath(x, path)));
        };
        setTableData(changeVerticalTree(tableData, newVertical));
      }
    },
    []
  );
  const horizontalHeaders = [
    [
      {
        type: "main",
        width: calcHeightNotSoOld(tableData.requirements[0] as Requirement<"horizontal">),
        height: calcHeightNotSoOld(vertical.requirements[0] as Requirement<"vertical">),
        stateAccess: {
          get: (path) => getByPath(tableData as any, path),
          set: (path, value): any => setTableData(setByPath(tableData as any, path, value))
        }
      } as MainCell,
      ...initial
    ],
    ...rest
  ];

  const verticalHeadersAndContent = createVerticalHeadersAndContent(
    tableData,
    {
      get: (path) => getByPath(tableData as any, path),
      set: (path, value): any => setTableData(setByPath(tableData as any, path, value))
    },
    [],
    [],
    // TODO: map horizontal paths here
    toConditionsHorizontal(tableData, [], {
      get: (path) => getByPath(tableData as any, path),
      set: (path, value): any => setTableData(setByPath(tableData as any, path, value))
    })
  );

  return [...horizontalHeaders, ...verticalHeadersAndContent];
};
