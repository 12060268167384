import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteOtherVendorRequest {
  _id: string;
}

interface DeleteOtherVendorResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteOtherVendorRequest,
  DeleteOtherVendorResponse
>("DeleteOtherVendor", {
  request: "delete_other_vendor"
});

export const deleteOtherVendorActions = socketMessageSlice.actions;
export const deleteOtherVendor = socketAction;
export default socketMessageSlice.reducer;
