import React, { useState } from "react";

import { Link } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { LogoWithText } from "components/AppBar/Logo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "absolute",
      width: "100%",
      height: "100%",
      background: "#19181f"
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(4)
    },

    submit: {
      backgroundColor: "#19181f",
      margin: theme.spacing(3, 0, 2)
    },
    errorText: {
      color: "#a4031f",
      marginTop: "2px",
      textAlign: "center"
    },
    logo: { textAlign: "center" }
  })
);

export default function SignIn(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <LogoWithText />
      </div>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Verify email
          </Typography>
          <p> Account created succesfully. A verificaton link has been sent to your email.</p>

          <Grid container>
            <Grid item xs={12}>
              <Link to="/sign-in">Sign in</Link>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
}
