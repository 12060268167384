import { Box, Button, Dialog, DialogActions, DialogContent, IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Warning from "@material-ui/icons/Warning";
import Big from "big.js";
import { HintTooltip } from "components/common/HintTooltip";
import formEditContext from "components/Content/FormEditContext";
import React, { useContext, useState } from "react";
import { generateForm, RenderSet, StateAccess } from "utils/models/formGenerator";
import CloseDialogButton from "../common/CloseDialogButton";
import { detailedFees } from "./model";
import { Deal } from "./types";
const calculateDetailedFeesSum = (deal: Deal) =>
  Object.values(deal?.data?.info?.taxesAndFees?.detailed ?? {})
    .reduce((acc, curr) => (typeof curr === "number" ? acc.add(new Big(curr)) : acc), new Big(0))
    .toNumber();
const areFeesEqual = (deal: Deal) => {
  const titleTransfer = deal?.data?.info?.taxesAndFees?.titleTransfer ?? 0;

  const detailedFeesSum = calculateDetailedFeesSum(deal);
  return titleTransfer === detailedFeesSum;
};
const setTitleTransform = (deal: Deal, stateAccess: StateAccess) => {
  const detailedFeesSum = calculateDetailedFeesSum(deal);
  stateAccess.set<Deal>(["data", "info", "taxesAndFees", "titleTransfer"], detailedFeesSum);
};

export default function FeesModalPreview({
  stateAccess,
  renderSet,
  type = "deal"
}: {
  type?: "deal" | "title_issue";
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const { enabled: editMode } = useContext(formEditContext);
  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deal: Deal = stateAccess.get<Deal>([] as any);
  const titleTransfer = deal?.data?.info?.taxesAndFees?.titleTransfer ?? 0;
  return (
    <>
      <div
        style={{
          position: "absolute",
          display: "flex",
          marginTop: "10px",
          marginLeft: "-3px"
        }}
      >
        <IconButton size="small" aria-label={`add-fees`} onClick={handleOpenDialog}>
          {areFeesEqual(deal) ? (
            editMode && type === "deal" ? (
              <HintTooltip title="Click here to add fees.">
                <Add />
              </HintTooltip>
            ) : (
              <HintTooltip title="Click here to view the fees modal preview.">
                <VisibilityIcon />
              </HintTooltip>
            )
          ) : (
            <HintTooltip title="The sum of the detailed fees is not equal to the Title/Transfer value">
              <Warning style={{ color: "#eed202" }} />
            </HintTooltip>
          )}
        </IconButton>
      </div>
      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        <CloseDialogButton closeFunction={handleClose} />
        <DialogContent>
          <Box>{generateForm(detailedFees, stateAccess, [], stateAccess, renderSet)}</Box>
        </DialogContent>
        <DialogActions>
          {editMode ? (
            <Button
              disabled={titleTransfer !== 0 && !areFeesEqual(deal)}
              onClick={handleClose}
              color="primary"
              variant="contained"
              id="add-detailed-fees"
            >
              Add
            </Button>
          ) : null}
          {titleTransfer === 0 &&
            !areFeesEqual(deal) &&
            (editMode ? (
              <Button
                onClick={() => setTitleTransform(deal, stateAccess)}
                color="primary"
                variant="contained"
                id="calculate-title-transfer"
              >
                Calculate
              </Button>
            ) : null)}
        </DialogActions>
      </Dialog>
    </>
  );
}
