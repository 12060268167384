import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { DealershipProgram } from "./types";
interface DeleteDealershipProgramRequest {
  _id: string;
}

interface DeleteDealershipProgramResponse {
  message: DealershipProgram;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteDealershipProgramRequest,
  DeleteDealershipProgramResponse
>("DeleteDealershipProgram", {
  request: "delete_dealership_program"
});

export const deleteDealershipProgramActions = socketMessageSlice.actions;
export const deleteDealershipProgram = socketAction;
export default socketMessageSlice.reducer;
