import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { DealershipProgram, AddDealershipProgramRequest, FormState } from "./types";

interface AddDealershipProgramResponse {
  message: DealershipProgram;
}
interface AddDealershipProgramsRequestSend {
  availableToRolesIds: string[];
  data: FormState;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddDealershipProgramsRequestSend,
  AddDealershipProgramResponse
>("AddDealershipProgram", {
  request: "new_dealership_program"
});

export const addDealershipProgramActions = socketMessageSlice.actions;

export const addDealershipProgram = (request: AddDealershipProgramRequest & Identifiable) => {
  return socketAction({
    requestId: request.requestId,
    availableToRolesIds: request.availableToRolesIds ?? [],
    data: request.data
  });
};
export default socketMessageSlice.reducer;
