import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditAdditionalRequiredDocumentData {
  _id: string;
  updatedAt: string;
  data: FormState;
}
interface EditAdditionalRequiredDocumentRequest {
  _id: string;
  data: FormState;
}

interface EditAdditionalRequiredDocumentResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditAdditionalRequiredDocumentRequest,
  EditAdditionalRequiredDocumentResponse
>("EditAdditionalRequiredDocument", {
  request: "update_additional_required_document"
});

export const editAdditionalRequiredDocumentActions = socketMessageSlice.actions;
export const editAdditionalRequiredDocument = (
  request: EditAdditionalRequiredDocumentData & Identifiable
) => {
  const { updatedAt, ...rest } = request;

  return socketAction({
    ...rest,
    data: {
      ...request.data,
      info: request.data.info
    }
  });
};
export default socketMessageSlice.reducer;
