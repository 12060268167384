import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { auth } from "../../firebase/firebase";
import { ThirdPartyDocumentsUsage, thirdPartyDocumentsUsageRequest } from "./types";

export const thirdPartyDocumentsUsage = (
  request: thirdPartyDocumentsUsageRequest
): (() => Promise<ThirdPartyDocumentsUsage[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/third_party_documents_usage_report/`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return response.data.result;
  };
};

export const useThirdPartyDocumentsUsage = (
  request: thirdPartyDocumentsUsageRequest,
  enabled = false
) => {
  const queryKey = ["third_party_documents_usage", request];

  const { data, isLoading, isSuccess, error, isFetching, isFetched } = useQuery({
    queryKey: queryKey,
    queryFn: thirdPartyDocumentsUsage(request),
    enabled: enabled && Object.keys(request).length === 2
  });
  return {
    data,
    isSuccess,
    isLoading,
    isFetching,
    isFetched,
    error
  };
};
export default useThirdPartyDocumentsUsage;
