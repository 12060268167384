import React from "react";
import LoopIcon from "@material-ui/icons/Loop";

export default ({ text = "Loading", style = {} }) => {
  return (
    <span style={style}>
      {text} <LoopIcon fontSize="large" className="spin" style={{ marginBottom: "-7px" }} />
    </span>
  );
};
