import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { auth } from "../../firebase/firebase";
import { HttpQuery } from "Chat/types/http";
import { MutationError } from "Chat/types";
import { PersonaReport } from "components/Deals/FraudValidation/types/internal/personaReports";

export const getPersonaReports = (query?: HttpQuery): (() => Promise<PersonaReport[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${process.env.REACT_APP_HTTP_URL}/api/v1/persona_report`, {
      headers: {
        authorization: `Bearer ${token}`,
        ...(query && Object.keys(query)?.length ? { query: JSON.stringify(query) } : {})
      }
    });

    return response.data.result?.docs ?? [];
  };
};

const useGetPersonaReports = (applicantId: string, query?: HttpQuery, enabled = true) => {
  const queryKey = [`persona-reports-${applicantId}`, query ?? []];

  const { data, isLoading, isFetching, error } = useQuery<
    PersonaReport[],
    MutationError,
    PersonaReport[]
  >({
    queryKey: queryKey,
    queryFn: getPersonaReports(query),
    enabled: applicantId ? enabled : false
  });

  return {
    data,
    isLoading,
    isFetching,
    error
  };
};

export default useGetPersonaReports;
