import { CustomRegexes } from "../../helpers";

const address = {
  type: "object",
  nullable: true,
  properties: {
    addressNumber: { type: "string", nullable: true },
    address: { type: "string", nullable: true },
    apt: { type: "string", nullable: true },
    city: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.LETTERS,
      errorMessage: { pattern: "should not contain numbers" },
    },
    state: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.LETTERS,
      errorMessage: { pattern: "should not contain numbers" },
    },
    zip: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.ZIP_CODE,
      errorMessage: {
        pattern:
          "should match either of the number formats: 'XXXXX' or 'XXXXX-XXXX'",
      },
    },
    time: {
      type: "object",
      nullable: true,
      properties: {
        years: {
          type: "string",
          nullable: true,
          pattern: CustomRegexes.NUMBERS,
          errorMessage: { pattern: "should not contain letters" },
        },
        months: {
          type: "string",
          nullable: true,
          pattern: CustomRegexes.NUMBERS,
          errorMessage: { pattern: "should not contain letters" },
        },
      },
    },
  },
};
const employer = {
  type: "object",
  nullable: true,
  properties: {
    name: { type: "string", nullable: true },
    grossMonthlySalary: { type: "number", nullable: true },
    workPhone: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.PHONE,
      errorMessage: {
        pattern: "should not contain letters and should not start with 0 or 1",
      },
    },
    occupationOrJobTitle: { type: "string", nullable: true },
    time: {
      type: "object",
      nullable: true,
      properties: {
        years: {
          type: "string",
          nullable: true,
          pattern: CustomRegexes.NUMBERS,
          errorMessage: { pattern: "should not contain letters" },
        },
        months: {
          type: "string",
          nullable: true,
          pattern: CustomRegexes.NUMBERS,
          errorMessage: { pattern: "should not contain letters" },
        },
      },
    },
  },
};
const applicant = {
  type: "object",
  nullable: true,
  properties: {
    firstName: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.LETTERS,
      errorMessage: { pattern: "should not contain numbers" },
    },
    middleName: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.LETTERS,
      errorMessage: { pattern: "should not contain numbers" },
    },
    lastName: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.LETTERS,
      errorMessage: { pattern: "should not contain numbers" },
    },
    idType: { type: "string", nullable: true },
    idNumber: { type: "string", nullable: true },
    idIssuedBy: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.LETTERS,
      errorMessage: { pattern: "should not contain numbers" },
    },
    idExpDate: {
      type: "string",
      format: "date-time",
      errorMessage: { format: "must be a valid date" },
      nullable: true,
    },
    dateOfBirth: {
      type: "string",
      nullable: true,
      format: "age-restriction",
      errorMessage: { format: "must be a date between 18 and 100" },
    },
    ssn: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.SSN,
      errorMessage: {
        pattern: `
        SSN cannot
        begin with 666 or 900-999,
        be 078-05-1120 or 219-09-9999,
        contain all zeroes in a group(i.e., 000-##-####, ###-00-####, or ###-##-0000)
        or contain all matching values(i.e., 000-00-0000, 111-11-1111, 222-22-2222, etc.)
      `,
      },
    },
    primaryPhone: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.PHONE,
      errorMessage: {
        pattern: "should not contain letters and should not start with 0 or 1",
      },
    },
    secondaryPhone: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.PHONE,
      errorMessage: {
        pattern: "should not contain letters and should not start with 0 or 1",
      },
    },
    email: {
      type: "string",
      nullable: true,
      pattern: CustomRegexes.EMAIL,
      errorMessage: {
        pattern: "should match the format <emailname>@<provider>.<org>",
      },
    },
    isUSCitizen: {
      type: ["string", "null"],
      enum: ["Yes", "No", null],
      nullable: true,
      errorMessage: { enum: "must be one of the following: 'Yes', 'No'" },
    },
    maritalStatus: {
      type: ["string", "null"],
      enum: ["Married", "Not married", null],
      nullable: true,
      errorMessage: {
        enum: "must be one of the following: 'Married', 'Not married'",
      },
    },
    grossMonthlyOtherIncome: { type: "number", nullable: true },
    otherIncomeSource: { type: "string", nullable: true },
    currentAddress: address,
    residentialStatus: {
      type: ["string", "null"],
      enum: ["own", "rent", "withRelatives", "withFriends", "other", null],
      errorMessage: {
        enum: "must be one of the following: 'own', 'rent', 'withRelatives', 'withFriends', 'other'",
      },
      nullable: true,
    },
    rentOrMortgageAmount: { type: "number", nullable: true },
    previousAddress: address,
    employmentStatus: {
      type: ["string", "null"],
      nullable: true,
      enum: ["Employee", "Business owner", "Retired", null],
      errorMessage: {
        enum: "must be one of the following: 'Employee', 'Business owner', 'Retired'",
      },
    },
    currentEmployer: employer,
    prevEmployer: employer,
  },
};
export default {
  creditType: {
    type: ["string", "null"],
    enum: ["individual", "joint", null],
    nullable: true,
    errorMessage: {
      enum: "must be one of the following: 'Individual', 'Joint'",
    },
  },
  applicant: applicant,
  coApplicant: applicant,
  collateral: {
    type: "object",
    nullable: true,
    properties: {
      vin: { type: "string", nullable: true },
      isVinDecoded: { type: "boolean", nullable: true },
      year: {
        type: "string",
        nullable: true,
        pattern: CustomRegexes.NUMBERS,
        errorMessage: { pattern: "should not contain letters" },
      },
      make: { type: "string", nullable: true },
      model: { type: "string", nullable: true },
      unitStatus: {
        type: ["string", "null"],
        enum: ["used", "new", null],
        nullable: true,
        errorMessage: { enum: "must be one of the following: 'new', 'used'" },
      },
      MSRP: {
        type: "number",
        nullable: true,
      },
      miles: {
        type: "string",
        nullable: true,
        pattern: CustomRegexes.DECIMAL_STRING,
        errorMessage: { pattern: "should not contain letters" },
      },
    },
  },
  price: {
    type: "object",
    nullable: true,
    properties: {
      price: {
        type: "number",
        nullable: true,
      },
      totalCash: {
        type: "number",
        nullable: true,
      },
      tradeAllowance: {
        type: "number",
        nullable: true,
      },
    },
  },
  payoff: {
    type: "object",
    nullable: true,
    properties: {
      payoff: {
        type: "number",
        nullable: true,
      },
      payoffBank: {
        type: "string",
        nullable: true,
      },
    },
  },
  taxes: {
    type: "object",
    nullable: true,
    properties: {
      salesTax: {
        type: "number",
        nullable: true,
      },
    },
  },
  fees: {
    type: "object",
    nullable: true,
    properties: {
      titleFees: {
        type: "number",
        nullable: true,
      },
      docFee: {
        type: "number",
        nullable: true,
      },
    },
  },
  tradeIn: {
    type: "object",
    nullable: true,
    properties: {
      vin: {
        type: "string",
        nullable: true,
      },
      year: {
        type: "string",
        nullable: true,
        pattern: CustomRegexes.NUMBERS,
        errorMessage: { pattern: "should not contain letters" },
      },
      make: { type: "string", nullable: true },
      model: { type: "string", nullable: true },
      miles: {
        type: "string",
        nullable: true,
        pattern: CustomRegexes.DECIMAL_STRING,
        errorMessage: { pattern: "should not contain letters" },
      },
    },
  },
};
