import { BsChatDotsFill } from "react-icons/bs";
import styles from "./Chat.module.css";
import ChatSearch from "./ChatSearch";
import WFDChat from "./WFDChat";
import React, { useContext, useEffect, useState } from "react";
import OpenedChat from "./OpenedChat";
import { Channel } from "../types/channels";
import useGetChannels from "../hooks/useChannel";
import { RootState } from "app/rootReducer";
import { useSelector } from "react-redux";
import IconButton from "./IconButton";
import { RiChatSmile3Fill } from "react-icons/ri";
import { Badge } from "@material-ui/core";
import { AiOutlineClose } from "react-icons/ai";
import { useStickyState } from "index";
import Switch, { NotificationSwitch } from "./Switch/Switch";
import { useChangeChatStatus, useGetChatMember } from "Chat/hooks/useChatMember";
import { queryClient } from "app/App";
import { ChatMember } from "Chat/types/chat_members";
import ApplicantsChat from "./ApplicantsChat";
import { ChatContext } from "components/Layout/LayoutWrapper";
import { useBrowserNotifications } from "Chat/hooks/useBrowserNotifications";
import { useSnackbar } from "notistack";

export const ChatMenu = ({
  onClick,
  containerClassName,
  channels,
  closeFunction
}: {
  containerClassName?: string;
  onClick: (index: string) => void;
  channels?: Channel[];
  closeFunction: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { requestBrowserNotificationPermissions } = useBrowserNotifications();
  const currentUser = useSelector((state: RootState) => state?.authSlice);
  const [chatNotificationsEnabled, setChatNotificationsEnabled] = useStickyState(
    false,
    "chatNotificationsEnabled"
  );
  const { data: chatMember } = useGetChatMember(currentUser.user?.email as string, "user");
  const { changeChatStatus } = useChangeChatStatus();
  const handleToggleNotifications = () => {
    if (!chatNotificationsEnabled) requestBrowserNotificationPermissions();
    setChatNotificationsEnabled(!chatNotificationsEnabled);
  };
  const handleToggleSwitch = () => {
    changeChatStatus(chatMember?._id as string);
    queryClient.setQueriesData<ChatMember>(["chat_member"], (oldChatMember) => {
      if (oldChatMember) {
        return {
          ...oldChatMember,
          data: {
            ...(oldChatMember?.data ?? {}),
            info: {
              ...(oldChatMember?.data?.info ?? {}),
              status: oldChatMember?.data?.info?.status === "active" ? "away" : "active"
            }
          }
        };
      }
    });
  };
  return (
    <div
      className={containerClassName ? `${styles[containerClassName]}` : `${styles.chatContainer}`}
    >
      <div className={styles.header}>
        <BsChatDotsFill size={25} color="#6B97F6" />
        <p className={styles.chatTitle}>chat</p>
        <Switch
          isActive={chatMember?.data.info.status === "active"}
          toggleFunction={handleToggleSwitch}
        />

        <NotificationSwitch
          isActive={chatNotificationsEnabled}
          toggleFunction={handleToggleNotifications}
        />
        <IconButton style={{ marginLeft: "auto" }} onClick={closeFunction}>
          <AiOutlineClose size={20} />
        </IconButton>
      </div>

      <div className={styles.searchContainer}>
        <ChatSearch />
      </div>
      <div className={styles.sectionsContainer}>
        <WFDChat
          onClick={onClick}
          channels={(channels ?? [])?.filter((channel) => channel.data.info.type !== "applicant")}
        />
        <div className={styles.divider}></div>
        <ApplicantsChat
          onClick={onClick}
          channels={(channels ?? [])?.filter((channel) => channel.data.info.type === "applicant")}
        />
      </div>
    </div>
  );
};

const ChatMessages = ({
  selectedChannel,
  closeFunction,
  closeChatFunction
}: {
  selectedChannel?: Channel;
  closeFunction: () => void;
  closeChatFunction: () => void;
}) => {
  return (
    <div className={`${styles.chatContainer} ${styles.openChatBackground}`}>
      <div className={styles.header}>
        <BsChatDotsFill size={25} color="#6B97F6" />
        <p className={styles.chatTitle}>chat</p>
        <IconButton style={{ marginLeft: "auto" }} onClick={closeFunction}>
          <AiOutlineClose size={20} />
        </IconButton>
      </div>
      <OpenedChat closeFunction={closeChatFunction} selectedChannel={selectedChannel} />
    </div>
  );
};
const Chat = () => {
  const chat = useContext(ChatContext);
  const [stickyState, setStickyState] = useStickyState<{
    channelId?: string;
  } | null>(null, "currentChat");

  const [openChannel, setOpenChannel] = useState(stickyState?.channelId ? true : false);
  const [selectedChannelId, setSelectedChannelId] = useState<string | undefined>(
    stickyState?.channelId ?? undefined
  );
  const user = useSelector((state: RootState) => state.authSlice);
  const { data: channels } = useGetChannels(
    user?.user?.databaseData?.data?.info?.email,
    undefined,
    user?.user?.databaseData?.data?.info?.email ? true : false
  );
  const visibleChannels = (channels ?? [])?.filter((channel) => !channel.data.info.hidden);
  const undreadMessages = visibleChannels?.reduce(
    (acc, curr) => acc + curr.data.info.unseenMessages,
    0
  );
  const closeFunction = () => {
    chat.closeChat();
    setStickyState(null);
  };
  const closeChatFunction = () => {
    setOpenChannel(false);
    setStickyState(null);

    chat.openChat();
  };
  return (
    <div style={{ position: "relative" }}>
      <IconButton onClick={() => (chat.chatOpen ? chat.closeChat() : chat.openChat())}>
        <Badge badgeContent={undreadMessages} color="secondary">
          <RiChatSmile3Fill size={24} color={"#fff"} />
        </Badge>
      </IconButton>
      {chat.chatOpen && (
        <div style={{ position: "absolute", right: -123 }}>
          {openChannel ? (
            <ChatMessages
              selectedChannel={visibleChannels.find(
                (visiblechannel) => visiblechannel._id === selectedChannelId
              )}
              closeFunction={closeFunction}
              closeChatFunction={closeChatFunction}
            />
          ) : (
            <ChatMenu
              closeFunction={closeFunction}
              onClick={(id) => {
                setOpenChannel(true);
                setStickyState({ channelId: id });
                setSelectedChannelId(id);
              }}
              channels={visibleChannels}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Chat;
