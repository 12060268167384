import { Schema } from "ajv";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";
import { dealNotes as notes } from "../common/notes_schema";
import { dealInfoProperties } from "./info_properties";

const unrequiredExternalId = { type: "string", nullable: true };

const insuranceProviderData: Schema = {
  type: "object",
  properties: { insuranceProviderNotes: notes }
};

const data: Schema = {
  type: "object",
  properties: {
    info: {
      type: "object",
      nullable: true,
      required: [],
      properties: dealInfoProperties
    },
    source: {
      nullable: true,
      type: "object",
      properties: {
        type: {
          type: ["string", "null"],
          nullable: true,
          enum: [
            "dealership_application",
            "mobile_application",
            "online_application",
            "web_finance_direct",
            "AI-Parser",
            null
          ],
          errorMessage: {
            enum: `must be one of the following: 
            'dealership_application', 'mobile_application', 'online_application', 'web_finance_direct', 'AI-Parser'`
          }
        },
        applicationId: {
          type: "string",
          nullable: true
        }
      }
    },
    additionalRequiredDocuments: {
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            nullable: true
          },
          value: {
            type: "boolean",
            nullable: true
          }
        }
      },
      nullable: true
    },
    netsuiteSalesOrderId: unrequiredExternalId,
    netsuiteEstimateId: unrequiredExternalId,
    netsuiteInvoiceId: unrequiredExternalId,
    netsuiteVendorBillIds: {
      type: "array",
      nullable: true,
      items: {
        type: "object",
        nullable: true,
        properties: {
          type: unrequiredExternalId,
          vendorBillId: unrequiredExternalId
        }
      }
    },
    GAPCompanyId: unrequiredExternalId,
    insuranceCompanyId: unrequiredExternalId,
    vehicleInsuranceCompanyId: unrequiredExternalId,
    lenderId: unrequiredExternalId,
    dealershipId: unrequiredExternalId,
    applicantId: unrequiredExternalId,
    coApplicantId: unrequiredExternalId,
    userId: unrequiredExternalId,
    payoffBankId: unrequiredExternalId,
    creditSmartsId: unrequiredExternalId,
    creditSmartsDate: {
      type: "string",
      format: "date-time",
      errorMessage: { format: "must be a valid date" },
      nullable: true
    },
    dealertrackDealNumbers: {
      type: "array",
      nullable: true,
      items: {
        type: "object",
        required: [],
        nullable: true,
        properties: {
          dealertrackNumber: { type: "string", nullable: true },
          dateSubmitted: {
            type: "string",
            format: "date-time",
            errorMessage: { format: "must be a valid date" },
            nullable: true
          }
        }
      }
    },
    order: { type: "integer", nullable: true },
    appOneApplicationNumber: { type: "string", nullable: true },
    appOneApplicationURL: { type: "string", nullable: true },
    notes: notes,
    stateTaxId: unrequiredExternalId,
    appOneData: {
      type: "object",
      nullable: true,
      properties: {
        dealId: { type: "string", nullable: true },
        refNumber: { type: "string", nullable: true },
        data: { type: "object", additionalProperties: true }
      }
    }
  }
};

export default {
  new_deal: {
    $id: "newDeal",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      }
    }
  },
  update_deal: {
    $id: "updateDeal",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      },
      approved: {
        type: "boolean",
        nullable: true
      },
      approvedAt: {
        type: "string",
        nullable: true
      },
      approvedByUserId: {
        type: "string",
        nullable: true
      },
      type: { type: "string", nullable: true }
    }
  },
  delete_deal: {
    $id: "deleteDeal",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" }
    }
  },
  recover_deal: {
    $id: "recoverDeal",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" }
    }
  },
  get_deal: {
    $id: "getDeal",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" }
    }
  },
  update_insurance_provider_deal: {
    $id: "updateDealForInsuranceProvider",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: insuranceProviderData
    }
  },
  get_insurance_provider_deal: {
    $id: "getDealForInsuranceProvider",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" }
    }
  },
  all_insurance_provider_deals: {
    $id: "getDealsForInsuranceProvider",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties }
    ]
  },
  all_deals: {
    $id: "allDeals",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties }
    ]
  },
  deals_order: {
    $id: "dealsOrder",
    type: "object",
    properties: {
      data: {
        type: "array",
        items: { type: "string" }
      }
    }
  },
  all_representative_deals: {
    $id: "allRepresentativeDeals",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties }
    ]
  }
};
