import {
  createSocketMessageSlice,
  Identifiable
} from "../../Middlewares/socketMessageSliceCreator";
import { AddDealershipBillsRequest, AddDealershipBillsResponse } from "./types";

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddDealershipBillsRequest,
  AddDealershipBillsResponse
>("AddDealershipBills", {
  request: "new_dealership_bills_report"
});

export const addDealershipBillsActions = socketMessageSlice.actions;
export const addDealershipBills = (request: AddDealershipBillsRequest & Identifiable) => {
  const { dealershipProgram, ...rest } = request.data;
  return socketAction({
    requestId: request.requestId,
    data: {
      ...rest,
      dealershipProgramId: dealershipProgram?._id ?? null
    }
  });
};
export default socketMessageSlice.reducer;
