import { createListSlice } from "../Middlewares/listSliceCreator";
import { Deal } from "./types";

const { ListSlice, getList } = createListSlice<Deal>({
  name: "DealList",
  request_topic: "all_deals",
  reducers: {}
});
export default ListSlice.reducer;
export const getDealList = getList;
export const listDealActions = ListSlice.actions;
export const clearDealList = ListSlice.actions.clearList;
export const removeDealList = ListSlice.actions.removeList;
