import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { Theme, withStyles } from "@material-ui/core/styles";
import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";
import DealershipFilter from "components/Filters/DealershipFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import { addColumnGroup } from "components/GroupedTable/ColumnGroups/addColumnGroupSlice";
import {
  getColumnGroupList,
  removeColumnGroupList
} from "components/GroupedTable/ColumnGroups/listColumnGroupSlice";
import { ColumnGroup, TableGroupByItem } from "components/GroupedTable/ColumnGroups/types";
import { TabContext } from "components/Layout/LayoutWrapper";
import { hideLoader, showLoader } from "components/Loader/loaderSlice";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModel } from "utils/entitySlice";
import formatDate from "utils/formatDate";
import { assertNever, capitalize, formatNumberAsCurrency, totalsDefault } from "utils/functions";
import { lockEntityFunction } from "utils/models/ShowForm";
import { fillDefaultsByPath, generateDefault } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import AccessControl from "../Access/AccessControl";
import ApplicantFilter from "../Filters/ApplicantFilter";
import DateFilter from "../Filters/DateFilter";
import LenderFilter from "../Filters/LenderFilter";
import NetsuiteFilter from "../Filters/NetsuiteFilter";
import PriceFilter from "../Filters/PriceFilter";
import TextFilter from "../Filters/TextFilter";
import UserFilter from "../Filters/UserFilter";
import GroupedTable, { Column } from "../GroupedTable";
import Table, { CellValue } from "../Table";
import { calculate } from "./Calculations";
import {
  AddDealFromDocumentRequest,
  addDealFromParsedDocument
} from "./addDealFromParsedDocumentSlice";
import { addDeal } from "./addDealSlice";
import { deleteDeal } from "./deleteDealSlice";
import { clearDealList, getDealList } from "./listDealSlice";
import { recoverDeal } from "./recoverDealSlice";
import { collateralTypes, Deal, DealData, DealSourceTypes, DealStatus } from "./types";
import { getDealListByIds } from "./listDealSliceByIds";
export const dealSourceToLabel = (dealSource: DealSourceTypes | undefined) => {
  switch (dealSource) {
    case DealSourceTypes.Dealership_Application:
      return "Dealership application";
    case DealSourceTypes.Mobile_Application:
      return "Dealership (mobile) application";
    case DealSourceTypes.Online_Application:
      return "Online (Widget) application";
    case DealSourceTypes.Web_Finance_Direct:
      return "WFD";
    default:
      return "N/A";
  }
};
const groupDealColumns: Column<any>[] = [
  {
    getData: (entry): CellValue =>
      // @ts-ignore
      entry?.applicantNames,
    label: "Customer",
    tableSettingsParent: "applicant",
    name: "customer",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.customer?.show;
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count", "list-count-unique", "list"],
      path: "applicantNames",
      filters: [
        {
          preview: TextFilter,
          applyOnFunctions: ["first", "last"],
          partialSearch: true,
          caseInsensitive: true
        },
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count", "list-count-unique"]
        }
      ]
    },
    filters: [
      {
        path: [
          ["data", "applicant", "data", "info", "firstName"],
          ["data", "applicant", "data", "info", "lastName"],
          ["data", "coApplicant", "data", "info", "lastName"],
          ["data", "coApplicant", "data", "info", "lastName"]
        ],
        preview: ApplicantFilter,
        caseInsensitive: true,
        partialSearch: true,
        name: "customer",
        label: "Customer"
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.applicant?.data?.info?.currentCity;
    },
    label: "Applicant city",
    tableSettingsParent: "applicant",
    grouping: {
      path: "data.applicant.data.info.currentCity",
      enabled: true,
      options: {
        path: ["_id", "applicantCurrentCity"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.applicantCurrentCity,
      key: "applicantCurrentCity"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count", "list-count-unique", "list"],
      path: "data.applicant.data.info.currentCity",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count", "list-count-unique"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "applicant", "data", "info", "currentCity"]
    },
    name: "applicantCurrentCity",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicantCurrentCity?.show;
    },
    default: "N/A",
    filters: [
      {
        path: ["data", "applicant", "data", "info", "currentCity"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.applicant?.data?.info?.currentState;
    },
    label: "Applicant state",
    tableSettingsParent: "applicant",
    grouping: {
      path: "data.applicant.data.info.currentState",
      enabled: true,
      options: {
        path: ["_id", "applicantCurrentState"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.applicantCurrentState,
      key: "applicantCurrentState"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count", "list-count-unique", "list"],
      path: "data.applicant.data.info.currentState",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count", "list-count-unique"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "applicant", "data", "info", "currentState"]
    },
    name: "applicantCurrentState",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicantCurrentState?.show;
    },
    default: "N/A",
    filters: [
      {
        path: ["data", "applicant", "data", "info", "currentState"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.applicant?.data?.info?.currentZipCode;
    },
    label: "Applicant zip",
    tableSettingsParent: "applicant",
    grouping: {
      path: "data.applicant.data.info.currentZipCode",
      enabled: true,
      options: {
        path: ["_id", "applicantCurrentZipCode"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.applicantCurrentZipCode,
      key: "applicantCurrentZipCode"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count", "list-count-unique", "list"],
      path: "data.applicant.data.info.currentZipCode",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "applicant", "data", "info", "currentZipCode"]
    },
    name: "applicantCurrentZipCode",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicantCurrentZipCode?.show;
    },
    default: "N/A",
    filters: [
      {
        path: ["data", "applicant", "data", "info", "currentZipCode"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },

  {
    getData: (entry): CellValue => {
      return entry.data?.applicant?.data?.info?.currentCounty;
    },
    label: "Applicant county",
    tableSettingsParent: "applicant",
    grouping: {
      path: "data.applicant.data.info.currentCounty",
      enabled: true,
      options: {
        path: ["_id", "applicantCurrentCounty"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.applicantCurrentCounty,
      key: "applicantCurrentCounty"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.applicant.data.info.currentCounty",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "applicant", "data", "info", "currentCounty"]
    },
    name: "applicantCurrentCounty",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicantCurrentCounty?.show;
    },
    default: "N/A",
    filters: [
      {
        path: ["data", "applicant", "data", "info", "currentCounty"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => dealSourceToLabel(entry.data?.source?.type),
    label: "Application source",
    tableSettingsParent: "deal",
    options: {
      sort: true,
      path: ["data", "source", "type"]
    },
    name: "source",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.source?.show;
    },
    grouping: {
      path: "data.source.type",
      enabled: true,
      options: {
        path: ["_id", "source"],
        sort: true
      },
      getData: (entry): CellValue => dealSourceToLabel(entry.data?.source),
      key: "source"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.source.type",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "source", "type"],
        preview: MultiSelectFilter,
        valuesForSelect: Object.values(DealSourceTypes),
        optionLabelForSelect: (source) => dealSourceToLabel(source)
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.coApplicant?.data?.info?.currentCity;
    },
    label: "Co-Applicant city",
    tableSettingsParent: "co-applicant",
    grouping: {
      path: "data.coApplicant.data.info.currentCity",
      enabled: true,
      options: {
        path: ["_id", "coApplicantCurrentCity"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.coApplicantCurrentCity,
      key: "coApplicantCurrentCity"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.coApplicant.data.info.currentCity",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "coApplicant", "data", "info", "currentCity"]
    },
    name: "coApplicantCurrentCity",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.coApplicantCurrentCity?.show;
    },
    default: "N/A",
    filters: [
      {
        path: ["data", "coApplicant", "data", "info", "currentCity"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.coApplicant?.data?.info?.currentState;
    },
    label: "Co-Applicant state",
    tableSettingsParent: "co-applicant",
    grouping: {
      path: "data.coApplicant.data.info.currentState",
      enabled: true,
      options: {
        path: ["_id", "coApplicantCurrentState"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.coApplicantCurrentState,
      key: "coApplicantCurrentState"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.coApplicant.data.info.currentState",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "coApplicant", "data", "info", "currentState"]
    },
    name: "coApplicantCurrentState",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.coApplicantCurrentState?.show;
    },
    default: "N/A",
    filters: [
      {
        path: ["data", "coApplicant", "data", "info", "currentState"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.coApplicant?.data?.info?.currentZipCode;
    },
    label: "Co-Applicant zip",
    tableSettingsParent: "co-applicant",
    grouping: {
      path: "data.coApplicant.data.info.currentZipCode",
      enabled: true,
      options: {
        path: ["_id", "coApplicantCurrentZipCode"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.coApplicantCurrentZipCode,
      key: "coApplicantCurrentZipCode"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.coApplicant.data.info.currentZipCode",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "coApplicant", "data", "info", "currentZipCode"]
    },
    name: "coApplicantCurrentZipCode",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.coApplicantCurrentZipCode?.show;
    },
    default: "N/A",
    filters: [
      {
        path: ["data", "coApplicant", "data", "info", "currentZipCode"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.coApplicant?.data?.info?.currentCounty;
    },
    label: "Co-Applicant county",
    tableSettingsParent: "co-applicant",
    grouping: {
      path: "data.coApplicant.data.info.currentCounty",
      enabled: true,
      options: {
        path: ["_id", "coApplicantCurrentCounty"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.coApplicantCurrentCounty,
      key: "coApplicantCurrentCounty"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.coApplicant.data.info.currentCounty",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    default: "N/A",
    options: {
      sort: true,
      path: ["data", "coApplicant", "data", "info", "currentCounty"]
    },
    name: "coApplicantCurrentCounty",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.coApplicantCurrentCounty?.show;
    },
    filters: [
      {
        path: ["data", "coApplicant", "data", "info", "currentCounty"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.dealership?.data?.info?.city;
    },
    label: "Dealership city",
    tableSettingsParent: "dealership",
    grouping: {
      path: "data.dealership.data.info.city",
      enabled: true,
      options: {
        path: ["_id", "dealershipCity"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.dealershipCity,
      key: "dealershipCity"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.dealership.data.info.city",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    default: "N/A",
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "city"]
    },
    name: "dealershipCity",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipCity?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "info", "city"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.dealership?.data?.info?.state;
    },
    label: "Dealership state",
    tableSettingsParent: "dealership",
    grouping: {
      path: "data.dealership.data.info.state",
      enabled: true,
      options: {
        path: ["_id", "dealershipState"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.dealershipState,
      key: "dealershipState"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.dealership.data.info.state",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    default: "N/A",
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "state"]
    },
    name: "dealershipState",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipState?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "info", "state"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.dealership?.data?.info?.zipCode;
    },
    label: "Dealership zip",
    tableSettingsParent: "dealership",
    grouping: {
      path: "data.dealership.data.info.zipCode",
      enabled: true,
      options: {
        path: ["_id", "dealershipZipCode"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.dealershipZipCode,
      key: "dealershipZipCode"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.dealership.data.info.zipCode",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    default: "N/A",
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "zipCode"]
    },
    name: "dealershipZipCode",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipZipCode?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "info", "zipCode"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.dealership?.data?.info?.county;
    },
    label: "Dealership county",
    tableSettingsParent: "dealership",
    grouping: {
      path: "data.dealership.data.info.county",
      enabled: true,
      options: {
        path: ["_id", "dealershipCounty"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.dealershipCounty,
      key: "dealershipCounty"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.dealership.data.info.county",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    default: "N/A",
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "county"]
    },
    name: "dealershipCounty",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipCounty?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "info", "county"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.info?.refNumber;
    },
    label: "Ref #",
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.info.refNumber",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    tableSettingsParent: "deal",
    options: {
      sort: true,
      path: ["data", "info", "refNumber"]
    },
    name: "refNumber",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.refNumber?.show;
    },
    filters: [
      {
        path: ["data", "info", "refNumber"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry?.data?.dealership?.data?.info?.displayName;
    },
    label: "Dealership",
    tableSettingsParent: "dealership",
    truncate: 15,
    grouping: {
      getData: (entry): CellValue => {
        return entry?.data?.dealership;
      },
      options: {
        path: ["_id", "dealership"],
        sort: true
      },
      enabled: true,
      key: "dealership",
      path: "data.dealership.data.info.displayName"
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.dealership.data.info.displayName",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    name: "dealership",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealership?.show;
    },
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "displayName"]
    },
    filters: [
      {
        path: ["data", "dealershipId"],
        preview: DealershipFilter,
        label: "Dealership"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      `${entry.data?.info?.extractedCreditScores?.applicant?.score} ${
        entry.data?.info?.extractedCreditScores?.coApplicant?.score
          ? "/ " + entry.data?.info?.extractedCreditScores?.coApplicant?.score
          : ""
      }`,
    label: "Credit Scores",
    name: "creditScores",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.creditScores?.show;
    },
    filters: []
  },

  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
    label: "Make",
    name: "make",
    tableSettingsParent: "vehicle",
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.info.vehicle.make",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    grouping: {
      path: "data.info.vehicle.make",
      enabled: true,
      options: {
        path: ["_id", "make"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.make,
      key: "make"
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "make"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ],
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.make?.show;
    }
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
    label: "Model",
    name: "model",
    tableSettingsParent: "vehicle",
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.info.vehicle.model",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    grouping: {
      getData: (entry): CellValue => entry.data?.model,
      path: "data.info.vehicle.model",
      enabled: true,
      key: "model",
      options: {
        path: ["_id", "model"],
        sort: true
      }
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.model?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "model"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.year,
    label: "Year",
    name: "year",
    tableSettingsParent: "vehicle",
    columnAggregation: {
      path: "data.info.vehicle.year",
      possibleFunctions: ["first", "last", "list", "list-count-unique", "list-count", "avg"],
      filters: [
        { preview: PriceFilter, applyOnFunctions: ["avg", "list-count-unique", "list-count"] }
      ]
    },
    grouping: {
      getData: (entry): CellValue => entry.data?.year,
      path: "data.info.vehicle.year",
      enabled: true,
      key: "year",
      options: {
        path: ["_id", "year"],
        sort: true
      }
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.year?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "year"],
        preview: PriceFilter,
        valuesForSelect: [1900, 2100],
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.VIN,
    label: "VIN",
    name: "vin",
    tableSettingsParent: "vehicle",
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.info.vehicle.VIN",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.vin?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "VIN"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.vehicle?.odometer) ?? "N/A",
    label: "Mileage",
    name: "mileage",
    tableSettingsParent: "vehicle",
    columnAggregation: {
      path: "data.info.vehicle.odometer",
      possibleFunctions: ["avg", "first", "last", "list-count-unique", "list-count", "list"],
      filters: [
        { preview: PriceFilter, applyOnFunctions: ["avg", "list-count-unique", "list-count"] }
      ]
    },
    type: "number",
    default: "N/A",
    total: totalsDefault(),
    grouping: {
      getData: (entry): CellValue => entry.data?.odometer,
      path: "data.info.vehicle.odometer",
      enabled: true,
      key: "odometer",
      options: {
        path: ["_id", "odometer"],
        sort: true
      }
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.mileage?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "odometer"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
    label: "Lender",
    name: "lender",
    tableSettingsParent: "lender",
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.lender.data.info.name",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    grouping: {
      getData: (entry): CellValue => entry.data?.lender,
      path: "data.lender.data.info.name",
      enabled: true,
      key: "lender",
      options: {
        path: ["_id", "lender"],
        sort: true
      }
    },
    filters: [
      {
        path: ["data", "lenderId"],
        preview: LenderFilter
      }
    ],
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.lender?.show;
    }
  },
  {
    getData: (entry): CellValue =>
      `${entry.data?.user?.data?.info?.firstName ?? ""} ${
        entry.data?.user?.data?.info?.lastName ?? ""
      }`,
    label: "F&I mgr",
    tableSettingsParent: "manager",
    name: "f&iManager",
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "userNames",
      filters: [
        {
          preview: TextFilter,
          applyOnFunctions: ["first", "last"],
          caseInsensitive: true,
          partialSearch: true
        },
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.["f&iManager"]?.show;
    },
    filters: [
      {
        path: ["data", "userId"],
        preview: UserFilter,
        type: "f&i manager"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry?.data?.repNames,
    label: "Rep",
    name: "rep",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.rep?.show;
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "repNames",
      filters: [
        {
          preview: TextFilter,
          applyOnFunctions: ["first", "last"],
          caseInsensitive: true,
          partialSearch: true
        },
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "dealership", "data", "representativeId"],
        preview: UserFilter,
        type: "representative"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.contractDate
        ? new Date(entry.data?.info?.dealDates?.contractDate)
        : "",
    label: "Contract",
    tableSettingsParent: "dates",
    options: {
      sort: true,
      path: ["data", "info", "dealDates", "contractDate"]
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.dealDates.contractDate",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    type: "date",
    name: "contractDate",
    dateFilterPath: ["data", "info", "dealDates", "contractDate"],
    grouping: {
      options: {
        path: ["_id", "contractDate"],
        sort: true
      },
      getData: (entry): CellValue => entry?.data?.contractDate,
      path: "data.info.dealDates.contractDate",
      enabled: true,
      key: "contractDate"
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.contractDate?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "contractDate"],
        preview: DateFilter,
        label: "Contract Date"
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry?.data?.info?.status;
    },
    label: "Status",
    tableSettingsParent: "status",
    options: {
      customBodyRender: (x: CellValue, deal): JSX.Element | string => {
        const dealStatus = x as DealStatus;
        return statusToChip(dealStatus, false);
      },
      sort: true,
      path: ["data", "info", "status"]
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.status",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "info", "status"],
        preview: MultiSelectFilter,
        valuesForSelect: Object.values(DealStatus),
        optionLabelForSelect: (status) => statusToLabel(status)
      }
    ],
    grouping: {
      getData: (entry): CellValue => {
        return entry.data?.status;
      },
      options: {
        path: ["_id", "status"],
        sort: true
      },
      path: "data.info.status",
      enabled: true,
      key: "status"
    },
    name: "status",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.status?.show;
    }
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.submittedForFunding
        ? new Date(entry.data?.info?.dealDates?.submittedForFunding)
        : "",
    label: "Submitted at",
    tableSettingsParent: "dates",
    options: {
      sort: true,
      path: ["data", "info", "dealDates", "submittedForFunding"]
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.dealDates.submittedForFunding",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    type: "date",
    name: "submittedForFunding",
    dateFilterPath: ["data", "info", "dealDates", "submittedForFunding"],
    grouping: {
      options: {
        path: ["_id", "submittedForFunding"],
        sort: true
      },
      getData: (entry): CellValue => entry?.data?.submittedForFunding,
      path: "data.info.dealDates.submittedForFunding",
      enabled: true,
      key: "submittedForFunding"
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.submittedForFunding?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "submittedForFunding"],
        preview: DateFilter,
        label: "Submitted for funding"
      }
    ]
  },
  {
    getData: (entry): CellValue => (entry as unknown) as string,
    label: "Netsuite",
    options: {
      customBodyRender: (x: CellValue, deal): JSX.Element | string => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {deal?.data?.netsuiteEstimateId && (
              <Chip size="small" label="E" style={{ fontFamily: "monospace" }} />
            )}
            {deal?.data?.netsuiteSalesOrderId && (
              <Chip
                size="small"
                label="S"
                style={{ backgroundColor: "#eba93f", fontFamily: "monospace" }}
              />
            )}
            {deal?.data?.netsuiteInvoiceId && (
              <Chip
                size="small"
                label="I"
                style={{ backgroundColor: "#4caf50", fontFamily: "monospace" }}
              />
            )}
            {(deal?.data?.netsuiteVendorBillIds ?? []).length > 0 && (
              <Chip
                size="small"
                label="B"
                style={{ backgroundColor: "#4caf50", fontFamily: "monospace" }}
              />
            )}
          </div>
        );
      },
      sort: true,
      path: ["data"]
    },
    name: "netsuite",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.netsuite?.show;
    },
    filters: [
      {
        path: ["data", "netsuiteEstimateId"],
        name: "netsuiteEstimateId",
        label: "Netsuite Estimate Id",
        preview: NetsuiteFilter
      },
      {
        path: ["data", "netsuiteSalesOrderId"],
        name: "netsuiteSalesOrderId",
        label: "Netsuite Sales Order Id",
        preview: NetsuiteFilter
      },
      {
        path: ["data", "netsuiteInvoiceId"],
        name: "netsuiteInvoiceId",
        label: "Netsuite Invoice Id",
        preview: NetsuiteFilter
      },
      {
        path: ["data", "netsuiteVendorBillIds"],
        name: "netsuiteVendorBillIds",
        label: "Netsuite Vendor Bill Ids",
        preview: NetsuiteFilter
      },
      {
        path: ["data", "info", "payment", "fundedAmount"],
        name: "fundedAmount",
        label: "Funded Amount",
        preview: NetsuiteFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return formatNumberAsCurrency(entry.data?.info?.payment?.fundedAmount ?? 0, "$");
    },
    label: "Funded",
    tableSettingsParent: "financial",
    name: "fundedAmount",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.fundedAmount?.show;
    },
    options: {
      sort: true,
      path: ["data", "info", "payment", "fundedAmount"]
    },
    type: "number",
    total: totalsDefault("$"),
    sign: (funcName) => "$",
    columnAggregation: {
      possibleFunctions: ["list", "list-count", "list-count-unique", "first", "last", "sum", "avg"],
      path: "data.info.payment.fundedAmount",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["sum", "list-count-unique", "list-count", "avg"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "info", "payment", "fundedAmount"],
        preview: PriceFilter,
        name: "fundedAmount",
        label: "Funded Amount"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.payment?.numberOfPayments ?? "N/A",
    label: "Term",
    name: "term",
    tableSettingsParent: "financial",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.term?.show;
    },
    type: "number",
    columnAggregation: {
      possibleFunctions: ["list", "list-count", "list-count-unique", "first", "last", "sum", "avg"],
      path: "data.info.payment.numberOfPayments",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["sum", "list-count-unique", "list-count", "avg"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "info", "payment", "numberOfPayments"],
        preview: PriceFilter,
        name: "term",
        label: "Term"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.payment?.monthlyPayment ?? 0, "$"),
    label: "Monthly Payment",
    name: "monthlyPayment",
    tableSettingsParent: "financial",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.monthlyPayment?.show;
    },
    default: "$0.00",
    type: "number",
    sign: (funcName) => "$",
    total: totalsDefault("$"),
    columnAggregation: {
      possibleFunctions: ["list", "list-count", "list-count-unique", "first", "last", "sum", "avg"],
      path: "data.info.payment.monthlyPayment",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["sum", "list-count-unique", "list-count", "avg"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "info", "payment", "monthlyPayment"],
        preview: PriceFilter,
        name: "monthlyPayment",
        label: "Monthly payment"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.fundedAt ? new Date(entry.data?.info?.dealDates?.fundedAt) : "",
    label: "Funded At",
    tableSettingsParent: "dates",
    options: {
      sort: true,
      path: ["data", "info", "dealDates", "fundedAt"]
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.dealDates.fundedAt",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    type: "date",
    name: "fundedAt",
    dateFilterPath: ["data", "info", "dealDates", "fundedAt"],
    grouping: {
      options: {
        path: ["_id", "fundedAt"],
        sort: true
      },
      getData: (entry): CellValue => entry?.data?.fundedAt,
      path: "data.info.dealDates.fundedAt",
      enabled: true,
      key: "fundedAt"
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.fundedAt?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "fundedAt"],
        preview: DateFilter,
        label: "Funded At"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.creditCheckDate ? new Date(entry.data?.info?.creditCheckDate) : "",
    label: "Credit check date",
    tableSettingsParent: "dates",
    options: {
      sort: true,
      path: ["data", "info", "creditCheckDate"]
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.creditCheckDate",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    type: "date",
    name: "creditCheckDate",
    dateFilterPath: ["data", "info", "creditCheckDate"],
    grouping: {
      options: {
        path: ["_id", "creditCheckDate"],
        sort: true
      },
      getData: (entry): CellValue => entry?.data?.creditCheckDate,
      path: "data.info.creditCheckDate",
      enabled: true,
      key: "creditCheckDate"
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.creditCheckDate?.show;
    },
    filters: [
      {
        path: ["data", "info", "creditCheckDate"],
        preview: DateFilter,
        label: "Credit Check Date"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.collateralReceivedDate
        ? new Date(entry.data?.info?.collateralReceivedDate)
        : "",
    label: "Collateral received date",
    tableSettingsParent: "dates",
    options: {
      sort: true,
      path: ["data", "info", "collateralReceivedDate"]
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.collateralReceivedDate",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    type: "date",
    name: "collateralReceivedDate",
    dateFilterPath: ["data", "info", "collateralReceivedDate"],
    grouping: {
      options: {
        path: ["_id", "collateralReceivedDate"],
        sort: true
      },
      getData: (entry): CellValue => entry?.data?.collateralReceivedDate,
      path: "data.info.collateralReceivedDate",
      enabled: true,
      key: "collateralReceivedDate"
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.collateralReceivedDate?.show;
    },
    filters: [
      {
        path: ["data", "info", "collateralReceivedDate"],
        preview: DateFilter,
        label: "Collateral Received Date"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.contractDate
        ? new Date(entry.data?.info?.dealDates?.contractDate)
        : "",
    label: "Contract date",
    tableSettingsParent: "dates",
    options: {
      sort: true,
      path: ["data", "info", "dealDates", "contractDate"]
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.dealDates.contractDate",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    type: "date",
    name: "contractDate",
    dateFilterPath: ["data", "info", "dealDates", "contractDate"],
    grouping: {
      options: {
        path: ["_id", "contractDate"],
        sort: true
      },
      getData: (entry): CellValue => entry?.data?.contractDate,
      path: "data.info.dealDates.contractDate",
      enabled: true,
      key: "contractDate"
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.contractDate?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "contractDate"],
        preview: DateFilter,
        label: "Contract Date"
      }
    ]
  },
  {
    getData: (deal): CellValue => {
      const insurances = deal.data?.info?.aftermarketOptions?.insurances ?? [];
      const hasGap = insurances?.some(
        (el: any) => el?.chosenRate?.insuranceType === "gap" && el?.chosenRate?.soldBy === "WFD"
      );
      const hasWarranty = insurances?.some(
        (el: any) =>
          el?.chosenRate?.insuranceType === "service warranty" && el?.chosenRate?.soldBy === "WFD"
      );
      return [hasGap ? "Gap" : "", hasWarranty ? "Warranty" : ""];
    },
    excelColumns: ["Gap", "Warranty"],
    label: "Prod.",
    options: {
      customBodyRender: (x: CellValue, deal): JSX.Element | string => {
        const insurances = deal.data?.info?.aftermarketOptions?.insurances ?? [];
        const hasGap = insurances.some(
          (el: any) => el?.chosenRate?.insuranceType === "gap" && el?.chosenRate?.soldBy === "WFD"
        );
        const hasWarranty = insurances.some(
          (el: any) =>
            el?.chosenRate?.insuranceType === "service warranty" && el?.chosenRate?.soldBy === "WFD"
        );
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {hasGap && <Chip size="small" label="G" style={{ fontFamily: "monospace" }} />}
            {hasWarranty && (
              <Chip
                size="small"
                label="W"
                style={{ backgroundColor: "#eba93f", fontFamily: "monospace" }}
              />
            )}
          </div>
        );
      },
      sort: true,
      path: ["data"]
    },
    name: "products",
    show: (userPermissions, tableSettings) => {
      return true;
    }
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.payment?.dealTotal ?? 0, "$"),
    label: "Amount Financed",
    name: "amountFinanced",
    tableSettingsParent: "financial",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.amountFinanced?.show;
    },
    default: "$0.00",
    type: "number",
    total: totalsDefault("$"),
    columnAggregation: {
      possibleFunctions: ["list", "list-count", "list-count-unique", "first", "last", "sum", "avg"],
      path: "data.info.payment.dealTotal",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["sum", "list-count-unique", "list-count", "avg"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "info", "payment", "dealTotal"],
        preview: PriceFilter,
        name: "amountFinanced",
        label: "Amount Financed"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.payment?.interestRate ?? 0),
    label: "Interest Rate",
    name: "interestRate",
    tableSettingsParent: "financial",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.interestRate?.show;
    },
    default: "0.00",
    type: "number",
    total: totalsDefault(),
    columnAggregation: {
      possibleFunctions: ["list", "list-count", "list-count-unique", "first", "last", "sum", "avg"],
      path: "data.info.payment.interestRate"
    },
    grouping: {
      getData: (entry) => {
        return entry?.data?.interestRate;
      },
      enabled: true,
      key: "interestRate",
      options: {
        path: ["_id", "interestRate"],
        sort: true
      },
      path: "data.info.payment.interestRate"
    },
    filters: [
      {
        path: ["data", "info", "payment", "interestRate"],
        preview: PriceFilter,
        name: "interestRate",
        label: "Interest Rate"
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      switch (entry?.data?.info?.type) {
        case "Recreational Vehicles":
          return "RV";
        case "Automotive":
          return "Auto";
        case "Marine":
          return "Marine";
        case "Power Sport":
          return "Power";
        default:
          return "";
      }
    },
    grouping: {
      getData: (entry) => {
        return entry?.data?.collateralType;
      },
      enabled: true,
      key: "collateralType",
      options: {
        path: ["_id", "collateralType"],
        sort: true
      },
      path: "data.info.type"
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.type",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "info", "type"],
        preview: MultiSelectFilter,
        valuesForSelect: Array.from(collateralTypes),
        optionLabelForSelect: (x) => x
      }
    ],
    label: "Collateral",
    truncate: 12,
    tableSettingsParent: "vehicle",
    name: "collateralType",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.collateralType?.show;
    },
    options: {
      sort: true,
      path: ["data", "info", "type"]
    }
  },

  {
    getData: (entry, hiddenValues): CellValue => {
      return entry?.createdAt;
    },
    tableSettingsParent: "dates",
    label: "Created at",
    type: "date",
    truncate: 15,
    name: "createdAt",
    columnAggregation: {
      path: "createdAt",
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      filters: [
        {
          preview: DateFilter,
          applyOnFunctions: ["first", "last"]
        },
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    dateFilterPath: ["createdAt"],
    grouping: {
      getData: (entry) => entry?.data?.createdAt,
      enabled: true,
      key: "createdAt",
      path: "createdAt",
      options: {
        path: ["_id", "createdAt"],
        sort: true
      }
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.createdAt?.show;
    },
    options: {
      sort: true,
      path: ["createdAt"]
    },
    filters: [
      {
        path: ["createdAt"],
        preview: DateFilter,
        label: "Created at"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.extractedCreditScores?.applicant?.score) ?? "N/A",
    label: "Applicant credit score",
    tableSettingsParent: "applicant",
    type: "number",
    name: "applicantCreditScore",
    total: totalsDefault(),
    columnAggregation: {
      path: "data.info.extractedCreditScores.applicant.score",
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last", "sum", "avg"],
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["avg", "list-count-unique", "list-count", "sum"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "info", "extractedCreditScores", "applicant", "score"]
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicantCreditScore?.show;
    },
    filters: [
      {
        path: ["data", "info", "extractedCreditScores", "applicant", "score"],
        preview: PriceFilter,
        name: "applicantCreditScore",
        label: "Applicant credit score"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.extractedCreditScores?.coApplicant?.score) ?? "N/A",
    label: "Co-Applicant credit score",
    tableSettingsParent: "co-applicant",
    name: "coApplicantCreditScore",
    type: "number",
    total: totalsDefault(),
    columnAggregation: {
      path: "data.info.extractedCreditScores.coApplicant.score",
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last", "sum", "avg"],
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["avg", "list-count-unique", "list-count", "sum"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "info", "extractedCreditScores", "coApplicant", "score"]
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.coApplicantCreditScore?.show;
    },
    filters: [
      {
        path: ["data", "info", "extractedCreditScores", "coApplicant", "score"],
        preview: PriceFilter,
        name: "coApplicantCreditScore",
        label: "Co-Applicant credit score"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.profit?.dealershipProfit?.totalProfit ?? 0, "$"),

    label: "Dealership Profit",
    tableSettingsParent: "financial",
    type: "number",
    name: "dealershipProfit",
    total: totalsDefault("$"),
    sign: (funcName) => "$",
    default: "$0.00",
    columnAggregation: {
      possibleFunctions: ["avg", "sum", "list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.profit.dealershipProfit.totalProfit",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["avg", "list-count-unique", "list-count", "sum"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "info", "dealershipProfit", "totalProfit"]
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipProfit?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealershipProfit", "totalProfit"],
        preview: PriceFilter,
        name: "dealershipProfit",
        label: "Dealership Profit Total"
      }
    ]
  },
  ...(Object.values(DealStatus).map((status) => ({
    getData: (entry): CellValue => (entry.data?.info?.isInStatus?.[status] ? "Yes" : "No"),
    label: `Is in status ${status}`,
    tableSettingsParent: "is in status",
    total: totalsDefault(),
    options: {
      sort: true,
      path: ["data", "info", "isInStatus", status]
    },
    grouping: {
      getData: (entry) => {
        return entry?.data?.[`isInStatus${status}`] ? "Yes" : "No";
      },
      enabled: true,
      key: `isInStatus${status}`,
      options: {
        path: ["_id", "isInStatus"],
        sort: true
      },
      path: `data.info.isInStatus.${status}`
    },
    default: "N/A",
    columnAggregation: {
      path: `data.info.isInStatus.${status}`,
      possibleFunctions: ["list", "list-count", "list-count-unique", "first", "last", "sum", "avg"],
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["sum", "list-count-unique", "list-count", "avg"]
        }
      ]
    },
    name: `isInStatus${capitalize(status)}`,
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.[`isInStatus${capitalize(status)}`]?.show;
    },
    filters: [
      {
        path: ["data", "info", "isInStatus", status],
        preview: MultiSelectFilter,
        valuesForSelect: [1, 0],
        optionLabelForSelect: (isInStatusBinary) => (isInStatusBinary === 1 ? "Yes" : "No")
      }
    ]
  })) as Column<any>[]),
  ...(Object.values(DealStatus).map((status) => ({
    getData: (entry): CellValue => (entry.data?.info?.wasInStatus?.[status] ? "Yes" : "No"),
    label: `Was in status ${status}`,
    tableSettingsParent: "was in status",
    total: totalsDefault(),
    options: {
      sort: true,
      path: ["data", "info", "wasInStatus", status]
    },
    grouping: {
      getData: (entry) => {
        return entry?.data?.[`wasInStatus${status}`] ? "Yes" : "No";
      },
      enabled: true,
      key: `wasInStatus${status}`,
      options: {
        path: ["_id", "wasInStatus"],
        sort: true
      },
      path: `data.info.wasInStatus.${status}`
    },
    default: "N/A",
    columnAggregation: {
      path: `data.info.wasInStatus.${status}`,
      possibleFunctions: ["list", "list-count", "list-count-unique", "first", "last", "sum", "avg"],
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["sum", "list-count-unique", "list-count", "avg"]
        }
      ]
    },
    name: `wasInStatus${capitalize(status)}`,
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.[`wasInStatus${capitalize(status)}`]?.show;
    },
    filters: [
      {
        path: ["data", "info", "wasInStatus", status],
        preview: MultiSelectFilter,
        valuesForSelect: [1, 0],
        optionLabelForSelect: (wasInStatusBinary) => (wasInStatusBinary === 1 ? "Yes" : "No")
      }
    ]
  })) as Column<any>[]),

  {
    getData: (entry): CellValue => entry._id,
    label: "Approver",
    name: "approver",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.approver?.show;
    },
    filters: [
      {
        path: ["approvedByUserId"],
        preview: UserFilter,
        label: "Approver"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry._id,
    label: "Actions",
    name: "actions",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.actions?.show;
    }
  }
];

export const projections = {
  "data.info.dealDates": 1,
  "data.info.creditCheckDate": 1,
  "data.info.collateralReceivedDate": 1,
  "data.applicant": 1,
  "data.coApplicant": 1,
  "data.info.aftermarketOptions": 1,
  "data.source": 1,
  "data.info.status": 1,
  "data.notes": 1,
  "data.info.profit.dealershipProfit.totalProfit": 1,
  "data.info.refNumber": 1,
  "data.dealership.data.info.displayName": 1,
  "data.dealership.data.representative.data.info.firstName": 1,
  "data.dealership.data.representative.data.info.lastName": 1,
  "data.info.vehicle.make": 1,
  "data.netsuiteEstimateId": 1,
  "data.netsuiteSalesOrderId": 1,
  "data.netsuiteInvoiceId": 1,
  "data.netsuiteVendorBillIds": 1,
  "data.info.vehicle.model": 1,
  "data.info.vehicle.VIN": 1,
  "data.lender.data.info.name": 1,
  "data.user.data.info.firstName": 1,
  "data.user.data.info.lastName": 1,
  "data.userId": 1,
  "data.user._id": 1,
  "data.info.isInStatus": 1,
  "data.info.wasInStatus": 1,
  "data.info.payment": 1,
  "data.order": 1,
  "data.info.extractedCreditScores": 1,
  "data.info.type": 1,
  availableToRolesIds: 1,
  _id: 1,
  approved: 1,
  approvedByUserId: 1,
  approvedAt: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1,
  applicantNames: 1,
  userNames: 1,
  repNames: 1
};

const addEntityFunction = (dispatch: Dispatch<any>, requestId: string) => () => {
  const defaultState: Deal = generateDefault(
    getModel("deal") as any,
    {},
    fillDefaultsByPath as any
  ) as Deal;
  const newDeal: Deal = calculate(defaultState, defaultState);

  dispatch(
    addDeal({
      requestId,
      ...newDeal
    })
  );
};
const addEntityFromDocumentFunction = (dispatch: Dispatch<any>, requestId: string) => (
  state: AddDealFromDocumentRequest
) => {
  dispatch(
    addDealFromParsedDocument({
      requestId,
      ...state
    })
  );
};
const ChipTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    maxHeight: "300px",
    overflowY: "scroll"
  }
}))(Tooltip);
export const chipTooltip = (
  chipLabel: string,
  chipStyle: React.CSSProperties,
  deal: Deal
): JSX.Element => {
  return (
    <ChipTooltip
      interactive
      open={deal.data.notes === undefined || deal.data.notes?.length === 0 ? false : undefined}
      title={
        <React.Fragment>
          {deal.data.notes?.map((note, index) => (
            <Paper elevation={2} key={index}>
              <Box m={1}>{note?.data?.info.note}</Box>
              <Grid container justifyContent="flex-end">
                <Box fontSize={10} color="#8c8c8c" style={{ padding: "0 5px 0 0" }}>
                  {note?.data?.info?.user?.firstName +
                    " " +
                    note?.data?.info?.user?.lastName +
                    " " +
                    formatDate(note?.createdAt || undefined, "medium", true)}
                </Box>
              </Grid>
            </Paper>
          ))}
        </React.Fragment>
      }
    >
      <Chip size="small" label={chipLabel} style={chipStyle} />
    </ChipTooltip>
  );
};
export const getStyleByStatus = (status: DealStatus): React.CSSProperties => {
  switch (status) {
    case DealStatus.CreditCheck:
      return { backgroundColor: "#ff0080" };
    case DealStatus.SentToDealer:
      return { backgroundColor: "#4caf50" };
    case DealStatus.Denied:
      return { backgroundColor: "#f44336" };
    case DealStatus.Cancelled:
      return { backgroundColor: "#f44336" };
    case DealStatus.SubmittedForFunding:
      return { backgroundColor: "#2b9aff" };
    case DealStatus.Dead:
      return { backgroundColor: "#1f2124", color: "#ffffff" };
    default:
      return {};
  }
};

export const statusToLabel = (status: DealStatus) => capitalize(status);
export const statusToChipTooltip = (
  status: DealStatus,
  deal: Deal,
  style: React.CSSProperties = { border: "1px solid #b8b8b8" }
) => chipTooltip(statusToLabel(status), style, deal);
export const statusToChip = (
  status: DealStatus,
  disabled = true,
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => Promise<void>
) => (
  <Chip
    size="small"
    disabled={disabled}
    label={statusToLabel(status)}
    style={{ border: "1px solid #b8b8b8", minWidth: "100px", ...getStyleByStatus(status) }}
    onClick={onClick}
  />
);
export default function ({
  query,
  slice = "table"
}: {
  query?: Record<string, unknown>;
  slice?: string;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const dealList = useSelector((state: RootState) => state.listDealSlice[slice]);
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const addDealState = useSelector((state: RootState) => state.addDealSlice[requestId]);
  const addDealFromParsedDocumentState = useSelector(
    (state: RootState) => state.addDealFromParsedDocumentSlice[requestId]
  );
  const user = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);
  const snapshotColumnGroupsList = useSelector(
    (state: RootState) => state.listColumnGroupSlice[requestId]
  );
  const addColumnGroupStatus = useSelector(
    (state: RootState) => state.addColumnGroupSlice[requestId]
  );

  const columnGroupData = JSON.parse(localStorage.getItem("columnGroupData") ?? "{}");
  const createOrFocusTab = useContext(TabContext);
  useEffect(() => {
    if (addDealFromParsedDocumentState?.status === "waiting") {
      dispatch(showLoader());
    }
    if (addDealFromParsedDocumentState?.status === "success") {
      enqueueSnackbar(`Successfully created a deal.`, { variant: "success" });
      dispatch(hideLoader());
    }
    if (addDealFromParsedDocumentState?.status === "error") {
      enqueueSnackbar(addDealFromParsedDocumentState?.message ?? "Something went wrong", {
        variant: "error"
      });
      dispatch(hideLoader());
    }
  }, [addDealFromParsedDocumentState?.status]);
  useEffect(() => {
    if (
      addDealState !== undefined &&
      addDealState.status === "success" &&
      addDealState.data !== null
    ) {
      lockEntityFunction(dispatch, addDealState.data.message._id, "deal", requestId);
      createOrFocusTab({
        label: "Show page",
        index: "showPage",
        isForSidebar: false,
        isForQuickAccess: false,
        isPersistent: false,
        props: {
          _id: addDealState.data.message._id,
          type: "deal"
        }
      });
    }
  }, [addDealState, createOrFocusTab, dispatch, requestId]);
  useEffect(() => {
    if (
      addDealFromParsedDocumentState !== undefined &&
      addDealFromParsedDocumentState.status === "success" &&
      addDealFromParsedDocumentState.data !== null
    ) {
      lockEntityFunction(
        dispatch,
        addDealFromParsedDocumentState.data.message._id,
        "deal",
        requestId
      );
      createOrFocusTab({
        label: "Show page",
        index: "showPage",
        isForSidebar: false,
        isForQuickAccess: false,
        isPersistent: false,
        props: {
          _id: addDealFromParsedDocumentState.data.message._id,
          type: "deal"
        }
      });
    }
  }, [addDealFromParsedDocumentState, createOrFocusTab, dispatch, requestId]);

  useEffect(() => {
    if (!snapshotColumnGroupsList)
      dispatch(
        getColumnGroupList(requestId, {
          options: { pagination: false },
          query: {
            "data.info.type": "deals",
            "data.info.isSnapshot": true,
            deleted: false
          }
        })
      );
    return () => {
      dispatch(removeColumnGroupList(requestId));
      return;
    };
  }, []);
  useEffect(() => {
    switch (addColumnGroupStatus?.status) {
      case "success":
        enqueueSnackbar("Successfully imported a column grouping!", {
          variant: "success"
        });
        break;
    }
  }, [addColumnGroupStatus?.status]);
  useEffect(() => {
    if (
      user?._id &&
      snapshotColumnGroupsList?.entities?.length &&
      columnGroupData?.columnGroupId &&
      columnGroupData?.page === "deals"
    ) {
      const foundGroup = snapshotColumnGroupsList?.entities?.find(
        (x) => x._id === columnGroupData?.columnGroupId
      );
      if (foundGroup) {
        const nonSnapshotGroupToCreate = {
          data: {
            userId: user?._id as string,
            info: {
              ...foundGroup.data.info,
              isSnapshot: false
            }
          }
        } as ColumnGroup;
        dispatch(
          addColumnGroup({
            requestId,
            ...nonSnapshotGroupToCreate
          })
        );
        localStorage.removeItem("columnGroupData");
      }
    }
  }, [snapshotColumnGroupsList?.entities]);
  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={[{ entity: "deal", action: "read" }]}>
        <GroupedTable
          toolbarStyle={{ minHeight: "48px" }}
          tableName="deals"
          entityName="deal"
          groupingType={"deals"}
          slice={slice}
          listFunction={getDealList}
          deleteEntityFunction={deleteDeal}
          // @ts-ignore
          addEntityFromDocumentFunction={addEntityFromDocumentFunction(dispatch, requestId)}
          addEntityFunction={addEntityFunction(dispatch, requestId)}
          recoverEntityFunction={recoverDeal}
          hideAddButtonByDocument={false}
          sort={{ createdAt: "desc" }}
          title={"Deals"}
          renderChildTable={(ids, tableGroupBy) => {
            return <ChildDeals tableGroupBy={tableGroupBy} dealIds={ids} />;
          }}
          clearListFunction={clearDealList}
          expandable={true}
          listEntity={{
            entities: dealList?.columnAggs?.length
              ? // @ts-ignore
                dealList?.entities?.map((dealAgg: any): any => {
                  const { _id, ...restOfData } = dealAgg;
                  return {
                    _id: Object.values(dealAgg._id).join("_"),
                    data: {
                      ...dealAgg._id,
                      ...restOfData
                    }
                  };
                })
              : dealList?.entities,
            ...(dealList?.comparison
              ? {
                  comparison: {
                    ...dealList?.comparison,
                    entities: dealList?.comparison?.columnAggs?.length
                      ? // @ts-ignore
                        dealList?.comparison?.entities?.map((dealAgg: any): any => {
                          const { _id, ...restOfData } = dealAgg;
                          return {
                            _id: Object.values(dealAgg._id).join("_"),
                            data: {
                              ...dealAgg._id,
                              ...restOfData
                            }
                          };
                        })
                      : dealList?.comparison?.entities
                  }
                }
              : {}),
            columnAggs: dealList?.columnAggs || [],
            totals: dealList?.totals,
            totalsAvg: dealList?.totalsAvg,
            totalsListCount: dealList?.totalsListCount,
            totalsListCountAvg: dealList?.totalsListCountAvg,
            totalsListCountUnique: dealList?.totalsListCountUnique,
            totalsListCountUniqueAvg: dealList?.totalsListCountUniqueAvg,
            status: dealList?.status,
            total: dealList?.total || 0
          }}
          defaultColumnGroupQuery={{ "data.info.type": "deals" }}
          defaultDateFilterColumnName="createdAt"
          rowsPerPage={50}
          defaultColumns={groupDealColumns}
          customCellFontSize="12px"
          query={query}
          projection={projections}
          showTotalFooter={true}
        />
      </AccessControl>
    </div>
  );
}
interface ChildDealsProps {
  dealIds?: string[];
  tableGroupBy: TableGroupByItem[];
}
const ChildDeals = ({ dealIds, tableGroupBy }: ChildDealsProps) => {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const columns: Column<DealData>[] = [
    {
      getData: (entry): CellValue =>
        [
          entry.data?.applicant
            ? `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
                entry.data?.applicant?.data?.info?.lastName ?? ""
              }`
            : undefined,
          entry.data?.coApplicant
            ? `${entry.data?.coApplicant?.data?.info?.firstName ?? ""} ${
                entry.data?.coApplicant?.data?.info?.lastName ?? ""
              }`
            : undefined
        ]
          .filter((x) => x)
          .join(" / "),
      label: "Customer",
      name: "customer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.customer?.show;
      },
      filters: [
        {
          path: [
            ["data", "applicant", "data", "info", "firstName"],
            ["data", "applicant", "data", "info", "lastName"],
            ["data", "coApplicant", "data", "info", "firstName"],
            ["data", "coApplicant", "data", "info", "lastName"]
          ],
          preview: ApplicantFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.refNumber,
      label: "Ref #",
      options: {
        sort: true,
        path: ["data", "info", "refNumber"]
      },
      name: "refNumber",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.refNumber?.show;
      },
      filters: [
        {
          path: ["data", "info", "refNumber"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => dealSourceToLabel(entry.data?.source?.type),
      label: "Application source",
      tableSettingsParent: "deal",
      options: {
        sort: true,
        path: ["data", "source", "type"]
      },
      name: "source",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.source?.show;
      },
      filters: [
        {
          path: ["data", "source", "type"],
          preview: MultiSelectFilter,
          valuesForSelect: Object.values(DealSourceTypes),
          optionLabelForSelect: (source) => dealSourceToLabel(source)
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.dealership?.data?.info?.displayName ?? "",
      label: "Dealership",
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      },
      filters: [
        {
          path: ["data", "dealershipId"],
          preview: DealershipFilter
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.info?.extractedCreditScores?.applicant?.score} ${
          entry.data?.info?.extractedCreditScores?.coApplicant?.score
            ? "/ " + entry.data?.info?.extractedCreditScores?.coApplicant?.score
            : ""
        }`,
      label: "Credit Scores",
      name: "creditScores",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.creditScores?.show;
      },
      filters: []
    },

    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
      label: "Make",
      name: "make",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.make?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "make"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
      label: "Model",
      name: "model",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.model?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "model"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.VIN,
      label: "VIN",
      name: "vin",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vin?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "VIN"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
      label: "Lender",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      },
      filters: [
        {
          path: ["data", "lenderId"],
          preview: LenderFilter
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.user?.data?.info?.firstName ?? ""} ${
          entry.data?.user?.data?.info?.lastName ?? ""
        }`,
      label: "F&I mgr",
      name: "f&iManager",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.["f&iManager"]?.show;
      },
      filters: [
        {
          path: ["data", "userId"],
          preview: UserFilter,
          type: "f&i manager"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.dealership?.data?.representative?.data?.info?.firstName ?? ""} ${
          entry.data?.dealership?.data?.representative?.data?.info?.lastName ?? ""
        }`,
      label: "Rep",
      name: "rep",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.rep?.show;
      },
      filters: [
        {
          path: ["data", "dealership", "data", "representativeId"],
          preview: UserFilter,
          type: "representative"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.dealDates?.contractDate
          ? new Date(entry.data?.info?.dealDates?.contractDate)
          : "",
      label: "Contract",
      options: {
        sort: true,
        path: ["data", "info", "dates", "contractDate"]
      },
      name: "contractDate",
      show: (_userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.contractDate?.show;
      },
      filters: [
        {
          path: ["data", "info", "dealDates", "contractDate"],
          preview: DateFilter,
          label: "Contract Date"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.status,
      label: "Status",
      options: {
        customBodyRender: (x: CellValue, deal): JSX.Element | string => {
          const dealStatus = x as DealStatus;
          return statusToChipTooltip(dealStatus, deal, getStyleByStatus(dealStatus));
        },
        sort: true,
        path: ["data", "info", "status"]
      },
      name: "status",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.status?.show;
      },
      filters: [
        {
          path: ["data", "info", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: Object.values(DealStatus),
          optionLabelForSelect: (status) => statusToLabel(status)
        }
      ]
    },

    {
      getData: (entry): CellValue =>
        entry.data?.info?.dealDates?.submittedForFunding
          ? new Date(entry.data?.info?.dealDates?.submittedForFunding)
          : "",
      label: "Submitted at",
      options: {
        sort: true,
        path: ["data", "info", "dates", "submittedForFunding"]
      },
      name: "submittedAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.submittedAt?.show;
      },
      filters: [
        {
          path: ["data", "info", "dealDates", "submittedForFunding"],
          preview: DateFilter,
          label: "Submitted for funding"
        }
      ]
    },
    {
      getData: (entry): CellValue => (entry as unknown) as string,
      label: "Netsuite",
      options: {
        customBodyRender: (x: CellValue, deal): JSX.Element | string => {
          return (
            <div style={{ whiteSpace: "nowrap" }}>
              {deal?.data?.netsuiteEstimateId && (
                <Chip size="small" label="E" style={{ fontFamily: "monospace" }} />
              )}
              {deal?.data?.netsuiteSalesOrderId && (
                <Chip
                  size="small"
                  label="S"
                  style={{ backgroundColor: "#eba93f", fontFamily: "monospace" }}
                />
              )}
              {deal?.data?.netsuiteInvoiceId && (
                <Chip
                  size="small"
                  label="I"
                  style={{ backgroundColor: "#4caf50", fontFamily: "monospace" }}
                />
              )}
              {(deal?.data?.netsuiteVendorBillIds ?? []).length > 0 && (
                <Chip
                  size="small"
                  label="B"
                  style={{ backgroundColor: "#4caf50", fontFamily: "monospace" }}
                />
              )}
            </div>
          );
        },
        sort: true,
        path: ["data"]
      },
      name: "netsuite",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.netsuite?.show;
      },
      filters: [
        {
          path: ["data", "netsuiteEstimateId"],
          name: "netsuiteEstimateId",
          label: "Netsuite Estimate Id",
          preview: NetsuiteFilter
        },
        {
          path: ["data", "netsuiteSalesOrderId"],
          name: "netsuiteSalesOrderId",
          label: "Netsuite Sales Order Id",
          preview: NetsuiteFilter
        },
        {
          path: ["data", "netsuiteInvoiceId"],
          name: "netsuiteInvoiceId",
          label: "Netsuite Invoice Id",
          preview: NetsuiteFilter
        },
        {
          path: ["data", "netsuiteVendorBillIds"],
          name: "netsuiteVendorBillIds",
          label: "Netsuite Vendor Bill Ids",
          preview: NetsuiteFilter
        },
        {
          path: ["data", "info", "payment", "fundedAmount"],
          name: "fundedAmount",
          label: "Funded Amount",
          preview: NetsuiteFilter
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        formatNumberAsCurrency(entry.data?.info?.payment?.fundedAmount ?? 0),
      label: "Funded",
      name: "fundedAmount",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.fundedAmount?.show;
      },
      options: {
        sort: true,
        path: ["data", "info", "payment", "fundedAmount"]
      },
      filters: [
        {
          path: ["data", "info", "payment", "fundedAmount"],
          preview: PriceFilter
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.dealDates?.fundedAt
          ? new Date(entry.data?.info?.dealDates?.fundedAt)
          : "",
      label: "Funded At",
      options: {
        sort: true,
        path: ["data", "info", "dealDates", "fundedAt"]
      },
      name: "fundedAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.fundedAt?.show;
      },
      filters: [
        {
          path: ["data", "info", "dealDates", "fundedAt"],
          preview: DateFilter,
          label: "Funded At"
        }
      ]
    },
    {
      getData: (deal): CellValue => {
        const insurances = deal.data?.info?.aftermarketOptions?.insurances ?? [];
        const hasGap = insurances?.some(
          (el) => el?.chosenRate?.insuranceType === "gap" && el?.chosenRate?.soldBy === "WFD"
        );
        const hasWarranty = insurances?.some(
          (el) =>
            el?.chosenRate?.insuranceType === "service warranty" && el?.chosenRate?.soldBy === "WFD"
        );
        return [hasGap ? "Gap" : "", hasWarranty ? "Warranty" : ""];
      },
      excelColumns: ["Gap", "Warranty"],
      label: "Prod.",
      options: {
        customBodyRender: (x: CellValue, deal): JSX.Element | string => {
          const insurances = deal.data?.info?.aftermarketOptions?.insurances ?? [];
          const hasGap = insurances.some(
            (el) => el?.chosenRate?.insuranceType === "gap" && el?.chosenRate?.soldBy === "WFD"
          );
          const hasWarranty = insurances.some(
            (el) =>
              el?.chosenRate?.insuranceType === "service warranty" &&
              el?.chosenRate?.soldBy === "WFD"
          );
          return (
            <div style={{ whiteSpace: "nowrap" }}>
              {hasGap && <Chip size="small" label="G" style={{ fontFamily: "monospace" }} />}
              {hasWarranty && (
                <Chip
                  size="small"
                  label="W"
                  style={{ backgroundColor: "#eba93f", fontFamily: "monospace" }}
                />
              )}
            </div>
          );
        },
        sort: true,
        path: ["data"]
      },
      name: "products",
      show: (userPermissions, tableSettings) => {
        return true;
      }
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Approver",
      name: "approver",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.approver?.show;
      },
      filters: [
        {
          path: ["approvedByUserId"],
          preview: UserFilter,
          label: "Approver"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Actions",
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];
  const slice = `childDeals-${dealIds?.join("-")}`;

  const dealList = useSelector((state: RootState) => state.listDealSliceByIds);
  const previousGroupedColumnKeys = tableGroupBy.map((group) => group.key);
  const filteredDefaultColumnsByPreviousGroups = columns.filter(
    (column) => !previousGroupedColumnKeys.includes(column.grouping?.key ?? "")
  );

  return (
    <div style={{ position: "relative" }}>
      <AccessControl
        requiredPermissions={[
          { entity: "deal", action: "read" },
          { entity: "deal", action: "menu" }
        ]}
      >
        <Table
          tableName="deals"
          entityName="deal"
          slice={slice}
          listFunction={getDealListByIds}
          listEntity={dealList[slice]}
          deleteEntityFunction={deleteDeal}
          addEntityFromDocumentFunction={addEntityFromDocumentFunction(dispatch, requestId)}
          addEntityFunction={addEntityFunction(dispatch, requestId)}
          recoverEntityFunction={recoverDeal}
          hideAddButtonByDocument={false}
          sort={{ createdAt: "desc" }}
          title={"Deals"}
          query={{ _id: { $in: dealIds } }}
          columns={filteredDefaultColumnsByPreviousGroups}
          projection={projections}
        />
      </AccessControl>
    </div>
  );
};
