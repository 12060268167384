import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditPrintedDocumentData {
  _id: string;
  updatedAt: string;
  data: FormState;
}
interface EditPrintedDocumentRequest {
  _id: string;
  data: FormState;
}

interface EditPrintedDocumentRequestResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditPrintedDocumentRequest,
  EditPrintedDocumentRequestResponse
>("EditPrintedDocument", {
  request: "update_printed_document"
});

export const editPrintedDocumentActions = socketMessageSlice.actions;
export const editPrintedDocument = (request: EditPrintedDocumentData & Identifiable) => {
  const { updatedAt, ...rest } = request;
  return socketAction({
    ...rest,
    data: {
      ...request.data,
      info: request.data.info
    }
  });
};
export default socketMessageSlice.reducer;
